import { Divider, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useRef } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Input from '@components/InputField';
import SearchSelect from 'react-select';
import { useForm } from 'react-hook-form';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import { USERS_TYPE } from '../../../Chat/types';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import DoubleInput from '@components/_Modal/DoubleInput';
import SingleInput from '@components/_Modal/SingleInput';
import { ModalMediumText } from '@components/_Modal/ModalTexts';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 600,
      padding: 20,
      paddingBottom: 5,
      borderRadius: 15,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   modalTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.3rem',
      marginBottom: 10,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const AddNewUserModal = ({ onAccept, onCancel, teamsData }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const personTypes = [
      { value: USERS_TYPE.PERSONEL, label: t?.employee },
      { value: USERS_TYPE.ADMIN, label: t?.admin },
   ];

   const { watch, handleSubmit, setValue, control } = useForm({
      mode: 'all',
      defaultValues: {
         name: '',
         surname: '',
         phone: '',
         type: personTypes[0],
         email: '',
         password: '',
         passwordConfirm: '',
      },
   });
   const password = useRef({});
   password.current = watch('password', '');
   //submit data
   const onSubmit = (data) => {
      onAccept(data);
   };
   // react select component styles
   const customStyles = {
      control: (base) => ({
         ...base,
         height: 45,
         minHeight: 45,
      }),
   };
   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <DoubleInput
            name1='name'
            control1={control}
            rules1={{
               required: t?.please_fill_this_field,
            }}
            required1={false}
            label1={t['page_account_input_profile_name']}
            editable1
            name2='surname'
            label2={t['page_account_input_profile_surname']}
            rules2={{
               required: t?.please_fill_this_field,
            }}
            required2={false}
            control2={control}
            editable2
         />
         <SingleInput
            name='email'
            label='E-mail'
            required={false}
            editable
            rules={{
               required: t?.please_fill_this_field,
               pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: t?.must_be_a_valid_email,
               },
            }}
            control={control}
         />
         <Grid contaner xs={12} style={{ marginBottom: 20 }}>
            <Typography
               style={{
                  marginLeft: 3,
                  fontSize: 18,
                  color: '#212121',
               }}
            >
               {t?.page_settings_users_role}
            </Typography>
            <SearchSelect
               name='type'
               options={personTypes}
               onChange={(type) => setValue('type', type)}
               styles={customStyles}
               defaultValue={personTypes[0]}
            />
         </Grid>
         <SingleInput
            isSelect
            isMulti
            label={t.teams}
            selectOptions={teamsData.map((team) => ({
               label: team.teamName,
               value: team._id,
            }))}
            name='teams'
            control={control}
            editable
            placeholder={t?.teams}
         />
         <ModalMediumText style={{ color: 'grey' }}>
            {t?.users_will_recive_email_notification_for_verify} <br />
            {t?.users_need_to_create_password_after_verify} <br />
            {t?.admins_can_edit_or_delete_user_later} <br />
         </ModalMediumText>
         <ModalButtonField>
            <ButtonCancel
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
               onClick={onCancel}
               label={t['comp_button_cancel']}
            />
            <ButtonOK
               className={classes.modalButtons}
               type='submit'
               // disabled={!isValid}
               label={t['comp_button_save']}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
            />
         </ModalButtonField>
      </form>
   );
};

export default AddNewUserModal;

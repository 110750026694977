import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import WifiOffIcon from '@material-ui/icons/WifiOff';

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
      height: '100vh',
   },
}));

const OffLineItem = () => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   return (
      <Grid
         className={classes.root}
         container
         spacing={3}
         justifyContent='center'
         direction='column'
         alignItems='center'
      >
         <Grid item>
            <WifiOffIcon fontSize={'large'} color='inherit' />
         </Grid>
         <Grid item>
            <Typography>{t?.you_are_currently_offline}</Typography>
         </Grid>
         <Grid item>
            <Button
               onClick={() => {
                  window.location.reload();
               }}
               variant={'contained'}
               color='primary'
               size={'large'}
            >
               {t?.try_again}
            </Button>
         </Grid>
      </Grid>
   );
};

export default OffLineItem;

import Input from '@components/InputField';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useContext } from 'react';
import { IQuick } from '../types';

const useStyles = makeStyles(() => ({
   quickArea: {
      border: '1px solid lightgray',
      borderRadius: 12,
      paddingTop: 20,
      paddingInline: 10,
      backgroundColor: '#fafafa',
      paddingBottom: 10,
   },
   title: {
      fontSize: 20,
      paddingBottom: 10,
      paddingLeft: 4,
   },
   deleteIcon: {
      marginLeft: 2,
   },
   button: {
      marginTop: 5,
      fontSize: 18,
      fontWeight: 'bold',
      color: '#424242',
   },
   buttonInputs: {
      marginTop: -15,
   },
}));

const QuickReplies = ({ isView, onAdd, visibilityOfInputs, register, onRemove, control, resetField }: IQuick) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   return (
      <Grid className={classes.quickArea}>
         <Grid container alignContent='center'>
            <Typography variant='inherit' className={classes.title}>
               {t['settings_whatsapp_business_template_form_quick_replies_header']}
            </Typography>
            <Typography style={{ fontSize: 18, paddingTop: 2 }} color='textSecondary' variant='inherit'>
               {t['settings_whatsapp_business_template_form_quick_replies_text']}
            </Typography>
         </Grid>
         <Grid container direction='column'>
            {
               visibilityOfInputs.map((enabled: boolean, index: number) => {
                  if (!enabled) {
                     return null;
                  }
                  return <Grid container alignItems='center' className={classes.buttonInputs}>
                     <Grid xs={11} md={11} lg={6}>
                        <Input
                           disabled={isView}
                           control={control}
                           rules={{
                              required: true,
                              maxLength: 20,
                           }}
                           {...register(`input${index}`)}
                           placeholder={t['settings_whatsapp_business_template_form_quick_replies_enter_button_text']}
                           maxLength={25}
                        />
                     </Grid>
                     {
                        !isView && (
                           <Grid xs={1} md={1} lg={5}>
                              <IconButton
                                 onClick={() => {
                                    onRemove(index);
                                    resetField(`input${index}`);
                                 }}
                                 className={classes.deleteIcon}
                              >
                                 <DeleteIcon color='error' />
                              </IconButton>
                           </Grid>)
                     }
                  </Grid>
               })
            }
            {
               !isView && (
                  <Grid item xs={11} md={11} lg={6}>
                     <Button
                        style={{
                           display:
                              !visibilityOfInputs[0] || !visibilityOfInputs[1] || !visibilityOfInputs[2] ? 'block' : 'none',
                           border: '2px dashed lightgray',
                           borderRadius: 8,
                        }}
                        fullWidth
                        variant='outlined'
                        className={classes.button}
                        onClick={onAdd}
                     >
                        {t['settings_whatsapp_business_template_form_quick_replies_add_button']}
                     </Button>
                  </Grid>
               )
            }

         </Grid>
      </Grid>
   );
};

export default QuickReplies;

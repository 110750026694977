import React from 'react';
import Nav from './nav';
import { Node } from 'reactflow';
import FlowBots from './flowBots';
import { FlowBotDataItem } from '@modules/Automation/FlowBots/types';

interface ContentPanelProps {
   selectedItem: FlowBotDataItem | undefined;
   setSelectedItem: React.Dispatch<React.SetStateAction<FlowBotDataItem | undefined>>;
   setFlowBotData: React.Dispatch<React.SetStateAction<FlowBotDataItem[]>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   clickedNodeId: string;
   setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
   filter: string;
}

const ContentPanel: React.FC<ContentPanelProps> = ({
   selectedItem,
   setSelectedItem,
   setFlowBotData,
   setContentNodes,
   clickedNodeId,
   setClickedHandleId,
   setClickedNodeId,
   filter,
   setIsNewNode,
}) => {
   return (
      <div>
         <Nav
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            setFlowBotData={setFlowBotData}
            setContentNodes={setContentNodes}
            clickedNodeId={clickedNodeId}
            setClickedHandleId={setClickedHandleId}
            setClickedNodeId={setClickedNodeId}
         />
         <FlowBots
            selectedItem={selectedItem}
            setIsNewNode={setIsNewNode}
            setSelectedItem={setSelectedItem}
            setFlowBotData={setFlowBotData}
            setContentNodes={setContentNodes}
            clickedNodeId={clickedNodeId}
            setClickedHandleId={setClickedHandleId}
            setClickedNodeId={setClickedNodeId}
            filter={filter}
         />
      </div>
   );
};

export default ContentPanel;

import React from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';

interface Props {
   className?: string;
}

export const TriggerIcon = ({ className }: Props) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='34' height='34' viewBox='0 0 34 34' className={className}>
         <rect id='Rectangle_757' data-name='Rectangle 757' width='34' height='34' rx='7' fill='#CBCBCB' />
         <g id='__TEMP__SVG__' transform='translate(4.525 7.536)'>
            <path
               id='Path_4724'
               data-name='Path 4724'
               d='M16.525.084a.623.623,0,0,1,.283.722L14.564,8.1H18.7a.623.623,0,0,1,.453,1.05L9.192,19.733a.623.623,0,0,1-1.048-.611l2.243-7.29H6.247a.623.623,0,0,1-.453-1.05L15.759.195a.623.623,0,0,1,.766-.112Z'
               fill='#10223A'
               fill-rule='evenodd'
            />
         </g>
      </svg>
   );
};
export const ConditionIcon = () => {
   return (
      <div
         style={{
            backgroundColor: '#BEF1DB',
            padding: '2px 3px',
            borderRadius: '3px',
            display: 'flex',
            justifyContent: 'center',
         }}
      >
         <FilterAltIcon style={{ color: '#76bc9f', fontSize: '15px' }} />
      </div>
   );
};

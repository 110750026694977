import { gql } from '@apollo/client';

export const CREATE_CUSTOMER_SATISFACTION_SURVEY_MUTATION = gql`
   mutation createSurveysMutation($input: CreateSurveysInput) {
      createSurveys(input: $input) {
         data {
            _id
            customer
            status
            name
            logo
            title
            message
            commentInputTitle
            submitButtonText
            themeMainColor
            thankYouMessage
            createdAt
            updatedAt
         }
         success
         code
         message
      }
   }
`;

export const UPDATE_CUSTOMER_SATISFACTION_SURVEY_MUTATION = gql`
   mutation updatedSurveysMutation($input: UpdateSurveysInput) {
      updateSurveys(input: $input) {
         data {
            _id
            customer
            status
            name
            logo
            title
            message
            commentInputTitle
            submitButtonText
            themeMainColor
            thankYouMessage
            createdAt
            updatedAt
         }
         success
         code
         message
      }
   }
`;

export const DELETED_CUSTOMER_SATISFACTION_MUTATION = gql`
   mutation deletedSurveysMutation($input: DeleteSurveysInput) {
      deleteSurveys(input: $input) {
         data {
            _id
            customer
            status
            name
            logo
            title
            message
            commentInputTitle
            submitButtonText
            themeMainColor
            thankYouMessage
            createdAt
            updatedAt
         }
         success
         code
         message
      }
   }
`;

export const FEEDBACK_SURVEY_MUTATION = gql`
   mutation updateSurveyHistory($_id: String!, $rating: String!, $comment: String) {
      updateSurveyHistory(input: { _id: $_id, rating: $rating, comment: $comment }) {
         data {
            _id
            customer
            chat
            person
            customerPerson
            survey
            surveyName
            rating
            comment
            status
            channel
            createdAt
            updatedAt
         }
         success
         code
         message
      }
   }
`;

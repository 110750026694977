import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import clsx from 'clsx';
import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
   container: {
      color: (props) =>
         props.commonUse ? (props.editable ? theme.chatApp.general.pallet.passiveStructureBlue : '#8A9EB5') : null,
      backgroundColor: (props) => (props.editable ? 'white' : '#eee'),
      borderColor: theme.palette.primary.main,
      height: (props) => (props.isMultiLine ? null : props.height || 45),
   },
   textInput: {
      width: '100%',
      border: 'none',
      resize: 'none',
      borderRadius: 10,
      padding: 10,
      fontSize: '1.075rem',
   },
   inputLabel: {
      marginTop: '-45px',
      color: (props) => props.labelColor || '',
      '&.focused': {
         color: theme.palette.primary.main,
      },
   },
   smallLabel: {
      fontSize: '0.8rem',
      // marginTop: -38,
   },
}));

const TextInput = (props) => {
   const {
      id,
      error,
      label,
      defaultValue,
      value,
      isFocused,
      isRequired,
      isPassword,
      editable,
      labelSize,
      rowsMax,
      hint,
      isMultiLine,
      rows,
      name,
      onChange,
      onEnter,
      maxLength,
      InputProps,
      endAdornment,
      placeholder,
      ...rest
   } = props;
   const classes = useStyles(props);

   const handleKeyPress = (event) => {
      if (event.key === 'Enter' && onEnter) {
         onEnter();
      }
   };

   return (
      <Fragment>
         <TextField
            variant='outlined'
            value={value || defaultValue}
            margin='normal'
            error={error}
            disabled={!editable}
            required={isRequired}
            fullWidth
            id={id}
            label={label}
            name={name}
            InputLabelProps={{
               variant: 'standard',
               shrink: false,
               classes: {
                  root: clsx(classes.inputLabel, labelSize === 'small' && classes.smallLabel),
                  focused: 'focused',
               },
            }}
            InputProps={{
               endAdornment,
               className: classes.container,
               classes: {
                  disabled: classes.disabled,
               },
            }}
            inputProps={{
               maxLength,
            }}
            onChange={onChange}
            onKeyUp={handleKeyPress}
            // autoComplete="email"
            autoFocus={isFocused}
            multiline={isMultiLine}
            rowsMax={isMultiLine ? rowsMax || 4 : 1}
            rows={isMultiLine ? rows || 3 : 1}
            type={isPassword ? 'password' : null}
            placeholder={placeholder}
            {...rest}
         />

         {hint && (
            <Typography style={{ marginTop: -5, fontSize: '0.8rem', color: props.labelColor || 'lightgray' }}>
               {hint}
            </Typography>
         )}
      </Fragment>
   );
};

export default TextInput;

import { gql } from '@apollo/client';

export const PERSON_CORE_FIELDS_FRAGMENT = gql`
   fragment PersonFields on Person {
      _id
      name
      surname
      username
      phone
      email
      photo
      type
      country
      city
      state
      address
      postCode
      setup
      status
      createdAt
      __typename
   }
`;

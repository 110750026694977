import { gql } from '@apollo/client';

// queries
export const GET_FLOW_BOT_QUERY = gql`
   query GetFlowBot($input: GetFlowBotInput!) {
      getFlowBot(input: $input) {
         success
         data {
            _id
            customer
            title
            status
            createdAt
            updatedAt
         }
         code
         message
      }
   }
`;

export const GET_FLOW_BOT_PAGINATED_QUERY = gql`
   query GetFlowBotPaginated($input: GetFlowBotPaginatedInput!) {
      getFlowBotPaginated(input: $input) {
         success
         data {
            hasNextPage
            totalRecords
            docs {
               _id
               customer
               title
               valid
               triggerFields {
                  keywords
                  rule
                  type
                  target
                  channelType
               }
               status
               createdAt
               updatedAt
            }
         }
         code
         message
      }
   }
`;

export const GET_FLOW_NODE_QUERY = gql`
   query GetFlowNode($input: GetFlowNodeInput!) {
      getFlowNode(input: $input) {
         success
         data {
            _id
            customer
            flowBot
            title
            type
            coordinates {
               x
               y
            }
            groupId
            templateId
            flowBlocks {
               _id
               customer
               flowBot
               flowNode
               title
               type
               category
               fields {
                  type
                  note
                  backgroundColor
                  rule
                  condition_field
                  keywords
                  platformIds
                  value
                  save_response_to_contact_field
                  question_answer_timeout
                  question_answer_timeout_unit
                  delay_time
                  delay_time_unit
                  target_on_reply
                  target_on_not_reply
                  target_on_invalid_input
                  isButtonRequired
                  requiredButtonMessage
                  liveChatVisitFrequencyUnit
                  informativeButtons {
                     type
                     title
                     target
                     hideTheEdge
                  }
                  quickReplies {
                     type
                     title
                     target
                     hideTheEdge
                  }
                  requestUrl
                  requestMethod
                  requestHeaders {
                     key
                     value
                  }
                  requestBody
                  requestBodyFormData {
                     key
                     value
                  }
                  requestBodyFormDataUrlEncoded {
                     key
                     value
                  }
                  responseDotNotation {
                     fieldId
                     value
                  }
                  targetOnSuccess
                  targetOnFailure
                  startFlowTargetedBot
                  startFlowNodeStep
               }
               target
               templateId
               createdAt
               updatedAt
               channelType
            }
            isValid
            createdAt
            updatedAt
         }
         code
         message
      }
   }
`;
export const GET_LIST_NODE_TEMPLATES_QUERY = gql`
   query ListNodeTemplates {
      listNodeTemplates {
         data {
            _id
            type
            title
         }
         code
         message
         success
      }
   }
`;
export const GET_LIST_BLOCK_TEMPLATES_QUERY = gql`
   query ListBlockTemplates($input: ListBlockTemplatesInput!) {
      listBlockTemplates(input: $input) {
         code
         data {
            _id
            title
            type
            fields {
               type
               rule
               keywords
               value
               save_response_to_contact_field
               question_answer_timeout
               question_answer_timeout_unit
               target_on_reply
               target_on_not_reply
               target_on_invalid_input
            }
         }
         message
         success
      }
   }
`;
export const GET_LIST_FLOW_NODE_QUERY = gql`
   query Data($input: ListFlowNodeInput!) {
      listFlowNode(input: $input) {
         data {
            _id
            customer
            flowBot
            title
            type
            coordinates {
               x
               y
            }
            groupId
            templateId
            flowBlocks {
               _id
               customer
               flowBot
               flowNode
               title
               type
               category
               fields {
                  type
                  note
                  backgroundColor
                  rule
                  condition_field
                  keywords
                  platformIds
                  value
                  save_response_to_contact_field
                  question_answer_timeout
                  question_answer_timeout_unit
                  delay_time
                  delay_time_unit
                  target_on_reply
                  target_on_not_reply
                  target_on_invalid_input
                  isButtonRequired
                  requiredButtonMessage
                  liveChatVisitFrequencyUnit
                  startFlowTargetedBot
                  startFlowNodeStep
                  informativeButtons {
                     type
                     title
                     target
                     hideTheEdge
                  }
                  quickReplies {
                     type
                     title
                     target
                     hideTheEdge
                  }
                  requestUrl
                  requestMethod
                  requestHeaders {
                     key
                     value
                  }
                  requestBody
                  requestBodyFormData {
                     key
                     value
                  }
                  requestBodyFormDataUrlEncoded {
                     key
                     value
                  }
                  responseDotNotation {
                     fieldId
                     value
                  }
                  targetOnSuccess
                  targetOnFailure
               }
               target
               templateId
               createdAt
               updatedAt
               channelType
            }
            isValid
            createdAt
            updatedAt
         }
      }
   }
`;
export const GET_LIST_FLOW_NODE_QUERY_PAGINATED = gql`
   query GetFlowNodePaginated($input: GetFlowNodePaginatedInput!) {
      getFlowNodePaginated(input: $input) {
         success
         data {
            docs {
               _id
               customer
               flowBot
               title
               type
               coordinates {
                  x
                  y
               }
               groupId
               templateId
               flowBlocks {
                  _id
                  customer
                  flowBot
                  flowNode
                  title
                  type
                  category
                  fields {
                     type
                     note
                     backgroundColor
                     rule
                     condition_field
                     keywords
                     platformIds
                     value
                     save_response_to_contact_field
                     question_answer_timeout
                     question_answer_timeout_unit
                     delay_time
                     delay_time_unit
                     target_on_reply
                     target_on_not_reply
                     target_on_invalid_input
                     isButtonRequired
                     requiredButtonMessage
                     liveChatVisitFrequencyUnit
                     startFlowTargetedBot
                     startFlowNodeStep
                     informativeButtons {
                        type
                        title
                        target
                        hideTheEdge
                     }
                     quickReplies {
                        type
                        title
                        target
                        hideTheEdge
                     }

                     requestUrl
                     requestMethod
                     requestHeaders {
                        key
                        value
                     }
                     requestBody
                     requestBodyFormData {
                        key
                        value
                     }
                     requestBodyFormDataUrlEncoded {
                        key
                        value
                     }
                     responseDotNotation {
                        fieldId
                        value
                     }
                     targetOnSuccess
                     targetOnFailure
                     startFlowTargetedBot
                     startFlowNodeStep
                  }
                  target
                  templateId
                  createdAt
                  updatedAt
                  channelType
               }
               isValid
               createdAt
               updatedAt
            }
            hasNextPage
            totalRecords
         }
         code
         message
      }
   }
`;
export const GET_CONNECTED_MESSAGE_PLATFORMS_QUERY = gql`
   query GetConnectedMessagePlatforms($customerId: ID!) {
      getConnectedMessagePlatforms(customerId: $customerId) {
         success
         data {
            whatsappWeb
            whatsappBusiness
            facebook
            instagram
            telegram
            email
         }
         code
         message
      }
   }
`;
export const GET_LOCATION = gql`
   query Locations($type: String!) {
      locations(type: $type) {
         success
         data
         code
         message
      }
   }
`;
export const GET_HTTP_TEST = gql`
   query HttpTest($input: HttpTestInput!) {
      httpTest(input: $input) {
         success
         data {
            statusCode
            requestFailed
            body
            headers {
               key
               value
            }
         }
         code
         message
      }
   }
`;

export const GET_PAGINATED_ECOMMERCE_RULES = gql`
   query GetPaginatedECommerceRules($input: GetPaginatedECommerceRulesInput) {
      getPaginatedECommerceRules(input: $input) {
         code
         data {
            docs {
               _id
               customer
               ruleTemplate
               title
               type
               status
               message1 {
                  delay {
                     delayTime
                     delayTimeUnit
                  }
                  sendMessage {
                     messageTemplateId
                  }
                  action {
                     ruleId
                     rule
                  }
               }
               message2 {
                  delay {
                     delayTime
                     delayTimeUnit
                  }
                  sendMessage {
                     messageTemplateId
                  }
                  action {
                     ruleId
                     rule
                  }
               }
               createdAt
               updatedAt
               platforms {
                  platformId
                  platformType
               }
            }
            hasNextPage
            totalRecords
         }
         message
         success
      }
   }
`;

export const GET_ECOMMERCE_RULES = gql`
   query GetECommerceRules($input: GetECommerceRulesInput) {
      getECommerceRules(input: $input) {
         success
         data {
            _id
            customer
            ruleTemplate
            title
            type
            status
            message1 {
               delay {
                  delayTime
                  delayTimeUnit
               }
               sendMessage {
                  messageTemplateId
               }
               action {
                  ruleId
                  rule
               }
            }
            message2 {
               delay {
                  delayTime
                  delayTimeUnit
               }
               sendMessage {
                  messageTemplateId
               }
               action {
                  ruleId
                  rule
               }
            }
            createdAt
            updatedAt
            platforms {
               platformId
               platformType
            }
         }
         code
         message
      }
   }
`;

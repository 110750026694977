import React, { useEffect, useState, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { FlowBlockType, IFlowBlockFieldTypes, IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import { LanguageContext } from '@helper/locale/langContext';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';

const useStyles = makeStyles(() => ({
   container: {
      position: 'relative',
      display: 'inline-block',
   },
   triggerIcon: {
      width: 20,
      height: 20,
      margin: '0 5px 5px 5px',
   },
   customHeader: {
      display: 'flex',
      borderBottom: '0.1px solid #f2f2f2',
   },
   addText: {
      padding: '10px',
      margin: '5px',
      textAlign: 'center',
      borderRadius: 2,
      fontSize: 8,
      color: '#AFAFAF',
      backgroundColor: '#FEFCE5',
   },
   nodeContainer: {
      border: '1px solid #ffffff',
      width: '150px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'white',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   InvalidNodeContainer: {
      border: '1px solid #FF7474',
      width: '150px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'white',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   nodeContainerHovered: {
      border: '1px solid #157cfc',
   },
   footer: {
      borderTop: ' 0.1px solid #f2f2f2',
      height: '12px',
      position: 'relative',
   },

   handleEdgeConnected: {
      position: 'absolute',
      top: '8px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#ADADAD',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },

   blocks: {
      display: 'flex',
      flexDirection: 'column',
      padding: '2px 5px',
      fontSize: '9px',
   },

   subBlocks: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '6px',
      backgroundColor: '#F5F5F5',
      borderRadius: '5px',
   },
   iconBox: {
      padding: '2px 3px',
      margin: '0 5px 5px 5px',
      borderRadius: '3px',
      display: 'flex',
      justifyContent: 'center',
   },
   handleTarget: {
      backgroundColor: '#fff',
      width: '5px',
      height: '5px',
      top: 14,
      marginLeft: '5px',
   },
}));
interface TriggerNodeProps {
   id: string;
   isConnectable: boolean;
   data: {
      clickedNodeId: string;
      title: string;
      flowBlocks: { delayTime: number; delayTimeUnit: string };
   };
}
const TriggerNode = ({ id, isConnectable, data }: TriggerNodeProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { title, clickedNodeId, flowBlocks } = data;

   const [isHover, setIsHover] = useState(false);

   useEffect(() => {
      setIsHover(id === clickedNodeId);
   }, [data, id, clickedNodeId]);

   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (id === clickedNodeId ? setIsHover(true) : setIsHover(false))}
         className={classes.container}
      >
         <div className={`${classes.nodeContainer} ${isHover ? classes.nodeContainerHovered : ''}`}>
            <div className={classes.customHeader}>
               <Handle
                  type='target'
                  position={Position.Left}
                  id={id}
                  className={classes.handleTarget}
                  isConnectable={isConnectable}
               />
               <div className={classes.iconBox} style={{ backgroundColor: '#fff8d9' }}>
                  <AccessTimeFilledIcon style={{ color: '#ffcf00', fontSize: '15px' }} />
               </div>
               <div style={{ marginTop: '6px', fontSize: 10 }}>{title}</div>
            </div>

            <div>
               <div
                  className={classes.addText}
                  style={{ display: 'flex', gap: '5px', justifyContent: 'center', color: '#385273' }}
               >
                  <div>{t.atomation_flow_bot_delay_wait}</div>
                  <div>{flowBlocks.delayTime}</div>
                  <div>
                     {flowBlocks.delayTimeUnit === 'DAYS'
                        ? flowBlocks.delayTime && flowBlocks.delayTime > 1
                           ? t.atomation_flow_days
                           : t.atomation_flow_day
                        : flowBlocks.delayTimeUnit === 'MINUTES'
                        ? flowBlocks.delayTime && flowBlocks.delayTime > 1
                           ? t.atomation_flow_minutes
                           : t.atomation_flow_minute
                        : flowBlocks.delayTimeUnit === 'HOURS'
                        ? flowBlocks.delayTimeUnit && flowBlocks.delayTime > 1
                           ? t.atomation_flow_hours
                           : t.atomation_flow_hour
                        : ''}
                  </div>
               </div>

               <div className={classes.footer}>
                  <Handle
                     type='source'
                     position={Position.Right}
                     id={id}
                     className={classes.handleEdgeConnected}
                     isConnectable={isConnectable}
                  />
               </div>
            </div>
         </div>
      </div>
   );
};
export default TriggerNode;

import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/tr';
import React from 'react';
import {
   CartesianGrid,
   Line,
   LineChart as LineChartCore,
   ReferenceLine,
   ResponsiveContainer,
   Tooltip,
   XAxis,
   YAxis,
} from 'recharts';
import { DAY_HOURS } from '@lib/utils';
import { renderActiveDot, renderDot } from '../shared-components/dot';
import CustomTooltip from './CustomTooltip';

const useStyles = makeStyles((theme) => ({
   root: {
      height: 500,
      width: '99%',
   },
}));

const LineChart = ({ chartData, mode }) => {
   const classes = useStyles();
   const maxTotalChatsCount = chartData.map((data) => data.totalChatsCount).reduce((a, b) => (a > b ? a : b), 0);
   const referenceLines = [
      maxTotalChatsCount,
      parseInt((0.75 * maxTotalChatsCount).toString(), 10),
      parseInt((0.5 * maxTotalChatsCount).toString(), 10),
      parseInt((0.25 * maxTotalChatsCount).toString(), 10),
      0,
   ];
   const _chartData = chartData.map((item, i) => {
      const _item = { ...item };
      switch (mode) {
         case 'HOUR':
            _item.title = DAY_HOURS[i];
            return _item;
         case 'DAY':
            _item.title = moment(Number(item.title)).format('MM/DD');
            return _item;
         case 'MONTH':
            _item.title = moment(Number(item.title)).format('MMM YYYY');
            return _item;
         default:
            return null;
      }
   });

   return (
      <div className={classes.root}>
         <ResponsiveContainer>
            <LineChartCore
               data={_chartData}
               margin={{
                  top: 10,
                  right: 10,
                  left: -25,
                  bottom: 5,
               }}
            >
               <CartesianGrid strokeDasharray='3 3' vertical={false} />
               <XAxis dataKey='title' tickSize={15} padding={{ left: 0, right: 5 }} tickLine={false} />
               <ReferenceLine y={referenceLines[0]} stroke='#E3E9Ef' />
               <ReferenceLine y={referenceLines[1]} stroke='#E3E9Ef' />
               <ReferenceLine y={referenceLines[2]} stroke='#E3E9Ef' />
               <ReferenceLine y={referenceLines[3]} stroke='#E3E9Ef' />
               <ReferenceLine y={referenceLines[4]} stroke='#E3E9Ef' />
               <YAxis tickLine={false} ticks={referenceLines.reverse()} />
               <Tooltip content={<CustomTooltip />} />
               <Line
                  type='monotone'
                  stroke='#B5C7D8'
                  isAnimationActive={false}
                  strokeWidth={3}
                  dot={renderDot}
                  activeDot={renderActiveDot}
                  dataKey='totalChatsCount'
               />
            </LineChartCore>
         </ResponsiveContainer>
      </div>
   );
};

export default LineChart;

import React from 'react';
const WhatsAppIcon = ({ selected, color, className, style }) => {
   const color1 = color || (selected ? '#00d954' : '#c7cdd6');
   return (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 529.7 531' className={className} style={{ width: '100%', ...(style || {}) }}>
         <path
            d='M0,531,38.07,389a261.44,261.44,0,0,1-32.9-126.7C5.17,117.66,122.83,0,267.43,0S529.7,117.66,529.7,262.26,412.05,524.53,267.43,524.53a261.6,261.6,0,0,1-124.66-31.78Zm149.87-91.48,9,5.37A212.36,212.36,0,0,0,267.43,475c117.28,0,212.7-95.4,212.7-212.69S384.71,49.58,267.43,49.58,54.75,145,54.75,262.26a212.07,212.07,0,0,0,31.1,110.28l5.52,9L70.12,460.9Z'
            fill={color1}
         />
         <path
            d='M365,298.74c-10.8-6.46-24.85-13.67-37.56-8.47-9.76,4-16,19.27-22.31,27.07-3.24,4-7.11,4.62-12.1,2.62-36.62-14.59-64.68-39-84.89-72.72-3.42-5.24-2.81-9.36,1.31-14.22,6.11-7.19,13.77-15.35,15.43-25s-2.9-21-6.9-29.63c-5.12-11-10.84-26.75-21.88-33-10.15-5.74-23.52-2.53-32.56,4.84-15.61,12.71-23.14,32.62-22.91,52.36a74.14,74.14,0,0,0,2.06,16.62c3.15,13,9.17,25.17,15.94,36.74a292.53,292.53,0,0,0,16.64,25.3c19.59,26.61,44,49.73,72.18,67a218.46,218.46,0,0,0,45,21.4c17.64,5.83,33.35,11.89,52.4,8.28,19.94-3.79,39.59-16.13,47.51-35.34,2.34-5.69,3.51-12,2.2-18C391.86,312.13,375,304.75,365,298.74Z'
            style={{ fill: color1, fillRule: 'evenodd' }}
         />
      </svg>
   );
};

export default WhatsAppIcon;

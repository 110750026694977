import { ButtonBase, Hidden, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment } from 'react';
import LeftMenuItem from '@components/LeftMenuItem';

const useStyles = makeStyles((theme) => ({
   titleContainer: {
      padding: 12,
   },
   menuItemContainer: {
      marginTop: 5,
      paddingLeft: 36,
      fontWeight: '500',
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      transition: 'all .5ms',
      [theme.breakpoints.down('md')]: {
         paddingLeft: 0,
      },
   },
   title: {
      paddingLeft: 12,
      [theme.breakpoints.down('md')]: {
         display: 'none',
      },
      color: (props) => (props.selected ? 'inherit' : 'black'),
   },
   itemContainer: {
      marginTop: 0,
      transition: 'all linear .5s',
   },
   iconSize: {
      fontSize: '3rem',
   },
   backgroundSelector: (props) => ({
      borderRadius: 8,
      transition: 'all .7s',
      background: props.selected ? '#fff' : undefined,
      marginBottom: 8,
   }),
   button: {},
   itemLink: {
      width: '100%',
   },
   icon: {
      width: 25,
      height: 25,
      fill: (props) => (props.selected ? theme.palette.primary.main : '#0E223C'),
   },
}));

const Section = (props) => {
   const { section, selectedItem } = props;
   const selected =
      props.sectionSelected && props.section.url === props.selectedItem && props.section.items.length === 0;
   const classes = useStyles({
      selected,
      ...props,
   });

   return (
      <Fragment>
         <Grid container alignItems='center' className={classes.itemContainer}>
            <Grid container wrap='nowrap' alignItems='center' className={classes.backgroundSelector}>
               <ButtonBase className={classes.button} disableRipple>
                  <Grid
                     container
                     wrap='nowrap'
                     justifyContent='center'
                     direction='row'
                     className={classes.titleContainer}
                  >
                     {section.icon(classes.icon)}
                     <Hidden mdDown>
                        <Typography className={classes.title}> {section.title} </Typography>
                     </Hidden>
                  </Grid>
               </ButtonBase>
            </Grid>
            <Grid container direction={'column'}>
               {section.items.map((item) => (
                  <LeftMenuItem
                     containerClass={classes.menuItemContainer}
                     label={item.title}
                     icon={item.icon}
                     url={item.url}
                     selected={selectedItem === item.url}
                  />
               ))}
            </Grid>
         </Grid>
      </Fragment>
   );
};

export default Section;

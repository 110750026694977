import { useMutation, useQuery } from '@apollo/client';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';
import { VERIFY_MAIL_MUTATION } from '../../../queries/Person/Service/GraphQL/mutation';
import { GET_APP_PREFERENCE_QUERY } from '../../../queries/Person/Service/GraphQL/query';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
   },
   container: {
      maxWidth: 600,
      textAlign: 'center',
   },
}));

const Verify = () => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const classes = useStyles();
   const history = useHistory();
   const { emailId } = useParams();
   const [forwardingCount, setForwardingCount] = useState(10);

   const [verifyMail, { loading, error }] = useMutation(VERIFY_MAIL_MUTATION, { fetchPolicy: 'no-cache' });
   const { data: appPreferenceData } = useQuery(GET_APP_PREFERENCE_QUERY);

   useEffect(() => {
      (async () => {
         try {
            await verifyMail({ variables: { emailId } });
         } catch (err) {
            console.log(err);
         }
      })();
   }, [emailId, verifyMail]);

   useEffect(() => {
      const countdownInterval = setInterval(() => {
         if (forwardingCount > 1) {
            setForwardingCount((forwardingCount) => forwardingCount - 1);
         } else {
            clearInterval(countdownInterval);
            history.push('/sign-in');
         }
      }, 1000);

      return () => {
         clearInterval(countdownInterval);
      };
   }, [forwardingCount, history]);

   if (loading) {
      return <div className='loading' />;
   }

   return (
      <Box className={classes.root}>
         <Box className={classes.container}>
            {!error ? (
               <>
                  <CheckCircleOutlineIcon style={{ color: '#24E12B', height: 80, width: 80 }} />
                  <Typography style={{ fontWeight: 600, fontSize: '1.25rem' }}>{t?.thank_you}</Typography>
                  <Typography style={{ paddingBottom: '.5rem' }}>
                     {' '}
                     {t?.welcome_verify_you_have_successfully_verified}
                  </Typography>
                  {!!appPreferenceData?.getPreference.data.requireAccountApproval === true ? (
                     <Typography style={{ paddingBottom: '.5rem' }}>{t?.welcome_verify_we_are_in_beta_now}</Typography>
                  ) : (
                     <Box mt={3}>
                        <Button variant='contained' color='primary' onClick={() => history.push('/sign-in')}>
                           {t?.welcome_steps_your_get_started}
                        </Button>
                        <Typography
                           style={{
                              display: 'flex',
                              alignItems: 'center',
                              flexDirection: 'column',
                              paddingTop: '0.5rem',
                              fontSize: 24,
                           }}
                        >
                           {forwardingCount}
                        </Typography>
                     </Box>
                  )}
               </>
            ) : error ? (
               <>
                  <CancelOutlinedIcon style={{ color: '#F4425B', height: 80, width: 80 }} />
                  <Typography style={{ fontWeight: 600, fontSize: '1.25rem' }}>
                     {' '}
                     {t?.welcome_steps_we_couldnt_verify_your_email}
                  </Typography>
                  <Typography style={{ paddingBottom: '.5rem' }}>
                     {' '}
                     {t?.welcome_please_restart_your_verification}
                  </Typography>
                  <Box mt={3}>
                     <Button variant='contained' onClick={() => history.push('/')} color='primary'>
                        {t?.welcome_please_restart_verification}
                     </Button>
                  </Box>
               </>
            ) : null}
         </Box>
      </Box>
   );
};

export default WithUrlTranslations(Verify);

import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import React, { useContext, useState } from 'react';
import { Node } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@components/Modal/modal';
import { useLazyQuery, useMutation } from '@apollo/client';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlowBotDataItem } from '@modules/Automation/FlowBots/types';
import { SET_BOT, SET_BOT_STATUS, SET_RULE_ID, SET_RULE_STATUS } from '@store/actions/automation';
import { useDispatch } from 'react-redux';
import EcommerceRuleModal from '@modules/Automation/FlowBots/components/EcommerceRuleModal';
import { CREATE_ECOMMERCE_RULE_STATUS } from '@queries/Automation/mutation';

const useStyles = makeStyles((theme: any) => ({
   header: {
      borderBottom: '1px solid #D7D8D8',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1.1rem',
      padding: '15px 20px 20px 20px',
      height: '65px',
   },
}));

interface NavProps {
   setInfiniteData: React.Dispatch<
      React.SetStateAction<{
         hasMore: boolean;
         totalLength: number;
         data: FlowBotDataItem[];
      }>
   >;
   getInfiniteData: {
      hasMore: boolean;
      totalLength: number;
      data: FlowBotDataItem[];
   };
}

const Nav: React.FC<NavProps> = ({ setInfiniteData, getInfiniteData }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [showModal, setShowModal] = useState(false);
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   const history = useHistory();
   const dispatch = useDispatch();

   const closeModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = () => {
      setShowModal(true);
   };
   const [createRule] = useMutation(CREATE_ECOMMERCE_RULE_STATUS);
   const handleSubmit = async (name: string, trigger: string) => {
      try {
         await createRule({
            variables: {
               input: {
                  customer: person.customer._id,
                  title: name,
                  type: trigger,
               },
            },
            onCompleted: (res) => {
               const rule = res.createECommerceRules.data;
               dispatch({
                  type: SET_RULE_STATUS,
                  payload: rule.status === true ? 'published' : 'draft',
               });
               dispatch({ type: SET_RULE_ID, payload: rule._id });
               dispatch({ type: SET_BOT, payload: rule?.title });
               history.push(`/automation/e-commerce/${rule._id}`);
            },
         }).then((e) => {
            setInfiniteData({
               ...getInfiniteData,
               data: [e.data.createECommerceRules.data, ...getInfiniteData.data].flat(),
            });
         });
      } catch (error) {
         console.error('Error creating flow bot:', error);
      }
   };

   return (
      <div>
         <div className={classes.header}>
            <div style={{ marginTop: '5px' }}>{t.automation_e_commerce}</div>
            <ButtonOK
               label={t?.automation_flow_create_new_plus}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  opacity: getInfiniteData.data.length >= 1 ? 0.4 : '',
               }}
               disabled={getInfiniteData.data.length >= 1}
               onClick={handleOpenModal}
            />
         </div>

         <Modal open={showModal} onClose={closeModal} modalHeader={t.automation_e_commerce_new_flow}>
            <EcommerceRuleModal onSubmit={handleSubmit} closeModal={closeModal} isCreate={true} />
         </Modal>
      </div>
   );
};

export default Nav;

import { useCallback, useState } from 'react';

const useBoolean = (initialState: boolean) => {
   const [value, setValue] = useState(!!initialState);
   const toggle = useCallback(() => setValue((p) => !p), []);
   const on = useCallback(() => setValue(true), []);
   const off = useCallback(() => setValue(false), []);

   return { value, toggle, on, off };
};

export default useBoolean;

import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, CircularProgress } from '@material-ui/core';
import ButtonOK from '@components/ButtonOK/buttonOk';
import 'react-phone-input-2/lib/style.css';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm } from 'react-hook-form';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 700,
      padding: 20,
      borderRadius: 15,
      maxHeight: 700,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   iconSize: {
      fontSize: '5rem',
   },
   backButton: {
      color: '#748FA7',
      backgroundColor: '#fff',
      textTransform: 'none',
      width: 128,
      height: 40,
      fontSize: '1.1rem',
      border: '1px solid #748FA7',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const getInformationList = (t) => {
   return [
      t?.page_settings_modal_telegram_modal_step_information_list_item_botFather.replace(
         '@BotFather',
         `<a href='https://t.me/BotFather' target='_blank' rel='noopener noreferrer'>@BotFather</a>`,
      ),
      t?.page_settings_modal_telegram_modal_step_information_list_item_create,
      t?.page_settings_modal_telegram_modal_step_information_list_item_copy_api,
   ];
};
const getInformationListRefresh = (t) => {
   return [
      t?.page_settings_modal_telegram_modal_step_information_list_item_botFather.replace(
         '@BotFather',
         `<a href='https://t.me/BotFather' target='_blank' rel='noopener noreferrer'>@BotFather</a>`,
      ),
      t?.page_settings_modal_telegram_modal_step_information_list_item_refresh,
      t?.page_settings_modal_telegram_modal_step_information_list_item_copy_api,
   ];
};

const ModalContent = ({ _id, teamsData, isLoading, onSubmit, closeModal, ...props }) => {
   const classes = useStyles(props);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const informationList = getInformationList(t);

   const {
      handleSubmit,
      control,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         _id,
         name: '',
         teams: [],
         apiToken: '',
      },
   });

   return (
      <Grid>
         <Fragment>
            <Grid xs={9} item alignItems='flex-start'>
               {informationList.map((item, index) => (
                  <ModalLargeText key={index}>
                     <div style={{ display: 'flex', gap: '0.5rem' }}>
                        {index + 1}. <Typography variant='body1' dangerouslySetInnerHTML={{ __html: item }} />
                     </div>
                  </ModalLargeText>
               ))}
            </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
               <Grid container xs={12}>
                  {!_id && (
                     <SingleInput
                        label={t?.page_settings_typo_platforms_add_telegram_name_label}
                        placeholder={t?.page_settings_typo_platforms_add_whats_app_web_placeholder}
                        control={control}
                        name='name'
                        required={false}
                        rules={{
                           required: t?.please_fill_this_field,
                        }}
                        helperText={t?.page_settings_typo_platforms_add_telegram_description}
                     />
                  )}
                  <SingleInput
                     label={t?.page_settings_typo_platforms_add_telegram_token_label}
                     placeholder={t?.page_settings_typo_platforms_add_telegram_token_placeholder}
                     control={control}
                     name='apiToken'
                     required={false}
                     rules={{
                        required: t?.please_fill_this_field,
                     }}
                  />
                  {!_id && (
                     <SingleInput
                        label={t?.teams}
                        isSelect
                        isMulti
                        selectOptions={teamsData.map((team) => ({
                           label: team.teamName,
                           value: team._id,
                        }))}
                        autoHeight
                        name='teams'
                        control={control}
                        rules={{
                           required: t?.please_fill_this_field,
                        }}
                        editable
                        height={40}
                        placeholder={t?.teams}
                     />
                  )}

                  <ModalButtonField>
                     <Button
                        className={classes.modalButtons}
                        onClick={closeModal}
                        disabled={isLoading}
                        style={{
                           color: '#212121',
                           backgroundColor: '#eeee',
                           '&:hover': {
                              backgroundColor: '#e0e0e0',
                           },
                        }}
                     >
                        {t?.cancel}
                     </Button>
                     <ButtonOK
                        className={classes.modalButtons}
                        label={_id? t?.update : t?.comp_button_connect}
                        type='submit'
                        disabled={!isValid || isLoading}
                        startIcon={isLoading ? <CircularProgress size={20} /> : null}
                        style={{
                           color: 'white',
                           backgroundColor: '#157CFC',
                           '&:hover': {
                              backgroundColor: '#105CBB',
                           },
                           float: 'right',
                        }}
                     />
                  </ModalButtonField>
               </Grid>
            </form>
         </Fragment>
      </Grid>
   );
};

export default ModalContent;

/* eslint-disable react-hooks/exhaustive-deps */
import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useContext, useState } from 'react';
import { Node, getNodesBounds, useNodes, useReactFlow, useStoreApi } from 'reactflow';

import { useMutation } from '@apollo/client';
import {
   FlowNodeType,
   IFlowBlockField,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import {
   DELETE_FLOW_NODE_MUTATION,
   UPDATE_ASK_QUESTION_BLOCK_MUTATION,
   UPDATE_BLOCK_MUTATION,
   UPDATE_FLOW_NODE_MUTATION,
} from '@queries/Automation/mutation';
import { useEffect } from 'react';
import useDetachNodes from './useDetachNodes';
import { getId } from './utils';
import { RootStateOrAny, useSelector } from 'react-redux';
import DeleteFow from '@modules/Automation/FlowBots/components/DeleteFlow';
import { useSnackbar } from 'notistack';
import { generateRandomId } from '@helper/functions';

const useStyles = makeStyles(() => ({
   container: {
      height: '100%',
      width: '400px',
      backgroundColor: '#FFFFFF',
      marginTop: '-15px',
      marginLeft: '-15px',
   },
   groupNode: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      width: '100%',
      margin: 10,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

interface LeftPanelProps {
   nodestate: Node[];
   handlePaneClick: () => void;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setIsToldBarClose: React.Dispatch<React.SetStateAction<boolean>>;
   setToGroup: React.Dispatch<React.SetStateAction<string[]>>;
}

const SideBar: React.FC<LeftPanelProps> = ({
   handlePaneClick,
   nodestate,
   setContentNodes,
   setLeftNodes,
   setToGroup,
   setIsToldBarClose,
}) => {
   const classes = useStyles();
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const nodes = useNodes();
   const selectedNodes = nodes.filter(
      (node) =>
         node.selected &&
         !node.parentNode &&
         node.type !== FlowNodeType.Triggers &&
         node.type !== FlowNodeType.WelcomeMessage,
   );

   //Mutations after deleting a group
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [deleteTarget, setDeleteTarget] = useState<string | undefined>();
   const [deleteType, setDeleteType] = useState('');
   const id = clickedId;

   const [matchingHttpBlocks, setMatchingHttpBlocks] = useState<{ blockId: string; targets: string[] }[]>([]);
   const [filteredFlowBlocks, setFilteredFlowBlocks] = useState<IFlowBlockTemplate[]>([]);
   const [quickReplyBlocks, setQuickReplyBlocks] = useState<IFlowBlockTemplate[]>([]);
   const [infButtonBlocks, setInfButtonBlocks] = useState<IFlowBlockTemplate[]>([]);
   const [matchingBlocks, setMatchingBlocks] = useState<{ blockId: string; targets: string[] }[]>([]);

   const isCloseTB =
      matchingHttpBlocks.length === 0 &&
      filteredFlowBlocks.length === 0 &&
      quickReplyBlocks.length === 0 &&
      infButtonBlocks.length === 0 &&
      matchingBlocks.length === 0 &&
      selectedNodes.length === 0 &&
      deleteTarget?.length !== undefined;

   useEffect(() => {
      setIsToldBarClose(isCloseTB);
   }, [isCloseTB]);

   const [showPanel, setShowPanel] = useState(true);

   const { deleteElements } = useReactFlow();
   const { enqueueSnackbar } = useSnackbar();

   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlock = async (blockId: string, target: null) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  target: target,
               },
            },
            onCompleted: (res) => {
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, target: target };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const handleUpdateFlowBlockQuickReply = async (blockId: string, blockFields: IFlowBlockField[]) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: blockFields.map((field: any) => {
                     for (let [key, value] of Object.entries(field)) {
                        if (value == null) {
                           delete field[key];
                        }
                     }
                     return field;
                  }),
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // Update askquestion blocks mutation
   const [updateBlockAskQuestion] = useMutation(UPDATE_ASK_QUESTION_BLOCK_MUTATION);
   const handleUpdateFlowBlockAskQuestion = async (blockId: string, fieldName: string) => {
      try {
         await updateBlockAskQuestion({
            variables: {
               input: {
                  _id: blockId,
                  fieldIndex: 0,
                  fieldName: fieldName,
                  fieldValue: null,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlockField.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const nodesToDelete = nodes.filter((node) => node.parentNode === id);
   const selectedNodesToDelete = nodes.filter(
      (node) =>
         node.selected &&
         !node.parentNode &&
         node.type !== FlowNodeType.Triggers &&
         node.type !== FlowNodeType.WelcomeMessage,
   );
   const nodeLength = nodesToDelete.length;
   const selectedNodesToDeleteLength = selectedNodesToDelete.length;

   useEffect(() => {
      if (nodesToDelete.length > 0) {
         // Extract all ids from nodesToDelete
         const idsToDelete = nodesToDelete.map((node) => node.id);

         // Filter the flowBlocks where the target matches any id among nodesToDelete
         const filteredFlowBlocksQuickReply = nodestate.flatMap(
            (node) =>
               node?.data?.flowBlocks?.filter((flowBlock: IFlowBlockTemplate) =>
                  flowBlock?.fields?.some((field) =>
                     field?.quickReplies?.some(
                        (quickReply) => quickReply && idsToDelete.includes(quickReply.target || ''),
                     ),
                  ),
               ) || [],
         );

         // Update quickReplyBlocks with targets set to null
         const updatedQuickReplyBlocks = filteredFlowBlocksQuickReply.map((flowBlock: IFlowBlockTemplate) => {
            return {
               ...flowBlock,
               fields: flowBlock.fields?.map((field) => ({
                  ...field,
                  quickReplies: field.quickReplies?.map((quickReply) => ({
                     ...quickReply,
                     target: quickReply && idsToDelete.includes(quickReply.target || '') ? null : quickReply.target,
                  })),
               })),
            };
         });

         // Update quickReplyBlocks state
         setQuickReplyBlocks((prev) => [...prev, ...updatedQuickReplyBlocks]);

         //For Informative button --------------------------------------------

         // Extract all ids from nodesToDelete
         const idsToDeleteInf = nodesToDelete.map((node) => node.id);

         // Filter the flowBlocks where the target matches any id among nodesToDelete
         const filteredFlowBlocksInfBtn = nodestate.flatMap(
            (node) =>
               node?.data?.flowBlocks?.filter((flowBlock: IFlowBlockTemplate) =>
                  flowBlock?.fields?.some((field) =>
                     field?.informativeButtons?.some(
                        (infBtn) => infBtn && idsToDeleteInf.includes(infBtn.target || ''),
                     ),
                  ),
               ) || [],
         );

         // Update quickReplyBlocks with targets set to null
         const updatedInfButtonBlocks = filteredFlowBlocksInfBtn.map((flowBlock: IFlowBlockTemplate) => {
            return {
               ...flowBlock,
               fields: flowBlock.fields?.map((field) => ({
                  ...field,
                  informativeButtons: field.informativeButtons?.map((infBtn) => ({
                     ...infBtn,
                     target: infBtn && idsToDeleteInf.includes(infBtn.target || '') ? null : infBtn.target,
                  })),
               })),
            };
         });

         // Update quickReplyBlocks state
         setInfButtonBlocks((prev) => [...prev, ...updatedInfButtonBlocks]);
      }
   }, [nodeLength]);

   useEffect(() => {
      if (selectedNodesToDeleteLength > 0) {
         // Extract all ids from nodesToDelete
         const idsToDelete = selectedNodesToDelete.map((node) => node.id);

         // Filter the flowBlocks where the target matches any id among nodesToDelete
         const filteredFlowBlocksQuickReply = nodestate.flatMap(
            (node) =>
               node?.data?.flowBlocks?.filter((flowBlock: IFlowBlockTemplate) =>
                  flowBlock?.fields?.some((field) =>
                     field?.quickReplies?.some(
                        (quickReply) => quickReply && idsToDelete.includes(quickReply.target || ''),
                     ),
                  ),
               ) || [],
         );

         // Update quickReplyBlocks with targets set to null
         const updatedQuickReplyBlocks = filteredFlowBlocksQuickReply.map((flowBlock: IFlowBlockTemplate) => {
            return {
               ...flowBlock,
               fields: flowBlock.fields?.map((field) => ({
                  ...field,
                  quickReplies: field.quickReplies?.map((quickReply) => ({
                     ...quickReply,
                     target: quickReply && idsToDelete.includes(quickReply.target || '') ? null : quickReply.target,
                  })),
               })),
            };
         });

         // Update quickReplyBlocks state
         setQuickReplyBlocks((prev) => [...prev, ...updatedQuickReplyBlocks]);

         //For Informative button

         // Extract all ids from nodesToDelete
         const idsToDeleteInf = selectedNodesToDelete.map((node) => node.id);

         // Filter the flowBlocks where the target matches any id among nodesToDelete
         const filteredFlowBlocksInfBtn = nodestate.flatMap(
            (node) =>
               node?.data?.flowBlocks?.filter((flowBlock: IFlowBlockTemplate) =>
                  flowBlock?.fields?.some((field) =>
                     field?.informativeButtons?.some(
                        (infBtn) => infBtn && idsToDeleteInf.includes(infBtn.target || ''),
                     ),
                  ),
               ) || [],
         );

         // Update quickReplyBlocks with targets set to null
         const updatedInfButtonBlocks = filteredFlowBlocksInfBtn.map((flowBlock: IFlowBlockTemplate) => {
            return {
               ...flowBlock,
               fields: flowBlock.fields?.map((field) => ({
                  ...field,
                  informativeButtons: field.informativeButtons?.map((infBtn) => ({
                     ...infBtn,
                     target: infBtn && idsToDeleteInf.includes(infBtn.target || '') ? null : infBtn.target,
                  })),
               })),
            };
         });

         // Update quickReplyBlocks state
         setInfButtonBlocks((prev) => [...prev, ...updatedInfButtonBlocks]);
      }
   }, [selectedNodesToDeleteLength]);

   const getMatch = (id: string) => {
      // Filter the flowBlocks where the target matches the current Node Id
      const filteredFlowBlocks = nodestate
         .map((item) => item?.data?.flowBlocks)
         .flat()
         .filter((flowBlock) => flowBlock?.target === id);
      setFilteredFlowBlocks((prev) => [...prev, ...filteredFlowBlocks]);

      // Get all Http blocks where the current id is used as target source handle
      nodestate
         .map((item) => item?.data?.flowBlocks)
         .flat()
         .forEach((flowBlock) => {
            if (flowBlock?.fields) {
               const matchingTargets = flowBlock.fields.filter(
                  (field: { targetOnSuccess: string; targetOnFailure: string }) => {
                     return field.targetOnSuccess === id || field.targetOnFailure === id;
                  },
               );

               if (matchingTargets.length > 0) {
                  const targets: string[] = [];

                  if (matchingTargets.some((target: { targetOnFailure: string }) => target.targetOnFailure === id)) {
                     targets.push('targetOnFailure');
                  }

                  if (matchingTargets.some((target: { targetOnSuccess: string }) => target.targetOnSuccess === id)) {
                     targets.push('targetOnSuccess');
                  }

                  setMatchingHttpBlocks((prev) => [...prev, { blockId: flowBlock._id, targets: targets }]);
               }
            }
         });

      //setMatchingBlocks([]);
      // Get all askquestion blocks where the current id is used as target source handle
      nodestate
         .map((item) => item?.data?.flowBlocks)
         .flat()
         .forEach((flowBlock) => {
            if (flowBlock?.fields) {
               const matchingTargets = flowBlock.fields.filter(
                  (field: {
                     target_on_reply: string;
                     target_on_not_reply: string;
                     target_on_invalid_input: string;
                  }) => {
                     return (
                        field.target_on_reply === id ||
                        field.target_on_not_reply === id ||
                        field.target_on_invalid_input === id
                     );
                  },
               );

               if (matchingTargets.length > 0) {
                  const targets: string[] = [];

                  if (matchingTargets.some((target: { target_on_reply: string }) => target.target_on_reply === id)) {
                     targets.push('target_on_reply');
                  }

                  if (
                     matchingTargets.some(
                        (target: { target_on_not_reply: string }) => target.target_on_not_reply === id,
                     )
                  ) {
                     targets.push('target_on_not_reply');
                  }

                  if (
                     matchingTargets.some(
                        (target: { target_on_invalid_input: string }) => target.target_on_invalid_input === id,
                     )
                  ) {
                     targets.push('target_on_invalid_input');
                  }
                  setMatchingBlocks((prev) => [...prev, { blockId: flowBlock._id, targets: targets }]);
               }
            }
         });
   };

   let nodeList: Node[] = nodes;
   const handleUpdateFlowBlockHttpRequest = async (
      blockId: string,
      fieldName: string,
      targetSize: number,
      blockSize: number,
   ) => {
      try {
         const foundField = nodeList.find((node) =>
            node.data?.flowBlocks?.some((block: IFlowBlockTemplate) => block._id === blockId),
         )?.data.flowBlocks[0]?.fields[0];

         let fields = [];
         if (fieldName === 'targetOnSuccess') {
            fields = [
               {
                  targetOnSuccess: null,
                  targetOnFailure: targetSize === 2 || blockSize === 2 ? null : foundField.targetOnFailure,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestHeaders: foundField.requestHeaders,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         } else {
            fields = [
               {
                  targetOnFailure: null,
                  targetOnSuccess: targetSize === 2 || blockSize === 2 ? null : foundField.targetOnSuccess,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         }
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   useEffect(() => {
      const fetchData = async () => {
         if (deleteTarget) {
            const target = null;
            filteredFlowBlocks.forEach((block) => {
               handleUpdateFlowBlock(block._id, target);
            });

            if (quickReplyBlocks.length > 0) {
               quickReplyBlocks.forEach((block) => {
                  handleUpdateFlowBlockQuickReply(block._id, block.fields);
               });
            }
            if (infButtonBlocks.length > 0) {
               infButtonBlocks.forEach((block) => {
                  handleUpdateFlowBlockQuickReply(block._id, block.fields);
               });
            }
         }

         if (matchingBlocks) {
            await Promise.all(
               matchingBlocks.map(async (block) => {
                  await Promise.all(
                     block.targets.map(async (target) => {
                        await handleUpdateFlowBlockAskQuestion(block.blockId, target);
                     }),
                  );
               }),
            );
         }

         if (matchingHttpBlocks) {
            await Promise.all(
               matchingHttpBlocks.map(async (block) => {
                  const targetSize = block.targets.length;
                  const blockSize = matchingHttpBlocks.filter((bl) => bl.blockId === block.blockId).length;
                  await Promise.all(
                     block.targets.map(async (target) => {
                        await handleUpdateFlowBlockHttpRequest(block.blockId, target, targetSize, blockSize);
                     }),
                  );
               }),
            );
         }
      };
      fetchData();
      return () => {
         //setDeleteTarget(undefined);
         setFilteredFlowBlocks([]);
         setQuickReplyBlocks([]);
         setInfButtonBlocks([]);
         setMatchingBlocks([]);
         setMatchingHttpBlocks([]);
      };
   }, [deleteTarget]);

   const [deleteFlowNode] = useMutation(DELETE_FLOW_NODE_MUTATION);
   const handleDeleteFlowNode = async (itemId: string) => {
      try {
         await deleteFlowNode({
            variables: {
               input: {
                  _id: itemId,
               },
            },
            onCompleted: (res) => {
               setContentNodes((prev) => prev.filter((node) => node.id !== itemId));
               setLeftNodes((prev) => prev.filter((node) => node.id !== itemId));
               setDeleteTarget(generateRandomId());
            },
         });
      } catch (error) {
         console.error('Error deleting node:', error);
      }
   };

   const handleDeleteNode = useCallback(
      (idToDelete: string) => {
         handleDeleteFlowNode(idToDelete);
      },
      [id, deleteElements],
   );

   const [updateFlowNode] = useMutation(UPDATE_FLOW_NODE_MUTATION);
   const handleUpdateFlowNode = async (itemId: string, groupId: string | null) => {
      try {
         await updateFlowNode({
            variables: {
               input: {
                  _id: itemId,
                  groupId: groupId,
               },
            },
         });
      } catch (error) {
         console.error('Error updating node:', error);
      }
   };

   // For creating group
   const store = useStoreApi();
   const selectedNodeIds = selectedNodes.map((node) => node.id);
   const isVisible = selectedNodeIds.length > 1 || deleteTarget !== undefined;

   const padding = 25;

   const onGroup = () => {
      store.getState().unselectNodesAndEdges({ nodes });
      handlePaneClick();
      const rectOfNodes = getNodesBounds(selectedNodes);
      const groupId = getId('group');
      const parentPosition = {
         x: rectOfNodes.x,
         y: rectOfNodes.y,
      };
      const groupNode = {
         id: groupId,
         type: 'group',
         position: {
            x: rectOfNodes.x - padding,
            y: rectOfNodes.y - padding,
         },
         data: {},
      };

      const nextNodes: Node[] = nodes.map((node) => {
         if (selectedNodeIds.includes(node.id)) {
            return {
               ...node,
               position: {
                  x: node.position.x - parentPosition.x + padding,
                  y: node.position.y - parentPosition.y + padding,
               },
               extent: 'parent',
               parentNode: groupId,
            };
         }

         return node;
      });

      store.getState().resetSelectedElements();
      store.setState({ nodesSelectionActive: false });

      if (selectedNodes.some((node) => node.id.startsWith('group_'))) {
         const groupCount = selectedNodes.filter((node) => node.id.startsWith('group_')).length;
         if (groupCount > 1) {
            setShowPanel(false);
            enqueueSnackbar(t.automation_group_warning, {
               variant: 'error',
               autoHideDuration: 2000,
            });
         } else {
            const selectedParent = selectedNodes.find((node) => node.id.startsWith('group_'));
            if (selectedParent) {
               const previousChilds = nodes.filter((node) => node.parentNode === selectedParent.id).map((pn) => pn.id);

               const newChilds = selectedNodes.filter((node) => !node.id.startsWith('group_')).map((pn) => pn.id);
               const nod = [...previousChilds, ...newChilds];
               setToGroup(nod);

               detachNodes(previousChilds, selectedParent.id, setContentNodes, setLeftNodes);
            }
         }
      } else {
         setContentNodes([groupNode, ...nextNodes]);
         setLeftNodes([groupNode, ...nextNodes]);
         selectedNodeIds.forEach((sn) => handleUpdateFlowNode(sn, groupId));
      }
   };

   useEffect(() => {
      if (selectedNodes.length > 1 && selectedNodes.some((node) => node.id.startsWith('group_'))) {
         setShowPanel(false);
      } else {
         setShowPanel(true);
      }
   }, [selectedNodes]);

   // For deleting and detach group
   const detachNodes = useDetachNodes();

   const onDelete = async () => {
      deleteElements({ nodes: [{ id }] });
      const nodesToDelete = nodes.filter((node) => node.parentNode === id);
      if (nodesToDelete.length > 0) {
         for (let i = 0; i < nodesToDelete.length; i++) {
            setDeleteTarget(generateRandomId());
            getMatch(nodesToDelete[i].id);
            handleDeleteNode(nodesToDelete[i].id);
         }
      }
   };

   const onDeleteSelectedNodes = async () => {
      const nodesToDelete = nodes.filter(
         (node) =>
            node.selected &&
            !node.parentNode &&
            node.type !== FlowNodeType.Triggers &&
            node.type !== FlowNodeType.WelcomeMessage,
      );
      if (nodesToDelete.length > 0) {
         for (let i = 0; i < nodesToDelete.length; i++) {
            setDeleteTarget(generateRandomId());
            getMatch(nodesToDelete[i].id);
            handleDeleteNode(nodesToDelete[i].id);
            const id = nodesToDelete[i].id;
            deleteElements({ nodes: [{ id }] });
            setIsToldBarClose(true);
         }
      }
   };

   const onDetach = () => {
      handlePaneClick();
      const childNodeIds = Array.from(store.getState().nodeInternals.values())
         .filter((n) => n.parentNode === id)
         .map((n) => n.id);
      detachNodes(childNodeIds, id, setContentNodes, setLeftNodes);
      childNodeIds.forEach((sn) => handleUpdateFlowNode(sn, null));
   };

   return (
      <Grid container className={classes.container} onClick={(e) => e.preventDefault()}>
         {clickedId.startsWith('group_') && showPanel && (
            <div className={classes.groupNode}>
               <ButtonOK
                  label={t?.automation_ungroup_steps}
                  onClick={onDetach}
                  className={classes.modalButtons}
                  style={{
                     color: '#157CFC',
                     backgroundColor: '#FFF',
                     border: '1px solid #157CFC',
                  }}
               />
               <ButtonOK
                  label={t?.automation_delete_steps}
                  onClick={() => {
                     setShowDeleteModal(true);
                     setDeleteType('group');
                  }}
                  className={classes.modalButtons}
                  style={{
                     color: '#ef5350',
                     backgroundColor: '#FFF',
                     border: '1px solid #ef5350',
                  }}
               />
            </div>
         )}
         {isVisible && (
            <div className={classes.groupNode}>
               <ButtonOK
                  label={t?.automation_group_steps}
                  onClick={() => onGroup()}
                  className={classes.modalButtons}
                  style={{
                     color: '#157CFC',
                     backgroundColor: '#FFF',
                     border: '1px solid #157CFC',
                  }}
               />
               <ButtonOK
                  label={t?.automation_delete_steps}
                  onClick={() => {
                     setShowDeleteModal(true);
                     setDeleteType('selected');
                  }}
                  className={classes.modalButtons}
                  style={{
                     color: '#ef5350',
                     backgroundColor: '#FFF',
                     border: '1px solid #ef5350',
                  }}
               />
            </div>
         )}
         <DeleteFow
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            onComplate={deleteType === 'group' ? onDelete : onDeleteSelectedNodes}
            text={t.automation_flow_delete_group_step_warning}
         />
      </Grid>
   );
};

export default SideBar;

import React from 'react';
const WhatsAppBusiness = ({ selected, color }) => {
   const color1 = color || (selected ? '#00d954' : '#c7cdd6');
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         enableBackground='new 0 0 32 32'
         viewBox='0 0 32 32'
         transform='scale(1.25 1.25)'
         style={{ transform: 'scale(1.25)', width: '100%' }}
      >
         <rect width='539' height='176' x='-330' y='-20' display='none' />
         <path
            fill={color1}
            d='M17,4C10.383,4,5,9.383,5,16c0,2.003,0.503,3.974,1.473,5.754l-2.33,5.827c-0.148,0.371-0.062,0.795,0.222,1.078c0.191,0.191,0.447,0.293,0.707,0.293c0.125,0,0.251-0.023,0.371-0.072l5.837-2.335C13.029,27.499,14.997,28,17,28c6.617,0,12-5.383,12-12S23.617,4,17,4z M17,26c-1.812,0-3.587-0.492-5.135-1.423c-0.276-0.166-0.613-0.188-0.906-0.063l-4.094,1.644l1.522-3.808l0.109-0.241c0.121-0.269,0.118-0.578-0.009-0.844c-0.027-0.058-0.063-0.124-0.103-0.19C7.479,19.541,7,17.786,7,16c0-5.514,4.486-10,10-10s10,4.486,10,10S22.514,26,17,26z M21.664,13.189C21.509,11.401,19.898,10,18,10h-4c-0.553,0-1,0.448-1,1v10c0,0.552,0.447,1,1,1h4c1.898,0,3.509-1.401,3.664-3.189c0.085-0.979-0.245-1.954-0.906-2.675c-0.042-0.046-0.086-0.091-0.131-0.135c0.045-0.044,0.089-0.089,0.131-0.135C21.419,15.144,21.749,14.168,21.664,13.189z M18,20h-3v-3h3.178c0.426,0,0.818,0.173,1.106,0.487c0.287,0.313,0.425,0.722,0.388,1.15C19.606,19.389,18.856,20,18,20z M19.284,14.513C18.996,14.827,18.604,15,18.178,15H15v-3h3c0.856,0,1.606,0.611,1.672,1.363C19.709,13.791,19.571,14.2,19.284,14.513z'
         />
      </svg>
   );
};

export default WhatsAppBusiness;

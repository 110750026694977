import React, { useState, useEffect, useContext, memo } from 'react';
import { Handle, Node, Position, useReactFlow } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteFow from '@modules/Automation/FlowBots/components/DeleteFlow';
import { LanguageContext } from '@helper/locale/langContext';
import LinkIcon from '@mui/icons-material/Link';
import {
   FileBlockProps,
   FlowBlockType,
   IFlowBlockField,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { Box, Grid } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import {
   CREATE_FLOW_NODE_MUTATION,
   DELETE_FLOW_NODE_MUTATION,
   UPDATE_ASK_QUESTION_BLOCK_MUTATION,
   UPDATE_BLOCK_MUTATION,
   UPDATE_FLOW_NODE_MUTATION,
} from '@queries/Automation/mutation';
import SmartButtonIcon from '@mui/icons-material/SmartButton';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SET_CLICKED_NODE_ID } from '@store/actions/automation';
import useDetachNodes from '../../App/DynamicGrouping/useDetachNodes';
import UngroupIcon from '../../../icons/UngroupIcon.svg';

const useStyles = makeStyles(() => ({
   container: {
      position: 'relative',
      display: 'inline-block',
      paddingTop: 5,
   },
   iconDiv: {
      display: 'flex',
      borderRadius: 5,
      backgroundColor: '#FFFFFF',
   },
   innerIcons: {
      padding: '2px 5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease',
      backgroundColor: 'transparent',
      '&:hover': {
         backgroundColor: '#E8E8E8',
      },
   },
   sendIcon: {
      width: 20,
      height: 20,
      margin: '0 5px 5px 5px',
   },
   customHeader: {
      display: 'flex',
      borderBottom: '0.1px solid #f2f2f2',
   },
   addText: {
      padding: '10px',
      margin: '5px',
      textAlign: 'center',
      borderRadius: 2,
      fontSize: 8,
      color: '#AFAFAF',
      backgroundColor: '#F7F7FA',
   },
   nodeContainer: {
      border: '1px solid #ffffff',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   InvalidNodeContainer: {
      border: '1px solid #FF7474',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   nodeContainerHovered: {
      border: '1px solid #157cfc',
      background: 'rgba(255, 255, 255, 0.6)',
   },
   footer: {
      borderTop: ' 0.1px solid #f2f2f2',
      height: '15px',
      position: 'relative',
   },
   handleBlock: {
      position: 'absolute',
      top: '10px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#fff',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleBlockNotUsed: {
      position: 'absolute',
      top: '10px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#fff',
      width: '8px',
      height: '8px',
      border: '1px solid #FEBC52',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleBlockQR: {
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#C3DDFF',
      width: '8px',
      height: '8px',
      border: '1px solid #147BFB',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleEdgeConnectedQR: {
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#147BFB',
      width: '8px',
      height: '8px',
      border: '1px solid #147BFB',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handlePath: {
      backgroundColor: '#147BFB',
      marginTop: '2px',
      position: 'absolute',
      right: -16,
      width: '8px',
      height: '8px',
      border: '1px solid #147BFB',
   },

   backPathGroup: {
      display: 'flex',
      gap: '5px',
      color: '#147BFB',
      width: '8px',
      height: '8px',
   },

   backPath: { color: '#147BFB', position: 'absolute', right: 0, marginBottom: '0.5px' },
   handleEdgeConnected: {
      position: 'absolute',
      top: '10px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#ADADAD',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleEdgeNotUsedConnected: {
      position: 'absolute',
      top: '10px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#FEBC52',
      width: '8px',
      height: '8px',
      border: '1px solid #FEBC52',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleTarget: {
      backgroundColor: '#fff',
      width: '5px',
      height: '5px',
      top: 14,
      marginLeft: '5px',
   },
   rawText: {
      backgroundColor: '#F7F7FA',
      borderRadius: '5px',
      margin: '0 5px',
   },
   textarea: {
      backgroundColor: 'transparent',
      padding: '5px',
      fontSize: 7,
      border: 'none',
      outline: 'none',
      resize: 'none',
      height: '100%',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      display: 'block',
      maxWidth: '100%',
   },
   preview: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      textAlign: 'center',
      borderRadius: '5px',
      margin: '0 5px',
      padding: '12px',
      fontSize: 7,
      border: '0.1px solid #DDDDDD',
   },
   previewImage: {
      margin: '0 5px',
      display: 'flex',
      flexDirection: 'column',
   },
   iconBox: {
      padding: '2px 3px',
      margin: '0 5px 5px 5px',
      borderRadius: '3px',
      display: 'flex',
      justifyContent: 'center',
   },
   blocks: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 5px',
      fontSize: '7px',
   },
   reply: {
      display: 'flex',
      justifyContent: 'space-between',
      position: 'relative',
      borderRadius: '5px',
      background: '#C3DDFF',
      padding: '5px',
      width: 'max-content',
      //maxWidth: '135px',
      textAlign: 'left',
      paddingRight: '20px',
   },
   boxTextInfinteBtn: {
      position: 'relative',
      width: '100%',
      overflow: 'hidden',
   },
   btn: {
      width: '100%',
      borderRadius: '5px',
      position: 'relative',
      background: '#ffffff',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '5px',
      //maxWidth: '135px',
      cursor: 'pointer',
   },
}));

interface SendButtonMessageNodeProps {
   id: string;
   isConnectable: boolean;
   data: {
      clickedNodeId: string;
      title: string;
      isValidNode: Boolean;
      isCreation: Boolean;
      flowBlocks: IFlowBlockTemplate[];
      setFileBlock: React.Dispatch<React.SetStateAction<FileBlockProps[]>>;
      setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
      setHandleToUpdate: React.Dispatch<React.SetStateAction<string>>;
      setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
      setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
      setIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
      setHandleIndexToUpdate: React.Dispatch<React.SetStateAction<number>>;
      setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
      setToGroup: React.Dispatch<React.SetStateAction<string[]>>;
      setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   };
}

const SendButtonMessageNode: React.FC<SendButtonMessageNodeProps> = ({ id, isConnectable, data }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const {
      title,
      clickedNodeId,
      flowBlocks,
      setIsNewNode,
      setClickedHandleId,
      setClickedNodeId,
      isValidNode,
      setIsDraggable,
      setHandleIndexToUpdate,
      isCreation,
      setLeftNodes,
      setContentNodes,
      setHandleToUpdate,
      setToGroup,
   } = data;
   const reactFlow = useReactFlow();
   const nodeList = reactFlow.getNodes();
   const [deleteTarget, setDeleteTarget] = useState(false);
   const [isDelete, setIsDelete] = useState(false);
   const [matchingBlocks, setMatchingBlocks] = useState<{ blockId: string; targets: string[] }[]>([]);
   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);

   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlock = async (blockId: string, target: null) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  target: target,
               },
            },
            onCompleted: (res) => {
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, target: target };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // Filter the flowBlocks where the target matches the current Node Id
   const filteredFlowBlocks = nodeList
      .map((item) => item?.data?.flowBlocks)
      .flat()
      .filter((flowBlock) => flowBlock?.target === id);

   const [quickReplyBlocks, setQuickReplyBlocks] = useState<IFlowBlockTemplate[]>([]);
   const [infButtonBlocks, setInfButtonBlocks] = useState<IFlowBlockTemplate[]>([]);

   useEffect(() => {
      // Filter the flowBlocks where the target matches the current Node Id in the quick reply blocks
      const filteredFlowBlocksQuickReply = nodeList.flatMap(
         (node) =>
            node?.data?.flowBlocks?.filter((flowBlock: IFlowBlockTemplate) =>
               flowBlock?.fields?.some((field) => field?.quickReplies?.some((quickReply) => quickReply?.target === id)),
            ) || [],
      );

      // Update quickReplyBlocks with targets set to null
      const updatedQuickReplyBlocks = filteredFlowBlocksQuickReply.map((flowBlock) => {
         return {
            ...flowBlock,
            fields: flowBlock.fields?.map((field: IFlowBlockField) => ({
               ...field,
               quickReplies: field.quickReplies?.map((quickReply) => ({
                  ...quickReply,
                  target: quickReply.target === id ? null : quickReply.target,
               })),
            })),
         };
      });

      setQuickReplyBlocks(updatedQuickReplyBlocks);
   }, [flowBlocks]);

   useEffect(() => {
      // Filter the flowBlocks where the target matches the current Node Id in the informative button blocks
      const filteredFlowBlocksInfButton = nodeList.flatMap(
         (node) =>
            node?.data?.flowBlocks?.filter((flowBlock: IFlowBlockTemplate) =>
               flowBlock?.fields?.some((field) =>
                  field?.informativeButtons?.some((infButton) => infButton?.target === id),
               ),
            ) || [],
      );

      // Update informativeButtons with targets set to null
      const updatedInfButtonBlocks = filteredFlowBlocksInfButton.map((flowBlock) => {
         return {
            ...flowBlock,
            fields: flowBlock.fields?.map((field: IFlowBlockField) => ({
               ...field,
               informativeButtons: field.informativeButtons?.map((infButton) => ({
                  ...infButton,
                  target: infButton.target === id ? null : infButton.target,
               })),
            })),
         };
      });

      setInfButtonBlocks(updatedInfButtonBlocks);
   }, [flowBlocks]);

   const handleUpdateFlowBlockQuickReply = async (blockId: string, blockFields: IFlowBlockField[]) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: blockFields.map((field: any) => {
                     for (let [key, value] of Object.entries(field)) {
                        if (value == null) {
                           delete field[key];
                        }
                     }
                     return field;
                  }),
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // Update askquestion blocks mutation
   const [updateBlockAskQuestion] = useMutation(UPDATE_ASK_QUESTION_BLOCK_MUTATION);
   const handleUpdateFlowBlockAskQuestion = async (blockId: string, fieldName: string) => {
      try {
         await updateBlockAskQuestion({
            variables: {
               input: {
                  _id: blockId,
                  fieldIndex: 0,
                  fieldName: fieldName,
                  fieldValue: null,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlockField.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   useEffect(() => {
      setMatchingBlocks([]);

      // Get all askquestion blocks wherethe curen id is used as target source handle
      nodeList
         .map((item) => item?.data?.flowBlocks)
         .flat()
         .forEach((flowBlock) => {
            if (flowBlock?.fields) {
               const matchingTargets = flowBlock.fields.filter(
                  (field: {
                     target_on_reply: string;
                     target_on_not_reply: string;
                     target_on_invalid_input: string;
                  }) => {
                     return (
                        field.target_on_reply === id ||
                        field.target_on_not_reply === id ||
                        field.target_on_invalid_input === id
                     );
                  },
               );

               if (matchingTargets.length > 0) {
                  const targets: string[] = [];

                  if (matchingTargets.some((target: { target_on_reply: string }) => target.target_on_reply === id)) {
                     targets.push('target_on_reply');
                  }

                  if (
                     matchingTargets.some(
                        (target: { target_on_not_reply: string }) => target.target_on_not_reply === id,
                     )
                  ) {
                     targets.push('target_on_not_reply');
                  }

                  if (
                     matchingTargets.some(
                        (target: { target_on_invalid_input: string }) => target.target_on_invalid_input === id,
                     )
                  ) {
                     targets.push('target_on_invalid_input');
                  }

                  setMatchingBlocks((prev) => [...prev, { blockId: flowBlock._id, targets: targets }]);
               }
            }
         });
   }, [clickedNodeId]);

   const handleUpdateFlowBlockHttpRequest = async (blockId: string, fieldName: string) => {
      try {
         const foundField = nodeList.find((node) =>
            node.data?.flowBlocks?.some((block: IFlowBlockTemplate) => block._id === blockId),
         )?.data.flowBlocks[0]?.fields[0];

         let fields = [];
         if (fieldName === 'targetOnSuccess') {
            fields = [
               {
                  targetOnSuccess: null,
                  targetOnFailure: foundField.targetOnFailure,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         } else {
            fields = [
               {
                  targetOnFailure: null,
                  targetOnSuccess: foundField.targetOnSuccess,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         }
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const [matchingHttpBlocks, setMatchingHttpBlocks] = useState<{ blockId: string; targets: string[] }[]>([]);
   useEffect(() => {
      setMatchingHttpBlocks([]);

      // Get all askquestion blocks wherethe curen id is used as target source handle
      nodeList
         .map((item) => item?.data?.flowBlocks)
         .flat()
         .forEach((flowBlock) => {
            if (flowBlock?.fields) {
               const matchingTargets = flowBlock.fields.filter(
                  (field: { targetOnSuccess: string; targetOnFailure: string }) => {
                     return field.targetOnSuccess === id || field.targetOnFailure === id;
                  },
               );

               if (matchingTargets.length > 0) {
                  const targets: string[] = [];

                  if (matchingTargets.some((target: { targetOnFailure: string }) => target.targetOnFailure === id)) {
                     targets.push('targetOnFailure');
                  }

                  if (matchingTargets.some((target: { targetOnSuccess: string }) => target.targetOnSuccess === id)) {
                     targets.push('targetOnSuccess');
                  }

                  setMatchingHttpBlocks((prev) => [...prev, { blockId: flowBlock._id, targets: targets }]);
               }
            }
         });
   }, [clickedNodeId]);

   useEffect(() => {
      if (deleteTarget) {
         const target = null;
         filteredFlowBlocks.forEach((block) => {
            handleUpdateFlowBlock(block._id, target);
         });

         if (quickReplyBlocks.length > 0) {
            quickReplyBlocks.forEach((block) => {
               handleUpdateFlowBlockQuickReply(block._id, block.fields);
            });
         }

         if (infButtonBlocks.length > 0) {
            infButtonBlocks.forEach((block) => {
               handleUpdateFlowBlockQuickReply(block._id, block.fields);
            });
         }

         if (matchingHttpBlocks) {
            matchingHttpBlocks.forEach((block) => {
               block.targets.forEach((target) => {
                  handleUpdateFlowBlockHttpRequest(block.blockId, target);
               });
            });
         }
      }

      if (matchingBlocks) {
         matchingBlocks.forEach((block) => {
            block.targets.forEach((target) => {
               handleUpdateFlowBlockAskQuestion(block.blockId, target);
            });
         });
      }

      return setDeleteTarget(false);
   }, [deleteTarget]);

   const [deleteFlowNode] = useMutation(DELETE_FLOW_NODE_MUTATION);
   const handleDeleteFlowNode = async (itemId: String) => {
      setDeleteTarget(true);
      setIsDelete(true);

      try {
         await deleteFlowNode({
            variables: {
               input: {
                  _id: itemId,
               },
            },
            onCompleted: () => {
               setLeftNodes((prev) => prev.filter((node) => node.id !== itemId));
               setContentNodes((prev) => prev.filter((node) => node.id !== itemId));

               deleteElements({ nodes: [{ id }] });
               setIsDelete(false);
            },
         });
      } catch (error) {
         console.error('Error deleting node:', error);
      }
   };

   // delete node
   const { deleteElements } = useReactFlow();

   const [isHover, setIsHover] = useState(false);

   const [blocks, setBlocks] = useState<IFlowBlockTemplate[]>([]);
   useEffect(() => {
      setBlocks(flowBlocks);
   }, [flowBlocks]);

   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   useEffect(() => {
      setIsHover(id === clickedId);
   }, [data, id, clickedId]);

   const limitedTitle = title.length > 21 ? `${title.slice(0, 19)}...` : title;
   const fieldHasValue = flowBlocks.some((item) => item.fields.some((field) => field.value && field.value.length > 0));

   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         let numRows = 0;
         let consecutiveChars = 0;

         for (let i = 0; i < content.length; i++) {
            if (content[i] !== '\n') {
               consecutiveChars++;
               if (consecutiveChars === 34) {
                  numRows++;
                  consecutiveChars = 0;
               }
            } else {
               consecutiveChars = 0;
            }
         }

         const enters = (content.match(/\n/g) || []).length;

         numRows += enters;

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: numRows + 2, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };

   const handleMouseOnHandle = (id: string, handleIndex: number) => {
      setClickedHandleId(id);
      setHandleIndexToUpdate(handleIndex);
   };

   const isQRFalse = blocks.some((block, index) => {
      return (
         (block?.type === FlowBlockType.SendQuickReplyMessage ||
            (block?.type === FlowBlockType.SendInformativeButtonMessage &&
               block.fields[0]?.informativeButtons &&
               block?.fields[0]?.informativeButtons[0]?.type === 'quickReply')) &&
         !block?.fields[0]?.isButtonRequired
      );
   });

   const isInfBtn = blocks.some((block, index) => {
      return (
         block?.type === FlowBlockType.SendInformativeButtonMessage &&
         block.fields[0]?.informativeButtons &&
         block?.fields[0]?.informativeButtons[0]?.type === 'url'
      );
   });

   const [isDuplicating, setIsDuplicating] = useState<boolean>(false);
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   const [createFlowNode] = useMutation(CREATE_FLOW_NODE_MUTATION);

   const handleCreateNode = async () => {
      setIsDuplicating(true);
      try {
         const currentNode = nodeList.find((node) => node.id === id) as any;
         const currentBlocks = currentNode.data?.flowBlocks.map((current: any) => ({
            flowBlockTemplateId: current.templateId,
            channelType: current.channelType,
            fields: current.fields.map((field: any) => {
               const filteredField = Object.fromEntries(
                  Object.entries(field).map(([key, value]) => {
                     // Exclude specific keys and values
                     const excludeKeys = [
                        'target_on_reply',
                        'target_on_not_reply',
                        'target_on_invalid_input',
                        'targetOnSuccess',
                        'targetOnFailure',
                     ];

                     if (excludeKeys.includes(key)) {
                        return [key, value];
                     }

                     // Exclude 'target' key from informativeButtons and quickReplies arrays
                     if (key === 'informativeButtons' && Array.isArray(value)) {
                        const updatedButtons = value.map((button) => {
                           if (button && button.target) {
                              const { target, ...buttonWithoutTarget } = button;
                              return buttonWithoutTarget;
                           }
                           return button;
                        });
                        return [key, updatedButtons];
                     }

                     if (key === 'quickReplies' && Array.isArray(value)) {
                        const updatedReplies = value.map((reply) => {
                           if (reply && reply.target) {
                              const { target, ...replyWithoutTarget } = reply;
                              return replyWithoutTarget;
                           }
                           return reply;
                        });
                        return [key, updatedReplies];
                     }

                     return [key, value];
                  }),
               );

               const finalFilteredField = Object.fromEntries(
                  Object.entries(filteredField).filter(([key, value]) => value !== null),
               );

               return finalFilteredField;
            }),
         }));

         if (currentNode) {
            const sourceParent = currentNode?.parentNode;
            const parentPosition = nodeList.find((node) => node.id === sourceParent)?.position;
            let childPosition = null;
            const newPosition = { x: currentNode.position.x + 200, y: currentNode.position.y };
            if (parentPosition) {
               childPosition = {
                  x: newPosition.x + parentPosition?.x - 25,
                  y: newPosition.y + parentPosition?.y - 25,
               };
            }

            const position = childPosition ? childPosition : newPosition;

            createFlowNode({
               variables: {
                  input: {
                     coordinates: position,
                     customer: person.customer._id,
                     flowBot: currentNode.flowBot,
                     templateId: currentNode.templateId,
                     title: t.automation_duplicate_copy + ' - ' + currentNode.data?.title,
                     flowBlocksToBeCreated: currentBlocks,
                     groupId: currentNode.parentNode,
                  },
               },
               onCompleted: async (res) => {
                  const duplicatedNode = res.createFlowNode.data;

                  const sourceParent = currentNode?.parentNode;
                  const parentPosition = nodeList.find((node) => node.id === sourceParent)?.position;
                  let childPosition = null;
                  const newPosition = duplicatedNode.coordinates;
                  if (parentPosition) {
                     childPosition = {
                        x: newPosition.x - parentPosition?.x + 25,
                        y: newPosition.y - parentPosition?.y + 25,
                     };
                  }

                  const newTargetNode = {
                     id: duplicatedNode._id,
                     type: duplicatedNode.type,
                     flowBot: duplicatedNode.flowBot,
                     templateId: duplicatedNode.templateId,
                     parentNode: duplicatedNode.groupId,
                     title: duplicatedNode.title,
                     data: {
                        title: duplicatedNode.title,
                        clickedNodeId,
                        flowBlocks: duplicatedNode.flowBlocks,
                        setIsDraggable: setIsDraggable,
                        setClickedNodeId: setClickedNodeId,
                        setLeftNodes: setLeftNodes,
                        setContentNodes: setContentNodes,
                        isValidNode: duplicatedNode.flowBlocks.length > 0 ? true : false,
                        setClickedHandleId: setClickedHandleId,
                        setIsNewNode: setIsNewNode,
                        setHandleToUpdate: setHandleToUpdate,
                        setToGroup: setToGroup,
                     },
                     position: childPosition ? childPosition : duplicatedNode.coordinates,
                  };
                  setContentNodes((prevNodes) => [...prevNodes, newTargetNode]);
                  setLeftNodes((prevNodes) => [...prevNodes, newTargetNode]);

                  setIsDuplicating(false);
               },
            });
         }
      } catch (error) {
         console.error('Error creating node:', error);
      }
   };

   const dispatch = useDispatch();
   const handleClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      isCreation && event.stopPropagation();
      if (botStatus === 'draft') {
         setClickedNodeId(id);
         dispatch({ type: SET_CLICKED_NODE_ID, payload: id });
      }
   };

   const currentNode = reactFlow.getNode(id);
   useEffect(() => {
      if (currentNode?.selected) {
         setIsHover(true);
      }
   }, [currentNode]);

   const detachNodes = useDetachNodes();
   const [updateFlowNode] = useMutation(UPDATE_FLOW_NODE_MUTATION);
   const handleUpdateFlowNodeGroup = async (itemId: string, groupId: string | null) => {
      try {
         await updateFlowNode({
            variables: {
               input: {
                  _id: itemId,
                  groupId: groupId,
               },
            },
         });
      } catch (error) {
         console.error('Error updating node:', error);
      }
   };
   const handleDetachNode = () => {
      const parentNode = currentNode?.parentNode;
      const allChildNodes = nodeList.filter(
         (node) => node.parentNode === parentNode && node.parentNode !== undefined && node.parentNode !== null,
      );
      const allChildNodeIds = nodeList.filter((node) => node.parentNode === parentNode).map((chdNode) => chdNode.id);
      if (allChildNodeIds.length > 2) {
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         const remainingNodeIds = allChildNodes.filter((node) => node.id !== id).map((chdNode) => chdNode.id);
         setToGroup(remainingNodeIds);
         //Update the detached node with groupId to null
         handleUpdateFlowNodeGroup(id, null);
      } else {
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         allChildNodeIds.forEach((sn) => handleUpdateFlowNodeGroup(sn, null));
      }
   };

   const handleDeleteNode = async () => {
      const parentNode = currentNode?.parentNode;
      const allChildNodes = nodeList.filter(
         (node) => node.parentNode === parentNode && node.parentNode !== undefined && node.parentNode !== null,
      );
      const allChildNodeIds = nodeList.filter((node) => node.parentNode === parentNode).map((chdNode) => chdNode.id);
      if (allChildNodeIds.length > 2) {
         await handleDeleteFlowNode(id);
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         const remainingNodeIds = allChildNodes.filter((node) => node.id !== id).map((chdNode) => chdNode.id);
         setToGroup(remainingNodeIds);
      } else {
         await handleDeleteFlowNode(id);
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
      }
   };

   const onDetach = () => handleDetachNode();
   const onDelete = () => handleDeleteNode();

   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (id === clickedId || currentNode?.selected ? setIsHover(true) : setIsHover(false))}
         className={classes.container}
      >
         {isHover && !isCreation && (
            <div
               style={{
                  position: 'absolute',
                  top: '-20px',
                  right: currentNode?.parentNode ? '33%' : '40%',
                  cursor: 'pointer',
               }}
               onMouseEnter={() => setIsDraggable(false)}
               onMouseLeave={() => setIsDraggable(true)}
            >
               {botStatus === 'draft' && (
                  <div className={classes.iconDiv}>
                     {isDuplicating ? (
                        <div className={classes.innerIcons}>
                           <CircularProgress size={13} />
                        </div>
                     ) : (
                        <div className={classes.innerIcons} onClick={() => handleCreateNode()}>
                           <ContentCopyIcon style={{ fontSize: '12px', fontWeight: 800 }} />
                        </div>
                     )}

                     {!isDelete ? (
                        <div className={classes.innerIcons} onClick={() => setShowDeleteModal(true)}>
                           <DeleteIcon style={{ fontSize: '13px', color: 'red' }} />
                        </div>
                     ) : (
                        <div className={classes.innerIcons}>
                           <CircularProgress size={13} />
                        </div>
                     )}

                     {currentNode?.parentNode && (
                        <div className={classes.innerIcons} onClick={onDetach}>
                           <img style={{ width: 12, height: 12 }} src={UngroupIcon} alt='UngroupIcon'></img>
                        </div>
                     )}
                  </div>
               )}
            </div>
         )}
         <div
            className={`${classes.nodeContainer} ${isHover && isValidNode ? classes.nodeContainerHovered : ''} ${
               !isValidNode ? classes.InvalidNodeContainer : ''
            }`}
            onClick={(event) => handleClicked(event)}
         >
            <div className={classes.customHeader}>
               <Handle
                  type='target'
                  position={Position.Left}
                  id={id}
                  className={classes.handleTarget}
                  isConnectable={isConnectable}
               />
               <div className={classes.iconBox} style={{ backgroundColor: '#C3DDFF' }}>
                  <SmartButtonIcon style={{ color: '#147BFB', fontSize: '15px' }} />
               </div>
               <div style={{ marginTop: '3px', fontSize: 9 }}>{limitedTitle}</div>
            </div>

            {Array.isArray(blocks) && blocks.length > 0 && fieldHasValue ? (
               <div style={{ margin: '5px 0 0 0' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                     {blocks.map((item) => (
                        <div key={item._id}>
                           {item.fields.map((field) => (
                              <Grid key={item._id}>
                                 {field.type === IFlowBlockFieldTypes.Text && field.value && field.value.length > 0 && (
                                    <div className={classes.rawText}>
                                       <textarea
                                          value={field.value}
                                          className={classes.textarea}
                                          readOnly
                                          {...calculateTextareaSize(field.value)}
                                       />
                                    </div>
                                 )}
                                 {item.type === FlowBlockType.SendInformativeButtonMessage &&
                                    field.type === IFlowBlockFieldTypes.SendMessage &&
                                    field.value &&
                                    field.value.length > 0 && (
                                       <Grid className={classes.blocks}>
                                          <div style={{ backgroundColor: '#F7F7FA', borderRadius: '5px' }}>
                                             <Box className={classes.boxTextInfinteBtn}>
                                                <textarea
                                                   value={field.value}
                                                   className={classes.textarea}
                                                   readOnly
                                                   {...calculateTextareaSize(field.value)}
                                                />
                                             </Box>
                                             <Grid style={{ margin: '5px' }}>
                                                <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                                   {item?.fields[0]?.informativeButtons &&
                                                      item?.fields[0]?.informativeButtons.map((button, ibIndex) => (
                                                         <div key={ibIndex} className={classes.btn}>
                                                            <div></div>
                                                            <span
                                                               style={{
                                                                  color: '#147BFB',
                                                               }}
                                                            >
                                                               {button.title?.split(',')[0].trim()}
                                                            </span>
                                                            {button.type === 'url' ? (
                                                               <LinkIcon
                                                                  style={{
                                                                     color: '#147BFB',
                                                                     fontSize: '12px',
                                                                  }}
                                                               />
                                                            ) : (
                                                               <div>
                                                                  <Handle
                                                                     type='source'
                                                                     position={Position.Right}
                                                                     id={item._id + ibIndex}
                                                                     className={
                                                                        button.target
                                                                           ? classes.handleEdgeConnected
                                                                           : classes.handleBlock
                                                                     }
                                                                     isConnectable={isConnectable}
                                                                     onMouseEnter={() =>
                                                                        handleMouseOnHandle(item._id + ibIndex, ibIndex)
                                                                     }
                                                                  />
                                                               </div>
                                                            )}
                                                         </div>
                                                      ))}
                                                </Grid>
                                             </Grid>
                                          </div>
                                       </Grid>
                                    )}
                              </Grid>
                           ))}
                        </div>
                     ))}
                  </div>
                  <div style={{ margin: '5px 0' }}>
                     {blocks.map((block) => (
                        <div key={block._id} className={classes.blocks}>
                           {block.type === FlowBlockType.SendQuickReplyMessage && (
                              <div>
                                 {block.fields.map((field, index) => (
                                    <div>
                                       <div
                                          key={index}
                                          style={{
                                             display: 'flex',
                                             flexDirection: 'column',
                                             gap: '0.3rem',
                                             textAlign: 'right',
                                             marginLeft: 'auto',
                                             width: 'max-content',
                                          }}
                                       >
                                          {field.quickReplies?.map((quick, quickIndex) => (
                                             <div
                                                style={{
                                                   display: 'flex',
                                                   justifyContent: 'flex-end',
                                                }}
                                             >
                                                <div className={classes.reply} key={quickIndex}>
                                                   <span>{quick.title}</span>
                                                   {quick.hideTheEdge ? (
                                                      <div className={classes.backPathGroup}>
                                                         <div style={{ position: 'relative' }}>
                                                            <Handle
                                                               type='source'
                                                               position={Position.Right}
                                                               className={classes.handlePath}
                                                               isConnectable={false}
                                                               onMouseEnter={() =>
                                                                  handleMouseOnHandle(
                                                                     block._id + quickIndex,
                                                                     quickIndex,
                                                                  )
                                                               }
                                                            />
                                                         </div>
                                                         <ArrowBackIcon
                                                            style={{ fontSize: '12px', fontWeight: '800' }}
                                                            className={classes.backPath}
                                                         />
                                                      </div>
                                                   ) : (
                                                      <Handle
                                                         type='source'
                                                         position={Position.Right}
                                                         id={block._id + quickIndex}
                                                         className={
                                                            quick.target
                                                               ? classes.handleEdgeConnectedQR
                                                               : classes.handleBlockQR
                                                         }
                                                         isConnectable={isConnectable}
                                                         onMouseEnter={() =>
                                                            handleMouseOnHandle(block._id + quickIndex, quickIndex)
                                                         }
                                                      />
                                                   )}
                                                </div>
                                             </div>
                                          ))}
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           )}
                        </div>
                     ))}
                  </div>
               </div>
            ) : isCreation ? (
               <Box
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     padding: '10px',
                     margin: '5px',
                     backgroundColor: '#F7F7FA',
                  }}
               >
                  <CircularProgress size={14} />
               </Box>
            ) : (
               <div className={classes.addText}>{t.atomation_flow_add_content}</div>
            )}

            {isQRFalse ? (
               <div className={classes.footer}>
                  <div style={{ fontSize: '7px' }}>
                     <span style={{ position: 'absolute', top: 5, right: 17, color: '#717171' }}>
                        {t.button_not_used}
                     </span>
                     {flowBlocks.map((item) => (
                        <div key={item._id}>
                           {item.type === FlowBlockType.SendMessageConnector && (
                              <Handle
                                 type='source'
                                 position={Position.Right}
                                 id={item._id}
                                 className={
                                    item.target ? classes.handleEdgeNotUsedConnected : classes.handleBlockNotUsed
                                 }
                                 isConnectable={isConnectable}
                                 onMouseEnter={() => setClickedHandleId(item._id)}
                              />
                           )}
                        </div>
                     ))}
                  </div>
               </div>
            ) : (
               isInfBtn && (
                  <div className={classes.footer}>
                     <div style={{ fontSize: '7px' }}>
                        <span style={{ position: 'absolute', top: 5, right: 17, color: '#717171' }}>{t.next}</span>
                        {flowBlocks.map((item) => (
                           <div key={item._id}>
                              {item.type === FlowBlockType.SendMessageConnector && (
                                 <Handle
                                    type='source'
                                    position={Position.Right}
                                    id={item._id}
                                    className={item.target ? classes.handleEdgeConnected : classes.handleBlock}
                                    isConnectable={isConnectable}
                                    onMouseEnter={() => setClickedHandleId(item._id)}
                                 />
                              )}
                           </div>
                        ))}
                     </div>
                  </div>
               )
            )}
         </div>

         <DeleteFow
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            onComplate={onDelete}
            text={t.automation_flow_delete_step_warning}
         />
      </div>
   );
};
export default memo(SendButtonMessageNode);

import React, { useContext } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import messengerIcon from './messenger.png';
import ButtonOK from '@components/ButtonOK/buttonOk';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import Input from '@components/InputField';
import { useForm } from 'react-hook-form';
import ColorizeIcon from '@material-ui/icons/Colorize';
import { DELETE_TEAM_MUTATION } from '@queries/Teams/mutation';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { ModalLargeText, ModalTitle } from '@components/_Modal/ModalTexts';
import SingleInput from '@components/_Modal/SingleInput';
import ModalButtonField from '@components/_Modal/ModalButtonField';
const useStyles = makeStyles((theme) => ({
   root: {
      borderRadius: 12,
      backgroundColor: '#fff',
      minWidth: 450,
      maxWidth: 600,
      maxHeight: '99vh',
      fontFamily: 'Helvetica',
      paddingInline: 20,
   },
   stepContainer: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      borderRadius: 10,
   },
   modalTitle: {
      textAlign: 'center',
      fontSize: '1rem',
      marginBottom: 15,
      fontWeight: 'bold',
   },
   stepLabel: {
      fontSize: '1.3rem',
   },
   contentTitle: {
      fontSize: '1.1rem',
   },
   subtitle: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginTop: 10,
   },
   informationListItem: {
      fontSize: '1.1rem',
   },
   informationListContainer: {
      marginTop: 30,
   },
   resultContainer: {
      width: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      padding: 5,
      borderRadius: 5,
   },
   statusTitle: {
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: 'bold',
   },
   informationTitle: {
      marginTop: 20,
   },
   colorInputContainer: {
      width: 100 + '%',
      height: 45,
      border: '1px solid #CCC',
      borderRadius: 4,
      marginBottom: 10,
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
   },
   ColorContainer: {
      width: 30,
      height: 30,
      marginLeft: 4,
      marginRight: 4,
      borderRadius: 6,
      '-webkit-box-shadow': 'inset 0px 0px 0px 2px #FFFFFF',
      boxShadow: 'inset 0px 0px 0px 2px #FFFFFF',
      cursor: 'pointer',
   },
   selectedColorContainer: {
      width: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 15,
      borderRight: '1px solid #CCC',
   },
   deleteTeamTitle: {
      fontSize: '1rem',
      color: '#000',
      textAlign: 'center',
      alignSelf: 'center',
      display: 'flex',
      fontWeight: 'bolder',
   },
   deleteTeamText: {
      fontSize: '0.9rem',
      color: '#000',
      marginBottom: 20,
   },
   inputLabel: { textAlign: 'left', fontSize: '1rem', color: theme.chatApp.general.pallet.passiveStructureBlue },
   header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '24px',
      // color: '#4681C3',
      color: 'black',
      fontWeight: 'bold',
      marginTop: 0,
      width: '100%',
      paddingInline: 20,
      paddingBlock: 10,
      marginBottom: 15,
      borderBottom: '2px solid #f5f5f5',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const getInformationList = (t) => {
   return [
      t?.page_settings_typo_platforms_facebook_information_list_login,
      t?.page_settings_typo_platforms_facebook_information_list_chose_account,
      t?.page_settings_typo_platforms_facebook_information_list_check,
      t?.page_settings_typo_platforms_facebook_information_list_consumer,
   ];
};
const ModalContent = ({ onClose, editData, teamsData = [], me }) => {
   const {
      handleSubmit,
      control,
      watch,
      register,
      formState: { errors },
   } = useForm({
      mode: 'all',
      defaultValues: {
         team: '',
      },
   });

   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

   const [deleteTeamMutation, { loading: deleteTeamLoading }] = useMutation(DELETE_TEAM_MUTATION);

   const classes = useStyles();
   // const [activeStep, setActiveStep] = useState(0);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   // const steps = getSteps(t);
   const validation = watch('validation');
   const selectedTeam = watch('team');

   const deleteHandler = () => {
      deleteTeamMutation({
         variables: {
            _id: editData.id,
            selectedTeam: selectedTeam.value,
            customer: me.customer._id,
         },
      })
         .then((e) => {
            enqueueSnackbar(t.general_information_deleted_successfully, {
               variant: 'success',
            });
            onClose(true);
         })
         .catch((error) => {
            enqueueSnackbar(t[error.message] || error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {t['comp_button_close']}
                  </div>
               ),
            });
            onClose();
         });
   };

   return (
      <Grid>
         <Grid>
            <ModalTitle>{t?.delete_team_title}</ModalTitle>
            <ModalLargeText>{t?.delete_team_text}</ModalLargeText>
            <SingleInput
               name='team'
               control={control}
               isSelect
               selectOptions={teamsData
                  .filter((q) => q._id != editData.id)
                  .map((team) => ({ label: team.teamName, value: team._id }))}
               editable
               required={false} // Disable html validation
               rules={{
                  required: t?.please_fill_this_field,
               }}
               placeholder={t?.select_team}
               label={t?.teams}
            />
            <SingleInput
               name='validation'
               control={control}
               editable
               required={false} // Disable html validation
               rules={{
                  required: t?.please_fill_this_field,
                  validation: (text) => text === editData.team.teamName,
               }}
               placeholder={editData?.teamName}
               label={t?.type_to_remove.replace('{placeholder}', editData.team.teamName)}
            />
            <ModalButtonField>
               <ButtonOK
                  className={classes.modalButtons}
                  label={t?.comp_button_cancel}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
                  onClick={onClose}
               />
               <ButtonCancel
                  className={classes.modalButtons}
                  label={t?.delete_team_button}
                  style={{
                     color: 'white',
                     backgroundColor: '#ef5350',
                     '&:hover': {
                        backgroundColor: '#f44336',
                     },
                  }}
                  disabled={!(validation === editData.team.teamName) || deleteTeamLoading}
                  onClick={() => deleteHandler()}
               />
            </ModalButtonField>
         </Grid>
      </Grid>
   );
};

export default ModalContent;

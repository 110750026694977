import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import MaterialDialog from '../MaterialDialog';
import { Grid } from '@material-ui/core';
import { Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import freeTrialEndPhoto from './freeTrialEnd.png';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';
const useStyles = makeStyles((theme) => ({
   root: {
      background: '#fff',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },

   fbold: {
      fontWeight: 'bold',
   },
   btnContainer: {
      margin: '10px 0px',
   },
}));

const DisabledAccountModal = () => {
   const customer = useSelector((state) => state.personReducer.person.customer);
   const isDisabledAccount = customer.appStatus === 'Disabled';
   const classes = useStyles();
   const history = useHistory();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const onClickUpgradeNow = () => {
      history.push('/account/packages/my-package');
   };

   const onClickSettings = () => {
      history.push('/settings/company/hours');
   };

   return (
      <MaterialDialog open={isDisabledAccount} disableCloseButton dialogTitle={t?.your_free_trial_is_ended}>
         <Grid container className={classes.root}>
            <Grid container item justifyContent='center' alignItems='center'>
               <img src={freeTrialEndPhoto} alt='free_trial_end' className={classes.btnContainer} />
            </Grid>
            <Grid container item xs={12} alignItems='center' justifyContent='center'>
               <Typography variant='h6' align='center' className={classes.fbold}>
                  {t?.your_free_trial_is_ended}
               </Typography>
               <Typography variant='body1' align='center'>
                  {t?.your_free_trial_is_ended_description}
               </Typography>
            </Grid>
            <Grid
               item
               container
               xs={12}
               justifyContent='space-between'
               alignItems='center'
               className={classes.btnContainer}
            >
               <Button variant='text' onClick={onClickSettings} color='primary'>
                  <Typography>{t?.go_to_settings}</Typography>
               </Button>
               <Button variant='contained' size='large' color='primary' onClick={onClickUpgradeNow}>
                  <Typography>{t?.upgrade_now}</Typography>
               </Button>
            </Grid>
         </Grid>
      </MaterialDialog>
   );
};

export default DisabledAccountModal;

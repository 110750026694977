import React, { FC, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MyTheme } from '../../../../../../../../../styles/config';
import { LanguageContext } from '../../../../../../../../../helper/locale/langContext';
import {CircularProgress, Typography} from '@material-ui/core';
import ButtonCancel from '../../../../../../../../../components/ButtonCancel/buttonCancel';
import ButtonOK from '../../../../../../../../../components/ButtonOK/buttonOk';
import Modal from '../../../../../../../../../components/Modal/modal';
import {Save} from "@material-ui/icons";
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';

const useStyles = makeStyles((theme: MyTheme) => ({
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 436,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   descriptionContainer: {
      padding: '40px 15px',
      textAlign: 'center',
   },
   modalDescription: {
     color: '#263548',
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      borderTop: '1px solid #F1F3F7',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7'
      }
  },
}));

interface MergeSplitModalProps {
   isVisible: boolean;
   actionType: 'merge' | 'split';
   loading: boolean;
   onCancel: () => void;
   onSubmit: () => void;
}

const MergeSplitModal: FC<MergeSplitModalProps> = ({ isVisible, actionType, loading, onCancel, onSubmit }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const headerTitle = t[`page_e_commerce_customer_${actionType}_modal_title`];
   const headerDescription = t[`page_e_commerce_customer_${actionType}_modal_description`];

   return (
      <Modal paperStyle={{ width: 450 }} open={isVisible} onClose={onCancel} modalHeader={headerTitle}>
               <ModalLargeText style={{
                  textAlign: 'center',
                  marginBlock: 30
               }}>
                  {headerDescription}</ModalLargeText>
               <ModalButtonField>
               <ButtonCancel
                  label={t['comp_button_cancel']}
                  disabled={loading}
                  onClick={onCancel}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                   }}
               />
               <ButtonOK
                    className={classes.modalButtons}
                    style={{
                       color: 'white',
                       backgroundColor: '#157CFC',
                       float: 'right'
                    }}
                 disabled={loading}
                 endIcon={
                    loading ? (
                      <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                    ) : undefined
                 }
                  label={t['general_message_yes']}
                  onClick={onSubmit}
               />
               </ModalButtonField>
      </Modal>
   )
};

export default MergeSplitModal;

import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import { LanguageContext } from '@helper/locale/langContext';
import WithUrlTranslations from '@hoc/WithUrlTranslations';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme: any) => ({
    modalContainer: {
        backgroundColor: '#fff',
        borderRadius: 16,
    },
    modalHeader: {
        height: 50,
        padding: '5px 15px',
        borderBottom: '1px solid #F1F3F7',
    },
    modalTitle: {
        color: theme.chatApp.general.pallet.passiveStructureBlue,
        fontSize: '1.1rem',
        fontWeight: 'bold',
    },
    modalBody: {
        paddingInline: 20,
        paddingBottom: 15,
    },
    modalBodyIconContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    modalDescription: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        paddingBlock: 30,
    },
    modalBodyRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    modalText: {
        color: '#797575',
        fontSize: '1rem',
        margin: 20,
    },
}));

const SubmitTemplate = ({ open, onClose, onSubmit, createLoading }: any) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;

    return (
        <Modal onClose={onClose} open={open}>
            <Grid container className={classes.modalContainer}>
                <Grid container justifyContent='center' alignItems='center' className={classes.modalHeader}>
                    <Typography className={classes.modalTitle}>{t['submit_template']}</Typography>
                </Grid>
                <Grid container className={classes.modalBody}>
                    <Grid item justifyContent='center' alignItems='center' className={classes.modalDescription}>
                        <Typography>{t['submit_template_desc']}</Typography>
                    </Grid>
                    <Grid container justifyContent='space-between'>
                        <ButtonCancel
                            disabled={createLoading}
                            label={t?.page_chat_btn_filter_cancel}
                            onClick={() => onClose()}
                            style={{ color: '#222', background: '#FFF', border: '1px solid #222', paddingTop: 8 }}
                        />
                        <ButtonOK
                            disabled={createLoading}
                            label={t['settings_whatsapp_business_template_submit']}
                            startIcon={createLoading ? <CircularProgress size={20} /> : null}
                            onClick={onSubmit}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Modal>
    );
};

export default WithUrlTranslations(SubmitTemplate);
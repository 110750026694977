import React, { useContext, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import { Grid, Typography, Box, Button, ClickAwayListener } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import { LiveChatLogo } from '../SharedComponents/Icons';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import classNames from 'classnames';
import { SketchPicker } from 'react-color';
import colorPicker from '../images/color_wheel.png';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';
import FormSaveAndCancelButtons from '../SharedComponents/FormSaveAndCancelButtons';
import { LIVE_CHAT_ICON, LIVE_CHAT_ICON_POSITION, SELECTED_LIVE_CHAT_ICON } from '../types';
import Input from '../../../../../components/InputField';
import { ChatBubble, InsertComment, Sms, Chat } from '@material-ui/icons';
import { FileUpload } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
   formContainer: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: '20px 30px',
   },
   inputRow: {
      marginBottom: '5px',
   },
   imageBox: {
      width: 100,
      height: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
   },
   imgURL: {
      width: '100%',
      height: '100%',
      border: '1px solid #ccc',
      borderRadius: '8px',
   },
   addImageIcon: {
      position: 'absolute',
      color: '#157CFC',
      backgroundColor: '#FFFFFF',
      bottom: '-15px',
      right: '-15px',
      height: '35px',
      width: '35px',
      borderRadius: '35px',
      cursor: 'pointer',
   },
   liveChatIcons: {
      display: 'flex',
      paddingBottom: '1rem',
   },
   liveChatIconContainer: {
      cursor: 'pointer',
      width: 50,
      borderRadius: '9px',
      height: 50,
      padding: '1px',
      marginRight: '8px',
      border: '2px solid transparent',
   },
   chatIcon: {
      fontSize: '30px',
      color: '#FFFFFF',
   },
   liveChatIconSubContainer: {
      fontSize: '30px',
      color: '#FFFFFF',
      borderRadius: '7px',
      width: '100%',
      height: '100%',
      background: '#157CFC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #EBEBEB',
   },
   roundLiveChatIconSubContainer: {
      fontSize: '30px',
      color: '#FFFFFF',
      borderRadius: '25px',
      width: '100%',
      height: '100%',
      background: '#157CFC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #EBEBEB',
   },
   liveChatIconLargeContainer: {
      cursor: 'pointer',
      width: 'fit-content',
      borderRadius: '9px',
      height: 50,
      padding: '1px',
      marginRight: '8px',
      border: '2px solid transparent',
   },
   liveChatIconLargeSubContainer: {
      fontSize: '30px',
      color: '#FFFFFF',
      borderRadius: '7px',
      width: '100%',
      height: '100%',
      background: '#157CFC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #EBEBEB',
      paddingLeft: '8px',
      paddingRight: '8px',
   },
   liveChatIconLabel: {
      paddingLeft: '8px',
      color: '#fff',
   },
   selected: {
      border: '2px solid #5FCD6B',
   },
   label: {
      marginTop: '10px',
      fontWeight: 600,
   },
   labelTopNegativeTen: {
      marginTop: '-10px',
   },
   labelTopNegativeFive: {
      marginTop: '-5px',
   },
   themeBoxesContainer: {
      display: 'flex',
      alignItems: 'center',
   },
   themeBox: {
      width: 50,
      borderRadius: '50%',
      height: 50,
      padding: '2px',
      marginRight: '5px',
   },
   themeBoxColorPicker: {
      width: 50,
      borderRadius: '50%',
      height: 50,
      padding: '2px',
      marginRight: '5px',
      cursor: 'pointer',
   },
   clickAwayListener: {
      position: 'relative',
   },
   dropdown: {
      position: 'absolute',
      paddingBottom: '25px',
      zIndex: 1,
      top: '50%',
      left: '50%',
   },
   themeBoxContent: {
      borderRadius: '50%',
      width: '100%',
      height: '100%',
   },
   iconLabelInputContainer: {
      // marginTop: '15px',
      // marginBottom: '-20px',
   },
   positionContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
      border: '1px solid #EBEBEB',
      borderRadius: '7px',
      overflow: 'hidden',
   },
   positionHeader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '8px 16px',
      borderBottom: '1px solid #EBEBEB',
   },
   positionBody: {
      display: 'flex',
      flexDirection: 'column',
      height: '120px',
      justifyContent: 'flex-end',
   },
   positionUp: {
      flex: 3,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
   },
   positionDown: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
   },
   positionCenterButton: {
      backgroundColor: '#EBEBEB',
      width: '25px',
      minWidth: '25px',
      height: '50px',
      marginBottom: '10px',
      border: '1px solid #EBEBEB',
      borderRadius: '4px',
   },
   positionBottomButton: {
      backgroundColor: '#EBEBEB',
      width: '32px',
      minWidth: '32px',
      height: '32px',
      border: '1px solid #EBEBEB',
      borderRadius: '6px',
   },
   selectedPosition: {
      backgroundColor: '#157CFC',
      '&:hover': {
         backgroundColor: 'rgb(0, 98, 174)',
      },
   },
}));

const Form = () => {
   const classes = useStyles();
   const { liveChatPreviewSettings, updateLiveChatPreviewSettings, dataUpdatedFromServer } =
      useContext(LiveChatSettingsContext);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const logoInputRef = useRef(null);
   const [colorPickerVisible, setColorPickerVisible] = useState(false);
   const [color, setColor] = useState();
   const liveChatIconRef = useRef(null);

   const defaultValues = {
      imgURL: liveChatPreviewSettings?.imgURL,
      greetingTitle: liveChatPreviewSettings.greetingTitle,
      greetingContent: liveChatPreviewSettings.greetingContent,
      newChatTitle: liveChatPreviewSettings.newChatTitle,
      newChatSubtitle: liveChatPreviewSettings.newChatSubtitle,
      titleOldChat: liveChatPreviewSettings.titleOldChat,
      themeColor: liveChatPreviewSettings.themeColor,
      textColor: liveChatPreviewSettings.textColor,
      iconSize: liveChatPreviewSettings.iconSize,
      iconLabel: liveChatPreviewSettings.iconLabel,
      iconPosition: liveChatPreviewSettings.iconPosition,
      liveChatIconType: liveChatPreviewSettings.liveChatIconType,
      uploadedLiveChatIcon: liveChatPreviewSettings.uploadedLiveChatIcon,
      spaceBottom: liveChatPreviewSettings.spaceBottom,
      spaceSide: liveChatPreviewSettings.spaceSide,
   };

   const { control, watch, setValue, reset } = useForm({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: useMemo(() => {
         return defaultValues;
      }, [defaultValues]),
   });

   useEffect(() => {
      reset(defaultValues);
   }, [dataUpdatedFromServer]);

   // if(!liveChatPreviewSettings) {
   //    return <p>Loading...</p>
   // }

   // const resetForm = useCallback(async () => {
   //    const myPromise = new Promise(function (resolve) {
   //       setTimeout(() => {
   //          resolve();
   //       }, [5000]);
   //    });

   //    myPromise.then(() => {
   //       console.log(defaultValues.greetingTitle, liveChatPreviewSettings.greetingTitle, 'greetingTitle');
   //       reset(defaultValues);
   //    });
   // }, [reset]);

   // console.log(defaultValues, "helloooo")

   // useEffect(() => {
   //    reset(defaultValues)
   // }, [defaultValues]);

   // useEffect(() => {
   //    if (liveChatPreviewSettings) {
   //       for (let key in defaultValues) {
   //          setValue(key, defaultValues[key]);
   //       }
   //    }
   // }, [liveChatPreviewSettings]);

   // console.log(liveChatPreviewSettings.greetingTitle, 'from the input side...');

   // console.log(defaultValues, "default__values__")

   const handleImageChange = (e) => {
      if (e.target.files[0]) {
         let reader = new FileReader();
         let file = e.target.files[0];
         reader.onloadend = () => {
            setValue('imgURL', reader.result);
         };
         reader.readAsDataURL(file);
      }
   };

   const handleImageUpload = () => logoInputRef.current.click();

   const toggleColorPicker = () => setColorPickerVisible((prevState) => !prevState);

   const getContrast = ({ r, g, b }) => {
      const yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? '#000000' : '#FFFFFF';
   };

   const handleThemeColorChange = (color) => setColor(color);

   useEffect(() => {
      const timer = setTimeout(() => {
         if (color) {
            setValue('themeColor', color.hex);
            setValue('textColor', getContrast(color.rgb));
         }
      }, 100);
      return () => clearTimeout(timer);
   }, [color]);

   const updateLiveChatIconType = (type) => {
      if (
         type === SELECTED_LIVE_CHAT_ICON.USER_UPLOADED_ICON
      ) {
         liveChatIconRef.current.click();
      } else {
         setValue('liveChatIconType', type);
      }
   };

   const liveChatBackgroundColor = (icon) => {
      if (icon === SELECTED_LIVE_CHAT_ICON.USER_UPLOADED_ICON) {
         return liveChatIconType === SELECTED_LIVE_CHAT_ICON.USER_UPLOADED_ICON ? 'transparent' : '#f7f7fa';
      } else {
         return liveChatIconType === icon ? '#157CFC' : "#f7f7fa";
      }
   };

   const liveChatColor = (icon) => {
      return liveChatIconType === icon ? '#FFFFFF' : "#a2a2a2";
   };

   const handleLiveChatIconChange = (e) => {
      if (e.target.files[0]) {
         let reader = new FileReader();
         let file = e.target.files[0];
         reader.onloadend = () => {
            setValue('uploadedLiveChatIcon', reader.result);
            setValue('liveChatIconType', SELECTED_LIVE_CHAT_ICON.USER_UPLOADED_ICON);
         };
         reader.readAsDataURL(file);
      }
   };

   const updateIconSize = (newSize) => {
      if (
         (newSize === LIVE_CHAT_ICON.PRIMARY || newSize === LIVE_CHAT_ICON.TERTIARY) &&
         iconSize === LIVE_CHAT_ICON.SECONDARY &&
         (iconPosition === LIVE_CHAT_ICON_POSITION.TOP_LEFT || iconPosition === LIVE_CHAT_ICON_POSITION.TOP_RIGHT)
      ) {
         setValue('iconPosition', LIVE_CHAT_ICON_POSITION.RIGHT);
      }
      setValue('iconSize', newSize);
   };

   const updateIconPosition = (newPosition) => setValue('iconPosition', newPosition);

   const {
      imgURL,
      greetingTitle,
      greetingContent,
      newChatTitle,
      newChatSubtitle,
      titleOldChat,
      themeColor,
      textColor,
      iconSize,
      iconPosition,
      liveChatIconType,
      uploadedLiveChatIcon,
      spaceBottom,
      spaceSide
   } = watch();

   useEffect(() => {
      updateLiveChatPreviewSettings({
         imgURL,
         greetingTitle,
         greetingContent,
         newChatTitle,
         newChatSubtitle,
         titleOldChat,
         themeColor,
         textColor,
         iconSize,
         iconPosition,
         liveChatIconType,
         uploadedLiveChatIcon,
         spaceBottom: Number.isNaN(Number(spaceBottom)) || spaceSide > 500 || spaceSide < 0 ? 0 : spaceBottom,
         spaceSide: Number.isNaN(Number(spaceSide)) || spaceSide > 500 || spaceSide < 0 ? 0 : spaceSide,
      });
   }, [
      imgURL,
      greetingTitle,
      greetingContent,
      newChatTitle,
      newChatSubtitle,
      titleOldChat,
      themeColor,
      textColor,
      iconSize,
      iconPosition,
      liveChatIconType,
      uploadedLiveChatIcon,
      spaceBottom,
      spaceSide
   ]);

   return (
      <>
         <div className={classes.formContainer}>
            <Grid container>
               <Grid item md={12}>
                  <Typography style={{ fontWeight: 600 }}>
                     {t['page_settings_typo_platforms_live_chat_appearance_form_logo']}
                  </Typography>
               </Grid>
               <Grid item md={12} className={classes.inputRow}>
                  <Box className={classes.imageBox}>
                     <img src={imgURL || liveChatPreviewSettings.imgURL} alt='logo url' className={classes.imgURL} />
                     <input hidden ref={logoInputRef} type='file' accept='image/*' onChange={handleImageChange} />
                     <AddCircleRoundedIcon className={classes.addImageIcon} onClick={handleImageUpload} />
                  </Box>
               </Grid>
               <Grid item md={12} style={{ marginTop: '1.65rem' }} className={classes.inputRow}>
                  <Input
                     name='greetingTitle'
                     label={t['page_settings_typo_platforms_live_chat_appearance_form_greeting_label']}
                     placeholder={t['page_settings_typo_platforms_live_chat_appearance_form_greeting_placeholder']}
                     control={control}
                     labelColor='black'
                     rules={{
                        required: t['page_settings_typo_platforms_live_chat_appearance_form_greeting_required'],
                     }}
                  />
               </Grid>
               <Grid item md={12} className={classes.inputRow}>
                  <Input
                     name='greetingContent'
                     label={t['page_settings_typo_platforms_live_chat_appearance_form_greeting_content_label']}
                     placeholder={
                        t['page_settings_typo_platforms_live_chat_appearance_form_greeting_content_placeholder']
                     }
                     control={control}
                     labelColor='black'
                     multiline
                     rules={{
                        required: t['page_settings_typo_platforms_live_chat_appearance_form_greeting_content_required'],
                     }}
                  />
               </Grid>
               <Grid item md={12} className={classes.inputRow}>
                  <Input
                     name='newChatTitle'
                     label={t['page_settings_typo_platforms_live_chat_appearance_form_title_for_new_chat_label']}
                     placeholder={
                        t['page_settings_typo_platforms_live_chat_appearance_form_title_for_new_chat_placeholder']
                     }
                     control={control}
                     labelColor='black'
                     rules={{
                        required:
                           t['page_settings_typo_platforms_live_chat_appearance_form_title_for_new_chat_required'],
                     }}
                  />
               </Grid>
               <Grid item md={12} className={classes.inputRow}>
                  <Input
                     name='newChatSubtitle'
                     label={t['page_settings_typo_platforms_live_chat_appearance_form_subtitle_for_new_chat_label']}
                     placeholder={
                        t['page_settings_typo_platforms_live_chat_appearance_form_subtitle_for_new_chat_placeholder']
                     }
                     control={control}
                     labelColor='black'
                     rules={{
                        required:
                           t['page_settings_typo_platforms_live_chat_appearance_form_subtitle_for_new_chat_required'],
                     }}
                  />
               </Grid>
               <Grid item md={12} className={classes.inputRow}>
                  <Input
                     name='titleOldChat'
                     label={t['page_settings_typo_platforms_live_chat_appearance_form_title_for_old_chat_label']}
                     placeholder={
                        t['page_settings_typo_platforms_live_chat_appearance_form_title_for_old_chat_placeholder']
                     }
                     control={control}
                     labelColor='black'
                     rules={{
                        required:
                           t['page_settings_typo_platforms_live_chat_appearance_form_title_for_old_chat_required'],
                     }}
                  />
               </Grid>
               <Grid item md={12}>
                  <Typography className={classNames(classes.label, classes.labelTopNegativeTen)}>
                     {t['page_settings_typo_platforms_live_chat_appearance_form_theme_color_label']}
                  </Typography>
               </Grid>
               <Grid item md={12} className={classNames(classes.themeBoxesContainer, classes.inputRow)}>
                  <Box className={classNames(classes.themeBox, classes.selected)}>
                     <Box className={classes.themeBoxContent} style={{ background: themeColor }} />
                  </Box>
                  <ClickAwayListener onClickAway={() => setColorPickerVisible(false)}>
                     <div className={classes.clickAwayListener}>
                        <Box className={classes.themeBoxColorPicker} onClick={toggleColorPicker}>
                           <img src={colorPicker} className={classes.themeBoxContent} alt={'color picker'} />
                        </Box>
                        {colorPickerVisible && (
                           <div className={classes.dropdown}>
                              <SketchPicker color={color} onChange={handleThemeColorChange} disableAlpha={true} />
                           </div>
                        )}
                     </div>
                  </ClickAwayListener>
               </Grid>
               <Grid item md={12}>
                  <Typography className={classes.label}>
                     {t['page_settings_typo_platforms_live_chat_appearance_form_live_chat_icon_label']}
                  </Typography>
               </Grid>
               <Grid item md={12} className={classNames(classes.liveChatIcons, classes.inputRow)}>
                  <Box
                     className={classNames(
                        classes.liveChatIconContainer,
                        iconSize === LIVE_CHAT_ICON.TERTIARY && classes.selected,
                     )}
                     onClick={() => updateIconSize(LIVE_CHAT_ICON.TERTIARY)}
                  >
                     <Box className={classes.roundLiveChatIconSubContainer}>
                        <LiveChatLogo />
                     </Box>
                  </Box>
                  <Box
                     className={classNames(
                        classes.liveChatIconContainer,
                        iconSize === LIVE_CHAT_ICON.PRIMARY && classes.selected,
                     )}
                     onClick={() => updateIconSize(LIVE_CHAT_ICON.PRIMARY)}
                  >
                     <Box className={classes.liveChatIconSubContainer}>
                        <LiveChatLogo />
                     </Box>
                  </Box>
                  <Box
                     className={classNames(
                        classes.liveChatIconLargeContainer,
                        iconSize === LIVE_CHAT_ICON.SECONDARY && classes.selected,
                     )}
                     onClick={() => updateIconSize(LIVE_CHAT_ICON.SECONDARY)}
                  >
                     <Box className={classes.liveChatIconLargeSubContainer}>
                        <LiveChatLogo />
                        <Typography className={classes.liveChatIconLabel}>
                           {liveChatPreviewSettings.translation[lang?.code === 'en-US' ? 'en' : 'tr']
                              ?.liveChatIconLabel ||
                              t?.page_settings_typo_platforms_live_chat_languages_default_chatWithUs}
                        </Typography>
                     </Box>
                  </Box>
               </Grid>
               <Grid item md={12}>
                  <Typography className={classes.label}>
                     {t['page_settings_typo_platforms_live_chat_appearance_form_live_chat_icon']}
                  </Typography>
               </Grid>
               <Grid item md={12} className={classNames(classes.liveChatIcons, classes.inputRow)}>
                  {
                     Object.keys(SELECTED_LIVE_CHAT_ICON).map((icon) => (
                        <Box
                           className={classNames(
                              classes.liveChatIconContainer,
                              liveChatIconType === icon && classes.selected,
                           )}
                           onClick={() => updateLiveChatIconType(icon)}
                        >
                           <Box className={classes.liveChatIconSubContainer} style={{ background: liveChatBackgroundColor(icon) }}>
                              {
                                 (() => {
                                    switch (icon) {
                                       case SELECTED_LIVE_CHAT_ICON.QPIEN_DEFAULT_ICON:
                                          return <LiveChatLogo size={".85em"} color={liveChatColor(icon)} />;
                                       case SELECTED_LIVE_CHAT_ICON.CHAT_BUBBLE_ICON:
                                          return <ChatBubble style={{ color: liveChatColor(icon) }} />;
                                       case SELECTED_LIVE_CHAT_ICON.CHAT_ICON:
                                          return <Chat style={{ color: liveChatColor(icon) }} />;
                                       case SELECTED_LIVE_CHAT_ICON.INSERT_COMMENT_ICON:
                                          return <InsertComment style={{ color: liveChatColor(icon) }} />;
                                       case SELECTED_LIVE_CHAT_ICON.SMS_ICON:
                                          return <Sms style={{ color: liveChatColor(icon) }} />;
                                       case SELECTED_LIVE_CHAT_ICON.USER_UPLOADED_ICON:
                                          return (
                                             <>
                                                {
                                                   uploadedLiveChatIcon ? <img style={{ borderRadius: 8 }} height={44} width={44} src={uploadedLiveChatIcon} /> : <FileUpload style={{ color: liveChatColor(icon) }} />
                                                }
                                                <input hidden ref={liveChatIconRef} type='file' accept='image/*' onChange={handleLiveChatIconChange} />
                                             </>);
                                       default:
                                          return <LiveChatLogo size={".85em"} color={liveChatColor(icon)} />;
                                    }
                                 })()
                              }
                           </Box>
                        </Box>))
                  }
               </Grid>
               <Grid item md={12}>
                  <Grid container spacing={2}>
                     <Grid item md={4}>
                        <Grid item md={12}>
                           <Typography className={classes.label}>
                              {t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_side_space']}
                           </Typography>
                        </Grid>
                        <Grid item md={12} className={classNames(classes.liveChatIcons, classes.inputRow)}>
                           <Input
                              name='spaceSide'
                              placeholder={
                                 t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_side_space']
                              }
                              control={control}
                              labelColor='black'
                              endAdornment={'px'}
                              type={"number"}
                              rules={{
                                 required: t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_side_space_error'],
                                 min: {
                                    value: 0,
                                    message: t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_side_space_error'],
                                 },
                                 max: {
                                    value: 500,
                                    message: t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_side_space_error'],
                                 },
                                 valueAsNumber: true,
                              }}
                           />
                        </Grid>
                     </Grid>
                     <Grid item md={4}>
                        <Grid item md={12}>
                           <Typography className={classes.label}>
                              {t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_bottom_space']}
                           </Typography>
                        </Grid>
                        <Grid item md={12} className={classNames(classes.liveChatIcons, classes.inputRow)}>
                           <Input
                              name='spaceBottom'
                              placeholder={
                                 t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_bottom_space']
                              }
                              endAdornment={'px'}
                              type={"number"}
                              control={control}
                              labelColor='black'
                              rules={{
                                 required: t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_bottom_space_error'],
                                 min: {
                                    value: 0,
                                    message: t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_bottom_space_error'],
                                 },
                                 max: {
                                    value: 500,
                                    message: t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_bottom_space_error'],
                                 },
                                 valueAsNumber: true,
                              }}
                           />
                        </Grid>
                     </Grid>
                     <Grid item md={4}></Grid>
                  </Grid>
               </Grid>
               {/* {iconSize === LIVE_CHAT_ICON.SECONDARY && (
                  <Grid item md={12} className={classNames(classes.iconLabelInputContainer, classes.inputRow)}>
                     <Input
                        name='iconLabel'
                        label={t['page_settings_typo_platforms_live_chat_appearance_form_icon_label_label']}
                        placeholder={t['page_settings_typo_platforms_live_chat_appearance_form_icon_label_placeholder']}
                        control={control}
                        labelColor='black'
                        rules={{
                           required: t['page_settings_typo_platforms_live_chat_appearance_form_icon_label_required'],
                           maxLength: {
                              value: 25,
                              message: t['max_25_characters'],
                           },
                        }}
                     />
                  </Grid>
               )} */}
               <Grid item md={12}>
                  <Typography className={classNames(classes.label, classes.labelTopNegativeFive)}>
                     {t['page_settings_typo_platforms_live_chat_appearance_form_icon_position_label']}
                  </Typography>
               </Grid>
               <Grid item md={12}>
                  <div className={classes.positionContainer}>
                     <div className={classes.positionHeader}>
                        <Typography>
                           {t['page_settings_typo_platforms_live_chat_appearance_form_dummy_website_address']}
                        </Typography>
                     </div>
                     <div className={classes.positionBody}>
                        {iconSize === LIVE_CHAT_ICON.SECONDARY && (
                           <div className={classes.positionUp}>
                              <Button
                                 className={classNames(
                                    classes.positionCenterButton,
                                    iconPosition === LIVE_CHAT_ICON_POSITION.TOP_LEFT && classes.selectedPosition,
                                 )}
                                 onClick={() => updateIconPosition(LIVE_CHAT_ICON_POSITION.TOP_LEFT)}
                                 disableTouchRipple
                              />
                              <Button
                                 className={classNames(
                                    classes.positionCenterButton,
                                    iconPosition === LIVE_CHAT_ICON_POSITION.TOP_RIGHT && classes.selectedPosition,
                                 )}
                                 onClick={() => updateIconPosition(LIVE_CHAT_ICON_POSITION.TOP_RIGHT)}
                                 disableTouchRipple
                              />
                           </div>
                        )}
                        <div className={classes.positionDown}>
                           <Button
                              className={classNames(
                                 classes.positionBottomButton,
                                 iconPosition === LIVE_CHAT_ICON_POSITION.LEFT && classes.selectedPosition,
                              )}
                              onClick={() => updateIconPosition(LIVE_CHAT_ICON_POSITION.LEFT)}
                              disableTouchRipple
                           />
                           <Button
                              className={classNames(
                                 classes.positionBottomButton,
                                 iconPosition === LIVE_CHAT_ICON_POSITION.RIGHT && classes.selectedPosition,
                              )}
                              onClick={() => updateIconPosition(LIVE_CHAT_ICON_POSITION.RIGHT)}
                              disableTouchRipple
                           />
                        </div>
                     </div>
                  </div>
               </Grid>
            </Grid>
         </div>
         <FormSaveAndCancelButtons resetValues={reset} />
      </>
   );
};

export default Form;

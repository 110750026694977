import ButtonOK from '@components/ButtonOK/buttonOk';

import RichTextReply from '@components/RichTextReply';

import ModalButtonField from '@components/_Modal/ModalButtonField';
import { LanguageContext } from '@helper/locale/langContext';
import {
   Button,
   Grid,
   Modal as MaterialModal,
   MenuItem,
   Paper,
   Select,
   Typography,
   CircularProgress,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import '@styles/index.css';
import React, { useContext, useEffect, useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FileUploadPreview from '@components/FileUploadPreview';
import useWindowResize from '@hooks/useWindowResize';
import { IconButton } from '@material-ui/core';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import { useSnackbar } from 'notistack';
import Resizer from 'react-image-file-resizer';

const MAX_FILE_SIZE = 10000000;

const useStyles = makeStyles((theme) => ({
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   modalPaper: {
      borderRadius: 12,
      backgroundColor: '#fff',
      minWidth: 450,
      width: 600,
      maxHeight: '99vh',
      fontFamily: 'Helvetica',
   },
   header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '20px',
      color: 'black',
      fontWeight: 'bold',
      marginTop: 0,
      width: '100%',
      paddingInline: 20,
      paddingBlock: 10,
      borderBottom: '2px solid #f5f5f5',
   },
   body: {
      overflowX: 'hidden',
      maxHeight: 'calc(99vh - 60px)',
      width: '100%',
      minHeight: 610,
      overflow: 'auto',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   btnBox: {
      position: 'sticky',
      bottom: 0,
      padding: '0 1rem',
      backgroundColor: '#fff',
      borderRadius: 12,
      display: 'flex',
      justifyContent: 'space-between',
      zIndex: 1,
   },
   selectBox: {
      backgroundColor: '#d9e8f9',
      borderRadius: 8,
      height: 34,
      marginLeft: 10,
   },
   noEmailAccounts: {
      backgroundColor: '#d9e8f9',
      borderRadius: 8,
      height: 30,
      marginLeft: 10,
      padding: '0 0.4rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   insideSelectBox: {
      borderRadius: 8,
      fontSize: 14,
      marginLeft: 8,
      marginTop: 3,
   },
   menuItem: {
      fontSize: 14,
      borderRadius: 4,
   },
}));

const NewEmailModalInput = (props) => {
   const { open, sendEmailSubmit } = props;

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const dispatch = useDispatch();
   const history = useHistory();
   const emailAccounts = useSelector((state) => state.chatReducer.emailAccounts?.emailAccounts);
   const createEmailModalVisible = useSelector((state) => state.chatReducer.createEmailModalVisible);

   const { enqueueSnackbar } = useSnackbar();

   const [state, setState] = useState({
      from: '',
      to: [],
      cc: [],
      bcc: [],
      subject: '',
      text: '<br><br>',
      emailSignature: '',
      media: [],
   });

   const classes = useStyles(props);
   const [isValid, setIsValid] = useState(false);
   const [showCc, setShowCc] = useState(false);
   const [showBcc, setShowBcc] = useState(false);
   const [isSendNewEmail, setIsSendNewEmail] = useState(false);
   const { width } = useWindowResize();
   const ITEM_HEIGHT = 48;
   const ITEM_PADDING_TOP = 8;
   const USER_MENU_PROPS = {
      anchorOrigin: {
         vertical: 'bottom',
         horizontal: 'left',
      },
      getContentAnchorEl: null,
      PaperProps: {
         style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP + 100,
            minWidth: '220px',
            marginRight: 10,
            marginLeft: '-15px',
            padding: '0 0.4rem',
         },
      },
   };

   useEffect(() => {
      if (emailAccounts && emailAccounts.length > 0) {
         setState((prevState) => ({
            ...prevState,
            from:
               emailAccounts[0].domain.valid === true
                  ? emailAccounts[0].senderEmailAddress
                  : emailAccounts[0].forwardingEmailAddress,
            emailSignature: emailAccounts[0].emailSignature,
         }));
      }
   }, [emailAccounts, createEmailModalVisible]);

   const handleIsValidChange = (newValue) => {
      setIsValid(newValue);
   };

   const handleAddCc = () => {
      setShowCc(!showCc);
   };
   const handleAddBcc = () => {
      setShowBcc(!showBcc);
   };

   const handleCloseModal = () => {
      dispatch({
         type: 'CREATE_EMAIL_MODAL_VISIBLE',
         payload: false,
      });
      setState({
         from: '',
         to: [],
         cc: [],
         bcc: [],
         subject: '',
         text: '<br><br>',
         emailSignature: '',
         media: [],
      });
   };

   const [prepareToUpload, setPrepareToUpload] = useState(false);

   const resizeFile = async (file) => {
      if (file.type.includes('image')) {
         return new Promise((resolve) => {
            Resizer.imageFileResizer(
               file,
               1920,
               1080,
               'JPEG',
               80,
               0,
               (uri) => {
                  resolve(uri);
               },
               'file',
            );
         });
      } else {
         return file;
      }
   };

   const handleFileInputChange = async (event) => {
      const files = event.target.files;
      if (!files || files.length === 0) {
         return;
      }

      setPrepareToUpload(true);

      const newFiles = Array.from(files).filter((file) => {
         return !state.media.find((addedFile) => addedFile.name === file.name);
      });

      if (newFiles.length === 0) {
         return;
      }

      const sizeCheckedImages = newFiles.filter((file) => {
         if (file.size > MAX_FILE_SIZE) {
            enqueueSnackbar(`"${file.name}" cannot be uploaded as it exceeds 10 MB size limit.`, {
               variant: 'error',
               autoHideDuration: 3500,
            });
            return false;
         }
         return true;
      });

      const compressedFiles = await Promise.all(
         sizeCheckedImages.map(async (image) => {
            return await resizeFile(image);
         }),
      );

      await Promise.all(compressedFiles).then((res) => {
         setState((prevState) => ({
            ...prevState,
            media: [...state.media, ...res],
         }));
      });

      setPrepareToUpload(false);
   };
   const handleRemoveFile = (index) => {
      state.media.splice(index, 1);
      const media = [...state.media];
      setState((s) => ({ ...s, media }));
   };
   const handleCancelUploads = () => {
      const media = [...state.media];
      setState((s) => ({ ...s, media }));
   };

   const emailAccount = () => {
      return emailAccounts.find((item) => {
         if (item.senderEmailAddress === state.from) {
            return item;
         }
         return item.forwardingEmailAddress === state.from;
      });
   };

   const handleSendEmailSubmit = () => {
      history.push('/chat');
      sendEmailSubmit({
         contentHtml: state.text,
         content: state.text,
         receiverId: emailAccount()?._id,
         emailData: {
            to: state.to,
            from: state.from,
            bcc: state.bcc,
            cc: state.cc,
            forwardingEmailAddress: emailAccount()?.forwardingEmailAddress,
         },
         subject: state.subject,
         media: state.media,
      });
      setIsSendNewEmail(true);
      setTimeout(() => {
         dispatch({
            type: 'CREATE_EMAIL_MODAL_VISIBLE',
            payload: false,
         });
         setState({
            from: '',
            to: [],
            cc: [],
            bcc: [],
            subject: '',
            text: '<br><br>',
            emailSignature: '',
            media: [],
         });
         setIsSendNewEmail(false);
      }, 1500);
   };

   return (
      <MaterialModal
         className={classes.modal}
         open={open}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 500,
         }}
      >
         <Paper className={classes.modalPaper}>
            <Grid container className={classes.header}>
               <Typography variant='inherit'>{t['page_account_preferences_new_email_message']}</Typography>
            </Grid>
            <Grid className={classes.body}>
               <div
                  style={{
                     width: '100%',
                     display: 'grid',
                     gridTemplateColumns: '1fr 1fr',
                     padding: '1rem',
                  }}
               >
                  <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                     <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span>From: </span>
                        <Grid className={classes.selectBox}>
                           <Select
                              MenuProps={USER_MENU_PROPS}
                              disabled={emailAccounts?.length === 0}
                              onChange={(event) =>
                                 setState({
                                    ...state,
                                    from: event.target.value,
                                    emailSignature: emailAccounts.find((item) => {
                                       if (item.domain.valid === true) {
                                          return item.senderEmailAddress === event.target.value;
                                       }
                                       return item.forwardingEmailAddress === event.target.value;
                                    })?.emailSignature,
                                    text: '<br><br>',
                                 })
                              }
                              value={
                                 state.from
                                    ? state.from
                                    : emailAccounts && emailAccounts[0]?.domain?.valid === true
                                    ? emailAccounts && emailAccounts[0]?.senderEmailAddress
                                    : emailAccounts && emailAccounts[0]?.forwardingEmailAddress
                              }
                              disableUnderline
                              className={classes.insideSelectBox}
                           >
                              {emailAccounts &&
                                 emailAccounts.map((item) => {
                                    if (item.domain.valid === true) {
                                       return (
                                          <MenuItem className={classes.menuItem} value={item.senderEmailAddress}>
                                             {item.senderEmailAddress}
                                          </MenuItem>
                                       );
                                    }
                                    return (
                                       <MenuItem className={classes.menuItem} value={item.forwardingEmailAddress}>
                                          {item.forwardingEmailAddress}
                                       </MenuItem>
                                    );
                                 })}
                           </Select>
                        </Grid>
                     </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                     <span onClick={handleAddCc} style={{ color: showCc && '#157CFC', cursor: 'pointer' }}>
                        Cc:
                     </span>

                     <span onClick={handleAddBcc} style={{ color: showBcc && '#157CFC', cursor: 'pointer' }}>
                        Bcc:
                     </span>
                  </div>
               </div>
               <div style={{ borderTop: '0.5px solid #DBDBDB', padding: '0.3rem 1rem' }}>
                  <div style={{ display: 'flex' }}>
                     <span style={{ marginTop: '0.7rem' }}>To:</span>
                     {state.to && (
                        <ReactMultiEmail
                           autoFocus
                           emails={state.to}
                           onChange={(_to) => {
                              setState({ ...state, to: _to });
                           }}
                           className='custom-input-color custom-tag-color'
                           style={{
                              border: 'none',
                              color: '#157CFC',
                              maxHeight: '150px',
                              overflow: 'auto',
                           }}
                           getLabel={(email, index, removeEmail) => {
                              return (
                                 <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                       ×
                                    </span>
                                 </div>
                              );
                           }}
                        />
                     )}
                  </div>
               </div>
               {showCc && (
                  <div style={{ borderTop: '0.5px solid #DBDBDB', padding: '0.3rem 1rem' }}>
                     <div style={{ display: 'flex' }}>
                        <span style={{ marginTop: '0.7rem' }}>Cc:</span>
                        {state.cc && (
                           <ReactMultiEmail
                              emails={state.cc}
                              onChange={(_cc) => {
                                 setState({ ...state, cc: _cc });
                              }}
                              className='custom-input-color custom-tag-color'
                              style={{
                                 border: 'none',
                                 color: '#157CFC',
                                 maxHeight: '150px',
                                 overflow: 'auto',
                              }}
                              autoFocus={true}
                              getLabel={(email, index, removeEmail) => {
                                 return (
                                    <div data-tag key={index}>
                                       <div data-tag-item>{email}</div>
                                       <span data-tag-handle onClick={() => removeEmail(index)}>
                                          ×
                                       </span>
                                    </div>
                                 );
                              }}
                           />
                        )}
                     </div>
                  </div>
               )}
               {showBcc && (
                  <div style={{ borderTop: '0.5px solid #DBDBDB', padding: '0.3rem 1rem' }}>
                     <div style={{ display: 'flex' }}>
                        <span style={{ marginTop: '0.7rem' }}>Bcc:</span>
                        {state.bcc && (
                           <ReactMultiEmail
                              emails={state.bcc}
                              onChange={(_bcc) => {
                                 setState({ ...state, bcc: _bcc });
                              }}
                              className='custom-input-color custom-tag-color'
                              style={{
                                 border: 'none',
                                 color: '#157CFC',
                                 maxHeight: '150px',
                                 overflow: 'auto',
                              }}
                              autoFocus={true}
                              getLabel={(email, index, removeEmail) => {
                                 return (
                                    <div data-tag key={index}>
                                       <div data-tag-item>{email}</div>
                                       <span data-tag-handle onClick={() => removeEmail(index)}>
                                          ×
                                       </span>
                                    </div>
                                 );
                              }}
                           />
                        )}
                     </div>
                  </div>
               )}

               <div style={{ borderTop: '0.5px solid #DBDBDB', padding: '0.5rem 1rem' }}>
                  <div style={{ marginTop: '0.6rem', display: 'flex' }}>
                     <span>{t['page_support_typo_subject']}: </span>
                     <textarea
                        style={{
                           outline: 'none',
                           border: 'none',
                           fontWeight: '550',
                           fontFamily: 'Helvetica',
                           width: '100%',
                           resize: 'none',
                           flex: 1,
                           marginBottom: '-0.5rem',
                        }}
                        value={state.subject}
                        onChange={(event) => setState({ ...state, subject: event.target.value })}
                     />
                  </div>
               </div>
               <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                  <RichTextReply
                     text={state.text}
                     onValueChange={(event) => setState({ ...state, text: event })}
                     onIsValidChange={handleIsValidChange}
                     emailSignature={state.emailSignature}
                     isNewModal={true}
                     placeholder={t['enter_your_text_here']}
                  />
                  <IconButton
                     style={{ position: 'absolute', right: '3px', top: '-3px' }}
                     onClick={() => document.getElementById('fileInput').click()}
                  >
                     <AttachFileOutlinedIcon />
                  </IconButton>
                  <div
                     style={{
                        margin: '10px 1rem',
                        flex: '1',
                     }}
                  ></div>
               </div>
               <div style={{ margin: '1rem 0 6rem 0' }}>
                  <FileUploadPreview
                     preparing={prepareToUpload}
                     width={width}
                     files={state.media}
                     onRemoveFile={handleRemoveFile}
                     onCancel={handleCancelUploads}
                     maxFileSize={MAX_FILE_SIZE}
                  />
               </div>
               <input
                  id='fileInput'
                  type='file'
                  multiple
                  hidden
                  onChange={handleFileInputChange}
                  accept='image/*, audio/*, video/*, .pdf, .doc, .docx, .ppt, .pptx, .txt'
               />
            </Grid>
            <div className={classes.btnBox}>
               <ModalButtonField>
                  <Button
                     className={classes.modalButtons}
                     onClick={handleCloseModal}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        '&:hover': {
                           backgroundColor: '#e0e0e0',
                        },
                     }}
                  >
                     {t['page_chat_typo_close']}
                  </Button>
                  <ButtonOK
                     startIcon={
                        isSendNewEmail ? (
                           <CircularProgress color='inherit' style={{ marginRight: 3 }} size={20} />
                        ) : null
                     }
                     onClick={handleSendEmailSubmit}
                     className={classes.modalButtons}
                     label={t['page_chat_typo_send']}
                     type='submit'
                     // disabled={!isValid} // look
                     disabled={state.to.length === 0 || state.subject.length === 0 || !state.from}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        '&:hover': {
                           backgroundColor: '#105CBB',
                        },
                        float: 'right',
                     }}
                  />
               </ModalButtonField>
            </div>
         </Paper>
      </MaterialModal>
   );
};

export default NewEmailModalInput;

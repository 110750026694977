import { gql } from '@apollo/client';
import { QNA_CORE_FIELDS_FRAGMENT, QNA_CHAT_CORE_FIELDS_FRAGMENT } from './fragments';

export const GET_QNA_CHAT_QUERY = gql`
   ${QNA_CORE_FIELDS_FRAGMENT}
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   query getQnAPersonQuery($_id: ID!, $page: Int, $limit: Int) {
      ActiveChatQnAs(input: { _id: $_id, page: $page, limit: $limit }) {
         data {
            QnAs {
               ...QnAFields
            }
            QnAChat {
               ...QnAChatFields
            }
            totalDocs
            limit
            page
            totalPages
            hasNextPage
            nextPage
            hasPrevPage
            prevPage
            pagingCounter
         }
         success
         message
         code
      }
   }
`;

export const GET_CUSTOMER_QUESTIONS_QUERY = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   query getQnAChatsQuery(
      $platformType: PlatformType
      $status: Int
      $tag: String
      $teams: String
      $startDate: Double
      $endDate: Double
      $text: String
      $page: Int
      $desc: Boolean
   ) {
      QnAChats(
         filter: {
            platformType: $platformType
            status: $status
            tag: $tag
            teams: $teams
            startDate: $startDate
            endDate: $endDate
            text: $text
            page: $page
            desc: $desc
         }
      ) {
         data {
            QnAChats {
               ...QnAChatFields
            }
            totalDocs
            limit
            page
            totalPages
            hasNextPage
            nextPage
            hasPrevPage
            prevPage
            pagingCounter
         }
         success
         message
         code
      }
   }
`;

export const GET_MY_QUESTIONS_QUERY = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   query getMyQuestionsQuery(
      $platformType: PlatformType
      $status: Int
      $tag: String
      $startDate: Double
      $endDate: Double
      $me: String
      $text: String
      $page: Int
      $desc: Boolean
   ) {
      getMyQnAChats(
         filter: {
            platformType: $platformType
            status: $status
            tag: $tag
            startDate: $startDate
            endDate: $endDate
            me: $me
            text: $text
            page: $page
            desc: $desc
         }
      ) {
         data {
            totalDocs
            limit
            page
            totalPages
            hasNextPage
            nextPage
            hasPrevPage
            prevPage
            pagingCounter
            QnAChats {
               ...QnAChatFields
            }
         }
         success
         message
         code
      }
   }
`;

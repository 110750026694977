import React from 'react';

// Component For Active Dot
const renderActiveDot = (props) => {
   const { cx, cy } = props;
   return (
      <svg x={cx - 10} y={cy - 10} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <circle id='Ellipse_125' data-name='Ellipse 125' cx='12' cy='12' r='12' fill='#2180f8' opacity='0.298' />
         <svg x='7' y='7' xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'>
            <circle id='Ellipse_126' data-name='Ellipse 126' cx='5' cy='5' r='5' fill='#2180f8' />
         </svg>
      </svg>
   );
};
// Component For Dot
const renderDot = (props) => {
   const { cx, cy } = props;
   return (
      <svg x={cx - 10} y={cy - 10} xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
         <circle id='Ellipse_125' data-name='Ellipse 125' cx='12' cy='12' r='12' fill='#B7C9DA' opacity='0.298' />
         <svg x='7' y='7' xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'>
            <circle id='Ellipse_126' data-name='Ellipse 126' cx='5' cy='5' r='5' fill='#B7C9DA' />
         </svg>
      </svg>
   );
};

export { renderActiveDot, renderDot };

import FileViewer from '@components/FileViewer';
import { extractFirstPart } from '@helper/functions';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, { useState } from 'react';
import DOMPurify from 'dompurify';

const useStyles = makeStyles(() => ({
   text: {
      padding: 0,
      fontSize: '0.95rem',
      color: '#2C2C2C',
   },
}));

const EmailBody = (props) => {
   let isExpanded = props.isExpanded;
   const medias = props.medias;
   const text = props.text;
   let contentHtml = props.contentHtml;

   const classes = useStyles();
   const styles = {
      image: {
         maxWidth: '100%',
         maxHeight: 338,
      },
   };

   // This code body style rejected in html
   if (contentHtml) {
      contentHtml = contentHtml.replaceAll('body {', '');
   }

   // Function to extract the content message part of the email
   let messageContent = extractFirstPart(contentHtml);

   // This code is for email sent from apple phone
   if (contentHtml?.includes('>') && !contentHtml?.includes('</')) {
      contentHtml = contentHtml?.split('\n').join('<br>');
   }

   //Get only the text from the extracted part

   const [isClicked, setIsClicked] = useState(false);
   const handleThreeDot = (event) => {
      event.stopPropagation();
      setIsClicked(!isClicked);
   };

   // This code for security
   const configHtml = {
      FORBID_TAGS: ['script'],
   };

   DOMPurify.addHook('afterSanitizeAttributes', (node) => {
      // set all elements owning target to target=_blank
      if ('target' in node) {
         node.setAttribute('target', '_blank');
         node.setAttribute('rel', 'noopener');
      }
   });

   return (
      <Grid container className={classes.textContainer}>
         <div
            style={{
               backgroundColor: isExpanded ? '#FFFFFF' : '',
               borderRadius: isExpanded ? '5px' : '0',
               margin: isExpanded ? '0.5rem' : '0',
               padding: isExpanded ? '0.5rem' : '0',
               width: '100%',
            }}
         >
            {isExpanded ? (
               <div>
                  {!isClicked ? (
                     <div className='p-no-margin'
                        style={{ display: 'flex', flexDirection: 'column' }}
                        dangerouslySetInnerHTML={{
                           __html: messageContent
                              ? DOMPurify.sanitize(messageContent, configHtml)
                              : contentHtml
                              ? DOMPurify.sanitize(contentHtml?.split('<p><br></p>').join(''),configHtml)
                              : DOMPurify.sanitize(text, configHtml),
                        }}
                     />
                  ) : (
                     <div className='p-no-margin'
                        dangerouslySetInnerHTML={{
                           __html: contentHtml ? DOMPurify.sanitize(contentHtml?.split('<p><br></p>').join(''), configHtml) : DOMPurify.sanitize(text, configHtml),
                        }}
                     />
                  )}
                  {messageContent && (
                     <MoreHorizIcon
                        onClick={handleThreeDot}
                        style={{
                           backgroundColor: '#dcdbdb',
                           height: '15px',
                           borderRadius: '6px',
                           marginTop: '12px',
                           cursor: 'pointer',
                        }}
                     />
                  )}
               </div>
            ) : (
               <Typography className={classes.text} style={{ margin: medias?.length > 0 ? '0 0.5rem' : '0' }}>
                  {text && (
                     <div className='p-no-margin'
                        dangerouslySetInnerHTML={{
                           __html: messageContent
                              ? DOMPurify.sanitize(messageContent, configHtml)
                              : // : contentHtml
                                // ? contentHtml.split('<p><br></p>').join('')
                                DOMPurify.sanitize(text.substring(0, 1000), configHtml) + (text.length > 1000 ? ' ...' : ''),
                        }}
                     />
                  )}
               </Typography>
            )}
         </div>

         <div style={{ width: '100%', display: 'flex' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
               {medias?.map((media) => (
                  <div
                     key={media.path}
                     style={{
                        width: '150px',
                        height: '100px',
                        margin: '0.5rem',
                        overflow: 'hidden',
                        position: 'relative',
                     }}
                  >
                     <div
                        style={{
                           width: '100%',
                           height: '100%',
                           display: 'flex',
                           alignItems: 'center',
                           justifyContent: 'center',
                        }}
                        onClick={(e) => e.stopPropagation()}
                     >
                        <FileViewer
                           showPreview
                           type={media.type}
                           url={media.path}
                           styles={styles}
                           onShow={(e) => {}}
                           style={{
                              width: '100%',
                              height: '100%',
                           }}
                        />
                     </div>
                  </div>
               ))}
            </div>
         </div>
      </Grid>
   );
};

export default EmailBody;

import { LanguageContext } from "@helper/locale/langContext";
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from "react";
import { Folder } from "../types";
import { useSnackbar } from 'notistack';
import moment from "moment";

export interface UploadFieldProps {
    size: number;
    onUploadData: ((folders: Folder[]) => void) | undefined;
}

const useStyles = makeStyles((theme: any) => ({
    container: {
        width: "100%",
        height: "25%",
        padding: 30,
        borderRadius: 20,
        border: "3px dashed #D1D1D1",
        cursor: "pointer",
        userSelect: "none",
        textAlign: "center",
        fontSize: 35,
        color: "#939393",
    }
}));

const acceptableFileSizeForType = (type: string, size: number) => {
    if (type.startsWith("image/")) {
        return size / 1024 / 1024 > 5;
    } else {
        return size / 1024 / 1024 > 50;
    }
}

const UploadField = ({ size, onUploadData }: UploadFieldProps) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const t = lang?.translation;
    const acceptedFileTypes = [
        ".png", ".jpg", ".jpeg", ".gif", ".svg", ".webp", ".bmp", ".mp4", ".mov", ".avi", ".flv", ".mpg", ".mpeg", ".heic",
    ]

    async function onChange(e: any) {
        const folders: { [key: string]: Folder } = {};
        let nsize = size;
        let idx = 0;
        const erroredPaths = [];

        const collator = new Intl.Collator('en', {
            numeric: true,
            sensitivity: 'base'
        });

        let selectedFilesWithFolders : File[] = []

        for (const file of e.target.files) {
            selectedFilesWithFolders.push(file);
        }
        selectedFilesWithFolders = selectedFilesWithFolders.sort((a: File, b: File) => collator.compare(a.name, b.name));


        for (const oldFile of selectedFilesWithFolders) {
            let chains = oldFile.webkitRelativePath.split("/");
            let folderName = chains[chains.length > 2 ? chains.length - 2 : 0];
            let folderDate = folders[folderName] ? folders[folderName].folderDate : moment().format("x");

            var file = new File([oldFile], `${folderName}*_*${folderDate}*_*${++idx}.${oldFile.name.split('.').at(-1)}`, {
                type: oldFile.type,
            });
            if (chains.length > 3 || !acceptedFileTypes.some(type => file.name.toLowerCase().endsWith(type))) {
                continue;
            } else if (acceptableFileSizeForType(oldFile.type, oldFile.size)) {
                erroredPaths.push(oldFile.webkitRelativePath);
                continue;
            } else if (folders[folderName] === undefined) {
                folders[folderName] = {
                    name: folderName,
                    folderDate,
                    files: [{
                        name: file.name,
                        size: file.size.toString(),
                        type: file.type,
                        file,
                    }],
                    sendingTelegram: false,
                    sendingWhatsapp: false,
                    telegramSent: false,
                    whatsAppSent: false,
                    whatsAppFailed: false,
                    telegramFailed: false,
                }
            } else {
                folders[folderName].files.push({
                    name: file.name,
                    size: file.size.toString(),
                    type: file.type,
                    file,
                });
            }
            nsize += file.size;
        }

        if (nsize / 1024 / 1024 > 500) {
            //@ts-ignore
            document.getElementById("selectFolder").value = "";
            enqueueSnackbar(
                `${t?.bulk_file_limit.replace('{size}', "500mb")}`,
                {
                    variant: 'error',
                    autoHideDuration: 3500,
                },
            );
            return;
        }

        if (erroredPaths.length > 0) {
            enqueueSnackbar(
                `${t?.bulk_file_limit_per_file}`,
                {
                    variant: 'error',
                    autoHideDuration: 4500,
                },
            );
        }

        const foldersArray = Object.values(folders);
        for (let folderIdx in foldersArray) {
            const folder = foldersArray[folderIdx];
            foldersArray[folderIdx].files = folder.files.sort((a, b) => collator.compare(a.name, b.name));
        }
        if (onUploadData) onUploadData(foldersArray);
    }

    function onDrop(e: any) {
        // TODO: TO BE IMPLEMENTED
        e.preventDefault();
    }

    function preventDefault(e: any) {
        e.preventDefault();
    }

    /* APPLY THESE TO DIV for drop which is not possible right now onDragOver={preventDefault} onDragEnter={preventDefault} onDrop={onDrop} */
    return (

        <div className={classes.container} onClick={onUploadData && (() => document.getElementById('selectFolder')?.click())} style={{
            cursor: onUploadData ? 'pointer' : 'not-allowed'
        }}>
            <p>{t?.bulk_upload}</p>
            <p style={{
                marginTop: -35,
                fontSize: 15
            }}>{t?.bulk_file_limit.replace("{size}", "500mb")}</p>
            {/*//@ts-ignore */}
            <input hidden id="selectFolder" name="fileList" onChange={onChange} type="file" webkitdirectory="true" directory="true" />
        </div >
    )
}

export default UploadField;
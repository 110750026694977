import { useLazyQuery, useMutation } from '@apollo/client';
import SingleInput from '@components/_Modal/SingleInput';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid } from '@material-ui/core';
import {
   FlowBlockType,
   FlowNodeType,
   IFlowBlockField,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { ADD_FLOW_BLOCK_MUTATION, UPDATE_BLOCK_MUTATION } from '@queries/Automation/mutation';
import {
   GET_FLOW_BOT_PAGINATED_QUERY,
   GET_FLOW_BOT_QUERY,
   GET_FLOW_NODE_QUERY,
   GET_LIST_FLOW_NODE_QUERY_PAGINATED,
} from '@queries/Automation/query';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Node } from 'reactflow';

interface StartFlowBlocksProps {
   leftNodes: Node[];
   clickedNodeId: string;
   isLoading: boolean;
   isSaveFlow: boolean;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   setIsSaveFlow: React.Dispatch<React.SetStateAction<boolean>>;
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
}

const StartFlowBlocks: React.FC<StartFlowBlocksProps> = ({
   leftNodes,
   setLeftNodes,
   clickedNodeId,
   setIsSaveFlow,
   setIsBtnDisable,
   setIsLoading,
   isLoading,
   isSaveFlow,
   setContentNodes,
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   let { id } = useParams();
   const currentBotId = id;

   const person = useSelector((rootReducer: any) => rootReducer.personReducer.person);
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);
   const [botsData, setBotsData] = useState<{ _id: string; title: string }[]>([]);
   const [nodesData, setNodesData] = useState<{ _id: string; title: string; type: string }[]>([]);
   const [toUpdate, setToUpdate] = useState<{ _id: string }[]>([]);
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const [getBot] = useLazyQuery(GET_FLOW_BOT_QUERY);
   const [getNode] = useLazyQuery(GET_FLOW_NODE_QUERY);
   const [startBot, setStartBot] = useState<{ title: string }>();
   const [startNode, setStartNode] = useState<{ title: string }>();
   const BOTS_PAGE_SIZE = 10;
   const NODES_PAGE_SIZE = 10;
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   const { control, watch, setValue, trigger } = useForm({
      mode: 'all',
   });

   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlock = async (flowBlockTemplateId: String, nodeId: string, field: IFlowBlockField[]) => {
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fields: field,
               },
            },
            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block.fields?.length > 0),
                              flowBlocks: [response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));

               setIsLoading(false);
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlock = async (blockId: string, fields: IFlowBlockField[]) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;

               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block.fields?.length > 0),
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
               setIsLoading(false);
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };
   const [getBotQuery] = useLazyQuery(GET_FLOW_BOT_PAGINATED_QUERY, {
      fetchPolicy: 'network-only',
   });
   const [getNodesQuery] = useLazyQuery(GET_LIST_FLOW_NODE_QUERY_PAGINATED, {
      fetchPolicy: 'network-only',
   });

   const channelType = leftNodes.find((node) => node.type === FlowNodeType.Triggers)?.data?.flowBlocks[0]?.channelType;

   const getBotLoadOptions = async (search: string | any[], prevOptions: string | any[]) => {
      let currentBotsData = botsData;

      if (search.length !== 0) {
         if (search.length < 3) {
            return {
               options: currentBotsData?.map(({ title, _id }) => ({
                  value: _id,
                  label: title,
               })),
               hasMore: false,
            };
         }
      }

      const getBotRequestData = await getBotQuery({
         variables: {
            input: {
               customer: person.customer._id,
               search,
               channelTypeFilter: channelType,
               pageSize: BOTS_PAGE_SIZE,
               page: prevOptions.length === 0 ? 1 : Math.ceil(prevOptions.length / BOTS_PAGE_SIZE) + 1,
            },
         },
      })
         .then((res) => {
            currentBotsData = res.data.getFlowBotPaginated.data.docs;
            setBotsData(currentBotsData);
            return res.data.getFlowBotPaginated.data;
         })
         .catch(() => []);

      let options = currentBotsData
         ?.map(({ title, _id }: { title: string; _id: string }) => ({
            value: _id,
            label: title,
         }))
         .filter((opt) => opt.value !== currentBotId);

      return {
         options,
         hasMore: getBotRequestData.hasNextPage,
      };
   };

   ////////////////////// Nodes //////////////
   const getNodesLoadOptions = async (search: string | any[], prevOptions: string | any[]) => {
      let currentNodesData = nodesData;

      if (search.length !== 0) {
         if (search.length < 3) {
            return {
               options: currentNodesData?.map(({ title, _id }) => ({
                  value: _id,
                  label: title,
               })),
               hasMore: false,
            };
         }
      }

      const getNodeRequestData = await getNodesQuery({
         variables: {
            input: {
               customer: person.customer._id,
               search,
               flowBotId: blockState[0]?.fields[0]?.startFlowTargetedBot,
               pageSize: NODES_PAGE_SIZE,
               page: prevOptions.length === 0 ? 1 : Math.ceil(prevOptions.length / BOTS_PAGE_SIZE) + 1,
            },
         },
      })
         .then((res) => {
            currentNodesData = res.data.getFlowNodePaginated.data.docs?.filter(
               (node: { type: FlowNodeType }) =>
                  node.type !== FlowNodeType.Triggers && node.type !== FlowNodeType.WelcomeMessage,
            );

            setNodesData(currentNodesData);
            return res.data.getFlowNodePaginated.data;
         })
         .catch(() => []);

      return {
         options: currentNodesData
            ?.map(({ title, _id }: { title: string; _id: string }) => ({
               value: _id,
               label: title,
            }))
            .reverse(),
         hasMore: getNodeRequestData.hasNextPage,
      };
   };

   useEffect(() => {
      if (blockState[0]?.fields[0].startFlowTargetedBot && blockState[0]?.fields[0].startFlowNodeStep) {
         getNode({
            variables: { input: { _id: blockState[0]?.fields[0].startFlowNodeStep } },
            fetchPolicy: 'cache-first',
            onCompleted: (res) => {
               const response = res?.getFlowNode?.data;
               setStartNode(response);
            },
         });
         getBot({
            variables: { input: { _id: blockState[0]?.fields[0].startFlowTargetedBot } },
            fetchPolicy: 'cache-first',
            onCompleted: (res) => {
               const response = res?.getFlowBot?.data;
               setStartBot(response);
            },
         });
      }
   }, [blockState, getBot, getNode]);

   const listBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   // default
   useEffect(() => {
      setBlockState([]);
      if (listBlocks.length > 0) {
         const data = listBlocks.map((item: IFlowBlockTemplate) => {
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });
         setBlockState(data);
      } else {
         // If there are no blocks, add a default item
         const newItem = {
            _id: Date.now().toString(),
            title: '',
            fields: [
               {
                  type: IFlowBlockFieldTypes.StartFlow,
               },
            ],
            type: FlowBlockType.StarFlow,
         };
         setBlockState([newItem]);
      }
   }, [clickedNodeId]);

   useEffect(() => {
      const field = blockState[0]?.fields[0];
      const botTitle = startBot?.title;
      const stepTitle = startNode?.title;

      setValue('flow', field?.startFlowTargetedBot ? { label: botTitle, value: field?.startFlowTargetedBot } : null);
      setValue('flowStep', field?.startFlowNodeStep ? { label: stepTitle, value: field?.startFlowNodeStep } : null);
   }, [startBot, startNode]);

   //enable btn
   useEffect(() => {
      const isInvalid = blockState.some((block) =>
         block.fields.some(
            (field) => field.startFlowNodeStep === undefined || field.startFlowTargetedBot === undefined,
         ),
      );
      if (isInvalid) {
         setIsBtnDisable(true);
      }
   }, [blockState]);

   // Save
   useEffect(() => {
      if (isSaveFlow && !isLoading) {
         const newBlocks = blockState.filter(
            (block) => !listBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );
         const existingBlocks = blockState.filter((block) =>
            listBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         // Add all new blocks to the backend
         const save = async () => {
            // Update fields
            if (toUpdate.length > 0) {
               toUpdate.forEach((item) => {
                  const existingBlock = existingBlocks.find((block) => block._id === item._id);
                  if (existingBlock) {
                     setIsLoading(true);

                     const fields = existingBlock.fields.map((exfield) => ({
                        type: exfield.type,
                        startFlowNodeStep: exfield.startFlowNodeStep,
                        startFlowTargetedBot: exfield.startFlowTargetedBot,
                     }));

                     handleUpdateFlowBlock(item._id, fields);
                  }
               });
               setToUpdate([]);
            }

            if (newBlocks.length > 0) {
               setIsLoading(true);
               (async () => {
                  if (LIST_BLOCK_TEMPLATE) {
                     const templateBlockId = LIST_BLOCK_TEMPLATE.find(
                        (item: { type: FlowBlockType }) => item.type === FlowBlockType.StarFlow,
                     )?._id;
                     for await (let item of newBlocks) {
                        if (templateBlockId) {
                           const fields = item.fields.map((exfield) => ({
                              type: exfield.type,
                              startFlowNodeStep: exfield.startFlowNodeStep,
                              startFlowTargetedBot: exfield.startFlowTargetedBot,
                           }));
                           await handleAddFlowBlock(templateBlockId, clickedNodeId, fields);
                        }
                     }
                  }
               })();
            }
         };
         save();
         setToUpdate([]);
      }
   }, [isSaveFlow]);

   const [keyFlowStep, setKeyFlowStep] = useState(0);

   const handleFlowChange = (selectedOption: { label: string; value: string }) => {
      if (selectedOption) {
         setIsBtnDisable(false);
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     startFlowTargetedBot: selectedOption.value,
                     startFlowNodeStep: undefined,
                  })),
               };
            });
            return updatedBlockState;
         });
         setKeyFlowStep((prev) => prev + 1);
         setValue('flow', selectedOption);
         setValue('flowStep', null);
      }
      setToUpdate([{ _id: blockState[0]._id }]);
   };
   const handleFlowStepChange = (selectedOption: { label: string; value: string }) => {
      if (selectedOption) {
         setIsBtnDisable(false);
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     startFlowNodeStep: selectedOption.value,
                  })),
               };
            });
            return updatedBlockState;
         });

         setValue('flowStep', selectedOption);
      }
      setToUpdate([{ _id: blockState[0]._id }]);
   };

   return (
      <div>
         <Grid container>
            <Grid item style={{ width: '100%' }}>
               <Grid style={{ position: 'relative' }}>
                  <SingleInput
                     name='flow'
                     type='flow'
                     label={t?.atomation_flow}
                     placeholder={<div>{t.atomation_flow_select}</div>}
                     isPaginateSelect
                     autoHeight
                     control={control}
                     maxMenuHeight={190}
                     selectOptions={botsData?.map(({ title, _id }) => ({
                        value: _id,
                        label: title,
                     }))}
                     value={watch('flow')}
                     loadOptions={getBotLoadOptions}
                     onChange={(flow: { label: string; value: string }) => {
                        handleFlowChange(flow);
                     }}
                  />
                  <SingleInput
                     key={keyFlowStep}
                     name='flowStep'
                     type='flowStep'
                     label={t?.atomation_flow_step}
                     placeholder={<div>{t.atomation_flow_select}</div>}
                     isPaginateSelect
                     autoHeight
                     disabled={blockState[0]?.fields[0]?.startFlowTargetedBot === undefined}
                     control={control}
                     maxMenuHeight={190}
                     selectOptions={nodesData.map(({ title, _id }) => ({
                        value: _id,
                        label: title,
                     }))}
                     value={watch('flowStep')}
                     loadOptions={getNodesLoadOptions}
                     onChange={(flowStep: { label: string; value: string }) => {
                        handleFlowStepChange(flowStep);
                     }}
                  />
               </Grid>
            </Grid>
         </Grid>
      </div>
   );
};

export default StartFlowBlocks;

import React, { useContext, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@components/InputField';
import Button from '@components/Button';
import { useHistory, useParams } from 'react-router-dom';
import { CREATE_PASSWORD_MUTATION } from '../../../queries/Person/Service/GraphQL/mutation';
import { useMutation } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
   primaryLink: {
      color: theme.palette.primary.main,
   },
   secondaryLink: {
      color: theme.palette.grey['800'],
   },
   helperText: {
      color: '#178CF9',
      paddingTop: '16px',
      margin: '10px',
   },
   subTitleText: {
      fontSize: '0.9rem',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.85)',
   },
}));

const ResetPasswordForm = () => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [errorState, setErrorState] = useState(false);
   const [isSuccess, setIsSuccess] = useState(false);
   const { enqueueSnackbar } = useSnackbar();
   const { token } = useParams();
   const {
      handleSubmit,
      control,
      watch,
      formState: { isValid },
   } = useForm({
      mode: 'all',
   });

   const classes = useStyles();
   const history = useHistory();
   const [createPasswordMutation, { loading: createPasswordLoading }] = useMutation(CREATE_PASSWORD_MUTATION);

   useEffect(() => {
      if (!token) {
         history.push('/');
      }
   }, [history, token]);

   // update data on submit
   const handleCreatePassword = async (data) => {
      try {
         const result = await createPasswordMutation({
            variables: {
               password: data.password,
               emailVerificationId: token,
            },
         });
         enqueueSnackbar(t.your_password_created, {
            variant: 'success',
            autoHideDuration: 2000,
         });

         history.replace('/');
      } catch (err) {
         setErrorState(true);
         setTimeout(() => {
            setErrorState(false);
         }, 3000);
      }
   };
   return (
      <div style={{ minWidth: '30%', marginTop: '7rem' }}>
         {createPasswordLoading && <div className='loading' />}
         <Typography
            style={{
               fontSize: '1.45rem',
               marginBottom: 35,
               color: 'black',
               fontWeight: 500,
               paddingBottom: '.5rem',
               borderBottom: '1px solid #EBEBEB',
            }}
         >
            {t?.create_your_password}
         </Typography>

         <form onSubmit={handleSubmit(handleCreatePassword)} className={classes.form}>
            <Grid container spacing={3}>
               <Grid item xs={12}>
                  <Input
                     name='password'
                     label={t?.password}
                     type='password'
                     control={control}
                     rules={{
                        required: t['password_required'],
                        minLength: {
                           value: 6,
                           message: t['password_must_be_6_chars'],
                        },
                     }}
                  />
               </Grid>
               <Grid item xs={12}>
                  <Input
                     name='confirmPassword'
                     label={t?.confirm_password}
                     type='password'
                     control={control}
                     rules={{
                        validate: (value) =>
                           value === watch('password') || t?.page_account_typo_error_form_message_dont_match,
                        required: t?.please_fill_this_field,
                     }}
                  />
               </Grid>
            </Grid>

            {errorState && (
               <Typography style={{ fontSize: '0.9rem', color: 'red' }}>{t?.error_create_password}</Typography>
            )}
            <Button
               label={t['create_your_password']}
               style={{ textTransform: 'none' }}
               disabled={!isValid}
               isPrimary
               type='submit'
            />

            <Grid
               container
               justifyContent={'space-between'}
               alignItems={'center'}
               style={{ marginTop: '20px', paddingBottom: '50px' }}
            >
               <Grid item xs>
                  <p className={classes.helperText}>{t?.return_to_login_screen}</p>
               </Grid>
               <Grid item>
                  <Button
                     onClick={() => history.push('/sign-in')}
                     isPrimary={false}
                     color='#178CF9'
                     style={{
                        textTransform: 'none',
                        paddingLeft: '1.5rem',
                        paddingRight: '1.5rem',
                     }}
                     label={t?.login}
                  />
               </Grid>
            </Grid>
         </form>
      </div>
   );
};

export default ResetPasswordForm;

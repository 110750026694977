import { LanguageContext } from '@helper/locale/langContext';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
   topBar: {
      padding: 10,
      paddingLeft: 10,
      borderBottom: '2px solid #f7f7f7',
   },

   icons: {
      marginRight: 10,
      fontSize: 30,
      cursor: 'pointer',
      paddingTop: 4,
   },
}));

const TemplatesTopBar = ({ appId }: any) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   return (
      <Grid container justifyContent='space-between' alignItems='center' className={classes.topBar}>
         <Grid>
            <Grid container alignItems='center'>
               <Link style={{ color: 'gray' }} to='/settings/platforms/whatsapp-business'>
                  <KeyboardBackspaceIcon fontSize='inherit' className={classes.icons} />
               </Link>
               <Typography variant='h6'>{t['settings_whatsapp_business_template_message_templates']}</Typography>
            </Grid>
         </Grid>
         <Grid>
            <Grid container alignItems='center'>
               <Link style={{ color: 'gray' }} to={`/settings/platforms/whatsapp-business/createNewTemplate/${appId}`}>
                  <Button color='primary' variant='contained'>
                     {t['settings_whatsapp_business_template_create_new_template']}
                  </Button>
               </Link>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default TemplatesTopBar;

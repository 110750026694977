import { gql } from '@apollo/client';

export const GET_PERSON_ACTIVITY = gql`
   query personActivities($customer: ID!, $limit: Int, $page: Int) {
      personActivities(customer: $customer, limit: $limit, page: $page) {
         data
         success
         code
         message
      }
   }
`;

export const GET_PERSON_ACTIVITY_SINGLE = gql`
   query getPersonActivity($customer: ID!, $person: ID!) {
      getPersonActivity(customer: $customer, person: $person) {
         data
         success
         code
         message
      }
   }
`;

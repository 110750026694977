import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Typography } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { MyTheme } from '@styles/config';

interface Props {
   type?: string | number;
   selected?: boolean;
}

const useStyles = makeStyles<MyTheme, Props>((theme) => ({
   root: {
      border: (props) => (props.selected ? `2px solid ${theme.palette.primary.main}` : '2px solid #eee'),
      // border: '2px solid #eee',
      borderRadius: 5,
      padding: 5,
      height: 35,
   },
   text: {
      fontWeight: 'bold',
      fontSize: '1rem',
      paddingLeft: 10,
   },
   avatar: {
      // fontWeight: ',
      textAlign: 'center',
      paddingLeft: 0,
      paddingTop: 1,
      paddingBottom: 0,
      width: 80,
      minWidth: 0,
      fontSize: '0.7rem',
   },
   icon: {
      fontSize: '1.3rem',
   },
}));

const getFilters = (t: any) => [
   {
      label: t['page_chat_typo_filter_title_all'],
      key: 'ALL',
      value: 'ALL',
      color: '#157CFC',
      type: -1,
   },
   {
      label: t['page_chat_typo_filter_title_waiting'],
      key: 'ACTIVE',
      value: 'ACTIVE',
      color: '#1ac31a',
      type: 0,
   },
   {
      label: t['page_chat_typo_filter_title_closed'],
      key: 'CLOSED',
      value: 'CLOSED',
      color: '#F2B544',
      type: 1,
   },
   {
      label: t['page_chat_typo_filter_title_resolved'],
      key: 'RESOLVED',
      value: 'RESOLVED',
      color: '#499cf2',
      type: 2,
   },
   {
      label: t['page_chat_typo_filter_title_spam'],
      key: 'SPAM',
      value: 'SPAM',
      color: '#577590',
      type: 3,
   },
   {
      label: t['page_chat_typo_filter_title_bot_is_joined'],
      key: 'BotIsJoined',
      value: 'BotIsJoined',
      color: '#157CFC',
      type: 6,
   },
   {
      label: t['page_chat_typo_filter_title_deleted'],
      key: 'DELETED',
      value: 'DELETED',
      color: '#d71929',
      type: 5,
   },
];

const Filter: React.FC<Props> = (props) => {
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const FILTERS = getFilters(t);

   const { type, selected } = props;
   // type prop could be SPAM, ACTIVE, ALL ETC.
   const classes = useStyles(props);
   const getItemFromType = (type?: string | number) => {
      return FILTERS.findIndex((item) => item.key === type);
   };

   return (
      <Grid xs={12} className={classes.root} container justifyContent='space-between' alignItems='center'>
         <Typography style={{ color: FILTERS[getItemFromType(type)].color }} className={classes.text}>
            {FILTERS[getItemFromType(type)].label}
         </Typography>
         {selected && (
            <CheckCircleIcon style={{ color: FILTERS[getItemFromType(type)].color }} className={classes.icon} />
         )}
      </Grid>
   );
};

export default Filter;

import { Grid, Typography, LinearProgress } from '@material-ui/core';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme) => ({
   title: {
      color: theme.chatApp.workspace.sideBar.backgroundColor,
   },
   root: {
      border: '2px solid #eee',
      borderRadius: 15,
      padding: 10,
   },
   labelText: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: '#97A8BF',
   },
   nameText: {
      fontSize: '1.3rem',
      fontWeight: 'bold',
      color: (props) => props.color,
      marginTop: 5,
   },
   valueText: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   linearProgress: {
      height: 15,
      borderRadius: 15,
      background: 'lightgrey',
   },
   bar: {
      // borderRadius: 15,
      backgroundColor: (props) => props.color || '',
   },
}));

const TagItem = ({ name, value, totalValue, color, percent }) => {
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   const classes = useStyles({ color });
   return (
      <Grid container className={classes.root}>
         <Grid container item direction='row' alignItems='center' justifyContent='space-evenly'>
            <Grid xs={4} direction='column'>
               <Typography className={classes.labelText}>{t?.page_chat_typo_filter_tag}</Typography>
               <Typography noWrap className={classes.nameText}>
                  #{name}
               </Typography>
            </Grid>
            {/* Added Chat */}
            <Grid xs={8} container item direction='column'>
               <Grid container alignItems='center'>
                  <Typography className={classes.labelText}>{t?.added_chat}</Typography>
                  <Typography className={classes.valueText} style={{ paddingLeft: 10 }}>
                     {value}
                  </Typography>
               </Grid>
               <Grid item container alignItems='center' style={{ marginTop: 8 }}>
                  <Grid xs={10}>
                     <LinearProgress
                        className={classes.linearProgress}
                        classes={{ bar: classes.bar }}
                        variant='determinate'
                        value={percent}
                     />
                  </Grid>
                  <Grid item xs={2}>
                     <Typography style={{ marginLeft: 5 }} className={classes.valueText}>
                        %{percent}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default TagItem;

import React from 'react';

const Marked = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='10.24' height='7.568' viewBox='0 0 10.24 7.568'>
         <path
            id='Path_3500'
            data-name='Path 3500'
            d='M2386.691,2930.215l.936-1,3.575-3.793a.935.935,0,0,1,.775-.306.959.959,0,0,1,.715.444,1.039,1.039,0,0,1-.116,1.318q-2.582,2.748-5.169,5.49a.958.958,0,0,1-1.416,0q-1.522-1.627-3.055-3.244a1.087,1.087,0,0,1-.214-1.178.944.944,0,0,1,1.248-.521,1.2,1.2,0,0,1,.355.255q1.161,1.236,2.325,2.47A.558.558,0,0,1,2386.691,2930.215Z'
            transform='translate(-2382.635 -2925.116)'
            fill='#24e19f'
         />
      </svg>
   );
};

export default Marked;

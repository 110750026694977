import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import IOSSwitch from '../SharedComponents/IOSSwitch';
import classNames from 'classnames';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';
import Modal from '../../../../../components/Modal/modal';
import DeleteAccountModalContent from './DeleteAccountModalContent';
import FormSaveAndCancelButtons from '../SharedComponents/FormSaveAndCancelButtons';
import { useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import ButtonCancel from '../../../../../components/ButtonCancel/buttonCancel';
import Input from '../../../../../components/InputField';
import { GET_PLATFORMS_QUERY } from '../../../../../queries/Settings/Service/GraphQL/Platform/query';
import { PLATFORM_TYPE } from '../../../../../queries/Settings/Service/GraphQL/Platform/types';
import ButtonOK from '@components/ButtonOK/buttonOk';
import EnableAccountModalContent from './EnableAccountModelContent';
import { LIVE_CHAT_VISIBILITY } from '@modules/Settings/SettingSpace/LiveChat/types';

const useStyles = makeStyles(() => ({
   formContainer: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: '20px 30px',
   },
   form: {
      paddingTop: '10px',
   },
   section: {
      background: '#F7F7FA',
      padding: '1rem !important',
      marginBottom: '1rem',
      borderRadius: 8,
   },
   sectionHeaderLeft: {
      flexGrow: 1,
   },
   sectionHeaderRight: {
      flexShrink: 0,
   },
   sectionBorderBottom: {
      borderBottom: '1px solid #EBEBEB',
   },
   sectionBody: {
      paddingTop: '1rem',
   },
   firstInputContainer: {
      paddingTop: '1.2rem',
      marginBottom: '5px',
   },
   inputContainer: {
      marginBottom: '5px',
   },
   lastInputContainer: {
      marginBottom: '-1rem',
   },
   inlineInputLeft: {
      paddingRight: '.5rem',
   },
   inlineInputRight: {
      paddingLeft: '.5rem',
   },
   btnActionContainer: {
      paddingRight: '4rem',
      paddingTop: '2rem',
   },
   cancelBtn: {
      color: 'white',
      boxShadow: 'none',
      fontSize: '1.2rem',
      fontWeight: 500,
      background: '#F4425B',
      paddingRight: '2.75rem',
      paddingLeft: '2.75rem',
   },
   saveBtn: {
      color: 'white',
      boxShadow: 'none',
      fontSize: '1.2rem',
      fontWeight: 500,
      paddingRight: '2.75rem',
      paddingLeft: '2.75rem',
   },
   sectionHeaderText: {
      fontSize: '1.1rem',
      fontWeight: 600,
   },
   sectionSubHeaderText: {
      color: '#ACACAC',
      fontSize: '.85rem',
   },
}));

const Form = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { liveChatPreviewSettings, updateLiveChatPreviewSettings, dataUpdatedFromServer, updateLiveChatSettings } =
      useContext(LiveChatSettingsContext);
   const liveChat = useSelector((state) => state.liveChatReducer);
   const [visibilityOfDisableModal, setVisibilityOfDisableModal] = useState(false);
   const [visibilityOfEnableModal, setVisibilityOfEnableModal] = useState(false);
   const person = useSelector((rootState) => rootState?.personReducer?.person);
   const [whatsappAccount, setWhatsappAccount] = useState(liveChat?.liveChatSettings.whatsappAccount);
   const [facebookAccount, setFacebookAccount] = useState(liveChat?.liveChatSettings.facebookAccount);
   const [instagramAccount, setInstagramAccount] = useState(liveChat?.liveChatSettings.instagramAccount);
   const [whatsAppAccountList, setWhatsAppAccountList] = useState({ web: [], business: [] });
   const [facebookAccountList, setFacebookAccountList] = useState([]);
   const [instagramAccountList, setInstagramAccountList] = useState([]);

   const liveChatVisibilityOptions = [
      {
         value: LIVE_CHAT_VISIBILITY.NOT_VISIBLE,
         label: t['page_settings_typo_platforms_live_chat_settings_section_livechat_not_visible'],
      },
      {
         value: LIVE_CHAT_VISIBILITY.VISIBLE,
         label: t['page_settings_typo_platforms_live_chat_settings_section_livechat_visible'],
      },
      {
         value: LIVE_CHAT_VISIBILITY.ONLY_INSIDE_WORKING_HOURS,
         label: t['page_settings_typo_platforms_live_chat_settings_section_livechat_only_inside_working_hours'],
      },
      {
         value: LIVE_CHAT_VISIBILITY.ONLY_OUTSIDE_WORKING_HOURS,
         label: t['page_settings_typo_platforms_live_chat_settings_section_livechat_only_outside_working_hours'],
      },
   ];

   const selectedWhatsAppLabel = useMemo(() => {
      if (!!whatsappAccount) {
         if (whatsAppAccountList?.web?.length > 0) {
            const web = whatsAppAccountList?.web?.find((item) => item.value === whatsappAccount);
            if (!!web) {
               return web.label;
            }
         }

         if (whatsAppAccountList?.business?.length > 0) {
            const business = whatsAppAccountList?.business?.find((item) => item.value === whatsappAccount);
            if (!!business) {
               return business.label;
            }
         }
      }
      return t?.livechat_select_options;
   }, [whatsAppAccountList, whatsappAccount]);

   const selectedFacebookLabel = useMemo(() => {
      if (!!facebookAccount && facebookAccountList && facebookAccountList.length > 0) {
         const item = facebookAccountList.find((item) => item.value === facebookAccount);
         if (!!item) {
            return item.label;
         }
      }
      return t?.livechat_select_options;
   }, [facebookAccountList, facebookAccount]);

   const selectedInstagramLabel = useMemo(() => {
      if (!!instagramAccount && instagramAccountList && instagramAccountList.length > 0) {
         const item = instagramAccountList.find((item) => item.value === instagramAccount);
         if (!!item) {
            return item.label;
         }
      }
      return t?.livechat_select_options;
   }, [instagramAccountList, instagramAccount]);

   useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, customer: person?.customer._id },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
         if (res?.getPlatforms?.data) {
            setWhatsAppAccountList(({ business }) => ({
               business,
               web: res?.getPlatforms?.data?.map((platform) => ({
                  value: platform.number,
                  label: platform.number,
               })),
            }));
         }
      },
   });

   useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.WHATS_APP_BUSINESS, customer: person?.customer._id },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
         if (res?.getPlatforms?.data) {
            setWhatsAppAccountList(({ web }) => ({
               web,
               business: res?.getPlatforms?.data?.map((platform) => ({
                  value: platform.phone,
                  label: platform.phone,
               })),
            }));
         }
      },
   });

   useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.FACEBOOK, customer: person?.customer._id },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
         if (res?.getPlatforms?.data) {
            setFacebookAccountList(
               res?.getPlatforms?.data?.map((platform) => ({
                  value: platform?.facebookPageId,
                  label: platform?.name,
               })),
            );
         }
      },
   });

   useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.INSTAGRAM, customer: person?.customer._id },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
         if (res?.getPlatforms?.data) {
            setInstagramAccountList(
               res?.getPlatforms?.data?.map((platform) => ({
                  value: platform?.category.replace('@', ''),
                  label: platform?.category.replace('@', ''),
               })),
            );
         }
      },
   });

   const closeDisableModal = () => setVisibilityOfDisableModal(false);
   const closeEnableModal = () => setVisibilityOfEnableModal(false);

   const defaultValues = {
      onlineStatus: liveChatPreviewSettings.onlineStatus,
      agentProfiles: liveChatPreviewSettings.agentProfiles,
      notificationSound: liveChatPreviewSettings.notificationSound,
      liveChatVisibility: liveChatPreviewSettings.liveChatVisibility,
      selectedLiveChatVisibilityOption:
         liveChatVisibilityOptions.find((item) => item.value === liveChatPreviewSettings.liveChatVisibility) ||
         liveChatVisibilityOptions[1],
      isWhatsapp: liveChatPreviewSettings.isWhatsapp,
      whatsappButtonTitle: liveChatPreviewSettings.whatsappButtonTitle,
      whatsappTitle: liveChatPreviewSettings.whatsappTitle,
      whatsappContent: liveChatPreviewSettings.whatsappContent,
      isFacebook: liveChatPreviewSettings.isFacebook,
      facebookButtonTitle: liveChatPreviewSettings.facebookButtonTitle,
      facebookTitle: liveChatPreviewSettings.facebookTitle,
      facebookContent: liveChatPreviewSettings.facebookContent,
      isInstagram: liveChatPreviewSettings.isInstagram,
      instagramButtonTitle: liveChatPreviewSettings.instagramButtonTitle,
      instagramTitle: liveChatPreviewSettings.instagramTitle,
      instagramContent: liveChatPreviewSettings.instagramContent,
      isPhoneCall: liveChatPreviewSettings.isPhoneCall,
      phoneCallButtonTitle: liveChatPreviewSettings.phoneCallButtonTitle,
      phoneCallNumber: liveChatPreviewSettings.phoneCallNumber,
      phoneCallTitle: liveChatPreviewSettings.phoneCallTitle,
      phoneCallContent: liveChatPreviewSettings.phoneCallContent,
      facebookAccount: liveChatPreviewSettings.facebookAccount,
      whatsappAccount: liveChatPreviewSettings.whatsappAccount,
      instagramAccount: liveChatPreviewSettings.instagramAccount,
   };

   const { control, watch, setValue, reset } = useForm({
      mode: 'all',
      defaultValues: useMemo(() => {
         return defaultValues;
      }, [defaultValues]),
   });

   useEffect(() => {
      reset(defaultValues);
   }, [dataUpdatedFromServer]);

   const {
      onlineStatus,
      agentProfiles,
      notificationSound,
      selectedLiveChatVisibilityOption,
      isWhatsapp,
      whatsappButtonTitle,
      whatsappTitle,
      whatsappContent,
      isFacebook,
      facebookButtonTitle,
      facebookTitle,
      facebookContent,
      isInstagram,
      instagramButtonTitle,
      instagramTitle,
      instagramContent,
      isPhoneCall,
      phoneCallButtonTitle,
      phoneCallNumber,
      phoneCallTitle,
      phoneCallContent,
   } = watch();

   useEffect(() => {
      updateLiveChatPreviewSettings({
         onlineStatus,
         agentProfiles,
         notificationSound,
         liveChatVisibility: selectedLiveChatVisibilityOption?.value,
         isWhatsapp,
         whatsappButtonTitle,
         whatsappTitle,
         whatsappContent,
         isFacebook,
         facebookButtonTitle,
         facebookTitle,
         facebookContent,
         isInstagram,
         instagramButtonTitle,
         instagramTitle,
         instagramContent,
         isPhoneCall,
         phoneCallButtonTitle,
         phoneCallNumber,
         phoneCallTitle,
         phoneCallContent,
         whatsappAccount,
         facebookAccount,
         instagramAccount,
      });
   }, [
      onlineStatus,
      agentProfiles,
      notificationSound,
      selectedLiveChatVisibilityOption?.value,
      isWhatsapp,
      whatsappButtonTitle,
      whatsappTitle,
      whatsappContent,
      isFacebook,
      facebookButtonTitle,
      facebookTitle,
      facebookContent,
      isInstagram,
      instagramButtonTitle,
      instagramTitle,
      instagramContent,
      isPhoneCall,
      phoneCallButtonTitle,
      phoneCallNumber,
      facebookAccount,
      whatsappAccount,
      instagramAccount,
      phoneCallTitle,
      phoneCallContent,
   ]);

   if (!liveChatPreviewSettings) return <div></div>;

   return (
      <>
         <div className={classes.formContainer}>
            <Grid container alignItems='center' spacing={1} className={classes.form}>
               <Grid item md={12} className={classes.section}>
                  <Grid container spacing={3} alignItems='center'>
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.sectionHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_online_status_title']}
                        </Typography>
                        <Typography className={classes.sectionSubHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_online_status_subtitle']}
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch checked={onlineStatus} onChange={() => setValue('onlineStatus', !onlineStatus)} />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid container spacing={3} alignItems='center'>
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.sectionHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_agent_profiles_title']}
                        </Typography>
                        <Typography className={classes.sectionSubHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_agent_profiles_subtitle']}
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch checked={agentProfiles} onChange={() => setValue('agentProfiles', !agentProfiles)} />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid container spacing={3} alignItems='center'>
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.sectionHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_notification_sound_title']}
                        </Typography>
                        <Typography className={classes.sectionSubHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_notification_sound_subtitle']}
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch
                           checked={notificationSound}
                           onChange={() => setValue('notificationSound', !notificationSound)}
                        />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid container spacing={3} alignItems='center'>
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.sectionHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_livechat_title']}
                        </Typography>
                        <Grid
                           item
                           className={classNames(classes.firstInputContainer, classes.inlineInputRight)}
                           style={{ marginBottom: '-1rem' }}
                        >
                           <Input
                              name='selectedLiveChatVisibilityOption'
                              label={
                                 t['page_settings_typo_platforms_live_chat_settings_section_livechat_visibility_label']
                              }
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_whatsapp_select_account_placeholder'
                                 ]
                              }
                              selectOptions={liveChatVisibilityOptions}
                              isSelect={true}
                              control={control}
                              background={'#FFFFFF'}
                              style={{ margin: '0 !important' }}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid
                     container
                     spacing={3}
                     alignItems='center'
                     className={classNames(isWhatsapp && classes.sectionBorderBottom)}
                  >
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.sectionHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_title']}
                        </Typography>
                        <Typography className={classes.sectionSubHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_subtitle']}
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch checked={isWhatsapp} onChange={() => setValue('isWhatsapp', !isWhatsapp)} />
                     </Grid>
                  </Grid>

                  {isWhatsapp && (
                     <Grid container className={classes.sectionBody}>
                        <Grid item md={7} className={classNames(classes.firstInputContainer, classes.inlineInputLeft)}>
                           <Input
                              name='whatsappButtonTitle'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_whatsapp_button_title_placeholder'
                                 ]
                              }
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_whatsapp_button_title_label'
                                 ]
                              }
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_whatsapp_button_title_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={5} className={classNames(classes.firstInputContainer, classes.inlineInputRight)}>
                           <Input
                              name='whatsappAccount'
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_whatsapp_account_button_title_label'
                                 ]
                              }
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_whatsapp_select_account_placeholder'
                                 ]
                              }
                              value={{
                                 label: selectedWhatsAppLabel,
                                 value: whatsappAccount,
                              }}
                              onChange={(e) => setWhatsappAccount(e.value)}
                              selectOptions={[
                                 { value: '', label: t?.livechat_select_options },
                                 ...whatsAppAccountList.web,
                                 ...whatsAppAccountList.business,
                              ]}
                              isSelect={true}
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_whatsapp_select_account_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={12} className={classes.inputContainer}>
                           <Input
                              name='whatsappTitle'
                              placeholder={
                                 t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_title_placeholder']
                              }
                              label={t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_title_label']}
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_whatsapp_title_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={12} className={classes.lastInputContainer}>
                           <Input
                              name='whatsappContent'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_whatsapp_content_placeholder'
                                 ]
                              }
                              label={
                                 t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_content_label']
                              }
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              multiline
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_whatsapp_content_required'
                                    ],
                              }}
                           />
                        </Grid>
                     </Grid>
                  )}
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid
                     container
                     spacing={3}
                     alignItems='center'
                     className={classNames(isFacebook && classes.sectionBorderBottom)}
                  >
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.sectionHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_facebook_title']}
                        </Typography>
                        <Typography className={classes.sectionSubHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_facebook_subtitle']}
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch checked={isFacebook} onChange={() => setValue('isFacebook', !isFacebook)} />
                     </Grid>
                  </Grid>

                  {isFacebook && (
                     <Grid container className={classes.sectionBody}>
                        <Grid item md={7} className={classNames(classes.firstInputContainer, classes.inlineInputLeft)}>
                           <Input
                              name='facebookButtonTitle'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_facebook_button_title_placeholder'
                                 ]
                              }
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_facebook_button_title_label'
                                 ]
                              }
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_facebook_button_title_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={5} className={classNames(classes.firstInputContainer, classes.inlineInputRight)}>
                           <Input
                              name='facebookAccount'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_facebook_select_account_placeholder'
                                 ]
                              }
                              isSelect
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_facebook_account_button_title_label'
                                 ]
                              }
                              value={{
                                 label: selectedFacebookLabel,
                                 value: facebookAccount,
                              }}
                              onChange={(e) => setFacebookAccount(e.value)}
                              selectOptions={[{ value: '', label: t?.livechat_select_options }, ...facebookAccountList]}
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_facebook_select_account_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={12} className={classes.inputContainer}>
                           <Input
                              name='facebookTitle'
                              placeholder={
                                 t['page_settings_typo_platforms_live_chat_settings_section_facebook_title_placeholder']
                              }
                              label={t['page_settings_typo_platforms_live_chat_settings_section_facebook_title_label']}
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_facebook_title_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={12} className={classes.lastInputContainer}>
                           <Input
                              name='facebookContent'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_facebook_content_placeholder'
                                 ]
                              }
                              label={
                                 t['page_settings_typo_platforms_live_chat_settings_section_facebook_content_label']
                              }
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              multiline
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_facebook_content_required'
                                    ],
                              }}
                           />
                        </Grid>
                     </Grid>
                  )}
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid
                     container
                     spacing={3}
                     alignItems='center'
                     className={classNames(isInstagram && classes.sectionBorderBottom)}
                  >
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.sectionHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_instagram_title']}
                        </Typography>
                        <Typography className={classes.sectionSubHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_instagram_subtitle']}
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch checked={isInstagram} onChange={() => setValue('isInstagram', !isInstagram)} />
                     </Grid>
                  </Grid>

                  {isInstagram && (
                     <Grid container className={classes.sectionBody}>
                        <Grid item md={7} className={classNames(classes.firstInputContainer, classes.inlineInputLeft)}>
                           <Input
                              name='instagramButtonTitle'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_instagram_button_title_placeholder'
                                 ]
                              }
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_instagram_button_title_label'
                                 ]
                              }
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_instagram_button_title_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={5} className={classNames(classes.firstInputContainer, classes.inlineInputRight)}>
                           <Input
                              name='instagramAccount'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_instagram_select_account_placeholder'
                                 ]
                              }
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_instagram_account_button_title_label'
                                 ]
                              }
                              isSelect
                              value={{
                                 label: selectedInstagramLabel,
                                 value: instagramAccount,
                              }}
                              onChange={(e) => setInstagramAccount(e.value)}
                              selectOptions={[
                                 { value: '', label: t?.livechat_select_options },
                                 ...instagramAccountList,
                              ]}
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_instagram_select_account_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={12} className={classes.inputContainer}>
                           <Input
                              name='instagramTitle'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_instagram_title_placeholder'
                                 ]
                              }
                              label={t['page_settings_typo_platforms_live_chat_settings_section_instagram_title_label']}
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_instagram_title_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={12} className={classes.lastInputContainer}>
                           <Input
                              name='instagramContent'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_instagram_content_placeholder'
                                 ]
                              }
                              label={
                                 t['page_settings_typo_platforms_live_chat_settings_section_instagram_content_label']
                              }
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              multiline
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_instagram_content_required'
                                    ],
                              }}
                           />
                        </Grid>
                     </Grid>
                  )}
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid
                     container
                     spacing={3}
                     alignItems='center'
                     className={classNames(isPhoneCall && classes.sectionBorderBottom)}
                  >
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.sectionHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_phone_call_title']}
                        </Typography>
                        <Typography className={classes.sectionSubHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_phone_call_subtitle']}
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch checked={isPhoneCall} onChange={() => setValue('isPhoneCall', !isPhoneCall)} />
                     </Grid>
                  </Grid>

                  {isPhoneCall && (
                     <Grid container className={classes.sectionBody}>
                        <Grid item md={7} className={classNames(classes.firstInputContainer, classes.inlineInputLeft)}>
                           <Input
                              name='phoneCallButtonTitle'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_phone_call_button_title_placeholder'
                                 ]
                              }
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_phone_call_button_title_label'
                                 ]
                              }
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_phone_call_button_title_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={5} className={classNames(classes.firstInputContainer, classes.inlineInputRight)}>
                           <Input
                              name='phoneCallNumber'
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_phone_call_phone_number_label'
                                 ]
                              }
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_phone_call_phone_number_placeholder'
                                 ]
                              }
                              rules={{
                                 required: isPhoneCall
                                    ? t[
                                         'page_settings_typo_platforms_live_chat_settings_section_phone_call_phone_number_required'
                                      ]
                                    : undefined,
                              }}
                              labelColor='black'
                              isPhone
                              splitCountryCode={false}
                              control={control}
                              background={'#FFFFFF'}
                           />
                        </Grid>
                        <Grid item md={12} className={classes.inputContainer}>
                           <Input
                              name='phoneCallTitle'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_phone_call_title_placeholder'
                                 ]
                              }
                              label={
                                 t['page_settings_typo_platforms_live_chat_settings_section_phone_call_title_label']
                              }
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_phone_call_title_required'
                                    ],
                              }}
                           />
                        </Grid>
                        <Grid item md={12} className={classes.lastInputContainer}>
                           <Input
                              name='phoneCallContent'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_settings_section_phone_call_content_placeholder'
                                 ]
                              }
                              label={
                                 t['page_settings_typo_platforms_live_chat_settings_section_phone_call_content_label']
                              }
                              labelColor='black'
                              control={control}
                              background={'#FFFFFF'}
                              multiline
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_settings_section_phone_call_content_required'
                                    ],
                              }}
                           />
                        </Grid>
                     </Grid>
                  )}
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid container spacing={3} alignItems='center'>
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.sectionHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_remove_channel_title']}
                        </Typography>
                        <Typography className={classes.sectionSubHeaderText}>
                           {t['page_settings_typo_platforms_live_chat_settings_section_remove_channel_subtitle']}
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        {liveChatPreviewSettings.connected ? (
                           <ButtonCancel
                              // label={
                              //    t['page_settings_typo_platforms_live_chat_settings_section_remove_channel_button_label']
                              // }
                              label={t?.page_settings_typo_platforms_live_chat_settings_disable_account}
                              variant={'contained'}
                              onClick={() => setVisibilityOfDisableModal(true)}
                           />
                        ) : (
                           <ButtonOK
                              label='Enable Account'
                              isPrimary
                              variant={'contained'}
                              onClick={() => setVisibilityOfEnableModal(true)}
                           />
                        )}
                     </Grid>
                  </Grid>
               </Grid>
            </Grid>
         </div>
         <FormSaveAndCancelButtons resetValues={reset} />
         <Modal
            paperStyle={{ width: 450 }}
            open={visibilityOfDisableModal}
            onClose={closeDisableModal}
            modalHeader={t['page_settings_typo_platforms_live_chat_settings_section_remove_channel_modal_title']}
         >
            <DeleteAccountModalContent
               onClose={closeDisableModal}
               onAccept={() => {
                  updateLiveChatPreviewSettings({ ...updateLiveChatPreviewSettings, connected: false });
                  updateLiveChatSettings(updateLiveChatPreviewSettings, { connected: false });
                  closeDisableModal();
               }}
            />
         </Modal>
         <Modal
            paperStyle={{ width: 450 }}
            open={visibilityOfEnableModal}
            onClose={closeEnableModal}
            modalHeader='Enable account'
         >
            <EnableAccountModalContent
               onClose={closeEnableModal}
               onAccept={() => {
                  updateLiveChatPreviewSettings({ ...updateLiveChatPreviewSettings, connected: true });
                  updateLiveChatSettings(updateLiveChatPreviewSettings, { connected: true });
                  closeEnableModal();
               }}
            />
         </Modal>
      </>
   );
};

export default Form;

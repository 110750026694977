import {
   Divider,
   FormControlLabel,
   Grid,
   IconButton,
   Popover,
   Table,
   TableBody,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useContext, useState, useEffect } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import MyTypography from '@components/MyTypography';
import CustomSwitch from '@components/Switch';
import AvatarTable from '../shared-components/AvatarTable';
import { StyledTableCell, StyledTableRow } from '../shared-components/tableItems';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import { PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/Platform/types';
import { useMutation } from '@apollo/client';
import { GET_PLATFORM_PAGE_DATA } from '@queries/Settings/Service/GraphQL/Platform/mutation';
import { useSnackbar } from 'notistack';
import { PlatformStatus } from '../types';
import DeletePlatformModal from '@components/Modal/DeletePlatformModal';
import ActionIconButton from '@modules/Settings/SettingSpace/ActionIconButton';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '0.9rem',
      fontWeight: 'bold',
   },
   label: {
      paddingRight: 8,
   },
   popoverContent: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: 250,
   },
   popoverHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 5,
      marginBottom: 5,
   },
   popoverHeaderLeft: { display: 'flex', flexDirection: 'column', marginRight: 8 },
   popoverHeaderLeftTitle: { fontWeight: 'bold' },
   popoverHeaderLeftCaption: { color: theme.chatApp.general.pallet.passiveStructureBlue, lineHeight: 1 },
   popoverHeaderRightIconButton: { alignSelf: 'center', padding: 0 },
   popoverHeaderRightIcon: { color: theme.chatApp.general.pallet.passiveStructureBlue, fontSize: 30 },
   popoverListItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginLeft: 10,
      marginRight: 10,
      marginTop: 5,
      marginBottom: 5,
   },
   popoverListItemLabel: { color: '#444', fontWeight: 'bold' },
   popoverListItemValue: { fontWeight: 'bolder' },
}));

const TableData = ({ tableData, handleUpdate, handleDelete, onChangeCommentSwitch, onChangeMessageSwitch }) => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   const theme = useTheme();
   const { enqueueSnackbar } = useSnackbar();

   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [selectedDeleteItemId, setSelectedDeletedItemId] = useState(null);

   const [anchorElPopper, setAnchorElPopper] = useState(null);
   const handleClickPopper = (event) => {
      setAnchorElPopper(event.currentTarget);
   };
   const handleClosePopper = () => {
      setAnchorElPopper(null);
   };
   const openPopper = Boolean(anchorElPopper);

   const [profileActivity, setProfileActivity] = useState({});

   const [getInstaPageDataMutation, { data: instaPageActivity, loading: getInstaPageDataLoading }] = useMutation(
      GET_PLATFORM_PAGE_DATA,
      {
         variables: {
            type: PLATFORM_TYPE.INSTAGRAM,
            metaData: { instagramPageId: tableData[0]?.instagramPageId },
         },
      },
   );

   useEffect(() => {
      getInstaPageDataMutation();
   }, [getInstaPageDataMutation]);

   useEffect(() => {
      if (instaPageActivity?.doCustomPlatformMutation?.data) {
         if (instaPageActivity?.doCustomPlatformMutation?.data.isError) {
            enqueueSnackbar(instaPageActivity?.doCustomPlatformMutation?.data.reason, {
               variant: 'error',
               autoHideDuration: 2500,
            });
         }
         setProfileActivity(instaPageActivity?.doCustomPlatformMutation?.data);
      }
   }, [instaPageActivity]);

   return (
      <TableContainer style={{ padding: 10 }}>
         <Table className={classes.table} aria-label='customized table'>
            <TableHead>
               <TableRow>
                  <StyledTableCell style={{ width: 300 }}> {t['page_settings_instagram_page_name']}</StyledTableCell>
                  <StyledTableCell align='left'>{t['teams']}</StyledTableCell>
                  <StyledTableCell align='left'>{t['direct_messages']}</StyledTableCell>
                  <StyledTableCell align='left'>{t['comments']}</StyledTableCell>
                  <StyledTableCell align='left'>{t['status']}</StyledTableCell>
                  <StyledTableCell align='right'>{t['page_settings_process']}</StyledTableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {tableData?.map((instagramPage) => (
                  <StyledTableRow key={instagramPage?._id.toString()}>
                     <StyledTableCell component='th' scope='row' className={classes.coloredText}>
                        <div style={{ display: 'flex' }}>
                           <AvatarTable
                              pageName={instagramPage?.name}
                              avatarUrl={instagramPage?.avatarUrl}
                              pageUsername={
                                 instagramPage?.instagramAccount?.firstName +
                                 ' ' +
                                 instagramPage?.instagramAccount?.lastName
                              }
                           />
                           <IconButton onClick={handleClickPopper} style={{ alignSelf: 'center' }}>
                              <InfoRoundedIcon />
                           </IconButton>
                           <Popover
                              open={openPopper}
                              onClose={handleClosePopper}
                              anchorEl={anchorElPopper}
                              anchorOrigin={{
                                 vertical: 'bottom',
                                 horizontal: 'center',
                              }}
                              transformOrigin={{
                                 vertical: 'top',
                                 horizontal: 'center',
                              }}
                           >
                              <div className={classes.popoverContent}>
                                 <div className={classes.popoverHeader}>
                                    <div className={classes.popoverHeaderLeft}>
                                       <Typography variant='subtitle1' className={classes.popoverHeaderLeftTitle}>
                                          {t['page_settings_instagram_profile_summary']}
                                       </Typography>
                                       <Typography variant='body2' className={classes.popoverHeaderLeftCaption}>
                                          {t['page_settings_instagram_last_30_days']}
                                       </Typography>
                                    </div>
                                    <IconButton
                                       disableTouchRipple={true}
                                       onClick={() => getInstaPageDataMutation()}
                                       className={classes.popoverHeaderRightIconButton}
                                    >
                                       <CachedRoundedIcon className={classes.popoverHeaderRightIcon} />
                                    </IconButton>
                                 </div>
                                 <Divider />
                                 <div className={classes.popoverListItem}>
                                    <Typography variant='subtitle2' className={classes.popoverListItemLabel}>
                                       {t['page_settings_instagram_reached_users']}
                                    </Typography>
                                    <Typography variant='subtitle1' className={classes.popoverListItemValue}>
                                       {profileActivity.reach === undefined || profileActivity.reach === null
                                          ? '-'
                                          : profileActivity.reach}
                                    </Typography>
                                 </div>
                                 <Divider />
                                 <div className={classes.popoverListItem}>
                                    <Typography variant='subtitle2' className={classes.popoverListItemLabel}>
                                       {t['page_settings_instagram_profile_views']}
                                    </Typography>
                                    <Typography variant='subtitle1' className={classes.popoverListItemValue}>
                                       {profileActivity.profile_views === undefined ||
                                       profileActivity.profile_views === null
                                          ? '-'
                                          : profileActivity.profile_views}
                                    </Typography>
                                 </div>
                                 <Divider />
                                 <div className={classes.popoverListItem}>
                                    <Typography variant='subtitle2' className={classes.popoverListItemLabel}>
                                       {t['page_settings_instagram_new_followers']}
                                    </Typography>
                                    <Typography variant='subtitle1' className={classes.popoverListItemValue}>
                                       {profileActivity.follower_count === undefined ||
                                       profileActivity.follower_count === null
                                          ? '-'
                                          : profileActivity.follower_count}
                                    </Typography>
                                 </div>
                                 <Divider />
                                 <div className={classes.popoverListItem}>
                                    <Typography variant='subtitle2' className={classes.popoverListItemLabel}>
                                       {t['page_settings_instagram_website_clicks']}
                                    </Typography>
                                    <Typography variant='subtitle1' className={classes.popoverListItemValue}>
                                       {profileActivity.website_clicks === undefined ||
                                       profileActivity.website_clicks === null
                                          ? '-'
                                          : profileActivity.website_clicks}
                                    </Typography>
                                 </div>
                              </div>
                           </Popover>
                        </div>
                     </StyledTableCell>
                     <StyledTableCell component='th' scope='row'>
                        <Typography style={{ color: instagramPage.teams.length > 0 ? 'initial' : 'red' }}>
                           {instagramPage.teams.length > 0
                              ? instagramPage.teams.length + ' ' + t['teams_with_number']
                              : t.no_teams}
                        </Typography>
                     </StyledTableCell>
                     <StyledTableCell className={classes.coloredText}>
                        <Grid container alignItems='center'>
                           <FormControlLabel
                              control={
                                 <CustomSwitch
                                    checked={instagramPage?.isMessageEnable}
                                    onChange={() => {
                                       onChangeMessageSwitch(instagramPage);
                                    }}
                                 />
                              }
                              label={instagramPage?.isMessageEnable ? t?.enabled : t?.disabled}
                              labelPlacement='start'
                              classes={{
                                 label: classes.label,
                              }}
                           />
                        </Grid>
                     </StyledTableCell>
                     <StyledTableCell className={classes.coloredText}>
                        <Grid container alignItems='center'>
                           <FormControlLabel
                              control={
                                 <CustomSwitch
                                    checked={instagramPage?.isCommentEnable}
                                    onChange={() => {
                                       onChangeCommentSwitch(instagramPage);
                                    }}
                                 />
                              }
                              label={instagramPage?.isCommentEnable ? t?.enabled : t?.disabled}
                              labelPlacement='start'
                              classes={{
                                 label: classes.label,
                              }}
                           />
                        </Grid>
                     </StyledTableCell>
                     <StyledTableCell align='left' className={classes.coloredText}>
                        <MyTypography
                           style={{
                              color:
                                 instagramPage?.status == PlatformStatus.CONNECTED
                                    ? theme.chatApp.general.pallet.confirmGreen
                                    : 'red',
                           }}
                        >
                           {instagramPage?.status == PlatformStatus.CONNECTED ? t?.connected : t?.disconnected}
                        </MyTypography>
                     </StyledTableCell>
                     <StyledTableCell align='right' className={classes.coloredText}>
                        <ActionIconButton
                           onClick={() => handleUpdate()}
                           tooltip={t?.comp_button_update}
                           actionType='REFRESH'
                        />
                        <ActionIconButton
                           onClick={() => {
                              setShowDeleteModal(true);
                              setSelectedDeletedItemId(instagramPage?._id);
                           }}
                           tooltip={t?.comp_button_delete}
                           actionType='DELETE'
                        />
                     </StyledTableCell>
                  </StyledTableRow>
               ))}
            </TableBody>
         </Table>
         <DeletePlatformModal
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            selectedDeleteItemId={selectedDeleteItemId}
            onComplate={handleDelete}
         />
      </TableContainer>
   );
};

export default TableData;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   root: {
      // backgroundColor: 'red',
   },
   text: {
      color: '#7F92A5',
      fontSize: '0.9rem',
   },
   line: {
      width: '100%',
      borderTop: 'solid 1px #eee',
   },
}));

const DateSeperator = ({ time }) => {
   const classes = useStyles();

   return (
      <Grid
         container
         className={classes.root}
         alignItems='center'
         justifyContent='space-between'
         direction='row'
         wrap='nowrap'
      >
         <Grid item xs={5}>
            <div className={classes.line} />
         </Grid>
         <Grid>
            <Typography className={classes.text}>{time}</Typography>
         </Grid>
         <Grid item xs={5}>
            <div className={classes.line} />
         </Grid>
      </Grid>
   );
};

export default DateSeperator;

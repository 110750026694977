import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../../../../fragments';

// mutations for customer products

export const CREATE_NEW_PAYMENT_CARD_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation createNewPaymentCardMutation(
      $customer: ID!
      $cardAlias: String!
      $cardHolderName: String!
      $cardNumber: String!
      $expireMonth: String!
      $expireYear: String!
      $cvc: String!
   ) {
      createNewPaymentCard(
         data: {
            customer: $customer
            cardAlias: $cardAlias
            cardHolderName: $cardHolderName
            cardNumber: $cardNumber
            expireMonth: $expireMonth
            expireYear: $expireYear
            cvc: $cvc
         }
      ) {
         data {
            ...CustomerFields
         }
         code
         message
         success
      }
   }
`;

export const MAKE_DEFAULT_PAYMENT_CARD_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation changeDefaultPaymentCardMutation($customer: ID!, $binNumber: String!, $lastFourDigits: String!) {
      makeDefaultPaymentCard(data: { customer: $customer, binNumber: $binNumber, lastFourDigits: $lastFourDigits }) {
         data {
            ...CustomerFields
         }
         success
         code
         message
      }
   }
`;
export const REMOVE_PAYMENT_CARD_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation removePaymentCardMutation($customer: ID!, $binNumber: String!, $lastFourDigits: String!) {
      removePaymentCard(data: { customer: $customer, binNumber: $binNumber, lastFourDigits: $lastFourDigits }) {
         data {
            ...CustomerFields
         }
         success
         message
         code
      }
   }
`;

export const BUY_CUSTOMER_PRODUCT_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation buyCustomerProductMutation(
      $customer: ID!
      $product: ID!
      $person: ID!
      $code: String
      $mode: String!
      $ip: String
      $cardAlias: String
      $cardHolderName: String
      $cardNumber: Double
      $expireMonth: Double
      $expireYear: Double
      $cvc: Double
      $registerCard: Double
      $cardUserKey: String
      $cardToken: String
      $users: Int!
      $session: Object
   ) {
      buyCustomerProduct(
         data: {
            customer: $customer
            product: $product
            mode: $mode
            code: $code
            ip: $ip
            cardAlias: $cardAlias
            cardHolderName: $cardHolderName
            cardNumber: $cardNumber
            expireMonth: $expireMonth
            expireYear: $expireYear
            cvc: $cvc
            registerCard: $registerCard
            cardUserKey: $cardUserKey
            cardToken: $cardToken
            person: $person
            users: $users
            session: $session
         }
      ) {
         data {
            ...CustomerFields
         }
         success
         code
         message
      }
   }
`;

export const BUY_CUSTOMER_PRODUCT_3D_MUTATION = gql`
   mutation buyCustomerProduct3dMutation(
      $customer: ID!
      $product: ID!
      $code: String
      $mode: String!
      $ip: String
      $cardAlias: String
      $cardHolderName: String
      $cardNumber: Double
      $expireMonth: Double
      $expireYear: Double
      $cvc: Double
      $registerCard: Double
   ) {
      buyCustomerProduct3d(
         data: {
            customer: $customer
            product: $product
            mode: $mode
            code: $code
            ip: $ip
            cardAlias: $cardAlias
            cardHolderName: $cardHolderName
            cardNumber: $cardNumber
            expireMonth: $expireMonth
            expireYear: $expireYear
            cvc: $cvc
            registerCard: $registerCard
         }
      ) {
         data {
            tmpCustomerProduct
            html
         }
         success
         message
         code
      }
   }
`;

export const UPDATE_CUSTOMER_PRODUCT_MUTATION = gql`
   mutation updateCustomerProductMutation($_id: ID!, $autoRenewal: Boolean, $periodMode: Int) {
      updatePersonCustomerProduct(data: { _id: $_id, autoRenewal: $autoRenewal, periodMode: $periodMode }) {
         data {
            _id
            name
            channels
            personCount
            clientCount
            messageCount
            whatsappWebCount
            teamsCount
            accountBalance
            whatsappBusinessCount
            facebookPageCount
            twitterCount
            instagramCount
            teamChat
            support
            price
            type
            paymentStatus
            endDate
            createdAt
         }
         success
         message
         code
      }
   }
`;

export const BUY_CUSTOMER_ADD_ON_MUTATION = gql`
   mutation buyCustomerProductAddOnMutation(
      $addOnId: ID!
      $customerProductId: ID!
      $quantity: Int!
      $cardToken: String
      $cardUserKey: String
      $cardHolderName: String
      $cardNumber: String
      $expireMonth: String
      $expireYear: String
      $cvc: String
      $registerCard: Boolean
      $code: String
   ) {
      buyCustomerProductAddOn(
         data: {
            addOnId: $addOnId
            customerProductId: $customerProductId
            quantity: $quantity
            cardToken: $cardToken
            cardUserKey: $cardUserKey
            cardHolderName: $cardHolderName
            cardNumber: $cardNumber
            expireMonth: $expireMonth
            expireYear: $expireYear
            cvc: $cvc
            registerCard: $registerCard
            code: $code
         }
      ) {
         data {
            totalWhatsappBusinessCount
            totalPersonCount
            addOns {
               _id
               name
               quantity
               renewalType
               price
            }
         }
         success
         code
         message
      }
   }
`;

// TODO ADDING TO COUPON CODE
export const CHANGE_CUSTOMER_MAIN_PRODUCT_MUTATION = gql`
   mutation changeCustomerMainProductMutation(
      $customerId: ID!
      $productId: ID!
      $activeRenewalPeriodMode: Int
      $code: String
   ) {
      changeCustomerProduct(
         data: {
            customerId: $customerId
            productId: $productId
            code: $code
            activeRenewalPeriodMode: $activeRenewalPeriodMode
         }
      ) {
         data {
            _id
            activeRenewalPeriodMode
            addOns {
               _id
               name
               renewalType
               price
               quantity
            }
            totalWhatsappBusinessCount
            totalPersonCount
            activeRenewalName
            telegramCount
            name
            channels
            personCount
            clientCount
            messageCount
            whatsappWebCount
            teamsCount
            whatsappBusinessCount
            accountBalance
            facebookPageCount
            twitterCount
            instagramCount
            teamChat
            support
            price
            type
            paymentStatus
            status
            periodMode
            periodCount
            endDate
            createdAt
            autoRenewal
            feeCharged
            ipAddress
            paymentFromOtherChannel
            couponCode {
               _id
               name
               code
               status
               endDate
               percent
               createdAt
            }
         }
         success
         code
         message
      }
   }
`;

export const DELETE_CUSTOMER_ADD_ON_MUTATION = gql`
   mutation deleteCustomerAddOnMutation($customerProductAddOnId: ID!, $quantity: Int!) {
      deleteCustomerProductAddOn(data: { customerProductAddOnId: $customerProductAddOnId, quantity: $quantity }) {
         data {
            _id
            activeRenewalPeriodMode
            addOns {
               _id
               name
               renewalType
               price
               quantity
            }
            totalWhatsappBusinessCount
            totalPersonCount
            activeRenewalName
            telegramCount
            name
            channels
            personCount
            clientCount
            messageCount
            whatsappWebCount
            teamsCount
            whatsappBusinessCount
            accountBalance
            facebookPageCount
            twitterCount
            instagramCount
            teamChat
            support
            price
            type
            paymentStatus
            status
            periodMode
            periodCount
            endDate
            createdAt
            autoRenewal
            feeCharged
            ipAddress
            couponCode {
               _id
               name
               code
               status
               endDate
               percent
               createdAt
            }
         }
         success
         code
         message
      }
   }
`;

export const RENEW_MAIN_PACKAGE_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation renewMainPackageMutation(
      $productId: ID!
      $cardHolderName: String!
      $cardNumber: Double!
      $expireMonth: Double!
      $expireYear: Double!
      $cvc: Double!
      $cardAlias: String
   ) {
      renewMainPackage(
         data: {
            productId: $productId
            cardHolderName: $cardHolderName
            cardNumber: $cardNumber
            expireMonth: $expireMonth
            expireYear: $expireYear
            cvc: $cvc
            cardAlias: $cardAlias
         }
      ) {
         data {
            ...CustomerFields
         }
         code
         message
         success
      }
   }
`;

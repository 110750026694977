/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
   FileBlockProps,
   FlowBlock,
   FlowNodeType,
   IFlowBlockTemplate,
   TempNode,
} from '@modules/Automation/FlowBots/types';
import React from 'react';
import { Node } from 'reactflow';
import ActionLeftPanel from './ActionLeftPanel';
import AskQuestionLeftPanel from './AskQuestionLeftPanel';
import ConditionLeftPanel from './ConditionLeftPanel';
import DelayLeftPanel from './DelayLeftPanel';
import HttpRequestLeftPanel from './HttpRequestLeftPanel';
import SendButtonMessageLeftPanel from './SendButtonMessageLeftPanel';
import SendMessageLeftPanel from './SendMessageLeftPanel';
import StartFlowLeftPanel from './StartFlowLeftPanel';
import TriggerLeftPanel from './TriggerLeftPanel';
import WelcomeMessageLeftPanel from './WelcomeMessageLeftPanel';
import NoteLeftPanel from './NoteLeftPanel';

const useStyles = makeStyles(() => ({
   container: {
      height: 'calc(100% - 60px)',
   },
   groupNode: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      width: '100%',
      margin: 10,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

interface LeftPanelProps {
   leftNodes: Node[];
   contentNodes: Node[];
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   nodes: Node[];
   setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   tempNodes: TempNode[];
   setTempNodes: React.Dispatch<React.SetStateAction<TempNode[]>>;
   clickedNodeId: string;
   flowBlock: FlowBlock[];
   setFlowBlock: React.Dispatch<React.SetStateAction<FlowBlock[]>>;
   blockState: IFlowBlockTemplate[];
   setBlockState: React.Dispatch<React.SetStateAction<IFlowBlockTemplate[]>>;
   fileBlock: FileBlockProps[];
   setFileBlock: React.Dispatch<React.SetStateAction<FileBlockProps[]>>;
   setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
   isNewNode: string;
}

const LeftPanel: React.FC<LeftPanelProps> = ({
   leftNodes,
   contentNodes,
   setContentNodes,
   setLeftNodes,
   clickedNodeId,
   blockState,
   setBlockState,
   setFileBlock,
   setIsNewNode,
}) => {
   const classes = useStyles();

   const getNodeTypeById = (clickedNodeId: string, leftNodes: Node[]) => {
      const clickedNode = leftNodes.find(
         (node: { id: string }) => node.id === clickedNodeId && !node.id.startsWith('group_'),
      );
      return clickedNode ? clickedNode.type : null;
   };
   const nodeType = getNodeTypeById(clickedNodeId, leftNodes);

   return (
      <Grid container className={classes.container}>
         {nodeType === 'send_message' && (
            <SendMessageLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               blockState={blockState}
               setBlockState={setBlockState}
               setFileBlock={setFileBlock}
               setLeftNodes={setLeftNodes}
               setContentNodes={setContentNodes}
            />
         )}
         {nodeType === 'send_message_buttons' && (
            <SendButtonMessageLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               setLeftNodes={setLeftNodes}
               setContentNodes={setContentNodes}
            />
         )}
         {nodeType === 'actions' && (
            <ActionLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               contentNodes={contentNodes}
               setContentNodes={setContentNodes}
               setLeftNodes={setLeftNodes}
            />
         )}
         {nodeType === 'start_flow' && (
            <StartFlowLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               contentNodes={contentNodes}
               setContentNodes={setContentNodes}
               setLeftNodes={setLeftNodes}
            />
         )}
         {nodeType === 'conditions' && (
            <ConditionLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               setLeftNodes={setLeftNodes}
               setContentNodes={setContentNodes}
            />
         )}
         {nodeType === 'ask_question' && (
            <AskQuestionLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               contentNodes={contentNodes}
               setContentNodes={setContentNodes}
               setLeftNodes={setLeftNodes}
            />
         )}
         {nodeType === 'delay' && (
            <DelayLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               contentNodes={contentNodes}
               setContentNodes={setContentNodes}
               setLeftNodes={setLeftNodes}
            />
         )}
         {nodeType === 'note' && (
            <NoteLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               contentNodes={contentNodes}
               setContentNodes={setContentNodes}
               setLeftNodes={setLeftNodes}
            />
         )}
         {nodeType === 'http_request' && (
            <HttpRequestLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               setLeftNodes={setLeftNodes}
               setContentNodes={setContentNodes}
            />
         )}
         {nodeType === 'trigger' && (
            <TriggerLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               setLeftNodes={setLeftNodes}
               setContentNodes={setContentNodes}
               setIsNewNode={setIsNewNode}
            />
         )}
         {nodeType === FlowNodeType.WelcomeMessage && (
            <WelcomeMessageLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               setContentNodes={setContentNodes}
               setLeftNodes={setLeftNodes}
            />
         )}
      </Grid>
   );
};

export default LeftPanel;

import { LanguageContext } from '@helper/locale/langContext';
import { Button, CircularProgress, Typography, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { BorderBottom } from '@material-ui/icons';
import React, { useContext } from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
   container: {
      backgroundColor: '#fff',
      padding: 10,
      paddingLeft: 16,
      borderBottom: ' 1px solid #d1d1d1',
   },
   menuItem: {
      margin: 0,
      marginTop: 5,
      marginBottom: 5,
      padding: 0,
   },
   selector: {
      textAlign: 'left',
      border: 'solid 1px lightgrey',
      borderRadius: 5,
      paddingLeft: 3,
      height: 32,
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
}));

const TopBar = ({ title, schedule_disabled, isSchedule, buttonsEnabled, onSave, onSend, onTest, isLoading }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm'));
   const [buttonDisabled, setButtonDisabled] = React.useState();

   return (
      <Grid style={{ boxShadow: ' 2px 2px 2px lightgray' }} variant='outlined'>
         <Grid container justifyContent='space-between' className={classes.container} alignItems={'center'}>
            <Grid container alignItems='flex-start' justifyContent='flex-start' item xs={3} md={7}>
               <Link to='/campaigns' style={{ marginTop: 5 }}>
                  <ArrowBackIcon color='disabled' />
               </Link>{' '}
               &nbsp;&nbsp;
               <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                  {title}
               </Typography>
            </Grid>
            <Grid
               xs={9}
               sm={3}
               md={5}
               item
               spacing={4}
               container
               alignItems='flex-end'
               justifyContent='flex-end'
               style={{
                  paddingTop: isSmallScreen ? 10 : undefined,
                  paddingBottom: isSmallScreen ? 10 : undefined,
                  flexWrap: isSmallScreen ? undefined : 'nowrap',
                  paddingRight: 20,
               }}
            >
               <Button
                  style={{
                     minWidth: 150,
                     marginRight: isSmallScreen ? undefined : 10,
                  }}
                  startIcon={isLoading && buttonDisabled === 1 ? <CircularProgress color='primary' size={20} /> : null}
                  color='primary'
                  variant='outlined'
                  onClick={() => {
                     setButtonDisabled(1);
                     onTest();
                  }}
                  disabled={!buttonsEnabled}
               >
                  {t?.campaign_test_button}
               </Button>
               <Button
                  style={{
                     minWidth: 150,
                     marginRight: isSmallScreen ? undefined : 10,
                  }}
                  startIcon={isLoading && buttonDisabled === 2 ? <CircularProgress color='primary' size={20} /> : null}
                  color='primary'
                  variant='outlined'
                  onClick={() => {
                     setButtonDisabled(2);
                     onSave();
                  }}
                  disabled={!buttonsEnabled}
               >
                  {t?.campaign_save}
               </Button>
               <Button
                  style={{
                     minWidth: 150,
                  }}
                  startIcon={isLoading && buttonDisabled === 3 ? <CircularProgress color='primary' size={20} /> : null}
                  disabled={!buttonsEnabled || schedule_disabled}
                  color='primary'
                  variant='contained'
                  onClick={() => {
                     setButtonDisabled(3);
                     onSend();
                  }}
               >
                  {isSchedule ? t?.campaign_schedule : t?.campaign_send}
               </Button>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default WithUrlTranslations(TopBar);

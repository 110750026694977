import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import Input from '../../../../../../../../components/InputField';
import ButtonOK from '../../../../../../../../components/ButtonOK/buttonOk';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_ECOMMERCE_CUSTOMER_MUTATAION } from '@queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import { Save } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { getPerson } from '@store/reducers/person';
import countryList from 'react-select-country-list';
import ChatRightPanelTabHeader from '@modules/Chat/RightPanel/ChatItem/ChatRightPanelTabHeader';
import { LOCATIONS } from '@queries/Locations/query';
import SearchSelect from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(() => ({
   container: {
      padding: 8,
   },
   formInputRow: {
      display: 'flex',
      marginTop: 5,
      marginBottom: 5,
   },
   inlineFormInputFirst: {
      marginRight: 5,
   },
   inlineFormInputLast: {
      marginLeft: 5,
   },
   itemContainer: {
      paddingTop: 2,
      paddingBottom: 2,
      marginTop: 4,
   },
   labelContainer: {
      borderBottom: '1px solid #D9E0E6',
      marginBottom: 8,
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      '& .MuiFormGroup-root': {
         display: 'block !important',
         flexWrap: 'none !important',
         flexDirection: 'none !important',
      },
   },
   label: {
      color: '#385273',
      fontSize: '1em',
      marginBottom: 10,
   },
   inputFix: {
      paddingLeft: 5,
      '& .MuiInputBase-root': {
         height: 38,
         marginTop: '-8px',
      },
   },
}));

interface CreateCustomerProps {
   onCancel: () => void;
   onCreated: () => void;
   createdFor: string;
}

const CreateCustomer = ({ onCancel, onCreated, createdFor }: CreateCustomerProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const me = useSelector(getPerson);
   //@ts-ignore
   let lang_code = me.customer?.language;
   const t: any = lang?.translation;
   const [createCustomerMutation, { loading: createCustomerLoading }] = useMutation(
      CREATE_ECOMMERCE_CUSTOMER_MUTATAION,
   );
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const eCommerceType = useSelector((rootReducer: any) => rootReducer.eCommerceReducer.eCommerceType);
   const countriesOptions = useMemo(() => countryList().getData(), []);

   const {
      watch,
      control,
      register,
      setValue,
      handleSubmit,
      formState: { errors },
   } = useForm({
      mode: 'all',
      defaultValues: {
         sameAsAbove: false,
         first_name: '',
         last_name: '',
         email: '',
         phone: '',
         first_nameShipping: '',
         last_nameShipping: '',
         phoneShipping: '',
         addressShipping: '',
         countryShipping: {
            value: 0,
            label: '',
         },
         cityShipping: {
            value: 0,
            label: '',
         },
         stateShipping: {
            value: 0,
            label: '',
         },
         zipShipping: '',
         shippingAddress: `'-'
         '-'
         '-'
         '-'
         '-'
         '-'`,
         identityNumberShipping: '',
         note: '',
         tags: [],
      },
   });

   const [getCountry, { data: countryData, loading: countryLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'country',
         id: 0,
      },
   });
   const [getState, { data: stateData, loading: stateLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'state',
         id: watch('countryShipping.value'),
      },
   });
   const [getCity, { data: cityData, loading: cityLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'city',
         id: watch('stateShipping.value'),
      },
   });

   useEffect(() => {
      if (countryData) {
         if (countryData.locations.data.length === 0) {
            setValue('countryShipping', {
               value: 0,
               label: '',
            });
         } else {
            setValue('countryShipping', {
               value: countryData.locations.data[lang_code === 'TR' ? 227 : 235].id,
               label: countryData.locations.data[lang_code === 'TR' ? 227 : 235].name,
            });
         }
      }
      getCountry();
   }, [countryData]);

   useEffect(() => {
      if (stateData) {
         if (stateData.locations.data.length === 0) {
            setValue('stateShipping', {
               value: 0,
               label: '',
            });
         } else {
            setValue('stateShipping', {
               value:
                  watch('countryShipping.value') === 225
                     ? stateData.locations.data[39].id
                     : stateData.locations.data[0].id,
               label:
                  watch('countryShipping.value') === 225
                     ? stateData.locations.data[39].name
                     : stateData.locations.data[0].name,
            });
         }
      }
      getState();
   }, [stateData]);

   useEffect(() => {
      if (cityData) {
         if (cityData.locations.data.length === 0) {
            setValue('cityShipping', {
               value: 0,
               label: '',
            });
         } else {
            setValue('cityShipping', {
               value: cityData.locations.data[0].id,
               label: cityData.locations.data[0].name,
            });
         }
      }
      getCity();
   }, [cityData]);

   //!

   const onSubmit = async (data: any) => {
      let variables = {
         type: eCommerceType,
         metaData: {
            customerId: me.customer._id,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            phone: !data.phone.includes('+') ? '+' + data.phone : data.phone,
            linkedPerson: createdFor,
            addresses: [
               {
                  firstName: data.sameAsAbove ? data.first_name : data.first_nameShipping,
                  lastName: data.sameAsAbove ? data.last_name : data.last_nameShipping,
                  phone: data.sameAsAbove
                     ? !data.phoneShipping.includes('+')
                        ? '+' + data.phone
                        : data.phone
                     : data.phoneShipping
                     ? !data.phoneShipping.includes('+')
                        ? '+' + data.phoneShipping
                        : data.phoneShipping
                     : '',
                  address: data.addressShipping,
                  country: data.countryShipping.value,
                  district: data.districtShipping,
                  city: data.cityShipping.value,
                  state: data.stateShipping.value,
                  zip: data.zipShipping,
                  identity_number: data.identityNumberShipping,
               },
            ],
            note: data.note,
            tags: data.tags?.map((tag: any) => tag.value) || [],
         },
      };
      createCustomerMutation({
         variables,
      })
         .then(() => {
            onCreated();
         })
         .catch((error) => {
            enqueueSnackbar(t[error.message] || error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {t['general_message_button_close']}
                  </div>
               ),
            });
         });
   };

   return (
      <div className={classes.container}>
         <ChatRightPanelTabHeader
            onClose={onCancel}
            title={t?.page_e_commerce_customer_create_customer}
            style={{ margin: -8, marginBottom: 8 }}
         />
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container xs={12}>
               <Grid container item xs={12} justify='space-between' className={classes.itemContainer}>
                  <Grid item xs={6} style={{ paddingRight: 5 }}>
                     {/* First Name Input */}
                     <Input label={t['page_e_commerce_customer_typo_first_name']} name='first_name' control={control} />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: 5 }}>
                     {/* Last Name Input */}
                     <Input label={t['page_e_commerce_customer_typo_last_name']} name='last_name' control={control} />
                  </Grid>
               </Grid>
               <Grid item xs={12} className={classes.itemContainer}>
                  {/* E-mail Input  */}
                  <Input
                     label={t['page_e_commerce_customer_typo_email']}
                     name='email'
                     rules={{
                        pattern: {
                           value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                           message: t?.must_be_a_valid_email,
                        },
                        required: t?.required,
                     }}
                     control={control}
                  />
               </Grid>
               <Grid item xs={12} className={classes.itemContainer}>
                  {/* Phone Input */}
                  <Input
                     isPhone
                     label={t['page_e_commerce_customer_typo_phone']}
                     name='phone'
                     control={control}
                     rules={{
                        required: t?.required,
                     }}
                  />
               </Grid>
               {/* Shipping Address Input */}
               {eCommerceType !== 'FAPRIKA' && (
                  <Fragment>
                     <div className={classes.labelContainer}>
                        <Typography className={classes.label}>
                           {t['page_e_commerce_customer_shipping_address']}
                        </Typography>
                        <FormGroup>
                           <FormControlLabel
                              control={<Checkbox {...register('sameAsAbove')} size='small' color='primary' />}
                              label={t?.page_e_commerce_customer_same_as_above}
                           />
                        </FormGroup>
                     </div>
                     {!watch('sameAsAbove') && (
                        <div>
                           <Grid container item xs={12} justify='space-between' className={classes.itemContainer}>
                              <Grid item xs={6} style={{ paddingRight: 5 }}>
                                 {/* First Name Shipping Input */}
                                 <Input
                                    label={t['page_e_commerce_customer_typo_first_name']}
                                    name='first_nameShipping'
                                    control={control}
                                 />
                              </Grid>
                              <Grid item xs={6} style={{ paddingLeft: 5 }}>
                                 {/* Last Name Shipping Input */}
                                 <Input
                                    label={t['page_e_commerce_customer_typo_last_name']}
                                    name='last_nameShipping'
                                    control={control}
                                 />
                              </Grid>
                           </Grid>
                           <Grid item xs={12} className={classes.itemContainer}>
                              {/* Phone Shipping Input */}
                              <Input
                                 isPhone
                                 label={t['page_e_commerce_customer_typo_phone']}
                                 name='phoneShipping'
                                 type='number'
                                 control={control}
                              />
                           </Grid>
                        </div>
                     )}
                     <Grid item xs={12} className={classes.itemContainer}>
                        {/* Address 1 Shipping Input */}
                        <Input
                           label={t['page_e_commerce_customer_address_1']}
                           name='addressShipping'
                           control={control}
                           multiline
                           rows={3}
                           {...(eCommerceType === 'TSOFT' && {
                              rules: {
                                 //patern
                                 pattern: {
                                    value: /(?=.{10})(?=.*[0-9])./,
                                    message: t?.must_be_a_valid_address,
                                 },
                                 required: t?.required,
                              },
                           })}
                        />
                     </Grid>
                     <Grid container item xs={12} justifyContent='space-between' className={classes.itemContainer}>
                        <Grid item xs={6} style={{ paddingRight: 5 }}>
                           {/* Country Shipping Input */}
                           <InputLabel
                              shrink={false}
                              style={{ marginBottom: 8, color: errors?.countryShipping && 'red' }}
                           >
                              {t?.country}
                           </InputLabel>
                           <Controller
                              name='countryShipping'
                              control={control}
                              rules={{
                                 required: true,
                                 validate: (value: any) => {
                                    if (value.value) {
                                       return true;
                                    }
                                    return false;
                                 },
                              }}
                              render={() => (
                                 <SearchSelect
                                    options={(countryData?.locations?.data
                                       ? [
                                            countryData?.locations?.data,
                                            {
                                               value: 0,
                                               label: t?.country,
                                            },
                                         ].flat()
                                       : []
                                    ).map(({ name, id }: any) => ({
                                       value: id,
                                       label: name,
                                    }))}
                                    isLoading={countryLoading}
                                    placeholder={'t?.country'}
                                    value={watch('countryShipping')}
                                    onChange={(value: any) => {
                                       setValue('countryShipping', value);
                                    }}
                                 />
                              )}
                           />
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 5 }}>
                           {/* City Shipping Input */}
                           <Input
                              isDisabled={stateLoading}
                              selectOptions={stateData?.locations?.data?.map(({ name, id }: any) => ({
                                 value: id,
                                 label: name,
                              }))}
                              label={t?.city}
                              value={watch('stateShipping')}
                              isCreatable={eCommerceType === 'SHOPIFY'}
                              isSelect={eCommerceType !== 'SHOPIFY'}
                              name='stateShipping'
                              control={control}
                              rules={{
                                 required: true,
                                 validate: (value: any) => {
                                    if (value.value) {
                                       return true;
                                    }
                                    return false;
                                 },
                              }}
                           />
                        </Grid>
                     </Grid>
                  </Fragment>
               )}
               <Grid container item xs={12} justifyContent='space-between' className={classes.itemContainer}>
                  <Grid
                     item
                     xs={eCommerceType !== 'IDEASOFT' && eCommerceType !== 'TICIMAX' ? 6 : 12}
                     style={{ paddingRight: 5 }}
                  >
                     <Input
                        isDisabled={cityLoading}
                        selectOptions={cityData?.locations?.data?.map(({ name, id }: any) => ({
                           value: id,
                           label: name,
                        }))}
                        label={t?.state}
                        value={watch('cityShipping')}
                        isCreatable={eCommerceType === 'SHOPIFY'}
                        isSelect={eCommerceType !== 'SHOPIFY'}
                        onChange={(value: any) => {
                           setValue('cityShipping', value);
                        }}
                        name='cityShipping'
                        control={control}
                        rules={{
                           required: cityData?.locations?.data?.length > 0,
                           validate: (value: any) => {
                              if (cityData?.locations?.data?.length <= 0) {
                                 return true;
                              }
                              if (value.value) {
                                 return true;
                              }
                              return false;
                           },
                        }}
                     />
                  </Grid>
                  {(eCommerceType !== 'TSOFT' && eCommerceType !== 'IDEASOFT' && eCommerceType !== 'TICIMAX' && (
                     <Grid item xs={6} style={{ paddingLeft: 5 }}>
                        {/* Zip Code Shipping Input */}
                        <Input label={t['page_e_commerce_customer_zip_code']} name='zipShipping' control={control} />
                     </Grid>
                  )) ||
                     (eCommerceType === 'TSOFT' && (
                        <Grid item xs={6} className={classes.inputFix}>
                           {/* District Shipping Input */}
                           <InputLabel shrink={false}>{t?.page_e_commerce_customer_district}</InputLabel>
                           <Input
                              placeholder={t?.page_e_commerce_customer_district}
                              name='districtShipping'
                              control={control}
                              rules={{
                                 required: t?.required,
                              }}
                           />
                        </Grid>
                     ))}
               </Grid>
               {(eCommerceType === 'TSOFT' || eCommerceType === 'IDEASOFT') && (
                  <Grid item xs={12} className={classes.itemContainer}>
                     {/* TSOFT Customer Identity Number Input */}
                     <Input
                        label={t['page_e_commerce_customer_identity_number']}
                        name='identityNumberShipping'
                        control={control}
                        rules={{
                           required: t?.required,
                           minLength: {
                              value: 11,
                              message: t?.must_be_11_characters,
                           },
                           maxLength: {
                              value: 11,
                              message: t?.must_be_11_characters,
                           },
                        }}
                     />
                  </Grid>
               )}
               {eCommerceType !== 'TSOFT' &&
                  eCommerceType !== 'IDEASOFT' &&
                  eCommerceType !== 'FAPRIKA' &&
                  eCommerceType !== 'TICIMAX' && (
                     <>
                        <Grid item xs={12} className={classes.itemContainer}>
                           {/* Customer Note Input */}
                           <Input
                              label={t['page_e_commerce_customer_customer_note']}
                              name='note'
                              multiline
                              control={control}
                              rows={2}
                           />
                        </Grid>
                        <Grid item xs={12} className={classes.itemContainer}>
                           {/* Tags Input */}
                           <Input
                              label={t['page_e_commerce_customer_tags']}
                              name='tags'
                              control={control}
                              isMulti={true}
                              isCreatable={true}
                              autoHeight
                              selectOptions={[]}
                           />
                        </Grid>
                     </>
                  )}
               <Grid container xs={12} justify='center' style={{ marginBottom: 32 }} alignItems='flex-start'>
                  <Fragment>
                     <Grid item xs={12} style={{ paddingLeft: 5 }}>
                        <ButtonOK
                           label={t['page_chat_typo_profile_save']}
                           fullWidth
                           height={35}
                           style={{ width: '100%' }}
                           type='submit'
                           disabled={eCommerceType === 'FAPRIKA'}
                           endIcon={
                              createCustomerLoading ? (
                                 <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                              ) : (
                                 <Save />
                              )
                           }
                           // onClick={handleSubmit(onSubmit)}
                           // disabled={!isValid || updateCustomerPersonLoading}
                           // endIcon={
                           //    updateCustomerPersonLoading ? (
                           //       <CircularProgress size={24} color='secondary' />
                           //    ) : undefined
                           // }
                        />
                     </Grid>
                  </Fragment>
               </Grid>
            </Grid>
         </form>
      </div>
   );
};

export default CreateCustomer;

import React, { useContext } from 'react';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import classNames from 'classnames';
import { Warning } from '@material-ui/icons';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';

const useStyles = makeStyles(() => ({
   container: {
      flexShrink: 0,
      height: '75px',
      display: 'flex',
      alignItems: 'center',
      paddingLeft: '25px',
      paddingRight: '25px',
      borderBottom: '1px solid #cccccc24',
   },
   item: {
      flex: 1,
      padding: 5,
   },
   button: {
      width: '100%',
      padding: '5px',
      boxShadow: 'none',
      color: '#10223A',
      backgroundColor: '#F7F7F7',
      '&:hover': {
         color: '#FFF',
         backgroundColor: '#157CFC',
      },
   },
   active: {
      color: '#FFF',
      backgroundColor: '#157CFC',
   },
}));

const pageLinks = [
   {
      url: 'appearance',
      activeName: 'appearance',
      label: 'page_settings_typo_platforms_live_chat_nav_appearance',
   },
   {
      url: 'get-started',
      activeName: 'getStarted',
      label: 'page_settings_typo_platforms_live_chat_nav_get_started',
   },
   {
      url: 'settings',
      activeName: 'settings',
      label: 'page_settings_typo_platforms_live_chat_nav_settings',
   },
   {
      url: 'installation',
      activeName: 'installation',
      label: 'page_settings_typo_platforms_live_chat_installation',
   },
   {
      url: 'languages',
      activeName: 'languages',
      label: 'page_settings_typo_platforms_live_chat_languages',
   },
];

const Nav = ({ active, url }) => {
   const history = useHistory();
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { liveChatPreviewSettings, updateLiveChatPreviewSettings, dataUpdatedFromServer } =
      useContext(LiveChatSettingsContext);

   return (
      <Grid container className={classes.container}>
         {pageLinks.map((link) => {
            let disabled = link.activeName === 'getStarted' && liveChatPreviewSettings.flowBotActive;
            return <Grid key={link.url} item className={classes.item}>
               <Tooltip title={disabled ? t?.live_chat_get_started_disabled_due_to_flowbots : ""}>
                  <span>
                     <Button
                        onClick={() => history.push(`${url}/${link.url}`)}
                        variant='contained'
                        disabled={disabled || liveChatPreviewSettings === undefined}
                        endIcon={disabled ? <Warning style={{ color: '#f7ca1b' }} /> : null}
                        className={classNames(classes.button, active === link.activeName && classes.active)}
                     >
                        {t[`${link.label}`]}
                     </Button>
                  </span>
               </Tooltip>
            </Grid>
         })}
      </Grid>
   );
};

export default Nav;

import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Edge, Node } from 'reactflow';
import TriggerLeftPanel from './TriggerLeftPanel';
import SendMessageLeftPanel from './SendMessageLeftPanel';
import ActionLeftPanel from './ActionLeftPanel';
import DelayLeftPanel from './DelayLeftPanel';

const useStyles = makeStyles(() => ({
   container: {
      height: 'calc(100% - 60px)',
   },
}));

interface LeftPanelProps {
   leftNodes: Node[];
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   clickedNodeId: string;
   isSecondMessage: boolean;
   heigherNode: number;
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   setHeigherNode: React.Dispatch<React.SetStateAction<number>>;
   setIsSecondMessage: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftPanel: React.FC<LeftPanelProps> = ({
   leftNodes,
   setLeftNodes,
   clickedNodeId,
   setClickedNodeId,
   heigherNode,
   setHeigherNode,
   setIsSecondMessage,
   isSecondMessage,
}) => {
   const classes = useStyles();
   const getNodeTypeById = (clickedNodeId: string, leftNodes: Node[]) => {
      const clickedNode = leftNodes.find((node: { id: string }) => node.id === clickedNodeId);
      return clickedNode ? clickedNode.type : null;
   };
   const nodeType = getNodeTypeById(clickedNodeId, leftNodes);

   return (
      <Grid container className={classes.container}>
         {nodeType === 'send_message' && (
            <SendMessageLeftPanel
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               setLeftNodes={setLeftNodes}
               setHeigherNode={setHeigherNode}
            />
         )}

         {nodeType === 'actions' && (
            <ActionLeftPanel leftNodes={leftNodes} clickedNodeId={clickedNodeId} setLeftNodes={setLeftNodes} />
         )}

         {nodeType === 'delay' && (
            <DelayLeftPanel leftNodes={leftNodes} clickedNodeId={clickedNodeId} setLeftNodes={setLeftNodes} />
         )}
         {nodeType === 'trigger' && (
            <TriggerLeftPanel
               setClickedNodeId={setClickedNodeId}
               leftNodes={leftNodes}
               setLeftNodes={setLeftNodes}
               clickedNodeId={clickedNodeId}
               heigherNode={heigherNode}
               setIsSecondMessage={setIsSecondMessage}
               isSecondMessage={isSecondMessage}
            />
         )}
      </Grid>
   );
};

export default LeftPanel;

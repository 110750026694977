import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../fragments';
export const GET_TEAMS_QUERY = gql`
   ${CUSTOMER_FRAGMENT}
   query GetTeamList($customer: String!, $search: String, $page: Int!, $pageSize: Int!, $me: String) {
      getTeamList(data: { search: $search, page: $page, pageSize: $pageSize, customer: $customer, me: $me }) {
         data {
            docs {
               _id
               isMain
               teamName
               color
               customer {
                  ...CustomerFields
               }
               drivers
               customerPersons {
                  _id
                  name
                  surname
                  username
                  phone
                  email
                  photo
                  type
                  status
                  setup
                  createdAt
               }
            }
            hasNextPage
            docsCount
         }
         success
         code
         message
      }
   }
`;

import { gql } from '@apollo/client';
import { QNA_CORE_FIELDS_FRAGMENT, QNA_CHAT_CORE_FIELDS_FRAGMENT } from './fragments';

export const QUESTION_TO_ME_SUBSCRIPTION = gql`
   ${QNA_CORE_FIELDS_FRAGMENT}
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   subscription QuestionToMeSubscription($customer: ID!, $token: String!) {
      newQnA(data: { token: $token, customer: $customer }) {
         data {
            ...QnAFields
            QnAChat {
               ...QnAChatFields
            }
         }
         success
         message
         code
      }
   }
`;
export const QUESTION_TO_ME_UPDATED_SUBSCRIPTION = gql`
   ${QNA_CORE_FIELDS_FRAGMENT}
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   subscription QuestionToMeUpdatedSubscription($customer: ID!, $token: String!) {
      updatedQnA(data: { token: $token, customer: $customer }) {
         data {
            ...QnAFields
            QnAChat {
               ...QnAChatFields
            }
         }
         success
         message
         code
      }
   }
`;

export const JOINED_QNA_PERSON_SUBSCRIPTION = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   subscription newJoinedQnAChatSubscription($customer: ID!, $token: String!) {
      newQnAChatJoin(data: { token: $token, customer: $customer }) {
         data {
            ...QnAChatFields
         }
         success
         message
         code
      }
   }
`;

export const FORWARD_QNA_PERSON_SUBSCRIPTION = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   subscription forwardQnAChatSubscription($customer: ID!, $token: String!) {
      newQnAChatForward(data: { token: $token, customer: $customer }) {
         data {
            ...QnAChatFields
         }
         success
         message
         code
      }
   }
`;

export const LEAVE_QNA_PERSON_SUBSCRIPTION = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   subscription LeaveQnASubscription($customer: ID!, $token: String!) {
      newQnAChatLeave(data: { token: $token, customer: $customer }) {
         data {
            ...QnAChatFields
         }
         success
         message
         code
      }
   }
`;

export const QUESTION_TO_ME_LOG = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   subscription QnAToMeLogSubscription($customer: ID!, $token: String!) {
      QnAToMe(data: { token: $token, customer: $customer }) {
         data {
            _id
            questionId
            question
            platformType
            type
            status
            QnAChat {
               ...QnAChatFields
            }
            createdBy {
               name
               surname
            }
         }
         success
         message
         code
      }
   }
`;

export const UPDATED_QNA_TAG_SUBSCRIPTION = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   subscription updateQnAChatTagSubscription($customer: ID!, $token: String!) {
      updatedQnAChatTag(data: { token: $token, customer: $customer }) {
         data {
            ...QnAChatFields
         }
         success
         message
         code
      }
   }
`;

export const NEW_QNA_TIMEOUT_SUBSCRIPTION = gql`
   ${QNA_CORE_FIELDS_FRAGMENT}
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   subscription newQnATimeoutSubscription($customer: ID!, $token: String!) {
      newQnATimeout(data: { token: $token, customer: $customer }) {
         data {
            ...QnAFields
            QnAChat {
               ...QnAChatFields
            }
         }
         success
         message
         code
      }
   }
`;

export const NEW_QNA_PERSON_TIMEOUT_SUBSCRIPTION = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   subscription newQnAChatTimeoutSubscription($customer: ID!, $token: String!) {
      newQnAChatTimeout(data: { token: $token, customer: $customer }) {
         data {
            ...QnAChatFields
         }
         success
         message
         code
      }
   }
`;

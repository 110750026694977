import { LanguageContext } from '@helper/locale/langContext';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EqualizerRoundedIcon from '@material-ui/icons/EqualizerRounded';
import React, { useContext } from 'react';
import { Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import CustomTooltip from './CustomTooltip';

const DAY_HOURS = [
   '00:00',
   '01:00',
   '02:00',
   '03:00',
   '04:00',
   '05:00',
   '06:00',
   '07:00',
   '08:00',
   '09:00',
   '10:00',
   '11:00',
   '12:00',
   '13:00',
   '14:00',
   '15:00',
   '16:00',
   '17:00',
   '18:00',
   '19:00',
   '20:00',
   '21:00',
   '22:00',
   '23:00',
];

const useStyles = makeStyles((theme) => ({
   title: {
      textAlign: 'left',
      fontSize: '15px',
      paddingLeft: theme.spacing(2),
      paddingTop: 12,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   container: {
      display: 'block',
      cursor: 'pointer',
   },

   paper: {
      width: '100%',
      height: '330px',
      borderRadius: 15,
   },
   NoDataContainer: {
      // flexGrow: 1,
      // display:'flex',
      // alignItems:'center',
      // justifyContent:'center'
      marginLeft: 100,
      marginTop: 200,
   },
}));

// const CustomizedAxisTick = props => {
//     const { x, y, payload } = props

//     return (
//         <g transform={`translate(${x},${y})`}>
//             <text dy={16} textAnchor='middle' fill='#666'>{payload.title}</text>
//         </g>
//     )
// }

const MessagesChart = ({ chartReport }) => {
   const maxReceived = chartReport.map((data) => data.received).reduce((a, b) => (a > b ? a : b), 0);
   const maxSent = chartReport.map((data) => data.sent).reduce((a, b) => (a > b ? a : b), 0);
   let maxVertical = maxReceived > maxSent ? maxReceived : maxSent;
   maxVertical *= 1.2;
   maxVertical = parseInt(maxVertical);
   const referenceLines = [
      maxVertical,
      parseInt((0.75 * maxVertical).toString(), 10),
      parseInt((0.5 * maxVertical).toString(), 10),
      parseInt((0.25 * maxVertical).toString(), 10),
      0,
   ];
   //Styles
   const classes = useStyles();
   // Language Context
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   //Data
   const data = chartReport.map((item, i) => {
      const newTitle = DAY_HOURS[i];
      return {
         index: i,
         title: newTitle,
         tooltipTitle: newTitle,
         receivedMessages: item.received,
         sentMessages: item.sent,
      };
   });

   return (
      <Paper elevation={1} className={classes.paper}>
         <div style={{ height: 45, borderBottom: '1px solid rgb(238, 238, 238)' }}>
            <Typography className={classes.title}>{t['page_dashboard_typo_messages']}</Typography>
         </div>
         {chartReport.length ? (
            <div style={{ height: 250 }}>
               <ResponsiveContainer>
                  <LineChart
                     isAnimationActive={false}
                     data={data}
                     width={'100%'}
                     margin={{
                        top: 20,
                        right: 50,
                        bottom: 10,
                     }}
                  >
                     {referenceLines.map((ref, i) => (
                        <ReferenceLine key={i} y={ref} stroke='#E3E9Ef' />
                     ))}
                     {/* <XAxis tick={<CustomizedAxisTick />} /> */}
                     <XAxis
                        dataKey='title'
                        axisLine={false}
                        tickLine={false}
                        angle={-45}
                        textAnchor='end'
                        padding={{ top: 20 }}
                     />
                     {/* <XAxis dataKey="name" axisLine={false} tickLine={false} angle={-45} textAnchor="end" minTickGap={5} /> */}
                     <YAxis axisLine={false} tickLine={false} ticks={referenceLines} padding={{ top: 20 }} />
                     {/* <Tooltip contentStyle={{borderRadius:10, boxShadow:'2px 2px grey'}} /> */}
                     <Tooltip content={<CustomTooltip />} />
                     <Legend
                        verticalAlign='top'
                        height={36}
                        iconType='circle'
                        align='right'
                        wrapperStyle={{ marginTop: -50 }}
                     />
                     <Line
                        type='monotone'
                        legendType='square'
                        dataKey='receivedMessages'
                        dot={false}
                        stroke='#0CAA73'
                        style={{ marginTop: 10 }}
                        activeDot={{ r: 8 }}
                        strokeWidth={3}
                        name={t['page_dashboard_typo_received_messages']}
                     />
                     <Line
                        type='monotone'
                        legendType='square'
                        dataKey='sentMessages'
                        style={{ marginTop: 10 }}
                        stroke='#157CFC'
                        dot={false}
                        strokeWidth={3}
                        activeDot={{ r: 8 }}
                        name={t['page_dashboard_typo_sent_messages']}
                     />
                  </LineChart>
               </ResponsiveContainer>
            </div>
         ) : (
            <div style={{ marginTop: 100, textAlign: 'center' }}>
               <EqualizerRoundedIcon fontSize='large' />
               <Typography style={{ color: 'grey' }} component='h6'>
                  {t['dont_have_chat_today']}
               </Typography>
            </div>
         )}
      </Paper>
   );
};

export default MessagesChart;

import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import TagIcon from '@components/TagIcon/tagIcon';
import {
   CREATE_CHAT_TAG_MUTATION,
   DELETE_CHAT_TAG_MUTATION,
   UPDATE_CHAT_TAG_MUTATION,
} from '../../../../queries/Settings/Service/GraphQL/ChatTags/mutation';
import { GET_CHAT_TAGS_QUERY, GET_CHAT_TAG_COUNT } from '../../../../queries/Settings/Service/GraphQL/ChatTags/query';
import {
   CREATED_CHAT_TAG_SUBSCRIPTION,
   DELETED_CHAT_TAG_SUBSCRIPTION,
   UPDATED_CHAT_TAG_SUBSCRIPTION,
} from '../../../../queries/Settings/Service/GraphQL/ChatTags/subscription';
import ModalContent from './modalContent';
import ActionIconButton from '@modules/Settings/SettingSpace/ActionIconButton';
import { DataGrid } from '@material-ui/data-grid';
import SearchBar from '@components/SearchBar';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   selector: {
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 10,
      paddingLeft: 3,
      height: 32,
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'medium',
   },
   button: {
      paddingTop: 10,
      width: 100,
      height: 30,
      color: 'white',
      borderRadius: 10,
   },
   buttonApply: {
      backgroundColor: '#487DBF',
      '&:hover': {
         backgroundColor: '#487DBF',
      },
   },
   paper: {
      borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
      width: '100%',
      borderRadius: 15,
      overflowY: 'auto',
      overflowX: 'hidden',
   },
   Table: {
      ' &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
         outline: 'none !important',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
         outline: 'none !important',
      },
      ' &.MuiDataGrid-root .MuiDataGrid-window': {
         overflowX: 'hidden',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
         padding: 0,
      },
   },
   content: {
      height: '100vh',
      overflowX: 'hidden',
      overflowY: 'auto',
      flexGrow: 1,
   },
   rowStyle: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
         outline: 'none',
      },

      fontWeight: 'bold',
      fontSize: '1rem',
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
}));

const Page = {
   pageSize: 15,
   page: 1,
};

const SpecialTags = ({ person, token }) => {
   const classes = useStyles();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [modalConfig, setModalConfig] = useState({ show: false, mode: null /* EDIT | NEW */ });

   const customer = person.customer._id;

   const [tagList, setTagList] = useState([]);
   const [tagCount, setTagCount] = useState(0);
   const [firstLoad, setFirstLoad] = useState(true);
   const [noQuickAnswer, setNoQuickAnswer] = useState(false);
   const [text, setText] = useState('');

   const handleEditRow = (row) => {
      setModalConfig({
         show: true,
         mode: 'EDIT',
         row,
      });
   };

   const handleCreateNewRow = () => {
      setModalConfig({
         show: true,
         mode: 'NEW',
      });
   };

   const handleCloseModal = () => {
      setModalConfig({
         show: false,
         mode: null,
      });
   };

   const handleDeleteModal = (row) => {
      setModalConfig({
         show: true,
         mode: 'DELETE',
         row,
      });
   };

   // get chat tags
   const [getChatTagQuery, { loading: chatTagsLoading }] = useLazyQuery(GET_CHAT_TAGS_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         let rows = data?.getChatTagList?.data?.docs.map((item) => {
            return {
               id: item._id,
               tag: item.name,
               color: item.color,
            };
         });

         setTagCount(data?.getChatTagList?.data?.docsCount);

         setTagList(rows);
      },

      onError: (error) => {
         // on query error
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  {' '}
                  Kapat
               </div>
            ),
         });
      },
   });

   // create mutation function
   const [createChatTagMutation, { loading: createChatTagLoading }] = useMutation(CREATE_CHAT_TAG_MUTATION);

   // update mutation function
   const [updateChatTagMutation, { loading: updateChatTagLoading }] = useMutation(UPDATE_CHAT_TAG_MUTATION);

   // delete mutation function
   const [deleteChatTagMutation, { loading: deleteChatTagLoading }] = useMutation(DELETE_CHAT_TAG_MUTATION);

   // subscription created
   useSubscription(CREATED_CHAT_TAG_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               createdChatTag: { data },
            },
         },
      }) => {
         const newTag = { id: data._id, tag: data.name, color: data.color };
         const index = tagList.findIndex((item) => item.id === data._id);
         if (index === -1) {
            setTagList([newTag, ...tagList]);
            setTagCount(tagCount + 1);
         }
      },
   });

   // subscription updated
   useSubscription(UPDATED_CHAT_TAG_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               updatedChatTag: { data },
            },
         },
      }) => {
         let index = tagList.findIndex((item) => item._id.toString() === data._id.toString());
         if (index > -1) {
            const newList = tagList.map((item) => {
               if (item._id.toString() === data._id.toString()) {
                  return {
                     ...item,
                     id: data._id,
                     tag: data.name,
                     color: data.color,
                  };
               }
               return item;
            });
            setTagList(newList);
         }
      },
   });

   // subscription deleted
   useSubscription(DELETED_CHAT_TAG_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               deletedChatTag: { data },
            },
         },
      }) => {
         const { tag } = data;
         if (tag) {
            if (tagList === 1) {
               setNoQuickAnswer(true);
            } else {
               setTagCount(tagCount - 1);
               setTagList(tagList.filter((item) => item.id !== tag._id));
            }
         }
      },
   });

   // add data on submit
   const addChatTagSubmit = async (formData) => {
      // run create mutation
      createChatTagMutation({
         variables: {
            name: formData.name,
            color: formData.color,
            customer: person.customer._id,
         },
      })
         .then(
            ({
               data: {
                  createChatTag: { data },
               },
            }) => {
               // on success event
               const newTag = { id: data._id, tag: data.name, color: data.color };
               const index = tagList.findIndex((item) => item.id === data._id);
               if (index === -1) {
                  setTagList([newTag, ...tagList ]);
                  setTagCount(tagCount + 1);
               }

               // close dialogs
               handleCloseModal({});
               enqueueSnackbar(`{ ${data.name} } ${t?.general_information_added_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });

               return '';
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                     {t?.general_message_button_close}
                  </div>
               ),
            });
         });
   };

   // update data on submit
   const editChatTagSubmit = async (formData) => {
      // run update mutation
      updateChatTagMutation({
         variables: {
            _id: formData._id,
            name: formData.name,
            color: formData.color,
         },
      })
         .then(
            ({
               data: {
                  updateChatTag: { data },
               },
            }) => {
               // on success event
               let index = tagList.findIndex((item) => item.id.toString() === data._id.toString());

               if (index > -1) {
                  const newList = tagList.map((item) => {
                     if (item.id.toString() === data._id.toString()) {
                        return {
                           ...item,
                           id: data._id,
                           tag: data.name,
                           color: data.color,
                        };
                     }
                     return item;
                  });
                  setTagList(newList);
               }
               // close modal
               handleCloseModal();
               enqueueSnackbar(`{ ${data.name} } ${t?.general_information_updated_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                     {t?.general_message_button_close}
                  </div>
               ),
            });
         });
   };

   // delete data on submit
   const handleDeleteChatTag = async (item) => {
      deleteChatTagMutation({ variables: { _id: item._id } }) // run to delete mutation
         .then(
            ({
               data: {
                  deleteChatTag: { data },
               },
            }) => {
               // success event
               if (tagCount === 1) {
                  setTagList([]);
                  setTagCount(tagCount - 1);
               } else {
                  setTagCount(tagCount - 1);
                  setTagList(tagList.filter((item) => item.id !== data._id));
               }
               handleCloseModal();
               return enqueueSnackbar(`{ ${data.name} } ${t?.general_information_deleted_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               });
            },
         )
         .catch((error) => {
            // error event
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                     {t?.general_message_button_close}
                  </div>
               ),
            });
         });
   };

   const loadingOrFetching = chatTagsLoading || createChatTagLoading || updateChatTagLoading || deleteChatTagLoading;

   const [queryParams, setQueryParams] = useState({
      customer,
      pageSize: Page.pageSize,
      page: Page.page,
      search: '',
   });

   useEffect(() => {
      setQueryParams((prev) => ({ ...prev, search: text }));
   }, [text]);

   useEffect(() => {
      getChatTagQuery({
         variables: {
            ...queryParams,
         },
      });
   }, [queryParams]);

   const columns = [
      {
         field: 'color',
         headerName: t?.page_settings_typo_color,
         flex: 1,
         minWidth: 150,
         sortable: false,
         renderCell: ({ row }) => {
            return <TagIcon width={80} color={row.color} height={40} />;
         },
      },
      {
         field: 'tag',
         headerName: t?.page_settings_typo_tag,
         flex: 1,
         minWidth: 150,
      },
      {
         field: 'operation',
         headerName: t?.page_settings_button_operation,
         flex: 1,
         sortable: false,
         headerAlign: 'center',
         align: 'center',
         minWidth: 150,
         renderCell: ({ row }) => {
            return (
               <>
                  <ActionIconButton
                     onClick={() => handleEditRow(row)}
                     tooltip={t?.comp_button_edit}
                     actionType='EDIT'
                  />
                  <ActionIconButton
                     onClick={() => handleDeleteModal(row)}
                     tooltip={t?.comp_button_delete}
                     actionType='DELETE'
                  />
               </>
            );
         },
      },
   ];

   return (
      <Fragment>
         {loadingOrFetching && <div className='loading' />}

         <div
            className={classes.content}
            style={{
               height: '100%',
               width: '100%',
               // display: firstLoad || noQuickAnswer ? 'none' : 'block',
            }}
         >
            <Grid
               container
               wrap='nowrap'
               style={{
                  height: '100%',
               }}
            >
               <Paper elevation={1} className={classes.paper}>
                  <DataGrid
                     loading={chatTagsLoading}
                     className={classes.Table}
                     components={{
                        Toolbar: (props) => {
                           return (
                              <div
                                 container
                                 className={classes.titleContainer}
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <div style={{ flexGrow: '1', fontSize: '20px', fontWeight: 'bold' }}>
                                    {t?.page_settings_typo_unique_tags}
                                 </div>
                                 <div>
                                    <SearchBar
                                       value={text}
                                       onChange={setText}
                                       placeholder={t['search']}
                                       style={{ width: 200, height: 40 }}
                                    />
                                 </div>

                                 <div style={{ paddingLeft: 5 }}>
                                    <ButtonOK
                                       onClick={handleCreateNewRow}
                                       width={200}
                                       height={40}
                                       label={t['page_settings_button_add_new']}
                                       fontSize='1.1rem'
                                    />
                                 </div>
                              </div>
                           );
                        },
                     }}
                     disableSelectionOnClick
                     localeText={{
                        toolbarColumns: '',
                        noRowsLabel: t?.no_data_to_show,
                     }}
                     rowHeight={64}
                     disableColumnMenu
                     disableColumnSelector
                     classes={{ columnHeader: classes.rowStyle }}
                     pageSize={Page.pageSize}
                     paginationMode='server'
                     page={queryParams.page - 1}
                     onPageChange={(params) => {
                        setQueryParams((prevState) => ({
                           ...prevState,
                           page: params + 1,
                        }));
                     }}
                     disableColumn
                     rows={tagList}
                     rowCount={tagCount}
                     columns={columns}
                  />
               </Paper>
            </Grid>
         </div>

         <Modal
            paperStyle={{ width: modalConfig.mode === 'DELETE' ? 450 : 600 }}
            open={modalConfig.show}
            onClose={handleCloseModal}
            modalHeader={
               modalConfig.mode === 'NEW'
                  ? t?.page_settings_typo_add_tag
                  : modalConfig.mode === 'EDIT'
                  ? t?.page_settings_typo_edit_tag
                  : modalConfig.mode === 'DELETE' && t?.general_message_are_your_sure
            }
         >
            <ModalContent
               tag={modalConfig.row}
               mode={modalConfig.mode}
               onAccept={modalConfig.mode === 'NEW' ? addChatTagSubmit : editChatTagSubmit}
               onCancel={handleCloseModal}
               onDelete={modalConfig.mode === 'DELETE' && handleDeleteChatTag}
            />
         </Modal>
      </Fragment>
   );
};

export default SpecialTags;

import { gql } from '@apollo/client';

// queries for customer product
export const GET_CUSTOMER_PRODUCT_QUERY = gql`
   query getCustomerProductQuery($customer: ID!) {
      personCustomerProducts(customer: $customer) {
         data {
            addOns {
               _id
               name
               quantity
               renewalType
               price
            }
            _id
            name
            channels
            personCount
            clientCount
            messageCount
            whatsappWebCount
            teamsCount
            whatsappBusinessCount
            facebookPageCount
            accountBalance
            twitterCount
            instagramCount
            teamChat
            support
            price
            feeCharged
            type
            paymentStatus
            status
            autoRenewal
            periodMode
            periodCount
            endDate
            createdAt
            ipAddress
            couponCode {
               _id
               name
               code
               status
               endDate
               percent
               createdAt
            }
         }
         success
         code
         message
      }
   }
`;

export const GET_CUSTOMER_PRODUCT_RENEWAL_PRICE_QUERY = gql`
   query getCustomerProductRenewalPriceQuery($customer: ID!) {
      customerProductRenewalPrice(customer: $customer) {
         data
         success
         code
         message
      }
   }
`;

export const GET_ADD_ON_PACKAGES_QUERY = gql`
   query getAllAdonsQuery {
      allAddOns {
         data {
            name
            _id
            description
            price
         }
         success
         code
         message
      }
   }
`;

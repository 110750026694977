import React, { useState, createContext, useContext, useEffect } from 'react';
import { LanguageContext } from '../helper/locale/langContext';
import defaultLogo from '../modules/Settings/SettingSpace/LiveChat/images/default_logo.png';
import _ from 'underscore';
import { backEndToFrontEnd, frontEndToBackEnd } from '../modules/Settings/SettingSpace/LiveChat/convertVariables';
import { useMutation, useQuery } from '@apollo/client';

import {
   CREATE_PLATFORM_MUTATION,
   DELETE_PLATFORM_MUTATION,
   UPDATE_PLATFORM_MUTATION,
} from '../queries/Settings/Service/GraphQL/Platform/mutation';

import { useSnackbar } from 'notistack';
import { Typography } from '@material-ui/core';
import {
   LIVE_CHAT_ICON,
   LIVE_CHAT_ICON_POSITION,
   SELECTED_LIVE_CHAT_ICON,
   VISITOR_CONTACT_INFO_VALUE,
} from '../modules/Settings/SettingSpace/LiveChat/types';
import { RUN_CUSTOM_PLATFORM_QUERY } from '../queries/Settings/Service/GraphQL/Platform/query';
import { PLATFORM_TYPE } from '../queries/Settings/Service/GraphQL/Platform/types';
import { useDispatch, useSelector } from 'react-redux';

const LiveChatSettingsContext = createContext(null);

export const LiveChatSettingsProvider = ({ children }) => {
   const dispatch = useDispatch();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const languageCode = person.customer.language.toLowerCase();

   const defaultValues = {
      defaultValue: true,
      status: false,
      // Appearance
      // imgURL: defaultLogo,
      greetingTitle: t['page_settings_typo_platforms_live_chat_appearance_form_greeting_default'],
      greetingContent: t['page_settings_typo_platforms_live_chat_appearance_form_greeting_content_default'],
      newChatTitle: t['page_settings_typo_platforms_live_chat_appearance_form_title_for_new_chat_default'],
      newChatSubtitle: t['page_settings_typo_platforms_live_chat_appearance_form_subtitle_for_new_chat_default'],
      titleOldChat: t['page_settings_typo_platforms_live_chat_appearance_form_title_for_old_chat_default'],
      themeColor: '#157CFC',
      textColor: '#FFFFFF',
      iconSize: LIVE_CHAT_ICON.PRIMARY,
      iconLabel: t['page_settings_typo_platforms_live_chat_appearance_form_icon_label_placeholder'],
      iconPosition: LIVE_CHAT_ICON_POSITION.RIGHT,
      liveChatIconType: SELECTED_LIVE_CHAT_ICON.QPIEN_DEFAULT_ICON,
      uploadedLiveChatIcon: '',
      spaceBottom: 0,
      spaceSide: 0,
      // Get Start
      greeting: false,
      greetingMessage1: t['page_settings_typo_platforms_live_chat_get_started_form_greeting_message_1_default'],
      greetingMessage2: t['page_settings_typo_platforms_live_chat_get_started_form_greeting_message_2_default'],
      welcomeMessageDuringWorkingHours: false,
      welcomeMessageDuringWorkingHoursMessage1:
         t['page_settings_typo_platforms_live_chat_get_started_form_welcome_message_during_working_hours_1_default'],
      welcomeMessageDuringWorkingHoursMessage2:
         t['page_settings_typo_platforms_live_chat_get_started_form_welcome_message_during_working_hours_2_default'],
      welcomeMessageOutsideOfWorkingHours: false,
      welcomeMessageOutsideOfWorkingHoursMessage1:
         t[
            'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_outside_of_working_hours_1_default'
         ],
      welcomeMessageOutsideOfWorkingHoursMessage2:
         t[
            'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_outside_of_working_hours_2_default'
         ],
      contactInformation: false,
      contactInformationSelectedOption: { value: VISITOR_CONTACT_INFO_VALUE.ALWAYS_ASK },
      contactInformationTitle:
         t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_title_default'],
      contactInformationMessage:
         t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_message_default'],
      contactInformationFullName: true,
      contactInformationEmailAddress: true,
      contactInformationPhoneNumber: false,
      contactInformationAddress: false,
      contactInformationFullNameRequired: false,
      contactInformationEmailAddressRequired: false,
      contactInformationPhoneNumberRequired: false,
      contactInformationAddressRequired: false,

      contactInformationThanksMessage:
         t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_message_default'],
      maxLengthContent:
         t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_permissionContent'],
      maxLengthLinkText:
         t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_permissionLinkText'],
      maxLength: true,
      maxLengthLinkUrl: '',
      rateUs: false,
      rateUsTitle: t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_title_default'],
      rateUsMessage: t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_message_default'],
      rateUsThanksMessage: t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_thanks_message_default'],
      minutesToClose: 30,
      // Settings
      onlineStatus: true,
      agentProfiles: true,
      notificationSound: true,
      isWhatsapp: false,
      whatsappButtonTitle: t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_button_title_default'],
      whatsappTitle: t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_title_default'],
      whatsappContent: t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_content_default'],
      whatsappAccount: '',
      isFacebook: false,
      facebookButtonTitle: t['page_settings_typo_platforms_live_chat_settings_section_facebook_button_title_default'],
      facebookTitle: t['page_settings_typo_platforms_live_chat_settings_section_facebook_title_default'],
      facebookContent: t['page_settings_typo_platforms_live_chat_settings_section_facebook_content_default'],
      facebookAccount: '',
      isInstagram: false,
      instagramButtonTitle: t['page_settings_typo_platforms_live_chat_settings_section_instagram_button_title_default'],
      instagramTitle: t['page_settings_typo_platforms_live_chat_settings_section_instagram_title_default'],
      instagramContent: t['page_settings_typo_platforms_live_chat_settings_section_instagram_content_default'],
      instagramAccount: '',
      isPhoneCall: false,
      phoneCallButtonTitle:
         t['page_settings_typo_platforms_live_chat_settings_section_phone_call_button_title_default'],
      phoneCallCountryCode: '90',
      phoneCallNumber: '',
      phoneCallTitle: t['page_settings_typo_platforms_live_chat_settings_section_phone_call_title_default'],
      phoneCallContent: t['page_settings_typo_platforms_live_chat_settings_section_phone_call_content_default'],
      // Installation
      trustedDomain1: '',
      trustedDomain2: '',
      trustedDomain3: '',
      // languages
      selectedLanguages: [
         languageCode === 'tr'
            ? { value: 'tr', label: 'Turkish - Türkçe', isFixed: true }
            : { value: 'en', label: 'English - English', isFixed: true },
      ],
      translation: {
         [languageCode]: {
            liveChatIconLabel: t['page_settings_typo_platforms_live_chat_languages_default_liveChatIconLabel'],
            greetingTitle: t['page_settings_typo_platforms_live_chat_appearance_form_greeting_default'],
            greetingContent: t['page_settings_typo_platforms_live_chat_appearance_form_greeting_content_default'],
            newChatTitle: t['page_settings_typo_platforms_live_chat_appearance_form_title_for_new_chat_default'],
            newChatSubtitle: t['page_settings_typo_platforms_live_chat_appearance_form_subtitle_for_new_chat_default'],
            titleOldChat: t['page_settings_typo_platforms_live_chat_appearance_form_title_for_old_chat_default'],
            greetingMessage1: t['page_settings_typo_platforms_live_chat_get_started_form_greeting_message_1_default'],
            greetingMessage2: t['page_settings_typo_platforms_live_chat_get_started_form_greeting_message_2_default'],
            welcomeMessageDuringWorkingHoursMessage1:
               t[
                  'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_during_working_hours_1_default'
               ],
            welcomeMessageDuringWorkingHoursMessage2:
               t[
                  'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_during_working_hours_2_default'
               ],
            welcomeMessageOutsideOfWorkingHoursMessage1:
               t[
                  'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_outside_of_working_hours_1_default'
               ],
            welcomeMessageOutsideOfWorkingHoursMessage2:
               t[
                  'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_outside_of_working_hours_2_default'
               ],
            contactInformationTitle:
               t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_title_default'],
            contactInformationMessage:
               t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_message_default'],
            contactInformationThanksMessage:
               t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_message_default'],
            maxLengthContent:
               t[
                  'page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_permissionContent'
               ],
            maxLengthLinkText:
               t[
                  'page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_permissionLinkText'
               ],
            rateUsTitle: t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_title_default'],
            rateUsMessage: t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_message_default'],
            rateUsThanksMessage:
               t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_thanks_message_default'],
            whatsappButtonTitle:
               t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_button_title_default'],
            whatsappTitle: t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_title_default'],
            whatsappContent: t['page_settings_typo_platforms_live_chat_settings_section_whatsapp_content_default'],
            facebookButtonTitle:
               t['page_settings_typo_platforms_live_chat_settings_section_facebook_button_title_default'],
            facebookTitle: t['page_settings_typo_platforms_live_chat_settings_section_facebook_title_default'],
            facebookContent: t['page_settings_typo_platforms_live_chat_settings_section_facebook_content_default'],
            instagramButtonTitle:
               t['page_settings_typo_platforms_live_chat_settings_section_instagram_button_title_default'],
            instagramTitle: t['page_settings_typo_platforms_live_chat_settings_section_instagram_title_default'],
            instagramContent: t['page_settings_typo_platforms_live_chat_settings_section_instagram_content_default'],
            phoneCallButtonTitle:
               t['page_settings_typo_platforms_live_chat_settings_section_phone_call_button_title_default'],
            phoneCallTitle: t['page_settings_typo_platforms_live_chat_settings_section_phone_call_title_default'],
            phoneCallContent: t['page_settings_typo_platforms_live_chat_settings_section_phone_call_content_default'],
            sendButtonLabel: t['page_settings_typo_platforms_live_chat_languages_default_sendButtonLabel'],
            seeAllYourConversationsButtonText:
               t['page_settings_typo_platforms_live_chat_languages_default_seeAllYourConversationsButtonText'],
            typeYourMessageHerePlaceholderText:
               t['page_settings_typo_platforms_live_chat_languages_default_typeYourMessageHerePlaceholderText'],
            online: t['page_settings_typo_platforms_live_chat_languages_default_online'],
            offline: t['page_settings_typo_platforms_live_chat_languages_default_offline'],
            contactInformationFullNamePlaceholderText:
               t['page_settings_typo_platforms_live_chat_languages_default_contactInformationFullNamePlaceholderText'],
            contactInformationEmailAddressPlaceholderText:
               t[
                  'page_settings_typo_platforms_live_chat_languages_default_contactInformationEmailAddressPlaceholderText'
               ],
            contactInformationPhoneNumberPlaceholderText:
               t[
                  'page_settings_typo_platforms_live_chat_languages_default_contactInformationPhoneNumberPlaceholderText'
               ],
            contactInformationAddressPlaceholderText:
               t['page_settings_typo_platforms_live_chat_languages_default_contactInformationAddressPlaceholderText'],
            contactDetailsText: t['page_settings_typo_platforms_live_chat_languages_default_contactDetailsText'],
            writeAReviewPlaceholderText:
               t['page_settings_typo_platforms_live_chat_languages_default_writeAReviewPlaceholderText'],
            yourFeedbackText: t['page_settings_typo_platforms_live_chat_languages_default_yourFeedbackText'],
            positive: t['page_settings_typo_platforms_live_chat_languages_default_positive'],
            negative: t['page_settings_typo_platforms_live_chat_languages_default_negative'],
            channelsText: t['page_settings_typo_platforms_live_chat_languages_default_channelsText'],
         },
      },
      flowBotTriggerFields: [],
      flowBotMessages: [],
      flowBotActive: false,
   };

   const SnackbarError = ({ id }) => (
      <div className='snackbar-error' onClick={() => closeSnackbar(id)}>
         <Typography style={{ color: '#FFFFFF' }}>{t['comp_button_close']}</Typography>
      </div>
   );

   const [liveChatPreviewSettings, setLiveChatPreviewSettings] = useState(defaultValues);
   const [liveChatSettings, setLiveChatSettings] = useState(defaultValues);
   const [dataUpdatedFromServer, setDataUpdatedFromServer] = useState(false);

   // TODO: update GET_PLATFORMS_QUERY for live chat
   const { loading: getPlatformQueryLoading, data: liveChatData } = useQuery(RUN_CUSTOM_PLATFORM_QUERY, {
      variables: {
         type: PLATFORM_TYPE.LIVE_CHAT,
         metaData: {
            op: 'GET_LIVE_CHAT_ACCOUNT',
            data: { customer: person.customer._id, lang: languageCode },
         },
      },
      fetchPolicy: 'network-only',
      // fetchPolicy: 'network-only',
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => <SnackbarError id={key} />,
         });
      },
      onCompleted: (data) => {
         if (data.runCustomPlatformQuery.data) {
            const newValues = {
               ...backEndToFrontEnd(
                  data.runCustomPlatformQuery.data,
                  languageCode,
                  defaultValues.translation[languageCode],
               ),
            };
            setLiveChatPreviewSettings((prevState) => ({ ...prevState, ...newValues, defaultValue: false }));
            setLiveChatSettings((prevState) => ({ ...prevState, ...newValues, defaultValue: false }));
            setDataUpdatedFromServer(true);
         }
      },
   });

   // delete live chat mutation function
   const [deletePlatformMutation, { loading: deletePlatformMutationLoading }] = useMutation(DELETE_PLATFORM_MUTATION);
   // update live chat mutation function
   const [updatePlatformMutation, { loading: updatePlatformMutationLoading }] = useMutation(UPDATE_PLATFORM_MUTATION);

   const updateLiveChatPreviewSettings = (newSettings) => {
      const {
         greetingTitle,
         greetingContent,
         newChatTitle,
         newChatSubtitle,
         titleOldChat,
         greetingMessage1,
         greetingMessage2,
         welcomeMessageDuringWorkingHoursMessage1,
         welcomeMessageDuringWorkingHoursMessage2,
         welcomeMessageOutsideOfWorkingHoursMessage1,
         welcomeMessageOutsideOfWorkingHoursMessage2,
         contactInformationTitle,
         contactInformationMessage,
         contactInformationThanksMessage,
         maxLengthContent,
         maxLengthLinkText,
         rateUsTitle,
         rateUsMessage,
         rateUsThanksMessage,
         whatsappButtonTitle,
         whatsappTitle,
         whatsappContent,
         facebookButtonTitle,
         facebookTitle,
         facebookContent,
         instagramButtonTitle,
         instagramTitle,
         instagramContent,
         phoneCallButtonTitle,
         phoneCallTitle,
         phoneCallContent,
         whatsappAccount,
         facebookAccount,
         instagramAccount,
      } = newSettings;
      setLiveChatPreviewSettings((previousPreviewSettings) => ({
         ...previousPreviewSettings,
         ...newSettings,
         defaultValue: false,
         translation: {
            ..._.extend(
               ...liveChatPreviewSettings.selectedLanguages.map((lang) => ({
                  [lang.value]: liveChatPreviewSettings.translation[lang.value],
               })),
            ),
            ...newSettings.translation,
            [languageCode]: {
               ...previousPreviewSettings.translation[languageCode],
               ...newSettings.translation?.[languageCode],
               greetingTitle: greetingTitle
                  ? greetingTitle
                  : previousPreviewSettings.translation[languageCode].greetingTitle,
               greetingContent: greetingContent
                  ? greetingContent
                  : previousPreviewSettings.translation[languageCode].greetingContent,
               newChatTitle: newChatTitle
                  ? newChatTitle
                  : previousPreviewSettings.translation[languageCode].newChatTitle,
               newChatSubtitle: newChatSubtitle
                  ? newChatSubtitle
                  : previousPreviewSettings.translation[languageCode].newChatSubtitle,
               titleOldChat: titleOldChat
                  ? titleOldChat
                  : previousPreviewSettings.translation[languageCode].titleOldChat,
               greetingMessage1: greetingMessage1
                  ? greetingMessage1
                  : previousPreviewSettings.translation[languageCode].greetingMessage1,
               greetingMessage2: greetingMessage2
                  ? greetingMessage2
                  : previousPreviewSettings.translation[languageCode].greetingMessage2,
               welcomeMessageDuringWorkingHoursMessage1: welcomeMessageDuringWorkingHoursMessage1
                  ? welcomeMessageDuringWorkingHoursMessage1
                  : previousPreviewSettings.translation[languageCode].welcomeMessageDuringWorkingHoursMessage1,
               welcomeMessageDuringWorkingHoursMessage2: welcomeMessageDuringWorkingHoursMessage2
                  ? welcomeMessageDuringWorkingHoursMessage2
                  : previousPreviewSettings.translation[languageCode].welcomeMessageDuringWorkingHoursMessage2,
               welcomeMessageOutsideOfWorkingHoursMessage1: welcomeMessageOutsideOfWorkingHoursMessage1
                  ? welcomeMessageOutsideOfWorkingHoursMessage1
                  : previousPreviewSettings.translation[languageCode].welcomeMessageOutsideOfWorkingHoursMessage1,
               welcomeMessageOutsideOfWorkingHoursMessage2: welcomeMessageOutsideOfWorkingHoursMessage2
                  ? welcomeMessageOutsideOfWorkingHoursMessage2
                  : previousPreviewSettings.translation[languageCode].welcomeMessageOutsideOfWorkingHoursMessage2,
               contactInformationTitle: contactInformationTitle
                  ? contactInformationTitle
                  : previousPreviewSettings.translation[languageCode].contactInformationTitle,
               contactInformationMessage: contactInformationMessage
                  ? contactInformationMessage
                  : previousPreviewSettings.translation[languageCode].contactInformationMessage,
               contactInformationThanksMessage:
                  contactInformationThanksMessage || contactInformationThanksMessage === ''
                     ? contactInformationThanksMessage
                     : previousPreviewSettings.translation[languageCode].contactInformationThanksMessage,

               maxLengthContent: maxLengthContent
                  ? maxLengthContent
                  : previousPreviewSettings.translation[languageCode]?.maxLengthContent,

               maxLengthLinkText: maxLengthLinkText
                  ? maxLengthLinkText
                  : previousPreviewSettings.translation[languageCode]?.maxLengthLinkText,
               rateUsTitle: rateUsTitle ? rateUsTitle : previousPreviewSettings.translation[languageCode].rateUsTitle,
               rateUsMessage: rateUsMessage
                  ? rateUsMessage
                  : previousPreviewSettings.translation[languageCode].rateUsMessage,
               rateUsThanksMessage:
                  rateUsThanksMessage || rateUsThanksMessage === ''
                     ? rateUsThanksMessage
                     : previousPreviewSettings.translation[languageCode].rateUsThanksMessage,
               whatsappButtonTitle: whatsappButtonTitle
                  ? whatsappButtonTitle
                  : previousPreviewSettings.translation[languageCode].whatsappButtonTitle,
               whatsappTitle: whatsappTitle
                  ? whatsappTitle
                  : previousPreviewSettings.translation[languageCode].whatsappTitle,
               whatsappContent: whatsappContent
                  ? whatsappContent
                  : previousPreviewSettings.translation[languageCode].whatsappContent,
               facebookButtonTitle: facebookButtonTitle
                  ? facebookButtonTitle
                  : previousPreviewSettings.translation[languageCode].facebookButtonTitle,
               facebookTitle: facebookTitle
                  ? facebookTitle
                  : previousPreviewSettings.translation[languageCode].facebookTitle,
               facebookContent: facebookContent
                  ? facebookContent
                  : previousPreviewSettings.translation[languageCode].facebookContent,
               instagramButtonTitle: instagramButtonTitle
                  ? instagramButtonTitle
                  : previousPreviewSettings.translation[languageCode].instagramButtonTitle,
               instagramTitle: instagramTitle
                  ? instagramTitle
                  : previousPreviewSettings.translation[languageCode].instagramTitle,
               instagramContent: instagramContent
                  ? instagramContent
                  : previousPreviewSettings.translation[languageCode].instagramContent,
               phoneCallButtonTitle: phoneCallButtonTitle
                  ? phoneCallButtonTitle
                  : previousPreviewSettings.translation[languageCode].phoneCallButtonTitle,
               phoneCallTitle: phoneCallTitle
                  ? phoneCallTitle
                  : previousPreviewSettings.translation[languageCode].phoneCallTitle,
               phoneCallContent: phoneCallContent
                  ? phoneCallContent
                  : previousPreviewSettings.translation[languageCode].phoneCallContent,
            },
         },
      }));
   };

   const updateLiveChatSettings = (_, connected) => {
      let updatedLiveChat = liveChatPreviewSettings;
      if (connected) {
         updatedLiveChat = { ...updatedLiveChat, ...connected };
      }
      updatePlatformMutation({
         variables: {
            type: PLATFORM_TYPE.LIVE_CHAT,
            metaData: {
               customer: person.customer._id,
               ...frontEndToBackEnd(updatedLiveChat, person.customer._id),
            },
         },
      })
         .then(() => {
            setLiveChatSettings((previousLiveChatSettings) => ({
               ...previousLiveChatSettings,
               ...liveChatPreviewSettings,
               defaultValue: false,
            }));
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               persist: true,
               action: (key) => <SnackbarError id={key} />,
            });
         });
   };

   const resetLiveChatSettings = () => {
      deletePlatformMutation({
         variables: {
            type: PLATFORM_TYPE.LIVE_CHAT,
            metaData: {
               customer: person.customer._id,
            },
         },
      })
         .then(() => {
            setLiveChatPreviewSettings(defaultValues);
            setLiveChatSettings(defaultValues);
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               persist: true,
               action: (key) => <SnackbarError id={key} />,
            });
         });
   };
   useEffect(() => {
      if (!liveChatSettings.defaultValue) {
         dispatch({
            type: 'SET_PAYLOAD',
            payload: {
               liveChatSettings,
            },
         });
      }
   }, [liveChatSettings]);

   useEffect(() => {
      if (!liveChatPreviewSettings.defaultValue)
         dispatch({
            type: 'SET_PAYLOAD',
            payload: {
               liveChatPreviewSettings,
            },
         });
   }, [liveChatPreviewSettings]);

   useEffect(() => {
      dispatch({
         type: 'SET_PAYLOAD',
         payload: {
            dataUpdatedFromServer,
         },
      });
   }, [dataUpdatedFromServer]);

   const liveChat = useSelector((state) => state.liveChatReducer);
   const value = {
      liveChatPreviewSettings: liveChat.liveChatPreviewSettings || liveChatPreviewSettings,
      updateLiveChatPreviewSettings,
      liveChatSettings: liveChat.liveChatSettings || liveChatSettings,
      updateLiveChatSettings,
      resetLiveChatSettings,
      dataUpdatedFromServer: liveChat.liveChatSettings || liveChatSettings,
   };

   // console.log(liveChatSettings, " liveChatSettings... from live chat data..")

   return (
      <LiveChatSettingsContext.Provider value={value}>
         {!liveChat.liveChatPreviewSettings ? <div className='loading' /> : children}
      </LiveChatSettingsContext.Provider>
   );
};

export default LiveChatSettingsContext;

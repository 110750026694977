import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';
import TranslationForm from './TranslationForm';
import usePreviousState from '../../../../../hooks/usePreviousState';
import _ from 'underscore';

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`scrollable-auto-tabpanel-${index}`}
         aria-labelledby={`scrollable-auto-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box p={2.5} pb={0}>
               {children}
            </Box>
         )}
      </div>
   );
}

function a11yProps(index) {
   return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
   };
}

const useStyles = makeStyles(() => ({
   root: {
      flexGrow: 1,
      width: '100%',
   },
}));

const TranslationTabView = ({ setReset, setTranslationFormLanguage }) => {
   const classes = useStyles();
   const { liveChatPreviewSettings } = useContext(LiveChatSettingsContext);
   const { selectedLanguages } = liveChatPreviewSettings;
   const prevSelectedLanguages = usePreviousState(selectedLanguages, selectedLanguages);
   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      if (newValue <= selectedLanguages.length) {
         setValue(newValue);
      }
   };

   // value: 'en', label: 'English - English', isFixed: true

   useEffect(() => {
      setTranslationFormLanguage(selectedLanguages[value].value);
   }, [value]);

   useEffect(() => {
      if (prevSelectedLanguages && selectedLanguages) {
         if (selectedLanguages.length === 1) {
            setValue(0);
         } else {
            const addedLanguage = _.difference(selectedLanguages, prevSelectedLanguages)[0];
            const removedLanguage = _.difference(prevSelectedLanguages, selectedLanguages)[0];
            if (addedLanguage) {
               // When a new language is added it is redirected to the language's form to create
               setValue(selectedLanguages.length - 1);
            } else if (removedLanguage) {
               const index = prevSelectedLanguages.findIndex((language) => language.value === removedLanguage.value);
               if (index === value) {
                  // When a language is removed, if its form is open it will be redirected to the default language's form
                  setValue(0);
               } else {
                  // When a language is removed, if another language form is open it will reopen
                  const index = selectedLanguages.findIndex(
                     (language) => language.value === prevSelectedLanguages[value].value,
                  );
                  setValue(index);
               }
            }
         }
      }
   }, [selectedLanguages]);

   return (
      <div className={classes.root}>
         <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
         >
            {selectedLanguages &&
               selectedLanguages.length > 0 &&
               selectedLanguages.map((language, index) => (
                  <Tab key={language.value} label={language.label} {...a11yProps(index)} />
               ))}
         </Tabs>
         {selectedLanguages &&
            selectedLanguages.length > 0 &&
            selectedLanguages.map((language, index) => (
               <TabPanel key={language.value} value={value} index={index}>
                  <TranslationForm languageCode={language.value} setReset={setReset} />
               </TabPanel>
            ))}
      </div>
   );
};

export default TranslationTabView;

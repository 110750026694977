import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import Profile from './profile/profile';
import Company from './company/company';
import MyPackage from './myPackage/myPackage';
import Packages from './packages';
import MyFactors from './myInvoices/myFactors';
import PreferencesAndNotificatins from './preferencesAndNotifications/preferencesAndNotifications';

const useStyles = makeStyles((theme) => ({
   root: {
      // borderLeft: '4px solid ' + theme.chatApp.general.pallet.passiveStructureBlue,
      height: '100%',
      width: '100%',
      overflowY: 'auto',
      borderRadius: 15,
   },
   container: {
      padding: 10,
      width: '100%',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
         padding: 0,
      },
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   appBarSpacer: theme.mixins.toolbar,
}));

const Space = ({ width }) => {
   const classes = useStyles();
   return (
      <Grid className={classes.container}>
         <div className={classes.appBarSpacer} />
         <Paper elevation={3} className={classes.root}>
            <Switch>
               <Route path={'/account/packages/all-factors'} exact>
                  {' '}
                  <Packages width={width} />{' '}
               </Route>
               <Route path={'/account/packages/my-package'} exact>
                  {' '}
                  <MyPackage width={width} />{' '}
               </Route>
               <Route path={'/account/packages/my-factors'} exact>
                  {' '}
                  <MyFactors width={width} />{' '}
               </Route>
               <Route path={'/account/profile'} exact component={Profile} />
               <Route path={'/account/company'} exact>
                  {' '}
                  <Company />{' '}
               </Route>
               <Route path={'/account/preferences'} exact>
                  {' '}
                  <PreferencesAndNotificatins />{' '}
               </Route>
               <Route path='/'>
                  {' '}
                  <div>Not found</div>{' '}
               </Route>
            </Switch>
         </Paper>
      </Grid>
   );
};

export default Space;

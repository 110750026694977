import { gql } from '@apollo/client';

// subscriptions
export const CREATED_PLATFORM_SUBSCRIPTION = gql`
   subscription createdWhatsappWebPhoneSubscription($token: String!, $customer: ID!) {
      createdPlatform(data: { token: $token, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;
export const UPDATED_PLATFORM_SUBSCRIPTION = gql`
   subscription updatedPlatform($token: String!, $customer: ID!) {
      updatedPlatform(data: { token: $token, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;
export const DELETED_PLATFORM_SUBSCRIPTION = gql`
   subscription deletedWhatsappWebPhoneSubscription($token: String!, $customer: ID!) {
      deletedPlatform(data: { token: $token, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;

import React, { useContext, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@components/InputField';
import Button from '@components/Button';
import { useHistory,Link } from 'react-router-dom';
import { FORGOT_PASSWORD_MUTATION } from '../../../queries/Person/Service/GraphQL/mutation';
import { useMutation } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
   primaryLink: {
      color: theme.palette.primary.main,
   },
   secondaryLink: {
      color: theme.palette.grey['800'],
   },
   helperText: {
      color: '#178CF9',
      paddingTop: '16px',
      margin: '10px',
   },
   subTitleText: {
      fontSize: '0.9rem',
      textAlign: 'center',
      color: 'rgba(0, 0, 0, 0.85)',
   },
}));

const ResetPasswordForm = () => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [errorState, setErrorState] = useState(false);
   const [showSuccessMessage, setShowSuccessMessage] = useState(false);
   const {
      handleSubmit,
      control,
      formState: { isValid },
   } = useForm({
      mode: 'all',
   });

   const classes = useStyles();
   const history = useHistory();
   const [forgotMyPasswordMutation, { loading: forgotMyPasswordLoading }] = useMutation(FORGOT_PASSWORD_MUTATION);

   const handleCorrectResetPasswordOperation = () => {
      setShowSuccessMessage(true);
      setTimeout(() => {
         setShowSuccessMessage(false);
         history.push('/sign-in');
      }, 3000);
   };
   // update data on submit
   const handleForgotMyPassword = async (state) => {
      try {
         await forgotMyPasswordMutation({
            variables: {
               email: state.email,
            },
         });
         handleCorrectResetPasswordOperation();
      } catch (err) {
         setErrorState(true);
         setTimeout(() => {
            setErrorState(false);
         }, 3000);
      }
   };

   return (
      <div style={{ minWidth: '30%', marginTop: '7rem' }}>
         {forgotMyPasswordLoading && <div className='loading' />}

         {!showSuccessMessage && (
            <form onSubmit={handleSubmit(handleForgotMyPassword)} className={classes.form}>
               <Typography
                  style={{
                     fontSize: '1.45rem',
                     marginBottom: 35,
                     color: 'black',
                     fontWeight: 500,
                     paddingBottom: '.5rem',
                     borderBottom: '1px solid #EBEBEB',
                  }}
               >
                  {t?.forgot_password}
               </Typography>
               <Grid container spacing={3}>
                  <Grid item xs={12}>
                     <Input
                        name='email'
                        control={control}
                        label={t['pre_load_input_label_email']}
                        rules={{
                           required: t['email_required'],
                           pattern: {
                              value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: t['email_valid'],
                           },
                        }}
                     />
                  </Grid>
               </Grid>

               {errorState && (
                  <Typography style={{ fontSize: '0.9rem', color: 'red' }}>
                     {t['pre_load_forget_password_wrong_email']}
                  </Typography>
               )}
               <Button
                  label={t?.page_chat_typo_send}
                  style={{ textTransform: 'none' }}
                  disabled={!isValid}
                  isPrimary
                  type='submit'
               />

               <Grid
                  container
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  style={{ marginTop: '20px', paddingBottom: '50px' }}
               >
                  <Grid item xs>
                  <Link to={'/sign-in'} className={classes.helperText}>
                        {t?.return_to_login_screen}
                     </Link>
                  </Grid>
                  <Grid item>
                     <Button
                        onClick={() => history.push('/sign-in')}
                        isPrimary={false}
                        color='#178CF9'
                        style={{
                           textTransform: 'none',
                           paddingLeft: '1.5rem',
                           paddingRight: '1.5rem',
                        }}
                        label={t?.login}
                     />
                  </Grid>
               </Grid>
            </form>
         )}
         {showSuccessMessage && (
            <Fragment>
               <Typography style={{ color: 'green', fontWeight: 'bold', fontSize: '1rem' }}>
                  {t?.forgot_password_success_message}
               </Typography>
               <Typography style={{ marginTop: 10, color: 'grey' }}>
                  {t?.forgot_password_wait_while_we_redirect}
               </Typography>
            </Fragment>
         )}
      </div>
   );
};

export default ResetPasswordForm;

import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SITE_URL } from '@helper/config';
import { CREATE_ECOMMERCE_PLATFORM_MUTATION } from '../../../../queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import { E_COMMERCE_PLATFORM_TYPE } from '../../../../queries/Settings/Service/GraphQL/ECommercePlatform/types';
import ErrorPage from '../shared-components/ErrorPage';

const CreateShopifyAccount = ({ location: { search } }) => {
   const { session } = queryString.parse(search);
   const isSessionValid = session !== 'undefined';
   const [createShopifyAccount, { loading }] = useMutation(CREATE_ECOMMERCE_PLATFORM_MUTATION);
   const person = useSelector((reducer) => reducer.personReducer.person);

   const [status, setStatus] = useState('');
   useEffect(() => {
      isSessionValid &&
         createShopifyAccount({
            variables: {
               type: E_COMMERCE_PLATFORM_TYPE.SHOPIFY,
               metaData: {
                  session,
                  customer: person.customer._id,
               },
            },
         });
      setTimeout(() => {
         const getshopifySession = localStorage.getItem('shopifySession');
         if (getshopifySession) {
            localStorage.removeItem('shopifySession');
            window.location.href = '/settings/e-commerce/shopify';
         } else {
            window.open('/authentication/shopify/close', '_self', '');
         }
      }, 1000);
      // .then((e) => {
      //    console.log(e)
      //    window.close();
      //    return '';
      // })
      // .catch((error) => {
      //    console.log("HATA", error);
      //    setStatus('failed');
      //    return '';
      // });
   }, [session, person?.customer?._id, createShopifyAccount]);
   // if (status === 'failed') {
   //    setTimeout(() => {
   //       window.close();
   //    }, 5000);
   // }

   if (loading) {
      return <div className='loading' />;
   }

   return <Fragment>{status === 'failed' && <ErrorPage platformHref={'/settings/e-commerce/shopify'} />}</Fragment>;
};

export default React.memo(CreateShopifyAccount);

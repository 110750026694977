import { TelegramIcon } from "@assets/icons/SettingsIcons/settingsIcons";
import WhatsappIcon from "@components/SocialMediaIcon/whatsappIcon";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { GridColumns } from "@material-ui/data-grid";
import EditIcon from '@material-ui/icons/Edit';
import React from "react";
import { LeftBarData } from "./components/leftBar";
import { RightBarData } from "./components/rightBar";

export enum SenderType {
    PersonGroups,
    WhatsappGroups,
    TelegramChats,
}

export enum SendingOption {
    Manualy = "Manualy",
    ByFolderName = "ByFolderNames"
}

export type BulkMessageStateData = LeftBarData;

export interface SelectedTarget {
    type: SenderType;
    id: string;
}

export type CreateBulkSharingOutput = {
    createBulkSharing: {
        code?: string;
        data: BulkData,
        success?: boolean;
        message?: string;
    }
}

export type FinalizeBulkSharingOutput = {
    finalizeBulkSharing: {
        code?: string;
        data: BulkData,
        success?: boolean;
        message?: string;
    }
}

export interface BulkData {
    _id: string;
    customer: string;
    name: null;
    whatsAppStatus: BulkSharingStatusEnum;
    telegramStatus: BulkSharingStatusEnum;
    whatsAppSendFolderNames: boolean;
    telegramSendFolderNames: boolean;
    qpienPersonGroups: string[];
    whatsAppGroups: string[];
    telegramChannels: string[];
    folders: BulkFolder[];
}

export interface BulkFolder {
    folderName: string;
    folderLink: string;
    telegramStatus: BulkSharingStatusEnum;
    whatsAppStatus: BulkSharingStatusEnum;
    length: number;
}

export enum BulkSharingStatusEnum {
    PENDING = 'PENDING',
    UPLOADED = 'UPLOADED',
    SENDING = 'SENDING',
    SENT = 'SENT',
    FAILED = 'FAILED',
    CANCELLED = 'CANCELLED',
}

export function generateFields(t: any, senderType: SenderType, onGroupEdit: (groupId: string) => void): GridColumns {
    switch (senderType) {
        case SenderType.TelegramChats:
            return [
                {
                    field: 'field_1',
                    headerName: t['bulk_message_telegram_account'],
                    flex: 3,
                    renderCell: ({ row }) => {
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <TelegramIcon style={{
                                        width: 30,
                                        height: 30,
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        marginBottom: 3,
                                    }} className={''} selected={false} />
                                </Grid>
                                <Grid item xs={10}>
                                    {row.field_1 ? (
                                        <Typography
                                            style={{
                                                marginTop: 5,
                                            }}
                                        >
                                            {row.field_1}
                                        </Typography>
                                    ) : (
                                        <div
                                            style={{
                                                width: 50,
                                                height: 5,
                                                padding: 5,
                                                borderBottom: '2px solid grey',
                                                marginTop: 5,
                                                marginLeft: 5,
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        );
                    },
                },
                {
                    field: 'field_2',
                    flex: 9,
                    headerName: t['bulk_connect_channel_name'],
                },
            ]
        case SenderType.WhatsappGroups:
            return [
                {
                    field: 'field_1',
                    headerName: t['bulk_message_whatsapp_account'],
                    flex: 3,
                    renderCell: ({ row }) => {
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <WhatsappIcon style={{
                                        width: 30,
                                        height: 30,
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        marginBottom: 3,
                                    }} className={''} selected={false} color={'#25d366'} />
                                </Grid>
                                <Grid item xs={10}>
                                    {row.field_1 ? (
                                        <Typography
                                            style={{
                                                marginTop: 5,
                                            }}
                                        >
                                            {row.field_1}
                                        </Typography>
                                    ) : (
                                        <div
                                            style={{
                                                width: 50,
                                                height: 5,
                                                padding: 5,
                                                borderBottom: '2px solid grey',
                                                marginTop: 5,
                                                marginLeft: 5,
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        );
                    },
                },
                {
                    field: 'field_2',
                    flex: 9,
                    headerName: t['bulk_connect_channel_name'],
                },
            ]
        case SenderType.PersonGroups:
            return [
                {
                    field: 'field_1',
                    headerName: t['bulk_message_whatsapp_account'],
                    minWidth: 150,
                    flex: 5,
                    renderCell: ({ row }) => {
                        return (
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <WhatsappIcon style={{
                                        width: 30,
                                        height: 30,
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        marginBottom: 3,
                                    }} className={''} selected={false} color={'#25d366'} />
                                </Grid>
                                <Grid item xs={10}>
                                    {row.field_1 ? (
                                        <Typography
                                            style={{
                                                marginTop: 5,
                                            }}
                                        >
                                            {row.field_1}
                                        </Typography>
                                    ) : (
                                        <div
                                            style={{
                                                width: 50,
                                                height: 5,
                                                padding: 5,
                                                borderBottom: '2px solid grey',
                                                marginTop: 5,
                                                marginLeft: 5,
                                            }}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        );
                    },
                },
                {
                    field: 'field_2',
                    headerName: t['bulk_list_field_2'],
                    flex: 5
                },
                {
                    field: 'field_3',
                    headerName: t['page_settings_button_action'],
                    minWidth: 100,
                    flex: 2,
                    renderCell: ({ row }) => {
                        return <IconButton
                            onClick={() => {
                                onGroupEdit(row.id);
                            }}
                        >
                            <EditIcon />
                        </IconButton>
                    },
                }
            ]
        default:
            return []
    }
}

export interface PersonGroup {
    _id: string;
    name: string;
    senderPlatform: any;
    senderPlatformType: string;
}

export interface FolderFile {
    name: string;
    size: string;
    type: string;
    file: File;
}

export interface Folder {
    name: string;
    files: FolderFile[];
    folderDate?: string;
    sendingTelegram: boolean;
    sendingWhatsapp: boolean;
    telegramSent: boolean;
    whatsAppSent: boolean;
    telegramFailed: boolean;
    whatsAppFailed: boolean;
}
import { LanguageContext } from '@helper/locale/langContext';
import { Box, ClickAwayListener, Grid, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { IFlowBlockFieldTypes, IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DataObjectIcon from '@mui/icons-material/DataObject';
import AceEditor from 'react-ace';
import DeleteIcon from '@mui/icons-material/Delete';

interface BodyRequestProps {
   blockState: IFlowBlockTemplate[];
   customFields: { name: string; _id: string }[];
   setBlockState: React.Dispatch<React.SetStateAction<IFlowBlockTemplate[]>>;
   showFieldHeader: string | null | undefined;
   hoveredField: string | null | undefined;
   showFieldIndexHeader: number | null | undefined;
   hoveredBlockId: number | null | undefined;
   selectedField: { blockId: string | null; field: string | null; index: number | null } | undefined;
   setSelectedBoyType: React.Dispatch<React.SetStateAction<string>>;
   setToUpdate: React.Dispatch<React.SetStateAction<{ _id: string }[]>>;
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   setSelectedField: React.Dispatch<
      React.SetStateAction<{ blockId: string | null; field: string | null; index: number | null } | undefined>
   >;
   selectedBoyType: string;
   handelFocusHeader: (index: number | null, field: string | null) => void;
   handelHover: (index: number | null, field: string | null) => void;
}
const BodyRequest: React.FC<BodyRequestProps> = ({
   blockState,
   selectedBoyType,
   setIsBtnDisable,
   handelFocusHeader,
   setBlockState,
   setToUpdate,
   setSelectedField,
   handelHover,
   setSelectedBoyType,
   showFieldIndexHeader,
   showFieldHeader,
   hoveredBlockId,
   hoveredField,
   selectedField,
   customFields,
}) => {
   const { lang } = useContext(LanguageContext);
   const [clickedBodyType, setClickedBodyType] = useState<boolean>(false);

   const t = lang?.translation;

   const useStyles = makeStyles(() => ({
      groupHeader: {
         display: 'flex',
         justifyContent: 'space-between',
         padding: '10px',
         marginBottom: '10px',
         fontWeight: 'bold',
         borderBottom: '1px solid #E7E7E7',
         borderRadius: '5px',
      },
      groupContent: {
         display: 'flex',
         flexDirection: 'column',
         marginBottom: '10px',
         border: '1px solid #E7E7E7',
         borderRadius: '5px',
      },
      btnGroup: {
         height: 36,
         width: '100%',
         border: '1px dashed gray',
         borderRadius: '10px',
         background: 'transparent',
         cursor: 'pointer',
         color: 'gray',
         marginTop: '10px',
      },

      title: {
         fontWeight: 600,
      },
      deleteIcon: {
         color: 'gray',
         cursor: 'pointer',
      },
      arrowResponse: {
         display: 'flex',
         cursor: 'pointer',
         color: 'gray',
      },
      dataObject: {
         position: 'absolute',
         right: 0,
         bottom: -5,
         fontSize: 16,
         backgroundColor: '#000',
         color: '#fff',
         borderRadius: 5,
         zIndex: 1,
      },
      dataObjectBody: {
         position: 'absolute',
         right: 0,
         bottom: -25,
         fontSize: 16,
         backgroundColor: '#000',
         color: '#fff',
         borderRadius: 5,
      },
   }));
   const classes = useStyles();

   const [showFieldBody, setShowFieldBody] = useState<string | null>();
   const [showFieldIndexBody, setShowFieldIndexBody] = useState<number | null>();
   const [bodyRequest, setBodyRequest] = useState<{ key: string; value: string }[]>([]);
   const [anchorBodyType, setAnchorBodyType] = useState<null | HTMLElement>(null);
   const [anchorElField, setAnchorElField] = useState<null | HTMLElement>(null);

   const handleMenuBodyTypeClose = () => {
      setAnchorBodyType(null);
      setClickedBodyType(false);
   };
   const handleMenuFieldClose = () => {
      setAnchorElField(null);
   };

   const handleMenuFieldOpen = (divRf: any, blockId: string, field: string, index: number) => {
      setAnchorElField(divRf.current);
      setSelectedField({ blockId: blockId, field: field, index: index });
   };

   const divRefBody = useRef(null);
   const divBodyRef = useRef(null);

   const handleMenuBodyTypeOpen = (divBodyRf: any) => {
      setAnchorBodyType(divBodyRf.current);
      setClickedBodyType(true);
   };
   const handelFocusBody = (index: number | null, field: string | null) => {
      setShowFieldBody(field);
      setShowFieldIndexBody(index);
   };

   const handleAddBodyRequest = (id: string) => {
      handelFocusHeader(null, null);
      const newBody = {
         key: '',
         value: '',
      };

      if (selectedBoyType === 'requestBodyFormData') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               if (item._id === id) {
                  const updatedFields = item.fields.map((field) => {
                     if (field.requestBodyFormData) {
                        return {
                           ...field,
                           requestBodyFormData: [...field.requestBodyFormData, newBody],
                        };
                     } else {
                        return {
                           ...field,
                           requestBodyFormData: [newBody],
                        };
                     }
                  });
                  return {
                     ...item,
                     fields: updatedFields,
                  };
               }
               return item;
            });
            setToUpdate([{ _id: id }]);
            return updatedBlockState;
         });
      }
      if (selectedBoyType === 'requestBodyFormDataUrlEncoded') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               if (item._id === id) {
                  const updatedFields = item.fields.map((field) => {
                     if (field.requestBodyFormDataUrlEncoded) {
                        return {
                           ...field,
                           requestBodyFormDataUrlEncoded: [...field.requestBodyFormDataUrlEncoded, newBody],
                        };
                     } else {
                        return {
                           ...field,
                           requestBodyFormDataUrlEncoded: [newBody],
                        };
                     }
                  });
                  return {
                     ...item,
                     fields: updatedFields,
                  };
               }
               return item;
            });
            setToUpdate([{ _id: id }]);
            return updatedBlockState;
         });
      }
   };

   const aceEditorRef = useRef<AceEditor | null>(null);

   const handleBodyChange = (id: string, value: string) => {
      setIsBtnDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     requestBody: value,
                  })),
               };
            }
            return item;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };

   useEffect(() => {
      if (
         selectedBoyType === 'requestBodyFormDataUrlEncoded' &&
         blockState[0]?.fields[0]?.requestBodyFormDataUrlEncoded
      ) {
         setBodyRequest(blockState[0]?.fields[0]?.requestBodyFormDataUrlEncoded);
      } else if (selectedBoyType === 'requestBodyFormData' && blockState[0]?.fields[0]?.requestBodyFormData) {
         setBodyRequest(blockState[0]?.fields[0]?.requestBodyFormData);
      }
   }, [selectedBoyType, blockState]);

   const height = 12;

   const handleUpdateBodyRequestValue = (id: string, value: string, bodyIndex: number) => {
      setIsBtnDisable(false);
      if (selectedBoyType === 'requestBodyFormData') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               if (item._id === id && item.fields) {
                  const updatedFields = item.fields.map((field) => {
                     if (field.requestBodyFormData) {
                        if (bodyIndex >= 0 && bodyIndex < field.requestBodyFormData.length) {
                           const updatedHeaders = [...field.requestBodyFormData];
                           updatedHeaders[bodyIndex] = {
                              ...field.requestBodyFormData[bodyIndex],
                              value: value,
                           };
                           return {
                              ...field,
                              requestBodyFormData: updatedHeaders,
                           };
                        }
                     }
                     return field;
                  });
                  return {
                     ...item,
                     fields: updatedFields,
                  };
               }
               return item;
            });
            setToUpdate([{ _id: id }]);
            return updatedBlockState;
         });
      }
      if (selectedBoyType === 'requestBodyFormDataUrlEncoded') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               if (item._id === id && item.fields) {
                  const updatedFields = item.fields.map((field) => {
                     if (field.requestBodyFormDataUrlEncoded) {
                        if (bodyIndex >= 0 && bodyIndex < field.requestBodyFormDataUrlEncoded.length) {
                           const updatedHeaders = [...field.requestBodyFormDataUrlEncoded];
                           updatedHeaders[bodyIndex] = {
                              ...field.requestBodyFormDataUrlEncoded[bodyIndex],
                              value: value,
                           };
                           return {
                              ...field,
                              requestBodyFormDataUrlEncoded: updatedHeaders,
                           };
                        }
                     }
                     return field;
                  });
                  return {
                     ...item,
                     fields: updatedFields,
                  };
               }
               return item;
            });
            setToUpdate([{ _id: id }]);
            return updatedBlockState;
         });
      }
   };
   const handleUpdateBodyRequestKey = (id: string, key: string, bodyIndex: number) => {
      setIsBtnDisable(false);
      if (selectedBoyType === 'requestBodyFormData') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               if (item._id === id && item.fields) {
                  const updatedFields = item.fields.map((field) => {
                     if (field.requestBodyFormData) {
                        if (bodyIndex >= 0 && bodyIndex < field.requestBodyFormData.length) {
                           const updatedHeaders = [...field.requestBodyFormData];
                           updatedHeaders[bodyIndex] = {
                              ...field.requestBodyFormData[bodyIndex],
                              key: key,
                           };
                           return {
                              ...field,
                              requestBodyFormData: updatedHeaders,
                           };
                        }
                     }
                     return field;
                  });
                  return {
                     ...item,
                     fields: updatedFields,
                  };
               }
               return item;
            });
            setToUpdate([{ _id: id }]);
            return updatedBlockState;
         });
      }
      if (selectedBoyType === 'requestBodyFormDataUrlEncoded') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               if (item._id === id && item.fields) {
                  const updatedFields = item.fields.map((field) => {
                     if (field.requestBodyFormDataUrlEncoded) {
                        if (bodyIndex >= 0 && bodyIndex < field.requestBodyFormDataUrlEncoded.length) {
                           const updatedHeaders = [...field.requestBodyFormDataUrlEncoded];
                           updatedHeaders[bodyIndex] = {
                              ...field.requestBodyFormDataUrlEncoded[bodyIndex],
                              key: key,
                           };
                           return {
                              ...field,
                              requestBodyFormDataUrlEncoded: updatedHeaders,
                           };
                        }
                     }
                     return field;
                  });
                  return {
                     ...item,
                     fields: updatedFields,
                  };
               }
               return item;
            });
            setToUpdate([{ _id: id }]);
            return updatedBlockState;
         });
      }
   };
   const handleSelectBodyType = (type: string) => {
      setBodyRequest([]);
      if (type === 'requestBodyFormData') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               const updatedFields = item.fields.map((field) => {
                  return {
                     ...field,
                     requestBodyFormDataUrlEncoded: null,
                     requestBody: null,
                  };
               });
               return { ...item, fields: updatedFields };
            });
            return updatedBlockState;
         });
      }

      if (type === 'requestBodyFormDataUrlEncoded') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               const updatedFields = item.fields.map((field) => {
                  return {
                     ...field,
                     requestBodyFormData: null,
                     requestBody: null,
                  };
               });
               return { ...item, fields: updatedFields };
            });
            return updatedBlockState;
         });
      }

      if (type === 'requestedBody') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((item) => {
               const updatedFields = item.fields.map((field) => {
                  return {
                     ...field,
                     requestBodyFormData: null,
                     requestBodyFormDataUrlEncoded: null,
                  };
               });
               return { ...item, fields: updatedFields };
            });
            return updatedBlockState;
         });
      }

      setSelectedBoyType(type);
      handleMenuBodyTypeClose();
   };

   const divRefHeader = useRef(null);

   const handleDeleteBodyRequest = (id: string, headerIndexToDelete: number) => {
      setIsBtnDisable(false);
      handelFocusHeader(null, null);
      if (selectedBoyType === 'requestBodyFormData') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((block) => {
               if (block._id === id && block.fields) {
                  const updatedFields = block.fields.map((field) => {
                     if (field.requestBodyFormData) {
                        if (headerIndexToDelete >= 0 && headerIndexToDelete < field.requestBodyFormData.length) {
                           const updatedHeaders = field.requestBodyFormData.filter(
                              (header, index) => index !== headerIndexToDelete,
                           );

                           return {
                              ...field,
                              requestBodyFormData: updatedHeaders,
                           };
                        }
                     }
                     return field;
                  });
                  return {
                     ...block,
                     fields: updatedFields,
                  };
               }
               return block;
            });
            setToUpdate([{ _id: id }]);
            return updatedBlockState;
         });
      }
      if (selectedBoyType === 'requestBodyFormDataUrlEncoded') {
         setBlockState((prevBlockState) => {
            const updatedBlockState = prevBlockState.map((block) => {
               if (block._id === id && block.fields) {
                  const updatedFields = block.fields.map((field) => {
                     if (field.requestBodyFormDataUrlEncoded) {
                        if (
                           headerIndexToDelete >= 0 &&
                           headerIndexToDelete < field.requestBodyFormDataUrlEncoded.length
                        ) {
                           const updatedHeaders = field.requestBodyFormDataUrlEncoded.filter(
                              (header, index) => index !== headerIndexToDelete,
                           );

                           return {
                              ...field,
                              requestBodyFormDataUrlEncoded: updatedHeaders,
                           };
                        }
                     }
                     return field;
                  });
                  return {
                     ...block,
                     fields: updatedFields,
                  };
               }
               return block;
            });
            setToUpdate([{ _id: id }]);
            return updatedBlockState;
         });
      }
   };

   const addField = (
      name: string,
      selectedField: { blockId: string | null; field: string | null; index: number | null } | undefined,
   ) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (selectedField && item._id === selectedField.blockId) {
               const updatedFields = item.fields.map((field) => {
                  if (field.type === IFlowBlockFieldTypes.HttpRequest) {
                     if (selectedField.field === 'body') {
                        const editor = aceEditorRef.current?.editor;
                        if (editor) {
                           const cursorPosition = editor.getCursorPosition();
                           editor.session.insert(cursorPosition, name);
                        }
                     }

                     if (
                        selectedField.blockId &&
                        field.requestBodyFormData &&
                        selectedField.index !== null &&
                        selectedField.field === 'request-body' &&
                        selectedBoyType === 'requestBodyFormData'
                     ) {
                        const textAreaValue = field.requestBodyFormData[selectedField.index].value || '';
                        const textAreaElement = document.getElementById(
                           `textarea-${selectedField.field}-${selectedField.index}`,
                        ) as HTMLTextAreaElement | null;

                        if (textAreaElement) {
                           const start = textAreaElement.selectionStart;
                           const end = textAreaElement.selectionEnd;
                           const updatedValue = textAreaValue.substring(0, start) + name + textAreaValue.substring(end);

                           handleUpdateBodyRequestValue(selectedField.blockId, updatedValue, selectedField.index);
                        }
                     }
                     if (
                        selectedField.blockId &&
                        field.requestBodyFormDataUrlEncoded &&
                        selectedField.index !== null &&
                        selectedField.field === 'request-body' &&
                        selectedBoyType === 'requestBodyFormDataUrlEncoded'
                     ) {
                        const textAreaValue = field.requestBodyFormDataUrlEncoded[selectedField.index].value || '';
                        const textAreaElement = document.getElementById(
                           `textarea-${selectedField.field}-${selectedField.index}`,
                        ) as HTMLTextAreaElement | null;

                        if (textAreaElement) {
                           const start = textAreaElement.selectionStart;
                           const end = textAreaElement.selectionEnd;
                           const updatedValue = textAreaValue.substring(0, start) + name + textAreaValue.substring(end);

                           handleUpdateBodyRequestValue(selectedField.blockId, updatedValue, selectedField.index);
                        }
                     }
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });

         setIsBtnDisable(false);
         return updatedBlockState;
      });

      handleMenuFieldClose();
   };

   return (
      <Grid>
         <Grid className={classes.groupContent} style={{ position: 'relative' }}>
            <Grid className={classes.groupHeader} ref={divBodyRef}>
               <Typography className={classes.title}>{t.automation_http_request_body}</Typography>
               <div onClick={() => handleMenuBodyTypeOpen(divBodyRef)} className={classes.arrowResponse}>
                  <div>
                     {selectedBoyType === 'requestedBody'
                        ? 'Json'
                        : selectedBoyType === 'requestBodyFormDataUrlEncoded'
                        ? 'Form-urlencoded'
                        : selectedBoyType === 'requestBodyFormData'
                        ? 'Form-data'
                        : ''}
                  </div>
                  {clickedBodyType ? (
                     <ArrowDropDownIcon onClick={() => setClickedBodyType((prev) => !prev)} />
                  ) : (
                     <ArrowRightIcon onClick={() => setClickedBodyType((prev) => !prev)} />
                  )}
               </div>
            </Grid>
            {selectedBoyType === 'requestedBody' ? (
               <ClickAwayListener onClickAway={() => handelFocusBody(null, null)}>
                  <div onClick={() => handelFocusHeader(null, null)}>
                     <AceEditor
                        ref={aceEditorRef}
                        name={`textarea-${'body'}-${0}`}
                        mode='json'
                        theme='xcode'
                        onChange={(value) => {
                           handleBodyChange(blockState[0]._id, value);
                        }}
                        editorProps={{ $blockScrolling: true }}
                        setOptions={{
                           enableBasicAutocompletion: true,
                           enableLiveAutocompletion: true,
                           enableSnippets: true,
                        }}
                        showGutter={false}
                        height='200px'
                        width='100%'
                        placeholder={t.automation_http_request_req_body}
                        value={blockState[0].fields[0].requestBody || ''}
                        onFocus={() => handelFocusBody(0, 'body')}
                        highlightActiveLine={false}
                        fontSize={14}
                     />
                     {showFieldBody === 'body' && showFieldIndexBody === 0 && (
                        <div ref={divRefBody}>
                           <DataObjectIcon
                              className={classes.dataObjectBody}
                              onClick={() => handleMenuFieldOpen(divRefBody, blockState[0]._id, 'body', 0)}
                           />
                        </div>
                     )}
                  </div>
               </ClickAwayListener>
            ) : (
               // ----------------------------Body Form-data and Form-urlencoded------------------
               <div style={{ margin: '5px' }}>
                  {bodyRequest.map((header, bodyIndex) => (
                     <div
                        style={{
                           position: 'relative',
                           display: 'flex',
                           justifyContent: 'space-between',
                        }}
                        onMouseEnter={() => handelHover(bodyIndex, 'request-body')}
                        onMouseLeave={() => handelHover(null, null)}
                        key={bodyIndex}
                     >
                        <Box
                           style={{
                              display: 'flex',
                              gap: '0.5rem',
                              width: '100%',
                              marginTop: '5px',
                              marginBottom: '-15px',
                           }}
                        >
                           <div style={{ width: '50%' }} onClick={() => handelFocusHeader(null, null)}>
                              <Typography style={{ marginBottom: '-15px' }}>{t.automation_http_request_key}</Typography>
                              <TextField
                                 name='key'
                                 placeholder={t.automation_http_request_key}
                                 value={header.key}
                                 variant='outlined'
                                 inputProps={{
                                    style: {
                                       height,
                                    },
                                 }}
                                 onChange={(e) =>
                                    handleUpdateBodyRequestKey(blockState[0]._id, e.target.value, bodyIndex)
                                 }
                              />
                           </div>
                           <div style={{ width: '50%', position: 'relative' }}>
                              <Typography style={{ marginBottom: '-15px' }}>{t.page_settings_value}</Typography>

                              <div>
                                 <TextField
                                    id={`textarea-${'request-body'}-${bodyIndex}`}
                                    name='value'
                                    placeholder={t.page_settings_value}
                                    value={header.value}
                                    variant='outlined'
                                    inputProps={{
                                       style: {
                                          height,
                                       },
                                    }}
                                    onFocus={() => handelFocusHeader(bodyIndex, 'request-body')}
                                    onChange={(e) =>
                                       handleUpdateBodyRequestValue(blockState[0]._id, e.target.value, bodyIndex)
                                    }
                                 />
                                 {showFieldHeader === 'request-body' && showFieldIndexHeader === bodyIndex && (
                                    <div ref={divRefHeader}>
                                       <DataObjectIcon
                                          className={classes.dataObject}
                                          onClick={() =>
                                             handleMenuFieldOpen(
                                                divRefHeader,
                                                blockState[0]._id,
                                                'request-body',
                                                bodyIndex,
                                             )
                                          }
                                       />
                                    </div>
                                 )}
                              </div>
                           </div>
                        </Box>
                        {hoveredBlockId === bodyIndex && hoveredField === 'request-body' && (
                           <DeleteIcon
                              onClick={() => handleDeleteBodyRequest(blockState[0]._id, bodyIndex)}
                              className={classes.deleteIcon}
                              style={{ position: 'absolute', right: -22, bottom: 10 }}
                           />
                        )}
                     </div>
                  ))}
                  {bodyRequest.length < 10 && (
                     <button className={classes.btnGroup} onClick={() => handleAddBodyRequest(blockState[0]._id)}>
                        {t.comp_button_add}
                     </button>
                  )}
               </div>
            )}
         </Grid>
         <Menu
            anchorEl={anchorElField}
            open={Boolean(anchorElField)}
            onClose={handleMenuFieldClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{ marginTop: 5 }}
            PaperProps={{
               style: {
                  width: '360px',
                  borderRadius: '5px',
                  maxHeight: '250px',
                  marginLeft: selectedField?.field === 'body' ? '0' : '-185px',
                  marginTop: selectedField?.field === 'body' ? '112px' : '20px',
               },
            }}
         >
            {selectedField?.field === 'body' && (
               <div>
                  <MenuItem onClick={() => addField(t.atomation_flow_first_name_value, selectedField)}>
                     {t.atomation_flow_first_name}
                  </MenuItem>
                  <MenuItem onClick={() => addField(t.atomation_flow_last_name_value, selectedField)}>
                     {t.atomation_flow_last_name}
                  </MenuItem>
                  <MenuItem onClick={() => addField(t.atomation_full_name_value, selectedField)}>
                     {t.atomation_full_name}
                  </MenuItem>
                  <MenuItem onClick={() => addField(t.atomation_flow_email_value, selectedField)}>
                     {t.atomation_flow_email}
                  </MenuItem>
                  <MenuItem onClick={() => addField(t.atomation_flow_phone_value, selectedField)}>
                     {t.atomation_flow_phone}
                  </MenuItem>
               </div>
            )}

            {customFields.map((custom) => (
               <MenuItem onClick={() => addField('{{' + custom.name + '}}', selectedField)}>{custom.name}</MenuItem>
            ))}
         </Menu>
         <Menu
            anchorEl={anchorBodyType}
            open={Boolean(anchorBodyType)}
            onClose={handleMenuBodyTypeClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{ marginTop: 5 }}
            PaperProps={{
               style: {
                  width: '353px',
                  borderRadius: '5px',
                  maxHeight: '250px',
                  marginLeft: 0,
                  marginTop: '75px',
               },
            }}
         >
            <div>
               <MenuItem onClick={() => handleSelectBodyType('requestedBody')}>Json</MenuItem>
               <MenuItem onClick={() => handleSelectBodyType('requestBodyFormData')}>Form-data</MenuItem>
               <MenuItem onClick={() => handleSelectBodyType('requestBodyFormDataUrlEncoded')}>
                  Form-urlencoded
               </MenuItem>
            </div>
         </Menu>
      </Grid>
   );
};

export default BodyRequest;

import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import { Checkbox, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../helper/locale/langContext';
import { CHECK_STATUS_ENUM } from './profileMenu';

// const LEFT_PANEL_WIDTH = 320;

const useStyles = makeStyles((theme) => ({
   root: {
      '&$checked': {
         color: theme.palette.primary.main,
      },
      padding: '5px',
   },
   message: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const LogOutMessage = ({ onAccept, onCancel, updatedJoinedChatStatusloading }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [checkboxStatus, setCheckboxStatus] = useState(CHECK_STATUS_ENUM.JUST_LOGOUT);

   return (
      <Grid>
         <ModalLargeText style={{ textAlign: 'center', marginBlock: 10 }}>{t?.are_you_sure_logout}</ModalLargeText>

         <Grid container alignItems='flex-start' style={{ flexWrap: 'inherit', marginBottom: 10, marginTop: 24 }}>
            <Checkbox
               checked={checkboxStatus === CHECK_STATUS_ENUM.JUST_LOGOUT ? true : false}
               onChange={() => setCheckboxStatus(CHECK_STATUS_ENUM.JUST_LOGOUT)}
               classes={{ root: classes.root, checked: classes.checked }}
            />
            <Typography style={{ marginTop: 3 }}>{t?.just_logout}</Typography>
         </Grid>
         <Grid container alignItems='flex-start' style={{ flexWrap: 'inherit', marginBottom: 10 }}>
            <Checkbox
               checked={checkboxStatus === CHECK_STATUS_ENUM.LOGOUT_AND_LEAVE_ALL_ASSIGNED_CONVERSATIONS ? true : false}
               onChange={() => setCheckboxStatus(CHECK_STATUS_ENUM.LOGOUT_AND_LEAVE_ALL_ASSIGNED_CONVERSATIONS)}
               classes={{ root: classes.root, checked: classes.checked }}
            />
            <Typography style={{ marginTop: 3 }}>{t?.logout_and_leave_all_assigned_conversations}</Typography>
         </Grid>
         <Grid container alignItems='flex-start' style={{ flexWrap: 'inherit', marginBottom: 10 }}>
            <Checkbox
               checked={checkboxStatus === CHECK_STATUS_ENUM.LOGOUT_AND_CLOSE_ALL_ASSIGNED_CONVERSATIONS ? true : false}
               onChange={() => setCheckboxStatus(CHECK_STATUS_ENUM.LOGOUT_AND_CLOSE_ALL_ASSIGNED_CONVERSATIONS)}
               classes={{ root: classes.root, checked: classes.checked }}
            />
            <Typography style={{ marginTop: 3 }}>{t?.logout_and_close_all_assigned_conversations}</Typography>
         </Grid>

         <ModalButtonField>
            <ButtonCancel
               className={classes.modalButtons}
               onClick={onCancel}
               label={t?.comp_button_cancel}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
            />
            <ButtonOK
               endIcon={
                  updatedJoinedChatStatusloading ? <CircularProgress style={{ color: 'white' }} size={20} /> : null
               }
               className={classes.modalButtons}
               onClick={() => onAccept(checkboxStatus)}
               style={{
                  color: 'white',
                  backgroundColor: '#ef5350',
                  '&:hover': {
                     backgroundColor: '#f44336',
                  },
               }}
               label={t?.comp_sidebar_logout}
            />
         </ModalButtonField>
      </Grid>
   );
};

export default LogOutMessage;

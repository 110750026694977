import React, { FC, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import { ButtonBase, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { ProductProps } from '../types';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import { currencyHandler } from '@helper/textUtils';
import { priceFormatWithoutCurrency } from '@helper/functions';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #D9E0E6',
      borderRadius: 4,
      marginBottom: 8,
      width: '100%',
      alignItems: 'stretch',
   },
   containerButtonbase: {
      display: 'flex',
      alignItems: 'center',
      padding: 8,
      flexGrow: 1,
   },
   selected: {
      border: '1px solid #2175F8',
   },
   image: {
      width: 42,
      height: 42,
      flexShrink: 0,
      borderRadius: 4,
      overflow: 'hidden',
   },
   defaultMediaIcon: {
      color: '#999999',
      fontSize: 42,
   },
   infoContainer: {
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: 5,
   },
   nameContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
   },
   name: {
      color: '#385273',
      fontSize: '1em',
      lineHeight: 1.4,
   },
   availableAndPrice: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
   },
   available: {
      color: '#385273',
      opacity: '0.6',
      fontSize: '0.9em',
      lineHeight: 1.3,
   },
   price: {
      color: '#157CFC',
      fontSize: '1em',
      lineHeight: 1.3,
   },
   variantsContainer: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      flexGrow: 1,
   },
   variantContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
      padding: '4px 8px',
      borderTop: '1px solid #D9E0E6',
   },
   variantLeft: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 3,
   },
   variantRight: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
}));

interface ProductListItemProps {
   product: ProductProps;
   isSelected: boolean;
   selectProduct: (id: string) => void;
   deselectProduct: (id: string) => void;
   getVariant: (id: string) => Promise<void>;
   eCommerceType: string;
   eCommerceVariantLoading: boolean;
}

const ProductListItem: FC<ProductListItemProps> = ({
   product,
   isSelected,
   selectProduct,
   deselectProduct,
   getVariant,
   eCommerceType,
   eCommerceVariantLoading,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [isVisibleVariants, setVisibleVariants] = useState<boolean>(false);
   const [isProductUpdatedRedux, setIsProductUpdatedRedux] = useState<number>(0);
   const [isECommerceVariantLoading, setIsECommerceVariantLoading] = useState<boolean>(false);
   const { _id, image, title, status, variants, total_stock } = product;
   const onClick = async () => {
      if (isSelected) {
         deselectProduct(_id);
         if (variants?.length !== 1) {
            setVisibleVariants(false);
            setIsProductUpdatedRedux(0);
         }
      } else {
         if (eCommerceType === E_COMMERCE_PLATFORM_TYPE.IDEASOFT) {
            if (eCommerceVariantLoading) return;
            setIsECommerceVariantLoading(true);
            await getVariant(_id.toString());
            setIsProductUpdatedRedux(1);
            setIsECommerceVariantLoading(false);
            selectProduct(_id);
         } else {
            selectProduct(_id);
            if (variants?.length !== 1) {
               setVisibleVariants(true);
            }
         }
      }
   };

   useEffect(() => {
      if (isProductUpdatedRedux > 0) {
         setVisibleVariants(true);
      }
   }, [isProductUpdatedRedux]);

   return (
      <div className={classes.container}>
         <ButtonBase
            onClick={onClick}
            disableRipple
            className={classNames(classes.containerButtonbase, isSelected && classes.selected)}
         >
            {image ? (
               <div style={{ position: 'relative' }}>
                  <img src={image} alt='product image' className={classes.image} />
                  {isECommerceVariantLoading && (
                     <CircularProgress
                        style={{
                           position: 'absolute',
                           color: '#2175F8',
                           top: 10,
                           left: 10,
                        }}
                        size={20}
                     />
                  )}
               </div>
            ) : (
               <div
                  className={classes.image}
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     position: 'relative',
                  }}
               >
                  <BrokenImageIcon className={classes.defaultMediaIcon} />
                  {isECommerceVariantLoading && (
                     <CircularProgress
                        style={{
                           position: 'absolute',
                           color: '#2175F8',
                           top: 10,
                           left: 10,
                        }}
                        size={20}
                     />
                  )}
               </div>
            )}
            <div className={classes.infoContainer}>
               <div className={classes.nameContainer}>
                  <Typography variant='body1' noWrap className={classes.name}>
                     {title}
                  </Typography>
               </div>
               <div className={classes.availableAndPrice}>
                  <Typography variant='body2' className={classes.available}>
                     {`${t['page_e_commerce_products_typo_stock']}: ${total_stock}`}
                  </Typography>
                  <Typography variant='body2' className={classes.price}>
                     {currencyHandler(variants[0]?.currency)}
                     {(variants[0]?.discountedPrice
                        ? variants[0]?.discountedPrice
                             ?.toString()
                             .split(' ')[0]
                             ?.toLocaleString('en-US', { minimumFractionDigits: 2 })
                        : variants[0]?.salesPrice?.toString().split(' ')[0]
                     )?.toLocaleString('en-US', { minimumFractionDigits: 2 })}
                  </Typography>
               </div>
            </div>
         </ButtonBase>
         {isVisibleVariants && (
            <div className={classes.variantsContainer}>
               {variants?.map((variant: any) => {
                  let variantName = '';
                  if (variant.variantsType1 && variant.variantsType1 !== 'Default Title') {
                     variantName = variant.variantsType1;
                  }
                  if (variant.variantsType2 && variant.variantsType2 !== 'Default Title') {
                     variantName = variantName + ' / ' + variant.variantsType2;
                  }
                  if (variant.variantsType3 && variant.variantsType3 !== 'Default Title') {
                     variantName = variantName + ' / ' + variant.variantsType3;
                  }
                  variantName = variantName.trim() === '' ? variant.name : variantName;
                  return (
                     <div key={variant.variantId} className={classes.variantContainer}>
                        <div className={classes.variantLeft}>
                           <Typography variant='body2' noWrap className={classes.name}>
                              {variantName}
                           </Typography>
                           <Typography style={{ opacity: 0.5, color: 'black' }}>&nbsp;({variant.stock})</Typography>
                        </div>
                        <div className={classes.variantRight}>
                           <Typography variant='body2' className={classes.price}>
                              {`${currencyHandler(variants[0]?.currency)}${priceFormatWithoutCurrency(
                                 variant?.discountedPrice,
                              )}`}
                           </Typography>
                        </div>
                     </div>
                  );
               })}
            </div>
         )}
      </div>
   );
};

export default ProductListItem;

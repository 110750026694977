import { LanguageContext } from '@helper/locale/langContext';
import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import { GET_HTTP_TEST } from '@queries/Automation/query';
import { useLazyQuery } from '@apollo/client';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import { isValidHttpUrl } from '@helper/functions';

interface HttpTestProps {
   blockState: IFlowBlockTemplate[];
   selectedBoyType: string;
}

const TestResponse: React.FC<HttpTestProps> = ({ blockState, selectedBoyType }) => {
   const useStyles = makeStyles(() => ({
      header: {
         display: 'flex',
         justifyContent: 'space-between',
         margin: '0 5px',
      },
      groupHeader: {
         display: 'flex',
         justifyContent: 'space-between',
         padding: '10px',
         fontWeight: 'bold',
         borderRadius: '5px',
      },
      groupResponse: {
         display: 'flex',
         flexDirection: 'column',
         gap: '10px',
         borderTop: '1px solid #E7E7E7',
         minHeight: '100px',
         padding: '10px',
      },
      groupContent: {
         display: 'flex',
         flexDirection: 'column',
         marginBottom: '10px',
         border: '1px solid #E7E7E7',
         borderRadius: '5px',
      },

      title: {
         fontWeight: 600,
      },
      test: {
         display: 'flex',
         gap: '10px',
         color: '#147CFC',
         fontWeight: 600,
         cursor: 'pointer',
      },
      arrow: {
         color: 'gray',
         cursor: 'pointer',
      },
      arrowResponse: {
         cursor: 'pointer',
      },
      arrowResponseClicked: {
         color: '#147CFC',
         cursor: 'pointer',
      },
      response: {
         display: 'flex',
         gap: '5px',
      },
      responseContent: {
         margin: '5px 10px',
      },
      textArea: {
         backgroundColor: '#F7F7FA',
      },
      disable: {
         display: 'flex',
         gap: '10px',
         color: 'gray',
         fontWeight: 600,
         //cursor: 'pointer',
      },
   }));
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [state, setState] = useState<
      { requestFailed: boolean; statusCode: number; body: string; headers: { key: string; value: string }[] }[]
   >([]);
   const [clickedDown, setClickedDown] = useState<boolean>(false);
   const [clickedResponseHeader, setClickedResponseHeader] = useState<boolean>(false);
   const [clickedResponseBody, setClickedResponseBody] = useState<boolean>(false);
   const [isLoading, setIsLoading] = useState(false);

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' justifyContent='center' xs={12}>
            <CircularProgress size={20} color='inherit' />
         </Grid>
      );
   }, []);

   const [getTest] = useLazyQuery(GET_HTTP_TEST);
   const handleTest = () => {
      setState([]);
      setClickedDown(false);
      setClickedResponseHeader(false);
      setClickedResponseBody(false);
      try {
         setIsLoading(true);
         let input = {};
         if (selectedBoyType === 'requestedBody') {
            input = {
               body: blockState[0]?.fields[0]?.requestBody,
               headers: blockState[0]?.fields[0]?.requestHeaders,
               method: blockState[0]?.fields[0]?.requestMethod,
               url: blockState[0]?.fields[0]?.requestUrl,
            };
         } else if (selectedBoyType === 'requestBodyFormData') {
            input = {
               formData: blockState[0]?.fields[0]?.requestBodyFormData,
               headers: blockState[0]?.fields[0]?.requestHeaders,
               method: blockState[0]?.fields[0]?.requestMethod,
               url: blockState[0]?.fields[0]?.requestUrl,
            };
         } else if (selectedBoyType === 'requestBodyFormDataUrlEncoded') {
            input = {
               formDataUrlEncoded: blockState[0]?.fields[0]?.requestBodyFormDataUrlEncoded,
               headers: blockState[0]?.fields[0]?.requestHeaders,
               method: blockState[0]?.fields[0]?.requestMethod,
               url: blockState[0]?.fields[0]?.requestUrl,
            };
         }
         getTest({
            variables: {
               input: input,
            },
            fetchPolicy: 'network-only',
            onCompleted: (res) => {
               setState([res.httpTest.data]);
               setIsLoading(false);
            },
         });
      } catch (error) {
         console.error('Error on test request', error);
      }
   };

   useEffect(() => {
      if (isValidHttpUrl(blockState[0]?.fields[0]?.requestUrl) === false || state.length === 0) {
         setClickedDown(false);
      }
   }, [blockState, state]);

   return (
      <Grid>
         <Grid className={classes.header}>
            <Typography className={classes.title}>{t.automation_http_request_response}</Typography>

            <Typography
               className={isValidHttpUrl(blockState[0]?.fields[0]?.requestUrl) ? classes.test : classes.disable}
               onClick={isValidHttpUrl(blockState[0]?.fields[0]?.requestUrl) ? handleTest : undefined}
            >
               {isLoading && <Loader />}
               <span style={{ whiteSpace: 'nowrap' }}>{t.campaign_test_button}</span>
            </Typography>
         </Grid>
         <Grid className={classes.groupContent}>
            <Grid className={classes.groupHeader}>
               <div>
                  {state.length > 0 ? (
                     <div>
                        {state.map((test, index) => (
                           <Typography key={index}>
                              {test.requestFailed === true ? t.automation_http_request_faild : test.statusCode + 'OK'}
                           </Typography>
                        ))}
                     </div>
                  ) : (
                     <Typography style={{ color: 'gray' }}>{t.automation_http_request_response_status_code}</Typography>
                  )}
               </div>

               {clickedDown ? (
                  <KeyboardArrowDownIcon
                     className={classes.arrow}
                     onClick={
                        isValidHttpUrl(blockState[0]?.fields[0]?.requestUrl) && state.length > 0
                           ? () => setClickedDown((prev) => !prev)
                           : undefined
                     }
                  />
               ) : (
                  <KeyboardArrowRightIcon
                     className={classes.arrow}
                     onClick={
                        isValidHttpUrl(blockState[0]?.fields[0]?.requestUrl) && state.length > 0
                           ? () => setClickedDown((prev) => !prev)
                           : undefined
                     }
                  />
               )}
            </Grid>
            {clickedDown && (
               <Grid className={classes.groupResponse}>
                  <Grid>
                     <Grid className={classes.response}>
                        {clickedResponseHeader ? (
                           <ArrowDropDownIcon
                              className={classes.arrowResponseClicked}
                              onClick={() => setClickedResponseHeader((prev) => !prev)}
                           />
                        ) : (
                           <ArrowRightIcon
                              className={classes.arrowResponse}
                              onClick={() => setClickedResponseHeader((prev) => !prev)}
                           />
                        )}
                        <Grid
                           onClick={() => setClickedResponseHeader((prev) => !prev)}
                           className={clickedResponseHeader ? classes.arrowResponseClicked : classes.arrowResponse}
                        >
                           {t.automation_http_request_response_headers}
                        </Grid>
                     </Grid>
                     {clickedResponseHeader && (
                        <Grid className={classes.responseContent}>
                           {state.map((test, index) => (
                              <div
                                 style={{
                                    maxHeight: '250px',
                                    width: '100%',
                                    overflow: 'auto',
                                 }}
                              >
                                 {test?.headers.map((head) => (
                                    <Box
                                       style={{
                                          display: 'flex',
                                          gap: '5px',
                                          width: '100%',
                                          marginTop: '5px',
                                          whiteSpace: 'nowrap',
                                       }}
                                    >
                                       <Typography className={classes.title}>{head.key}</Typography>
                                       <div>:</div>
                                       <Typography>{head.value}</Typography>
                                    </Box>
                                 ))}
                              </div>
                           ))}
                        </Grid>
                     )}
                  </Grid>
                  <Grid>
                     <Grid className={classes.response}>
                        {clickedResponseBody ? (
                           <ArrowDropDownIcon
                              className={classes.arrowResponseClicked}
                              onClick={() => setClickedResponseBody((prev) => !prev)}
                           />
                        ) : (
                           <ArrowRightIcon
                              className={classes.arrowResponse}
                              onClick={() => setClickedResponseBody((prev) => !prev)}
                           />
                        )}
                        <Grid
                           onClick={() => setClickedResponseBody((prev) => !prev)}
                           className={clickedResponseBody ? classes.arrowResponseClicked : classes.arrowResponse}
                        >
                           {t.automation_http_request_response_body}
                        </Grid>
                     </Grid>
                     {clickedResponseBody && (
                        <Grid className={classes.responseContent}>
                           {state.map((test, index) => (
                              <AceEditor
                                 name='testName'
                                 mode='json'
                                 theme='xcode'
                                 showGutter={false}
                                 height='200px'
                                 width='100%'
                                 value={test.body}
                                 highlightActiveLine={false}
                                 fontSize={14}
                                 readOnly={true}
                                 wrapEnabled={true}
                                 className={classes.textArea}
                              />
                           ))}
                        </Grid>
                     )}
                  </Grid>
               </Grid>
            )}
         </Grid>
      </Grid>
   );
};

export default TestResponse;

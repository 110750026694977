import { LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
   root: {
      height: 29,
      borderRadius: 5,
   },
   bar: {
      backgroundColor: ({ item }) => item?.color || theme.palette.primary.main,
      borderRadius: 5,
   },
   colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
   },
   progressLabel: {
      position: 'absolute',
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      height: 29,
      '& span': {
         color: '#fff',
         width: '100%',
         paddingLeft: 8,
      },
   },
   textColorBlack: {
      color: '#000000 !important',
   },
}));

const PercentageSection = ({ item }) => {
   const classes = useStyles({
      item,
   });

   return (
      <div style={{ position: 'relative' }}>
         <div className={classes.progressLabel}>
            <span className={classNames(item.percentage === 0 && classes.textColorBlack)}>{item.value}</span>
         </div>
         <LinearProgress
            classes={{ colorPrimary: classes.colorPrimary, root: classes.root, bar: classes.bar }}
            value={item.percentage}
            variant={'determinate'}
         />
      </div>
   );
};

export default PercentageSection;

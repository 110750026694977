import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   vertical: {
      // place holder for later developments. default behaviour
      paddingLeft: 1,
      // backgroundColor: 'red',
      //marginTop: theme.spacing(1),
      backgroundColor: 'transparent',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '57px',
   },
   horizontal: {
      height: '65px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // paddingLeft: 2,
   },
   // Drawer Icon Size
   icon: {
      // fontSize: 50,
      // transform: (props) => props.hovered && 'rotate(-10deg)',
      // transform: (props) => (props.hovered || props.selected) && 'scale(1.1)',
      transition: 'all .1s',
      width: 25,
      height: 25,
   },
   notSelectedIcon: {
      fill: '#7d8da0',
   },
   selectedIcon: {
      fill: '#fff',
   },
   verticalSelectedIconContainer: {
      width: '50px',
      height: '50px',
      backgroundColor: '#707070',
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   verticalNotSelectedIconContainer: {
      width: 50,
      height: 50,
      backgroundColor: 'rgba(112, 112, 112, 0.2)',
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   horizontalSelectedIconContainer: {
      backgroundColor: '#707070',
      height: '50px',
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '80%',
   },
   horizontalNotSelectedIconContainer: {
      width: '80%',
      height: '50px',
      backgroundColor: 'rgba(112, 112, 112, 0.2)',
      borderRadius: 10,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
}));

const SideBarItem = (props) => {
   const { item, selected, onClick, isHorizontal } = props;
   const classes = useStyles(props);

   return (
      <div className={isHorizontal ? classes.horizontal : classes.vertical}>
         <div
            onClick={onClick}
            className={clsx(
               !isHorizontal
                  ? selected
                     ? classes.verticalSelectedIconContainer
                     : props.hovered && classes.verticalNotSelectedIconContainer
                  : selected
                  ? classes.horizontalSelectedIconContainer
                  : props.hovered && classes.horizontalNotSelectedIconContainer,
            )}
         >
            <Button style={{ backgroundColor: 'transparent' }} disableRipple disableFocusRipple disableTouchRipple>
               {item.icon(clsx(classes.icon, selected ? classes.selectedIcon : classes.notSelectedIcon))}
            </Button>
         </div>
      </div>
   );
};

export default SideBarItem;

import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PaymentIcon from '@material-ui/icons/Payment';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme) => ({
   button: {
      borderRadius: 5,
      fontWeight: 'normal',
      background: (props) => (props.defaultIcons ? '#157cfcbf' : '#157CFC'),
      color: 'white',
      '&:hover': {
         background: '#157cfce8',
      },
   },
   filled: {
      color: 'white',
      backgroundColor: (props) => props.color || theme.chatApp.general.pallet.passiveStructureBlue,
      '&:hover': {
         backgroundColor: (props) => props.color || theme.chatApp.general.pallet.passiveStructureBlue,
      },
   },
   Outlined: {
      color: (props) => props.color || theme.chatApp.general.pallet.passiveStructureBlue,
      backgroundColor: 'white',
      border: (props) => '2px solid ' + (props.color || theme.chatApp.general.pallet.passiveStructureBlue),
      '&:hover': {
         color: 'white',
         backgroundColor: (props) => props.color || theme.chatApp.general.pallet.passiveStructureBlue,
      },
   },
}));

const ButtonFunction = (props) => {
   const { label, onClick, fullWidth, defaultIcons, disabled } = props;
   const classes = useStyles(props);

   return (
      <Button
         fullWidth={fullWidth}
         onClick={onClick}
         disabled={disabled}
         startIcon={defaultIcons && <PaymentIcon />}
         className={clsx(classes.button)}
      >
         <span> {label} </span>
      </Button>
   );
};

ButtonFunction.defaultProps = {
   variant: 'filled',
   label: 'Düzenle',
};

export default ButtonFunction;

import React, { useContext } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme) => ({
   actionsContainer: {
      padding: '2rem 0px',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row-reverse',
      marginTop: '8px',
   },
   nextBtn: {
      background: '#178CF9',
      paddingLeft: '3rem',
      paddingRight: '3rem',
      textTransform: 'capitalize',
   },
   backBtn: {
      border: '2px solid #B9D0E5',
      background: '#F7F7FA',
      paddingLeft: '3rem',
      paddingRight: '3rem',
      textTransform: 'capitalize',
      color: '#B9D0E5',
      boxShadow: 'none',
      '&:hover': {
         background: '#f7f7fa38',
         boxShadow: 'none',
      },
   },
}));

const NextBackBtn = ({
   isValid = true,
   nextBtnText,
   prevPageHandler,
   nextPageHandler,
   showBackBtn = true,
   nextBtnType = 'button',
   loading,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   return (
      <Grid container className={classes.actionsContainer}>
         <Button
            variant='contained'
            color='primary'
            onClick={nextBtnType === 'button' ? nextPageHandler : undefined}
            className={classes.nextBtn}
            disabled={!isValid || loading}
            type={nextBtnType}
         >
            {loading ? t?.submitting : nextBtnText ? nextBtnText : t?.next}
         </Button>

         {showBackBtn && (
            <Button variant='contained' className={classes.backBtn} onClick={prevPageHandler}>
               {t?.back}
            </Button>
         )}
      </Grid>
   );
};

export default NextBackBtn;

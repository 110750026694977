import { Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import Header from './Header';
import TabView from './TabView';

const useStyles = makeStyles((theme) => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      ...theme.mixins.toolbar,
      height: '100vh',
      backgroundColor: '#FFF',
      paddingTop: 60,
      width: '400px',
      borderLeft: '1px solid #F0F0F0',
   },
}));

const RightPanel = ({ activeChatId, activeChatType, open, onClose }) => {
   const classes = useStyles();
   const me = useSelector((reducer) => reducer.personReducer.person);
   const activeChat = useSelector((reducer) => reducer.chatReducer.activeChat);
   const participant = activeChat?.participants.find((item) => item._id !== me._id);

   return (
      <Slide in={open} direction='left'>
         <div className={classes.container}>
            <Header participant={participant} activeChatType={activeChatType} onClose={onClose} />
            <TabView activeChatId={activeChatId} />
         </div>
      </Slide>
   );
};

export default RightPanel;

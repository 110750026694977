import CustomSwitch from '@components/Switch';
import { FormControlLabel, Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { LanguageContext } from '@helper/locale/langContext';

interface triggerBlocksProps {
   isSecondMessage: boolean;
   setIsSecondMessage: React.Dispatch<React.SetStateAction<boolean>>;
}
const TriggerBlocks: React.FC<triggerBlocksProps> = ({ setIsSecondMessage, isSecondMessage }) => {
   const handleToggleBtnClicked = () => {
      setIsSecondMessage((prev: boolean) => {
         const res = !prev;
         return res;
      });
   };

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <div>
         <div
            style={{
               backgroundColor: '#F7F7FA',
               borderRadius: '5px',
               border: '1px solid #E7E7E7',
               padding: '8px 10px',
            }}
         >
            {t.automation_cart_abandonned}
         </div>
         <div>
            <Grid
               style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  marginBottom: '5px',
                  marginTop: '1.2rem',
               }}
            >
               <div style={{ display: 'flex', gap: '3px' }}>
                  <InfoIcon style={{ color: '#C3DDFF', marginTop: '-2px' }} />
                  <span style={{ fontSize: '14px' }}>{t.automation_send_second_msg}</span>
               </div>
               <div style={{ marginRight: '-15px' }}>
                  <FormControlLabel
                     control={<CustomSwitch checked={isSecondMessage} onChange={handleToggleBtnClicked} />}
                     label=''
                  />
               </div>
            </Grid>
         </div>
      </div>
   );
};

export default TriggerBlocks;

import { gql } from '@apollo/client';

export const CREATE_BULK_SHARING = gql`
   mutation createBulkSharing(
      $customerId: ID!
      $qpienPersonGroups: [String]!
      $whatsAppGroups: [String]!
      $telegramChannels: [String]!
      $whatsAppSendFolderNames: Boolean!
      $telegramSendFolderNames: Boolean!
      $telegramSendingType: String!
   ) {
      createBulkSharing(
         input: {
            customerId: $customerId
            qpienPersonGroups: $qpienPersonGroups
            whatsAppGroups: $whatsAppGroups
            telegramChannels: $telegramChannels
            whatsAppSendFolderNames: $whatsAppSendFolderNames
            telegramSendFolderNames: $telegramSendFolderNames
            telegramSendingType: $telegramSendingType
         }
      ) {
         code
         data {
            _id
            customer
            name
            whatsAppStatus
            telegramStatus
            whatsAppSendFolderNames
            telegramSendFolderNames
            telegramSendingType
            qpienPersonGroups
            whatsAppGroups
            telegramChannels
            folders {
               folderName
               folderLink
               whatsAppStatus
               telegramStatus
               length
            }
         }
         success
         message
      }
   }
`;

export const FINALIZE_BULK_SHARING = gql`
   mutation finalizeBulkSharing(
      $_id: ID!
   ) {
      finalizeBulkSharing(
         input: {
            _id: $_id
         }
      ) {
         code
         data {
            _id
            customer
            name
            whatsAppStatus
            telegramStatus
            whatsAppSendFolderNames
            telegramSendFolderNames
            telegramSendingType
            qpienPersonGroups
            whatsAppGroups
            telegramChannels
            folders {
               folderName
               folderLink
               whatsAppStatus
               telegramStatus
               length
            }
         }
         success
         message
      }
   }
`;
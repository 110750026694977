import React, { FC } from 'react';
import { ButtonBase, Popover, PopoverProps, PopoverOrigin } from '@material-ui/core';
import { Fragment, useRef } from 'react';

type VoidFn = () => void;

interface Props extends PopoverProps {
   button?: React.ReactNode;
   onOpen: VoidFn;
   onClose: VoidFn;
   buttonRipple?: boolean;
   openOnTop?: boolean;
   hasDisable?: boolean;
   isDisabled?: boolean;
   disableBackdropClick?: boolean;
   openRightAligned?: boolean;
   transfer?: PopoverOrigin;
   anchor?: PopoverOrigin;
   paperStyle?: React.CSSProperties;
}

const ButtonBasedPopup: FC<Props> = (props) => {
   const {
      disableBackdropClick,
      open,
      onOpen,
      onClose,
      openOnTop,
      openRightAligned,
      button,
      buttonRipple,
      style,
      transfer,
      isDisabled,
      anchorPosition,
      children,
      anchor,
      hasDisable,
      paperStyle,
   } = props;

   const buttonRef = useRef(null);

   const handleButtonClick = () => {
      if (!isDisabled) {
         if (open) {
            onClose();
         } else {
            onOpen();
         }
      }
   };

   return (
      <Fragment>
         <Popover
            disableBackdropClick={disableBackdropClick}
            anchorEl={buttonRef.current}
            open={open}
            disableAutoFocus
            onClose={onClose}
            PaperProps={{
               style: {
                  borderRadius: 10,
                  ...paperStyle,
               },
            }}
            anchorPosition={anchorPosition}
            anchorOrigin={
               anchor || {
                  vertical: openOnTop ? 'top' : 'bottom',
                  horizontal: openRightAligned ? 'center' : 'left',
               }
            }
            style={{
               marginTop: openOnTop ? -75 : 10,
               borderRadius: 15,
               ...style,
            }}
            transformOrigin={transfer}
         >
            {children}
         </Popover>
         <ButtonBase
            disableRipple={!buttonRipple}
            ref={buttonRef}
            onClick={handleButtonClick}
            style={{
               backgroundColor: isDisabled ? 'transparent' : hasDisable ? 'white' : undefined,
               cursor: isDisabled ? 'auto' : 'pointer',
            }}
         >
            {button}
         </ButtonBase>
      </Fragment>
   );
};

export default ButtonBasedPopup;

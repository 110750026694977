import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import { Grid, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useContext, useEffect } from 'react';
import { LanguageContext } from '../../helper/locale/langContext';
import { MyTheme } from '../../styles/config';
import ButtonCancel from '../ButtonCancel/buttonCancel';
import ButtonOK from '../ButtonOK/buttonOk';
import Modal from '../Modal/modal';

const useStyles = makeStyles((theme: MyTheme) => ({
   root: {
      '&$checked': {
         color: theme.palette.primary.main,
      },
      padding: '5px',
   },
   checked: {},

   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 436,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },
   modalBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '5px',
      textAlign: 'center',
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
   },
   checkItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
   },
   itemText: {
      marginTop: 5,
      textAlign: 'left',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

interface AreYouSureProps {
   isVisible: boolean;
   actionType?: 'disconnect' | 'delete';
   setShowModal: (param: boolean) => void;
   onComplate: any;
   selectedDeleteItemId: string;
   isEmailDelete?: boolean | undefined;
}

export enum CheckboxTypes {
   remove_channel_option_keep_everything,
   remove_channel_option_delete_all_conversations_and_messages,
}

const AreYouSure: FC<AreYouSureProps> = ({
   isVisible,
   setShowModal,
   onComplate,
   selectedDeleteItemId,
   isEmailDelete,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [deleteButtonEnabled, setDeleteButtonEnabled] = React.useState<Boolean>(false);
   const [showSubmenu, setShowSubmenu] = React.useState<Boolean>(false);
   const [checkBoxType, setCheckBoxType] = React.useState<Number>(0);
   const [checkedState, setCheckedState] = React.useState([false, false]);

   const chekBoxHandler = (selectedType: CheckboxTypes) => {
      if (selectedType === CheckboxTypes.remove_channel_option_keep_everything) {
         setCheckBoxType(CheckboxTypes.remove_channel_option_keep_everything);
         const checked = !checkedState[selectedType] == true;
         setCheckedState([checked, false]);
      } else if (
         selectedType === CheckboxTypes.remove_channel_option_delete_all_conversations_and_messages
      ) {
         setCheckBoxType(CheckboxTypes.remove_channel_option_delete_all_conversations_and_messages);
         const checked = !checkedState[selectedType] == true;
         setCheckedState([false, checked]);
      }
   };

   useEffect(() => {
      setDeleteButtonEnabled(checkedState.some((item) => item === true));
      setShowSubmenu(checkedState[1] === true);
   }, [checkedState]);

   const onSubmit = () => {
      setShowModal(false);
   };

   return (
      <Modal
         open={isVisible}
         onClose={() => {
            setShowModal(false);
         }}
         modalHeader={t?.delete_modal_remove_channel_title}
         paperStyle={{ width: 450 }}
      >
         <ModalLargeText style={{ textAlign: 'center', marginBlock: 10 }}>
            {t?.delete_modal_remove_channel_text} <br />
            {t?.delete_modal_remove_channel_text2}
         </ModalLargeText>

         {!isEmailDelete && (
            <Grid container alignItems='center' style={{ marginBottom: 5 }}>
               <Checkbox
                  checked={checkedState[CheckboxTypes.remove_channel_option_keep_everything]}
                  onChange={() =>
                     chekBoxHandler(CheckboxTypes.remove_channel_option_keep_everything)
                  }
                  classes={{ root: classes.root, checked: classes.checked }}
               />
               <Typography>{t?.delete_modal_remove_channel_option_keep_everything}</Typography>
            </Grid>
         )}

         <Grid container alignItems='flex-start' style={{ flexWrap: 'inherit', marginBottom: 16 }}>
            <Checkbox
               checked={
                  checkedState[
                     CheckboxTypes.remove_channel_option_delete_all_conversations_and_messages
                  ]
               }
               onChange={() =>
                  chekBoxHandler(
                     CheckboxTypes.remove_channel_option_delete_all_conversations_and_messages,
                  )
               }
               classes={{ root: classes.root, checked: classes.checked }}
            />
            <Typography style={{ marginTop: 3 }}>
               {t?.delete_modal_remove_channel_option_delete_all_conversations_and_messages}
            </Typography>
         </Grid>

         <ModalButtonField>
            <ButtonCancel
               label={t['comp_button_cancel']}
               onClick={() => {
                  setShowModal(false);
               }}
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
               }}
            />

            <ButtonOK
               label={t?.comp_button_delete}
               onClick={() => {
                  onComplate(selectedDeleteItemId, checkBoxType);
                  setShowModal(false);
               }}
               disabled={!deleteButtonEnabled}
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#ef5350',
               }}
            />
         </ModalButtonField>
      </Modal>
   );
};

export default AreYouSure;

import SingleInput from '@components/_Modal/SingleInput';
import { LanguageContext } from '@helper/locale/langContext';
import { Box, ClickAwayListener, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import React, { useContext, useRef, useState } from 'react';
import CancelIcon from '@material-ui/icons/Cancel';
import { SketchPicker } from 'react-color';

const useStyles = makeStyles(() => ({
   formContainer: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: '20px 30px',
      marginBottom: '5rem',
   },
   inputRow: {
      marginBottom: '5px',
   },
   imageBox: {
      width: 105,
      height: 105,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      borderRadius: '8px',
   },
   imgURL: {
      width: '100px',
      height: '100px',
      objectFit: 'contain',
      borderRadius: '8px',
   },
   addImageIcon: {
      position: 'absolute',
      color: '#157CFC',
      backgroundColor: '#FFFFFF',
      bottom: '-15px',
      right: '-15px',
      height: '35px',
      width: '35px',
      borderRadius: '35px',
      cursor: 'pointer',
   },
   cancelImageIcon: {
      position: 'absolute',
      color: '#FF4D4D',
      backgroundColor: '#FFFFFF',
      top: '-5px',
      right: '-15px',
      height: '35px',
      width: '35px',
      borderRadius: '35px',
      cursor: 'pointer',
   },
   color: {
      display: 'flex',
      width: '100%',
      borderRadius: '5px',
      height: 45,
   },
   colorHover: {
      display: 'flex',
      width: '100%',
      borderRadius: '5px',
      height: 45,
      '&:hover': {
         border: '1px solid black',
      },
   },
   input: {
      marginBottom: '5px',
      display: 'flex',
      width: '100%',
      borderRadius: '5px',
      paddingLeft: '0.5rem',
      height: 45,
      border: '1px solid #DBDBDB',
      '&:hover': {
         border: '1px solid black',
      },
      '&:focus': {
         outline: '1px solid #157CFC',
         border: 'none',
      },
   },
   inputError: {
      marginBottom: '5px',
      display: 'flex',
      width: '100%',
      borderRadius: '5px',
      paddingLeft: '0.5rem',
      height: 45,
      border: '1px solid #DBDBDB',
      '&:hover': {
         border: '1px solid black',
      },
      '&:focus': {
         outline: '1px solid red',
         border: 'none',
      },
   },
   inputColor: {
      marginRight: '5px',
      width: 'auto',
      margin: '5px 0',
      border: 'none',
      '&:focus': {
         outline: 'none',
      },
      '&:hover': {
         border: 'none',
      },
   },
   themeBoxColorPicker: {
      width: 38,
      borderRadius: '5px',
      height: 38,
      margin: '3px',
      marginRight: '5px',
      cursor: 'pointer',
   },

   textarea: {
      marginBottom: '5px',
      display: 'flex',
      width: '100%',
      borderRadius: '5px',
      paddingLeft: '0.5rem',
      paddingTop: '0.5rem',
      height: 100,
      resize: 'none',
      border: '1px solid #DBDBDB',
      '&:hover': {
         border: '1px solid black',
      },
      '&:focus': {
         outline: '1px solid #157CFC',
         border: 'none',
      },
   },
   textareaError: {
      marginBottom: '5px',
      display: 'flex',
      width: '100%',
      borderRadius: '5px',
      paddingLeft: '0.5rem',
      paddingTop: '0.5rem',
      height: 100,
      resize: 'none',
      border: '1px solid #DBDBDB',
      '&:hover': {
         border: '1px solid black',
      },
      '&:focus': {
         outline: '1px solid red',
         border: 'none',
      },
   },
   colorPickerContainer: {
      zIndex: 2,
   },
   error: {
      color: 'red',
      fontSize: '12px',
   },
}));
const Form = (props) => {
   const { state, setIsThanksMessageClicked, control, setValue, isClickSave } = props;
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const logoInputRef = useRef(null);
   const [colorPickerVisible, setColorPickerVisible] = useState(false);

   const handleThemeColorChange = (newColor) => {
      setValue('themeMainColor', newColor);
   };

   const handleImageUpload = () => {
      if (logoInputRef.current) {
         logoInputRef.current.click();
      }
   };

   const handleImageDelete = () => {
      setValue('logo', null);
   };

   const handleThanksMessageInputClick = () => {
      setIsThanksMessageClicked(true);
   };

   const handleClickAway = () => {
      setIsThanksMessageClicked(false);
   };

   const [borderColor, setBorderColor] = useState('1px solid #DBDBDB'); // Initial border color

   const handleBorderColor = () => {
      setBorderColor(
         borderColor === '1px solid #DBDBDB'
            ? '1px solid #157CFC'
            : '1px solid #157CFC'
            ? '1px solid #157CFC'
            : '1px solid #DBDBDB',
      );
   };
   const handleCloseBorder = () => {
      setBorderColor(
         borderColor === '1px solid #157CFC' ? '1px solid #DBDBDB' : '1px solid #DBDBDB',
      );
   };

   return (
      <div className={classes.formContainer}>
         <form>
            <Grid container>
               <Grid item md={12} className={classes.inputRow}>
                  <div>
                     <SingleInput
                        label={t?.page_settings_typo_survey_livechat_name}
                        isMulti
                        autoHeight
                        name='name'
                        control={control}
                        rules={{
                           required: t?.please_fill_this_field,
                        }}
                        editable
                        value={state.name}
                        height={40}
                        placeholder={t?.page_settings_typo_survey_livechat_name}
                     />
                  </div>
               </Grid>
               <Grid item md={12}>
                  <Typography
                     style={{ fontSize: '18px', color: !state.logo && isClickSave && 'red' }}
                  >
                     {t['page_settings_typo_survey_livechat_logo']}
                  </Typography>
               </Grid>
               <Grid item md={12}>
                  <Box
                     style={{
                        border: '1px solid #ccc',
                     }}
                     className={classes.imageBox}
                  >
                     {state.logo ? (
                        <img
                           src={
                              typeof state.logo === 'string'
                                 ? state.logo
                                 : URL.createObjectURL(state.logo)
                           }
                           alt='logo url'
                           className={classes.imgURL}
                        />
                     ) : (
                        <span>{t['page_settings_typo_survey_upload_logo']}</span>
                     )}
                     <input
                        hidden
                        ref={logoInputRef}
                        type='file'
                        accept='image/*'
                        onChange={() => {
                           setValue('logo', logoInputRef.current.files[0]);
                        }}
                     />
                     {state.logo && <CancelIcon onClick={handleImageDelete} className={classes.cancelImageIcon} />}
                     <AddCircleRoundedIcon
                        className={classes.addImageIcon}
                        onClick={handleImageUpload}
                     />
                  </Box>
               </Grid>
               <Grid item md={12} className={classes.inputRow} style={{ marginTop: '1rem' }}>
                  <Typography
                     style={{
                        fontSize: '18px',
                        color: state.themeMainColor.length < 1 && isClickSave && 'red',
                     }}
                  >
                     {t['page_settings_typo_survey_livechat_theme_color']}
                  </Typography>
                  <ClickAwayListener onClickAway={handleCloseBorder}>
                     <div
                        className={
                           borderColor === '1px solid #157CFC' ? classes.color : classes.colorHover
                        }
                     >
                        <div
                           style={{
                              border:
                                 state.themeMainColor.length < 1 && isClickSave
                                    ? '1px solid red'
                                    : borderColor,
                           }}
                           className={classes.color}
                           onClick={handleBorderColor}
                        >
                           <div
                              className={classes.themeBoxColorPicker}
                              style={{ backgroundColor: state.themeMainColor }}
                              onClick={() => setColorPickerVisible(!colorPickerVisible)}
                           >
                              {/* Display color square */}
                           </div>
                           <input
                              required
                              type='text'
                              name='themeColor'
                              value={state.themeMainColor}
                              onChange={(event) => handleThemeColorChange(event.target.value)}
                              className={classes.inputColor}
                           />
                           {colorPickerVisible && ( // Show the color picker when visible
                              <ClickAwayListener onClickAway={() => setColorPickerVisible(false)}>
                                 <div className={classes.colorPickerContainer}>
                                    <SketchPicker
                                       color={state.themeMainColor}
                                       onChange={(newColor) => handleThemeColorChange(newColor.hex)}
                                    />
                                 </div>
                              </ClickAwayListener>
                           )}
                        </div>
                     </div>
                  </ClickAwayListener>
               </Grid>
               <Grid item md={12} className={classes.inputRow}>
                  <div style={{ marginTop: '20px' }}>
                     <SingleInput
                        label={t?.page_settings_typo_survey_livechat_survey_title}
                        isMulti
                        autoHeight
                        name='title'
                        control={control}
                        rules={{
                           required: t?.please_fill_this_field,
                        }}
                        maxLength={200}
                        editable
                        value={state.title}
                        height={40}
                        onFocus={handleClickAway}
                        placeholder={t?.page_settings_typo_survey_livechat_name}
                        className={classes.input}
                     />
                  </div>
               </Grid>
               <Grid item md={12} className={classes.inputRow}>
                  <div>
                     <SingleInput
                        label={t?.page_settings_typo_survey_livechat_survey_message}
                        isMulti
                        autoHeight
                        name='message'
                        maxLength={200}
                        control={control}
                        rules={{
                           required: t?.please_fill_this_field,
                        }}
                        editable
                        value={state.message}
                        height={40}
                        onFocus={handleClickAway}
                        placeholder={t?.page_settings_typo_survey_livechat_survey_message}
                        className={classes.textarea}
                     />
                  </div>
               </Grid>
               <Grid item md={12} className={classes.inputRow}>
                  <div>
                     <SingleInput
                        label={t?.page_settings_typo_survey_livechat_survey_comment}
                        isMulti
                        autoHeight
                        name='commentInputTitle'
                        maxLength={24}
                        control={control}
                        editable
                        value={state.commentInputTitle}
                        height={40}
                        onFocus={handleClickAway}
                        placeholder={t?.page_settings_typo_survey_livechat_survey_comment}
                        className={classes.input}
                     />
                  </div>
               </Grid>
               <Grid item md={12} className={classes.inputRow}>
                  <div>
                     <SingleInput
                        label={t?.page_settings_typo_survey_livechat_survey_button_text}
                        isMulti
                        autoHeight
                        name='submitButtonText'
                        maxLength={24}
                        control={control}
                        rules={{
                           required: t?.please_fill_this_field,
                        }}
                        editable
                        value={state.submitButtonText}
                        height={40}
                        onFocus={handleClickAway}
                        placeholder={t?.page_settings_typo_survey_livechat_survey_button_text}
                        className={classes.input}
                     />
                  </div>
               </Grid>
               <Grid item md={12} className={classes.inputRow}>
                  <div>
                     <SingleInput
                        label={t?.page_settings_typo_survey_livechat_survey_thanks}
                        isMulti
                        autoHeight
                        name='thankYouMessage'
                        maxLength={200}
                        control={control}
                        rules={{
                           required: t?.please_fill_this_field,
                        }}
                        editable
                        value={state.thankYouMessage}
                        height={40}
                        onFocus={handleThanksMessageInputClick}
                        placeholder={t?.page_settings_typo_survey_livechat_survey_thanks}
                        className={classes.textarea}
                     />
                  </div>
               </Grid>
            </Grid>
         </form>
      </div>
   );
};

export default Form;

import ButtonOK from '@components/ButtonOK/buttonOk';
import InputCopy from '@components/InputCopy';
import Modal from '@components/Modal/modal';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import SingleInput from '@components/_Modal/SingleInput';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useContext } from 'react';
import { useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 700,
      padding: 20,
      borderRadius: 15,
      maxHeight: 700,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   iconSize: {
      fontSize: '5rem',
   },
   backButton: {
      color: '#748FA7',
      backgroundColor: '#fff',
      textTransform: 'none',
      width: 128,
      height: 40,
      fontSize: '1.1rem',
      border: '1px solid #748FA7',
   },
   title: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginTop: '5px',
      marginBottom: '15px',
      fontSize: '1.2rem',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const ModalContent = (props) => {
   const getInformationList = (t) => {
      return [
         t?.page_settings_modal_email_information_login,
         t?.page_settings_modal_email_information_access,
         t?.page_settings_modal_email_information_look,
         t?.page_settings_modal_email_information_add,
      ];
   };

   const { onClose, show, mode, teamsData, addEmailSubmit, setModalConfig, newEmail, emailData } =
      props;

   const classes = useStyles(props);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const informationList = getInformationList(t);

   const history = useHistory();
   const {
      handleSubmit,
      control,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         name: '',
         teams: [],
         email: '',
      },
   });

   // only for test
   const onSubmit = async (data) => {
      data = await {
         ...data,
         teams: data.teams.map((team) => team.value),
      }
      if (mode === 'NEW') {
         onClose();
         addEmailSubmit(data);
      }
   };

   const handleConnect = () => {
      history.push('/settings/platforms/email');
      setModalConfig({
         show: false,
      });
   };

   return (
      <Fragment>
         {mode === 'NEW' && (
            <Modal open={show} onClose={onClose} modalHeader={t['page_settings_modal_email']}>
               <Grid>
                  <Fragment>
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container xs={12}>
                           <SingleInput
                              label={t?.page_settings_typo_platforms_add_sender_name}
                              placeholder={
                                 t?.page_settings_typo_platforms_add_sender_name_placeholder
                              }
                              control={control}
                              name='name'
                              required={false}
                              rules={{
                                 required: t?.please_fill_this_field,
                              }}
                              helperText={
                                 t?.page_settings_typo_platforms_add_email_sender_description
                              }
                           />
                           <SingleInput
                              label={t?.page_settings_typo_platforms_add_email}
                              placeholder={t?.page_settings_typo_platforms_add_email_placeholder}
                              control={control}
                              name='email'
                              type='email'
                              required={false}
                              rules={{
                                 required: t?.please_fill_this_field,
                              }}
                              helperText={
                                 t?.page_settings_typo_platforms_add_email_team_description
                              }
                           />
                           <SingleInput
                              label={t?.teams}
                              isSelect
                              isMulti
                              autoHeight
                              name='teams'
                              control={control}
                              rules={{
                                 required: t?.please_fill_this_field,
                              }}
                              editable
                              height={40}
                              placeholder={t?.teams}
                              helperText={
                                 t?.page_settings_typo_platforms_add_email_sender_description
                              }
                              selectOptions={teamsData?.map((team) => ({
                                 label: team.teamName,
                                 value: team._id,
                              }))}
                           />
                           <ModalButtonField>
                              <Button
                                 onClick={onClose}
                                 className={classes.modalButtons}
                                 style={{
                                    color: '#212121',
                                    backgroundColor: '#eeee',
                                    '&:hover': {
                                       backgroundColor: '#e0e0e0',
                                    },
                                 }}
                              >
                                 {t?.cancel}
                              </Button>
                              <ButtonOK
                                 className={classes.modalButtons}
                                 label={t?.comp_button_connect}
                                 type='submit'
                                 disabled={!isValid}
                                 style={{
                                    color: 'white',
                                    backgroundColor: '#157CFC',
                                    '&:hover': {
                                       backgroundColor: '#105CBB',
                                    },
                                    float: 'right',
                                 }}
                              />
                           </ModalButtonField>
                        </Grid>
                     </form>
                  </Fragment>
               </Grid>
            </Modal>
         )}
         {mode === 'EMAILCOMPLETED' && emailData && (
            <Modal open={true} modalHeader={t['page_settings_modal_email']}>
               <Grid>
                  <Fragment>
                     <form onSubmit={handleSubmit(onSubmit)}>
                        <Grid container xs={12}>
                           <Grid container xs={12}>
                              <Grid item xs={12}>
                                 <div style={{ fontSize: '1.2rem' }}>
                                    {t?.page_settings_typo_platforms_forwarding_sender_email}
                                 </div>
                                 <div
                                    style={{
                                       display: 'grid',
                                       gridTemplateColumns: '1fr 1fr',
                                       border: 'solid #cccccc 1px',
                                       borderRadius: '5px',
                                       paddingLeft: '0.5rem',
                                       marginBottom: '1rem',
                                       backgroundColor: '#EDEDED',
                                    }}
                                 >
                                    <p>{newEmail?.forwardingEmailAddress}</p>
                                    <div
                                       style={{
                                          display: 'flex',
                                          justifyContent: 'flex-end',
                                          alignItems: 'center',
                                       }}
                                    >
                                       <InputCopy value={newEmail?.forwardingEmailAddress} />
                                    </div>
                                 </div>
                              </Grid>
                           </Grid>
                           <Typography>
                              <div style={{ padding: '0.5rem 0' }}>
                                 {[t?.page_settings_typo_platforms_add_email_follow_instruction]}
                              </div>
                           </Typography>

                           <Grid xs={12} item alignItems='flex-start'>
                              <div>
                                 {informationList.map((item, index) => (
                                    <ModalLargeText key={index}>
                                       <div style={{ display: 'flex', gap: '0.5rem' }}>
                                          {index + 1}.{' '}
                                          <Typography
                                             variant='body1'
                                             dangerouslySetInnerHTML={{ __html: item }}
                                          />
                                       </div>
                                    </ModalLargeText>
                                 ))}
                                 <Typography
                                    style={{ paddingBottom: '2rem' }}
                                    variant='body1'
                                    dangerouslySetInnerHTML={{
                                       __html: `
                                          <div>
                                          ${t?.page_settings_modal_email_information_note.replace(
                                             'Qpien inbox page',
                                             `<a href='https://t.me/BotFather' target='_blank' rel='noopener noreferrer'>Qpien inbox page</a>`,
                                          )}
                                          </div>
                                       `,
                                    }}
                                 />
                              </div>
                           </Grid>

                           <ModalButtonField>
                              <ButtonOK
                                 className={classes.modalButtons}
                                 label={t?.comp_button_connected}
                                 type='submit'
                                 disabled={!isValid}
                                 style={{
                                    color: 'white',
                                    backgroundColor: '#157CFC',
                                    '&:hover': {
                                       backgroundColor: '#105CBB',
                                    },
                                    float: 'right',
                                 }}
                                 onClick={handleConnect}
                              />
                           </ModalButtonField>
                        </Grid>
                     </form>
                  </Fragment>
               </Grid>
            </Modal>
         )}
      </Fragment>
   );
};

export default ModalContent;

import { QnAPlatformType } from '../../Questions/util/types';

export enum PLATFORM_TYPE {
   FACEBOOK = 'FACEBOOK',
   INSTAGRAM = 'INSTAGRAM',
   WHATS_APP = 'WHATS_APP',
   WHATS_APP_WEB = 'WHATS_APP_WEB',
   WHATS_APP_BUSINESS = 'WHATS_APP_BUSINESS',
   HEPSIBURADA = 'HEPSIBURADA',
   TRENDYOL = 'TRENDYOL',
   LIVE_CHAT = 'LIVE_CHAT',
   N11 = 'N11',
   EMAIL = 'EMAIL',
   TELEGRAM = 'TELEGRAM',
}

export enum USERS_TYPE {
   EMAIL = -11,
   MANUAL_CREATION = -10,
   N11 = -8,
   TELEGRAM = -9,
   LIVE_CHAT = -7,
   TRENDYOL = -6,
   HEPSIBURADA = -5,
   FACEBOOK = -4,
   INSTAGRAM = -3,
   WHATS_APP_WEB = -2,
   WHATS_APP_BUSINESS = -1,
   PERSONEL = 0,
   ADMIN = 1,
   OWNER = 2,
   BOT = 3,
}

export type MERGED_PLATFORMS_TYPES = PLATFORM_TYPE | QnAPlatformType;

import { useLazyQuery, useQuery } from '@apollo/client';
import { CircularProgress, MenuItem, Typography, TextField, Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ChatQuickAnswerArrowIcon } from '@assets/icons/ChatPageIcons';
import { LanguageContext } from '@helper/locale/langContext';
import { getPerson } from '@store/reducers/person';
import {
   GET_QUICK_ANSWERS_COUNT,
   GET_QUICK_ANSWERS_QUERY,
} from '@queries/Settings/Service/GraphQL/QuickAnswers/query';
import { GET_CUSTOMER_SATISFACTION_SURVEYS } from '@queries/Settings/Service/GraphQL/CustomerSatisfaction/query';
import { GET_TEMPLATES, GET_TEMPLATES_COUNT } from '@queries/Campaigns/Service/GraphQL/query';
import { getActiveChat } from '@store/reducers/chat';
import InfiniteScroll from 'react-infinite-scroll-component';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from '../../../../styles/config';
import LinkedFormattedText from '@components/LinkedFormattedText';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import SearchBar from '@components/SearchBar';

const ITEM_HEIGHT = 60;

const useStyles = makeStyles((theme) => ({
   root: {
      overflowY: 'auto',
      maxHeight: 600,
      minHeight: 200,
      backgroundColor: '#fff',
   },
   tab: {
      color: '#8E8E8E',
      borderRadius: 0,
      fontSize: '1.2rem',
      padding: '2px 8px',
      borderBottom: '1px solid transparent',
      '&:hover': {
         backgroundColor: 'transparent',
      },
   },
   selectedTab: {
      color: '#0e0e0e',
      borderBottom: '2px solid #018cf9',
   },
   quickAnswerContainer: {
      height: ITEM_HEIGHT * 5,
      [theme.breakpoints.down('xl')]: {
         height: ITEM_HEIGHT * 5,
      },
      [theme.breakpoints.down('l')]: {
         height: ITEM_HEIGHT * 4,
      },
      [theme.breakpoints.down('md')]: {
         height: ITEM_HEIGHT * 3,
      },
      [theme.breakpoints.down('xs')]: {
         height: ITEM_HEIGHT * 2,
      },
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      overflowY: 'auto',
      overflowX: 'hidden',
   },
   noItemContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
   },
   titleContainer: {
      padding: 10,
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 4,
      borderBottom: '2px solid #eee',
      // height: 70,
   },
   headerRight: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
   },
   answerSearchInputContainer: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      marginRight: 8,
      marginLeft: 8,
      maxWidth: 300,
      width: '100%',
   },
   answerSearchInput: {
      margin: '4px 0px !important',
   },
   addNewQuickAnswerButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 8,
      marginLeft: 8,
      borderRadius: 8,
      height: 40,
      width: 40,
      backgroundColor: '#157CFC',
   },
   addIcon: {
      color: '#FFF',
   },
   title: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.3rem',
      marginLeft: 10,
   },
   answerContainer: {
      maxHeight: 40,
   },
   selectedIcon: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginLeft: 20,
   },
   notSelectedIcon: {
      color: 'grey',
      marginLeft: 20,
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
   },
   text: {
      fontSize: '0.9rem',
      color: 'grey',
      wordBreak: 'break-all',
   },
   content: {
      fontSize: '0.9rem',
      wordBreak: 'break-all',
   },
   templateMessage: {
      alignSelf: 'flex-start',
      fontSize: '1rem',
      lineHeight: '3em',
      height: '3em',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100%',
   },
   arrowIcon: {
      width: 20,
      height: 20,
      fill: 'grey',
   },
   arrowIconBlue: {
      fill: theme.chatApp.general.pallet.passiveStructureAzure,
   },
   menuItem: {
      '&:hover': {
         backgroundColor: '#F7F7FA',
      },
      width: '100%',
      height: ITEM_HEIGHT,
      flexShrink: 0,
      borderBottom: '1px solid #eee',
   },
   selectedMenuItem: {
      backgroundColor: '#e0e0e1',
      '&:hover': {
         backgroundColor: '#c3c3c5',
      },
   },
   checkIconContainer: {
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 5,
   },
   checkIcon: {
      color: '#2B9B1E',
   },
}));

const getPersonValue = (person, value, defaultValue) => {
   switch (value) {
      case 'first_name':
         return person?.name || defaultValue || '-';
      case 'last_name':
         return person?.surname || defaultValue || '-';
      default:
         return person[value] || defaultValue || '-';
   }
};

const getContentWithFieldsAndPerson = (content, fields, person) => {
   let [temporaryMessage] = content.split('|');
   temporaryMessage = temporaryMessage?.trim();
   let message = temporaryMessage?.replaceAll('\n', ' ');
   if (fields && Array.isArray(fields)) {
      fields.forEach((field) => {
         if (Number.isInteger(field?.key)) {
            message = message?.replaceAll(
               `{{${field?.key}}}`,
               getPersonValue(person, field?.value, field?.exampleData),
            );
         }
      });
   } else {
      message = message?.replace(/{{\d}}/gm, '-');
   }
   return message;
};

const Page = {
   pageSize: 15,
   page: 1,
};

const QuickAnswers = ({
   platformStatus,
   onSend,
   whatsAppBusinessPhoneCreatedAt,
   selectedTab,
   setSelectedTab,
   selectedTemplate,
   setSelectedTemplate,
   selectedSurvey,
   setSelectedSurvey,
   filterAnswerList,
   setState,
}) => {
   const [hoveredIndex, setHoveredIndex] = useState(-1);
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const me = useSelector(getPerson);
   const activeChat = useSelector(getActiveChat);
   const chatPerson = activeChat?.participants[0];
   const customer = me?.customer?._id;
   const isXS = useMediaQuery(theme.breakpoints.down('xs'));
   const isMD = useMediaQuery(theme.breakpoints.down('md'));
   const isL = useMediaQuery(theme.breakpoints.down('l'));
   const isXL = useMediaQuery(theme.breakpoints.down('xl'));
   let height = ITEM_HEIGHT * 5;
   if (isXL) height = ITEM_HEIGHT * 5;
   if (isL) height = ITEM_HEIGHT * 4;
   if (isMD) height = ITEM_HEIGHT * 3;
   if (isXS) height = ITEM_HEIGHT * 2;
   const [answerList, setAnswerList] = useState([]);
   const [quickAnswerCount, setQuickAnswerCount] = useState(0);

   const isWhatsAppBusiness = !!whatsAppBusinessPhoneCreatedAt;
   const canISendMessage = !!activeChat.isJoinable && !!platformStatus;
   const customerId = me.customer._id;
   const [searchText, setSearchText] = useState(null);

   useEffect(() => {
      if (!isWhatsAppBusiness && canISendMessage) {
         setSelectedTab('QUICK_ANSWER');
      }
      if (isWhatsAppBusiness && !canISendMessage) {
         setSelectedTab('TEMPLATES');
      }
   }, [isWhatsAppBusiness, canISendMessage]);

   /* get quick answers */
   const [getQuickAnswer, { data: quickAnswersData }] = useLazyQuery(GET_QUICK_ANSWERS_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setQuickAnswerCount(data.quickAnswers.data.docsCount);
         if (searchText === null || searchText.length > 2 || searchText === '') {
            // setAnswerList([]);
            setAnswerList([...answerList, ...data.quickAnswers.data.docs]);

            return;
         }
         if (searchText !== null && searchText.length <= 2) {
            setAnswerList([...answerList, ...data.quickAnswers.data.docs]);
         }
      },
   });

   /* get satisfactionCustomer */
   // Infinite scrolling and searching can be added later
   const [getSatisfactionData, { data: satisfactionCustomerData }] = useLazyQuery(
      GET_CUSTOMER_SATISFACTION_SURVEYS,
      {
         fetchPolicy: 'no-cache',
      },
   );

   const [quickAnswerParam, setQuickAnswerParam] = useState({
      customer: customerId,
      pageSize: Page.pageSize,
      page: Page.page,
      search: null,
   });

   // Infinite scrolling and searching can be added later, for this reason created a new state. Number must be added to the backend mongodriver if it is to be added count
   const [satisfactionCustomerParam, setSatisfactionCustomerParam] = useState({
      customer: customerId,
      pageSize: Page.pageSize,
      pageNumber: Page.page,
      name: '',
      status: true,
   });

   useEffect(() => {
      if (searchText === null || searchText.length > 2 || searchText === '') {
         setAnswerList([]);
         if (searchText !== null) {
            setQuickAnswerParam((prev) => ({ ...prev, search: searchText, page: Page.page }));
         } else if (searchText === '') {
            setQuickAnswerParam((prev) => ({ ...prev, search: searchText, page: Page.page }));
         }
      }
   }, [searchText]);

   useEffect(() => {
      setSearchText(filterAnswerList);
   }, [filterAnswerList]);

   useEffect(() => {
      getQuickAnswer({
         variables: {
            ...quickAnswerParam,
         },
      });
   }, [quickAnswerParam]);

   useEffect(() => {
      getSatisfactionData({
         variables: {
            ...satisfactionCustomerParam,
         },
      });
   }, [satisfactionCustomerParam]);

   const handleNextAnswerPage = () => {
      if (answerList.length < quickAnswerCount) {
         setQuickAnswerParam({
            ...quickAnswerParam,
            page: quickAnswerParam.page + 1,
         });
      }
   };

   const [templateQuery, setTemplateQuery] = useState({
      startDate: whatsAppBusinessPhoneCreatedAt,
      pageSize: 10,
      pageNumber: 1,
      customer,
      qpienFields: true,
      eCommerceCart: false,
   });

   const [getInfiniteData, setInfiniteData] = useState({
      hasMore: true,
      totalLength: 0,
      data: [],
   });

   const [getTemplates] = useLazyQuery(GET_TEMPLATES, {
      fetchPolicy: 'cache-first',
      onCompleted: ({ getAllTemplatesPaginate }) => {
         let new_data = [...getInfiniteData?.data, getAllTemplatesPaginate.data].flat();
         setInfiniteData({
            ...getInfiniteData,
            data: [...getInfiniteData?.data, getAllTemplatesPaginate.data].flat(),
            hasMore: getInfiniteData?.totalLength > new_data.length,
         });
      },
   });

   const [getTemplatesCount] = useLazyQuery(GET_TEMPLATES_COUNT, {
      fetchPolicy: 'cache-first',
      onCompleted: ({ getAllTemplatesPaginateCount }) => {
         setInfiniteData({
            ...getInfiniteData,
            totalLength: getAllTemplatesPaginateCount.data,
            hasMore: getAllTemplatesPaginateCount.data > getInfiniteData?.data?.length,
         });
      },
   });

   useEffect(() => {
      getTemplates({
         variables: {
            ...templateQuery,
         },
      });
      if (getInfiniteData?.data?.length === 0) {
         getTemplatesCount({
            variables: {
               ...templateQuery,
            },
         });
      }
   }, [getTemplates, getTemplatesCount, templateQuery]);

   function handleNextPage() {
      if (getInfiniteData?.totalLength > getInfiniteData?.data?.length) {
         setTemplateQuery({
            ...templateQuery,
            pageNumber: templateQuery.pageNumber + 1,
         });
      }
   }

   const templates = getInfiniteData?.data?.reduce((results, item) => {
      if (item?.status === 'APPROVED') {
         const obj = {
            _id: item._id,
            templateId: item.templateId,
            content: getContentWithFieldsAndPerson(item?.message, item?.qpienFields, chatPerson),
         };
         results.push(obj);
      }
      return results;
   }, []);

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   }, []);

   return (
      <Grid container xs={12} className={classes.root}>
         <Grid
            item
            container
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            className={classes.titleContainer}
         >
            <Grid container alignItems='center' xs={0} sm={7}>
               {canISendMessage && (
                  <Button
                     color='primary'
                     disableRipple={true}
                     onClick={() => setSelectedTab('QUICK_ANSWER')}
                     className={clsx(classes.tab, selectedTab === 'QUICK_ANSWER' && classes.selectedTab)}
                  >
                     {t['page_chat_typo_quick_answers_']}
                  </Button>
               )}
               {!!isWhatsAppBusiness && (
                  <Button
                     color='primary'
                     disableRipple={true}
                     onClick={() => setSelectedTab('TEMPLATES')}
                     className={clsx(classes.tab, selectedTab === 'TEMPLATES' && classes.selectedTab)}
                  >
                     {t['page_chat_typo_quick_answers_templates']}
                  </Button>
               )}
               <Button
                  color='primary'
                  disableRipple={true}
                  onClick={() => setSelectedTab('SATISFACTION_SURVEYS')}
                  className={clsx(
                     classes.tab,
                     selectedTab === 'SATISFACTION_SURVEYS' && classes.selectedTab,
                  )}
               >
                  {t['page_chat_typo_quick_answers_satisfacion_surveys']}
               </Button>
            </Grid>
            {canISendMessage && selectedTab === 'QUICK_ANSWER' && (
               <Grid item xs={12} sm={5} className={classes.headerRight}>
                  <div className={classes.answerSearchInputContainer}>
                     <div>
                        <SearchBar
                           value={searchText}
                           onChange={setSearchText}
                           placeholder={t['search']}
                           style={{ width: 200, height: 40 }}
                        />
                     </div>
                  </div>
               </Grid>
            )}
         </Grid>
         {canISendMessage && selectedTab === 'SATISFACTION_SURVEYS' && (
            <Grid container className={classes.quickAnswerContainer}>
               {satisfactionCustomerData?.getSurveys?.data?.length === 0 && (
                  <Grid container className={classes.noItemContainer}>
                     <Typography
                        align='center'
                        style={{ padding: 20, width: '100%' }}
                        className={classes.text}
                     >
                        {satisfactionCustomerData?.getSurveys?.data?.length !== 0
                           ? ''
                           : t?.there_is_no_satisfaction_survey}
                     </Typography>
                  </Grid>
               )}
               {satisfactionCustomerData?.getSurveys?.data?.map((survey) => (
                  <MenuItem
                     key={survey._id}
                     style={{
                        backgroundColor: selectedSurvey?._id === survey._id && '#efefef',
                     }}
                     onClick={() => {
                        setSelectedSurvey(survey);
                        setState((prev) => ({ ...prev, text: '', contentHtml: '' }));
                     }}
                     className={classes.menuItem}
                  >
                     <Grid container direction='row'>
                        <Grid item xs={11} direction='column'>
                           <Typography
                              style={{
                                 textAlign: 'left',
                                 fontWeight: 'bold',
                              }}
                              noWrap
                           >
                              {survey.title}
                           </Typography>
                           <Grid item className={classes.answerContainer}>
                              <Typography noWrap className={classes.content}>
                                 {survey.message}
                              </Typography>
                           </Grid>
                        </Grid>
                        <Grid xs={1} container justifyContent='flex-end' alignItems='center'>
                           {selectedSurvey?._id === survey._id && (
                              <div className={classes.checkIconContainer}>
                                 <CheckCircleRoundedIcon className={classes.checkIcon} />
                              </div>
                           )}
                        </Grid>
                     </Grid>
                  </MenuItem>
               ))}
            </Grid>
         )}
         {canISendMessage && selectedTab === 'QUICK_ANSWER' && (
            <Grid container className={classes.quickAnswerContainer}>
               {answerList?.length === 0 && (
                  <Grid container className={classes.noItemContainer}>
                     <Typography align='center' style={{ padding: 20, width: '100%' }} className={classes.text}>
                        {quickAnswersData?.quickAnswers?.data?.docs.length !== 0 ? '' : t?.there_is_no_quick_answer}
                     </Typography>
                  </Grid>
               )}
               <InfiniteScroll
                  scrollableTarget='scrollableDiv'
                  dataLength={answerList.length || 0}
                  hasMore={answerList.length >= quickAnswerCount ? false : true}
                  loader={<Loader />}
                  next={handleNextAnswerPage}
                  height={height}
               >
                  {answerList?.map((quickAnswer, index) => (
                     <MenuItem key={quickAnswer._id} onClick={() => onSend(quickAnswer)} className={classes.menuItem}>
                        <Grid container direction='row'>
                           <Grid item xs={11} direction='column'>
                              <Typography
                                 style={{
                                    textAlign: 'left',
                                    fontWeight: 'bold',
                                 }}
                                 noWrap
                              >
                                 {quickAnswer.name}
                              </Typography>
                              <Grid item className={classes.answerContainer}>
                                 <Typography noWrap className={classes.content}>
                                    {quickAnswer.answer}
                                 </Typography>
                              </Grid>
                           </Grid>
                           <Grid xs={1} container justifyContent='flex-end' alignItems='center'>
                              <ChatQuickAnswerArrowIcon
                                 className={clsx(classes.arrowIcon, hoveredIndex === index && classes.arrowIconBlue)}
                              />
                           </Grid>
                        </Grid>
                     </MenuItem>
                  ))}
               </InfiniteScroll>
            </Grid>
         )}
         {!!isWhatsAppBusiness && selectedTab === 'TEMPLATES' && (
            <Grid container className={classes.quickAnswerContainer}>
               {templates?.length === 0 && (
                  <Grid container className={classes.noItemContainer}>
                     <Typography align='center' style={{ padding: 20, width: '100%' }} className={classes.text}>
                        {templates?.length !== 0
                           ? t?.page_chat_typo_quick_answer_templates_not_found
                           : t?.there_is_no_quick_answer_templates}
                     </Typography>
                  </Grid>
               )}
               <InfiniteScroll
                  scrollableTarget='scrollableDiv'
                  dataLength={templates?.length || 0}
                  hasMore={getInfiniteData?.hasMore}
                  loader={<Loader />}
                  endMessage={
                     getInfiniteData?.totalLength === 0 ? (
                        <Typography variant='body1' style={{ textAlign: 'center' }}>
                           {t?.campaign_no_template}
                        </Typography>
                     ) : null
                  }
                  next={handleNextPage}
                  height={height}
               >
                  {templates?.map((template) => (
                     <MenuItem
                        key={template?.templateId}
                        className={clsx(
                           classes.menuItem,
                           selectedTemplate === template?.templateId && classes.selectedMenuItem,
                        )}
                        onClick={() => setSelectedTemplate(template?.templateId)}
                     >
                        <Typography className={classes.templateMessage}>
                           {template?.content && (
                              // @ts-ignore
                              <LinkedFormattedText text={`${template?.content}`} />
                           )}
                        </Typography>
                        {selectedTemplate === template?.templateId && (
                           <div className={classes.checkIconContainer}>
                              <CheckCircleRoundedIcon className={classes.checkIcon} />
                           </div>
                        )}
                     </MenuItem>
                  ))}
               </InfiniteScroll>
            </Grid>
         )}
      </Grid>
   );
};

export default QuickAnswers;

import React, { useEffect, useContext, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Avatar, IconButton, Typography, Button } from '@material-ui/core';
import Input from '@components/InputField';
import AddIcon from '@material-ui/icons/AddCircle';
import {
   CHANGE_CUSTOMER_PHOTO_MUTATION,
   UPDATE_CUSTOMER_MUTATION,
} from '../../../../queries/Customer/Service/GraphQL/Customer/mutation';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import SearchSelect from 'react-select';
import clsx from 'classnames';
import { LanguageContext } from '@helper/locale/langContext';
import UserPlaceholder from '@assets/icons/ProfileIcons/user.svg';
import { Controller, useForm } from 'react-hook-form';
import { UPDATE_ME } from '@store/actions/person';
import { useLazyQuery } from '@apollo/client';
import { LOCATIONS } from '@queries/Locations/query';

const useStyles = makeStyles((theme) => ({
   selector: {
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 10,
      paddingLeft: 3,
      height: 32,
   },
   titleContainer: {
      color: '#385273',
      borderRadius: 15,
      padding: 15,
      backgroundColor: 'white',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: '1px solid #F7F7FA',
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   button: {
      paddingTop: 10,
      width: 100,
      height: 30,
      color: 'white',
      borderRadius: 10,
   },
   buttonApply: {
      backgroundColor: '#487DBF',
      '&:hover': {
         backgroundColor: '#487DBF',
      },
   },
   passwordSectionTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   textInput: {
      width: '100%',
      border: 'none',
      resize: 'none',
      borderRadius: 10,
      padding: 10,
      borderColor: 'lightgrey',
      fontSize: '1.075rem',
   },
   buttonContainer: {
      marginTop: 10,
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
         marginBottom: 90,
      },
      background: '#178CF9',
      paddingLeft: '3rem',
      paddingRight: '3rem',
      textTransform: 'capitalize',
   },
   snackBar: {
      color: '#000',
      fontSize: '13px',
      cursor: 'pointer',
      fontWeight: '500',
   },
   titleText: {
      marginLeft: 10,
      fontSize: '1.1rem',
      color: '#434343',
   },
   mainContainer: {
      marginTop: 10,
      padding: 10,
      [theme.breakpoints.down('sm')]: {
         padding: 0,
      },
   },
   container: {
      maxWidth: 1000,
      padding: '0px 2rem',
   },
   p5: {
      padding: 5,
   },
   avatarContainer: {
      // border: '1px solid #F7F7FA',
      padding: '5px',
      borderRadius: '50%',
   },
   avatar: {
      width: 100,
      height: 100,
      borderRadius: 50,
   },
   posRelative: {
      position: 'relative',
   },
   imgIconContainer: {
      position: 'absolute',
      top: -35,
      left: 64,
   },
   addIcon: {
      color: '#157CFC',
      fontSize: '1.75rem',
   },
   mt10: {
      marginTop: 10,
   },
   imgText: {
      color: '#8A9EB5',
      fontSize: '0.75rem',
      textAlign: 'center',
   },
   pt3rem: {
      paddingTop: '1.5rem',
   },
   pb0: {
      padding: 0,
   },
   p1rem0: {
      padding: '1rem 0px',
   },
   addressText: {
      fontSize: '.8rem',
      color: '#434343',
   },
   pt1rem: {
      paddingTop: '1rem',
   },
   form: {
      paddingTop: '5rem',
      paddingBottom: '4rem',
   },
   mb1rem: {
      marginBottom: '1rem',
   },
   sectionText: {
      fontSize: '.8rem',
      color: '#434343',
   },
   customText: {
      '& .MuiOutlinedInput-root': {
         height: 38,
      },
      '& .MuiFormControl-root': {
         marginTop: '0px !important',
      },
   },
}));

const Company = () => {
   const classes = useStyles();
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const customer = person.customer;
   let lang_code = customer?.language;
   const dispatch = useDispatch();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [isEdit, setIsEdit] = useState(false);

   const {
      handleSubmit,
      control,
      setValue,
      watch,
      formState: { isDirty, errors },
   } = useForm({
      mode: 'all',
      defaultValues: {
         companyName: customer.companyName,
         authorizedPersonIdentityNumber: customer.authorizedPersonIdentityNumber,
         email: customer.email,
         phone: customer?.phone,
         authorizedPersonName: customer.authorizedPersonName,
         authorizedPersonSurname: customer.authorizedPersonSurname,
         invoiceCompanyName: customer.invoiceCompanyName,
         invoicePostCode: customer.invoicePostCode,
         invoiceAddress: customer.invoiceAddress,
         taxBuilding: customer.taxBuilding,
         taxNumber: customer.taxNumber,
         invoiceCountry: {
            value: customer.invoiceCountry || '',
            label: customer.invoiceCountry || t?.you_must_first_select_a_country,
         },
         invoiceState: {
            value: customer.invoiceState || '',
            label: customer.invoiceState || t?.please_select_a_state,
         },
         invoiceCity: {
            value: customer.invoiceCity || '',
            label: customer.invoiceCity || t?.please_select_a_state,
         },
      },
   });

   //!

   const [getCountryInvoice, { data: countryDataInvoice, loading: countryLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'country',
         id: 0,
      },
   });
   const [getStateInvoice, { data: stateDataInvoice, loading: stateLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'state',
         id: watch('invoiceCountry.value'),
      },
   });
   const [getCityInvoice, { data: cityDataInvoice, loading: cityLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'city',
         id: watch('invoiceState.value'),
      },
   });

   useEffect(() => {
      if (countryDataInvoice) {
         if (countryDataInvoice.locations.data.length === 0) {
            setValue('invoiceCountry', {
               value: 0,
               label: '',
            });
         } else {
            let country = countryDataInvoice.locations.data.find((country) => country.name === customer.invoiceCountry);

            if (country) {
               setValue('invoiceCountry', {
                  value: country.id,
                  label: country.name,
               });
            } else {
               setValue('invoiceCountry', {
                  value: countryDataInvoice.locations.data[lang_code === 'TR' ? 227 : 235].id,
                  label: countryDataInvoice.locations.data[lang_code === 'TR' ? 227 : 235].name,
               });
            }
         }
      }
      getCountryInvoice();
   }, [countryDataInvoice]);

   useEffect(() => {
      if (stateDataInvoice) {
         if (stateDataInvoice.locations.data.length === 0) {
            setValue('invoiceState', {
               value: 0,
               label: '',
            });
         } else {
            let state = stateDataInvoice.locations.data.find((state) => state.name === customer.invoiceState);
            if (state) {
               setValue('invoiceState', {
                  value: state.id,
                  label: state.name,
               });
            } else {
               setValue('invoiceState', {
                  value:
                     watch('countryShipping.value') === 225
                        ? stateDataInvoice.locations.data[39].id
                        : stateDataInvoice.locations.data[0].id,
                  label:
                     watch('countryShipping.value') === 225
                        ? stateDataInvoice.locations.data[39].name
                        : stateDataInvoice.locations.data[0].name,
               });
            }
         }
      }
      getStateInvoice();
   }, [stateDataInvoice]);

   useEffect(() => {
      if (cityDataInvoice) {
         if (cityDataInvoice.locations.data.length === 0) {
            setValue('invoiceCity', {
               value: 0,
               label: '',
            });
         } else {
            let city = cityDataInvoice.locations.data.find((city) => city.name === customer.invoiceCity);
            if (city) {
               setValue('invoiceCity', {
                  value: city.id,
                  label: city.name,
               });
            } else {
               setValue('invoiceCity', {
                  value: cityDataInvoice.locations.data[0].id,
                  label: cityDataInvoice.locations.data[0].name,
               });
            }
         }
      }
      getCityInvoice();
   }, [cityDataInvoice]);

   //!

   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const handleClickUpload = () => {
      document.getElementById('selectProfileImagePerson').click();
   };

   const [changeCustomerPhoto, { loading: changePersonPhotoLoading }] = useMutation(CHANGE_CUSTOMER_PHOTO_MUTATION);
   const [updateCustomerMutation, { loading: updateCustomerLoading }] = useMutation(UPDATE_CUSTOMER_MUTATION);

   const handleUploadFile = async (files) => {
      if (!files || !files[0]) {
         return;
      }
      if (files[0].size > 1000000) {
         enqueueSnackbar(`${files[0].name} ${t?.page_support_typo_cant_upload_more_than_1_mb}`, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={classes.snackBar} onClick={() => closeSnackbar(key)}>
                  {' '}
                  Kapat
               </div>
            ),
         });
         return false;
      }
      changeCustomerPhoto({
         variables: {
            _id: person.customer._id,
            photo: files[0],
         },
      })
         .then(
            ({
               data: {
                  changeCustomerPhoto: { data: changeCustomerPhoto },
               },
            }) => {
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        customer: {
                           ...person.customer,
                           ...changeCustomerPhoto,
                        },
                     },
                  },
               });

               enqueueSnackbar(`{ ${changeCustomerPhoto.companyName} } fotoğrafı başarılı bir şekilde değiştirildi.`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={classes.snackBar} onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   // update data on submit
   const editCustomerSubmit = async (data) => {
      updateCustomerMutation({
         variables: {
            ...data,
            _id: customer._id,
            phone: data.phone,
            invoiceCountry: data.invoiceCountry.label,
            invoiceCity: data.invoiceCity.label,
            invoiceState: data.invoiceState.label,
            authorizedPersonName: data.authorizedPersonName,
            authorizedPersonSurname: data.authorizedPersonSurname,
         },
      })
         .then(
            ({
               data: {
                  updatePersonCustomer: { data: updatePersonCustomer },
               },
            }) => {
               // on success event
               // close modal
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        customer: {
                           ...person.customer,
                           ...updatePersonCustomer,
                        },
                     },
                  },
               });
               enqueueSnackbar(`{ ${updatePersonCustomer.companyName} } başarılı bir şekilde güncellendi`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={classes.snackBar} onClick={() => closeSnackbar(key)}>
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   const loadingOrFetching = changePersonPhotoLoading || updateCustomerLoading;

   if (loadingOrFetching) {
      return <div className='loading'></div>;
   }

   return (
      <Grid container>
         <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
            <Typography className={classes.titleText}>{t['company_info']}</Typography>
         </Grid>
         {/* </AppBar> */}
         <Grid className={classes.container}>
            <Grid item direction='row' xs={12} className={classes.mainContainer}>
               <Grid container direction='row'>
                  <Grid item className={classes.p5} xs={12} container>
                     <Grid direction='column' container>
                        <Grid container direction='row'>
                           <div className={classes.avatarContainer}>
                              <Avatar
                                 src={person.customer.photo ? person.customer.photo : UserPlaceholder}
                                 className={classes.avatar}
                              />
                              <div className={classes.posRelative}>
                                 <div className={classes.imgIconContainer}>
                                    <IconButton onClick={handleClickUpload}>
                                       <input
                                          id='selectProfileImagePerson'
                                          hidden
                                          onChange={(e) => handleUploadFile(e.target.files)}
                                          type='file'
                                          accept='image/*'
                                       />
                                       <AddIcon className={classes.addIcon} />
                                    </IconButton>
                                 </div>
                              </div>
                           </div>
                        </Grid>
                        <Grid container direction='row' className={classes.mt10}>
                           <Typography className={classes.imgText}>{t['page_account_typo_profile_image']}</Typography>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
               <form className={classes.pt3rem} onSubmit={handleSubmit((data) => editCustomerSubmit(data))}>
                  <Grid container spacing={3} className={clsx(classes.container, classes.pb0)}>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='companyName'
                           label={t?.brand_company_name}
                           control={control}
                           rules={{
                              required: t?.company_name_is_required,
                              minLength: {
                                 value: 2,
                                 message: t?.company_name_must_be_atleast_2_characters,
                              },
                              maxLength: {
                                 value: 40,
                                 message: t?.max_40_characters,
                              },
                           }}
                        />
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='email'
                           label={t?.company_email}
                           control={control}
                           rules={{
                              required: t?.email_field_is_required,
                              pattern: {
                                 value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                 message: t?.must_be_a_valid_email,
                              },
                           }}
                        />
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='authorizedPersonName'
                           label={t?.authorized_person_full_name}
                           control={control}
                           required={false}
                           rules={{
                              required: t?.authorized_full_name_field_is_required,
                           }}
                        />
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='authorizedPersonSurname'
                           label={t?.authorized_person_surname}
                           control={control}
                           required={false}
                           rules={{
                              required: t?.authorized_surname_field_is_required,
                           }}
                        />
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='authorizedPersonIdentityNumber'
                           label={t?.authorized_person_id_number}
                           control={control}
                           type='number'
                           rules={{
                              required: t?.authorized_id_number_field_is_required,
                              minLength: {
                                 value: 5,
                                 message: t?.authorized_person_identity_number_must_be_atleast_5_characters,
                              },
                              maxLength: {
                                 value: 20,
                                 message: t?.authorized_person_identity_number_must_be_maximum_20_characters,
                              },
                           }}
                        />
                     </Grid>

                     <Grid item md={6} xs={12}>
                        <Input
                           name='phone'
                           isPhone
                           splitCountryCode={true}
                           control={control}
                           label={t?.company_phone}
                           rules={{
                              required: t?.phone_is_required,
                           }}
                        />
                     </Grid>
                  </Grid>

                  <Grid item md={12} className={classes.mb1rem}>
                     <Typography className={classes.sectionText}>{t?.billing_information}</Typography>
                  </Grid>

                  <Grid
                     item
                     spacing={3}
                     container
                     style={{ padding: '12px 0px', height: '100%' }}
                     className={clsx(classes.container, classes.pb0)}
                  >
                     <Grid item xs={12}>
                        <Input
                           name='invoiceCompanyName'
                           label={t?.legal_company_name}
                           control={control}
                           rules={{
                              required: t?.legal_company_name_is_required,
                           }}
                        />
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='taxBuilding'
                           label={t?.tax_administration}
                           control={control}
                           rules={{
                              required: t?.tax_administration_is_required,
                           }}
                        />
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='taxNumber'
                           label={t?.taxNumber}
                           type='number'
                           control={control}
                           rules={{
                              required: t?.tax_number_field_is_required,
                           }}
                        />
                     </Grid>
                     <Grid item md={6} xs={12} className={classes.mb1rem}>
                        <InputLabel
                           shrink={false}
                           className={classes.pb8}
                           style={{
                              marginBottom: 8,
                              color: errors?.invoiceCountry && 'red',
                           }}
                        >
                           {t?.country}
                        </InputLabel>
                        <Controller
                           name='invoiceCountry'
                           control={control}
                           rules={{
                              required: t?.country_field_is_required,
                              validate: (value) => {
                                 if (value.value) {
                                    return true;
                                 }
                                 return false;
                              },
                           }}
                           render={({ field }) => (
                              <SearchSelect
                                 isDisabled={countryLoadingInvoice}
                                 options={countryDataInvoice?.locations?.data?.map(({ name, id }) => ({
                                    value: id,
                                    label: name,
                                 }))}
                                 value={watch('invoiceCountry')}
                                 onChange={(value) => {
                                    setValue('invoiceCountry', value);
                                 }}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item md={6} xs={12} className={classes.mb1rem}>
                        <InputLabel
                           shrink={false}
                           className={classes.pb8}
                           style={{
                              marginBottom: 8,
                              color: errors?.invoiceCity && 'red',
                           }}
                        >
                           {t?.city}
                        </InputLabel>
                        <Controller
                           control={control}
                           name='invoiceState'
                           rules={{
                              required: t?.this_field_is_required,
                              validate: (value) => {
                                 if (value.value) {
                                    return true;
                                 }
                                 return false;
                              },
                           }}
                           render={({ field }) => (
                              <SearchSelect
                                 isDisabled={stateLoadingInvoice}
                                 options={stateDataInvoice?.locations?.data?.map(({ name, id }) => ({
                                    value: id,
                                    label: name,
                                 }))}
                                 value={watch('invoiceState')}
                                 onChange={(value) => {
                                    setValue('invoiceState', value);
                                 }}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item md={6} xs={12} className={classes.mb1rem}>
                        <InputLabel
                           shrink={false}
                           className={classes.pb8}
                           style={{
                              marginBottom: 8,
                              color: errors?.invoiceCity && 'red',
                           }}
                        >
                           {t?.state}
                        </InputLabel>
                        <Controller
                           control={control}
                           name='invoiceCity'
                           rules={{
                              required: t?.this_field_is_required,
                              validate: (value) => {
                                 if (value.value || cityDataInvoice?.locations?.data?.length === 0) {
                                    return true;
                                 }
                                 return false;
                              },
                           }}
                           render={({ field }) => (
                              <SearchSelect
                                 isDisabled={cityLoadingInvoice || cityDataInvoice?.locations?.data?.length === 0}
                                 options={cityDataInvoice?.locations?.data?.map(({ name, id }) => ({
                                    value: id,
                                    label: name,
                                 }))}
                                 value={watch('invoiceCity')}
                                 onChange={(value) => {
                                    setValue('invoiceCity', value);
                                 }}
                              />
                           )}
                        />
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='invoicePostCode'
                           label={t?.zip_code}
                           control={control}
                           rules={{
                              required: t?.zip_code_field_is_required,
                           }}
                        />
                     </Grid>
                     <Grid item xs={12}>
                        <Input
                           name='invoiceAddress'
                           label={t?.address}
                           control={control}
                           rules={{
                              required: t?.address_field_is_required,
                           }}
                           multiline
                        />
                     </Grid>
                     {person.type >= 1 && (
                        <Grid item container xs={12} justifyContent='flex-end' className={classes.pt1rem}>
                           <Button
                              variant='contained'
                              color='primary'
                              className={classes.buttonContainer}
                              disabled={!isDirty || Object.keys(errors).length !== 0}
                              type='submit'
                           >
                              {t?.update}
                           </Button>
                        </Grid>
                     )}
                  </Grid>
               </form>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default Company;

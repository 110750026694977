import React, { Fragment, useContext, useCallback } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm } from 'react-hook-form';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';

interface ModalContentProps {
   onSubmit: (newName: string) => void;
   value?: { title: string | undefined };
   closeModal: () => void;
   isCreate?: Boolean;
   isLoading?: Boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   container: {
      width: '100%',
   },
}));

const CreateModal: React.FC<ModalContentProps> = ({ value, onSubmit, closeModal, isCreate, isLoading }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' justifyContent='center' xs={12}>
            <CircularProgress size={20} color='inherit' />
         </Grid>
      );
   }, []);

   const {
      handleSubmit,
      control,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         name: value && value.title ? value.title : '',
      },
   });

   const handleFormSubmit = (data: { name: string }) => {
      onSubmit(data.name);
   };

   return (
      <Grid container>
         <Fragment>
            <div className={classes.container}>
               <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <Grid container>
                     <SingleInput
                        label={t['automation_flow_name']}
                        name='name'
                        placeholder={t['automation_flow_enter_name']}
                        control={control}
                        rules={{
                           required: t['automation_flow_name_required'],
                           maxLength: { value: 70, message: t?.automation_flow_flow_bot_title_max_length },
                        }}
                     />

                     <ModalButtonField>
                        <Button
                           className={classes.modalButtons}
                           onClick={closeModal}
                           style={{
                              color: '#212121',
                              backgroundColor: '#eeee',
                           }}
                        >
                           {t?.cancel}
                        </Button>
                        <Button
                           className={classes.modalButtons}
                           type='submit'
                           disabled={!isValid || isLoading === true}
                           style={{
                              color: 'white',
                              backgroundColor: '#157CFC',
                              float: 'right',
                           }}
                        >
                           <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                              {isLoading && <Loader />}
                              <span style={{ whiteSpace: 'nowrap' }}>
                                 {isCreate ? t.create_team_button : t.automation_flow_rename}
                              </span>
                           </div>
                        </Button>
                     </ModalButtonField>
                  </Grid>
               </form>
            </div>
         </Fragment>
      </Grid>
   );
};

export default CreateModal;

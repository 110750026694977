import { Button, Grid, Typography } from '@material-ui/core';
import React, { useContext, useMemo } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PhoneIcon from '@material-ui/icons/Phone';
import { LanguageContext } from '@helper/locale/langContext';
import { IPreviewPanel } from './types';

const useStyles = makeStyles((theme: any) => ({
   panel: {
      border: '1px solid #f7f7f7',
      height: '100%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      // minWidth: 400 ,
      position: 'sticky',
   },
   previewTop: {
      padding: 8,
      borderBottom: '2px solid #f7f7f7',
      color: '#424242',
   },
   phoneSection: {
      paddingTop: 20,
   },
   topPaper: {
      width: '100%',
      //height: 150,
      maxHeight: 450,
      minHeight: 150,
      whiteSpace: "pre-wrap",
      wordBreak: "break-word",
      wordWrap: "break-word",
      overflow: "overlay",
      // height: 'auto',
      backgroundColor: 'white',
      padding: 10,
      borderRadius: 8,
      boxShadow: '1px 1px 2px lightgray',
      marginTop: 10,
      maxWidth: 330,
      marginLeft: 'auto',
      fontSize: ".9rem",
      marginRight: 'auto',
      '&::webkit-scrollbar': {
         width: '0.4em',
         height: '0.4em',
      },
      '&::webkit-scrollbar-track': {
         height: '0.4em',
      },
   },
   buttons: {
      backgroundColor: 'white',
      marginTop: 10,
      border: 'none',
      boxShadow: '1px 1px 2px lightgray',
      fontSize: 18,
      paddingBlock: 10,
   },
   '@global': {
      '::-webkit-scrollbar': {
         width: 6,
         transition: 'all .5s',
      },
      '::-webkit-scrollbar-track': {
         background: theme.chatApp.general.pallet.passiveStructureGrey,
      },
      '::-webkit-scrollbar-thumb': {
         background: '#C9CCD5',
      },
      '::-webkit-scrollbar-thumb:hover': {
         background: '#9399ab',
      },
   },
}));
const PreviewPanel = ({ callUs, webUrl, visibilityOfInputs, watch }: IPreviewPanel) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();

   let new_text = watch("message");

   let bold_text_regex = /\*([^\*]+)\*/g;
   let bold_text = new_text.matchAll(bold_text_regex);
   for (let bold of bold_text) {
      new_text = new_text.replace(bold[0], `<span><b>${bold[1]}</b></span>`);
   }

   let italic_text_regex = /\_{1}([^\_]+)\_{1}/g;
   let italic_text = new_text.matchAll(italic_text_regex);
   for (let italic of italic_text) {
      new_text = new_text.replace(italic[0], `<span><i>${italic[1]}</i></span>`);
   }

   let underline_text_regex = /\~{1}([^\~]+)\~{1}/g;
   let underline_text = new_text.matchAll(underline_text_regex);
   for (let underline of underline_text) {
      new_text = new_text.replace(underline[0], `<span><s>${underline[1]}</s></span>`);
   }

   let single_line_text_regex = /\`\`\`([^\`\`\`]+)\`\`\`/g;
   let single_line_text = new_text.matchAll(single_line_text_regex);
   for (let single_line of single_line_text) {
      new_text = new_text.replace(single_line[0], `<span><code>${single_line[1]}</code></span>`);
   }

   let regex = new RegExp(/\{\{(\d+)\}\}/g, 'g');
   let matched: any = new_text?.matchAll(regex);
   for (let match of matched) {
      new_text = new_text.replace(match[0], `<span style="color: #018cf9;" >${watch("qpienFields").find((x: any) => x.key === Number(match[1]))?.exampleData || match[1]}</span>`);
   }

   let templateType = watch("templateType");
   let sampleMedia = watch("sampleMedia");

   //Create video image from video with useMemo
   const memoizedValue = useMemo(() => {
      if (sampleMedia?.file) {
         if ((templateType.value || templateType) === "IMAGE") {
            return <img alt="example_preview" src={typeof sampleMedia.file == "string" ? sampleMedia.file : URL.createObjectURL(
               sampleMedia.file
            )} style={{ width: '100%', height: 'auto', marginBottom: 8 }} />
         } else if ((templateType.value || templateType) === "VIDEO") {
            return <video
               src={typeof sampleMedia.file == "string" ? sampleMedia.file : URL.createObjectURL(
                  sampleMedia.file
               )}
               style={{ width: '100%', height: 'auto', marginBottom: 8 }}
               autoPlay={true}
               controls
               muted
            />;
         } else {
            return null;
         }
      } else {
         return null;
      }
   }, [templateType, sampleMedia]);

   return (
      <Grid className={classes.panel}>
         <Grid container alignItems='center' className={classes.previewTop}>
            <VisibilityIcon color='inherit' fontSize='large' style={{ marginRight: 10 }} />
            <Typography variant='h6' color='inherit'>
               {t['settings_whatsapp_business_template_preview']}
            </Typography>
         </Grid>
         <Grid container direction='row' style={{ paddingInline: '10%' }} className={classes.phoneSection}>


            <div className={classes.topPaper}>
               {
                  memoizedValue
               }
               <div dangerouslySetInnerHTML={{ __html: new_text || t["campaign_input_placeholder"] }} />
            </div>
            <Grid container style={{
               maxWidth: 330,
               marginLeft: "auto",
               marginRight: "auto"
            }}>
               {watch('input0').length > 0 && visibilityOfInputs[0] && (
                  <Button className={classes.buttons} fullWidth variant='outlined' color='inherit'>
                     <Typography noWrap color='primary'>
                        {watch('input0')}
                     </Typography>
                  </Button>
               )}
               {watch('input1').length > 0 && visibilityOfInputs[1] && (
                  <Button className={classes.buttons} fullWidth variant='outlined' color='inherit'>
                     <Typography noWrap color='primary'>
                        {watch('input1')}
                     </Typography>
                  </Button>
               )}
               {watch('input2').length > 0 && visibilityOfInputs[2] && (
                  <Button className={classes.buttons} fullWidth variant='outlined' color='inherit'>
                     <Typography noWrap color='primary'>
                        {watch('input2')}
                     </Typography>
                  </Button>
               )}
               {webUrl ? (
                  <Button
                     className={classes.buttons}
                     fullWidth
                     variant='outlined'
                     color='primary'
                     startIcon={<OpenInNewIcon />}
                  >
                     <Typography noWrap>{watch('buttonTextweb')}</Typography>
                  </Button>
               ) : null}
               {callUs ? (
                  <Button
                     className={classes.buttons}
                     fullWidth
                     variant='outlined'
                     color='primary'
                     startIcon={<PhoneIcon />}
                  >
                     <Typography noWrap>{watch('buttonTextphoneName')}</Typography>
                  </Button>
               ) : null}
            </Grid>
         </Grid>
      </Grid >
   );
};

export default PreviewPanel;

import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../../../fragments';

// subsriptions for the persons module
export const UPDATED_ME_SUBSCRIPTION = gql`
   ${CUSTOMER_FRAGMENT}
   subscription updateMeSubscription($token: String!, $_id: ID, $customer: ID!) {
      updatedMe(data: { token: $token, _id: $_id, customer: $customer }) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            type
            setup
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const AUTO_LOGOUT_PERSON = gql`
   subscription autoLogoutPersonSubscription($token: String!, $_id: String!) {
      autoLogoutPerson(data: { token: $token, _id: $_id }) {
         data {
            _id
            name
            surname
         }
         success
         code
         message
      }
   }
`;

export const CREATED_PERSON_SUBSCRIPTION = gql`
   ${CUSTOMER_FRAGMENT}
   subscription createdPersonSubscription($token: String!, $customer: ID!) {
      createdPerson(data: { token: $token, customer: $customer }) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            type
            setup
            status
            signedTeams
            createdAt
            country
            state
            address
            postCode
            city
            phoneUsagePermission
            emailUsagePermission
            tags
            language
         }
         success
         code
         message
      }
   }
`;

export const IMPORTED_PERSON_SUBSCRIPTION = gql`
   subscription importedPersons($token: String!, $customer: ID!) {
      importedPersons(data: { token: $token, customer: $customer }) {
         data {
            percent
         }
         success
         code
         message
      }
   }
`;

export const CREATED_CUSTOMER_PERSON_SUBSCRIPTION = gql`
   ${CUSTOMER_FRAGMENT}
   subscription createdCustomerPersonSubscription($token: String!, $customer: ID!) {
      createdCustomerPerson(data: { token: $token, customer: $customer }) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            chatParticipateLimit
            participatingChatsCount
            type
            setup
            status
            createdAt
            signedTeams
            accountStatus
            isEmailVerified
         }
         success
         code
         message
      }
   }
`;

export const UPDATED_CUSTOMER_PERSON_SUBSCRIPTION = gql`
   ${CUSTOMER_FRAGMENT}
   subscription updatedCustomerPersonSubscription($token: String!, $customer: ID!) {
      updatedCustomerPerson(data: { token: $token, customer: $customer }) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            chatParticipateLimit
            participatingChatsCount
            type
            setup
            status
            signedTeams
            createdAt
            language
            tags
            country
            state
         }
         success
         code
         message
      }
   }
`;

export const DELETED_CUSTOMER_PERSON_SUBSCRIPTION = gql`
   ${CUSTOMER_FRAGMENT}
   subscription deletedCustomerPersonSubscription($token: String!, $customer: ID!) {
      deletedCustomerPerson(data: { token: $token, customer: $customer }) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            type
            setup
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;

import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, MenuItem, Select, Button, useMediaQuery } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux';
import { playSound } from '@helper/functions';
import { useMutation } from '@apollo/client';
import { UPDATE_CUSTOMER_MUTATION } from '../../../../queries/Customer/Service/GraphQL/Customer/mutation';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { LanguageContext } from '@helper/locale/langContext';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import Modal from '@components/Modal/modal';
import ModalContent from './modalContent';

const LANGUAGES = [
   {
      label: 'Türkçe',
      key: 'TR',
   },
   {
      label: 'İngilizce',
      key: 'EN',
   },
];

const CURRENCIES = [
   {
      label: '$ ABD',
      local: 'page_account_preferences_currency_dolar',
      key: 'USD',
   },
   {
      label: '€ Euro',
      local: 'page_account_preferences_currency_euro',
      key: 'EUR',
   },
   {
      label: '₺ Türk Lirası',
      local: 'page_account_preferences_currency_try',
      key: 'TRY',
   },
];

const AUDIO_FILES = [
   {
      name: 'Ses 1',
      local: 'page_account_preferences_notification_sound_1',
      value: 'sound1.mp3',
   },
   {
      name: 'Ses 2',
      local: 'page_account_preferences_notification_sound_2',
      value: 'sound2.mp3',
   },
   {
      name: 'Ses 3',
      local: 'page_account_preferences_notification_sound_3',
      value: 'sound3.mp3',
   },
   {
      name: 'Ses 4',
      local: 'page_account_preferences_notification_sound_4',
      value: 'sound4.mp3',
   },
   {
      name: 'Ses 5',
      local: 'page_account_preferences_notification_sound_5',
      value: 'sound5.mp3',
   },
   {
      name: 'Ses 6',
      local: 'page_account_preferences_notification_sound_6',
      value: 'sound6.mp3',
   },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
   anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
   },
   getContentAnchorEl: null,
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 64,
      },
   },
};

const useStyles = makeStyles((theme) => ({
   titleContainer: {
      color: '#385273',
      borderRadius: 15,
      padding: 15,
      backgroundColor: 'white',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: '1px solid #F7F7FA',
      position: 'sticky',
      zIndex: 5,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   menuItem: {
      margin: 0,
      marginTop: 5,
      marginBottom: 5,
      padding: 0,
   },
   topHeaderTitle: {
      color: '#385273',
   },
   topHeaderContainer: {
      marginTop: 30,
      marginBottom: 10,
      padding: 10,
      paddingLeft: 25,
   },
   backgroundColorContainer: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
   },
   selectLabel: {
      color: '#385273',
      fontSize: '1.1rem',
   },
   selectBox: {
      border: '1px solid #E0E0E0',
      borderRadius: 10,
      paddingLeft: 10,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      backgroundColor: 'white',
      minWidth: 128,
      maxWidth: 261,
   },
   tableCell: {
      height: 30,
   },
   bottomContainer: {
      padding: 10,
      marginBottom: 60,
   },
   endUpdateButton: {
      paddingRight: '1rem',
      marginTop: theme.spacing(3),
      [theme.breakpoints.down('sm')]: {
         paddingLeft: '1rem',
         marginBottom: 40,
         marginTop: 10,
      },
   },
}));

const StyledTableCell = withStyles((theme) => ({
   head: {
      backgroundColor: '#F7F7FA',
      color: '#385273',
      fontSize: '1.1rem',
   },
   body: {
      color: '#385273',
      background: 'white',
      fontSize: '1.1rem',
   },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
   root: {
      '&:nth-of-type(odd)': {
         backgroundColor: 'white',
      },
      paddingRight: '2rem',
   },
}))(TableRow);

const BlueSwitch = withStyles((theme) => ({
   root: {
      width: 48,
      height: 24,
      padding: 0,
      display: 'flex',
   },
   switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
         transform: 'translateX(24px)',
         color: theme.palette.common.white,
         '& + $track': {
            opacity: 1,
            backgroundColor: '#157CFC',
            borderColor: '#157CFC',
         },
      },
   },
   thumb: {
      width: 20,
      height: 20,
      boxShadow: 'none',
   },
   track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 20,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
   },
   checked: {},
}))(Switch);

const PreferencesAndNotifications = () => {
   const [modalConfig, setModalConfig] = useState(false);
   const classes = useStyles();
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const [updateCustomerMutation] = useMutation(UPDATE_CUSTOMER_MUTATION);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   // const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES[0]);
   // const [selectedCurrency, setSelectedCurrency] = useState(CURRENCIES[0]);
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const [newMessageSystem, setNewMessageSystem] = useLocalStorage('newMessageSystem', {
      sound: 'sound4.mp3',
      active: true,
   });

   const [state, setState] = useState({
      _id: person.customer._id,
      allowConnect: person.customer.allowConnect ? person.customer.allowConnect : true,
      newChatEmail: person.customer.newChatEmail,
      newChatSystem: person.customer.newChatSystem,
      newChatBell: person.customer.newChatBell,
      newChatBellName: person.customer.newChatBellName,
      transferredChatEmail: person.customer.transferredChatEmail,
      transferredChatSystem: person.customer.transferredChatSystem,
      transferredChatBell: person.customer.transferredChatBell,
      transferredChatBellName: person.customer.transferredChatBellName,
      timeoutChatEmail: person.customer.timeoutChatEmail,
      timeoutChatSystem: person.customer.timeoutChatSystem,
      timeoutChatBell: person.customer.timeoutChatBell,
      timeoutChatBellName: person.customer.timeoutChatBellName,

      newQnAChatEmail: person.customer?.newQnAChatEmail,
      newQnAChatSystem: person.customer?.newQnAChatSystem,
      newQnAChatBell: person.customer?.newQnAChatBell,
      newQnAChatBellName: person.customer?.newQnAChatBellName,
      transferredQnAChatEmail: person.customer?.transferredQnAChatEmail,
      transferredQnAChatSystem: person.customer?.transferredQnAChatSystem,
      transferredQnAChatBell: person.customer?.transferredQnAChatBell,
      transferredQnAChatBellName: person.customer?.transferredQnAChatBellName,
      timeoutQnAChatEmail: person.customer?.timeoutQnAChatEmail,
      timeoutQnAChatSystem: person.customer?.timeoutQnAChatSystem,
      timeoutQnAChatBell: person.customer?.timeoutQnAChatBell,
      timeoutQnAChatBellName: person.customer?.timeoutQnAChatBellName,

      // tiemzone
      timeZone: person.customer.timeZone || 'Europe/Istanbul',
      language: person.customer.language || 'TR',
      currency: person.customer.currency || 'TRY',
   });

   const [newQnAMessageSystem, setNewQnAMessageSystem] = useLocalStorage('newQnAMessageSystem', {
      sound: 'sound4.mp3',
      active: true,
   });

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const history = useHistory();

   const ALLOW_CONNECT = [
      { label: t?.page_account_preferences_admin_connect_allow, key: true },
      { label: t?.page_account_preferences_admin_connect_dontallow, key: false },
   ];

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, history, enqueueSnackbar, closeSnackbar]);

   const handleSwitchChange = (e) => {
      const { name, checked } = e.target;
      setState((prevState) => ({ ...prevState, [name]: checked }));
   };

   useEffect(() => {
      setState({
         _id: person.customer._id,
         newChatEmail: person.customer.newChatEmail,
         newChatSystem: person.customer.newChatSystem,
         newChatBell: person.customer.newChatBell,
         newChatBellName: person.customer.newChatBellName,
         transferredChatEmail: person.customer.transferredChatEmail,
         transferredChatSystem: person.customer.transferredChatSystem,
         transferredChatBell: person.customer.transferredChatBell,
         transferredChatBellName: person.customer.transferredChatBellName,
         timeoutChatEmail: person.customer.timeoutChatEmail,
         timeoutChatSystem: person.customer.timeoutChatSystem,
         timeoutChatBell: person.customer.timeoutChatBell,
         timeoutChatBellName: person.customer.timeoutChatBellName,
         allowConnect: person.customer.allowConnect ? person.customer.allowConnect : true,
         // Comments
         newQnAChatEmail: person.customer?.newQnAChatEmail,
         newQnAChatSystem: person.customer?.newQnAChatSystem,
         newQnAChatBell: person.customer?.newQnAChatBell,
         newQnAChatBellName: person.customer?.newQnAChatBellName,
         transferredQnAChatEmail: person.customer?.transferredQnAChatEmail,
         transferredQnAChatSystem: person.customer?.transferredQnAChatSystem,
         transferredQnAChatBell: person.customer?.transferredQnAChatBell,
         transferredQnAChatBellName: person.customer?.transferredQnAChatBellName,
         timeoutQnAChatEmail: person.customer?.timeoutQnAChatEmail,
         timeoutQnAChatSystem: person.customer?.timeoutQnAChatSystem,
         timeoutQnAChatBell: person.customer?.timeoutQnAChatBell,
         timeoutQnAChatBellName: person.customer?.timeoutQnAChatBellName,
         // time zone etc
         timeZone: person.customer.timeZone || 'Europe/Istanbul',
         language: person.customer.language || 'TR',
         currency: person.customer.currency || 'TRY',
      });
   }, [
      person.customer.allowConnect,
      person.customer._id,
      person.customer.currency,
      person.customer.language,
      person.customer?.newChatBell,
      person.customer?.newChatBellName,
      person.customer?.newChatEmail,
      person.customer?.newChatSystem,
      person.customer?.newQnAChatBell,
      person.customer?.newQnAChatBellName,
      person.customer?.newQnAChatEmail,
      person.customer?.newQnAChatSystem,
      person.customer?.timeZone,
      person.customer?.timeoutChatBell,
      person.customer?.timeoutChatBellName,
      person.customer?.timeoutChatEmail,
      person.customer?.timeoutChatSystem,
      person.customer?.timeoutQnAChatBell,
      person.customer?.timeoutQnAChatBellName,
      person.customer?.timeoutQnAChatEmail,
      person.customer?.timeoutQnAChatSystem,
      person.customer?.transferredChatBell,
      person.customer?.transferredChatBellName,
      person.customer?.transferredChatEmail,
      person.customer?.transferredChatSystem,
      person.customer?.transferredQnAChatBell,
      person.customer?.transferredQnAChatBellName,
      person.customer?.transferredQnAChatEmail,
      person.customer?.transferredQnAChatSystem,
   ]);

   const handleAudioChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => ({ ...prevState, [name]: value }));
      playSound(value);
   };

   const handleUpdate = () => {
      setModalConfig(true);
   };

   const handleCloseModal = () => {
      setModalConfig(false);
   };

   const editCustomerSubmit = async () => {
      // run update mutation
      updateCustomerMutation({
         variables: {
            ...state,
         },
      })
         .then(
            ({
               data: {
                  updatePersonCustomer: { data: updatePersonCustomer },
               },
            }) => {
               // on success event
               // close modal
               handleCloseModal();
               enqueueSnackbar(`${updatePersonCustomer.companyName} ${t?.general_information_updated_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
      //    }
      // });
   };

   return (
      <div style={{ marginBottom: 20 }}>
         {/* <AppBar position="sticky"> */}
         <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
            <Typography style={{ marginLeft: 10, fontSize: '1.1rem', color: '#434343' }}>
               {t['comp_sidebar_preferences']}
            </Typography>
         </Grid>
         {/* </AppBar> */}
         <Grid item xs={12} container direction='column'>
            <Grid
               item
               xs={12}
               container
               alignItems='center'
               justifyContent='space-between'
               className={classes.titleContainer}
            >
               <Grid item xs={7} sm={8} container justifyContent='flex-start'>
                  <Typography className={classes.selectLabel}>
                     {t['page_account_preferences_language_title']}
                  </Typography>
               </Grid>
               <Grid item xs={5} sm={4} className={classes.selectBox}>
                  <Select
                     style={{ marginTop: 0, width: '100%' }}
                     disableUnderline
                     value={state.language}
                     onChange={(e) => {
                        setState((prevState) => ({ ...prevState, language: e.target.value }));
                     }}
                     MenuProps={MenuProps}
                  >
                     {LANGUAGES.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                           <Typography>{option.label}</Typography>
                        </MenuItem>
                     ))}
                  </Select>
               </Grid>
            </Grid>
            <Grid
               item
               xs={12}
               container
               alignItems='center'
               justifyContent='space-between'
               className={classes.titleContainer}
            >
               <Grid item xs={7} container justifyContent='flex-start'>
                  <Typography className={classes.selectLabel}>
                     {t['page_account_preferences_timezone_title']}
                  </Typography>
               </Grid>
               <Grid item xs={5} sm={4} className={classes.selectBox}>
                  <Select
                     style={{ marginTop: 0, width: '100%' }}
                     disableUnderline
                     value={state.timeZone}
                     onChange={(e) => {
                        setState((prevState) => ({ ...prevState, timeZone: e.target.value }));
                     }}
                     MenuProps={MenuProps}
                  >
                     {moment.tz.names().map((zone) => (
                        <MenuItem key={zone} value={zone}>
                           <Typography> {zone} </Typography>
                        </MenuItem>
                     ))}
                  </Select>
               </Grid>
            </Grid>
            <Grid
               item
               xs={12}
               container
               alignItems='center'
               justifyContent='space-between'
               className={classes.titleContainer}
            >
               <Grid item xs={7} sm={8} container justifyContent='flex-start'>
                  <Typography className={classes.selectLabel}>{t['page_account_preferences_currency']}</Typography>
               </Grid>
               <Grid item xs={5} sm={4} className={classes.selectBox}>
                  <Select
                     style={{ marginTop: 0, width: '100%' }}
                     disableUnderline
                     value={state.currency}
                     onChange={(e) => {
                        setState((prevState) => ({ ...prevState, currency: e.target.value }));
                     }}
                     MenuProps={MenuProps}
                  >
                     {CURRENCIES.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                           <Typography className={classes.selectLabel}>{t[option.local]}</Typography>
                        </MenuItem>
                     ))}
                  </Select>
               </Grid>
            </Grid>

            <Grid
               item
               xs={12}
               container
               alignItems='center'
               justifyContent='space-between'
               className={classes.titleContainer}
            >
               <Grid item xs={7} sm={8} container justifyContent='flex-start'>
                  <Typography className={classes.selectLabel}>{t['page_account_preferences_admin_connect']}</Typography>
               </Grid>
               <Grid item xs={5} sm={4} className={classes.selectBox}>
                  <Select
                     style={{ marginTop: 0, width: '100%' }}
                     disableUnderline
                     value={state.allowConnect == false ? false : true}
                     onChange={(e) => {
                        setState((prevState) => ({ ...prevState, allowConnect: e.target.value }));
                     }}
                     MenuProps={MenuProps}
                  >
                     {ALLOW_CONNECT.map((option) => (
                        <MenuItem key={option.key} value={option.key}>
                           <Typography className={classes.selectLabel}>{option.label}</Typography>
                        </MenuItem>
                     ))}
                  </Select>
               </Grid>
            </Grid>

            <Grid
               item
               xs={12}
               container
               justifyContent='flex-end'
               style={{ marginTop: 20, paddingRight: '1rem', paddingLeft: isSmallScreen && '1rem' }}
            >
               <Button
                  onClick={() => handleUpdate()}
                  style={{ paddingLeft: '5rem', paddingRight: '5rem' }}
                  variant='contained'
                  color='primary'
                  fullWidth={isSmallScreen}
               >
                  {t['page_account_button_profile_update']}
               </Button>
            </Grid>

            <Grid className={classes.topHeaderContainer}>
               <Typography className={classes.selectLabel}>{t['page_account_preferences_notifications']}</Typography>
            </Grid>
            <Grid container style={{ overflowY: 'auto' }}>
               <TableContainer>
                  <Table className={classes.table} aria-label='customized table'>
                     <TableHead>
                        <TableRow>
                           <StyledTableCell>
                              {t['page_account_preferences_single_notification']} ({t?.page_dashboard_typo_messages})
                           </StyledTableCell>
                           <StyledTableCell align='center'>{t['page_account_input_profile_email']}</StyledTableCell>
                           <StyledTableCell align='center'>
                              {t['page_account_preferences_notification_system']}
                           </StyledTableCell>
                           <StyledTableCell align='center'>
                              {t['page_account_preferences_notification_browser']}
                           </StyledTableCell>
                           <StyledTableCell align='center'>
                              {t['page_account_preferences_notification_sound']}
                           </StyledTableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        <StyledTableRow>
                           <StyledTableCell component='th' scope='row'>
                              {t['page_account_preferences_new_email_message']}
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch checked={false} disabled />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch checked={false} disabled />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={newMessageSystem.active}
                                    onChange={(e) => {
                                       setNewMessageSystem((prev) => ({
                                          ...prev,
                                          active: e.target.checked,
                                       }));
                                    }}
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='flex-end' className={classes.selectBox}>
                                 <Select
                                    style={{ marginTop: 0, width: '100%', textAlign: 'left' }}
                                    disableUnderline
                                    value={newMessageSystem.sound}
                                    disabled={!newMessageSystem.active}
                                    onChange={(e) => {
                                       setNewMessageSystem((prev) => ({
                                          ...prev,
                                          sound: e.target.value,
                                       }));
                                       playSound(e.target.value);
                                    }}
                                    MenuProps={MenuProps}
                                 >
                                    {AUDIO_FILES.map((bel) => (
                                       <MenuItem key={bel.value} value={bel.value}>
                                          <Typography> {t[bel.local]} </Typography>
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </Grid>
                           </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                           <StyledTableCell component='th' scope='row'>
                              {t['page_account_preferences_new_chat']}
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.newChatEmail}
                                    onChange={handleSwitchChange}
                                    name='newChatEmail'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.newChatSystem}
                                    onChange={(e) => {
                                       setState((prev) => ({
                                          ...prev,
                                          newChatBell: e.target.checked,
                                       }));
                                       handleSwitchChange(e);
                                    }}
                                    name='newChatSystem'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.newChatBell}
                                    onChange={handleSwitchChange}
                                    name='newChatBell'
                                    disabled={!state.newChatSystem}
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='flex-end' className={classes.selectBox}>
                                 <Select
                                    style={{ marginTop: 0, width: '100%', textAlign: 'left' }}
                                    disableUnderline
                                    value={state.newChatBellName}
                                    disabled={!state.newChatBell}
                                    name='newChatBellName'
                                    onChange={handleAudioChange}
                                    MenuProps={MenuProps}
                                 >
                                    {AUDIO_FILES.map((bel) => (
                                       <MenuItem key={bel.value} value={bel.value}>
                                          <Typography> {t[bel.local]} </Typography>
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </Grid>
                           </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow style={{ height: 32 }}>
                           <StyledTableCell component='th' scope='row'>
                              {t['page_account_preferences_transferred_chat']}
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.transferredChatEmail}
                                    onChange={handleSwitchChange}
                                    name='transferredChatEmail'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.transferredChatSystem}
                                    onChange={(e) => {
                                       setState((prev) => ({
                                          ...prev,
                                          transferredChatBell: e.target.checked,
                                       }));
                                       handleSwitchChange(e);
                                    }}
                                    name='transferredChatSystem'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.transferredChatBell}
                                    onChange={handleSwitchChange}
                                    name='transferredChatBell'
                                    disabled={!state.transferredChatSystem}
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='right' className={classes.tableCell}>
                              <Grid container justifyContent='flex-end' className={classes.selectBox}>
                                 <Select
                                    style={{ marginTop: 0, width: '100%', textAlign: 'left' }}
                                    disableUnderline
                                    disabled={!state.transferredChatBell}
                                    value={state.transferredChatBellName}
                                    name='transferredChatBellName'
                                    onChange={handleAudioChange}
                                    MenuProps={MenuProps}
                                 >
                                    {AUDIO_FILES.map((bel) => (
                                       <MenuItem key={bel.value} value={bel.value}>
                                          <Typography> {t[bel.local]} </Typography>
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </Grid>
                           </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow style={{ height: 32 }}>
                           <StyledTableCell component='th' scope='row'>
                              {t['page_account_preferences_timeout_chat']}
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.timeoutChatEmail}
                                    onChange={handleSwitchChange}
                                    name='timeoutChatEmail'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.timeoutChatSystem}
                                    onChange={(e) => {
                                       setState((prev) => ({
                                          ...prev,
                                          timeoutChatBell: e.target.checked,
                                       }));
                                       handleSwitchChange(e);
                                    }}
                                    name='timeoutChatSystem'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.timeoutChatBell}
                                    onChange={handleSwitchChange}
                                    disabled={!state.timeoutChatSystem}
                                    name='timeoutChatBell'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='right' className={classes.tableCell}>
                              <Grid container justifyContent='flex-end' className={classes.selectBox}>
                                 <Select
                                    style={{ marginTop: 0, width: '100%', textAlign: 'left' }}
                                    disableUnderline
                                    disabled={!state.timeoutChatBell}
                                    value={state.timeoutChatBellName}
                                    name='timeoutChatBellName'
                                    onChange={handleAudioChange}
                                    MenuProps={MenuProps}
                                 >
                                    {AUDIO_FILES.map((bel) => (
                                       <MenuItem key={bel.value} value={bel.value}>
                                          <Typography> {t[bel.local]} </Typography>
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </Grid>
                           </StyledTableCell>
                        </StyledTableRow>
                     </TableBody>
                  </Table>
               </TableContainer>

               {/* Comments */}
               <TableContainer>
                  <Table className={classes.table} aria-label='customized table'>
                     <TableHead>
                        <TableRow>
                           <StyledTableCell>
                              {t['page_account_preferences_single_notification']} ({t?.comments})
                           </StyledTableCell>
                           <StyledTableCell align='center'>{t['page_account_input_profile_email']}</StyledTableCell>
                           <StyledTableCell align='center'>
                              {t['page_account_preferences_notification_system']}
                           </StyledTableCell>
                           <StyledTableCell align='center'>
                              {t['page_account_preferences_notification_browser']}
                           </StyledTableCell>
                           <StyledTableCell align='center'>
                              {t['page_account_preferences_notification_sound']}
                           </StyledTableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        <StyledTableRow>
                           <StyledTableCell component='th' scope='row'>
                              {t['page_account_preferences_new_qna_message']}
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch checked={false} disabled />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch checked={false} disabled />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={newQnAMessageSystem.active}
                                    onChange={(e) => {
                                       setNewQnAMessageSystem((prev) => ({
                                          ...prev,
                                          active: e.target.checked,
                                       }));
                                    }}
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='flex-end' className={classes.selectBox}>
                                 <Select
                                    style={{ marginTop: 0, width: '100%', textAlign: 'left' }}
                                    disableUnderline
                                    value={newQnAMessageSystem.sound}
                                    disabled={!newQnAMessageSystem.active}
                                    onChange={(e) => {
                                       setNewQnAMessageSystem((prev) => ({
                                          ...prev,
                                          sound: e.target.value,
                                       }));
                                       playSound(e.target.value);
                                    }}
                                    MenuProps={MenuProps}
                                 >
                                    {AUDIO_FILES.map((bel) => (
                                       <MenuItem key={bel.value} value={bel.value}>
                                          <Typography> {t[bel.local]} </Typography>
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </Grid>
                           </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                           <StyledTableCell component='th' scope='row'>
                              {t['page_account_preferences_new_qna_chat']}
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.newQnAChatEmail}
                                    onChange={handleSwitchChange}
                                    name='newQnAChatEmail'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.newQnAChatSystem}
                                    onChange={(e) => {
                                       setState((prev) => ({
                                          ...prev,
                                          newQnAChatBell: e.target.checked,
                                       }));
                                       handleSwitchChange(e);
                                    }}
                                    name='newQnAChatSystem'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.newQnAChatBell}
                                    onChange={handleSwitchChange}
                                    name='newQnAChatBell'
                                    disabled={!state.newQnAChatSystem}
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='flex-end' className={classes.selectBox}>
                                 <Select
                                    style={{ marginTop: 0, width: '100%', textAlign: 'left' }}
                                    disableUnderline
                                    value={state.newQnAChatBellName}
                                    disabled={!state.newQnAChatBell}
                                    name='newQnAChatBellName'
                                    onChange={handleAudioChange}
                                    MenuProps={MenuProps}
                                 >
                                    {AUDIO_FILES.map((bel) => (
                                       <MenuItem key={bel.value} value={bel.value}>
                                          <Typography> {t[bel.local]} </Typography>
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </Grid>
                           </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow style={{ height: 32 }}>
                           <StyledTableCell component='th' scope='row'>
                              {t['page_account_preferences_qna_transferred_chat']}
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.transferredQnAChatEmail}
                                    onChange={handleSwitchChange}
                                    name='transferredQnAChatEmail'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.transferredQnAChatSystem}
                                    onChange={(e) => {
                                       setState((prev) => ({
                                          ...prev,
                                          transferredQnAChatBell: e.target.checked,
                                       }));
                                       handleSwitchChange(e);
                                    }}
                                    name='transferredQnAChatSystem'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.transferredQnAChatBell}
                                    onChange={handleSwitchChange}
                                    name='transferredQnAChatBell'
                                    disabled={!state.transferredQnAChatSystem}
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='right' className={classes.tableCell}>
                              <Grid container justifyContent='flex-end' className={classes.selectBox}>
                                 <Select
                                    style={{ marginTop: 0, width: '100%', textAlign: 'left' }}
                                    disableUnderline
                                    disabled={!state.transferredQnAChatBell}
                                    value={state.transferredQnAChatBellName}
                                    name='transferredQnAChatBellName'
                                    onChange={handleAudioChange}
                                    MenuProps={MenuProps}
                                 >
                                    {AUDIO_FILES.map((bel) => (
                                       <MenuItem key={bel.value} value={bel.value}>
                                          <Typography> {t[bel.local]} </Typography>
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </Grid>
                           </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow style={{ height: 32 }}>
                           <StyledTableCell component='th' scope='row'>
                              {t['page_account_preferences_qna_timeout_chat']}
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.timeoutQnAChatEmail}
                                    onChange={handleSwitchChange}
                                    name='timeoutQnAChatEmail'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.timeoutQnAChatSystem}
                                    onChange={(e) => {
                                       setState((prev) => ({
                                          ...prev,
                                          timeoutQnAChatBell: e.target.checked,
                                       }));
                                       handleSwitchChange(e);
                                    }}
                                    name='timeoutQnAChatSystem'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='center' className={classes.tableCell}>
                              <Grid container justifyContent='center'>
                                 <BlueSwitch
                                    checked={state.timeoutQnAChatBell}
                                    onChange={handleSwitchChange}
                                    disabled={!state.timeoutQnAChatSystem}
                                    name='timeoutQnAChatBell'
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='right' className={classes.tableCell}>
                              <Grid container justifyContent='flex-end' className={classes.selectBox}>
                                 <Select
                                    style={{ marginTop: 0, width: '100%', textAlign: 'left' }}
                                    disableUnderline
                                    disabled={!state.timeoutQnAChatBell}
                                    value={state.timeoutQnAChatBellName}
                                    name='timeoutQnAChatBellName'
                                    onChange={handleAudioChange}
                                    MenuProps={MenuProps}
                                 >
                                    {AUDIO_FILES.map((bel) => (
                                       <MenuItem key={bel.value} value={bel.value}>
                                          <Typography> {t[bel.local]} </Typography>
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </Grid>
                           </StyledTableCell>
                        </StyledTableRow>
                     </TableBody>
                  </Table>
               </TableContainer>
               <Grid item xs={12} container justifyContent='flex-end' className={classes.endUpdateButton}>
                  <Button
                     onClick={handleUpdate}
                     style={{ paddingLeft: '5rem', paddingRight: '5rem' }}
                     variant='contained'
                     color='primary'
                     fullWidth={isSmallScreen}
                  >
                     {t['page_account_button_profile_update']}
                  </Button>
               </Grid>
            </Grid>
         </Grid>
         {/* Modal */}
         <Modal
            paperStyle={{ width: 450 }}
            open={modalConfig}
            onClose={handleCloseModal}
            modalHeader={t?.general_message_are_your_sure}
         >
            <ModalContent onAccept={editCustomerSubmit} onCancel={handleCloseModal} />
         </Modal>
      </div>
   );
};

export default PreferencesAndNotifications;

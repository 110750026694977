import React from 'react';
import PageLayout from '../SharedComponents/PageLayout';
import Nav from '../SharedComponents/Nav';
import Form from './Form';
import Previews from './Previews';

const GetStarted = ({ url }) => (
   <PageLayout nav={<Nav active='getStarted' url={url} />} form={<Form />} previews={<Previews />} />
);

export default GetStarted;

export const E_COMMERCE_TYPE = 'E_COMMERCE_TYPE';
export const E_COMMERCE_STORE = 'E_COMMERCE_STORE';
export const E_COMMERCE_ORDERS = 'E_COMMERCE_ORDERS';
export const E_COMMERCE_ALL_SHIPPING_METHODS = 'E_COMMERCE_ALL_SHIPPING_METHODS';
export const E_COMMERCE_ALL_PAYMENT_TYPES = 'E_COMMERCE_ALL_PAYMENT_TYPES';
export const SET_E_COMMERCE_TOTAL_NUMBER_OF_ORDERS = 'SET_E_COMMERCE_TOTAL_NUMBER_OF_ORDERS';
export const E_COMMERCE_PRODUCTS = 'E_COMMERCE_PRODUCTS';
export const E_COMMERCE_CUSTOMERS = 'E_COMMERCE_CUSTOMERS';
export const UPDATE_E_COMMERCE_CUSTOMER = 'UPDATE_E_COMMERCE_CUSTOMER';
export const SET_E_COMMERCE_TOTAL_NUMBER_OF_CUSTOMERS = 'SET_E_COMMERCE_TOTAL_NUMBER_OF_CUSTOMERS';
export const DELETE_STORE = 'DELETE_STORE';

import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { classNames } from 'react-select/src/utils';
import { StatisticsTooltipIcon } from '@assets/icons/StatisticsPageIcons';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme: any) => ({
   paper: {
      width: '220px',
      height: '160px',
   },
   tooltipContainer: {
      background: '#157CFC',
      borderRadius: 4,
      minWidth: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
   },
   count: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '15px',
   },
   icon: {
      marginBottom: theme.spacing(1),
      width: 25,
      height: 25,
   },
   hour: {
      // color: theme.chatApp.general.pallet.passiveStructureGrey,
      color: '#7C94B2',
   },
}));

// 0:
// color: "#26BF8A"
// dataKey: "received"
// fill: "#26BF8A"
// formatter: undefined
// name: "Received Messages"
// payload: {title: "08/07", received: 0, sent: 3}
// type: undefined
// unit: undefined
// value: 0

const CustomTooltip = ({ active, payload, label }: any) => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   }: any = useContext(LanguageContext);

   if (active && payload && payload.length) {
      return (
         <Paper style={{ padding: '20px', borderRadius: 10 }} className={classes.paper} elevation={1}>
            <Grid container direction='column' justifyContent='flex-start'>
               <Grid item>
                  <Grid container justifyContent='space-between' alignItems='center'>
                     <StatisticsTooltipIcon className={classes.icon} fill='#ccd5e3' />
                     <Typography variant='subtitle2' className={classes.hour}>
                        {payload[0]?.payload?.title}
                     </Typography>
                  </Grid>
               </Grid>
               <Grid container justifyContent='space-between' alignItems='center'>
                  <Typography variant='subtitle2' style={{ color: '#19AA73' }}>
                     {t?.page_dashboard_typo_received_messages}
                  </Typography>
                  <div className={classes.tooltipContainer} style={{ background: '#5AC19D' }}>
                     <Typography className={classes.count}>{payload[0]?.payload?.received}</Typography>
                  </div>
               </Grid>
               <Grid item>
                  <Grid container justifyContent='space-between' alignItems='center'>
                     <Typography variant='subtitle2' style={{ color: '#157CFC' }}>
                        {t?.page_dashboard_typo_sent_messages}
                     </Typography>
                     <div className={classes.tooltipContainer}>
                        <Typography className={classes.count}>{payload[0]?.payload?.sent}</Typography>
                     </div>
                  </Grid>
               </Grid>
            </Grid>
         </Paper>
      );
   }
   return null;
};

export default CustomTooltip;

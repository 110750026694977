import { Grid, Typography, Paper } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import SearchBar from '@components/SearchBar';
import { DataGrid } from '@material-ui/data-grid';
import ButtonOK from '@components/ButtonOK/buttonOk';

const useStyles = makeStyles((theme) => ({
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   ButtonContainer: {
      paddingLeft: 5,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   Table: {
      ' &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
         outline: 'none !important',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
         outline: 'none !important',
      },
      ' &.MuiDataGrid-root .MuiDataGrid-window': {
         overflowX: 'hidden',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
         padding: 0,
      },
   },
   content: {
      height: '100vh',
      overflowX: 'hidden',
      overflowY: 'auto',
      flexGrow: 1,
   },
   rowStyle: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
         outline: 'none',
      },

      fontWeight: 'bold',
      fontSize: '1rem',
   },
   paper: {
      borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
      width: '100%',
      borderRadius: 15,
      overflowY: 'auto',
      overflowX: 'hidden',
   },
}));

const TableData = ({
   columns,
   teamsLoading,
   Page,
   setQueryParams,
   queryParams,
   teamList,
   teamCount,
   setModalMode,
   handleOpenCreateModal,
   text,
   setText,
   person,
}) => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   const theme = useTheme();

   return (
      <div
         className={classes.content}
         style={{
            height: '100%',
            width: '100%',
            // display: firstLoad || noQuickAnswer ? 'none' : 'block',
         }}
      >
         <Grid
            container
            wrap='nowrap'
            style={{
               height: '100%',
            }}
         >
            <Paper elevation={1} className={classes.paper}>
               <DataGrid
                  loading={teamsLoading}
                  className={classes.Table}
                  components={{
                     Toolbar: (props) => {
                        return (
                           <div
                              container
                              className={classes.titleContainer}
                              style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'center',
                                 alignItems: 'center',
                              }}
                           >
                              <div style={{ flexGrow: '1', fontSize: '20px', fontWeight: 'bold' }}>
                                 {t?.page_settings_users_teams}
                              </div>
                              <div>
                                 <SearchBar
                                    value={text}
                                    onChange={setText}
                                    placeholder={t['search']}
                                    style={{ width: 200, height: 40 }}
                                 />
                              </div>
                              <div>
                                 <Typography
                                    style={{
                                       margin: '0px 10px',
                                       color:
                                          person.customer.usageTeamsCount < person.customer.package.teamsCount
                                             ? 'initial'
                                             : 'red',
                                    }}
                                 >
                                    {t?.teams_limit} {person.customer.usageTeamsCount} /{' '}
                                    {person.customer.package.teamsCount}
                                 </Typography>
                              </div>

                              <div style={{ paddingLeft: 5 }}>
                                 <ButtonOK
                                    onClick={() => {
                                       setModalMode('CREATE');
                                       handleOpenCreateModal();
                                    }}
                                    width={200}
                                    height={40}
                                    label={t['create_new_team']}
                                    fontSize='1.1rem'
                                 />
                              </div>
                           </div>
                        );
                     },
                  }}
                  disableSelectionOnClick
                  localeText={{
                     toolbarColumns: '',
                     noRowsLabel: t?.no_data_to_show,
                  }}
                  rowHeight={64}
                  disableColumnMenu
                  disableColumnSelector
                  classes={{ columnHeader: classes.rowStyle }}
                  pageSize={Page.pageSize}
                  paginationMode='server'
                  page={queryParams.page - 1}
                  onPageChange={(params) => {
                     setQueryParams((prevState) => ({
                        ...prevState,
                        page: params + 1,
                     }));
                  }}
                  disableColumn
                  rows={teamList}
                  rowCount={teamCount}
                  columns={columns}
               />
            </Paper>
         </Grid>
      </div>
   );
};

export default TableData;

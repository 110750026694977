import { gql } from '@apollo/client';

export const GET_CUSTOM_FIELDS = gql`
   query getCustomFields($customer: String!) {
      getCustomFields(input: { customer: $customer }) {
         success
         message
         data {
            _id
            customer
            name
            type
            listValues
            label
            description
            createdAt
            updatedAt
         }
         code
      }
   }
`;

export const GET_PERSON_CUSTOM_FIELDS = gql`
   query getPersonCustomFields($person: String!) {
      personCustomFields(person: $person) {
         success
         message
         data {
            customField {
               _id
               customer
               name
               type
               listValues
               label
            }
            value
         }
         code
      }
   }
`;

import { useState, useEffect } from 'react';
import { PLATFORM_TYPE } from '@modules/Chat/types';

const useChatPlatformStatus = (activeChat: any) => {
   const [platformStatus, setPlatformStatus] = useState(true);
   useEffect(() => {
      if (activeChat?.type === PLATFORM_TYPE.WHATS_APP_WEB) {
         if (activeChat.participants[0].type.toString() === '-15') setPlatformStatus(true);
         else if (activeChat?.whatsAppPhone) setPlatformStatus(activeChat?.whatsAppPhone?.status);
         else setPlatformStatus(false);
      } else if (activeChat?.type === PLATFORM_TYPE.FACEBOOK) {
         if (activeChat.participants[0].type.toString() === '-15') setPlatformStatus(true);
         else if (activeChat?.facebookPage) setPlatformStatus(activeChat?.facebookPage?.isMessageEnable);
         else setPlatformStatus(false);
      } else if (activeChat?.type === PLATFORM_TYPE.INSTAGRAM) {
         if (activeChat.participants[0].type.toString() === '-15') setPlatformStatus(true);
         else if (activeChat?.instagramPage) setPlatformStatus(activeChat?.instagramPage?.isMessageEnable);
         else setPlatformStatus(false);
      } else if (activeChat?.type === PLATFORM_TYPE.WHATS_APP_BUSINESS) {
         if (activeChat.participants[0].type.toString() === '-15') setPlatformStatus(true);
         else if (activeChat?.whatsAppBusinessPhone) setPlatformStatus(activeChat?.whatsAppBusinessPhone);
         else setPlatformStatus(false);
      } else if (activeChat?.type === PLATFORM_TYPE.TELEGRAM) {
         if (activeChat.telegramAccount) {
            setPlatformStatus(activeChat.telegramAccount.status);
         } else {
            setPlatformStatus(false);
         }
      } else if (activeChat?.type === PLATFORM_TYPE.EMAIL) {
         if (activeChat.emailAccount) {
            setPlatformStatus(true);
         } else {
            setPlatformStatus(false);
         }
      } else {
         setPlatformStatus(true);
      }
   }, [
      activeChat?.type,
      activeChat?.whatsAppPhone,
      activeChat?.whatsAppBusinessPhone,
      activeChat?.facebookPage,
      activeChat?.instagramPage,
      activeChat?.telegramAccount,
      activeChat?.emailAccounts,
   ]);

   return platformStatus;
};

export default useChatPlatformStatus;

import { useMutation } from '@apollo/client';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import { LanguageContext } from '@helper/locale/langContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { REMOVE_CAMPAIGN } from '@queries/Campaigns/Service/GraphQL/mutation';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';

const useStyles = makeStyles((theme) => ({
   modalContainer: {
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 16,
   },
   modalHeader: {
      height: 50,
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalBody: {
      paddingInline: 20,
      paddingBottom: 15,
   },
   modalBodyIconContainer: {
      display: 'flex',
      flexDirection: 'column',
   },
   modalDescription: {
      textAlign: 'center',
      paddingBlock: 30,
   },
   modalBodyRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
   },
   modalText: {
      color: '#797575',
      fontSize: '1rem',
      margin: 20,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const RemoveCampaign = ({ open, onClose, campaignId }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [createLoading, setCreateLoading] = useState(false);
   const { enqueueSnackbar } = useSnackbar();

   const [removeCampaignMutation] = useMutation(REMOVE_CAMPAIGN, {
      async update(
         cache,
         {
            data: {
               removeCampaign: { data: removeCampaign },
            },
         },
      ) {},
   });
   return (
      <Modal paperStyle={{ width: 450 }} onClose={onClose} open={open} modalHeader={t['campaign_remove']}>
         <ModalLargeText style={{ textAlign: 'center', marginBlock: 40 }}>
            {t['campaign_remove_description']}
         </ModalLargeText>
         <ModalButtonField>
            <ButtonCancel
               label={t?.page_chat_btn_filter_cancel}
               onClick={() => onClose()}
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            />
            <ButtonOK
               label={t['campaign_remove_delete']}
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#ef5350',
                  '&:hover': {
                     backgroundColor: '#f44336',
                  },
                  float: 'right',
               }}
               startIcon={createLoading ? <CircularProgress size={20} /> : null}
               onClick={() => {
                  setCreateLoading(true);
                  removeCampaignMutation({
                     variables: {
                        input: {
                           _id: campaignId,
                        },
                     },
                  })
                     .then((_) => {
                        setCreateLoading(false);
                        onClose();
                        enqueueSnackbar(t['campaign_remove_success'], {
                           variant: 'success',
                        });
                     })
                     .catch((err) => {
                        setCreateLoading(false);
                        onClose();
                        enqueueSnackbar(err.message, {
                           // send error message
                           variant: 'error',
                        });
                     });
               }}
            />
         </ModalButtonField>
      </Modal>
   );
};

export default WithUrlTranslations(RemoveCampaign);

import useBoolean from './useBoolean';

const useSeeMore = (text: string, length: number) => {
   const { value: showMore, off: handleSeeMore, on: handleOffSeeMore } = useBoolean(text?.length > length);
   const { value: isOnlyText } = useBoolean(text?.length >= length);

   return [showMore, handleSeeMore, handleOffSeeMore, isOnlyText] as const;
};

export default useSeeMore;

import React from 'react';

const Error = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='140' height='140' viewBox='0 0 140 140'>
         <g id='Group_336' data-name='Group 336' transform='translate(-890 -436)'>
            <g
               id='Ellipse_75'
               dataname='Ellipse 75'
               transform='translate(890 436)'
               fill='none'
               stroke='#f4425b'
               strokeWidth='6'
            >
               <circle cx='70' cy='70' r='70' stroke='none' />
               <circle cx='70' cy='70' r='67' fill='none' />
            </g>
            <path
               id='Union_3'
               dataname='Union 3'
               d='M13991.558,17040.58l-16.263-16.266-16.263,16.266a8.5,8.5,0,0,1-12.023-12.02l16.266-16.268-16.262-16.264a8.5,8.5,0,0,1,12.02-12.02l16.262,16.264,16.264-16.264a8.5,8.5,0,0,1,12.021,12.02l-16.264,16.266,16.264,16.266a8.5,8.5,0,0,1-12.021,12.02Z'
               transform='translate(-13015.293 -16506.295)'
               fill='#f4425b'
            />
         </g>
      </svg>
   );
};

export default Error;

import { ButtonBase, CircularProgress, Grid } from '@material-ui/core';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import React from 'react';

const MiscFile = ({ name, showPreview, isLoading, disabled, url }) => {
   // console.log('url', url);
   return (
      <Grid
         style={{ background: '#dcdbdb', width:150, height:100, borderRadius:6 }}
         container
         direction='row'
         justifyContent='space-between'
         alignItems='center'
         wrap='nowrap'
      >
         <Grid container justifyContent='space-around' alignItems='center' direction='column'>
            {showPreview && (
               <ButtonBase disabled={disabled} onClick={() => window.open(url)}>
                  <InsertDriveFileIcon style={{ fontSize: '3.5rem' }} color='action' />
               </ButtonBase>
            )}
            {isLoading && <CircularProgress size={24} color={'primary'} />}
         </Grid>
      </Grid>
   );
};

export default MiscFile;

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import { useSelector } from 'react-redux';
import PackageItem from './packageItem';
import useFreeTrialDate from '@hooks/useFreeTrialDate';

const useStyles = makeStyles((theme) => ({
   root: {
      [theme.breakpoints.down('sm')]: {
         width: (props) => props.width - 25,
      },
      padding: '5px 10px',
      height: 350,
      overflowY: 'auto',
   },

   iconColor: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
   },
   borderBottom0: {
      borderBottom: 0,
   },
   item: {
      backgroundColor: (props) => (props.hasFreeTrial ? theme.chatApp.general.pallet.passiveStructureAzure : '#F1F6FE'),
      color: (props) => (props.hasFreeTrial ? '#fff' : theme.chatApp.general.pallet.passiveStructureBlue),
      padding: 20,
      borderRadius: 10,
      margin: '10px 0px',
      fontWeight: 'bold',
   },
}));

const ActivePackages = (props) => {
   const { onCancelPackage } = props;
   const person = useSelector((state) => state.personReducer.person);
   const addOns = person.customer.package.addOns;
   const classes = useStyles(props);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const customerFreeTrialDate = useSelector((state) => state.personReducer.person.customer.freeTrialExpiringDate);
   const { isHours, difference, isEnd } = useFreeTrialDate(customerFreeTrialDate);

   return (
      <Grid item xs={12} className={classes.root}>
         <Grid container direction='column' item xs={12}>
            <Grid container item xs={12}>
               {/* Free Trial */}
               {difference > 0 && (
                  <PackageItem
                     item={{
                        name: `${t?.free_trial}`,
                        price: `${Math.floor(difference)} ${isHours ? t?.hours_left : t?.day_left}`,
                     }}
                     active={!isEnd}
                  />
               )}
               {/* Current Main Package */}
               <PackageItem active={isEnd} isProduct product={person.customer.package} />
               {/* Add On Packages */}
               {addOns.map((item, index) => {
                  return (
                     <PackageItem
                        key={item?.name + index}
                        isAddOn
                        active={false}
                        item={item}
                        onCancelPackage={onCancelPackage}
                     />
                  );
               })}
            </Grid>
         </Grid>
      </Grid>
   );
};

export default ActivePackages;

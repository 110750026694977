import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import {
   CREATE_FLOW_NODE_MUTATION,
   UPDATE_ASK_QUESTION_BLOCK_MUTATION,
   UPDATE_BLOCK_MUTATION,
   UPDATE_FLOW_NODE_MUTATION,
} from '@queries/Automation/mutation';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactFlow, {
   Controls,
   MarkerType,
   Panel,
   ReactFlowProvider,
   addEdge,
   applyEdgeChanges,
   applyNodeChanges,
} from 'reactflow';
import 'reactflow/dist/style.css';
import customEdge from '../Nodes/FlowEdges/customEdge';
import ActionNode from '../Nodes/FlowNodes/actions';
import AskQuestionNode from '../Nodes/FlowNodes/askQuestion';
import ConditionNode from '../Nodes/FlowNodes/condition';
import DelayNode from '../Nodes/FlowNodes/delay';
import HttpRequestNode from '../Nodes/FlowNodes/httpRequest';
import MenuNode from '../Nodes/FlowNodes/menu';
import SendButtonMessageNode from '../Nodes/FlowNodes/sendButtonMessage';
import SendMessageNode from '../Nodes/FlowNodes/sendMessage';
import StartFlow from '../Nodes/FlowNodes/startFlow';
import TriggerNode from '../Nodes/FlowNodes/trigger';
import WelcomeMessageNode from '../Nodes/FlowNodes/welcomeMessage';
import NoteNode from '../Nodes/FlowNodes/note';

import { generateRandomId } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';
import { FlowBlockType, FlowNodeType, IFlowBlockFieldTypes } from '@modules/Automation/FlowBots/types';
import { SET_CLICKED_NODE_ID } from '@store/actions/automation';
import HelperLines from './HelperLine/HelperLines';
import { getHelperLines } from './HelperLine/utils';
import Layout from './HorizontalLayout/getLayoutedElements';
import NoteIcon from '@mui/icons-material/StickyNote2Outlined';

import '@reactflow/node-resizer/dist/style.css';
import GroupNode from './DynamicGrouping/GroupNode';
import SideBar from './DynamicGrouping/Sidebar';
import styles from './DynamicGrouping/style.module.css';
import { onCreateGroups } from './DynamicGrouping/utils';

const useStyles = makeStyles(() => ({
   btnPanel: {
      padding: '3px 2px',
      backgroundColor: 'white',
      borderRadius: 1,
      border: '1px solid #E1E9E9',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.05)',
      cursor: 'pointer',
      '&:hover': {
         backgroundColor: '#F7F7FA',
      },
   },
   btnPanelNote: {
      padding: '0 2px',
      backgroundColor: 'white',
      borderRadius: 1,
      border: '1px solid #E1E9E9',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.05)',
      cursor: 'pointer',
      '&:hover': {
         backgroundColor: '#F7F7FA',
      },
   },
   btnPanelInfo: {
      marginTop: 0,
      padding: '10px 20px',
      backgroundColor: '#C3DDFF',
      borderRadius: 10,
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.05)',
   },
}));

const fitViewOptions = {
   padding: 0.5,
};
const defaultEdgeOptions = {
   animated: false,
};
const customStyle = {
   width: '100%',
   height: `calc(100vh - 65px)`,
};

let timer = null;

const nodeTypes = {
   send_message: SendMessageNode,
   send_message_buttons: SendButtonMessageNode,
   ask_question: AskQuestionNode,
   conditions: ConditionNode,
   actions: ActionNode,
   delay: DelayNode,
   http_request: HttpRequestNode,
   trigger: TriggerNode,
   welcome_message: WelcomeMessageNode,
   MenuNode: MenuNode,
   start_flow: StartFlow,
   group: GroupNode,
   note: NoteNode,
};

const edgeTypes = {
   customEdge: customEdge,
};

const Flow = ({
   handleToUpdate,
   handleIndexToUpdate,
   setIsSideBarVisible,
   setHandleToUpdate,
   contentNodes,
   clickedNodeId,
   setClickedNodeId,
   clickedHandleId,
   setClickedHandleId,
   setIsNewNode,
   isDraggable,
   setIsDraggable,
   blockState,
   setBlockState,
   isDraft,
   setContentNodes,
   setIsDraft,
   setLeftNodes,
   isNewNode,
   setToGroup,
   toGroup,
}) => {
   const classes = useStyles();
   const person = useSelector((reducer) => reducer.personReducer.person);
   let { id } = useParams();
   const flowBotId = id;
   const connectionCreated = useRef(false);
   const initialEdges = [];
   const [edges, setEdges] = useState(initialEdges);
   const proOptions = { hideAttribution: true };
   const [reactFlowInstance, setReactFlowInstance] = useState(null);
   const reactFlowWrapper = useRef(null);
   const [prevPosition, setPrevPosition] = useState();
   const [prevvNodeId, setPrevNodeId] = useState();
   const [sourceNodeId, setSourceNodeId] = useState(null);
   const [allowClickPane, setAllowClickPane] = useState(true);
   const [nodeState, setNodeState] = useState([]);
   const sourceNodeType = nodeState.find((node) => node.id === sourceNodeId)?.type;
   const LIST_NODE_TEMPLATE = useSelector((reducer) => reducer.automationReducer.listNodeTemplates);
   const hasOperations = useSelector((reducer) => reducer.automationReducer.hasOperations);
   const clickedId = useSelector((reducer) => reducer.automationReducer.clickedNodeId);
   const [draggedGroup, setDraggedGroup] = useState();
   const [draggedNode, setDraggedNode] = useState();

   const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const dispatch = useDispatch();

   useEffect(() => {
      setNodeState(contentNodes);
   }, [contentNodes]);

   // update node mutation
   const [updateFlowNode] = useMutation(UPDATE_FLOW_NODE_MUTATION);
   const handleUpdateFlowNode = async (itemId, position) => {
      const updateNodes = (prevNodes) =>
         prevNodes.map((node) => {
            if (node.id === itemId) {
               return {
                  ...node,
                  position: { x: position.x, y: position.y },
               };
            }
            return node;
         });
      setContentNodes((prev) => updateNodes(prev));
      setLeftNodes((prev) => updateNodes(prev));

      try {
         await updateFlowNode({
            variables: {
               input: {
                  _id: itemId,
                  coordinates: { x: position.x, y: position.y },
               },
            },
            onCompleted: (res) => {
               const updateNodes = (prevNodes) =>
                  prevNodes.map((node) => {
                     if (node.id === itemId) {
                        return {
                           ...node,
                           position: { x: position.x, y: position.y },
                        };
                     }
                     return node;
                  });
               //  setContentNodes((prev) => updateNodes(prev));
               //  setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating node:', error);
      }
   };
   const handleUpdateFlowNodeGroupPosition = async (itemId, position) => {
      try {
         await updateFlowNode({
            variables: {
               input: {
                  _id: itemId,
                  coordinates: { x: position.x, y: position.y },
               },
            },
            onCompleted: (res) => {
               setContentNodes(nodeState);
               setLeftNodes(nodeState);

               setDraggedGroup();
            },
         });
      } catch (error) {
         console.error('Error updating node:', error);
      }
   };

   const handleUpdateFlowNodeGroup = async (itemId, groupId) => {
      try {
         await updateFlowNode({
            variables: {
               input: {
                  _id: itemId,
                  groupId: groupId,
               },
            },
         });
      } catch (error) {
         console.error('Error updating node:', error);
      }
   };

   // ----------------------------Helper lines---------------------------------
   const [helperLineHorizontal, setHelperLineHorizontal] = useState(undefined);
   const [helperLineVertical, setHelperLineVertical] = useState(undefined);
   const customApplyNodeChanges = useCallback((changes, nodes) => {
      // reset the helper lines (clear existing lines, if any)
      setHelperLineHorizontal(undefined);
      setHelperLineVertical(undefined);

      // this will be true if it's a single node being dragged
      // inside we calculate the helper lines and snap position for the position where the node is being moved to
      if (changes.length === 1 && changes[0].type === 'position' && changes[0].dragging && changes[0].position) {
         const helperLines = getHelperLines(changes[0], nodes);

         // if we have a helper line, we snap the node to the helper line position
         // this is being done by manipulating the node position inside the change object
         changes[0].position.x = helperLines.snapPosition.x ?? changes[0].position.x;
         changes[0].position.y = helperLines.snapPosition.y ?? changes[0].position.y;

         // if helper lines are returned, we set them so that they can be displayed
         setHelperLineHorizontal(helperLines.horizontal);
         setHelperLineVertical(helperLines.vertical);
      }

      return applyNodeChanges(changes, nodes);
   }, []);

   const onNodesChange = useCallback(
      (changes) => {
         setNodeState((nodes) => customApplyNodeChanges(changes, nodes));
      },
      [setNodeState, customApplyNodeChanges],
   );

   //--------------------------------Dynamic grouping ---------------------------
   // Update all child nodes of a dragged group
   useEffect(() => {
      if (draggedGroup) {
         const childNodes = nodeState.filter((nd) => nd.parentNode === draggedGroup);
         if (childNodes) {
            childNodes.forEach((cn) => {
               const parentPosition = nodeState.find((node) => node.id === cn.parentNode)?.position;
               const childPosition = {
                  x: cn.position.x + parentPosition?.x,
                  y: cn.position.y + parentPosition?.y,
               };

               handleUpdateFlowNodeGroupPosition(cn.id, childPosition);
            });
         }
      }
   }, [draggedGroup]);

   // Update position of node dragged inside a group
   useEffect(() => {
      if (draggedNode) {
         const childNode = nodeState.find((nd) => nd.id === draggedNode);
         if (childNode) {
            const parentPosition = nodeState.find((node) => node.id === childNode.parentNode)?.position;
            const childPosition = {
               x: childNode.position.x + parentPosition?.x,
               y: childNode.position.y + parentPosition?.y,
            };

            handleUpdateFlowNodeGroupPosition(childNode.id, childPosition);
         }
      }
   }, [draggedNode]);

   const onNodeDragStop = useCallback(
      (_, node) => {
         let nodePosition = node?.position;
         if (prevPosition !== nodePosition) {
            if (node.id.startsWith('random-') || node.id.startsWith('group_') || node.parentNode) {
               if (node.id.startsWith('group_')) {
                  if (timer != null) clearTimeout(timer);

                  timer = setTimeout(() => {
                     setDraggedGroup(node.id);
                  }, 2000);
               } else if (node.parentNode) {
                  if (timer != null) clearTimeout(timer);

                  timer = setTimeout(() => {
                     setDraggedNode(node.id);
                  }, 2000);
               } else {
                  console.log('This is a random node');
               }
            } else {
               if (prevvNodeId === node?.id) {
                  if (timer != null) clearTimeout(timer);

                  timer = setTimeout(() => {
                     handleUpdateFlowNode(node.id, nodePosition);
                  }, 2000);
               } else {
                  handleUpdateFlowNode(node.id, nodePosition);
               }
               setPrevNodeId(node.id);
               setPrevPosition(nodePosition);
            }
         }

         setClickedNodeId(node.id);
         dispatch({ type: SET_CLICKED_NODE_ID, payload: node.id });
      },
      [prevPosition, prevvNodeId],
   );

   const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), [setEdges]);

   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlock = async (blockId, target) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  target: target,
               },
            },
         });
         setIsNewNode(generateRandomId());
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const [blocks, setBlocks] = useState([]);
   const [isUpdateQR, setIsUpdateQR] = useState(false);
   const [blockIdToUpdate, setBlockIdToUpdate] = useState();

   // Responsable for updating the targets in quickReplies and InfrormatibeButtons
   useEffect(() => {
      if (isUpdateQR && blockIdToUpdate) {
         const blockFields = blocks?.find((block) => block._id === blockIdToUpdate)?.fields;
         try {
            if (blockFields) {
               updateBlock({
                  variables: {
                     input: {
                        _id: blockIdToUpdate,
                        fields: blockFields.map((field) => {
                           for (let [key, value] of Object.entries(field)) {
                              if (value == null) {
                                 delete field[key];
                              }
                           }
                           return field;
                        }),
                     },
                  },
               });
               setIsNewNode(generateRandomId());
            }
         } catch (error) {
            console.error('Error updating block:', error);
         }
      }
   }, [isUpdateQR]);

   useEffect(() => {
      const block = nodeState.find((node) => node.id === sourceNodeId)?.data?.flowBlocks;
      setBlocks(block);
   }, [sourceNodeId, nodeState]);

   const handleUpdateFlowBlockQuickReply = async (blockId, target, quickReplyIndex) => {
      setBlocks((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === blockId) {
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.quickReplies && field.quickReplies[quickReplyIndex]) {
                        return {
                           ...field,
                           quickReplies: field.quickReplies.map((quickReply, index) => {
                              if (index === quickReplyIndex) {
                                 return {
                                    ...quickReply,
                                    target: target,
                                 };
                              }
                              return quickReply;
                           }),
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };
   const handleUpdateFlowBlockInfinitiveBtn = async (blockId, target, infBtnIndex) => {
      setBlocks((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === blockId) {
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.informativeButtons && field.informativeButtons[infBtnIndex]) {
                        return {
                           ...field,
                           informativeButtons: field.informativeButtons.map((infBtn, index) => {
                              if (index === infBtnIndex) {
                                 return {
                                    ...infBtn,
                                    target: target,
                                 };
                              }
                              return infBtn;
                           }),
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };

   const [updateBlockAskQuestion] = useMutation(UPDATE_ASK_QUESTION_BLOCK_MUTATION);
   const handleUpdateFlowBlockAskQuestion = async (blockId, target, handleToUpdate) => {
      try {
         let input = {};

         if (handleToUpdate === 'action_on_reply') {
            input = {
               _id: blockId,
               fieldIndex: 0,
               fieldName: 'target_on_reply',
               fieldValue: target,
            };
         } else if (handleToUpdate === 'action_if_not_reply') {
            input = {
               _id: blockId,
               fieldIndex: 0,
               fieldName: 'target_on_not_reply',
               fieldValue: target,
            };
         } else if (handleToUpdate === 'action_if_invalid') {
            input = {
               _id: blockId,
               fieldIndex: 0,
               fieldName: 'target_on_invalid_input',
               fieldValue: target,
            };
         }

         await updateBlockAskQuestion({
            variables: {
               input: input,
            },
         });

         setIsNewNode(generateRandomId());
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const handleUpdateFlowBlockHttpRequest = async (blockId, target, handleToUpdate) => {
      try {
         const foundField = nodeState.find((node) => node.data?.flowBlocks?.some((block) => block._id === blockId))
            ?.data.flowBlocks[0]?.fields[0];

         let fields = [];
         if (handleToUpdate === 'targetOnSuccess') {
            fields = [
               {
                  targetOnSuccess: target,
                  targetOnFailure: foundField.targetOnFailure,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         } else {
            fields = [
               {
                  targetOnFailure: target,
                  targetOnSuccess: foundField.targetOnSuccess,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         }

         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: () => {
               setIsNewNode(generateRandomId());
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const [createFlowNode] = useMutation(CREATE_FLOW_NODE_MUTATION);
   const handleCreateNode = async (selectedNodeType, nodeTitle, event, position) => {
      event.preventDefault();
      try {
         if (sourceNodeId && clickedHandleId && position) {
            const sourceNode = nodeState.find((node) => node.id === sourceNodeId)?.parentNode;
            const parentPosition = nodeState.find((node) => node.id === sourceNode)?.position;
            const childPosition = {
               x: position.x - parentPosition?.x + 25,
               y: position.y - parentPosition?.y + 25,
            };
            const newTargetNode = {
               id: 'random-' + generateRandomId(),
               type: selectedNodeType,
               title: nodeTitle,
               parentNode: sourceNode,
               data: {
                  title: nodeTitle,
                  clickedNodeId,
                  flowBlocks: [],
                  setHandleToUpdate,
                  setIsDraggable,
                  setClickedNodeId,
                  setClickedHandleId,
                  isCreation: true,
               },
               expandParent: true,
               position: sourceNode ? childPosition : position,
               flowBot: flowBotId,
               dragHandle: '.custom-drag-handle',
            };
            setNodeState((prevNodes) => [...prevNodes, newTargetNode]);

            const newEdge = {
               id: generateRandomId(),
               source: sourceNodeId,
               target: newTargetNode.id,
               sourceHandle: clickedHandleId,
               type: 'customEdge',
               markerEnd: { type: MarkerType.ArrowClosed },
               data: { setEdges, setContentNodes, setLeftNodes, setClickedNodeId, reactFlowBounds },
            };
            setEdges((prevEdges) => [...prevEdges, newEdge]);

            // Check if sourceNodeType is "ask_question" or not
            const isAskQuestion = sourceNodeType === 'ask_question';
            const isHttpRequest = sourceNodeType === 'http_request';
            const isSendButtonMessage = sourceNodeType === 'send_message_buttons';
            const isWelcomeMessage = sourceNodeType === 'welcome_message';

            const blocks = nodeState.find((node) => node.id === sourceNodeId)?.data?.flowBlocks;
            const isQR = blocks.find(
               (block) =>
                  block._id === clickedHandleId.slice(0, -handleIndexToUpdate?.toString().length) &&
                  block.type === FlowBlockType.SendQuickReplyMessage,
            );
            const isInfBtn = blocks.find(
               (block) =>
                  block._id === clickedHandleId.slice(0, -handleIndexToUpdate?.toString().length) &&
                  block.type === FlowBlockType.SendInformativeButtonMessage,
            );

            if (LIST_NODE_TEMPLATE) {
               const templateId = LIST_NODE_TEMPLATE.find((item) => item.type === selectedNodeType)?._id;
               createFlowNode({
                  variables: {
                     input: {
                        coordinates: position,
                        customer: person.customer._id,
                        flowBot: flowBotId,
                        templateId: templateId,
                        title: nodeTitle,
                        groupId: newTargetNode.parentNode,
                     },
                  },
                  onCompleted: async (res) => {
                     if (isHttpRequest) {
                        handleUpdateFlowBlockHttpRequest(
                           clickedHandleId.slice(0, -1),
                           res.createFlowNode.data._id,
                           handleToUpdate,
                        );
                     } else if (isAskQuestion) {
                        handleUpdateFlowBlockAskQuestion(
                           clickedHandleId.slice(0, -1),
                           res.createFlowNode.data._id,
                           handleToUpdate,
                        );
                     } else if ((isSendButtonMessage && isQR) || isWelcomeMessage) {
                        await handleUpdateFlowBlockQuickReply(
                           clickedHandleId.slice(0, -handleIndexToUpdate.toString().length),
                           res.createFlowNode.data._id,
                           handleIndexToUpdate,
                        );

                        setBlockIdToUpdate(clickedHandleId.slice(0, -handleIndexToUpdate.toString().length));
                        setIsUpdateQR(true);
                     } else if (isSendButtonMessage && isInfBtn) {
                        await handleUpdateFlowBlockInfinitiveBtn(
                           clickedHandleId.slice(0, -handleIndexToUpdate.toString().length),
                           res.createFlowNode.data._id,
                           handleIndexToUpdate,
                        );

                        setBlockIdToUpdate(clickedHandleId.slice(0, -handleIndexToUpdate.toString().length));
                        setIsUpdateQR(true);
                     } else {
                        handleUpdateFlowBlock(clickedHandleId, res.createFlowNode.data._id);
                     }

                     setClickedNodeId(res.createFlowNode.data._id);
                     dispatch({ type: SET_CLICKED_NODE_ID, payload: res.createFlowNode.data._id });
                     setIsDraggable(isDraft);
                  },
               });
            }
         } else if (selectedNodeType === 'note' && LIST_NODE_TEMPLATE) {
            const templateId = LIST_NODE_TEMPLATE.find((item) => item.type === selectedNodeType)?._id;
            const newTargetNode = {
               id: 'random-' + generateRandomId(),
               type: selectedNodeType,
               title: nodeTitle,
               data: {
                  title: nodeTitle,
                  clickedNodeId,
                  flowBlocks: [],
                  setHandleToUpdate,
                  setIsDraggable,
                  setClickedNodeId,
                  setClickedHandleId,
                  isCreation: true,
               },
               expandParent: true,
               position: position,
               flowBot: flowBotId,
            };
            setNodeState((prevNodes) => [...prevNodes, newTargetNode]);
            setLeftNodes((prevNodes) => [...prevNodes, newTargetNode]);

            createFlowNode({
               variables: {
                  input: {
                     coordinates: position,
                     customer: person.customer._id,
                     flowBot: flowBotId,
                     templateId: templateId,
                     title: nodeTitle,
                  },
               },
               onCompleted: async (res) => {
                  setClickedNodeId(res.createFlowNode.data._id);
                  dispatch({ type: SET_CLICKED_NODE_ID, payload: res.createFlowNode.data._id });
                  setIsDraggable(isDraft);
                  setIsNewNode(generateRandomId());
               },
            });
         }
         setHandleToUpdate(null);
         setClickedHandleId(null);
         connectionCreated.current = false;
         setAllowClickPane(true);
      } catch (error) {
         console.error('Error creating node:', error);
      }
   };

   const handleConnectStart = (handleId, nodeId) => {
      if (clickedHandleId) {
         // Filter out the previous edge associated with the current source node
         setEdges((prevEdges) =>
            prevEdges.filter((edge) => !(edge.source === nodeId && edge.sourceHandle === clickedHandleId)),
         );
      }
      setSourceNodeId(nodeId);
      setIsUpdateQR(false);
   };

   const handlePaneClick = () => {
      setClickedNodeId('');
      if (allowClickPane) {
         const updatedNodes = nodeState.map((node) => ({
            ...node,
            data: {
               ...node.data,
               clickedNodeId: '',
            },
         }));
         setNodeState(updatedNodes);
         dispatch({ type: SET_CLICKED_NODE_ID, payload: '' });
      }
   };

   const onConnect = useCallback(
      async (connection) => {
         if (connection.source !== connection.target) {
            connectionCreated.current = true;

            const isAskQuestion = sourceNodeType === 'ask_question';
            const isHttpRequest = sourceNodeType === 'http_request';
            const isSendMessageButton = sourceNodeType === 'send_message_buttons';
            const isWelcomeMessage = sourceNodeType === 'welcome_message';

            const blocks = nodeState.find((node) => node.id === sourceNodeId)?.data?.flowBlocks;
            const isQR = blocks.find(
               (block) =>
                  block._id === clickedHandleId.slice(0, -handleIndexToUpdate?.toString().length) &&
                  block.type === FlowBlockType.SendQuickReplyMessage,
            );
            const isInfBtn = blocks.find(
               (block) =>
                  block._id === clickedHandleId.slice(0, -handleIndexToUpdate?.toString().length) &&
                  block.type === FlowBlockType.SendInformativeButtonMessage,
            );

            try {
               if (isAskQuestion) {
                  await handleUpdateFlowBlockAskQuestion(
                     clickedHandleId.slice(0, -1),
                     connection.target,
                     handleToUpdate,
                  );
               } else if (isHttpRequest) {
                  handleUpdateFlowBlockHttpRequest(clickedHandleId.slice(0, -1), connection.target, handleToUpdate);
               } else if ((isSendMessageButton && isQR) || isWelcomeMessage) {
                  await handleUpdateFlowBlockQuickReply(
                     clickedHandleId.slice(0, -handleIndexToUpdate.toString().length),
                     connection.target,
                     handleIndexToUpdate,
                  );
                  setBlockIdToUpdate(clickedHandleId.slice(0, -handleIndexToUpdate.toString().length));
                  setIsUpdateQR(true);
               } else if (isSendMessageButton && isInfBtn) {
                  await handleUpdateFlowBlockInfinitiveBtn(
                     clickedHandleId.slice(0, -handleIndexToUpdate.toString().length),
                     connection.target,
                     handleIndexToUpdate,
                  );
                  setBlockIdToUpdate(clickedHandleId.slice(0, -handleIndexToUpdate.toString().length));
                  setIsUpdateQR(true);
               } else {
                  handleUpdateFlowBlock(clickedHandleId, connection.target);
               }
            } catch (error) {
               console.error('Error updating flow block:', error);
            }

            setEdges((eds) =>
               addEdge(
                  {
                     ...connection,
                     type: 'customEdge',
                     markerEnd: { type: MarkerType.ArrowClosed },
                     data: { setEdges, setIsNewNode, setContentNodes, setLeftNodes, setClickedNodeId, reactFlowBounds },
                     sourceHandle: clickedHandleId,
                  },
                  eds,
               ),
            );

            setHandleToUpdate(null);
            setSourceNodeId(null);
            setClickedHandleId(null);
            setAllowClickPane(true);
         } else {
            connectionCreated.current = true;
            setIsNewNode(generateRandomId());
         }
      },
      [setEdges, edges, sourceNodeType, clickedHandleId],
   );

   const onConnectEnd = useCallback(
      (e) => {
         if (!connectionCreated.current) {
            setAllowClickPane(false);
            dispatch({ type: SET_CLICKED_NODE_ID, payload: '' });

            // Call Menu
            const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect();

            if (reactFlowBounds) {
               const position = reactFlowInstance.project({
                  x: e.clientX - reactFlowBounds.left - 5,
                  y: e.clientY - reactFlowBounds.top - 5,
               });

               if (sourceNodeId && clickedHandleId) {
                  const newTargetNode = {
                     id: generateRandomId(),
                     type: 'MenuNode',
                     data: { handleCreateNode, setIsDraggable, setIsNewNode, contentNodes },
                     position,
                  };

                  setNodeState((prevNodes) => [...prevNodes, newTargetNode]);
                  setIsDraggable(false);

                  const newEdge = {
                     id: generateRandomId(),
                     source: sourceNodeId,
                     target: newTargetNode.id,
                     sourceHandle: clickedHandleId,
                     type: 'default',
                     markerEnd: { type: MarkerType.ArrowClosed },
                     data: { setEdges },
                  };
                  setEdges((prevEdges) => [...prevEdges, newEdge]);
               }
            }
         }
         connectionCreated.current = false;
         setClickedHandleId(null);
      },
      [connectionCreated, sourceNodeId, clickedHandleId],
   );

   const onNodeDragStart = (event, node) => {
      setPrevPosition(node.position);
   };

   // Update nodes data in each custom node
   useEffect(() => {
      if (clickedNodeId) {
         const updatedNodes = nodeState.map((node) => ({
            ...node,
            data: {
               ...node.data,
               setIsNewNode: setIsNewNode,
               isNewNode: isNewNode,
               clickedNodeId: clickedNodeId,
               setIsDraggable: setIsDraggable,
               setContentNodes: setContentNodes,
               setClickedNodeId: setClickedNodeId,
            },
         }));

         setNodeState(updatedNodes);
      }
   }, [clickedNodeId]);

   useEffect(() => {
      const newEdges = [];

      contentNodes.forEach((node) => {
         const { id: source, data } = node;

         if (data && data.flowBlocks && data.flowBlocks.length > 0) {
            const { flowBlocks } = data;

            flowBlocks.forEach((block) => {
               const { target, _id: sourceHandle } = block;

               const isOnMatchBlock =
                  block.type === FlowBlockType.Condition && block.fields.some((field) => field.value === 'on_no_match');

               const isQRNotUsed =
                  node.type === FlowNodeType.SendButtonMessage &&
                  block?.type === FlowBlockType.SendMessageConnector &&
                  node.data.flowBlocks.some((block) => block.fields[0]?.isButtonRequired === false);

               if (target) {
                  newEdges.push({
                     id: 'edg-' + sourceHandle,
                     source,
                     target,
                     sourceHandle,
                     type: 'customEdge',
                     markerEnd: { type: MarkerType.ArrowClosed },
                     data: {
                        setEdges,
                        setIsNewNode,
                        setContentNodes,
                        reactFlowBounds,
                        setLeftNodes,
                        color: isOnMatchBlock ? '#FF5A80' : isQRNotUsed ? '#FEBC52' : '',
                        setBlocks,
                        blocks,
                        setClickedNodeId,
                     },
                  });
               }

               if (node.type === FlowNodeType.AskQuestion) {
                  block.fields.forEach((field, index) => {
                     if (field) {
                        let targetKey = null;

                        if (field.target_on_reply !== null) {
                           targetKey = field.target_on_reply;

                           newEdges.push({
                              id: 'edg-' + generateRandomId(),
                              source,
                              target: targetKey,
                              sourceHandle: `${block._id}${'1'}`,
                              type: 'customEdge',
                              markerEnd: { type: MarkerType.ArrowClosed },
                              data: {
                                 setEdges,
                                 setIsNewNode,
                                 reactFlowBounds,
                                 setContentNodes,
                                 setLeftNodes,
                                 color: '#147CFC',
                                 setClickedNodeId,
                              },
                           });
                        }
                        if (field.target_on_not_reply !== null) {
                           targetKey = field.target_on_not_reply;
                           newEdges.push({
                              id: 'edg-' + generateRandomId(),
                              source,
                              target: targetKey,
                              sourceHandle: `${block._id}${'2'}`,
                              type: 'customEdge',
                              markerEnd: { type: MarkerType.ArrowClosed },
                              data: {
                                 setEdges,
                                 setIsNewNode,
                                 reactFlowBounds,
                                 setContentNodes,
                                 setLeftNodes,
                                 color: '#FF5A80',
                                 setClickedNodeId,
                              },
                           });
                        }
                        if (field.target_on_invalid_input !== null) {
                           targetKey = field.target_on_invalid_input;
                           newEdges.push({
                              id: 'edg-' + generateRandomId(),
                              source,
                              target: targetKey,
                              sourceHandle: `${block._id}${'3'}`,
                              type: 'customEdge',
                              markerEnd: { type: MarkerType.ArrowClosed },
                              data: {
                                 setEdges,
                                 reactFlowBounds,
                                 setIsNewNode,
                                 setContentNodes,
                                 setLeftNodes,
                                 color: '#FEBC52',
                                 setClickedNodeId,
                              },
                           });
                        }
                     }
                  });
               }
               if (node.type === FlowNodeType.HttpRequest) {
                  block.fields.forEach((field, index) => {
                     if (field) {
                        let targetKey = null;

                        if (field.targetOnSuccess !== null) {
                           targetKey = field.targetOnSuccess;

                           newEdges.push({
                              id: 'edg-' + generateRandomId(),
                              source,
                              target: targetKey,
                              sourceHandle: `${block._id}${'1'}`,
                              type: 'customEdge',
                              markerEnd: { type: MarkerType.ArrowClosed },
                              data: {
                                 setEdges,
                                 setIsNewNode,
                                 setContentNodes,
                                 reactFlowBounds,
                                 setLeftNodes,
                                 color: '#147CFC',
                                 setClickedNodeId,
                              },
                           });
                        }
                        if (field.targetOnFailure !== null) {
                           targetKey = field.targetOnFailure;
                           newEdges.push({
                              id: 'edg-' + generateRandomId(),
                              source,
                              target: targetKey,
                              sourceHandle: `${block._id}${'2'}`,
                              type: 'customEdge',
                              markerEnd: { type: MarkerType.ArrowClosed },
                              data: {
                                 setEdges,
                                 setIsNewNode,
                                 setContentNodes,
                                 reactFlowBounds,
                                 setLeftNodes,
                                 color: '#FF5A80',
                                 setClickedNodeId,
                              },
                           });
                        }
                     }
                  });
               }

               if (node.type === FlowNodeType.WelcomeMessage && block.type === 'send_quick_reply_message') {
                  block.fields.forEach((field, index) => {
                     if (field) {
                        field.quickReplies.forEach((reply, index) => {
                           let targetKey = null;

                           if (reply.target !== null) {
                              targetKey = reply.target;

                              newEdges.push({
                                 id: 'edg-' + generateRandomId(),
                                 source,
                                 target: targetKey,
                                 sourceHandle: `${block._id}${index}`,
                                 type: 'customEdge',
                                 markerEnd: { type: MarkerType.ArrowClosed },
                                 data: {
                                    setEdges,
                                    setIsNewNode,
                                    setContentNodes,
                                    setLeftNodes,
                                    reactFlowBounds,
                                    color: '#147CFC',
                                    setBlocks,
                                    blocks,
                                    setClickedNodeId,
                                 },
                              });
                           }
                        });
                     }
                  });
               }
               if (node.type === FlowNodeType.SendButtonMessage) {
                  block.fields.forEach((field, index) => {
                     if (field && field.informativeButtons) {
                        field.informativeButtons.forEach((reply, index) => {
                           let targetKey = null;

                           if (reply.target !== null) {
                              targetKey = reply.target;

                              newEdges.push({
                                 id: 'edg-' + generateRandomId(),
                                 source,
                                 target: targetKey,
                                 sourceHandle: `${block._id}${index}`,
                                 type: 'customEdge',
                                 markerEnd: { type: MarkerType.ArrowClosed },
                                 data: {
                                    setEdges,
                                    setIsNewNode,
                                    setContentNodes,
                                    setLeftNodes,
                                    reactFlowBounds,
                                    setBlocks,
                                    blocks,
                                    setClickedNodeId,
                                 },
                              });
                           }
                        });
                     }
                     if (field && field.quickReplies) {
                        field.quickReplies.forEach((reply, index) => {
                           let targetKey = null;

                           if (reply.target !== null && !reply.hideTheEdge) {
                              targetKey = reply.target;

                              newEdges.push({
                                 id: 'edg-' + generateRandomId(),
                                 source,
                                 target: targetKey,
                                 sourceHandle: `${block._id}${index}`,
                                 type: 'customEdge',
                                 markerEnd: { type: MarkerType.ArrowClosed },
                                 data: {
                                    setEdges,
                                    setIsNewNode,
                                    setContentNodes,
                                    setLeftNodes,
                                    reactFlowBounds,
                                    color: '#147CFC',
                                    setBlocks,
                                    setClickedNodeId,
                                    blocks,
                                 },
                              });
                           }
                        });
                     }
                  });
               }
            });
         }
      });

      const isMenuNode = contentNodes.some((item) => item.type === 'MenuNode');
      if (!isMenuNode) {
         setEdges(newEdges);
      }
   }, [contentNodes]);

   useEffect(() => {
      setIsDraggable(isDraft);
   }, [isDraft]);

   useEffect(() => {
      setIsDraggable(!hasOperations);
   }, [hasOperations]);

   const [isGroup, setIsGroup] = useState(false);
   useEffect(() => {
      setIsGroup(clickedId.startsWith('group_'));
   }, [clickedId]);

   const [isSBVisible, setIsSBVisible] = useState(false);
   const selectedNodes = nodeState.filter(
      (node) =>
         node.selected &&
         !node.parentNode &&
         node.type !== FlowNodeType.Triggers &&
         node.type !== FlowNodeType.WelcomeMessage,
   );
   const selectedNodeIds = selectedNodes.map((node) => node.id);
   const isVisible = selectedNodeIds.length > 1 || isGroup;

   useEffect(() => {
      setIsSBVisible(isVisible);
      setIsSideBarVisible(isVisible);
   }, [isVisible]);

   const [groupFound, setGroupFound] = useState(false);
   useEffect(() => {
      setGroupFound(contentNodes.some((node) => node.type === 'group'));
   }, [contentNodes]);

   //Create group by adding a node inside an exixtiing
   useEffect(() => {
      if (toGroup.length > 0) {
         const filteredNodes = nodeState.filter((node) => toGroup.includes(node.id));

         const NewNodes = onCreateGroups(nodeState, filteredNodes);
         setContentNodes(NewNodes);
         setLeftNodes(NewNodes);
         const groupId = NewNodes.find((nn) => nn.id === filteredNodes[0].id)?.parentNode;
         toGroup.forEach((sn) => handleUpdateFlowNodeGroup(sn, groupId));
      }
   }, [toGroup]);

   const [isToldBarClose, setIsToldBarClose] = useState(false);
   useEffect(() => {
      if (isToldBarClose) {
         handlePaneClick();
      }
   }, [isToldBarClose]);

   const handleCreateNoteNode = (e) => {
      const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect();

      if (reactFlowBounds) {
         const screenWidth = window.innerWidth;

         const largeScreenWidth = 1600;

         const xOffsetPercentage = 900 / largeScreenWidth;
         const yOffsetPercentage = 150 / largeScreenWidth;

         const xOffset = xOffsetPercentage * screenWidth;
         const yOffset = yOffsetPercentage * screenWidth;

         const position = reactFlowInstance.project({
            x: e.clientX - reactFlowBounds.left - xOffset,
            y: e.clientY - reactFlowBounds.top + yOffset,
         });
         handleCreateNode('note', t.automation_note, e, position);
      }
   };

   return (
      <div style={customStyle} ref={reactFlowWrapper} className={styles.wrapper}>
         <ReactFlowProvider>
            <ReactFlow
               nodes={nodeState}
               edges={edges}
               onNodesChange={onNodesChange}
               onEdgesChange={onEdgesChange}
               onConnect={onConnect}
               onConnectEnd={onConnectEnd}
               onClickConnectEnd={() => setAllowClickPane(true)}
               proOptions={proOptions}
               fitView
               fitViewOptions={fitViewOptions}
               defaultEdgeOptions={defaultEdgeOptions}
               elevateEdgesOnSelect
               elevateNodesOnSelect
               nodeTypes={nodeTypes}
               edgeTypes={edgeTypes}
               onPaneClick={handlePaneClick}
               onInit={setReactFlowInstance}
               onNodeDragStop={onNodeDragStop}
               onNodeDragStart={onNodeDragStart}
               nodeDragThreshold={1}
               nodesDraggable={isDraggable}
               onConnectStart={(event, { handleId, nodeId }) => handleConnectStart(handleId, nodeId)}
               edgesUpdatable={isDraft}
               edgesFocusable={isDraft}
               nodesConnectable={isDraft}
               nodesFocusable={isDraft}
            >
               {isDraft ? (
                  <Panel
                     style={{
                        position: 'absolute',
                        right: 0,
                        marginTop: '8rem',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                     }}
                  >
                     {!groupFound && (
                        <div className={classes.btnPanel}>
                           <Layout nodeState={nodeState} handleUpdateFlowNode={handleUpdateFlowNode} />
                        </div>
                     )}
                     <div className={classes.btnPanelNote} onClick={handleCreateNoteNode}>
                        <NoteIcon style={{ width: '20px', height: '20px', margin: '4px 2px 0 0' }} />
                     </div>
                  </Panel>
               ) : (
                  <Panel position='top-center' className={classes.btnPanelInfo}>
                     <span>{t.automation_flow_viewing_publish}</span>
                  </Panel>
               )}
               <Controls position='top-right' showInteractive={isDraft} />
               {(isGroup || isSBVisible) && (
                  <Panel style={{ height: '100%' }}>
                     <SideBar
                        handlePaneClick={handlePaneClick}
                        nodestate={nodeState}
                        setContentNodes={setContentNodes}
                        setLeftNodes={setLeftNodes}
                        setToGroup={setToGroup}
                        setIsToldBarClose={setIsToldBarClose}
                     />
                  </Panel>
               )}
               <HelperLines horizontal={helperLineHorizontal} vertical={helperLineVertical} />
            </ReactFlow>
         </ReactFlowProvider>
      </div>
   );
};

export default Flow;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Flow from './App';
import { Node, ReactFlowProvider } from 'reactflow';
import { IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';

const useStyles = makeStyles(() => ({
   formContainer: {
      flexGrow: 1,
      overflowY: 'auto',
   },
}));

interface ContentPanelProps {
   setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   clickedHandleId: string;
   isNewNode: string;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
   clickedNodeId: string;
   contentNodes: Node[];
   setHandleToUpdate: React.Dispatch<React.SetStateAction<string | null>>;
   handleToUpdate: string | null;
   handleIndexToUpdate: Number | null;
   isDraggable: boolean;
   setIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
   blockState: IFlowBlockTemplate[];
   setBlockState: React.Dispatch<React.SetStateAction<IFlowBlockTemplate[]>>;
   toGroup: string[];
   isDraft: boolean;
   setIsDraft: React.Dispatch<React.SetStateAction<boolean>>;
   setIsSideBarVisible: React.Dispatch<React.SetStateAction<boolean>>;
   setToGroup: React.Dispatch<React.SetStateAction<string[]>>;
}

const ContentPanel: React.FC<ContentPanelProps> = ({
   handleToUpdate,
   handleIndexToUpdate,
   setHandleToUpdate,
   contentNodes,
   setLeftNodes,
   clickedNodeId,
   setClickedNodeId,
   clickedHandleId,
   setClickedHandleId,
   setIsNewNode,
   isDraggable,
   setIsDraggable,
   blockState,
   setBlockState,
   isDraft,
   setIsDraft,
   setContentNodes,
   setIsSideBarVisible,
   isNewNode,
   setToGroup,
   toGroup,
}) => {
   const classes = useStyles();
   return (
      <div className={classes.formContainer}>
         <ReactFlowProvider>
            <Flow
               contentNodes={contentNodes}
               setContentNodes={setContentNodes}
               isNewNode={isNewNode}
               clickedNodeId={clickedNodeId}
               setClickedNodeId={setClickedNodeId}
               clickedHandleId={clickedHandleId}
               setClickedHandleId={setClickedHandleId}
               setIsNewNode={setIsNewNode}
               handleToUpdate={handleToUpdate}
               handleIndexToUpdate={handleIndexToUpdate}
               setHandleToUpdate={setHandleToUpdate}
               isDraggable={isDraggable}
               setIsDraggable={setIsDraggable}
               blockState={blockState}
               setBlockState={setBlockState}
               isDraft={isDraft}
               setIsDraft={setIsDraft}
               setLeftNodes={setLeftNodes}
               setIsSideBarVisible={setIsSideBarVisible}
               setToGroup={setToGroup}
               toGroup={toGroup}
            />
         </ReactFlowProvider>
      </div>
   );
};

export default ContentPanel;

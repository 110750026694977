import React, { useState, useContext, Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { Grid, Typography, Box, Button, FormHelperText } from '@material-ui/core';
import Copy from './assets/Copy';
import ActiveDot from './assets/ActiveDot';
import Dot from './assets/Dot';
import clsx from 'clsx';
import BtnActions from './components/NextBackBtn';
import { GET_PRODUCTS_QUERY } from '../../queries/Products/Service/GraphQL/query';
import { BUY_CUSTOMER_PRODUCT_MUTATION } from '../../queries/Customer/Service/GraphQL/CustomerProduct/mutation';
import { useMutation, useQuery } from '@apollo/client';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import publicIp from 'public-ip';
import { LanguageContext } from '@helper/locale/langContext';
import { cc_expires_format, normalizeCardNumber } from './utils/validation';
import { useCoupon } from '../../hooks/useCoupon';
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_ME } from '@store/actions/person';
import { priceFormat } from '@helper/functions';
import moment from 'moment';
import { RenewalType } from '@types/enums';

const useStyles = makeStyles((theme) => ({
   card: {
      border: '1px solid #E2E2E2',
      borderRadius: '8px',
      padding: '1rem 1rem',
      marginBottom: '1rem',
      minHeight: 250,
   },
   cardItem: {
      margin: '.6rem 0px',
      display: 'flex',
      alignItems: 'center',
   },
   cardItemText: {
      fontSize: '.85rem',
      color: '#315276',
      fontWeight: 500,
   },
   main: {
      borderRadius: '8px',
      width: '100%',
      background: '#FFFFFF',
      boxShadow: '0px 1px 3px #00000029',
      // maxHeight: '500px',
   },
   title: {
      color: '#72889D',
      fontSize: '1.1rem',
   },
   ml: {
      marginLeft: '2rem',
      fontSize: '1.5rem',
   },
   header: {
      padding: '1.5rem 2rem',
      borderBottom: '1px solid #DEDEDE',
      alignItems: 'center',
      justifyContent: 'space-between',
      display: 'flex',
   },
   container: {
      padding: '1rem',
      width: '100%',
      margin: 'auto',
   },
   priceTexts: {
      display: 'flex',
      justifyContent: 'flex-end',
   },
   inputContainer: {
      border: '1px solid #E2E2E2',
      borderRadius: '8px',
      padding: '.25rem .5rem',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 300,
   },
   inputBase: {
      flex: 1,
      border: 'none',
      padding: '.5rem',
      marginLeft: '.3rem',
      fontWeight: 300,
      '&:focus': {
         border: 'none',
         outline: 'none',
      },
      '&::placeholder': {
         fontWeight: 300,
         color: '#315276',
         opacity: '0.37',
         verticalAlign: 'center',
      },
   },
   centerTextIcon: {
      display: 'flex',
      alignItems: 'center',
      color: '#485A6F',
      fontSize: '.85rem',
   },
   couponText: {
      color: '#178CF9',
   },
   cardHeaderTitle: {
      color: '#315276',
      fontSize: '1rem',
      paddingBottom: '1rem',
   },
   saveCardText: {
      paddingLeft: '1rem',
      color: '#485A6F',
      fontWeight: '400',
      fontSize: '.85rem',
   },
   preferenceStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '8px',
   },
   errorText: {
      color: 'red',
      fontSize: '12px',
   },
   mlp5: {
      marginLeft: '.5rem',
   },
   checkBox: {
      margin: 0,
      padding: 0,
      '&:hover': {
         cursor: 'pointer',
      },
   },
   colorRed: {
      color: 'red',
   },
   pleft9rem: {
      paddingLeft: '9rem',
   },
   cardLabel: {
      color: '#ccc',
   },
}));

export const CheckBox = ({ selected, setSelected }) => {
   const classes = useStyles();
   return (
      <Box className={classes.checkBox} onClick={setSelected}>
         {selected ? <ActiveDot /> : <Dot />}
      </Box>
   );
};

const getProductId = (products, packageName) => {
   const foundProduct = products.find((product) => product.name.toLowerCase() === packageName.toLowerCase());

   return foundProduct._id;
};

const Payment = ({ nextPageHandler, prevPageHandler, checkIfSuccess, packageOptions }) => {
   const classes = useStyles();
   const [storeCard, setStoreCard] = useState(true);

   const [buyProduct, { loading: buyProductLoading }] = useMutation(BUY_CUSTOMER_PRODUCT_MUTATION);
   packageOptions = packageOptions || JSON.parse(window.localStorage.getItem('packageOptions'));
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const dispatch = useDispatch();
   const customer = person.customer;
   const { data: productsData } = useQuery(GET_PRODUCTS_QUERY, {
      fetchPolicy: 'cache-first',
      variables: {
         customerId: customer._id,
      },
   });

   const {
      register,
      handleSubmit,
      watch,
      setValue,
      formState: { errors, isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         users: 0,
      },
   });

   const [couponState, { loading: couponLoading, getCouponCode }] = useCoupon(
      packageOptions.price * watch('users') || 0,
   );

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const onSubmit = async (data) => {
      const ip = await publicIp.v4({
         fallbackUrls: ['https://ifconfig.co/ip'],
      });

      const cardData = {
         cardHolderName: person.customer.hasFreeTrial ? undefined : data.cardHolderName,
         cardNumber: person.customer.hasFreeTrial ? undefined : data.cardNumber.split(/\s+/).join(''),
         expireMonth: person.customer.hasFreeTrial ? undefined : data.date.split('/')[0],
         expireYear: person.customer.hasFreeTrial ? undefined : data.date.split('/')[1],
         cvc: person.customer.hasFreeTrial ? undefined : data.cvc,
         registerCard: person.customer.hasFreeTrial ? undefined : storeCard ? 1 : 0,
      };

      const getShopifySession = localStorage.getItem('shopifySession');
      buyProduct({
         variables: {
            customer: customer._id,
            product: getProductId(productsData.products.data, packageOptions.name),
            person: person._id,
            code: couponState.couponCodeText,
            users: data.users,
            ip,
            mode: packageOptions.mode === RenewalType.YEARLY ? RenewalType.YEARLY : RenewalType.MONTHLY,
            cardAlias: data.cardHolderName,
            ...(couponState.isPaying && !person.customer.hasFreeTrial && cardData),
            ...(!!getShopifySession && {
               session: { ...JSON.parse(getShopifySession), type: 'SHOPIFY' },
            }),
         },
      })
         .then(
            ({
               data: {
                  buyCustomerProduct: { data },
               },
            }) => {
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        customer: {
                           ...data,
                           isANewCustomer: true,
                        },
                     },
                  },
               });

               checkIfSuccess({
                  isSuccess: true,
                  message: '',
                  isFreeTrial: person.customer.hasFreeTrial,
               });
               nextPageHandler();
            },
         )
         .catch((err) => {
            checkIfSuccess({
               isSuccess: false,
               message: err.message,
               isFreeTrial: person.customer.hasFreeTrial,
            });
            nextPageHandler();
         });
   };

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <Box className={classes.main}>
            <Grid container className={classes.header}>
               <Typography className={classes.title}>{t?.welcome_your_free_14_day_trial}</Typography>
            </Grid>
            <Box style={{ textAlign: 'center', padding: '.5rem' }}>
               <span role='img' aria-label='emoji' style={{ fontSize: '4rem' }}>
                  😎
               </span>
               <Typography className={classes.cardItemText} style={{ fontWeight: 600, paddingBottom: '.5rem' }}>
                  {t?.welcome_no_credit_card_required}
               </Typography>
               <Typography className={classes.cardItemText}>
                  {/* // TODO */}
                  {`14 ${t?.days} 
                  ${t?.welcome_try_for}. ${t?.welcome_make_payment_if_you_want_continue}`}
               </Typography>
            </Box>

            <Grid container spacing={3} className={classes.container}>
               <Grid item md={6}>
                  <Box className={classes.card}>
                     <Grid container>
                        <Grid item xs={6} className={classes.cardItem}>
                           <Typography className={classes.cardItemText}>
                              {' '}
                              {packageOptions.name} /{' '}
                              {packageOptions.mode === RenewalType.YEARLY ? t?.yearly : t?.monthly}
                           </Typography>
                        </Grid>
                        <Grid item xs={6} className={clsx(classes.cardItem, classes.priceTexts)}>
                           <Typography className={classes.cardItemText}>
                              {packageOptions.mode === RenewalType.YEARLY
                                 ? `12 * ${priceFormat(packageOptions.price / 12)}`
                                 : priceFormat(packageOptions.price)}
                           </Typography>
                        </Grid>
                        <Grid item xs={6} className={classes.cardItem}>
                           <Typography className={classes.cardItemText}>{t?.users}</Typography>
                        </Grid>
                        <Grid item xs={6} className={clsx(classes.cardItem, classes.priceTexts)}>
                           <Select
                              labelId='demo-simple-select-standard-label'
                              disableUnderline
                              displayEmpty
                              className={classes.cardItemText}
                              name='users'
                              {...register('users', {
                                 required: true,
                                 validate: (val) => val > 0,
                              })}
                           >
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={2}>2</MenuItem>
                              <MenuItem value={3}>3</MenuItem>
                              <MenuItem value={4}>4</MenuItem>
                              <MenuItem value={5}>5</MenuItem>
                              <MenuItem value={6}>6</MenuItem>
                              <MenuItem value={7}>7</MenuItem>
                              <MenuItem value={8}>8</MenuItem>
                              <MenuItem value={9}>9</MenuItem>
                              <MenuItem value={10}>10</MenuItem>
                           </Select>
                           {watch('users') === 0 && (
                              <FormHelperText style={{ color: '#157cfc' }}>
                                 {t?.welcome_please_select_count_of_users}
                              </FormHelperText>
                           )}
                        </Grid>
                        <Grid item xs={6} className={classes.cardItem}>
                           <Typography className={classes.cardItemText}>{t?.tax_included}</Typography>
                        </Grid>
                        <Grid item xs={6} className={clsx(classes.cardItem, classes.priceTexts)}>
                           <Typography className={classes.cardItemText}>
                              {packageOptions.mode === RenewalType.YEARLY
                                 ? `12 * (${watch('users')} * ${priceFormat(
                                      packageOptions.price / 12,
                                   )}) * 0.18 = ${priceFormat((couponState.totalPrice / 100) * 18)}`
                                 : priceFormat((couponState.totalPrice / 100) * 18)}
                           </Typography>
                        </Grid>
                        {couponState.couponStatus ||
                           (person.customer.hasFreeTrial && (
                              <Fragment>
                                 <Grid item xs={6} className={classes.cardItem}>
                                    <Typography className={classes.cardItemText}>
                                       {' '}
                                       {t?.discount}
                                       {couponState.couponCodeText && (
                                          <span className={classes.couponText}>
                                             {': ' + couponState.couponCodeText}
                                          </span>
                                       )}
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={6} className={clsx(classes.cardItem, classes.priceTexts)}>
                                    <Typography className={classes.cardItemText}>
                                       {' '}
                                       ${couponState.couponDiscount}{' '}
                                    </Typography>
                                 </Grid>
                              </Fragment>
                           ))}
                        <Grid item xs={12} style={{ borderBottom: '1px solid #E2E2E2' }} />
                        <Grid item xs={6} className={classes.cardItem}>
                           <Typography className={classes.cardItemText}>{t?.total}</Typography>
                        </Grid>
                        <Grid item xs={6} className={clsx(classes.cardItem, classes.priceTexts)}>
                           <Typography className={classes.cardItemText}>
                              {' '}
                              {priceFormat(couponState.totalPrice)}
                           </Typography>
                        </Grid>
                     </Grid>
                  </Box>
                  {!person.customer.hasFreeTrial && (
                     <Grid container spacing={3}>
                        <Grid item xs={12}>
                           {couponLoading && <div className='loader' />}
                           <Box className={classes.inputContainer}>
                              <Typography> {t?.coupon_code} </Typography>
                              <input
                                 className={classes.inputBase}
                                 placeholder={t?.enter_the_code}
                                 maxLength={11}
                                 {...register('code')}
                              />
                              {watch('code') && watch('code').trim().length === 11 && (
                                 <Button
                                    onClick={() => {
                                       getCouponCode({
                                          variables: { code: watch('code').trim().toUpperCase() },
                                       });
                                    }}
                                    color='secondary'
                                 >
                                    {t?.apply_coupon}
                                 </Button>
                              )}
                           </Box>
                           <span style={{ color: 'red' }}> {couponState.couponErrorMessage} </span>
                        </Grid>
                        <Grid item xs={12}>
                           <Typography className={classes.centerTextIcon}>
                              <Copy /> &nbsp;&nbsp; {t?.if_you_want_a_coupon}
                           </Typography>
                        </Grid>
                     </Grid>
                  )}
               </Grid>

               {person.customer.hasFreeTrial && (
                  <Grid item md={6}>
                     <Box className={classes.card}>
                        <Grid container>
                           <Grid item xs={6} className={classes.cardItem}>
                              <Typography className={classes.cardItemText}>
                                 {t?.welcome_payment_your_package}
                              </Typography>
                           </Grid>
                           <Grid item xs={6} className={clsx(classes.cardItem, classes.priceTexts)}>
                              <Typography className={classes.cardItemText} style={{ fontWeight: 'bold' }}>
                                 {packageOptions.name} /{' '}
                                 {packageOptions.mode === RenewalType.YEARLY ? t?.yearly : t?.monthly}
                              </Typography>
                           </Grid>
                           <Grid item xs={6} className={classes.cardItem}>
                              <Typography className={classes.cardItemText}>{t?.welcome_payment_user_count}</Typography>
                           </Grid>
                           <Grid item xs={6} className={clsx(classes.cardItem, classes.priceTexts)}>
                              <Typography className={classes.cardItemText} style={{ fontWeight: 'bold' }}>
                                 {watch('users') || 0}
                              </Typography>
                           </Grid>
                           <Grid item xs={6} className={classes.cardItem}>
                              <Typography className={classes.cardItemText}>
                                 {t?.welcome_payment_total_payment}
                              </Typography>
                           </Grid>
                           <Grid item xs={6} className={clsx(classes.cardItem, classes.priceTexts)}>
                              <Typography className={classes.cardItemText} style={{ fontWeight: 'bold' }}>
                                 {priceFormat(couponState.totalPrice)}
                              </Typography>
                           </Grid>
                           <Grid item xs={6} className={classes.cardItem}>
                              <Typography className={classes.cardItemText}>
                                 {t?.welcome_payment_you_have_free_trial}
                              </Typography>
                           </Grid>
                           <Grid item xs={6} className={clsx(classes.cardItem, classes.priceTexts)}>
                              <Typography className={classes.cardItemText} style={{ fontWeight: 'bold' }}>
                                 {`14 ${t?.days}`}
                              </Typography>
                           </Grid>
                           <Grid item xs={6} className={classes.cardItem}>
                              <Typography className={classes.cardItemText}>
                                 {t?.welcome_payment_your_first_payment_will_start}
                              </Typography>
                           </Grid>
                           <Grid item xs={6} className={clsx(classes.cardItem, classes.priceTexts)}>
                              <Typography className={classes.cardItemText} style={{ fontWeight: 'bold' }}>
                                 {moment(new Date()).add(14, 'day').format('DD.MM.YYYY')}
                              </Typography>
                           </Grid>
                        </Grid>
                     </Box>
                  </Grid>
               )}

               {couponState.isPaying && !person.customer.hasFreeTrial && (
                  <Grid item md={6}>
                     <Typography className={classes.cardHeaderTitle}>
                        <span> {t?.enter_card_details} </span>
                     </Typography>
                     <Grid container spacing={3}>
                        <Grid item xs={12}>
                           <Box
                              className={classes.inputContainer}
                              style={{
                                 borderColor: errors.cardHolderName ? 'red' : undefined,
                              }}
                           >
                              <Typography className={classes.cardLabel}>
                                 {' '}
                                 {t?.card_holder} <span className={classes.colorRed}> * </span>{' '}
                              </Typography>
                              <input
                                 className={clsx(classes.inputBase, classes.pleft9rem)}
                                 placeholder='John Doe'
                                 {...register('cardHolderName', {
                                    required: t?.card_holder_name_is_required,
                                    pattern: {
                                       value: /^((?:[A-Za-z]+ ?){1,3})$/,
                                       message: t?.invalid_card_holder_name,
                                    },
                                 })}
                              />
                           </Box>
                           {errors.cardHolderName && (
                              <span className={classes.errorText}>{errors.cardHolderName.message}</span>
                           )}
                        </Grid>
                        <Grid item xs={12}>
                           <Box
                              className={classes.inputContainer}
                              style={{ borderColor: errors.cardNumber ? 'red' : undefined }}
                           >
                              <Typography className={classes.cardLabel}>
                                 {' '}
                                 {t?.card_number} <span className={classes.colorRed}> * </span>{' '}
                              </Typography>
                              <input
                                 className={clsx(classes.inputBase, classes.pleft9rem)}
                                 placeholder='****&#9;&#9;****&#9;&#9;****&#9;&#9;****'
                                 type='tel'
                                 inputMode='numeric'
                                 autoComplete='cc-number'
                                 {...register('cardNumber', {
                                    required: t?.card_number_is_required,
                                    validate: (value) => {
                                       if (value) {
                                          setValue('cardNumber', normalizeCardNumber(value));
                                       }
                                    },
                                 })}
                              />
                           </Box>
                           {errors.cardNumber && <span className={classes.errorText}>{errors.cardNumber.message}</span>}
                        </Grid>
                        <Grid item xs={12}>
                           <Grid container>
                              <Grid xs={6}>
                                 <Box
                                    className={classes.inputContainer}
                                    style={{
                                       borderColor: errors.date ? 'red' : undefined,
                                       marginRight: '.5rem',
                                    }}
                                 >
                                    <Typography className={classes.cardLabel}>
                                       {' '}
                                       {t?.date} <span className={classes.colorRed}> * </span>{' '}
                                    </Typography>
                                    <input
                                       className={classes.inputBase}
                                       {...register('date', {
                                          required: t?.expiry_date_is_required,
                                          validate: (value) => {
                                             setValue('date', cc_expires_format(value));
                                          },
                                       })}
                                    />
                                 </Box>
                                 {errors.date && <span className={classes.errorText}>{errors.date.message}</span>}
                              </Grid>
                              <Grid xs={6}>
                                 <Box
                                    className={classes.inputContainer}
                                    style={{
                                       marginLeft: '.5rem',
                                       borderColor: errors.cvc ? 'red' : undefined,
                                    }}
                                 >
                                    <Typography className={classes.cardLabel}>
                                       {' '}
                                       {t?.cvc} <span className={classes.colorRed}> * </span>{' '}
                                    </Typography>
                                    <input
                                       className={classes.inputBase}
                                       // type="number"
                                       // max={999}
                                       maxLength={3}
                                       {...register('cvc', {
                                          required: t?.cvv_is_required,
                                          validate: (value) => {
                                             const newValue = value.replace(/[^0-9]/gi, '');
                                             setValue('cvc', newValue);
                                          },
                                       })}
                                    />
                                 </Box>
                                 {errors.cvc && (
                                    <span className={clsx(classes.errorText, classes.mlp5)}>{errors.cvc.message}</span>
                                 )}
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid item xs={12}>
                           <Typography className={classes.preferenceStyle}>
                              <CheckBox
                                 selected={storeCard}
                                 setSelected={() => {
                                    setStoreCard((prev) => !prev);
                                 }}
                              />
                              {t?.iyzico_secure_payment}
                           </Typography>
                        </Grid>
                     </Grid>
                  </Grid>
               )}
            </Grid>
         </Box>
         <BtnActions
            isValid={isValid || !couponState.isPaying}
            // nextBtnText={t?.make_payment}
            nextBtnType='submit'
            prevPageHandler={prevPageHandler}
            loading={buyProductLoading}
         />
      </form>
   );
};

export default Payment;

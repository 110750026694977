import React, { useRef, useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Swiper from 'react-id-swiper';
import { LanguageContext } from '@helper/locale/langContext';
import 'swiper/swiper.min.css'
import { ButtonBase, CircularProgress } from '@material-ui/core';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import classNames from 'classnames';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CardActions from '@material-ui/core/CardActions';
import Link from '@material-ui/core/Link';
import Card from '@material-ui/core/Card';
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import withWidth from '@material-ui/core/withWidth';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import { useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import { RE_SEND_MESSAGE_MUTATION } from '@queries/Chat/Service/GraphQL/Message/mutation';
import Modal from '@components/Modal/modal';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';

const CARD_WIDTH = 200;
const CARD_LIST_GAP = 8;

const MAX_WIDTH_FOR_THREE_ITEM = 3 * (CARD_WIDTH + CARD_LIST_GAP);
const MAX_WIDTH_FOR_TWO_ITEM = 2 * (CARD_WIDTH + CARD_LIST_GAP);
const MAX_WIDTH_FOR_ONE_ITEM = CARD_WIDTH + CARD_LIST_GAP;
const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
      marginTop: 4,
      marginBottom: 4,
      paddingRight: 20 - CARD_LIST_GAP,
      marginRight: 20,
   },
   productList: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.only('xs')]: {
         maxWidth: MAX_WIDTH_FOR_ONE_ITEM,
      },
      [theme.breakpoints.between('sm', 'md')]: {
         maxWidth: MAX_WIDTH_FOR_TWO_ITEM,
      },
      [theme.breakpoints.up('lg')]: {
         maxWidth: MAX_WIDTH_FOR_THREE_ITEM,
      },
      overflow: 'visible',
      position: 'relative',
   },
   swiperButton: {
      position: 'absolute',
      borderRadius: '13px',
      border: '1px solid rgba(225, 234, 242, 0.73)',
      backgroundColor: 'rgba(255, 255, 255, 0.73)',
      height: '26px',
      width: '26px',
      top: 'calc(50% - 13px)',
      zIndex: 2,
   },
   swiperButtonLeft: {
      left: 13,
   },
   swiperButtonRight: {
      right: 13 + CARD_LIST_GAP,
   },
   chevronIcon: {
      fontSize: '20px',
   },
   card: {
      display: 'flex',
      flexDirection: 'column',
      width: CARD_WIDTH,
      minWidth: CARD_WIDTH,
      maxWidth: CARD_WIDTH,
      borderRadius: 10,
      border: '1px solid #F2F7FF',
      flexShrink: 0,
      boxSizing: 'border-box',
   },
   media: {
      width: '100%',
      height: CARD_WIDTH,
      display: 'block',
      objectFit: 'cover',
   },
   defaultMediaIcon: {
      color: '#999999',
      height: CARD_WIDTH,
      fontSize: CARD_WIDTH,
   },
   cardContent: {
      flexGrow: 1,
      backgroundColor: '#F7F7FA',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'column',
      padding: 8,
      '&:last-child': {
         paddingBottom: 8,
      },
   },
   cardActions: {
      flexDirection: 'column',
      alignItems: 'stretch',
      gap: 8,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
   },
   customLink: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FFF',
      margin: 0,
      padding: '0 10px',
      borderRadius: 5,
      height: 30,
      textDecoration: 'none',
      fontWeight: '500',
      color: '#2F2F2F',
      '&:hover': {
         textDecoration: 'none',
      },
      '&:not(:first-child)': {
         marginLeft: 0,
      },
   },
   titleContainer: {
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box !important',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      whiteSpace: 'normal',
   },
   messageAck: {
      position: 'absolute',
      // left: '-25px',
      left: '-25px',
      height: '100%',
      zIndex: 2,
      top: 4,
      justifyContent: 'center',
      display: 'flex',
      color: '#848484',
      // paddingLeft: 7px;
      alignItems: 'end',
   },
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 436,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalText: {
      color: '#797575',
      fontSize: '1rem',
      margin: 20,
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      borderTop: '1px solid #F1F3F7',
   },
}));

const ProductCards = ({ elements, isMine, width, messageObj = null, ack }) => {
   const classes = useStyles({ isMine, width });
   const [showDoubleThick, setShowDoubleThick] = useState(false);
   const [isResending, setIsResending] = useState(false);
   const [changedAckStatus, setChangedAckStatus] = useState(false);
   const [showFailedRepliedModal, setShowFailedRepliedModal] = useState(false);
   const sliderRef = useRef(null);
   let numberOfItemsToShow;
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const activeChat = useSelector((rootReducer) => rootReducer.chatReducer.activeChat);
   const [reSendMessageMutation, { loading: reSendMessageLoading }] = useMutation(RE_SEND_MESSAGE_MUTATION);
   const me = useSelector((rootReducer) => rootReducer.personReducer.person);
   const reSendMessage = async () => {
      setShowFailedRepliedModal(false);
      setIsResending(true);
      if (messageObj) {
         reSendMessageMutation({
            variables: {
               ...messageObj,
               sender: me._id,
               chat: activeChat._id,
            },
         }).then(() => {
            setTimeout(() => setIsResending(false), 1000);
         });
      }
   };

   useEffect(() => {
      if (ack == 0) {
         setChangedAckStatus(true);
      }
      if (ack == 2) {
         if (changedAckStatus) {
            setTimeout(() => {
               setShowDoubleThick(true);
            }, 500);
         } else {
            setShowDoubleThick(true);
         }
      }
   }, [ack]);

   if (width === 'xs') {
      numberOfItemsToShow = 1;
   } else if (width === 'sm' || width === 'md') {
      numberOfItemsToShow = 2;
   } else {
      numberOfItemsToShow = 3;
   }

   const params = {
      slidesPerView: numberOfItemsToShow,
      spaceBetween: CARD_LIST_GAP,
      slidesPerGroup: numberOfItemsToShow,
   };

   if (!elements || elements.length === 0) return null;

   return (
      <Grid justifyContent={isMine ? 'flex-end' : 'flex-start'} className={classes.root}>
         <Grid className={classes.productList}>
            {elements && elements.length > numberOfItemsToShow && (
               <ButtonBase
                  className={classNames(classes.swiperButton, classes.swiperButtonLeft)}
                  onClick={() => {
                     if (sliderRef.current) {
                        sliderRef.current.swiper.slidePrev();
                     }
                  }}
               >
                  <KeyboardArrowLeftIcon className={classes.chevronIcon} />
               </ButtonBase>
            )}
            <Swiper ref={sliderRef} {...params}>
               {elements.map((product, idx) => (
                  <Card key={idx} elevation={0} className={classNames(classes.card)}>
                     {product.image_url ? (
                        <CardMedia className={classes.media} image={product.image_url} title={product.title} />
                     ) : (
                        <CardMedia className={classes.media} title={product.title}>
                           <BrokenImageIcon className={classes.defaultMediaIcon} />
                        </CardMedia>
                     )}
                     <CardContent className={classes.cardContent}>
                        <Grid className={classes.titleContainer}>
                           <Typography variant='body2' align='left'>
                              {product.title}
                           </Typography>
                        </Grid>
                        <CardActions className={classes.cardActions}>
                           {product.buttons.map((button, idx) => (
                              <Link
                                 key={idx}
                                 target='blank'
                                 href={button.button_url}
                                 variant='body2'
                                 className={classes.customLink}
                              >
                                 {button.button_text}
                              </Link>
                           ))}
                        </CardActions>
                     </CardContent>
                  </Card>
               ))}
            </Swiper>
            {elements && elements.length > numberOfItemsToShow && (
               <ButtonBase
                  className={classNames(classes.swiperButton, classes.swiperButtonRight)}
                  onClick={() => {
                     if (sliderRef.current) {
                        sliderRef.current.swiper.slideNext();
                     }
                  }}
               >
                  <KeyboardArrowRightIcon className={classes.chevronIcon} />
               </ButtonBase>
            )}
            {isMine && (
               <Grid item className={classes.messageAck}>
                  {(ack == 0 || isResending) && (
                     <CircularProgress style={{ marginRight: 10, padding: 2 }} size={15} color={'#848484'} />
                  )}
                  {ack == 1 && !isResending && <CheckIcon style={{ marginRight: 10, padding: 2 }} fontSize='small' />}
                  {ack == 2 && !isResending && !showDoubleThick && (
                     <CheckIcon style={{ marginRight: 10, padding: 2 }} fontSize='small' />
                  )}
                  {ack == 2 && !isResending && showDoubleThick && (
                     <Grid item>
                        <CheckIcon style={{ padding: 2 }} fontSize='small' />
                        <CheckIcon
                           style={{ padding: 2, marginRight: '-6px', position: 'absolute', right: '2px' }}
                           fontSize='small'
                        />
                     </Grid>
                  )}
                  {ack == 3 && !isResending && (
                     <ErrorIcon
                        style={{ padding: 0, color: 'red', marginBottom: 2, cursor: 'pointer' }}
                        fontSize='small'
                        onClick={() => setShowFailedRepliedModal(true)}
                     />
                  )}
               </Grid>
            )}
         </Grid>
         <Modal open={showFailedRepliedModal} onClose={() => setShowFailedRepliedModal(false)}>
            <div className={classes.modalContainer}>
               <div className={classes.modalHeader}>
                  <Typography className={classes.modalTitle}>{t?.resend_message_title}</Typography>
               </div>
               <div className={classes.childrenContainer}>
                  <Typography className={classes.modalText}>{t?.resend_message_text}</Typography>
               </div>
               <div className={classes.modalFooter}>
                  <ButtonCancel
                     label={t['comp_button_cancel']}
                     onClick={() => setShowFailedRepliedModal(false)}
                     style={{ backgroundColor: 'transparent', color: '#263548', border: '1px solid #263548' }}
                  />
                  <ButtonOK
                     label={t?.pre_load_forget_pass_sent_OK}
                     onClick={() => reSendMessage()}
                     endIcon={
                        reSendMessageLoading ? (
                           <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                        ) : (
                           <></>
                        )
                     }
                  />
               </div>
            </div>
         </Modal>
      </Grid>
   );
};

export default withWidth()(ProductCards);

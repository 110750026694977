import React from 'react';

const ActiveDot = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='29' height='29' viewBox='0 0 29 29'>
         <g id='Ellipse_64' data-name='Ellipse 64' fill='none' stroke='#d1d1d1' strokeWidth='2'>
            <circle cx='14.5' cy='14.5' r='14.5' stroke='none' />
            <circle cx='14.5' cy='14.5' r='13.5' fill='none' />
         </g>
      </svg>
   );
};

export default ActiveDot;

import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SITE_URL } from '@helper/config';
import { CREATE_ECOMMERCE_PLATFORM_MUTATION } from '../../../../queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import { E_COMMERCE_PLATFORM_TYPE } from '../../../../queries/Settings/Service/GraphQL/ECommercePlatform/types';
import ErrorPage from '../shared-components/ErrorPage';
const CreateWooCommerceAccount = ({ location: { search } }) => {
   const params = queryString.parse(search.replace('-space-', ' '));
   // const formData = jwt.verify(search.params, '_');
   const [createWooCommerceAccount, { loading }] = useMutation(CREATE_ECOMMERCE_PLATFORM_MUTATION);
   const person = useSelector((reducer) => reducer.personReducer.person);

   const [status, setStatus] = useState('');
   useEffect(() => {
      createWooCommerceAccount({
         variables: {
            type: E_COMMERCE_PLATFORM_TYPE.WOOCOMMERCE,
            metaData: {
               session: '',
               customer: person.customer._id,
               ...params,
            },
         },
         onQueryUpdated: () => alert('asd'),
      });
      setTimeout(() => {
         window.open('/authentication/woocommerce/close', '_self', '');
      }, 1000);

      // // window.close();
      //    .then((e) => {
      //       window.close();
      //       return '';
      //    })
      // .catch((error) => {
      //    console.log("HATA", error);
      //    setStatus('failed');
      //    return '';
      // });
   }, []);
   // if (status === 'failed') {
   //    setTimeout(() => {
   //       window.close();
   //    }, 5000);
   // }

   if (loading) {
      return <div className='loading' />;
   }

   return <Fragment>{status === 'failed' && <ErrorPage platformHref={'/settings/e-commerce/woocommerce'} />}</Fragment>;
};

export default React.memo(CreateWooCommerceAccount);

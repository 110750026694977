import { CircularProgress, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import { CREATE_PERSON_GROUP_MUTATION } from '@queries/PersonGroups/Service/GraphQL/mutation';
import { useSelector } from 'react-redux';
import { getPerson } from '@store/reducers/person';
import { useSnackbar } from 'notistack';
import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import InputField from '@components/InputField';
import SingleInput from '@components/_Modal/SingleInput';
import ModalButtonField from '@components/_Modal/ModalButtonField';

const useStyles = makeStyles((theme) => ({
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      borderTop: '1px solid #F1F3F7',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const CreateGroupModal = ({ setCreateGroup, showCreateManualAccount, showCreateGroup }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector(getPerson);
   const { enqueueSnackbar } = useSnackbar();

   const [createPersonGroupsMutation, { loading }] = useMutation(CREATE_PERSON_GROUP_MUTATION);

   const createGroupSubmit = (data) => {
      createPersonGroupsMutation({
         variables: {
            name: data.name,
            customer: person.customer._id,
            isDynamic: false,
         },
      })
         .then(() => {
            enqueueSnackbar(t['page_settings_typo_chat_settings_automatic_actions_success_message'], {
               variant: 'success',
               autoHideDuration: 2000,
            });
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               autoHideDuration: 2000,
            });
         })
         .finally(() => {
            setCreateGroup({
               visible: false,
               loading: false,
            });
         });
   };

   const { control, handleSubmit, register } = useForm({
      mode: 'all',
   });

   return (
      <Modal
         open={showCreateGroup.visible}
         onClose={() =>
            setCreateGroup({
               ...showCreateGroup,
               visible: false,
            })
         }
         modalHeader={t?.create_new_user_group}
      >
         <SingleInput
            label={t?.group_name}
            name='name'
            rules={{
               required: t?.required_field,
               maxLength: { value: 30, message: t?.page_contacts_typo_group_name_max_length },
            }}
            control={control}
            placeholder={t?.group_name}
         />
         <ModalButtonField>
            <ButtonCancel
               disabled={showCreateManualAccount.loading}
               label={t['comp_button_cancel']}
               onClick={() =>
                  setCreateGroup({
                     visible: false,
                     loading: false,
                  })
               }
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            />
            <ButtonOK
               className={classes.modalButtons}
               disabled={showCreateGroup.loading || loading}
               label={t?.pre_load_forget_pass_sent_OK}
               endIcon={
                  showCreateGroup.loading && <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
               }
               onClick={handleSubmit(createGroupSubmit)}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
            />
         </ModalButtonField>
      </Modal>
   );
};

export default CreateGroupModal;

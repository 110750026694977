import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import { IconButton, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { LOCATIONS } from '@queries/Locations/query';
import { GET_CHAT_TAGS_QUERY } from '@queries/Settings/Service/GraphQL/ChatTags/query';
import { GET_CUSTOM_FIELDS } from '@queries/Settings/Service/GraphQL/CustomFields/query';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { EnumCustomFieldsType } from '../Settings/SettingSpace/CustomFields/customFields';

import DoubleInput from '@components/_Modal/DoubleInput';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';

import useTranslateLanguage from '@hooks/useTranslateLanguage';

import { useLazyQuery, useQuery } from '@apollo/client';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
      padding: 20,
      paddingBottom: 5,
      borderRadius: 15,
      maxHeight: '90vh',
      overflowY: 'auto',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      [theme.breakpoints.down('sm')]: {
         overflowY: 'scroll',
         height: '90vh',
      },
   },
   title: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.4rem',
   },
   groupLabel: {
      fontSize: '18px',
      color: '#212121',
      marginBottom: '-12px',
      lineHeight: 1,
   },
   itemContainer: {
      height: '100%',
   },
   groupInput: {
      '& .MuiOutlinedInput-input': {
         paddingTop: '8px !important',
         paddingBottom: '8px !important',
      },
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   closeIcon: {
      position: 'absolute',
      top: '33px',
      right: '50px',
      width: '20px',
      opacity: '.8',
      cursor: 'pointer',
   },
}));

const AddEditContact = ({ onSave, onCancel, mode, row }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [formValues, setFormValues] = useState({
      name: null,
      surname: null,
      phone: null,
      email: null,
      tags: [],
      country: null,
      language: null,
      state: null,
   });
   const [isOpenCustomFields, setIsOpenCustomFields] = useState(false);
   const [isRemovePersonCustomFieldList, setIsRemovePersonCustomFieldList] = useState(false);
   const [isChangedTags, setIsChangedTags] = useState(false);
   const [filteredCustomFieldData, setFilteredCustomFieldData] = useState();
   const [chatTagsData, setChatTagsData] = useState();
   const person = useSelector((reducer) => reducer.personReducer.person);
   const { data: languagesData, loading: languagesLoading } = useTranslateLanguage(lang.shortTitle);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

   const formattedData = {};
   const CHAT_TAGS_PAGE_SIZE = 10;

   // This code block is for formatting the custom fields data
   if (row.personCustomFields) {
      row.personCustomFields.forEach((field) => {
         if (field.customField.type === EnumCustomFieldsType.LIST) {
            formattedData[field.customField._id] = {
               label: field.value,
               value: field.value,
            };
         } else {
            formattedData[field.customField._id] = field.value;
         }
      });
   }

   const {
      control,
      watch,
      formState: { isValid, isDirty },
      setValue,
   } = useForm({
      mode: 'all',
      reValidateMode: 'all',
      defaultValues: {
         name: row.name || '',
         surname: row.surname || '',
         email: row.email || '',
         phone: row.phone || '',
         tags: row.tags || [],
         country: row.country
            ? {
                 label: row.country,
                 value: row.country,
              }
            : '',
         language: row.language
            ? {
                 label: row.language,
                 value: row.language,
              }
            : '',
         state: row.state
            ? {
                 label: row.state,
                 value: row.state,
              }
            : '',
         personCustomFields: formattedData || {},
      },
   });

   useQuery(GET_CUSTOM_FIELDS, {
      variables: {
         customer: person.customer._id,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
         setFilteredCustomFieldData(
            data.getCustomFields.data
               // This filter operation is for removing the custom fields that are already added to the contact
               .filter(
                  (field) =>
                     !Object.entries(formattedData)
                        .map(([customFieldId]) => customFieldId)
                        .includes(field._id),
               ),
         );
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const [getCountryInvoice, { data: countryDataInvoice, loading: countryLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'country',
         id: 0,
      },
   });

   const [getStateInvoice, { data: stateDataInvoice, loading: stateLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'state',
         id: watch('country.value'),
      },
   });

   const [getChatTagQuery] = useLazyQuery(GET_CHAT_TAGS_QUERY, {
      fetchPolicy: 'cache-first',
   });

   useEffect(() => {
      setFormValues((prevState) => ({
         ...prevState,
         _id: row._id,
         name: row.name || '',
         surname: row.surname || '',
         phone: row.phone || '',
         type: row.type || '',
         tags: row.tags || [],
         country: row.country || '',
         language: row.language || '',
         state: row.state || '',
         personCustomFields: formattedData || [],
      }));
   }, [row]);

   useEffect(() => {
      if (row.country && row.country === watch('country')) {
         setValue('state', null);
      }
   }, [watch('country')]);

   const handleValueChange = (e) => {
      const { name, value } = e.target;
      setFormValues((state) => ({ ...state, [name]: value }));
   };

   const getTagsLoadOptions = async (search, prevOptions) => {
      if (search.length !== 0) {
         if (search.length < 3) {
            return {
               options: chatTagsData.map(({ name, _id, color }) => {
                  return {
                     value: _id,
                     label: name,
                     color,
                  };
               }),
               hasMore: false,
            };
         }
      }

      const getChatTagRequestData = await getChatTagQuery({
         variables: {
            customer: person.customer._id,
            search,
            pageSize: CHAT_TAGS_PAGE_SIZE,
            page: prevOptions.length === 0 ? 1 : prevOptions.length / CHAT_TAGS_PAGE_SIZE + 1,
         },
      })
         .then((res) => {
            setChatTagsData(res.data.getChatTagList.data.docs);
            return res.data.getChatTagList.data;
         })
         .catch(() => []);
      return {
         options: getChatTagRequestData.docs.map(({ name, _id, color }) => {
            return {
               value: _id,
               label: name,
               color,
            };
         }),
         hasMore: getChatTagRequestData.hasNextPage,
      };
   };

   const isValidNewCustomFieldValid = (type) => {
      switch (type) {
         case 'EMAIL':
            return {
               pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t['page_contacts_typo_custom_field_invalid_email'],
               },
            };
         case 'NUMBER':
            return {
               pattern: {
                  value: /^\d+$/,
                  message: t['page_contacts_typo_custom_field_invalid_number'],
               },
            };
         case 'URL':
            return {
               pattern: {
                  value: /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g,
                  message: t['page_contacts_typo_custom_field_invalid_url'],
               },
            };
         case 'DATE':
            return {
               pattern: {
                  value:
                     lang.shortTitle === 'TR'
                        ? /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
                        : /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                  message: t['page_contacts_typo_custom_field_invalid_date'],
               },
            };
         default:
            return;
      }
   };

   const customFieldPlaceHolder = (type) => {
      switch (type) {
         case 'EMAIL':
            return t['page_contacts_typo_custom_field_placeholder_email'];
         case 'NUMBER':
            return t['page_contacts_typo_custom_field_placeholder_number'];
         case 'URL':
            return t['page_contacts_typo_custom_field_placeholder_url'];
         case 'DATE':
            return t['page_contacts_typo_custom_field_placeholder_date'];
         case 'TEXT':
            return t['page_contacts_typo_custom_field_placeholder_text'];
         case 'LIST':
            return t['page_contacts_typo_custom_field_placeholder_list_values'];
         case 'PHONE':
            return t['page_contacts_typo_custom_field_placeholder_phone'];
         default:
            return;
      }
   };

   const tagsStyles = {
      multiValue: (provided, state) => ({
         ...provided,
         backgroundColor: state.data.color,
      }),
      multiValueLabel: (provided, state) => ({
         ...provided,
         color: state.data.color === '#fff' ? '#212121' : '#fff',
      }),
      multiValueRemove: (provided, state) => ({
         ...provided,
         color: state.data.color === '#fff' ? '#212121' : '#fff',
         '&:hover': {
            backgroundColor: 'rgba(0,0,0, .1)',
            color: state.data.color === '#fff' ? '#212121' : '#fff',
         },
         cursor: 'pointer',
      }),
   };

   return (
      <Grid>
         <div style={{ overflowY: 'scroll', maxHeight: 550, paddingRight: 16 }}>
            {/* Name and Surame  */}
            <DoubleInput
               name1='name'
               control1={control}
               label1={t['page_account_input_profile_name']}
               onChange1={handleValueChange}
               rules1={{
                  required: t['required_field'],
               }}
               name2='surname'
               control2={control}
               label2={t['page_account_input_profile_surname']}
            />

            {/* Phone & email Input  */}
            <SingleInput
               name='email'
               type='email'
               control={control}
               variant='outlined'
               label={t['page_account_input_profile_email']}
            />
            <SingleInput name='phone' control={control} isPhone label={t['page_account_input_profile_phone']} />
            <DoubleInput
               maxMenuHeight1={190}
               name1='country'
               control1={control}
               label1={t['page_account_input_profile_country']}
               isSelect1
               isLoading1={countryLoadingInvoice}
               onFocus1={() => {
                  setValue('state', null);
                  getCountryInvoice();
               }}
               selectOptions1={countryDataInvoice?.locations?.data?.map(({ name, id }) => ({
                  value: id,
                  label: name,
               }))}
               name2='state'
               maxMenuHeight2={190}
               control2={control}
               isSelect2
               isLoading2={stateLoadingInvoice}
               disabled2={watch('country').length === 0 || countryLoadingInvoice}
               onFocus2={() => {
                  if (typeof watch('country.value') === 'number') {
                     getStateInvoice();
                  }
               }}
               selectOptions2={stateDataInvoice?.locations?.data?.map(({ name, id }) => ({
                  value: id,
                  label: name,
               }))}
               label2={t['page_account_input_profile_city']}
            />
            <SingleInput
               name='tags'
               type='tags'
               control={control}
               label={t['page_account_input_profile_tag']}
               isPaginateSelect
               isMulti
               autoHeight
               maxMenuHeight={190}
               customSelectStyles={tagsStyles}
               selectOptions={chatTagsData?.getChatTagList?.data.docs.map(({ name, _id, color }) => ({
                  value: _id,
                  label: name,
                  color,
               }))}
               value={watch('tags')}
               loadOptions={getTagsLoadOptions}
               onChange={(tag) => {
                  setValue('tags', tag);
                  setIsChangedTags(true);
               }}
            />
            <SingleInput
               maxMenuHeight={190}
               name='language'
               isSelect
               customSelectStyles={{
                  menu: (base) => ({
                     ...base,
                     top: 'auto',
                     bottom:
                        // menuItem position top or bottom
                        Object.values(watch('personCustomFields')).filter((item) => item !== undefined).length === 0 &&
                        !isOpenCustomFields &&
                        '100%',
                  }),
               }}
               selectOptions={languagesData?.map(({ label, code }) => ({
                  value: code,
                  label,
               }))}
               isLoading={languagesLoading}
               control={control}
               label={t['page_account_input_profile_language']}
            />
            {row.personCustomFields.map((field) => {
               return (
                  <div style={{ position: 'relative' }}>
                     <SingleInput
                        placeholder={customFieldPlaceHolder(field.customField.type)}
                        name={`personCustomFields.${field.customField._id}`}
                        isSelect={field.customField.type === EnumCustomFieldsType.LIST}
                        control={control}
                        maxMenuHeight={190}
                        customSelectStyles={{
                           menu: (base) => ({
                              ...base,
                              top: 'auto',
                              bottom: !isOpenCustomFields && '100%',
                           }),
                        }}
                        selectOptions={row.personCustomFields
                           .filter(
                              (item) =>
                                 item.customField._id === field.customField._id &&
                                 item.customField.type === EnumCustomFieldsType.LIST,
                           )[0]
                           ?.customField?.listValues?.map((value) => ({
                              label: value,
                              value,
                           }))}
                        variant='outlined'
                        label={field.customField.name}
                        rules={isValidNewCustomFieldValid(field.customField.type)}
                        isPhone={field.customField.type === EnumCustomFieldsType.PHONE}
                     />
                     {field.customField.type === EnumCustomFieldsType.LIST &&
                        watch(`personCustomFields.${field.customField._id}`) && (
                           <span
                              onClick={() => {
                                 setValue(`personCustomFields.${field.customField._id}`, '');
                                 setIsRemovePersonCustomFieldList(true);
                              }}
                           >
                              <CloseIcon className={classes.closeIcon} />
                           </span>
                        )}
                  </div>
               );
            })}

            <div
               style={{
                  borderTop: 'solid rgba(0,0,0, .05) 1.5px',
                  borderBottom: isOpenCustomFields && 'solid rgba(0,0,0, .05) 1.5px',
               }}
            >
               <Grid
                  container
                  direction='row'
                  style={{
                     borderRadius: '5px',
                  }}
                  alignItems='center'
                  wrap='nowrap'
               >
                  <Typography
                     noWrap
                     style={{
                        textDecoration: 'none',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        color: filteredCustomFieldData ? '#212121' : '#9E9E9E',
                     }}
                  >
                     {t['page_settings_typo_custom_field']}
                  </Typography>
                  <IconButton
                     disabled={!filteredCustomFieldData}
                     onClick={() => {
                        setIsOpenCustomFields(!isOpenCustomFields);
                     }}
                  >
                     {isOpenCustomFields ? (
                        <KeyboardArrowDownIcon style={{ fontSize: '32px' }} />
                     ) : (
                        <KeyboardArrowRightIcon style={{ fontSize: '32px' }} />
                     )}
                  </IconButton>
               </Grid>
            </div>
            {isOpenCustomFields && filteredCustomFieldData && (
               <div style={{ marginTop: '1.5rem' }}>
                  {Array.from(filteredCustomFieldData)
                     .reverse()
                     .map((field, index) => {
                        return (
                           <div style={{ position: 'relative' }}>
                              <SingleInput
                                 maxMenuHeight={190}
                                 placeholder={customFieldPlaceHolder(field.type)}
                                 name={`personCustomFields.${field._id}`}
                                 isSelect={field.type === EnumCustomFieldsType.LIST}
                                 selectOptions={field.listValues.map((value) => ({
                                    label: value,
                                    value,
                                 }))}
                                 customSelectStyles={{
                                    menu: (base) => ({
                                       ...base,
                                       top: 'auto',
                                       bottom: filteredCustomFieldData.length - index < 3 ? '100%' : 'auto',
                                    }),
                                 }}
                                 isDate={field.type === EnumCustomFieldsType.DATE} // 12/12/2020 for formatter
                                 control={control}
                                 variant='outlined'
                                 label={field.name}
                                 rules={isValidNewCustomFieldValid(field.type)}
                                 isPhone={field.type === EnumCustomFieldsType.PHONE}
                              />
                              {field.type === EnumCustomFieldsType.LIST && watch(`personCustomFields.${field._id}`) && (
                                 <span onClick={() => setValue(`personCustomFields.${field._id}`, '')}>
                                    <CloseIcon className={classes.closeIcon} />
                                 </span>
                              )}
                           </div>
                        );
                     })}
               </div>
            )}
         </div>
         <ModalButtonField>
            <ButtonCancel
               onClick={onCancel}
               label={t['comp_button_cancel']}
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            />
            <ButtonOK
               onClick={() => {
                  const formData = {
                     ...formValues,
                     ...watch(),
                     personCustomFields: Object.entries(watch('personCustomFields')).map(([key, value]) => {
                        if (typeof value === 'object' && value !== null) {
                           return {
                              customField: key,
                              value: value.value,
                           };
                        } else {
                           return {
                              customField: key,
                              value: value || '',
                           };
                        }
                     }),
                  };
                  onSave(formData);
               }}
               label={mode === 'NEW' ? t['comp_button_add'] : t['comp_button_save']}
               disabled={!isValid || (!isDirty && !isRemovePersonCustomFieldList && !isChangedTags)}
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
            />
         </ModalButtonField>
      </Grid>
   );
};

export default AddEditContact;

import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   errorContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      height: '20%',
   },
   errorText: {
      color: '#cc0033',
      fontSize: '13px',
      fontWeight: 'bold',
      lineHeight: '20px',
      textShadow: '1px 1px rgba(250,250,250,.3)',
      padding: '1rem 0px',
   },
}));

const Error = ({ message, show }) => {
   const classes = useStyles();

   if (show) {
      return (
         <Grid container className={classes.errorContainer}>
            <p className={classes.errorText}> {message} </p>
         </Grid>
      );
   } else {
      return null;
   }
};

Error.defaultProps = {
   message: 'An Error Occured!! Please wait while we fix the error or refresh in the meantime',
   show: true,
};

export default Error;

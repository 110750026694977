import {
   SET_BOT,
   SET_LOCATION,
   SET_LANGUAGES,
   SET_CHANNEL,
   SET_AGENTS,
   SET_TAGS,
   SET_NODETEMPLATES,
   SET_BLOCKTEMPLATES,
   SET_BOT_STATUS,
   SET_RULE_STATUS,
   SET_BOT_ID,
   SET_HAS_OPERATIONS,
   SET_RULE_ID,
   SET_SAVE_RULE_STATE,
   SET_IS_SECOND_MESSAGE,
   SET_TEAMS,
   SET_CUSTOM_FIELDS,
   SET_PLATFORM,
   SET_START_BOT,
   SET_START_NODE,
   SET_CLICKED_NODE_ID,
} from '@store/actions/automation';

const INITIAL_STATE = {
   bot: '',
   hasOperations: false,
   isSecond: false,
   botId: null,
   ruleId: null,
   botSatus: '',
   clickedNodeId: '',
   ruleSatus: '',
   locations: [],
   languages: [],
   channels: [],
   agents: [],
   platforms: [],
   teams: [],
   customFields: [],
   tags: [],
   listNodeTemplates: [],
   listBlockTemplates: [],
   ruleState: {},
   startNode: {},
   startBot: {},
};

export default (state = INITIAL_STATE, action = {}) => {
   switch (action.type) {
      case SET_BOT:
         return {
            ...state,
            bot: action.payload,
         };
      case SET_CLICKED_NODE_ID:
         return {
            ...state,
            clickedNodeId: action.payload,
         };
      case SET_PLATFORM:
         return {
            ...state,
            platforms: action.payload,
         };
      case SET_HAS_OPERATIONS:
         return {
            ...state,
            hasOperations: action.payload,
         };
      case SET_BOT_ID:
         return {
            ...state,
            botId: action.payload,
         };
      case SET_CHANNEL:
         return {
            ...state,
            channels: action.payload,
         };
      case SET_RULE_ID:
         return {
            ...state,
            ruleId: action.payload,
         };
      case SET_IS_SECOND_MESSAGE:
         return {
            ...state,
            isSecond: action.payload,
         };
      case SET_SAVE_RULE_STATE:
         return {
            ...state,
            ruleState: action.payload,
         };
      case SET_BOT_STATUS:
         return {
            ...state,
            botSatus: action.payload,
         };
      case SET_RULE_STATUS:
         return {
            ...state,
            ruleSatus: action.payload,
         };
      case SET_START_BOT:
         return {
            ...state,
            startBot: action.payload,
         };
      case SET_START_NODE:
         return {
            ...state,
            startNode: action.payload,
         };
      case SET_LOCATION:
         return {
            ...state,
            locations: action.payload,
         };
      case SET_LANGUAGES:
         return {
            ...state,
            languages: action.payload,
         };
      case SET_AGENTS:
         return {
            ...state,
            agents: action.payload,
         };
      case SET_TEAMS:
         return {
            ...state,
            teams: action.payload,
         };
      case SET_CUSTOM_FIELDS:
         return {
            ...state,
            customFields: action.payload,
         };
      case SET_TAGS:
         return {
            ...state,
            tags: action.payload,
         };
      case SET_NODETEMPLATES:
         return {
            ...state,
            listNodeTemplates: action.payload,
         };
      case SET_BLOCKTEMPLATES:
         return {
            ...state,
            listBlockTemplates: action.payload,
         };
      default:
         return state;
   }
};

export const getBotState = (state) => state.botReducer;
export const getBot = (state) => state.botReducer.botList;
export const getLocations = (state) => state.botReducer.locations;
export const getLanguages = (state) => state.botReducer.getLanguages;

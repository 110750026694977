import SearchBar from '@components/SearchBar';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, MenuItem, Select, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { GridToolbarContainer } from '@material-ui/data-grid';
import React, { useContext } from 'react';
import { SenderType } from '../types';

const useStyles = makeStyles((theme) => ({
    selector: {
        textAlign: 'left',
        border: 'solid 1px lightgrey',
        borderRadius: 5,
        paddingLeft: 3,
        height: 32,
    },
    container: {
        padding: '10px !important',
    },
    item: {
        display: 'inline-block',
        width: 7,
        height: 30,
        backgroundColor: 'white',
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        marginRight: '10px',
    },
    menuItem: {
        height: 45,
    },
}));

export interface ToolbarProps {
    onSenderChanged: (value: SenderType) => void;
    activeSendGroup: SenderType;
    onSearchChange: (value: string) => void;
    searchValue: string;
    onNewSharing: () => void;
}

const Toolbar = ({ onSenderChanged, activeSendGroup, onSearchChange, searchValue, onNewSharing }: ToolbarProps) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const t = lang?.translation;

    const ITEM_HEIGHT = 100;
    const ITEM_PADDING_TOP = 1;

    return (
        <GridToolbarContainer className={classes.container}>
            <Grid container spacing={3}>
                <Grid xs={3} item>
                    <Select
                        variant='outlined'
                        labelId='sender-picker'
                        onChange={(e) => {
                            onSenderChanged(Number(e.target.value) as SenderType);
                        }}
                        value={activeSendGroup}
                        style={{ height: 37.5, marginBottom: 0, marginTop: 0, width: '100%' }}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            PaperProps: {
                                style: {
                                    maxHeight: 100 * 4.5 + 1,
                                    marginTop: -18,
                                },
                            },
                        }}
                    >
                        <MenuItem value='0' key='0' className={classes.menuItem}>
                            <p>{t['bulk_message_option_1']}</p>
                        </MenuItem>
                        <MenuItem value='1' key='1' className={classes.menuItem}>
                            <p>{t['bulk_message_option_2']}</p>
                        </MenuItem>
                        <MenuItem value='2' key='2' className={classes.menuItem}>
                            <p>{t['bulk_message_option_3']}</p>
                        </MenuItem>
                    </Select>
                </Grid>
                <Grid xs={3} item>
                    <div style={{ display: activeSendGroup == SenderType.PersonGroups ? "unset" : 'none' }}>
                        <SearchBar
                            value={searchValue}
                            onChange={onSearchChange}
                            placeholder={t?.page_chat_search_placeholder}
                            style={{ width: '100%' }}
                        />
                    </div>
                </Grid>
                <Grid xs={6} item>
                    <Button onClick={onNewSharing} style={{ display: "block", marginLeft: "auto" }} color='primary' variant='contained'>
                        {t['bulk_message_create']}
                    </Button>
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
};

export default Toolbar;

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import moment from 'moment';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import { trimLongText } from '@helper/textUtils';
import { getPerson } from '@store/reducers/person';

const useStyles = makeStyles((theme) => ({
   root: {
      height: '20%',
      color: theme.chatApp.workspace.sideBar.backgroundColor,
      marginBottom: theme.spacing(1),
   },
   title: {
      color: '#4C6588',
      fontWeight: 'bold',
   },
   subtitle: {
      color: '#617FA7',
      fontSize: 14,
   },
   rightSection: {
      [theme.breakpoints.down('xs')]: {
         marginTop: theme.spacing(1),
      },
   },
   selected: {
      background: '#fff !important',
   },
   rootButton: {
      width: '100% !important',
      fontSize: '0.9rem',
      fontWeight: 'bold',
      borderRadius: 8,
      transition: 'all .5s',
   },
   toggleButtonContainer: {
      width: '324px',
      [theme.breakpoints.down('xs')]: {
         width: '100%',
      },
   },
}));

const WelcomeSection = ({ onChangeTab, tabValue }) => {
   const person = useSelector(getPerson);
   const classes = useStyles();
   const {
      lang: { translation: t, shortTitle },
   } = useContext(LanguageContext);

   return (
      <div className={classes.root}>
         <Grid container justifyContent={'space-between'} alignItems={'center'}>
            <Grid item xs={12} sm={6}>
               <Typography component='h6' className={classes.title}>
                  {t?.page_dashboard_typo_welcome},{' '}
                  <Typography component='span' style={{ color: '#10223A', fontWeight: 'bold' }}>
                     {trimLongText(person?.name?.split(' ')[0], 15)}
                  </Typography>
               </Typography>
               <Typography
                  style={{
                     color: '#617FA7',
                  }}
                  variant={'subtitle2'}
               >
                  {moment().locale(shortTitle.toLowerCase()).format('DD MMMM, dddd')}
               </Typography>
            </Grid>

            <Grid className={classes.rightSection} item sm={6} xs={12} container justifyContent={'flex-end'}>
               <ToggleButtonGroup
                  value={tabValue}
                  onChange={(event, value) => {
                     if (value) {
                        onChangeTab(value);
                     }
                  }}
                  color='primary'
                  exclusive={true}
                  size={'small'}
                  className={classes.toggleButtonContainer}
               >
                  <ToggleButton
                     classes={{
                        selected: classes.selected,
                        root: classes.rootButton,
                     }}
                     color={'primary'}
                     value='messages'
                  >
                     {t?.messages}
                  </ToggleButton>
                  <ToggleButton
                     classes={{ root: classes.rootButton, selected: classes.selected }}
                     color={'primary'}
                     value='comments'
                  >
                     {t?.comments}
                  </ToggleButton>
               </ToggleButtonGroup>
            </Grid>
         </Grid>
      </div>
   );
};

export default WelcomeSection;

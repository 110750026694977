import { useLazyQuery } from '@apollo/client';
import { SettingsWhatsappBusinessIcon, WhatsappIcon } from '@assets/icons/SettingsIcons/settingsIcons';
import ButtonOK from '@components/ButtonOK/buttonOk';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';
import { LanguageContext } from '@helper/locale/langContext';
import {
   Button,
   Checkbox,
   CircularProgress,
   Grid,
   Modal as MaterialModal,
   MenuItem,
   Paper,
   Select,
   Typography,
   useMediaQuery,
} from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import {
   GET_RECIPIENTS,
   GET_RECIPIENTS_COUNT,
   GET_TEMPLATES,
   GET_TEMPLATES_COUNT,
} from '@queries/Campaigns/Service/GraphQL/query';
import '@styles/index.css';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-multi-email/dist/style.css';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { PLATFORM_TYPE } from '../../types/index';

import { useMutation } from '@apollo/client';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import Modal from '@components/Modal/modal';
import { CREATE_PERSON_MUTATION } from '@queries/Person/Service/GraphQL/mutation';
import { getPerson } from '@store/reducers/person';
import { useSnackbar } from 'notistack';
import { AsyncPaginate } from 'react-select-async-paginate';

const useStyles = makeStyles((theme) => ({
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   modalPaper: {
      borderRadius: 12,
      backgroundColor: '#fff',
      minWidth: 450,
      width: 600,
      maxHeight: '99vh',
      fontFamily: 'Helvetica',
   },
   header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '20px',
      color: 'black',
      fontWeight: 'bold',
      marginTop: 0,
      width: '100%',
      paddingInline: 20,
      paddingBlock: 10,
      borderBottom: '2px solid #f5f5f5',
   },
   body: {
      overflowX: 'hidden',
      maxHeight: 'calc(99vh - 60px)',
      width: '100%',
      minHeight: 610,
      overflow: 'auto',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   btnBox: {
      position: 'sticky',
      bottom: 0,
      padding: '0 1rem',
      backgroundColor: '#fff',
      borderRadius: 12,
      display: 'flex',
      justifyContent: 'space-between',
      zIndex: 1,
   },
   fromMenuItem: {
      height: 45,
   },
   IconContainer: {
      width: 35,
      height: 35,
      borderRadius: 10,
      textAlign: 'center',
      padding: 8,
      marginRight: 10,
   },
   listIcon: {
      width: 20,
      height: 20,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   newTemplate: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 8,
      padding: 4,
      marginRight: 2,
      cursor: 'pointer',
      color: 'white',
      backgroundColor: '#157CFC',
      '&:hover': {
         backgroundColor: '#105CBB',
      },
      float: 'right',
   },
}));

const RECIPIENTS_PAGE_SIZE = 10;
const TEMPLATE_PAGE_SIZE = 10;

const NewWhatsappModalInput = (props) => {
   const { open, sendWhatsappSubmit, person } = props;
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const dispatch = useDispatch();
   const history = useHistory();
   const whatsappWebAccounts = useSelector((state) => state.chatReducer.whatsappWebAccounts?.whatsappWebAccounts);
   const whatsappBusinessAccounts = useSelector(
      (state) => state.chatReducer.whatsappBusinessAccounts.whatsappBusinessAccounts,
   );
   const classes = useStyles(props);
   const [isSendNewWhatsapp, setIsSendNewWhatsapp] = useState(false);
   const [showCreatePersonModal, setShowCreatePersonModal] = useState(false);
   const [templatesData, setTemplatesData] = useState(null);
   const [recipientsData, setRecipientsData] = useState(null);
   const whatsappAccounts = [];
   const ITEM_HEIGHT = 100;
   const ITEM_PADDING_TOP = 1;
   const MenuProps = {
      anchorOrigin: {
         vertical: 'bottom',
         horizontal: 'left',
      },
      getContentAnchorEl: null,
      transformOrigin: {
         vertical: 'top',
         horizontal: 'left',
      },
      PaperProps: {
         style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: !isSmallScreen ? 200 : 300,
         },
      },
   };

   const [state, setState] = useState({
      to: [],
      content: '',
      template: '',
      senderId: '',
   });

   const [getRecipients] = useLazyQuery(GET_RECIPIENTS, {
      fetchPolicy: 'no-cache',
      onCompleted: (getRecipients) => {
         return getRecipients.getRecipients.data.users.filter((user) => user.name.length > 2);
      },
   });

   const [getRecipientsCount] = useLazyQuery(GET_RECIPIENTS_COUNT, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getRecipientsCount }) => {
         return getRecipientsCount.getRecipientsCount.data;
      },
   });

   const [getTemplates] = useLazyQuery(GET_TEMPLATES, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getAllTemplatesPaginate }) => {
         return getAllTemplatesPaginate.data.filter((template) => template.status === 'APPROVED');
      },
   });

   const [getTemplatesCount] = useLazyQuery(GET_TEMPLATES_COUNT, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getAllTemplatesPaginateCount }) => {
         return getAllTemplatesPaginateCount.data;
      },
   });

   if (whatsappBusinessAccounts?.length > 0) {
      whatsappBusinessAccounts.forEach((account) => {
         if (account.status === 'Approved') {
            whatsappAccounts.push(account);
         }
      });
   }

   if (whatsappWebAccounts?.length > 0) {
      whatsappWebAccounts.forEach((account) => {
         if (account.status) {
            whatsappAccounts.push(account);
         }
      });
   }

   const isTemplateMessage = () => {
      if (whatsappAccounts?.find((item) => item._id === state.senderId)) {
         return whatsappAccounts?.find((item) => item._id === state.senderId).type === PLATFORM_TYPE.WHATS_APP_BUSINESS;
      }
      return false;
   };

   const handleCloseModal = () => {
      dispatch({
         type: 'CREATE_WHATSAPP_MODAL_VISIBLE',
         payload: false,
      });
      setState({
         to: [],
         content: '',
         template: null,
         senderId:
            (whatsappAccounts.length > 0 &&
               whatsappAccounts.find((account) => account.type === PLATFORM_TYPE.WHATS_APP_BUSINESS)?._id) ||
            whatsappAccounts[0]._id,
      });
   };

   const handleSendWhatsappSubmit = async () => {
      history.push('/chat');
      setIsSendNewWhatsapp(true);
      const isSendMessage = await sendWhatsappSubmit({
         customerId: person.customer._id,
         me: person._id,
         receiverIds: state.to.map((item) => item.value),
         platformType: whatsappAccounts.find((account) => account._id === state.senderId).type,
         platformId: whatsappAccounts.find((account) => account._id === state.senderId)._id,
         messageType:
            whatsappAccounts.find((account) => account._id === state.senderId).type === PLATFORM_TYPE.WHATS_APP_BUSINESS
               ? 3
               : 0,
         media: [],
         content: state.content || '',
         templateId: state.template?.value || '',
      });
      if (isSendMessage) {
         dispatch({
            type: 'CREATE_WHATSAPP_MODAL_VISIBLE',
            payload: false,
         });
         setState({
            to: [],
            content: '',
            template: null,
            senderId:
               (whatsappAccounts.length > 0 &&
                  whatsappAccounts.find((account) => account.type === PLATFORM_TYPE.WHATS_APP_BUSINESS)?._id) ||
               whatsappAccounts[0]._id,
         });
      }
      setIsSendNewWhatsapp(false);
   };

   useEffect(() => {
      setState({
         ...state,
         senderId:
            (whatsappAccounts?.length > 0 &&
               whatsappAccounts?.find((account) => account.type === PLATFORM_TYPE.WHATS_APP_BUSINESS)?._id) ||
            whatsappAccounts[0]?._id,
      });
   }, []);

   const getTemplateLoadOptions = async (search, prevOptions) => {
      const getTemplatesCountRequestData = await getTemplatesCount({
         variables: {
            startDate: null,
            pageSize: TEMPLATE_PAGE_SIZE,
            pageNumber: 1,
            customer: person.customer._id,
            eCommerceCart: false,
         },
      })
         .then((res) => res.data.getAllTemplatesPaginateCount.data)
         .catch(() => 0);

      const getTemplatesRequestData = await getTemplates({
         variables: {
            startDate: null,
            pageSize: TEMPLATE_PAGE_SIZE,
            pageNumber: 1,
            customer: person.customer._id,
            eCommerceCart: false,
         },
      })
         .then((res) => {
            setTemplatesData(res.data.getAllTemplatesPaginate.data);
            return res.data.getAllTemplatesPaginate.data;
         })
         .catch(() => []);

      return {
         options: getTemplatesRequestData.map((template) => {
            return {
               label: template.name.split('**')[0],
               value: template.templateId,
            };
         }),
         hasMore: prevOptions.length + getTemplatesRequestData.length < getTemplatesCountRequestData,
      };
   };

   const getRecipientsLoadOptions = async (search, prevOptions) => {
      if (search.length !== 0) {
         if (search.length < 3) {
            return {
               options: recipientsData.map((recipient) => {
                  return {
                     label: `${recipient.name} (${recipient.phone})`,
                     value: recipient._id,
                  };
               }),
               hasMore: false,
            };
         }
      }
      const getRecipientsCountRequestData = await getRecipientsCount({
         variables: {
            customer: person.customer._id,
            name: search,
            isGroup: false,
         },
      })
         .then((res) => res.data.getRecipientsCount.data)
         .catch(() => 0);

      const getRecipientsRequestData = await getRecipients({
         variables: {
            pageNumber: prevOptions.length === 0 ? 1 : prevOptions.length / RECIPIENTS_PAGE_SIZE + 1,
            pageSize: RECIPIENTS_PAGE_SIZE,
            customer: person.customer._id,
            name: search,
            isGroup: false,
         },
      })
         .then((res) => {
            setRecipientsData(res.data.getRecipients.data.users);
            return res.data.getRecipients.data.users;
         })
         .catch(() => []);

      return {
         options: getRecipientsRequestData.map((recipient) => {
            return {
               label: `${recipient.name} (${recipient.phone})`,
               value: recipient._id,
            };
         }),
         hasMore: prevOptions.length + getRecipientsRequestData.length < getRecipientsCountRequestData,
      };
   };

   return (
      <>
         <MaterialModal
            className={classes.modal}
            open={open}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
               timeout: 500,
            }}
         >
            <Paper className={classes.modalPaper}>
               <Grid container className={classes.header}>
                  <Typography variant='inherit'>{t['page_account_preferences_new_whatsapp_message']}</Typography>
               </Grid>
               <Grid className={classes.body}>
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '0 1rem 1rem 1rem',
                        gap: 10,
                     }}
                  >
                     <div
                        style={{
                           width: '100%',
                           gap: '1rem',
                           display: 'grid',
                        }}
                     >
                        <div>
                           <div
                              style={{
                                 fontSize: 18,
                                 color: '#212121',
                                 marginLeft: 3,
                              }}
                           >
                              {t?.phone_from}
                           </div>
                           <Grid sm={12}>
                              <Select
                                 style={{ height: 42, marginBottom: 0, display: 'flex' }}
                                 variant='outlined'
                                 labelId='sender-picker'
                                 onChange={(e) => {
                                    setState({
                                       ...state,
                                       content: '',
                                       template: null,
                                       senderId: e.target.value,
                                    });
                                 }}
                                 getContentAnchorEl={null}
                                 value={state.senderId}
                                 MenuProps={MenuProps}
                              >
                                 {whatsappAccounts &&
                                    whatsappAccounts.map((account) => {
                                       return (
                                          <MenuItem
                                             key={account._id}
                                             value={account._id}
                                             className={classes.fromMenuItem}
                                          >
                                             <Grid style={{ display: 'flex', alignItems: 'center' }} container>
                                                <div
                                                   style={{
                                                      backgroundColor: '#D5FFE0',
                                                      fill: 'rgb(37, 211, 102)',
                                                   }}
                                                   className={classes.IconContainer}
                                                >
                                                   {account.type === 'WHATS_APP_BUSINESS' ? (
                                                      <SettingsWhatsappBusinessIcon className={classes.listIcon} />
                                                   ) : (
                                                      <WhatsappIcon className={classes.listIcon} />
                                                   )}
                                                </div>
                                                <Typography noWrap>{account.name}</Typography>
                                             </Grid>
                                          </MenuItem>
                                       );
                                    })}
                              </Select>
                           </Grid>
                        </div>
                     </div>
                     <Grid sm={12}>
                        <div
                           style={{
                              fontSize: 18,
                              color: '#212121',
                              marginLeft: 3,
                           }}
                        >
                           {t?.phone_to}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', gap: 14 }}>
                           <div style={{ width: '100%' }}>
                              <AsyncPaginate
                                 placeholder={t?.phone_number_placeholder}
                                 cacheOptions
                                 isMulti
                                 closeMenuOnSelect={false}
                                 value={state.to}
                                 loadOptions={getRecipientsLoadOptions}
                                 onChange={(to) => setState({ ...state, to })}
                              />
                           </div>
                           <div className={classes.newTemplate} onClick={() => setShowCreatePersonModal(true)}>
                              <AddIcon />
                           </div>
                        </div>
                     </Grid>
                     {isTemplateMessage() && (
                        <Grid sm={12}>
                           <div
                              style={{
                                 fontSize: 18,
                                 color: '#212121',
                                 marginLeft: 3,
                              }}
                           >
                              {t?.page_chat_typo_quick_answers_templates}
                           </div>
                           <div style={{ width: '100%' }}>
                              <AsyncPaginate
                                 isSearchable={false}
                                 placeholder={t?.campaign_select_template}
                                 cacheOptions
                                 value={state.template}
                                 loadOptions={getTemplateLoadOptions}
                                 onChange={(template) => {
                                    const templateId = template.value;
                                    setState({
                                       ...state,
                                       template,
                                       content: templatesData
                                          .find((template) => template.templateId === templateId)
                                          .message?.split('|')[0],
                                    });
                                 }}
                              />
                           </div>
                        </Grid>
                     )}
                  </div>
                  <div
                     style={{
                        backgroundColor: isTemplateMessage() ? '#FCFCFC' : '#fff',
                        borderTop: '0.5px solid #DBDBDB',
                        padding: '0 1rem',
                     }}
                  >
                     <textarea
                        style={{
                           width: '96%',
                           height: '410px',
                           margin: '1rem 0.5rem',
                           padding: '0.5rem 0.1rem',
                           border: 'none',
                           borderRadius: 8,
                           resize: 'none',
                           backgroundColor: isTemplateMessage() ? '#FCFCFC' : '#fff',
                           outline: 'none',
                           fontFamily: 'Helvetica',
                        }}
                        value={state.content}
                        disabled={isTemplateMessage()}
                        type='text'
                        onChange={(e) => setState({ ...state, content: e.target.value })}
                     />
                  </div>
               </Grid>
               <div className={classes.btnBox}>
                  <ModalButtonField>
                     <Button
                        className={classes.modalButtons}
                        onClick={handleCloseModal}
                        style={{
                           color: '#212121',
                           backgroundColor: '#eeee',
                           '&:hover': {
                              backgroundColor: '#e0e0e0',
                           },
                        }}
                     >
                        {t['page_chat_typo_close']}
                     </Button>
                     <ButtonOK
                        startIcon={
                           isSendNewWhatsapp ? (
                              <CircularProgress color='inherit' style={{ marginRight: 3 }} size={20} />
                           ) : null
                        }
                        onClick={handleSendWhatsappSubmit}
                        className={classes.modalButtons}
                        label={t['page_chat_typo_send']}
                        type='submit'
                        disabled={
                           !state.to ||
                           state.to.length === 0 ||
                           (isTemplateMessage() && !state.template) ||
                           state.content?.length < 4 ||
                           isSendNewWhatsapp
                        }
                        style={{
                           color: 'white',
                           backgroundColor: '#157CFC',
                           '&:hover': {
                              backgroundColor: '#105CBB',
                           },
                           float: 'right',
                        }}
                     />
                  </ModalButtonField>
               </div>
            </Paper>
         </MaterialModal>
         <CreateNewUserModal
            setShowCreatePersonModal={setShowCreatePersonModal}
            showCreatePersonModal={showCreatePersonModal}
         />
      </>
   );
};

const CreateNewUserModal = ({ setShowCreatePersonModal, showCreatePersonModal }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar } = useSnackbar();
   const person = useSelector(getPerson);

   const { control, handleSubmit, reset } = useForm({
      mode: 'all',
      defaultValues: {
         name: '',
         surname: '',
         phone: '',
      },
   });

   const [createPersonMutation, { loading }] = useMutation(CREATE_PERSON_MUTATION);
   const [isLicenseAgreement, setIsLicenseAgreement] = useState(false);
   const [isCreateNewUserLoading, setIsCreateNewUserLoading] = useState(false);

   const createPersonSubmit = (data) => {
      if (!data.email && (!data.phone || data.phone?.length < 5)) {
         enqueueSnackbar(t['create_new_contact_enter_email_or_phone_number'], {
            variant: 'error',
            autoHideDuration: 4000,
         });
         return;
      }
      setIsCreateNewUserLoading(true);
      createPersonMutation({
         variables: {
            name: data.name,
            surname: data.surname,
            phone: data.phone,
            email: '',
            password: '',
            customer: person.customer._id,
            type: -10,
         },
      })
         .then(() => {
            enqueueSnackbar(t['page_settings_typo_chat_settings_automatic_actions_success_message'], {
               variant: 'success',
               autoHideDuration: 2000,
            });
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               autoHideDuration: 3000,
            });
         })
         .finally(() => {
            setShowCreatePersonModal(false);
            setIsCreateNewUserLoading(false);
            reset();
         });
   };

   return (
      <Modal
         open={showCreatePersonModal}
         onClose={() => setShowCreatePersonModal(false)}
         modalHeader={t?.create_new_contact_title}
      >
         <SingleInput
            name='name'
            label={t['page_e_commerce_customer_typo_first_name']}
            placeholder={t['page_e_commerce_customer_typo_first_name']}
            rules={{ required: t?.required_field }}
            control={control}
         />
         <SingleInput
            name='surname'
            label={t['page_e_commerce_customer_typo_last_name']}
            placeholder={t['page_e_commerce_customer_typo_last_name']}
            rules={{ required: t?.required_field }}
            control={control}
         />
         <SingleInput name='phone' isPhone label={t['page_account_input_profile_phone']} control={control} />
         <div
            style={{
               display: 'flex',
               flexDirection: 'row',
               justifyContent: 'flex-start',
               alignItems: 'flex-start',
               gap: 8,
               marginBottom: 16,
            }}
         >
            <Checkbox
               color='primary'
               value={isLicenseAgreement}
               onChange={(checked) => {
                  setIsLicenseAgreement(checked.target.checked);
               }}
            />
            <div>
               <span>{t['create_new_contact_disclaimer']} </span>
               <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={
                     lang.code === 'tr'
                        ? 'https://www.qpien.com/tr/kullanim-kosullari'
                        : 'https://www.qpien.com/user-agreement'
                  }
               >
                  {t['create_new_contact_disclaimer_qpien']}
               </a>
               <span>{t['create_new_contact_disclaimer_end']}</span>
            </div>
         </div>
         <ModalButtonField>
            <ButtonCancel
               className={classes.modalButtons}
               label={t['comp_button_cancel']}
               onClick={() => setShowCreatePersonModal(false)}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            />
            <ButtonOK
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
               disabled={!isLicenseAgreement || loading}
               label={t?.pre_load_forget_pass_sent_OK}
               endIcon={
                  isCreateNewUserLoading && <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
               }
               onClick={handleSubmit(createPersonSubmit)}
            />
         </ModalButtonField>
      </Modal>
   );
};

export default NewWhatsappModalInput;

import { SET_PAYLOAD } from '../actions/liveChat'; // action types
import _ from 'underscore';
// default initial state
const INITIAL_STATE = {
   liveChatPreviewSettings: null,
   updateLiveChatPreviewSettings: null,
   liveChatSettings: null,
   updateLiveChatSettings: null,
   resetLiveChatSettings: null,
   dataUpdatedFromServer: null,
};

export default (state = INITIAL_STATE, action = {}) => {
   switch (action.type) {
      case SET_PAYLOAD:
         return {
            ...state,
            ...action.payload,
         };
      default:
         return state;
   }
};

export const getECommerceProducts = (state) => state.eCommerceReducer.eCommerceProducts;
export const getECommerceStore = (state) => state.eCommerceReducer.eCommerceStore;

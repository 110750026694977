import {Dispatch, SetStateAction} from "react";
import {
  getCustomerTeamMembersQuery_customerTeams_data
} from "@queries/Person/Service/GraphQL/__generated__/getCustomerTeamMembersQuery";
import {ChatType} from "../../types/enums";

export enum ChatListTab {
  YOURS = 'YOURS',
  COMPANY = 'COMPANY',
}

interface ChatListTabsProps {
  chatType: ChatType;
  agents: getCustomerTeamMembersQuery_customerTeams_data[] | undefined;
  chatFilters: any;
  selectedTab: ChatListTab;
  setSelectedTab: Dispatch<SetStateAction<ChatListTab>>;
  companyChatCount?: string;
  mineChatCount?: string;
}

export default ChatListTabsProps;


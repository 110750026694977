import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Grid, ButtonBase, Divider, InputAdornment } from '@material-ui/core';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import TextInput from '@components/TextInput';
import TagIcon from '@components/TagIcon/tagIcon';
import { LanguageContext } from '@helper/locale/langContext';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText, ModalTitle } from '@components/_Modal/ModalTexts';
import { ChromePicker } from 'react-color';
import ColorizeIcon from '@material-ui/icons/Colorize';

import ErrorIcon from '@material-ui/icons/Error';

const COLORS = [
   { key: 'C_0', value: '#277DA1' },
   { key: 'C_1', value: '#577590' },
   { key: 'C_2', value: '#62B6CB' },
   { key: 'C_3', value: '#43AA8B' },
   { key: 'C_4', value: '#F9C74F' },
   { key: 'C_5', value: '#F87F43' },
   { key: 'C_6', value: '#FF87AB' },
   { key: 'C_7', value: '#A06CD5' },
   { key: 'C_8', value: '#90BE6D' },
   { key: 'C_9', value: '#F94144' },
   { key: 'C_10', value: '#0080FE' },
   { key: 'C_11', value: '#03A0FE' },
];

const useStyles = makeStyles((theme) => ({
   root: {
      width: 600,
      padding: 20,
      paddingBottom: 10,
      borderRadius: 15,
      // width: 700,
      // height: 490,
      textAlign: 'center',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   iconSize: {
      fontSize: '5rem',
   },
   title: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.4rem',
   },
   subTitle: {
      fontSize: '1rem',
   },
   coloredButtons: {
      '&:hover': {
         transform: 'scale(1.1)',
         transition: 'all 0.3s',
      },
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   popover: {
      // this is not essential, it's some styling for the picker
      position: 'absolute',
      zIndex: '2',
   },
   cover: {
      // same as for popover
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
   },
}));

const ModalContent = ({ tag, onAccept, onCancel, mode, onDelete }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [state, setState] = useState({
      color: mode === 'EDIT' ? tag.color : null,
      name: mode === 'EDIT' ? tag.tag : '',
      _id: mode === 'EDIT' || mode === 'DELETE' ? tag.id : '',
   });
   const [remove, setRemove] = useState({
      _id: mode === 'DELETE' ? tag.id : '',
   });
   const [showColorPicker, setShowColorPicker] = useState(false);


   const handleInputChange = (e) => {
      const { name, value } = e.target;
      setState((prevState) => ({ ...prevState, [name]: value }));
      setRemove((prevState) => ({ ...prevState, [name]: value }));
   };

   const classes = useStyles();
   // only for test
   return (
      <Grid>
         {mode === 'DELETE' ? (
            <Grid container direction='column' alignItems='center'>
               <ModalLargeText style={{ marginBottom: 30, marginTop: 20 }}>
                  {t?.page_settings_typo_delete_tag}
               </ModalLargeText>
               <ModalButtonField>
                  <ButtonCancel
                     className={classes.modalButtons}
                     onClick={onCancel}
                     label={t['comp_button_cancel']}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        '&:hover': {
                           backgroundColor: '#e0e0e0',
                        },
                     }}
                  />
                  <ButtonOK
                     className={classes.modalButtons}
                     style={{
                        color: 'white',
                        backgroundColor: '#ef5350',
                        '&:hover': {
                           backgroundColor: '#f44336',
                        },
                        float: 'right',
                     }}
                     onClick={() => onDelete(remove)}
                     label={t['comp_button_delete']}
                  />
               </ModalButtonField>
            </Grid>
         ) : (
            <Grid>
               <Grid container alignItems='center' justifyContent='flex-start' direction='row' style={{ height: 90 }}>
                  <Grid xs={12}>
                     <ModalLargeText
                        style={{
                           marginBottom: -10,
                           marginLeft: 6,
                        }}
                     >
                        {t?.page_settings_typo_tag}
                     </ModalLargeText>
                     <TextInput
                        style={{
                           paddingInline: 10,
                        }}
                        defaultValue={state.name}
                        maxLength={16}
                        name='name'
                        onChange={handleInputChange}
                        editable
                        height={55}
                        placeholder={t['page_settings_typo_add_tag_placeholder']}
                        // End node for input
                        endAdornment={
                           <InputAdornment position='end'>
                              <TagIcon width={120} height={40} color={state.color} style={{ marginRight: 5 }} />
                           </InputAdornment>
                        }
                     />
                  </Grid>
               </Grid>
               <Grid justifyContent='flex-start' alignItems='flex-start'>
                  <Typography
                     style={{ textAlign: 'left', marginLeft: 5, marginTop: 10, fontSize: 18, color: '#212121' }}
                  >
                     {t?.page_settings_typo_tag_color}
                  </Typography>
               </Grid>
               <Grid container justifyContent='flex-start' direction='row'>
                  <Grid container justifyContent='space-between' style={{ padding: 10, paddingTop: 0 }}>
                     {COLORS.map((color) => (
                        <ButtonBase
                           key={color.value}
                           onClick={() => setState((prevState) => ({ ...prevState, color: color.value }))}
                           style={{ height: 30, width: 30, marginTop: 0 }}
                           disableRipple
                        >
                           <Grid
                              className={classes.coloredButtons}
                              style={{
                                 borderRadius: 10,
                              }}
                           >
                              <TagIcon width={30} height={30} color={color.value} />
                           </Grid>
                        </ButtonBase>
                     ))}
                     <ColorizeIcon
                        style={{ marginLeft: 10, cursor: 'pointer' }}
                        onClick={(e) => {
                           setState({ ...state, color: '#277DA1' });
                           setShowColorPicker(true);
                        }}
                     />
                     <div style={{ zIndex: 9 }}>
                        {showColorPicker && (
                           <div className={classes.popover}>
                              <div
                                 className={classes.cover}
                                 onClick={() => {
                                    setShowColorPicker(false);
                                 }}
                                 role='button'
                                 tabIndex='0'
                                 aria-label='Save'
                              />

                              <ChromePicker
                                 color={state.color}
                                 onChange={(color) => setState({ ...state, color: color.hex })}
                                 onChangeComplete={(color) => setState({ ...state, color: color.hex })}
                              />
                           </div>
                        )}
                     </div>
                  </Grid>
               </Grid>
               <ModalButtonField>
                  <ButtonCancel
                     className={classes.modalButtons}
                     onClick={onCancel}
                     label={t['comp_button_cancel']}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        '&:hover': {
                           backgroundColor: '#e0e0e0',
                        },
                     }}
                  />
                  <ButtonOK
                     className={classes.modalButtons}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        '&:hover': {
                           backgroundColor: '#105CBB',
                        },
                        float: 'right',
                     }}
                     onClick={() => onAccept(state)}
                     label={t['comp_button_save']}
                     disabled={!state.name || !state.color} // Disabled Button An Empty input or not selected color
                  />
               </ModalButtonField>
            </Grid>
         )}
      </Grid>
   );
};

export default ModalContent;

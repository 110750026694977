import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Flow from './Nodes';
import { Node, ReactFlowProvider } from 'reactflow';

const useStyles = makeStyles(() => ({
   formContainer: {
      flexGrow: 1,
      overflowY: 'auto',
   },
}));

interface ContentPanelProps {
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   clickedNodeId: string;
   leftNodes: Node[];
   isDraft: boolean;
   setIsDraft: React.Dispatch<React.SetStateAction<boolean>>;
   setIsSecondMessage: React.Dispatch<React.SetStateAction<boolean>>;
   isSecondMessage: boolean;
}

const ContentPanel: React.FC<ContentPanelProps> = ({
   clickedNodeId,
   setClickedNodeId,
   isDraft,
   setIsDraft,
   leftNodes,
   isSecondMessage,
   setIsSecondMessage,
}) => {
   const classes = useStyles();

   return (
      <div className={classes.formContainer}>
         <ReactFlowProvider>
            <Flow
               leftNodes={leftNodes}
               clickedNodeId={clickedNodeId}
               setClickedNodeId={setClickedNodeId}
               isDraft={isDraft}
               setIsSecondMessage={setIsSecondMessage}
               isSecondMessage={isSecondMessage}
            />
         </ReactFlowProvider>
      </div>
   );
};

export default ContentPanel;

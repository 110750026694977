import React from 'react';

export const StatisticsMessageIcon = (props) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='25.543' height='25.791' viewBox='0 0 25.543 25.791' {...props}>
         <g id='Group_4055' data-name='Group 4055' transform='translate(-171.567 -153.395)'>
            <path
               id='Path_85'
               data-name='Path 85'
               d='M244.955,191.37a27.489,27.489,0,0,1-4.666-1.218,6.463,6.463,0,0,0-4.145-.391c-2.08.417-2.845-.347-2.4-2.442a6.813,6.813,0,0,0-.425-4.131,12.713,12.713,0,1,1,24.369-6.528A12.94,12.94,0,0,1,244.955,191.37Zm-7.47-5.359c1.125.349,2.382.645,3.565,1.126a10.161,10.161,0,0,0,6.1.65,9.605,9.605,0,1,0-10.759-5.262A26.341,26.341,0,0,1,237.485,186.011Z'
               transform='translate(-60.709 -12.184)'
               // fill='#0e223c'
            />
            <path
               id='Path_86'
               data-name='Path 86'
               d='M286.06,218.009c-.074.8-.418,1.358-1.307,1.41a1.375,1.375,0,1,1,1.307-1.41Z'
               transform='translate(-100.119 -51.664)'
               // fill='#0e223c'
            />
            <path
               id='Path_87'
               data-name='Path 87'
               d='M264.4,216.612a1.35,1.35,0,0,1,1.325,1.492,1.252,1.252,0,0,1-1.407,1.268,1.284,1.284,0,0,1-1.346-1.354A1.373,1.373,0,0,1,264.4,216.612Z'
               transform='translate(-84.43 -51.619)'
               // fill='#0e223c'
            />
            <path
               id='Path_88'
               data-name='Path 88'
               d='M306.527,218.006a1.343,1.343,0,0,1-1.441,1.38,1.241,1.241,0,0,1-1.318-1.356,1.274,1.274,0,0,1,1.292-1.4A1.392,1.392,0,0,1,306.527,218.006Z'
               transform='translate(-115.955 -51.629)'
               // fill='#0e223c'
            />
         </g>
      </svg>
   );
};

export const StatisticsChatIcon = (props) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='29.108' height='29.147' viewBox='0 0 29.108 29.147' {...props}>
         <g id='Group_3804' data-name='Group 3804' transform='translate(0)'>
            <g id='Group_136' data-name='Group 136' transform='translate(0)'>
               <path
                  id='Path_84'
                  data-name='Path 84'
                  d='M185.208,223.305c0-1.742,0-3.3,0-4.867,0-.372-.012-.744.005-1.115.037-.788.488-1.272,1.143-1.25a1.111,1.111,0,0,1,1.051,1.251c.012,2.656.021,5.311,0,7.967a1.088,1.088,0,0,1-1.814.95c-1.184-.756-2.325-1.581-3.522-2.317a2.672,2.672,0,0,0-1.317-.39c-4.753-.027-9.507-.015-14.261-.018a4.137,4.137,0,0,1-4.457-4.473q0-5.617,0-11.233a4.105,4.105,0,0,1,4.42-4.423c1.328,0,2.656-.008,3.983,0,.8.007,1.3.436,1.306,1.082s-.485,1.1-1.284,1.119c-1.327.024-2.656,0-3.983.011-1.549.014-2.214.7-2.216,2.259q0,5.617,0,11.233a1.914,1.914,0,0,0,2.194,2.184c4.594,0,9.189.031,13.782-.018a5.131,5.131,0,0,1,3.484,1.06C184.127,222.647,184.593,222.9,185.208,223.305Z'
                  transform='translate(-162.031 -197.357)'
                  // fill='#0e223c'
               />
               <path
                  id='Path_85'
                  data-name='Path 85'
                  d='M241.167,183.665a19.279,19.279,0,0,1-3.272-.854,4.532,4.532,0,0,0-2.907-.274c-1.458.292-2-.243-1.684-1.713a4.778,4.778,0,0,0-.3-2.9,8.916,8.916,0,1,1,17.089-4.578A9.075,9.075,0,0,1,241.167,183.665Zm-5.239-3.758c.789.244,1.671.452,2.5.79a7.125,7.125,0,0,0,4.28.456,6.736,6.736,0,1,0-7.545-3.69A18.475,18.475,0,0,1,235.929,179.907Z'
                  transform='translate(-221.08 -165.578)'
                  // fill='#0e223c'
               />
               <path
                  id='Path_86'
                  data-name='Path 86'
                  d='M285.228,217.609c-.052.559-.293.952-.917.989a.965.965,0,1,1,.917-.989Z'
                  transform='translate(-263.952 -208.527)'
                  // fill='#0e223c'
               />
               <path
                  id='Path_87'
                  data-name='Path 87'
                  d='M263.975,216.612a.947.947,0,0,1,.929,1.046.878.878,0,0,1-.987.889.9.9,0,0,1-.944-.949A.963.963,0,0,1,263.975,216.612Z'
                  transform='translate(-246.883 -208.478)'
                  // fill='#0e223c'
               />
               <path
                  id='Path_88'
                  data-name='Path 88'
                  d='M305.7,217.593a.942.942,0,0,1-1.01.968.87.87,0,0,1-.924-.951.894.894,0,0,1,.906-.981A.976.976,0,0,1,305.7,217.593Z'
                  transform='translate(-281.178 -208.49)'
                  // fill='#0e223c'
               />
            </g>
         </g>
      </svg>
   );
};

export const StatisticsQuestionCommentIcon = (props) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         xmlnsXlink='http://www.w3.org/1999/xlink'
         width='30'
         height='27'
         viewBox='0 0 30 27'
         {...props}
      >
         <defs>
            <clipPath id='clip-path'>
               <rect
                  id='Rectangle_7'
                  data-name='Rectangle 7'
                  width='30'
                  height='27'
                  transform='translate(0 0)'
                  // fill='#157cfc'
                  stroke='#707070'
                  strokeWidth='1'
               />
            </clipPath>
            <clipPath id='clip-path-2'>
               <path
                  id='Path_11'
                  data-name='Path 11'
                  d='M3065.067,524.028h-3.144v-3.664l-19.82-1.991-20.926,3.506s-9.965,20.353-9.709,20.353,1.334,14.015,2.035,14.471,14.683,5.5,18.2,5.653,25.37,2.177,26.307,2.03,11.405-6.151,11.405-6.151l-.81-19.127s-2.134-12.736-2.066-13.039S3065.067,524.028,3065.067,524.028Z'
                  transform='translate(-3011.463 -518.373)'
                  fill='none'
                  stroke='#707070'
                  strokeWidth='1'
               />
            </clipPath>
         </defs>
         <g id='Group_4149' data-name='Group 4149' transform='translate(-137 -271)'>
            <g id='Group_9' data-name='Group 9' transform='translate(137 271)'>
               <g id='Mask_Group_4' data-name='Mask Group 4' transform='translate(0 0)' clip-path='url(#clip-path)'>
                  <path
                     id='Path_10'
                     data-name='Path 10'
                     d='M.112.281A3.064,3.064,0,0,1,3.456,3.412H.112Z'
                     transform='translate(25.132 1.124)'
                     // fill='#157cfc'
                  />
                  <g
                     id='Mask_Group_3'
                     data-name='Mask Group 3'
                     transform='translate(-25.016 -1.229)'
                     clip-path='url(#clip-path-2)'
                  >
                     <g id='Group_2' data-name='Group 2' transform='translate(25.898 2.629)'>
                        <path
                           id='Path_16'
                           data-name='Path 16'
                           d='M4789.146,638.54a1.221,1.221,0,0,1-.627-.172l-3.573-2.144c-.073-.044-.6-.247-.6-.247-.1-.035-.235-.205-2.332-.159-.483.012-1.2.012-2.261.013h-4.732l-6.883-.007a6.865,6.865,0,0,1-2.867-.729,3.845,3.845,0,0,1-1.372-1.458,1.683,1.683,0,0,1-.16-.43c-.108-.368-.107-1.807-.091-13.1a4,4,0,0,1,3.522-3.986c.792-.043,19.89-.035,23-.018a1.062,1.062,0,1,1-.007,2.117h-.007c-1.735-.01-22.121-.019-22.629-.007a1.85,1.85,0,0,0-1.581,1.88c-.006,4.269-.012,10.432-.006,12.22,0,.079,0,.246,0,.246l.121.316a2.352,2.352,0,0,0,2.013.838c5.257.005,13.081.011,13.742,0,3.314.049,4.022.417,6.173,1.82V617.7a1.153,1.153,0,0,1,2.3,0v19.786A1.105,1.105,0,0,1,4789.146,638.54Z'
                           transform='translate(-4763.094 -615.579)'
                           // fill='#157cfc'
                        />
                        <path
                           id='Path_16_-_Outline'
                           data-name='Path 16 - Outline'
                           d='M4788.947,638.8a1.8,1.8,0,0,1-.927-.254l-3.562-2.136c-.065-.032-.312-.132-.516-.21-.029-.011-.054-.022-.071-.03a4.613,4.613,0,0,0-1.394-.1c-.2,0-.415,0-.647.008-.489.011-1.2.012-2.269.013h-4.738l-6.875-.007h-.013a7.4,7.4,0,0,1-3.154-.805,4.4,4.4,0,0,1-1.57-1.661,2.156,2.156,0,0,1-.205-.547c-.121-.448-.12-1.586-.1-13.206a4.523,4.523,0,0,1,.76-2.512,4.661,4.661,0,0,1,3.211-1.977l.033-.006.034,0c.538-.029,8.5-.032,11.9-.032,4.417,0,8.739,0,10.84.013h.883l.078,21.89A1.635,1.635,0,0,1,4788.947,638.8Zm-4.586-3.535h0a5.12,5.12,0,0,1,.685.29l3.573,2.144a.644.644,0,0,0,.613.023.547.547,0,0,0,.313-.486V618.477l-1.2,0v17.79l-.867-.565c-2.081-1.357-2.693-1.681-5.856-1.727-.268,0-1.459.006-3.639.006-2.319,0-5.714,0-10.09-.007h0c-.032,0-.092,0-.169,0a2.781,2.781,0,0,1-2.312-1.067l-.039-.053-.173-.456,0-.084s0-.167,0-.246c-.006-1.7,0-7.524.005-12.223a2.355,2.355,0,0,1,2.017-2.374l.049-.009h.048c.2,0,3.275-.006,5.815-.006,4.53,0,10.564,0,14.117.007v-.009a1.5,1.5,0,0,1,.489-1.1c-2.211,0-5.523-.007-8.894-.007-6.791,0-11.275.011-11.807.03a3.536,3.536,0,0,0-2.441,1.505,3.59,3.59,0,0,0-.6,1.966v.013c-.016,10.484-.02,12.666.071,12.969l.009.032,0,.024a1.26,1.26,0,0,0,.109.28,3.379,3.379,0,0,0,1.17,1.256,6.318,6.318,0,0,0,2.568.652h.009l6.882.007h4.735c1.064,0,1.768,0,2.244-.012.243-.005.469-.008.674-.008a4.517,4.517,0,0,1,1.881.205Zm-2.738-2.308h.01c2.984.044,3.989.358,5.616,1.36V618.473c-3.552,0-9.587-.007-14.117-.007-4.471,0-5.514,0-5.736.005a1.266,1.266,0,0,0-.924.661,1.6,1.6,0,0,0-.171.712c-.007,4.7-.011,10.526,0,12.22,0,.041,0,.107,0,.16l.064.171a1.724,1.724,0,0,0,1.363.569c.052,0,.09,0,.116,0h.042c4.384,0,7.783.006,10.1.006,2.17,0,3.392,0,3.629-.006Zm6.724-15.5,1.2,0v-.013a.6.6,0,0,0-1.2,0Zm1.865-1.054a1.534,1.534,0,0,1,.353.565.523.523,0,0,0-.17-.448.61.61,0,0,0-.182-.117Z'
                           transform='translate(-4762.896 -615.337)'
                           // fill='#157cfc'
                        />
                     </g>
                  </g>
                  <path
                     id='Path_12'
                     data-name='Path 12'
                     d='M.112.281A3.051,3.051,0,0,1,3.44,3.412H.112Z'
                     transform='translate(25.132 1.124)'
                     // fill='#157cfc'
                  />
                  <path
                     id='Path_13'
                     data-name='Path 13'
                     d='M3052.563,528.644s.31-1.818-1.414-1.823c-.01-.017,1.7-.24,1.7-.24'
                     transform='translate(-3027.345 -522.277)'
                     // fill='#157cfc'
                  />
               </g>
            </g>
            <path
               id='Path_1934'
               data-name='Path 1934'
               d='M286.06,218.009c-.074.8-.418,1.358-1.307,1.41a1.375,1.375,0,1,1,1.307-1.41Z'
               transform='translate(-132.676 65.078)'
               // fill='#157cfc'
            />
            <path
               id='Path_1935'
               data-name='Path 1935'
               d='M264.4,216.612a1.35,1.35,0,0,1,1.325,1.492,1.252,1.252,0,0,1-1.407,1.268,1.284,1.284,0,0,1-1.346-1.354A1.373,1.373,0,0,1,264.4,216.612Z'
               transform='translate(-116.986 65.122)'
               // fill='#157cfc'
            />
            <path
               id='Path_1936'
               data-name='Path 1936'
               d='M306.527,218.006a1.343,1.343,0,0,1-1.441,1.38,1.241,1.241,0,0,1-1.318-1.356,1.274,1.274,0,0,1,1.292-1.4A1.392,1.392,0,0,1,306.527,218.006Z'
               transform='translate(-148.512 65.113)'
               // fill='#157cfc'
            />
         </g>
      </svg>
   );
};

export const StatisticsQuestionConversationIcon = (props) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         xmlnsXlink='http://www.w3.org/1999/xlink'
         width='29.71'
         height='27.439'
         viewBox='0 0 29.71 27.439'
         {...props}
      >
         <defs>
            <clipPath id='clip-path'>
               <rect
                  id='Rectangle_7'
                  data-name='Rectangle 7'
                  width='19'
                  height='17'
                  transform='translate(0 -0.31)'
                  // fill='#0e223c'
                  stroke='#707070'
                  strokeWidth='1'
               />
            </clipPath>
            <clipPath id='clip-path-2'>
               <path
                  id='Path_11'
                  data-name='Path 11'
                  d='M3044.672,522.063h-1.948v-2.391l-12.279-1.3-12.964,2.288s-6.174,13.281-6.015,13.281.826,9.145,1.26,9.443,9.1,3.587,11.273,3.689,15.717,1.421,16.3,1.324,7.066-4.014,7.066-4.014l-.5-12.481s-1.323-8.311-1.28-8.508S3044.672,522.063,3044.672,522.063Z'
                  transform='translate(-3011.463 -518.373)'
                  fill='none'
                  stroke='#707070'
                  strokeWidth='1'
               />
            </clipPath>
         </defs>
         <g id='Group_4148' data-name='Group 4148' transform='translate(-128.29 -300)'>
            <g id='Group_4146' data-name='Group 4146' transform='translate(128.29 298.292)'>
               <g id='Group_136' data-name='Group 136' transform='translate(0)'>
                  <path
                     id='Path_84'
                     data-name='Path 84'
                     d='M185.208,223.305c0-1.742,0-3.3,0-4.867,0-.372-.012-.744.005-1.115.037-.788.488-1.272,1.143-1.25a1.111,1.111,0,0,1,1.051,1.251c.012,2.656.021,5.311,0,7.967a1.088,1.088,0,0,1-1.814.95c-1.184-.756-2.325-1.581-3.522-2.317a2.672,2.672,0,0,0-1.317-.39c-4.753-.027-9.507-.015-14.261-.018a4.137,4.137,0,0,1-4.457-4.473q0-5.617,0-11.233a4.105,4.105,0,0,1,4.42-4.423c1.328,0,2.656-.008,3.983,0,.8.007,1.3.436,1.306,1.082s-.485,1.1-1.284,1.119c-1.327.024-2.656,0-3.983.011-1.549.014-2.214.7-2.216,2.259q0,5.617,0,11.233a1.914,1.914,0,0,0,2.194,2.184c4.594,0,9.189.031,13.782-.018a5.131,5.131,0,0,1,3.484,1.06C184.127,222.647,184.593,222.9,185.208,223.305Z'
                     transform='translate(-162.031 -197.357)'
                     // fill='#0e223c'
                  />
               </g>
            </g>
            <g id='Group_4147' data-name='Group 4147'>
               <g id='Group_9' data-name='Group 9' transform='translate(-1.563 -47.464)'>
                  <g
                     id='Mask_Group_4'
                     data-name='Mask Group 4'
                     transform='translate(140.564 347.775)'
                     clip-path='url(#clip-path)'
                  >
                     <path
                        id='Path_10'
                        data-name='Path 10'
                        d='M.112.281A1.945,1.945,0,0,1,2.184,2.324H.112Z'
                        transform='translate(15.527 0.326)'
                        // fill='#0e223c'
                     />
                     <g
                        id='Mask_Group_3'
                        data-name='Mask Group 3'
                        transform='translate(-15.499 -1.112)'
                        clip-path='url(#clip-path-2)'
                     >
                        <g id='Group_2' data-name='Group 2' transform='translate(16.045 1.715)'>
                           <path
                              id='Path_16'
                              data-name='Path 16'
                              d='M4779.442,630.737a.726.726,0,0,1-.389-.112l-2.214-1.4c-.045-.029-.37-.161-.37-.161-.065-.023-.146-.134-1.445-.1-.3.008-.742.008-1.4.008h-2.932l-4.264,0a4.076,4.076,0,0,1-1.776-.476,2.459,2.459,0,0,1-.85-.952,1.152,1.152,0,0,1-.1-.281c-.067-.24-.066-1.179-.056-8.55a2.583,2.583,0,0,1,2.182-2.6c.491-.028,12.322-.023,14.25-.012a.691.691,0,1,1,0,1.381h0c-1.075-.006-13.7-.013-14.019,0a1.2,1.2,0,0,0-.979,1.227c0,2.786-.008,6.807,0,7.974,0,.052,0,.16,0,.16l.075.206a1.418,1.418,0,0,0,1.247.547c3.257,0,8.1.007,8.514,0a5.221,5.221,0,0,1,3.825,1.188V617.136a.712.712,0,0,1,1.424,0v12.911A.7.7,0,0,1,4779.442,630.737Z'
                              transform='translate(-4763.302 -615.755)'
                              // fill='#0e223c'
                           />
                           <path
                              id='Path_16_-_Outline'
                              data-name='Path 16 - Outline'
                              d='M4779.035,630.649a1.074,1.074,0,0,1-.574-.165l-2.207-1.394c-.041-.021-.194-.086-.32-.137l-.043-.019a2.723,2.723,0,0,0-.864-.066c-.123,0-.257,0-.4.005-.3.008-.741.008-1.406.008h-2.935l-4.259,0h-.008a4.394,4.394,0,0,1-1.954-.526,2.819,2.819,0,0,1-.973-1.084,1.463,1.463,0,0,1-.127-.357c-.075-.293-.074-1.035-.064-8.617a3.061,3.061,0,0,1,.471-1.639,2.872,2.872,0,0,1,1.99-1.29l.02,0h.021c.333-.019,5.268-.021,7.374-.021,2.737,0,5.415,0,6.716.008h.547l.048,14.284A1.037,1.037,0,0,1,4779.035,630.649Zm-2.841-2.307h0a3.108,3.108,0,0,1,.424.189l2.213,1.4a.381.381,0,0,0,.38.015.358.358,0,0,0,.194-.317V617.386h-.744v11.609l-.537-.369a4.9,4.9,0,0,0-3.628-1.127c-.166,0-.9,0-2.255,0-1.437,0-3.54,0-6.251,0h0c-.02,0-.057,0-.1,0a1.691,1.691,0,0,1-1.432-.7l-.024-.035-.107-.3v-.055s0-.109,0-.161c0-1.106,0-4.91,0-7.976a1.52,1.52,0,0,1,1.25-1.549l.031-.006h.03c.126,0,2.029,0,3.6,0,2.806,0,6.544,0,8.746,0v-.006a1,1,0,0,1,.3-.716c-1.37,0-3.422,0-5.51,0-4.207,0-6.985.007-7.314.019a2.177,2.177,0,0,0-1.512.982,2.428,2.428,0,0,0-.37,1.283v.009c-.01,6.841-.012,8.265.044,8.463l.006.021,0,.015a.852.852,0,0,0,.067.183,2.172,2.172,0,0,0,.725.82,3.751,3.751,0,0,0,1.591.425h.005l4.264,0h2.934c.66,0,1.1,0,1.39-.008.15,0,.291-.005.418-.005a2.671,2.671,0,0,1,1.166.134Zm-1.7-1.506h.006a5.342,5.342,0,0,1,3.479.887V617.383c-2.2,0-5.939,0-8.746,0-2.77,0-3.416,0-3.554,0a.788.788,0,0,0-.573.431,1.1,1.1,0,0,0-.105.465c0,3.067-.007,6.869,0,7.974,0,.027,0,.07,0,.1l.04.112a1.041,1.041,0,0,0,.844.371l.072,0h.026l6.259,0c1.345,0,2.1,0,2.249,0Zm4.165-10.112.744,0v-.009a.372.372,0,0,0-.744,0Zm1.156-.688a1.015,1.015,0,0,1,.218.369.351.351,0,0,0-.105-.292.378.378,0,0,0-.113-.076Z'
                              transform='translate(-4762.896 -615.337)'
                              // fill='#0e223c'
                           />
                        </g>
                     </g>
                     <path
                        id='Path_12'
                        data-name='Path 12'
                        d='M.112.281A1.937,1.937,0,0,1,2.174,2.324H.112Z'
                        transform='translate(15.527 0.326)'
                        // fill='#0e223c'
                     />
                     <path
                        id='Path_13'
                        data-name='Path 13'
                        d='M3052.025,527.927s.192-1.186-.876-1.189c-.006-.011,1.055-.157,1.055-.157'
                        transform='translate(-3036.402 -524.083)'
                        // fill='#0e223c'
                     />
                  </g>
               </g>
               <path
                  id='Path_1931'
                  data-name='Path 1931'
                  d='M285.228,217.609c-.052.559-.293.952-.917.989a.965.965,0,1,1,.917-.989Z'
                  transform='translate(-135.759 89.9)'
                  // fill='#0e223c'
               />
               <path
                  id='Path_1932'
                  data-name='Path 1932'
                  d='M263.975,216.612a.947.947,0,0,1,.929,1.046.878.878,0,0,1-.987.889.9.9,0,0,1-.944-.949A.963.963,0,0,1,263.975,216.612Z'
                  transform='translate(-118.689 89.949)'
                  // fill='#0e223c'
               />
               <path
                  id='Path_1933'
                  data-name='Path 1933'
                  d='M305.7,217.593a.942.942,0,0,1-1.01.968.87.87,0,0,1-.924-.951.894.894,0,0,1,.906-.981A.976.976,0,0,1,305.7,217.593Z'
                  transform='translate(-152.984 89.938)'
                  // fill='#0e223c'
               />
            </g>
         </g>
      </svg>
   );
};

export const StatisticsTagIcon = (props) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='44.657' height='44.657' viewBox='0 0 44.657 44.657' {...props}>
         <path
            id='tag'
            d='M8.746,6.247a2.5,2.5,0,0,0-2.5-2.5,2.5,2.5,0,0,0-2.5,2.5,2.5,2.5,0,0,0,2.5,2.5,2.5,2.5,0,0,0,2.5-2.5ZM29.578,17.493a2.391,2.391,0,0,1-.722,1.757l-9.586,9.605a2.5,2.5,0,0,1-1.777.722,2.391,2.391,0,0,1-1.757-.722L1.777,14.876A5.814,5.814,0,0,1,.517,12.9,6.029,6.029,0,0,1,0,10.621V2.5A2.4,2.4,0,0,1,.742.742,2.406,2.406,0,0,1,2.5,0h8.122A6.029,6.029,0,0,1,12.9.517,6.052,6.052,0,0,1,14.9,1.777L28.855,15.716a2.5,2.5,0,0,1,.722,1.777Z'
            transform='translate(43.243 22.328) rotate(135)'
            strokeWidth='2'
         />
      </svg>
   );
};

export const StatisticsUserIcon = (props) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='27.551' height='27.706' viewBox='0 0 27.551 27.706' {...props}>
         <g id='user-multiple' transform='translate(-2.054 -3.066)'>
            <path
               id='Path_1943'
               data-name='Path 1943'
               d='M34.061,33.254H31.722V27.836a5.652,5.652,0,0,0-5.847-5.418V20.25c4.519,0,8.18,3.4,8.186,7.586Z'
               transform='translate(-4.456 -2.482)'
            />
            <path
               id='Path_1944'
               data-name='Path 1944'
               d='M21.571,32.262H19.639v-5a4.926,4.926,0,0,0-4.83-5h-5.8a4.926,4.926,0,0,0-4.83,5v5H2.25v-5A6.9,6.9,0,0,1,9.012,20.25h5.8a6.9,6.9,0,0,1,6.762,7.007Z'
               transform='translate(-0.196 -1.49)'
            />
            <path
               id='Path_1945'
               data-name='Path 1945'
               d='M22.5,2.25V4.217a5.077,5.077,0,0,1,5.223,4.917A5.077,5.077,0,0,1,22.5,14.05v1.967a7.107,7.107,0,0,0,7.312-6.884A7.107,7.107,0,0,0,22.5,2.25Z'
               transform='translate(-2.408 0.816)'
            />
            <path
               id='Path_1946'
               data-name='Path 1946'
               d='M12.9,4.35A5.251,5.251,0,1,1,7.7,9.6,5.222,5.222,0,0,1,12.9,4.35m0-2.1A7.351,7.351,0,1,0,20.165,9.6,7.311,7.311,0,0,0,12.9,2.25Z'
               transform='translate(-1.076 0.816)'
            />
         </g>
      </svg>
   );
};

export const StatisticsTooltipIcon = ({ className, fill }) => {
   const filled = fill ? fill : '#ccd5e3';
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         className={className}
         width='29.109'
         height='29.147'
         viewBox='0 0 29.109 29.147'
         fill={filled}
      >
         <g id='Group_215' data-name='Group 215' transform='translate(-162.031 -165.578)'>
            <path
               id='Path_84'
               data-name='Path 84'
               d='M185.208,223.305c0-1.742,0-3.3,0-4.867,0-.372-.012-.744.005-1.115.037-.788.488-1.272,1.143-1.25a1.111,1.111,0,0,1,1.051,1.251c.012,2.656.021,5.311,0,7.967a1.088,1.088,0,0,1-1.814.95c-1.184-.756-2.325-1.581-3.522-2.317a2.672,2.672,0,0,0-1.317-.39c-4.753-.027-9.507-.015-14.261-.018a4.137,4.137,0,0,1-4.457-4.473q0-5.617,0-11.233a4.105,4.105,0,0,1,4.42-4.423c1.328,0,2.656-.008,3.983,0,.8.007,1.3.436,1.306,1.082s-.485,1.1-1.284,1.119c-1.327.024-2.656,0-3.983.011-1.549.014-2.214.7-2.216,2.259q0,5.617,0,11.233a1.914,1.914,0,0,0,2.194,2.184c4.594,0,9.189.031,13.783-.018a5.131,5.131,0,0,1,3.484,1.06C184.127,222.647,184.593,222.9,185.208,223.305Z'
               transform='translate(0 -31.779)'
               fill={filled}
            />
            <path
               id='Path_85'
               data-name='Path 85'
               d='M241.167,183.665a19.279,19.279,0,0,1-3.272-.854,4.532,4.532,0,0,0-2.907-.274c-1.458.292-2-.243-1.684-1.713a4.778,4.778,0,0,0-.3-2.9,8.916,8.916,0,1,1,17.089-4.578A9.075,9.075,0,0,1,241.167,183.665Zm-5.239-3.758c.789.244,1.671.452,2.5.79a7.125,7.125,0,0,0,4.28.456,6.736,6.736,0,1,0-7.545-3.69A18.475,18.475,0,0,1,235.929,179.907Z'
               transform='translate(-59.049)'
               fill={filled}
            />
            <path
               id='Path_86'
               data-name='Path 86'
               d='M285.228,217.609c-.052.559-.293.952-.917.989a.965.965,0,1,1,.917-.989Z'
               transform='translate(-101.921 -42.949)'
               fill={filled}
            />
            <path
               id='Path_87'
               data-name='Path 87'
               d='M263.975,216.612a.947.947,0,0,1,.929,1.046.878.878,0,0,1-.987.889.9.9,0,0,1-.944-.949A.963.963,0,0,1,263.975,216.612Z'
               transform='translate(-84.852 -42.9)'
               fill={filled}
            />
            <path
               id='Path_88'
               data-name='Path 88'
               d='M305.7,217.593a.942.942,0,0,1-1.01.968.87.87,0,0,1-.924-.951.894.894,0,0,1,.906-.981A.976.976,0,0,1,305.7,217.593Z'
               transform='translate(-119.147 -42.912)'
            />
         </g>
      </svg>
   );
};

export const StatisticsQuestionMessagesTooltipIcon = (props) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='31.976' height='27.528' viewBox='0 0 31.976 27.528' {...props}>
         <path
            id='comment-alt'
            d='M0,1.815V20.421H5.624V25.8l8.765-5.383h12.9V1.815ZM28.572,5.9V8.246h.959V21.514H24.814v3.546L19.343,21.7l-.3-.186H14.916L11.1,23.856h7.216l7.055,4.331,1.884,1.156V23.856h4.716V5.9Z'
            transform='translate(0 -1.815)'
            fill='#ccd5e3'
         />
      </svg>
   );
};

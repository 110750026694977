import { Grid, Typography } from '@material-ui/core';
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
    root: {
        color: '#212121',
        marginBottom: 16,
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',
        // color: '#4681C3',
        color: 'black',
        fontWeight: 'bold',
        marginTop: 0,
        width: '100%',
        paddingInline: 20,
        paddingBlock: 10,
        marginBottom: 15,
        borderBottom: '2px solid #f5f5f5'
    },
    title: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: 10
    },
    largeText: {
        fontSize: '18px',
    },
    mediumText: {
        fontSize: '16px',
    },
    smallText: {
        fontSize: '14px',
    }
}))

export const ModalHeader = (props: any) => {
    const { children, style } = props;
    const classes = useStyles();
    return (
        <Grid className={clsx(classes.header, classes.root)} style={style}>
            <Typography variant='inherit'>
                {children}
            </Typography>
        </Grid>
    )
}

export const ModalTitle = (props: any) => {
    const { children, style } = props;
    const classes = useStyles();
    return (
        <Grid className={clsx(classes.title, classes.root)} style={style}>
            <Typography variant='inherit'>
                {children}
            </Typography>
        </Grid>
    )
}

export const ModalLargeText = (props: any) => {
    const { children, style } = props;
    const classes = useStyles();

    return (
        <Grid className={clsx(classes.largeText, classes.root)} style={style}>
            <Typography variant='inherit'>
                {children}
            </Typography>
        </Grid>
    )
};

export const ModalMediumText = (props: any) => {
    const { children, style } = props;
    const classes = useStyles();
    return (
        <Grid className={clsx(classes.mediumText, classes.root)} style={style}>
            <Typography variant='inherit'>
                {children}
            </Typography>
        </Grid>
    )
};

export const ModalSmallText = (props: any) => {
    const { children, style } = props;
    const classes = useStyles();

    return (
        <Grid className={clsx(classes.smallText, classes.root)} style={style}>
            <Typography variant='inherit'>
                {children}
            </Typography>
        </Grid>
    )
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   hashtagButton: {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
      color: 'white',
      fontWeight: 'bold',
      paddingTop: 2,
      textAlign: 'center',
   },
   buttonStyle: {
      borderRadius: 5,
      width: (props) => props.width || '100%',
      backgroundColor: (props) => props.color,
      height: (props) => props.height || 32,
   },
}));

const Tag = (props) => {
   const classes = useStyles(props);
   return (
      <Grid className={classes.buttonStyle} item container alignItems='center' justifyContent='center' wrap='nowrap'>
         <Typography className={classes.hashtagButton}> # </Typography>
      </Grid>
   );
};

export default Tag;

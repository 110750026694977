import { LanguageContext } from '@helper/locale/langContext';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { Node } from 'reactflow';
import { GET_PLATFORMS_QUERY } from '@queries/Settings/Service/GraphQL/Platform/query';
import { useQuery, useLazyQuery } from '@apollo/client';
import { PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/Platform/types';
import { RootStateOrAny, useSelector } from 'react-redux';
import SingleInput from '@components/_Modal/SingleInput';
import { useForm } from 'react-hook-form';
import { GET_TEMPLATES, GET_TEMPLATES_COUNT } from '@queries/Campaigns/Service/GraphQL/query';

interface SendMsgBlocksProps {
   leftNodes: Node[];
   clickedNodeId: string;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setHeigherNode: React.Dispatch<React.SetStateAction<number>>;
}
const SendMsgBlocks: React.FC<SendMsgBlocksProps> = ({ leftNodes, setLeftNodes, clickedNodeId, setHeigherNode }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const useStyles = makeStyles((theme) => ({
      textArea: {
         backgroundColor: '#F7F7FA',
         borderRadius: '5px',
         border: '1px solid #E7E7E7',
         outline: 'none',
         resize: 'none',
         width: '100%',
         minHeight: '80px',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '10px',
         '&:focus': {
            border: '1px solid #E7E7E7',
         },
      },
      qpienFiels: {
         display: 'flex',
         justifyContent: 'space-betwee',
         gap: '10px',
         width: '100%',
      },
      field: {
         backgroundColor: '#F7F7FA',
         display: 'flex',
         justifyContent: 'space-between',
         padding: '8px 10px',
         border: '1px solid #E7E7E7',
         borderRadius: '5px',
         width: '100%',
      },
      preview: {
         backgroundColor: '#F7F7FA',
         display: 'flex',
         flexDirection: 'column',
         textAlign: 'center',
         borderRadius: '5px',
         justifyContent: 'center',
         height: '60px',
         padding: '5px',
         width: '100%',
         fontSize: '1rem',
         border: '0.1px dashed #DDDDDD',
         marginBottom: '10px',
      },
      templateContainer: {
         display: 'flex',
         flexDirection: 'column',
         gap: '10px',
      },
   }));
   const classes = useStyles();

   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         const words = content.length;

         let numRows = words / 30;
         const enters = (content.match(/\n/g) || []).length;

         if (enters) {
            numRows += enters;
         }

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: numRows + 1, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);

   const [selectedTemplate, setselectedTemplate] = useState<
      {
         _id: string;
         vertical: string;
         platforms?: { _id: string[]; phone: string };
         message: string;
         qpienFields: [{ key: number; value: string }];
         buttons: [{ text: string; type: string; url: string; example: string[] }];
         sampleMedia: { name: string };
      }[]
   >([]);

   const [sendersData, setSendersData] = useState<{ _id: string; phone: string }[]>([]);
   const [templateData, setTemplateData] = useState<
      {
         _id: string;
         vertical: string;
         platforms: { _id: string[]; phone: string };
         message: string;
         qpienFields: [{ key: number; value: string }];
         buttons: [{ text: string; type: string; url: string; example: string[] }];
         sampleMedia: { name: string };
      }[]
   >([]);
   const TEMPLATE_PAGE_SIZE = 10;
   const { control, watch, setValue } = useForm({
      mode: 'all',
   });

   useEffect(() => {
      const clickNode = leftNodes.find((node) => node.id === clickedNodeId)?.data;
      setValue(
         'templates',
         clickNode?.flowBlocks[0]?._id
            ? { label: clickNode?.flowBlocks[0]?.vertical, value: clickNode?.flowBlocks[0]?._id }
            : null,
      );

      const selectedSender = sendersData?.find((sender) => sender._id === clickNode?.platFormId);
      setValue(
         'senders',
         clickNode?.platFormId ? { label: selectedSender?.phone, value: clickNode?.platFormId } : null,
      );
   }, [clickedNodeId, leftNodes, setValue, sendersData]);

   useEffect(() => {
      const clickedNode = leftNodes.find((node) => node.id === clickedNodeId);
      setselectedTemplate([
         {
            _id: clickedNode?.data.flowBlocks[0]?._id,
            vertical: clickedNode?.data.flowBlocks[0]?.vertical,
            message: clickedNode?.data.flowBlocks[0]?.message,
            qpienFields: clickedNode?.data.flowBlocks[0]?.qpienFields,
            buttons: clickedNode?.data.flowBlocks[0]?.buttons,
            sampleMedia: clickedNode?.data.flowBlocks[0]?.sampleMedia,
         },
      ]);
   }, [clickedNodeId]);

   function isObjectNotEmpty(obj: { name?: string }) {
      return Object.keys(obj).length > 0;
   }

   useEffect(() => {
      setLeftNodes((prevNodes) => {
         return prevNodes.map((node) => {
            if (node.id === clickedNodeId) {
               const updatedData = {
                  ...node.data,
                  flowBlocks: selectedTemplate,
               };

               return {
                  ...node,
                  data: updatedData,
               };
            }

            return node;
         });
      });
   }, [selectedTemplate, clickedNodeId]);

   useEffect(() => {
      const firstMessageNode = leftNodes.find((node) => node.id === '3-first');
      if (firstMessageNode) {
         const h = calculateTextareaSize(selectedTemplate[0]?.message);
         setHeigherNode(h.rows);
      }
   }, [selectedTemplate]);

   const handleSelectedTemplate = (currentTemplate: any) => {
      const selectedTemplate = templateData.find((template) => template._id === currentTemplate.value);
      if (selectedTemplate) {
         setselectedTemplate((templates) => {
            return templates.map((template) => {
               const updatedTemplate = {
                  ...template,
                  _id: selectedTemplate?._id,
                  vertical: selectedTemplate?.vertical,
                  message: selectedTemplate?.message,
                  qpienFields: selectedTemplate?.qpienFields,
                  buttons: selectedTemplate?.buttons,
                  sampleMedia: selectedTemplate?.sampleMedia,
               };
               return updatedTemplate;
            });
         });
      }
   };

   const { data: whatsappBusinessPlatfromData, loading: loadingPlatform2 } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.WHATS_APP_BUSINESS, customer: person.customer._id },
      fetchPolicy: 'cache-first',
   });

   useEffect(() => {
      setSendersData(
         whatsappBusinessPlatfromData?.getPlatforms?.data?.filter((platform: any) => platform.status === 'Approved'),
      );
   }, [whatsappBusinessPlatfromData]);

   const handleSenderChange = (selectedPlatform: { label: string; value: string }) => {
      if (selectedPlatform) {
         setLeftNodes((prevNodes) => {
            return prevNodes.map((node) => {
               const updatedData = {
                  ...node.data,
                  platFormId: selectedPlatform.value,
               };

               return {
                  ...node,
                  data: updatedData,
               };
            });
         });
      }
   };

   const [getTemplates] = useLazyQuery(GET_TEMPLATES, {
      fetchPolicy: 'no-cache',
   });

   const [getTemplatesCount] = useLazyQuery(GET_TEMPLATES_COUNT, {
      fetchPolicy: 'no-cache',
   });

   const getTemplateLoadOptions = async (prevOptions: any[]) => {
      const getTemplatesCountRequestData = await getTemplatesCount({
         variables: {
            customer: person.customer._id,
            eCommerceCart: true,
         },
      })
         .then((res) => res.data.getAllTemplatesPaginateCount.data)
         .catch(() => 0);

      const getTemplatesRequestData = await getTemplates({
         variables: {
            startDate: null,
            pageSize: TEMPLATE_PAGE_SIZE,
            pageNumber: prevOptions.length === 0 ? 1 : prevOptions.length / TEMPLATE_PAGE_SIZE + 1,
            customer: person.customer._id,
            eCommerceCart: true,
         },
      })
         .then((res) => {
            setTemplateData((prev) => [...prev, ...res.data.getAllTemplatesPaginate.data]);
            return res.data.getAllTemplatesPaginate.data;
         })
         .catch(() => []);

      return {
         options: getTemplatesRequestData.map(({ vertical, _id }: { vertical: string; _id: string }) => ({
            value: _id,
            label: vertical,
         })),

         hasMore: prevOptions.length + getTemplatesRequestData.length < getTemplatesCountRequestData,
      };
   };

   return (
      <div style={{ marginBottom: 50 }}>
         <div style={{ marginBottom: 10 }}>
            {clickedNodeId === '3-first' && (
               <SingleInput
                  name='senders'
                  type='senders'
                  label={t['automation_sender_account']}
                  placeholder={<div>{t.atomation_flow_select}</div>}
                  isSelect
                  autoHeight
                  control={control}
                  maxMenuHeight={190}
                  selectOptions={sendersData?.map(({ phone, _id }) => ({
                     value: _id,
                     label: phone,
                  }))}
                  value={watch('senders')}
                  onChange={(sender: { label: string; value: string }) => {
                     handleSenderChange(sender);
                  }}
               />
            )}
            <SingleInput
               name='templates'
               type='templates'
               label={t['automation_sender_message']}
               placeholder={<div>{t.atomation_flow_select}</div>}
               isPaginateSelect
               autoHeight
               control={control}
               maxMenuHeight={190}
               selectOptions={templateData?.map(({ vertical, _id }) => ({
                  value: _id,
                  label: vertical,
               }))}
               value={watch('templates')}
               loadOptions={() => getTemplateLoadOptions(templateData)}
               onChange={(template: any) => {
                  handleSelectedTemplate(template);
               }}
            />
         </div>

         {selectedTemplate[0]?._id && (
            <div>
               {selectedTemplate?.map((item, index) => (
                  <Grid key={item._id} className={classes.templateContainer}>
                     {/* Content for each selected template */}
                     <Grid style={{ position: 'relative' }}>
                        <textarea
                           id={`textarea-${item._id}`}
                           key={item._id}
                           className={classes.textArea}
                           name='rawText'
                           placeholder={'Empty template'}
                           readOnly
                           value={item.message?.split(' | ')[0].trim()}
                           {...calculateTextareaSize(item.message?.split(' | ')[0].trim())}
                        />
                     </Grid>
                     {item.qpienFields?.map((qpien) => (
                        <Grid key={qpien.key} className={classes.qpienFiels}>
                           <div style={{ fontSize: 20, marginTop: 5 }}>(({qpien.key}))</div>
                           <div className={classes.field}>{qpien.value}</div>
                        </Grid>
                     ))}
                     {/* Additional content for the template */}
                     {item?.sampleMedia && isObjectNotEmpty(item?.sampleMedia) && (
                        <Grid>
                           <div style={{ fontSize: 20 }}>{t.campaign_replace_media}</div>
                           <div className={classes.preview}>
                              <span>{item?.sampleMedia?.name}</span>
                           </div>
                        </Grid>
                     )}
                     {/* Additional content for the template */}
                     {item.buttons[0]?.type === 'URL' && (
                        <Grid>
                           <div style={{ fontSize: 20 }}>{t.automation_sender_dynamic_URL}</div>
                           {item.buttons?.map((btn, index) => (
                              <div>
                                 {btn.type === 'URL' && (
                                    <div>
                                       <div>{btn.url}</div>
                                       <Grid className={classes.qpienFiels}>
                                          <div style={{ fontSize: 20, marginTop: 5 }}>(({index + 1}))</div>
                                          <div className={classes.field}>{btn.example[0]}</div>
                                       </Grid>
                                    </div>
                                 )}
                              </div>
                           ))}
                        </Grid>
                     )}
                  </Grid>
               ))}
            </div>
         )}
      </div>
   );
};

export default SendMsgBlocks;

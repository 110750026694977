import React, { useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider } from '@material-ui/core';
import moment, { MomentInput } from 'moment';
import './style.css';
import { MyTheme } from '@styles/config';
import { LanguageContext } from '@helper/locale/langContext';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from 'react-datepicker';

export interface DateRangePickerProps {
  onDateFilterChange: (start: number, end: number) => void;
  startDate?: MomentInput;
  endDate?: MomentInput;
  containerStyle?: any;
}

const useStyles = makeStyles((theme: MyTheme) => ({
  datePicker: {
    backgroundColor: 'white',
    zIndex: 10000,
    maxHeight: '38px',
    borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
    borderRadius: 10,
    border: '1px solid ',
    width: '100%',
    height: 38,
    alignItems:'center',
    justifyContent:'space-between',
    flexWrap:'nowrap',
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 10,
  },
  input: {
    width: '100%',
    textAlign: 'center',
    borderWidth: 0,
  },
  inputSeparator: {
    margin: 10,
  },
  datePickerChildrenDivider: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    height: '1px',
  },
  datePickerChildren: {
    padding: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  datePickerChildrenRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const DateRangePicker = ({ startDate, endDate, onDateFilterChange, containerStyle }: DateRangePickerProps) => {
  const classes = useStyles();
  const {lang: { translation: t }}: any = useContext(LanguageContext);

  const DatePickerChildren = useCallback(() => {
    return (
      <div >
        <Divider className={classes.datePickerChildrenDivider}/>
        <div className={classes.datePickerChildren}>
          <div className={classes.datePickerChildrenRow}>
            <Button
              centerRipple={false}
              disableRipple={true}
              disableTouchRipple={true}
              focusRipple={false}
              color="primary"
              onClick={() => onDateFilterChange(moment().valueOf(),moment().valueOf())}
            >
              {t['page_dashboard_typo_today']}
            </Button>
            <Button
              centerRipple={false}
              disableRipple={true}
              disableTouchRipple={true}
              focusRipple={false}
              color="primary"
              onClick={() => {onDateFilterChange(moment().subtract(1, 'day').valueOf(),moment().subtract(1, 'day').valueOf())}}
            >
              {t['page_dashboard_typo_yesterday']}
            </Button>
          </div>
          <div className={classes.datePickerChildrenRow}>
            <Button
              centerRipple={false}
              disableRipple={true}
              disableTouchRipple={true}
              focusRipple={false}
              color="primary"
              onClick={() => {onDateFilterChange(moment().subtract(7, 'days').valueOf(),moment().valueOf())}}
            >
              {t['page_dashboard_typo_this_week']}
            </Button>
            <Button
              centerRipple={false}
              disableRipple={true}
              disableTouchRipple={true}
              focusRipple={false}
              color="primary"
              onClick={() => {onDateFilterChange(moment().subtract(1, 'month').valueOf(),moment().valueOf())}}
            >
              {t['page_dashboard_typo_this_month']}
            </Button>
          </div>
        </div>
      </div>
    );
  }, []);

  const momentStartDateToDate = moment(startDate).toDate();
  const momentEndDateToDate = moment(endDate).toDate();

  return (
    <div className={classes.datePicker} style={containerStyle}>
      <DatePicker
        selectsStart
        dateFormat={'dd/MM/yyyy'}
        shouldCloseOnSelect={false}
        selected={momentStartDateToDate}
        startDate={momentStartDateToDate}
        endDate={momentEndDateToDate}
        maxDate={momentEndDateToDate}
        onChange={(date) => onDateFilterChange(moment(date).valueOf(), moment(endDate).valueOf())}
        className={classes.input}
      >
        <DatePickerChildren />
      </DatePicker>
      <p className={classes.inputSeparator}> - </p>
      <DatePicker
        selectsEnd
        dateFormat={'dd/MM/yyyy'}
        shouldCloseOnSelect={false}
        selected={momentEndDateToDate}
        startDate={momentStartDateToDate}
        endDate={momentEndDateToDate}
        minDate={momentStartDateToDate}
        maxDate={new Date()}
        onChange={(date) => onDateFilterChange(moment(startDate).valueOf(), moment(date).valueOf())}
        className={classes.input}
      >
        <DatePickerChildren />
      </DatePicker>
    </div>
  );
};

export default DateRangePicker;

import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';
import { GET_CUSTOMER_SESSIONS, VERIFY_MAIL_MUTATION } from '@queries/Person/Service/GraphQL/mutation';
import { GET_APP_PREFERENCE_QUERY } from '../../../queries/Person/Service/GraphQL/query';
import { useLocation } from 'react-router-dom';
import { CHANGE_PROJECT_VERSION, SIGN_IN } from '@store/actions/person';
import { useDispatch } from 'react-redux';
import { PROJECT_VERSION } from '@helper/config';
import { persistor } from '@store/store';

const LoginByAdmin = () => {
   const dispatch = useDispatch();


   const [getSessionMutation] = useMutation(GET_CUSTOMER_SESSIONS);

   const loginHandler = async () => {

      const queryParams = new URLSearchParams(window.location.search);
      let token: any = queryParams.get('token');
      let customerId: any = queryParams.get('customerId');

      getSessionMutation({
         variables: {
            customerId,
            token
         },
      })
         .then(async ({ data: { getCustomerSessionByAdmin } }) => {
            const { token, person } = getCustomerSessionByAdmin.data;
            dispatch({
               type: CHANGE_PROJECT_VERSION,
               payload: { projectVersion: PROJECT_VERSION },
            });
            // set store token
            dispatch({ type: SIGN_IN, payload: { token, person } });
            await persistor.flush();
         })
         .catch((e) => alert(e));
   };

   useEffect(() => {
      loginHandler();
   }, []);

   return <>loading...</>;
};

export default WithUrlTranslations(LoginByAdmin);

import { NotificationIcon } from '@assets/icons/NotificationIcons/notificationIcon';
import { DissatisfiedIcon, NeutralIcon, SatisfiedIcon } from '@assets/icons/SurveyIcons';
import { LanguageContext } from '@helper/locale/langContext';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: 20,
      marginBottom: 20,
      opacity: 0.8,
   },
   content: {
      backgroundColor: '#fff',
      borderRadius: 15,
      padding: 6,
      paddingLeft: 10,
      paddingRight: 10,
      maxWidth: 650,
      textAlign: 'left',
   },
   footer: {
      color: '#7F92A5',
      [theme.breakpoints.down('xs')]: {
         maxWidth: 220,
      },
   },
   notificationIcon: {
      width: 15,
      height: 15,
      fill: '#7F92A5',
   },
   ratingContainer: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: '0px 0px 4px 2px rgba(0,0,0,0.1)',
      justifyContent: 'center',
      alignItems: 'center',
      width: '300px',
      borderRadius: '6px',
   },
   ratingHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
   },
   ratingTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      margin: '10px 0 0 10px',
   },
   ratingTitle: {
      fontWeight: '500',
      marginBottom: '10px',
   },
   ratingEmoji: {
      border: '1px solid #DBDBDB',
      borderRadius: '4px',
      padding: '4px 4px 0 4px',
      margin: '10px',
      cursor: 'default',
   },
   textContainer: {
      width: '100%',
      borderTop: '1px solid rgba(0,0,0,0.1)',
      padding: '10px',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
   },
}));

const SurveyEvent = ({ text, time, rating }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const renderRating = (rating) => {
      switch (rating) {
         case 'Dissatisfied':
            return <DissatisfiedIcon height='30' width='30' />;
         case 'Neutral':
            return <NeutralIcon height='30' width='30' />;
         case 'Satisfied':
            return <SatisfiedIcon height='30' width='30' />;
         default:
            return '';
      }
   };

   const whichRatingTextColor = (rating) => {
      switch (rating) {
         case 'Dissatisfied':
            return '#FF4D4D';
         case 'Neutral':
            return '#6E6E6E';
         case 'Satisfied':
            return '#1ac31a';
         default:
            return '#000';
      }
   };

   return (
      <Grid container justifyContent='center' className={classes.root}>
         <div className={clsx(classes.content)}>
            <Grid container xs={12} justifyContent='center' alignItems='center' wrap='nowrap'>
               <Grid item xs={3}>
                  <NotificationIcon className={classes.notificationIcon} />
               </Grid>
               <Grid style={{ paddingLeft: 12 }} item xs={9}>
                  <div className={classes.ratingContainer}>
                     <div className={classes.ratingHeader}>
                        <div className={classes.ratingTextContainer}>
                           <span
                              style={{
                                 fontWeight: '600',
                              }}
                           >
                              {t['feedback']}
                           </span>
                           <span
                              style={{ color: whichRatingTextColor(rating) }}
                              className={classes.ratingTitle}
                           >
                              {t[rating?.toLowerCase()]}
                           </span>
                        </div>
                        <span className={classes.ratingEmoji}>{renderRating(rating)}</span>
                     </div>
                     {text && <div className={classes.textContainer}>{text}</div>}
                  </div>
               </Grid>
               <Typography
                  style={{ minWidth: '85px' }}
                  align='center'
                  variant='subtitle2'
                  className={classes.footer}
               >
                  {time}
               </Typography>
            </Grid>
         </div>
      </Grid>
   );
};

export default SurveyEvent;

import { BulkMessageStateData, SenderType, SendingOption } from '@modules/BulkMessaging/types';
// default initial state
const INITIAL_STATE: BulkMessageStateData = {
   whatsAppSendFolderNames: false,
   telegramSendFolderNames: false,
   sender: SenderType.WhatsappGroups,
   selectedChatIds: [],
   selectedPersonGroupIds: [],
   lastSelectedMenu: SenderType.WhatsappGroups,
   telegramSendingType: SendingOption.Manualy,
};

export type FilterStateAction = { type: 'CHANGE_BULK_MESSAGING_STATE'; payload: BulkMessageStateData };

const reducer = (state = INITIAL_STATE, action: FilterStateAction): BulkMessageStateData => {
   switch (action.type) {
      case 'CHANGE_BULK_MESSAGING_STATE':
         return {
            ...state,
            ...action.payload,
         };
      default:
         return state;
   }
};

export const getBulkMessageState = (state: any) => state.bulkMessagingReducer as BulkMessageStateData;
export default reducer;

import { Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import MyTypography from '@components/MyTypography';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme) => ({
   root: {
      padding: theme.spacing(1),
      background: '#fff',
      borderRadius: 8,
   },
   headerIcon: {
      color: '#FFAD60',
      fontSize: '2.5rem',
   },
   divider: {
      width: '100%',
      marginTop: 4,
   },
   childItem: {
      borderRadius: 8,
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      margin: '6px 10px',
      [theme.breakpoints.down('xs')]: {
         textAlign: 'center',
      },
   },
   hintIcon: {
      [theme.breakpoints.down('xs')]: {
         width: '100%',
         marginBottom: 4,
      },
   },
}));

const getItems = (t) => [
   {
      title: t?.dashboard_tips_title_1,
      description: t?.dashboard_tips_description_1,
      iconColor: 'blue',
   },
   {
      title: t?.dashboard_tips_title_2,
      description: t?.dashboard_tips_description_2,
      iconColor: 'lightgreen',
   },
   {
      title: t?.dashboard_tips_title_3,
      description: t?.dashboard_tips_description_3,
      iconColor: '#F56B7B',
   },
   {
      title: t?.dashboard_tips_title_4,
      description: t?.dashboard_tips_description_4,
      iconColor: 'lightgrey',
   },
];

const HintSection = () => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   const items = getItems(t);
   return (
      <Grid container className={classes.root}>
         <Grid container alignItems={'center'} item xs={12}>
            <EmojiObjectsIcon className={classes.headerIcon} />
            <MyTypography>{t?.important_tips}</MyTypography>
            <Divider className={classes.divider} />
         </Grid>

         <Grid container style={{ marginTop: 12 }} spacing={2}>
            {items.map((item) => (
               <Grid
                  key={item.iconColor + item.title}
                  alignItems={'center'}
                  item
                  container
                  className={classes.childItem}
                  xs={12}
               >
                  <EmojiObjectsIcon className={classes.hintIcon} style={{ color: item.iconColor }} fontSize={'large'} />
                  <Grid item style={{ paddingLeft: 8 }} xs={11}>
                     <MyTypography bold color='initial'>
                        {item.title}
                     </MyTypography>
                     <MyTypography variant={'subtitle2'}>{item.description}</MyTypography>
                  </Grid>
               </Grid>
            ))}
         </Grid>
      </Grid>
   );
};

export default HintSection;

import React, { FC, useContext } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MyTheme } from '../../../../styles/config';
import { LanguageContext } from '../../../../helper/locale/langContext';
import ButtonCancel from '../../../../components/ButtonCancel/buttonCancel';
import ButtonOK from '../../../../components/ButtonOK/buttonOk';
import Modal from '../../../../components/Modal/modal';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';

const useStyles = makeStyles((theme: MyTheme) => ({
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 436,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '75px 25px',
      textAlign: 'center',
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7'
      }
   },
}));

interface AreYouSureProps {
   isVisible: boolean;
   actionType: 'disconnect' | 'delete';
   onCancel: () => void;
   onSubmit: () => void;
}

const AreYouSure: FC<AreYouSureProps> = ({ isVisible, actionType, onCancel, onSubmit }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const headerTitle = actionType === 'disconnect' ? t['disconnect?'] : t['delete_account?'];
   const description =
      actionType === 'disconnect'
         ? t['are_you_sure_you_want_to_disconnect_this_account?']
         : t['are_you_sure_you_want_to_delete_this_account?'];
   const buttonLabel = actionType === 'disconnect' ? t['comp_button_disconnect'] : t['comp_button_delete'];

   return (
      <Modal paperStyle={{ width: 450 }} open={isVisible} onClose={onCancel} modalHeader={headerTitle}>
      <ModalLargeText style={{ textAlign: 'center' }}>{t['are_you_sure_you_want_to_delete_ecommerce_account']}</ModalLargeText>
      <ModalLargeText style={{ textAlign: 'center' }}>{t['are_you_sure_you_want_to_delete_this_account_warning']}</ModalLargeText>
      <ModalButtonField>
         <ButtonCancel
            label={t['comp_button_cancel']}
            className={classes.modalButtons}
            onClick={onCancel}
            style={{
               color: '#212121',
               backgroundColor: '#eeee',
            }}
            />
            <ButtonOK
               className={classes.modalButtons}
               style={{
                  color: 'white',
                     backgroundColor: '#ef5350',
                  float: 'right'
               }}
               label={buttonLabel}
               onClick={onSubmit}
            />
      </ModalButtonField>
   </Modal>
   );
};

export default AreYouSure;

import { useLazyQuery } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, ButtonGroup, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Delete from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { GET_TEMPLATES, GET_TEMPLATES_COUNT } from '@queries/Campaigns/Service/GraphQL/query';
import { IStateTemplateData } from '../types';
import RemoveTemplate from './RemoveTemplate';
import { LangCodes } from '@helper/language';

const useStyles = makeStyles({
   table: {
      height: '100%',
   },
   Table: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
         outline: 'none',
      },
   },
   rowStyle: {
      color: '#385273',
      fontWeight: 'bold',
      fontSize: '1rem',
   },
   btnGroup: {
      '& > *:first-child': {
         border: 'none !important',
      },
   },
});

const TemplatesTable = ({ appId }: any) => {
   const classes = useStyles();
   const person = useSelector((reducer: any) => reducer.personReducer.person);
   let customer = person.customer._id;
   const { lang } = useContext(LanguageContext);
   const [isRemoveModalVisible, setRemoveModalVisible] = useState(null);
   const t = lang?.translation;

   const [templateData, setTemplateData] = useState<IStateTemplateData>({
      data: [],
      totalLength: 0,
   });

   const [queryParams, setQueryParams] = useState({
      pageNumber: 1,
      pageSize: 15,
      customer,
      allStatus: true,
      appId,
      startDate: null,
      eCommerceCart: null, // All templates
   });

   const columns = [
      {
         field: 'vertical',
         headerName: t['settings_whatsapp_business_template_form_name'],
         flex: 2,
         editable: false,
         minWidth: 150,
         renderCell: (data: any) => {
            if (data?.row.vertical) {
               return <span style={{ marginLeft: 8 }}>{data.row.vertical}</span>;
            } else {
               return <div style={{ width: 20, height: 2, marginLeft: 8, background: 'grey' }} />;
            }
         },
      },
      {
         field: 'category',
         flex: 1,
         headerName: t['settings_whatsapp_business_template_category'],
         minWidth: 130,
         editable: false,
         renderCell: (data: any) => {
            switch (data?.row.category) {
               case 'AUTHENTICATION':
                  return <p>{t['settings_whatsapp_business_template_category_authentication']}</p>;
               case 'MARKETING':
                  return <p>{t['settings_whatsapp_business_template_category_marketing']}</p>;
               case 'UTILITY':
                  return <p>{t['settings_whatsapp_business_template_category_utility']}</p>;
            }
         },
      },
      {
         field: 'status',
         flex: 1,
         headerName: t['settings_whatsapp_business_template_status'],
         minWidth: 110,
         editable: false,
         renderCell: (data: any) => {
            switch (data.row.status) {
               case 'APPROVED':
                  return <span style={{ color: '#60de65' }}>{t?.settings_whatsapp_b_approved}</span>;
               case 'PENDING':
                  return <span style={{ color: '#FFA000' }}>{t?.settings_whatsapp_b_pending}</span>;
               case 'REJECTED':
                  return (
                     <Tooltip title={data.row.reason || ''}>
                        <span style={{ color: '#FF0000' }}>{t?.settings_whatsapp_b_rejected}</span>
                     </Tooltip>
                  );
               default:
                  return <span style={{ color: 'blue' }}>{t?.settings_whatsapp_b_rejected}</span>;
            }
         },
      },
      {
         field: 'language',
         flex: 1,
         headerName: t['settings_whatsapp_business_template_language'],
         minWidth: 100,
         editable: false,
         renderCell: (data: any) => {
            return <span>{LangCodes.find((x) => x.value === data.row.language)?.label}</span>;
         },
      },
      {
         field: 'edit',
         minWidth: 120,
         headerName: t['settings_whatsapp_business_template_edit'],
         editable: false,
         renderCell: (data: any) => {
            return (
               <ButtonGroup variant='text' className={classes.btnGroup}>
                  <Button
                     style={{ border: 'none !important' }}
                     onClick={() => {
                        window.location.href = `/settings/platforms/whatsapp-business/showTemplate/${appId}/${data.id}`;
                     }}
                  >
                     <VisibilityIcon />
                  </Button>
                  <Button
                     onClick={() => {
                        setRemoveModalVisible(data.row.templateId);
                     }}
                  >
                     <Delete style={{ color: 'red' }} />
                  </Button>
               </ButtonGroup>
            );
         },
      },
   ];

   const [getTemplates] = useLazyQuery(GET_TEMPLATES, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getAllTemplatesPaginate }) => {
         let data = getAllTemplatesPaginate.data.map((x: any) => {
            return {
               id: x._id,
               templateId: x.templateId,
               vertical: x.vertical,
               category: x.category,
               status: x.status,
               language: x.languageCode,
               reason: x.reason,
               edit: '',
            };
         });
         setTemplateData((prevState) => ({
            ...prevState,
            data,
         }));
      },
   });

   const [getTemplatesCount, { data: dataCampaignsCount }] = useLazyQuery(GET_TEMPLATES_COUNT, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getAllTemplatesPaginateCount }) => {
         setTemplateData((prevState) => ({
            ...prevState,
            totalLength: getAllTemplatesPaginateCount.data,
         }));
      },
   });

   useEffect(() => {
      getTemplates({
         variables: {
            ...queryParams,
         },
      });
      getTemplatesCount({
         variables: {
            ...queryParams,
         },
      });
   }, [getTemplates, getTemplatesCount, queryParams]);

   return (
      <Grid container className={classes.table}>
         <DataGrid
            loading={!dataCampaignsCount}
            localeText={{
               toolbarColumns: '',
               noRowsLabel: t?.no_data_to_show,
            }}
            rowHeight={64}
            className={classes.Table}
            classes={{ columnHeader: classes.rowStyle }}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            columns={columns}
            pageSize={15}
            rowCount={templateData.totalLength}
            paginationMode='server'
            page={queryParams.pageNumber - 1}
            onPageChange={(params) => {
               setQueryParams((prevState) => ({
                  ...prevState,
                  pageNumber: params + 1,
               }));
            }}
            rows={templateData.data}
         />
         <RemoveTemplate
            open={!!isRemoveModalVisible}
            onClose={(isRemoved: boolean) => {
               if (isRemoved) {
                  setQueryParams({
                     ...queryParams,
                     pageNumber: queryParams.pageNumber,
                  });
               }
               setRemoveModalVisible(null);
            }}
            templateId={isRemoveModalVisible}
         />
      </Grid>
   );
};

export default TemplatesTable;

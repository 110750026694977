import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { NotificationIcon } from '@assets/icons/NotificationIcons/notificationIcon';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: 20,
      marginBottom: 20,
      opacity: 0.8,
   },
   content: {
      backgroundColor: '#fff',
      borderRadius: 15,
      padding: 6,
      paddingLeft: 10,
      paddingRight: 10,
      maxWidth: 650,
      textAlign: 'left',
      border: '1px solid #eee',
   },
   footer: {
      // fontSize: '0.8rem',
      color: '#7F92A5',
      [theme.breakpoints.down('xs')]: {
         maxWidth: 220,
      },
   },
   metaData: {
      fontSize: '0.8rem',
      // marginTop: 10,
      paddingLeft: 10,
      color: 'grey',
   },
   notificationIcon: {
      width: 15,
      height: 15,
      fill: '#7F92A5',
   },
}));

const detectLang = (text, lang) => {
   // yeniAd/chat_mutation_tag_replace/#577590/eskiAd/eskiColor
   const t = lang?.translation;
   if (
      text.includes('chat_mutation_tag_add') ||
      text.includes('chat_mutation_tag_remove' || text.includes('chat_mutation_tag_replace'))
   ) {
      const str = text.split('/');
      const newTagColorCode = str[2];
      const oldTagColorCode = str[4];
      return {
         tagNameNew: '#' + str[0],
         tagNameOld: str[3] ? '#' + str[3] + ' ==> ' : '',
         tagEvent: t[str[1]],
         logColorNew: newTagColorCode,
         logColorOld: str[3] ? '' : oldTagColorCode,
         code: 1,
      };
   } else if (
      text.includes('chat_archive_log') ||
      text.includes('chat_out_participant_log') ||
      text.includes('chat_waiting_log') ||
      text.includes('chat_close_log')
   ) {
      return { tagNameNew: '', tagNameOld: '', tagEvent: t[text], logColorNew: '', logColorOld: '', code: 0 };
   } else if (text.includes('chat_page_auto_redirect')) {
      return { tagNameNew: '', tagNameOld: '', tagEvent: t[text], logColorNew: '', logColorOld: '', code: 2 };
   } else if (text.includes('chat_page_transfer_chat')) {
      const str = text.split('/');
      const newPersonName = str[1];
      const transferedLog = t[str[0]];
      const transferedPerson = ' ==> ' + newPersonName;

      return {
         // tagNameOld: transferedPerson,
         tagEvent: transferedLog + transferedPerson,
         code: 1,
         // logColorOld: '#000000',
      };
   } else if (text.includes('chat_timeout_left_time')) {
      return;
   } else {
      return { tagNameNew: '', tagNameOld: '', tagEvent: t[text], logColorNew: '', logColorOld: '', code: 1 };
   }
};

const Event = ({ sender, text, time }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const tagInfo = useMemo(() => detectLang(text, lang), [lang, text]);
   const logColorNew = tagInfo?.logColorNew;
   const logColorOld = tagInfo?.logColorOld;
   const tagNameNew = tagInfo?.tagNameNew;
   const tagNameOld = tagInfo?.tagNameOld;
   const tagEvent = tagInfo?.tagEvent;
   const codeText = tagInfo?.code;

   return (
      <Grid container justifyContent='center' className={classes.root}>
         <div className={clsx(classes.content)}>
            <Grid container xs={12} justifyContent='center' alignItems='center' wrap='nowrap'>
               <Grid item xs={3}>
                  <NotificationIcon className={classes.notificationIcon} />
               </Grid>
               <Grid style={{ paddingLeft: 12 }} item xs={9}>
                  <Typography variant='subtitle2' align='center' className={classes.footer} noWrap>
                     {codeText === 1 ? (sender?.name ? sender.name + ' - ' : 'Deleted User - ') : ''}
                     {tagNameOld && (
                        <Typography
                           component='span'
                           variant='subtitle1'
                           style={{ color: logColorOld, fontWeight: 'bold' }}
                        >
                           {tagNameOld}
                        </Typography>
                     )}
                     {tagNameNew && (
                        <Typography
                           component='span'
                           variant='subtitle1'
                           style={{ color: logColorNew, fontWeight: 'bold' }}
                        >
                           {tagNameNew}
                        </Typography>
                     )}
                     {tagEvent}
                     {codeText === 2 ? (sender?.name ? ' - ' + sender.name : ' - Deleted User') : ''}
                  </Typography>
               </Grid>
               <Typography style={{ minWidth: '100px' }} align='center' variant='subtitle2' className={classes.footer}>
                  {time}
               </Typography>
            </Grid>
         </div>
      </Grid>
   );
};

export default Event;

import { gql } from '@apollo/client';

// mutations
export const UPDATE_CHAT_STATUS_MUTATION = gql`
   mutation updateChatStatusMutation($_id: ID!, $status: Int!, $person: ID!) {
      updateChatStatus(data: { _id: $_id, status: $status, person: $person }) {
         data {
            _id
            participants {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               setup
               createdAt
            }
            status
            isJoinable
            joinStatus
         }
         success
         code
         message
      }
   }
`;
export const JOIN_CHAT_MUTATION = gql`
   mutation joinChatMutation($_id: ID!, $person: ID!) {
      joinChat(data: { _id: $_id, person: $person }) {
         data {
            _id
            participants {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               setup
               createdAt
            }
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            joinStatus
            isJoinable
            firstLog
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const LEAVE_CHAT_MUTATION = gql`
   mutation leaveChatMutation($_id: ID!, $person: ID!) {
      leaveChat(data: { _id: $_id, person: $person }) {
         data {
            _id
            participants {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               setup
               createdAt
            }
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            joinStatus
            isJoinable
            firstLog
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const TRANSFER_CHAT_MUTATION = gql`
   mutation transferChatMutation($_id: ID!, $from: ID!, $to: ID!) {
      transferChat(data: { _id: $_id, from: $from, to: $to }) {
         data {
            _id
            participants {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               setup
               createdAt
            }
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            joinStatus
            isJoinable
            firstLog
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const CHANGE_CHAT_TAG_MUTATION = gql`
   mutation changeChatTagMutation($_id: ID!, $tag: ID, $person: ID!) {
      changeChatTag(data: { _id: $_id, tag: $tag, person: $person }) {
         data {
            _id
            tag {
               _id
               name
               color
            }
         }
         success
         code
         message
      }
   }
`;

export const SIGN_AS_ARCHIVE_CHAT_MUTATION = gql`
   mutation signAsArchiveChatMutation($_id: ID!, $person: ID!) {
      signAsArchive(data: { _id: $_id, person: $person }) {
         data {
            _id
            participants {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               setup
               createdAt
            }
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            joinStatus
            isJoinable
            firstLog
            status
            createdAt
         }
         success
         message
         code
      }
   }
`;

export const UPDATED_JOINED_CHAT_STATUS_MUTATION = gql`
   mutation updateJoinedChatsStatusMutation($me: String!, $status: Int!) {
      updateJoinedChatsStatus(data: { me: $me, status: $status }) {
         data
         success
         message
         code
      }
   }
`;

import React from 'react';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import CancelIcon from '@material-ui/icons/Cancel';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { makeStyles } from '@material-ui/core/styles';
import { MyTheme } from '@styles/config';

type ActionType = 'EDIT' | 'DELETE' | 'REFRESH' | 'CONNECT' | 'DISCONNECT' | 'VIEW';

interface ActionIconButtonProps {
   onClick: () => void;
   disabled?: boolean | undefined;
   tooltip: string;
   actionType: ActionType;
}

const getIcon = (actionType: ActionType) => {
   switch (actionType) {
      case 'EDIT':
         return <EditIcon />;
      case 'DELETE':
         return <DeleteIcon />;
      case 'REFRESH':
         return <RefreshIcon />;
      case 'CONNECT':
         return <CheckCircleIcon />;
      case 'DISCONNECT':
         return <CancelIcon />;
      case 'VIEW':
         return <VisibilityIcon />;
      default:
         return null;
   }
};

interface StyleProps {
   actionType: ActionType;
}

const useStyles = makeStyles<MyTheme, StyleProps>(() => ({
   tooltip: {
      fontSize: '0.9rem',
   },
   button: {
      color: (props) => {
         switch (props.actionType) {
            case 'EDIT':
            case 'REFRESH':
               return '#385273';
            case 'CONNECT':
               return '#09bc19';
            case 'DELETE':
               return '#ff3d00';
            case 'DISCONNECT':
               return '#ff6200';
            default:
               return undefined;
         }
      },
   },
}));

const ActionIconButton = ({ onClick, disabled, tooltip, actionType }: ActionIconButtonProps) => {
   const classes = useStyles({ actionType });

   return (
      <Tooltip title={<Typography className={classes.tooltip}>{tooltip}</Typography>}>
         <IconButton disabled={disabled} onClick={onClick} className={classes.button}>
            {getIcon(actionType)}
         </IconButton>
      </Tooltip>
   );
};

export default ActionIconButton;

import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, ButtonBase, IconButton, Tooltip, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CodeIcon, CopyToClipboardIcon, GoogleTagManagerLogo, ShopifyLogo } from '../SharedComponents/Icons';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { useForm } from 'react-hook-form';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';
import FormSaveAndCancelButtons from '../SharedComponents/FormSaveAndCancelButtons';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
   formContainer: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: '20px 30px',
   },
   section1: {
      marginTop: '6px',
      marginBottom: '10px',
   },
   section3: {
      marginTop: '35px',
   },
   inputContainer: {
      marginTop: '-15px',
      marginBottom: '-15px',
   },
   previewLive: {
      display: 'flex',
      backgroundColor: '#F7F7FA',
      padding: '8px 16px',
      borderRadius: '5px',
      alignItems: 'center',
   },
   previewLiveLeft: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
   },
   title: {
      fontSize: '1.1rem',
      color: '#000000',
      fontWeight: 'bold',
   },
   subtitle: {
      fontSize: '.8rem',
      color: '#A7A7A7',
   },
   inputRight: {
      justifyContent: 'center',
      alignItems: 'center',
   },
   removeIconContainer: {
      padding: '5px',
      margin: '5px',
   },
   removeIcon: {
      fontSize: '25px',
      color: '#000000',
   },
   previewLiveButton: {
      maxHeight: '35px',
      color: '#FFFFFF',
      boxShadow: 'none',
      fontSize: '1rem',
      fontWeight: 500,
      background: '#5FCD6B',
      '&:hover': {
         boxShadow: 'none',
         background: '#56b761',
      },
      '&:focus': {
         boxShadow: 'none',
         background: '#56b761',
      },
   },
   addDomainButton: {
      maxHeight: '40px',
      marginTop: '5px',
      color: '#000000',
      boxShadow: 'none',
      fontSize: '1rem',
      fontWeight: 500,
      background: '#F7F7FA',
      '&:hover': {
         boxShadow: 'none',
         background: '#eeeeee',
      },
      '&:focus': {
         boxShadow: 'none',
         background: '#eeeeee',
      },
   },
   accordionContainer: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #E0E0E0',
      borderRadius: '5px',
   },
   accordionHeader: {
      display: 'flex',
   },
   accordionHeaderLeft: {
      flexShrink: 0,
      fontSize: '40px',
      width: '56px',
      height: '56px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#00C5FF',
   },
   accordionHeaderCenter: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   accordionTitle: {
      color: '#157CFC',
   },
   accordionHeaderRight: {
      flexShrink: 0,
      width: '56px',
      height: '56px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   arrowButton: {
      borderRadius: 99,
      padding: 6,
   },
   arrowIcon: {
      color: '#7C819D',
      fontSize: '24px',
      transition: '300ms',
      transform: 'rotate(90deg)',
   },
   arrowIconUp: {
      transform: 'rotate(270deg)',
   },
   accordionBody: {
      borderTop: '1px solid #E0E0E0',
      padding: '10px',
   },
   codeContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px',
      border: '1px solid #E0E0E0',
      borderRadius: '5px',
      marginTop: 8,
      marginBottom: 8,
   },
   address: {
      color: '#444F5D',
      width: '90%',
      overflowWrap: 'break-word',
   },
   copyToClipboardButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '20px',
      color: '#157cfc',
      padding: '10px',
      borderRadius: '4px',
      backgroundColor: '#F7F7F7',
   },
   question: {
      color: '#656E79',
      fontSize: '.8rem',
   },
   textButton: {
      color: '#529FFF',
   },
   formDescriptions: {
      paddingLeft: '30px',
      alignSelf: 'flex-start',
      color: '#486B91',
   },
   formDescription: {
      fontSize: '.8rem',
   },
}));

const Form = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { liveChatPreviewSettings, updateLiveChatPreviewSettings } = useContext(LiveChatSettingsContext);
   const [copyStatus, setCopyStatus] = useState(
      t['page_settings_typo_platforms_live_chat_installation_section_live_chat_code_copy_to_clipboard'] || '',
   );
   const [isJSCodeOpen, setIsOpenJSCode] = useState(true);
   const [isInput2Open, setIsInput2Open] = useState(liveChatPreviewSettings.trustedDomain2 !== '');
   const [isInput3Open, setIsInput3Open] = useState(liveChatPreviewSettings.trustedDomain3 !== '');

   const code = `
<script id="qpien_livechat_widget" src="https://api.qpien.com/Message/LIVE_CHAT/widget/${liveChatPreviewSettings.id}"> </script>
<script>
  function qpienIframeAction(action) {
    const iframe = document.getElementById('qpien-livechat-widget-iframe');
    iframe?.contentWindow.postMessage(action, '*');
  }
</script>
`;

   const { control, watch, reset, setValue } = useForm({
      mode: 'all',
      defaultValues: {
         trustedDomain1: liveChatPreviewSettings.trustedDomain1,
         trustedDomain2: liveChatPreviewSettings.trustedDomain2,
         trustedDomain3: liveChatPreviewSettings.trustedDomain3,
      },
   });

   const { trustedDomain1, trustedDomain2, trustedDomain3 } = watch();

   useEffect(() => {
      if (trustedDomain2 !== '') setIsInput2Open(true);
      if (trustedDomain3 !== '') setIsInput3Open(true);
      updateLiveChatPreviewSettings({
         trustedDomain1,
         trustedDomain2,
         trustedDomain3,
      });
   }, [trustedDomain1, trustedDomain2, trustedDomain3]);

   const increaseNumberOfInput = () => {
      if (!isInput2Open) {
         setIsInput2Open(true);
      } else if (!isInput3Open) {
         setIsInput3Open(true);
      }
   };

   const closeInput2 = () => {
      setIsInput2Open(false);
      setValue('trustedDomain2', '');
   };
   const closeInput3 = () => {
      setIsInput3Open(false);
      setValue('trustedDomain3', '');
   };

   const copyCodeToClipboard = (code) => {
      navigator.clipboard
         .writeText(code)
         .then(() => {
            setCopyStatus(
               t[
                  'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_copy_to_clipboard_success'
               ],
            );
         })
         .catch(() => {
            setCopyStatus(
               t['page_settings_typo_platforms_live_chat_installation_section_live_chat_code_copy_to_clipboard_error'],
            );
         });
   };

   return (
      <>
         <div className={classes.formContainer}>
            <Grid container alignItems='center' spacing={1}>
               {/* <Grid item md={12} className={classes.section1}>
                  <div className={classes.previewLive}>
                     <div className={classes.previewLiveLeft}>
                        <Typography className={classes.title}>
                           {t['page_settings_typo_platforms_live_chat_installation_section_preview_live_title']}
                        </Typography>
                        <Typography className={classes.subtitle}>
                           {t['page_settings_typo_platforms_live_chat_installation_section_preview_live_subtitle']}
                        </Typography>
                     </div>
                     <Button variant='contained' className={classes.previewLiveButton}>
                        {t['page_settings_typo_platforms_live_chat_installation_section_preview_live_button_label']}
                     </Button>
                  </div>
               </Grid> */}

               <Grid item md={12}>
                  <Typography className={classes.title}>
                     {t['page_settings_typo_platforms_live_chat_installation_section_live_chat_code_title']}
                  </Typography>
                  <div className={classes.accordionContainer}>
                     <div className={classes.accordionHeader}>
                        <div className={classes.accordionHeaderLeft}>
                           <CodeIcon />
                        </div>
                        <div className={classes.accordionHeaderCenter}>
                           <Typography className={classes.accordionTitle}>JavaScript</Typography>
                        </div>
                        <div className={classes.accordionHeaderRight}>
                           <ButtonBase onClick={() => setIsOpenJSCode(!isJSCodeOpen)} className={classes.arrowButton}>
                              <ArrowForwardIosRoundedIcon
                                 className={classNames(classes.arrowIcon, isJSCodeOpen && classes.arrowIconUp)}
                              />
                           </ButtonBase>
                        </div>
                     </div>
                     {isJSCodeOpen && (
                        <div className={classes.accordionBody}>
                           <Box className={classes.codeContainer} style={{ marginTop: 0 }}>
                              <Typography className={classes.address}>{code}</Typography>
                              <Tooltip title={copyStatus}>
                                 <IconButton
                                    disableRipple
                                    onClick={() => copyCodeToClipboard(code)}
                                    className={classes.copyToClipboardButton}
                                 >
                                    <CopyToClipboardIcon />
                                 </IconButton>
                              </Tooltip>
                           </Box>
                           <Typography style={{ marginBottom: 8 }}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_javascript_description'
                                 ]
                              }
                           </Typography>
                           <Divider />
                           <Typography variant='h6' style={{ marginTop: 8, marginBottom: 8 }}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_javascript_buttons_subtitle'
                                 ]
                              }
                           </Typography>
                           <Typography variant='body1' style={{ marginTop: 0, marginBottom: 5 }}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_javascript_buttons_description_1'
                                 ]
                              }
                           </Typography>
                           <Typography variant='body1' style={{ marginTop: 5, marginBottom: 5 }}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_javascript_buttons_description_2'
                                 ]
                              }
                           </Typography>
                           <Typography variant='body1' style={{ marginTop: 5, marginBottom: 5 }}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_javascript_buttons_step_1'
                                 ]
                              }
                           </Typography>
                           <Typography variant='body1' style={{ marginTop: 5, marginBottom: 10 }}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_javascript_buttons_step_2'
                                 ]
                              }
                           </Typography>
                           {[
                              `<button onClick="qpienIframeAction('openLiveChat')">Open LiveChat</button>`,
                              `<button onClick="qpienIframeAction('openWhatsApp')">Open WhatsApp</button>`,
                              `<button onClick="qpienIframeAction('openMessenger')">Open Messenger</button>`,
                              `<button onClick="qpienIframeAction('openInstagram')">Open Instagram</button>`,
                              `<button onClick="qpienIframeAction('openPhoneCall')">Open PhoneCall</button>`,
                           ].map((item) => (
                              <Box className={classes.codeContainer}>
                                 <Typography className={classes.address}>{item}</Typography>
                                 <Tooltip title={copyStatus}>
                                    <IconButton
                                       disableRipple
                                       onClick={() => copyCodeToClipboard(item)}
                                       className={classes.copyToClipboardButton}
                                    >
                                       <CopyToClipboardIcon />
                                    </IconButton>
                                 </Tooltip>
                              </Box>
                           ))}
                           <Typography className={classes.question}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_javascript_footer_question'
                                 ]
                              }{' '}
                              <a
                                 href={
                                    t[
                                       'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_javascript_footer_button_href'
                                    ]
                                 }
                                 target='_blank'
                                 className={classes.textButton}
                              >
                                 {
                                    t[
                                       'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_javascript_footer_button_text'
                                    ]
                                 }
                              </a>
                           </Typography>
                        </div>
                     )}
                  </div>
               </Grid>
               <Grid item md={12}>
                  <div className={classes.accordionContainer}>
                     <div className={classes.accordionHeader}>
                        <div className={classes.accordionHeaderLeft}>
                           <GoogleTagManagerLogo />
                        </div>
                        <div className={classes.accordionHeaderCenter}>
                           <Typography className={classes.accordionTitle}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_google_tag_manager_title'
                                 ]
                              }
                           </Typography>
                        </div>
                        <div className={classes.accordionHeaderRight} />
                     </div>
                  </div>
               </Grid>
               <Grid item md={12}>
                  <div className={classes.accordionContainer}>
                     <div className={classes.accordionHeader}>
                        <div className={classes.accordionHeaderLeft}>
                           <ShopifyLogo />
                        </div>
                        <div className={classes.accordionHeaderCenter}>
                           <Typography className={classes.accordionTitle}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_shopify_title'
                                 ]
                              }
                           </Typography>
                        </div>
                        <div className={classes.accordionHeaderRight} />
                     </div>
                  </div>
               </Grid>

               {/* <Grid item container md={12} className={classes.section3}>
                  <Grid item container md={5}>
                     <Grid item container md={10} className={classes.inputContainer}>
                        <Input
                           name='trustedDomain1'
                           label={t['page_settings_typo_platforms_live_chat_installation_section_trusted_domain_title']}
                           placeholder={t['page_settings_typo_platforms_live_chat_installation_section_trusted_domain_placeholder']}
                           labelColor='black'
                           control={control}
                           background='white'
                           rules={{
                              required: t['page_settings_typo_platforms_live_chat_installation_section_trusted_domain_required'],
                              pattern: {
                                 value: domainRegEx,
                                 message: t['page_settings_typo_platforms_live_chat_installation_section_trusted_domain_invalid'],
                              },
                           }}
                        />
                     </Grid>
                     {(isInput2Open || trustedDomain2 !== '') && (
                        <Grid item container alignItems='center' md={12}>
                           <Grid item md={10} className={classes.inputContainer}>
                              <Input
                                 name='trustedDomain2'
                                 placeholder={t['page_settings_typo_platforms_live_chat_installation_section_trusted_domain_placeholder']}
                                 labelColor='black'
                                 control={control}
                                 background='white'
                                 rules={{
                                    pattern: {
                                       value: domainRegEx,
                                       message: t['page_settings_typo_platforms_live_chat_installation_section_trusted_domain_invalid'],
                                    },
                                 }}
                              />
                           </Grid>
                           <Grid item md={2} className={classes.inputRight}>
                              <IconButton
                                 onClick={closeInput2}
                                 className={classes.removeIconContainer}
                              >
                                 <DeleteIcon className={classes.removeIcon} />
                              </IconButton>
                           </Grid>
                        </Grid>
                     )}
                     {(isInput3Open || trustedDomain3 !== '') && (
                        <Grid item container alignItems='center' md={12}>
                           <Grid item md={10} className={classes.inputContainer}>
                              <Input
                                 name='trustedDomain3'
                                 placeholder={t['page_settings_typo_platforms_live_chat_installation_section_trusted_domain_placeholder']}
                                 labelColor='black'
                                 control={control}
                                 background='white'
                                 rules={{
                                    pattern: {
                                       value: domainRegEx,
                                       message: t['page_settings_typo_platforms_live_chat_installation_section_trusted_domain_invalid'],
                                    },
                                 }}
                              />
                           </Grid>
                           <Grid item md={2} className={classes.inputRight}>
                              <IconButton
                                 onClick={closeInput3}
                                 className={classes.removeIconContainer}
                              >
                                 <DeleteIcon className={classes.removeIcon} />
                              </IconButton >
                           </Grid>
                        </Grid>
                     )}
                     {(!isInput2Open || !isInput3Open) && (
                        <Button
                           variant='contained'
                           className={classes.addDomainButton}
                           onClick={increaseNumberOfInput}
                           disabled={isInput2Open && isInput3Open}
                        >
                           {t['page_settings_typo_platforms_live_chat_installation_section_trusted_domain_add_another_domain']}
                        </Button>
                     )}
                  </Grid>
                  <Grid item container md={7} className={classes.formDescriptions}>
                     <Typography className={classes.formDescription}>
                        {t['page_settings_typo_platforms_live_chat_installation_section_trusted_description1']}
                     </Typography>
                     <Typography className={classes.formDescription}>
                        {t['page_settings_typo_platforms_live_chat_installation_section_trusted_description2']}
                     </Typography>
                     <Typography className={classes.formDescription}>
                        {t['page_settings_typo_platforms_live_chat_installation_section_trusted_description3']}
                     </Typography>
                  </Grid>

               </Grid> */}
            </Grid>
         </div>
         <FormSaveAndCancelButtons resetValues={reset} />
      </>
   );
};

export default Form;

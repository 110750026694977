import React, { useContext, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { Step, Stepper, StepLabel, Divider } from '@material-ui/core';
import messengerIcon from './messenger.png';
import Input from '@components/InputField';
import { ModalLargeText, ModalMediumText, ModalTitle } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';
const { useForm } = require('react-hook-form');

const useStyles = makeStyles((theme) => ({
   root: {
      width: 700,
      padding: 20,
      // height: 500,
      // paddingBottom: 5,
      borderRadius: 15,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   modalTitle: {
      textAlign: 'center',
      fontSize: '1.4rem',
      color: '#4681C3',
      marginBottom: 15,
      fontWeight: 'bold',
   },
   stepLabel: {
      fontSize: '1.3rem',
   },
   contentTitle: {
      fontSize: '1.2rem',
      color: '#326290',
   },
   subtitle: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginTop: 10,
   },
   informationListItem: {
      fontSize: '1.1rem',
   },
   informationListContainer: {
      marginTop: 30,
   },
   resultContainer: {
      width: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      padding: 5,
      borderRadius: 5,
   },
   statusTitle: {
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: 'bold',
   },
   icon: {
      width: 120,
      height: 120,
   },
   informationTitle: {
      fontSize: '1rem',
      marginTop: 10,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const getSteps = (t) => {
   return [t?.page_settings_modal_facebook_modal_step_information, t?.teams, t?.result];
};

const getInformationList = (t) => {
   return [
      t?.page_settings_typo_platforms_facebook_information_list_login,
      t?.page_settings_typo_platforms_facebook_information_list_chose_account,
      t?.page_settings_typo_platforms_facebook_information_list_check,
      t?.page_settings_typo_platforms_facebook_information_list_consumer,
   ];
};
const ModalContent = ({ show, handleClickFacebookLogin, onClose, teamsData }) => {
   const classes = useStyles();
   const [activeStep, setActiveStep] = useState(0);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const steps = getSteps(t);
   const informationList = getInformationList(t);

   const handleNextButton = () => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
   };

   const handleBackButton = () => {
      setActiveStep(activeStep - 1);
   };

   const {
      handleSubmit,
      control,
      formState: { isValid },
      getValues,
   } = useForm({
      mode: 'all',
      defaultValues: {
         teams: [],
      },
   });

   return (
      <Grid>
         <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
               <Step key={label}>
                  <StepLabel>
                     <Typography className={classes.stepLabel}>{label}</Typography>
                  </StepLabel>
               </Step>
            ))}
         </Stepper>
         {activeStep === 0 && (
            <Grid>
               <ModalTitle>Facebook Messenger</ModalTitle>
               <ModalLargeText>{t?.page_settings_facebook_add_account_description}</ModalLargeText>
               <Grid
                  item
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  style={{ marginTop: 20 }}
                  xs={12}
               >
                  <Grid item className={classes.informationListContainer} xs={9}>
                     {informationList.map((label, index) => (
                        <ModalMediumText key={label}>
                           {index + 1}- {label}
                        </ModalMediumText>
                     ))}
                  </Grid>
                  <Grid item>
                     <img src={messengerIcon} alt='messenger-icon' />
                  </Grid>
                  <ModalButtonField>
                     <ButtonOK
                        label={t?.next}
                        className={classes.modalButtons}
                        onClick={handleNextButton}
                        style={{
                           marginLeft: 'auto',
                           color: 'white',
                           backgroundColor: '#157CFC',
                           '&:hover': {
                              backgroundColor: '#105CBB',
                           },
                        }}
                     />
                  </ModalButtonField>
               </Grid>
            </Grid>
         )}

         {activeStep === 1 && (
            <>
               <SingleInput
                  label={t?.teams}
                  isSelect
                  isMulti
                  autoHeight
                  selectOptions={teamsData.map((team) => ({
                     label: team.teamName,
                     value: team._id,
                  }))}
                  rules={{
                     required: t?.please_fill_this_field,
                  }}
                  control={control}
                  name='teams'
                  editable
                  height={40}
                  placeholder={t?.teams}
                  helperText={t?.select_teams_for_permission}
               />
               <ModalButtonField>
                  <Button
                     className={classes.modalButtons}
                     onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        '&:hover': {
                           backgroundColor: '#e0e0e0',
                        },
                     }}
                  >
                     {t?.back}
                  </Button>
                  <ButtonOK
                     className={classes.modalButtons}
                     label={t?.connect}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        '&:hover': {
                           backgroundColor: '#105CBB',
                        },
                        float: 'right',
                     }}
                     disabled={!isValid}
                     onClick={() => handleSubmit(handleClickFacebookLogin(getValues().teams.map((team) => team.value)))}
                  />
               </ModalButtonField>
            </>
         )}
      </Grid>
   );
};

export default ModalContent;

import React, { useState, useEffect, useContext, Fragment } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Checkbox, Box, TextField, Button, Fade, Typography, Grid, Divider } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import ButtonFunction from '@components/ButtonFunction';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { SITE_URL } from '@helper/config';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { LanguageContext } from '@helper/locale/langContext';
import { useCoupon } from '../../../../hooks/useCoupon';
import { cc_expires_format, normalizeCardNumber } from '../../../Welcome/utils/validation';
import moment from 'moment';
import { priceFormat } from '@helper/functions';
import { useHistory } from 'react-router-dom';
import { DAYS } from '@lib/constants';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { AddOnName } from '@types/enums';

const RedCheckBox = withStyles({
   root: {
      color: blue[400],
      '&$checked': {
         color: blue[600],
      },
   },
   checked: {},
})((props) => <Checkbox color='default' {...props} />);

const useStyles = makeStyles((theme) => ({
   root: {
      width: 1000,
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 20,
      borderRadius: 18,
      [theme.breakpoints.down('sm')]: {
         width: 450,
      },
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      boxShadow: '0px 3px 6px #00000029',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
   },
   savedCardContainer: {
      [theme.breakpoints.up('md')]: {},
      minHeight: 280,
   },
   content: {
      height: '75vh',
      overflowY: 'auto',
   },
   divider: {
      margin: 0,
      height: 1,
   },
   cancelBtn: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginLeft: 20,
      height: 40,
   },
   renevalContainer: {
      padding: 15,
      border: '1px solid #E3E3E3',
      borderRadius: 10,
   },
   padding5: {
      padding: '5px 0px',
   },
   message: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   title: {
      fontSize: '24px',
      color: '#212121',
      fontWeight: 'bold',
      padding: theme.spacing(1),
   },
   topContainer: {
      border: 'solid 1px #E3E3E3',
      padding: '1rem 0px',
      width: '100%',
      borderRadius: 10,
      paddingBottom: 0,
      // height: '100%',
   },
   couponContainer: {
      padding: '1rem 0px',
      width: '100%',
      paddingBottom: 0,
      marginBottom: 10,
   },
   payBtnContainer: {
      marginTop: 20,
   },
   secondaryTitle: {
      paddingBottom: 10,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      paddingLeft: 10,
   },
   discountInputContainer: {
      textAlign: 'center',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      paddingBottom: 10,
   },
   cardRowContainer: {
      // padding: 5,
      marginTop: 5,
   },
   containerBorder: {
      border: ' 1px solid #E3E3E3',
      borderRadius: 4,
      paddingLeft: '1rem',
      paddingRight: '1rem',
   },
   newCard: {
      [theme.breakpoints.up('md')]: {
         paddingLeft: 15,
      },
   },
   newCardContainer: {
      border: 'solid 1px #E3E3E3',
      width: '100%',
      marginTop: 20,
      borderRadius: 10,
      padding: 10,
   },
   saveCardHint: {
      fontSize: '0.85rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   inputContainer: {
      border: '1px solid #E2E2E2',
      borderRadius: '4px',
      padding: '.25rem 1rem',
      margin: 10,
      display: 'flex',
      alignItems: 'center',
      fontWeight: 300,
   },
   backgroundBlue: {
      backgroundColor: '#DBEBFF',
   },
   inputBase: {
      flex: 1,
      border: 'none',
      padding: '.5rem',
      fontWeight: 300,
      '&:focus': {
         border: 'blue',
         outline: 'none',
      },
      '&::placeholder': {
         fontWeight: 300,
         color: '#315276',
         opacity: '0.37',
         verticalAlign: 'center',
      },
   },
   errorText: {
      color: 'red',
      fontSize: '12px',
   },
   colorRed: {
      color: 'red',
   },
   upperCardLabel: {
      minWidth: '45%',
   },
   cardLabel: {
      color: '#93A8C1',
   },
   disabledContainer: {
      padding: 20,
      minHeight: 200,
   },
   marginY: {
      margin: theme.spacing(1, 0),
   },
   iyzicoLogo: {
      width: 150,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   errorMessage: {
      color: 'red',
      paddingLeft: 10,
   },
}));

const DoPaymentModal = ({ person, onAccept, onClose, product, buyMode, mode, addOnPackagesCount }) => {
   const classes = useStyles();
   const isAddOn = mode !== 'Main';
   const diffDay = moment(person.customer?.package?.endDate).diff(new Date(), DAYS, true);
   const differenceDay = diffDay < 1 && diffDay > 0 ? 1 : Math.floor(diffDay);
   const customerCurrentPackageIsMonthly = person.customer.package.periodMode === 0;
   const isUserAddOn = product.name === AddOnName.USER;
   const history = useHistory();
   const [couponCode, setCouponCode] = useState('');
   const initialState = {
      payment3d: false,
      registerCard: false,
      selectedCard: null,
   };

   const customer = person.customer;
   const isDisabledPayment =
      !customer?.invoiceAddress ||
      !customer?.invoiceCity ||
      !customer?.invoiceCompanyName ||
      !customer?.invoiceCountry ||
      !customer?.invoicePostCode ||
      !customer?.invoiceState ||
      !customer?.phone ||
      !customer?.invoicePostCode ||
      !customer?.authorizedPersonName ||
      !customer?.authorizedPersonSurname;

   const [state, setState] = useState(initialState);

   const getAddOnTotalPrice = () => {
      if (product.name === AddOnName.ACTIVE_CUSTOMER || product.name ===  AddOnName.ACCOUNT_BALANCE) {
         return product.price;
      }
      if (customerCurrentPackageIsMonthly) {
         const perDayPrice = isUserAddOn ? person.customer.package.price / 30 : product.price / 30;
         const totalPriceNotFixed = differenceDay * perDayPrice;
         return totalPriceNotFixed;
      } else {
         // If Customer package is yearly because of all add on's based on the main package.
         const perDayPrice = (isUserAddOn ? person.customer.package.price : product.price * 12) / 365;
         const totalPrice = differenceDay * perDayPrice;
         return totalPrice;
      }
   };

   const [couponState, { loading: couponLoading, getCouponCode }] = useCoupon(
      getAddOnTotalPrice() * addOnPackagesCount, // Total price * quantity
   );

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const {
      register,
      setValue,
      watch,
      formState: { errors, isValid },
   } = useForm({
      mode: 'all',
   });

   useEffect(() => {
      // eslint-disable-next-line no-unused-vars
      let _ = person?.customer?.paymentCards?.map((card) => {
         if (card.default) {
            setState((state) => ({
               ...state,
               selectedCard: card,
               payment3d: false,
            }));
         }
         return '';
      });
   }, [person]);

   const getTitleFromMode = (mode) => {
      if (mode === 'Customer') {
         return t?.add_active_customer_title;
      } else if (mode === 'User') return t?.add_user_seat_title;
      else if (mode === 'Number') {
         return t?.add_whatsapp_business_api_title;
      } else if (mode === 'Main') {
         return t?.change_main_package;
      } else if (mode === 'Balance') {
         return t?.add_on_balance;
      } else {
         return t?.pay;
      }
   };

   return (
      <Grid>
         <Grid container xs='12'>
            <Grid
               container
               justifyContent='center'
               style={{
                  borderBottom: '1px solid #EFEFEF',
               }}
            >
               <Typography className={classes.title}> {getTitleFromMode(mode)} </Typography>
            </Grid>

            <Grid xs='12' container className={classes.content}>
               <Grid xs='12' item container style={{ padding: 20, height: '100%' }}>
                  <Grid item container xs={12}>
                     {/* Reneval Details */}
                     <Grid xs='12' container item className={classes.renevalContainer}>
                        <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                           <Typography variant='body1' className={classes.secondaryTitle}>
                              {t?.reneval_details}
                           </Typography>
                           <Divider className={classes.divider} />
                        </div>
                        <Grid direction='column' xs={12} item>
                           <Grid item xs='12' container justifyContent='space-between' className={classes.padding5}>
                              <Typography>{mode === 'Customer' ? 'Start date' : t?.first_payment_date}</Typography>
                              <Typography style={{ fontWeight: 'bold' }}>{moment().format('DD/MM/YY')}</Typography>
                           </Grid>
                           {mode === 'Customer' || mode === "Balance" ? (
                              <Fragment>
                                 <Grid item container justifyContent='space-between' className={classes.padding5}>
                                    <Typography>{t?.this_add_on_stays_until_its_done}</Typography>
                                    <Typography style={{ fontWeight: 'bold' }}>{t?.forever}</Typography>
                                 </Grid>
                              </Fragment>
                           ) : (
                              <Fragment>
                                 <Grid item container justifyContent='space-between' className={classes.padding5}>
                                    {/* If customer package is monthly buying will be monthly */}
                                    <Typography>
                                       {customerCurrentPackageIsMonthly
                                          ? t?.monthly_price_for_this_package
                                          : t?.yearly_price_for_this_package}
                                    </Typography>
                                    <Typography style={{ fontWeight: 'bold' }}>
                                       {customerCurrentPackageIsMonthly
                                          ? priceFormat(isUserAddOn ? person.customer.package.price : product.price)
                                          : `${priceFormat(
                                               isUserAddOn ? person.customer.package.price / 12 : product.price,
                                            )} * 12 = ${priceFormat(
                                               isUserAddOn ? person.customer.package.price : product.price * 12,
                                            )}`}
                                    </Typography>
                                 </Grid>
                                 <Grid item container justifyContent='space-between' className={classes.padding5}>
                                    <Typography>
                                       {differenceDay} {t?.day_cost_for_this_package}{' '}
                                    </Typography>
                                    <Typography style={{ fontWeight: 'bold' }}>
                                       {priceFormat(getAddOnTotalPrice())}
                                    </Typography>
                                 </Grid>
                              </Fragment>
                           )}

                           <Grid item container xs='12' className={classes.marginY}>
                              <Typography
                                 align='center'
                                 color='primary'
                                 variant='caption'
                                 style={{ fontWeight: 'bold' }}
                              >
                                 {mode === 'Customer'
                                    ? t?.reneval_text_payment_modal_active_customer
                                    : t?.reneval_text_payment_modal_description}
                              </Typography>
                           </Grid>
                        </Grid>
                     </Grid>

                     {/* Package Details */}
                     <Grid item container xs='12'>
                        <Grid className={classes.topContainer} style={{ marginTop: 20, padding: 15 }} item xs={12}>
                           <Typography variant='body1' className={classes.secondaryTitle}>
                              {t?.package_details}
                           </Typography>
                           <Divider className={classes.divider} />
                           <Grid style={{ padding: '20px 5px' }}>
                              <Grid container justifyContent='space-between' style={{ paddingBottom: 10 }}>
                                 <Typography>
                                    {isAddOn && t?.add_on} / {product?.name?.substring(0, 16)} /{' '}
                                    {customerCurrentPackageIsMonthly ? t?.monthly : t?.yearly}
                                 </Typography>
                                 <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                    {priceFormat(getAddOnTotalPrice())}
                                 </Typography>
                              </Grid>
                              <Grid container justifyContent='space-between' style={{ paddingBottom: 10 }}>
                                 <Typography style={{ fontSize: '0.9rem' }}>{t?.quantity}</Typography>
                                 <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                    {addOnPackagesCount}
                                 </Typography>
                              </Grid>
                              <Grid container justifyContent='space-between' style={{ paddingBottom: 10 }}>
                                 <Typography style={{ fontSize: '0.9rem' }}>{t?.tax_included}</Typography>
                                 <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                    {priceFormat((couponState.totalPrice * 18) / 100)}
                                 </Typography>
                              </Grid>
                              {couponState.couponStatus && (
                                 <Grid
                                    container
                                    justifyContent='space-between'
                                    style={{ paddingBottom: 5, color: 'green' }}
                                 >
                                    <Typography>
                                       {t?.discount} ({couponState.couponCodeText}){' '}
                                    </Typography>
                                    <Typography>{priceFormat(couponState.couponDiscount)}</Typography>
                                 </Grid>
                              )}
                              <Divider className={classes.divider} />
                              <Grid container justifyContent='space-between'>
                                 <Typography style={{ fontWeight: 'bold' }} variant='body1'>
                                    {t?.total}
                                 </Typography>
                                 <Typography style={{ fontWeight: 'bold' }} variant='body1'>
                                    {priceFormat(couponState.totalPrice)}
                                 </Typography>
                              </Grid>
                           </Grid>
                        </Grid>

                        <Grid className={classes.couponContainer} item xs={12}>
                           {couponLoading && <div className='loader' />}

                           <TextField
                              placeholder={t.enter_coupon_code}
                              type='text'
                              disabled={couponState.couponStatus}
                              fullWidth
                              variant='outlined'
                              inputProps={{
                                 maxLength: 11,
                              }}
                              onChange={(e) => setCouponCode(e.target.value)}
                              InputProps={{
                                 endAdornment: (
                                    <Fragment>
                                       {couponCode.trim().length === 11 && (
                                          <Button
                                             onClick={() => {
                                                getCouponCode({
                                                   variables: { code: couponCode.toUpperCase() },
                                                });
                                             }}
                                             disabled={couponState.couponStatus}
                                             style={{ width: 150 }}
                                             color='secondary'
                                             variant='text'
                                          >
                                             {t?.apply_coupon}
                                          </Button>
                                       )}
                                    </Fragment>
                                 ),
                              }}
                           />

                           {couponState.couponErrorMessage && !couponState.couponStatus && (
                              <span style={{ color: 'red', fontSize: '14px' }}>{couponState.couponErrorMessage}</span>
                           )}
                           {!!couponState.couponStatus && couponState.couponErrorMessage.length > 0 && (
                              <span style={{ color: 'green', fontSize: '14px' }}>{couponState.couponErrorMessage}</span>
                           )}
                        </Grid>
                        {!couponState.isPaying && (
                           <Grid container justifyContent='flex-end' item xs={12}>
                              <ButtonOK
                                 fullWidth
                                 label={t?.comp_button_continue}
                                 onClick={() =>
                                    onAccept({
                                       product: product._id,
                                       code: couponCode,
                                       mode: buyMode,
                                    })
                                 }
                              />
                           </Grid>
                        )}
                     </Grid>
                  </Grid>

                  <Grid item xs={12} direction='column' justifyContent='space-between'>
                     {/* Saved Cards */}
                     <Grid container xs='12' item>
                        {couponState.isPaying && (
                           <Fragment>
                              <Grid
                                 className={classes.topContainer}
                                 style={{ padding: 10, marginBottom: 10 }}
                                 alignItems='center'
                                 item
                                 xs={12}
                              >
                                 <Typography className={classes.secondaryTitle}>{t?.my_saved_card}</Typography>
                                 <Divider className={classes.divider} />
                                 <Grid
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                    style={{ padding: 10, marginTop: 10 }}
                                 >
                                    {/* kayıtlı kartlar */}

                                    {person?.customer?.paymentCards?.length === 0 ? (
                                       <Grid className={classes.noCardContainer} container xs={12}>
                                          <Typography gutterBottom variant='body1' paragraph>
                                             {t?.you_have_not_add_card_yet}
                                          </Typography>
                                          <Button
                                             className={classes.modalButtons}
                                             onClick={() => history.push('/account/packages/my-package')}
                                             style={{
                                                marginLeft: 'auto',
                                                color: 'white',
                                                backgroundColor: '#157CFC',
                                                '&:hover': {
                                                   backgroundColor: '#105CBB',
                                                },
                                             }}
                                          >
                                             {t?.page_settings_button_add_new}
                                          </Button>
                                       </Grid>
                                    ) : (
                                       person?.customer?.paymentCards?.map((card) => (
                                          <Grid
                                             key={card.binNumber + card.lastFourDigits}
                                             container
                                             item
                                             xs={12}
                                             className={classes.cardRowContainer}
                                          >
                                             <Grid item xs={2} style={{ marginTop: 0 }}>
                                                <RedCheckBox
                                                   checked={
                                                      state.selectedCard?.binNumber +
                                                         state.selectedCard?.lastFourDigits ===
                                                      card.binNumber + card.lastFourDigits
                                                   }
                                                   onChange={(e) =>
                                                      setState((state) => ({
                                                         ...state,
                                                         selectedCard: card,
                                                         payment3d: false,
                                                      }))
                                                   }
                                                   value={'card._id'}
                                                   name={'card'}
                                                   inputProps={{ 'aria-label': '' }}
                                                />
                                             </Grid>
                                             <Grid
                                                item
                                                container
                                                justifyContent='center'
                                                alignItems='center'
                                                xs={10}
                                                className={classes.containerBorder}
                                             >
                                                <Grid item xs={9}>
                                                   <Typography>
                                                      {card?.binNumber?.substring(0, 4)} **** **** {card.lastFourDigits}
                                                   </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                   <Typography noWrap>{card.cardBankName}</Typography>
                                                </Grid>
                                             </Grid>
                                          </Grid>
                                       ))
                                    )}

                                    {state.selectedCard && (
                                       <Grid item xs={12} style={{ marginTop: 10 }}>
                                          <ButtonFunction
                                             fullWidth
                                             defaultIcons
                                             label={t?.use_new_card}
                                             variant='outlined'
                                             onClick={() =>
                                                setState((state) => ({
                                                   ...state,
                                                   selectedCard: null,
                                                   payment3d: false,
                                                }))
                                             }
                                          />
                                       </Grid>
                                    )}
                                 </Grid>
                              </Grid>
                           </Fragment>
                        )}
                     </Grid>

                     {isDisabledPayment ? (
                        <Grid item xs='12' container className={classes.disabledContainer} justifyContent='flex-end'>
                           <Box border='1px solid #E2E2E2' p={3} borderRadius={10}>
                              <Typography variant='body1' style={{ fontWeight: 'bold' }} color='error'>
                                 {t?.you_cant_pay_right_now}
                              </Typography>

                              <Button
                                 color='primary'
                                 size='large'
                                 fullWidth
                                 onClick={() => history.push('/account/company')}
                                 variant='contained'
                                 className={classes.marginY}
                              >
                                 {t?.edit_company_information}
                              </Button>
                           </Box>
                        </Grid>
                     ) : (
                        !state.selectedCard && (
                           <Grid item xs='12' container justifyContent='flex-end' style={{ marginBottom: 16 }}>
                              {/* Add New Cards */}
                              <Fade in={!state.selectedCard} timeout={1000}>
                                 {
                                    <Grid container xs={12} className={classes.newCardContainer}>
                                       <Grid item xs={12}>
                                          <Box
                                             className={classes.inputContainer}
                                             style={{
                                                borderColor: errors.cardHolderName ? 'red' : undefined,
                                             }}
                                          >
                                             <input
                                                className={clsx(classes.inputBase, classes.pleft9rem)}
                                                placeholder='Card Holder Name'
                                                {...register('cardHolderName', {
                                                   required: t?.card_holder_name_is_required,
                                                })}
                                             />
                                          </Box>
                                          {errors.cardHolderName && (
                                             <span className={classes.errorMessage}>
                                                {errors.cardHolderName.message}
                                             </span>
                                          )}
                                       </Grid>
                                       <Grid item xs={12}>
                                          <Box
                                             className={classes.inputContainer}
                                             style={{
                                                borderColor: errors.cardNumber ? 'red' : undefined,
                                             }}
                                          >
                                             <input
                                                className={clsx(classes.inputBase, classes.pleft9rem)}
                                                placeholder='Card Number'
                                                type='tel'
                                                inputMode='numeric'
                                                autoComplete='cc-number'
                                                {...register('cardNumber', {
                                                   required: t?.card_number_is_required,
                                                   validate: (value) => {
                                                      if (value) {
                                                         setValue('cardNumber', normalizeCardNumber(value));
                                                      }
                                                   },
                                                })}
                                             />
                                          </Box>
                                          {errors.cardNumber && (
                                             <span className={classes.errorMessage}>{errors.cardNumber.message}</span>
                                          )}
                                       </Grid>
                                       <Grid item xs={12}>
                                          <Grid container>
                                             <Grid xs={6}>
                                                <Box
                                                   className={classes.inputContainer}
                                                   style={{
                                                      borderColor: errors.date ? 'red' : undefined,
                                                      marginRight: '.5rem',
                                                   }}
                                                >
                                                   <input
                                                      className={classes.inputBase}
                                                      placeholder='Expiry Date'
                                                      style={{ maxWidth: '100px' }}
                                                      {...register('date', {
                                                         required: t?.expiry_date_is_required,
                                                         validate: (value) => {
                                                            setValue('date', cc_expires_format(value));
                                                            if (value.length < 5) {
                                                               return 'Please enter a valid expire date.';
                                                            }
                                                         },
                                                      })}
                                                   />
                                                </Box>
                                                {errors.date && (
                                                   <span className={classes.errorMessage}>{errors.date.message}</span>
                                                )}
                                             </Grid>
                                             <Grid xs={6}>
                                                <Box
                                                   className={classes.inputContainer}
                                                   style={{
                                                      marginLeft: '.5rem',
                                                      borderColor: errors.cvc ? 'red' : undefined,
                                                   }}
                                                >
                                                   <input
                                                      className={classes.inputBase}
                                                      style={{ maxWidth: '100px' }}
                                                      placeholder='CVV'
                                                      maxLength='3'
                                                      {...register('cvc', {
                                                         required: t?.cvv_is_required,
                                                         validate: (value) => {
                                                            if (value.length !== 3)
                                                               return 'CVV must be atleast 3 characters';
                                                         },
                                                      })}
                                                   />
                                                </Box>
                                                {errors.cvc && (
                                                   <span className={classes.errorMessage}>{errors.cvc.message}</span>
                                                )}
                                             </Grid>
                                          </Grid>
                                       </Grid>
                                       <Grid container justifyContent='flex-start' alignItems='center' wrap='nowrap'>
                                          <RedCheckBox
                                             checked={state.registerCard}
                                             onChange={() =>
                                                setState((state) => ({
                                                   ...state,
                                                   registerCard: !state.registerCard,
                                                }))
                                             }
                                             name='registerCard'
                                          />
                                          <Typography className={classes.saveCardHint}>{t?.saved_card}</Typography>
                                          <img
                                             src={SITE_URL + '/iyzico.svg'}
                                             alt='logo'
                                             className={classes.iyzicoLogo}
                                          />
                                       </Grid>
                                    </Grid>
                                 }
                              </Fade>
                           </Grid>
                        )
                     )}
                  </Grid>
               </Grid>
            </Grid>
            <ModalButtonField>
               <Button
                  className={classes.modalButtons}
                  onClick={() => onClose()}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               >
                  {t?.comp_button_cancel}
               </Button>
               <ButtonOK
                  className={classes.modalButtons}
                  label={t?.payment}
                  disabled={(!isValid && !state.selectedCard) || isDisabledPayment || !couponState.isPaying}
                  style={{
                     marginLeft: 'auto',
                     color: 'white',
                     backgroundColor: '#157CFC',
                     '&:hover': {
                        backgroundColor: '#105CBB',
                     },
                  }}
                  onClick={() => {
                     let cardData = watch();
                     if (!state.selectedCard && isValid) {
                        cardData = watch();
                        cardData.cardNumber = cardData.cardNumber.split(/\s+/).join('');
                        cardData.expireMonth = cardData.date.split('/')[0];
                        cardData.expireYear = cardData.date.split('/')[1];
                        cardData.cardAlias = cardData.cardHolderName;
                        setState((prevState) => ({ ...prevState, registerCard: true }));
                     }
                     const formDatas = {
                        product: product._id,
                        code: couponCode,
                        quantity: addOnPackagesCount,
                        ...state,
                        ...cardData,
                     };

                     onAccept(formDatas);
                  }}
               />
            </ModalButtonField>
         </Grid>
      </Grid>
   );
};

export default DoPaymentModal;

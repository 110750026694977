import { gql } from '@apollo/client';

export const UPDATED_CAMPAIGN_SUBSCRIPTION = gql`
   subscription updatedCampaign($token: String!, $customer: ID!) {
      updatedCampaign(input: { token: $token, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;

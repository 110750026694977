import { gql, useQuery } from '@apollo/client';

// queries
const GET_COUNTRIES_QUERY = gql`
   query getCountriesQuery {
      countries {
         data {
            country
            states
         }
         code
         success
         message
      }
   }
`;

export const useCountries = (options) => {
   const { data: countriesData } = useQuery(GET_COUNTRIES_QUERY, {
      fetchPolicy: 'cache-first',
      ...options,
   });
   return {
      countriesData: countriesData?.countries?.data,
   };
};

import React from 'react';

const FacebookMessengerIcon = ({ color, selected, className }) => {
   const color1 = color ? color : selected ? '#0084ff' : '#c7cdd6';
   return (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 595.07 599' className={className} style={{ width: '100%' }}>
         <path
            d='M555,137.76q40.08,62.9,40.08,139.53T555,416.82q-40.14,62.9-107.71,100.32t-149.57,37.4a316.07,316.07,0,0,1-85.64-11.44L110.85,599V493.25a285.47,285.47,0,0,1-81.11-94.62Q0,342.76,0,277.29q0-76.61,40.12-139.53T147.8,37.4Q215.39,0,297.71,0q82,0,149.57,37.4T555,137.76ZM327.15,373.38,490,199.77l-146.28,81.1-77.52-81.1L103.34,373.38,251.44,292Z'
            fill={color1}
         />
      </svg>
   );
};

export default FacebookMessengerIcon;

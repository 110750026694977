import { gql } from '@apollo/client';

export const GET_PERSON_GROUPS = gql`
   query getPersonGroups($input: GetPersonGroupsInput!) {
      getPersonGroups(input: $input) {
         code
         data {
            _id
            name
            userCount
            isDynamic
         }
         success
         message
      }
   }
`;

export const GET_JOINED_GROUPS = gql`
   query getJoinedGroups($input: GetJoinedGroupsInput!) {
      getJoinedGroups(input: $input) {
         code
         data {
            _id
            name
         }
         success
         message
      }
   }
`;

export const PERSON_GROUP_LIST_COUNT = gql`
   query getPersonGroupListCount($_id: ID!, $customer: ID!, $name: String, $fromDate: Date, $toDate: Date, $type: Int) {
      getPersonGroupListCount(
         _id: $_id
         customer: $customer
         name: $name
         fromDate: $fromDate
         toDate: $toDate
         type: $type
      ) {
         data
         success
         code
         message
      }
   }
`;

export const PERSON_GROUP_LIST = gql`
   query personGroupList(
      $_id: ID!
      $customer: ID!
      $name: String
      $fromDate: Date
      $toDate: Date
      $type: Int
      $pageNumber: Int!
      $pageSize: Int!
   ) {
      personGroupList(
         _id: $_id
         customer: $customer
         name: $name
         fromDate: $fromDate
         toDate: $toDate
         type: $type
         pageNumber: $pageNumber
         pageSize: $pageSize
      ) {
         data {
            docs {
               _id
               name
               surname
               country
               state
               address
               postCode
               city
               type
               email
               phone
               createdAt
               phoneUsagePermission
               emailUsagePermission
               tags
               language
            }
            hasNextPage
            docsCount
         }
         success
         code
         message
      }
   }
`;

export const GET_GROUPS_LIST = gql`
   query getGroupListQuery($customer: ID!, $search: String, $page: Int!, $pageSize: Int!) {
      getGroupList(customer: $customer, search: $search, page: $page, pageSize: $pageSize) {
         data {
            docs {
               _id
               name
               senderPlatform
               senderPlatformType
            }
            hasNextPage
            docsCount
         }
         success
         code
         message
      }
   }
`;

export const GET_GROUP_LIST = gql`
   query GetGroupList(
      $customer: ID!
      $pageSize: Int!
      $page: Int!
      $search: String
      $groupType: Int
      $isFillPerson: Boolean
   ) {
      getGroupList(
         customer: $customer
         pageSize: $pageSize
         page: $page
         search: $search
         groupType: $groupType
         isFillPerson: $isFillPerson
      ) {
         data {
            docs {
               _id
               name
               userCount
               isDynamic
               conditions {
                  type
                  rule
                  value
               }
               shouldMatchAllConditions
            }
            hasNextPage
            docsCount
         }
      }
   }
`;

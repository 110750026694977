import { gql } from '@apollo/client';

export const GET_CAMPAIGN = gql`
   query getCampaign($input: GetCampaignInput!) {
      getCampaign(input: $input) {
         code
         data {
            _id
            name
            platform_type
            platform_id
            platform_info
            whats_app_web_id
            whats_app_business_id
            whats_app_business_template_id
            whats_app_business_template_params
            schedule_type
            schedule_date
            user_recipients {
               label
               value
            }
            group_recipients {
               label
               value
            }
            dynamic_group_recipients {
               label
               value
            }
            createdAt
            total_recipient_count
            content
            media
            status
            success_count
            failure_count
            platform_error_count
            cost
            survey
         }
         success
         message
      }
   }
`;

export const GET_CAMPAIGNS = gql`
   query getCampaigns($customer: ID!, $name: String, $pageNumber: Int!, $pageSize: Int!) {
      getCampaigns(customer: $customer, name: $name, pageNumber: $pageNumber, pageSize: $pageSize) {
         code
         data {
            _id
            name
            platform_type
            platform_id
            platform_info
            whats_app_web_id
            whats_app_business_id
            schedule_type
            schedule_date
            user_recipients
            group_recipients
            dynamic_group_recipients
            createdAt
            updatedAt
            total_recipient_count
            content
            media
            status
            success_count
            failure_count
            platform_error_count
            cost
         }
         success
         message
      }
   }
`;

export const GET_CAMPAIGNS_COUNT = gql`
   query getCampaignsCount($customer: ID!, $name: String) {
      getCampaignsCount(customer: $customer, name: $name) {
         code
         data
         success
         message
      }
   }
`;

export const GET_RECIPIENTS = gql`
   query getRecipients($customer: ID!, $name: String, $isGroup: Boolean, $pageNumber: Int!, $pageSize: Int!) {
      getRecipients(customer: $customer, name: $name, isGroup: $isGroup, pageNumber: $pageNumber, pageSize: $pageSize) {
         code
         data {
            isGroupResponse
            groups {
               _id
               name
               users
            }
            users {
               name
               _id
               phone
            }
         }
         success
         message
      }
   }
`;

export const GET_RECIPIENTS_COUNT = gql`
   query getRecipientsCount($customer: ID!, $name: String, $isGroup: Boolean) {
      getRecipientsCount(customer: $customer, name: $name, isGroup: $isGroup) {
         code
         data
         success
         message
      }
   }
`;

export const CALCULATE_BALANCE_USAGE = gql`
   query calculateBalanceUsage($campaignId: ID!) {
      calculateBalanceUsage(campaignId: $campaignId) {
         code
         data {
            sufficient
            balance
            required_balance
            recipient_count
         }
         success
         message
      }
   }
`;

export const GET_TEMPLATES = gql`
   query getAllTemplatesPaginate(
      $customer: ID!
      $startDate: Date
      $pageNumber: Int!
      $pageSize: Int!
      $allStatus: Boolean
      $appId: String
      $qpienFields: Boolean
      $eCommerceCart: Boolean
   ) {
      getAllTemplatesPaginate(
         customer: $customer
         startDate: $startDate
         pageNumber: $pageNumber
         pageSize: $pageSize
         allStatus: $allStatus
         appId: $appId
         qpienFields: $qpienFields
         eCommerceCart: $eCommerceCart
      ) {
         code
         data
         success
         message
      }
   }
`;

export const GET_TEMPLATES_COUNT = gql`
   query getAllTemplatesPaginateCount(
      $customer: ID!
      $startDate: Date
      $allStatus: Boolean
      $appId: String
      $qpienFields: Boolean
      $eCommerceCart: Boolean
   ) {
      getAllTemplatesPaginateCount(
         customer: $customer
         startDate: $startDate
         allStatus: $allStatus
         appId: $appId
         qpienFields: $qpienFields
         eCommerceCart: $eCommerceCart
      ) {
         code
         data
         success
         message
      }
   }
`;

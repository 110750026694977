import {useRef, useState} from "react";
import { useSubscription } from '@apollo/client';
import {
  FORWARD_QNA_PERSON_SUBSCRIPTION,
  JOINED_QNA_PERSON_SUBSCRIPTION,
  LEAVE_QNA_PERSON_SUBSCRIPTION,
  NEW_QNA_PERSON_TIMEOUT_SUBSCRIPTION,
  NEW_QNA_TIMEOUT_SUBSCRIPTION,
  UPDATED_QNA_TAG_SUBSCRIPTION,
  QUESTION_TO_ME_SUBSCRIPTION,
  QUESTION_TO_ME_UPDATED_SUBSCRIPTION,
  QUESTION_TO_ME_LOG,
} from '@queries/Questions/Service/subscription';
import {
  newJoinedQnAChatSubscription,
  newJoinedQnAChatSubscriptionVariables,
} from '@queries/Questions/Service/__generated__/newJoinedQnAChatSubscription';
import {
  LeaveQnASubscription,
  LeaveQnASubscriptionVariables,
} from '@queries/Questions/Service/__generated__/LeaveQnASubscription';
import {
  updateQnAChatTagSubscription,
  updateQnAChatTagSubscriptionVariables,
} from '@queries/Questions/Service/__generated__/updateQnAChatTagSubscription';
import {
  newQnATimeoutSubscription,
  newQnATimeoutSubscriptionVariables,
} from '@queries/Questions/Service/__generated__/newQnATimeoutSubscription';
import {
  newQnAChatTimeoutSubscription,
  newQnAChatTimeoutSubscriptionVariables,
} from '@queries/Questions/Service/__generated__/newQnAChatTimeoutSubscription';
import {
  forwardQnAChatSubscription,
  forwardQnAChatSubscriptionVariables,
} from '@queries/Questions/Service/__generated__/forwardQnAChatSubscription';
import {
  QuestionToMeSubscription,
  QuestionToMeSubscriptionVariables,
} from '@queries/Questions/Service/__generated__/QuestionToMeSubscription';
import {
  QuestionToMeUpdatedSubscription,
  QuestionToMeUpdatedSubscriptionVariables,
} from '@queries/Questions/Service/__generated__/QuestionToMeUpdatedSubscription';
import {
  QnAToMeLogSubscription,
  QnAToMeLogSubscriptionVariables,
} from '@queries/Questions/Service/__generated__/QnAToMeLogSubscription';
import { useDispatch, useSelector } from 'react-redux';

import {
  FORWARDED_QUESTION,
  JOINED_QUESTION,
  LEAVED_QUESTION,
  NEW_QNA_PERSON_TIMEOUT,
  NEW_QNA_TIMEOUT,
  QUESTION_TO_ME,
  QUESTION_TO_ME_UPDATED,
  QUESTION_UPDATE_LOG,
  UPDATED_QNA_TAG,
} from '@store/actions/questions';
import {getPerson, getToken} from "@store/reducers/person";

const useQuestionSubscriptions = () => {
  const person = useSelector(getPerson);
  const token = useSelector(getToken);
  const dispatch = useDispatch();
  const variables = { token, customer: person?.customer?._id };
  const messagesPool = useRef<any[]>([]);
  const [poolTimerActivated, setPoolTimerActivated] = useState<boolean>(false);

  const handleQuestionToMe = (message: any) => {
    return (dispatch: any) => {
      if (!poolTimerActivated) {
        setTimeout(() => {
          dispatch({
            type: QUESTION_TO_ME,
            payload: {
              messagesPool: message,
              me: person,
            },
          });
          setPoolTimerActivated(false);
          messagesPool.current.length = 0;
        }, 500);
        setPoolTimerActivated(true);
      }
    };
  };
  const handleQuestionToMeUpdated = (message: any, updatedMessage: any) => {
    return (dispatch: any) => {
      dispatch({
        type: QUESTION_TO_ME_UPDATED,
        payload: {
          messagesPool: message,
          me: person,
          updatedMessage: updatedMessage,
        },
      });
    };
  };

  useSubscription<QuestionToMeSubscription, QuestionToMeSubscriptionVariables>(QUESTION_TO_ME_SUBSCRIPTION, {
    variables,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.newQnA?.data) {
        messagesPool.current.push(subscriptionData.data?.newQnA?.data);
        dispatch(handleQuestionToMe(messagesPool.current));
      }
    },
  });

  useSubscription<QuestionToMeUpdatedSubscription, QuestionToMeUpdatedSubscriptionVariables>(
    QUESTION_TO_ME_UPDATED_SUBSCRIPTION,
    {
      variables,
      onSubscriptionData: ({ subscriptionData }) => {
        if (subscriptionData.data?.updatedQnA?.data) {
          dispatch(handleQuestionToMeUpdated(messagesPool.current, subscriptionData.data?.updatedQnA?.data));
        }
      },
    },
  );

  useSubscription<newJoinedQnAChatSubscription, newJoinedQnAChatSubscriptionVariables>(JOINED_QNA_PERSON_SUBSCRIPTION, {
    variables,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.newQnAChatJoin?.data) {
        dispatch({
          type: JOINED_QUESTION,
          payload: {
            qnaChat: subscriptionData.data.newQnAChatJoin.data,
          },
        });
      }
    },
  });

  useSubscription<LeaveQnASubscription, LeaveQnASubscriptionVariables>(LEAVE_QNA_PERSON_SUBSCRIPTION, {
    variables,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.newQnAChatLeave?.data) {
        dispatch({
          type: LEAVED_QUESTION,
          payload: {
            qnaChat: subscriptionData.data.newQnAChatLeave.data,
          },
        });
      }
    },
  });

  useSubscription<newQnATimeoutSubscription, newQnATimeoutSubscriptionVariables>(NEW_QNA_TIMEOUT_SUBSCRIPTION, {
    variables,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.newQnATimeout?.data) {
        dispatch({
          type: NEW_QNA_TIMEOUT,
          payload: {
            qna: subscriptionData.data.newQnATimeout.data
          },
        });
      }
    },
  });

  useSubscription<newQnAChatTimeoutSubscription, newQnAChatTimeoutSubscriptionVariables>(
    NEW_QNA_PERSON_TIMEOUT_SUBSCRIPTION,
    {
      variables,
      onSubscriptionData: ({ subscriptionData }) => {
        if (subscriptionData.data?.newQnAChatTimeout?.data) {
          dispatch({
            type: NEW_QNA_PERSON_TIMEOUT,
            payload: {
              qnaChat: subscriptionData.data.newQnAChatTimeout.data,
            },
          });
        }
      },
    },
  );

  useSubscription<updateQnAChatTagSubscription, updateQnAChatTagSubscriptionVariables>(UPDATED_QNA_TAG_SUBSCRIPTION, {
    variables,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.updatedQnAChatTag?.data) {
        dispatch({
          type: UPDATED_QNA_TAG,
          payload: {
            qnaChat: subscriptionData.data.updatedQnAChatTag.data,
          },
        });
      }
    },
  });

  useSubscription<forwardQnAChatSubscription, forwardQnAChatSubscriptionVariables>(FORWARD_QNA_PERSON_SUBSCRIPTION, {
    variables,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.newQnAChatForward?.data) {
        dispatch({
          type: FORWARDED_QUESTION,
          payload: {
            qnaChat: subscriptionData.data.newQnAChatForward.data,
          },
        });
      }
    },
  });

  useSubscription<QnAToMeLogSubscription, QnAToMeLogSubscriptionVariables>(QUESTION_TO_ME_LOG, {
    variables,
    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data?.QnAToMe?.data) {
        dispatch({
          type: QUESTION_UPDATE_LOG,
          payload: {
            qna: subscriptionData.data.QnAToMe.data
          },
        });
      }
    },
  });
};

export default useQuestionSubscriptions;

import { useRef, useEffect } from 'react';

const usePreviousState = (value, initialValue) => {
   const ref = useRef(initialValue);
   useEffect(() => {
      ref.current = value;
   }, [value]);
   if (ref.current === 'undefined' && typeof initialValue === 'undefined') {
      return ref.current;
   }

   return ref.current;
};

export default usePreviousState;

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { LanguageContext } from '@helper/locale/langContext';
import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SettingsSpace from './SettingSpace/settingSpace';
import SettingsPanel from './SettingsPanel/settingsPanel';

const useStyles = makeStyles((theme) => ({
   content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'auto',
      padding: 20,
   },
   container: {
      margin: 0,
      height: 'calc(100% - 60px)',
      // padding:20,
   },
   appBarSpacer: theme.mixins.toolbar,
   SettingsPanel: {
      width: 370,
      height: '100%',
      [theme.breakpoints.down('md')]: {
         width: 70,
      },
   },
}));

const Settings = () => {
   const classes = useStyles();
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const history = useHistory();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, history, enqueueSnackbar, closeSnackbar]);

   return (
      <main className={classes.content}>
         <Grid container className={classes.container} wrap='nowrap'>
            <div className={classes.SettingsPanel}>
               <SettingsPanel />
            </div>
            <Grid item xs={false} sm={12} lg={10} xl={10}>
               <SettingsSpace />
            </Grid>
         </Grid>
      </main>
   );
};

export default Settings;

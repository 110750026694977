import { gql } from '@apollo/client';

export const GET_E_COMMERCE_PLATFORM_TYPE = gql`
   query getECommercePlatformTypeQuery($customer: ID!) {
      getECommercePlatformType(customer: $customer) {
         data
         success
         code
         message
      }
   }
`;

export const GET_E_COMMERCE_STORE_CARGO_COMPANIES = gql`
   query getECommerceStoreCargoCompaniesQuery($type: String, $customer: ID!) {
      getECommerceStoreCargoCompanies(type: $type, customer: $customer) {
         data {
            name
            id
            price
            internatiolPrice
            minPrice
            maxPrice
         }
         success
         code
         message
      }
   }
`;

export const GET_E_COMMERCE_STORE_PAYMENT_TYPES = gql`
   query getECommerceStorePaymentTypesQuery($type: String, $customer: ID!) {
      getECommerceStorePaymentTypes(type: $type, customer: $customer) {
         data {
            isOn
            types
         }
         success
         code
         message
      }
   }
`;

import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../../../helper/locale/langContext';

const useStyles = makeStyles(() => ({
   container: {
      flexShrink: 0,
      height: '75px',
      display: 'flex',
      alignItems: 'center',
      paddingRight: '25px',
      borderBottom: '1px solid #cccccc24',
   },
   navContent: {
      display: 'flex',
      width: '100%',
      borderRadius: '10px',
      backgroundColor: '#F7F7F7',
   },
   item: {
      flex: 1,
      padding: 5,
   },
   button: {
      width: '100%',
      padding: '5px',
      boxShadow: 'none',
      color: '#10223A',
      backgroundColor: '#F7F7F7',
      '&:hover': {
         backgroundColor: '#FFFFFF',
      },
   },
   active: {
      backgroundColor: '#FFFFFF',
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
   },
}));

const tabBar = [
   {
      activeName: 'survey',
      label: 'page_settings_typo_survey_page',
   },
   {
      activeName: 'livechat',
      label: 'page_settings_typo_platforms_live_chat_nav_livechat',
   },
   {
      activeName: 'email',
      label: 'pre_load_input_label_email',
   },
   {
      activeName: 'messaging',
      label: 'page_settings_typo_messaging',
   },
];

const Nav = (props) => {
   const { activeNavButton, setActiveNavButton } = props;
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <Grid container className={classes.container}>
         <Grid className={classes.navContent}>
            {tabBar.map((link) => (
               <Grid key={link.url} item className={classes.item}>
                  <Button
                     onClick={() => setActiveNavButton(link.activeName)}
                     variant='contained'
                     className={classNames(
                        classes.button,
                        activeNavButton === link.activeName && classes.active,
                     )}
                  >
                     {t[`${link.label}`]}
                  </Button>
               </Grid>
            ))}
         </Grid>
      </Grid>
   );
};

export default Nav;

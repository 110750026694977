import { Avatar, Grid } from '@material-ui/core';
import React from 'react';
import MyTypography from '@components/MyTypography';

const AvatarTable = ({ avatarUrl, pageName, pageUsername }) => {
   return (
      <Grid container justifyContent={'center'} alignItems={'center'}>
         <Grid item xs={3}>
            <Avatar style={{ width: 50, height: 50 }} variant={'circular'} src={avatarUrl} alt={pageUsername} />
         </Grid>
         <Grid item container direction={'column'} xs={9}>
            <MyTypography color='initial' bold variant={'body1'}>
               {pageName}
            </MyTypography>
            <MyTypography variant={'caption'} color='initial'>
               {pageUsername}
            </MyTypography>
         </Grid>
      </Grid>
   );
};

export default AvatarTable;

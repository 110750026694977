import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Typography } from '@material-ui/core';
import { Fragment } from 'react';

const PhoneInputComp = (props) => {
   const { onChange, value, width, label, labelColor, editable, height, enableSearch } = props;

   return (
      <Fragment>
         {label && (
            <Typography style={{ textAlign: 'left', color: editable ? labelColor : '#212121', fontSize: '18px' }}>
               {' '}
               {label}{' '}
            </Typography>
         )}
         <PhoneInput
            country={'tr'}
            value={value}
            countryCodeEditable={false}
            disabled={!editable}
            enableSearch={enableSearch}
            containerStyle={{ width: '100%' }}
            inputStyle={{ width, height, backgroundColor: editable ? 'white' : '#eee' }}
            onChange={(phone) => onChange(phone)}
            dropdownStyle={{ height: '190px' }}
         />
      </Fragment>
   );
};

export default PhoneInputComp;

import { useMutation, useQuery } from '@apollo/client';
import { Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { QnAPlatformType } from '../../../Questions/util/types';
import { useSelector } from 'react-redux';
import { getCustomer } from '@store/reducers/person';
import {
   CREATE_QNA_ACCOUNT_MUTATION,
   DELETE_QNA_ACCOUNT_MUTATION,
   UPDATE_QNA_ACCOUNT_MUTATION,
} from '../../../../queries/Settings/Service/GraphQL/QuestionPlatform/mutation';
import { GET_QNA_ACCOUNTS_QUERY } from '../../../../queries/Settings/Service/GraphQL/QuestionPlatform/query';
import Form from './form';
import ModalContent from './modalContent';
import DataTable from './table';
import { GET_TEAMS_QUERY } from '@queries/Teams/query';
import Modal from '@components/Modal/modal';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   title: {
      marginLeft: 10,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   noAccountContainer: {
      height: '60vh',
      padding: theme.spacing(3),
   },
   fbold: {
      fontWeight: 'bold',
   },
   editContainer: {
      padding: `${theme.spacing(2)}px 6px`,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const HepsiBuradaQuestions = (props) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const [selectedItem, setSelectedItem] = useState();
   const t = lang?.translation;
   const [showDisconnectModal, setShowDisconnectModal] = useState(null);
   const [modalConfig, setModalConfig] = useState({
      show: false,
      success: false,
      editMode: false,
   });
   const customer = useSelector(getCustomer);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const errorMessage = (message) =>
      enqueueSnackbar(message, {
         variant: 'error',
         persist: true,
         action: (key) => (
            <Button variant='text' color='inherit' onClick={() => closeSnackbar(key)}>
               Kapat
            </Button>
         ),
      });

   const { data, loading: getAccountsLoading } = useQuery(GET_QNA_ACCOUNTS_QUERY, {
      variables: {
         type: QnAPlatformType.HEPSIBURADA,
      },
      onError: (error) => {
         errorMessage(error.message);
      },
   });

   const { loading: teamsLoading, data: teamsData } = useQuery(GET_TEAMS_QUERY, {
      variables: { customer: customer._id, pageSize: 100, page: 1 },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const [createAccountMutation, { loading: createAccountLoading }] = useMutation(CREATE_QNA_ACCOUNT_MUTATION, {
      update: (
         cache,
         {
            data: {
               createQnAAccount: { data },
            },
         },
      ) => {
         const cachedData = cache.readQuery({
            query: GET_QNA_ACCOUNTS_QUERY,
            variables: {
               type: QnAPlatformType.HEPSIBURADA,
            },
         });

         cache.writeQuery({
            query: GET_QNA_ACCOUNTS_QUERY,
            variables: {
               type: QnAPlatformType.HEPSIBURADA,
            },
            data: {
               QnAAccounts: {
                  ...cachedData.QnAAccounts,
                  data: [...cachedData.QnAAccounts.data, data],
               },
            },
         });
      },
   });

   const [updateAccountMutation, { loading: updateAccountLoading }] = useMutation(UPDATE_QNA_ACCOUNT_MUTATION, {
      update: (
         cache,
         {
            data: {
               updateQnAAccount: { data },
            },
         },
      ) => {
         const cachedData = cache.readQuery({
            query: GET_QNA_ACCOUNTS_QUERY,
            variables: {
               type: QnAPlatformType.HEPSIBURADA,
            },
         });

         cache.writeQuery({
            query: GET_QNA_ACCOUNTS_QUERY,
            variables: {
               type: QnAPlatformType.HEPSIBURADA,
            },
            data: {
               QnAAccounts: {
                  ...cachedData.QnAAccounts,
                  data: cachedData?.QnAAccounts?.data?.map((acc) =>
                     acc._id.toString() === data._id.toString() ? { ...acc, ...data } : acc,
                  ),
               },
            },
         });
      },
   });

   const [deleteAccountMutation, { loading: deleteAccountLoading }] = useMutation(DELETE_QNA_ACCOUNT_MUTATION, {
      update: (
         cache,
         {
            data: {
               deleteQnAAccount: { data },
            },
         },
      ) => {
         const cachedData = cache.readQuery({
            query: GET_QNA_ACCOUNTS_QUERY,
            variables: {
               type: QnAPlatformType.HEPSIBURADA,
            },
         });

         cache.writeQuery({
            query: GET_QNA_ACCOUNTS_QUERY,
            variables: {
               type: QnAPlatformType.HEPSIBURADA,
            },
            data: {
               QnAAccounts: {
                  ...cachedData.QnAAccounts,
                  data: cachedData?.QnAAccounts?.data?.filter((account) => account._id !== data._id),
               },
            },
         });
      },
   });

   const handleShowAddModal = useCallback(() => {
      setModalConfig((prevState) => ({
         ...prevState,
         show: true,
      }));
   }, []);

   const handleCloseModal = useCallback(() => {
      setModalConfig((prevState) => ({
         ...prevState,
         show: false,
         editMode: false,
      }));
   }, []);

   const onAddAccount = useCallback(
      (data) => {
         createAccountMutation({
            variables: {
               type: QnAPlatformType.HEPSIBURADA,
               metaData: {
                  ...data,
                  teams: data.teams.map((team) => team.value),
               },
            },
         })
            .then(({ data }) => {
               setModalConfig((prev) => ({
                  ...prev,
                  success: true,
               }));
            })
            .catch((err) => {
               setModalConfig((prev) => ({
                  ...prev,
                  success: false,
                  errorMessage: err.message,
               }));
            });
      },
      [createAccountMutation],
   );

   if (getAccountsLoading) {
      return <div className='loading' />;
   }

   const handleEdit = (item) => {
      setSelectedItem(item);
      setModalConfig((prev) => ({
         ...prev,
         editMode: true,
      }));
   };

   const handleDelete = (item, deleteType) => {
      deleteAccountMutation({
         variables: {
            _id: item._id,
            type: QnAPlatformType.HEPSIBURADA,
            deleteType,
         },
      })
         .then(({ data }) => {
            enqueueSnackbar(t?.general_information_deleted_successfully, {
               variant: 'success',
               autoHideDuration: 2000,
            });
         })
         .catch((err) => {
            errorMessage(err.message);
         });
   };

   const onAcceptEditData = (data) => {
      updateAccountMutation({
         variables: {
            type: QnAPlatformType.HEPSIBURADA,
            metaData: {
               storeName: data.storeName,
            },
         },
      })
         .then(({ data }) => {
            enqueueSnackbar(t?.general_information_updated_successfully, {
               variant: 'success',
               autoHideDuration: 2000,
            });
            setModalConfig((prev) => ({
               ...prev,
               editMode: false,
            }));
         })
         .catch((err) => {
            errorMessage(err.message);
         });
   };

   const loadingOrFetching = createAccountLoading || updateAccountLoading || deleteAccountLoading || teamsLoading;

   return (
      <Fragment>
         <Grid container justifyContent='flex-start' alignItems='center' className={classes.titleContainer}>
            <Link to='/settings/channels-and-integrations' style={{ marginTop: 5 }}>
               <ArrowBackIcon color='disabled' />
            </Link>
            <Typography className={classes.title}>{t?.page_settings_typo_platforms_hepsi_burada}</Typography>
         </Grid>
         {data?.QnAAccounts?.data?.length === 0 ? (
            <Grid>
               <Grid
                  className={classes.noAccountContainer}
                  direction='column'
                  justifyContent='center'
                  alignItems='center'
                  container
                  spacing={2}
                  xs={12}
               >
                  <Grid item>
                     <Typography variant='h5' align='center' color='inherit' className={classes.fbold}>
                        {t?.setting_hepsiburada_power_up_your_customer}
                     </Typography>
                  </Grid>

                  <Grid item>
                     <Typography color='textSecondary' align='center' variant='body1'>
                        {t?.setting_hepsiburada_you_are_going_to_strengthen}
                     </Typography>
                  </Grid>

                  <Grid item>
                     <ButtonOK
                        onClick={handleShowAddModal}
                        label={t?.settings_hepsiburada_connect}
                        width={200}
                        height={40}
                        fontSize='1.1rem'
                     />
                  </Grid>
               </Grid>
            </Grid>
         ) : (
            <DataTable
               tableData={data?.QnAAccounts?.data}
               handleDelete={handleDelete}
               handleDisconnect={(item) => {
                  if (item.isConnected) {
                     setShowDisconnectModal(item);
                  } else {
                     updateAccountMutation({
                        variables: {
                           type: QnAPlatformType.HEPSIBURADA,
                           metaData: {
                              isConnected: !item.isConnected,
                           },
                        },
                     })
                        .then(({ data }) => {
                           enqueueSnackbar(t?.general_information_updated_successfully, {
                              variant: 'success',
                              autoHideDuration: 2000,
                           });
                        })
                        .catch((err) => {
                           errorMessage(err.message);
                        });
                  }
               }}
               handleEdit={handleEdit}
            />
         )}
         <Modal open={modalConfig.show} onClose={handleCloseModal} modalHeader={t?.settings_hepsiburada_connect}>
            <ModalContent
               loading={loadingOrFetching}
               status={modalConfig.success}
               onAccept={onAddAccount}
               onCancel={handleCloseModal}
               errorMessage={modalConfig?.errorMessage}
               teamsData={teamsData?.getTeamList?.data?.docs}
            />
         </Modal>
         <Modal open={modalConfig.editMode} onClose={handleCloseModal} modalHeader={t?.settings_edit_account}>
            <Grid justifyContent='center' alignItems='center' container className={classes.editContainer} xs={12}>
               <Form onAccept={onAcceptEditData} mode data={selectedItem} teamsData={teamsData?.getTeamList?.data?.docs} />
            </Grid>
         </Modal>
         <Modal
            paperStyle={{ width: 450 }}
            onClose={() => setShowDisconnectModal(null)}
            open={showDisconnectModal != null}
            modalHeader={t?.comp_button_disconnect + '?'}
         >
            <ModalLargeText style={{ textAlign: 'center', marginBlock: 40 }}>
               {t?.settings_question_disconnect_modal_title}
            </ModalLargeText>
            <ModalButtonField>
               <ButtonCancel
                  label={t?.general_message_no}
                  onClick={() => setShowDisconnectModal(null)}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
               <ButtonOK
                  label={t?.general_message_yes}
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#0060df',
                  }}
                  onClick={() => {
                     updateAccountMutation({
                        variables: {
                           type: QnAPlatformType.HEPSIBURADA,
                           metaData: {
                              isConnected: !showDisconnectModal.isConnected,
                           },
                        },
                     })
                        .then(({ data }) => {
                           enqueueSnackbar(t?.general_information_updated_successfully, {
                              variant: 'success',
                              autoHideDuration: 2000,
                           });
                           setShowDisconnectModal(null);
                        })
                        .catch((err) => {
                           setShowDisconnectModal(null);
                           errorMessage(err.message);
                        });
                  }}
               />
            </ModalButtonField>
         </Modal>
      </Fragment>
   );
};

export default HepsiBuradaQuestions;

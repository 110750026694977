import { Grid, MenuItem, Select, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useRef } from 'react';
import { EnglishFlagIcon, TurkishFlagIcon } from '@assets/icons/NavbarIcons/LanguageIcons';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme) => ({
   root: {
      '& .MuiSelect-select:focus': {
         backgroundColor: 'white',
      },
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: null,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   selectedItemTitle: {
      marginLeft: 5,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   menuItem: {
      margin: 0,
      marginTop: 5,
      marginBottom: 5,
      padding: 0,
   },
   iconColor: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginRight: 5,
   },
   notSelectedContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#E8F4FF',
      borderRadius: 5,
      width: 100,
   },
   SelectedContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      borderRadius: 5,
      width: 100,
   },
   notSelectedTitle: {
      color: '#35526F',
      paddingLeft: theme.spacing(1),
   },
   SelectedTitle: {
      color: 'grey',
      paddingLeft: theme.spacing(1),
   },
   iconContainer: {
      [theme.breakpoints.down('sm')]: {
         paddingLeft: theme.spacing(1),
      },
   },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
   anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
   },
   getContentAnchorEl: null,
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 105,
      },
   },
};
// Get Language Icon from code
const getLanguageIcon = (code, isSmall) => {
   switch (code) {
      case 'TR':
         return <TurkishFlagIcon className={isSmall ? 'language-small-chip-icon' : 'language-flag-icon'} />;
      case 'EN':
         return <EnglishFlagIcon className={isSmall ? 'language-small-chip-icon' : 'language-flag-icon'} />;
      default:
         break;
   }
};

const LangSelectBox = () => {
   const classes = useStyles();
   const { languages, lang, switchLang } = useContext(LanguageContext);
   const selectRef = useRef();
   const handleChangeLanguage = (lang) => {
      selectRef.current.blur();
      switchLang({ ...lang });
      window.localStorage.setItem('langCode', lang.code);
   };

   return (
      <Grid className={classes.root}>
         <Select
            ref={selectRef}
            fullWidth
            disableUnderline
            value={lang?.code}
            renderValue={() => (
               <Grid container className={classes.iconContainer} direction='row' wrap='nowrap'>
                  {getLanguageIcon(lang?.shortTitle)}
               </Grid>
            )}
            MenuProps={MenuProps}
         >
            {languages.map((option) => (
               <MenuItem
                  style={{ background: 'transparent', marginLeft: 10, marginRight: 10 }}
                  key={option.code}
                  value={option.code}
                  className={classes.menuItem}
                  onClick={() => handleChangeLanguage(option)}
               >
                  <div
                     className={option.title === lang?.title ? classes.notSelectedContainer : classes.SelectedContainer}
                  >
                     {/* Small Chip Icons */}
                     {getLanguageIcon(option.shortTitle, true)}
                     <Typography
                        variant='subtitle1'
                        className={option.title === lang?.title ? classes.notSelectedTitle : classes.SelectedTitle}
                     >
                        {option.title}
                     </Typography>
                  </div>
               </MenuItem>
            ))}
         </Select>
      </Grid>
   );
};

export default LangSelectBox;

import React, { Fragment, useContext } from 'react';
import { Link, Typography, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { LanguageContext } from '@helper/locale/langContext';
import { trimLongText } from '@helper/textUtils';
import useSeeMore from '../../../../../hooks/useSeeMore';
import { MyTheme } from '@styles/config';
import { MediaType } from '../../../../../types/utils';
import MyTypography from '@components/MyTypography';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';
interface MessageItemProps {
   isMine?: boolean;
   text?: string;
   time?: string;
   senderTitle?: string;
   type?: MediaType;
   personPhoto?: string;
   showAvatarOnMessage?: boolean;
   isRejectedMessage?: boolean;
   isDeletedMessage?: boolean;
   isFailedMessage?: boolean;
   isTimeoutMessage?: boolean;
   isNotAnsweredMessage?: boolean;
   isSending?: boolean;
   isModifiedOnPlatform?: boolean;
   isCommentAnswer?: boolean;
}

const useStyles = makeStyles<MyTheme, MessageItemProps>((theme) => ({
   root: {
      marginTop: 8,
   },
   content: {
      borderRadius: 10,
      borderTopLeftRadius: 0,
      padding: 10,
      paddingRight: 15,
      paddingLeft: 12,
      // [theme.breakpoints.up('lg')]: {
      //    // maxWidth: (props) =>
      //    //    props.isRejectedMessage || props.isDeletedMessage || props.isTimeoutMessage || props.isFailedMessage
      //    //       ? '25%'
      //    //       : '50%',
      // },
      maxWidth: '50%',
      minWidth: 100,
      textAlign: 'left',
      wordBreak: 'break-word',
      backgroundColor: (props) =>
         props.isRejectedMessage || props.isDeletedMessage || props.isFailedMessage
            ? '#FDE7EC'
            : props.isTimeoutMessage
               ? '#E6E6E6'
               : props.isMine
                  ? '#F2F7FF'
                  : '#F7F7FA',
      [theme.breakpoints.down('sm')]: {
         maxWidth: '80% !important',
      },
   },
   isMine: {
      borderRadius: 10,
      borderTopRightRadius: 0,
   },
   metaData: {
      fontSize: '0.8rem',
      marginTop: 5,
      color: '#7F92A5',
   },
   textContainer: {
      // padding: (props) => (props?.medias?.length > 0 ? 5 : undefined),
   },
   text: {
      marginRight: 10,
      padding: 0,
      // fontSize: '0.85rem',
      color: '#2C2C2C',
      whiteSpace: 'pre-line',
      fontWeight: 'normal',
   },
   timeoutOrRejected: {
      borderRadius: 10,
   },
   tooltip: {
      backgroundColor: (props) => (props.isMine ? '#F2F7FF' : '#F7F7FA'),
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
   },
}));

const QuestionMessageItem: React.FC<MessageItemProps> = (props) => {
   const {
      isMine,
      text,
      time,
      isDeletedMessage,
      isTimeoutMessage,
      isRejectedMessage,
      isSending,
      isModifiedOnPlatform,
      isNotAnsweredMessage,
      isCommentAnswer,
      isFailedMessage,
   } = props;
   const classes = useStyles(props);
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const length = 200;
   // @ts-ignore
   const [showMore, handleSeeMore] = useSeeMore(text, length);

   const isTimeoutOrRejectedMessage = isTimeoutMessage || isRejectedMessage || isDeletedMessage || isFailedMessage || isNotAnsweredMessage;

   return (
      <Grid container className={classes.root} direction='column' xs={12}>
         <Grid item container direction='column'>
            <Grid container justifyContent={isMine ? 'flex-end' : 'flex-start'}>
               {isCommentAnswer && <SubdirectoryArrowRightIcon fontSize={'large'} color={'action'} />}
               <div
                  className={clsx(
                     classes.content,
                     isTimeoutOrRejectedMessage ? classes.timeoutOrRejected : isMine && classes.isMine
                  )}
               >
                  <Grid container direction='column'>
                     <Fragment>
                        <Tooltip
                           title={
                              <MyTypography variant='caption' style={{ backgroundColor: 'transparent' }}>
                                 {time}
                              </MyTypography>
                           }
                           arrow
                           placement={isMine ? 'left' : 'right'}
                           classes={{
                              tooltip: classes.tooltip,
                           }}
                        >
                           {isRejectedMessage || isDeletedMessage || isFailedMessage || isNotAnsweredMessage ? (
                              <Grid xs={12} container>
                                 <Grid item xs={12}>
                                    <MyTypography variant='body2' align='left' bold>
                                       {isModifiedOnPlatform
                                          ? t?.this_question_rejected_other_platform
                                          : isDeletedMessage
                                             ? t?.deleted_reason
                                             : isFailedMessage
                                                ? t?.error
                                                : isNotAnsweredMessage ?
                                                   t?.mark_as_replied_reason
                                                   : t?.reject_reason}
                                    </MyTypography>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <MyTypography
                                       style={{
                                          paddingTop: 8,
                                       }}
                                       variant='body2'
                                       align='left'
                                    >
                                       {isFailedMessage ? t?.failed_question_message : text}
                                    </MyTypography>
                                 </Grid>
                              </Grid>
                           ) : isTimeoutMessage ? (
                              <Grid container xs={12}>
                                 <Grid item xs={12}>
                                    <MyTypography align='left' variant='body2' color='inherit' bold>
                                       {t?.system_message}
                                    </MyTypography>
                                 </Grid>
                                 <Grid item xs={12}>
                                    <MyTypography
                                       style={{
                                          paddingTop: 8,
                                       }}
                                       variant='body2'
                                       align='left'
                                    >
                                       {t?.this_question_is_timeout_description || text}
                                    </MyTypography>
                                 </Grid>
                              </Grid>
                           ) : (
                              <Grid xs={12} className={classes.textContainer}>
                                 {isModifiedOnPlatform && (
                                    <Grid item xs={12}>
                                       <MyTypography
                                          style={{ padding: '4px 0px' }}
                                          align='left'
                                          variant='body2'
                                          color='inherit'
                                          bold
                                       >
                                          {t?.this_question_answered_other_platform}
                                       </MyTypography>
                                    </Grid>
                                 )}
                                 {showMore ? (
                                    <MyTypography bold className={classes.text}>
                                       {trimLongText(text, length)}
                                       <Link
                                          variant='body2'
                                          //@ts-ignore
                                          onClick={handleSeeMore}
                                          style={{ display: 'inline', wordBreak: 'break-word' }}
                                       >
                                          {t?.see_more}
                                       </Link>
                                    </MyTypography>
                                 ) : (
                                    <Typography
                                       variant='body2'
                                       style={{ paddingTop: isModifiedOnPlatform ? 8 : undefined }}
                                       className={classes.text}
                                    >
                                       {text == "mark_as_replied" ? t?.question_is_not_answered : text}
                                    </Typography>
                                 )}
                              </Grid>
                           )}
                        </Tooltip>
                        {isSending && (
                           <Grid item>
                              <Typography align={'right'} className={classes.metaData}>
                                 {t?.chat_page_message_sending}
                              </Typography>
                           </Grid>
                        )}
                     </Fragment>
                  </Grid>
               </div>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default QuestionMessageItem;

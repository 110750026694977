import React, { useContext, useEffect, useState } from 'react';
import IconAndGreetingPreview from '../../SharedComponents/Previews/IconAndGreetingPreview';
import usePreviousState from '../../../../../../hooks/usePreviousState';
import LiveChatSettingsContext from '../../../../../../contexts/LiveChatSettingsContext';
import _ from 'underscore';
import IntroPreview from '../../SharedComponents/Previews/IntroPreview';
import ChatPreview from '../../SharedComponents/Previews/ChatPreview';

const groupsAndTheirKeys = {
   chat: ['onlineStatus'],
   greeting: ['agentProfiles'],
   whatsappChannel: ['isWhatsapp', 'whatsappButtonTitle', 'whatsappTitle', 'whatsappContent'],
   facebookChannel: ['isFacebook', 'facebookButtonTitle', 'facebookTitle', 'facebookContent'],
   instagramChannel: ['isInstagram', 'instagramButtonTitle', 'instagramTitle', 'instagramContent'],
   phoneCallChannel: [
      'isPhoneCall',
      'phoneCallButtonTitle',
      'phoneCallTitle',
      'phoneCallContent',
      'phoneCallCountryCode',
      'phoneCallNumber',
   ],
};

const Previews = () => {
   const { liveChatPreviewSettings } = useContext(LiveChatSettingsContext);
   const prevPreviewData = usePreviousState(liveChatPreviewSettings, liveChatPreviewSettings);
   const [currentPreview, setCurrentPreview] = useState('default');

   useEffect(() => {
      // set preview to show
      if (prevPreviewData && liveChatPreviewSettings) {
         if (!_.isEqual(prevPreviewData, liveChatPreviewSettings)) {
            if (prevPreviewData.onlineStatus !== liveChatPreviewSettings.onlineStatus) {
               setCurrentPreview('chat');
            } else if (prevPreviewData.agentProfiles !== liveChatPreviewSettings.agentProfiles) {
               setCurrentPreview('greeting');
            } else {
               const channels = ['whatsappChannel', 'facebookChannel', 'instagramChannel', 'phoneCallChannel'];
               let findNewPreviewToShow = false;
               channels.forEach((channel) => {
                  const channelWithElements = groupsAndTheirKeys[channel];
                  if (channelWithElements) {
                     channelWithElements.forEach((key, index) => {
                        if (index === 0) {
                           if (prevPreviewData[key] !== liveChatPreviewSettings[key]) {
                              if (prevPreviewData[key] === false && liveChatPreviewSettings[key] === true)
                                 setCurrentPreview(channel);
                              if (prevPreviewData[key] === true && liveChatPreviewSettings[key] === false)
                                 findNewPreviewToShow = true;
                           }
                        } else {
                           if (prevPreviewData[key] !== liveChatPreviewSettings[key]) {
                              setCurrentPreview(channel);
                           }
                        }
                     });
                  }
               });
               if (findNewPreviewToShow) {
                  const channel = channels
                     .reverse()
                     .find((channel) => liveChatPreviewSettings[groupsAndTheirKeys[channel][0]] === true);
                  if (channel) {
                     setCurrentPreview(channel);
                  } else if (liveChatPreviewSettings.agentProfiles) {
                     setCurrentPreview('greeting');
                  } else if (liveChatPreviewSettings.onlineStatus) {
                     setCurrentPreview('chat');
                  } else {
                     setCurrentPreview('default');
                  }
               }
            }
         }
      }
   }, [prevPreviewData, liveChatPreviewSettings]);

   return (
      <>
         {currentPreview === 'default' && liveChatPreviewSettings && <IconAndGreetingPreview type='icon' />}
         {currentPreview === 'chat' && liveChatPreviewSettings && (
            <ChatPreview type='welcomeMessageDuringWorkingHours' />
         )}
         {currentPreview === 'greeting' && liveChatPreviewSettings && <IntroPreview type='greeting' />}
         {currentPreview === 'whatsappChannel' && liveChatPreviewSettings && <IntroPreview type='whatsappChannel' />}
         {currentPreview === 'facebookChannel' && liveChatPreviewSettings && <IntroPreview type='facebookChannel' />}
         {currentPreview === 'instagramChannel' && liveChatPreviewSettings && <IntroPreview type='instagramChannel' />}
         {currentPreview === 'phoneCallChannel' && liveChatPreviewSettings && <IntroPreview type='phoneCallChannel' />}
      </>
   );
};

export default Previews;

import React from 'react';

export const DashboardActiveIcon = ({ className, style }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} style={style} viewBox='0 0 41.508 41.563'>
         <g id='Group_257' data-name='Group 257' transform='translate(-162.031 -165.578)'>
            <path
               id='Path_84'
               data-name='Path 84'
               d='M195.08,231.792c0-2.484,0-4.712,0-6.941,0-.53-.017-1.061.008-1.59.053-1.124.7-1.813,1.629-1.782a1.584,1.584,0,0,1,1.5,1.784c.017,3.787.03,7.574-.006,11.36-.015,1.558-1.261,2.2-2.587,1.354-1.689-1.078-3.316-2.254-5.022-3.3a3.81,3.81,0,0,0-1.877-.557c-6.778-.039-13.557-.021-20.335-.026-3.932,0-6.353-2.436-6.355-6.378q0-8.009,0-16.018c0-3.891,2.413-6.3,6.3-6.307,1.893,0,3.787-.012,5.68,0,1.136.009,1.851.622,1.863,1.542s-.692,1.575-1.831,1.6c-1.893.035-3.787,0-5.68.016-2.209.021-3.158.993-3.159,3.221q-.006,8.009,0,16.018c0,2.12,1,3.113,3.129,3.114,6.551,0,13.1.045,19.653-.026a7.316,7.316,0,0,1,4.968,1.512C193.539,230.853,194.2,231.215,195.08,231.792Z'
               transform='translate(0 -29.212)'
               fill='#90c1fd'
            />
            <path
               id='Path_85'
               data-name='Path 85'
               d='M244.955,191.37a27.489,27.489,0,0,1-4.666-1.218,6.463,6.463,0,0,0-4.145-.391c-2.08.417-2.845-.347-2.4-2.442a6.813,6.813,0,0,0-.425-4.131,12.713,12.713,0,1,1,24.369-6.528A12.94,12.94,0,0,1,244.955,191.37Zm-7.47-5.359c1.125.349,2.382.645,3.565,1.126a10.161,10.161,0,0,0,6.1.65,9.605,9.605,0,1,0-10.759-5.262A26.341,26.341,0,0,1,237.485,186.011Z'
               transform='translate(-54.28)'
               fill='#90c1fd'
            />
            <path
               id='Path_86'
               data-name='Path 86'
               d='M286.06,218.009c-.074.8-.418,1.358-1.307,1.41a1.375,1.375,0,1,1,1.307-1.41Z'
               transform='translate(-93.69 -39.48)'
               fill='#90c1fd'
            />
            <path
               id='Path_87'
               data-name='Path 87'
               d='M264.4,216.612a1.35,1.35,0,0,1,1.325,1.492,1.252,1.252,0,0,1-1.407,1.268,1.284,1.284,0,0,1-1.346-1.354A1.373,1.373,0,0,1,264.4,216.612Z'
               transform='translate(-77.999 -39.435)'
               fill='#90c1fd'
            />
            <path
               id='Path_88'
               data-name='Path 88'
               d='M306.527,218.006a1.343,1.343,0,0,1-1.441,1.38,1.241,1.241,0,0,1-1.318-1.356,1.274,1.274,0,0,1,1.292-1.4A1.392,1.392,0,0,1,306.527,218.006Z'
               transform='translate(-109.525 -39.446)'
               fill='#90c1fd'
            />
         </g>
      </svg>
   );
};

export const DashboardSolvedIcon = ({ className, style }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} style={style} viewBox='0 0 41.508 41.563'>
         <g id='Group_258' data-name='Group 258' transform='translate(-162.031 -165.578)'>
            <path
               id='Path_84'
               data-name='Path 84'
               d='M195.08,231.792c0-2.484,0-4.712,0-6.941,0-.53-.017-1.061.008-1.59.053-1.124.7-1.813,1.629-1.782a1.584,1.584,0,0,1,1.5,1.784c.017,3.787.03,7.574-.006,11.36-.015,1.558-1.261,2.2-2.587,1.354-1.689-1.078-3.316-2.254-5.022-3.3a3.81,3.81,0,0,0-1.877-.557c-6.778-.039-13.557-.021-20.335-.026-3.932,0-6.353-2.436-6.355-6.378q0-8.009,0-16.018c0-3.891,2.413-6.3,6.3-6.307,1.893,0,3.787-.012,5.68,0,1.136.009,1.851.622,1.863,1.542s-.692,1.575-1.831,1.6c-1.893.035-3.787,0-5.68.016-2.209.021-3.158.993-3.159,3.221q-.006,8.009,0,16.018c0,2.12,1,3.113,3.129,3.114,6.551,0,13.1.045,19.653-.026a7.316,7.316,0,0,1,4.968,1.512C193.539,230.853,194.2,231.215,195.08,231.792Z'
               transform='translate(0 -29.212)'
               fill='#67ceaa'
            />
            <path
               id='Path_85'
               data-name='Path 85'
               d='M244.955,191.37a27.489,27.489,0,0,1-4.666-1.218,6.463,6.463,0,0,0-4.145-.391c-2.08.417-2.845-.347-2.4-2.442a6.813,6.813,0,0,0-.425-4.131,12.713,12.713,0,1,1,24.369-6.528A12.94,12.94,0,0,1,244.955,191.37Zm-7.47-5.359c1.125.349,2.382.645,3.565,1.126a10.161,10.161,0,0,0,6.1.65,9.605,9.605,0,1,0-10.759-5.262A26.341,26.341,0,0,1,237.485,186.011Z'
               transform='translate(-54.28)'
               fill='#67ceaa'
            />
            <g id='Group_650' data-name='Group 650' transform='translate(184.033 174.17)'>
               <path
                  id='Path_3500'
                  data-name='Path 3500'
                  d='M2388.057,2931.541l1.251-1.256,4.779-4.779a1.291,1.291,0,0,1,1.991.173,1.253,1.253,0,0,1-.156,1.661q-3.451,3.463-6.909,6.917a1.333,1.333,0,0,1-1.894,0q-2.035-2.05-4.084-4.087a1.309,1.309,0,0,1-.286-1.484,1.289,1.289,0,0,1,1.668-.656,1.605,1.605,0,0,1,.475.322q1.552,1.557,3.108,3.112A.561.561,0,0,1,2388.057,2931.541Z'
                  transform='translate(-2382.635 -2925.116)'
                  fill='#67ceaa'
               />
            </g>
         </g>
      </svg>
   );
};

export const DashboardWaitingIcon = ({ className, style }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} style={style} viewBox='0 0 41.508 41.563'>
         <g id='Group_259' data-name='Group 259' transform='translate(-162.031 -165.578)'>
            <path
               id='Path_84'
               data-name='Path 84'
               d='M195.08,231.792c0-2.484,0-4.712,0-6.941,0-.53-.017-1.061.008-1.59.053-1.124.7-1.813,1.629-1.782a1.584,1.584,0,0,1,1.5,1.784c.017,3.787.03,7.574-.006,11.36-.015,1.558-1.261,2.2-2.587,1.354-1.689-1.078-3.316-2.254-5.022-3.3a3.81,3.81,0,0,0-1.877-.557c-6.778-.039-13.557-.021-20.335-.026-3.932,0-6.353-2.436-6.355-6.378q0-8.009,0-16.018c0-3.891,2.413-6.3,6.3-6.307,1.893,0,3.787-.012,5.68,0,1.136.009,1.851.622,1.863,1.542s-.692,1.575-1.831,1.6c-1.893.035-3.787,0-5.68.016-2.209.021-3.158.993-3.159,3.221q-.006,8.009,0,16.018c0,2.12,1,3.113,3.129,3.114,6.551,0,13.1.045,19.653-.026a7.316,7.316,0,0,1,4.968,1.512C193.539,230.853,194.2,231.215,195.08,231.792Z'
               transform='translate(0 -29.212)'
               fill='#fed987'
            />
            <path
               id='Path_85'
               data-name='Path 85'
               d='M244.955,191.37a27.489,27.489,0,0,1-4.666-1.218,6.463,6.463,0,0,0-4.145-.391c-2.08.417-2.845-.347-2.4-2.442a6.813,6.813,0,0,0-.425-4.131,12.713,12.713,0,1,1,24.369-6.528A12.94,12.94,0,0,1,244.955,191.37Zm-7.47-5.359c1.125.349,2.382.645,3.565,1.126a10.161,10.161,0,0,0,6.1.65,9.605,9.605,0,1,0-10.759-5.262A26.341,26.341,0,0,1,237.485,186.011Z'
               transform='translate(-54.28)'
               fill='#fed987'
            />
            <path
               id='Path_2'
               data-name='Path 2'
               d='M12.27,10.429H8.277A1.291,1.291,0,0,1,6.989,9.141V5.277A1.217,1.217,0,0,1,8.277,3.989,1.217,1.217,0,0,1,9.565,5.277V7.853h2.7a1.217,1.217,0,0,1,1.288,1.288A1.385,1.385,0,0,1,12.27,10.429Z'
               transform='translate(181.825 170.243)'
               fill='#fed987'
            />
         </g>
      </svg>
   );
};

export const DashboardTimeOutIcon = ({ className, style }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} style={style} viewBox='0 0 41.508 41.563'>
         <g id='Group_260' data-name='Group 260' transform='translate(-1389.601 -251.395)'>
            <g id='Group_260-2' data-name='Group 260' transform='translate(1227.57 85.816)'>
               <path
                  id='Path_84'
                  data-name='Path 84'
                  d='M195.08,231.792c0-2.484,0-4.712,0-6.941,0-.53-.017-1.061.008-1.59.053-1.124.7-1.813,1.629-1.782a1.584,1.584,0,0,1,1.5,1.784c.017,3.787.03,7.574-.006,11.36-.015,1.558-1.261,2.2-2.587,1.354-1.689-1.078-3.316-2.254-5.022-3.3a3.81,3.81,0,0,0-1.877-.557c-6.778-.039-13.557-.021-20.335-.026-3.932,0-6.353-2.436-6.355-6.378q0-8.009,0-16.018c0-3.891,2.413-6.3,6.3-6.307,1.893,0,3.787-.012,5.68,0,1.136.009,1.851.622,1.863,1.542s-.692,1.575-1.831,1.6c-1.893.035-3.787,0-5.68.016-2.209.021-3.158.993-3.159,3.221q-.006,8.009,0,16.018c0,2.12,1,3.113,3.129,3.114,6.551,0,13.1.045,19.653-.026a7.316,7.316,0,0,1,4.968,1.512C193.539,230.853,194.2,231.215,195.08,231.792Z'
                  transform='translate(0 -29.212)'
                  fill='#f19ba5'
               />
               <path
                  id='Path_85'
                  data-name='Path 85'
                  d='M244.955,191.37a27.489,27.489,0,0,1-4.666-1.218,6.463,6.463,0,0,0-4.145-.391c-2.08.417-2.845-.347-2.4-2.442a6.813,6.813,0,0,0-.425-4.131,12.713,12.713,0,1,1,24.369-6.528A12.94,12.94,0,0,1,244.955,191.37Zm-7.47-5.359c1.125.349,2.382.645,3.565,1.126a10.161,10.161,0,0,0,6.1.65,9.605,9.605,0,1,0-10.759-5.262A26.341,26.341,0,0,1,237.485,186.011Z'
                  transform='translate(-54.28)'
                  fill='#f19ba5'
               />
               <rect
                  id='Rectangle_60'
                  data-name='Rectangle 60'
                  width='9'
                  height='3'
                  rx='1.5'
                  transform='translate(186.43 177.184)'
                  fill='#f19ba5'
               />
            </g>
         </g>
      </svg>
   );
};

export const DashboardSpamIcon = ({ className, style }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} style={style} viewBox='0 0 41.508 41.563'>
         <g id='Group_262' data-name='Group 262' transform='translate(-1819 -251.395)'>
            <g id='Group_261' data-name='Group 261' transform='translate(429.399)'>
               <g id='Group_260' data-name='Group 260' transform='translate(1227.57 85.816)'>
                  <path
                     id='Path_84'
                     data-name='Path 84'
                     d='M195.08,231.792c0-2.484,0-4.712,0-6.941,0-.53-.017-1.061.008-1.59.053-1.124.7-1.813,1.629-1.782a1.584,1.584,0,0,1,1.5,1.784c.017,3.787.03,7.574-.006,11.36-.015,1.558-1.261,2.2-2.587,1.354-1.689-1.078-3.316-2.254-5.022-3.3a3.81,3.81,0,0,0-1.877-.557c-6.778-.039-13.557-.021-20.335-.026-3.932,0-6.353-2.436-6.355-6.378q0-8.009,0-16.018c0-3.891,2.413-6.3,6.3-6.307,1.893,0,3.787-.012,5.68,0,1.136.009,1.851.622,1.863,1.542s-.692,1.575-1.831,1.6c-1.893.035-3.787,0-5.68.016-2.209.021-3.158.993-3.159,3.221q-.006,8.009,0,16.018c0,2.12,1,3.113,3.129,3.114,6.551,0,13.1.045,19.653-.026a7.316,7.316,0,0,1,4.968,1.512C193.539,230.853,194.2,231.215,195.08,231.792Z'
                     transform='translate(0 -29.212)'
                     fill='#c2d2e3'
                  />
                  <path
                     id='Path_85'
                     data-name='Path 85'
                     d='M244.955,191.37a27.489,27.489,0,0,1-4.666-1.218,6.463,6.463,0,0,0-4.145-.391c-2.08.417-2.845-.347-2.4-2.442a6.813,6.813,0,0,0-.425-4.131,12.713,12.713,0,1,1,24.369-6.528A12.94,12.94,0,0,1,244.955,191.37Zm-7.47-5.359c1.125.349,2.382.645,3.565,1.126a10.161,10.161,0,0,0,6.1.65,9.605,9.605,0,1,0-10.759-5.262A26.341,26.341,0,0,1,237.485,186.011Z'
                     transform='translate(-54.28)'
                     fill='#c2d2e3'
                  />
               </g>
            </g>
            <rect
               id='Rectangle_61'
               data-name='Rectangle 61'
               width='11.05'
               height='3'
               rx='1.5'
               transform='translate(1842.932 267.105) rotate(-45)'
               fill='#c2d2e3'
            />
            <rect
               id='Rectangle_62'
               data-name='Rectangle 62'
               width='11.05'
               height='3'
               rx='1.5'
               transform='translate(1845.053 259.291) rotate(45)'
               fill='#c2d2e3'
            />
         </g>
      </svg>
   );
};

export const ReceivedMessagesIcon = ({ className, fill }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} fill={fill} viewBox='0 0 30.206 30.246'>
         <g id='Group_255' data-name='Group 255' transform='translate(-134.702 -349.812)'>
            <g id='Group_253' data-name='Group 253' transform='translate(117.702 164.095)'>
               <g id='Group_136' data-name='Group 136' transform='translate(17 185.717)'>
                  <path
                     id='Path_84'
                     data-name='Path 84'
                     d='M186.082,224.056c0-1.808,0-3.429,0-5.051,0-.386-.013-.772.005-1.157.039-.818.506-1.32,1.186-1.3a1.153,1.153,0,0,1,1.091,1.3c.013,2.756.022,5.511,0,8.267a1.129,1.129,0,0,1-1.883.985c-1.229-.784-2.413-1.64-3.654-2.4a2.772,2.772,0,0,0-1.366-.405c-4.933-.028-9.865-.015-14.8-.019a4.293,4.293,0,0,1-4.625-4.641q0-5.828,0-11.657a4.26,4.26,0,0,1,4.587-4.59c1.378,0,2.756-.009,4.133,0,.827.007,1.347.452,1.356,1.122s-.5,1.146-1.333,1.162c-1.377.025-2.756,0-4.133.012-1.607.015-2.3.722-2.3,2.344q0,5.828,0,11.657a1.986,1.986,0,0,0,2.277,2.266c4.767,0,9.535.033,14.3-.019a5.324,5.324,0,0,1,3.615,1.1C184.96,223.373,185.443,223.637,186.082,224.056Z'
                     transform='translate(-162.031 -197.13)'
                  />
                  <path
                     id='Path_85'
                     data-name='Path 85'
                     d='M241.5,184.347a20,20,0,0,1-3.4-.887,4.7,4.7,0,0,0-3.016-.285c-1.513.3-2.07-.253-1.748-1.777a4.958,4.958,0,0,0-.31-3.006,9.252,9.252,0,1,1,17.734-4.751A9.417,9.417,0,0,1,241.5,184.347Zm-5.436-3.9c.819.254,1.734.469,2.594.819a7.394,7.394,0,0,0,4.442.473,6.99,6.99,0,1,0-7.829-3.829A19.166,19.166,0,0,1,236.066,180.447Z'
                     transform='translate(-220.658 -165.578)'
                  />
               </g>
            </g>
            <path
               id='Path_175'
               data-name='Path 175'
               d='M32.943,24.441l2.275,2.274c.406.406.462.773.163,1.069s-.641.234-1.044-.17l-2.585-2.585c-.444-.445-.442-.72.009-1.172q1.334-1.334,2.667-2.669a.674.674,0,0,1,.658-.235.62.62,0,0,1,.284,1.065c-.519.537-1.053,1.059-1.582,1.586C33.517,23.875,33.244,24.143,32.943,24.441Z'
               transform='translate(121.624 334.688)'
               strokeWidth='1'
            />
         </g>
      </svg>
   );
};

export const SentMessagesIcon = ({ className, fill }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} fill={fill} viewBox='0 0 30.206 30.246'>
         <g id='Group_256' data-name='Group 256' transform='translate(-134.702 -349.812)'>
            <g id='Group_253' data-name='Group 253' transform='translate(117.702 164.095)'>
               <g id='Group_136' data-name='Group 136' transform='translate(17 185.717)'>
                  <path
                     id='Path_84'
                     data-name='Path 84'
                     d='M186.082,224.056c0-1.808,0-3.429,0-5.051,0-.386-.013-.772.005-1.157.039-.818.506-1.32,1.186-1.3a1.153,1.153,0,0,1,1.091,1.3c.013,2.756.022,5.511,0,8.267a1.129,1.129,0,0,1-1.883.985c-1.229-.784-2.413-1.64-3.654-2.4a2.772,2.772,0,0,0-1.366-.405c-4.933-.028-9.865-.015-14.8-.019a4.293,4.293,0,0,1-4.625-4.641q0-5.828,0-11.657a4.26,4.26,0,0,1,4.587-4.59c1.378,0,2.756-.009,4.133,0,.827.007,1.347.452,1.356,1.122s-.5,1.146-1.333,1.162c-1.377.025-2.756,0-4.133.012-1.607.015-2.3.722-2.3,2.344q0,5.828,0,11.657a1.986,1.986,0,0,0,2.277,2.266c4.767,0,9.535.033,14.3-.019a5.324,5.324,0,0,1,3.615,1.1C184.96,223.373,185.443,223.637,186.082,224.056Z'
                     transform='translate(-162.031 -197.13)'
                  />
                  <path
                     id='Path_85'
                     data-name='Path 85'
                     d='M241.5,184.347a20,20,0,0,1-3.4-.887,4.7,4.7,0,0,0-3.016-.285c-1.513.3-2.07-.253-1.748-1.777a4.958,4.958,0,0,0-.31-3.006,9.252,9.252,0,1,1,17.734-4.751A9.417,9.417,0,0,1,241.5,184.347Zm-5.436-3.9c.819.254,1.734.469,2.594.819a7.394,7.394,0,0,0,4.442.473,6.99,6.99,0,1,0-7.829-3.829A19.166,19.166,0,0,1,236.066,180.447Z'
                     transform='translate(-220.658 -165.578)'
                  />
               </g>
            </g>
            <path
               id='Path_175'
               data-name='Path 175'
               d='M34.048,24.441l-2.275,2.274c-.406.406-.462.773-.163,1.069s.641.234,1.044-.17l2.585-2.585c.444-.445.442-.72-.009-1.172q-1.334-1.334-2.667-2.669a.674.674,0,0,0-.658-.235.62.62,0,0,0-.284,1.065c.519.537,1.053,1.059,1.582,1.586C33.474,23.875,33.747,24.143,34.048,24.441Z'
               transform='translate(122.624 334.688)'
               strokeWidth='1'
            />
         </g>
      </svg>
   );
};

export const DashboardPlatformIcon = ({ className, style }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} style={style} viewBox='0 0 25.022 24.982'>
         <g id='Group_213' data-name='Group 213' transform='translate(167.567 152.025)'>
            <path
               id='Path_165'
               data-name='Path 165'
               d='M-167.567-145.367a1.5,1.5,0,0,1,.929-1.042q5.429-2.694,10.84-5.421a1.549,1.549,0,0,1,1.531.013c3.328,1.684,6.668,3.345,10,5.014.349.174.7.35,1.046.524a1.1,1.1,0,0,1,.665,1.038,1.107,1.107,0,0,1-.669,1.037l-11.269,5.634a1.187,1.187,0,0,1-1.123,0q-5.622-2.813-11.247-5.62a1.364,1.364,0,0,1-.708-.909Zm21.316.127c-.088-.05-.155-.091-.225-.126q-4.173-2.087-8.345-4.177a.464.464,0,0,0-.469.007q-4.158,2.088-8.322,4.165c-.074.037-.146.079-.243.131.106.056.175.094.246.13q4.161,2.081,8.32,4.166a.479.479,0,0,0,.488-.008q4.146-2.081,8.3-4.153C-146.426-145.144-146.35-145.187-146.251-145.24Z'
               transform='translate(0 0)'
               fill='#90c1fd'
            />
            <path
               id='Path_166'
               data-name='Path 166'
               d='M-167.567,61.228a.52.52,0,0,0,.047-.091,1.131,1.131,0,0,1,1.678-.733q2.131,1.059,4.257,2.126,3.139,1.569,6.276,3.142a.505.505,0,0,0,.515,0q5.227-2.624,10.459-5.238a1.281,1.281,0,0,1,.973-.136,1.135,1.135,0,0,1,.812,1.015,1.135,1.135,0,0,1-.655,1.1q-1.864.939-3.733,1.869-3.734,1.867-7.465,3.738a1.331,1.331,0,0,1-1.291,0q-5.453-2.739-10.917-5.456a1.583,1.583,0,0,1-.957-1.02Z'
               transform='translate(0 -200.949)'
               fill='#90c1fd'
            />
            <path
               id='Path_167'
               data-name='Path 167'
               d='M-167.567,167.859a1.139,1.139,0,0,1,.993-.967,1.565,1.565,0,0,1,.785.161q5.216,2.586,10.416,5.2a.627.627,0,0,0,.638.006q5.213-2.622,10.438-5.218a1.511,1.511,0,0,1,.709-.165,1.085,1.085,0,0,1,1.018.928,1.117,1.117,0,0,1-.64,1.225q-1.946.981-3.9,1.952-3.6,1.8-7.2,3.6c-.188.094-.385.17-.578.255h-.32c-.177-.076-.359-.143-.532-.229q-5.447-2.722-10.9-5.441a1.5,1.5,0,0,1-.93-1.042Z'
               transform='translate(0 -301.88)'
               fill='#90c1fd'
            />
         </g>
      </svg>
   );
};

export const DashboardCommentsWaitingIcon = ({ className, style }) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width='28.618'
         height='24.641'
         className={className}
         viewBox='0 0 28.618 24.641'
      >
         <g id='Group_4558' data-name='Group 4558' transform='translate(0 -0.985)'>
            <path
               id='Path_84'
               data-name='Path 84'
               d='M182.408,220.9c0-1.532,0-2.905,0-4.279,0-.327-.011-.654,0-.98.033-.693.429-1.118,1-1.1a.977.977,0,0,1,.924,1.1c.011,2.335.019,4.67,0,7a.956.956,0,0,1-1.6.835c-1.041-.665-2.045-1.39-3.1-2.037a2.349,2.349,0,0,0-1.158-.343c-4.179-.024-8.358-.013-12.538-.016a3.637,3.637,0,0,1-3.918-3.932q0-4.938,0-9.876a3.609,3.609,0,0,1,3.886-3.889c1.167,0,2.335-.007,3.5,0,.7.006,1.141.383,1.149.951s-.427.971-1.129.984c-1.167.021-2.335,0-3.5.01-1.362.013-1.947.612-1.948,1.986q0,4.938,0,9.876a1.682,1.682,0,0,0,1.929,1.92c4.039,0,8.079.028,12.117-.016a4.511,4.511,0,0,1,3.063.932C181.457,220.32,181.867,220.543,182.408,220.9Z'
               transform='translate(-162.031 -198.085)'
               fill='#fed987'
            />
            <g id='Group_4555' data-name='Group 4555' transform='translate(10.291 1.135)'>
               <g id='Group_2415' data-name='Group 2415'>
                  <path
                     id='Subtraction_26'
                     data-name='Subtraction 26'
                     d='M17.478,17.542h-.022a.733.733,0,0,1-.508-.2l-3.5-3.389H2.578A2.538,2.538,0,0,1,0,11.463V2.49A2.538,2.538,0,0,1,2.578,0H15.6a2.538,2.538,0,0,1,2.578,2.49V16.847a.694.694,0,0,1-.446.638l-.009,0A.606.606,0,0,1,17.478,17.542ZM2.578,1.388a1.123,1.123,0,0,0-1.141,1.1v8.973a1.123,1.123,0,0,0,1.141,1.1H13.743a.723.723,0,0,1,.506.2l2.49,2.414V2.49a1.123,1.123,0,0,0-1.141-1.1Z'
                     fill='#fed987'
                     stroke='#fed987'
                     stroke-width='0.3'
                  />
                  <path
                     id='Path_2'
                     data-name='Path 2'
                     d='M11.212,9.309H8.018a1.05,1.05,0,0,1-1.03-1.064V5.052a1.024,1.024,0,0,1,.283-.772.956.956,0,0,1,.747-.293.956.956,0,0,1,.747.293,1.024,1.024,0,0,1,.283.772V7.181h2.163a.956.956,0,0,1,.747.293,1.024,1.024,0,0,1,.283.772A1.127,1.127,0,0,1,11.212,9.309Z'
                     transform='translate(0.06 0.722)'
                     fill='#fed987'
                  />
               </g>
            </g>
         </g>
      </svg>
   );
};

export const DashboardCommentsSpamIcon = ({ className, style }) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width='28.618'
         height='24.641'
         className={className}
         viewBox='0 0 28.618 24.641'
      >
         <g id='Group_4560' data-name='Group 4560' transform='translate(0 -0.985)'>
            <path
               id='Path_84'
               data-name='Path 84'
               d='M182.408,220.9c0-1.532,0-2.905,0-4.279,0-.327-.011-.654,0-.98.033-.693.429-1.118,1-1.1a.977.977,0,0,1,.924,1.1c.011,2.335.019,4.67,0,7a.956.956,0,0,1-1.6.835c-1.041-.665-2.045-1.39-3.1-2.037a2.349,2.349,0,0,0-1.158-.343c-4.179-.024-8.358-.013-12.538-.016a3.637,3.637,0,0,1-3.918-3.932q0-4.938,0-9.876a3.609,3.609,0,0,1,3.886-3.889c1.167,0,2.335-.007,3.5,0,.7.006,1.141.383,1.149.951s-.427.971-1.129.984c-1.167.021-2.335,0-3.5.01-1.362.013-1.947.612-1.948,1.986q0,4.938,0,9.876a1.682,1.682,0,0,0,1.929,1.92c4.039,0,8.079.028,12.117-.016a4.511,4.511,0,0,1,3.063.932C181.457,220.32,181.867,220.543,182.408,220.9Z'
               transform='translate(-162.031 -198.085)'
               fill='#c2d2e4'
            />
            <g id='Group_4555' data-name='Group 4555' transform='translate(10.291 1.135)'>
               <g id='Group_2415' data-name='Group 2415'>
                  <path
                     id='Subtraction_26'
                     data-name='Subtraction 26'
                     d='M17.478,17.542h-.022a.733.733,0,0,1-.508-.2l-3.5-3.389H2.578A2.538,2.538,0,0,1,0,11.463V2.49A2.538,2.538,0,0,1,2.578,0H15.6a2.538,2.538,0,0,1,2.578,2.49V16.847a.694.694,0,0,1-.446.638l-.009,0A.606.606,0,0,1,17.478,17.542ZM2.578,1.388a1.123,1.123,0,0,0-1.141,1.1v8.973a1.123,1.123,0,0,0,1.141,1.1H13.743a.723.723,0,0,1,.506.2l2.49,2.414V2.49a1.123,1.123,0,0,0-1.141-1.1Z'
                     fill='#c2d2e3'
                     stroke='#c2d2e3'
                     stroke-width='0.3'
                  />
                  <rect
                     id='Rectangle_1124'
                     data-name='Rectangle 1124'
                     width='6.866'
                     height='1.864'
                     rx='0.932'
                     transform='translate(6.002 9.427) rotate(-45)'
                     fill='#c2d2e3'
                  />
                  <rect
                     id='Rectangle_1125'
                     data-name='Rectangle 1125'
                     width='6.866'
                     height='1.864'
                     rx='0.932'
                     transform='translate(7.32 4.572) rotate(45)'
                     fill='#c2d2e3'
                  />
               </g>
            </g>
         </g>
      </svg>
   );
};

export const DashboardCommentsOpenIcon = ({ className, style }) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width='28.618'
         height='24.641'
         className={className}
         viewBox='0 0 28.618 24.641'
      >
         <g id='Group_4557' data-name='Group 4557' transform='translate(0 -0.985)'>
            <path
               id='Path_84'
               data-name='Path 84'
               d='M182.408,220.9c0-1.532,0-2.905,0-4.279,0-.327-.011-.654,0-.98.033-.693.429-1.118,1-1.1a.977.977,0,0,1,.924,1.1c.011,2.335.019,4.67,0,7a.956.956,0,0,1-1.6.835c-1.041-.665-2.045-1.39-3.1-2.037a2.349,2.349,0,0,0-1.158-.343c-4.179-.024-8.358-.013-12.538-.016a3.637,3.637,0,0,1-3.918-3.932q0-4.938,0-9.876a3.609,3.609,0,0,1,3.886-3.889c1.167,0,2.335-.007,3.5,0,.7.006,1.141.383,1.149.951s-.427.971-1.129.984c-1.167.021-2.335,0-3.5.01-1.362.013-1.947.612-1.948,1.986q0,4.938,0,9.876a1.682,1.682,0,0,0,1.929,1.92c4.039,0,8.079.028,12.117-.016a4.511,4.511,0,0,1,3.063.932C181.457,220.32,181.867,220.543,182.408,220.9Z'
               transform='translate(-162.031 -198.085)'
               fill='#90c1fd'
            />
            <g id='Group_4555' data-name='Group 4555' transform='translate(10.291 1.135)'>
               <g id='Group_2415' data-name='Group 2415'>
                  <path
                     id='Subtraction_26'
                     data-name='Subtraction 26'
                     d='M17.478,17.542h-.022a.733.733,0,0,1-.508-.2l-3.5-3.389H2.578A2.538,2.538,0,0,1,0,11.463V2.49A2.538,2.538,0,0,1,2.578,0H15.6a2.538,2.538,0,0,1,2.578,2.49V16.847a.694.694,0,0,1-.446.638l-.009,0A.606.606,0,0,1,17.478,17.542ZM2.578,1.388a1.123,1.123,0,0,0-1.141,1.1v8.973a1.123,1.123,0,0,0,1.141,1.1H13.743a.723.723,0,0,1,.506.2l2.49,2.414V2.49a1.123,1.123,0,0,0-1.141-1.1Z'
                     fill='#90c1fd'
                     stroke='#90c1fd'
                     stroke-width='0.3'
                  />
                  <path
                     id='comment-alt-dots'
                     d='M14.886,9.711a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,14.886,9.711Z'
                     transform='translate(-9.693 -3.44)'
                     fill='#90c1fd'
                  />
                  <path
                     id='comment-alt-dots-2'
                     data-name='comment-alt-dots'
                     d='M14.886,9.711a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,14.886,9.711Z'
                     transform='translate(-5.693 -3.44)'
                     fill='#90c1fd'
                  />
                  <path
                     id='comment-alt-dots-3'
                     data-name='comment-alt-dots'
                     d='M14.886,9.711a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,14.886,9.711Z'
                     transform='translate(-1.693 -3.44)'
                     fill='#90c1fd'
                  />
               </g>
            </g>
         </g>
      </svg>
   );
};

export const DashboardCommentsDoneIcon = ({ className, style }) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width='28.618'
         height='24.641'
         className={className}
         viewBox='0 0 28.618 24.641'
      >
         <g id='Group_4556' data-name='Group 4556' transform='translate(0 -0.985)'>
            <path
               id='Path_84'
               data-name='Path 84'
               d='M182.408,220.9c0-1.532,0-2.905,0-4.279,0-.327-.011-.654,0-.98.033-.693.429-1.118,1-1.1a.977.977,0,0,1,.924,1.1c.011,2.335.019,4.67,0,7a.956.956,0,0,1-1.6.835c-1.041-.665-2.045-1.39-3.1-2.037a2.349,2.349,0,0,0-1.158-.343c-4.179-.024-8.358-.013-12.538-.016a3.637,3.637,0,0,1-3.918-3.932q0-4.938,0-9.876a3.609,3.609,0,0,1,3.886-3.889c1.167,0,2.335-.007,3.5,0,.7.006,1.141.383,1.149.951s-.427.971-1.129.984c-1.167.021-2.335,0-3.5.01-1.362.013-1.947.612-1.948,1.986q0,4.938,0,9.876a1.682,1.682,0,0,0,1.929,1.92c4.039,0,8.079.028,12.117-.016a4.511,4.511,0,0,1,3.063.932C181.457,220.32,181.867,220.543,182.408,220.9Z'
               transform='translate(-162.031 -198.085)'
               fill='#67ceaa'
            />
            <g id='Group_4555' data-name='Group 4555' transform='translate(10.291 1.135)'>
               <g id='Group_2415' data-name='Group 2415'>
                  <path
                     id='Subtraction_26'
                     data-name='Subtraction 26'
                     d='M17.478,17.542h-.022a.733.733,0,0,1-.508-.2l-3.5-3.389H2.578A2.538,2.538,0,0,1,0,11.463V2.49A2.538,2.538,0,0,1,2.578,0H15.6a2.538,2.538,0,0,1,2.578,2.49V16.847a.694.694,0,0,1-.446.638l-.009,0A.606.606,0,0,1,17.478,17.542ZM2.578,1.388a1.123,1.123,0,0,0-1.141,1.1v8.973a1.123,1.123,0,0,0,1.141,1.1H13.743a.723.723,0,0,1,.506.2l2.49,2.414V2.49a1.123,1.123,0,0,0-1.141-1.1Z'
                     fill='#67ceaa'
                     stroke='#67ceaa'
                     stroke-width='0.3'
                  />
                  <g id='Group_650' data-name='Group 650' transform='translate(4.835 4.004)'>
                     <path
                        id='Path_3500'
                        data-name='Path 3500'
                        d='M2386,2929.108l.778-.78,2.969-2.969a.8.8,0,0,1,1.238.108.778.778,0,0,1-.1,1.032q-2.145,2.151-4.293,4.3a.829.829,0,0,1-1.177,0q-1.264-1.274-2.537-2.54a.813.813,0,0,1-.177-.922.8.8,0,0,1,1.036-.408,1,1,0,0,1,.3.2q.965.967,1.931,1.933A.412.412,0,0,1,2386,2929.108Z'
                        transform='translate(-2382.634 -2925.116)'
                        fill='#67ceaa'
                     />
                  </g>
               </g>
            </g>
         </g>
      </svg>
   );
};

export const DashboardCommentsTimeoutIcon = ({ className, style }) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width='28.618'
         height='24.641'
         className={className}
         viewBox='0 0 28.618 24.641'
      >
         <g id='Group_4559' data-name='Group 4559' transform='translate(0 -0.985)'>
            <path
               id='Path_84'
               data-name='Path 84'
               d='M182.408,220.9c0-1.532,0-2.905,0-4.279,0-.327-.011-.654,0-.98.033-.693.429-1.118,1-1.1a.977.977,0,0,1,.924,1.1c.011,2.335.019,4.67,0,7a.956.956,0,0,1-1.6.835c-1.041-.665-2.045-1.39-3.1-2.037a2.349,2.349,0,0,0-1.158-.343c-4.179-.024-8.358-.013-12.538-.016a3.637,3.637,0,0,1-3.918-3.932q0-4.938,0-9.876a3.609,3.609,0,0,1,3.886-3.889c1.167,0,2.335-.007,3.5,0,.7.006,1.141.383,1.149.951s-.427.971-1.129.984c-1.167.021-2.335,0-3.5.01-1.362.013-1.947.612-1.948,1.986q0,4.938,0,9.876a1.682,1.682,0,0,0,1.929,1.92c4.039,0,8.079.028,12.117-.016a4.511,4.511,0,0,1,3.063.932C181.457,220.32,181.867,220.543,182.408,220.9Z'
               transform='translate(-162.031 -198.085)'
               fill='#f19ba5'
            />
            <g id='Group_4555' data-name='Group 4555' transform='translate(10.291 1.135)'>
               <g id='Group_2415' data-name='Group 2415'>
                  <path
                     id='Subtraction_26'
                     data-name='Subtraction 26'
                     d='M17.478,17.542h-.022a.733.733,0,0,1-.508-.2l-3.5-3.389H2.578A2.538,2.538,0,0,1,0,11.463V2.49A2.538,2.538,0,0,1,2.578,0H15.6a2.538,2.538,0,0,1,2.578,2.49V16.847a.694.694,0,0,1-.446.638l-.009,0A.606.606,0,0,1,17.478,17.542ZM2.578,1.388a1.123,1.123,0,0,0-1.141,1.1v8.973a1.123,1.123,0,0,0,1.141,1.1H13.743a.723.723,0,0,1,.506.2l2.49,2.414V2.49a1.123,1.123,0,0,0-1.141-1.1Z'
                     fill='#f19ba5'
                     stroke='#f19ba5'
                     stroke-width='0.3'
                  />
                  <rect
                     id='Rectangle_1123'
                     data-name='Rectangle 1123'
                     width='6.592'
                     height='1.864'
                     rx='0.932'
                     transform='translate(5.792 6.907)'
                     fill='#f19ba5'
                  />
               </g>
            </g>
         </g>
      </svg>
   );
};

export const DashboardCommentMainIcon = ({ className, children }) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width='21.832'
         height='21.832'
         className={className}
         viewBox='0 0 21.832 21.832'
      >
         <g id='Group_2415' data-name='Group 2415' transform='translate(0.15 0.15)'>
            <path
               id='Subtraction_26'
               data-name='Subtraction 26'
               d='M20.7,21.532h-.026a.852.852,0,0,1-.6-.245l-4.15-4.16H3.054A3.059,3.059,0,0,1,0,14.071V3.056A3.059,3.059,0,0,1,3.054,0H18.478a3.059,3.059,0,0,1,3.054,3.056V20.68a.851.851,0,0,1-.528.783l-.011.006A.7.7,0,0,1,20.7,21.532ZM3.054,1.7A1.353,1.353,0,0,0,1.7,3.056V14.071a1.353,1.353,0,0,0,1.351,1.353H16.28a.841.841,0,0,1,.6.245l2.95,2.963V3.056A1.353,1.353,0,0,0,18.478,1.7Z'
               fill='#157cfc'
               stroke='#157cfd'
               stroke-width='0.3'
            />
            <path
               id='comment-alt-dots'
               d='M13.016,9.711a1.1,1.1,0,1,0,1.1,1.1A1.1,1.1,0,0,0,13.016,9.711Zm-4.406,0a1.1,1.1,0,1,0,1.1,1.1A1.1,1.1,0,0,0,8.61,9.711Zm8.813,0a1.1,1.1,0,1,0,1.1,1.1A1.1,1.1,0,0,0,17.422,9.711Z'
               transform='translate(-2.258 -1.928)'
               fill='#157cfc'
            />
         </g>
      </svg>
   );
};

export const DashboardChannelsMainIcon = ({ className, children }) => {
   return (
      <svg
         id='Group_2502'
         data-name='Group 2502'
         xmlns='http://www.w3.org/2000/svg'
         width='25.022'
         height='24.982'
         className={className}
         viewBox='0 0 25.022 24.982'
      >
         <path
            id='Path_165'
            data-name='Path 165'
            d='M-167.567-145.367a1.5,1.5,0,0,1,.929-1.042q5.429-2.694,10.84-5.421a1.549,1.549,0,0,1,1.531.013c3.328,1.684,6.668,3.345,10,5.014.349.174.7.35,1.046.524a1.1,1.1,0,0,1,.665,1.038,1.107,1.107,0,0,1-.669,1.037l-11.269,5.634a1.187,1.187,0,0,1-1.123,0q-5.622-2.813-11.247-5.62a1.364,1.364,0,0,1-.708-.909Zm21.316.127c-.088-.05-.155-.091-.225-.126q-4.173-2.087-8.345-4.177a.464.464,0,0,0-.469.007q-4.158,2.088-8.322,4.165c-.074.037-.146.079-.243.131.106.056.175.094.246.13q4.161,2.081,8.32,4.166a.479.479,0,0,0,.488-.008q4.146-2.081,8.3-4.153C-146.426-145.144-146.35-145.187-146.251-145.24Z'
            transform='translate(167.567 152.025)'
            fill='#147af9'
         />
         <path
            id='Path_166'
            data-name='Path 166'
            d='M-167.567,61.228a.52.52,0,0,0,.047-.091,1.131,1.131,0,0,1,1.678-.733q2.131,1.059,4.257,2.126,3.139,1.569,6.276,3.142a.505.505,0,0,0,.515,0q5.227-2.624,10.459-5.238a1.281,1.281,0,0,1,.973-.136,1.135,1.135,0,0,1,.812,1.015,1.135,1.135,0,0,1-.655,1.1q-1.864.939-3.733,1.869-3.734,1.867-7.465,3.738a1.331,1.331,0,0,1-1.291,0q-5.453-2.739-10.917-5.456a1.583,1.583,0,0,1-.957-1.02Z'
            transform='translate(167.567 -48.923)'
            fill='#147af9'
         />
         <path
            id='Path_167'
            data-name='Path 167'
            d='M-167.567,167.859a1.139,1.139,0,0,1,.993-.967,1.565,1.565,0,0,1,.785.161q5.216,2.586,10.416,5.2a.627.627,0,0,0,.638.006q5.213-2.622,10.438-5.218a1.511,1.511,0,0,1,.709-.165,1.085,1.085,0,0,1,1.018.928,1.117,1.117,0,0,1-.64,1.225q-1.946.981-3.9,1.952-3.6,1.8-7.2,3.6c-.188.094-.385.17-.578.255h-.32c-.177-.076-.359-.143-.532-.229q-5.447-2.722-10.9-5.441a1.5,1.5,0,0,1-.93-1.042Z'
            transform='translate(167.567 -149.855)'
            fill='#147af9'
         />
      </svg>
   );
};

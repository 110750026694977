import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../../../../fragments';

// mutations
export const CHANGE_CUSTOMER_PHOTO_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation changeCustomerPhotoMutation($_id: ID!, $photo: FileUpload!) {
      changeCustomerPhoto(data: { _id: $_id, photo: $photo }) {
         data {
            ...CustomerFields
         }
         success
         code
         message
      }
   }
`;

export const UPDATE_CUSTOMER_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation updateCustomerMutation(
      $_id: ID!
      $phone: String
      $companyName: String
      $taxBuilding: String
      $taxNumber: String
      $address: String
      $authorizedPersonName: String
      $authorizedPersonSurname: String
      $authorizedPersonIdentityNumber: String
      $email: String
      $country: String
      $state: String
      $postCode: String
      $city: String
      $invoiceCompanyName: String
      $invoiceCountry: String
      $invoiceState: String
      $invoicePostCode: String
      $invoiceCity: String
      $invoiceAddress: String
      $newChatEmail: Boolean
      $newChatSystem: Boolean
      $newChatBell: Boolean
      $newChatBellName: String
      $transferredChatEmail: Boolean
      $transferredChatSystem: Boolean
      $transferredChatBell: Boolean
      $transferredChatBellName: String
      $timeoutChatEmail: Boolean
      $timeoutChatSystem: Boolean
      $timeoutChatBell: Boolean
      $timeoutChatBellName: String
      $newQnAChatEmail: Boolean
      $newQnAChatSystem: Boolean
      $newQnAChatBell: Boolean
      $newQnAChatBellName: String
      $transferredQnAChatEmail: Boolean
      $transferredQnAChatSystem: Boolean
      $transferredQnAChatBell: Boolean
      $transferredQnAChatBellName: String
      $timeoutQnAChatEmail: Boolean
      $timeoutQnAChatSystem: Boolean
      $timeoutQnAChatBell: Boolean
      $timeoutQnAChatBellName: String
      $timeZone: String
      $language: String
      $currency: String
      $companySize: String
      $companyIndustry: String
      $purposeOfUsage: String
      $yourRole: String
      $favouriteProduct: String
      $howYouHearAboutUs: String
      $autoArchiveTime: Int
      $participantOutChatTime: Int
      $autoCloseByCustomerPerson: AutoCloseSettingsInput
      $autoCloseByPerson: AutoCloseSettingsInput
      $autoConversationAssignment: Boolean
      $autoConversationAssignmentTime: Int
      $autoConversationAssignmentTimeUnit: String
      $allowConnect: Boolean
      $teamGoalTime: Int
      $teamFirstResponseGoalTime: Int
   ) {
      updatePersonCustomer(
         data: {
            _id: $_id
            phone: $phone
            companyName: $companyName
            taxBuilding: $taxBuilding
            taxNumber: $taxNumber
            address: $address
            authorizedPersonName: $authorizedPersonName
            authorizedPersonSurname: $authorizedPersonSurname
            authorizedPersonIdentityNumber: $authorizedPersonIdentityNumber
            email: $email
            country: $country
            state: $state
            postCode: $postCode
            city: $city
            invoiceCompanyName: $invoiceCompanyName
            invoiceCountry: $invoiceCountry
            invoiceState: $invoiceState
            invoicePostCode: $invoicePostCode
            invoiceCity: $invoiceCity
            invoiceAddress: $invoiceAddress
            newChatEmail: $newChatEmail
            newChatSystem: $newChatSystem
            newChatBell: $newChatBell
            newChatBellName: $newChatBellName
            transferredChatEmail: $transferredChatEmail
            transferredChatSystem: $transferredChatSystem
            transferredChatBell: $transferredChatBell
            transferredChatBellName: $transferredChatBellName
            timeoutChatEmail: $timeoutChatEmail
            timeoutChatSystem: $timeoutChatSystem
            timeoutChatBell: $timeoutChatBell
            timeoutChatBellName: $timeoutChatBellName
            newQnAChatEmail: $newQnAChatEmail
            newQnAChatSystem: $newQnAChatSystem
            newQnAChatBell: $newQnAChatBell
            newQnAChatBellName: $newQnAChatBellName
            transferredQnAChatEmail: $transferredQnAChatEmail
            transferredQnAChatSystem: $transferredQnAChatSystem
            transferredQnAChatBell: $transferredQnAChatBell
            transferredQnAChatBellName: $transferredQnAChatBellName
            timeoutQnAChatEmail: $timeoutQnAChatEmail
            timeoutQnAChatSystem: $timeoutQnAChatSystem
            timeoutQnAChatBell: $timeoutQnAChatBell
            timeoutQnAChatBellName: $timeoutQnAChatBellName
            timeZone: $timeZone
            language: $language
            currency: $currency
            companySize: $companySize
            companyIndustry: $companyIndustry
            purposeOfUsage: $purposeOfUsage
            yourRole: $yourRole
            favouriteProduct: $favouriteProduct
            howYouHearAboutUs: $howYouHearAboutUs
            autoArchiveTime: $autoArchiveTime
            participantOutChatTime: $participantOutChatTime
            autoCloseByCustomerPerson: $autoCloseByCustomerPerson
            autoCloseByPerson: $autoCloseByPerson
            autoConversationAssignment: $autoConversationAssignment
            autoConversationAssignmentTime: $autoConversationAssignmentTime
            autoConversationAssignmentTimeUnit: $autoConversationAssignmentTimeUnit
            allowConnect: $allowConnect
            teamGoalTime: $teamGoalTime
            teamFirstResponseGoalTime: $teamFirstResponseGoalTime
         }
      ) {
         data {
            ...CustomerFields
         }
         success
         code
         message
      }
   }
`;
export const UPDATE_CUSTOMER_WORKING_HOUR_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation updateCustomerWorkingHourMutation(
      $workingHourId: ID!
      $name: String
      $status: Boolean
      $openingHour: Int
      $openingMinute: Int
      $closingHour: Int
      $closingMinute: Int
   ) {
      updateCustomerWorkingHour(
         data: {
            name: $name
            workingHourId: $workingHourId
            status: $status
            openingHour: $openingHour
            openingMinute: $openingMinute
            closingHour: $closingHour
            closingMinute: $closingMinute
         }
      ) {
         data {
            ...CustomerFields
         }
         success
         code
         message
      }
   }
`;

import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
   SET_QUESTION_CLICKED_ITEM,
   SET_QUESTION_CLICKED_REPLY_TYPE,
   SET_QUESTION_CLICKED_STATUS,
} from '@store/actions/questions';
import { LanguageContext } from '@helper/locale/langContext';
import { getActiveQuestion } from '@store/reducers/questions';
import { getIsOpenQuestionProfileMenu } from '@store/reducers/ui';
import { MyTheme } from '@styles/config';
import { QUESTION_ADD_NOTE_MUTATION, SEND_REPLY_QUESTION_MUTATION } from '../../../queries/Questions/Service/mutation';
import { GET_QNA_CHAT_QUERY } from '../../../queries/Questions/Service/query';
import useQnaQuestions from '../shared-hooks/useQnaQuestions';
import { QnAPersonStatusType, QnAType } from '../util/types';
import ChatInput, { SubmitInputType } from './ChatInput/chatInput';
import QuestionsMessageItem from './items/questionChatItem';
import { getPerson } from "@store/reducers/person";

const useStyles = makeStyles<MyTheme, { isDrawerOpen: boolean }>((theme) => ({
   root: {
      flexGrow: 1,
      padding: theme.spacing(1),
      transition: theme.transitions.create('margin', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -theme.chatApp.questions.drawerWidth,
   },
   contentShift: {
      transition: theme.transitions.create('margin', {
         easing: theme.transitions.easing.easeOut,
         duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
   },
   appBarSpacer: theme.mixins.toolbar,
   inputContainer: {
      width: (props) => (props.isDrawerOpen ? 'calc(100vw - 515px - 350px)' : 'calc(100vw - 510px)'),
      [theme.breakpoints.down('sm')]: {
         width: '90vw !important',
      },
      paddingLeft: 8,
   },
}));

const getMessage = (activeQuestion: any, t: any) => {
   switch (activeQuestion?.status) {
      case QnAPersonStatusType.WAITING:
         return activeQuestion?.canJoin ? t?.join_the_conversation_to_reply : t?.qna_out_button_text;
      case QnAPersonStatusType.ARCHIVE:
         return activeQuestion?.canJoin ? t?.this_chat_has_archived_join : t?.qna_out_button_text;
      case QnAPersonStatusType.SOLVED:
         return activeQuestion?.canJoin
            ? t?.this_conversation_already_has_been_resolved_join_to_continue
            : t?.qna_out_button_text;
      case QnAPersonStatusType.SPAM:
         return activeQuestion?.canJoin ? t?.this_conversation_spam_join_to_take_action : t?.qna_out_button_text;
      default:
         return '';
   }
};

const QuestionsSpace: React.FC = (props) => {
   const { _id }: any = useParams(); // get chat id
   const isDrawerOpen = useSelector(getIsOpenQuestionProfileMenu);
   const person = useSelector(getPerson);
   const activeQuestion = useSelector(getActiveQuestion);
   const clickedItemState = useSelector((state: any) => state.questionsState.contentState);
   const activeQuestionMessages = useSelector((state: any) => state.questionsState?.activeQuestionMessages?.messages);
   const dispatch = useDispatch();
   const classes = useStyles({ isDrawerOpen: isDrawerOpen });
   const history = useHistory();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   const { enqueueSnackbar } = useSnackbar();
   const { setActiveQuestionMessages, setActiveQuestion, sendReplyQuestion } = useQnaQuestions();
   const [sendReplyMutation, { loading: submitLoading }] = useMutation(SEND_REPLY_QUESTION_MUTATION);
   const [addNoteMutation, { loading: noteLoading }] = useMutation(QUESTION_ADD_NOTE_MUTATION);
   const [hasAuthority, setHasAuthority] = useState<boolean | null>(null);

   useEffect(() => {
      if (!activeQuestion?.joinedPerson) {
         setHasAuthority(null);
      } else if (activeQuestion?.joinedPerson?._id === person?._id) {
         setHasAuthority(true);
      } else {
         setHasAuthority(false);
      }
   }, [activeQuestion?.joinedPerson, person?._id]);

   let [paginateSettings, setPaginateSettings] = useState({
      hasMore: true,
      page: 1,
      limit: 20
   });

   //lazyQuery
   let [getActiveQuestionMessages, { loading: activeQuestionMessagesLoading }] = useLazyQuery(GET_QNA_CHAT_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
         if (data) {
            setActiveQuestion(data?.ActiveChatQnAs?.data?.QnAChat);
            let preActiveQuestionMessages = activeQuestionMessages;
            for (let i = 0; i < data?.ActiveChatQnAs?.data?.QnAs.length; i++) {
               let item = data?.ActiveChatQnAs?.data?.QnAs[i];
               if (!preActiveQuestionMessages.find((i: any) => i._id === item._id)) {
                  preActiveQuestionMessages.push(item);
               }
            }
            setActiveQuestionMessages(preActiveQuestionMessages);
            setPaginateSettings({
               hasMore: data?.ActiveChatQnAs?.data.totalDocs > preActiveQuestionMessages.length,
               page: preActiveQuestionMessages.length,
               limit: paginateSettings.limit
            });
         }
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 2000,
         });
         history.replace('/questions');
      },
   });

   useEffect(() => {
      setActiveQuestionMessages([]);
      getActiveQuestionMessages({
         variables: {
            _id,
            page: 0,
            limit: paginateSettings.limit,
         }
      });
   }, [_id]);

   useEffect(() => {
      if (activeQuestion?._id) {
         dispatch({
            type: SET_QUESTION_CLICKED_ITEM,
            // payload: activeQuestion.lastQnA,
            payload: null,
         });

         dispatch({
            type: SET_QUESTION_CLICKED_REPLY_TYPE,
            payload: null,
         });

         dispatch({
            type: SET_QUESTION_CLICKED_STATUS,
            payload: null,
         });
      }
   }, [activeQuestion?._id, dispatch]);

   const onSubmit = (data: SubmitInputType) => {
      switch (data.type) {
         case QnAType.NOTE:
            addNoteMutation({
               variables: {
                  question: data.text,
                  QnAChat: activeQuestion._id,
               },
            })
               .then(
                  ({
                     data: {
                        addQnANote: { data },
                     },
                  }) => {
                     sendReplyQuestion(data);
                     enqueueSnackbar(t?.general_information_added_successfully, {
                        variant: 'success',
                        autoHideDuration: 2500,
                     });
                  }
               )
               .catch((err) => {
                  enqueueSnackbar(err.message, {
                     variant: 'error',
                     autoHideDuration: 2500,
                  });
               });
            break;
         case QnAType.QNA:
            if (clickedItemState?.clickedItem?._id) {
               sendReplyMutation({
                  variables: {
                     _id: clickedItemState.clickedItem._id,
                     replyType: clickedItemState.replyType,
                     rejectReason: clickedItemState.operation === 'reject' ? data.text : null,
                     answer: clickedItemState.operation === 'reject' ? null : data.text,
                     platformType: clickedItemState?.clickedItem.platformType
                  },
               })
                  .then(
                     ({
                        data: {
                           updateQnA: { data },
                        },
                     }) => {
                        // update state
                        sendReplyQuestion(data);

                        // show success message
                        enqueueSnackbar(t?.general_information_added_successfully, {
                           variant: 'success',
                           autoHideDuration: 2500,
                        });

                        // update clicked item
                        dispatch({
                           type: SET_QUESTION_CLICKED_ITEM,
                           payload: null,
                        });
                     }
                  )
                  .catch((err) => {
                     enqueueSnackbar(err.message, {
                        variant: 'error',
                        autoHideDuration: 2500,
                     });
                  });
            }
            break;
         default:
            break;
      }
   };

   const disableMessage = getMessage(activeQuestion, t) as string;

   const loadingOrFetching = activeQuestionMessagesLoading && activeQuestionMessages.length === 0;

   if (loadingOrFetching) {
      return (
         <Grid
            container
            style={{
               height: '100vh',
            }}
            justifyContent='center'
            alignItems='center'
            className={isDrawerOpen ? classes.contentShift : undefined}
         >
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   }

   return (
      <div
         className={clsx(classes.root, {
            [classes.contentShift]: isDrawerOpen,
         })}
      >
         <div className={classes.appBarSpacer} />

         <QuestionsMessageItem hasAuthority={hasAuthority} hasMore={paginateSettings.hasMore} next={() => {
            getActiveQuestionMessages({
               variables: {
                  _id,
                  page: paginateSettings.page,
                  limit: paginateSettings.limit,
               }
            });
         }} />
         <div className={classes.inputContainer}>
            <ChatInput
               message={disableMessage}
               hasAuthority={hasAuthority}
               loading={activeQuestionMessagesLoading}
               buttonLoading={noteLoading || submitLoading}
               submit={onSubmit}
            />
         </div>
      </div>
   );
};

export default QuestionsSpace;

import { CircularProgress, Divider, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DATE_RANGES } from '../../../../types/enums';
import React, { useContext } from 'react';
import ReactSelect from 'react-select';
import { DashboardChannelsMainIcon } from '@assets/icons/DashboardIcons/dashboardIcons';
import {
   HepsiBuradaIcon,
   QuestionsFacebookIcon,
   TrendyolQuestionIcon,
   QuestionsN11Icon,
} from '@assets/icons/QuestionIcons';
import { LanguageContext } from '@helper/locale/langContext';
import MyTypography from '@components/MyTypography';
import InstagramIcon from '@components/SocialMediaIcon/instagramIcon';
import { QnAPlatformType } from '@modules/Questions/util/types';
import PercentageSection from './percentageSection';

const CIRCULAR_PROGRESS_SIZE = 36;
const useStyles = makeStyles((theme) => ({
   root: {
      padding: theme.spacing(1),
      background: '#fff',
      borderRadius: 8,
   },
   mainIcon: {
      width: 25,
      height: 25,
   },
   divider: {
      width: '100%',
      marginTop: theme.spacing(1),
   },
   headerContainer: {
      height: 40,
      marginLeft: 4,
      [theme.breakpoints.down('sm')]: {
         height: 100,
      },
   },
   titleText: {
      paddingLeft: theme.spacing(1.3),
   },
   bottomContainer: {
      position: 'relative',
      [theme.breakpoints.down('xs')]: {
         marginTop: theme.spacing(1),
      },
   },
   loading: {
      position: 'absolute',
      height: CIRCULAR_PROGRESS_SIZE * 2,
      width: CIRCULAR_PROGRESS_SIZE * 2,
      top: `calc(50% - ${CIRCULAR_PROGRESS_SIZE}px)`,
      left: `calc(50% - ${CIRCULAR_PROGRESS_SIZE}px)`,
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
   },
}));

const ITEMS = (classes, data) => [
   {
      name: QnAPlatformType.INSTAGRAM,
      code: 4,
      tooltip: 'Instagram',
      icon: <InstagramIcon className={classes.mainIcon} selected />,
      color: '#CD3B80',
      value: data?.instagram,
      percentage: data?.total === 0 ? 0 : (data?.instagram / data?.total) * 100,
   },
   {
      name: QnAPlatformType.FACEBOOK,
      code: 3,
      tooltip: 'Facebook',
      icon: <QuestionsFacebookIcon className={classes.mainIcon} selected />,
      color: '#34589D',
      value: data?.facebook,
      percentage: data?.total === 0 ? 0 : (data?.facebook / data?.total) * 100,
   },
   {
      name: QnAPlatformType.TRENDYOL,
      code: 7,
      tooltip: 'Trendyol',
      icon: <TrendyolQuestionIcon className={classes.mainIcon} selected />,
      color: '#FF7F00',
      value: data?.trendyol,
      percentage: data?.total === 0 ? 0 : (data?.trendyol / data?.total) * 100,
   },
   {
      name: QnAPlatformType.HEPSIBURADA,
      code: 8,
      tooltip: 'Hepsiburada',
      icon: <HepsiBuradaIcon className={classes.mainIcon} selected />,
      color: '#FC4D1D',
      value: data?.hepsiburada,
      percentage: data?.total === 0 ? 0 : (data?.hepsiburada / data?.total) * 100,
   },
   {
      name: QnAPlatformType.N11,
      code: 8,
      tooltip: 'N11',
      icon: <QuestionsN11Icon className={classes.mainIcon} selected />,
      color: '#ea222f',
      value: data?.n11,
      percentage: data?.total === 0 ? 0 : (data?.n11 / data?.total) * 100,
   },
];

const getSelectDates = (t) => [
   {
      label: t?.page_dashboard_typo_today,
      value: DATE_RANGES.TODAY,
   },
   {
      label: t?.page_dashboard_typo_yesterday,
      value: DATE_RANGES.YESTERDAY,
   },
   {
      label: t?.page_dashboard_typo_this_week,
      value: DATE_RANGES.THIS_WEEK,
   },
   {
      label: t?.page_dashboard_typo_this_month,
      value: DATE_RANGES.THIS_MONTH,
   },
];

const RightSection = ({ data, onChangeDate, value, loading }) => {
   const classes = useStyles({ items: ITEMS });
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const items = ITEMS(classes, data);
   const selectItems = getSelectDates(t);

   return (
      <Grid container className={classes.root}>
         <Grid
            xs={12}
            item
            alignItems={'center'}
            justifyContent={'space-between'}
            container
            className={classes.headerContainer}
         >
            <Grid alignItems={'center'} container item xs={12} md={6} lg={10}>
               <DashboardChannelsMainIcon className={classes.mainIcon} />
               <MyTypography variant={'body1'} className={classes.titleText} bold color='inherit'>
                  {t?.channels}
               </MyTypography>
            </Grid>
            <Grid item xs={12} md={6} lg={2}>
               <ReactSelect
                  styles={{
                     container: (provided) => ({
                        ...provided,
                        zIndex: 999,
                     }),
                  }}
                  options={selectItems}
                  value={value}
                  onChange={onChangeDate}
               />
            </Grid>
            <Divider className={classes.divider} />
         </Grid>

         <Grid container spacing={2} className={classes.bottomContainer}>
            {items.map((item) => (
               <Grid container alignItems='center' key={item.name} item xs={12}>
                  <Grid item container justifyContent={'center'} alignItems={'center'} xs={2} sm={1}>
                     {item.icon}
                  </Grid>
                  <Grid item xs={8} sm={10}>
                     <PercentageSection item={item} />
                  </Grid>
                  <Grid item container justifyContent={'center'} xs={2} sm={1}>
                     <MyTypography bold>%{isNaN(item.percentage) ? 0 : item.percentage.toFixed(2)} </MyTypography>
                  </Grid>
               </Grid>
            ))}
            {loading && (
               <Grid container className={classes.loading} justifyContent={'center'} alignItems={'center'}>
                  <CircularProgress size={CIRCULAR_PROGRESS_SIZE} color={'primary'} />
               </Grid>
            )}
         </Grid>
      </Grid>
   );
};

export default RightSection;

import { ButtonBase, Grid, useMediaQuery } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TOGGLE_QUESTION_DRAWER_PROFILE } from '@store/actions/ui';
import { SITE_URL } from '@helper/config';
import ButtonBasedPopup from '@components/ButtonBasedPopup';
import MediumAvatar from '@components/MediumAvatar';
import QuestionNavButtons from './buttons';

const QuestionNavBarElements: React.FC = (props) => {
   const [collapsButtons, setCollapseButtons] = useState(false);
   const isOpenQuestionProfileMenu = useSelector((state: any) => state.uiReducer.isOpenQuestionProfileMenu);
   const activeQuestion = useSelector((state: any) => state.questionsState.activeQuestion);
   // const active = useSelector((state: any) => state.uiReducer.isOpenQuestionProfileMenu);

   const [open, setOpen] = useState(false);
   const isMediumScreen = useMediaQuery('(max-width: 1450px)');
   const dispatch = useDispatch();

   useEffect(() => {
      setCollapseButtons(isMediumScreen);
   }, [isMediumScreen]);

   return (
      <Grid container wrap='nowrap' xs={12} spacing={2}>
         <ButtonBase
            onClick={() => {
               dispatch({
                  type: TOGGLE_QUESTION_DRAWER_PROFILE,
                  payload: !isOpenQuestionProfileMenu,
               });
            }}
         >
            <MediumAvatar
               onlineStatus={activeQuestion?.ownerPerson?.status}
               hideCircle
               isQna={true}
               navBarAvatar={true}
               isCustomer
               image={activeQuestion?.photo ? activeQuestion?.photo : SITE_URL + '/none.jpg'}
               title={activeQuestion?.ownerPerson?.name}
               name={activeQuestion?.ownerPerson?.name}
               username={activeQuestion?.email}
               socialMedia={activeQuestion?.socialMedia}
            />
         </ButtonBase>
         <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center'>
            {collapsButtons ? (
               <Grid container justifyContent='flex-end'>
                  <ButtonBasedPopup
                     open={open}
                     onOpen={() => setOpen(true)}
                     onClose={() => setOpen(false)}
                     button={<MoreIcon style={{ color: 'grey' }} />}
                  >
                     <QuestionNavButtons />
                  </ButtonBasedPopup>
               </Grid>
            ) : (
               <QuestionNavButtons />
            )}
         </Grid>
      </Grid>
   );
};

export default QuestionNavBarElements;

import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import EditIcon from '@material-ui/icons/EditOutlined';
import CreateModal from '@modules/Automation/FlowBots/components/createModal';
import Modal from '@components/Modal/modal';
import { useParams } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { UPDATE_ECOMMERCE_RULE, UPDATE_ECOMMERCE_RULE_STATUS } from '@queries/Automation/mutation';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Node } from 'reactflow';
import { SET_RULE_STATUS, SET_SAVE_RULE_STATE } from '@store/actions/automation';

const useStyles = makeStyles((theme: any) => ({
   container: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
   },
   title: {
      display: 'flex',
      gap: '5px',
      padding: '25px',
      fontSize: '1rem',
   },
   btn: {
      padding: '10px 16px',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
      width: '100%',
      marginTop: '5px',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 120,
      height: 36,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

interface NavBarProps {
   isDraft: boolean;
   isSecondMessage: boolean;
   setIsDraft: React.Dispatch<React.SetStateAction<boolean>>;
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   leftNodes: Node[];
}

interface State {
   input: {
      customer: string;
      _id: string | undefined;
      type: string;
      title: string | undefined;
      platforms: {
         platformType: string;
         platformId: string[];
      };
      message2: {
         sendMessage: {
            messageTemplateId: null | string;
         };
         delay: {
            delayTimeUnit: string;
            delayTime: number;
         };
         action: {
            ruleId: null | string;
            rule: null | string;
         };
      };
      message1: {
         sendMessage: {
            messageTemplateId: null | string;
         };
         delay: {
            delayTimeUnit: string;
            delayTime: number;
         };
         action: {
            ruleId: null | string;
            rule: null | string;
         };
      };
   };
}

const NavBar: React.FC<NavBarProps> = ({ isDraft, setIsDraft, leftNodes, isSecondMessage, setClickedNodeId }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const dispatch = useDispatch();

   const [bot, setBot] = useState<string | undefined>();
   const [showModal, setShowModal] = useState(false);
   const [isInValid, setIsInvalid] = useState(false);

   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);

   const botTitle = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.bot);
   const { enqueueSnackbar } = useSnackbar();
   const [title, setTitle] = useState<string>();

   let { id } = useParams();
   const ruleId = id;
   const history = useHistory();

   const [state, setState] = useState<State>({
      input: {
         customer: person.customer._id,
         _id: ruleId,
         type: 'ABANDONED_CHECKOUT',
         title: botTitle,
         platforms: {
            platformType: 'WHATS_APP_BUSINESS',
            platformId: [],
         },
         message2: {
            sendMessage: {
               messageTemplateId: null,
            },
            delay: {
               delayTimeUnit: 'HOURS',
               delayTime: 1,
            },
            action: {
               ruleId: null,
               rule: null,
            },
         },
         message1: {
            sendMessage: {
               messageTemplateId: null,
            },
            delay: {
               delayTimeUnit: 'HOURS',
               delayTime: 1,
            },
            action: {
               ruleId: null,
               rule: null,
            },
         },
      },
   });

   // useEffect for updating platforms
   useEffect(() => {
      const platform = leftNodes.find((node) => node.id.includes('3-first'))?.data?.platFormId;
      if (platform) {
         setState((prev) => ({
            ...prev,
            input: {
               ...prev.input,
               platforms: {
                  platformType: 'WHATS_APP_BUSINESS',
                  platformId: platform ? [platform] : [],
               },
            },
         }));
      }
   }, [leftNodes]);

   useEffect(() => {
      dispatch({ type: SET_SAVE_RULE_STATE, payload: state });
   }, [state, leftNodes]);

   useEffect(() => {
      const firstMessageNodes = leftNodes.filter((node) => node.id.includes('-first'));
      const secondMessageNodes = leftNodes.filter((node) => node.id.includes('-second'));

      if (firstMessageNodes.length > 0) {
         const delay1 = firstMessageNodes.find((node) => node.id === '2-first')?.data?.flowBlocks;
         const sendMessage1 = firstMessageNodes.find((node) => node.id === '3-first')?.data?.flowBlocks;
         const action1 = firstMessageNodes.find((node) => node.id === '4-first')?.data?.flowBlocks;

         if (delay1 && sendMessage1 && action1) {
            setState((prev) => ({
               ...prev,
               input: {
                  ...prev.input,

                  message1: {
                     sendMessage: {
                        messageTemplateId: sendMessage1[0]?._id,
                     },
                     delay: {
                        delayTimeUnit: delay1.delayTimeUnit,
                        delayTime: delay1.delayTime,
                     },
                     action: {
                        ruleId: action1.ruleId,
                        rule: action1.rule,
                     },
                  },
               },
            }));
         }
      }

      if (secondMessageNodes.length > 0) {
         const delay2 = secondMessageNodes.find((node) => node.id === '5-second')?.data?.flowBlocks;
         const sendMessage2 = secondMessageNodes.find((node) => node.id === '6-second')?.data?.flowBlocks;
         const action2 = secondMessageNodes.find((node) => node.id === '7-second')?.data?.flowBlocks;

         if (delay2 && sendMessage2 && action2) {
            setState((prev) => ({
               ...prev,
               input: {
                  ...prev.input,

                  message2: {
                     sendMessage: {
                        messageTemplateId: sendMessage2[0]?._id,
                     },
                     delay: {
                        delayTimeUnit: delay2.delayTimeUnit,
                        delayTime: delay2.delayTime,
                     },
                     action: {
                        ruleId: action2.ruleId,
                        rule: action2.rule,
                     },
                  },
               },
            }));
         }
      }
   }, [leftNodes]);

   useEffect(() => {
      const firstMessageNodes = leftNodes.filter((node) => node.id.includes('-first'));
      const secondMessageNodes = leftNodes.filter((node) => node.id.includes('-second'));

      if (firstMessageNodes.length > 0) {
         const delay1 = firstMessageNodes.find((node) => node.id === '2-first')?.data?.flowBlocks;
         const delay2 = secondMessageNodes.find((node) => node.id === '5-second')?.data?.flowBlocks;
         const sendMessage1 = firstMessageNodes.find((node) => node.id === '3-first')?.data?.flowBlocks;
         const action1 = firstMessageNodes.find((node) => node.id === '4-first')?.data?.flowBlocks;

         if (delay1 && sendMessage1 && action1) {
            setIsInvalid(
               (delay1?.delayTimeUnit === 'MINUTES' && (delay1?.delayTime < 15 || delay1?.delayTime > 59)) ||
                  (delay1?.delayTimeUnit === 'HOURS' && (delay1?.delayTime < 1 || delay1?.delayTime > 23)) ||
                  (delay2?.delayTimeUnit === 'HOURS' && (delay2?.delayTime < 1 || delay2?.delayTime > 23)) ||
                  (delay1?.delayTimeUnit === 'DAYS' && (delay1?.delayTime < 1 || delay1?.delayTime > 31)) ||
                  (delay2?.delayTimeUnit === 'DAYS' && (delay2?.delayTime < 1 || delay2?.delayTime > 31)) ||
                  !leftNodes.some((node) => node?.data?.platFormId) ||
                  !state.input.message1.sendMessage.messageTemplateId ||
                  (isSecondMessage && !state.input.message2.sendMessage.messageTemplateId),
            );
         }
      }
   }, [isSecondMessage, leftNodes, state]);

   useEffect(() => {
      setBot(botTitle);
   }, [botTitle]);
   const value = { title: bot };

   const [updateRule] = useMutation(UPDATE_ECOMMERCE_RULE);
   const handleRenameSubmit = async (newTitle: String) => {
      try {
         await updateRule({
            variables: {
               input: {
                  _id: ruleId,
                  title: newTitle,
                  type: 'ABANDONED_CHECKOUT',
                  customer: person.customer._id,
               },
            },
         }).then((e) => setTitle(e.data.updateECommerceRules.data.title));

         setShowModal(false);
      } catch (error) {
         console.error('Error renaming flow:', error);
      }
   };

   const handleSave = async () => {
      try {
         const updatedInput = isSecondMessage
            ? { ...state.input }
            : {
                 ...state.input,
                 message2: {
                    sendMessage: {
                       messageTemplateId: null,
                    },
                    delay: {
                       delayTimeUnit: 'HOURS',
                       delayTime: 1,
                    },
                    action: {
                       ruleId: null,
                       rule: null,
                    },
                 },
              };

         setState((prev) => ({
            ...prev,
            input: updatedInput,
         }));

         await updateRule({
            variables: { input: updatedInput },
            onCompleted: async () => {
               handleToggleStatus(true);
            },
         });

         setShowModal(false);
      } catch (error) {
         console.error('Error saving rule:', error);
         enqueueSnackbar((error as { message: string }).message, {
            variant: 'error',
            autoHideDuration: 2000,
         });
      }
   };

   const [updateStatus] = useMutation(UPDATE_ECOMMERCE_RULE_STATUS);

   const handleToggleStatus = async (currentStatus: Boolean) => {
      try {
         await updateStatus({
            variables: {
               input: {
                  _id: ruleId,
                  status: currentStatus,
                  customer: person.customer._id,
               },
            },
            onCompleted: async () => {
               setClickedNodeId('');
               await history.push('/automation/e-commerce/');
            },
         });
      } catch (error) {
         console.error('Error updating status:', error);

         enqueueSnackbar(t.automation_status_error, {
            variant: 'error',
            autoHideDuration: 2000,
         });
      }
   };

   const handleShowModal = () => {
      setShowModal(true);
   };
   const closeModal = () => {
      setShowModal(false);
   };
   const handleDraft = () => {
      dispatch({ type: SET_RULE_STATUS, payload: '' });
      handleToggleStatus(false);
   };
   const handlePublish = () => {
      dispatch({ type: SET_RULE_STATUS, payload: '' });
      handleSave();
   };

   const handleToggle = async (currentStatus: Boolean) => {
      try {
         await updateStatus({
            variables: {
               input: {
                  _id: ruleId,
                  status: currentStatus,
                  customer: person.customer._id,
               },
            },
            onCompleted: async () => {
               setIsDraft(true);
               dispatch({ type: SET_RULE_STATUS, payload: 'draft' });
            },
         });
      } catch (error) {
         console.error('Error updating status:', error);
         enqueueSnackbar((error as { message: string }).message, {
            variant: 'error',
            autoHideDuration: 2000,
         });
      }
   };

   return (
      <Grid>
         <Grid className={classes.container}>
            <div className={classes.title}>
               <div>{title || bot}</div>
               <EditIcon style={{ fontSize: '20px', cursor: 'pointer' }} onClick={handleShowModal} />
            </div>

            <div>
               {isDraft ? (
                  <div className={classes.btn}>
                     <Button
                        className={classes.modalButtons}
                        style={{
                           color: '#212121',
                           backgroundColor: '#eeee',
                        }}
                        onClick={handleDraft}
                     >
                        {t.cancel}
                     </Button>
                     <ButtonOK
                        className={classes.modalButtons}
                        label={t.save}
                        disabled={isInValid}
                        type='submit'
                        style={{
                           color: 'white',
                           backgroundColor: '#157CFC',
                           float: 'right',
                           opacity: isInValid ? 0.4 : '',
                        }}
                        onClick={handlePublish}
                     />
                  </div>
               ) : (
                  <div className={classes.btn}>
                     <ButtonOK
                        className={classes.modalButtons}
                        label={t.page_settings_button_edit}
                        type='submit'
                        style={{
                           color: 'white',
                           backgroundColor: '#157CFC',
                           float: 'right',
                        }}
                        onClick={() => handleToggle(false)}
                     />
                  </div>
               )}
            </div>
         </Grid>
         <Modal open={showModal} onClose={closeModal} modalHeader={t.automation_flow_update_flow}>
            <CreateModal value={value} onSubmit={handleRenameSubmit} closeModal={closeModal} isLoading={false} />
         </Modal>
      </Grid>
   );
};

export default NavBar;

import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import { GET_GROUPS_LIST } from '@queries/PersonGroups/Service/GraphQL/query';
import { UPDATED_PERSON_GROUP_SUBSCRIPTION } from '@queries/PersonGroups/Service/GraphQL/subscription';
import { GET_PLATFORMS_PAGINATED_QUERY } from '@queries/Settings/Service/GraphQL/Platform/query';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Toolbar from './components/toolbar';
import EditPersonGroup from './modals/editPersonGroup';
import { SenderType, generateFields } from './types';


const useStyles = makeStyles((theme: any) => ({
    contentShow: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'hidden',
        paddingTop: '20vh',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '50%',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            minHeight: '-webkit-fill-available',
            height: '100%',
        },
    },
    title: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
    },
    desc: {
        width: '50%',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        fontSize: '1.1rem',
    },
    button: {
        paddingInline: 60,
        fontSize: 20,
    },
    content: {
        height: '100vh',
        overflowX: 'hidden',
        overflowY: 'auto',
        flexGrow: 1,
        padding: 25,
        paddingTop: '8vh',
    },
    paper: {
        borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
        width: '100%',
        borderRadius: 15,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    '@global': {
        '::-webkit-scrollbar': {
            width: 6,
            transition: 'all .5s',
        },
        '::-webkit-scrollbar-track': {
            background: theme.chatApp.general.pallet.passiveStructureGrey,
        },
        '::-webkit-scrollbar-thumb': {
            background: '#C9CCD5',
        },
        '::-webkit-scrollbar-thumb:hover': {
            background: '#9399ab',
        },
    },
    listIcon: {
        width: 30,
        height: 30,
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: 3,
    },
    rowStyle: {
        color: '#385273',
        fontWeight: 'bold',
        fontSize: '1rem',
    },
    Table: {
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    },
}));

const BulkMessaging = () => {
    const classes = useStyles();
    const person = useSelector((reducer: any) => reducer.personReducer.person);
    const token = useSelector((reducer: any) => reducer.personReducer.token);
    const { lang } = useContext(LanguageContext);
    const history = useHistory();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const t = lang?.translation;

    useEffect(() => {
        if (!person.customer.isWholeSaler) {
            enqueueSnackbar(t['access_denied_to_this_module'], {
                // send error message
                variant: 'error',
                persist: true,
                action: (key) => (
                    <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                        {t['modal_close']}
                    </div>
                ),
            });

            history.push('/');
        }
    }, [person, history, enqueueSnackbar, closeSnackbar, t]);

    const [searchValue, setSearchValue] = React.useState('');
    const [editDialogInfo, setEditDialogInfo] = React.useState<null | string>(null);

    const [pageData, setPageData] = React.useState<{
        totalDocs: number,
        data: {
            id: string;
            platformId: string;
            field_1?: string;
            field_2?: string;
            field_3?: string;
        }[]
    }>({
        totalDocs: 0,
        data: [],
    });

    const [filter, setFilter] = React.useState({
        senderType: SenderType.PersonGroups,
        search: '',
        pageSize: 20,
        page: 1,
    });

    useEffect(() => {
        const time = setTimeout(() => {
            setFilter((prev) => ({ ...prev, search: searchValue }));
        }, 500);

        return () => clearTimeout(time);
    }, [searchValue]);


    const [getGroupsList, { loading: loadingGetGroupsList }] = useLazyQuery(GET_GROUPS_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ getGroupList: data }) => {
            setPageData({
                ...(data.data.totalDocs && { totalDocs: data.data.docsCount }),
                data: data.data.docs.map((personGroup: any) => ({
                    id: personGroup._id,
                    platformId: personGroup.senderPlatform?._id || null,
                    field_1: personGroup.senderPlatform ? `${personGroup.senderPlatform.name} (${personGroup.senderPlatform.number})` : '',
                    field_2: personGroup.name,
                    field_3: '',
                }))
            });
        },
    });

    const [getPlatformList, { loading: platformListLoading }] = useLazyQuery(GET_PLATFORMS_PAGINATED_QUERY, {
        onCompleted: ({ getPlatformsPaginated: data }) => {
            setPageData({
                ...(data.data.totalRecords && { totalDocs: data.data.totalRecords }),
                data: data.data.data.map((platform: any) => {
                    if (platform.groups) {
                        return platform.groups.map((group: any) => ({
                            id: group.chat,
                            field_1: `${platform?.name} (${platform?.number})`,
                            field_2: group?.chatName,
                        }))
                    } else if (platform.channels) {
                        return platform.channels.map((channel: any) => ({
                            id: channel.chat,
                            field_1: platform?.firstName,
                            field_2: channel?.chatName,
                        }))
                    }
                }).flat()
            });
        },
        fetchPolicy: 'no-cache',
    });

    const { data: platformList, loading: whatsAppListLoading } = useQuery(GET_PLATFORMS_PAGINATED_QUERY, {
        fetchPolicy: 'no-cache',
        variables: {
            params: {
                customer: person.customer._id,
                platformType: "WHATS_APP_WEB",
                page: 1,
                pageSize: 100,
            }
        }
    });


    useSubscription(UPDATED_PERSON_GROUP_SUBSCRIPTION, {
        variables: { token, customer: person.customer._id },
        onSubscriptionData: async ({
            client,
            subscriptionData: {
                data: {
                    updatedGroup: { data: updatedGroup },
                },
            },
        }) => {
            let ifUpdateRequired = pageData.data.find(x => x.id == updatedGroup.groupId);
            if (ifUpdateRequired) {
                if (updatedGroup.operationType === 'update') {
                    ifUpdateRequired.field_1 = updatedGroup.senderPlatform ? `${updatedGroup.senderPlatform.name} (${updatedGroup.senderPlatform.number})` : '';
                    ifUpdateRequired.platformId = updatedGroup.senderPlatform ? updatedGroup.senderPlatform._id : null;
                    setPageData({
                        ...pageData,
                        data: pageData.data.map(x => (x.id === ifUpdateRequired?.id ? ifUpdateRequired : x)),
                    })
                } else if (updatedGroup.operationType === 'remove') {
                    setPageData({
                        totalDocs: pageData.totalDocs - 1,
                        data: pageData.data.filter(x => x.id !== updatedGroup.groupId),
                    });
                }
            }
        }
    });
    const loading = loadingGetGroupsList || platformListLoading || whatsAppListLoading;

    useEffect(() => {
        setPageData({
            totalDocs: 0,
            data: [],
        });
        if (filter?.senderType == SenderType.PersonGroups) {
            getGroupsList({
                variables: {
                    customer: person.customer._id,
                    search: filter?.search,
                    page: filter?.page,
                    pageSize: filter?.pageSize,
                },
            });
        } else {
            getPlatformList({
                variables: {
                    params: {
                        customer: person.customer._id,
                        platformType: filter?.senderType == SenderType.TelegramChats ? "TELEGRAM" : "WHATS_APP_WEB",
                        search: filter?.search,
                        page: filter?.page,
                        pageSize: 100,
                    }
                },
            });
        }
    }, [filter]);

    return (
        <main className={classes.content}>
            <Grid
                container
                wrap='nowrap'
                style={{
                    height: '100%',
                }}
            >
                <Paper elevation={1} className={classes.paper}>
                    <DataGrid
                        loading={loading}
                        components={{
                            Toolbar: (props) => {
                                return <Toolbar onSenderChanged={(senderType) => {
                                    setFilter((prev) => ({ ...prev, senderType }));
                                }} onNewSharing={() => {
                                    window.location.href = '/bulk-messaging/send';
                                }} onSearchChange={setSearchValue} searchValue={searchValue} activeSendGroup={filter?.senderType} />;
                            },
                        }}
                        className={classes.Table}
                        disableSelectionOnClick
                        localeText={{
                            toolbarColumns: '',
                            noRowsLabel: t?.no_data_to_show,
                        }}
                        rowHeight={64}
                        disableColumnMenu
                        disableColumnSelector
                        hideFooterPagination={filter.senderType != SenderType.PersonGroups}
                        classes={{ columnHeader: classes.rowStyle }}
                        pageSize={filter.pageSize}
                        pagination={filter.senderType === SenderType.PersonGroups ? true : undefined}
                        paginationMode='server'
                        page={filter.page - 1}
                        onPageChange={(params) => {
                            setFilter((prev) => ({ ...prev, page: params + 1 }));
                        }}
                        rows={pageData?.data}
                        rowCount={pageData.totalDocs}
                        columns={generateFields(t, filter.senderType, (groupId: string) => {
                            setEditDialogInfo(groupId);
                        })}
                    />
                </Paper>
            </Grid>
            <EditPersonGroup platformName='WhatsAppPhones' platformsData={platformList?.getPlatformsPaginated?.data.data || []} isOpen={!!editDialogInfo} personGroup={pageData?.data.find(x => x.id == editDialogInfo) || null} onClose={() => {
                setEditDialogInfo(null);
            }} />
        </main>
    );

}
export default BulkMessaging;
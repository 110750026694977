export const PROJECT_VERSION = '7f107c1e2cfb575bab3793f83a43e717'; // merhaba_bu_versiyon_1
export const TIMEZONE = 'Europe/Istanbul'; // USER TIMEZONE "Europe/Istanbul"
// export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone; // USER TIMEZONE "Europe/Istanbul"
export const responsiveMeasure = {
   mobile: {
      width: 950,
      height: 'auto',
   },
   tablet: {
      width: 1310,
      height: 'auto',
   },
   desktop: {
      width: 1920,
      height: 'auto',
   },
};

/* local */
// export const API_URL = 'http://localhost:3005';
// export const SITE_URL = 'https://localhost:3000';
// export const SHOPIFY_APP_URL = 'https://apps.shopify.com/partners/qpien';

/* Production qpien.com */
export const API_URL = 'https://api.qpien.com';
export const SITE_URL = 'https://app.qpien.com';
export const SHOPIFY_APP_URL = 'https://apps.shopify.com/partners/qpien';

/* Gamma before Production */
// export const API_URL = 'https://apigamma.qpien.com';
// export const SITE_URL = 'https://appgamma.qpien.com';
// export const SHOPIFY_APP_URL = 'https://apps.shopify.com/partners/qpien';

// dev
// export const API_URL = 'https://apidev.qpien.com';
// export const SITE_URL = 'https://appdev.qpien.com';
// export const SHOPIFY_APP_URL = 'https://partners.shopify.com/2347985/apps/6516901';

// alfa
// export const API_URL = 'https://apialfa.qpien.com';
// export const SITE_URL = 'https://alfadev.qpien.com';
// export const SHOPIFY_APP_URL = 'https://partners.shopify.com/2347985/apps/6516901';

// beta
// export const API_URL = 'https://apibeta.qpien.com';
// export const SITE_URL = 'https://betadev.qpien.com';
// export const SHOPIFY_APP_URL = 'https://partners.shopify.com/2347985/apps/6516901';

import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';

const useStyles = makeStyles(() => ({
   container: {
      marginTop: 10,
      width: '100%',
      height: '60px',
      border: '3px dashed #D1D1D1',
      textAlign: 'center',
      padding: 4,
      paddingLeft: 10,
      paddingRight: 10,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
   },
}));

const MediaUpload = ({ handleUploadFiles, uploadDetails, disabled }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const kbValueNumberFormatter = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow',
   });

   return (
      <div
         className={classes.container}
         style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
         onClick={disabled ? null : () => document.getElementById('selectFile').click()}
      >
         <b style={{ fontSize: 15, margin: 0, color: '#393939' }}>
            {uploadDetails ? uploadDetails.name : t?.campaign_replace_media}
         </b>
         <p style={{ fontSize: 13, margin: 0, marginTop: 2, color: '#7e7e7e' }}>
            {uploadDetails ? kbValueNumberFormatter.format(uploadDetails.size) : t?.campaign_replace_media_desc}
         </p>
         <input
            id='selectFile'
            hidden
            onChange={(e) => {
               handleUploadFiles(e.target.files);
               e.target.value = '';
            }}
            type='file'
            accept={'.png,.jpg,.jpeg,.pdf,.mp4'}
         />
      </div>
   );
};

export default WithUrlTranslations(MediaUpload);

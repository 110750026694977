import { Box, Button, Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { LanguageContext } from '@helper/locale/langContext';
// import useLocalStorage from '../../../../hooks/useLocalStorage';
import Input from '@components/InputField/index';
import { Typography } from '@material-ui/core';
import SingleInput from '@components/_Modal/SingleInput';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const TrendyolForm = ({ onAccept, onBack, mode, onAcceptEditData, data: selectedItem }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const {
      control,
      handleSubmit,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {},
   });

   const onSubmit = (data) => {
      if (mode) {
         onAcceptEditData({
            ...data,
            _id: selectedItem._id,
         });
      } else {
         onAccept(data);
      }
   };

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <Grid container>
            <SingleInput
               control={control}
               name='emailOrPhone'
               label={t?.email_or_phone + '*'}
               placeholder={t?.example + 'john@gmail.com'}
               helperText={t?.connection_modal_faprika_email}
               rules={{
                  required: t?.please_fill_this_field,
                  minLength: {
                     value: 5,
                     message: t?.max_5_characters,
                  },
                  validate: (v) => {
                     return !!v.trim();
                  },
               }}
            />
            <SingleInput
               control={control}
               name='password'
               label={t?.password + '*'}
               placeholder={'*************'}
               type='password'
               helperText={t?.connection_modal_faprika_password}
               rules={{
                  required: t?.please_fill_this_field,
                  minLength: {
                     value: 5,
                     message: t?.max_5_characters,
                  },
                  validate: (v) => {
                     return !!v.trim();
                  },
               }}
            />
            <Typography color='secondary'>{t?.connection_modal_faprika_warning}</Typography>

            <Box my={2} width='100%'>
               <Grid container justifyContent={mode ? 'flex-end' : 'space-between'}>
                  {!mode && (
                     <Button
                        className={classes.modalButtons}
                        style={{
                           color: '#212121',
                           backgroundColor: '#eeee',
                           '&:hover': {
                              backgroundColor: '#e0e0e0',
                           },
                        }}
                        onClick={onBack}
                     >
                        {t?.back}
                     </Button>
                  )}
                  <Button
                     className={classes.modalButtons}
                     type='submit'
                     disabled={!isValid}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        '&:hover': {
                           backgroundColor: '#105CBB',
                        },
                        float: 'right',
                     }}
                  >
                     {mode ? t?.save : t?.connect}
                  </Button>
               </Grid>
            </Box>
         </Grid>
      </form>
   );
};

export default TrendyolForm;

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import SideBarItems from './sideBarItems';
import { MainLogo } from '@assets/icons/SideBarIcons/sideBarIcons';
import { useHistory } from 'react-router-dom';
const drawerWidth = 63;
const useStyles = makeStyles((theme) => ({
   drawerPaper: {
      position: 'relative',
      border: 'none',
      whiteSpace: 'nowrap',
      // height:'100vh',
      width: drawerWidth,
      backgroundColor: theme.chatApp.workspace.sideBar.backgroundColor,
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
      zIndex: 999,
   },
   drawerWidthClose: {
      transition: theme.transitions.create('width', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
   },
   iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.1),
   },
   icon: {
      marginLeft: '5px',
      color: theme.chatApp.workspace.sideBar.iconColor,
   },
}));

const VerticalSideBar = () => {
   const history = useHistory();
   const classes = useStyles();
   const handleClickLogo = () => {
      history.push('/');
   };
   return (
      <Drawer variant='persistent' classes={{ paper: clsx(classes.drawerPaper) }} open={true}>
         <div className={classes.iconContainer}>
            <IconButton onClick={handleClickLogo}>
               <MainLogo style={{ width: 45, height: 45 }} />
            </IconButton>
         </div>
         <SideBarItems mode='VERTICAL' />
      </Drawer>
   );
};

export default React.memo(VerticalSideBar);

import { responsiveMeasure } from '@helper/config';
import ChatSpace from '@modules/Chat/MainPanel/ChatSpace/chatSpace';
import { Grid } from '@material-ui/core';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { SEND_MESSAGE_MUTATION } from '@queries/Chat/Service/GraphQL/Message/mutation';
import { useSnackbar } from 'notistack';
import { v4 as uuidv4 } from 'uuid';
import { SEND_MESSAGE } from '@store/actions/chat';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveChat } from '@store/reducers/chat';
import { getPerson } from '@store/reducers/person';
import ChatInput, { MAX_FILE_COUNT } from '@modules/Chat/MainPanel/ChatInput/chatInput';
import { LanguageContext } from '@helper/locale/langContext';
import { PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/Platform/types';

const getActiveChatMessageFromStatus = (activeChat: any, t: any) => {
   switch (activeChat.status) {
      case 0:
         return t?.join_the_chat_to_reply;
      case 1:
         if (activeChat?.isJoinable) return t?.chat_archive_button_text;
         return t?.chat_out_button_text;
      case 2:
         if (activeChat?.isJoinable) return t?.this_chat_already_has_been_resolved_join_to_continue;
         else return t?.chat_out_button_text;
      case 3:
         if (activeChat?.isJoinable) return t?.this_chat_spam_join_to_take_action;
         return t?.chat_out_button_text;
      case 4:
         if (activeChat?.isJoinable) return t?.this_chat_disabled_join_to_take_action;
         return t?.chat_out_button_text;
      default:
         return '';
   }
};

type PropTypes = {
   profileStatus: any;
   width: any;
   isEmail: boolean;
};

const ChatMainPanel = ({ profileStatus, width, isEmail }: PropTypes) => {
   const chatItemsRef = useRef(null);
   const me = useSelector(getPerson);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const activeChat = useSelector(getActiveChat);
   const dispatch = useDispatch();
   const [sendMessageMutation, { loading: sendMessageLoading }] = useMutation(SEND_MESSAGE_MUTATION);
   const [fileIsDropping, setFileIsDropping] = useState(false);
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   const disableMessage = getActiveChatMessageFromStatus(activeChat, t);
   const [chatInputHeight, setChatInputHeight] = useState(0);

   const sendMessageSubmit = useCallback(
      async (formData) => {
         if (formData.text.length > 0 || formData.media.length > 0) {
            /* run send update mutation */

            // only for cache
            let media = [];
            if (formData.media.length > 0) {
               const files = formData.media;

               for (const file of files) {
                  if (file?.path) {
                     media.push(file);
                  } else {
                     media.push({
                        path: URL.createObjectURL(file),
                        type: file.type,
                     });
                  }
               }
            }

            const messageStatusId = uuidv4();

            dispatch({
               type: SEND_MESSAGE,
               payload: {
                  sendingStatusId: messageStatusId,
                  isSending: true,
                  sender: me,
                  type: 0,
                  content: formData.text,
                  contentHtml: formData.text,
                  media: media || [],
                  personStatus: true,
                  isMine: true,
                  createdAt: new Date(),
                  readStatus: false,
                  ack: 0,
                  platformMessageId: '',
                  subject:formData.subject,
               },
            });

            // run mutation
            await sendMessageMutation({
               variables: {
                  sender: me._id,
                  chat: activeChat?._id,
                  type: 0,
                  content: formData.text,
                  media: formData.media.slice(0, MAX_FILE_COUNT) || [],
                  personStatus: true,
                  sendingStatusId: messageStatusId,
                  subject:formData.subject,
                  emailData:formData.emailData,
                  contentHtml: formData.text,
               },
            });
         }
      },
      [sendMessageMutation, activeChat?._id, me, dispatch],
   );

   const sendSurveyMessageSubmit = useCallback(
      async (formData) => {
         const messageStatusId = uuidv4();

         if (activeChat.type !== PLATFORM_TYPE.LIVE_CHAT) {
            dispatch({
               type: SEND_MESSAGE,
               payload: {
                  sendingStatusId: messageStatusId,
                  isSending: true,
                  sender: me,
                  type: 8,
                  content: formData.text,
                  media: [],
                  personStatus: true,
                  isMine: true,
                  createdAt: new Date(),
                  readStatus: false,
                  ack: 0,
                  platformMessageId: '',
               },
            });
         }

         // run mutation
         await sendMessageMutation({
            variables: {
               sender: me._id,
               chat: activeChat?._id,
               type: 8,
               content: formData.text,
               media: [],
               personStatus: true,
               sendingStatusId: messageStatusId,
               surveyData: {
                  surveyId: formData.surveyData.surveyId,
                  title: formData.surveyData.title,
                  message: formData.surveyData.message,
                  thanksMessage: formData.surveyData.thanksMessage,
                  submitButtonText: formData.surveyData.submitButtonText,
                  themeMainColor: formData.surveyData.themeMainColor,
                  commentInputTitle: formData.surveyData.commentInputTitle,
               },
            },
         }).catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {t['general_message_button_close']}
                  </div>
               ),
            });
         });
      },
      [sendMessageMutation, activeChat?._id, me, dispatch],
   );

   // send private mutation
   const sendPrivateMessageSubmit = useCallback(
      async (formData) => {
         if (formData?.text?.length > 0) {
            /* run send update mutation */
            const messageStatusId = uuidv4();
            dispatch({
               type: SEND_MESSAGE,
               payload: {
                  sender: me,
                  chat: activeChat?._id,
                  type: 4,
                  content: formData.text,
                  media: [],
                  personStatus: true,
                  isSending: true,
                  sendingStatusId: messageStatusId,
                  isMine: true,
                  createdAt: new Date(),
                  readStatus: false,
                  platformMessageId: '',
               },
            });

            await sendMessageMutation({
               variables: {
                  sender: me._id,
                  chat: activeChat?._id,
                  type: 4,
                  content: formData.text,
                  media: [],
                  personStatus: true,
                  sendingStatusId: messageStatusId,
               },
            });
         }
      },
      [sendMessageMutation, activeChat?._id, me, dispatch],
   );

   const onDragEnd = useCallback((e) => {
      e.preventDefault();
      setFileIsDropping(false);
   }, []);

   const onDragOver = useCallback((e) => {
      e.preventDefault();
      setFileIsDropping(true);
   }, []);

   return (
      <Grid
         ref={chatItemsRef}
         item
         style={{
            display: profileStatus && width < responsiveMeasure.tablet.width ? 'none' : 'block',
            width:
               width > responsiveMeasure.mobile.width
                  ? +width -
                    (66 + // drawer
                       400 + // left panel
                       15 + // middle panel left margin
                       10 + // middle panel right margin
                       (profileStatus && 400)) // right panel
                  : profileStatus
                  ? '0%'
                  : '100%',
            backgroundColor: 'white',
            marginLeft: 15,
            marginRight: 10,
            padding: 0,
            textAlign: 'center',
            paddingBottom: 0,
         }}
      >
         <div onDragOver={onDragOver} onDragEnd={onDragEnd} onDrop={onDragEnd}>
            <ChatSpace chatInputHeight={chatInputHeight} isEmail={isEmail} />
         </div>
         {activeChat && (
            <ChatInput
               activeChat={activeChat}
               fileIsDropping={fileIsDropping}
               submitSurvey={sendSurveyMessageSubmit}
               submit={sendMessageSubmit}
               loading={sendMessageLoading}
               submitPrivateMessage={sendPrivateMessageSubmit}
               message={disableMessage}
               onFileDropped={() => setFileIsDropping(false)}
               width={width}
               mobileMode={width < responsiveMeasure.mobile}
               setChatInputHeight={setChatInputHeight}
               isEmail={isEmail}
            />
         )}
      </Grid>
   );
};

export default ChatMainPanel;

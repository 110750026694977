// TODO: Switch to enum after implementing typescript
export const E_COMMERCE_PLATFORM_TYPE = {
   SHOPIFY: 'SHOPIFY',
   WOOCOMMERCE: 'WOOCOMMERCE',
   FAPRIKA: 'FAPRIKA',
   TSOFT: 'TSOFT',
   TICIMAX: 'TICIMAX',
   IDEASOFT: 'IDEASOFT',
   IKAS: 'IKAS',
};

export const USERS_TYPE = {};

import React, { useContext } from 'react';
import { ButtonBase, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../../helper/locale/langContext';
import Button from '@material-ui/core/Button';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { getPerson } from '@store/reducers/person';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { EXPORT_STATISTICS_QUERY } from '@queries/Statistics/Service/GraphQL/query';
import { useSnackbar } from 'notistack';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText, ModalMediumText } from '@components/_Modal/ModalTexts';

const useStyles = makeStyles(() => ({
    paper: {
        maxWidth: 370,
        borderRadius: 15,
        display: 'flex',
        flexDirection: 'column',
    },
    titleContainer: {
        padding: '15px',
    },
    title: {
        textAlign: 'center',
        fontSize: '1.3rem',
        color: '#385273',
        fontWeight: 'bold',
    },
    contentContainer: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px',
    },
    subTitle: {
        fontSize: '1.2rem',
        color: '#1E2944',
        textAlign: 'center',
        marginBottom: '50px',
    },
    description: {
        fontSize: '1.1rem',
        color: '#10223A',
        textAlign: 'center',
        marginBottom: '20px',
    },
    footerContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
    },
    button: {
        height: 40,
        width: 140,
        fontSize: '1.1rem',
    },
    modalButtons: {
        fontSize: '18px',
        textTransform: 'none',
        paddingBlock: 5,
        paddingInline: 15,
        minWidth: 140,
        height: 44,
        borderRadius: 6,
        transition: 'all .5s',
        '&:disabled': {
            opacity: '0.7'
         }
    },
}));

const ExportModalContent = ({ onClose, onAccept, operation, params }: any) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;
    const me = useSelector(getPerson);
    const { enqueueSnackbar } = useSnackbar();

    const [exportToExcel,] = useLazyQuery(EXPORT_STATISTICS_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            enqueueSnackbar(t?.export_statistics_excel, {
                variant: 'success',
                autoHideDuration: 3000,
            });

            onClose()
        },
    });

    return (
        <Grid >
            <ModalLargeText style={{ textAlign: 'center' }}>
                {t?.exportData_title}
            </ModalLargeText>
            <ModalMediumText style={{ textAlign: 'center' }}>
                {t?.pre_load_input_label_email}:<span style={{ color: 'blue' }}>{me.email}</span>
            </ModalMediumText>
            <ModalButtonField>
                <Button
                    style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                    }}
                    onClick={onClose}
                    className={classes.modalButtons}>
                    {t?.comp_button_cancel}
                </Button>
                <ButtonOK
                    className={classes.modalButtons}
                    style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        float: 'right'
                    }}
                    label={t?.page_chat_typo_send}
                    onClick={() => {

                        exportToExcel({
                            variables: {
                                customer: me.customer._id,
                                email: me.email,
                                operation: operation,
                                params: params
                            }
                        })

                    }}
                />
            </ModalButtonField>
        </Grid >
    );
};

export default ExportModalContent;

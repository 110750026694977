import { useMutation } from '@apollo/client';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import Modal from '@components/Modal/modal';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import { LanguageContext } from '@helper/locale/langContext';
import { CircularProgress, IconButton, Tooltip, Typography } from '@material-ui/core';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForwardIosRounded } from '@material-ui/icons';
import { CopyToClipboardIcon } from '@modules/Settings/SettingSpace/LiveChat/SharedComponents/Icons';
import { CREATE_ECOMMERCE_ORDER } from '@queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import { getPerson } from '@store/reducers/person';
import { MyTheme } from '@styles/config';
import { useSnackbar } from 'notistack';
import React, { FC, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import ButtonOK from '../../../../../../../../../components/ButtonOK/buttonOk';
import { BillProps } from '../../../Store/types';
import { CollectPaymentModalType, CreditCard, NotificationChannels } from '../../types';
import MarkWithStatus from './MarkWithStatus';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';

const useStyles = makeStyles((theme: MyTheme) => ({
   buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 8,
      gap: 8,
   },
   footerButtonContainer: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'stretch',
   },
   arrowDownIcon: {
      transform: 'rotate(90deg)',
   },
   copyToClipboardButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '20px',
      color: '#157cfc',
      padding: '10px',
      borderRadius: 0,
      // borderRadius: '4px',
      backgroundColor: '#F7F7F7',
   },
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: '#000',
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalSubtitle: {
      color: '#000',
      fontSize: '1rem',
      textAlign: 'center',
   },
   modalDescription: {
      color: '#000',
      fontSize: '0.9rem',
      fontWeight: 'normal',
      textAlign: 'center',
   },
   childrenContainer: {
      padding: 15,
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 25,
      borderTop: '1px solid #F1F3F7',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

interface FooterButtonsProps {
   selectedCustomerId: string;
   products: any;
   closeCustomerCart: () => void;
   bill: BillProps;
   orderNote: string;
   shipping: {
      shippingPrice: string;
      shippingTitle: string;
      shippingId: string;
   };
   sendInvoiceActive: boolean;
   paymentType?: any;
   alreadyCreatedOrderId?: string;
}

const FooterButtons: FC<FooterButtonsProps> = ({
   selectedCustomerId,
   products,
   closeCustomerCart,
   orderNote,
   shipping,
   bill,
   sendInvoiceActive,
   paymentType,
   alreadyCreatedOrderId,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const person = useSelector(getPerson);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

   const [copyStatus, setCopyStatus] = useState<string>(
      t['page_settings_typo_platforms_live_chat_installation_section_live_chat_code_copy_to_clipboard'] || '',
   );
   const [anchorElCollectPayment, setAnchorElCollectPayment] = useState<null | undefined | HTMLElement>(null);
   const [anchorElShareCart, setAnchorElShareCart] = useState<null | HTMLElement>(null);
   const [currentCollectPaymentModal, setCurrentCollectPaymentModal] = useState<CollectPaymentModalType | undefined>();

   const eCommerceType = useSelector((rootReducer: any) => rootReducer?.eCommerceReducer?.eCommerceType);

   const handleAnchorElCollectPayment = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElCollectPayment(event.currentTarget);
   };
   const handleCloseAnchorElCollectPayment = () => {
      setAnchorElCollectPayment(null);
   };
   const handleAnchorElShareCart = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorElShareCart(event.currentTarget);
   };
   const handleCloseAnchorElShareCart = () => {
      setAnchorElShareCart(null);
   };

   const handleClickCollectPayment = (type: CollectPaymentModalType) => {
      setCurrentCollectPaymentModal(type);
   };

   const closeCollectPaymentModal = () => {
      setCurrentCollectPaymentModal(undefined);
   };

   const completeCart = () => {
      closeCollectPaymentModal();
      closeCustomerCart();
   };

   const onSubmitPayByCreditCard = (data: CreditCard) => {
      // alert(data.cardHolderName);
      // alert(data.cardNumber);
      // alert(data.date);
      // alert(data.cvc);
   };

   const copyCodeToClipboard = (code: string) => {
      navigator.clipboard
         .writeText(code)
         .then(() => {
            setCopyStatus(
               t[
                  'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_copy_to_clipboard_success'
               ],
            );
         })
         .catch(() => {
            setCopyStatus(
               t['page_settings_typo_platforms_live_chat_installation_section_live_chat_code_copy_to_clipboard_error'],
            );
         });
   };

   const lineItems = products?.map((product: any) => ({
      _id: product._id,
      variant_id: product.variantId,
      quantity: product.quantity,
      applied_discount:
         product.discount && product.discount?.discountValue !== 0
            ? {
                 description: product.discount?.discountReason,
                 value_type: product.discount?.discountType,
                 value: product.discount?.discountValue,
                 title: 'Qpien',
              }
            : undefined,
   }));

   const me = useSelector((rootReducer: any) => rootReducer.personReducer.person);
   const activeChat = useSelector((rootReducer: any) => rootReducer.chatReducer.activeChat);

   const [createOrderMutation, { data, loading }] = useMutation(CREATE_ECOMMERCE_ORDER);

   const createOrder = (
      markAsPaid: boolean,
      isCheckout: boolean,
      tSoftIsEFT: boolean,
      thenCallback: (() => void) | undefined,
   ) => {
      const appliedDiscount = {
         description: bill.discount.discountReason,
         value_type: bill.discount.discountType,
         value: bill.discount.discountValue,
         title: 'Qpien',
      };
      const variables = {
         type: eCommerceType,
         metaData: {
            alreadyCreatedOrderId,
            markAsPaid,
            tSoftIsEFT,
            paymentId: paymentType.paymentId,
            subPaymentId: paymentType.subPaymentId,
            paymentPrice: paymentType.paymentPrice,
            orderNote,
            selectedCustomerId,
            shipping,
            shippingMethodId: shipping?.shippingId,
            shippingPrice: shipping?.shippingPrice,
            customerId: person?.customer?._id,
            personId: person?._id,
            appliedDiscount: bill && bill.discount.discountValue !== 0 ? appliedDiscount : undefined,
            lineItems,
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            tags: [],
            chatId: activeChat?._id,
            isCheckout,
         },
      };
      createOrderMutation({ variables })
         .then((res) => {
            enqueueSnackbar(t?.general_information_success, {
               variant: 'success',
               autoHideDuration: 3000,
            });
            if (thenCallback) {
               thenCallback();
            }
         })
         .catch((error) => {
            enqueueSnackbar(t[error.message] || error.message, {
               variant: 'error',
               autoHideDuration: 3000,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {t['general_message_button_close']}
                  </div>
               ),
            });
         });
   };

   const onSubmitMarkAsPending = (data: NotificationChannels) => {
      createOrder(false, false, false, completeCart);
      // alert(`chatChecked: ${data.chatChecked}`);
      // alert(`emailChecked: ${data.emailChecked}`);
      // alert(`SMSChecked: ${data.SMSChecked}`);
      // alert(`whatsappChecked: ${data.whatsappChecked}`);
   };

   const onSubmitMarkAsPaid = (data: NotificationChannels) => {
      createOrder(true, false, false, completeCart);
      // alert(`chatChecked: ${data.chatChecked}`);
      // alert(`emailChecked: ${data.emailChecked}`);
      // alert(`SMSChecked: ${data.SMSChecked}`);
      // alert(`whatsappChecked: ${data.whatsappChecked}`);
   };

   const sendInvoice = () => {
      const markIsPaid = E_COMMERCE_PLATFORM_TYPE.TICIMAX ? false : true;
      createOrder(markIsPaid, true, false, undefined);
   };

   const selectEFT = () => {
      createOrder(false, false, true, completeCart);
   };

   const selectOnDoor = () => {
      createOrder(false, false, false, completeCart);
   };

   return (
      <div className={classes.buttonsContainer}>
         {/*<div className={classes.footerButtonContainer}>*/}
         {/*   <ButtonOK*/}
         {/*      onClick={handleAnchorElShareCart}*/}
         {/*      label={t['page_e_commerce_customer_customer_cart_share_cart']}*/}
         {/*      endIcon={<ArrowForwardIosRounded className={classes.arrowDownIcon} />}*/}
         {/*      disabled={disabled || loading}*/}
         {/*      style={{*/}
         {/*         backgroundColor: '#fff',*/}
         {/*         borderRadius: 5,*/}
         {/*         border: '1px solid #D9E0E6',*/}
         {/*         color: '#385273',*/}
         {/*         flex: 1,*/}
         {/*         width: 'fit-content',*/}
         {/*      }}*/}
         {/*      fontSize='14px'*/}
         {/*   />*/}
         {/*   <Menu*/}
         {/*      id='simple-menu'*/}
         {/*      anchorEl={anchorElShareCart}*/}
         {/*      keepMounted*/}
         {/*      open={Boolean(anchorElShareCart)}*/}
         {/*      onClose={handleCloseAnchorElShareCart}*/}
         {/*      anchorOrigin={{*/}
         {/*         vertical: 'top',*/}
         {/*         horizontal: 'left',*/}
         {/*      }}*/}
         {/*      transformOrigin={{*/}
         {/*         vertical: 'bottom',*/}
         {/*         horizontal: 'left',*/}
         {/*      }}*/}
         {/*   >*/}
         {/*      <MenuItem onClick={handleCloseAnchorElShareCart}>*/}
         {/*         <Typography>{t['page_e_commerce_customer_customer_cart_share_cart_send_in_chat']}</Typography>*/}
         {/*      </MenuItem>*/}
         {/*      <MenuItem onClick={handleCloseAnchorElShareCart}>*/}
         {/*         <Typography>{t['page_e_commerce_customer_customer_cart_share_cart_send_email']}</Typography>*/}
         {/*      </MenuItem>*/}
         {/*   </Menu>*/}
         {/*</div>*/}
         <div className={classes.footerButtonContainer}>
            <ButtonOK
               onClick={
                  alreadyCreatedOrderId
                     ? () => setCurrentCollectPaymentModal('complete_order')
                     : handleAnchorElCollectPayment
               }
               label={
                  alreadyCreatedOrderId
                     ? t['page_e_commerce_customer_typo_continue_customer_order']
                     : t['page_e_commerce_customer_customer_cart_collect_payment']
               }
               endIcon={!alreadyCreatedOrderId && <ArrowForwardIosRounded className={classes.arrowDownIcon} />}
               disabled={products?.length === 0 || loading}
               style={{
                  borderRadius: 5,
                  border: 'none',
                  flex: 1,
                  width: 'fit-content',
               }}
               fontSize='14px'
            />
            <Menu
               id='simple-menu'
               anchorEl={anchorElCollectPayment}
               keepMounted
               open={Boolean(anchorElCollectPayment)}
               onClose={handleCloseAnchorElCollectPayment}
               anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
               }}
               transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
               }}
            >
               {/* <MenuItem onClick={() => handleClickCollectPayment('pay_by_credit_card')}>
                  <Typography>
                     {t['page_e_commerce_customer_customer_cart_collect_payment_pay_by_credit_card']}
                  </Typography>
               </MenuItem> */}
               {(eCommerceType === 'TSOFT' && (
                  <>
                     <MenuItem onClick={() => handleClickCollectPayment('custom')}>
                        <Typography>{t['page_e_commerce_customer_customer_cart_collect_custom_payment']}</Typography>
                     </MenuItem>
                  </>
               )) || (
                  <>
                     <MenuItem onClick={() => handleClickCollectPayment('mark_as_pending')}>
                        <Typography>
                           {t['page_e_commerce_customer_customer_cart_collect_payment_mark_as_pending']}
                        </Typography>
                     </MenuItem>
                     <MenuItem onClick={() => handleClickCollectPayment('mark_as_paid')}>
                        <Typography>
                           {t['page_e_commerce_customer_customer_cart_collect_payment_mark_as_paid']}
                        </Typography>
                     </MenuItem>
                  </>
               )}
               {sendInvoiceActive && (
                  <MenuItem onClick={() => handleClickCollectPayment('send_invoice')}>
                     <Typography>{t['page_e_commerce_customer_customer_cart_collect_payment_send_invoice']}</Typography>
                  </MenuItem>
               )}
            </Menu>

            {/* <PayByCreditCard
               isVisible={currentCollectPaymentModal === 'pay_by_credit_card'}
               onCancel={closeCollectPaymentModal}
               onSubmit={onSubmitPayByCreditCard}
            /> */}
            <MarkWithStatus
               isVisible={currentCollectPaymentModal === 'mark_as_pending'}
               status={'mark_as_pending'}
               submitButtonDisabled={loading}
               onCancel={closeCollectPaymentModal}
               onSubmit={onSubmitMarkAsPending}
            />
            <MarkWithStatus
               isVisible={currentCollectPaymentModal === 'mark_as_paid'}
               status={'mark_as_paid'}
               submitButtonDisabled={loading}
               onCancel={closeCollectPaymentModal}
               onSubmit={onSubmitMarkAsPaid}
            />
            <MarkWithStatus
               isVisible={currentCollectPaymentModal === 'custom'}
               status={'custom'}
               submitButtonDisabled={loading}
               onCancel={closeCollectPaymentModal}
               onSubmit={onSubmitMarkAsPaid}
            />
            <MarkWithStatus
               isVisible={currentCollectPaymentModal === 'eft'}
               status={'eft'}
               submitButtonDisabled={loading}
               onCancel={closeCollectPaymentModal}
               onSubmit={selectEFT}
            />
            <MarkWithStatus
               isVisible={currentCollectPaymentModal === 'on_door'}
               status={'on_door'}
               submitButtonDisabled={loading}
               onCancel={closeCollectPaymentModal}
               onSubmit={selectOnDoor}
            />
            <Modal
               open={currentCollectPaymentModal === 'send_invoice'}
               onClose={data?.createECommerceOrder?.data ? completeCart : closeCollectPaymentModal}
            >
               <div className={classes.modalContainer}>
                  <div className={classes.modalHeader}>
                     <Typography className={classes.modalTitle}>
                        {t['page_chat_typo_ecommerce_send_invoice_modal_title']}
                     </Typography>
                  </div>
                  <div className={classes.childrenContainer}>
                     <Typography className={classes.modalSubtitle}>
                        {t['page_chat_typo_ecommerce_send_invoice_modal_subtitle']}
                     </Typography>
                     <Typography className={classes.modalDescription}>
                        {t['page_chat_typo_ecommerce_send_invoice_modal_description']}
                     </Typography>
                     <div>
                        <Typography>{t['page_chat_typo_ecommerce_send_invoice_modal_title']}</Typography>
                        {data?.createECommerceOrder?.data ? (
                           <div
                              style={{
                                 display: 'flex',
                                 flexDirection: 'row',
                                 justifyContent: 'space-between',
                                 alignItems: 'center',
                                 height: 40,
                                 flexGrow: 1,
                                 backgroundColor: '#fff',
                                 border: '1px solid #ddd',
                                 borderRadius: 8,
                                 overflow: 'hidden',
                              }}
                           >
                              <Typography variant='body1' noWrap style={{ paddingLeft: 16 }}>
                                 {data?.createECommerceOrder?.data?.invoice_url}
                              </Typography>
                              <Tooltip title={copyStatus}>
                                 <IconButton
                                    disableRipple
                                    onClick={() => copyCodeToClipboard(data?.createECommerceOrder?.data?.invoice_url)}
                                    className={classes.copyToClipboardButton}
                                 >
                                    <CopyToClipboardIcon />
                                 </IconButton>
                              </Tooltip>
                           </div>
                        ) : (
                           <div
                              style={{
                                 display: 'flex',
                                 flexGrow: 1,
                                 backgroundColor: '#eee',
                                 border: '1px solid #ddd',
                                 borderRadius: 8,
                                 height: 40,
                                 padding: '0 16px',
                                 alignItems: 'center',
                              }}
                           >
                              <Typography variant='body1'>
                                 {t['page_chat_typo_ecommerce_send_invoice_link_placeholder']}
                              </Typography>
                           </div>
                        )}
                     </div>
                  </div>
                  <div className={classes.modalFooter}>
                     <ButtonCancel
                        label={data?.createECommerceOrder?.data ? t['comp_button_close'] : t['comp_button_cancel']}
                        onClick={data?.createECommerceOrder?.data ? completeCart : closeCollectPaymentModal}
                        style={{ backgroundColor: 'transparent', color: '#263548', border: '1px solid #263548' }}
                     />
                     <ButtonOK
                        disabled={loading || data?.createECommerceOrder?.data}
                        label={t['comp_button_confirm']}
                        onClick={sendInvoice}
                        endIcon={loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
                     />
                  </div>
               </div>
            </Modal>
            <Modal
               paperStyle={{ width: 450 }}
               open={currentCollectPaymentModal === 'complete_order'}
               onClose={data?.createECommerceOrder?.data ? completeCart : closeCollectPaymentModal}
               modalHeader={t['page_e_commerce_customer_typo_continue_customer_order']}
            >
               <ModalLargeText style={{ textAlign: 'center' }}>
                  {t['page_e_commerce_customer_typo_continue_customer_order_confirm']}
               </ModalLargeText>
               <ModalButtonField>
                  <ButtonCancel
                     className={classes.modalButtons}
                     label={t['comp_button_cancel']}
                     disabled={loading}
                     onClick={data?.createECommerceOrder?.data ? completeCart : closeCollectPaymentModal}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                     }}
                  />
                  <ButtonOK
                     className={classes.modalButtons}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        float: 'right',
                     }}
                     label={t['comp_button_confirm']}
                     onClick={selectOnDoor}
                     disabled={loading}
                     endIcon={loading ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
                  />
               </ModalButtonField>
            </Modal>
         </div>
      </div>
   );
};

export default FooterButtons;

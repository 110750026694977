import React, { FC, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { LanguageContext } from '../../../../../helper/locale/langContext';

const useStyles = makeStyles(() => ({
   root: {
      display: 'flex',
      flexDirection: 'column',
      padding: 4,
      borderRadius: 4,
      margin: 8,
   },
   rootOrderNote: {
      backgroundColor: '#ffd2b6',
   },
   rootTrackingInfo: {
      backgroundColor: '#FCDB81',
   },
   rootShippingMethod: {
      backgroundColor: '#F1F4F6',
   },
   rootPaymentType: {
      backgroundColor: '#F1F4F6',
   },
   rootSubPaymentType: {
      backgroundColor: '#F1F4F6',
   },
   header: {
      display: 'flex',
      flexGrow: 1,
      justifyContent: 'space-between',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
   },
   headerLeftTitle: {
      fontSize: '1.3em',
      lineHeight: 1.4,
   },
   childrenContainer: {
      padding: 5,
      borderRadius: 4,
   },
   childrenContainerOrderNote: {
      backgroundColor: '#ffeadd',
   },
   childrenContainerTrackingInfo: {
      backgroundColor: '#fef6e1',
   },
   childrenContainerShippingMethod: {
      backgroundColor: '#FFFFFF',
   },
   childrenContainerPaymentType: {
      backgroundColor: '#FFFFFF',
   },
   childrenContainerSubPaymentType: {
      backgroundColor: '#FFFFFF',
   },
}));

interface NoteCardProps {
   type: 'orderNote' | 'trackingInfo' | 'shippingMethod' | 'paymentType' | 'subPaymentType';
   headerRight?: React.ReactNode;
}

const NoteCard: FC<NoteCardProps> = ({ type, headerRight, children }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   return (
      <div
         className={classNames(
            classes.root,
            type === 'orderNote' && classes.rootOrderNote,
            type === 'trackingInfo' && classes.rootTrackingInfo,
            type === 'shippingMethod' && classes.rootShippingMethod,
            type === 'paymentType' && classes.rootPaymentType,
            type === 'subPaymentType' && classes.rootSubPaymentType
         )}
      >
         <div className={classes.header}>
            <Typography variant='body1' noWrap className={classes.headerLeftTitle}>
               {type === 'orderNote' && t['page_e_commerce_store_typo_orderNote']}
               {type === 'trackingInfo' && t['page_e_commerce_store_typo_trackingInfo']}
               {type === 'shippingMethod' && t['page_e_commerce_store_typo_shippingMethod']}
               {type === 'paymentType' && t['page_e_commerce_store_typo_paymentType']}
               {type === 'subPaymentType' && t['page_e_commerce_store_typo_subPaymentType']}
            </Typography>
            {headerRight}
         </div>
         <div
            className={classNames(
               classes.childrenContainer,
               type === 'orderNote' && classes.childrenContainerOrderNote,
               type === 'trackingInfo' && classes.childrenContainerTrackingInfo,
               type === 'shippingMethod' && classes.childrenContainerShippingMethod,
               type === 'paymentType' && classes.childrenContainerPaymentType,
               type === 'subPaymentType' && classes.childrenContainerSubPaymentType
            )}
         >
            {children}
         </div>
      </div>
   );
};

export default NoteCard;

import React from 'react';

export const EnglishFlagIcon = ({ className }) => {
   return (
      <svg
         version='1.1'
         id='Layer_1'
         xmlns='http://www.w3.org/2000/svg'
         xmlnsXlink='http://www.w3.org/1999/xlink'
         x='0px'
         y='0px'
         viewBox='0 0 55.2 38.4'
         style={{
            enableBackground: 'new 0 0 55.2 38.4',
         }}
         xmlSpace='preserve'
         className={className}
      >
         <g>
            <path
               className='st0'
               d='M2.87,38.4h49.46c1.59-0.09,2.87-1.42,2.87-3.03V3.03c0-1.66-1.35-3.02-3.01-3.03H3.01 C1.35,0.01,0,1.37,0,3.03v32.33C0,36.98,1.28,38.31,2.87,38.4L2.87,38.4z'
            />
            <polygon
               className='st1'
               points='23.74,23.03 23.74,38.4 31.42,38.4 31.42,23.03 55.2,23.03 55.2,15.35 31.42,15.35 31.42,0 23.74,0 23.74,15.35 0,15.35 0,23.03 23.74,23.03'
            />
            <path className='st2' d='M33.98,12.43V0h18.23c1.26,0.02,2.34,0.81,2.78,1.92L33.98,12.43L33.98,12.43z' />
            <path className='st2' d='M33.98,25.97V38.4h18.35c1.21-0.07,2.23-0.85,2.66-1.92L33.98,25.97L33.98,25.97z' />
            <path className='st2' d='M21.18,25.97V38.4H2.87c-1.21-0.07-2.24-0.85-2.66-1.94L21.18,25.97L21.18,25.97z' />
            <path className='st2' d='M21.18,12.43V0H2.99C1.73,0.02,0.64,0.82,0.21,1.94L21.18,12.43L21.18,12.43z' />
            <polygon className='st2' points='0,12.8 7.65,12.8 0,8.97 0,12.8' />
            <polygon className='st2' points='55.2,12.8 47.51,12.8 55.2,8.95 55.2,12.8' />
            <polygon className='st2' points='55.2,25.6 47.51,25.6 55.2,29.45 55.2,25.6' />
            <polygon className='st2' points='0,25.6 7.65,25.6 0,29.43 0,25.6' />
            <polygon className='st1' points='55.2,3.25 36.15,12.8 40.41,12.8 55.2,5.4 55.2,3.25' />
            <polygon className='st1' points='19.01,25.6 14.75,25.6 0,32.98 0,35.13 19.05,25.6 19.01,25.6' />
            <polygon className='st1' points='10.52,12.81 14.78,12.81 0,5.41 0,7.55 10.52,12.81' />
            <polygon className='st1' points='44.63,25.59 40.37,25.59 55.2,33.02 55.2,30.88 44.63,25.59' />
         </g>
      </svg>
   );
};

export const TurkishFlagIcon = ({ className }) => {
   return (
      <svg className={className} xmlns='http://www.w3.org/2000/svg' viewBox='0 -30000 90000 60000'>
         <title>Flag of Turkey</title>
         <path fill='#e30a17' d='m0-30000h90000v60000H0z' />
         <path
            fill='#fff'
            d='m41750 0 13568-4408-8386 11541V-7133l8386 11541zm925 8021a15000 15000 0 1 1 0-16042 12000 12000 0 1 0 0 16042z'
         />
      </svg>
   );
};

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, ClickAwayListener, Grid, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import DataObjectIcon from '@mui/icons-material/DataObject';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { Node } from 'reactflow';

import {
   FileBlockProps,
   FlowBlockType,
   IFlowBlockField,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { LanguageContext } from '@helper/locale/langContext';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
   ADD_FLOW_BLOCK_MUTATION,
   REMOVE_BLOCKS_FROM_NODE_MUTATION,
   UPDATE_BLOCK_MUTATION,
} from '@queries/Automation/mutation';
import { GET_LIST_BLOCK_TEMPLATES_QUERY } from '@queries/Automation/query';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SET_BLOCKTEMPLATES } from '@store/actions/automation';
import SendMessageButtonPanel from './buttonPanel';
import { VariantType, useSnackbar } from 'notistack';

interface SendMsgBlocksProps {
   leftNodes: Node[];
   setIsBtnSendMsgDisable: React.Dispatch<React.SetStateAction<boolean>>;
   isSaveSendMsg: boolean;
   setIsSaveSendMsg: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   clickedNodeId: string;
   setFileBlock: React.Dispatch<React.SetStateAction<FileBlockProps[]>>;
   blockState: IFlowBlockTemplate[];
   setBlockState: React.Dispatch<React.SetStateAction<IFlowBlockTemplate[]>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
}

const SendMsgBlocks: React.FC<SendMsgBlocksProps> = ({
   leftNodes,
   setIsBtnSendMsgDisable,
   isSaveSendMsg,
   setIsSaveSendMsg,
   clickedNodeId,
   setFileBlock,
   blockState,
   setBlockState,
   isLoading,
   setIsLoading,
   setContentNodes,
   setLeftNodes,
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const divRef = useRef(null);
   const { enqueueSnackbar } = useSnackbar();
   const useStyles = makeStyles((theme) => ({
      textArea: {
         backgroundColor: '#F7F7FA',
         border: '1px solid #E7E7E7',
         borderRadius: '5px',
         outline: 'none',
         resize: 'none',
         width: '100%',
         minHeight: '80px',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '10px',
         '&:focus': {
            backgroundColor: '#fff',
            border: '2px solid #147CFC',
         },
      },
      textAreaError: {
         backgroundColor: '#F7F7FA',
         border: '1px solid #FF7474',
         borderRadius: '5px',
         outline: 'none',
         resize: 'none',
         width: '100%',
         minHeight: '80px',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '10px',
         '&:focus': {
            backgroundColor: '#fff',
            border: '2px solid #FF7474',
         },
      },

      file: {
         border: '1px solid #E7E7E7',
         backgroundColor: '#F7F7FA',
         borderRadius: '5px',
         width: '100%',
         fontSize: '1rem',
         display: 'flex',
         justifyContent: 'center',
         minHeight: '130px',
         color: 'gray',
         cursor: 'pointer',
         marginBottom: '10px',
      },
      previewImage: {
         backgroundColor: '#F7F7FA',
         borderRadius: '5px',
         width: '100%',
         fontSize: '1rem',
         display: 'flex',
         justifyContent: 'center',
         minHeight: '130px',
         color: 'gray',
         cursor: 'pointer',
         marginBottom: '10px',
      },
      preview: {
         display: 'flex',
         flexDirection: 'column',
         textAlign: 'center',
         borderRadius: '5px',
         justifyContent: 'center',
         height: '130px',
         padding: '5px',
         width: '100%',
         fontSize: '1rem',
         border: '0.1px solid #DDDDDD',
         marginBottom: '10px',
      },

      item: {
         display: 'flex',
         flexDirection: 'column',
      },
      customFileInput: {
         color: 'transparent',
         '&::-webkit-file-upload-button': {
            visibility: 'hidden',
         },
         '&::before': {
            content: t.atomation_flow_upload_an_image,
            color: '#818181',
            background: 'transparent',
            outline: 'none',
            cursor: 'pointer',
            fontSize: '1rem',
            justifyContent: 'center',
            display: 'flex',
            height: '100%',
         },
      },
      customFileVideo: {
         color: 'transparent',
         '&::-webkit-file-upload-button': {
            visibility: 'hidden',
         },
         '&::before': {
            content: t.atomation_flow_upload_a_video,
            color: '#818181',
            background: 'transparent',
            outline: 'none',
            cursor: 'pointer',
            fontSize: '1rem',
            display: 'flex',
            justifyContent: 'center',
         },
      },
      deleteIcon: {
         color: 'gray',
         backgroundColor: '#fff',
         borderRadius: 3,
         cursor: 'pointer',
         boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
         zIndex: 9999,
      },
      container: {
         display: 'flex',
         flexDirection: 'column',
         fontSize: '1rem',
         position: 'relative',
         height: '100%',
      },
      boxText: {
         width: '100%',
         overflow: 'hidden',
         paddingBottom: '18px',
      },
      miniBox: {
         position: 'absolute',
         bottom: -18,
         right: 2,
         width: '26%',
         borderRadius: 7,
         float: 'right',
         display: 'grid',
         gridTemplateColumns: 'repeat(3, 1fr)',
         gap: '1px',
         color: '#fff',
         backgroundColor: 'black',
         padding: '2px 4px',
         cursor: 'pointer',
         zIndex: 1,
      },
   }));

   const showSnackbar = (message: string, variant: VariantType | undefined) => {
      enqueueSnackbar(message, {
         variant: variant,
         autoHideDuration: 2000,
      });
   };

   const classes = useStyles();
   const [anchorElField, setAnchorElField] = useState<null | HTMLElement>(null);
   const [isHover, setIsHover] = useState(false);
   const [isValid, setIsValid] = useState<boolean>(true);
   const [isDeletable, setIsDeletable] = useState(true);
   const [hoverId, setHoverId] = useState<string>('');
   const [menuId, setMenuId] = useState<string>('');
   const [wordCounts, setWordCounts] = useState<{ [key: string]: number }>({});
   const [activeTextareaId, setActiveTextareaId] = useState<string | null>(null);
   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
   const [deletedBlockId, setDeletedBlockId] = useState<string[]>([]);
   const [files, setFiles] = useState<FileBlockProps[]>([]);
   const [toUpdate, setToUpdate] = useState<{ _id: string; fieldsValue: string }[]>([]);
   const [mutationCount, setMutationCount] = useState(0);
   const [fileUpdate, setFileUpdate] = useState<
      { _id: string; index: number; file: File; mediaType: IFlowBlockFieldTypes }[]
   >([]);
   const dispatch = useDispatch();
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const [customFields, setCustomFields] = useState<{ name: string; _id: string }[]>([]);
   const CUSTOM_FIELDS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.customFields);
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   useEffect(() => {
      setCustomFields(CUSTOM_FIELDS);
   }, [CUSTOM_FIELDS]);

   // Add block mutation
   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlock = async (
      flowBlockTemplateId: String,
      nodeId: string,
      field: IFlowBlockField[],
      prevBlockId: string,
   ) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fields: field,
               },
            },
            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];

               setDataId((prev) => [...prev, { prevData: prevBlockId, newData: response._id }]);
               setMutationCount((prevCount) => prevCount - 1);
               setIsDeletable(true);

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: true,
                              flowBlocks: [...node.data.flowBlocks, response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };
   const handleAddFlowBlockMedia = async (
      flowBlockTemplateId: String,
      nodeId: string,
      media: { _id?: string; index: number; file: File; mediaType: IFlowBlockFieldTypes },
      prevBlockId: string,
   ) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fieldsIndex: media.index,
                  media: media.file,
                  fields: [
                     {
                        type: media.mediaType,
                     },
                  ],
               },
            },
            onCompleted: async (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];

               setDataId((prev) => [...prev, { prevData: prevBlockId, newData: response._id }]);
               setIsDeletable(true);
               setMutationCount((prevCount) => prevCount - 1);

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              isValidNode: true,
                              flowBlocks: [...node.data.flowBlocks, response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };
   const handleAddFlowBlockConnector = async (flowBlockTemplateId: String, nodeId: string) => {
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
               },
            },
            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,

                           data: {
                              ...node.data,
                              flowBlocks: [...node.data.flowBlocks, response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlockInput = async (blockId: string, input: string) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: {
                     type: IFlowBlockFieldTypes.Text,
                     value: input,
                  },
               },
            },
            onCompleted: (res) => {
               setMutationCount((prevCount) => prevCount - 1);

               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block.type !== FlowBlockType.SendMessageConnector)
                                 ? true
                                 : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const [dataId, setDataId] = useState<{ prevData: string; newData: string }[]>([]);
   // Delete block mutation
   const [deleteBlock] = useMutation(REMOVE_BLOCKS_FROM_NODE_MUTATION);
   const handleDeleteFlowBlock = async (blockId: String[], nodeId: string) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await deleteBlock({
            variables: {
               input: {
                  blocks: blockId,
                  node: nodeId,
               },
            },
            onCompleted: () => {
               setMutationCount((prevCount) => prevCount - 1);
               setContentNodes((prevNodes) =>
                  prevNodes.map((node) =>
                     node.id === clickedNodeId
                        ? {
                             ...node,
                             data: {
                                ...node.data,
                                clickedNodeId: clickedId,
                                isValidNode: blockState.some(
                                   (block) => block.type !== FlowBlockType.SendMessageConnector,
                                )
                                   ? true
                                   : false,
                                flowBlocks: node.data.flowBlocks.filter(
                                   (flowBlock: IFlowBlockTemplate) => !blockId.includes(flowBlock._id),
                                ),
                             },
                          }
                        : node,
                  ),
               );
               setLeftNodes((prevNodes) =>
                  prevNodes.map((node) =>
                     node.id === clickedNodeId
                        ? {
                             ...node,
                             data: {
                                ...node.data,
                                flowBlocks: node.data.flowBlocks.filter(
                                   (flowBlock: IFlowBlockTemplate) => !blockId.includes(flowBlock._id),
                                ),
                             },
                          }
                        : node,
                  ),
               );
            },
         });
      } catch (error) {
         console.error('Error deleting block:', error);
      }
   };

   const handleMenuFieldClose = () => {
      setAnchorElField(null);
   };

   const handleMenuFieldOpen = (id: string) => {
      setAnchorElField(divRef.current);
      setMenuId(id);
   };

   const handlePickerFieldOpen = (id: string) => {
      setShowEmojiPicker(true);
      setActiveTextareaId(id);
   };

   const handleCreateBlock = (type: IFlowBlockFieldTypes) => {
      setIsBtnSendMsgDisable(true);
      const newItem = {
         _id: Date.now().toString(),
         title: '',
         fields: [
            {
               type: type,
               value: '',
            },
         ],
         type: FlowBlockType.SendMessage,
      };

      setBlockState((prevBlockState) => [...prevBlockState, newItem]);
   };

   const calculateWordCount = (id: string, text: string) => {
      const wordCount = text.length;
      setWordCounts((prevWordCounts) => ({
         ...prevWordCounts,
         [id]: 1500 - wordCount,
      }));
   };

   const handleBlockInputChange = (id: string, text: string) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     value: text,
                  })),
               };
            }
            return item;
         });

         if (text.length > 0) {
            setIsBtnSendMsgDisable(false);
         } else {
            setIsBtnSendMsgDisable(true);
         }
         calculateWordCount(id, text);
         return updatedBlockState;
      });

      setToUpdate([{ _id: id, fieldsValue: text }]);
   };

   const backendBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];

   useEffect(() => {
      if (mutationCount > 0) {
         setIsLoading(true);
      } else {
         setIsLoading(false);
      }
   }, [mutationCount]);

   //save
   useEffect(() => {
      if (isSaveSendMsg && !isLoading) {
         setIsSaveSendMsg(false);
         setIsBtnSendMsgDisable(false);

         const newBlocks = blockState.filter(
            (block) => !backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );
         const existingBlocks = blockState.filter((block) =>
            backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         // Delete blocks into the backend
         let updatedFiles = [...files];
         if (deletedBlockId.length > 0) {
            deletedBlockId.forEach((element) => {
               updatedFiles = updatedFiles.filter((item) => item._id !== element);
               setFileBlock(updatedFiles);
            });
            const filteredBlocksId = deletedBlockId.filter((blockId) =>
               backendBlocks.some((block: { _id: string }) => block._id === blockId),
            );

            if (filteredBlocksId.length > 0) {
               handleDeleteFlowBlock(filteredBlocksId, clickedNodeId);
            }
            setDeletedBlockId([]);
         }

         // Add all new blocks to the backend
         const save = async () => {
            // Update fields
            if (toUpdate.length > 0) {
               toUpdate.forEach((item) => {
                  // Check if the item's _id exists in existingBlocks
                  if (existingBlocks.some((block) => block._id === item._id)) {
                     handleUpdateFlowBlockInput(item._id, item.fieldsValue);
                  }
               });
               setToUpdate([]);
            }

            if (newBlocks.length > 0) {
               const fetchBlockTemplates = async () => {
                  const response = await getBlockTemplate({
                     variables: { input: {} },
                     fetchPolicy: 'network-only',
                  });

                  if (response.data) {
                     return response.data.listBlockTemplates.data;
                  }

                  return null;
               };

               (async () => {
                  let blockTemplate = [];
                  if (LIST_BLOCK_TEMPLATE?.length > 0) {
                     blockTemplate = LIST_BLOCK_TEMPLATE;
                  } else {
                     blockTemplate = await fetchBlockTemplates();
                     dispatch({ type: SET_BLOCKTEMPLATES, payload: blockTemplate });
                  }

                  if (blockTemplate) {
                     const templateBlockId = blockTemplate.find(
                        (item: { type: FlowBlockType }) => item.type === FlowBlockType.SendMessage,
                     )?._id;
                     const templateBlockMediaId = blockTemplate.find(
                        (item: { type: FlowBlockType }) => item.type === FlowBlockType.SendMedia,
                     )?._id;

                     setIsDeletable(false);
                     for await (let item of newBlocks) {
                        if (templateBlockId && templateBlockMediaId) {
                           const hasFileInput = item.fields.find(
                              (field) =>
                                 field.type === IFlowBlockFieldTypes.ImageUrl ||
                                 field.type === IFlowBlockFieldTypes.VideoUrl,
                           );

                           if (!hasFileInput) {
                              await handleAddFlowBlock(templateBlockId, clickedNodeId, item.fields, item._id);
                           } else if (fileUpdate.length > 0) {
                              const media = fileUpdate.find((file) => item._id === file._id);
                              if (media) {
                                 await handleAddFlowBlockMedia(templateBlockMediaId, clickedNodeId, media, item._id);
                              }
                           }
                        }
                     }
                  }
               })();
            }
         };
         save();
         setFileBlock(files);
         setFileUpdate([]);
         setToUpdate([]);
      }
   }, [isSaveSendMsg]);

   // update the local state with the Id coming from the response mutation
   useEffect(() => {
      if (dataId) {
         let updatedBlockState = [...blockState];
         let updatedFileState = [...files];
         dataId.forEach((element) => {
            updatedBlockState = updatedBlockState.map((block) => {
               if (element.prevData === block._id) {
                  return {
                     ...block,
                     _id: element.newData,
                  };
               } else {
                  return block;
               }
            });
            updatedFileState = updatedFileState.map((file) => {
               if (element.prevData === file._id) {
                  return {
                     ...file,
                     _id: element.newData,
                  };
               } else {
                  return file;
               }
            });
         });

         setBlockState(updatedBlockState);
         setFiles(updatedFileState);
      }
   }, [leftNodes]);

   const [getBlockTemplate] = useLazyQuery(GET_LIST_BLOCK_TEMPLATES_QUERY);

   const listBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   // default
   useEffect(() => {
      setBlockState([]);
      if (listBlocks.length > 0) {
         const data = listBlocks.map((item: IFlowBlockTemplate) => {
            const value = item.fields[0]?.value;
            if (value) {
               calculateWordCount(item._id, value);
            }
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });
         setBlockState(data);
      } else {
         const isConnector = backendBlocks.some(
            (block: { type: FlowBlockType }) => block.type === FlowBlockType.SendMessageConnector,
         );

         if (!isConnector) {
            (async () => {
               if (LIST_BLOCK_TEMPLATE) {
                  const templateBlockIdConnector = LIST_BLOCK_TEMPLATE.find(
                     (item: { type: FlowBlockType }) => item.type === FlowBlockType.SendMessageConnector,
                  )?._id;
                  if (templateBlockIdConnector) {
                     handleAddFlowBlockConnector(templateBlockIdConnector, clickedNodeId);
                  }
               }
            })();
         }
      }
   }, [clickedNodeId]);

   const handleSetIsHover = (blockId: string) => {
      setIsHover(true);
      setHoverId(blockId);
   };

   const handleFileUpload = (
      Id: string,
      fieldIndex: number,
      event: React.ChangeEvent<HTMLInputElement>,
      mediaType: IFlowBlockFieldTypes,
   ) => {
      const selectedFile = event.target.files && event.target.files[0];

      if (selectedFile) {
         // Set size limits for image and video
         const maxSizeImage = 5 * 1024 * 1024; // 5 MB
         const maxSizeVideo = 25 * 1024 * 1024; // 25 MB

         if (
            (mediaType === IFlowBlockFieldTypes.ImageUrl && selectedFile.size > maxSizeImage) ||
            (mediaType === IFlowBlockFieldTypes.VideoUrl && selectedFile.size > maxSizeVideo)
         ) {
            const message =
               mediaType === IFlowBlockFieldTypes.ImageUrl
                  ? t.automation_flow_image_warning
                  : t.automation_flow_video_warning;
            showSnackbar(message, 'error');
            event.target.value = '';

            return;
         } else {
            setFileUpdate((prev) => [
               ...prev,
               { _id: Id, index: fieldIndex, file: selectedFile, mediaType: mediaType },
            ]);

            // Create a new entry in files or update an existing one with the selected file
            const updatedFileBlock = files.map((item) => (item._id === Id ? { ...item, file: selectedFile } : item));

            // If the fileId doesn't exist in files, create a new entry
            if (!updatedFileBlock.some((item) => item._id === Id)) {
               updatedFileBlock.push({ _id: Id, file: selectedFile });
            }
            setFiles(updatedFileBlock);
            if (isValid) {
               setIsBtnSendMsgDisable(true);
            } else {
               setIsBtnSendMsgDisable(false);
            }
         }
      }
   };

   // delete block and the corresponding file
   const handleBlockDelete = (idToDelete: string) => {
      const existingBlocks = backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === idToDelete);

      const hasFieldValueToUpdate = backendBlocks.some((backendBlock: { _id: string; fields: IFlowBlockField[] }) => {
         const correspondingBlockState = blockState.find((stateBlock) => stateBlock._id === backendBlock._id);

         return correspondingBlockState
            ? backendBlock.fields.some((field, index) => {
                 const correspondingField = correspondingBlockState.fields[index];
                 return correspondingField && correspondingField.value !== field.value;
              })
            : false;
      });

      setBlockState((prevBlock) => {
         setDeletedBlockId((prevBlock) => [...prevBlock, idToDelete]);
         const updatedBlockState = prevBlock.filter((item) => item._id !== idToDelete);

         const hasMoreBlockStates = updatedBlockState.length > backendBlocks.length;
         const shouldUpdate = hasFieldValueToUpdate || hasMoreBlockStates;

         if (existingBlocks || shouldUpdate) {
            setIsBtnSendMsgDisable(false);
         }
         return updatedBlockState;
      });
   };

   const addEmoji = (e: any, id: string) => {
      const emoji = e.native;

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               const updatedFields = item.fields.map((field) => {
                  if (field.type === IFlowBlockFieldTypes.Text || field.type === IFlowBlockFieldTypes.SendMessage) {
                     const textAreaValue = field.value || '';
                     const textAreaElement = document.getElementById(`textarea-${id}`) as HTMLTextAreaElement | null;
                     if (textAreaElement) {
                        const start = textAreaElement.selectionStart;
                        const end = textAreaElement.selectionEnd;
                        const updatedValue = textAreaValue.substring(0, start) + emoji + textAreaValue.substring(end);
                        calculateWordCount(id, updatedValue);
                        setToUpdate([{ _id: id, fieldsValue: updatedValue }]);
                        return { ...field, value: updatedValue };
                     }
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });

         setIsBtnSendMsgDisable(false);
         return updatedBlockState;
      });

      setShowEmojiPicker(false);
   };

   const addField = (name: string, id: string) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               const updatedFields = item.fields.map((field) => {
                  if (field.type === IFlowBlockFieldTypes.Text || field.type === IFlowBlockFieldTypes.SendMessage) {
                     const textAreaValue = field.value || '';
                     const textAreaElement = document.getElementById(`textarea-${id}`) as HTMLTextAreaElement | null;
                     if (textAreaElement) {
                        const start = textAreaElement.selectionStart;
                        const end = textAreaElement.selectionEnd;
                        const updatedValue = textAreaValue.substring(0, start) + name + textAreaValue.substring(end);
                        calculateWordCount(id, updatedValue);
                        setToUpdate([{ _id: id, fieldsValue: updatedValue }]);
                        return { ...field, value: updatedValue };
                     }
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });

         setIsBtnSendMsgDisable(false);
         return updatedBlockState;
      });

      handleMenuFieldClose();
   };

   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         let numRows = 0;
         let consecutiveChars = 0;

         for (let i = 0; i < content.length; i++) {
            if (content[i] !== '\n') {
               consecutiveChars++;
               if (consecutiveChars === 36) {
                  numRows++;
                  consecutiveChars = 0;
               }
            } else {
               consecutiveChars = 0;
            }
         }

         const enters = (content.match(/\n/g) || []).length;

         numRows += enters;

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: numRows + 2, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };

   // making enabled or disabled the save button
   function hasNegativeValue(obj: { [x: string]: number }) {
      for (let key in obj) {
         if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] < 0) {
               return true;
            }
         }
      }
      return false;
   }

   useEffect(() => {
      const isInvalid = blockState.some((block) =>
         block.fields.some((field) => field.type === 'text' && field.value === ''),
      );

      const blockMedia = blockState.filter((block) =>
         block.fields.some((field) => (field.type === 'image_url' || field.type === 'video_url') && field.value === ''),
      );

      let isValidMedia;
      if (blockMedia) {
         isValidMedia = blockMedia.every((block) => files.some((file) => file._id === block._id));
      }
      setIsValid(isInvalid);

      if (isInvalid || !isValidMedia || hasNegativeValue(wordCounts)) {
         setIsBtnSendMsgDisable(true);
      }
   }, [blockState, fileUpdate]);

   return (
      <Grid container className={classes.container} wrap='nowrap'>
         <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <ClickAwayListener onClickAway={() => setActiveTextareaId(null)}>
               <Grid className={classes.item}>
                  {blockState.map((item) => (
                     <Grid key={item._id}>
                        {item.fields.map((field, index) => {
                           let content = null;

                           if (field.type === IFlowBlockFieldTypes.Text) {
                              content = (
                                 <div style={{ width: '100%' }}>
                                    <Box className={classes.boxText}>
                                       <textarea
                                          id={`textarea-${item._id}`}
                                          key={item._id}
                                          className={
                                             field.value && field.value?.length > 1500
                                                ? classes.textAreaError
                                                : classes.textArea
                                          }
                                          name='rawText'
                                          placeholder={t.atomation_flow_enter_message}
                                          value={field.value}
                                          onFocus={() => setActiveTextareaId(item._id)}
                                          onClick={() => setActiveTextareaId(item._id)}
                                          onChange={(event) => {
                                             handleBlockInputChange(item._id, event.target.value);
                                          }}
                                          {...calculateTextareaSize(field.value)}
                                       />

                                       {activeTextareaId && activeTextareaId === item._id && (
                                          <div style={{ position: 'sticky', top: 0, width: '100%' }} ref={divRef}>
                                             <div className={classes.miniBox} onMouseDown={(e) => e.preventDefault()}>
                                                <div onClick={() => handleMenuFieldOpen(item._id)}>
                                                   <DataObjectIcon
                                                      style={{
                                                         display: 'flex',
                                                         justifyContent: 'center',
                                                         fontSize: '18px',
                                                         marginTop: '2px',
                                                      }}
                                                   />
                                                </div>
                                                <EmojiEmotionsIcon
                                                   onClick={() => handlePickerFieldOpen(item._id)}
                                                   style={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      fontSize: '18px',
                                                      marginTop: '2px',
                                                   }}
                                                />
                                                <span
                                                   style={{
                                                      color: 'green',
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      fontSize: '14px',
                                                      marginTop: '2px',
                                                   }}
                                                >
                                                   {wordCounts[item._id] === 0 ? 0 : wordCounts[item._id] || 1500}
                                                </span>
                                             </div>
                                          </div>
                                       )}
                                    </Box>
                                    {showEmojiPicker && activeTextareaId === item._id && (
                                       <ClickAwayListener onClickAway={() => setShowEmojiPicker(false)}>
                                          <div style={{ zIndex: '9999', position: 'sticky', marginTop: '-15px' }}>
                                             <Picker
                                                onSelect={(e) => addEmoji(e, item._id)}
                                                style={{ width: '100%' }}
                                                title={t['emoji_selector_title']}
                                             />
                                          </div>
                                       </ClickAwayListener>
                                    )}
                                 </div>
                              );
                           } else if (
                              field.type === IFlowBlockFieldTypes.ImageUrl ||
                              field.type === IFlowBlockFieldTypes.VideoUrl
                           ) {
                              const correspondingFile = files.find((file) => file._id === item._id);

                              if (field.type === IFlowBlockFieldTypes.ImageUrl) {
                                 content = field.value ? (
                                    <Grid className={classes.previewImage} onClick={() => setActiveTextareaId(null)}>
                                       <img
                                          key={item._id}
                                          src={field.value.split('*_*')[0]}
                                          alt='Preview'
                                          style={{
                                             width: '100%',
                                             height: '100%',
                                             borderRadius: '10px',
                                             border: '1px solid #E7E7E7',
                                          }}
                                       />
                                    </Grid>
                                 ) : correspondingFile ? (
                                    <Grid className={classes.previewImage} onClick={() => setActiveTextareaId(null)}>
                                       <img
                                          key={item._id}
                                          src={URL.createObjectURL(correspondingFile.file)}
                                          alt='Preview'
                                          style={{
                                             width: '100%',
                                             height: '100%',
                                             borderRadius: '10px',
                                             border: '1px solid #E7E7E7',
                                          }}
                                       />
                                    </Grid>
                                 ) : (
                                    <Grid className={classes.file} onClick={() => setActiveTextareaId(null)}>
                                       <input
                                          key={item._id}
                                          type='file'
                                          accept='image/*'
                                          onChange={(event) =>
                                             handleFileUpload(item._id, index, event, IFlowBlockFieldTypes.ImageUrl)
                                          }
                                          className={classes.customFileInput}
                                          style={{ paddingTop: '55px', height: '100%', width: '100%' }}
                                       />
                                    </Grid>
                                 );
                              } else if (field.type === IFlowBlockFieldTypes.VideoUrl) {
                                 content = correspondingFile ? (
                                    <Grid className={classes.preview} onClick={() => setActiveTextareaId(null)}>
                                       <SlideshowIcon
                                          style={{
                                             color: 'gray',
                                             width: '50px',
                                             height: '50px',
                                             marginLeft: '8rem',
                                          }}
                                       />
                                       <span>{correspondingFile.file.name}</span>
                                    </Grid>
                                 ) : field.value ? (
                                    <Grid className={classes.preview} onClick={() => setActiveTextareaId(null)}>
                                       <SlideshowIcon
                                          style={{
                                             color: 'gray',
                                             width: '50px',
                                             height: '50px',
                                             marginLeft: '8rem',
                                          }}
                                       />
                                       <span>{field.value.split('*_*').slice(1).join('*_*')}</span>
                                    </Grid>
                                 ) : (
                                    <Grid className={classes.file} onClick={() => setActiveTextareaId(null)}>
                                       <input
                                          key={item._id}
                                          type='file'
                                          accept='video/*'
                                          onChange={(event) =>
                                             handleFileUpload(item._id, index, event, IFlowBlockFieldTypes.VideoUrl)
                                          }
                                          className={classes.customFileVideo}
                                          style={{ paddingTop: '55px', height: '100%', width: '100%' }}
                                       />
                                    </Grid>
                                 );
                              }
                           }
                           return (
                              <Grid>
                                 <div
                                    key={item._id}
                                    style={{ display: 'flex', position: 'relative' }}
                                    onMouseEnter={() => handleSetIsHover(item._id)}
                                    onMouseLeave={() => setIsHover(false)}
                                 >
                                    {content}

                                    {isHover && item._id === hoverId && isDeletable && (
                                       <div
                                          style={{
                                             position: 'absolute',
                                             top: 2,
                                             right: -20,
                                             zIndex: 9999,
                                          }}
                                       >
                                          <Grid onClick={() => handleBlockDelete(item._id)}>
                                             <DeleteIcon className={classes.deleteIcon} />
                                          </Grid>
                                       </div>
                                    )}
                                 </div>
                              </Grid>
                           );
                        })}
                     </Grid>
                  ))}
               </Grid>
            </ClickAwayListener>
            <Grid>
               <SendMessageButtonPanel handleCreateBlock={handleCreateBlock} blockState={blockState} />
            </Grid>
         </Grid>

         <Menu
            anchorEl={anchorElField}
            open={Boolean(anchorElField)}
            onClose={handleMenuFieldClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{ marginTop: 5 }}
            PaperProps={{
               style: {
                  width: '363px',
                  borderRadius: '5px',
                  maxHeight: '250px',
                  marginTop: '20px',
               },
            }}
         >
            <MenuItem onClick={() => addField(t.atomation_flow_first_name_value, menuId)}>
               {t.atomation_flow_first_name}
            </MenuItem>
            <MenuItem onClick={() => addField(t.atomation_flow_last_name_value, menuId)}>
               {t.atomation_flow_last_name}
            </MenuItem>
            <MenuItem onClick={() => addField(t.atomation_full_name_value, menuId)}>{t.atomation_full_name}</MenuItem>
            <MenuItem onClick={() => addField(t.atomation_flow_email_value, menuId)}>{t.atomation_flow_email}</MenuItem>
            <MenuItem onClick={() => addField(t.atomation_flow_phone_value, menuId)}>{t.atomation_flow_phone}</MenuItem>

            {customFields.map((custom) => (
               <MenuItem onClick={() => addField('{{' + custom.name + '}}', menuId)}>{custom.name}</MenuItem>
            ))}
         </Menu>
      </Grid>
   );
};

export default SendMsgBlocks;

import React from 'react';

const Failed = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='8.765' height='8.763' viewBox='0 0 8.765 8.763'>
         <path
            id='Path_3459'
            data-name='Path 3459'
            d='M6135.121,1375.8l-1.04,1.042c-.613.613-1.223,1.228-1.839,1.837a.873.873,0,0,1-1.463-.332.858.858,0,0,1,.242-.925l2.729-2.727a.946.946,0,0,1,.141-.1c-.055-.062-.082-.092-.108-.121l-2.748-2.748a.877.877,0,1,1,1.208-1.271c.487.48.969.967,1.451,1.451l1.316,1.315c.031.032.065.062.107.1.044-.042.083-.078.121-.115l2.748-2.748a.872.872,0,0,1,1.463.329.857.857,0,0,1-.24.925l-2.729,2.728c-.038.038-.083.068-.173.14a1.179,1.179,0,0,1,.179.117q1.37,1.367,2.738,2.738a.872.872,0,0,1-.331,1.463.847.847,0,0,1-.916-.232q-1.3-1.3-2.6-2.6C6135.29,1375.985,6135.212,1375.9,6135.121,1375.8Z'
            transform='translate(-6130.732 -1370.184)'
            fill='#fd0e37'
         />
      </svg>
   );
};

export default Failed;

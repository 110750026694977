import { useMutation } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { UPDATE_FLOW_NODE_MUTATION } from '@queries/Automation/mutation';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Node } from 'reactflow';
import AskQuestionBlocks from '../ContentPanel/Nodes/FlowBlocks/askQuestionBlocks';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '5px 0',
      width: '100%',
   },
   subContainer: {
      padding: '0 18px 10px 18px',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100%',
      height: 'calc(100% - 65px)',
   },
   btnContainer: {
      margin: '10px 20px 20px 10px',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      padding: '5px 13px',
      minWidth: 120,
      height: 36,
      borderRadius: 6,
      transition: 'all 0.5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },

   header: {
      display: 'flex',
      gap: '10px',
      marginBottom: '10px',
      paddingLeft: '18px',
      padding: '5px 18px 10px 18px',
      borderBottom: '1px solid #F7F7FA',
   },
   input: {
      border: 'none',
      outline: 'none',
      fontSize: '1.1rem',
   },
   btn: {
      height: 36,
      width: '100%',
      border: '1px dashed #157AFA',
      borderRadius: '10px',
      background: 'transparent',
      cursor: 'pointer',
   },
   iconBox: {
      width: 40,
      height: 35,
      borderRadius: '7px',
      display: 'flex',
      justifyContent: 'center',
   },
}));

interface AskQuestionProps {
   leftNodes: Node[];
   contentNodes: Node[];
   clickedNodeId: string;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
}

const AskQuestionLeftPanel: React.FC<AskQuestionProps> = ({
   leftNodes,
   clickedNodeId,
   setContentNodes,
   setLeftNodes,
   contentNodes,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [isBtnDisable, setIsBtnDisable] = useState(true);
   const [isSaveAskQuestion, setIsSaveAskQuestion] = useState(false);
   const [prevTitle, setPrevTitle] = useState<string>();
   const [input, setInput] = useState<string>();
   const [isLoading, setIsLoading] = useState(false);
   const [isUpdating, setIsUpdating] = useState(false);

   useEffect(() => {
      setPrevTitle(leftNodes.find((node) => node.id === clickedNodeId)?.data.title);
   }, [clickedNodeId, leftNodes]);

   useEffect(() => {
      setInput(leftNodes.find((node) => node.id === clickedNodeId)?.data.title);
   }, [clickedNodeId]);

   const [updateFlowNode] = useMutation(UPDATE_FLOW_NODE_MUTATION);
   const handleUpdateFlowNode = async (itemId: String) => {
      try {
         if (prevTitle !== input) {
            setIsUpdating(true);
            await updateFlowNode({
               variables: {
                  input: {
                     _id: itemId,
                     title: input,
                  },
               },
               onCompleted: (res) => {
                  const updateNodes = (prevNodes: Node[], clickedNodeId: string, input: string) =>
                     prevNodes.map((node) => {
                        if (node.id === clickedNodeId) {
                           // Update the title inside the data object
                           return { ...node, data: { ...node.data, title: input } };
                        }
                        return node;
                     });

                  if (input) {
                     setContentNodes((prev) => updateNodes(prev, clickedNodeId, input));
                     setLeftNodes((prev) => updateNodes(prev, clickedNodeId, input));
                  }
                  setIsUpdating(false);
               },
            });
         }
      } catch (error) {
         console.error('Error updatingting node:', error);
      }
   };

   const handleTitleSave = () => {
      handleUpdateFlowNode(clickedNodeId);
      if (!isLoading) {
         setIsSaveAskQuestion(true);
         setIsBtnDisable(true);
      }
   };

   useEffect(() => {
      if (!isLoading) {
         setIsBtnDisable(true);
      }
   }, [isLoading]);

   const maxLength = 32;

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' justifyContent='center' xs={12}>
            <CircularProgress size={20} color='inherit' />
         </Grid>
      );
   }, []);

   return (
      <Grid className={classes.container}>
         <Grid className={classes.header}>
            <div className={classes.iconBox} style={{ backgroundColor: '#FFDEDE' }}>
               <QuestionMarkIcon style={{ color: '#FF7474', fontSize: '25px', marginTop: '5px' }} />
            </div>
            <input
               value={input}
               className={classes.input}
               onChange={(e) => {
                  if (e.target.value.length <= maxLength) {
                     setInput(e.target.value);
                     setIsBtnDisable(false);
                  }
               }}
            />
         </Grid>
         <Grid className={classes.subContainer} style={{ flex: 1 }}>
            <AskQuestionBlocks
               leftNodes={leftNodes}
               setIsBtnDisable={setIsBtnDisable}
               isSaveAskQuestion={isSaveAskQuestion}
               setIsSaveAskQuestion={setIsSaveAskQuestion}
               clickedNodeId={clickedNodeId}
               isLoading={isLoading}
               setIsLoading={setIsLoading}
               contentNodes={contentNodes}
               setContentNodes={setContentNodes}
               setLeftNodes={setLeftNodes}
            />
         </Grid>
         <Grid className={classes.btnContainer}>
            <Grid style={{ overflowY: 'auto', flex: 1 }}>
               <Button
                  className={classes.modalButtons}
                  type='submit'
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     float: 'right',
                  }}
                  onClick={handleTitleSave}
                  disabled={isBtnDisable}
               >
                  <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                     {(isLoading || isUpdating) && <Loader />}
                     <span style={{ whiteSpace: 'nowrap' }}>{t.page_chat_btn_navbar_save}</span>
                  </div>
               </Button>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default AskQuestionLeftPanel;

import { gql } from '@apollo/client';

// queries
export const GET_E_COMMERCE_PLATFORMS_QUERY = gql`
   query getECommercePlatforms($type: String!, $customer: ID!) {
      getECommercePlatforms(type: $type, customer: $customer) {
         data
         success
         code
         message
      }
   }
`;
export const GET_ECOMMERCE_ALREADY_CONNECTED = gql`
   query getAlreadyConnectedStore($type: String!, $customer: ID!) {
      getAlreadyConnectedStore(type: $type, customer: $customer) {
         data
         success
         code
         message
      }
   }
`;
export const GET_E_COMMERCE_LINKED_CUSTOMER_QUERY = gql`
   query getECommerceLinkedCustomer($customer: ID!, $person: ID!) {
      getECommerceLinkedCustomer(customer: $customer, person: $person) {
         data
         success
         code
         message
      }
   }
`;

export const GET_E_COMMERCE_CUSTOMER_ABANDONED_CART_QUERY = gql`
   query getCustomerAbandonedOrder($customer: ID!, $platformCustomer: String) {
      getCustomerAbandonedOrder(customer: $customer, platformCustomer: $platformCustomer) {
         data {
            available
            data
         }
         success
         code
         message
      }
   }
`;

export const GET_E_COMMERCE_PRODUCTS_QUERY = gql`
   query getECommerceProducts($type: String, $customer: ID!, $status: Int, $page: Int, $pageToken: String, $query: String) {
      getECommerceProducts(type: $type, customer: $customer, page: $page, pageToken: $pageToken, query: $query, status: $status) {
         data {
            products
            nextPageToken
            nextPage
            hasNextPage
            totalProducts
         }
         success
         code
         message
      }
   }
`;

export const GET_E_COMMERCE_VARIANTS_QUERY = gql`
   query getECommerceVariants($customer: ID!, $productId: String!) {
      getECommerceVariants(customer: $customer, productId: $productId) {
         data {
            variantId
            name
            currency
            salesPrice
            discountedPrice
            stock
            variantsType1
            variantsType2
            variantsType3
         }
         success
         code
         message
      }
   }
`;

export const GET_E_COMMERCE_ORDERS_QUERY = gql`
   query getECommerceOrders(
      $type: String
      $customer: ID!
      $platformCustomer: String
      $status: String
      $page: Int
      $pageToken: String
      $query: String
   ) {
      getECommerceOrders(
         type: $type
         platformCustomer: $platformCustomer
         customer: $customer
         page: $page
         pageToken: $pageToken
         query: $query
         status: $status
      ) {
         data {
            orders
            hasNextPage
            totalOrders
            nextPageToken
            nextPage
         }
         success
         code
         message
      }
   }
`;

export const GET_E_COMMERCE_CUSTOMERS_QUERY = gql`
   query getECommerceCustomers(
      $type: String
      $customer: ID!
      $status: Int
      $page: Int
      $pageToken: String
      $query: String
   ) {
      getECommerceCustomers(
         type: $type
         customer: $customer
         page: $page
         pageToken: $pageToken
         query: $query
         status: $status
      ) {
         data {
            customerList
            nextPageToken
            nextPage
            hasNextPage
            totalCustomerCount
         }
         success
         code
         message
      }
   }
`;

export const GET_E_COMMERCE_CONNECTION_QUERY = gql`
   query getLoginUrl($type: String!, $customer: ID!, $storeUrl: String!, $metaData: Object) {
      getLoginUrl(type: $type, customer: $customer, storeUrl: $storeUrl, metaData: $metaData) {
         data {
            url
         }
         success
         code
         message
      }
   }
`;

import React, { FC, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, IconButton, Typography } from '@material-ui/core';
import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import Input from '../../../../../components/InputField';
import ButtonOK from '../../../../../components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import { DISCOUNT_TYPES, DiscountType, IDiscountProps } from '../../Tabs/E-Commerce/Tabs/Customer/types';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      width: 'fit-content',
      backgroundColor: '#fff',
      border: '1px solid #D9E0E6',
      borderRadius: 4,
   },
   body: {
      display: 'flex',
      flexDirection: 'column',
      padding: 8,
      borderBottom: '1px solid #D9E0E6',
   },
   row: {
      display: 'flex',
      flexDirection: 'column',
      paddingTop: 8,
      paddingBottom: 8,
   },
   footer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alinItems: 'center',
      padding: 8,
   },
   label: {
      fontSize: '1.2rem',
      marginBottom: 4,
      marginTop: 4,
   },
   discountType: {
      color: '#223659',
   },
   selectedDiscountType: {
      color: '#98A6B5',
   },
   texInputContainer: {
      marginTop: -15,
      marginBottom: -18,
   },
   discountValueContainer: {
      border: 'none',
      margin: 0,
      background: '#FFF',
      height: 36,
      display: 'flex',
      alignItems: 'center',
      borderRadius: 4,
      boxSizing: 'border-box',
   },
   increaseDecreaseContainer: {
      backgroundColor: '#fff',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   increaseDecreaseButton: {
      borderRadius: 4,
      height: 18,
      width: 18,
   },
   increaseDecreaseIcon: {
      fontSize: 15,
   },
   inputContainer: {
      width: 40,
      overflow: 'hidden',
   },
   input: {
      boxSizing: 'border-box',
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 5,
      paddingRight: 5,
      width: 40,
      height: 36,
      border: 'none',
      fontSize: '1em',
      fontWeight: 400,
      lineHeight: 'normal',
      outline: 'none',
      textAlign: 'left',
   },
   discountTypeAndValueGroup: {
      display: 'flex',
      height: 36,
      alignItems: 'center',
      border: '2px solid #DEE1E6',
      borderRadius: 4,
      padding: 0,
      boxSizing: 'border-box',
      backgroundColor: '#F4F5F7',
      overflow: 'hidden',
      alignSelf: 'flex-start',
   },
   discountTypeButton: {
      width: 36,
      height: 36,
      borderRight: '1px solid #DEE1E6',
      '&:hover': {
         backgroundColor: '#eee',
      },
   },
   selectedDiscountTypeButton: {
      backgroundColor: '#ddd',
   },
   selectedDiscountTypeContainer: {
      display: 'flex',
      width: 20,
      justifyContent: 'center',
      alignItems: 'center',
   },
}));

interface DiscountCardProps {
   currency: string;
   close: () => void;
   // handleDiscountData: (data: IDiscountProps) => void;
   handleDiscountData: (data: any) => void; //TODO: will be refactor
   price: number;
   currentDiscount?: IDiscountProps;
   isNoteEditable?: boolean;
}

const DiscountCard: FC<DiscountCardProps> = ({
   close,
   currency,
   handleDiscountData,
   price,
   currentDiscount,
   isNoteEditable,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [selectedDiscountType, setSelectedDiscountType] = useState<DiscountType>(
      currentDiscount?.discountType || DISCOUNT_TYPES.FIXED_VALUE
   );
   const [discountValue, setDiscountValue] = useState<number>(currentDiscount?.discountValue || 0);

   const { control, setValue, handleSubmit } = useForm({
      mode: 'all',
      defaultValues: {
         discountReason: currentDiscount?.discountReason || '',
         discountType: selectedDiscountType,
         discountValue: discountValue,
      },
   });

   const isDiscountValid = (type: DiscountType, value: number) => {
      if (type === DISCOUNT_TYPES.PERCENTAGE && value >= 0 && value <= 100) {
         return true;
      } else return type !== DISCOUNT_TYPES.PERCENTAGE && value >= 0 && value <= price;
   };

   const changeDiscountType = (type: DiscountType) => {
      if (!isDiscountValid(type, discountValue)) {
         setDiscountValue(0);
         setValue('discountValue', 0);
      }
      setSelectedDiscountType(type);
      setValue('discountType', type);
   };

   const changeDiscountValue = (newValue: number) => {
      if (isDiscountValid(selectedDiscountType, newValue)) {
         setDiscountValue(newValue);
         setValue('discountValue', newValue);
      }
   };

   return (
      <div className={classes.container}>
         <div className={classes.body}>
            <div className={classes.row}>
               <Typography variant='body2' noWrap className={classes.label}>
                  {t['page_e_commerce_payment_discount_this_item_by']}
               </Typography>
               <div className={classes.discountTypeAndValueGroup}>
                  {Object.values(DISCOUNT_TYPES).map((discountType, index) => (
                     <ButtonBase
                        key={index}
                        onClick={() => changeDiscountType(discountType)}
                        disableRipple
                        className={classNames(
                           classes.discountTypeButton,
                           selectedDiscountType === discountType && classes.selectedDiscountTypeButton
                        )}
                     >
                        <Typography variant='body2' noWrap className={classes.discountType}>
                           {discountType === DISCOUNT_TYPES.FIXED_VALUE ? currency : '%'}
                        </Typography>
                     </ButtonBase>
                  ))}
                  <div className={classes.discountValueContainer}>
                     <div className={classes.selectedDiscountTypeContainer}>
                        <Typography variant='body2' noWrap className={classes.selectedDiscountType}>
                           {selectedDiscountType === DISCOUNT_TYPES.FIXED_VALUE ? currency : '%'}
                        </Typography>
                     </div>
                     <div className={classes.inputContainer}>
                        <input
                           type='number'
                           value={Number(discountValue).toString()}
                           onChange={(e) => changeDiscountValue(Number(e.target.value))}
                           className={classes.input}
                           onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                        />
                     </div>
                     <div className={classes.increaseDecreaseContainer}>
                        <IconButton
                           className={classes.increaseDecreaseButton}
                           onClick={() => changeDiscountValue(discountValue - 1)}
                           size='small'
                           disableRipple
                        >
                           <RemoveRoundedIcon className={classes.increaseDecreaseIcon} />
                        </IconButton>
                        <IconButton
                           className={classes.increaseDecreaseButton}
                           onClick={() => changeDiscountValue(discountValue + 1)}
                           size='small'
                           disableRipple
                        >
                           <AddRoundedIcon className={classes.increaseDecreaseIcon} />
                        </IconButton>
                     </div>
                  </div>
               </div>
            </div>
            {isNoteEditable && (
               <div className={classes.row}>
                  <Typography variant='body2' noWrap className={classes.label}>
                     {t['page_e_commerce_payment_reason']}
                  </Typography>
                  <div className={classes.texInputContainer}>
                     <Input
                        name='discountReason'
                        control={control}
                        editable
                        height={36}
                        placeholder={t['page_e_commerce_payment_reason_placeholder']}
                     />
                  </div>
               </div>
            )}
         </div>
         <div className={classes.footer}>
            <ButtonOK
               onClick={close}
               label={t['comp_button_close']}
               style={{
                  backgroundColor: '#fff',
                  borderRadius: 5,
                  border: '1px solid #D9E0E6',
                  color: '#385273',
                  flex: 1,
                  width: 'fit-content',
                  marginRight: 8,
               }}
            />
            <ButtonOK
               onClick={handleSubmit(handleDiscountData)}
               label={t['comp_button_apply']}
               style={{
                  borderRadius: 5,
                  border: 'none',
                  flex: 1,
                  width: 'fit-content',
                  marginLeft: 8,
               }}
            />
         </div>
      </div>
   );
};

export default DiscountCard;

import { gql } from '@apollo/client';

// mutations
export const CREATE_ECOMMERCE_PLATFORM_MUTATION = gql`
   mutation createECommercePlatformMutation($type: String!, $metaData: Object!) {
      createECommercePlatform(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;

export const CONNECT_ECOMMERCE_PLATFORM_MUTATION = gql`
   mutation connectECommercePlatform($customerId: String!, $eCommerceStoreId: String!) {
      connectECommercePlatform(customerId: $customerId, eCommerceStoreId: $eCommerceStoreId) {
         data
         success
         code
         message
      }
   }
`;
export const UPDATE_ECOMMERCE_PLATFORM_MUTATION = gql`
   mutation updateECommercePlatformMutation($type: String!, $metaData: Object!) {
      updateECommercePlatform(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;
export const DELETE_ECOMMERCE_PLATFORM_MUTATION = gql`
   mutation deleteECommercePlatformMutation($type: String!, $metaData: Object!) {
      deleteECommercePlatform(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;

export const UPDATE_ECOMMERCE_CUSTOMER_MUTATION = gql`
   mutation updateECommerceCustomerMutation($type: String, $metaData: Object!) {
      updateECommerceCustomer(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;

export const CREATE_ECOMMERCE_CUSTOMER_MUTATAION = gql`
   mutation createECommerceCustomerMutatation($type: String, $metaData: Object!) {
      createECommerceCustomer(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;

export const LINK_E_COMMERCE_CUSTOMER_MUTATION = gql`
   mutation linkECommerceCustomerMutation($type: String, $metaData: LinkECommerceCustomerInputType!) {
      linkECommerceCustomer(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;
export const UNLINK_E_COMMERCE_CUSTOMER_MUTATION = gql`
   mutation unlinkECommerceCustomerMutation($type: String, $metaData: Object!) {
      unlinkECommerceCustomer(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;

export const CHECK_E_COMMERCE_CUSTOMER_ADDRESSES = gql`
   mutation checkECommerceCustomerAddressesMutation($type: String, $metaData: Object!) {
      checkECommerceCustomerAddresses(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;

export const CREATE_ECOMMERCE_ORDER = gql`
   mutation createECommerceOrderMutation($type: String, $metaData: Object!) {
      createECommerceOrder(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;

export const PAYMENT_FROM_OTHER_CHANNEL = gql`
   mutation paymentFromOtherChannelMutation($data: Object!) {
      paymentFromOtherChannel(data: $data) {
         data {
            _id
            name
            paymentFromOtherChannel
         }
         success
         code
         message
      }
   }
`;

export const UPDATE_ECOMMERCE_PLATFORM_SETTINGS_MUTATION = gql`
   mutation updateECommercePlatformSettingsMutation($type: String!, $metaData: ECommercePlatformSettingsInputType!) {
      updateECommercePlatformSettings(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;

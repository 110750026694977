import React, { useContext, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Node } from 'reactflow';
import { LanguageContext } from '@helper/locale/langContext';
import SendMsgBlocks from '../Blocks/sendMessageBlocks';
import SendIcon from '@mui/icons-material/Send';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '5px 0',
      width: '100%',
   },
   subContainer: {
      padding: '0 18px 0 18px',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100%',
      height: 'calc(100% - 65px)',
   },

   header: {
      display: 'flex',
      gap: '10px',
      marginBottom: '10px',
      paddingLeft: '18px',
      padding: '5px 18px 10px 18px',
      borderBottom: '1px solid #F7F7FA',
   },
   input: {
      border: 'none',
      outline: 'none',
      fontSize: '1.1rem',
   },
   iconBox: {
      width: 40,
      height: 35,
      borderRadius: '7px',
      display: 'flex',
      justifyContent: 'center',
   },
}));

interface SendMessagePanelProps {
   leftNodes: Node[];
   clickedNodeId: string;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setHeigherNode: React.Dispatch<React.SetStateAction<number>>;
}

const SendMessageLeftPanel: React.FC<SendMessagePanelProps> = ({
   leftNodes,
   setLeftNodes,
   clickedNodeId,
   setHeigherNode,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [prevTitle, setPrevTitle] = useState<string>();
   const [input, setInput] = useState<string>();

   useEffect(() => {
      setPrevTitle(leftNodes.find((node) => node.id === clickedNodeId)?.data.title);
   }, [clickedNodeId, leftNodes]);

   useEffect(() => {
      setInput(leftNodes.find((node) => node.id === clickedNodeId)?.data.title);
   }, [clickedNodeId]);

   const maxLength = 32;

   return (
      <Grid className={classes.container}>
         <Grid className={classes.header}>
            <div className={classes.iconBox} style={{ backgroundColor: '#C3DDFF' }}>
               <SendIcon style={{ color: '#147BFB', fontSize: '25px', marginTop: '5px' }} />
            </div>
            <Grid style={{ marginTop: '5px' }}>
               <input
                  value={input}
                  className={classes.input}
                  onChange={(e) => {
                     if (e.target.value.length <= maxLength) {
                        setInput(e.target.value);
                     }
                  }}
               />
            </Grid>
         </Grid>
         <Grid className={classes.subContainer} style={{ flex: 1 }}>
            <SendMsgBlocks
               leftNodes={leftNodes}
               setLeftNodes={setLeftNodes}
               clickedNodeId={clickedNodeId}
               setHeigherNode={setHeigherNode}
            />
         </Grid>
      </Grid>
   );
};

export default SendMessageLeftPanel;

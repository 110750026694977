import { LanguageContext } from '@helper/locale/langContext';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Info from '@material-ui/icons/Info';
import React, { useContext } from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';

const useStyles = makeStyles((theme) => ({
   container: {
      backgroundColor: '#fff',
      // padding: 10,
      // marginTop: 60,
   },
   customPaper: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      height: 50,
      width: '100%',
      marginBottom: 10,
      paddingLeft: 16,
      paddingRight: 10,
   },
   menuItem: {
      margin: 0,
      marginTop: 5,
      marginBottom: 5,
      padding: 0,
   },
   selector: {
      textAlign: 'left',
      border: 'solid 1px lightgrey',
      borderRadius: 5,
      paddingLeft: 3,
      height: 32,
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
}));

const TabBar = ({ selectedTab, completeTabs, onTabClick, isPreview, selectPlatform }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <Grid container alignItems='center' className={classes.customPaper}>
         <Grid alignItems='center' justifyContent='space-between' direction='row' spacing={2} container>
            <Grid item xs={6}>
               <Button
                  fullWidth
                  variant='outlined'
                  style={{
                     marginRight: 5,
                     minWidth: 140,
                     borderWidth: 2,
                     borderColor: selectedTab === 0 ? '#0080fe' : '#D1D1D1',
                  }}
                  endIcon={
                     isPreview ? null : completeTabs.includes(0) ? (
                        <CheckCircle style={{ color: 'green' }} />
                     ) : (
                        <Info style={{ color: '#FFC107' }} />
                     )
                  }
                  onClick={() => onTabClick(0)}
               >
                  {t['campaign_settings']}
               </Button>
            </Grid>
            <Grid item xs={6}>
               <Button
                  fullWidth
                  style={{
                     minWidth: 140,
                     borderWidth: 2,
                     borderColor: selectedTab === 1 ? '#0080fe' : '#D1D1D1',
                  }}
                  disabled={!selectPlatform}
                  variant='outlined'
                  endIcon={
                     isPreview ? null : completeTabs.includes(1) ? (
                        <CheckCircle style={{ color: 'green' }} />
                     ) : (
                        <Info style={{ color: '#FFC107' }} />
                     )
                  }
                  onClick={() => onTabClick(1)}
               >
                  {t['campaign_content']}
               </Button>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default WithUrlTranslations(TabBar);

import React, { useState } from 'react';
import Nav from './nav';
import List from './list';
import { FlowBotDataItem } from '@modules/Automation/FlowBots/types';

interface RulesListProps {}

const RulesList: React.FC<RulesListProps> = () => {
   const [selectedItem, setSelectedItem] = useState<FlowBotDataItem>();
   const [getInfiniteData, setInfiniteData] = useState<{
      hasMore: boolean;
      totalLength: number;
      data: FlowBotDataItem[];
   }>({
      hasMore: true,
      totalLength: 0,
      data: [],
   });

   return (
      <div>
         <Nav getInfiniteData={getInfiniteData} setInfiniteData={setInfiniteData} />
         <List
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            getInfiniteData={getInfiniteData}
            setInfiniteData={setInfiniteData}
         />
      </div>
   );
};

export default RulesList;

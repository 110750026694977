import { useMutation } from '@apollo/client';
import { CircularProgress, Grid } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { TOGGLE_QUESTION_DRAWER_PROFILE } from '@store/actions/ui';
import { SITE_URL } from '@helper/config';
import { LanguageContext } from '@helper/locale/langContext';
import { getActiveQuestion } from '@store/reducers/questions';
import { MyTheme } from '@styles/config';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Input from '@components/InputField';
import MediumAvatar from '@components/MediumAvatar';
import { UPDATE_OWNER_PERSON_MUTATION } from '../../../queries/Questions/Service/mutation';
import useQnaQuestions from '../shared-hooks/useQnaQuestions';
import { QnAPlatformType } from '../util/types';

const useStyles = makeStyles((theme: MyTheme) =>
   createStyles({
      root: {
         display: 'flex',
      },
      appBar: {
         transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
      },
      appBarShift: {
         width: `calc(100% - ${theme.chatApp.questions.drawerWidth}px)`,
         transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
         }),
         marginRight: theme.chatApp.questions.drawerWidth,
      },
      title: {
         flexGrow: 1,
      },
      hide: {
         display: 'none',
      },
      drawer: {
         flexShrink: 0,
         top: 60,
      },
      drawerPaper: {
         width: theme.chatApp.questions.drawerWidth,
         marginTop: 60,
         [theme.breakpoints.down('md')]: {
            width: '100%',
         },
         [theme.breakpoints.only('md')]: {
            width: `calc(100% - 466px)`,
         },
         overflowY: 'auto',
         height: 'calc(100vh - 60px)',
         zIndex: 2,
         // backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      },
      drawerHeader: {
         display: 'flex',
         alignItems: 'center',
         padding: theme.spacing(0, 1),
         ...theme.mixins.toolbar,
         justifyContent: 'flex-start',
      },
      header: {
         height: 59,
         padding: theme.spacing(0, 1),
      },
      content: {
         flexGrow: 1,
         padding: theme.spacing(3),
         transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
         }),
         marginRight: -theme.chatApp.questions.drawerWidth,
      },
      contentShift: {
         transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
         }),
         marginRight: 0,
      },

      button: {
         // paddingTop:10,
         fontSize: '0.9rem',
         fontWeight: 'bold',
         color: 'white',
         backgroundColor: '#23d935',
         '&:hover': {
            backgroundColor: theme.palette.success.dark,
         },
      },
      cancel: {
         backgroundColor: '#f21630',
         '&:hover': {
            backgroundColor: '#970617',
         },
      },
      item: {
         marginTop: 3,
         marginBottom: 3,
      },
      tag: {
         marginLeft: 1,
         marginRight: 1,
         paddingTop: 7,
         height: 30,
         width: 68,
         padding: 5,
         borderRadius: 10,
         fontWeight: 'bold',
         border: 'solid 1px #cbd3dd',
         color: theme.chatApp.general.pallet.passiveStructureBlue,
      },
      backgroundContainer: {
         // overflowY: 'auto',
         // overscrollBehavior: 'contain',
         height: theme.mixins.toolbar.height,
         ...theme.mixins.toolbar,
      },
      itemContainer: {
         paddingTop: 2,
         paddingBottom: 2,
         marginTop: 4,
      },
      avatarContainer: {},
      label: {
         color: theme.chatApp.general.pallet.passiveStructureBlue,
         fontWeight: 'bold',
      },
      fixedValues: {
         color: theme.chatApp.general.pallet.passiveStructureBlue,
         marginLeft: 4,
      },
      nameText: {
         fontSize: '1.1rem',
      },
      information: {
         borderBottom: '1px solid lightgrey',
         padding: '10px',
      },
   })
);

const PersistentDrawerRight = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const { enqueueSnackbar } = useSnackbar();
   const { updateQnaPerson } = useQnaQuestions();
   const openMenu = useSelector((state: any) => state.uiReducer.isOpenQuestionProfileMenu);
   const activeQuestion = useSelector(getActiveQuestion);
   const activeQuestionPerson = useSelector((reducer: any) => reducer.questionsState.activeQuestion?.ownerPerson);
   const [editModeActivated, setEditModeActivated] = useState(false);
   const dispatch = useDispatch();
   const [updateQnAPersonMutation, { loading }] = useMutation(UPDATE_OWNER_PERSON_MUTATION);

   const defaultValues = useMemo(() => ({
      _id: activeQuestionPerson?._id || '',
      name: activeQuestionPerson?.name || '',
      phone: activeQuestionPerson?.phone || '',
      email: activeQuestionPerson?.email || '',
   }), [
      activeQuestionPerson?._id,
      activeQuestionPerson?.name,
      activeQuestionPerson?.phone,
      activeQuestionPerson?.email,
   ]);

   const { control, reset, handleSubmit } = useForm({
      mode: 'all',
      defaultValues: defaultValues,
   });

   useEffect(() => {
      reset(defaultValues);
   }, [defaultValues, reset]);

   const onSubmit = handleSubmit((data) => {
      updateQnAPersonMutation({
         variables: {
            ...data,
            name: data.name,
         },
      })
         .then(
            ({
               data: {
                  updatePerson: { data },
               },
            }) => {
               enqueueSnackbar(t?.general_information_updated_successfully, {
                  variant: 'success',
                  autoHideDuration: 2000,
               });

               setEditModeActivated(false);
               updateQnaPerson(data);
            }
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               autoHideDuration: 2000,
            });
         });
   });

   return (
      <Drawer
         className={classes.drawer}
         variant='persistent'
         anchor='right'
         open={openMenu}
         classes={{
            paper: classes.drawerPaper,
         }}
      >
         <Grid alignItems='center' justifyContent='center' container className={classes.backgroundContainer}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
               <Grid container className={classes.header} alignItems='center' direction='row'>
                  <Grid item xs={11}>
                     <MediumAvatar
                        isCustomer
                        hideCircle
                        size='small'
                        title={activeQuestionPerson?.name}
                        name={activeQuestionPerson?.name}
                        image={activeQuestionPerson?.photo ? activeQuestionPerson.photo : SITE_URL + '/none.jpg'}
                        onlineStatus={activeQuestionPerson?.status}
                        isQna
                     />
                  </Grid>
                  <Grid item xs={1}>
                     <IconButton
                        onClick={() => {
                           dispatch({
                              type: TOGGLE_QUESTION_DRAWER_PROFILE,
                              payload: false,
                           });
                        }}
                        style={{ border: '2px solid grey', borderRadius: 6, padding: 2 }}
                     >
                        <CloseIcon />
                     </IconButton>
                  </Grid>
               </Grid>
               <Divider style={{ width: '100%' }} />
               <Grid className={classes.information}>
                  {/* Todo Add Store name */}

                  <Grid
                     item
                     xs={12}
                     style={{ padding: 10, paddingBottom: 0 }}
                     container
                     justifyContent='flex-start'
                     alignItems='center'
                  >
                     <Typography className={classes.label}>
                        {activeQuestion?.platformType === QnAPlatformType.TRENDYOL ||
                           activeQuestion?.platformType === QnAPlatformType.HEPSIBURADA ||
                           activeQuestion?.platformType === QnAPlatformType.N11
                           ? t?.settings_page_question_store_name
                           : t['page_chat_typo_profile_channel']}
                        :
                     </Typography>

                     {activeQuestion?.platformType === QnAPlatformType.HEPSIBURADA && (
                        <Typography className={classes.fixedValues}>
                           {activeQuestion?.hepsiburadaAccount?.storeName}
                        </Typography>
                     )}

                     {activeQuestion?.platformType === QnAPlatformType.TRENDYOL && (
                        <Typography className={classes.fixedValues}>
                           {activeQuestion?.trendyolAccount?.storeName}
                        </Typography>
                     )}

                     {activeQuestion?.platformType === QnAPlatformType.N11 && (
                        <Typography className={classes.fixedValues}>
                           {activeQuestion?.n11Account?.storeName}
                        </Typography>
                     )}
                     {activeQuestion?.platformType === QnAPlatformType.FACEBOOK && (
                        <Typography className={classes.fixedValues}>{activeQuestion?.facebookPage?.name}</Typography>
                     )}

                     {activeQuestion?.platformType === QnAPlatformType.INSTAGRAM && (
                        <Typography className={classes.fixedValues}>{activeQuestion?.instagramPage?.name}</Typography>
                     )}
                  </Grid>

                  <Grid
                     item
                     xs={12}
                     style={{ padding: 10, paddingBottom: 0 }}
                     container
                     justifyContent='flex-start'
                     alignItems='center'
                  >
                     <Typography className={classes.label}> {t['page_chat_typo_profile_creation_date']}: </Typography>
                     <Typography className={classes.fixedValues}>
                        {moment(activeQuestionPerson?.createdAt).format('DD.MM.YYYY hh:mm')}{' '}
                     </Typography>
                  </Grid>
               </Grid>

               <form onSubmit={onSubmit}>
                  <Grid container item xs={12} style={{ padding: 15 }}>
                     <Grid item xs={12} className={classes.itemContainer}>
                        {/* Name Input */}
                        <Input
                           label={t['page_contacts_typo_name_surname']}
                           name='name'
                           control={control}
                           disabled={!editModeActivated}
                           height={40}
                           background='white'
                           rules={{
                              required: 'This field is required',
                              validate: (val: string) => {
                                 return !!val.trim();
                              },
                           }}
                        />
                     </Grid>
                     <Grid item xs={12} style={{ textAlign: 'left' }} className={classes.itemContainer}>
                        {/* Phone Input */}
                        <Input
                           label={t['page_chat_typo_profile_phone']}
                           disabled={!editModeActivated}
                           name='phone'
                           control={control}
                           background='white'
                           isPhone
                           splitCountryCode={true}
                        />
                     </Grid>
                     <Grid item xs={12} className={classes.itemContainer}>
                        {/* E-mail Input  */}
                        <Input
                           label={t['page_chat_typo_profile_mail']}
                           name='email'
                           rules={{
                              pattern: {
                                 value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                 message: t?.must_be_a_valid_email,
                              },
                           }}
                           background='white'
                           control={control}
                           disabled={!editModeActivated}
                        />
                     </Grid>
                     <Grid container xs={12} justifyContent='center' alignItems='flex-start'>
                        {editModeActivated ? (
                           <Fragment>
                              <Grid item xs={6} style={{ paddingRight: 5 }}>
                                 <ButtonCancel
                                    onClick={() => {
                                       setEditModeActivated(false);
                                       reset();
                                    }}
                                    label={t['page_chat_btn_filter_cancel']}
                                    fullWidth
                                    type='reset'
                                    height={35}
                                    style={{ width: '100%' }}
                                 />
                              </Grid>
                              <Grid item xs={6} style={{ paddingLeft: 5 }}>
                                 <ButtonOK
                                    label={t['page_chat_typo_profile_save']}
                                    fullWidth
                                    height={35}
                                    type='submit'
                                    style={{ width: '100%' }}
                                    endIcon={loading ? <CircularProgress size={24} color='secondary' /> : undefined}
                                    disabled={loading}
                                 />
                              </Grid>
                           </Fragment>
                        ) : (
                           <Grid item xs={12}>
                              <ButtonOK
                                 fullWidth
                                 onClick={() => setEditModeActivated(true)}
                                 label={t['page_chat_typo_profile_edit']}
                                 height={35}
                                 fontWeight='bold'
                                 fontSize='1.1rem'
                                 style={{ width: '100%' }}
                              />
                           </Grid>
                        )}
                     </Grid>
                  </Grid>
               </form>
            </Grid>
         </Grid>
      </Drawer>
   );
};

export default PersistentDrawerRight;

import React from 'react';

const Logo = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='136.599' height='46.536' viewBox='0 0 136.599 46.536'>
         <g id='Group_81' data-name='Group 81' transform='translate(-17.232 -14.283)'>
            <g id='Group_1' dataname='Group 1' transform='translate(60.563 14.283)'>
               <path
                  id='Path_1'
                  dataname='Path 1'
                  d='M205.742,24.163c-4.036,0-7.472,1.295-10.7,6.958a13.542,13.542,0,0,1,4.017-6.457H194.99a2.873,2.873,0,0,0-2.149.907,2.99,2.99,0,0,0-.877,2.158V60.819h4.1a2.357,2.357,0,0,0,1.755-.75,2.512,2.512,0,0,0,.726-1.814v-9.32a9.438,9.438,0,0,0,7.2,2.814,11.991,11.991,0,0,0,9.018-3.785,13.844,13.844,0,0,0,3.631-10.008,13.849,13.849,0,0,0-3.631-10.008A12.01,12.01,0,0,0,205.742,24.163Zm4.211,19.571a6.166,6.166,0,0,1-4.8,2.093,6.394,6.394,0,0,1-4.877-2.093A8.286,8.286,0,0,1,198.4,38a8.337,8.337,0,0,1,1.913-5.778,6.223,6.223,0,0,1,4.84-2.133,6.108,6.108,0,0,1,4.8,2.133A8.457,8.457,0,0,1,211.83,38a8.285,8.285,0,0,1-1.876,5.738Zm35.363-19.571c-8.45,0-13.894,5.711-14.048,13.466-.167,8.478,5.923,13.919,14.136,14.1,5.62.127,8.485-1.56,10.2-2.963a2.042,2.042,0,0,0,.114-2.777l-2.389-2.731a10.8,10.8,0,0,1-7.642,2.932c-4.361-.082-6.982-2.192-7.662-6.124a8.868,8.868,0,0,1,0-4.046,12.762,12.762,0,0,0,.729,4.06l18.552.211a1.5,1.5,0,0,0,1.516-1.441c.024-.7.03-1.347.024-1.667C258.708,29.433,252.751,24.162,245.317,24.163Zm-7.249,11.214a7.179,7.179,0,0,1,7.005-6.241,6.628,6.628,0,0,1,6.952,6.241Zm45.71-5.956a9.225,9.225,0,0,0-3.784-4.016,9.72,9.72,0,0,0-5.117-1.243,7.743,7.743,0,0,0-4.895,1.422,10.474,10.474,0,0,0-4.33,5.207,9.524,9.524,0,0,1,3.758-6.129l-4.255.009a3,3,0,0,0-2.21.957,3.228,3.228,0,0,0-.937,2.358V51.824h2.864a3.667,3.667,0,0,0,2.767-1.2,3.776,3.776,0,0,0,.951-2.558V37.268a7.88,7.88,0,0,1,1.421-4.845,4.908,4.908,0,0,1,4.157-2.029c2.821.114,4.5,2.284,4.5,5.981v15.45h2.875a3.686,3.686,0,0,0,3.686-3.686V36.273a14.669,14.669,0,0,0-1.454-6.852Zm-61.359-4.358a3.1,3.1,0,0,0-.877,2.248V51.826H224.6a3.417,3.417,0,0,0,3.516-3.534V24.163h-3.551A2.89,2.89,0,0,0,222.419,25.063Zm2.426-10.779a3.391,3.391,0,0,0-3.57,3.354,3.467,3.467,0,0,0,3.57,3.354,3.7,3.7,0,0,0,2.1-.647,4.736,4.736,0,0,1-.721.577,2.69,2.69,0,0,0,1.451.46c.045.006.519.09.306-.242a1.749,1.749,0,0,1-.161-1.613s.058-.065.06-.069a2.882,2.882,0,0,0,.534-1.819A3.29,3.29,0,0,0,224.845,14.284Z'
                  transform='translate(-191.964 -14.283)'
                  fill='#1684fc'
               />
            </g>
            <path
               id='Path_2'
               dataname='Path 2'
               d='M54.152,43.6a13.234,13.234,0,0,0,.747-1.144l.009-.013c.006-.01.01-.022.015-.032.068-.12.13-.224.212-.38l-.03.016a18.17,18.17,0,0,0,2.3-9,17.54,17.54,0,0,0-5.711-13.427,20.3,20.3,0,0,0-14.374-5.305,20.3,20.3,0,0,0-14.374,5.305,17.538,17.538,0,0,0-5.711,13.427,18.322,18.322,0,0,0,2.035,8.613,17.866,17.866,0,0,0,5.677,6.452,19.9,19.9,0,0,0,8.368,3.341,25.886,25.886,0,0,0,4.336.35c.091,0,.176-.009.265-.01.185,0,.362-.014.543-.021q.77-.028,1.511-.1c.134-.014.268-.027.4-.043A20.6,20.6,0,0,0,49.477,48.2a26.821,26.821,0,0,1-4.057,3.239s1.665,1.706,8.166,2.581c.253.034,2.925.5,1.723-1.357C54.992,52.052,50.855,48.594,54.152,43.6Zm-24.437.111A12.513,12.513,0,0,1,23.4,33.777c-.44-7.093,5.505-13.233,13.278-13.713s14.432,4.881,14.871,11.975c.4,6.4-4.414,12.014-11.07,13.4a16.216,16.216,0,0,0,3.1.675s-5.06,1.591-11.83-1.4a14.355,14.355,0,0,1-2.014-.993Zm.334-12.057a1.528,1.528,0,1,0,1.638,1.524,1.584,1.584,0,0,0-1.638-1.524Zm14.866,0a1.528,1.528,0,1,0,1.638,1.524,1.584,1.584,0,0,0-1.638-1.524Zm-7.433,0a1.528,1.528,0,1,0,1.638,1.524,1.584,1.584,0,0,0-1.638-1.524Z'
               transform='translate(0 -0.021)'
               fill='#1684fc'
            />
         </g>
      </svg>
   );
};

export default Logo;

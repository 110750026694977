import { gql } from '@apollo/client';

export const CREATE_QNA_ACCOUNT_MUTATION = gql`
   mutation createQuestionAccountMutation($type: QnAPlatformType!, $metaData: Object!) {
      createQnAAccount(input: { type: $type, metaData: $metaData }) {
         data
         success
         code
         message
      }
   }
`;

export const UPDATE_QNA_ACCOUNT_MUTATION = gql`
   mutation updateQuestionAccountMutation($type: QnAPlatformType!, $metaData: Object!) {
      updateQnAAccount(input: { type: $type, metaData: $metaData }) {
         data
         success
         code
         message
      }
   }
`;

export const DELETE_QNA_ACCOUNT_MUTATION = gql`
   mutation deleteQuestionAccountMutation($_id: ID!, $type: QnAPlatformType!, $deleteType: Int) {
      deleteQnAAccount(input: { _id: $_id, type: $type, deleteType: $deleteType }) {
         data
         success
         code
         message
      }
   }
`;

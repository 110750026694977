import { LanguageContext } from '@helper/locale/langContext';
import { Button, Grid, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
   topBar: {
      borderBottom: '2px solid #f7f7f7',
      paddingBlock: 10,
      paddingInline: 20,
   },
}));

const TopBar = ({ isView, isValid, appId, isLoading, handleSubmit, isSelectedSurvey }: any) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   return (
      <Grid container xs={12} justifyContent='space-between' alignItems='center' className={classes.topBar}>
         <Grid>
            <Grid container alignItems='center'>
               <Link style={{ color: '#424242' }} to={`/settings/platforms/whatsapp-business/templatesPage/${appId}`}>
                  <KeyboardBackspaceIcon
                     fontSize='inherit'
                     style={{ marginRight: 10, cursor: 'pointer', fontSize: 30, paddingTop: 4 }}
                  />
               </Link>
               <Typography align='left' variant='h6' color='inherit'>
                  {
                     t[
                        isView
                           ? 'settings_whatsapp_business_template_view_template'
                           : 'settings_whatsapp_business_template_create_new_template'
                     ]
                  }
               </Typography>
            </Grid>
         </Grid>
         <Grid xs={12} sm={12} md={2}>
            {!isView && (
               <Button
                  disabled={!isValid || isLoading || isSelectedSurvey}
                  startIcon={isLoading ? <CircularProgress size={20} /> : null}
                  onClick={handleSubmit}
                  fullWidth
                  style={{ marginRight: 20 }}
                  color='primary'
                  variant='contained'
               >
                  {t['settings_whatsapp_business_template_submit']}
               </Button>
            )}
         </Grid>
      </Grid>
   );
};

export default TopBar;

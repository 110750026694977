export const english = {
   AF: 'Afghanistan',
   AX: 'Åland Islands',
   AL: 'Albania',
   DZ: 'Algeria',
   AS: 'American Samoa',
   AD: 'AndorrA',
   AO: 'Angola',
   AI: 'Anguilla',
   AQ: 'Antarctica',
   AG: 'Antigua and Barbuda',
   AR: 'Argentina',
   AM: 'Armenia',
   AW: 'Aruba',
   AU: 'Australia',
   AT: 'Austria',
   AZ: 'Azerbaijan',
   BS: 'Bahamas',
   BH: 'Bahrain',
   BD: 'Bangladesh',
   BB: 'Barbados',
   BY: 'Belarus',
   BE: 'Belgium',
   BZ: 'Belize',
   BJ: 'Benin',
   BM: 'Bermuda',
   BT: 'Bhutan',
   BO: 'Bolivia',
   BA: 'Bosnia and Herzegovina',
   BW: 'Botswana',
   BV: 'Bouvet Island',
   BR: 'Brazil',
   IO: 'British Indian Ocean Territory',
   BN: 'Brunei Darussalam',
   BG: 'Bulgaria',
   BF: 'Burkina Faso',
   BI: 'Burundi',
   KH: 'Cambodia',
   CM: 'Cameroon',
   CA: 'Canada',
   CV: 'Cape Verde',
   KY: 'Cayman Islands',
   CF: 'Central African Republic',
   TD: 'Chad',
   CL: 'Chile',
   CN: 'China',
   CX: 'Christmas Island',
   CC: 'Cocos (Keeling) Islands',
   CO: 'Colombia',
   KM: 'Comoros',
   CG: 'Congo',
   CD: 'Congo, The Democratic Republic of the',
   CK: 'Cook Islands',
   CR: 'Costa Rica',
   CI: "Cote D'Ivoire",
   HR: 'Croatia',
   CU: 'Cuba',
   CY: 'Cyprus',
   CZ: 'Czech Republic',
   DK: 'Denmark',
   DJ: 'Djibouti',
   DM: 'Dominica',
   DO: 'Dominican Republic',
   EC: 'Ecuador',
   EG: 'Egypt',
   SV: 'El Salvador',
   GQ: 'Equatorial Guinea',
   ER: 'Eritrea',
   EE: 'Estonia',
   ET: 'Ethiopia',
   FK: 'Falkland Islands (Malvinas)',
   FO: 'Faroe Islands',
   FJ: 'Fiji',
   FI: 'Finland',
   FR: 'France',
   GF: 'French Guiana',
   PF: 'French Polynesia',
   TF: 'French Southern Territories',
   GA: 'Gabon',
   GM: 'Gambia',
   GE: 'Georgia',
   DE: 'Germany',
   GH: 'Ghana',
   GI: 'Gibraltar',
   GR: 'Greece',
   GL: 'Greenland',
   GD: 'Grenada',
   GP: 'Guadeloupe',
   GU: 'Guam',
   GT: 'Guatemala',
   GG: 'Guernsey',
   GN: 'Guinea',
   GW: 'Guinea-Bissau',
   GY: 'Guyana',
   HT: 'Haiti',
   HM: 'Heard Island and Mcdonald Islands',
   VA: 'Holy See (Vatican City State)',
   HN: 'Honduras',
   HK: 'Hong Kong',
   HU: 'Hungary',
   IS: 'Iceland',
   IN: 'India',
   ID: 'Indonesia',
   IR: 'Iran, Islamic Republic Of',
   IQ: 'Iraq',
   IE: 'Ireland',
   IM: 'Isle of Man',
   IL: 'Israel',
   IT: 'Italy',
   JM: 'Jamaica',
   JP: 'Japan',
   JE: 'Jersey',
   JO: 'Jordan',
   KZ: 'Kazakhstan',
   KE: 'Kenya',
   KI: 'Kiribati',
   KP: "Korea, Democratic People'S Republic of",
   KR: 'Korea, Republic of',
   KW: 'Kuwait',
   KG: 'Kyrgyzstan',
   LA: "Lao People'S Democratic Republic",
   LV: 'Latvia',
   LB: 'Lebanon',
   LS: 'Lesotho',
   LR: 'Liberia',
   LY: 'Libyan Arab Jamahiriya',
   LI: 'Liechtenstein',
   LT: 'Lithuania',
   LU: 'Luxembourg',
   MO: 'Macao',
   MK: 'Macedonia, The Former Yugoslav Republic of',
   MG: 'Madagascar',
   MW: 'Malawi',
   MY: 'Malaysia',
   MV: 'Maldives',
   ML: 'Mali',
   MT: 'Malta',
   MH: 'Marshall Islands',
   MQ: 'Martinique',
   MR: 'Mauritania',
   MU: 'Mauritius',
   YT: 'Mayotte',
   MX: 'Mexico',
   FM: 'Micronesia, Federated States of',
   MD: 'Moldova, Republic of',
   MC: 'Monaco',
   MN: 'Mongolia',
   MS: 'Montserrat',
   MA: 'Morocco',
   MZ: 'Mozambique',
   MM: 'Myanmar',
   NA: 'Namibia',
   NR: 'Nauru',
   NP: 'Nepal',
   NL: 'Netherlands',
   AN: 'Netherlands Antilles',
   NC: 'New Caledonia',
   NZ: 'New Zealand',
   NI: 'Nicaragua',
   NE: 'Niger',
   NG: 'Nigeria',
   NU: 'Niue',
   NF: 'Norfolk Island',
   MP: 'Northern Mariana Islands',
   NO: 'Norway',
   OM: 'Oman',
   PK: 'Pakistan',
   PW: 'Palau',
   PS: 'Palestinian Territory, Occupied',
   PA: 'Panama',
   PG: 'Papua New Guinea',
   PY: 'Paraguay',
   PE: 'Peru',
   PH: 'Philippines',
   PN: 'Pitcairn',
   PL: 'Poland',
   PT: 'Portugal',
   PR: 'Puerto Rico',
   QA: 'Qatar',
   RE: 'Reunion',
   RO: 'Romania',
   RU: 'Russian Federation',
   RW: 'RWANDA',
   SH: 'Saint Helena',
   KN: 'Saint Kitts and Nevis',
   LC: 'Saint Lucia',
   PM: 'Saint Pierre and Miquelon',
   VC: 'Saint Vincent and the Grenadines',
   WS: 'Samoa',
   SM: 'San Marino',
   ST: 'Sao Tome and Principe',
   SA: 'Saudi Arabia',
   SN: 'Senegal',
   CS: 'Serbia and Montenegro',
   SC: 'Seychelles',
   SL: 'Sierra Leone',
   SG: 'Singapore',
   SK: 'Slovakia',
   SI: 'Slovenia',
   SB: 'Solomon Islands',
   SO: 'Somalia',
   ZA: 'South Africa',
   GS: 'South Georgia and the South Sandwich Islands',
   ES: 'Spain',
   LK: 'Sri Lanka',
   SD: 'Sudan',
   SR: 'Suriname',
   SJ: 'Svalbard and Jan Mayen',
   SZ: 'Swaziland',
   SE: 'Sweden',
   CH: 'Switzerland',
   SY: 'Syrian Arab Republic',
   TW: 'Taiwan, Province of China',
   TJ: 'Tajikistan',
   TZ: 'Tanzania, United Republic of',
   TH: 'Thailand',
   TL: 'Timor-Leste',
   TG: 'Togo',
   TK: 'Tokelau',
   TO: 'Tonga',
   TT: 'Trinidad and Tobago',
   TN: 'Tunisia',
   TR: 'Turkey',
   TM: 'Turkmenistan',
   TC: 'Turks and Caicos Islands',
   TV: 'Tuvalu',
   UG: 'Uganda',
   UA: 'Ukraine',
   AE: 'United Arab Emirates',
   GB: 'United Kingdom',
   US: 'United States',
   UM: 'United States Minor Outlying Islands',
   UY: 'Uruguay',
   UZ: 'Uzbekistan',
   VU: 'Vanuatu',
   VE: 'Venezuela',
   VN: 'Viet Nam',
   VG: 'Virgin Islands, British',
   VI: 'Virgin Islands, U.S.',
   WF: 'Wallis and Futuna',
   EH: 'Western Sahara',
   YE: 'Yemen',
   ZM: 'Zambia',
   ZW: 'Zimbabwe',
};

export const turkish = {
   BD: 'Bangladeş',
   BE: 'Belçika',
   BF: 'Burkina Faso',
   BG: 'Bulgaristan',
   BA: 'Bosna Hersek',
   BB: 'Barbados',
   WF: 'Wallis ve Futuna',
   BL: 'Saint Barthelemy',
   BM: 'Bermuda',
   BN: 'Brunei',
   BO: 'Bolivya',
   BH: 'Bahreyn',
   BI: 'Burundi',
   BJ: 'Benin',
   BT: 'Butan',
   JM: 'Jamaika',
   BV: 'Bouvet Adası',
   BW: 'Botsvana',
   WS: 'Samoa',
   BQ: 'Bonaire, Saint Eustatius ve Saba',
   BR: 'Brezilya',
   BS: 'Bahamalar',
   JE: 'Jersey',
   BY: 'Beyaz Rusya',
   BZ: 'Belize',
   RU: 'Rusya',
   RW: 'Ruanda',
   RS: 'Sırbistan',
   TL: 'Doğu Timor',
   RE: 'Réunion',
   TM: 'Türkmenistan',
   TJ: 'Tacikistan',
   RO: 'Romanya',
   TK: 'Tokelau',
   GW: 'Gine-Bissau',
   GU: 'Guam',
   GT: 'Guatemala',
   GS: 'Güney Georgia ve Güney Sandviç Adaları',
   GR: 'Yunanistan',
   GQ: 'Ekvator Ginesi',
   GP: 'Guadeloupe',
   JP: 'Japonya',
   GY: 'Guyana',
   GG: 'Guernsey',
   GF: 'Fransız Guyanası',
   GE: 'Gürcistan',
   GD: 'Grenada',
   GB: 'Birleşik Krallık',
   GA: 'Gabon',
   SV: 'El Salvador',
   GN: 'Gine',
   GM: 'Gambiya',
   GL: 'Grönland',
   GI: 'Cebelitarık',
   GH: 'Gana',
   OM: 'Umman',
   TN: 'Tunus',
   JO: 'Ürdün',
   IK: 'Hırvatistan',
   HT: 'Haiti',
   HU: 'Macaristan',
   HK: 'Hong Kong',
   HN: 'Honduras',
   HM: 'Heard Adası ve McDonald Adaları',
   VE: 'Venezüella',
   PR: 'Porto Riko',
   PW: 'Palau',
   PT: 'Portekiz',
   SJ: 'Svalbard',
   PY: 'Paraguay',
   IQ: 'Irak',
   PA: 'Panama',
   PF: 'Fransız Polinezyası',
   PG: 'Papua Yeni Gine',
   PE: 'Peru',
   PK: 'Pakistan',
   PH: 'Filipinler',
   PN: 'Pitcairn Adaları',
   PL: 'Polonya',
   PM: 'Saint Pierre ve Miquelon',
   ZM: 'Zambiya',
   EH: 'Batı Sahra',
   EE: 'Estonya',
   EG: 'Mısır',
   ZA: 'Güney Afrika',
   EC: 'Ekvador',
   IT: 'İtalya',
   VN: 'Vietnam',
   SB: 'Solomon Adaları',
   ET: 'Etiyopya',
   SO: 'Somali',
   ZW: 'Zimbabwe',
   SA: 'Suudi Arabistan',
   ES: 'İspanya',
   ER: 'Eritrea',
   ME: 'Karadağ',
   MD: 'Moldova',
   MG: 'Madagaskar',
   MF: 'Saint Martin',
   MA: 'Fas',
   MC: 'Monaco',
   UZ: 'Özbekistan',
   MM: 'Myanmar',
   ML: 'Mali',
   MO: 'Macao',
   MN: 'Moğolistan',
   MH: 'Marshall Adaları',
   MK: 'Makedonya',
   MU: 'Mauritius',
   MT: 'Malta',
   MW: 'Malavi',
   MV: 'Maldivler',
   MQ: 'Martinik',
   MP: 'Kuzey Mariana Adaları',
   MS: 'Montserrat',
   MR: 'Moritanya',
   IM: 'Isle of Man',
   UG: 'Uganda',
   TZ: 'Tanzanya',
   MY: 'Malezya',
   MX: 'Meksika',
   IL: 'İsrail',
   FR: 'Fransa',
   IO: 'İngiliz Hint Okyanusu Bölgesi',
   SH: 'Saint Helena',
   FI: 'Finlandiya',
   FJ: 'Fiji',
   FK: 'Falkland Adaları',
   FM: 'Mikronezya',
   FO: 'Faroe Adaları',
   NI: 'Nikaragua',
   NL: 'Hollanda',
   NO: 'Norveç',
   NA: 'Namibya',
   VU: 'Vanuatu',
   NC: 'Yeni Kaledonya',
   NE: 'Nijer',
   NF: 'Norfolk Adası',
   NG: 'Nijerya',
   NZ: 'Yeni Zelanda',
   NP: 'Nepal',
   NR: 'Nauru',
   NU: 'Niue',
   CK: 'Cook Adaları',
   XK: 'Kosova',
   CI: 'Fildişi Sahilleri',
   CH: 'İsviçre',
   CO: 'Kolombiya',
   CN: 'Çin',
   CM: 'Kamerun',
   CL: 'Şili',
   CC: 'Cocos Adaları',
   CA: 'Kanada',
   CG: 'Kongo Cumhuriyeti',
   CF: 'Orta Afrika Cumhuriyeti',
   CD: 'Demokratik Kongo Cumhuriyeti',
   CZ: 'Çek Cumhuriyeti',
   CY: 'Kıbrıs',
   CX: 'Noel Adası',
   CR: 'Kosta Rika',
   CW: 'Curacao',
   CV: 'Cape Verde',
   CU: 'Küba',
   SZ: 'Svaziland',
   SY: 'Suriye',
   SX: 'Sint Maarten',
   KG: 'Kırgızistan',
   KE: 'Kenya',
   SS: 'Güney Sudan',
   SR: 'Surinam',
   KI: 'Kiribati',
   KH: 'Kamboçya',
   KN: 'Saint Kitts ve Nevis',
   KM: 'Komor',
   ST: 'Sao Tome ve Principe',
   SK: 'Slovakya',
   KR: 'Güney Kore',
   SI: 'Slovenya',
   KP: 'Kuzey Kore',
   KW: 'Kuveyt',
   SN: 'Senegal',
   SM: 'San Marino',
   SL: 'Sierra Leone',
   SC: 'Seyşel Adaları',
   KZ: 'Kazakistan',
   KY: 'Kayman Adaları',
   SG: 'Singapur',
   SE: 'İsveç',
   SD: 'Sudan',
   DO: 'Dominik Cumhuriyeti',
   DM: 'Dominika',
   DJ: 'Cibuti',
   DK: 'Danimarka',
   VG: 'İngiliz Virgin Adaları',
   DE: 'Almanya',
   YE: 'Yemen',
   DZ: 'Cezayir',
   ABD: 'Amerika Birleşik Devletleri',
   UY: 'Uruguay',
   YT: 'Mayotte',
   UM: 'Birleşik Devletlerin Küçük Dış Adaları',
   LB: 'Lübnan',
   LC: 'Saint Lucia',
   LA: 'Laos',
   TV: 'Tuvalu',
   TW: 'Tayvan',
   TT: 'Trinidad ve Tobago',
   TR: 'Türkiye',
   LK: 'Sri Lanka',
   LI: 'Lihtenştayn',
   AG: 'Letonya',
};

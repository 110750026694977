import { Box, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import Graphics from '@components/Graphics';
import LangSelectBox from '@components/LangSelectBox';
import { LanguageContext } from '@helper/locale/langContext';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';
import ResetPassword from '../ForgotPassword';
import CreatePassword from '../CreatePassword';
import ResetPasswordResult from '../ResetPassword';
import SignIn from '../SignIn';
import SignUp from '../SignUp';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: 'white',
      zIndex: -1,
   },
   graphics: {
      backgroundColor: '#edf5ff',
      minHeight: '100vh',
   },
   paper: {
      margin: theme.spacing(4, 6, 8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   content: {
      maxWidth: '460px',
      marginTop: '10px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
         marginTop: 0,
      },
   },
   leftSide: {
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
         paddingLeft: 0,
      },
   },
}));

const Preload = () => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const classes = useStyles();

   const history = useHistory();

   const location = useLocation();

   return (
      <div>
         <Grid container component='main' className={classes.root}>
            <Grid item xs={12} md={6} className={classes.leftSide}>
               <div style={{ height: '100%' }}>
                  <div className={classes.paper}>
                     <div className={classes.content}>
                        <div
                           style={{
                              marginTop: 20,
                              marginBottom: 50,
                           }}
                        >
                           <div
                              style={{
                                 textAlign: 'center',
                                 display: 'flex',
                                 alignItems: 'center',
                                 justifyContent: 'space-between',
                              }}
                           >
                              {/* <ThreeDRotation color="primary" style={{ fontSize: 70 }} /> */}
                              <img src={'/logo.svg'} width={128} alt='logo' />
                              <Grid
                                 style={{ width: 60, padding: '.15rem', border: '1px solid #F2F2F2', borderRadius: 4 }}
                              >
                                 <LangSelectBox />
                              </Grid>
                           </div>
                           {/* <Typography component="h1" variant="h5">Chatapp</Typography> */}
                        </div>
                        <Switch>
                           <Route path='/forgot-password' component={ResetPassword} />
                           <Route path='/create-password/:token' component={CreatePassword} />
                           <Route path='/reset-password/:token' component={ResetPasswordResult} />
                           <Route path='/sign-up' component={SignUp} />
                           <Route exact path='/sign-in' component={SignIn} />
                           <Redirect to='/sign-in' />
                        </Switch>
                     </div>
                  </div>
               </div>
            </Grid>

            <Box component={Grid} item display={{ xs: 'none', md: 'block' }} md={6} className={classes.graphics}>
               <Typography
                  style={{
                     textAlign: 'center',
                     paddingTop: '2rem',
                     color: '#888888',
                     fontSize: '.85rem',
                     fontWeight: 'bold',
                  }}
               >
                  {location.pathname.includes('sign-in') ? (
                     <>
                        {t?.dont_have_an_account}
                        <span
                           style={{
                              cursor: 'pointer',
                              color: '#1684FC',
                              paddingLeft: '.2rem',
                              fontWeight: 'bold',
                           }}
                           onClick={() => history.push('/sign-up')}
                        >
                           {t?.create_free_account}
                        </span>
                     </>
                  ) : (
                     location.pathname.includes('sign-up') && (
                        <>
                           {t?.already_have_an_account}
                           <span
                              style={{ cursor: 'pointer', color: '#1684FC', paddingLeft: '.2rem' }}
                              onClick={() => history.push('/sign-in')}
                           >
                              {t?.login}
                           </span>
                        </>
                     )
                  )}
               </Typography>
               <Graphics
                  scale={1}
                  title={t['pre_load_sign_in_demo']}
                  text1={t['pre_load_fill_form_text']}
                  text2={t['pre_load_demo_info_text']}
               />
            </Box>
         </Grid>
      </div>
   );
};

export default WithUrlTranslations(React.memo(Preload));

import { useMutation } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { Box, ClickAwayListener, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ColorizeIcon from '@material-ui/icons/Colorize';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import {
   FlowBlockType,
   IFlowBlockField,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { ADD_FLOW_BLOCK_MUTATION, UPDATE_BLOCK_MUTATION } from '@queries/Automation/mutation';
import { Picker } from 'emoji-mart';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Node } from 'reactflow';

interface NoteBlocksProps {
   leftNodes: Node[];
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setIsSaveNote: React.Dispatch<React.SetStateAction<boolean>>;
   isSaveNote: boolean;
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   clickedNodeId: string;
}
const NoteBlocks: React.FC<NoteBlocksProps> = ({
   leftNodes,
   setIsBtnDisable,
   isLoading,
   setIsLoading,
   setIsSaveNote,
   isSaveNote,
   clickedNodeId,
   setContentNodes,
   setLeftNodes,
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);

   const useStyles = makeStyles((theme) => ({
      textInput: {
         marginTop: '16px',
         width: '80%',
      },

      textArea: {
         backgroundColor: '#F7F7FA',
         borderRadius: '5px',
         border: '1px solid #E7E7E7',
         outline: 'none',
         resize: 'none',
         width: '100%',
         minHeight: '80px',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '10px',
         marginBottom: '7px',
         '&:focus': {
            backgroundColor: '#fff',
            border: '2px solid #147CFC',
         },
      },
      textAreaError: {
         backgroundColor: '#F7F7FA',
         borderRadius: '5px',
         border: '1px solid #FF7474',
         outline: 'none',
         resize: 'none',
         width: '100%',
         minHeight: '80px',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '10px',
         marginBottom: '7px',
         '&:focus': {
            backgroundColor: '#fff',
            border: '2px solid #FF7474',
         },
      },
      miniBox: {
         position: 'absolute',
         bottom: -13,
         right: 2,
         width: '18%',
         borderRadius: 7,
         float: 'right',
         display: 'grid',
         gridTemplateColumns: 'repeat(2, 1fr)',
         gap: '1px',
         color: '#fff',
         backgroundColor: 'black',
         padding: '2px 4px',
         cursor: 'pointer',
      },
      colorInputContainer: {
         width: 100 + '%',
         height: 45,
         border: '1px solid #CCC',
         borderRadius: 4,
         marginBottom: '1rem',
         flexDirection: 'row',
         display: 'flex',
         alignItems: 'center',
      },
      selectedColorContainer: {
         width: 50,
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         marginRight: 15,
         borderRight: '1px solid #CCC',
      },
      ColorContainer: {
         width: 30,
         height: 30,
         marginLeft: 4,
         marginRight: 4,
         borderRadius: 6,
         '-webkit-box-shadow': 'inset 0px 0px 0px 2px #FFFFFF',
         boxShadow: 'inset 0px 0px 0px 2px #FFFFFF',
         cursor: 'pointer',
      },
      popover: {
         position: 'absolute',
         zIndex: 2,
      },
      cover: {
         position: 'fixed',
         top: '0px',
         right: '0px',
         bottom: '0px',
         left: '0px',
      },
   }));
   const classes = useStyles();
   const [wordCounts, setWordCounts] = useState<{ [key: string]: number }>({});
   const [activeTextareaId, setActiveTextareaId] = useState<string | null>(null);
   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);
   const [toUpdate, setToUpdate] = useState<{ _id: string; fields: string }[]>([]);
   const [selectedColor, setSelectedColor] = React.useState('#FFF0C4');
   const [showColorPicker, setShowColorPicker] = useState(false);
   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);

   const handleAddFlowBlock = async (flowBlockTemplateId: String, nodeId: string, field: IFlowBlockField[]) => {
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fields: field,
               },
            },
            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block.fields?.length > 0),
                              flowBlocks: [response],
                           },
                        };
                     }
                     return node;
                  });

               // Updating the _id of the first element
               setBlockState((prev) => {
                  const updatedState = [...prev];
                  if (updatedState.length > 0) {
                     updatedState[0]._id = response._id;
                  }
                  return updatedState;
               });

               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));

               setIsLoading(false);
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlock = async (blockId: string, fields: IFlowBlockField[]) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;

               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              isValidNode: blockState.some((block) => block.fields?.length > 0),
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
               setIsLoading(false);
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   useEffect(() => {
      setIsBtnDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item, index) => {
            if (index === 0) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     backgroundColor: selectedColor,
                  })),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   }, [selectedColor]);

   const handleTextChange = (id: string, text: string) => {
      if (blockState[0].fields[0].note !== undefined) {
         setIsBtnDisable(false);
      } else {
         setIsBtnDisable(true);
      }

      calculateWordCount(id, text);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     note: text,
                  })),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };

   const listBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   // default
   useEffect(() => {
      setBlockState([]);
      if (listBlocks.length > 0) {
         const data = listBlocks.map((item: IFlowBlockTemplate) => {
            const value = item.fields[0]?.note;
            if (value) {
               calculateWordCount(item._id, value);
            }
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });
         setBlockState(data);
         setSelectedColor(data[0]?.fields[0].backgroundColor);
      } else {
         // If there are no blocks, add a default item
         const newItem = {
            _id: Date.now().toString(),
            title: '',
            fields: [
               {
                  type: IFlowBlockFieldTypes.Note,
                  note: '',
                  backgroundColor: selectedColor,
               },
            ],
            type: FlowBlockType.Note,
         };
         setBlockState([newItem]);
      }
   }, [clickedNodeId]);

   // Save
   useEffect(() => {
      if (isSaveNote) {
         // Adding Block in the backend
         const fields = [
            {
               type: IFlowBlockFieldTypes.Text,
               note: blockState[0].fields[0].note,
               backgroundColor: blockState[0].fields[0].backgroundColor,
            },
         ];

         if (listBlocks.length <= 0) {
            if (LIST_BLOCK_TEMPLATE) {
               const templateBlockId = LIST_BLOCK_TEMPLATE.find(
                  (item: { type: FlowBlockType }) => item.type === FlowBlockType.Note,
               )?._id;
               if (templateBlockId) {
                  setIsLoading(true);
                  handleAddFlowBlock(templateBlockId, clickedNodeId, fields);
               }
            }
         } else if (listBlocks.length > 0) {
            setIsLoading(true);
            handleUpdateFlowBlock(blockState[0]._id, fields);
         }
         setIsSaveNote(false);
      }
   }, [isSaveNote]);

   useEffect(() => {
      const isInvalid = blockState.some((block) => block.fields.some((field) => Number.isNaN(field.delay_time)));
      if (isInvalid) {
         setIsBtnDisable(true);
      }
   }, [blockState]);

   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         let numRows = 0;
         let consecutiveChars = 0;

         for (let i = 0; i < content.length; i++) {
            if (content[i] !== '\n') {
               consecutiveChars++;
               if (consecutiveChars === 36) {
                  numRows++;
                  consecutiveChars = 0;
               }
            } else {
               consecutiveChars = 0;
            }
         }

         const enters = (content.match(/\n/g) || []).length;

         numRows += enters;

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: numRows + 2, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };

   const divRef = useRef(null);

   const calculateWordCount = (id: string, text: string) => {
      const wordCount = text.length;
      setWordCounts((prevWordCounts) => ({
         ...prevWordCounts,
         [id]: 1500 - wordCount,
      }));
   };
   const addEmoji = (e: any, id: string) => {
      const emoji = e.native;

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               const updatedFields = item.fields.map((field) => {
                  const textAreaValue = field.note || '';
                  const textAreaElement = document.getElementById(`textarea-${id}`) as HTMLTextAreaElement | null;

                  if (textAreaElement) {
                     const start = textAreaElement.selectionStart;
                     const end = textAreaElement.selectionEnd;
                     const updatedValue = textAreaValue.substring(0, start) + emoji + textAreaValue.substring(end);
                     calculateWordCount(id, updatedValue);
                     setToUpdate([{ _id: id, fields: updatedValue }]);
                     return { ...field, note: updatedValue };
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });
         setIsBtnDisable(false);
         return updatedBlockState;
      });
      setShowEmojiPicker(false);
   };

   function hasNegativeValue(obj: { [x: string]: number }) {
      for (let key in obj) {
         if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] < 0) {
               return true;
            }
         }
      }
      return false;
   }

   useEffect(() => {
      const isInvalid = blockState.some((block) =>
         block.fields.some((field) => field.note?.length === 0 || field.note === null || field.note === undefined),
      );
      if (isInvalid || hasNegativeValue(wordCounts)) {
         setIsBtnDisable(true);
      }
   }, [blockState]);

   const Color = ({ color, isSelected }: { color: string; isSelected?: boolean }) => {
      const selected = isSelected ? isSelected : selectedColor === color;
      return (
         <Grid
            onClick={() => setSelectedColor(color)}
            className={classes.ColorContainer}
            style={{ background: color, border: selected ? '1px solid #4681C3' : 'none' }}
         ></Grid>
      );
   };

   return (
      <Grid>
         {blockState.map((item) => (
            <div
               style={{
                  width: '100%',
               }}
            >
               {item.fields.map((field) => {
                  return (
                     <Box
                        style={{
                           width: '100%',
                           marginTop: '-5px',
                        }}
                        key={field.type}
                     >
                        <Typography style={{ fontWeight: 600 }}>{t.automation_note_background}</Typography>
                        <Grid className={classes.colorInputContainer}>
                           <Grid className={classes.selectedColorContainer}>
                              <Color color={selectedColor} isSelected={false} />
                           </Grid>

                           <Color color='#FFF0C4' />
                           <Color color='#E5C6FF' />
                           <Color color='#FFA5E0' />
                           <Color color='#A5D6FF' />
                           <Color color='#CBFFBC' />
                           <Color color='#FFBBAF' />
                           <Color color='#FFFCB7' />
                           <ColorizeIcon
                              style={{ marginLeft: 10, cursor: 'pointer' }}
                              onClick={(e) => setShowColorPicker(true)}
                           />
                           <div style={{ zIndex: 9 }}>
                              {showColorPicker && (
                                 <div className={classes.popover}>
                                    <div
                                       className={classes.cover}
                                       onClick={() => setShowColorPicker(false)}
                                       role='button'
                                       tabIndex={0}
                                       aria-label='Save'
                                    />

                                    <ChromePicker
                                       color={selectedColor}
                                       onChange={(color) => setSelectedColor(color.hex)}
                                       onChangeComplete={(color) => setSelectedColor(color.hex)}
                                    />
                                 </div>
                              )}
                           </div>
                        </Grid>
                        <ClickAwayListener onClickAway={() => setActiveTextareaId(null)}>
                           <Box style={{ position: 'relative' }}>
                              <textarea
                                 id={`textarea-${item._id}`}
                                 key={item._id}
                                 value={field.note}
                                 className={
                                    field.note && field.note?.length > 1500 ? classes.textAreaError : classes.textArea
                                 }
                                 name='rawText'
                                 placeholder={t.atomation_flow_enter_a_question}
                                 onFocus={() => setActiveTextareaId(item._id)}
                                 onClick={() => setActiveTextareaId(item._id)}
                                 onChange={(event) => {
                                    handleTextChange(item._id, event.target.value);
                                 }}
                                 {...calculateTextareaSize(field.note)}
                              />

                              {activeTextareaId && (
                                 <div style={{ position: 'sticky', top: 0, width: '100%' }} ref={divRef}>
                                    <div className={classes.miniBox}>
                                       <EmojiEmotionsIcon
                                          onClick={() => {
                                             setShowEmojiPicker(true);
                                             setActiveTextareaId(item._id);
                                          }}
                                          style={{
                                             display: 'flex',
                                             justifyContent: 'center',
                                             fontSize: '18px',
                                             marginTop: '2px',
                                          }}
                                       />
                                       <span
                                          style={{
                                             color: 'green',
                                             display: 'flex',
                                             justifyContent: 'center',
                                             fontSize: '14px',
                                             marginTop: '2px',
                                          }}
                                       >
                                          {wordCounts[item._id] === 0 ? 0 : wordCounts[item._id] || 1500}
                                       </span>
                                    </div>
                                 </div>
                              )}
                              {showEmojiPicker && activeTextareaId === item._id && (
                                 <ClickAwayListener onClickAway={() => setShowEmojiPicker(false)}>
                                    <div style={{ zIndex: '9999', position: 'sticky' }}>
                                       <Picker
                                          onSelect={(e) => addEmoji(e, item._id)}
                                          style={{ width: '100%' }}
                                          title={t['emoji_selector_title']}
                                       />
                                    </div>
                                 </ClickAwayListener>
                              )}
                           </Box>
                        </ClickAwayListener>
                     </Box>
                  );
               })}
            </div>
         ))}
      </Grid>
   );
};

export default NoteBlocks;

import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Fragment, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';
import Button from '@components/Button';
import Input from '@components/InputField';
import { SIGN_UP_PERSON_MUTATION } from '../../../queries/Person/Service/GraphQL/mutation';

const useStyles = makeStyles((theme) => ({
   primaryLink: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      marginTop: 10,
      fontSize: '0.8rem',
   },
   secondaryLink: {
      color: theme.palette.grey['800'],
   },
   helperText: {
      color: theme.palette.grey['600'],
      paddingTop: '16px',
      margin: '10px',
      fontSize: '0.9rem',
   },
   checkTitle: {
      fontSize: '.80rem',
      paddingLeft: 4,
   },
}));

const SignUp = () => {
   const {
      lang: { translation: t, code: langCode },
   } = useContext(LanguageContext);

   const [errorState, setErrorState] = useState({
      status: false,
      message: '',
   });
   const classes = useStyles();
   const history = useHistory();
   // sign in mutation
   const [signUp, { loading: mutationLoading }] = useMutation(SIGN_UP_PERSON_MUTATION, { fetchPolicy: 'no-cache' });

   const {
      handleSubmit,
      control,
      formState: { isValid },
   } = useForm({
      mode: 'all',
   });

   const signUpHandler = async (variables) => {
      try {
         const signUpData = await signUp({
            variables: {
               ...variables,
               language: langCode === 'tr' ? 'TR' : 'EN',
            },
         });
         history.push(`/sign-up/confirmation?email=${signUpData.data.createCustomer.data.email}`);
      } catch (err) {
         setErrorState({
            status: true,
            message: err.message,
         });
         console.log(err);
         setTimeout(() => {
            setErrorState({
               status: false,
               message: '',
            });
         }, 5000);
      }
   };

   return (
      <Grid>
         {mutationLoading ? <div className='loading' /> : null}
         <Fragment>
            <Typography
               style={{
                  fontSize: '1.45rem',
                  marginBottom: 15,
                  color: 'black',
                  fontWeight: 500,
                  paddingBottom: '.5rem',
                  borderBottom: '1px solid #EBEBEB',
               }}
            >
               {t?.create_your_account}
            </Typography>
            <Grid container>
               <Grid item md={4} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <CheckCircleIcon color='primary' />
                  <Typography className={classes.checkTitle}> {t?.ft_day_free_trial} </Typography>
               </Grid>
               <Grid item md={4} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <CheckCircleIcon color='primary' />
                  <Typography className={classes.checkTitle}> {t?.cancel_anytime} </Typography>
               </Grid>
               <Grid item md={4} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <CheckCircleIcon color='primary' />
                  <Typography className={classes.checkTitle}> {t?.no_credit_card_required} </Typography>
               </Grid>
            </Grid>

            <form onSubmit={handleSubmit(signUpHandler)} className={classes.form}>
               <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                  <Grid item xs={12} md={6}>
                     <Input
                        name='name'
                        control={control}
                        label={t?.name}
                        rules={{
                           required: t?.name_field_is_required,
                           minLength: {
                              value: 2,
                              message: t?.name_must_be_atleast_2_characters,
                           },
                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={6}>
                     <Input
                        name='surname'
                        rules={{
                           required: t?.surname_field_is_required,
                           minLength: {
                              value: 2,
                              message: t?.surname_must_be_atleast_2_characters,
                           },
                        }}
                        control={control}
                        label={t?.page_account_input_profile_surname}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Input
                        name='companyName'
                        control={control}
                        label={t?.company_name}
                        rules={{
                           required: t?.company_name_is_required,
                           minLength: {
                              value: 2,
                              message: t?.company_name_must_be_atleast_2_characters,
                           },
                           maxLength: {
                              value: 40,
                              message: t?.max_40_characters,
                           },
                        }}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Input
                        name='phone'
                        label={t?.page_contacts_typo_phone}
                        isPhone
                        splitCountryCode={true}
                        control={control}
                        rules={{
                           required: t?.phone_is_required,
                        }}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Input
                        name='email'
                        control={control}
                        label={t['pre_load_input_label_email']}
                        rules={{
                           required: t['email_required'],
                           pattern: {
                              value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: t['email_valid'],
                           },
                        }}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Input
                        name='password'
                        label={t['pre_load_input_label_password']}
                        type='password'
                        control={control}
                        rules={{
                           required: t['password_required'],
                           minLength: {
                              value: 6,
                              message: t['password_must_be_6_chars'],
                           },
                        }}
                     />
                  </Grid>

                  {errorState.status && (
                     <Grid xs={12} item>
                        <Typography
                           style={{
                              marginTop: 0,
                              fontSize: '0.9rem',
                              color: 'red',
                           }}
                        >
                           {errorState.message}
                        </Typography>
                     </Grid>
                  )}

                  <Grid item xs={12}>
                     <Button
                        type='submit'
                        label={t?.create_account}
                        style={{ textTransform: 'none' }}
                        isPrimary
                        disabled={!isValid}
                     />
                     <Typography style={{ color: '#888888', fontSize: '.85rem', fontWeight: 300, paddingTop: '.5rem' }}>
                        {t?.by_clicking}
                        <a target='_blank' rel='noopener noreferrer' href='https://www.qpien.com/user-agreement'>
                           {' ' + t?.terms_of_service}
                        </a>
                        {'\n'}
                        {t?.and}
                        <a target='_blank' rel='noopener noreferrer' href='https://www.qpien.com/privacy-policy'>
                           {'\n' + t?.privacy_policy}
                        </a>
                        {langCode === 'tr' && ' ' + t?.to_be_accepted}
                     </Typography>
                  </Grid>
               </Grid>
            </form>
         </Fragment>
      </Grid>
   );
};

export default SignUp;

import React, { FC, Fragment, useContext, useState, useEffect } from 'react';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Typography } from '@material-ui/core';
import { LanguageContext } from '../../../../../../../../helper/locale/langContext';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { ICustomerInfoProps, ITagProps } from '../types';
import MergeSplitModal from './MergeSplitModal';
import Input from '../../../../../../../../components/InputField';
import ButtonCancel from '../../../../../../../../components/ButtonCancel/buttonCancel';
import ButtonOK from '../../../../../../../../components/ButtonOK/buttonOk';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
   LINK_E_COMMERCE_CUSTOMER_MUTATION,
   UNLINK_E_COMMERCE_CUSTOMER_MUTATION,
   UPDATE_ECOMMERCE_CUSTOMER_MUTATION,
} from '@queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import { splitNumber } from '@helper/functions';
import { Save } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { getPerson } from '@store/reducers/person';
import { getActiveChat } from '@store/reducers/chat';
import ECommerceTabHeaderContext from '../../../../../../../../contexts/ECommerceTabHeaderContext';
import SearchSelect from 'react-select';
import InputLabel from '@material-ui/core/InputLabel';
import { LOCATIONS } from '@queries/Locations/query';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';

const useStyles = makeStyles(() => ({
   container: {
      padding: 8,
   },
   formInputRow: {
      display: 'flex',
      marginTop: 5,
      marginBottom: 5,
   },
   inlineFormInputFirst: {
      marginRight: 5,
   },
   inlineFormInputLast: {
      marginLeft: 5,
   },
   itemContainer: {
      paddingTop: 2,
      paddingBottom: 2,
      marginTop: 4,
   },
   labelContainer: {
      borderBottom: '1px solid #D9E0E6',
      marginBottom: 8,
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      '& .MuiFormGroup-root': {
         display: 'block !important',
         flexWrap: 'none !important',
         flexDirection: 'none !important',
      },
   },
   label: {
      color: '#385273',
      fontSize: '1em',
      marginBottom: 10,
   },
   inputFix: {
      paddingLeft: 5,
      '& .MuiInputBase-root': {
         height: 38,
         marginTop: '-8px',
      },
   },
}));

interface CustomerInfoProps {
   // customer: ICustomerInfoProps;
   customer: any;
   onCustomerSplit?: (() => Promise<any>) | undefined;
   platformCustomerId: number | string;
}

const CustomerInfo: FC<CustomerInfoProps> = ({ customer, onCustomerSplit, platformCustomerId }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);

   //@ts-ignore
   const me = useSelector(getPerson);
   let lang_code = me?.customer?.language;
   const t: any = lang?.translation;
   const [editModeActivated, setEditModeActivated] = useState(false);
   const [isCustomerMerged, setCustomerMerged] = useState<boolean>(customer._id == platformCustomerId);
   const [visibleModal, setVisibleModal] = useState<'merge' | 'split' | null>(null);
   const [updateCustomerMutation, { loading: updateCustomerLoading }] = useMutation(UPDATE_ECOMMERCE_CUSTOMER_MUTATION);
   const eCommerceType = useSelector((rootReducer: any) => rootReducer?.eCommerceReducer?.eCommerceType);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const activeChatPerson = useSelector(getActiveChat).participants[0]._id;
   const headerContext = useContext(ECommerceTabHeaderContext);

   const defaultAddressObject =
      customer.addresses.find((address: any) => address['default'] === true) || customer.addresses[0] || [];
   const savedAddressSelectDefaultOption = {
      value: defaultAddressObject?.id,
      label: defaultAddressObject?.address,
      address: defaultAddressObject,
   };
   const savedAddressSelectOptions = [
      savedAddressSelectDefaultOption,
      ...customer.addresses
         .filter((e: any) => !e['default'])
         ?.map((address: any) => ({
            value: address?.id,
            label: address.address || '-',
            address: address,
         })),
   ];

   const closeModal = () => {
      setVisibleModal(null);
   };

   const [linkECommerceCustomer, { loading: linkLoading }] = useMutation(LINK_E_COMMERCE_CUSTOMER_MUTATION);
   const [unlinkECommerceCustomer, { loading: unlinkLoading }] = useMutation(UNLINK_E_COMMERCE_CUSTOMER_MUTATION);

   const mergeCustomer = () => {
      linkECommerceCustomer({
         variables: {
            metaData: {
               customerId: me.customer._id,
               person: activeChatPerson,
               email: customer.email,
               phone: customer.phone,
               eCommerceCustomer: customer._id || customer?.platformCustomerId,
            },
         },
      })
         .then(() => {
            setCustomerMerged(true);
         })
         .catch(() => {
            setCustomerMerged(false);
         })
         .finally(() => {
            closeModal();
         });
   };

   const splitCustomer = () => {
      unlinkECommerceCustomer({
         variables: {
            metaData: {
               customerId: me.customer._id,
               person: activeChatPerson,
               eCommerceCustomer: customer._id || customer?.platformCustomerId,
            },
         },
      })
         .then(() => {
            setCustomerMerged(false);
            if (onCustomerSplit) {
               onCustomerSplit();
               if (headerContext?.onGoBackClick) {
                  // eslint-disable-next-line
                  headerContext?.onGoBackClick();
               }
            }
         })
         .catch(() => {
            setCustomerMerged(true);
         })
         .finally(() => {
            closeModal();
         });
   };
   const {
      watch,
      control,
      register,
      reset,
      setValue,
      handleSubmit,
      formState: { isValid, isDirty, errors },
   } = useForm({
      mode: 'all',
      defaultValues: {
         sameAsAbove: false,
         first_name: customer.firstName || '',
         last_name: customer.lastName || '',
         email: customer.email || '',
         phone: customer.phone || '',
         savedAddresses: savedAddressSelectDefaultOption,
         first_nameShipping: savedAddressSelectDefaultOption.address.first_name || '',
         last_nameShipping: savedAddressSelectDefaultOption.address.last_name || '',
         phoneShipping: savedAddressSelectDefaultOption.address.phone || '',
         addressShipping: savedAddressSelectDefaultOption.address.address || '',
         countryShipping: {
            value: 0,
            label: '',
         },
         cityShipping: {
            value: savedAddressSelectDefaultOption.address.city || 0,
            label: savedAddressSelectDefaultOption.address.city || '',
         },
         stateShipping: {
            value: savedAddressSelectDefaultOption.address.state || 0,
            label: savedAddressSelectDefaultOption.address.state || '',
         },
         districtShipping: savedAddressSelectDefaultOption.address.district || '',
         zipShipping: savedAddressSelectDefaultOption.address.zip || '',
         identityNumberShipping: savedAddressSelectDefaultOption.address.identity_number || '',
         shippingAddress: `${savedAddressSelectDefaultOption.address.first_name || '-'} ${
            savedAddressSelectDefaultOption.address.last_name || '-'
         }
${savedAddressSelectDefaultOption.address.address || '-'}
${savedAddressSelectDefaultOption.address.city || '-'} ${savedAddressSelectDefaultOption.address.state || '-'} ${
            savedAddressSelectDefaultOption.address.zip || '-'
         }
${savedAddressSelectDefaultOption.address.country || '-'},
${savedAddressSelectDefaultOption.address.district || '-'}
${savedAddressSelectDefaultOption.address.phone || '-'}`,
         note: customer.note || '',
         tags:
            customer.tags.map((tag: any) => ({
               value: tag,
               label: tag,
            })) || [],
      },
   });

   const watchSelectedSavedAddress = watch('savedAddresses');

   const onSubmit = async (data: any) => {
      const { value, address } = watchSelectedSavedAddress;
      let variables = {
         type: customer.eCommercePlatformType,
         metaData: {
            _id: customer._id,
            customerId: me.customer._id,
            firstName: data.first_name,
            lastName: data.last_name,
            email: data.email,
            phone: !data.phone.includes('+') ? '+' + data.phone : data.phone,
            addresses: [
               ...customer.addresses.map((e: any, i: number) => {
                  if (e.id === value) {
                     return {
                        ...e,
                        first_name: data.sameAsAbove ? data.first_name : data.first_nameShipping,
                        last_name: data.sameAsAbove ? data.last_name : data.last_nameShipping,
                        phone: data.sameAsAbove
                           ? !data.phoneShipping.includes('+')
                              ? '+' + data.phoneShipping
                              : data.phone
                           : data.phoneShipping
                           ? !data.phoneShipping.includes('+')
                              ? '+' + data.phoneShipping
                              : data.phoneShipping
                           : '',
                        address: data.addressShipping,
                        country: data.countryShipping.value,
                        city: data.cityShipping.value,
                        state: data.stateShipping.value,
                        district: data.districtShipping,
                        zip: data.zipShipping,
                        identityNumber: data.identityNumberShipping,
                     };
                  }
                  return e;
               }),
               ...((customer.addresses.length === 0 && [
                  {
                     first_name: data.sameAsAbove ? data.first_name : data.first_nameShipping,
                     last_name: data.sameAsAbove ? data.last_name : data.last_nameShipping,
                     phone: data.sameAsAbove
                        ? customer.eCommercePlatformType !== 'TSOFT'
                           ? '+' + data.phoneShipping
                           : data.phone
                        : data.phoneShipping
                        ? customer.eCommercePlatformType !== 'TSOFT'
                           ? '+' + data.phoneShipping
                           : data.phoneShipping
                        : '',
                     address: data.addressShipping,
                     country: data.countryShipping.value,
                     city: data.cityShipping.value,
                     state: data.stateShipping.value,
                     district: data.districtShipping,
                     zip: data.zipShipping,
                     identityNumber: data.identityNumberShipping,
                  },
               ]) ||
                  []),
            ],
            note: data.note,
            tags: data.tags?.map((tag: any) => tag.value) || [],
         },
      };

      updateCustomerMutation({
         variables,
      })
         .then((e) => {
            setEditModeActivated(false);
         })
         .catch((error) => {
            enqueueSnackbar(t[error.message] || error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {t['general_message_button_close']}
                  </div>
               ),
            });
         });
   };

   const [getCountry, { data: countryData, loading: countryLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'country',
         id: 0,
      },
   });
   const [getState, { data: stateData, loading: stateLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'state',
         id: watch('countryShipping.value'),
      },
   });
   const [getCity, { data: cityData, loading: cityLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'city',
         id: watch('stateShipping.value'),
      },
   });
   useEffect(() => {
      if (countryData) {
         if (countryData.locations.data.length === 0) {
            setValue('countryShipping', {
               value: 0,
               label: '',
            });
         } else {
            let country = countryData.locations.data.find(
               (country: any) =>
                  country.name === savedAddressSelectDefaultOption.address.country ||
                  Object.values(country.translations).includes(savedAddressSelectDefaultOption.address.country),
            );

            if (country) {
               setValue('countryShipping', {
                  value: country.id,
                  label: country.name,
               });
            } else {
               setValue('countryShipping', {
                  value: countryData.locations.data[lang_code === 'TR' ? 227 : 235].id,
                  label: countryData.locations.data[lang_code === 'TR' ? 227 : 235].name,
               });
            }
         }
      }
      getCountry();
   }, [countryData]);

   useEffect(() => {
      if (stateData) {
         if (stateData.locations.data.length === 0) {
            setValue('stateShipping', {
               value: 0,
               label: '',
            });
         } else {
            let state = stateData.locations.data.find(
               (state: any) => state.name === savedAddressSelectDefaultOption.address.city,
            );
            if (state) {
               setValue('stateShipping', {
                  value: state.id,
                  label: state.name,
               });
            } else {
               setValue('stateShipping', {
                  value:
                     watch('countryShipping.value') === 225
                        ? stateData.locations.data[39].id
                        : stateData.locations.data[0].id,
                  label:
                     watch('countryShipping.value') === 225
                        ? stateData.locations.data[39].name
                        : stateData.locations.data[0].name,
               });
            }
         }
      }
      getState();
   }, [stateData]);

   useEffect(() => {
      if (cityData) {
         if (cityData.locations.data.length === 0) {
            setValue('cityShipping', {
               value: 0,
               label: '',
            });
         } else {
            let city = cityData.locations.data.find(
               (city: any) => city.name === savedAddressSelectDefaultOption.address.state,
            );

            if (city) {
               setValue('cityShipping', {
                  value: city.id,
                  label: city.name,
               });
            } else {
               setValue('cityShipping', {
                  value: cityData.locations.data[0].id,
                  label: cityData.locations.data[0].name,
               });
            }
         }
      }
      getCity();
   }, [cityData]);

   //!

   useEffect(() => {
      const { value, address } = watchSelectedSavedAddress;
      setValue('first_nameShipping', address?.first_name || '');
      setValue('last_nameShipping', address?.last_name || '');
      setValue('phoneShipping', address?.phone || '');
      setValue('addressShipping', address?.address || '');
      //setValue('countryShipping', address?.country || '');
      setValue('districtShipping', address?.district || '');
      //setValue('cityShipping', address?.city || '');
      //setValue('stateShipping', address?.state || '');
      setValue('zipShipping', address?.zip || '');
      setValue('sameAsAbove', false);
   }, [watchSelectedSavedAddress]);

   return (
      <div className={classes.container}>
         <MergeSplitModal
            isVisible={visibleModal === 'merge'}
            actionType={'merge'}
            loading={linkLoading}
            onCancel={closeModal}
            onSubmit={mergeCustomer}
         />
         <MergeSplitModal
            isVisible={visibleModal === 'split'}
            actionType={'split'}
            loading={unlinkLoading}
            onCancel={closeModal}
            onSubmit={splitCustomer}
         />
         <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container xs={12}>
               <Grid container item xs={12} justify='space-between' className={classes.itemContainer}>
                  <Grid item xs={6} style={{ paddingRight: 5 }}>
                     {/* First Name Input */}
                     <Input
                        label={t['page_e_commerce_customer_typo_first_name']}
                        name='first_name'
                        disabled={!editModeActivated}
                        control={control}
                     />
                  </Grid>
                  <Grid item xs={6} style={{ paddingLeft: 5 }}>
                     {/* Last Name Input */}
                     <Input
                        label={t['page_e_commerce_customer_typo_last_name']}
                        name='last_name'
                        disabled={!editModeActivated}
                        control={control}
                     />
                  </Grid>
               </Grid>
               <Grid item xs={12} className={classes.itemContainer}>
                  {/* E-mail Input  */}
                  <Input
                     label={t['page_e_commerce_customer_typo_email']}
                     name='email'
                     rules={{
                        pattern: {
                           value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                           message: t?.must_be_a_valid_email,
                        },
                        required: t?.required,
                     }}
                     control={control}
                     disabled={!editModeActivated}
                  />
               </Grid>
               <Grid item xs={12} className={classes.itemContainer}>
                  {/* Phone Input */}
                  <Input
                     label={t['page_e_commerce_customer_typo_phone']}
                     disabled={!editModeActivated}
                     name='phone'
                     control={control}
                     isPhone
                     splitCountryCode={true}
                  />
               </Grid>
               {/* Shipping Address Input */}
               {editModeActivated && customer.eCommercePlatformType !== 'FAPRIKA' ? (
                  <Fragment>
                     <div className={classes.labelContainer}>
                        <Typography className={classes.label}>
                           {t['page_e_commerce_customer_shipping_address']}
                        </Typography>
                        <FormGroup>
                           <FormControlLabel
                              control={<Checkbox {...register('sameAsAbove')} size='small' color='primary' />}
                              label={t?.page_e_commerce_customer_same_as_above}
                           />
                        </FormGroup>
                     </div>
                     <Grid item xs={12} className={classes.itemContainer}>
                        {/* Saved Addresses Shipping Input */}
                        <Input
                           label={t['page_e_commerce_customer_saved_address']}
                           name='savedAddresses'
                           disabled={!editModeActivated}
                           control={control}
                           isSelect={true}
                           selectOptions={savedAddressSelectOptions}
                           notDisplay={savedAddressSelectOptions.length === 1}
                        />
                     </Grid>
                     {!watch('sameAsAbove') && (
                        <div>
                           <Grid container item xs={12} justify='space-between' className={classes.itemContainer}>
                              <Grid item xs={6} style={{ paddingRight: 5 }}>
                                 {/* First Name Shipping Input */}
                                 <Input
                                    label={t['page_e_commerce_customer_typo_first_name']}
                                    name='first_nameShipping'
                                    disabled={!editModeActivated}
                                    control={control}
                                 />
                              </Grid>
                              <Grid item xs={6} style={{ paddingLeft: 5 }}>
                                 {/* Last Name Shipping Input */}
                                 <Input
                                    label={t['page_e_commerce_customer_typo_last_name']}
                                    name='last_nameShipping'
                                    disabled={!editModeActivated}
                                    control={control}
                                 />
                              </Grid>
                           </Grid>
                           <Grid item xs={12} className={classes.itemContainer}>
                              {/* Phone Shipping Input */}
                              <Input
                                 label={t['page_e_commerce_customer_typo_phone']}
                                 disabled={!editModeActivated}
                                 name='phoneShipping'
                                 control={control}
                                 isPhone
                                 splitCountryCode={true}
                              />
                           </Grid>
                        </div>
                     )}
                     <Grid item xs={12} className={classes.itemContainer}>
                        {/* Address 1 Shipping Input */}
                        <Input
                           label={t['page_e_commerce_customer_address_1']}
                           name='addressShipping'
                           disabled={!editModeActivated}
                           control={control}
                           multiline
                           rows={3}
                           {...(customer.eCommercePlatformType === 'TSOFT' && {
                              rules: {
                                 //patern
                                 pattern: {
                                    value: /(?=.{10})(?=.*[0-9])./,
                                    message: t?.must_be_a_valid_address,
                                 },
                                 required: t?.required,
                              },
                           })}
                           {...(customer.eCommercePlatformType === 'TSOFT' && {
                              helperText: t.address_helper_text,
                           })}
                        />
                     </Grid>
                     <Grid container item xs={12} justify='space-between' className={classes.itemContainer}>
                        <Grid item xs={6} style={{ paddingRight: 5 }}>
                           {/* Country Shipping Input */}
                           <InputLabel
                              shrink={false}
                              style={{ marginBottom: 8, color: errors?.countryShipping && 'red' }}
                           >
                              {t?.page_e_commerce_customer_country}
                           </InputLabel>
                           <Controller
                              name='countryShipping'
                              control={control}
                              rules={{
                                 required: true,
                                 validate: (value: any) => {
                                    if (value.value) {
                                       return true;
                                    }
                                    return false;
                                 },
                              }}
                              render={() => (
                                 <SearchSelect
                                    isDisabled={!editModeActivated || countryLoading}
                                    options={countryData?.locations?.data?.map(({ name, id }: any) => ({
                                       value: id,
                                       label: name,
                                    }))}
                                    value={watch('countryShipping')}
                                    onChange={(value: any) => {
                                       setValue('countryShipping', value);
                                    }}
                                 />
                              )}
                           />
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 5 }}>
                           {/* City Shipping Input */}
                           <Input
                              isDisabled={!editModeActivated || stateLoading}
                              selectOptions={stateData?.locations?.data?.map(({ name, id }: any) => ({
                                 value: id,
                                 label: name,
                              }))}
                              label={t?.city}
                              value={watch('stateShipping')}
                              isCreatable={customer.eCommercePlatformType === 'SHOPIFY'}
                              isSelect={customer.eCommercePlatformType !== 'SHOPIFY'}
                              name='stateShipping'
                              control={control}
                              rules={{
                                 required: true,
                                 validate: (value: any) => {
                                    if (value.value) {
                                       return true;
                                    }
                                    return false;
                                 },
                              }}
                           />
                        </Grid>
                     </Grid>
                     <Grid container item xs={12} justify='space-between' className={classes.itemContainer}>
                        <Grid
                           item
                           xs={
                              customer.eCommercePlatformType !== 'IDEASOFT' &&
                              customer.eCommercePlatformType !== 'TICIMAX'
                                 ? 6
                                 : 12
                           }
                           style={{ paddingRight: 5 }}
                        >
                           <Input
                              isDisabled={!editModeActivated || cityLoading}
                              selectOptions={cityData?.locations?.data?.map(({ name, id }: any) => ({
                                 value: id,
                                 label: name,
                              }))}
                              label={t?.state}
                              value={watch('cityShipping')}
                              isCreatable={customer.eCommercePlatformType === 'SHOPIFY'}
                              isSelect={customer.eCommercePlatformType !== 'SHOPIFY'}
                              name='cityShipping'
                              control={control}
                              rules={{
                                 required: true,
                                 validate: (value: any) => {
                                    if (value.value) {
                                       return true;
                                    }
                                    return false;
                                 },
                              }}
                           />
                        </Grid>
                        {(customer.eCommercePlatformType !== 'TSOFT' &&
                           customer.eCommercePlatformType !== 'IDEASOFT' &&
                           customer.eCommercePlatformType !== 'TICIMAX' && (
                              <Grid item xs={6} style={{ paddingLeft: 5 }}>
                                 {/* Zip Code Shipping Input */}
                                 <Input
                                    label={t['page_e_commerce_customer_zip_code']}
                                    name='zipShipping'
                                    disabled={!editModeActivated}
                                    control={control}
                                 />
                              </Grid>
                           )) ||
                           (customer.eCommercePlatformType === 'TSOFT' && (
                              <Grid item xs={6} className={classes.inputFix}>
                                 {/* District Shipping Input */}
                                 <InputLabel shrink={false}>{t?.page_e_commerce_customer_district}</InputLabel>
                                 <Input
                                    name='districtShipping'
                                    placeholder={t?.page_e_commerce_customer_district}
                                    disabled={!editModeActivated}
                                    control={control}
                                 />
                              </Grid>
                           ))}
                     </Grid>
                     {(customer.eCommercePlatformType === 'TSOFT' || customer.eCommercePlatformType === 'IDEASOFT') && (
                        <Grid item xs={12} className={classes.itemContainer}>
                           {/* TSOFT Customer Identity Number Input */}
                           <Input
                              label={t['page_e_commerce_customer_identity_number']}
                              name='identityNumberShipping'
                              disabled={!editModeActivated}
                              control={control}
                              rules={{
                                 required: t?.required,
                                 minLength: {
                                    value: 11,
                                    message: t?.must_be_11_characters,
                                 },
                                 maxLength: {
                                    value: 11,
                                    message: t?.must_be_11_characters,
                                 },
                              }}
                              {...(customer.eCommercePlatformType === 'TSOFT' && {
                                 helperText: t.identity_number_helper_text,
                              })}
                           />
                        </Grid>
                     )}
                  </Fragment>
               ) : (
                  customer.eCommercePlatformType !== 'FAPRIKA' && (
                     <Grid item xs={12} className={classes.itemContainer}>
                        <Input
                           label={t['page_e_commerce_customer_shipping_address']}
                           name='shippingAddress'
                           multiline
                           disabled={!editModeActivated}
                           control={control}
                           rows={5}
                        />
                     </Grid>
                  )
               )}

               {/* Customer Note Input */}
               {customer.eCommercePlatformType !== E_COMMERCE_PLATFORM_TYPE.TSOFT &&
                  customer.eCommercePlatformType !== E_COMMERCE_PLATFORM_TYPE.IDEASOFT &&
                  customer.eCommercePlatformType !== E_COMMERCE_PLATFORM_TYPE.FAPRIKA &&
                  customer.eCommercePlatformType !== E_COMMERCE_PLATFORM_TYPE.TICIMAX &&
                  customer.eCommercePlatformType !== E_COMMERCE_PLATFORM_TYPE.IKAS && (
                     <>
                        <Grid item xs={12} className={classes.itemContainer}>
                           <Input
                              label={t['page_e_commerce_customer_customer_note']}
                              name='note'
                              multiline
                              disabled={!editModeActivated}
                              height={30}
                              control={control}
                              rows={2}
                           />
                        </Grid>
                        <Grid item xs={12} className={classes.itemContainer}>
                           {/* Tags Input */}
                           <Input
                              label={t['page_e_commerce_customer_tags']}
                              name='tags'
                              disabled={!editModeActivated}
                              control={control}
                              isMulti={true}
                              isCreatable={true}
                              autoHeight
                              selectOptions={customer.tags.map((tag: any) => {
                                 return { value: tag, label: tag };
                              })}
                           />
                        </Grid>
                     </>
                  )}
               <Grid container>
                  {editModeActivated ? (
                     <Grid
                        container
                        xs={12}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems='center'
                        style={{ gap: 8 }}
                     >
                        <ButtonCancel
                           onClick={() => {
                              setEditModeActivated(false);
                              reset();
                           }}
                           label={t['page_chat_btn_filter_cancel']}
                           height={35}
                           style={{ flexGrow: 1 }}
                        />
                        <ButtonOK
                           label={t['page_chat_typo_profile_save']}
                           style={{ flexGrow: 1 }}
                           height={35}
                           // type='submit'
                           endIcon={
                              updateCustomerLoading ? (
                                 <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                              ) : (
                                 <Save />
                              )
                           }
                           onClick={handleSubmit(onSubmit)}
                           disabled={!isValid || !isDirty || updateCustomerLoading}
                        />
                     </Grid>
                  ) : (
                     <Grid
                        container
                        xs={12}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems='center'
                        style={{ gap: 8 }}
                     >
                        <ButtonOK
                           onClick={() => setVisibleModal(isCustomerMerged ? 'split' : 'merge')}
                           label={
                              isCustomerMerged
                                 ? t['page_e_commerce_customer_split']
                                 : t['page_e_commerce_customer_merge']
                           }
                           height={35}
                           fontSize='1.1rem'
                           style={{
                              flexGrow: 1,
                              borderRadius: 5,
                              border: '1px solid #10223A',
                              backgroundColor: isCustomerMerged ? '#FFF' : '#10223A',
                              color: isCustomerMerged ? '#10223A' : '#FFF',
                           }}
                        />
                        <ButtonOK
                           style={{ flexGrow: 1 }}
                           onClick={() => setEditModeActivated(true)}
                           label={t['page_chat_typo_profile_edit']}
                           height={35}
                           fontSize='1.1rem'
                           disabled={
                              customer.eCommercePlatformType === E_COMMERCE_PLATFORM_TYPE.IKAS ||
                              customer.eCommercePlatformType === E_COMMERCE_PLATFORM_TYPE.FAPRIKA
                           }
                        />
                     </Grid>
                  )}
               </Grid>
            </Grid>
         </form>
      </div>
   );
};

export default CustomerInfo;

import { Avatar, Divider, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { SITE_URL } from '@helper/config';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Input from '@components/InputField';
import { USERS_TYPE } from '../../../Chat/types/index';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 600,
      padding: 20,
      paddingBottom: 5,
      borderRadius: 15,
      height: 550,
      textAlign: 'center',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   modalTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.3rem',
      marginBottom: 10,
   },
   textColor: {
      fontSize: '1.2rem',
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
   },
   personTypeTitle: {
      fontSize: '1.2rem',
      fontWeight: 'normal',
      marginTop: theme.spacing(2),
   },
}));
const ResetPasswordModal = ({ person, onAccept, onCancel }) => {
   const classes = useStyles();
   //state
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { handleSubmit, control, watch } = useForm({
      mode: 'all',
      defaultValues: {
         _id: person?._id,
         password: '',
         passwordConfirm: '',
      },
   });
   const password = useRef({});
   password.current = watch('password', '');
   // Submit Form
   const onSubmit = (data) => {
      onAccept(data);
   };
   return (
      <Grid container xs='auto'>
         <Paper elevation={3} className={classes.root}>
            <form onSubmit={handleSubmit(onSubmit)}>
               <Typography className={classes.modalTitle}>{t?.user_reset_password}</Typography>
               <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
               <Grid container xs={12}>
                  {/* Person Avatar */}
                  <Grid
                     container
                     direction='column'
                     className={classes.textColor}
                     style={{ padding: 10, paddingLeft: 20 }}
                     justifyContent='flex-start'
                     wrap='nowrap'
                     alignItems='center'
                  >
                     <Grid item>
                        <Avatar
                           alt={person?.name}
                           src={person?.photo ? person?.photo : SITE_URL + '/none.jpg'}
                           style={{ width: 100, height: 100 }}
                        />
                     </Grid>
                     <Typography className={classes.textColor} noWrap style={{ height: 24 }}>
                        {person?.name + ' ' + person?.surname}
                     </Typography>
                     <Typography className={classes.personTypeTitle}>
                        {person?.type === USERS_TYPE.PERSONEL && t?.employee}
                        {person?.type === USERS_TYPE.ADMIN && t?.admin}
                        {person?.type === USERS_TYPE.OWNER && t?.owner}
                     </Typography>
                  </Grid>
                  <Divider light style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />

                  {/* Input Area */}
                  <Grid container style={{ marginTop: 10 }}>
                     <Grid item xs={6} style={{ paddingRight: 5 }}>
                        <Input
                           name='password'
                           type='password'
                           password={true}
                           required={false}
                           control={control}
                           label={t['pre_load_input_label_password']}
                           editable
                           height={40}
                           rules={{
                              required: t?.please_fill_this_field,
                              minLength: {
                                 value: 6,
                                 message: t?.password_must_be_6_chars,
                              },
                           }}
                        />
                     </Grid>
                     <Grid item xs={6} style={{ paddingLeft: 10 }}>
                        <Input
                           name='passwordConfirm'
                           type='password'
                           label={t['page_account_input_profile_repeat_new_password']}
                           labelSize='small'
                           required={false}
                           rules={{
                              validate: (value) =>
                                 value === password.current || t?.page_account_typo_error_form_message_dont_match,
                              required: t?.please_fill_this_field,
                           }}
                           editable
                           control={control}
                           height={40}
                        />
                     </Grid>
                  </Grid>

                  <Divider style={{ width: '100%', marginTop: 10, marginBottom: 10 }} />
                  {/* // Buttons */}
                  <Grid item container justifyContent='space-between' style={{ marginTop: 40 }}>
                     <ButtonCancel
                        width={200}
                        height={50}
                        onClick={onCancel}
                        fontSize='1.1rem'
                        label={t['comp_button_cancel']}
                     />
                     <ButtonOK width={200} type='submit' height={50} fontSize='1.1rem' label={t['comp_button_save']} />
                  </Grid>
               </Grid>
            </form>
         </Paper>
      </Grid>
   );
};

export default ResetPasswordModal;

import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { PieChart as PieChartCore, Cell, Pie, Sector } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
const renderActiveShape = (props) => {
   const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, payload } = props;
   return (
      <g>
         <text x={cx} y={cy} dy={8} textAnchor='middle' fontWeight='bold' fontSize='.9rem' fill='#385273'>
            {' '}
            {payload.title}
         </text>
         <Sector
            cx={cx}
            cy={cy}
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            fill={payload.color}
         />
      </g>
   );
};

const useStyles = makeStyles((theme) => ({
   title: {
      color: theme.chatApp.workspace.sideBar.backgroundColor,
   },
   textColor: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   titleText: {
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   valueText: {
      fontSize: '1.2rem',
      marginLeft: '5px',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
}));

const PieChart = ({ value, totalValue, color, percent }) => {
   const classes = useStyles();

   const data = [
      {
         value,
         title: '%' + percent,
         color,
      },
      {
         value:
            totalValue > 0
               ? totalValue - value === 0
                  ? 0
                  : totalValue - value
               : totalValue - value === 0
               ? 1
               : totalValue - value,
         title: 'Other',
         color: '#D3D3D3',
      },
   ];

   return (
      <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
         <PieChartCore width={70} height={70}>
            <Pie
               activeShape={renderActiveShape}
               activeIndex={0}
               data={data}
               //cx={25}
               //cy={45}
               innerRadius={24}
               outerRadius={30}
               dataKey='value'
            >
               {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
               ))}
            </Pie>
         </PieChartCore>
         <Grid item>
            <Grid container>
               <Typography className={clsx(classes.title, classes.valueText)}> {value}</Typography>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default PieChart;

import {
   FormControlLabel,
   Grid,
   IconButton,
   Menu,
   MenuItem,
   Table,
   TableBody,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import MyTypography from '@components/MyTypography';
import CustomSwitch from '@components/Switch';
import AvatarTable from '../shared-components/AvatarTable';
import { StyledTableCell, StyledTableRow } from '../shared-components/tableItems';
import { PlatformStatus } from '../types';
import DeletePlatformModal from '@components/Modal/DeletePlatformModal';
import ActionIconButton from '@modules/Settings/SettingSpace/ActionIconButton';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      // fontWeight: 'bold',
   },
   label: {
      paddingRight: 8,
   },
}));

const TableData = ({ tableData, handleUpdate, handleDelete, onChangeCommentSwitch, onChangeMessageSwitch }) => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   const theme = useTheme();

   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [selectedDeleteItemId, setSelectedDeletedItemId] = useState(null);

   return (
      <TableContainer style={{ padding: 10 }}>
         <Table className={classes.table} aria-label='customized table'>
            <TableHead>
               <TableRow>
                  <StyledTableCell style={{ width: 300 }}> {t['page_settings_instagram_page_name']}</StyledTableCell>
                  <StyledTableCell align='left'>{t['teams']}</StyledTableCell>
                  <StyledTableCell align='left'>{t['direct_messages']}</StyledTableCell>
                  <StyledTableCell align='left'>{t['comments']}</StyledTableCell>
                  <StyledTableCell align='left'>{t['status']}</StyledTableCell>
                  <StyledTableCell align='right'>{t['page_settings_process']}</StyledTableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {tableData?.map((facebookPage) => (
                  <StyledTableRow key={facebookPage?._id.toString()}>
                     <StyledTableCell component='th' scope='row' className={classes.coloredText}>
                        <AvatarTable
                           pageName={facebookPage?.name}
                           avatarUrl={facebookPage?.avatarUrl}
                           pageUsername={
                              facebookPage?.facebookAccount?.firstName + ' ' + facebookPage?.facebookAccount?.lastName
                           }
                        />
                     </StyledTableCell>
                     <StyledTableCell component='th' scope='row'>
                        <Typography style={{ color: facebookPage.teams.length > 0 ? 'initial' : 'red' }}>
                           {facebookPage.teams.length > 0
                              ? facebookPage.teams.length + ' ' + t['teams_with_number']
                              : t.no_teams}
                        </Typography>
                     </StyledTableCell>
                     <StyledTableCell className={classes.coloredText}>
                        <Grid container alignItems='center'>
                           <FormControlLabel
                              control={
                                 <CustomSwitch
                                    checked={facebookPage?.isMessageEnable}
                                    onChange={() => {
                                       onChangeMessageSwitch(facebookPage);
                                    }}
                                 />
                              }
                              label={facebookPage?.isMessageEnable ? t?.enabled : t?.disabled}
                              labelPlacement='start'
                              classes={{
                                 label: classes.label,
                              }}
                           />
                        </Grid>
                     </StyledTableCell>
                     <StyledTableCell className={classes.coloredText}>
                        <Grid container alignItems='center'>
                           <FormControlLabel
                              control={
                                 <CustomSwitch
                                    checked={facebookPage?.isCommentEnable}
                                    onChange={() => {
                                       onChangeCommentSwitch(facebookPage);
                                    }}
                                 />
                              }
                              label={facebookPage?.isCommentEnable ? t?.enabled : t?.disabled}
                              labelPlacement='start'
                              classes={{
                                 label: classes.label,
                              }}
                           />
                        </Grid>
                     </StyledTableCell>
                     <StyledTableCell className={classes.coloredText}>
                        <MyTypography
                           style={{
                              color:
                                 facebookPage?.status == PlatformStatus.CONNECTED
                                    ? theme.chatApp.general.pallet.confirmGreen
                                    : 'red',
                           }}
                        >
                           {facebookPage?.status == PlatformStatus.CONNECTED ? t?.connected : t?.disconnected}
                        </MyTypography>
                     </StyledTableCell>
                     <StyledTableCell align='right' className={classes.coloredText}>
                        <ActionIconButton
                           onClick={() => handleUpdate()}
                           tooltip={t?.comp_button_update}
                           actionType='REFRESH'
                        />
                        <ActionIconButton
                           onClick={() => {
                              setShowDeleteModal(true);
                              setSelectedDeletedItemId(facebookPage?._id);
                           }}
                           tooltip={t?.comp_button_delete}
                           actionType='DELETE'
                        />
                     </StyledTableCell>
                  </StyledTableRow>
               ))}
            </TableBody>
         </Table>
         <DeletePlatformModal
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            selectedDeleteItemId={selectedDeleteItemId}
            onComplate={handleDelete}
         />
      </TableContainer>
   );
};

export default TableData;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Paper, ButtonBase } from '@material-ui/core';
import Swiper from 'react-id-swiper';
import 'swiper/swiper.min.css'

const useStyles = makeStyles((theme) => ({
   root: {
      borderRadius: 15,
   },
}));
const sliderSettings = {
   // slidesPerView: 1,
   // autoplay: {
   //   delay: 2500,
   //   disableOnInteraction: false
   // },
   pagination: {
      el: '.swiper-pagination',
      clickable: true,
   },
};

const Marketing = (props) => {
   const { marketingContent, onClose, onShowLater } = props;
   const classes = useStyles();

   return (
      <Grid container direction='row' wrap='nowrap' alignItems='flex-start'>
         <Paper className={classes.root} elevation={3}>
            {marketingContent?.messages.length > 1 ? (
               <Grid
                  style={{
                     width: 600,
                     maxHeight: 650,
                     overflowY: 'auto',
                     padding: 20,
                  }}
               >
                  <Swiper {...sliderSettings}>
                     {marketingContent.messages.map((message) => (
                        <div
                           style={{
                              paddingBottom: 50,
                              width: '100%',
                              textAlign: 'center',
                           }}
                        >
                           <div
                              dangerouslySetInnerHTML={{
                                 __html: message,
                              }}
                           />
                        </div>
                     ))}
                  </Swiper>
               </Grid>
            ) : (
               <Grid container style={{ padding: 20, minWidth: 300 }}>
                  <div
                     style={{
                        paddingBottom: 50,
                        width: '100%',
                        textAlign: 'center',
                     }}
                  >
                     <div
                        dangerouslySetInnerHTML={{
                           __html: marketingContent?.messages[0],
                        }}
                     />
                  </div>
               </Grid>
            )}

            <Grid
               container
               direction='row'
               justifyContent='space-between'
               alignItems='center'
               style={{ backgroundColor: 'lightgrey', padding: 20 }}
            >
               <ButtonBase onClick={onShowLater}>Daha Sonra Göster</ButtonBase>
               <ButtonBase onClick={onClose}>Kapat</ButtonBase>
            </Grid>
         </Paper>
      </Grid>
   );
};

export default Marketing;

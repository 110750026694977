import { useQuery } from '@apollo/client';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
   GET_E_COMMERCE_PLATFORM_TYPE,
   GET_E_COMMERCE_STORE_CARGO_COMPANIES,
   GET_E_COMMERCE_STORE_PAYMENT_TYPES,
} from '@queries/ECommerce/Service/GraphQL/queries';
import {
   E_COMMERCE_ALL_PAYMENT_TYPES,
   E_COMMERCE_ALL_SHIPPING_METHODS,
   E_COMMERCE_TYPE
} from '@store/actions/ecommerce';
import React, { FC, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import CannotAccessModal from '../../ChatItem/CannotAccessModal';
import SubTabView from './SubTabView';

const useStyles = makeStyles(() => ({
   container: {
      height: '100%',
      overflowY: 'auto',
   },
   centerContainer: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FAFAFA',
      margin: 20,
      padding: 45,
      borderRadius: 15,
      textAlign: 'center',
   },
}));

interface ECommerceProps { }

const ECommerce: FC<ECommerceProps> = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [isConnected, setIsConnected] = useState<boolean>(false);
   const history = useHistory();
   const dispatch = useDispatch();
   const token = useSelector((rootReducer: any) => rootReducer.personReducer.token);
   const person = useSelector((rootReducer: any) => rootReducer.personReducer.person);
   const eCommerceType = useSelector((rootReducer: any) => rootReducer.eCommerceReducer.eCommerceType);

   const {
      data: platform,
      loading: loadingPlatform,
   } = useQuery(GET_E_COMMERCE_PLATFORM_TYPE, {
      variables: { customer: person.customer._id },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
         dispatch({
            type: E_COMMERCE_TYPE,
            payload: { eCommerceType: data?.getECommercePlatformType?.data || '' },
         });
      },
   });

   useQuery(GET_E_COMMERCE_STORE_CARGO_COMPANIES, {
      variables: { type: eCommerceType, customer: person.customer._id },
      fetchPolicy: 'network-only',
      skip: !platform,
      onCompleted: (res) => {
         dispatch({
            type: E_COMMERCE_ALL_SHIPPING_METHODS,
            payload: { eCommerceShippingMethods: res?.getECommerceStoreCargoCompanies?.data || '' },
         });
      },
   });

   useQuery(GET_E_COMMERCE_STORE_PAYMENT_TYPES, {
      variables: { type: eCommerceType, customer: person.customer._id },
      fetchPolicy: 'network-only',
      skip: !platform,
      onCompleted: (res) => {
         dispatch({
            type: E_COMMERCE_ALL_PAYMENT_TYPES,
            payload: { eCommercePaymentTypes: res?.getECommerceStorePaymentTypes?.data || '' },
         });
      },
   });

   const openShopifySettings = () => {
      history.push('/settings/channels-and-integrations');
   };

   return (
      <div className={classes.container}>
         {
            loadingPlatform ? (
               <div className={classes.centerContainer}>
                  <div className={classes.cardContainer}>
                     <CircularProgress />
                  </div>
               </div>
            ) : !platform ? (
               <CannotAccessModal
                  title={t['no_connection']}
                  description={t['connect_your_e_commerce_website']}
                  button={<ButtonOK label={t['connect']} onClick={openShopifySettings} />}
               />
            ) : <SubTabView />
         }
      </div>
   );
};

export default ECommerce;

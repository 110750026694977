import React, { FC, useContext } from 'react';
import { LanguageContext } from '../../../../helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { MyTheme } from '../../../../styles/config';

const useStyles = makeStyles((theme: MyTheme) => ({
   container: {
      height: 70,
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 20,
      borderBottom: '1px solid #D4DBE4',
   },
   title: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
}));

interface SettingHeaderProps {
   title: string;
   rightButton?: React.ReactNode;
   leftButton?: React.ReactNode;
   style?: any;
}

const SettingHeader: FC<SettingHeaderProps> = ({ title, rightButton, leftButton, style = {} }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   return (
      <div className={classes.container} style={style}>
         {leftButton}
         <Typography className={classes.title}>{title}</Typography>
         {rightButton}
      </div>
   );
};

export default SettingHeader;

import { gql } from '@apollo/client';

export const GET_TRANSLATE_TEXT = gql`
   query TranslateMessageText($text: String!, $lang: String!) {
      translateMessageText(text: $text, lang: $lang) {
         code
         data {
            language {
               from
               score
               to
            }
            translation
            userLang
            text
         }
         message
         success
      }
   }
`;

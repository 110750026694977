import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Modal as MaterialModal, Paper } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles((theme) => ({
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   modalPaper: {
      borderRadius: 12,
      backgroundColor: '#fff',
      minWidth: 450,
      width: 600,
      maxHeight: '99vh',
      fontFamily: 'Helvetica',
   },
   header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '24px',
      // color: '#4681C3',
      color: 'black',
      fontWeight: 'bold',
      marginTop: 0,
      width: '100%',
      paddingInline: 20,
      paddingBlock: 10,
      borderBottom: '2px solid #f5f5f5',
   },
   body: {
      paddingInline: 20,
      paddingTop: 16,
      overflow: 'overlay',
      overflowX: 'hidden',
      maxHeight: 'calc(99vh - 60px)',
      width: '100%',
   },
}));

// TODO: Change to use MaterialModal
const Modal = (props) => {
   const { open, children, onClose, modalHeader, paperStyle, bodyStyle } = props;
   const classes = useStyles(props);
   return (
      <MaterialModal
         className={classes.modal}
         open={open}
         onClose={onClose}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 500,
         }}
         disableEscapeKeyDown={props?.disableEscapeKeyDown || false}
         disableBackdropClick={props?.disableBackdropClick || false}
      >
         <Paper className={classes.modalPaper} style={paperStyle}>
            {modalHeader && (
               <Grid container className={classes.header}>
                  <Typography variant='inherit'>{modalHeader}</Typography>
               </Grid>
            )}
            <Grid className={classes.body} style={bodyStyle}>
               {children}
            </Grid>
         </Paper>
      </MaterialModal>
   );
};

export default Modal;

import { ButtonBase, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useRef, useState } from 'react';
import Modal from '../Modal/modal';
import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';

const useStyles = makeStyles((theme) => ({
   video: {
      maxWidth: 800,
      maxHeight: 600,
      [theme.breakpoints.down('sm')]: {
         width: '75vw',
         height: '50vh',
      },
      borderRadius: 6,
   },
   previewVideo: {
      position: 'absolute',
      marginLeft: 'auto',
      marginRight: 'auto',
      left: 0,
      right: 0,
      textAlign: 'center',
      zIndex: 1,
      transition: 'all .5s',
   },
   videoThumbnail: {
      opacity: 0.85,
      maxWidth: '100%',
      borderRadius: 6,
   },
   playIcon: {
      fontSize: '4rem',
      transition: 'all .5s',
   },
   button: {
      width: 150,
      height: 100,
      backgroundColor: '#dcdbdb',
      transition: 'all .5s',
      '&:hover > div': {
         transform: 'scale(1.05)',
      },

      '&:hover > div > svg': {
         color: '#191919',
      },
      borderRadius:6
   },
}));

const VideoPlayer = ({ url, name, onShow, isLoading, showPreview, onClose, type, disabled, preview }) => {
   const [showModal, setShowModal] = useState(false);
   const classes = useStyles();

   return (
      <Grid
         container
         direction='row'
         justifyContent='space-between'
         alignItems='flex-start'
         wrap='nowrap'
         style={{ backgroundColor: disabled ? 'lightgrey' : 'transparent'}}
      >
         <Grid
            container
            justifyContent='space-around'
            alignItems='flex-start'
            direction='column'
            className={classes.container}
         >
            {showPreview && (
               <ButtonBase
                  onClick={() => {
                     setShowModal(true);
                  }}
                  className={classes.button}
               >
                  <div className={classes.previewVideo}>
                     <PlayCircleFilledWhiteIcon className={classes.playIcon} color='action' />
                  </div>
               </ButtonBase>
            )}
            {name && (
               <Typography
                  style={{ fontSize: '1rem', color: 'grey', cursor: 'pointer', marginTop: 5 }}
                  onClick={() => setShowModal(true)}
               >
                  {name}
               </Typography>
            )}

            {isLoading && <CircularProgress />}
            <Modal
               open={showModal}
               onClose={() => {
                  setShowModal(false);
               }}
               paperStyle={{
                  width: 'auto',
                  height: 'auto',
                  backgroundColor: 'transparent',
                  boxShadow: 'unset',
               }}
            >
               <video className={classes.video} autoPlay controls>
                  <source src={url} type={'video/mp4'} />
               </video>
            </Modal>
         </Grid>
      </Grid>
   );
};

export default VideoPlayer;

import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ForwardIcon from '@material-ui/icons/Forward';
import moment from 'moment';
import 'moment/locale/tr';
import React, { useCallback, useContext } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import MyTypography from '@components/MyTypography';
import SocialMediaIcon from '@components/SocialMediaIcon';

const useStyles = makeStyles((theme) => ({
   root: {
      transition: 'all .5s',
      '&:hover': {
         backgroundColor: '#EDEDED',
      },
      cursor: 'pointer',
      opacity: (props) => (props.notification.seen ? 0.5 : 1),
      marginTop: 5,
      padding: 5,
      paddingLeft: 7,
      paddingRight: 7,
      borderRadius: 7,
      backgroundColor: (props) => (props.notification.hasSeen ? '#F8F8F8' : '#F7FBFF'),
      overflowX: 'hidden',
   },
   textColor: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   menuItem: {
      margin: 0,
      marginTop: 5,
      marginBottom: 5,
      padding: 0,
      width: 350,
   },
   forwardIcon: {
      fontSize: '2rem',
      color: (props) => (props.notification.hasSeen ? '#C3C3C3' : '#5ac19d'),
      marginLeft: 0,
      marginRight: 10,
   },
   timeColor: {
      color: theme.palette.primary.main,
      whiteSpace: 'nowrap',
   },
}));

const NotificationItem = React.memo(({ notification, handleClickNotif }) => {
   const classes = useStyles({ notification });
   const {
      lang: { shortTitle },
   } = useContext(LanguageContext);

   const handleClickItem = useCallback(() => {
      handleClickNotif(notification);
   }, [handleClickNotif, notification]);

   return (
      <Grid container justifyContent='flex-start' alignItems='center' wrap='nowrap' className={classes.root}>
         <div className={classes.menuItem} onClick={handleClickItem}>
            <Grid
               container
               direction='row'
               justifyContent='flex-start'
               wrap='nowrap'
               alignItems='center'
               style={{ backgroundColor: 'transparent' }}
            >
               {notification?.platformCode === -1 ? (
                  <ForwardIcon className={classes.forwardIcon} />
               ) : (
                  <Grid style={{ marginRight: 10 }}>
                     <SocialMediaIcon
                        selected={!notification?.hasSeen}
                        width={32}
                        height={32}
                        socialMediaCode={notification?.platformCode}
                     />
                  </Grid>
               )}
               <Grid container direction='column'>
                  <Grid container item alignItems='center' justifyContent='space-between'>
                     <Grid container style={{ paddingRight: 4 }} item xs={11}>
                        <MyTypography noWrap bold variant='subtitle2' className={classes.textColor}>
                           {notification?.title}
                        </MyTypography>
                     </Grid>
                     <Grid container justifyContent={'flex-end'} item xs={1}>
                        <MyTypography variant='caption' bold className={classes.timeColor}>
                           {moment(notification?.createdAt).locale(shortTitle).fromNow(true)}
                        </MyTypography>
                     </Grid>
                  </Grid>
                  <Grid container item xs={12} alignItems='center' justifyContent='flex-start'>
                     <Typography variant='caption' className={classes.textColor}>
                        {notification?.message}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
         </div>
      </Grid>
   );
});

export default NotificationItem;

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import SettingHeader from '@modules/Settings/SettingSpace/shared-components/settingHeader';
import SettingBody from '@modules/Settings/SettingSpace/shared-components/settingBody';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { Grid, Typography, Divider, TextField } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import { useMutation } from '@apollo/client';
import { UPDATE_CUSTOMER_MUTATION } from '@queries/Customer/Service/GraphQL/Customer/mutation';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      height: '100%',
   },
   formContainer: {
      padding: 20,
      width: 800,
   },
   inputRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 10,
   },
   textField: {
      width: 100,
      margin: '0 !important',
   },
   TextFieldHolder: {
      '& .MuiFormControl-root': {
         margin: '0px !important',
      },
   },
   helperText: {
      position: 'absolute',
      left: -10,
      top: -20,
      width: '300%',
   },
}));

const dot = (color = 'transparent') => ({
   alignItems: 'center',
   display: 'flex',
   ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
   },
});

const colourStyles = {
   control: (styles) => ({ ...styles, width: 120, height: 44, marginBottom: 3 }),
   option: (styles, { data, isSelected }) => {
      return {
         ...styles,
         color: data.color,
         backgroundColor: isSelected ? '#EEEEEE' : 'white',

         ':hover': {
            ...styles[':hover'],
            backgroundColor: '#EFEFEF',
         },
      };
   },
   input: (styles) => ({ ...styles, ...dot() }),
   placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
   singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const noColourStyles = {
   control: (styles) => ({ ...styles, width: 120, height: 44, marginBottom: 3 }),
   option: (styles, { data, isSelected }) => {
      return {
         ...styles,
         backgroundColor: isSelected ? '#EEEEEE' : 'white',
         color: data.color,
         ':hover': {
            ...styles[':hover'],
            backgroundColor: '#EFEFEF',
         },
      };
   },
   input: (styles) => ({ ...styles }),
   placeholder: (styles) => ({ ...styles }),
   singleValue: (styles, { data }) => ({ ...styles }),
};

const ChatSettings = ({ person }) => {
   const customer = useSelector((rootReducer) => rootReducer?.personReducer?.person?.customer);
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const { enqueueSnackbar } = useSnackbar();
   const t = lang?.translation;
   const ruleOptions = [
      {
         value: 0,
         label: t['page_settings_typo_chat_settings_automatic_actions_select_option_close'],
         color: '#FF5630',
      },
      {
         value: 1,
         label: t['page_settings_typo_chat_settings_automatic_actions_select_option_open'],
         color: '#36B37E',
      },
   ];
   const chatStatusOptions = [
      {
         value: 'WAITING',
         label: t['page_settings_typo_chat_settings_status_0'],
         //green
         color: '#36B37E',
      },
      {
         value: 'CLOSE',
         label: t['page_settings_typo_chat_settings_status_1'],
         color: '#4b9afc',
      },
      {
         value: 'ARCHIVE',
         label: t['page_settings_typo_chat_settings_status_2'],
         //yellow
         color: '#FFAB00',
      },
   ];

   const teamGoalStatusOptions = [
      {
         value: 'HOURS',
         label: t['page_settings_typo_chat_settings_team_goals_hour'],
         //green
      },
      {
         value: 'MINUTES',
         label: t['page_settings_typo_chat_settings_team_goals_minutes'],
      },
      {
         value: 'SECONDS',
         label: t['page_settings_typo_chat_settings_team_goals_seconds'],
         //yellow
      },
   ];

   const autoAssignmentTimeUnits = [
      {
         value: 'DAYS',
         label: t['page_settings_typo_chat_settings_team_goals_day'],
         //yellow
      },
      {
         value: 'HOURS',
         label: t['page_settings_typo_chat_settings_team_goals_hour'],
         //green
      },
      {
         value: 'MINUTES',
         label: t['page_settings_typo_chat_settings_team_goals_minutes'],
      },
   ];

   const {
      handleSubmit,
      control,
      watch,
      setValue,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         autoCloseByCustomerPersonIsOn: !!(person.customer?.autoCloseByCustomerPerson?.time || 0),
         autoCloseByCustomerPerson: person.customer?.autoCloseByCustomerPerson || {
            status: 'CLOSE',
            time: 0,
         },
         autoCloseByPersonIsOn: !!(person.customer?.autoCloseByPerson?.time || 0),
         autoCloseByPerson: person.customer?.autoCloseByPerson || {
            status: 'CLOSE',
            time: 0,
         },
         autoConversationAssignment: person.customer?.autoConversationAssignment || false,
         autoConversationAssignmentTimeIsOn: !!(person.customer?.autoConversationAssignmentTime || 0),
         autoConversationAssignmentTime: person.customer?.autoConversationAssignmentTime || 0,
         autoConversationAssignmentTimeUnit: {
            value: person.customer?.autoConversationAssignmentTimeUnit || 'HOURS',
            label:
               teamGoalStatusOptions.find((item) => item.value === person.customer?.autoConversationAssignmentTimeUnit)
                  ?.label || t['page_settings_typo_chat_settings_team_goals_hour'],
         },
         teamFirstResponseGoalTimeIsOn: !!(person.customer?.teamFirstResponseGoalTime || 0),
         teamFirstResponseGoalTime: {
            target:
               person.customer?.teamFirstResponseGoalTime >= 3.6e6
                  ? 'HOURS'
                  : person.customer?.teamFirstResponseGoalTime >= 60_000
                  ? 'MINUTES'
                  : 'SECONDS',
            time:
               person.customer?.teamFirstResponseGoalTime >= 3.6e6
                  ? person.customer?.teamFirstResponseGoalTime / 3.6e6
                  : person.customer?.teamFirstResponseGoalTime >= 60_000
                  ? person.customer?.teamFirstResponseGoalTime / 60_000
                  : person.customer?.teamFirstResponseGoalTime / 1000,
         },
         teamGoalIsOn: person.customer?.teamGoalTime !== 0,
         teamGoal: {
            target:
               person.customer?.teamGoalTime >= 3.6e6
                  ? 'HOURS'
                  : person.customer?.teamGoalTime >= 60_000
                  ? 'MINUTES'
                  : 'SECONDS',
            time:
               person.customer?.teamGoalTime >= 3.6e6
                  ? person.customer?.teamGoalTime / 3.6e6
                  : person.customer?.teamGoalTime >= 60_000
                  ? person.customer?.teamGoalTime / 60_000
                  : person.customer?.teamGoalTime / 1000,
         },
      },
   });
   const [updateCustomerMutation, { loading }] = useMutation(UPDATE_CUSTOMER_MUTATION);

   const submit = (values) => {
      const variables = {
         autoCloseByCustomerPerson: {
            status: values.autoCloseByCustomerPerson.status,
            time: Number(values.autoCloseByCustomerPerson.time),
         },
         autoCloseByPerson: {
            status: values.autoCloseByPerson.status,
            time: Number(values.autoCloseByPerson.time),
         },
         teamGoalTime:
            Number(values.teamGoal.time) *
            (values.teamGoal.target === 'HOURS' ? 3_600_000 : values.teamGoal.target === 'MINUTES' ? 60_000 : 1000),
         teamFirstResponseGoalTime:
            Number(values.teamFirstResponseGoalTime.time) *
            (values.teamFirstResponseGoalTime.target === 'HOURS'
               ? 3_600_000
               : values.teamFirstResponseGoalTime.target === 'MINUTES'
               ? 60_000
               : 1000),
         autoConversationAssignment: values.autoConversationAssignment,
         autoConversationAssignmentTime: Number(values.autoConversationAssignmentTime),
         autoConversationAssignmentTimeUnit: values.autoConversationAssignmentTimeUnit.value,
      };
      updateCustomerMutation({
         variables: {
            ...person?.customer,
            ...customer,
            ...variables,
         },
      })
         .then(() => {
            enqueueSnackbar(t['page_settings_typo_chat_settings_automatic_actions_success_message'], {
               variant: 'success',
               autoHideDuration: 4000,
            });
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               autoHideDuration: 4000,
            });
         });
   };

   return (
      <div className={classes.container}>
         <SettingHeader
            title={t['page_settings_typo_chat_settings']}
            rightButton={
               <ButtonOK
                  disabled={loading || !isValid}
                  onClick={handleSubmit((values) => submit(values))}
                  label={t['update']}
               />
            }
         />
         <SettingBody>
            <div className={classes.formContainer}>
               <Typography variant='h6'>{t['page_settings_typo_chat_settings_conversation_assignment']}</Typography>
               <Typography variant='body2'>
                  {t['page_settings_typo_chat_settings_conversation_assignment_description']}
               </Typography>
               <br />
               <Typography variant='body1'>
                  {t?.page_settings_typo_chat_settings_conversation_assignment_auto_assignment}
               </Typography>
               <div style={{ width: 144 }}>
                  <ReactSelect
                     onChange={(val) => {
                        setValue('autoConversationAssignment', !!val.value)
                        if (!val.value) {
                           setValue('autoConversationAssignmentTimeIsOn', false);
                           setValue('autoConversationAssignmentTime', 0);
                           setValue('autoConversationAssignmentTimeUnit', { value: 'HOURS', label: 'Hours' });
                        }
                     }}
                     value={watch('autoConversationAssignment') ? ruleOptions[1] : ruleOptions[0]}
                     options={ruleOptions}
                     styles={colourStyles}
                  />
               </div>
               <br />
               <Typography variant='h6'>
                  {t['page_settings_typo_chat_settings_conversation_assignment_auto_assignment_rules']}
               </Typography>
               <Typography variant='body2'>
                  {t['page_settings_typo_chat_settings_conversation_assignment_auto_assignment_rules_description']}
               </Typography>
               <br />
               <Typography variant='body1'>
                  {t?.page_settings_typo_chat_settings_conversation_assignment_auto_assignment_rules_detail}
               </Typography>
               <br />
               <Grid container spacing={0}>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_action_status}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_team_goals_target}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_team_goals_time}</Typography>
                  </Grid>
               </Grid>
               <Grid container spacing={0}>
                  <Grid item xs={2}>
                     <ReactSelect
                        isDisabled={!watch('autoConversationAssignment')}
                        onChange={(val) => {
                           let isSame = watch('autoConversationAssignmentTimeIsOn') === !!val.value;
                           if (isSame) return;
                           setValue('autoConversationAssignmentTimeIsOn', !!val.value);
                           if (val.value) {
                              setValue('autoConversationAssignmentTime', 1);
                              setValue('autoConversationAssignmentTimeUnit', autoAssignmentTimeUnits[0]);
                           } else {
                              setValue('autoConversationAssignmentTime', 0);
                              setValue('autoConversationAssignmentTimeUnit', autoAssignmentTimeUnits[0]);
                           }
                        }}
                        value={watch('autoConversationAssignmentTimeIsOn') ? ruleOptions[1] : ruleOptions[0]}
                        options={ruleOptions}
                        styles={colourStyles}
                     />
                  </Grid>

                  <Grid item xs={2}>
                     <ReactSelect
                        isDisabled={
                           !watch('autoConversationAssignmentTimeIsOn') || !watch('autoConversationAssignment')
                        }
                        onChange={(val) => {
                           let isSame = watch('autoConversationAssignmentTimeUnit.value') === val.value;
                           if (isSame) return;
                           setValue('autoConversationAssignmentTimeUnit.value', val.value);
                           setValue('autoConversationAssignmentTime', 1);
                        }}
                        value={autoAssignmentTimeUnits.find(
                           (x) => x.value === watch('autoConversationAssignmentTimeUnit.value'),
                        )}
                        options={autoAssignmentTimeUnits}
                        styles={noColourStyles}
                     />
                  </Grid>

                  <Grid item xs={2} className={classes.TextFieldHolder}>
                     <Controller
                        name={'autoConversationAssignmentTime'}
                        rules={{
                           required: watch('autoConversationAssignment') && watch('autoConversationAssignmentTimeIsOn'),
                           max: 1440,
                           min:
                              watch('autoConversationAssignment') && watch('autoConversationAssignmentTimeIsOn')
                                 ? 1
                                 : 0,
                        }}
                        control={control}
                        render={({ field, fieldState: { error, isDirty, isTouched } }) => (
                           <TextField
                              disabled={
                                 !watch('autoConversationAssignment') || !watch('autoConversationAssignmentTimeIsOn')
                              }
                              type={'number'}
                              error={!!error}
                              {...field}
                              placeholder={t['page_settings_typo_chat_settings_automatic_actions_select_placeholder']}
                              variant='outlined'
                              style={{ marginTop: '0px !important' }}
                           />
                        )}
                     />
                  </Grid>
               </Grid>
               <br />
               <Divider />
               <br />
               <Typography variant='h6'>{t['page_settings_typo_chat_settings_automatic_actions_title']}</Typography>
               <Typography variant='body2'>
                  {t['page_settings_typo_chat_settings_automatic_actions_description']}
               </Typography>
               <br />
               <Typography
                  variant='body1'
                  dangerouslySetInnerHTML={{
                     __html: t['page_settings_typo_chat_settings_automatic_actions_agent'],
                  }}
               />
               <br />
               <Grid container spacing={0}>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_action_status}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_chat_status}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_min}</Typography>
                  </Grid>
               </Grid>
               <Grid container spacing={0}>
                  <Grid item xs={2}>
                     <ReactSelect
                        onChange={(val) => {
                           let isSame = watch('autoCloseByCustomerPersonIsOn') === !!val.value;
                           if (!isSame) {
                              setValue('autoCloseByCustomerPersonIsOn', !!val.value);
                              setValue('autoCloseByCustomerPerson', {
                                 status: 'WAITING',
                                 time: !!val.value ? 5 : 0,
                              });
                           }
                        }}
                        value={watch('autoCloseByCustomerPersonIsOn') ? ruleOptions[1] : ruleOptions[0]}
                        options={ruleOptions}
                        styles={colourStyles}
                     />
                  </Grid>

                  <Grid item xs={2}>
                     <ReactSelect
                        isDisabled={!watch('autoCloseByCustomerPersonIsOn')}
                        onChange={(val) => {
                           let isSame = val.value === watch('autoCloseByCustomerPerson').status;
                           if (!isSame) {
                              setValue('autoCloseByCustomerPerson', {
                                 status: val.value,
                                 time: watch('autoCloseByCustomerPerson').time || 0,
                              });
                           }
                        }}
                        value={chatStatusOptions.find((x) => x.value === watch('autoCloseByCustomerPerson').status)}
                        options={chatStatusOptions}
                        styles={colourStyles}
                     />
                  </Grid>

                  <Grid item xs={2} className={classes.TextFieldHolder}>
                     <Controller
                        name={'autoCloseByCustomerPerson.time'}
                        rules={{
                           required: watch('autoCloseByCustomerPersonIsOn'),
                           max: 1440,
                           min: watch('autoCloseByCustomerPersonIsOn') ? 5 : 0,
                        }}
                        control={control}
                        render={({ field, fieldState: { error, isDirty, isTouched } }) => (
                           <TextField
                              disabled={!watch('autoCloseByCustomerPersonIsOn')}
                              type={'number'}
                              error={!!error}
                              {...field}
                              placeholder={t['page_settings_typo_chat_settings_automatic_actions_select_placeholder']}
                              variant='outlined'
                              style={{ marginTop: '0px !important' }}
                           />
                        )}
                     />
                  </Grid>
               </Grid>
               <br />
               <Typography
                  variant='body1'
                  dangerouslySetInnerHTML={{
                     __html: t['page_settings_typo_chat_settings_automatic_actions_customer'],
                  }}
               />
               <br />
               <Grid container spacing={0}>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_action_status}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_chat_status}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_min}</Typography>
                  </Grid>
               </Grid>
               <Grid container spacing={0}>
                  <Grid item xs={2}>
                     <ReactSelect
                        onChange={(val) => {
                           let isSame = watch('autoCloseByPersonIsOn') === !!val.value;
                           if (!isSame) {
                              setValue('autoCloseByPersonIsOn', !!val.value);
                              setValue('autoCloseByPerson', {
                                 status: 'WAITING',
                                 time: !!val.value ? 5 : 0,
                              });
                           }
                        }}
                        value={watch('autoCloseByPersonIsOn') ? ruleOptions[1] : ruleOptions[0]}
                        options={ruleOptions}
                        styles={colourStyles}
                     />
                  </Grid>

                  <Grid item xs={2}>
                     <ReactSelect
                        isDisabled={!watch('autoCloseByPersonIsOn')}
                        onChange={(val) => {
                           let isSame = watch('autoCloseByPerson').status === val.value;
                           if (!isSame) {
                              setValue('autoCloseByPerson', {
                                 status: val.value,
                                 time: watch('autoCloseByPerson').time || 5,
                              });
                           }
                        }}
                        value={chatStatusOptions.find((x) => x.value === watch('autoCloseByPerson').status)}
                        options={chatStatusOptions}
                        styles={colourStyles}
                     />
                  </Grid>
                  <Grid item xs={2} className={classes.TextFieldHolder}>
                     <Controller
                        name={'autoCloseByPerson.time'}
                        rules={{
                           required: watch('autoCloseByPersonIsOn'),
                           max: 1440,
                           min: watch('autoCloseByPersonIsOn') ? 5 : 0,
                        }}
                        control={control}
                        render={({ field, fieldState: { error, isDirty, isTouched } }) => (
                           <TextField
                              disabled={!watch('autoCloseByPersonIsOn')}
                              type={'number'}
                              {...field}
                              error={!!error}
                              placeholder={t['page_settings_typo_chat_settings_automatic_actions_select_placeholder']}
                              variant='outlined'
                              style={{ marginTop: '0px !important' }}
                           />
                        )}
                     />
                  </Grid>
               </Grid>
               <br />
               <Divider />
               <br />
               <Typography variant='h6'>{t['page_settings_typo_chat_settings_team_goals']}</Typography>
               <Typography variant='body2'>{t['page_settings_typo_chat_settings_team_desc']}</Typography>
               <br />
               <Typography variant='body1'>{t['page_settings_typo_chat_settings_team_resolutionTime']}</Typography>
               <br />
               <Grid container spacing={0}>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_team_goals_status}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_team_goals_time}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_team_goals_target}</Typography>
                  </Grid>
               </Grid>
               <Grid container spacing={0}>
                  <Grid item xs={2}>
                     <ReactSelect
                        onChange={(val) => {
                           let isSame = watch('teamGoalIsOn') === !!val.value;
                           if (!isSame) {
                              setValue('teamGoalIsOn', !!val.value);
                              setValue('teamGoal', {
                                 target: 'MINUTES',
                                 time: !!val.value ? 5 : 0,
                              });
                           }
                        }}
                        value={watch('teamGoalIsOn') ? ruleOptions[1] : ruleOptions[0]}
                        options={ruleOptions}
                        styles={colourStyles}
                     />
                  </Grid>

                  <Grid item xs={2}>
                     <ReactSelect
                        isDisabled={!watch('teamGoalIsOn')}
                        onChange={(val) => {
                           let isSame = watch('teamGoal').target === val.value;
                           if (!isSame) {
                              setValue('teamGoal', {
                                 target: val.value,
                                 time: watch('teamGoal').time || 1,
                              });
                           }
                        }}
                        value={teamGoalStatusOptions.find((x) => x.value === watch('teamGoal').target)}
                        options={teamGoalStatusOptions}
                        styles={noColourStyles}
                     />
                  </Grid>
                  <Grid item xs={2} className={classes.TextFieldHolder}>
                     <Controller
                        name={'teamGoal.time'}
                        rules={{
                           required: watch('teamGoalIsOn'),
                           min: watch('teamGoalIsOn') ? 1 : 0,
                        }}
                        control={control}
                        render={({ field, fieldState: { error, isDirty, isTouched } }) => (
                           <TextField
                              disabled={!watch('teamGoalIsOn')}
                              type={'number'}
                              {...field}
                              error={!!error}
                              placeholder={t['page_settings_typo_chat_settings_automatic_actions_select_placeholder']}
                              variant='outlined'
                              style={{ marginTop: '0px !important' }}
                           />
                        )}
                     />
                  </Grid>
               </Grid>
               <br />
               <Typography variant='body1'>{t['page_settings_typo_chat_settings_team_firstResponseTime']}</Typography>
               <br />
               <Grid container spacing={0}>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_team_goals_status}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_team_goals_time}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                     <Typography variant='body1'>{t?.page_settings_typo_chat_settings_team_goals_target}</Typography>
                  </Grid>
               </Grid>
               <Grid container spacing={0}>
                  <Grid item xs={2}>
                     <ReactSelect
                        onChange={(val) => {
                           let isSame = watch('teamFirstResponseGoalTimeIsOn') === !!val.value;
                           if (!isSame) {
                              setValue('teamFirstResponseGoalTimeIsOn', !!val.value);
                              setValue('teamFirstResponseGoalTime', {
                                 target: 'MINUTES',
                                 time: !!val.value ? 5 : 0,
                              });
                           }
                        }}
                        value={watch('teamFirstResponseGoalTimeIsOn') ? ruleOptions[1] : ruleOptions[0]}
                        options={ruleOptions}
                        styles={colourStyles}
                     />
                  </Grid>

                  <Grid item xs={2}>
                     <ReactSelect
                        isDisabled={!watch('teamFirstResponseGoalTimeIsOn')}
                        onChange={(val) => {
                           let isSame = watch('teamFirstResponseGoalTime').target === val.value;
                           if (!isSame) {
                              setValue('teamFirstResponseGoalTime', {
                                 target: val.value,
                                 time: watch('teamFirstResponseGoalTime').time || 1,
                              });
                           }
                        }}
                        value={teamGoalStatusOptions.find((x) => x.value === watch('teamFirstResponseGoalTime').target)}
                        options={teamGoalStatusOptions}
                        styles={noColourStyles}
                     />
                  </Grid>
                  <Grid item xs={2} className={classes.TextFieldHolder}>
                     <Controller
                        name={'teamFirstResponseGoalTime.time'}
                        rules={{
                           required: watch('teamFirstResponseGoalTimeIsOn'),
                           min: watch('teamFirstResponseGoalTimeIsOn') ? 1 : 0,
                        }}
                        control={control}
                        render={({ field, fieldState: { error, isDirty, isTouched } }) => (
                           <TextField
                              disabled={!watch('teamFirstResponseGoalTimeIsOn')}
                              type={'number'}
                              {...field}
                              error={!!error}
                              placeholder={t['page_settings_typo_chat_settings_automatic_actions_select_placeholder']}
                              variant='outlined'
                              style={{ marginTop: '0px !important' }}
                           />
                        )}
                     />
                  </Grid>
               </Grid>
            </div>
         </SettingBody>
      </div>
   );
};

export default ChatSettings;

import { LanguageContext } from '@helper/locale/langContext';
import { Button, Typography, useMediaQuery, IconButton, } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: any) => ({
    container: {
        backgroundColor: '#fff',
        padding: 10,
        paddingLeft: 16,
        borderBottom: ' 1px solid #d1d1d1',
    },
    menuItem: {
        margin: 0,
        marginTop: 5,
        marginBottom: 5,
        padding: 0,
    },
    selector: {
        textAlign: 'left',
        border: 'solid 1px lightgrey',
        borderRadius: 5,
        paddingLeft: 3,
        height: 32,
    },
    item: {
        display: 'inline-block',
        width: 7,
        height: 30,
        backgroundColor: 'white',
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        marginRight: '10px',
    },
}));

export interface TopBarProps {
    onSend: (() => void) | null | undefined;
    onLeave: () => void;
}

const TopBar = ({ onSend, onLeave }: TopBarProps) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;
    const theme = useTheme();
    //@ts-ignore
    const isSmallScreen = useMediaQuery(theme.breakpoints.between('sm'));


    return (
        <Grid style={{ boxShadow: ' 2px 2px 2px lightgray' }}>
            <Grid container justifyContent='space-between' className={classes.container} alignItems={'center'}>
                <Grid container alignItems='flex-start' justifyContent='flex-start' item xs={3} md={7}>
                    <IconButton onClick={onLeave} style={{ marginTop: 5, width: 10, height: 10 }}>
                        <ArrowBackIcon color='disabled' />
                    </IconButton>{' '}
                    &nbsp;&nbsp;
                    <Typography variant='h6' style={{ fontWeight: 'bold', marginTop: 2 }}>
                        {t?.bulk_upload_title}
                    </Typography>
                </Grid>
                <Grid
                    xs={9}
                    sm={3}
                    md={5}
                    item
                    spacing={4}
                    container
                    alignItems='flex-end'
                    justifyContent='flex-end'
                    style={{
                        paddingTop: isSmallScreen ? 10 : undefined,
                        paddingBottom: isSmallScreen ? 10 : undefined,
                        flexWrap: isSmallScreen ? undefined : 'nowrap',
                        paddingRight: 20,
                    }}
                >
                    <Button
                        style={{
                            minWidth: 150,
                        }}
                        disabled={!onSend}
                        color='primary'
                        variant='contained'
                        onClick={onSend || (() => { })}
                    >
                        {t?.campaign_send}
                    </Button>
                </Grid>
            </Grid>
        </Grid >
    );
};

export default TopBar;

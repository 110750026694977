import React, { Fragment, useContext, useCallback, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress, Typography } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm } from 'react-hook-form';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';
import SearchSelect from 'react-select';

interface ModalContentProps {
   onSubmit: (newName: string, trigger: string) => void;
   value?: { title: string | undefined; trigger: string | undefined };
   closeModal: () => void;
   isCreate?: Boolean;
   isLoading?: Boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   container: {
      width: '100%',
   },
   ruleSelect: {
      width: '100%',
      marginBottom: '15px',
   },
}));

const EcommerceRuleModal: React.FC<ModalContentProps> = ({ value, onSubmit, closeModal, isCreate, isLoading }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const customStyles = {
      control: (base: any) => ({
         ...base,
         height: 45,
      }),
   };

   const OPTIONS = [
      {
         value: 'ABANDONED_CHECKOUT ',
         label: t.automation_cart_abandonned,
      },
      // {
      //    value: 'order_cerated',
      //    label: 'Order cerated',
      // },
   ];

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' justifyContent='center' xs={12}>
            <CircularProgress size={20} color='inherit' />
         </Grid>
      );
   }, []);

   const {
      handleSubmit,
      setValue,
      getValues,
      control,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         name: value && value.title ? value.title : '',
         trigger: value && value.trigger ? value.trigger : '',
      },
   });

   const handleFormSubmit = (data: { name: string; trigger: string }) => {
      onSubmit(data.name, 'ABANDONED_CHECKOUT');
      closeModal();
   };

   const handleSelectChange = (trigger: string) => {
      setValue('trigger', trigger);
   };

   return (
      <Grid container>
         <Fragment>
            <div className={classes.container}>
               <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <Grid container>
                     <SingleInput
                        label={t['automation_flow_name']}
                        name='name'
                        placeholder={t['automation_flow_enter_name']}
                        control={control}
                        rules={{
                           required: t['automation_flow_name_required'],
                           maxLength: { value: 70, message: t?.automation_flow_flow_bot_title_max_length },
                        }}
                     />
                     {isCreate && (
                        <Grid style={{ width: '100%', marginTop: '-5px' }}>
                           <Typography style={{ marginLeft: 5 }}>{t.automation_e_commerce_when_to_start}</Typography>
                           <SearchSelect
                              label={t['automation_flow_Informative_button_type']}
                              name='type'
                              isSelect={true}
                              styles={customStyles}
                              className={classes.ruleSelect}
                              control={control}
                              onChange={(e) => {
                                 if (e) {
                                    handleSelectChange(e.value);
                                 }
                              }}
                              defaultValue={OPTIONS[0]}
                              options={OPTIONS}
                              placeholder={t.settings_whatsapp_business_template_form_select}
                           />
                        </Grid>
                     )}

                     <ModalButtonField>
                        <Button
                           className={classes.modalButtons}
                           onClick={closeModal}
                           style={{
                              color: '#212121',
                              backgroundColor: '#eeee',
                           }}
                        >
                           {t?.cancel}
                        </Button>
                        <Button
                           className={classes.modalButtons}
                           type='submit'
                           disabled={!isValid}
                           style={{
                              color: 'white',
                              backgroundColor: '#157CFC',
                              float: 'right',
                           }}
                        >
                           <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                              {isLoading && <Loader />}
                              <span style={{ whiteSpace: 'nowrap' }}>
                                 {isCreate ? t.create_team_button : t.automation_flow_rename}
                              </span>
                           </div>
                        </Button>
                     </ModalButtonField>
                  </Grid>
               </form>
            </div>
         </Fragment>
      </Grid>
   );
};

export default EcommerceRuleModal;

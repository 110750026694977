import { Button, CircularProgress, Grid, InputLabel, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';

import { LanguageContext } from '@helper/locale/langContext';
import SearchSelect from 'react-select';
import ButtonOK from '@components/ButtonOK/buttonOk';

import Input from '@components/InputField';
import MyTypography from '@components/MyTypography';
// const LEFT_PANEL_WIDTH = 320;

import { getBusinessVerticals } from './Utils';

import LinkifyIt from 'linkify-it';
import { LangCodes } from '@helper/language';
import { ModalLargeText, ModalTitle } from '@components/_Modal/ModalTexts';
import SingleInput from '@components/_Modal/SingleInput';
import DoubleInput from '@components/_Modal/DoubleInput';
import { useLazyQuery } from '@apollo/client';
import { LOCATIONS } from '@queries/Locations/query';
import { useSelector } from 'react-redux';

const linkify = new LinkifyIt();

const useStyles = makeStyles((theme) => ({
   root: {
      width: 700,
      padding: 20,
      // paddingBottom: 5,
      borderRadius: 15,
      // width: 700,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   iconSize: {
      fontSize: '5rem',
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: null,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   selector: {
      textAlign: 'left',
      border: 'solid 1px lightgrey',
      borderRadius: 5,
      paddingLeft: 3,
      height: 32,
   },
   stepLabel: {
      fontSize: '1.2rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   importantNotesItem: {
      fontSize: '1rem',
   },
   btnNext: {
      marginTop: 30,
      marginBottom: 10,
      paddingInline: 20,
   },
   confirmButtonContainer: {
      display: 'inline-block',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      backgroundColor: 'rgba(90, 193, 157, 1)',
      padding: 5,
      borderRadius: 5,
      marginRight: 2,
      marginLeft: 2,
      transition: 'all .5s',
      '&:hover': {
         cursor: 'pointer',
         backgroundColor: '#266650',
      },
   },
   resultContainer: {
      width: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      padding: 5,
      borderRadius: 5,
   },
   statusTitle: {
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: 'bold',
   },
   informationTitle: {
      marginTop: 20,
      fontSize: '1.1rem',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   inputFix: {
      paddingLeft: 15,
      marginTop: 17,
      '& .MuiInputBase-root': {
         height: 38,
         marginTop: '-8px',
      },
   },
}));
// Get Steps
const getSteps = (t) => {
   return [
      t?.settings_whatsapp_b_submit_step_1,
      t?.settings_whatsapp_b_submit_step_2,
      t?.settings_whatsapp_b_submit_step_3,
      t?.settings_whatsapp_b_submit_step_4,
   ];
};

// Get Information List For Step 1
const getImportantNotesList = (t) => {
   return [
      t?.settings_whatsapp_b_submit_note_1,
      t?.settings_whatsapp_b_submit_note_2,
      t?.settings_whatsapp_b_submit_note_3,
   ];
};

const ModalContent = (props) => {
   const classes = useStyles();
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const customer = person.customer;
   let lang_code = customer?.language;
   const { onAccept, onCancel, status, loading, teamsData } = props;
   const {
      handleSubmit,
      control,
      setValue,
      watch,
      formState: { errors },
   } = useForm();
   //Steps
   const [activeStep, setActiveStep] = useState(0);
   // Language Context
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const businessVerticals = getBusinessVerticals(t);

   const onSubmit = (data) => {
      const submitData = {
         ...data,
         country: data.country.label,
         city: data.state.label,
         state: data.city.label,
         teams: data.teams.map((team) => team.value),
      };
      onAccept(submitData);
      setActiveStep((prevStep) => prevStep + 1);
   };
   // get Lists
   const importantNotesList = getImportantNotesList(t);
   const steps = getSteps(t);

   //!

   const [getCountry, { data: countryData, loading: countryLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'country',
         id: 0,
      },
   });
   const [getState, { data: stateData, loading: stateLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'state',
         id: watch('country.value'),
      },
   });
   const [getCity, { data: cityData, loading: cityLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'city',
         id: watch('state.value'),
      },
   });

   useEffect(() => {
      if (countryData) {
         if (countryData.locations.data.length === 0) {
            setValue('country', {
               value: 0,
               label: '',
            });
         } else {
            setValue('country', {
               value: countryData.locations.data[lang_code === 'TR' ? 227 : 235].id,
               label: countryData.locations.data[lang_code === 'TR' ? 227 : 235].name,
            });
         }
      }
      getCountry();
   }, [countryData]);

   useEffect(() => {
      if (stateData) {
         if (stateData.locations.data.length === 0) {
            setValue('state', {
               value: 0,
               label: '',
            });
         } else {
            setValue('state', {
               value: watch('country.value') === 225 ? stateData.locations.data[39].id : stateData.locations.data[0].id,
               label:
                  watch('country.value') === 225 ? stateData.locations.data[39].name : stateData.locations.data[0].name,
            });
         }
      }
      getState();
   }, [stateData]);

   useEffect(() => {
      if (cityData) {
         if (cityData.locations.data.length === 0) {
            setValue('city', {
               value: 0,
               label: '',
            });
         } else {
            setValue('city', {
               value: cityData.locations.data[0].id,
               label: cityData.locations.data[0].name,
            });
         }
      }
      getCity();
   }, [cityData]);
   //!

   // only for test
   return (
      <Grid>
         <Fragment>
            <Stepper activeStep={activeStep} alternativeLabel>
               {steps.map((label) => (
                  <Step key={label}>
                     <StepLabel>
                        <Typography className={classes.stepLabel}>{label}</Typography>
                     </StepLabel>
                  </Step>
               ))}
            </Stepper>
            {activeStep === steps.length - 1 ? (
               <Fragment>
                  {loading && (
                     <Grid container justifyContent='center' alignItems='center' style={{ height: 400 }} xs={12}>
                        <CircularProgress size={36} color='primary' />
                     </Grid>
                  )}
                  {/* Connect or Error */}
                  {status === 'success' && !loading && (
                     // Connect
                     <Grid
                        container
                        justifyContent='space-between'
                        direction='column'
                        xs={12}
                        style={{ paddingInline: 20 }}
                     >
                        <Grid item container direction='column' justifyContent='center' alignItems='center'>
                           <MyTypography variant='h5' bold color='inherit' style={{ padding: '24px 0px' }}>
                              {t?.settings_whatsapp_b_business_submit_success_title}
                           </MyTypography>
                           <MyTypography style={{ paddingTop: 4, textAlign: 'center' }} color='inherit'>
                              {t?.settings_whatsapp_b_business_submit_success_description_1}
                           </MyTypography>
                           <MyTypography style={{ textAlign: 'center' }} color='inherit'>
                              {t?.settings_whatsapp_b_business_submit_success_description_2}
                           </MyTypography>
                        </Grid>
                        <Grid item container justifyContent='flex-end'>
                           <ButtonOK
                              label={t?.settings_whatsapp_b_input_okay}
                              style={{
                                 marginBlock: 16,
                                 color: 'white',
                                 backgroundColor: '#157CFC',
                                 '&:hover': {
                                    backgroundColor: '#105CBB',
                                 },
                                 float: 'right',
                              }}
                              onClick={onCancel}
                           />
                        </Grid>
                     </Grid>
                  )}
                  {status === 'error' &&
                     !loading && ( //Error
                        <Grid container justifyContent='space-between' direction='column' style={{ paddingInline: 20 }}>
                           <Grid item container direction='column' justifyContent='center' alignItems='center'>
                              <MyTypography variant='h5' bold color='inherit' style={{ padding: '24px 0px' }}>
                                 {t?.settings_whatsapp_b_business_submit_failed_title}
                              </MyTypography>
                              <MyTypography style={{ paddingTop: 4, textAlign: 'center' }} color='inherit'>
                                 {t?.settings_whatsapp_b_business_submit_failed_description_1}
                              </MyTypography>
                              <MyTypography style={{ textAlign: 'center' }} color='inherit'>
                                 {t?.settings_whatsapp_b_business_submit_failed_description_2}
                              </MyTypography>
                           </Grid>
                           <Grid item container justifyContent='flex-end'>
                              <ButtonOK
                                 label={t?.try_again}
                                 className={classes.modalButtons}
                                 style={{
                                    color: 'white',
                                    backgroundColor: '#157CFC',
                                    '&:hover': {
                                       backgroundColor: '#105CBB',
                                    },
                                    float: 'right',
                                 }}
                                 onClick={() => setActiveStep(0)}
                              />
                           </Grid>
                        </Grid>
                     )}
               </Fragment>
            ) : (
               <Grid container justifyContent='space-between'>
                  {/* Step 1 */}
                  {activeStep === 0 && (
                     <Grid container item xs={12} style={{ paddingInline: 20 }}>
                        <Grid item justifyContent='space-between' style={{ marginTop: 15 }}>
                           <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>
                              {t.settings_whatsapp_b_submit_description_title}
                           </Typography>
                           <Typography align='left' style={{ marginTop: 5 }}>
                              {t.settings_whatsapp_b_submit_description}
                           </Typography>
                           <Typography align='left' style={{ marginTop: 25 }}>
                              {t.settings_whatsapp_b_submit_description_step_1}
                           </Typography>
                           <Typography align='left'>{t.settings_whatsapp_b_submit_description_step_2}</Typography>
                           <Typography align='left'>{t.settings_whatsapp_b_submit_description_step_3}</Typography>
                        </Grid>
                        <Grid
                           style={{
                              padding: '12px',
                              backgroundColor: '#F5F5F5',
                              width: '100%',
                              marginTop: 25,
                           }}
                        >
                           <Typography
                              style={{
                                 fontSize: '1.2rem',
                                 textAlign: 'left',
                                 fontWeight: 'bold',
                              }}
                           >
                              {t?.settings_whatsapp_b_submit_notes_title}
                           </Typography>
                           <Grid
                              item
                              container
                              justifyContent='space-between'
                              style={{ marginTop: 30 }}
                              alignItems='center'
                           >
                              <Grid container justifyContent='flex-start' item direction='column'>
                                 {importantNotesList.map((label, index) => (
                                    <li
                                       key={label}
                                       align='left'
                                       style={{
                                          marginTop: index === 0 ? 0 : 10,
                                       }}
                                       className={classes.importantNotesItem}
                                    >
                                       {label}
                                    </li>
                                 ))}
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  )}
                  {/* Step 2  */}
                  {activeStep === 1 && (
                     <form
                        onSubmit={handleSubmit(() => {
                           setActiveStep(activeStep + 1);
                        })}
                        style={{ width: '100%' }}
                     >
                        <Grid
                           container
                           justifyContent='space-between'
                           alignItems='center'
                           xs={12}
                           style={{ marginTop: 15 }}
                        >
                           <Grid
                              item
                              container
                              style={{
                                 overflow: 'overlay',
                                 maxHeight: 'calc(72vh - 60px)',
                                 paddingInline: 20,
                              }}
                           >
                              <ModalTitle>{t.settings_whatsapp_b_details_title}</ModalTitle>
                              <ModalLargeText>{t.settings_whatsapp_b_details_description}</ModalLargeText>
                              <SingleInput
                                 control={control}
                                 name='name'
                                 required={false}
                                 label={t.settings_whatsapp_b_details_input_display_name}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                 }}
                                 placeholder={t?.settings_whatsapp_b_details_input_display_name}
                              />
                              <SingleInput
                                 isPhone={true}
                                 splitCountryCode={false}
                                 name='number'
                                 label={t.settings_whatsapp_b_details_input_phone_number}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                 }}
                                 control={control}
                              />
                              <SingleInput
                                 control={control}
                                 name='fb_business_id'
                                 required={false}
                                 label={t.settings_whatsapp_b_details_input_fb_b_id}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                 }}
                                 placeholder={t?.settings_whatsapp_b_details_input_fb_b_id}
                              />
                              <SingleInput
                                 control={control}
                                 name='fb_page_url'
                                 required={false}
                                 label={t.settings_whatsapp_b_details_input_fb_page_url}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                    validate: (value) => linkify.test(value) || t?.url_valid,
                                 }}
                                 placeholder={t?.settings_whatsapp_b_details_input_fb_page_url}
                              />
                              <SingleInput
                                 type='email'
                                 control={control}
                                 name='fbContactEmail'
                                 required={false}
                                 label={t.settings_whatsapp_b_details_input_fb_b_manager_email}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                    pattern: {
                                       value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                       message: t['email_valid'],
                                    },
                                 }}
                                 placeholder={t?.settings_whatsapp_b_details_input_fb_b_manager_email}
                              />
                              <SingleInput
                                 isSelect
                                 isMulti
                                 autoHeight
                                 selectOptions={teamsData.map((team) => ({
                                    label: team.teamName,
                                    value: team._id,
                                 }))}
                                 label={t.settings_whatsapp_b_details_input_teams}
                                 name='teams'
                                 control={control}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                 }}
                                 editable
                                 height={40}
                                 placeholder={t?.teams}
                              />
                           </Grid>
                        </Grid>
                        {/* Submit Button */}
                        <Grid container justifyContent='space-between' className={classes.btnNext}>
                           <Button
                              onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                              className={classes.modalButtons}
                              style={{
                                 color: '#212121',
                                 backgroundColor: '#eeee',
                                 '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                 },
                              }}
                           >
                              {t?.back}
                           </Button>
                           <ButtonOK
                              className={classes.modalButtons}
                              label={t?.continue}
                              type='submit'
                              style={{
                                 color: 'white',
                                 backgroundColor: '#157CFC',
                                 '&:hover': {
                                    backgroundColor: '#105CBB',
                                 },
                                 float: 'right',
                              }}
                           />
                        </Grid>
                     </form>
                  )}
                  {activeStep === 2 && (
                     <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                        <Grid
                           container
                           justifyContent='space-between'
                           alignItems='center'
                           xs={12}
                           style={{ marginTop: 15, paddingInline: 20 }}
                        >
                           <Grid
                              item
                              container
                              style={{
                                 overflow: 'overlay',
                                 maxHeight: 'calc(72vh - 60px)',
                              }}
                           >
                              <ModalTitle>{t.settings_whatsapp_b_business_details_title}</ModalTitle>
                              <ModalLargeText>{t.settings_whatsapp_b_business_details_description}</ModalLargeText>
                              <SingleInput
                                 control={control}
                                 name='entity_name'
                                 required={false}
                                 label={t.settings_whatsapp_b_business_details_input_entity_name}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                 }}
                                 placeholder={t?.settings_whatsapp_b_business_details_input_entity_name}
                              />
                              <DoubleInput
                                 control1={control}
                                 name1='business_vertical'
                                 isSelect1
                                 required1={false}
                                 selectOptions1={businessVerticals}
                                 label1={t.settings_whatsapp_b_business_details_input_business_veritcal}
                                 rules1={{
                                    required: t?.please_fill_this_field,
                                 }}
                                 placeholder1={t?.settings_whatsapp_b_business_details_input_business_veritcal}
                                 control2={control}
                                 name2='language'
                                 isSelect2
                                 required2={false}
                                 selectOptions2={LangCodes}
                                 label2={t.settings_whatsapp_b_business_details_input_language}
                                 rules2={{
                                    required: t?.please_fill_this_field,
                                 }}
                                 placeholder2={t?.settings_whatsapp_b_business_details_input_language}
                              />
                              <SingleInput
                                 control={control}
                                 name='address'
                                 required={false}
                                 label={t.settings_whatsapp_b_business_details_input_address}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                 }}
                                 placeholder={t?.settings_whatsapp_b_business_details_input_address}
                              />
                              <Grid container justifyContent='space-between' alignItems='center' xs={12}>
                                 <Grid
                                    style={{
                                       paddingRight: 15,
                                    }}
                                    item
                                    xs={6}
                                 >
                                    <InputLabel
                                       shrink={false}
                                       className={classes.pb8}
                                       style={{
                                          marginBottom: 8,
                                          color: errors?.country && 'red',
                                       }}
                                    >
                                       {t?.country}
                                    </InputLabel>
                                    <Controller
                                       name='country'
                                       control={control}
                                       rules={{
                                          required: t?.country_field_is_required,
                                          validate: (value) => {
                                             if (value.value) {
                                                return true;
                                             }
                                             return false;
                                          },
                                       }}
                                       render={({ field }) => (
                                          <SearchSelect
                                             isDisabled={countryLoading}
                                             options={countryData?.locations?.data?.map(({ name, id }) => ({
                                                value: id,
                                                label: name,
                                             }))}
                                             value={watch('country')}
                                             onChange={(value) => {
                                                setValue('country', value);
                                             }}
                                          />
                                       )}
                                    />
                                 </Grid>

                                 <Grid
                                    style={{
                                       paddingLeft: 15,
                                    }}
                                    item
                                    xs={6}
                                 >
                                    <InputLabel
                                       shrink={false}
                                       className={classes.pb8}
                                       style={{
                                          marginBottom: 8,
                                          color: errors?.state && 'red',
                                       }}
                                    >
                                       {t?.state}
                                    </InputLabel>
                                    <Controller
                                       name='state'
                                       control={control}
                                       rules={{
                                          required: t?.this_field_is_required,
                                          validate: (value) => {
                                             if (value.value) {
                                                return true;
                                             }
                                             return false;
                                          },
                                       }}
                                       render={({ field }) => (
                                          <SearchSelect
                                             isDisabled={stateLoading}
                                             options={stateData?.locations?.data?.map(({ name, id }) => ({
                                                value: id,
                                                label: name,
                                             }))}
                                             value={watch('state')}
                                             onChange={(value) => {
                                                setValue('state', value);
                                             }}
                                          />
                                       )}
                                    />
                                 </Grid>
                              </Grid>

                              <Grid container justifyContent='space-between' alignItems='center' xs={12}>
                                 <Grid
                                    style={{
                                       paddingRight: 15,
                                    }}
                                    item
                                    xs={6}
                                 >
                                    <InputLabel
                                       shrink={false}
                                       className={classes.pb8}
                                       style={{
                                          marginBottom: 8,
                                          color: errors?.city && 'red',
                                       }}
                                    >
                                       {t?.city}
                                    </InputLabel>
                                    <Controller
                                       name='city'
                                       control={control}
                                       rules={{
                                          required: t?.this_field_is_required,
                                          validate: (value) => {
                                             if (value.value || cityData?.locations?.data?.length === 0) {
                                                return true;
                                             }
                                             return false;
                                          },
                                       }}
                                       render={({ field }) => (
                                          <SearchSelect
                                             isDisabled={cityLoading || cityData?.locations?.data?.length === 0}
                                             options={cityData?.locations?.data?.map(({ name, id }) => ({
                                                value: id,
                                                label: name,
                                             }))}
                                             value={watch('city')}
                                             onChange={(value) => {
                                                setValue('city', value);
                                             }}
                                          />
                                       )}
                                    />
                                 </Grid>
                                 <Grid className={classes.inputFix} item xs={6}>
                                    <InputLabel shrink={false}>
                                       {t?.settings_whatsapp_b_business_details_input_zip_code}
                                    </InputLabel>
                                    <Input
                                       control={control}
                                       name='zip_code'
                                       required={false}
                                       rules={{
                                          required: t?.please_fill_this_field,
                                       }}
                                       placeholder={t?.settings_whatsapp_b_business_details_input_zip_code}
                                    />
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                        {/* Submit Button */}
                        <Grid container justifyContent='space-between' className={classes.btnNext}>
                           <Button
                              onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                              className={classes.modalButtons}
                              style={{
                                 color: '#212121',
                                 backgroundColor: '#eeee',
                                 '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                 },
                              }}
                           >
                              {t?.back}
                           </Button>
                           <ButtonOK
                              className={classes.modalButtons}
                              label={t?.comp_button_submit}
                              type='submit'
                              style={{
                                 color: 'white',
                                 backgroundColor: '#157CFC',
                                 '&:hover': {
                                    backgroundColor: '#105CBB',
                                 },
                                 float: 'right',
                              }}
                           />
                        </Grid>
                     </form>
                  )}

                  <Grid
                     item
                     container
                     justifyContent='space-between'
                     className={activeStep !== 0 ? null : classes.btnNext}
                  >
                     <Button
                        onClick={onCancel}
                        className={classes.modalButtons}
                        style={{
                           color: '#212121',
                           backgroundColor: '#eeee',
                           '&:hover': {
                              backgroundColor: '#e0e0e0',
                           },
                           display: activeStep !== 0 ? 'none' : undefined,
                        }}
                     >
                        {t?.comp_button_cancel}
                     </Button>
                     <ButtonOK
                        label={t?.next}
                        onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                        className={classes.modalButtons}
                        style={{
                           color: 'white',
                           backgroundColor: '#157CFC',
                           '&:hover': {
                              backgroundColor: '#105CBB',
                           },
                           float: 'right',
                           display: activeStep !== 0 ? 'none' : undefined,
                        }}
                     />
                  </Grid>
               </Grid>
            )}
         </Fragment>
      </Grid>
   );
};

export default ModalContent;

import Input from '@components/InputField';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import ButtonFields from './formUtilities/ButtonFields';
import CustomTextArea from './formUtilities/CustomTextArea';
import FirstForm from './formUtilities/FirstForm';
import SampleMedia from './formUtilities/SampleMedia';
import VariableField from './formUtilities/VariableField';
import { INewTemplates } from './types';
const useStyles = makeStyles((theme) => ({
   templateForm: {
      width: '100%',
   },
   form: {
      padding: 20,
   },
}));

const NewTemplateForms = ({
   isView,
   watch,
   setValue,
   resetField,
   handleMedia,
   control,
   onRemove,
   register,
   visibilityOfInputs,
   setVisibilityOfInputs,
   onAdd,
   isSmallScreen,
   handleCallUs,
   handleWebUrl,
   openButtonPanel,
   setOpenButtonPanel,
   openQuickReply,
   setOpenQuickReply,
   selectedButtonTop,
   setSelectedButtonTop,
   selectedButtonBottom,
   setSelectedButtonBottom,
   customFields
}: INewTemplates) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   let templateType = (watch('templateType')?.value || watch('templateType'));
   return (
      <Grid container className={classes.templateForm}>
         <Grid container direction='column' className={classes.form}>
            <Grid>
               <FirstForm
                  watch={watch}
                  isView={isView}
                  setValue={setValue}
                  register={register}
                  control={control}
                  isSmallScreen={isSmallScreen}
                  handleMedia={handleMedia}
               />
            </Grid>
            {(templateType === "IMAGE" || templateType === "VIDEO" || templateType === "DOCUMENT") && (
               <Grid>
                  <SampleMedia _templateType={templateType} isView={isView} watch={watch} setValue={setValue} />
               </Grid>
            )}
            <Grid>
               <Typography variant='h6'>{t['settings_whatsapp_business_template_message_title']}</Typography>
               <CustomTextArea
                  watch={watch}
                  register={register}
                  isView={isView}
                  setValue={setValue}
               />
            </Grid>
            {
               watch('qpienFields').length !== 0 && (
                  <Grid>
                     <VariableField
                        control={control}
                        isView={isView}
                        watch={watch}
                        register={register}
                        setValue={setValue}
                        customFields={customFields}
                     />
                  </Grid>
               )
            }
            {
               (watch('footer') === "" || !isView) && (
                  <Grid>
                     <Typography variant='h6' style={{
                        marginBottom: -12,
                     }}>{t['settings_whatsapp_business_template_example_message_footer']}</Typography>
                     <Input
                        disabled={isView}
                        {...register('footer', {
                           maxLength: 60
                        })}
                        style={{ marginTop: '10px !important' }}
                        control={control}
                        fullWidth
                        placeholder={t['settings_whatsapp_business_template_example_message_footer_placeholder']}
                        variant='outlined'
                     />
                  </Grid>
               )
            }
            <Grid>
               <ButtonFields
                  selectedButtonTop={selectedButtonTop}
                  setSelectedButtonTop={setSelectedButtonTop}
                  selectedButtonBottom={selectedButtonBottom}
                  setSelectedButtonBottom={setSelectedButtonBottom}
                  openButtonPanel={openButtonPanel}
                  setOpenButtonPanel={setOpenButtonPanel}
                  openQuickReply={openQuickReply}
                  setOpenQuickReply={setOpenQuickReply}
                  watch={watch}
                  isView={isView}
                  resetField={resetField}
                  handleMedia={handleMedia}
                  control={control}
                  onRemove={onRemove}
                  setValue={setValue}
                  register={register}
                  visibilityOfInputs={visibilityOfInputs}
                  setVisibilityOfInputs={setVisibilityOfInputs}
                  onAdd={onAdd}
                  isSmallScreen={isSmallScreen}
                  handleWebUrl={handleWebUrl}
                  handleCallUs={handleCallUs}
               />
            </Grid>
         </Grid>
      </Grid>
   );
};

export default NewTemplateForms;

import Input from '@components/InputField';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import React, { useContext, useEffect } from 'react';
import { IButton } from '../types';
import QuickReplies from './QuickReplies';
import SampleURL from './SampleURL';
import { useLazyQuery } from '@apollo/client';
import { GET_CUSTOMER_SATISFACTION_SURVEYS } from '@queries/Settings/Service/GraphQL/CustomerSatisfaction/query';
import { ISurveys } from '@modules/Settings/SettingSpace/CustomerSatisfaction/customerSatisfaction';

const useStyles = makeStyles(() => ({
   buttonField: {
      paddingBottom: 100,
   },
   title: {
      fontSize: 20,
      paddingBottom: 10,
      paddingLeft: 4,
   },
   bottomButtonsArea: {
      border: '1px solid lightgray',
      borderRadius: 12,
      paddingTop: 20,
      paddingInline: 15,
      backgroundColor: '#fafafa',
   },
   bottomButtons: {
      width: 44,
      height: 44,
      '&.MuiButton-root': {
         minWidth: 40,
      },
      borderRadius: 10,
      fontSize: 28,
      marginBottom: 2,
   },
}));

const ButtonFields = ({
   watch,
   isView,
   resetField,
   handleMedia,
   control,
   isSmallScreen,
   handleCallUs,
   handleWebUrl,
   onAdd,
   visibilityOfInputs,
   register,
   onRemove,
   setVisibilityOfInputs,
   setValue,

   openButtonPanel,
   setOpenButtonPanel,

   openQuickReply,
   setOpenQuickReply,
   selectedButtonTop,
   setSelectedButtonTop,
   selectedButtonBottom,
   setSelectedButtonBottom,
}: IButton) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const addButtonPanel = (data: any) => {
      if (data.value === 'urlOrPhoneButton') {
         setSelectedButtonTop(true);
         handleWebUrl('template_custom');
         setOpenButtonPanel(true);
         setOpenQuickReply(false);
         resetField('input0');
         resetField('input1');
         resetField('input2');
         setVisibilityOfInputs([false, false, false]);
      } else if (data.value === 'quickReplies') {
         setOpenQuickReply(true);
         setOpenButtonPanel(false);
         resetField('buttonTextweb');
         resetField('buttonTextphoneName');
         resetField('buttonTextphoneNumber');
         resetField('websiteUrl');
         resetField('variable');
         resetField('phone');
         handleWebUrl('no_template_variable');
         handleCallUs(false);
         setSelectedButtonTop(false);
         setSelectedButtonBottom(true);
         resetField('sampleUrl');
      } else if (data.value === 'noButton') {
         setVisibilityOfInputs([false, false, false]);
         setSelectedButtonTop(false);
         setSelectedButtonBottom(true);
         handleWebUrl('no_template_variable');
         handleCallUs(false);
         setOpenQuickReply(false);
         setOpenButtonPanel(false);
         resetField('buttonTextweb');
         resetField('buttonTextphoneName');
         resetField('buttonTextphoneNumber');
         resetField('websiteUrl');
         resetField('variable');
         resetField('input0');
         resetField('input1');
         resetField('input2');
         resetField('phone');
         resetField('sampleUrl');
      }
   };

   const handleCheckBoxTop = (params?: any) => {
      if (params) setSelectedButtonTop(params);
      else setSelectedButtonTop(!selectedButtonTop);
   };
   const handleCheckBoxBottom = () => {
      setSelectedButtonBottom(!selectedButtonBottom);
   };

   const surveyPage = {
      pageSize: 15,
      page: 1,
   };

   const satisfactionCustomerParam = {
      customer: watch('customer'),
      pageSize: surveyPage.pageSize,
      pageNumber: surveyPage.page,
      name: '',
      status: true,
   };

   const [getSatisfactionData, { data: satisfactionCustomerData }] = useLazyQuery(GET_CUSTOMER_SATISFACTION_SURVEYS, {
      fetchPolicy: 'no-cache',
   });

   useEffect(() => {
      if (watch('variable.value') === 'template_survey_url') {
         getSatisfactionData({
            variables: satisfactionCustomerParam,
         });
      }
   }, [watch('variable.value')]);

   return (
      <Grid className={classes.buttonField}>
         <Grid className={classes.title}>
            <Typography variant='inherit'>
               {t['settings_whatsapp_business_template_form_button_fields_header']}
            </Typography>
         </Grid>
         <Grid>
            <Typography style={{ paddingLeft: 3 }}>
               {t['settings_whatsapp_business_template_form_button_fields_text']}
            </Typography>
         </Grid>
         <Grid xs={12} md={5}>
            <Input
               disabled={isView}
               onChange={(data: any) => {
                  addButtonPanel(data);
                  if (data.value !== 'urlOrPhoneButton') {
                     setValue('survey', null);
                     setValue('eCommerceCart', false);
                  }
               }}
               selectDefaultValue={watch('addButtonPanel')}
               control={control}
               name='addButtonPanel'
               placeholder={t['settings_whatsapp_business_template_form_button_fields_no_button']}
               isSelect
               selectOptions={[
                  { value: 'noButton', label: t['settings_whatsapp_business_template_form_button_fields_no_button'] },
                  {
                     value: 'urlOrPhoneButton',
                     label: t['settings_whatsapp_business_template_form_button_fields_url_button'],
                  },
                  {
                     value: 'quickReplies',
                     label: t['settings_whatsapp_business_template_form_button_fields_quick_replies'],
                  },
               ]}
            />
         </Grid>
         {openButtonPanel && (
            <Grid style={{ marginLeft: { isSmallScreen } && -10 }} className={classes.bottomButtonsArea}>
               <Grid container alignItems='center' spacing={2}>
                  {!isView && (
                     <Grid item xs={12} md={12} lg={1}>
                        <Button
                           onClick={() => {
                              handleCheckBoxTop();
                              handleWebUrl();
                           }}
                           className={classes.bottomButtons}
                           variant='outlined'
                        >
                           <FiberManualRecordIcon
                              fontSize='inherit'
                              color={!selectedButtonTop ? 'disabled' : 'primary'}
                           />
                        </Button>
                     </Grid>
                  )}
                  <Grid item xs={12} md={12} lg={4}>
                     {' '}
                     <Input
                        maxLength={25}
                        disabled={!selectedButtonTop || isView}
                        background='white'
                        control={control}
                        {...register('buttonTextweb')}
                        label={t['settings_whatsapp_business_template_form_button_fields_button_text']}
                        placeholder={t['settings_whatsapp_business_template_form_button_fields_visit_website']}
                        rules={{
                           required: selectedButtonTop,
                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={12} lg={isView ? 8 : 4}>
                     <Input
                        disabled={!selectedButtonTop || isView || watch('variable.value') === 'template_survey_url'}
                        background='white'
                        control={control}
                        label={t['template_website_url']}
                        placeholder={t['settings_whatsapp_business_template_form_button_fields_enter_url']}
                        {...register('buttonTextwebUrl')}
                        rules={{
                           required: selectedButtonTop,
                           validate: (v: any) => {
                              const regex =
                                 /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/gm;
                              const result = regex.test(v);
                              return result;
                           },
                        }}
                     />
                  </Grid>
                  {!isView && (
                     <Grid item xs={12} md={12} lg={3}>
                        <Input
                           disabled={!selectedButtonTop || isView}
                           selectDefaultValue={watch('variable')}
                           name='variable'
                           control={control}
                           onChange={(data: any) => {
                              setValue(`variable`, data);
                              setValue(
                                 `buttonTextwebUrl`,
                                 data.value === 'template_survey_url'
                                    ? t['settings_whatsapp_business_template_form_button_fields_survey_url']
                                    : '',
                              );
                              setValue(`buttonTextwebExample`, '');
                              setValue('survey', null);
                              setValue('eCommerceCart', watch('variable.value') === 'template_cart_url');
                           }}
                           label={t['template_variable']}
                           placeholder={t['settings_whatsapp_business_template_form_button_fields_no_button']}
                           isSelect
                           selectOptions={[
                              { value: 'template_custom', label: t['template_custom'] },
                              { value: 'template_cart_url', label: t['template_cart_url'] },
                              { value: 'template_survey_url', label: t['template_survey_url'] },
                              {
                                 value: 'no_template_variable',
                                 label: t['no_template_variable'],
                              },
                           ]}
                        />
                     </Grid>
                  )}
               </Grid>
               <Grid container alignItems='center' spacing={2}>
                  {!isView && (
                     <Grid item xs={12} md={12} lg={1}>
                        <Button
                           onClick={() => {
                              handleCheckBoxBottom();
                              handleCallUs();
                           }}
                           className={classes.bottomButtons}
                           variant='outlined'
                        >
                           <FiberManualRecordIcon
                              fontSize='inherit'
                              color={selectedButtonBottom ? 'disabled' : 'primary'}
                           />
                        </Button>
                     </Grid>
                  )}
                  <Grid item xs={12} md={12} lg={3}>
                     <Input
                        maxLength={25}
                        disabled={selectedButtonBottom || isView}
                        background='white'
                        control={control}
                        {...register('buttonTextphoneName')}
                        label={t['settings_whatsapp_business_template_form_button_fields_button_text']}
                        placeholder={t['settings_whatsapp_business_template_form_button_fields_call_us']}
                        rules={{
                           required: !selectedButtonBottom,
                        }}
                     />
                  </Grid>
                  <Grid item xs={12} md={12} lg={8}>
                     <Input
                        disabled={selectedButtonBottom || isView}
                        name='buttonTextphoneNumber'
                        label={t?.page_contacts_typo_phone}
                        isPhone
                        splitCountryCode={true}
                        control={control}
                        rules={{
                           required: !selectedButtonBottom,
                        }}
                     />
                  </Grid>
               </Grid>
               {selectedButtonTop && !isView && watch('variable.value') === 'template_survey_url' && (
                  <Grid item xs={12}>
                     <Input
                        disabled={!selectedButtonTop || isView}
                        name='survey'
                        control={control}
                        onChange={(data: { value: string; label: string }) => {
                           setValue('survey', data.value);
                           setValue('buttonTextwebExample', '1a2b3c4d');
                        }}
                        label={t['template_survey']}
                        placeholder={t['settings_whatsapp_business_template_form_survey_select_placeholder']}
                        isSelect
                        selectOptions={satisfactionCustomerData?.getSurveys.data?.map((survey: ISurveys) => {
                           return { value: survey._id, label: `${survey.title} - ${survey.message}` };
                        })}
                     />
                  </Grid>
               )}
               {selectedButtonTop && !isView && watch('variable.value') !== 'no_template_variable' && (
                  <SampleURL
                     watch={watch}
                     isView={isView}
                     setValue={setValue}
                     register={register}
                     isSmallScreen={isSmallScreen}
                     handleMedia={handleMedia}
                     control={control}
                  />
               )}
            </Grid>
         )}
         {openQuickReply && (
            <QuickReplies
               isView={isView}
               resetField={resetField}
               control={control}
               onRemove={onRemove}
               register={register}
               visibilityOfInputs={visibilityOfInputs}
               onAdd={onAdd}
            />
         )}
      </Grid>
   );
};

export default ButtonFields;

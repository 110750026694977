import Input from '@components/InputField';
import { LanguageContext } from '@helper/locale/langContext';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';

const useStyles = makeStyles(() => ({
    customInput: {
        width: '100%',
        height: 220,
        borderRadius: 5,
        border: '2px solid #D1D1D1',
        marginTop: '10px',
        marginBottom: '10px',
    },
    inputBar: {
        height: '85%',
        width: '100%',
        '& .MuiPopover-paper': {
            backgroundColor: 'red',
            color: 'yellow',
        },
    },
    textArea: {
        resize: 'none',
        fontSize: '14.7px',
        width: '100%',
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        height: 217,
        border: 'none',
        paddingLeft: 10,
        paddingTop: 10,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        '&:focus-visible': {
            border: 'none !important',
            outline: 'none !important',
        },
        '&:focus': {
            border: 'none !important',
            outline: 'none !important',
        },
    },
    inputBreaker: {
        '& .MuiFormControl-root': {
            marginTop: '0px !important',
        }
    },
    textField: {
        height: 50,
        marginTop: '0px !important',
        '& input': {
            border: 'none',
            height: 19,
        },
        '& .Mui-underline:after': {
            outline: 'none',
            border: 'none !important',
            underline: 'none !important',
        },
        '& .MuiSelect-select': {
            outline: 'none',
            fontSize: 16,
            padding: 3,
        },
        '&:active': {
            outline: 'none',
        },
        '& .MuiOutlinedInput-root': {
            border: 'none !important',
            outline: 'none !important',
            underline: 'none !important',
        },
        '& .MuiOutlinedInput-root::before': {
            border: 'none !important',
            outline: 'none !important',
            underline: 'none !important',
        },
        '& .MuiOutlinedInput-root::before:focus-visible': {
            border: 'none !important',
            outline: 'none !important',
            underline: 'none !important',
        },
        '& .MuiOutlinedInput-root:hover': {
            border: 'none !important',
            outline: 'none !important',
            underline: 'none !important',
        },
        '& .MuiOutlinedInput-root:focus-visible': {
            border: 'none !important',
            outline: 'none !important',
            underline: 'none !important',
        },
        '& .MuiOutlinedInput-root:focus': {
            border: 'none !important',
            outline: 'none !important',
            underline: 'none !important',
        },
        '& .MuiOutlinedInput-underline:after': {
            border: 'none !important',
            outline: 'none !important',
            underline: 'none !important',
        },
    },
    buttonField: {
        paddingBottom: 10,
    },
    title: {
        fontSize: 20,
        paddingBottom: 10,
        paddingLeft: 4,
    },
    variables: {
        height: 50,
        padding: 10,
        marginTop: '0px !important',
        borderRadius: 4,
        border: '2px solid #D1D1D1',
        '& .Mui-underline:after': {
            outline: 'none',
            border: 'none !important',
        },
        '& .MuiSelect-select': {
            outline: 'none',
            fontSize: 16,
            padding: 3,
        },
        '&:active': {
            outline: 'none',
        },
        '& .MuiInput-root::before': {
            border: 'none !important',
        },
        '& .MuiInput-root::before:focus-visible': {
            border: 'none !important',
        },
        '& .MuiInput-root:hover': {
            border: 'none !important',
        },
        '& .MuiInput-root:focus-visible': {
            border: 'none !important',
        },
        '& .MuiInput-root:focus': {
            border: 'none !important',
        },
        '& .MuiInput-underline:after': {
            border: 'none !important',
            underline: 'none !important',
        },
    },
}));

const VariableField = ({ isView, control, register, watch, setValue, customFields }: any) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;
    return (
        <Grid className={classes.buttonField}>
            <Grid className={classes.title}>
                <Typography variant='inherit'>
                    {t['settings_whatsapp_business_template_variables_title']}
                </Typography>
            </Grid>
            <Grid>
                <Typography style={{ paddingLeft: 3 }}>
                    {t['settings_whatsapp_business_template_variables_desc']}
                </Typography>
            </Grid>
            <Grid container direction="row" spacing={1} style={{ marginTop: 5, fontSize: 18 }}>
                <Grid item xs={1}>
                    <Typography align="center">
                        {t["settings_whatsapp_business_template_variables_vnm"]}
                    </Typography>
                </Grid>
                <Grid item xs={4} style={{ marginRight: 10, marginLeft: 5 }}>
                    <Typography align="left">
                        {t["settings_whatsapp_business_template_variables_qpienfield"]}
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <Typography>
                        {t["settings_whatsapp_business_template_variables_example"]}
                    </Typography>
                </Grid>
            </Grid>
            <Grid>
                {
                    watch("qpienFields").map((field: any, index: number) => {
                        return (
                            <Grid container key={index} direction="row" spacing={1} style={{ height: 60, marginBottom: 3 }}>
                                <Grid item xs={1}>
                                    <p style={{ fontSize: 17, color: '#7f7f7f', marginTop: 11, textAlign: "center" }}>{`{{${field.key}}}`}</p>
                                </Grid>
                                <Grid className={classes.inputBreaker} item xs={4} style={{ marginRight: 10, marginLeft: 5 }}>
                                    <Input
                                        rules={{
                                            required: true,
                                        }}
                                        disabled={isView}
                                        onChange={(data: any) => {
                                            setValue(`qpienFields.${index}.value`, data.value)
                                        }}
                                        control={control}
                                        selectDefaultValue={watch(`qpienFields.${index}.value`)}
                                        name='category'
                                        isSelect
                                        placeholder={t['settings_whatsapp_business_template_variables_qpienfield_placeholder']}
                                        selectOptions={[
                                            { value: '', label: t['settings_whatsapp_business_template_variables_qpienfield_placeholder'] },
                                            { value: 'first_name', label: t['tokens_first_name'] },
                                            { value: 'last_name', label: t['tokens_last_name'] },
                                            { value: 'email', label: t['tokens_email'] },
                                            { value: 'phone', label: t['tokens_phone'] },
                                            ...customFields.map((x: { label: string; name: string; }) => ({
                                                value: x.label,
                                                label: `{${x.name}}`
                                            }))
                                        ]}
                                    />

                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        disabled={isView}
                                        {...register(`qpienFields.${index}.exampleData`, { required: true })}
                                        defaultValue={watch(`qpienFields.${index}.exampleData`)}
                                        className={classes.textField}
                                        fullWidth
                                        placeholder={t['settings_whatsapp_business_template_variables_example_placeholder']}
                                        variant='outlined'
                                        size='small'
                                    />
                                </Grid>
                            </Grid>
                        );
                    })
                }
            </Grid>
        </Grid >

    );
};

export default VariableField;

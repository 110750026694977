import FileViewer from '@components/FileViewer';
import { Avatar, Button, CircularProgress, ClickAwayListener, Tooltip, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { SET_EMAIL_DATA } from '@store/actions/chat';
import clsx from 'clsx';
import React, { Fragment, useContext, useEffect, useState } from 'react';
// import { trimLongText } from '@helper/textUtils';
import { LanguageContext } from '@helper/locale/langContext';
// import useSeeMore from '../../../../../hooks/useSeeMore';
import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import LinkPreview from '@components/LinkPreview';
import LinkedFormattedText from '@components/LinkedFormattedText';
import Modal from '@components/Modal/modal';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import TranslateIcon from '@material-ui/icons/Translate';
import { Alert } from '@material-ui/lab';
import { RE_SEND_MESSAGE_MUTATION } from '@queries/Chat/Service/GraphQL/Message/mutation';
import { GET_TRANSLATE_TEXT } from '@queries/Translate/query';
import LinkifyIt from 'linkify-it';
import { useDispatch, useSelector } from 'react-redux';
import EmailBody from './emailBody';
import EmailExpandHeader from './emailExpandHeader';

const linkify = new LinkifyIt();

export const AVATAR_SIZE = 30;

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: 4,
      marginBottom: 4,
      marginRight: 20,
   },
   content: {
      borderRadius: 10,
      borderTopLeftRadius: 0,
      padding: (props) => (props?.medias?.length > 0 ? 4 : 10),
      paddingRight: 15,
      paddingLeft: 2,
      marginRight: 10,
      maxWidth: (props) =>
         props?.medias?.length > 0 || (props.text && linkify.test(props.text)) ? 400 : 400,
      minWidth: 100,
      textAlign: 'left',
      wordBreak: 'break-word',
      backgroundColor: (props) =>
         props.isMine ? (props.isPrivate ? '#FFE9A0' : '#F2F7FF') : '#F7F7FA',
      position: 'relative',
   },
   contentExpand: {
      borderRadius: 10,
      borderTopLeftRadius: 0,
      padding: '10px 10px 4px 10px',
      paddingRight: 15,
      paddingLeft: 2,
      marginRight: 10,
      width: '100%',
      minWidth: 100,
      textAlign: 'left',
      wordBreak: 'break-word',
      backgroundColor: (props) =>
         props.isMine ? (props.isPrivate ? '#FFE9A0' : '#F2F7FF') : '#F8F8F8',
      position: 'relative',
   },
   isMine: {
      borderRadius: 10,
      borderTopRightRadius: 0,
   },

   metaData: {
      fontSize: '0.8rem',
      marginTop: 5,
      color: '#7F92A5',
   },
   subjectContainer: {
      padding: (props) => (props?.medias?.length > 0 && props.text ? '0.5rem' : '0 0 0.5rem 0'),
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   textContainer: {
      padding: (props) => (props?.medias?.length > 0 && props.text ? 5 : 0),
      flexDirection: 'column',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   textWrapper: {
      display: 'flex',
      flexDirection: (props) => (props.isMine ? 'row-reverse' : 'row'),
      gap: '10px',
      width: '100%',
      alignItems: 'flex-start',
      '& > p': {
         flex: '1',
      },
   },
   tooltip: {
      backgroundColor: (props) => (props.isMine ? '#F2F7FF' : '#F7F7FA'),
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
   },
   text: {
      marginRight: 4,
      padding: 0,
      fontSize: '0.95rem',
      color: '#2C2C2C',
   },
   fontW: {
      fontWeight: 550,
   },

   companyMessage: {
      width: '100%',
      marginRight: 4,
      padding: 0,
      fontSize: '0.95rem',
      color: '#2C2C2C',
      whiteSpace: 'pre-line',
      '& div[role=alert]': {
         alignItems: 'center',
         justifyContent: 'center',
      },
   },
   mediaContainer: {
      marginTop: (props) => (props?.medias?.length > 0 ? 6 : undefined),
   },
   avatarContainer: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      display: 'flex',
      alignSelf: 'flex-end',
   },
   avatar: {
      width: AVATAR_SIZE,
      height: AVATAR_SIZE,
      borderRadius: AVATAR_SIZE / 2,
   },
   senderNameAndTime: {
      color: '#868686',
      marginRight: AVATAR_SIZE + 10,
      marginLeft: 10,
      fontSize: '0.7rem',
   },
   messageAck: {
      position: 'absolute',
      left: '-25px',
      height: '100%',
      top: 4,
      justifyContent: 'center',
      display: 'flex',
      color: '#848484',
      // paddingLeft: 7px;
      alignItems: 'end',
   },
   modalContainer: {
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 16,
   },
   modalHeader: {
      height: 50,
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalBody: {
      paddingInline: 20,
      paddingBottom: 15,
   },
   modalBodyIconContainer: {
      display: 'flex',
      flexDirection: 'column',
   },
   modalDescription: {
      textAlign: 'center',
      paddingBlock: 30,
   },
   modalBodyRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
   },
   modalText: {
      color: '#797575',
      fontSize: '1rem',
      margin: 20,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   messageButton: {
      '-webkit-user-select': 'text',
      '-moz-user-select': 'text',
      '-ms-user-select': 'text',
      'user-select': 'text',
   },
   translateBtn: {
      cursor: 'pointer',
      backgroundColor: (props) => (props.open ? '#bed9ff' : '#dbdbdb'),
      padding: '3px',
      minWidth: '0px',
      justifyContent: (props) => (props.isMine ? 'flex-start' : 'flex-end'),
      '&:hover': {
         backgroundColor: (props) => (props.open ? '#bed9ff' : '#dbdbdb'),
      },
   },
   translateIcon: {
      color: (props) => (props.open ? '#0268e2' : '#00000080'),
   },
   tooltipContainer: {
      '& div:first-child': {
         minWidth: '250px',
         backgroundColor: '#0268e2',
         color: '#fff',
         fontSize: '.75rem',
         maxWidth: 'auto',
         animation: 'none',
         '&  > span': {
            color: '#0268e2',
         },
         '& svg': {
            width: 'inherit',
            height: 'inherit',
            margin: 'auto',
         },
      },
   },
}));

const Messages = ({
   isMine,
   text,
   time,
   isAutoCreate,
   ack,
   emailData,
   subject,
   contentHtml,
   senderTitle,
   medias,
   privateReplyQnaId,
   senderPhoto,
   showAvatarOnMessage,
   isSending,
   isBroadcast,
   isBulkMessage,
   messageObj = null,
   isPrivate = false,
   isEmail,
}) => {
   const [open, setOpen] = useState(false);
   const classes = useStyles({ isMine, medias, text, isPrivate, open });
   const { lang } = useContext(LanguageContext);
   const [getTranslateText, { data: translatedTextData, loading: translatedTextLoading }] =
      useLazyQuery(GET_TRANSLATE_TEXT);
   const t = lang?.translation;
   // const length = 1000;
   // const [showMore, handleSeeMore] = useSeeMore(text, length);
   const [showDoubleThick, setShowDoubleThick] = useState(false);
   const [isResending, setIsResending] = useState(false);
   const [changedAckStatus, setChangedAckStatus] = useState(false);
   const [showFailedRepliedModal, setShowFailedRepliedModal] = useState(false);
   const [timeVisible, setTimeVisible] = useState(false);
   const dispatch = useDispatch();

   // override styles for the media component..
   const styles = {
      image: {
         maxWidth: '100%',
         maxHeight: 338,
      },
   };
   const activeChat = useSelector((rootReducer) => rootReducer.chatReducer.activeChat);
   const [reSendMessageMutation, { loading: reSendMessageLoading }] =
      useMutation(RE_SEND_MESSAGE_MUTATION);
   const me = useSelector((rootReducer) => rootReducer.personReducer.person);

   const reSendMessage = async () => {
      setShowFailedRepliedModal(false);
      setIsResending(true);
      if (messageObj) {
         reSendMessageMutation({
            variables: {
               ...messageObj,
               sender: me._id,
               chat: activeChat._id,
            },
         }).then(() => {
            setTimeout(() => setIsResending(false), 1000);
         });
      }
   };

   useEffect(() => {
      if (ack == 0) {
         setChangedAckStatus(true);
      }
      if (ack == 2) {
         if (changedAckStatus) {
            setTimeout(() => {
               setShowDoubleThick(true);
            }, 500);
         } else {
            setShowDoubleThick(true);
         }
      }
   }, [ack]);

   const toggleTimeVisible = () => {
      setTimeVisible(!timeVisible);
   };
   const [isExpanded, setIsExpanded] = useState(false);
   const [showReplyIcon, setShowReplyIcon] = useState(false);

   const handleMouseEnter = () => {
      setShowReplyIcon(true);
   };

   const handleMouseLeave = () => {
      setShowReplyIcon(false);
   };

   const handleReplyClick = (e) => {
      // e.stopPropagation();
      dispatch({
         type: SET_EMAIL_DATA,
         payload: messageObj,
      });
   };

   const handleToggleExpand = () => {
      setIsExpanded(!isExpanded);
      setTimeVisible(!timeVisible);
      setOpen(false);
   };

   const handleFalidResend = (e) => {
      e.stopPropagation();
      setShowFailedRepliedModal(true);
   };

   const handleTooltipOpen = (event) => {
      event.stopPropagation();
      getTranslateText({ variables: { text: text, lang: me.language } });
      setOpen(!open);
   };
   return (
      <div className={classes.root}>
         {isEmail ? (
            <Grid item container direction='column'>
               <Grid
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  item
                  container
                  className={classes.messageButton}
                  justifyContent={isMine ? 'flex-end' : 'flex-start'}
               >
                  {!isPrivate && isMine && showReplyIcon && !isExpanded && (
                     <div style={{ zIndex: '10', marginRight: '1rem' }}>
                        <ReplyAllIcon
                           style={{ cursor: 'pointer' }}
                           onClick={() => handleReplyClick()}
                        />
                     </div>
                  )}
                  <div
                     className={clsx(
                        isExpanded ? classes.contentExpand : classes.content,
                        isMine && classes.isMine,
                     )}
                  >
                     <Grid direction='column'>
                        <div
                           style={{
                              padding: isExpanded ? '0' : '0',
                           }}
                        >
                           {isExpanded ? (
                              <div
                                 style={{
                                    display: 'grid',
                                    gridTemplateColumns: '5fr 1fr',
                                    borderBottom: '0.5px solid #DEDEDE',
                                    margin: '0 0.5rem',
                                 }}
                              >
                                 <EmailExpandHeader
                                    isPrivate={isPrivate}
                                    toggleTimeVisible={toggleTimeVisible}
                                    handleToggleExpand={handleToggleExpand}
                                    emailData={emailData}
                                    isEmail={isEmail}
                                    subject={subject}
                                    timeVisible={timeVisible}
                                    isMine={isMine}
                                    time={time}
                                    isAutoCreate={isAutoCreate}
                                    senderTitle={senderTitle}
                                    handleReplyClick={handleReplyClick}
                                 />
                              </div>
                           ) : (
                              <div
                                 onClick={
                                    isPrivate ? toggleTimeVisible : !isSending && handleToggleExpand
                                 }
                                 style={{ fontWeight: 550, cursor: 'pointer', fontSize: '16px' }}
                                 className={`${classes.subjectContainer} email-expand-header-container`}
                              >
                                 {subject}
                              </div>
                           )}
                           <EmailBody
                              medias={medias}
                              isExpanded={isExpanded}
                              text={text}
                              contentHtml={contentHtml}
                           />
                        </div>
                        {privateReplyQnaId && (
                           <Grid container className={classes.textContainer}>
                              <Typography className={classes.text}>
                                 <Alert severity='info'>
                                    {t['qna_page_private_replied']}
                                    <a
                                       href={`${window.location.origin}/questions/${privateReplyQnaId}`}
                                       target='_blank'
                                       rel='noopener noreferrer'
                                    >
                                       {t['qna_page_private_see_comment']}
                                    </a>
                                 </Alert>
                              </Typography>
                           </Grid>
                        )}
                        {isBroadcast && (
                           <Grid
                              container
                              className={classes.textContainer}
                              style={{ width: '100%' }}
                           >
                              <Typography className={classes.companyMessage}>
                                 <Alert severity='info'>{t['chat_reply_campaign']}</Alert>
                              </Typography>
                           </Grid>
                        )}
                        {isBulkMessage && (
                           <Grid
                              container
                              className={classes.textContainer}
                              style={{ width: '100%' }}
                           >
                              <Typography className={classes.companyMessage}>
                                 <Alert severity='info'>{t['chat_reply_bulk_message']}</Alert>
                              </Typography>
                           </Grid>
                        )}
                     </Grid>
                     {isMine && (
                        <Grid item className={classes.messageAck}>
                           {(ack == 0 || isResending || (isPrivate && isSending)) && (
                              <CircularProgress
                                 style={{ marginRight: 10, padding: 2 }}
                                 size={15}
                                 color={'#848484'}
                              />
                           )}
                           {ack == 1 && !isResending && (
                              <CheckIcon style={{ marginRight: 10, padding: 2 }} fontSize='small' />
                           )}
                           {ack == 2 && !isResending && !showDoubleThick && (
                              <CheckIcon style={{ marginRight: 10, padding: 2 }} fontSize='small' />
                           )}
                           {((ack == 2 && !isResending && showDoubleThick) ||
                              (isPrivate && !isSending)) && (
                              <Grid item>
                                 <CheckIcon style={{ padding: 2 }} fontSize='small' />
                                 <CheckIcon
                                    style={{
                                       padding: 2,
                                       marginRight: '-6px',
                                       position: 'absolute',
                                       right: '2px',
                                    }}
                                    fontSize='small'
                                 />
                              </Grid>
                           )}
                           {ack == 3 && !isResending && (
                              <ErrorIcon
                                 style={{
                                    padding: 0,
                                    color: 'red',
                                    marginBottom: 2,
                                    cursor: 'pointer',
                                 }}
                                 fontSize='small'
                                 onClick={handleFalidResend}
                              />
                           )}
                        </Grid>
                     )}
                  </div>
                  {!isPrivate && !isMine && showReplyIcon && !isExpanded && (
                     <div style={{ zIndex: '10', marginLeft: '0.5rem' }}>
                        <ReplyAllIcon style={{ cursor: 'pointer' }} onClick={handleReplyClick} />
                     </div>
                  )}
                  <div className={classes.avatarContainer}>
                     {showAvatarOnMessage && (
                        <Avatar src={senderPhoto} className={classes.avatar} />
                     )}
                  </div>
               </Grid>
               {isPrivate && timeVisible && (
                  <Grid container justifyContent={isMine ? 'flex-end' : 'flex-start'}>
                     <Typography className={classes.senderNameAndTime}>
                        {!isMine
                           ? `${time}`
                           : `${isAutoCreate ? t?.from_phone : senderTitle} | ${time}`}
                     </Typography>
                  </Grid>
               )}
            </Grid>
         ) : (
            <Grid item container direction='column'>
               <Grid
                  item
                  container
                  onClick={toggleTimeVisible}
                  className={classes.messageButton}
                  justifyContent={isMine ? 'flex-end' : 'flex-start'}
               >
                  <div className={clsx(classes.content, isMine && classes.isMine)}>
                     <Grid direction='column'>
                        {medias?.map((media) => (
                           <Fragment key={media.path}>
                              <Grid
                                 container
                                 justifyContent='center'
                                 className={classes.mediaContainer}
                              >
                                 <FileViewer
                                    showPreview
                                    type={media.type}
                                    url={media.path}
                                    styles={styles}
                                    onShow={(e) => {}}
                                 />
                              </Grid>
                           </Fragment>
                        ))}
                        {privateReplyQnaId && (
                           <Grid container className={classes.textContainer}>
                              <Typography className={classes.text}>
                                 <Alert severity='info'>
                                    {t['qna_page_private_replied']}
                                    <a
                                       href={`${window.location.origin}/questions/${privateReplyQnaId}`}
                                       target='_blank'
                                       rel='noopener noreferrer'
                                    >
                                       {t['qna_page_private_see_comment']}
                                    </a>
                                 </Alert>
                              </Typography>
                           </Grid>
                        )}
                        {isBroadcast && (
                           <Grid
                              container
                              className={classes.textContainer}
                              style={{ width: '100%' }}
                           >
                              <Typography className={classes.companyMessage}>
                                 <Alert severity='info'>{t['chat_reply_campaign']}</Alert>
                              </Typography>
                           </Grid>
                        )}

                        <Grid container className={classes.textContainer}>
                           <div className={classes.textWrapper}>
                              {
                              text && (
                              <Typography className={classes.text}>
                                 {text && <LinkedFormattedText text={text} />}
                              </Typography>
                              )}
                              {me.isTranslate && text && (
                                 <ClickAwayListener onClickAway={() => setOpen(false)}>
                                    <div>
                                       <Tooltip
                                          PopperProps={{
                                             disablePortal: true,
                                          }}
                                          placement='top'
                                          onClose={() => setOpen(false)}
                                          open={open}
                                          disableFocusL
                                          classes={{ popper: classes.tooltipContainer }}
                                          disableHoverListener
                                          disableTouchListener
                                          title={
                                             !translatedTextLoading ? (
                                                translatedTextData?.translateMessageText.data
                                                   .translation
                                             ) : (
                                                <>
                                                   <CircularProgress size={16} />
                                                </>
                                             )
                                          }
                                          arrow
                                       >
                                          <Button
                                             className={classes.translateBtn}
                                             onClick={handleTooltipOpen}
                                          >
                                             <TranslateIcon
                                                fontSize='small'
                                                className={classes.translateIcon}
                                             />
                                          </Button>
                                       </Tooltip>
                                    </div>
                                 </ClickAwayListener>
                              )}
                           </div>
                           <LinkPreview message={text} />
                        </Grid>
                     </Grid>

                     {isMine && (
                        <Grid item className={classes.messageAck}>
                           {(ack == 0 || isResending || (isPrivate && isSending)) && (
                              <CircularProgress
                                 style={{ marginRight: 10, padding: 2 }}
                                 size={15}
                                 color={'#848484'}
                              />
                           )}
                           {ack == 1 && !isResending && (
                              <CheckIcon style={{ marginRight: 10, padding: 2 }} fontSize='small' />
                           )}
                           {ack == 2 && !isResending && !showDoubleThick && (
                              <CheckIcon style={{ marginRight: 10, padding: 2 }} fontSize='small' />
                           )}
                           {((ack == 2 && !isResending && showDoubleThick) ||
                              (isPrivate && !isSending)) && (
                              <Grid item>
                                 <CheckIcon style={{ padding: 2 }} fontSize='small' />
                                 <CheckIcon
                                    style={{
                                       padding: 2,
                                       marginRight: '-6px',
                                       position: 'absolute',
                                       right: '2px',
                                    }}
                                    fontSize='small'
                                 />
                              </Grid>
                           )}
                           {ack == 3 && !isResending && (
                              <ErrorIcon
                                 style={{
                                    padding: 0,
                                    color: 'red',
                                    marginBottom: 2,
                                    cursor: 'pointer',
                                 }}
                                 fontSize='small'
                                 onClick={() => setShowFailedRepliedModal(true)}
                              />
                           )}
                        </Grid>
                     )}
                  </div>
                  <div className={classes.avatarContainer}>
                     {showAvatarOnMessage && (
                        <Avatar src={senderPhoto} className={classes.avatar} />
                     )}
                  </div>
               </Grid>
               {timeVisible && (
                  <Grid container justifyContent={isMine ? 'flex-end' : 'flex-start'}>
                     <Typography className={classes.senderNameAndTime}>
                        {!isMine
                           ? `${time}`
                           : `${isAutoCreate ? t?.from_phone : senderTitle} | ${time}`}
                     </Typography>
                  </Grid>
               )}
            </Grid>
         )}

         <Modal
            open={showFailedRepliedModal}
            onClose={() => setShowFailedRepliedModal(false)}
            paperStyle={{ width: 450 }}
            modalHeader={t?.resend_message_title}
         >
            <ModalLargeText style={{ textAlign: 'center', marginBlock: 40 }}>
               {t?.resend_message_text}
            </ModalLargeText>
            <ModalButtonField>
               <ButtonCancel
                  label={t?.page_chat_btn_filter_cancel}
                  onClick={() => setShowFailedRepliedModal(false)}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
               <ButtonOK
                  label={t?.pre_load_forget_pass_sent_OK}
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     '&:hover': {
                        backgroundColor: '#105CBB',
                     },
                     float: 'right',
                  }}
                  startIcon={
                     reSendMessageLoading ? <CircularProgress color='inherit' size={20} /> : null
                  }
                  onClick={() => reSendMessage()}
               />
            </ModalButtonField>
         </Modal>
      </div>
   );
};

export default Messages;

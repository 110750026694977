import React, { useState, useContext, useEffect, memo } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import { TriggerIcon } from '@modules/Automation/FlowBots/FlowBuilder/icons/icons';
import { IFlowBlockFieldRules, IFlowBlockFieldTypes, IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SET_CLICKED_NODE_ID } from '@store/actions/automation';

const useStyles = makeStyles(() => ({
   container: {
      position: 'relative',
      display: 'inline-block',
      background: 'rgba(255, 255, 255, 0.6)',
   },
   triggerIcon: {
      width: 20,
      height: 20,
      margin: '0 5px 5px 5px',
   },
   customHeader: {
      display: 'flex',
      borderBottom: '0.1px solid #f2f2f2',
   },
   addText: {
      padding: '10px',
      margin: '5px',
      textAlign: 'center',
      borderRadius: 2,
      fontSize: 7,
      color: '#AFAFAF',
      backgroundColor: '#F7F7FA',
   },
   deleteIcon: {
      position: 'absolute',
      top: '-15px',
      right: '46%',
      cursor: 'pointer',
   },
   nodeContainer: {
      border: '1px solid #ffffff',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   nodeContainerHovered: {
      border: '1px solid #157cfc',
      background: 'rgba(255, 255, 255, 0.6)',
   },
   footer: {
      borderTop: ' 0.1px solid #f2f2f2',
      height: '12px',
      position: 'relative',
   },
   handle: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '-1px',
      marginTop: '3px',
      backgroundColor: '#fff',
      width: '9px',
      height: '9px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      cursor: 'default',
      '&:hover': {
         cursor: 'default',
         marginRight: '-2px',
         width: '11px',
         height: '11px',
      },
   },
   handleTarget: {
      backgroundColor: '#fff',
      width: '5px',
      height: '5px',
      top: 14,
      marginLeft: '5px',
   },
   handleBlock: {
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#fff',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleEdgeConnected: {
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#ADADAD',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },

   subBlocksTags: {
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      maxWidth: '100%',
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: '10fr 1fr',
      padding: '4px',
      border: '1px solid #F2F2F2',
      borderRadius: '5px',
   },
   blocks: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      margin: '4px',
      fontSize: '7px',
   },
   blockItem: {
      display: 'flex',
      gap: '2px',
      flexWrap: 'wrap',
      paddingBottom: '2.5px',
      borderBottom: '0.1px solid #f2f2f2',
      '&:last-child': {
         borderBottom: 'none',
      },
   },
   InvalidNodeContainer: {
      border: '1px solid #FF7474',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   line: {
      width: '40%',
   },
}));

interface UserVisitsPageProps {
   id: string;
   isConnectable: boolean;
   data: {
      clickedNodeId: string;
      title: string;
      isValidNode: Boolean;
      flowBlocks: IFlowBlockTemplate[];
      setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
      setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   };
}

const UserVisitsPageNode = ({ id, isConnectable, data }: UserVisitsPageProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { title, clickedNodeId, setClickedHandleId, isValidNode, flowBlocks, setClickedNodeId } = data;
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);
   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);
   const reactFlow = useReactFlow();
   const [isHover, setIsHover] = useState(false);

   const LOCATIONS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.locations);
   const LANGUAGES = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.languages);

   const getLabelFromLocation = (value: string) => {
      const locationItem =
         LOCATIONS && LOCATIONS.find((item: { value: string; label: string }) => item.value === value);
      return locationItem ? locationItem.label : '';
   };

   const getLabelFromLanguages = (value: string) => {
      const Item = LANGUAGES && LANGUAGES.find((item: { value: string }) => item.value === value);
      return Item ? Item.label : '';
   };

   useEffect(() => {
      setIsHover(id === clickedNodeId);
   }, [data, id, clickedNodeId]);

   const dispatch = useDispatch();
   const handleClicked = () => {
      if (botStatus === 'draft') {
         setClickedNodeId(id);
         dispatch({ type: SET_CLICKED_NODE_ID, payload: id });
      }
   };

   const currentNode = reactFlow.getNode(id);
   useEffect(() => {
      if (currentNode?.selected) {
         setIsHover(true);
      }
   }, [currentNode]);
   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (id === clickedId || currentNode?.selected ? setIsHover(true) : setIsHover(false))}
         className={classes.container}
         onClick={handleClicked}
      >
         <div
            className={`${classes.nodeContainer} ${isHover && isValidNode ? classes.nodeContainerHovered : ''} ${
               !isValidNode ? classes.InvalidNodeContainer : ''
            }`}
         >
            <div className={classes.customHeader}>
               <TriggerIcon className={classes.triggerIcon} />
               <div style={{ marginTop: '3px', fontSize: 9 }}>{title}</div>
            </div>
            {flowBlocks[0]?.fields.length > 0 ? (
               <div className={classes.blocks}>
                  {flowBlocks.map((item, index) => (
                     <div>
                        <div key={index} className={classes.subBlocksTags}>
                           <div style={{ display: 'flex', flexDirection: 'column', gap: '2.5px' }}>
                              {item.fields?.map((field, fieldIndex) => (
                                 <div key={fieldIndex} className={classes.blockItem}>
                                    <span style={{ color: '#000000' }}>
                                       {field.type === IFlowBlockFieldTypes.BrowserLanguageCheck
                                          ? t.atomation_flow_condition_browser_language
                                          : field.type === IFlowBlockFieldTypes.BrowserCountryCheck
                                          ? t.atomation_flow_condition_browser_country
                                          : field.type === IFlowBlockFieldTypes.PageUrlCheck
                                          ? t.atomation_flow_condition__page_url
                                          : ''}
                                    </span>
                                    <span style={{ color: '#818181' }}>
                                       {field.rule === IFlowBlockFieldRules.Contains
                                          ? t.atomation_flow_trigger_block_contains
                                          : field.rule === IFlowBlockFieldRules.Equal
                                          ? t.atomation_flow_trigger_block_equal
                                          : field.rule === IFlowBlockFieldRules.NotContains
                                          ? t.atomation_flow_trigger_block_not_contains
                                          : t.atomation_flow_trigger_block_not_equal}
                                    </span>
                                    <span style={{ color: '#000000' }}>
                                       {field.value && field.type === IFlowBlockFieldTypes.BrowserCountryCheck
                                          ? getLabelFromLocation(field.value)
                                          : field.value && field.type === IFlowBlockFieldTypes.BrowserLanguageCheck
                                          ? getLabelFromLanguages(field.value)
                                          : field.value}
                                    </span>
                                 </div>
                              ))}
                           </div>

                           <Handle
                              type='source'
                              position={Position.Right}
                              id={item._id}
                              className={item.target ? classes.handleEdgeConnected : classes.handleBlock}
                              isConnectable={false}
                              onMouseEnter={() => setClickedHandleId(item._id)}
                           />
                        </div>

                        {index < flowBlocks.length - 1 && (
                           <div style={{ display: 'flex', margin: '5px 5px 0 5px' }}>
                              <div className={classes.line}>
                                 <hr style={{ borderBottom: '1px solid #EDEDED' }} color='#FFFFFF' />
                              </div>
                              <div style={{ width: '20%', textAlign: 'center' }}>{t.atomation_flow_Or}</div>
                              <div className={classes.line}>
                                 <hr style={{ borderBottom: '1px solid #EDEDED' }} color='#FFFFFF' />
                              </div>
                           </div>
                        )}
                     </div>
                  ))}
               </div>
            ) : (
               <div className={classes.addText}>{t.atomation_flow_trigger_block_add_start_trigger}</div>
            )}
         </div>
      </div>
   );
};

export default memo(UserVisitsPageNode);

import { gql } from '@apollo/client';

// subscriptions
export const CREATED_CHAT_TAG_SUBSCRIPTION = gql`
   subscription createdChatTagSubscription($token: String!, $customer: ID!) {
      createdChatTag(data: { token: $token, customer: $customer }) {
         data {
            _id
            name
            color
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            chatCount
            createdAt
         }
         success
         code
         message
      }
   }
`;

export const UPDATED_CHAT_TAG_SUBSCRIPTION = gql`
   subscription updatedChatTagSubscription($token: String!, $customer: ID!) {
      updatedChatTag(data: { token: $token, customer: $customer }) {
         data {
            _id
            name
            color
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            chatCount
            createdAt
         }
         success
         code
         message
      }
   }
`;

export const DELETED_CHAT_TAG_SUBSCRIPTION = gql`
   subscription deletedChatTagSubscription($token: String!, $customer: ID!) {
      deletedChatTag(data: { token: $token, customer: $customer }) {
         data {
            _id
            name
            color
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            chatCount
            createdAt
         }
         success
         code
         message
      }
   }
`;

import React, { FC, useContext, useEffect } from 'react';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Typography, CircularProgress, Button } from '@material-ui/core';
import { TabProps } from '@material-ui/core/Tab/';
import Customer from '../Tabs/Customer';
import Store from '../Tabs/Store';
import Products from '../Tabs/Products';
import ECommerceTabHeaderContext from '../../../../../../contexts/ECommerceTabHeaderContext';
import ChatRightPanelTabHeader from '../../../ChatItem/ChatRightPanelTabHeader';
import { GET_E_COMMERCE_LINKED_CUSTOMER_QUERY } from '@queries/Settings/Service/GraphQL/ECommercePlatform/query';
import { getPerson } from '@store/reducers/person';
import { useSelector } from 'react-redux';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import CustomerInfo from '../Tabs/Customers/CustomerInfo';
import { getActiveChat } from '@store/reducers/chat';
import AllCustomers from '../Tabs/Store/AllCustomers';
import { LINK_E_COMMERCE_CUSTOMER_MUTATION } from '@queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import { useSnackbar } from 'notistack';
import useWindowResize from '@hooks/useWindowResize';
import { CustomerProps } from '@modules/Chat/RightPanel/Tabs/E-Commerce/Tabs/Store/types';
import MergeSplitModal from '@modules/Chat/RightPanel/Tabs/E-Commerce/Tabs/Customer/CustomerInfo/MergeSplitModal';
import CannotAccessModal from '@modules/Chat/RightPanel/ChatItem/CannotAccessModal';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';

const StyledTabs = withStyles({
   root: {
      border: 'none',
      height: 40,
      minHeight: 40,
      flexShrink: 0,
      borderRadius: 8,
      backgroundColor: '#F4F4F4',
      marginBottom: 15,
   },
   indicator: {
      position: 'absolute',
      width: 'calc(33.3% - 8px)!important', // (100/tab count)% - (margin * 2)px
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: 32,
      margin: 4,
      zIndex: 1,
      borderRadius: 6,
      backgroundColor: '#FFF',
      boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.1)',
   },
})(Tabs);

const StyledTab = withStyles((theme: Theme) =>
   createStyles({
      root: {
         textTransform: 'none',
         minWidth: 72,
         height: 40,
         minHeight: 40,
         flex: 1,
         padding: 5,
         borderRadius: 6,
         '&:hover': {
            color: '#000',
         },
         '&$selected': {
            color: '#000',
            fontWeight: theme.typography.fontWeightMedium,
         },
         '&:focus': {
            color: '#333',
         },
      },
      wrapper: {
         zIndex: 2,
      },
   }),
)((props: TabProps) => <Tab {...props} />);

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      padding: 15,
      height: '100%',
   },
   tabPanelContainer: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 4,
      border: '1px solid #D9E0E6',
   },
   title: {
      marginBottom: 8,
   },
   description: {
      marginBottom: 12,
   },
   container2: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   container3: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: 15,
   },
   cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 20,
      borderRadius: 15,
      textAlign: 'center',
   },
}));

interface TabPanelProps {
   children?: React.ReactNode;
   index: any;
   value: any;
}

function TabPanel(props: TabPanelProps) {
   const classes = useStyles();
   const { children, value, index, ...rest } = props;
   const headerContext = useContext(ECommerceTabHeaderContext);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         className={classes.tabPanelContainer}
         style={{ display: value === index ? 'flex' : 'none' }}
         {...rest}
      >
         {value === index && (
            <React.Fragment>
               {headerContext && headerContext.title && headerContext.title !== '' && headerContext.onGoBackClick && (
                  <ChatRightPanelTabHeader onClose={headerContext.onGoBackClick} title={headerContext.title} />
               )}
               {children}
            </React.Fragment>
         )}
      </div>
   );
}

interface SubTabViewProps {}

type CustomerTabViewName = 'ROOT' | 'CREATE_CUSTOMER' | 'LINK_CUSTOMER' | 'LINKED_CUSTOMER';

const SubTabView: FC<SubTabViewProps> = () => {
   const classes = useStyles();
   const [value, setValue] = React.useState<number>(2);
   const [title, setTitle] = React.useState<string>('');
   const [onGoBackClick, setOnGoBackClick] = React.useState<() => void>(() => () => {});
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const activeChatPerson = useSelector(getActiveChat).participants[0]._id;
   const me = useSelector(getPerson);
   const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      if (value !== newValue) {
         setTitle('');
         setOnGoBackClick(() => () => {});
      }
      setValue(newValue);
   };
   const [isCustomerLinked, setCustomerLinked] = React.useState<boolean>(false);
   const [linking, setLinking] = React.useState<boolean>(false);
   const [mergeModal, setMergeModal] = React.useState<boolean>(false);
   const eCommerceType = useSelector((rootReducer: any) => rootReducer?.eCommerceReducer?.eCommerceType);
   const { height } = useWindowResize();

   const [customerView, setCustomerView] = React.useState<CustomerTabViewName>('ROOT');

   const [fetchECommerceCustomer, { data: eCommerceCustomer, loading: eCommerceCustomerLoading }] = useLazyQuery(
      GET_E_COMMERCE_LINKED_CUSTOMER_QUERY,
      {
         fetchPolicy: 'no-cache',
      },
   );

   useEffect(() => {
      setCustomerLinked(!!eCommerceCustomer?.getECommerceLinkedCustomer?.data);
   }, [eCommerceCustomer]);

   const fetchCustomerECommerce = async () => {
      setCustomerView('ROOT');
      const customer = await fetchECommerceCustomer({
         variables: {
            person: activeChatPerson,
            customer: me.customer._id,
         },
      });
   };

   useEffect(() => {
      setCustomerView('ROOT');
      fetchCustomerECommerce();
   }, [activeChatPerson]);

   useEffect(() => {
      if (value === 0) {
         fetchCustomerECommerce();
      }
   }, [value]);

   const [linkECommerceCustomer] = useMutation(LINK_E_COMMERCE_CUSTOMER_MUTATION);

   const linkCustomer = async (e: CustomerProps) => {
      setLinking(true);
      try {
         await linkECommerceCustomer({
            variables: {
               metaData: {
                  customerId: me.customer._id,
                  person: activeChatPerson,
                  email: e.email,
                  phone: e.phone,
                  eCommerceCustomer: e._id || e.platformCustomerId,
               },
            },
         });
         await fetchCustomerECommerce();
      } catch (e) {
         const error = e as Error;
         if (error.message == 'page_e_commerce_customer_link_email_or_phone_required') {
            //This is a dumb code but ts doesn't allow me to use the translation key as a variable
            enqueueSnackbar(t['page_e_commerce_customer_link_email_or_phone_required'], {
               variant: 'error',
               persist: false,
               autoHideDuration: 3000,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {t['general_message_button_close']}
                  </div>
               ),
            });
         } else {
            enqueueSnackbar(t['page_e_commerce_customer_failed_to_link'], {
               variant: 'error',
               persist: false,
               autoHideDuration: 3000,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {t['general_message_button_close']}
                  </div>
               ),
            });
         }
      } finally {
         setLinking(false);
         setMergeModal(false);
      }
   };

   const goToRoot = () => setCustomerView('ROOT');
   const [customerToMerge, setCustomerToMerge] = React.useState<any>(null);

   return (
      <ECommerceTabHeaderContext.Provider
         value={{
            onGoBackClick,
            setOnGoBackClick,
            title,
            setTitle,
         }}
      >
         <div className={classes.container}>
            <StyledTabs value={value} onChange={handleChange} aria-label='ant example'>
               <StyledTab label={t['customer']} />
               <StyledTab label={t['store']} />
               <StyledTab label={t['products']} />
            </StyledTabs>
            <TabPanel value={value} index={0}>
               {eCommerceCustomerLoading ? (
                  <div className={classes.container2} style={{ height: height - 260 }}>
                     <CircularProgress />
                  </div>
               ) : isCustomerLinked && eCommerceCustomer?.getECommerceLinkedCustomer?.data ? (
                  <Customer
                     customer={eCommerceCustomer?.getECommerceLinkedCustomer?.data}
                     onCustomerSplit={fetchCustomerECommerce}
                  />
               ) : (
                  (customerView === 'CREATE_CUSTOMER' && (
                     <CustomerInfo
                        createdFor={activeChatPerson}
                        onCancel={goToRoot}
                        onCreated={fetchCustomerECommerce}
                     />
                  )) ||
                  (customerView === 'LINK_CUSTOMER' && (
                     <>
                        <MergeSplitModal
                           isVisible={mergeModal}
                           actionType={'merge'}
                           loading={linking}
                           onCancel={() => {
                              setMergeModal(false);
                              setCustomerToMerge(null);
                           }}
                           onSubmit={() => linkCustomer(customerToMerge)}
                        />
                        <AllCustomers
                           openCustomerDetail={(e) => {
                              setCustomerToMerge(e);
                              setMergeModal(true);
                           }}
                           header={
                              <ChatRightPanelTabHeader
                                 onClose={goToRoot}
                                 title={t?.page_e_commerce_customer_link_customer}
                              />
                           }
                        />
                     </>
                  )) ||
                  (customerView === 'ROOT' && (
                     <div className={classes.container3} style={{ height: height - 260 }}>
                        <Typography variant='h6' style={{ margin: '5px 0', fontWeight: 'bold' }}>
                           {t.page_e_commerce_products_search_customer_not_found}
                        </Typography>
                        <Typography style={{ margin: '5px 0' }}>
                           {t.page_e_commerce_products_customer_not_found_description}
                        </Typography>
                        <Button
                           variant='contained'
                           color='primary'
                           onClick={() => {
                              if (
                                 eCommerceType === E_COMMERCE_PLATFORM_TYPE.IKAS ||
                                 eCommerceType === E_COMMERCE_PLATFORM_TYPE.FAPRIKA
                              ) {
                                 enqueueSnackbar(t['does_not_support_create_customer_feautre'], {
                                    // send error message
                                    variant: 'error',
                                    persist: true,
                                    action: (key) => (
                                       <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                                          Kapat
                                       </div>
                                    ),
                                 });
                                 return;
                              }
                              setCustomerView('CREATE_CUSTOMER');
                           }}
                           style={{ margin: '5px 0' }}
                        >
                           {t.page_e_commerce_customer_create_customer}
                        </Button>
                        <Typography variant='body2' style={{ margin: '5px 0' }}>
                           {t.or}
                        </Typography>
                        <Button
                           variant='contained'
                           onClick={() => {
                              setCustomerView('LINK_CUSTOMER');
                           }}
                           style={{ margin: '5px 0', backgroundColor: '#10223a', color: '#fff' }}
                        >
                           {t.page_e_commerce_customer_link_customer}
                        </Button>
                     </div>
                  ))
               )}
            </TabPanel>
            <TabPanel value={value} index={1}>
               <Store />
            </TabPanel>
            <TabPanel value={value} index={2}>
               <Products />
            </TabPanel>
         </div>
      </ECommerceTabHeaderContext.Provider>
   );
};

export default SubTabView;

import React, { Fragment, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Grid, Stepper, StepLabel, Button } from '@material-ui/core';
import ButtonOK from '@components/ButtonOK/buttonOk';
import 'react-phone-input-2/lib/style.css';
import { LanguageContext } from '@helper/locale/langContext';
import { Step } from '@material-ui/core';
import { WhatsappIcon } from '@assets/icons/SettingsIcons/settingsIcons';
import Input from '@components/InputField';
import { useForm, Controller } from 'react-hook-form';
import { ModalLargeText, ModalTitle } from '@components/_Modal/ModalTexts';
import Checkbox from '@material-ui/core/Checkbox';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';
import { useSelector } from 'react-redux';
import { getCustomer } from '@store/reducers/person';

const useStyles = makeStyles((theme) => ({
   checkBoxRoot: {
      '&$checked': {
         color: theme.palette.primary.main,
      },
      padding: '5px',
   },
   checked: {},
   root: {
      width: 700,
      padding: 20,
      borderRadius: 15,
      maxHeight: 700,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   iconSize: {
      fontSize: '5rem',
   },
   backButton: {
      color: '#748FA7',
      backgroundColor: '#fff',
      textTransform: 'none',
      width: 128,
      height: 40,
      fontSize: '1.1rem',
      border: '1px solid #748FA7',
   },
   stepLabel: {
      fontSize: '1.2rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: null,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));
const getInformationList = (t) => {
   return [
      t?.page_settings_modal_whatsapp_modal_step_information_list_item_name,
      t?.page_settings_modal_whatsapp_modal_step_information_list_add_want_to_phone,
      t?.page_settings_modal_whatsapp_modal_step_information_list_item_consumer,
   ];
};
const getSteps = (t) => {
   return [
      t?.page_settings_modal_whatsapp_modal_step_information,
      t?.page_settings_modal_whatsapp_modal_step_account_information,
   ];
};

const ModalContent = (props) => {
   const { onAccept, mode } = props;
   const customer = useSelector(getCustomer);
   const classes = useStyles(props);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const steps = getSteps(t);
   const informationList = getInformationList(t);
   const [activeStep, setActiveStep] = useState(0);
   const {
      handleSubmit,
      control,
      setValue,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         name: '',
         teams: [],
         number: '',
         groupChats: false,
         receivePhoneMsg: false,
      },
   });
   // only for test
   const onSubmit = async (data) => {
      const newData = {
         name: data.name,
         number: data.number, // get phone code
         teams: data.teams.map((team) => team.value),
         ...(customer.isWholeSaler && {
            groupChats: data.groupChats,
            receivePhoneMsg: data.receivePhoneMsg,
         }),
      };
      onAccept(newData);
   };
   return (
      <Grid>
         {mode === 'NEW' && (
            <Fragment>
               <Stepper alternativeLabel activeStep={activeStep}>
                  {steps.map((title) => {
                     return (
                        <Step key={title}>
                           <StepLabel>
                              <Typography className={classes.stepLabel}>{title}</Typography>
                           </StepLabel>
                        </Step>
                     );
                  })}
               </Stepper>
               {activeStep !== steps.length && (
                  <Grid container style={{ marginTop: 20 }}>
                     {/* Step One Information */}
                     {activeStep === 0 && (
                        <Grid>
                           <ModalTitle>WhatApp Web</ModalTitle>
                           <ModalLargeText>{t?.page_settings_modal_whatsapp_modal_redirect_to_qr}</ModalLargeText>
                           <Grid
                              container
                              // justifyContent='space-between'
                              xs={12}
                              style={{ marginTop: 10 }}
                           >
                              <Grid xs={9} item alignItems='flex-start'>
                                 {informationList.map((item, index) => (
                                    <ModalLargeText key={index}>
                                       {index + 1}-{item}
                                    </ModalLargeText>
                                 ))}
                              </Grid>
                              <Grid item xs={2}>
                                 <WhatsappIcon />
                              </Grid>
                           </Grid>
                           <ModalButtonField>
                              <ButtonOK
                                 label={t?.next}
                                 onClick={() => setActiveStep((prevStep) => prevStep + 1)}
                                 className={classes.modalButtons}
                                 style={{
                                    marginLeft: 'auto',
                                    color: 'white',
                                    backgroundColor: '#157CFC',
                                    '&:hover': {
                                       backgroundColor: '#105CBB',
                                    },
                                 }}
                              />
                           </ModalButtonField>
                        </Grid>
                     )}
                     {/* Step Two Form */}
                     {activeStep === 1 && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                           <Grid container xs={12}>
                              <SingleInput
                                 label={t?.page_settings_typo_platforms_add_whats_app_web_label}
                                 placeholder={t?.page_settings_typo_platforms_add_whats_app_web_placeholder}
                                 control={control}
                                 name='name'
                                 required={false}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                 }}
                                 helperText={t?.page_settings_typo_platforms_add_whats_app_web_description}
                              />
                              <SingleInput
                                 label={t?.teams}
                                 isSelect
                                 isMulti
                                 autoHeight
                                 selectOptions={props.teamsData.map((team) => ({
                                    label: team.teamName,
                                    value: team._id,
                                 }))}
                                 name='teams'
                                 control={control}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                 }}
                                 editable
                                 height={40}
                                 placeholder={t?.teams}
                              />
                              <SingleInput
                                 label={t?.page_account_input_profile_phone}
                                 isPhone={true}
                                 splitCountryCode={false}
                                 name='number'
                                 control={control}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                 }}
                              />
                              {customer.isWholeSaler && (
                                 <div>
                                    <Controller
                                       name='groupChats'
                                       control={control}
                                       render={({ field: { onChange, value } }) => (
                                          <Grid
                                             container
                                             alignItems='flex-start'
                                             style={{ flexWrap: 'inherit', marginBottom: 16 }}
                                          >
                                             <Checkbox
                                                onChange={onChange}
                                                value={value}
                                                classes={{ root: classes.checkBoxRoot, checked: classes.checked }}
                                             />
                                             <Typography style={{ marginTop: 6 }}>
                                                {t?.whatsapp_web_telephone_group_allow}
                                             </Typography>
                                          </Grid>
                                       )}
                                    />
                                    <Controller
                                       name='receivePhoneMsg'
                                       control={control}
                                       render={({ field: { onChange, value } }) => (
                                          <Grid
                                             container
                                             alignItems='flex-start'
                                             style={{ flexWrap: 'inherit', marginBottom: 16 }}
                                          >
                                             <Checkbox
                                                onChange={onChange}
                                                value={value}
                                                classes={{ root: classes.checkBoxRoot, checked: classes.checked }}
                                             />
                                             <Typography style={{ marginTop: 6 }}>
                                                {t?.whatsapp_web_telephone_group_receive_msgs}
                                             </Typography>
                                          </Grid>
                                       )}
                                    />
                                 </div>
                              )}
                              <ModalButtonField>
                                 <Button
                                    className={classes.modalButtons}
                                    onClick={() => setActiveStep((prevStep) => prevStep - 1)}
                                    style={{
                                       color: '#212121',
                                       backgroundColor: '#eeee',
                                       '&:hover': {
                                          backgroundColor: '#e0e0e0',
                                       },
                                    }}
                                 >
                                    {t?.back}
                                 </Button>
                                 <ButtonOK
                                    className={classes.modalButtons}
                                    label={t?.comp_button_save}
                                    type='submit'
                                    disabled={!isValid}
                                    style={{
                                       color: 'white',
                                       backgroundColor: '#157CFC',
                                       '&:hover': {
                                          backgroundColor: '#105CBB',
                                       },
                                       float: 'right',
                                    }}
                                 />
                              </ModalButtonField>
                           </Grid>
                        </form>
                     )}
                  </Grid>
               )}
            </Fragment>
         )}
      </Grid>
   );
};

export default ModalContent;

import React, { FC, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MyTheme } from '../../../../../../../../../../styles/config';
import { LanguageContext } from '../../../../../../../../../../helper/locale/langContext';
import { CircularProgress, Typography } from '@material-ui/core';
import { CollectPaymentModalType } from '../../../types';
import Modal from '../../../../../../../../../../components/Modal/modal';
import ButtonCancel from '../../../../../../../../../../components/ButtonCancel/buttonCancel';
import ButtonOK from '../../../../../../../../../../components/ButtonOK/buttonOk';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';

const useStyles = makeStyles((theme: MyTheme) => ({
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 436,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   childrenContainer: {
      padding: 15,
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      borderTop: '1px solid #F1F3F7',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

interface CollectPaymentModalProps {
   isVisible: boolean;
   actionType: CollectPaymentModalType;
   submitButtonDisabled?: boolean | undefined;
   onCancel: () => void;
   onSubmit: () => void;
}

const CollectPaymentModal: FC<CollectPaymentModalProps> = ({
   isVisible,
   actionType,
   submitButtonDisabled = false,
   onCancel,
   onSubmit,
   children,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const headerTitle = t[`page_e_commerce_customer_customer_cart_collect_payment_${actionType}`];
   const buttonLabel =
      actionType === 'pay_by_credit_card'
         ? t['page_e_commerce_customer_customer_cart_collect_payment']
         : t['general_message_yes'];

   return (
      <Modal paperStyle={{ width: 450 }} open={isVisible} onClose={onCancel} modalHeader={headerTitle}>
         <ModalLargeText>{children}</ModalLargeText>
         <ModalButtonField>
            <ButtonCancel
               className={classes.modalButtons}
               label={t['comp_button_cancel']}
               onClick={onCancel}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
               }}
            />
            <ButtonOK
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  float: 'right',
               }}
               disabled={submitButtonDisabled}
               label={buttonLabel}
               onClick={onSubmit}
               endIcon={submitButtonDisabled ? <CircularProgress size={20} style={{ color: 'white' }} /> : null}
            />
         </ModalButtonField>
      </Modal>
   );
};

export default CollectPaymentModal;

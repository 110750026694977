import { gql } from '@apollo/client';

// mutations
export const CREATE_CHAT_TAG_MUTATION = gql`
   mutation createChatTagMutation($name: String!, $color: String!, $customer: ID!) {
      createChatTag(data: { name: $name, color: $color, customer: $customer }) {
         data {
            _id
            name
            color
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const UPDATE_CHAT_TAG_MUTATION = gql`
   mutation updateChatTagMutation($_id: ID!, $name: String!, $color: String!) {
      updateChatTag(data: { _id: $_id, name: $name, color: $color }) {
         data {
            _id
            name
            color
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const DELETE_CHAT_TAG_MUTATION = gql`
   mutation deleteChatTagMutation($_id: ID!) {
      deleteChatTag(data: { _id: $_id }) {
         data {
            _id
            name
            color
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            createdAt
         }
         success
         code
         message
      }
   }
`;

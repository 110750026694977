export const SET_CHANNEL = 'SET_CHANNEL';
export const SET_BOT_ID = 'SET_BOT_ID';
export const SET_RULE_ID = 'SET_RULE_ID';
export const SET_HAS_OPERATIONS = 'SET_HAS_OPERATIONS';
export const SET_BOT = 'SET_BOT';
export const SET_BOT_STATUS = 'SET_BOT_STATUS';
export const SET_RULE_STATUS = 'SET_RULE_STATUS';
export const SET_LOCATION = 'SET_LOCATION';
export const SET_LANGUAGES = 'SET_LANGUAGES';
export const SET_AGENTS = 'SET_AGENTS';
export const SET_TAGS = 'SET_TAGS';
export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_BLOCKTEMPLATES = 'SET_BLOCKTEMPLATES';
export const SET_NODETEMPLATES = 'SET_NODETEMPLATES';
export const SET_SAVE_RULE_STATE = 'SET_SAVE_RULE_STATE';
export const SET_IS_SECOND_MESSAGE = 'SET_IS_SECOND_MESSAGE';
export const SET_PLATFORM = 'SET_PLATFORM';
export const SET_START_NODE = 'SET_START_NODE';
export const SET_START_BOT = 'SET_START_BOT';
export const SET_CLICKED_NODE_ID = 'SET_CLICKED_NODE_ID';

import { applyMiddleware, createStore } from 'redux';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; //
import rootReducer from './reducers/rootReducer';

import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { composeWithDevTools } from 'redux-devtools-extension';

const persistConfig = {
   key: 'redux',
   storage,
   blacklist: ['filterReducer', 'questionsState'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Extension for Redux
const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(promise, thunk)));
let persistor = persistStore(store);

export { store, persistor };

type RootState = ReturnType<typeof store.getState>;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export enum QnAPlatformType {
   HEPSIBURADA = 'HEPSIBURADA',
   TRENDYOL = 'TRENDYOL',
   INSTAGRAM = 'INSTAGRAM',
   FACEBOOK = 'FACEBOOK',
   N11 = 'N11',
}

export enum QnAPersonStatusType {
   WAITING,
   SOLVED,
   SPAM,
   ARCHIVE,
   DELETED = 5,
}

export enum QnAStatus {
   REJECTED = 'REJECTED',
   ANSWERED = 'ANSWERED',
   TIMED_OUT = 'TIMED_OUT',
   WAITING = 'WAITING',
   DELETED = 'DELETED',
   NOT_ANSWERED = 'NOT_ANSWERED',
   FAILED = 'FAILED',
}

export enum QnAReplyType {
   PUBLIC = 'PUBLIC',
   PRIVATE = 'PRIVATE',
}

export enum QnAType {
   QNA,
   NOTE,
   LOG,
}

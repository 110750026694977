import React, { FC, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, ButtonBase } from '@material-ui/core';
import classNames from 'classnames';
import { LanguageContext } from '../../../../../../../../helper/locale/langContext';
import { CustomerProps } from '../types';

const useStyles = makeStyles(() => ({
   root: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #D9E0E6',
      borderRadius: 4,
      padding: 5,
      marginBottom: 8,
      marginRight: 8,
      marginLeft: 8,
      height: 60,
   },
   container: {
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      padding: 5,
   },
   nameAndStatusContainer: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   name: {
      color: '#385273',
      fontSize: '1.2em',
      lineHeight: 1.3,
   },
   linkStatus: {
      lineHeight: 1.3,
   },
   linked: {
      color: '#13ba45',
   },
   emailContainer: {
      marginTop: 5,
      display: 'flex',
   },
   email: {
      color: '#4a6992',
      lineHeight: 1.3,
   },
}));

interface CustomersListItemProps {
   customer: any;
   onClick: () => void;
}

const CustomersListItem: FC<CustomersListItemProps> = ({ customer, onClick }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const { firstName, lastName, email, linkedPerson } = customer;

   return (
      <ButtonBase onClick={onClick} disableRipple className={classes.root}>
         <div className={classes.container}>
            <div className={classes.nameAndStatusContainer}>
               <Typography variant='body1' noWrap className={classes.name}>
                  {firstName || '-'} {lastName || '-'}
               </Typography>
               {linkedPerson && (
                  <Typography variant='body1' noWrap className={classNames(classes.linkStatus, classes.linked)}>
                     {t['page_e_commerce_customer_linked']}
                  </Typography>
               )}

               {/* <Typography variant='body2' className={classNames(classes.status, classes[status])}>
                  {t[`page_e_commerce_store_status_option_${status}`]}
               </Typography> */}
            </div>
            <div className={classes.emailContainer}>
               <Typography variant='body2' className={classes.email}>
                  {email}
               </Typography>
            </div>
         </div>
      </ButtonBase>
   );
};

export default CustomersListItem;

import { ClickAwayListener, Grid, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { useContext, useState, useEffect, useRef } from 'react';
import { Node } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import SearchSelect from 'react-select';
import DeleteIcon from '@mui/icons-material/Delete';
import DataObjectIcon from '@mui/icons-material/DataObject';
import {
   FlowBlockType,
   IFlowBlockField,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { generateRandomId } from '@helper/functions';
import { useMutation } from '@apollo/client';
import { ADD_FLOW_BLOCK_MUTATION, UPDATE_BLOCK_MUTATION } from '@queries/Automation/mutation';
import { RootStateOrAny, useSelector } from 'react-redux';

interface ActionBlocksProps {
   leftNodes: Node[];
   contentNodes: Node[];
   setIsSaveAction: React.Dispatch<React.SetStateAction<boolean>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   isSaveAction: boolean;
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   clickedNodeId: string;
}
const ActionBlocks: React.FC<ActionBlocksProps> = ({
   leftNodes,
   setIsBtnDisable,
   isLoading,
   setIsLoading,
   setIsSaveAction,
   isSaveAction,
   clickedNodeId,
   setContentNodes,
   setLeftNodes,
   contentNodes,
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const useStyles = makeStyles((theme) => ({
      btnGroup: {
         height: 36,
         width: '100%',
         border: '1px dashed #B16AE7',
         borderRadius: '10px',
         background: 'transparent',
         cursor: 'pointer',
         color: '#B16AE7',
      },
      selectValue: {
         marginBottom: '10px',
         width: '100%',
      },
      selectValue50: {
         marginBottom: '10px',
         width: '50%',
      },
      deleteIcon: {
         color: 'gray',
         cursor: 'pointer',
      },
      dataObject: {
         position: 'absolute',
         right: 0,
         bottom: -5,
         fontSize: 16,
         backgroundColor: '#000',
         color: '#fff',
         borderRadius: 5,
         zIndex: 1,
      },
   }));
   const classes = useStyles();

   const OPTIONS_CONSENT = [
      {
         value: 'true',
         label: t.page_contacts_typo_subscribed,
      },
      {
         value: 'false',
         label: t.page_contacts_typo_unsubscribed,
      },
   ];
   const OPTIONS_STATUS = [
      {
         value: '0',
         label: t.page_settings_typo_chat_settings_automatic_actions_select_option_open,
      },
      {
         value: '2',
         label: t.page_settings_typo_chat_settings_automatic_actions_select_option_close,
      },
   ];

   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [showUD, setShowUD] = useState(false);
   const [hoveredBlockId, setHoveredBlockId] = useState<number | null>();
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);
   const [blocks, setBlocks] = useState<IFlowBlockTemplate[]>([]);
   const [searchSelectKey, setSearchSelectKey] = useState<number>(0);
   const [dataId, setDataId] = useState<{ prevData: string; newData: string }[]>([]);
   const [toUpdate, setToUpdate] = useState<{ _id: string }[]>([]);
   const [fieldCounts, setFieldCounts] = useState<number>(0);
   const AGENTS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.agents);
   const TEAMS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.teams);
   const TAGS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.tags);
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   useEffect(() => {
      const counts = blockState[0]?.fields.length;
      setFieldCounts(counts);
      setBlocks(blockState);
   }, [blockState]);

   useEffect(() => {
      setSearchSelectKey((prevKey) => prevKey + 1);
   }, [clickedNodeId, AGENTS, blockState]);

   const handleMenuOpenBlock = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setShowUD(!showUD);
   };

   const handleMenuClose = () => {
      setAnchorEl(null);
      setShowUD(false);
   };
   const handleSelectAction = (actionField: IFlowBlockFieldTypes) => {
      setIsBtnDisable(true);
      const updatedBlocks = blockState.map((block) => {
         const newField = {
            type: actionField,
            value: '',
         };

         return {
            ...block,
            fields: [...block.fields, newField],
         };
      });

      setBlockState(updatedBlocks);
      handleMenuClose();
   };

   const handleDeleteField = (blockId: string, indexToDelete: number) => {
      setIsBtnDisable(false);
      const updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            if (indexToDelete >= 0 && indexToDelete < block.fields.length) {
               const updatedFields = block.fields.filter((field, index) => index !== indexToDelete);

               return {
                  ...block,
                  fields: updatedFields,
               };
            }
         }

         return block;
      });

      setToUpdate((prev) => {
         // Check if the _id already exists in the array
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });
      setBlockState(updatedBlocks);
   };

   const handleSelectValueChange = (blockId: string, index: number, value: string | undefined) => {
      if (value) {
         setIsBtnDisable(false);
         const updatedBlocks = blockState.map((block) => {
            if (block._id === blockId) {
               if (index >= 0 && index < block.fields.length) {
                  const updatedFields = block.fields.map((field, i) => {
                     if (i === index) {
                        return {
                           ...field,
                           value: value,
                        };
                     }
                     return field;
                  });

                  return {
                     ...block,
                     fields: updatedFields,
                  };
               }
            }

            return block;
         });

         setToUpdate((prev) => {
            // Check if the _id already exists in the array
            const isIdUnique = !prev.some((item) => item._id === blockId);
            return isIdUnique ? [...prev, { _id: blockId }] : prev;
         });

         setBlockState(updatedBlocks);
      }
   };

   const handleCustomValueChange = (blockId: string, index: number, value: string) => {
      setIsBtnDisable(false);
      const updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            if (index >= 0 && index < block.fields.length) {
               const updatedFields = block.fields.map((field, i) => {
                  if (i === index) {
                     return {
                        ...field,
                        customValue: value,
                        value: field.name + '*_*_*' + value,
                     };
                  }
                  return field;
               });

               return {
                  ...block,
                  fields: updatedFields,
               };
            }
         }

         return block;
      });

      setToUpdate((prev) => {
         // Check if the _id already exists in the array
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });

      setBlockState(updatedBlocks);
   };

   const handleNameValueChange = (blockId: string, index: number, value: string | undefined) => {
      if (value) {
         setIsBtnDisable(false);
         const updatedBlocks = blockState.map((block) => {
            if (block._id === blockId) {
               if (index >= 0 && index < block.fields.length) {
                  const updatedFields = block.fields.map((field, i) => {
                     if (i === index) {
                        return {
                           ...field,
                           name: value,
                           value: value + '*_*_*' + field.customValue,
                        };
                     }
                     return field;
                  });

                  return {
                     ...block,
                     fields: updatedFields,
                  };
               }
            }

            return block;
         });

         setToUpdate((prev) => {
            // Check if the _id already exists in the array
            const isIdUnique = !prev.some((item) => item._id === blockId);
            return isIdUnique ? [...prev, { _id: blockId }] : prev;
         });

         setBlockState(updatedBlocks);
      }
   };
   // enable btn
   useEffect(() => {
      const isInvalid = blockState.some(
         (block) =>
            block.fields.some((field) => field.value === '') ||
            block.fields.some(
               (field) =>
                  field.type === 'set_custom_field' &&
                  (field.customValue === '' || field.customValue === undefined || field.customValue === 'undefined'),
            ),
      );
      if (isInvalid) {
         setIsBtnDisable(true);
      }
   }, [blockState]);

   const customStyles = {
      control: (base: any) => ({
         ...base,
         height: 35,
      }),
   };

   // Add block mutation
   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlock = async (
      flowBlockTemplateId: String,
      nodeId: string,
      field: IFlowBlockField[],
      prevBlockId: string,
   ) => {
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fields: field,
               },
            },
            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];

               setIsLoading(false);
               setIsBtnDisable(true);
               setDataId((prev) => [...prev, { prevData: prevBlockId, newData: response._id }]);

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block?.fields?.length > 0) ? true : false,
                              flowBlocks: [response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   // Update block mutation for a specific field
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);

   const handleUpdateFlowBlockFields = async (blockId: string, fields: IFlowBlockField[]) => {
      setIsLoading(true);
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;

               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block?.fields?.length > 0) ? true : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));

               setIsLoading(false);
               setIsBtnDisable(true);
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // update the local state with the Id coming from the response mutation
   useEffect(() => {
      if (dataId) {
         let updatedBlockState = [...blockState];
         dataId.forEach((element) => {
            updatedBlockState = updatedBlockState.map((block) => {
               if (element.prevData === block._id) {
                  return {
                     ...block,
                     _id: element.newData,
                  };
               } else {
                  return block;
               }
            });
         });

         setBlockState(updatedBlockState);
      }
   }, [contentNodes]);

   const backendBlocks = contentNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];
   const listBlocks = contentNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   // default
   useEffect(() => {
      if (listBlocks?.length > 0) {
         const data = listBlocks.map((item: IFlowBlockTemplate) => {
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });

         const updatedBlocks = data.map((block: IFlowBlockTemplate) => {
            const updatedFields = block.fields.map((field) => {
               if (field.type === 'set_custom_field') {
                  return {
                     ...field,
                     customValue: field.value?.split('*_*_*')[1],
                     name: field.value?.split('*_*_*')[0],
                  };
               } else {
                  return field;
               }
            });

            return {
               ...block,
               fields: updatedFields,
            };
         });

         setBlockState(updatedBlocks);
      } else {
         const block = {
            _id: generateRandomId(),
            title: '',
            fields: [],
            type: FlowBlockType.Action,
         };

         setBlockState([block]);
      }
   }, [clickedNodeId]);

   // Save
   useEffect(() => {
      if (isSaveAction && !isLoading) {
         setIsSaveAction(false);

         const newBlocks = blockState.filter(
            (block) => !backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );
         const existingBlocks = blockState.filter((block) =>
            backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         // Add all new blocks to the backend
         const save = async () => {
            // Update fields
            if (toUpdate.length > 0) {
               toUpdate.forEach((item) => {
                  const existingBlock = existingBlocks.find((block) => block._id === item._id);
                  if (existingBlock) {
                     setIsLoading(true);

                     const fields = existingBlock.fields.map((exfield) => ({
                        type: exfield.type,
                        value: exfield.value,
                     }));

                     handleUpdateFlowBlockFields(item._id, fields);
                  }
               });
               setToUpdate([]);
            }

            if (newBlocks.length > 0) {
               setIsLoading(true);

               (async () => {
                  if (LIST_BLOCK_TEMPLATE) {
                     const templateBlockId = LIST_BLOCK_TEMPLATE.find(
                        (item: { type: FlowBlockType }) => item.type === FlowBlockType.Action,
                     )?._id;
                     for await (let item of newBlocks) {
                        if (templateBlockId) {
                           const fields = item.fields.map((exfield) => ({
                              type: exfield.type,
                              value: exfield.value,
                           }));
                           await handleAddFlowBlock(templateBlockId, clickedNodeId, fields, item._id);
                        }
                     }
                  }
               })();
            }
         };
         save();
         setToUpdate([]);
      }
   }, [isSaveAction]);

   const getLabelFromAgents = (value: string) => {
      const Item = AGENTS && AGENTS.find((item: { value: string }) => item.value === value);
      return Item ? Item.label : '';
   };
   const getLabelFromTeams = (value: string) => {
      const Item = TEAMS && TEAMS.find((item: { value: string }) => item.value === value);
      return Item ? Item.label : '';
   };
   const getLabelFromTags = (value: string) => {
      const Item = TAGS && TAGS.find((item: { value: string }) => item.value === value);
      return Item ? Item.label : '';
   };

   const CUSTOM_FIELDS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.customFields);
   const [conditionMenu, setConditionMenu] = useState<{ label: string; value: string }[]>([]);
   useEffect(() => {
      setCustomFields(CUSTOM_FIELDS);
      CUSTOM_FIELDS.map((item: { name: string; label: string }) => {
         setConditionMenu((prev) => [...prev, { label: item.name, value: item.name }]);
      });
   }, [CUSTOM_FIELDS]);

   const height = 12;
   const [customFields, setCustomFields] = useState<{ name: string; _id: string }[]>([]);
   const [showFieldHeader, setShowFieldHeader] = useState<string | null>();
   const [showFieldIndexHeader, setShowFieldIndexHeader] = useState<number | null>();
   const [anchorElField, setAnchorElField] = useState<null | HTMLElement>(null);
   const [selectedField, setSelectedField] = useState<{
      blockId: string | null;
      field: string | null;
      index: number | null;
   }>();

   const handelFocusSetCustom = (index: number | null, field: string | null) => {
      setShowFieldHeader(field);
      setShowFieldIndexHeader(index);
   };

   const divRefCustom = useRef(null);

   const handleMenuFieldOpen = (divRf: any, blockId: string, field: string, index: number) => {
      setAnchorElField(divRf.current);
      setSelectedField({ blockId: blockId, field: field, index: index });
   };
   const handleMenuFieldClose = () => {
      setAnchorElField(null);
   };

   const addField = (
      name: string,
      selectedField: { blockId: string | null; field: string | null; index: number | null } | undefined,
   ) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (selectedField && item._id === selectedField.blockId) {
               const updatedFields = item.fields.map((field) => {
                  if (field.type === IFlowBlockFieldTypes.SetCustomField) {
                     if (selectedField.blockId && selectedField.index !== null && selectedField.field === 'custom') {
                        const textAreaValue = field.customValue || '';
                        const textAreaElement = document.getElementById(
                           `textarea-${'custom'}-${selectedField.index}`,
                        ) as HTMLTextAreaElement | null;

                        if (textAreaElement) {
                           const start = textAreaElement.selectionStart;
                           const end = textAreaElement.selectionEnd;
                           const updatedValue = textAreaValue.substring(0, start) + name + textAreaValue.substring(end);

                           handleCustomValueChange(selectedField.blockId, selectedField.index, updatedValue);
                        }
                     }
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });

         setIsBtnDisable(false);
         return updatedBlockState;
      });

      handleMenuFieldClose();
   };

   return (
      <Grid>
         <Grid>
            <ClickAwayListener onClickAway={() => handelFocusSetCustom(null, null)}>
               <div>
                  {blockState.map((item, index) => (
                     <Grid>
                        {item.fields.map((field, fielIndex) => (
                           <Grid>
                              <Typography>
                                 {field.type === 'add_chat_tag'
                                    ? t.atomation_flow_action_add_tag
                                    : field.type === 'clear_custom_field'
                                    ? t.atomation_flow_action_clear_custom_field
                                    : field.type === 'set_custom_field'
                                    ? t.atomation_flow_action_set_custom_field
                                    : field.type === 'remove_chat_tag'
                                    ? t.atomation_flow_action_remove_tag
                                    : field.type === 'change_email_consent'
                                    ? t.atomation_flow_action_email_consent_change
                                    : field.type === 'change_phone_consent'
                                    ? t.atomation_flow_action_phone_consent_change
                                    : field.type === 'assign_to_agent'
                                    ? t.atomation_flow_action_assign_to_agent
                                    : field.type === 'assign_to_team'
                                    ? t.atomation_flow_action_assign_to_team
                                    : t.atomation_flow_action_conversation_status_change}
                              </Typography>
                              <Grid
                                 style={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}
                                 onMouseEnter={() => setHoveredBlockId(fielIndex)}
                                 onMouseLeave={() => setHoveredBlockId(null)}
                              >
                                 <div
                                    style={{
                                       position: 'relative',
                                       display: 'flex',
                                       width: '100%',
                                       gap: field.type === 'set_custom_field' ? '5px' : 0,
                                    }}
                                 >
                                    <SearchSelect
                                       key={searchSelectKey}
                                       options={
                                          field.type === 'add_chat_tag'
                                             ? TAGS
                                             : field.type === 'remove_chat_tag'
                                             ? TAGS
                                             : field.type === 'change_email_consent'
                                             ? OPTIONS_CONSENT
                                             : field.type === 'change_phone_consent'
                                             ? OPTIONS_CONSENT
                                             : field.type === 'assign_to_agent'
                                             ? AGENTS
                                             : field.type === 'assign_to_team'
                                             ? TEAMS
                                             : field.type === 'clear_custom_field' || field.type === 'set_custom_field'
                                             ? conditionMenu
                                             : OPTIONS_STATUS
                                       }
                                       className={
                                          field.type === 'set_custom_field'
                                             ? classes.selectValue50
                                             : classes.selectValue
                                       }
                                       menuPosition='fixed'
                                       placeholder={<div>{t.atomation_flow_select}</div>}
                                       styles={customStyles}
                                       defaultValue={
                                          field.value &&
                                          !(
                                             field.type === 'set_custom_field' &&
                                             field.value &&
                                             field.value.split('*_*_*')[0] === 'undefined'
                                          )
                                             ? {
                                                  label:
                                                     field.type === 'assign_to_agent'
                                                        ? getLabelFromAgents(field.value)
                                                        : field.type === 'set_custom_field'
                                                        ? field.value.split('*_*_*')[0]
                                                        : field.type === 'assign_to_team'
                                                        ? getLabelFromTeams(field.value)
                                                        : field.type === 'add_chat_tag'
                                                        ? getLabelFromTags(field.value)
                                                        : field.type === 'remove_chat_tag'
                                                        ? getLabelFromTags(field.value)
                                                        : field.type === 'change_email_consent'
                                                        ? field.value === 'true'
                                                           ? t.page_contacts_typo_subscribed
                                                           : t.page_contacts_typo_unsubscribed
                                                        : field.type === 'change_phone_consent'
                                                        ? field.value === 'true'
                                                           ? t.page_contacts_typo_subscribed
                                                           : t.page_contacts_typo_unsubscribed
                                                        : field.type === 'change_conversation_status'
                                                        ? field.value === '0'
                                                           ? t.page_settings_typo_chat_settings_automatic_actions_select_option_open
                                                           : t.page_settings_typo_chat_settings_automatic_actions_select_option_close
                                                        : field.value,
                                                  value:
                                                     field.type === 'set_custom_field'
                                                        ? field.value.split('*_*_*')[0]
                                                        : field.value,
                                               }
                                             : null
                                       }
                                       onChange={(e) => {
                                          if (e) {
                                             if (field.type === 'set_custom_field') {
                                                handleNameValueChange(item._id, fielIndex, e.value);
                                             } else {
                                                handleSelectValueChange(item._id, fielIndex, e.value);
                                             }
                                          }
                                       }}
                                    />
                                    <div style={{ marginTop: -15 }}>
                                       {field.type === 'set_custom_field' && (
                                          <TextField
                                             id={`textarea-${'custom'}-${fielIndex}`}
                                             name='value'
                                             placeholder={t.page_settings_value}
                                             value={
                                                field.value && field.value.split('*_*_*')[1] === 'undefined'
                                                   ? null
                                                   : field.value && field.value.split('*_*_*')[1]
                                             }
                                             variant='outlined'
                                             inputProps={{
                                                style: {
                                                   height,
                                                },
                                             }}
                                             onFocus={() => handelFocusSetCustom(fielIndex, 'custom')}
                                             onChange={(e) =>
                                                handleCustomValueChange(item._id, fielIndex, e.target.value)
                                             }
                                          />
                                       )}
                                       {showFieldHeader === 'custom' && showFieldIndexHeader === fielIndex && (
                                          <div ref={divRefCustom}>
                                             <DataObjectIcon
                                                className={classes.dataObject}
                                                onClick={() =>
                                                   handleMenuFieldOpen(divRefCustom, item._id, 'custom', fielIndex)
                                                }
                                             />
                                          </div>
                                       )}
                                    </div>
                                 </div>
                                 {hoveredBlockId === fielIndex && (
                                    <DeleteIcon
                                       onClick={() => handleDeleteField(item._id, fielIndex)}
                                       className={classes.deleteIcon}
                                       style={{ position: 'absolute', right: -22, top: 0 }}
                                    />
                                 )}
                              </Grid>
                           </Grid>
                        ))}
                     </Grid>
                  ))}

                  {fieldCounts < 12 && (
                     <Grid>
                        <button className={classes.btnGroup} onClick={(e) => handleMenuOpenBlock(e)}>
                           {t.atomation_flow_add_action}
                        </button>
                     </Grid>
                  )}
               </div>
            </ClickAwayListener>
         </Grid>

         <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{ marginTop: 10 }}
            PaperProps={{
               style: {
                  width: '365px',
                  borderRadius: '2px',
               },
            }}
         >
            <MenuItem onClick={() => handleSelectAction(IFlowBlockFieldTypes.AddChatTag)}>
               {t.atomation_flow_action_add_tag}
            </MenuItem>
            <MenuItem onClick={() => handleSelectAction(IFlowBlockFieldTypes.RemoveChatTag)}>
               {t.atomation_flow_action_remove_tag}
            </MenuItem>
            <MenuItem onClick={() => handleSelectAction(IFlowBlockFieldTypes.AssignToAgent)}>
               {t.atomation_flow_action_assign_to_agent}
            </MenuItem>
            <MenuItem onClick={() => handleSelectAction(IFlowBlockFieldTypes.AssignToTeam)}>
               {t.atomation_flow_action_assign_to_team}
            </MenuItem>
            <MenuItem onClick={() => handleSelectAction(IFlowBlockFieldTypes.SetCustomField)}>
               {t.atomation_flow_action_set_custom_field}
            </MenuItem>
            <MenuItem onClick={() => handleSelectAction(IFlowBlockFieldTypes.ClearCustomField)}>
               {t.atomation_flow_action_clear_custom_field}
            </MenuItem>
            <MenuItem onClick={() => handleSelectAction(IFlowBlockFieldTypes.ChangeEmailConsent)}>
               {t.atomation_flow_action_email_consent_change}
            </MenuItem>
            <MenuItem onClick={() => handleSelectAction(IFlowBlockFieldTypes.ChangePhoneConsent)}>
               {t.atomation_flow_action_phone_consent_change}
            </MenuItem>
            <MenuItem onClick={() => handleSelectAction(IFlowBlockFieldTypes.ChangeConversationStatus)}>
               {t.atomation_flow_action_conversation_status_change}
            </MenuItem>
         </Menu>
         <Menu
            anchorEl={anchorElField}
            open={Boolean(anchorElField)}
            onClose={handleMenuFieldClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{ marginTop: 5 }}
            PaperProps={{
               style: {
                  width: '365px',
                  borderRadius: '5px',
                  maxHeight: '250px',
                  marginLeft: '-174px',
                  marginTop: '20px',
               },
            }}
         >
            {customFields.map((custom) => (
               <MenuItem onClick={() => addField('{{' + custom.name + '}}', selectedField)}>{custom.name}</MenuItem>
            ))}
         </Menu>
      </Grid>
   );
};

export default ActionBlocks;

import { Box, Button, Grid, CircularProgress } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LanguageContext } from '@helper/locale/langContext';
// import useLocalStorage from '../../../../hooks/useLocalStorage';
import Input from '@components/InputField/index';
import SingleInput from '@components/_Modal/SingleInput';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { makeStyles } from '@material-ui/core/styles';
import { Opacity } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const WooCommerceForm = ({ onAccept, onBack, mode, onAcceptEditData, data: selectedItem }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const [urlLoading, setUrlLoading] = useState(false);
   const t = lang?.translation;

   const {
      control,
      handleSubmit,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         storeName: mode ? selectedItem?.storeName : '',
         store_url: mode ? selectedItem?.store_url : 'https://',
      },
   });

   const onSubmit = (data) => {
      setUrlLoading(true);
      if (mode) {
         onAcceptEditData({
            ...data,
            _id: selectedItem._id,
         });
      } else {
         onAccept(data);
      }
   };

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <Grid container>
            <SingleInput
               control={control}
               name='storeName'
               label={'WooCommerce Store Name' + '*'}
               placeholder={t?.example + 'Pazardabuldum'}
               helperText={t?.settings_form_name_of_showing_panel}
               rules={{
                  required: t?.please_fill_this_field,
                  minLength: {
                     value: 5,
                     message: t?.max_5_characters,
                  },
                  validate: (v) => {
                     return !!v.trim();
                  },
               }}
            />
            <SingleInput
               control={control}
               name='store_url'
               label={'WooCommerce Store URL' + '*'}
               placeholder={t?.example + 'https://pazardabuldum.com'}
               rules={{
                  required: t?.please_fill_this_field,
                  minLength: {
                     value: 5,
                     message: t?.max_5_characters,
                  },
                  validate: (v) => {
                     // v = v.trim();
                     const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/gm;
                     return regex.test(v);
                  },
               }}
            />
            <ModalButtonField>
               {!mode && (
                  <Button
                     className={classes.modalButtons}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        '&:hover': {
                           backgroundColor: '#e0e0e0',
                        },
                     }}
                     onClick={onBack}
                  >
                     {t?.back}
                  </Button>
               )}
               <Button
                  className={classes.modalButtons}
                  type='submit'
                  disabled={!isValid}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     '&:hover': {
                        backgroundColor: '#105CBB',
                     },
                     float: 'right',
                  }}
               >
                  {urlLoading ? <CircularProgress color='inherit' size={25} /> : mode ? t?.save : t?.connect}
               </Button>
            </ModalButtonField>
         </Grid>
      </form>
   );
};

export default WooCommerceForm;

import React from 'react';
import { ButtonBase, CircularProgress, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { useState } from 'react';
import AudiotapeIcon from '@material-ui/icons/Audiotrack';
import Modal from '../Modal/modal';
import CloseIcon from '@material-ui/icons/Close';

const AudioPlayer = ({ url, name, isLoading, showPreview, onClose, disabled, preview }) => {
   const [showModal, setShowModal] = useState(false);

   return (
      <Grid
         container
         direction='row'
         justifyContent='space-between'
         alignItems='flex-start'
         wrap='nowrap'
         style={{ backgroundColor: disabled ? 'lightgrey' : 'transparent' }}
      >
         <Grid
            container
            justifyContent='space-around'
            alignItems='flex-start'
            direction='column'
            style={{ padding: 10, paddingBottom: 5 }}
         >
            {showPreview && (
               <ButtonBase onClick={() => setShowModal(true)}>
                  <AudiotapeIcon style={{ fontSize: '3rem', color: 'grey' }} />
               </ButtonBase>
            )}
            <Typography
               style={{ fontSize: '1rem', color: 'grey', cursor: 'pointer', marginTop: 5 }}
               onClick={() => setShowModal(true)}
            >
               {' '}
               {name}{' '}
            </Typography>
            {isLoading && <CircularProgress />}
            <Modal
               open={showModal}
               onClose={() => setShowModal(false)}
               paperStyle={{
                  width: 'auto',
                  height: 'auto',
                  backgroundColor: 'transparent',
                  boxShadow: 'unset',
               }}
            >
               <audio controls>
                  <source src={url} type='audio/mp3' />
                  Your browser does not support the audio element.
               </audio>
            </Modal>
         </Grid>
         {!preview && (
            <IconButton onClick={onClose}>
               <CloseIcon />
            </IconButton>
         )}
      </Grid>
   );
};

export default AudioPlayer;

import React from 'react';

// Icons for dashboard
export const HomeIcon = ({ className, fill }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} fill={fill} viewBox='0 0 25.477 25.008'>
         <g id='Group_229' data-name='Group 229' transform='translate(-18 -107.346)'>
            <path
               id='Path_95'
               data-name='Path 95'
               d='M913.639,220.355h-4.648c0,.285,0,.542,0,.8.005,2.346.015,4.691.014,7.037,0,1.007-.4,1.418-1.4,1.421q-3.809.01-7.617,0c-.962,0-1.394-.412-1.4-1.362-.01-4.522-.02-9.044.023-13.565a1.764,1.764,0,0,1,.61-1.213c3.737-2.873,7.509-5.7,11.269-8.544a1.238,1.238,0,0,1,1.655,0c3.76,2.843,7.533,5.669,11.269,8.543a1.766,1.766,0,0,1,.611,1.213c.043,4.522.033,9.044.023,13.566,0,.955-.432,1.363-1.392,1.365q-3.808.011-7.617,0c-1,0-1.4-.412-1.4-1.418C913.635,225.608,913.639,223.023,913.639,220.355Zm-6.951,6.913c0-.325,0-.586,0-.847q-.008-3.481-.014-6.963c0-1.051.395-1.451,1.438-1.454q3.155-.008,6.31,0c1.15,0,1.531.375,1.533,1.512q.006,3.482,0,6.963v.767h5.785v-.757c0-3.578-.011-7.156.012-10.735a1.248,1.248,0,0,0-.584-1.131c-3.119-2.326-6.22-4.677-9.328-7.019-.17-.128-.35-.243-.545-.377-3.379,2.55-6.74,5.075-10.078,7.629a1.14,1.14,0,0,0-.3.81c-.02,3.651-.014,7.3-.011,10.952,0,.21.028.419.044.65Z'
               transform='translate(-880.585 -97.261)'
            />
         </g>
      </svg>
   );
};

export const ChatIcon = ({ className, fill }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} fill={fill} viewBox='0 0 23.745 23.976'>
         <g id='Group_4563' data-name='Group 4563' transform='translate(-9.843)'>
            <path
               id='Path_85'
               data-name='Path 85'
               d='M244.062,189.554a25.554,25.554,0,0,1-4.337-1.133,6.008,6.008,0,0,0-3.853-.364c-1.933.388-2.645-.323-2.233-2.27a6.333,6.333,0,0,0-.4-3.84,11.818,11.818,0,1,1,22.653-6.069A12.029,12.029,0,0,1,244.062,189.554Zm-6.944-4.982c1.046.324,2.215.6,3.314,1.047a9.445,9.445,0,0,0,5.674.6,8.929,8.929,0,1,0-10-4.891A24.5,24.5,0,0,1,237.118,184.572Z'
               transform='translate(-222.433 -165.578)'
            />
            <path
               id='comment-alt-dots'
               d='M14.886,9.711a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,14.886,9.711Z'
               transform='translate(1.831 1.027)'
            />
            <path
               id='comment-alt-dots-2'
               data-name='comment-alt-dots'
               d='M14.886,9.711a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,14.886,9.711Z'
               transform='translate(6.83 1.027)'
            />
            <path
               id='comment-alt-dots-3'
               data-name='comment-alt-dots'
               d='M14.886,9.711a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,14.886,9.711Z'
               transform='translate(11.828 1.027)'
            />
         </g>
      </svg>
   );
};

export const ActiveVisitorIcon = ({ className, fill, active }) => {
   return (
      <svg
         className={className}
         fill={fill}
         xmlns='http://www.w3.org/2000/svg'
         width='25.66'
         height='25.319'
         viewBox='0 0 25.66 25.319'
      >
         <g id='Group_4630' data-name='Group 4630' transform='translate(-17.34 -286)'>
            <path
               id='radar-solid'
               d='M25.081,13.993A11.077,11.077,0,1,1,13.293,2.916V5.844a8.145,8.145,0,1,0,8.862,8.15H20.573A6.567,6.567,0,1,1,13.214,7.5v3.481A3.186,3.186,0,1,0,14.8,17.157a3.253,3.253,0,0,0,2.294-3.877A3.346,3.346,0,0,0,14.8,10.985V1.333h-.87a12.66,12.66,0,1,0,12.66,12.66Z'
               transform='translate(16.072 284.667)'
               // fill='#7d8da0'
            />
            <circle
               id='Ellipse_280'
               data-name='Ellipse 280'
               cx='4'
               cy='4'
               r='4'
               transform='translate(35 286)'
               fill={active ? '#00ff71' : '#7d8da0'}
            />
         </g>
      </svg>
   );
};

export const ProfileIcon = ({ className, fill }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} fill={fill} viewBox='0 0 21.918 25.197'>
         <path
            id='Path_96'
            data-name='Path 96'
            d='M834.123,109.371a6.572,6.572,0,1,1,5.955.015c.126.049.223.094.324.126a10.947,10.947,0,0,1,7.661,10.332c0,.354,0,.708,0,1.061a1.108,1.108,0,0,1-1.233,1.225H827.421a1.137,1.137,0,0,1-1.265-1.266,11.735,11.735,0,0,1,.957-5.372,10.873,10.873,0,0,1,6.813-6.016c.054-.017.106-.036.159-.057C834.094,109.417,834.1,109.4,834.123,109.371Zm-5.8,10.555H845.9a8.579,8.579,0,0,0-4.161-7.411,8.35,8.35,0,0,0-9.169-.051A8.569,8.569,0,0,0,828.324,119.926Zm8.79-20.805a4.388,4.388,0,1,0,4.387,4.371A4.387,4.387,0,0,0,837.114,99.121Z'
            transform='translate(-826.149 -96.935)'
            // fill='#7d8da0'
         />
      </svg>
   );
};

export const StatisticsIcon = ({ className, fill, style }) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         className={className}
         fill={fill}
         viewBox='0 0 22.089 22.089'
         style={{ ...style }}
      >
         <rect
            id='Rectangle_20'
            data-name='Rectangle 20'
            width='5.522'
            height='8.283'
            rx='1'
            transform='translate(0 13.806)'
         />
         <rect
            id='Rectangle_21'
            data-name='Rectangle 21'
            width='5.522'
            height='13.806'
            rx='1'
            transform='translate(8.283 8.283)'
         />
         <rect
            id='Rectangle_22'
            data-name='Rectangle 22'
            width='5.522'
            height='22.089'
            rx='1'
            transform='translate(16.567 0)'
         />
      </svg>
   );
};

export const SettingsIcon = ({ className, fill }) => {
   return (
      <svg className={className} fill={fill} viewBox='0 0 26.445 27.936'>
         <g transform='translate(-13.599 0.05)'>
            <path
               id='Path_80'
               data-name='Path 80'
               d='M269.306,333a.951.951,0,0,1-.055.442l-.027.082a13.935,13.935,0,0,1-2.482,4.3l-.055.066a.994.994,0,0,1-1.1.295l-2.536-.9a10.856,10.856,0,0,1-3.109,1.795l-.491,2.651a1.006,1.006,0,0,1-.8.8l-.082.016a13.876,13.876,0,0,1-2.471.224h.011a13.891,13.891,0,0,0,2.477-.224l.082-.016a.992.992,0,0,0,.8-.8l.491-2.646a10.9,10.9,0,0,0,3.1-1.795l2.531.9a1,1,0,0,0,1.1-.295l.055-.065a13.926,13.926,0,0,0,2.482-4.293l.027-.082A.917.917,0,0,0,269.306,333Z'
               transform='translate(-229.273 -314.883)'
            />
            <path
               id='Path_81'
               data-name='Path 81'
               d='M163.839,154.019a4.222,4.222,0,1,0-4.219,4.219A4.213,4.213,0,0,0,163.839,154.019Zm-6.9,0a2.678,2.678,0,1,1,.784,1.9A2.693,2.693,0,0,1,156.935,154.019Z'
               transform='translate(-132.798 -140.122)'
            />
            <path
               id='Path_82'
               data-name='Path 82'
               d='M29.385,27.646a.992.992,0,0,0,.8-.8l.491-2.651A11.058,11.058,0,0,0,33.787,22.4l2.536.9A1,1,0,0,0,37.42,23l.055-.065a13.843,13.843,0,0,0,2.482-4.3l.027-.082a.943.943,0,0,0,.055-.442,1.006,1.006,0,0,0-.344-.649l-2.035-1.74-.005-.005a11.03,11.03,0,0,0,.147-1.8,10.835,10.835,0,0,0-.115-1.576c-.011-.071-.022-.136-.033-.207,0-.005-.005-.016-.005-.022l2.04-1.746a1,1,0,0,0,.289-1.1l-.027-.082a13.827,13.827,0,0,0-2.482-4.3l-.055-.065a1,1,0,0,0-1.1-.295l-2.536.9a10.9,10.9,0,0,0-3.1-1.795h-.005L30.182.984c-.005-.016-.005-.033-.011-.049a.988.988,0,0,0-.785-.747L29.3.171a14.021,14.021,0,0,0-4.958,0l-.082.016a.992.992,0,0,0-.8.8l-.491,2.662A11.136,11.136,0,0,0,19.878,5.44l-2.553-.905a1,1,0,0,0-1.1.295l-.055.065a13.827,13.827,0,0,0-2.482,4.3l-.027.082a1,1,0,0,0,.245,1.058c.016.011.027.027.044.038l2.067,1.762v.005a10.761,10.761,0,0,0-.142,1.778,11.334,11.334,0,0,0,.142,1.778l-2.073,1.767a.994.994,0,0,0-.289,1.1l.027.082a13.889,13.889,0,0,0,2.482,4.3l.055.065a.967.967,0,0,0,.578.333.994.994,0,0,0,.518-.038l2.553-.905c.005.005.011.005.011.011a10.958,10.958,0,0,0,3.071,1.778l.491,2.662a1.006,1.006,0,0,0,.8.8l.082.016a13.939,13.939,0,0,0,2.476.224h.011a13.832,13.832,0,0,0,2.471-.224Zm-1.244-2.073a11.966,11.966,0,0,1-2.651,0l-.556-3.027-1.178-.453A8.854,8.854,0,0,1,21.3,20.67l-.976-.807L17.412,20.9a11.74,11.74,0,0,1-1.326-2.3l2.351-2.013-.2-1.249a8.837,8.837,0,0,1,0-2.836l.2-1.249L16.087,9.242a11.74,11.74,0,0,1,1.326-2.3l2.913,1.036.976-.807a8.777,8.777,0,0,1,2.455-1.424L24.94,5.3,25.5,2.276a11.966,11.966,0,0,1,2.651,0L28.7,5.3l1.189.447a8.748,8.748,0,0,1,2.471,1.429l.976.8,2.891-1.025a11.741,11.741,0,0,1,1.326,2.3l-2.324,2,.2,1.244a8.985,8.985,0,0,1,.12,1.435,8.4,8.4,0,0,1-.12,1.435l-.207,1.249L37.556,18.6a11.741,11.741,0,0,1-1.326,2.3L33.34,19.868l-.976.8A8.824,8.824,0,0,1,29.892,22.1l-1.189.447Z'
               transform='translate(0 0)'
            />
         </g>
      </svg>
   );
};

export const SupportIcon = ({ className, fill }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} fill={fill} viewBox='0 0 27.502 27.609'>
         <g id='Group_177' data-name='Group 177' transform='translate(-1016.13 -3326.176)'>
            <path
               id='Path_130'
               data-name='Path 130'
               d='M-203.362-228.741c.47.138.947.258,1.408.422a2.81,2.81,0,0,1,.648.377.61.61,0,0,1,.194.863.589.589,0,0,1-.845.173,4.079,4.079,0,0,0-2.45-.6c-3.62.015-7.24,0-10.86.007a6.075,6.075,0,0,0-5.149,2.584,5.827,5.827,0,0,0-1.124,3.526q-.01,6.9,0,13.8c0,.05,0,.1.008.189.082-.061.144-.1.2-.148.538-.477,1.07-.96,1.614-1.43a.525.525,0,0,0,.2-.445q-.008-5.859-.005-11.717a4.255,4.255,0,0,1,1.79-3.619,4.156,4.156,0,0,1,2.53-.8c4.858,0,9.717-.014,14.575,0a4.268,4.268,0,0,1,4.331,4.34q.024,5.326,0,10.652a4.285,4.285,0,0,1-4.366,4.363c-4.408.01-8.816-.005-13.224-.015a.761.761,0,0,0-.558.213q-1.95,1.794-3.91,3.577a2.055,2.055,0,0,1-.178.151.585.585,0,0,1-.634.083.59.59,0,0,1-.353-.56c0-.528,0-1.057,0-1.585q0-1.4,0-2.806v-.3c-.107.087-.173.136-.234.191q-.9.81-1.79,1.622c-.517.47-.96.384-1.241-.242v-15.8c.014-.092.027-.185.044-.277a11.9,11.9,0,0,1,.332-1.7,7.229,7.229,0,0,1,5.262-4.872c.4-.106.814-.148,1.222-.22Zm-14.923,24.553c.089-.073.129-.1.166-.138.874-.8,1.767-1.585,2.616-2.413a2.426,2.426,0,0,1,1.833-.726c4.321.027,8.643.021,12.964.018a3.057,3.057,0,0,0,3.156-3.147q.009-5.3,0-10.6a3.039,3.039,0,0,0-3.119-3.124q-7.262-.008-14.523.006a3.429,3.429,0,0,0-1.068.176,2.982,2.982,0,0,0-2.025,2.956q0,8.353,0,16.706Z'
               transform='translate(1239.426 3555.417)'
               strokeWidth='1'
            />
            <path
               id='Path_131'
               data-name='Path 131'
               d='M144.5-246.954a3.491,3.491,0,0,1,2.034.542,2.527,2.527,0,0,1,1.135,1.532,3.3,3.3,0,0,1,.139.611,2.822,2.822,0,0,1-1.016,2.3c-.247.228-.519.431-.787.636a1.26,1.26,0,0,0-.54,1.058,1.047,1.047,0,0,1-.385.878.9.9,0,0,1-1.33-.143.48.48,0,0,1-.108-.265,8.815,8.815,0,0,1,.093-1.162,3.173,3.173,0,0,1,1.547-1.981,1.958,1.958,0,0,0,.716-.794.968.968,0,0,0-.258-1.2,1.912,1.912,0,0,0-1.524-.384,1.86,1.86,0,0,0-1.522,1.069.794.794,0,0,1-1.465-.055.752.752,0,0,1-.012-.562,2.715,2.715,0,0,1,.94-1.316,4.992,4.992,0,0,1,1.151-.588,8.131,8.131,0,0,1,1.18-.242Z'
               transform='translate(886.626 3580.524)'
            />
            <path
               id='Path_132'
               data-name='Path 132'
               d='M194.292-41.738a.805.805,0,0,1-.394.838.986.986,0,0,1-.93.044.8.8,0,0,1-.521-.771,1.091,1.091,0,0,1,.237-.859.951.951,0,0,1,.965-.262A.9.9,0,0,1,194.292-41.738Z'
               transform='translate(837.668 3385.531)'
            />
         </g>
      </svg>
   );
};

export const QuestionsSideBarIcon = ({ className, fill }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} fill={fill} viewBox='0 0 24.355 23.747'>
         <g id='Group_2415' data-name='Group 2415' transform='translate(0.15 0.15)'>
            <path
               id='Subtraction_26'
               data-name='Subtraction 26'
               d='M23.13,23.447H23.1a.965.965,0,0,1-.672-.267l-4.637-4.53H3.412A3.375,3.375,0,0,1,0,15.322V3.328A3.375,3.375,0,0,1,3.412,0H20.643a3.375,3.375,0,0,1,3.412,3.328V22.519a.927.927,0,0,1-.59.853l-.013.006A.8.8,0,0,1,23.13,23.447ZM3.412,1.855A1.493,1.493,0,0,0,1.9,3.328V15.322A1.493,1.493,0,0,0,3.412,16.8H18.187a.952.952,0,0,1,.67.267l3.3,3.226V3.328a1.493,1.493,0,0,0-1.509-1.473Z'
               // stroke='currentColor'
               // strokeWidth='0.5'
            />
            <path
               id='comment-alt-dots'
               d='M14.886,9.711a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,14.886,9.711Z'
               transform='translate(-7.857 -0.979)'
            />
            <path
               id='comment-alt-dots-2'
               data-name='comment-alt-dots'
               d='M14.886,9.711a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,14.886,9.711Z'
               transform='translate(-2.859 -0.978)'
            />
            <path
               id='comment-alt-dots-3'
               data-name='comment-alt-dots'
               d='M14.886,9.711a1.25,1.25,0,1,0,1.25,1.25A1.25,1.25,0,0,0,14.886,9.711Z'
               transform='translate(2.14 -0.978)'
            />
         </g>
      </svg>
   );
};
// Main Logo in Drawer
export const MainLogo = ({ className, style }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} style={style} viewBox='0 0 42.149 41.746'>
         <path
            id='Path_2'
            data-name='Path 2'
            d='M55.971,45.046a13.889,13.889,0,0,0,.784-1.2s.007-.009.01-.014.011-.023.016-.033c.072-.126.136-.235.223-.4l-.031.017a19.065,19.065,0,0,0,2.409-9.448,18.4,18.4,0,0,0-5.992-14.089,21.3,21.3,0,0,0-15.083-5.567,21.3,21.3,0,0,0-15.082,5.567,18.4,18.4,0,0,0-5.992,14.089,19.224,19.224,0,0,0,2.135,9.038,18.747,18.747,0,0,0,5.957,6.77A20.884,20.884,0,0,0,34.1,53.28a27.16,27.16,0,0,0,4.55.367c.095,0,.184-.009.278-.011.194,0,.38-.014.57-.022q.808-.03,1.586-.11c.141-.015.282-.028.42-.045a21.619,21.619,0,0,0,9.557-3.59,28.142,28.142,0,0,1-4.257,3.4s1.747,1.79,8.568,2.708c.265.035,3.069.53,1.808-1.424C56.852,53.911,52.512,50.283,55.971,45.046Zm-25.641.116a13.13,13.13,0,0,1-6.623-10.427c-.461-7.443,5.776-13.886,13.933-14.389s15.143,5.121,15.6,12.565c.417,6.718-4.632,12.606-11.616,14.062a17.015,17.015,0,0,0,3.248.709s-5.31,1.67-12.412-1.466a15.063,15.063,0,0,1-2.113-1.042Zm.351-12.651a1.6,1.6,0,1,0,1.718,1.6,1.662,1.662,0,0,0-1.718-1.6Zm15.6,0A1.6,1.6,0,1,0,48,34.111a1.662,1.662,0,0,0-1.719-1.6Zm-7.8,0a1.6,1.6,0,1,0,1.719,1.6,1.662,1.662,0,0,0-1.719-1.6Z'
            transform='translate(-17.232 -14.312)'
            fill='#fff'
         />
      </svg>
   );
};

export const CampaignIcon = ({ className, style }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} style={style} viewBox='0 0 42.149 41.746'>
         <path d='M36.5 25.5v-3H44v3ZM39 40l-6.05-4.5 1.8-2.4 6.05 4.5Zm-4.1-25.15-1.8-2.4L39 8l1.8 2.4ZM10.5 38v-8H7q-1.25 0-2.125-.875T4 27v-6q0-1.25.875-2.125T7 18h9l10-6v24l-10-6h-2.5v8ZM28 30.7V17.3q1.35 1.2 2.175 2.925Q31 21.95 31 24t-.825 3.775Q29.35 29.5 28 30.7ZM7 21v6h9.8l6.2 3.7V17.3L16.8 21Zm8 3Z' />
      </svg>
   );
};

export const BulkMessagingIcon = ({ className, style }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} style={style} viewBox='0 -960 960 960'>
         <path d='M0-240v-53q0-38.567 41.5-62.784Q83-380 150.376-380q12.165 0 23.395.5Q185-379 196-377.348q-8 17.348-12 35.165T180-305v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-19.861-3.5-37.431Q773-360 765-377.273q11-1.727 22.171-2.227 11.172-.5 22.829-.5 67.5 0 108.75 23.768T960-293v53H780Zm-480-60h360v-6q0-37-50.5-60.5T480-390q-79 0-129.5 23.5T300-305v5ZM149.567-410Q121-410 100.5-430.562 80-451.125 80-480q0-29 20.562-49.5Q121.125-550 150-550q29 0 49.5 20.5t20.5 49.933Q220-451 199.5-430.5T149.567-410Zm660 0Q781-410 760.5-430.562 740-451.125 740-480q0-29 20.562-49.5Q781.125-550 810-550q29 0 49.5 20.5t20.5 49.933Q880-451 859.5-430.5T809.567-410ZM480-480q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm.351-60Q506-540 523-557.351t17-43Q540-626 522.851-643t-42.5-17Q455-660 437.5-642.851t-17.5 42.5Q420-575 437.351-557.5t43 17.5ZM480-300Zm0-300Z' />
      </svg>
   );
};

export const AutomationIcon = ({ className, style }) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         width='30.076'
         height='38.662'
         className={className}
         style={style}
         viewBox='0 0 30.076 38.662'
      >
         <path
            id='Path_4724'
            data-name='Path 4724'
            d='M24.626.148a1.086,1.086,0,0,1,.493,1.259L21.208,14.113h7.215a1.086,1.086,0,0,1,.79,1.831L11.844,34.4a1.086,1.086,0,0,1-1.828-1.064l3.91-12.708H6.711A1.086,1.086,0,0,1,5.92,18.8L23.291.341A1.086,1.086,0,0,1,24.626.146Z'
            transform='translate(1.108 -1.072) rotate(11)'
         />
      </svg>
   );
};

import { Divider, TextField, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { ChatNoteIcon } from '@assets/icons/ChatPageIcons';
import { LanguageContext } from '@helper/locale/langContext';
import { MyTheme } from '@styles/config';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';

interface NoteInputProps {
   width?: number;
   onCancel: () => void;
   onSend: (note: string) => void;
}

const useStyles = makeStyles<MyTheme, NoteInputProps>((theme) => ({
   root: {
      padding: 10,
      width: (props: any) => (props.width < 700 ? 300 : 550),
      height: 350,
      maxHeight: 400,
   },
   title: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.5rem',
      marginLeft: 10,
   },
   textInput: {
      width: '100%',
      padding: 15,
      fontSize: '1.075rem',
   },
   icon: {
      width: 20,
      height: 20,
   },
}));

const Note: React.FC<NoteInputProps> = (props) => {
   const { onCancel, onSend } = props;
   const classes = useStyles(props);
   const { lang } = useContext(LanguageContext);
   const inputRef = useRef<HTMLInputElement | null>(null);
   const t: any = lang?.translation;
   const [state, setState] = useState({
      noteText: '',
   });
   // all input text on change event
   const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setState((prevState) => ({ ...prevState, [name]: value }));
   };

   useEffect(() => {
      if (inputRef.current) {
         inputRef.current.focus();
      }
   }, []);

   return (
      <Grid className={classes.root} container>
         <Grid item container alignItems='center' justifyContent='center' direction='row'>
            <ChatNoteIcon className={classes.icon} />
            <Typography align='center' variant='h5' className={classes.title}>
               {t?.special_note}
            </Typography>
         </Grid>
         <Divider style={{ width: '100%', marginTop: 10 }} />
         <Grid item xs={12}>
            <TextField
               className={classes.textInput}
               rows='7'
               inputRef={inputRef}
               onChange={handleInputChange}
               value={state.noteText}
               InputProps={{
                  disableUnderline: true,
               }}
               name='noteText'
               placeholder={t['page_chat_typo_please_enter_note_']}
               multiline
               fullWidth
            />
         </Grid>
         <Divider style={{ width: '100%' }} />
         <Grid item container justifyContent='space-between' style={{ paddingRight: 10, paddingLeft: 10, padding: 15 }}>
            <Grid item xs={5}>
               <ButtonCancel
                  height={40}
                  style={{ width: '100%' }}
                  fontSize='1.1rem'
                  onClick={onCancel}
                  label={t?.comp_button_cancel}
               />
            </Grid>
            <Grid item xs={5}>
               <ButtonOK
                  height={40}
                  onClick={() => onSend(state.noteText)}
                  disabled={state.noteText.trim().length > 0 ? false : true}
                  label={t?.page_chat_btn_add_note}
                  fontSize='1.1rem'
                  style={{ width: '100%' }}
               />
            </Grid>
         </Grid>
      </Grid>
   );
};

export default Note;

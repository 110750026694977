import { gql } from '@apollo/client';

export const GET_CUSTOMER_SATISFACTION_SURVEYS = gql`
   query getSurveys(
      $customer: String!
      $pageSize: Int!
      $pageNumber: Int!
      $name: String
      $status: Boolean
   ) {
      getSurveys(
         input: {
            customer: $customer
            pageSize: $pageSize
            pageNumber: $pageNumber
            name: $name
            status: $status
         }
      ) {
         success
         message
         data {
            _id
            customer
            status
            name
            logo
            title
            message
            commentInputTitle
            submitButtonText
            themeMainColor
            thankYouMessage
            createdAt
            updatedAt
         }
         code
      }
   }
`;

export const GET_CUSTOMER_SATISFACTION_SURVEY = gql`
   query getSurvey($_id: ID!) {
      getSurvey(input: { _id: $_id }) {
         success
         message
         data {
            _id
            customer
            status
            name
            logo
            title
            message
            commentInputTitle
            submitButtonText
            themeMainColor
            thankYouMessage
            createdAt
            updatedAt
         }
         code
      }
   }
`;

export const GET_FEEDBACK_SURVEY = gql`
   query getFeedbackSurvey($_id: String!) {
      getFeedbackSurvey(input: { _id: $_id }) {
         success
         message
         data {
            _id
            customer
            status
            name
            logo
            title
            message
            commentInputTitle
            submitButtonText
            themeMainColor
            thankYouMessage
            createdAt
            updatedAt
            isRated
         }
         code
      }
   }
`;

import { Grid } from '@material-ui/core';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from './NavBar';
import LeftPanel from './LeftPanel/Panels';
import ContentPanel from './ContentPanel';
import { Edge, MarkerType, Node } from 'reactflow';
import { FlowBotDataItem, FlowNode } from '../../FlowBots/types';
import { useLazyQuery } from '@apollo/client';
import { GET_ECOMMERCE_RULES } from '@queries/Automation/query';
import { RootStateOrAny, useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import { SET_IS_SECOND_MESSAGE } from '@store/actions/automation';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

const useStyles = makeStyles(() => ({
   container: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
   },
   navBar: {
      backgroundColor: '#FFFFFF',
      borderBottom: '1px solid #F5F5F5',
      height: '65px',
      width: '100%',
   },
   content: {
      flex: 1,
      display: 'flex',
   },
   leftPanel: {
      backgroundColor: '#FFFFFF',
      width: '360px',
      height: '100%',
      position: 'fixed',
      zIndex: 1,
   },
   contentPanel: {
      flex: 1,
      backgroundColor: '#F7F7FA',
   },
}));

interface RulesBuilderProps {
   leftNodes: Node[];
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   selectedItem: FlowBotDataItem | undefined;
   clickedNodeId: string;
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
}

const RulesBuilder: React.FC<RulesBuilderProps> = ({
   leftNodes,
   setLeftNodes,
   selectedItem,
   setClickedNodeId,
   clickedNodeId,
}) => {
   const classes = useStyles();
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   let { id } = useParams();
   //const ruleId = id;
   const [heigherNode, setHeigherNode] = useState<number>(200);
   const [isSecondMessage, setIsSecondMessage] = useState(false);

   const ruleStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.ruleSatus);
   const ruleId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.ruleId);
   const dispatch = useDispatch();

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [getRules] = useLazyQuery(GET_ECOMMERCE_RULES);

   const componentMounted = useRef(true);

   useEffect(() => {
      (async () => {
         setLeftNodes([]);
         setClickedNodeId('');
         await getRules({
            variables: {
               input: {
                  customer: person.customer._id,
                  _id: ruleId,
               },
            },
            fetchPolicy: 'network-only',
            onCompleted: (res) => {
               if (componentMounted.current) {
                  const response = res.getECommerceRules.data.message1;
                  const response2 = res.getECommerceRules.data.message2;
                  const plateForm = res.getECommerceRules.data.platforms[0]?.platformId[0];

                  setLeftNodes([
                     {
                        id: '1-first',
                        type: 'trigger',
                        data: {
                           title: 'Trigger',
                           clickedNodeId,
                           isSecondMessage,
                           platFormId: plateForm,
                        },
                        position: { x: 0, y: 50 },
                     },
                     {
                        id: '2-first',
                        type: 'delay',

                        data: {
                           title: 'Delay',
                           clickedNodeId,
                           setClickedNodeId,
                           flowBlocks: response.delay,
                           platFormId: plateForm,
                        },
                        position: { x: 200, y: 50 },
                     },
                     {
                        id: '3-first',
                        type: 'send_message',
                        data: {
                           title: 'Send Message',
                           clickedNodeId,
                           setClickedNodeId,
                           flowBlocks: [response.sendMessage?.messageTemplateId] || [],
                           platFormId: plateForm,
                        },
                        position: { x: 400, y: 50 },
                     },
                     {
                        id: '4-first',
                        type: 'actions',
                        data: {
                           title: 'Action',
                           clickedNodeId,
                           setClickedNodeId,
                           flowBlocks: response.action,
                           platFormId: plateForm,
                        },
                        position: { x: 600, y: 50 },
                     },

                     //second
                     {
                        id: '5-second',
                        type: 'delay',
                        data: {
                           title: 'Delay',
                           clickedNodeId,
                           setClickedNodeId,
                           flowBlocks: response2.delay,
                           platFormId: plateForm,
                        },
                        position: { x: 200, y: heigherNode },
                     },
                     {
                        id: '6-second',
                        type: 'send_message',
                        data: {
                           title: 'Send Message',
                           clickedNodeId,
                           setClickedNodeId,
                           flowBlocks: [response2.sendMessage?.messageTemplateId] || [],
                           platFormId: plateForm,
                        },
                        position: { x: 400, y: heigherNode },
                     },
                     {
                        id: '7-second',
                        type: 'actions',
                        data: {
                           title: 'Action',
                           clickedNodeId,
                           setClickedNodeId,
                           flowBlocks: response2.action,
                           platFormId: plateForm,
                        },
                        position: { x: 600, y: heigherNode },
                     },
                  ]);

                  if (response2.sendMessage?.messageTemplateId) {
                     setIsSecondMessage(true);
                  }
               }
               return () => {
                  componentMounted.current = false;
               };
            },
         });
      })();
   }, []);

   const [isDraft, setIsDraft] = useState<boolean>(true);

   useEffect(() => {
      if (clickedNodeId) {
         const updatedNodes = leftNodes.map((node) => ({
            ...node,
            data: {
               ...node.data,
               leftNodes: leftNodes,
               clickedNodeId: clickedNodeId,
               isSecondMessage: isSecondMessage,
            },
         }));

         setLeftNodes(updatedNodes);
         dispatch({ type: SET_IS_SECOND_MESSAGE, payload: isSecondMessage });
      }
   }, [clickedNodeId, isSecondMessage]);

   useEffect(() => {
      if (ruleStatus === 'published') {
         setIsDraft(false);
      } else {
         setIsDraft(true);
      }
   }, [ruleStatus]);

   return (
      <div>
         <Grid className={classes.container}>
            <div className={classes.navBar}>
               <NavBar
                  isDraft={isDraft}
                  setIsDraft={setIsDraft}
                  leftNodes={leftNodes}
                  isSecondMessage={isSecondMessage}
                  setClickedNodeId={setClickedNodeId}
               />
            </div>
            <div className={classes.content}>
               {clickedNodeId.length > 0 && (
                  <div className={classes.leftPanel}>
                     <LeftPanel
                        leftNodes={leftNodes}
                        setLeftNodes={setLeftNodes}
                        clickedNodeId={clickedNodeId}
                        setClickedNodeId={setClickedNodeId}
                        setHeigherNode={setHeigherNode}
                        heigherNode={heigherNode}
                        setIsSecondMessage={setIsSecondMessage}
                        isSecondMessage={isSecondMessage}
                     />
                  </div>
               )}
               <div className={classes.contentPanel}>
                  <ContentPanel
                     leftNodes={leftNodes}
                     clickedNodeId={clickedNodeId}
                     setClickedNodeId={setClickedNodeId}
                     isDraft={isDraft}
                     setIsDraft={setIsDraft}
                     setIsSecondMessage={setIsSecondMessage}
                     isSecondMessage={isSecondMessage}
                  />
               </div>
            </div>
         </Grid>
      </div>
   );
};

export default RulesBuilder;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { Node } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { Box, ClickAwayListener, Grid, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import SearchSelect from 'react-select';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { LanguageContext } from '@helper/locale/langContext';
import {
   FlowBlockType,
   HttpTestMethod,
   IFlowBlockField,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { generateRandomId, isValidHttpUrl } from '@helper/functions';
import { useMutation } from '@apollo/client';
import { RootStateOrAny, useSelector } from 'react-redux';
import { ADD_FLOW_BLOCK_MUTATION, UPDATE_BLOCK_MUTATION } from '@queries/Automation/mutation';
import DataObjectIcon from '@mui/icons-material/DataObject';
import TestResponse from './testResponse';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import BodyRequest from './bodyRequest';

interface HttpRequestBlocksProps {
   leftNodes: Node[];
   setIsSaveRequest: React.Dispatch<React.SetStateAction<boolean>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   isSaveRequest: boolean;
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   clickedNodeId: string;
}
const HttpRequestBlocks: React.FC<HttpRequestBlocksProps> = ({
   leftNodes,
   setIsBtnDisable,
   isLoading,
   setIsLoading,
   setIsSaveRequest,
   isSaveRequest,
   clickedNodeId,
   setContentNodes,
   setLeftNodes,
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const useStyles = makeStyles(() => ({
      container: {
         display: 'flex',
         flexDirection: 'column',
         gap: '10px',
      },
      btnGroup: {
         height: 36,
         width: '100%',
         border: '1px dashed gray',
         borderRadius: '10px',
         background: 'transparent',
         cursor: 'pointer',
         color: 'gray',
         marginTop: '10px',
      },
      blocks: {
         border: '1px solid #E7E7E7',
         display: 'grid',
         gridTemplateColumns: '10fr 1fr',
         padding: '10px',
         marginBottom: '10px',
         borderRadius: '10px',
      },
      handle: {
         backgroundColor: '#fff',
         marginTop: '5px',
      },
      textArea: {
         borderRadius: '5px',
         border: 'none',
         outline: 'none',
         resize: 'none',
         width: '100%',
         minHeight: '100px',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '0 10px',
         '&:focus': {
            border: 'none',
         },
      },

      groupHeader: {
         display: 'flex',
         justifyContent: 'space-between',
         padding: '10px',
         marginBottom: '10px',
         fontWeight: 'bold',
         borderBottom: '1px solid #E7E7E7',
         borderRadius: '5px',
      },
      groupContent: {
         display: 'flex',
         flexDirection: 'column',
         marginBottom: '10px',
         border: '1px solid #E7E7E7',
         borderRadius: '5px',
      },
      textInput: {
         marginTop: '16px',
      },
      title: {
         fontWeight: 600,
      },
      deleteIcon: {
         color: 'gray',
         cursor: 'pointer',
      },
      arrowResponse: {
         display: 'flex',
         cursor: 'pointer',
         color: 'gray',
      },
      dataObject: {
         position: 'absolute',
         right: 0,
         bottom: -5,
         fontSize: 16,
         backgroundColor: '#000',
         color: '#fff',
         borderRadius: 5,
         zIndex: 1,
      },
      dataObjectBody: {
         position: 'absolute',
         right: 0,
         bottom: -25,
         fontSize: 16,
         backgroundColor: '#000',
         color: '#fff',
         borderRadius: 5,
      },
   }));
   const classes = useStyles();

   const OPTIONS = [
      {
         value: HttpTestMethod.POST,
         label: 'POST',
      },
      {
         value: HttpTestMethod.GET,
         label: 'GET',
      },
      {
         value: HttpTestMethod.PUT,
         label: 'PUT',
      },
      {
         value: HttpTestMethod.DELETE,
         label: 'DELETE',
      },
   ];

   const [searchSelectKey, setSearchSelectKey] = useState<number>(0);
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);
   const [hoveredBlockId, setHoveredBlockId] = useState<number | null>();
   const [hoveredField, setHoveredField] = useState<string | null>();
   const [showFieldUrl, setShowFieldUrl] = useState<string | null>();
   const [showFieldIndexUrl, setShowFieldIndexUrl] = useState<number | null>();
   const [showFieldHeader, setShowFieldHeader] = useState<string | null>();
   const [showFieldIndexHeader, setShowFieldIndexHeader] = useState<number | null>();
   const [selectedField, setSelectedField] = useState<{
      blockId: string | null;
      field: string | null;
      index: number | null;
   }>();
   const [anchorElField, setAnchorElField] = useState<null | HTMLElement>(null);
   const [CUSTOMFIELDS, SETCUSTOMFIELDS] = useState<{ label: string; value: string }[]>([]);
   const [customFields, setCustomFields] = useState<{ name: string; _id: string }[]>([]);
   const [dataId, setDataId] = useState<{ prevData: string; newData: string }[]>([]);
   const [toUpdate, setToUpdate] = useState<{ _id: string }[]>([]);
   const CUSTOM_FIELDS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.customFields);

   const [mutationCount, setMutationCount] = useState(0);
   const [selectedBoyType, setSelectedBoyType] = useState<string>('requestedBody');
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);

   useEffect(() => {
      setCustomFields(CUSTOM_FIELDS);
      CUSTOM_FIELDS.map((item: { name: string; _id: string }) => {
         SETCUSTOMFIELDS((prev) => [...prev, { label: item.name, value: item._id }]);
      });
   }, [CUSTOM_FIELDS]);

   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlockFields = async (blockId: string, fields: IFlowBlockField[]) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields.map((field: any) => {
                     for (let [key, value] of Object.entries(field)) {
                        if (value == null) {
                           delete field[key];
                        }
                     }
                     return field;
                  }),
               },
            },
            onCompleted: (res) => {
               setMutationCount((prevCount) => prevCount - 1);
               setIsBtnDisable(true);

               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some(
                                 (block) => block.fields[0]?.requestUrl && block.fields[0]?.requestUrl?.length > 0,
                              )
                                 ? true
                                 : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };
   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlock = async (
      flowBlockTemplateId: String,
      nodeId: string,
      field: IFlowBlockField[],
      prevBlockId: string,
   ) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fields: field,
               },
            },
            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];
               setMutationCount((prevCount) => prevCount - 1);

               setDataId((prev) => [...prev, { prevData: prevBlockId, newData: response._id }]);
               setIsBtnDisable(true);

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some(
                                 (block) => block.fields[0]?.requestUrl && block.fields[0]?.requestUrl?.length > 0,
                              )
                                 ? true
                                 : false,
                              flowBlocks: [...node.data.flowBlocks, response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };
   function getNameById(id: string | undefined) {
      const matchingProperty = customFields.find((property) => property._id === id);
      return matchingProperty ? matchingProperty.name : null;
   }

   const listBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   useEffect(() => {
      setTimeout(() => {
         setSearchSelectKey((prev) => prev + 1);
      }, 1000);
   }, [blockState]);

   // default
   useEffect(() => {
      setBlockState([]);
      setIsBtnDisable(true);
      if (listBlocks.length > 0) {
         const data = listBlocks.map((item: IFlowBlockTemplate) => {
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });
         setBlockState(data);

         const bodyType = data[0].fields[0];

         if (bodyType?.requestBody !== null) {
            setSelectedBoyType('requestedBody');
         } else if (bodyType?.requestBodyFormDataUrlEncoded !== null) {
            setSelectedBoyType('requestBodyFormDataUrlEncoded');
         } else if (bodyType?.requestBodyFormData !== null) {
            setSelectedBoyType('requestBodyFormData');
         }
      } else {
         const fields = [
            {
               type: IFlowBlockFieldTypes.HttpRequest,
               requestHeaders: [],
               responseDotNotation: [],
               requestMethod: HttpTestMethod.GET,
            },
         ];
         const block = [
            {
               _id: generateRandomId(),
               title: '',
               fields: fields,
               type: FlowBlockType.HttpRequest,
            },
         ];
         setBlockState(block);
      }
   }, [clickedNodeId]);

   const customStyles = {
      control: (base: any) => ({
         ...base,
         height: 35,
      }),
   };
   const height = 12;

   const handleMethodeChange = (id: string, value: HttpTestMethod) => {
      setIsBtnDisable(false);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            const body =
               value === HttpTestMethod.GET || value === HttpTestMethod.DELETE ? undefined : item.fields[0].requestBody;

            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     requestMethod: value,
                     requestBody: body,
                  })),
               };
            }
            return item;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };
   const handleUrlChange = (id: string, value: string) => {
      setIsBtnDisable(false);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     requestUrl: value,
                  })),
               };
            }
            return item;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };

   const handleAddHeader = (id: string) => {
      handelFocusHeader(null, null);
      const newHeader = {
         key: '',
         value: '',
      };

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               const updatedFields = item.fields.map((field) => {
                  if (field.requestHeaders) {
                     return {
                        ...field,
                        requestHeaders: [...field.requestHeaders, newHeader],
                     };
                  } else {
                     return {
                        ...field,
                        requestHeaders: [newHeader],
                     };
                  }
               });
               return {
                  ...item,
                  fields: updatedFields,
               };
            }
            return item;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };

   const handleAddResponseMapping = (id: string) => {
      const newResponse = {
         fieldId: '',
         value: '',
      };

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               const updatedFields = item.fields.map((field) => {
                  if (field.responseDotNotation) {
                     return {
                        ...field,
                        responseDotNotation: [...field.responseDotNotation, newResponse],
                     };
                  } else {
                     return {
                        ...field,
                        responseDotNotation: [newResponse],
                     };
                  }
               });
               return {
                  ...item,
                  fields: updatedFields,
               };
            }
            return item;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };

   const handleUpdateHeaderKey = (id: string, key: string, headerIndex: number) => {
      setIsBtnDisable(false);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id && item.fields) {
               const updatedFields = item.fields.map((field) => {
                  if (field.requestHeaders) {
                     if (headerIndex >= 0 && headerIndex < field.requestHeaders.length) {
                        const updatedHeaders = [...field.requestHeaders];
                        updatedHeaders[headerIndex] = {
                           ...field.requestHeaders[headerIndex],
                           key: key,
                        };
                        return {
                           ...field,
                           requestHeaders: updatedHeaders,
                        };
                     }
                  }
                  return field;
               });
               return {
                  ...item,
                  fields: updatedFields,
               };
            }
            return item;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };
   const handleUpdateHeaderValue = (id: string, value: string, headerIndex: number) => {
      setIsBtnDisable(false);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id && item.fields) {
               const updatedFields = item.fields.map((field) => {
                  if (field.requestHeaders) {
                     if (headerIndex >= 0 && headerIndex < field.requestHeaders.length) {
                        const updatedHeaders = [...field.requestHeaders];
                        updatedHeaders[headerIndex] = {
                           ...field.requestHeaders[headerIndex],
                           value: value,
                        };
                        return {
                           ...field,
                           requestHeaders: updatedHeaders,
                        };
                     }
                  }
                  return field;
               });
               return {
                  ...item,
                  fields: updatedFields,
               };
            }
            return item;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };

   const handleUpdateResponseMapValue = (id: string, value: string, mapIndex: number) => {
      setIsBtnDisable(false);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id && item.fields) {
               const updatedFields = item.fields.map((field) => {
                  if (field.responseDotNotation) {
                     if (mapIndex >= 0 && mapIndex < field.responseDotNotation.length) {
                        const updatedResponse = [...field.responseDotNotation];
                        updatedResponse[mapIndex] = {
                           ...field.responseDotNotation[mapIndex],
                           value: value,
                        };
                        return {
                           ...field,
                           responseDotNotation: updatedResponse,
                        };
                     }
                  }
                  return field;
               });
               return {
                  ...item,
                  fields: updatedFields,
               };
            }
            return item;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };
   const handleUpdateResponseMapField = (id: string, fieldId: string | null, mapIndex: number) => {
      setIsBtnDisable(false);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (fieldId && item._id === id && item.fields) {
               const updatedFields = item.fields.map((field) => {
                  if (field.responseDotNotation) {
                     if (mapIndex >= 0 && mapIndex < field.responseDotNotation.length) {
                        const updatedResponse = [...field.responseDotNotation];
                        updatedResponse[mapIndex] = {
                           ...field.responseDotNotation[mapIndex],
                           fieldId: fieldId,
                        };
                        return {
                           ...field,
                           responseDotNotation: updatedResponse,
                        };
                     }
                  }
                  return field;
               });
               return {
                  ...item,
                  fields: updatedFields,
               };
            }
            return item;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };

   const handleDeleteResponseMapping = (id: string, responseIndexToDelete: number) => {
      setIsBtnDisable(false);
      setSearchSelectKey((prev) => prev + 1);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((block) => {
            if (block._id === id && block.fields) {
               const updatedFields = block.fields.map((field) => {
                  if (field.responseDotNotation) {
                     if (responseIndexToDelete >= 0 && responseIndexToDelete < field.responseDotNotation.length) {
                        const updatedResponses = field.responseDotNotation.filter(
                           (response, index) => index !== responseIndexToDelete,
                        );

                        return {
                           ...field,
                           responseDotNotation: updatedResponses,
                        };
                     }
                  }
                  return field;
               });
               return {
                  ...block,
                  fields: updatedFields,
               };
            }

            return block;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };

   const handleDeleteHeader = (id: string, headerIndexToDelete: number) => {
      setIsBtnDisable(false);
      handelFocusHeader(null, null);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((block) => {
            if (block._id === id && block.fields) {
               const updatedFields = block.fields.map((field) => {
                  if (field.requestHeaders) {
                     if (headerIndexToDelete >= 0 && headerIndexToDelete < field.requestHeaders.length) {
                        const updatedHeaders = field.requestHeaders.filter(
                           (header, index) => index !== headerIndexToDelete,
                        );

                        return {
                           ...field,
                           requestHeaders: updatedHeaders,
                        };
                     }
                  }
                  return field;
               });
               return {
                  ...block,
                  fields: updatedFields,
               };
            }
            return block;
         });
         setToUpdate([{ _id: id }]);
         return updatedBlockState;
      });
   };

   const handelHover = (index: number | null, field: string | null) => {
      setHoveredBlockId(index);
      setHoveredField(field);
   };
   const handelFocusUrl = (index: number | null, field: string | null) => {
      setShowFieldUrl(field);
      setShowFieldIndexUrl(index);
   };
   const handelFocusHeader = (index: number | null, field: string | null) => {
      setShowFieldHeader(field);
      setShowFieldIndexHeader(index);
   };

   const backendBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];

   // Save
   useEffect(() => {
      if (isSaveRequest && !isLoading) {
         setIsSaveRequest(false);

         const existingBlocks = blockState.filter((block) =>
            backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         const newBlocks = blockState.filter(
            (block) => !listBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         // Add all new blocks to the backend
         const save = async () => {
            // Update fields
            if (toUpdate.length > 0) {
               toUpdate.forEach((item) => {
                  const existingBlock = existingBlocks.find((block) => block._id === item._id);
                  if (existingBlock) {
                     setIsLoading(true);
                     handleUpdateFlowBlockFields(item._id, existingBlock.fields);
                  }
               });
               setToUpdate([]);
            }

            if (newBlocks.length > 0) {
               setIsLoading(true);
               (async () => {
                  if (LIST_BLOCK_TEMPLATE) {
                     const templateBlockId = LIST_BLOCK_TEMPLATE.find(
                        (item: { type: FlowBlockType }) => item.type === FlowBlockType.HttpRequest,
                     )?._id;
                     for await (let item of newBlocks) {
                        if (templateBlockId) {
                           const fields = item.fields.map((exfield) => ({
                              type: exfield.type,
                              requestHeaders: exfield.requestHeaders,
                              requestUrl: exfield.requestUrl,
                              responseDotNotation: exfield.responseDotNotation,
                              requestMethod: exfield.requestMethod,
                           }));
                           await handleAddFlowBlock(templateBlockId, clickedNodeId, fields, item._id);
                        }
                     }
                  }
               })();
            }
         };
         save();
         setToUpdate([]);
      }
   }, [isSaveRequest]);

   // update the local state with the Id coming from the response mutation
   useEffect(() => {
      if (dataId.length > 0) {
         let updatedBlockState = [...blockState];
         dataId.forEach((element) => {
            updatedBlockState = updatedBlockState.map((block) => {
               if (element.prevData === block._id) {
                  return {
                     ...block,
                     _id: element.newData,
                  };
               } else {
                  return block;
               }
            });
         });

         setBlockState(updatedBlockState);
      }
   }, [leftNodes]);

   useEffect(() => {
      if (mutationCount > 0) {
         setIsLoading(true);
      } else {
         setIsLoading(false);
      }
   }, [mutationCount]);

   const handleMenuFieldClose = () => {
      setAnchorElField(null);
   };

   const divRef = useRef(null);
   const divRefHeader = useRef(null);

   const handleMenuFieldOpen = (divRf: any, blockId: string, field: string, index: number) => {
      setAnchorElField(divRf.current);
      setSelectedField({ blockId: blockId, field: field, index: index });
   };

   const addField = (
      name: string,
      selectedField: { blockId: string | null; field: string | null; index: number | null } | undefined,
   ) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (selectedField && item._id === selectedField.blockId) {
               const updatedFields = item.fields.map((field) => {
                  if (field.type === IFlowBlockFieldTypes.HttpRequest) {
                     if (selectedField.field === 'url') {
                        const textAreaValue = field.requestUrl || '';
                        const textAreaElement = document.getElementById(
                           `textarea-${selectedField.field}-${selectedField.index}`,
                        ) as HTMLTextAreaElement | null;
                        if (textAreaElement) {
                           const start = textAreaElement.selectionStart;
                           const end = textAreaElement.selectionEnd;
                           const updatedValue = textAreaValue.substring(0, start) + name + textAreaValue.substring(end);
                           if (selectedField.blockId) {
                              setToUpdate([{ _id: selectedField.blockId }]);
                           }
                           return { ...field, requestUrl: updatedValue };
                        }
                     }

                     if (
                        selectedField.blockId &&
                        field.requestHeaders &&
                        selectedField.index !== null &&
                        selectedField.field === 'header'
                     ) {
                        const textAreaValue = field.requestHeaders[selectedField.index].value || '';
                        const textAreaElement = document.getElementById(
                           `textarea-${selectedField.field}-${selectedField.index}`,
                        ) as HTMLTextAreaElement | null;

                        if (textAreaElement) {
                           const start = textAreaElement.selectionStart;
                           const end = textAreaElement.selectionEnd;
                           const updatedValue = textAreaValue.substring(0, start) + name + textAreaValue.substring(end);

                           handleUpdateHeaderValue(selectedField.blockId, updatedValue, selectedField.index);
                        }
                     }
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });

         setIsBtnDisable(false);
         return updatedBlockState;
      });

      handleMenuFieldClose();
   };

   // rules validation to enable save button or not
   useEffect(() => {
      if (blockState) {
         const isInvalidTextarea = blockState.some((block) =>
            block.fields.some(
               (field) =>
                  field.requestUrl === '' ||
                  field.requestUrl === null ||
                  ((field.requestMethod === HttpTestMethod.POST || field.requestMethod === HttpTestMethod.PUT) &&
                     (((field.requestBody === null || field.requestBody === '') &&
                        selectedBoyType === 'requestedBody') ||
                        (selectedBoyType === 'requestBodyFormData' &&
                           field.requestBodyFormData &&
                           field.requestBodyFormData.some((header) => header.key === '' || header.value === '')) ||
                        (selectedBoyType === 'requestBodyFormDataUrlEncoded' &&
                           field.requestBodyFormDataUrlEncoded &&
                           field.requestBodyFormDataUrlEncoded.some(
                              (header) => header.key === '' || header.value === '',
                           )))),
            ),
         );

         const isInvalidSelect = blockState.some((block) =>
            block.fields.some(
               (field) =>
                  (field.requestHeaders &&
                     field.requestHeaders.some((header) => header.key === '' || header.value === '')) ||
                  (field.responseDotNotation &&
                     field.responseDotNotation.some((response) => response.fieldId === '' || response.value === '')),
            ),
         );
         const isInvalid =
            isInvalidTextarea || isInvalidSelect || !isValidHttpUrl(blockState[0]?.fields[0]?.requestUrl);
         if (isInvalid) {
            setIsBtnDisable(isInvalid);
         }
      }
   }, [blockState]);

   return (
      <Grid>
         {blockState.map((item) => (
            <div key={item._id}>
               {item.fields.map((field, index) => (
                  <div className={classes.container} key={index}>
                     <div onClick={() => handelFocusHeader(null, null)}>
                        <Typography className={classes.title}> {t.automation_http_request_type}</Typography>
                        <SearchSelect
                           options={OPTIONS}
                           menuPosition='fixed'
                           placeholder={<div>{t.atomation_flow_select}</div>}
                           styles={customStyles}
                           defaultValue={
                              field.requestMethod
                                 ? {
                                      label: field.requestMethod.toString(),
                                      value: field.requestMethod,
                                   }
                                 : null
                           }
                           onChange={(e) => {
                              if (e) {
                                 handleMethodeChange(item._id, e.value);
                              }
                           }}
                        />
                     </div>
                     <div style={{ position: 'relative' }} onClick={() => handelFocusHeader(null, null)}>
                        <Typography style={{ marginBottom: '-15px' }} className={classes.title}>
                           {t.automation_http_request_url}
                        </Typography>
                        <ClickAwayListener onClickAway={() => handelFocusUrl(null, null)}>
                           <div>
                              <TextField
                                 id={`textarea-${'url'}-${0}`}
                                 name='url'
                                 error={!isValidHttpUrl(blockState[0].fields[0].requestUrl)}
                                 placeholder={t.automation_http_request_url}
                                 variant='outlined'
                                 inputProps={{
                                    style: {
                                       height,
                                    },
                                 }}
                                 value={field.requestUrl}
                                 style={{ width: '100%' }}
                                 onChange={(e) => handleUrlChange(item._id, e.target.value)}
                                 onFocus={() => handelFocusUrl(0, 'url')}
                              />
                              {showFieldUrl === 'url' && showFieldIndexUrl === 0 && (
                                 <div ref={divRef}>
                                    <DataObjectIcon
                                       className={classes.dataObject}
                                       onClick={() => handleMenuFieldOpen(divRef, item._id, 'url', 0)}
                                    />
                                 </div>
                              )}
                           </div>
                        </ClickAwayListener>
                     </div>
                     <div style={{ marginTop: '-15px' }}>
                        <Typography className={classes.title}> {t.automation_http_request_headers}</Typography>
                        {field.requestHeaders?.map((header, headerIndex) => (
                           <div
                              style={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}
                              onMouseEnter={() => handelHover(headerIndex, 'header')}
                              onMouseLeave={() => handelHover(null, null)}
                              key={headerIndex}
                           >
                              <Box
                                 style={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    width: '100%',
                                    marginTop: '5px',
                                    marginBottom: '-15px',
                                 }}
                              >
                                 <div style={{ width: '50%' }} onClick={() => handelFocusHeader(null, null)}>
                                    <Typography style={{ marginBottom: '-15px' }}>
                                       {t.automation_http_request_key}
                                    </Typography>
                                    <TextField
                                       name='key'
                                       placeholder={t.automation_http_request_key}
                                       value={header.key}
                                       variant='outlined'
                                       inputProps={{
                                          style: {
                                             height,
                                          },
                                       }}
                                       onChange={(e) => handleUpdateHeaderKey(item._id, e.target.value, headerIndex)}
                                    />
                                 </div>
                                 <div style={{ width: '50%', position: 'relative' }}>
                                    <Typography style={{ marginBottom: '-15px' }}>{t.page_settings_value}</Typography>

                                    <div>
                                       <TextField
                                          id={`textarea-${'header'}-${headerIndex}`}
                                          name='value'
                                          placeholder={t.page_settings_value}
                                          value={header.value}
                                          variant='outlined'
                                          inputProps={{
                                             style: {
                                                height,
                                             },
                                          }}
                                          onFocus={() => handelFocusHeader(headerIndex, 'header')}
                                          onChange={(e) =>
                                             handleUpdateHeaderValue(item._id, e.target.value, headerIndex)
                                          }
                                       />
                                       {showFieldHeader === 'header' && showFieldIndexHeader === headerIndex && (
                                          <div ref={divRefHeader}>
                                             <DataObjectIcon
                                                className={classes.dataObject}
                                                onClick={() =>
                                                   handleMenuFieldOpen(divRefHeader, item._id, 'header', headerIndex)
                                                }
                                             />
                                          </div>
                                       )}
                                    </div>
                                 </div>
                              </Box>
                              {hoveredBlockId === headerIndex && hoveredField === 'header' && (
                                 <DeleteIcon
                                    onClick={() => handleDeleteHeader(item._id, headerIndex)}
                                    className={classes.deleteIcon}
                                    style={{ position: 'absolute', right: -22, bottom: 10 }}
                                 />
                              )}
                           </div>
                        ))}
                        {field.requestHeaders && field.requestHeaders.length < 10 && (
                           <button className={classes.btnGroup} onClick={() => handleAddHeader(item._id)}>
                              {t.comp_button_add}
                           </button>
                        )}
                     </div>
                     {(field.requestMethod === HttpTestMethod.POST || field.requestMethod === HttpTestMethod.PUT) && (
                        <BodyRequest
                           selectedBoyType={selectedBoyType}
                           blockState={blockState}
                           setBlockState={setBlockState}
                           handelFocusHeader={handelFocusHeader}
                           setToUpdate={setToUpdate}
                           setIsBtnDisable={setIsBtnDisable}
                           setSelectedField={setSelectedField}
                           handelHover={handelHover}
                           setSelectedBoyType={setSelectedBoyType}
                           showFieldHeader={showFieldHeader}
                           showFieldIndexHeader={showFieldIndexHeader}
                           hoveredField={hoveredField}
                           hoveredBlockId={hoveredBlockId}
                           customFields={customFields}
                           selectedField={selectedField}
                        />
                     )}
                     <TestResponse blockState={blockState} selectedBoyType={selectedBoyType} />
                     <div onClick={() => handelFocusHeader(null, null)}>
                        <Typography className={classes.title}> {t.automation_http_request_response_mapping}</Typography>
                        {field.responseDotNotation?.map((response, mapIndex) => (
                           <div
                              style={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}
                              onMouseEnter={() => handelHover(mapIndex, 'responseMap')}
                              onMouseLeave={() => handelHover(null, null)}
                              key={mapIndex}
                           >
                              <Box
                                 style={{
                                    display: 'flex',
                                    gap: '0.5rem',
                                    width: '100%',
                                    marginTop: '5px',
                                    marginBottom: '-15px',
                                 }}
                              >
                                 <div style={{ width: '50%' }}>
                                    <Typography style={{ marginBottom: '-15px' }}>
                                       {t.automation_http_request_json_path}
                                    </Typography>
                                    <TextField
                                       name='JSONPath'
                                       placeholder={t.automation_http_request_json_path}
                                       value={response.value}
                                       variant='outlined'
                                       inputProps={{
                                          style: {
                                             height,
                                          },
                                       }}
                                       onChange={(event) =>
                                          handleUpdateResponseMapValue(item._id, event.target.value, mapIndex)
                                       }
                                    />
                                 </div>
                                 <div style={{ width: '50%' }}>
                                    <Typography style={{ marginBottom: '-15px' }}>
                                       {' '}
                                       {t.page_settings_typo_custom_field}
                                    </Typography>
                                    <SearchSelect
                                       key={searchSelectKey}
                                       options={CUSTOMFIELDS}
                                       className={classes.textInput}
                                       menuPosition='fixed'
                                       placeholder={<div>{t.atomation_flow_select}</div>}
                                       styles={customStyles}
                                       onChange={(e) => {
                                          if (e) {
                                             handleUpdateResponseMapField(item._id, e.value, mapIndex);
                                          }
                                       }}
                                       defaultValue={
                                          response.fieldId
                                             ? {
                                                  value: response.fieldId,
                                                  label: getNameById(response.fieldId),
                                               }
                                             : null
                                       }
                                    />
                                 </div>
                              </Box>
                              {hoveredBlockId === mapIndex && hoveredField === 'responseMap' && (
                                 <DeleteIcon
                                    onClick={() => handleDeleteResponseMapping(item._id, mapIndex)}
                                    className={classes.deleteIcon}
                                    style={{ position: 'absolute', right: -22, bottom: 10 }}
                                 />
                              )}
                           </div>
                        ))}
                        {field.responseDotNotation && field.responseDotNotation?.length < 10 && (
                           <button className={classes.btnGroup} onClick={() => handleAddResponseMapping(item._id)}>
                              {t.comp_button_add}
                           </button>
                        )}
                     </div>
                     <div onClick={() => handelFocusHeader(null, null)}>
                        <div className={classes.blocks}>
                           <div>
                              <Typography>{t.automation_http_request_response_successful}</Typography>
                              <Typography style={{ fontSize: '0.8rem' }}>
                                 {t.automation_http_request_status_is_200}
                              </Typography>
                           </div>
                           <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <CircleOutlinedIcon
                                 className={classes.handle}
                                 style={{
                                    color: '#147CFC',
                                    width: '20px',
                                    height: '20px',
                                 }}
                              />
                           </div>
                        </div>
                        <div className={classes.blocks}>
                           <div>
                              <Typography>{t.automation_http_request_response_faild}</Typography>
                              <Typography style={{ fontSize: '0.8rem' }}>
                                 {t.automation_http_request_status_not_200}
                              </Typography>
                           </div>
                           <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <CircleOutlinedIcon
                                 className={classes.handle}
                                 style={{
                                    color: '#FF5A80',
                                    width: '20px',
                                    height: '20px',
                                 }}
                              />
                           </div>
                        </div>
                     </div>
                     <Menu
                        anchorEl={anchorElField}
                        open={Boolean(anchorElField)}
                        onClose={handleMenuFieldClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        style={{ marginTop: 5 }}
                        PaperProps={{
                           style: {
                              width: '363px',
                              borderRadius: '5px',
                              maxHeight: '250px',
                              marginLeft: selectedField?.field === 'header' ? '-185px' : 0,
                              marginTop: selectedField?.field === 'body' ? '40px' : '20px',
                           },
                        }}
                     >
                        {selectedField?.field === 'body' && (
                           <div>
                              <MenuItem onClick={() => addField(t.atomation_flow_first_name_value, selectedField)}>
                                 {t.atomation_flow_first_name}
                              </MenuItem>
                              <MenuItem onClick={() => addField(t.atomation_flow_last_name_value, selectedField)}>
                                 {t.atomation_flow_last_name}
                              </MenuItem>
                              <MenuItem onClick={() => addField(t.atomation_full_name_value, selectedField)}>
                                 {t.atomation_full_name}
                              </MenuItem>
                              <MenuItem onClick={() => addField(t.atomation_flow_email_value, selectedField)}>
                                 {t.atomation_flow_email}
                              </MenuItem>
                              <MenuItem onClick={() => addField(t.atomation_flow_phone_value, selectedField)}>
                                 {t.atomation_flow_phone}
                              </MenuItem>
                           </div>
                        )}

                        {customFields.map((custom) => (
                           <MenuItem onClick={() => addField('{{' + custom.name + '}}', selectedField)}>
                              {custom.name}
                           </MenuItem>
                        ))}
                     </Menu>
                  </div>
               ))}
            </div>
         ))}
      </Grid>
   );
};

export default HttpRequestBlocks;

import { Grid, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';
import { Node } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import SearchSelect from 'react-select';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import {
   FlowBlockType,
   FlowInstanceMessageChannel,
   IFlowBlockField,
   IFlowBlockFieldRules,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { generateRandomId } from '@helper/functions';
import { useMutation } from '@apollo/client';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
   ADD_FLOW_BLOCK_MUTATION,
   REMOVE_BLOCKS_FROM_NODE_MUTATION,
   UPDATE_BLOCK_MUTATION,
} from '@queries/Automation/mutation';
import { SET_LANGUAGES } from '@store/actions/automation';
import { useDispatch } from 'react-redux';
import { LANGUAGES_EN_TR } from '@helper/language';

interface ConditionBlocksProps {
   leftNodes: Node[];
   setIsSaveCondition: React.Dispatch<React.SetStateAction<boolean>>;
   isSaveCondition: boolean;
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   clickedNodeId: string;
}
const ConditionBlocks: React.FC<ConditionBlocksProps> = ({
   leftNodes,
   setIsBtnDisable,
   isLoading,
   setIsLoading,
   setContentNodes,
   setIsSaveCondition,
   isSaveCondition,
   clickedNodeId,
   setLeftNodes,
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const useStyles = makeStyles((theme) => ({
      btn: {
         height: 36,
         width: '100%',
         border: '1px dashed #BEF1DB',
         borderRadius: '10px',
         background: 'transparent',
         cursor: 'pointer',
         color: '#1DC37C',
      },
      btnGroup: {
         height: 36,
         width: '100%',
         border: '1px dashed #157AFA',
         borderRadius: '10px',
         background: 'transparent',
         cursor: 'pointer',
         color: '#157AFA',
      },
      row: {
         display: 'flex',
         justifyContent: 'space-between',
         gap: '5px',
         width: '100%',
      },
      groupHeader: {
         display: 'flex',
         justifyContent: 'space-between',
         padding: '10px',
         marginBottom: '10px',
         fontWeight: 'bold',
         borderBottom: '1px solid #E7E7E7',
         borderRadius: '5px',
      },
      groupContent: {
         display: 'flex',
         flexDirection: 'column',
         marginBottom: '10px',
         border: '1px solid #E7E7E7',
         borderRadius: '5px',
      },
      ruleSelect: {
         marginTop: '16px',
         height: '50px',
         width: '45%',
      },
      ruleSelect100: {
         marginTop: '16px',
         height: '50px',
         width: '100%',
      },
      ruleSelectValue: {
         marginTop: '16px',
         height: '50px',
         width: '55%',
      },
      deleteIcon: {
         color: 'gray',
         cursor: 'pointer',
      },
      line: {
         width: '100%',
      },
   }));
   const classes = useStyles();
   const OPTIONS = [
      {
         value: IFlowBlockFieldRules.Equal,
         label: t.atomation_flow_trigger_block_equal,
      },
      {
         value: IFlowBlockFieldRules.Contains,
         label: t.atomation_flow_trigger_block_contains,
      },
      {
         value: IFlowBlockFieldRules.NotEqual,
         label: t.atomation_flow_trigger_block_not_equal,
      },
      {
         value: IFlowBlockFieldRules.NotContains,
         label: t.atomation_flow_trigger_block_not_contains,
      },
      {
         value: IFlowBlockFieldRules.Unknown,
         label: t.atomation_flow_trigger_block_unknown,
      },
      {
         value: IFlowBlockFieldRules.HasAnyValue,
         label: t.atomation_flow_trigger_block_has_any_value,
      },
      {
         value: IFlowBlockFieldRules.EndWith,
         label: t.atomation_flow_trigger_block_end_with,
      },
      {
         value: IFlowBlockFieldRules.StartWith,
         label: t.atomation_flow_trigger_block_start_with,
      },
   ];
   const OPTIONS_WORKING = [
      {
         value: 'in_of_working_hours',
         label: t.atomation_flow_in_working_hours,
      },
      {
         value: 'not_in_working_hours',
         label: t.atomation_flow_not_in_working_hours,
      },
   ];

   const [OPTIONS_LANGUAGE, SETOPTIONS_LANGUAGE] = useState<{ value: string; label: string }[]>([]);
   const [LOCATION, SETLOCATION] = useState<{ value: string; label: string }[]>([]);
   const dispatch = useDispatch();
   const country = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.locations);
   const CHANNELS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.channels);
   const backendBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);

   useEffect(() => {
      SETLOCATION(country);
   }, [country]);

   const OPTIONS_CONSENT = [
      {
         value: 'true',
         label: t.page_contacts_typo_subscribed,
      },
      {
         value: 'false',
         label: t.page_contacts_typo_unsubscribed,
      },
   ];

   useEffect(() => {
      let langs: any = [];
      LANGUAGES_EN_TR.map((item) => {
         langs.push({
            value: item.value,
            label: t.atomation_flow_language === 'tr' ? item.turkish : item.english,
         });
      });
      dispatch({ type: SET_LANGUAGES, payload: langs });
      SETOPTIONS_LANGUAGE(langs);
   }, []);

   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [showUD, setShowUD] = useState(false);
   const [isAddBlock, setIsAddBlock] = useState(false);
   const [isAddField, setIsAddField] = useState(false);
   const [selectedBlockId, setSelectedBlockId] = useState<string | null>();
   const [refrech, setRefrech] = useState<string | null>();
   const [hoveredBlockId, setHoveredBlockId] = useState<string | null>();
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);
   const [deletedBlockId, setDeletedBlockId] = useState<string[]>([]);
   const [dataId, setDataId] = useState<{ prevData: string; newData: string }[]>([]);
   const [toUpdate, setToUpdate] = useState<{ _id: string }[]>([]);
   const [searchSelectKey, setSearchSelectKey] = useState<number>(0);
   const [searchSelectKeyRule, setSearchSelectKeyRule] = useState<string>('');
   const [textFieldKey, setTextFieldKey] = useState<string>('');
   const [mutationCount, setMutationCount] = useState(0);
   const [customFields, setCustomFields] = useState<
      { name: string; type: string; listValues: string[]; label: string }[]
   >([]);
   const [conditionMenu, setConditionMenu] = useState<{ label: string; value: string }[]>([
      {
         label: t.atomation_flow_first_name,
         value: 'name',
      },
      {
         label: t.atomation_flow_last_name,
         value: 'surname',
      },
      {
         label: t.atomation_flow_email,
         value: 'email',
      },
      {
         label: t.atomation_flow_phone,
         value: 'phone',
      },
      {
         label: t.atomation_flow_condition_channel,
         value: 'channel',
      },
      {
         label: t.atomation_flow_condition_email_consent,
         value: 'email_consent',
      },
      {
         label: t.atomation_flow_condition_phone_consent,
         value: 'phone_consent',
      },
      {
         label: t.atomation_flow_condition_working_hours,
         value: 'working_hours',
      },
      {
         label: t.atomation_flow_condition_message_content,
         value: 'message_content',
      },
      {
         label: t.atomation_flow_condition_browser_country,
         value: 'browser_country',
      },
      {
         label: t.atomation_flow_condition_browser_language,
         value: 'browser_language',
      },
      {
         label: t.atomation_flow_condition__page_url,
         value: 'page_url',
      },
   ]);

   const CUSTOM_FIELDS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.customFields);

   useEffect(() => {
      setCustomFields(CUSTOM_FIELDS);
      CUSTOM_FIELDS.map((item: { name: string; label: string }) => {
         setConditionMenu((prev) => [...prev, { label: item.name, value: item.label }]);
      });
   }, [CUSTOM_FIELDS]);

   function getTypeByName(name: string | undefined) {
      const matchingProperty = customFields.find((property) => property.name === name);
      return matchingProperty ? matchingProperty.type : null;
   }

   function getListByName(name: string | undefined) {
      const matchingProperty = customFields.find((property) => property.name === name);
      return matchingProperty ? matchingProperty.listValues : null;
   }
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   const handleMenuOpenBlock = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      setShowUD(!showUD);
      setIsAddBlock(true);
      setIsAddField(false);
   };

   const handleMenuOpenField = (event: React.MouseEvent<HTMLElement>, blockId: string) => {
      setAnchorEl(event.currentTarget);
      setShowUD(!showUD);
      setIsAddField(true);
      setIsAddBlock(false);
      setSelectedBlockId(blockId);
   };
   const handleMenuClose = () => {
      setAnchorEl(null);
      setShowUD(false);
      setIsAddField(false);
      setIsAddBlock(false);
   };
   const handleSelectCondition = (conditionField: string) => {
      if (isAddBlock) {
         setIsBtnDisable(true);
         const block = {
            _id: generateRandomId(),
            title: '',
            fields: [
               {
                  type: IFlowBlockFieldTypes.Text,
                  condition_field: conditionField,
                  rule: IFlowBlockFieldRules.Contains,
                  value: '',
               },
            ],
            type: FlowBlockType.Condition,
         };

         setBlockState((prev) => [...prev, block]);
      }
      if (isAddField) {
         setIsBtnDisable(true);
         const updatedBlocks = blockState.map((block) => {
            if (block._id === selectedBlockId) {
               const newField = {
                  type: IFlowBlockFieldTypes.Text,
                  condition_field: conditionField,
                  rule: IFlowBlockFieldRules.Contains,
                  value: '',
               };

               return {
                  ...block,
                  fields: [...block.fields, newField],
               };
            }

            return block;
         });

         setBlockState(updatedBlocks);
      }

      handleMenuClose();
   };

   const handleDeleteBlock = (idToDelete: string) => {
      const existingBlocks = backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === idToDelete);
      if (existingBlocks) {
         setIsBtnDisable(false);
      }

      setBlockState((prevBlock) => {
         setDeletedBlockId((prevBlock) => [...prevBlock, idToDelete]);
         return prevBlock.filter((item) => item._id !== idToDelete);
      });
   };
   const handleDeleteField = (blockId: string, indexToDelete: number) => {
      setRefrech(generateRandomId());
      setIsBtnDisable(false);

      let updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            if (indexToDelete >= 0 && indexToDelete < block.fields.length) {
               const updatedFields = block.fields.filter((field, index) => index !== indexToDelete);

               // Check if the deleted field was the last one in the block
               const isLastField = updatedFields.length === 0;

               if (isLastField) {
                  setDeletedBlockId((prevDeletedBlock) => [...prevDeletedBlock, block._id]);
               }

               return {
                  ...block,
                  fields: updatedFields,
               };
            }
         }

         return block;
      });

      // Remove blocks where fields were deleted (including the last one)
      updatedBlocks = updatedBlocks.filter((block) => block.fields.length > 0);

      setToUpdate((prev) => {
         // Check if the _id already exists in the array
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });

      setBlockState(updatedBlocks);
   };

   const handleSelectValueChange = (blockId: string, index: number, value: string) => {
      setIsBtnDisable(false);

      const updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            if (index >= 0 && index < block.fields.length) {
               const updatedFields = block.fields.map((field, i) => {
                  if (i === index) {
                     return {
                        ...field,
                        value: value,
                     };
                  }
                  return field;
               });

               return {
                  ...block,
                  fields: updatedFields,
               };
            }
         }

         return block;
      });

      setToUpdate((prev) => {
         // Check if the _id already exists in the array
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });

      setBlockState(updatedBlocks);
   };

   const handleSelectRuleChange = (blockId: string, index: number, rule: IFlowBlockFieldRules) => {
      setIsBtnDisable(false);
      const updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            if (index >= 0 && index < block.fields.length) {
               const updatedFields = block.fields.map((field, i) => {
                  if (i === index) {
                     if (rule === IFlowBlockFieldRules.HasAnyValue || rule === IFlowBlockFieldRules.Unknown) {
                        return {
                           ...field,
                           rule: rule,
                           value: '',
                        };
                     } else {
                        return {
                           ...field,
                           rule: rule,
                        };
                     }
                  }
                  return field;
               });
               return {
                  ...block,
                  fields: updatedFields,
               };
            }
         }

         return block;
      });

      setToUpdate((prev) => {
         // Check if the _id already exists in the array
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });

      setBlockState(updatedBlocks);
   };
   const handleTextValueChange = (blockId: string, index: number, text: string, fieldCondition: string | undefined) => {
      if (text.length > 0) {
         setIsBtnDisable(false);
      } else {
         setIsBtnDisable(true);
      }

      const updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            if (index >= 0 && index < block.fields.length) {
               const updatedFields = block.fields.map((field, i) => {
                  if (i === index) {
                     return {
                        ...field,
                        value: text,
                     };
                  }
                  return field;
               });

               return {
                  ...block,
                  fields: updatedFields,
               };
            }
         }

         return block;
      });
      setToUpdate((prev) => {
         // Check if the _id already exists in the array
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });
      setBlockState(updatedBlocks);
   };
   //enable button
   useEffect(() => {
      const isInvalid = blockState.some((block) =>
         block.fields.some(
            (field) =>
               field.value === '' &&
               !(field.rule === IFlowBlockFieldRules.HasAnyValue || field.rule === IFlowBlockFieldRules.Unknown),
         ),
      );
      if (isInvalid) {
         setIsBtnDisable(true);
      }
   }, [blockState]);

   const customStyles = {
      control: (base: any) => ({
         ...base,
         height: 35,
      }),
   };

   // Add block mutation
   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlock = async (
      flowBlockTemplateId: String,
      nodeId: string,
      field: IFlowBlockField[],
      prevBlockId: string,
   ) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fields: field,
               },
            },
            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];
               setMutationCount((prevCount) => prevCount - 1);

               setDataId((prev) => [...prev, { prevData: prevBlockId, newData: response._id }]);
               setIsBtnDisable(true);

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              isValidNode: blockState.length > 1 ? true : false,
                              flowBlocks: [...node.data.flowBlocks, response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   // Delete block mutation
   const [deleteBlocks] = useMutation(REMOVE_BLOCKS_FROM_NODE_MUTATION);
   const handleDeleteFlowBlock = async (blockId: String[], nodeId: string) => {
      try {
         setMutationCount((prevCount) => prevCount + 1);
         await deleteBlocks({
            variables: {
               input: {
                  blocks: blockId,
                  node: nodeId,
               },
            },
            onCompleted: () => {
               setMutationCount((prevCount) => prevCount - 1);
               setIsBtnDisable(true);

               setContentNodes((prevNodes) =>
                  prevNodes.map((node) =>
                     node.id === clickedNodeId
                        ? {
                             ...node,
                             data: {
                                ...node.data,
                                flowBlocks: node.data.flowBlocks.filter(
                                   (flowBlock: IFlowBlockTemplate) => !blockId.includes(flowBlock._id),
                                ),
                             },
                          }
                        : node,
                  ),
               );
               setLeftNodes((prevNodes) =>
                  prevNodes.map((node) =>
                     node.id === clickedNodeId
                        ? {
                             ...node,
                             data: {
                                ...node.data,
                                flowBlocks: node.data.flowBlocks.filter(
                                   (flowBlock: IFlowBlockTemplate) => !blockId.includes(flowBlock._id),
                                ),
                             },
                          }
                        : node,
                  ),
               );
            },
         });
      } catch (error) {
         console.error('Error deleting block:', error);
      }
   };

   // Update block mutation for a specific field
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);

   const handleUpdateFlowBlockFields = async (blockId: string, fields: IFlowBlockField[]) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some(
                                 (block) => block.fields && block.fields.some((field) => field.value !== 'on_no_match'),
                              )
                                 ? true
                                 : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));

               setMutationCount((prevCount) => prevCount - 1);
               setIsBtnDisable(true);
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // update the local state with the Id coming from the response mutation
   useEffect(() => {
      if (dataId) {
         let updatedBlockState = [...blockState];
         dataId.forEach((element) => {
            updatedBlockState = updatedBlockState.map((block) => {
               if (element.prevData === block._id) {
                  return {
                     ...block,
                     _id: element.newData,
                  };
               } else {
                  return block;
               }
            });
         });

         setBlockState(updatedBlockState);
      }
   }, [leftNodes]);

   const listBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   // default
   useEffect(() => {
      setBlockState([]);
      if (listBlocks.length > 0) {
         const data = listBlocks.map((item: IFlowBlockTemplate) => {
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });
         setBlockState(data);
      } else {
         const noMatchBlockExists = blockState.some(
            (block) => block.fields && block.fields.some((field) => field.value === 'on_no_match'),
         );

         if (!noMatchBlockExists) {
            const block = {
               _id: generateRandomId(),
               title: '',
               fields: [
                  {
                     type: IFlowBlockFieldTypes.Text,
                     rule: IFlowBlockFieldRules.Contains,
                     value: 'on_no_match',
                  },
               ],
               type: FlowBlockType.Condition,
            };

            setBlockState([block]);

            (async () => {
               if (LIST_BLOCK_TEMPLATE) {
                  const templateBlockId = LIST_BLOCK_TEMPLATE.find(
                     (item: { type: FlowBlockType }) => item.type === FlowBlockType.Condition,
                  )?._id;
                  if (templateBlockId) {
                     handleAddFlowBlock(templateBlockId, clickedNodeId, block.fields, block._id);
                  }
               }
            })();
         }
      }
   }, [clickedNodeId]);

   useEffect(() => {
      if (mutationCount > 0) {
         setIsLoading(true);
      } else {
         setIsLoading(false);
      }
   }, [mutationCount]);

   // Save
   useEffect(() => {
      if (isSaveCondition && !isLoading) {
         setIsSaveCondition(false);

         const newBlocks = blockState.filter(
            (block) => !backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );
         const existingBlocks = blockState.filter((block) =>
            backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         // Delete blocks into the backend
         if (deletedBlockId.length > 0) {
            const idToDelete = deletedBlockId.filter((item) =>
               backendBlocks.some((backendItem: { _id: string }) => backendItem._id === item),
            );
            if (idToDelete.length > 0) {
               handleDeleteFlowBlock(idToDelete, clickedNodeId);
               setDeletedBlockId([]);
            }
         }

         // Add all new blocks to the backend
         const save = async () => {
            // Update fields
            if (toUpdate.length > 0) {
               toUpdate.forEach((item) => {
                  const existingBlock = existingBlocks.find((block) => block._id === item._id);
                  if (existingBlock) {
                     setIsLoading(true);
                     handleUpdateFlowBlockFields(item._id, existingBlock.fields);
                  }
               });
               setToUpdate([]);
            }

            if (newBlocks.length > 0) {
               (async () => {
                  if (LIST_BLOCK_TEMPLATE) {
                     const templateBlockId = LIST_BLOCK_TEMPLATE.find(
                        (item: { type: FlowBlockType }) => item.type === FlowBlockType.Condition,
                     )?._id;
                     for await (let item of newBlocks) {
                        if (templateBlockId) {
                           await handleAddFlowBlock(templateBlockId, clickedNodeId, item.fields, item._id);
                        }
                     }
                  }
               })();
            }
         };
         save();
         setToUpdate([]);
      }
   }, [isSaveCondition]);

   const getLabelFromLocation = (value: string) => {
      const locationItem = LOCATION.find((item) => item.value === value);
      return locationItem ? locationItem.label : '';
   };
   const getLabelFromLanguages = (value: string) => {
      const Item = OPTIONS_LANGUAGE.find((item) => item.value === value);
      return Item ? Item.label : '';
   };

   useEffect(() => {
      setSearchSelectKey((prevKey) => prevKey + 1);
      setSearchSelectKeyRule((prevKey) => prevKey + 'r1');
      setTextFieldKey((prevKey) => prevKey + 't1');
   }, [country, clickedNodeId, refrech]);

   const blocks = blockState.slice(1);
   const height = 12;

   function getNameByLabel(label: string | undefined) {
      const lowercasedLabel = label?.toLowerCase();
      const matchingProperty = customFields.find((property) => property.label.toLowerCase() === lowercasedLabel);
      return matchingProperty ? matchingProperty.name : null;
   }

   return (
      <Grid>
         <Grid>
            {blocks.map((item, index) => (
               <div>
                  <Grid key={index} className={classes.groupContent}>
                     <Grid
                        className={classes.groupHeader}
                        onMouseEnter={() => setHoveredBlockId(item._id)}
                        onMouseLeave={() => setHoveredBlockId(null)}
                     >
                        <Typography>
                           {t.atomation_flow_condition_condition_group} #{index + 1}
                        </Typography>
                        {hoveredBlockId === item._id && (
                           <DeleteIcon onClick={() => handleDeleteBlock(item._id)} className={classes.deleteIcon} />
                        )}
                     </Grid>
                     {item.fields.map((field, index) => (
                        <Grid style={{ padding: '0 10px' }}>
                           <Grid style={{ marginBottom: '-10px' }}>
                              {field.condition_field === 'channel'
                                 ? t.atomation_flow_condition_channel
                                 : field.condition_field === 'email_consent'
                                 ? t.atomation_flow_condition_email_consent
                                 : field.condition_field === 'message_content'
                                 ? t.atomation_flow_condition_message_content
                                 : field.condition_field === 'phone_consent'
                                 ? t.atomation_flow_condition_phone_consent
                                 : field.condition_field === 'browser_language'
                                 ? t.atomation_flow_condition_browser_language
                                 : field.condition_field === 'browser_country'
                                 ? t.atomation_flow_condition_browser_country
                                 : field.condition_field === 'working_hours'
                                 ? t.atomation_flow_condition_working_hours
                                 : field.condition_field === 'email'
                                 ? t.pre_load_input_label_email
                                 : field.condition_field === 'surname'
                                 ? t.atomation_flow_last_name
                                 : field.condition_field === 'name'
                                 ? t.atomation_flow_first_name
                                 : field.condition_field === 'phone'
                                 ? t.atomation_flow_phone
                                 : field.condition_field === 'page_url'
                                 ? t.atomation_flow_condition__page_url
                                 : getNameByLabel(field.condition_field)}
                           </Grid>
                           <Grid className={classes.row}>
                              <SearchSelect
                                 key={searchSelectKeyRule}
                                 options={OPTIONS}
                                 className={
                                    field.rule === IFlowBlockFieldRules.HasAnyValue ||
                                    field.rule === IFlowBlockFieldRules.Unknown
                                       ? classes.ruleSelect100
                                       : classes.ruleSelect
                                 }
                                 menuPosition='fixed'
                                 placeholder={<div>{t.atomation_flow_select}</div>}
                                 styles={customStyles}
                                 defaultValue={
                                    field.rule
                                       ? {
                                            label:
                                               field.rule === IFlowBlockFieldRules.Contains
                                                  ? t.atomation_flow_trigger_block_contains
                                                  : field.rule === IFlowBlockFieldRules.Equal
                                                  ? t.atomation_flow_trigger_block_equal
                                                  : field.rule === IFlowBlockFieldRules.NotContains
                                                  ? t.atomation_flow_trigger_block_not_contains
                                                  : field.rule === IFlowBlockFieldRules.HasAnyValue
                                                  ? t.atomation_flow_trigger_block_has_any_value
                                                  : field.rule === IFlowBlockFieldRules.Unknown
                                                  ? t.atomation_flow_trigger_block_unknown
                                                  : field.rule === IFlowBlockFieldRules.StartWith
                                                  ? t.atomation_flow_trigger_block_start_with
                                                  : field.rule === IFlowBlockFieldRules.EndWith
                                                  ? t.atomation_flow_trigger_block_end_with
                                                  : t.atomation_flow_trigger_block_not_equal,
                                            value: field.rule,
                                         }
                                       : null
                                 }
                                 onChange={(e) => {
                                    if (e) {
                                       handleSelectRuleChange(item._id, index, e.value);
                                    }
                                 }}
                              />

                              {!(
                                 field.rule === IFlowBlockFieldRules.HasAnyValue ||
                                 field.rule === IFlowBlockFieldRules.Unknown
                              ) &&
                              (field.condition_field === 'channel' ||
                                 field.condition_field === 'email_consent' ||
                                 field.condition_field === 'phone_consent' ||
                                 field.condition_field === 'working_hours' ||
                                 field.condition_field === 'browser_country' ||
                                 field.condition_field === 'browser_language' ||
                                 getTypeByName(field.condition_field) === 'LIST') ? (
                                 <SearchSelect
                                    key={searchSelectKey}
                                    options={
                                       field.condition_field === 'channel'
                                          ? CHANNELS
                                          : field.condition_field === 'email_consent' ||
                                            field.condition_field === 'phone_consent'
                                          ? OPTIONS_CONSENT
                                          : getTypeByName(field.condition_field) === 'LIST'
                                          ? (getListByName(field.condition_field) || []).map((lst) => ({
                                               label: lst,
                                               value: lst,
                                            }))
                                          : field.condition_field === 'browser_language'
                                          ? OPTIONS_LANGUAGE
                                          : field.condition_field === 'browser_country'
                                          ? LOCATION
                                          : field.condition_field === 'working_hours'
                                          ? OPTIONS_WORKING
                                          : OPTIONS
                                    }
                                    className={classes.ruleSelectValue}
                                    menuPosition='fixed'
                                    placeholder={<div>{t.atomation_flow_select}</div>}
                                    styles={customStyles}
                                    defaultValue={
                                       field.value
                                          ? {
                                               label:
                                                  field.value === 'in_of_working_hours'
                                                     ? t.atomation_flow_in_working_hours
                                                     : field.value === 'not_in_working_hours'
                                                     ? t.atomation_flow_not_in_working_hours
                                                     : field.value && field.condition_field === 'browser_country'
                                                     ? getLabelFromLocation(field.value)
                                                     : field.value && field.condition_field === 'browser_language'
                                                     ? getLabelFromLanguages(field.value)
                                                     : field.value && field.condition_field === 'email_consent'
                                                     ? field.value === 'true'
                                                        ? t.page_contacts_typo_subscribed
                                                        : t.page_contacts_typo_unsubscribed
                                                     : field.value && field.condition_field === 'phone_consent'
                                                     ? field.value === 'true'
                                                        ? t.page_contacts_typo_subscribed
                                                        : t.page_contacts_typo_unsubscribed
                                                     : field.value && field.condition_field === 'channel'
                                                     ? field.value === FlowInstanceMessageChannel.WhatsappBusiness
                                                        ? t.atomation_flow_whatsappbussiness
                                                        : field.value === FlowInstanceMessageChannel.WhatsappWeb
                                                        ? t.page_settings_typo_platforms_whats_app_web
                                                        : field.value === FlowInstanceMessageChannel.LiveChat
                                                        ? t.page_settings_typo_platforms_live_chat
                                                        : field.value
                                                     : field.value,
                                               value: field.value,
                                            }
                                          : null
                                    }
                                    onChange={(e) => {
                                       if (e) {
                                          handleSelectValueChange(item._id, index, e.value);
                                       }
                                    }}
                                 />
                              ) : (
                                 !(
                                    field.rule === IFlowBlockFieldRules.HasAnyValue ||
                                    field.rule === IFlowBlockFieldRules.Unknown
                                 ) && (
                                    <TextField
                                       key={textFieldKey}
                                       name='value'
                                       placeholder='value'
                                       defaultValue={field.value}
                                       inputProps={{
                                          style: {
                                             height,
                                          },
                                       }}
                                       variant='outlined'
                                       style={{ width: '55%' }}
                                       onChange={(e) =>
                                          handleTextValueChange(item._id, index, e.target.value, field.condition_field)
                                       }
                                    />
                                 )
                              )}
                              <CloseIcon
                                 onClick={() => handleDeleteField(item._id, index)}
                                 style={{ marginTop: '1.5rem' }}
                                 className={classes.deleteIcon}
                              />
                           </Grid>
                        </Grid>
                     ))}

                     <Grid style={{ padding: '10px' }}>
                        <button className={classes.btn} onClick={(e) => handleMenuOpenField(e, item._id)}>
                           + {t.atomation_flow_condition_add}
                        </button>
                     </Grid>
                  </Grid>

                  {index < blocks.length - 1 && (
                     <div style={{ display: 'flex', gap: '10px', margin: '5px', justifyContent: 'center' }}>
                        <div className={classes.line}>
                           <hr color='#EDEDED' />
                        </div>
                        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t.atomation_flow_Or}</div>
                        <div className={classes.line}>
                           <hr color='#EDEDED' />
                        </div>
                     </div>
                  )}
               </div>
            ))}

            {blocks.length < 10 && (
               <Grid>
                  {blocks.length > 0 && (
                     <div style={{ display: 'flex', margin: '5px', gap: '10px', justifyContent: 'center' }}>
                        <div className={classes.line}>
                           <hr color='#EDEDED' />
                        </div>
                        <div style={{ textAlign: 'center', fontWeight: 'bold' }}>{t.atomation_flow_Or}</div>
                        <div className={classes.line}>
                           <hr color='#EDEDED' />
                        </div>
                     </div>
                  )}
                  <button className={classes.btnGroup} onClick={(e) => handleMenuOpenBlock(e)}>
                     {t.atomation_flow_condition_add_group}
                  </button>
               </Grid>
            )}
         </Grid>

         <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{ marginTop: 10 }}
            PaperProps={{
               style: {
                  width: isAddField ? '345px' : '364px',
                  height: '250px',
                  borderRadius: '2px',
               },
            }}
         >
            {conditionMenu.map((item) => (
               <MenuItem key={item.value} onClick={() => handleSelectCondition(item.value)}>
                  {item.label}
               </MenuItem>
            ))}
         </Menu>
      </Grid>
   );
};

export default ConditionBlocks;

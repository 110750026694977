import React, { useContext } from 'react';
import { Grid, Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactSelect from 'react-select';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import { LanguageContext } from '@helper/locale/langContext';
import { ModalMediumText, ModalSmallText, ModalTitle } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';

const useStyles = makeStyles((theme) => ({
   title: {
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderBottom: '1px solid #eee',
      padding: 10,
   },
   accountSection: {
      backgroundColor: '#F1F6FE',
      padding: 25,
      marginTop: 20,
      borderRadius: 5,
   },
   aboutSection: {
      backgroundColor: '#fff',
      padding: '5px 10px',
      marginTop: 10,
   },
   buttonCancel: {
      height: 35,
      fontSize: '1.1rem',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: 5,
      border: '1px solid #b9d0e5',
      color: '#748fa7',
   },
   mLeft5: {
      marginLeft: 5,
   },
   marginTop5: {
      marginTop: 5,
      marginBottom: 5,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const OPTIONS = [
   { label: 1, value: 1 },
   { label: 2, value: 2 },
   { label: 3, value: 3 },
   { label: 4, value: 4 },
   { label: 5, value: 5 },
   { label: 6, value: 6 },
   { label: 7, value: 7 },
   { label: 8, value: 8 },
   { label: 9, value: 9 },
   { label: 10, value: 10 },
];
const DeleteUserModal = ({ customer, onClose, onDelete, selectValue, onChangeCount, defaultValue, selectOptions }) => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   return (
      <Grid>
         <ModalTitle>{t?.delete_some_of_user}</ModalTitle>
         <ModalSmallText>{t?.if_you_delete_user_can_not_login_user}</ModalSmallText>
         <Grid className={classes.accountSection} direction='column' item container>
            <Grid className={classes.aboutSection} container direction='column' item xs='12'>
               <ModalMediumText>
                  {t?.you_have_to_right_user_usage}
                  <Typography component='span' className={classes.mLeft5} color='primary'>
                     {customer.package.totalPersonCount}
                  </Typography>
               </ModalMediumText>
               <ModalMediumText>
                  {t?.active_user_count}
                  <Typography component='span' style={{ color: '#4caf50' }} className={classes.mLeft5}>
                     {customer.usagePersonCount}
                  </Typography>
               </ModalMediumText>
               <ModalMediumText>
                  {t?.you_can_delete_count_user}
                  <Typography component='span' color='error' className={classes.mLeft5}>
                     {customer.package.totalPersonCount - customer.usagePersonCount}
                  </Typography>
               </ModalMediumText>
            </Grid>

            <Grid item container alignItems='center' style={{ marginTop: 10 }} justifyContent='center'>
               <Grid item xs='10'>
                  <ModalMediumText>{t?.how_many_seat_you_want_to_delete}</ModalMediumText>
               </Grid>
               <Grid item xs='2'>
                  <ReactSelect
                     options={OPTIONS || selectOptions}
                     getOptionValue={(option) => option.value}
                     defaultValue={{ label: 1, value: 1 } || defaultValue}
                     onChange={(option) => onChangeCount(option)}
                     value={selectValue}
                  />
               </Grid>
            </Grid>
         </Grid>
         {/* Buttons */}
         <ModalButtonField>
            <Button
               className={classes.modalButtons}
               disableRipple
               onClick={onClose}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            >
               {t['back']}
            </Button>
            <ButtonCancel
               className={classes.modalButtons}
               label={t?.comp_button_delete}
               onClick={onDelete}
               style={{
                  color: 'white',
                  backgroundColor: '#ef5350',
                  '&:hover': {
                     backgroundColor: '#f44336',
                  },
                  float: 'right',
               }}
            />
         </ModalButtonField>
      </Grid>
   );
};

export default DeleteUserModal;

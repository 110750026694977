import React from 'react';
const InstagramIcon = ({ color, selected, className }) => {
   const color1 = color ? color : selected ? 'url(#linear-gradient)' : '#c7cdd6';
   const color2 = color ? color : selected ? 'url(#linear-gradient-2)' : '#c7cdd6';
   const color3 = color ? color : selected ? 'url(#linear-gradient-3)' : '#c7cdd6';

   return (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 595 595' className={className} style={{ width: '100%' }}>
         <defs>
            <linearGradient
               id='linear-gradient'
               x1='182.45'
               y1='-119.78'
               x2='722.06'
               y2='396.73'
               gradientUnits='userSpaceOnUse'
            >
               <stop offset='0' stopColor='#ffe185' />
               <stop offset='0.21' stopColor='#ffbb36' />
               <stop offset='0.38' stopColor='#ff5176' />
               <stop offset='0.52' stopColor='#f63395' />
               <stop offset='0.74' stopColor='#a436d2' />
               <stop offset='1' stopColor='#5f4eed' />
            </linearGradient>
            <linearGradient id='linear-gradient-2' x1='30.19' y1='39.3' x2='569.8' y2='555.8' href='#linear-gradient' />
            <linearGradient
               id='linear-gradient-3'
               x1='29.02'
               y1='40.52'
               x2='568.63'
               y2='557.02'
               href='#linear-gradient'
            />
         </defs>
         <path d='M455,106.24a34.88,34.88,0,1,0,34.88,34.88A34.88,34.88,0,0,0,455,106.24Z' fill={color1} />
         <path
            d='M299.94,151c-80.8,0-146.53,65.73-146.53,146.53S219.14,444,299.94,444,446.47,378.29,446.47,297.5,380.74,151,299.94,151Zm0,240.39A93.86,93.86,0,1,1,393.8,297.5,94,94,0,0,1,299.94,391.36Z'
            fill={color2}
         />
         <path
            d='M416.27,595H178.72C80.17,595,0,514.83,0,416.28V178.72C0,80.17,80.17,0,178.72,0H416.27C514.81,0,595,80.17,595,178.72V416.28C595,514.83,514.81,595,416.27,595ZM178.72,56A122.88,122.88,0,0,0,56,178.72V416.28A122.87,122.87,0,0,0,178.72,539H416.27A122.88,122.88,0,0,0,539,416.28V178.72A122.89,122.89,0,0,0,416.27,56Z'
            fill={color3}
         />
      </svg>
   );
};

export default InstagramIcon;

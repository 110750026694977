import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../styles/index.css';

const useStyles = makeStyles((theme) => ({
   inputLabel: {
      fontSize: '1.2rem',
      color: (props) => props.labelColor || '',
      '&.focused': {
         color: theme.palette.primary.main,
      },
   },
   editorContainer: {
      height: '170px',
      margin: '0.5rem 0.5rem 4rem 0.5rem',
   },
}));

const RichText = (props) => {
   const {
      label,
      value,
      setValue,
      htmlValue,
      setHtmlValue,
      setIsClickedSignatureHtml,
      isClickedSignatureHtml,
      setIsClickedSignatureText,
      isClickedSignatureText,
      setCodeSignature,
      codeSignature,
   } = props;
   const classes = useStyles(props);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const handleHtml = () => {
      setCodeSignature(true);
      setIsClickedSignatureHtml('green');
      setIsClickedSignatureText('initial');
   };
   const handleText = () => {
      setCodeSignature(false);
      setIsClickedSignatureText('green');
      setIsClickedSignatureHtml('initial');
   };

   const modules = {
      toolbar: [
         //  [{ header: [1, 2, 3, 4, 5, 6, false] }],
         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
         [{ list: 'ordered' }, { list: 'bullet' }],
         ['link', 'image'],
         //  ['clean'],
         // ['code-block'],
      ],
      clipboard: {
         matchVisual: false,
      },
   };

   const formats = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'link',
      'image',
      'video',
      'clean',
   ];

   const divStyle = {
      display: 'flex',
      gap: '1rem',
      justifyContent: 'flex-end',
      cursor: 'pointer',
      padding: '0 0.5rem',
   };

   return (
      <div>
         <Grid
            container
            alignItems='center'
            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
         >
            <Grid>
               <Typography className={classes.inputLabel}>{label}</Typography>
            </Grid>
            <div style={divStyle}>
               <p onClick={handleText} style={{ color: isClickedSignatureText }}>
                  {t['page_settings_typo_text']}
               </p>
               <p onClick={handleHtml} style={{ color: isClickedSignatureHtml }}>
                  Html
               </p>
            </div>
         </Grid>
         <div>
            {!codeSignature ? (
               <ReactQuill
                  theme='snow'
                  value={value}
                  onChange={setValue}
                  modules={modules}
                  formats={formats}
                  className={classes.editorContainer}
               />
            ) : (
               <textarea
                  value={htmlValue}
                  onChange={(e) => setHtmlValue(e.target.value)}
                  type='text'
                  name='html'
                  style={{
                     height: '170px',
                     width: '100%',
                     fontFamily: 'Helvetica',
                     fontSize: '1rem',
                     padding: '1rem',
                  }}
               />
            )}
         </div>
      </div>
   );
};

export default RichText;

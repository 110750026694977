import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import 'moment/locale/tr';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory } from 'react-router-dom';
import { SET_ACTIVE_QUESTION } from '@store/actions/questions';
import { QuestionsMessageIcon } from '@assets/icons/QuestionIcons';
import { LanguageContext } from '@helper/locale/langContext';
import WithUrlTranslations from '../../hoc/WithUrlTranslations';
import { getActiveQuestion } from '@store/reducers/questions';
import { MyTheme } from '@styles/config';
import LeftPanel from './LeftPanel';
import QuestionsSpace from './Space/chatSpace';
import PersistentDrawerRight from './UserProfile/testDrawer';
import useQuestionSubscriptions from "@modules/Questions/shared-hooks/useQuestionSubscriptions";

const useStyles = makeStyles((theme: MyTheme) => ({
   content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
         minHeight: '-webkit-fill-available',
         height: '100%',
      },
   },
   container: {
      backgroundColor: 'white',
   },
   appBarSpacer: theme.mixins.toolbar,
   footer: {
      backgroundColor: 'green',
      borderTop: '1px solid #E7E7E7',
      textAlign: 'center',
      padding: 20,
      position: 'fixed',
      left: 527,
      bottom: 0,
      minHeigh: 100,
   },
   profileBackgroundContainer: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
   },
   noChatIcon: {
      width: 200,
      height: 200,
   },
   noChatText: {
      color: '#ccd8e8',
      fontSize: '1.1rem',
      fontWeight: 'bold',
      marginTop: theme.spacing(4),
   },
   leftPanel: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      height: '100vh',
      width: 403,
      [theme.breakpoints.down('sm')]: {
         width: '100%',
      },
   },
   '@global': {
      '::-webkit-scrollbar': {
         width: 6,
         transition: 'all .5s',
      },
      '::-webkit-scrollbar-track': {
         background: theme.chatApp.general.pallet.passiveStructureGrey,
      },
      '::-webkit-scrollbar-thumb': {
         background: '#C9CCD5',
      },
      '::-webkit-scrollbar-thumb:hover': {
         background: '#9399ab',
      },
   },
}));

const Questions: React.FC = () => {
   const classes = useStyles();
   const history = useHistory();
   const dispatch = useDispatch();
   const activeQuestion = useSelector(getActiveQuestion);
   useQuestionSubscriptions();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   useEffect(() => {
      if (history.location.pathname === '/questions') {
         dispatch({
            type: SET_ACTIVE_QUESTION,
            payload: null,
         });
      }
   }, [dispatch, history.location.pathname]);

   useEffect(() => {
      if (!activeQuestion) {
         history.replace('/questions');
      }
   }, [activeQuestion, history]);

   return (
      <main className={classes.content}>
         <Grid container className={classes.container} wrap='nowrap'>
            <Hidden smDown={history.location.pathname.includes('/questions/')}>
               <div className={classes.leftPanel}>
                  <LeftPanel />
               </div>
            </Hidden>

            {history.location.pathname !== '/questions' ? (
               <Switch>
                  <Route path='/questions/:_id'>
                     <QuestionsSpace />
                     <PersistentDrawerRight />
                     <div className={classes.appBarSpacer} />
                  </Route>
               </Switch>
            ) : (
               <Hidden smDown>
                  <Grid
                     style={{ textAlign: 'center', margin: 'auto' }}
                     alignItems='center'
                     justifyContent='center'
                     direction='column'
                     container
                     wrap={'nowrap'}
                  >
                     <QuestionsMessageIcon className={classes.noChatIcon} />
                     <Typography className={classes.noChatText}>{t?.there_is_no_selected_chat}</Typography>
                  </Grid>
               </Hidden>
            )}
         </Grid>
      </main>
   );
};

export default WithUrlTranslations(Questions);

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
const useStyles = makeStyles((theme) => ({
   item: {
      display: 'inline-block',
      width: 7,
      height: (props) => props.height || 35,
      marginTop: 0,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
}));

const ListItemIndicator = (props) => {
   const { selected } = props;
   const classes = useStyles();
   return <div className={clsx(classes.item, selected && classes.selectedItem)} />;
};

export default ListItemIndicator;

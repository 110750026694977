export const SET_QNA_CHAT_FILTERS = 'SET_QNA_CHAT_FILTERS';
export const RESET_QNA_CHAT_FILTERS = 'RESET_QNA_CHAT_FILTERS';
export const SET_MY_CUSTOMER_QUESTIONS = 'SET_MY_CUSTOMER_QUESTIONS';
export const SET_MY_QUESTIONS = 'SET_MY_QUESTIONS';
export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';
export const SET_ACTIVE_QUESTION_MESSAGES = 'SET_ACTIVE_QUESTION_MESSAGES';
export const UPDATE_QNA_PERSON = 'UPDATE_QNA_PERSON';
export const SET_QUESTION_CLICKED_ITEM = 'SET_QUESTION_CLICKED_ITEM';
export const SET_QUESTION_CLICKED_REPLY_TYPE = 'SET_QUESTION_CLICKED_REPLY_TYPE';
export const SET_QUESTION_CLICKED_STATUS = 'SET_QUESTION_CLICKED_STATUS';
export const SET_QUESTION_CLICKED_FORM_STATUS = 'SET_QUESTION_CLICKED_FORM_STATUS';
export const QUESTION_UPDATE_LOG = 'QUESTION_UPDATE_LOG';
export const NEW_QNA_TIMEOUT = 'NEW_QNA_TIMEOUT';
export const NEW_QNA_PERSON_TIMEOUT = 'NEW_QNA_PERSON_TIMEOUT';

export const JOINED_QUESTION = 'JOINED_QUESTION';
export const LEAVED_QUESTION = 'LEAVED_QUESTION';
export const UPDATED_QUESTION_STATUS = 'UPDATED_QUESTION_STATUS';
export const FORWARDED_QUESTION = 'FORWARDED_QUESTION';
export const UPDATED_QNA_TAG = 'UPDATED_QNA_TAG';
export const QUESTION_TO_ME = 'QUESTION_TO_ME';
export const QUESTION_TO_ME_UPDATED = 'QUESTION_TO_ME_UPDATED';
export const SEND_ANSWER = 'SEND_ANSWER';
export const CONFIRM_ANSWER = 'CONFIRM_ANSWER';

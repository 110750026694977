import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { LangCodes } from '@helper/language';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CREATE_TEMPLATE_MUTATION } from '@queries/Settings/Service/GraphQL/Platform/mutation';
import { GET_TEMPLATE } from '@queries/Settings/Service/GraphQL/Platform/query';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import TopBar from './formUtilities/TopBar';
import NewTemplateForms from './NewTemplateForms';
import PreviewPanel from './PreviewPanel';
import SubmitTemplate from './tableTemplatesPage/SubmitTemplate';
import { GET_CUSTOM_FIELDS } from '@queries/Settings/Service/GraphQL/CustomFields/query';

const useStyles = makeStyles((theme) => ({
   content: {
      width: '100%',
      height: '100%',
      minWidth: 795,
      overflow: 'hidden',
   },
   form: {
      height: '100%',
      overflowY: 'auto',
   },
   previewPanel: {
      backgroundColor: '#fafafa',
      height: '100%',
      paddingInline: 10,
      overflowY: 'hidden',
   },
}));

const CreateNewTemplate = () => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.only('sm'));
   const [callUs, setCallUs] = useState(false);
   const [webUrl, setWebUrl] = useState(false);
   const [visibilityOfInputs, setVisibilityOfInputs] = useState([false, false, false]);
   const [createLoading, setCreateLoading] = useState(false);

   const [openButtonPanel, setOpenButtonPanel] = useState(false);
   const [openQuickReply, setOpenQuickReply] = useState(false);

   const [selectedButtonTop, setSelectedButtonTop] = useState(false);
   const [selectedButtonBottom, setSelectedButtonBottom] = useState(true);

   const [customFields, setCustomFields] = useState([] as any[]);

   const person = useSelector((reducer: any) => reducer.personReducer.person);
   const appId = useParams()._id;
   const mongoId = useParams()._mongoId;
   const [isLoading, setLoading] = useState(!!mongoId);

   const [isSubmitOpen, setIsSubmitOpen] = useState(false);

   const { enqueueSnackbar } = useSnackbar();

   const [createTemplateMutation] = useMutation(CREATE_TEMPLATE_MUTATION, {
      async update(
         cache,
         {
            data: {
               createTemplate: { data: createTemplate },
            },
         },
      ) {},
   });

   const handleCallUs = (params?: any) => {
      if (params !== undefined) setCallUs(params);
      else setCallUs(!callUs);
   };
   const handleWebUrl = (params?: any) => {
      if (params !== undefined) setWebUrl(params);
      else setWebUrl(!webUrl);
   };

   const { register, handleSubmit, watch, control, resetField, reset, setValue, formState } = useForm({
      mode: 'all',
      reValidateMode: 'onChange',
      defaultValues: {
         name: '',
         languageCode: '',
         category: '',
         templateType: '',
         message: '',
         appId,
         exampleMedia: '',
         sampleMedia: {},
         customer: person.customer._id,
         path: '',
         footer: '',
         qpienFields: [],
         survey: null,
         eCommerceCart: false,

         buttonTextweb: '', // Buttons 1
         buttonTextwebUrl: '', // Buttons 1
         buttonTextwebVariable: '', // Buttons 1
         buttonTextwebExample: '', // Buttons 1
         variable: {
            value: 'no_template_variable',
            label: t['no_template_variable'],
         },
         buttonTextphoneName: '', // Buttons 2
         buttonTextphoneNumber: '', // Buttons 2

         input0: '',
         input1: '',
         input2: '',

         names: '',
         surname: '',
         email: '',
         rule: '',
         adress: '',

         title: '',
         content: '',
      },
   });

   const handleMedia = (data: any) => {
      setValue('sampleMedia', {});
      setValue('exampleMedia', '');
      setValue('path', '');
   };

   const onSubmit = (data: any) => {
      const obj: any = {
         name: data.name,
         vertical: data.name,
         languageCode: data.languageCode,
         category: data.category,
         templateType: data.templateType,
         message: data.message,
         qpienFields: data.qpienFields,
         example: data.example,
         appId,
         exampleMedia: data.exampleMedia,
         path: data.path,
         sampleMedia: data.sampleMedia,
         customer: person.customer._id,
         buttons: [],
         footer: data.footer,
         survey: data.survey,
         ...(data.eCommerceCart && { eCommerceCart: data.eCommerceCart }),
      };

      if (data.buttonTextweb) {
         if (data.variable.value !== 'no_template_variable') {
            let baseUrl = data.buttonTextwebUrl.endsWith('/') ? data.buttonTextwebUrl : data.buttonTextwebUrl + '/';
            obj.buttons.push({
               type: 'URL',
               text: data.buttonTextweb,
               url: `${baseUrl}{{1}}`,
               example: [`${baseUrl}${data.buttonTextwebExample}`],
            });
         } else {
            obj.buttons.push({
               type: 'URL',
               text: data.buttonTextweb,
               url: data.buttonTextwebUrl,
               example: [data.buttonTextwebUrl],
            });
         }
      }

      if (data.buttonTextphoneName) {
         obj.buttons.push({
            type: 'PHONE_NUMBER',
            text: data.buttonTextphoneName,
            phone_number: data.buttonTextphoneNumber,
         });
      }

      if (data.input0) {
         obj.buttons.push({
            type: 'QUICK_REPLY',
            text: data.input0.replaceAll(',', '_'),
         });
      }

      if (data.input1) {
         obj.buttons.push({
            type: 'QUICK_REPLY',
            text: data.input1.replaceAll(',', '_'),
         });
      }

      if (data.input2) {
         obj.buttons.push({
            type: 'QUICK_REPLY',
            text: data.input2.replaceAll(',', '_'),
         });
      }
      setCreateLoading(true);
      if (data.qpienFields.length > 0) {
         let example = data.message;
         obj.qpienFields.forEach((field: any) => {
            example = example.replaceAll(`{{${field.key}}}`, field.exampleData);
         });
         obj.example = example;
      } else {
         obj.example = data.message;
      }

      createTemplateMutation({
         variables: {
            data: obj,
         },
      })
         .then((res) => {
            setCreateLoading(false);
            window.location.href = `/settings/platforms/whatsapp-business/templatesPage/${appId}`;
         })
         .catch((err) => {
            setIsSubmitOpen(false);
            setCreateLoading(false);
            enqueueSnackbar(err.message, {
               // send error message
               variant: 'error',
            });
         });
   };

   const onRemove = (index: any) => {
      setVisibilityOfInputs((prev) => {
         return prev.map((item, i) => (index === i ? false : item));
      });
   };

   const onAdd = () => {
      setVisibilityOfInputs((prev) => {
         const indexOfFirstUnvisibleInput = prev.findIndex((item) => item === false);
         if (indexOfFirstUnvisibleInput > -1) {
            return prev.map((item, i) => (indexOfFirstUnvisibleInput === i ? true : item));
         } else {
            return prev;
         }
      });
   };

   const { loading: isCustomFieldsLoading } = useQuery(GET_CUSTOM_FIELDS, {
      variables: {
         customer: person.customer._id,
      },
      fetchPolicy: 'cache-first',
      onCompleted: (data) => {
         const response = data.getCustomFields.data;
         setCustomFields(response);
      },
   });

   const [getPlatforms] = useLazyQuery(GET_TEMPLATE, {
      fetchPolicy: 'network-only',
      onCompleted: ({ getTemplate }) => {
         let { data } = getTemplate;
         handleMedia(data.templateType);
         var obj: any = {
            name: data.vertical,
            vertical: data.vertical,
            message: data.message,
            example: data.example,
            exampleMedia: data.exampleMedia,
            sampleMedia: data.sampleMedia,
            path: data.exampleMedia,
            footer: data.footer,
            qpienFields: data.qpienFields,
            eCommerceCart: data.eCommerceCart,

            buttonTextweb: '', // Buttons 1
            buttonTextwebUrl: '', // Buttons 1
            buttonTextwebVariable: '', // Buttons 1
            buttonTextwebExample: '', // Buttons 1

            buttonTextphoneName: '', // Buttons 2
            buttonTextphoneNumber: '', // Buttons 2

            input0: '',
            input1: '',
            input2: '',
         };
         obj.qpienFields = obj.qpienFields.map((_field: any) => {
            let value;
            switch (_field.value) {
               case 'first_name':
                  value = { value: 'first_name', label: t['tokens_first_name'] };
                  break;
               case 'last_name':
                  value = { value: 'last_name', label: t['tokens_last_name'] };
                  break;
               case 'email':
                  value = { value: 'email', label: t['tokens_email'] };
                  break;
               case 'phone':
                  value = { value: 'phone', label: t['tokens_phone'] };
                  break;
               case '':
                  value = {
                     value: '',
                     label: t['settings_whatsapp_business_template_variables_qpienfield_placeholder'],
                  };
                  break;
               default:
                  const isCustomField = customFields.find((item) => item.label === _field.value);
                  if (isCustomField) {
                     value = { value: isCustomField.label, label: `{${isCustomField.name}}` };
                  }
                  break;
            }
            return {
               ..._field,
               value,
            };
         });
         switch (data.category) {
            case 'UTILITY':
               obj.category = { value: 'UTILITY', label: t['settings_whatsapp_business_template_category_utility'] };
               break;
            case 'AUTHENTICATION':
               obj.category = {
                  value: 'AUTHENTICATION',
                  label: t['settings_whatsapp_business_template_category_authentication'],
               };
               break;
            case 'MARKETING':
               obj.category = {
                  value: 'MARKETING',
                  label: t['settings_whatsapp_business_template_category_marketing'],
               };
               break;
         }
         switch (data.templateType) {
            case 'TEXT':
               obj.templateType = { value: 'TEXT', label: t['settings_whatsapp_business_template_form_text'] };
               break;
            case 'IMAGE':
               obj.templateType = { value: 'IMAGE', label: t['settings_whatsapp_business_template_form_image'] };
               break;
            case 'VIDEO':
               obj.templateType = { value: 'VIDEO', label: t['settings_whatsapp_business_template_form_video'] };
               break;
            case 'DOCUMENT':
               obj.templateType = { value: 'DOCUMENT', label: t['settings_whatsapp_business_template_form_file'] };
               break;
         }

         obj.languageCode = LangCodes.find((item) => item.value === data.languageCode);
         let message_part = obj.message.split('|')[0];
         let btn_part = obj.message.includes('|') ? obj.message.split('|').slice(1) : '';

         var isQuickReply = false;
         var isButton = false;

         var addButtonPanel = {
            value: 'noButton',
            label: t['settings_whatsapp_business_template_form_button_fields_no_button'],
         };

         if (btn_part) {
            for (let i = 0; i < btn_part.length; i++) {
               let button = btn_part[i];
               let is_quick_reply = /(?:\[)(.+)(?:\])/g.test(button);
               let is_url =
                  button.includes(',') &&
                  /([(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*))/g.test(
                     button,
                  );
               let is_phone = button.includes(',') && /((?:\+|)[0-9]+) ([0-9]+)/g.test(button);
               if (is_url) {
                  setSelectedButtonTop(true);
                  setWebUrl(true);
                  isButton = true;
                  let content = button.match(/(?:\[)(.+)(?:\])/g)[0].replace(/[\[\]]/g, '');
                  obj = {
                     ...obj,
                     buttonTextweb: content.split(',')[0].trim(),
                     buttonTextwebUrl: content.split(',')[1].trim(),
                  };
               } else if (is_phone) {
                  setCallUs(true);
                  setSelectedButtonBottom(true);
                  isButton = true;
                  let content = button.match(/(?:\[)(.+)(?:\])/g)[0].replace(/[\[\]]/g, '');
                  obj = {
                     ...obj,
                     buttonTextphoneName: content.split(',')[0].trim(),
                     buttonTextphoneNumber: content.split(',')[1].trim(),
                  };
               } else if (is_quick_reply) {
                  setVisibilityOfInputs((prevState) => {
                     prevState[i] = true;
                     return prevState;
                  });
                  isQuickReply = true;
                  let quick_reply = button.match(/(?:\[)(.+)(?:\])/g)[0].replace(/[\[\]]/g, '');
                  obj = {
                     ...obj,
                     [`input${i}`]: quick_reply,
                  };
               }
            }
         }

         if (isQuickReply) {
            setOpenQuickReply(true);
            addButtonPanel = {
               value: 'quickReplies',
               label: t['settings_whatsapp_business_template_form_button_fields_quick_replies'],
            };
         } else if (isButton) {
            addButtonPanel = {
               value: 'urlOrPhoneButton',
               label: t['settings_whatsapp_business_template_form_button_fields_url_button'],
            };
            setOpenButtonPanel(true);
         }
         obj.addButtonPanel = addButtonPanel;
         obj.message = message_part;
         reset(obj);
         setLoading(false);
      },
   });

   let message = watch('message');

   useEffect(() => {
      let regex = new RegExp(/\{\{(\d+)\}\}/g, 'g');
      let is_variable_exists = regex.test(message.toString());
      if (!is_variable_exists) {
         setValue('qpienFields', []);
      } else {
         let matched: any = message?.match(regex);
         let _matched: any = [];
         for (let idx = 0; idx < matched.length; idx++) {
            let key = matched[idx].replace(/[{}]/g, '');
            if (isNaN(Number(key))) {
               continue;
            }
            key = Number(key);
            _matched.push(key);
            if (watch('qpienFields').findIndex((item: any) => item.key === key) === -1) {
               let q: any = `qpienFields.${idx}`;
               setValue(q, {
                  key,
                  value: '',
                  exampleData: '',
                  ref: 'person_params',
               });
            }
         }
         let unexisted = watch('qpienFields').filter((item: any) => _matched.includes(item.key));
         setValue('qpienFields', unexisted);
      }
   }, [message]);

   useEffect(() => {
      if (mongoId && !isCustomFieldsLoading) {
         getPlatforms({
            variables: {
               id: mongoId,
            },
         });
      }
   }, [isCustomFieldsLoading]);

   if (isLoading || isCustomFieldsLoading) {
      return <div className='loading' />;
   }

   return (
      <Grid container xs={12} className={classes.content}>
         <Grid
            xs={6}
            sm={6}
            md={7}
            lg={8}
            style={{ height: isSmallScreen ? 'calc(100% - 108px)' : 'calc(100% - 44px)' }}
         >
            <Grid container>
               <TopBar
                  isSelectedSurvey={!watch('survey') && watch('variable.value') === 'template_survey_url'}
                  isView={!!mongoId}
                  isValid={
                     formState.isValid &&
                     watch('message').length <= 1024 &&
                     (watch('templateType') === 'TEXT' || watch('exampleMedia') !== '')
                  }
                  appId={appId}
                  isLoading={createLoading}
                  handleSubmit={() => {
                     setIsSubmitOpen(true);
                  }}
               />
            </Grid>
            <Grid className={classes.form}>
               <NewTemplateForms
                  openButtonPanel={openButtonPanel}
                  setOpenButtonPanel={setOpenButtonPanel}
                  openQuickReply={openQuickReply}
                  setOpenQuickReply={setOpenQuickReply}
                  isView={!!mongoId}
                  resetField={resetField}
                  watch={watch}
                  setValue={setValue}
                  handleMedia={handleMedia}
                  control={control}
                  onRemove={onRemove}
                  register={register}
                  setVisibilityOfInputs={setVisibilityOfInputs}
                  visibilityOfInputs={visibilityOfInputs}
                  onAdd={onAdd}
                  isSmallScreen={isSmallScreen}
                  handleCallUs={handleCallUs}
                  handleWebUrl={handleWebUrl}
                  setSelectedButtonTop={setSelectedButtonTop}
                  setSelectedButtonBottom={setSelectedButtonBottom}
                  selectedButtonTop={selectedButtonTop}
                  selectedButtonBottom={selectedButtonBottom}
                  customFields={customFields}
               />
            </Grid>
         </Grid>
         <Grid xs={6} sm={6} md={5} lg={4} className={classes.previewPanel}>
            <PreviewPanel visibilityOfInputs={visibilityOfInputs} callUs={callUs} webUrl={webUrl} watch={watch} />
         </Grid>
         <SubmitTemplate
            open={isSubmitOpen}
            onClose={() => {
               setIsSubmitOpen(false);
            }}
            onSubmit={handleSubmit(onSubmit)}
            createLoading={createLoading}
         />
      </Grid>
   );
};

export default CreateNewTemplate;

import { gql } from '@apollo/client';
import { CHAT_ALL_FIELDS_FRAGMENT } from '@queries/Chat/Service/GraphQL/Chat/fragments';
// queries
export const GET_TEAM_CHAT_QUERY = gql`
   query getTeamChatQuery($me: ID!, $participant: ID!, $customer: ID!) {
      getTeamChat(me: $me, participant: $participant, customer: $customer) {
         data {
            _id
            participants {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               setup
               createdAt
            }
            type
            whatsAppPhone {
               _id
               name
               number
               status
            }
            whatsAppSenderPhone
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            tag {
               _id
               name
               color
            }
            joinStatus
            isJoinable
            firstLog
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;

export const GET_ACTIVE_CHAT_QUERY = gql`
   query getActiveChatQuery($_id: ID!, $customer: ID!) {
      getActiveChat(_id: $_id, customer: $customer) {
         data {
            _id
            participants {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               countryCode
               state
               address
               postCode
               city
               eCommerceProfileId
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               platformId
               setup
               createdAt
            }
            color
            type
            whatsAppSenderPhone
            whatsAppPhone {
               _id
               name
               number
               status
               createdAt
               onlineStatus
            }
            facebookPage {
               _id
               name
               category
               facebookAccount {
                  _id
                  firstName
                  lastName
                  email
                  facebookId
                  accessToken
                  createdAt
               }
               isMessageEnable
               isCommentEnable
               status
               failedMessage
               facebookPageId
               accessToken
               createdAt
            }
            instagramPage {
               _id
               name
               isMessageEnable
            }
            whatsAppBusinessPhone
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            tag {
               _id
               name
               color
            }
            joinStatus
            color
            isJoinable
            firstLog
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const GET_MY_CUSTOMER_CHATS_QUERY = gql`
   ${CHAT_ALL_FIELDS_FRAGMENT}
   query getMyCustomerChatsQuery(
      $customer: ID!
      $page: Int
      $text: Filter
      $platform: Filter
      $tag: Filter
      $status: Filter
      $startDate: Filter
      $endDate: Filter
      $desc: Boolean
      $teams: String
   ) {
      getMyCustomerChats(
         customer: $customer
         page: $page
         text: $text
         platform: $platform
         tag: $tag
         status: $status
         startDate: $startDate
         endDate: $endDate
         desc: $desc
         teams: $teams
      ) {
         data {
            totalDocs
            limit
            page
            totalPages
            hasNextPage
            nextPage
            hasPrevPage
            prevPage
            pagingCounter
            chats {
               ...ChatAllFields
            }
         }
         success
         code
         message
      }
   }
`;

export const GET_MY_CHATS_QUERY = gql`
   ${CHAT_ALL_FIELDS_FRAGMENT}
   query getMyChatsQuery(
      $me: String!
      $page: Int
      $text: Filter
      $platform: Filter
      $tag: Filter
      $status: Filter
      $startDate: Filter
      $endDate: Filter
      $desc: Boolean
   ) {
      getMyChats(
         me: $me
         page: $page
         text: $text
         platform: $platform
         tag: $tag
         status: $status
         startDate: $startDate
         endDate: $endDate
         desc: $desc
      ) {
         data {
            totalDocs
            limit
            page
            totalPages
            hasNextPage
            nextPage
            hasPrevPage
            prevPage
            pagingCounter
            chats {
               ...ChatAllFields
            }
         }
         success
         code
         message
      }
   }
`;

export const CUSTOM_CHATS_QUERY = gql`
   query CustomChatsQuery(
      $customer: String!
      $platform: String
      $pageSize: Int
      $page: Int
      $search: String
      $searchGroupChat: Boolean
   ) {
      customChatsQuery(
         customer: $customer
         platform: $platform
         pageSize: $pageSize
         page: $page
         search: $search
         searchGroupChat: $searchGroupChat
      ) {
         success
         data {
            docs {
               _id
               type
               participants {
                  name
               }
               whatsAppPhone {
                  _id
                  name
                  number
               }
               telegramAccount {
                  _id
                  userName
                  firstName
               }
            }
            hasNextPage
            totalDocs
         }
         code
         message
      }
   }
`;

// TODO: Switch to enum after implementing typescript
export const PLATFORM_TYPE = {
   FACEBOOK: 'FACEBOOK',
   INSTAGRAM: 'INSTAGRAM',
   WHATS_APP_WEB: 'WHATS_APP_WEB',
   WHATS_APP_BUSINESS: 'WHATS_APP_BUSINESS',
   LIVE_CHAT: 'LIVE_CHAT',
   EMAIL: 'EMAIL',
   TELEGRAM: 'TELEGRAM',
};

export const USERS_TYPE = {
   FACEBOOK: 'USER_FACEBOOK',
   INSTAGRAM: 'USER_INSTAGRAM',
   WHATS_APP_WEB: 'USER_WHATS_APP_WEB',
   WHATS_APP_BUSINESS: 'USER_WHATS_APP_BUSINESS',
   PERSONNEL: 'USER_PERSONNEL',
   ADMIN: 'USER_ADMIN',
   OWNER: 'USER_OWNER',
};

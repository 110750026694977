import ButtonBasedPopup from '@components/ButtonBasedPopup';
import { LanguageContext } from '@helper/locale/langContext';
import WithUrlTranslations from '@hoc/WithUrlTranslations';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { Code, FormatBold, FormatItalic, StrikethroughS } from '@material-ui/icons';
import InfoIcon from '@material-ui/icons/Info';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import { Picker } from 'emoji-mart';
import React, { useContext } from 'react';

const useStyles = makeStyles(() => ({
    customInput: {
        width: '100%',
        height: 260,
        marginTop: '10px',
        marginBottom: '10px',
    },
    inputBar: {
        height: '85%',
        width: '100%',
        '& .MuiPopover-paper': {
            backgroundColor: 'red',
            color: 'yellow',
        },
    },
    textArea: {
        resize: 'none',
        fontSize: '14.7px',
        width: '100%',
        border: '2px solid #D1D1D1',
        borderRadius: 5,
        wordWrap: 'break-word',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        height: 217,
        paddingLeft: 10,
        paddingTop: 10,
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        '&:focus-visible': {
            border: '2px solid #D1D1D1',
            borderRadius: 5,
            outline: 'none !important',
        },
        '&:focus': {
            border: '2px solid #D1D1D1',
            borderRadius: 5,
            outline: 'none !important',
        },
    },
    addMedia: {
        width: 110,
        height: 30,
        padding: 0,
        paddingTop: 3,
        fontSize: 13,
        borderRadius: 5,
        border: '1px dashed #D1D1D1',
    },
    bottomBar: {
        padding: 5,
        height: '15%',
        width: '100%',
    },
    bottomButtons: {
        display: 'flex',
    },
    inputCounter: {
        fontSize: '0.875rem',
        textAlign: 'start',
        width: 90,
        color: '#A0A0A0',
        height: 20,
        lineHeight: 2,
        flexGrow: 1,
    },
    inputText: {
        fontSize: '0.875rem',
    },
    inputIcon: {
        fontSize: '0.875rem',
        width: 15,
        height: 15,
        marginLeft: 2,
        verticalAlign: 'text-top',
    },
}));

const CustomInput = ({ watch, isView, register, setValue }: any) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;
    const [openEmoji, setOpenEmoji] = React.useState(false);
    let message = watch('message');

    function addVariable() {
        let regex = new RegExp(/\{\{(\d+)\}\}/g, 'g');
        let matched: any = message?.matchAll(regex);
        let variableNames = []
        for (let match of matched) {
            let name = match[1];
            if (!isNaN(Number(name))) {
                variableNames.push(Number(name));
            }
        }
        variableNames = variableNames.sort((a, b) => a - b).reverse();
        let newVariableName = variableNames.length > 0 ? variableNames[0] + 1 : 1;
        if (message.endsWith(' ') || message === '') {
            setValue("message", message + `{{${newVariableName}}}`);
        } else {
            setValue("message", message + ` {{${newVariableName}}}`);
        }
    }

    function addFormat(type: string) {
        switch (type) {
            case 'bold':
                if (message.endsWith(' ') || message === '') {
                    setValue("message", message + `*${t["format_value"]}*`);
                } else {
                    setValue("message", message + ` *${t["format_value"]}*`);
                }
                break;
            case 'italic':
                if (message.endsWith(' ') || message === '') {
                    setValue("message", message + `_${t["format_value"]}_`);
                } else {
                    setValue("message", message + ` _${t["format_value"]}_`);
                }
                break;
            case 'strikethrough':
                if (message.endsWith(' ') || message === '') {
                    setValue("message", message + `~${t["format_value"]}~`);
                }
                else {
                    setValue("message", message + ` ~${t["format_value"]}~`);
                }
                break;
            case 'code':
                if (message.endsWith(' ') || message === '') {
                    setValue("message", message + `\`\`\`${t["format_value"]}\`\`\``);
                }
                else {
                    setValue("message", message + ` \`\`\`${t["format_value"]}\`\`\``);
                }
                break;
            default:
                throw new Error('Invalid format type');
        }
    }

    function addEmoji(e: any) {
        let emoji = e.native;
        if (message.endsWith(' ') || message === '') {
            setValue("message", message + emoji);
        } else {
            setValue("message", message + ' ' + emoji);
        }
        setOpenEmoji(false);
    }

    return (
        <div className={classes.customInput}>
            <div className={classes.inputBar}>
                <textarea
                    disabled={isView}
                    {...register('message')}
                    placeholder={t['campaign_input_placeholder']}
                    className={classes.textArea}
                />
            </div>
            <div className={classes.bottomBar}>
                <div className={classes.bottomButtons}>
                    {
                        <div
                            className={classes.inputCounter}
                            style={{
                                color:
                                    (message || "").length > 1024
                                        ? 'red'
                                        : undefined,
                            }}
                        >
                            <span className={classes.inputText}>
                                {(message.length || 0) >
                                    1024
                                    ? 1024
                                    : message.length || 0}
                                /1024
                            </span>
                            <InfoIcon className={classes.inputIcon} />
                        </div>
                    }

                    <div
                        style={{
                            width: '35px !important',
                            height: '35px !important',
                        }}
                    >
                        <ButtonBasedPopup
                            isDisabled={isView}
                            anchor={{
                                vertical: 20,
                                horizontal: 40,
                            }}
                            transfer={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            button={
                                <IconButton
                                    disabled={isView}
                                    style={{
                                        width: 35,
                                        height: 30,
                                    }}
                                >
                                    <SentimentSatisfiedOutlinedIcon />
                                </IconButton>
                            }
                            open={openEmoji}
                            onOpen={() => setOpenEmoji(true)}
                            onClose={() => setOpenEmoji(false)}
                            elevation={12}
                        >
                            <Grid style={{ paddingBottom: 0 }}>
                                <Picker
                                    onSelect={addEmoji}
                                    style={{
                                        zIndex: '99999',
                                    }}
                                    title={t['emoji_selector_title']}
                                    exclude={[
                                        'search',
                                        'places',
                                        'foods',
                                        'activity',
                                        'places',
                                        'objects',
                                        'symbols',
                                    ]}
                                    i18n={{
                                        search: t['emoji_selector_search'],
                                        notfound: t['emoji_selector_not_found'],
                                        categories: {
                                            // search: 'Arama Sonuçları',
                                            // recent: 'Sık Kullanılan',
                                            // smileys: 'Yüz ve Duygu İfadeleri',
                                            people: t['emoji_selector_category_people'],
                                            nature: t['emoji_selector_category_nature'],
                                            // foods: 'Yemek ve İçecek',
                                            // activity: 'Aktivite',
                                            // places: 'Seyehat ve Yerler',
                                            // objects: 'Objeler',
                                            // symbols: 'Semboller',
                                            flags: t['emoji_selector_category_flags'],
                                            // custom: 'Özel',
                                        }
                                    }}
                                />
                            </Grid>
                        </ButtonBasedPopup>
                    </div>
                    <IconButton
                        disabled={isView}
                        onClick={() => addFormat('bold')}
                        style={{
                            width: 35,
                            height: 30,
                        }}
                    >
                        <FormatBold />
                    </IconButton>
                    <IconButton
                        disabled={isView}
                        onClick={() => addFormat('italic')}
                        style={{
                            width: 35,
                            height: 30,
                        }}
                    >
                        <FormatItalic />
                    </IconButton>
                    <IconButton
                        disabled={isView}
                        onClick={() => addFormat('strikethrough')}
                        style={{
                            width: 35,
                            height: 30,
                        }}
                    >
                        <StrikethroughS />
                    </IconButton>
                    <IconButton
                        disabled={isView}
                        onClick={() => addFormat('code')}
                        style={{
                            width: 35,
                            height: 30,
                        }}
                    >
                        <Code />
                    </IconButton>
                    <Button
                        disabled={isView}
                        onClick={addVariable}
                        className={classes.addMedia}
                    >
                        {t['settings_whatsapp_business_template_add_variable']}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default WithUrlTranslations(CustomInput);

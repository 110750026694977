import {
   CircularProgress,
   Divider,
   Grid,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/styles';
import React, { useContext } from 'react';
import { DashboardCommentMainIcon } from '@assets/icons/DashboardIcons/dashboardIcons';
import { LanguageContext } from '@helper/locale/langContext';
import MyTypography from '@components/MyTypography';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import clsx from 'clsx';

const CIRCULAR_PROGRESS_SIZE = 36;
const useStyles = makeStyles((theme) => ({
   root: {
      padding: theme.spacing(1),
      background: '#fff',
      borderRadius: 8,
   },
   headerContainer: {
      height: 40,
      marginLeft: 4,
   },
   mainIcon: {
      width: 25,
      height: 25,
   },
   titleText: {
      paddingLeft: theme.spacing(1.3),
   },
   divider: {
      width: '100%',
      marginTop: theme.spacing(1),
   },
   rootTableContainer: {
      minHeight: '250px',
   },
   rootTableBody: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      height: 50,
      marginTop: 4,
      '& > td:first-child': {
         borderTopLeftRadius: '10px',
         borderBottomLeftRadius: '10px',
      },

      '& > td:last-child': {
         borderTopRightRadius: '10px',
         borderBottomRightRadius: '10px',
      },
   },
   table: {
      borderSpacing: '0 5px !important',
      borderCollapse: 'separate !important',
      '.MuiTableRow-root td:first-child': {
         borderTopLeftRadius: '10px',
         borderBottomLeftRadius: '10px',
      },

      '.MuiTableRow-root td:last-child': {
         borderTopRightRadius: '10px',
         borderBottomRightRadius: '10px',
      },
   },
   tableHeadCell: {
      padding: '10px 16px 6px 16px',
   },
   bold: {
      fontWeight: 'bold',
   },
   hover: {
      opacity: 0.8,
   },
   loading: {
      position: 'absolute',
      height: CIRCULAR_PROGRESS_SIZE * 2,
      width: CIRCULAR_PROGRESS_SIZE * 2,
      top: `calc(50% - ${CIRCULAR_PROGRESS_SIZE}px)`,
      left: `calc(50% - ${CIRCULAR_PROGRESS_SIZE}px)`,
      backgroundColor: '#FFFFFF',
      borderRadius: '10px',
   },
}));

const StyledTableCell = withStyles({
   root: {
      borderBottom: 'none',
   },
})(TableCell);

const Text = ({ children }) => {
   const classes = useStyles();
   return (
      <MyTypography className={classes.title} variant={'body2'}>
         {children}
      </MyTypography>
   );
};

const LeftSection = ({ data, loading }) => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const ROWS = [
      {
         title: t?.waiting,
         color: '#67CEAA',
         today: data?.today.receivedCount,
         yesterday: data?.yesterday.receivedCount,
         thisWeek: data?.thisWeek.receivedCount,
         thisMonth: data?.thisMonth.receivedCount,
      },
      {
         title: t?.replied,
         color: '#368EFB',
         today: data?.today.repliedCount,
         yesterday: data?.yesterday.repliedCount,
         thisWeek: data?.thisWeek.repliedCount,
         thisMonth: data?.thisMonth.repliedCount,
      },
      {
         title: t?.not_replied,
         color: '#F56B7B',
         today: data?.today.notReplied,
         yesterday: data?.yesterday.notReplied,
         thisWeek: data?.thisWeek.notReplied,
         thisMonth: data?.thisMonth.notReplied,
      },
      {
         title: t?.total,
         color: '#1D2935',
         today: data?.today.receivedCount + data?.today.repliedCount + data?.today.notReplied,
         yesterday: data?.yesterday.receivedCount + data?.yesterday.repliedCount + data?.yesterday.notReplied,
         thisWeek: data?.thisWeek.receivedCount + data?.thisWeek.repliedCount + data?.thisWeek.notReplied,
         thisMonth: data?.thisMonth.receivedCount + data?.thisMonth.repliedCount + data?.thisMonth.notReplied,
      },
   ];

   return (
      <Grid container className={classes.root}>
         <Grid xs={12} alignItems='center' item container className={classes.headerContainer}>
            <DashboardCommentMainIcon className={classes.mainIcon} />
            <MyTypography bold variant={'body1'} className={classes.titleText}>
               {t?.comments}
            </MyTypography>
            <Divider className={classes.divider} />
         </Grid>

         {/* Table Data */}
         <Grid container item xs={12}>
            <TableContainer classes={{ root: classes.rootTableContainer }}>
               <Table aria-label={'simple-table'} className={classes.table}>
                  <TableHead>
                     <TableRow>
                        <StyledTableCell className={clsx(classes.bold, classes.tableHeadCell)}>
                           {' '}
                           {t?.status}:
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableHeadCell}>
                           <Text>{t?.page_dashboard_typo_today}</Text>
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableHeadCell}>
                           <Text>{t?.page_dashboard_typo_yesterday}</Text>
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableHeadCell}>
                           <Text>{t?.page_dashboard_typo_this_week}</Text>
                        </StyledTableCell>
                        <StyledTableCell className={classes.tableHeadCell}>
                           <Text>{t?.page_dashboard_typo_this_month}</Text>
                        </StyledTableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody style={{ position: 'relative' }}>
                     {ROWS.map((row) => (
                        <TableRow key={row.title} classes={{ root: classes.rootTableBody, hover: classes.hover }}>
                           <StyledTableCell>
                              <Grid spacing={2} container alignItems='center'>
                                 <FiberManualRecordIcon style={{ color: row.color, fontSize: '2.5rem' }} />
                                 <MyTypography variant={'subtitle2'}>{row.title}</MyTypography>
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell className={classes.bold}>
                              {isNaN(row.today) ? undefined : row.today}
                           </StyledTableCell>
                           <StyledTableCell className={classes.bold}>
                              {isNaN(row.yesterday) ? undefined : row.yesterday}
                           </StyledTableCell>
                           <StyledTableCell className={classes.bold}>
                              {isNaN(row.thisWeek) ? undefined : row.thisWeek}
                           </StyledTableCell>
                           <StyledTableCell className={classes.bold}>
                              {isNaN(row.thisMonth) ? undefined : row.thisMonth}
                           </StyledTableCell>
                        </TableRow>
                     ))}
                     {loading && (
                        <Grid container className={classes.loading} justifyContent={'center'} alignItems={'center'}>
                           <CircularProgress size={CIRCULAR_PROGRESS_SIZE} color={'primary'} />
                        </Grid>
                     )}
                  </TableBody>
               </Table>
            </TableContainer>
         </Grid>
      </Grid>
   );
};

export default LeftSection;

import ButtonBasedPopup from '@components/ButtonBasedPopup';
import { LanguageContext } from '@helper/locale/langContext';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import InfoIcon from '@material-ui/icons/Info';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import { Picker } from 'emoji-mart';
import React, { useContext } from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';

const useStyles = makeStyles(() => ({
   customInput: {
      width: '100%',
      height: 260,
      borderRadius: 5,
      border: '2px solid #D1D1D1',
      marginTop: '10px',
      marginBottom: '10px',
   },
   inputBar: {
      height: '85%',
      width: '100%',
      borderBottom: '1px solid #D1D1D1',
      '& .MuiPopover-paper': {
         backgroundColor: 'red',
         color: 'yellow',
      },
   },
   textArea: {
      resize: 'none',
      fontSize: '14.7px',
      width: '100%',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      height: 217,
      border: 'none',
      paddingLeft: 10,
      paddingTop: 10,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      '&:focus-visible': {
         border: 'none !important',
         outline: 'none !important',
      },
      '&:focus': {
         border: 'none !important',
         outline: 'none !important',
      },
   },
   addMedia: {
      width: 90,
      height: 30,
      border: '1px dashed #D1D1D1',
      padding: 0,
      paddingTop: 3,
      fontSize: 13,
   },
   bottomBar: {
      padding: 5,
      height: '15%',
      width: '100%',
   },
   bottomButtons: {
      display: 'flex',
   },
   variables: {
      width: 95,
      height: 30,
      padding: 2,
      marginLeft: '5px !important',
      marginTop: '0px !important',
      border: '1px dashed #D1D1D1',
      '& .Mui-underline:after': {
         outline: 'none',
         border: 'none !important',
      },
      '& .MuiSelect-select': {
         outline: 'none',
         fontSize: 13,
         padding: 3,
      },
      '&:active': {
         outline: 'none',
      },
      '& .MuiInput-root::before': {
         border: 'none !important',
      },
      '& .MuiInput-root::before:focus-visible': {
         border: 'none !important',
      },
      '& .MuiInput-root:hover': {
         border: 'none !important',
      },
      '& .MuiInput-root:focus-visible': {
         border: 'none !important',
      },
      '& .MuiInput-root:focus': {
         border: 'none !important',
      },
      '& .MuiInput-underline:after': {
         border: 'none !important',
         underline: 'none !important',
      },
   },
   inputCounter: {
      fontSize: '0.875rem',
      textAlign: 'end',
      width: 90,
      color: '#A0A0A0',
      height: 20,
      lineHeight: 2,
      flexGrow: 1,
   },
   inputText: {
      fontSize: '0.875rem',
   },
   inputIcon: {
      fontSize: '0.875rem',
      width: 15,
      height: 15,
      marginLeft: 2,
      verticalAlign: 'text-top',
   },
}));

const MenuProps = {
   anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
   },
   getContentAnchorEl: null,
   transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
   },
   style: {
      marginLeft: 3,
      '&:hover': {
         backgroundColor: 'red',
      },
   },
};

const CustomInput = ({ initialValue, onTokenChange, handleUploadFiles, disabled }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [openEmoji, setOpenEmoji] = React.useState(false);

   let tokens = [
      'tokens_first_name',
      'tokens_last_name',
      'tokens_email',
      'tokens_phone',
      'tokens_survey',
   ];

   let onKeyUp = (event) => {
      let value = event.target.value;
      /*const wrap = (s, w) =>{
         let regex = new RegExp(`\\S.{0,${w}}(?=\\s+|$)`, 'g');
         let chunks = s.match(regex);
         return chunks ? chunks.join('\n') : '';
      }*/
      const wrap = (s, w) => s.replace(new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n');
      value = wrap(value, 37);
      let new_value = value;

      tokens.forEach((token) => {
         let local = t[token];
         new_value = new_value.replaceAll(local, `<span style="color: #147CFC;">${local}</span>`);
      });

      onTokenChange(value, new_value);
   };

   function addEmoji(e) {
      let emoji = e.native;
      let text_area = document.getElementById('(text-area)');
      if (text_area.value.endsWith(' ')) {
         text_area.value += emoji;
      } else {
         text_area.value += ' ' + emoji;
      }
      onKeyUp({ target: text_area });
      setOpenEmoji(false);
   }

   return (
      <div className={classes.customInput}>
         <div className={classes.inputBar}>
            <textarea
               disabled={disabled}
               placeholder={t['campaign_input_placeholder']}
               defaultValue={initialValue}
               onKeyUp={onKeyUp}
               id='(text-area)'
               className={classes.textArea}
               ref={(element) => element?.focus?.()}
            />
         </div>
         <div className={classes.bottomBar}>
            <div className={classes.bottomButtons}>
               <Button
                  disabled={disabled}
                  onClick={() => document.getElementById('selectFile').click()}
                  className={classes.addMedia}
                  startIcon={<ImageIcon />}
               >
                  <input
                     id='selectFile'
                     hidden
                     multiple={true}
                     onChange={(e) => {
                        handleUploadFiles(e.target.files);
                        e.target.value = '';
                     }}
                     type='file'
                     accept={'.png, .jpg, .jpeg, .mp4, .pdf'}
                  />
                  {t['campaign_media']}
               </Button>
               <FormControl className={classes.variables}>
                  <Select
                     id='input_selector'
                     value={-1}
                     disabled={disabled}
                     onChange={(e) => {
                        let token = tokens[e.target.value];
                        let local = t[token];
                        let text_area = document.getElementById('(text-area)');
                        if (text_area.value.endsWith(' ')) {
                           text_area.value += local;
                        } else {
                           text_area.value += ' ' + local;
                        }
                        onKeyUp({ target: text_area });
                     }}
                     MenuProps={MenuProps}
                  >
                     <MenuItem disabled value={-1}>
                        {t['token_variables']}
                     </MenuItem>
                     <MenuItem value={0}>{t['tokens_first_name']}</MenuItem>
                     <MenuItem value={1}>{t['tokens_last_name']}</MenuItem>
                     <MenuItem value={2}>{t['tokens_email']}</MenuItem>
                     <MenuItem value={3}>{t['tokens_phone']}</MenuItem>
                     <MenuItem value={4}>{t['tokens_survey']}</MenuItem>
                  </Select>
               </FormControl>
               <div
                  style={{
                     width: '35px !important',
                     height: '35px !important',
                  }}
               >
                  <ButtonBasedPopup
                     isDisabled={disabled}
                     anchor={{
                        vertical: 20,
                        horizontal: 40,
                     }}
                     transfer={{
                        vertical: 'bottom',
                        horizontal: 'left',
                     }}
                     button={
                        <IconButton
                           disabled={disabled}
                           style={{
                              width: 35,
                              height: 30,
                           }}
                        >
                           <SentimentSatisfiedOutlinedIcon className={classes.iconColor} />
                        </IconButton>
                     }
                     open={openEmoji}
                     onOpen={() => setOpenEmoji(true)}
                     onClose={() => setOpenEmoji(false)}
                     elevation={12}
                  >
                     <Grid style={{ paddingBottom: 0 }}>
                        <Picker
                           onSelect={addEmoji}
                           style={{
                              zIndex: '99999',
                           }}
                           title={t['emoji_selector_title']}
                           exclude={['search', 'places', 'foods', 'activity', 'places', 'objects', 'symbols']}
                           i18n={{
                              search: t['emoji_selector_search'],
                              clear: t['emoji_selector_clear'], // Accessible label on "clear" button
                              notfound: t['emoji_selector_not_found'],
                              skintext: t['emoji_selector_skintext'],
                              frequently: t['emoji_selector_frequently'],
                              categories: {
                                 frequent: t['emoji_selector_frequent'],
                                 // search: 'Arama Sonuçları',
                                 // recent: 'Sık Kullanılan',
                                 // smileys: 'Yüz ve Duygu İfadeleri',
                                 people: t['emoji_selector_category_people'],
                                 nature: t['emoji_selector_category_nature'],
                                 // foods: 'Yemek ve İçecek',
                                 // activity: 'Aktivite',
                                 // places: 'Seyehat ve Yerler',
                                 // objects: 'Objeler',
                                 // symbols: 'Semboller',
                                 flags: t['emoji_selector_category_flags'],
                                 // custom: 'Özel',
                              },
                              categorieslabel: t['emoji_selector_categorieslabel'], // Accessible title for the list of categories
                              skintones: {
                                 1: t['emoji_selector_skintones_1'],
                                 2: t['emoji_selector_skintones_2'],
                                 3: t['emoji_selector_skintones_3'],
                                 4: t['emoji_selector_skintones_4'],
                                 5: t['emoji_selector_skintones_5'],
                                 6: t['emoji_selector_skintones_6'],
                              },
                           }}
                        />
                     </Grid>
                  </ButtonBasedPopup>
               </div>
               <div
                  className={classes.inputCounter}
                  style={{
                     color: document.getElementById('(text-area)')?.value?.length > 1024 ? 'red' : undefined,
                  }}
               >
                  <span className={classes.inputText}>
                     {(document.getElementById('(text-area)')?.value?.length || 0) > 1024
                        ? 1024
                        : document.getElementById('(text-area)')?.value?.length || 0}
                     /1024
                  </span>
                  <InfoIcon className={classes.inputIcon} />
               </div>
            </div>
         </div>
      </div>
   );
};

export default WithUrlTranslations(CustomInput);

import React, { memo, useContext, useMemo } from 'react';
import { LanguageContext } from "@helper/locale/langContext";
import { useSelector } from "react-redux";
import { getPerson } from "@store/reducers/person";
import { Avatar, ButtonBase, Chip, Typography } from "@material-ui/core";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import FormatListBulletedRoundedIcon from "@material-ui/icons/FormatListBulletedRounded";
import QueryBuilderRoundedIcon from "@material-ui/icons/QueryBuilderRounded";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import CheckCircleOutlineRoundedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import ErrorOutlineRoundedIcon from "@material-ui/icons/ErrorOutlineRounded";
import DeleteIcon from "@material-ui/icons/Delete";
import GroupRoundedIcon from "@material-ui/icons/GroupRounded";
import ChatListTabsProps, { ChatListTab } from "@components/ChatListTabs/ChatListTabs.types";
import {
  getCustomerTeamMembersQuery_customerTeams_data
} from "@queries/Person/Service/GraphQL/__generated__/getCustomerTeamMembersQuery";
import { MyTheme } from "@styles/config";
import { ChatType, DmChatStatus, QnaChatStatus } from "../../types/enums";
import { BotLogo } from '@modules/Settings/SettingSpace/LiveChat/SharedComponents/Icons';

const useStyles = makeStyles<MyTheme>((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'stretch',
    height: 50,
  },
  iconButtonContainer: {
    display: 'flex',
    flexShrink: 0,
    flexBasis: 42,
  },
  buttonsContainer: {
    display: 'flex',
    flexGrow: 1,
    width: 'calc(100% - 42px)',
    gap: 10,
  },
  buttonContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    flex: 1,
    flexBasis: 0,
    minWidth: 0,
  },
  button: {
    height: 42,
    borderRadius: 10,
    flexGrow: 1,
    flexWrap: 'nowrap',
    padding: '2px 8px',
    overflow: 'hidden',
    transition: 'all .3s',
  },
  button1: {
    color: '#FFF',
    border: '1px solid #157CFC',
    backgroundColor: '#157CFC',
    '&:hover': {
      backgroundColor: '#116ada',
      border: '1px solid #1260c1',
    },
  },
  button2: {
    color: theme.chatApp.general.pallet.passiveStructureBlue,
    border: '1px solid #e8e9ed',
    backgroundColor: '#FFF',
    '&:hover': {
      backgroundColor: '#e8e9ed',
    }
  },
  buttonLabel: {
    textTransform: 'capitalize',
    marginLeft: 5,
    fontSize: '1.2em',
  },
  filterIcon: {
    width: 25,
    height: 25,
  },
  allUsersIconContainer: {
    flexShrink: 0,
    width: 30,
    height: 30,
    borderRadius: 30,
    backgroundColor: '#bdbdbd',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  allUsersIcon: {
    width: 20,
    height: 20,
    color: '#FAFAFA',
  },
  avatar: {
    width: 25,
    height: 25,
  },
  chip: {
    backgroundColor: '#FAFAFA',
    position: 'absolute',
    height: 18,
    top: -8,
    right: 0,
    "-webkit-box-shadow": "0px 1px 4px -2px black",
    "-moz-box-shadow": "0px 1px 4px -2px black",
    "box-shadow": "0px 1px 4px -2px black",
    color: "#385273"
  },
  chipSelected: {
    backgroundColor: '#157CFC',
    color: '#FFF',
  },
}));

const StatusIcon = ({ chatType, status }: { chatType: ChatType, status: DmChatStatus | QnaChatStatus}) => {
  if (chatType === ChatType.DM) {
    switch (status) {
      case DmChatStatus.ALL:
        return <FormatListBulletedRoundedIcon />;
      case DmChatStatus.ACTIVE:
        return <QueryBuilderRoundedIcon />;
      case DmChatStatus.TIMED_OUT:
        return <FolderOpenRoundedIcon />;
      case DmChatStatus.SOLVED:
        return <CheckCircleOutlineRoundedIcon />;
      case DmChatStatus.SPAM:
        return <ErrorOutlineRoundedIcon />;
      case DmChatStatus.DELETED:
        return <DeleteIcon />;
      case DmChatStatus.BotIsJoined:
          return <BotLogo size="24px"/>;
      default:
        return null;
    }
  }
  if (chatType === ChatType.QNA) {
    switch (status) {
      case QnaChatStatus.ALL:
        return <FormatListBulletedRoundedIcon />;
      case QnaChatStatus.WAITING:
        return <QueryBuilderRoundedIcon />;
      case QnaChatStatus.SOLVED:
        return <CheckCircleOutlineRoundedIcon />;
      case QnaChatStatus.SPAM:
        return <ErrorOutlineRoundedIcon />;
      case QnaChatStatus.TIMED_OUT:
        return <FolderOpenRoundedIcon />;
      case QnaChatStatus.DELETED:
        return <DeleteIcon />;
      default:
        return null;
    }
  }
  return null
};


const getStatusLabel = (chatType: ChatType, status: DmChatStatus | QnaChatStatus,t: any) => {
  if (chatType === ChatType.DM) {
    switch (status) {
      case DmChatStatus.ALL:
        return t['page_chat_all_status_tab_label'];
      case DmChatStatus.ACTIVE:
        return t['page_chat_waiting_status_tab_label'];
      case DmChatStatus.TIMED_OUT:
        return t['page_chat_archived_status_tab_label'];
      case DmChatStatus.SOLVED:
        return t['page_chat_closed_status_tab_label'];
      case DmChatStatus.SPAM:
        return t['page_chat_spam_status_tab_label'];
      case DmChatStatus.DELETED:
        return t['page_chat_deleted_status_tab_label'];
      case DmChatStatus.BotIsJoined:
        return t['page_chat_typo_filter_title_bot_is_joined'];
      default:
        return '';
    }
  }
  if (chatType === ChatType.QNA) {
    switch (status) {
      case QnaChatStatus.ALL:
        return t['page_chat_all_status_tab_label'];
      case QnaChatStatus.WAITING:
        return t['page_chat_waiting_status_tab_label'];
      case QnaChatStatus.SOLVED:
        return t['page_chat_closed_status_tab_label'];
      case QnaChatStatus.SPAM:
        return t['page_chat_spam_status_tab_label'];
      case QnaChatStatus.TIMED_OUT:
        return t['page_chat_archived_status_tab_label'];
      case QnaChatStatus.DELETED:
        return t['page_chat_deleted_status_tab_label'];
      default:
        return '';
    }
  }
};

const AgentAvatar = memo(({ ids, agents }: { ids: string; agents: ChatListTabsProps['agents']}) => {
  const classes = useStyles();
  const me = useSelector(getPerson);
  if (!ids || ids.includes(',')) {
    return (
      <div className={classes.allUsersIconContainer}>
        <GroupRoundedIcon  className={classes.allUsersIcon} />
      </div>
    )
  } else if (ids === me?._id) {
    return <Avatar src={me?.photo} className={classes.avatar} />
  } else {
    const agent = agents?.find(agent => ids === agent._id);
    if (agent) {
      return <Avatar src={agent.photo || ''} className={classes.avatar} />
    } else return null;
  }
});


const getAgentDisplayName = (agent: getCustomerTeamMembersQuery_customerTeams_data | undefined) => {
  if (agent) {
    if (agent.name && agent.surname) {
      return `${agent.name} ${agent.surname}`;
    } else if (agent.name) {
      return agent.name;
    } else if (agent.username) {
      return agent.username;
    } else return '';
  }
  return '';
};

const ChatListTabs = ({ chatType, chatFilters, agents, selectedTab, setSelectedTab, companyChatCount, mineChatCount }: ChatListTabsProps) => {
  const classes = useStyles();
  const { lang } = useContext(LanguageContext);
  const t = lang?.translation;
  const me = useSelector(getPerson);

  const agentName = useMemo(() => {
    if (!chatFilters?.me || chatFilters?.me.includes(',')) {
      return t['page_chat_typo_filter_title_all_users'];
    } else  if (chatFilters?.me === me?._id) {
      return t['page_chat_typo_filter_title_mine'];
    }  else {
      const agent = agents?.find(agent => chatFilters?.me === agent._id);
      return getAgentDisplayName(agent);
    }
  }, [chatFilters?.me, agents, me, t]);

  return (
    <div className={classes.container}>
      <div className={classes.buttonsContainer} style={{ marginTop: companyChatCount || mineChatCount ? 8 : 0 }}>
        <div className={classes.buttonContainer} style={{ position: 'relative' }}>
          <ButtonBase
            disableRipple
            onClick={() => setSelectedTab(ChatListTab.COMPANY)}
            className={classNames(classes.button, selectedTab === ChatListTab.COMPANY ? classes.button1 : classes.button2)}
          >
            <StatusIcon chatType={chatType} status={chatFilters.status} />
            <Typography noWrap variant='button' className={classes.buttonLabel}>
              {getStatusLabel(chatType, chatFilters.status, t)}
            </Typography>
          </ButtonBase>
          {companyChatCount && companyChatCount != "0" &&
            <Chip className={classNames(classes.chip, selectedTab == ChatListTab.COMPANY && classes.chipSelected)} label={companyChatCount} />
          }
        </div>
        <div className={classes.buttonContainer} style={{ position: 'relative' }}>
          <ButtonBase
            disableRipple
            onClick={() => setSelectedTab(ChatListTab.YOURS)}
            className={classNames(classes.button, selectedTab === ChatListTab.YOURS ? classes.button1 : classes.button2)}
          >
            <AgentAvatar ids={chatFilters?.me} agents={agents} />
            <Typography noWrap variant='button' className={classes.buttonLabel}>
              {agentName}
            </Typography>
          </ButtonBase>
          {
            mineChatCount && mineChatCount != "0" &&
            <Chip className={classNames(classes.chip, selectedTab == ChatListTab.YOURS && classes.chipSelected)} label={mineChatCount} />
          }
        </div>
      </div>
    </div>
  );
};

export default ChatListTabs;

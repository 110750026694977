import React, { Fragment, useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { LanguageContext } from '@helper/locale/langContext';
import { GET_CUSTOMER_INVOICES_QUERY } from '../../../../queries/Customer/Service/GraphQL/Customer/query';
import { useQuery } from '@apollo/client';
import InvoiceTable from './invoiceTable';

const useStyles = makeStyles((theme) => ({
   table: {
      //minWidth: 700,
   },
   titleContainer: {
      color: '#385273',
      borderRadius: 15,
      padding: 20,
      backgroundColor: 'white',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: '1px solid #F7F7FA',
   },
   title: {
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
   },
}));

const MyFactors = () => {
   const classes = useStyles();
   const history = useHistory();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [tableData, setTableData] = useState([]);

   const { loading } = useQuery(GET_CUSTOMER_INVOICES_QUERY, {
      fetchPolicy: 'network-only',
      variables: {
         customerId: person.customer._id,
      },
      onCompleted: (data) => {
         if (data) {
            // i dont know why data is not extensible?
            const tData = data?.getCustomerInvoices?.data.map((item) => ({ ...item }));
            setTableData(tData);
         }
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div
                  style={{
                     color: '#000',
                     fontSize: '13px',
                     cursor: 'pointer',
                     fontWeight: '500',
                  }}
                  onClick={() => closeSnackbar(key)}
               >
                  {' '}
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, closeSnackbar, enqueueSnackbar, history]);

   if (loading) {
      return <div className='loading'></div>;
   }

   return (
      <Fragment>
         <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
            <Grid item>
               <Typography className={classes.title}> {t?.my_bills}</Typography>
            </Grid>
         </Grid>
         <Grid container xs={12}>
            <InvoiceTable data={tableData.reverse()} />
         </Grid>
      </Fragment>
   );
};

export default MyFactors;

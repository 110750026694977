import React from 'react';

const EmailIcon = ({ selected }) => {
   const color1 = selected ? '#e65a4d' : '#c7cdd6';
   return (
      <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 674.67 530'>
         <path
            d='M577.27,0H97.4A97.51,97.51,0,0,0,0,97.4V432.6A97.51,97.51,0,0,0,97.4,530H577.27a97.52,97.52,0,0,0,97.4-97.4V97.4A97.51,97.51,0,0,0,577.27,0Zm0,46.56a50.75,50.75,0,0,1,11.57,1.38L386.39,277.65a65.38,65.38,0,0,1-98.1,0L85.84,47.94A50.66,50.66,0,0,1,97.4,46.56Zm50.84,386a50.9,50.9,0,0,1-50.84,50.84H97.4A50.9,50.9,0,0,1,46.56,432.6V97.4a50.48,50.48,0,0,1,3.82-19.27l203,230.31a112,112,0,0,0,168,0l203-230.3a50.61,50.61,0,0,1,3.81,19.26Z'
            fill={color1}
         />
      </svg>
   );
};

export default EmailIcon;

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import LineChart from './lineChart';
import Toolbar from '../toolbar';
import Chip from '../chip';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { NetworkStatus, useQuery } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import Error from '@components/Errors/Error';
import { calculateTimeDifference } from '@helper/functions';
import { GET_CHATS_STATISTICS_QUERY } from '@queries/Statistics/Service/GraphQL/query';
// const platformCodes = ['whatsAppWeb', 'whatsAppBusiness', 'facebook', 'instagram'];
const getColumns = (t) => [
   {
      title: t['page_chat_typo_filter_title_active'],
      value: 0,
   },
   {
      title: t['page_chat_typo_filter_title_waiting'],
      value: 0,
   },
   {
      title: t['page_chat_typo_filter_title_closed'],
      value: 0,
   },
   {
      title: t['page_chat_typo_filter_title_resolved'],
      value: 0,
   },
   {
      title: t['page_chat_typo_filter_title_spam_blocked'],
      value: 0,
   },
];

const useStyles = makeStyles((theme) => ({
   emptyList: {
      fontSize: '1.3rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      marginTop: 40,
   },
}));

const Contacts = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const CONTACTS_STATISTICS_DATA = getColumns(t);
   const [state, setState] = useState({
      // startDate: moment().subtract(1, 'day').valueOf(),
      startDate: moment().valueOf(),
      endDate: moment().valueOf(),
      platform: 'ALL',
      totalReceived: 0,
      totalSent: 0,
      chartData: [],
   });

   const {
      data: chatsStatisticsData,
      loading,
      error,
      refetch,
      networkStatus,
   } = useQuery(GET_CHATS_STATISTICS_QUERY, {
      variables: {
         customer: person.customer._id,
         startDate: state.startDate,
         endDate: state.endDate,
         platform: state.platform,
      },
      fetchPolicy: 'network-only',
   });

   const handleChangeFilterDate = (startDate, endDate) => {
      setState((prevState) => ({
         ...prevState,
         startDate,
         endDate,
      }));
   };

   const handleChangePlatform = (platform) => {
      setState((prevState) => ({ ...prevState, platform }));
   };

   if (chatsStatisticsData?.getChatsStatistics) {
      CONTACTS_STATISTICS_DATA[0].value = chatsStatisticsData?.getChatsStatistics?.data?.activeChatsCount;
      CONTACTS_STATISTICS_DATA[1].value = chatsStatisticsData?.getChatsStatistics?.data?.waitingChatsCount;
      CONTACTS_STATISTICS_DATA[2].value = chatsStatisticsData?.getChatsStatistics?.data?.closedChatsCount;
      CONTACTS_STATISTICS_DATA[3].value = chatsStatisticsData?.getChatsStatistics?.data?.solvedChatsCount;
      CONTACTS_STATISTICS_DATA[4].value = chatsStatisticsData?.getChatsStatistics?.data?.spammedChatsCount;
   }

   const showChartReportUI = (chats) => {
      if (
         chats?.activeChatsCount === 0 &&
         chats?.waitingChatsCount === 0 &&
         chats?.closedChatsCount === 0 &&
         chats?.solvedChatsCount === 0 &&
         chats?.spammedChatsCount === 0
      ) {
         return false;
      }
      return true;
   };

   useEffect(() => {
      refetch();
   }, [state, refetch]);

   const loadingOrFetching = networkStatus === NetworkStatus.refetch || loading;

   if (loadingOrFetching) {
      return <div className='loading' />;
   }

   return (
      <Fragment>
         <Fragment>
            <Toolbar
               onPlatformChange={handleChangePlatform}
               platform={state.platform}
               onDateChange={handleChangeFilterDate}
               startDate={state.startDate}
               endDate={state.endDate}
               title={t['page_statistics_typo_customers']}
               operation={'getChatsStatistics'}
               params={{
                  customer: person.customer._id,
                  startDate: state.startDate,
                  endDate: state.endDate,
                  platform: state.platform,
               }}
            />
            <Grid container direction='column' style={{ padding: 20 }}>
               <Grid container justifyContent='space-between' alignItems='center'>
                  {CONTACTS_STATISTICS_DATA.map((item) => (
                     <Grid key={item.title} item xs={2}>
                        <Chip title={item.title} value={item.value} />
                     </Grid>
                  ))}
               </Grid>
               {showChartReportUI(chatsStatisticsData?.getChatsStatistics?.data) ? (
                  <Fragment>
                     {!error && (
                        <Grid item style={{ marginTop: 30 }}>
                           <LineChart
                              chartData={
                                 chatsStatisticsData?.getChatsStatistics
                                    ? chatsStatisticsData?.getChatsStatistics?.data.chartReport
                                    : []
                              }
                              mode={calculateTimeDifference(state.startDate, state.endDate)}
                           />
                        </Grid>
                     )}
                  </Fragment>
               ) : (
                  <Grid xs={12} container justifyContent='center' style={{ height: 100 }}>
                     <Typography className={classes.emptyList}>{t?.your_contact_list_is_empty}</Typography>{' '}
                  </Grid>
               )}
            </Grid>
         </Fragment>
         <Error show={!!error} message={error?.message} />
      </Fragment>
   );
};

export default Contacts;

import React, { useContext, useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MenuItem, Typography, CircularProgress, useMediaQuery } from '@material-ui/core';
import { useLazyQuery, useQuery } from '@apollo/client';
import { GET_QUICK_ANSWERS_QUERY } from '../../../../queries/Settings/Service/GraphQL/QuickAnswers/query';
import { useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { useHistory } from 'react-router-dom';
import { ChatQuickAnswerIcon, ChatQuickAnswerArrowIcon } from '@assets/icons/ChatPageIcons';
import AddIcon from '@material-ui/icons/Add';
import { trimLongText } from '@helper/textUtils';
import clsx from 'clsx';
import theme, { MyTheme } from '@styles/config';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchBar from '@components/SearchBar';

interface Props {
   onSend: (quickAnswer: string) => void;
   filterAnswerList: string | null;
}

const ITEM_HEIGHT = 60;

const useStyles = makeStyles((theme: MyTheme) => ({
   root: {
      overflowY: 'auto',
      maxHeight: 600,
      minHeight: 200,
      backgroundColor: '#fff',
   },
   quickAnswerIcon: {
      width: 20,
      height: 20,
      textAlign: 'center',
      opacity: 0.7,
      [theme.breakpoints.down('xs')]: {
         display: 'none',
      },
   },
   quickAnswerContainer: {
      height: ITEM_HEIGHT * 5,
      [theme.breakpoints.down('xl')]: {
         height: ITEM_HEIGHT * 5,
      },

      [theme.breakpoints.down('md')]: {
         height: ITEM_HEIGHT * 3,
      },
      [theme.breakpoints.down('xs')]: {
         height: ITEM_HEIGHT * 2,
      },
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      overflowY: 'hidden',
      overflowX: 'hidden',
   },
   titleContainer: {
      padding: 10,
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 4,
      borderBottom: '2px solid #eee',
      height: 70,
   },
   title: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.3rem',
      marginLeft: 10,
   },
   answerContainer: {
      maxHeight: 40,
   },
   selectedIcon: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginLeft: 20,
   },
   notSelectedIcon: {
      color: 'grey',
      marginLeft: 20,
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   container: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
   },
   text: {
      fontSize: '0.9rem',
      color: 'grey',
      wordBreak: 'break-all',
   },
   arrowIcon: {
      width: 20,
      height: 20,
      fill: 'grey',
   },
   arrowIconBlue: {
      fill: theme.chatApp.general.pallet.passiveStructureAzure,
   },
   menuItem: {
      '&:hover': {
         backgroundColor: '#F7F7FA',
      },
      width: '100%',
      height: 60,
      flexShrink: 0,
      borderBottom: '1px solid #eee',
   },
}));

const Page = {
   pageSize: 15,
   page: 1,
};

interface IquickAnswerParam {
   customer: any;
   pageSize: number;
   page: number;
   search: any;
}

const QuestionQuickAnswer: React.FC<Props> = ({ onSend, filterAnswerList }) => {
   const [hoveredIndex, setHoveredIndex] = useState(-1);
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);

   const t: any = lang?.translation;
   const history = useHistory();
   const me = useSelector((reducer: any) => reducer.personReducer.person);

   const isXS = useMediaQuery(theme.breakpoints.down('xs'));
   const isMD = useMediaQuery(theme.breakpoints.down('md'));
   const isXL = useMediaQuery(theme.breakpoints.down('xl'));
   let height = ITEM_HEIGHT * 5;
   if (isXL) height = ITEM_HEIGHT * 5;
   if (isMD) height = ITEM_HEIGHT * 3;
   if (isXS) height = ITEM_HEIGHT * 2;

   const customerId = me.customer._id;
   const [searchText, setSearchText] = useState<string | null>(null);
   const [answerList, setAnswerList] = useState<any>([]);
   const [quickAnswerCount, setQuickAnswerCount] = useState<number>(0);

   /* get quick answers */
   const [getQuickAnswer, { data: quickAnswersData }] = useLazyQuery(GET_QUICK_ANSWERS_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setQuickAnswerCount(data.quickAnswers.data.docsCount);
         if (searchText === null || searchText.length > 2 || searchText === '') {
            // setAnswerList([]);
            setAnswerList([...answerList, ...data.quickAnswers.data.docs]);

            return;
         }
         if (searchText !== null && searchText.length <= 2) {
            setAnswerList([...answerList, ...data.quickAnswers.data.docs]);
         }
      },
   });

   const [quickAnswerParam, setQuickAnswerParam] = useState<IquickAnswerParam>({
      customer: customerId,
      pageSize: Page.pageSize,
      page: Page.page,
      search: null,
   });

   useEffect(() => {
      if (searchText === null || searchText.length > 2 || searchText === '') {
         setAnswerList([]);
         if (searchText !== null) {
            setQuickAnswerParam((prev) => ({ ...prev, search: searchText, page: Page.page }));
         } else if (searchText === '') {
            setQuickAnswerParam((prev) => ({ ...prev, search: searchText, page: Page.page }));
         }
      }
   }, [searchText]);

   useEffect(() => {
      setSearchText(filterAnswerList);
   }, [filterAnswerList]);

   useEffect(() => {
      getQuickAnswer({
         variables: {
            ...quickAnswerParam,
         },
      });
   }, [quickAnswerParam]);

   const handleNextAnswerPage = () => {
      if (answerList.length < quickAnswerCount) {
         setQuickAnswerParam({
            ...quickAnswerParam,
            page: quickAnswerParam.page + 1,
         });
      }
   };

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   }, []);

   const handleAddQuickAnswer = () => {
      history.push('/settings/company/quick-answers');
   };
   // Loading State
   // if (loading) {
   //    return (
   //       <Grid
   //          xs={12}
   //          alignItems='center'
   //          justifyContent='center'
   //          direction='column'
   //          className={classes.root}
   //          container
   //       >
   //          <CircularProgress size={50} color='primary' />
   //          <Typography className={classes.title} style={{ marginTop: 30 }}>
   //             {t?.loading}
   //          </Typography>
   //       </Grid>
   //    );
   // }
   return (
      <Grid container xs={12} className={classes.root}>
         <Grid
            item
            container
            alignItems='center'
            direction='row'
            justifyContent='space-between'
            className={classes.titleContainer}
         >
            <Grid container alignItems='center' xs={8}>
               <ChatQuickAnswerIcon className={classes.quickAnswerIcon} />
               <Typography align='center' noWrap className={classes.title}>
                  {t['page_chat_typo_quick_answers_']}
               </Typography>
            </Grid>
            <Grid>
               <div>
                  <SearchBar
                     value={searchText}
                     onChange={setSearchText}
                     placeholder={t['search']}
                     style={{ width: 200, height: 40 }}
                  />
               </div>
            </Grid>
            {/* <Grid item xs={4}>
               <ButtonOK
                  label={t?.page_settings_button_add_new}
                  fontSize='1.1rem'
                  style={{ width: '100%' }}
                  height={35}
                  onClick={handleAddQuickAnswer}
                  endIcon={<AddIcon fontSize='small' />}
               />
            </Grid> */}
         </Grid>
         <Grid
            container
            justifyContent='flex-start'
            direction={quickAnswersData?.quickAnswers.data.docs.length > 4 ? undefined : 'column'}
            className={classes.quickAnswerContainer}
         >
            {quickAnswersData?.quickAnswers?.data?.docs?.length === 0 && (
               <Grid container justifyContent='center' alignItems='center'>
                  <Typography align='center' style={{ padding: 20, width: '100%' }} className={classes.text}>
                     {t?.there_is_no_quick_answer}
                  </Typography>
               </Grid>
            )}
            <InfiniteScroll
               scrollableTarget='scrollableDiv'
               dataLength={answerList?.length || 0}
               hasMore={answerList.length >= quickAnswerCount ? false : true}
               loader={<Loader />}
               next={handleNextAnswerPage}
               height={height}
            >
               {answerList.map((quickAnswer: any, index: any) => (
                  <MenuItem
                     key={quickAnswer._id}
                     onClick={() => onSend(quickAnswer.answer)}
                     className={classes.menuItem}
                  >
                     <Grid container direction='row'>
                        <Grid item xs={11} direction='column'>
                           <Typography
                              style={{
                                 textAlign: 'left',
                                 fontWeight: 'bold',
                              }}
                              noWrap
                           >
                              {trimLongText(quickAnswer.name, 30)}
                           </Typography>
                           <Grid item className={classes.answerContainer}>
                              <Typography noWrap className={classes.text}>
                                 {trimLongText(quickAnswer.answer, 38)}
                              </Typography>
                           </Grid>
                        </Grid>
                        <Grid xs={1} container justifyContent='flex-end' alignItems='center'>
                           <ChatQuickAnswerArrowIcon
                              className={clsx(classes.arrowIcon, hoveredIndex === index && classes.arrowIconBlue)}
                           />
                        </Grid>
                     </Grid>
                  </MenuItem>
               ))}
            </InfiniteScroll>
         </Grid>
      </Grid>
   );
};

export default QuestionQuickAnswer;

import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ChatSettings from '@modules/Settings/SettingSpace/ChatSettings';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import ChannelsAndIntegrations from './ChannelsAndIntegrations/channelsAndIntegrations';
import Email from './Email';
import EmailAccountSetting from './Email/accountSetting';
import FacebookMessenger from './FacebookMessenger/facebookMessenger';
import Faprika from './Faprika';
import HepsiBuradaQuestions from './HepsiBuradaQuestions';
import Ideasoft from './Ideasoft';
import InstagramDM from './InstagramDM/instagramDM';
import LiveChat from './LiveChat';
import N11Questions from './N11Questions';
import QuickAnswers from './QuickAnswers/quickAnswers';
import Shopify from './Shopify';
import SpecialTags from './SpecialTags/specialTags';
import Teams from './Teams';
import Telegram from './Telegram/index.tsx';
import Ticimax from './Ticimax';
import Ikas from './Ikas';
import TrendyolQuestions from './TrendyolQuestions';
import Tsoft from './Tsoft';
import Users from './Users/users';
import CreateNewTemplate from './WhatsAppBusiness/templatesPage/CreateNewTemplate';
import TemplatesPage from './WhatsAppBusiness/templatesPage/TemplatesPage';
import WhatsappBusiness from './WhatsAppBusiness/whatsappBusiness';
import WhatsappWeb from './WhatsAppWeb/whatsappWeb';
import WooCommerce from './WooCommerce';
import CustomerSatisfaction from './CustomerSatisfaction';
import SurveyPage from './CustomerSatisfaction/Survey/index';
import CustomFields from './CustomFields';

import MessageHours from './messageHours';
const useStyles = makeStyles((theme) => ({
   root: {
      height: '100%',
      width: '100%',
      borderRadius: 15,
      overflowY: 'auto',
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   appBarSpacer: theme.mixins.toolbar,
}));

const SettingsSpace = (props) => {
   const classes = useStyles();
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const token = useSelector((rootReducer) => rootReducer.personReducer.token);
   return (
      <Grid style={{ padding: 10, width: '100%', height: '100%' }}>
         <div className={classes.appBarSpacer} />
         <Paper elevation={1} className={classes.root}>
            <Switch>
               <Route path={'/settings/teams'} exact>
                  <Teams person={person} token={token} />
               </Route>
               <Route path={'/settings/company/hours'} exact>
                  <MessageHours person={person} />
               </Route>
               <Route path={'/settings/company/quick-answers'} exact>
                  <QuickAnswers person={person} token={token} />
               </Route>
               <Route path={'/settings/company/chat-settings'} exact>
                  <ChatSettings person={person} token={token} />
               </Route>
               <Route path={'/settings/company/custom-fields'} exact>
                  <CustomFields person={person} token={token} />
               </Route>
               <Route path={'/settings/company/customer-satisfaction'} exact>
                  <CustomerSatisfaction person={person} token={token} />
               </Route>
               <Route path={'/settings/company/customer-satisfaction/create-survey'} exact>
                  <SurveyPage isNewSurvey={true} person={person} token={token} />
               </Route>
               <Route path={'/settings/company/customer-satisfaction/survey/:id'} exact>
                  <SurveyPage person={person} token={token} />
               </Route>
               <Route path={'/settings/company/tags'} exact>
                  <SpecialTags person={person} token={token} />
               </Route>
               <Route path={'/settings/channels-and-integrations'} exact>
                  <ChannelsAndIntegrations />
               </Route>
               <Route path={'/settings/platforms/live-chat'}>
                  <LiveChat />
               </Route>
               <Route path={'/settings/platforms/whatsapp-web'} exact>
                  <WhatsappWeb person={person} token={token} />
               </Route>
               <Route path={'/settings/platforms/telegram'} exact>
                  <Telegram person={person} token={token} />
               </Route>
               <Route path={'/settings/platforms/email'} exact>
                  <Email person={person} token={token} />
               </Route>
               <Route path={'/settings/platforms/email/account/:_id'} exact>
                  <EmailAccountSetting person={person} token={token} />
               </Route>
               <Route path={'/settings/platforms/whatsapp-business'} exact>
                  <WhatsappBusiness person={person} token={token} />
               </Route>
               <Route path={'/settings/platforms/whatsapp-business/templatesPage/:_id'} exact>
                  <TemplatesPage person={person} token={token} />
               </Route>
               <Route path={'/settings/platforms/whatsapp-business/createNewTemplate/:_id'} exact>
                  <CreateNewTemplate />
               </Route>
               <Route path={'/settings/platforms/whatsapp-business/showTemplate/:_id/:_mongoId'} exact>
                  <CreateNewTemplate />
               </Route>
               <Route path={'/settings/platforms/facebook'} exact>
                  <FacebookMessenger person={person} token={token} />
               </Route>
               <Route path={'/settings/platforms/instagram'} exact>
                  <InstagramDM person={person} token={token} />
               </Route>
               <Route path={'/settings/platforms/trendyol-questions'} exact>
                  <TrendyolQuestions />
               </Route>
               <Route path={'/settings/platforms/n11-questions'} exact>
                  <N11Questions />
               </Route>
               <Route path={'/settings/platforms/hepsiburada-questions'} exact>
                  <HepsiBuradaQuestions />
               </Route>
               <Route path={'/settings/e-commerce/shopify'} exact>
                  <Shopify />
               </Route>
               <Route path={'/settings/e-commerce/woocommerce'} exact>
                  <WooCommerce />
               </Route>
               <Route path={'/settings/e-commerce/faprika'} exact>
                  <Faprika />
               </Route>
               <Route path={'/settings/e-commerce/ideasoft'} exact>
                  <Ideasoft />
               </Route>
               <Route path={'/settings/e-commerce/tsoft'} exact>
                  <Tsoft />
               </Route>
               <Route path={'/settings/e-commerce/ticimax'} exact>
                  <Ticimax />
               </Route>
               <Route path={'/settings/e-commerce/ikas'} exact>
                  <Ikas />
               </Route>
               <Route path={'/settings/users'} exact>
                  <Users person={person} token={token} />
               </Route>
               <Redirect to={'/settings/company/hours'} />
            </Switch>
         </Paper>
      </Grid>
   );
};

export default SettingsSpace;

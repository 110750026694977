import { LANGUAGES } from '../language';
import { createContext } from 'react';
import { GetElementType } from '../../types/utils';

export type LanguageObj = GetElementType<typeof LANGUAGES>;

interface ContextType {
   lang: Pick<LanguageObj, 'translation'>;
   languages: LanguageObj[];
   switchLang: (language: LanguageObj) => void;
}

export const LanguageContext = createContext<ContextType>({
   //@ts-ignore
   lang: { translation: {} },
   languages: [],
   switchLang: () => {},
});

let languages: LanguageObj[] = [];

(function initialize() {
   LANGUAGES.forEach((lang) => {
      const language = { ...lang };
      // Make standard routes for none default languages.
      // Make reverse mapper
      Object.keys(language.translation).forEach(
         //@ts-ignore
         (trKey) => (language.reverseMapper[language.translation[trKey]] = trKey)
      );
      // Save it
      languages.push(language);
   });
})();

export function getDefaultLang() {
   const locale = navigator.language;
   let localeLang = LANGUAGES.find((lang) => lang.code === (window.localStorage.getItem('langCode') || locale));
   if (!localeLang) {
      console.warn('Locale not supported! Setting language to en-US');
      return LANGUAGES.find((lang) => lang.default);
   }
   return localeLang;
}

export function getAllLanguages() {
   return languages;
}

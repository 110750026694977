import React, { Fragment, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { LanguageContext } from '@helper/locale/langContext';
import CreatableSelect from 'react-select/creatable';
import { AsyncPaginate } from 'react-select-async-paginate';

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: 15,
      '& .MuiInputBase-root.Mui-disabled': {
         // backgroundColor: (props) => props.disabledColor || 'red',
      },
   },
   container: {
      borderColor: theme.palette.primary.main,
      backgroundColor: (props) => (props.disabled ? '' : props.background),
   },
   textInput: {
      width: '100%',
      border: 'none',
      resize: 'none',
      borderRadius: 10,
      padding: 10,
      fontSize: '1.075rem',
      // background: (props) => (props.disabled ? '#eee' : 'white'),
   },
   inputLabel: {
      marginTop: '-45px',
      color: (props) => props.labelColor || '',
      '&.focused': {
         color: theme.palette.primary.main,
      },
   },
   smallLabel: {
      fontSize: '0.8rem',
      marginTop: -38,
   },
   phoneContainer: {
      backgroundColor: (props) => (props.disabled ? '#eee' : '#fff'),
   },
}));
// React Select Styles

const Input = (props) => {
   const {
      maxLength,
      control,
      name,
      label,
      disabled,
      editable,
      rules,
      type,
      helperText,
      placeholder,
      isPhone,
      isEmail,
      splitCountryCode,
      multiline,
      isSelect,
      isMulti,
      isCreatable,
      isSearchable,
      selectOptions,
      selectDefaultValue,
      customSelectStyles,
      rows,
      notDisplay,
      startAdornment,
      endAdornment,
      onKeyPress = null,
      onChange = false,
      value = false,
      autoHeight = false,
      isOptionDisabled,
      onFocus,
      maxMenuHeight,
      isClearable,
      backspaceRemovesValue,
      isLoading,
      isPaginateSelect,
      isDate,
      loadOptions,
   } = props;
   const customStyles = {
      control: (base, props) => {
         // console.log(props, 'PROPS!!!');
         return {
            ...base,
            height: autoHeight ? 'auto' : 44,
            ...(autoHeight ? { paddingTop: 2, paddingBottom: 2 } : {}),
            minHeight: 44,
            fontSize: '0.9rem',
            color: props.isDisabled ? '#fff' : '#aaa',
            backgroundColor: props.isDisabled ? 'transparent' : '#fff',
            // borderColor: isError ? "red" : undefined
         };
      },
      // option: (styles, { isDisabled }) => {
      //    // const color = chroma(data.color);
      //    return {
      //       ...styles,
      //       backgroundColor: isDisabled ? 'red' : 'blue',
      //       color: '#FFF',
      //       cursor: isDisabled ? 'not-allowed' : 'default',
      //    };
      // },
   };

   const classes = useStyles(props);

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [showPassword, setShowPassword] = useState(false);
   const [currentCountry, setCurrentCountry] = useState({
      dialCode: '90',
      iso2: 'tr',
   });

   const handleClickShowPassword = () => {
      setShowPassword(!showPassword);
   };

   const whichValidate = () => {
      if (isPhone) {
         return (value) => {
            const isRequired = !!rules?.required;
            const { dialCode, iso2 } = currentCountry;
            if (!isRequired && (!value || value === dialCode)) {
               return true;
            } else if (value && iso2 && dialCode) {
               const isValid = isValidPhoneNumber(value, iso2?.toUpperCase());
               return isValid ? true : t['phone_number_is_not_valid'];
            } else {
               return undefined;
            }
         };
      } else if (type === 'email') {
         return (value) => {
            const isRequired = !!rules?.required;
            if (!isRequired && !value) {
               return true;
            } else if (value) {
               const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/; // TODO: update regex
               if (value.match(emailRegex)) {
                  return true;
               } else return t['email_is_not_valid'];
            } else {
               return false;
            }
         };
      } else if (type === 'tags') {
         return (value) => {
            return value?.length > 5 ? t['tags_is_max_5'] : true;
         };
      } else {
         return undefined;
      }
   };

   return (
      <Controller
         name={name}
         control={control}
         render={({ field, fieldState: { error, isDirty, isTouched } }) => (
            <FormControl
               className={classes.root}
               style={{
                  display: notDisplay ? 'none' : undefined,
               }}
               fullWidth
            >
               <InputLabel
                  shrink={false}
                  className={classes.inputLabel}
                  style={{ color: error ? 'red' : null }}
                  htmlFor={name}
                  placeholder={placeholder || name}
               >
                  {label}
               </InputLabel>
               <Grid container alignItems='center'>
                  {isPhone ? (
                     <Grid item xs={12}>
                        <PhoneInput
                           type='tel'
                           placeholder={t['phone_number_placeholder']}
                           country={'tr'}
                           {...field}
                           onChange={(value, country) => {
                              if (!country?.dialCode || !country?.countryCode) {
                                 alert(JSON.stringify(country));
                              }
                              const phoneToSave = splitCountryCode
                                 ? value.replace(country?.dialCode, `${country?.dialCode} `)
                                 : value;
                              field.onChange(phoneToSave);
                              setCurrentCountry({
                                 dialCode: country?.dialCode,
                                 iso2: country?.countryCode,
                              });
                           }}
                           isValid={!error}
                           inputStyle={{
                              width: '100%',
                              height: '44px',
                              backgroundColor: disabled ? 'transparent' : 'white',
                              color: disabled ? 'rgba(0, 0, 0, 0.38)' : undefined,
                           }}
                           enableSearch={true}
                           disabled={disabled}
                           editable={editable}
                        />
                     </Grid>
                  ) : isSelect && onChange ? (
                     <Grid xs={12} item>
                        <Select
                           options={selectOptions}
                           styles={{ ...customStyles, ...customSelectStyles }}
                           {...(onchange ? field : null)}
                           defaultValue={selectDefaultValue}
                           isSearchable
                           isMulti={isMulti}
                           isDisabled={disabled}
                           placeholder={placeholder}
                           isLoading={isLoading}
                           onChange={onChange}
                           {...(value ? { value: value } : null)}
                           isOptionDisabled={isOptionDisabled}
                           maxMenuHeight={maxMenuHeight}
                           onFocus={onFocus}
                        />
                     </Grid>
                  ) : isCreatable ? (
                     <Grid xs={12} item>
                        <CreatableSelect
                           options={selectOptions}
                           styles={{ ...customStyles, ...customSelectStyles }}
                           {...field}
                           defaultValue={selectDefaultValue}
                           isSearchable
                           isMulti={isMulti}
                           isDisabled={disabled}
                           placeholder={placeholder}
                           isOptionDisabled={isOptionDisabled}
                           isClearable={isClearable}
                           backspaceRemovesValue={backspaceRemovesValue}
                        />
                     </Grid>
                  ) : isSelect ? (
                     <Grid xs={12} item>
                        <Select
                           options={selectOptions}
                           styles={{ ...customStyles, ...customSelectStyles }}
                           {...field}
                           defaultValue={selectDefaultValue}
                           isSearchable
                           isMulti={isMulti}
                           isLoading={isLoading}
                           isDisabled={disabled}
                           placeholder={placeholder}
                           isOptionDisabled={isOptionDisabled}
                           maxMenuHeight={maxMenuHeight}
                           onFocus={onFocus}
                        />
                     </Grid>
                  ) : isPaginateSelect ? (
                     <Grid xs={12} item>
                        <AsyncPaginate
                           placeholder={placeholder}
                           styles={{ ...customStyles, ...customSelectStyles }}
                           cacheOptions
                           isMulti={isMulti}
                           isDisabled={disabled}
                           isSearchable={isSearchable}
                           isClearable={isClearable}
                           maxMenuHeight={maxMenuHeight}
                           closeMenuOnSelect={isMulti ? false : true}
                           value={value}
                           loadOptions={loadOptions}
                           onChange={onChange}
                        />
                     </Grid>
                  ) : (
                     <Grid item xs={isPhone ? 8 : 12}>
                        <OutlinedInput
                           onFocus={onFocus}
                           inputProps={maxLength && { maxLength: maxLength }}
                           className={classes.container}
                           id={name}
                           onKeyPress={onKeyPress}
                           placeholder={placeholder || label}
                           variant='outlined'
                           {...field}
                           disabled={!!disabled}
                           editable={!!editable}
                           fullWidth
                           multiline={multiline}
                           onChange={(e) => {
                              if (isDate) {
                                 // 12/12/2020 for formatter
                                 let formattedValue = e.target.value
                                    .replace(/\D/g, '')
                                    .replace(/^(\d{2})(\d{1,2})/, '$1/$2')
                                    .replace(/^(\d{2})\/(\d{2})(\d{1,4})/, '$1/$2/$3')
                                    .substring(0, 10);
                                 field.onChange(formattedValue);
                                 return;
                              }
                              field.onChange(e.target.value);
                           }}
                           rows={multiline ? (rows ? rows : 4) : undefined}
                           type={
                              type === 'password' && showPassword === false
                                 ? 'password'
                                 : type === 'password' && showPassword
                                 ? 'text'
                                 : type
                           }
                           error={!!error}
                           startAdornment={
                              startAdornment && <InputAdornment position='start'>{startAdornment}</InputAdornment>
                           }
                           endAdornment={
                              type === 'password' ? (
                                 <InputAdornment position='end'>
                                    <IconButton
                                       aria-label='toggle password visibility'
                                       onClick={handleClickShowPassword}
                                       edge='end'
                                    >
                                       {showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                 </InputAdornment>
                              ) : (
                                 <Fragment>
                                    {endAdornment && <InputAdornment position='end'>{endAdornment}</InputAdornment>}
                                 </Fragment>
                              )
                           }
                        />
                     </Grid>
                  )}
               </Grid>
               <FormHelperText error={!helperText || !!error}>
                  {error ? error.message : helperText ? helperText : null}
               </FormHelperText>
            </FormControl>
         )}
         rules={{
            validate: whichValidate(),
            ...rules,
         }}
         classes={{
            root: {
               marginBottom: '1rem',
            },
         }}
      />
   );
};

Input.defaultProps = {
   editable: true,
   commonUse: true,
   type: 'text',
   labelSize: 'normal',
   required: true,
};

export default Input;

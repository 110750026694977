import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, ClickAwayListener, FormControlLabel, Grid, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { Node } from 'reactflow';

import {
   FlowBlockType,
   FlowInstanceMessageChannel,
   IFlowBlockField,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { LanguageContext } from '@helper/locale/langContext';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { useMutation } from '@apollo/client';
import {
   ADD_FLOW_BLOCK_MUTATION,
   REMOVE_BLOCKS_FROM_NODE_MUTATION,
   UPDATE_BLOCK_MUTATION,
} from '@queries/Automation/mutation';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import SendMessageButtonPanel from './buttonPanel';
import QuickReply from './quickReply';
import InformativeButton from './informativeButton';
import InfoIcon from '@mui/icons-material/Info';
import CustomSwitch from '@components/Switch';

interface SendButtonMsgBlocksProps {
   leftNodes: Node[];
   setIsBtnSendMsgDisable: React.Dispatch<React.SetStateAction<boolean>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   isSaveSendMsg: boolean;
   setIsSaveSendMsg: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   clickedNodeId: string;
}

const SendButtonMsgBlocks: React.FC<SendButtonMsgBlocksProps> = ({
   leftNodes,
   setIsBtnSendMsgDisable,
   isSaveSendMsg,
   setIsSaveSendMsg,
   clickedNodeId,
   isLoading,
   setIsLoading,
   setContentNodes,
   setLeftNodes,
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const divRef = useRef(null);

   const useStyles = makeStyles((theme) => ({
      textArea: {
         backgroundColor: '#F7F7FA',
         border: '1px solid #E7E7E7',
         borderRadius: '5px',
         outline: 'none',
         resize: 'none',
         width: '100%',
         minHeight: '80px',
         height: '100%',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '10px',
         '&:focus': {
            backgroundColor: '#fff',
            border: '2px solid #147CFC',
         },
      },
      textAreaError: {
         backgroundColor: '#F7F7FA',
         border: '1px solid #FF7474',
         borderRadius: '5px',
         outline: 'none',
         resize: 'none',
         width: '100%',
         minHeight: '80px',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '10px',
         '&:focus': {
            backgroundColor: '#fff',
            border: '2px solid #FF7474',
         },
      },
      textAreaInfinteBtn: {
         backgroundColor: '#F7F7FA',
         border: '1px solid #F7F7FA',
         borderBottom: '1px solid #E7E7E7',
         borderRadius: '5px',
         outline: 'none',
         resize: 'none',
         width: '100%',
         height: '100%',
         minHeight: '80px',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '10px',
         '&:focus': {
            backgroundColor: '#fff',
            border: '1px solid #147CFC',
            borderBottom: '1px solid #147CFC',
         },
      },
      boxText: {
         position: 'relative',
         width: '100%',
         minHeight: '80px',
         overflow: 'hidden',
         // marginBottom: '10px',
         paddingBottom: '20px',
      },
      boxTextInfinteBtn: {
         position: 'relative',
         width: '100%',
         minHeight: '80px',
         overflow: 'hidden',
         zIndex: 1,
         paddingBottom: '20px',
      },
      modalButtons: {
         fontSize: '18px',
         textTransform: 'none',
         paddingBlock: 5,
         paddingInline: 15,
         minWidth: 100,
         height: 44,
         borderRadius: 6,
         transition: 'all .5s',
         '&:disabled': {
            opacity: '0.7',
         },
      },
      file: {
         border: '1px solid #E7E7E7',
         backgroundColor: '#F7F7FA',
         borderRadius: '5px',
         width: '100%',
         fontSize: '1rem',
         display: 'flex',
         justifyContent: 'center',
         minHeight: '130px',
         color: 'gray',
         cursor: 'pointer',
         marginBottom: '10px',
      },
      previewImage: {
         backgroundColor: '#F7F7FA',
         borderRadius: '5px',
         width: '100%',
         fontSize: '1rem',
         display: 'flex',
         justifyContent: 'center',
         minHeight: '130px',
         color: 'gray',
         cursor: 'pointer',
         marginBottom: '10px',
      },
      preview: {
         display: 'flex',
         flexDirection: 'column',
         textAlign: 'center',
         borderRadius: '5px',
         justifyContent: 'center',
         height: '130px',
         padding: '5px',
         width: '100%',
         fontSize: '1rem',
         border: '0.1px solid #DDDDDD',
         marginBottom: '10px',
      },

      item: {
         display: 'flex',
         flexDirection: 'column',
      },
      customFileInput: {
         color: 'transparent',
         '&::-webkit-file-upload-button': {
            visibility: 'hidden',
         },
         '&::before': {
            content: t.atomation_flow_upload_an_image,
            color: '#818181',
            background: 'transparent',
            outline: 'none',
            cursor: 'pointer',
            fontSize: '1rem',
            justifyContent: 'center',
            display: 'flex',
            height: '100%',
         },
      },
      customFileVideo: {
         color: 'transparent',
         '&::-webkit-file-upload-button': {
            visibility: 'hidden',
         },
         '&::before': {
            content: t.atomation_flow_upload_a_video,
            color: '#818181',
            background: 'transparent',
            outline: 'none',
            cursor: 'pointer',
            fontSize: '1rem',
            display: 'flex',
            justifyContent: 'center',
         },
      },
      deleteIcon: {
         color: 'gray',
         backgroundColor: '#fff',
         borderRadius: 3,
         cursor: 'pointer',
         boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
         zIndex: 9999,
      },
      container: {
         display: 'flex',
         flexDirection: 'column',
         fontSize: '1rem',
         position: 'relative',
         height: '100%',
      },
      miniBox: {
         position: 'absolute',
         bottom: 0,
         right: 2,
         width: '26%',
         borderRadius: 7,
         float: 'right',
         display: 'grid',
         gridTemplateColumns: 'repeat(3, 1fr)',
         gap: '1px',
         color: '#fff',
         backgroundColor: 'black',
         padding: '2px 4px',
         cursor: 'pointer',
      },
      miniBoxInf: {
         position: 'absolute',
         bottom: -20,
         right: 2,
         width: '26%',
         borderRadius: 7,
         float: 'right',
         display: 'grid',
         gridTemplateColumns: 'repeat(3, 1fr)',
         gap: '1px',
         color: '#fff',
         backgroundColor: 'black',
         padding: '2px 4px',
         cursor: 'pointer',
      },
   }));

   const classes = useStyles();
   const [anchorElField, setAnchorElField] = useState<null | HTMLElement>(null);
   const [isHover, setIsHover] = useState(false);

   const [isDeletable, setIsDeletable] = useState(true);
   const [hoverId, setHoverId] = useState<string>('');
   const [menuId, setMenuId] = useState<string>('');
   const [wordCounts, setWordCounts] = useState<{ [key: string]: number }>({});
   const [activeTextareaId, setActiveTextareaId] = useState<string | null>(null);
   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
   const [isBlockTextLast, setIsBlockTextLast] = useState(false);
   const [deletedBlockId, setDeletedBlockId] = useState<string[]>([]);
   const [toUpdate, setToUpdate] = useState<{ _id: string; fieldsValue: string }[]>([]);
   const [toUpdateQR, setToUpdateQR] = useState<{ _id: string }[]>([]);
   const [mutationCount, setMutationCount] = useState(0);
   const [isValidMessage, setIsValidMessage] = useState(true);
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);

   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   // Add block mutation
   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlock = async (
      flowBlockTemplateId: String,
      nodeId: string,
      field: IFlowBlockField[],
      prevBlockId: string,
   ) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fields: field,
               },
            },
            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];

               setDataId((prev) => [...prev, { prevData: prevBlockId, newData: response._id }]);
               setMutationCount((prevCount) => prevCount - 1);
               setIsDeletable(true);

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block.type !== FlowBlockType.SendMessageConnector)
                                 ? true
                                 : false,
                              flowBlocks: [...node.data.flowBlocks, response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   const handleAddFlowBlockConnector = async (flowBlockTemplateId: String, nodeId: string) => {
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
               },
            },
            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];
               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block.type !== FlowBlockType.SendMessageConnector)
                                 ? true
                                 : false,
                              flowBlocks: [...node.data.flowBlocks, response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };
   const handleUpdateFieldValue = (blockId: string, value: string) => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === blockId) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.informativeButtons) {
                        return {
                           ...field,
                           value: value,
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });
         calculateWordCount(blockId, value);
         return updatedBlockState;
      });
   };

   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);

   const handleUpdateFlowBlockInput = async (blockId: string, input: string) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: {
                     type: IFlowBlockFieldTypes.Text,
                     value: input,
                  },
               },
            },
            onCompleted: (res) => {
               setMutationCount((prevCount) => prevCount - 1);

               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block.type !== FlowBlockType.SendMessageConnector)
                                 ? true
                                 : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };
   const handleUpdateFlowBlockQuickReply = async (blockId: string, blockFields: IFlowBlockField[]) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: blockFields.map((field: any) => {
                     for (let [key, value] of Object.entries(field)) {
                        if (value == null) {
                           delete field[key];
                        }
                     }
                     return field;
                  }),
               },
            },
            onCompleted: (res) => {
               setMutationCount((prevCount) => prevCount - 1);

               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block.type !== FlowBlockType.SendMessageConnector)
                                 ? true
                                 : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const [dataId, setDataId] = useState<{ prevData: string; newData: string }[]>([]);

   // Delete block mutation
   const [deleteBlock] = useMutation(REMOVE_BLOCKS_FROM_NODE_MUTATION);
   const handleDeleteFlowBlock = async (blockId: String[], nodeId: string) => {
      setMutationCount((prevCount) => prevCount + 1);
      try {
         await deleteBlock({
            variables: {
               input: {
                  blocks: blockId,
                  node: nodeId,
               },
            },
            onCompleted: () => {
               setMutationCount((prevCount) => prevCount - 1);

               setContentNodes((prevNodes) =>
                  prevNodes.map((node) =>
                     node.id === clickedNodeId
                        ? {
                             ...node,
                             data: {
                                ...node.data,
                                clickedNodeId: clickedId,
                                isValidNode: blockState.some(
                                   (block) => block.fields[0]?.requestUrl && block.fields[0]?.requestUrl?.length > 0,
                                )
                                   ? true
                                   : false,
                                flowBlocks: node.data.flowBlocks.filter(
                                   (flowBlock: IFlowBlockTemplate) => !blockId.includes(flowBlock._id),
                                ),
                             },
                          }
                        : node,
                  ),
               );
               setLeftNodes((prevNodes) =>
                  prevNodes.map((node) =>
                     node.id === clickedNodeId
                        ? {
                             ...node,
                             data: {
                                ...node.data,
                                flowBlocks: node.data.flowBlocks.filter(
                                   (flowBlock: IFlowBlockTemplate) => !blockId.includes(flowBlock._id),
                                ),
                             },
                          }
                        : node,
                  ),
               );
            },
         });
      } catch (error) {
         console.error('Error deleting block:', error);
      }
   };

   const handleMenuFieldClose = () => {
      setAnchorElField(null);
   };

   const handleMenuFieldOpen = (id: string) => {
      setAnchorElField(divRef.current);
      setMenuId(id);
   };

   const handlePickerFieldOpen = (id: string) => {
      setShowEmojiPicker(true);
      setActiveTextareaId(id);
   };

   const handleCreateBlockQuickReply = () => {
      setIsBtnSendMsgDisable(true);
      const newItem = [
         {
            _id: Date.now().toString(),
            title: '',
            fields: [
               {
                  type: IFlowBlockFieldTypes.Text,
                  value: '',
               },
            ],
            type: FlowBlockType.SendMessage,
         },
         {
            _id: Date.now().toString() + 'qr',
            title: '',
            fields: [
               {
                  type: IFlowBlockFieldTypes.SendMessage,
                  isButtonRequired: true,
                  requiredButtonMessage: t.atomation_flow_default_response,
                  quickReplies: [],
               },
            ],
            type: FlowBlockType.SendQuickReplyMessage,
         },
      ];

      setBlockState((prevBlockState) => [...prevBlockState, ...newItem]);
   };

   const handleCreateBlockInformativeButton = () => {
      setIsBtnSendMsgDisable(true);
      const newItem = {
         _id: Date.now().toString(),
         title: '',
         fields: [
            {
               type: IFlowBlockFieldTypes.SendMessage,
               value: '',
               informativeButtons: [],
            },
         ],
         type: FlowBlockType.SendInformativeButtonMessage,
      };

      setBlockState((prevBlockState) => [...prevBlockState, newItem]);
   };

   const calculateWordCount = (id: string, text: string) => {
      const wordCount = text.length;
      setWordCounts((prevWordCounts) => ({
         ...prevWordCounts,
         [id]: 1500 - wordCount,
      }));
   };

   const handleBlockInputChange = (id: string, text: string) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     value: text,
                  })),
               };
            }
            return item;
         });

         if (text.length > 0) {
            setIsBtnSendMsgDisable(false);
         } else {
            setIsBtnSendMsgDisable(true);
         }
         calculateWordCount(id, text);
         return updatedBlockState;
      });

      setToUpdate([{ _id: id, fieldsValue: text }]);
   };

   const handleToggleBtnClicked = (blockId: string, value: boolean | undefined) => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (value !== undefined) {
               if (item._id === blockId) {
                  return {
                     ...item,
                     fields: item.fields.map((field) => ({
                        ...field,
                        isButtonRequired: !value,
                     })),
                  };
               }
            }
            return item;
         });

         return updatedBlockState;
      });
      setToUpdateQR([{ _id: blockId }]);
   };
   const handleMessageBtnChange = (blockId: string, value: string | undefined) => {
      if (value !== '') {
         setIsBtnSendMsgDisable(false);
         setIsValidMessage(true);
      } else {
         setIsBtnSendMsgDisable(true);
         setIsValidMessage(false);
      }
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (value !== undefined) {
               if (item._id === blockId) {
                  return {
                     ...item,
                     fields: item.fields.map((field) => ({
                        ...field,
                        requiredButtonMessage: value,
                     })),
                  };
               }
            }
            return item;
         });

         return updatedBlockState;
      });
      setToUpdateQR([{ _id: blockId }]);
   };

   const backendBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];

   useEffect(() => {
      if (mutationCount > 0) {
         setIsLoading(true);
      } else {
         setIsLoading(false);
      }
   }, [mutationCount]);

   //save
   useEffect(() => {
      if (isSaveSendMsg && !isLoading) {
         setIsSaveSendMsg(false);
         setIsBtnSendMsgDisable(false);

         const newBlocks = blockState.filter(
            (block) => !backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );
         const existingBlocks = blockState.filter((block) =>
            backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         // Delete blocks into the backend

         if (deletedBlockId.length > 0) {
            const filteredBlocksId = deletedBlockId.filter((blockId) =>
               backendBlocks.some((block: { _id: string }) => block._id === blockId),
            );

            if (filteredBlocksId.length > 0) {
               handleDeleteFlowBlock(filteredBlocksId, clickedNodeId);
            }

            setDeletedBlockId([]);
         }

         // Add all new blocks to the backend
         const save = async () => {
            // Update fields
            if (toUpdate.length > 0) {
               toUpdate.forEach((item) => {
                  // Check if the item's _id exists in existingBlocks
                  if (existingBlocks.some((block) => block._id === item._id)) {
                     handleUpdateFlowBlockInput(item._id, item.fieldsValue);
                  }
               });
               setToUpdate([]);
            }

            if (toUpdateQR.length > 0) {
               // Check if the item's _id exists in existingBlocks
               const blocks = existingBlocks.find((block) => block._id === toUpdateQR[0]?._id);
               if (blocks) {
                  handleUpdateFlowBlockQuickReply(toUpdateQR[0]?._id, blocks.fields);
               }
               setToUpdateQR([]);
            }

            if (newBlocks.length > 0) {
               (async () => {
                  if (LIST_BLOCK_TEMPLATE) {
                     const templateBlockId = LIST_BLOCK_TEMPLATE.find(
                        (item: { type: FlowBlockType }) => item.type === FlowBlockType.SendMessage,
                     )?._id;

                     const templateBlockQuickReply = LIST_BLOCK_TEMPLATE.find(
                        (item: { type: FlowBlockType }) => item.type === FlowBlockType.SendQuickReplyMessage,
                     )?._id;

                     const templateBlockInformativeButton = LIST_BLOCK_TEMPLATE.find(
                        (item: { type: FlowBlockType }) => item.type === FlowBlockType.SendInformativeButtonMessage,
                     )?._id;

                     setIsDeletable(false);
                     for await (let item of newBlocks) {
                        if (templateBlockId && templateBlockQuickReply && templateBlockInformativeButton) {
                           const isQuickReply = item.type === FlowBlockType.SendQuickReplyMessage;
                           const isInformativeButton = item.type === FlowBlockType.SendInformativeButtonMessage;

                           if (isQuickReply) {
                              await handleAddFlowBlock(templateBlockQuickReply, clickedNodeId, item.fields, item._id);
                           } else if (isInformativeButton) {
                              await handleAddFlowBlock(
                                 templateBlockInformativeButton,
                                 clickedNodeId,
                                 item.fields,
                                 item._id,
                              );
                           } else {
                              await handleAddFlowBlock(templateBlockId, clickedNodeId, item.fields, item._id);
                           }
                        }
                     }
                  }
               })();
            }
         };
         save();
         setToUpdate([]);
      }
   }, [isSaveSendMsg]);

   // update the local state with the Id coming from the response mutation
   useEffect(() => {
      if (dataId) {
         let updatedBlockState = [...blockState];
         dataId.forEach((element) => {
            updatedBlockState = updatedBlockState.map((block) => {
               if (element.prevData === block._id) {
                  return {
                     ...block,
                     _id: element.newData,
                  };
               } else {
                  return block;
               }
            });
         });

         setBlockState(updatedBlockState);
      }
   }, [leftNodes]);

   const listBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   // default
   useEffect(() => {
      setBlockState([]);
      if (listBlocks.length > 0) {
         const data = listBlocks.map((item: IFlowBlockTemplate) => {
            const value = item.fields[0]?.value;
            if (value) {
               calculateWordCount(item._id, value);
            }
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });
         setBlockState(data);
      } else {
         const isConnector = backendBlocks.some(
            (block: { type: FlowBlockType }) => block.type === FlowBlockType.SendMessageConnector,
         );

         if (!isConnector) {
            (async () => {
               if (LIST_BLOCK_TEMPLATE) {
                  const templateBlockIdConnector = LIST_BLOCK_TEMPLATE.find(
                     (item: { type: FlowBlockType }) => item.type === FlowBlockType.SendMessageConnector,
                  )?._id;
                  if (templateBlockIdConnector) {
                     handleAddFlowBlockConnector(templateBlockIdConnector, clickedNodeId);
                  }
               }
            })();
         }
      }
   }, [clickedNodeId]);

   const handleSetIsHover = (blockId: string) => {
      setIsHover(true);
      setHoverId(blockId);
   };

   // delete block and the corresponding file
   const handleBlockDelete = (idToDelete: string) => {
      const existingBlocks = backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === idToDelete);

      const hasFieldValueToUpdate = backendBlocks.some((backendBlock: { _id: string; fields: IFlowBlockField[] }) => {
         const correspondingBlockState = blockState.find((stateBlock) => stateBlock._id === backendBlock._id);

         return correspondingBlockState
            ? backendBlock.fields.some((field, index) => {
                 const correspondingField = correspondingBlockState.fields[index];
                 return (
                    correspondingField &&
                    (correspondingField.value !== field.value ||
                       ((correspondingField.informativeButtons ?? []) &&
                          (field.informativeButtons ?? []).length > 0 &&
                          correspondingField.informativeButtons!.some(
                             (button, buttonIndex) =>
                                button.title !== (field.informativeButtons![buttonIndex]?.title ?? null),
                          )) ||
                       correspondingField.requiredButtonMessage !== field.requiredButtonMessage ||
                       correspondingField.isButtonRequired !== field.isButtonRequired ||
                       ((correspondingField.quickReplies ?? []) &&
                          (field.quickReplies ?? []).length > 0 &&
                          correspondingField.quickReplies!.some(
                             (reply, replyIndex) => reply.title !== (field.quickReplies![replyIndex]?.title ?? null),
                          )))
                 );
              })
            : false;
      });

      setBlockState((prevBlock) => {
         setDeletedBlockId((prevBlock) => [...prevBlock, idToDelete]);
         const updatedBlockState = prevBlock.filter((item) => item._id !== idToDelete);

         const hasMoreBlockStates = updatedBlockState.length > backendBlocks.length;
         const shouldUpdate = hasFieldValueToUpdate || hasMoreBlockStates;

         if (existingBlocks || shouldUpdate) {
            setIsBtnSendMsgDisable(false);
         }
         return updatedBlockState;
      });
   };

   const addEmoji = (e: any, id: string) => {
      const emoji = e.native;

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               const updatedFields = item.fields.map((field) => {
                  if (field.type === IFlowBlockFieldTypes.Text || field.type === IFlowBlockFieldTypes.SendMessage) {
                     const textAreaValue = field.value || '';
                     const textAreaElement = document.getElementById(`textarea-${id}`) as HTMLTextAreaElement | null;
                     if (textAreaElement) {
                        const start = textAreaElement.selectionStart;
                        const end = textAreaElement.selectionEnd;
                        const updatedValue = textAreaValue.substring(0, start) + emoji + textAreaValue.substring(end);
                        calculateWordCount(id, updatedValue);
                        setToUpdate([{ _id: id, fieldsValue: updatedValue }]);
                        return { ...field, value: updatedValue };
                     }
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });

         setIsBtnSendMsgDisable(false);
         return updatedBlockState;
      });

      setShowEmojiPicker(false);
   };

   const addField = (name: string, id: string) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               const updatedFields = item.fields.map((field) => {
                  if (field.type === IFlowBlockFieldTypes.Text || field.type === IFlowBlockFieldTypes.SendMessage) {
                     const textAreaValue = field.value || '';
                     const textAreaElement = document.getElementById(`textarea-${id}`) as HTMLTextAreaElement | null;
                     if (textAreaElement) {
                        const start = textAreaElement.selectionStart;
                        const end = textAreaElement.selectionEnd;
                        const updatedValue = textAreaValue.substring(0, start) + name + textAreaValue.substring(end);
                        calculateWordCount(id, updatedValue);
                        setToUpdate([{ _id: id, fieldsValue: updatedValue }]);
                        return { ...field, value: updatedValue };
                     }
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });

         setIsBtnSendMsgDisable(false);
         return updatedBlockState;
      });

      handleMenuFieldClose();
   };

   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         let numRows = 0;
         let consecutiveChars = 0;

         for (let i = 0; i < content.length; i++) {
            if (content[i] !== '\n') {
               consecutiveChars++;
               if (consecutiveChars === 36) {
                  numRows++;
                  consecutiveChars = 0;
               }
            } else {
               consecutiveChars = 0;
            }
         }

         const enters = (content.match(/\n/g) || []).length;

         numRows += enters;

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: numRows + 2, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };

   // making enabled or disabled the save button
   function hasNegativeValue(obj: { [x: string]: number }) {
      for (let key in obj) {
         if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] < 0) {
               return true;
            }
         }
      }
      return false;
   }
   useEffect(() => {
      const isInvalid = blockState.some((block) =>
         block.fields.some((field) => field.type === 'text' && field.value === ''),
      );

      const isQR = blockState.some((block) => block.type === FlowBlockType.SendQuickReplyMessage);

      const filteredBlockState = blockState.filter((block) => block.type !== FlowBlockType.SendQuickReplyMessage);
      const isTextBlockLast =
         filteredBlockState.length > 0 &&
         filteredBlockState[filteredBlockState.length - 1].fields.some((field) => field.type === 'text');

      setIsBlockTextLast(isTextBlockLast);

      const qrBlock = blockState.some(
         (block) => block.type === FlowBlockType.SendQuickReplyMessage && block.fields[0]?.quickReplies?.length === 0,
      );

      const infBtnBlock = blockState.some(
         (block) =>
            block.type === FlowBlockType.SendInformativeButtonMessage &&
            block.fields[0]?.informativeButtons?.length === 0,
      );
      const infBtnBlockFieldValue = blockState.some(
         (block) => block.type === FlowBlockType.SendInformativeButtonMessage && block.fields[0]?.value?.length === 0,
      );

      if (
         isInvalid ||
         (!isTextBlockLast && isQR) ||
         qrBlock ||
         infBtnBlock ||
         infBtnBlockFieldValue ||
         hasNegativeValue(wordCounts)
      ) {
         setIsBtnSendMsgDisable(true);
      }
   }, [blockState]);

   const channelType = leftNodes.find((node) => node.type === 'trigger')?.data?.flowBlocks[0].channelType;

   return (
      <Grid container className={classes.container} wrap='nowrap'>
         {channelType === FlowInstanceMessageChannel.LiveChat ||
         channelType === FlowInstanceMessageChannel.WhatsappBusiness ||
         channelType === FlowInstanceMessageChannel.Facebook ||
         channelType === FlowInstanceMessageChannel.Instagram ? (
            <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
               <ClickAwayListener onClickAway={() => setActiveTextareaId(null)}>
                  <Grid className={classes.item}>
                     {blockState.map((item) => (
                        <Grid key={item._id}>
                           {item.fields.map((field, index) => {
                              let content = null;
                              if (item.type === FlowBlockType.SendInformativeButtonMessage) {
                                 content = (
                                    <div
                                       onMouseEnter={() => handleSetIsHover(item._id)}
                                       onMouseLeave={() => setIsHover(false)}
                                       style={{ width: '100%' }}
                                    >
                                       <Grid
                                          style={{
                                             display: 'flex',
                                             flexDirection: 'column',
                                             gap: '5px',
                                             width: '100%',
                                             backgroundColor: '#F7F7FA',
                                             border: '1px solid #E7E7E7',
                                             borderRadius: '5px',
                                             marginBottom: '10px',
                                          }}
                                       >
                                          <Box className={classes.boxTextInfinteBtn}>
                                             <textarea
                                                id={`textarea-${item._id}`}
                                                key={item._id}
                                                className={
                                                   field.value && field.value?.length > 1500
                                                      ? classes.textAreaError
                                                      : classes.textAreaInfinteBtn
                                                }
                                                name='rawText'
                                                placeholder={t.atomation_flow_enter_message}
                                                value={field.value}
                                                onFocus={() => setActiveTextareaId(item._id)}
                                                onClick={() => setActiveTextareaId(item._id)}
                                                onChange={(event) => {
                                                   handleUpdateFieldValue(item._id, event.target.value);
                                                }}
                                                {...calculateTextareaSize(field.value)}
                                             />
                                             {activeTextareaId && activeTextareaId === item._id && (
                                                <div style={{ position: 'sticky', top: 0, width: '100%' }} ref={divRef}>
                                                   <div
                                                      className={classes.miniBoxInf}
                                                      onMouseDown={(e) => e.preventDefault()}
                                                   >
                                                      <div onClick={() => handleMenuFieldOpen(item._id)}>
                                                         <DataObjectIcon
                                                            style={{
                                                               display: 'flex',
                                                               justifyContent: 'center',
                                                               fontSize: '18px',
                                                               marginTop: '2px',
                                                            }}
                                                         />
                                                      </div>
                                                      <EmojiEmotionsIcon
                                                         onClick={() => handlePickerFieldOpen(item._id)}
                                                         style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            fontSize: '18px',
                                                            marginTop: '2px',
                                                         }}
                                                      />
                                                      <span
                                                         style={{
                                                            color: 'green',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            fontSize: '14px',
                                                            marginTop: '2px',
                                                         }}
                                                      >
                                                         {wordCounts[item._id] === 0 ? 0 : wordCounts[item._id] || 1500}
                                                      </span>
                                                   </div>
                                                </div>
                                             )}

                                             {showEmojiPicker && activeTextareaId === item._id && (
                                                <ClickAwayListener onClickAway={() => setShowEmojiPicker(false)}>
                                                   <div
                                                      style={{
                                                         zIndex: '9999',
                                                         position: 'sticky',
                                                         marginBottom: '-5px',
                                                      }}
                                                   >
                                                      <Picker
                                                         onSelect={(e) => addEmoji(e, item._id)}
                                                         style={{ width: '100%' }}
                                                         title={t['emoji_selector_title']}
                                                      />
                                                   </div>
                                                </ClickAwayListener>
                                             )}
                                          </Box>
                                          <Grid
                                             style={{ margin: '-15px 10px 10px 10px' }}
                                             onClick={() => setActiveTextareaId(null)}
                                          >
                                             {/* InformativeButton component goes here */}
                                             <InformativeButton
                                                leftNodes={leftNodes}
                                                setIsBtnSendMsgDisable={setIsBtnSendMsgDisable}
                                                clickedNodeId={clickedNodeId}
                                                blockState={blockState}
                                                currentBlock={item}
                                                setBlockState={setBlockState}
                                                setToUpdateQR={setToUpdateQR}
                                                setDeletedBlockId={setDeletedBlockId}
                                             />
                                          </Grid>
                                       </Grid>
                                       {isHover && item._id === hoverId && isDeletable && (
                                          <div
                                             style={{
                                                position: 'absolute',
                                                top: 2,
                                                right: -20,
                                                zIndex: 9999,
                                             }}
                                          >
                                             <Grid onClick={() => handleBlockDelete(item._id)}>
                                                <DeleteIcon className={classes.deleteIcon} />
                                             </Grid>
                                          </div>
                                       )}

                                       {field.informativeButtons &&
                                          field.informativeButtons[0]?.type === 'quickReply' && (
                                             <div>
                                                {field.informativeButtons && field.informativeButtons?.length > 0 && (
                                                   <Grid
                                                      style={{
                                                         display: 'flex',
                                                         justifyContent: 'space-between',
                                                         width: '100%',
                                                         marginBottom: '5px',
                                                         marginTop: '1.2rem',
                                                      }}
                                                   >
                                                      <div style={{ display: 'flex', gap: '3px' }}>
                                                         <InfoIcon style={{ color: '#C3DDFF', marginTop: '-2px' }} />
                                                         <span style={{ fontSize: '14px' }}>
                                                            {t.atomation_flow_can_you_response}
                                                         </span>
                                                      </div>
                                                      <div style={{ marginRight: '-15px' }}>
                                                         <FormControlLabel
                                                            control={
                                                               <CustomSwitch
                                                                  checked={field.isButtonRequired}
                                                                  onChange={() =>
                                                                     handleToggleBtnClicked(
                                                                        item._id,
                                                                        field.isButtonRequired,
                                                                     )
                                                                  }
                                                               />
                                                            }
                                                            label=''
                                                         />
                                                      </div>
                                                   </Grid>
                                                )}
                                                {field.informativeButtons &&
                                                   field.informativeButtons?.length > 0 &&
                                                   field.isButtonRequired && (
                                                      <textarea
                                                         className={classes.textArea}
                                                         name='rawText'
                                                         placeholder={t.atomation_flow_enter_message}
                                                         value={field.requiredButtonMessage || ''}
                                                         onChange={(e) =>
                                                            handleMessageBtnChange(item._id, e.target.value)
                                                         }
                                                         style={{ borderColor: !isValidMessage ? '#FF7474' : '' }}
                                                      />
                                                   )}
                                             </div>
                                          )}
                                    </div>
                                 );
                              }

                              return (
                                 <Grid>
                                    <div key={item._id} style={{ display: 'flex', position: 'relative' }}>
                                       {content}
                                    </div>
                                 </Grid>
                              );
                           })}
                        </Grid>
                     ))}
                     {blockState.map((item) => (
                        <div>
                           {item.fields.map((field, index) => (
                              <div>
                                 {item.type === FlowBlockType.SendMessage &&
                                    field.type === IFlowBlockFieldTypes.Text && (
                                       <div style={{ width: '100%' }}>
                                          <ClickAwayListener onClickAway={() => setActiveTextareaId(null)}>
                                             <Box className={classes.boxText}>
                                                <textarea
                                                   id={`textarea-${item._id}`}
                                                   key={item._id + index}
                                                   className={
                                                      field.value && field.value?.length > 1500
                                                         ? classes.textAreaError
                                                         : classes.textArea
                                                   }
                                                   name='rawText'
                                                   placeholder={t.atomation_flow_enter_message}
                                                   value={field.value}
                                                   onFocus={() => setActiveTextareaId(item._id)}
                                                   onClick={() => setActiveTextareaId(item._id)}
                                                   onChange={(event) => {
                                                      handleBlockInputChange(item._id, event.target.value);
                                                   }}
                                                   {...calculateTextareaSize(field.value)}
                                                />

                                                {activeTextareaId && activeTextareaId === item._id && (
                                                   <div ref={divRef}>
                                                      <div
                                                         className={classes.miniBox}
                                                         onMouseDown={(e) => e.preventDefault()}
                                                      >
                                                         <div onClick={() => handleMenuFieldOpen(item._id)}>
                                                            <DataObjectIcon
                                                               style={{
                                                                  display: 'flex',
                                                                  justifyContent: 'center',
                                                                  fontSize: '18px',
                                                                  marginTop: '2px',
                                                               }}
                                                            />
                                                         </div>
                                                         <EmojiEmotionsIcon
                                                            onClick={() => handlePickerFieldOpen(item._id)}
                                                            style={{
                                                               display: 'flex',
                                                               justifyContent: 'center',
                                                               fontSize: '18px',
                                                               marginTop: '2px',
                                                            }}
                                                         />
                                                         <span
                                                            style={{
                                                               color: 'green',
                                                               display: 'flex',
                                                               justifyContent: 'center',
                                                               fontSize: '14px',
                                                               marginTop: '2px',
                                                            }}
                                                         >
                                                            {wordCounts[item._id] === 0
                                                               ? 0
                                                               : wordCounts[item._id] || 1500}
                                                         </span>
                                                      </div>
                                                   </div>
                                                )}
                                             </Box>
                                          </ClickAwayListener>

                                          {showEmojiPicker && activeTextareaId === item._id && (
                                             <ClickAwayListener onClickAway={() => setShowEmojiPicker(false)}>
                                                <div style={{ zIndex: '9999', position: 'sticky', marginTop: '-15px' }}>
                                                   <Picker
                                                      onSelect={(e) => addEmoji(e, item._id)}
                                                      style={{ width: '100%' }}
                                                      title={t['emoji_selector_title']}
                                                   />
                                                </div>
                                             </ClickAwayListener>
                                          )}
                                       </div>
                                    )}
                              </div>
                           ))}
                        </div>
                     ))}
                     <div style={{ margin: '-15px 0 0 0' }}>
                        <QuickReply
                           leftNodes={leftNodes}
                           setIsBtnSendMsgDisable={setIsBtnSendMsgDisable}
                           clickedNodeId={clickedNodeId}
                           blockState={blockState}
                           setBlockState={setBlockState}
                           setToUpdateQR={setToUpdateQR}
                           setDeletedBlockId={setDeletedBlockId}
                           isBlockTextLast={isBlockTextLast}
                        />
                     </div>
                  </Grid>
               </ClickAwayListener>
               <Grid>
                  <SendMessageButtonPanel
                     handleCreateBlockQuickReply={handleCreateBlockQuickReply}
                     handleCreateBlockInformativeButton={handleCreateBlockInformativeButton}
                     blockState={blockState}
                     isBlockTextLast={isBlockTextLast}
                     leftNodes={leftNodes}
                  />
               </Grid>
            </Grid>
         ) : (
            <div style={{ color: '#FF7474' }}>{channelType} does not support this feature</div>
         )}

         <Menu
            anchorEl={anchorElField}
            open={Boolean(anchorElField)}
            onClose={handleMenuFieldClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{ marginTop: 5 }}
            PaperProps={{
               style: {
                  width: '363px',
                  borderRadius: '5px',
                  marginTop: '20px',
               },
            }}
         >
            <MenuItem onClick={() => addField(t.atomation_flow_first_name_value, menuId)}>
               {t.atomation_flow_first_name}
            </MenuItem>
            <MenuItem onClick={() => addField(t.atomation_flow_last_name_value, menuId)}>
               {t.atomation_flow_last_name}
            </MenuItem>
            <MenuItem onClick={() => addField(t.atomation_full_name_value, menuId)}>{t.atomation_full_name}</MenuItem>
            <MenuItem onClick={() => addField(t.atomation_flow_email_value, menuId)}>{t.atomation_flow_email}</MenuItem>
            <MenuItem onClick={() => addField(t.atomation_flow_phone_value, menuId)}>{t.atomation_flow_phone}</MenuItem>
         </Menu>
      </Grid>
   );
};

export default SendButtonMsgBlocks;

import { gql } from '@apollo/client';

// mutations
export const CREATE_FLOW_BOT_MUTATION = gql`
   mutation CreateFlowBot($input: CreateFlowBotInput!) {
      createFlowBot(input: $input) {
         success
         data {
            _id
            customer
            title
            status
            createdAt
            updatedAt
         }
         code
         message
      }
   }
`;

export const UPDATE_FLOW_BOT_MUTATION = gql`
   mutation UpdateFlowBot($input: UpdateFlowBotInput!) {
      updateFlowBot(input: $input) {
         success
         data {
            _id
            customer
            title
            triggerFields {
               keywords
               rule
               type
               target
               channelType
            }
            status
            createdAt
            updatedAt
         }
         code
         message
      }
   }
`;

export const DELETE_FLOW_BOT_MUTATION = gql`
   mutation DeleteFlowBot($input: DeleteFlowBotInput!) {
      deleteFlowBot(input: $input) {
         success
         data {
            _id
            customer
            title
            status
            createdAt
            updatedAt
         }
         code
         message
      }
   }
`;
export const CREATE_FLOW_NODE_MUTATION = gql`
   mutation CreateFlowNode($input: CreateFlowNodeInput!) {
      createFlowNode(input: $input) {
         success
         code
         message
         data {
            _id
            customer
            flowBot
            title
            type
            coordinates {
               x
               y
            }
            groupId
            templateId
            flowBlocks {
               _id
               customer
               title
               type
               category
               fields {
                  value
                  type
                  note
                  backgroundColor
                  rule
                  condition_field
                  keywords
                  platformIds
                  save_response_to_contact_field
                  question_answer_timeout
                  question_answer_timeout_unit
                  delay_time
                  delay_time_unit
                  target_on_reply
                  target_on_not_reply
                  target_on_invalid_input
                  isButtonRequired
                  requiredButtonMessage
                  liveChatVisitFrequencyUnit
                  startFlowTargetedBot
                  startFlowNodeStep
                  informativeButtons {
                     type
                     title
                     target
                     hideTheEdge
                  }
                  quickReplies {
                     type
                     title
                     target
                     hideTheEdge
                  }
                  requestUrl
                  requestMethod
                  requestHeaders {
                     key
                     value
                  }
                  requestBody
                  requestBodyFormData {
                     key
                     value
                  }
                  requestBodyFormDataUrlEncoded {
                     key
                     value
                  }
                  responseDotNotation {
                     fieldId
                     value
                  }
                  targetOnSuccess
                  targetOnFailure
               }
               target
               templateId
               createdAt
               updatedAt
            }
            createdAt
            updatedAt
         }
      }
   }
`;

export const UPDATE_FLOW_NODE_MUTATION = gql`
   mutation UpdateFlowNode($input: UpdateFlowNodeInput!) {
      updateFlowNode(input: $input) {
         success
         data {
            _id
            customer
            title
            type
            coordinates {
               x
               y
            }
            groupId
            flowBlocks {
               _id
               customer
               title
               type
               category
               target
               templateId
               createdAt
               updatedAt
            }
            createdAt
            updatedAt
         }
         code
         message
      }
   }
`;
export const UPDATE_BLOCK_MUTATION = gql`
   mutation UpdateBlock($input: UpdateBlockInput!) {
      updateBlock(input: $input) {
         success
         data {
            _id
            customer
            flowBot
            flowNode
            title
            type
            category
            fields {
               type
               note
               backgroundColor
               rule
               condition_field
               keywords
               platformIds
               value
               save_response_to_contact_field
               question_answer_timeout
               question_answer_timeout_unit
               delay_time
               delay_time_unit
               target_on_reply
               target_on_not_reply
               target_on_invalid_input
               isButtonRequired
               requiredButtonMessage
               liveChatVisitFrequencyUnit
               startFlowTargetedBot
               startFlowNodeStep
               informativeButtons {
                  type
                  title
                  target
                  hideTheEdge
               }
               quickReplies {
                  type
                  title
                  target
                  hideTheEdge
               }
               sections {
                  title
                  subtitle
                  options {
                     type
                     title
                     description
                     target
                     _id
                  }
                  _id
               }
               requestUrl
               requestMethod
               requestHeaders {
                  key
                  value
               }
               requestBody
               requestBodyFormData {
                  key
                  value
               }
               requestBodyFormDataUrlEncoded {
                  key
                  value
               }
               responseDotNotation {
                  fieldId
                  value
               }
               targetOnSuccess
               targetOnFailure
            }
            target
            templateId
            createdAt
            updatedAt
            channelType
         }
         code
         message
      }
   }
`;
export const UPDATE_ASK_QUESTION_BLOCK_MUTATION = gql`
   mutation UpdateBlockField($input: UpdateBlockFieldInput!) {
      updateBlockField(input: $input) {
         success
         data {
            _id
            customer
            title
            type
            category
            fields {
               type
               rule
               keywords
               value
               save_response_to_contact_field
               question_answer_timeout
               question_answer_timeout_unit
               target_on_reply
               target_on_not_reply
               target_on_invalid_input
            }
            target
            templateId
            createdAt
            updatedAt
         }
         code
         message
      }
   }
`;
export const DELETE_FLOW_NODE_MUTATION = gql`
   mutation DeleteFlowNode($input: DeleteFlowNodeInput!) {
      deleteFlowNode(input: $input) {
         data {
            _id
            customer
            flowBot
            title
            type
            coordinates {
               y
               x
            }
            templateId
            createdAt
            updatedAt
         }
      }
   }
`;
export const ADD_FLOW_BLOCK_MUTATION = gql`
   mutation AddFlowBlock($input: AddFlowBlockInput!) {
      addFlowBlock(input: $input) {
         data {
            _id
            customer
            flowBot
            title
            type
            coordinates {
               x
               y
            }
            templateId
            flowBlocks {
               _id
               customer
               flowBot
               flowNode
               title
               type
               category
               fields {
                  type
                  note
                  backgroundColor
                  rule
                  condition_field
                  keywords
                  platformIds
                  value
                  save_response_to_contact_field
                  question_answer_timeout
                  question_answer_timeout_unit
                  delay_time
                  delay_time_unit
                  target_on_reply
                  target_on_not_reply
                  target_on_invalid_input
                  isButtonRequired
                  requiredButtonMessage
                  liveChatVisitFrequencyUnit
                  startFlowTargetedBot
                  startFlowNodeStep
                  informativeButtons {
                     type
                     title
                     target
                     hideTheEdge
                  }
                  quickReplies {
                     type
                     title
                     target
                     hideTheEdge
                  }

                  requestUrl
                  requestMethod
                  requestHeaders {
                     key
                     value
                  }
                  requestBody
                  requestBodyFormData {
                     key
                     value
                  }
                  requestBodyFormDataUrlEncoded {
                     key
                     value
                  }
                  responseDotNotation {
                     fieldId
                     value
                  }
                  targetOnSuccess
                  targetOnFailure
                  startFlowTargetedBot
                  startFlowNodeStep
               }
               target
               templateId
               createdAt
               updatedAt
               channelType
            }
            isValid
            createdAt
            updatedAt
         }
      }
   }
`;
export const BLOCK_REPLACE_INDEX_MUTATION = gql`
   mutation BlockReplaceIndex($input: BlockReplaceIndexInput!) {
      blockReplaceIndex(input: $input) {
         success
         data {
            flowBlocks {
               _id
               customer
               title
               type
               category
               fields
               target
               templateId
               createdAt
               updatedAt
            }
         }
         code
         message
      }
   }
`;
export const REMOVE_BLOCK_FROM_NODE_MUTATION = gql`
   mutation RemoveBlockFromNode($input: RemoveBlockFromNodeInput!) {
      removeBlockFromNode(input: $input) {
         success
         code
         message
         data {
            _id
            customer
            flowBot
            title
            type
            coordinates {
               x
               y
            }
            templateId
            flowBlocks {
               _id
               customer
               title
               type
               category
               target
               templateId
               createdAt
               updatedAt
            }
            createdAt
            updatedAt
         }
      }
   }
`;
export const REMOVE_BLOCKS_FROM_NODE_MUTATION = gql`
   mutation RemoveBlocksFromNode($input: RemoveBlocksFromNodeInput!) {
      removeBlocksFromNode(input: $input) {
         success
         code
         message
         data {
            _id
            customer
            flowBot
            title
            type
            coordinates {
               x
               y
            }
            templateId
            flowBlocks {
               _id
               customer
               title
               type
               category
               target
               templateId
               createdAt
               updatedAt
            }
            createdAt
            updatedAt
         }
      }
   }
`;
export const UPDATE_ECOMMERCE_RULE_STATUS = gql`
   mutation UpdateECommerceRulesStatus($input: UpdateECommerceRulesStatusInput) {
      updateECommerceRulesStatus(input: $input) {
         success
         data {
            _id
            customer
            ruleTemplate
            title
            type
            status
            message1 {
               delay {
                  delayTime
                  delayTimeUnit
               }
               sendMessage {
                  messageTemplateId
               }
               action {
                  ruleId
                  rule
               }
            }
            message2 {
               delay {
                  delayTime
                  delayTimeUnit
               }
               sendMessage {
                  messageTemplateId
               }
               action {
                  ruleId
                  rule
               }
            }
            createdAt
            updatedAt
         }
         code
         message
      }
   }
`;
export const UPDATE_ECOMMERCE_RULE = gql`
   mutation UpdateECommerceRules($input: UpdateECommerceRulesInput) {
      updateECommerceRules(input: $input) {
         success
         message
         code
         data {
            _id
            customer
            ruleTemplate
            title
            type
            status
            message1 {
               delay {
                  delayTime
                  delayTimeUnit
               }
               sendMessage {
                  messageTemplateId
               }
               action {
                  ruleId
                  rule
               }
            }
            message2 {
               delay {
                  delayTime
                  delayTimeUnit
               }
               sendMessage {
                  messageTemplateId
               }
               action {
                  ruleId
                  rule
               }
            }
            createdAt
            updatedAt
         }
      }
   }
`;
export const CREATE_ECOMMERCE_RULE_STATUS = gql`
   mutation CreateECommerceRules($input: CreateECommerceRulesInput) {
      createECommerceRules(input: $input) {
         success
         data {
            _id
            customer
            ruleTemplate
            title
            type
            status
            message1 {
               delay {
                  delayTime
                  delayTimeUnit
               }
               sendMessage {
                  messageTemplateId
               }
               action {
                  ruleId
                  rule
               }
            }
            message2 {
               delay {
                  delayTime
                  delayTimeUnit
               }
               sendMessage {
                  messageTemplateId
               }
               action {
                  ruleId
                  rule
               }
            }
            createdAt
            updatedAt
         }
         code
         message
      }
   }
`;
export const DELETE_ECOMMERCE_RULE_STATUS = gql`
   mutation DeleteECommerceRules($input: DeleteECommerceRulesInput) {
      deleteECommerceRules(input: $input) {
         success
         data {
            _id
            customer
            ruleTemplate
            title
            type
            status
            message1 {
               delay {
                  delayTime
                  delayTimeUnit
               }
               sendMessage {
                  messageTemplateId
               }
               action {
                  ruleId
                  rule
               }
            }
            message2 {
               delay {
                  delayTime
                  delayTimeUnit
               }
               sendMessage {
                  messageTemplateId
               }
               action {
                  ruleId
                  rule
               }
            }
            createdAt
            updatedAt
         }
         code
         message
      }
   }
`;
export const DUPLICATE_FLOW_BOT = gql`
   mutation DuplicateFlowBot($input: DuplicateFlowBotInput!) {
      duplicateFlowBot(input: $input) {
         code
         message
         success
         data {
            _id
            customer
            title
            valid
            triggerFields {
               keywords
               rule
               type
               target
               channelType
               platformIds
            }
            status
            createdAt
            updatedAt
         }
      }
   }
`;

import React, { FC, useContext } from 'react';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
import { TabProps } from '@material-ui/core/Tab/';
import Person from '@material-ui/icons/Person';
import Public from '@material-ui/icons/Public';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import Activity from '../Tabs/Activity';
import Profile from '../Tabs/Profile';
import ECommerce from '../Tabs/E-Commerce';
import { LanguageContext } from '@helper/locale/langContext';

const StyledTabs = withStyles({
   root: {
      borderBottom: '1px solid #e8e8e8',
      height: 50,
      flexShrink: 0,
   },
   indicator: {
      backgroundColor: '#1890ff',
   },
})(Tabs);

const StyledTab = withStyles((theme: Theme) =>
   createStyles({
      root: {
         textTransform: 'none',
         minWidth: 72,
         height: 50,
         minHeight: 50,
         // flex: 1,
         padding: 5,
         '&:hover': {
            color: '#40a9ff',
            opacity: 1,
         },
         '&$selected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
         },
         '&:focus': {
            color: '#40a9ff',
         },
      },
      wrapper: {
         flexDirection: 'row',
         '& svg': {
            margin: 6,
         },
      },
      selected: {},
   })
)((props: TabProps) => <Tab {...props} />);

const useStyles = makeStyles((theme: Theme) => ({
   root: {
      flexGrow: 1,
   },
   padding: {
      padding: theme.spacing(3),
   },
   container: {
      // flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      height: 'calc(100vh - 72px)', // Header height
   },
   labelContainer: {
      width: 'auto',
      padding: 0,
   },
   iconLabelWrapper: {
      flexDirection: 'row',
   },
}));

interface TabPanelProps {
   children?: React.ReactNode;
   index: any;
   value: any;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         style={{ height: 'calc(100vh - 180px)', display: value === index ? 'flex' : 'none', flexDirection: 'column', overflow: index == 1 ? "scroll" : "auto", overflowX: "hidden" }}
         {...other}
      >
         {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
   );
}

interface TabViewProps {
   activeChatId: string;
}

const TabView: FC<TabViewProps> = ({ activeChatId }) => {
   const classes = useStyles();
   const [value, setValue] = React.useState(0);
   const [personLoading, setPersonLoading] = React.useState(false);

   React.useEffect(() => {
      if (personLoading) {
         setPersonLoading(false);
      }
   }, [personLoading]);

   const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
      setValue(newValue);
   };
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   return (
      <div className={classes.container}>
         <StyledTabs value={value} onChange={handleChange} aria-label='ant example'>
            <StyledTab label={t['profile']} icon={<Person />} />
            <StyledTab label={t['activity']} icon={<Public />} />
            <StyledTab label={t['store']} icon={<ShoppingCartIcon />} />
         </StyledTabs>
         <TabPanel value={value} index={0}>
            {!personLoading && <Profile setPersonLoading={setPersonLoading} activeChatId={activeChatId} />}
         </TabPanel>
         <TabPanel value={value} index={1}>
            <Activity />
         </TabPanel>
         <TabPanel value={value} index={2}>
            <ECommerce />
         </TabPanel>
      </div>
   );
};

export default TabView;

import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { AVATAR_SIZE } from "@modules/Chat/MainPanel/ChatSpace/ChatItemsContainer/Message";
import {Avatar, CircularProgress, Typography, Tooltip} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import LinkedFormattedText from "@components/LinkedFormattedText";
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import { ExternalLinkIcon } from '@assets/icons/ProfileIcons';
import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import FileViewer from "@components/FileViewer";

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display:'flex',
    alignSelf: 'flex-end',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    gap: 10,
    marginTop: 4,
    marginBottom: 4,
    marginRight: 20,
    width: 320,
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    padding: 5,
    borderRadius: 12,
    backgroundColor: '#F2F7FF',
  },
  cardMedia: {
    width: '100%',
    height: 'auto',
    borderRadius: 12 - 5,
    maxHeight: 270,
    padding: 0,
    margin: 0,
    'object-fit': 'cover',
    'object-position': 'top',
  },
  contentContainer: {
    padding: 7,
  },
  content: {
    padding: 7,
    textAlign: 'left',
  },
  avatarContainer: {
    flexShrink: 0,
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    display: 'flex',
    alignSelf: 'flex-end',
  },
  avatar: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
  },
  senderNameAndTime: {
    color: '#868686',
    marginRight: AVATAR_SIZE + 10,
    marginLeft: 10,
    fontSize: '0.7rem',
  },
  buttonContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    padding: 8,
    borderRadius: 12 - 5,
    marginTop: 5,
    color: '#2183FC',
    backgroundColor: '#FFFFFF',
  },
  buttonContent: {
    display: 'flex',
    alignItems:'center',
    cursor: "default",
    gap: 5,
  },
  messageAck: {
    position: 'absolute',
    left: '-25px',
    height: '100%',
    top: 4,
    justifyContent: 'center',
    display: 'flex',
    color: '#848484',
    // paddingLeft: 7px;
    alignItems: 'end',
  },
}));

enum TemplateType {
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  DOCUMENT = 'DOCUMENT',
}

const getTemplateType = (type: string): TemplateType => {
  switch (type) {
    case TemplateType.TEXT:
      return TemplateType.TEXT;
    case TemplateType.IMAGE:
      return TemplateType.IMAGE;
    case TemplateType.VIDEO:
      return TemplateType.VIDEO;
    case TemplateType.DOCUMENT:
      return TemplateType.DOCUMENT;
    default:
      return TemplateType.TEXT;
  }
}

enum IconType {
  EXTERNAL_LINK = 'EXTERNAL_LINK',
  PHONE = 'PHONE',
}

const getContentAndButtonsWithParams = (content: string, params: Array<string>): { buttons: { label: string; iconType?: IconType | undefined }[]; content: string } => {
  let [temporaryMessage, ...temporaryButtons] = content.split('|');
  temporaryMessage = temporaryMessage.trim();
  let message = temporaryMessage
  params.forEach((element: string, index: number) => {
    const number = index + 1 ;
    if (Number.isInteger(number)) {
      message = message?.replaceAll(`{{${number}}}`, element);
    }
  });
  const buttons = temporaryButtons.map((temporaryButton) => {
    let button = temporaryButton.trim();
    button = button.slice(1, button.length - 1);
    const [temporaryLabel, url] = button.split(',');
    return {label: temporaryLabel.trim(), ...( url && { iconType: url.match(/^\d+/) ? IconType.PHONE : IconType.EXTERNAL_LINK})}
  });
  return { content: message, buttons };
};

const getButtonIcon = (iconType: IconType | undefined) => {
  switch (iconType) {
    case IconType.PHONE:
      return <PhoneRoundedIcon style={{ width: 20, height: 20 }} />
    case IconType.EXTERNAL_LINK:
      return <ExternalLinkIcon width={20} height={20} />
    default:
      return null;
  }
}

interface IWhatsAppBusinessTemplate {
  item: any;
  ack: any;
  time: string;
  senderTitle: string;
}

const WhatsAppBusinessTemplate = ({ item, ack, senderTitle, time }: IWhatsAppBusinessTemplate) => {
  const classes = useStyles();
  const [timeVisible, setTimeVisible] = useState(false);
  const [showDoubleThick, setShowDoubleThick] = useState(false);
  const [changedAckStatus, setChangedAckStatus] = useState(false);

  const toggleTimeVisible = () => {
    setTimeVisible(!timeVisible);
  };

  const templateType: TemplateType = getTemplateType(item?.template_cards?.templateType)
  const { content, buttons } = getContentAndButtonsWithParams(item?.content || '', item?.template_cards?.template?.params || [])
  let media;
  if (item?.template_cards?.message) media = JSON.parse(item?.template_cards?.message);

  useEffect(() => {
    if (ack == 0) {
      setChangedAckStatus(true);
    }
    if (ack == 2) {
      if (changedAckStatus) {
        setTimeout(() => {
          setShowDoubleThick(true);
        }, 500);
      } else {
        setShowDoubleThick(true);
      }
    }
  }, [ack]);

  return (
    <>
      {timeVisible && (
        <Grid container justifyContent={'flex-end'}>
          <Typography className={classes.senderNameAndTime}>
            {`${senderTitle} | ${time}`}
          </Typography>
        </Grid>
      )}
    <div onClick={toggleTimeVisible} className={classes.root}>
      <Grid item className={classes.messageAck}>
        {(ack == 0) && (
          <CircularProgress
            style={{ marginRight: 10, padding: 2 }}
            size={15}
            color="primary"
          />
        )}
        {ack == 1 && (
          <CheckIcon
            style={{ marginRight: 10, padding: 2 }}
            fontSize='small'
          />
        )}
        {ack == 2 && !showDoubleThick && (
          <CheckIcon
            style={{ marginRight: 10, padding: 2 }}
            fontSize='small'
          />
        )}
        {((ack == 2 && showDoubleThick)) && (
          <Grid item>
            <CheckIcon style={{ padding: 2 }} fontSize='small' />
            <CheckIcon
              style={{
                padding: 2,
                marginRight: '-6px',
                position: 'absolute',
                right: '2px',
              }}
              fontSize='small'
            />
          </Grid>
        )}
        {ack == 3 && (
          <Tooltip title={item.reason?.message || ''}>
            <ErrorIcon
              style={{
                padding: 0,
                color: 'red',
                marginBottom: 2,
              }}
              fontSize='small'
            />
          </Tooltip>
        )}
      </Grid>
      <div className={classes.card}>
        <div>
          {templateType && media?.type && media[media?.type]?.link && (
            <FileViewer
              showPreview
              type={media?.type}
              url={media[media?.type]?.link}
              styles={{
                width: '100%',
                height: 'auto',
                borderRadius: 12 - 5,
                maxHeight: 270,
                padding: 0,
                margin: 0,
                'object-fit': 'cover',
                'object-position': 'top',
              }} onShow={(e:any) => {}} name="" isLoading={false} onRemove={undefined} disabled={undefined} />
          )}
          <Typography className={classes.content}>
            {content && (
              // @ts-ignore
              <LinkedFormattedText text={content} />
            )}
          </Typography >
        </div>
        {buttons?.map?.((item) => (
          <div className={classes.buttonContainer}>
            <div className={classes.buttonContent}>
              {getButtonIcon(item.iconType)}
              <Typography>{item.label}</Typography>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.avatarContainer}>
        {item?.sender?.photo && (
          <Avatar src={item?.sender?.photo} className={classes.avatar} />
        )}
      </div>
    </div>
    </>
  );
};

export default WhatsAppBusinessTemplate;

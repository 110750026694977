import { gql } from '@apollo/client';

export const GET_DASHBOARDS_STATISTICS_QUERY = gql`
   query getDashboardsStatisticsQuery($customer: ID!) {
      getDashboardsStatistics(customer: $customer) {
         data {
            activeChatsCount
            waitingChatsCount
            closedChatsCount
            solvedChatsCount
            spammedChatsCount
            #
            receivedToday
            sentToday
            #
            facebookReceived
            instagramReceived
            whatsAppWebReceived
            whatsAppBusinessReceived
            liveChatReceived
            chartReport {
               title
               received
               sent
            }
         }
         success
         code
         message
      }
   }
`;

export const GET_DASHBOARDS_COMMENTS_STATISTICS_QUERY = gql`
   query getCommentsDashboardStatistics {
      QnAChatStatistics
   }
`;

// For the right panel
export const GET_DASHBOARDS_COMMENTS_PLATFORMS_STATISTICS_QUERY = gql`
   query getCommentsPlatformDashboardStatistics($date: QnAStatisticsEnum!) {
      getQnAStatistics(input: { date: $date })
   }
`;

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { LiveChatIcon, SettingsWhatsappBusinessIcon, WhatsappIcon } from '@assets/icons/SettingsIcons/settingsIcons';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Hidden, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import WithUrlTranslations from '../../hoc/WithUrlTranslations';
import TopBar from './components/TopBar';
import TabBar from './components/tabBar';
import VirtualPhone from './components/virtualPhone';

import { UPDATE_CAMPAIGN } from '@queries/Campaigns/Service/GraphQL/mutation';
import { GET_CAMPAIGN } from '@queries/Campaigns/Service/GraphQL/query';
import { GET_CUSTOMER_SATISFACTION_SURVEYS } from '@queries/Settings/Service/GraphQL/CustomerSatisfaction/query';
import { GET_PLATFORMS_QUERY } from '@queries/Settings/Service/GraphQL/Platform/query';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { GET_RECIPIENTS, GET_RECIPIENTS_COUNT } from '@queries/Campaigns/Service/GraphQL/query';
import { GET_GROUP_LIST } from '@queries/PersonGroups/Service/GraphQL/query';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useHistory } from 'react-router-dom';
import SelectAnother from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import CustomInput from './components/CustomInput';
import MediaUpload from './components/MediaUpload';
import SendDialog from './components/SendDialog';
import TemplateContent from './components/TemplateContent';
import TemplateSelector from './components/TemplateSelector';
import TestDialog from './components/TestDialog';
import VariableSelector from './components/VariableSelector';
import PhotoHolder from './components/photoHolder';

const Campaigns = () => {
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const useStyles = makeStyles((theme) => ({
      content: {
         flexGrowY: 1,
         height: '100vh',
         width: '100%',
         minWidth: isSmallScreen && 1000,
         overflow: 'hidden',
         [theme.breakpoints.down('sm')]: {
            minHeight: '-webkit-fill-available',
            height: '100%',
         },
         backgroundColor: '#F7F7FA',
         borderRadius: 16,
         border: '10px solid F7F7FA',
         paddingTop: 60,
      },
      surveyContent: {
         fontSize: '0.9rem',
         wordBreak: 'break-all',
      },
      answerContainer: {
         marginTop: -4,
         maxHeight: 40,
      },
      container: {
         height: '100%',
         width: '100%',
      },
      appBarSpacer: theme.mixins.toolbar,
      footer: {
         backgroundColor: 'green',
         borderTop: '1px solid #E7E7E7',
         textAlign: 'center',
         padding: 20,
         position: 'fixed',
         left: 527,
         bottom: 0,
         minHeigh: 100,
      },
      profileBackgroundContainer: {
         backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      },
      noChatIcon: {
         width: 200,
         height: 200,
      },
      noChatText: {
         color: '#ccd8e8',
         fontSize: '1.1rem',
         fontWeight: 'bold',
         marginTop: theme.spacing(4),
      },
      leftPanel: {
         display: 'block',
         height: '100%',
         backgroundColor: 'white',
         // background: '#F7F7FA',
         width: 403,
         [theme.breakpoints.down('sm')]: {
            width: '100%',
         },
         borderRight: '2px solid #f7f7fa',
      },
      rightPanel: {
         backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      },
      tab: {
         height: 'calc(100% - 115px)',
         overflowY: 'auto',
         overflowX: 'hidden',
      },
      tabSection: {
         width: isSmallScreen ? '100%' : 350,
         marginTop: 5,
         backgroundColor: 'white',
         paddingRight: 10,
         paddingLeft: 16,
      },
      tabSectionTitle: {
         marginTop: 5,
         marginBottom: 0,
         paddingLeft: 2,
         fontSize: '1.1rem',
         fontWeight: 700,
      },
      dynamicURL: {
         margin: 0,
         paddingLeft: 2,
         color: 'rgb(21, 124, 252)',
         fontSize: 14,
      },
      tabSectionSlient: {
         margin: 0,
         paddingLeft: 2,
         color: '#696969',
         fontSize: 14,
      },
      selectTemplate: {
         width: '100%',
         border: '2px solid #D1D1D1',
         marginTop: 5,
         marginBottom: 10,
         backgroundColor: 'white',
         display: 'flex',
      },
      datePickerChildrenDivider: {
         display: 'flex',
         flexGrow: 1,
         width: '100%',
      },
      datePickerChildren: {
         display: 'flex',
         flexDirection: 'column',
         color: 'red',
      },
      datePickerChildrenRow: {
         display: 'flex',
         justifyContent: 'space-between',
         width: '326px',
      },
      listIcon: {
         width: 20,
         height: 20,
         display: 'block',
         marginLeft: 'auto',
         marginRight: 'auto',
      },
      scheduleRatioBox: {
         border: '2px solid #d1d1d1',
         borderRadius: 7,
         width: '100%',
         paddingLeft: 10,
         color: 'gray',
      },
      menuItem: {
         height: 45,
      },
      IconContainer: {
         width: 35,
         height: 35,
         borderRadius: 10,
         textAlign: 'center',
         padding: 8,
         marginRight: 10,
      },
      popperPaper: {
         width: 450,
         height: 500,
         overflow: 'hidden',
         padding: 10,
      },
      popperHeader: {
         display: 'flex',
         height: 50,
      },
      popperTitle: {
         marginTop: 7,
      },
      popperCloseBtn: {
         marginLeft: 'auto',
      },
      popperError: {
         whiteSpace: 'pre-line',
      },
      popperBody: {
         overflow: 'auto',
         height: 'calc(100% - 50px)',
         padding: 5,
      },
      fullScreenLoader: {
         position: 'fixed',
         top: 0,
         left: 0,
         width: '100%',
         height: '100%',
         backgroundColor: 'rgba(0,0,0,0.5)',
         zIndex: 9999,
      },
      modal: {
         userSelect: 'none',
      },
   }));

   const ITEM_HEIGHT = 100;
   const ITEM_PADDING_TOP = 1;
   const RECIPIENTS_PAGE_SIZE = 10;
   const GROUP_PAGE_SIZE = 10;
   const MenuProps = {
      anchorOrigin: {
         vertical: 'bottom',
         horizontal: 'left',
      },
      getContentAnchorEl: null,
      transformOrigin: {
         vertical: 'top',
         horizontal: 'left',
      },
      PaperProps: {
         style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: !isSmallScreen ? 324 : 440,
         },
      },
   };

   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const campaignId = useParams()._id;
   const t = lang?.translation;
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const person = useSelector((reducer) => reducer.personReducer.person);
   const customerId = person.customer._id;

   const [templateAnchorElPopper, setTemplateAnchorElPopper] = useState(null);
   const [selectedSurvey, setSelectedSurvey] = useState(null);
   const [recipientsData, setRecipientsData] = useState(null);
   const [groupData, setGroupData] = useState(null);
   const [whichContactType, setWhichContactType] = useState({
      value: null,
      label: null,
   });
   var [selectedIdx, setSelectedIdx] = useState(0);

   var [campaignSettings, setCampaignSettings] = useState({
      status: '',
      campaignName: 'New Campaign',
      platform_type: 'WHATS_APP',
      platform_id: '',
      group_recipients: [],
      dynamic_group_recipients: [],
      user_recipients: [],
      total_recipient_count: 0,
      media: null,
      schedule: null,
      survey: null,

      content: '',
      contentMarkup: '',
      buttons: [],

      startDate: new Date(),
      template: null,
      templateText: null,
      template_params: null,
   });
   const [isSendDialogOpen, setSendDialogOpen] = useState(false);
   const [isTestDialogOpen, setTestDialogOpen] = useState(false);
   const [isSurveySelectOpen, setIsSurveySelectOpen] = useState(false);
   let customer = person.customer._id;
   const [isSaving, setSaving] = useState(false);
   const history = useHistory();

   const [platforms, setPlatforms] = useState({
      acquired_platforms: [],
      data: [],
   });

   const surveyPage = {
      pageSize: 15,
      page: 1,
   };

   const satisfactionCustomerParam = {
      customer: customerId,
      pageSize: surveyPage.pageSize,
      pageNumber: surveyPage.page,
      name: '',
      status: true,
   };

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, history, enqueueSnackbar, closeSnackbar, t]);

   function resolveTemplate(template) {
      const wrap = (s, w) => s.replace(new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n');

      let text = template.message.split('|')[0];
      let url = template.message.includes('|') ? template.message.split('|').slice(1).join('|') : '';

      let buttons = [];
      if (url) {
         let btn_part = url.split('|');
         for (let i = 0; i < btn_part.length; i++) {
            let button = btn_part[i];
            let is_url =
               button.includes(',') &&
               /([(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*))/g.test(
                  button,
               );
            let is_phone = button.includes(',') && /((?:\+|)[0-9]+) ([0-9]+)/g.test(button);

            if (is_url) {
               let content = button.match(/(?:\[)(.+)(?:\])/g)[0].replace(/[\[\]]/g, '');
               let title = content.split(',')[0];
               let url = content.split(',')[1];
               buttons.push({
                  type: 'url',
                  title: title,
                  url: url,
               });
            } else if (is_phone) {
               let content = button.match(/(?:\[)(.+)(?:\])/g)[0].replace(/[\[\]]/g, '');
               let title = content.split(',')[0];
               let phone = content.split(',')[1];
               buttons.push({
                  type: 'phone',
                  title: title,
                  phone: phone,
               });
            } else {
               let content = button.match(/(?:\[)(.+)(?:\])/g)[0].replace(/[\[\]]/g, '');
               buttons.push({
                  type: 'quick_reply',
                  title: content,
               });
            }
         }
      }

      let value = wrap(text, 37);
      value = value.replace(/{{(.*?)}}/g, '<span style="color: #147CFC;">{{$1}}</span>');

      let params = [];

      let textParams = text.match(/{{(.*?)}}/g);
      let urlParams = url.match(/{{(.*?)}}/g);
      if (template.qpienFields) {
         params = template.qpienFields.map((field) => {
            return {
               key: field.key,
               value: 'tokens_' + field.value,
               type: 'TEXT',
               ref: field.ref,
            };
         });
      } else if (textParams) {
         for (let i = 0; i < textParams.length; i++) {
            params.push({
               key: i + 1,
               value: '',
               type: 'TEXT',
               ref: 'none',
            });
         }
      }

      if (template?.buttons?.find((x) => x.type === 'URL' && x.url.includes('{{') && x.url.includes('}}'))) {
         let urlBtn = template.buttons.find((x) => x.type === 'URL');
         let urlExample = urlBtn.example;
         if (urlExample && urlExample.length > 0) {
            let urlParam = urlExample[0]
               .replace(/((?:http|https):\/\/)/g, '')
               .split('/')
               .slice(1)
               .join('/');
            params.push({
               key: 1,
               value: urlParam,
               type: 'URL',
               ref: 'none',
            });
         }
      }

      if (urlParams && !template?.buttons?.find((x) => x.type === 'URL')) {
         for (let i = 0; i < urlParams.length; i++) {
            params.push({
               key: i + 1,
               value: '',
               type: 'URL',
               ref: 'none',
            });
         }
      }
      return {
         ...(params.length ? { template_params: params } : {}),

         media: template?.sampleMedia?.file
            ? [
                 {
                    ...template.sampleMedia,
                    path: template?.sampleMedia?.file,
                 },
              ]
            : [],

         template,
         contentMarkup: value,
         buttons,
      };
   }

   function isPlatformApproved(platform, account) {
      switch (platform) {
         case 'WHATS_APP':
            if (account.id.startsWith('business')) return account.status === 'Approved';
            else return account.status;
         default:
            throw new Error('Unimplemented platform');
      }
   }

   const isSendDisabled = () => {
      if ((campaignSettings.content.includes(t.tokens_survey) || campaignSettings.survey) && !selectedSurvey) {
         enqueueSnackbar(t['campaign_survey_not_selected'], {
            variant: 'error',
            autoHideDuration: 1500,
         });
         return true;
      }
      return false;
   };

   const { loading: wpWebLoading } = useQuery(GET_PLATFORMS_QUERY, {
      variables: {
         customer,
         type: 'WHATS_APP_WEB',
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getPlatforms }) => {
         setPlatforms({
            data: [
               ...platforms.data,
               ...getPlatforms.data.map((x) => ({
                  ...x,
                  id: (x.type == 'WHATS_APP_WEB' ? 'web_' : 'business_') + x._id,
               })),
            ],
         });
      },
   });

   const { loading: wpBusinessLoading } = useQuery(GET_PLATFORMS_QUERY, {
      variables: {
         customer,
         type: 'WHATS_APP_BUSINESS',
      },
      fetchPolicy: 'no-cache',
      onCompleted: ({ getPlatforms }) => {
         setPlatforms({
            data: [
               ...platforms.data,
               ...getPlatforms.data.map((x) => ({
                  ...x,
                  id: (x.type == 'WHATS_APP_WEB' ? 'web_' : 'business_') + x._id,
               })),
            ],
         });
      },
   });

   const [updateCampaignMutation, { loading: updateCampaignLoading }] = useMutation(UPDATE_CAMPAIGN, {
      async update(
         cache,
         {
            data: {
               updateCampaign: { data: updateCampaign },
            },
         },
      ) {},
   });

   let tokens = ['tokens_first_name', 'tokens_last_name', 'tokens_email', 'tokens_phone', 'tokens_survey'];

   const [getSatisfactionData, { data: satisfactionCustomerData }] = useLazyQuery(GET_CUSTOMER_SATISFACTION_SURVEYS, {
      fetchPolicy: 'no-cache',
   });

   const [getCampaign] = useLazyQuery(GET_CAMPAIGN, {
      fetchPolicy: 'no-cache',
      onError: (error) => {
         window.location.href = '/campaigns';
      },
      onCompleted: ({ getCampaign }) => {
         let {
            status,
            name,
            schedule_date,
            schedule_type,
            group_recipients,
            dynamic_group_recipients,
            user_recipients,
            total_recipient_count,
            media,
            platform_type,
            platform_id,
            content,
            whats_app_business_template_id,
            whats_app_business_template_params,
         } = getCampaign.data;
         let value = content;

         setWhichContactType(
            user_recipients.length > 0
               ? { value: 0, label: t?.campaign_contacts }
               : group_recipients.length > 0
               ? { value: 1, label: t?.campaign_manuel_groups }
               : dynamic_group_recipients.length > 0
               ? { value: 2, label: t?.campaign_dynamic_groups }
               : { value: 0, label: t?.campaign_contacts },
         );

         tokens.forEach((token) => {
            let local = t[token];
            value = value.replaceAll('{' + token + '}', local);
         });

         const wrap = (s, w) => s.replace(new RegExp(`(?![^\\n]{1,${w}}$)([^\\n]{1,${w}})\\s`, 'g'), '$1\n');

         let newContent = wrap(value, 37);
         let contentMarkup = wrap(value, 37);

         tokens.forEach((token) => {
            let local = t[token];
            contentMarkup = contentMarkup.replaceAll(local, `<span style="color: #147CFC;">${local}</span>`);
         });
         let resolvedTemplate = {};
         if (whats_app_business_template_id) {
            resolvedTemplate = resolveTemplate(whats_app_business_template_id);
         }
         if (whats_app_business_template_params.length) {
            if (resolvedTemplate) {
               resolvedTemplate.template_params = whats_app_business_template_params;
            } else {
               campaignSettings.template_params = whats_app_business_template_params;
            }
         }

         setCampaignSettings({
            ...campaignSettings,
            campaignName: name,
            status,
            platform_type,
            group_recipients,
            dynamic_group_recipients,
            user_recipients,
            total_recipient_count,
            media: media ? (Array.isArray(media) ? media : [media]) : [],
            platform_id,
            template: whats_app_business_template_id,
            schedule: schedule_type === 0 ? null : moment(schedule_date),
            content: newContent,
            contentMarkup,
            startDate: getCampaign?.data?.whats_app_business_id?.createdAt || new Date(),
            ...resolvedTemplate,
         });

         if (newContent.includes(t.tokens_survey)) {
            setIsSurveySelectOpen(true);
            getSatisfactionData({
               variables: {
                  ...satisfactionCustomerParam,
               },
            });
         }
         if (getCampaign.data?.survey) {
            setSelectedSurvey(getCampaign.data?.survey);
            getSatisfactionData({
               variables: {
                  ...satisfactionCustomerParam,
               },
            });
         }
      },
   });

   useEffect(() => {
      getCampaign({
         variables: {
            input: {
               _id: campaignId,
            },
         },
      });
   }, [getCampaign, campaignId]);

   const DatePickerChildren = () => {
      return (
         <div>
            <Divider className={classes.datePickerChildrenDivider} />
            <div className={classes.datePickerChildren}>
               <div className={classes.datePickerChildrenRow}></div>
            </div>
         </div>
      );
   };

   const kbValueNumberFormatter = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow',
   });

   const handleUploadFiles = async (files, wpBusiness) => {
      if (!files) {
         return;
      }

      let medias = [];
      for (const _file of files) {
         let file = _file;
         let [file_type, file_ext] = file.type.split('/');
         let size_limit =
            file_type === 'png' || file_type === 'jpeg' ? 2000000 : file_type === 'mp4' ? 20000000 : 2000000;
         if (file.size > size_limit) {
            enqueueSnackbar(
               `${t?.campaign_upload_size_limit.replace('size', kbValueNumberFormatter.format(size_limit))}`,
               {
                  variant: 'error',
                  autoHideDuration: 1500,
               },
            );
            return false;
         }
         medias.push({
            name: file.name,
            size: file.size,
            type: file.type,
            file: file,
         });
      }

      setCampaignSettings({
         ...campaignSettings,
         media: [...(campaignSettings.media && !wpBusiness ? campaignSettings.media : []), ...medias],
      });
   };

   let media = campaignSettings.media || campaignSettings.whats_app_business_template_id?.media;

   var saveCampaign = async () => {
      return new Promise((resolve, reject) => {
         setSaving(true);
         let updateCampaignInput = {
            _id: campaignId,
            platform_id: campaignSettings.platform_id,
            media: media ? (Array.isArray(media) ? media : [media]) : [],
            schedule_type: campaignSettings.schedule ? 1 : 0,
            schedule_date: campaignSettings.schedule,
            group_recipients: campaignSettings.group_recipients.map((group) => group.value),
            dynamic_group_recipients: campaignSettings.dynamic_group_recipients.map((group) => group.value),
            user_recipients: campaignSettings.user_recipients.map((user) => user.value),
            ...(selectedSurvey && { survey: selectedSurvey }),
         };
         switch (campaignSettings.platform_type) {
            case 'WHATS_APP': {
               if (campaignSettings.platform_id.startsWith('business')) {
                  updateCampaignInput.template_params = campaignSettings.template_params;
                  updateCampaignInput.template_id = campaignSettings.template._id;
               } else {
                  let value = campaignSettings.content;
                  tokens.forEach((token) => {
                     let local = t[token];
                     value = value.replaceAll(local, '{' + token + '}');
                  });
                  updateCampaignInput.content = value;
               }
               break;
            }
            default:
               throw new Error('Platform type not found or unimplemented');
         }

         updateCampaignMutation({
            variables: updateCampaignInput,
         })
            .then((res) => {
               setSaving(false);
               resolve();
            })
            .catch((_) => {
               enqueueSnackbar(t['connection_progress_error'], {
                  variant: 'error',
                  autoHideDuration: 1500,
               });
               setSaving(false);
               reject();
            });
      });
   };

   const onSelectVariableFunc = (content, contentMarkup) => {
      setCampaignSettings({
         ...campaignSettings,
         content,
         contentMarkup,
      });
      // One time request for satisfaction survey data
      if (content.includes(t.tokens_survey) && !satisfactionCustomerData) {
         getSatisfactionData({
            variables: {
               ...satisfactionCustomerParam,
            },
         });
      }
      if (content.includes(t.tokens_survey)) {
         setIsSurveySelectOpen(true);
      } else {
         setIsSurveySelectOpen(false);
         setSelectedSurvey(null);
      }
   };

   const [getRecipients] = useLazyQuery(GET_RECIPIENTS, {
      fetchPolicy: 'no-cache',
      onCompleted: (getRecipients) => {
         return getRecipients.getRecipients.data.users;
      },
   });

   const [getRecipientsCount] = useLazyQuery(GET_RECIPIENTS_COUNT, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getRecipientsCount }) => {
         return getRecipientsCount.getRecipientsCount.data;
      },
   });

   const [getGroupListQuery] = useLazyQuery(GET_GROUP_LIST, {
      fetchPolicy: 'cache-and-network',
   });

   const getGroupData = async (search, prevOptions) => {
      if (search.length !== 0) {
         if (search.length < 3) {
            return {
               options: groupData.docs.map((group) => {
                  return {
                     isDynamic: group.isDynamic,
                     label: group.name,
                     value: group._id,
                     userCount: group.userCount,
                  };
               }),
               hasMore: false,
            };
         }
      }
      const getGroupDataRequestData = await getGroupListQuery({
         variables: {
            page: prevOptions.length === 0 ? 1 : prevOptions.length / GROUP_PAGE_SIZE + 1,
            pageSize: GROUP_PAGE_SIZE,
            customer: person.customer._id,
            search,
            groupType: whichContactType.value,
            isFillPerson: whichContactType.value === 1,
         },
      })
         .then((res) => {
            setGroupData(res.data.getGroupList.data);
            return res.data.getGroupList.data;
         })
         .catch(() => []);

      return {
         options: getGroupDataRequestData.docs.map((group) => {
            return {
               isDynamic: group.isDynamic,
               label: group.name,
               value: group._id,
               userCount: group.userCount,
            };
         }),
         hasMore: getGroupDataRequestData.hasNextPage,
      };
   };

   const getRecipientsLoadOptions = async (search, prevOptions) => {
      if (search.length !== 0) {
         if (search.length < 3) {
            return {
               options: recipientsData.map((recipient) => {
                  return {
                     label: `${recipient.name} (${recipient.phone})`,
                     value: recipient._id,
                  };
               }),
               hasMore: false,
            };
         }
      }
      const getRecipientsCountRequestData = await getRecipientsCount({
         variables: {
            customer: person.customer._id,
            name: search,
            isGroup: false,
         },
      })
         .then((res) => res.data.getRecipientsCount.data)
         .catch(() => 0);

      const getRecipientsRequestData = await getRecipients({
         variables: {
            pageNumber: prevOptions.length === 0 ? 1 : prevOptions.length / RECIPIENTS_PAGE_SIZE + 1,
            pageSize: RECIPIENTS_PAGE_SIZE,
            customer: person.customer._id,
            name: search,
            isGroup: false,
         },
      })
         .then((res) => {
            setRecipientsData(res.data.getRecipients.data.users);
            return res.data.getRecipients.data.users;
         })
         .catch(() => []);

      return {
         options: getRecipientsRequestData.map((recipient) => {
            return {
               label: `${recipient.name} (${recipient.phone})`,
               value: recipient._id,
            };
         }),
         hasMore: prevOptions.length + getRecipientsRequestData.length < getRecipientsCountRequestData,
      };
   };

   return (
      <Grid container className={classes.content}>
         <Grid xs={12}>
            <TopBar
               isSchedule={campaignSettings.schedule != null}
               title={campaignSettings.campaignName}
               buttonsEnabled={(() => {
                  switch (campaignSettings.platform_type) {
                     case 'WHATS_APP': {
                        if (campaignSettings.status === 'SENT') {
                           return false;
                        } else if (campaignSettings.platform_id?.startsWith('business')) {
                           return (
                              !isSaving &&
                              (campaignSettings.total_recipient_count > 0 ||
                                 campaignSettings.dynamic_group_recipients?.length > 0) &&
                              campaignSettings.platform_id !== '' &&
                              (campaignSettings.template?.templateType === 'TEXT' || campaignSettings.media) &&
                              campaignSettings.template_params?.filter((p) => p.value === '').length === 0 &&
                              (!campaignSettings.schedule || moment(campaignSettings.schedule).isAfter(moment()))
                           );
                        } else {
                           return (
                              !isSaving &&
                              campaignSettings.content !== '' &&
                              campaignSettings.content.length <= 1024 &&
                              (campaignSettings.total_recipient_count > 0 ||
                                 campaignSettings.dynamic_group_recipients?.length > 0) &&
                              campaignSettings.platform_id !== '' &&
                              (!campaignSettings.schedule || moment(campaignSettings.schedule).isAfter(moment()))
                           );
                        }
                     }
                     default:
                        throw new Error('Platform type not found or unimplemented');
                  }
               })()}
               schedule_disabled={isSaving || campaignSettings.status !== 'DRAFT'}
               onSave={() => {
                  if (isSendDisabled()) {
                     return;
                  }
                  saveCampaign();
               }}
               onSend={() => {
                  if (isSendDisabled()) {
                     return;
                  }
                  saveCampaign()
                     .then(() => {
                        setSendDialogOpen(true);
                     })
                     .catch(() => {});
               }}
               onTest={() => {
                  if (isSendDisabled()) {
                     return;
                  }
                  saveCampaign()
                     .then(() => {
                        setTestDialogOpen(true);
                     })
                     .catch(() => {});
               }}
               isLoading={updateCampaignLoading}
            />
         </Grid>
         <Grid container className={classes.container} wrap='nowrap'>
            <Hidden>
               <Grid container className={classes.leftPanel}>
                  <TabBar
                     selectPlatform={campaignSettings.platform_id}
                     isPreview={campaignSettings.status === 'SENT'}
                     selectedTab={selectedIdx}
                     completeTabs={(() => {
                        let tabs = [];

                        switch (campaignSettings.platform_type) {
                           case 'WHATS_APP': {
                              if (campaignSettings.platform_id?.startsWith('business')) {
                                 if (
                                    campaignSettings.template &&
                                    (campaignSettings.template.templateType === 'TEXT' || campaignSettings.media) &&
                                    (!Array.isArray(campaignSettings.template_params) ||
                                       campaignSettings.template_params?.filter((p) => p.value === '').length === 0)
                                 ) {
                                    tabs.push(1);
                                 }
                              } else {
                                 if (campaignSettings.content !== '' && campaignSettings.content.length <= 1024) {
                                    tabs.push(1);
                                 }
                              }
                              break;
                           }
                           default:
                              throw new Error('Platform type not found or unimplemented');
                        }

                        if (
                           (campaignSettings.total_recipient_count > 0 ||
                              campaignSettings.dynamic_group_recipients.length > 0) &&
                           campaignSettings.platform_id !== '' &&
                           (!campaignSettings.schedule || moment(campaignSettings.schedule).isAfter(moment()))
                        ) {
                           tabs.push(0);
                        }

                        return tabs;
                     })()}
                     onTabClick={setSelectedIdx}
                  />
                  <div
                     className={classes.tab}
                     style={{
                        display: selectedIdx === 0 ? 'block' : 'none',
                     }}
                  >
                     <div className={classes.tabSection}>
                        <p className={classes.tabSectionTitle}>{t['campaign_sender']}</p>
                        <p className={classes.tabSectionSlient}>
                           {t['campaign_sending_to']}
                           <span
                              style={{
                                 color: '#157CFC',
                              }}
                           >
                              {campaignSettings.platform_type === 'WHATS_APP'
                                 ? 'WhatsApp'
                                 : campaignSettings.platform_type === 'SMS'
                                 ? 'SMS'
                                 : 'Unknown'}
                           </span>
                        </p>
                        <FormControl fullWidth>
                           {!campaignSettings.platform_id ? (
                              <InputLabel
                                 disabled
                                 shrink={false}
                                 variant='outlined'
                                 style={{ marginTop: -5 }}
                                 id='some-label'
                              >
                                 {t['campaign_sender_placeholder']}
                              </InputLabel>
                           ) : null}
                           <Select
                              style={{ height: 44, marginBottom: 0 }}
                              variant='outlined'
                              labelId='sender-picker'
                              disabled={campaignSettings.status !== 'DRAFT'}
                              onChange={(e) => {
                                 switch (campaignSettings.platform_type) {
                                    case 'WHATS_APP': {
                                       if (e.target.value.startsWith('web_')) {
                                          setCampaignSettings({
                                             ...campaignSettings,
                                             template: null,
                                             templateText: null,
                                             template_params: null,
                                             media: null,
                                             content: '',
                                             contentMarkup: '',
                                             buttons: [],
                                             platform_id: e.target.value,
                                          });
                                       } else {
                                          setCampaignSettings({
                                             ...campaignSettings,
                                             template: null,
                                             templateText: null,
                                             template_params: null,
                                             media: null,
                                             content: '',
                                             contentMarkup: '',
                                             buttons: [],
                                             startDate: platforms.data.find((x) => x.id === e.target.value)?.createdAt,
                                             platform_id: e.target.value,
                                          });
                                       }
                                       break;
                                    }
                                    default: {
                                       throw new Error('Unknown platform type');
                                    }
                                 }
                              }}
                              getContentAnchorEl={null}
                              value={platforms.data.length === 0 ? ' ' : campaignSettings.platform_id}
                              MenuProps={MenuProps}
                           >
                              {platforms.data.length === 0 ? (
                                 <MenuItem value='' key='' disabled>
                                    <em>{t['campaign_no_sender']}</em>
                                 </MenuItem>
                              ) : (
                                 platforms.data.map((platform) => {
                                    return (
                                       <MenuItem
                                          disabled={
                                             platform.type === 'WHATS_APP_BUSINESS'
                                                ? platform.status !== 'Approved'
                                                : platform.type === 'WHATS_APP_WEB'
                                                ? !platform.status
                                                : false
                                          }
                                          key={platform.id}
                                          value={platform.id}
                                          className={classes.menuItem}
                                       >
                                          <Grid container>
                                             <Grid container xs={2}>
                                                <div
                                                   className={classes.IconContainer}
                                                   style={{
                                                      backgroundColor:
                                                         campaignSettings.platform_type === 'WHATS_APP'
                                                            ? '#D5FFE0'
                                                            : '#F5F5F5',
                                                      fill:
                                                         campaignSettings.platform_type === 'WHATS_APP'
                                                            ? 'rgb(37, 211, 102)'
                                                            : '#000',
                                                   }}
                                                >
                                                   {campaignSettings.platform_type === 'WHATS_APP' ? (
                                                      platform.type === 'WHATS_APP_BUSINESS' ? (
                                                         <SettingsWhatsappBusinessIcon className={classes.listIcon} />
                                                      ) : (
                                                         <WhatsappIcon className={classes.listIcon} />
                                                      )
                                                   ) : (
                                                      <LiveChatIcon className={classes.listIcon} />
                                                   )}
                                                </div>
                                             </Grid>
                                             <Grid
                                                container
                                                xs={9}
                                                alignContent='center'
                                                style={{
                                                   paddingTop: 2,
                                                   overflow: 'hidden',
                                                }}
                                             >
                                                <Typography noWrap>
                                                   {platform.name}{' '}
                                                   {(platform.type === 'WHATS_APP_BUSINESS'
                                                      ? platform.status !== 'Approved'
                                                      : platform.type === 'WHATS_APP_WEB'
                                                      ? !platform.status
                                                      : false) && `(${t['campaign_platform_not_connected']})`}
                                                </Typography>
                                             </Grid>
                                          </Grid>
                                       </MenuItem>
                                    );
                                 })
                              )}
                           </Select>
                        </FormControl>
                     </div>
                     <div
                        className={classes.tabSection}
                        style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', gap: 6 }}
                     >
                        <Grid sm={12}>
                           <div
                              style={{
                                 fontSize: 18,
                                 color: '#212121',
                                 marginLeft: 3,
                              }}
                           >
                              {t?.campaign_recipients}
                           </div>
                           <SelectAnother
                              options={[
                                 {
                                    value: 0,
                                    label: t?.campaign_contacts,
                                 },
                                 {
                                    value: 1,
                                    label: t?.campaign_manuel_groups,
                                 },
                                 {
                                    value: 2,
                                    label: t?.campaign_dynamic_groups,
                                 },
                              ]}
                              placeholder={t?.campaign_select_template}
                              value={whichContactType}
                              isDisabled={campaignSettings.status !== 'DRAFT'}
                              onChange={(e) => {
                                 setWhichContactType(e);
                                 switch (e.value) {
                                    case 0:
                                       setCampaignSettings({
                                          ...campaignSettings,
                                          group_recipients: [],
                                          dynamic_group_recipients: [],
                                          total_recipient_count: 0,
                                       });
                                       break;
                                    case 1:
                                       setCampaignSettings({
                                          ...campaignSettings,
                                          user_recipients: [],
                                          dynamic_group_recipients: [],
                                          total_recipient_count: 0,
                                       });
                                       break;
                                    case 2:
                                       setCampaignSettings({
                                          ...campaignSettings,
                                          group_recipients: [],
                                          user_recipients: [],
                                          total_recipient_count: 0,
                                       });
                                       break;
                                    default:
                                       break;
                                 }
                              }}
                           />
                        </Grid>
                        <Grid sm={12}>
                           <div
                              style={{
                                 fontSize: 18,
                                 color: '#212121',
                                 marginLeft: 3,
                              }}
                           >
                              {whichContactType.value === 0
                                 ? t?.campaign_contacts
                                 : whichContactType.value === 1
                                 ? t?.campaign_manuel_groups
                                 : t?.campaign_dynamic_groups}
                           </div>
                           {whichContactType.value === 0 && (
                              <AsyncPaginate
                                 placeholder={t?.phone_number_placeholder}
                                 cacheOptions
                                 isMulti
                                 closeMenuOnSelect={false}
                                 value={campaignSettings.user_recipients}
                                 loadOptions={getRecipientsLoadOptions}
                                 isDisabled={campaignSettings.status !== 'DRAFT'}
                                 onChange={(recipient) =>
                                    setCampaignSettings({
                                       ...campaignSettings,
                                       user_recipients: recipient,
                                       total_recipient_count: recipient?.length || 0,
                                    })
                                 }
                              />
                           )}
                           {whichContactType.value === 1 && (
                              <AsyncPaginate
                                 key={whichContactType.value}
                                 placeholder={t?.phone_number_placeholder}
                                 cacheOptions
                                 isMulti
                                 closeMenuOnSelect={false}
                                 value={campaignSettings.group_recipients}
                                 loadOptions={getGroupData}
                                 isDisabled={campaignSettings.status !== 'DRAFT'}
                                 onChange={(manuelGroup) => {
                                    setCampaignSettings({
                                       ...campaignSettings,
                                       group_recipients: manuelGroup,
                                       total_recipient_count:
                                          manuelGroup?.reduce((acc, val) => acc + val.userCount, 0) || 0,
                                    });
                                 }}
                              />
                           )}
                           {whichContactType.value === 2 && (
                              <AsyncPaginate
                                 key={whichContactType.value}
                                 placeholder={t?.phone_number_placeholder}
                                 cacheOptions
                                 value={campaignSettings.dynamic_group_recipients}
                                 loadOptions={getGroupData}
                                 isDisabled={campaignSettings.status !== 'DRAFT'}
                                 onChange={(dynamicGroup) =>
                                    setCampaignSettings({
                                       ...campaignSettings,
                                       dynamic_group_recipients: [dynamicGroup],
                                    })
                                 }
                              />
                           )}
                        </Grid>
                        {whichContactType.value !== 2 && (
                           <p className={classes.tabSectionSlient}>
                              {t['campaign_list_title']}
                              <span
                                 style={{
                                    color: '#157CFC',
                                 }}
                              >
                                 {campaignSettings.total_recipient_count}
                              </span>
                           </p>
                        )}
                     </div>
                     <div className={classes.tabSection}>
                        <p className={classes.tabSectionTitle}>{t['campaign_schedule']}</p>
                        <p
                           className={classes.tabSectionSlient}
                           style={{
                              color:
                                 campaignSettings.status === 'DRAFT' &&
                                 campaignSettings.schedule &&
                                 moment(campaignSettings.schedule).isBefore(moment())
                                    ? 'red'
                                    : undefined,
                           }}
                        >
                           {campaignSettings.schedule &&
                           moment(campaignSettings.schedule).isBefore(moment()) &&
                           campaignSettings.status === 'DRAFT'
                              ? t['campaign_wrong_date']
                              : t['campaign_scheduled_for']}
                           <span
                              style={{
                                 color: '#157CFC',
                              }}
                           >
                              {campaignSettings.schedule == null
                                 ? t['campaign_now']
                                 : moment(campaignSettings.schedule).format('DD/MM/yyyy HH:mm')}
                           </span>{' '}
                        </p>
                        <FormControl fullWidth>
                           <RadioGroup
                              style={{ marginTop: -10 }}
                              aria-disabled={
                                 campaignSettings.status !== 'DRAFT' && campaignSettings.status !== 'SCHEDULED'
                              }
                              value={campaignSettings.schedule == null ? '0' : '1'}
                              onChange={(e) => {
                                 setCampaignSettings({
                                    ...campaignSettings,
                                    schedule: e.target.value === '0' ? null : moment().add('hours', 1).toDate(),
                                 });
                              }}
                           >
                              <Grid container className={classes.scheduleRatioBox}>
                                 <FormControlLabel
                                    value={'0'}
                                    disabled={
                                       campaignSettings.status !== 'DRAFT' && campaignSettings.status !== 'SCHEDULED'
                                    }
                                    control={<Radio color='primary' />}
                                    label={t['campaign_now']}
                                 />
                              </Grid>
                              <Grid container className={classes.scheduleRatioBox} style={{ marginBlock: 5 }}>
                                 <FormControlLabel
                                    value={'1'}
                                    disabled={
                                       campaignSettings.status !== 'DRAFT' && campaignSettings.status !== 'SCHEDULED'
                                    }
                                    control={<Radio color='primary' />}
                                    label={t['campaign_pick_a_date']}
                                 />
                              </Grid>
                              {campaignSettings.schedule != null &&
                                 (campaignSettings.status === 'DRAFT' || campaignSettings.status === 'SCHEDULED') && (
                                    <div
                                       style={{
                                          display: 'block',
                                          width: '100%',
                                          marginLeft: 'auto',
                                          marginRight: 'auto',
                                       }}
                                    >
                                       <DatePicker
                                          selectsStart
                                          style={{
                                             display: 'block',
                                             marginLeft: 'auto',
                                             marginRight: 'auto',
                                          }}
                                          dateFormat={'dd/MM/yyyy HH:mm'}
                                          shouldCloseOnSelect={true}
                                          showTimeSelect
                                          selected={moment(campaignSettings.schedule).toDate()}
                                          onChange={(date) => {
                                             setCampaignSettings({
                                                ...campaignSettings,
                                                schedule: moment(date || new Date()),
                                             });
                                          }}
                                       >
                                          <DatePickerChildren />
                                       </DatePicker>
                                    </div>
                                 )}
                           </RadioGroup>
                        </FormControl>
                     </div>
                  </div>
                  <div
                     className={classes.tab}
                     style={{
                        display: selectedIdx === 1 ? 'block' : 'none',
                     }}
                  >
                     {campaignSettings.platform_type === 'WHATS_APP' &&
                        (campaignSettings.platform_id?.startsWith('web_') || !campaignSettings.platform_id) && (
                           <div className={classes.tabSection}>
                              <p className={classes.tabSectionTitle}>{t['campaign_message']}</p>
                              <p className={classes.tabSectionSlient}>{t['campaign_message_desc']}</p>
                              <CustomInput
                                 disabled={campaignSettings.status === 'SENT'}
                                 isSmallScreen={isSmallScreen}
                                 initialValue={campaignSettings.content}
                                 onTokenChange={onSelectVariableFunc}
                                 handleUploadFiles={handleUploadFiles}
                              />
                              {isSurveySelectOpen && (
                                 <FormControl fullWidth>
                                    {!selectedSurvey ? (
                                       <InputLabel
                                          disabled
                                          shrink={false}
                                          variant='outlined'
                                          style={{ marginTop: -20 }}
                                          id='some-label'
                                       >
                                          {t['campaign_survey_placeholder']}
                                       </InputLabel>
                                    ) : null}
                                    <Select
                                       style={{ height: 48, marginTop: -15 }}
                                       variant='outlined'
                                       labelId='survey-picker'
                                       disabled={campaignSettings.status !== 'DRAFT' || !satisfactionCustomerData}
                                       getContentAnchorEl={null}
                                       value={
                                          satisfactionCustomerData?.getSurveys?.data?.length === 0
                                             ? ' '
                                             : selectedSurvey
                                       }
                                       MenuProps={MenuProps}
                                    >
                                       {satisfactionCustomerData?.getSurveys?.data.length === 0 ? (
                                          <MenuItem value='' key='' disabled>
                                             <em>{t['campaign_no_survey']}</em>
                                          </MenuItem>
                                       ) : (
                                          satisfactionCustomerData?.getSurveys?.data.map((survey) => {
                                             return (
                                                <MenuItem
                                                   onClick={() => {
                                                      setSelectedSurvey(survey._id);
                                                   }}
                                                   key={survey._id}
                                                   value={survey._id}
                                                   className={classes.menuItem}
                                                >
                                                   <Grid container direction='row'>
                                                      <Grid item xs={11} direction='column'>
                                                         <Typography
                                                            style={{
                                                               textAlign: 'left',
                                                               fontWeight: 'bold',
                                                            }}
                                                            noWrap
                                                         >
                                                            {`${survey.title.slice(0, 16)}${
                                                               survey.title.length > 16 ? '...' : ''
                                                            }`}
                                                         </Typography>
                                                         <Grid item className={classes.answerContainer}>
                                                            <Typography className={classes.surveyContent} noWrap>
                                                               {`${survey.message.slice(0, 16)}${
                                                                  survey.message.length > 16 ? '...' : ''
                                                               }`}
                                                            </Typography>
                                                         </Grid>
                                                      </Grid>
                                                   </Grid>
                                                </MenuItem>
                                             );
                                          })
                                       )}
                                    </Select>
                                 </FormControl>
                              )}
                              <div style={{ height: 300, overflowY: 'auto', overflowX: 'hidden' }}>
                                 {(campaignSettings.media != null || !!campaignSettings.template?.file) &&
                                    campaignSettings.media.map((media, i) => (
                                       <PhotoHolder
                                          disabled={campaignSettings.status === 'SENT'}
                                          onRemove={() => {
                                             setCampaignSettings({
                                                ...campaignSettings,
                                                media: campaignSettings.media.filter((m, j) => j !== i),
                                             });
                                          }}
                                          media={media}
                                       />
                                    ))}
                              </div>
                           </div>
                        )}
                     {campaignSettings.platform_type === 'WHATS_APP' &&
                        campaignSettings.platform_id?.startsWith('business_') && (
                           <div className={classes.tabSection}>
                              <p className={classes.tabSectionTitle}>{t['campaign_template']}</p>
                              <p className={classes.tabSectionSlient}>{t['campaign_template_desc']}</p>
                              <Button
                                 disabled={campaignSettings.status === 'SENT'}
                                 className={classes.selectTemplate}
                                 onClick={(e) => {
                                    setTemplateAnchorElPopper(e.currentTarget);
                                 }}
                              >
                                 <div
                                    style={{
                                       overflow: 'hidden',
                                       textOverflow: 'ellipsis',
                                    }}
                                 >
                                    {campaignSettings.template
                                       ? campaignSettings.template.vertical || campaignSettings.template.name
                                       : t['campaign_select_template']}
                                 </div>
                                 <div style={{ marginLeft: 'auto', paddingTop: 5 }}>
                                    <ArrowDropDownIcon style={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                                 </div>
                              </Button>
                           </div>
                        )}
                     {campaignSettings.platform_type === 'WHATS_APP' &&
                        campaignSettings.platform_id?.startsWith('business_') && (
                           <div className={classes.tabSection}>
                              <TemplateContent contentMarkup={campaignSettings.contentMarkup} />
                           </div>
                        )}
                     {campaignSettings.platform_type === 'WHATS_APP' &&
                        campaignSettings.platform_id?.startsWith('business_') &&
                        campaignSettings.template_params?.filter((x) => x.type === 'TEXT').length !== 0 && (
                           <div className={classes.tabSection}>
                              {campaignSettings.template_params
                                 ?.filter((x) => x.type === 'TEXT')
                                 .map((param) => {
                                    let idx = campaignSettings.template_params.findIndex(
                                       (x) => x.key === param.key && x.type === 'TEXT',
                                    );
                                    return (
                                       <VariableSelector
                                          disabled={campaignSettings.status === 'SENT'}
                                          refer={'person_params'}
                                          idx={campaignSettings.template_params[idx].key}
                                          value={campaignSettings.template_params[idx].value}
                                          onChange={(value) => {
                                             let oldCampaign = campaignSettings;
                                             oldCampaign.template_params[idx].value = value;
                                             oldCampaign.template_params[idx].ref = 'person_params';
                                             setCampaignSettings({
                                                ...oldCampaign,
                                             });
                                          }}
                                          key={idx}
                                       />
                                    );
                                 })}
                           </div>
                        )}

                     {campaignSettings?.template &&
                        campaignSettings?.template?.templateType !== 'TEXT' &&
                        campaignSettings.platform_type === 'WHATS_APP' &&
                        campaignSettings.platform_id &&
                        campaignSettings.platform_id.startsWith('business') && (
                           <div className={classes.tabSection}>
                              <p className={classes.tabSectionTitle}>{t['campaign_media']}</p>
                              <p className={classes.tabSectionSlient}>{t['campaign_media_desc']}</p>
                              <MediaUpload
                                 disabled={campaignSettings.status === 'SENT'}
                                 handleUploadFiles={(files) => {
                                    handleUploadFiles(files, true);
                                 }}
                                 uploadDetails={(campaignSettings.media || [])[0]}
                              />
                           </div>
                        )}

                     {campaignSettings.platform_type === 'WHATS_APP' &&
                        campaignSettings.platform_id?.startsWith('business_') &&
                        campaignSettings.template &&
                        campaignSettings.template_params &&
                        campaignSettings.template_params.filter((x) => {
                           let urlRegex = new RegExp(`(htt(?:p|ps):\/\/.+\/)(?:\\{\\{${x.key}\\}\\})`, 'g');
                           return x.type === 'URL' && urlRegex.test(campaignSettings.template.message);
                        }).length !== 0 && (
                           <div className={classes.tabSection}>
                              <br></br>
                              <p className={classes.tabSectionTitle}>{t['campaign_dynamic_url']}</p>
                              <p className={classes.tabSectionSlient}>{t['campaign_dynamic_url_desc']}</p>
                              {campaignSettings.template.survey && (
                                 <FormControl fullWidth>
                                    <Select
                                       style={{ height: 48, marginTop: -15 }}
                                       variant='outlined'
                                       labelId='survey-picker'
                                       disabled={campaignSettings.status !== 'DRAFT' || !satisfactionCustomerData}
                                       getContentAnchorEl={null}
                                       value={
                                          satisfactionCustomerData?.getSurveys?.data?.length === 0
                                             ? ' '
                                             : selectedSurvey
                                       }
                                       MenuProps={MenuProps}
                                    >
                                       {satisfactionCustomerData?.getSurveys?.data.length === 0 ? (
                                          <MenuItem value='' key='' disabled>
                                             <em>{t['campaign_no_survey']}</em>
                                          </MenuItem>
                                       ) : (
                                          satisfactionCustomerData?.getSurveys?.data.map((survey) => {
                                             return (
                                                <MenuItem
                                                   onClick={() => {
                                                      setSelectedSurvey(survey._id);
                                                   }}
                                                   key={survey._id}
                                                   value={survey._id}
                                                   className={classes.menuItem}
                                                >
                                                   <Grid container direction='row'>
                                                      <Grid item xs={11} direction='column'>
                                                         <Typography
                                                            style={{
                                                               textAlign: 'left',
                                                               fontWeight: 'bold',
                                                            }}
                                                            noWrap
                                                         >
                                                            {`${survey.title.slice(0, 16)}${
                                                               survey.title.length > 16 ? '...' : ''
                                                            }`}
                                                         </Typography>
                                                         <Grid item className={classes.answerContainer}>
                                                            <Typography className={classes.surveyContent} noWrap>
                                                               {`${survey.message.slice(0, 16)}${
                                                                  survey.message.length > 16 ? '...' : ''
                                                               }`}
                                                            </Typography>
                                                         </Grid>
                                                      </Grid>
                                                   </Grid>
                                                </MenuItem>
                                             );
                                          })
                                       )}
                                    </Select>
                                 </FormControl>
                              )}
                              {campaignSettings.template_params
                                 ?.filter((x) => {
                                    let urlRegex = new RegExp(`(htt(?:p|ps):\/\/.+\/)(?:\\{\\{${x.key}\\}\\})`, 'g');
                                    return x.type === 'URL' && urlRegex.test(campaignSettings.template.message);
                                 })
                                 .map((param) => {
                                    let idx = campaignSettings.template_params.findIndex(
                                       (x) => x.key === param.key && x.type === 'URL',
                                    );
                                    let urlRegex = new RegExp(
                                       `(htt(?:p|ps):\/\/.+\/)(?:\\{\\{${param.key}\\}\\})`,
                                       'g',
                                    );
                                    let url = campaignSettings.template.message.match(urlRegex)[0];
                                    url = url.replaceAll(
                                       `{{${param.key}}}`,
                                       campaignSettings.template_params[idx].value,
                                    );

                                    return (
                                       <div>
                                          <p className={classes.dynamicURL}>{url}</p>
                                          <VariableSelector
                                             disabled={
                                                campaignSettings.status === 'SENT' || campaignSettings.template.survey
                                             }
                                             refer={'none'}
                                             idx={campaignSettings.template_params[idx].key}
                                             value={
                                                campaignSettings.template.survey
                                                   ? campaignSettings.template.buttons[0].example[0]
                                                   : campaignSettings.template_params[idx].value
                                             }
                                             onChange={(value) => {
                                                let oldCampaign = campaignSettings;
                                                oldCampaign.template_params[idx].value = value;
                                                oldCampaign.template_params[idx].ref = 'none';
                                                setCampaignSettings({
                                                   ...oldCampaign,
                                                });
                                             }}
                                             key={idx}
                                          />
                                       </div>
                                    );
                                 })}
                           </div>
                        )}

                     {templateAnchorElPopper && (
                        <TemplateSelector
                           anchor={templateAnchorElPopper}
                           onClose={() => {
                              setTemplateAnchorElPopper(null);
                           }}
                           startDate={campaignSettings.startDate}
                           onSelect={(template) => {
                              getSatisfactionData({
                                 variables: {
                                    ...satisfactionCustomerParam,
                                 },
                              });
                              setSelectedSurvey(template.survey);
                              let resolvedTemplate = resolveTemplate(template);
                              setCampaignSettings({
                                 ...campaignSettings,
                                 survey: template.survey,
                                 template_params: [],
                                 media: resolvedTemplate.template?.sampleMedia
                                    ? [resolvedTemplate.template?.sampleMedia]
                                    : null,
                                 ...resolvedTemplate,
                              });
                           }}
                        />
                     )}
                  </div>
               </Grid>
            </Hidden>

            <Hidden>
               <Grid className={classes.rightPanel} alignItems='center' direction='column' container wrap={'nowrap'}>
                  <Grid
                     container
                     justifyContent='center'
                     style={{
                        width: '100%',
                        height: '87%',
                        borderRadius: 15,
                     }}
                  >
                     <VirtualPhone
                        media={campaignSettings.media}
                        sender={(() => {
                           switch (campaignSettings.platform_type) {
                              case 'WHATS_APP': {
                                 if (campaignSettings.platform_id?.startsWith('web_')) {
                                    let sender = platforms.data.find((p) => p.id === campaignSettings.platform_id);
                                    if (sender) {
                                       return sender.number;
                                    } else {
                                       return null;
                                    }
                                 } else if (campaignSettings.platform_id?.startsWith('business_')) {
                                    let sender = platforms.data.find((p) => p.id === campaignSettings.platform_id);
                                    if (sender) {
                                       return sender.phone;
                                    } else {
                                       return null;
                                    }
                                 } else {
                                    return null;
                                 }
                              }
                              default: {
                                 throw new Error('Unknown platform type');
                              }
                           }
                        })()}
                        messageContent={campaignSettings.contentMarkup}
                        buttons={campaignSettings.buttons}
                     />
                  </Grid>
               </Grid>
            </Hidden>
         </Grid>
         {isSendDialogOpen && (
            <SendDialog
               open={isSendDialogOpen}
               onClose={() => setSendDialogOpen(false)}
               id={campaignId}
               name={campaignSettings.campaignName}
               recipientCount={campaignSettings.total_recipient_count}
               time={campaignSettings.schedule}
            />
         )}

         {isTestDialogOpen && (
            <TestDialog
               open={isTestDialogOpen}
               onClose={() => setTestDialogOpen(false)}
               id={campaignId}
               name={campaignSettings.campaignName}
               recipientCount={campaignSettings.total_recipient_count}
               time={campaignSettings.schedule}
            />
         )}
      </Grid>
   );
};

export default WithUrlTranslations(Campaigns);

import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import Input from '@components/InputField';

const useStyles = makeStyles(() => ({
   inputField: {
      width: '100%',
      marginTop: 6,

      '& .MuiInputLabel-root': {
         marginBottom: 4,
         marginLeft: 3,
         fontSize: 18,
         color: '#212121',
      },
      '& .makeStyles-inputLabel-141': {
         marginTop: -50,
      },
      '& .MuiGrid-spacing-xs-1': {
         width: '100%',
         margin: 0,
      },
   },
}));

const DoubleInput = (props: any) => {
   const classes = useStyles();

   const {
      control1,
      control2,
      maxLength1,
      maxLength2,
      name1,
      name2,
      label1,
      label2,
      disabled1,
      disabled2,
      editable1,
      editable2,
      required1,
      required2,
      rules1,
      rules2,
      type1,
      type2,
      helperText1,
      helperText2,
      placeholder1,
      placeholder2,
      isPhone1,
      isPhone2,
      isEmail1,
      isEmail2,
      splitCountryCode1,
      splitCountryCode2,
      multiline1,
      multiline2,
      isSelect1,
      isSelect2,
      isMulti1,
      isMulti2,
      isCreatable1,
      isCreatable2,
      selectOptions1,
      selectOptions2,
      selectDefaultValue1,
      selectDefaultValue2,
      customSelectStyles1,
      customSelectStyles2,
      rows1,
      rows2,
      notDisplay1,
      notDisplay2,
      startAdornment1,
      startAdornment2,
      endAdornment1,
      endAdornment2,
      isOptionDisabled1,
      isOptionDisabled2,
      onKeyPress1,
      onKeyPress2,
      onChange1,
      onChange2,
      onFocus1,
      onFocus2,
      maxMenuHeight1,
      maxMenuHeight2,
      isLoading1,
      isLoading2,
      autoHeight1,
      autoHeight2,
      isDate1,
      isDate2,
   } = props;

   return (
      <Grid container xs={12}>
         <Grid item xs={6} style={{ paddingRight: 10 }} className={classes.inputField}>
            <Input
               control={control1}
               maxMenuHeight={maxMenuHeight1}
               maxLength={maxLength1}
               name={name1}
               label={label1}
               disabled={disabled1}
               editable={editable1}
               required={required1}
               rules={rules1}
               type={type1}
               helperText={helperText1}
               placeholder={placeholder1 || label1 || name1}
               isPhone={isPhone1}
               isEmail={isEmail1}
               splitCountryCode={splitCountryCode1}
               multiline={multiline1}
               isSelect={isSelect1}
               isMulti={isMulti1}
               isCreatable={isCreatable1}
               selectOptions={selectOptions1}
               selectDefaultValue={selectDefaultValue1}
               customSelectStyles={customSelectStyles1}
               rows={rows1}
               notDisplay={notDisplay1}
               startAdornment={startAdornment1}
               endAdornment={endAdornment1}
               onKeyPress={onKeyPress1}
               value={false}
               autoHeight={autoHeight1 || false}
               isOptionDisabled={isOptionDisabled1}
               onChange={onChange1}
               onFocus={onFocus1}
               isLoading={isLoading1}
               isDate={isDate1}
            />
         </Grid>
         <Grid item xs={6} className={classes.inputField}>
            <Input
               control={control2}
               maxMenuHeight={maxMenuHeight2}
               maxLength={maxLength2}
               name={name2}
               label={label2}
               disabled={disabled2}
               editable={editable2}
               required={required2}
               rules={rules2}
               type={type2}
               helperText={helperText2}
               placeholder={placeholder2 || label2 || name2}
               isPhone={isPhone2}
               isEmail={isEmail2}
               splitCountryCode={splitCountryCode2}
               multiline={multiline2}
               isSelect={isSelect2}
               isMulti={isMulti2}
               isCreatable={isCreatable2}
               selectOptions={selectOptions2}
               selectDefaultValue={selectDefaultValue2}
               customSelectStyles={customSelectStyles2}
               rows={rows2}
               notDisplay={notDisplay2}
               startAdornment={startAdornment2}
               endAdornment={endAdornment2}
               onKeyPress={onKeyPress2}
               value={false}
               autoHeight={autoHeight2 || false}
               isOptionDisabled={isOptionDisabled2}
               onChange={onChange2}
               onFocus={onFocus2}
               isLoading={isLoading2}
               isDate={isDate2}
            />
         </Grid>
      </Grid>
   );
};

export default DoubleInput;

import { useLazyQuery } from "@apollo/client";
import { LanguageContext } from '@helper/locale/langContext';
import { Button, Checkbox, CircularProgress, ListItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CUSTOM_CHATS_QUERY } from "@queries/Chat/Service/GraphQL/Chat/query";
import { GET_GROUPS_LIST } from "@queries/PersonGroups/Service/GraphQL/query";
import React, { useCallback, useContext, useEffect } from 'react';
import { useSelector } from "react-redux";
import { SelectedTarget, SenderType } from "../types";
import { GET_PLATFORMS_PAGINATED_QUERY } from "@queries/Settings/Service/GraphQL/Platform/query";

const useStyles = makeStyles((theme: any) => ({
    container: {
        border: '2px solid #D1D1D1',
        borderRadius: '5px',
        height: 400,
        overflowY: "auto",
        overflowX: "hidden",
    },
    checkBoxLabel: {
        whiteSpace: 'pre',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        marginLeft: 15,
    },
    tabSectionTitle: {
        marginTop: 2,
        marginBottom: 5,
        paddingLeft: 2,
        fontSize: '1.1rem',
        fontWeight: 500,
    },
    nextButton: {
        width: "100%",
        marginTop: 10,
    },
}));

interface TargetSelectorProps {
    setBusy: (isBusy: boolean) => void;
    disabled: boolean;
    type: SenderType;
    selectedIds: SelectedTarget[];
    onSelectedIdsChange: (selectedIds: SelectedTarget[]) => void;
}

interface SubChannels {
    id: string;
    name: string;
}

interface Platform {
    platformId: string;
    platformName: string;
    subChannels: SubChannels[]
    type: String;
}

export interface PlatformInfo {
    _id: string
    //wp web
    name?: string
    //telegram
    firstName?: string;
    number: string
    customer: string
    status: boolean
    beforeDeployStatus: boolean
    failedMessage: boolean
    groupChats: boolean
    receivePhoneMsg: boolean
    tryCount: number
    sessionStatus: boolean
    createdAt: string
    updatedAt: string
    __v: number
    groups?: Group[]
    channels?: Group[]
    id: string
}

export interface Group {
    chat: string
    platformChatId: string
    chatName: string
    tag: any[]
    _id: string
}


const TargetSelector = ({ setBusy, disabled, onSelectedIdsChange, selectedIds, type }: TargetSelectorProps) => {
    const classes = useStyles();
    const person = useSelector((reducer: any) => reducer.personReducer.person);
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;

    const [filter, setFilter] = React.useState({
        search: '',
        pageSize: 100,
        page: 1,
    });

    {/*
        Search future, to be used in future. Just set setSearchValue with textBar.
        If you asked to do this you know who do thank :)

        const [searchValue, setSearchValue] = React.useState('');
        useEffect(() => {
            const time = setTimeout(() => {
                setFilter((prev) => ({ ...prev, search: searchValue }));
            }, 500);
        
            return () => clearTimeout(time);
        }, [searchValue]);
    */}

    const [pageData, setPageData] = React.useState<{
        totalDocs: number;
        hasMore: boolean;
        data: Platform[]
    }>({
        totalDocs: 0,
        hasMore: false,
        data: [],
    });

    const [getGroupsList, { loading: loadingGetGroupsList }] = useLazyQuery(GET_GROUPS_LIST, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ getGroupList: data }) => {
            const oldData = [...pageData.data];
            for (const groupData of data?.data?.docs) {
                let platformId = groupData?.senderPlatform?._id;
                let platformName = groupData?.senderPlatform?.name || groupData?.senderPlatform?.firstName;

                if (groupData?.senderPlatform?.number) {
                    platformName = `${platformName} (${groupData?.senderPlatform?.number})`;
                }

                let alreadyExists = oldData.findIndex((group) => group.platformId === platformId);
                if (!groupData?.senderPlatform) {
                    continue;
                } else if (alreadyExists == -1) {
                    oldData.push({
                        platformId,
                        platformName,
                        subChannels: [
                            {
                                id: groupData?._id,
                                name: groupData?.name,
                            }
                        ],
                        type: groupData.senderPlatformType == "WhatsAppPhones" ? "WHATS_APP_WEB" : "TELEGRAM",
                    });

                } else {
                    oldData[alreadyExists].subChannels.push({
                        id: groupData._id,
                        name: groupData?.name,
                    });
                }

            }

            setPageData({
                ...(data?.data?.totalDocs && { totalDocs: data?.data?.totalDocs }),
                hasMore: data?.data?.hasNextPage,
                data: oldData,
            });
            setBusy(false);
        },
    });

    const [getPlatformList, { loading: platformListLoading }] = useLazyQuery(GET_PLATFORMS_PAGINATED_QUERY, {
        onCompleted: ({ getPlatformsPaginated: data }) => {
            setPageData({
                ...(data?.data?.totalRecords && { totalDocs: data?.data?.totalRecords }),
                hasMore: data?.data?.hasNextPage,
                data: (data?.data?.data as PlatformInfo[]).filter(x => (x.groups || x.channels || []).length > 0).map(x => ({
                    platformId: x._id,
                    platformName: x?.name || x?.firstName || "unexpected platform",
                    subChannels: (x.groups || x.channels || []).map((x) => ({
                        id: x.chat,
                        name: x.chatName,
                    })),
                    type: x?.name ? "WHATS_APP_WEB" : "TELEGRAM",
                })),
            });
            setBusy(false);
        }
    });

    useEffect(() => {
        setPageData({
            totalDocs: 0,
            hasMore: true,
            data: []
        });
    }, [type]);

    useEffect(() => {
        setBusy(true);
        if (type == SenderType.PersonGroups) {
            getGroupsList({
                variables: {
                    customer: person.customer._id,
                    search: filter?.search,
                    page: filter?.page,
                    pageSize: filter?.pageSize,
                },
            });
        } else {
            getPlatformList({
                variables: {
                    params: {
                        customer: person.customer._id,
                        platformType: type == SenderType.TelegramChats ? "TELEGRAM" : "WHATS_APP_WEB",
                        search: filter?.search,
                        page: filter?.page,
                        pageSize: filter?.pageSize,
                    }
                },
            });
        }
    }, [filter, type]);

    let handleNextPage = () => {
        setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
    };

    const Loader = useCallback(() => {
        return (
            <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
                <CircularProgress size={36} color='primary' />
            </Grid>
        );
    }, []);

    return (
        <div>
            <Grid container justifyContent='space-between' alignItems='center' style={{ height: 35, marginBottom: 10 }}>
                <Grid
                    item
                    xs={10}
                >
                    <p className={classes.tabSectionTitle}>{t[type == SenderType.PersonGroups ? 'bulk_message_option_1' : type == SenderType.WhatsappGroups ? 'bulk_message_option_2' : 'bulk_message_option_3']}</p>
                </Grid>
                <Grid item xs={2}>
                    <Checkbox
                        edge='end'
                        disabled={disabled}
                        checked={
                            selectedIds.some(x => x.type == type)
                        }
                        onChange={(e) => {
                            if (selectedIds.some(x => x.type == type)) {
                                let ids: SelectedTarget[] = [];
                                for (let i = 0; i < pageData.data.length; i++) {
                                    ids = [...ids, ...pageData.data[i].subChannels.map(x => ({
                                        id: x.id,
                                        type,
                                    }))];
                                }
                                onSelectedIdsChange(
                                    selectedIds.filter(x => ids.findIndex(y => y.id === x.id) == -1)
                                )
                            } else {
                                let ids: SelectedTarget[] = [];
                                for (let i = 0; i < pageData.data.length; i++) {
                                    ids = [...ids, ...pageData.data[i].subChannels.map(x => ({
                                        id: x.id,
                                        type,
                                    }))];
                                }
                                onSelectedIdsChange([...selectedIds, ...ids]);
                            }
                        }}
                        color='primary'
                        tabIndex={-1}
                        disableRipple
                    />
                </Grid>
            </Grid>
            <div className={classes.container}>
                {
                    (platformListLoading || loadingGetGroupsList) && <Loader />
                }
                {pageData?.data?.map((data) => {
                    return (
                        <ListItem key={data.platformId} style={{ paddingLeft: 0, display: "block" }}>
                            <Grid container justifyContent='flex-start' alignItems='center' style={{ height: 35, marginTop: '-5px' }}>
                                <Grid item xs={1}>
                                    <Checkbox
                                        disabled={disabled}
                                        edge='end'
                                        checked={
                                            !!data.subChannels.filter((channel) => selectedIds.map(x => x.id).includes(channel.id)).length
                                        }
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                onSelectedIdsChange([
                                                    ...selectedIds,
                                                    ...(data.subChannels.map((channel) => ({ id: channel.id, type }))),
                                                ]);
                                            } else {
                                                onSelectedIdsChange(selectedIds.filter((target) => data.subChannels.map(channel => channel.id).indexOf(target.id) == -1));
                                            }
                                        }}
                                        color='primary'
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': data.platformId }}
                                    />
                                </Grid>
                                <Grid
                                    item
                                    xs={10}
                                    className={classes.checkBoxLabel}
                                >
                                    {data.platformName}
                                </Grid>
                            </Grid>
                            {
                                data.subChannels.map((channel) => {
                                    return <Grid key={channel.id} container justifyContent='flex-start' alignItems='center' style={{ marginLeft: 25, height: 30, marginTop: '-5px' }}>
                                        <Grid item xs={1}>
                                            <Checkbox
                                                disabled={disabled}
                                                edge='end'
                                                color='primary'
                                                checked={
                                                    selectedIds.map(x => x.id).includes(channel.id)
                                                }
                                                onChange={(e) => {
                                                    if (e.target.checked) {
                                                        onSelectedIdsChange([
                                                            ...selectedIds,
                                                            { type, id: channel.id },
                                                        ]);
                                                    } else {
                                                        onSelectedIdsChange(selectedIds.filter((target) => target.id !== channel.id));
                                                    }
                                                }}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': channel.id }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={10}
                                            className={classes.checkBoxLabel}
                                        >
                                            {channel.name}
                                        </Grid>
                                    </Grid>
                                })
                            }
                        </ListItem>
                    );
                })}
            </div >
            <Button
                className={classes.nextButton}
                disabled={loadingGetGroupsList || platformListLoading || !pageData?.hasMore}
                color='primary'
                variant='contained'
                onClick={(() => {
                    handleNextPage();
                })}
            >
                {t?.next_page}
            </Button>
        </div>
    );
};

export default TargetSelector;

import { gql } from '@apollo/client';

// subscriptions
export const CREATED_QUICK_ANSWER_SUBSCRIPTION = gql`
   subscription createdQuickAnswerSubscription($token: String!, $customer: ID!) {
      createdQuickAnswer(data: { token: $token, customer: $customer }) {
         data {
            _id
            name
            answer
            payload {
               name
               size
               type
               path
            }
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const UPDATED_QUICK_ANSWER_SUBSCRIPTION = gql`
   subscription updatedQuickAnswerSubscription($token: String!, $customer: ID!) {
      updatedQuickAnswer(data: { token: $token, customer: $customer }) {
         data {
            _id
            name
            answer
            payload {
               name
               size
               type
               path
            }
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const DELETED_QUICK_ANSWER_SUBSCRIPTION = gql`
   subscription deletedQuickAnswerSubscription($token: String!, $customer: ID!) {
      deletedQuickAnswer(data: { token: $token, customer: $customer }) {
         data {
            _id
            name
            answer
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            createdAt
         }
         success
         code
         message
      }
   }
`;

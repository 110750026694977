import React, { useState, Fragment, useContext } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PackageBox from './components/PackageBox';
import BtnActions from './components/NextBackBtn';
import { Button } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import { useQuery } from '@apollo/client';
import { GET_PRODUCTS_QUERY } from '../../queries/Products/Service/GraphQL/query';
import { specialPackage } from '@lib/utils';
import useSubscriptionWithCache from '../../hooks/useSubscriptionWithCache';
import {
   CREATED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION,
   DELETED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION,
   UPDATED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION,
} from '../../queries/Customer/Service/GraphQL/CustomerProduct/subscription';
import { useSelector } from 'react-redux';
import { RenewalType } from '@types/enums';

const useStyles = makeStyles((theme) => ({
   main: {
      borderRadius: '8px',
      width: '100%',
      background: '#FFFFFF',
      boxShadow: '0px 1px 3px #00000029',
   },
   title: {
      color: 'black',
   },
   header: {
      padding: '1.5rem 2rem',
      borderBottom: '1px solid #DEDEDE',
      alignItems: 'center',
      justifyContent: 'space-between',
      display: 'flex',
   },
   container: {
      padding: '1rem',
   },
   off20: {
      color: '#178CF9',
      paddingLeft: '2px',
      paddingRight: '2px',
   },
   year: {
      alignItems: 'center',
      justifyContent: 'space-between',
   },
   yearOptionsBox: {
      border: '1px solid #EAEAEA',
      borderRadius: '10px',
      marginLeft: '1rem',
      padding: '.4rem',
   },
   ads: {
      color: '#72889D',
      display: 'flex',
      alignItems: 'center',
   },
   paymentDurationBtn: {
      background: '#178CF9',
      color: 'white',
      borderRadius: '5px',
      '&:hover': {
         background: '#178cf9d9',
         color: 'white',
      },
   },
   discount: {
      transition: 'all .7s',
      transitionTimingFunction: 'cubic-bezier(0.8, 2, 1.4, 0.1)',
      '&:hover': {
         color: '#157cfc',
         transform: 'scale(1.04)',
      },
   },
}));

const getPrice = (product, paymentType) => {
   const isYearly = paymentType === RenewalType.YEARLY;
   return isYearly ? product.yearlyPrice : product.monthlyPrice;
};

const Package = ({ nextPageHandler, prevPageHandler, getPackageOptions }) => {
   const [active, setActive] = useState(window.localStorage.getItem('activePackage') || '');
   const [paymentType, setPaymentType] = useState(RenewalType.YEARLY);
   const customer = useSelector((state) => state.personReducer.person.customer);
   const token = useSelector((state) => state.personReducer.token);
   const [hasSpecialPackage, setHasSpecialPackage] = useState(false);

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();

   const {
      loading: productsLoading,
      data: productsData,
      error: productsError,
   } = useQuery(GET_PRODUCTS_QUERY, {
      fetchPolicy: 'cache-first',
      variables: {
         customerId: customer._id,
      },
      onCompleted: (data) => {
         if (data) {
            setHasSpecialPackage(data.products.data.findIndex((p) => p.name === 'Special') > -1);
         }
      },
   });

   useSubscriptionWithCache(
      CREATED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION,
      {
         token,
         customer: customer._id,
      },
      'createdCustomerSpecialPackage',
      'products',
      (existing, newData) => {
         setHasSpecialPackage(true);
         return [...existing.data, newData];
      },
   );

   useSubscriptionWithCache(
      UPDATED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION,
      {
         token,
         customer: customer._id,
      },
      'updatedCustomerSpecialPackage',
      'products',
      (existing, newData) => existing.data.map((product) => (product.name === newData.name ? { ...newData } : product)),
   );

   useSubscriptionWithCache(
      DELETED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION,
      {
         token,
         customer: customer._id,
      },
      'deletedCustomerSpecialPackage',
      'products',
      (existing, newData) => {
         setHasSpecialPackage(false);
         return existing.data.filter((product) => product.name !== newData.name);
      },
   );

   const chooseHandler = (optionChosen) => {
      setActive(optionChosen);
      window.localStorage.setItem('activePackage', optionChosen);
   };

   const products = productsData?.products?.data;

   const packageHandler = () => {
      const packageOptions = {
         name: active,
         mode: paymentType,
         price: getPrice(
            products.find((product) => product.name.toLowerCase() === active.toLocaleLowerCase()),
            paymentType,
         ),
      };

      getPackageOptions(packageOptions);
      window.localStorage.setItem('packageOptions', JSON.stringify(packageOptions));
      nextPageHandler();
   };

   const loadingOrFetching = productsLoading;

   if (loadingOrFetching) {
      return <div className='loading' />;
   }

   if (productsError) {
      return <div className='snackbar-error'>An error occured!!</div>;
   }

   return (
      <Fragment>
         <Box className={classes.main}>
            <Grid container className={classes.header}>
               <Typography className={classes.title}> {t?.package_details} </Typography>
               <Typography className={classes.ads}>
                  <Typography variant='h6' color='textSecondary' className={classes.discount}>
                     {t?.yearly_payment_discount}
                  </Typography>
                  <Box className={classes.yearOptionsBox}>
                     <Button
                        onClick={() => setPaymentType(RenewalType.YEARLY)}
                        className={paymentType === RenewalType.YEARLY ? classes.paymentDurationBtn : ''}
                     >
                        {t?.yearly}
                     </Button>
                     <Button
                        onClick={() => setPaymentType(RenewalType.MONTHLY)}
                        className={paymentType === RenewalType.MONTHLY ? classes.paymentDurationBtn : ''}
                     >
                        {t?.monthly}
                     </Button>
                  </Box>
               </Typography>
            </Grid>
            <Grid container spacing={3} justifyContent='center' className={classes.container}>
               {products?.map((product) => {
                  return (
                     <Grid item md='3' style={{ width: window.innerWidth < 550 ? 'inherit' : null }}>
                        <PackageBox
                           active={active === product.name}
                           product={product}
                           paymentType={paymentType}
                           chooseHandler={chooseHandler}
                        />
                     </Grid>
                  );
               })}
               {!hasSpecialPackage && (
                  <Grid item md='3'>
                     <PackageBox
                        isTemp
                        product={specialPackage}
                        paymentType={paymentType}
                        chooseHandler={chooseHandler}
                     />
                  </Grid>
               )}
            </Grid>
         </Box>
         <BtnActions nextPageHandler={packageHandler} prevPageHandler={prevPageHandler} isValid={!!active} />
      </Fragment>
   );
};

export default Package;

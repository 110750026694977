import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import IOSSwitch from '../SharedComponents/IOSSwitch';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import classNames from 'classnames';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';
import FormSaveAndCancelButtons from '../SharedComponents/FormSaveAndCancelButtons';
import { VISITOR_CONTACT_INFO_VALUE } from '../types';
import Input from '../../../../../components/InputField';
import Checkbox from '../../../../../components/Checkbox';

const useStyles = makeStyles(() => ({
   formContainer: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: '20px 30px',
   },
   form: {
      paddingTop: '10px',
   },
   section: {
      background: '#F7F7FA',
      padding: '1rem !important',
      marginBottom: '1rem',
      borderRadius: 8,
   },
   sectionHeaderLeft: {
      flexGrow: 1,
   },
   sectionHeaderRight: {
      flexShrink: 0,
   },
   sectionBorderBottom: {
      borderBottom: '1px solid #EBEBEB',
   },
   cardTitle: {
      fontWeight: 600,
      fontSize: '1.1rem',
   },
   cardSubtitle: {
      color: '#ACACAC',
      fontSize: '.85rem',
   },
   cardDescription: {
      fontWeight: 400,
      fontSize: '1.1rem',
      color: '#ACACAC',
   },
   firstInputContainer: {
      paddingTop: '2rem',
      marginBottom: '5px',
   },
   inputContainer: {
      marginBottom: '5px',
   },
   lastInputContainer: {
      marginBottom: '-1rem',
   },
   switchInputContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '-5px',
      marginBottom: '0px',
   },
   fakeInput: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      marginRight: '15px',
      height: '44px',
      padding: '5px 15px',
      borderRadius: '4px',
      border: '1px solid #EBEBEB',
      backgroundColor: '#EBEBEB',
   },
   fakeInputOn: {
      backgroundColor: '#FFFFFF',
   },
   fakePlaceholder: {
      color: '#C9C9C9',
   },
   imageBox: {
      width: 100,
      height: 100,
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      border: '1px solid #ccc',
      position: 'relative',
      padding: '1rem',
   },
   addImageIcon: {
      position: 'absolute',
      color: '#157CFC',
      bottom: '-8px',
      right: '-10px',
      height: '35px',
      width: '35px',
      cursor: 'pointer',
   },
   liveChatIconContainer: {
      cursor: 'pointer',
      width: 50,
      borderRadius: '50%',
      height: 50,
      padding: '.15rem',
      border: '2px solid #5FCD6B',
   },
   liveChatIconSubContainer: {
      borderRadius: '50%',
      width: '100%',
      height: '100%',
      background: '#157CFC',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
}));

const Form = () => {
   const validURL = (str) => {
      var pattern = new RegExp(
         '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
         'i',
      ); // fragment locator
      return !!pattern.test(str);
   };

   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { liveChatPreviewSettings, updateLiveChatPreviewSettings, dataUpdatedFromServer } =
      useContext(LiveChatSettingsContext);

   if (liveChatPreviewSettings.flowBotActive) {
      window.location.replace('/settings/platforms/live-chat/appearance');
   }

   const options = [
      {
         value: VISITOR_CONTACT_INFO_VALUE.ALWAYS_ASK,
         label: t[
            `page_settings_typo_platforms_live_chat_get_started_form_contact_information_option_${VISITOR_CONTACT_INFO_VALUE.ALWAYS_ASK}`
         ],
      },
      {
         value: VISITOR_CONTACT_INFO_VALUE.ASK_OUTSIDE_WORKING_HOURS,
         label: t[
            `page_settings_typo_platforms_live_chat_get_started_form_contact_information_option_${VISITOR_CONTACT_INFO_VALUE.ASK_OUTSIDE_WORKING_HOURS}`
         ],
      },
   ];
   const contactInformationPermissionOptions = [
      {
         value: true,
         label: t[`ask_for_permission_ask`],
      },
      {
         value: false,
         label: t[`ask_for_permission_dontask`],
      },
   ];

   const defaultValues = {
      greeting: liveChatPreviewSettings.greeting,
      greetingMessage1: liveChatPreviewSettings.greetingMessage1,
      welcomeMessageDuringWorkingHours: liveChatPreviewSettings.welcomeMessageDuringWorkingHours,
      welcomeMessageDuringWorkingHoursMessage1: liveChatPreviewSettings.welcomeMessageDuringWorkingHoursMessage1,
      welcomeMessageOutsideOfWorkingHours: liveChatPreviewSettings.welcomeMessageOutsideOfWorkingHours,
      welcomeMessageOutsideOfWorkingHoursMessage1: liveChatPreviewSettings.welcomeMessageOutsideOfWorkingHoursMessage1,
      contactInformation: liveChatPreviewSettings.contactInformation,
      contactInformationSelectedOption: options.find(
         (item) => item.value === liveChatPreviewSettings.contactInformationSelectedOption?.value,
      ),

      contactInformationPermission:
         liveChatPreviewSettings.contactInformationPermission == true
            ? contactInformationPermissionOptions[0]
            : contactInformationPermissionOptions[1],

      contactInformationPermissionContent:
         liveChatPreviewSettings.contactInformationPermissionContent ||
         t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_permissionContent'],
      contactInformationPermissionLinkUrl: liveChatPreviewSettings.contactInformationPermissionLinkUrl,
      contactInformationPermissionLinkText: liveChatPreviewSettings.contactInformationPermissionLinkText,
      contactInformationTitle: liveChatPreviewSettings.contactInformationTitle,
      contactInformationMessage: liveChatPreviewSettings.contactInformationMessage,
      contactInformationFullName: liveChatPreviewSettings.contactInformationFullName,
      contactInformationEmailAddress: liveChatPreviewSettings.contactInformationEmailAddress,
      contactInformationPhoneNumber: liveChatPreviewSettings.contactInformationPhoneNumber,
      contactInformationAddress: liveChatPreviewSettings.contactInformationAddress,
      contactInformationFullNameRequired: liveChatPreviewSettings.contactInformationFullNameRequired
         ? { label: t.required, value: true }
         : { label: t.not_required, value: false },
      //  liveChatPreviewSettings.contactInformationFullNameRequired,
      contactInformationEmailAddressRequired: liveChatPreviewSettings.contactInformationEmailAddressRequired
         ? { label: t.required, value: true }
         : { label: t.not_required, value: false },
      contactInformationPhoneNumberRequired: liveChatPreviewSettings.contactInformationPhoneNumberRequired
         ? { label: t.required, value: true }
         : { label: t.not_required, value: false },
      contactInformationAddressRequired: liveChatPreviewSettings.contactInformationAddressRequired
         ? { label: t.required, value: true }
         : { label: t.not_required, value: false },

      contactInformationThanksMessage: liveChatPreviewSettings.contactInformationThanksMessage,
      rateUs: liveChatPreviewSettings.rateUs,
      rateUsTitle: liveChatPreviewSettings.rateUsTitle,
      rateUsMessage: liveChatPreviewSettings.rateUsMessage,
      rateUsThanksMessage: liveChatPreviewSettings.rateUsThanksMessage,
      minutesToClose: liveChatPreviewSettings.minutesToClose,
   };

   const { control, watch, setValue, reset } = useForm({
      mode: 'all',
      defaultValues: useMemo(() => {
         return defaultValues;
      }, [liveChatPreviewSettings]),
   });

   useEffect(() => {
      reset(defaultValues);
   }, [dataUpdatedFromServer]);

   const {
      greeting,
      greetingMessage1,
      welcomeMessageDuringWorkingHours,
      welcomeMessageDuringWorkingHoursMessage1,
      welcomeMessageOutsideOfWorkingHours,
      welcomeMessageOutsideOfWorkingHoursMessage1,
      contactInformation,
      contactInformationSelectedOption,
      contactInformationTitle,
      contactInformationMessage,
      contactInformationFullName,
      contactInformationEmailAddress,
      contactInformationPhoneNumber,
      contactInformationAddress,
      contactInformationThanksMessage,
      rateUs,
      rateUsTitle,
      rateUsMessage,
      rateUsThanksMessage,
      contactInformationFullNameRequired,
      contactInformationEmailAddressRequired,
      contactInformationPhoneNumberRequired,
      contactInformationAddressRequired,
      contactInformationPermission,
      contactInformationPermissionContent,
      contactInformationPermissionLinkUrl,
      contactInformationPermissionLinkText,
      minutesToClose,
   } = watch();
   const RequiredSelectOptions = [
      { label: t.required, value: true },
      { label: t.not_required, value: false },
   ];
   useEffect(() => {
      setValue(
         'contactInformationFullNameRequired',
         liveChatPreviewSettings.contactInformationFullNameRequired
            ? { label: t.required, value: true }
            : { label: t.not_required, value: false },
      );

      setValue(
         'contactInformationEmailAddressRequired',
         liveChatPreviewSettings.contactInformationEmailAddressRequired
            ? { label: t.required, value: true }
            : { label: t.not_required, value: false },
      );

      setValue(
         'contactInformationAddressRequired',
         liveChatPreviewSettings.contactInformationAddressRequired
            ? { label: t.required, value: true }
            : { label: t.not_required, value: false },
      );

      setValue(
         'contactInformationPhoneNumberRequired',
         liveChatPreviewSettings.contactInformationPhoneNumberRequired
            ? { label: t.required, value: true }
            : { label: t.not_required, value: false },
      );

      setValue(
         'contactInformationPermission',
         liveChatPreviewSettings.contactInformationPermission
            ? { label: t.ask_for_permission_ask, value: true }
            : { label: t.ask_for_permission_dontask, value: false },
      );
   }, [t]);

   useEffect(() => {
      updateLiveChatPreviewSettings({
         greeting,
         greetingMessage1,
         welcomeMessageDuringWorkingHours,
         welcomeMessageDuringWorkingHoursMessage1,
         welcomeMessageOutsideOfWorkingHours,
         welcomeMessageOutsideOfWorkingHoursMessage1,
         contactInformation,
         contactInformationSelectedOption: { value: contactInformationSelectedOption.value },
         contactInformationTitle,
         contactInformationMessage,
         contactInformationFullName,
         contactInformationEmailAddress,
         contactInformationPhoneNumber,
         contactInformationAddress,
         contactInformationFullNameRequired: contactInformationFullNameRequired.value,
         contactInformationEmailAddressRequired: contactInformationEmailAddressRequired.value,
         contactInformationPhoneNumberRequired: contactInformationPhoneNumberRequired.value,
         contactInformationAddressRequired: contactInformationAddressRequired.value,
         contactInformationThanksMessage,
         contactInformationPermission: contactInformationPermission.value,
         contactInformationPermissionContent,
         contactInformationPermissionLinkUrl,
         contactInformationPermissionLinkText,
         rateUs,
         rateUsTitle,
         rateUsMessage,
         rateUsThanksMessage,
         minutesToClose,
      });
   }, [
      greeting,
      greetingMessage1,
      welcomeMessageDuringWorkingHours,
      welcomeMessageDuringWorkingHoursMessage1,
      welcomeMessageOutsideOfWorkingHours,
      welcomeMessageOutsideOfWorkingHoursMessage1,
      contactInformation,
      contactInformationSelectedOption,
      contactInformationTitle,
      contactInformationMessage,
      contactInformationFullName,
      contactInformationEmailAddress,
      contactInformationPhoneNumber,
      contactInformationAddress,
      contactInformationThanksMessage,
      rateUs,
      rateUsTitle,
      rateUsMessage,
      rateUsThanksMessage,
      contactInformationFullNameRequired,
      contactInformationEmailAddressRequired,
      contactInformationPhoneNumberRequired,
      contactInformationAddressRequired,
      contactInformationPermission,
      contactInformationPermissionContent,
      contactInformationPermissionLinkUrl,
      contactInformationPermissionLinkText,
      minutesToClose,
   ]);

   return (
      <>
         <div className={classes.formContainer}>
            <Grid container alignItems='center' spacing={1} className={classes.form}>
               <Grid item md={12} className={classes.section}>
                  <Grid container spacing={3} className={classNames(greeting && classes.sectionBorderBottom)}>
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.cardTitle}>
                           {t['page_settings_typo_platforms_live_chat_get_started_form_greeting_card_title']}
                        </Typography>
                        <Typography className={classes.cardSubtitle}>
                           {t['page_settings_typo_platforms_live_chat_get_started_form_greeting_card_subtitle']}
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch checked={greeting} onChange={() => setValue('greeting', !greeting)} />
                     </Grid>
                  </Grid>
                  {greeting && (
                     <React.Fragment>
                        <Box className={classes.firstInputContainer}>
                           <Input
                              name='greetingMessage1'
                              label={
                                 t['page_settings_typo_platforms_live_chat_get_started_form_greeting_message_1_label']
                              }
                              labelColor='black'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_greeting_message_1_placeholder'
                                 ]
                              }
                              control={control}
                              background={'#FFFFFF'}
                              multiline
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_get_started_form_greeting_message_1_required'
                                    ],
                              }}
                           />
                        </Box>
                     </React.Fragment>
                  )}
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid
                     container
                     spacing={3}
                     className={classNames(welcomeMessageDuringWorkingHours && classes.sectionBorderBottom)}
                  >
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.cardTitle}>
                           {
                              t[
                                 'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_during_working_hours_card_title'
                              ]
                           }{' '}
                           <span className={classes.cardDescription}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_during_working_hours_card_title_description'
                                 ]
                              }
                           </span>
                        </Typography>
                        <Typography className={classes.cardSubtitle}>
                           {
                              t[
                                 'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_during_working_hours_card_subtitle'
                              ]
                           }
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch
                           checked={welcomeMessageDuringWorkingHours}
                           onChange={() =>
                              setValue('welcomeMessageDuringWorkingHours', !welcomeMessageDuringWorkingHours)
                           }
                        />
                     </Grid>
                  </Grid>
                  {welcomeMessageDuringWorkingHours && (
                     <React.Fragment>
                        <Box className={classes.firstInputContainer}>
                           <Input
                              name='welcomeMessageDuringWorkingHoursMessage1'
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_during_working_hours_1_label'
                                 ]
                              }
                              labelColor='black'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_during_working_hours_1_placeholder'
                                 ]
                              }
                              control={control}
                              background={'#FFFFFF'}
                              multiline
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_during_working_hours_1_required'
                                    ],
                              }}
                           />
                        </Box>
                     </React.Fragment>
                  )}
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid
                     container
                     spacing={3}
                     className={classNames(welcomeMessageOutsideOfWorkingHours && classes.sectionBorderBottom)}
                  >
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.cardTitle}>
                           {
                              t[
                                 'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_outside_of_working_hours_card_title'
                              ]
                           }{' '}
                           <span className={classes.cardDescription}>
                              {
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_outside_of_working_hours_card_title_description'
                                 ]
                              }
                           </span>
                        </Typography>
                        <Typography className={classes.cardSubtitle}>
                           {
                              t[
                                 'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_outside_of_working_hours_card_subtitle'
                              ]
                           }
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch
                           checked={welcomeMessageOutsideOfWorkingHours}
                           onChange={() =>
                              setValue('welcomeMessageOutsideOfWorkingHours', !welcomeMessageOutsideOfWorkingHours)
                           }
                        />
                     </Grid>
                  </Grid>
                  {welcomeMessageOutsideOfWorkingHours && (
                     <React.Fragment>
                        <Box className={classes.firstInputContainer}>
                           <Input
                              name='welcomeMessageOutsideOfWorkingHoursMessage1'
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_outside_of_working_hours_1_label'
                                 ]
                              }
                              labelColor='black'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_outside_of_working_hours_1_placeholder'
                                 ]
                              }
                              control={control}
                              background={'#FFFFFF'}
                              multiline
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_get_started_form_welcome_message_outside_of_working_hours_1_required'
                                    ],
                              }}
                           />
                        </Box>
                     </React.Fragment>
                  )}
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid container spacing={3} className={classNames(contactInformation && classes.sectionBorderBottom)}>
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.cardTitle}>
                           {t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_card_title']}
                        </Typography>
                        <Typography className={classes.cardSubtitle}>
                           {
                              t[
                                 'page_settings_typo_platforms_live_chat_get_started_form_contact_information_card_subtitle'
                              ]
                           }
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch
                           checked={contactInformation}
                           onChange={() => setValue('contactInformation', !contactInformation)}
                        />
                     </Grid>
                  </Grid>
                  {contactInformation && (
                     <React.Fragment>
                        <Box className={classes.firstInputContainer}>
                           <Input
                              name='contactInformationSelectedOption'
                              isSelect
                              selectOptions={options}
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_contact_information_select_label'
                                 ]
                              }
                              labelColor='black'
                              placeholder={
                                 t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_title_placeholder']
                              }
                              control={control}
                              background={'#FFFFFF'}
                           />
                        </Box>
                        <Box className={classes.inputContainer}>
                           <Input
                              name='contactInformationTitle'
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_contact_information_title_label'
                                 ]
                              }
                              labelColor='black'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_contact_information_title_placeholder'
                                 ]
                              }
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_get_started_form_contact_information_title__required'
                                    ],
                              }}
                           />
                        </Box>
                        <Box className={classes.inputContainer}>
                           <Input
                              name='contactInformationMessage'
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_contact_information_message_label'
                                 ]
                              }
                              labelColor='black'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_contact_information_message_placeholder'
                                 ]
                              }
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_get_started_form_contact_information_message_required'
                                    ],
                              }}
                           />
                        </Box>
                        <Box className={classes.inputContainer} style={{ paddingBottom: '5px' }}>
                           <div className={classes.switchInputContainer}>
                              <div
                                 style={{ minWidth: '70%' }}
                                 className={classNames(
                                    classes.fakeInput,
                                    contactInformationFullName && classes.fakeInputOn,
                                 )}
                              >
                                 <Typography className={classes.fakePlaceholder}>
                                    {
                                       t[
                                          'page_settings_typo_platforms_live_chat_get_started_form_contact_information_full_name'
                                       ]
                                    }
                                 </Typography>
                              </div>
                              <Input
                                 name='contactInformationFullNameRequired'
                                 isSelect
                                 selectOptions={RequiredSelectOptions}
                                 labelColor='black'
                                 control={control}
                                 background={'#FFFFFF'}
                                 customSelectStyles={{ width: 20 }}
                              />

                              <IOSSwitch
                                 checked={contactInformationFullName}
                                 onChange={() => setValue('contactInformationFullName', !contactInformationFullName)}
                              />
                           </div>
                           <div className={classes.switchInputContainer}>
                              <div
                                 style={{ minWidth: '70%' }}
                                 className={classNames(
                                    classes.fakeInput,
                                    contactInformationEmailAddress && classes.fakeInputOn,
                                 )}
                              >
                                 <Typography className={classes.fakePlaceholder}>
                                    {
                                       t[
                                          'page_settings_typo_platforms_live_chat_get_started_form_contact_information_email_address'
                                       ]
                                    }
                                 </Typography>
                              </div>
                              <Input
                                 name='contactInformationEmailAddressRequired'
                                 isSelect
                                 selectOptions={RequiredSelectOptions}
                                 labelColor='black'
                                 control={control}
                                 background={'#FFFFFF'}
                                 customSelectStyles={{ width: 20 }}
                              />
                              <IOSSwitch
                                 checked={contactInformationEmailAddress}
                                 onChange={() =>
                                    setValue('contactInformationEmailAddress', !contactInformationEmailAddress)
                                 }
                              />
                           </div>
                           <div className={classes.switchInputContainer}>
                              <div
                                 style={{ minWidth: '70%' }}
                                 className={classNames(
                                    classes.fakeInput,
                                    contactInformationPhoneNumber && classes.fakeInputOn,
                                 )}
                              >
                                 <Typography className={classes.fakePlaceholder}>
                                    {
                                       t[
                                          'page_settings_typo_platforms_live_chat_get_started_form_contact_information_phone_number'
                                       ]
                                    }
                                 </Typography>
                              </div>
                              <Input
                                 name='contactInformationPhoneNumberRequired'
                                 isSelect
                                 selectOptions={RequiredSelectOptions}
                                 labelColor='black'
                                 control={control}
                                 background={'#FFFFFF'}
                                 customSelectStyles={{ width: 20 }}
                              />
                              <IOSSwitch
                                 checked={contactInformationPhoneNumber}
                                 onChange={() =>
                                    setValue('contactInformationPhoneNumber', !contactInformationPhoneNumber)
                                 }
                              />
                           </div>
                           <div className={classes.switchInputContainer}>
                              <div
                                 style={{ minWidth: '70%' }}
                                 className={classNames(
                                    classes.fakeInput,
                                    contactInformationAddress && classes.fakeInputOn,
                                 )}
                              >
                                 <Typography className={classes.fakePlaceholder}>
                                    {
                                       t[
                                          'page_settings_typo_platforms_live_chat_get_started_form_contact_information_address'
                                       ]
                                    }
                                 </Typography>
                              </div>
                              <Input
                                 name='contactInformationAddressRequired'
                                 isSelect
                                 selectOptions={RequiredSelectOptions}
                                 labelColor='black'
                                 control={control}
                                 background={'#FFFFFF'}
                                 customSelectStyles={{ width: 20 }}
                              />
                              <IOSSwitch
                                 checked={contactInformationAddress}
                                 onChange={() => setValue('contactInformationAddress', !contactInformationAddress)}
                              />
                           </div>
                        </Box>
                        <Grid container style={{ marginBottom: 15 }} spacing={2}>
                           <Grid item xs={4} className={classes.lastInputContainer}>
                              <Input
                                 disabled={!contactInformationEmailAddress && !contactInformationPhoneNumber}
                                 name='contactInformationPermission'
                                 isSelect
                                 label={t['ask_for_permission']}
                                 labelColor='black'
                                 selectOptions={contactInformationPermissionOptions}
                                 control={control}
                                 background={'#FFFFFF'}
                                 rules={{
                                    required:
                                       t[
                                          'page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_message_required'
                                       ],
                                 }}
                              />
                           </Grid>

                           <Grid item xs={8} className={classes.lastInputContainer}>
                              <Input
                                 disabled={
                                    !contactInformationPermission.value ||
                                    (!contactInformationEmailAddress && !contactInformationPhoneNumber)
                                 }
                                 name='contactInformationPermissionContent'
                                 label={t['ask_for_permission_content']}
                                 labelColor='black'
                                 control={control}
                                 background={'#FFFFFF'}
                                 rules={{
                                    required: true,
                                    maxLength: {
                                       value: 70,
                                       message: t?.max_70_characters,
                                    },
                                 }}
                                 maxLength={70}
                              />
                           </Grid>
                        </Grid>
                        <Grid container style={{ marginBottom: 15 }} spacing={2}>
                           <Grid item xs={6} className={classes.lastInputContainer}>
                              <Input
                                 disabled={
                                    !contactInformationPermission.value ||
                                    (!contactInformationEmailAddress && !contactInformationPhoneNumber)
                                 }
                                 name='contactInformationPermissionLinkUrl'
                                 label={t['ask_for_permission_link_url']}
                                 labelColor='black'
                                 placeholder='https://'
                                 control={control}
                                 background={'#FFFFFF'}
                                 rules={{
                                    required: false,
                                    // t[
                                    //    'page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_message_required'
                                    // ],
                                    validate: (text) => {
                                       if (contactInformationPermissionLinkText?.length > 1 && text?.length < 1)
                                          return false;
                                    },

                                    pattern:
                                       /^$|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi,
                                 }}
                              />
                           </Grid>

                           <Grid item xs={6} className={classes.lastInputContainer}>
                              <Input
                                 disabled={
                                    !contactInformationPermission.value ||
                                    (!contactInformationEmailAddress && !contactInformationPhoneNumber)
                                 }
                                 name='contactInformationPermissionLinkText'
                                 label={t['ask_for_permission_link_text']}
                                 labelColor='black'
                                 control={control}
                                 background={'#FFFFFF'}
                                 maxLength={30}
                                 rules={{
                                    maxLength: {
                                       value: 30,
                                       message: t?.max_30_characters,
                                    },
                                    required: false,
                                    validate: (text) => {
                                       if (validURL(contactInformationPermissionLinkUrl) && text?.length < 1)
                                          return false;
                                       if (contactInformationPermissionLinkUrl?.length < 1 && text?.length > 1)
                                          return false;
                                       return true;
                                    },
                                 }}
                              />
                           </Grid>
                        </Grid>

                        <Box className={classes.lastInputContainer}>
                           <Input
                              name='contactInformationThanksMessage'
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_message_label'
                                 ]
                              }
                              labelColor='black'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_message_placeholder'
                                 ]
                              }
                              control={control}
                              background={'#FFFFFF'}
                           />
                        </Box>
                     </React.Fragment>
                  )}
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid container spacing={3} className={classNames(rateUs && classes.sectionBorderBottom)}>
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.cardTitle}>
                           {t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_card_title']}
                        </Typography>
                        <Typography className={classes.cardSubtitle}>
                           {t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_card_subtitle']}
                        </Typography>
                     </Grid>
                     <Grid item className={classes.sectionHeaderRight}>
                        <IOSSwitch checked={rateUs} onChange={() => setValue('rateUs', !rateUs)} />
                     </Grid>
                  </Grid>
                  {rateUs && (
                     <React.Fragment>
                        <Box className={classes.firstInputContainer}>
                           <Input
                              name='rateUsTitle'
                              label={t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_title_label']}
                              labelColor='black'
                              placeholder={
                                 t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_title_placeholder']
                              }
                              control={control}
                              background={'#FFFFFF'}
                              rules={{
                                 required:
                                    t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_title_required'],
                              }}
                           />
                        </Box>
                        <Box className={classes.inputContainer}>
                           <Input
                              name='rateUsMessage'
                              label={t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_message_label']}
                              labelColor='black'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_rate_us_message_placeholder'
                                 ]
                              }
                              control={control}
                              background={'#FFFFFF'}
                              multiline
                              rules={{
                                 required:
                                    t[
                                       'page_settings_typo_platforms_live_chat_get_started_form_rate_us_message_required'
                                    ],
                              }}
                           />
                        </Box>
                        <Box className={classes.lastInputContainer}>
                           <Input
                              name='rateUsThanksMessage'
                              label={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_rate_us_thanks_message_label'
                                 ]
                              }
                              labelColor='black'
                              placeholder={
                                 t[
                                    'page_settings_typo_platforms_live_chat_get_started_form_rate_us_thanks_message_placeholder'
                                 ]
                              }
                              control={control}
                              background={'#FFFFFF'}
                              multiline
                           />
                        </Box>
                     </React.Fragment>
                  )}
               </Grid>
               <Grid item md={12} className={classes.section}>
                  <Grid container spacing={3} className={classNames(rateUs && classes.sectionBorderBottom)}>
                     <Grid item className={classes.sectionHeaderLeft}>
                        <Typography className={classes.cardTitle}>
                           {t['page_settings_typo_platforms_live_chat_get_started_form_auto_close_title']}
                        </Typography>
                        <Typography className={classes.cardSubtitle}>
                           {t['page_settings_typo_platforms_live_chat_get_started_form_auto_close_description']}
                        </Typography>
                     </Grid>
                  </Grid>
                  <React.Fragment>
                     <Box className={classes.firstInputContainer}>
                        <Input
                           name='minutesToClose'
                           type='number'
                           label={t['page_settings_typo_platforms_live_chat_get_started_form_auto_close_label']}
                           labelColor='black'
                           placeholder={
                              t['page_settings_typo_platforms_live_chat_get_started_form_auto_close_placeholder']
                           }
                           control={control}
                           background={'#FFFFFF'}
                           rules={{
                              required:
                                 t['page_settings_typo_platforms_live_chat_get_started_form_auto_close_required'],
                              validate: (value) => {
                                 if (value < 0) {
                                    return t[
                                       'page_settings_typo_platforms_live_chat_get_started_form_auto_close_min_value'
                                    ];
                                 } else if (value > 60) {
                                    return t[
                                       'page_settings_typo_platforms_live_chat_get_started_form_auto_close_max_value'
                                    ];
                                 } else return true;
                              },
                           }}
                        />
                     </Box>
                  </React.Fragment>
               </Grid>
            </Grid>
         </div>
         <FormSaveAndCancelButtons resetValues={reset} />
      </>
   );
};

export default Form;

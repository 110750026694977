import { Table, TableContainer, TableHead, TableRow, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import { StyledTableCell, StyledTableRow } from '../shared-components/tableItems';
import DeletePlatformModal from '@components/Modal/DeletePlatformModal';
import ActionIconButton from '@modules/Settings/SettingSpace/ActionIconButton';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
   },
}));

const DataTable = ({ tableData, handleDisconnect, handleDelete, handleEdit }) => {
   const classes = useStyles();
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [selectedDeleteItemId, setSelectedDeletedItemId] = useState(null);

   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   return (
      <TableContainer style={{ padding: 10 }}>
         <Table className={classes.table} aria-label='customized table'>
            <TableHead>
               <TableRow>
                  <StyledTableCell align='left'> {t['settings_page_question_store_name']}</StyledTableCell>
                  <StyledTableCell align='left'>{t['teams']}</StyledTableCell>
                  <StyledTableCell align='left'>{t?.status}</StyledTableCell>
                  <StyledTableCell align='left'>{t?.operation}</StyledTableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {tableData?.map((item) => (
                  <StyledTableRow key={item.apiKey}>
                     <StyledTableCell
                        component='th'
                        scope='row'
                        className={classes.coloredText}
                        style={{ fontWeight: 'bold' }}
                     >
                        {item.storeName}
                     </StyledTableCell>
                     <StyledTableCell
                        component='th'
                        scope='row'
                        style={{ color: item.teams.length > 0 ? 'initial' : 'red' }}
                     >
                        {item.teams.length > 0 ? item.teams.length + ' ' + t['teams_with_number'] : t.no_teams}
                     </StyledTableCell>
                     <StyledTableCell
                        align='left'
                        style={{ color: item.isConnected ? '#5AC19D' : 'red' }}
                        className={classes.coloredText}
                     >
                        {item.isConnected ? t?.page_settings_whatsapp_active : t?.page_settings_whatsapp_not_active}
                     </StyledTableCell>
                     <StyledTableCell align='left'>
                        <ActionIconButton
                           onClick={() => handleEdit(item)}
                           tooltip={t?.comp_button_edit}
                           actionType='EDIT'
                        />
                        <ActionIconButton
                           onClick={() => handleDisconnect(item)}
                           tooltip={item.isConnected ? t?.comp_button_disconnect : t?.comp_button_activate}
                           actionType={item.isConnected ? 'DISCONNECT' : 'CONNECT'}
                        />
                        <ActionIconButton
                           onClick={() => {
                              setShowDeleteModal(true);
                              setSelectedDeletedItemId(item);
                           }}
                           tooltip={t?.comp_button_delete}
                           actionType='DELETE'
                        />
                     </StyledTableCell>
                  </StyledTableRow>
               ))}
            </TableBody>
         </Table>
         <DeletePlatformModal
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            selectedDeleteItemId={selectedDeleteItemId}
            onComplate={handleDelete}
         />
      </TableContainer>
   );
};

export default DataTable;

import React, { useState, useEffect, useContext } from 'react';
import { Grid, Button, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { Node } from 'reactflow';
import { TagsInput } from 'react-tag-input-component';
import TelegramIcon from '@mui/icons-material/Telegram';
import {
   FlowBlockType,
   FlowInstanceMessageChannel,
   IFlowBlockField,
   IFlowBlockFieldRules,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { LanguageContext } from '@helper/locale/langContext';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
   ADD_FLOW_BLOCK_MUTATION,
   REMOVE_BLOCK_FROM_NODE_MUTATION,
   UPDATE_BLOCK_MUTATION,
} from '@queries/Automation/mutation';
import { GET_LIST_BLOCK_TEMPLATES_QUERY } from '@queries/Automation/query';
import { SET_BLOCKTEMPLATES } from '@store/actions/automation';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import {
   FacebookMessengerIcon,
   InstagramIcon,
   LiveChatIcon,
   WhatsAppBusiness,
   WhatsAppIcon,
} from '@modules/Automation/FlowBots/components/socialIcons';
import SingleInput from '@components/_Modal/SingleInput';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
   deleteIcon: {
      color: 'gray',
      backgroundColor: '#fff',
      borderRadius: 3,
      cursor: 'pointer',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
   },
   container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      fontSize: '1rem',
      position: 'relative',
   },
   newMessageReceived: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 0',
      margin: '5px 0',
      border: '1px solid #E7E7E7',
      borderRadius: '5px',
   },
   header: {
      display: 'flex',
      gap: '5px',
      padding: '0 10px 10px 10px',
      borderBottom: '1px solid #E7E7E7',
   },
   content: { fontSize: '0.9rem', padding: '10px' },
   titleKey: { fontSize: '0.9rem', padding: '10px 10px 0 10px' },
   blocks: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      backgroundColor: '#F5F5F5',
      borderRadius: '10px',
   },
   handle: {
      backgroundColor: '#fff',
      width: '16px',
      height: '16px',
      border: '1px solid #ADADAD',
      borderRadius: '50%',
      marginTop: '5px',
   },
   blockTags: {
      display: 'grid',
      gridTemplateColumns: '10fr 1fr',
      padding: '0 10px 0 5px',
      backgroundColor: '#FFF',
      borderRadius: '10px',
      border: '1px solid #E7E7E7',
      width: '100%',
   },
   tags: {
      border: 'none',
   },

   btnDashed: {
      height: 40,
      width: '100%',
      border: '1px dashed #D9D9EB',
      borderRadius: '10px',
      background: 'transparent',
      cursor: 'pointer',
   },
   iconBox: {
      padding: '5px',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
   },
   icon: {
      width: 30,
      height: 30,
   },
}));

interface UserSendMessageProps {
   leftNodes: Node[];
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   isSaveTrigger: boolean;
   setIsSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   clickedNodeId: string;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
}

const UserSendMessage: React.FC<UserSendMessageProps> = ({
   leftNodes,
   setContentNodes,
   setLeftNodes,
   setIsBtnDisable,
   isSaveTrigger,
   setIsSaveTrigger,
   clickedNodeId,
   setIsLoading,
   isLoading,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [isHover, setIsHover] = useState(false);
   const [isDeletable, setIsDeletable] = useState(true);
   const [hoverId, setHoverId] = useState<string>('');
   const [blocks, setBlocks] = useState<IFlowBlockTemplate[]>([]);
   const [addTag, setAddTag] = useState<{ _id: string }[]>([]);
   const [idTDelete, setIdToDelete] = useState<string[]>([]);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [seletedFlowBlockId, setSelectedFlowBlockId] = useState<string>('');
   const [showUD, setShowUD] = useState(false);
   const dispatch = useDispatch();
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const [getBlockTemplate] = useLazyQuery(GET_LIST_BLOCK_TEMPLATES_QUERY);
   const [account, setAccount] = useState<string[]>([]);
   const [sendersData, setSendersData] = useState<{ _id: string; name: string; type: string; firstName: string }[]>([]);
   const PLATFORM = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.platforms);
   const { control, watch, setValue } = useForm({
      mode: 'all',
   });

   useEffect(() => {
      setSendersData(PLATFORM);
   }, [PLATFORM]);

   useEffect(() => {
      const platformIds = blocks
         .flatMap((field) => field?.fields)
         .flatMap((field) => field?.platformIds)
         .filter((platformId) => platformId !== undefined && platformId !== null);

      const platformAccount = platformIds.length > 0 ? platformIds : account;

      const selectedSenders = sendersData?.filter((sender) => platformAccount?.includes(sender._id));
      // Create options for default values
      const defaultValues = selectedSenders
         ? selectedSenders.map((sender) => ({
              label:
                 sender.type === 'LIVE_CHAT'
                    ? 'Live Chat'
                    : sender.type === 'TELEGRAM'
                    ? sender.firstName
                    : sender.name,
              value: sender._id,
           }))
         : null;

      setValue('senders', defaultValues);
   }, [clickedNodeId, blocks, sendersData, setValue]);

   const handleSenderChange = (selectedPlatform: any) => {
      setIsBtnDisable(false);
      const values = selectedPlatform?.map((pair: { value: string }) => pair.value);

      setAccount(values);

      const blockId = blocks.map((pair: { _id: string }) => pair._id);
      blockId.forEach((item) => {
         if (!addTag.some((tag) => tag._id === item)) {
            setAddTag((prev) => [...prev, { _id: item }]);
         }
      });
   };

   useEffect(() => {
      setBlocks((prevBlocks) =>
         prevBlocks.map((block) => ({
            ...block,
            fields: block.fields.map((field) => ({
               ...field,
               platformIds: account,
            })),
         })),
      );
   }, [account]);

   const handleMenuClose = () => {
      setAnchorEl(null);
      setShowUD(false);
   };

   const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, flowBlockId: string) => {
      setAnchorEl(event.currentTarget);
      setSelectedFlowBlockId(flowBlockId);
      setShowUD(!showUD);
   };

   const handleTagsOptionChange = (id: string, newTitle: IFlowBlockFieldRules) => {
      setBlocks((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               // Update the rule with the new text
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     rule: newTitle,
                  })),
               };
            }
            return item;
         });

         setIsBtnDisable(false);

         return updatedBlockState;
      });

      setAddTag((prev) => [...prev, { _id: id }]);

      handleMenuClose();
   };
   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlock = async (blockId: string, fields: IFlowBlockField[]) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields.map((field: any) => {
                     for (let [key, value] of Object.entries(field)) {
                        if (value == null) {
                           delete field[key];
                        }
                     }
                     return field;
                  }),
               },
            },
            onCompleted: (res) => {
               setIsDeletable(true);
               setIsLoading(false);
               setIsBtnDisable(true);

               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // Add block mutation
   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlockTag = async (flowBlockTemplateId: string, nodeId: string, fields: IFlowBlockField[]) => {
      try {
         setIsLoading(true);
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  channelType: blocks[0]?.channelType,
                  fields: fields,
               },
            },

            onCompleted: (res) => {
               setIsDeletable(true);
               setIsLoading(false);
               setIsBtnDisable(true);

               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              flowBlocks: [...node.data.flowBlocks, response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   // Delete block mutation
   const [deleteBlock] = useMutation(REMOVE_BLOCK_FROM_NODE_MUTATION);
   const handleDeleteFlowBlock = async (blockId: String, nodeId: string) => {
      try {
         await deleteBlock({
            variables: {
               input: {
                  block: blockId,
                  node: nodeId,
               },
            },
            onCompleted: (res) => {
               setIsLoading(false);

               setContentNodes((prevNodes) =>
                  prevNodes.map((node) =>
                     node.id === clickedNodeId
                        ? {
                             ...node,
                             data: {
                                ...node.data,
                                flowBlocks: node.data.flowBlocks.filter(
                                   (flowBlock: IFlowBlockTemplate) => !blockId.includes(flowBlock._id),
                                ),
                             },
                          }
                        : node,
                  ),
               );
               setLeftNodes((prevNodes) =>
                  prevNodes.map((node) =>
                     node.id === clickedNodeId
                        ? {
                             ...node,
                             data: {
                                ...node.data,
                                flowBlocks: node.data.flowBlocks.filter(
                                   (flowBlock: IFlowBlockTemplate) => !blockId.includes(flowBlock._id),
                                ),
                             },
                          }
                        : node,
                  ),
               );
            },
         });
      } catch (error) {
         console.error('Error deleting block:', error);
      }
   };

   const handleCreateBlock = (title: string) => {
      setIsBtnDisable(true);

      const platformIds = blocks
         .flatMap((field) => field?.fields)
         .flatMap((field) => field?.platformIds)
         .filter((platformId) => platformId !== undefined && platformId !== null);

      const platformAccount = platformIds.length > 0 ? platformIds : account;

      if (blocks.length < 10) {
         setIsBtnDisable(true);
         if (blocks.length < 10) {
            const newItem = {
               _id: Date.now().toString(),
               title: title,
               fields: [
                  {
                     type: IFlowBlockFieldTypes.keywordsMessageReceived,
                     rule: IFlowBlockFieldRules.Contains,
                     keywords: [],
                     platformIds: platformAccount?.filter((id) => id !== undefined) as string[],
                  },
               ],
               type: FlowBlockType.NewMessageReceived,
            };
            setBlocks((prevBlockState) => [...prevBlockState, newItem]);
         }
      }
   };

   const handleBlockInputChange = (flowBlockId: string, newTags: string[]) => {
      setIsBtnDisable(false);
      setBlocks((prevBlocks) =>
         prevBlocks.map((block) => {
            if (block._id === flowBlockId) {
               return {
                  ...block,
                  fields: block.fields.map((field) => ({
                     ...field,
                     keywords: [...newTags],
                  })),
               };
            }
            return block;
         }),
      );
      // Filter out the previous tags with the same _id
      setAddTag((prevTags) => prevTags.filter((tag) => tag._id !== flowBlockId));

      // Add the new tag
      setAddTag((prev) => [...prev, { _id: flowBlockId }]);
   };

   const handleBlockDelete = (idToDelete: string) => {
      setIsBtnDisable(false);
      setBlocks((prevBlock) => {
         return prevBlock.filter((item) => item._id !== idToDelete);
      });

      setIdToDelete((prev) => [...prev, idToDelete]);
   };

   const backendBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];

   // if the user click on save button
   useEffect(() => {
      if (isSaveTrigger && !isLoading) {
         const newBlocks = blocks.filter(
            (block) => !backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );
         const existingBlocks = blocks.filter((block) =>
            backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         const save = async () => {
            // Add all new tags into the backend
            if (addTag.length > 0) {
               if (existingBlocks) {
                  setIsLoading(true);
                  for await (let item of existingBlocks) {
                     if (addTag.some((tag) => tag._id === item._id)) {
                        const fields = item.fields;
                        handleUpdateFlowBlock(item._id, fields);
                     }
                  }
               }
               if (newBlocks) {
                  const fetchBlockTemplates = async () => {
                     const response = await getBlockTemplate({
                        variables: { input: {} },
                        fetchPolicy: 'network-only',
                     });

                     if (response.data) {
                        return response.data.listBlockTemplates.data;
                     }

                     return null;
                  };

                  (async () => {
                     let blockTemplate = [];
                     if (LIST_BLOCK_TEMPLATE?.length > 0) {
                        blockTemplate = LIST_BLOCK_TEMPLATE;
                     } else {
                        blockTemplate = await fetchBlockTemplates();
                        dispatch({ type: SET_BLOCKTEMPLATES, payload: blockTemplate });
                     }

                     if (blockTemplate) {
                        const templateBlockId = blockTemplate.find(
                           (item: { type: FlowBlockType }) => item.type === FlowBlockType.NewMessageReceived,
                        )?._id;
                        setIsDeletable(false);
                        for await (let item of newBlocks) {
                           if (addTag.some((tag) => tag._id === item._id)) {
                              const fields = item.fields;
                              if (templateBlockId) {
                                 handleAddFlowBlockTag(templateBlockId, clickedNodeId, fields);
                              }
                           }
                        }
                     }
                  })();
               }
               setAddTag([]);
            }
         };

         // Delete blocks into the backend
         if (idTDelete.length > 0) {
            const filteredBlocksId = idTDelete.filter((blockId) =>
               backendBlocks.some((block: { _id: string }) => block._id === blockId),
            );
            setIsLoading(true);
            filteredBlocksId.forEach((element) => {
               handleDeleteFlowBlock(element, clickedNodeId);
            });
            setIdToDelete([]);
         }
         save();
         setIsSaveTrigger(false);
      }
   }, [isSaveTrigger]);

   const handleSetIsHover = (blockId: string) => {
      setIsHover(true);
      setHoverId(blockId);
   };

   const validateTagLength = (newTag: string | string[], field: string[] | undefined) => {
      const totalTags = 10;
      const tagLength = 32;

      if (field && field.length + 1 < totalTags && newTag.length <= tagLength) {
         setIsBtnDisable(false);
         return true;
      } else {
         return false;
      }
   };

   const newBlockItems = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   //default
   useEffect(() => {
      setBlocks(newBlockItems);
      setIsBtnDisable(true);
   }, [clickedNodeId, newBlockItems, leftNodes]);

   const multipleTagBlocks = blocks.filter(
      (item) =>
         item.type === FlowBlockType.NewMessageReceived &&
         Array.isArray(item.fields) &&
         item.fields.some((field) => field.type === IFlowBlockFieldTypes.keywordsMessageReceived),
   );

   // Enable or disable button
   useEffect(() => {
      const blockState = blocks.slice(1);
      const isInvalid = blockState.some((block) =>
         block.fields.some(
            (field) =>
               field.platformIds === null ||
               field.platformIds === undefined ||
               field.platformIds?.length === 0 ||
               (field.keywords?.length === 0 &&
                  !(field.rule === IFlowBlockFieldRules.HasAnyValue || field.rule === IFlowBlockFieldRules.Unknown)),
         ),
      );
      const aValidKeyword = blockState.some(
         (block) =>
            block.fields.some((field) => field.keywords && field.keywords?.length > 0) ||
            block.fields.some(
               (field) =>
                  field.rule === IFlowBlockFieldRules.HasAnyValue || field.rule === IFlowBlockFieldRules.Unknown,
            ),
      );

      if (isInvalid || !aValidKeyword) {
         setIsBtnDisable(true);
      }
   }, [blocks]);

   return (
      <Grid container className={classes.container} wrap='nowrap'>
         <Grid className={classes.newMessageReceived}>
            <Grid className={classes.header}>
               <div>
                  {blocks[0]?.channelType === FlowInstanceMessageChannel.LiveChat ? (
                     <div>
                        <LiveChatIcon selected={true} className={classes.icon} />
                     </div>
                  ) : blocks[0]?.channelType === FlowInstanceMessageChannel.Facebook ? (
                     <FacebookMessengerIcon selected={true} className={classes.icon} />
                  ) : blocks[0]?.channelType === FlowInstanceMessageChannel.Instagram ? (
                     <InstagramIcon selected={true} className={classes.icon} />
                  ) : blocks[0]?.channelType === FlowInstanceMessageChannel.Telegram ? (
                     <div
                        style={{
                           width: 30,
                           height: 30,
                           backgroundColor: '#25A3E1',
                           borderRadius: '45%',
                        }}
                     >
                        <TelegramIcon className={classes.icon} style={{ color: '#fff', margin: '2px 2px 0 0' }} />
                     </div>
                  ) : blocks[0]?.channelType === FlowInstanceMessageChannel.WhatsappBusiness ? (
                     <WhatsAppBusiness selected={true} className={classes.icon} />
                  ) : blocks[0]?.channelType === FlowInstanceMessageChannel.WhatsappWeb ? (
                     <WhatsAppIcon selected={true} className={classes.icon} />
                  ) : (
                     ''
                  )}
               </div>

               <span style={{ fontWeight: 'bold', marginTop: '5px' }}>
                  {t.atomation_flow_trigger_modal_user_send_message}
               </span>
            </Grid>

            <Grid style={{ fontSize: '0.9rem' }}>
               <div className={classes.titleKey}>{t.atomation_flow_message_with_keyword}</div>
               {multipleTagBlocks.map((item) => (
                  <Grid key={item._id} className={classes.content}>
                     {item.fields.map((field) => (
                        <Grid>
                           {field.type === IFlowBlockFieldTypes.keywordsMessageReceived && (
                              <Grid>
                                 <Grid style={{ display: 'flex' }} onClick={(event) => handleMenuOpen(event, item._id)}>
                                    <Grid style={{ display: 'flex', gap: '3px' }}>
                                       <Grid>{t.atomation_flow_message}</Grid>
                                       <Grid>
                                          {field.rule === IFlowBlockFieldRules.Contains
                                             ? t.atomation_flow_trigger_block_contains
                                             : field.rule === IFlowBlockFieldRules.Equal
                                             ? t.atomation_flow_trigger_block_equal
                                             : field.rule === IFlowBlockFieldRules.NotContains
                                             ? t.atomation_flow_trigger_block_not_contains
                                             : field.rule === IFlowBlockFieldRules.Unknown
                                             ? t.atomation_flow_trigger_block_unknown
                                             : field.rule === IFlowBlockFieldRules.HasAnyValue
                                             ? t.atomation_flow_trigger_block_has_any_value
                                             : field.rule === IFlowBlockFieldRules.StartWith
                                             ? t.atomation_flow_trigger_block_start_with
                                             : field.rule === IFlowBlockFieldRules.EndWith
                                             ? t.atomation_flow_trigger_block_end_with
                                             : t.atomation_flow_trigger_block_not_equal}
                                          :
                                       </Grid>
                                    </Grid>
                                    <div>
                                       {item._id === seletedFlowBlockId && showUD ? (
                                          <KeyboardArrowUpIcon style={{ paddingBottom: '7px' }} />
                                       ) : (
                                          <KeyboardArrowDownIcon style={{ paddingBottom: '7px' }} />
                                       )}
                                    </div>
                                 </Grid>
                                 <Grid
                                    style={{ display: 'flex', position: 'relative' }}
                                    onMouseEnter={() => handleSetIsHover(item._id)}
                                    onMouseLeave={() => setIsHover(false)}
                                 >
                                    <Grid className={classes.blockTags}>
                                       {!(
                                          field.rule === IFlowBlockFieldRules.HasAnyValue ||
                                          field.rule === IFlowBlockFieldRules.Unknown
                                       ) ? (
                                          <TagsInput
                                             value={field.keywords || []}
                                             onChange={(tags) => handleBlockInputChange(item._id, tags)}
                                             name='tag'
                                             beforeAddValidate={(newTag) => validateTagLength(newTag, field.keywords)}
                                             placeHolder={
                                                field.keywords && field.keywords.length > 0
                                                   ? ''
                                                   : t.atomation_flow_trigger_block_keyword
                                             }
                                             onBlur={(e: { target: { value: string } }) => {
                                                const value = e.target.value;
                                                if (!(field.keywords || []).includes(value) && value !== '') {
                                                   handleBlockInputChange(item._id, [...(field.keywords || []), value]);
                                                   e.target.value = '';
                                                }
                                             }}
                                          />
                                       ) : (
                                          <div style={{ height: '37px' }}>
                                             <div
                                                style={{
                                                   height: '73%',
                                                   margin: '5px 0',
                                                   backgroundColor: '#f7f7fa',
                                                   borderRadius: 5,
                                                }}
                                             ></div>
                                          </div>
                                       )}
                                       <Grid
                                          style={{
                                             display: 'flex',
                                             justifyContent: 'flex-end',
                                             marginTop: '5px',
                                          }}
                                       >
                                          <Grid className={classes.handle}></Grid>
                                       </Grid>
                                    </Grid>
                                    {isHover && item._id === hoverId && isDeletable && (
                                       <Grid
                                          style={{ position: 'absolute', top: '15%', right: -25 }}
                                          onClick={() => handleBlockDelete(item._id)}
                                       >
                                          <DeleteIcon className={classes.deleteIcon} />
                                       </Grid>
                                    )}
                                 </Grid>
                              </Grid>
                           )}
                        </Grid>
                     ))}
                  </Grid>
               ))}

               {blocks.length < 10 && (
                  <Grid style={{ fontSize: '0.9rem', padding: '5px 10px' }}>
                     <Button onClick={() => handleCreateBlock('Contains')} className={classes.btnDashed}>
                        {t.atomation_flow_trigger_block_add_keyword}
                     </Button>
                  </Grid>
               )}
            </Grid>
         </Grid>
         <SingleInput
            name='senders'
            type='senders'
            label={t['automation_account']}
            placeholder={<div>{t.atomation_flow_select}</div>}
            isSelect
            isMulti
            isClearable
            autoHeight
            control={control}
            maxMenuHeight={190}
            selectOptions={sendersData?.map(({ name, _id, type, firstName }) => ({
               value: _id,
               label: type === 'LIVE_CHAT' ? 'Live Chat' : type === 'TELEGRAM' ? firstName : name,
            }))}
            value={watch('senders')}
            onChange={(sender: { label: string; value: string }) => {
               handleSenderChange(sender);
            }}
         />
         <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{ marginTop: 40 }}
            PaperProps={{
               style: {
                  width: '302px',
                  borderRadius: '5px',
               },
            }}
         >
            <MenuItem onClick={() => handleTagsOptionChange(seletedFlowBlockId, IFlowBlockFieldRules.Equal)}>
               {t.atomation_flow_trigger_block_equal}
            </MenuItem>
            <MenuItem onClick={() => handleTagsOptionChange(seletedFlowBlockId, IFlowBlockFieldRules.Contains)}>
               {t.atomation_flow_trigger_block_contains}
            </MenuItem>
            <MenuItem onClick={() => handleTagsOptionChange(seletedFlowBlockId, IFlowBlockFieldRules.NotEqual)}>
               {t.atomation_flow_trigger_block_not_equal}
            </MenuItem>
            <MenuItem onClick={() => handleTagsOptionChange(seletedFlowBlockId, IFlowBlockFieldRules.NotContains)}>
               {t.atomation_flow_trigger_block_not_contains}
            </MenuItem>
            <MenuItem onClick={() => handleTagsOptionChange(seletedFlowBlockId, IFlowBlockFieldRules.Unknown)}>
               {t.atomation_flow_trigger_block_unknown}
            </MenuItem>
            <MenuItem onClick={() => handleTagsOptionChange(seletedFlowBlockId, IFlowBlockFieldRules.HasAnyValue)}>
               {t.atomation_flow_trigger_block_has_any_value}
            </MenuItem>
            <MenuItem onClick={() => handleTagsOptionChange(seletedFlowBlockId, IFlowBlockFieldRules.EndWith)}>
               {t.atomation_flow_trigger_block_end_with}
            </MenuItem>
            <MenuItem onClick={() => handleTagsOptionChange(seletedFlowBlockId, IFlowBlockFieldRules.StartWith)}>
               {t.atomation_flow_trigger_block_start_with}
            </MenuItem>
         </Menu>
      </Grid>
   );
};

export default UserSendMessage;

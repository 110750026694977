import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import React, { useContext, useState } from 'react';
import { Node } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@components/Modal/modal';
import CreateModal from '../../components/createModal';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CREATE_FLOW_BOT_MUTATION, CREATE_FLOW_NODE_MUTATION } from '@queries/Automation/mutation';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlowBotDataItem, NodeTemplates } from '@modules/Automation/FlowBots/types';
import { GET_LIST_NODE_TEMPLATES_QUERY } from '@queries/Automation/query';
import { SET_BOT_STATUS } from '@store/actions/automation';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme: any) => ({
   header: {
      borderBottom: '1px solid #D7D8D8',
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '1.1rem',
      padding: '15px 20px 20px 20px',
      height: '65px',
   },
}));

interface NavProps {
   selectedItem: FlowBotDataItem | undefined;
   setSelectedItem: React.Dispatch<React.SetStateAction<FlowBotDataItem | undefined>>;
   setFlowBotData: React.Dispatch<React.SetStateAction<FlowBotDataItem[]>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   clickedNodeId: string;
   setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
}

const Nav: React.FC<NavProps> = ({
   selectedItem,
   setSelectedItem,
   setFlowBotData,
   setContentNodes,
   clickedNodeId,
   setClickedHandleId,
   setClickedNodeId,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [showModal, setShowModal] = useState(false);
   const history = useHistory();
   const dispatch = useDispatch();

   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);

   const closeModal = () => {
      setShowModal(false);
   };

   const handleOpenModal = () => {
      setShowModal(true);
   };

   const [createFlowNode] = useMutation(CREATE_FLOW_NODE_MUTATION);
   const handleCreateNode = async (flowBotId: string, nodeTemplate: NodeTemplates[]) => {
      try {
         setContentNodes([]);
         setClickedNodeId('');
         const templateId = nodeTemplate && nodeTemplate.find((item) => item.type === 'trigger');
         const position = { x: 1, y: 1 };

         await createFlowNode({
            variables: {
               input: {
                  coordinates: position,
                  customer: person.customer._id,
                  flowBot: flowBotId,
                  templateId: templateId?._id,
                  title: t.atomation_flow_trigger_block_trigger,
               },
            },
            onCompleted: (res) => {
               history.push(`/automation/flow-builder/${flowBotId}`);
            },
         });
      } catch (error) {
         console.error('Error creating node:', error);
      }
   };

   const [getNodesTemplate] = useLazyQuery(GET_LIST_NODE_TEMPLATES_QUERY);
   const [loading, setLoading] = useState(false);

   // Create mutation
   const [createFlowBot] = useMutation(CREATE_FLOW_BOT_MUTATION);
   const handleSubmit = async (newtitle: String) => {
      setLoading(true);
      try {
         await createFlowBot({
            variables: {
               input: {
                  customer: person.customer._id,
                  title: newtitle,
               },
            },
            onCompleted: async (res) => {
               const createdBotId = res.createFlowBot.data._id;
               dispatch({
                  type: SET_BOT_STATUS,
                  payload: res.createFlowBot.data.status === true ? 'published' : 'draft',
               });
               setLoading(false);

               // Define an async function to fetch node templates
               const fetchNodeTemplates = async () => {
                  const response = await getNodesTemplate({
                     variables: {},
                     fetchPolicy: 'network-only',
                  });

                  if (response.data) {
                     return response.data.listNodeTemplates.data;
                  }

                  return null;
               };

               const nodeTemplate = await fetchNodeTemplates();

               setShowModal(false);
               setSelectedItem(res.createFlowBot.data);
               handleCreateNode(createdBotId, nodeTemplate);
            },
         });
      } catch (error) {
         console.error('Error creating flow bot:', error);
      }
   };

   return (
      <div>
         <div className={classes.header}>
            <div style={{ marginTop: '5px' }}>{t?.automation_flow_flow_bot}</div>
            <ButtonOK
               label={t?.automation_flow_create_new_plus}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
               }}
               onClick={handleOpenModal}
            />
         </div>

         <Modal open={showModal} onClose={closeModal} modalHeader={t.automation_flow_create_new_flow}>
            <CreateModal isLoading={loading} onSubmit={handleSubmit} closeModal={closeModal} isCreate={true} />
         </Modal>
      </div>
   );
};

export default Nav;

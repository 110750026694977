import { useQuery } from '@apollo/client';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useContext, useState } from 'react';
import {
   DashboardCommentsDoneIcon,
   DashboardCommentsOpenIcon,
   DashboardCommentsSpamIcon,
   DashboardCommentsTimeoutIcon,
   DashboardCommentsWaitingIcon,
} from '@assets/icons/DashboardIcons/dashboardIcons';
import { LanguageContext } from '@helper/locale/langContext';
import { DATE_RANGES } from '../../../types/enums';
import {
   GET_DASHBOARDS_COMMENTS_PLATFORMS_STATISTICS_QUERY,
   GET_DASHBOARDS_COMMENTS_STATISTICS_QUERY,
} from '../../../queries/Dashboard/Service/GraphQL/query';
import SmallChip from '../SmallChipsSection/smallChip';
import HintSection from './hintSection';
import LeftSection from './leftSection/leftSection';
import RightSection from './rightSection/rightSection';

const useStyles = makeStyles((theme) => ({
   root: {
      //   padding: `${theme.spacing(1)}px 0px`,
   },
   chipsContainer: {
      padding: '16px 16px 0 16px !important',
   },
   topSectionRoot: {
      [theme.breakpoints.down('sm')]: {
         width: '100%',
         marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
         width: '24%',
         marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
         width: '24%',
         marginBottom: theme.spacing(2),
      },
   },
   marginAuto: {
      margin: 'auto',
   },
   true: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 300,
      alignSelf: 'left',
   },
   item: {
      width: '47%',
      marginLeft: 20,
   },
   icon: {
      width: 36,
      height: 36,
      marginBottom: 20,
   },
}));

const CommentDashboard = ({ values }) => {
   const classes = useStyles();
   const isSpecResolution = useMediaQuery('@media(max-width:1120px) and (min-width:750px)');
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   const [rightSectionDateValue, setRightSectionDateValue] = useState({
      label: t?.page_dashboard_typo_today,
      value: DATE_RANGES.TODAY,
   });
   const { data: dashboardsStatistics, loading: dashboardLoading } = useQuery(
      GET_DASHBOARDS_COMMENTS_STATISTICS_QUERY,
      {
         fetchPolicy: 'network-only',
      },
   );

   const { data: dashboardPlatformStatistics, loading: getPlatformStatisticsLoading } = useQuery(
      GET_DASHBOARDS_COMMENTS_PLATFORMS_STATISTICS_QUERY,
      {
         fetchPolicy: 'network-only',
         variables: {
            date: rightSectionDateValue.value,
         },
      },
   );

   const data = dashboardsStatistics?.QnAChatStatistics.data;
   const rightData = dashboardPlatformStatistics?.getQnAStatistics?.data;

   const onChangeDate = (date) => {
      setRightSectionDateValue(date);
   };

   const ITEMS = [
      {
         title: t['page_dashboard_typo_waiting'],
         count: data?.waitingQnAChatCount,
         icon: <DashboardCommentsWaitingIcon className={classes.icon} />,
      },
      {
         title: t['page_dashboard_typo_active'],
         count: data?.activeQnAChatCount,
         icon: <DashboardCommentsOpenIcon className={classes.icon} />,
      },
      {
         title: t['page_dashboard_typo_closed'],
         count: data?.solvedQnAChatCount,
         icon: <DashboardCommentsDoneIcon className={classes.icon} />,
      },
      {
         title: t['page_dashboard_typo_archive'],
         count: data?.timeoutQnAChatCount,
         icon: <DashboardCommentsTimeoutIcon className={classes.icon} />,
      },
      // {
      //    title: t['page_chat_typo_filter_title_spam_blocked'],
      //    count: data?.spammedQnAChatCount,
      //    icon: <DashboardCommentsSpamIcon className={classes.icon} />,
      // },
   ];

   return (
      <Grid container spacing={4} direction={'column'} className={classes.root}>
         <Grid item xs={12} container justifyContent={'space-between'} className={classes.chipsContainer}>
            {ITEMS.map((item) => (
               <Grid key={item.title} item className={clsx(classes.topSectionRoot, isSpecResolution && classes.item)}>
                  <SmallChip {...item} />
               </Grid>
            ))}
         </Grid>
         <Grid container spacing={2} item xs={12} className={classes.marginAuto}>
            {/* Left Section */}
            <Grid item container xs={12} md={6}>
               <LeftSection loading={dashboardLoading} data={data} />
            </Grid>
            {/* Right Section */}
            <Grid item container xs={12} md={6}>
               <RightSection
                  loading={getPlatformStatisticsLoading}
                  value={rightSectionDateValue}
                  onChangeDate={onChangeDate}
                  data={rightData}
               />
            </Grid>

            <Grid item container xs={12}>
               <HintSection />
            </Grid>
         </Grid>
      </Grid>
   );
};

export default CommentDashboard;

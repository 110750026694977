import { gql } from '@apollo/client';

export const GET_INCOMING_MESSAGES_STATISTICS_QUERY = gql`
   query getStatisticsIncomingMessagesQuery($customer: ID!, $startDate: Date!, $endDate: Date!, $platform: Double!) {
      getCustomerStatistics(customer: $customer, startDate: $startDate, endDate: $endDate, platform: $platform) {
         data {
            totalReceived
            totalSent
            chartData {
               title
               received
               sent
            }
         }
         success
         code
         message
      }
   }
`;
export const EXPORT_STATISTICS_QUERY = gql`
   query ExportToExcel($customer: ID!, $operation: String, $params: Object, $email: String) {
      exportToExcel(customer: $customer, operation: $operation, params: $params, email: $email) {
         data
         success
      }
   }
`;

export const GET_TAGS_STATISTICS_QUERY = gql`
   query getTagsStatisticsQuery($customer: ID!, $startDate: Date!, $endDate: Date!, $platform: Double!) {
      getCustomerTagStatistics(customer: $customer, startDate: $startDate, endDate: $endDate, platform: $platform) {
         data {
            totalChats
            totalTaggedChats
            totalRemovedTagsChats
            tagsData {
               title
               _id
               color
               count
            }
         }
         success
         code
         message
      }
   }
`;

export const GET_PERSONS_STATISTICS_QUERY = gql`
   query getPersonStatisticsQuery($customer: ID!, $startDate: Date!, $endDate: Date!, $platform: Double!) {
      getPersonsStatistics(customer: $customer, startDate: $startDate, endDate: $endDate, platform: $platform) {
         data {
            personsCount
            activeChatsCount
            sentMessagesCount
            closedChatsCount
            spammedChatsCount
            solvedChatsCount
            totalResolutionTime
            totalFirstResponseTime
            totalFirstResponseCount
            personsReport {
               _id
               email
               photo
               title
               spammedChatCount
               joinedChatCount
               sentMessageCount
               closedChatCount
               solvedChatCount
               resolutionTime
               firstResponseTime
               firstResponseCount
            }
         }
         success
         code
         message
      }
   }
`;

export const GET_CHATS_STATISTICS_QUERY = gql`
   query getChatsStatisticsQuery($customer: ID!, $startDate: Date!, $endDate: Date!, $platform: Double!) {
      getChatsStatistics(customer: $customer, startDate: $startDate, endDate: $endDate, platform: $platform) {
         data {
            activeChatsCount
            waitingChatsCount
            closedChatsCount
            solvedChatsCount
            spammedChatsCount
            chartReport {
               title
               activeChatsCount
               waitingChatsCount
               closedChatsCount
               solvedChatsCount
               spammedChatsCount
               totalChatsCount
            }
         }
         success
         code
         message
      }
   }
`;

export const GET_CUSTOMER_SATISFACTION_STATISTICS_QUERY = gql`
   query getCustomerSatisfactionStatisticsQuery( $customer: ID!, $startDate: Date!, $endDate: Date!, $surveyId: String, $platform: Double) {
      getSurveyHistoryStatistic(input: { customer: $customer, startDate: $startDate, endDate: $endDate, surveyId: $surveyId, platform: $platform }) {
         data {
            totalResponded
            totalSent
            totalSatisfied
            totalDissatisfied
            totalNeutral
            chartData {
               title
               responded
               sent
               satisfied
               dissatisfied
               neutral
            }
         }
         success
         code
         message
      }
   }
`;

export const GET_ABANDONED_CART_STATISTICS_QUERY = gql`
   query getEcommerceRulesHistoryStatistic( $customer: ID!, $startDate: Date!, $endDate: Date!) {
      getEcommerceRulesHistoryStatistic(input: { customer: $customer, startDate: $startDate, endDate: $endDate }) {
         data {
           totalAbandonedCart
           totalRecoveredCart
           totalRecoveredValue
           totalRecoveryRate
           totalCost
           chartData {
             title
             abandonedCart
             recoveredCart
             recoveredValue
             recoveryRate
             cartValue
             cartCurrency
             cost
             costCurrency
             cartStatus
           }
         }
         success
         code
         message
      }
   }
`;
import { TelegramIcon } from "@assets/icons/SettingsIcons/settingsIcons";
import WhatsappIcon from "@components/SocialMediaIcon/whatsappIcon";
import { LanguageContext } from '@helper/locale/langContext';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { SenderType } from "../types";

const useStyles = makeStyles((theme: any) => ({
    container: {
        backgroundColor: '#fff',
        // padding: 10,
        // marginTop: 60,
    },
    customPaper: {
        backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
        height: 50,
        width: '100%',
        marginBottom: 10,
        paddingLeft: 16,
        paddingRight: 10,
    },
    menuItem: {
        margin: 0,
        marginTop: 5,
        marginBottom: 5,
        padding: 0,
    },
    selector: {
        textAlign: 'left',
        border: 'solid 1px lightgrey',
        borderRadius: 5,
        paddingLeft: 3,
        height: 32,
    },
    item: {
        display: 'inline-block',
        width: 7,
        height: 30,
        backgroundColor: 'white',
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        marginRight: '10px',
    },
}));


export interface TabBarProps {
    isBusy: boolean;
    selectedTab: SenderType,
    onTabClick: (tab: SenderType) => void,
}

interface TabProps {
    isBusy: boolean;
    type: SenderType;
    isSelected: boolean;
    label: string;
    icon: JSX.Element;
    onClick: (arg0: SenderType) => void;
}

const Tab = ({ isBusy, type, isSelected, label, icon, onClick }: TabProps) => {

    return <Grid item xs={6}>
        <Button
            fullWidth
            disabled={isBusy}
            variant='outlined'
            style={{
                marginRight: 5,
                minWidth: 140,
                borderWidth: 2,
                borderColor: isSelected ? '#0080fe' : '#D1D1D1',
            }}
            startIcon={icon}
            onClick={() => onClick(type)}
        >
            {label}
        </Button>
    </Grid>

}

const TabBar = ({ isBusy, selectedTab, onTabClick }: TabBarProps) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;

    return (
        <Grid container alignItems='center' className={classes.customPaper}>
            <Grid alignItems='center' justifyContent='space-between' direction='row' spacing={2} container>
                <Tab isBusy={isBusy} type={SenderType.WhatsappGroups} isSelected={selectedTab === SenderType.WhatsappGroups || selectedTab === SenderType.PersonGroups} onClick={onTabClick} label={"Whatsapp"} icon={<WhatsappIcon style={{
                    width: 25,
                    height: 25,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3,
                }} className={''} selected={true} color={'#25d366'} />} />
                <Tab isBusy={isBusy} type={SenderType.TelegramChats} isSelected={selectedTab === SenderType.TelegramChats} onClick={onTabClick} label={"Telegram"} icon={<TelegramIcon style={{
                    width: 25,
                    height: 25,
                    display: 'block',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    marginBottom: 3,
                }} className={''} selected={true} />} />
            </Grid>
        </Grid>
    );
};

export default TabBar;

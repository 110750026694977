import React from 'react';
import { Grid, Slide, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
type IconSize = 'medium' | 'small';
interface IProps {
   icon: React.ReactNode;
   title: string;
   iconSize?: IconSize;
}
const useStyles = makeStyles((theme: any) => ({
   root: {
      color: theme.chatApp.workspace.navBar.titleColor,
   },
   title: {
      color: theme.chatApp.workspace.navBar.titleColor,
      marginLeft: theme.spacing(1),
   },
}));
const BaseNav = (props: IProps) => {
   const { icon, title } = props;
   const classes = useStyles(props);
   return (
      <Slide in direction='right'>
         <Grid container alignItems='center' className={classes.root}>
            {/* {showBackIcon && isSmallScreen && (
               <IconButton>
                  <ArrowBackIcon fontSize='large' />
               </IconButton>
            )} */}
            {icon}
            <Typography component='h1' variant='h6' noWrap className={classes.title}>
               {title}
            </Typography>
         </Grid>
      </Slide>
   );
};

export default BaseNav;

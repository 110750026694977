import React, { useContext, useState, useEffect } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import messengerIcon from './messenger.png';
import ButtonOK from '@components/ButtonOK/buttonOk';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import Input from '@components/InputField';
import { useForm } from 'react-hook-form';
import ColorizeIcon from '@material-ui/icons/Colorize';
import { ChromePicker } from 'react-color';
import { PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/Platform/types';
import { CREATE_TEAM_MUTATION, UPDATE_TEAM_MUTATION } from '@queries/Teams/mutation';
import { useMutation, useQuery } from '@apollo/client';
import { get } from 'underscore';
import { PERSON_TYPE } from '../../../../types/enums';
import { useSnackbar } from 'notistack';
import InputLabel from '@material-ui/core/InputLabel';
import { QnAPlatformType } from '@modules/Questions/util/types';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';

const platformTypeHandler = (type) => {
   switch (type) {
      case PLATFORM_TYPE.WHATS_APP_WEB:
         return 'Whatsapp Web';
      case PLATFORM_TYPE.INSTAGRAM:
         return 'Instagram';
      case PLATFORM_TYPE.WHATS_APP_BUSINESS:
         return 'Whatsapp Business';
      case PLATFORM_TYPE.FACEBOOK:
         return 'Facebook';
      case PLATFORM_TYPE.LIVE_CHAT:
         return 'Live Chat';
      case QnAPlatformType.HEPSIBURADA:
         return 'Hepsiburada';
      case QnAPlatformType.TRENDYOL:
         return 'Trendyol';
      case QnAPlatformType.N11:
         return 'n11';
      case PLATFORM_TYPE.EMAIL:
         return 'Email';
      default:
         return type;
   }
};

const useStyles = makeStyles((theme) => ({
   root: {
      width: 700,
      padding: 20,
      paddingBottom: 5,
      borderRadius: 15,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   stepContainer: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      borderRadius: 10,
   },
   modalTitle: {
      textAlign: 'center',
      fontSize: '1.4rem',
      color: '#4681C3',
      marginBottom: 15,
      fontWeight: 'bold',
   },
   stepLabel: {
      fontSize: '1.3rem',
   },
   contentTitle: {
      fontSize: '1.2rem',
      color: '#326290',
   },
   subtitle: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginTop: 10,
   },
   informationListItem: {
      fontSize: '1.1rem',
   },
   informationListContainer: {
      marginTop: 30,
   },
   resultContainer: {
      width: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      padding: 5,
      borderRadius: 5,
   },
   statusTitle: {
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: 'bold',
   },
   informationTitle: {
      marginTop: 20,
   },
   colorInputContainer: {
      width: 100 + '%',
      height: 45,
      border: '1px solid #CCC',
      borderRadius: 4,
      marginTop: '1rem',
      marginBottom: '1rem',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
   },
   ColorContainer: {
      width: 30,
      height: 30,
      marginLeft: 4,
      marginRight: 4,
      borderRadius: 6,
      '-webkit-box-shadow': 'inset 0px 0px 0px 2px #FFFFFF',
      boxShadow: 'inset 0px 0px 0px 2px #FFFFFF',
      cursor: 'pointer',
   },
   selectedColorContainer: {
      width: 50,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 15,
      borderRight: '1px solid #CCC',
   },
   popover: {
      // this is not essential, it's some styling for the picker
      position: 'absolute',
      zIndex: '2',
   },
   cover: {
      // same as for popover
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
   },
   inputLabel: {
      color: '#212121',
      padding: 0,
      fontSize: '18px',
      marginBottom: '-18px',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

// const getSteps = (t) => {
//    return [t?.page_settings_modal_whatsapp_modal_step_information];
// };

const getInformationList = (t) => {
   return [
      t?.page_settings_typo_platforms_facebook_information_list_login,
      t?.page_settings_typo_platforms_facebook_information_list_chose_account,
      t?.page_settings_typo_platforms_facebook_information_list_check,
      t?.page_settings_typo_platforms_facebook_information_list_consumer,
   ];
};
const ModalContent = ({
   show,
   onClose,
   editData,
   mode,
   customerPersons = [],
   allPlatforms = [],
   me,
}) => {
   const defaultPerson = customerPersons.find((p) => p.type === PERSON_TYPE.OWNER);
   const {
      control,
      watch,
      getValues,
      setValue,
      formState: { errors, isDirty },
   } = useForm({
      mode: 'all',
      defaultValues: {
         users:
            mode === 'EDIT'
               ? editData?.team?.customerPersons?.map((person) => ({
                    label: person.name,
                    value: person._id,
                    isFixed: person.type === PERSON_TYPE.OWNER,
                 }))
               : [
                    {
                       label: defaultPerson?.name,
                       value: defaultPerson?._id,
                       isFixed: true,
                    },
                 ],
         channels:
            mode === 'EDIT'
               ? editData?.team?.drivers.map((driver) => {
                    const findDriver = allPlatforms.find((e) => e._id == driver);
                    //   return { label: 'asdasd', value: 'c' };
                    if (findDriver)
                       return {
                          label:
                             findDriver.type !== PLATFORM_TYPE.LIVE_CHAT
                                ? `${platformTypeHandler(findDriver.type)} - (${
                                     findDriver.name || findDriver.storeName
                                  })`
                                : `${platformTypeHandler(findDriver.type)}`,
                          value: findDriver._id,
                          type: findDriver.type,
                       };
                 })
               : [],
         name: mode === 'EDIT' ? editData.team.teamName : '',
         color: mode === 'EDIT' ? editData.team.color : '',
      },
   });

   const watchChannels = watch('channels');
   const watchUsers = watch('users');
   const watchteamName = watch('name');
   const watchColor = watch('color');

   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

   useEffect(() => {
      // console.log('watchChannels', watchChannels);
      // setTimeout can be added depending on the situation
      // const timer = setTimeout(() => {
      // setFilteredProducts([
      //    ...filteredProductsByType.filter(product => product.name.toLowerCase().includes(watchSearchProduct.toLowerCase()))
      // ]);
      // }, 200);
      // return () => clearTimeout(timer);
   }, [watchChannels]);
   const [createPlatformMutation, { loading: createPlatformMutationLoading }] =
      useMutation(CREATE_TEAM_MUTATION);
   const [updatePlatformMutation, { loading: updatePlatformMutationLoading }] =
      useMutation(UPDATE_TEAM_MUTATION);
   const [showColorPicker, setShowColorPicker] = useState(false);
   const [selectedColor, setSelectedColor] = React.useState(
      mode == 'EDIT' ? editData.team.color : 'gray',
   );
   const classes = useStyles();
   // const [activeStep, setActiveStep] = useState(0);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   // const steps = getSteps(t);
   // const incrementStep = () => {
   //    setActiveStep((prevStep) => prevStep + 1);
   // };

   // const handleTryAgainButton = () => {
   //    setActiveStep(0);
   //    dispatch({ type: UPDATE_STATUS_FACEBOOK_ACCOUNT, payload: {} });
   // };

   // const checkisOtherPlatformExist = (type) => {
   //    return watchChannels.some((e) => e?.type == type);
   // };

   const onChangeUsers = (value, actionMeta) => {
      let appropriate = true;
      let newValues = value;

      switch (actionMeta.action) {
         case 'remove-value':
         case 'pop-value':
            if (actionMeta.removedValue?.isFixed) {
               appropriate = false;
               return;
            }
            break;
         case 'clear':
            // newValues = selectedLanguages.filter((v) => v?.isFixed);
            newValues = [
               {
                  label: defaultPerson?.name,
                  value: defaultPerson?._id,
                  isFixed: true,
               },
            ];
            break;
      }
      if (appropriate) {
         // newValues = orderOptions(newValues);
         setValue('users', newValues, { shouldDirty: true });
      }
   };

   const Color = ({ color, isSelected }) => {
      const selected = isSelected ? isSelected : selectedColor == color;
      return (
         <Grid
            onClick={() => setSelectedColor(color)}
            className={classes.ColorContainer}
            style={{ background: color, border: selected ? '1px solid #4681C3' : 'none' }}
         ></Grid>
      );
   };

   const createPlatformhandler = () => {
      if (mode == 'EDIT') {
         updatePlatformMutation({
            variables: {
               _id: editData.id,
               customer: me.customer._id,
               teamName: watchteamName,
               drivers: watchChannels?.map((e) => e?.value) || [],
               customerPersons: watchUsers?.map((e) => e?.value) || [],
               color: selectedColor,
            },
         })
            .then((e) => {
               enqueueSnackbar(t.general_information_updated_successfully, {
                  variant: 'success',
               });
               onClose(true);
            })
            .catch((error) => {
               enqueueSnackbar(t[error.message] || error.message, {
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                        {t['comp_button_close']}
                     </div>
                  ),
               });
               onClose();
            });
      } else {
         createPlatformMutation({
            variables: {
               customer: me.customer._id,
               teamName: watchteamName,
               drivers: watchChannels.map((e) => e?.value),
               customerPersons: watchUsers.map((e) => e?.value),
               color: selectedColor,
            },
         })
            .then((e) => {
               enqueueSnackbar(t.general_information_added_successfully, {
                  variant: 'success',
               });
               onClose(true);
            })
            .catch((error) => {
               enqueueSnackbar(t[error.message] || error.message, {
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                        {t['comp_button_close']}
                     </div>
                  ),
               });
               onClose();
            });
      }
   };

   return (
      <Grid>
         <SingleInput
            name='name'
            label={t?.team_name}
            control={control}
            editable
            required={true} //Disable html validation
            rules={{
               required: t?.please_fill_this_field,
            }}
            placeholder={t?.page_settings_typo_quick_ans_add_placeholder_title}
         />
         <Grid container xs={12}>
            <Typography className={classes.inputLabel}>{t?.team_color}</Typography>
            <Grid className={classes.colorInputContainer}>
               <Grid className={classes.selectedColorContainer}>
                  <Color color={selectedColor} isSelected={false} />
               </Grid>
               <Color color='gray' />
               <Color color='red' />
               <Color color='green' />
               <Color color='blue' />
               <Color color='purple' />
               <Color color='black' />
               <Color color='pink' />
               <ColorizeIcon
                  style={{ marginLeft: 10, cursor: 'pointer' }}
                  onClick={(e) => setShowColorPicker(true)}
               />
               <div style={{ zIndex: 9 }}>
                  {showColorPicker && (
                     <div className={classes.popover}>
                        <div
                           className={classes.cover}
                           onClick={() => setShowColorPicker(false)}
                           // onKeyDown={this.handleClick}
                           role='button'
                           tabIndex='0'
                           aria-label='Save'
                        />

                        <ChromePicker
                           color={selectedColor}
                           onChange={(color) => setSelectedColor(color.hex)}
                           onChangeComplete={(color) => setSelectedColor(color.hex)}
                        />
                     </div>
                  )}
               </div>
            </Grid>
         </Grid>
         <SingleInput
            isSelect
            isMulti
            selectOptions={customerPersons.map((person) => ({
               label: person.name,
               value: person._id,
            }))}
            autoHeight
            name='users'
            label={t?.team_users}
            control={control}
            value={getValues().users}
            onChange={onChangeUsers}
            editable
            required={false} //Disable html validation
            placeholder={t?.page_settings_typo_quick_ans_add_placeholder_title}
         />
         <SingleInput
            isSelect
            isMulti
            name='channels'
            label={t?.team_channels}
            control={control}
            autoHeight
            editable
            selectOptions={allPlatforms.map((e) => ({
               label: `${platformTypeHandler(e?.type)} ${
                  e?.type !== PLATFORM_TYPE.LIVE_CHAT
                     ? ' - (' + (e?.name || e?.storeName || e?.firstName || e?.senderName) + ')'
                     : ''
               }`,
               value: e?._id,
               // isDisabled: checkisOtherPlatformExist(e?.type),
               type: e?.type,
            }))}
            required={false} //Disable html validation
            placeholder={t?.page_settings_typo_quick_ans_add_placeholder_title}
         />
         <ModalButtonField>
            <ButtonOK
               className={classes.modalButtons}
               label={t?.comp_button_cancel}
               onClick={() => onClose(false)}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            />
            <ButtonOK
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
               label={mode == 'EDIT' ? t?.save : t?.create_team_button}
               disabled={
                  getValues().users.length == 0 ||
                  getValues().name.length == 0 ||
                  getValues().name.match(/^ *$/) !== null ||
                  !isDirty ||
                  createPlatformMutationLoading
               }
               onClick={() => createPlatformhandler()}
            />
         </ModalButtonField>
         {/* </Paper> */}
      </Grid>
   );
};

export default ModalContent;

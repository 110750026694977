import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useContext } from 'react';
import { XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, Bar, BarChart, CartesianGrid } from 'recharts';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import CustomTooltip from './tooltip';

const useStyles = makeStyles((theme) => ({
   height500: {
      height: 520,
      padding: 0,
   },
}));

const DAY_HOURS = [
   '00:00',
   '01:00',
   '02:00',
   '03:00',
   '04:00',
   '05:00',
   '06:00',
   '07:00',
   '08:00',
   '09:00',
   '10:00',
   '11:00',
   '12:00',
   '13:00',
   '14:00',
   '15:00',
   '16:00',
   '17:00',
   '18:00',
   '19:00',
   '20:00',
   '21:00',
   '22:00',
   '23:00',
];

const LineChart = ({ chartData, mode = 'HOUR' }) => {
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   const colors = {
      received: '#26BF8A',
      sent: '#157CFC',
   };
   // const maxReceived = chartData.map((data) => data.received).reduce((a, b) => (a > b ? a : b), 0);
   // const maxSent = chartData.map((data) => data.sent).reduce((a, b) => (a > b ? a : b), 0);
   // let maxVertical = maxReceived > maxSent ? maxReceived : maxSent;
   // maxVertical = Math.floor(maxVertical * 1.2);
   // const referenceLines = [
   //    maxVertical,
   //    parseInt((0.75 * maxVertical).toString(), 10),
   //    parseInt((0.5 * maxVertical).toString(), 10),
   //    parseInt((0.25 * maxVertical).toString(), 10),
   //    0,
   // ];
   const _chartData = chartData.map((item, i) => {
      const _item = { ...item };
      _item.title = mode === 'HOUR' ? DAY_HOURS[i] : moment(Number(item.title)).format('MM/DD');
      return _item;
   });

   const classes = useStyles();
   return (
      <Grid component='div' className={classes.height500}>
         <ResponsiveContainer>
            <BarChart
               barSize={35}
               margin={{
                  top: 5,
                  right: 45,
                  left: 5,
                  bottom: 10,
               }}
               data={_chartData}
            >
               <CartesianGrid stroke='#E3E9EF' strokeDasharray='3 3' />
               <XAxis dataKey='title' />
               <YAxis />
               {/* Custom Tooltip */}
               <Tooltip
                  content={<CustomTooltip />}
                  cursor={{
                     fill: '#eee',
                  }}
               />
               <Legend verticalAlign='top' align='right' iconType='circle' iconSize={20} height={80} />
               <Bar dataKey='received' name={t?.page_statistics_typo_received_messages} fill={colors.received} />
               <Bar dataKey='sent' name={t?.page_statistics_typo_sent_messages} fill={colors.sent} />
            </BarChart>
         </ResponsiveContainer>
      </Grid>
   );
};

export default LineChart;

import React, { useContext } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import useFreeTrialDate from '../../hooks/useFreeTrialDate';

const useStyles = makeStyles((theme) => ({
   root: {
      width: (props) => (props.code === 'tr' ? 600 : 300),
      border: '1px solid #eee',
      borderRadius: 8,
      marginBottom: 4,
      transition: theme.transitions.create('width'),
      marginRight: 5,
   },
   circularBox: {
      position: 'absolute',
      top: 5,
      left: 37,
   },
   box: {
      // padding: 10,
      borderRadius: 50,
      border: '1px solid' + theme.chatApp.general.pallet.passiveStructureAzure,
      width: 15,
      height: 30,
   },
   freeTrial: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
}));

const Circle = ({ circleColor, text }) => {
   return (
      <svg width='50' height='50'>
         <circle
            r='15'
            cx='25'
            cy='25'
            fill='transparent'
            stroke={circleColor}
            strokeWidth='0.1rem'
            strokeDashoffset='0'
         ></circle>
         <text color='black' x='50%' y='50%' dominantBaseline='central' textAnchor='middle' fontSize={'0.9rem'}>
            {text}
         </text>
      </svg>
   );
};

const FreeTrial = () => {
   const {
      lang: { translation: t, code },
   } = useContext(LanguageContext);
   const classes = useStyles({ code });

   const customerFreeTrialDate = useSelector((state) => state.personReducer.person.customer.freeTrialExpiringDate);
   const { isHours, difference, isEnd } = useFreeTrialDate(customerFreeTrialDate);

   if (isEnd) {
      // if free trial ended
      return null;
   }

   return (
      <Grid className={classes.root} container direction='row' justifyContent='center' alignItems='center'>
         <Grid item xs={4} container alignItems='center'>
            <Typography noWrap className={classes.freeTrial}>
               {t?.free_trial}
            </Typography>
         </Grid>
         <Grid item container justifyContent='center' alignItems='center' xs={3}>
            <Circle text={Math.floor(difference)} circleColor={'#157CFC'} />
         </Grid>
         <Grid container alignItems='center' item xs={4}>
            <Typography color='primary'> {isHours ? t?.hours_left : t?.day_left}</Typography>
         </Grid>
      </Grid>
   );
};

export default FreeTrial;

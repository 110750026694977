import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import FlowBots from './FlowBots';
import { Node } from 'reactflow';
import FlowBuilder from './FlowBuilder';
import { FileBlockProps, FlowBlock, FlowBotDataItem, IFlowBlockTemplate, TempNode } from './types';
import RulesBuilder from '../E-commerceRules/RulesBuilder';
import RulesList from '../E-commerceRules/RulesList';
import RulesFlow from '../E-commerceRules/left';

interface RouteProps {
   contentNodes: Node[];
   leftNodes: Node[];
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   selectedItem: FlowBotDataItem | undefined;
   setSelectedItem: React.Dispatch<React.SetStateAction<FlowBotDataItem | undefined>>;
   flowBotData: FlowBotDataItem[];
   setFlowBotData: React.Dispatch<React.SetStateAction<FlowBotDataItem[]>>;
   nodes: Node[];
   setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   tempNodes: TempNode[];
   setTempNodes: React.Dispatch<React.SetStateAction<TempNode[]>>;
   flowBlock: FlowBlock[];
   setFlowBlock: React.Dispatch<React.SetStateAction<FlowBlock[]>>;
   blockState: IFlowBlockTemplate[];
   setBlockState: React.Dispatch<React.SetStateAction<IFlowBlockTemplate[]>>;
   clickedNodeId: string;
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   clickedHandleId: string;
   setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
   fileBlock: FileBlockProps[];
   setFileBlock: React.Dispatch<React.SetStateAction<FileBlockProps[]>>;
   isNewNode: string;
   setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
}

const Routes: React.FC<RouteProps> = ({
   leftNodes,
   contentNodes,
   setContentNodes,
   setLeftNodes,
   selectedItem,
   setSelectedItem,
   flowBotData,
   setFlowBotData,
   nodes,
   setNodes,
   tempNodes,
   setTempNodes,
   clickedHandleId,
   setClickedNodeId,
   clickedNodeId,
   setClickedHandleId,
   flowBlock,
   setFlowBlock,
   blockState,
   setBlockState,
   fileBlock,
   setFileBlock,
   setIsNewNode,
   isNewNode,
}) => {
   return (
      <div style={{ width: '100%', height: '100%', backgroundColor: '#FFFFFF' }}>
         <Switch>
            <Route path={'/automation/flow-bots'}>
               <FlowBots
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  setFlowBotData={setFlowBotData}
                  setContentNodes={setContentNodes}
                  clickedNodeId={clickedNodeId}
                  setClickedNodeId={setClickedNodeId}
                  setClickedHandleId={setClickedHandleId}
                  setIsNewNode={setIsNewNode}
               />
            </Route>
            <Route path={'/automation/flow-builder/:id'}>
               <FlowBuilder
                  leftNodes={leftNodes}
                  setLeftNodes={setLeftNodes}
                  contentNodes={contentNodes}
                  setContentNodes={setContentNodes}
                  nodes={nodes}
                  setNodes={setNodes}
                  selectedItem={selectedItem}
                  flowBotData={flowBotData}
                  tempNodes={tempNodes}
                  setTempNodes={setTempNodes}
                  clickedHandleId={clickedHandleId}
                  setClickedNodeId={setClickedNodeId}
                  clickedNodeId={clickedNodeId}
                  setClickedHandleId={setClickedHandleId}
                  flowBlock={flowBlock}
                  setFlowBlock={setFlowBlock}
                  blockState={blockState}
                  setBlockState={setBlockState}
                  fileBlock={fileBlock}
                  setFileBlock={setFileBlock}
                  setIsNewNode={setIsNewNode}
                  isNewNode={isNewNode}
               />
            </Route>

            <Route path={'/automation/e-commerce/:id'}>
               <RulesBuilder
                  leftNodes={leftNodes}
                  setLeftNodes={setLeftNodes}
                  selectedItem={selectedItem}
                  setClickedNodeId={setClickedNodeId}
                  clickedNodeId={clickedNodeId}
               />
            </Route>
            <Route path={'/automation/e-commerce'}>
               <RulesFlow />
            </Route>
            <Redirect to={'/automation/flow-bots'} />
         </Switch>
      </div>
   );
};

export default Routes;

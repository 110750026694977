import React, { useEffect, useContext, useState } from 'react';

import languageOptions from '../../../../../helper/languageOptions';
import { Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import Input from '../../../../../components/InputField';

const SelectLanguages = ({ setReset }) => {
   const { liveChatPreviewSettings, updateLiveChatPreviewSettings } = useContext(LiveChatSettingsContext);
   const { lang } = useContext(LanguageContext);
   const [selectedLanguages, setSelectedLanguages] = useState(liveChatPreviewSettings.selectedLanguages);
   const t = lang?.translation;

   const { control, watch, reset } = useForm({
      mode: 'all',
      defaultValues: {
         selectedLanguages: liveChatPreviewSettings.selectedLanguages,
      },
   });

   useEffect(() => {
      setReset(() => reset);
   }, [reset]);

   // const { selectedLanguages } = watch();

   useEffect(() => {
      // updateLiveChatPreviewSettings({
      //    selectedLanguages,
      // });
   }, [selectedLanguages]);
   const selectLanguageOptions = languageOptions?.map(({ value, englishName, nativeName }) => ({
      value: value,
      label: `${englishName} - ${nativeName}`,
      isFixed: value == 'tr' || value == 'en' ? true : false,
   }));

   const orderOptions = (values) => {
      return values.filter((v) => v?.isFixed).concat(values.filter((v) => !v?.isFixed));
   };

   const onChangeLanguage = (value, actionMeta) => {
      let appropriate = true;
      let newValues = value;

      switch (actionMeta.action) {
         case 'remove-value':
         case 'pop-value':
            if (actionMeta.removedValue?.isFixed) {
               appropriate = false;
               return;
            }
            break;
         case 'clear':
            // newValues = selectedLanguages.filter((v) => v?.isFixed);
            newValues = [
               {
                  label: 'English - English',
                  value: 'en',
                  isFixed: true,
               },
               {
                  isFixed: true,
                  label: 'Turkish - Türkçe',
                  value: 'tr',
               },
            ];
            break;
      }
      if (appropriate) {
         // newValues = orderOptions(newValues);
         setSelectedLanguages(newValues);
         updateLiveChatPreviewSettings({
            selectedLanguages: newValues,
         });
      }
   };

   return (
      <Grid item xs={12}>
         <Input
            autoHeight
            isSelect
            isMulti
            name='selectedLanguages'
            label={t['page_settings_typo_platforms_live_chat_languages_select_languages_label']}
            placeholder={t['page_settings_typo_platforms_live_chat_languages_select_languages_placeholder']}
            selectOptions={selectLanguageOptions}
            value={selectedLanguages}
            onChange={onChangeLanguage}
            // styles={styles}
            // isClearable={selectedLanguages.some((v) => !v.isFixed)}
            control={control}
            background={'white'}
         />
      </Grid>
   );
};

export default SelectLanguages;

import { LanguageContext } from '@helper/locale/langContext';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FileCopy } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const useStyles = makeStyles((theme) => ({}));

const InputDeleteRefresh = (props: any) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { value } = props;
   const { enqueueSnackbar } = useSnackbar();
   const [state, setState] = useState({
      value: value,
      copied: false,
   });

   const handleCopy = () => {
      setState({ ...state, copied: true });
      enqueueSnackbar(
         t[
            'page_settings_typo_platforms_live_chat_installation_section_live_chat_code_copy_to_clipboard_success'
         ],
         {
            // send success message
            variant: 'success',
            autoHideDuration: 3000,
         },
      );
   };

   return (
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
         <IconButton>
            <CopyToClipboard text={state.value} onCopy={handleCopy}>
               <FileCopy />
            </CopyToClipboard>
         </IconButton>
      </div>
   );
};

export default InputDeleteRefresh;

import React, { useEffect, useState, useContext } from 'react';
import { Handle, Position } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import SendIcon from '@mui/icons-material/Send';
import LinkIcon from '@mui/icons-material/Link';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles(() => ({
   container: {
      position: 'relative',
      display: 'inline-block',
   },
   triggerIcon: {
      width: 20,
      height: 20,
      margin: '0 5px 5px 5px',
   },
   customHeader: {
      display: 'flex',
      borderBottom: '0.1px solid #f2f2f2',
   },
   addText: {
      padding: '10px',
      margin: '5px',
      textAlign: 'center',
      borderRadius: 2,
      fontSize: 8,
      color: '#AFAFAF',
      backgroundColor: '#F7F7FA',
   },
   nodeContainer: {
      border: '1px solid #ffffff',
      width: '150px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'white',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   InvalidNodeContainer: {
      border: '1px solid #FF7474',
      width: '150px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'white',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   nodeContainerHovered: {
      border: '1px solid #157cfc',
   },
   footer: {
      borderTop: ' 0.1px solid #f2f2f2',
      height: '12px',
      position: 'relative',
   },

   handleEdgeConnected: {
      position: 'absolute',
      top: '8px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#ADADAD',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },

   blocks: {
      display: 'flex',
      flexDirection: 'column',
      padding: '2px 5px',
      fontSize: '9px',
   },

   subBlocks: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '6px',
      backgroundColor: '#F5F5F5',
      borderRadius: '5px',
   },
   iconBox: {
      padding: '2px 3px',
      margin: '0 5px 5px 5px',
      borderRadius: '3px',
      display: 'flex',
      justifyContent: 'center',
   },
   handleTarget: {
      backgroundColor: '#fff',
      width: '5px',
      height: '5px',
      top: 14,
      marginLeft: '5px',
   },
   textarea: {
      backgroundColor: 'transparent',
      padding: '5px',
      fontSize: 9,
      border: 'none',
      outline: 'none',
      resize: 'none',
      height: '100%',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      display: 'block',
      maxWidth: '100%',
   },
   rawText: {
      backgroundColor: '#F7F7FA',
      borderRadius: '5px',
      margin: '5px',
   },
}));
interface SendMsgNodeProps {
   id: string;
   isConnectable: boolean;
   data: {
      clickedNodeId: string;
      title: string;
      isValidNode: Boolean;
      flowBlocks: {
         _id: string;
         vertical: string;
         message: string;
         qpienFields: [{ key: number; value: string }];
         buttons: [{ text: string; type: string; url: string; example: string[] }];
         sampleMedia: { name: string };
      }[];
   };
}
const SendMsgNode = ({ id, isConnectable, data }: SendMsgNodeProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { title, clickedNodeId, flowBlocks } = data;

   const [isHover, setIsHover] = useState(false);
   const [blocks, setBlocks] = useState<
      {
         _id: string;
         vertical: string;
         message: string;
         qpienFields: [{ key: number; value: string }];
         buttons: [{ text: string; type: string; url: string; example: string[] }];
         sampleMedia: { name: string };
      }[]
   >([]);

   useEffect(() => {
      setIsHover(id === clickedNodeId);
   }, [data, id, clickedNodeId]);

   useEffect(() => {
      setBlocks(flowBlocks);
   }, [flowBlocks]);

   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         const words = content.length;
         let numRows = words / 22;
         const enters = (content.match(/\n/g) || []).length;
         if (enters) {
            numRows += enters;
         }
         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));
         return { rows: numRows, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };

   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (id === clickedNodeId ? setIsHover(true) : setIsHover(false))}
         className={classes.container}
      >
         <div className={`${classes.nodeContainer} ${isHover ? classes.nodeContainerHovered : ''}`}>
            <div className={classes.customHeader}>
               <Handle
                  type='target'
                  position={Position.Left}
                  id={id}
                  className={classes.handleTarget}
                  isConnectable={isConnectable}
               />
               <div className={classes.iconBox} style={{ backgroundColor: '#C3DDFF' }}>
                  <SendIcon style={{ color: '#147BFB', fontSize: '15px' }} />
               </div>
               <div style={{ marginTop: '3px', fontSize: 10 }}>{title}</div>
            </div>

            <div>
               <div>
                  {blocks[0]?.message ? (
                     <div className={classes.rawText}>
                        <textarea
                           value={blocks[0]?.message.split(' | ')[0].trim()}
                           className={classes.textarea}
                           readOnly
                           {...calculateTextareaSize(blocks[0]?.message.split(' | ')[0].trim())}
                        />
                        {blocks[0]?.buttons.length > 0 && blocks[0]?.buttons[0].type === 'URL' && (
                           <div
                              style={{
                                 padding: '10px 5px',
                                 fontSize: 9,
                              }}
                           >
                              <div
                                 style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#fff',
                                    padding: 5,
                                    borderRadius: 5,
                                    marginTop: 2,
                                 }}
                              >
                                 <div>{blocks[0]?.buttons[0]?.text}</div>
                                 <LinkIcon
                                    style={{
                                       color: '#147BFB',
                                       fontSize: '12px',
                                    }}
                                 />
                              </div>
                           </div>
                        )}
                     </div>
                  ) : (
                     <div className={classes.addText}>{t.atomation_flow_add_content}</div>
                  )}

                  <div className={classes.footer}>
                     <Handle
                        type='source'
                        position={Position.Right}
                        id={id}
                        className={classes.handleEdgeConnected}
                        isConnectable={isConnectable}
                     />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default SendMsgNode;

import { useEffect } from 'react';
import { useState } from 'react';

const useWindowResize = () => {
   const [height, setHeight] = useState(window.innerHeight); // Save the current date to be able to trigger an update
   const [width, setWidth] = useState(window.innerWidth); // Save the current date to be able to trigger an update

   useEffect(() => {
      const resize = () => {
         setHeight(window.innerHeight);
         setWidth(window.innerWidth);
      };
      window.addEventListener('resize', resize);
      return () => window.removeEventListener('resize', resize);
   }, []);

   return {
      width,
      height,
   };
};

export default useWindowResize;

import { gql } from '@apollo/client';
import { PERSON_CORE_FIELDS_FRAGMENT } from '../../Person/Service/GraphQL/fragments';
import { QNA_CHAT_CORE_FIELDS_FRAGMENT, QNA_CORE_FIELDS_FRAGMENT } from './fragments';

export const UPDATE_OWNER_PERSON_MUTATION = gql`
   ${PERSON_CORE_FIELDS_FRAGMENT}
   mutation updateCustomerPersonQuestionsMutation(
      $_id: ID!
      $name: String
      $surname: String
      $phone: String
      $email: String
      $type: Int
      $country: String
      $state: String
      $address: String
      $postCode: String
      $city: String
   ) {
      updatePerson(
         data: {
            _id: $_id
            name: $name
            surname: $surname
            phone: $phone
            email: $email
            type: $type
            country: $country
            state: $state
            address: $address
            postCode: $postCode
            city: $city
         }
      ) {
         data {
            ...PersonFields
         }
         success
         code
         message
      }
   }
`;

export const JOIN_QNA_MUTATION = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   mutation joinQnAMutation($_id: ID!) {
      joinQnAChat(input: { _id: $_id }) {
         data {
            ...QnAChatFields
         }
         success
         message
         code
      }
   }
`;

export const FORWARD_QNA_MUTATION = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   mutation forwardQnAMutation($_id: ID!, $joinedPerson: ID!) {
      forwardQnAChat(input: { _id: $_id, joinedPerson: $joinedPerson }) {
         data {
            ...QnAChatFields
         }
         success
         message
         code
      }
   }
`;

export const LEAVE_QNA_MUTATION = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   mutation leaveQnAMutation($_id: ID!, $status: Int) {
      leaveQnAChat(input: { _id: $_id, status: $status }) {
         data {
            ...QnAChatFields
         }
         success
         message
         code
      }
   }
`;

export const QUESTION_ADD_NOTE_MUTATION = gql`
   ${QNA_CORE_FIELDS_FRAGMENT}
   mutation createQuestionNoteMutation($question: String!, $QnAChat: ID!) {
      addQnANote(input: { question: $question, QnAChat: $QnAChat }) {
         data {
            ...QnAFields
         }
         success
         message
         code
      }
   }
`;

export const QUESTION_ADD_PERSON_TAG_MUTATION = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   mutation addQuestionTagMutation($_id: ID!, $tag: ID) {
      addQnAChatTag(input: { _id: $_id, tag: $tag }) {
         data {
            ...QnAChatFields
         }
         success
         message
         code
      }
   }
`;

export const SEND_REPLY_QUESTION_MUTATION = gql`
   ${QNA_CORE_FIELDS_FRAGMENT}
   mutation replyQuestionMutation(
      $_id: ID!
      $platformType: String
      $answer: String
      $replyType: String
      $rejectReason: String
   ) {
      updateQnA(
         input: {
            _id: $_id
            answer: $answer
            replyType: $replyType
            rejectReason: $rejectReason
            platformType: $platformType
         }
      ) {
         data {
            ...QnAFields
         }
         success
         message
         code
      }
   }
`;

export const SIGN_AS_ARCHIVE_QNA_MUTATION = gql`
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   mutation signAsArchiveQnAChatMutation($_id: ID!) {
      signAsArchiveQnAChat(input: { _id: $_id }) {
         data {
            ...QnAChatFields
         }
         success
         message
         code
      }
   }
`;

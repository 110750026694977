import React, { useEffect, useState, useContext, memo } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { TriggerIcon } from '@modules/Automation/FlowBots/FlowBuilder/icons/icons';
import {
   FlowBlockType,
   IFlowBlockFieldRules,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { LanguageContext } from '@helper/locale/langContext';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SET_CLICKED_NODE_ID } from '@store/actions/automation';

const useStyles = makeStyles(() => ({
   container: {
      position: 'relative',
      display: 'inline-block',
      background: 'rgba(255, 255, 255, 0.6)',
   },
   triggerIcon: {
      width: 20,
      height: 20,
      margin: '0 5px 5px 5px',
   },
   customHeader: {
      display: 'flex',
      borderBottom: '0.1px solid #f2f2f2',
   },
   addText: {
      padding: '10px',
      margin: '5px',
      textAlign: 'center',
      borderRadius: 2,
      fontSize: 7,
      color: '#AFAFAF',
      backgroundColor: '#F7F7FA',
   },
   deleteIcon: {
      position: 'absolute',
      top: '-15px',
      right: '46%',
      cursor: 'pointer',
   },
   nodeContainer: {
      border: '1px solid #ffffff',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   InvalidNodeContainer: {
      border: '1px solid #FF7474',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   nodeContainerHovered: {
      border: '1px solid #157cfc',
      background: 'rgba(255, 255, 255, 0.6)',
   },
   footer: {
      borderTop: ' 0.1px solid #f2f2f2',
      height: '12px',
      position: 'relative',
   },
   handle: {
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '-1px',
      marginTop: '3px',
      backgroundColor: '#fff',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      '&:hover': {
         cursor: 'default',
         marginRight: '-2px',
         width: '10px',
         height: '10px',
      },
   },
   handleBlock: {
      position: 'absolute',
      top: '12px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#fff',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleEdgeConnected: {
      position: 'absolute',
      top: '12px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#ADADAD',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },

   handleBlockTag: {
      position: 'absolute',
      borderRadius: '50%',
      cursor: 'default',
      marginRight: '10px',
      backgroundColor: '#fff',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      '&:hover': {
         cursor: 'default',
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   blocks: {
      display: 'flex',
      flexDirection: 'column',
      padding: '2px 5px',
      fontSize: '7px',
   },
   subBlocksTags: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '4px',
      border: '1px solid #F2F2F2',
      borderRadius: '5px',
   },
   subBlocks: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '6px',
      backgroundColor: '#F5F5F5',
      borderRadius: '5px',
   },
}));
interface UserSendMessageNodeProps {
   id: string;
   isConnectable: boolean;
   data: {
      clickedNodeId: string;
      title: string;
      isValidNode: Boolean;
      flowBlocks: IFlowBlockTemplate[];
      setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
      setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   };
}
const UserSendMessageNode = ({ id, isConnectable, data }: UserSendMessageNodeProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { title, clickedNodeId, flowBlocks, setClickedHandleId, isValidNode, setClickedNodeId } = data;
   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);
   const reactFlow = useReactFlow();

   const [isHover, setIsHover] = useState(false);

   const multipleTagBlocks = flowBlocks.filter(
      (item) =>
         item.type === FlowBlockType.NewMessageReceived &&
         Array.isArray(item.fields) &&
         item.fields.some((field) => field.type === IFlowBlockFieldTypes.keywordsMessageReceived),
   );

   useEffect(() => {
      setIsHover(id === clickedNodeId);
   }, [id, clickedNodeId]);

   const dispatch = useDispatch();
   const handleClicked = () => {
      if (botStatus === 'draft') {
         setClickedNodeId(id);
         dispatch({ type: SET_CLICKED_NODE_ID, payload: id });
      }
   };

   const currentNode = reactFlow.getNode(id);
   useEffect(() => {
      if (currentNode?.selected) {
         setIsHover(true);
      }
   }, [currentNode]);

   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (id === clickedId || currentNode?.selected ? setIsHover(true) : setIsHover(false))}
         className={classes.container}
         onClick={handleClicked}
      >
         <div
            className={`${classes.nodeContainer} ${isHover && isValidNode ? classes.nodeContainerHovered : ''} ${
               !isValidNode ? classes.InvalidNodeContainer : ''
            }`}
         >
            <div className={classes.customHeader}>
               <TriggerIcon className={classes.triggerIcon} />
               <div style={{ marginTop: '3px', fontSize: 9 }}>{title}</div>
            </div>

            {Array.isArray(flowBlocks) && multipleTagBlocks.length > 0 ? (
               <div>
                  <div>
                     {multipleTagBlocks.map((item) => (
                        <div key={item._id} className={classes.blocks}>
                           <div>
                              {item.fields.map((field, index) => (
                                 <div>
                                    <div>
                                       {t.atomation_flow_message}{' '}
                                       {field.rule === IFlowBlockFieldRules.Contains
                                          ? t.atomation_flow_trigger_block_contains
                                          : field.rule === IFlowBlockFieldRules.Equal
                                          ? t.atomation_flow_trigger_block_equal
                                          : field.rule === IFlowBlockFieldRules.NotContains
                                          ? t.atomation_flow_trigger_block_not_contains
                                          : field.rule === IFlowBlockFieldRules.Unknown
                                          ? t.atomation_flow_trigger_block_unknown
                                          : field.rule === IFlowBlockFieldRules.HasAnyValue
                                          ? t.atomation_flow_trigger_block_has_any_value
                                          : field.rule === IFlowBlockFieldRules.StartWith
                                          ? t.atomation_flow_trigger_block_start_with
                                          : field.rule === IFlowBlockFieldRules.EndWith
                                          ? t.atomation_flow_trigger_block_end_with
                                          : t.atomation_flow_trigger_block_not_equal}
                                       :
                                    </div>
                                    <div className={classes.subBlocksTags}>
                                       {!(
                                          field.rule === IFlowBlockFieldRules.HasAnyValue ||
                                          field.rule === IFlowBlockFieldRules.Unknown
                                       ) ? (
                                          <div>
                                             <div
                                                key={index}
                                                style={{ display: 'flex', gap: '0.3rem', flexWrap: 'wrap' }}
                                             >
                                                {field.keywords?.map((keyword, keywordIndex) => (
                                                   <span
                                                      style={{
                                                         backgroundColor: '#F5F5F5',
                                                         padding: '2px',
                                                         borderRadius: 2,
                                                         whiteSpace: 'nowrap',
                                                         display: 'inline-block',
                                                      }}
                                                      key={keywordIndex}
                                                   >
                                                      {keyword}
                                                   </span>
                                                ))}
                                             </div>
                                          </div>
                                       ) : (
                                          <div style={{ height: '15px', width: '100%' }}>
                                             <div
                                                style={{
                                                   height: '100%',
                                                   width: '85%',
                                                   backgroundColor: '#F5F5F5',
                                                   borderRadius: 5,
                                                }}
                                             ></div>
                                          </div>
                                       )}

                                       <Handle
                                          type='source'
                                          position={Position.Right}
                                          id={item._id}
                                          className={item.target ? classes.handleEdgeConnected : classes.handleBlock}
                                          isConnectable={isConnectable}
                                          onMouseEnter={() => setClickedHandleId(item._id)}
                                       />
                                    </div>
                                 </div>
                              ))}
                           </div>
                        </div>
                     ))}
                  </div>
               </div>
            ) : (
               <div className={classes.addText}>{t.atomation_flow_trigger_block_add_start_trigger}</div>
            )}
         </div>
      </div>
   );
};
export default memo(UserSendMessageNode);

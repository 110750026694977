import React, { useState, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Box } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { LanguageContext } from '@helper/locale/langContext';
import { cc_expires_format, normalizeCardNumber } from '@modules/Welcome/utils/validation';
import { CheckBox } from '@modules/Welcome/Payment';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ModalButtonField from '@components/_Modal/ModalButtonField';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 600,
      padding: '20px 20px 20px 10px',
      borderRadius: 18,
      [theme.breakpoints.down('sm')]: {
         width: '90%',
         marginLeft: 'auto',
         marginRight: 'auto',
      },
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      boxShadow: '0px 3px 6px #00000029',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
      textTransform: 'capitalize',
   },
   message: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   title: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '24px',
      // color: '#4681C3',
      color: 'black',
      fontWeight: 'bold',
      marginTop: 0,
      width: '100%',
      marginBottom: 15,
   },
   inputContainer: {
      border: '1px solid #E2E2E2',
      borderRadius: '8px',
      margin: 10,
      padding: '.25rem 1rem',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 300,
   },
   inputBase: {
      flex: 1,
      border: 'none',
      padding: '.5rem',
      marginLeft: '.3rem',
      fontWeight: 300,
      '&:focus': {
         border: 'none',
         outline: 'none',
      },
      '&::placeholder': {
         fontWeight: 300,
         color: '#315276',
         opacity: '0.37',
         verticalAlign: 'center',
      },
   },
   centerTextIcon: {
      display: 'flex',
      alignItems: 'center',
      color: '#485A6F',
      fontSize: '.85rem',
   },
   couponText: {
      color: '#178CF9',
   },
   cardHeaderTitle: {
      color: '#315276',
      fontSize: '1rem',
      paddingBottom: '1rem',
   },
   saveCardText: {
      paddingLeft: '1rem',
      color: '#485A6F',
      fontWeight: '400',
      fontSize: '.85rem',
   },
   preferenceStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '8px',
      color: '#485A6F',
      fontSize: '14px',
   },
   errorText: {
      color: 'red',
      fontSize: '12px',
   },
   mlp5: {
      marginLeft: '.5rem',
   },
   checkBox: {
      margin: 0,
      padding: 0,
      '&:hover': {
         cursor: 'pointer',
      },
   },
   colorRed: {
      color: 'red',
   },
   upperCardLabel: {
      minWidth: '45%',
   },
   cardLabel: {
      color: '#93A8C1',
   },
   marginY: {
      margin: '15px 0px',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const AddNewCardModal = ({ onAccept, onMakePayment }) => {
   const classes = useStyles();
   const history = useHistory();
   const customer = useSelector((state) => state.personReducer.person.customer);
   const isCustomerDisabled = customer.appStatus === 'Disabled';
   const {
      register,
      handleSubmit,
      setValue,
      formState: { errors, isValid },
   } = useForm({
      mode: 'all',
   });
   const [storeCard, setStoreCard] = useState(true);

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const isDisabledPayment =
      !customer?.invoiceAddress ||
      !customer?.invoiceCity ||
      !customer?.invoiceCompanyName ||
      !customer?.invoiceCountry ||
      !customer?.invoicePostCode ||
      !customer?.invoiceState ||
      !customer?.phone ||
      !customer?.invoicePostCode ||
      !customer?.authorizedPersonName ||
      !customer?.authorizedPersonSurname;

   const handleData = (data) => {
      data.cardNumber = data.cardNumber.split(/\s+/).join('');
      data.expireMonth = data.date.split('/')[0];
      data.expireYear = data.date.split('/')[1];
      delete data.date;
      // make payment immediately
      if (isCustomerDisabled) {
         onMakePayment({
            productId: customer.package._id,
            ...data,
         });
      } else {
         onAccept(data);
      }
   };

   if (isDisabledPayment) {
      return (
         <Grid>
            <Grid container xs={12} className={classes.marginY} justifyContent='center'>
               <ErrorOutlineIcon color='error' fontSize='large' />
            </Grid>
            <Typography align='center' variant='h6' color='inherit'>
               {t?.you_cant_add_card_right_now}
            </Typography>
            <Button
               variant='contained'
               size='large'
               color='primary'
               fullWidth
               onClick={() => history.push('/account/company')}
               className={classes.marginY}
            >
               <Typography>{t?.edit_company_information}</Typography>
            </Button>
         </Grid>
      );
   }

   return (
      <Grid>
         <form onSubmit={handleSubmit(handleData)}>
            <Grid
               container
               direction='row'
               style={{
                  marginBottom: 20,
                  paddingBottom: 10,
                  borderBottom: '1px solid #EFEFEF',
               }}
            >
               <Typography className={classes.title}>
                  {' '}
                  {isCustomerDisabled ? t?.make_payment : t?.add_new_card}
               </Typography>
            </Grid>

            <Grid container style={{ width: '100%' }}>
               <Grid item xs={12}>
                  <Box
                     className={classes.inputContainer}
                     style={{
                        borderColor: errors.cardHolderName ? 'red' : undefined,
                     }}
                  >
                     <Typography className={clsx(classes.cardLabel, classes.upperCardLabel)}>
                        {' '}
                        {t?.card_holder} <span className={classes.colorRed}> * </span>{' '}
                     </Typography>
                     <input
                        className={clsx(classes.inputBase, classes.pleft9rem)}
                        placeholder='John Doe'
                        {...register('cardHolderName', {
                           required: t?.card_holder_name_is_required,
                           validate: (val) => {
                              if (!val.trim().length > 0) return t?.card_holder_name_is_required;
                           },
                        })}
                     />
                  </Box>
                  {errors.cardHolderName && <span className={classes.errorText}>{errors.cardHolderName.message}</span>}
               </Grid>

               <Grid item xs={12}>
                  <Box
                     className={classes.inputContainer}
                     style={{ borderColor: errors.cardNumber ? 'red' : undefined }}
                  >
                     <Typography className={clsx(classes.cardLabel, classes.upperCardLabel)}>
                        {' '}
                        {t?.card_number} <span className={classes.colorRed}> * </span>{' '}
                     </Typography>
                     <input
                        className={clsx(classes.inputBase, classes.pleft9rem)}
                        placeholder='****&#9;&#9;****&#9;&#9;****&#9;&#9;****'
                        type='tel'
                        inputMode='numeric'
                        autoComplete='cc-number'
                        {...register('cardNumber', {
                           required: t?.card_number_is_required,
                           validate: (value) => {
                              if (value) {
                                 setValue('cardNumber', normalizeCardNumber(value));
                              }
                           },
                        })}
                     />
                  </Box>
                  {errors.cardNumber && <span className={classes.errorText}>{errors.cardNumber.message}</span>}
               </Grid>

               <Grid item xs={12}>
                  <Grid container>
                     <Grid xs={6}>
                        <Box
                           className={classes.inputContainer}
                           style={{
                              borderColor: errors.date ? 'red' : undefined,
                           }}
                        >
                           <Typography className={classes.cardLabel}>
                              {' '}
                              {t?.date} <span className={classes.colorRed}> * </span>{' '}
                           </Typography>
                           <input
                              className={classes.inputBase}
                              style={{ maxWidth: '130px' }}
                              {...register('date', {
                                 required: t?.expiry_date_is_required,
                                 validate: (value) => {
                                    setValue('date', cc_expires_format(value));
                                    if (value.length < 5) {
                                       return 'Please enter a valid expire date.';
                                    }
                                 },
                              })}
                           />
                        </Box>
                        {errors.date && <span className={classes.errorText}>{errors.date.message}</span>}
                     </Grid>
                     <Grid xs={6}>
                        <Box
                           className={classes.inputContainer}
                           style={{
                              marginLeft: '.5rem',
                              borderColor: errors.cvc ? 'red' : undefined,
                           }}
                        >
                           <Typography className={classes.cardLabel}>
                              {' '}
                              {t?.cvc} <span className={classes.colorRed}> * </span>{' '}
                           </Typography>
                           <input
                              className={classes.inputBase}
                              style={{ maxWidth: '100px' }}
                              // type="number"
                              maxLength='3'
                              {...register('cvc', {
                                 required: t?.cvv_is_required,
                                 validate: (value) => {
                                    if (!value.trim().length > 0) return t?.cvv_is_required;
                                    if (value.length !== 3) return 'CVV must be atleast 3 characters';
                                 },
                              })}
                           />
                        </Box>
                        {errors.cvc && (
                           <span className={clsx(classes.errorText, classes.mlp5)}>{errors.cvc.message}</span>
                        )}
                     </Grid>
                  </Grid>
               </Grid>

               <Grid
                  container
                  justifyContent='space-between'
                  direction='row'
                  item
                  xs={10}
                  style={{ marginBlock: 15, marginLeft: 15 }}
               >
                  <Grid item xs={1}>
                     <CheckBox
                        selected={storeCard}
                        setSelected={() => {
                           setStoreCard((prev) => true);
                        }}
                     />
                  </Grid>
                  <Grid item xs={11}>
                     <Typography className={classes.preferenceStyle}>{t?.allow_store_card}</Typography>
                  </Grid>
               </Grid>
               <ModalButtonField style={{ justifyContent: 'end' }}>
                  <Button
                     disabled={!isValid}
                     type='submit'
                     className={classes.modalButtons}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        '&:hover': {
                           backgroundColor: '#105CBB',
                        },
                     }}
                  >
                     {isCustomerDisabled ? t?.make_payment : t?.page_chat_typo_profile_save}
                  </Button>
               </ModalButtonField>
            </Grid>
         </form>
      </Grid>
   );
};

export default AddNewCardModal;

import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
   AccountAllPackagesIcon,
   AccountMyInvoicesIcon,
   AccountMyPackageIcon,
   CompanyIcon,
   PackagesIcon,
   PreferencesIcon,
   ProfileIcon,
} from '@assets/icons/ProfileIcons';
import { LanguageContext } from '@helper/locale/langContext';
import Section from './section';

const useStyles = makeStyles((theme) => ({
   root: {
      height: '100%',
      width: '100%',
      borderRadius: 15,
      background: '#F7F7FA',
   },
   leftContainer: {
      padding: 10,
      width: '100%',
      height: '100%',
   },
   appBarSpacer: theme.mixins.toolbar,
}));

const Panel = () => {
   const classes = useStyles();
   const history = useHistory();
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const MOCK_SETTINGS_MENU = [
      {
         title: t['comp_sidebar_profile'],
         url: '/account/profile',
         icon: (className) => <ProfileIcon className={className} />,
         items: [],
         personType: 0,
      },
      {
         url: '/account/company',
         icon: (className) => <CompanyIcon className={className} />,
         title: t['comp_sidebar_company'],
         items: [],
         personType: 0,
      },
      {
         url: '/account/packages/my-package',
         title: t['comp_sidebar_package_and_factors'],
         include: 'packages',
         icon: (className) => <PackagesIcon className={className} />,
         items: [
            {
               url: '/account/packages/my-package',
               title: t['comp_sidebar_my_packages'],
               icon: (className) => <AccountMyPackageIcon className={className} />,
            },
            {
               url: '/account/packages/my-factors',
               title: t['comp_sidebar_my_factors'],

               icon: (className) => <AccountMyInvoicesIcon className={className} />,
            },
            {
               url: '/account/packages/all-factors',
               title: t['comp_sidebar_all_factors'],
               icon: (className) => <AccountAllPackagesIcon className={className} />,
            },
         ],
         personType: 1,
      },
      {
         url: '/account/preferences',
         title: t['comp_sidebar_preferences'],
         icon: (className) => <PreferencesIcon className={className} />,
         items: [],
         personType: 1,
      },
   ];

   return (
      <div className={classes.leftContainer}>
         <div className={classes.appBarSpacer} />
         <Paper elevation={3} className={classes.root}>
            {MOCK_SETTINGS_MENU.map(
               (section) =>
                  section.personType <= person.type && (
                     <Link to={section.url}>
                        <Section
                           key={section.url}
                           sectionSelected={
                              history.location.pathname === section.url ||
                              (section.include && history.location.pathname.includes(section.include))
                           }
                           selectedItem={history.location.pathname}
                           section={section}
                        />
                     </Link>
                  ),
            )}
         </Paper>
      </div>
   );
};

export default Panel;

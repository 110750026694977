import { gql } from '@apollo/client';
import { CHAT_ALL_FIELDS_FRAGMENT } from './fragments';

// subscriptions
export const UPDATED_CHAT_STATUS_SUBSCRIPTION = gql`
   ${CHAT_ALL_FIELDS_FRAGMENT}
   subscription updatedChatStatusSubscription($token: String!, $customer: ID!) {
      updatedChatStatus(data: { token: $token, customer: $customer }) {
         data {
            ...ChatAllFields
            previousStatus
            previousParticipant
         }
         success
         code
         message
      }
   }
`;

export const JOIN_CHAT_SUBSCRIPTION = gql`
   ${CHAT_ALL_FIELDS_FRAGMENT}
   subscription joinChatSubscription($token: String!, $customer: ID!) {
      joinCustomerChat(data: { token: $token, customer: $customer }) {
         data {
            ...ChatAllFields
            previousStatus
            previousParticipant
            isRedirect
         }
         success
         code
         message
      }
   }
`;

export const LEAVE_CHAT_SUBSCRIPTION = gql`
   ${CHAT_ALL_FIELDS_FRAGMENT}
   subscription leaveChatSubscription($token: String!, $customer: ID!) {
      leaveCustomerChat(data: { token: $token, customer: $customer }) {
         data {
            ...ChatAllFields
            previousStatus
            previousParticipant
         }
         success
         code
         message
      }
   }
`;

export const ARCHIVE_CHAT_SUBSCRIPTION = gql`
   ${CHAT_ALL_FIELDS_FRAGMENT}
   subscription archiveChatSubscription($token: String!, $customer: ID!) {
      archiveCustomerChat(data: { token: $token, customer: $customer }) {
         data {
            ...ChatAllFields
            previousStatus
            previousParticipant
         }
         success
         code
         message
      }
   }
`;

export const TRANSFER_CHAT_SUBSCRIPTION = gql`
   ${CHAT_ALL_FIELDS_FRAGMENT}
   subscription transferChatSubscription($token: String!, $customer: ID!) {
      transferChat(data: { token: $token, customer: $customer }) {
         data {
            ...ChatAllFields
            previousStatus
            previousParticipant
         }
         success
         code
         message
      }
   }
`;

export const CHANGED_CHAT_TAG_SUBSCRIPTION = gql`
   ${CHAT_ALL_FIELDS_FRAGMENT}
   subscription changedChatTagSubscription($token: String!, $customer: ID!) {
      changedChatTag(data: { token: $token, customer: $customer }) {
         data {
            ...ChatAllFields
         }
         success
         code
         message
      }
   }
`;

import { gql } from '@apollo/client';

export const LOCATIONS = gql`
   query locations($type: String!, $id: Number) {
      locations(type: $type, id: $id) {
         code
         data
         success
         message
      }
   }
`;

import React, { Fragment, useContext, useEffect, useState } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Toolbar from '../toolbar';
import moment from 'moment-timezone';
import Chip from '../chip';
import Users from './users';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import { GET_PERSON_CUSTOMERS_QUERY } from '../../../../queries/Person/Service/GraphQL/query';
import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import Error from '@components/Errors/Error';
import { GET_PERSONS_STATISTICS_QUERY } from '@queries/Statistics/Service/GraphQL/query';

const getColumns = (t) => [
   {
      title: t['page_statistics_typo_users_table_total_sent_messages'],
      value: 0,
      x: 3,
   },
   {
      title: t['page_statistics_typo_users_table_total_solved_messages'],
      value: 0,
      x: 3,
   },

   {
      title: t['page_statistics_typo_users_table_avarage_resolution_time'],
      value: 0,
      x: 3,
   },
   {
      title: t['page_statistics_typo_users_table_avarage_first_response_time'],
      value: 0,
      x: 3,
   },
];

const useStyles = makeStyles((theme) => ({
   root: {
      padding: 20,
      paddingBottom: '6rem',
   },
   summaryContainer: {
      paddingRight: 10,
      paddingLeft: 10,
   },
   usersContainer: {
      marginTop: 20,
   },
   emptyList: {
      fontSize: '1.3rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      marginTop: 40,
   },
}));

const calculatePercentages = (argList, totalActiveChats, totalSentMessages, totalSolvedMessages) => {
   const newList = argList.map((item) => ({
      ...item,
      percentActive: Math.round((100 * item?.joinedChatCount) / totalActiveChats),
      percentSent: Math.round((100 * item?.sentMessageCount) / totalSentMessages),
      percentSolved: Math.round((100 * item?.solvedChatCount) / totalSolvedMessages),
   }));

   return newList;
};

const Tags = () => {
   const classes = useStyles();
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   // Language Context
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const MOCK_SUMMARY_DATA = getColumns(t);
   const [state, setState] = useState({
      // startDate: moment().subtract(1, 'day').valueOf(),
      startDate: moment().valueOf(),
      endDate: moment().valueOf(),
      platform: 'ALL',
      totalReceived: 0,
      totalSent: 0,
      chartData: [],
   });
   const {
      loading: personsLoading,
      data: personsData,
      error: personsError,
   } = useQuery(GET_PERSON_CUSTOMERS_QUERY, {
      variables: { customer: person.customer._id, notSelectBot: true, page: 1, pageSize: 20 },
      fetchPolicy: 'cache-first',
   });
   let myList = [];

   const {
      data: personsStatisticsData,
      loading: statisticDataLoading,
      error: statsPersonError,
      refetch: statsRefetch,
      networkStatus: statsNetworkStatus,
   } = useQuery(GET_PERSONS_STATISTICS_QUERY, {
      variables: {
         customer: person.customer._id,
         startDate: state.startDate,
         endDate: state.endDate,
         platform: state.platform,
      },
      fetchPolicy: 'network-only',
   });
   const handleChangeFilterDate = (startDate, endDate) => {
      setState((prevState) => {
         const newState = { ...prevState };
         if (startDate) {
            newState.startDate = startDate;
         }
         if (endDate) {
            newState.endDate = endDate;
         }
         return newState;
      });
   };

   const handleChangePlatform = (platform) => {
      setState((prevState) => ({ ...prevState, platform }));
   };

   useEffect(() => {
      statsRefetch();
   }, [state, statsRefetch]);

   const parseTime = (ms) => {
      let hour = Math.floor(ms / 3600000);
      let minute = Math.floor((ms % 3600000) / 60000);
      let second = Math.floor(((ms % 3600000) % 60000) / 1000);
      let newTime;

      if (hour > 0) {
         newTime = `${hour}h ${minute}m ${second}s`;
      } else if (minute > 0) {
         newTime = `${minute}m ${second}s`;
      } else {
         newTime = `${second}s`;
      }
      return newTime;
   };

   const loadingOrRefetching = statsNetworkStatus === 4 || personsLoading || statisticDataLoading;
   if (personsStatisticsData?.getPersonsStatistics?.data && personsData?.customerPersons?.data?.docs) {
      //MOCK_SUMMARY_DATA[0].value = personsData?.customerPersons?.data.length;
      //MOCK_SUMMARY_DATA[1].value = personsStatisticsData?.getPersonsStatistics?.data?.activeChatsCount;
      MOCK_SUMMARY_DATA[0].value = personsStatisticsData?.getPersonsStatistics?.data?.sentMessagesCount;
      MOCK_SUMMARY_DATA[1].value = personsStatisticsData?.getPersonsStatistics?.data?.solvedChatsCount;
      MOCK_SUMMARY_DATA[2].value = parseTime(
         personsStatisticsData?.getPersonsStatistics?.data?.totalResolutionTime
            ? personsStatisticsData?.getPersonsStatistics?.data?.totalResolutionTime /
                 personsStatisticsData?.getPersonsStatistics?.data?.solvedChatsCount
            : 0,
      );
      MOCK_SUMMARY_DATA[3].value = parseTime(
         personsStatisticsData?.getPersonsStatistics?.data?.totalFirstResponseTime
            ? personsStatisticsData?.getPersonsStatistics?.data?.totalFirstResponseTime /
                 personsStatisticsData?.getPersonsStatistics?.data?.totalFirstResponseCount
            : 0,
      );
      // now write some data on userDoc
      const totalActiveChats = personsStatisticsData?.getPersonsStatistics?.data?.activeChatsCount;
      const totalSentMessages = personsStatisticsData?.getPersonsStatistics?.data.sentMessagesCount;
      const totalSolvedMessages = personsStatisticsData?.getPersonsStatistics?.data.solvedChatsCount;
      const usersReportList = personsStatisticsData?.getPersonsStatistics?.data.personsReport;
      myList = calculatePercentages(usersReportList, totalActiveChats, totalSentMessages, totalSolvedMessages);
   }

   if (loadingOrRefetching) {
      return <div className='loading' />;
   }

   return (
      <Fragment>
         <Toolbar
            onPlatformChange={handleChangePlatform}
            platform={state.platform}
            onDateChange={handleChangeFilterDate}
            startDate={state.startDate}
            endDate={state.endDate}
            title={t['page_statistics_typo_users']}
            operation={'getPersonStatistics'}
            params={{
               customer: person.customer._id,
               startDate: state.startDate,
               endDate: state.endDate,
               platform: state.platform,
            }}
         />
         {personsStatisticsData?.getPersonsStatistics?.data && personsData?.customerPersons?.data?.docs && (
            <Grid container className={classes.root}>
               <Grid container direction='row' justifyContent='flex-start' xs={12} alignItems='center'>
                  {MOCK_SUMMARY_DATA.map((item, index) => (
                     <Grid key={item.title + index} item xs={item.x} style={{ padding: '5px 5px' }}>
                        <Chip key={index} title={item.title} value={item.value} />
                     </Grid>
                  ))}
               </Grid>
               {personsData?.customerPersons?.data?.docs?.length > 0 ? (
                  <Grid
                     container
                     direction='row'
                     justifyContent='space-between'
                     alignItems='center'
                     className={classes.usersContainer}
                  >
                     <Users
                        totalSentMessages={personsStatisticsData.getPersonsStatistics?.data?.sentMessagesCount}
                        totalActiveChats={personsStatisticsData.getPersonsStatistics?.data?.activeChatsCount}
                        totalSolvedChats={personsStatisticsData.getPersonsStatistics?.data?.solvedChatsCount}
                        report={myList || []}
                        users={personsData?.customerPersons?.data?.docs || []}
                     />
                  </Grid>
               ) : (
                  <Grid xs={12} container justifyContent='center' style={{ height: 100 }}>
                     <Typography className={classes.emptyList}>{t?.your_message_list_is_empty}</Typography>{' '}
                  </Grid>
               )}
            </Grid>
         )}
         <Error
            show={!!personsError || !!statsPersonError}
            message={personsError?.message || statsPersonError?.message}
         />
      </Fragment>
   );
};
export default Tags;

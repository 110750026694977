import {
   DISCOUNT_TYPES,
   DiscountType,
   IDiscountProps,
} from '@modules/Chat/RightPanel/Tabs/E-Commerce/Tabs/Customer/types';

export interface ProductProps {
   _id: string;
   image: string;
   name: string;
   quantity: number;
   price: number;
}

export interface CustomerProps {
   _id: string;
   fullName: string;
   email: string;
   phone?: string;
   platformCustomerId?: string;
   status: 'passive' | 'active';
}

export enum ORDER_FLAGS {
   ALL_ORDERS = 'ALL_ORDERS',
   OPEN = 'OPEN',
   CLOSED = 'CLOSED',
   CANCELLED = 'CANCELLED',
   PENDING = 'PENDING',
   PAID = 'PAID',
   REFUNDED = 'REFUNDED',
   FULFILLED = 'FULFILLED',
   UNFULFILLED = 'UNFULFILLED',
   PARTIALLY_FULFILLED = 'PARTIALLY_FULFILLED',
   PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
   SHIPPED = 'SHIPPED',
   PARTIAL = 'PARTIAL',
   UNKNOWN = 'UNKNOWN',
}

export enum ORDER_FLAGS_COLORS {
   ALL_ORDERS = '#fff',
   OPEN = '#157CFC',
   CLOSED = '#157CFC',
   CANCELLED = '#157CFC',
   PENDING = '#FE7F2D',
   PAID = '#2A9D8F',
   REFUNDED = '#FF5C5C',
   FULFILLED = '#A576E6',
   UNFULFILLED = '#EFBD42',
   PARTIALLY_FULFILLED = '#EFBD42',
   PARTIALLY_PAID = '#ff7d5c',
   PARTIALLY_REFUNDED = '#FF5C5C',
   PARTIAL = '#EFBD42',
   SHIPPED = '#A576E6',
   UNKNOWN = '#EFBD42',
}

export interface BillProps {
   status: string;
   subTotal: number;
   tax: number;
   billIncludesTaxes: boolean;
   shipping: number;
   discount: IDiscountProps;
   payment: number;
   total: number;
   currency: string;
   paidByCustomer?: number;
}

export interface OrderProps {
   _id: string;
   owner: CustomerProps;
   flags: string[];
   price: number;
   orderNote?: string;
   trackingInfo?: string;
   shippingMethod?: string;
   fulfilledProducts?: ProductProps[];
   unfulfilledProducts?: ProductProps[];
   removedProducts?: ProductProps[];
   bill: BillProps;
   createdAt: Date;
}

import {
   ChatSettingsIcon,
   CustomerSatisfaction,
   LiveChatIcon,
   MessageHoursIcon,
   PageSettingsIcon,
   PageSettingsTeamsIcon,
   QuickAnswersIcon,
   SpecialTagsIcon,
} from '@assets/icons/SettingsIcons/settingsIcons';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AppsIcon from '@material-ui/icons/Apps';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import SettingSection from './settingSection';

const MOCK_SETTINGS_MENU = [
   {
      title: 'page_settings_typo_users',
      clickable: false,
      code: 'USERS',
      url: '/settings/users',
      icon: <PageSettingsTeamsIcon fill={'#0E223C'} className='SettingPageIcon' />,
      items: [
         {
            title: 'page_settings_typo_users',
            code: 'USERS',
            url: '/settings/users',
            icon: <PersonOutlineIcon fill={'#0E223C'} className='SettingPageIcon' style={{ fontSize: '1.5rem' }} />,
         },
         {
            title: 'teams',
            code: 'USERS',
            url: '/settings/teams',
            icon: <PageSettingsTeamsIcon fill={'#0E223C'} className='SettingPageIcon' />,
         },
      ],
   },
   {
      title: 'page_settings_typo_general_settings',
      clickable: false,
      code: 'GENERAL_SETTINGS',
      icon: <PageSettingsIcon fill={'#0E223C'} className='SettingPageIcon' />,
      items: [
         {
            code: 'MESSAGE_HOURS',
            url: '/settings/company/hours',
            title: 'page_settings_typo_messaging_hours',
            icon: (className) => <MessageHoursIcon className={className} />,
         },
         {
            code: 'SPECIAL_TAGS',
            url: '/settings/company/tags',
            title: 'page_settings_typo_unique_tags',
            icon: (className) => <SpecialTagsIcon className={className} />,
         },
         {
            code: 'QUICK_ANSWERS',
            url: '/settings/company/quick-answers',
            title: 'page_settings_typo_quick_answers',
            icon: (className) => <QuickAnswersIcon className={className} />,
         },
         {
            code: 'CHAT_SETTINGS',
            url: '/settings/company/chat-settings',
            title: 'page_settings_typo_chat_settings',
            icon: (className) => <ChatSettingsIcon className={className} />,
         },
         {
            code: 'CUSTOM_FIELDS',
            url: '/settings/company/custom-fields',
            title: 'page_settings_typo_custom_fields',
            icon: (className) => <DescriptionOutlinedIcon className={className} />,
         },
         {
            code: 'CUSTOMER_SATISFACTION',
            url: '/settings/company/customer-satisfaction',
            title: 'page_settings_typo_customer_satisfaction',
            icon: (className) => <CustomerSatisfaction className={className} />,
         },
      ],
   },
   {
      title: 'page_settings_typo_platforms_live_chat',
      code: 'LIVE_CHAT',
      clickable: true,
      url: '/settings/platforms/live-chat',
      icon: <LiveChatIcon className={'SettingPageIcon'} />,
   },
   {
      title: 'page_settings_typo_channels_and_integrations',
      code: 'CHANNELS_AND_INTEGRATIONS',
      clickable: true,
      url: '/settings/channels-and-integrations',
      icon: <AppsIcon className='SettingPageIcon' />,
   },
];

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      borderRadius: 15,
      // padding: 20,
      paddingTop: 10,
      paddingLeft: 0,
      // background: theme.chatApp.general.pallet.passiveStructureGrey,
      background: '#F7F7FA',
   },
   appBarSpacer: theme.mixins.toolbar,
}));

const SettingsPanel = () => {
   const classes = useStyles();
   const history = useHistory();

   const [selectedSection, setSelectedSection] = useState({});

   const handleChange = useCallback(
      (section, item) => {
         setSelectedSection(section);
         history.push(item.url);
      },
      [history],
   );

   return (
      <Grid style={{ padding: 10, width: '100%', height: '100%' }}>
         <div className={classes.appBarSpacer} />
         <Paper elevation={1} className={classes.root}>
            {MOCK_SETTINGS_MENU.map((section) => (
               <SettingSection
                  key={section.code}
                  selected={section.url && history.location.pathname.includes(section.url)}
                  //selected={selectedSection.code === section.code}
                  section={section}
                  onItemChange={(item) => handleChange(section, item)}
               />
            ))}
         </Paper>
      </Grid>
   );
};

export default SettingsPanel;

import { gql } from '@apollo/client';

export const CHAT_ALL_FIELDS_FRAGMENT = gql`
   fragment ChatAllFields on Chat {
      _id
      teams {
         _id
         color
         drivers
         teamName
         isMain
      }
      unReadCount
      participants {
         _id
         name
         surname
         username
         phone
         email
         photo
         type
         status
         country
         countryCode
         state
         address
         postCode
         city
         eCommerceProfileId
         whatsAppWebPhone
         whatsAppBusinessPhone
         facebookId
         instagramId
         platformId
         setup
         createdAt
         language
         tags
      }
      color
      type
      activeFlowInstance
      whatsAppSenderPhone
      whatsAppPhone {
         _id
         name
         number
         status
         createdAt
         onlineStatus
      }
      facebookPage {
         _id
         name
         category
         facebookAccount {
            _id
            firstName
            lastName
            email
            facebookId
            accessToken
            createdAt
         }
         isMessageEnable
         isCommentEnable
         status
         failedMessage
         facebookPageId
         accessToken
         createdAt
      }
      lastMessage {
         _id
         type
         content
         contentHtml
         emailData {
            from
            to
            cc
            bcc
         }
         media
         template_cards
         readStatus
         # whatsAppWebId
         QnAId
         ack
         # whatsAppBusinessId
         personStatus
         createdAt
         subject
      }
      instagramPage {
         _id
         name
         category
         instagramAccount {
            _id
            firstName
            lastName
            email
            instagramId
            accessToken
            createdAt
         }
         isMessageEnable
         isCommentEnable
         status
         failedMessage
         instagramPageId
         accessToken
         createdAt
      }
      emailAccount {
         _id
         forwardingEmailAddress
         customer
         connectedEmailAddress
         senderName
         emailSignature
      }
      whatsAppBusinessPhone
      telegramAccount {
         _id
         botId
         firstName
         userName
         apiToken
         receiveChannelMessages
         createdAt
         status
      }
      customer {
         accountStatus
         accountConfirmation
         appStatus
         hasFreeTrial
         freeTrialExpiringDate
         isANewCustomer
         _id
         companyName
      }
      tag {
         _id
         name
         color
      }
      joinStatus
      color
      isJoinable
      firstLog
      status
      createdAt
      __typename
   }
`;

import React, { useContext } from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext, getDefaultLang, getAllLanguages } from '@helper/locale/langContext';
const useStyles = makeStyles({
   contactContainer: {
      display: 'flex',
      marginRight: 'auto',
      flexDirection: 'column',
      background: '#F7F7FA',
      borderRadius: 8,
      color: '#10223A',
      width: 320,
   },
   headerText: {
      fontWeight: 'bold',
      margin: 15,
   },
   inputs: {
      margin: 15,
      marginTop: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      flexGrow: 1,
   },
   subHeaderText: {
      fontSize: '.85rem',
      paddingBottom: '.5rem',
   },
   input: {
      width: '90%',
      border: '1px solid #EBEBEB',
      borderRadius: '4px',
      backgroundColor: 'white',
      margin: '8px 0',
      outline: 'none',
      padding: '.5rem .25rem',
      transition: '.3s',
      color: '#9F9F9F',
      '&::placeholder': {
         color: '#9F9F9F',
      },
      '&:focus': {
         borderColor: '#d9d8d8',
         boxShadow: '0 0 8px 0 #d9d8d8',
      },
   },
   btn: {
      backgroundColor: '#157cfc',
      color: 'white',
      width: '95%',
      '&:hover': {
         backgroundColor: '#157cfc',
         boxShadow: '0 0 8px 0 #ccc',
      },
   },
   fakeInputContainer: {
      backgroundColor: '#FFF',
      border: '1px solid #c8c8c8',
      borderRadius: 6,
      display: 'flex',
      padding: '10px 15px',
      alignItems: 'center',
      flexGrow: 1,
   },
});

const FakeInput = ({ value }) => {
   const classes = useStyles();

   if (!value) return null;
   return (
      <div className={classes.fakeInputContainer}>
         <Typography variant='body1' noWrap={true}>
            {value}
         </Typography>
      </div>
   );
};

const ContactInfoMessage = ({ address, email, name, phone }) => {
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const classes = useStyles();

   return (
      <Box className={classes.contactContainer}>
         <Typography className={classes.headerText}>{t?.contact_details}</Typography>
         <div className={classes.inputs}>
            <FakeInput value={name} />
            <FakeInput value={email} />
            <FakeInput value={phone} />
            <FakeInput value={address} />
         </div>
      </Box>
   );
};

export default ContactInfoMessage;

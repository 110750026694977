import React from 'react';

type Fill = string;

export const JoinChatIcon = ({ fill }: { fill: Fill }) => (
   <svg xmlns='http://www.w3.org/2000/svg' width='17.042' height='16.5' viewBox='0 0 17.042 16.5'>
      <g id='log-in-outline' transform='translate(0.75 0.75)'>
         <path
            id='Path_4362'
            data-name='Path 4362'
            d='M9,8.25V6.375A1.875,1.875,0,0,1,10.875,4.5h7.5A1.875,1.875,0,0,1,20.25,6.375v11.25A1.875,1.875,0,0,1,18.375,19.5H11.25A2.233,2.233,0,0,1,9,17.625V15.75'
            transform='translate(-4.708 -4.5)'
            fill='none'
            stroke={fill}
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.5'
         />
         <path
            id='Path_4363'
            data-name='Path 4363'
            d='M13.5,15.75,17.25,12,13.5,8.25'
            transform='translate(-4.97 -4.5)'
            fill='none'
            stroke={fill}
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.5'
         />
         <path
            id='Path_4364'
            data-name='Path 4364'
            d='M3.75,12H15.28'
            transform='translate(-3.75 -4.5)'
            fill='none'
            stroke={fill}
            stroke-linecap='round'
            stroke-linejoin='round'
            stroke-width='1.5'
         />
      </g>
   </svg>
);

export const LeaveChatIcon = () => (
   <svg xmlns='http://www.w3.org/2000/svg' width='18.625' height='13.242' viewBox='0 0 18.625 13.242'>
      <path
         id='Path_4442'
         data-name='Path 4442'
         d='M18.923,11.886a1.212,1.212,0,1,1,0,2.418H6.449l3.3,3.065a1.149,1.149,0,0,1,0,1.71,1.374,1.374,0,0,1-1.841,0L2.447,14.007a1.153,1.153,0,0,1,0-1.823L7.909,7.111a1.373,1.373,0,0,1,1.841,0,1.149,1.149,0,0,1,0,1.71l-3.3,3.065Z'
         transform='translate(-1.8 -6.474)'
         fill='#838383'
         stroke='#f0f0f0'
         stroke-width='0.4'
      />
   </svg>
);

export const CloseChatIcon = () => (
   <svg xmlns='http://www.w3.org/2000/svg' width='18.005' height='14.01' viewBox='0 0 18.005 14.01'>
      <path
         id='Path_3500'
         data-name='Path 3500'
         d='M2389.326,2933.881c.533-.592,1.038-1.152,1.543-1.711q2.948-3.261,5.9-6.521a1.51,1.51,0,0,1,1.279-.525,1.57,1.57,0,0,1,1.177.762,1.842,1.842,0,0,1-.191,2.266q-4.259,4.723-8.526,9.437a1.54,1.54,0,0,1-2.336,0q-2.511-2.8-5.039-5.577a1.927,1.927,0,0,1-.352-2.026,1.535,1.535,0,0,1,2.057-.9,1.961,1.961,0,0,1,.587.439q1.916,2.124,3.835,4.245A1.052,1.052,0,0,1,2389.326,2933.881Z'
         transform='translate(-2382.1 -2924.615)'
         fill='#838383'
         stroke='#d8d8d8'
         stroke-width='1'
      />
   </svg>
);

export const ForwardChatIcon = () => (
   <svg xmlns='http://www.w3.org/2000/svg' width='15.617' height='12.831' viewBox='0 0 15.617 12.831'>
      <g id='people-fill' transform='translate(-6.358 -2.612)'>
         <path
            id='Path_4443'
            data-name='Path 4443'
            d='M7.091,14.578A1.056,1.056,0,0,1,6,13.53c0-1.048,1.091-4.193,5.455-4.193s5.455,3.145,5.455,4.193a1.056,1.056,0,0,1-1.091,1.048Zm4.364-6.289a3.211,3.211,0,0,0,3.273-3.145A3.211,3.211,0,0,0,11.455,2,3.211,3.211,0,0,0,8.182,5.145,3.211,3.211,0,0,0,11.455,8.289ZM7.091,5.669Z'
            transform='translate(0.358 0.612)'
            fill='#838383'
            fill-rule='evenodd'
         />
      </g>
      <path
         id='Path_4444'
         data-name='Path 4444'
         d='M2.679,9.609a.672.672,0,1,0,0,1.344H9.186l-1.722,1.7a.668.668,0,0,0,0,.951.684.684,0,0,0,.96,0l2.849-2.82a.668.668,0,0,0,0-1.014L8.424,6.954a.684.684,0,0,0-.96,0,.667.667,0,0,0,0,.951l1.722,1.7Z'
         transform='translate(3.91 -1.227)'
         fill='#838383'
         stroke='#f0f0f0'
         stroke-width='0.4'
      />
   </svg>
);

export const EditChatTagIcon = () => (
   <svg xmlns='http://www.w3.org/2000/svg' width='15.41' height='10.046' viewBox='0 0 15.41 10.046'>
      <path
         id='tag'
         d='M1.16,0A1.242,1.242,0,0,0,.337.281.862.862,0,0,0,0,.968V7.677a.863.863,0,0,0,.337.687,1.242,1.242,0,0,0,.823.281H9.795a1.13,1.13,0,0,0,.918-.428l3.1-3.895L10.712.428A1.13,1.13,0,0,0,9.795,0Z'
         transform='translate(0.7 0.7)'
         fill='none'
         stroke='#838383'
         stroke-width='1.4'
      />
   </svg>
);

export const ArchiveChatIcon = () => (
   <svg xmlns='http://www.w3.org/2000/svg' width='15.214' height='12.308' viewBox='0 0 15.214 12.308'>
      <path
         id='archive'
         d='M13.935.094H1.278A1.251,1.251,0,0,0,.372.455,1.153,1.153,0,0,0,0,1.307V3.792a.58.58,0,0,0,.179.434A.614.614,0,0,0,.625,4.4h.654v6.79a1.151,1.151,0,0,0,.372.852,1.207,1.207,0,0,0,.875.36H12.687a1.216,1.216,0,0,0,.877-.361,1.146,1.146,0,0,0,.371-.852V4.4h.654a.614.614,0,0,0,.446-.174.58.58,0,0,0,.179-.434V1.307a1.151,1.151,0,0,0-.372-.852A1.249,1.249,0,0,0,13.935.094ZM2.525,11.189V4.4H12.687v6.79Zm11.41-8.032H1.278V1.307H13.936V3.156Zm-4.16,2.6a3.587,3.587,0,0,1-2.169.693,3.587,3.587,0,0,1-2.169-.693.564.564,0,0,0-.46-.1.565.565,0,0,0-.4.216.66.66,0,0,0-.119.477.472.472,0,0,0,.238.39,5.049,5.049,0,0,0,5.825,0,.472.472,0,0,0,.238-.39.66.66,0,0,0-.119-.477.558.558,0,0,0-.4-.216A.573.573,0,0,0,9.775,5.757Z'
         transform='translate(0 -0.094)'
         fill='#838383'
      />
   </svg>
);

export const SpamChatIcon = () => (
   <svg xmlns='http://www.w3.org/2000/svg' width='13.138' height='13.697' viewBox='0 0 13.138 13.697'>
      <path
         id='spamalt'
         d='M8.228,12.2H3.41L0,8.624V3.573L3.41,0H8.228l3.41,3.573v5.05ZM6.546,1.906a.375.375,0,0,0-.108-.268.342.342,0,0,0-.256-.113H5.455a.342.342,0,0,0-.256.113.375.375,0,0,0-.108.268V7.242A.375.375,0,0,0,5.2,7.51a.342.342,0,0,0,.256.113h.727a.342.342,0,0,0,.256-.113.375.375,0,0,0,.108-.268Zm0,7.623V9.511l-.108-.249a.342.342,0,0,0-.256-.113H5.455a.342.342,0,0,0-.256.113.375.375,0,0,0-.108.268v.762a.375.375,0,0,0,.108.268.342.342,0,0,0,.256.113h.727a.342.342,0,0,0,.256-.113.375.375,0,0,0,.108-.268Z'
         transform='translate(0.75 0.75)'
         fill='none'
         stroke='#838383'
         stroke-width='1.5'
      />
   </svg>
);

import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonOK from '@components/ButtonOK/buttonOk';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import { useForm } from 'react-hook-form';

import SingleInput from '@components/_Modal/SingleInput';
import ModalButtonField from '@components/_Modal/ModalButtonField';

const useStyles = makeStyles((theme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const ModalContent = ({ onClose, onDelete, person }) => {
   const {
      control,
      watch,
      formState: { errors },
   } = useForm({
      mode: 'all',
      defaultValues: {
         team: '',
      },
   });

   const classes = useStyles();
   const { lang } = useContext(LanguageContext);

   const t = lang?.translation;
   const deleteText = person.name;
   const validation = watch('validation');

   return (
      <Grid>
         <SingleInput
            label={t?.type_to_remove.replace('{placeholder}', deleteText)}
            name='validation'
            control={control}
            editable
            required={false} // Disable html validation
            rules={{
               required: t?.please_fill_this_field,
               validation: (text) => text === deleteText,
            }}
            placeholder={deleteText}
         />
         <ModalButtonField>
            <ButtonOK
               className={classes.modalButtons}
               label={t?.comp_button_cancel}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
               onClick={onClose}
            />
            <ButtonCancel
               className={classes.modalButtons}
               label={t?.delete_team_button}
               style={{
                  color: 'white',
                  backgroundColor: '#ef5350',
                  '&:hover': {
                     backgroundColor: '#f44336',
                  },
               }}
               disabled={!(validation === deleteText)}
               onClick={() => onDelete(person.id)}
            />
         </ModalButtonField>
      </Grid>
   );
};

export default ModalContent;

import { LanguageContext } from '@helper/locale/langContext';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import React, { useContext } from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';

const useStyles = makeStyles(() => ({
   customInput: {
      width: '100%',
      height: 220,
      borderRadius: 5,
      border: '2px solid #D1D1D1',
      marginTop: '10px',
      marginBottom: '10px',
   },
   inputBar: {
      height: '85%',
      width: '100%',
      '& .MuiPopover-paper': {
         backgroundColor: 'red',
         color: 'yellow',
      },
   },
   textArea: {
      resize: 'none',
      fontSize: '14.7px',
      width: '100%',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      height: 217,
      border: 'none',
      paddingLeft: 10,
      paddingTop: 10,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      '&:focus-visible': {
         border: 'none !important',
         outline: 'none !important',
      },
      '&:focus': {
         border: 'none !important',
         outline: 'none !important',
      },
   },
   textField: {
      width: '100%',
      height: 50,
      marginLeft: '5px !important',
      marginTop: '10px !important',
      //border: '2px solid #D1D1D1',
      //borderRadius: 4,
      '& input': {
         border: 'none',
         height: 25,
      },
      '& .Mui-underline:after': {
         outline: 'none',
         border: 'none !important',
         underline: 'none !important',
      },
      '& .MuiSelect-select': {
         outline: 'none',
         fontSize: 16,
         padding: 3,
      },
      '&:active': {
         outline: 'none',
      },
      '& .MuiOutlinedInput-root': {
         border: 'none !important',
         outline: 'none !important',
         underline: 'none !important',
      },
      '& .MuiOutlinedInput-root::before': {
         border: 'none !important',
         outline: 'none !important',
         underline: 'none !important',
      },
      '& .MuiOutlinedInput-root::before:focus-visible': {
         border: 'none !important',
         outline: 'none !important',
         underline: 'none !important',
      },
      '& .MuiOutlinedInput-root:hover': {
         border: 'none !important',
         outline: 'none !important',
         underline: 'none !important',
      },
      '& .MuiOutlinedInput-root:focus-visible': {
         border: 'none !important',
         outline: 'none !important',
         underline: 'none !important',
      },
      '& .MuiOutlinedInput-root:focus': {
         border: 'none !important',
         outline: 'none !important',
         underline: 'none !important',
      },
      '& .MuiOutlinedInput-underline:after': {
         border: 'none !important',
         outline: 'none !important',
         underline: 'none !important',
      },
   },
   variables: {
      width: '100%',
      height: 50,
      padding: 10,
      marginLeft: '5px !important',
      marginTop: '10px !important',
      borderRadius: 4,
      border: '2px solid #D1D1D1',
      '& .Mui-underline:after': {
         outline: 'none',
         border: 'none !important',
      },
      '& .MuiSelect-select': {
         outline: 'none',
         fontSize: 16,
         padding: 3,
      },
      '&:active': {
         outline: 'none',
      },
      '& .MuiInput-root::before': {
         border: 'none !important',
      },
      '& .MuiInput-root::before:focus-visible': {
         border: 'none !important',
      },
      '& .MuiInput-root:hover': {
         border: 'none !important',
      },
      '& .MuiInput-root:focus-visible': {
         border: 'none !important',
      },
      '& .MuiInput-root:focus': {
         border: 'none !important',
      },
      '& .MuiInput-underline:after': {
         border: 'none !important',
         underline: 'none !important',
      },
   },
}));

const VariableSelector = ({ idx, refer, value, onChange, disabled }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const MenuProps = {
      anchorOrigin: {
         vertical: 'top',
         horizontal: 'left',
      },
      getContentAnchorEl: null,
      transformOrigin: {
         vertical: 'top',
         horizontal: 'left',
      },
      PaperProps: {
         style: {
            marginLeft: '-12px',
         },
      },
   };

   let tokens = [
      'tokens_first_name',
      'tokens_last_name',
      'tokens_email',
      'tokens_phone',
      'tokens_country',
      'tokens_city',
      'tokens_state',
      'tokens_address',
   ];

   return (
      <div style={{ display: 'flex', width: '100%' }}>
         <div>
            <p style={{ minWidth: 46, marginLeft: 10, fontSize: 19, color: '#7f7f7f' }}>{`{{${idx}}}`}</p>
         </div>
         <div style={{ width: '100%', marginLeft: 10, marginRight: 5 }}>
            {refer === 'person_params' ? (
               <FormControl className={classes.variables}>
                  <Select
                     disabled={disabled}
                     fullWidth
                     value={tokens.findIndex((x) => x === value)}
                     onChange={(e) => {
                        let token = tokens[e.target.value];
                        onChange(token);
                     }}
                     MenuProps={MenuProps}
                  >
                     <MenuItem disabled value={-1}>
                        {t['token_variables']}
                     </MenuItem>
                     <MenuItem value={0}>{t['tokens_first_name']}</MenuItem>
                     <MenuItem value={1}>{t['tokens_last_name']}</MenuItem>
                     <MenuItem value={2}>{t['tokens_email']}</MenuItem>
                     <MenuItem value={3}>{t['tokens_phone']}</MenuItem>
                     <MenuItem value={4}>{t['tokens_country']}</MenuItem>
                     <MenuItem value={5}>{t['tokens_city']}</MenuItem>
                     <MenuItem value={6}>{t['tokens_state']}</MenuItem>
                     <MenuItem value={7}>{t['tokens_address']}</MenuItem>
                  </Select>
               </FormControl>
            ) : (
               <TextField
                  disabled={disabled}
                  className={classes.textField}
                  defaultValue={value}
                  onChange={(e) => onChange(e.target.value)}
                  fullWidth
                  variant='outlined'
                  size='small'
               />
            )}
         </div>
      </div>
   );
};

export default WithUrlTranslations(VariableSelector);

import { LanguageContext } from "@helper/locale/langContext";
import { Grid, Hidden } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from "react";
import { Folder } from "../types";
import FolderHolder from "./folderHolder";
import UploadField from "./uploadField";

export enum ActiveTab {
    SelectedFolders,
    SendingFolders,
    SentFolders,
}

export interface RightBarData {
    uploading: boolean;
    selectedFolders: Folder[];
    activeTab: ActiveTab;
}

export interface RightBarProps {
    onUpdate: (leftBarData: RightBarData) => void;
    rightBarData: RightBarData;
}

const useStyles = makeStyles((theme: any) => ({
    rightPanel: {
        backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
        padding: 20,
        paddingBottom: 65,
    },
}));

const RightPanel = ({ onUpdate, rightBarData }: RightBarProps) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;

    const onUploadMedia = (folders: Folder[]) => {
        onUpdate({
            ...rightBarData,
            activeTab: ActiveTab.SelectedFolders,
            selectedFolders: [
                ...rightBarData.selectedFolders,
                ...folders,
            ]
        });
    }

    const onFolderRemove = (folder: Folder) => {
        onUpdate({
            ...rightBarData,
            selectedFolders: rightBarData.selectedFolders.filter(f => `${f.name}*_*${f.folderDate}` !== `${folder.name}*_*${folder.folderDate}`),
        });
    }

    const onTabChange = (tab: ActiveTab) => {
        onUpdate({
            ...rightBarData,
            activeTab: tab,
        });
    }

    return (
        <Hidden>
            <Grid className={classes.rightPanel} alignItems='center' direction='column' container wrap={'nowrap'}>
                <UploadField size={rightBarData.selectedFolders.map(x => x.files.map(x => x.size)).flat().reduce((a, b) => Number(a) + Number(b), 0)} onUploadData={rightBarData.uploading ? undefined : onUploadMedia} />
                <FolderHolder rightBarData={rightBarData} onTabChange={onTabChange} onFolderRemove={onFolderRemove} />
            </Grid>
        </Hidden>
    )
}

export default RightPanel;
import { Grid, Hidden } from '@material-ui/core';
import React from 'react';
import MessagesChart from './Messages';
import MessagesBox from './MessagesBox';

const ChartSection = ({ chartReport, receivedToday, sentToday }) => {
   return (
      <Grid container spacing={4} style={{ marginTop: 10 }}>
         <Grid item xs={12} lg={4}>
            <MessagesBox receivedToday={receivedToday} sentToday={sentToday} />
         </Grid>

         <Hidden smDown>
            <Grid item xs={6} lg={8} sm={12} md={12}>
               <MessagesChart chartReport={chartReport} />
            </Grid>
         </Hidden>
      </Grid>
   );
};

export default React.memo(ChartSection);

import { TableCell, TableRow } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const StyledTableCell = withStyles((theme) => ({
   head: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
   },
   body: {
      fontSize: 14,
   },
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
   root: {
      backgroundColor: 'transparent',
   },
}))(TableRow);

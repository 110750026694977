import sound1 from './sound1.mp3';
import sound2 from './sound2.mp3';
import sound3 from './sound3.mp3';
import sound4 from './sound4.mp3';
import sound5 from './sound5.mp3';
import sound6 from './sound6.mp3';
import bip from './bip.mp3';

export const getSoundByName = (soundName) => {
   if (soundName === 'sound1.mp3') return sound1;
   if (soundName === 'sound2.mp3') return sound2;
   if (soundName === 'sound3.mp3') return sound3;
   if (soundName === 'sound4.mp3') return sound4;
   if (soundName === 'sound5.mp3') return sound5;
   if (soundName === 'sound6.mp3') return sound6;
   if (soundName === 'bip.mp3') return bip;
   else {
      console.warn('sound type not found!');
   }
};

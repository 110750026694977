import React from 'react';
import { Grid } from '@material-ui/core';

const Button = ({ status, size }) => {
   const color = status === 2 ? '#00ff38' : status === 1 ? '#F81032' : '#707070';
   return (
      <Grid
         style={{
            backgroundColor: 'white',
            borderRadius: 30,
            width: size === 'small' ? 8 : 14,
            height: size === 'small' ? 8 : 14,
            paddingTop: size === 'small' ? 1 : 2,
         }}
      >
         <div
            style={{
               width: size === 'small' ? 6 : 10,
               height: size === 'small' ? 6 : 10,
               margin: 'auto',
               backgroundColor: color,
               borderRadius: 20,
            }}
         />
      </Grid>
   );
};

export default Button;

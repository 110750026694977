import React, { useContext } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@components/InputField';
import { useForm } from 'react-hook-form';
import BtnActions from './components/NextBackBtn';
import { useMutation } from '@apollo/client';
import { UPDATE_ME } from '@store/actions/person';
import { useSelector, useDispatch } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import { UPDATE_CUSTOMER_MUTATION } from '../../queries/Customer/Service/GraphQL/Customer/mutation';

const useStyles = makeStyles((theme) => ({
   main: {
      borderRadius: '8px',
      width: '100%',
      background: '#FFFFFF',
      boxShadow: '0px 1px 3px #00000029',
   },
   title: {
      padding: '1.5rem 2rem',
      borderBottom: '1px solid #DEDEDE',
      color: '#72889D',
   },
   container: {
      padding: '46px 90px',
      width: '100%',
      margin: '0 auto',
   },
}));

const Business = ({ nextPageHandler }) => {
   const classes = useStyles();
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const dispatch = useDispatch();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const {
      handleSubmit,
      control,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         companyIndustry: person.customer.companyIndustry
            ? { value: person.customer.companyIndustry, label: person.customer.companyIndustry }
            : undefined,
         companySize: person.customer.companySize
            ? { value: person.customer.companySize, label: person.customer.companySize }
            : undefined,
         favouriteProduct: person.customer.favouriteProduct
            ? { value: person.customer.favouriteProduct, label: person.customer.favouriteProduct }
            : undefined,
         howYouHearAboutUs: person.customer.howYouHearAboutUs
            ? { value: person.customer.howYouHearAboutUs, label: person.customer.howYouHearAboutUs }
            : undefined,
         purposeOfUsage: person.customer.purposeOfUsage
            ? { value: person.customer.purposeOfUsage, label: person.customer.purposeOfUsage }
            : undefined,
         yourRole: person.customer.yourRole
            ? { value: person.customer.yourRole, label: person.customer.yourRole }
            : undefined,
      },
   });

   const [updateUserInfo, { loading }] = useMutation(UPDATE_CUSTOMER_MUTATION);

   const submitHandler = async (data) => {
      const formattedData = {};
      for (const key in data) {
         formattedData[key] = data[key].value;
      }
      try {
         updateUserInfo({
            variables: {
               ...formattedData,
               _id: person.customer._id,
            },
         }).then(
            ({
               data: {
                  updatePersonCustomer: { data },
               },
            }) => {
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        customer: {
                           ...person.customer,
                           ...data,
                        },
                     },
                  },
               });
            },
         );
         nextPageHandler();
      } catch (err) {
         console.log(err, 'error');
      }
   };

   return (
      <form onSubmit={handleSubmit(submitHandler)}>
         <Box className={classes.main}>
            <Typography className={classes.title}> {t?.letUsKnowMore} </Typography>
            <Grid container spacing={3} className={classes.container}>
               <Grid item md={6} xs={12} container>
                  <Input
                     isSelect
                     isSearchable
                     selectOptions={[
                        { value: '1-5', label: '1-5' },
                        { value: '6-10', label: '6-10' },
                        { value: '11-50', label: '11-50' },
                        { value: '51-100', label: '51-100' },
                        { value: '101-200', label: '101-200' },
                        { value: '201', label: '500' },
                        { value: '501+', label: '501+' },
                     ]}
                     name='companySize'
                     label={t?.welcome_company_size + '*'}
                     control={control}
                     placeholder={t?.welcome_company_size_placeholder}
                     rules={{
                        required: true,
                     }}
                  />
               </Grid>
               <Grid item md={6} xs={12} container>
                  <Input
                     isSelect
                     isSearchable
                     selectOptions={[
                        { value: 'Automative', label: t?.welcome_options_automative },
                        { value: 'Digital agencies', label: t?.welcome_options_digital_agencies },
                        { value: 'E-commerce', label: t?.welcome_options_e_commerce },
                        { value: 'Education', label: t?.welcome_options_education },
                        { value: 'Finance & bank', label: t?.welcome_options_finance_bank },
                        { value: 'Health care', label: t?.welcome_options_health_care },
                        { value: 'Hosting & Internet', label: t?.welcome_options_hosting_internet },
                        { value: 'Non profit', label: t?.welcome_options_non_profit },
                        { value: 'Online courses', label: t?.welcome_options_online_courses },
                        { value: 'Real estate', label: t?.welcome_options_real_estate },
                        { value: 'Tourism & travel', label: t?.welcome_options_tourism_travel },
                        { value: 'Other', label: t?.welcome_options_other },
                     ]}
                     name='companyIndustry'
                     label={t?.welcome_your_company_industry + '*'}
                     control={control}
                     placeholder={t?.welcome_your_company_industry_placeholder}
                     rules={{
                        required: true,
                     }}
                  />
               </Grid>
               <Grid item md={6} xs={12} container>
                  <Input
                     isSelect
                     isSearchable
                     selectOptions={[
                        { value: 'Customer service', label: t?.welcome_options_customer_service },
                        { value: 'Sales', label: t?.welcome_options_sales },
                        { value: 'Marketing', label: t?.welcome_options_marketing },
                        { value: 'Human research', label: t?.welcome_options_human_research },
                        { value: 'Operations', label: t?.welcome_options_operations },
                        { value: 'Finance', label: t?.welcome_options_finance },
                        { value: 'Other', label: t?.welcome_options_other },
                     ]}
                     name='purposeOfUsage'
                     label={t?.welcome_purpose_of_usage + '*'}
                     control={control}
                     placeholder={t?.welcome_purpose_of_usage_placeholder}
                     rules={{
                        required: true,
                     }}
                  />
               </Grid>
               <Grid item md={6} xs={12} container>
                  <Input
                     isSelect
                     isSearchable
                     selectOptions={[
                        { value: 'Account management', label: t?.welcome_options_account_management },
                        { value: 'Business development', label: t?.welcome_options_business_development },
                        { value: 'CEO & Owner', label: t?.welcome_options_ceo_owner },
                        { value: 'Customer service', label: t?.welcome_options_customer_service },
                        { value: 'E-commerce management', label: t?.welcome_options_e_commerce_management },
                        { value: 'Engineering', label: t?.welcome_options_engineering },
                        { value: 'Finance', label: t?.welcome_options_finance },
                        { value: 'Marketing', label: t?.welcome_options_marketing },
                        { value: 'Operations', label: t?.welcome_options_operations },
                        { value: 'Project management', label: t?.welcome_options_project_management },
                        { value: 'Sales', label: t?.welcome_options_sales },
                        { value: 'Other', label: t?.welcome_options_other },
                     ]}
                     name='yourRole'
                     label={t?.welcome_your_role + '*'}
                     placeholder={t?.welcome_your_role_placeholder}
                     control={control}
                     rules={{
                        required: true,
                     }}
                  />
               </Grid>
               <Grid item md={6} xs={12} container>
                  <Input
                     isSelect
                     isSearchable
                     selectOptions={[
                        { value: 'Live chat', label: 'Live chat' },
                        { value: 'Facebook', label: 'Facebook' },
                        { value: 'Instagram', label: 'Instagram' },
                        { value: 'WhatsApp', label: 'WhatsApp' },
                        { value: 'E-mail', label: 'E-mail' },
                        { value: 'Flowbot', label: 'Flowbot' },
                        { value: 'Help center', label: t?.welcome_options_help_center },
                        { value: 'E-commerce', label: t?.welcome_options_e_commerce_management },
                     ]}
                     name='favouriteProduct'
                     label={t?.welcome_your_which_product_most_interested + '*'}
                     control={control}
                     placeholder={t?.welcome_your_which_product_most_interested_placeholder}
                     rules={{
                        required: true,
                     }}
                  />
               </Grid>
               <Grid item md={6} xs={12} container>
                  <Input
                     isSelect
                     isSearchable
                     selectOptions={[
                        { value: 'A friend or family member', label: t?.welcome_options_a_friend_or_family },
                        { value: 'Search engine', label: t?.welcome_options_search_engine },
                        { value: 'Search engine ads', label: t?.welcome_options_search_engine_ads },
                        { value: 'Social media', label: t?.welcome_options_social_media },
                        { value: 'Social media ads', label: t?.welcome_options_social_media_ads },
                        { value: 'Qpien Team', label: t?.welcome_options_qpien_team },
                        { value: 'Linkedin', label: t?.welcome_your_linkedin },
                        { value: 'Linkedin ads', label: t?.welcome_your_linkedin_ads },
                        { value: 'Other', label: t?.welcome_options_other },
                     ]}
                     name='howYouHearAboutUs'
                     label={t?.welcome_your_how_you_heard_about_us + '*'}
                     control={control}
                     placeholder={t?.welcome_your_how_you_heard_about_us_placeholder}
                     rules={{
                        required: true,
                     }}
                  />
               </Grid>
            </Grid>
         </Box>
         <BtnActions showBackBtn={false} isValid={isValid} nextBtnType='submit' loading={loading} />
      </form>
   );
};

export default Business;

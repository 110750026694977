import React, { useContext, useEffect, useRef, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Grid, Popper, Paper, Avatar } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import CachedIcon from '@mui/icons-material/Cached';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import SendIcon from '@mui/icons-material/Send';
import { Node } from 'reactflow';
import {
   FlowBlockType,
   FlowNodeType,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import { RootStateOrAny, useSelector } from 'react-redux';
import UserPlaceholder from '@assets/icons/ProfileIcons/user.svg';
import LinkIcon from '@mui/icons-material/Link';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme: Theme) =>
   createStyles({
      modal: {
         display: 'flex',
         alignItems: 'center',
         justifyContent: 'center',
         margin: '2rem 0 0 10rem',
         height: 'calc(100% - 150px)',
      },
      modalPaper: {
         borderRadius: 10,
         backgroundColor: '#fff',
         maxWidth: '380px',
         width: '380px',
         fontFamily: 'Helvetica',
         color: '#000',
         fontSize: '1rem',
         minHeight: '300px',
         height: 'calc(100% - 30px)',
         overflow: 'hidden',
      },
      header: {
         borderRadius: '10px 10px 0 0',
         display: 'flex',
         justifyContent: 'space-between',
         alignItems: 'center',
         fontSize: '24px',
         color: 'black',
         fontWeight: 'bold',
         marginTop: 0,
         width: '100%',
         paddingInline: 20,
         paddingBlock: 10,
         backgroundColor: '#157CFC',
      },
      bodyContainer: {
         display: 'flex',
         flexDirection: 'column',
         justifyContent: 'space-between',
         overflow: 'hidden',
         maxHeight: '608px',
         height: '608px',
      },
      body: {
         paddingInline: 20,
         paddingTop: 16,
         overflow: 'overlay',
         width: '100%',
         marginBottom: '10px',
      },
      input: {
         width: '100%',
         display: 'grid',
         gridTemplateColumns: '10fr 1fr',
         border: '1px solid #eee',
         padding: '10px',
      },
      avatar: {
         width: 50,
         height: 50,
         borderRadius: '50%',
      },
      miniAvatar: {
         width: 40,
         height: 40,
         padding: '3px',
         borderRadius: '50%',
      },
      miniAvatarNotUsed: {
         width: 40,
         height: 1,
         padding: '3px',
         borderRadius: '50%',
      },
      text: {
         padding: '10px',
         backgroundColor: '#eeee',
         borderRadius: '5px',
         width: 'max-content',
         maxWidth: '280px',
      },
      informativeBlock: {
         padding: '10px',
         backgroundColor: '#eeee',
         borderRadius: '5px',
         //width: 'max-content',
         maxWidth: '280px',
      },
      quickReply: {
         cursor: 'pointer',
         padding: '10px',
         backgroundColor: '#B5D2F6',
         borderRadius: '5px',
         width: 'max-content',
      },
      quickReplyInfBtn: {
         cursor: 'pointer',
         padding: '10px',
         color: '#157CFC',
         backgroundColor: '#fff',
         borderRadius: '5px',
         textAlign: 'center',
         marginTop: '5px',
      },
      httpButton: {
         border: '1px solid #E7E7E7',
         display: 'grid',
         gridTemplateColumns: '10fr 1fr',
         padding: '10px',
         marginBottom: '2px',
         borderRadius: '8px',
         backgroundColor: '#fff',
      },
      handle: {
         backgroundColor: '#fff',
         marginTop: '5px',
      },
      btnLink: {
         padding: '10px',
         backgroundColor: '#fff',
         borderRadius: '5px',
         color: '#157CFC',
         textAlign: 'center',
         marginTop: '5px',
      },
      response: {
         display: 'flex',
         alignSelf: 'flex-end',
         position: 'relative',
         width: 'max-content',
         maxWidth: '340px',
         padding: '10px',
         backgroundColor: '#157CFC',
         borderRadius: '5px',
         color: '#fff',
      },
      loop: {
         display: 'flex',
         alignSelf: 'flex-end',
         position: 'relative',
         width: 'max-content',
         maxWidth: '340px',
         padding: '10px',
         backgroundColor: '#ED4337',
         borderRadius: '5px',
         color: '#fff',
      },
      left: {
         display: 'flex',
         flexDirection: 'column',
         gap: '10px',
         marginBottom: '10px',
      },
      right: {
         display: 'flex',
         flexDirection: 'column',
         gap: '10px',
         textAlign: 'right',
         marginLeft: 'auto',
         width: 'max-content',
         marginBottom: '10px',
      },
      conditions: {
         display: 'flex',
         flexDirection: 'column',
         gap: '5px',
         marginTop: '5px',
      },
      button: {
         display: 'flex',
         justifyContent: 'space-between',
         gap: '10px',
         padding: '5px',
         backgroundColor: '#eeee',
         borderRadius: '5px',
         width: '100%',
         cursor: 'pointer',
      },
      preview: {
         display: 'flex',
         flexDirection: 'column',
         gap: '5px',
         textAlign: 'center',
         borderRadius: '5px',
         margin: '0 5px',
         height: '120px',
         padding: '12px',
         border: '0.1px solid #DDDDDD',
      },
      previewImage: {
         margin: '0 5px',
         display: 'flex',
         flexDirection: 'column',
      },
   }),
);

interface TestFlowModalProps {
   open: boolean;
   anchorElField: HTMLElement | null;
   setOpen: React.Dispatch<React.SetStateAction<boolean>>;
   setPaths: React.Dispatch<React.SetStateAction<Node[]>>;
   setSelectedPath: React.Dispatch<React.SetStateAction<{ currentNodeId: string; targetId: string }[]>>;
   selectedPath: { currentNodeId: string; targetId: string }[];
   setReplyQuestion: React.Dispatch<React.SetStateAction<{ text: string; questionId: string }[]>>;
   paths: Node[];
}

const TestFlowModal: React.FC<TestFlowModalProps> = (props) => {
   const { open, setOpen, anchorElField, paths, setPaths, setSelectedPath, setReplyQuestion, selectedPath } = props;
   const classes = useStyles(props);

   const [reply, setReply] = useState<string | null>();
   const [questionId, setQuestionId] = useState<string | null>();
   const [show, setShow] = useState(false);

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const onInputChange = (text: string) => {
      setReply(text);
   };

   const saveReply = () => {
      if (reply && questionId) {
         setShow(false);
         setReplyQuestion((prev) => [...prev, { text: reply, questionId: questionId }]);
      }

      setReply(null);
   };
   const onKeyPress = (e: { key: string }) => {
      if (e.key === 'Enter') {
         saveReply();
      }
   };

   useEffect(() => {
      const lastNode = paths[paths.length - 1];
      if (lastNode?.type === FlowNodeType.AskQuestion) {
         setShow(true);
         setQuestionId(lastNode.id);
      } else {
         setShow(false);
      }
   }, [paths]);

   const onClose = () => {
      setOpen(false);
      setPaths([]);
      setSelectedPath([]);
      setReplyQuestion([]);
      setReply(null);
   };
   const onReset = () => {
      setPaths([]);
      setSelectedPath([]);
      setReplyQuestion([]);
      setReply(null);
   };
   const handleSelectCondition = (nodeId: string, targetId: string | null | undefined) => {
      if (targetId) {
         setSelectedPath((prev) => [...prev, { currentNodeId: nodeId, targetId: targetId }]);
      }
   };

   // Scroll to the bottom when the component mounts or when the content changes
   const containerRef = useRef<HTMLDivElement>(null);
   useEffect(() => {
      scrollToBottom();
   }, [paths, show]);

   const scrollToBottom = () => {
      if (containerRef.current) {
         containerRef.current.scrollTop = containerRef.current.scrollHeight;
      }
   };

   const person = useSelector((rootReducer: RootStateOrAny) => rootReducer.personReducer.person);

   return (
      <Popper className={classes.modal} open={open} anchorEl={anchorElField}>
         <Paper className={classes.modalPaper}>
            <Grid container className={classes.header}>
               <div className={classes.avatar}>
                  <Avatar
                     src={person.customer.photo ? person.customer.photo : UserPlaceholder}
                     className={classes.avatar}
                  />
               </div>
               <div style={{ display: 'flex', gap: '10px' }}>
                  <CachedIcon style={{ cursor: 'pointer', color: '#eeee' }} onClick={onReset} />
                  <CloseIcon onClick={onClose} style={{ cursor: 'pointer', color: '#fff' }} />
               </div>
            </Grid>
            <div className={classes.bodyContainer}>
               <div className={classes.body} ref={containerRef}>
                  {paths.map((node, index) => (
                     <div key={index}>
                        {/* For Send meassages and Ask question */}
                        {node.type === FlowNodeType.SendMessage && (
                           <div>
                              <div className={classes.left}>
                                 {node.data?.flowBlocks?.map((block: IFlowBlockTemplate, blockIndex: number) => (
                                    <div>
                                       {block?.type === FlowBlockType.SendMessage && (
                                          <div
                                             style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 10fr',
                                                gap: '10px',
                                             }}
                                          >
                                             {blockIndex === 1 ? (
                                                <Avatar
                                                   src={person.customer.photo ? person.customer.photo : UserPlaceholder}
                                                   className={classes.miniAvatar}
                                                />
                                             ) : (
                                                <div
                                                   className={classes.miniAvatar}
                                                   style={{ backgroundColor: '#fff' }}
                                                ></div>
                                             )}

                                             <div className={classes.text}>{block.fields[0].value}</div>
                                          </div>
                                       )}
                                       {block?.fields[0]?.type === IFlowBlockFieldTypes.ImageUrl &&
                                          block?.fields[0]?.value && (
                                             <div
                                                style={{
                                                   display: 'grid',
                                                   gridTemplateColumns: '1fr 10fr',
                                                   gap: '10px',
                                                }}
                                             >
                                                {blockIndex === 1 ? (
                                                   <Avatar
                                                      src={
                                                         person.customer.photo ? person.customer.photo : UserPlaceholder
                                                      }
                                                      className={classes.miniAvatar}
                                                   />
                                                ) : (
                                                   <div
                                                      className={classes.miniAvatar}
                                                      style={{ backgroundColor: '#fff' }}
                                                   ></div>
                                                )}

                                                <div className={classes.previewImage}>
                                                   <img
                                                      src={block?.fields[0]?.value?.split('*_*')[0]}
                                                      alt='Preview'
                                                      style={{
                                                         width: '100%',
                                                         height: '100%',
                                                         borderRadius: '5px',
                                                         border: '1px solid #DDDDDD',
                                                      }}
                                                   />
                                                </div>
                                             </div>
                                          )}

                                       {block?.fields[0]?.type === IFlowBlockFieldTypes.VideoUrl &&
                                          block?.fields[0]?.value && (
                                             <div
                                                style={{
                                                   display: 'grid',
                                                   gridTemplateColumns: '1fr 10fr',
                                                   gap: '10px',
                                                }}
                                             >
                                                {blockIndex === 1 ? (
                                                   <Avatar
                                                      src={
                                                         person.customer.photo ? person.customer.photo : UserPlaceholder
                                                      }
                                                      className={classes.miniAvatar}
                                                   />
                                                ) : (
                                                   <div
                                                      className={classes.miniAvatar}
                                                      style={{ backgroundColor: '#fff' }}
                                                   ></div>
                                                )}

                                                <div className={classes.preview}>
                                                   <SlideshowIcon
                                                      style={{ color: 'gray', fontSize: '3rem', marginLeft: '6rem' }}
                                                   />
                                                   <span>
                                                      {block?.fields[0]?.value?.split('*_*').slice(1).join('*_*')}
                                                   </span>
                                                </div>
                                             </div>
                                          )}
                                    </div>
                                 ))}
                              </div>
                           </div>
                        )}
                        {node.type === FlowNodeType.AskQuestion && (
                           <div>
                              <div className={classes.left}>
                                 {node.data?.flowBlocks?.map((block: IFlowBlockTemplate, blockIndex: number) => (
                                    <div>
                                       {block?.type === FlowBlockType.Question && (
                                          <div
                                             style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 10fr',
                                                gap: '10px',
                                             }}
                                          >
                                             {blockIndex === 0 ? (
                                                <Avatar
                                                   src={person.customer.photo ? person.customer.photo : UserPlaceholder}
                                                   className={classes.miniAvatar}
                                                />
                                             ) : (
                                                <div
                                                   className={classes.miniAvatar}
                                                   style={{ backgroundColor: '#fff' }}
                                                ></div>
                                             )}

                                             <div className={classes.text}>{block.fields[0].value}</div>
                                          </div>
                                       )}
                                    </div>
                                 ))}
                              </div>
                           </div>
                        )}
                        {node.type === 'reply_question' && (
                           <div className={classes.right}>
                              <span className={classes.response}>{node.data?.title}</span>
                           </div>
                        )}
                        {node.type === 'loop' && (
                           <div className={classes.left}>
                              <div
                                 style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 10fr',
                                    gap: '10px',
                                 }}
                              >
                                 <Avatar
                                    src={person.customer.photo ? person.customer.photo : UserPlaceholder}
                                    className={classes.miniAvatar}
                                 />

                                 <span className={classes.loop}>{node.data?.title}</span>
                              </div>
                           </div>
                        )}
                        {/* For Condition */}
                        {node.type === FlowNodeType.Conditions && (
                           <div>
                              {selectedPath.every((path) => path.currentNodeId !== node.id) && (
                                 <div style={{ margin: '20px 0' }}>
                                    <div style={{ fontWeight: 700 }}>{t.automation_flow_choose_path}</div>
                                    <span>{t.automation_flow_choose_path_description}</span>
                                    {node.data.flowBlocks.map((block: { target: string }, indexCondition: number) => (
                                       <div className={classes.conditions}>
                                          {indexCondition !== 0 && (
                                             <div
                                                className={classes.button}
                                                onClick={() => handleSelectCondition(node.id, block.target)}
                                             >
                                                <div>
                                                   {t.atomation_flow_menu_condition} #{indexCondition}
                                                </div>
                                                <CircleOutlinedIcon
                                                   style={{
                                                      color: '#ADADAD',
                                                      width: '18px',
                                                      height: '18px',
                                                   }}
                                                />
                                             </div>
                                          )}
                                       </div>
                                    ))}
                                    {node.data.flowBlocks.map((block: { target: string }, indexCondition: number) => (
                                       <div className={classes.conditions}>
                                          {indexCondition === 0 && (
                                             <div
                                                className={classes.button}
                                                onClick={() => handleSelectCondition(node.id, block.target)}
                                             >
                                                <div>{t.atomation_flow_not_match_condition}</div>
                                                <CircleOutlinedIcon
                                                   style={{
                                                      color: '#FF5A80',
                                                      width: '18px',
                                                      height: '18px',
                                                   }}
                                                />
                                             </div>
                                          )}
                                       </div>
                                    ))}
                                 </div>
                              )}
                           </div>
                        )}
                        {/* For Welcome node */}
                        {node.type === FlowNodeType.WelcomeMessage && (
                           <div style={{ margin: '10px 0' }}>
                              {node.data?.flowBlocks?.map((block: IFlowBlockTemplate, blockIndex: number) => (
                                 <div>
                                    {block?.type === FlowBlockType.SendMessage && (
                                       <div
                                          style={{
                                             display: 'grid',
                                             gridTemplateColumns: '1fr 10fr',
                                             gap: '10px',
                                             marginTop: '5px',
                                          }}
                                       >
                                          {blockIndex === 0 ? (
                                             <Avatar
                                                src={person.customer.photo ? person.customer.photo : UserPlaceholder}
                                                className={classes.miniAvatar}
                                             />
                                          ) : (
                                             <div
                                                className={classes.miniAvatar}
                                                style={{ backgroundColor: '#fff' }}
                                             ></div>
                                          )}

                                          <div className={classes.text}>{block.fields[0].value}</div>
                                       </div>
                                    )}

                                    {block?.type === FlowBlockType.SendQuickReplyMessage && (
                                       <div>
                                          {block.fields[0]?.quickReplies?.map((quickReply, replyIndex) => (
                                             <div>
                                                <div
                                                   key={replyIndex}
                                                   style={{
                                                      display: 'grid',
                                                      gridTemplateColumns: '1fr 10fr',
                                                      gap: '10px',
                                                      marginTop: '5px',
                                                   }}
                                                >
                                                   <div
                                                      className={classes.miniAvatar}
                                                      style={{ backgroundColor: '#fff' }}
                                                   ></div>
                                                   <div
                                                      className={classes.quickReply}
                                                      style={{
                                                         opacity:
                                                            selectedPath.find(
                                                               (path) => path.currentNodeId === node.id,
                                                            ) &&
                                                            selectedPath.every(
                                                               (path) => path.targetId !== quickReply.target,
                                                            )
                                                               ? 0.5
                                                               : '',
                                                      }}
                                                      onClick={() => {
                                                         if (
                                                            !selectedPath.some((path) => path.currentNodeId === node.id)
                                                         ) {
                                                            handleSelectCondition(node.id, quickReply.target);
                                                         }
                                                      }}
                                                   >
                                                      {quickReply.title}
                                                   </div>
                                                </div>
                                             </div>
                                          ))}
                                       </div>
                                    )}
                                 </div>
                              ))}
                           </div>
                        )}
                        {/* For Button message node */}
                        {node.type === FlowNodeType.SendButtonMessage && (
                           <div style={{ margin: '10px 0', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                              {node.data?.flowBlocks?.map((block: IFlowBlockTemplate, blockIndex: number) => (
                                 <div>
                                    {block?.type === FlowBlockType.SendInformativeButtonMessage && (
                                       <div>
                                          <div
                                             style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 10fr',
                                                gap: '10px',
                                             }}
                                          >
                                             {blockIndex === 1 ? (
                                                <Avatar
                                                   src={person.customer.photo ? person.customer.photo : UserPlaceholder}
                                                   className={classes.miniAvatar}
                                                />
                                             ) : (
                                                <div
                                                   className={classes.miniAvatar}
                                                   style={{ backgroundColor: '#fff' }}
                                                ></div>
                                             )}

                                             <div className={classes.informativeBlock}>
                                                <div>{block.fields[0].value}</div>
                                                {block.fields[0].informativeButtons?.map((infBtn, replyIndex) => (
                                                   <div
                                                      className={
                                                         infBtn.type === 'url'
                                                            ? classes.btnLink
                                                            : classes.quickReplyInfBtn
                                                      }
                                                      style={{
                                                         opacity:
                                                            selectedPath.find(
                                                               (path) => path.currentNodeId === node.id,
                                                            ) &&
                                                            selectedPath.every(
                                                               (path) => path.targetId !== infBtn.target,
                                                            )
                                                               ? 0.5
                                                               : '',
                                                      }}
                                                      onClick={() => {
                                                         if (
                                                            !selectedPath.some((path) => path.currentNodeId === node.id)
                                                         ) {
                                                            handleSelectCondition(node.id, infBtn.target);
                                                         }
                                                      }}
                                                   >
                                                      {infBtn.type === 'url' ? (
                                                         <div
                                                            style={{ display: 'flex', justifyContent: 'space-between' }}
                                                         >
                                                            <div></div>
                                                            <span>
                                                               {infBtn.title && infBtn.title.split(',')[0].trim()}
                                                            </span>
                                                            <LinkIcon style={{ color: '#157CFC' }} />
                                                         </div>
                                                      ) : (
                                                         <div>{infBtn.title}</div>
                                                      )}
                                                   </div>
                                                ))}
                                             </div>
                                          </div>
                                       </div>
                                    )}

                                    {block?.type === FlowBlockType.SendMessage && (
                                       <div
                                          style={{
                                             display: 'grid',
                                             gridTemplateColumns: '1fr 10fr',
                                             gap: '10px',
                                             marginTop: '5px',
                                          }}
                                       >
                                          {blockIndex === 1 ? (
                                             <Avatar
                                                src={person.customer.photo ? person.customer.photo : UserPlaceholder}
                                                className={classes.miniAvatar}
                                             />
                                          ) : (
                                             <div
                                                className={classes.miniAvatar}
                                                style={{ backgroundColor: '#fff' }}
                                             ></div>
                                          )}

                                          <div className={classes.text}>{block.fields[0]?.value}</div>
                                       </div>
                                    )}
                                    {block?.type === FlowBlockType.SendQuickReplyMessage && (
                                       <div>
                                          {block.fields[0].quickReplies?.map((quickReply, replyIndex) => (
                                             <div>
                                                <div
                                                   key={replyIndex}
                                                   style={{
                                                      display: 'grid',
                                                      gridTemplateColumns: '1fr 10fr',
                                                      gap: '10px',
                                                      marginTop: '5px',
                                                   }}
                                                >
                                                   <div
                                                      className={classes.miniAvatar}
                                                      style={{ backgroundColor: '#fff' }}
                                                   ></div>

                                                   <div
                                                      className={classes.quickReply}
                                                      style={{
                                                         opacity:
                                                            selectedPath.find(
                                                               (path) => path.currentNodeId === node.id,
                                                            ) &&
                                                            selectedPath.every(
                                                               (path) => path.targetId !== quickReply.target,
                                                            )
                                                               ? 0.5
                                                               : '',
                                                      }}
                                                      onClick={() => {
                                                         if (
                                                            !selectedPath.some((path) => path.currentNodeId === node.id)
                                                         ) {
                                                            handleSelectCondition(node.id, quickReply.target);
                                                         }
                                                      }}
                                                   >
                                                      {quickReply.title}
                                                   </div>
                                                </div>
                                             </div>
                                          ))}
                                       </div>
                                    )}
                                    {block.fields[0]?.isButtonRequired === false &&
                                       ((block.fields[0]?.informativeButtons &&
                                          block.fields[0]?.informativeButtons[0]?.type === 'quickReply') ||
                                          (block.fields[0]?.quickReplies &&
                                             block.fields[0]?.quickReplies[0]?.type === 'quickReply')) && (
                                          <div
                                             style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 10fr',
                                                gap: '10px',
                                                marginTop: '5px',
                                             }}
                                          >
                                             <div
                                                className={classes.miniAvatarNotUsed}
                                                style={{ backgroundColor: '#fff' }}
                                             ></div>

                                             <div
                                                style={{
                                                   color: '#FFB300',
                                                   cursor: 'pointer',
                                                   opacity:
                                                      selectedPath.find((path) => path.currentNodeId === node.id) &&
                                                      selectedPath.every(
                                                         (path) =>
                                                            path.targetId !==
                                                            node.data?.flowBlocks.find(
                                                               (blk: { type: FlowBlockType }) =>
                                                                  blk.type === FlowBlockType.SendMessageConnector,
                                                            )?.target,
                                                      )
                                                         ? 0.5
                                                         : '',
                                                }}
                                                onClick={() => {
                                                   if (!selectedPath.some((path) => path.currentNodeId === node.id)) {
                                                      handleSelectCondition(
                                                         node.id,
                                                         node.data?.flowBlocks.find(
                                                            (blk: { type: FlowBlockType }) =>
                                                               blk.type === FlowBlockType.SendMessageConnector,
                                                         )?.target,
                                                      );
                                                   }
                                                }}
                                             >
                                                {t.button_not_used}
                                             </div>
                                          </div>
                                       )}
                                 </div>
                              ))}
                           </div>
                        )}
                        {/* For HTTP Request node */}
                        {node.type === FlowNodeType.HttpRequest && (
                           <div style={{ margin: '10px 0', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                              {node.data?.flowBlocks?.map((block: IFlowBlockTemplate, blockIndex: number) => (
                                 <div>
                                    {block?.type === FlowBlockType.HttpRequest && (
                                       <div>
                                          <div
                                             style={{
                                                display: 'grid',
                                                gridTemplateColumns: '1fr 10fr',
                                                gap: '10px',
                                             }}
                                          >
                                             <Avatar
                                                src={person.customer.photo ? person.customer.photo : UserPlaceholder}
                                                className={classes.miniAvatar}
                                             />

                                             <div className={classes.informativeBlock}>
                                                <div>{block.fields[0].requestMethod}</div>

                                                <div>
                                                   {block.fields[0].requestUrl && block.fields[0].requestUrl.length > 31
                                                      ? `${
                                                           block.fields[0].requestUrl &&
                                                           block.fields[0].requestUrl.slice(0, 29)
                                                        }...`
                                                      : block.fields[0].requestUrl}
                                                </div>
                                                <div
                                                   className={classes.httpButton}
                                                   style={{
                                                      opacity:
                                                         selectedPath.find((path) => path.currentNodeId === node.id) &&
                                                         selectedPath.every(
                                                            (path) => path.targetId !== block.fields[0].targetOnSuccess,
                                                         )
                                                            ? 0.5
                                                            : '',
                                                   }}
                                                   onClick={() => {
                                                      if (
                                                         !selectedPath.some((path) => path.currentNodeId === node.id)
                                                      ) {
                                                         handleSelectCondition(
                                                            node.id,
                                                            block.fields[0].targetOnSuccess,
                                                         );
                                                      }
                                                   }}
                                                >
                                                   <div>
                                                      <div>{t.automation_http_request_response_successful}</div>
                                                   </div>
                                                   <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                      <CircleOutlinedIcon
                                                         className={classes.handle}
                                                         style={{
                                                            color: '#147CFC',
                                                            width: '20px',
                                                            height: '20px',
                                                         }}
                                                      />
                                                   </div>
                                                </div>
                                                <div
                                                   className={classes.httpButton}
                                                   style={{
                                                      opacity:
                                                         selectedPath.find((path) => path.currentNodeId === node.id) &&
                                                         selectedPath.every(
                                                            (path) => path.targetId !== block.fields[0].targetOnFailure,
                                                         )
                                                            ? 0.5
                                                            : '',
                                                   }}
                                                   onClick={() => {
                                                      if (
                                                         !selectedPath.some((path) => path.currentNodeId === node.id)
                                                      ) {
                                                         handleSelectCondition(
                                                            node.id,
                                                            block.fields[0].targetOnFailure,
                                                         );
                                                      }
                                                   }}
                                                >
                                                   <div>
                                                      <div>{t.automation_http_request_response_faild}</div>
                                                   </div>
                                                   <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                      <CircleOutlinedIcon
                                                         className={classes.handle}
                                                         style={{
                                                            color: '#FF5A80',
                                                            width: '20px',
                                                            height: '20px',
                                                         }}
                                                      />
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    )}
                                 </div>
                              ))}
                           </div>
                        )}

                        {/* For Action and Delay */}
                        {(node.type === FlowNodeType.Actions ||
                           node.type === FlowNodeType.Delay ||
                           node.type === FlowNodeType.StarFlow) && (
                           <div
                              style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 gap: '10px',
                                 marginBottom: '10px',
                                 color: 'gray',
                                 textAlign: 'center',
                              }}
                           >
                              <span>
                                 {node.data?.title} {t.automation_flow_node_worked}
                              </span>
                           </div>
                        )}
                     </div>
                  ))}
               </div>
               {show && (
                  <Grid className={classes.input}>
                     <input
                        style={{ border: 'none', outline: 'none', fontSize: '1.05rem' }}
                        placeholder='Enter your message'
                        onChange={(e) => onInputChange(e.target.value)}
                        onKeyPress={onKeyPress}
                     />
                     <SendIcon
                        style={{
                           color: reply && reply.length > 0 ? '#157CFC' : 'gray',
                           cursor: reply && reply.length > 0 ? 'pointer' : 'not-allowed',
                        }}
                        onClick={reply && reply.length > 0 ? saveReply : undefined}
                     />
                  </Grid>
               )}
            </div>
         </Paper>
      </Popper>
   );
};

export default TestFlowModal;

import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import { getCustomer } from '@store/reducers/person';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import DeletePlatformModal from '@components/Modal/DeletePlatformModal';
import {
   CREATE_PLATFORM_MUTATION,
   DELETE_PLATFORM_MUTATION,
   UPDATE_PLATFORM_MUTATION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/mutation';
import { GET_TEAMS_QUERY } from '../../../../queries/Teams/query';
import { GET_PLATFORMS_QUERY } from '../../../../queries/Settings/Service/GraphQL/Platform/query';
import {
   CREATED_PLATFORM_SUBSCRIPTION,
   DELETED_PLATFORM_SUBSCRIPTION,
   UPDATED_PLATFORM_SUBSCRIPTION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/subscription';
import { PLATFORM_TYPE } from '../../../../queries/Settings/Service/GraphQL/Platform/types';
import ModalContent from './modalContent';
import QrCodeModal from './qrCodeModal';
import ActionIconButton from '@modules/Settings/SettingSpace/ActionIconButton';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// import WebWhatsAppPhonesTable from './WebWhatsAppPhonesTable';
// import AddWhatsAppPhone from './AddWhatsAppPhone';
// import QrCode from './QrCode';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   selector: {
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 10,
      paddingLeft: 3,
      height: 32,
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      //   backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   title: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },
   numberTitle: {
      fontSize: '1rem',
      fontWeight: 'medium',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   button: {
      paddingTop: 10,
      width: 144,
      height: 30,
      color: 'white',
      borderRadius: 5,
      marginRight: 10,
   },
   buttonApply: {
      backgroundColor: '#487DBF',
      '&:hover': {
         backgroundColor: '#487DBF',
      },
   },
   ButtonContainer: {
      paddingLeft: 5,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const StyledTableCell = withStyles((theme) => ({
   head: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
   },
   body: {
      fontSize: 14,
   },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
   root: {
      backgroundColor: 'transparent',
   },
}))(TableRow);

const QuickAnswers = ({ person, token }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const customer = useSelector(getCustomer);
   const initialState = {
      show: false,
      mode: null /* EDIT | NEW */,
      row: { _id: '', number: null, dialogStatus: false, qrCode: null, status: '', error: '' },
   };
   const [modalConfig, setModalConfig] = useState(initialState);
   const [confirmQrCancel, setConfirmQrCancel] = useState(-1);
   //unused
   //const [showQrModal, setShowQrModal] = useState(false);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [selectedDeleteItemId, setSelectedDeletedItemId] = useState(null);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

   // Add new Phone Modal
   const handleCloseModal = () => {
      setModalConfig(initialState);
   };

   // get whatsApp phones
   const {
      loading: whatsAppPhonesLoading,
      data: incomingData,
      client: platformsQueryClient,
   } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, customer: person.customer._id },
      fetchPolicy: 'cache-first',
      onError: (error) => {
         // on query error
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   // create person mutation function
   const [createPlatformMutation, { loading: createWhatsAppPhoneLoading }] = useMutation(CREATE_PLATFORM_MUTATION);

   // update mutation function
   const [updatePlatformMutation, { loading: updateWhatsAppPhoneLoading }] = useMutation(UPDATE_PLATFORM_MUTATION);

   // delete person mutation function
   const [deletePlatformMutation, { loading: deleteWhatsAppPhoneLoading }] = useMutation(DELETE_PLATFORM_MUTATION);

   // subscription created whats app phone
   useSubscription(CREATED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               createdPlatform: { data },
            },
         },
      }) => {
         let { getPlatforms } = client.cache.readQuery({
            query: GET_PLATFORMS_QUERY,
            variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, customer: person ? person.customer._id : '' },
         });
         client.cache.writeQuery({
            query: GET_PLATFORMS_QUERY,
            variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, customer: person ? person.customer._id : '' },
            data: {
               getPlatforms: {
                  data: [data.metaData.pages, ...getPlatforms.data],
                  success: true,
                  code: null,
                  message: null,
               },
            }, // add new data into old one
         });
      },
   });

   const updateSubsCache = (data, client, operation) => {
      let { getPlatforms } = client.cache.readQuery({
         query: GET_PLATFORMS_QUERY,
         variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, customer: person ? person.customer._id : '' },
      });

      if (operation === 'create') {
      }
      let index = getPlatforms.data.findIndex((item) => item._id.toString() === data.metaData._id.toString());
      if (index > -1) {
         let opResult;
         if (operation === 'update') {
            opResult = getPlatforms.data.map((item) => {
               if (item._id.toString() === data.metaData._id.toString()) {
                  const toWrite = {
                     ...item,
                     ...data.metaData,
                  };
                  return toWrite;
               }
               return item;
            });
         } else if (operation === 'delete') {
            opResult = getPlatforms.data.filter((item) => item._id.toString() !== data.metaData._id.toString());
         }
         client.cache.writeQuery({
            query: GET_PLATFORMS_QUERY,
            variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, customer: person ? person.customer._id : '' },
            data: { getPlatforms: { data: opResult, success: true, code: null, message: null } }, // add new data into old one
         });
      }
   };

   // subscription updated whats app phone
   useSubscription(UPDATED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedPlatform: { data },
            },
         },
      }) => {
         if (data.metaData.error && data.metaData.message) {
            enqueueSnackbar(data.metaData.message, {
               variant: 'error',
               autoHideDuration: 4000,
            });
         }
         updateSubsCache(data, client, 'update');
         if (modalConfig.row._id.toString() === data.metaData._id.toString()) {
            //if (data.metaData.qrCode === null && data.metaData.onlineStatus === 'CONNECTED') {
            //   setShowQrModal(false);
            //} else {
            //   setShowQrModal(false);
            //}
         }
      },
   });

   // subscription deleted whats app phone
   useSubscription(DELETED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               deletedPlatform: { data },
            },
         },
      }) => {
         await updateSubsCache(data, client, 'delete');
      },
   });

   // add data on submit
   const addWhatsAppPhoneSubmit = (formData) => {
      createPlatformMutation({
         variables: {
            type: PLATFORM_TYPE.WHATS_APP_WEB,
            metaData: {
               name: formData.name,
               number: formData.number,
               customer: person.customer._id,
               teams: formData.teams,
               groupChats: formData.groupChats,
               receivePhoneMsg: formData.receivePhoneMsg,
            },
         },
      })
         .then(
            ({
               data: {
                  createPlatform: {
                     data: { metaData: data },
                  },
               },
            }) => {
               handleCloseModal();
               enqueueSnackbar(`${data.pages.name} ${t?.general_information_added_successfully}`, {
                  // send success messaget
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
            });
         });
   };

   // add data on submit
   const handleActiveAndDeActiveSubmit = (data) => {
      // run create mutation
      updatePlatformMutation({ variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, metaData: data } })
         .then(
            ({
               data: {
                  updatePlatform: { data },
               },
            }) => {
               // on success event
               // close dialogs
               let stringData = data.metaData.status ? t['platform_activate'] : t['platform_de_activate'];
               enqueueSnackbar(stringData, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   // delete data on submit
   const handleDeleteWhatsAppPhone = (item, deleteType) => {
      // await all results
      deletePlatformMutation({
         variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, metaData: { _id: item, deleteType } },
      }) // run to delete mutation
         .then(
            ({
               data: {
                  deletePlatform: { data },
               },
            }) => {
               // success event
               return enqueueSnackbar(`${data.metaData.name} ${t?.general_information_deleted_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               });
            },
         )
         .catch((error) => {
            // error event
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               autoHideDuration: 3000,
            });
         });
   };

   const handleCreateNewRow = () => {
      setModalConfig({
         show: true,
         mode: 'NEW',
         row: { _id: '', number: null, dialogStatus: false, qrCode: null },
      });
   };

   const { loading: teamsLoading, data: teamsData } = useQuery(GET_TEAMS_QUERY, {
      variables: { customer: person.customer._id, pageSize: 100, page: 1},
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const loadingOrFetching =
      whatsAppPhonesLoading ||
      createWhatsAppPhoneLoading ||
      deleteWhatsAppPhoneLoading ||
      updateWhatsAppPhoneLoading ||
      teamsLoading;

   if (loadingOrFetching) {
      return <div className='loading' />;
   }

   return (
      <Fragment>
         <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
            <div style={{ display: 'flex' }}>
               <Link to='/settings/channels-and-integrations' style={{ marginTop: 5 }}>
                  <ArrowBackIcon color='disabled' />
               </Link>
               <Typography style={{ marginLeft: 10, fontSize: '1.1rem', fontWeight: 'bold', lineHeight: '35px' }}>
                  WhatApp Web
               </Typography>
            </div>
            <Grid className={classes.ButtonContainer}>
               <Typography
                  style={{
                     marginRight: 10,
                     color:
                        person.customer.usageWhatsappWebCount < person.customer.package.whatsappWebCount
                           ? 'initial'
                           : 'red',
                  }}
               >
                  {t?.team_limit} {person.customer.usageWhatsappWebCount} / {person.customer.package.whatsappWebCount}
               </Typography>
               <ButtonOK
                  onClick={handleCreateNewRow}
                  disabled={person.customer.usageWhatsappWebCount >= person.customer.package.whatsappWebCount}
                  width={200}
                  height={40}
                  fontSize='1.1rem'
                  label={t.page_settings_button_add_new}
               />
            </Grid>
         </Grid>
         <Grid>
            <TableContainer style={{ padding: 10 }}>
               <Table className={classes.table} aria-label='customized table'>
                  <TableHead>
                     <TableRow>
                        <StyledTableCell style={{ width: 300 }}>{t['page_settings_account_name']}</StyledTableCell>
                        <StyledTableCell align='left'>{t['teams']} </StyledTableCell>
                        <StyledTableCell align='left'>{t['page_settings_phone_number']} </StyledTableCell>
                        <StyledTableCell align='left'>{t['page_settings_phone_status']} </StyledTableCell>
                        <StyledTableCell align='left'>{t['page_settings_connection_status']} </StyledTableCell>
                        {/* <StyledTableCell align='left'>{t['page_settings_charge_status']} </StyledTableCell> */}
                        <StyledTableCell align='right'>{t['page_settings_process']} </StyledTableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {incomingData?.getPlatforms?.data?.map((whatsAppPhone, idx) => (
                        <StyledTableRow key={whatsAppPhone._id}>
                           <StyledTableCell component='th' scope='row'>
                              <Typography className={classes.title}>{whatsAppPhone.name}</Typography>
                           </StyledTableCell>
                           <StyledTableCell component='th' scope='row'>
                              <Typography style={{ color: whatsAppPhone.teams?.length > 0 ? 'initial' : 'red' }}>
                                 {whatsAppPhone.teams.length > 0
                                    ? whatsAppPhone.teams.length + ' ' + t?.teams_with_number
                                    : t.no_teams}
                              </Typography>
                           </StyledTableCell>
                           <StyledTableCell align='left'>
                              <Typography className={classes.numberTitle}>{whatsAppPhone.number}</Typography>
                           </StyledTableCell>
                           <StyledTableCell align='left' className={classes.coloredText}>
                              {whatsAppPhone.status ? (
                                 <Typography
                                    noWrap
                                    style={{
                                       fontSize: '0.9rem',
                                       color: '#23D935',
                                    }}
                                 >
                                    {t['page_settings_whatsapp_active']}
                                 </Typography>
                              ) : (
                                 <Typography
                                    noWrap
                                    style={{
                                       fontSize: '0.9rem',
                                       color: 'red',
                                    }}
                                 >
                                    {t['page_settings_whatsapp_not_active']}
                                 </Typography>
                              )}
                           </StyledTableCell>
                           <StyledTableCell align='left' className={classes.coloredText}>
                              <Typography
                                 noWrap
                                 style={{
                                    fontSize: '0.9rem',
                                    color: whatsAppPhone.sessionStatus ? '#23D935' : 'red',
                                 }}
                              >
                                 {whatsAppPhone.sessionStatus ? 'CONNECTED' : 'DISCONNECT'}
                              </Typography>
                           </StyledTableCell>
                           {/* <StyledTableCell align='left' className={classes.coloredText}>
                              <Typography>
                                 {whatsAppPhone.battery
                                    ? t['page_settings_whatsapp_battery'] +
                                      whatsAppPhone.battery.battery +
                                      ',' +
                                      t['page_settings_whatsapp_status'] +
                                      whatsAppPhone.battery.plugged
                                       ? t['page_settings_whatsapp_plugged']
                                       : t['page_settings_whatsapp_not_plugged']
                                    : ''}
                              </Typography>
                           </StyledTableCell> */}
                           <StyledTableCell align='right' style={{ minWidth: 250 }}>
                              <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                                 <ActionIconButton
                                    onClick={() => {
                                       handleActiveAndDeActiveSubmit({
                                          _id: whatsAppPhone._id,
                                          status: !whatsAppPhone.status,
                                       });
                                    }}
                                    tooltip={
                                       !whatsAppPhone.status
                                          ? t['page_settings_whatsapp_activate']
                                          : t['page_settings_whatsapp_deactivate']
                                    }
                                    actionType={!whatsAppPhone.status ? 'CONNECT' : 'DISCONNECT'}
                                 />
                                 {whatsAppPhone.qrCode && whatsAppPhone.status && (
                                    <Modal open={whatsAppPhone.status && whatsAppPhone.onlineStatus !== 'CONNECTED'}>
                                       <QrCodeModal
                                          row={whatsAppPhone}
                                          onCancel={(data) => {
                                             setConfirmQrCancel(idx);
                                          }}
                                       />
                                    </Modal>
                                 )}
                                 <ActionIconButton
                                    onClick={() => {
                                       setShowDeleteModal(true);
                                       setSelectedDeletedItemId(whatsAppPhone._id);
                                    }}
                                    tooltip={t['comp_button_delete']}
                                    actionType={'DELETE'}
                                 />
                              </Grid>
                           </StyledTableCell>
                        </StyledTableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </Grid>

         <Modal
            open={modalConfig.show}
            onClose={handleCloseModal}
            modalHeader={modalConfig.mode === 'NEW' && t['page_settings_modal_whatsapp_add_phone']}
         >
            <ModalContent
               whatsAppPhone={modalConfig.row}
               mode={modalConfig.mode}
               teamsData={teamsData?.getTeamList?.data?.docs}
               row={modalConfig.row}
               onAccept={modalConfig.mode === 'NEW' && addWhatsAppPhoneSubmit}
               createWhatsAppPhoneLoading={createWhatsAppPhoneLoading}
               handleCancelModal={handleCloseModal}
            />
         </Modal>

         <Modal
            paperStyle={{ width: 450 }}
            onClose={() => setConfirmQrCancel(-1)}
            open={confirmQrCancel >= 0}
            modalHeader={t?.general_message_are_your_sure}
         >
            <ModalLargeText style={{ textAlign: 'center', marginBlock: 40 }}>
               {t?.whatsapp_web_qr_qode_modal_warning}
            </ModalLargeText>
            <ModalButtonField>
               <ButtonCancel
                  label={t?.general_message_no}
                  onClick={() => setConfirmQrCancel(-1)}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
               <ButtonOK
                  label={t?.general_message_yes}
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#0060df',
                  }}
                  onClick={() => {
                     let newData = JSON.parse(JSON.stringify(incomingData.getPlatforms.data));
                     newData[confirmQrCancel].status = false;
                     platformsQueryClient.cache.writeQuery({
                        query: GET_PLATFORMS_QUERY,
                        variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, customer: person.customer._id },
                        data: {
                           getPlatforms: {
                              data: newData,
                           },
                        },
                     });
                     handleActiveAndDeActiveSubmit(newData[confirmQrCancel]);
                     setConfirmQrCancel(-1);
                  }}
               />
            </ModalButtonField>
         </Modal>

         <DeletePlatformModal
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            selectedDeleteItemId={selectedDeleteItemId}
            onComplate={handleDeleteWhatsAppPhone}
         />
      </Fragment>
   );
};

export default QuickAnswers;

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import Panel from './StatisticsPanel/statisticsPanel';
import Space from './StatisticsSpace/statisticsSpace';

const useStyles = makeStyles((theme) => ({
   content: {
      flexGrow: 1,
      height: '100vh',
      padding: 20,
      overflow: 'auto',
   },
   container: {
      margin: 0,
      height: 'calc(100% - 60px)',
   },
   appBarSpacer: theme.mixins.toolbar,
   leftPanel: {
      width: 370,
      height: '100%',
      [theme.breakpoints.down('md')]: {
         width: 70,
      },
   },
}));

const Statistics = () => {
   const classes = useStyles();
   return (
      <main className={classes.content}>
         <Grid container className={classes.container} wrap='nowrap'>
            <div className={classes.leftPanel}>
               <Panel />
            </div>
            <Grid item xs={false} sm={12} lg={10} xl={10}>
               <Space />
            </Grid>
         </Grid>
      </main>
   );
};

export default Statistics;

import { Grid } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NavBar from './NavBar';
import LeftPanel from './LeftPanel';
import ContentPanel from './ContentPanel';
import { Node, ReactFlowProvider, getNodesBounds } from 'reactflow';
import {
   FileBlockProps,
   FlowBlock,
   FlowBotDataItem,
   FlowInstanceMessageChannel,
   FlowNode,
   FlowNodeType,
   IFlowBlockTemplate,
   TempNode,
} from '../types';
import { useLazyQuery } from '@apollo/client';
import {
   GET_CONNECTED_MESSAGE_PLATFORMS_QUERY,
   GET_LIST_FLOW_NODE_QUERY,
   GET_LIST_NODE_TEMPLATES_QUERY,
   GET_LOCATION,
} from '@queries/Automation/query';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { LanguageContext } from '@helper/locale/langContext';
import { GET_CHAT_TAGS_QUERY } from '@queries/Settings/Service/GraphQL/ChatTags/query';
import {
   SET_AGENTS,
   SET_BLOCKTEMPLATES,
   SET_TAGS,
   SET_TEAMS,
   SET_NODETEMPLATES,
   SET_CUSTOM_FIELDS,
   SET_LOCATION,
   SET_CHANNEL,
   SET_PLATFORM,
} from '@store/actions/automation';
import { useDispatch } from 'react-redux';
import { GET_TEAMS_QUERY } from '@queries/Teams/query';
import { GET_LIST_BLOCK_TEMPLATES_QUERY } from '@queries/Automation/query';
import { GET_PERSON_CUSTOMERS_QUERY } from '@queries/Person/Service/GraphQL/query';
import { GET_CUSTOM_FIELDS } from '@queries/Settings/Service/GraphQL/CustomFields/query';
import { GET_PLATFORMS_QUERY } from '@queries/Settings/Service/GraphQL/Platform/query';
import { PLATFORM_TYPE } from '@modules/Chat/types';
import { createGroupNodes } from './ContentPanel/App/DynamicGrouping/utils';

const useStyles = makeStyles(() => ({
   container: {
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
   },
   navBar: {
      backgroundColor: '#FFFFFF',
      borderBottom: '1px solid #F5F5F5',
      height: '65px',
      width: '100%',
   },
   content: {
      flex: 1,
      display: 'flex',
   },
   leftPanel: {
      backgroundColor: '#FFFFFF',
      width: '400px',
      height: '100%',
      position: 'fixed',
      zIndex: 1,
   },
   contentPanel: {
      flex: 1,
      backgroundColor: '#F7F7FA',
   },
}));

interface FlowBuilderProps {
   contentNodes: Node[];
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   leftNodes: Node[];
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   selectedItem: FlowBotDataItem | undefined;
   flowBotData: FlowBotDataItem[];
   nodes: Node[];
   setNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   tempNodes: TempNode[];
   setTempNodes: React.Dispatch<React.SetStateAction<TempNode[]>>;
   flowBlock: FlowBlock[];
   setFlowBlock: React.Dispatch<React.SetStateAction<FlowBlock[]>>;
   blockState: IFlowBlockTemplate[];
   setBlockState: React.Dispatch<React.SetStateAction<IFlowBlockTemplate[]>>;
   clickedNodeId: string;
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   clickedHandleId: string;
   setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
   fileBlock: FileBlockProps[];
   setFileBlock: React.Dispatch<React.SetStateAction<FileBlockProps[]>>;
   isNewNode: string;
   setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
}

const FlowBuilder: React.FC<FlowBuilderProps> = ({
   leftNodes,
   contentNodes,
   setContentNodes,
   setLeftNodes,
   selectedItem,
   flowBotData,
   nodes,
   setNodes,
   tempNodes,
   setTempNodes,
   clickedHandleId,
   setClickedNodeId,
   clickedNodeId,
   setClickedHandleId,
   flowBlock,
   setFlowBlock,
   blockState,
   setBlockState,
   fileBlock,
   setFileBlock,
   setIsNewNode,
   isNewNode,
}) => {
   const classes = useStyles();
   const history = useHistory();
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   let { id } = useParams();
   const flowBotId = id;
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [handleToUpdate, setHandleToUpdate] = useState<string | null>(null);
   const [platformType, setPlatformType] = useState<string>();
   const [handleIndexToUpdate, setHandleIndexToUpdate] = useState<number | null>(null);
   const [isDraggable, setIsDraggable] = useState<boolean>(true);
   const [isDraft, setIsDraft] = useState<boolean>(true);
   const LIST_NODE_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listNodeTemplates);
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);
   const [isSideBarVisible, setIsSideBarVisible] = useState(false);
   const [toGroup, setToGroup] = useState<string[]>([]);

   const getNodeTypeById = (clickedNodeId: string, nodes: Node[]) => {
      const clickedNode = nodes.find((node: { id: string }) => node.id === clickedNodeId);
      return clickedNode ? clickedNode.type : null;
   };

   const nodeType = getNodeTypeById(clickedId, leftNodes);

   //Get all nodes for the currrent flow bot
   const [getNodes] = useLazyQuery(GET_LIST_FLOW_NODE_QUERY);

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, history, enqueueSnackbar, closeSnackbar]);

   useEffect(() => {
      getNodes({
         variables: {
            input: {
               customer: person.customer._id,
               flowBot: flowBotId,
            },
         },
         fetchPolicy: 'network-only',
         onCompleted: (res) => {
            const myNodes = res.listFlowNode.data;
            const filteredNodes = myNodes.filter((item: FlowNode) => item.flowBot === flowBotId);

            const nodes = filteredNodes.map((item: FlowNode) => ({
               id: item._id,
               type: item.type,
               title: item.title,
               data: {
                  groupId: item.groupId,
                  title: item.title,
                  clickedNodeId,
                  setClickedNodeId,
                  setIsNewNode,
                  flowBlocks: item.flowBlocks,
                  setClickedHandleId,
                  setHandleToUpdate,
                  setHandleIndexToUpdate,
                  fileBlock,
                  contentNodes,
                  setContentNodes,
                  setLeftNodes,
                  isNewNode,
                  isValidNode: item.isValid,
                  setIsDraggable,
                  setToGroup,
                  toGroup,
               },
               position: item.coordinates,
               flowBot: item.flowBot,
               templateId: item.templateId,
               isValid: item.isValid,
            }));

            const updatedNodesWithGroups = createGroupNodes(nodes);

            setContentNodes(updatedNodesWithGroups);
            setLeftNodes(updatedNodesWithGroups);

            // Set platform type
            const platformTyp = filteredNodes.find(
               (node: { type: FlowNodeType }) => node.type === FlowNodeType.Triggers,
            )?.flowBlocks[0]?.channelType;

            let platf = undefined;
            switch (platformTyp) {
               case 'LiveChat':
                  platf = PLATFORM_TYPE.LIVE_CHAT;
                  break;
               case 'Facebook':
                  platf = PLATFORM_TYPE.FACEBOOK;
                  break;
               case 'Instagram':
                  platf = PLATFORM_TYPE.INSTAGRAM;
                  break;
               case 'Telegram':
                  platf = PLATFORM_TYPE.TELEGRAM;
                  break;
               case 'WhatsappBusiness':
                  platf = PLATFORM_TYPE.WHATS_APP_BUSINESS;
                  break;
               case 'WhatsappWeb':
                  platf = PLATFORM_TYPE.WHATS_APP_WEB;
                  break;
               case 'Email':
                  platf = PLATFORM_TYPE.EMAIL;
                  break;
               default:
                  break;
            }
            setPlatformType(platf);
         },
      });
   }, [isNewNode]);

   useEffect(() => {
      if (botStatus === 'published') {
         setIsDraft(false);
      } else {
         setIsDraft(true);
      }
   }, [botStatus]);

   //Get Tags, Agents, Teams, CustomFields,ListNodeTemplates, ListBlocksTemplates... then store them in redux
   const dispatch = useDispatch();
   const [getCountries] = useLazyQuery(GET_LOCATION);
   const [getBlockTemplate] = useLazyQuery(GET_LIST_BLOCK_TEMPLATES_QUERY);
   const [getNodesTemplate] = useLazyQuery(GET_LIST_NODE_TEMPLATES_QUERY);
   const [getPerson] = useLazyQuery(GET_PERSON_CUSTOMERS_QUERY);
   const [getTeams] = useLazyQuery(GET_TEAMS_QUERY);
   const [getTags] = useLazyQuery(GET_CHAT_TAGS_QUERY);
   const [getCustomField] = useLazyQuery(GET_CUSTOM_FIELDS);
   const [getChannels] = useLazyQuery(GET_CONNECTED_MESSAGE_PLATFORMS_QUERY);
   const [getPlatforms] = useLazyQuery(GET_PLATFORMS_QUERY);

   useEffect(() => {
      getPerson({
         variables: { customer: person.customer._id, notSelectBot: true, page: 1, pageSize: 100 },
         fetchPolicy: 'network-only',
         onCompleted: (res) => {
            let options: any = [];
            const response = res?.customerPersons?.data?.docs;

            response.map((item: { _id: string; name: string; surname: string }) => {
               options.push({
                  value: item._id,
                  label: item.name + ' ' + item.surname,
               });
            });
            dispatch({ type: SET_AGENTS, payload: options });
         },
      });
      getTeams({
         variables: { customer: person.customer._id, me: person?._id, page: 1, pageSize: 100 },
         fetchPolicy: 'network-only',
         onCompleted: (res) => {
            let options: any = [];
            const response = res?.getTeamList?.data?.docs;

            response.map((item: { _id: string; teamName: string }) => {
               options.push({
                  value: item._id,
                  label: item.teamName,
               });
            });

            dispatch({ type: SET_TEAMS, payload: options });
         },
      });

      getTags({
         variables: { customer: person.customer._id, notSelectBot: true, page: 1, pageSize: 100 },
         fetchPolicy: 'network-only',
         onCompleted: (res) => {
            let options: any = [];
            const response = res?.getChatTagList?.data?.docs;
            response.map((item: { _id: string; name: string }) => {
               options.push({
                  value: item._id,
                  label: item.name,
               });
            });
            dispatch({ type: SET_TAGS, payload: options });
         },
      });

      getCustomField({
         variables: {
            customer: person.customer._id,
         },
         fetchPolicy: 'network-only',
         onCompleted: (res) => {
            const response = res?.getCustomFields?.data;
            dispatch({ type: SET_CUSTOM_FIELDS, payload: response });
         },
      });

      if (LIST_BLOCK_TEMPLATE.length === 0) {
         getBlockTemplate({
            variables: { input: {} },
            fetchPolicy: 'network-only',
            onCompleted: (res) => {
               const response = res.listBlockTemplates.data;
               dispatch({ type: SET_BLOCKTEMPLATES, payload: response });
            },
         });
      }

      if (LIST_NODE_TEMPLATE.length === 0) {
         getNodesTemplate({
            variables: {},
            fetchPolicy: 'network-only',
            onCompleted: (res) => {
               const response = res?.listNodeTemplates?.data;
               dispatch({ type: SET_NODETEMPLATES, payload: response });
            },
         });
      }

      getCountries({
         variables: {
            type: 'country',
         },
         fetchPolicy: 'cache-first',
         onCompleted: (res) => {
            const response = res.locations.data;
            const flattenedResponse = response.flat();

            if (Array.isArray(flattenedResponse) && flattenedResponse.length > 0) {
               const firstItem = flattenedResponse[0];

               if ('translations' in firstItem && typeof firstItem.translations === 'object') {
                  const trTranslation = firstItem.translations['tr'];

                  if (trTranslation) {
                     const options = flattenedResponse.map((item) => ({
                        label: t.atomation_flow_language === 'tr' ? item.translations['tr'] : item.name,
                        value: item.iso2,
                     }));

                     dispatch({ type: SET_LOCATION, payload: options });
                  } else {
                     console.error('Translation for not found in the translations object.');
                  }
               } else {
                  console.error('Invalid translations property or translations is not an object.');
               }
            } else {
               console.error('Invalid response format or empty response.');
            }
         },
      });

      getChannels({
         variables: {
            customerId: person.customer._id,
         },
         fetchPolicy: 'cache-first',
         onCompleted: (res) => {
            const response = [res.getConnectedMessagePlatforms.data];

            let options: any = [
               {
                  value: FlowInstanceMessageChannel.LiveChat,
                  label: t.page_settings_typo_platforms_live_chat,
               },
            ];

            response.map((item) => {
               if (item.whatsappWeb === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.WhatsappWeb,
                     label: t.page_settings_typo_platforms_whats_app_web,
                  });
               }
               if (item.whatsappBusiness === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.WhatsappBusiness,
                     label: t.atomation_flow_whatsappbussiness,
                  });
               }
               if (item.facebook === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.Facebook,
                     label: t.package_start_feature_4,
                  });
               }
               if (item.instagram === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.Instagram,
                     label: t.package_start_feature_5,
                  });
               }
               if (item.email === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.Email,
                     label: t.pre_load_input_label_email,
                  });
               }
               if (item.telegram === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.Telegram,
                     label: t.page_settings_typo_platforms_telegram,
                  });
               }
            });
            dispatch({ type: SET_CHANNEL, payload: options });
         },
      });
   }, []);

   useEffect(() => {
      if (platformType !== undefined) {
         getPlatforms({
            variables: { type: platformType, customer: person.customer._id },
            fetchPolicy: 'network-only',
            onCompleted: (res) => {
               dispatch({ type: SET_PLATFORM, payload: res.getPlatforms.data });
            },
         });
      }
   }, [platformType]);

   return (
      <ReactFlowProvider>
         <Grid className={classes.container}>
            <div className={classes.navBar}>
               <NavBar
                  selectedItem={selectedItem}
                  flowBotData={flowBotData}
                  isDraft={isDraft}
                  setIsDraft={setIsDraft}
                  contentNodes={contentNodes}
               />
            </div>
            <div className={classes.content}>
               {nodeType !== null &&
                  !isSideBarVisible &&
                  nodeType !== 'NodeMenu' &&
                  botStatus === 'draft' &&
                  !clickedNodeId.startsWith('group_') && (
                     <div className={classes.leftPanel}>
                        <LeftPanel
                           leftNodes={leftNodes}
                           contentNodes={contentNodes}
                           setLeftNodes={setLeftNodes}
                           setContentNodes={setContentNodes}
                           nodes={nodes}
                           setNodes={setNodes}
                           clickedNodeId={clickedNodeId}
                           flowBlock={flowBlock}
                           setFlowBlock={setFlowBlock}
                           blockState={blockState}
                           setBlockState={setBlockState}
                           fileBlock={fileBlock}
                           setFileBlock={setFileBlock}
                           tempNodes={tempNodes}
                           setTempNodes={setTempNodes}
                           setIsNewNode={setIsNewNode}
                           isNewNode={isNewNode}
                        />
                     </div>
                  )}
               <div className={classes.contentPanel}>
                  <ContentPanel
                     setIsSideBarVisible={setIsSideBarVisible}
                     contentNodes={contentNodes}
                     clickedNodeId={clickedNodeId}
                     setClickedNodeId={setClickedNodeId}
                     clickedHandleId={clickedHandleId}
                     setContentNodes={setContentNodes}
                     setClickedHandleId={setClickedHandleId}
                     setIsNewNode={setIsNewNode}
                     isNewNode={isNewNode}
                     setHandleToUpdate={setHandleToUpdate}
                     handleToUpdate={handleToUpdate}
                     handleIndexToUpdate={handleIndexToUpdate}
                     isDraggable={isDraggable}
                     setIsDraggable={setIsDraggable}
                     blockState={blockState}
                     setLeftNodes={setLeftNodes}
                     setBlockState={setBlockState}
                     isDraft={isDraft}
                     setIsDraft={setIsDraft}
                     setToGroup={setToGroup}
                     toGroup={toGroup}
                  />
               </div>
            </div>
         </Grid>
      </ReactFlowProvider>
   );
};

export default FlowBuilder;

import {
   E_COMMERCE_PRODUCTS,
   E_COMMERCE_STORE,
   E_COMMERCE_ORDERS,
   DELETE_STORE,
   E_COMMERCE_CUSTOMERS,
   SET_E_COMMERCE_TOTAL_NUMBER_OF_ORDERS,
   SET_E_COMMERCE_TOTAL_NUMBER_OF_CUSTOMERS,
   E_COMMERCE_TYPE,
   E_COMMERCE_ALL_SHIPPING_METHODS,
   UPDATE_E_COMMERCE_CUSTOMER,
   E_COMMERCE_ALL_PAYMENT_TYPES,
} from '../actions/ecommerce'; // action types
// default initial state
const INITIAL_STATE = {
   eCommerceType: null,
   eCommerceShippingMethods: null,
   eCommercePaymentTypes: null,
   eCommerceStore: null,
   eCommerceProducts: null,
   eCommerceOrders: null,
   eCommerceTotalNumberOfOrders: null,
   eCommerceCustomers: null,
   eCommerceTotalNumberOfCustomers: null,
};

export default (state = INITIAL_STATE, action = {}) => {
   switch (action.type) {
      case DELETE_STORE:
         return {
            ...state,
            eCommerceProducts: null,
         };
      case E_COMMERCE_TYPE:
         return {
            ...state,
            eCommerceType: action.payload.eCommerceType,
         };
      case E_COMMERCE_ALL_SHIPPING_METHODS:
         return {
            ...state,
            eCommerceShippingMethods: action.payload.eCommerceShippingMethods,
         };
      case E_COMMERCE_ALL_PAYMENT_TYPES:
         return {
            ...state,
            eCommercePaymentTypes: action.payload.eCommercePaymentTypes,
         };
      case SET_E_COMMERCE_TOTAL_NUMBER_OF_ORDERS:
         return {
            ...state,
            eCommerceTotalNumberOfOrders: action.payload.eCommerceTotalNumberOfOrders,
         };
      case SET_E_COMMERCE_TOTAL_NUMBER_OF_CUSTOMERS:
         return {
            ...state,
            eCommerceTotalNumberOfCustomers: action.payload.eCommerceTotalNumberOfCustomers,
         };
      case E_COMMERCE_PRODUCTS:
         return {
            ...state,
            eCommerceProducts: action.payload.eCommerceProducts,
         };
      case E_COMMERCE_STORE:
         return {
            ...state,
            eCommerceStore: action.payload.eCommerceStore,
         };
      case E_COMMERCE_ORDERS:
         return {
            ...state,
            eCommerceOrders: action.payload.eCommerceOrders,
         };
      case E_COMMERCE_CUSTOMERS:
         return {
            ...state,
            eCommerceCustomers: action.payload.eCommerceCustomers,
         };
      case UPDATE_E_COMMERCE_CUSTOMER:
         return {
            ...state,
            eCommerceCustomers: {
               ...state.eCommerceCustomers,
               docs: state?.eCommerceCustomers?.docs?.map((customer) => {
                  if (customer.id === action.payload.customer.id) {
                     return action.payload.customer;
                  }
                  return customer;
               }),
            },
         };

      default:
         return state;
   }
};

export const getECommerceProducts = (state) => state.eCommerceReducer.eCommerceProducts;
export const getECommerceStore = (state) => state.eCommerceReducer.eCommerceStore;
export const getECommerceOrders = (state) => state.eCommerceReducer.eCommerceOrders;
export const getECommerceCustomers = (state) => state.eCommerceReducer.eCommerceCustomers;

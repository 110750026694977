import React, { FC, useContext, useMemo } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import 'moment/locale/tr';
import FullAvatar from '@components/FullAvatar';
import { getPersonActivities } from '@store/reducers/personActivity';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getChatFilters } from '@store/reducers/chat';
import getFlagEmoji from '../../../../utils/getCountryFlagWithCountryCode';

const useStyles = makeStyles((theme) => ({
   root: {
      paddingBottom: 2,
      paddingTop: 2,
      cursor: 'pointer',
      flexGrow: 1,
   },
}));

interface ChatItemProps {
   myId: string;
   teams: any;
   myChat: any;
   renderAgentPhoto?: boolean;
   isVisibleIconTooltip: boolean;
   setIsVisibleIconTooltip: React.Dispatch<React.SetStateAction<boolean>>;
   agentStatus: any;
}

function isJsonString(str: string) {
   if (!str) return false;
   try {
      JSON.parse(str);
   } catch (e) {
      return false;
   }
   return true;
}

const ChatItem: FC<ChatItemProps> = ({
   myChat,
   teams,
   myId,
   renderAgentPhoto,
   isVisibleIconTooltip,
   setIsVisibleIconTooltip,
   agentStatus,
}) => {
   const personActivities = useSelector(getPersonActivities);
   const chatFilters = useSelector(getChatFilters);
   const person = useSelector((reducer: any) => reducer.personReducer.person);
   const classes = useStyles();
   let participant = myChat.participants.filter((participant: any) => participant._id.toString() !== myId)[0];
   let participant1 = myChat.participants[1];

   const {
      lang: { shortTitle, translation: t },
   }: any = useContext(LanguageContext);
   const history = useHistory();
   const isSelected = history.location.pathname.startsWith(`/chat/${myChat._id}`);
   // Check last message type
   let lastMessageType;
   let isMedia;
   let textStatusColor = '';
   let subject = myChat?.lastMessage?.subject;

   // if last message is note
   if (myChat?.lastMessage?.type === 4) {
      lastMessageType = t?.chat_page_added_note + ': ' + myChat?.lastMessage?.content;
      subject = t?.chat_page_no_subject;
   } else if (myChat?.lastMessage?.type === 2) {
      lastMessageType = t?.chat_page_you_send_product_card;
      subject = t?.chat_page_you_send_product_card;
   } else if (t[myChat?.lastMessage?.content]) {
      lastMessageType = t[myChat?.lastMessage?.content];
   } else {
      // Includes text and media
      if (myChat?.lastMessage?.content && myChat?.lastMessage?.media?.length > 0) {
         isMedia = true;
         lastMessageType = myChat?.lastMessage?.content;
      }
      if (myChat?.lastMessage?.subject && myChat?.lastMessage?.media?.length > 0) {
         isMedia = true;
         subject = myChat?.lastMessage?.subject;
         if (!subject) {
            subject = t?.chat_page_no_subject;
         }
      }
      // includes only media
      else if (!myChat?.lastMessage?.content && myChat?.lastMessage?.media?.length > 0) {
         isMedia = true;
         lastMessageType = 'Media';
         if (!subject) {
            subject = t?.chat_page_no_subject;
         }
      } else if (!myChat?.lastMessage?.subject && myChat?.lastMessage?.media?.length > 0) {
         isMedia = true;
         subject = 'Media';
      }
      // only text
      else {
         lastMessageType = myChat?.lastMessage?.content;
         if (!subject) {
            subject = t?.chat_page_no_subject;
         }

         // live chat custom messages
         if (isJsonString(myChat?.lastMessage?.content)) {
            const contentObj = JSON.parse(myChat?.lastMessage?.content);
            if (contentObj) {
               if (contentObj.reaction) {
                  lastMessageType = t?.chat_page_received_review;
               } else if (contentObj.name || contentObj.address || contentObj.phone || contentObj.email) {
                  lastMessageType = t?.chat_page_received_contact_info;
               }
            }
         }
      }
   }

   let textStatus = '';
   if (myChat.status === 0) {
      textStatus = myChat.joinStatus ? t?.page_chat_typo_filter_title_active : t?.page_chat_typo_filter_title_waiting;
      textStatusColor = '#1ac31a';
   } else if (myChat.status === 1) {
      textStatus = t?.page_chat_typo_filter_title_closed;
      textStatusColor = '#F2B544';
   } else if (myChat.status === 2) {
      textStatus = t?.page_chat_typo_filter_title_resolved;
      textStatusColor = '#499cf2';
   } else if (myChat.status === 3) {
      textStatus = t?.page_chat_typo_filter_title_spam;
      textStatusColor = '#577590';
   } else if (myChat.status === 6) {
      textStatus = t?.page_chat_typo_filter_title_bot_is_joined;
      textStatusColor = '#7d44e1';
   }
   const socialMediaIconColor = useMemo(() => {
      if (teams?.length === 1) return null;
      if (myChat.teams && Array.isArray(myChat.teams) && myChat.teams.length > 0) {
         if (chatFilters?.teams?.value === 'ALL') {
            if (myChat.teams.length > 1) {
               return '#333';
            } else {
               const team = myChat?.teams[0];
               return team?.color || null;
            }
         } else {
            const team = myChat?.teams.find((t: any) => t._id === chatFilters?.teams?.value);
            if (team) return team?.color || null;
            if (!team) {
               if (myChat.teams.length > 1) {
                  return '#333';
               } else {
                  const t = myChat?.teams[0];
                  return t?.color || null;
               }
            }
         }
      }
      return null;
   }, [myChat.teams, teams, chatFilters?.teams?.value]);

   return (
      <Link to={`/chat/${myChat._id}`}>
         <Grid xs={12} container justifyContent='space-between' className={classes.root}>
            <FullAvatar
               isVisibleIconTooltip={isVisibleIconTooltip}
               setIsVisibleIconTooltip={setIsVisibleIconTooltip}
               online={personActivities.some((e: any) => e.personString == participant._id)}
               isSelectedItem={isSelected}
               image={participant.photo}
               messageSeenStatus={myChat?.lastMessage?.ack}
               socialMedia={myChat.type} // Type of message (LIVE_CHAT, EMAIL, TELEGRAM...)
               socialMediaIconColor={socialMediaIconColor}
               name={participant.name}
               name1={participant1?.name}
               countryFlag={myChat?.participants[0]?.countryCode}
               surname={participant.surname}
               surname1={participant1?.surname}
               tag={myChat.tag}
               joinStatus={myChat.joinStatus}
               lastSeen={moment(myChat?.lastMessage?.createdAt).locale(shortTitle.toString()).fromNow(true)}
               unreadMessagesCount={myChat?.unReadCount}
               isBold={!myChat?.lastMessage.personStatus}
               lastMessage={lastMessageType}
               subject={subject}
               chatId={myChat._id}
               isMedia={isMedia}
               chatColor={myChat.color}
               agentPhoto={myChat.participants[1]?.photo}
               renderAgentPhoto={renderAgentPhoto}
               textStatus={textStatus}
               textStatusColor={textStatusColor ? textStatusColor : undefined}
               agentStatus={agentStatus}
               person={person}
            />
         </Grid>
      </Link>
   );
};

export default React.memo(ChatItem);

import {
   SettingsFacebookIcon,
   SettingsGmailIcon,
   SettingsGoogleIcon, SettingsHepsiburadaIconColor,
   SettingsInstagramIcon,
   SettingsN11Icon,
   SettingsOutlookIcon,
   SettingsTelegramIcon, SettingsTrendyolIconColor,
   SettingsTwitterIcon,
   SettingsWeChatIcon,
   SettingsWhatsappBusinessIcon,
   SettingsWooCommerceColor,
   ShopifyIcon,
   WhatsappIcon
} from '@assets/icons/SettingsIcons/settingsIcons';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Mail, Sms } from '@material-ui/icons';
import SettingBody from '@modules/Settings/SettingSpace/shared-components/settingBody';
import SettingHeader from '@modules/Settings/SettingSpace/shared-components/settingHeader';
import clsx from 'clsx';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import IntegrationCard from './card';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      height: '100%',
   },
   formContainer: {
      padding: 20,
   },
   inputRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: 10,
   },
   textField: {
      width: 100,
      margin: '0 !important',
   },
   TextFieldHolder: {
      '& .MuiFormControl-root': {
         margin: '0px !important',
      },
   },
   helperText: {
      position: 'absolute',
      left: -10,
      top: -20,
      width: '300%',
   },
   itemIcons: {
      width: 40,
      height: 40,
   },
   wpBusinesIcon: {
      fill: '#25d366',
   },
   telegramIcon: {
      fill: '#2daae0',
   },
   facebookIcon: {
      fill: '#3b5998',
   },
}));

const ChannelsAndIntegrations = () => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   const customer = useSelector((state) => state.personReducer.person.customer);

   const categories = [
      {
         langCode: 'category_channels',
         items: [
            {
               title: 'WhatsApp',
               type: 'free',
               description: 'sub_category_whatsapp_description',
               icon: <WhatsappIcon className={classes.itemIcons} />,
               url: '/settings/platforms/whatsapp-web',
               connected: !!customer.usageWhatsappWebCount,
               soon: false,
            },
            {
               title: 'WhatsApp Business',
               type: 'add_on',
               description: 'sub_category_whatsapp_business_description',
               icon: <SettingsWhatsappBusinessIcon className={clsx(classes.itemIcons, classes.wpBusinesIcon)} />,
               url: '/settings/platforms/whatsapp-business',
               connected: !!customer.usageWhatsappBusinessCount,
               soon: false,
            },
            {
               title: 'Telegram',
               type: 'add_on',
               description: 'sub_category_telegram_description',
               icon: <SettingsTelegramIcon className={clsx(classes.itemIcons, classes.telegramIcon)} />,
               url: '/settings/platforms/telegram', 
               connected: !!customer.usageTelegramCount,
               soon: false,
            },
            {
               title: 'WeChat',
               type: 'add_on',
               description: 'sub_category_wechat_description',
               icon: <SettingsWeChatIcon className={classes.itemIcons} />,
               connected: false,
               soon: true,
            },
            {
               title: 'SMS',
               type: 'add_on',
               description: 'sub_category_sms_description',
               icon: <Sms style={{ fill: '#0060df' }} className={classes.itemIcons} />,
               connected: false,
               soon: true,
            },
            {
               title: 'Google Business Messages',
               type: 'add_on',
               description: 'sub_category_google_business_messages_description',
               icon: <SettingsGoogleIcon className={classes.itemIcons} />,
               connected: false,
               soon: true,
            },
            {
               title: 'Facebook',
               type: 'free',
               url: '/settings/platforms/facebook',
               description: 'sub_category_facebook_description',
               icon: <SettingsFacebookIcon className={clsx(classes.itemIcons, classes.facebookIcon)} />,
               connected: !!customer.usageFacebookPageCount,
               soon: false,
            },
            {
               title: 'Instagram',
               type: 'free',
               url: '/settings/platforms/instagram',
               description: 'sub_category_instagram_description',
               icon: <SettingsInstagramIcon className={classes.itemIcons} />,
               connected: !!customer.usageInstagramCount,
               soon: false,
            },
            {
               title: 'Twitter',
               type: 'add_on',
               description: 'sub_category_twitter_description',
               icon: <SettingsTwitterIcon className={classes.itemIcons} />,
               connected: !!customer.usageTwitterCount,
               soon: true,
            },
            {
               title: 'Emails',
               type: 'free',
               description: 'sub_category_imap_description',
               icon: <Mail className={classes.itemIcons} />,
               url: '/settings/platforms/email', 
               connected: !!customer.usageEmailCount,
               soon: false,
            },
         ],
      },
      {
         langCode: 'category_ecommerce',
         items: [
            {
               title: 'Shopify',
               type: 'free',
               url: '/settings/e-commerce/shopify',
               description: 'sub_category_shopify_description',
               icon: <ShopifyIcon fill='#88c323' className={classes.itemIcons} />,
               connected: false,
               soon: false,
            },
            {
               title: 'WooCommerce',
               type: 'free',
               url: '/settings/e-commerce/woocommerce',
               description: 'sub_category_woocommerce_description',
               icon: <SettingsWooCommerceColor className={classes.itemIcons} />,
               connected: false,
               soon: false,
            },
            {
               title: 'Ikas',
               type: 'free',
               url: '/settings/e-commerce/ikas',
               description: 'sub_category_ikas_description',
               icon: <img alt='ideasoft' className={classes.itemIcons} src={'/ecommerce_logo/ikas_logo.png'} />,
               connected: false,
               soon: false,
            },
            {
               title: 'IdeaSoft',
               type: 'free',
               url: '/settings/e-commerce/ideasoft',
               description: 'sub_category_ideasoft_description',
               icon: <img alt='ideasoft' className={classes.itemIcons} src={'/ecommerce_logo/ideasoft_logo.png'} />,
               connected: false,
               soon: false,
            },
            {
               title: 'T-Soft',
               type: 'free',
               url: '/settings/e-commerce/tsoft',
               description: 'sub_category_tsoft_description',
               icon: <img alt='tsoft' className={classes.itemIcons} src={'/ecommerce_logo/tsoft_logo.png'} />,
               connected: false,
               soon: false,
            },
            {
               title: 'Ticimax',
               type: 'free',
               url: '/settings/e-commerce/ticimax',
               description: 'sub_category_ticimax_description',
               icon: <img alt='ticimax' className={classes.itemIcons} src={'/ecommerce_logo/ticimax_logo.webp'} />,
               connected: false,
               soon: false,
            },
            {
               title: 'Faprika',
               type: 'free',
               url: '/settings/e-commerce/faprika',
               description: 'sub_category_faprika_description',
               icon: <img alt='faprika' className={classes.itemIcons} src={'/ecommerce_logo/faprika_logo.png'} />,
               connected: false,
               soon: false,
            },
         ],
      },
      {
         langCode: 'category_marketplace',
         items: [
            {
               title: 'Hepsiburada',
               type: 'free',
               url: '/settings/platforms/hepsiburada-questions',
               description: 'sub_category_hepsiburada_description',
               icon: <SettingsHepsiburadaIconColor className={classes.itemIcons} />,
               connected: false,
               soon: false,
            },
            {
               title: 'Trendyol',
               type: 'free',
               url: '/settings/platforms/trendyol-questions',
               description: 'sub_category_trendyol_description',
               icon: <SettingsTrendyolIconColor className={classes.itemIcons} />,
               connected: false,
               soon: false,
            },
            {
               title: 'N11',
               type: 'free',
               url: '/settings/platforms/n11-questions',
               description: 'sub_category_n11_description',
               icon: <SettingsN11Icon className={classes.itemIcons} />,
               connected: false,
               soon: false,
            },
         ],
      },
   ];

   return (
      <div className={classes.container}>
         <SettingHeader title={t?.channels_and_integrations} />
         <SettingBody>
            <div className={classes.formContainer}>
               {categories.map((category) => (
                  <div key={category.langCode} style={{ marginTop: 20 }}>
                     <Typography style={{ marginLeft: 10, fontSize: '1.2rem', fontWeight: 'bold' }}>
                        {t?.[category.langCode]}
                     </Typography>
                     <Typography style={{ marginLeft: 10, fontSize: '1rem' }}>
                        {t[category.langCode + '_description']}
                     </Typography>
                     <Grid container spacing={2} style={{ marginTop: 10 }}>
                        {category.items.map((platform, index) => (
                           <IntegrationCard
                              key={index}
                              title={platform.title}
                              description={t[platform.description]}
                              icon={platform.icon}
                              url={platform.url}
                              connected={platform.connected}
                              soon={platform.soon}
                              type={platform.type}
                           />
                        ))}
                     </Grid>
                  </div>
               ))}
            </div>
         </SettingBody>
      </div>
   );
};

export default ChannelsAndIntegrations;

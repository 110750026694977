import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, LinearProgress, Typography } from '@material-ui/core';
import { LanguageContext } from '../../../../../../../../helper/locale/langContext';
import { useForm } from 'react-hook-form';
import { CustomerProps } from '../types';
import CustomersListItem from '../CustomersListItem';
import MOCK_CUSTOMER_LIST from '../MOCK_CUSTOMER_LIST';
import Input from '../../../../../../../../components/InputField';
import { useDispatch, useSelector } from 'react-redux';
import { GET_E_COMMERCE_CUSTOMERS_QUERY } from '@queries/Settings/Service/GraphQL/ECommercePlatform/query';
import { E_COMMERCE_CUSTOMERS, SET_E_COMMERCE_TOTAL_NUMBER_OF_CUSTOMERS } from '@store/actions/ecommerce';
import { useSnackbar } from 'notistack';
import { useQuery } from '@apollo/client';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowResize from '@hooks/useWindowResize';

const useStyles = makeStyles(() => ({
   listAndInputs: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
   },
   inputsContainer: {
      marginTop: -15,
      marginBottom: -15,
      marginLeft: 8,
      marginRight: 8,
   },
   listContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 3,
   },
   linearProgressContainer: {
      height: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
   },
}));

interface AllCustomersProps {
   openCustomerDetail: (customer: CustomerProps) => void;
   header?: React.ReactElement | undefined;
}

const AllCustomers: FC<AllCustomersProps> = ({ openCustomerDetail, header }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [fetchingMore, setFetchingMore] = useState(false);
   const [searchText, setSearchText] = useState('');
   const eCommerceCustomers = useSelector((rootReducer: any) => rootReducer.eCommerceReducer.eCommerceCustomers);
   const me = useSelector((rootReducer: any) => rootReducer.personReducer.person);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const dispatch = useDispatch();
   const { height } = useWindowResize();

   const { watch, control } = useForm({
      mode: 'all',
      defaultValues: {
         searchCustomer: '',
      },
   });

   const watchSearchCustomer = watch('searchCustomer');

   useEffect(() => {
      const timer = setTimeout(() => {
         setSearchText(watchSearchCustomer);
      }, 500);
      return () => clearTimeout(timer);
   }, [watchSearchCustomer]);

   const { loading, fetchMore } = useQuery(GET_E_COMMERCE_CUSTOMERS_QUERY, {
      variables: {
         customer: me.customer._id,
         page: 1,
         query: searchText,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
         dispatch({
            type: E_COMMERCE_CUSTOMERS,
            payload: { eCommerceCustomers: data.getECommerceCustomers.data },
         });
         if (searchText === '') {
            dispatch({
               type: SET_E_COMMERCE_TOTAL_NUMBER_OF_CUSTOMERS,
               payload: { eCommerceTotalNumberOfCustomers: data?.getECommerceCustomers?.data?.totalCustomerCount || 0 },
            });
         }
      },
      onError: (error: any) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            autoHideDuration: 2000,
         });
      },
   });

   const handleNext = useCallback(() => {
      if (eCommerceCustomers?.totalDocs > 20 || eCommerceCustomers?.hasNextPage) {
         setFetchingMore(true);
         fetchMore({
            variables: {
               page: eCommerceCustomers?.nextPage,
               pageToken: eCommerceCustomers?.nextPageToken
            },
         })
            .then(({ data }: any) => {
               dispatch({
                  type: E_COMMERCE_CUSTOMERS,
                  payload: {
                     eCommerceCustomers: {
                        ...data.getECommerceCustomers.data,
                        customerList: [...eCommerceCustomers?.customerList, ...data.getECommerceCustomers.data?.customerList],
                     },
                  },
               });
            })
            .finally(() => {
               setFetchingMore(false);
            });
      }
   }, [
      eCommerceCustomers?.totalDocs,
      eCommerceCustomers?.hasNextPage,
      eCommerceCustomers?.nextPage,
      eCommerceCustomers?.nextPageToken,
      eCommerceCustomers?.page,
   ]);

   return (
      <>
         {header}
         <div className={classes.listAndInputs}>
            <div className={classes.inputsContainer}>
               <Grid container direction='row' alignItems='center' justify='space-between' wrap='nowrap'>
                  <Grid item xs={12}>
                     <Input
                        name='searchCustomer'
                        required={false}
                        control={control}
                        placeholder={t['page_e_commerce_store_search_customer_input_placeholder']}
                     />
                  </Grid>
               </Grid>
            </div>
            <div className={classes.linearProgressContainer}>{loading && <LinearProgress />}</div>
            <div className={classes.listContainer}>
               <InfiniteScroll
                  next={handleNext}
                  dataLength={eCommerceCustomers?.customerList?.length || 0}
                  height={height - 385}
                  loader={
                     <Grid
                        style={{
                           height: 350,
                           margin: '20px 0px',
                        }}
                        container
                        justifyContent='center'
                        alignItems='center'
                        xs={12}
                     >
                        {fetchingMore && <CircularProgress size={24} color='primary' />}
                        {!fetchingMore && eCommerceCustomers?.customerList?.length < 1 && (
                           <Typography>{t['page_e_commerce_products_search_customer_not_found']}</Typography>
                        )}
                     </Grid>
                  }
                  hasMore={eCommerceCustomers?.hasNextPage}
                  style={{
                     display: 'flex',
                     flexDirection: 'column',
                     overflowX: 'hidden',
                  }}
               >
                  {eCommerceCustomers?.customerList?.map((customer: any, index: any) => (
                     <CustomersListItem key={index} customer={customer} onClick={() => openCustomerDetail(customer)} />
                  ))}
               </InfiniteScroll>
            </div>
         </div>
      </>
   );
};

export default AllCustomers;

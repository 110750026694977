import { useLazyQuery } from '@apollo/client';
import ButtonOK from '@components/ButtonOK/buttonOk';
import DateRangePicker from '@components/DateRangePicker';
import Filter from '@components/Filter';
import Tag from '@components/Tag';
import { LanguageContext } from '@helper/locale/langContext';
import {
   Avatar,
   Box,
   Button,
   CircularProgress,
   Grid,
   Menu,
   MenuItem,
   Select,
   TextField,
   Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import { GET_CHAT_TAGS_QUERY } from '@queries/Settings/Service/GraphQL/ChatTags/query';
import { SET_CHAT_FILTERS } from '@store/actions/chat';
import { SET_QNA_CHAT_FILTERS } from '@store/actions/questions';
import { getPerson } from '@store/reducers/person';
import cn from 'classnames';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { ChatType, PERSON_TYPE } from '../../../types/enums';

const FIRST_ROW_HEIGHT = 44;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const USER_MENU_PROPS = {
   anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
   },
   getContentAnchorEl: null,
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP + 100,
         width: 180,
      },
   },
};

const useStyles = makeStyles((theme) => ({
   root: {
      width: 380,
      textAlign: 'center',
      padding: 10,
      '& .MuiSelect-select:focus': {
         backgroundColor: 'transparent',
      },
      [theme.breakpoints.down('sm')]: {
         width: '100%',
      },
   },
   margin: {
      marginLeft: '77px',
      width: '100%',
   },
   title: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginTop: '5px',
      marginBottom: '15px',
      fontSize: '1.2rem',
   },
   selectedOptionTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      textAlign: 'left',
      paddingLeft: 10,
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   menuItem: {
      width: '100%',
      // "&:hover": {
      //     backgroundColor: 'transparent',
      // },
      margin: 0,
      marginTop: 2,
      paddingRight: 10,
      paddingLeft: 5,
      // marginBottom: 2,
      marginLeft: 5,
      borderRadius: 10,
   },
   label: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   selectBox: {
      border: '1px solid #B3B5B9',
      borderRadius: 8,
      height: 35,
   },
   filterLabelText: {
      fontSize: '1rem',
      color: '#B3B5B9',
      textAlign: 'left',
      paddingLeft: 14,
      fontWeight: 'bold',
   },
   filterUserLabelText: {
      fontSize: '1rem',
      color: '#3B3B3B',
      textAlign: 'left',
      paddingLeft: 14,
      fontWeight: 'bold',
   },
   buttonCancel: {
      height: 35,
      fontSize: '1.1rem',
      fontWeight: 'bold',
      textTransform: 'none',
      borderRadius: 5,
      border: '1px solid #B9D0E5',
      color: '#748FA7',
   },
   avatar: {
      width: 30,
      height: 30,
   },
   allUsersIconContainer: {
      width: 30,
      height: 30,
      borderRadius: 30,
      backgroundColor: '#bdbdbd',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   allUsersIcon: {
      width: 20,
      height: 20,
      color: '#FAFAFA',
   },
   searchInputContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 10,
      marginLeft: 10,
      '& .MuiOutlinedInput-root': {
         height: '40px',
      },
   },
   searchInput: {
      margin: '8px 0px 5px 0px !important',
   },
   teamMenuFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 7,
      paddingRight: 10,
      borderTop: '1px solid #E6E6E6',
   },
   teamAvatar: {
      width: 30,
      height: 30,
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlack,
      marginRight: 8,
      fontSize: 16,
   },
   teamLabel: {
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: '500',
   },
   teamMenuInput: {
      height: FIRST_ROW_HEIGHT,
      backgroundColor: '#fff',
      borderRadius: 8,
      border: '1px solid #B3B5B9',
      paddingRight: 5,
      paddingLeft: 5,
      minWidth: 0,
      width: '100%',
   },
   teamMenuInputActive: {
      backgroundColor: '#fff !important',
   },
   teamMenuItemContainer: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 5,
   },
   teamMenuItem: {
      width: '100%',
      backgroundColor: '#fff',
      borderRadius: 6,
      paddingLeft: 10,
      paddingRight: 10,
   },
   statusAvatar: {
      position: 'absolute',
      top: 3,
      right: '-3px',
      width: 11,
      height: 11,
      borderRadius: '50%',
   },
}));

const Page = {
   pageSize: 10,
   page: 1,
};

const FilterBox = ({
   chatType,
   chatFilters,
   agents,
   tags,
   statusList,
   onFilterClose,
   width,
   onSelectTeam,
   setSelectedTeam,
   selectedTeam,
   teams,
}) => {
   // language context
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const dispatch = useDispatch();
   const classes = useStyles();
   const me = useSelector(getPerson);
   const toolbarFirstRowRef = useRef(null);
   const [tagList, setTagList] = useState([]);
   const [filterTagList, setFilterTagList] = useState('');
   const [agentList, setAgentList] = useState([]);
   const [filterAgentList, setFilterAgentList] = useState('');
   const [teamMenuAnchorEl, setTeamMenuAnchorEl] = useState(null);
   const [chatTagCount, setChatTagCount] = useState(0);
   const [search, setSearch] = useState('');

   const handleClose = () => {
      onFilterClose();
   };
   const handleTeamMenuClose = () => {
      setTeamMenuAnchorEl(null);
   };

   const teamOptions = useMemo(() => {
      let options = [];

      if (teams && Array.isArray(teams)) {
         if (teams.length === 0 || teams.length > 1) {
            options = [
               {
                  value: 'ALL',
                  label: t?.page_chat_all_teams,
                  backgroundColor: '#333',
               },
            ];
         }

         if (teams.length > 0) {
            options = [
               ...options,
               ...teams?.map((team) => ({
                  value: team._id,
                  label: team.teamName,
                  backgroundColor: team.color,
               })),
            ];
         }
      }
      return options;
   }, [teams]);

   useEffect(() => {
      if (tags) {
         if (!filterTagList) {
            setTagList(tags);
         } else {
            setTagList(tags.filter((tag) => tag.name.toLowerCase().includes(filterTagList.toLowerCase())));
         }
      } else {
         setTagList([]);
      }
   }, [tags, filterTagList]);

   useEffect(() => {
      onSelectTeam(selectedTeam);
      handleTeamMenuClose();
   }, [selectedTeam]);

   const handleTagFilterChange = (event) => {
      setFilterTagList(event.target.value);
      setSearch(event.target.value);
   };

   useEffect(() => {
      if (chatFilters?.teams?.value && teamOptions && Array.isArray(teamOptions) && teamOptions?.length > 0) {
         const isCurrentlySelectedTeamsExistInOptions = teamOptions.find(
            (option) => option.value === chatFilters?.teams?.value,
         );
         if (!isCurrentlySelectedTeamsExistInOptions) {
            onSelectTeam(teamOptions[0]);
         }
      }
   }, [chatFilters?.teams?.value, teamOptions]);

   const getAgentDisplayName = useCallback((agent) => {
      if (agent) {
         if (agent._id === me?._id) {
            return t['page_chat_typo_filter_title_mine'];
         } else if (agent.name && agent.surname) {
            return `${agent.name} ${agent.surname}`;
         } else if (agent.name) {
            return agent.name;
         } else if (agent.username) {
            return agent.username;
         } else if (agent.email) {
            return agent.email;
         } else return '';
      }
      return '';
   }, []);

   useEffect(() => {
      if (agents) {
         setAgentList(
            agents.filter((agent) => {
               return getAgentDisplayName(agent).toLowerCase().includes(filterAgentList.toLowerCase());
            }),
         );
      } else {
         setAgentList([]);
      }
   }, [agents, filterAgentList, getAgentDisplayName]);

   const handleUserFilterChange = (event) => {
      setFilterAgentList(event.target.value);
   };

   const updateChatFilters = useCallback(
      (filters) => {
         dispatch({
            type: chatType === ChatType.DM ? SET_CHAT_FILTERS : SET_QNA_CHAT_FILTERS,
            payload: filters,
         });
      },
      [dispatch],
   );

   const onDateFilterChange = useCallback(
      (startDate, endDate) => {
         dispatch({
            type: chatType === ChatType.DM ? SET_CHAT_FILTERS : SET_QNA_CHAT_FILTERS,
            payload: {
               startDate: moment(startDate).startOf('day').format(),
               endDate: moment(endDate).endOf('day').format(),
            },
         });
      },
      [dispatch],
   );

   const TeamMenuItem = ({ onClick, team, containerStyle, itemStyle }) => (
      <div className={classes.teamMenuItemContainer} style={containerStyle}>
         <MenuItem
            disableRipple
            disableTouchRipple
            onClick={onClick}
            className={classes.teamMenuItem}
            style={itemStyle}
         >
            <div
               style={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                  alignItems: 'center',
               }}
            >
               <Avatar className={classes.teamAvatar} style={{ backgroundColor: team.backgroundColor }}>
                  <Typography variant='body1'>{team?.label?.slice(0, 2)}</Typography>
               </Avatar>
               <Typography variant='body2' noWrap={true} className={classes.teamLabel}>
                  {team.label}
               </Typography>
            </div>
         </MenuItem>
      </div>
   );

   const agentOptions = useMemo(() => {
      let options = [{ label: t['page_chat_typo_filter_title_mine'], value: me?._id, photo: me?.photo }];
      if (me?._id && agents) {
         const allAgentsIds = agents?.length > 0 ? [me?._id, ...agents?.map((agent) => agent._id)].join(',') : me?._id;
         options = [
            {
               label: t['page_chat_typo_filter_title_all_users'],
               value: allAgentsIds,
               photo: 'ALL',
            },
            { label: t['page_chat_typo_filter_title_mine'], value: me?._id, photo: me?.photo },
            ...agentList.map((agent) => ({
               label: getAgentDisplayName(agent),
               value: agent._id,
               photo: agent.photo,
               status: agent.status,
            })),
         ];
      }
      return options;
   }, [agentList, agents, getAgentDisplayName, me?._id]);

   const stopPropagation = (e) => {
      e.stopPropagation();
   };
   //*********************************************** */

   const [getChatTagQuery, { data: chatTagsData, loading: chatTagsDataLoading }] = useLazyQuery(GET_CHAT_TAGS_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setTagList([...tagList, ...data?.getChatTagList?.data?.docs]);
         setChatTagCount(data?.getChatTagList?.data?.docsCount);
      },
   });
   const [chatTagParam, setChatTagParam] = useState({
      customer: me.customer._id,
      pageSize: Page.pageSize,
      page: Page.page,
      search: '',
   });

   const handleNextAnswerPage = () => {
      if (tagList.length < chatTagCount) {
         setChatTagParam({
            ...chatTagParam,
            page: chatTagParam.page + 1,
         });
      }
   };

   useEffect(() => {
      setTagList([]);
      setChatTagParam((prev) => ({ ...prev, search: search, page: Page.page }));
   }, [search]);

   useEffect(() => {
      getChatTagQuery({
         variables: {
            ...chatTagParam,
         },
      });
   }, [chatTagParam]);

   const Loader = () => {
      return (
         <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   };

   return (
      <Box className={classes.root} style={{ width: width }}>
         <Typography variant='h5' className={classes.title}>
            {t['page_chat_typo_filters_']}
         </Typography>
         <Grid container style={{ padding: '10px' }} direction='column' spacing={1}>
            <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
               <Typography className={classes.label}>{t['teams']}</Typography>
               <div style={{ width: 232 }}>
                  <MenuItem
                     ref={toolbarFirstRowRef}
                     disableRipple
                     disableTouchRipple
                     onClick={(e) => setTeamMenuAnchorEl(e.currentTarget)}
                     className={cn(classes.teamMenuInput, Boolean(teamMenuAnchorEl) && classes.teamMenuInputActive)}
                  >
                     <div
                        style={{
                           flexGrow: 1,
                           width: '100%',
                           minWidth: 0,
                           display: 'flex',
                           flexDirection: 'row',
                           alignItems: 'center',
                           marginLeft: '6px',
                           marginRight: '-5px',
                        }}
                     >
                        <Avatar
                           className={classes.teamAvatar}
                           style={{ backgroundColor: chatFilters?.teams?.backgroundColor }}
                        >
                           {chatFilters?.teams?.label?.slice(0, 2)}
                        </Avatar>
                        <Typography variant='body2' noWrap={true} className={classes.teamLabel}>
                           {chatFilters?.teams?.label}
                        </Typography>
                        <ArrowDropDownIcon style={{ color: 'GrayText' }} />
                     </div>
                  </MenuItem>
               </div>
            </Grid>
            <Menu
               anchorEl={toolbarFirstRowRef.current}
               keepMounted
               open={Boolean(teamMenuAnchorEl)}
               onClose={handleTeamMenuClose}
               anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
               style={{ marginTop: 5, height: 400, width: 265 }}
            >
               {teamOptions.map((option, index) => (
                  <TeamMenuItem
                     key={index}
                     onClick={() => {
                        setSelectedTeam(option);
                        updateChatFilters({ teams: option });
                     }}
                     team={option}
                     containerStyle={{ width: toolbarFirstRowRef.current?.offsetWidth }}
                     itemStyle={{
                        border: chatFilters?.teams?.value === option?.value ? '1px solid #157CFC' : '1px solid #E7E7E7',
                     }}
                  />
               ))}
            </Menu>
            {(me.type === PERSON_TYPE.OWNER || me.type === PERSON_TYPE.MANAGER) && (
               <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
                  <Typography className={classes.label}>{t['page_chat_typo_filter_user']}</Typography>
                  <Grid className={classes.selectBox}>
                     <Select
                        style={{
                           marginTop: 0,
                           width: 230,
                           height: 32,
                           borderColor: 'rebeccapurple',
                        }}
                        disableUnderline
                        value={chatFilters.me}
                        onChange={({ target: { value } }) => updateChatFilters({ me: value })}
                        MenuProps={USER_MENU_PROPS}
                        renderValue={(value) => {
                           if (!value || value.includes(',')) {
                              return (
                                 <Typography className={classes.filterUserLabelText}>
                                    {t?.page_chat_typo_filter_title_all_users}
                                 </Typography>
                              );
                           } else if (value === me?._id) {
                              return (
                                 <Typography className={classes.filterUserLabelText}>
                                    {t?.page_chat_typo_filter_title_mine}
                                 </Typography>
                              );
                           }
                           return (
                              <Typography className={classes.filterUserLabelText}>
                                 {getAgentDisplayName(agents?.find((agent) => agent._id === value))}
                              </Typography>
                           );
                        }}
                     >
                        {agents?.length > 4 && (
                           <div className={classes.searchInputContainer} onClick={stopPropagation}>
                              <TextField
                                 id='search-agent-filter-box'
                                 label={t['page_chat_typo_search_agent']}
                                 variant='outlined'
                                 size='small'
                                 fullWidth
                                 value={filterAgentList}
                                 onChange={handleUserFilterChange}
                                 onClick={stopPropagation}
                                 className={classes.searchInput}
                              />
                           </div>
                        )}
                        {agentOptions.map((item) => (
                           <Grid key={item.value} value={item.value} container justifyContent='flex-start'>
                              <MenuItem className={classes.menuItem}>
                                 {item.photo === 'ALL' ? (
                                    <div className={classes.allUsersIconContainer}>
                                       <GroupRoundedIcon className={classes.allUsersIcon} />
                                    </div>
                                 ) : (
                                    <div style={{ position: 'relative' }}>
                                       <Avatar src={item.photo} className={classes.avatar} />
                                       <span
                                          className={classes.statusAvatar}
                                          style={{
                                             backgroundColor: item.status === 2 ? '#49be25' : '#ababab',
                                          }}
                                       />
                                    </div>
                                 )}
                                 <Typography className={classes.filterUserLabelText}>{item.label}</Typography>
                              </MenuItem>
                           </Grid>
                        ))}
                     </Select>
                  </Grid>
               </Grid>
            )}
            <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
               <Typography className={classes.label}>{t['page_chat_typo_filter_tag']}</Typography>
               <Grid className={classes.selectBox}>
                  <Select
                     style={{ marginTop: 0, width: 230, height: 32, borderColor: 'rebeccapurple' }}
                     disableUnderline
                     value={chatFilters.tag}
                     // onChange={() => {}}
                     MenuProps={USER_MENU_PROPS}
                     renderValue={(value) => {
                        const item = tags?.find((item) => item._id === value);
                        if (value === 'ALL') {
                           return (
                              <Typography className={classes.filterLabelText} style={{ color: '#157CFC' }}>
                                 {t?.page_chat_typo_filter_title_all}
                              </Typography>
                           );
                        }
                        return (
                           <Typography className={classes.filterLabelText} style={{ color: item?.color }}>
                              #{item?.name}
                           </Typography>
                        );
                     }}
                  >
                     {tags?.length > 4 && (
                        <div className={classes.searchInputContainer} onClick={stopPropagation}>
                           <TextField
                              id='search-tag-filter-box'
                              label={t['page_chat_typo_search_tag']}
                              variant='outlined'
                              size='small'
                              fullWidth
                              value={search}
                              onChange={handleTagFilterChange}
                              onClick={stopPropagation}
                              className={classes.searchInput}
                           />
                        </div>
                     )}
                     <Grid
                        key={'ALL'}
                        value={'ALL'}
                        onClick={() => updateChatFilters({ tag: 'ALL' })}
                        container
                        justifyContent='flex-start'
                     >
                        {tags?.length > 0 ? (
                           <MenuItem disableRipple className={classes.menuItem}>
                              <Filter type={'ALL'} style={{ paddingRight: 10 }} />
                           </MenuItem>
                        ) : (
                           <Typography className={classes.filterLabelText} style={{ paddingLeft: '50px' }}>
                              {t.there_is_no_tag}
                           </Typography>
                        )}
                     </Grid>
                     <Grid>
                        <InfiniteScroll
                           scrollableTarget='scrollableDiv'
                           dataLength={tagList?.length || 0}
                           hasMore={tagList.length >= chatTagCount ? false : true}
                           loader={<Loader />}
                           next={handleNextAnswerPage}
                           height={200}
                        >
                           {tagList?.map((item) => (
                              <Grid
                                 onClick={() => updateChatFilters({ tag: item._id })}
                                 key={item._id}
                                 value={item.name}
                                 container
                                 justifyContent='flex-start'
                              >
                                 <MenuItem disableRipple className={classes.menuItem}>
                                    <Tag name={item.name} color={item.color} />
                                 </MenuItem>
                              </Grid>
                           ))}
                        </InfiniteScroll>
                     </Grid>
                  </Select>
               </Grid>
            </Grid>
            <Grid item xs={12} container justifyContent='space-between' alignItems='center'>
               <Typography className={classes.label}>{t['page_chat_typo_filter_status']}</Typography>
               <Grid className={classes.selectBox}>
                  <Select
                     style={{ marginTop: 0, width: 230, height: 32 }}
                     disableUnderline
                     value={chatFilters.status}
                     onChange={({ target: { value } }) => updateChatFilters({ status: value })}
                     renderValue={(value) => {
                        const item = statusList.find((item) => item.value === value);
                        return (
                           <Typography
                              variant='body1'
                              style={{ color: item.color }}
                              className={classes.filterLabelText}
                           >
                              {item.label}
                           </Typography>
                        );
                     }}
                     MenuProps={USER_MENU_PROPS}
                  >
                     {statusList.map((item, index) => (
                        <Grid key={item.key} value={item.value} container justifyContent='flex-start'>
                           <MenuItem className={classes.menuItem}>
                              <Filter
                                 selected={chatFilters.status === item.value}
                                 type={item.key}
                                 style={{ paddingRight: 10 }}
                              />
                           </MenuItem>
                        </Grid>
                     ))}
                  </Select>
               </Grid>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 5 }}>
               <DateRangePicker
                  onDateFilterChange={onDateFilterChange}
                  startDate={chatFilters.startDate}
                  endDate={chatFilters.endDate}
               />
            </Grid>
            <Grid container justifyContent='space-between' spacing={1} xs={12} style={{ marginTop: 15 }}>
               <Grid item xs={6}>
                  <Button
                     variant='outlined'
                     fullWidth
                     disableRipple
                     onClick={handleClose}
                     className={classes.buttonCancel}
                  >
                     {t['page_chat_typo_close']}
                  </Button>
               </Grid>
               <Grid item xs={6}>
                  <ButtonOK
                     label={t['page_chat_btn_filter_list']}
                     fontSize='1.1rem'
                     fontWeight='bold'
                     style={{ width: '100%' }}
                     height={35}
                     onClick={handleClose}
                  />
               </Grid>
            </Grid>
         </Grid>
      </Box>
   );
};

export default FilterBox;

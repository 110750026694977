import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { SITE_URL } from '../../../../helper/config';
import CloseRounded from '@material-ui/icons/CloseRounded';
import MediumAvatar from '../../../../components/MediumAvatar';
import { getSocialMediaFromType } from '@helper/functions';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
   container: {
      flexShrink: 0,
      height: 72,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 15,
   },

   closeIconButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 30,
      height: 30,
      border: '1px solid #8CA9CE',
   },
   closeIcon: {
      fontSize: 20,
      color: '#8CA9CE',
   },
}));

interface Participant {
   name: string;
   surname: string;
   photo: string;
   status: number;
}

interface HeaderProps {
   participant: Participant;
   activeChatType: string | null | undefined;
   onClose: () => void;
}

const Header: FC<HeaderProps> = ({ participant, activeChatType, onClose }) => {
   const classes = useStyles();

   return (
      <div className={classes.container}>
         <MediumAvatar
            navBarAvatar={true}
            isCustomer
            hideCircle
            size='small'
            name={participant.name}
            surname={participant.surname}
            socialMedia={activeChatType}
            title={participant.name}
            image={participant.photo ? participant.photo : SITE_URL + '/none.jpg'}
            onlineStatus={participant.status}
         />
         <IconButton onClick={onClose} className={classes.closeIconButton}>
            <CloseRounded className={classes.closeIcon} />
         </IconButton>
      </div>
   );
};

export default Header;

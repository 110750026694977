import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import SocialMediaIcon from '@components/SocialMediaIcon';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import { TIMEZONE } from '@helper/config';
import { getSocialMediaFromType } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, ButtonBase, Grid, LinearProgress, Tooltip, Typography, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GridOverlay, DataGrid as MaterialDataGrid } from '@material-ui/data-grid';
import CheckCircleRoundedIcon from '@material-ui/icons/ReportOutlined';
import TableToolbar from '@modules/Contacts/components/TableToolbar';
import CreateGroupModal from '@modules/Contacts/forms/CreateGroupModal';
import DynamicGroupModal from '@modules/Contacts/forms/DynamicGroupModal';
import CreateNewUserModal from '@modules/Contacts/forms/CreateNewUserModal';
import EditGroupModal from '@modules/Contacts/forms/EditGroupModal';
import { DELETE_PERSON_MUTATION, UPDATE_PERSON_MUTATION } from '@queries/Person/Service/GraphQL/mutation';
import {
   PUSH_PERSONS_TO_GROUP_MUTATION,
   REMOVE_PERSONS_FROM_GROUP_MUTATION,
} from '@queries/PersonGroups/Service/GraphQL/mutation';
import { GET_PERSON_GROUPS } from '@queries/PersonGroups/Service/GraphQL/query';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { UPDATED_PERSON_GROUP_SUBSCRIPTION } from '../../queries/PersonGroups/Service/GraphQL/subscription';
import ImportUsersModal from './forms/ImportUsersModal';

const RenderDropDown = ({ value, person, area }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [_value, setValue] = useState(value);
   const [isOpen, setIsOpen] = useState(false);
   const [isModalVisible, setModalVisible] = useState(false);
   const [modalArea, setModalArea] = useState('email');
   const [updatePersonMutation] = useMutation(UPDATE_PERSON_MUTATION);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const updatePerson = (data) => {
      updatePersonMutation({
         variables: {
            _id: person._id,
            ...data,
         },
      })
         .then(
            ({
               data: {
                  updatePerson: { data: updatePerson },
               },
            }) => {
               enqueueSnackbar(`${updatePerson.name} başarılı bir şekilde güncellendi`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   return (
      <ButtonBase
         style={{ borderRadius: 10, padding: 5 }}
         onClick={(e) => {
            e.stopPropagation();
         }}
      >
         <Grid
            container
            direction='row'
            alignItems='center'
            style={{
               background: _value == true ? '#cafaca' : _value == false ? '#ffa9a9' : '',
            }}
         >
            <DropdownMenu
               isOpen={isOpen}
               onOpenChange={(attrs) => {
                  setIsOpen(attrs.isOpen);
               }}
               trigger={
                  _value == true
                     ? t?.page_contacts_typo_subscribed
                     : _value == false
                     ? t?.page_contacts_typo_unsubscribed
                     : '--------'
               }
            >
               <DropdownItemGroup id='actions'>
                  <div style={{ color: 'green' }}>
                     <DropdownItem
                        onClick={() => {
                           if (_value == false) {
                              setModalArea(area);
                              setModalVisible(true);
                           } else {
                              setValue(true);
                              area == 'phone'
                                 ? updatePerson({ phoneUsagePermission: true })
                                 : updatePerson({ emailUsagePermission: true });
                           }
                           setIsOpen(false);
                        }}
                        id='edit'
                     >
                        {t?.page_contacts_typo_subscribed}
                     </DropdownItem>
                  </div>
                  <div style={{ color: 'red' }}>
                     <DropdownItem
                        id='move'
                        onClick={() => {
                           setValue(false);
                           area == 'phone'
                              ? updatePerson({ phoneUsagePermission: false })
                              : updatePerson({ emailUsagePermission: false });
                           setIsOpen(false);
                        }}
                     >
                        {t?.page_contacts_typo_unsubscribed}
                     </DropdownItem>
                  </div>
               </DropdownItemGroup>
            </DropdownMenu>
         </Grid>

         <Modal
            open={isModalVisible}
            onClose={() => setModalVisible(false)}
            modalHeader={t['page_contacts_typo_modal_title']}
            paperStyle={{ width: 450 }}
         >
            <Grid container direction='column' alignItems='center'>
               <CheckCircleRoundedIcon className={classes.successIcon} />
               <ModalLargeText>{t['page_contacts_typo_modal_' + modalArea + '_text']}</ModalLargeText>
            </Grid>
            <ModalButtonField>
               <ButtonCancel
                  className={classes.modalButtons}
                  label={t?.page_chat_btn_filter_cancel}
                  onClick={() => setModalVisible(false)}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
               <ButtonOK
                  className={classes.modalButtons}
                  label={t['pre_load_forget_pass_sent_OK']}
                  onClick={() => {
                     setValue(true);
                     updatePerson(area === 'phone' ? { phoneUsagePermission: true } : { emailUsagePermission: true });
                     setModalVisible(false);
                  }}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     '&:hover': {
                        backgroundColor: '#105CBB',
                     },
                     float: 'right',
                  }}
               />
            </ModalButtonField>
         </Modal>
      </ButtonBase>
   );
};

const getColumns = (classes, clickCallback, t) => [
   {
      headerName: t['page_contacts_typo_platform'],
      field: 'type',
      width: 120,
      renderCell: ({ row }) => {
         return (
            <Tooltip title={getSocialMediaFromType(row.type)}>
               <ButtonBase style={{ borderRadius: 10, padding: 20 }} onClick={() => clickCallback(row)}>
                  <Grid container direction='row' alignItems='center'>
                     {row.type ? (
                        <Grid
                           container
                           justifyContent='center'
                           alignItems='center'
                           // style={{  }}
                        >
                           <SocialMediaIcon socialMediaCode={row.type} selected width={32} height={44} />
                        </Grid>
                     ) : (
                        <div style={{ borderBottom: 'solid 2px grey', width: 24 }} />
                     )}
                  </Grid>
               </ButtonBase>
            </Tooltip>
         );
      },
   },
   {
      headerName: t['page_contacts_typo_name_surname'],
      field: 'name',
      //width: 200,
      flex: 3,
      renderCell: ({ row }) => {
         return (
            <Tooltip title={t?.tooltip_edit_user_button_text}>
               <ButtonBase style={{ borderRadius: 10, padding: 5 }} onClick={() => clickCallback(row)}>
                  {row.name ? (
                     <Grid container direction='row' alignItems='center' wrap='nowrap'>
                        <Typography noWrap style={{ textDecoration: 'none' }}>
                           {row.surname ? `${row.name} ${row.surname}` : row.name}
                        </Typography>
                     </Grid>
                  ) : (
                     <div style={{ borderBottom: 'solid 2px grey', width: 24 }} />
                  )}
               </ButtonBase>
            </Tooltip>
         );
      },
   },
   {
      headerName: t['page_contacts_typo_phone'],
      field: 'phone',
      //width: 160,
      flex: 2,
      renderCell: ({ row }) => {
         return (
            <Tooltip title={t?.tooltip_edit_user_button_text}>
               <ButtonBase style={{ borderRadius: 10, padding: 5 }} onClick={() => clickCallback(row)}>
                  <Grid container direction='row' alignItems='center' wrap='nowrap'>
                     {!row?.phone?.includes('undefined') ? (
                        <Typography noWrap>{row.phone}</Typography>
                     ) : (
                        <div style={{ borderBottom: 'solid 2px grey', width: 24 }} />
                     )}
                  </Grid>
               </ButtonBase>
            </Tooltip>
         );
      },
   },
   {
      headerName: t['page_contacts_typo_email'],
      field: 'email',
      flex: 2,
      renderCell: ({ row }) => {
         return (
            <Tooltip title={t?.tooltip_edit_user_button_text}>
               <ButtonBase style={{ borderRadius: 10, padding: 5 }} onClick={() => clickCallback(row)}>
                  {row.email ? (
                     <Typography noWrap={true} style={{ textTransform: 'none' }}>
                        {row.email}
                     </Typography>
                  ) : (
                     <div style={{ borderBottom: 'solid 2px grey', width: 24 }} />
                  )}
               </ButtonBase>
            </Tooltip>
         );
      },
   },
   {
      headerName: t['page_contacts_typo_phone_permission'],
      field: 'phoneUsagePermission',
      //width: 200,
      flex: 2,
      renderCell: ({ row }) => {
         return (
            <Tooltip title={getSocialMediaFromType(row.type)}>
               <RenderDropDown value={row.phoneUsagePermission} area='phone' person={row}></RenderDropDown>
            </Tooltip>
         );
      },
   },
   {
      headerName: t['page_contacts_typo_email_permission'],
      field: 'emailUsagePermission',
      //width: 200,
      flex: 2,
      renderCell: ({ row }) => {
         return (
            <Tooltip title={getSocialMediaFromType(row.type)}>
               <RenderDropDown value={row.emailUsagePermission} area='email' person={row}></RenderDropDown>
            </Tooltip>
         );
      },
   },
   {
      headerName: t['page_contacts_typo_creation_date'],
      field: 'createdAt',
      //width: 180,
      flex: 2,
      renderCell: ({ row }) => {
         return (
            <Tooltip title={t?.tooltip_edit_user_button_text}>
               <ButtonBase style={{ borderRadius: 10, padding: 5 }} onClick={() => clickCallback(row)}>
                  <Grid container direction='row' alignItems='center' wrap='nowrap'>
                     {row.createdAt ? (
                        <Typography noWrap>{moment(row.createdAt).tz(TIMEZONE).format('DD.MM.YYYY hh:mm')}</Typography>
                     ) : (
                        <div style={{ borderBottom: 'solid 2px grey', width: 24 }} />
                     )}
                  </Grid>
               </ButtonBase>
            </Tooltip>
         );
      },
   },
];
const useStyles = makeStyles((theme) => ({
   root: {
      margin: 0,
      width: '100%',
      height: '100%',
   },
   ToolBarContainer: {
      // height: 100,
   },
   page: {
      display: 'flex',
   },
   Table: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
         outline: 'none',
      },
   },
   rowStyle: {
      color: '#385273',
      fontWeight: 'bold',
      fontSize: '1rem',
   },
   icon: {
      width: 40,
      height: 40,
   },
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 25,
   },
   successIcon: {
      fontSize: 70,
      color: '#ffc107',
      margin: 15,
   },
   modalDescription: {
      marginBottom: 55,
   },
   informationListItem: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      padding: '4px 0px',
   },
   modalBodyRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
   },
   modalText: {
      color: '#797575',
      fontSize: '1rem',
      margin: 20,
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      borderTop: '1px solid #F1F3F7',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const DataGrid = ({
   excelExport,
   onRowClicked,
   width,
   data,
   onSearchChanged,
   onGroupChanged,
   selectedGroupData,
   loading,
   searchValue,
   selectedPlatform,
   selectedTeam,
   onPlatformChanged,
   onTeamChanged,
   dateFilter,
   pageNumber,
   onPageNumberChange,
   totalCount,
   onPageSizeChange,
   text,
   setText,
   getPersonCustomField,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [selectionModel, setSelectionModel] = React.useState([]);
   const [isDeleteModalVisible, setDeleteModalVisible] = React.useState(false);
   const [deletePersonMutation] = useMutation(DELETE_PERSON_MUTATION);
   const [isButtonLoading, setIsButtonLoading] = useState(false);
   const person = useSelector((reducer) => reducer.personReducer.person);
   const token = useSelector((reducer) => reducer.personReducer.token);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

   const [showCreateGroup, setCreateGroup] = useState({
      visible: false,
      loading: false,
      isEdit: false,
   });

   const [showDynamicGroup, setDynamicGroup] = useState({
      visible: false,
      loading: false,
   });

   const [showImportUsers, setImportUsers] = useState({
      visible: false,
      data_loaded: false,
   });

   const [showEditGroup, setEditGroup] = useState({
      visible: false,
      groupId: null,
      loading: false,
   });

   const [showCreateManualAccount, setCreateManualAccount] = useState({
      licenseAgreement: false,
      visible: false,
      phone: '',
   });

   const [pushPersonsToGroup, {}] = useMutation(PUSH_PERSONS_TO_GROUP_MUTATION, {
      async update(
         cache,
         {
            data: {
               pushPersonsToGroup: { data: pushPersonsToGroup },
            },
         },
      ) {},
   });

   const [removePersonsFromGroup, {}] = useMutation(REMOVE_PERSONS_FROM_GROUP_MUTATION, {
      async update(
         cache,
         {
            data: {
               removePersonsFromGroup: { data: removePersonsFromGroup },
            },
         },
      ) {},
   });

   const handlePushUsersToGroup = (group_id) => {
      pushPersonsToGroup({
         variables: {
            _id: group_id,
            personIds: selectionModel,
            customer: person.customer._id,
         },
      })
         .then(() => {})
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const handleRemoveUsersFromGroup = (group_id) => {
      removePersonsFromGroup({
         variables: {
            _id: group_id,
            personIds: selectionModel,
            customer: person.customer._id,
         },
      })
         .then(() => {})
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const [availablePersonGroups, setAvailablePersonGroups] = useState({
      groups: [],
      loading: false,
   });

   const [getPersonGroups, { data: personGroupData }] = useLazyQuery(GET_PERSON_GROUPS, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setAvailablePersonGroups({
            groups: data.getPersonGroups.data,
            loading: true,
         });
      },
   });

   useEffect(() => {
      if (showImportUsers.data_loaded) {
         onPageNumberChange(1);
      }
   }, [showImportUsers, onPageNumberChange]);

   const handleDeleteUsers = () => {
      setIsButtonLoading(true);
      deletePersonMutation({
         variables: {
            selectedUsers: selectionModel,
         },
      })
         .then(() => {
            setDeleteModalVisible(false);
            onPageNumberChange(1);
            onSearchChanged('');
            setAvailablePersonGroups({
               groups: [],
               loading: false,
            });
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         })
         .finally(() => {
            setIsButtonLoading(false);
         });
   };

   const handleRowClicked = useCallback(
      ({ row }) => {
         onRowClicked(row);
      },
      [onRowClicked],
   );

   let customer = person.customer._id;

   // subscription updated person
   useSubscription(UPDATED_PERSON_GROUP_SUBSCRIPTION, {
      variables: { token, customer },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedGroup: { data: updatedGroup },
            },
         },
      }) => {
         if (updatedGroup.operationType.toString().startsWith('modify_')) {
            setAvailablePersonGroups({
               groups: availablePersonGroups.groups.map((group) => {
                  if (group._id === updatedGroup.groupId || updatedGroup.groupIds?.includes(group._id)) {
                     let new_count = group.count;
                     if (updatedGroup.userCount != undefined) {
                        new_count = updatedGroup.userCount;
                     } else {
                        new_count =
                           updatedGroup.operationType === 'modify_push'
                              ? group.userCount + updatedGroup.updateCount
                              : group.userCount - updatedGroup.updateCount;
                     }
                     return {
                        ...group,
                        userCount: new_count,
                     };
                  } else {
                     return group;
                  }
               }),
            });

            if (
               selectedGroupData._id === updatedGroup.groupId ||
               updatedGroup.groupIds?.includes(selectedGroupData._id)
            ) {
               onPageNumberChange(1);
               onGroupChanged(selectedGroupData);
            }
            setSelectionModel([]);
            enqueueSnackbar(t['page_settings_typo_chat_settings_automatic_actions_success_message'], {
               variant: 'success',
               autoHideDuration: 2000,
            });
         } else if (updatedGroup.operationType === 'create') {
            setAvailablePersonGroups({
               groups: [
                  ...availablePersonGroups.groups,
                  {
                     _id: updatedGroup.groupData._id,
                     name: updatedGroup.groupData.name,
                     userCount: 0,
                  },
               ],
               loading: false,
            });
         } else if (updatedGroup.operationType === 'update') {
            setAvailablePersonGroups({
               groups: availablePersonGroups.groups.map((group) => {
                  if (group._id === updatedGroup.groupId) {
                     return {
                        _id: group._id,
                        name: updatedGroup.groupName,
                        userCount: group.userCount,
                     };
                  }
                  return group;
               }),
               loading: false,
            });
         } else if (updatedGroup.operationType === 'remove') {
            setAvailablePersonGroups({
               groups: availablePersonGroups.groups.filter((group) => group._id !== updatedGroup.groupId),
               loading: false,
            });
            onPageNumberChange(1);
            onGroupChanged({ _id: -1 });
         }
      },
   });

   useEffect(() => {
      if (!availablePersonGroups.loading) {
         getPersonGroups({
            variables: {
               input: {
                  customer: person.customer._id,
               },
            },
         });
         setAvailablePersonGroups({
            ...availablePersonGroups,
            loading: true,
         });
      }
   }, [personGroupData, getPersonGroups, person.customer._id, availablePersonGroups]);

   const columns = useMemo(() => getColumns(classes, handleRowClicked, t), [classes, handleRowClicked, t]);

   const handleSearch = useCallback(
      (value) => {
         onPageNumberChange(1);
         onSearchChanged(value);
      },
      [onPageNumberChange, onSearchChanged],
   );

   const handleGroupChange = useCallback(
      (value) => {
         onPageNumberChange(1);
         onGroupChanged(value);
      },
      [onPageNumberChange, onGroupChanged],
   );

   return (
      <Grid xs={12} className={classes.root}>
         <MaterialDataGrid
            components={{
               Toolbar: () => (
                  <TableToolbar
                     setCreateManualAccount={setCreateManualAccount}
                     availablePersonGroups={availablePersonGroups}
                     setEditGroup={setEditGroup}
                     setCreateGroup={setCreateGroup}
                     setDynamicGroup={setDynamicGroup}
                     setImportUsers={setImportUsers}
                     handleGroupChange={handleGroupChange}
                     selectedGroupData={selectedGroupData}
                     handleSearch={handleSearch}
                     searchValue={searchValue}
                     onPlatformChanged={onPlatformChanged}
                     onTeamChanged={onTeamChanged}
                     dateFilter={dateFilter}
                     excelExport={excelExport}
                     text={text}
                     setText={setText}
                     selectedPlatform={selectedPlatform}
                     selectedTeam={selectedTeam}
                     selectionModel={selectionModel}
                     handlePushUsersToGroup={handlePushUsersToGroup}
                     handleRemoveUsersFromGroup={handleRemoveUsersFromGroup}
                     setDeleteModalVisible={setDeleteModalVisible}
                     showEditGroup={showEditGroup}
                     showCreateGroup={showCreateGroup}
                     showDynamicGroup={showDynamicGroup}
                     showImportUsers={showImportUsers}
                     width={width}
                  />
               ),
               LoadingOverlay: () => (
                  <GridOverlay>
                     <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                        <LinearProgress />
                     </div>
                  </GridOverlay>
               ),
            }}
            className={classes.Table}
            checkboxSelectionVisibleOnly={true}
            columns={columns}
            checkboxSelection
            // Traslation On Table
            localeText={{
               columnsPanelTextFieldLabel: t?.contacts_label_column,
               columnsPanelTextFieldPlaceholder: t?.contacts_placeholder_column,
               columnsPanelHideAllButton: t?.contacts_hide_all_button,
               columnsPanelShowAllButton: t?.contacts_show_all_button,
               toolbarColumns: '',
               noRowsLabel: t?.no_data_to_show,
            }}
            rows={data ? data : []}
            getRowId={(row) => row._id}
            disableColumn
            pageSize={20}
            rowCount={totalCount}
            paginationMode='server'
            page={pageNumber - 1}
            onPageChange={(params) => {
               onPageNumberChange(params + 1);
            }}
            disableSelectionOnClick
            disableColumnMenu
            loading={loading}
            onRowClick={(data) => {
               getPersonCustomField({
                  variables: {
                     person: data.row._id,
                  },
               });
               handleRowClicked(data);
            }}
            rowHeight={64}
            classes={{ columnHeader: classes.rowStyle }}
            onSelectionModelChange={(newSelectionModel) => {
               setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
         />
         {showEditGroup.visible && <EditGroupModal showEditGroup={showEditGroup} setEditGroup={setEditGroup} />}
         {showCreateGroup.visible && (
            <CreateGroupModal
               setCreateGroup={setCreateGroup}
               showCreateManualAccount={showCreateManualAccount}
               showCreateGroup={showCreateGroup}
            />
         )}
         {showDynamicGroup.visible && (
            <DynamicGroupModal
               setDynamicGroup={setDynamicGroup}
               showCreateManualAccount={showCreateManualAccount}
               showDynamicGroup={showDynamicGroup}
            />
         )}
         {showCreateManualAccount.visible && (
            <CreateNewUserModal
               showCreateManualAccount={showCreateManualAccount}
               setCreateManualAccount={setCreateManualAccount}
            />
         )}
         {showImportUsers.visible && (
            <ImportUsersModal showImportUsers={showImportUsers} setImportUsers={setImportUsers} />
         )}

         <Modal
            open={isDeleteModalVisible}
            onClose={() => {
               setDeleteModalVisible(false);
               setIsButtonLoading(false);
            }}
            modalHeader={t['delete_contacts']}
            paperStyle={{ width: 450 }}
         >
            <ModalLargeText style={{ textAlign: 'center', marginTop: 30 }}>
               {t['delete_person_modal_are_you_sure']}
            </ModalLargeText>
            <ModalLargeText style={{ textAlign: 'center', marginBottom: 30 }}>
               {t?.delete_person_modal_text}
            </ModalLargeText>
            <ModalButtonField>
               <Button
                  onClick={() => setDeleteModalVisible(false)}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               >
                  {t['comp_button_cancel']}
               </Button>
               <ButtonCancel
                  disabled={isButtonLoading}
                  label={t['comp_button_delete']}
                  onClick={handleDeleteUsers}
                  endIcon={isButtonLoading && <CircularProgress size={20} color='inherit' />}
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#ef5350',
                     '&:hover': {
                        backgroundColor: '#f44336',
                     },
                  }}
               />
            </ModalButtonField>
         </Modal>
      </Grid>
   );
};

export default DataGrid;

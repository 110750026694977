import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { Fragment } from 'react';
const useStyles = makeStyles((theme) => ({
   countContainer: {
      backgroundColor: 'red',
      textAlign: 'center',
      width: 20,
      height: 20,
      borderRadius: 20,
      padding: 0,
      paddingTop: (props) => getPaddingTopBasedOnCount(props.count),
   },
   count: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: (props) => getFontSizedBasedOnCount(props.count),
      fontFamily: 'Helvetica',
      padding: 0,
      margin: 0,
   },
}));

const getFontSizedBasedOnCount = (count) => {
   if (count < 100) {
      return '0.9rem';
   } else {
      return '0.6rem';
   }
};

const getPaddingTopBasedOnCount = (count) => {
   if (count < 100) {
      return 0;
   } else {
      return 3;
   }
};

const NotifCount = (props) => {
   const classes = useStyles(props);
   let { count, children, max, noChild } = props;
   const displayBadge = false; //count > 0;
   const displayCount = count > max ? max + '+' : count;
   const childRef = useRef(null);
   const badgeRef = useRef(null);
   const [childWidth, setChildWidth] = useState(0);
   useEffect(() => {
      if (!noChild) {
         const width = childRef?.current?.getBoundingClientRect().width;
         const badgeWidth = badgeRef?.current?.getBoundingClientRect().width || 0;
         setChildWidth(width - badgeWidth);
      }
   }, [noChild]);

   const badge = displayBadge ? (
      <Paper ref={badgeRef} className={classes.countContainer}>
         <Typography className={classes.count}>{displayCount}</Typography>
      </Paper>
   ) : null;

   return (
      <Fragment>
         {noChild ? (
            <Fragment>{/* {badge} */}</Fragment>
         ) : (
            <Fragment>
               <div style={{ position: 'relative', zIndex: 1000 }}>
                  <div style={{ position: 'absolute', left: childWidth + 10, top: 0 }}>{badge}</div>
               </div>
               <span ref={childRef}>{children}</span>
            </Fragment>
         )}
      </Fragment>
   );
};

export default NotifCount;

import React, { useContext, useEffect, useState } from 'react';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import { Node } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { GET_CHAT_TAGS_QUERY } from '@queries/Settings/Service/GraphQL/ChatTags/query';
import { useLazyQuery } from '@apollo/client';
import SingleInput from '@components/_Modal/SingleInput';
import { useForm } from 'react-hook-form';
import { LanguageContext } from '@helper/locale/langContext';
import DeleteIcon from '@mui/icons-material/Delete';

interface ActionBlocksProps {
   leftNodes: Node[];
   clickedNodeId: string;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
}

const ActionBlocks: React.FC<ActionBlocksProps> = ({ leftNodes, setLeftNodes, clickedNodeId }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [searchSelectKey, setSearchSelectKey] = useState<number>(0);

   useEffect(() => {
      setSearchSelectKey((prevKey) => prevKey + 1);
   }, [clickedNodeId]);

   const useStyles = makeStyles((theme) => ({
      btnGroup: {
         height: 36,
         width: '100%',
         border: '1px dashed #B16AE7',
         borderRadius: '10px',
         background: 'transparent',
         cursor: 'pointer',
         color: '#B16AE7',
      },
      selectValue: {
         marginBottom: '10px',
         width: '100%',
      },
      deleteIcon: {
         color: 'gray',
         cursor: 'pointer',
      },
   }));

   const person = useSelector((rootReducer: any) => rootReducer.personReducer.person);
   const [chatTagsData, setChatTagsData] = useState([]);
   const CHAT_TAGS_PAGE_SIZE = 10;

   const {
      control,
      handleSubmit,
      watch,
      setValue,
      formState: { isValid },
   } = useForm({
      mode: 'all',
   });
   const [showSelect, setShowSelect] = useState<boolean>(false);
   const [isTagAdded, setIsTagAdded] = useState<boolean>(false);

   useEffect(() => {
      const clickNode = leftNodes.find((node) => node.id === clickedNodeId)?.data.flowBlocks;
      setValue('tags', clickNode.ruleId ? { label: clickNode.rule, value: clickNode.ruleId } : null);
      setShowSelect(clickNode.ruleId === null && !isTagAdded);
   }, [clickedNodeId, leftNodes, setValue, isTagAdded]);

   const [getChatTagQuery] = useLazyQuery(GET_CHAT_TAGS_QUERY, {
      fetchPolicy: 'network-only',
   });

   const getTagsLoadOptions = async (search: string | any[], prevOptions: string | any[]) => {
      let currentChatTagsData = chatTagsData;

      if (search.length !== 0) {
         if (search.length < 3) {
            return {
               options: currentChatTagsData?.map(({ name, _id, color }) => ({
                  value: _id,
                  label: name,
                  color,
               })),
               hasMore: false,
            };
         }
      }

      const getChatTagRequestData = await getChatTagQuery({
         variables: {
            customer: person.customer._id,
            search,
            pageSize: CHAT_TAGS_PAGE_SIZE,
            page: prevOptions.length === 0 ? 1 : prevOptions.length / CHAT_TAGS_PAGE_SIZE + 1,
         },
      })
         .then((res) => {
            currentChatTagsData = res.data.getChatTagList.data.docs;
            setChatTagsData(currentChatTagsData);
            return res.data.getChatTagList.data;
         })
         .catch(() => []);

      return {
         options: currentChatTagsData?.map(({ name, _id }: { name: string; _id: string }) => ({
            value: _id,
            label: name,
         })),
         hasMore: getChatTagRequestData.hasNextPage,
      };
   };

   const handleTagChange = (selectedOption: { label: string; value: string }) => {
      if (selectedOption) {
         setLeftNodes((prevNodes) => {
            return prevNodes.map((node) => {
               if (node.id === clickedNodeId) {
                  const updatedData = {
                     ...node.data,
                     flowBlocks: {
                        ...node.data.flowBlocks,
                        ruleId: selectedOption.value,
                        rule: selectedOption.label,
                     },
                  };

                  return {
                     ...node,
                     data: updatedData,
                  };
               }

               return node;
            });
         });
      }
   };
   const handleDeleteField = () => {
      setLeftNodes((prevNodes) => {
         return prevNodes.map((node) => {
            if (node.id === clickedNodeId) {
               const updatedData = {
                  ...node.data,
                  flowBlocks: {
                     ...node.data.flowBlocks,
                     ruleId: null,
                     rule: null,
                  },
               };

               return {
                  ...node,
                  data: updatedData,
               };
            }
            setIsTagAdded(false);
            return node;
         });
      });
   };

   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [hoveredBlockId, setHoveredBlockId] = useState<boolean>(false);

   const classes = useStyles();
   const handleMenuOpenBlock = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleMenuClose = () => {
      setAnchorEl(null);
   };
   const handleAddTag = () => {
      setIsTagAdded(true);
      setAnchorEl(null);
   };

   return (
      <div>
         <Grid container>
            <Grid item style={{ width: '100%' }}>
               {showSelect ? (
                  <button className={classes.btnGroup} onClick={(e) => handleMenuOpenBlock(e)}>
                     {t.atomation_flow_add_action}
                  </button>
               ) : (
                  <Grid
                     style={{ position: 'relative', display: 'flex', justifyContent: 'space-between' }}
                     onMouseEnter={() => setHoveredBlockId(true)}
                     onMouseLeave={() => setHoveredBlockId(false)}
                  >
                     <SingleInput
                        //key={tagKey}
                        name='tags'
                        type='tags'
                        label={t?.atomation_flow_action_add_tag}
                        isPaginateSelect
                        autoHeight
                        control={control}
                        maxMenuHeight={190}
                        selectOptions={chatTagsData?.map(({ name, _id }) => ({
                           value: _id,
                           label: name,
                        }))}
                        value={watch('tags')}
                        loadOptions={getTagsLoadOptions}
                        onChange={(tag: { label: string; value: string }) => {
                           handleTagChange(tag);
                        }}
                     />
                     {hoveredBlockId && (
                        <DeleteIcon
                           onClick={() => handleDeleteField()}
                           className={classes.deleteIcon}
                           style={{ position: 'absolute', right: -22, top: 20 }}
                        />
                     )}
                  </Grid>
               )}
            </Grid>
            <Menu
               anchorEl={anchorEl}
               open={Boolean(anchorEl)}
               onClose={handleMenuClose}
               anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
               style={{ marginTop: 10 }}
               PaperProps={{
                  style: {
                     width: '325px',
                     borderRadius: '2px',
                  },
               }}
            >
               <MenuItem onClick={handleAddTag}>{t.atomation_flow_action_add_tag}</MenuItem>
            </Menu>
         </Grid>
      </div>
   );
};

export default ActionBlocks;

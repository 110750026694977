import { useLazyQuery, useMutation } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import {
   CREATE_CUSTOMER_SATISFACTION_SURVEY_MUTATION,
   UPDATE_CUSTOMER_SATISFACTION_SURVEY_MUTATION,
} from '@queries/Settings/Service/GraphQL/CustomerSatisfaction/mutation';
import { GET_CUSTOMER_SATISFACTION_SURVEY } from '@queries/Settings/Service/GraphQL/CustomerSatisfaction/query';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import Nav from '../Components/Nav';
import PageLayout from '../Components/PageLayout';
import Form from './Form';
import Previews from './Previews';

const Survey = ({ isNewSurvey, person }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [activeNavButton, setActiveNavButton] = useState('survey');
   const [isThanksMessageClicked, setIsThanksMessageClicked] = useState(false);
   const [isClickSave, setIsClickSave] = useState(false);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const history = useHistory();
   const surveyId = useParams().id;

   const { handleSubmit, control, watch, setValue } = useForm({
      mode: 'all',
      defaultValues: {
         name: '',
         logo: null,
         title: '',
         message: '',
         commentInputTitle: '',
         submitButtonText: '',
         themeMainColor: '#007aff',
         thankYouMessage: '',
      },
   });

   const setSurveyValues = (survey) => {
      for (const key in survey) {
         if (survey.hasOwnProperty(key)) {
            setValue(key, survey[key]);
         }
      }
   };

   let [getSurveyData, { loading: surveyLoading }] = useLazyQuery(
      GET_CUSTOMER_SATISFACTION_SURVEY,
      {
         fetchPolicy: 'network-only',
         onCompleted: (data) => {
            setSurveyValues(data.getSurvey.data);
         },
         onError: (error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         },
      },
   );

   const [createSurveysMutation, { loading: createSurveyLoading }] = useMutation(
      CREATE_CUSTOMER_SATISFACTION_SURVEY_MUTATION,
   );
   const [updateSurveysMutation, { loading: updateSurveyLoading }] = useMutation(
      UPDATE_CUSTOMER_SATISFACTION_SURVEY_MUTATION,
   );

   const addSurveySubmit = async (formData) => {
      if (formData.themeMainColor === '') {
         setIsClickSave(true);
         return;
      }
      createSurveysMutation({
         variables: {
            input: {
               customer: person.customer._id,
               name: formData.name,
               logo: formData.logo,
               title: formData.title,
               message: formData.message,
               commentInputTitle: formData.commentInputTitle,
               submitButtonText: formData.submitButtonText,
               themeMainColor: formData.themeMainColor,
               thankYouMessage: formData.thankYouMessage,
            },
         },
      })
         .then(
            ({
               data: {
                  createSurveys: { data },
               },
            }) => {
               enqueueSnackbar(`${data.name} ${t['general_information_added_successfully']}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               });
               history.push('/settings/company/customer-satisfaction');
            },
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const updateSurveySubmit = async (formData) => {
      updateSurveysMutation({
         variables: {
            input: {
               _id: formData._id,
               customer: person.customer._id,
               status: formData.status,
               name: formData.name,
               logo: formData.logo,
               title: formData.title,
               message: formData.message,
               commentInputTitle: formData.commentInputTitle,
               submitButtonText: formData.submitButtonText,
               themeMainColor: formData.themeMainColor,
               thankYouMessage: formData.thankYouMessage,
            },
         },
      })
         .then((data) => {
            enqueueSnackbar(
               `${data.data?.updateSurveys.data.name} ${t['general_information_updated_successfully']}`,
               {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               },
            );
            history.push('/settings/company/customer-satisfaction');
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   useEffect(() => {
      if (!isNewSurvey) {
         getSurveyData({
            variables: {
               _id: surveyId,
            },
         });
      }
   }, []);

   const isLoading = surveyLoading || updateSurveyLoading || createSurveyLoading;

   if (isLoading) {
      return <div className='loading' />;
   }

   return (
      <>
         <PageLayout
            isNewSurvey={isNewSurvey}
            onSubmit={
               isNewSurvey ? handleSubmit(addSurveySubmit) : handleSubmit(updateSurveySubmit)
            }
            nav={<Nav activeNavButton={activeNavButton} setActiveNavButton={setActiveNavButton} />}
            form={
               <Form
                  isClickSave={isClickSave}
                  setValue={setValue}
                  control={control}
                  setIsThanksMessageClicked={setIsThanksMessageClicked}
                  state={watch()}
               />
            }
            previews={
               <Previews
                  watch={watch()}
                  isThanksMessageClicked={isThanksMessageClicked}
                  activeNavButton={activeNavButton}
               />
            }
         />
      </>
   );
};

export default Survey;

import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import { LanguageContext } from '@helper/locale/langContext';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { GET_PERSON_GROUPS } from '@queries/PersonGroups/Service/GraphQL/query';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import {
   CircularProgress,
   Stepper,
   StepLabel,
   TextField,
   Step,
   Typography,
   Checkbox,
   LinearProgress,
   Select,
   FormControl,
   MenuItem,
   Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getPerson } from '@store/reducers/person';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { IMPORT_PERSONS } from '@queries/Person/Service/GraphQL/mutation';
import { IMPORTED_PERSON_SUBSCRIPTION } from '../../../queries/Person/Service/GraphQL/subscription';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText, ModalMediumText, ModalTitle } from '@components/_Modal/ModalTexts';

const useStyles = makeStyles((theme) => ({
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 750,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      borderTop: '1px solid #F1F3F7',
   },
   innerContainer: {},
   uploadContainer: {
      width: '100%',
      height: 150,
      marginLeft: 5,
      marginRight: 5,
      backgroundColor: '#e9e9e9',
      borderRadius: 20,
      paddingTop: 20,
      textAlign: 'center',
      cursor: 'pointer',
   },
   selectRoot: {
      // border: '1px solid ' + theme.chatApp.general.pallet.passiveStructureBlue,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      backgroundColor: 'transparent',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 4,
      width: '100%',
      height: 44,
      paddingTop: 6,
      paddingLeft: 10,
      marginTop: '0px !important',
   },
   normalRow: {
      marginTop: '0px !important',
      marginBottom: '16px !important',
      background: '#e7e7e7',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const ImportUsersModal = ({ showImportUsers, setImportUsers }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar } = useSnackbar();
   const person = useSelector(getPerson);
   const token = useSelector((reducer) => reducer.personReducer.token);

   const [stateData, setStateData] = useState({
      isLoading: false,
      progressPercent: 0,
      activeStep: 0,
      licenseAgreement: false,
      userHeaders: [],
      exampleData: [],
      groups: [],
      file: null,
      success: 0,
      failed: 0,
   });

   const [importData, setImportData] = useState({
      customer: person.customer._id,
      selected_fields: [0, 1, 2, 3, 4, 5, 6, 7, 8],
      user_groups: [],
   });
   let steps = ['import_step_1', 'import_step_2', 'import_step_3', 'import_step_4'];

   const [getGroups] = useLazyQuery(GET_PERSON_GROUPS, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getPersonGroups }) => {
         setStateData({
            ...stateData,
            groups: getPersonGroups.data,
            isLoading: false,
            activeStep: stateData.activeStep + 1,
         });
      },
   });

   useSubscription(IMPORTED_PERSON_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         subscriptionData: {
            data: {
               importedPersons: { data: importedPersons },
            },
         },
      }) => {
         let percent = importedPersons.percent;
         setStateData({
            ...stateData,
            progressPercent: percent,
         });
      },
   });

   const [importPersons] = useMutation(IMPORT_PERSONS);

   let handleFile = (e) => {
      let file = e.target.files[0];
      //10mb limit
      if (file.size > 10000000) {
         enqueueSnackbar(t['upload_area_description'], {
            variant: 'error',
         });
         return;
      }

      setStateData({
         ...stateData,
         isLoading: true,
      });

      //1mb max read for header and example 1 line of data.
      //Rest will be read in server side
      var blob = file.slice(0, 1000000);
      let reader = new FileReader();
      reader.readAsText(blob);
      reader.onload = (e) => {
         let csvData = e.target.result;
         let csvRecordsArray = csvData.split(/\r\n|\n/);

         if (csvRecordsArray.length === 0 || csvRecordsArray.length === 1) {
            enqueueSnackbar(t['import_file_format_error'], {
               variant: 'error',
            });
            setStateData({
               ...stateData,
               isLoading: false,
            });
            return;
         }

         let userHeaders = csvRecordsArray[0].split(',').slice(0, 20);
         let exampleData = csvRecordsArray[1].split(',').slice(0, 20);

         setImportData({
            ...importData,
            selected_fields: userHeaders.map((e, idx) => -1),
         });

         setStateData({
            ...stateData,
            userHeaders,
            exampleData,
            file: file,
         });
      };
      reader.onerror = (_) => {
         enqueueSnackbar(t['import_file_format_error'], {
            variant: 'error',
         });
         setStateData({
            ...stateData,
            isLoading: false,
         });
      };
   };

   const MenuProps = {
      anchorOrigin: {
         vertical: 'bottom',
         horizontal: 'left',
      },
      getContentAnchorEl: null,
      PaperProps: {
         style: {
            maxHeight: 300,
            width: 250,
         },
      },
   };

   const kbValueNumberFormatter = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow',
   });

   return (
      <Modal
         open={showImportUsers.visible}
         onClose={() =>
            setImportUsers((state) => ({
               visible: false,
               data_loaded: state.data_loaded,
            }))
         }
         disableEscapeKeyDown
         disableBackdropClick
         modalHeader={t?.import_title}
      >
         <Stepper alternativeLabel activeStep={stateData.activeStep} className={classes.step}>
            {steps.map((title) => {
               return (
                  <Step key={title}>
                     <StepLabel>
                        <Typography className={classes.stepLabel}>{t[title]}</Typography>
                     </StepLabel>
                  </Step>
               );
            })}
         </Stepper>
         <div className={classes.innerContainer}>
            {stateData.activeStep === 0 && (
               <div>
                  <br />
                  <input type='file' accept='.csv' id='csv-select' hidden onChange={handleFile} />
                  <div
                     className={classes.uploadContainer}
                     onClick={() => {
                        document.getElementById('csv-select').click();
                     }}
                     style={{
                        border: stateData.file ? '1px solid #018cf9' : null,
                     }}
                  >
                     <h2>
                        <b>{stateData.file ? stateData.file.name : t?.upload_area_title}</b>
                     </h2>
                     <Typography>
                        {stateData.file
                           ? kbValueNumberFormatter.format(stateData.file.size)
                           : t?.upload_area_description}
                     </Typography>
                  </div>
                  <br />
                  <Typography>
                     {t?.upload_example_file}
                     <a
                        href={
                           lang.code === 'tr'
                              ? 'https://qpien-prod.s3.amazonaws.com/qpien_needed_files/qpien_example_contact_list_tr.csv'
                              : 'https://qpien-prod.s3.amazonaws.com/qpien_needed_files/qpien_example_contact_list_en.csv'
                        }
                        target='_blank'
                        rel='noopener noreferrer'
                     >
                        {' ' + t?.upload_example_file_link}
                     </a>
                  </Typography>
                  <br />
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        marginTop: 5,
                        gap: 8,
                     }}
                  >
                     <Checkbox
                        checked={stateData.licenseAgreement}
                        onChange={(event) => {
                           setStateData((state) => ({
                              ...state,
                              licenseAgreement: event.target.checked,
                           }));
                        }}
                        inputProps={{ 'aria-label': 'controlled' }}
                     />
                     <div>
                        <span>{t['create_new_contact_disclaimer']} </span>
                        <a
                           target='_blank'
                           rel='noopener noreferrer'
                           href={
                              lang.code === 'tr'
                                 ? 'https://www.qpien.com/tr/kullanim-kosullari'
                                 : 'https://www.qpien.com/user-agreement'
                           }
                        >
                           {t['create_new_contact_disclaimer_qpien']}
                        </a>
                        <span>{t['create_new_contact_disclaimer_end']}</span>
                     </div>
                  </div>
               </div>
            )}
            {stateData.activeStep === 1 && (
               <div style={{ height: '100%' }}>
                  <Grid container justifyContent='center'>
                     <Grid item xs={4} sm={4}>
                        <h3>{t?.import_column_name}</h3>
                     </Grid>
                     <Grid item xs={4} sm={4}>
                        <h3
                           style={{
                              marginLeft: 10,
                           }}
                        >
                           {t?.import_example_data}
                        </h3>
                     </Grid>
                     <Grid item xs={4} sm={4}>
                        <h3>{t?.import_qpien_field}</h3>
                     </Grid>
                  </Grid>
                  <Grid
                     container
                     justifyContent='center'
                     spacing={1}
                     style={{
                        overflow: 'auto',
                        height: 'calc(100% - 100px)',
                     }}
                  >
                     <Grid item xs={4} sm={4}>
                        {stateData.userHeaders.map((data, index) => {
                           return (
                              <div key={index}>
                                 <TextField
                                    fullWidth
                                    className={classes.normalRow}
                                    disabled
                                    variant='outlined'
                                    inputProps={{
                                       style: {
                                          color: 'black',
                                       },
                                    }}
                                    value={data}
                                 />
                              </div>
                           );
                        })}
                     </Grid>
                     <Grid item xs={4} sm={4}>
                        {stateData.exampleData.map((data, index) => {
                           return (
                              <div key={index}>
                                 <TextField
                                    fullWidth
                                    className={classes.normalRow}
                                    disabled
                                    variant='outlined'
                                    value={data}
                                    inputProps={{ style: { color: 'black' } }}
                                 />
                              </div>
                           );
                        })}
                     </Grid>
                     <Grid item xs={4} sm={4}>
                        <div>
                           {importData.selected_fields.map((field, index) => {
                              return (
                                 <FormControl fullWidth className={classes.selectRoot} key={index}>
                                    <Select
                                       style={{ marginTop: 0, borderRadius: 0 }}
                                       MenuProps={MenuProps}
                                       disableUnderline
                                       disabled={stateData.userHeaders[index] === ''}
                                       value={
                                          stateData.userHeaders[index] === '' ? -1 : importData.selected_fields[index]
                                       }
                                       onChange={(e) => {
                                          let new_array = importData.selected_fields;
                                          new_array[index] = Number(e.target.value);
                                          setImportData({
                                             ...importData,
                                             selected_fields: new_array,
                                          });
                                       }}
                                    >
                                       {Array(10)
                                          .fill(0)
                                          .map((_, i) => {
                                             return (
                                                <MenuItem
                                                   value={i - 1}
                                                   disabled={
                                                      i - 1 !== -1 ? importData.selected_fields.includes(i - 1) : false
                                                   }
                                                >
                                                   {t[`import_qpien_field_${i - 1}`]}
                                                </MenuItem>
                                             );
                                          })}
                                    </Select>
                                 </FormControl>
                              );
                           })}
                        </div>
                     </Grid>
                  </Grid>
                  <Typography style={{ marginTop: 20, textAlign: 'center', color: 'red' }}>
                     {!importData.selected_fields.includes(3) && !importData.selected_fields.includes(2)
                        ? t?.email_or_phone_required
                        : !importData.selected_fields.includes(0)
                        ? t?.name_field_required
                        : ''}
                  </Typography>
               </div>
            )}
            {stateData.activeStep === 2 && (
               <div>
                  <Grid container spacing={1} justifyContent='flex-start'>
                     <Grid item xs={1}>
                        <CheckCircleOutlineRoundedIcon
                           style={{
                              fontSize: 30,
                              color: '#4caf50',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              display: 'block',
                              height: '100%',
                           }}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <h3>
                           {t?.import_columns_imported.replace(
                              '{0}',
                              importData.selected_fields.filter((x) => x !== -1).length,
                           )}
                        </h3>
                     </Grid>
                  </Grid>
                  <Grid container spacing={1} justifyContent='flex-start'>
                     <Grid item xs={1}>
                        <CheckCircleOutlineRoundedIcon
                           style={{
                              fontSize: 30,
                              color: '#ecc024',
                              marginLeft: 'auto',
                              marginRight: 'auto',
                              display: 'block',
                              height: '100%',
                           }}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <h3>
                           {t?.import_columns_ignored.replace(
                              '{0}',
                              importData.selected_fields.filter((x) => x === -1).length,
                           )}
                        </h3>
                     </Grid>
                  </Grid>
                  <br />
                  <Typography>{t?.user_groups}</Typography>
                  <Autocomplete
                     disabled={(stateData.groups || []).length === 0 || stateData.isLoading}
                     fullWidth
                     multiple
                     options={(stateData.groups || [])
                        .filter((item) => !item.isDynamic)
                        .map((group) => ({
                           _id: group._id,
                           name: group.name,
                        }))}
                     value={importData.selected_groups}
                     getOptionLabel={(option) => {
                        return option.name;
                     }}
                     size='small'
                     noOptionsText={t['no_user_group']}
                     renderInput={(params) => (
                        <TextField
                           {...params}
                           className={classes.groupInput}
                           variant='outlined'
                           placeholder={importData.user_groups?.length === 0 ? t['user_groups'] : ''}
                        />
                     )}
                     onChange={(_, value) => {
                        //Remove duplicates
                        const unique = value.filter((v, i, a) => a.findIndex((t) => t._id === v._id) === i);
                        setImportData({
                           ...importData,
                           user_groups: unique,
                        });
                     }}
                  />
                  <Typography
                     style={{
                        display: stateData.isLoading ? '' : 'none',
                        marginTop: 20,
                        marginBottom: 20,
                        textAlign: 'center',
                     }}
                  >
                     {t?.import_info}
                  </Typography>
                  <LinearProgress
                     variant='determinate'
                     value={stateData.progressPercent}
                     style={{ display: stateData.isLoading ? '' : 'none' }}
                  />
               </div>
            )}
            {stateData.activeStep === 3 && (
               <div style={{ textAlign: 'center', paddingTop: 15 }}>
                  <CheckCircleOutlineRoundedIcon
                     style={{
                        fontSize: 120,
                        color: '00d600',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        display: 'block',
                        height: '100%',
                     }}
                  />
                  <ModalTitle>{t?.import_success_title}</ModalTitle>
                  <ModalMediumText>
                     {t?.import_success_description.replace('{0}', stateData.success).replace('{1}', stateData.failed)}
                  </ModalMediumText>
               </div>
            )}
         </div>
         <ModalButtonField style={{ marginTop: 15 }}>
            {stateData.activeStep !== steps.length - 1 ? (
               <ButtonCancel
                  className={classes.modalButtons}
                  disabled={stateData.isLoading}
                  label={stateData.activeStep === 0 ? t['comp_button_cancel'] : t['back']}
                  onClick={() => {
                     if (stateData.activeStep === 0) {
                        setImportUsers({
                           visible: false,
                           data_loaded: false,
                        });
                     } else {
                        setStateData({
                           ...stateData,
                           activeStep: stateData.activeStep - 1,
                        });
                     }
                  }}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
            ) : null}

            <ButtonOK
               className={classes.modalButtons}
               disabled={
                  stateData.isLoading ||
                  !stateData.licenseAgreement ||
                  (stateData.activeStep === 0 ? stateData.file === null : false) ||
                  (((!importData.selected_fields.includes(3) && !importData.selected_fields.includes(2)) ||
                     !importData.selected_fields.includes(0)) &&
                     stateData.activeStep === 1)
               }
               label={
                  stateData.activeStep === steps.length - 1
                     ? t['page_settings_button_close']
                     : stateData.activeStep === steps.length - 2
                     ? t['import']
                     : t['next']
               }
               style={{
                  display: stateData.activeStep === steps.length - 1 ? 'block' : undefined,
                  marginRight: stateData.activeStep === steps.length - 1 ? 'auto' : undefined,
                  color: stateData.activeStep === steps.length - 1 ? '#212121' : 'white',
                  backgroundColor: stateData.activeStep === steps.length - 1 ? '#eeee' : '#157CFC',
                  '&:hover': {
                     backgroundColor: stateData.activeStep === steps.length - 1 ? '#e0e0e0' : '#105CBB',
                  },
               }}
               endIcon={
                  stateData.isLoading && <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
               }
               onClick={() => {
                  if (stateData.activeStep === steps.length - 1) {
                     setImportUsers({
                        visible: false,
                        data_loaded: true,
                     });
                  } else if (stateData.activeStep === 1) {
                     getGroups({
                        variables: {
                           input: {
                              customer: person.customer._id,
                           },
                        },
                     });
                     setStateData((state) => ({
                        ...state,
                        isLoading: true,
                     }));
                  } else if (stateData.activeStep === steps.length - 2) {
                     setStateData((state) => ({
                        ...state,
                        isLoading: true,
                     }));
                     importPersons({
                        variables: {
                           customer: person.customer._id,
                           file: stateData.file,
                           fields: importData.selected_fields,
                           user_groups: importData.user_groups.map((x) => x._id),
                        },
                     })
                        .then(({ data }) => {
                           let { success, failed } = data.importPersons.data;
                           setStateData((state) => ({
                              ...state,
                              success,
                              failed,
                              isLoading: false,
                              activeStep: state.activeStep + 1,
                           }));
                        })
                        .catch((err) => {
                           if (err.message) {
                              if (err.message === 'INVALID_CSV') {
                                 enqueueSnackbar(t['import_invalid_csv'], {
                                    variant: 'error',
                                 });
                              } else if (err.message === 'REQUIRED_FIELDS') {
                                 enqueueSnackbar(t['import_required_fields'], {
                                    variant: 'error',
                                 });
                              }
                              setStateData((state) => ({
                                 ...state,
                                 isLoading: false,
                                 file: null,
                                 activeStep: 0,
                              }));
                           } else {
                              enqueueSnackbar(t['import_error_occured'], {
                                 variant: 'error',
                              });

                              setStateData((state) => ({
                                 ...state,
                                 isLoading: false,
                              }));
                           }
                        });
                  } else {
                     setStateData((state) => ({
                        ...state,
                        activeStep: state.activeStep + 1,
                     }));
                  }
               }}
            />
         </ModalButtonField>
      </Modal>
   );
};

export default ImportUsersModal;

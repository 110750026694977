import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useMemo } from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';
import { Button, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
   phone: {
      width: 300,
      height: 600,
      borderRadius: 50,
      border: '5px solid #D1D1D1',
      marginTop: '7vh',
      marginBottom: '6vh',
      backgroundColor: 'white',
   },
   phoneUserBar: {
      borderTopLeftRadius: 45,
      borderTopRightRadius: 45,
      backgroundColor: '#F7F7F7',
      height: 50,
      width: '100%',
      paddingTop: 15,
   },
   phoneUserBarInput: {
      width: 240,
      textAlign: 'center',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: 20,
      paddingRight: 20,
      borderRadius: 50,
      backgroundColor: 'white',
   },
   phoneMessageBar: {
      height: 500,
      width: '100%',
      wordWrap: 'overflow',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      overflow: 'auto',
      padding: '15px 5px 15px 5px',
   },
   phoneMessageMedia: {
      backgroundColor: '#F7F7F7',
      borderRadius: 15,
      marginBottom: 10,
      width: '90%',
      minHeight: 50,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: 10,
   },

   phoneMessageMediaImg: {
      width: 178,
      height: 142,
      objectFit: 'contain',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
   },

   phoneMessageText: {
      backgroundColor: '#F7F7F7',
      borderRadius: 5,
      width: '95%',
      padding: 10,
      textAlign: 'start',
      minHeight: 50,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      '&::webkit-scrollbar': {
         width: '0.4em',
         height: '0.4em',
      },
      '&::webkit-scrollbar-track': {
         height: '0.4em',
      },
   },

   phoneMessageEntity: {
      color: '#147CFC',
   },
   templateButtons: {
      backgroundColor: 'white',
      marginTop: 10,
      border: '1px solid #F7F7F7',
      boxShadow: '1px 1px 2px lightgray',
      fontSize: 18,
      paddingBlock: 10,
      textTransform: 'none !important',
   },
   templateDiv: {
      paddingRight: 10,
      paddingLeft: 10,
   },
   phoneBottom: {
      height: 50,
      borderBottomLeftRadius: 50,
      borderBottomRightRadius: 50,
   },
   '@global': {
      '::-webkit-scrollbar': {
         width: 6,
         transition: 'all .5s',
      },
      '::-webkit-scrollbar-track': {
         background: theme.chatApp.general.pallet.passiveStructureGrey,
      },
      '::-webkit-scrollbar-thumb': {
         background: '#C9CCD5',
      },
      '::-webkit-scrollbar-thumb:hover': {
         background: '#9399ab',
      },
   },
}));

const VirtualPhone = ({ media, sender, messageContent, buttons }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   let memoizedMedia = useMemo(() => {
      if (!media || !Array.isArray(media)) {
         return null;
      }
      
      try {
         return media
            .map((imedia) => {
               if (!imedia || !imedia.file || !imedia.size || !imedia.type) {
                  return null;
               }
               let [file_type] = imedia.type.split('/');
               let elements = [];
               switch (file_type) {
                  case 'image':
                     elements.push(
                        <img
                           className={classes.phoneMessageMediaImg}
                           alt='phoneMessageContent'
                           src={typeof imedia.file === 'string' ? imedia.file : URL.createObjectURL(imedia.file)}
                           style={{ width: '100%' }}
                        />,
                     );
                     break;
                  case 'video':
                     elements.push(
                        <video
                           className={classes.phoneMessageMediaImg}
                           src={typeof imedia.file == 'string' ? imedia.file : URL.createObjectURL(imedia.file)}
                           style={{ width: '100%', height: 'auto', marginBottom: 8 }}
                           autoPlay={true}
                           controls
                           muted
                        />,
                     );
                     break;
                  default:
                     return null;
               }
               return elements;
            })
            .filter((x) => !!x);
      } catch (e) {
         console.log('e', e);
         return null;
      }
   }, [media]);

   return (
      <div className={classes.phone}>
         <div className={classes.phoneUserBar}>
            <div
               className={classes.phoneUserBarInput}
               style={{
                  color: sender ? 'black' : 'red',
               }}
            >
               {sender ? sender : t['campaign_pick_a_sender']}
            </div>
         </div>
         <div className={classes.phoneMessageBar}>
            <div className={classes.phoneMessageText}>
               {memoizedMedia}
               {messageContent !== '' ? (
                  <div
                     dangerouslySetInnerHTML={{
                        __html: messageContent,
                     }}
                  />
               ) : (
                  <div>{t?.campaign_input_placeholder}</div>
               )}
            </div>
            {buttons && buttons.length > 0 && (
               <div className={classes.templateDiv}>
                  {buttons.map((x) => {
                     return (
                        <Button
                           className={classes.templateButtons}
                           fullWidth
                           variant='outlined'
                           color='primary'
                           startIcon={x.type === 'phone' ? <PhoneIcon /> : x.type === 'url' ? <OpenInNewIcon /> : null}
                        >
                           <Typography noWrap>{x.title}</Typography>
                        </Button>
                     );
                  })}
               </div>
            )}
         </div>
         <div className={classes.phoneBottom}></div>
      </div>
   );
};

export default WithUrlTranslations(VirtualPhone);

import {
   FormControl,
   MenuItem,
   Select,
   Typography,
   useMediaQuery,
   Button,
   ListItemText,
   TextField,
} from '@material-ui/core';
import { useQuery } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
// import Select from 'react-select';
import { getSocialMediaFromType } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';
import { USERS_TYPE } from '../Chat/types';
import DateRangePicker from '@components/DateRangePicker';
import SearchBar from '@components/SearchBar';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import Menu from '@material-ui/core/Menu';
import { GET_GROUP_LIST, GET_PERSON_GROUPS } from '@queries/PersonGroups/Service/GraphQL/query';
import { GET_TEAMS_QUERY } from '../../queries/Teams/query';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { getPerson } from '@store/reducers/person';
import InfiniteScroll from 'react-infinite-scroll-component';

const getPlatforms = (t) => {
   return [
      {
         value: 'ALL',
         label: t?.all_platforms,
         key: 'ALL_PLATFORMS',
      },
      {
         value: USERS_TYPE.WHATS_APP_WEB,
         label: 'WhatsApp Web',
         key: 'WHATSAPP_WEB',
      },
      {
         value: USERS_TYPE.WHATS_APP_BUSINESS,
         label: 'Whatsapp Business',
         key: 'WHATSAPP_BUSINESS',
      },
      {
         value: USERS_TYPE.FACEBOOK,
         label: 'Facebook Messenger',
         key: 'FACEBOOK_MESSENGER',
      },
      {
         value: USERS_TYPE.INSTAGRAM,
         label: 'Instagram',
         key: 'INSTAGRAM',
      },

      {
         value: USERS_TYPE.LIVE_CHAT,
         label: 'Live Chat',
         key: 'LIVE CHAT',
      },
      {
         value: USERS_TYPE.TRENDYOL,
         label: 'Trendyol',
         key: 'TRENDYOL',
      },

      {
         value: USERS_TYPE.HEPSIBURADA,
         label: 'Hepsiburada',
         key: 'HEPSIBURADA',
      },
      {
         value: USERS_TYPE.TELEGRAM,
         label: 'Telegram',
         key: 'TELEGRAM',
      },
      {
         value: USERS_TYPE.EMAIL,
         label: 'Email',
         key: 'EMAIL',
      },
   ];
};

const MenuProps = {
   anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
   },
   getContentAnchorEl: null,
   PaperProps: {
      style: {
         maxHeight: 300,
         width: 250,
      },
   },
};

const useStyles = makeStyles((theme) => ({
   container: {
      backgroundColor: '#fff',
      padding: 10,
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   innerLabel: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      // padding:0,
      marginTop: -12,
   },
   menuItem: {
      margin: 0,
      marginTop: 5,
      marginBottom: 5,
      padding: 0,
   },
   selector: {
      textAlign: 'left',
      border: 'solid 1px lightgrey',
      borderRadius: 5,
      paddingLeft: 3,
      height: 32,
   },
   selectRoot: {
      // border: '1px solid ' + theme.chatApp.general.pallet.passiveStructureBlue,
      border: '1px solid rgba(0, 0, 0, 0.23)',
      backgroundColor: 'transparent',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 4,
      width: '100%',
      height: '38px',
      paddingTop: '3px',
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   listItemText: {
      flex: '0 1 auto',
      '& .MuiTypography-body1': {
         overflow: 'hidden',
         'white-space': 'nowrap',
         'text-overflow': 'ellipsis',
      },
   },
   searchInput: {
      margin: '8px 0px 5px 10px !important',
      width: '90%',
   },
}));

const Page = {
   pageSize: 10,
   page: 1,
};

const TopToolbar = ({
   excelExport,
   onPlatformChanged,
   dateFilter,
   selectedPlatform,
   onTeamChanged,
   selectedTeam,
   handleDeleteUsers,
   personGroups,
   handleGroupAdd,
   handleGroupRemove,
   deleteButtonDisable = false,
   text,
   setText,
   selectedGroupData,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const t = lang?.translation;
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const PLATFORMS = getPlatforms(t);
   const [_selectedPlatform, setSelectedPlatform] = useState(selectedPlatform || PLATFORMS[0].value);
   const [teamsData, setTeamsData] = useState(null);
   const [_selectedTeam, setSelectedTeam] = useState(selectedTeam || { _id: 'ALL' });
   const handlePlatformSelect = (platform) => {
      setSelectedPlatform(platform.value);
      onPlatformChanged(platform.value);
   };
   const handleTeamSelect = (team) => {
      setSelectedTeam(team);
      onTeamChanged(team);
   };

   const [groupList, setGroupList] = useState([]);
   const [groupListCount, setGroupListCount] = useState(0);
   const [search, setSearch] = useState('');
   const me = useSelector(getPerson);

   useQuery(GET_TEAMS_QUERY, {
      variables: { customer: me.customer._id, pageSize: 100, page: 1 },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
         setTeamsData(data.getTeamList.data.docs);
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const platformsSelector = (
      <Grid item>
         <FormControl className={classes.selectRoot}>
            <Select
               style={{ marginTop: 0, borderRadius: 0 }}
               disableUnderline
               value={_selectedPlatform}
               renderValue={() => (
                  <Typography style={{ paddingLeft: 10, marginTop: -3 }}>
                     {_selectedPlatform !== 'ALL' ? getSocialMediaFromType(_selectedPlatform) : t?.all_platforms}
                  </Typography>
               )}
               MenuProps={MenuProps}
            >
               {PLATFORMS.map((option) => (
                  <MenuItem
                     style={{ paddingLeft: 0 }}
                     key={option.value}
                     value={option.value}
                     className={classes.menuItem}
                     onClick={() => handlePlatformSelect(option)}
                  >
                     <Typography style={{ marginLeft: 10 }}> {option.label} </Typography>
                  </MenuItem>
               ))}
            </Select>
         </FormControl>
      </Grid>
   );

   const teamsSelector = (
      <Grid item>
         <FormControl className={classes.selectRoot}>
            <Select
               style={{ marginTop: 0, borderRadius: 0 }}
               disableUnderline
               value={_selectedTeam}
               renderValue={() => (
                  <Typography style={{ paddingLeft: 10, marginTop: -3 }}>
                     {_selectedTeam._id !== 'ALL' && _selectedTeam ? _selectedTeam.teamName : t?.all_teams}
                  </Typography>
               )}
               MenuProps={MenuProps}
            >
               <MenuItem
                  style={{ paddingLeft: 0 }}
                  key={'ALL'}
                  value={'ALL'}
                  className={classes.menuItem}
                  onClick={() => handleTeamSelect('ALL')}
               >
                  <Typography style={{ marginLeft: 10 }}>All Teams</Typography>
               </MenuItem>
               {teamsData &&
                  teamsData.map((option) => (
                     <MenuItem
                        style={{ paddingLeft: 0 }}
                        key={option._id}
                        value={option._id}
                        className={classes.menuItem}
                        onClick={() => handleTeamSelect(option)}
                     >
                        <Typography style={{ marginLeft: 10 }}> {option.teamName} </Typography>
                     </MenuItem>
                  ))}
            </Select>
         </FormControl>
      </Grid>
   );

   const dateSelector = (
      <Grid item>
         <DateRangePicker
            onDateFilterChange={dateFilter.onDateFilterChange}
            startDate={dateFilter.fromDate}
            endDate={dateFilter.toDate}
            containerStyle={{ borderRadius: 4, border: '1px solid rgba(0, 0, 0, 0.23)' }}
         />
      </Grid>
   );
   const [add_anchorEl, setAddAnchorEl] = React.useState(null);
   const add_open = Boolean(add_anchorEl);
   const add_handleClick = (event) => {
      setAddAnchorEl(event.currentTarget);
   };
   const add_handleClose = (type) => {
      if (type !== -1) {
         handleGroupAdd(type);
      }
      setAddAnchorEl(null);
   };

   const [remove_anchorEl, setRemoveAnchorEl] = React.useState(null);
   const remove_open = Boolean(remove_anchorEl);
   const remove_handleClick = (event) => {
      setRemoveAnchorEl(event.currentTarget);
   };
   const remove_handleClose = (type) => {
      if (type != -1) {
         handleGroupRemove(type);
      }
      setRemoveAnchorEl(null);
   };

   const [getGroupListQuery] = useLazyQuery(GET_GROUP_LIST, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setGroupList([...groupList, ...data?.getGroupList?.data?.docs]);
         setGroupListCount(data?.getGroupList?.data?.docsCount);
      },
   });

   const handleNextAnswerPage = () => {
      if (groupList.length < groupListCount) {
         setGroupListParam({
            ...groupListParam,
            page: groupListParam.page + 1,
         });
      }
   };

   const [groupListParam, setGroupListParam] = useState({
      customer: me.customer._id,
      pageSize: Page.pageSize,
      page: Page.page,
      search: '',
   });

   useEffect(() => {
      setGroupList([]);
      setGroupListParam((prev) => ({ ...prev, search: search, page: Page.page }));
   }, [search]);

   useEffect(() => {
      getGroupListQuery({
         variables: {
            ...groupListParam,
         },
      });
   }, [groupListParam]);

   const Loader = () => {
      return (
         <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   };

   return (
      <Grid
         container
         direction={isSmallScreen ? 'column' : 'row'}
         alignItems={!isSmallScreen && 'center'}
         className={classes.container}
      >
         {/* Responsive Toolbar */}
         <Grid item xs={12} sm={12} md={2} style={{ paddingRight: 5 }}>
            <SearchBar
               value={text}
               onChange={setText}
               placeholder={`${t['page_chat_search_input_placeholder']} (${t['page_chat_typo_profile_name_family']})`}
               fullWidth
            />
         </Grid>
         <Grid item xs={12} sm={12} md={2} style={{ paddingRight: 5 }}>
            {teamsSelector}
         </Grid>
         <Grid item xs={12} sm={12} md={2} style={{ paddingRight: 5 }}>
            {platformsSelector}
         </Grid>
         <Grid item xs={12} sm={12} md={2} style={{ paddingRight: 5 }}>
            {dateSelector}
         </Grid>
         <Grid
            sm={12}
            md={4}
            item
            container
            alignItems='flex-end'
            justifyContent='flex-end'
            style={{ paddingRight: 0, paddingTop: isSmallScreen && 10 }}
         >
            <Button style={{ marginRight: 5 }} onClick={() => handleDeleteUsers()} disabled={deleteButtonDisable}>
               <DeleteIcon color='secondary' style={{ opacity: deleteButtonDisable ? 0.5 : 1 }} />
            </Button>
            <div style={{ marginRight: 5 }}>
               <Button
                  aria-controls={add_open ? 'group_menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={add_open ? 'true' : undefined}
                  startIcon={<AddIcon />}
                  variant='outlined'
                  disableElevation
                  onClick={add_handleClick}
                  disabled={personGroups.length === 0 || deleteButtonDisable}
                  style={{ backgroundColor: add_open && '#157CFC', color: add_open && '#fafafa' }}
               >
                  {t.comp_button_add}
               </Button>
               <Menu
                  id='fade-menu'
                  MenuListProps={{
                     'aria-labelledby': 'fade-button',
                     style: {
                        maxHeight: 300,
                        width: 250,
                        '& .MuiTypography-body1': {
                           overflow: 'hidden',
                           'white-space': 'nowrap',
                           'text-overflow': 'ellipsis',
                        },
                     },
                  }}
                  anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'left',
                  }}
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'left',
                  }}
                  getContentAnchorEl={null}
                  anchorEl={add_anchorEl}
                  open={add_open}
                  onClose={() => add_handleClose(-1)}
               >
                  <TextField
                     id='search-group-add-box'
                     label={t.page_chat_search_placeholder}
                     variant='outlined'
                     size='small'
                     fullWidth
                     value={search}
                     onChange={(e) => {
                        setSearch(e.target.value);
                     }}
                     // onClick={stopPropagation}
                     className={classes.searchInput}
                  />
                  <InfiniteScroll
                     scrollableTarget='scrollableDiv'
                     dataLength={groupList?.length || 0}
                     hasMore={groupList.length >= groupListCount ? false : true}
                     loader={<Loader />}
                     next={handleNextAnswerPage}
                     height={200}
                  >
                     {groupList.map((group) => {
                        return (
                           <MenuItem
                              onClick={() => add_handleClose(group._id)}
                              disableRipple
                              style={{
                                 backgroundColor: selectedGroupData._id === group._id ? 'rgb(235,235,235)' : undefined,
                                 borderLeft: selectedGroupData._id === -1 ? '4px solid #157CFC' : undefined,
                              }}
                           >
                              <ListItemText className={classes.listItemText}>{group.name}</ListItemText>
                           </MenuItem>
                        );
                     })}
                  </InfiniteScroll>
               </Menu>
            </div>
            <div style={{ marginRight: 5 }}>
               <Button
                  aria-controls={remove_open ? 'group_menu_r' : undefined}
                  aria-haspopup='true'
                  aria-expanded={remove_open ? 'true' : undefined}
                  variant='outlined'
                  startIcon={<RemoveIcon />}
                  disableElevation
                  onClick={remove_handleClick}
                  disabled={personGroups.length === 0 || deleteButtonDisable}
                  style={{ backgroundColor: remove_open && 'red', color: remove_open && '#fafafa' }}
               >
                  {t.remove}
               </Button>
               <Menu
                  id='fade-menu'
                  MenuListProps={{
                     'aria-labelledby': 'fade-button',
                     style: {
                        maxHeight: 300,
                        width: 250,
                     },
                  }}
                  anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'right',
                  }}
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
                  getContentAnchorEl={null}
                  anchorEl={remove_anchorEl}
                  open={remove_open}
                  onClose={() => remove_handleClose(-1)}
               >
                  <TextField
                     id='search-group-add-box'
                     label={t.page_chat_search_placeholder}
                     variant='outlined'
                     size='small'
                     fullWidth
                     value={search}
                     onChange={(e) => {
                        setSearch(e.target.value);
                     }}
                     // onClick={stopPropagation}
                     className={classes.searchInput}
                  />
                  <InfiniteScroll
                     scrollableTarget='scrollableDiv'
                     dataLength={groupList?.length || 0}
                     hasMore={groupList.length >= groupListCount ? false : true}
                     loader={<Loader />}
                     next={handleNextAnswerPage}
                     height={200}
                  >
                     {groupList.map((group) => {
                        return (
                           <MenuItem
                              onClick={() => remove_handleClose(group._id)}
                              disableRipple
                              style={{
                                 backgroundColor: selectedGroupData._id === group._id ? 'rgb(235,235,235)' : undefined,
                                 borderRight: selectedGroupData._id === -1 ? '4px solid red' : undefined,
                              }}
                           >
                              <ListItemText className={classes.listItemText}>{group.name}</ListItemText>
                           </MenuItem>
                        );
                     })}
                  </InfiniteScroll>
               </Menu>
            </div>
            <Button
               variant='outlined'
               startIcon={<CloudUploadIcon />}
               style={{ marginRight: 5 }}
               onClick={excelExport}
               height={38}
               fontSize='1rem'
               fontWeight='bold'
            >
               {t?.export}
            </Button>
         </Grid>
      </Grid>
   );
};

export default TopToolbar;

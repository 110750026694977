import { useMutation, useQuery } from '@apollo/client';
import ButtonOK from '@components/ButtonOK/buttonOk';
import CustomSwitch from '@components/Switch';
import { LanguageContext, getDefaultLang } from '@helper/locale/langContext';
import {
   FormControlLabel,
   Grid,
   Menu,
   MenuItem,
   Table,
   TableBody,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
   DELETED_CUSTOMER_SATISFACTION_MUTATION,
   UPDATE_CUSTOMER_SATISFACTION_SURVEY_MUTATION,
} from '@queries/Settings/Service/GraphQL/CustomerSatisfaction/mutation';
import { GET_CUSTOMER_SATISFACTION_SURVEYS } from '@queries/Settings/Service/GraphQL/CustomerSatisfaction/query';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { StyledTableCell, StyledTableRow } from '../shared-components/tableItems';
import DeleteSurvey from './DeleteSurvey';
import {
   ISurveys,
   SurveyProps,
   SurveyResponseMutation,
   UpdateSurveysMutationVariables,
   UpdateSurveysVariables,
} from './customerSatisfaction';

const useStyles = makeStyles((theme: any) => ({
   table: {
      minWidth: 700,
   },

   ButtonContainer: {
      paddingLeft: 5,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },

   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   label: {
      paddingRight: 8,
   },
}));

const CustomerSatisfaction = ({ person, token }: SurveyProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const history = useHistory();
   const location = useLocation();
   const path = location.pathname;
   const [contextMenuAnchor, setContextMenuAnchor] = useState(null);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [surveyData, setSurveyData] = useState<ISurveys[]>([]);
   const [selectedItem, setSelectedItem] = useState<Partial<ISurveys>>({
      _id: '',
      customer: '',
      status: false,
      name: '',
      logo: '',
      title: '',
      message: '',
      commentInputTitle: '',
      submitButtonText: '',
      themeMainColor: '',
      thankYouMessage: '',
   });

   const [updateSurveysMutation, { loading: updateSurveyLoading }] = useMutation<
      SurveyResponseMutation,
      UpdateSurveysMutationVariables
   >(UPDATE_CUSTOMER_SATISFACTION_SURVEY_MUTATION);

   const [deleteSurveysMutation, { loading: deleteSurveyLoading }] = useMutation(
      DELETED_CUSTOMER_SATISFACTION_MUTATION,
   );

   const { loading: surveysLoading } = useQuery(GET_CUSTOMER_SATISFACTION_SURVEYS, {
      variables: {
         pageSize: 20,
         pageNumber: 1,
         customer: person.customer._id,
         name: '',
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data: {
         getSurveys: {
            data: ISurveys[];
         };
      }) => {
         setSurveyData(data.getSurveys.data);
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const updateSurveySubmit = async (formData: UpdateSurveysVariables) => {
      updateSurveysMutation({
         variables: {
            input: {
               _id: formData._id,
               customer: person.customer._id,
               status: formData.status,
            },
         },
      })
         .then((data) => {
            enqueueSnackbar(
               `${data.data?.updateSurveys.data.name} ${t['general_information_updated_successfully']}`,
               {
                  variant: 'success',
                  autoHideDuration: 3000,
               },
            );
            setSurveyData(
               surveyData.map((item) => {
                  if (item._id === formData._id && data.data?.updateSurveys.data) {
                     return data.data?.updateSurveys.data;
                  }
                  return item;
               }),
            );
         })
         .catch((error: { message: string }) => {
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const handleDeleteSubmit = async () => {
      // await all results
      await deleteSurveysMutation({
         variables: {
            input: {
               _id: selectedItem._id,
               customer: person.customer._id,
            },
         },
      }) // run to delete mutation
         .then((data) => {
            enqueueSnackbar(
               `${data.data?.deleteSurveys.data.name} ${t['general_information_deleted_successfully']}`,
               {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               },
            );
            setSurveyData(surveyData.filter((item) => item._id !== selectedItem._id));
         })
         .catch((error) => {
            // error event
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const handleToggleStatus = (_id: string, status: boolean) => {
      updateSurveySubmit({
         _id,
         customer: person.customer._id,
         status: !status,
      });
   };

   const handleContextMenuOpen = (event: any, item: ISurveys) => {
      event.preventDefault();
      setContextMenuAnchor(event.currentTarget);
      setSelectedItem(item);
   };

   const handleContextMenuClose = () => {
      setContextMenuAnchor(null);
   };

   const handleNewSurvey = () => {
      history.push(`${path}/create-survey`);
   };
   const handleEdit = (item: any) => {
      handleContextMenuClose();
      history.push(`${path}/survey/${item._id}`);
   };

   const handleDelete = () => {
      // Implement your delete logic here using the selectedItem
      setShowDeleteModal(true);
      handleContextMenuClose();
   };

   const isLoading = surveysLoading || updateSurveyLoading || deleteSurveyLoading;

   if (isLoading) {
      return <div className='loading' />;
   }

   return (
      <Fragment>
         <Grid
            container
            justifyContent='space-between'
            alignItems='center'
            className={classes.titleContainer}
         >
            <div style={{ display: 'flex' }}>
               <Typography
                  style={{
                     marginLeft: 10,
                     fontSize: '1.1rem',
                     fontWeight: 'bold',
                     lineHeight: '35px',
                  }}
               >
                  {t['page_settings_typo_customer_satisfaction']}
               </Typography>
            </div>
            <Grid className={classes.ButtonContainer}>
               <Typography
                  style={{
                     marginRight: 10,
                     color: 'initial',
                  }}
               >
                  {t?.teams_limit} {person.customer.usageSurveyCount} /{' '}
                  {person.customer.package.surveyCount}
               </Typography>
               <ButtonOK
                  disabled={surveyData.length >= person.customer.package.surveyCount}
                  width={200}
                  height={40}
                  fontSize='1.1rem'
                  label={t.page_settings_typo_new_survey}
                  onClick={handleNewSurvey}
               />
            </Grid>
         </Grid>
         <Grid>
            <TableContainer>
               <Table className={classes.table} aria-label='customized table'>
                  <TableHead>
                     <TableRow>
                        <StyledTableCell align='left'>
                           {t['page_chat_typo_profile_name_family']}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                           {t['page_settings_typo_status']}{' '}
                        </StyledTableCell>
                        <StyledTableCell align='right'>
                           {t['page_settings_typo_last_modified']}
                        </StyledTableCell>
                        <StyledTableCell align='right'></StyledTableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {surveyData?.map((item: ISurveys) => (
                        <StyledTableRow key={item._id}>
                           <StyledTableCell component='th' scope='row'>
                              <Typography className={classes.coloredText}>{item.name}</Typography>
                           </StyledTableCell>
                           <StyledTableCell component='th' scope='row'>
                              <Grid container alignItems='center'>
                                 <FormControlLabel
                                    control={
                                       <CustomSwitch
                                          checked={item?.status}
                                          onChange={() => handleToggleStatus(item._id, item.status)}
                                       />
                                    }
                                    label=''
                                    labelPlacement='start'
                                    classes={{
                                       label: classes.label,
                                    }}
                                 />
                              </Grid>
                           </StyledTableCell>
                           <StyledTableCell align='right' component='th' scope='row'>
                              <Typography>
                                 {new Date(item?.updatedAt).toLocaleDateString(
                                    getDefaultLang()?.shortTitle,
                                    {
                                       year: 'numeric',
                                       month: 'short',
                                       day: 'numeric',
                                       hour: '2-digit',
                                       minute: '2-digit',
                                       hour12: false,
                                    },
                                 )}
                              </Typography>
                           </StyledTableCell>
                           <StyledTableCell align='right'>
                              <Grid
                                 container
                                 direction='row'
                                 justifyContent='flex-end'
                                 alignItems='center'
                              >
                                 <MoreVertIcon
                                    style={{ cursor: 'pointer' }}
                                    onClick={(e) => handleContextMenuOpen(e, item)}
                                 />
                              </Grid>
                           </StyledTableCell>
                        </StyledTableRow>
                     ))}
                  </TableBody>
                  <Menu
                     anchorEl={contextMenuAnchor}
                     open={Boolean(contextMenuAnchor)}
                     onClose={handleContextMenuClose}
                  >
                     <MenuItem onClick={() => handleEdit(selectedItem)}>
                        {t['page_settings_button_edit']}
                     </MenuItem>
                     <MenuItem onClick={handleDelete}>{t['page_chat_typo_reject']}</MenuItem>
                  </Menu>
               </Table>
            </TableContainer>
         </Grid>

         <DeleteSurvey
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            onComplate={handleDeleteSubmit}
         />
      </Fragment>
   );
};

export default CustomerSatisfaction;

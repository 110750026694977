import React, { FC } from 'react';
import { OrderProps } from '../../Store/types';
import OrderList from '../../SharedComponents/OrderList';

interface CustomerOrdersProps {
   openOrderDetail: (order: OrderProps) => void;
   customer: string;
}

const CustomerOrders: FC<CustomerOrdersProps> = ({ openOrderDetail, customer }) => {
   return (
     <div style={{ marginTop: 8 }}>
        <OrderList customer={customer} openOrderDetail={openOrderDetail} />
     </div>
   );
};

export default CustomerOrders;

import React from 'react';

const ActiveDot = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='29' height='29' viewBox='0 0 29 29'>
         <g id='Ellipse_58' data-name='Ellipse 58' fill='none' stroke='#178cf9' strokeWidth='2'>
            <circle cx='14.5' cy='14.5' r='14.5' stroke='none' />
            <circle cx='14.5' cy='14.5' r='13.5' fill='none' />
         </g>
         <circle
            id='Ellipse_63'
            data-name='Ellipse 63'
            cx='8.5'
            cy='8.5'
            r='8.5'
            transform='translate(6 6)'
            fill='#178cf9'
         />
      </svg>
   );
};

export default ActiveDot;

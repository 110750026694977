import React, { useContext } from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import { LanguageContext } from '../../../../../helper/locale/langContext';

const useStyles = makeStyles(() => ({
   container: {
      padding: '15px',
      display: 'flex',
      alignItems: 'center',
      borderBottom: '1px solid #cccccc24',
   },
   icon: {
      color: '#385273',
   },
   title: {
      marginLeft: '8px',
      color: '#385273',
   },
}));

const PreviewHeader = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <Box className={classes.container}>
         <VisibilityRoundedIcon className={classes.icon} />
         <Typography variant='body1' className={classes.title}>
            {t['page_settings_typo_platforms_live_chat_preview']}
         </Typography>
      </Box>
   );
};

export default PreviewHeader;

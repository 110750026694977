import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { TriggerIcon } from '../../../../FlowBots/FlowBuilder/icons/icons';
import { Edge, Node } from 'reactflow';
import TriggerBlocks from '../Blocks/triggerBlocks';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '5px 0',
      width: '100%',
   },
   subContainer: {
      padding: '0 18px 10px 18px',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100%',
      height: 'calc(100% - 65px)',
   },

   sendIcon: {
      width: 35,
      height: 35,
   },
   header: {
      display: 'flex',
      gap: '10px',
      marginBottom: '15px',
      paddingLeft: '18px',
      padding: '5px 18px 10px 18px',
      borderBottom: '1px solid #F7F7FA',
   },
   input: {
      border: 'none',
      outline: 'none',
      fontSize: '1.1rem',
   },
}));

interface TriggerPanelProps {
   leftNodes: Node[];
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   setIsSecondMessage: React.Dispatch<React.SetStateAction<boolean>>;
   clickedNodeId: string;
   isSecondMessage: boolean;
   heigherNode: number | undefined;
}

const TriggerLeftPanel: React.FC<TriggerPanelProps> = ({
   leftNodes,
   clickedNodeId,
   setLeftNodes,
   setClickedNodeId,
   heigherNode,
   setIsSecondMessage,
   isSecondMessage,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <Grid className={classes.container}>
         <Grid className={classes.header}>
            <TriggerIcon className={classes.sendIcon} />
            <Grid style={{ fontSize: '1.1rem', marginTop: '5px' }}>{t.atomation_flow_trigger_block_trigger}</Grid>
         </Grid>
         <Grid className={classes.subContainer} style={{ flex: 1 }}>
            <Grid>
               <TriggerBlocks setIsSecondMessage={setIsSecondMessage} isSecondMessage={isSecondMessage} />
            </Grid>
         </Grid>
      </Grid>
   );
};

export default TriggerLeftPanel;

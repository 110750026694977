import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, Typography, ClickAwayListener, IconButton } from '@material-ui/core';
import classNames from 'classnames';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DiscountCard from '../DiscountCard';
import { BillProps, ORDER_FLAGS } from '../../Tabs/E-Commerce/Tabs/Store/types';
import { DISCOUNT_TYPES, IDiscountProps } from '../../Tabs/E-Commerce/Tabs/Customer/types';
import { LanguageContext } from '@helper/locale/langContext';
import { currencyHandler } from '@helper/textUtils';

const useStyles = makeStyles(() => ({
   root: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 4,
      margin: 8,
      backgroundColor: '#f7f7fa',
      overflow: 'hidden',
   },
   row: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 8,
      alignItems: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
   },
   labelWithComponent: {
      display: 'flex',
      alignItems: 'center',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
   },
   footerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 8,
      alignItems: 'center',
      opacity: 0.6,
      backgroundColor: '#e2e2e2',
   },
   statusIconAndTitle: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
   },
   title: {
      color: '#385273',
      fontSize: '1.2em',
      lineHeight: 1.4,
   },
   icon: {
      fontSize: 36,
      marginRight: 8,
   },
   pendingIcon: {
      color: '#FE7F2D',
   },
   paidIcon: {
      color: '#88E011',
   },
   refundedIcon: {
      color: '#367D61',
   },
   smallText: {
      color: '#385273',
      fontSize: '1em',
      lineHeight: 1.3,
   },
   smokeText: {
      color: '#385273',
      fontSize: '1em',
      lineHeight: 1.3,
      opacity: 0.7,
   },
   largeText: {
      color: '#385273',
      fontSize: '1.2em',
      lineHeight: 1.4,
   },
   checkBox: {
      padding: '0px !important',
      transform: 'scale(0.8)',
      marginLeft: 5,
   },
   editButton: {
      padding: 3,
      marginLeft: 2,
   },
   clickAwayListenerContentContainer: {
      position: 'relative',
   },
   dropdown: {
      position: 'absolute',
      width: 'fit-content',
      right: 0,
      left: 0,
      bottom: 28,
      zIndex: 1,
   },
   overflowVisible: {
      overflow: 'visible',
   },
}));

interface PaymentCardProps {
   status: any;
   subTotal: number;
   tax: number;
   billIncludesTaxes: boolean;
   shipping: number;
   discount: IDiscountProps;
   total: number;
   currency: string;
   paidByCustomer?: number;
   payment?: any;
   setBill?: Dispatch<SetStateAction<BillProps>> | undefined;
   editable?: boolean;
   isNoteEditable?: boolean;
   toggleChargeTax?: () => void;
}

const calculateDiscount = (total: number, discount?: IDiscountProps) => {
   if (!discount || discount.discountValue === 0) return 0;
   const { discountType, discountValue } = discount;
   return discountType === DISCOUNT_TYPES.PERCENTAGE
      ? (total * discountValue) / 100
      : discountValue > total
      ? total
      : discountValue;
};

const PaymentCard: FC<PaymentCardProps> = ({
   status,
   subTotal,
   tax,
   billIncludesTaxes,
   shipping,
   discount,
   total,
   currency,
   paidByCustomer,
   payment,
   isNoteEditable,
   setBill,
   editable = false,
   toggleChargeTax,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [discountCardVisibility, setDiscountCardVisibility] = React.useState(false);
   const totalWithoutDiscount = subTotal + (billIncludesTaxes ? tax : 0);
   const discountValue = calculateDiscount(totalWithoutDiscount, discount);
   const newTotal = total || totalWithoutDiscount + shipping - discountValue + (payment || 0);

   const handleClick = () => {
      setDiscountCardVisibility((prev) => !prev);
   };

   const closeDiscountCard = () => {
      setDiscountCardVisibility(false);
   };

   const handleDiscountData = (data: IDiscountProps) => {
      if (setBill) {
         setBill((prevBill) => ({
            ...prevBill,
            discount: data,
         }));
      }
      closeDiscountCard();
   };
   // const Icon = () => {
   //    if (status === ORDER_FLAGS.PENDING) {
   //       return <CheckCircleRoundedIcon className={classNames(classes.icon, classes.pendingIcon)} />;
   //    } else if (status === ORDER_FLAGS.PAID) {
   //       return <CheckCircleRoundedIcon className={classNames(classes.icon, classes.paidIcon)} />;
   //    } else if (status === ORDER_FLAGS.REFUNDED) {
   //       return <CheckCircleRoundedIcon className={classNames(classes.icon, classes.refundedIcon)} />;
   //    }
   //    return null;
   // };

   return (
      <div className={classNames(classes.root, discountCardVisibility && classes.overflowVisible)}>
         {/* <div className={classes.row}>
            <div className={classes.statusIconAndTitle}>
               <Icon />
               <Typography variant='subtitle1' noWrap className={classes.title}>
                  {t[`page_e_commerce_store_status_option_${status}`]}
               </Typography>
            </div>
         </div> */}
         <div className={classes.row}>
            <Typography variant='body2' noWrap className={classes.smallText}>
               {t['page_e_commerce_store_payment_subtotal']}
            </Typography>
            <Typography variant='body2' noWrap className={classes.smallText}>
               {`${currencyHandler(currency)}${subTotal.toFixed(2)}`}
            </Typography>
         </div>
         {!!tax && (
            <div className={classes.row}>
               <div className={classes.labelWithComponent}>
                  <Typography variant='body2' noWrap className={classes.smallText}>
                     {t['page_e_commerce_store_payment_tax']}
                  </Typography>
                  {/* {editable && (
                  <React.Fragment>
                     <Checkbox
                        disableRipple
                        color="primary"
                        checked={bill.billIncludesTaxes}
                        onChange={toggleChargeTax}
                        className={classes.checkBox}
                     />
                     <Typography variant='body2' noWrap className={classes.smokeText} >
                        {t['page_e_commerce_payment_charge_taxes']}
                     </Typography>
                  </React.Fragment>
               )} */}
               </div>
               <Typography variant='body2' noWrap className={classes.smallText}>
                  {`${currencyHandler(currency)}${tax.toFixed(2)}`}
               </Typography>
            </div>
         )}
         <div className={classNames(classes.row, discountCardVisibility && classes.overflowVisible)}>
            <div className={classNames(classes.labelWithComponent, discountCardVisibility && classes.overflowVisible)}>
               <Typography variant='body2' noWrap className={classes.smallText}>
                  {t['page_e_commerce_store_payment_discount']}
               </Typography>
               {editable && (
                  <ClickAwayListener onClickAway={closeDiscountCard}>
                     <div className={classes.clickAwayListenerContentContainer}>
                        <IconButton type='button' onClick={handleClick} disableRipple className={classes.editButton}>
                           <EditRoundedIcon fontSize='small' />
                        </IconButton>
                        {discountCardVisibility && (
                           <div className={classes.dropdown}>
                              <DiscountCard
                                 close={closeDiscountCard}
                                 currency={currencyHandler(currency)}
                                 handleDiscountData={handleDiscountData}
                                 price={totalWithoutDiscount}
                                 currentDiscount={discount}
                                 isNoteEditable={isNoteEditable}
                              />
                           </div>
                        )}
                     </div>
                  </ClickAwayListener>
               )}
            </div>
            <Typography variant='body2' noWrap className={classes.smallText}>
               {`-${currencyHandler(currency)}${(discountValue || 0).toFixed(2)}`}
            </Typography>
         </div>
         <div className={classes.row}>
            <Typography variant='body2' noWrap className={classes.smallText}>
               {t['page_e_commerce_store_payment_shipping']}
            </Typography>
            <Typography variant='body2' noWrap className={classes.smallText}>
               {`${currencyHandler(currency)}${shipping?.toFixed(2)}`}
            </Typography>
         </div>
         {payment ? (
            <div className={classes.row}>
               <Typography variant='body2' noWrap className={classes.smallText}>
                  {t['page_e_commerce_store_payment_payment']}
               </Typography>
               <Typography variant='body2' noWrap className={classes.smallText}>
                  {`${currencyHandler(currency)}${payment?.toFixed(2)}`}
               </Typography>
            </div>
         ) : null}
         <div className={classes.row}>
            <Typography variant='body2' noWrap className={classes.largeText}>
               {t['page_e_commerce_store_payment_total']}
            </Typography>
            <Typography variant='body2' noWrap className={classes.largeText}>
               {`${currencyHandler(currency)}${newTotal?.toFixed(2)}`}
            </Typography>
         </div>
         {/* {paidByCustomer && (
            <div className={classes.footerContainer}>
               <Typography variant='body2' noWrap className={classes.largeText}>
                  {t['page_e_commerce_store_payment_paid_by_customer']}
               </Typography>
               <Typography variant='body2' noWrap className={classes.largeText}>
                  {`$${paidByCustomer}`}
               </Typography>
            </div>
         )} */}
      </div>
   );
};

export default PaymentCard;

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
   root: {
      '&$checked': {
         color: theme.palette.primary.main,
      },
   },
   checked: {},
}));

const TextInput = ({ id, label, isChecked }) => {
   const classes = useStyles();
   return (
      <FormControlLabel
         id={id}
         control={
            <Checkbox value='remember' checked={isChecked} classes={{ root: classes.root, checked: classes.checked }} />
         }
         label={label}
      />
   );
};

export default TextInput;

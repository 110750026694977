import { useQuery } from '@apollo/client';
import { Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_ME } from '@store/actions/person';
import { GET_CUSTOMER_QUERY } from '../../queries/Customer/Service/GraphQL/Customer/query';
import Panel from './Panel';
import Space from './Space';

// const LEFT_PANEL_WIDTH = 320;

const useStyles = makeStyles((theme) => ({
   content: {
      flexGrow: 1,
      height: '100vh',
      overflowY: 'auto',
      padding: 20,
      [theme.breakpoints.down('sm')]: {
         padding: 0,
      },
   },
   container: {
      margin: 0,
      height: 'calc(100% - 60px)',
      // padding:20,
   },
   leftPanel: {
      width: 330,
      [theme.breakpoints.down('md')]: {
         width: 70,
      },
   },
   appBarSpacer: theme.mixins.toolbar,
}));

const Account = () => {
   const classes = useStyles();
   const dispatch = useDispatch();
   // const token = useSelector((rootReducer) => rootReducer.personReducer.token);
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const width = useSelector((rootReducer) => rootReducer.uiReducer.width);
   useQuery(GET_CUSTOMER_QUERY, {
      fetchPolicy: 'network-only',
      variables: {
         _id: person.customer._id,
      },
      onCompleted: (data) => {
         if (data) {
            dispatch({
               type: UPDATE_ME,
               payload: {
                  person: {
                     ...person,
                     customer: {
                        ...person.customer,
                        ...data?.personCustomer?.data,
                     },
                  },
               },
            });
         }
      },
   });

   // subscription updated me
   // useSubscription(UPDATED_ME_SUBSCRIPTION, {
   //    variables: { token, _id: person ? person._id : '', customer: person.customer._id },
   //    onSubscriptionData: async ({
   //       client,
   //       subscriptionData: {
   //          data: {
   //             updatedMe: { data },
   //          },
   //       },
   //    }) => {
   //       dispatch({
   //          type: UPDATE_ME,
   //          payload: {
   //             person: {
   //                ...person,
   //                ...data,
   //             },
   //          },
   //       });
   //    },
   // });

   // updated customer product

   return (
      <main className={classes.content}>
         <Grid container className={classes.container} wrap='nowrap'>
            <Hidden xsDown>
               <div className={classes.leftPanel}>
                  <Panel />
               </div>
            </Hidden>
            <Grid item xs={12} lg={10} xl={10}>
               <Space width={width} />
            </Grid>
         </Grid>
      </main>
   );
};

export default React.memo(Account);

import React from 'react';
import PageLayout from '../SharedComponents/PageLayout';
import Nav from '../SharedComponents/Nav';
import Form from './Form';
import Previews from './Previews';

const Settings = ({ url }) => (
   <PageLayout nav={<Nav active='settings' url={url} />} form={<Form url={url} />} previews={<Previews />} />
);

export default Settings;

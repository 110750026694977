import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Badge } from '@material-ui/core';
import { NotificationIcon } from '@assets/icons/NotificationIcons/notificationIcon';

const useStyles = makeStyles((theme) => ({
   main: {
      fill: theme.chatApp.general.pallet.passiveStructureBlue,
      width: 20,
      height: 20,
   },
   container: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
   },
}));

const NotificationsButton = ({ notificationCount }) => {
   const classes = useStyles();
   return (
      <div>
         <Badge color='error' badgeContent={notificationCount} max={99} className={classes.container}>
            <NotificationIcon className={classes.main} />
         </Badge>
      </div>
   );
};
export default NotificationsButton;

import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import QRImage from 'react-qr-image';
import { ModalLargeText, ModalMediumText, ModalSmallText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 700,
      padding: 20,
      paddingBottom: 5,
      borderRadius: 15,
      // width: 700,
      height: 450,
      textAlign: 'center',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));
const QrCodeModal = ({ row, onCancel }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   return (
      <Grid>
         <ModalLargeText style={{ textAlign: 'center' }}>WhatsApp QR Code : {row.number}</ModalLargeText>
         <Grid container alignItems='center' justifyContent='space-evenly' style={{ marginTop: 20 }}>
            <Grid item xs={7} style={{ textAlign: 'left' }}>
               <ModalMediumText style={{ fontSize: '1rem', fontWeight: 'bold', marginBottom: 15 }}>
                  {t?.page_settings_modal_whatsapp_modal_qr_code_list_item}
               </ModalMediumText>
               <ModalSmallText>
                  1. {t?.page_settings_modal_whatsapp_modal_qr_code_list_item_open_your_phone}
               </ModalSmallText>
               <ModalSmallText>2. {t?.page_settings_modal_whatsapp_modal_qr_code_list_item_other}</ModalSmallText>
               <ModalSmallText>
                  3. {t?.page_settings_modal_whatsapp_modal_step_information_list_read_qr_code}
               </ModalSmallText>
               <ModalSmallText>4. {t?.page_settings_modal_whatsapp_modal_qr_warning}</ModalSmallText>
            </Grid>
            <Grid item xs={5}>
               <QRImage style={{ width: '100%' }} text={row.qrCode} />
            </Grid>
            <ModalButtonField>
               <ButtonCancel
                  label={t?.comp_button_cancel}
                  onClick={() => {
                     const data = {
                        _id: row._id,
                        status: false,
                     };
                     onCancel(data);
                  }}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
            </ModalButtonField>
         </Grid>
      </Grid>
   );
};

export default QrCodeModal;

import { useLazyQuery, useSubscription } from '@apollo/client';
import { LiveChatIcon, WhatsappIcon } from '@assets/icons/SettingsIcons/settingsIcons';
import { CampaignIcon } from '@assets/icons/SideBarIcons/sideBarIcons';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, Grid, IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import Analytics from '@material-ui/icons/Assessment';
import MoreVert from '@material-ui/icons/MoreVert';
import { GET_CAMPAIGNS, GET_CAMPAIGNS_COUNT } from '@queries/Campaigns/Service/GraphQL/query';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import WithUrlTranslations from '../../hoc/WithUrlTranslations';
import { UPDATED_CAMPAIGN_SUBSCRIPTION } from '../../queries/Campaigns/Service/GraphQL/subscription';
import CreateCampaign from './components/CreateCampaign';
import DataGridSearchBar from './components/DataGridSearchBar';
import RemoveCampaign from './components/RemoveCampaign';
import ReportModal from './components/ReportModal';

const useStyles = makeStyles((theme) => ({
   contentShow: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'hidden',
      paddingTop: '20vh',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '50%',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
         minHeight: '-webkit-fill-available',
         height: '100%',
      },
   },
   title: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
   },
   desc: {
      width: '50%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '1.1rem',
   },
   button: {
      paddingInline: 60,
      fontSize: 20,
   },
   content: {
      height: '100vh',
      overflowX: 'hidden',
      overflowY: 'auto',
      flexGrow: 1,
      padding: 25,
      paddingTop: '8vh',
   },
   paper: {
      borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
      width: '100%',
      borderRadius: 15,
      overflowY: 'auto',
      overflowX: 'hidden',
   },
   '@global': {
      '::-webkit-scrollbar': {
         width: 6,
         transition: 'all .5s',
      },
      '::-webkit-scrollbar-track': {
         background: theme.chatApp.general.pallet.passiveStructureGrey,
      },
      '::-webkit-scrollbar-thumb': {
         background: '#C9CCD5',
      },
      '::-webkit-scrollbar-thumb:hover': {
         background: '#9399ab',
      },
   },
   listIcon: {
      width: 30,
      height: 30,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 3,
   },
   rowStyle: {
      color: '#385273',
      fontWeight: 'bold',
      fontSize: '1rem',
   },
   Table: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
         outline: 'none',
      },
   },
}));

const Campaigns = () => {
   const classes = useStyles();
   const person = useSelector((reducer) => reducer.personReducer.person);
   const token = useSelector((reducer) => reducer.personReducer.token);
   const { lang } = useContext(LanguageContext);
   const history = useHistory();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const t = lang?.translation;

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, history, enqueueSnackbar, closeSnackbar, t]);

   const [noCampaigns, setNoCampaigns] = useState(false);
   const [isRemoveModalVisible, setRemoveModalVisible] = useState(null);
   const [isCreateModalVisible, setCreateModalVisible] = useState(false);
   const [isReportModalVisible, setReportModalVisible] = useState({
      visible: false,
      data: null,
   });
   const [isFirstLoad, setFirstLoad] = useState(true);

   const [campaignList, setCampaignList] = useState([]);
   const [campaignListCount, setCampaignListCount] = useState(0);
   let customer = person.customer._id;

   useSubscription(UPDATED_CAMPAIGN_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedCampaign: { data: updatedCampaign },
            },
         },
      }) => {
         const { campaignData } = updatedCampaign;
         if (updatedCampaign.operationType === 'remove') {
            if (campaignListCount === 1) {
               setRemoveModalVisible(null);
               setNoCampaigns(true);
            } else {
               setRemoveModalVisible(null);
               setCampaignListCount(campaignListCount - 1);
               setCampaignList(campaignList.filter((item) => item.id !== campaignData._id));
            }
         } else if (updatedCampaign.operationType === 'update') {
            setCampaignList(
               campaignList.map((item) => {
                  if (item.id === campaignData._id) {
                     let delivery_rate =
                        campaignData.success_count || campaignData.failure_count
                           ? (campaignData.success_count /
                                (campaignData.success_count +
                                   campaignData.failure_count +
                                   campaignData.platform_error_count)) *
                             100
                           : 0;
                     return {
                        id: campaignData._id,
                        name: campaignData.name,
                        platform_type: campaignData.platform_type,
                        platform_info: campaignData.platform_info,
                        delivery_rate,
                        status: campaignData.status,
                        total_recipient_count: campaignData.total_recipient_count,
                        cost: campaignData?.cost || 0,
                        updated_at: item.updatedAt,
                        created_at: campaignData.created_at,
                        schedule_date: campaignData.schedule_date,
                        success_count: campaignData.success_count,
                        failure_count: campaignData.failure_count,
                        platform_error_count: campaignData.platform_error_count,
                        options: -1,
                     };
                  } else {
                     return item;
                  }
               }),
            );
         }
      },
   });

   const [getCampaigns] = useLazyQuery(GET_CAMPAIGNS, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         let rows = data?.getCampaigns?.data.map((item, index) => {
            let delivery_rate =
               item.success_count || item.failure_count
                  ? (item.success_count / (item.success_count + item.failure_count + item.platform_error_count)) * 100
                  : 0;

            return {
               id: item._id,
               name: item.name,
               platform_info: item.platform_info,
               platform_type: item.platform_type,
               status: item.status,
               total_recipient_count: item.total_recipient_count,
               cost: item?.cost || 0,
               created_at: item.createdAt,
               updated_at: item.updatedAt,
               schedule_date: item.schedule_date,
               success_count: item.success_count,
               failure_count: item.failure_count,
               platform_error_count: item.platform_error_count,
               delivery_rate:delivery_rate % 1 !== 0 ? delivery_rate.toFixed(2) : delivery_rate,
               options: -1,
            };
         });
         setCampaignList(rows);
      },
   });

   const [getCampaignsCount, { data: dataCampaignsCount }] = useLazyQuery(GET_CAMPAIGNS_COUNT, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         if (data?.getCampaignsCount?.data === 0 && isFirstLoad) {
            setNoCampaigns(true);
         } else {
            setCampaignListCount(data.getCampaignsCount.data);
            setFirstLoad(false);
         }
      },
   });

   const [anchorEl, setAnchorEl] = useState(null);
   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const [queryParams, setQueryParams] = useState({
      pageNumber: 1,
      pageSize: 15,
      customer,
      name: null,
   });

   const [text, setText] = useState('');

   useEffect(() => {
      const time = setTimeout(() => {
         setQueryParams((prev) => ({ ...prev, name: text }));
      }, 500);

      return () => clearTimeout(time);
   }, [text]);

   const columns = [
      {
         field: 'platform_type',
         headerName: t['campaign_sender'],
         minWidth: 250,
         flex: 2,
         renderCell: ({ row }) => {
            let platform_icon;
            switch (row.platform_type) {
               case 'WHATS_APP':
                  platform_icon = <WhatsappIcon className={classes.listIcon} />;
                  break;
               case 'SMS':
               case 'EMAIL':
                  platform_icon = <LiveChatIcon className={classes.listIcon} />;
                  break;
               default:
                  platform_icon = <LiveChatIcon className={classes.listIcon} />;
                  break;
            }

            let sender = row.platform_info ? (
               <Typography
                  style={{
                     marginTop: 5,
                  }}
               >
                  {row.platform_info}
               </Typography>
            ) : (
               <div
                  style={{
                     width: 50,
                     height: 5,
                     padding: 5,
                     borderBottom: '2px solid grey',
                     marginTop: 5,
                     marginLeft: 5,
                  }}
               />
            );

            return (
               <Grid container spacing={1}>
                  <Grid item xs={2}>
                     {platform_icon}
                  </Grid>
                  <Grid item xs={10}>
                     {sender}
                  </Grid>
               </Grid>
            );
         },
      },
      {
         field: 'name',
         headerName: t['campaign_name'],
         flex: 3,
      },
      {
         field: 'status',
         headerName: t['status'],
         minWidth: 120,
         flex: 1,
         renderCell: ({ row }) => {
            switch (row.status) {
               case 'DRAFT':
                  return (
                     <Typography
                        style={{
                           color: 'rgba(0, 0, 0, 0.23)',
                        }}
                     >
                        {t['campaign_status_0']}
                     </Typography>
                  );
               case 'SCHEDULED':
                  return (
                     <Typography
                        style={{
                           color: '#4592e2',
                        }}
                     >
                        {t['campaign_status_1']}
                     </Typography>
                  );
               case 'SENDING':
                  return (
                     <Typography
                        style={{
                           color: 'rgb(255, 160, 0)',
                        }}
                     >
                        {t['campaign_status_2']}
                     </Typography>
                  );
               case 'SENT':
                  return (
                     <Typography
                        style={{
                           color: '#4caf50',
                        }}
                     >
                        {t['campaign_status_3']}
                     </Typography>
                  );
               case 'FAILED':
                  return (
                     <Typography
                        style={{
                           color: '#f44336',
                        }}
                     >
                        {t['campaign_status_4']}
                     </Typography>
                  );
               default:
                  return (
                     <Typography
                        style={{
                           color: 'rgba(0, 0, 0, 0.23)',
                        }}
                     >
                        {t['campaign_status_0']}
                     </Typography>
                  );
            }
         },
      },
      {
         field: 'total_recipient_count',
         flex: 2,
         minWidth: 170,
         headerName: t['campaign_total_recipients'],
         renderCell: ({ row }) => {
            return <p>{row.total_recipient_count}</p>;
         },
      },
      {
         field: 'cost',
         flex: 2,
         minWidth: 170,
         headerName: t['campaign_cost'],
         renderCell: ({ row }) => {
            if (row.cost || row.cost === 0)
               return <p>${row.cost.toFixed(2)}</p>;
            else
               return (
                  <div
                     style={{
                        width: 50,
                        height: 5,
                        padding: 5,
                        borderBottom: '2px solid grey',
                        marginTop: 5,
                        marginLeft: 0,
                     }}
                  />
               );
         },
      },
      {
         field: 'delivery_rate',
         flex: 2,
         minWidth: 150,
         headerName: t['campaign_delivery_rate'],
         renderCell: ({ row }) => {
            return (
               <div style={{ display: 'flex' }}>
                  <Typography style={{ lineHeight: 4, minWidth: 60 }}>
                     {row.success_count} (%{row.delivery_rate})
                  </Typography>
                  {row.status === 'SENT' || row.status === 'FAILED' ? (
                     <IconButton
                        aria-label='more'
                        onClick={() => {
                           setReportModalVisible({
                              visible: true,
                              data: row,
                           });
                        }}
                        style={{ marginTop: 4, width: 50, height: 50 }}
                     >
                        <Analytics style={{ color: 'green' }} />
                     </IconButton>
                  ) : null}
               </div>
            );
         },
      },
      {
         field: 'schedule_date',
         flex: 2,
         minWidth: 150,
         headerName: t['campaign_date'],
         renderCell: (params) =>
            params.row.schedule_date ? (
               <p>{moment(params.row.schedule_date).format('DD/MM/YYYY HH:mm')}</p>
            ) : (
               <p>{moment(params.row.updated_at).format('DD/MM/YYYY HH:mm')}</p>
            ),
      },
      {
         field: 'options',
         headerName: t['campaign_action'],
         flex: 1,
         minWidth: 150,

         renderCell: ({ row }) => {
            return (
               <div>
                  <IconButton
                     disabled={row.status === 'SENDING'}
                     variant='plain'
                     id={row.id}
                     aria-controls={!!anchorEl && anchorEl.id === row.id ? `mnu${row.id}` : undefined}
                     aria-haspopup='true'
                     aria-expanded={!!anchorEl && anchorEl.id === row.id ? 'true' : undefined}
                     onClick={handleClick}
                  >
                     <MoreVert />
                  </IconButton>
                  <Menu
                     id={`mnu${row.id}`}
                     aria-labelledby={row.id}
                     anchorEl={anchorEl}
                     open={!!anchorEl && anchorEl.id === row.id}
                     onClose={handleClose}
                     getContentAnchorEl={null}
                     style={{ position: 'fixed', top: -9 }}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                     }}
                     transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                     }}
                  >
                     {(row.status === 'SENT' || row.status === 'DRAFT' || row.status === 'SCHEDULED') && (
                        <MenuItem
                           onClick={() => {
                              handleClose();
                              window.location.href = `/campaigns/${row.id}`;
                           }}
                        >
                           {row.status === 'SENT' ? t['comp_button_view'] : t['page_chat_typo_profile_edit']}
                        </MenuItem>
                     )}
                     {(row.status === 'DRAFT' || row.status === 'FAILED' || row.status === 'SCHEDULED') && (
                        <MenuItem
                           onClick={() => {
                              handleClose();
                              setRemoveModalVisible(row.id);
                           }}
                        >
                           {t['comp_button_delete']}
                        </MenuItem>
                     )}
                  </Menu>
               </div>
            );
         },
      },
   ];

   useEffect(() => {
      getCampaigns({
         variables: {
            ...queryParams,
         },
      });
      getCampaignsCount({
         variables: {
            ...queryParams,
         },
      });
   }, [getCampaignsCount, getCampaigns, queryParams]);

   return (
      <main className={noCampaigns ? classes.contentShow : classes.content}>
         <div
            style={{
               height: '100%',
               width: '100%',
               display: noCampaigns ? 'block' : 'none',
            }}
         >
            <CampaignIcon
               className={''}
               style={{
                  width: '120px',
               }}
            />
            <br />
            <p className={classes.title}>{t['campaign_page_desc_1']}</p>
            <p className={classes.desc}>{t['campaign_page_desc_2']}</p>
            <br />
            <Button
               color='primary'
               variant='contained'
               className={classes.button}
               onClick={() => setCreateModalVisible(true)}
            >
               {t['comp_navbar_campaign_new']}
            </Button>
            <CreateCampaign
               open={isCreateModalVisible}
               onClose={() => {
                  setCreateModalVisible(false);
               }}
            />
         </div>
         <div
            style={{
               height: '100%',
               width: '100%',
               display: isFirstLoad || noCampaigns ? 'none' : 'block',
            }}
         >
            <Grid
               container
               wrap='nowrap'
               style={{
                  height: '100%',
               }}
            >
               <Paper elevation={1} className={classes.paper}>
                  <DataGrid
                     loading={!dataCampaignsCount}
                     components={{
                        Toolbar: (props) => {
                           return (
                              <DataGridSearchBar
                                 onSearchChange={setText}
                                 searchValue={text}
                                 onNewCampaign={() => setCreateModalVisible(true)}
                              />
                           );
                        },
                     }}
                     className={classes.Table}
                     disableSelectionOnClick
                     localeText={{
                        toolbarColumns: '',
                        noRowsLabel: t?.no_data_to_show,
                     }}
                     rowHeight={64}
                     disableColumnMenu
                     disableColumnSelector
                     classes={{ columnHeader: classes.rowStyle }}
                     pageSize={15}
                     paginationMode='server'
                     page={queryParams.pageNumber - 1}
                     onPageChange={(params) => {
                        setQueryParams((prevState) => ({
                           ...prevState,
                           pageNumber: params + 1,
                        }));
                     }}
                     disableColumn
                     rows={campaignList}
                     rowCount={campaignListCount}
                     columns={columns}
                  />
               </Paper>
            </Grid>
         </div>

         <CreateCampaign
            open={isCreateModalVisible}
            onClose={() => {
               setCreateModalVisible(false);
            }}
         />
         <RemoveCampaign
            open={!!isRemoveModalVisible}
            onClose={() => {
               setRemoveModalVisible(null);
            }}
            campaignId={isRemoveModalVisible}
         />
         <ReportModal
            open={isReportModalVisible.visible}
            campaignData={isReportModalVisible.data}
            onClose={() =>
               setReportModalVisible({
                  visible: false,
                  data: isReportModalVisible.data,
               })
            }
         />
      </main>
   );
};

export default WithUrlTranslations(Campaigns);

import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';

interface IProps {
   value: number;
   title: string;
   applyWrap?: boolean;
}
const useStyles = makeStyles((theme: any) => ({
   root: {
      padding: 10,
      borderRadius: 15,
      backgroundColor: '#fff',
      border: '1px solid #E7E7E7',
      height: 120,
   },
   title: {
      textAlign: 'left',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      color: '#97A7BF',
      marginLeft: 5,
   },
   label: {
      fontSize: '1.8rem',
      textAlign: 'center',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
}));

const Chip: FC<IProps> = ({ title, value, applyWrap }) => {
   const classes = useStyles();
   return (
      <Grid container wrap='nowrap' className={classes.root} direction='column'>
         <Grid item>
            <Typography noWrap className={classes.title}>
               {title}
            </Typography>
         </Grid>
         <Grid item container justifyContent='center' style={{ marginTop: 10 }}>
            <Typography className={classes.label} style={{
               ...(applyWrap && {
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden"
               })
            }}> {value}</Typography>
         </Grid>
      </Grid>
   );
};

Chip.defaultProps = {
   title: '',
   value: 0,
};

export default Chip;

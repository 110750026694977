import { gql } from '@apollo/client';

export const BULK_SHARING_FOLDER_PROCESS = gql`
   subscription bulkSharingFolderProcess($token: String!, $customer: ID!) {
      bulkSharingFolderProcess(input: { token: $token, customer: $customer }) {
         data {
            customer
            folderName
            percentage
         }
         success
         code
         message
      }
   }
`;

export const BULK_SHARING_CREATE = gql`
   subscription bulkSharingCreate($token: String!, $customer: ID!) {
      bulkSharingCreate(input: { token: $token, customer: $customer }) {
         data {
            _id
            customer
            name
            telegramStatus
            whatsAppStatus
            whatsAppSendFolderNames
            telegramSendFolderNames
            qpienPersonGroups
            whatsAppGroups
            telegramChannels
            folders {
               folderName
               folderLink
               telegramStatus
               whatsAppStatus
               length
            }
         }
         success
         code
         message
      }
   }
`;

export const BULK_SHARING_UPDATE = gql`
   subscription bulkSharingUpdate($token: String!, $customer: ID!) {
      bulkSharingUpdate(input: { token: $token, customer: $customer }) {
         data {
            _id
            customer
            name
            telegramStatus
            whatsAppStatus
            whatsAppSendFolderNames
            telegramSendFolderNames
            qpienPersonGroups
            whatsAppGroups
            telegramChannels
            folders {
               folderName
               folderLink
               telegramStatus
               whatsAppStatus
               length
            }
         }
         success
         code
         message
      }
   }
`;

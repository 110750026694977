import { createContext, Dispatch, SetStateAction } from 'react';

interface WhatsAppBusinessSettingsContextValue {
   value: string;
   setValue: Dispatch<SetStateAction<string>>;
}

const WhatsAppBusinessSettingsContext = createContext<WhatsAppBusinessSettingsContextValue>({
   value: '',
   setValue: () => {},
});

export default WhatsAppBusinessSettingsContext;

import React from 'react';
import { ButtonBase, CircularProgress, Grid, IconButton, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ImageIcon from '@material-ui/icons/Image';
import { useState } from 'react';
import Modal from '../Modal/modal';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
   image: {
      width: (props) => props.imageWidth || 64,
      height: (props) => props.imageHeight || 64,
   },
   closeIconContainer: {
      width: 30,
      height: 30,
      position: 'relative',
      left: 10,
      top: -5,
   },
   closeIcon: {
      width: 25,
      height: 25,
   },
   imageName: {
      fontSize: '1rem',
      color: 'grey',
      cursor: 'pointer',
      marginTop: 5,
      [theme.breakpoints.down('sm')]: {
         display: 'none',
      },
   },
}));
const ImageViewer = ({ url, name, isLoading, showPreview, onClose, disabled, preview, ...props }) => {
   const [showModal, setShowModal] = useState(false);
   const classes = useStyles(props);
   return (
      <Grid
         container
         direction='row'
         justifyContent='space-between'
         alignItems='flex-start'
         wrap='nowrap'
         style={{ backgroundColor: disabled ? 'lightgrey' : 'transparent' }}
      >
         <Grid
            container
            justifyContent='space-around'
            alignItems='flex-start'
            direction='column'
            style={{ padding: 10, paddingBottom: 5 }}
         >
            <Grid container direction='row' alignItems='flex-start'>
               {showPreview &&
                  (url ? (
                     <ButtonBase onClick={() => setShowModal(true)}>
                        <img src={url} className={classes.image} alt={name} />
                     </ButtonBase>
                  ) : (
                     <ImageIcon style={{ fontSize: '2.5rem', color: 'grey' }} />
                  ))}
               {!preview && (
                  <IconButton className={classes.closeIconContainer} onClick={onClose}>
                     <CloseIcon className={classes.closeIcon} />
                  </IconButton>
               )}
            </Grid>

            <Typography className={classes.imageName} onClick={() => setShowModal(true)}>
               {' '}
               {name}
            </Typography>
            {isLoading && <CircularProgress />}
            <Modal
               open={showModal}
               onClose={() => setShowModal(false)}
               paperStyle={{
                  width: 'auto',
                  height: 'auto',
                  backgroundColor: 'transparent',
                  boxShadow: 'unset',
               }}
            >
               <img src={url} alt={name} style={{ width: '100%', height: 'auto' }} />
            </Modal>
         </Grid>
      </Grid>
   );
};

export default ImageViewer;

import React, { useContext, useCallback, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress, Typography } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm } from 'react-hook-form';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';
import SearchSelect from 'react-select';

const OPTIONS = [
   {
      value: 'quickReply',
      label: 'Quick reply',
   },
   {
      value: 'url',
      label: 'Url',
   },
];
const OPTIONS_URL = [
   {
      value: 'url',
      label: 'Url',
   },
];
const OPTIONS_QUICK = [
   {
      value: 'quickReply',
      label: 'Quick reply',
   },
];

interface ModalContentProps {
   onSubmit: (data: { title: string; type: string; url: string }) => void;
   value?: { title: string | null; type: string | null; url: string | undefined };
   closeModal: () => void;
   deleteAll?: () => void;
   deleteReply?: () => void;
   isLoading?: Boolean;
   isOnlyUrl?: Boolean;
   isCreate?: Boolean;
   isCreateUrl?: string;
   isHasQickReply?: Boolean;
   isWelcome?: Boolean;
   hasMultipleRows?: Boolean;
   isOnlyQuickReply?: Boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   container: {
      width: '100%',
   },
   ruleSelect: {
      width: '100%',
      marginBottom: '15px',
   },
}));

const InformativeButtonModal: React.FC<ModalContentProps> = ({
   value,
   deleteReply,
   onSubmit,
   closeModal,
   isCreate,
   isCreateUrl,
   isLoading,
   deleteAll,
   isHasQickReply,
   isWelcome,
   hasMultipleRows,
   isOnlyUrl,
   isOnlyQuickReply,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' justifyContent='center' xs={12}>
            <CircularProgress size={20} color='inherit' />
         </Grid>
      );
   }, []);

   const {
      handleSubmit,
      control,
      setValue,
      getValues,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         title: value && value.title ? value.title : '',
         type: value && value.type ? value.type : '',
         url: value && value.url ? value.url : '',
      },
   });
   const [showUrl, setShowUrl] = useState(value && value.type === 'url');

   useEffect(() => {
      if (isOnlyUrl) {
         setShowUrl(true);
      }
   }, [isOnlyUrl]);

   const handleFormSubmit = (data: { title: string; type: string; url: string }) => {
      closeModal();

      let dataVal: any = {};
      if (data.type === '' && data.url === '') {
         dataVal = { title: data.title, type: 'quickReply', url: '' };
      } else if (data.type === 'quickReply') {
         dataVal = { title: data.title, type: 'quickReply', url: '' };
      } else if (data.type === '' && data.url.length > 1) {
         dataVal = { title: data.title, type: 'url', url: data.url };
      } else {
         dataVal = data;
      }
      onSubmit(dataVal);
   };

   const handleSelectChange = (type: string) => {
      setShowUrl(type === 'url');
      setValue('type', type);
   };
   const customStyles = {
      control: (base: any) => ({
         ...base,
         height: 45,
      }),
   };

   const isValidUrl = (url: string): boolean => {
      const urlPattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      return urlPattern.test(url);
   };
   const isOkay = (isValid && !showUrl) || (isValid && showUrl && isValidUrl(getValues('url')));

   return (
      <div className={classes.container}>
         <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container>
               <SingleInput
                  label={t['automation_flow_Informative_button_title']}
                  name='title'
                  placeholder={t['automation_flow_quick_reply_title_enter']}
                  control={control}
                  rules={{
                     required: t['automation_flow_quick_reply_title_enter_rule'],
                     maxLength: { value: 20, message: t?.automation_flow_flow_bot_title_max_length20 },
                  }}
               />
               <Grid style={{ width: '100%', marginTop: '-5px' }}>
                  <Typography style={{ marginLeft: 5 }}>{t.automation_flow_Informative_button_type}</Typography>
                  <SearchSelect
                     label={t['automation_flow_Informative_button_type']}
                     name='type'
                     isSelect={true}
                     styles={customStyles}
                     className={classes.ruleSelect}
                     control={control}
                     onChange={(e) => {
                        if (e) {
                           handleSelectChange(e.value);
                        }
                     }}
                     options={
                        isOnlyQuickReply
                           ? OPTIONS_QUICK
                           : (isCreate && isCreateUrl === 'url') || isOnlyUrl
                           ? OPTIONS_URL
                           : isCreate && isCreateUrl === 'quickReply'
                           ? OPTIONS_QUICK
                           : hasMultipleRows
                           ? OPTIONS_QUICK
                           : OPTIONS
                     }
                     defaultValue={
                        value?.type
                           ? {
                                value: value.type,
                                label: value.type === 'url' ? 'Url' : 'Quick reply',
                             }
                           : isOnlyUrl
                           ? {
                                value: 'url',
                                label: 'Url',
                             }
                           : {
                                value: 'quickReply',
                                label: 'Quick reply',
                             }
                     }
                     placeholder={t['automation_flow_Informative_button_type']}
                  />
               </Grid>
               {showUrl && (
                  <SingleInput
                     label={t['automation_flow_Informative_button_url']}
                     name='url'
                     placeholder={t['automation_flow_quick_reply_title_enter']}
                     control={control}
                     rules={{
                        pattern: {
                           value: /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
                           message: t.automation_flow_invalid_url_format,
                        },
                     }}
                  />
               )}

               <ModalButtonField>
                  {!isCreate ? (
                     <Button
                        disabled={!isHasQickReply}
                        className={classes.modalButtons}
                        onClick={deleteReply}
                        style={{
                           color: 'white',
                           backgroundColor: 'red',
                           opacity: !isHasQickReply ? 0.4 : '',
                        }}
                     >
                        {t.page_chat_typo_reject}
                     </Button>
                  ) : (
                     <Button
                        className={classes.modalButtons}
                        onClick={isWelcome ? closeModal : deleteAll}
                        style={{
                           color: '#000000',
                           backgroundColor: '#E8E8E8',
                        }}
                     >
                        {isWelcome ? t.cancel : t.delete_all}
                     </Button>
                  )}

                  <Button
                     className={classes.modalButtons}
                     type='submit'
                     disabled={!isOkay}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        float: 'right',
                     }}
                  >
                     <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                        {isLoading && <Loader />}
                        <span style={{ whiteSpace: 'nowrap' }}>{t.page_chat_btn_navbar_save}</span>
                     </div>
                  </Button>
               </ModalButtonField>
            </Grid>
         </form>
      </div>
   );
};

export default InformativeButtonModal;

import React, { useCallback, useState, useEffect, useContext, memo } from 'react';
import { Handle, Node, Position, useReactFlow } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteFow from '@modules/Automation/FlowBots/components/DeleteFlow';
import { LanguageContext } from '@helper/locale/langContext';
import {
   FlowBlockType,
   IFlowBlockField,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { useMutation } from '@apollo/client';
import {
   CREATE_FLOW_NODE_MUTATION,
   DELETE_FLOW_NODE_MUTATION,
   UPDATE_ASK_QUESTION_BLOCK_MUTATION,
   UPDATE_BLOCK_MUTATION,
   UPDATE_FLOW_NODE_MUTATION,
} from '@queries/Automation/mutation';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SET_CLICKED_NODE_ID } from '@store/actions/automation';
import useDetachNodes from '../../App/DynamicGrouping/useDetachNodes';
import UngroupIcon from '../../../icons/UngroupIcon.svg';

const useStyles = makeStyles(() => ({
   container: {
      position: 'relative',
      display: 'inline-block',
      paddingTop: 5,
   },
   iconDiv: {
      display: 'flex',
      borderRadius: 5,
      backgroundColor: '#FFFFFF',
   },
   innerIcons: {
      padding: '2px 5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease',
      backgroundColor: 'transparent',
      '&:hover': {
         backgroundColor: '#E8E8E8',
      },
   },
   rawText: {
      margin: '5px 5px 0 5px',
   },
   textarea: {
      backgroundColor: '#F7F7FA',
      fontSize: 7,
      border: 'none',
      borderRadius: '5px',
      padding: '5px',
      width: '100%',
      outline: 'none',
      resize: 'none',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      maxWidth: '100%',
   },
   askIcon: {
      width: 20,
      height: 20,
      margin: '0 5px 5px 5px',
   },
   customHeader: {
      display: 'flex',
      borderBottom: '0.1px solid #f2f2f2',
   },
   addText: {
      padding: '10px',
      margin: '5px',
      textAlign: 'center',
      borderRadius: 2,
      fontSize: 7,
      color: '#AFAFAF',
      backgroundColor: '#F7F7FA',
   },
   nodeContainer: {
      border: '1px solid #ffffff',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   InvalidNodeContainer: {
      border: '1px solid #FF7474',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   nodeContainerHovered: {
      border: '1px solid #157cfc',
      background: 'rgba(255, 255, 255, 0.6)',
   },

   handle: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1px',
      backgroundColor: '#fff',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      cursor: 'default',
      marginRight: '9px',
      transition: 'transform 0.3s',
      '&:hover': {
         marginRight: '8px',
         width: '9px',
         height: '9px',
      },
   },
   handleTarget: {
      backgroundColor: '#fff',
      width: '5px',
      height: '5px',
      top: 14,
      marginLeft: '5px',
   },
   subBlocks: {
      position: 'relative',
      display: 'flex',
      gap: '5px',
      justifyContent: 'space-between',
      padding: '5px',
      margin: '0 5px 5px 5px',
      border: '1px solid #F2F2F2',
      borderRadius: '5px',
      fontSize: '7px',
   },
   iconBox: {
      padding: '2px 3px',
      margin: '0 5px 5px 5px',
      borderRadius: '3px',
      display: 'flex',
      justifyContent: 'center',
   },
}));

interface AskQuestionProps {
   id: string;
   isConnectable: boolean;
   data: {
      title: string;
      clickedNodeId: string;
      isValidNode: Boolean;
      flowBlocks: IFlowBlockTemplate[];
      setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
      setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
      setHandleToUpdate: React.Dispatch<React.SetStateAction<string>>;
      setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
      ask: IFlowBlockTemplate[];
      setIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
      isCreation: boolean;
      setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
      setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
      setToGroup: React.Dispatch<React.SetStateAction<string[]>>;
   };
}

const AskQuestion = ({ id, isConnectable, data }: AskQuestionProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const {
      title,
      clickedNodeId,
      flowBlocks,
      setClickedHandleId,
      setIsNewNode,
      setHandleToUpdate,
      setClickedNodeId,
      isValidNode,
      setIsDraggable,
      isCreation,
      setContentNodes,
      setLeftNodes,
      setToGroup,
   } = data;
   const reactFlow = useReactFlow();
   const nodeList = reactFlow.getNodes();
   const [deleteTarget, setDeleteTarget] = useState(false);
   const [isDelete, setIsDelete] = useState(false);
   const [matchingBlocks, setMatchingBlocks] = useState<{ blockId: string; targets: string[] }[]>([]);
   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);

   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlock = async (blockId: string, target: null) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  target: target,
               },
            },
            onCompleted: (res) => {
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, target: target };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // Filter the flowBlocks where the target matches the current Node Id
   const filteredFlowBlocks = nodeList
      .map((item) => item?.data?.flowBlocks)
      .flat()
      .filter((flowBlock) => flowBlock?.target === id);

   const [quickReplyBlocks, setQuickReplyBlocks] = useState<IFlowBlockTemplate[]>([]);
   const [infButtonBlocks, setInfButtonBlocks] = useState<IFlowBlockTemplate[]>([]);

   useEffect(() => {
      // Filter the flowBlocks where the target matches the current Node Id in the quick reply blocks
      const filteredFlowBlocksQuickReply = nodeList.flatMap(
         (node) =>
            node?.data?.flowBlocks?.filter((flowBlock: IFlowBlockTemplate) =>
               flowBlock?.fields?.some((field) => field?.quickReplies?.some((quickReply) => quickReply?.target === id)),
            ) || [],
      );

      // Update quickReplyBlocks with targets set to null
      const updatedQuickReplyBlocks = filteredFlowBlocksQuickReply.map((flowBlock) => {
         return {
            ...flowBlock,
            fields: flowBlock.fields?.map((field: IFlowBlockField) => ({
               ...field,
               quickReplies: field.quickReplies?.map((quickReply) => ({
                  ...quickReply,
                  target: quickReply.target === id ? null : quickReply.target,
               })),
            })),
         };
      });

      setQuickReplyBlocks(updatedQuickReplyBlocks);
   }, [flowBlocks]);

   useEffect(() => {
      // Filter the flowBlocks where the target matches the current Node Id in the informative button blocks
      const filteredFlowBlocksInfButton = nodeList.flatMap(
         (node) =>
            node?.data?.flowBlocks?.filter((flowBlock: IFlowBlockTemplate) =>
               flowBlock?.fields?.some((field) =>
                  field?.informativeButtons?.some((infButton) => infButton?.target === id),
               ),
            ) || [],
      );

      // Update informativeButtons with targets set to null
      const updatedInfButtonBlocks = filteredFlowBlocksInfButton.map((flowBlock) => {
         return {
            ...flowBlock,
            fields: flowBlock.fields?.map((field: IFlowBlockField) => ({
               ...field,
               informativeButtons: field.informativeButtons?.map((infButton) => ({
                  ...infButton,
                  target: infButton.target === id ? null : infButton.target,
               })),
            })),
         };
      });

      setInfButtonBlocks(updatedInfButtonBlocks);
   }, [flowBlocks]);

   const handleUpdateFlowBlockQuickReply = async (blockId: string, blockFields: IFlowBlockField[]) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: blockFields.map((field: any) => {
                     for (let [key, value] of Object.entries(field)) {
                        if (value == null) {
                           delete field[key];
                        }
                     }
                     return field;
                  }),
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // Update askquestion blocks mutation
   const [updateBlockAskQuestion] = useMutation(UPDATE_ASK_QUESTION_BLOCK_MUTATION);
   const handleUpdateFlowBlockAskQuestion = async (blockId: string, fieldName: string) => {
      try {
         await updateBlockAskQuestion({
            variables: {
               input: {
                  _id: blockId,
                  fieldIndex: 0,
                  fieldName: fieldName,
                  fieldValue: null,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlockField.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   useEffect(() => {
      setMatchingBlocks([]);

      // Get all askquestion blocks wherethe curen id is used as target source handle
      nodeList
         .map((item) => item?.data?.flowBlocks)
         .flat()
         .forEach((flowBlock) => {
            if (flowBlock?.fields) {
               const matchingTargets = flowBlock.fields.filter(
                  (field: {
                     target_on_reply: string;
                     target_on_not_reply: string;
                     target_on_invalid_input: string;
                  }) => {
                     return (
                        field.target_on_reply === id ||
                        field.target_on_not_reply === id ||
                        field.target_on_invalid_input === id
                     );
                  },
               );

               if (matchingTargets.length > 0) {
                  const targets: string[] = [];

                  if (matchingTargets.some((target: { target_on_reply: string }) => target.target_on_reply === id)) {
                     targets.push('target_on_reply');
                  }

                  if (
                     matchingTargets.some(
                        (target: { target_on_not_reply: string }) => target.target_on_not_reply === id,
                     )
                  ) {
                     targets.push('target_on_not_reply');
                  }

                  if (
                     matchingTargets.some(
                        (target: { target_on_invalid_input: string }) => target.target_on_invalid_input === id,
                     )
                  ) {
                     targets.push('target_on_invalid_input');
                  }

                  setMatchingBlocks((prev) => [...prev, { blockId: flowBlock._id, targets: targets }]);
               }
            }
         });
   }, [clickedNodeId]);

   const handleUpdateFlowBlockHttpRequest = async (blockId: string, fieldName: string) => {
      try {
         const foundField = nodeList.find((node) =>
            node.data?.flowBlocks?.some((block: IFlowBlockTemplate) => block._id === blockId),
         )?.data.flowBlocks[0]?.fields[0];

         let fields = [];
         if (fieldName === 'targetOnSuccess') {
            fields = [
               {
                  targetOnSuccess: null,
                  targetOnFailure: foundField.targetOnFailure,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         } else {
            fields = [
               {
                  targetOnFailure: null,
                  targetOnSuccess: foundField.targetOnSuccess,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         }
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const [matchingHttpBlocks, setMatchingHttpBlocks] = useState<{ blockId: string; targets: string[] }[]>([]);
   useEffect(() => {
      setMatchingHttpBlocks([]);

      // Get all askquestion blocks wherethe curen id is used as target source handle
      nodeList
         .map((item) => item?.data?.flowBlocks)
         .flat()
         .forEach((flowBlock) => {
            if (flowBlock?.fields) {
               const matchingTargets = flowBlock.fields.filter(
                  (field: { targetOnSuccess: string; targetOnFailure: string }) => {
                     return field.targetOnSuccess === id || field.targetOnFailure === id;
                  },
               );

               if (matchingTargets.length > 0) {
                  const targets: string[] = [];

                  if (matchingTargets.some((target: { targetOnFailure: string }) => target.targetOnFailure === id)) {
                     targets.push('targetOnFailure');
                  }

                  if (matchingTargets.some((target: { targetOnSuccess: string }) => target.targetOnSuccess === id)) {
                     targets.push('targetOnSuccess');
                  }

                  setMatchingHttpBlocks((prev) => [...prev, { blockId: flowBlock._id, targets: targets }]);
               }
            }
         });
   }, [clickedNodeId]);

   useEffect(() => {
      if (deleteTarget) {
         const target = null;
         filteredFlowBlocks.forEach((block) => {
            handleUpdateFlowBlock(block._id, target);
         });

         if (quickReplyBlocks.length > 0) {
            quickReplyBlocks.forEach((block) => {
               handleUpdateFlowBlockQuickReply(block._id, block.fields);
            });
         }
         if (infButtonBlocks.length > 0) {
            infButtonBlocks.forEach((block) => {
               handleUpdateFlowBlockQuickReply(block._id, block.fields);
            });
         }
      }

      if (matchingBlocks) {
         matchingBlocks.forEach((block) => {
            block.targets.forEach((target) => {
               handleUpdateFlowBlockAskQuestion(block.blockId, target);
            });
         });
      }

      if (matchingHttpBlocks) {
         matchingHttpBlocks.forEach((block) => {
            block.targets.forEach((target) => {
               handleUpdateFlowBlockHttpRequest(block.blockId, target);
            });
         });
      }

      return setDeleteTarget(false);
   }, [deleteTarget]);

   const [deleteFlowNode] = useMutation(DELETE_FLOW_NODE_MUTATION);
   const handleDeleteFlowNode = async (itemId: String) => {
      setDeleteTarget(true);
      setIsDelete(true);
      try {
         await deleteFlowNode({
            variables: {
               input: {
                  _id: itemId,
               },
            },
            onCompleted: () => {
               setLeftNodes((prev) => prev.filter((node) => node.id !== itemId));
               setContentNodes((prev) => prev.filter((node) => node.id !== itemId));

               deleteElements({ nodes: [{ id }] });
               setIsDelete(false);
            },
         });
      } catch (error) {
         console.error('Error deleting node:', error);
      }
   };

   const { deleteElements } = useReactFlow();

   const [isHover, setIsHover] = useState(false);

   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   useEffect(() => {
      setIsHover(id === clickedId);
   }, [data, id, clickedId]);

   const limitedTitle = title.length > 21 ? `${title.slice(0, 19)}...` : title;

   const blockData = flowBlocks.filter(
      (item) =>
         item.type === FlowBlockType.Question &&
         Array.isArray(item.fields) &&
         item.fields.some((field) => field.value && field.value?.length > 0),
   );
   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         let numRows = 0;
         let consecutiveChars = 0;

         for (let i = 0; i < content.length; i++) {
            if (content[i] !== '\n') {
               consecutiveChars++;
               if (consecutiveChars === 34) {
                  numRows++;
                  consecutiveChars = 0;
               }
            } else {
               consecutiveChars = 0;
            }
         }

         const enters = (content.match(/\n/g) || []).length;

         numRows += enters;

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: numRows + 2, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };

   const handleMouseOnHandle = (id: string, handle: string) => {
      setClickedHandleId(id);
      setHandleToUpdate(handle);
   };

   const [isDuplicating, setIsDuplicating] = useState<boolean>(false);
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   const [createFlowNode] = useMutation(CREATE_FLOW_NODE_MUTATION);

   const handleCreateNode = async () => {
      setIsDuplicating(true);
      try {
         const currentNode = nodeList.find((node) => node.id === id) as any;
         const currentBlocks = currentNode.data?.flowBlocks.map((current: any) => ({
            flowBlockTemplateId: current.templateId,
            channelType: current.channelType,
            fields: current.fields.map((field: IFlowBlockField) => {
               const fieldF = {
                  question_answer_timeout: field.question_answer_timeout,
                  question_answer_timeout_unit: field.question_answer_timeout_unit,
                  save_response_to_contact_field: field.save_response_to_contact_field,
                  type: field.type,
                  value: field.value,
               };

               return fieldF;
            }),
         }));

         if (currentNode) {
            const sourceParent = currentNode?.parentNode;
            const parentPosition = nodeList.find((node) => node.id === sourceParent)?.position;
            let childPosition = null;
            const newPosition = { x: currentNode.position.x + 200, y: currentNode.position.y };
            if (parentPosition) {
               childPosition = {
                  x: newPosition.x + parentPosition?.x - 25,
                  y: newPosition.y + parentPosition?.y - 25,
               };
            }

            const position = childPosition ? childPosition : newPosition;

            createFlowNode({
               variables: {
                  input: {
                     coordinates: position,
                     customer: person.customer._id,
                     flowBot: currentNode.flowBot,
                     templateId: currentNode.templateId,
                     title: t.automation_duplicate_copy + ' - ' + currentNode.data?.title,
                     flowBlocksToBeCreated: currentBlocks,
                     groupId: currentNode.parentNode,
                  },
               },
               onCompleted: async (res) => {
                  const duplicatedNode = res.createFlowNode.data;

                  const sourceParent = currentNode?.parentNode;
                  const parentPosition = nodeList.find((node) => node.id === sourceParent)?.position;
                  let childPosition = null;
                  const newPosition = duplicatedNode.coordinates;
                  if (parentPosition) {
                     childPosition = {
                        x: newPosition.x - parentPosition?.x + 25,
                        y: newPosition.y - parentPosition?.y + 25,
                     };
                  }

                  const newTargetNode = {
                     id: duplicatedNode._id,
                     type: duplicatedNode.type,
                     flowBot: duplicatedNode.flowBot,
                     templateId: duplicatedNode.templateId,
                     parentNode: duplicatedNode.groupId,
                     title: duplicatedNode.title,
                     data: {
                        title: duplicatedNode.title,
                        clickedNodeId,
                        flowBlocks: duplicatedNode.flowBlocks,
                        setIsDraggable: setIsDraggable,
                        setClickedNodeId: setClickedNodeId,
                        setLeftNodes: setLeftNodes,
                        setContentNodes: setContentNodes,
                        isValidNode: duplicatedNode.flowBlocks.length > 0 ? true : false,
                        setClickedHandleId: setClickedHandleId,
                        setIsNewNode: setIsNewNode,
                        setHandleToUpdate: setHandleToUpdate,
                        setToGroup: setToGroup,
                     },
                     position: childPosition ? childPosition : duplicatedNode.coordinates,
                  };
                  setContentNodes((prevNodes) => [...prevNodes, newTargetNode]);
                  setLeftNodes((prevNodes) => [...prevNodes, newTargetNode]);

                  setIsDuplicating(false);
               },
            });
         }
      } catch (error) {
         console.error('Error creating node:', error);
      }
   };
   const dispatch = useDispatch();
   const handleClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      isCreation && event.stopPropagation();
      if (botStatus === 'draft') {
         setClickedNodeId(id);
         dispatch({ type: SET_CLICKED_NODE_ID, payload: id });
      }
   };

   const currentNode = reactFlow.getNode(id);
   useEffect(() => {
      if (currentNode?.selected) {
         setIsHover(true);
      }
   }, [currentNode]);

   const detachNodes = useDetachNodes();
   const [updateFlowNode] = useMutation(UPDATE_FLOW_NODE_MUTATION);
   const handleUpdateFlowNodeGroup = async (itemId: string, groupId: string | null) => {
      try {
         await updateFlowNode({
            variables: {
               input: {
                  _id: itemId,
                  groupId: groupId,
               },
            },
         });
      } catch (error) {
         console.error('Error updating node:', error);
      }
   };
   const handleDetachNode = () => {
      const parentNode = currentNode?.parentNode;
      const allChildNodes = nodeList.filter(
         (node) => node.parentNode === parentNode && node.parentNode !== undefined && node.parentNode !== null,
      );
      const allChildNodeIds = nodeList.filter((node) => node.parentNode === parentNode).map((chdNode) => chdNode.id);
      if (allChildNodeIds.length > 2) {
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         const remainingNodeIds = allChildNodes.filter((node) => node.id !== id).map((chdNode) => chdNode.id);
         setToGroup(remainingNodeIds);
         //Update the detached node with groupId to null
         handleUpdateFlowNodeGroup(id, null);
      } else {
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         allChildNodeIds.forEach((sn) => handleUpdateFlowNodeGroup(sn, null));
      }
   };

   const handleDeleteNode = async () => {
      const parentNode = currentNode?.parentNode;
      const allChildNodes = nodeList.filter(
         (node) => node.parentNode === parentNode && node.parentNode !== undefined && node.parentNode !== null,
      );
      const allChildNodeIds = nodeList.filter((node) => node.parentNode === parentNode).map((chdNode) => chdNode.id);
      if (allChildNodeIds.length > 2) {
         await handleDeleteFlowNode(id);
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         const remainingNodeIds = allChildNodes.filter((node) => node.id !== id).map((chdNode) => chdNode.id);
         setToGroup(remainingNodeIds);
      } else {
         await handleDeleteFlowNode(id);
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
      }
   };

   const onDetach = () => handleDetachNode();
   const onDelete = () => handleDeleteNode();

   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (id === clickedId || currentNode?.selected ? setIsHover(true) : setIsHover(false))}
         className={classes.container}
      >
         {isHover && !isCreation && (
            <div
               style={{
                  position: 'absolute',
                  top: '-20px',
                  right: currentNode?.parentNode ? '33%' : '40%',
                  cursor: 'pointer',
               }}
               onMouseEnter={() => setIsDraggable(false)}
               onMouseLeave={() => setIsDraggable(true)}
            >
               {botStatus === 'draft' && (
                  <div className={classes.iconDiv}>
                     {isDuplicating ? (
                        <div className={classes.innerIcons}>
                           <CircularProgress size={13} />
                        </div>
                     ) : (
                        <div className={classes.innerIcons} onClick={() => handleCreateNode()}>
                           <ContentCopyIcon style={{ fontSize: '12px', fontWeight: 800 }} />
                        </div>
                     )}

                     {!isDelete ? (
                        <div className={classes.innerIcons} onClick={() => setShowDeleteModal(true)}>
                           <DeleteIcon style={{ fontSize: '13px', color: 'red' }} />
                        </div>
                     ) : (
                        <div className={classes.innerIcons}>
                           <CircularProgress size={13} />
                        </div>
                     )}

                     {currentNode?.parentNode && (
                        <div className={classes.innerIcons} onClick={onDetach}>
                           <img style={{ width: 12, height: 12 }} src={UngroupIcon} alt='UngroupIcon'></img>
                        </div>
                     )}
                  </div>
               )}
            </div>
         )}
         <div
            className={`${classes.nodeContainer} ${isHover && isValidNode ? classes.nodeContainerHovered : ''} ${
               !isValidNode ? classes.InvalidNodeContainer : ''
            }`}
            onClick={(event) => handleClicked(event)}
         >
            <div className={classes.customHeader}>
               <Handle
                  type='target'
                  position={Position.Left}
                  id={id}
                  className={classes.handleTarget}
                  isConnectable={isConnectable}
               />
               <div className={classes.iconBox} style={{ backgroundColor: '#FFDEDE' }}>
                  <QuestionMarkIcon style={{ color: '#FF7474', fontSize: '15px' }} />
               </div>
               <div style={{ marginTop: '3px', fontSize: 9 }}>{limitedTitle}</div>
            </div>

            {blockData.length > 0 ? (
               <div>
                  {blockData.map((item) => (
                     <div key={item._id} className={classes.rawText}>
                        {item.fields.map((field) => (
                           <textarea
                              value={field.value}
                              className={classes.textarea}
                              readOnly
                              {...calculateTextareaSize(field.value)}
                           />
                        ))}
                     </div>
                  ))}

                  {blockData.map((item) => (
                     <div key={item._id}>
                        {item.fields.map((field) => (
                           <div>
                              <div className={classes.subBlocks}>
                                 <div>{t.atomation_flow_action_on_reply}</div>
                                 <Handle
                                    type='source'
                                    position={Position.Right}
                                    id={item._id + '1'}
                                    className={classes.handle}
                                    isConnectable={isConnectable}
                                    style={{
                                       border: '1px solid #147CFC',
                                       backgroundColor: field.target_on_reply ? '#147CFC' : '',
                                    }}
                                    onMouseEnter={() => handleMouseOnHandle(item._id + '1', 'action_on_reply')}
                                 />
                              </div>
                              <div className={classes.subBlocks}>
                                 <div>{t.atomation_flow_action_if_not_reply}</div>
                                 <Handle
                                    type='source'
                                    position={Position.Right}
                                    id={item._id + '2'}
                                    className={classes.handle}
                                    isConnectable={isConnectable}
                                    style={{
                                       border: '1px solid #FF5A80',
                                       backgroundColor:
                                          field.target_on_not_reply !== null && field.target_on_not_reply !== undefined
                                             ? '#FF5A80'
                                             : '',
                                    }}
                                    onMouseEnter={() => handleMouseOnHandle(item._id + '2', 'action_if_not_reply')}
                                 />
                              </div>
                              <div className={classes.subBlocks}>
                                 <div>{t.atomation_flow_action_if_invalid_input}</div>
                                 <Handle
                                    type='source'
                                    position={Position.Right}
                                    id={item._id + '3'}
                                    className={classes.handle}
                                    isConnectable={isConnectable}
                                    style={{
                                       border: '1px solid #FEBC52',
                                       backgroundColor:
                                          field.target_on_invalid_input !== null &&
                                          field.target_on_invalid_input !== undefined
                                             ? '#FEBC52'
                                             : '',
                                    }}
                                    onMouseEnter={() => handleMouseOnHandle(item._id + '3', 'action_if_invalid')}
                                 />
                              </div>
                           </div>
                        ))}
                     </div>
                  ))}
               </div>
            ) : isCreation ? (
               <Box
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     padding: '10px',
                     margin: '5px',
                     backgroundColor: '#F7F7FA',
                  }}
               >
                  <CircularProgress size={14} />
               </Box>
            ) : (
               <div className={classes.addText}>{t.atomation_flow_add_question}</div>
            )}
         </div>
         <DeleteFow
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            onComplate={onDelete}
            text={t.automation_flow_delete_step_warning}
         />
      </div>
   );
};

export default memo(AskQuestion);

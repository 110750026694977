import { Box, Button, Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { LanguageContext } from '@helper/locale/langContext';
// import useLocalStorage from '../../../../hooks/useLocalStorage';
import Input from '@components/InputField/index';
import SingleInput from '@components/_Modal/SingleInput';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const N11Form = ({ onAccept, onBack, mode, onAcceptEditData, data: selectedItem, teamsData }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const {
      control,
      handleSubmit,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         storeName: mode ? selectedItem?.storeName : '',
         apiKey: mode ? selectedItem?.apiKey : '',
         apiSecret: mode ? selectedItem?.apiSecret : '',
         sellerId: mode ? selectedItem?.sellerId : '',
         teams: mode
            ? teamsData
                 .filter((t) => selectedItem?.teams?.includes(t._id))
                 ?.map((team) => ({
                    value: team._id,
                    label: team.teamName,
                 }))
            : [],
      },
   });

   const onSubmit = (data) => {
      if (mode) {
         onAcceptEditData({
            ...data,
            _id: selectedItem._id,
         });
      } else {
         onAccept(data);
      }
   };

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         <Grid container>
            <SingleInput
               control={control}
               name='storeName'
               label={t?.settings_page_question_store_name + '*'}
               placeholder={t?.example + 'Pazardabuldum'}
               helperText={t?.settings_form_name_of_showing_panel}
               rules={{
                  required: t?.please_fill_this_field,
                  minLength: {
                     value: 5,
                     message: t?.max_5_characters,
                  },
                  validate: (v) => {
                     return !!v.trim();
                  },
               }}
            />
            <SingleInput
               control={control}
               name='apiKey'
               label={t?.api_key + '*'}
               placeholder='64GJD7HD98DJDKDC'
               rules={{
                  required: t?.please_fill_this_field,
                  validate: (v) => {
                     return !!v.trim();
                  },
               }}
               disabled={mode}
            />
            <SingleInput
               control={control}
               name='apiSecret'
               placeholder='64GJD7HD98DJDKDC'
               label={t?.secret_key + '*'}
               rules={{
                  required: t?.please_fill_this_field,
                  validate: (v) => {
                     return !!v.trim();
                  },
               }}
               disabled={mode}
            />
            <SingleInput
               isSelect
               isMulti
               autoHeight
               selectOptions={teamsData.map((team) => ({
                  label: team.teamName,
                  value: team._id,
               }))}
               rules={{
                  required: t?.please_fill_this_field,
               }}
               control={control}
               name='teams'
               editable
               height={40}
               placeholder={t?.teams}
               label={t?.teams}
               disabled={mode}
               helperText={!mode ? t?.select_teams_for_permission : ''}
            />
            <ModalButtonField>
               {!mode && (
                  <Button
                     onClick={onBack}
                     className={classes.modalButtons}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        '&:hover': {
                           backgroundColor: '#e0e0e0',
                        },
                     }}
                  >
                     {t?.back}
                  </Button>
               )}
               <Button
                  disabled={!isValid}
                  type='submit'
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     '&:hover': {
                        backgroundColor: '#105CBB',
                     },
                     float: 'right',
                  }}
               >
                  {mode ? t?.save : t?.connect}
               </Button>
            </ModalButtonField>
         </Grid>
      </form>
   );
};

export default N11Form;

import React, { useEffect, useState, useContext, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography, Backdrop } from '@material-ui/core';
import { SIGN_OUT } from '@store/actions/person';
import { AUTO_LOGOUT_PERSON } from '../../queries/Person/Service/GraphQL/subscription';
import { useApolloClient, useSubscription } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme) => ({
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
   },
   autoLogoutBox: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #fff',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 5,
   },
   autoLogoutTitle: {
      fontSize: '1.2rem',
      paddingBottom: '1rem',
   },
   autoLogoutBtnBox: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '1rem',
   },
   logoutBtn: {
      padding: '.5rem 1rem',
   },
}));

const AutoLogout = () => {
   const token = useSelector((rootReducer) => rootReducer.personReducer.token);
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const [autoLogoutOpen, setAutoLogoutOpen] = React.useState(false);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const dispatch = useDispatch();
   const history = useHistory();
   const client = useApolloClient();
   const { data: autoLogoutData } = useSubscription(AUTO_LOGOUT_PERSON, {
      variables: { token, _id: person?._id },
      skip: !token || !person,
      fetchPolicy: 'network-only',
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               autoLogoutPerson: { data },
            },
         },
      }) => {},
   });
   const logoutHandler = useCallback(async () => {
      dispatch({ type: SIGN_OUT, payload: {} });
      localStorage.clear();
      history.replace('/');
      setAutoLogoutOpen(false);
      await client.resetStore();
      // window.location.reload();
   }, [client, dispatch, history]);

   useEffect(() => {
      if (autoLogoutData?.autoLogoutPerson?.data) {
         setAutoLogoutOpen(true);
      }
   }, [autoLogoutData]);

   const classes = useStyles();
   const [remainingLogoutTime, setRemainingLogoutTime] = useState(5);

   useEffect(() => {
      if (!autoLogoutData?.autoLogoutPerson?.data) return;
      let intervalID = setInterval(() => {
         if (remainingLogoutTime === 0) return;
         setRemainingLogoutTime((remainingTime) => remainingTime - 1);
      }, 1000);

      return () => {
         clearInterval(intervalID);
      };
      //eslint-disable-next-line
   }, [autoLogoutData?.autoLogoutPerson?.data, autoLogoutOpen, remainingLogoutTime]);

   useEffect(() => {
      if (remainingLogoutTime === 0) {
         logoutHandler();
      }
   }, [logoutHandler, remainingLogoutTime]);

   if (!autoLogoutOpen) return null;

   return (
      <Backdrop className={classes.backdrop} open={autoLogoutOpen}>
         <Box className={classes.autoLogoutBox}>
            <Typography className={classes.autoLogoutTitle}>
               {t?.logging_you_out_in} {remainingLogoutTime} {t?.seconds}
            </Typography>
            <Typography> {t?.another_client} </Typography>
            <Box className={classes.autoLogoutBtnBox}>
               <Button onClick={logoutHandler} className={classes.logoutBtn} variant='contained' color='primary'>
                  {t?.logout_now}
               </Button>
            </Box>
         </Box>
      </Backdrop>
   );
};

export default AutoLogout;

import { useMutation, useQuery } from '@apollo/client';
import ButtonOK from '@components/ButtonOK/buttonOk';
import CustomSwitch from '@components/Switch';
import { LanguageContext } from '@helper/locale/langContext';
import {
   Button,
   CircularProgress,
   FormControlLabel,
   Grid,
   IconButton,
   Input,
   InputLabel,
   OutlinedInput,
   Tab,
   TabProps,
   Tabs,
   Typography,
} from '@material-ui/core';
import Select from 'react-select';
import Modal from '@components/Modal/modal';
import {
   ArrowBack,
   Code,
   Edit,
   FormatBold,
   FormatItalic,
   SentimentSatisfiedOutlined,
   StrikethroughS,
} from '@material-ui/icons';
import { makeStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { GET_CHAT_TAGS_QUERY } from '@queries/Settings/Service/GraphQL/ChatTags/query';
import { UPDATE_ECOMMERCE_PLATFORM_SETTINGS_MUTATION } from '@queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';
import { getPerson } from '@store/reducers/person';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import Info from '@material-ui/icons/Info';
import ButtonBasedPopup from '@components/ButtonBasedPopup';
import { Picker } from 'emoji-mart';
import DropdownMenu, { DropdownItemGroup, DropdownItem } from '@atlaskit/dropdown-menu';
import { ModalLargeText, ModalTitle } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';

const Variables = [
   {
      name: 'order_number',
   },
   // {
   //    name: 'order_status',
   // },
   {
      name: 'payment_status',
   },
   {
      name: 'shipping_status',
   },
   {
      name: 'shipping_address',
   },
   {
      name: 'order_items',
   },
   {
      name: 'subtotal',
   },
   {
      name: 'shipping',
   },
   {
      name: 'discount',
   },
   {
      name: 'total',
   },
];

const StyledTabs = withStyles({
   root: {
      border: 'none',
      height: 40,
      minHeight: 40,
      flexShrink: 0,
      borderRadius: 8,
      backgroundColor: '#F4F4F4',
      marginBottom: 15,
   },
   indicator: {
      position: 'absolute',
      width: 'calc(25% - 8px)!important', // (100/tab count)% - (margin * 2)px
      left: 0,
      top: 0,
      right: 0,
      bottom: 0,
      height: 32,
      margin: 4,
      zIndex: 1,
      borderRadius: 6,
      backgroundColor: '#FFF',
      boxShadow: '0px 0px 8px 1px rgba(0,0,0,0.1)',
   },
})(Tabs);

const StyledTab = withStyles((theme: Theme) =>
   createStyles({
      root: {
         textTransform: 'none',
         height: 40,
         minHeight: 40,
         flex: 1,
         padding: 5,
         borderRadius: 6,
         '&:hover': {
            color: '#000',
         },
         '&$selected': {
            color: '#000',
            fontWeight: theme.typography.fontWeightMedium,
         },
         '&:focus': {
            color: '#333',
         },
      },
      wrapper: {
         zIndex: 2,
      },
   })
)((props: TabProps) => <Tab {...props} />);

const useStyles = makeStyles((theme: any) => ({
   item: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#F7F7F7',
      color: 'black',
      width: '100%',
      padding: '16px',
      margin: '8px 16px',
      borderRadius: '8px',
      border: '1px solid #E5E5E5',
   },
   label: {
      color: 'black',
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   insideItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 4px',
   },
   editButton: {
      backgroundColor: 'white',
      borderRadius: 0,
      border: '1px solid #E5E5E5',
   },
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.3rem',
      fontWeight: 'bold',
   },
   modalSubtitle: {
      fontSize: '1.1rem',
      textAlign: 'left',
      alignSelf: 'flex-start',
   },
   modalBodyText: {
      fontSize: '0.9rem',
   },
   modalBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px 30px',
   },
   modalBodyIconContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   modalDescription: {
      marginBottom: 55,
   },
   select: {
      width: '100%',
      height: 40,
      marginBottom: 20,
      marginTop: 10,
   },
   customInput: {
      width: '100%',
      marginTop: '10px',
      marginBottom: '10px',
   },
   inputBar: {
      width: '100%',
      '& .MuiPopover-paper': {
         backgroundColor: 'red',
         color: 'yellow',
      },
   },
   textArea: {
      resize: 'none',
      fontFamily: 'Helvetica',
      fontSize: '14.7px',
      width: '100%',
      border: '2px solid #D1D1D1',
      borderRadius: 10,
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      height: 450,
      paddingLeft: 10,
      paddingTop: 10,
      '&:focus-visible': {
         border: '2px solid #D1D1D1',
         borderRadius: 5,
         outline: 'none !important',
      },
      '&:focus': {
         border: '2px solid #D1D1D1',
         borderRadius: 5,
         outline: 'none !important',
      },
   },
   textAreaPreview: {
      overflow: 'auto',
      fontFamily: 'Helvetica',
      fontSize: '14.7px',
      width: '100%',
      border: '2px solid #D1D1D1',
      borderRadius: 10,
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      lineHeight: 'normal',
      height: 450,
      marginBlock: 10,
      marginLeft: 10,
      paddingLeft: 10,
      paddingTop: 10,
      backgroundColor: '#F1F7FF',
      '&:focus-visible': {
         border: '2px solid #D1D1D1',
         borderRadius: 5,
         outline: 'none !important',
      },
      '&:focus': {
         border: '2px solid #D1D1D1',
         borderRadius: 5,
         outline: 'none !important',
      },
   },
   addMedia: {
      width: 110,
      height: 30,
      padding: 0,
      paddingTop: 3,
      fontSize: 13,
      borderRadius: 5,
      border: '1px dashed #D1D1D1',
   },
   bottomBar: {
      padding: 5,
      height: '15%',
      width: '100%',
   },
   bottomButtons: {
      display: 'flex',
   },
   inputCounter: {
      fontSize: '0.875rem',
      textAlign: 'start',
      width: 90,
      color: '#A0A0A0',
      height: 20,
      lineHeight: 2,
      flexGrow: 1,
   },
   inputText: {
      fontSize: '0.875rem',
   },
   inputIcon: {
      fontSize: '0.875rem',
      width: 15,
      height: 15,
      marginLeft: 2,
      verticalAlign: 'text-top',
   },
   inputLabel: {
      marginBottom: 5,
      color: '#212121',
      fontSize: '18px',
      '&.focused': {
         color: theme.palette.primary.main,
      },
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

interface SettingsPageProps {
   setSettingsPageVisible: (visible: boolean) => void;
   orderConfirmationMessage: any;
   orderConfirmationLabel: any;
   paymentTypes: any;
   platformId: string;
}

const SettingsPage = ({
   setSettingsPageVisible,
   orderConfirmationMessage,
   orderConfirmationLabel,
   paymentTypes,
   platformId,
}: SettingsPageProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [isConfirmationMessageSettingsOpen, setIsConfirmationMessageSettingsOpen] = React.useState<boolean>(false);
   const [isConfirmationLabelSettingsOpen, setIsConfirmationLabelSettingsOpen] = React.useState<boolean>(false);
   const [isPaymentTypesSettingsOpen, setIsPaymentTypesSettingsOpen] = React.useState<boolean>(false);
   const [currentMessageTab, setCurrentMessageTab] = React.useState<number>(0);
   const [currentPaymentTypeTab, setCurrentPaymentTypeTab] = React.useState<number>(0);
   const [currentMessageValue, setCurrentMessageValue] = React.useState<string>('');

   const [isConfirmationMessageOn, setIsConfirmationMessageOn] = React.useState<boolean>(orderConfirmationMessage.isOn);
   const [isConfirmationLabelOn, setIsConfirmationLabelOn] = React.useState<boolean>(orderConfirmationLabel.isOn);
   const [isPaymentTypesOn, setIsPaymentTypesOn] = React.useState<boolean>(paymentTypes.isOn);
   const [orderConfirmationMessageValue, setOrderConfirmationMessageValue] = React.useState<any>({
      tr: orderConfirmationMessage?.tr || '',
      en: orderConfirmationMessage?.en || '',
      ar: orderConfirmationMessage?.ar || '',
      ru: orderConfirmationMessage?.ru || '',
   });
   const [paymentTypesValue, setPaymentTypesValue] = React.useState<any>(
      paymentTypes?.types?.length > 0
         ? paymentTypes?.types
         : [
            {
               name: '',
               id: '',
               price: '',
               subPaymentTypes: [
                  {
                     name: '',
                     id: '',
                     price: '',
                  },
                  {
                     name: '',
                     id: '',
                     price: '',
                  },
               ],
            },
            {
               name: '',
               id: '',
               price: '',
               subPaymentTypes: [
                  {
                     name: '',
                     id: '',
                     price: '',
                  },
                  {
                     name: '',
                     id: '',
                     price: '',
                  },
               ],
            },
            {
               name: '',
               id: '',
               price: '',
               subPaymentTypes: [
                  {
                     name: '',
                     id: '',
                     price: '',
                  },
                  {
                     name: '',
                     id: '',
                     price: '',
                  },
               ],
            },
            {
               name: '',
               id: '',
               price: '',
               subPaymentTypes: [
                  {
                     name: '',
                     id: '',
                     price: '',
                  },
                  {
                     name: '',
                     id: '',
                     price: '',
                  },
               ],
            },
         ]
   );
   const [orderConfirmationLabelValue, setOrderConfirmationLabelValue] = React.useState<any>({
      value: orderConfirmationLabel.label?._id,
      label: orderConfirmationLabel.label?.name,
   });
   const [updateECommercePlatformSettings, { loading: settingsLoading }] = useMutation(
      UPDATE_ECOMMERCE_PLATFORM_SETTINGS_MUTATION
   );
   const [openEmoji, setOpenEmoji] = React.useState(false);
   const me = useSelector(getPerson);

   const handleTabChangeMessage = (event: React.ChangeEvent<{}>, newValue: number) => {
      setCurrentMessageTab(newValue);
   };

   const handleTabChangePaymentType = (event: React.ChangeEvent<{}>, newValue: number) => {
      setCurrentMessageTab(newValue);
   };

   let _currentMessageValue = currentMessageValue;

   let bold_text_regex = /\*([^\*]+)\*/g;
   let bold_text = _currentMessageValue.matchAll(bold_text_regex);
   //@ts-ignore
   for (let bold of bold_text) {
      _currentMessageValue = _currentMessageValue.replace(bold[0], `<span><b>${bold[1]}</b></span>`);
   }

   let italic_text_regex = /\_{1}([^\_]+)\_{1}/g;
   let italic_text = _currentMessageValue.matchAll(italic_text_regex);
   //@ts-ignore
   for (let italic of italic_text) {
      _currentMessageValue = _currentMessageValue.replace(italic[0], `<span><i>${italic[1]}</i></span>`);
   }

   let underline_text_regex = /\~{1}([^\~]+)\~{1}/g;
   let underline_text = _currentMessageValue.matchAll(underline_text_regex);
   //@ts-ignore
   for (let underline of underline_text) {
      _currentMessageValue = _currentMessageValue.replace(underline[0], `<span><s>${underline[1]}</s></span>`);
   }

   let single_line_text_regex = /\`\`\`([^\`\`\`]+)\`\`\`/g;
   let single_line_text = _currentMessageValue.matchAll(single_line_text_regex);
   //@ts-ignore
   for (let single_line of single_line_text) {
      _currentMessageValue = _currentMessageValue.replace(
         single_line[0],
         `<span><code>${single_line[1]}</code></span>`
      );
   }

   let variable_text_regex = /(\{\{[^\}\]]+\}\})/g;
   let variable_text = _currentMessageValue.matchAll(variable_text_regex);
   //@ts-ignore
   for (let variable_line of variable_text) {
      _currentMessageValue = _currentMessageValue.replace(
         variable_line[0],
         `<span style="color: #018cf9";>${t.order_confirmation_message_modal_example}</span>`
      );
   }

   useEffect(() => {
      let lang;
      switch (currentMessageTab) {
         case 0:
            lang = 'tr';
            break;
         case 1:
            lang = 'en';
            break;
         case 2:
            lang = 'ar';
            break;
         case 3:
            lang = 'ru';
            break;
         default:
            lang = 'en';
            break;
      }

      setCurrentMessageValue(orderConfirmationMessageValue[lang]);
   }, [currentMessageTab]);

   useEffect(() => {
      let lang;
      switch (currentMessageTab) {
         case 0:
            lang = 'tr';
            break;
         case 1:
            lang = 'en';
            break;
         case 2:
            lang = 'ar';
            break;
         case 3:
            lang = 'ru';
            break;
         default:
            lang = 'en';
            break;
      }

      setOrderConfirmationMessageValue({
         ...orderConfirmationMessageValue,
         [lang]: currentMessageValue,
      });
   }, [currentMessageValue]);

   function addVariable(variable: string) {
      if (currentMessageValue.endsWith(' ') || currentMessageValue === '') {
         setCurrentMessageValue(currentMessageValue + `{{${variable}}}`);
      } else {
         setCurrentMessageValue(currentMessageValue + ` {{${variable}}}`);
      }
   }

   function addFormat(type: string) {
      switch (type) {
         case 'bold':
            if (currentMessageValue.endsWith(' ') || currentMessageValue === '') {
               setCurrentMessageValue(currentMessageValue + `*${t['format_value']}*`);
            } else {
               setCurrentMessageValue(currentMessageValue + ` *${t['format_value']}*`);
            }
            break;
         case 'italic':
            if (currentMessageValue.endsWith(' ') || currentMessageValue === '') {
               setCurrentMessageValue(currentMessageValue + `_${t['format_value']}_`);
            } else {
               setCurrentMessageValue(currentMessageValue + ` _${t['format_value']}_`);
            }
            break;
         case 'strikethrough':
            if (currentMessageValue.endsWith(' ') || currentMessageValue === '') {
               setCurrentMessageValue(currentMessageValue + `~${t['format_value']}~`);
            } else {
               setCurrentMessageValue(currentMessageValue + ` ~${t['format_value']}~`);
            }
            break;
         case 'code':
            if (currentMessageValue.endsWith(' ') || currentMessageValue === '') {
               setCurrentMessageValue(currentMessageValue + `\`\`\`${t['format_value']}\`\`\``);
            } else {
               setCurrentMessageValue(currentMessageValue + ` \`\`\`${t['format_value']}\`\`\``);
            }
            break;
         default:
            throw new Error('Invalid format type');
      }
   }

   function addEmoji(e: any) {
      let emoji = e.native;
      if (currentMessageValue.endsWith(' ') || currentMessageValue === '') {
         setCurrentMessageValue(currentMessageValue + emoji);
      } else {
         setCurrentMessageValue(currentMessageValue + ' ' + emoji);
      }
      setOpenEmoji(false);
   }

   const { data: chatTagsData } = useQuery(GET_CHAT_TAGS_QUERY, {
      variables: { customer: me.customer._id, pageSize: 1000, page: 1 },
      fetchPolicy: 'cache-first',
   });

   return (
      <div>
         <Grid
            container
            style={{
               paddingInline: '16px',
               paddingTop: '16px',
               marginBottom: 40,
            }}
            justifyContent='space-between'
            xs={12}
         >
            <Grid container xs={6} alignItems='center'>
               <Grid item>
                  <Button onClick={() => setSettingsPageVisible(false)}>
                     <ArrowBack />
                  </Button>
               </Grid>
               <Grid item>
                  <Typography
                     style={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                     }}
                  >
                     {' '}
                     {t.e_commerce_platform_settings}
                  </Typography>
               </Grid>
            </Grid>
            <Grid
               container
               style={{
                  marginTop: 20,
               }}
            >
               {/* <Grid item className={classes.item}>
                  <Typography className={classes.label}>{t.order_confirmation_message_title}</Typography>
                  <Grid className={classes.insideItem}>
                     {<OutlinedInput
                        style={{
                           backgroundColor: 'white',
                        }}
                        onClick={() => setIsConfirmationMessageSettingsOpen(true)}
                        value={orderConfirmationMessage['tr']}
                     />}
                     <IconButton
                        onClick={() => setIsConfirmationMessageSettingsOpen(true)}
                        className={classes.editButton}
                        size='small'
                     >
                        <Edit color='primary' />
                     </IconButton>
                     <FormControlLabel
                        control={
                           <CustomSwitch
                              checked={isConfirmationMessageOn}
                              onChange={() => {
                                 if (settingsLoading) return;
                                 setIsConfirmationMessageOn(!isConfirmationMessageOn);
                                 updateECommercePlatformSettings({
                                    variables: {
                                       type: E_COMMERCE_PLATFORM_TYPE.TSOFT,
                                       metaData: {
                                          _id: platformId,
                                          orderConfirmationMessage: {
                                             isOn: !isConfirmationMessageOn,
                                          },
                                       },
                                    },
                                    onError: (err) => {
                                       setIsConfirmationMessageOn(!isConfirmationMessageOn);
                                    },
                                 });
                              }}
                           />
                        }
                        label=''
                        labelPlacement='start'
                        classes={{
                           label: classes.label,
                        }}
                     />
                  </Grid>
               </Grid> */}
               {/* <Grid item className={classes.item}>
                  <Typography className={classes.label}>{t.order_confirmation_label_title}</Typography>
                  <Grid className={classes.insideItem}>
                     { <OutlinedInput
                        style={{
                           backgroundColor: 'white',
                        }}
                        onClick={() => setIsConfirmationLabelSettingsOpen(true)}
                        value={orderConfirmationLabelValue.label}
                     /> }
                     <IconButton
                        onClick={() => setIsConfirmationLabelSettingsOpen(true)}
                        className={classes.editButton}
                        size='small'
                     >
                        <Edit color='primary' />
                     </IconButton>
                     <FormControlLabel
                        control={
                           <CustomSwitch
                              checked={isConfirmationLabelOn}
                              onChange={() => {
                                 if (settingsLoading) return;
                                 setIsConfirmationLabelOn(!isConfirmationLabelOn);
                                 updateECommercePlatformSettings({
                                    variables: {
                                       type: E_COMMERCE_PLATFORM_TYPE.TSOFT,
                                       metaData: {
                                          _id: platformId,
                                          orderConfirmationLabel: {
                                             isOn: !isConfirmationLabelOn,
                                          },
                                       },
                                    },
                                    onError: (err) => {
                                       setIsConfirmationLabelOn(!isConfirmationLabelOn);
                                    },
                                 });
                              }}
                           />
                        }
                        label=''
                        labelPlacement='start'
                        classes={{
                           label: classes.label,
                        }}
                     />
                  </Grid>
               </Grid> */}
               <Grid item className={classes.item}>
                  <Typography className={classes.label}>{t.order_payment_types_title}</Typography>
                  <Grid className={classes.insideItem}>
                     {/* <OutlinedInput
                        style={{
                           backgroundColor: 'white',
                        }}
                        onClick={() => setIsConfirmationMessageSettingsOpen(true)}
                        value={orderConfirmationMessage['tr']}
                     /> */}
                     <IconButton
                        onClick={() => setIsPaymentTypesSettingsOpen(true)}
                        className={classes.editButton}
                        size='small'
                     >
                        <Edit color='primary' />
                     </IconButton>
                     <FormControlLabel
                        control={
                           <CustomSwitch
                              checked={isPaymentTypesOn}
                              onChange={() => {
                                 if (settingsLoading) return;
                                 setIsPaymentTypesOn(!isPaymentTypesOn);
                                 updateECommercePlatformSettings({
                                    variables: {
                                       type: E_COMMERCE_PLATFORM_TYPE.TSOFT,
                                       metaData: {
                                          _id: platformId,
                                          paymentTypes: {
                                             isOn: !isPaymentTypesOn,
                                          },
                                       },
                                    },
                                    onError: (err) => {
                                       setIsPaymentTypesOn(!isPaymentTypesOn);
                                    },
                                 });
                              }}
                           />
                        }
                        label=''
                        labelPlacement='start'
                        classes={{
                           label: classes.label,
                        }}
                     />
                  </Grid>
               </Grid>
            </Grid>
         </Grid>

         <Modal open={isConfirmationMessageSettingsOpen} onClose={() => setIsConfirmationMessageSettingsOpen(false)} modalHeader={t['order_confirmation_message_modal_title']}>
            <ModalTitle className={classes.modalSubtitle}>
               {t['order_confirmation_message_modal_subtitle']}
            </ModalTitle>
            <ModalLargeText className={classes.modalBodyText}>
               {t['order_confirmation_message_modal_description']}
            </ModalLargeText>
            <StyledTabs
               value={currentMessageTab}
               onChange={handleTabChangeMessage}
               aria-label='Tabs'
               style={{
                  width: '100%',
                  marginTop: 20,
                  marginBottom: 20,
               }}
            >
               <StyledTab label={t['order_confirmation_message_modal_turkish']} />
               <StyledTab label={t['order_confirmation_message_modal_english']} />
               <StyledTab label={t['order_confirmation_message_modal_arabic']} />
               <StyledTab label={t['order_confirmation_message_modal_russian']} />
            </StyledTabs>
            <Grid
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
               }}
            >
               <div className={classes.customInput}>
                  <div className={classes.inputBar}>
                     <textarea
                        value={currentMessageValue}
                        onChange={(e) => {
                           if (e.target.value.length > 1024) return;
                           setCurrentMessageValue(e.target.value);
                        }}
                        placeholder={t['campaign_input_placeholder']}
                        className={classes.textArea}
                     />
                  </div>
                  <div className={classes.bottomBar}>
                     <div className={classes.bottomButtons}>
                        {
                           <div
                              className={classes.inputCounter}
                              style={{
                                 color: (currentMessageValue || '').length >= 1024 ? 'red' : undefined,
                              }}
                           >
                              <span className={classes.inputText}>
                                 {(currentMessageValue.length || 0) > 1024
                                    ? 1024
                                    : currentMessageValue.length || 0}
                                 /1024
                              </span>
                              <Info className={classes.inputIcon} />
                           </div>
                        }
                        <DropdownMenu
                           trigger={t.order_confirmation_message_modal_add_variable}
                           placement='top-end'
                           zIndex={9999}
                        >
                           <DropdownItemGroup>
                              {Variables.map((variable: any) => {
                                 return (
                                    <DropdownItem
                                       key={variable}
                                       onClick={() => {
                                          addVariable(variable.name);
                                       }}
                                    >
                                       {t['order_confirmation_message_variable_' + variable.name]}
                                    </DropdownItem>
                                 );
                              })}
                           </DropdownItemGroup>
                        </DropdownMenu>
                     </div>
                  </div>
               </div>
               <div
                  dangerouslySetInnerHTML={{
                     __html: _currentMessageValue,
                  }}
                  className={classes.textAreaPreview}
               ></div>
            </Grid>
            <ModalButtonField>
               <ButtonCancel
                  label={t['comp_button_cancel']}
                  onClick={() => setIsConfirmationMessageSettingsOpen(false)}
                  className={classes.modalButtons}
                  disabled={settingsLoading}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                  }}
               />

               <ButtonOK
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     float: 'right'
                  }}
                  disabled={settingsLoading}
                  startIcon={settingsLoading ? <CircularProgress color={"secondary"} size={20} /> : null}
                  label={t['comp_button_save']}
                  onClick={() => {
                     updateECommercePlatformSettings({
                        variables: {
                           type: E_COMMERCE_PLATFORM_TYPE.TSOFT,
                           metaData: {
                              _id: platformId,
                              orderConfirmationMessage: {
                                 ...orderConfirmationMessageValue,
                              },
                           },
                        },
                        onCompleted: () => {
                           setIsConfirmationMessageSettingsOpen(false);
                        },
                        onError: (err) => {
                           console.log(err);
                           setIsConfirmationMessageSettingsOpen(false);
                        },
                     });
                  }}
               />
            </ModalButtonField>
         </Modal>
         <Modal open={isPaymentTypesSettingsOpen} onClose={() => setIsPaymentTypesSettingsOpen(false)} modalHeader={t['order_payment_type_title']}>
            <ModalTitle>{t['order_payment_type_subtitle']}</ModalTitle>
            <ModalLargeText>{t['order_payment_type_description']}</ModalLargeText>
            <StyledTabs
               value={currentMessageTab}
               onChange={handleTabChangePaymentType}
               aria-label='Tabs'
               style={{
                  width: '100%',
                  marginTop: 20,
                  marginBottom: 20,
               }}
            >
               <StyledTab label={t['order_payment_type_tab'] + ' ' + 1} />
               <StyledTab label={t['order_payment_type_tab'] + ' ' + 2} />
               <StyledTab label={t['order_payment_type_tab'] + ' ' + 3} />
               <StyledTab label={t['order_payment_type_tab'] + ' ' + 4} />
            </StyledTabs>
            <Grid
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
                  marginBottom: 20,
               }}
            >
               <div
                  style={{
                     width: '50%',
                  }}
               >
                  <InputLabel shrink={false} className={classes.inputLabel}>
                     {t.order_payment_type_input_name}
                  </InputLabel>
                  <OutlinedInput
                     value={paymentTypesValue[currentMessageTab]?.name}
                     style={{
                        width: '100%',
                        marginRight: 10,
                     }}
                     onChange={(e) => {
                        const _paymentTypeValue = JSON.parse(JSON.stringify(paymentTypesValue));
                        _paymentTypeValue[currentMessageTab].name = e.target.value;
                        setPaymentTypesValue(_paymentTypeValue);
                     }}
                  />
               </div>
               <div
                  style={{
                     width: '23%',
                  }}
               >
                  <InputLabel shrink={false} className={classes.inputLabel}>
                     {t.order_payment_type_input_price}
                  </InputLabel>
                  <OutlinedInput
                     value={paymentTypesValue[currentMessageTab]?.price}
                     style={{
                        width: '100%',
                        marginRight: 10,
                     }}
                     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                     type='number'
                     onChange={(e) => {
                        const _paymentTypeValue = JSON.parse(JSON.stringify(paymentTypesValue));
                        _paymentTypeValue[currentMessageTab].price = e.target.value;
                        setPaymentTypesValue(_paymentTypeValue);
                     }}
                  />
               </div>
               <div
                  style={{
                     width: '23%',
                  }}
               >
                  <InputLabel shrink={false} className={classes.inputLabel}>
                     {t.order_payment_type_input_id}
                  </InputLabel>
                  <OutlinedInput
                     value={paymentTypesValue[currentMessageTab]?.id}
                     style={{
                        width: '100%',
                        marginRight: 10,
                     }}
                     inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                     type='number'
                     onChange={(e) => {
                        const _paymentTypeValue = JSON.parse(JSON.stringify(paymentTypesValue));
                        _paymentTypeValue[currentMessageTab].id = e.target.value;
                        setPaymentTypesValue(_paymentTypeValue);
                     }}
                  />
               </div>
            </Grid>
            <Typography className={classes.modalSubtitle}>
               {t['order_subpayment_type_subtitle']}{' '}
               <span
                  style={{
                     color: '#9B9B9B',
                     fontSize: 12,
                  }}
               >
                  {t['order_subpayment_type_subtitle_optional']}
               </span>
            </Typography>
            <Typography className={classes.modalBodyText}>{t['order_subpayment_type_description']}</Typography>
            <Grid
               style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'stretch',
                  marginTop: 20,
                  marginBottom: 20,
               }}
            >
               <Grid
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     width: '100%',
                     justifyContent: 'space-between',
                     alignItems: 'stretch',
                     marginBottom: 20,
                  }}
               >
                  <div
                     style={{
                        width: '50%',
                     }}
                  >
                     <InputLabel shrink={false} className={classes.inputLabel}>
                        {t.order_payment_type_input_name}
                     </InputLabel>
                     <OutlinedInput
                        value={paymentTypesValue[currentMessageTab]?.['subPaymentTypes'][0].name}
                        style={{
                           width: '100%',
                           marginRight: 10,
                        }}
                        onChange={(e) => {
                           const _paymentTypeValue = JSON.parse(JSON.stringify(paymentTypesValue));
                           _paymentTypeValue[currentMessageTab]['subPaymentTypes'][0].name = e.target.value;
                           setPaymentTypesValue(_paymentTypeValue);
                        }}
                     />
                  </div>
                  <div
                     style={{
                        width: '23%',
                     }}
                  >
                     <InputLabel shrink={false} className={classes.inputLabel}>
                        {t.order_payment_type_input_price}
                     </InputLabel>
                     <OutlinedInput
                        value={paymentTypesValue[currentMessageTab]?.['subPaymentTypes'][0].price}
                        style={{
                           width: '100%',
                           marginRight: 10,
                        }}
                        type='number'
                        onChange={(e) => {
                           const _paymentTypeValue = JSON.parse(JSON.stringify(paymentTypesValue));
                           _paymentTypeValue[currentMessageTab]['subPaymentTypes'][0].price = e.target.value;
                           setPaymentTypesValue(_paymentTypeValue);
                        }}
                     />
                  </div>
                  <div
                     style={{
                        width: '23%',
                     }}
                  >
                     <InputLabel shrink={false} className={classes.inputLabel}>
                        {t.order_payment_type_input_id}
                     </InputLabel>
                     <OutlinedInput
                        value={paymentTypesValue[currentMessageTab]?.['subPaymentTypes'][0].id}
                        style={{
                           width: '100%',
                           marginRight: 10,
                        }}
                        type='number'
                        onChange={(e) => {
                           const _paymentTypeValue = JSON.parse(JSON.stringify(paymentTypesValue));
                           _paymentTypeValue[currentMessageTab]['subPaymentTypes'][0].id = e.target.value;
                           setPaymentTypesValue(_paymentTypeValue);
                        }}
                     />
                  </div>
               </Grid>
               <Grid
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     width: '100%',
                     justifyContent: 'space-between',
                     alignItems: 'stretch',
                  }}
               >
                  <div
                     style={{
                        width: '50%',
                     }}
                  >
                     <InputLabel shrink={false} className={classes.inputLabel}>
                        {t.order_payment_type_input_name}
                     </InputLabel>
                     <OutlinedInput
                        value={paymentTypesValue[currentMessageTab]?.['subPaymentTypes'][1].name}
                        style={{
                           width: '100%',
                           marginRight: 10,
                        }}
                        onChange={(e) => {
                           const _paymentTypeValue = JSON.parse(JSON.stringify(paymentTypesValue));
                           _paymentTypeValue[currentMessageTab]['subPaymentTypes'][1].name = e.target.value;
                           setPaymentTypesValue(_paymentTypeValue);
                        }}
                     />
                  </div>
                  <div
                     style={{
                        width: '23%',
                     }}
                  >
                     <InputLabel shrink={false} className={classes.inputLabel}>
                        {t.order_payment_type_input_price}
                     </InputLabel>
                     <OutlinedInput
                        value={paymentTypesValue[currentMessageTab]?.['subPaymentTypes'][1].price}
                        style={{
                           width: '100%',
                           marginRight: 10,
                        }}
                        type='number'
                        onChange={(e) => {
                           const _paymentTypeValue = JSON.parse(JSON.stringify(paymentTypesValue));
                           _paymentTypeValue[currentMessageTab]['subPaymentTypes'][1].price = e.target.value;
                           setPaymentTypesValue(_paymentTypeValue);
                        }}
                     />
                  </div>
                  <div
                     style={{
                        width: '23%',
                     }}
                  >
                     <InputLabel shrink={false} className={classes.inputLabel}>
                        {t.order_payment_type_input_id}
                     </InputLabel>
                     <OutlinedInput
                        value={paymentTypesValue[currentMessageTab]?.['subPaymentTypes'][1].id}
                        style={{
                           width: '100%',
                           marginRight: 10,
                        }}
                        type='number'
                        onChange={(e) => {
                           const _paymentTypeValue = JSON.parse(JSON.stringify(paymentTypesValue));
                           _paymentTypeValue[currentMessageTab]['subPaymentTypes'][1].id = e.target.value;
                           setPaymentTypesValue(_paymentTypeValue);
                        }}
                     />
                  </div>
               </Grid>
            </Grid>
            <ModalButtonField>
               <ButtonCancel
                  label={t['comp_button_cancel']}
                  onClick={() => setIsPaymentTypesSettingsOpen(false)}
                  disabled={settingsLoading}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                  }}
               />

               <ButtonOK
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     float: 'right'
                  }}
                  disabled={settingsLoading}
                  startIcon={settingsLoading ? <CircularProgress color={"secondary"} size={20} /> : null}
                  label={t['comp_button_save']}
                  onClick={() => {
                     updateECommercePlatformSettings({
                        variables: {
                           type: E_COMMERCE_PLATFORM_TYPE.TSOFT,
                           metaData: {
                              _id: platformId,
                              paymentTypes: {
                                 types: paymentTypesValue,
                              },
                           },
                        },
                        onCompleted: () => {
                           setIsPaymentTypesSettingsOpen(false);
                        },
                        onError: (err) => {
                           console.log(err);
                           setIsPaymentTypesSettingsOpen(false);
                        },
                     });
                  }}
               />
            </ModalButtonField>
         </Modal>
         <Modal open={isConfirmationLabelSettingsOpen} onClose={() => setIsConfirmationLabelSettingsOpen(false)} modalHeader={t['order_confirmation_label_modal_title']}>
            <ModalTitle className={classes.modalSubtitle}>
               {t['order_confirmation_label_modal_subtitle']}
            </ModalTitle>
            <ModalLargeText className={classes.modalBodyText}>
               {t['order_confirmation_label_modal_description']}
            </ModalLargeText>
            <Select
               value={orderConfirmationLabelValue}
               onChange={(e: any) => {
                  console.log(e);
                  setOrderConfirmationLabelValue(e);
               }}
               className={classes.select}
               options={chatTagsData?.getChatTagList?.data?.docs?.map((tag: any) => ({
                  value: tag._id,
                  label: tag.name,
               }))}
            />
            <ModalButtonField>
               <ButtonCancel
                  label={t['comp_button_cancel']}
                  disabled={settingsLoading}
                  onClick={() => setIsConfirmationLabelSettingsOpen(false)}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                  }}
               />

               <ButtonOK
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     float: 'right'
                  }}
                  disabled={settingsLoading}
                  startIcon={settingsLoading ? <CircularProgress color={"secondary"} size={20} /> : null}
                  label={t['comp_button_save']}
                  onClick={() => {
                     updateECommercePlatformSettings({
                        variables: {
                           type: E_COMMERCE_PLATFORM_TYPE.TSOFT,
                           metaData: {
                              _id: platformId,
                              orderConfirmationLabel: {
                                 isOn: isConfirmationLabelOn,
                                 label: orderConfirmationLabelValue.value,
                              },
                           },
                        },
                        onCompleted: () => {
                           setIsConfirmationLabelSettingsOpen(false);
                        },
                        onError: (err) => {
                           console.log(err);
                           setIsConfirmationLabelSettingsOpen(false);
                        },
                     });
                  }}
               />
            </ModalButtonField>
         </Modal>
      </div>
   );
};

export default SettingsPage;

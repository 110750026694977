import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, LinearProgress, Typography } from '@material-ui/core';
import { LanguageContext } from '../../../../../../../../helper/locale/langContext';
import { useForm } from 'react-hook-form';
import { ORDER_FLAGS } from '../../Store/types';
import Input from '../../../../../../../../components/InputField';
import OrderCard from '../../../../../ChatItem/OrderCard';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from '@apollo/client';
import { GET_E_COMMERCE_ORDERS_QUERY } from '@queries/Settings/Service/GraphQL/ECommercePlatform/query';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { E_COMMERCE_ORDERS, SET_E_COMMERCE_TOTAL_NUMBER_OF_ORDERS } from '@store/actions/ecommerce';
import useWindowResize from '@hooks/useWindowResize';

const useStyles = makeStyles(() => ({
   listAndInputs: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
   },
   inputsContainer: {
      marginTop: -15,
      marginBottom: -15,
      marginLeft: 8,
      marginRight: 8,
   },
   listContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 3,
   },
   linearProgressContainer: {
      height: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
   },
}));

interface OrderListProps {
   openOrderDetail: (order: any) => void;
   customer?: string;
}

const OrderList: FC<OrderListProps> = ({ openOrderDetail, customer }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const dispatch = useDispatch();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const me = useSelector((rootReducer: any) => rootReducer.personReducer.person);
   const eCommerceOrders = useSelector((rootReducer: any) => rootReducer.eCommerceReducer?.eCommerceOrders);
   const [searchText, setSearchText] = useState('');
   const [fetchingMore, setFetchingMore] = useState(false);
   const { height } = useWindowResize();

   const { watch, control } = useForm({
      mode: 'all',
      defaultValues: {
         searchOrder: '',
         selectOrderStatus: {
            value: ORDER_FLAGS.ALL_ORDERS,
            label: t[`page_e_commerce_store_status_option_${ORDER_FLAGS.ALL_ORDERS.toLowerCase()}`],
         },
      },
   });

   const watchSearchOrder = watch('searchOrder');
   const watchSelectOrderStatus = watch('selectOrderStatus');

   useEffect(() => {
      const timer = setTimeout(() => {
         setSearchText(watchSearchOrder);
      }, 500);
      return () => clearTimeout(timer);
   }, [watchSearchOrder]);

   const { loading, fetchMore } = useQuery(GET_E_COMMERCE_ORDERS_QUERY, {
      variables: {
         customer: me.customer._id,
         platformCustomer: customer?.toString(),
         page: 1,
         query: searchText,
         status: watchSelectOrderStatus.value === ORDER_FLAGS.ALL_ORDERS ? undefined : watchSelectOrderStatus.value,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
         dispatch({
            type: E_COMMERCE_ORDERS,
            payload: { eCommerceOrders: data.getECommerceOrders.data },
         });
         if (searchText === '') {
            dispatch({
               type: SET_E_COMMERCE_TOTAL_NUMBER_OF_ORDERS,
               payload: { eCommerceTotalNumberOfOrders: data?.getECommerceOrders?.data?.totalOrders || 0 },
            });
         }
      },
      onError: (error: any) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key: any) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const selectOrderOptions = Object.values(ORDER_FLAGS).map((flag) => ({
      value: flag,
      label: t[`page_e_commerce_store_status_option_${flag?.toLowerCase()}`],
   }));

   const handleNext = useCallback(() => {
      if (eCommerceOrders?.hasNextPage) {
         setFetchingMore(true);
         fetchMore({
            variables: {
               page: eCommerceOrders?.nextPage,
               pageToken: eCommerceOrders?.nextPageToken,
            },
         })
            .then(({ data }: any) => {
               dispatch({
                  type: E_COMMERCE_ORDERS,
                  payload: {
                     eCommerceOrders: {
                        ...data.getECommerceOrders.data,
                        orders: [...eCommerceOrders?.orders, ...data.getECommerceOrders.data?.orders],
                     },
                  },
               });
            })
            .finally(() => {
               setFetchingMore(false);
            });
      }
   }, [
      eCommerceOrders?.totalDocs,
      eCommerceOrders?.hasNextPage,
      eCommerceOrders?.nextPage,
      eCommerceOrders?.nextPageToken,
      eCommerceOrders?.page,
   ]);

   return (
      <div className={classes.listAndInputs}>
         <div className={classes.inputsContainer}>
            <Grid container direction='row' alignItems='center' justify='space-between' wrap='nowrap'>
               <Grid item xs={6}>
                  <Input
                     name='searchOrder'
                     required={false}
                     control={control}
                     placeholder={t['page_e_commerce_store_search_order_input_placeholder']}
                  />
               </Grid>
               <Grid item xs={6} style={{ paddingLeft: 8 }}>
                  <Input
                     isSelect={true}
                     selectOptions={selectOrderOptions}
                     name='selectOrderStatus'
                     control={control}
                     customSelectStyles={{ textTransform: 'capitalize' }}
                  />
               </Grid>
            </Grid>
         </div>
         <div className={classes.linearProgressContainer}>{loading && <LinearProgress />}</div>
         <div className={classes.listContainer}>
            <InfiniteScroll
               next={handleNext}
               dataLength={eCommerceOrders?.orders?.length || 0}
               height={height - 385}
               loader={
                  <Grid
                     style={{
                        height: 350,
                        margin: '20px 0px',
                     }}
                     container
                     justifyContent='center'
                     alignItems='center'
                     xs={12}
                  >
                     {fetchingMore && <CircularProgress size={24} color='primary' />}
                     {!fetchingMore && eCommerceOrders?.orders?.length < 1 && (
                        <Typography>{t['page_e_commerce_products_search_order_not_found']}</Typography>
                     )}
                  </Grid>
               }
               hasMore={eCommerceOrders?.hasNextPage}
               style={{
                  display: 'flex',
                  flexDirection: 'column',
                  overflowX: 'hidden',
               }}
            >
               {eCommerceOrders?.orders?.map((order: any, index: number) => (
                  <OrderCard key={index} order={order} cursorPointer={true} onClick={() => openOrderDetail(order)} />
               ))}
            </InfiniteScroll>
         </div>
      </div>
   );
};

export default OrderList;

import { TOGGLE_PROFILE, UI_WIDTH, TOGGLE_QUESTION_DRAWER_PROFILE } from '../actions/ui'; // action types
// default initial state
const INITIAL_STATE = {
   width: 0,
   height: 0,
   isOpenChatProfileMenu: false,
   isOpenQuestionProfileMenu: true,
};

export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case UI_WIDTH:
         return {
            ...state,
            height: action.payload.height,
            width: action.payload.width,
         };
      case TOGGLE_PROFILE:
         return {
            ...state,
            isOpenChatProfileMenu: action.payload,
         };
      case TOGGLE_QUESTION_DRAWER_PROFILE:
         return {
            ...state,
            isOpenQuestionProfileMenu: action.payload,
         };
      default:
         return state;
   }
};

export const getIsOpenChatProfileMenu = (state) => state.uiReducer.isOpenChatProfileMenu;
export const getIsOpenQuestionProfileMenu = (state) => state.uiReducer.isOpenQuestionProfileMenu;
export const getWidth = (state) => state.uiReducer.width;
export const getHeight = (state) => state.uiReducer.height;

import { ButtonBase, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';
import LeftMenuItem from '@components/LeftMenuItem';

const useStyles = makeStyles((theme) => ({
   container: {
      marginTop: 5,
      paddingLeft: 36,
      fontWeight: 500,
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      transition: 'all 1ms',
      [theme.breakpoints.down('md')]: {
         paddingLeft: 0,
      },
   },
   titleContainer: {
      padding: 10,
      borderRadius: 10,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginLeft: 10,
      width: '100%',
      [theme.breakpoints.down('md')]: {
         marginLeft: 0,
      },
   },
   title: {
      marginTop: 5,
      marginLeft: 15,
      fontWeight: 500,
      fontSize: '1.1rem',
      color: '#0E223C',
      [theme.breakpoints.down('md')]: {
         display: 'none',
      },
   },
   iconHolder: {
      '& .SettingPageIcon': {
         fill: (props) => (props.selected ? theme.palette.primary.main : '#9BA1BA'),
         filter: (props) => (props.selected ? 'none' : 'grayscale(100%)'),
      },
   },
}));

const SettingSection = (props) => {
   const { section, onItemChange, onClick, selected, clickable } = props;
   const history = useHistory();
   const classes = useStyles(props);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const handleItemClick = (item) => {
      onItemChange(item);
   };

   return (
      <Fragment>
         <ButtonBase
            style={{ width: '100%' }}
            disableRipple={!clickable}
            onClick={() => (section.items?.length > 0 ? onClick : handleItemClick(section))}
         >
            <Grid
               item
               xs={12}
               container
               wrap='nowrap'
               justifyContent='center'
               direction='row'
               className={classes.titleContainer}
               style={{
                  background: selected ? '#fff' : undefined,
               }}
            >
               <Grid container justifyContent='flex-start' alignItems='center' className={classes.iconHolder}>
                  {section.icon}
                  <Typography className={classes.title}> {t && t[section.title]} </Typography>
               </Grid>
            </Grid>
         </ButtonBase>
         <Grid container direction={'column'}>
            {section.items?.map((item) => (
               <LeftMenuItem
                  label={t[item?.title]}
                  url={item.url}
                  selected={history.location.pathname.includes(item.url)}
                  icon={item?.icon}
                  containerClass={classes.container}
                  hoverClass={classes.hover}
               />
            ))}
         </Grid>
      </Fragment>
   );
};

export default SettingSection;

import { useMutation } from '@apollo/client';
import { WhatsappIcon } from '@assets/icons/SettingsIcons/settingsIcons';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CREATE_CAMPAIGN } from '@queries/Campaigns/Service/GraphQL/mutation';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';

const useStyles = makeStyles((theme) => ({
   wpIcon: {
      width: 20,
      height: 20,
   },
   modalContainer: {
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 16,
      paddingBottom: 15,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalBody: {
      display: 'flex',
      flexDirection: 'column',
      paddingInline: 20,
      height: '100%',
   },
   modalBodyIconContainer: {
      display: 'flex',
      flexDirection: 'column',
   },
   modalDescription: {
      flexDirection: 'column',
      justifyContent: 'space-between',
   },
   modalBodyRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
   },
   modalText: {
      color: '#797575',
      fontSize: '1rem',
      margin: 20,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const CreateCampaign = ({ open, onClose }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [createLoading, setCreateLoading] = useState(false);
   const { enqueueSnackbar } = useSnackbar();
   const person = useSelector((reducer) => reducer.personReducer.person);
   const [selectedPlatform, setSelectedPlatform] = React.useState('WHATS_APP');
   const {
      register,
      control,
      handleSubmit,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         name: '',
      },
   });

   const [createCampaignMutation] = useMutation(CREATE_CAMPAIGN, {
      async update(
         cache,
         {
            data: {
               createCampaign: { data: createCampaign },
            },
         },
      ) {
         window.location.href = '/campaigns/' + createCampaign._id;
      },
   });

   const createCampaignSubmit = (data) => {
      setCreateLoading(true);
      createCampaignMutation({
         variables: {
            input: {
               ...data,
               customer: person.customer._id,
               platform_type: selectedPlatform,
            },
         },
      })
         .then((_) => {
            setCreateLoading(false);
         })
         .catch((err) => {
            setCreateLoading(false);
            onClose();
            enqueueSnackbar(err.message, {
               // send error message
               variant: 'error',
               persist: true,
            });
         });
   };

   return (
      <Modal paperStyle={{ width: 450 }} onClose={onClose} open={open} modalHeader={t['campaign_create']}>
         <Grid container>
            <Typography style={{ paddingLeft: 2, colr: '#212121', fontSize: 18 }}>
               {t['campaign_select_channel']}
            </Typography>
            <Grid container justifyContent='space-between' style={{ marginBottom: 20 }}>
               <Button
                  variant='outlined'
                  disabled
                  style={{
                     width: 200,
                     border: selectedPlatform === 'SMS' ? '1px solid #25D366' : '1px solid #E4E6EB',
                  }}
                  onClick={() => setSelectedPlatform('SMS')}
               >
                  SMS ({t['soon']})
               </Button>
               <Button
                  variant='outlined'
                  startIcon={<WhatsappIcon className={classes.wpIcon} />}
                  onClick={() => setSelectedPlatform('WHATS_APP')}
                  style={{
                     width: 200,
                     border: selectedPlatform === 'WHATS_APP' ? '1px solid #25D366' : '1px solid #E4E6EB',
                  }}
               >
                  WhatsApp
               </Button>
            </Grid>
         </Grid>
         <SingleInput
            name='name'
            label={t['name']}
            rules={{
               required: t?.required_field,
               maxLength: {
                  value: 35,
                  message: t?.campaign_name_character_limit,
               },
               minLength: {
                  value: 3,
                  message: t?.campaign_name_character_limit,
               },
            }}
            control={control}
            id='campaign-name'
            placeholder={t['create_campaign_name_label']}
         />
         <ModalButtonField>
            <ButtonCancel
               label={t?.page_chat_btn_filter_cancel}
               onClick={() => onClose()}
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            />
            <ButtonOK
               disabled={!isValid || createLoading}
               label={t['comp_navbar_campaign_new_create']}
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
               startIcon={createLoading ? <CircularProgress color='inherit' size={20} /> : null}
               onClick={handleSubmit(createCampaignSubmit)}
            />
         </ModalButtonField>
      </Modal>
   );
};

export default WithUrlTranslations(CreateCampaign);

import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import ReactFlow, {
   applyNodeChanges,
   applyEdgeChanges,
   ReactFlowProvider,
   Controls,
   MarkerType,
   Panel,
} from 'reactflow';
import 'reactflow/dist/style.css';
import SendMessageNode from './FlowNodes/sendMessage';
import ActionNode from './FlowNodes/actions';
import DelayNode from './FlowNodes/delay';
import TriggerNode from './FlowNodes/trigger';
import 'reactflow/dist/style.css';
import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/styles';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
   btnPanelInfo: {
      marginTop: 0,
      padding: '10px 20px',
      backgroundColor: '#C3DDFF',
      borderRadius: 10,
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.05)',
   },
}));

const fitViewOptions = {
   padding: 0.5,
};
const defaultEdgeOptions = {
   animated: false,
};
const customStyle = {
   width: '100%',
   height: `calc(100vh - 65px)`,
};

const nodeTypes = {
   send_message: SendMessageNode,
   actions: ActionNode,
   delay: DelayNode,
   trigger: TriggerNode,
};

const Flow = ({ leftNodes, clickedNodeId, setClickedNodeId, isDraft, setIsSecondMessage, isSecondMessage }) => {
   const proOptions = { hideAttribution: true };
   const reactFlowWrapper = useRef(null);
   const classes = useStyles();
   const [allowClickPane, setAllowClickPane] = useState(true);
   const [nodeState, setNodeState] = useState([]);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const initialEdges = [
      {
         id: 'e1-first',
         source: '1-first',
         target: '2-first',
         markerEnd: { type: MarkerType.ArrowClosed },
      },
      {
         id: 'e2-first',
         source: '2-first',
         target: '3-first',
         markerEnd: { type: MarkerType.ArrowClosed },
      },
      {
         id: 'e3-first',
         source: '3-first',
         target: '4-first',
         markerEnd: { type: MarkerType.ArrowClosed },
      },

      {
         id: 'e1-second',
         source: '1-first',
         sourceHandle: '1-first2',
         target: '5-second',
         markerEnd: { type: MarkerType.ArrowClosed },
      },
      {
         id: 'e2-second',
         source: '5-second',
         target: '6-second',
         markerEnd: { type: MarkerType.ArrowClosed },
      },
      {
         id: 'e3-second',
         source: '6-second',
         target: '7-second',
         markerEnd: { type: MarkerType.ArrowClosed },
      },
   ];

   const [edges, setEdges] = useState(initialEdges);

   useEffect(() => {
      setNodeState(leftNodes);
   }, [leftNodes]);

   const calculateTextareaSize = (content) => {
      if (content) {
         const words = content.length;

         let numRows = words / 30;
         const enters = (content.match(/\n/g) || []).length;

         if (enters) {
            numRows += enters;
         }

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: numRows + 1, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };

   useEffect(() => {
      if (!isSecondMessage) {
         setNodeState((prevNodes) => prevNodes.filter((node) => !node.id.includes('-second')));
         setEdges((prevEdges) => prevEdges.filter((edge) => !edge.id.includes('-second')));
      } else {
         const firstMessageNode = leftNodes.find((node) => node.id === '3-first')?.data.flowBlocks;
         const h = calculateTextareaSize(firstMessageNode[0]?.message);

         const updatedNodes = leftNodes.map((node) => {
            if (node.id === '1-first') {
               return {
                  ...node,
                  data: {
                     ...node.data,
                     clickedNodeId: clickedNodeId,
                     isSecondMessage: isSecondMessage,
                  },
               };
            }
            if (h.rows > 2 && node.id && node.id.includes('-second')) {
               const Y = h.rows * 10 + 200;
               return {
                  ...node,
                  position: { x: node.position.x, y: Y },
               };
            }
            return node;
         });
         setEdges(initialEdges);
         setNodeState(updatedNodes);
      }
   }, [leftNodes, clickedNodeId, isSecondMessage]);

   const onNodesChange = useCallback(
      (changes) => setNodeState((nds) => applyNodeChanges(changes, nds)),
      [setNodeState],
   );

   const onEdgesChange = useCallback((changes) => setEdges((eds) => applyEdgeChanges(changes, eds)), [setEdges]);
   const ruleStatus = useSelector((reducer) => reducer.automationReducer.ruleSatus);

   const handlePaneClick = () => {
      setClickedNodeId('');
      if (allowClickPane) {
         const updatedNodes = nodeState.map((node) => ({
            ...node,
            data: {
               ...node.data,
               clickedNodeId: '',
            },
         }));
         setNodeState(updatedNodes);
      }
   };

   // Update nodes data in each custom node
   useEffect(() => {
      if (clickedNodeId) {
         const updatedNodes = nodeState.map((node) => ({
            ...node,
            data: {
               ...node.data,
               clickedNodeId: clickedNodeId,
               setClickedNodeId: setClickedNodeId,
               isSecondMessage: isSecondMessage,
            },
         }));

         setNodeState(updatedNodes);
      }
   }, [clickedNodeId, isSecondMessage]);

   const handleNodeSelection = (event, node) => {
      event.stopPropagation();
      if (ruleStatus === 'draft') {
         setClickedNodeId(node.id);
         setAllowClickPane(true);
      }
   };

   return (
      <div style={customStyle} ref={reactFlowWrapper}>
         <ReactFlowProvider>
            <ReactFlow
               nodes={nodeState}
               edges={edges}
               onNodesChange={onNodesChange}
               onEdgesChange={onEdgesChange}
               onClickConnectEnd={() => setAllowClickPane(true)}
               proOptions={proOptions}
               fitView
               fitViewOptions={fitViewOptions}
               defaultEdgeOptions={defaultEdgeOptions}
               onNodeClick={(event, node) => handleNodeSelection(event, node)}
               nodeTypes={nodeTypes}
               onPaneClick={handlePaneClick}
               nodesDraggable={false}
               edgesUpdatable={false}
               edgesFocusable={false}
               nodesConnectable={false}
               nodesFocusable={false}
            >
               <Controls position='top-right' showInteractive={isDraft} />

               {!isDraft && (
                  <Panel position='top-center' className={classes.btnPanelInfo}>
                     <span>{t.automation_flow_viewing_publish}</span>
                  </Panel>
               )}
            </ReactFlow>
         </ReactFlowProvider>
      </div>
   );
};

export default Flow;

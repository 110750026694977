import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import LeftContent from './LeftContent/leftContent';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme) => ({
   header: {
      borderBottom: '1px solid #D7D8D8',
      padding: '15px 20px 20px 20px',
      height: '65px',
      fontSize: '1.3rem',
   },
}));

interface FlowProps {
   setFilter: React.Dispatch<React.SetStateAction<string>>;
   setShowEcommerce: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftPanel: React.FC<FlowProps> = ({ setFilter, setShowEcommerce }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   return (
      <Grid style={{ height: '100%', backgroundColor: '#F1F1F1' }}>
         <Grid className={classes.header}>
            <div style={{ marginTop: '5px' }}>{t.automation_flow}</div>
         </Grid>
         <Grid>
            <LeftContent setFilter={setFilter} setShowEcommerce={setShowEcommerce} />
         </Grid>
      </Grid>
   );
};

export default LeftPanel;

import React, { useContext, useState, useEffect, Fragment } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import MinimizeRoundedIcon from '@material-ui/icons/MinimizeRounded';
import SentimentSatisfiedRoundedIcon from '@material-ui/icons/SentimentSatisfiedRounded';
import AttachFileRoundedIcon from '@material-ui/icons/AttachFileRounded';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { BotLogo, ThumbUp } from './Icons';
import classNames from 'classnames';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';
import { LIVE_CHAT_ICON_POSITION } from '../types';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflow: 'hidden',
      borderRadius: '8px',
      width: '100%',
      height: '100%',
      maxWidth: '350px',
      maxHeight: '600px',
      marginLeft: (props) =>
         (props.iconPosition === LIVE_CHAT_ICON_POSITION.RIGHT ||
            props.iconPosition === LIVE_CHAT_ICON_POSITION.TOP_RIGHT ||
            props.type === 'home') &&
         'auto',
      marginRight: (props) =>
         (props.iconPosition === LIVE_CHAT_ICON_POSITION.LEFT ||
            props.iconPosition === LIVE_CHAT_ICON_POSITION.TOP_LEFT ||
            props.type === 'home') &&
         'auto',
      boxShadow: '0px 0px 21px -8px rgba(204,204,204,1)',
   },
   headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      padding: '15px',
      background: (props) => props.themeColor || '#157CFC',
   },
   headerLeft: {
      flexShrink: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      alignSelf: 'center',
      width: '36px',
      height: '36px',
      backgroundColor: (props) => (props.textColor === '#FFFFFF' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)'),
      borderRadius: '12px',
   },
   headerLeftIcon: {
      fontSize: '30px',
      color: (props) => props.textColor || '#FFF',
   },
   headerBody: {
      flexGrow: '1',
      alignSelf: 'center',
      display: 'flex',
      alignItems: 'flex-start',
      marginLeft: '10px',
      marginRight: '10px',
   },
   botLogoHeader: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      width: '36px',
      height: '36px',
      fontSize: '36px',
      borderRadius: '36px',
      color: '#FFFFFF',
   },
   botLogoChat: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexShrink: 0,
      width: '36px',
      height: '36px',
      fontSize: '36px',
      borderRadius: '36px',
      color: '#178cf9',
   },
   headerRight: {
      flexShrink: '0',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      width: '36px',
      height: '36px',
   },
   headerRightIcon: {
      transform: 'rotate(180deg)',
      fontSize: '30px',
      color: (props) => props.textColor || '#FFF',
   },
   titleAndStatus: {
      flexGrow: '1',
      display: 'flex',
      justifyContent: 'space-evenly',
      alignSelf: 'stretch',
      flexDirection: 'column',
      marginLeft: '7.5px',
   },
   noAvatar: {
      marginLeft: '2px',
   },
   headerTitle: {
      fontSize: '1rem',
      fontWeight: '600',
      color: (props) => props.textColor || '#FFF',
      lineHeight: '1.2',
   },
   emoji: {
      fontWeight: 'normal',
   },
   statusContainer: {
      display: 'flex',
      alignItems: 'center',
   },
   statusDot: {
      width: '10px',
      height: '10px',
      borderRadius: '10px',
      backgroundColor: '#42E26C',
      border: '2px solid #FFF',
      marginRight: '5px',
   },
   offline: {
      backgroundColor: '#848484',
   },
   headerStatus: {
      fontSize: '.75rem',
      color: (props) => props.textColor || '#FFF',
      lineHeight: 'normal',
   },
   icon: {
      fontSize: '30px',
   },
   colorGray: {
      color: '#444C64',
   },
   sendIcon: {
      color: (props) => props.themeColor || '#FFF',
   },
   main: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#FFF',
      minHeight: '350px',
      overflowY: 'auto',
   },
   dateContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      paddingTop: '7.5px',
      paddingBottom: '7.5px',
   },
   date: {
      color: '#9A9A9A',
      backgroundColor: '#FFF',
      paddingLeft: '5px',
      paddingRight: '5px',
      zIndex: 1,
   },
   dateBackgroundLine: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: '50%',
      height: '1px',
      backgroundColor: '#EDEDED',
   },
   messagesGroup: {
      display: 'flex',
      paddingLeft: '15px',
      paddingRight: '15px',
      paddingBottom: '10px',
   },
   avatar: {
      flex: 1,
      alignSelf: 'flex-end',
      display: 'flex',
      marginRight: '8px',
   },
   elements: {
      flex: 9,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
   },
   message: {
      backgroundColor: '#F7F7FA',
      fontSize: '.85rem',
      padding: '.5rem',
      borderRadius: '7px',
      color: '#10223A',
      maxWidth: '85%',
      marginTop: '8px',
      whiteSpace: 'pre-line',
   },
   messageRight: {
      backgroundColor: (props) => props.themeColor,
      color: (props) => props.textColor,
      alignSelf: 'flex-end',
   },
   infoContainer: {
      backgroundColor: '#F7F7FA',
      padding: '.5rem',
      borderRadius: '7px',
      width: '85%',
      marginTop: '8px',
   },
   infoContainerRight: {
      alignSelf: 'flex-end',
   },
   infoTitle: {
      fontSize: '.9rem',
      fontWeight: 'bold',
      color: '#10223A',
   },
   infoMessage: {
      marginTop: '4px',
      fontSize: '.85rem',
      color: '#10223A',
   },
   positive: {
      color: '#5DCC77',
      marginTop: 0,
      lineHeight: 'normal',
   },
   thumbs: {
      marginTop: '8px',
      display: 'flex',
   },
   thumbUpContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '36px',
      marginRight: '4px',
      backgroundColor: '#D7EEDF',
      color: '#5DCC77',
      border: '1px solid #EDEDED',
      borderRadius: '8px',
   },
   thumbDownContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '36px',
      marginLeft: '4px',
      backgroundColor: '#FFCCD2',
      color: '#ED3737',
      border: '1px solid #EDEDED',
      borderRadius: '8px',
      transform: 'rotate(180deg)',
   },
   fakeInput: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      marginTop: '8px',
      height: '35px',
      padding: '5px 15px',
      borderRadius: '4px',
      border: '1px solid #EBEBEB',
      backgroundColor: '#FFFFFF',
   },
   fakeMultilineInput: {
      alignItems: 'flex-start',
      height: '75px',
   },
   fakeInputText: {
      color: '#9F9F9F',
   },
   feedbackText: {
      color: '#10223A',
   },
   fakeButton: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '8px',
      height: '35px',
      padding: '5px 15px',
      borderRadius: '4px',
      backgroundColor: (props) => props.themeColor,
   },
   fakeButtonText: {
      fontWeight: '500',
      fontSize: '1rem',
      color: (props) => props.textColor,
   },
   rateUsThanksHeader: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   titleAndFeedbackStatus: {
      display: 'flex',
      flexDirection: 'column',
   },
   singleThumbContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '90px',
      minHeight: '36px',
      marginLeft: '8px',
      backgroundColor: '#D7EEDF',
      color: '#5DCC77',
      border: '1px solid #EDEDED',
      borderRadius: '8px',
   },
   footerContainer: {
      display: 'flex',
      flexDirection: 'column',
      borderTop: '1px solid #EDEDED',
      backgroundColor: '#FFFFFF',
   },
   inputAndIcons: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '15px',
   },
   input: {
      fontSize: '.7rem',
      color: '#9A9A9A',
   },
   footerIcons: {
      display: 'flex',
   },
   footerIconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '18px',
      height: '18px',
      margin: '2px',
   },
   footerIcon: {
      fontSize: '18px',
   },
   poweredByQpien: {
      textAlign: 'center',
      color: '#444C64',
      fontSize: '.65rem',
      paddingBottom: '10px',
   },
   qpien: {
      color: '#157CFC',
      textAlign: 'center',
   },
}));

const Chat = ({ type, children }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { liveChatPreviewSettings } = useContext(LiveChatSettingsContext);
   const {
      themeColor,
      textColor,
      iconPosition,
      greetingTitle,
      welcomeMessageDuringWorkingHoursMessage1,
      welcomeMessageOutsideOfWorkingHoursMessage1,
      contactInformationTitle,
      contactInformationMessage,
      contactInformationThanksMessage,
      contactInformationFullName,
      contactInformationEmailAddress,
      contactInformationPhoneNumber,
      contactInformationAddress,
      contactInformationPermission,
      contactInformationPermissionContent,
      contactInformationPermissionLinkUrl,
      contactInformationPermissionLinkText,
      rateUsTitle,
      rateUsMessage,
      rateUsThanksMessage,
      onlineStatus,
      agentProfiles,
   } = liveChatPreviewSettings;
   const classes = useStyles({ themeColor, textColor, iconPosition, type });
   const [chatData, setChatData] = useState();

   const permissionTextHandler = (text) => {
      if (!text) return text;
      if (text?.length > 70) {
         return text?.substring(0, 70);
      } else return text;
   };

   useEffect(() => {
      switch (type) {
         case 'welcomeMessageDuringWorkingHours':
            setChatData([
               {
                  elements: [
                     {
                        content: t['page_settings_typo_platforms_live_chat_get_started_welcome_preview_hello_there'],
                        type: 'text',
                     },
                  ],
                  sender: 'right',
               },
               {
                  elements: [
                     {
                        content: welcomeMessageDuringWorkingHoursMessage1,
                        type: 'text',
                     },
                     {
                        type: 'text',
                     },
                  ],
                  sender: 'left',
               },
            ]);
            break;
         case 'welcomeMessageOutsideOfWorkingHours':
            setChatData([
               {
                  elements: [
                     {
                        content: t['page_settings_typo_platforms_live_chat_get_started_welcome_preview_hello_there'],
                        type: 'text',
                     },
                  ],
                  sender: 'right',
               },
               {
                  elements: [
                     {
                        content: welcomeMessageOutsideOfWorkingHoursMessage1,
                        type: 'text',
                     },
                  ],
                  sender: 'left',
               },
            ]);
            break;
         case 'contactInformation':
            setChatData([
               {
                  elements: [
                     {
                        content:
                           welcomeMessageDuringWorkingHoursMessage1 ||
                           welcomeMessageOutsideOfWorkingHoursMessage1 ||
                           t[
                              'page_settings_typo_platforms_live_chat_get_started_contact_information_preview_message_1'
                           ],
                        type: 'text',
                     },
                  ],
                  sender: 'left',
               },
               {
                  elements: [
                     {
                        title: contactInformationTitle,
                        message: contactInformationMessage,
                        type: 'info',
                     },
                  ],
                  sender: 'left',
               },
            ]);
            break;
         case 'contactInformationThanks':
            setChatData([
               {
                  elements: [
                     {
                        content:
                           welcomeMessageDuringWorkingHoursMessage1 ||
                           welcomeMessageOutsideOfWorkingHoursMessage1 ||
                           t[
                              'page_settings_typo_platforms_live_chat_get_started_contact_information_preview_message_1'
                           ],
                        type: 'text',
                     },
                  ],
                  sender: 'left',
               },
               {
                  elements: [
                     {
                        title: t[
                           'page_settings_typo_platforms_live_chat_get_started_contact_information_preview_contact_details'
                        ],
                        type: 'info',
                     },
                  ],
                  sender: 'right',
               },
               {
                  elements: [
                     {
                        content: contactInformationThanksMessage,
                        type: 'text',
                     },
                  ],
                  sender: 'left',
               },
            ]);
            break;
         case 'rateUs':
            setChatData([
               {
                  elements: [
                     {
                        content:
                           welcomeMessageDuringWorkingHoursMessage1 ||
                           welcomeMessageOutsideOfWorkingHoursMessage1 ||
                           t['page_settings_typo_platforms_live_chat_get_started_rate_us_preview_message_1'],
                        type: 'text',
                     },
                  ],
                  sender: 'left',
               },
               {
                  elements: [
                     {
                        title: rateUsTitle,
                        message: rateUsMessage,
                        type: 'rate',
                     },
                  ],
                  sender: 'left',
               },
            ]);
            break;
         case 'rateUsThanks':
            setChatData([
               {
                  elements: [
                     {
                        content:
                           welcomeMessageDuringWorkingHoursMessage1 ||
                           welcomeMessageOutsideOfWorkingHoursMessage1 ||
                           t['page_settings_typo_platforms_live_chat_get_started_rate_us_preview_message_1'],
                        type: 'text',
                     },
                  ],
                  sender: 'left',
               },
               {
                  elements: [
                     {
                        title: t['page_settings_typo_platforms_live_chat_get_started_rate_us_your_feedback'],
                        type: 'rate',
                     },
                  ],
                  sender: 'right',
               },
               {
                  elements: [
                     {
                        content: rateUsThanksMessage,
                        type: 'text',
                     },
                  ],
                  sender: 'left',
               },
               {
                  elements: [
                     {
                        content: t['page_settings_typo_platforms_live_chat_get_started_rate_us_after_thanks'],
                        type: 'text',
                     },
                  ],
                  sender: 'right',
               },
            ]);
            break;
         default:
            setChatData([
               {
                  elements: [
                     {
                        content: t['page_settings_typo_platforms_live_chat_home_preview_message_1'],
                        type: 'text',
                     },
                     {
                        content: t['page_settings_typo_platforms_live_chat_home_preview_message_2'],
                        type: 'text',
                     },
                     {
                        content: t['page_settings_typo_platforms_live_chat_home_preview_message_3'],
                        type: 'text',
                     },
                  ],
                  sender: 'left',
               },
            ]);
      }
   }, [
      welcomeMessageDuringWorkingHoursMessage1,
      welcomeMessageOutsideOfWorkingHoursMessage1,
      contactInformationTitle,
      contactInformationMessage,
      contactInformationThanksMessage,
      rateUsTitle,
      rateUsMessage,
      rateUsThanksMessage,
   ]);

   return (
      <div className={classes.container}>
         <Grid container className={classes.headerContainer}>
            <div className={classes.headerLeft}>
               <NavigateBeforeRoundedIcon className={classes.headerLeftIcon} />
            </div>
            <Grid container className={classes.headerBody}>
               {agentProfiles && (
                  <div className={classes.botLogoHeader}>
                     <BotLogo />
                  </div>
               )}
               <div className={classNames(classes.titleAndStatus, !agentProfiles && classes.noAvatar)}>
                  <Typography variant={'body1'} noWrap className={classes.headerTitle}>
                     {greetingTitle}
                  </Typography>
                  {onlineStatus && (
                     <div className={classes.statusContainer}>
                        <div
                           className={classNames(
                              classes.statusDot,
                              type && type === 'welcomeMessageOutsideOfWorkingHours' && classes.offline,
                           )}
                        />
                        <Typography variant={'body2'} noWrap className={classes.headerStatus}>
                           {type && type === 'welcomeMessageOutsideOfWorkingHours'
                              ? t['page_settings_typo_platforms_live_chat_home_preview_status_offline']
                              : t['page_settings_typo_platforms_live_chat_home_preview_status_online']}
                        </Typography>
                     </div>
                  )}
               </div>
            </Grid>
            <div className={classes.headerRight}>
               <MinimizeRoundedIcon className={classes.headerRightIcon} />
            </div>
         </Grid>
         <div className={classes.main}>
            <div className={classes.dateContainer}>
               <Typography variant={'body2'} noWrap className={classes.date}>
                  08.08.2021
               </Typography>
               <div className={classes.dateBackgroundLine} />
            </div>
            {chatData &&
               chatData.map((datum, datumIndex) => (
                  <div key={datumIndex.toString()} className={classes.messagesGroup}>
                     {datum.sender === 'left' && agentProfiles && (
                        <div className={classes.avatar}>
                           <div className={classes.botLogoChat}>
                              <BotLogo />
                           </div>
                        </div>
                     )}
                     <div className={classes.elements}>
                        {datum.elements.map((element, elementIndex) => (
                           <Fragment key={elementIndex.toString()}>
                              {element.type === 'text' && element.content && (
                                 <Typography
                                    className={classNames(
                                       classes.message,
                                       datum.sender === 'right' && classes.messageRight,
                                    )}
                                 >
                                    {element.content}
                                 </Typography>
                              )}
                              {element.type === 'info' && (
                                 <div
                                    className={classNames(
                                       classes.infoContainer,
                                       datum.sender === 'right' && classes.infoContainerRight,
                                    )}
                                 >
                                    {element.title && (
                                       <Typography variant='body1' className={classes.infoTitle}>
                                          {element.title}
                                       </Typography>
                                    )}
                                    {element.message && (
                                       <Typography variant='body2' className={classes.infoMessage}>
                                          {element.message}
                                       </Typography>
                                    )}
                                    {contactInformationFullName && (
                                       <div className={classes.fakeInput}>
                                          <Typography noWrap variant='body2' className={classes.fakeInputText}>
                                             {type === 'contactInformation'
                                                ? t[
                                                     'page_settings_typo_platforms_live_chat_get_started_form_contact_information_full_name'
                                                  ]
                                                : t[
                                                     'page_settings_typo_platforms_live_chat_get_started_contact_information_preview_contact_details_full_name'
                                                  ]}
                                          </Typography>
                                       </div>
                                    )}
                                    {contactInformationEmailAddress && (
                                       <div className={classes.fakeInput}>
                                          <Typography noWrap variant='body2' className={classes.fakeInputText}>
                                             {type === 'contactInformation'
                                                ? t[
                                                     'page_settings_typo_platforms_live_chat_get_started_form_contact_information_email_address'
                                                  ]
                                                : t[
                                                     'page_settings_typo_platforms_live_chat_get_started_contact_information_preview_contact_details_email_address'
                                                  ]}
                                          </Typography>
                                       </div>
                                    )}
                                    {contactInformationPhoneNumber && (
                                       <div className={classes.fakeInput}>
                                          <Typography noWrap variant='body2' className={classes.fakeInputText}>
                                             {type === 'contactInformation'
                                                ? t[
                                                     'page_settings_typo_platforms_live_chat_get_started_form_contact_information_phone_number'
                                                  ]
                                                : t[
                                                     'page_settings_typo_platforms_live_chat_get_started_contact_information_preview_contact_details_phone_number'
                                                  ]}
                                          </Typography>
                                       </div>
                                    )}
                                    {contactInformationAddress && (
                                       <div className={classes.fakeInput}>
                                          <Typography noWrap variant='body2' className={classes.fakeInputText}>
                                             {type === 'contactInformation'
                                                ? t[
                                                     'page_settings_typo_platforms_live_chat_get_started_form_contact_information_address'
                                                  ]
                                                : t[
                                                     'page_settings_typo_platforms_live_chat_get_started_contact_information_preview_contact_details_address'
                                                  ]}
                                          </Typography>
                                       </div>
                                    )}

                                    {contactInformationPermission && (
                                       <Grid container style={{ flexWrap: 'nowrap' }}>
                                          <Checkbox
                                             value='remember'
                                             defaultChecked
                                             defaultValue='checked'
                                             className={classes.checkBox}
                                             color='primary'
                                          />
                                          <Typography className={classes.infoMessage} style={{ fontSize: '13px' }}>
                                             {permissionTextHandler(contactInformationPermissionContent)}
                                             &nbsp;
                                             <a href={contactInformationPermissionLinkUrl} target='_blank'>
                                                {contactInformationPermissionLinkText}
                                             </a>
                                          </Typography>
                                       </Grid>
                                    )}
                                    {type === 'contactInformation' &&
                                       (contactInformationFullName ||
                                          contactInformationEmailAddress ||
                                          contactInformationPhoneNumber ||
                                          contactInformationAddress) && (
                                          <div className={classes.fakeButton}>
                                             <Typography variant='body2' className={classes.fakeButtonText}>
                                                {
                                                   t[
                                                      'page_settings_typo_platforms_live_chat_get_started_contact_information_preview_send'
                                                   ]
                                                }
                                             </Typography>
                                          </div>
                                       )}
                                 </div>
                              )}
                              {element.type === 'rate' && type === 'rateUs' && (
                                 <div className={classes.infoContainer}>
                                    {element.title && (
                                       <Typography variant='body1' className={classes.infoTitle}>
                                          {element.title}
                                       </Typography>
                                    )}
                                    {element.message && (
                                       <Typography variant='body2' className={classes.infoMessage}>
                                          {element.message}
                                       </Typography>
                                    )}
                                    <div className={classes.thumbs}>
                                       <div className={classes.thumbUpContainer}>
                                          <ThumbUp />
                                       </div>
                                       <div className={classes.thumbDownContainer}>
                                          <ThumbUp />
                                       </div>
                                    </div>
                                    <div className={classNames(classes.fakeInput, classes.fakeMultilineInput)}>
                                       <Typography noWrap variant='body2' className={classes.fakeInputText}>
                                          {
                                             t[
                                                'page_settings_typo_platforms_live_chat_get_started_rate_us_preview_write_a_review'
                                             ]
                                          }
                                       </Typography>
                                    </div>
                                    <div className={classes.fakeButton}>
                                       <Typography variant='body2' className={classes.fakeButtonText}>
                                          {t['page_settings_typo_platforms_live_chat_get_started_rate_us_preview_sen']}
                                       </Typography>
                                    </div>
                                 </div>
                              )}
                              {element.type === 'rate' && type === 'rateUsThanks' && (
                                 <div className={classNames(classes.infoContainer, classes.infoContainerRight)}>
                                    <div className={classes.rateUsThanksHeader}>
                                       <div className={classes.titleAndFeedbackStatus}>
                                          <Typography variant='body1' className={classes.infoTitle}>
                                             {
                                                t[
                                                   'page_settings_typo_platforms_live_chat_get_started_rate_us_your_feedback'
                                                ]
                                             }
                                          </Typography>
                                          <Typography
                                             variant='body2'
                                             className={classNames(classes.infoMessage, classes.positive)}
                                          >
                                             {t['page_settings_typo_platforms_live_chat_get_started_rate_us_positive']}
                                          </Typography>
                                       </div>
                                       <div className={classes.singleThumbContainer}>
                                          <ThumbUp />
                                       </div>
                                    </div>
                                    <div className={classNames(classes.fakeInput, classes.fakeMultilineInput)}>
                                       <Typography
                                          variant='body2'
                                          className={classNames(classes.fakeInputText, classes.feedbackText)}
                                       >
                                          {
                                             t[
                                                'page_settings_typo_platforms_live_chat_get_started_rate_us_positive_feedback_text'
                                             ]
                                          }
                                       </Typography>
                                    </div>
                                 </div>
                              )}
                           </Fragment>
                        ))}
                     </div>
                  </div>
               ))}
            {children}
         </div>
         <div className={classes.footerContainer}>
            <div className={classes.inputAndIcons}>
               <Typography className={classes.input}>
                  {t['page_settings_typo_platforms_live_chat_home_preview_input']}
               </Typography>
               <div className={classes.footerIcons}>
                  <div className={classes.footerIconContainer}>
                     <SentimentSatisfiedRoundedIcon className={classNames(classes.footerIcon, classes.colorGray)} />
                  </div>
                  <div className={classes.footerIconContainer}>
                     <AttachFileRoundedIcon className={classNames(classes.footerIcon, classes.colorGray)} />
                  </div>
                  <div className={classes.footerIconContainer}>
                     <SendRoundedIcon className={classNames(classes.footerIcon, classes.sendIcon)} />
                  </div>
               </div>
            </div>
            <Typography className={classes.poweredByQpien}>
               {'Powered by'}
               <span className={classes.qpien}>{' Qpien'}</span>
            </Typography>
         </div>
      </div>
   );
};

export default Chat;

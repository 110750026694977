export enum LIVE_CHAT_ICON {
   PRIMARY = 'PRIMARY',
   SECONDARY = 'SECONDARY',
   TERTIARY = 'TERTIARY',
}

export enum LIVE_CHAT_ICON_POSITION {
   RIGHT = 'RIGHT',
   LEFT = 'LEFT',
   TOP_LEFT = 'TOP_LEFT',
   TOP_RIGHT = 'TOP_RIGHT',
}

export enum VISITOR_CONTACT_INFO_VALUE {
   ALWAYS_ASK = 'ALWAYS_ASK',
   ASK_OUTSIDE_WORKING_HOURS = 'ASK_OUTSIDE_WORKING_HOURS',
}

export enum LIVE_CHAT_VISIBILITY {
   NOT_VISIBLE = 'NOT_VISIBLE',
   VISIBLE = 'VISIBLE',
   ONLY_INSIDE_WORKING_HOURS = 'ONLY_INSIDE_WORKING_HOURS',
   ONLY_OUTSIDE_WORKING_HOURS = 'ONLY_OUTSIDE_WORKING_HOURS',
}

export enum SELECTED_LIVE_CHAT_ICON {
   QPIEN_DEFAULT_ICON = 'QPIEN_DEFAULT_ICON',
   SMS_ICON = 'SMS_ICON',
   CHAT_ICON = 'CHAT_ICON',
   CHAT_BUBBLE_ICON = 'CHAT_BUBBLE_ICON',
   INSERT_COMMENT_ICON = 'INSERT_COMMENT_ICON',
   USER_UPLOADED_ICON = 'USER_UPLOADED_ICON',
}
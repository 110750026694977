import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import DisabledAccountModal from '@components/DisabledAccountModal';
import MaterialDialog from '@components/MaterialDialog';
import CommentDashboard from './Comments';
import { useSelector } from 'react-redux';
import DashboardMessages from './Messages';
import ShowTourModal from './showTourModal';
import WelcomeSection from './WelcomeSection';

const useStyles = makeStyles((theme) => ({
   content: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      flexGrow: 1,
      height: '100vh',
      overflowY: 'auto',
   },
   container: {
      // backgroundColor:'red',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
   },
   appBarSpacer: theme.mixins.toolbar,
}));

const Dashboard = () => {
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const classes = useStyles();
   const [showTourModal, setShowTourModal] = useState(null);
   const [tabValue, setTabValue] = useState('messages');
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   React.useEffect(() => {
      const isWelcome = window.localStorage.getItem('isWelcome');
      if (isWelcome) {
         setShowTourModal((value) => {
            localStorage.removeItem('isWelcome');
            return true;
         });
      } else {
         setShowTourModal(false);
      }
   }, []);

   useEffect(() => {
      const getshopifySession = localStorage.getItem('shopifySession');
      if (getshopifySession && person.customer.appStatus === 'Enabled') {
         setTimeout(() => (window.location.href = '/authentication/shopify?session=' + getshopifySession), 1500);
      }

      const getIdeasoftState = localStorage.getItem('ideasoftState');
      const getIdeasoftCode = localStorage.getItem('ideasoftCode');
      const getIdeasoftDomain = localStorage.getItem('ideasoftDomain');
      if (getIdeasoftState || getIdeasoftCode) {
         setTimeout(
            () =>
               (window.location.href = `/authentication/ideasoft?state=${getIdeasoftState}&code=${getIdeasoftCode}&domain=${getIdeasoftDomain}`),
            1500,
         );
      }
   }, []);

   const handleTabValue = useCallback((value) => {
      setTabValue(value);
   }, []);

   return (
      <Fragment>
         <DisabledAccountModal />
         <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth='xl' className={classes.container}>
               <WelcomeSection tabValue={tabValue} onChangeTab={handleTabValue} />

               {tabValue === 'messages' ? <DashboardMessages /> : <CommentDashboard />}
            </Container>
            <MaterialDialog
               open={showTourModal}
               disableCloseButton
               dialogTitle={t?.dashboard_welcome_to_qpien_dashboard}
            >
               <ShowTourModal onSkip={() => setShowTourModal(false)} />
            </MaterialDialog>
         </main>
      </Fragment>
   );
};

export default React.memo(Dashboard);

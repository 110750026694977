import React, { FC, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import { ORDER_FLAGS, ProductProps } from '../../Tabs/E-Commerce/Tabs/Store/types';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import { BrokenImage } from '@material-ui/icons';
import { currencyHandler } from '@helper/textUtils';

const useStyles = makeStyles(() => ({
   root: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 4,
      margin: 8,
      border: '1px solid #D9E0E6',
   },
   headerContainer: {
      display: 'flex',
      padding: 8,
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   statusIconAndTitle: {
      display: 'flex',
      alignItems: 'center',
   },
   // defaultMediaIcon: {
   //    color: '#999999',
   //    fontSize: 42,
   // },
   title: {
      color: '#385273',
      fontSize: '1.2em',
      lineHeight: 1.4,
   },
   productsCount: {
      color: '#385273',
      opacity: '0.6',
      fontSize: '1em',
      lineHeight: 1.3,
   },

   productCardContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 8,
      // width: '100%',
      borderTop: '1px solid #D9E0E6',
   },
   image: {
      width: 42,
      height: 42,
      borderRadius: 4,
      overflow: 'hidden',
   },
   infoContainer: {
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: 5,
   },
   nameContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
   },
   name: {
      color: '#385273',
      fontSize: '1.2em',
      lineHeight: 1.4,
   },
   countAndPrice: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   count: {
      color: '#385273',
      opacity: '0.6',
      fontSize: '1em',
      lineHeight: 1.3,
   },
   price: {
      color: '#385273',
      fontSize: '1em',
      lineHeight: 1.3,
   },
   icon: {
      fontSize: 36,
      marginRight: 8,
   },
   unfulfilledIcon: {
      color: '#DEE4EA',
   },
   fulfilledIcon: {
      color: '#FCDB81',
   },
   removedIcon: {
      color: '#FF8989',
   },
}));

interface ProductsWithStatusProps {
   products: any[];
   status: ORDER_FLAGS.UNFULFILLED | ORDER_FLAGS.FULFILLED | 'REMOVED';
   currency: string;
}

const ProductsWithStatus: FC<ProductsWithStatusProps> = ({ products, status, currency }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;

   const Icon = () => {
      if (status === ORDER_FLAGS.UNFULFILLED) {
         return <CheckCircleRoundedIcon className={classNames(classes.icon, classes.unfulfilledIcon)} />;
      } else if (status === ORDER_FLAGS.FULFILLED) {
         return <CheckCircleRoundedIcon className={classNames(classes.icon, classes.fulfilledIcon)} />;
      } else if (status === 'REMOVED') {
         return <RemoveCircleRoundedIcon className={classNames(classes.icon, classes.removedIcon)} />;
      }

      return null;
   };

   return (
      <div className={classes.root}>
         <div className={classes.headerContainer}>
            <div className={classes.statusIconAndTitle}>
               <Icon />
               <Typography variant='subtitle1' noWrap className={classes.title}>
                  {t[`page_e_commerce_store_status_option_${status.toLowerCase()}`]}
               </Typography>
            </div>
            <Typography variant='body2' noWrap className={classes.productsCount}>
               {`${products.length} ${t['page_e_commerce_store_typo_items']}`}
            </Typography>
         </div>
         {products.map((product, index) => {
            const discountedPrice = product.price - product.applied_discount || product.price;
            return (
               <div key={index} className={classes.productCardContainer}>
                  {/* {product.image ? (
                  <img src={product.image} alt='product image' className={classes.image} />
               ) : (
                  <div
                     className={classes.image}
                     style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                     }}
                  >
                     <BrokenImage className={classes.defaultMediaIcon} />
                  </div>
               )} */}
                  <div className={classes.infoContainer}>
                     <div className={classes.nameContainer}>
                        <Typography variant='body1' noWrap className={classes.name}>
                           {product.productName}
                        </Typography>
                     </div>
                     <div className={classes.countAndPrice}>
                        <Typography variant='body2' className={classes.count}>
                           {`${currencyHandler(currency)}${discountedPrice.toFixed(2)} x ${product.quantity}`}
                        </Typography>
                        <Typography variant='body2' className={classes.price}>
                           {`${t['page_e_commerce_store_typo_total']}: ${currencyHandler(currency)}${(
                              discountedPrice * product.quantity
                           ).toFixed(2)}`}
                        </Typography>
                     </div>
                  </div>
               </div>
            );
         })}
      </div>
   );
};

export default ProductsWithStatus;

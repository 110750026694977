import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

const IntegrationCard = (platform) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const history = useHistory();

   const useStyles = makeStyles(() => ({
      classContainer: {
         borderRadius: 5,
         border: platform.connected ? '1px solid #0060df' : '1px solid grey',
         minHeight: 200,
         background: platform.soon ? 'rgba(164, 164, 164, 0.1)' : 'none',
         userSelect: platform.soon ? 'none' : '',
         cursor: platform.soon ? 'default' : 'pointer',
         '&:hover': {
            boxShadow: platform.soon ? 'none' : '0px 0px 10px 0px rgba(0,0,0,0.2)',
            //background: 'rgba(1,1,1, 0.1)',
         },
      },
      soon: {
         color: '#0060df',
         float: 'right',
         marginTop: 5,
         marginRight: 5,
      },
      connected: {
         textAlign: 'center',
         borderRadius: 5,
         backgroundColor: '#0060df',
         color: 'white',
         width: 80,
         marginLeft: 'auto',
      },
      statusBar: {
         height: 10,
      },
   }));
   const classes = useStyles();

   return (
      <Grid
         item
         xs={6}
         md={4}
         key={platform.title}
         onClick={() => {
            //navigate to platform.url
            if (!platform.soon) {
               history.push(platform.url);
            }
         }}
      >
         <div className={classes.classContainer}>
            <div className={classes.statusBar}>
               {platform.soon && <div className={classes.soon}>{t?.card_soon}</div>}
               {platform.connected && <div className={classes.connected}>{t?.card_connected}</div>}
            </div>

            <div style={{ padding: 15 }}>
               {/* icon and title */}
               <Grid container spacing={0} justifyContent='flex-start' alignItems='flex-start'>
                  <Grid item xs={2} style={{maxWidth: 50}}>
                     {platform.icon}
                  </Grid>
                  <Grid item xs style={{ paddingLeft: 5 }}>
                     <span className='MuiTypography-root MuiTypography-body1 MuiTypography-colorTextPrimary'>
                        {platform.title}
                     </span>
                     <br />
                     <span className='MuiTypography-root MuiTypography-body2 MuiTypography-colorTextSecondary'>
                        {t[`sub_category_type_${platform.type}`]}
                     </span>
                  </Grid>
               </Grid>
               {/* Detail */}
               <Grid container direction='column' style={{ marginTop: 10, marginBottom: 20 }}>
                  <Typography variant={'body2'} color='textSecondary'>
                     {platform.description}
                  </Typography>
               </Grid>
            </div>
         </div>
      </Grid>
   );
};

export default IntegrationCard;

import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { Paper } from '@material-ui/core';
import ButtonFunction from '@components/ButtonFunction';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 500,
      padding: 20,
      paddingTop: 5,
      paddingBottom: 5,
      borderRadius: 15,
      // width: 700,
      // height:400,
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
         width: 350,
      },
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   message: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   title: {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginBottom: 30,
   },
}));

const PackageOPModal = ({ successful, onAccept }) => {
   const classes = useStyles();
   return (
      <Grid container item xs={12}>
         <Paper className={classes.root} elevation={3}>
            <Grid container direction='column' style={{ overflow: 'auto' }}>
               <Grid container justifyContent='center'>
                  {successful ? (
                     <CheckCircleIcon style={{ fontSize: '5rem', color: 'green', margin: 30 }} />
                  ) : (
                     <HighlightOffIcon style={{ fontSize: '5rem', color: 'red', margin: 30 }} />
                  )}
               </Grid>
               <Grid container justifyContent='center'>
                  <Typography className={classes.title}>
                     {successful ? 'İptal Edilemez' : 'İptal İşlemi Başarılı'}
                  </Typography>
               </Grid>
               <Grid container justifyContent='center'>
                  <Typography className={classes.message}>
                     {successful
                        ? `Ek kullanıcı paketini iptal etmeden önce sistemdeki fazla kullanıcıları silmeniz gerekmektedir.
                Kullanıcı silme işleminden sonra tekrar deneyiniz.`
                        : `Ek kullanıcı paketiniz iptal edilmiştir. Paket bitiş tarihinden sonra artık yenilenmeyecektir.`}
                  </Typography>
               </Grid>
               <Grid container justifyContent='center' style={{ padding: 20 }}>
                  <Grid item style={{ paddingLeft: 5 }}>
                     <ButtonFunction onClick={onAccept} label='Kapat' />
                  </Grid>
               </Grid>
            </Grid>
         </Paper>
      </Grid>
   );
};

export default PackageOPModal;

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import SideBarItems from './sideBarItems';
const drawerHeight = 60;

const useStyles = makeStyles((theme) => ({
   drawerPaper: {
      scrollbarWidth: 'none',
      position: 'fixed',
      border: 'none',
      whiteSpace: 'nowrap',
      // height:'100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: drawerHeight,
      backgroundColor: theme.chatApp.workspace.sideBar.backgroundColor,
      overflowY: 'hidden',
      transition: theme.transitions.create('width', {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   },
   icon: {
      // marginLeft: '5px',
      color: theme.chatApp.workspace.sideBar.iconColor,
   },
}));

const HorizontalSideBar = () => {
   const classes = useStyles();
   return (
      <Drawer variant='persistent' classes={{ paper: clsx(classes.drawerPaper) }} open={true} anchor={'bottom'}>
         <SideBarItems mode='HORIZONTAL' />
      </Drawer>
   );
};

export default React.memo(HorizontalSideBar);

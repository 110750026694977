import Input from '@components/InputField';
import { LangCodes } from '@helper/language';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { IForm } from '../types';

const useStyles = makeStyles((theme) => ({
   content: {
      width: '100%',
      '&.MuiOutlinedInput-root': {
         height: 100,
      },
   },
   inputTitles: {
      marginBottom: -12,
      fontSize: 18,
      paddingLeft: 3,
   },
}));

const FirstForm = ({ isView, watch, isSmallScreen, handleMedia, setValue, control, register }: IForm) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <Grid className={classes.content}>
         <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
               <Typography className={classes.inputTitles}>
                  {t['settings_whatsapp_business_template_form_name']}
               </Typography>
               <Input
                  {...register('name', {
                     required: true,
                     maxLength: 30,
                  })}
                  control={control}
                  disabled={isView}
                  fullWidth
                  placeholder={t['settings_whatsapp_business_template_form_name']}
                  variant='outlined'
               />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
               <Typography className={classes.inputTitles}>
                  {t['settings_whatsapp_business_template_form_language']}
               </Typography>
               <Input
                  rules={{
                     required: true,
                  }}
                  name='languageCode'
                  control={control}
                  disabled={isView}
                  selectDefaultValue={watch('languageCode')}
                  isSelect
                  onChange={(data: any) => {
                     setValue('languageCode', data.value);
                  }}
                  placeholder={t['settings_whatsapp_business_template_form_select']}
                  selectOptions={LangCodes}
               />
            </Grid>
         </Grid>
         <Grid container spacing={2} style={{ marginTop: { isSmallScreen } && 10 }}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
               <Typography className={classes.inputTitles}>
                  {t['settings_whatsapp_business_template_form_category']}
               </Typography>
               <Input
                  rules={{
                     required: true,
                  }}
                  disabled={isView}
                  onChange={(data: any) => {
                     setValue('category', data.value);
                  }}
                  selectDefaultValue={watch('category')}
                  control={control}
                  name='category'
                  isSelect
                  placeholder={t['settings_whatsapp_business_template_form_select']}
                  selectOptions={[
                     {
                        value: 'AUTHENTICATION',
                        label: t['settings_whatsapp_business_template_category_authentication'],
                     },
                     { value: 'MARKETING', label: t['settings_whatsapp_business_template_category_marketing'] },
                     { value: 'UTILITY', label: t['settings_whatsapp_business_template_category_utility'] },
                  ]}
               />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
               <Typography className={classes.inputTitles}>
                  {t['settings_whatsapp_business_template_form_template_type']}
               </Typography>
               <Input
                  rules={{
                     required: true,
                  }}
                  disabled={isView}
                  onChange={(data: any) => {
                     handleMedia(data);
                     setValue('templateType', data.value);
                  }}
                  selectDefaultValue={watch('templateType')}
                  control={control}
                  name='templateType'
                  isSelect
                  placeholder={t['settings_whatsapp_business_template_form_select']}
                  selectOptions={[
                     { value: 'TEXT', label: t['settings_whatsapp_business_template_form_text'] },
                     { value: 'IMAGE', label: t['settings_whatsapp_business_template_form_image'] },
                     { value: 'VIDEO', label: t['settings_whatsapp_business_template_form_video'] },
                     { value: 'DOCUMENT', label: t['settings_whatsapp_business_template_form_file'] },
                  ]}
               />
            </Grid>
         </Grid>
      </Grid>
   );
};

export default FirstForm;

import React, { FC, useContext, useState, Fragment } from 'react';
import SubjectRoundedIcon from '@material-ui/icons/SubjectRounded';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../../../../../../helper/locale/langContext';
import { CustomerProps, OrderProps } from './types';
import Customer from '../Customer';
import OrderDetail from './OrderDetail';
import AllCustomers from './AllCustomers';
import AllOrders from './AllOrders';
import ECommerceTabHeaderContext from '../../../../../../../contexts/ECommerceTabHeaderContext';
import MenuItem from '../../../../ChatItem/MenuItem';
import { useSelector } from 'react-redux';
import CannotAccessModal from '@modules/Chat/RightPanel/ChatItem/CannotAccessModal';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
   root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
   },
   detailView: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 4,
   },

   headerIcon: {
      fontSize: 32,
      color: '#fff',
   },
}));

interface StoreProps { }

type ViewNameType = 'all_orders' | 'order' | 'all_customers' | 'customer' | null;

const Store: FC<StoreProps> = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const headerContext = useContext(ECommerceTabHeaderContext);
   const [selectedCustomer, setSelectedCustomer] = useState<CustomerProps | null>(null);
   const [selectedOrder, setSelectedOrder] = useState<OrderProps | null>(null);
   const [isVisibleViewName, setVisibleViewName] = useState<ViewNameType>(null);
   const history = useHistory();
   const eCommerceTotalNumberOfOrders = useSelector(
      (rootReducer: any) => rootReducer.eCommerceReducer.eCommerceTotalNumberOfOrders
   );
   const eCommerceTotalNumberOfCustomers = useSelector(
      (rootReducer: any) => rootReducer.eCommerceReducer.eCommerceTotalNumberOfCustomers
   );
   const eCommerceType = useSelector((rootReducer: any) => rootReducer?.eCommerceReducer?.eCommerceType);

   const openView = (viewName: ViewNameType, title: string) => {
      setVisibleViewName(viewName);
      if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
         headerContext.setTitle(title);
         headerContext.setOnGoBackClick(() => () => closeView());
      }
   };

   const closeView = () => {
      setVisibleViewName(null);
      if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
         headerContext.setTitle('');
         headerContext.setOnGoBackClick(() => null);
      }
   };

   const openCustomerView = (customer: CustomerProps) => {
      setSelectedCustomer(customer);
      setVisibleViewName('customer');
      if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
         headerContext.setTitle(t['page_e_commerce_store_typo_customer_title']);
         headerContext.setOnGoBackClick(() => () => closeCustomerDetail());
      }
   };

   const onCloseCustomerDetail = () => {
      setVisibleViewName('customer');
      if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
         headerContext.setTitle(t['page_e_commerce_store_typo_customer_title']);
         headerContext.setOnGoBackClick(() => () => closeCustomerDetail());
      }
   };

   const closeCustomerDetail = () => {
      setVisibleViewName('all_customers');
      if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
         headerContext.setTitle(t['page_e_commerce_store_typo_all_customer']);
         headerContext.setOnGoBackClick(() => () => closeView());
      }
   };

   const openOrderView = React.useCallback(
      (order: OrderProps) => {
         setSelectedOrder(order);
         setVisibleViewName('order');
         if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
            headerContext.setTitle(t['page_e_commerce_store_typo_order_detail_title']);
            headerContext.setOnGoBackClick(() => () => closeOrderView());
         }
      },
      [headerContext]
   );

   const closeOrderView = () => {
      setVisibleViewName('all_orders');
      if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
         headerContext.setTitle(t['page_e_commerce_store_typo_all_order']);
         headerContext.setOnGoBackClick(() => () => closeView());
      }
   };

   return (
      <div className={classes.root}>
         {isVisibleViewName && (
            <div className={classes.detailView}>
               {isVisibleViewName === 'all_orders' && <AllOrders openOrderDetail={openOrderView} />}
               {isVisibleViewName === 'order' && selectedOrder && <OrderDetail order={selectedOrder} />}
               {isVisibleViewName === 'all_customers' && <AllCustomers openCustomerDetail={openCustomerView} />}
               {isVisibleViewName === 'customer' && selectedCustomer && (
                  <Customer
                     customer={selectedCustomer}
                     containerStyle={{ marginTop: -8 }}
                     onCloseDetailView={onCloseCustomerDetail}
                  />
               )}
            </div>
         )}
         {!isVisibleViewName && (
            <Fragment>
               {/* All Orders */}
               <MenuItem
                  icon={<SubjectRoundedIcon className={classes.headerIcon} />}
                  title={t['page_e_commerce_store_typo_all_order']}
                  subtitle={eCommerceTotalNumberOfOrders < 0 ? "" : `${eCommerceTotalNumberOfOrders || 0} ${t['page_e_commerce_store_typo_order']}`}
                  onClick={() => openView('all_orders', t['page_e_commerce_store_typo_all_order'])}
                  containerStyle={{ marginTop: 8 }}
               />
               {/* All Customers */}
               <MenuItem
                  icon={<SubjectRoundedIcon className={classes.headerIcon} />}
                  title={t['page_e_commerce_store_typo_all_customer']}
                  subtitle={eCommerceTotalNumberOfCustomers < 0 ? "" : `${eCommerceTotalNumberOfCustomers || 0} ${t['page_e_commerce_store_typo_customer']}`}
                  onClick={() => openView('all_customers', t['page_e_commerce_store_typo_all_customer'])}
                  containerStyle={{ marginBottom: 8 }}
               />
            </Fragment>
         )}
      </div>
   );
};

export default Store;

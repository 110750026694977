import _ from 'underscore';
import moment from 'moment';
import { QnAType } from '../../modules/Questions/util/types';
import {
   CONFIRM_ANSWER,
   FORWARDED_QUESTION,
   JOINED_QUESTION,
   LEAVED_QUESTION,
   NEW_QNA_PERSON_TIMEOUT,
   NEW_QNA_TIMEOUT,
   QUESTION_TO_ME,
   QUESTION_TO_ME_UPDATED,
   QUESTION_UPDATE_LOG,
   SEND_ANSWER,
   SET_ACTIVE_QUESTION,
   SET_ACTIVE_QUESTION_MESSAGES,
   SET_MY_CUSTOMER_QUESTIONS,
   SET_MY_QUESTIONS,
   SET_QUESTION_CLICKED_FORM_STATUS,
   SET_QUESTION_CLICKED_ITEM,
   SET_QUESTION_CLICKED_REPLY_TYPE,
   SET_QUESTION_CLICKED_STATUS,
   UPDATED_QNA_TAG,
   UPDATE_QNA_PERSON,
   SET_QNA_CHAT_FILTERS,
   RESET_QNA_CHAT_FILTERS,
} from '../actions/questions';
import { QnaChatStatus } from '../../types/enums';

const INITIAL_STATE = {
   qnaChatFilters: {
      me: '',
      teams: null,
      page: 0,
      text: '',
      platformType: 'ALL',
      tag: 'ALL',
      status: QnaChatStatus.WAITING,
      startDate: moment().subtract(1, 'months').valueOf(),
      endDate: moment().valueOf(),
      desc: 'desc',
      updatedAt: moment().valueOf(),
   },
   myQuestionList: {
      questions: [],
      page: 0,
      hasNextPage: null,
      totalDocs: null,
      limit: null,
   },
   myCustomerQuestionList: {
      questions: [],
      page: 0,
      hasNextPage: null,
      totalDocs: null,
      limit: null,
   },
   activeQuestion: null,
   activeQuestionMessages: {
      messages: [],
      totalDocs: null,
      page: 1,
      limit: null,
   },
   contentState: {
      clickedItem: null,
      replyType: null,
      operation: null,
      isValid: false,
   },
};

const isTheChatFitTheFilter = (qnaChat, filter, statusControl = true, ownerControl = false, teamControl = true) => {
   return (
      (!ownerControl || (qnaChat?.joinedPerson?._id && filter.me?.includes(qnaChat?.joinedPerson?._id))) &&
      (!teamControl ||
         !qnaChat.teams ||
         (!!qnaChat.teams &&
            Array.isArray(qnaChat.teams) &&
            qnaChat.teams?.length > 0 &&
            (filter.teams?.value === 'ALL' || !!qnaChat.teams?.find((team) => team?._id === filter.teams?.value)))) &&
      (!statusControl || filter.status === 'ALL' || qnaChat?.status === filter.status) &&
      (filter.tag === 'ALL' || qnaChat?.tag?._id === filter.tag) &&
      (filter.platformType === 'ALL' || qnaChat?.platformType === filter.platformType) &&
      qnaChat?.lastQnA?.createdAt &&
      moment(filter.startDate).isSameOrBefore(qnaChat.lastQnA?.createdAt, 'days') &&
      moment(filter.endDate).isSameOrAfter(qnaChat.lastQnA?.createdAt, 'days')
   );
};

function sortByLastQnA(item) {
   return item.lastQnA.updatedAt;
}

const handleQuestionToMe = (state, { messagesPool, me }) => {
   // handle new question here
   let {
      myCustomerQuestionList: { questions: myCustomerQuestions },
      myQuestionList: { questions: myQuestions },
      activeQuestion,
      activeQuestionMessages: { messages: activeQuestionMessages },
   } = state;

   for (const message of messagesPool) {
      // check new qna is belong to this customer
      if (message.QnAChat.customer._id.toString() === me.customer._id.toString()) {
         if (message.QnAChat.joinedPerson) {
            if (message.QnAChat.joinedPerson._id.toString() === me._id.toString()) {
               // this is my qna
               const index = myQuestions.findIndex((item) => item._id.toString() === message.QnAChat._id.toString());

               if (index > -1) {
                  // my active questions
                  if (activeQuestion && activeQuestion._id.toString() === message.QnAChat._id.toString()) {
                     activeQuestionMessages = [...activeQuestionMessages, message];
                  }
                  myQuestions = myQuestions.map((item) =>
                     item._id.toString() === message.QnAChat._id.toString()
                        ? {
                             ...item,
                             lastQnA: message,
                          }
                        : item,
                  );

                  myQuestions = _.sortBy(myQuestions, sortByLastQnA).reverse();
               } else {
                  const newQna = {
                     ...message.QnAChat,
                  };

                  myQuestions = [newQna, ...myQuestions];
               }
            }
         } else {
            const index = myCustomerQuestions.findIndex(
               (item) => item._id.toString() === message.QnAChat._id.toString(),
            );

            // no qna joinedPerson
            if (index > -1) {
               if (activeQuestion && activeQuestion._id.toString() === message.QnAChat._id.toString()) {
                  activeQuestionMessages = [message, ...activeQuestionMessages];
               }
               myCustomerQuestions = myCustomerQuestions.map((item) =>
                  item._id.toString() === message.QnAChat._id.toString()
                     ? {
                          ...item,
                          lastQnA: message,
                       }
                     : item,
               );

               myCustomerQuestions = _.sortBy(myCustomerQuestions, sortByLastQnA).reverse();
            } else {
               const newQna = {
                  ...message.QnAChat,
               };

               myCustomerQuestions = [newQna, ...myCustomerQuestions];
            }
         }
      }
   }

   return {
      ...state,
      myCustomerQuestionList: {
         ...state.myCustomerQuestionList,
         questions: myCustomerQuestions,
         totalDocs: myCustomerQuestions.length,
      },
      myQuestionList: {
         ...state.myQuestionList,
         questions: myQuestions,
         totalDocs: myQuestions.length,
      },
      activeQuestionMessages: {
         ...state.activeQuestionMessages,
         messages: activeQuestionMessages,
      },
   };
};
const handleQuestionToMeUpdated = (state, { messagesPool, me, updatedMessage }) => {
   // handle updated question here
   let {
      myCustomerQuestionList: { questions: myCustomerQuestions },
      myQuestionList: { questions: myQuestions },
      activeQuestion: { QnAs: activeQuestionQnAs },
      activeQuestionMessages: { messages: activeQuestionMessages },
   } = state;

   // TODO : TEST EDİLECEK, redux'u update edicek
   activeQuestionMessages = activeQuestionMessages.map((e) =>
      e._id.toString() == updatedMessage._id.toString() ? updatedMessage : e,
   );
   myCustomerQuestions = myCustomerQuestions.map((e) =>
      e._id.toString() == updatedMessage._id.toString() ? updatedMessage : e,
   );
   activeQuestionQnAs = activeQuestionQnAs?.map((e) =>
      e._id.toString() == updatedMessage._id.toString() ? updatedMessage : e,
   );

   // for (const message of messagesPool) {
   //    // check new qna is belong to this customer
   //    if (message.QnAChat.customer._id.toString() === me.customer._id.toString()) {
   //       if (message.QnAChat.joinedPerson) {
   //          if (message.QnAChat.joinedPerson._id.toString() === me._id.toString()) {
   //             // this is my qna
   //             const index = myQuestions.findIndex((item) => item._id.toString() === message.QnAChat._id.toString());
   //             if (index > -1) {
   //                // my active questions
   //                if (activeQuestion && activeQuestion._id.toString() === message.QnAChat._id.toString()) {
   //                   activeQuestionMessages = activeQuestionMessages.map((e) =>
   //                      e._id.toString() == updatedMessage._id.toString() ? updatedMessage : e
   //                   );
   //                }
   //             }
   //          }
   //       } else {
   //          const index = myCustomerQuestions.findIndex(
   //             (item) => item._id.toString() === message.QnAChat._id.toString()
   //          );

   //          // no qna joinedPerson
   //          if (index > -1) {
   //             if (activeQuestion && activeQuestion._id.toString() === message.QnAChat._id.toString()) {
   //                activeQuestionMessages = activeQuestionMessages.map((e) =>
   //                   e._id.toString() == updatedMessage._id.toString() ? updatedMessage : e
   //                );
   //             }
   //          }
   //       }
   //    }
   // }

   return {
      ...state,
      myCustomerQuestionList: {
         ...state.myCustomerQuestionList,
         questions: myCustomerQuestions,
         totalDocs: myCustomerQuestions.length,
      },
      myQuestionList: {
         ...state.myQuestionList,
         questions: myQuestions,
         totalDocs: myQuestions.length,
      },
      activeQuestionMessages: {
         ...state.activeQuestionMessages,
         messages: activeQuestionMessages,
      },
   };
};

const handleUpdateQnAPerson = (state, action) => {
   const data = action.payload;
   const {
      myQuestionList: { questions },
      myCustomerQuestionList: { questions: customerQuestions },
      activeQuestion,
   } = state;

   // this is my question
   if (activeQuestion.joinedPerson) {
      const newList = questions.map((question) =>
         question.ownerPerson._id.toString() === data._id.toString()
            ? { ...question, ownerPerson: { ...data } }
            : question,
      );

      return {
         ...state,
         activeQuestion: {
            ...state.activeQuestion,
            ownerPerson: {
               ...data,
            },
         },
         myQuestionList: {
            ...state.myQuestionList,
            questions: newList,
         },
      };
   } else {
      const newList = customerQuestions.map((question) =>
         question.ownerPerson._id.toString() === data._id.toString()
            ? { ...question, ownerPerson: { ...data } }
            : question,
      );
      return {
         ...state,
         activeQuestion: {
            ...state.activeQuestion,
            ownerPerson: {
               ...data,
            },
         },
         myCustomerQuestionList: {
            ...state.myCustomerQuestionList,
            questions: newList,
         },
      };
   }
};

const handleSendAnswer = (state, message) => {
   let activeQuestionMessages = [...state.activeQuestionMessages.messages];
   if (message.type === QnAType.QNA) {
      return {
         ...state,
         activeQuestionMessages: {
            ...state.activeQuestionMessages,
            messages: state.activeQuestionMessages.messages.map((item) =>
               item._id.toString() === message._id.toString() ? { ...item, ...message } : item,
            ),
         },
      };
   } else {
      return {
         ...state,
         activeQuestionMessages: {
            ...state.activeQuestionMessages,
            messages: [message, ...activeQuestionMessages],
            totalDocs: state.activeQuestionMessages.totalDocs + 1,
         },
      };
   }
};

const handleConfirmQnA = (state, message) => {
   // let activeQuestionMessages = [...state.activeQuestionMessages.messages];
   if (message.type !== QnAType.LOG) {
      return {
         ...state,
         activeQuestionMessages: {
            ...state.activeQuestionMessages,
            messages: state.activeQuestionMessages.messages.map((item) =>
               item._id.toString() === message._id.toString() ? { ...item, ...message, isSending: false } : item,
            ),
         },
      };
   }

   return state;
};

const handleJoinedQuestion = (state, { qnaChat }) => {
   let {
      qnaChatFilters,
      activeQuestion,
      myCustomerQuestionList: { questions: myCustomerQuestions },
      myQuestionList: { questions: myQuestions },
   } = state;

   if (activeQuestion && activeQuestion?._id === qnaChat._id) {
      activeQuestion = {
         ...activeQuestion,
         ...qnaChat,
      };
   }

   myCustomerQuestions = myCustomerQuestions.filter((item) => item._id !== qnaChat?._id);
   myQuestions = myQuestions.filter((item) => item._id !== qnaChat?._id);

   if (isTheChatFitTheFilter(qnaChat, qnaChatFilters, false, true, false)) {
      myQuestions = [qnaChat, ...myQuestions];
      myQuestions = _.sortBy(myQuestions, sortByLastQnA);
      if (qnaChatFilters?.desc === 'desc') {
         myQuestions = myQuestions.reverse();
      }
   }

   return {
      ...state,
      activeQuestion: activeQuestion,
      myQuestionList: {
         ...state.myQuestionList,
         questions: myQuestions,
         totalDocs: myQuestions.length,
      },
      myCustomerQuestionList: {
         ...state.myCustomerQuestionList,
         questions: myCustomerQuestions,
         totalDocs: myCustomerQuestions.length,
      },
   };
};

const handleLeaveQuestion = (state, { qnaChat }) => {
   let {
      qnaChatFilters,
      activeQuestion,
      myCustomerQuestionList: { questions: myCustomerQuestionList },
      myQuestionList: { questions: myQuestionList },
   } = state;

   if (activeQuestion && activeQuestion?._id === qnaChat._id) {
      activeQuestion = {
         ...activeQuestion,
         ...qnaChat,
      };
   }

   myCustomerQuestionList = myCustomerQuestionList.filter((item) => item._id !== qnaChat?._id);
   myQuestionList = myQuestionList.filter((item) => item._id !== qnaChat?._id);

   if (isTheChatFitTheFilter(qnaChat, qnaChatFilters, true, false, true)) {
      myCustomerQuestionList = [qnaChat, ...myCustomerQuestionList];
      myCustomerQuestionList = _.sortBy(myCustomerQuestionList, sortByLastQnA);
      if (qnaChatFilters?.desc === 'desc') {
         myCustomerQuestionList = myCustomerQuestionList.reverse();
      }
   }

   return {
      ...state,
      myQuestionList: {
         ...state.myQuestionList,
         questions: myQuestionList,
         totalDocs: myQuestionList.length,
      },
      myCustomerQuestionList: {
         ...state.myCustomerQuestionList,
         questions: myCustomerQuestionList,
         totalDocs: myCustomerQuestionList.length,
      },
      activeQuestion,
   };
};

const handleForwardedQuestion = (state, { qnaChat }) => {
   let {
      qnaChatFilters,
      activeQuestion,
      myQuestionList: { questions: myQuestions },
      myCustomerQuestionList: { questions: myCustomerQuestionList },
   } = state;

   if (activeQuestion && activeQuestion?._id === qnaChat._id) {
      activeQuestion = {
         ...activeQuestion,
         ...qnaChat,
      };
   }

   myCustomerQuestionList = myCustomerQuestionList.filter((item) => item._id !== qnaChat?._id);
   myQuestions = myQuestions.filter((item) => item._id !== qnaChat?._id);

   if (isTheChatFitTheFilter(qnaChat, qnaChatFilters, false, true, false)) {
      myQuestions = [qnaChat, ...myQuestions];
      myQuestions = _.sortBy(myQuestions, sortByLastQnA);
      if (qnaChatFilters?.desc === 'desc') {
         myQuestions = myQuestions.reverse();
      }
   }

   return {
      ...state,
      myQuestionList: { ...state.myQuestionList, questions: myQuestions, totalDocs: myQuestions.length },
      myCustomerQuestionList: {
         ...state.myCustomerQuestionList,
         questions: myCustomerQuestionList,
         totalDocs: myCustomerQuestionList.length,
      },
      activeQuestion: activeQuestion,
   };
};

const handleQuestionLogMessage = (state, { qna }) => {
   const {
      activeQuestionMessages: { messages: activeMessages, totalDocs },
      activeQuestion,
   } = state;

   if (qna.type === QnAType.LOG && activeQuestion && activeQuestion._id === qna.QnAChat._id) {
      return {
         ...state,
         activeQuestionMessages: {
            ...state.activeQuestionMessages,
            messages: [qna, ...activeMessages],
            totalDocs: totalDocs + 1,
         },
      };
   } else {
      return state;
   }
};

const handleUpdateQnATag = (state, { qnaChat }) => {
   let {
      qnaChatFilters,
      activeQuestion,
      myQuestionList: { questions: myQuestions },
      myCustomerQuestionList: { questions: myCustomerQuestionList },
   } = state;

   if (activeQuestion && activeQuestion?._id === qnaChat._id) {
      activeQuestion = {
         ...activeQuestion,
         ...qnaChat,
      };
   }

   myCustomerQuestionList = myCustomerQuestionList.filter((item) => item._id !== qnaChat?._id);
   myQuestions = myQuestions.filter((item) => item._id !== qnaChat?._id);

   if (isTheChatFitTheFilter(qnaChat, qnaChatFilters, false, true, false)) {
      myQuestions = [qnaChat, ...myQuestions];
      myQuestions = _.sortBy(myQuestions, sortByLastQnA);
      if (qnaChatFilters?.desc === 'desc') {
         myQuestions = myQuestions.reverse();
      }
   }
   if (isTheChatFitTheFilter(qnaChat, qnaChatFilters, true, false, true)) {
      myCustomerQuestionList = [qnaChat, ...myCustomerQuestionList];
      myCustomerQuestionList = _.sortBy(myCustomerQuestionList, sortByLastQnA);
      if (qnaChatFilters?.desc === 'desc') {
         myCustomerQuestionList = myCustomerQuestionList.reverse();
      }
   }

   return {
      ...state,
      myQuestionList: { ...state.myQuestionList, questions: myQuestions, totalDocs: myQuestions.length },
      myCustomerQuestionList: {
         ...state.myCustomerQuestionList,
         questions: myCustomerQuestionList,
         totalDocs: myCustomerQuestionList.length,
      },
      activeQuestion: activeQuestion,
   };
};

const handleNewQnaPersonTimeout = (state, { qnaChat }) => {
   let {
      qnaChatFilters,
      activeQuestion,
      myQuestionList: { questions: myQuestions },
      myCustomerQuestionList: { questions: myCustomerQuestionList },
   } = state;

   if (activeQuestion && activeQuestion?._id === qnaChat._id) {
      activeQuestion = {
         ...activeQuestion,
         ...qnaChat,
      };
   }

   myCustomerQuestionList = myCustomerQuestionList.filter((item) => item._id !== qnaChat?._id);
   myQuestions = myQuestions.filter((item) => item._id !== qnaChat?._id);

   if (isTheChatFitTheFilter(qnaChat, qnaChatFilters, false, true, false)) {
      myQuestions = [qnaChat, ...myQuestions];
      myQuestions = _.sortBy(myQuestions, sortByLastQnA);
      if (qnaChatFilters?.desc === 'desc') {
         myQuestions = myQuestions.reverse();
      }
   }
   if (isTheChatFitTheFilter(qnaChat, qnaChatFilters, true, false, true)) {
      myCustomerQuestionList = [qnaChat, ...myCustomerQuestionList];
      myCustomerQuestionList = _.sortBy(myCustomerQuestionList, sortByLastQnA);
      if (qnaChatFilters?.desc === 'desc') {
         myCustomerQuestionList = myCustomerQuestionList.reverse();
      }
   }

   return {
      ...state,
      myQuestionList: { ...state.myQuestionList, questions: myQuestions, totalDocs: myQuestions.length },
      myCustomerQuestionList: {
         ...state.myCustomerQuestionList,
         questions: myCustomerQuestionList,
         totalDocs: myCustomerQuestionList.length,
      },
      activeQuestion: activeQuestion,
   };
};

const handleNewQnaTimeout = (state, { qna }) => {
   let {
      activeQuestionMessages: { messages: activeQuestionMessages },
      activeQuestion,
   } = state;

   if (activeQuestion && qna.QnAChat._id.toString() === activeQuestion._id.toString()) {
      activeQuestionMessages = activeQuestionMessages.map((item) =>
         item._id.toString() === qna._id.toString() ? { ...item, ...qna } : item,
      );
   }

   return {
      ...state,
      activeQuestionMessages: {
         ...state.activeQuestionMessages,
         messages: activeQuestionMessages,
      },
   };
};

const reducer = (state = INITIAL_STATE, action = {}) => {
   switch (action.type) {
      case SET_QNA_CHAT_FILTERS:
         return {
            ...state,
            qnaChatFilters: { ...state.qnaChatFilters, ...action.payload, updatedAt: moment().valueOf() },
         };
      case RESET_QNA_CHAT_FILTERS:
         return { ...state, qnaChatFilters: INITIAL_STATE.qnaChatFilters };
      case SET_MY_QUESTIONS:
         return {
            ...state,
            myQuestionList: {
               ...state.myQuestionList,
               ...action.payload,
            },
         };

      case SET_MY_CUSTOMER_QUESTIONS:
         return {
            ...state,
            myCustomerQuestionList: {
               ...state.myCustomerQuestionList,
               ...action.payload,
            },
         };
      case SET_ACTIVE_QUESTION:
         return {
            ...state,
            activeQuestion: action.payload,
         };

      case SET_ACTIVE_QUESTION_MESSAGES:
         return {
            ...state,
            activeQuestionMessages: { ...state.activeQuestionMessages, messages: action.payload },
         };
      case QUESTION_TO_ME:
         return handleQuestionToMe(state, action.payload);
      case QUESTION_TO_ME_UPDATED:
         return handleQuestionToMeUpdated(state, action.payload);

      case UPDATE_QNA_PERSON:
         return handleUpdateQnAPerson(state, action);

      case SEND_ANSWER:
         return handleSendAnswer(state, action.payload);

      case SET_QUESTION_CLICKED_ITEM:
         return {
            ...state,
            contentState: {
               ...state.contentState,
               clickedItem: action.payload,
               // isAnswerable: isAnswerable.answer,
            },
         };

      case JOINED_QUESTION:
         return handleJoinedQuestion(state, action.payload);

      case LEAVED_QUESTION:
         return handleLeaveQuestion(state, action.payload);

      case FORWARDED_QUESTION:
         return handleForwardedQuestion(state, action.payload);

      case QUESTION_UPDATE_LOG:
         return handleQuestionLogMessage(state, action.payload);

      case CONFIRM_ANSWER:
         return handleConfirmQnA(state, action.payload);

      case UPDATED_QNA_TAG:
         return handleUpdateQnATag(state, action.payload);

      case NEW_QNA_TIMEOUT:
         return handleNewQnaTimeout(state, action.payload);

      case NEW_QNA_PERSON_TIMEOUT:
         return handleNewQnaPersonTimeout(state, action.payload);

      case SET_QUESTION_CLICKED_REPLY_TYPE:
         return {
            ...state,
            contentState: {
               ...state.contentState,
               replyType: action.payload,
            },
         };

      case SET_QUESTION_CLICKED_STATUS:
         return {
            ...state,
            contentState: {
               ...state.contentState,
               operation: action.payload,
            },
         };

      case SET_QUESTION_CLICKED_FORM_STATUS:
         return {
            ...state,
            contentState: {
               ...state.contentState,
               isValid: action.payload,
            },
         };

      default:
         return state;
   }
};

export default reducer;

export const getQnaChatFilters = (state) => state.questionsState.qnaChatFilters;
export const getActiveQuestion = (state) => state.questionsState.activeQuestion;
export const getActiveQuestionMessages = (state) => state.questionsState.activeQuestionMessages;
export const getMyCustomerQuestionsState = (state) => state.questionsState.myCustomerQuestionList;
export const getMyQuestionsState = (state) => state.questionsState.myQuestionList;
export const getContentState = (state) => state.questionsState.contentState;
export const getContentClickedItem = (state) => state.questionsState.contentState.clickedItem;

import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../../../fragments';

export const UPDATE_PROFILE_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation updateProfileMutation(
      $_id: ID!
      $name: String
      $surname: String
      $phone: String
      $email: String
      $type: Int
      $status: Int
      $companyIndustry: String
      $companySize: String
      $favouriteProduct: String
      $howYouHearAboutUs: String
      $chatParticipateLimit: Int
      $purposeOfUsage: String
      $yourRole: String
      $isTranslate: Boolean
      $language: String
   ) {
      updateProfile(
         data: {
            _id: $_id
            name: $name
            surname: $surname
            phone: $phone
            email: $email
            type: $type
            status: $status
            companyIndustry: $companyIndustry
            companySize: $companySize
            favouriteProduct: $favouriteProduct
            howYouHearAboutUs: $howYouHearAboutUs
            chatParticipateLimit: $chatParticipateLimit
            purposeOfUsage: $purposeOfUsage
            yourRole: $yourRole
            isTranslate: $isTranslate
            language: $language
         }
      ) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            chatParticipateLimit
            status
            isTranslate
            language
         }
         success
         code
         message
      }
   }
`;

export const UPDATE_SINGLE_CUSTOMER_PROFILE_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation updateSingleCustomerProfile(
      $_id: ID!
      $name: String
      $surname: String
      $phone: String
      $email: String
      $type: Int
      $status: Int
      $companyIndustry: String
      $companySize: String
      $favouriteProduct: String
      $howYouHearAboutUs: String
      $chatParticipateLimit: Int
      $purposeOfUsage: String
      $yourRole: String
      $isTranslate: Boolean
      $language: String
   ) {
      updateSingleCustomerProfile(
         data: {
            _id: $_id
            name: $name
            surname: $surname
            phone: $phone
            email: $email
            type: $type
            status: $status
            companyIndustry: $companyIndustry
            companySize: $companySize
            favouriteProduct: $favouriteProduct
            howYouHearAboutUs: $howYouHearAboutUs
            chatParticipateLimit: $chatParticipateLimit
            purposeOfUsage: $purposeOfUsage
            yourRole: $yourRole
            isTranslate: $isTranslate
            language: $language
         }
      ) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            chatParticipateLimit
            status
            isTranslate
            language
         }
         success
         code
         message
      }
   }
`;

export const CHANGE_PERSON_PHOTO_MUTATION = gql`
   mutation changePersonPhotoMutation($_id: ID!, $photo: FileUpload!) {
      changePersonPhoto(data: { _id: $_id, photo: $photo }) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            chatParticipateLimit
         }
         success
         code
         message
      }
   }
`;

export const CHANGE_PERSON_PASSWORD_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation updatePersonPasswordMutation($_id: ID!, $oldPassword: String!, $newPassword: String!) {
      changePersonPassword(data: { _id: $_id, oldPassword: $oldPassword, newPassword: $newPassword }) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            chatParticipateLimit
            customer {
               ...CustomerFields
            }
            type
            setup
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;

import { Divider, Grid, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useRef } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonOK from '@components/ButtonOK/buttonOk';
import SearchSelect from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import { USERS_TYPE } from '../../../Chat/types';
import SingleInput from '@components/_Modal/SingleInput';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import DoubleInput from '@components/_Modal/DoubleInput';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 600,
      padding: 20,
      paddingBottom: 5,
      borderRadius: 15,
      height: 'auto',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   modalTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.3rem',
      marginBottom: 10,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   customInputDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 42,
      borderRadius: 5,
      border: '2px solid #dcdcdc',
      '&input:focus': {
         outline: 'none !important',
      },
      '&input:focus-visible': {
         outline: 'none !important',
      },
      '&input:hover': {
         outline: 'none !important',
      },
      backgroundColor: '#f4f4f4',
   },
   customInput: {
      height: 38,
      width: 60,
      borderRadius: 7,
      borderLeft: 'none !important',
      borderBottom: 'none !important',
      borderTop: 'none !important',
      outline: 'none !important',
      textDecoration: 'none',
      fontSize: 16,
      borderRight: '2px solid #dcdcdc',
      textAlign: 'center',
   },
   customInputText: {
      width: '100%',
      height: 40,
      padding: 8,
      fontSize: 16,
   },
   customInputDivError: {
      border: '2px solid #ff0000',
   },
   customInputError: {
      borderRight: '2px solid #ff0000',
   },
}));
const EditUserModal = ({ onCancel, person, onAccept, teamsData }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const personTypes = [
      { value: USERS_TYPE.PERSONEL, label: t?.employee },
      { value: USERS_TYPE.ADMIN, label: t?.admin },
   ];


   const { watch, handleSubmit, setValue, control, register } = useForm({
      mode: 'all',
      defaultValues: {
         _id: person?.id,
         name: person?.name,
         surname: person?.surname,
         phone: person?.phone,
         type:
            person?.type === 0 ? { value: 0, label: t?.employee } : person?.type === 1 && { value: 1, label: t?.admin },
         email: person?.email,
         chatParticipateLimit: person?.chatParticipateLimit || 0,
         teams: person?.signedTeams.map((team) => {
            let foundTeam = teamsData?.find((t) => t._id === team);
            return { value: foundTeam?._id, label: foundTeam?.teamName };
         }),
         password: '',
         passwordConfirm: '',
      },
   });
   const password = useRef({});
   password.current = watch('password', '');
   //submit data
   const onSubmit = (data) => {
      onAccept(data);
   };
   // react select component styles
   const customStyles = {
      control: (base) => ({
         ...base,
         height: 45,
         minHeight: 45,
      }),
   };

   const isRobot = Number(person.type) === 3;

   return (
      <form onSubmit={handleSubmit(onSubmit)}>
         {isRobot && (
            <SingleInput
               name='name'
               control={control}
               label={t['page_account_input_profile_name']}
               editable
               height={40}
               required={false}
            />
         )}

         {!isRobot && (
            <DoubleInput
               name1='name'
               control1={control}
               label1={t['page_account_input_profile_name']}
               editable1
               required1={false}
               name2='surname'
               label2={t['page_account_input_profile_surname']}
               control2={control}
               editable2
               required2={false}
            />
         )}
         {!isRobot && <SingleInput name='email' label='E-mail' labelSize='small' editable control={control} />}
         {!isRobot && (
            <Grid xs={12} style={{ marginBottom: 20 }}>
               <Typography align='left'>{t?.page_settings_users_role}</Typography>
               <SearchSelect
                  className='col-form-label text-dark'
                  name='type'
                  options={personTypes}
                  defaultValue={watch('type')}
                  onChange={(type) => setValue('type', type)}
                  styles={customStyles}
               />
            </Grid>
         )}
         {!isRobot && (
            <SingleInput
               isSelect
               isMulti
               label={t.teams}
               selectOptions={teamsData.map((team) => ({
                  label: team.teamName,
                  value: team._id,
               }))}
               name='teams'
               control={control}
               editable
               placeholder={t?.teams}
            />
         )}
         {!isRobot && (
            <Controller
               name='chatParticipateLimit'
               control={control}
               defaultValue={0}
               rules={{
                  required: true,
                  validate: (value) => {
                     return !isNaN(parseInt(value)) && parseInt(value) >= 0;
                  },
               }}
               render={({ field, fieldState: { error } }) => {
                  return (
                     <div style={{ marginBottom: 20 }}>
                        <Typography style={{ marginLeft: 2 }} align='left'>
                           {t?.page_settings_typo_chat_settings_conversation_assignment_auto_assignment}
                        </Typography>
                        <div className={clsx(classes.customInputDiv, error && classes.customInputDivError)}>
                           <input
                              className={clsx(classes.customInput, error && classes.customInputError)}
                              {...field}
                              type='number'
                           />
                           <div className={classes.customInputText}>
                              {
                                 t[
                                    'page_settings_typo_chat_settings_conversation_assignment_auto_assignment_description'
                                 ]
                              }
                           </div>
                        </div>
                     </div>
                  );
               }}
            />
         )}
         <ModalButtonField>
            <ButtonCancel
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
               onClick={onCancel}
               label={t['comp_button_cancel']}
            />
            <ButtonOK
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
               type='submit'
               label={t['comp_button_save']}
            />
         </ModalButtonField>
      </form>
   );
};

export default EditUserModal;

import { useMutation } from '@apollo/client';
import { UPDATE_FLOW_NODE_MUTATION } from '@queries/Automation/mutation';
import { useCallback } from 'react';
import { Node, useReactFlow, useStoreApi } from 'reactflow';

function useDetachNodes() {
   const { setNodes } = useReactFlow();
   const store = useStoreApi();

   const detachNodes = useCallback(
      (
         ids: string[],
         removeParentId?: string,
         setContentNodes?: React.Dispatch<React.SetStateAction<Node[]>>,
         setLeftNodes?: React.Dispatch<React.SetStateAction<Node[]>>,
      ) => {
         const { nodeInternals } = store.getState();
         const nextNodes = Array.from(nodeInternals.values()).map((n) => {
            if (ids.includes(n.id) && n.parentNode) {
               const parentNode = nodeInternals.get(n.parentNode);
               return {
                  ...n,
                  position: {
                     x: n.position.x + (parentNode?.positionAbsolute?.x ?? 0),
                     y: n.position.y + (parentNode?.positionAbsolute?.y ?? 0),
                  },
                  expandParent: undefined,
                  parentNode: undefined,
               };
            }

            return n;
         });
         setNodes(nextNodes.filter((n) => !removeParentId || n.id !== removeParentId));
         if (setLeftNodes && setContentNodes) {
            setLeftNodes(nextNodes.filter((n) => !removeParentId || n.id !== removeParentId));
            setContentNodes(nextNodes.filter((n) => !removeParentId || n.id !== removeParentId));
         }
      },
      [setNodes, store],
   );

   return detachNodes;
}

export default useDetachNodes;

import { LanguageContext } from '@helper/locale/langContext';
import { ClickAwayListener, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ReplyIcon from '@material-ui/icons/Reply';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import React, { useContext, useEffect, useRef, useState } from 'react';

const useStyles = makeStyles((theme) => ({
   tooltipContainer: {
      '& div:first-child': {
         backgroundColor: '#F7F7FA',
         color: '#000000',
         fontSize: '.75rem',
         maxWidth: '230px',
         padding: '0.5rem',
         animation: 'none',
         '&  > span': {
            color: '#F7F7FA',
         },
         '& svg': {
            width: 'inherit',
            height: 'inherit',
            margin: 'auto',
         },
         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      },
   },
   envelopeInfo: {
      fontWeight: '550',
      color: 'rgba(0, 0, 0, 0.75)',
      cursor: 'text',
   },
   fontColor: {
      color: 'rgba(0, 0, 0, 0.9)',
   },
}));

const EmailExpandHeader = (props) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const emailData = props.emailData;
   const isEmail = props.isEmail;
   const subject = props.subject;
   const timeVisible = props.timeVisible;
   const isMine = props.isMine;
   const time = props.time;
   const isAutoCreate = props.isAutoCreate;
   const senderTitle = props.senderTitle;
   const handleReplyClick = props.handleReplyClick;
   const toggleTimeVisible = props.toggleTimeVisible;
   const handleToggleExpand = props.handleToggleExpand;
   const isPrivate = props.isPrivate;
   const isMouseOnCc = useRef(null);
   const isMouseOnFrom = useRef(null);
   const isMouseOnTo = useRef(null);
   const isMouseOnBcc = useRef(null);
   const isMouseOnSubject = useRef(null);
   const isMouseOnCcToolbar = useRef(null);
   const isMouseOnToToolbar = useRef(null);
   const isMouseOnBccToolbar = useRef(null);

   const classes = useStyles();

   const [openToolbar, setOpenToolbar] = useState({
      to: false,
      cc: false,
      bcc: false,
   });

   const [toEmails, setToEmails] = useState([]);
   const [ccEmails, setCcEmails] = useState([]);
   const [bccEmails, setBccEmails] = useState([]);
   useEffect(() => {
      if (isEmail && emailData && Array.isArray(emailData.to)) {
         setToEmails(emailData.to);
      }
      if (isEmail && emailData && Array.isArray(emailData.cc)) {
         setCcEmails(emailData.cc);
      }
      if (isEmail && emailData && Array.isArray(emailData.bcc)) {
         setBccEmails(emailData.bcc);
      }
   }, [isEmail, emailData]);

   const visibleCc = ccEmails.slice(0, 2);
   const remainingCc = ccEmails.slice(2);

   const visibleTo = toEmails.slice(0, 2);
   const remainingTo = toEmails.slice(2);

   const visibleBcc = bccEmails.slice(0, 2);
   const remainingBcc = bccEmails.slice(2);

   const handleToggleExpandVisible = (event) => {
      event.stopPropagation();
      if (
         (isMouseOnFrom.current && isMouseOnFrom.current.contains(event.target)) ||
         (isMouseOnCc.current && isMouseOnCc.current.contains(event.target)) ||
         (isMouseOnTo.current && isMouseOnTo.current.contains(event.target)) ||
         (isMouseOnBcc.current && isMouseOnBcc.current.contains(event.target)) ||
         (isMouseOnCcToolbar.current && isMouseOnCcToolbar?.current.contains(event.target)) ||
         (isMouseOnToToolbar.current && isMouseOnToToolbar?.current.contains(event.target)) ||
         (isMouseOnBccToolbar.current && isMouseOnBccToolbar?.current.contains(event.target)) ||
         (isMouseOnSubject.current && isMouseOnSubject.current.contains(event.target))
      ) {
         return;
      }
      isPrivate ? toggleTimeVisible() : handleToggleExpand();
   };

   return (
      <>
         <div
            onClick={handleToggleExpandVisible}
            style={{ display: 'flex', flexDirection: 'column' }}
         >
            <span>
               <span ref={isMouseOnFrom} className={classes.envelopeInfo}>
                  {emailData?.from}
               </span>
            </span>

            {toEmails.length > 0 && (
               <span ref={isMouseOnTo} style={{ width: '550px' }}>
                  <span className={classes.fontColor}>To: </span>
                  {visibleTo?.map((recipient, index) => (
                     <span key={index} className={classes.envelopeInfo}>
                        &lt;{recipient}&gt; {index !== visibleTo.length - 1 && ','}
                        {remainingTo.length > 0 && visibleTo.length - 1 === index && (
                           <ClickAwayListener
                              onClickAway={() =>
                                 setOpenToolbar({
                                    ...openToolbar,
                                    to: false,
                                 })
                              }
                           >
                              <Tooltip
                                 PopperProps={{
                                    disablePortal: true,
                                 }}
                                 placement='top'
                                 onClose={() => setOpenToolbar({ ...openToolbar, to: false })}
                                 open={openToolbar.to}
                                 disableFocusL
                                 classes={{ popper: classes.tooltipContainer }}
                                 disableHoverListener
                                 disableTouchListener
                                 title={remainingTo.map((recipient) => `<${recipient}>`)}
                                 arrow
                              >
                                 <span
                                    ref={isMouseOnToToolbar}
                                    style={{
                                       fontWeight: 'bold',
                                       color: '#4888F7',
                                       fontSize: '12px',
                                       cursor: 'pointer',
                                    }}
                                    onClick={() => setOpenToolbar({ ...openToolbar, to: true })}
                                 >
                                    <span
                                       style={{
                                          marginLeft: '10px',
                                          minWidth: '40px',
                                       }}
                                    >
                                       ...+{remainingTo.length}
                                    </span>
                                 </span>
                              </Tooltip>
                           </ClickAwayListener>
                        )}
                     </span>
                  ))}
               </span>
            )}
            {ccEmails.length > 0 && (
               <span ref={isMouseOnCc} style={{ width: '550px' }}>
                  <span className={classes.fontColor}>Cc: </span>
                  {visibleCc?.map((recipient, index) => (
                     <span key={index} className={classes.envelopeInfo}>
                        &lt;{recipient}&gt; {index !== visibleCc.length - 1 && ','}
                        {remainingCc.length > 0 && visibleCc.length - 1 === index && (
                           <ClickAwayListener
                              onClickAway={() =>
                                 setOpenToolbar({
                                    ...openToolbar,
                                    cc: false,
                                 })
                              }
                           >
                              <Tooltip
                                 PopperProps={{
                                    disablePortal: true,
                                 }}
                                 placement='top'
                                 onClose={() => setOpenToolbar({ ...openToolbar, cc: false })}
                                 open={openToolbar.cc}
                                 disableFocusL
                                 classes={{ popper: classes.tooltipContainer }}
                                 disableHoverListener
                                 disableTouchListener
                                 title={remainingCc.map((recipient) => `<${recipient}>`)}
                                 arrow
                              >
                                 <span
                                    ref={isMouseOnCcToolbar}
                                    style={{
                                       fontWeight: 'bold',
                                       color: '#4888F7',
                                       fontSize: '12px',
                                       cursor: 'pointer',
                                    }}
                                    onClick={() => setOpenToolbar({ ...openToolbar, cc: true })}
                                 >
                                    <span
                                       style={{
                                          marginLeft: '10px',
                                          minWidth: '40px',
                                       }}
                                    >
                                       ...+{remainingCc.length}
                                    </span>
                                 </span>
                              </Tooltip>
                           </ClickAwayListener>
                        )}
                     </span>
                  ))}
               </span>
            )}
            {bccEmails.length > 0 && (
               <span ref={isMouseOnBcc} style={{ width: '550px' }}>
                  <span className={classes.fontColor}>Bcc: </span>
                  {visibleBcc?.map((recipient, index) => (
                     <span key={index} className={classes.envelopeInfo}>
                        &lt;{recipient}&gt; {index !== visibleBcc.length - 1 && ','}
                        {remainingBcc.length > 0 && visibleBcc.length - 1 === index && (
                           <ClickAwayListener
                              onClickAway={() =>
                                 setOpenToolbar({
                                    ...openToolbar,
                                    bcc: false,
                                 })
                              }
                           >
                              <Tooltip
                                 PopperProps={{
                                    disablePortal: true,
                                 }}
                                 placement='top'
                                 onClose={() => setOpenToolbar({ ...openToolbar, bcc: false })}
                                 open={openToolbar.bcc}
                                 disableFocusL
                                 classes={{ popper: classes.tooltipContainer }}
                                 disableHoverListener
                                 disableTouchListener
                                 title={remainingBcc.map((recipient) => `<${recipient}>`)}
                                 arrow
                              >
                                 <span
                                    ref={isMouseOnBccToolbar}
                                    style={{
                                       color: '#4888F7',
                                       fontSize: '12px',
                                       cursor: 'pointer',
                                    }}
                                    onClick={() => setOpenToolbar({ ...openToolbar, bcc: true })}
                                 >
                                    <span
                                       style={{
                                          marginLeft: '10px',
                                          minWidth: '40px',
                                       }}
                                    >
                                       ...+{remainingBcc.length}
                                    </span>
                                 </span>
                              </Tooltip>
                           </ClickAwayListener>
                        )}
                     </span>
                  ))}
               </span>
            )}
            <span ref={isMouseOnSubject} style={{ width: '550px' }}>
               <span className={classes.fontColor}>Subject: </span>{' '}
               <span className={classes.envelopeInfo}>{subject}</span>
            </span>
         </div>

         <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                  {timeVisible && (
                     <Grid container justifyContent={isMine ? 'flex-end' : 'flex-start'}>
                        <div
                           style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              fontSize: '0.7rem',
                              color: '#868686',
                           }}
                        >
                           {!isMine
                              ? `${time}`
                              : `${isAutoCreate ? t?.from_phone : senderTitle} | ${time}`}
                        </div>
                     </Grid>
                  )}
               </div>
               <div
                  style={{
                     width: '100%',
                     display: 'flex',
                     justifyContent: 'flex-end',
                     cursor: 'pointer',
                  }}
               >
                  {(toEmails.length > 1) | (ccEmails.length > 1) | (bccEmails.length > 1) ? (
                     <ReplyAllIcon onClick={handleReplyClick} />
                  ) : (
                     <ReplyIcon onClick={handleReplyClick} />
                  )}
               </div>
            </div>
         </div>
      </>
   );
};

export default EmailExpandHeader;

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import DoubleInput from '@components/_Modal/DoubleInput';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';
import { LanguageContext } from '@helper/locale/langContext';
import useTranslateLanguage from '@hooks/useTranslateLanguage';
import { Checkbox, CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { LOCATIONS } from '@queries/Locations/query';
import { CREATE_PERSON_MUTATION } from '@queries/Person/Service/GraphQL/mutation';
import { GET_CHAT_TAGS_QUERY } from '@queries/Settings/Service/GraphQL/ChatTags/query';
import { GET_CUSTOM_FIELDS } from '@queries/Settings/Service/GraphQL/CustomFields/query';
import { getPerson } from '@store/reducers/person';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { EnumCustomFieldsType } from '../../Settings/SettingSpace/CustomFields/customFields';

const useStyles = makeStyles((theme) => ({
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      borderTop: '1px solid #F1F3F7',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   closeIcon: {
      position: 'absolute',
      top: '33px',
      right: '50px',
      width: '20px',
      opacity: '.8',
      cursor: 'pointer',
   },
}));

const CreateNewUserModal = ({ showCreateManualAccount, setCreateManualAccount }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const { data: languagesData, loading: languagesLoading } = useTranslateLanguage(lang.shortTitle);
   const person = useSelector(getPerson);
   const customFieldPlaceHolder = (type) => {
      switch (type) {
         case 'EMAIL':
            return t['page_contacts_typo_custom_field_placeholder_email'];
         case 'NUMBER':
            return t['page_contacts_typo_custom_field_placeholder_number'];
         case 'URL':
            return t['page_contacts_typo_custom_field_placeholder_url'];
         case 'DATE':
            return t['page_contacts_typo_custom_field_placeholder_date'];
         case 'TEXT':
            return t['page_contacts_typo_custom_field_placeholder_text'];
         case 'LIST':
            return t['page_contacts_typo_custom_field_placeholder_list_values'];
         case 'PHONE':
            return t['page_contacts_typo_custom_field_placeholder_phone'];
         default:
            return;
      }
   };

   const isValidNewCustomFieldValid = (type) => {
      switch (type) {
         case 'EMAIL':
            return {
               pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t['page_contacts_typo_custom_field_invalid_email'],
               },
            };
         case 'NUMBER':
            return {
               pattern: {
                  value: /^\d+$/,
                  message: t['page_contacts_typo_custom_field_invalid_number'],
               },
            };
         case 'URL':
            return {
               pattern: {
                  value: /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g,
                  message: t['page_contacts_typo_custom_field_invalid_url'],
               },
            };
         case 'DATE':
            return {
               pattern: {
                  value:
                     lang.shortTitle === 'TR'
                        ? /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
                        : /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                  message: t['page_contacts_typo_custom_field_invalid_date'],
               },
            };
         case 'LIST':
            return {
               required: t['page_contacts_typo_custom_field_invalid_list'],
            };
         default:
            return;
      }
   };

   const CHAT_TAGS_PAGE_SIZE = 10;

   const [isOpenCustomFields, setIsOpenCustomFields] = useState(false);
   const [filteredCustomFieldData, setFilteredCustomFieldData] = useState();
   const [chatTagsData, setChatTagsData] = useState();

   const {
      control,
      handleSubmit,
      watch,
      setValue,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         name: '',
         surname: '',
         email: '',
         phone: '',
         tags: [],
         language: '',
         country: '',
         state: '',
         personCustomFields: {},
      },
   });

   const [createPersonMutation, { loading }] = useMutation(CREATE_PERSON_MUTATION);

   const [getCountryInvoice, { data: countryDataInvoice, loading: countryLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'country',
         id: 0,
      },
   });

   const [getStateInvoice, { data: stateDataInvoice, loading: stateLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'state',
         id: watch('country.value'),
      },
   });

   const [getChatTagQuery] = useLazyQuery(GET_CHAT_TAGS_QUERY, {
      fetchPolicy: 'cache-first',
   });

   useQuery(GET_CUSTOM_FIELDS, {
      variables: {
         customer: person.customer._id,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
         setFilteredCustomFieldData(data.getCustomFields.data);
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const createPersonSubmit = (data) => {
      data = {
         ...data,
         personCustomFields: Object.entries(watch('personCustomFields')).map(([key, value]) => {
            if (typeof value === 'object' && value !== null) {
               return {
                  customField: key,
                  value: value.value,
               };
            } else {
               return {
                  customField: key,
                  value: value || '',
               };
            }
         }),
      };
      setCreateManualAccount({
         ...showCreateManualAccount,
         loading: true,
      });
      createPersonMutation({
         variables: {
            name: data.name,
            surname: data.surname,
            email: data.email,
            phone: data.phone,
            tags: data.tags.length > 0 ? data.tags : null,
            language: data.language ? data.language.label : null,
            country: data.country ? data.country.label : null,
            state: data.state ? data.state.label : null,
            personCustomFields: data.personCustomFields.length > 0 ? data.personCustomFields : null,
            password: '',
            customer: person.customer._id,
            type: -10,
         },
      })
         .then(
            ({
               data: {
                  createPerson: { data: createPerson },
               },
            }) => {
               enqueueSnackbar(`${createPerson.name} ${t['general_information_added_successfully']}`, {
                  variant: 'success',
                  autoHideDuration: 2000,
               });
               setCreateManualAccount({
                  loading: false,
                  visible: false,
                  licenseAgreement: false,
                  phone: '',
               });
            },
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               autoHideDuration: 3000,
            });
            setCreateManualAccount({
               loading: false,
               ...showCreateManualAccount,
            });
         });
   };

   const getTagsLoadOptions = async (search, prevOptions) => {
      if (search.length !== 0) {
         if (search.length < 3) {
            return {
               options: chatTagsData.map(({ name, _id, color }) => {
                  return {
                     value: _id,
                     label: name,
                     color,
                  };
               }),
               hasMore: false,
            };
         }
      }

      const getChatTagRequestData = await getChatTagQuery({
         variables: {
            customer: person.customer._id,
            search,
            pageSize: CHAT_TAGS_PAGE_SIZE,
            page: prevOptions.length === 0 ? 1 : prevOptions.length / CHAT_TAGS_PAGE_SIZE + 1,
         },
      })
         .then((res) => {
            setChatTagsData(res.data.getChatTagList.data.docs);
            return res.data.getChatTagList.data;
         })
         .catch(() => []);
      return {
         options: getChatTagRequestData.docs.map(({ name, _id, color }) => {
            return {
               value: _id,
               label: name,
               color,
            };
         }),
         hasMore: getChatTagRequestData.hasNextPage,
      };
   };

   const tagsStyles = {
      multiValue: (provided, state) => ({
         ...provided,
         backgroundColor: state.data.color,
      }),
      multiValueLabel: (provided, state) => ({
         ...provided,
         color: state.data.color === '#fff' ? '#212121' : '#fff',
      }),
      multiValueRemove: (provided, state) => ({
         ...provided,
         color: state.data.color === '#fff' ? '#212121' : '#fff',
         '&:hover': {
            backgroundColor: 'rgba(0,0,0, .1)',
            color: state.data.color === '#fff' ? '#212121' : '#fff',
         },
         cursor: 'pointer',
      }),
   };

   return (
      <Modal
         open={showCreateManualAccount.visible}
         onClose={() =>
            setCreateManualAccount((state) => ({
               ...state,
               visible: false,
               licenseAgreement: false,
            }))
         }
         modalHeader={t?.create_new_contact_title}
      >
         <div style={{ overflowY: 'scroll', maxHeight: 550, paddingRight: 14 }}>
            <DoubleInput
               rules1={{ required: t?.required_field }}
               name1='name'
               control1={control}
               label1={t['page_e_commerce_customer_typo_first_name']}
               name2='surname'
               maxMenuHeight2={190}
               control2={control}
               label2={t['page_e_commerce_customer_typo_last_name']}
            />
            <SingleInput type='email' name='email' label='Email' placeholder='Email' control={control} />
            <SingleInput name='phone' isPhone label={t['page_account_input_profile_phone']} control={control} />
            <DoubleInput
               maxMenuHeight1={190}
               name1='country'
               control1={control}
               label1={t['page_account_input_profile_country']}
               isSelect1
               isLoading1={countryLoadingInvoice}
               onFocus1={() => {
                  setValue('state', null);
                  getCountryInvoice();
               }}
               selectOptions1={countryDataInvoice?.locations?.data?.map(({ name, id }) => ({
                  value: id,
                  label: name,
               }))}
               name2='state'
               maxMenuHeight2={190}
               control2={control}
               isSelect2
               isLoading2={stateLoadingInvoice}
               disabled2={watch('country').length === 0 || countryLoadingInvoice}
               onFocus2={() => {
                  if (typeof watch('country.value') === 'number') {
                     getStateInvoice();
                  }
               }}
               selectOptions2={stateDataInvoice?.locations?.data?.map(({ name, id }) => ({
                  value: id,
                  label: name,
               }))}
               label2={t['page_account_input_profile_city']}
            />
            <SingleInput
               name='tags'
               type='tags'
               control={control}
               label={t['page_account_input_profile_tag']}
               isPaginateSelect
               isMulti
               autoHeight
               maxMenuHeight={190}
               customSelectStyles={tagsStyles}
               selectOptions={chatTagsData?.getChatTagList?.data.docs.map(({ name, _id, color }) => ({
                  value: _id,
                  label: name,
                  color,
               }))}
               value={watch('tags')}
               loadOptions={getTagsLoadOptions}
               onChange={(tag) => setValue('tags', tag)}
            />

            <SingleInput
               maxMenuHeight={190}
               name='language'
               isSelect
               selectOptions={languagesData?.map(({ label, code }) => ({
                  value: code,
                  label,
               }))}
               isLoading={languagesLoading}
               control={control}
               label={t['page_account_input_profile_language']}
            />

            <div
               style={{
                  borderTop: 'solid rgba(0,0,0, .05) 1.5px',
                  borderBottom: 'solid rgba(0,0,0, .05) 1.5px',
                  marginBottom: 10,
               }}
            >
               <Grid
                  container
                  direction='row'
                  style={{
                     borderRadius: '5px',
                  }}
                  alignItems='center'
                  wrap='nowrap'
               >
                  <Typography
                     noWrap
                     style={{
                        textDecoration: 'none',
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        color: filteredCustomFieldData ? '#212121' : '#9E9E9E',
                     }}
                  >
                     {t['page_settings_typo_custom_field']}
                  </Typography>
                  <IconButton
                     disabled={!filteredCustomFieldData}
                     onClick={() => {
                        setIsOpenCustomFields(!isOpenCustomFields);
                     }}
                  >
                     {isOpenCustomFields ? (
                        <KeyboardArrowDownIcon style={{ fontSize: '32px' }} />
                     ) : (
                        <KeyboardArrowRightIcon style={{ fontSize: '32px' }} />
                     )}
                  </IconButton>
               </Grid>
            </div>
            {isOpenCustomFields && filteredCustomFieldData && (
               <div style={{ marginTop: '1.5rem' }}>
                  {Array.from(filteredCustomFieldData)
                     .reverse()
                     .map((field, index) => {
                        return (
                           <div style={{ position: 'relative' }}>
                              <SingleInput
                                 maxMenuHeight={190}
                                 placeholder={customFieldPlaceHolder(field.type)}
                                 name={`personCustomFields.${field._id}`}
                                 isSelect={field.type === EnumCustomFieldsType.LIST}
                                 selectOptions={field.listValues.map((value) => ({
                                    label: value,
                                    value,
                                 }))}
                                 isDate={field.type === EnumCustomFieldsType.DATE} // 12/12/2020 for formatter
                                 customSelectStyles={{
                                    menu: (base) => ({
                                       ...base,
                                       top: 'auto',
                                       bottom: filteredCustomFieldData.length - index < 3 ? '100%' : 'auto',
                                    }),
                                 }}
                                 control={control}
                                 variant='outlined'
                                 label={field.name}
                                 rules={isValidNewCustomFieldValid(field.type)}
                                 isPhone={field.type === EnumCustomFieldsType.PHONE}
                              />
                              {field.type === EnumCustomFieldsType.LIST && watch(`personCustomFields.${field._id}`) && (
                                 <span onClick={() => setValue(`personCustomFields.${field._id}`, '')}>
                                    <CloseIcon className={classes.closeIcon} />
                                 </span>
                              )}
                           </div>
                        );
                     })}
               </div>
            )}
            <div
               style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: 8,
                  marginBottom: 16,
               }}
            >
               <Checkbox
                  color='primary'
                  value={showCreateManualAccount.licenseAgreement}
                  onChange={() => {
                     setCreateManualAccount((state) => ({
                        ...state,
                        licenseAgreement: !showCreateManualAccount.licenseAgreement,
                     }));
                  }}
               />
               <div>
                  <span>{t['create_new_contact_disclaimer']} </span>
                  <a
                     target='_blank'
                     rel='noopener noreferrer'
                     href={
                        lang.code === 'tr'
                           ? 'https://www.qpien.com/tr/kullanim-kosullari'
                           : 'https://www.qpien.com/user-agreement'
                     }
                  >
                     {t['create_new_contact_disclaimer_qpien']}
                  </a>
                  <span>{t['create_new_contact_disclaimer_end']}</span>
               </div>
            </div>
         </div>
         <ModalButtonField>
            <ButtonCancel
               className={classes.modalButtons}
               disabled={showCreateManualAccount.loading}
               label={t['comp_button_cancel']}
               onClick={() =>
                  setCreateManualAccount({
                     visible: false,
                     licenseAgreement: false,
                     phone: '',
                     loading: false,
                  })
               }
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            />
            <ButtonOK
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
               disabled={
                  !showCreateManualAccount.licenseAgreement ||
                  showCreateManualAccount.loading ||
                  loading ||
                  !isValid ||
                  // Email or phone must be filled
                  (watch('email').length === 0 && watch('phone').length === 0)
               }
               label={t?.pre_load_forget_pass_sent_OK}
               endIcon={
                  showCreateManualAccount.loading && (
                     <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                  )
               }
               onClick={handleSubmit(createPersonSubmit)}
            />
         </ModalButtonField>
      </Modal>
   );
};

export default CreateNewUserModal;

import moment from 'moment';
import { QnAPersonStatusType, QnAPlatformType } from '@modules/Questions/util/types';

type SortingType = 'desc' | 'asc';

export type DayLike = number | string;

export type MyTabOptions = 'me' | 'company';

export type TagFilterState = {
   name: string;
   value: string;
};

type DayFilterState = {
   filterStartDate: DayLike;
   filterEndDate: DayLike;
};

type PlatformType = string | QnAPlatformType;

export type FilterStateAction =
   | { type: 'CHANGE_FILTER_DATE'; payload: { start: DayLike; end: DayLike } }
   | { type: 'CHANGE_STATUS'; payload: number }
   | { type: 'CHANGE_TAG'; payload: { value: string; name: string } }
   | { type: 'CHANGE_PLATFORM'; payload: PlatformType }
   | { type: 'CHANGE_SEARCH_FILTER'; payload: string }
   | { type: 'CHANGE_MY_TAB'; payload: MyTabOptions }
   | { type: 'CHANGE_SORTING'; payload: SortingType };

export interface FilterState {
   day: DayFilterState;
   tagState: TagFilterState;
   statusState: number | string;
   platform: PlatformType;
   searchFilter: string;
   myTab: MyTabOptions;
   sorting: SortingType;
}

const initialState: FilterState = {
   day: {
      filterStartDate: moment().subtract(1, 'month').valueOf(),
      filterEndDate: moment().valueOf(),
   },
   tagState: {
      name: 'ALL',
      value: 'ALL',
   },
   statusState: QnAPersonStatusType.WAITING,
   platform: 'ALL',
   searchFilter: '',
   myTab: 'company',
   sorting: 'desc',
};

const reducer = (state = initialState, action: FilterStateAction): FilterState => {
   switch (action.type) {
      case 'CHANGE_FILTER_DATE':
         return {
            ...state,
            day: {
               filterStartDate: action.payload.start,
               filterEndDate: action.payload.end,
            },
         };

      case 'CHANGE_STATUS':
         return {
            ...state,
            statusState: action.payload,
         };

      case 'CHANGE_TAG':
         return {
            ...state,
            tagState: {
               name: action.payload.name,
               value: action.payload.value,
            },
         };

      case 'CHANGE_SEARCH_FILTER':
         return {
            ...state,
            searchFilter: action.payload,
         };

      case 'CHANGE_PLATFORM':
         return {
            ...state,
            platform: action.payload,
         };

      case 'CHANGE_MY_TAB':
         return {
            ...state,
            myTab: action.payload,
         };

      case 'CHANGE_SORTING':
         return {
            ...state,
            sorting: action.payload,
         };

      default:
         return state;
   }
};

export const getFilterState = (state: any) => state.filterReducer as FilterState;
export default reducer;

import React from 'react';
export const WhatsAppIcon = ({ selected, className }) => {
   const color1 = '#00d954';
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         viewBox='0 0 529.7 531'
         className={className}
         style={{ width: '100%', opacity: selected ? '' : 0.4 }}
      >
         <path
            d='M0,531,38.07,389a261.44,261.44,0,0,1-32.9-126.7C5.17,117.66,122.83,0,267.43,0S529.7,117.66,529.7,262.26,412.05,524.53,267.43,524.53a261.6,261.6,0,0,1-124.66-31.78Zm149.87-91.48,9,5.37A212.36,212.36,0,0,0,267.43,475c117.28,0,212.7-95.4,212.7-212.69S384.71,49.58,267.43,49.58,54.75,145,54.75,262.26a212.07,212.07,0,0,0,31.1,110.28l5.52,9L70.12,460.9Z'
            fill={color1}
         />
         <path
            d='M365,298.74c-10.8-6.46-24.85-13.67-37.56-8.47-9.76,4-16,19.27-22.31,27.07-3.24,4-7.11,4.62-12.1,2.62-36.62-14.59-64.68-39-84.89-72.72-3.42-5.24-2.81-9.36,1.31-14.22,6.11-7.19,13.77-15.35,15.43-25s-2.9-21-6.9-29.63c-5.12-11-10.84-26.75-21.88-33-10.15-5.74-23.52-2.53-32.56,4.84-15.61,12.71-23.14,32.62-22.91,52.36a74.14,74.14,0,0,0,2.06,16.62c3.15,13,9.17,25.17,15.94,36.74a292.53,292.53,0,0,0,16.64,25.3c19.59,26.61,44,49.73,72.18,67a218.46,218.46,0,0,0,45,21.4c17.64,5.83,33.35,11.89,52.4,8.28,19.94-3.79,39.59-16.13,47.51-35.34,2.34-5.69,3.51-12,2.2-18C391.86,312.13,375,304.75,365,298.74Z'
            style={{ fill: color1, fillRule: 'evenodd' }}
         />
      </svg>
   );
};

export const WhatsAppBusiness = ({ selected, className }) => {
   const color1 = '#00d954';
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         enableBackground='new 0 0 32 32'
         viewBox='0 0 32 32'
         transform='scale(1.25 1.25)'
         className={className}
         style={{ transform: 'scale(1.25)', width: '100%', opacity: selected ? '' : 0.4 }}
      >
         <rect width='539' height='176' x='-330' y='-20' display='none' />
         <path
            fill={color1}
            d='M17,4C10.383,4,5,9.383,5,16c0,2.003,0.503,3.974,1.473,5.754l-2.33,5.827c-0.148,0.371-0.062,0.795,0.222,1.078c0.191,0.191,0.447,0.293,0.707,0.293c0.125,0,0.251-0.023,0.371-0.072l5.837-2.335C13.029,27.499,14.997,28,17,28c6.617,0,12-5.383,12-12S23.617,4,17,4z M17,26c-1.812,0-3.587-0.492-5.135-1.423c-0.276-0.166-0.613-0.188-0.906-0.063l-4.094,1.644l1.522-3.808l0.109-0.241c0.121-0.269,0.118-0.578-0.009-0.844c-0.027-0.058-0.063-0.124-0.103-0.19C7.479,19.541,7,17.786,7,16c0-5.514,4.486-10,10-10s10,4.486,10,10S22.514,26,17,26z M21.664,13.189C21.509,11.401,19.898,10,18,10h-4c-0.553,0-1,0.448-1,1v10c0,0.552,0.447,1,1,1h4c1.898,0,3.509-1.401,3.664-3.189c0.085-0.979-0.245-1.954-0.906-2.675c-0.042-0.046-0.086-0.091-0.131-0.135c0.045-0.044,0.089-0.089,0.131-0.135C21.419,15.144,21.749,14.168,21.664,13.189z M18,20h-3v-3h3.178c0.426,0,0.818,0.173,1.106,0.487c0.287,0.313,0.425,0.722,0.388,1.15C19.606,19.389,18.856,20,18,20z M19.284,14.513C18.996,14.827,18.604,15,18.178,15H15v-3h3c0.856,0,1.606,0.611,1.672,1.363C19.709,13.791,19.571,14.2,19.284,14.513z'
         />
      </svg>
   );
};

export const LiveChatIcon = ({ selected, className }) => {
   const color1 = '#157cfc';

   return (
      <svg
         id='Group_2306'
         data-name='Group 2306'
         xmlns='http://www.w3.org/2000/svg'
         width='55px'
         height='55px'
         viewBox='0 0 35.267 35.177'
         className={className}
         style={{ width: '100%', opacity: selected ? '' : 0.4 }}
      >
         <path
            id='Path_85'
            data-name='Path 85'
            d='M17.506,35.177a38.333,38.333,0,0,1-6.442-1.662,9.024,9.024,0,0,0-5.723-.534c-2.871.569-3.928-.473-3.316-3.331a9.188,9.188,0,0,0-.587-5.634C-1.23,17.346-.214,11.052,4.723,5.764,9.684.45,15.96-1.274,22.984.943c6.93,2.188,11.066,7.039,12.1,14.169C36.617,25.708,28.349,34.942,17.506,35.177ZM7.192,27.868c1.553.475,3.289.88,4.922,1.536a14.187,14.187,0,0,0,8.427.886,13.123,13.123,0,0,0,9.8-16.676A13.288,13.288,0,0,0,12.87,5.288,13.106,13.106,0,0,0,5.687,23.114,35.614,35.614,0,0,1,7.192,27.868Z'
            transform='translate(0 0)'
            fill={color1}
         />
         <path
            id='Path_86'
            data-name='Path 86'
            d='M1.952.939c-.052.559-.293.952-.917.989A.934.934,0,0,1,0,.971.931.931,0,0,1,.957,0,.939.939,0,0,1,1.952.939Z'
            transform='translate(16.992 16.572)'
            fill={color1}
         />
         <path
            id='Path_87'
            data-name='Path 87'
            d='M1,0a.947.947,0,0,1,.929,1.046.878.878,0,0,1-.987.889A.9.9,0,0,1,0,.987.963.963,0,0,1,1,0Z'
            transform='translate(10.242 16.557)'
            fill={color1}
         />
         <path
            id='Path_88'
            data-name='Path 88'
            d='M1.935.968a.942.942,0,0,1-1.01.968A.87.87,0,0,1,0,.985.894.894,0,0,1,.907,0,.976.976,0,0,1,1.935.968Z'
            transform='translate(23.785 16.562)'
            fill={color1}
         />
      </svg>
   );
};

export const InstagramIcon = ({ selected, className }) => {
   const color1 = 'url(#linear-gradient)';
   const color2 = 'url(#linear-gradient-2)';
   const color3 = 'url(#linear-gradient-3)';

   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         viewBox='0 0 595 595'
         className={className}
         style={{ width: '100%', opacity: selected ? '' : 0.4 }}
      >
         <defs>
            <linearGradient
               id='linear-gradient'
               x1='182.45'
               y1='-119.78'
               x2='722.06'
               y2='396.73'
               gradientUnits='userSpaceOnUse'
            >
               <stop offset='0' stopColor='#ffe185' />
               <stop offset='0.21' stopColor='#ffbb36' />
               <stop offset='0.38' stopColor='#ff5176' />
               <stop offset='0.52' stopColor='#f63395' />
               <stop offset='0.74' stopColor='#a436d2' />
               <stop offset='1' stopColor='#5f4eed' />
            </linearGradient>
            <linearGradient id='linear-gradient-2' x1='30.19' y1='39.3' x2='569.8' y2='555.8' href='#linear-gradient' />
            <linearGradient
               id='linear-gradient-3'
               x1='29.02'
               y1='40.52'
               x2='568.63'
               y2='557.02'
               href='#linear-gradient'
            />
         </defs>
         <path d='M455,106.24a34.88,34.88,0,1,0,34.88,34.88A34.88,34.88,0,0,0,455,106.24Z' fill={color1} />
         <path
            d='M299.94,151c-80.8,0-146.53,65.73-146.53,146.53S219.14,444,299.94,444,446.47,378.29,446.47,297.5,380.74,151,299.94,151Zm0,240.39A93.86,93.86,0,1,1,393.8,297.5,94,94,0,0,1,299.94,391.36Z'
            fill={color2}
         />
         <path
            d='M416.27,595H178.72C80.17,595,0,514.83,0,416.28V178.72C0,80.17,80.17,0,178.72,0H416.27C514.81,0,595,80.17,595,178.72V416.28C595,514.83,514.81,595,416.27,595ZM178.72,56A122.88,122.88,0,0,0,56,178.72V416.28A122.87,122.87,0,0,0,178.72,539H416.27A122.88,122.88,0,0,0,539,416.28V178.72A122.89,122.89,0,0,0,416.27,56Z'
            fill={color3}
         />
      </svg>
   );
};

export const FacebookMessengerIcon = ({ selected, className }) => {
   const color1 = '#0084ff';
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         viewBox='0 0 595.07 599'
         className={className}
         style={{ width: '100%', opacity: selected ? '' : 0.4 }}
      >
         <path
            d='M555,137.76q40.08,62.9,40.08,139.53T555,416.82q-40.14,62.9-107.71,100.32t-149.57,37.4a316.07,316.07,0,0,1-85.64-11.44L110.85,599V493.25a285.47,285.47,0,0,1-81.11-94.62Q0,342.76,0,277.29q0-76.61,40.12-139.53T147.8,37.4Q215.39,0,297.71,0q82,0,149.57,37.4T555,137.76ZM327.15,373.38,490,199.77l-146.28,81.1-77.52-81.1L103.34,373.38,251.44,292Z'
            fill={color1}
         />
      </svg>
   );
};

export const EmailIcon = ({ selected, className }) => {
   const color1 = '#e65a4d';
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} viewBox='0 0 674.67 530'>
         <path
            d='M577.27,0H97.4A97.51,97.51,0,0,0,0,97.4V432.6A97.51,97.51,0,0,0,97.4,530H577.27a97.52,97.52,0,0,0,97.4-97.4V97.4A97.51,97.51,0,0,0,577.27,0Zm0,46.56a50.75,50.75,0,0,1,11.57,1.38L386.39,277.65a65.38,65.38,0,0,1-98.1,0L85.84,47.94A50.66,50.66,0,0,1,97.4,46.56Zm50.84,386a50.9,50.9,0,0,1-50.84,50.84H97.4A50.9,50.9,0,0,1,46.56,432.6V97.4a50.48,50.48,0,0,1,3.82-19.27l203,230.31a112,112,0,0,0,168,0l203-230.3a50.61,50.61,0,0,1,3.81,19.26Z'
            fill={color1}
         />
      </svg>
   );
};

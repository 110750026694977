import { useQuery } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import useBoolean from '@hooks/useBoolean';
import useWindowResize from '@hooks/useWindowResize';
import { CircularProgress, Grid, IconButton, Zoom } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GET_ACTIVE_CHAT_MESSAGES } from '@queries/Chat/Service/GraphQL/Message/query';
import { SET_ACTIVE_CHAT_MESSAGES } from '@store/actions/chat';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import addDaysToChatList from '../../../../../utils/addDaysToChatList';
import ContactInfoMessage from './ContactInfoMessage';
import RateUsMessage from './RateUsMessage';
import MessageItem from './getItemJSX';

const useStyles = makeStyles(() => ({
   latestMessageIcon: {
      position: 'absolute',
      backgroundColor: 'lightgrey',
      bottom: 200,
      right: (props) => (props.profileStatus ? 400 : 50),
      zIndex: 100,
      '&:hover': {
         backgroundColor: 'lightgrey',
      },
   },
}));

const ChatItemsContainer = ({ chatInputHeight, isEmail }) => {
   const activeChat = useSelector((reducer) => reducer.chatReducer.activeChat);
   const messages = useSelector(
      (state) => state.chatReducer.activeChatMessages[activeChat._id]?.messages,
   );
   const totalDocs = useSelector(
      (reducer) => reducer.chatReducer.activeChatMessages[activeChat._id]?.totalDocs,
   );
   const hasNextPage = useSelector(
      (reducer) => reducer.chatReducer.activeChatMessages[activeChat._id]?.hasNextPage,
   );
   const savedMessagesLength = useSelector(
      (reducer) => reducer.chatReducer.activeChatMessages[activeChat._id]?.savedMessagesLength,
   );
   const profileStatus = useSelector((state) => state.uiReducer.isOpenChatProfileMenu);
   const activeChatMessagePage =
      useSelector((reducer) => reducer.chatReducer.activeChatMessages[activeChat._id]?.page) || 1;
   const classes = useStyles({ profileStatus });
   const dispatch = useDispatch();
   const { height } = useWindowResize();
   const {
      value: showScrollButton,
      on: showScrollButtonOn,
      off: showScrollButtonOff,
   } = useBoolean(false);
   const messageEl = useRef(null);
   const scrollRef = useRef(null);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [messagesToRender, setMessagesToRender] = useState([]);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const { fetchMore, loading: activeChatMessagesLoading } = useQuery(GET_ACTIVE_CHAT_MESSAGES, {
      variables: {
         chatId: activeChat._id,
         page: 0,
         chatStartDate: activeChat?.createdAt,
      },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
         if (res?.getActiveChatMessages?.data) {
            dispatch({
               type: SET_ACTIVE_CHAT_MESSAGES,
               payload: {
                  ...res?.getActiveChatMessages?.data,
                  activeChatId: activeChat?._id,
                  messages: res?.getActiveChatMessages?.data?.messages.map((message) => ({
                     ...message,
                     isSending: false,
                  })),
               },
            });
         }
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  {t['comp_button_close']}
               </div>
            ),
         });
      },
   });

   useEffect(() => {
      if (messageEl?.current) {
         setTimeout(() => {
            messageEl.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
         }, 100);
      }
   }, [totalDocs]);

   const handleScroll = (e) => {
      const bottom = e.target.scrollTop < -400;
      if (bottom) {
         showScrollButtonOn(); // button active
      } else {
         showScrollButtonOff();
      }
   };

   useEffect(() => {
      if (messages) {
         const chatListWithDays = addDaysToChatList(messages);
         if (chatListWithDays) {
            setMessagesToRender(chatListWithDays);
         }
      }
   }, [JSON.stringify(messages)]);

   const handleNext = useCallback(() => {
      fetchMore({
         variables: {
            chatId: activeChat._id,
            page: savedMessagesLength ? savedMessagesLength : 0,
         },
      })
         .then(({ data }) => {
            const newMessages = [
               ...messages,
               ...data?.getActiveChatMessages?.data?.messages?.map((message) => ({
                  isSending: false,
                  ...message,
               })),
            ];
            dispatch({
               type: SET_ACTIVE_CHAT_MESSAGES,
               payload: {
                  activeChatId: activeChat._id,
                  messages: newMessages,
                  page: data?.getActiveChatMessages?.data?.page,
                  hasNextPage: data?.getActiveChatMessages?.data?.hasNextPage,
                  totalDocs: data?.getActiveChatMessages?.data?.totalDocs,
               },
            });
         })
         .catch((err) => console.log(err));
   }, [activeChat?._id, activeChatMessagePage, dispatch, fetchMore, savedMessagesLength, messages]);

   if (activeChatMessagesLoading) {
      return (
         <Grid
            container
            xs={12}
            justifyContent='center'
            alignItems='center'
            style={{ height: '100vh' }}
         >
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   }

   return (
      <Grid container direction='column' item xs={12}>
         {messagesToRender && (
            <div
               id='chatDiv'
               style={{
                  overflowY: 'auto',
                  display: 'flex',
                  flexDirection: 'column-reverse',
               }}
               className='chat'
               ref={scrollRef}
            >
               <InfiniteScroll
                  next={handleNext}
                  dataLength={messagesToRender?.length || 0}
                  height={chatInputHeight ? height - 100 - chatInputHeight : height - 225}
                  loader={
                     <Grid
                        style={{
                           height: 350,
                           margin: '20px 0px',
                        }}
                        container
                        justifyContent='center'
                        alignItems='center'
                        xs={12}
                     >
                        <CircularProgress size={24} color='primary' />
                     </Grid>
                  }
                  hasMore={hasNextPage}
                  style={{
                     display: 'flex',
                     flexDirection: 'column-reverse',
                     overflowX: 'hidden',
                  }}
                  inverse={true}
                  scrollableTarget='chatDiv'
                  onScroll={handleScroll}
               >
                  <div ref={messageEl} />
                  {messagesToRender.map((message, index, originalArray) => {
                     // message.messageType?.includes('RATE')
                     const previousMessage = originalArray[index - 1];
                     // Todo - Delete message feature.
                     return message.messageType?.includes('RATE') ? (
                        <RateUsMessage
                           key={message._id || message.sendingStatusId}
                           {...JSON.parse(message.content)}
                        />
                     ) : message.messageType?.includes('CONTACT') ? (
                        <ContactInfoMessage
                           key={message._id || message.sendingStatusId}
                           {...JSON.parse(message.content)}
                        />
                     ) : (
                        <MessageItem
                           id={message._id || message.sendingStatusId}
                           item={message}
                           previousMessage={previousMessage}
                           isEmail={isEmail}
                        />
                     );
                  })}

                  <Zoom timeout={300} in={showScrollButton}>
                     <IconButton
                        className={classes.latestMessageIcon}
                        onClick={() => {
                           messageEl.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
                        }}
                        size='medium'
                        color='primary'
                     >
                        <ExpandMoreIcon fontSize='medium' />
                     </IconButton>
                  </Zoom>
               </InfiniteScroll>
            </div>
         )}
      </Grid>
   );
};

export default ChatItemsContainer;

import { gql } from '@apollo/client';
import { CHAT_CORE_FIELDS } from '../../../../fragments';

export const GET_MY_NOTIFICATIONS_QUERY = gql`
   ${CHAT_CORE_FIELDS}
   query getMyNotificationsQuery($person: ID!, $page: Int) {
      myNotifications(person: $person, page: $page) {
         data {
            notifications {
               _id
               type
               readingStatus
               bellType
               chatId {
                  ...ChatCoreFields
               }
               QnAChatId {
                  _id
                  fullName
               }
               hasSeen
               sender {
                  name
                  surname
                  _id
                  type
                  phone
                  photo
               }
               title
               messages
               createdAt
            }
            unReadCount
            totalDocs
            limit
            page
            totalPages
            hasNextPage
            nextPage
            hasPrevPage
            prevPage
            pagingCounter
            __typename
         }
         success
         code
         message
      }
   }
`;

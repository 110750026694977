import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import {
   CREATE_PLATFORM_MUTATION,
   DELETE_PLATFORM_MUTATION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/mutation';
import { GET_PLATFORMS_QUERY } from '../../../../queries/Settings/Service/GraphQL/Platform/query';
import { GET_TEAMS_QUERY } from '../../../../queries/Teams/query';
import {
   CREATED_PLATFORM_SUBSCRIPTION,
   DELETED_PLATFORM_SUBSCRIPTION,
   UPDATED_PLATFORM_SUBSCRIPTION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/subscription';
import { PLATFORM_TYPE } from '../../../../queries/Settings/Service/GraphQL/Platform/types';
import ModalContent from './modalContent';
import DeletePlatformModal from '@components/Modal/DeletePlatformModal';
import ContactUsModal from './contactUsModal';
import { WhatsappIcon } from '@assets/icons/SettingsIcons/settingsIcons';
import { useHistory, Link } from 'react-router-dom';
import EditPage from '@modules/Settings/SettingSpace/WhatsAppBusiness/EditPage';
import WhatsAppBusinessSettingsProvider from '../../../../containers/WhatsAppBusinessSettingsProvider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { statusTranslate } from './Utils';
import ActionIconButton from '../ActionIconButton';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   selector: {
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 10,
      paddingLeft: 3,
      height: 32,
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      backgroundColor: 'white',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      // fontWeight: 'bold',
   },
   button: {
      paddingTop: 10,
      width: 144,
      height: 30,
      color: 'white',
      borderRadius: 5,
      marginRight: 10,
   },
   buttonApply: {
      backgroundColor: '#487DBF',
      '&:hover': {
         backgroundColor: '#487DBF',
      },
   },
   accountNameTableTitle: {
      fontSize: '1.1rem',
   },
   accountPhoneTableTitle: {
      fontSize: '0.9rem',
   },
   noAccountContainer: {
      height: '60vh',
      padding: theme.spacing(3),
   },
   linkText: {
      color: '#487DBF',
      cursor: 'pointer',
   },
   ButtonContainer: {
      paddingLeft: 5,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
}));

const StyledTableCell = withStyles((theme) => ({
   head: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      width: 200,
      minWidth: 140,
   },
   body: {
      fontSize: 14,
   },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
   //    root: {
   //       '&:nth-of-type(odd)': {
   //          backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
   //       },
   //    },
}))(TableRow);

const QuickAnswers = ({ person, token }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [modalConfig, setModalConfig] = useState({ show: false, status: null, number: '' });
   const [contactUsModalShow, setContactUsModalShow] = useState(false);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [selectedDeleteItemId, setSelectedDeletedItemId] = useState(null);
   const [isEditing, setIsEditing] = useState(false);
   const [editingItem, setEditingItem] = useState({});

   const history = useHistory();

   const handleCreateNewRow = () => {
      setModalConfig((prev) => ({
         ...prev,
         show: true,
      }));
   };

   const handleCloseModal = (_, reason) => {
      if (reason && reason === 'backdropClick') return;
      setModalConfig((prev) => ({
         ...prev,
         show: false,
      }));
   };

   // get whatsApp phones
   const { loading: whatsAppBusinessPhonesLoading, data: whatsAppBusinessPhonesData } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.WHATS_APP_BUSINESS, customer: person.customer._id },
      fetchPolicy: 'cache-first',
      onError: (error) => {
         // on query error
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   // create person mutation function
   const [createPlatformMutation, { loading: createWhatsAppBusinessPhoneLoading }] =
      useMutation(CREATE_PLATFORM_MUTATION);

   // delete person mutation function
   const [deleteWhatsAppBusinessPhoneMutation, { loading: deleteWhatsAppBusinessPhoneLoading }] =
      useMutation(DELETE_PLATFORM_MUTATION);

   useSubscription(CREATED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '', type: PLATFORM_TYPE.WHATS_APP_BUSINESS },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               createdPlatform: {
                  data: { metaData: data },
               },
            },
         },
      }) => {
         let { getPlatforms } = client.cache.readQuery({
            query: GET_PLATFORMS_QUERY,
            variables: { type: PLATFORM_TYPE.WHATS_APP_BUSINESS, customer: person ? person.customer._id : '' },
         });
         client.cache.writeQuery({
            query: GET_PLATFORMS_QUERY,
            variables: { type: PLATFORM_TYPE.WHATS_APP_BUSINESS, customer: person ? person.customer._id : '' },
            data: {
               getPlatforms: { ...getPlatforms, data: [data?.pages, ...getPlatforms.data] },
            },
         });
      },
   });

   // subscription deleted whats app phone
   useSubscription(UPDATED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '', type: PLATFORM_TYPE.WHATS_APP_BUSINESS },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedPlatform: {
                  data: { metaData: data },
               },
            },
         },
      }) => {
         let { getPlatforms } = await client.cache.readQuery({
            query: GET_PLATFORMS_QUERY,
            variables: { customer: person ? person.customer._id : '', type: PLATFORM_TYPE.WHATS_APP_BUSINESS },
         });

         const newList = getPlatforms.data.map((item) =>
            item._id.toString() === data._id.toString() ? { ...data, ...item } : item,
         );

         client.cache.writeQuery({
            query: GET_PLATFORMS_QUERY,
            variables: { customer: person ? person.customer._id : '', type: PLATFORM_TYPE.WHATS_APP_BUSINESS },
            data: {
               getPlatforms: {
                  ...getPlatforms,
                  data: newList,
               },
            },
         });
      },
   });

   // subscription deleted whats app phone
   useSubscription(DELETED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '', type: PLATFORM_TYPE.WHATS_APP_BUSINESS },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               deletedPlatform: {
                  data: { metaData: data },
               },
            },
         },
      }) => {
         let { getPlatforms } = await client.cache.readQuery({
            query: GET_PLATFORMS_QUERY,
            variables: { customer: person ? person.customer._id : '', type: PLATFORM_TYPE.WHATS_APP_BUSINESS },
         });

         let index = getPlatforms.data.findIndex((item) => item._id.toString() === data._id.toString());
         if (index > -1) {
            const newList = await getPlatforms.data.filter((item) => item._id.toString() !== data._id.toString());
            client.cache.writeQuery({
               query: GET_PLATFORMS_QUERY,
               variables: { customer: person ? person.customer._id : '', type: PLATFORM_TYPE.WHATS_APP_BUSINESS },
               data: {
                  getPlatforms: {
                     ...getPlatforms,
                     data: newList,
                  },
               },
            });
         }
      },
   });

   const handleUpgrade = () => {
      history.push('/account/packages/all-factors');
   };

   // add data on submit
   const addWhatsAppBusinessPhoneSubmit = async (formData) => {
      // run create mutation
      createPlatformMutation({
         variables: {
            type: PLATFORM_TYPE.WHATS_APP_BUSINESS,
            metaData: {
               name: formData.name,
               phone: formData.number,
               customer: person.customer._id,
               fbManagerId: formData.fb_business_id,
               faceUrl: formData.fb_page_url,
               language: formData.language?.value,
               businessEntity: formData.entity_name,
               addressLine: formData.address,
               businessVertical: formData.business_vertical?.value,
               fbContactEmail: formData.fbContactEmail,
               city: formData.city,
               state: formData.state,
               country: formData.country,
               zipCode: formData.zip_code,
               teams: formData.teams,
            },
         },
      })
         .then((data) => {
            setModalConfig((prevState) => ({
               ...prevState,
               status: 'success',
            }));
         })
         .catch((error) => {
            setModalConfig((prevState) => ({
               ...prevState,
               status: 'error',
            }));
         });
   };

   // delete data on submit
   const handleDeleteWhatsAppBusinessPhone = async (item, deleteType) => {
      // await all results
      await deleteWhatsAppBusinessPhoneMutation({
         variables: {
            type: PLATFORM_TYPE.WHATS_APP_BUSINESS,
            metaData: {
               _id: item,
               deleteType,
            },
         },
      }) // run to delete mutation
         .then(
            ({
               data: {
                  deletePlatform: {
                     data: { metaData: data },
                  },
               },
            }) => {
               // success event
               handleCloseModal();
               enqueueSnackbar(`${data.name} başarılı bir şekilde silindi`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               });
            },
         )
         .catch((error) => {
            // error event
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const { loading: teamsLoading, data: teamsData } = useQuery(GET_TEAMS_QUERY, {
      variables: { customer: person.customer._id, pageSize: 100, page: 1 },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const loadingOrFetching =
      whatsAppBusinessPhonesLoading ||
      createWhatsAppBusinessPhoneLoading ||
      deleteWhatsAppBusinessPhoneLoading ||
      teamsLoading;

   if (whatsAppBusinessPhonesLoading) {
      return <div className='loading' />;
   }

   return (
      <WhatsAppBusinessSettingsProvider>
         <Fragment>
            {isEditing && (
               <EditPage item={editingItem} goBack={() => setIsEditing(false)} teamsData={teamsData?.getTeamList?.data?.docs} />
            )}
            {!isEditing && (
               <>
                  <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
                     <div style={{ display: 'flex' }}>
                        <Link to='/settings/channels-and-integrations' style={{ marginTop: 5 }}>
                           <ArrowBackIcon color='disabled' />
                        </Link>
                        <Typography
                           style={{ marginLeft: 10, fontSize: '1.1rem', fontWeight: 'bold', lineHeight: '35px' }}
                        >
                           WhatsApp Business API
                        </Typography>
                     </div>

                     <Grid className={classes.ButtonContainer}>
                        <Typography
                           style={{
                              marginRight: 10,
                              color:
                                 person.customer.usageWhatsappBusinessCount <
                                 person.customer.package.whatsappBusinessCount
                                    ? 'initial'
                                    : 'red',
                           }}
                        >
                           {t?.team_limit} {person.customer.usageWhatsappBusinessCount} /{' '}
                           {person.customer.package.whatsappBusinessCount}
                        </Typography>
                        <ButtonOK
                           onClick={handleCreateNewRow}
                           width={200}
                           height={40}
                           label={t['page_settings_button_add_new']}
                           fontSize='1.1rem'
                           disabled={
                              person.customer.usageWhatsappBusinessCount >=
                              person.customer.package.whatsappBusinessCount
                           }
                        />
                     </Grid>
                  </Grid>
                  <Grid>
                     {whatsAppBusinessPhonesData && whatsAppBusinessPhonesData.getPlatforms.data.length > 0 ? (
                        <TableContainer style={{ padding: 10 }}>
                           <Table className={classes.table} aria-label='customized table'>
                              <TableHead>
                                 <TableRow>
                                    <StyledTableCell style={{ minWidth: 200 }} className={classes.coloredText}>
                                       {t['page_settings_account_name']}
                                    </StyledTableCell>
                                    <StyledTableCell
                                       style={{ minWidth: 172 }}
                                       align='left'
                                       className={classes.coloredText}
                                    >
                                       {t['page_settings_phone_number']}
                                    </StyledTableCell>
                                    <StyledTableCell align='left' className={classes.coloredText}>
                                       {t['teams']}
                                    </StyledTableCell>
                                    <StyledTableCell align='left' className={classes.coloredText}>
                                       {t['settings_whatsapp_b_table_status']}
                                    </StyledTableCell>
                                    <StyledTableCell align='center' className={classes.coloredText}>
                                       {t['settings_whatsapp_templates']}
                                    </StyledTableCell>
                                    <StyledTableCell align='right'>{t['page_settings_process']}</StyledTableCell>
                                 </TableRow>
                              </TableHead>
                              <TableBody>
                                 {whatsAppBusinessPhonesData?.getPlatforms?.data.map((whatsAppBusinessPhone) => (
                                    <StyledTableRow key={whatsAppBusinessPhone._id}>
                                       <StyledTableCell component='th' scope='row' className={classes.coloredText}>
                                          <Typography className={classes.accountNameTableTitle}>
                                             {whatsAppBusinessPhone.name}
                                          </Typography>
                                       </StyledTableCell>
                                       <StyledTableCell align='left' className={classes.coloredText}>
                                          <Typography className={classes.accountPhoneTableTitle}>
                                             +{whatsAppBusinessPhone.phone}
                                          </Typography>
                                       </StyledTableCell>
                                       <StyledTableCell align='left' className={classes.coloredText}>
                                          <Typography>
                                             {whatsAppBusinessPhone.teams.length} {t.teams_with_number}
                                          </Typography>
                                       </StyledTableCell>
                                       <StyledTableCell align='left' className={classes.coloredText}>
                                          <Typography className={classes.accountPhoneTableTitle}>
                                             {statusTranslate(t, whatsAppBusinessPhone.status)}
                                          </Typography>
                                       </StyledTableCell>
                                       <StyledTableCell align='center' className={classes.coloredText}>
                                          <Typography className={classes.accountPhoneTableTitle}>
                                             <Link
                                                to={
                                                   whatsAppBusinessPhone.status === 'Approved'
                                                      ? `/settings/platforms/whatsapp-business/templatesPage/${whatsAppBusinessPhone.appId}`
                                                      : null
                                                }
                                             >
                                                <ViewCompactIcon
                                                   style={{
                                                      cursor:
                                                         whatsAppBusinessPhone.status === 'Approved'
                                                            ? 'pointer'
                                                            : 'auto',
                                                   }}
                                                   fontSize='large'
                                                   color={
                                                      whatsAppBusinessPhone.status === 'Approved'
                                                         ? 'primary'
                                                         : 'disabled'
                                                   }
                                                />
                                             </Link>
                                          </Typography>
                                       </StyledTableCell>
                                       <StyledTableCell align='right' style={{ minWidth: 132 }}>
                                          <Grid container justifyContent='flex-end'>
                                             <Grid>
                                                <ActionIconButton
                                                   onClick={() => {
                                                      setIsEditing(true);
                                                      setEditingItem(whatsAppBusinessPhone);
                                                   }}
                                                   tooltip={
                                                      whatsAppBusinessPhone.status === 'Waiting for approval'
                                                         ? t.comp_button_view
                                                         : t.comp_button_edit
                                                   }
                                                   actionType={
                                                      whatsAppBusinessPhone.status === 'Waiting for approval'
                                                         ? 'VIEW'
                                                         : 'EDIT'
                                                   }
                                                />
                                                {whatsAppBusinessPhone.status !== 'Waiting for approval' && (
                                                   <ActionIconButton
                                                      onClick={() => {
                                                         setShowDeleteModal(true);
                                                         setSelectedDeletedItemId(whatsAppBusinessPhone?._id);
                                                      }}
                                                      tooltip={t?.comp_button_delete}
                                                      actionType='DELETE'
                                                   />
                                                )}
                                             </Grid>
                                          </Grid>
                                       </StyledTableCell>
                                    </StyledTableRow>
                                 ))}
                              </TableBody>
                           </Table>
                        </TableContainer>
                     ) : (
                        <Grid>
                           <Grid
                              className={classes.noAccountContainer}
                              direction='column'
                              justifyContent='center'
                              alignItems='center'
                              container
                              spacing={2}
                           >
                              <WhatsappIcon />
                              <Grid
                                 style={{
                                    marginTop: 20,
                                 }}
                                 item
                              >
                                 <Typography variant='h5' align='center' color='inherit' className={classes.fbold}>
                                    {t?.settings_whatsapp_b_for_your_business}
                                 </Typography>
                              </Grid>

                              <Grid
                                 item
                                 style={{
                                    maxWidth: 500,
                                 }}
                              >
                                 <Typography color='textSecondary' align='center'>
                                    {t?.settings_whatsapp_b_you_will_be_able_to_reach}
                                 </Typography>
                              </Grid>
                              <Grid item>
                                 {person.customer.package.whatsappBusinessCount > 0 ? (
                                    <ButtonOK
                                       onClick={handleCreateNewRow}
                                       label={t?.settings_whatsapp_b_connect}
                                       width={200}
                                       height={40}
                                       fontSize='1.1rem'
                                    />
                                 ) : (
                                    <ButtonOK
                                       onClick={handleUpgrade}
                                       label={t?.upgrade}
                                       width={200}
                                       height={40}
                                       fontSize='1.1rem'
                                    />
                                 )}
                              </Grid>
                              <Grid item>
                                 {person.customer.package.whatsappBusinessCount > 0 ? (
                                    <Typography
                                       onClick={() => setContactUsModalShow(true)}
                                       color='link'
                                       align='center'
                                       className={classes.linkText}
                                    >
                                       {t?.settings_whatsapp_b_existing_provider_migration}
                                    </Typography>
                                 ) : (
                                    <Typography
                                       onClick={handleUpgrade}
                                       color='link'
                                       align='center'
                                       className={classes.linkText}
                                    >
                                       {t?.settings_whatsapp_b_upgrade_your_plan}
                                    </Typography>
                                 )}
                              </Grid>
                           </Grid>
                        </Grid>
                     )}
                  </Grid>
                  <Modal
                     bodyStyle={{ overflow: 'hidden', paddingInline: 0, paddingBottom: 10 }}
                     open={modalConfig.show}
                     onClose={handleCloseModal}
                     modalHeader={t['settings_whatsapp_b_submit_title']}
                  >
                     <ModalContent
                        onDelete={handleDeleteWhatsAppBusinessPhone}
                        onAccept={addWhatsAppBusinessPhoneSubmit}
                        onCancel={handleCloseModal}
                        teamsData={teamsData?.getTeamList?.data?.docs}
                        status={modalConfig.status}
                        loading={createWhatsAppBusinessPhoneLoading}
                     />
                  </Modal>

                  <Modal width={500} open={contactUsModalShow} onClose={() => setContactUsModalShow(false)}>
                     <div>
                        <ContactUsModal
                           onCancel={() => setContactUsModalShow(false)}
                           onAccept={() => window.open('mailto:support@qpien.com?subject=Qpien%20Special%20Package%20Request', '_blank')}
                        />
                     </div>
                  </Modal>

                  <DeletePlatformModal
                     isVisible={showDeleteModal}
                     setShowModal={setShowDeleteModal}
                     selectedDeleteItemId={selectedDeleteItemId}
                     onComplate={handleDeleteWhatsAppBusinessPhone}
                  />
               </>
            )}
         </Fragment>
      </WhatsAppBusinessSettingsProvider>
   );
};

export default QuickAnswers;

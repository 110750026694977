import { Grid, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import React, { useContext, useState, useEffect } from 'react';
import { Node } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import SearchSelect from 'react-select';
import CloseIcon from '@mui/icons-material/Close';
import {
   FlowBlockType,
   FlowFrequency,
   FlowInstanceMessageChannel,
   FlowNodeType,
   IFlowBlockField,
   IFlowBlockFieldRules,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
   NodeTemplates,
} from '@modules/Automation/FlowBots/types';
import { generateRandomId } from '@helper/functions';
import { useMutation } from '@apollo/client';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
   ADD_FLOW_BLOCK_MUTATION,
   CREATE_FLOW_NODE_MUTATION,
   UPDATE_BLOCK_MUTATION,
} from '@queries/Automation/mutation';
import { useDispatch } from 'react-redux';
import { LANGUAGES_EN_TR } from '@helper/language';
import {
   FacebookMessengerIcon,
   InstagramIcon,
   LiveChatIcon,
   WhatsAppBusiness,
   WhatsAppIcon,
} from '@modules/Automation/FlowBots/components/socialIcons';

import TelegramIcon from '@mui/icons-material/Telegram';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import SingleInput from '@components/_Modal/SingleInput';
import { SET_LANGUAGES } from '@store/actions/automation';

interface UserVisitePageProps {
   leftNodes: Node[];
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   isSaveTrigger: boolean;
   setIsSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   clickedNodeId: string;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
}

const UserVisitePage: React.FC<UserVisitePageProps> = ({
   leftNodes,
   setIsBtnDisable,
   isSaveTrigger,
   setIsSaveTrigger,
   clickedNodeId,
   setIsLoading,
   isLoading,
   setContentNodes,
   setLeftNodes,
   setIsNewNode,
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const useStyles = makeStyles((theme) => ({
      btn: {
         height: 36,
         width: '100%',
         border: '1px dashed #157AFA',
         borderRadius: '10px',
         background: 'transparent',
         cursor: 'pointer',
         color: '#157AFA',
         marginBottom: '10px',
      },
      row: {
         display: 'flex',
         gap: '5px',
         width: '100%',
      },
      groupHeader: {
         display: 'flex',
         justifyContent: 'space-between',
         padding: '10px',
         marginBottom: '10px',
         fontWeight: 'bold',
         borderBottom: '1px solid #E7E7E7',
         borderRadius: '5px',
      },
      groupContent: {
         display: 'flex',
         flexDirection: 'column',
         marginBottom: '10px',
         border: '1px solid #E7E7E7',
         borderRadius: '5px',
      },
      ruleSelect: {
         marginTop: '16px',
         height: '50px',
         width: '40%',
      },
      frequencySelect: {
         marginTop: '16px',
         height: '50px',
         width: '100%',
      },
      ruleSelectValue: {
         marginTop: '16px',
         height: '50px',
         width: '55%',
      },
      deleteIcon: {
         color: 'gray',
         cursor: 'pointer',
      },
      icon: {
         width: 30,
         height: 30,
      },
   }));
   const classes = useStyles();
   const OPTIONS = [
      {
         value: IFlowBlockFieldRules.Contains,
         label: t.atomation_flow_trigger_block_contains,
      },
      {
         value: IFlowBlockFieldRules.Equal,
         label: t.atomation_flow_trigger_block_equal,
      },
      {
         value: IFlowBlockFieldRules.NotEqual,
         label: t.atomation_flow_trigger_block_not_equal,
      },
      {
         value: IFlowBlockFieldRules.NotContains,
         label: t.atomation_flow_trigger_block_not_contains,
      },
   ];
   const OPTIONS_FREQUENCY = [
      {
         value: FlowFrequency.OnEveryVisit,
         label: t.atomation_flow_trigger_send_each_visit,
      },
      {
         value: FlowFrequency.OncePer24H,
         label: t.atomation_flow_send_once_per_24h,
      },
      {
         value: FlowFrequency.OnceForUinqueVisitor,
         label: t.atomation_flow_send_once_for_unique_visitor,
      },
   ];

   const [OPTIONS_LANGUAGE, SETOPTIONS_LANGUAGE] = useState<{ value: string; label: string }[]>([]);
   const [LOCATION, SETLOCATION] = useState<{ value: string; label: string }[]>([]);
   const dispatch = useDispatch();
   const country = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.locations);
   const backendBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];
   const LIST_NODE_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listNodeTemplates);
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   let { id } = useParams();
   const flowBotId = id;
   const LOCATIONS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.locations);

   useEffect(() => {
      SETLOCATION(LOCATIONS);
   }, [LOCATIONS]);

   useEffect(() => {
      let langs: any = [];
      LANGUAGES_EN_TR.map((item) => {
         langs.push({
            value: item.value,
            label: t.atomation_flow_language === 'tr' ? item.turkish : item.english,
         });
      });
      dispatch({ type: SET_LANGUAGES, payload: langs });
      SETOPTIONS_LANGUAGE(langs);
   }, []);

   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [showUD, setShowUD] = useState(false);
   const [selectedBlockId, setSelectedBlockId] = useState<string | null>();
   const [refrech, setRefrech] = useState<string | null>();
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);
   const [dataId, setDataId] = useState<{ prevData: string; newData: string }[]>([]);
   const [account, setAccount] = useState<string[]>([]);
   const [toUpdate, setToUpdate] = useState<{ _id: string }[]>([]);
   const [searchSelectKey, setSearchSelectKey] = useState<number>(0);
   const [searchSelectKeyRule, setSearchSelectKeyRule] = useState<string>('');
   const [textFieldKey, setTextFieldKey] = useState<string>('');
   const frequencyFirstField = blockState[0]?.fields[0]?.liveChatVisitFrequencyUnit;
   const PLATFORM = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.platforms);

   const [sendersData, setSendersData] = useState<{ _id: string; phone: string }[]>([]);
   const { control, watch, setValue } = useForm({
      mode: 'all',
   });

   useEffect(() => {
      setSendersData(PLATFORM);
   }, [PLATFORM]);

   useEffect(() => {
      const platformIds = blockState
         .flatMap((field) => field?.fields)
         .flatMap((field) => field?.platformIds)
         .filter((platformId) => platformId !== undefined && platformId !== null);

      const platformAccount = platformIds.length > 0 ? platformIds : account;

      const selectedSenders = sendersData?.filter((sender) => platformAccount?.includes(sender._id));
      // Create options for default values
      const defaultValues = selectedSenders
         ? selectedSenders.map((sender) => ({ label: t.page_settings_typo_platforms_live_chat, value: sender._id }))
         : null;
      setValue('senders', defaultValues);
   }, [clickedNodeId, blockState, sendersData, account]);

   const handleSenderChange = (selectedPlatform: any) => {
      setIsBtnDisable(false);
      const values = selectedPlatform?.map((pair: { value: string }) => pair.value);

      setAccount(values);

      const blockId = blockState.map((pair: { _id: string }) => pair._id);
      blockId.forEach((item) => {
         if (!toUpdate.some((tag) => tag._id === item)) {
            setToUpdate((prev) => [...prev, { _id: item }]);
         }
      });
   };

   useEffect(() => {
      setBlockState((prevBlocks) =>
         prevBlocks.map((block) => ({
            ...block,
            fields: block.fields.map((field) => ({
               ...field,
               platformIds: account,
            })),
         })),
      );
   }, [account]);

   const handleMenuOpenField = (event: React.MouseEvent<HTMLElement>, blockId: string) => {
      setAnchorEl(event.currentTarget);
      setShowUD(!showUD);
      setSelectedBlockId(blockId);
   };
   const handleMenuClose = () => {
      setAnchorEl(null);
      setShowUD(false);
   };
   const handleSelectField = (type: IFlowBlockFieldTypes) => {
      setIsBtnDisable(true);

      const platformIds = blockState
         .flatMap((field) => field?.fields)
         .flatMap((field) => field?.platformIds)
         .filter((platformId) => platformId !== undefined && platformId !== null);

      const platformAccount = platformIds.length > 0 ? platformIds : account;

      const updatedBlocks = blockState.map((block) => {
         if (block._id === selectedBlockId) {
            const newField = {
               type: type,
               rule: IFlowBlockFieldRules.Contains,
               liveChatVisitFrequencyUnit: frequencyFirstField ? frequencyFirstField : frequency.value,
               value: '',
               platformIds: platformAccount.filter((id) => id !== undefined) as string[],
            };

            return {
               ...block,
               fields: [...block.fields, newField],
            };
         }

         return block;
      });

      if (selectedBlockId) {
         setToUpdate((prev) => {
            const isIdUnique = !prev.some((item) => item._id === selectedBlockId);
            return isIdUnique ? [...prev, { _id: selectedBlockId }] : prev;
         });
      }

      setBlockState(updatedBlocks);

      handleMenuClose();
   };

   const handleDeleteField = (blockId: string, indexToDelete: number) => {
      setRefrech(generateRandomId());
      setIsBtnDisable(false);

      let updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            if (indexToDelete >= 0 && indexToDelete < block.fields.length) {
               const updatedFields = block.fields.filter((field, index) => index !== indexToDelete);

               return {
                  ...block,
                  fields: updatedFields,
               };
            }
         }

         return block;
      });

      setToUpdate((prev) => {
         // Check if the _id already exists in the array
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });

      setBlockState(updatedBlocks);
   };

   const handleSelectValueChange = (blockId: string, index: number, value: string) => {
      setIsBtnDisable(false);

      const updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            if (index >= 0 && index < block.fields.length) {
               const updatedFields = block.fields.map((field, i) => {
                  if (i === index) {
                     return {
                        ...field,
                        value: value,
                     };
                  }
                  return field;
               });

               return {
                  ...block,
                  fields: updatedFields,
               };
            }
         }

         return block;
      });

      setToUpdate((prev) => {
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });

      setBlockState(updatedBlocks);
   };

   const handleSelectRuleChange = (blockId: string, index: number, rule: IFlowBlockFieldRules) => {
      setIsBtnDisable(false);

      const updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            if (index >= 0 && index < block.fields.length) {
               const updatedFields = block.fields.map((field, i) => {
                  if (i === index) {
                     return {
                        ...field,
                        rule: rule,
                     };
                  }
                  return field;
               });

               return {
                  ...block,
                  fields: updatedFields,
               };
            }
         }

         return block;
      });

      setToUpdate((prev) => {
         // Check if the _id already exists in the array
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });

      setBlockState(updatedBlocks);
   };
   const handleSelectFrequencyChange = (blockId: string, frequency: FlowFrequency) => {
      setIsBtnDisable(false);

      const updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            const updatedFields = block.fields.map((field, i) => {
               return {
                  ...field,
                  liveChatVisitFrequencyUnit: frequency,
               };
            });

            return {
               ...block,
               fields: updatedFields,
            };
         }

         return block;
      });

      setToUpdate((prev) => {
         // Check if the _id already exists in the array
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });

      setBlockState(updatedBlocks);
   };

   const handleTextValueChange = (blockId: string, index: number, text: string, fieldCondition: string | undefined) => {
      if (text.length > 0) {
         setIsBtnDisable(false);
      } else {
         setIsBtnDisable(true);
      }

      const updatedBlocks = blockState.map((block) => {
         if (block._id === blockId) {
            if (index >= 0 && index < block.fields.length) {
               const updatedFields = block.fields.map((field, i) => {
                  if (i === index) {
                     return {
                        ...field,
                        value: text,
                     };
                  }
                  return field;
               });

               return {
                  ...block,
                  fields: updatedFields,
               };
            }
         }

         return block;
      });
      setToUpdate((prev) => {
         // Check if the _id already exists in the array
         const isIdUnique = !prev.some((item) => item._id === blockId);
         return isIdUnique ? [...prev, { _id: blockId }] : prev;
      });
      setBlockState(updatedBlocks);
   };

   //enable button
   useEffect(() => {
      const isInvalid = blockState.some((block) =>
         block.fields.some(
            (field) =>
               field.value === '' ||
               field.platformIds === null ||
               field.platformIds === undefined ||
               field.platformIds?.length === 0,
         ),
      );
      const isInvalidBlock = blockState.some((block) => block.fields.length <= 0);

      if (isInvalid || isInvalidBlock) {
         setIsBtnDisable(true);
      }
   }, [blockState]);

   const customStyles = {
      control: (base: any) => ({
         ...base,
         height: 35,
      }),
   };

   const triggerNode = leftNodes.find((node) => node.type === FlowNodeType.Triggers);

   const handleUpdateFlowBlockTarget = async (blockId: string, target: string) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  target: target,
               },
            },
            onCompleted: (res) => {
               setIsNewNode(generateRandomId());
               setIsLoading(false);
               setIsBtnDisable(true);
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlockWelcome = async (flowBlockTemplateId: String, nodeId: string, field: IFlowBlockField[]) => {
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fields: field,
               },
            },
            onCompleted: (res) => {
               setIsLoading(false);
               setIsNewNode(generateRandomId());
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   const [createFlowNode] = useMutation(CREATE_FLOW_NODE_MUTATION);
   const handleCreateNode = async (flowBotId: string, nodeTemplate: NodeTemplates[]) => {
      try {
         const templateId = nodeTemplate && nodeTemplate.find((item) => item.type === FlowNodeType.WelcomeMessage);
         if (triggerNode) {
            const position = { x: triggerNode.position.x + 200, y: triggerNode.position.y };
            await createFlowNode({
               variables: {
                  input: {
                     coordinates: position,
                     customer: person.customer._id,
                     flowBot: flowBotId,
                     templateId: templateId?._id,
                     title: t.atomation_flow_welcome_message_node,
                  },
               },
               onCompleted: (res) => {
                  const target = res.createFlowNode.data._id;
                  const blockId = blockState[0]._id;
                  handleUpdateFlowBlockTarget(blockId, target);

                  // Add default blocks in the welcome node
                  const newItem = [
                     {
                        _id: Date.now().toString(),
                        title: '',
                        fields: [
                           {
                              type: IFlowBlockFieldTypes.Text,
                              value: t.atomation_flow_default_welcome_message_block,
                           },
                        ],
                        type: FlowBlockType.SendMessage,
                     },
                     {
                        _id: Date.now().toString(),
                        title: '',
                        fields: [
                           {
                              type: IFlowBlockFieldTypes.SendMessage,
                              isButtonRequired: true,
                              requiredButtonMessage: t.atomation_flow_default_response,
                              quickReplies: [
                                 {
                                    type: 'quickReply',
                                    title: t.page_settings_typo_platforms_live_chat_get_started_form_greeting_message_1_default,
                                 },
                              ],
                           },
                        ],
                        type: FlowBlockType.SendQuickReplyMessage,
                     },
                  ];

                  if (newItem.length > 0) {
                     (async () => {
                        if (LIST_BLOCK_TEMPLATE) {
                           const templateBlockId = LIST_BLOCK_TEMPLATE.find(
                              (item: { type: FlowBlockType }) => item.type === FlowBlockType.SendMessage,
                           )?._id;

                           const templateBlockQuickReply = LIST_BLOCK_TEMPLATE.find(
                              (item: { type: FlowBlockType }) => item.type === FlowBlockType.SendQuickReplyMessage,
                           )?._id;

                           for await (let item of newItem) {
                              if (templateBlockId && templateBlockQuickReply) {
                                 const isQuickReply = item.type === FlowBlockType.SendQuickReplyMessage;

                                 if (isQuickReply) {
                                    await handleAddFlowBlockWelcome(templateBlockQuickReply, target, item.fields);
                                 } else {
                                    await handleAddFlowBlockWelcome(templateBlockId, target, item.fields);
                                 }
                              }
                           }
                        }
                     })();
                  }
               },
            });
         }
      } catch (error) {
         console.error('Error creating node:', error);
      }
   };

   // Update block mutation for a specific field
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlockFields = async (blockId: string, fields: IFlowBlockField[], isHasField: boolean) => {
      setIsLoading(true);
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields.map((field) => {
                     const filteredField = Object.fromEntries(
                        Object.entries(field).filter(([key, value]) => value !== null),
                     );

                     return filteredField;
                  }),
               },
            },
            onCompleted: async (res) => {
               //create welcome message here

               if (isHasField) {
                  setIsLoading(false);

                  const response = res.updateBlock.data;
                  const updateNodes = (prevNodes: Node[]) =>
                     prevNodes.map((node) => {
                        if (node.id === clickedNodeId) {
                           // Update the flowBlocks fields with the new value
                           return {
                              ...node,
                              data: {
                                 ...node.data,
                                 //isValidNode: true,
                                 clickedNodeId: clickedNodeId,
                                 flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                    if (block._id === blockId) {
                                       return { ...block, fields: response?.fields };
                                    }
                                    return block;
                                 }),
                              },
                           };
                        }
                        return node;
                     });
                  setContentNodes((prev) => updateNodes(prev));
                  setLeftNodes((prev) => updateNodes(prev));
               } else {
                  if (LIST_NODE_TEMPLATE && flowBotId) {
                     handleCreateNode(flowBotId, LIST_NODE_TEMPLATE);
                  }
               }
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const listBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   // default
   useEffect(() => {
      setBlockState([]);
      if (listBlocks.length > 0) {
         const data = listBlocks.map((item: IFlowBlockTemplate) => {
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });
         setBlockState(data);
      }
   }, [clickedNodeId]);

   // Save
   useEffect(() => {
      if (isSaveTrigger && !isLoading) {
         setIsSaveTrigger(false);

         const existingBlocks = blockState.filter((block) =>
            backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         const isHasField = backendBlocks[0]?.fields.length > 0;

         // Add all new blocks to the backend
         const save = async () => {
            // Update fields
            if (toUpdate.length > 0) {
               toUpdate.forEach((item) => {
                  const existingBlock = existingBlocks.find((block) => block._id === item._id);
                  if (existingBlock) {
                     setIsLoading(true);
                     handleUpdateFlowBlockFields(item._id, existingBlock.fields, isHasField);
                  }
               });
               setToUpdate([]);
            }
         };
         save();
         setToUpdate([]);
      }
   }, [isSaveTrigger]);

   const getLabelFromLocation = (value: string) => {
      const locationItem = LOCATION.find((item) => item.value === value);
      return locationItem ? locationItem.label : '';
   };
   const getLabelFromLanguages = (value: string) => {
      const Item = OPTIONS_LANGUAGE.find((item) => item.value === value);
      return Item ? Item.label : '';
   };

   useEffect(() => {
      setSearchSelectKey((prevKey) => prevKey + 1);
      setSearchSelectKeyRule((prevKey) => prevKey + 'r1');
      setTextFieldKey((prevKey) => prevKey + 't1');
   }, [country, clickedNodeId, refrech]);

   const [frequency, setFrequency] = useState<{ label: string; value: FlowFrequency }>({
      label: t.atomation_flow_send_once_for_unique_visitor,
      value: FlowFrequency.OnceForUinqueVisitor,
   });

   const height = 12;
   return (
      <Grid>
         <Grid>
            {blockState.map((item, index) => (
               <div>
                  <Grid key={index} className={classes.groupContent}>
                     <Grid className={classes.groupHeader}>
                        <Grid style={{ display: 'flex', gap: '5px' }}>
                           <div>
                              <div>
                                 <LiveChatIcon selected={true} className={classes.icon} />
                              </div>
                              {blockState[0]?.channelType === FlowInstanceMessageChannel.Facebook ? (
                                 <FacebookMessengerIcon selected={true} className={classes.icon} />
                              ) : blockState[0]?.channelType === FlowInstanceMessageChannel.Instagram ? (
                                 <InstagramIcon selected={true} className={classes.icon} />
                              ) : blockState[0]?.channelType === FlowInstanceMessageChannel.Telegram ? (
                                 <div
                                    style={{
                                       width: 30,
                                       height: 30,
                                       backgroundColor: '#25A3E1',
                                       borderRadius: '45%',
                                    }}
                                 >
                                    <TelegramIcon
                                       className={classes.icon}
                                       style={{ color: '#fff', margin: '2px 2px 0 0' }}
                                    />
                                 </div>
                              ) : blockState[0]?.channelType === FlowInstanceMessageChannel.WhatsappBusiness ? (
                                 <WhatsAppBusiness selected={true} className={classes.icon} />
                              ) : blockState[0]?.channelType === FlowInstanceMessageChannel.WhatsappWeb ? (
                                 <WhatsAppIcon selected={true} className={classes.icon} />
                              ) : (
                                 ''
                              )}
                           </div>

                           <Typography style={{ marginTop: '6px', fontWeight: 'bold' }}>
                              {t.atomation_flow_user_visit_a_page}
                           </Typography>
                        </Grid>
                     </Grid>
                     {blockState[0]?.fields.length > 0 && (
                        <div>
                           {item.fields.map((field, index) => (
                              <Grid style={{ padding: '0 10px' }}>
                                 <Grid style={{ marginBottom: '-10px' }}>
                                    {field.type === IFlowBlockFieldTypes.BrowserLanguageCheck
                                       ? t.atomation_flow_condition_browser_language
                                       : field.type === IFlowBlockFieldTypes.BrowserCountryCheck
                                       ? t.atomation_flow_condition_browser_country
                                       : field.type === IFlowBlockFieldTypes.PageUrlCheck
                                       ? t.atomation_flow_condition__page_url
                                       : ''}
                                 </Grid>
                                 <Grid className={classes.row}>
                                    <SearchSelect
                                       key={searchSelectKeyRule}
                                       options={OPTIONS}
                                       className={classes.ruleSelect}
                                       menuPosition='fixed'
                                       placeholder={<div>{t.atomation_flow_select}</div>}
                                       styles={customStyles}
                                       defaultValue={
                                          field.rule
                                             ? {
                                                  label:
                                                     field.rule === IFlowBlockFieldRules.Contains
                                                        ? t.atomation_flow_trigger_block_contains
                                                        : field.rule === IFlowBlockFieldRules.Equal
                                                        ? t.atomation_flow_trigger_block_equal
                                                        : field.rule === IFlowBlockFieldRules.NotContains
                                                        ? t.atomation_flow_trigger_block_not_contains
                                                        : t.atomation_flow_trigger_block_not_equal,
                                                  value: field.rule,
                                               }
                                             : null
                                       }
                                       onChange={(e) => {
                                          if (e) {
                                             handleSelectRuleChange(item._id, index, e.value);
                                          }
                                       }}
                                    />

                                    {field.type === IFlowBlockFieldTypes.BrowserCountryCheck ||
                                    field.type === IFlowBlockFieldTypes.BrowserLanguageCheck ? (
                                       <SearchSelect
                                          key={searchSelectKey}
                                          options={
                                             field.type === IFlowBlockFieldTypes.BrowserLanguageCheck
                                                ? OPTIONS_LANGUAGE
                                                : field.type === IFlowBlockFieldTypes.BrowserCountryCheck
                                                ? LOCATION
                                                : OPTIONS
                                          }
                                          className={classes.ruleSelectValue}
                                          menuPosition='fixed'
                                          placeholder={<div>{t.atomation_flow_select}</div>}
                                          styles={customStyles}
                                          defaultValue={
                                             field.value
                                                ? {
                                                     label:
                                                        field.value &&
                                                        field.type === IFlowBlockFieldTypes.BrowserCountryCheck
                                                           ? getLabelFromLocation(field.value)
                                                           : field.value &&
                                                             field.type === IFlowBlockFieldTypes.BrowserLanguageCheck
                                                           ? getLabelFromLanguages(field.value)
                                                           : field.value,
                                                     value: field.value,
                                                  }
                                                : null
                                          }
                                          onChange={(e) => {
                                             if (e) {
                                                handleSelectValueChange(item._id, index, e.value);
                                             }
                                          }}
                                       />
                                    ) : (
                                       <TextField
                                          key={textFieldKey}
                                          name='value'
                                          placeholder='value'
                                          defaultValue={field.value}
                                          inputProps={{
                                             style: {
                                                height,
                                             },
                                          }}
                                          variant='outlined'
                                          style={{ width: '55%' }}
                                          onChange={(e) =>
                                             handleTextValueChange(item._id, index, e.target.value, field.type)
                                          }
                                       />
                                    )}

                                    <CloseIcon
                                       onClick={() => handleDeleteField(item._id, index)}
                                       style={{ marginTop: '1.5rem' }}
                                       className={classes.deleteIcon}
                                    />
                                 </Grid>
                              </Grid>
                           ))}
                        </div>
                     )}
                     {blockState[0].fields.length < 10 && (
                        <Grid style={{ padding: '10px', borderBottom: '1px solid #E7E7E7' }}>
                           <button className={classes.btn} onClick={(e) => handleMenuOpenField(e, item._id)}>
                              + {t.atomation_flow_rule_add}
                           </button>
                        </Grid>
                     )}

                     <Grid style={{ padding: '0 10px', marginTop: '10px' }}>
                        <Grid style={{ marginBottom: '-10px' }}>{t.atomation_flow_trigger_frequency}</Grid>
                        <Grid className={classes.row}>
                           <SearchSelect
                              key={searchSelectKeyRule}
                              options={OPTIONS_FREQUENCY}
                              className={classes.frequencySelect}
                              menuPosition='fixed'
                              placeholder={<div>{t.atomation_flow_select}</div>}
                              styles={customStyles}
                              defaultValue={
                                 blockState[0]?.fields[0]?.liveChatVisitFrequencyUnit
                                    ? {
                                         label:
                                            blockState[0].fields[0].liveChatVisitFrequencyUnit ===
                                            FlowFrequency.OnEveryVisit
                                               ? t.atomation_flow_trigger_send_each_visit
                                               : blockState[0].fields[0].liveChatVisitFrequencyUnit ===
                                                 FlowFrequency.OncePer24H
                                               ? t.atomation_flow_send_once_per_24h
                                               : t.atomation_flow_send_once_for_unique_visitor,
                                         value: blockState[0].fields[0].liveChatVisitFrequencyUnit,
                                      }
                                    : {
                                         label: frequency.label,
                                         value: frequency.value,
                                      }
                              }
                              onChange={(e) => {
                                 if (e) {
                                    handleSelectFrequencyChange(item._id, e.value);
                                 }
                              }}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
               </div>
            ))}
         </Grid>
         <SingleInput
            name='senders'
            type='senders'
            label={t['automation_account']}
            placeholder={<div>{t.atomation_flow_select}</div>}
            isSelect
            isMulti
            isClearable
            autoHeight
            control={control}
            maxMenuHeight={190}
            selectOptions={sendersData?.map(({ _id }) => ({
               value: _id,
               label: t.page_settings_typo_platforms_live_chat,
            }))}
            value={watch('senders')}
            onChange={(sender: { label: string; value: string }) => {
               handleSenderChange(sender);
            }}
         />
         <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{ marginTop: 10 }}
            PaperProps={{
               style: {
                  width: '305px',
                  borderRadius: '2px',
               },
            }}
         >
            <MenuItem onClick={() => handleSelectField(IFlowBlockFieldTypes.BrowserCountryCheck)}>
               {t.atomation_flow_condition_browser_country}
            </MenuItem>
            <MenuItem onClick={() => handleSelectField(IFlowBlockFieldTypes.BrowserLanguageCheck)}>
               {t.atomation_flow_condition_browser_language}
            </MenuItem>
            <MenuItem onClick={() => handleSelectField(IFlowBlockFieldTypes.PageUrlCheck)}>
               {t.atomation_flow_condition__page_url}
            </MenuItem>
         </Menu>
      </Grid>
   );
};

export default UserVisitePage;

import React, { memo, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { NodeProps, useStore } from 'reactflow';
import { getRelativeNodesBounds } from './utils';
import useDetachNodes from './useDetachNodes';
import { SET_CLICKED_NODE_ID } from '@store/actions/automation';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
   groupNode: {
      backgroundColor: 'rgba(230, 238, 249, 0.7)',
      borderRadius: '5px',
   },
   groupNodeHovered: {
      backgroundColor: 'rgba(230, 238, 249, 0.7)',
      border: '1px solid #157cfc',
      borderRadius: '5px',
   },
});

function GroupNode({ id }: NodeProps) {
   const classes = useStyles();
   const [isHover, setIsHover] = useState(false);
   const { minWidth, minHeight, childNodes } = useStore((store) => {
      const childNodes = Array.from(store.nodeInternals.values()).filter((n) => n.parentNode === id && n.id !== id);
      const rect = getRelativeNodesBounds(childNodes);
      const padding = 5;
      return {
         minWidth: rect.x + rect.width + padding,
         minHeight: rect.y + rect.height + padding,
         childNodes: childNodes,
      };
   }, isEqual);

   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   const dispatch = useDispatch();
   const handleClicked = () => {
      if (botStatus === 'draft') {
         dispatch({ type: SET_CLICKED_NODE_ID, payload: id });
      }
   };

   // Detach all groups with only one child
   const chilNoseSize = childNodes.length;
   const detachNodes = useDetachNodes();
   useEffect(() => {
      if (chilNoseSize === 1) {
         const id = childNodes.map((cn) => cn.id);
         detachNodes([...id]);
      }
   }, [chilNoseSize]);

   useEffect(() => {
      setIsHover(clickedId === id);
   }, [clickedId]);

   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (clickedId !== id ? setIsHover(false) : setIsHover(true))}
         className={isHover ? classes.groupNodeHovered : classes.groupNode}
         style={{
            minHeight: minHeight,
            minWidth: minWidth,
         }}
         onClick={handleClicked}
      ></div>
   );
}

type IsEqualCompareObj = {
   minWidth: number;
   minHeight: number;
};

function isEqual(prev: IsEqualCompareObj, next: IsEqualCompareObj): boolean {
   return prev.minWidth === next.minWidth && prev.minHeight === next.minHeight;
}

export default memo(GroupNode);

import { LanguageContext } from '@helper/locale/langContext';
import {
   FormControlLabel,
   Grid,
   Table,
   TableBody,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   Menu,
   MenuItem,
   CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import BarChartIcon from '@mui/icons-material/BarChart';
import React, { Fragment, useContext, useState, useCallback, useEffect } from 'react';
import { StyledTableCell, StyledTableRow } from '../../FlowBots/components/tableItems';
import CustomSwitch from '@components/Switch';
import Modal from '@components/Modal/modal';
import DeleteFow from '@modules/Automation/FlowBots/components/DeleteFlow';
import InfiniteScroll from 'react-infinite-scroll-component';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_PAGINATED_ECOMMERCE_RULES } from '@queries/Automation/query';

import { formatDate } from '@helper/functions';
import { useHistory } from 'react-router-dom';
import { FlowBotDataItem } from '@modules/Automation/FlowBots/types';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import EcommerceRuleModal from '@modules/Automation/FlowBots/components/EcommerceRuleModal';
import {
   CREATE_ECOMMERCE_RULE_STATUS,
   DELETE_ECOMMERCE_RULE_STATUS,
   UPDATE_ECOMMERCE_RULE,
   UPDATE_ECOMMERCE_RULE_STATUS,
} from '@queries/Automation/mutation';
import { SET_BOT, SET_RULE_ID, SET_RULE_STATUS } from '@store/actions/automation';

const useStyles = makeStyles((theme: any) => ({
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   title: {
      cursor: 'pointer',
   },
   tableHead: {
      display: 'flex',
      width: '100%',
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
   },
   contentFirstBox: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '100%',

      [theme.breakpoints.down('md')]: {
         gridTemplateColumns: '1fr 2fr',
      },
      [theme.breakpoints.down('sm')]: {
         gridTemplateColumns: '1fr 2fr',
      },
   },
   contentSecondBox: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 2fr 0.2fr',
   },
   container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100vh - 10rem)',
      textAlign: 'center',
      gap: '1rem',
   },
   text: {
      margin: '0 25rem',
      [theme.breakpoints.down('md')]: {
         margin: '0 1rem',
      },
      [theme.breakpoints.down('sm')]: {
         margin: '0 1rem',
      },
   },
   icon: {
      width: 25,
      height: 25,
   },
}));

interface ListProps {
   selectedItem: FlowBotDataItem | undefined;
   setSelectedItem: React.Dispatch<React.SetStateAction<FlowBotDataItem | undefined>>;
   setInfiniteData: React.Dispatch<
      React.SetStateAction<{
         hasMore: boolean;
         totalLength: number;
         data: FlowBotDataItem[];
      }>
   >;
   getInfiniteData: {
      hasMore: boolean;
      totalLength: number;
      data: FlowBotDataItem[];
   };
}

const List: React.FC<ListProps> = ({ setSelectedItem, selectedItem, getInfiniteData, setInfiniteData }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar } = useSnackbar();

   const [showModal, setShowModal] = useState(false);
   const [contextMenuAnchor, setContextMenuAnchor] = useState(null);

   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [isCreate, setIsCreate] = useState(false);
   const [showListFlow, setShowListFlow] = useState(true);
   const [flowBotDataNoFiltered, setFlowBotDataNoFiltered] = useState<FlowBotDataItem[]>([]);
   const [filter, setFilter] = useState<string>('');

   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   const history = useHistory();
   const dispatch = useDispatch();

   const defaultFlowBotQuery = {
      page: 1,
      pageSize: 13,
      customer: person.customer._id,
   };

   const [flowBotQuery, setFlowBotQuery] = useState(defaultFlowBotQuery);

   useEffect(() => {
      setFlowBotQuery((prevFlowBotQuery) => ({
         ...prevFlowBotQuery,
         search: filter,
      }));
      return () => {};
   }, [filter]);

   const [getRules] = useLazyQuery(GET_PAGINATED_ECOMMERCE_RULES);
   useEffect(() => {
      getRules({
         variables: { input: flowBotQuery },
         fetchPolicy: 'network-only',
         onCompleted: (res) => {
            if (filter && filter.length > 0) {
               setInfiniteData({
                  ...getInfiniteData,
                  data: [res.getPaginatedECommerceRules.data.docs].flat(),
                  hasMore: res.getPaginatedECommerceRules.data.hasNextPage,
                  totalLength: res.getPaginatedECommerceRules.data.totalRecords,
               });
               setShowListFlow(res.getPaginatedECommerceRules.data.totalRecords > 0 ? true : false);

               // setFlowBotData([...getInfiniteData.data, res.getPaginatedECommerceRules.data.docs].flat());
               setFlowBotDataNoFiltered([]);
            } else {
               setInfiniteData({
                  ...getInfiniteData,
                  data: [...flowBotDataNoFiltered, res.getPaginatedECommerceRules.data.docs].flat(),
                  hasMore: res.getPaginatedECommerceRules.data.hasNextPage,
                  totalLength: res.getPaginatedECommerceRules.data.totalRecords,
               });
               setShowListFlow(res.getPaginatedECommerceRules.data.totalRecords > 0 ? true : false);

               // setFlowBotData([...flowBotDataNoFiltered, res.getPaginatedECommerceRules.data.docs].flat());
               setFlowBotDataNoFiltered([...flowBotDataNoFiltered, res.getPaginatedECommerceRules.data.docs].flat());
            }
         },
      });
   }, [flowBotQuery]);

   const [updateStatus] = useMutation(UPDATE_ECOMMERCE_RULE_STATUS);
   const [updateRule] = useMutation(UPDATE_ECOMMERCE_RULE);
   const [createRule] = useMutation(CREATE_ECOMMERCE_RULE_STATUS);

   const handleToggleStatusClicked = async (itemId: String, currentStatus: Boolean) => {
      try {
         const response = await updateStatus({
            variables: {
               input: {
                  _id: itemId,
                  status: !currentStatus,
                  customer: person.customer._id,
               },
            },
         });

         dispatch({ type: SET_RULE_STATUS, payload: '' });

         const newRuleData = response.data.updateECommerceRulesStatus.data;
         const newRuleId = newRuleData._id;

         const existingRuleIndex = getInfiniteData.data.findIndex((rule) => rule._id === newRuleId);

         if (existingRuleIndex !== -1) {
            const updatedData = [...getInfiniteData.data];
            updatedData[existingRuleIndex] = newRuleData;

            setInfiniteData({
               ...getInfiniteData,
               data: updatedData,
            });
         }
      } catch (error) {
         console.error('Error updating status:', error);
         enqueueSnackbar((error as { message: string }).message, {
            variant: 'error',
            autoHideDuration: 2000,
         });
      }
   };

   const handleRenameSubmit = async (newTitle: String) => {
      try {
         if (selectedItem) {
            const response = await updateRule({
               variables: {
                  input: {
                     _id: selectedItem._id,
                     title: newTitle,
                     type: 'ABANDONED_CHECKOUT',
                     customer: person.customer._id,
                  },
               },
            });

            const newRuleData = response.data.updateECommerceRules.data;
            const newRuleId = newRuleData._id;

            const existingRuleIndex = getInfiniteData.data.findIndex((rule) => rule._id === newRuleId);

            if (existingRuleIndex !== -1) {
               const updatedData = [...getInfiniteData.data];
               updatedData[existingRuleIndex] = newRuleData;

               setInfiniteData({
                  ...getInfiniteData,
                  data: updatedData,
               });
            }

            setShowModal(false);
         }
      } catch (error) {
         console.error('Error renaming flow:', error);
      }
   };

   const handleCreateFlow = async (name: string, trigger: string) => {
      try {
         await createRule({
            variables: {
               input: {
                  customer: person.customer._id,
                  title: name,
                  type: trigger,
               },
            },
            onCompleted: (res) => {
               const rule = res.createECommerceRules.data;
               dispatch({
                  type: SET_RULE_STATUS,
                  payload: rule.status === true ? 'published' : 'draft',
               });
               dispatch({ type: SET_RULE_ID, payload: rule._id });
               dispatch({ type: SET_BOT, payload: rule?.title });
               history.push(`/automation/e-commerce/${rule._id}`);
            },
         }).then((e) => {
            setInfiniteData({
               ...getInfiniteData,
               data: [e.data.createECommerceRules.data, ...getInfiniteData.data].flat(),
            });
         });
      } catch (error) {
         console.error('Error creating flow bot:', error);
         enqueueSnackbar((error as { message: string }).message, {
            variant: 'error',
            autoHideDuration: 2000,
         });
      }
   };

   const [deleteRule] = useMutation(DELETE_ECOMMERCE_RULE_STATUS);
   const handleDeleteSubmit = async () => {
      try {
         if (selectedItem) {
            await deleteRule({
               variables: {
                  input: {
                     _id: selectedItem._id,
                     customer: person.customer._id,
                  },
               },
            });

            setInfiniteData((prevData) => {
               const updatedData = prevData.data.filter((item) => item._id !== selectedItem._id);

               return {
                  ...prevData,
                  data: updatedData,
               };
            });

            setShowListFlow(false);
         }
      } catch (error) {
         console.error('Error deleting rule:', error);
         enqueueSnackbar((error as { message: string }).message, {
            variant: 'error',
            autoHideDuration: 2000,
         });
      }
   };

   const [loading, setLoading] = useState(false);
   const handleNextPage = () => {
      setLoading(true);
      setTimeout(() => {
         getInfiniteData.hasMore &&
            setFlowBotQuery({
               ...flowBotQuery,
               page: flowBotQuery.page + 1,
            });
         setLoading(false);
      }, 500);
   };

   const handleContextMenuOpen = (event: any, item: FlowBotDataItem) => {
      event.preventDefault();
      setContextMenuAnchor(event.currentTarget);
      setSelectedItem(item);
   };

   const handleContextMenuClose = () => {
      setContextMenuAnchor(null);
   };
   const handleRename = () => {
      setIsCreate(false);
      setShowModal(true);
      setContextMenuAnchor(null);
   };

   const handleDelete = () => {
      setContextMenuAnchor(null);
      setShowDeleteModal(true);
   };

   const handleOpenModal = () => {
      setIsCreate(true);
      setShowModal(true);
      setValue(undefined);
   };
   const closeModal = () => {
      setShowModal(false);
   };

   const handleTitleClicked = async (item: FlowBotDataItem) => {
      dispatch({ type: SET_BOT, payload: item?.title });
      dispatch({ type: SET_RULE_ID, payload: item?._id });
      dispatch({ type: SET_RULE_STATUS, payload: item?.status === true ? 'published' : 'draft' });
      setSelectedItem(item);
      await history.push(`/automation/e-commerce/${item._id}`);
   };

   // screen size and truncate functions
   const getScreenSize = () => {
      const width = window.innerWidth;
      if (width >= 600 && width < 1280) {
         return 17;
      } else {
         return 70;
      }
   };

   const truncateString = (str: string, maxLength: number) => {
      if (str?.length <= maxLength) {
         return str;
      } else {
         return str?.slice(0, maxLength) + '...';
      }
   };
   const screenSize = getScreenSize();

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' justifyContent='center' xs={12}>
            <CircularProgress size={20} color='inherit' />
         </Grid>
      );
   }, []);

   const [value, setValue] = useState<{ title: string; trigger: string }>();

   useEffect(() => {
      if (selectedItem) {
         const val = {
            title: selectedItem.title,
            trigger: 'ABANDONED_CHECKOUT',
         };
         setValue(val);
      }
   }, [selectedItem]);

   const handleClickReport = () => {
      window.location.replace(`${window.location.origin}/statistics/abandoned-carts`);
   };

   return (
      <Fragment>
         {showListFlow ? (
            <Grid style={{ padding: '0.5rem', maxHeight: '750px', overflow: 'hidden' }}>
               <InfiniteScroll
                  dataLength={getInfiniteData?.data?.length}
                  next={handleNextPage}
                  hasMore={getInfiniteData?.hasMore}
                  loader={loading && <Loader />}
                  scrollableTarget='scrollableDiv'
               >
                  <TableContainer style={{ width: '100%' }}>
                     <Table
                        aria-label='customized table'
                        style={{
                           display: 'flex',
                           flexDirection: 'column',
                           width: '100%',
                        }}
                     >
                        <TableHead className={classes.tableHead}>
                           <TableRow className={classes.contentFirstBox}>
                              <StyledTableCell align='left'>
                                 {t['page_settings_typo_survey_livechat_name']}
                              </StyledTableCell>
                              <div className={classes.contentSecondBox} style={{ paddingRight: '2.5rem' }}>
                                 <StyledTableCell align='left'>{t.automation_reports} </StyledTableCell>
                                 <StyledTableCell align='right'>{t['page_chat_typo_status']} </StyledTableCell>
                                 <StyledTableCell align='right'>{t['page_settings_typo_update_date']}</StyledTableCell>
                                 <StyledTableCell align='right'></StyledTableCell>
                              </div>
                           </TableRow>
                        </TableHead>
                        <TableBody
                           id='scrollableDiv'
                           style={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: 'column',
                              height: 680,
                              overflow: 'auto',
                           }}
                        >
                           {getInfiniteData.data?.map((item: FlowBotDataItem) => (
                              <StyledTableRow key={item._id} className={classes.contentFirstBox}>
                                 <StyledTableCell component='th' scope='row'>
                                    <Typography className={classes.coloredText}>
                                       <span className={classes.title} onClick={() => handleTitleClicked(item)}>
                                          {truncateString(item.title, screenSize)}
                                       </span>
                                    </Typography>
                                 </StyledTableCell>
                                 <div className={classes.contentSecondBox}>
                                    <StyledTableCell align='left' component='th' scope='row'>
                                       <div style={{ display: 'flex', gap: '10px' }}>
                                          <BarChartIcon
                                             style={{ color: '#0A81FD', cursor: 'pointer' }}
                                             onClick={handleClickReport}
                                          />
                                          <Typography
                                             style={{ color: '#838383', cursor: 'pointer' }}
                                             onClick={handleClickReport}
                                          >
                                             {t.automation_view_reports}
                                          </Typography>
                                       </div>
                                    </StyledTableCell>
                                    <StyledTableCell align='right' component='th' scope='row'>
                                       <FormControlLabel
                                          control={<CustomSwitch checked={item.status} />}
                                          onChange={() => handleToggleStatusClicked(item._id, item.status)}
                                          label=''
                                          labelPlacement='start'
                                       />
                                    </StyledTableCell>
                                    <StyledTableCell align='right' component='th' scope='row'>
                                       <Typography>
                                          {formatDate(t.atomation_flow_bot_date_format, item.updatedAt)}
                                       </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align='right'>
                                       <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                                          <MoreVertIcon onClick={(e) => handleContextMenuOpen(e, item)} />
                                       </Grid>
                                    </StyledTableCell>
                                 </div>
                              </StyledTableRow>
                           ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </InfiniteScroll>
            </Grid>
         ) : (
            <Grid>
               <Grid className={classes.container}>
                  <Typography style={{ fontWeight: 'bold' }}>
                     {t['automation_flow_you_dont_have_any_e_commerce_rule']}
                  </Typography>
                  <Typography className={classes.text}>{t['automation_flow_about_e_commerce_rule']}</Typography>
                  <Grid>
                     <ButtonOK
                        label={t.automation_flow_create_new_plus}
                        style={{
                           color: 'white',
                           backgroundColor: '#157CFC',
                        }}
                        onClick={handleOpenModal}
                     />
                  </Grid>
               </Grid>
            </Grid>
         )}
         <Menu anchorEl={contextMenuAnchor} open={Boolean(contextMenuAnchor)} onClose={handleContextMenuClose}>
            <MenuItem onClick={handleRename}>{t['automation_flow_rename']}</MenuItem>
            <MenuItem onClick={handleDelete}>
               <span style={{ color: 'red' }}> {t['page_chat_typo_reject']}</span>
            </MenuItem>
         </Menu>

         <Modal open={showModal} onClose={closeModal} modalHeader={t.automation_e_commerce_new_flow}>
            <EcommerceRuleModal
               onSubmit={isCreate ? handleCreateFlow : handleRenameSubmit}
               closeModal={closeModal}
               isCreate={isCreate}
               value={value}
            />
         </Modal>
         <DeleteFow
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            onComplate={handleDeleteSubmit}
            text={t.automation_flow_delete_warning}
         />
      </Fragment>
   );
};

export default List;

import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { Fragment, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { CHANGE_PROJECT_VERSION, SIGN_IN } from '@store/actions/person';
import { PROJECT_VERSION } from '@helper/config';
import { LanguageContext } from '@helper/locale/langContext';
import { persistor } from '@store/store';
import Input from '@components/InputField';
import { SIGN_IN_PERSON_MUTATION } from '../../../queries/Person/Service/GraphQL/mutation';
import Button from '@components/Button';

const useStyles = makeStyles((theme) => ({
   primaryLink: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      marginTop: 10,
      fontSize: '0.8rem',
   },
   secondaryLink: {
      color: theme.palette.grey['800'],
   },
   helperText: {
      color: theme.palette.grey['600'],
      paddingTop: '16px',
      margin: '10px',
      fontSize: '0.9rem',
   },
}));

const SignIn = () => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [errorState, setErrorState] = useState(false);
   const classes = useStyles();
   const dispatch = useDispatch();
   const [signIn, { loading: mutationLoading }] = useMutation(SIGN_IN_PERSON_MUTATION, { fetchPolicy: 'no-cache' });

   const {
      handleSubmit,
      control,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         email: '',
         password: '',
      },
   });

   // handle login submit
   const loginSubmit = async (state) => {
      signIn({ variables: { email: state.email, password: state.password } })
         .then(
            async ({
               data: {
                  personSignIn: {
                     data: { token, person },
                  },
               },
            }) => {
               // set store project version
               dispatch({
                  type: CHANGE_PROJECT_VERSION,
                  payload: { projectVersion: PROJECT_VERSION },
               });
               // set store token
               dispatch({ type: SIGN_IN, payload: { token, person } });
               await persistor.flush();
            },
         )
         .catch((error) => {
            setErrorState(error.message);
            setTimeout(() => {
               setErrorState(false);
            }, 5000);
         });
   };
   return (
      <Grid direction='row' justifyContent='space-between' style={{ minWidth: '30%', marginTop: '7rem' }}>
         {mutationLoading ? <div className='loading' /> : null}
         <Fragment>
            <Typography
               style={{
                  fontSize: '1.45rem',
                  marginBottom: 15,
                  color: 'black',
                  fontWeight: 500,
                  paddingBottom: '.5rem',
                  borderBottom: '1px solid #EBEBEB',
               }}
            >
               {t?.log_into_your_account}
            </Typography>

            <form onSubmit={handleSubmit(loginSubmit)} className={classes.form}>
               <Grid container spacing={3} style={{ marginTop: '1rem' }}>
                  <Grid item xs={12}>
                     <Input
                        name='email'
                        control={control}
                        label={t['pre_load_input_label_email']}
                        rules={{
                           required: t['email_required'],
                           pattern: {
                              value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: t['email_valid'],
                           },
                        }}
                     />
                  </Grid>
                  <Grid item xs={12}>
                     <Input
                        name='password'
                        label={t['pre_load_input_label_password']}
                        type='password'
                        control={control}
                        rules={{
                           required: t['password_required'],
                           minLength: {
                              value: 6,
                              message: t['password_must_be_6_chars'],
                           },
                        }}
                     />
                  </Grid>
                  {errorState && (
                     <Typography
                        style={{
                           marginTop: -25,
                           fontSize: '0.9rem',
                           color: 'red',
                        }}
                     >
                        {errorState}
                     </Typography>
                  )}
               </Grid>
               <Grid container justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item container justifyContent='flex-end'>
                     <Link to='/forgot-password' className={classes.primaryLink}>
                        {t['pre_load_sign_in_forget_pass']}
                     </Link>
                  </Grid>
               </Grid>
               <Button type='submit' label={t?.login} isPrimary disabled={!isValid} />
            </form>
         </Fragment>
      </Grid>
   );
};

export default SignIn;

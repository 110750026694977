import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { useSnackbar } from 'notistack';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
   CLEAR_ALL_NOTIFICATIONS_MUTATION,
   DELETE_ALL_NOTIFICATIONS_MUTATION,
   SET_NOTIFICATION_SEEN_STATUS_MUTATION,
} from '../../../queries/Notifications/Service/GraphQL/Notifications/mutation';
import { GET_MY_NOTIFICATIONS_QUERY } from '../../../queries/Notifications/Service/GraphQL/Notifications/query';
import { MY_NOTIFICATION_SUBSCRIPTION } from '../../../queries/Notifications/Service/GraphQL/Notifications/subscription';
import { useHistory } from 'react-router-dom';
import { getSoundByName } from '@assets/beeps';
import useSound from 'use-sound';
const initialState = {
   notifications: [],
   totalDocs: 0,
   limit: 20,
   page: 1,
   totalPages: 1,
   hasNextPage: false,
   nextPage: null,
   hasPrevPage: false,
   prevPage: null,
   pagingCounter: 1,
   unReadCount: 0,
};

const useNotifications = (triggerSubs) => {
   const person = useSelector((state) => state.personReducer.person);
   const token = useSelector((state) => state.personReducer.token);
   const customer = useSelector((state) => state.personReducer.person.customer);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [playNewNotification] = useSound(getSoundByName(customer.newChatBellName));
   const [playTimeout] = useSound(getSoundByName(customer.timeoutChatBellName));
   const [playTransferred] = useSound(getSoundByName(customer.transferredChatBellName));
   const [playQnANewNotification] = useSound(getSoundByName(customer.newQnAChatBellName));
   const [playQnATimeout] = useSound(getSoundByName(customer.timeoutQnAChatBellName));
   const [playQnATransferred] = useSound(getSoundByName(customer.transferredQnAChatBellName));
   const history = useHistory();

   const [setNotificationSeenStatusMutation, { loading: changeNotificationStatusLoading }] = useMutation(
      SET_NOTIFICATION_SEEN_STATUS_MUTATION,
      {
         update: (
            cache,
            {
               data: {
                  setSeenStatus: {
                     data: { _id },
                  },
               },
            },
         ) => {
            const { myNotifications } = cache.readQuery({
               query: GET_MY_NOTIFICATIONS_QUERY,
               variables: {
                  person: person._id,
                  page: 1,
               },
            });

            const newDocs = myNotifications?.data?.notifications?.map((notification) =>
               notification._id === _id ? { ...notification, hasSeen: true } : notification,
            );

            if (notificationsData?.myNotifications?.data?.unReadCount > 0) {
               cache.writeQuery({
                  query: GET_MY_NOTIFICATIONS_QUERY,
                  variables: {
                     person: person._id,
                     page: 1,
                  },
                  data: {
                     myNotifications: {
                        ...myNotifications,
                        data: {
                           ...myNotifications.data,
                           notifications: newDocs,
                           unReadCount: notificationsData?.myNotifications?.data?.unReadCount - 1,
                        },
                     },
                  },
               });
            }
         },
      },
   );

   const [clearAllNotificationsMutation, { loading: clearAllNotificationsLoading }] = useMutation(
      CLEAR_ALL_NOTIFICATIONS_MUTATION,
      {
         update: (cache, { data }) => {
            const { myNotifications } = cache.readQuery({
               query: GET_MY_NOTIFICATIONS_QUERY,
               variables: {
                  person: person._id,
                  page: 1,
               },
            });

            const newArr = myNotifications?.data?.notifications?.map((notification) => ({
               ...notification,
               hasSeen: true,
            }));

            cache.writeQuery({
               query: GET_MY_NOTIFICATIONS_QUERY,
               variables: {
                  person: person._id,
                  page: 1,
               },
               data: {
                  myNotifications: {
                     ...myNotifications,
                     data: {
                        ...myNotifications?.data,
                        notifications: newArr,
                        unReadCount: 0,
                     },
                  },
               },
            });
         },
      },
   );

   const [deleteAllNotificationsMutation, { loading: deleteAllNotificationsLoading }] = useMutation(
      DELETE_ALL_NOTIFICATIONS_MUTATION,
      {
         update: (cache, { data }) => {
            cache.writeQuery({
               query: GET_MY_NOTIFICATIONS_QUERY,
               variables: {
                  person: person._id,
                  page: 1,
               },
               data: {
                  myNotifications: {
                     data: initialState,
                     success: true,
                     code: null,
                     message: null,
                  },
               },
            });
         },
      },
   );

   const {
      data: notificationsData,
      fetchMore,
      loading: notificationQueryLoading,
   } = useQuery(GET_MY_NOTIFICATIONS_QUERY, {
      variables: { person: person._id, page: 1 },
      fetchPolicy: 'cache-first',
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   useSubscription(MY_NOTIFICATION_SUBSCRIPTION, {
      variables: {
         person: person._id,
         token,
      },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               personNotification: { data },
            },
         },
      }) => {
         // TODO. NEED SOME WARNINGS.

         data.QnAChatId = data.QnAChatId || null;
         if (triggerSubs) {
            const cachedData = client.cache.readQuery({
               query: GET_MY_NOTIFICATIONS_QUERY,
               variables: {
                  person: person._id,
                  page: 1,
               },
            });

            const existData = cachedData?.myNotifications?.data;

            client.cache.writeQuery({
               query: GET_MY_NOTIFICATIONS_QUERY,
               variables: {
                  person: person._id,
                  page: 1,
               },
               data: {
                  myNotifications: {
                     ...cachedData?.myNotifications,
                     data: {
                        ...cachedData?.myNotifications?.data,
                        notifications: [data, ...existData?.notifications],
                        unReadCount: existData.unReadCount + 1,
                     },
                  },
               },
            });
            // play sound if customer outside of chat page
            // messages
            if (
               customer.newChatSystem &&
               data.bellType === 1 &&
               !history.location.pathname.includes('chat') &&
               customer.newChatBell
            ) {
               playNewNotification();
            } else if (
               customer.transferredChatSystem &&
               data.bellType === 2 &&
               !history.location.pathname.includes('chat') &&
               customer.transferredChatBell
            ) {
               playTransferred();
            } else if (
               customer.timeoutChatSystem &&
               data.bellType === 3 &&
               !history.location.pathname.includes('chat') &&
               customer.timeoutChatBell
            ) {
               playTimeout();
            }
            // qna comments
            else if (
               customer.newQnAChatSystem &&
               data.bellType === 1 &&
               !history.location.pathname.includes('questions') &&
               customer.newQnAChatBell
            ) {
               playQnANewNotification();
            } else if (
               customer.transferredQnAChatSystem &&
               data.bellType === 2 &&
               !history.location.pathname.includes('questions') &&
               customer.transferredQnAChatBell
            ) {
               // transfer
               playQnATransferred();
            } else if (
               customer.timeoutQnAChatSystem &&
               data.bellType === 3 &&
               !history.location.pathname.includes('questions') &&
               customer.timeoutQnAChatBell
            ) {
               // timeout
               playQnATimeout();
            }
         }
      },
   });

   const handleClickNotification = useCallback(
      async (notification) => {
         await setNotificationSeenStatusMutation({
            variables: {
               _id: notification._id,
            },
         });

         switch (notification.type) {
            case 2:
               // Chat Notification
               history.push(`/chat/${notification?.chatId?._id}`);
               break;
            case 3:
               // Team chat notification
               break;
            case 4:
               // QnA Notification
               history.push(`/questions/${notification?.QnAChatId?._id}`);
               break;

            default:
               break;
         }

         // TODO. MUST ADD NEW NOTIFICATION ACTION.
      },
      [history, setNotificationSeenStatusMutation],
   );

   const handleMakeAllNotificationsSeen = useCallback(async () => {
      await clearAllNotificationsMutation({
         variables: {
            person: person._id,
         },
      });
   }, [clearAllNotificationsMutation, person._id]);

   const handleDeleteAllNotifications = useCallback(async () => {
      await deleteAllNotificationsMutation({
         variables: {
            person: person._id,
         },
      });
   }, [deleteAllNotificationsMutation, person._id]);

   const handleNextMore = useCallback(async () => {
      await fetchMore({
         variables: {
            person: person._id,
            page: notificationsData?.myNotifications?.data?.page + 1,
         },
         updateQuery: (prevResult, { fetchMoreResult }) => {
            const newDocs = [
               ...prevResult?.myNotifications?.data?.notifications,
               ...fetchMoreResult?.myNotifications?.data?.notifications,
            ];
            return {
               myNotifications: {
                  ...fetchMoreResult?.myNotifications,
                  data: {
                     ...fetchMoreResult?.myNotifications?.data,
                     notifications: newDocs,
                  },
               },
            };
         },
      });
   }, [fetchMore, notificationsData?.myNotifications?.data?.page, person._id]);

   const loadingStatus =
      deleteAllNotificationsLoading ||
      clearAllNotificationsLoading ||
      changeNotificationStatusLoading ||
      notificationQueryLoading;

   return {
      notificationsData: notificationsData?.myNotifications?.data,
      handleNextMore,
      handleClickNotification,
      handleMakeAllNotificationsSeen,
      handleDeleteAllNotifications,
      loadingStatus,
   };
};

export default useNotifications;

import ChatFilters from '@components/ChatFilters';
import ChatListTabs from '@components/ChatListTabs';
import { ChatListTab } from '@components/ChatListTabs/ChatListTabs.types';
import SelectablePlatforms from '@components/SelectablePlatforms';
import { LanguageContext } from '@helper/locale/langContext';
import { Box } from '@material-ui/core';
import { getCustomerTeamMembersQuery_customerTeams_data } from '@queries/Person/Service/GraphQL/__generated__/getCustomerTeamMembersQuery';
import {
   getChatTagsQuery_chatTags_docs
} from '@queries/Settings/Service/GraphQL/ChatTags/__generated__/getChatTagsQuery';
import { getTeams_getTeams_data_docs } from '@queries/Teams/__generated__/getTeams';
import { RESET_QNA_CHAT_FILTERS, SET_QNA_CHAT_FILTERS } from '@store/actions/questions';
import { getQnaChatFilters } from '@store/reducers/questions';
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ChatType } from '../../../../types/enums';
import { QnAPersonStatusType, QnAPlatformType } from '../../util/types';

const getPlatforms = (t: any) => [
   { name: QnAPlatformType.INSTAGRAM, code: 4, tooltip: 'Instagram ' + t?.comments },
   { name: QnAPlatformType.FACEBOOK, code: 9, tooltip: 'Facebook ' + t?.comments },
   { name: QnAPlatformType.HEPSIBURADA, code: 8, tooltip: 'Hepsiburada' },
   { name: QnAPlatformType.TRENDYOL, code: 7, tooltip: 'Trendyol' },
   { name: QnAPlatformType.N11, code: -8, tooltip: 'N11' },
];

const getStatusList = (t: any) => [
   { label: t['page_chat_typo_filter_title_all'], key: 'ALL', value: 'ALL', color: '#157CFC' },
   {
      label: t['page_chat_typo_filter_title_active'],
      key: 'ACTIVE',
      value: QnAPersonStatusType.WAITING,
      color: '#1ac31a',
   },
   {
      label: t['page_chat_typo_filter_title_closed'],
      key: 'CLOSED',
      value: QnAPersonStatusType.ARCHIVE,
      color: '#F2B544',
   },
   {
      label: t['page_chat_typo_filter_title_resolved'],
      key: 'RESOLVED',
      value: QnAPersonStatusType.SOLVED,
      color: '#499cf2',
   },
   { label: t['page_chat_typo_filter_title_spam'], key: 'SPAM', value: QnAPersonStatusType.SPAM, color: '#577590' },
   {
      label: t['page_chat_typo_filter_title_deleted'],
      key: 'DELETED',
      value: QnAPersonStatusType.DELETED,
      color: '#577590',
   },
];

interface LeftPanelToolbarProps {
   teams: undefined | getTeams_getTeams_data_docs[];
   agents: undefined | getCustomerTeamMembersQuery_customerTeams_data[];
   tags: undefined | getChatTagsQuery_chatTags_docs[];
   currentTab: ChatListTab;
   onTabChange: Dispatch<SetStateAction<ChatListTab>>;
}

const LeftPanelToolbar = ({ teams, agents, tags, currentTab, onTabChange }: LeftPanelToolbarProps) => {
   const {
      lang: { translation: t },
   }: any = useContext(LanguageContext);
   const platformList = getPlatforms(t);
   const dispatch = useDispatch();
   const qnaChatFilters = useSelector(getQnaChatFilters);
   const statusList = getStatusList(t);

   const onSelectTeam = useCallback(
      (teams) => {
         dispatch({
            type: SET_QNA_CHAT_FILTERS,
            payload: { teams },
         });
      },
      [dispatch],
   );

   const onSelectPlatform = useCallback(
      (platformType) => {
         dispatch({
            type: SET_QNA_CHAT_FILTERS,
            payload: { platformType },
         });
      },
      [dispatch],
   );

   const onChangeSort = useCallback(
      (sort) => {
         dispatch({
            type: SET_QNA_CHAT_FILTERS,
            payload: { desc: sort },
         });
      },
      [dispatch],
   );

   const onSearch = useCallback(
      (text) => {
         dispatch({
            type: SET_QNA_CHAT_FILTERS,
            payload: { text },
         });
      },
      [dispatch],
   );

   useEffect(() => {
      if (!qnaChatFilters) {
         dispatch({
            type: RESET_QNA_CHAT_FILTERS,
         });
      }
   }, [qnaChatFilters]);

   if (!qnaChatFilters) return null;

   return (
      <Box
         width='100%'
         height='100%'
         style={{ marginLeft: '10px', marginRight: '10px', paddingTop: '10px', overflowX: 'hidden' }}
      >
         <ChatFilters
            chatType={ChatType.QNA}
            chatFilters={qnaChatFilters}
            teams={teams}
            agents={agents}
            tags={tags}
            statusList={statusList}
            onSearch={onSearch}
            onChangeSort={onChangeSort}
            onSelectTeam={onSelectTeam}
            emailAccounts={null}
            whatsappWebAccounts={null}
            whatsappBusinessAccounts={null}
            isQuestionTab={true} // Dont show createNewEmail button in QnA
         />
         <SelectablePlatforms
            platformsData={platformList}
            onSelectPlatform={onSelectPlatform}
            currentSelectedPlatform={qnaChatFilters.platformType}
         />
         <ChatListTabs
            chatType={ChatType.QNA}
            chatFilters={qnaChatFilters}
            agents={agents}
            selectedTab={currentTab}
            setSelectedTab={onTabChange}
         />
      </Box>
   );
};

export default LeftPanelToolbar;

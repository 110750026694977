import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import Messages from './messages';
import Contacts from './contacts';
import { useSelector } from 'react-redux';
import Tags from './tags';
import Users from './users';
import CustomerSatisfaction from './customer-satisfaction';
import AbandonedCarts from './abandoned-carts';
import { useSnackbar } from 'notistack';
import { LanguageContext } from '@helper/locale/langContext';
// import QuestionMessages from './question-messages';
// import QuestionChats from './question-chats';

const useStyles = makeStyles((theme) => ({
   root: {
      height: '100%',
      width: '100%',
      borderRadius: 15,
      overflowY: 'auto',
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   appBarSpacer: theme.mixins.toolbar,
}));

const StatisticsSpace = () => {
   const classes = useStyles();
   const person = useSelector((reducer) => reducer.personReducer.person);
   const history = useHistory();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, history, enqueueSnackbar, closeSnackbar]);

   return (
      <Grid style={{ padding: 10, width: '100%', height: '100%' }}>
         <div className={classes.appBarSpacer} />
         <Paper elevation={3} className={classes.root}>
            <Switch>
               <Route path={'/statistics'} exact>
                  {' '}
                  <Messages />{' '}
               </Route>
               <Route path={'/statistics/contacts'} exact>
                  {' '}
                  <Contacts />{' '}
               </Route>
               <Route path={'/statistics/tags'} exact>
                  {' '}
                  <Tags />{' '}
               </Route>
               <Route path={'/statistics/users'} exact>
                  {' '}
                  <Users />{' '}
               </Route>
               <Route path={'/statistics/customer-satisfaction'} exact>
                  {' '}
                  <CustomerSatisfaction />{' '}
               </Route>
               <Route path={'/statistics/abandoned-carts'} exact>
                  {' '}
                  <AbandonedCarts />{' '}
               </Route>
               {/* <Route path={'/statistics/question-messages'} exact>
                  {' '}
                  <QuestionMessages />{' '}
               </Route>
               <Route path={'/statistics/question-chats'} exact>
                  {' '}
                  <QuestionChats />{' '}
               </Route> */}
               <Redirect to={'/statistics'} />
            </Switch>
         </Paper>
      </Grid>
   );
};

export default StatisticsSpace;

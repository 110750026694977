import { Grid, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { MyTheme } from '@styles/config';
import MyTypography from '../MyTypography';
import clsx from 'clsx';

interface Props {
   icon?: (className: string) => JSX.Element | React.ReactNode;
   selected?: boolean;
   label?: string;
   onClick?: React.MouseEventHandler<HTMLAnchorElement>;
   url?: any;
   containerClass?: string;
   hoverClass?: string;
   preventDefault?: boolean;
   iconClass?: string;
}

const useStyles = makeStyles<MyTheme, Props>((theme) => ({
   main: {
      width: '100%',
   },
   root: (props) => ({
      background: props.selected ? '#fff' : undefined,
      height: 40,
      // borderRadius: 6,
      '&:hover': {
         backgroundColor: (props) => (!props.selected ? '#fff' : undefined),
      },
   }),
   label: {
      color: (props) => (props.selected ? '#0E223C' : '#9BA1BA'),
      fontSize: 16,
   },
   icon: {
      fill: (props) => (props.selected ? theme.palette.primary.main : '#9BA1BA'),
      filter: (props) => (props.selected ? 'none' : 'grayscale(100%)'),
      width: 20,
      height: 20,
   },
   iconItem: {
      [theme.breakpoints.down('md')]: {
         justifyContent: 'center',
      },
   },
}));

const LeftMenuItem: React.FC<Props> = (props) => {
   const classes = useStyles(props);
   const { onClick, label, icon, url, containerClass, iconClass } = props;
   return (
      <Link to={url} onClick={onClick} className={classes.main}>
         <Grid alignItems={'center'} direction={'row'} className={clsx(classes.root, containerClass)} container>
            <Grid container className={classes.iconItem} alignItems={'center'} item md={false} lg={1}>
               {typeof icon === 'function' ? icon?.(clsx(classes.icon, iconClass)) : icon}
            </Grid>
            <Hidden mdDown>
               <Grid style={{ paddingLeft: 16 }} item md={11}>
                  <MyTypography className={classes.label}>{label}</MyTypography>
               </Grid>
            </Hidden>
         </Grid>
      </Link>
   );
};

export default LeftMenuItem;

import moment from 'moment';

const dateFormat = 'DD-MM-YYYY';

type Message = {
  _id: string;
  type: number;
  createdAt: string;
} | null;

export type MessagesDateSeparator = {
  _id: string;
  type: number;
  createdAt: string;
};

type DateSeparator = Message & {
  formattedDate: string;
};

type Accumulator = {
  [index: string]: Message[];
};

function groupedDays(messages: Message[]) {
  return messages.reduce((acc: Accumulator, el) => {
    const messageDay = moment(el?.createdAt).format(dateFormat);
    if (acc[messageDay]) {
      return { ...acc, [messageDay]: acc[messageDay].concat([el]) };
    }
    return { ...acc, [messageDay]: [el] };
  }, {});
}

function addDaysToChatList(messages: Message[] | null) {
  if (messages) {
    const days = groupedDays(messages);
    const sortedDays = Object.keys(days).sort((x, y) => moment(y, dateFormat).unix() - moment(x, dateFormat).unix());
    return sortedDays.reduce((acc: Message[], date) => {
      const sortedMessages: Message[] = days[date].sort(
        (x, y) => moment(y?.createdAt).unix() - moment(x?.createdAt).unix(),
      );
      const dateSeparator: DateSeparator = {
        type: 9,
        createdAt: date,
        formattedDate: date,
        _id: date,
      };
      return acc.concat([...sortedMessages, dateSeparator]);
    }, []);
  }
  return null;
}

export default addDaysToChatList;

import React, { Fragment, useContext, useState, useCallback, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonBase, Grid, MenuItem, Typography, Divider, Tooltip, CircularProgress } from '@material-ui/core';
import ButtonBasedPopup from '@components/ButtonBasedPopup';
import MediumAvatar from '@components/MediumAvatar';
import { GET_CUSTOMER_TEAMS_QUERY } from '@queries/Person/Service/GraphQL/query';
import { GET_CHAT_TAGS_QUERY, GET_CHAT_TAG_COUNT } from '@queries/Settings/Service/GraphQL/ChatTags/query';
import { SITE_URL } from '@helper/config';
import Tag from '@components/Tag';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import AddIcon from '@material-ui/icons/Add';
import {
   LeaveChatIcon,
   CloseChatIcon,
   EditChatTagIcon,
   ForwardChatIcon,
   ArchiveChatIcon,
   SpamChatIcon,
} from '@assets/icons/NavbarIcons/chatHeaderIcons';
import useChatMutations from '../../Chat/hooks/useChatMutations';
import useChatPlatformStatus from '@hooks/useChatPlatformStatus';
import Modal from '@components/Modal/modal';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { UPDATED_CUSTOMER_PERSON_SUBSCRIPTION } from '@queries/Person/Service/GraphQL/subscription';
import InfiniteScroll from 'react-infinite-scroll-component';
import SearchBar from '@components/SearchBar';

const useStyles = makeStyles((theme) => ({
   root: {
      // height:40,
      // width:400,
      padding: 5,
      borderRadius: 10,
   },
   buttonsContainer: {
      padding: 5,
      borderRadius: 10,
      maxWidth: 500,
      overflowX: 'auto',
   },
   menuItemRoot: {
      '&:hover': {
         backgroundColor: 'transparent',
      },
   },
   tagSearchInputContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 8,
      marginLeft: 8,
   },
   tagSearchInput: {
      margin: '8px 0px 5px 0px !important',
   },
   input: {
      padding: 5,
   },
   joinButton: {
      textTransform: 'none',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      paddingLeft: 8,
      paddingRight: 8,
      marginRight: 4,
      fontSize: '1.1rem',
      borderRadius: 5,
      backgroundColor: 'white',
      height: 36,
   },
   actionButton: {
      padding: 0,
      height: 36,
      width: 36,
      minWidth: 36,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F0F0F0',
      border: '1px solid #D8D8D8',
      borderRadius: 8,
      fontSize: 20,
      marginLeft: 4,
      marginRight: 4,
      '&:focus': {
         backgroundColor: '#D8D8D8',
      },
   },
   disabledActionButton: {
      opacity: '0.5',
      '&:focus': {
         backgroundColor: '#F0F0F0',
      },
   },
   activeButton: {
      cursor: 'pointer',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   inActiveButton: {
      cursor: 'default',
      color: 'grey',
   },
   joined: {
      backgroundColor: 'white',
      textTransform: 'none',
   },
   notJoined: {
      backgroundColor: '#47D054',
      textTransform: 'none',
      color: 'white',
      '&:hover': {
         filter: 'brightness(1.1)',
         backgroundColor: '#47D054',
      },
   },
   tagContainer: {
      padding: 10,
      backgroundColor: 'white',
      position: 'sticky',
      top: -10,
      zIndex: 4,
   },
   tagTitle: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   noTeamMemberTitle: {
      fontSize: '0.9rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   tags: {
      border: 'solid 1px #cbd3dd',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   more: {
      paddingTop: 3,
      border: 'solid 1px #cbd3dd',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      width: 50,
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 35,
      marginTop: 0,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   tagBottomButtons: {
      position: 'sticky',
      zIndex: 5,
      bottom: -10,
      backgroundColor: '#fff',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const Page = {
   pageSize: 10,
   page: 1,
};

const ChatButtons = () => {
   const classes = useStyles();
   const activeChat = useSelector((reducer) => reducer.chatReducer.activeChat);
   const me = useSelector((reducer) => reducer.personReducer.person);
   const token = useSelector((rootReducer) => rootReducer.personReducer.token);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [openUsers, setOpenUsers] = useState(false);
   const [openTags, setOpenTags] = useState(false);
   const [selectedTag, setSelectedTag] = useState(null);
   const platformStatus = useChatPlatformStatus(activeChat);
   const [hasAuthority, setHasAuthority] = useState(false);
   const [isModalVisible, setModalVisible] = useState(false);

   const customerId = me.customer._id;
   const [searchText, setSearchText] = useState(null);
   const [isSearchText, setTextChange] = useState(false);
   const [chatTagCount, setChatTagCount] = useState(0);
   const [answerList, setAnswerList] = useState([]);

   const { loading, leaveChat, updateChat, changeChatTag, transferChat, signAsArchiveChat } = useChatMutations();

   const { data: customerTeamsData, refetch: refetchCustomerTeamsData } = useQuery(GET_CUSTOMER_TEAMS_QUERY, {
      variables: { person: me._id, customer: me.customer._id },
      fetchPolicy: 'cache-first',
   });

   // Get ChatTags
   const [getChatTagQuery, { data: chatTagsData, loading: chatTagsDataLoading }] = useLazyQuery(GET_CHAT_TAGS_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         if (isSearchText) {
            setAnswerList([...answerList, ...data?.getChatTagList?.data?.docs]);
            setChatTagCount(data?.getChatTagList?.data?.docsCount);
            setTextChange(false);
            return;
         }
         setAnswerList([...answerList, ...data?.getChatTagList?.data?.docs]);
         setChatTagCount(data?.getChatTagList?.data?.docsCount);
      },
   });

   // subscription updated person
   useSubscription(UPDATED_CUSTOMER_PERSON_SUBSCRIPTION, {
      variables: { token, customer: me ? me.customer._id : '' },
      onSubscriptionData: () => {
         refetchCustomerTeamsData();
      },
   });

   const [chatTagParam, setChatTagParam] = useState({
      customer: customerId,
      pageSize: Page.pageSize,
      page: Page.page,
      search: null,
   });

   useEffect(() => {
      setAnswerList([]);
      if (searchText !== null) {
         setTextChange(true);
         setChatTagParam((prev) => ({ ...prev, search: searchText, page: Page.page }));
      } else if (searchText === '') {
         setChatTagParam((prev) => ({ ...prev, search: searchText, page: Page.page }));
      }
   }, [searchText]);

   useEffect(() => {
      getChatTagQuery({
         variables: {
            ...chatTagParam,
         },
      });
   }, [chatTagParam]);

   const handleNextAnswerPage = () => {
      if (answerList.length < chatTagCount) {
         setChatTagParam({
            ...chatTagParam,
            page: chatTagParam.page + 1,
         });
      }
   };

   const handleCloseChat = (chatData) => {
      updateChat(chatData._id, parseInt(chatData.status));
   };

   const handleSpamChat = (chatData) => {
      updateChat(chatData._id, parseInt(chatData.status));
   };

   const handleChangeChatTag = useCallback(
      (chatID, selectedTagID) => {
         setOpenTags(false);
         changeChatTag(chatID, selectedTagID, me._id);
      },
      [setOpenTags, changeChatTag, me?._id],
   );

   const hasJoined = activeChat?.joinStatus;

   useEffect(() => {
      setHasAuthority(!activeChat?.joinStatus ? true : !!activeChat?.participants.find((p) => p._id === me._id));
   }, [activeChat?.joinStatus, activeChat?.participants]);

   const Loader = () => {
      return (
         <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   };

   return (
      <Grid className={classes.root} container sm={12} justifyContent='center' alignItems='center'>
         <Grid className={classes.buttonsContainer} container direction='row' wrap='nowrap' alignItems='center'>
            {/* LEAVE */}
            {hasJoined && (
               <Tooltip title={t['page_chat_typo_leave']}>
                  <Button
                     onClick={leaveChat}
                     className={clsx(
                        classes.actionButton,
                        (!hasJoined || !platformStatus || (!hasAuthority && me.type !== 1 && me.type !== 2)) &&
                           classes.disabledActionButton,
                     )}
                     disableTouchRipple
                     disabled={!platformStatus || (!hasAuthority && me.type !== 1 && me.type !== 2)}
                  >
                     <LeaveChatIcon />
                  </Button>
               </Tooltip>
            )}
            {/* CLOSE */}
            <Tooltip title={t['page_chat_typo_solved']}>
               <Button
                  onClick={() => {
                     handleCloseChat({
                        _id: activeChat?._id,
                        status: 2,
                     });
                  }}
                  className={clsx(
                     classes.actionButton,
                     (!platformStatus || activeChat?.status === 2 || !hasAuthority) && classes.disabledActionButton,
                  )}
                  disableTouchRipple
                  disabled={!platformStatus || activeChat?.status === 2 || !hasAuthority}
               >
                  <CloseChatIcon />
               </Button>
            </Tooltip>

            {/* TRANSFER */}
            <ButtonBasedPopup
               style={{ marginLeft: 5 }}
               isDisabled={!activeChat?.isJoinable || !platformStatus || !hasAuthority}
               button={
                  <Tooltip title={t['page_chat_typo_forward']}>
                     <Button
                        className={clsx(
                           classes.actionButton,
                           (!activeChat?.isJoinable || !platformStatus || !hasAuthority) &&
                              classes.disabledActionButton,
                        )}
                        disableTouchRipple
                        disabled={!activeChat?.isJoinable || !platformStatus || !hasAuthority}
                     >
                        <ForwardChatIcon />
                     </Button>
                  </Tooltip>
               }
               open={openUsers}
               onOpen={() => setOpenUsers(true)}
               onClose={() => setOpenUsers(false)}
            >
               <Grid
                  style={{ paddingBottom: 10, paddingTop: 10, width: '225px', maxHeight: 400 }}
                  container
                  justifyContent='center'
                  alignItems='center'
               >
                  {customerTeamsData?.customerTeams?.data?.length === 0 && (
                     <Grid style={{ height: 150 }} container justifyContent='center' alignItems='center'>
                        <Grid item>
                           <Typography align='center' className={classes.tagTitle}>
                              {t?.there_is_no_team_member}
                           </Typography>
                        </Grid>
                        <Grid item>
                           <Link to='/settings/users'>
                              <ButtonOK
                                 label={t?.page_settings_button_add_new}
                                 fontSize='1rem'
                                 width={120}
                                 height={30}
                                 endIcon={<AddIcon fontSize='small' />}
                              />
                           </Link>
                        </Grid>
                     </Grid>
                  )}
                  {customerTeamsData?.customerTeams?.data.map((person, i) => (
                     <Grid
                        key={person._id}
                        container
                        justifyContent='flex-start'
                        alignItems='center'
                        style={{ paddingLeft: 10, paddingRight: 10 }}
                     >
                        <MenuItem
                           style={{
                              paddingLeft: 10,
                              paddingRight: 0,
                              borderRadius: 10,
                              width: '100%',
                              height: 55,
                           }}
                           onClick={() => transferChat(activeChat?._id, person._id)}
                        >
                           <Grid container justifyContent='flex-start' alignItems='center'>
                              {/* <div className={clsx(classes.item, mouseOverIndex === i && classes.selectedItem)} /> */}
                              <ButtonBase disableRipple disableTouchRipple>
                                 <MediumAvatar
                                    avatarBorder
                                    title={person.name}
                                    image={person.photo ? person.photo : SITE_URL + '/none.jpg'}
                                    onlineStatus={person.status}
                                    username={person.email}
                                 />
                              </ButtonBase>
                           </Grid>
                        </MenuItem>
                     </Grid>
                  ))}
               </Grid>
            </ButtonBasedPopup>

            {/* TAGS */}
            <ButtonBasedPopup
               isDisabled={!platformStatus || !hasAuthority}
               style={{ marginLeft: 5 }}
               native
               button={
                  <Tooltip title={t['page_chat_typo_filter_tag']}>
                     <Button
                        className={clsx(
                           classes.actionButton,
                           (!platformStatus || !hasAuthority) && classes.disabledActionButton,
                        )}
                        disableTouchRipple
                        disabled={!platformStatus || !hasAuthority}
                     >
                        <EditChatTagIcon />
                     </Button>
                  </Tooltip>
               }
               open={openTags}
               onOpen={() => setOpenTags(true)}
               onClose={() => setOpenTags(false)}
            >
               <Grid style={{ padding: 10, width: 280, maxHeight: '400px', overflowY: 'hidden' }}>
                  <Grid item container direction='row' justifyContent='space-between' className={classes.tagContainer}>
                     <Grid container alignItems='center' xs={6} item>
                        <Typography className={classes.tagTitle}>{t['page_chat_typo_tags']}</Typography>
                     </Grid>
                     <Grid item xs={6}>
                        <Link to={'/settings/company/tags'}>
                           <ButtonOK
                              label={t?.page_settings_button_add_new}
                              fontSize='1rem'
                              width={120}
                              height={30}
                              endIcon={<AddIcon fontSize='small' />}
                           />
                        </Link>
                     </Grid>
                  </Grid>
                  <Divider style={{ width: '100%', marginTop: 2, marginBottom: 5 }} />

                  <div className={classes.tagSearchInputContainer}>
                     <div>
                        <SearchBar
                           value={searchText}
                           onChange={setSearchText}
                           placeholder={t['search']}
                           style={{ width: 238, height: 35, right: 3 }}
                        />
                     </div>
                  </div>

                  <InfiniteScroll
                     scrollableTarget='scrollableDiv'
                     dataLength={answerList?.length || 0}
                     hasMore={answerList.length >= chatTagCount ? false : true}
                     loader={<Loader />}
                     next={handleNextAnswerPage}
                     height={220}
                  >
                     {answerList?.map((tag, i) => {
                        return (
                           <Grid
                              key={tag.id}
                              container
                              justifyContent='flex-start'
                              alignItems='center'
                              wrap='nowrap'
                              style={{
                                 paddingLeft: 5,
                                 paddingRight: 5,
                                 marginTop: 5,
                                 cursor: 'pointer',
                              }}
                           >
                              <MenuItem
                                 disableRipple
                                 className={classes.menuItemRoot}
                                 onClick={() => setSelectedTag(tag)}
                                 style={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    borderRadius: 10,
                                    width: '100%',
                                    cursor: 'pointer',
                                 }}
                              >
                                 <Grid
                                    container
                                    justifyContent='flex-start'
                                    alignItems='center'
                                    wrap='nowrap'
                                    style={{ paddingLeft: 3, paddingRight: 3 }}
                                 >
                                    <Tag
                                       name={tag.name}
                                       color={tag.color}
                                       buttonStyle
                                       preSelected={selectedTag?._id === tag._id}
                                       selected={tag._id === activeChat?.tag?._id}
                                       style={{
                                          cursor: 'pointer',
                                          hovered: true,
                                       }}
                                    />
                                 </Grid>
                              </MenuItem>
                           </Grid>
                        );
                     })}
                     {chatTagCount === 0 && (
                        <Grid xs={12} container style={{ height: 200 }} justifyContent='center' alignItems='center'>
                           {!chatTagsDataLoading && (
                              <Typography align='center' className={classes.tagTitle}>
                                 {t?.there_is_no_tag}
                              </Typography>
                           )}
                        </Grid>
                     )}
                  </InfiniteScroll>
                  {/* If person doesn't have tag, don't show the buttons */}
                  {answerList.length !== 0 ? (
                     <Fragment>
                        <Divider style={{ width: '100%', marginTop: 15, marginBottom: 5 }} />
                        <Grid
                           item
                           xs={12}
                           container
                           justifyContent='space-between'
                           style={{ padding: 15 }}
                           className={classes.tagBottomButtons}
                        >
                           <Grid item xs={6} style={{ paddingRight: 5 }}>
                              <ButtonCancel
                                 onClick={() => handleChangeChatTag(activeChat?._id, null)}
                                 fontSize='1rem'
                                 height={35}
                                 style={{ width: '100%' }}
                                 label={t?.remove}
                              />
                           </Grid>
                           <Grid item xs={6} container justifyContent='flex-end' style={{ paddingLeft: 5 }}>
                              <ButtonOK
                                 onClick={() => handleChangeChatTag(activeChat?._id, selectedTag?._id)}
                                 fontSize='1rem'
                                 height={35}
                                 style={{ width: '100%' }}
                                 label={t?.comp_button_save}
                              />
                           </Grid>
                        </Grid>
                     </Fragment>
                  ) : (
                     <Grid xs={12} container style={{ height: 200 }} justifyContent='center' alignItems='center'>
                        <Typography align='center' className={classes.tagTitle}></Typography>
                     </Grid>
                  )}
               </Grid>
            </ButtonBasedPopup>

            <Tooltip title={t['page_chat_typo_archive']}>
               <Button
                  onClick={() => signAsArchiveChat(activeChat?._id, me._id)}
                  className={clsx(
                     classes.actionButton,
                     (!platformStatus || activeChat?.status === 1 || !hasAuthority) && classes.disabledActionButton,
                  )}
                  disableTouchRipple
                  disabled={!platformStatus || activeChat?.status === 1 || !hasAuthority}
               >
                  <ArchiveChatIcon />
               </Button>
            </Tooltip>

            <Tooltip title='Spam'>
               <Button
                  onClick={() => {
                     setModalVisible(true);
                  }}
                  className={clsx(
                     classes.actionButton,
                     (!platformStatus || activeChat?.status === 3 || !hasAuthority) && classes.disabledActionButton,
                  )}
                  disableTouchRipple
                  disabled={!platformStatus || activeChat?.status === 3 || !hasAuthority}
               >
                  <SpamChatIcon />
               </Button>
            </Tooltip>
         </Grid>
         <Modal
            open={isModalVisible}
            onClose={() => setModalVisible(false)}
            modalHeader={t?.general_message_are_your_sure}
            paperStyle={{ width: 450 }}
         >
            <ModalLargeText style={{ textAlign: 'center', paddingBlock: 30 }}>{t?.chat_will_spammed}</ModalLargeText>
            <ModalButtonField>
               <ButtonCancel
                  className={classes.modalButtons}
                  label={t?.general_message_no}
                  onClick={() => setModalVisible(false)}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
               <ButtonOK
                  className={classes.modalButtons}
                  label={t?.general_message_yes}
                  onClick={() => {
                     setModalVisible(false);
                     handleSpamChat({
                        _id: activeChat?._id,
                        status: 3,
                        message: t?.chat_will_spammed,
                     });
                  }}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     '&:hover': {
                        backgroundColor: '#105CBB',
                     },
                     float: 'right',
                  }}
               />
            </ModalButtonField>
         </Modal>
      </Grid>
   );
};

export default ChatButtons;

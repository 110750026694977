import { useQuery } from '@apollo/client';
import { GET_TRANSLATE_LANGUAGE } from '@queries/TranslateLanguage/query';
import { useSelector } from 'react-redux';

const useTranslateLanguage = (lang) => {
   const { data, loading } = useQuery(GET_TRANSLATE_LANGUAGE, { variables: { lang: lang || 'en' } });
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   if (loading || !data) return { data: [], loading, personLang: { code: '', label: '' } };
   const languages = data.getTranslateLanguage.data.LANGS;
   const _data = Object.keys(languages).map((key) => ({
      label: languages[key],
      code: key,
   }));
   const personLang = _data.find((lang) => lang?.code === person.language?.toLowerCase());
   return { data: _data, loading, personLang: personLang };
};

export default useTranslateLanguage;

import React, { useContext, useEffect } from 'react';
import { LanguageContext } from '@helper/locale/langContext';

// Example url
// https://example.com/example?lang=TR -> set lang to TR
// https://example.com/example?lang=EN -> set lang to EN
const WithUrlTranslations = (Component: React.ComponentType) => {
   const WithUrlTranslationsComponent = (props: any) => {
      const { languages, switchLang } = useContext(LanguageContext);
      const langQuery = new URLSearchParams(window.location.search).get('lang');

      useEffect(() => {
         if (langQuery) {
            const currentLang = languages.find((lang) => lang.shortTitle === langQuery);
            if (currentLang) {
               switchLang({ ...currentLang });
            }
         }
      }, [langQuery, languages, switchLang]);

      return <Component {...props} />;
   };

   return WithUrlTranslationsComponent;
};

export default WithUrlTranslations;

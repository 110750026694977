import ButtonOK from '@components/ButtonOK/buttonOk';
import RichTextReply from '@components/RichTextReply';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { LanguageContext } from '@helper/locale/langContext';
import { trimLongText } from '@helper/textUtils';
import { Button, Grid, Modal as MaterialModal, Paper, Typography } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import '@styles/index.css';
import React, { useContext, useEffect, useState } from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { useSelector } from 'react-redux';

import FileUploadPreview from '@components/FileUploadPreview';
import useWindowResize from '@hooks/useWindowResize';
import { IconButton } from '@material-ui/core';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import { useSnackbar } from 'notistack';
import Resizer from 'react-image-file-resizer';

const MAX_FILE_SIZE = 10000000;

const useStyles = makeStyles((theme) => ({
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   modalPaper: {
      borderRadius: 12,
      backgroundColor: '#fff',
      minWidth: 450,
      width: 600,
      maxHeight: '99vh',
      fontFamily: 'Helvetica',
   },
   header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '20px',
      // color: '#4681C3',
      color: 'black',
      fontWeight: 'bold',
      marginTop: 0,
      width: '100%',
      paddingInline: 20,
      paddingBlock: 10,
      borderBottom: '2px solid #f5f5f5',
   },
   body: {
      overflowX: 'hidden',
      maxHeight: 'calc(99vh - 60px)',
      width: '100%',
      minHeight: 610,
      overflow: 'auto',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   btnBox: {
      position: 'sticky',
      bottom: 0,
      padding: '0 1rem',
      backgroundColor: '#fff',
      borderRadius: 12,
      display: 'flex',
      justifyContent: 'space-between',
      zIndex: 1,
   },
}));

// TODO: Change to use MaterialModal
const Modal = (props) => {
   const {
      open,
      onClose,
      modalHeader,
      dataToSend,
      submit,
      newSubject,
      isNewMessage,
      noSubject,
      isReplyMessage,
      emailSignature,
      me,
   } = props;

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const emailData = useSelector((rootReducer) => rootReducer.chatReducer)?.emailData;
   const emailAccounts = useSelector((state) => state.chatReducer.emailAccounts.emailAccounts);

   const classes = useStyles(props);
   const [showCc, setShowCc] = useState(false);
   const [showBcc, setShowBcc] = useState(false);
   const [from, setFrom] = useState('');
   const [to, setTo] = useState([]);
   const { width } = useWindowResize();
   const [cc, setCc] = useState([]);
   const [bcc, setBcc] = useState([]);
   const [media, setMedia] = useState([]);
   const [richTextValue, setRichTextValue] = useState('');
   const [subject, setSubject] = useState(modalHeader);
   const [isClicked, setIsClicked] = useState(false);
   const [localNewSubject, setLocalNewSubject] = useState('');
   const [focused, setFocused] = React.useState(false);
   const [isChangedTo, setIsChangedTo] = useState(false);
   const [state, setState] = useState({
      isPrivate: false,
      isRecording: false,
      recordStartTime: null,
      contentHtml: '',
      text: dataToSend?.text,
      media: dataToSend?.media,
      quickAnswer: false,
      showEmoji: false,
      subject: '',
      emailData: {
         to: [],
         from: '',
         [cc]: [],
         bcc: [],
      },
   });

   useEffect(() => {
      setLocalNewSubject(newSubject);
      if (isNewMessage && !isReplyMessage) {
         setSubject('');
         setFrom('');
         setTo([]);
         setCc([]);
         setBcc([]);
      } else if (!isNewMessage && isReplyMessage) {
         setFrom(emailData?.emailData?.from.toString());
         setTo(emailData?.emailData?.to);
         setCc(emailData?.emailData?.cc);
         setBcc(emailData?.emailData?.bcc);
         setSubject(
            emailData?.subject.includes('Re:') ||
               emailData?.subject.includes('Fwd:') ||
               emailData?.subject.includes('Fw:')
               ? emailData?.subject
               : `Re: ${emailData?.subject}` || '',
         );
      }
   }, [noSubject, isNewMessage, isReplyMessage]);

   useEffect(() => {
      if (open) {
         setFrom(emailData?.emailData?.from.toString());
         setTo(emailData?.emailData?.to);
         setCc(emailData?.emailData?.cc);
         setBcc(emailData?.emailData?.bcc);
         setSubject(
            emailData?.subject.includes('Re:') ||
               emailData?.subject.includes('Fwd:') ||
               emailData?.subject.includes('Fw:')
               ? emailData?.subject
               : `Re: ${emailData?.subject}` || '',
         );
         setMedia(dataToSend?.media);
         setRichTextValue(dataToSend?.text);
      } else {
         setSubject('');
         setFrom('');
         setTo([]);
         setCc([]);
         setBcc([]);
         setMedia([]);
         setRichTextValue('');
      }
   }, [open]);

   const handleAddCc = () => {
      setShowCc(!showCc);
   };
   const handleAddBcc = () => {
      setShowBcc(!showBcc);
   };
   useEffect(() => {
      if ((cc && cc.length > 0) || to.length > 1) {
         setShowCc(true);
      } else {
         setShowCc(false);
      }
      if (bcc && bcc.length > 0) {
         setShowBcc(true);
      } else {
         setShowBcc(false);
      }
   }, [cc, to]);

   // Function to handle receiving the value from the RichTextReply component
   const handleRichTextChange = (value) => {
      setRichTextValue(value);
   };

   // This function is used to remove the < and > characters from the email address.
   const removedCharacterTo = (character) => {
      const returnCharacter = character.map((item) => {
         if (item.includes('<')) {
            return item.split('<')[1].split('>')[0];
         }
         return item;
      });
      return returnCharacter;
   };

   const isMineMessage = () => {
      return emailData?.sender?._id === me || emailData?.sender?.type >= 0;
   };

   const isDomainValid = () => {
      return emailAccounts?.find((item) => item.forwardingEmailAddress === emailData?.emailData?.envelope?.to[0])
         ?.domain?.valid;
   };

   const removedStringCharacterTo = (email) => {
      if (email?.includes('<')) {
         return email?.split('<')[1]?.split('>')[0];
      }
      return email;
   };

   // This function is used to convert <to> to 'cc' if the user is replying to an email.
   const convertCc = (concatToCc) => {
      // This code should be fixed
      const convertToCc = removedCharacterTo(concatToCc);
      const indexForwardingEmail = convertToCc.findIndex((item) => item === emailData.emailData.envelope.to[0]);
      const indexSenderEmail = convertToCc.findIndex((item) => item === emailData.emailData.envelope.from);
      if (indexForwardingEmail !== -1) {
         convertToCc.splice(indexForwardingEmail, 1);
      } else if (indexSenderEmail !== -1) {
         convertToCc.splice(indexSenderEmail, 1);
      }
      return removedCharacterTo(emailData.emailData.cc).concat(convertToCc);
   };

   useEffect(() => {
      setState((prevState) => ({
         ...prevState,
         text: dataToSend?.text,
         media: media,
      }));
   }, [dataToSend]);

   useEffect(() => {
      setSubject(subject);
      setState((prevState) => ({
         ...prevState,
         text: emailData ? `${richTextValue} <hr id="line-blockquote"> ${emailData?.contentHtml}` : richTextValue,
         subject: isReplyMessage ? subject : localNewSubject,
         emailData: {
            to: isMineMessage() ? to : [removedStringCharacterTo(emailData?.emailData?.from)],
            from: isMineMessage()
               ? from
               : isDomainValid()
               ? emailAccounts?.find((item) => item.forwardingEmailAddress === emailData?.emailData?.envelope?.to[0])
                    ?.senderEmailAddress
               : emailData?.emailData?.envelope?.to[0],
            cc: emailData?.emailData?.envelope?.from ? convertCc(to) : cc,
            bcc: bcc,
         },
      }));
   }, [subject, to, from, cc, bcc, richTextValue]);

   const handleSubmit = () => {
      submit(state);
      onClose();
      setIsClicked(false);
   };
   const handleClose = () => {
      setRichTextValue('');
      setLocalNewSubject('');
      onClose();
      setSubject('');
      setIsClicked(false);
      setIsChangedTo(false);
   };
   const handleThreeDot = (event) => {
      event.stopPropagation();
      setIsClicked(!isClicked);
   };

   const { enqueueSnackbar } = useSnackbar();
   const [prepareToUpload, setPrepareToUpload] = useState(false);

   const resizeFile = async (file) => {
      if (file.type.includes('image')) {
         return new Promise((resolve) => {
            Resizer.imageFileResizer(
               file,
               1920,
               1080,
               'JPEG',
               80,
               0,
               (uri) => {
                  resolve(uri);
               },
               'file',
            );
         });
      } else {
         return file;
      }
   };

   const handleFileInputChange = async (event) => {
      const files = event.target.files;
      if (!files || files.length === 0) {
         return;
      }

      setPrepareToUpload(true);

      const newFiles = Array.from(files).filter((file) => {
         return !state.media.find((addedFile) => addedFile.name === file.name);
      });

      if (newFiles.length === 0) {
         return;
      }

      const sizeCheckedImages = newFiles.filter((file) => {
         if (file.size > MAX_FILE_SIZE) {
            enqueueSnackbar(`"${file.name}" cannot be uploaded as it exceeds 10 MB size limit.`, {
               variant: 'error',
               autoHideDuration: 3500,
            });
            return false;
         }
         return true;
      });

      const compressedFiles = await Promise.all(
         sizeCheckedImages.map(async (image) => {
            return await resizeFile(image);
         }),
      );

      await Promise.all(compressedFiles).then((res) => {
         setState((prevState) => ({
            ...prevState,
            media: [...state.media, ...res],
         }));
      });

      setPrepareToUpload(false);

      // event.target.value = ''; // Reset file input
   };
   const handleRemoveFile = (index) => {
      state.media.splice(index, 1);
      const media = [...state.media];
      setState((s) => ({ ...s, media }));
   };
   const handleCancelUploads = () => {
      const media = [...state.media];
      setState((s) => ({ ...s, media }));
   };

   return (
      <MaterialModal
         className={classes.modal}
         open={open}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 500,
         }}
      >
         <Paper className={classes.modalPaper}>
            <Grid container className={classes.header}>
               {isReplyMessage ? (
                  <Typography variant='inherit'>{trimLongText(subject, 45)}</Typography>
               ) : (
                  <Typography variant='inherit'>{t['page_account_preferences_new_email_message']}</Typography>
               )}
            </Grid>

            <Grid className={classes.body}>
               <div
                  style={{
                     width: '100%',
                     display: 'grid',
                     gridTemplateColumns: '1fr 1fr',
                     padding: '1rem',
                  }}
               >
                  <div style={{ textAlign: 'left', display: 'flex', flexDirection: 'column' }}>
                     <div>
                        From:{' '}
                        <span style={{ color: '#157CFC' }}>
                           {isMineMessage()
                              ? from
                              : isDomainValid()
                              ? emailAccounts?.find(
                                   (item) => item.forwardingEmailAddress === emailData?.emailData?.envelope?.to[0],
                                )?.senderEmailAddress
                              : emailData?.emailData?.envelope?.to[0]}
                        </span>
                     </div>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '0.5rem' }}>
                     <span onClick={handleAddCc} style={{ color: showCc && '#157CFC', cursor: 'pointer' }}>
                        Cc:
                     </span>

                     <span onClick={handleAddBcc} style={{ color: showBcc && '#157CFC', cursor: 'pointer' }}>
                        Bcc:
                     </span>
                  </div>
               </div>
               <div style={{ borderTop: '0.5px solid #DBDBDB', padding: '0.3rem 1rem' }}>
                  <div style={{ display: 'flex' }}>
                     <span style={{ marginTop: '0.7rem' }}>To:</span>
                     {to && (
                        <ReactMultiEmail
                           /// Look at the this code again
                           emails={
                              isMineMessage() || isChangedTo
                                 ? to
                                 : [removedStringCharacterTo(emailData?.emailData?.from)]
                           }
                           onChange={(_to) => {
                              setIsChangedTo(true);
                              setTo(_to);
                           }}
                           className='custom-input-color custom-tag-color'
                           style={{
                              border: 'none',
                              color: '#157CFC',
                              maxHeight: '150px',
                              overflow: 'auto',
                           }}
                           onFocus={() => setFocused(true)}
                           onBlur={() => setFocused(false)}
                           getLabel={(email, index, removeEmail) => {
                              return (
                                 <div data-tag key={index}>
                                    <div data-tag-item>{email}</div>
                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                       ×
                                    </span>
                                 </div>
                              );
                           }}
                        />
                     )}
                  </div>
               </div>
               {showCc && (
                  <div style={{ borderTop: '0.5px solid #DBDBDB', padding: '0.3rem 1rem' }}>
                     <div style={{ display: 'flex' }}>
                        <span style={{ marginTop: '0.7rem' }}>Cc:</span>
                        {cc && (
                           <ReactMultiEmail
                              emails={
                                 isReplyMessage
                                    ? emailData?.emailData?.envelope?.from
                                       ? convertCc(emailData?.emailData?.to)
                                       : cc
                                    : []
                              }
                              onChange={(_cc) => {
                                 setCc(_cc);
                              }}
                              className='custom-input-color custom-tag-color'
                              style={{
                                 border: 'none',
                                 color: '#157CFC',
                                 maxHeight: '150px',
                                 overflow: 'auto',
                              }}
                              autoFocus={true}
                              onFocus={() => setFocused(true)}
                              onBlur={() => setFocused(false)}
                              getLabel={(email, index, removeEmail) => {
                                 return (
                                    <div data-tag key={index}>
                                       <div data-tag-item>{email}</div>
                                       <span data-tag-handle onClick={() => removeEmail(index)}>
                                          ×
                                       </span>
                                    </div>
                                 );
                              }}
                           />
                        )}
                     </div>
                  </div>
               )}
               {showBcc && (
                  <div style={{ borderTop: '0.5px solid #DBDBDB', padding: '0.3rem 1rem' }}>
                     <div style={{ display: 'flex' }}>
                        <span style={{ marginTop: '0.7rem' }}>Bcc:</span>
                        {bcc && (
                           <ReactMultiEmail
                              emails={isReplyMessage ? bcc : []}
                              onChange={(_bcc) => {
                                 setBcc(_bcc);
                              }}
                              className='custom-input-color custom-tag-color'
                              style={{
                                 border: 'none',
                                 color: '#157CFC',
                                 maxHeight: '150px',
                                 overflow: 'auto',
                              }}
                              autoFocus={true}
                              onFocus={() => setFocused(true)}
                              onBlur={() => setFocused(false)}
                              getLabel={(email, index, removeEmail) => {
                                 return (
                                    <div data-tag key={index}>
                                       <div data-tag-item>{email}</div>
                                       <span data-tag-handle onClick={() => removeEmail(index)}>
                                          ×
                                       </span>
                                    </div>
                                 );
                              }}
                           />
                        )}
                     </div>
                  </div>
               )}

               <div style={{ borderTop: '0.5px solid #DBDBDB', padding: '0.5rem 1rem' }}>
                  <div style={{ marginTop: '0.6rem', display: 'flex' }}>
                     <span>{t['page_support_typo_subject']}: </span>
                     <textarea
                        style={{
                           outline: 'none',
                           border: 'none',
                           fontWeight: '550',
                           fontFamily: 'Helvetica',
                           width: '100%',
                           resize: 'none',
                           flex: 1,
                           marginBottom: '-0.5rem',
                        }}
                        value={isReplyMessage ? subject : localNewSubject}
                        onChange={
                           isReplyMessage
                              ? (event) => setSubject(event.target.value)
                              : (event) => setLocalNewSubject(event.target.value)
                        }
                     />
                  </div>
               </div>
               <div style={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
                  <RichTextReply
                     text={state.text}
                     onValueChange={handleRichTextChange}
                     emailSignature={emailSignature !== '<br><br>' && emailSignature}
                     placeholder={t['enter_your_text_here']}
                  />
                  <IconButton
                     style={{ position: 'absolute', right: '3px', top: '-3px' }}
                     onClick={() => document.getElementById('fileInput').click()}
                  >
                     <AttachFileOutlinedIcon />
                  </IconButton>
                  <div
                     style={{
                        margin: '10px 1rem',
                        flex: '1',
                     }}
                  ></div>
               </div>
               {!isNewMessage && !noSubject && (
                  <div style={{ margin: '0 1rem' }}>
                     {isClicked && <div dangerouslySetInnerHTML={{ __html: emailData?.contentHtml }} />}
                     <MoreHorizIcon
                        onClick={handleThreeDot}
                        style={{
                           backgroundColor: '#dcdbdb',
                           height: '15px',
                           borderRadius: '6px',
                           marginTop: '12px',
                           cursor: 'pointer',
                        }}
                     />
                  </div>
               )}
               <div style={{ margin: '1rem 0 6rem 0' }}>
                  <FileUploadPreview
                     preparing={prepareToUpload}
                     width={width}
                     files={state.media}
                     onRemoveFile={handleRemoveFile}
                     onCancel={handleCancelUploads}
                     maxFileSize={MAX_FILE_SIZE}
                  />
               </div>
               <input
                  id='fileInput'
                  type='file'
                  multiple
                  hidden
                  onChange={handleFileInputChange}
                  accept='image/*, audio/*, video/*, .pdf, .doc, .docx, .ppt, .pptx, .txt'
               />
            </Grid>
            <div className={classes.btnBox}>
               <ModalButtonField>
                  <Button
                     className={classes.modalButtons}
                     onClick={handleClose}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        '&:hover': {
                           backgroundColor: '#e0e0e0',
                        },
                     }}
                  >
                     Cancel
                  </Button>
                  <ButtonOK
                     onClick={handleSubmit}
                     className={classes.modalButtons}
                     label='Send'
                     type='submit'
                     disabled={!to || to?.length === 0 || !subject || subject?.length === 0}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        '&:hover': {
                           backgroundColor: '#105CBB',
                        },
                        float: 'right',
                     }}
                  />
               </ModalButtonField>
            </div>
         </Paper>
      </MaterialModal>
   );
};

export default Modal;

import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React, { useContext } from 'react';
import Resizer from 'react-image-file-resizer';

const useStyles = makeStyles(() => ({
   sampleMedia: {
      paddingBottom: 0,
      marginBlock: 10,
   },
   title: {
      fontSize: 20,
      paddingBottom: 10,
      paddingLeft: 4,
   },
   mediaBox: {
      width: '100%',
      userSelect: 'none',
      height: 50,
      border: '2px dashed lightgrey',
      borderRadius: 8,
      paddingTop: 2,
      marginBlock: 10,
      textAlign: 'center',
   },
}));
const SampleMedia = ({ _templateType, isView, watch, setValue }: any) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   const inputRef = React.useRef<HTMLInputElement>(null);
   const { enqueueSnackbar } = useSnackbar();

   const resizeFile = (file: any, ext: any) =>
      new Promise((resolve) => {
         Resizer.imageFileResizer(
            file,
            1920,
            1080,
            ext,
            80,
            0,
            (uri) => {
               resolve(uri);
            },
            'file'
         );
      });

   const kbValueNumberFormatter = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow',
   });

   const handleUploadFiles = async (files: any) => {
      if (!files || !files[0]) {
         return;
      }
      let file = files[0];
      let [file_type, file_ext] = file.type.split('/');
      let size_limit = file_type === "png" || file_type === "jpeg" ? 2000000 : file_type === "mp4" ? 20000000 : 2000000;
      if (file.size > size_limit) {
         enqueueSnackbar(`${t?.campaign_upload_size_limit.replace("size", kbValueNumberFormatter.format(size_limit))}`, {
            variant: 'error',
            autoHideDuration: 1500,
         });
         return false;
      }
      const file_path = `C:/Users/Shared/Desktop/${file.name}`;
      var _file;
      switch (templateType) {
         case "IMAGE":
            if (file_ext === 'png' || file_ext === 'jpg' || file_ext === 'jpeg') {
               _file = await resizeFile(file, file_ext);
            } else {
               enqueueSnackbar(`${t?.wrong_file_format}`, {
                  variant: 'error',
                  autoHideDuration: 1500,
               });
               return false;
            }
            break;
         case "VIDEO":
            if (file_ext === 'mp4') {
               _file = file;
            } else {
               enqueueSnackbar(`${t?.wrong_file_format}`, {
                  variant: 'error',
                  autoHideDuration: 1500,
               });
               return false;
            }
            break;
         case "DOCUMENT":
            if (file_ext === 'pdf') {
               _file = file;
            } else {
               enqueueSnackbar(`${t?.wrong_file_format}`, {
                  variant: 'error',
                  autoHideDuration: 1500,
               });
               return false;
            }
            break;
         case "TEXT":
            break;
         default:
            throw new Error("Invalid template type");
      }
      setValue('sampleMedia', {
         name: _file.name,
         size: _file.size,
         type: _file.type,
         file: _file,
      });
      setValue('path', file_path);
      setValue('exampleMedia', file_path);
   }
   let templateType = (_templateType?.value || _templateType);
   return (
      <Grid container className={classes.sampleMedia}>
         <Grid className={classes.title}>
            <Typography variant='inherit'>
               {t['settings_whatsapp_business_template_form_sample_media_header']}
            </Typography>
         </Grid>
         <Grid>
            <Typography style={{ paddingLeft: 3 }}>
               {t['settings_whatsapp_business_template_form_sample_media_text']}
            </Typography>
         </Grid>
         <Grid container>
            <div className={classes.mediaBox} style={{ cursor: isView ? 'not-allowed' : 'pointer' }} onClick={() => isView ? null : inputRef?.current?.click()}>
               <Grid container alignItems='center' justifyContent='center'>
                  <Typography style={{ fontSize: 18, marginTop: 10, width: 250 }} color='primary' variant='inherit' noWrap>
                     {!watch('path') ? `+ ${t['settings_whatsapp_business_template_form_sample_media_add_media']}` : watch('path').split("/").slice(-1)[0]}
                  </Typography>
                  <input onChange={(e) => {
                     handleUploadFiles(e.target.files);
                     e.target.value = '';
                  }}
                     type='file' ref={inputRef} hidden
                     accept={
                        templateType === "IMAGE" ? '.png,.jpg,.jpeg' : templateType === "VIDEO" ? '.mp4' : '.pdf'
                     }
                  />
               </Grid>
            </div>
         </Grid>
      </Grid>
   );
};

export default SampleMedia;

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography, Grid, Divider } from '@material-ui/core';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText, ModalTitle } from '@components/_Modal/ModalTexts';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 650,
      padding: 20,
      paddingBottom: 5, // width: 700,
      height: 440,
      textAlign: 'center',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   modalTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.3rem',
   },
   iconSize: {
      fontSize: '5rem',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const ModalContent = ({ quickAnswer, onAccept, onCancel, mode }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   // only for test
   return (
      <Grid>
         <Grid container direction='column' alignItems='center'>
            <ModalLargeText style={{ marginBlock: 30 }}>{t?.you_will_update_preferences}</ModalLargeText>
            <ModalButtonField>
               <ButtonCancel
                  className={classes.modalButtons}
                  onClick={onCancel}
                  label={t?.general_message_no}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
               <ButtonOK
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     '&:hover': {
                        backgroundColor: '#105CBB',
                     },
                     float: 'right',
                  }}
                  onClick={() => onAccept()}
                  label={t?.general_message_yes}
               />
            </ModalButtonField>
         </Grid>
      </Grid>
   );
};

export default ModalContent;

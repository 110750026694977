import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { CustomerSatisfaction } from '@assets/icons/SettingsIcons/settingsIcons';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
   StatisticsChatIcon,
   StatisticsMessageIcon,
   // StatisticsQuestionCommentIcon,
   // StatisticsQuestionConversationIcon,
   StatisticsTagIcon,
   StatisticsUserIcon,
} from '@assets/icons/StatisticsPageIcons';
import { LanguageContext } from '@helper/locale/langContext';
import LeftMenuItem from '@components/LeftMenuItem';
import { ShoppingBasket } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
   main: {
      height: '100%',
      width: '100%',
      // paddingTop: 10,
      paddingLeft: 0,
      borderRadius: 15,
      overflowY: 'auto',
      background: '#F7F7FA',
   },
   root: {
      padding: 10,
      width: '100%',
      height: '100%',
   },
   containerClass: {
      paddingLeft: theme.spacing(3),
      height: 50,
      [theme.breakpoints.down('md')]: {
         paddingLeft: 0,
      },
      marginTop: 5,
      fontWeight: '500',
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   icon: {
      width: 25,
      height: 25,
   },
   appBarSpacer: theme.mixins.toolbar,
}));

const getMenuItems = (t) => [
   {
      title: t['page_statistics_typo_messages'],
      url: '/statistics',
      icon: (className) => <StatisticsMessageIcon className={className} />,
      items: [],
   },
   {
      title: t['page_statistics_typo_customers'],
      url: '/statistics/contacts',
      icon: (className) => <StatisticsChatIcon className={className} />,
      items: [],
   },
   // {
   //    title: t['page_statistics_typo_questions'],
   //    url: '/statistics/question-messages',
   //    icon: (className) => <StatisticsQuestionCommentIcon className={className} />,
   //    items: [],
   // },
   // {
   //    title: t['page_statistics_typo_question_chats'],
   //    url: '/statistics/question-chats',
   //    icon: (className) => <StatisticsQuestionConversationIcon className={className} />,
   //    items: [],
   // },
   {
      title: t['page_statistics_typo_tags'],
      url: '/statistics/tags',
      icon: (className) => <StatisticsTagIcon className={className} />,
      items: [],
   },
   {
      title: t['page_statistics_typo_users'],
      url: '/statistics/users',
      icon: (className) => <StatisticsUserIcon className={className} />,
      items: [],
   },
   {
      title: t['page_statistics_typo_customer_satisfaction'],
      url: '/statistics/customer-satisfaction',
      icon: (className) => <CustomerSatisfaction className={className} />,
      items: [],
   },
   {
      title: t['page_statistics_typo_abandoned_carts'],
      url: '/statistics/abandoned-carts',
      icon: (className) => <ShoppingBasket className={className} />,
      items: [],
   },
];

const StaticsPanel = () => {
   const history = useHistory();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const MOCK_STATISTICS_MENU = getMenuItems(t);
   const classes = useStyles();

   return (
      <Grid className={classes.root}>
         <div className={classes.appBarSpacer} />
         <Paper elevation={3} className={classes.main}>
            {MOCK_STATISTICS_MENU.map((section) => (
               <LeftMenuItem
                  key={section.url}
                  url={section.url}
                  selected={section.url === history.location.pathname}
                  icon={section.icon}
                  label={section.title}
                  onClick={(e) => {
                     if (history.location.pathname === section.url) e.preventDefault();
                  }}
                  containerClass={classes.containerClass}
                  iconClass={classes.icon}
               />
            ))}
         </Paper>
      </Grid>
   );
};

export default StaticsPanel;

import {
   Avatar,
   Grid,
   Table,
   TableHead,
   TableBody,
   TableCell,
   TableContainer,
   TableRow,
   Typography,
} from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import PieChart from './pieChart';
import GaugeChart from 'react-gauge-chart';
import { useSelector } from 'react-redux';

const StyledTableCell = withStyles((theme) => ({
   root: {
      padding: 0,
   },
   head: {
      backgroundColor: theme.palette.common.white,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      // borderBottom: '3px solid ' + theme.chatApp.general.pallet.passiveStructureBlue,
      //   fontWeight:'bold'
   },
   body: {
      fontSize: 14,
   },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
   root: {
      border: '1px solid #eee',
   },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
   title: {
      color: theme.chatApp.workspace.sideBar.backgroundColor,
   },
   root: {
      borderLeft: '5px solid ' + theme.chatApp.general.pallet.passiveStructureBlue,
   },
   table: {
      minWidth: 700,
      width: '100%',
      // borderCollapse: 'separate',
      // borderSpacing: '0px 10px',
      // border: '1px solid red',
   },
   padding10: {
      padding: 12,
   },
   emptyList: {
      fontSize: '1.3rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      marginTop: 40,
   },
   tableRoot: {},
   mainColor: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
}));

const Users = ({ users, totalSentMessages, totalActiveChats, totalSolvedChats, report }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);

   const parseTime = (ms) => {
      let hour = Math.floor(ms / 3600000);
      let minute = Math.floor((ms % 3600000) / 60000);
      let second = Math.floor(((ms % 3600000) % 60000) / 1000);
      let newTime;

      if (hour > 0) {
         newTime = `${hour}h ${minute}m ${second}s`;
      } else if (minute > 0) {
         newTime = `${minute}m ${second}s`;
      } else {
         newTime = `${second}s`;
      }
      return newTime;
   };

   //clone users
   let _users = JSON.parse(JSON.stringify(users));
   let _report = JSON.parse(JSON.stringify(report));
   let unfoundUsers = report.filter(
      (x) =>
         users.filter((y) => y._id === x._id).length === 0 &&
         (x.sentMessageCount !== 0 || x.joinedChatCount !== 0 || x.solvedChatCount !== 0),
   );

   if (unfoundUsers.length) {
      let joinedChatCount = unfoundUsers.reduce((a, b) => a + b.joinedChatCount, 0);
      let sentMessageCount = unfoundUsers.reduce((a, b) => a + b.sentMessageCount, 0);
      let solvedChatCount = unfoundUsers.reduce((a, b) => a + b.solvedChatCount, 0);
      let percentActive = unfoundUsers.reduce((a, b) => a + b.percentActive, 0);
      let percentSolved = unfoundUsers.reduce((a, b) => a + b.percentSolved, 0);
      let percentSent = unfoundUsers.reduce((a, b) => a + (b.percentSent || 0), 0);
      let resolutionTime = unfoundUsers.reduce((a, b) => a + b.resolutionTime, 0);
      let firstResponseTime = unfoundUsers.reduce((a, b) => a + b.firstResponseTime, 0);
      let firstResponseCount = unfoundUsers.reduce((a, b) => a + b.firstResponseCount, 0);

      let user = {
         _id: '-1',
         email: null,
         photo: null,
         name: t['page_statistics_typo_deleted_users'],
         surname: '',
      };
      let report = {
         _id: '-1',
         joinedChatCount,
         sentMessageCount,
         solvedChatCount,
         resolutionTime,
         firstResponseTime,
         firstResponseCount,
         percentActive,
         percentSolved,
         percentSent,
      };
      _report.push(report);
      _users.push(user);
   }

   _report = _report.sort((a, b) => b.joinedChatCount - a.joinedChatCount).reverse();
   _users = _users
      .sort((a, b) => {
         let indexA = _report.findIndex((x) => x._id === a._id);
         let indexB = _report.findIndex((x) => x._id === b._id);
         return indexA - indexB;
      })
      .reverse();

   // read all personnel and take the count from there.
   // get customer persons
   return (
      <Grid item xs={12}>
         <TableContainer>
            {!(totalSentMessages === 0 && totalActiveChats === 0 && totalSolvedChats === 0) ? (
               <Table className={classes.table} aria-label='customized table'>
                  <TableHead>
                     <TableRow>
                        <StyledTableCell>{t['page_statistics_typo_users_table_user']}</StyledTableCell>
                        <StyledTableCell>{t['page_statistics_typo_users_table_joined_chat']}</StyledTableCell>
                        <StyledTableCell>{t['page_statistics_typo_users_table_sent_messages']}</StyledTableCell>
                        <StyledTableCell>{t['page_statistics_typo_users_table_solved_chat']}</StyledTableCell>
                        <StyledTableCell>
                           {t['page_statistics_typo_users_table_avarage_resolution_time']}
                        </StyledTableCell>
                        <StyledTableCell>
                           {t['page_statistics_typo_users_table_avarage_first_response_time']}
                        </StyledTableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {_users &&
                        _users.map((row, index) => (
                           <StyledTableRow key={row._id}>
                              <StyledTableCell component='th' scope='row'>
                                 <Grid container alignItems={'center'} style={{ paddingLeft: 4 }} spacing={1}>
                                    <Grid item>
                                       <Avatar
                                          style={{
                                             width: 48,
                                             height: 48,
                                          }}
                                          src={row?.photo}
                                       />
                                    </Grid>
                                    <Grid item>
                                       <Typography className={classes.mainColor} variant='subtitle1' color='initial'>
                                          {row.name + ' ' + row.surname}
                                       </Typography>
                                       <Typography color='textSecondary' variant='body2'>
                                          {row.email}
                                       </Typography>
                                    </Grid>
                                 </Grid>
                              </StyledTableCell>
                              <StyledTableCell component='th' scope='row'>
                                 <PieChart
                                    value={_report.find((user) => user._id === row._id)?.joinedChatCount || 0}
                                    color='#157CFC'
                                    totalValue={totalActiveChats}
                                    percent={_report.find((user) => user._id === row._id)?.percentActive || 0}
                                 />
                              </StyledTableCell>
                              <StyledTableCell component='th' scope='row'>
                                 <PieChart
                                    title={t['page_statistics_typo_users_table_sent_messages']}
                                    value={_report.find((user) => user._id === row._id)?.sentMessageCount || 0}
                                    color='#F19BA5'
                                    totalValue={totalSentMessages}
                                    percent={_report.find((user) => user._id === row._id)?.percentSent || 0}
                                 />
                              </StyledTableCell>
                              <StyledTableCell component='th' scope='row'>
                                 <PieChart
                                    title={t['page_statistics_typo_users_table_solved_chat']}
                                    value={_report.find((user) => user._id === row._id)?.solvedChatCount || 0}
                                    color='#5AC19D'
                                    totalValue={totalSolvedChats}
                                    percent={_report.find((user) => user._id === row._id)?.percentSolved || 0}
                                 />
                              </StyledTableCell>
                              <StyledTableCell component='th' scope='row'>
                                 <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
                                    <GaugeChart
                                       id={row._id}
                                       nrOfLevels={420}
                                       arcPadding={0}
                                       cornerRadius={0}
                                       arcsLength={person.customer?.teamGoalTime === 0 ? [1] : [0.4, 0.1, 0.5]}
                                       colors={
                                          person.customer?.teamGoalTime === 0
                                             ? ['#5BE12C']
                                             : ['#5BE12C', '#F5CD19', '#EA4228']
                                       }
                                       percent={(() => {
                                          let targetMs = person.customer?.teamGoalTime;
                                          if (targetMs === 0) return 0.5;
                                          let resolutionTime =
                                             _report.find((user) => user._id === row._id)?.resolutionTime || 0;
                                          let solvedChatCount =
                                             _report.find((user) => user._id === row._id)?.solvedChatCount || 0;
                                          let avgResolutionTime = resolutionTime / solvedChatCount;
                                          let percent = !avgResolutionTime
                                             ? 0
                                             : (avgResolutionTime / (targetMs * 2)) * 1.0;
                                          return percent > 1.0 ? 1.0 : percent;
                                       })()}
                                       animate={false}
                                       textColor='#157cfc'
                                       hideText={true}
                                       style={{ width: 100, height: 50, color: 'blue' }}
                                    />
                                    <Typography className={classes.mainColor} variant='subtitle1' color='initial'>
                                       {(() => {
                                          let resolutionTime =
                                             _report.find((user) => user._id === row._id)?.resolutionTime || 0;
                                          let solvedChatCount =
                                             _report.find((user) => user._id === row._id)?.solvedChatCount || 0;
                                          return parseTime(!resolutionTime ? 0 : resolutionTime / solvedChatCount);
                                       })()}
                                    </Typography>
                                 </Grid>
                              </StyledTableCell>
                              <StyledTableCell component='th' scope='row'>
                                 <Grid container direction='row' alignItems='center' justifyContent='flex-start'>
                                    <GaugeChart
                                       id={row._id}
                                       nrOfLevels={10}
                                       cornerRadius={2}
                                       arcsLength={
                                          person.customer?.teamFirstResponseGoalTime === 0
                                             ? [1]
                                             : [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]
                                       }
                                       colors={
                                          person.customer?.teamFirstResponseGoalTime === 0
                                             ? ['#5BE12C']
                                             : [
                                                  '#5BE12C',
                                                  '#5BE12C',
                                                  '#5BE12C',
                                                  '#5BE12C',
                                                  '#F5CD19',
                                                  '#EA4228',
                                                  '#EA4228',
                                                  '#EA4228',
                                                  '#EA4228',
                                                  '#EA4228',
                                               ]
                                       }
                                       percent={(() => {
                                          let targetMs = person.customer?.teamFirstResponseGoalTime;
                                          if (targetMs === 0) return 0.5;
                                          let firstResponseTime =
                                             _report.find((user) => user._id === row._id)?.firstResponseTime || 0;
                                          let firstResponseCount =
                                             _report.find((user) => user._id === row._id)?.firstResponseCount || 0;
                                          let avgFirstResponseTime = firstResponseTime / firstResponseCount;
                                          let percent = !avgFirstResponseTime
                                             ? 0
                                             : (avgFirstResponseTime / (targetMs * 2)) * 1.0;
                                          return percent > 1.0 ? 1.0 : percent;
                                       })()}
                                       animate={false}
                                       textColor='#157cfc'
                                       hideText={true}
                                       style={{ width: 100, height: 50, color: 'blue' }}
                                    />
                                    <Typography className={classes.mainColor} variant='subtitle1' color='initial'>
                                       {(() => {
                                          let firstResponseTime =
                                             _report.find((user) => user._id === row._id)?.firstResponseTime || 0;
                                          let firstResponseCount =
                                             _report.find((user) => user._id === row._id)?.firstResponseCount || 0;
                                          return parseTime(
                                             !firstResponseTime ? 0 : firstResponseTime / firstResponseCount,
                                          );
                                       })()}
                                    </Typography>
                                 </Grid>
                              </StyledTableCell>
                           </StyledTableRow>
                        ))}
                  </TableBody>
               </Table>
            ) : (
               <Grid xs={12} container justifyContent='center' style={{ height: 100 }}>
                  <Typography className={classes.emptyList}>{t?.your_user_list_is_empty}</Typography>
               </Grid>
            )}
         </TableContainer>
      </Grid>
   );
};

export default Users;

import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import { getPerson } from '@store/reducers/person';
import Event from './Event/event';
import SurveyEvent from './Event/surveyEvent';
import Message from './Message';
import ProductCards from './ProductCards';
import DateSeperator from '@modules/Chat/MainPanel/ChatSpace/ChatItemsContainer/DateSeparator/dateSeparator';
import { PLATFORM_TYPE } from '../../../../Chat/types';
import WhatsAppBusinessTemplate from '@modules/Chat/MainPanel/ChatSpace/ChatItemsContainer/WhatsAppBusinessTemplate';

const getMessageType = (message) => {
   const medias = message.media;
   if (!medias) return;
   if (medias.length === 0) {
      return 'TEXT';
   } else if (medias.every((media) => media.type?.includes('image'))) {
      return 'IMAGE';
   } else if (medias.every((media) => media.type?.includes('video'))) {
      return 'VIDEO';
   } else {
      return 'FILE';
   }
};

const MessageItem = ({ item, previousMessage, isEmail }) => {
   const me = useSelector(getPerson);
   const {
      lang: { shortTitle: lang },
   } = useContext(LanguageContext);

   const time = moment(item.createdAt).format(lang === 'EN' ? 'hh:mm A' : 'HH:mm');
   const isMine = useMemo(() => {
      if (item.sender) {
         return item.sender?._id === me._id || item?.sender?.type >= 0;
      } else {
         return true;
      }
   }, [item.sender]);

   const showAvatarOnMessage = previousMessage?.sender?._id !== item.sender?._id && isMine;
   switch (item.type) {
      case 0:
         return (
            <Message
               messageObj={item}
               text={item.content}
               emailData={item.emailData}
               subject={item.subject}
               contentHtml={item.contentHtml}
               ack={item.ack}
               senderTitle={item.sender?.name}
               type={getMessageType(item)}
               medias={item.media}
               time={time}
               senderPhoto={item.sender?.photo}
               isMine={isMine}
               personPhoto={me?.photo}
               showAvatarOnMessage={showAvatarOnMessage}
               isSending={item?.isSending}
               isAutoCreate={item?.autoCreate}
               isEmail={isEmail}
            />
         );
      case 1:
      case 13:
         return (
            <Message
               messageObj={item}
               text={item.content}
               ack={item.ack}
               senderTitle={item.sender?.name}
               type={getMessageType(item)}
               medias={item.media}
               time={time}
               isBulkMessage={true}
               senderPhoto={item.sender?.photo}
               isMine={isMine}
               personPhoto={me?.photo}
               showAvatarOnMessage={showAvatarOnMessage}
               isSending={item?.isSending}
               isAutoCreate={item?.autoCreate}
            />
         );
      case 2:
         return (
            <ProductCards messageObj={item} ack={item.ack} elements={item.template_cards?.elements} isMine={isMine} />
         );
      case 3:
         return <WhatsAppBusinessTemplate item={item} ack={item.ack} time={time} senderTitle={item.sender?.name} />;
      case 4:
         return (
            <Message
               senderPhoto={item.sender?.photo}
               senderTitle={item.sender?.name}
               isPrivate={true}
               text={item.content}
               medias={item.media}
               time={time}
               showAvatarOnMessage={showAvatarOnMessage}
               isMine={true}
               isSending={item?.isSending}
               isEmail={isEmail}
            />
         );
      case 5:
         return <Event text={item.content} time={time} sender={item.sender} />;
      case 6:
         return (
            <Message
               messageObj={item}
               text={item.content}
               ack={item.ack}
               senderTitle={item.sender?.name}
               type={getMessageType(item)}
               medias={item.media}
               privateReplyQnaId={item.QnAId}
               time={time}
               senderPhoto={item.sender?.photo}
               isMine={isMine}
               personPhoto={me?.photo}
               showAvatarOnMessage={showAvatarOnMessage}
               isSending={item?.isSending}
               isAutoCreate={item?.autoCreate}
               isEmail={isEmail}
            />
         );
      case 7:
         return (
            <Message
               messageObj={item}
               text={item.content}
               ack={item.ack}
               senderTitle={item.sender?.name}
               type={getMessageType(item)}
               medias={item.media}
               time={time}
               isBroadcast={true}
               senderPhoto={item.sender?.photo}
               isMine={isMine}
               personPhoto={me?.photo}
               showAvatarOnMessage={showAvatarOnMessage}
               isSending={item?.isSending}
               isAutoCreate={item?.autoCreate}
               isEmail={isEmail}
            />
         );
      case 8:
         return (
            // İf platformtype is livechat, dont show message
            item?.chat?.type !== PLATFORM_TYPE.LIVE_CHAT && (
               <Message
                  chatType={item?.chat?.type}
                  messageObj={item}
                  text={item.content}
                  ack={item.ack}
                  senderTitle={item.sender?.name}
                  type={getMessageType(item)}
                  medias={item.media}
                  time={time}
                  senderPhoto={item.sender?.photo}
                  isMine={isMine}
                  personPhoto={me?.photo}
                  showAvatarOnMessage={showAvatarOnMessage}
                  isSending={item?.isSending}
                  isAutoCreate={item?.autoCreate}
               />
            )
         );
      case 9:
         return <DateSeperator time={item.createdAt} />;
      case 10:
         return <SurveyEvent text={item.content} time={time} rating={item?.surveyData?.rating} />;
      default:
         console.log(`Type of item ${JSON.stringify(item)} not found!`, item);
         return null;
   }
};

export default MessageItem;

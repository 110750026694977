import {
   DashboardActiveIcon,
   DashboardSolvedIcon,
   DashboardTimeOutIcon,
   DashboardWaitingIcon
} from '@assets/icons/DashboardIcons/dashboardIcons';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SET_CHAT_FILTERS } from '@store/actions/chat';
import { getPerson } from "@store/reducers/person";
import clsx from 'classnames';
import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SmallChip from './smallChip';

const useStyles = makeStyles((theme) => ({
   topSectionRoot: {
      [theme.breakpoints.down('sm')]: {
         width: '100%',
         marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('md')]: {
         width: '24%',
         marginBottom: theme.spacing(2),
      },
      [theme.breakpoints.up('lg')]: {
         width: '24%',
         marginBottom: theme.spacing(2),
      },
   },
   true: {
      marginBottom: theme.spacing(1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: 300,
      alignSelf: 'left',
   },
   item: {
      width: '48%',
      // backgroundColor: 'red',
      // marginLeft: theme.spacing(3),
      marginLeft: 20,
   },
   icon: {
      width: ' 36px',
      height: '36px',
      marginBottom: '20px',
   },
}));

const SmallChipSection = ({
   activeChatsCount,
   spammedChatsCount,
   solvedChatsCount,
   waitingChatsCount,
   closedChatsCount,
}) => {
   const isSpecResolution = useMediaQuery('@media(max-width:1120px) and (min-width:750px)');
   const classes = useStyles();
   const history = useHistory();
   const dispatch = useDispatch();
   const person = useSelector(getPerson);
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const ITEMS = [
      {
         icon: <DashboardWaitingIcon className={classes.icon} />,
         title: t?.page_dashboard_typo_waiting,
         count: waitingChatsCount,
         type: 'WAITING',
         redirectPage: '/chat?tab=COMPANY',
         dispatchPayload: {
            type: SET_CHAT_FILTERS,
            payload: { status: 0 },
         },
      },
      {
         icon: <DashboardActiveIcon className={classes.icon} />,
         title: t?.page_dashboard_typo_active,
         count: activeChatsCount,
         type: 'ACTIVE',
         redirectPage: '/chat?tab=YOURS',
         dispatchPayload: {
            type: SET_CHAT_FILTERS,
            payload: { me: person._id }
         },
      },
      {
         icon: <DashboardSolvedIcon className={classes.icon} />,
         title: t?.page_dashboard_typo_closed,
         count: solvedChatsCount,
         type: 'CLOSED',
         redirectPage: '/chat?tab=COMPANY',
         dispatchPayload: {
            type: SET_CHAT_FILTERS,
            payload: { status: 2 },
         },
      },
      {
         icon: <DashboardTimeOutIcon className={classes.icon} />,
         title: t?.page_dashboard_typo_archive,
         count: closedChatsCount,
         type: 'ARCHIVE',
         redirectPage: '/chat?tab=COMPANY',
         dispatchPayload: {
            type: SET_CHAT_FILTERS,
            payload: { status: 1 },
         },
      },
      // {
      //    icon: <DashboardSpamIcon className={classes.icon} />,
      //    title: t?.page_chat_typo_filter_title_spam_blocked,
      //    count: spammedChatsCount,
      //    type: 'SPAM_BLOCKED',
      // },
   ];

   return (
      <Grid
         container
         style={{ marginBottom: 8 }}
         className={clsx(classes.root, isSpecResolution ? classes.true : classes.root)}
         justifyContent='space-between'
      >
         {
            ITEMS.map((item) => (
               <Grid
                  key={item.type + item.title}
                  item
                  className={clsx(classes.topSectionRoot, isSpecResolution && classes.item)}
                  onClick={() => {
                     dispatch(item.dispatchPayload);
                     history.push(item.redirectPage);
                  }}
               >
                  <SmallChip icon={item.icon} title={item.title} count={item.count} />
               </Grid>
            ))
         }
      </Grid >
   );
};

export default SmallChipSection;

import React, { useContext, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';
import { RESEND_MAIL_MUTATION } from '../../../queries/Person/Service/GraphQL/mutation';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: '100vh',
   },
   container: {
      maxWidth: 640,
      textAlign: 'center',
   },
   resendLink: {
      textDecoration: 'underline',
      cursor: 'pointer',
   },
   contactLink: {
      color: 'black',
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
         color: 'black',
      },
   },
}));

const Confirmation = () => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar } = useSnackbar();

   const [resendMail, { loading }] = useMutation(RESEND_MAIL_MUTATION, {
      fetchPolicy: 'no-cache',
      onError: (err) => {
         enqueueSnackbar(err.message, {
            variant: 'error',
            autoHideDuration: 2500,
         });
      },
   });

   const classes = useStyles();
   const query = new URLSearchParams(useLocation().search);

   const [mailResent, setMailResent] = useState(false);

   const resendCodeHandler = async () => {
      try {
         await resendMail({ variables: { email: query.get('email') } });
         setMailResent(true);
      } catch (err) {
         console.log(err);
         setMailResent(false);
      }
   };

   if (loading) {
      return <div className='loading' />;
   }

   return (
      <Box className={classes.root}>
         <Box className={classes.container}>
            <span role='img' style={{ fontSize: '5rem' }}>
               {' '}
               👋{' '}
            </span>
            <Typography style={{ fontWeight: 600, fontSize: '1.75rem', paddingBottom: '1rem' }}>
               {' '}
               {t?.welcome_you_are_almost_done}
            </Typography>
            <Typography style={{ fontWeight: 500, fontSize: '1.2rem' }}>
               {' '}
               {t?.welcome_thanks_for_the_signing}
            </Typography>
            <Typography>
               {' '}
               {t?.welcome_we_have_sent_email} {query.get('email')}.{' '}
            </Typography>
            <Typography> {t?.welcome_verify_your_email_to_start} </Typography>

            {mailResent ? (
               <Typography style={{ paddingTop: '1rem' }}>
                  {' '}
                  {t?.welcome_we_resent_your_verification_email}
                  {query.get('email')}.{t?.welcome_if_you_dont_see_it_in_your_box}
                  <a href='https://www.qpien.com' className={classes.contactLink}>
                     {' '}
                     {t?.contact_us}
                  </a>
               </Typography>
            ) : (
               <Typography style={{ paddingTop: '1rem' }}>
                  {t?.welcome_didnt_receive_anything}
                  <span onClick={resendCodeHandler} className={classes.resendLink}>
                     {' '}
                     {t?.resend_code}
                  </span>
               </Typography>
            )}
         </Box>
      </Box>
   );
};

export default Confirmation;

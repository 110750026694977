import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import SingleInput from '@components/_Modal/SingleInput';
import { LanguageContext } from '@helper/locale/langContext';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MyTheme } from '@styles/config';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { EnumCustomFieldsType, ICustomFields } from './customFields';

const useStyles = makeStyles((theme: MyTheme) => ({
   saveModalOkButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
      color: 'white',
      backgroundColor: '#157CFC',
      '&:hover': {
         backgroundColor: '#105CBB',
      },
      float: 'right',
   },
   saveModalCancelButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
      color: '#212121',
      backgroundColor: '#eeee',
      '&:hover': {
         backgroundColor: '#e0e0e0',
      },
   },
   deleteModalCancelButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
      color: '#212121',
      backgroundColor: '#eeee',
      '&:hover': {
         backgroundColor: '#e0e0e0',
      },
   },
   deleteModalOkButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
      color: 'white',
      backgroundColor: '#ef5350',
      '&:hover': {
         backgroundColor: '#f44336',
      },
      float: 'right',
   },
}));

const ModalContent = ({
   onAccept,
   onEdit,
   onCancel,
   mode,
   onDelete,
   createdCustomFieldLoading,
   updatedCustomFieldLoading,
}: {
   mode: { show: boolean; operation: string; data: ICustomFields | null };
   onAccept: (data: {
      name: string;
      type: { label: string; value: string };
      listValues: string[];
      description: string;
   }) => void;
   onEdit: (data: {
      _id: string;
      name: string;
      type: { label: string; value: string };
      listValues: string[];
      description: string;
   }) => void;
   onCancel: () => void;
   onDelete: (_id: string | null) => void;
   createdCustomFieldLoading: boolean;
   updatedCustomFieldLoading: boolean;
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const CUSTOM_FİELD_TYPE = [
      { name: t.page_settings_typo_custom_field_type_text, value: 'TEXT' },
      { name: t.page_settings_typo_custom_field_type_number, value: 'NUMBER' },
      { name: t.page_settings_typo_custom_field_type_email, value: 'EMAIL' },
      { name: t.page_settings_typo_custom_field_type_phone, value: 'PHONE' },
      { name: t.page_settings_typo_custom_field_type_url, value: 'URL' },
      { name: t.page_settings_typo_custom_field_type_date, value: 'DATE' },
      { name: t.page_settings_typo_custom_field_type_list, value: 'LIST' },
   ];

   const { handleSubmit, control, watch } = useForm({
      defaultValues: {
         name: mode.data ? mode.data.name : '',
         type: mode.data
            ? {
                 label: (
                    CUSTOM_FİELD_TYPE.find((item) => item.value === (mode.data as ICustomFields).type) ||
                    CUSTOM_FİELD_TYPE[0]
                 ).name,
                 value: mode.data.type,
              }
            : {
                 label: t?.page_settings_typo_custom_field_type_text,
                 value: 'TEXT',
              },
         listValues: mode.data ? mode.data.listValues.map((e) => ({ label: e, value: e })) : [],
         description: mode.data ? mode.data.description : '',
      },
   });

   const onSubmit = (data: {
      name: string;
      type: { label: string; value: string };
      listValues: {
         label: string;
         value: string;
      }[];
      description: string;
   }) => {
      if (mode.operation === 'EDIT') {
         onEdit({
            _id: (mode.data as ICustomFields)._id,
            ...data,
            listValues: watch('type').value === EnumCustomFieldsType.LIST ? data.listValues.map((e) => e.value) : [],
         });
      } else {
         onAccept({
            ...data,
            listValues: watch('type').value === EnumCustomFieldsType.LIST ? data.listValues.map((e) => e.value) : [],
         });
      }
   };

   const isValidCustomField = (data: {
      name: string;
      type: { label: string; value: string };
      description: string;
      listValues: { label: string; value: string }[];
   }) => {
      if (
         data.name.length === 0 ||
         data.type.value.length === 0 ||
         (data.type.value === EnumCustomFieldsType.LIST &&
            (data.listValues?.length === 0 || data.listValues?.length > 72)) ||
         !data.listValues
      ) {
         return false;
      }
      return true;
   };

   return (
      <Grid>
         {mode.operation === 'DELETE' ? (
            <Grid container direction='column' alignItems='center'>
               <ModalLargeText style={{ marginBottom: 30, marginTop: 20 }}>
                  {t?.page_settings_typo_delete_custom_field}
               </ModalLargeText>
               <ModalButtonField>
                  <ButtonCancel
                     className={classes.deleteModalCancelButtons}
                     onClick={onCancel}
                     label={t['comp_button_cancel']}
                  />
                  <ButtonOK
                     className={classes.deleteModalOkButtons}
                     onClick={() => {
                        mode.data && onDelete(mode.data._id);
                     }}
                     label={t['comp_button_delete']}
                  />
               </ModalButtonField>
            </Grid>
         ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
               <SingleInput
                  disabled={mode.operation === 'EDIT'}
                  label={t?.page_settings_typo_custom_field_type}
                  placeholder={t?.page_settings_typo_custom_field_type_placeholder}
                  name='type'
                  control={control}
                  editable
                  isSelect
                  selectOptions={CUSTOM_FİELD_TYPE.map((e) => ({
                     label: e.name,
                     value: e.value,
                  }))}
                  maxMenuHeight={190}
               />
               {watch('type').value === EnumCustomFieldsType.LIST && (
                  <SingleInput
                     customSelectStyles={
                        mode.operation === 'EDIT' && {
                           multiValueLabel: () => ({
                              padding: '0.1rem 0.5rem',
                              fontSize: '0.8rem',
                              color: 'hsl(0,0%,20%)',
                           }),
                           multiValueRemove: () => ({
                              display: 'none',
                           }),
                        }
                     }
                     isSelect
                     isMulti
                     isCreatable
                     autoHeight
                     name='listValues'
                     label={t?.page_settings_typo_custom_field_list_values}
                     control={control}
                     editable
                     rules={{
                        required: t?.please_fill_this_field,
                     }}
                     placeholder={t?.page_settings_typo_custom_field_list_values_placeholder}
                     isClearable={mode.operation === 'EDIT' ? false : true}
                     backspaceRemovesValue={mode.operation === 'EDIT' ? false : true}
                  />
               )}
               <SingleInput
                  label={t?.page_settings_typo_custom_field_name}
                  name='name'
                  control={control}
                  editable
                  placeholder={t?.page_settings_typo_custom_field_name_placeholder}
                  rules={{
                     required: t?.please_fill_this_field,
                     maxLength: {
                        value: 32,
                        message: t['page_setting_typo_custom_field_name_character_limit'],
                     },
                  }}
               />
               <SingleInput
                  label={t?.page_settings_typo_custom_field_description}
                  name='description'
                  control={control}
                  editable
                  placeholder={t?.page_settings_typo_custom_field_description_placeholder}
                  rules={{
                     maxLength: {
                        value: 72,
                        message: t['page_setting_typo_custom_field_description_character_limit'],
                     },
                  }}
               />
               <ModalButtonField>
                  <ButtonCancel
                     className={classes.saveModalCancelButtons}
                     onClick={onCancel}
                     label={t['comp_button_cancel']}
                  />
                  <ButtonOK
                     disabled={!isValidCustomField(watch())}
                     className={classes.saveModalOkButtons}
                     label={mode.operation === 'EDIT' ? t['comp_button_update'] : t['comp_button_save']}
                     type='submit'
                     endIcon={
                        (createdCustomFieldLoading || updatedCustomFieldLoading) && (
                           <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                        )
                     }
                  />
               </ModalButtonField>
            </form>
         )}
      </Grid>
   );
};

export default ModalContent;

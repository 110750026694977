import { gql } from '@apollo/client';

//query
export const GET_ACTIVE_CHAT_MESSAGES = gql`
   query getActiveChatMessages($chatId: ID!, $page: Int, $chatStartDate: Date) {
      getActiveChatMessages(data: { chatId: $chatId, page: $page, chatStartDate: $chatStartDate }) {
         data {
            messages {
               # isSending @client
               sendingStatusId
               messageType
               _id
               reason
               sender {
                  _id
                  name
                  surname
                  username
                  phone
                  email
                  photo
                  type
                  status
                  country
                  state
                  address
                  postCode
                  city
                  whatsAppWebPhone
                  whatsAppBusinessPhone
                  facebookId
                  instagramId
                  setup
                  createdAt
               }
               chat {
                  _id
                  type
               }
               type
               content
               contentHtml
               subject
               emailData {
                  from
                  to
                  cc
                  bcc
                  envelope {
                     from
                     to
                  }
               }
               media
               autoCreate
               template_cards
               readStatus
               platformMessageId
               QnAId
               # whatsAppWebId
               ack
               # whatsAppBusinessId
               personStatus
               createdAt
               surveyData {
                  rating
               }
            }
            totalDocs
            limit
            page
            totalPages
            hasNextPage
            nextPage
            hasPrevPage
            prevPage
            pagingCounter
         }
         success
         code
         message
      }
   }
`;

export const GET_ACTIVE_TEAM_CHAT_MESSAGES = gql`
   query getActiveTeamChatMessages($chatId: ID!, $page: Int) {
      getActiveTeamChatMessages(data: { chatId: $chatId, page: $page }) {
         data {
            messages {
               _id
               sender {
                  _id
                  name
                  surname
                  username
                  phone
                  email
                  photo
                  type
                  status
                  country
                  state
                  address
                  postCode
                  city
                  whatsAppWebPhone
                  whatsAppBusinessPhone
                  facebookId
                  instagramId
                  setup
                  createdAt
               }
               chat {
                  _id
                  type
               }
               type
               content
               media
               readStatus
               platformMessageId
               # whatsAppWebId
               ack
               # whatsAppBusinessId
               personStatus
               createdAt
            }
            totalDocs
            limit
            page
            totalPages
            hasNextPage
            nextPage
            hasPrevPage
            prevPage
            pagingCounter
         }
         success
         code
         message
      }
   }
`;

export const GET_URL_INFO = gql`
   query getUrlInfo($url: String!) {
      getUrlInfo(data: { url: $url }) {
         data {
            ogTitle
            ogUrl
            ogImage {
               url
               width
               height
               type
            }
            ogVideo {
               url
               width
               height
               type
            }
            ogType
            ogDescription
            requestUrl
            success
         }
         success
         code
         message
      }
   }
`;

import React, { useEffect, useState, useContext } from 'react';
import { Handle, Node, Position, useReactFlow } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { TriggerIcon } from '@modules/Automation/FlowBots/FlowBuilder/icons/icons';
import {
   FlowBlockType,
   IFlowBlockFieldRules,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles(() => ({
   container: {
      position: 'relative',
      display: 'inline-block',
   },
   triggerIcon: {
      width: 20,
      height: 20,
      margin: '0 5px 5px 5px',
   },
   customHeader: {
      display: 'flex',
      borderBottom: '0.1px solid #f2f2f2',
   },
   addText: {
      padding: '10px',
      margin: '5px',
      textAlign: 'center',
      borderRadius: 2,
      fontSize: 8,
      color: '#AFAFAF',
      backgroundColor: '#F7F7FA',
   },
   nodeContainer: {
      border: '1px solid #ffffff',
      width: '150px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'white',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   InvalidNodeContainer: {
      border: '1px solid #FF7474',
      width: '150px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'white',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   nodeContainerHovered: {
      border: '1px solid #157cfc',
   },
   footer: {
      borderTop: ' 0.1px solid #f2f2f2',
      height: '12px',
      position: 'relative',
   },

   handleEdgeConnected: {
      position: 'absolute',
      top: '8px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#ADADAD',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },

   blocks: {
      display: 'flex',
      flexDirection: 'column',
      padding: '2px 5px',
      fontSize: '9px',
   },

   subBlocks: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '6px',
      backgroundColor: '#F5F5F5',
      borderRadius: '5px',
   },
}));
interface TriggerNodeProps {
   id: string;
   isConnectable: boolean;
   data: {
      clickedNodeId: string;
      title: string;
      isSecondMessage: boolean;
   };
}
const TriggerNode = ({ id, isConnectable, data }: TriggerNodeProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { title, clickedNodeId, isSecondMessage } = data;

   const [isHover, setIsHover] = useState(false);

   useEffect(() => {
      setIsHover(id === clickedNodeId);
   }, [data, id, clickedNodeId]);

   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (id === clickedNodeId ? setIsHover(true) : setIsHover(false))}
         className={classes.container}
      >
         <div className={`${classes.nodeContainer} ${isHover ? classes.nodeContainerHovered : ''}`}>
            <div className={classes.customHeader}>
               <TriggerIcon className={classes.triggerIcon} />
               <div style={{ marginTop: '3px', fontSize: 10 }}>{title}</div>
            </div>

            <div>
               <div style={{ position: 'relative', margin: '5px' }}>
                  <div
                     style={{
                        backgroundColor: '#F7F7FA',
                        borderRadius: '5px',
                        padding: '5px',
                        fontSize: '9px',
                     }}
                  >
                     {t.automation_cart_abandonned}
                  </div>

                  <Handle
                     type='source'
                     position={Position.Right}
                     id={id}
                     className={classes.handleEdgeConnected}
                     isConnectable={isConnectable}
                  />
               </div>
               {isSecondMessage && (
                  <div style={{ position: 'relative', margin: '5px' }}>
                     <div
                        style={{
                           backgroundColor: '#F7F7FA',
                           borderRadius: '5px',
                           padding: '5px',
                           fontSize: '9px',
                        }}
                     >
                        Send Second Message
                     </div>

                     <Handle
                        type='source'
                        position={Position.Right}
                        id={id + 2}
                        className={classes.handleEdgeConnected}
                        isConnectable={isConnectable}
                     />
                  </div>
               )}
            </div>
         </div>
      </div>
   );
};
export default TriggerNode;

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import React from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';

const useStyles = makeStyles((theme) => ({
   paperDiv: {
      width: '100%',
      height: 75,
      marginBottom: 10,
   },
   imgContainer: {
      borderRadius: 5,
   },
   typography: {
      marginTop: 10,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
   },
   btn: {
      width: 30,
      height: 30,
   },
}));

const PhotoHolder = ({ media, onRemove, disabled }) => {
   const classes = useStyles();
   const kbValueNumberFormatter = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow',
   });

   let memoizedMedia = React.useMemo(() => {
      let [file_type, file_ext] = media.type.split('/');
      switch (file_type) {
         case 'image':
            return (
               <img
                  className={classes.imgContainer}
                  alt='phoneMessageContent'
                  width={75}
                  height={75}
                  src={media.path || URL.createObjectURL(media.file)}
               />
            );
         case 'video':
            return (
               <video
                  className={classes.imgContainer}
                  width={75}
                  height={75}
                  src={media.path || URL.createObjectURL(media.file)}
                  autoPlay={true}
                  loop
                  muted
               />
            );
         default:
            return (
               <img
                  className={classes.imgContainer}
                  width={75}
                  height={75}
                  alt='phoneMessageContent'
                  src={'/none.png'}
               />
            );
      }
   }, [media]);

   return (
      <Paper className={classes.paperDiv}>
         <Grid container direction='row'>
            <Grid item xs={3}>
               {memoizedMedia}
            </Grid>
            <Grid item xs={7}>
               <Typography sx={{ fontSize: 12 }} gutterBottom className={classes.typography}>
                  {media.name}
               </Typography>
               <Typography variant='body2' className={classes.typography}>
                  {kbValueNumberFormatter.format(media.size)}
               </Typography>
            </Grid>
            <Grid style={{ padding: 24 }} item xs={2}>
               <IconButton disabled={disabled} onClick={onRemove} className={classes.btn} aria-label='delete'>
                  <CancelIcon />
               </IconButton>
            </Grid>
         </Grid>
      </Paper>
   );
};

export default WithUrlTranslations(PhotoHolder);

import { gql } from '@apollo/client';

// mutations
export const CREATE_PLATFORM_MUTATION = gql`
   mutation createPlatformMutation($type: String!, $metaData: Object!) {
      createPlatform(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;
export const UPDATE_PLATFORM_MUTATION = gql`
   mutation updatePlatformMutation($type: String!, $metaData: Object!) {
      updatePlatform(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;
export const DELETE_PLATFORM_MUTATION = gql`
   mutation deletePlatformMutation($type: String!, $metaData: Object!) {
      deletePlatform(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;
export const GET_PLATFORM_PAGE_DATA = gql`
   mutation getPlatformPageDataMutation($type: String!, $metaData: Object!) {
      doCustomPlatformMutation(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;
export const DELETE_TEMPLATE_MUTATION = gql`
   mutation deleteTemplateMutation($data: templateId!) {
      deleteTemplate(data: $data) {
         data
         success
         code
         message
      }
   }
`;
export const CREATE_TEMPLATE_MUTATION = gql`
   mutation createTemplateMutation($data: template!) {
      createTemplate(data: $data) {
         success
         code
         message
      }
   }
`;

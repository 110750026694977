import React, { FC } from 'react';
import { Typography, ButtonBase, IconButton, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
      padding: 8,
      borderRadius: 4,
      border: '1px solid #D9E0E6',
      margin: '4px 8px',
      cursor: 'default',
   },
   cursorPointer: {
      cursor: 'pointer',
   },
   iconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: 40,
      height: 40,
      backgroundColor: '#aaa',
      borderRadius: 8,
   },
   textsContainer: {
      paddingLeft: 15,
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
   },
   body1: {
      color: '#385273',
      fontSize: '1.3em',
      lineHeight: 1.4,
      textTransform: 'none',
   },
   body2: {
      color: '#385273',
      opacity: '0.7',
      fontSize: '1em',
      lineHeight: 1.3,
      textTransform: 'none',
   },
}));

interface MenuItemProps {
   icon: React.ReactNode;
   title: string;
   subtitle: string;
   onClick?: () => void;
   containerStyle?: React.CSSProperties;
   cursorPointer?: boolean;
   headerRight?: React.ReactNode;
   loading?: boolean;
   subTitleButton?: React.ReactNode;
}

const MenuItem: FC<MenuItemProps> = ({
   icon,
   title,
   subtitle,
   onClick,
   containerStyle,
   cursorPointer = true,
   headerRight,
   loading = false,
   subTitleButton,
}) => {
   const classes = useStyles();

   return (
      <ButtonBase
         disableRipple
         onClick={onClick}
         className={classNames(classes.container, cursorPointer && classes.cursorPointer)}
         style={containerStyle}
         disabled={loading}
      >
         <div className={classes.iconContainer}>{icon}</div>
         <div className={classes.textsContainer}>
            <Typography variant='body1' noWrap className={classes.body1}>
               {title}
            </Typography>
            <Typography variant='body2' noWrap className={classes.body2}>
               {
                  subTitleButton || subtitle
               }
            </Typography>
         </div>
         {!headerRight && onClick && (
            <IconButton>{loading ? <CircularProgress size={24} /> : <ArrowForwardIosRoundedIcon />}</IconButton>
         )}
         {headerRight}
      </ButtonBase>
   );
};

export default MenuItem;

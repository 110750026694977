import languageOptions from '@helper/languageOptions';

const backEndToFrontEnd = (backEndData, defaultLanguageCode, defaultLanguageTranslations) => {
   return {
      // live chat status
      status: true,
      connected: backEndData.connected,
      // Appearance
      imgURL: backEndData.appearance.logo,
      greetingTitle: backEndData.appearance.greeting,
      greetingContent: backEndData.appearance.greetingContent,
      newChatTitle: backEndData.appearance.newChatTitle,
      newChatSubtitle: backEndData.appearance.newChatSubtitle,
      titleOldChat: backEndData.appearance.oldChatsTitle,
      themeColor: backEndData.appearance.themeColor,
      textColor: backEndData.appearance.textColor,
      iconSize: backEndData.appearance.liveChatIcon,
      iconLabel: backEndData.appearance.liveChatIconLabel,
      iconPosition: backEndData.appearance.liveChatIconPosition,
      liveChatIconType: backEndData.appearance.liveChatIconType,
      uploadedLiveChatIcon: backEndData.appearance.uploadedLiveChatIcon,
      spaceBottom: backEndData.appearance.spaceBottom,
      spaceSide: backEndData.appearance.spaceSide,
      id: backEndData._id,
      // Get Start
      greeting: backEndData.getStartedInfo.greetingMessage.status,
      greetingMessage1: backEndData.getStartedInfo.greetingMessage.firstMessage,
      greetingMessage2: backEndData.getStartedInfo.greetingMessage.secondMessage,
      welcomeMessageDuringWorkingHours: backEndData.getStartedInfo.welcomeMessageDuringWorkHours.status,
      welcomeMessageDuringWorkingHoursMessage1: backEndData.getStartedInfo.welcomeMessageDuringWorkHours.firstMessage,
      welcomeMessageDuringWorkingHoursMessage2: backEndData.getStartedInfo.welcomeMessageDuringWorkHours.secondMessage,
      welcomeMessageOutsideOfWorkingHours: backEndData.getStartedInfo.welcomeMessageOutsideWorkHours.status,
      welcomeMessageOutsideOfWorkingHoursMessage1:
         backEndData.getStartedInfo.welcomeMessageOutsideWorkHours.firstMessage,
      welcomeMessageOutsideOfWorkingHoursMessage2:
         backEndData.getStartedInfo.welcomeMessageOutsideWorkHours.secondMessage,
      contactInformation: backEndData.getStartedInfo.visitorContactInfo.status,
      contactInformationSelectedOption: { value: backEndData.getStartedInfo.visitorContactInfo.value },
      contactInformationTitle: backEndData.getStartedInfo.visitorContactInfo.title,
      contactInformationMessage: backEndData.getStartedInfo.visitorContactInfo.message,
      contactInformationFullName: backEndData.getStartedInfo.visitorContactInfo.fullName,
      contactInformationEmailAddress: backEndData.getStartedInfo.visitorContactInfo.emailAddress,
      contactInformationPhoneNumber: backEndData.getStartedInfo.visitorContactInfo.phoneNumber,
      contactInformationAddress: backEndData.getStartedInfo.visitorContactInfo.address,
      contactInformationFullNameRequired: backEndData.getStartedInfo.visitorContactInfo.fullNameRequired,
      contactInformationEmailAddressRequired: backEndData.getStartedInfo.visitorContactInfo.emailAddressRequired,
      contactInformationPhoneNumberRequired: backEndData.getStartedInfo.visitorContactInfo.phoneNumberRequired,
      contactInformationAddressRequired: backEndData.getStartedInfo.visitorContactInfo.addressRequired,
      contactInformationPermission: backEndData.getStartedInfo.visitorContactInfo.contactInformationPermission,
      contactInformationPermissionContent:
         backEndData.getStartedInfo.visitorContactInfo.contactInformationPermissionContent,
      contactInformationPermissionLinkUrl:
         backEndData.getStartedInfo.visitorContactInfo.contactInformationPermissionLinkUrl,
      contactInformationPermissionLinkText:
         backEndData.getStartedInfo.visitorContactInfo.contactInformationPermissionLinkText,
      contactInformationThanksMessage: backEndData.getStartedInfo.visitorContactInfo.thankYouMessage,
      rateUs: backEndData.getStartedInfo.visitorRateUs.status,
      rateUsTitle: backEndData.getStartedInfo.visitorRateUs.title,
      rateUsMessage: backEndData.getStartedInfo.visitorRateUs.message,
      rateUsThanksMessage: backEndData.getStartedInfo.visitorRateUs.thankYouMessage,
      minutesToClose: backEndData.getStartedInfo.minutesToClose,
      // Settings
      onlineStatus: backEndData.settings.showOnlineStatus,
      agentProfiles: backEndData.settings.showAgentProfiles,
      notificationSound: backEndData.settings.notificationSound,
      liveChatVisibility: backEndData.settings.liveChat?.visibility,

      isWhatsapp: backEndData.settings.whatsApp.status,
      whatsappButtonTitle: backEndData.settings.whatsApp.buttonTitle,
      whatsappTitle: backEndData.settings.whatsApp.title,
      whatsappContent: backEndData.settings.whatsApp.content,
      isFacebook: backEndData.settings.facebookMessanger.status,
      facebookButtonTitle: backEndData.settings.facebookMessanger.buttonTitle,
      facebookTitle: backEndData.settings.facebookMessanger.title,
      facebookContent: backEndData.settings.facebookMessanger.content,
      isInstagram: backEndData.settings.instagram.status,
      instagramButtonTitle: backEndData.settings.instagram.buttonTitle,
      instagramTitle: backEndData.settings.instagram.title,
      instagramContent: backEndData.settings.instagram.content,
      isPhoneCall: backEndData.settings.phoneCall.status,
      phoneCallButtonTitle: backEndData.settings.phoneCall.buttonTitle,
      phoneCallNumber: backEndData.settings.phoneCall.account,
      facebookAccount: backEndData.settings.facebookMessanger.account,
      whatsappAccount: backEndData.settings.whatsApp.account,
      instagramAccount: backEndData.settings.instagram.account,
      phoneCallTitle: backEndData.settings.phoneCall.title,
      phoneCallContent: backEndData.settings.phoneCall.content,

      // Installation
      trustedDomain1: backEndData.websiteDomains[0] || '',
      trustedDomain2: backEndData.websiteDomains[1] || '',
      trustedDomain3: backEndData.websiteDomains[2] || '',
      // languages
      selectedLanguages:
         Object.keys(backEndData.translations).map((value) => ({
            value,
            label: `${languageOptions.find((val) => val.value === value).englishName} - ${
               languageOptions.find((val) => val.value === value).nativeName
               }`,
            isFixed: true,
         })) || [],
      translation: {
         ...backEndData.translations,
         // [defaultLanguageCode]: {
         //    ...defaultLanguageTranslations,
         //    greetingTitle: backEndData.appearance.greeting,
         //    greetingContent: backEndData.appearance.greetingContent,
         //    newChatTitle: backEndData.appearance.newChatTitle,
         //    newChatSubtitle: backEndData.appearance.newChatSubtitle,
         //    titleOldChat: backEndData.appearance.oldChatsTitle,
         //    greetingMessage1: backEndData.getStartedInfo.greetingMessage.firstMessage,
         //    greetingMessage2: backEndData.getStartedInfo.greetingMessage.secondMessage,
         //    welcomeMessageDuringWorkingHoursMessage1: backEndData.getStartedInfo.welcomeMessageDuringWorkHours.firstMessage,
         //    welcomeMessageDuringWorkingHoursMessage2: backEndData.getStartedInfo.welcomeMessageDuringWorkHours.secondMessage,
         //    welcomeMessageOutsideOfWorkingHoursMessage1: backEndData.getStartedInfo.welcomeMessageOutsideWorkHours.firstMessage,
         //    welcomeMessageOutsideOfWorkingHoursMessage2: backEndData.getStartedInfo.welcomeMessageOutsideWorkHours.secondMessage,
         //    contactInformationTitle: backEndData.getStartedInfo.visitorContactInfo.title,
         //    contactInformationMessage: backEndData.getStartedInfo.visitorContactInfo.message,
         //    contactInformationThanksMessage: backEndData.getStartedInfo.visitorContactInfo.thankYouMessage,
         //    rateUsTitle: backEndData.getStartedInfo.visitorRateUs.title,
         //    rateUsMessage: backEndData.getStartedInfo.visitorRateUs.message,
         //    rateUsThanksMessage: backEndData.getStartedInfo.visitorRateUs.thankYouMessage,
         //    whatsappButtonTitle: backEndData.settings.whatsApp.buttonTitle,
         //    whatsappTitle: backEndData.settings.whatsApp.title,
         //    whatsappContent: backEndData.settings.whatsApp.content,
         //    facebookButtonTitle: backEndData.settings.facebookMessanger.buttonTitle,
         //    facebookTitle: backEndData.settings.facebookMessanger.title,
         //    facebookContent: backEndData.settings.facebookMessanger.content,
         //    instagramButtonTitle: backEndData.settings.instagram.buttonTitle,
         //    instagramTitle: backEndData.settings.instagram.title,
         //    instagramContent: backEndData.settings.instagram.content,
         //    phoneCallButtonTitle: backEndData.settings.phoneCall.buttonTitle,
         //    phoneCallTitle: backEndData.settings.phoneCall.title,
         //    phoneCallContent: backEndData.settings.phoneCall.content,
         // }
      },
      flowBotMessages: backEndData.flowBotMessages || [],
      flowBotTriggerFields: backEndData.flowBotTriggerFields || [],
      flowBotActive: backEndData.flowBotActive || false,
   };
};

const frontEndToBackEnd = (frontEndData, customerId) => {
   return {
      connected: frontEndData.connected,
      appearance: {
         logo: frontEndData.imgURL,
         greeting: frontEndData.greetingTitle,
         greetingContent: frontEndData.greetingContent,
         newChatTitle: frontEndData.newChatTitle,
         newChatSubtitle: frontEndData.newChatSubtitle,
         oldChatsTitle: frontEndData.titleOldChat,
         themeColor: frontEndData.themeColor,
         textColor: frontEndData.textColor,
         liveChatIcon: frontEndData.iconSize,
         liveChatIconLabel: frontEndData.iconLabel,
         liveChatIconPosition: frontEndData.iconPosition,
         customerId: customerId,
         liveChatIconType: frontEndData.liveChatIconType,
         uploadedLiveChatIcon: frontEndData.uploadedLiveChatIcon,
         spaceBottom: Number.isNaN(Number(frontEndData.spaceBottom)) || frontEndData.spaceBottom > 500 || frontEndData.spaceBottom < 0 ? 0 : frontEndData.spaceBottom,
         spaceSide: Number.isNaN(Number(frontEndData.spaceSide)) || frontEndData.spaceSide > 500 || frontEndData.spaceSide < 0 ? 0 : frontEndData.spaceSide,
      },
      translations: frontEndData.translation || {},
      getStartedInfo: {
         greetingMessage: {
            status: frontEndData.greeting,
            firstMessage: frontEndData.greetingMessage1,
            secondMessage: frontEndData.greetingMessage2,
         },
         welcomeMessageDuringWorkHours: {
            status: frontEndData.welcomeMessageDuringWorkingHours,
            firstMessage: frontEndData.welcomeMessageDuringWorkingHoursMessage1,
            secondMessage: frontEndData.welcomeMessageDuringWorkingHoursMessage2,
         },
         welcomeMessageOutsideWorkHours: {
            status: frontEndData.welcomeMessageOutsideOfWorkingHours,
            firstMessage: frontEndData.welcomeMessageOutsideOfWorkingHoursMessage1,
            secondMessage: frontEndData.welcomeMessageOutsideOfWorkingHoursMessage2,
         },
         visitorContactInfo: {
            status: frontEndData.contactInformation && (
               frontEndData.contactInformationFullName ||
               frontEndData.contactInformationEmailAddress ||
               frontEndData.contactInformationPhoneNumber ||
               frontEndData.contactInformationAddress
            ),
            value: frontEndData.contactInformationSelectedOption.value,
            title: frontEndData.contactInformationTitle,
            message: frontEndData.contactInformationMessage,
            fullName: frontEndData.contactInformationFullName,
            emailAddress: frontEndData.contactInformationEmailAddress,
            phoneNumber: frontEndData.contactInformationPhoneNumber,
            address: frontEndData.contactInformationAddress,
            fullNameRequired: frontEndData.contactInformationFullNameRequired,
            emailAddressRequired: frontEndData.contactInformationEmailAddressRequired,
            phoneNumberRequired: frontEndData.contactInformationPhoneNumberRequired,
            addressRequired: frontEndData.contactInformationAddressRequired,
            thankYouMessage: frontEndData.contactInformationThanksMessage,
            contactInformationPermission: frontEndData.contactInformationPermission,
            contactInformationPermissionContent: frontEndData.contactInformationPermissionContent,
            contactInformationPermissionLinkUrl: frontEndData.contactInformationPermissionLinkUrl,
            contactInformationPermissionLinkText: frontEndData.contactInformationPermissionLinkText,
         },
         visitorRateUs: {
            status: frontEndData.rateUs,
            title: frontEndData.rateUsTitle,
            message: frontEndData.rateUsMessage,
            thankYouMessage: frontEndData.rateUsThanksMessage,
         },
         minutesToClose: frontEndData.minutesToClose,
      },
      settings: {
         showOnlineStatus: frontEndData.onlineStatus,
         showAgentProfiles: frontEndData.agentProfiles,
         notificationSound: frontEndData.notificationSound,
         liveChat: {
            visibility: frontEndData.liveChatVisibility,
         },
         whatsApp: {
            status: frontEndData.isWhatsapp,
            buttonTitle: frontEndData.whatsappButtonTitle,
            account: frontEndData.whatsappAccount,
            title: frontEndData.whatsappTitle,
            content: frontEndData.whatsappContent,
         },
         facebookMessanger: {
            status: frontEndData.isFacebook,
            buttonTitle: frontEndData.facebookButtonTitle,
            account: frontEndData.facebookAccount,
            title: frontEndData.facebookTitle,
            content: frontEndData.facebookContent,
         },
         instagram: {
            status: frontEndData.isInstagram,
            buttonTitle: frontEndData.instagramButtonTitle,
            account: frontEndData.instagramAccount,
            title: frontEndData.instagramTitle,
            content: frontEndData.instagramContent,
         },
         phoneCall: {
            status: frontEndData.isPhoneCall,
            buttonTitle: frontEndData.phoneCallButtonTitle,
            account: frontEndData.phoneCallNumber,
            title: frontEndData.phoneCallTitle,
            content: frontEndData.phoneCallContent,
         },
      },
      websiteDomains: [frontEndData.trustedDomain1, frontEndData.trustedDomain2, frontEndData.trustedDomain3],
   };
};

export { backEndToFrontEnd, frontEndToBackEnd };

import React from 'react';
import { Box, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PreviewHeader from '../../PreviewHeader';
import Chat from '../../Chat';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      borderRadius: 15,
      overflowY: 'auto',
      background: '#F7F7FA',
      marginBottom: '3rem',
   },
   mainContainer: {
      flexGrow: 1,
      overflow: 'hidden',
      width: '100%',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      position: 'relative',
   },
}));

const ChatPreview = ({ type }) => {
   const classes = useStyles();

   return (
      <Paper elevation={1} className={classes.container}>
         <PreviewHeader />
         <Box className={classes.mainContainer}>
            <Chat type={type} />
         </Box>
      </Paper>
   );
};

export default ChatPreview;

import { gql } from '@apollo/client';

export const UPDATED_PERSON_GROUP_SUBSCRIPTION = gql`
   subscription updatedGroup($token: String!, $customer: ID!) {
      updatedGroup(data: { token: $token, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;

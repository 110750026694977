import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, MenuItem, Select } from '@material-ui/core';
import DateRangePicker from '@components/DateRangePicker';
import { LanguageContext } from '@helper/locale/langContext';
import { PLATFORM_TYPE } from '../../Chat/types';
import DownloadIcon from '@assets/icons/StatisticIcons/DownloadIcon';
import Modal from '@components/Modal/modal';
import ExportModalContent from './ExportModal';
/*
    sohbet tipi
    0 => Personeller arasında
    1 => whatsapp web  gelen
    2 => whatsapp business gelen
    3 => facebooktan gelen
    4 => instagramdan gelen
    5 => mailden gelen
*/

const useStyles = makeStyles((theme) => ({
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      // backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: '1px solid #eee',
   },
   selector: {
      textAlign: 'left',
      backgroundColor: 'white',
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 10,
      height: 38,
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: null,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   selectedItemTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   menuItem: {
      margin: 0,
      marginTop: 5,
      marginBottom: 5,
      padding: 0,
      height: 30,
   },
   menuText: {
      fontSize: '1rem',
      marginLeft: 10,
   },
   rightContainer: {
      [theme.breakpoints.down('xs')]: {
         marginTop: 8,
      },
   },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
   anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
   },
   getContentAnchorEl: null,
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
};

const getPlatformOptions = (t) => [
   {
      title: t['page_statistics_typo_all_'],
      key: 'ALL',
   },
   {
      title: 'WhatsApp Web',
      key: PLATFORM_TYPE.WHATS_APP_WEB,
   },
   {
      title: 'WhatsApp Business API',
      key: PLATFORM_TYPE.WHATS_APP_BUSINESS,
   },
   {
      title: 'Facebook Messenger',
      key: PLATFORM_TYPE.FACEBOOK,
   },
   {
      title: 'Instagram',
      key: PLATFORM_TYPE.INSTAGRAM,
   },
   {
      title: 'Live Chat',
      key: PLATFORM_TYPE.LIVE_CHAT,
   },
   {
      title: 'Telegram',
      key: PLATFORM_TYPE.TELEGRAM,
   },
   {
      title: 'Email',
      key: PLATFORM_TYPE.EMAIL,
   },
];

const Toolbar = ({
   title,
   onDateChange,
   onPlatformChange,
   onSurveyChange,
   platform,
   survey,
   surveyOptions,
   startDate,
   endDate,
   platformOptions,
   operation,
   params,
   satisfactionCustomerData,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [showExportModal, setShowExportModal] = useState(false);
   const platforms = platformOptions?.length > 0 ? platformOptions : getPlatformOptions(t);
   // Platform Options

   // only for test
   return (
      <div className='sticky-header'>
         <Grid
            item
            xs={12}
            container
            justifyContent='flex-start'
            alignItems='center'
            className={classes.titleContainer}
         >
            <Grid container item sm={satisfactionCustomerData ? 4 : 6}>
               <Grid item container justifyContent='flex-start' xs={6}>
                  <Typography style={{ marginLeft: 10, fontSize: '1.1rem', fontWeight: 'bold' }}>
                     {title}
                  </Typography>
               </Grid>
            </Grid>
            <Grid
               className={classes.rightContainer}
               container
               spacing={2}
               item
               sm={12}
               md={satisfactionCustomerData ? 8 : 6}
            >
               {satisfactionCustomerData && (
                  <Grid item sm={12} md={3} style={{ paddingRight: 10 }}>
                     <Grid className={classes.selector}>
                        <Select
                           placeholder={t['page_statistics_typo_survey_all_']}
                           fullWidth
                           disableUnderline
                           value={survey}
                           onChange={({ target: { value } }) => onSurveyChange(value)}
                           MenuProps={MenuProps}
                        >
                           {surveyOptions.map((option) => (
                              <MenuItem key={option.key} value={option.key} className={classes.menuItem}>
                                 <Typography className={classes.menuText}>{option.title}</Typography>
                              </MenuItem>
                           ))}
                        </Select>
                     </Grid>
                  </Grid>
               )}
               <Grid item sm={12} md={satisfactionCustomerData ? 4 : 6} style={{ paddingRight: 10 }}>
                  {
                     onPlatformChange && (<Grid className={classes.selector}>
                        <Select
                           fullWidth
                           disableUnderline
                           value={platform}
                           onChange={({ target: { value } }) => onPlatformChange(value)}
                           MenuProps={MenuProps}
                        >
                           {platforms.map((option) => (
                              <MenuItem
                                 key={option.key}
                                 value={option.key}
                                 className={classes.menuItem}
                              >
                                 <Typography className={classes.menuText}>{option.title}</Typography>
                              </MenuItem>
                           ))}
                        </Select>
                     </Grid>)
                  }
               </Grid>
               <Grid
                  item
                  md={satisfactionCustomerData ? 5 : 6}
                  sm={10}
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     justifyContent: 'center',
                     alignItems: 'center',
                     cursor: 'pointer',
                  }}
               >
                  <DateRangePicker onDateFilterChange={onDateChange} startDate={startDate} endDate={endDate} />
                  {operation && !satisfactionCustomerData && (
                     <DownloadIcon onClick={() => setShowExportModal(true)} size={25} style={{ marginLeft: 10 }} />
                  )}
               </Grid>
            </Grid>
         </Grid>
         {showExportModal && (
            <Modal
               paperStyle={{ width: 450 }}
               open={showExportModal}
               onClose={() => setShowExportModal(false)}
               modalHeader={t?.exportData}
            >
               <ExportModalContent
                  onClose={() => setShowExportModal(false)}
                  operation={operation}
                  params={params}
               />
            </Modal>
         )}
      </div>
   );
};

export default Toolbar;

import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext, useState, useCallback, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import { MenuItem, CircularProgress, Grid, Paper, Popover, Typography } from '@material-ui/core';
import { GET_TEMPLATES, GET_TEMPLATES_COUNT } from '@queries/Campaigns/Service/GraphQL/query';

const useStyles = makeStyles((theme) => ({
   popperPaper: {
      width: 325,
      overflow: 'hidden',
      padding: 10,
      paddingBottom: 10,
      borderRadius: 15,
      marginBottom: 0,
      position: 'relative',
   },
}));

const TemplateSelector = ({ startDate, onClose, anchor, onSelect }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector((reducer) => reducer.personReducer.person);
   let customer = person.customer._id;

   const [templateQuery, setTemplateQuery] = useState({
      startDate,
      pageSize: 10,
      pageNumber: 1,
      customer,
      eCommerceCart: false,
   });

   const [getInfiniteData, setInfiniteData] = useState({
      hasMore: true,
      totalLength: 0,
      data: [],
   });

   const [getTemplates] = useLazyQuery(GET_TEMPLATES, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getAllTemplatesPaginate }) => {
         let new_data = [...getInfiniteData.data, getAllTemplatesPaginate.data].flat();
         setInfiniteData({
            ...getInfiniteData,
            data: [...getInfiniteData.data, getAllTemplatesPaginate.data].flat(),
            hasMore: getInfiniteData.totalLength > new_data.length,
         });
      },
   });

   const [getTemplatesCount] = useLazyQuery(GET_TEMPLATES_COUNT, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ getAllTemplatesPaginateCount }) => {
         setInfiniteData({
            ...getInfiniteData,
            totalLength: getAllTemplatesPaginateCount.data,
            hasMore: getAllTemplatesPaginateCount.data > getInfiniteData.data.length,
         });
      },
   });

   useEffect(() => {
      getTemplates({
         variables: {
            ...templateQuery,
         },
      });
      if (getInfiniteData.data.length === 0) {
         getTemplatesCount({
            variables: {
               ...templateQuery,
            },
         });
      }
   }, [getTemplates, getTemplatesCount, templateQuery]);

   function handleNextPage() {
      if (getInfiniteData.totalLength > getInfiniteData.data.length) {
         setTemplateQuery({
            ...templateQuery,
            pageNumber: templateQuery.pageNumber + 1,
         });
      }
   }

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   }, []);

   const MenuProps = {
      anchorOrigin: {
         vertical: 'top',
         horizontal: 'left',
      },
      getContentAnchorEl: null,
      transformOrigin: {
         vertical: 'top',
         horizontal: 'left',
      },
      PaperProps: {
         style: {
            marginLeft: '-12px',
         },
      },
   };

   return (
      <Popover open={anchor != null} onClose={onClose} anchorEl={anchor} MenuProps={MenuProps} placement='bottom'>
         <Paper elevation={8} className={classes.popperPaper}>
            <InfiniteScroll
               scrollableTarget='scrollableDiv'
               dataLength={getInfiniteData?.data?.length || 0}
               hasMore={getInfiniteData.hasMore}
               loader={<Loader />}
               endMessage={
                  getInfiniteData?.totalLength === 0 ? (
                     <Typography variant='body1' style={{ textAlign: 'center' }}>
                        {t?.campaign_no_template}
                     </Typography>
                  ) : null
               }
               next={handleNextPage}
               height={215}
            >
               {getInfiniteData?.data?.map((data) => {
                  return (
                     <MenuItem
                        key={data._id}
                        disablePadding
                        style={{ height: 40, paddingLeft: 0 }}
                        onClick={() => {
                           onClose();
                           onSelect(data);
                        }}
                     >
                        {data.vertical || data.name}
                     </MenuItem>
                  );
               })}
            </InfiniteScroll>
         </Paper>
      </Popover>
   );
};

export default WithUrlTranslations(TemplateSelector);

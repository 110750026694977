import { useMutation } from '@apollo/client';
import {
   CHANGE_PERSON_PASSWORD_MUTATION,
   CHANGE_PERSON_PHOTO_MUTATION,
   UPDATE_PROFILE_MUTATION,
} from '../../../queries/Account/Service/GraphQL/mutation';

// Service Layer
export const useProfile = () => {
   const [changePersonPhoto, { loading: changePersonPhotoLoading }] = useMutation(CHANGE_PERSON_PHOTO_MUTATION);
   const [changePersonPassword, { loading: changePersonPasswordLoading }] = useMutation(
      CHANGE_PERSON_PASSWORD_MUTATION,
   );
   const [updateProfileMutation, { loading: updateProfileLoading }] = useMutation(UPDATE_PROFILE_MUTATION);

   return {
      updateProfileMutation,
      changePersonPassword,
      changePersonPhoto,
      updateProfileLoading,
      changePersonPasswordLoading,
      changePersonPhotoLoading,
   };
};

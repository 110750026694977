import { Box, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Chat, ChatBubble, InsertComment, Sms } from '@material-ui/icons';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import { FileUpload } from '@mui/icons-material';
import classNames from 'classnames';
import React, { useContext, useEffect, useRef, useState } from 'react';
import LiveChatSettingsContext from '../../../../../../contexts/LiveChatSettingsContext';
import { LanguageContext } from '../../../../../../helper/locale/langContext';
import { LIVE_CHAT_ICON, LIVE_CHAT_ICON_POSITION, SELECTED_LIVE_CHAT_ICON } from '../../types';
import { LiveChatLogo } from '../Icons';
import PreviewHeader from '../PreviewHeader';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      borderRadius: 15,
      overflowY: 'auto',
      background: '#F7F7FA',
      marginBottom: '3rem',
   },
   mainContainer: {
      flexGrow: 1,
      overflow: 'hidden',
      width: '100%',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      position: 'relative',
   },
   greetingContainer: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
   },
   greetingContainerBottomLeft: {
      alignItems: 'flex-start',
      bottom: 75,
      left: 20,
      right: 20,
   },
   greetingContainerBottomRight: {
      alignItems: 'flex-end',
      bottom: 75,
      left: 20,
      right: 20,
   },
   greetingContainerCenterLeft: {
      alignItems: 'flex-start',
      bottom: '15%',
      left: '55px',
      right: 20,
   },
   greetingContainerCenterRight: {
      alignItems: 'flex-end',
      bottom: '15%',
      left: 20,
      right: '55px',
   },
   closeIconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '5px',
      backgroundColor: '#2F2F2F',
   },
   closeIcon: {
      color: '#FFFFFF',
   },
   messageContainer: {
      background: '#F7F7FA',
      padding: '8px 16px',
      borderRadius: '5px',
      maxWidth: '70%',
      marginTop: '10px',
      whiteSpace: 'pre-line',
   },
   fakeInputContainer: {
      width: '70%',
      background: '#FFFFFF',
   },
   message: {
      fontSize: '1rem',
      color: '#2F2F2F',
      wordBreak: 'break-word',
   },
   fakeInputText: {
      fontSize: '1rem',
      color: '#9A9A9A',
      wordBreak: 'break-word',
   },
   absoluteContainer: {
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
   },
   iconAndLabelContainer: {
      display: 'flex',
      alignItems: 'center',
      maxWidth: '100%',
      padding: '6px',
      background: (props) => props.themeColor,
      borderRadius: '7px',
      border: '1px solid #EBEBEB',
   },
   roundedIconAndLabelContainer: {
      borderRadius: '25px',
      padding: '8px',
   },
   customImageIconAndLabelContainer: {
      background: 'transparent !important',
      padding: 0,
   },
   absoluteBottomLeft: {
      bottom: 20,
      left: 20,
      marginRight: 20,
   },
   absoluteBottomRight: {
      bottom: 20,
      right: 20,
      marginLeft: 20,
   },
   absoluteCenterLeft: {
      bottom: '15%',
      transform: 'rotate(-90deg)',
      transformOrigin: 'bottom left',
   },
   absoluteCenterRight: {
      bottom: '15%',
      transform: 'rotate(-90deg)',
      transformOrigin: 'bottom left',
   },
   iconContainer: {
      fontSize: '30px',
      color: (props) => props.textColor,
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   iconContainerResetRotate: {
      transform: 'rotate(90deg)',
   },
   labelContainer: {
      flexGrow: 1,
      paddingLeft: '8px',
      paddingRight: '2px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
   },
   iconLabel: {
      fontSize: '15px',
      color: (props) => props.textColor,
   },
}));

const IconAndGreetingPreview = ({ type }) => {
   const { liveChatPreviewSettings } = useContext(LiveChatSettingsContext);
   const { iconSize, iconPosition, iconLabel, themeColor, textColor, liveChatIconType, uploadedLiveChatIcon } = liveChatPreviewSettings;
   const classes = useStyles({ themeColor, textColor });
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { greetingMessage1, greetingMessage2 } = liveChatPreviewSettings;
   const iconAndLabelContainerRef = useRef(null);
   const [extraPositionStyle, setExtraPositionStyle] = useState(null);

   useEffect(() => {
      if (iconPosition) {
         setExtraPositionStyle({
            left:
               iconPosition === LIVE_CHAT_ICON_POSITION.TOP_LEFT
                  ? iconAndLabelContainerRef?.current?.offsetHeight + 2
                  : null,
            right:
               iconPosition === LIVE_CHAT_ICON_POSITION.TOP_RIGHT
                  ? iconAndLabelContainerRef?.current?.offsetWidth * -1 + 2
                  : null,
         });
      }
   }, [iconPosition, iconLabel]);

   if (liveChatPreviewSettings) {
      return (
         <Paper elevation={1} className={classes.container}>
            <PreviewHeader />
            <Box className={classes.mainContainer}>
               {type === 'greeting' && (
                  <div
                     className={classNames(
                        classes.greetingContainer,
                        iconPosition === LIVE_CHAT_ICON_POSITION.LEFT && classes.greetingContainerBottomLeft,
                        iconPosition === LIVE_CHAT_ICON_POSITION.RIGHT && classes.greetingContainerBottomRight,
                        iconPosition === LIVE_CHAT_ICON_POSITION.TOP_LEFT && classes.greetingContainerCenterLeft,
                        iconPosition === LIVE_CHAT_ICON_POSITION.TOP_RIGHT && classes.greetingContainerCenterRight,
                     )}
                  >
                     <div className={classes.closeIconContainer}>
                        <CloseRoundedIcon fontSize='small' className={classes.closeIcon} />
                     </div>
                     {greetingMessage1 && (
                        <Paper elevation={1} className={classes.messageContainer}>
                           <Typography className={classes.message}>{greetingMessage1}</Typography>
                        </Paper>
                     )}
                     <Paper elevation={1} className={classNames(classes.messageContainer, classes.fakeInputContainer)}>
                        <Typography className={classes.fakeInputText}>
                           {
                              t[
                              'page_settings_typo_platforms_live_chat_get_started_greeting_preview_type_your_message_here'
                              ]
                           }
                        </Typography>
                     </Paper>
                  </div>
               )}

               <div
                  className={classNames(
                     classes.absoluteContainer,
                     iconPosition === LIVE_CHAT_ICON_POSITION.LEFT && classes.absoluteBottomLeft,
                     iconPosition === LIVE_CHAT_ICON_POSITION.RIGHT && classes.absoluteBottomRight,
                     iconPosition === LIVE_CHAT_ICON_POSITION.TOP_LEFT && classes.absoluteCenterLeft,
                     iconPosition === LIVE_CHAT_ICON_POSITION.TOP_RIGHT && classes.absoluteCenterRight,
                  )}
                  ref={iconAndLabelContainerRef}
                  style={extraPositionStyle}
               >
                  <div className={classNames(classes.iconAndLabelContainer,
                     iconSize === LIVE_CHAT_ICON.TERTIARY && classes.roundedIconAndLabelContainer,
                     iconSize !== LIVE_CHAT_ICON.SECONDARY && liveChatIconType === SELECTED_LIVE_CHAT_ICON.USER_UPLOADED_ICON && classes.customImageIconAndLabelContainer,
                  )}>
                     <div
                        className={classNames(
                           classes.iconContainer,
                           iconPosition === LIVE_CHAT_ICON_POSITION.TOP_LEFT && classes.iconContainerResetRotate,
                           iconPosition === LIVE_CHAT_ICON_POSITION.TOP_RIGHT && classes.iconContainerResetRotate,
                        )}
                     >
                        {
                           (() => {
                              switch (liveChatIconType) {
                                 case SELECTED_LIVE_CHAT_ICON.QPIEN_DEFAULT_ICON:
                                    return <LiveChatLogo style={{ fontSize: '30px' }} />;
                                 case SELECTED_LIVE_CHAT_ICON.CHAT_BUBBLE_ICON:
                                    return <ChatBubble style={{ fontSize: '30px' }} />;
                                 case SELECTED_LIVE_CHAT_ICON.CHAT_ICON:
                                    return <Chat style={{ fontSize: '30px' }} />;
                                 case SELECTED_LIVE_CHAT_ICON.INSERT_COMMENT_ICON:
                                    return <InsertComment style={{ fontSize: '30px' }} />;
                                 case SELECTED_LIVE_CHAT_ICON.SMS_ICON:
                                    return <Sms style={{ fontSize: '30px' }} />;
                                 case SELECTED_LIVE_CHAT_ICON.USER_UPLOADED_ICON:
                                    return (
                                       uploadedLiveChatIcon ? <img style={{ borderRadius: iconSize === LIVE_CHAT_ICON.TERTIARY ? 25 : 8 }} width={iconSize === LIVE_CHAT_ICON.SECONDARY ? 30 : 44} src={uploadedLiveChatIcon} /> : <FileUpload style={{ color: "#a2a2a2", width: '30' }} />
                                    );
                                 default:
                                    return <LiveChatLogo />;
                              }
                           })()
                        }
                     </div>
                     {iconSize === LIVE_CHAT_ICON.SECONDARY && iconLabel && (
                        <div className={classes.labelContainer}>
                           <Typography noWrap className={classes.iconLabel}>
                              {iconLabel}
                           </Typography>
                        </div>
                     )}
                  </div>
               </div>
            </Box>
         </Paper>
      );
   }
};

export default IconAndGreetingPreview;

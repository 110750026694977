import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../../../fragments';

export const CREATE_CUSTOMER_PERSON_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation createCustomerPersonMutation(
      $name: String!
      $surname: String!
      $phone: String!
      $email: String!
      $password: String!
      $customer: ID!
      $type: Int!
      $signedTeams: Array!
   ) {
      createCustomerPerson(
         data: {
            name: $name
            surname: $surname
            phone: $phone
            email: $email
            password: $password
            customer: $customer
            type: $type
            signedTeams: $signedTeams
         }
      ) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            type
            setup
            status
            createdAt
            chatParticipateLimit
            signedTeams
            isEmailVerified
            accountStatus
         }
         success
         code
         message
      }
   }
`;
export const UPDATE_CUSTOMER_PERSON_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation updateCustomerPersonMutation(
      $_id: ID!
      $name: String
      $surname: String
      $phone: String
      $email: String
      $type: Int
      $country: String
      $state: String
      $address: String
      $postCode: String
      $city: String
      $chatParticipateLimit: Int
      $signedTeams: Array
   ) {
      updateCustomerPerson(
         data: {
            _id: $_id
            name: $name
            surname: $surname
            phone: $phone
            email: $email
            type: $type
            country: $country
            state: $state
            address: $address
            postCode: $postCode
            city: $city
            chatParticipateLimit: $chatParticipateLimit
            signedTeams: $signedTeams
         }
      ) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            type
            country
            city
            state
            address
            postCode
            setup
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;

export const UPDATE_PERSON_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation updatePersonMutation(
      $_id: ID!
      $name: String
      $surname: String
      $phone: String
      $email: String
      $type: Int
      $country: String
      $state: String
      $address: String
      $postCode: String
      $city: String
      $phoneUsagePermission: Boolean
      $emailUsagePermission: Boolean
      $personCustomFields: [PersonCustomFieldsInput]
      $language: String
      $tags: [PersonTagsInput]
   ) {
      updatePerson(
         data: {
            _id: $_id
            name: $name
            surname: $surname
            phone: $phone
            email: $email
            type: $type
            country: $country
            state: $state
            address: $address
            postCode: $postCode
            city: $city
            phoneUsagePermission: $phoneUsagePermission
            emailUsagePermission: $emailUsagePermission
            personCustomFields: $personCustomFields
            language: $language
            tags: $tags
         }
      ) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            type
            country
            city
            state
            address
            postCode
            setup
            status
            createdAt
            phoneUsagePermission
            emailUsagePermission
            language
            tags
         }
         success
         code
         message
      }
   }
`;

export const CREATE_PERSON_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation createPersonMutation(
      $name: String!
      $surname: String!
      $phone: String!
      $email: String!
      $password: String!
      $customer: ID!
      $setup: Boolean
      $country: String
      $state: String
      $address: String
      $signedTeams: Array
      $type: Int!
      $tags: [PersonTagsInput]
      $personCustomFields: [PersonCustomFieldsInput]
      $language: String
   ) {
      createPerson(
         data: {
            name: $name
            surname: $surname
            phone: $phone
            email: $email
            password: $password
            customer: $customer
            setup: $setup
            country: $country
            state: $state
            address: $address
            signedTeams: $signedTeams
            type: $type
            tags: $tags
            personCustomFields: $personCustomFields
            language: $language
         }
      ) {
         data {
            _id
            name
            surname
            username
            phone
            email
            customer {
               ...CustomerFields
            }
            setup
            country
            state
            address
            signedTeams
            type
            language
            tags
         }
         success
         code
         message
      }
   }
`;

export const DELETE_PERSON_MUTATION = gql`
   mutation deletePersonMutation($selectedUsers: Array!) {
      deleteMultiplePerson(data: { multipleIds: $selectedUsers }) {
         data
         success
         code
         message
      }
   }
`;

export const GET_CUSTOMER_SESSIONS = gql`
   ${CUSTOMER_FRAGMENT}
   mutation getCustomerSessionByAdminMutation($customerId: String!, $token: String!) {
      getCustomerSessionByAdmin(data: { customerId: $customerId, token: $token }) {
         data {
            token
            person {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               setup
               createdAt
               customer {
                  ...CustomerFields
               }
            }
         }
         success
         code
         message
      }
   }
`;

export const RESEND_CREATE_PASSWORD_MAIL_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation resendCreatePasswordMailMutation($_id: ID!) {
      resendCreatePasswordMail(data: { _id: $_id }) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            type
            setup
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;

export const DELETE_CUSTOMER_PERSON_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation deleteCustomerPersonMutation($_id: ID!) {
      deleteCustomerPerson(data: { _id: $_id }) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            type
            setup
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;

export const SIGN_IN_PERSON_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation personSingInMutation($email: String!, $password: String!) {
      personSignIn(data: { email: $email, password: $password }) {
         data {
            token
            person {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               chatParticipateLimit
               facebookId
               instagramId
               setup
               createdAt
               language
               isTranslate
               customer {
                  ...CustomerFields
               }
            }
         }
         success
         code
         message
      }
   }
`;
export const RESET_CUSTOMER_PERSON_PASSWORD_MUTATION = gql`
   ${CUSTOMER_FRAGMENT}
   mutation resetCustomerPersonPasswordMutation($_id: ID!, $newPassword: String!) {
      resetCustomerPersonPassword(data: { _id: $_id, newPassword: $newPassword }) {
         data {
            _id
            name
            surname
            username
            phone
            email
            photo
            customer {
               ...CustomerFields
            }
            type
            setup
            status
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const FORGOT_PASSWORD_MUTATION = gql`
   mutation forgotPersonPasswordMutation($email: String!) {
      forgotMyPassword(data: { email: $email }) {
         success
         code
         message
         data
      }
   }
`;

export const CREATE_PASSWORD_MUTATION = gql`
   mutation createPasswordMutation($emailVerificationId: String!, $password: String!) {
      createPassword(data: { emailVerificationId: $emailVerificationId, password: $password }) {
         success
         code
         message
         data {
            _id
            name
            surname
         }
      }
   }
`;

export const RESET_PASSWORD_MUTATION = gql`
   mutation resetPersonPasswordMutation($password: String!, $token: String!) {
      resetMyPassword(data: { password: $password, token: $token }) {
         data {
            _id
            name
            surname
         }
         success
         code
         message
      }
   }
`;

export const SIGN_UP_PERSON_MUTATION = gql`
   mutation signUpPersonMutation(
      $name: String!
      $phone: String!
      $surname: String!
      $companyName: String!
      $email: String!
      $password: String!
      $language: Language!
   ) {
      createCustomer(
         data: {
            name: $name
            phone: $phone
            surname: $surname
            companyName: $companyName
            email: $email
            password: $password
            language: $language
         }
      ) {
         data {
            _id
            email
         }
         success
         code
         message
      }
   }
`;

export const RESEND_MAIL_MUTATION = gql`
   mutation resendMailMutation($email: String!) {
      resendVerificationEmail(email: $email) {
         data
         success
         code
         message
      }
   }
`;

export const VERIFY_MAIL_MUTATION = gql`
   mutation verifyEmailMutation($emailId: String!) {
      verifyEmail(emailId: $emailId) {
         data
         success
         code
         message
      }
   }
`;

export const IMPORT_PERSONS = gql`
   mutation importPersons($customer: ID!, $file: FileUpload!, $fields: [Int]!, $user_groups: [ID]!) {
      importPersons(data: { customer: $customer, file: $file, fields: $fields, user_groups: $user_groups }) {
         data {
            success
            failed
         }
         success
         code
         message
      }
   }
`;

import { gql } from '@apollo/client';

// mutations
export const SEND_MESSAGE_MUTATION = gql`
   mutation sendMessageMutation(
      $sender: ID!
      $chat: ID!
      $type: Int!
      $content: String
      $contentHtml: String
      $media: [Media]
      $template_cards: Object
      $personStatus: Boolean!
      $sendingStatusId: String
      $_id: String
      $surveyData: SurveyDataInputForLivechat
      $emailData: MailInfoInput
      $subject: String
   ) {
      createMessage(
         data: {
            contentHtml: $contentHtml
            emailData: $emailData
            subject: $subject
            sender: $sender
            chat: $chat
            type: $type
            content: $content
            media: $media
            template_cards: $template_cards
            personStatus: $personStatus
            sendingStatusId: $sendingStatusId
            _id: $_id
            surveyData: $surveyData
         }
      ) {
         data {
            _id
            sender {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               setup
               createdAt
            }
            chat {
               _id
               type
            }
            type
            content
            contentHtml
            subject
            emailData {
               to
               from
               bcc
               cc
            }
            media
            template_cards
            readStatus
            platformMessageId
            # whatsAppWebId
            ack
            # whatsAppBusinessId
            personStatus
            createdAt
            ##for the cache
            sendingStatusId
         }
         success
         code
         message
      }
   }
`;
export const RE_SEND_MESSAGE_MUTATION = gql`
   mutation reSenddMessageMutation(
      $sender: ID!
      $chat: ID!
      $type: Int!
      $content: String
      $media: [Media]
      $template_cards: Object
      $personStatus: Boolean!
      $sendingStatusId: String
      $_id: String
   ) {
      reSendMessage(
         data: {
            sender: $sender
            chat: $chat
            type: $type
            content: $content
            media: $media
            template_cards: $template_cards
            personStatus: $personStatus
            sendingStatusId: $sendingStatusId
            _id: $_id
         }
      ) {
         data {
            _id
            sender {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               setup
               createdAt
            }
            chat {
               _id
               type
            }
            type
            content
            media
            template_cards
            readStatus
            platformMessageId
            # whatsAppWebId
            ack
            # whatsAppBusinessId
            personStatus
            createdAt
            ##for the cache
            sendingStatusId
         }
         success
         code
         message
      }
   }
`;

export const READ_MESSAGE_MUTATION = gql`
   mutation readMessageMutation($_id: ID!) {
      readMessage(data: { _id: $_id }) {
         data {
            _id
            readStatus
            personStatus
         }
         success
         code
         message
      }
   }
`;

export const CREATE_EMAIL_MESSAGE_MUTATION = gql`
   mutation createEmailFromUserPanelMutation(
      $me: String!
      $customer: String!
      $type: String
      $senderId: String
      $uniqueKey: String
      $contentHtml: String
      $content: String
      $receiverId: String
      $subject: String
      $media: [Media]
      $emailData: MailInfoInput
   ) {
      createEmailFromUserPanel(
         data: {
            me: $me
            customer: $customer
            type: $type
            senderId: $senderId
            uniqueKey: $uniqueKey
            contentHtml: $contentHtml
            content: $content
            receiverId: $receiverId
            subject: $subject
            media: $media
            emailData: $emailData
         }
      ) {
         data {
            _id
         }
         success
         code
         message
      }
   }
`;

export const CREATE_MESSAGE_FROM_USER_PANEL_MUTATION = gql`
   mutation createMessageFromUserPanelMutation(
      $customerId: String!
      $me: String!
      $receiverIds: [String]!
      $platformType: String!
      $platformId: String!
      $messageType: Int!
      $media: [Media]
      $content: String!
      $template_cards: OTemplateCardInput
   ) {
      createMessageFromUserPanel(
         data: {
            customerId: $customerId
            me: $me
            receiverIds: $receiverIds
            platformType: $platformType
            platformId: $platformId
            messageType: $messageType
            media: $media
            content: $content
            template_cards: $template_cards
         }
      ) {
         data
         success
         code
         message
      }
   }
`;

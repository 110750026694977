import { useLazyQuery, useMutation } from '@apollo/client';
import { Divider, Paper, Typography, Box, Link, Select, MenuItem } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import io from 'socket.io-client';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { getSocialMediaFromType } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';
import useWindowResize from '../../hooks/useWindowResize';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import { GET_CUSTOMER_CLIENT_LIST } from '../../queries/Person/Service/GraphQL/query';
import AddEditContact from './addEditContact';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { GET_PERSON_ACTIVITY } from '@queries/PersonActivity/query';
import emptyVisitor from './emptyVisitor.png';
import isoLangs from './isoLang.json';
import { API_URL } from '@helper/config';
import { english, turkish } from './countries';
import md5 from 'md5';
import Select2 from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { getPersonActivities } from '@store/reducers/personActivity';

function createData(name, chatStarted, countryLanguage, firstConnect, lastConnect) {
   return { name, chatStarted, countryLanguage, firstConnect, lastConnect };
}

const rows = [createData('India', 'IN', 1324171354, 3287263, 123)];

const useStyles = makeStyles((theme) => ({
   select: {
      width: 200,
      backgroundColor: 'transparent',
      '::before': {
         border: 0,
      },
   },
   paper: {
      borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
      width: '100%',
   },
   content: {
      flexGrow: 1,
      overflowX: 'hidden',
      height: '100vh',
      overflowY: 'auto',
   },
   container: {
      margin: 0,
      height: 'calc(100% - 65px)',
      overflow: 'hidden',
      display: 'contents',
      // padding:20,
   },
   appBarSpacer: theme.mixins.toolbar,
   root: {
      height: 450,
      // width: '100%',
      width: '100%',
      padding: 20,
      borderRadius: 20,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   exportModalTitle: {
      fontSize: '1.4rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },
   selectFileCountContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.2rem',
      backgroundColor: '#F7F7F7',
      borderRadius: 5,
      height: 50,
      padding: 10,
   },
   dateContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 5,
      height: 50,
      // padding: 2,
      marginTop: 5,
   },
   dateBox: {
      border: '1px solid #EDEDED',
      padding: 10,
      borderRadius: 5,
      marginTop: 5,
   },
   tableHead: {
      background: '#eff2f6',
      minHeight: 50,
   },
   divContainer: {
      width: 44,
      height: 44,
      borderRadius: 32,
      marginRight: 10,
   },
   avatarText: {
      textAlign: 'center',
      fontSize: '1.3rem',
      color: 'white',
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
   },
   personNameContainer: {
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
   },
   nameText: {
      color: '#506c89',
   },
   statusText: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
   },
   linkText: {
      fontSize: '0.8rem',
      color: 'blue',
   },
   emptyContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '4%',
   },
   emptyTitle: {
      fontSize: '1.4rem',
      fontWeight: 'bold',
   },
   emptyText: {
      fontSize: '1rem',
   },
   emptyImage: {
      width: '45%',
   },
   countryContainer: {
      display: 'flex',
      alignItems: 'center',
   },
   countryFlag: {
      marginRight: 7,
      width: '25px',
      height: '25px',
      minWidth: '25px',
      borderRadius: '100%',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      border: 0,
   },
   toolBarContainer: {
      display: 'flex',
      marginBottom: 15,
      justifyContent: 'space-between',
   },
   toolBarItem: {
      background: 'rgb(239, 242, 246)',
      padding: 5,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: 5,
   },
   activeUserCount: {
      color: 'green',
      fontSize: '1.2rem',
      fontWeight: 'bold',
      marginLeft: 5,
      marginRight: 10,
   },
   scrollDiv: {
      overflow: 'scroll',
      height: 'calc(100vh - 230px)',
      display: 'block',
   },
}));

const Contacts = () => {
   const classes = useStyles();
   const history = useHistory();
   const [rowsPerPage, setRowsPerPage] = React.useState(10);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [exportModal, setExportModal] = useState(false);
   // const [personActivities, setPersonActivities] = useState([]);
   const personActivities = useSelector(getPersonActivities);
   const [personActivitiesFilter, setPersonActivitiesFilter] = useState([]);
   const [modalConfig, setModalConfig] = useState({
      show: false,
      mode: null /* INFO | NEW */,
   });
   const [allStatus, setAllStatus] = useState(-1);
   const [country, setCountry] = useState({ value: -1 });
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   useEffect(() => {
      setCountry({ label: t?.all_countries, value: -1 });
   }, [t]);

   const columns = [
      { id: 'name', label: t?.personActivity_name, minWidth: 170 },
      { id: 'chatStarted', label: t?.personActivity_chatStarted, minWidth: 100 },
      { id: 'countryLanguage', label: t?.personActivity_country_language, minWidth: 100 },
      { id: 'firstConnect', label: t?.personActivity_first_visit, minWidth: 100 },
      { id: 'lastConnect', label: t?.personActivity_last_visit, minWidth: 100 },
   ];

   const person = useSelector((reducer) => reducer.personReducer.person);
   const token = useSelector((reducer) => reducer.personReducer.token);
   const { width } = useWindowResize();
   const [socket, setSocket] = useState(null);
   const dispatch = useDispatch();
   const [queryParams, setQueryParams] = useState({
      page: 0,
      limit: 20,
      customer: person.customer._id,
   });

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, history, enqueueSnackbar, closeSnackbar]);

   const [getClients, { loading: loadingClientList, data: clientsData }] = useLazyQuery(GET_PERSON_ACTIVITY, {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
         dispatch({
            type: 'SET_PERSON_ACTIVITY',
            payload: data.personActivities.data.filter((e) => e.status == 'online'),
         });
      },
   });

   useEffect(() => {
      getClients({ variables: queryParams });
   }, [getClients, queryParams]);

   const handleShowRow = useCallback((row) => {
      setModalConfig({
         show: true,
         mode: 'INFO',
         row: row,
      });
   }, []);

   const handleCloseModal = useCallback(() => {
      setModalConfig({
         show: false,
         mode: 'INFO',
         row: {},
      });
   }, []);

   const handleSearch = useCallback((value) => {
      setQueryParams((prev) => ({ ...prev, name: value }));
   }, []);

   const handlePlatformChange = useCallback((platform) => {
      const _value = platform === 'ALL' ? null : platform;
      setQueryParams((prev) => ({ ...prev, type: _value }));
   }, []);

   const handleDateChange = useCallback((fromDate, toDate) => {
      setQueryParams((prev) => ({ ...prev, fromDate, toDate }));
   }, []);

   const handlePageSizeChange = useCallback((limit) => {
      setQueryParams((prev) => ({ ...prev, limit }));
   }, []);

   const handlePageNumberChange = useCallback((page) => {
      setQueryParams((prev) => ({ ...prev, page }));
   }, []);

   const handleChangePage = (event, newPage) => {
      setQueryParams((prev) => ({ ...prev, page: newPage }));
   };

   const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setQueryParams((prev) => ({ ...prev, page: 0 }));
   };

   const shorterner = (str, len) => {
      if (str.length > len) {
         return str?.substring(0, len) + '...';
      }
      return str;
   };
   const generateUserId = (_id, len = 6) => md5(_id)?.substring(0, len)?.toUpperCase();

   const customStyles = {
      zIndex: 9999,
      control: (base, state) => ({
         ...base,
         background: 'transparent',
         // match with the menu
         borderRadius: state.isFocused ? '3px 3px 0 0' : 3,
         border: 0,
         color: 'red',
         // Removes weird border around container
         '&:hover': {
            // Overwrittes the different states of border
            borderColor: state.isFocused ? 'red' : 'blue',
         },
      }),
      singleValue: (provided) => ({
         ...provided,
         color: 'rgb(80, 108, 137)',
         fontSize: '0.9rem',
      }),
      menu: (base) => ({
         ...base,

         // override border radius to match the box
         borderRadius: 0,
         zIndex: 9999,
         // kill the gap
         marginTop: 0,
      }),
      menuList: (base) => ({
         ...base,
         // kill the white space on first and last option
         padding: 0,
      }),
   };

   const CHAT_COLORS = ['#4CC9F0', '#238AB3', '#2A9D8F', '#A1C181', '#FF5C5C', '#FE7F2D', '#FCCA46', '#A06CD5'];

   const getRandomColorByName = (name) => {
      // get first alphabet in upper case
      const firstAlphabet = name.at(-1).toLowerCase();
      const asciiCode = firstAlphabet.charCodeAt(0);
      const color = CHAT_COLORS.at(asciiCode % CHAT_COLORS.length);

      return {
         color: color,
         character: firstAlphabet.toUpperCase(),
      };
   };
   const getRandomColor = (id) => {
      const CHAT_COLORS = ['#4CC9F0', '#238AB3', '#2A9D8F', '#A1C181', '#FF5C5C', '#FE7F2D', '#FCCA46', '#A06CD5'];
      return CHAT_COLORS[Math.floor(Math.random() * CHAT_COLORS.length)];
   };
   useEffect(() => {
      setPersonActivitiesFilter(() => {
         let filtered = personActivities.filter((item) => {
            if (allStatus === -1) {
               return true;
            }
            if (allStatus === 0) {
               return item.lastStatus == 0 || item.lastStatus == 1;
            }
            return item.lastStatus === allStatus;
         });
         filtered = filtered.filter((item) => {
            if (country.value !== -1) {
               return item.country === country.value;
            }
            return true;
         });
         return filtered;
      });
   }, [personActivities, allStatus, country]);

   const reverse = (s) => {
      return [...s].reverse().join('');
   };

   const countries = lang.code == 'en-US' ? english : lang.code == 'tr' ? turkish : {};
   const handlePersonActivityStatusColor = (row) => {
      if (row.lastStatus === 0) return 'green';
      else if (row.lastStatus === 1 && !row.activities.some((e) => e.status == 2)) return 'green';
      else if (row.lastStatus === 1) return 'orange';
      else if (row.lastStatus === 2) return 'red';
      else if (row.lastStatus === 2) return 'orange';
      else return 'orange';
   };
   const handlePersonActivityStatusText = (row) => {
      if (row.lastStatus === 1 && !row.activities.some((e) => e.status == 2)) {
         return t['personActivity_status_0'];
      } else if (row.lastStatus === 1) {
         return t['personActivity_status_3'];
      } else {
         return t['personActivity_status_' + row.lastStatus];
      }
   };

   
   const makeid = (length) => {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
   };
   const formatDate = lang?.code == 'en-US' ? 'DD.MM.YYYY hh:mm A' : 'DD.MM.YYYY HH:mm';
   return (
      <main className={classes.content} style={{ padding: width < 960 ? 0 : 20 }}>
         {/* {loadingClientList && personActivities.length < 1 && <div className='loading' />} */}
         {/* {loadingClientList && <div className='loading' />} */}
         <div className={classes.appBarSpacer} />
         <Grid item sx={12} className={classes.toolBarContainer}>
            <Grid className={classes.toolBarItem} style={{ height: 45 }}>
               <Typography style={{ color: '#506c89', marginLeft: 10 }}>{t?.total_active_visitors}</Typography>
               <Typography className={classes.activeUserCount}>{personActivities.length}</Typography>
            </Grid>

            <Grid className={classes.toolBarContainer} spacing={3}>
               <Grid item className={classes.toolBarItem}>
                  <Select
                     value={allStatus}
                     onChange={(e) => setAllStatus(e.target.value)}
                     inputProps={{ placeholder: 'MenuItem' }}
                     disableUnderline
                     MenuProps={{
                        anchorOrigin: {
                           vertical: 'bottom',
                           horizontal: 'left',
                        },
                        transformOrigin: {
                           vertical: 'top',
                           horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                     }}
                     style={{ width: 140, color: '#506c89', paddingLeft: 5 }}
                  >
                     <MenuItem value={-1} style={{ fontStyle: 'normal' }}>
                        {t?.all_status}
                     </MenuItem>
                     <MenuItem value={0} style={{ color: 'green', fontStyle: 'normal' }}>
                        {t?.personActivity_status_0}
                     </MenuItem>
                     <MenuItem value={3} style={{ color: 'orange', fontStyle: 'normal' }}>
                        {t?.personActivity_status_3}
                     </MenuItem>
                  </Select>
               </Grid>
               <Grid item className={classes.toolBarItem} style={{ marginLeft: 15 }}>
                  <Select2
                     className={classes.select}
                     styles={customStyles}
                     options={[
                        { label: t?.all_countries, value: -1 },
                        ...Object.keys(countries).map((key) => new Object({ label: countries[key], value: key })),
                     ]}
                     // options={[{ label: 'asd', value: 'asd' }]}
                     // styles={customStyles || customSelectStyles}
                     defaultValue={{ label: t?.all_countries, value: -1 }}
                     value={country}
                     isSearchable
                     isMulti={false}
                     // isDisabled={disabled}
                     // placeholder={placeholder}
                     onChange={(e) => setCountry(e)}
                  />

                  {/* <Select
                     value={country}
                     onChange={(e) => setCountry(e.target.value)}
                     inputProps={{ placeholder: 'MenuItem' }}
                     disableUnderline
                     style={{ width: 140, color: '#506c89', paddingLeft: 5 }}
                     MenuProps={{
                        anchorOrigin: {
                           vertical: 'bottom',
                           horizontal: 'left',
                        },
                        transformOrigin: {
                           vertical: 'top',
                           horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                     }}
                  >
                     <MenuItem value={-1}>
                        <em>{t?.country}</em>
                     </MenuItem>
                     {countries &&
                        Object.keys(countries).map((key) => {
                           return <MenuItem value={key}>{countries[key]}</MenuItem>;
                        })}
                  </Select> */}
               </Grid>
            </Grid>
         </Grid>

         <Grid container className={classes.container} wrap='nowrap'>
            <Grid item className={classes.paper} sx={{ maxHeight: '75%' }}>
               <TableContainer style={{ height: 'calc(100vh - 230px)', background: '#FFF' }}>
                  <Table stickyHeader aria-label='sticky table'>
                     <TableHead>
                        <TableRow>
                           {columns.map((column) => (
                              <TableCell
                                 align={column.align}
                                 style={{
                                    minWidth: column.minWidth,
                                    background: '#eff2f6',
                                    color: '#506c89',
                                    fontSize: '1rem',
                                 }}
                              >
                                 {column.label}
                              </TableCell>
                           ))}
                        </TableRow>
                     </TableHead>
                     {personActivities.length > 0 && (
                        <TableBody>
                           {personActivitiesFilter
                              .slice(queryParams.page * rowsPerPage, queryParams.page * rowsPerPage + rowsPerPage)
                              .map((row) => {
                                 if (!row) return;
                                 const name = row?.person?.name?.includes('Chat-')
                                    ? row.person?.name?.split('Chat-')[1] || generateUserId(row?.personString)
                                    : row.person?.name || generateUserId(row?.personString);
                                 return (
                                    <TableRow hover role='checkbox' tabIndex={-1} key={row._id}>
                                       <TableCell
                                       // key={column.id} align={column.align}
                                       >
                                          <Grid container>
                                             <div
                                                className={classes.divContainer}
                                                style={{
                                                   backgroundColor: getRandomColorByName(row._id).color,
                                                }}
                                             >
                                                <Typography className={classes.avatarText}>{name[0]}</Typography>
                                             </div>
                                             <Box className={classes.personNameContainer}>
                                                <Typography className={classes.nameText}>
                                                   #{shorterner(name, 15)}
                                                </Typography>
                                                <Typography
                                                   className={classes.statusText}
                                                   style={{ color: handlePersonActivityStatusColor(row) }}
                                                >
                                                   {handlePersonActivityStatusText(row)}
                                                </Typography>
                                             </Box>
                                          </Grid>
                                       </TableCell>
                                       <TableCell>
                                          <Typography
                                             style={{
                                                fontWeight: row.chatStarted ? 'bold' : 400,
                                                color: row.chatStarted ? '#333' : '#666',
                                             }}
                                          >
                                             {row.chatStarted ? t?.chat_started : t?.chat_not_started}
                                          </Typography>
                                       </TableCell>
                                       <TableCell>
                                          <Grid className={classes.countryContainer}>
                                             <div
                                                className={classes.countryFlag}
                                                style={{
                                                   // backgroundImage: `url(https://flagcdn.com/144x108/${row.country.toLowerCase()}.png)`,
                                                   backgroundImage: countries[row.country]
                                                      ? `url(https://www.coolgenerator.com/Pic/flag/${row.country.toLowerCase()}.png)`
                                                      : 'url(https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/International_Flag_of_Planet_Earth.svg/1280px-International_Flag_of_Planet_Earth.svg.png)',
                                                }}
                                             ></div>

                                             <Typography>
                                                {' '}
                                                {countries[row.country] || t?.country_not_found} /{' '}
                                                {isoLangs[row.language].name}
                                             </Typography>
                                          </Grid>
                                       </TableCell>
                                       <TableCell>
                                          <Box>
                                             <Typography className={classes.nameText}>
                                                {moment(row.firstConnect.date).format(formatDate)}
                                             </Typography>
                                             <Link
                                                target={'_blank'}
                                                title={row.firstConnect.url}
                                                href={row.firstConnect.url}
                                                className={classes.linkText}
                                             >
                                                {shorterner(row.firstConnect.url, 35)}
                                             </Link>
                                          </Box>
                                       </TableCell>
                                       <TableCell>
                                          <Box>
                                             <Typography className={classes.nameText}>
                                                {moment(row.lastConnect.date).format(formatDate)}
                                             </Typography>
                                             <Link
                                                target={'_blank'}
                                                title={row.lastConnect.url}
                                                href={row.lastConnect.url}
                                                className={classes.linkText}
                                             >
                                                {shorterner(row.lastConnect.url, 35)}
                                             </Link>
                                          </Box>
                                       </TableCell>
                                    </TableRow>
                                 );
                              })}
                        </TableBody>
                     )}
                  </Table>
                  {personActivities.length < 1 && (
                     <Grid className={classes.emptyContainer}>
                        <Typography className={classes.emptyTitle}>{t?.personActivity_title1}</Typography>
                        <Typography className={classes.emptyTitle} style={{ marginBottom: 10 }}>
                           {t?.personActivity_title2}
                        </Typography>
                        <Typography className={classes.emptyText}>{t?.personActivity_text1}</Typography>
                        <Typography className={classes.emptyText} style={{ marginBottom: 15 }}>
                           {t?.personActivity_text2}
                        </Typography>
                        <img className={classes.emptyImage} src={emptyVisitor}></img>
                     </Grid>
                  )}
               </TableContainer>
               {personActivities.length > 0 && (
                  <TablePagination
                     rowsPerPageOptions={[10, 25, 100]}
                     component='div'
                     count={rows.length}
                     rowsPerPage={rowsPerPage}
                     page={queryParams.page}
                     labelRowsPerPage={t?.rows_per_page}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                  />
               )}
            </Grid>
         </Grid>
      </main>
   );
};

export default Contacts;

import {
   DissatisfiedIcon,
   NeutralIcon,
   SatisfiedIcon,
   ThanksIcon,
} from '@assets/icons/SurveyIcons';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(() => ({
   formContainer: {
      flexGrow: 1,
      overflowY: 'auto',
      marginBottom: '5rem',
      overflowX: 'hidden',
      marginRight: '1rem',
   },
   messageSurvey: {
      borderRadius: '10px',
      margin: '1rem 25px 0 0',
      padding: '1rem',
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      gap: '0.8rem',
      marginBottom: '5rem',
   },
   icons: {
      display: 'flex',
      gap: '0.8rem',
      margin: '0.5rem 0 1.3rem 0',
   },
   icon: {
      border: `1px solid #DBDBDB`,
      borderRadius: '10px',
      padding: '5px 5px 0 5px',
   },
   logo: {
      marginTop: '1rem',
      width: '120px',
      height: 'auto',
   },
   comment: {
      color: '#A0A0A0',
      minHeight: '5rem',
      height: 'auto',
      width: '100%',
      padding: '1rem',
      border: '1px solid #DBDBDB',
      borderRadius: '10px',
   },
   thanksMessage: {
      width: '100%',
      padding: '2rem',
      textAlign: 'center',
   },
   modalButtons: {
      height: '35px',
      width: 'auto',
      color: 'white',
      marginRight: 'auto',
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 130,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   link: {
      display: 'flex',
      margin: '0.5rem 0 1.3rem 0',
   },
   a: {
      transform: 'none',
   },
}));

const Index = (props) => {
   const { activeNavButton, watch, isThanksMessageClicked } = props;
   const classes = useStyles();

   return (
      <>
         {activeNavButton === 'messaging' ? (
            <div className={classes.formContainer}>
               {watch.logo && (
                  <img
                     className={classes.logo}
                     src={
                        typeof watch.logo === 'string'
                           ? watch.logo
                           : URL.createObjectURL(watch.logo)
                     }
                     alt={watch.logo}
                  />
               )}
               <div
                  style={{
                     border: (watch.title || watch.message) && '1px solid #DBDBDB',
                  }}
                  className={classes.messageSurvey}
               >
                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.8rem',
                     }}
                  >
                     <div
                        style={{
                           fontWeight: '600',
                           whiteSpace: 'pre-wrap',
                           wordBreak: 'break-word',
                        }}
                     >
                        {watch.title}
                     </div>
                     <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                        {watch.message}
                     </div>
                     {(watch.title || watch.message) && (
                        <div className={classes.link}>
                           <a href='https://www.feedback.qpien.com'>
                              https://www.feedback.qpien.com
                           </a>
                        </div>
                     )}
                  </div>
               </div>
            </div>
         ) : (
            <div className={classes.formContainer}>
               {watch.logo && (
                  <img
                     className={classes.logo}
                     src={
                        typeof watch.logo === 'string'
                           ? watch.logo
                           : URL.createObjectURL(watch.logo)
                     }
                     alt={watch.logo}
                  />
               )}
               <div
                  style={{
                     border: (watch.title || watch.message) && '1px solid #DBDBDB',
                  }}
                  className={classes.messageSurvey}
               >
                  {!isThanksMessageClicked && (watch.title || watch.message) && (
                     <div
                        style={{
                           display: 'flex',
                           flexDirection: 'column',
                           gap: '0.8rem',
                        }}
                     >
                        <div
                           style={{
                              fontWeight: '600',
                              whiteSpace: 'pre-wrap',
                              wordBreak: 'break-word',
                           }}
                        >
                           {watch.title}
                        </div>
                        <div style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                           {watch.message}
                        </div>
                        <div className={classes.icons}>
                           <div className={classes.icon}>
                              <DissatisfiedIcon width='40' height='40' />
                           </div>
                           <div className={classes.icon}>
                              <NeutralIcon width='40' height='40' />
                           </div>
                           <div className={classes.icon}>
                              <SatisfiedIcon width='40' height='40' />
                           </div>
                        </div>
                     </div>
                  )}
                  {!isThanksMessageClicked && (
                     <>
                        {watch.commentInputTitle && (
                           <>
                              <div
                                 style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                                 className={classes.comment}
                              >
                                 {watch.commentInputTitle}
                              </div>
                           </>
                        )}
                        {watch.submitButtonText && (
                           <Button
                              className={classes.modalButtons}
                              style={{
                                 backgroundColor: watch.themeMainColor,
                              }}
                           >
                              {watch.submitButtonText}
                           </Button>
                        )}
                     </>
                  )}

                  {isThanksMessageClicked && (
                     <>
                        <div className={classes.thanksMessage}>
                           {watch.thankYouMessage && <ThanksIcon />}
                           <div
                              style={{
                                 marginTop: '1.3rem',
                                 whiteSpace: 'pre-wrap',
                                 wordBreak: 'break-word',
                              }}
                           >
                              {watch.thankYouMessage}
                           </div>
                        </div>
                     </>
                  )}
               </div>
            </div>
         )}
      </>
   );
};

export default Index;

import React, { useEffect, useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Typography, Box } from '@material-ui/core';
import CurrentPackage from './currentPackage';
import ActivePackages from './activePackages';
import SavedCards from './savedCards';
import PackageOPModal from './packageOPModal';
import Modal from '@components/Modal/modal';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import { useMutation, useQuery, useSubscription } from '@apollo/client';
import {
   GET_CUSTOMER_PRODUCT_QUERY,
   GET_CUSTOMER_PRODUCT_RENEWAL_PRICE_QUERY,
} from '../../../../queries/Customer/Service/GraphQL/CustomerProduct/query';
import { CREATED_CUSTOMER_PRODUCT_SUBSCRIPTION } from '../../../../queries/Customer/Service/GraphQL/CustomerProduct/subscription';
import { DELETE_CUSTOMER_ADD_ON_MUTATION } from '../../../../queries/Customer/Service/GraphQL/CustomerProduct/mutation';
import InfoIcon from '@material-ui/icons/Info';
import { useSnackbar } from 'notistack';
import DeleteWhatsappModal from './deleteWhatsAppModal';
import DeleteUserModal from './deleteUserModal';
import moment from 'moment';
import { UPDATE_ME } from '@store/actions/person';
import { GET_PRODUCTS_QUERY } from '../../../../queries/Products/Service/GraphQL/query';
import { priceFormat } from '@helper/functions';
import { AddOnName } from '@types/enums';

const useStyles = makeStyles((theme) => ({
   table: {
      // minWidth: 700,
   },
   selector: {
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 10,
      paddingLeft: 3,
      height: 32,
   },
   mainTitle: {
      fontSize: '1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },
   packageDesc: {
      color: '#757575',
   },
   titleContainer: {
      color: '#385273',
      borderRadius: 15,
      padding: 15,
      backgroundColor: 'white',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: '1px solid #F7F7FA',
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   titleItem: {
      marginLeft: 10,
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },
   passwordSectionTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   textInput: {
      width: '100%',
      border: 'none',
      resize: 'none',
      borderRadius: 10,
      padding: 10,
      borderColor: 'lightgrey',
      fontSize: '1.075rem',
   },
   overviewText: {
      fontSize: '1rem',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   bottomContainer: {
      paddingLeft: 20,
      paddingRight: 20,
      overflowX: 'auto',
      marginBottom: 20,
      [theme.breakpoints.down('sm')]: {
         marginBottom: 90,
      },
   },
   title: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },

   titleBox: {
      padding: '10px 15px',
      borderBottom: '1px solid #D9D9D9',
   },

   nextRenewalContainer: {
      backgroundColor: '#FFEBA1',
      padding: 20,
      borderRadius: 8,
   },
   packageContainer: {
      border: ' 1px solid #D9D9D9',
      borderRadius: '17px',
      height: '100%',
   },
   currentUsesContainer: {
      minWidth: 340,
      padding: 10,
      height: 500,
      overflowY: 'auto',
      [theme.breakpoints.down('md')]: {
         height: 350,
      },
   },
}));

const MyPackage = ({ width }) => {
   const classes = useStyles();
   const history = useHistory();
   const dispatch = useDispatch();
   const [allProductData, setAllProductData] = useState({});
   const [opResultModalConfig, setOpResultModalConfig] = useState({
      show: false,
   });
   const [state, setState] = useState({
      deleteWhatsappModal: false,
      deleteUserModal: false,
      whatsappModalSelectedCount: { label: 1, value: 1 },
      deleteUserModalSelectedCount: { label: 1, value: 1 },
   });
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const token = useSelector((rootReducer) => rootReducer.personReducer.token);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, history, enqueueSnackbar, closeSnackbar]);

   const { loading: getCustomerProductsLoading } = useQuery(GET_CUSTOMER_PRODUCT_QUERY, {
      variables: { customer: person.customer._id },
      // fetchPolicy: 'network-only',
      onError: (error) => {
         // on query error
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const [deleteAddOn, { loading: deleteAddOnLoading }] = useMutation(DELETE_CUSTOMER_ADD_ON_MUTATION, {
      refetchQueries: [
         {
            query: GET_CUSTOMER_PRODUCT_RENEWAL_PRICE_QUERY,
            variables: {
               customer: person?.customer?._id,
            },
         },
      ],
   });

   const { loading: allProductLoading } = useQuery(GET_PRODUCTS_QUERY, {
      fetchPolicy: 'cache-first',
      variables: {
         customerId: person.customer._id,
      },
      onCompleted: (data) => {
         if (data) {
            setAllProductData(
               data.products.data.find((item) => item.name === person.customer.package.activeRenewalName),
            );
         }
      },
   });
   // TODO
   const onCancelPackage = (item) => {
      // Whatsapp Web API
      if (item.type === AddOnName.WHATSAPP_BUSINESS) {
         handleChangeDeleteWhatsappModal();
      } else {
         // Delete User Modal
         handleChangeDeleteUserModal();
      }
   };

   const handleChangeDeleteWhatsappModal = () => {
      setState((prev) => ({
         ...prev,
         deleteWhatsappModal: !state.deleteWhatsappModal,
      }));
   };
   const handleChangeDeleteUserModal = () => {
      setState((prev) => ({
         ...prev,
         deleteUserModal: !state.deleteUserModal,
      }));
   };

   // Delete Whatsapp Business API from Add on
   const onDeleteWhatsappModal = () => {
      deleteAddOn({
         variables: {
            quantity: state.whatsappModalSelectedCount.value,
            customerProductAddOnId: person.customer.package.addOns.find((item) => item.name === AddOnName.WHATSAPP_BUSINESS)
               ._id,
         },
      })
         .then(
            ({
               data: {
                  deleteCustomerProductAddOn: { data },
               },
            }) => {
               enqueueSnackbar('İşlem başarıyla tamamlandı', {
                  variant: 'success',
                  autoHideDuration: 2000,
               });
               // Update redux
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        customer: {
                           ...person.customer,
                           package: data,
                        },
                     },
                  },
               });
               setState((prevState) => ({
                  ...prevState,
                  deleteWhatsappModal: false,
                  whatsappModalSelectedCount: {
                     value: 1,
                     label: 1,
                  },
               }));
            },
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   // Delete User from Add On
   const onDeleteUserModal = () => {
      deleteAddOn({
         variables: {
            quantity: state.deleteUserModalSelectedCount.value,
            customerProductAddOnId: person.customer.package.addOns.find((item) => item.name === AddOnName.USER)?._id,
         },
      })
         .then(
            ({
               data: {
                  deleteCustomerProductAddOn: { data },
               },
            }) => {
               enqueueSnackbar('Kullanıcılar başarıyla silindi', {
                  variant: 'success',
                  autoHideDuration: 2000,
               });
               // Update redux
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        customer: {
                           ...person.customer,
                           package: data,
                        },
                     },
                  },
               });
               setState((prevState) => ({
                  ...prevState,
                  deleteUserModal: false,
                  deleteUserModalSelectedCount: {
                     value: 1,
                     label: 1,
                  },
               }));
            },
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   useSubscription(CREATED_CUSTOMER_PRODUCT_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               createdPersonCustomerProduct: { data: createdPersonCustomerProduct },
            },
         },
      }) => {
         let {
            personCustomerProducts: { data: personCustomerProducts },
         } = await client.cache.readQuery({
            query: GET_CUSTOMER_PRODUCT_QUERY,
            variables: { customer: person ? person.customer._id : '' },
         }); // react cache query
         if (
            !personCustomerProducts.find((item) => item._id.toString() === createdPersonCustomerProduct._id.toString())
         ) {
            personCustomerProducts = [createdPersonCustomerProduct, ...personCustomerProducts];
            await client.cache.writeQuery({
               query: GET_CUSTOMER_PRODUCT_QUERY,
               variables: { customer: person ? person.customer._id : '' },
               data: { personCustomerProducts }, // add new data into old one
            });
         }
      },
   });

   const { data: totalRenewalPriceData, loading: totalPriceLoading } = useQuery(
      GET_CUSTOMER_PRODUCT_RENEWAL_PRICE_QUERY,
      {
         variables: {
            customer: person?.customer?._id,
         },
         fetchPolicy: 'network-only',
      },
   );

   const fetchingOrLoading = getCustomerProductsLoading || deleteAddOnLoading || allProductLoading || totalPriceLoading;

   if (fetchingOrLoading) {
      return <div className='loading' />;
   }

   const hasCustomerChangedPackage =
      person.customer.package.name !== person.customer.package.activeRenewalName ||
      person.customer.package.periodMode !== person.customer.package.activeRenewalPeriodMode;

   return (
      <Grid container>
         <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
            {width >= 1280 && (
               <Grid item>
                  <Typography style={{ fontWeight: 'bold' }} className={classes.titleItem}>
                     {t?.comp_sidebar_my_packages}
                  </Typography>
               </Grid>
            )}
         </Grid>
         <Grid container direction='row' justifyContent='center' item xs={12} style={{ padding: '1rem' }}>
            {/* Current Usages */}
            <Grid item xs={12} lg={6} xl={4} className={classes.currentUsesContainer}>
               <CurrentPackage customer={person?.customer} />
            </Grid>
            <Grid item xs={12} lg={6} xl={8} style={{ padding: 10, height: 500, overflowY: 'auto' }}>
               <Box className={classes.packageContainer}>
                  <Grid container xs={12} justifyContent='space-between' className={classes.titleBox}>
                     <Typography className={classes.title}>{t?.active_plans}</Typography>
                     <Typography className={classes.title}>
                        {person.customer.package.periodMode === 0 ? t?.monthly : t?.yearly}
                     </Typography>
                  </Grid>
                  {/* Active Plans */}
                  <ActivePackages
                     onCancelPackage={onCancelPackage} // Delete user or delete whatsapp business phone
                  />

                  <Grid
                     container
                     justifyContent='center'
                     alignItems='center'
                     style={{ padding: 20, borderTop: '1px solid #eee' }}
                  >
                     <Grid item xs={6} container direction='column' alignItems='flex-start' justifyContent='center'>
                        <Typography className={classes.mainTitle}>{t?.next_billing_date}</Typography>
                        <Typography className={classes.packageDesc}>
                           {moment(person.customer?.package?.endDate).format('DD/MM/YY')}
                        </Typography>
                     </Grid>
                     <Grid item xs={6} container direction='column' alignItems='flex-end' justifyContent='center'>
                        <Typography className={classes.mainTitle}>{t?.next_total_payment}</Typography>
                        <Typography className={classes.packageDesc}>
                           {priceFormat(totalRenewalPriceData?.customerProductRenewalPrice?.data)}
                        </Typography>
                     </Grid>
                  </Grid>
               </Box>
            </Grid>
            {/* Change Main Package Is Different */}
            {hasCustomerChangedPackage && (
               <Grid container style={{ padding: 10 }}>
                  <Grid className={classes.nextRenewalContainer} justifyContent='space-between' item container xs='12'>
                     <Grid item container xs='12' alignItems='center' direction='row'>
                        <InfoIcon color='secondary' fontSize='24' />
                        <Typography style={{ fontWeight: 'bold', paddingLeft: 10 }} color='textSecondary' variant='h6'>
                           {t?.next_payment_will_be}: {person.customer.package.activeRenewalName} ({' '}
                           {person.customer.package.activeRenewalPeriodMode === 1 ? t?.yearly : t?.monthly})
                        </Typography>
                        <Grid item xs='12'>
                           <Typography variant='caption' color='textSecondary'>
                              {t?.you_changed_the_main_package}{' '}
                           </Typography>
                        </Grid>
                     </Grid>
                     {person.customer.package.activeRenewalPeriodMode === 1 ? (
                        <Typography color='textSecondary' variant='body1' style={{ fontWeight: 'bold' }}>
                           {`${priceFormat(allProductData.yearlyPrice / 12)} * 12 * ${
                              person.customer.package.totalPersonCount
                           } = ${priceFormat(allProductData.yearlyPrice * person.customer.package.totalPersonCount)}`}
                        </Typography>
                     ) : (
                        <Typography color='textSecondary' variant='body1' style={{ fontWeight: 'bold' }}>
                           {`${priceFormat(allProductData.monthlyPrice)} * ${
                              person.customer.package.totalPersonCount
                           } = ${priceFormat(allProductData.monthlyPrice * person.customer.package.totalPersonCount)}`}
                        </Typography>
                     )}

                     <Typography color='textSecondary' style={{ fontWeight: 'bold' }}>
                        {t?.first_billing_date} : {moment(person.customer.package.endDate).format('DD/MM/YY')}
                     </Typography>
                  </Grid>
               </Grid>
            )}
         </Grid>

         {/* DeleteWhatsapp Modal */}
         <Modal
            open={state.deleteWhatsappModal}
            onClose={handleChangeDeleteWhatsappModal}
            modalHeader={t?.delete_whatsapp_account}
         >
            <DeleteWhatsappModal
               onDelete={onDeleteWhatsappModal}
               customer={person.customer}
               onClose={handleChangeDeleteWhatsappModal}
               selectValue={state.whatsappModalSelectedCount}
               onChangeCount={(item) => {
                  setState((prev) => ({
                     ...prev,
                     whatsappModalSelectedCount: item,
                  }));
               }}
            />
         </Modal>

         {/* User Delete Modal */}
         <Modal open={state.deleteUserModal} onClose={handleChangeDeleteUserModal} modalHeader={t?.delete_user}>
            <DeleteUserModal
               onDelete={onDeleteUserModal}
               customer={person.customer}
               onClose={handleChangeDeleteUserModal}
               selectValue={state.deleteUserModalSelectedCount}
               onChangeCount={(item) => {
                  setState((prev) => ({
                     ...prev,
                     deleteUserModalSelectedCount: item,
                  }));
               }}
            />
         </Modal>
         {/* Saved Cards */}
         <Grid
            item
            xs={12}
            className={classes.bottomContainer}
            style={{
               display:
                  person?.customer?.customerFromChannel && person.customer.customerFromChannel === 'SHOPIFY'
                     ? 'none'
                     : null,
            }}
         >
            <SavedCards width={width} />
         </Grid>
         <Modal
            open={opResultModalConfig.show}
            onClose={() =>
               setOpResultModalConfig({
                  show: false,
                  successful: opResultModalConfig.successful,
               })
            }
         >
            <div>
               <PackageOPModal
                  successful={opResultModalConfig.successful}
                  onAccept={() =>
                     setOpResultModalConfig({
                        show: false,
                        successful: opResultModalConfig.successful,
                     })
                  }
               />
            </div>
         </Modal>
      </Grid>
   );
};

export default MyPackage;

import { useMutation } from '@apollo/client';
import { WhatsappIcon } from '@assets/icons/SettingsIcons/settingsIcons';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import SearchBar from '@components/SearchBar';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { LanguageContext } from '@helper/locale/langContext';
import { MenuItem, Select, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { UPDATE_PERSON_GROUP_MUTATION } from '@queries/PersonGroups/Service/GraphQL/mutation';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme: any) => ({
    wpIcon: {
        width: 20,
        height: 20,
    },
    modalContainer: {
        backgroundColor: '#fff',
        width: 450,
        borderRadius: 16,
        paddingBottom: 15,
    },
    modalHeader: {
        display: 'flex',
        height: 50,
        justifyContent: 'center',
        alignItems: 'center',
        padding: '5px 15px',
        borderBottom: '1px solid #F1F3F7',
    },
    modalTitle: {
        color: theme.chatApp.general.pallet.passiveStructureBlue,
        fontSize: '1.1rem',
        fontWeight: 'bold',
    },
    modalBody: {
        display: 'flex',
        flexDirection: 'column',
        paddingInline: 20,
        height: '100%',
    },
    modalBodyIconContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    modalDescription: {
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    modalBodyRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    modalText: {
        color: '#797575',
        fontSize: '1rem',
        margin: 20,
    },
    modalButtons: {
        fontSize: '18px',
        textTransform: 'none',
        paddingBlock: 5,
        paddingInline: 15,
        minWidth: 140,
        height: 44,
        borderRadius: 6,
        transition: 'all .5s',
        '&:disabled': {
            opacity: '0.7',
        },
    },

    inputField: {
        width: '100%',
        marginTop: 6,

        '& .MuiInputLabel-root': {
            marginLeft: 3,
            fontSize: 18,
            color: '#212121',
            fontWeight: 'medium',
        },

        '& .makeStyles-inputLabel-141': {
            marginTop: -50,
        }
    },
    inputLabel: {
        color: "#212121",
        fontSize: 18,
        marginLeft: 3
    },
    menuItem: {
        height: 45,
    },
    IconContainer: {
        width: 35,
        height: 35,
        borderRadius: 10,
        textAlign: 'center',
        padding: 8,
        marginRight: 10,
    },
    listIcon: {
        width: 20,
        height: 20,
        display: 'block',
        marginLeft: 'auto',
    }
}));

export interface EditPersonGroupProps {
    onClose: () => void;
    isOpen: boolean;
    personGroup: {
        id: string;
        platformId: string;
        field_1?: string;
        field_2?: string;
        field_3?: string;
    } | null;
    platformName: string;
    platformsData: any[];
}

const EditPersonGroup = ({ onClose, isOpen, personGroup, platformName, platformsData }: EditPersonGroupProps) => {
    const [updatePersonGroup] = useMutation(UPDATE_PERSON_GROUP_MUTATION);
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;
    const [createLoading, setCreateLoading] = useState(false);
    const person = useSelector((reducer: any) => reducer.personReducer.person);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedPlatformId, setSelectedPlatformId] = React.useState(
        ''
    );


    function editGroup(e: any) {
        updatePersonGroup({
            variables: {
                _id: personGroup?.id,
                customer: person.customer._id,
                senderPlatform: selectedPlatformId,
                senderPlatformType: platformName
            },
        })
            .then(() => {
                enqueueSnackbar(t['page_settings_typo_chat_settings_automatic_actions_success_message'], {
                    variant: 'success',
                    autoHideDuration: 2000,
                });
                onClose();
            })
            .catch((error) => {
                enqueueSnackbar(error.message, {
                    variant: 'error',
                    autoHideDuration: 2000,
                });
                onClose();
            })
    }

    return (
        <Modal paperStyle={{ width: 450 }} onClose={onClose} open={isOpen} modalHeader={t['bulk_connect_groups']}>
            <Grid item xs={12} className={classes.inputField}>
                <Typography className={classes.inputLabel}>{t['bulk_list_field_2']}</Typography>
                <SearchBar
                    value={personGroup?.field_2 as string}
                    disabled
                    style={{ width: '100%' }}
                    placeholder={''}
                    onChange={undefined} />
            </Grid>
            <br />
            <Select
                style={{ height: 44, marginBottom: 0, marginTop: 0, width: '100%' }}
                variant='outlined'
                labelId='sender-picker'
                onChange={(e) => {
                    setSelectedPlatformId(e.target.value as string);
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                }}
                value={selectedPlatformId as string || platformsData.find(x => x._id == personGroup?.platformId)?._id}
            >
                {platformsData.length === 0 ? (
                    <MenuItem value='' key='' disabled>
                        <em>{t['campaign_no_sender']}</em>
                    </MenuItem>
                ) : (
                    platformsData.map((platform) => {
                        return (
                            <MenuItem
                                key={platform._id}
                                value={platform._id}
                                className={classes.menuItem}
                            >
                                <Grid container>
                                    <Grid container xs={2}>
                                        <div
                                            className={classes.IconContainer}
                                            style={{
                                                backgroundColor: '#D5FFE0',
                                                fill: 'rgb(37, 211, 102)'
                                            }}
                                        >
                                            <WhatsappIcon className={classes.listIcon} />
                                        </div>
                                    </Grid>
                                    <Grid
                                        container
                                        xs={9}
                                        alignContent='center'
                                        style={{
                                            paddingTop: 2,
                                            overflow: 'hidden',
                                        }}
                                    >
                                        <Typography noWrap>
                                            {`${platform.name} (${platform.number})`}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </MenuItem>
                        );
                    })
                )}
            </Select>
            <br />
            <br />
            <Typography className={classes.inputLabel}>{t['bulk_connect_groups_2']}</Typography>
            <ModalButtonField>
                <ButtonCancel
                    label={t?.page_chat_btn_filter_cancel}
                    onClick={() => onClose()}
                    className={classes.modalButtons}
                    style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                    }}
                />
                <ButtonOK
                    label={t['connect']}
                    disabled={selectedPlatformId === ''}
                    className={classes.modalButtons}
                    style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        float: 'right',
                    }}
                    startIcon={createLoading ? <CircularProgress size={20} /> : null}
                    onClick={editGroup}
                />
            </ModalButtonField>
        </Modal>
    );
};

export default EditPersonGroup;

import { useLazyQuery, useMutation } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { Box, Grid, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
   FlowBlockType,
   IFlowBlockField,
   IFlowBlockFieldTimeoutUnits,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { ADD_FLOW_BLOCK_MUTATION, UPDATE_BLOCK_MUTATION } from '@queries/Automation/mutation';
import { GET_LIST_BLOCK_TEMPLATES_QUERY } from '@queries/Automation/query';
import React, { useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import SearchSelect from 'react-select';
import { Node } from 'reactflow';

interface DelayBlocksProps {
   leftNodes: Node[];
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setIsSaveDelay: React.Dispatch<React.SetStateAction<boolean>>;
   isSaveDelay: boolean;
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   clickedNodeId: string;
}
const DelayBlocks: React.FC<DelayBlocksProps> = ({
   leftNodes,
   setIsBtnDisable,
   isLoading,
   setIsLoading,
   setIsSaveDelay,
   isSaveDelay,
   clickedNodeId,
   setContentNodes,
   setLeftNodes,
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [time, setTime] = useState<number | undefined>();
   const [unit, setUnit] = useState<string | undefined>();
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);

   useEffect(() => {
      setTime(blockState[0]?.fields[0]?.delay_time);
      setUnit(blockState[0]?.fields[0]?.delay_time_unit);
   }, [blockState]);

   const OPTIONS = [
      {
         value: IFlowBlockFieldTimeoutUnits.Seconds,
         label: time && time > 1 ? t.atomation_flow_seconds : t.atomation_flow_second,
      },
      {
         value: IFlowBlockFieldTimeoutUnits.Minutes,
         label: time && time > 1 ? t.atomation_flow_minutes : t.atomation_flow_minute,
      },
      {
         value: IFlowBlockFieldTimeoutUnits.Hours,
         label: time && time > 1 ? t.atomation_flow_hours : t.atomation_flow_hour,
      },
   ];
   const useStyles = makeStyles((theme) => ({
      textInput: {
         marginTop: '16px',
         width: '80%',
      },
   }));
   const customStyles = {
      control: (base: any) => ({
         ...base,
         height: 35,
      }),
   };
   const classes = useStyles();
   const [timeUnit, seTimeUnit] = useState<{ _id: string; fields: string }[]>([]);
   const [timeValue, setTimeValue] = useState<{ _id: string; fields: number }[]>([]);
   const [defaultUnit, setDefaultUnit] = useState<string | null>(null);
   const [searchSelectKey, setSearchSelectKey] = useState<number>(0);
   const dispatch = useDispatch();
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   useEffect(() => {
      if (unit === 'seconds') {
         setDefaultUnit(time && time > 1 ? t.atomation_flow_seconds : t.atomation_flow_second);
      } else if (unit === 'minutes') {
         setDefaultUnit(time && time > 1 ? t.atomation_flow_minutes : t.atomation_flow_minute);
      } else if (unit === 'hours') {
         setDefaultUnit(time && time > 1 ? t.atomation_flow_hours : t.atomation_flow_hour);
      } else {
         setDefaultUnit(null);
      }

      // Increment the key to force a re-render of SearchSelect
      setSearchSelectKey((prevKey) => prevKey + 1);
   }, [time, unit]);

   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlock = async (flowBlockTemplateId: String, nodeId: string, field: IFlowBlockField[]) => {
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fields: field,
               },
            },
            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some((block) => block.fields?.length > 0),
                              flowBlocks: [response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));

               setIsLoading(false);
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlock = async (blockId: string, fields: IFlowBlockField[]) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;

               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              isValidNode: blockState.some((block) => block.fields?.length > 0),
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
               setIsLoading(false);
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const handleTimeValueChange = (id: string, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const parsedValue = parseInt(e.target.value, 10);

      if (blockState[0].fields[0].delay_time_unit !== undefined && !isNaN(parsedValue)) {
         setIsBtnDisable(false);
      } else {
         setIsBtnDisable(true);
      }
      setTimeValue([{ _id: id, fields: parsedValue }]);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     delay_time: parsedValue,
                  })),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };

   const handleTimeUnitChange = (id: string, label: string) => {
      if (blockState[0].fields[0].delay_time !== undefined) {
         setIsBtnDisable(false);
      } else {
         setIsBtnDisable(true);
      }

      seTimeUnit([{ _id: id, fields: label }]);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     delay_time_unit: label,
                  })),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };

   const [getBlockTemplate] = useLazyQuery(GET_LIST_BLOCK_TEMPLATES_QUERY);
   const listBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   // default
   useEffect(() => {
      setBlockState([]);
      if (listBlocks.length > 0) {
         const data = listBlocks.map((item: IFlowBlockTemplate) => {
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });
         setBlockState(data);
      } else {
         // If there are no blocks, add a default item
         const newItem = {
            _id: Date.now().toString(),
            title: '',
            fields: [
               {
                  type: IFlowBlockFieldTypes.Text,
                  delay_time: 10,
                  delay_time_unit: IFlowBlockFieldTimeoutUnits.Seconds,
               },
            ],
            type: FlowBlockType.Delay,
         };
         setBlockState([newItem]);
         setTimeValue([{ _id: newItem._id, fields: newItem.fields[0].delay_time }]);
         seTimeUnit([{ _id: newItem._id, fields: newItem.fields[0].delay_time_unit }]);

         setTimeout(() => {
            setIsBtnDisable(false);
         }, 1500);
      }
   }, [clickedNodeId]);

   // Save
   useEffect(() => {
      if (isSaveDelay) {
         if (listBlocks.length <= 0 && timeUnit.length > 0 && timeValue.length > 0) {
            // Adding Block in the backend
            (async () => {
               const fields = [
                  {
                     type: IFlowBlockFieldTypes.Text,
                     delay_time: timeValue[0].fields,
                     delay_time_unit: timeUnit[0].fields,
                  },
               ];

               if (LIST_BLOCK_TEMPLATE) {
                  const templateBlockId = LIST_BLOCK_TEMPLATE.find(
                     (item: { type: FlowBlockType }) => item.type === FlowBlockType.Delay,
                  )?._id;
                  if (templateBlockId) {
                     setIsLoading(true);
                     handleAddFlowBlock(templateBlockId, clickedNodeId, fields);
                  }
               }
            })();
         } else if (listBlocks.length > 0) {
            if (timeUnit.length > 0 && timeValue.length <= 0) {
               const fields = [
                  {
                     type: IFlowBlockFieldTypes.Text,
                     delay_time: blockState[0].fields[0].delay_time,
                     delay_time_unit: timeUnit[0].fields,
                  },
               ];
               setIsLoading(true);
               handleUpdateFlowBlock(timeUnit[0]._id, fields);
            }

            if (timeValue.length > 0 && timeUnit.length <= 0) {
               const fields = [
                  {
                     type: IFlowBlockFieldTypes.Text,
                     delay_time: timeValue[0].fields,
                     delay_time_unit: blockState[0].fields[0].delay_time_unit,
                  },
               ];
               setIsLoading(true);
               handleUpdateFlowBlock(timeValue[0]._id, fields);
            }

            if (timeValue.length > 0 && timeUnit.length > 0) {
               const fields = [
                  {
                     type: IFlowBlockFieldTypes.Text,
                     delay_time: timeValue[0].fields,
                     delay_time_unit: timeUnit[0].fields,
                  },
               ];
               setIsLoading(true);
               handleUpdateFlowBlock(timeValue[0]._id, fields);
            }
         }
         setTimeValue([]);
         seTimeUnit([]);
         setIsSaveDelay(false);
      }
   }, [isSaveDelay]);

   const height = 12;

   useEffect(() => {
      const isInvalid = blockState.some((block) => block.fields.some((field) => Number.isNaN(field.delay_time)));
      if (isInvalid) {
         setIsBtnDisable(true);
      }
   }, [blockState]);

   return (
      <Grid>
         <Typography>{t.atomation_flow_bot_delay_enter_time}</Typography>
         {blockState[0]?.fields.map((field) => (
            <Box
               style={{
                  display: 'flex',
                  gap: '0.5rem',
                  width: '100%',
                  marginTop: '-10px',
               }}
               key={field.type}
            >
               <TextField
                  name='timeNumber'
                  placeholder='0'
                  defaultValue={field.delay_time}
                  variant='outlined'
                  inputProps={{
                     style: {
                        height,
                     },
                  }}
                  style={{ width: '40%' }}
                  onChange={(e) => {
                     handleTimeValueChange(blockState[0]._id, e);
                  }}
               />
               <SearchSelect
                  key={searchSelectKey}
                  options={OPTIONS}
                  className={classes.textInput}
                  menuPosition='fixed'
                  placeholder={<div>{t.atomation_flow_select}</div>}
                  styles={customStyles}
                  defaultValue={
                     defaultUnit
                        ? {
                             label: defaultUnit,
                             value: defaultUnit,
                          }
                        : null
                  }
                  onChange={(e) => {
                     if (e) {
                        handleTimeUnitChange(blockState[0]._id, e.value);
                     }
                  }}
               />
            </Box>
         ))}
      </Grid>
   );
};

export default DelayBlocks;

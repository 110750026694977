import {
   SIGN_IN,
   CHANGE_PROJECT_VERSION,
   SIGN_OUT,
   UPDATE_ME,
   UPDATE_PROFILE_STATUS,
   UPDATE_CONFIRMATION_URL,
} from '../actions/person'; // action types
// default initial state
const INITIAL_STATE = {
   projectVersion: null,
   token: null,
   person: null,
};

export default (state = INITIAL_STATE, action) => {
   switch (action.type) {
      case SIGN_IN:
         return {
            ...state,
            person: action.payload.person,
            token: action.payload.token,
         };
      case CHANGE_PROJECT_VERSION:
         return {
            ...state,
            projectVersion: action.payload.projectVersion,
         };
      case SIGN_OUT:
         return {
            ...state,
            token: null,
            person: null,
            projectVersion: null,
         };

      case UPDATE_ME:
         return {
            ...state,
            person: action.payload.person,
         };
      case UPDATE_PROFILE_STATUS:
         return {
            ...state,
            person: {
               ...state.person,
               status: action.payload,
            },
         };
      case UPDATE_CONFIRMATION_URL:
         let _state = {
            ...state,
            person: {
               ...state.person,
               customer: {
                  ...state.person.customer,
                  package: {
                     ...state.person.customer.package,
                     paymentFromOtherChannel: {
                        ...state.person.customer.package.paymentFromOtherChannel,
                        confirmationUrl: action.payload,
                     },
                  },
               },
            },
         };
         return _state;
      default:
         return state;
   }
};

export const getPerson = (state) => state.personReducer.person;
export const getToken = (state) => state.personReducer.token;
export const getCustomer = (state) => state.personReducer.person.customer;

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonOK from '@components/ButtonOK/buttonOk';
import MyTypography from '@components/MyTypography';

const useStyles = makeStyles((theme) => ({
   root: {
      height: '100vh',
      width: '100%',
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
   },
   title: {
      fontSize: '2rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   subTitle: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
}));

const LIST_ITEMS = (t) => {
   return [t?.settings_page_error_desc_1, t?.settings_page_error_desc_2, t?.settings_page_error_desc_3];
};

const ErrorPage = ({ platformHref }) => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const listItems = LIST_ITEMS(t);
   const history = useHistory();

   return (
      <Grid container direction='column' justifyContent='center' alignItems='center' className={classes.root}>
         <MyTypography margin variant='h6' bold color='inherit'>
            {t?.unexpected_error} 🧐
         </MyTypography>

         <MyTypography margin bold>
            {t?.possible_problems}
         </MyTypography>

         {listItems.map((item) => (
            <MyTypography key={item} variant='body1' color='textSecondary' align='center'>
               - {item}
            </MyTypography>
         ))}

         <MyTypography variant='body1' align='center' bold margin>
            {t?.settings_page_error_note}
         </MyTypography>

         <ButtonOK
            label={t?.try_again}
            onClick={() => {
               history.push(platformHref);
            }}
            style={{
               marginTop: 24,
            }}
         />
      </Grid>
   );
};

export default ErrorPage;

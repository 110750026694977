import ButtonOK from '@components/ButtonOK/buttonOk';
import InputCopy from '@components/InputCopy';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowForwardIos, Delete, FileCopy } from '@material-ui/icons';
import React, { useContext, useState } from 'react';

const useStyles = makeStyles((theme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },

   flexRight: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginRight: '0.5rem',
      fontFamily: 'Helvetica',
   },
}));

const InputDropdown = (props: any) => {
   const {
      domainData,
      handleCheckDomainStatus,
      emailData,
      setDeleteDomainModalVisible,
      checkDomainStatusLoading,
   } = props;
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const handleDropdown = () => {
      setVisible(!visible);
   };

   const { labelDomain, labelSenderEmailAddress } = props;
   const [visible, setVisible] = useState(false);

   return (
      <Grid container xs={12}>
         <Grid item xs={12}>
            <div style={{ fontSize: '1.2rem' }}>{labelSenderEmailAddress}</div>
            <div
               style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  border: 'solid #cccccc 1px',
                  borderRadius: '5px',
                  paddingLeft: '0.5rem',
                  marginBottom: '10px',
               }}
            >
               <p>{emailData.senderEmailAddress}</p>
               <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <IconButton onClick={() => setDeleteDomainModalVisible(true)}>
                     <Delete />
                  </IconButton>
               </div>
            </div>
            <div style={{ fontSize: '1.2rem' }}>{labelDomain}</div>
            <div
               style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  border: 'solid #cccccc 1px',
                  borderRadius: '5px',
                  paddingLeft: '0.5rem',
                  marginBottom: '40px',
               }}
            >
               <p>
                  {`${domainData.domain} `}
                  <span
                     style={{
                        color: domainData.valid == 'true' ? '#00C853' : '#D6802B',
                     }}
                  >
                     (
                     {domainData.valid == 'true'
                        ? t['page_settings_email_verified']
                        : t['page_settings_email_unverified']}
                     )
                  </span>
               </p>
               <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  <IconButton onClick={handleDropdown}>
                     <ArrowForwardIos />
                  </IconButton>
               </div>
            </div>

            {visible && (
               <div
                  style={{
                     borderLeft: 'solid #cccccc 1px',
                     borderBottom: 'solid #cccccc 1px',
                     borderRight: 'solid #cccccc 1px',
                     borderTop: 'solid #cccccc 1px',
                     borderRadius: '5px',
                     padding: '1rem',
                     marginBottom: '1rem',
                     fontFamily: 'Helvetica',
                  }}
               >
                  <div
                     style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                     }}
                  >
                     <h2>{t['page_settings_verify_your_domain']}</h2>
                     <ButtonOK
                        label={t['page_settings_check_status']}
                        endIcon={
                           checkDomainStatusLoading ? (
                              <CircularProgress style={{ color: 'white' }} size={20} />
                           ) : null
                        }
                        className={classes.modalButtons}
                        style={{
                           color: 'white',
                           backgroundColor: '#0060df',
                           width: '60%',
                           marginTop: '0.5rem',
                           marginLeft: 'auto',
                           marginRight: '0.5rem',
                        }}
                        onClick={() => {
                           handleCheckDomainStatus(emailData._id);
                        }}
                     />
                  </div>
                  <div>
                     <p>{t['page_settings_add_dns_record']}</p>
                     <p>{t['page_settings_visite_admin_console']}</p>
                     <p>{t['page_settings_note_authentification_provider']}</p>
                  </div>

                  <div>
                     <div
                        style={{
                           display: 'grid',
                           gridTemplateColumns: '1fr 1fr',
                           marginBottom: '3px',
                        }}
                     >
                        <p>{t['page_settings_record']}</p>
                        <p
                           className={classes.flexRight}
                           style={{
                              color: domainData.dns?.mail_cname.valid ? '#00C853' : '#D6802B',
                           }}
                        >
                           {domainData.dns?.mail_cname.valid
                              ? t['page_settings_email_verified']
                              : t['page_settings_verification_pending']}
                        </p>
                     </div>
                     <div
                        style={{
                           border: 'solid #cccccc 1px',
                           borderRadius: '5px',
                           marginRight: '0.5rem',
                           marginTop: '-1rem',
                        }}
                     >
                        <table style={{ width: '100%', paddingLeft: '10px' }}>
                           <tbody>
                              <tr style={{ height: '42px' }}>
                                 <td>{t['page_settings_type']}</td>
                                 <td
                                    style={{
                                       marginRight: '3.5rem',
                                       height: '30px',
                                       display: 'flex',
                                       alignItems: 'end',
                                    }}
                                    className={classes.flexRight}
                                 >
                                    {domainData.dns?.mail_cname.type}
                                    <IconButton style={{ display: 'none' }}>
                                       <FileCopy />
                                    </IconButton>
                                 </td>
                              </tr>
                              <tr style={{ height: '42px' }}>
                                 <td>{t['page_settings_host_name']}</td>
                                 <td className={classes.flexRight}>
                                    {domainData.dns?.mail_cname.host}
                                    <InputCopy value={domainData.dns?.mail_cname.host} />
                                 </td>
                              </tr>
                              <tr style={{ height: '42px' }}>
                                 <td>{t['page_settings_value']}</td>
                                 <td className={classes.flexRight}>
                                    {domainData.dns?.mail_cname.data}
                                    <InputCopy value={domainData.dns?.mail_cname.data} />
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
                  <div>
                     <div
                        style={{
                           display: 'grid',
                           gridTemplateColumns: '1fr 1fr',
                           marginBottom: '3px',
                        }}
                     >
                        <p>{t['page_settings_record']}</p>
                        <p
                           className={classes.flexRight}
                           style={{
                              color: domainData.dns?.dkim1.valid ? '#00C853' : '#D6802B',
                           }}
                        >
                           {domainData.dns?.dkim1.valid
                              ? t['page_settings_email_verified']
                              : t['page_settings_verification_pending']}
                        </p>
                     </div>
                     <div
                        style={{
                           border: 'solid #cccccc 1px',
                           borderRadius: '5px',
                           marginRight: '0.5rem',
                           marginTop: '-1rem',
                        }}
                     >
                        <table style={{ width: '100%', paddingLeft: '10px' }}>
                           <tbody>
                              <tr style={{ height: '42px' }}>
                                 <td>{t['page_settings_type']}</td>
                                 <td
                                    style={{
                                       marginRight: '3.5rem',
                                       height: '30px',
                                       display: 'flex',
                                       alignItems: 'end',
                                    }}
                                    className={classes.flexRight}
                                 >
                                    {domainData.dns?.dkim1.type}
                                    <IconButton style={{ display: 'none' }}>
                                       <FileCopy />
                                    </IconButton>
                                 </td>
                              </tr>
                              <tr style={{ height: '42px' }}>
                                 <td>{t['page_settings_host_name']}</td>
                                 <td className={classes.flexRight}>
                                    {domainData.dns?.dkim1.host}
                                    <InputCopy value={domainData.dns?.dkim1.host} />
                                 </td>
                              </tr>
                              <tr style={{ height: '42px' }}>
                                 <td>{t['page_settings_value']}</td>
                                 <td className={classes.flexRight}>
                                    {domainData.dns?.dkim1.data}
                                    <InputCopy value={domainData.dns?.dkim1.data} />
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
                  <div>
                     <div
                        style={{
                           display: 'grid',
                           gridTemplateColumns: '1fr 1fr',
                           marginBottom: '3px',
                        }}
                     >
                        <p>{t['page_settings_record']}</p>
                        <p
                           className={classes.flexRight}
                           style={{
                              color: domainData.dns?.dkim2.valid ? '#00C853' : '#D6802B',
                           }}
                        >
                           {domainData.dns?.dkim2.valid
                              ? t['page_settings_email_verified']
                              : t['page_settings_verification_pending']}
                        </p>
                     </div>
                     <div
                        style={{
                           border: 'solid #cccccc 1px',
                           borderRadius: '5px',
                           marginRight: '0.5rem',
                           marginTop: '-1rem',
                        }}
                     >
                        <table style={{ width: '100%', paddingLeft: '10px' }}>
                           <tbody>
                              <tr style={{ height: '42px' }}>
                                 <td>{t['page_settings_type']}</td>
                                 <td
                                    style={{
                                       marginRight: '3.5rem',
                                       height: '30px',
                                       display: 'flex',
                                       alignItems: 'end',
                                    }}
                                    className={classes.flexRight}
                                 >
                                    {domainData.dns?.dkim2.type}
                                    <IconButton style={{ display: 'none' }}>
                                       <FileCopy />
                                    </IconButton>
                                 </td>
                              </tr>
                              <tr style={{ height: '42px' }}>
                                 <td>{t['page_settings_host_name']}</td>
                                 <td className={classes.flexRight}>
                                    {domainData.dns?.dkim2.host}
                                    <InputCopy value={domainData.dns?.dkim2.host} />
                                 </td>
                              </tr>
                              <tr style={{ height: '42px' }}>
                                 <td>{t['page_settings_value']}</td>
                                 <td className={classes.flexRight}>
                                    {domainData.dns?.dkim2.data}
                                    <InputCopy value={domainData.dns?.dkim2.data} />
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            )}
         </Grid>
      </Grid>
   );
};

export default InputDropdown;

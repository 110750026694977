import React, { useContext, useEffect, useState } from 'react';
import IconAndGreetingPreview from '../../SharedComponents/Previews/IconAndGreetingPreview';
import usePreviousState from '../../../../../../hooks/usePreviousState';
import LiveChatSettingsContext from '../../../../../../contexts/LiveChatSettingsContext';
import _ from 'underscore';
import ChatPreview from '../../SharedComponents/Previews/ChatPreview';

const groupsAndTheirKeys = {
   greeting: ['greetingMessage1'],
   welcomeMessageDuringWorkingHours: ['welcomeMessageDuringWorkingHoursMessage1'],
   welcomeMessageOutsideOfWorkingHours: ['welcomeMessageOutsideOfWorkingHoursMessage1'],
   contactInformation: [
      'contactInformationSelectedOption',
      'contactInformationTitle',
      'contactInformationMessage',
      'contactInformationFullName',
      'contactInformationEmailAddress',
      'contactInformationPhoneNumber',
      'contactInformationAddress',
      'contactInformationPermission',
      'contactInformationPermissionContent',
      'contactInformationPermissionLinkUrl',
      'contactInformationPermissionLinkText',
   ],
   contactInformationThanks: ['contactInformationThanksMessage'],
   rateUs: ['rateUsTitle', 'rateUsMessage'],
   rateUsThanks: ['rateUsThanksMessage'],
};

const Previews = () => {
   const { liveChatPreviewSettings } = useContext(LiveChatSettingsContext);
   const prevPreviewData = usePreviousState(liveChatPreviewSettings, liveChatPreviewSettings);
   const [currentPreview, setCurrentPreview] = useState('default');

   useEffect(() => {
      // set preview to show
      if (prevPreviewData && liveChatPreviewSettings) {
         if (!_.isEqual(prevPreviewData, liveChatPreviewSettings)) {
            let hasASwitchChanged = true;
            const groupNames = Object.getOwnPropertyNames(groupsAndTheirKeys);
            groupNames.forEach((group) => {
               groupsAndTheirKeys[group].forEach((item) => {
                  if (
                     prevPreviewData.hasOwnProperty(item) &&
                     liveChatPreviewSettings.hasOwnProperty(item) &&
                     (typeof prevPreviewData[item] === 'object'
                        ? !_.isEqual(prevPreviewData[item], liveChatPreviewSettings[item])
                        : prevPreviewData[item] !== liveChatPreviewSettings[item])
                  ) {
                     hasASwitchChanged = false;
                     setCurrentPreview(group);
                  }
               });
            });
            if (hasASwitchChanged) {
               let hasASwitchOpened = null;
               groupNames.forEach((group) => {
                  if (prevPreviewData[group] === false && liveChatPreviewSettings[group] === true) {
                     hasASwitchOpened = true;
                     setCurrentPreview(group);
                  }
               });
               if (!hasASwitchOpened) {
                  let flag = false;
                  groupNames.forEach((group) => {
                     if (
                        prevPreviewData[group] === true &&
                        liveChatPreviewSettings[group] === false &&
                        currentPreview === group
                     ) {
                        setCurrentPreview(group);
                        flag = true;
                     }
                  });
                  if (flag) {
                     let isThereAnOpenSwitch = false;
                     for (const group of groupNames.reverse()) {
                        if (liveChatPreviewSettings[group] === true) {
                           setCurrentPreview(group);
                           isThereAnOpenSwitch = true;
                           break;
                        }
                     }
                     if (!isThereAnOpenSwitch) {
                        setCurrentPreview('default');
                     }
                  }
               }
            }
         }
      }
   }, [prevPreviewData, liveChatPreviewSettings]);

   return (
      <>
         {currentPreview === 'default' && liveChatPreviewSettings && <IconAndGreetingPreview type='icon' />}
         {currentPreview === 'greeting' && liveChatPreviewSettings && <IconAndGreetingPreview type='greeting' />}
         {currentPreview === 'welcomeMessageDuringWorkingHours' && liveChatPreviewSettings && (
            <ChatPreview type='welcomeMessageDuringWorkingHours' />
         )}
         {currentPreview === 'welcomeMessageOutsideOfWorkingHours' && liveChatPreviewSettings && (
            <ChatPreview type='welcomeMessageOutsideOfWorkingHours' />
         )}
         {currentPreview === 'contactInformation' && liveChatPreviewSettings && (
            <ChatPreview type='contactInformation' />
         )}
         {currentPreview === 'contactInformationThanks' && liveChatPreviewSettings && (
            <ChatPreview type='contactInformationThanks' />
         )}
         {currentPreview === 'rateUs' && liveChatPreviewSettings && <ChatPreview type='rateUs' />}
         {currentPreview === 'rateUsThanks' && liveChatPreviewSettings && <ChatPreview type='rateUsThanks' />}
      </>
   );
};

export default Previews;

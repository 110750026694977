import { gql } from '@apollo/client';
import { COUPON_CODE_CORE_FIELDS } from '../../../fragments';
// queries
export const GET_PRODUCTS_QUERY = gql`
   query getProductsQuery($customerId: ID) {
      products(customerId: $customerId) {
         data {
            _id
            name
            channels
            clientCount
            messages
            whatsappWebCount
            whatsappBusinessCount
            facebookPageCount
            twitterCount
            instagramCount
            monthlyPrice
            yearlyPrice
            type
            teamChat
            support
            createdAt
         }
         success
         code
         message
      }
   }
`;

export const GET_COUPON_CODE_QUERY = gql`
   ${COUPON_CODE_CORE_FIELDS}
   query getCouponCodeQuery($code: String!) {
      couponCode(code: $code) {
         data {
            ...CoreCouponCodeFields
         }
         success
         code
         message
      }
   }
`;

import { NetworkStatus, useLazyQuery, useQuery } from '@apollo/client';
import { DissatisfiedIcon, NeutralIcon, SatisfiedIcon } from '@assets/icons/SurveyIcons/index';
import Error from '@components/Errors/Error';
import { calculateTimeDifference } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GET_CUSTOMER_SATISFACTION_SURVEYS } from '@queries/Settings/Service/GraphQL/CustomerSatisfaction/query';
import { GET_CUSTOMER_SATISFACTION_STATISTICS_QUERY } from '@queries/Statistics/Service/GraphQL/query';
import moment from 'moment-timezone';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Chip from '../chip';
import Toolbar from '../toolbar';
import LineChart from './lineChart';
// const platformCodes = ['whatsAppWeb', 'whatsAppBusiness', 'facebook', 'instagram'];
const getColumns = (t) => [
   {
      title: t['page_customer_satisfaction_typo_filter_title_sent'],
      value: 0,
   },
   {
      title: t['page_customer_satisfaction_typo_filter_title_responded'],
      value: 0,
   },
   {
      title: (
         <div style={{ alignItems: 'center', display: 'flex', gap: '0.5rem' }}>
            {t['page_customer_satisfaction_typo_filter_title_satisfied']}{' '}
            <span style={{ width: 30, height: 30 }}>
               <SatisfiedIcon height='30' width='30' />
            </span>
         </div>
      ),
      value: 0,
   },
   {
      title: (
         <div style={{ alignItems: 'center', display: 'flex', gap: '0.5rem' }}>
            {t['page_customer_satisfaction_typo_filter_title_neutral']}{' '}
            <span style={{ width: 30, height: 30 }}>
               <NeutralIcon height='30' width='30' />
            </span>
         </div>
      ),
      value: 0,
   },
   {
      title: (
         <div style={{ alignItems: 'center', display: 'flex', gap: '0.5rem' }}>
            {t['page_customer_satisfaction_typo_filter_title_dissatisfied']}{' '}
            <span style={{ width: 30, height: 30 }}>
               <DissatisfiedIcon height='30' width='30' />
            </span>
         </div>
      ),
      value: 0,
   },
];

const useStyles = makeStyles((theme) => ({
   emptyList: {
      fontSize: '1.3rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      marginTop: 40,
   },
}));

const CustomerSatisfaction = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const [CUSTOMER_SATISFACTION_STATISTICS_DATA] = useState(getColumns(t));
   const [state, setState] = useState({
      // startDate: moment().subtract(1, 'day').valueOf(),
      startDate: moment().valueOf(),
      endDate: moment().valueOf(),
      platform: 'ALL',
      survey: 'ALL',
      totalResponded: 0,
      totalSent: 0,
      totalSatisfied: 0,
      totalNeutral: 0,
      totalDissatisfied: 0,
      chartData: [],
   });

   const Page = {
      pageSize: 20,
      page: 1,
   };

   const [surveyOptions, setSurveyOptions] = useState([{ key: 'ALL', title: t['page_statistics_typo_survey_all_'] }]);

   const [satisfactionCustomerParam, setSatisfactionCustomerParam] = useState({
      customer: person.customer._id,
      pageSize: Page.pageSize,
      pageNumber: Page.page,
   });

   const [getSatisfactionData, { data: satisfactionCustomerData, loading: satisfactionCustomerLoading }] = useLazyQuery(
      GET_CUSTOMER_SATISFACTION_SURVEYS,
      {
         fetchPolicy: 'no-cache',
      },
   );

   const {
      data: customerSatisfactionStatisticsData,
      loading,
      error,
      refetch,
      networkStatus,
   } = useQuery(GET_CUSTOMER_SATISFACTION_STATISTICS_QUERY, {
      variables: {
         customer: person.customer._id,
         startDate: state.startDate,
         endDate: state.endDate,
         platform: state.platform,
         surveyId: state.survey,
      },
      fetchPolicy: 'network-only',
   });

   const handleChangeFilterDate = (startDate, endDate) => {
      setState((prevState) => ({
         ...prevState,
         startDate,
         endDate,
      }));
   };

   const handleChangePlatform = (platform) => {
      setState((prevState) => ({ ...prevState, platform }));
   };

   const handleChangeSurvey = (survey) => {
      setState((prevState) => ({ ...prevState, survey }));
   };

   if (customerSatisfactionStatisticsData?.getSurveyHistoryStatistic) {
      CUSTOMER_SATISFACTION_STATISTICS_DATA[0].value =
         customerSatisfactionStatisticsData?.getSurveyHistoryStatistic?.data?.totalSent;
      CUSTOMER_SATISFACTION_STATISTICS_DATA[1].value =
         customerSatisfactionStatisticsData?.getSurveyHistoryStatistic?.data?.totalResponded;
      CUSTOMER_SATISFACTION_STATISTICS_DATA[2].value =
         customerSatisfactionStatisticsData?.getSurveyHistoryStatistic?.data?.totalSatisfied;
      CUSTOMER_SATISFACTION_STATISTICS_DATA[3].value =
         customerSatisfactionStatisticsData?.getSurveyHistoryStatistic?.data?.totalNeutral;
      CUSTOMER_SATISFACTION_STATISTICS_DATA[4].value =
         customerSatisfactionStatisticsData?.getSurveyHistoryStatistic?.data?.totalDissatisfied;
   }

   const showChartReportUI = (survey) => {
      if (
         survey?.totalSent === 0 &&
         survey?.totalResponded === 0 &&
         survey?.totalSatisfied === 0 &&
         survey?.totalNeutral === 0 &&
         survey?.totalDissatisfied === 0
      ) {
         return false;
      }
      return true;
   };

   useEffect(() => {
      refetch();
   }, [state, refetch]);

   useEffect(() => {
      getSatisfactionData({
         variables: {
            ...satisfactionCustomerParam,
         },
      });
   }, [satisfactionCustomerParam]);

   useEffect(() => {
      if (satisfactionCustomerData?.getSurveys?.data) {
         setSurveyOptions(
            surveyOptions.concat(
               satisfactionCustomerData?.getSurveys?.data.map((item) => ({
                  key: item._id,
                  title: item.name,
               })),
            ),
         );
      }
   }, [satisfactionCustomerData]);

   const loadingOrFetching = networkStatus === NetworkStatus.refetch || loading || satisfactionCustomerLoading;

   if (loadingOrFetching) {
      return <div className='loading' />;
   }

   return (
      <Fragment>
         <Fragment>
            <Toolbar
               onPlatformChange={handleChangePlatform}
               onSurveyChange={handleChangeSurvey}
               platform={state.platform}
               survey={state.survey}
               onDateChange={handleChangeFilterDate}
               surveyOptions={surveyOptions}
               startDate={state.startDate}
               endDate={state.endDate}
               title={t['page_statistics_typo_customer_satisfaction']}
               operation={'getCustomerSatisfactionStatistics'}
               params={{
                  customer: person.customer._id,
                  startDate: state.startDate,
                  endDate: state.endDate,
                  platform: state.platform,
                  survey: state.survey,
               }}
               satisfactionCustomerData={satisfactionCustomerData?.getSurveys?.data}
            />
            <Grid container direction='column' style={{ padding: 20 }}>
               <Grid container justifyContent='space-between' alignItems='center'>
                  {CUSTOMER_SATISFACTION_STATISTICS_DATA.map((item) => (
                     <Grid key={item.title} item xs={2}>
                        <Chip title={item.title} value={item.value} />
                     </Grid>
                  ))}
               </Grid>
               {showChartReportUI(customerSatisfactionStatisticsData?.getSurveyHistoryStatistic?.data) ? (
                  <Fragment>
                     {!error && (
                        <Grid item style={{ marginTop: 30 }}>
                           <LineChart
                              chartData={
                                 customerSatisfactionStatisticsData?.getSurveyHistoryStatistic
                                    ? customerSatisfactionStatisticsData?.getSurveyHistoryStatistic?.data.chartData
                                    : []
                              }
                              mode={calculateTimeDifference(state.startDate, state.endDate)}
                           />
                        </Grid>
                     )}
                  </Fragment>
               ) : (
                  <Grid xs={12} container justifyContent='center' style={{ height: 100 }}>
                     <Typography className={classes.emptyList}>{t?.your_contact_list_is_empty}</Typography>{' '}
                  </Grid>
               )}
            </Grid>
         </Fragment>
         <Error show={!!error} message={error?.message} />
      </Fragment>
   );
};

export default CustomerSatisfaction;

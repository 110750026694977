import React, { memo } from 'react';
import { GridToolbarContainer } from '@material-ui/data-grid';
import { Grid } from '@material-ui/core';
import TopTopToolbar from '@modules/Contacts/topTopToolbar';
import TopToolbar from '@modules/Contacts/topToolbar';

const TableToolbar = ({
   setCreateManualAccount,
   availablePersonGroups,
   setEditGroup,
   setCreateGroup,
   setDynamicGroup,
   setImportUsers,
   handleGroupChange,
   selectedGroupData,
   handleSearch,
   searchValue,
   onPlatformChanged,
   onTeamChanged,
   dateFilter,
   excelExport,
   text,
   setText,
   selectedPlatform,
   selectedTeam,
   selectionModel,
   handlePushUsersToGroup,
   handleRemoveUsersFromGroup,
   setDeleteModalVisible,
   showEditGroup,
   showCreateGroup,
   showDynamicGroup,
   showImportUsers,
   width,
}) => {
   return (
      <GridToolbarContainer>
         <Grid xs={12} container direction='row' alignItems='center'>
            <TopTopToolbar
               createManual={(person) => {
                  setCreateManualAccount({
                     loading: false,
                     visible: true,
                     licenseAgreement: false,
                     phone: '',
                     person,
                  });
               }}
               personGroups={availablePersonGroups.groups || []}
               editGroup={(group_id, group_name) => {
                  setEditGroup({
                     ...showEditGroup,
                     groupId: group_id,
                     groupName: group_name,
                     visible: true,
                  });
               }}
               createGroup={() => {
                  setCreateGroup({
                     ...showCreateGroup,
                     visible: true,
                  });
               }}
               DynamicGroup={(group_id, group_name, shouldMatchAllConditions, conditions, isEdit) => {
                  setDynamicGroup({
                     ...showDynamicGroup,
                     group_id,
                     group_name,
                     shouldMatchAllConditions,
                     conditions,
                     visible: true,
                     isEdit,
                  });
               }}
               importUsers={() => {
                  setImportUsers({
                     ...showImportUsers,
                     visible: true,
                     data_loaded: false,
                  });
               }}
               onGroupChanged={handleGroupChange}
               selectedGroupData={selectedGroupData}
               width={width}
            />
            <br />
            <div
               style={{
                  width: '100%',
                  height: '2px',
                  backgroundColor: '#E0E0E0',
               }}
            />
            <TopToolbar
               width={width}
               onSearchChanged={handleSearch}
               searchValue={searchValue}
               onPlatformChanged={onPlatformChanged}
               onTeamChanged={onTeamChanged}
               dateFilter={dateFilter}
               excelExport={excelExport}
               text={text}
               setText={setText}
               selectedPlatform={selectedPlatform}
               selectedTeam={selectedTeam}
               deleteButtonDisable={selectionModel.length < 1}
               personGroups={availablePersonGroups.groups || []}
               handleGroupAdd={(g_id) => {
                  handlePushUsersToGroup(g_id);
               }}
               handleGroupRemove={(g_id) => {
                  handleRemoveUsersFromGroup(g_id);
               }}
               selectedGroupData={selectedGroupData}
               handleDeleteUsers={() => setDeleteModalVisible(true)}
            />
         </Grid>
      </GridToolbarContainer>
   );
};

export default TableToolbar;

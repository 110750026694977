import { LanguageContext } from "@helper/locale/langContext";
import { Checkbox, Grid, Hidden, MenuItem, Select, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import React, { useContext } from "react";
import { SelectedTarget, SenderType, SendingOption } from "../types";
import TabBar from "./tabBar";
import TargetSelector from "./targetSelector";

const useStyles = makeStyles((theme: any) => ({
    leftPanel: {
        display: 'block',
        height: '100%',
        backgroundColor: 'white',
        // background: '#F7F7FA',
        width: 403,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        borderRight: '2px solid #f7f7fa',
    },
    tab: {
        height: 'calc(100% - 115px)',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    tabSection: {
        // width: isSmallScreen ? '100%' : 350,
        width: 350,
        marginTop: 5,
        backgroundColor: 'white',
        paddingRight: 10,
        paddingLeft: 16,
    },
    tabSectionTitle: {
        marginTop: 7,
        marginBottom: 7,
        paddingLeft: 2,
        fontSize: '1.1rem',
        fontWeight: 500,
    },
    menuItem: {
        height: 45,
    },
}));

export interface LeftBarData {
    whatsAppSendFolderNames: boolean;
    telegramSendFolderNames: boolean;
    sender: SenderType;
    telegramSendingType: SendingOption;
    selectedChatIds: SelectedTarget[];
    selectedPersonGroupIds: SelectedTarget[];
    lastSelectedMenu: SenderType;
}

interface LeftBarProps {
    onUpdate: (leftBarData: LeftBarData) => void;
    leftBarData: LeftBarData;
    disabled: boolean;
}

const LeftPanel = ({ disabled, onUpdate, leftBarData }: LeftBarProps) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [isBusy, setBusy] = React.useState(false);

    const ITEM_HEIGHT = 100;
    const ITEM_PADDING_TOP = 1;
    return (
        <Hidden>
            <Grid container className={classes.leftPanel}>
                <TabBar isBusy={isBusy} selectedTab={leftBarData.sender} onTabClick={(sender: SenderType) => {
                    let newData = { ...leftBarData, sender }
                    if (sender === SenderType.TelegramChats) {
                        newData.lastSelectedMenu = leftBarData.sender;
                    } else if (leftBarData.sender === SenderType.TelegramChats) {
                        newData.sender = newData.lastSelectedMenu;
                    }
                    onUpdate(newData);
                }} />
                <div
                    className={classes.tab}>
                    <div className={classes.tabSection}>
                        <div style={{ display: leftBarData.sender == SenderType.TelegramChats ? 'block' : 'none' }}>
                            <p className={classes.tabSectionTitle}>{t['bulk_how_to_select_channels']}</p>
                            <Select
                                variant='outlined'
                                disabled={disabled || isBusy}
                                labelId='sender-picker'
                                onChange={(e) => {
                                    onUpdate({
                                        ...leftBarData,
                                        ...(e.target.value as SendingOption == SendingOption.ByFolderName && {
                                            selectedChatIds: leftBarData.selectedChatIds.filter(chat => chat.type != SenderType.TelegramChats),
                                        }),
                                        telegramSendingType: e.target.value as SendingOption
                                    });
                                }}
                                value={leftBarData.telegramSendingType}
                                style={{ height: 37.5, marginBottom: 0, marginTop: 0, width: '100%' }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    PaperProps: {
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                            marginTop: -18,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value='Manualy' key='0' className={classes.menuItem}>
                                    <p>{t['bulk_select_manually']}</p>
                                </MenuItem>
                                <MenuItem value='ByFolderNames' key='1' className={classes.menuItem}>
                                    <p>{t['bulk_select_by_channel_name']}</p>
                                </MenuItem>
                            </Select>
                            <br />
                        </div>
                        <div style={{ display: leftBarData.sender == SenderType.TelegramChats ? 'none' : 'block' }}>
                            <p className={classes.tabSectionTitle}>{t['bulk_concact_or_whatsapp_choser']}</p>
                            <Select
                                variant='outlined'
                                disabled={disabled || isBusy}
                                labelId='sender-picker'
                                onChange={(e) => {
                                    const sender = Number(e.target.value) as SenderType;
                                    const data = { ...leftBarData, sender };
                                    // If person group selected, remove the selected whatsapp groups or vice versa :)
                                    if (sender == SenderType.PersonGroups && leftBarData.sender == SenderType.WhatsappGroups) {
                                        data.selectedChatIds = data.selectedChatIds.filter(target => target.type !== SenderType.WhatsappGroups);
                                    } else if (sender == SenderType.WhatsappGroups && leftBarData.sender == SenderType.PersonGroups) {
                                        data.selectedPersonGroupIds = []
                                    }
                                    onUpdate(data);
                                }}
                                value={leftBarData.sender}
                                style={{ height: 37.5, marginBottom: 0, marginTop: 0, width: '100%' }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    PaperProps: {
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                            marginTop: -18,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value='0' key='0' className={classes.menuItem}>
                                    <p>{t['bulk_message_option_1']}</p>
                                </MenuItem>
                                <MenuItem value='1' key='1' className={classes.menuItem}>
                                    <p>{t['bulk_message_option_2']}</p>
                                </MenuItem>
                            </Select>
                            <br />
                        </div>
                        <div>
                            <p className={classes.tabSectionTitle}>{t['bulk_folder_first']}</p>
                            <Select
                                variant='outlined'
                                labelId='sender-picker'
                                disabled={disabled}
                                onChange={(e) => {
                                    onUpdate({
                                        ...leftBarData,
                                        [leftBarData.sender === SenderType.WhatsappGroups || leftBarData.sender === SenderType.PersonGroups ? 'whatsAppSendFolderNames' : 'telegramSendFolderNames']: !!Number(e.target.value)
                                    });
                                }}
                                value={leftBarData.sender === SenderType.WhatsappGroups || leftBarData.sender === SenderType.PersonGroups ? leftBarData.whatsAppSendFolderNames ? 1 : 0 : leftBarData.sender === SenderType.TelegramChats ? leftBarData.telegramSendFolderNames ? 1 : 0 : 0}
                                style={{ height: 37.5, marginBottom: 0, marginTop: 0, width: '100%' }}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    PaperProps: {
                                        style: {
                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                            marginTop: -18,
                                        },
                                    },
                                }}
                            >
                                <MenuItem value='0' key='0' className={classes.menuItem}>
                                    <p>{t['general_no']}</p>
                                </MenuItem>
                                <MenuItem value='1' key='1' className={classes.menuItem}>
                                    <p>{t['general_yes']}</p>
                                </MenuItem>
                            </Select>
                        </div>
                        <br />
                        <div>
                            <TargetSelector setBusy={setBusy} disabled={disabled || (leftBarData.sender == SenderType.TelegramChats && leftBarData.telegramSendingType == SendingOption.ByFolderName)} onSelectedIdsChange={
                                (selectedIds: SelectedTarget[]) => {
                                    onUpdate({
                                        ...leftBarData,
                                        [leftBarData.sender === SenderType.PersonGroups ? 'selectedPersonGroupIds' : 'selectedChatIds']: selectedIds,
                                    })
                                }
                            }
                                selectedIds={leftBarData.sender === SenderType.PersonGroups ? leftBarData.selectedPersonGroupIds : leftBarData.selectedChatIds} type={leftBarData.sender} />
                        </div>
                    </div>
                </div>
            </Grid>
        </Hidden>
    );
};


export default LeftPanel;
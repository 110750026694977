import { useMutation, useQuery, useSubscription } from '@apollo/client';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import InputCopy from '@components/InputCopy';
import InputDropdown from '@components/InputDropdown';
import Modal from '@components/Modal/modal';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import SingleInput from '@components/_Modal/SingleInput';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { ArrowBack } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';
import RichText from '../../../../components/RichText';
import IOSSwitch from '../LiveChat/SharedComponents/IOSSwitch';

import {
   CHECK_DOMAIN_STATUS_MUTATION,
   REMOVE_DOMAIN_FROM_EMAIL_MUTATION,
   UPDATE_DOMAIN_MUTATION,
} from '../../../../queries/Settings/Service/GraphQL/Domain/mutation';
import { GET_DOMAIN_QUERY } from '../../../../queries/Settings/Service/GraphQL/Domain/query';
import {
   CREATED_DOMAIN_SUBSCRIPTION,
   DELETED_DOMAIN_SUBSCRIPTION,
   UPDATED_DOMAIN_SUBSCRIPTION,
} from '../../../../queries/Settings/Service/GraphQL/Domain/subscription';
import { UPDATE_PLATFORM_MUTATION } from '../../../../queries/Settings/Service/GraphQL/Platform/mutation';
import { GET_PLATFORM_DATA_QUERY } from '../../../../queries/Settings/Service/GraphQL/Platform/query';
import { UPDATED_PLATFORM_SUBSCRIPTION } from '../../../../queries/Settings/Service/GraphQL/Platform/subscription';
import { PLATFORM_TYPE } from '../../../../queries/Settings/Service/GraphQL/Platform/types';

import ModalContent from './modalContent';

const useStyles = makeStyles((theme) => ({
   container: {
      padding: theme.spacing(2),
      paddingInline: '20%',
      '& .MuiInputBase-root.Mui-disabled': {
         backgroundColor: '#fafafa',
         color: 'rgba(0, 0, 0, 0.87)',
         borderRadius: 8,
      },
      '& .makeStyles-inputLabel-216': {
         marginTop: -49,
      },
      '& .css-14jk2my-container': {
         backgroundColor: '#fafafa',
      },
      '& .MuiFormControl-root': {
         paddingBottom: 5,
      },
      '& .css-1uccc91-singleValue': {
         color: 'rgba(0, 0, 0, 0.87)',
      },

      '& .css-107lb6w-singleValue': {
         color: 'rgba(0, 0, 0, 0.87)',
      },
   },

   inputLabel: {
      fontSize: '1.2rem',
      color: (props) => props.labelColor || '',
      '&.focused': {
         color: theme.palette.primary.main,
      },
      margin: '0.5rem 0',
   },

   table: {
      minWidth: 700,
   },

   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   title: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },

   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const AccountSetting = ({ person, token }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const emailAccountId = useParams()._id;
   const [emailData, setEmailData] = useState('');
   const [domainData, setDomainData] = useState('');
   const [signatureValue, setSignatureValue] = useState('');
   const [signatureHtmlValue, setSignatureHtmlValue] = useState('');
   const [updateDomainModal, setUpdateDomainModal] = useState(false);
   const [checkDomainStatusLoading, setCheckDomainStatusLoading] = useState(false);
   const [isSignatureActive, setIsSignatureActive] = useState(false);
   const [isClickedSignatureHtml, setIsClickedSignatureHtml] = useState('initial');
   const [isClickedSignatureText, setIsClickedSignatureText] = useState('green');
   const [codeSignature, setCodeSignature] = useState(false);

   const [checkDomainStatusMutation] = useMutation(CHECK_DOMAIN_STATUS_MUTATION);

   const [removeDomainFromEmailMutation, { loading: removeDomainFromEmailLoading }] = useMutation(
      REMOVE_DOMAIN_FROM_EMAIL_MUTATION,
   );

   const [updatePlatformMutation, { loading: updatePlatformLoading }] =
      useMutation(UPDATE_PLATFORM_MUTATION);

   const [updateDomainMutation, { loading: updateDomainLoading }] =
      useMutation(UPDATE_DOMAIN_MUTATION);

   const { loading: emailLoading } = useQuery(GET_PLATFORM_DATA_QUERY, {
      variables: {
         type: PLATFORM_TYPE.EMAIL,
         metaData: { customer: person.customer._id, _id: emailAccountId },
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setEmailData(data?.getPlatformData?.data);
         data?.getPlatformData?.data.emailSignature
            ? setIsSignatureActive(true)
            : setIsSignatureActive(false);
         setValue('name', data?.getPlatformData?.data.senderName);
         setSignatureValue(data?.getPlatformData?.data.emailSignature);
         setSignatureHtmlValue(data?.getPlatformData?.data.emailSignature);
         data?.getPlatformData?.data.emailSignature ? setIsSignatureActive(true) : setIsSignatureActive(false);
      },
      onError: (error) => {
         // on query error
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const { loading: domainLoading } = useQuery(GET_DOMAIN_QUERY, {
      variables: { customer: person.customer._id, _id: emailAccountId },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setDomainData(data?.getDomain?.data);
      },
   });

   useSubscription(UPDATED_DOMAIN_SUBSCRIPTION, {
      variables: {
         token,
         customer: person ? person.customer._id : '',
      },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedDomain: { data },
            },
         },
      }) => {
         setDomainData(data);
      },
   });

   useSubscription(UPDATED_PLATFORM_SUBSCRIPTION, {
      variables: {
         token,
         customer: person ? person.customer._id : '',
         type: PLATFORM_TYPE.EMAIL,
      },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedPlatform: {
                  data: {
                     metaData: { pages: data },
                  },
               },
            },
         },
      }) => {
         setEmailData(data);
         setValue('name', data.senderName);
         setSignatureValue(data.emailSignature);
         setSignatureHtmlValue(data.emailSignature);
         data.emailSignature ? setIsSignatureActive(true) : setIsSignatureActive(false);
      },
   });

   useSubscription(CREATED_DOMAIN_SUBSCRIPTION, {
      variables: {
         token,
         customer: person ? person.customer._id : '',
      },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               createdDomain: { data },
            },
         },
      }) => {
         setDomainData(data);
      },
   });

   useSubscription(DELETED_DOMAIN_SUBSCRIPTION, {
      variables: {
         token,
         customer: person ? person.customer._id : '',
      },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               deletedDomain: { data },
            },
         },
      }) => {
         setDomainData(null);
      },
   });

   const handleRemoveDomainFromEmail = async (_id) => {
      // run removeDomainFromEmail mutation
      await removeDomainFromEmailMutation({
         variables: {
            _id: _id,
            customer: person.customer._id,
         },
      })
         .then((data) => {
            enqueueSnackbar(
               `${data?.data.removeDomainFromEmail.data.domain || ''} ${
                  t['general_information_deleted_successfully']
               }`,
               {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               },
            );
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const handleCheckDomainStatus = async (_id) => {
      // run checkDomainStatus mutation
      setCheckDomainStatusLoading(true);
      await checkDomainStatusMutation({
         variables: {
            _id: _id,
            customer: person.customer._id,
         },
      })
         .then((data) => {
            enqueueSnackbar(
               `${data?.data.checkDomainStatus.data.domain || ''} ${
                  t['general_information_updated_successfully']
               }`,
               {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               },
            );
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
      setCheckDomainStatusLoading(false);
   };

   const handleUpdateDomain = async (data) => {
      // run checkDomainStatus mutation
      await updateDomainMutation({
         variables: {
            _id: emailAccountId,
            customer: person.customer._id,
            senderEmailAddress: data.emailown,
         },
      })
         .then((data) => {
            enqueueSnackbar(
               `${data?.data.updateDomain.data.domain || ''} ${
                  t['general_information_added_successfully']
               }`,
               {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               },
            );
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };
   const handleUpdateEmail = async (data) => {
      // run updateEmail mutation
      await updatePlatformMutation({
         variables: {
            type: PLATFORM_TYPE.EMAIL,
            metaData: {
               _id: emailAccountId,
               customer: person.customer._id,
               senderName: data.name,
               emailSignature: !isSignatureActive && !data.signature ? null : data.signature,
            },
         },
      })
         .then((data) => {
            if (!isSignatureActive) {
               setSignatureValue(null);
               setSignatureHtmlValue(null);
            }
            setCodeSignature(false);
            setIsClickedSignatureText('green');
            setIsClickedSignatureHtml('initial');
            enqueueSnackbar(t['general_information_updated_successfully'], {
               // send success message
               variant: 'success',
               autoHideDuration: 3000,
            });
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const {
      handleSubmit,
      control,
      setValue,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         name: emailData?.senderName,
      },
   });

   const onSubmit = async (data) => {
      if (!updateDomainModal) {
         handleUpdateEmail({
            name: data.name,
            signature: isClickedSignatureHtml === 'green' ? signatureHtmlValue : signatureValue,
         });
      } else {
         handleUpdateDomain(data);
         setUpdateDomainModal(false);
      }
   };

   const initialState = {
      show: false,
   };
   const [modalConfig, setModalConfig] = useState(initialState);
   const [deleteDomainModalVisible, setDeleteDomainModalVisible] = useState(false);

   const handleEmailAdded = () => {
      setUpdateDomainModal(true);
   };

   const handleCloseModal = () => {
      setModalConfig(initialState);
   };

   if (
      emailLoading ||
      removeDomainFromEmailLoading ||
      updatePlatformLoading ||
      updateDomainLoading ||
      domainLoading
   ) {
      return <div className='loading' />;
   }

   return (
      <Grid container>
         <Fragment>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
               <Grid
                  className={classes.titleContainer}
                  style={{
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     width: '100%',
                  }}
               >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                     <Link style={{ color: 'gray' }} to='/settings/platforms/email'>
                        <ArrowBack />
                     </Link>
                     <Typography
                        style={{
                           marginLeft: 10,
                           fontSize: '1.1rem',
                           fontWeight: 'bold',
                           lineHeight: '35px',
                        }}
                     >
                        Account setting
                     </Typography>
                  </div>
                  <Grid className={classes.ButtonContainer}>
                     <ButtonOK
                        type='submit'
                        disabled={false}
                        width={200}
                        height={40}
                        fontSize='1.1rem'
                        label={t.page_settings_button_save}
                     />
                  </Grid>
               </Grid>

               <div
                  style={{
                     display: 'flex',
                     gap: '1.5rem',
                     alignItems: 'center',
                     marginLeft: '1.5rem',
                     fontFamily: 'Helvetica',
                  }}
               >
                  <div style={{ width: '65%' }}>
                     <SingleInput
                        label={t?.page_settings_typo_platforms_add_sender_name}
                        placeholder={emailData.senderName}
                        control={control}
                        name='name'
                        value={emailData?.senderName}
                        required={false}
                        rules={{
                           required: t?.please_fill_this_field,
                        }}
                     />
                     <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography className={classes.inputLabel}>
                           {t?.page_settings_typo_platforms_add_signature_to_all_sent_emails}
                        </Typography>
                        <IOSSwitch
                           checked={isSignatureActive}
                           onChange={() => setIsSignatureActive(!isSignatureActive)}
                        />
                     </Grid>
                     <hr style={{ margin: '0.5rem 0 1rem 0' }} />
                     {isSignatureActive && (
                        <div style={{ marginBottom: '1.5rem' }}>
                           <RichText
                              name='signature'
                              value={signatureValue}
                              setValue={setSignatureValue}
                              setHtmlValue={setSignatureHtmlValue}
                              htmlValue={signatureHtmlValue}
                              setIsClickedSignatureHtml={setIsClickedSignatureHtml}
                              isClickedSignatureHtml={isClickedSignatureHtml}
                              setIsClickedSignatureText={setIsClickedSignatureText}
                              isClickedSignatureText={isClickedSignatureText}
                              setCodeSignature={setCodeSignature}
                              codeSignature={codeSignature}
                              label={t?.page_settings_typo_platforms_add_signature}
                           />
                        </div>
                     )}
                     <div>
                        <Grid container xs={12}>
                           <Grid item xs={12}>
                              <div style={{ fontSize: '1.2rem' }}>
                                 {t?.page_settings_typo_platforms_forwarding_sender_email}
                              </div>
                              <div
                                 style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr 1fr',
                                    border: 'solid #cccccc 1px',
                                    borderRadius: '5px',
                                    paddingLeft: '0.5rem',
                                    marginBottom: '1rem',
                                    backgroundColor: '#EDEDED',
                                 }}
                              >
                                 <p>{emailData.forwardingEmailAddress}</p>
                                 <div
                                    style={{
                                       display: 'flex',
                                       justifyContent: 'flex-end',
                                       alignItems: 'center',
                                    }}
                                 >
                                    <InputCopy value={emailData.forwardingEmailAddress} />
                                 </div>
                              </div>
                           </Grid>
                        </Grid>
                     </div>
                     <Typography style={{ marginBottom: '1rem' }}>
                        {t['page_settings_connect_email_setting_message']}
                     </Typography>
                     <Typography style={{ marginBottom: '1rem' }}>
                        {t['page_settings_connect_email_setting_message2']}
                     </Typography>
                     {domainData ? (
                        <InputDropdown
                           checkDomainStatusLoading={checkDomainStatusLoading}
                           handleCheckDomainStatus={handleCheckDomainStatus}
                           handleRemoveDomainFromEmail={handleRemoveDomainFromEmail}
                           domainData={domainData}
                           labelDomain={t?.page_settings_domain}
                           labelSenderEmailAddress={t?.page_settings_email_sender}
                           emailData={emailData}
                           setDeleteDomainModalVisible={setDeleteDomainModalVisible}
                        />
                     ) : (
                        <ButtonOK
                           label={t?.page_settings_button_send_own_email}
                           className={classes.modalButtons}
                           style={{
                              color: 'white',
                              backgroundColor: '#0060df',
                              width: '100%',
                              margin: '0 auto 20px auto',
                           }}
                           onClick={handleEmailAdded}
                        />
                     )}
                  </div>
                  <div style={{ flex: 1 }}></div>
               </div>

               <ModalContent
                  show={modalConfig.show}
                  mode={modalConfig.mode}
                  onClose={handleCloseModal}
               />
               <Modal
                  open={deleteDomainModalVisible}
                  onClose={() => {
                     setDeleteDomainModalVisible(false);
                  }}
                  modalHeader={t['delete_domain']}
                  paperStyle={{ width: 450 }}
               >
                  <ModalLargeText style={{ textAlign: 'center' }}>
                     {t['delete_domain_modal_are_you_sure']}
                  </ModalLargeText>
                  <ModalButtonField>
                     <Button
                        onClick={() => setDeleteDomainModalVisible(false)}
                        className={classes.modalButtons}
                        style={{
                           color: '#212121',
                           backgroundColor: '#eeee',
                           '&:hover': {
                              backgroundColor: '#e0e0e0',
                           },
                        }}
                     >
                        {t['comp_button_cancel']}
                     </Button>
                     <ButtonCancel
                        // disabled={isButtonLoading}
                        label={t['comp_button_delete']}
                        onClick={() => {
                           handleRemoveDomainFromEmail(emailAccountId);
                           setDeleteDomainModalVisible(false);
                           // setDomainData(false);
                        }}
                        className={classes.modalButtons}
                        style={{
                           color: 'white',
                           backgroundColor: '#ef5350',
                           '&:hover': {
                              backgroundColor: '#f44336',
                           },
                        }}
                     />
                  </ModalButtonField>
               </Modal>
               <Modal
                  open={updateDomainModal}
                  modalHeader={t['page_settings_modal_email_ownemail']}
               >
                  <Grid>
                     <Fragment>
                        <form>
                           <Grid container xs={12}>
                              <SingleInput
                                 label={t?.page_settings_typo_platforms_add_email}
                                 placeholder={t?.page_settings_typo_platforms_add_email_placeholder}
                                 control={control}
                                 name='emailown'
                                 type='email'
                                 required={false}
                                 rules={{
                                    required: t?.please_fill_this_field,
                                 }}
                              />

                              <Grid xs={12} item alignItems='flex-start'>
                                 <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    <Typography>
                                       {[t?.page_settings_modal_email_information_ownemail]}
                                    </Typography>
                                 </div>
                              </Grid>

                              <ModalButtonField>
                                 <Button
                                    onClick={() => setUpdateDomainModal(false)}
                                    className={classes.modalButtons}
                                    style={{
                                       color: '#212121',
                                       backgroundColor: '#eeee',
                                       '&:hover': {
                                          backgroundColor: '#e0e0e0',
                                       },
                                    }}
                                 >
                                    {t?.cancel}
                                 </Button>
                                 <ButtonOK
                                    type='submit'
                                    className={classes.modalButtons}
                                    label={t?.comp_button_connected}
                                    disabled={!isValid}
                                    style={{
                                       color: 'white',
                                       backgroundColor: '#157CFC',
                                       '&:hover': {
                                          backgroundColor: '#105CBB',
                                       },
                                       float: 'right',
                                    }}
                                 />
                              </ModalButtonField>
                           </Grid>
                        </form>
                     </Fragment>
                  </Grid>
               </Modal>
            </form>
         </Fragment>
      </Grid>
   );
};

export default AccountSetting;

import SearchBar from '@components/SearchBar';
import { LanguageContext } from '@helper/locale/langContext';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GridToolbarContainer } from '@material-ui/data-grid';
import React, { useContext } from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';

const useStyles = makeStyles((theme) => ({
   menuItem: {
      margin: 0,
      marginTop: 5,
      marginBottom: 5,
      padding: 0,
   },
   selector: {
      textAlign: 'left',
      border: 'solid 1px lightgrey',
      borderRadius: 5,
      paddingLeft: 3,
      height: 32,
   },
   container: {
      padding: '10px !important',
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
}));

const TopBar = ({ onSearchChange, searchValue, onNewCampaign }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <GridToolbarContainer className={classes.container}>
         <Grid container spacing={8}>
            <Grid container xs={6} item alignItems='flex-start' justifyContent='flex-start'>
               <SearchBar
                  value={searchValue}
                  onChange={onSearchChange}
                  placeholder={t['campaign_search']}
                  style={{ width: 300 }}
               />
            </Grid>
            <Grid container xs={6} item alignItems='flex-end' justifyContent='flex-end'>
               <Button onClick={onNewCampaign} style={{ width: 150 }} color='primary' variant='contained'>
                  {t['comp_navbar_campaign_new']}
               </Button>
            </Grid>
         </Grid>
      </GridToolbarContainer>
   );
};

export default WithUrlTranslations(TopBar);

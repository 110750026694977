import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, CircularProgress, CircularProgressProps, Box } from '@material-ui/core';
import ButtonOK from '@components/ButtonOK/buttonOk';
import 'react-phone-input-2/lib/style.css';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm } from 'react-hook-form';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import Modal from '@components/Modal/modal';

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    return (
        <Box style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            marginBottom: 10
        }} sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
                marginBottom: 10
            }} size={60} variant="determinate" color="primary" value={props.value} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    variant="caption"
                    component="div"
                >{`${Math.round(props.value)}%`}</Typography>
            </Box>
        </Box>
    );
}

const useStyles = makeStyles((theme: any) => ({
    root: {
        width: 700,
        padding: 20,
        borderRadius: 15,
        maxHeight: 700,
        color: theme.chatApp.general.pallet.passiveStructureBlue,
    },
    iconSize: {
        fontSize: '5rem',
    },
    backButton: {
        color: '#748FA7',
        backgroundColor: '#fff',
        textTransform: 'none',
        width: 128,
        height: 40,
        fontSize: '1.1rem',
        border: '1px solid #748FA7',
    },
    modalButtons: {
        fontSize: '18px',
        textTransform: 'none',
        paddingBlock: 5,
        paddingInline: 15,
        minWidth: 140,
        height: 44,
        borderRadius: 6,
        transition: 'all .5s',
        '&:disabled': {
            opacity: '0.7',
        },
    },
}));

export interface ChannelSwitchModalContentProps {
    isSend: boolean;
    isOpen: boolean;
    isLoading: boolean;
    loadingPercent: number;
    onClose: () => void;
    onAccept: () => void;
}

const LeaveOrSendModal = ({ isOpen, onAccept, onClose, loadingPercent, isSend, isLoading }: ChannelSwitchModalContentProps) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;

    return (
        <Modal
            onClose={onClose}
            open={isOpen}
            modalHeader={t['general_message_are_your_sure']}
            disableEscapeKeyDown
            disableBackdropClick
        >
            <Grid>
                <Fragment>
                    <Grid
                        xs={9}
                        item
                        alignItems='center'
                        justifyContent='center'
                        style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                    >
                        <ModalLargeText style={{ textAlign: 'center' }}>
                            {isLoading ? t?.bulk_uploading : isSend ? t?.bulk_upload_send : t?.bulk_upload_leave_message}
                        </ModalLargeText>
                        <div>

                        </div>
                        {
                            isLoading && (
                                loadingPercent > 0 ? (
                                    <CircularProgressWithLabel color='primary' size={40} value={loadingPercent} />
                                ) : (
                                    <CircularProgress style={{
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                        display: "block",
                                        marginBottom: 10
                                    }} color='primary' size={40} />
                                )
                            )
                        }
                    </Grid>
                    <Grid container xs={12}>
                        <ModalButtonField>
                            <Button
                                className={classes.modalButtons}
                                disabled={isLoading}
                                onClick={onClose}
                                style={{
                                    color: '#212121',
                                    backgroundColor: '#eeee',
                                    //@ts-ignore
                                    '&:hover': {
                                        backgroundColor: '#e0e0e0',
                                    },
                                }}
                            >
                                {t?.cancel}
                            </Button>
                            <ButtonOK
                                className={classes.modalButtons}
                                label={t?.comp_button_confirm}
                                onClick={onAccept}
                                disabled={isLoading}
                                style={{
                                    color: 'white',
                                    backgroundColor: '#157CFC',
                                    //@ts-ignore
                                    '&:hover': {
                                        backgroundColor: '#105CBB',
                                    },
                                    float: 'right',
                                }}
                            />
                        </ModalButtonField>
                    </Grid>
                </Fragment>
            </Grid >
        </Modal >
    );
};

export default LeaveOrSendModal;

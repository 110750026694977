import React, { FC, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ButtonBase, ClickAwayListener, IconButton, Typography } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import { DISCOUNT_TYPES, ICartProductProps, IDiscountProps } from '../../types';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import classNames from 'classnames';
import DiscountCard from '../../../../../../ChatItem/DiscountCard';
import { BrokenImage as BrokenImageIcon } from '@material-ui/icons';
import { priceFormatWithoutCurrency } from '@helper/functions';
import { currencyHandler } from '@helper/textUtils';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #D9E0E6',
      borderRadius: 4,
      padding: 8,
      marginLeft: 8,
      marginRight: 8,
      marginBottom: 8,
      marginTop: 0,
      position: 'relative',
      overflow: 'visible',
   },
   selected: {
      border: '1px solid #2175F8',
   },
   image: {
      width: 52,
      height: 52,
      borderRadius: 4,
      flexShrink: 0,
      overflow: 'hidden',
   },
   defaultMediaIcon: {
      color: '#999999',
      fontSize: 42,
   },
   infoContainer: {
      flexGrow: 1,
      overflow: 'visible',
      textOverflow: 'ellipsis',
      paddingLeft: 5,
   },
   upContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
   },
   nameContainer: {
      display: 'flex',
      flexDirection: 'row',
   },
   name: {
      color: '#385273',
      fontSize: '15px',
      lineHeight: 1.4,
      wordBreak: 'break-word',
   },
   variantName: {
      color: '#4192F8',
      fontSize: '15px',
      lineHeight: 1.4,
   },
   downContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   downLeftContainer: {
      display: 'flex',
   },
   price: {
      color: '#157CFC',
      fontSize: '15px',
      lineHeight: 1.3,
      alignSelf: 'left',
   },
   discountedPrice: {
      color: '#8d8d8d',
      fontSize: '15px',
      lineHeight: 1.3,
      alignSelf: 'left',
      textDecoration: 'line-through',
   },
   totalPrice: {
      color: '#385273',
      fontSize: '15px',
      lineHeight: 1.3,
      alignSelf: 'right',
   },
   iconButton: {
      padding: 5,
   },
   icon: {
      fontSize: 18,
      color: '#F00B0B',
   },
   increaseDecreaseContainer: {
      display: 'flex',
      alignSelf: 'center',
      alignItems: 'center',
      border: '1px solid #eee',
      borderRadius: 4,
      marginLeft: 5,
      marginRight: 5,
      marginTop: 0,
      marginBottom: 0,
      padding: 0,
      boxSizing: 'border-box',
      backgroundColor: '#eee',
   },

   increaseDecreaseButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#eee',
   },
   decreaseButton: {
      paddingRight: 5,
   },
   increaseButton: {
      paddingLeft: 5,
   },
   increaseDecreaseButton: {
      borderRadius: 0,
      height: 22,
      width: 22,
   },
   increaseDecreaseIcon: {
      fontSize: 15,
   },
   inputContainer: {
      width: 44,
      borderRadius: 4,
      overflow: 'hidden',
   },
   input: {
      boxSizing: 'border-box',
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 5,
      paddingRight: 5,
      width: 44,
      height: 22,
      border: 'none',
      fontSize: '1em',
      fontWeight: 400,
      lineHeight: 'normal',
      outline: 'none',
      textAlign: 'center',
   },
   dropdown: {
      position: 'absolute',
      width: 'fit-content',
      right: 0,
      left: 0,
      top: '20px',
      zIndex: 1,
   },
   clickAwayListenerContentContainer: {
      position: 'relative',
   },
}));

interface CustomerCartProductListItemProps {
   product: ICartProductProps;
   increaseNumberOfProduct: (productIdAndVariantId: string) => void;
   decreaseNumberOfProduct: (productIdAndVariantId: string) => void;
   removeProductFromCart: (productIdAndVariantId: string) => void;
   onProductQuantityChange: (productIdAndVariantId: string, newValue: number) => void;
   discountApplicability?: boolean | undefined;
   applyDiscountToProduct: (productIdAndVariantId: string, discount: IDiscountProps) => void;
   disabled?: boolean;
}

const CustomerCartProductListItem: FC<CustomerCartProductListItemProps> = ({
   product,
   increaseNumberOfProduct,
   decreaseNumberOfProduct,
   removeProductFromCart,
   onProductQuantityChange,
   discountApplicability,
   applyDiscountToProduct,
   disabled,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const {
      productIdAndVariantId,
      image,
      title,
      quantity,
      price,
      discount,
      variantsType1,
      variantsType2,
      variantsType3,
      discountedPrice: discountedPriceFromProps,
   } = product;
   let variantName = '';
   if (variantsType1 && variantsType1 !== 'Default Title') {
      variantName = variantName + ' / ' + variantsType1;
   }
   if (variantsType2 && variantsType2 !== 'Default Title') {
      variantName = variantName + ' / ' + variantsType2;
   }
   if (variantsType3 && variantsType3 !== 'Default Title') {
      variantName = variantName + ' / ' + variantsType3;
   }
   const [discountCardVisibility, setDiscountCardVisibility] = React.useState(false);
   let discountedPrice;

   if (discount?.discountType && discount?.discountValue) {
      if (discount?.discountType === DISCOUNT_TYPES.PERCENTAGE) {
         discountedPrice = price - (price * discount.discountValue) / 100;
      } else if (discount?.discountType === DISCOUNT_TYPES.FIXED_VALUE) {
         if (discount.discountValue <= price) {
            discountedPrice = price - discount.discountValue;
         } else {
            discountedPrice = 0;
         }
      }
   }

   const handleChange = (e: any) => {
      onProductQuantityChange(productIdAndVariantId, e.target.value);
   };

   const handleClick = () => {
      setDiscountCardVisibility((prev) => !prev);
   };

   const closeDiscountCard = () => {
      setDiscountCardVisibility(false);
   };

   const handleDiscountData = (data: IDiscountProps) => {
      applyDiscountToProduct(productIdAndVariantId, data);
      closeDiscountCard();
   };

   return (
      <div className={classes.container}>
         {image ? (
            <img src={image} alt='product image' className={classes.image} />
         ) : (
            <div
               className={classes.image}
               style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <BrokenImageIcon className={classes.defaultMediaIcon} />
            </div>
         )}
         <div className={classes.infoContainer}>
            <div className={classes.upContainer}>
               <div className={classes.nameContainer}>
                  <Typography variant='body1' className={classes.name}>
                     {title}
                     {variantName !== '' && (
                        <Typography variant='body1' display='inline' className={classes.variantName}>
                           {variantName}
                        </Typography>
                     )}
                  </Typography>
               </div>
               <IconButton className={classes.iconButton} onClick={() => removeProductFromCart(productIdAndVariantId)} disabled={disabled}>
                  <CloseRoundedIcon className={classes.icon} />
               </IconButton>
            </div>
            <div className={classes.downContainer}>
               <ClickAwayListener onClickAway={closeDiscountCard}>
                  <div className={classes.clickAwayListenerContentContainer}>
                     <ButtonBase disabled={!discountApplicability} disableRipple onClick={handleClick}>
                        <Typography
                           variant='body2'
                           className={classNames(
                              classes.price,
                              discountedPrice !== undefined && classes.discountedPrice,
                           )}
                        >
                           {`${currencyHandler(product?.currency)}${priceFormatWithoutCurrency(
                              discountedPriceFromProps != undefined && discountedPriceFromProps != 0.00 ? discountedPriceFromProps : price,
                           )}`}
                        </Typography>
                        {discountedPrice !== undefined && (
                           <Typography variant='body2' className={classes.price}>
                              {`${currencyHandler(product?.currency)}${priceFormatWithoutCurrency(discountedPrice)}`}
                           </Typography>
                        )}
                     </ButtonBase>
                     {discountCardVisibility && (
                        <div className={classes.dropdown}>
                           <DiscountCard
                              close={closeDiscountCard}
                              currency={currencyHandler(product?.currency)}
                              handleDiscountData={handleDiscountData}
                              price={discountedPriceFromProps || price}
                              currentDiscount={discount}
                           />
                        </div>
                     )}
                  </div>
               </ClickAwayListener>
               <div className={classes.increaseDecreaseContainer}>
                  <div className={classes.increaseDecreaseButtonContainer}>
                     <IconButton
                        className={classNames(classes.increaseDecreaseButton, classes.decreaseButton)}
                        onClick={() => decreaseNumberOfProduct(productIdAndVariantId)}
                        size='small'
                        disableRipple
                        disabled={disabled}
                     >
                        <RemoveRoundedIcon className={classes.increaseDecreaseIcon} />
                     </IconButton>
                  </div>
                  <div className={classes.inputContainer}>
                     <input
                        type='number'
                        name='quantity'
                        value={quantity}
                        onChange={handleChange}
                        className={classes.input}
                        disabled={disabled}
                     />
                  </div>
                  <div className={classes.increaseDecreaseButtonContainer}>
                     <IconButton
                        className={classNames(classes.increaseDecreaseButton, classes.increaseButton)}
                        onClick={() => increaseNumberOfProduct(productIdAndVariantId)}
                        size='small'
                        disableRipple
                        disabled={disabled}
                     >
                        <AddRoundedIcon className={classes.increaseDecreaseIcon} />
                     </IconButton>
                  </div>
               </div>
               <Typography variant='body2' className={classes.totalPrice}>
                  {`${currencyHandler(product?.currency)}${priceFormatWithoutCurrency(
                     discountedPrice !== undefined && discountedPrice !== 0.00
                        ? discountedPrice * quantity
                        : (discountedPriceFromProps == 0.00 || discountedPriceFromProps == undefined ? price : discountedPriceFromProps) * quantity,
                  )}`}
               </Typography>
            </div>
         </div>
      </div>
   );
};

export default CustomerCartProductListItem;

import { useQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { GET_PERSON_CUSTOMERS_QUERY } from '@queries/Person/Service/GraphQL/query';
import { GET_PERSONS_STATISTICS_QUERY } from '@queries/Statistics/Service/GraphQL/query';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { GET_DASHBOARDS_STATISTICS_QUERY } from '../../../queries/Dashboard/Service/GraphQL/query';
import * as GetDashboardStatisticsTypes from '../../../queries/Dashboard/Service/GraphQL/__generated__/getDashboardsStatisticsQuery';
import ChannelPerformance from '../ChannelPerformance';
import ChartsSection from '../ChartsSection';
import HintSection from '../Comments/hintSection';
import SmallChipSection from '../SmallChipsSection';

const DashboardMessages = () => {
   const person = useSelector((rootReducer: any) => rootReducer.personReducer.person);
   const { data: dashboardsStatistics } = useQuery<
      GetDashboardStatisticsTypes.getDashboardsStatisticsQuery,
      GetDashboardStatisticsTypes.getDashboardsStatisticsQueryVariables
   >(GET_DASHBOARDS_STATISTICS_QUERY, {
      variables: { customer: person?.customer?._id },
      fetchPolicy: 'network-only',
   });

   const { data: performanceData } = useQuery(GET_PERSONS_STATISTICS_QUERY, {
      variables: {
         customer: person?.customer?._id,
         platform: 'ALL',
         startDate: moment().startOf('day'),
         endDate: moment().endOf('day'),
      },
      fetchPolicy: 'network-only',
   });

   const { data: personData } = useQuery(GET_PERSON_CUSTOMERS_QUERY, {
      variables: { customer: person.customer._id, notSelectBot: true, page: 1, pageSize: 100 },
      fetchPolicy: 'cache-first',
   });
   const data = dashboardsStatistics?.getDashboardsStatistics.data;

   return (
      <Fragment>
         <SmallChipSection
            activeChatsCount={data?.activeChatsCount}
            closedChatsCount={data?.closedChatsCount}
            spammedChatsCount={data?.spammedChatsCount}
            waitingChatsCount={data?.waitingChatsCount}
            solvedChatsCount={data?.solvedChatsCount}
         />

         <ChannelPerformance
            channelData={{
               facebook: data?.facebookReceived,
               instagram: data?.instagramReceived,
               whatsAppBusiness: data?.whatsAppBusinessReceived,
               whatsAppWeb: data?.whatsAppWebReceived,
               liveChat: data?.liveChatReceived,
            }}
            performanceData={{
               ...performanceData?.getPersonsStatistics.data,
               teamFirstResponseGoalTime: person?.customer?.teamFirstResponseGoalTime,
               teamGoalTime: person?.customer?.teamGoalTime,
            }}
            personData={personData?.customerPersons?.data?.docs}
         />

         <ChartsSection
            chartReport={data?.chartReport || []}
            receivedToday={data?.receivedToday}
            sentToday={data?.sentToday}
         />
         <Grid style={{ marginTop: 24 }} item container xs={12}>
            <HintSection />
         </Grid>
      </Fragment>
   );
};

export default DashboardMessages;

import React, { useState } from 'react';
import { FlowBlockType, FlowNodeType, IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import UserSendMessageNode from './userSendMessageNode';
import { Grid } from '@material-ui/core';
import UserVisitsPageNode from './userVisitsPageNode';
import { Node, useReactFlow } from 'reactflow';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SET_CLICKED_NODE_ID } from '@store/actions/automation';

interface TriggerNodeProps {
   id: string;
   isConnectable: boolean;
   data: {
      clickedNodeId: string;
      title: string;
      isNewNode: string;
      setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
      setNodeInstances: React.Dispatch<React.SetStateAction<Node[]>>;
      setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
      setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
      flowBlocks: IFlowBlockTemplate[];
      isValidNode: Boolean;
      setIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
   };
}
const TriggerNode = ({ id, isConnectable, data }: TriggerNodeProps) => {
   const { flowBlocks, isNewNode, setClickedNodeId } = data;
   const [isFocus, setIsFocus] = useState(false);
   const dispatch = useDispatch();

   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);
   const { setCenter } = useReactFlow();
   const focusNode = (targetNode: Node<any> | undefined) => {
      if (targetNode && botStatus === 'draft') {
         const node = targetNode;
         if (node.width && node.height) {
            const x = node.position.x + node.width / 2;
            const y = node.position.y + node.height / 2;
            const zoom = 2;
            setCenter(x, y, { zoom, duration: 1000 });
            setClickedNodeId(targetNode.id);
            dispatch({ type: SET_CLICKED_NODE_ID, payload: targetNode.id });

            setIsFocus(true);
         }
      } else {
         dispatch({ type: SET_CLICKED_NODE_ID, payload: '' });
         setClickedNodeId('');
      }
   };

   const reactFlow = useReactFlow();
   const nodes = reactFlow.getNodes();
   const triggerNode = nodes.find((node) => node.type === FlowNodeType.Triggers);
   if (isNewNode.length === 0 && !isFocus) {
      focusNode(triggerNode);
   }

   return (
      <Grid>
         {flowBlocks[0]?.type === FlowBlockType.AllMessageReceived ? (
            <UserSendMessageNode id={id} isConnectable={isConnectable} data={data} />
         ) : (
            <UserVisitsPageNode id={id} isConnectable={isConnectable} data={data} />
         )}
      </Grid>
   );
};
export default TriggerNode;

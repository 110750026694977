import {
   SET_PERSON_ACTIVITY,
   NEW_PERSON_ACTIVITY,
   UPDATE_PERSON_ACTIVITY,
   DELETE_PERSON_ACTIVITY,
   SET_ONLINE_PERSONS,
   NEW_ONLINE_PERSONS,
   DELETE_ONLINE_PERSONS,
   CHAT_STARTED,
} from '../actions/personActivity'; // action types
import _ from 'underscore';
// default initial state
const INITIAL_STATE = {
   activePersons: [],
   personActivities: [],
};

export default (state = INITIAL_STATE, action = {}) => {
   switch (action.type) {
      case SET_PERSON_ACTIVITY:
         return { ...action.payload, personActivities: action.payload };
      case NEW_PERSON_ACTIVITY:
         try {
            return { ...state, personActivities: [action.payload, ...state.personActivities] };
         } catch (e) {
            return { ...state, personActivities: [action.payload] };
         }
      case UPDATE_PERSON_ACTIVITY:
         if (action.payload.lastStatus == 2) {
            return { ...state, personActivities: state.personActivities.filter((e) => e._id != action.payload._id) };
         } else {
            if (!state.personActivities.find((e) => e._id == action.payload._id)) {
               return { ...state, personActivities: [action.payload, ...state.personActivities] };
            }

            const newObj = state.personActivities.map((e) => {
               if (e._id == action.payload._id) {
                  return action.payload;
               } else {
                  return e;
               }
            });
            return { ...state, personActivities: newObj };
         }
      case DELETE_PERSON_ACTIVITY:
         return {
            ...state,
            personActivities: state.personActivities.filter(
               (personActivity) => personActivity._id !== action.payload._id,
            ),
         };
      case SET_ONLINE_PERSONS:
         return { ...state, activePersons: action.payload };
      case NEW_ONLINE_PERSONS:
         try {
            return { ...state, activePersons: [action.payload, ...state.activePersons] };
         } catch (e) {
            console.log('error', e);
            return { ...state, activePersons: [action.payload] };
         }
      case DELETE_ONLINE_PERSONS:
         return {
            ...state,
            activePersons: state.activePersons.filter((person) => !_.findWhere(action.payload, { _id: person._id })),
         };
      case CHAT_STARTED:
         return {
            ...state,
            personActivities: state.personActivities.map((e) => {
               if (e.person == action.payload.personString) {
                  return new Object({ ...e, chatStarted: true });
               } else {
                  return e;
               }
            }),
         };

      default:
         return state;
   }
};

export const getActivePersons = (state) => state.personActivityReducer.activePersons;
export const getPersonActivities = (state) => state.personActivityReducer.personActivities;

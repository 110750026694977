import React, { useContext, useState, useEffect, useMemo } from 'react';
import {
   MenuItem,
   Select,
   Typography,
   useMediaQuery,
   Button,
   IconButton,
   TextField,
   InputBase,
   FormControl,
   CircularProgress,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import Menu from '@material-ui/core/Menu';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ListItem from '@material-ui/core/ListItem';
import InfiniteScroll from 'react-infinite-scroll-component';
import { GET_GROUP_LIST } from '@queries/PersonGroups/Service/GraphQL/query';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { getPerson } from '@store/reducers/person';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ElectricBoltIcon from '@material-ui/icons/FlashOn';

const useStyles = makeStyles((theme) => ({
   container: {
      backgroundColor: '#fff',
      padding: 10,
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   innerLabel: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      // padding:0,
      marginTop: -12,
   },
   menuItem: {
      margin: 0,
      marginTop: 5,
      marginBottom: 5,
      padding: 0,
   },
   selector: {
      textAlign: 'left',
      border: 'solid 1px lightgrey',
      borderRadius: 5,
      paddingLeft: 3,
      height: 32,
   },
   selectRoot: {
      border: '1px solid ' + theme.chatApp.general.pallet.passiveStructureBlue,
      backgroundColor: 'transparent',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: '10px',
      width: '100%',
      height: '38px',
      paddingTop: '3px',
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   listItemText: {
      flex: '0 1 auto',
      '& .MuiTypography-body1': {
         overflow: 'hidden',
         'white-space': 'nowrap',
         'text-overflow': 'ellipsis',
      },
   },
   listItemTextUserCount: {
      display: 'flex',
      flexGrow: 1,
      marginRight: 10,
   },
   editButton: {
      width: 32,
      height: 32,
      '&:hover': {
         color: theme.chatApp.general.pallet.passiveStructureAzure,
      },
   },
   editIcon: {
      fontSize: 20,
   },
   searchInput: {
      margin: '8px 0px 5px 10px !important',
      width: '90%',
      '& .MuiOutlinedInput-root': {
         height: '38px',
      },
   },
   groupIcon: {
      color: 'rgba(0, 0, 0, 0.4)',
      marginRight: 8,
   },
}));

const Page = {
   pageSize: 10,
   page: 1,
};

const TopTopToolbar = ({
   DynamicGroup,
   createGroup,
   editGroup,
   personGroups,
   createManual,
   importUsers,
   onGroupChanged,
   selectedGroupData,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const [anchorEl, setAnchorEl] = useState(null);
   const me = useSelector(getPerson);

   const [groupList, setGroupList] = useState([]);
   const [groupListCount, setGroupListCount] = useState(0);
   const [search, setSearch] = useState('');

   const open = Boolean(anchorEl);
   const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = (type) => {
      if (type === 0) {
         createManual();
      } else if (type === 1) {
         createGroup();
      } else if (type === 2) {
         DynamicGroup();
      }
      setAnchorEl(null);
   };

   const [getGroupListQuery] = useLazyQuery(GET_GROUP_LIST, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setGroupList([...groupList, ...data?.getGroupList?.data?.docs]);
         setGroupListCount(data?.getGroupList?.data?.docsCount);
      },
   });

   const handleNextAnswerPage = () => {
      if (groupList.length < groupListCount) {
         setGroupListParam({
            ...groupListParam,
            page: groupListParam.page + 1,
         });
      }
   };

   const [groupListParam, setGroupListParam] = useState({
      customer: me.customer._id,
      pageSize: Page.pageSize,
      page: Page.page,
      search: '',
   });

   useEffect(() => {
      setGroupList([]);
      setGroupListParam((prev) => ({ ...prev, search: search, page: Page.page }));
   }, [search]);

   useEffect(() => {
      getGroupListQuery({
         variables: {
            ...groupListParam,
         },
      });
   }, [groupListParam]);

   const Loader = () => {
      return (
         <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   };

   return (
      <Grid
         container
         direction={isSmallScreen ? 'column' : 'row'}
         alignItems={!isSmallScreen && 'center'}
         className={classes.container}
      >
         <Grid container alignItems='flex-start' justifyContent='flex-start' item xs={8} style={{ paddingRight: 5 }}>
            <FormControl>
               <Select
                  displayEmpty
                  value={selectedGroupData || -1}
                  input={<InputBase />}
                  renderValue={(selected) => {
                     if (selected._id === -1) {
                        return (
                           <span>
                              <b>{t['label_group']}</b> {t['label_group_filter_all']}
                           </span>
                        );
                     }
                     return (
                        <span>
                           <b>{t['label_group']}</b> {personGroups.find((group) => group._id === selected._id)?.name}
                        </span>
                     );
                  }}
                  MenuProps={{
                     // autoFocus: false,
                     anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                     },
                     transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                     },
                     getContentAnchorEl: null,
                     PaperProps: {
                        style: {
                           maxHeight: 400,
                           minWidth: 300,
                        },
                     },
                  }}
               >
                  {personGroups.length === 0 ? (
                     <ListItem disabled disablePadding>
                        <span>{t['no_user_group']}</span>
                     </ListItem>
                  ) : (
                     <>
                        <Grid>
                           <TextField
                              id='search-group-filter-box'
                              label={t.page_chat_search_placeholder}
                              variant='outlined'
                              size='small'
                              fullWidth
                              value={search}
                              onChange={(e) => {
                                 setSearch(e.target.value);
                              }}
                              // onClick={stopPropagation}
                              className={classes.searchInput}
                           />
                        </Grid>
                        <Grid>
                           <MenuItem
                              style={{
                                 borderLeft: selectedGroupData._id === -1 ? '4px solid #157CFC' : undefined,
                              }}
                              key={-1}
                              value={-1}
                              disablePadding
                              disableRipple
                              onClick={(e) => {
                                 e.stopPropagation();
                                 onGroupChanged({ _id: -1 });
                              }}
                           >
                              <Typography noWrap={true}>{t['label_group_filter_all']}</Typography>
                           </MenuItem>
                        </Grid>

                        <Grid>
                           <InfiniteScroll
                              scrollableTarget='scrollableDiv'
                              dataLength={groupList?.length || 0}
                              hasMore={groupList.length >= groupListCount ? false : true}
                              loader={<Loader />}
                              next={handleNextAnswerPage}
                              height={200}
                           >
                              {groupList.map((group) => (
                                 <MenuItem
                                    style={{
                                       borderLeft:
                                          group._id === selectedGroupData._id ? '4px solid #157CFC' : undefined,
                                    }}
                                    key={group._id}
                                    value={group._id}
                                    disablePadding
                                    disableRipple
                                    onClick={(e) => {
                                       e.stopPropagation();
                                       onGroupChanged(group);
                                    }}
                                 >
                                    {group.isDynamic ? (
                                       <ElectricBoltIcon className={classes.groupIcon} />
                                    ) : (
                                       <AccountCircleIcon className={classes.groupIcon} />
                                    )}
                                    <ListItemText noWrap={true} className={classes.listItemText}>
                                       {group.name}&nbsp;
                                    </ListItemText>
                                    <ListItemText className={classes.listItemTextUserCount}>
                                       {!group.isDynamic && <span style={{ opacity: 0.7 }}> ({group.userCount})</span>}
                                    </ListItemText>
                                    <IconButton
                                       onClick={(e) => {
                                          group.isDynamic
                                             ? DynamicGroup(
                                                  group._id,
                                                  group.name,
                                                  group.shouldMatchAllConditions,
                                                  group.conditions,
                                                  true,
                                               )
                                             : editGroup(group._id, group.name);
                                          e.stopPropagation();
                                       }}
                                       disableRipple
                                       className={classes.editButton}
                                    >
                                       <EditIcon className={classes.editIcon} />
                                    </IconButton>
                                 </MenuItem>
                              ))}
                           </InfiniteScroll>
                        </Grid>
                     </>
                  )}
               </Select>
            </FormControl>
         </Grid>
         <Grid
            xs={4}
            item
            container
            alignItems='flex-end'
            justifyContent='flex-end'
            style={{ paddingRight: 0, paddingTop: isSmallScreen && 10 }}
         >
            <Button
               variant='outlined'
               startIcon={<CloudDownloadIcon />}
               style={{ marginRight: 5 }}
               onClick={importUsers}
               height={38}
               fontSize='1rem'
               fontWeight='bold'
            >
               {t?.import}
            </Button>
            <div>
               <Button
                  aria-controls={open ? 'demo-customized-menu' : undefined}
                  aria-haspopup='true'
                  aria-expanded={open ? 'true' : undefined}
                  variant='primary'
                  disableElevation
                  style={{ marginRight: 5, color: 'white', backgroundColor: '#157CFC' }}
                  onClick={handleClick}
               >
                  {t['create_contact_or_group']}
               </Button>
               <Menu
                  id='fade-menu'
                  MenuListProps={{
                     'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'right',
                  }}
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
                  getContentAnchorEl={null}
                  onClose={() => handleClose(-1)}
               >
                  <MenuItem onClick={() => handleClose(0)} disableRipple>
                     {t['create_new_contact_title']}
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(1)} disableRipple>
                     {t['create_new_user_group']}
                  </MenuItem>
                  <MenuItem onClick={() => handleClose(2)} disableRipple>
                     {t['create_new_user_dynamic_group']}
                  </MenuItem>
               </Menu>
            </div>
         </Grid>
      </Grid>
   );
};

export default TopTopToolbar;

interface ISavedAddress {
   firstName: string;
   lastName: string;
   phone: string;
   address: string;
   country: string;
   city: string;
   state: string;
   zipCode: string;
}

export interface ICustomerInfoProps {
   _id: number;
   firstName: string;
   lastName: string;
   email: string;
   phone: string;
   shippingAddress: string;
   savedAddresses: ISavedAddress[];
   firstNameShipping: string;
   lastNameShipping: string;
   phoneShipping: string;
   addressShipping: string;
   countryShipping: string;
   cityShipping: string;
   stateShipping: string;
   zipCodeShipping: string;
   customerNote: string;
   tags: string[];
}

interface ICustomerOrderProps {
   _id: number;
   name: string;
   price: number;
}

export interface IProductVariant {
   variantId: string;
   name: string;
   image: string;
   price: number;
   salesPrice: number;
   available: number;
   stock: number;
}

export interface IProductProps {
   _id: string;
   productIdAndVariantId: string;
   productId: string;
   variantId: string;
   image: string;
   name: string;
   category: string;
   available?: number;
   price?: number;
   isPriceIncludeTaxes: boolean;
   taxRate: number;
   variants?: IProductVariant[];
}

export enum DISCOUNT_TYPES {
   FIXED_VALUE = 'fixed_amount',
   PERCENTAGE = 'percentage',
}

export type DiscountType = DISCOUNT_TYPES.FIXED_VALUE | DISCOUNT_TYPES.PERCENTAGE;

export interface IDiscountProps {
   discountType: DiscountType;
   discountValue: number;
   discountReason: '';
}

export interface ICartProductProps {
   title: string;
   variantName: string;
   productIdAndVariantId: string;
   _id: string;
   variantId: string;
   currency: string;
   price: number;
   discount?: IDiscountProps | undefined;
   discountedPrice?: number | undefined;
   salesPrice?: number;
   stock: number;
   quantity: number;
   image?: string | undefined;
   variantsType1: string | null;
   variantsType2: string | null;
   variantsType3: string | null;

   // discountedPrice?: number | undefined;
   // _id: string;
   // productId: string;
   // price: number;
   // isPriceIncludeTaxes: boolean;
   // taxRate: number;
   // discount?: IDiscountProps;
   // quantity: number;
   // image: string;
   // variantName?: string;
   // discountedPrice?: number;
   // currency?: string;
}

export interface ICustomerCartProps {
   customer: ICustomerInfoProps | null;
   orderId?: string;
   products: any[];
}

export interface ICustomerProps {
   info: ICustomerInfoProps;
   orders: ICustomerOrderProps[];
   cart: ICustomerCartProps;
}

export interface ITagProps {
   value: string;
   label: string;
}

export type CollectPaymentModalType =
   | 'pay_by_credit_card'
   | 'mark_as_pending'
   | 'mark_as_paid'
   | 'send_invoice'
   | 'eft'
   | 'on_door'
   | 'complete_order'
   | 'custom';

export interface CreditCard {
   cardHolderName: string;
   cardNumber: number;
   date: string;
   cvc: number;
}

export interface NotificationChannels {
   chatChecked: boolean;
   emailChecked: boolean;
   SMSChecked: boolean;
   whatsappChecked: boolean;
}

import {
   ActiveVisitorIcon,
   BulkMessagingIcon,
   CampaignIcon,
   ChatIcon,
   HomeIcon,
   QuestionsSideBarIcon,
   SettingsIcon,
   StatisticsIcon,
   AutomationIcon,
   SupportIcon,
} from '@assets/icons/SideBarIcons/sideBarIcons';
import ButtonBasedPopup from '@components/ButtonBasedPopup';
import MediumAvatar from '@components/MediumAvatar';
import { SITE_URL } from '@helper/config';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import { getPersonActivities } from '@store/reducers/personActivity';
import clsx from 'clsx';
import React, { Fragment, useCallback, useState, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import ProfileMenu from './profileMenu';
import SideBarItem from './sideBarItem';
import SaveDraftFlow from '@modules/Automation/FlowBots/components/SaveDraftFlow';
import {
   UPDATE_ECOMMERCE_RULE,
   UPDATE_ECOMMERCE_RULE_STATUS,
   UPDATE_FLOW_BOT_MUTATION,
} from '@queries/Automation/mutation';
import { useMutation } from '@apollo/client';
import { useSnackbar } from 'notistack';
import { LanguageContext } from '@helper/locale/langContext';
import { SET_BOT_STATUS, SET_RULE_STATUS } from '@store/actions/automation';
import { useDispatch } from 'react-redux';
import SaveDraftRule from '@modules/Automation/FlowBots/components/SaveDraftRule';
// import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

const useStyles = makeStyles((theme) => ({
   horizontalList: {
      display: 'flex',
      flexDirection: 'row',
      padding: 0,
      alignItems: 'center',
      justifyContent: 'center',
   },
   verticalList: {
      // default list behaviour
      // marginTop: theme.spacing(4),
   },
   avatarH: {
      marginTop: 5,
   },
   avatarV: {
      marginLeft: 10,
      position: 'fixed',
      bottom: 40,
   },
}));

const SideBarItems = ({ mode }) => {
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const personActivities = useSelector(getPersonActivities);

   const HORIZONTAL_ITEMS = [
      {
         title: 'Dashboard',
         url: '/',
         personType: 0,
         icon: (classValues) => <HomeIcon className={classValues} />,
      },
      {
         title: 'Chat',
         url: '/chat',
         personType: 0,
         icon: (classValues) => <ChatIcon className={classValues} />,
      },
      {
         title: 'Questions',
         url: '/questions',
         icon: (classValues) => <QuestionsSideBarIcon className={classValues} />,
         personType: 0,
      },
      {
         title: 'Contacts',
         url: '/contacts',
         personType: 1,
         icon: (classValues) => <PermContactCalendarIcon className={classValues} />,
      },
      {
         title: 'Active Visitors',
         url: '/active-visitors',
         personType: 1,
         icon: (classValues) => <ActiveVisitorIcon className={classValues} />,
      },
      {
         title: 'Automations',
         url: '/automation',
         icon: (classValues) => <AutomationIcon className={classValues} />,
         personType: 1,
      },
   ];

   let VERTICAL_ITEMS = [
      {
         title: 'Dashboard',
         url: '/',
         icon: (classValues) => <HomeIcon className={classValues} />,
         personType: 0,
      },
      {
         title: 'Chat',
         url: '/chat',
         icon: (classValues) => <ChatIcon className={classValues} />,
         personType: 0,
      },
      {
         title: 'Questions',
         url: '/questions',
         icon: (classValues) => <QuestionsSideBarIcon className={classValues} />,
         personType: 0,
      },
      {
         title: 'Contacts',
         url: '/contacts',
         icon: (classValues) => <PermContactCalendarIcon className={classValues} />,
         personType: 1,
      },
      {
         title: 'Active Visitors',
         url: '/active-visitors',
         personType: 1,
         icon: (classValues) => <ActiveVisitorIcon className={classValues} active={personActivities.length > 0} />,
      },
      {
         title: 'Automations',
         url: '/automation',
         icon: (classValues) => <AutomationIcon className={classValues} />,
         personType: 1,
      },
      {
         title: 'Campaigns',
         url: '/campaigns',
         icon: (classValues) => <CampaignIcon className={classValues} />,
         personType: 1,
      },
      {
         title: 'Statistics',
         url: '/statistics',
         icon: (classValues) => <StatisticsIcon style={{ width: 20 }} className={classValues} />,
         personType: 1,
      },
      {
         title: 'Settings',
         url: '/settings/company/hours',
         icon: (classValues) => <SettingsIcon className={classValues} />,
         personType: 1,
      },
   ];

   if (person.customer.isWholeSaler) {
      VERTICAL_ITEMS = [
         ...VERTICAL_ITEMS.slice(0, 5),
         {
            title: 'Bulk Messaging',
            url: '/bulk-messaging',
            icon: (classValues) => <BulkMessagingIcon className={classValues} />,
            personType: 1,
         },
         ...VERTICAL_ITEMS.slice(5, VERTICAL_ITEMS.length),
      ];
   }

   // mode could be HORIZONTAL or VERTICAL
   const classes = useStyles();
   const [selectedIndex, setSelectedIndex] = useState(-1);
   const location = useLocation();
   const [openProfileMenu, setOpenProfileMenu] = useState(false);
   const isHorizontal = mode === 'HORIZONTAL';
   const items = isHorizontal ? HORIZONTAL_ITEMS : VERTICAL_ITEMS;
   const { enqueueSnackbar } = useSnackbar();

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const botId = useSelector((reducer) => reducer.automationReducer.botId);
   const ruleId = useSelector((reducer) => reducer.automationReducer.ruleId);
   const dispatch = useDispatch();

   // Update mutation
   const [updateFlowBot] = useMutation(UPDATE_FLOW_BOT_MUTATION);
   const [updateStatus] = useMutation(UPDATE_ECOMMERCE_RULE_STATUS);

   const handleToggleStatus = async (status) => {
      try {
         await updateFlowBot({
            variables: {
               input: {
                  _id: botId,
                  status: status,
               },
            },
            onCompleted: () => {
               dispatch({ type: SET_BOT_STATUS, payload: '' });
            },
         });
      } catch (error) {
         console.error('Error updating status:', error);

         enqueueSnackbar(t.atomation_flow_notification, {
            variant: 'error',
            autoHideDuration: 2000,
         });
         dispatch({ type: SET_BOT_STATUS, payload: '' });
      }
   };
   const handleToggleStatusRule = async (status) => {
      try {
         await updateStatus({
            variables: {
               input: {
                  _id: ruleId,
                  status: status,
                  customer: person.customer._id,
               },
            },
            onCompleted: () => {
               dispatch({ type: SET_RULE_STATUS, payload: '' });
            },
         });
      } catch (error) {
         console.error('Error updating status:', error);

         enqueueSnackbar(t.automation_status_error, {
            variant: 'error',
            autoHideDuration: 2000,
         });
         dispatch({ type: SET_RULE_STATUS, payload: '' });
      }
   };

   const handleItemClick = useCallback(() => {
      setOpenProfileMenu(false);
   }, []);

   const botStatus = useSelector((reducer) => reducer.automationReducer.botSatus);
   const ruleStatus = useSelector((reducer) => reducer.automationReducer.ruleSatus);
   const ruleState = useSelector((reducer) => reducer.automationReducer.ruleState);
   const isSecondMessage = useSelector((reducer) => reducer.automationReducer.isSecond);

   const [openModalRule, setOpenModalRule] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [link, setLink] = useState(null);

   const handlePublish = () => {
      handleToggleStatus(true);
   };

   const handleDraft = () => {
      handleToggleStatus(false);
   };

   const [updateRule] = useMutation(UPDATE_ECOMMERCE_RULE);

   const handleSave = async () => {
      try {
         const updatedInput = isSecondMessage
            ? { ...ruleState.input }
            : {
                 ...ruleState.input,
                 message2: {
                    sendMessage: {
                       messageTemplateId: null,
                    },
                    delay: {
                       delayTimeUnit: 'HOURS',
                       delayTime: 1,
                    },
                    action: {
                       ruleId: null,
                       rule: null,
                    },
                 },
              };

         await updateRule({
            variables: { input: updatedInput },
            onCompleted: async () => {
               handleToggleStatusRule(true);
            },
         });
      } catch (error) {
         console.error('Error saving rule:', error);
      }
   };
   const handlePublishRule = () => {
      handleSave();
   };

   const handleDraftRule = () => {
      handleToggleStatusRule(false);
   };

   const handleLinkClick = (event, item) => {
      if (botStatus === 'draft') {
         event.preventDefault();
         setOpenModal(true);
         setLink(item.url);
      }
      if (ruleStatus === 'draft') {
         event.preventDefault();
         setOpenModalRule(true);
         setLink(item.url);
      }
   };

   return (
      <Grid container direction={isHorizontal ? 'row' : 'column'} justifyContent='space-between' wrap='nowrap'>
         {items.map((item, i) => {
            return (
               <Fragment key={item.url + i}>
                  {person.type >= item.personType && (
                     <Link
                        to={botStatus === 'draft' ? null : item.url}
                        onClick={(event) => handleLinkClick(event, item)}
                     >
                        <Grid
                           key={item.url}
                           item
                           onMouseEnter={() => {
                              if (selectedIndex !== i) {
                                 setSelectedIndex(i);
                              }
                           }}
                           onMouseLeave={() => {
                              setSelectedIndex(-1);
                           }}
                        >
                           <SideBarItem
                              isHorizontal={isHorizontal}
                              hovered={selectedIndex === i}
                              item={item}
                              selected={location.pathname.split('/')[1] === item.url.split('/')[1]}
                              onClick={handleItemClick}
                           />
                        </Grid>
                     </Link>
                  )}
               </Fragment>
            );
         })}
         <Grid item className={clsx(isHorizontal && classes.avatarH, !isHorizontal && classes.avatarV)}>
            <ButtonBasedPopup
               button={
                  <Grid style={{ marginLeft: isHorizontal ? 0 : -3 }}>
                     <MediumAvatar image={person?.photo ? person?.photo : SITE_URL + '/none.jpg'} size='medium' />
                  </Grid>
               }
               open={openProfileMenu}
               onOpen={() => {
                  setOpenProfileMenu(true);
               }}
               style={{ marginLeft: isHorizontal ? -10 : 70, marginTop: -50 }}
               onClose={() => {
                  setOpenProfileMenu(false);
               }}
            >
               <ProfileMenu onClick={handleItemClick} />
            </ButtonBasedPopup>
         </Grid>

         <SaveDraftFlow
            isVisible={openModal}
            setShowModal={setOpenModal}
            onPublish={handlePublish}
            onDraft={handleDraft}
            link={link}
         />
         <SaveDraftRule
            isVisible={openModalRule}
            setShowModal={setOpenModalRule}
            onPublish={handlePublishRule}
            onDraft={handleDraftRule}
            link={link}
         />
      </Grid>
   );
};

export default React.memo(SideBarItems);

import { gql } from '@apollo/client';

export const CREATE_PERSON_GROUP_MUTATION = gql`
   mutation createPersonGroup(
      $name: String!
      $customer: ID!
      $conditions: [InputConditions]
      $shouldMatchAllConditions: Boolean
      $isDynamic: Boolean
   ) {
      createPersonGroup(
         data: {
            name: $name
            customer: $customer
            conditions: $conditions
            shouldMatchAllConditions: $shouldMatchAllConditions
            isDynamic: $isDynamic
         }
      ) {
         success
         code
         message
      }
   }
`;

export const UPDATE_PERSON_GROUP_MUTATION = gql`
   mutation updatePersonGroup(
      $_id: ID!
      $name: String
      $senderPlatform: String
      $senderPlatformType: String
      $conditions: [InputConditions]
      $shouldMatchAllConditions: Boolean
      $customer: ID!
   ) {
      updatePersonGroup(
         data: {
            _id: $_id
            name: $name
            senderPlatform: $senderPlatform
            senderPlatformType: $senderPlatformType
            conditions: $conditions
            shouldMatchAllConditions: $shouldMatchAllConditions
            customer: $customer
         }
      ) {
         success
         code
         message
      }
   }
`;

export const REMOVE_PERSON_GROUP_MUTATION = gql`
   mutation removePersonGroup($_id: ID!, $customer: ID!) {
      removePersonGroup(data: { _id: $_id, customer: $customer }) {
         success
         code
         message
      }
   }
`;

export const PUSH_PERSONS_TO_GROUP_MUTATION = gql`
   mutation pushPersonsToGroup($_id: ID!, $personIds: [ID]!, $customer: ID!) {
      pushPersonsToGroup(data: { _id: $_id, personIds: $personIds, customer: $customer }) {
         success
         code
         message
      }
   }
`;

export const REMOVE_PERSONS_FROM_GROUP_MUTATION = gql`
   mutation removePersonsFromGroup($_id: ID!, $personIds: [ID]!, $customer: ID!) {
      removePersonsFromGroup(data: { _id: $_id, personIds: $personIds, customer: $customer }) {
         success
         code
         message
      }
   }
`;

export const PUSH_PERSON_TO_GROUPS_MUTATION = gql`
   mutation pushPersonToGroups($personId: ID!, $groupIds: [ID]!, $customer: ID!) {
      pushPersonToGroups(data: { personId: $personId, groupIds: $groupIds, customer: $customer }) {
         success
         code
         message
      }
   }
`;

export const REMOVE_PERSON_FROM_GROUPS_MUTATION = gql`
   mutation removePersonFromGroups($personId: ID!, $groupIds: [ID]!, $customer: ID!) {
      removePersonFromGroups(data: { personId: $personId, groupIds: $groupIds, customer: $customer }) {
         success
         code
         message
      }
   }
`;

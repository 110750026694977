import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Divider, Paper, Typography } from '@material-ui/core';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import TextInput from '@components/TextInput';
import PhoneInputComp from '@components/PhoneInput';

import { getSocialMediaFromType } from '@helper/functions';
import moment from 'moment-timezone';
import { TIMEZONE } from '@helper/config';
import SearchSelect from 'react-select';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm } from 'react-hook-form';
import { useCountries } from '../../hooks/useCountries';
// const LEFT_PANEL_WIDTH = 320;

const useStyles = makeStyles((theme) => ({
   root: {
      width: '100%',
      padding: 20,
      paddingBottom: 5,
      borderRadius: 15,
      // width: 700,
      // height:400,
      height: 750,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      [theme.breakpoints.down('sm')]: {
         overflowY: 'scroll',
         height: '90vh',
      },
   },
   title: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.4rem',
   },
   countriesLabel: {
      fontSize: '1rem',
      color: 'rgb(138, 158, 181)',
   },
   itemContainer: {
      height: '100%',
      marginTop: 10,
   },
}));
const customStyles = {
   control: (base, { isDisabled }) => ({
      ...base,
      height: 40,
      minHeight: 40,
      fontSize: '0.9rem',
      width: '100%',
      backgroundColor: isDisabled ? '#eee' : 'transparent',
      border: isDisabled && '1px solid #b0b0b0',
   }),
   menuList: (base) => ({
      ...base,
      maxHeight: '200px',
   }),
};
const AddEditContact = ({ onSave, onCancel, mode, row }) => {
   const classes = useStyles();
   const [startEdit, setStartEdit] = useState(false);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [formValues, setFormValues] = useState({
      country: { value: '', label: 'Lütfen bir ülke seçin', states: [] },
      state: { value: '', label: 'Önce bir ülke seçmelisiniz' },
      name: null,
      phone: null,
      mail: null,
      address: null,
      city: null,
      postCode: null,
   });

   const {
      register,
      watch,
      formState: { errors, isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         mail: row.mail || '',
      },
   });
   const { ref, ...inputProps } = register('mail', {
      pattern: {
         value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
         message: t?.must_be_a_valid_email,
      },
   });

   useEffect(() => {
      setFormValues((prevState) => ({
         ...prevState,
         _id: row._id,
         country: {
            value: row.country || '',
            label: row.country || '',
            states: [],
         },
         state: { value: row.state || '', label: row.state || '' },
         name: row.name || '',
         phone: row.phone || '',
         address: row.address || '',
         postCode: row.postCode || '',
         city: row.city || '',
         type: row.type || '',
         createdAt: row.createdAt || '',
      }));
   }, [row]);

   const { countriesData } = useCountries();

   useEffect(() => {
      if (countriesData?.length > 0 && formValues.country?.states?.length < 1) {
         let selectedCountry = countriesData.filter((country) => formValues.country.value === country.country)[0];
         if (selectedCountry) {
            setFormValues((prevState) => ({
               ...prevState,
               country: {
                  value: selectedCountry.country,
                  label: selectedCountry.country,
                  states: selectedCountry.states,
               },
            }));
         }
      }
   }, [countriesData, formValues]);

   const handleValueChange = (e) => {
      const { name, value } = e.target;
      setFormValues((state) => ({ ...state, [name]: value }));
   };
   return (
      <Grid container item xs={12}>
         <Paper className={classes.root} elevation={3}>
            <Typography className={classes.title}>{mode === 'INFO' && t?.user_information}</Typography>
            <Divider style={{ width: '100%', marginTop: 5 }} />
            <Grid container direction='column' style={{ overflow: 'auto', marginTop: 30 }}>
               {/* Platform and Creation Date (Not Editable) */}
               <Grid container direction='row'>
                  <Grid item xs={12} md={6} style={{ padding: 5 }}>
                     <TextInput
                        label='Platform'
                        height={40}
                        defaultValue={getSocialMediaFromType(formValues.type) || ''}
                        labelColor={'#8A9EB5'}
                        editable={false}
                     />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: 5 }}>
                     <TextInput
                        label={t['page_contacts_typo_creation_date']}
                        defaultValue={moment(formValues.createdAt).tz(TIMEZONE).format('DD.MM.YYYY hh:mm') || ''}
                        labelColor={'#8A9EB5'}
                        height={40}
                        editable={false}
                     />
                  </Grid>
               </Grid>
               {/* Name and E-mail */}
               <Grid container direction='row' justifyContent='center'>
                  <Grid item xs={12} md={6} style={{ padding: 5 }}>
                     <TextInput
                        label={t['page_settings_users_name']}
                        editable={startEdit}
                        height={40}
                        name='name'
                        onChange={handleValueChange}
                        defaultValue={formValues.name || ''}
                        labelColor={'#8A9EB5'}
                     />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: 5 }}>
                     <TextInput
                        inputRef={ref}
                        variant='outlined'
                        {...inputProps}
                        error={!!errors.mail}
                        helperText={errors.mail?.message}
                        label='E-mail'
                        height={40}
                        editable={startEdit}
                        labelColor={'#8A9EB5'}
                     />
                  </Grid>
               </Grid>
               {/* Phone Input  */}
               <Grid item xs={12} style={{ padding: 5, marginTop: 5 }}>
                  <PhoneInputComp
                     label={t['page_account_input_profile_phone']}
                     editable={startEdit}
                     height={40}
                     width='100%'
                     name='phone'
                     value={formValues.phone}
                     onChange={(value) => setFormValues((state) => ({ ...state, phone: value }))}
                     labelColor={'#8A9EB3'}
                  />
               </Grid>
               <Grid container direction='row'>
                  <Grid container item xs={12} justifyContent='space-between' className={classes.itemContainer}>
                     <Grid item xs={6} style={{ padding: 5, maxHeight: '1000px' }}>
                        <Typography className={classes.countriesLabel}>
                           {t['page_chat_typo_profile_country']}
                        </Typography>
                        <SearchSelect
                           isDisabled={!startEdit}
                           value={formValues.country}
                           styles={customStyles}
                           onChange={(country) => {
                              setFormValues((prevState) => ({
                                 ...prevState,
                                 country,
                                 state: {
                                    value: country.states[0],
                                    label: country.states[0],
                                 },
                              }));
                           }}
                           options={countriesData?.map(({ country, states }) => ({
                              value: country,
                              label: country,
                              states,
                           }))}
                        />
                     </Grid>
                     <Grid item xs={6} style={{ padding: 5 }}>
                        <Typography className={classes.countriesLabel}>{t['page_chat_typo_profile_city']}</Typography>
                        <SearchSelect
                           isDisabled={!startEdit}
                           value={formValues.state}
                           styles={customStyles}
                           onChange={(state) => {
                              setFormValues((prevState) => ({ ...prevState, state }));
                           }}
                           options={formValues.country?.states?.map((state) => {
                              return { value: state, label: state };
                           })}
                        />
                     </Grid>
                  </Grid>
               </Grid>

               <Grid container direction='row' style={{ marginTop: 20 }}>
                  <Grid item xs={12} md={6} style={{ padding: 3 }}>
                     <TextInput
                        label={t['page_chat_typo_profile_neighborhood']}
                        editable={startEdit}
                        height={40}
                        name='city'
                        onChange={handleValueChange}
                        defaultValue={formValues.city || ''}
                        labelColor={'#8A9EB3'}
                     />
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: 3 }}>
                     <TextInput
                        label={t['page_contacts_typo_postal_code']}
                        editable={startEdit}
                        height={40}
                        name='postCode'
                        onChange={handleValueChange}
                        defaultValue={formValues.postCode || ''}
                        labelColor={'#8A9EB3'}
                     />
                  </Grid>
               </Grid>

               <Grid item xs={12}>
                  <TextInput
                     label={t['page_contacts_typo_city']}
                     editable={startEdit}
                     name='address'
                     defaultValue={formValues.address || ''}
                     onChange={handleValueChange}
                     isMultiLine
                     labelColor={'#8A9EB5'}
                     height={40}
                  />
               </Grid>
            </Grid>

            <Grid
               container
               direction='row'
               item
               justifyContent='space-between'
               style={{ padding: 20, paddingLeft: 0, paddingRight: 0 }}
            >
               <Grid item container xs={6} style={{ paddingRight: 5 }}>
                  <ButtonCancel
                     onClick={onCancel}
                     height={40}
                     width={128}
                     label={t['comp_button_cancel']}
                     fontWeight='bold'
                  />
               </Grid>
               <Grid item container xs={6} justifyContent='flex-end' style={{ paddingLeft: 5 }}>
                  <ButtonOK
                     onClick={() => {
                        const formData = { ...formValues, mail: watch('mail') };
                        startEdit ? onSave(formData) : setStartEdit(true);
                     }}
                     width={128}
                     height={40}
                     label={
                        mode === 'NEW'
                           ? t['comp_button_add']
                           : startEdit
                           ? t['comp_button_save']
                           : t['comp_button_edit']
                     }
                     fontWeight='bold'
                     disabled={startEdit && !isValid}
                  />
               </Grid>
            </Grid>
         </Paper>
      </Grid>
   );
};

export default AddEditContact;

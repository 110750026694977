import React, { FC, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import { ButtonBase, Checkbox, Typography } from '@material-ui/core';
import CollectPaymentDialog from '../CollectPaymentModal';
import { CollectPaymentModalType, NotificationChannels } from '../../../types';

const useStyles = makeStyles(() => ({
   child: {
      display: 'flex',
      flexDirection: 'column',
   },
   up: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: 20,
   },
   question: {
      marginBottom: 8,
   },
   down: {
      display: 'flex',
      flexDirection: 'column',
   },
   labelContainer: {
      marginBottom: 5,
   },
   label: {
      color: '#315276',
      lineHeight: 2,
   },
   checkBoxesContainer: {
      display: 'flex',
      flexWrap: 'wrap',
   },
   checkBoxAndLabel: {
      display: 'flex',
      alignItems: 'center',
      marginRight: 15,
   },
   checkBox: {
      padding: '0px !important',
      marginRight: 5,
   },
   text: {
      color: '#263548',
   },
}));

interface MarkWithStatusProps {
   isVisible: boolean;
   status: CollectPaymentModalType;
   submitButtonDisabled: boolean;
   onCancel: () => void;
   onSubmit: (data: NotificationChannels) => void;
}

const MarkWithStatus: FC<MarkWithStatusProps> = ({ isVisible, status, submitButtonDisabled, onCancel, onSubmit }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [channels, setChannels] = useState<NotificationChannels>({
      chatChecked: false,
      emailChecked: false,
      SMSChecked: false,
      whatsappChecked: false,
   });

   return (
      <CollectPaymentDialog
         isVisible={isVisible}
         submitButtonDisabled={submitButtonDisabled}
         actionType={status}
         onCancel={onCancel}
         onSubmit={() => onSubmit(channels)}
      >
         <div className={classes.child}>
            <div className={classes.up}>
               <div className={classes.question}>
                  <Typography className={classes.text}>
                     {t[`page_e_commerce_customer_cart_${status}_question`]}
                  </Typography>
               </div>
               <Typography className={classes.text}>
                  {t[`page_e_commerce_customer_cart_${status}_description`]}
               </Typography>
            </div>
            {/*<div className={classes.down}>*/}
            {/*   <div className={classes.labelContainer}>*/}
            {/*      <Typography className={classes.label}>*/}
            {/*         {t[`page_e_commerce_customer_cart_${status}_options_label`]}*/}
            {/*      </Typography>*/}
            {/*   </div>*/}
            {/*   <div className={classes.checkBoxesContainer}>*/}
            {/*      <ButtonBase*/}
            {/*         disableRipple*/}
            {/*         onClick={() => setChannels(prevState => ({...prevState, chatChecked: !prevState.chatChecked}))}*/}
            {/*         className={classes.checkBoxAndLabel}*/}
            {/*      >*/}
            {/*         <Checkbox*/}
            {/*            disableRipple*/}
            {/*            color="primary"*/}
            {/*            checked={channels.chatChecked}*/}
            {/*            className={classes.checkBox}*/}
            {/*         />*/}
            {/*         <Typography variant='body2' noWrap className={classes.label} >*/}
            {/*            {t['page_e_commerce_customer_cart_channel_option_chat']}*/}
            {/*         </Typography>*/}
            {/*      </ButtonBase>*/}
            {/*      <ButtonBase*/}
            {/*         disableRipple*/}
            {/*         onClick={() => setChannels(prevState => ({...prevState, emailChecked: !prevState.emailChecked}))}*/}
            {/*         className={classes.checkBoxAndLabel}*/}
            {/*      >*/}
            {/*         <Checkbox*/}
            {/*            disableRipple*/}
            {/*            color="primary"*/}
            {/*            checked={channels.emailChecked}*/}
            {/*            className={classes.checkBox}*/}
            {/*         />*/}
            {/*         <Typography variant='body2' noWrap className={classes.label} >*/}
            {/*            {t['page_e_commerce_customer_cart_channel_option_e_mail']}*/}
            {/*         </Typography>*/}
            {/*      </ButtonBase>*/}
            {/*      <ButtonBase*/}
            {/*         disableRipple*/}
            {/*         onClick={() => setChannels(prevState => ({...prevState, SMSChecked: !prevState.SMSChecked}))}*/}
            {/*         className={classes.checkBoxAndLabel}*/}
            {/*      >*/}
            {/*         <Checkbox*/}
            {/*            disableRipple*/}
            {/*            color="primary"*/}
            {/*            checked={channels.SMSChecked}*/}
            {/*            className={classes.checkBox}*/}
            {/*         />*/}
            {/*         <Typography variant='body2' noWrap className={classes.label} >*/}
            {/*            {t['page_e_commerce_customer_cart_channel_option_sms']}*/}
            {/*         </Typography>*/}
            {/*      </ButtonBase>*/}
            {/*      <ButtonBase*/}
            {/*         disableRipple*/}
            {/*         onClick={() => setChannels(prevState => ({...prevState, whatsappChecked: !prevState.whatsappChecked}))}*/}
            {/*         className={classes.checkBoxAndLabel}*/}
            {/*      >*/}
            {/*         <Checkbox*/}
            {/*            disableRipple*/}
            {/*            color="primary"*/}
            {/*            checked={channels.whatsappChecked}*/}
            {/*            className={classes.checkBox}*/}
            {/*         />*/}
            {/*         <Typography variant='body2' noWrap className={classes.label} >*/}
            {/*            {t['page_e_commerce_customer_cart_channel_option_WhatsApp']}*/}
            {/*         </Typography>*/}
            {/*      </ButtonBase>*/}
            {/*   </div>*/}
            {/*</div>*/}
         </div>
      </CollectPaymentDialog>
   );
};

export default MarkWithStatus;

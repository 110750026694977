
import React from 'react'
export default function OnlineCircle({ size = 20, style = {}, online = false }) {

    return (<span style={{
        background: online ? '#4caf50' : 'rgb(147 149 147)',
        borderRadius: '100%',
        width: size,
        height: size,
        display: 'inline-block',
        ...style
    }}></span>)
}
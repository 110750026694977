import { Switch, SwitchProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { MyTheme } from '@styles/config';
export type ColorsKey = 'blue' | 'orange' | 'red';

interface Props extends SwitchProps {
   colorKey?: ColorsKey;
}

const useStyles = makeStyles<MyTheme, Props>((theme) => ({
   root: {
      width: 48,
      height: 24,
      padding: 0,
      display: 'flex',
   },
   switchBase: {
      padding: 2,
      color: theme.palette.grey[500],
      '&$checked': {
         transform: 'translateX(24px)',
         color: theme.palette.common.white,
         '& + $track': {
            opacity: 1,
            // TODO - add colors to this component
            backgroundColor: '#157CFC',
            borderColor: '#157CFC',
         },
      },
   },
   thumb: {
      width: 20,
      height: 20,
      boxShadow: 'none',
   },
   track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 20,
      opacity: 1,
      backgroundColor: theme.palette.common.white,
   },
   checked: {},
}));

const CustomSwitch = (props: Props) => {
   const classes = useStyles(props);
   return (
      <Switch
         classes={{
            root: classes.root,
            thumb: classes.thumb,
            track: classes.track,
            switchBase: classes.switchBase,
            checked: classes.checked,
         }}
         {...props}
      />
   );
};

export default CustomSwitch;

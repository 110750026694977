import { useApolloClient, useMutation } from '@apollo/client';
import {
   CompanyIcon,
   LogoutIcon,
   PackagesIcon,
   PreferencesIcon,
   ProfileIcon,
} from '@assets/icons/SideBarIcons/profileMenuIcons';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ListItemIndicator from '@components/ListItemIndicator/listItemIndicator';
import Modal from '@components/Modal/modal';
import { LanguageContext } from '@helper/locale/langContext';
import { ButtonBase, Typography, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AllOutIcon from '@material-ui/icons/AllOut';
import { UPDATED_JOINED_CHAT_STATUS_MUTATION } from '@queries/Chat/Service/GraphQL/Chat/mutation';
import { SIGN_OUT } from '@store/actions/person';
import { UPDATE_PROFILE_STATUS } from '@store/actions/person.js';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UPDATE_SINGLE_CUSTOMER_PROFILE_MUTATION } from '../../queries/Account/Service/GraphQL/mutation';
import IOSSwitch from '../Settings/SettingSpace/LiveChat/SharedComponents/IOSSwitch';
import LogOutMessage from './logOutMessage';

const useStyles = makeStyles((theme) => ({
   itemTitle: {
      fontSize: '1rem',
      // fontWeight: 'bold',
      marginLeft: 10,
      color: '#27333E',
   },
   selected: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   notSelected: {
      color: '#9EAEC3',
   },
   container: {
      backgroundColor: null,
      color: '#9EAEC3',
   },
   containerSelected: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   logoutButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
   },
   icon: {
      width: 18,
      height: 18,
      fill: '#c1c1c1',
      [theme.breakpoints.down('xs')]: {
         width: 14,
         height: 14,
      },
   },
   LogoutIcon: {
      width: 20,
      height: 20,
   },
}));

export const CHECK_STATUS_ENUM = {
   JUST_LOGOUT: 'just_logout',
   LOGOUT_AND_LEAVE_ALL_ASSIGNED_CONVERSATIONS: 'logout_and_leave_all_assigned_conversations',
   LOGOUT_AND_CLOSE_ALL_ASSIGNED_CONVERSATIONS: 'logout_and_close_all_assigned_conversations',
};

const ProfileMenu = React.memo(({ onClick }) => {
   // mode could be HORIZONTAL or VERTICAL
   const classes = useStyles();
   const theme = useTheme();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const [showLogOutMessage, setShowLogoutMessage] = useState(false);
   const [personStatus, setPersonStatus] = useState({
      isRequested: false,
      status: person.status,
   });
   const dispatch = useDispatch();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const client = useApolloClient();

   const [updatedJoinedChatStatus, { loading: updatedJoinedChatStatusloading }] = useMutation(
      UPDATED_JOINED_CHAT_STATUS_MUTATION,
   );

   const [updateSingleCustomerProfileMutation] = useMutation(UPDATE_SINGLE_CUSTOMER_PROFILE_MUTATION);

   const handleStatusChange = (status) => {
      if (personStatus.isRequested) {
         return;
      }
      setPersonStatus({
         isRequested: true,
         status,
      });
      updateSingleCustomerProfileMutation({ variables: { status, _id: person._id } })
         .then(
            ({
               data: {
                  updateSingleCustomerProfile: { data },
               },
            }) => {
               if (data.status === 0) {
                  enqueueSnackbar(t?.success_person_status_offline, {
                     // send success message
                     variant: 'success',
                     autoHideDuration: 2000,
                  });
               }
               if (data.status === 2) {
                  enqueueSnackbar(t?.success_person_status_online, {
                     // send success message
                     variant: 'success',
                     autoHideDuration: 2000,
                  });
               }
               // change person state from redux store
               dispatch({ type: UPDATE_PROFILE_STATUS, payload: data.status });
               setPersonStatus({
                  status: data.status,
                  isRequested: false,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {t['general_message_button_close']}
                  </div>
               ),
            });
            setPersonStatus({
               isRequested: false,
               status: person.status,
            });
         });
   };

   const handleUpdatedJoinedChatStatus = async (checkboxStatus) => {
      return updatedJoinedChatStatus({
         variables: {
            me: person._id,
            status: checkboxStatus === CHECK_STATUS_ENUM.LOGOUT_AND_LEAVE_ALL_ASSIGNED_CONVERSATIONS ? 0 : 2,
         },
      })
         .then((data) => data.data?.updateJoinedChatsStatus?.data)
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               autoHideDuration: 3000,
            });
         });
   };

   const handleLogOut = async (checkboxStatus) => {
      if (checkboxStatus !== CHECK_STATUS_ENUM.JUST_LOGOUT) {
         const isUpdatedChat = await handleUpdatedJoinedChatStatus(checkboxStatus);
         if (!isUpdatedChat) {
            return;
         }
      }
      setShowLogoutMessage(false);
      // sign out
      dispatch({ type: SIGN_OUT, payload: {} });
      localStorage.clear();
      await client.resetStore();
      // reset app state
   };

   return (
      <Grid
         container
         direction={'column'}
         justifyContent='space-between'
         wrap='nowrap'
         style={{ width: 230, maxHeight: 370 }}
      >
         <Grid
            style={{
               display: 'flex',
               justifyContent: 'space-between',
               alignItems: 'center',
               padding: '5px 10px',
               borderBottom: '1px solid #E8E9ED',
            }}
         >
            <Typography style={{ fontWeight: 600, marginLeft: 6 }}>{t?.available}</Typography>
            <IOSSwitch
               checked={personStatus.status === 2 ? true : false}
               onChange={(e) => handleStatusChange(e.target.checked ? 2 : 0)}
            />
         </Grid>

         <Link to={'/account/profile'}>
            <ButtonBase onClick={onClick} style={{ width: '100%', textAlign: 'left', marginTop: 5 }}>
               <Grid container justifyContent='flex-start' alignItems='center' className={classes.container}>
                  <ListItemIndicator />
                  <ProfileIcon className={classes.icon} />
                  <Typography className={classes.itemTitle}>{t?.comp_sidebar_profile}</Typography>
               </Grid>
            </ButtonBase>
         </Link>
         <Link to={'/account/company'}>
            <ButtonBase onClick={onClick} style={{ width: '100%', textAlign: 'left', marginTop: 5 }}>
               <Grid container justifyContent='flex-start' alignItems='center' className={classes.container}>
                  <ListItemIndicator />
                  <CompanyIcon className={classes.icon} />
                  <Typography className={classes.itemTitle}>{t?.comp_sidebar_company}</Typography>
               </Grid>
            </ButtonBase>
         </Link>
         {person.type >= 1 && (
            <Link to={'/account/packages/my-package'}>
               <ButtonBase onClick={onClick} style={{ width: '100%', textAlign: 'left', marginTop: 5 }}>
                  <Grid container justifyContent='flex-start' alignItems='center' className={classes.container}>
                     <ListItemIndicator />
                     <PackagesIcon className={classes.icon} />
                     <Typography className={classes.itemTitle}>
                        {isSmallScreen ? t?.comp_sidebar_my_packages : t?.comp_sidebar_package_and_factors}
                     </Typography>
                  </Grid>
               </ButtonBase>
            </Link>
         )}
         {person.type >= 1 && isSmallScreen && (
            <Link to={'/account/packages/my-factors'}>
               <ButtonBase onClick={onClick} style={{ width: '100%', textAlign: 'left', marginTop: 5 }}>
                  <Grid container justifyContent='flex-start' alignItems='center' className={classes.container}>
                     <ListItemIndicator />
                     <PackagesIcon className={classes.icon} fill='#c1c1c1' />
                     <Typography className={classes.itemTitle}>{t?.comp_sidebar_my_factors}</Typography>
                  </Grid>
               </ButtonBase>
            </Link>
         )}
         {person.type >= 1 && isSmallScreen && (
            <Link to={'/account/packages/all-factors'}>
               <ButtonBase onClick={onClick} style={{ width: '100%', textAlign: 'left', marginTop: 5 }}>
                  <Grid container justifyContent='flex-start' alignItems='center' className={classes.container}>
                     <ListItemIndicator />
                     <AllOutIcon className={classes.icon} />
                     <Typography className={classes.itemTitle}>{t?.all_packages}</Typography>
                  </Grid>
               </ButtonBase>
            </Link>
         )}
         {person.type >= 1 && (
            <Link to={'/account/preferences'}>
               <ButtonBase onClick={onClick} style={{ width: '100%', textAlign: 'left', marginTop: 5 }}>
                  <Grid container justifyContent='flex-start' alignItems='center' className={classes.container}>
                     <ListItemIndicator />
                     <PreferencesIcon className={classes.icon} />
                     <Typography className={classes.itemTitle}>{t?.comp_sidebar_preferences}</Typography>
                  </Grid>
               </ButtonBase>
            </Link>
         )}
         <Grid justifyContent='flex-end' alignItems='center' container className={classes.logoutButtonContainer}>
            <ButtonCancel
               label={t['comp_sidebar_logout']}
               onClick={() => setShowLogoutMessage(true)}
               variant='contained'
               width={200}
               icon={<LogoutIcon className={classes.LogoutIcon} />}
            />
         </Grid>
         <Modal
            modalHeader={t?.comp_sidebar_logout}
            paperStyle={{ width: 450 }}
            open={showLogOutMessage}
            onClose={() => setShowLogoutMessage(false)}
         >
            <div>
               <LogOutMessage
                  updatedJoinedChatStatusloading={updatedJoinedChatStatusloading}
                  onAccept={handleLogOut}
                  onCancel={() => setShowLogoutMessage(false)}
               />
            </div>
         </Modal>
      </Grid>
   );
});

export default ProfileMenu;

import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../../../../fragments';

// subscriptions
export const UPDATED_CUSTOMER_SUBSCRIPTION = gql`
   ${CUSTOMER_FRAGMENT}
   subscription updatedCustomerSubscription($token: String!, $_id: ID) {
      updatedPersonCustomer(data: { token: $token, _id: $_id }) {
         data {
            ...CustomerFields
         }
         success
         code
         message
      }
   }
`;

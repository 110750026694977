import { useMutation, useQuery, useSubscription } from '@apollo/client';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
   CREATE_PLATFORM_MUTATION,
   DELETE_PLATFORM_MUTATION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/mutation';
import { GET_PLATFORMS_QUERY } from '../../../../queries/Settings/Service/GraphQL/Platform/query';
import {
   CREATED_PLATFORM_SUBSCRIPTION,
   DELETED_PLATFORM_SUBSCRIPTION,
   UPDATED_PLATFORM_SUBSCRIPTION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/subscription';

import { PLATFORM_TYPE } from '../../../../queries/Settings/Service/GraphQL/Platform/types';
import { GET_TEAMS_QUERY } from '../../../../queries/Teams/query';
import EmailContent from './emailContent';
import ModalContent from './modalContent';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },

   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   title: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },

   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const Email = ({ person, token }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [newEmail, setNewEmail] = useState(false);

   const initialState = {
      show: false,
   };
   const [modalConfig, setModalConfig] = useState(initialState);

   const handleNewModal = () => {
      setModalConfig({
         show: true,
         mode: 'NEW',
      });
   };
   const handleCloseModal = () => {
      setModalConfig(initialState);
   };

   const [createPlatformMutation, { loading: createEmailLoading }] =
      useMutation(CREATE_PLATFORM_MUTATION);

   const [deleteEmailMutation, { loading: deleteEmailLoading }] =
      useMutation(DELETE_PLATFORM_MUTATION);

   const { loading: emailLoading, data: emailData } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.EMAIL, customer: person.customer._id },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const { loading: teamsLoading, data: teamsData } = useQuery(GET_TEAMS_QUERY, {
      variables: { customer: person.customer._id, pageSize: 100, page: 1 },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   useSubscription(CREATED_PLATFORM_SUBSCRIPTION, {
      variables: {
         token,
         customer: person ? person.customer._id : '',
         type: PLATFORM_TYPE.EMAIL,
      },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               createdPlatform: {
                  data: { metaData: data },
               },
            },
         },
      }) => {
         let { getPlatforms } = client.cache.readQuery({
            query: GET_PLATFORMS_QUERY,
            variables: {
               type: PLATFORM_TYPE.EMAIL,
               customer: person ? person.customer._id : '',
            },
         });
         client.cache.writeQuery({
            query: GET_PLATFORMS_QUERY,
            variables: {
               type: PLATFORM_TYPE.EMAIL,
               customer: person ? person.customer._id : '',
            },
            data: {
               getPlatforms: {
                  ...getPlatforms,
                  data: [...getPlatforms.data, data?.pages],
               },
            },
         });
      },
   });

   useSubscription(UPDATED_PLATFORM_SUBSCRIPTION, {
      variables: {
         token,
         customer: person ? person.customer._id : '',
         type: PLATFORM_TYPE.EMAIL,
      },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedPlatform: {
                  data: {
                     metaData: { pages: data },
                  },
               },
            },
         },
      }) => {
         let { getPlatforms } = await client.cache.readQuery({
            query: GET_PLATFORMS_QUERY,
            variables: {
               customer: person ? person.customer._id : '',
               type: PLATFORM_TYPE.EMAIL,
            },
         });
         const newList = getPlatforms.data.map((item) =>
            item._id.toString() === data._id.toString() ? data : item,
         );

         client.cache.writeQuery({
            query: GET_PLATFORMS_QUERY,
            variables: {
               customer: person ? person.customer._id : '',
               type: PLATFORM_TYPE.EMAIL,
            },
            data: {
               getPlatforms: {
                  ...getPlatforms,
                  data: newList,
               },
            },
         });
      },
   });

   useSubscription(DELETED_PLATFORM_SUBSCRIPTION, {
      variables: {
         token,
         customer: person ? person.customer._id : '',
         type: PLATFORM_TYPE.EMAIL,
      },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               deletedPlatform: {
                  data: { metaData: data },
               },
            },
         },
      }) => {
         let { getPlatforms } = await client.cache.readQuery({
            query: GET_PLATFORMS_QUERY,
            variables: {
               customer: person ? person.customer._id : '',
               type: PLATFORM_TYPE.EMAIL,
            },
         });

         let index = getPlatforms.data.findIndex(
            (item) => item._id.toString() === data._id.toString(),
         );
         if (index > -1) {
            const newList = await getPlatforms.data.filter(
               (item) => item._id.toString() !== data._id.toString(),
            );
            client.cache.writeQuery({
               query: GET_PLATFORMS_QUERY,
               variables: {
                  customer: person ? person.customer._id : '',
                  type: PLATFORM_TYPE.EMAIL,
               },
               data: {
                  getPlatforms: {
                     ...getPlatforms,
                     data: newList,
                  },
               },
            });
         }
      },
   });

   // add data on submit
   const addEmailSubmit = async (formData) => {
      // run create mutation
      createPlatformMutation({
         variables: {
            type: PLATFORM_TYPE.EMAIL,
            metaData: {
               senderName: formData.name,
               customer: person.customer._id,
               senderEmailAddress: formData.email,
               teams: formData.teams,
            },
         },
      })
         .then(
            ({
               data: {
                  createPlatform: { data },
               },
            }) => {
               setNewEmail(data.metaData.pages);
               setModalConfig({ mode: 'EMAILCOMPLETED', open: true });
               enqueueSnackbar(
                  `${data.metaData.pages.senderName} ${t['general_information_added_successfully']}`,
                  {
                     // send success message
                     variant: 'success',
                     autoHideDuration: 3000,
                  },
               );
            },
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const handleDeleteEmail = async (item, deleteType) => {
      // await all results
      await deleteEmailMutation({
         variables: {
            type: PLATFORM_TYPE.EMAIL,
            metaData: {
               _id: item,
               deleteType,
            },
         },
      }) // run to delete mutation
         .then(
            ({
               data: {
                  deletePlatform: {
                     data: { metaData: data },
                  },
               },
            }) => {
               // success event
               handleCloseModal();
               enqueueSnackbar(
                  `${data.senderName} ${t['general_information_deleted_successfully']}`,
                  {
                     // send success message
                     variant: 'success',
                     autoHideDuration: 3000,
                  },
               );
            },
         )
         .catch((error) => {
            // error event
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const isLoading = teamsLoading || createEmailLoading || deleteEmailLoading || emailLoading;

   if (isLoading) {
      return <div className='loading' />;
   }

   return (
      <Grid container justifyContent='center' alignItems='center'>
         <Fragment>
            {emailData && emailData.getPlatforms.data.length > 0 ? (
               <EmailContent
                  emailData={emailData.getPlatforms.data}
                  person={person}
                  setModalConfig={setModalConfig}
                  handleDeleteEmail={handleDeleteEmail}
               />
            ) : (
               <>
                  <Grid
                     container
                     justifyContent='space-between'
                     alignItems='center'
                     className={classes.titleContainer}
                  >
                     <div style={{ display: 'flex' }}>
                        <Link to='/settings/channels-and-integrations' style={{ marginTop: 5 }}>
                           <ArrowBackIcon color='disabled' />
                        </Link>
                        <Typography
                           style={{
                              marginLeft: 10,
                              fontSize: '1.1rem',
                              fontWeight: 'bold',
                              lineHeight: '35px',
                           }}
                        >
                           Email
                        </Typography>
                     </div>
                  </Grid>

                  <div
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '1.5rem',
                        alignItems: 'center',
                        marginTop: '10rem',
                        fontFamily: 'Helvetica',
                     }}
                  >
                     <Typography className={classes.title}>
                        {t['page_settings_connect_email_account']}
                     </Typography>
                     <Typography>{t['page_settings_connect_email']}</Typography>
                     <ButtonOK
                        label={t?.page_settings_modal_email}
                        className={classes.modalButtons}
                        style={{
                           color: 'white',
                           backgroundColor: '#0060df',
                           width: '40%',
                           margin: '0 auto',
                        }}
                        onClick={handleNewModal}
                     />
                  </div>
               </>
            )}
            <ModalContent
               show={modalConfig.show}
               mode={modalConfig.mode}
               setModalConfig={setModalConfig}
               newEmail={newEmail}
               emailData={emailData}
               onA
               onClose={handleCloseModal}
               addEmailSubmit={addEmailSubmit}
               teamsData={teamsData?.getTeamList?.data?.docs}
            />
         </Fragment>
      </Grid>
   );
};

export default Email;

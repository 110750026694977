import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import DeletePlatformModal from '@components/Modal/DeletePlatformModal';
import Modal from '@components/Modal/modal';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import { LanguageContext } from '@helper/locale/langContext';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ActionIconButton from '@modules/Settings/SettingSpace/ActionIconButton';
import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      //   backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   title: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },
   numberTitle: {
      fontSize: '1rem',
      fontWeight: 'medium',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   button: {
      paddingTop: 10,
      width: 144,
      height: 30,
      color: 'white',
      borderRadius: 5,
      marginRight: 10,
   },
   buttonApply: {
      backgroundColor: '#487DBF',
      '&:hover': {
         backgroundColor: '#487DBF',
      },
   },
   ButtonContainer: {
      paddingLeft: 5,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const StyledTableCell = withStyles((theme) => ({
   head: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
   },
   body: {
      fontSize: 14,
   },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
   root: {
      backgroundColor: 'transparent',
   },
}))(TableRow);

const EmailContent = ({ person, token, emailData, setModalConfig, handleDeleteEmail }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const initialState = {
      show: false,
      mode: null /* EDIT | NEW */,
      row: { _id: '', number: null, dialogStatus: false, qrCode: null, status: '', error: '' },
   };

   const [confirmQrCancel, setConfirmQrCancel] = useState(-1);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [selectedDeleteItemId, setSelectedDeletedItemId] = useState(null);

   const handleCreateNewRow = () => {
      setModalConfig({
         show: true,
         mode: 'NEW',
      });
   };

   return (
      <Fragment>
         <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
            <div style={{ display: 'flex' }}>
               <Link to='/settings/channels-and-integrations' style={{ marginTop: 5 }}>
                  <ArrowBackIcon color='disabled' />
               </Link>
               <Typography
                  style={{
                     marginLeft: 10,
                     fontSize: '1.1rem',
                     fontWeight: 'bold',
                     lineHeight: '35px',
                  }}
               >
                  Email
               </Typography>
            </div>
            <Grid className={classes.ButtonContainer}>
               <Typography
                  style={{
                     marginRight: 10,
                     color: 'initial',
                  }}
               >
                  {t?.teams_limit} {person.customer.usageEmailCount} / {person.customer.package.emailCount}
               </Typography>
               <ButtonOK
                  onClick={handleCreateNewRow}
                  disabled={emailData.length >= person.customer.package.emailCount}
                  width={200}
                  height={40}
                  fontSize='1.1rem'
                  label={t.page_settings_button_add_new}
               />
            </Grid>
         </Grid>
         <Grid style={{ width: '100%' }}>
            <TableContainer style={{ padding: 10 }}>
               <Table className={classes.table} aria-label='customized table'>
                  <TableHead>
                     <TableRow>
                        <StyledTableCell style={{ width: 300 }}>
                           {t['page_settings_typo_platforms_add_sender_name']}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 500 }}>
                           {t['page_settings_typo_platforms_add_email']}
                        </StyledTableCell>
                        <StyledTableCell style={{ width: 100 }}>{t['page_settings_users_teams']}</StyledTableCell>
                        <StyledTableCell align='right'>{t['page_settings_process']} </StyledTableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {Array.from(emailData)
                        .reverse()
                        .map((email) => (
                           <StyledTableRow>
                              <StyledTableCell component='th' scope='row'>
                                 <div
                                    style={{
                                       display: 'flex',
                                       flexDirection: 'row',
                                       flexWrap: 'wrap',
                                       gap: '0.5rem',
                                    }}
                                 >
                                    <Typography>{email.senderName}</Typography>
                                 </div>
                              </StyledTableCell>
                              <StyledTableCell component='th' scope='row'>
                                 <Typography style={{ color: 'initial' }}>
                                    {email.senderEmailAddress && `${email.senderEmailAddress} `}
                                    <span
                                       style={{
                                          color: email.domain
                                             ? email.domain.valid === true
                                                ? '#00C853'
                                                : '#D6802B'
                                             : 'initial',
                                       }}
                                    >
                                       {email.domain
                                          ? email.domain.valid === true
                                             ? `(${t['page_settings_email_verified']})`
                                             : `(${t['page_settings_email_unverified']})`
                                          : email.forwardingEmailAddress}
                                    </span>
                                 </Typography>
                              </StyledTableCell>
                              <StyledTableCell component='th' scope='row'>
                                 <Typography
                                    style={{
                                       color: email?.teams?.length > 0 ? 'initial' : 'red',
                                    }}
                                 >
                                    {email?.teams?.length > 0
                                       ? email?.teams?.length + ' ' + t?.teams_with_number
                                       : t.no_teams}
                                 </Typography>
                              </StyledTableCell>
                              <StyledTableCell align='right' style={{ minWidth: 250 }}>
                                 <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                                    <Link to={`/settings/platforms/email/account/${email._id}`}>
                                       <ActionIconButton actionType={'EDIT'} />
                                    </Link>
                                    <ActionIconButton
                                       onClick={() => {
                                          setShowDeleteModal(true);
                                          setSelectedDeletedItemId(email?._id);
                                       }}
                                       tooltip={t?.comp_button_delete}
                                       actionType={'DELETE'}
                                    />
                                 </Grid>
                              </StyledTableCell>
                           </StyledTableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </Grid>

         <Modal
            paperStyle={{ width: 450 }}
            onClose={() => setConfirmQrCancel(-1)}
            open={confirmQrCancel >= 0}
            modalHeader={t?.general_message_are_your_sure}
         >
            <ModalLargeText style={{ textAlign: 'center', marginBlock: 40 }}>
               {t?.whatsapp_web_qr_qode_modal_warning}
            </ModalLargeText>
            <ModalButtonField>
               <ButtonCancel
                  label={t?.general_message_no}
                  onClick={() => setConfirmQrCancel(-1)}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
               <ButtonOK
                  label={t?.general_message_yes}
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#0060df',
                  }}
               />
            </ModalButtonField>
         </Modal>

         <DeletePlatformModal
            isEmailDelete={true}
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            selectedDeleteItemId={selectedDeleteItemId}
            onComplate={handleDeleteEmail}
         />
      </Fragment>
   );
};

export default EmailContent;

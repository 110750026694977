import { TelegramIcon, WhatsappIcon } from '@assets/icons/SettingsIcons/settingsIcons';
import { LanguageContext } from '@helper/locale/langContext';
import { CircularProgress, Grid, IconButton, ListItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft, ChevronRight, Folder as FolderIcon } from '@material-ui/icons';
import moment from 'moment';
import React, { useCallback, useContext } from 'react';
import { useSelector } from 'react-redux';
import { BulkSharingStatusEnum } from '../types';
import { FilterType, PageDataType } from './folderHolder';
import { ActiveTab } from './rightBar';

const useStyles = makeStyles((theme: any) => ({
   container: {
      width: '100%',
      height: '100%',
      backgroundColor: '#ffffff',
   },
   tab: {
      width: '88%',
      textAlign: 'center',
      backgroundColor: '#137cfc',
      color: '#ffff',
      borderRadius: '7px',
      userSelect: 'none',
      cursor: 'pointer',
   },
   folderLine: {
      display: 'flex',
      width: '100%',
      height: 35,
      border: '1px solid #efefef',
      borderRadius: '7px',
      marginBottom: '7px',
      alignItems: 'initial',
      justifyContent: 'space-between',
   },
   iconHolder: {
      width: '35px',
      height: '35px',
      borderRadius: '7px',
      backgroundColor: '#efefef',
      padding: '5px',
      color: '#989898',
      fontSize: '20px',
   },
   titleHolder: {
      textAlign: 'start',
      padding: '3px',
      marginLeft: '10px',
      display: 'flex',
      width: 270,
   },
   removeHolder: {
      marginLeft: 'auto',
   },
   listIconLoaded: {
      width: 25,
      height: 25,
      display: 'block',
      marginLeft: 'auto',
   },
   listIcon: {
      width: 25,
      height: 25,
      display: 'block',
      marginLeft: 'auto',
      position: 'absolute',
   },
   listIconWp: {
      width: 25,
      height: 25,
      display: 'block',
      marginLeft: 'auto',
      position: 'absolute',
      '& path': {
         fill: '#989898',
      },
   },
}));

interface FolderLineProps {
   title: string;
   dontShowDate: boolean;
   wpSent: boolean;
   wpSending: boolean;
   telegramSent: boolean;
   telegramSending: boolean;
   telegramFailed: boolean;
   whatsAppFailed: boolean;
}

const LoaderGenerator = ({
   wpSent,
   wpSending,
   telegramSent,
   telegramSending,
   telegramFailed,
   whatsAppFailed,
   date,
}: {
   wpSent: boolean;
   wpSending: boolean;
   telegramSent: boolean;
   telegramSending: boolean;
   telegramFailed: boolean;
   whatsAppFailed: boolean;
   date: string | null;
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   return (
      <div style={{ display: 'flex' }}>
         {wpSending && (
            <div style={{ padding: 5 }}>
               {/* <WhatsappIcon className={classes.listIcon} /> */}
               <WhatsappIcon className={wpSent && !whatsAppFailed ? classes.listIconLoaded : classes.listIconWp} />
               {!wpSent && <CircularProgress style={{ color: 'white' }} size={25} color='inherit' />}
            </div>
         )}
         {telegramSending && (
            <div style={{ padding: 5 }}>
               <TelegramIcon
                  className={telegramSent && !telegramFailed ? classes.listIconLoaded : classes.listIcon}
                  selected={telegramSent && !telegramFailed}
               />
               {!telegramSent && <CircularProgress style={{ color: 'white' }} size={25} color='inherit' />}
            </div>
         )}
         {date && (telegramFailed || whatsAppFailed) && (
            <Typography
               style={{
                  paddingTop: 4,
                  paddingRight: 6,
               }}
               variant='subtitle1'
               color='error'
            >
               {[telegramFailed ? 'Telegram' : null, whatsAppFailed ? 'WhatsApp' : null].filter((x) => !!x).join(',') +
                  ` ${t?.campaign_status_4}`}
            </Typography>
         )}
         {date && (wpSending ? wpSent : true) && (telegramSending ? telegramSent : true) && (
            <Typography
               style={{
                  paddingTop: 4,
                  paddingRight: 6,
               }}
               variant='subtitle1'
               color='textSecondary'
            >
               {moment(date, 'x').format('YYYY-MM-DD HH:mm')}
            </Typography>
         )}
      </div>
   );
};

const FolderLine = ({ title, dontShowDate, ...loaderProps }: FolderLineProps) => {
   const classes = useStyles();
   const name = title.includes('*_*') ? title.split('*_*').slice(0, -1) : title;
   const date = title.includes('*_*') ? (title.split('*_*').at(-1) as string) : '';

   return (
      <div className={classes.folderLine}>
         <div style={{ display: 'flex' }}>
            <div className={classes.iconHolder}>
               <FolderIcon style={{ width: 25, height: 25 }} />
            </div>
            <div className={classes.titleHolder}>
               <Typography noWrap variant='subtitle1' color='textSecondary'>
                  {name}
               </Typography>
            </div>
         </div>
         <div>
            <LoaderGenerator {...{ ...loaderProps, date: dontShowDate ? null : date }} />
         </div>
      </div>
   );
};

export interface FolderHistoryListerProps {
   activeTab: ActiveTab;
   loading: boolean;
   setFilter: (filter: FilterType) => void,
   filter: FilterType,
   pageData: PageDataType,
   handlePreviousPage: () => void,
   handleNextPage: () => void,
}

const FolderHistoryLister = ({ activeTab, loading, pageData, setFilter, filter, handlePreviousPage, handleNextPage }: FolderHistoryListerProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector((reducer: any) => reducer.personReducer.person);

   const disabledStyle = {
      backgroundColor: '#efefef',
      color: '#989898',
      cursor: 'not-allowed',
   };

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   }, []);

   return (
      <div style={{ padding: '3px', height: '100%' }}>
         <div style={{ padding: '3px', overflowX: 'hidden', overflowY: 'auto', height: '80%' }}>
            {loading && <Loader />}
            {pageData?.data?.map((folder) => {
               return (
                  <ListItem key={folder.folderLink} style={{ padding: 0, display: 'block' }}>
                     <FolderLine
                        title={folder.folderName}
                        dontShowDate={activeTab == ActiveTab.SendingFolders}
                        wpSending={activeTab == ActiveTab.SendingFolders ? !!folder.whatsAppStatus : false}
                        wpSent={folder.whatsAppStatus === BulkSharingStatusEnum.SENT}
                        telegramSending={activeTab == ActiveTab.SendingFolders ? !!folder.telegramStatus : false}
                        telegramSent={folder.telegramStatus === BulkSharingStatusEnum.SENT}
                        telegramFailed={
                           folder?.telegramStatus == BulkSharingStatusEnum.FAILED || false
                        }
                        whatsAppFailed={
                           folder?.whatsAppStatus == BulkSharingStatusEnum.FAILED || false
                        }
                     />
                  </ListItem>
               );
            })}
         </div>
         <div style={{ height: 55, display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
            <p style={{ marginRight: 10 }}>{`${filter.page}-${filter.pageSize}`}</p>
            <IconButton
               onClick={handlePreviousPage}
               disabled={filter.page == 1 || loading}
               style={{ padding: 5 }}
            >
               <ChevronLeft
                  style={{
                     width: 25,
                     height: 25,
                     color: filter.page == 1 || loading ? '#cbcbcb' : '#484848',
                  }}
               />
            </IconButton>
            <IconButton
               onClick={handleNextPage}
               disabled={!pageData.hasMore || loading}
               style={{ padding: 5 }}
            >
               <ChevronRight
                  style={{
                     width: 25,
                     height: 25,
                     color: !pageData.hasMore || loading ? '#cbcbcb' : '#484848',
                  }}
               />
            </IconButton>
         </div>
      </div>
   );
};

export default FolderHistoryLister;

import React, {FC, useContext} from 'react';
import { Grid, Typography } from '@material-ui/core';
import FilePlayer from '../FilePlayer';
import Lottie from 'react-lottie';
import DropAnimation from './drop.json';
import { Fragment } from 'react';
import {LanguageContext} from "@helper/locale/langContext";

const defaultOptions = {
   loop: true,
   autoplay: true,
   animationData: DropAnimation,
   rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
   },
};

interface PreUploadedFile {
   path: string;
   name: string;
   type: string;
}

interface FileUploadPreviewProps {
   preparing?: boolean | undefined;
   files: (File | PreUploadedFile)[];
   width?: number;
   sthIsDropping?: boolean;
   onRemoveFile: (fileIndex: number) => void;
   maxFileSize?: number;
   onCancel?: () => void;
}

// types for the child components
export interface FileViewerProps {
   url?: string;
   name?: string;
   isLoading?: boolean;
}

// with props customize paper width, height or image width, height
const FileUploadPreview: FC<FileUploadPreviewProps> = ({
   preparing,
   files,
   width,
   sthIsDropping,
   onRemoveFile,
   onCancel,
   maxFileSize,
   ...props
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const _files = maxFileSize ? (files.length > maxFileSize ? files.slice(0, maxFileSize) : files) : files;
   return (
      <Grid>
         {preparing && (
           <Grid container justifyContent='flex-start' alignItems='center'>
              <Typography style={{ fontWeight: 'bold', paddingTop: 5 }}>
                 {t?.page_chat_input_preparing_to_upload}
              </Typography>
           </Grid>
           )}
         {sthIsDropping && (
            <Grid>
               <Lottie options={defaultOptions} height={128} width={64} />
               <Typography style={{ fontWeight: 'bold', color: 'red', paddingBottom: 5 }}>
                  {t?.page_chat_input_drop_files}
               </Typography>
            </Grid>
         )}
         {_files.length > 0 && (
            <Fragment>
               {
                  <Grid container justifyContent='flex-start' alignItems='center'>
                     {_files.map((file, index) => {
                        return (
                           <Grid key={file.name}>
                              {/* @ts-ignore */}
                              <FilePlayer
                                 type={file.type}
                                 // showPreview={(width && width > 600) || !width}
                                 showPreview
                                 url={(file as PreUploadedFile)?.path ? (file as PreUploadedFile).path : URL.createObjectURL(file as File)}
                                 name={file.name}
                                 onRemove={() => onRemoveFile(index)}
                                 {...props}
                              />
                           </Grid>
                        );
                     })}
                  </Grid>
               }
            </Fragment>
         )}
         {maxFileSize && files.length > maxFileSize && (
            <Typography style={{ color: 'red', fontSize: '0.85rem', padding: 5 }}>
               {t?.page_chat_input_max_file_count_warning}
            </Typography>
         )}
      </Grid>
   );
};

export default FileUploadPreview;

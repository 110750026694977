// import Item from '../SideBar/item';
import ButtonBasedPopup from '@components/ButtonBasedPopup';
import FreeTrial from '@components/FreeTrial';
import LangSelectBox from '@components/LangSelectBox';
import { responsiveMeasure } from '@helper/config';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Hidden } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import HomeIcon from '@material-ui/icons/Home';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@material-ui/icons/Tune';
import { SET_ACTIVE_CHAT } from '@store/actions/chat';
import { TOGGLE_QUESTION_DRAWER_PROFILE } from '@store/actions/ui';
import clsx from 'clsx';
import React, { Fragment, useCallback, useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useWindowResize from '../../hooks/useWindowResize';
import BaseNav from './BaseNav';
import ChatNavbarElements from './ChatNavbarElements';
import Notifications from './Notifications/notifications';
import NotificationsButton from './Notifications/notificationsButton';
import useNotifications from './Notifications/useNotifications';
import QuestionNavBarElements from './QuestionsNavBarElements';

import { ActiveVisitorIcon, CampaignIcon } from '@assets/icons/SideBarIcons/sideBarIcons';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'flex',
   },
   toolbar: {
      paddingRight: 24, // keep right padding when drawer closed
   },
   toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
   },
   appBar: (props) => ({
      marginLeft: props.sideBarWidth,
      backgroundColor: theme.chatApp.workspace.navBar.backgroundColor,
      width: `calc(100% - ${props.sideBarWidth}px)`,
      height: 60,
      boxShadow: 'none',
      borderBottom: 'solid 1px lightgrey',
      zIndex: 999,
      transition: theme.transitions.create(['margin'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.down('sm')]: {
         width: `100%`,
      },
      [theme.breakpoints.up('md')]: {
         width: `calc(100% - ${props.sideBarWidth + 40}px`,
      },
   }),
   menuToggle: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create(['transform'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.standard,
      }),
   },
   menuToggleOpen: {
      transform: 'rotate(180deg)',
      transition: theme.transitions.create(['transform'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.standard,
      }),
   },
   title: {
      flexGrow: 1,
      color: theme.chatApp.workspace.navBar.titleColor,
   },
}));

const NavBar = (props) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles(props);
   const dispatch = useDispatch();
   const { toggleProfile } = props;
   const location = useLocation();
   const history = useHistory();
   const { notificationsData } = useNotifications(true);
   const [openNotifications, setOpenNotifications] = useState(false);
   const { width } = useWindowResize();

   const handleChangeOpenNotification = useCallback(
      () => setOpenNotifications(!openNotifications),
      [openNotifications],
   );

   return (
      <AppBar position='absolute' className={clsx(classes.appBar)}>
         <Toolbar className={classes.toolbar}>
            {width < responsiveMeasure.mobile.width && location.pathname.includes('/chat/') && (
               <Grid item xs={2}>
                  <IconButton
                     onClick={() => {
                        history.push('/chat');
                        toggleProfile('close');
                        dispatch({
                           type: SET_ACTIVE_CHAT,
                           payload: { activeChat: null },
                        });
                     }}
                  >
                     <ArrowBackIcon />
                  </IconButton>
               </Grid>
            )}
            {width < 625 && location.pathname.includes('/questions/') && (
               <Grid xs={2} item>
                  <IconButton
                     onClick={() => {
                        history.push('/questions');
                        dispatch({
                           type: TOGGLE_QUESTION_DRAWER_PROFILE,
                           payload: false,
                        });
                     }}
                  >
                     <ArrowBackIcon />
                  </IconButton>
               </Grid>
            )}
            <Grid container justifyContent='space-between' alignItems='center'>
               <Grid item style={{ flexGrow: 1 }} xs={12}>
                  {location.pathname === '/' ? <BaseNav icon={<HomeIcon />} title='Dashboard' /> : null}
                  {location.pathname.includes('/chat/') ? <ChatNavbarElements toggleProfile={toggleProfile} /> : null}
                  {location.pathname.includes('/campaigns') ? (
                     <BaseNav
                        icon={
                           <CampaignIcon
                              style={{
                                 width: 24,
                                 height: 24,
                                 color: '#385273',
                                 marginBottom: 5,
                              }}
                           />
                        }
                        title={t['comp_navbar_campaign_title']}
                     />
                  ) : null}
                  {location.pathname.includes('/bulk-messaging') ? (
                     <BaseNav
                        icon={
                           <CampaignIcon
                              style={{
                                 width: 24,
                                 height: 24,
                                 color: '#385273',
                                 marginBottom: 5,
                              }}
                           />
                        }
                        title={t?.bulk_messaging_title}
                     />
                  ) : null}
                  {location.pathname.includes('/questions/') ? (
                     <QuestionNavBarElements toggleProfile={toggleProfile} />
                  ) : null}
                  {location.pathname === '/contacts' ? (
                     <BaseNav
                        icon={<PermContactCalendarIcon fontSize='default' />}
                        title={t['comp_navbar_contact_title']}
                     />
                  ) : null}
                  {location.pathname.includes('/statistics') ? (
                     <BaseNav icon={<EqualizerIcon />} title={t['page_statistics_title']} />
                  ) : null}
                  {location.pathname.includes('/active-visitors') ? (
                     <BaseNav icon={<ActiveVisitorIcon />} title={t['active-visitors']} />
                  ) : null}
                  {location.pathname.includes('/settings') ? (
                     <BaseNav icon={<SettingsIcon />} title={t['comp_navbar_settings_title']} />
                  ) : null}
                  {location.pathname.includes('/account') ? (
                     <BaseNav icon={<TuneIcon />} title={t['comp_navbar_account_settings_title']} />
                  ) : null}
               </Grid>
            </Grid>
            {
               <Fragment>
                  <Hidden smDown>
                     {/* Free Trial */}
                     {!(location.pathname.includes('/chat') || location.pathname.includes('/questions')) && (
                        <FreeTrial />
                     )}
                     {/* Navbar Notifications  */}
                     <Grid style={{ marginRight: 20 }}>
                        <ButtonBasedPopup
                           button={<NotificationsButton notificationCount={notificationsData?.unReadCount} />}
                           open={openNotifications}
                           openRightAligned
                           style={{ marginLeft: -140, marginTop: 20 }}
                           onClose={handleChangeOpenNotification}
                           onOpen={handleChangeOpenNotification}
                        >
                           <Notifications onClickRequest={handleChangeOpenNotification} />
                        </ButtonBasedPopup>
                     </Grid>
                     {/* Language Select Box On Navbar  */}
                     <Grid>
                        <LangSelectBox />
                     </Grid>

                     {/* Navbar Profile Component changed location */}
                  </Hidden>
               </Fragment>
            }
         </Toolbar>
      </AppBar>
   );
};
export default React.memo(NavBar);

import React, { FC, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, LinearProgress } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import ProductListItem from './ProductListItem';
import { useForm } from 'react-hook-form';
import Input from '../../../../../../../components/InputField';
import ButtonOK from '../../../../../../../components/ButtonOK/buttonOk';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CircularProgress } from '@material-ui/core';
import {
   GET_E_COMMERCE_PRODUCTS_QUERY,
   GET_E_COMMERCE_VARIANTS_QUERY,
} from '@queries/Settings/Service/GraphQL/ECommercePlatform/query';
import useWindowResize from '@hooks/useWindowResize';
import { useSnackbar } from 'notistack';
import { useLazyQuery, useMutation, useQuery, useSubscription } from '@apollo/client';
import useBoolean from '@hooks/useBoolean';
import {
   CREATED_E_COMMERCE_PRODUCTS_SUBSCRIPTION,
   DELETED_E_COMMERCE_PRODUCTS_SUBSCRIPTION,
   UPDATED_E_COMMERCE_PRODUCTS_SUBSCRIPTION,
} from '@queries/Settings/Service/GraphQL/ECommercePlatform/subscription';
import { SEND_MESSAGE_MUTATION } from '@queries/Chat/Service/GraphQL/Message/mutation';
import { v4 as uuidv4 } from 'uuid';
import { SEND_MESSAGE } from '@store/actions/chat';
import { E_COMMERCE_PRODUCTS } from '@store/actions/ecommerce';
import { PLATFORM_TYPE } from '@modules/Chat/types';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
   },
   headerContainer: {
      padding: '12px 16px',
      display: 'flex',
      borderBottom: '1px solid #D9E0E6',
   },
   linearProgressContainer: {
      height: 8,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'stretch',
   },
   noteIcon: {
      fontSize: 32,
      color: '#fff',
   },
   smallText: {
      color: '#385273',
      opacity: '0.7',
      fontSize: '1em',
      lineHeight: 1.3,
   },
   listContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 8,
      height: '100%',
      overflowY: 'hidden',
   },
   inputsContainer: {
      marginTop: -15,
      marginBottom: -18,
   },
   footerContainer: {
      padding: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
}));

interface ProductsProps {}

const MAX_SELECT_PRODUCT = 10;

const Products: FC<ProductsProps> = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
   const { height } = useWindowResize();
   const [searchText, setSearchText] = useState<string>('');
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [fetchingMore, setFetchingMore] = useState(false);
   const [selectedVariantIds, setSelectedVariantIds] = useState<string[]>([]); // [productId]
   const eCommerceProducts = useSelector((rootReducer: any) => rootReducer.eCommerceReducer.eCommerceProducts);
   const token = useSelector((rootReducer: any) => rootReducer.personReducer.token);
   const me = useSelector((rootReducer: any) => rootReducer.personReducer.person);
   const activeChat = useSelector((rootReducer: any) => rootReducer.chatReducer.activeChat);
   const eCommerceType = useSelector((rootReducer: any) => rootReducer?.eCommerceReducer?.eCommerceType);
   const dispatch = useDispatch();

   const { value: showScrollButton, on: showScrollButtonOn, off: showScrollButtonOff } = useBoolean(false);

   const isChatMine = useMemo(() => {
      return !!activeChat?.participants?.find((participant: any) => participant._id === me._id);
   }, [activeChat, me]);

   const getVariant = async (productId: string) => {
      if (selectedVariantIds.includes(productId)) return;
      setSelectedVariantIds((prevState) => [productId, ...prevState]);
      await getECommerceVariants({
         variables: {
            customer: me.customer._id,
            productId,
         },
      });
   };

   const selectProduct = async (id: string) => {
      if (
         selectedProducts.length < MAX_SELECT_PRODUCT &&
         activeChat.joinStatus &&
         isChatMine &&
         activeChat.type !== PLATFORM_TYPE.EMAIL
      )
         setSelectedProducts((prevState) => (!prevState.includes(id) ? [...prevState, id] : prevState));
   };

   const deselectProduct = (id: string) => {
      setSelectedProducts((prevState) => [...prevState.filter((productId) => productId !== id)]);
   };

   const updateProduct = (getUpdateproduct: any) => {
      handleNext();
   };

   const deleteProduct = (getDeletedproduct: any) => {
      handleNext();
   };

   const createdProduct = (getCreatedproduct: any) => {
      handleNext();
   };

   const [getECommerceVariants, { loading: eCommerceVariantLoading }] = useLazyQuery(GET_E_COMMERCE_VARIANTS_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data: {
         getECommerceVariants: {
            data: {
               variantId: string;
               name: string;
               currency: string;
               salesPrice: string;
               discountedPrice: string;
               stock: number;
               variantsType1: string;
               variantsType2: string;
               variantsType3: string;
            }[];
         };
      }) => {
         if (data.getECommerceVariants.data.length > 0) {
            const updatedECommerceProducts = eCommerceProducts?.products.map((product: any) => {
               if (product._id.toString() === selectedVariantIds[0]) {
                  return {
                     ...product,
                     variants: data.getECommerceVariants.data,
                  };
               } else {
                  return product;
               }
            });
            dispatch({
               type: E_COMMERCE_PRODUCTS,
               payload: {
                  eCommerceProducts: {
                     ...eCommerceProducts,
                     products: updatedECommerceProducts,
                  },
               },
            });
         }
      },
   });

   useSubscription(UPDATED_E_COMMERCE_PRODUCTS_SUBSCRIPTION, {
      variables: { token: token, customer: me ? me.customer._id : '', type: eCommerceType },
      onSubscriptionData: async ({ client, subscriptionData: { data } }) => {
         const getUpdateproduct = data?.updatedECommerceProducts?.data || null;
         if (getUpdateproduct) {
            updateProduct(getUpdateproduct);
         }
      },
   });

   useSubscription(DELETED_E_COMMERCE_PRODUCTS_SUBSCRIPTION, {
      variables: { token: token, customer: me ? me.customer._id : '', type: eCommerceType },
      onSubscriptionData: async ({ client, subscriptionData: { data } }) => {
         const getDeleteproduct = data?.deletedECommerceProducts?.data || null;
         if (getDeleteproduct) {
            deleteProduct(getDeleteproduct);
         }
      },
   });

   useSubscription(CREATED_E_COMMERCE_PRODUCTS_SUBSCRIPTION, {
      variables: { token: token, customer: me ? me.customer._id : '', type: eCommerceType },
      onSubscriptionData: async ({ client, subscriptionData: { data } }) => {
         const getCreatedproduct = data?.updatedECommerceProducts?.data || null;
         if (getCreatedproduct) {
            createdProduct(getCreatedproduct);
         }
      },
   });

   const { watch, control, handleSubmit } = useForm({
      mode: 'all',
      defaultValues: {
         searchProduct: '',
         selectProductType: { value: 1, label: t['page_e_commerce_products_active'] },
      },
   });

   const watchSearchProduct = watch('searchProduct');
   const watchSelectProductType = watch('selectProductType');

   useEffect(() => {
      const timer = setTimeout(() => {
         setSearchText(watchSearchProduct);
      }, 500);
      return () => clearTimeout(timer);
   }, [watchSearchProduct]);

   const { fetchMore, loading: fetching } = useQuery(GET_E_COMMERCE_PRODUCTS_QUERY, {
      variables: {
         customer: me.customer._id,
         page: 1,
         query: searchText,
         status: watchSelectProductType.value,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data: any) => {
         dispatch({
            type: E_COMMERCE_PRODUCTS,
            payload: { eCommerceProducts: data.getECommerceProducts.data },
         });
      },
      onError: (error: any) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key: any) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const handleNext = useCallback(() => {
      if (eCommerceProducts?.hasNextPage) {
         setFetchingMore(true);
         fetchMore({
            variables: {
               page: eCommerceProducts?.nextPage,
               pageToken: eCommerceProducts.nextPageToken,
            },
         })
            .then(({ data }: any) => {
               dispatch({
                  type: E_COMMERCE_PRODUCTS,
                  payload: {
                     eCommerceProducts: {
                        ...data.getECommerceProducts.data,
                        products: [...eCommerceProducts?.products, ...data.getECommerceProducts.data.products],
                     },
                  },
               });
            })
            .finally(() => {
               setFetchingMore(false);
            });
      }
   }, [
      eCommerceProducts?.totalDocs,
      eCommerceProducts?.hasNextPage,
      eCommerceProducts?.nextPage,
      eCommerceProducts?.nextPageToken,
   ]);

   //@ts-ignore
   // const distinctProductTypes: any = [
   //    t['page_e_commerce_products_select_input_placeholder'],
   //    //@ts-ignore
   //    ...new Set(eCommerceProducts.docs.map((product: { status: any }) => product.status)),
   // ];
   // const selectProductOptions = distinctProductTypes.map((productType: any, index: any) => ({
   //    value: index,
   //    label:
   //       index == 0
   //          ? t['page_e_commerce_products_select_input_placeholder']
   //          : t['page_e_commerce_products_' + productType],
   // }));

   const selectProductOptions = [
      { value: 0, label: t['page_e_commerce_products_select_input_placeholder'] },
      { value: 1, label: t['page_e_commerce_products_active'] },
      { value: 2, label: t['page_e_commerce_products_draft'] },
   ];

   const [sendMessageMutation, { loading: sendMessageLoading }] = useMutation(SEND_MESSAGE_MUTATION);

   const shareProductCards = () => {
      if (selectedProducts && selectedProducts.length > 0) {
         const messageStatusId = uuidv4();

         sendMessageMutation({
            variables: {
               sender: me._id,
               chat: activeChat._id,
               type: 2,
               content: null,
               media: [],
               template_cards: eCommerceProducts?.products.filter((product: any) =>
                  selectedProducts.includes(product._id),
               ),
               personStatus: true,
               sendingStatusId: messageStatusId,
            },
         }).then((res) => {
            if (res.data?.createMessage?.data) {
               dispatch({
                  type: SEND_MESSAGE,
                  payload: res.data.createMessage.data,
               });
               setSelectedProducts([]);
            }
         });
      }
   };

   return (
      <div className={classes.container}>
         {/* <MenuItem
            icon={<SubjectRoundedIcon className={classes.noteIcon} />}
            title={t['page_e_commerce_products_typo_all_products']}
            subtitle={`${productList.length} ${t['page_e_commerce_products_typo_product']}`}
            containerStyle={{ marginTop: 8, marginBottom: 0 }}
            cursorPointer={false}
         /> */}

         <div className={classes.listContainer}>
            <div className={classes.inputsContainer}>
               <Grid container direction='row' alignItems='center' justify='space-between' wrap='nowrap'>
                  {/* Search Product Input */}
                  <Grid item xs={eCommerceType === E_COMMERCE_PLATFORM_TYPE.IKAS ? 12 : 6}>
                     <Input
                        name='searchProduct'
                        required={false}
                        control={control}
                        placeholder={t['page_e_commerce_products_search_product_input_placeholder']}
                     />
                  </Grid>
                  {/* Select Product Type */}
                  {eCommerceType !== E_COMMERCE_PLATFORM_TYPE.IKAS && (
                     <Grid item xs={6} style={{ paddingLeft: 5 }}>
                        <Input
                           isSelect={true}
                           selectOptions={selectProductOptions}
                           name='selectProductType'
                           control={control}
                        />
                     </Grid>
                  )}
               </Grid>
            </div>
            <div className={classes.linearProgressContainer}>{fetching && <LinearProgress />}</div>
            <InfiniteScroll
               next={handleNext}
               dataLength={eCommerceProducts?.products?.length || 0}
               height={height - 385}
               loader={
                  <Grid
                     style={{
                        height: 350,
                        margin: '20px 0px',
                     }}
                     container
                     justifyContent='center'
                     alignItems='center'
                     xs={12}
                  >
                     {fetchingMore && <CircularProgress size={24} color='primary' />}
                     {!fetchingMore && eCommerceProducts?.docs?.length < 1 && (
                        <Typography>{t['page_e_commerce_products_search_product_not_found']}</Typography>
                     )}
                  </Grid>
               }
               hasMore={eCommerceProducts?.hasNextPage}
               style={{
                  // flex: 1,
                  // height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  overflowX: 'hidden',
               }}
               // scrollableTarget='chatDiv'
            >
               {eCommerceProducts?.products?.map((product: any) => (
                  <ProductListItem
                     key={product._id}
                     product={product}
                     isSelected={selectedProducts.includes(product._id)}
                     selectProduct={selectProduct}
                     deselectProduct={deselectProduct}
                     getVariant={getVariant}
                     eCommerceType={eCommerceType}
                     eCommerceVariantLoading={eCommerceVariantLoading}
                  />
               ))}
            </InfiniteScroll>
         </div>

         <div className={classes.footerContainer}>
            <Typography variant='body2' noWrap className={classes.smallText}>
               {`${selectedProducts.length}/${MAX_SELECT_PRODUCT} ${t['page_e_commerce_products_typo_product_selected']}`}
            </Typography>
            <ButtonOK
               type='submit'
               label={t['page_e_commerce_products_typo_share_product_cards']}
               style={{
                  flexGrow: 1,
                  marginLeft: 20,
               }}
               startIcon={sendMessageLoading ? <CircularProgress size={20} /> : null}
               disabled={selectedProducts?.length === 0 || !activeChat.joinStatus || !isChatMine || sendMessageLoading}
               onClick={shareProductCards}
            />
         </div>
      </div>
   );
};

export default Products;

import React, { useState, useContext, useEffect } from 'react';
import { Grid, Menu } from '@material-ui/core';
import { FlowBlockType, FlowInstanceMessageChannel, IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import { Node } from 'reactflow';
import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import InformativeButtonModal from '@modules/Automation/FlowBots/components/informativeButtonModal';
import LinkIcon from '@mui/icons-material/Link';

const useStyles = makeStyles((theme) => ({
   btnDashed: {
      height: 40,
      width: '100%',
      border: '1px dashed #D9D9EB',
      borderRadius: '10px',
      background: 'transparent',
      cursor: 'pointer',
   },
   btn: {
      height: 40,
      width: '100%',
      borderRadius: '10px',
      background: '#ffffff',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px',
      cursor: 'pointer',
   },
   handle: {
      backgroundColor: '#fff',
      width: '16px',
      height: '16px',
      border: '1px solid #ADADAD',
      borderRadius: '50%',
      marginTop: '4px',
   },
}));
interface InformativeButtonProps {
   leftNodes: Node[];
   setIsBtnSendMsgDisable: React.Dispatch<React.SetStateAction<boolean>>;
   clickedNodeId: string;
   currentBlock: IFlowBlockTemplate;
   blockState: IFlowBlockTemplate[];
   setBlockState: React.Dispatch<React.SetStateAction<IFlowBlockTemplate[]>>;
   setToUpdateQR: React.Dispatch<React.SetStateAction<{ _id: string }[]>>;
   setDeletedBlockId: React.Dispatch<React.SetStateAction<string[]>>;
}
const InformativeButton: React.FC<InformativeButtonProps> = ({
   leftNodes,
   setIsBtnSendMsgDisable,
   clickedNodeId,
   blockState,
   currentBlock,
   setBlockState,
   setToUpdateQR,
   setDeletedBlockId,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [isCreate, setIsCreate] = useState(false);
   const [hasMultipleRows, sethasMultipleRows] = useState(false);
   const [isCreateUrl, setIsCreateUrl] = useState('');
   const [isOnlyUrl, setIsOnlyUrl] = useState(false);
   const [isOnlyQuickReply, setIsOnlyQuickReply] = useState(false);
   const [selectedItem, setSelectedItem] = useState<{
      title: string | null;
      type: string | null;
      url: string | undefined;
   }>();
   const [anchorElFieldInformativeButton, setAnchorElFieldInformativeButton] = useState<null | HTMLElement>(null);
   const [selectedBlockId, setSelectedBlockId] = useState<{ _id: string | null; QRindex: number | null }>();
   const backendBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];
   useEffect(() => {
      const isOnlyUrl = blockState.some(
         (block) =>
            block.fields[0]?.informativeButtons &&
            block.fields[0]?.informativeButtons[0]?.type === 'url' &&
            blockState.filter((block) => block.type !== FlowBlockType.SendMessageConnector).length > 1,
      );
      setIsOnlyUrl(isOnlyUrl);
   }, [blockState]);
   const handleModalOpen = (event: React.MouseEvent<HTMLElement>, block: IFlowBlockTemplate) => {
      setIsCreate(true);
      const isUrl = block.fields[0]?.informativeButtons && block.fields[0]?.informativeButtons[0]?.type;
      if (isUrl) {
         setIsCreateUrl(isUrl);
      }
      setSelectedBlockId({ _id: block._id, QRindex: null });
      setAnchorElFieldInformativeButton(event.currentTarget);
   };
   const handleEditModalOpen = (
      event: React.MouseEvent<HTMLElement>,
      value: string | undefined,
      type: string | null,
      url: string | undefined,
      blockId: string,
      qrIndex: number,
   ) => {
      setIsCreate(false);
      if (value) {
         setSelectedItem({ title: value, type: type, url: url });
         setSelectedBlockId({ _id: blockId, QRindex: qrIndex });
      }
      setAnchorElFieldInformativeButton(event.currentTarget);
   };
   useEffect(() => {
      const channelType = leftNodes.find((node) => node.type === 'trigger')?.data?.flowBlocks[0].channelType;
      if (channelType === FlowInstanceMessageChannel.WhatsappBusiness) {
         setIsOnlyQuickReply(true);
      }
      const number = currentBlock.fields[0]?.informativeButtons?.length;
      if (number) {
         sethasMultipleRows(number > 1);
      }
   }, [currentBlock]);
   const modalClose = () => {
      setAnchorElFieldInformativeButton(null);
      setSelectedItem({ title: null, type: null, url: undefined });
      setSelectedBlockId({ _id: null, QRindex: null });
   };
   const handleAddInformativeButton = (data: { title: string; type: string; url: string }) => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (selectedBlockId?._id === item._id && item.type === FlowBlockType.SendInformativeButtonMessage) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.informativeButtons) {
                        return {
                           ...field,
                           informativeButtons: [
                              ...field.informativeButtons,
                              {
                                 type: data.type,
                                 title: data.url.length >= 1 ? data.title + ',' + data.url : data.title,
                              },
                           ],
                           // Add conditional fields here based on data.type
                           isButtonRequired: data.type === 'quickReply' ? true : field.isButtonRequired,
                           requiredButtonMessage:
                              data.type === 'quickReply'
                                 ? t.atomation_flow_default_response
                                 : field.requiredButtonMessage,
                        };
                     } else {
                        return {
                           ...field,
                           informativeButtons: [
                              {
                                 type: data.type,
                                 title: data.url.length >= 1 ? data.title + ',' + data.url : data.title,
                              },
                           ],
                           // Add conditional fields here based on data.type
                           isButtonRequired: data.type === 'quickReply' ? true : field.isButtonRequired,
                           requiredButtonMessage:
                              data.type === 'quickReply'
                                 ? t.atomation_flow_default_response
                                 : field.requiredButtonMessage,
                        };
                     }
                  }),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };
   const handleUpdateInformativeButton = (data: { title: string; type: string; url: string }) => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === selectedBlockId?._id) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.informativeButtons) {
                        return {
                           ...field,
                           informativeButtons: field.informativeButtons.map((quick, index) => {
                              if (index === selectedBlockId.QRindex) {
                                 return {
                                    ...quick,
                                    title:
                                       data.url.length !== undefined
                                          ? data.title + (data.url === '' ? '' : ',') + data.url
                                          : data.title,
                                    type: data.type,
                                 };
                              }
                              return quick;
                           }),
                           // Add or remove conditional fields based on data.type
                           isButtonRequired: data.type === 'quickReply' ? true : undefined,
                           requiredButtonMessage:
                              data.type === 'quickReply' ? t.atomation_flow_default_response : undefined,
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };
   const handleDeleteQuickReply = () => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === selectedBlockId?._id) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.informativeButtons) {
                        return {
                           ...field,
                           informativeButtons: field.informativeButtons.filter(
                              (_, index) => index !== selectedBlockId.QRindex,
                           ),
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
      modalClose();
   };
   const handleBlockDeleteQR = () => {
      const existingBlocks = backendBlocks.some(
         (backendBlock: { _id: string }) => backendBlock._id === selectedBlockId?._id,
      );
      if (existingBlocks) {
         setIsBtnSendMsgDisable(false);
      }
      if (selectedBlockId) {
         setBlockState((prevBlock) => {
            setDeletedBlockId((prevDeletedBlock) => [...prevDeletedBlock, selectedBlockId?._id || '']);
            const updatedBlockState = prevBlock.filter((item) => item._id !== selectedBlockId?._id);
            return updatedBlockState;
         });
         modalClose();
      }
   };
   return (
      <Grid>
         <Grid style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            {currentBlock?.fields[0]?.informativeButtons &&
               currentBlock?.fields[0]?.informativeButtons.map((button, index) => (
                  <div
                     key={index}
                     className={classes.btn}
                     onClick={(e) =>
                        handleEditModalOpen(
                           e,
                           button.type === 'url' ? button.title?.split(',')[0].trim() : button.title,
                           button.type,
                           button.type === 'url' ? button.title?.split(',')[1].trim() : undefined,
                           currentBlock._id,
                           index,
                        )
                     }
                  >
                     <div></div>
                     {button.title?.split(',')[0].trim()}
                     {button.type === 'url' ? (
                        <LinkIcon
                           style={{
                              color: '#147BFB',
                              fontSize: '20px',
                           }}
                        />
                     ) : (
                        <Grid className={classes.handle}></Grid>
                     )}
                  </div>
               ))}
            {currentBlock?.fields[0]?.informativeButtons &&
               currentBlock?.fields[0]?.informativeButtons.length <
                  (currentBlock?.fields[0]?.informativeButtons[0]?.type === 'url' ? 1 : 3) && (
                  <button className={classes.btnDashed} onClick={(e) => handleModalOpen(e, currentBlock)}>
                     + {t.settings_whatsapp_business_template_form_quick_replies_add_button}
                  </button>
               )}
         </Grid>
         <Menu
            anchorEl={anchorElFieldInformativeButton}
            open={Boolean(anchorElFieldInformativeButton)}
            onClose={modalClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
               style: {
                  width: '450px',
                  borderRadius: '10px',
                  padding: '10px 20px 0 20px',
                  marginLeft: '10px',
               },
            }}
         >
            <InformativeButtonModal
               value={selectedItem}
               onSubmit={isCreate ? handleAddInformativeButton : handleUpdateInformativeButton}
               closeModal={modalClose}
               deleteReply={handleDeleteQuickReply}
               deleteAll={handleBlockDeleteQR}
               isCreate={isCreate}
               isCreateUrl={isCreateUrl}
               isOnlyUrl={isOnlyUrl}
               isHasQickReply={true}
               isWelcome={true}
               hasMultipleRows={hasMultipleRows}
               isOnlyQuickReply={isOnlyQuickReply}
            />
         </Menu>
      </Grid>
   );
};
export default InformativeButton;

import { useQuery } from '@apollo/client';
import { getTeams, getTeamsVariables } from "@queries/Teams/__generated__/getTeams";
import { GET_TEAMS_QUERY } from "@queries/Teams/query";
import {
  getCustomerTeamMembersQuery,
  getCustomerTeamMembersQueryVariables,
} from "@queries/Person/Service/GraphQL/__generated__/getCustomerTeamMembersQuery";
import { GET_CUSTOMER_TEAMS_QUERY } from "@queries/Person/Service/GraphQL/query";
import { getChatTagsQuery, getChatTagsQueryVariables } from "@queries/Settings/Service/GraphQL/ChatTags/__generated__/getChatTagsQuery";
import { GET_CHAT_TAGS_QUERY } from "@queries/Settings/Service/GraphQL/ChatTags/query";
import {useSelector} from "react-redux";
import {getPerson} from "@store/reducers/person";

const useCommonQueriesOfChats = () => {
  const person = useSelector(getPerson);

  const { data: agentsData } = useQuery<
    getCustomerTeamMembersQuery,
    getCustomerTeamMembersQueryVariables
  >(GET_CUSTOMER_TEAMS_QUERY, {
    variables: { person: person._id, customer: person.customer._id },
    skip: !person._id || !person.customer._id,
    fetchPolicy: 'network-only',
  });

  const { data: teamsData } = useQuery<getTeams, getTeamsVariables>(GET_TEAMS_QUERY, {
    variables: { customer: person.customer._id, me: person?._id, pageSize: 100, page: 1 },
    skip: !person.customer._id,
    fetchPolicy: 'network-only',
  });

  const { data: tagsData } = useQuery<getChatTagsQuery, getChatTagsQueryVariables>(GET_CHAT_TAGS_QUERY, {
   variables: { customer: person.customer._id, pageSize: 100, page: 1 },
   skip: !person.customer._id,
   fetchPolicy: 'network-only',
});

return {
   agents: agentsData?.customerTeams?.data,
   teams: teamsData?.getTeamList?.data?.docs,
   tags: tagsData?.getChatTagList?.data?.docs,
};
};

export default useCommonQueriesOfChats;

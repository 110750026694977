import { NetworkStatus, useQuery } from '@apollo/client';
import Error from '@components/Errors/Error';
import { calculateTimeDifference } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GET_ABANDONED_CART_STATISTICS_QUERY } from '@queries/Statistics/Service/GraphQL/query';
import moment from 'moment-timezone';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Chip from '../chip';
import Toolbar from '../toolbar';
import LineChart from './lineChart';
const getColumns = (t) => [
    {
        title: t['page_statistics_typo_abandoned_carts'],
        value: 0,
    },
    {
        title: t['page_statistics_abandoned_carts_recovered_carts'],
        value: 0,
    },
    {
        title: t['page_statistics_abandoned_carts_recovery_rate'],
        value: 0,
    },
    {
        title: t['page_statistics_abandoned_carts_recovered_value'],
        value: 0,
    },
    {
        title: t['page_statistics_abandoned_carts_cost'],
        value: 0,
    },
];

const useStyles = makeStyles((theme) => ({
    emptyList: {
        fontSize: '1.3rem',
        color: theme.chatApp.general.pallet.passiveStructureBlue,
        fontWeight: 'bold',
        marginTop: 40,
    },
}));

const AbandonedCarts = () => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;
    const person = useSelector((rootReducer) => rootReducer.personReducer.person);
    const [ABANDONED_CARTS_STATISTICS_DATA] = useState(getColumns(t));
    const [state, setState] = useState({
        startDate: moment().valueOf(),
        endDate: moment().valueOf(),
        totalAbandonedCart: 0,
        totalRecoveredCart: 0,
        totalRecoveredValue: 0,
        totalRecoveryRate: 0,
        chartData: [],
    });

    const Page = {
        pageSize: 20,
        page: 1,
    };


    const {
        data: ecommerceRulesHistoryStatisticData,
        loading,
        error,
        refetch,
        networkStatus,
    } = useQuery(GET_ABANDONED_CART_STATISTICS_QUERY, {
        variables: {
            customer: person.customer._id,
            startDate: state.startDate,
            endDate: state.endDate,
        },
        fetchPolicy: 'network-only',
    });

    const handleChangeFilterDate = (startDate, endDate) => {
        setState((prevState) => ({
            ...prevState,
            startDate,
            endDate,
        }));
    };


    function formatMoney(amount, currency = 'USD') {
        try {
            const formatter = new Intl.NumberFormat('TR', {
                style: 'currency',
                currency: currency == 'TL' ? 'TRY' : currency,
                minimumFractionDigits: 2,
                currencyDisplay: 'symbol',
            });

            return formatter.format(amount);
        } catch (error) {
            return `${amount} ${currency}`;
        }
    }

    function findMostCommonCurrency(chartData, key) {
        const currencyCount = {};

        chartData.forEach((data) => {
            const currency = data[key];
            if (currency) {
                currencyCount[currency] = (currencyCount[currency] || 0) + 1;
            }
        })

        // Find the most common currency
        let mostCommonCurrency = null;
        let maxCount = 0;

        for (const currency in currencyCount) {
            if (currencyCount[currency] > maxCount) {
                mostCommonCurrency = currency;
                maxCount = currencyCount[currency];
            }
        }

        return mostCommonCurrency;
    }

    if (ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic) {
        let mostCommonRecoveredValueCurrency = findMostCommonCurrency(ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic?.data?.chartData, 'cartCurrency') || 'USD';
        let mostCommonTotalCostCurrency = findMostCommonCurrency(ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic?.data?.chartData, 'costCurrency') || 'USD';

        ABANDONED_CARTS_STATISTICS_DATA[0].value =
            ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic?.data?.totalAbandonedCart + ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic?.data?.totalRecoveredCart;
        ABANDONED_CARTS_STATISTICS_DATA[1].value =
            ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic?.data?.totalRecoveredCart;
        ABANDONED_CARTS_STATISTICS_DATA[2].value =
            (lang?.code.toLowerCase().includes('tr') ? '%' : '') + ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic?.data?.totalRecoveryRate + (!lang?.code.toLowerCase().includes('tr') ? '%' : '');
        ABANDONED_CARTS_STATISTICS_DATA[3].value = formatMoney(ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic?.data?.totalRecoveredValue, mostCommonRecoveredValueCurrency);
        ABANDONED_CARTS_STATISTICS_DATA[4].value = formatMoney(ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic?.data?.totalCost, mostCommonTotalCostCurrency);
    }

    const showChartReportUI = (data) => {
        if (
            data?.totalAbandonedCart === 0 &&
            data?.totalRecoveredCart === 0 &&
            data?.totalRecoveredValue === 0 &&
            data?.totalRecoveryRate === 0 &&
            data?.totalCost === 0
        ) {
            return false;
        }
        return true;
    };

    useEffect(() => {
        refetch();
    }, [state, refetch]);


    const loadingOrFetching = networkStatus === NetworkStatus.refetch || loading;

    if (loadingOrFetching) {
        return <div className='loading' />;
    }

    return (
        <Fragment>
            <Fragment>
                <Toolbar
                    onDateChange={handleChangeFilterDate}
                    startDate={state.startDate}
                    endDate={state.endDate}
                    title={t['page_statistics_typo_abandoned_carts']}
                    params={{
                        customer: person.customer._id,
                        startDate: state.startDate,
                        endDate: state.endDate,
                    }}
                />
                <Grid container direction='column' style={{ padding: 20 }}>
                    <Grid container justifyContent='space-between' alignItems='center'>
                        {ABANDONED_CARTS_STATISTICS_DATA.map((item) => (
                            <Grid key={item.title} item xs={2}>
                                <Chip title={item.title} value={item.value} applyWrap />
                            </Grid>
                        ))}
                    </Grid>
                    {showChartReportUI(ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic?.data) ? (
                        <Fragment>
                            {!error && (
                                <Grid item style={{ marginTop: 30 }}>
                                    <LineChart
                                        chartData={
                                            ecommerceRulesHistoryStatisticData?.getEcommerceRulesHistoryStatistic?.data.chartData || []
                                        }
                                        mode={calculateTimeDifference(state.startDate, state.endDate)}
                                    />
                                </Grid>
                            )}
                        </Fragment>
                    ) : (
                        <Grid xs={12} container justifyContent='center' style={{ height: 100 }}>
                            <Typography className={classes.emptyList}>{t?.page_statistics_abandoned_carts_no_abandoned_carts}</Typography>{' '}
                        </Grid>
                    )}
                </Grid>
            </Fragment>
            <Error show={!!error} message={error?.message} />
        </Fragment>
    );
};

export default AbandonedCarts;

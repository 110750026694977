import { useSubscription } from '@apollo/client';

type ApplyFunc = (exisitingData: any, newData: any) => any;

/**
 * @param subscription subscription
 * @param variables subscription variables
 * @param newDataFieldName subscription field name
 * @param updatedFieldName field name to be updated
 * @param applyFunc Function that takes exisitingData and newData as parameters
 */
const useSubscriptionWithCache = (
   subscription: any,
   variables: object,
   newDataFieldName: string,
   updatedFieldName: string,
   applyFunc: ApplyFunc
) => {
   useSubscription(subscription, {
      variables: {
         ...variables,
      },
      onSubscriptionData: ({ client, subscriptionData: { data } }) => {
         if (data) {
            const newData = data[newDataFieldName]?.data;
            client.cache.modify({
               fields: {
                  [updatedFieldName]: (existingData = []) => {
                     return {
                        data: applyFunc(existingData, newData),
                     };
                  },
               },
            });
         }
      },
   });
};

export default useSubscriptionWithCache;

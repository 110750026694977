import PersonIcon from '@material-ui/icons/Person';
import TwitterIcon from '@material-ui/icons/Twitter';
import React from 'react';
import {
   HepsiBuradaIcon,
   QuestionsFacebookIcon,
   TrendyolQuestionIcon,
   QuestionsN11Icon,
} from '@assets/icons/QuestionIcons/index';
import { PLATFORM_TYPE, USERS_TYPE } from '../../modules/Chat/types';
import { QnAPlatformType } from '../../modules/Questions/util/types';
import EmailIcon from './emailIcon';
import FacebookMessengerIcon from './facebookMessengerIcon';
import InstagramIcon from './instagramIcon';
import WhatsappBusiness from './whatsappBusiness';
import WhatsappIcon from './whatsappIcon';
import LiveChatIcon from './liveChatIcon';
import { AccountCircle } from '@material-ui/icons';
import { TelegramIcon } from '@assets/icons/SettingsIcons/settingsIcons';

const getSocialMediaIcon = (socialMediaCode, color, selected, twitterSize, className) => {
   switch (socialMediaCode) {
      case 'LIVE_CHAT':
         return <LiveChatIcon color={color} selected={selected} />;
      case USERS_TYPE.PERSONEL:
      case 0:
         return <PersonIcon />;
      case PLATFORM_TYPE.WHATS_APP:
      case PLATFORM_TYPE.WHATS_APP_WEB:
      case USERS_TYPE.WHATS_APP_WEB:
      case 1:
         return <WhatsappIcon color={color} selected={selected} />;
      case PLATFORM_TYPE.WHATS_APP_BUSINESS:
      case USERS_TYPE.WHATS_APP_BUSINESS:
      case 2:
         return <WhatsappBusiness color={color} selected={selected} />;
      case PLATFORM_TYPE.FACEBOOK:
      case USERS_TYPE.FACEBOOK:
      case 3:
         return <FacebookMessengerIcon color={color} selected={selected} />;
      case PLATFORM_TYPE.INSTAGRAM:
      case USERS_TYPE.INSTAGRAM:
      case 4:
         return <InstagramIcon color={color} selected={selected} />;
      case 5:
      case PLATFORM_TYPE.EMAIL: // Todo add email and comment integration
      case USERS_TYPE.EMAIL:
         return <EmailIcon selected={selected} />;
      case 6:
      case 'TWITTER':
         return <TwitterIcon style={{ color: selected ? '#00ACEE' : 'lightgrey', fontSize: twitterSize || '8rem' }} />;
      case 7:
      case 'TRENDYOL':
      case QnAPlatformType.TRENDYOL:
      case USERS_TYPE.TRENDYOL:
         return <TrendyolQuestionIcon color={color} selected={selected} />;
      case QnAPlatformType.HEPSIBURADA:
      case USERS_TYPE.HEPSIBURADA:
      case 8:
         return <HepsiBuradaIcon color={color} selected={selected} />;
      case 9:
         // For Facebook Comment
         return <QuestionsFacebookIcon color={color} selected={selected} />;
      case 10:
      case -7:
         return <LiveChatIcon selected={selected} />;
      case -10:
         return <AccountCircle style={{ fontSize: 33 }} />;
      case -8:
      case 'N11':
         return <QuestionsN11Icon selected={selected} />;
      case -9:
      case PLATFORM_TYPE.TELEGRAM:
      case USERS_TYPE.TELEGRAM:
         return <TelegramIcon selected={selected} />;
      default:
         console.warn('SocialMediaIcon not found');
         return <PersonIcon />;
   }
};

const SocialMediaIcon = (props) => {
   const { socialMediaCode, selected, color, width, height, twitterSize, containerStyle } = props;
   const SocialMediaIconComponent = getSocialMediaIcon(socialMediaCode, color, selected, twitterSize);
   return (
      <div
         style={{
            width: width,
            height: height,
            backgroundColor: 'transparent',
            borderRadius: 20,
            padding: 2,
            ...containerStyle,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
         }}
      >
         {SocialMediaIconComponent}
      </div>
   );
};

export default SocialMediaIcon;

import React, { useContext } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
const getColumns = (t, payload) => [
   {
      title: t?.total,
      value: payload[0]?.payload?.totalChatsCount || 0,
   },
   {
      title: t?.page_dashboard_typo_active,
      value: payload[0]?.payload?.activeChatsCount || 0,
   },
   {
      title: t?.page_dashboard_typo_waiting,
      value: payload[0]?.payload?.waitingChatsCount || 0,
   },
   {
      title: t?.page_chat_typo_filter_title_closed,
      value: payload[0]?.payload?.closedChatsCount || 0,
   },
   {
      title: t?.page_chat_typo_filter_title_resolved,
      value: payload[0]?.payload?.solvedChatsCount || 0,
   },
   {
      title: t?.page_chat_typo_filter_title_spam_blocked,
      value: payload[0]?.payload?.spammedChatsCount || 0,
   },
];
const useStyles = makeStyles((theme) => ({
   root: {
      padding: 20,
      borderRadius: 10,
      width: 350,
   },
   headerContainer: {
      padding: 10,
      backgroundColor: '#F7F7FA',
      fontSize: '1.1rem',
      borderRadius: 10,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   bottomContainer: {
      padding: 10,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   countContainer: {
      backgroundColor: '#1AAE7A',
      // width: 25,
      height: 28,
      borderRadius: 5,
      fontWeight: 'bold',
   },
}));
const CustomTooltip = (props) => {
   const { payload } = props;
   const classes = useStyles();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   if (!payload || !payload.length) {
      return null;
   }
   const COLUMNS = getColumns(t, payload);

   return (
      <Paper elevation={3} className={classes.root}>
         <Grid container direction='column' alignItems='center'>
            <Grid item container direction='row' justifyContent='space-between' className={classes.headerContainer}>
               <Typography style={{ fontSize: '1.2rem' }}>{t?.choice}:</Typography>
               <Typography style={{ fontSize: '1.2rem' }}>{payload[0]?.payload?.title}</Typography>
            </Grid>
            <Grid container className={classes.bottomContainer} item direction='column' xs={12} justifyContent='center'>
               {COLUMNS.map((item, index) => (
                  <Grid direction='row' container xs={12} key={item.title + index} style={{ marginTop: 10 }}>
                     <Grid item xs={9}>
                        <Typography variant='body1'>{item.title}</Typography>
                     </Grid>
                     <Grid
                        item
                        xs={3}
                        justifyContent='center'
                        alignItems='center'
                        container
                        className={classes.countContainer}
                     >
                        <Typography variant='subtitle1' style={{ color: 'white', fontWeight: 'bold' }}>
                           {item.value}
                        </Typography>
                     </Grid>
                  </Grid>
               ))}
            </Grid>
         </Grid>
      </Paper>
   );
};

export default CustomTooltip;

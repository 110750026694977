import { useEffect, useState } from 'react';
import moment from 'moment';
import { DAYS, HOURS } from '@lib/constants';

const useFreeTrialDate = (customerFreeTrialDate) => {
   const diff = moment(parseInt(customerFreeTrialDate)).diff(new Date(), DAYS, true);
   const [difference, setDifference] = useState(diff);
   const [isHours, setIsHours] = useState(difference > 0 && difference < 1);
   const [isEnd, setIsEnd] = useState(false);

   useEffect(() => {
      const difference = moment(parseInt(customerFreeTrialDate)).diff(new Date(), DAYS, true);
      setDifference(difference);
      setIsHours(false);
      if (difference > 0 && difference < 1) {
         const hoursDifference = moment(parseInt(customerFreeTrialDate)).diff(new Date(), HOURS, true); // Todo will be hours
         setDifference(Math.floor(hoursDifference));
         setIsHours(true);
      } else if (difference < 0) {
         setIsEnd(true);
      }
   }, [customerFreeTrialDate]);

   return {
      isHours,
      difference,
      isEnd,
   };
};

export default useFreeTrialDate;

import React from 'react';
import {
   Dialog,
   DialogContent,
   DialogTitle,
   Grid,
   IconButton,
   Typography,
   useMediaQuery,
   Fade,
   Divider,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

// TODO: change old modal component to this one
const useStyles = makeStyles((theme) => ({
   root: {
      // minWidth: 400,
      transition: theme.transitions.create('maxHeight'),
   },
   titleContainer: {
      padding: 5,
      position: 'sticky',
      top: 0,
      background: '#fff',
      zIndex: 5,
   },
   title: {
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      paddingBottom: 6,
   },
}));
/**
 *
 * @param open if true, dialog is open
 * @param children dialog content
 * @param dialogTitle title of the dialog
 * @param disableCloseButton don't show the close icon
 * @param onClose Callback fired when the component requests to be closed.
 */
const MaterialDialog = ({ open, children, dialogTitle, onClose, disableCloseButton }) => {
   const classes = useStyles();
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

   return (
      <Dialog
         className={classes.root}
         open={open}
         fullScreen={isSmallScreen}
         TransitionComponent={Fade}
         onClose={onClose}
         classes={{
            paper: classes.paper,
         }}
      >
         <DialogTitle>
            <Grid alignItems='center' className={classes.titleContainer} container>
               <Grid item container justifyContent='center' xs={11}>
                  <Typography variant='h5' color='primary' className={classes.title}>
                     {dialogTitle}
                  </Typography>
               </Grid>
               {!disableCloseButton && (
                  <Grid item container justifyContent='flex-end' xs={1}>
                     <IconButton onClick={onClose}>
                        <CloseIcon />
                     </IconButton>
                  </Grid>
               )}
            </Grid>
            <Divider style={{ width: '100%' }} />
         </DialogTitle>
         <DialogContent>{children}</DialogContent>
      </Dialog>
   );
};

export default MaterialDialog;

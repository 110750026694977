import { useMutation } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import {
   SEND_ANSWER,
   SET_ACTIVE_QUESTION,
   SET_ACTIVE_QUESTION_MESSAGES,
   UPDATE_QNA_PERSON,
} from '@store/actions/questions';
import { useSnackbar } from 'notistack';
import { useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import {
   FORWARD_QNA_MUTATION,
   JOIN_QNA_MUTATION,
   LEAVE_QNA_MUTATION,
   QUESTION_ADD_PERSON_TAG_MUTATION,
   SIGN_AS_ARCHIVE_QNA_MUTATION,
} from '@queries/Questions/Service/mutation';
import { QnAPersonStatusType } from '../util/types';

const useQnaQuestions = () => {
   const dispatch = useDispatch();
   const { enqueueSnackbar } = useSnackbar();
   const [joinQuestionMutation, { loading: joinQuestionLoading }] = useMutation(JOIN_QNA_MUTATION);
   const [leaveQuestionMutation, { loading: leaveQuestionLoading }] = useMutation(LEAVE_QNA_MUTATION);
   const [forwardQuestionMutation, { loading: forwardQuestionLoading }] = useMutation(FORWARD_QNA_MUTATION);
   const [addTagQuestionMutation, { loading: addTagQuestionLoading }] = useMutation(QUESTION_ADD_PERSON_TAG_MUTATION);
   const [signAsArchiveQuestionMutation, { loading: signAsArchiveQuestionLoading }] =
      useMutation(SIGN_AS_ARCHIVE_QNA_MUTATION);
   const {
      lang: { translation: t },
   }: any = useContext(LanguageContext);

   const setActiveQuestionMessages = useCallback(
      (data: any) => {
         dispatch({
            type: SET_ACTIVE_QUESTION_MESSAGES,
            payload: data,
         });
      },
      [dispatch]
   );

   const setActiveQuestion = useCallback(
      (data: any) => {
         dispatch({
            type: SET_ACTIVE_QUESTION,
            payload: data,
         });
      },
      [dispatch]
   );

   const updateQnaPerson = useCallback(
      (data: any) => {
         dispatch({
            type: UPDATE_QNA_PERSON,
            payload: data,
         });
      },
      [dispatch]
   );

   const sendReplyQuestion = useCallback(
      (params: any) => {
         dispatch({
            type: SEND_ANSWER,
            payload: params,
         });
      },
      [dispatch]
   );

   const handleJoinQuestion = useCallback(
      (questionId: string) => {
         joinQuestionMutation({
            variables: {
               _id: questionId,
            },
         })
            .then(({ data }) => {
               enqueueSnackbar(t?.question_joined_question_success, {
                  variant: 'success',
                  autoHideDuration: 2500,
               });
            })
            .catch((err) => {
               enqueueSnackbar(err.message, {
                  variant: 'error',
                  autoHideDuration: 2500,
               });
            });
      },
      [enqueueSnackbar, joinQuestionMutation, t?.question_joined_question_success]
   );

   const handleLeaveQuestion = useCallback(
      (qnaId: string, status: QnAPersonStatusType) => {
         leaveQuestionMutation({
            variables: {
               _id: qnaId,
               status,
            },
         })
            .then(({ data }) => {
               enqueueSnackbar(t?.question_leaved_question_success, {
                  variant: 'success',
                  autoHideDuration: 2500,
               });
            })
            .catch((err) => {
               enqueueSnackbar(err.message, {
                  variant: 'error',
                  autoHideDuration: 2500,
               });
            });
      },
      [enqueueSnackbar, leaveQuestionMutation, t?.question_leaved_question_success]
   );

   const handleForwardQuestion = useCallback(
      (qnaId: string, forwardedPersonId: string) => {
         forwardQuestionMutation({
            variables: {
               _id: qnaId,
               joinedPerson: forwardedPersonId,
            },
         })
            .then(({ data }) => {
               enqueueSnackbar(t?.question_forwarded_question_success, {
                  variant: 'success',
                  autoHideDuration: 2500,
               });
            })
            .catch((err) => {
               enqueueSnackbar(err.message, {
                  variant: 'error',
                  autoHideDuration: 2500,
               });
            });
      },
      [enqueueSnackbar, forwardQuestionMutation, t?.question_forwarded_question_success]
   );

   const handleAddTagQuestion = useCallback(
      (data: { qnaId: string; tagId: string | null }) => {
         addTagQuestionMutation({
            variables: {
               _id: data.qnaId,
               tag: data.tagId,
            },
         })
            .then((data) => {
               enqueueSnackbar(t?.chat_mutation_tag_add, {
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            })
            .catch((error) => {
               enqueueSnackbar(error.message, {
                  variant: 'error',
                  autoHideDuration: 2000,
               });
            });
      },
      [addTagQuestionMutation, enqueueSnackbar, t?.chat_mutation_tag_add]
   );

   const handleSignAsArchiveQuestionMutation = useCallback(
      ({ qnaId }: { qnaId: string }) => {
         signAsArchiveQuestionMutation({
            variables: {
               _id: qnaId,
            },
         })
            .then(({ data }) => {
               enqueueSnackbar(t?.question_archived_question_success, {
                  variant: 'success',
                  autoHideDuration: 2500,
               });
            })
            .catch((err) => {
               enqueueSnackbar(err.message, {
                  variant: 'error',
                  autoHideDuration: 2500,
               });
            });
      },
      [signAsArchiveQuestionMutation, enqueueSnackbar, t?.question_archived_question_success]
   );

   const mutationsLoading =
      joinQuestionLoading ||
      forwardQuestionLoading ||
      leaveQuestionLoading ||
      addTagQuestionLoading ||
      signAsArchiveQuestionLoading;

   return {
      setActiveQuestionMessages,
      setActiveQuestion,
      updateQnaPerson,
      loadingState: {
         mutationsLoading,
      },
      sendReplyQuestion,
      handleJoinQuestion,
      handleLeaveQuestion,
      handleForwardQuestion,
      handleAddTagQuestion,
      handleSignAsArchiveQuestionMutation,
   } as const;
};

export default useQnaQuestions;

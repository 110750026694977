// defines
import { ApolloClient, ApolloProvider, from, InMemoryCache, split } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Zoom } from '@material-ui/core';
import ApolloLinkTimeout from 'apollo-link-timeout';
import { createUploadLink } from 'apollo-upload-client';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { API_URL } from '@helper/config';
import { persistor, store } from '@store/store';
import { SIGN_OUT, UPDATE_ME } from '@store/actions/person';
import { useDispatch } from 'react-redux';
const errorLink = onError(({ graphQLErrors, networkError }) => {
   if (graphQLErrors)
      graphQLErrors.forEach(async ({ message, locations, path, extensions }) => {
         //! TODO: More check needed maybe extensions.code no longer exists
         if (
            extensions?.code === 'UNAUTHENTICATED' ||
            message?.includes('Token not found.') ||
            message?.includes('Token incorrect or expired, please login again.') ||
            message?.includes('Token hatalı yada süresi dolmuş, lütfen tekrar giriş yapın.')
         ) {
            setTimeout(async () => {
               store.dispatch({ type: SIGN_OUT, payload: {} });
               localStorage.clear();
               await client.resetStore();
            }, 1500);
         }
      });

   if (networkError) console.log(`[Network error]: ${networkError.message}`);
});

const timeoutLink = new ApolloLinkTimeout(3600000); // 10 second timeout

const authLink = setContext(async (_, { headers }) => {
   // get the authentication token from local storage if it exists
   const personReducer = JSON.parse(localStorage.getItem('persist:redux'))?.personReducer;

   if (!personReducer) {
      window.location.reload(); // Refresh the page
      return { headers };
   }

   const token = JSON.parse(personReducer).token;
   return {
      headers: {
         ...headers,
         authorization: token,
         language: localStorage.getItem('langCode') || navigator.language,
      },
   };
});

let wsUrl =
   (window.location.protocol.toString() === 'https:' ? 'wss://' : 'ws://') +
   API_URL.split('http://').join('').split('https://').join('') +
   '/graphql';

const wsLink = new GraphQLWsLink(
   createClient({
      url: wsUrl,
      shouldRetry: true,
   }),
);

// create http link
const httpLink = authLink.concat(
   createUploadLink({
      uri: API_URL + '/graphql',
      headers: {
         'apollo-require-preflight': true,
      },
   }),
);

// split websocket and http links
const splitLink = split(
   ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
   },
   wsLink,
   httpLink,
);

const client = new ApolloClient({
   link: from([errorLink, timeoutLink, splitLink]),
   cache: new InMemoryCache({
      addTypename: false,
   }),
   connectToDevTools: true,
});

ReactDOM.render(
   <ApolloProvider client={client}>
      <BrowserRouter>
         <Provider store={store}>
            <PersistGate loading={<div>Loading...</div>} persistor={persistor}>
               <SnackbarProvider
                  preventDuplicate
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  TransitionComponent={Zoom}
                  maxSnack={4}
               >
                  <App />
               </SnackbarProvider>
            </PersistGate>
         </Provider>
      </BrowserRouter>
   </ApolloProvider>,
   document.getElementById('root'),
);

import React from 'react';

const Success = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='140' height='140' viewBox='0 0 140 140'>
         <g id='Group_336' data-name='Group 336' transform='translate(-890 -436)'>
            <path
               id='Path_200'
               dataname='Path 200'
               d='M553.152-338.453c3.037-3.109,5.838-6.026,8.693-8.888q14.879-14.916,29.791-29.8c3.64-3.637,8.944-3.843,12.4-.5,3.576,3.46,3.583,8.783-.142,12.521q-22.2,22.276-44.483,44.47c-3.756,3.739-8.7,3.732-12.5,0q-8.755-8.591-17.347-17.347a8.541,8.541,0,0,1-.17-12.257,8.539,8.539,0,0,1,12.386.257C545.515-346.241,549.211-342.454,553.152-338.453Z'
               transform='translate(393.204 857.844)'
               fill='#24e19f'
            />
            <g
               id='Ellipse_75'
               dataname='Ellipse 75'
               transform='translate(890 436)'
               fill='none'
               stroke='#24e19f'
               strokeWidth='6'
            >
               <circle cx='70' cy='70' r='70' stroke='none' />
               <circle cx='70' cy='70' r='67' fill='none' />
            </g>
         </g>
      </svg>
   );
};

export default Success;

import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import { FlowBlockType, FlowInstanceMessageChannel, IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import { Node } from 'reactflow';

const useStyles = makeStyles((theme) => ({
   btn: {
      height: 36,
      width: '100%',
      border: '1px dashed #D7D7D7',
      borderRadius: '10px',
      background: 'transparent',
      cursor: 'pointer',
      textAlign: 'center',
   },
}));

interface BtnPannelProps {
   handleCreateBlockQuickReply: () => void;
   handleCreateBlockInformativeButton: () => void;
   blockState: IFlowBlockTemplate[];
   isBlockTextLast: boolean;
   leftNodes: Node[];
}
const SendMessageButtonPanel: React.FC<BtnPannelProps> = ({
   blockState,
   handleCreateBlockQuickReply,
   handleCreateBlockInformativeButton,
   leftNodes,
   isBlockTextLast,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [showQR, setShowQR] = useState<boolean>(true);
   const [showInfBtn, setShowInfBtn] = useState<boolean>(true);
   const totalBlocks = blockState.length;

   useEffect(() => {
      const isQR = blockState.some((block) => block.type === FlowBlockType.SendQuickReplyMessage);
      const isInfBtn = blockState.some(
         (block) =>
            block.type === FlowBlockType.SendInformativeButtonMessage &&
            blockState.filter((blk) => blk.type !== FlowBlockType.SendMessageConnector)[0]?.fields[0]
               ?.informativeButtons?.length === 0,
      );

      const isInfBtnQR = blockState.some(
         (block) =>
            block.type === FlowBlockType.SendInformativeButtonMessage &&
            block.fields[0].informativeButtons &&
            block.fields[0].informativeButtons[0]?.type === 'quickReply',
      );
      const isInfBtnInfUrl = blockState.some(
         (block) =>
            block.type === FlowBlockType.SendInformativeButtonMessage &&
            block.fields[0].informativeButtons &&
            block.fields[0].informativeButtons[0]?.type === 'url',
      );

      if (isQR || isInfBtn || isInfBtnQR) {
         setShowQR(false);
         setShowInfBtn(false);
      } else if (isInfBtnInfUrl) {
         setShowQR(false);
         setShowInfBtn(true);
      } else {
         setShowQR(true);
         setShowInfBtn(true);
      }
   }, [blockState]);

   const channelType = leftNodes.find((node) => node.type === 'trigger')?.data?.flowBlocks[0].channelType;

   return (
      <Grid>
         <Grid style={{ width: '100%', display: 'flex', gap: '5px', marginTop: '20px' }}>
            <button
               disabled={!showInfBtn || totalBlocks === 6}
               className={classes.btn}
               onClick={handleCreateBlockInformativeButton}
            >
               <span> {t.atomation_flow_text_button}</span>
            </button>
            {(channelType === FlowInstanceMessageChannel.LiveChat ||
               channelType === FlowInstanceMessageChannel.Facebook ||
               channelType === FlowInstanceMessageChannel.Instagram) && (
               <button disabled={!showQR} className={classes.btn} onClick={handleCreateBlockQuickReply}>
                  <span>{t.atomation_flow_quick_reply}</span>
               </button>
            )}
         </Grid>
      </Grid>
   );
};

export default SendMessageButtonPanel;

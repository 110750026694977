import { useMutation, useQuery, useSubscription, useLazyQuery } from '@apollo/client';
import { Avatar, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState, useEffect } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import Modal from '@components/Modal/modal';
import {
   DELETE_PLATFORM_MUTATION,
   UPDATE_PLATFORM_MUTATION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/mutation';
import { GET_TEAMS_QUERY } from '../../../../queries/Teams/query';
import {
   CREATED_PLATFORM_SUBSCRIPTION,
   DELETED_PLATFORM_SUBSCRIPTION,
   UPDATED_PLATFORM_SUBSCRIPTION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/subscription';
import { GET_QNA_ACCOUNTS_QUERY } from '@queries/Settings/Service/GraphQL/QuestionPlatform/query';
import { PLATFORM_TYPE } from '../../../../queries/Settings/Service/GraphQL/Platform/types';
import CreateModalContent from './createModalContent';
import DeleteModalContent from './deleteModalContent';
import TableData from './table';
import { GET_PERSON_CUSTOMERS_QUERY } from '@queries/Person/Service/GraphQL/query';
import { GET_PLATFORMS_QUERY } from '@queries/Settings/Service/GraphQL/Platform/query';
import { useSelector } from 'react-redux';
import { getPerson, getToken } from '@store/reducers/person';
import { QnAPlatformType } from '@modules/Questions/util/types';
import { AvatarGroup } from '@material-ui/lab';
import ActionIconButton from '../ActionIconButton';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   selector: {
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 10,
      paddingLeft: 3,
      height: 32,
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      // fontWeight: 'bold',
   },
   button: {
      paddingTop: 10,
      width: 144,
      height: 30,
      color: 'white',
      borderRadius: 5,
      marginRight: 10,
   },
   buttonApply: {
      backgroundColor: '#487DBF',
      '&:hover': {
         backgroundColor: '#487DBF',
      },
   },
   ButtonContainer: {
      paddingLeft: 5,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },

   divContainer: {
      width: 42,
      height: 42,
      borderRadius: 32,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   avatarText: {
      textAlign: 'center',
      fontSize: '1.3rem',
      color: 'white',
   },
}));

const Page = {
   pageSize: 15,
   page: 1,
};

const TeamPage = ({ person, token }) => {
   const me = useSelector(getPerson);
   const classes = useStyles();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const initialState = {
      show: false,
      status: '',
      errorMessage: '',
   };
   const [modalMode, setModalMode] = useState('CREATE');
   const [CreateModalConfig, setCreateModalConfig] = useState(initialState);
   const [DeleteModalConfig, setDeleteModalConfig] = useState(initialState);
   const [editData, setEditData] = useState({});

   const customerId = person.customer._id;

   // get chat tags

   const { data: whatsappWebPlatfromData, loading: loadingPlatform1 } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
   });

   const { data: whatsappBusinessPlatfromData, loading: loadingPlatform2 } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.WHATS_APP_BUSINESS, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
   });

   const { data: facebookPlatfromData, loading: loadingPlatform3 } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.FACEBOOK, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
   });
   const { data: instagramPlatfromData, loading: loadingPlatform4 } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.INSTAGRAM, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
   });
   const { data: liveChatPlatformData, loading: loadingPlatform5 } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.LIVE_CHAT, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
   });

   const { data: trendyolQnAData, loading: loadingPlatform6 } = useQuery(GET_QNA_ACCOUNTS_QUERY, {
      variables: { type: QnAPlatformType.TRENDYOL, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
   });

   const { data: n11QnAData, loading: loadingPlatform7 } = useQuery(GET_QNA_ACCOUNTS_QUERY, {
      variables: { type: QnAPlatformType.N11, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
   });

   const { data: hepsiburadaQnAData, loading: loadingPlatform8 } = useQuery(GET_QNA_ACCOUNTS_QUERY, {
      variables: { type: QnAPlatformType.HEPSIBURADA, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
   });

   const { data: telegramPlatformData, loading: loadingPlatform9 } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.TELEGRAM, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
   });
   const { data: emailPlatformData, loading: loadingPlatform10 } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.EMAIL, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
   });

   const whatsappWebPlatforms = whatsappWebPlatfromData?.getPlatforms?.data || [];
   const whatsappBusinessPlatforms = whatsappBusinessPlatfromData?.getPlatforms?.data || [];
   const facebookPlatforms = facebookPlatfromData?.getPlatforms?.data || [];
   const instagramPlatforms = instagramPlatfromData?.getPlatforms?.data || [];
   const liveChatPlatforms = liveChatPlatformData?.getPlatforms?.data || [];
   const trendyolQnAs = trendyolQnAData?.QnAAccounts?.data || [];
   const n11QnAs = n11QnAData?.QnAAccounts?.data || [];
   const hepsiburadaQnAs = hepsiburadaQnAData?.QnAAccounts?.data || [];
   const telegramPlatforms = telegramPlatformData?.getPlatforms?.data || [];
   const emailPlatforms = emailPlatformData?.getPlatforms?.data || [];

   const allPlatforms = [
      ...whatsappWebPlatforms,
      ...whatsappBusinessPlatforms,
      ...facebookPlatforms,
      ...instagramPlatforms,
      ...liveChatPlatforms,
      ...trendyolQnAs,
      ...n11QnAs,
      ...hepsiburadaQnAs,
      ...telegramPlatforms,
      ...emailPlatforms,
   ];

   const { loading: personsLoading, data: personsData } = useQuery(GET_PERSON_CUSTOMERS_QUERY, {
      variables: { customer: me.customer._id, notSelectBot: true, pageSize: 100, page: 1 },
      fetchPolicy: 'cache-and-network',
   });

   const [teamsCount, setTeamsCount] = useState(0);
   const [teamsList, setTeamsList] = useState([]);
   const [text, setText] = useState('');

   const [getTeams, { loading: teamsLoading, data: teamsData }] = useLazyQuery(GET_TEAMS_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
         let rows = data?.getTeamList?.data?.docs.map((item) => {
            return {
               id: item._id,
               team: item,
               users: item,
               channels: item.drivers,
               operation: item,
            };
         });
         setTeamsList(rows);
         setTeamsCount(data?.getTeamList?.data?.docsCount);
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            autoHideDuration: 2500,
         });
      },
   });

   const [getTeamsParam, setGetTeamsParam] = useState({
      customer: customerId,
      pageSize: Page.pageSize,
      page: Page.page,
      search: '',
   });

   useEffect(() => {
      getTeams({
         variables: {
            ...getTeamsParam,
         },
      });
   }, [getTeamsParam]);

   useEffect(() => {
      setGetTeamsParam((prev) => ({ ...prev, search: text }));
   }, [text]);

   const [deleteFacebookPage, { loading: deleteFacebookPagesLoading }] = useMutation(DELETE_PLATFORM_MUTATION, {
      fetchPolicy: 'network-only',
      onError: (error) => {
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const [updateAccountMutation, { loading: updateAccountLoading }] = useMutation(UPDATE_PLATFORM_MUTATION);

   const updateSubsCache = (data, client, operation) => {
      try {
         let { getPlatforms } = client.cache.readQuery({
            // query: GET_PLATFORMS_QUERY,
            variables: { type: PLATFORM_TYPE.FACEBOOK, customer: person ? person.customer._id : '' },
         }); // react cache query
         //Find index of specific object using findIndex method.
         let updatedData = [];
         if (operation === 'create') {
            updatedData = [...data.metaData.facebookPages, ...getPlatforms.data];
         } else {
            let index = getPlatforms.data.findIndex((item) => item._id.toString() === data.metaData._id.toString());
            if (index > -1) {
               if (operation === 'update') {
                  updatedData = getPlatforms.data.map((item) => {
                     if (item._id.toString() === data.metaData._id.toString()) {
                        const toWrite = {
                           ...item,
                           ...data.metaData,
                        };
                        return toWrite;
                     }
                     return item;
                  });
               } else if (operation === 'delete') {
                  updatedData = getPlatforms.data.filter(
                     (item) => item._id.toString() !== data.metaData._id.toString(),
                  );
               }
            }
         }

         client.cache.writeQuery({
            // query: GET_PLATFORMS_QUERY,
            variables: { type: PLATFORM_TYPE.FACEBOOK, customer: person ? person.customer._id : '' },
            data: { getPlatforms: { ...getPlatforms, data: updatedData } }, // add new data into old one
         });
      } catch (error) {
         console.warn(error);
      }
   };

   useSubscription(CREATED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               createdPlatform: { data },
            },
         },
      }) => {
         updateSubsCache(data, client, 'create');
      },
   });

   useSubscription(UPDATED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedPlatform: { data },
            },
         },
      }) => {
         updateSubsCache(data, client, 'update');
      },
   });

   useSubscription(DELETED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               deletedPlatform: { data },
            },
         },
      }) => {
         updateSubsCache(data, client, 'delete');
      },
   });

   // delete data on submit
   const handleDeleteFacebookPage = async (item, deleteType) => {
      deleteFacebookPage({
         variables: {
            type: PLATFORM_TYPE.FACEBOOK,
            metaData: {
               facebookPageId: item.facebookPageId,
               customer: person.customer._id,
               deleteType,
            },
         },
      })
         .then(
            ({
               data: {
                  deletePlatform: {
                     data: { metaData },
                  },
               },
            }) => {
               enqueueSnackbar(`${metaData.name} ${t?.general_information_deleted_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               });
            },
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const handleCloseModal = (refresh = false) => {
      setCreateModalConfig(initialState);
      setDeleteModalConfig(initialState);
      if (refresh === true) {
         getTeams({
            variables: {
               ...getTeamsParam,
            },
         });
      }
   };

   const handleOpenCreateModal = () => {
      setCreateModalConfig((prevState) => ({
         ...prevState,
         mode: 'CREATE',
         show: true,
      }));
   };
   const handleOpenModalDelete = (data) => {
      setEditData(data);
      setDeleteModalConfig((prevState) => ({
         ...prevState,
         mode: 'CREATE',
         show: true,
      }));
   };

   const updateAccountCommentFunction = (page) => {
      updateAccountMutation({
         variables: {
            type: PLATFORM_TYPE.FACEBOOK,
            metaData: {
               _id: page._id,
               isCommentEnable: !page.isCommentEnable,
            },
         },
      })
         .then(({ data }) => {
            enqueueSnackbar(t?.general_information_updated_successfully, {
               variant: 'success',
               autoHideDuration: 2500,
            });
         })
         .catch((err) => {
            enqueueSnackbar(err.message, {
               variant: 'error',
               autoHideDuration: 2500,
            });
         });
   };

   const onEdit = (data) => {
      setEditData(data);
      setModalMode('EDIT');
      handleOpenCreateModal();
   };
   const updateAccountMessageFunction = (page) => {
      updateAccountMutation({
         variables: {
            type: PLATFORM_TYPE.FACEBOOK,
            metaData: {
               _id: page._id,
               isMessageEnable: !page.isMessageEnable,
            },
         },
      })
         .then(({ data }) => {
            enqueueSnackbar(t?.general_information_updated_successfully, {
               variant: 'success',
               autoHideDuration: 2500,
            });
         })
         .catch((err) => {
            enqueueSnackbar(err.message, {
               variant: 'error',
               autoHideDuration: 2500,
            });
         });
   };

   const loadingOrFetching =
      updateAccountLoading ||
      teamsLoading ||
      personsLoading ||
      loadingPlatform1 ||
      loadingPlatform2 ||
      loadingPlatform3 ||
      loadingPlatform4 ||
      loadingPlatform5 ||
      loadingPlatform6 ||
      loadingPlatform7 ||
      loadingPlatform8 ||
      loadingPlatform9 ||
      loadingPlatform10;

   if (loadingOrFetching) {
      return <div className='loading' />;
   }

   const columns = [
      {
         field: 'team',
         headerName: t['team'],
         flex: 1,
         minWidth: 150,
         headerAlign: 'center',
         renderCell: ({ row }) => {
            return (
               <Grid
                  center
                  style={{ flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
               >
                  <div
                     className={classes.divContainer}
                     style={{ backgroundColor: row.team.color ? row.team.color : 'red' }}
                  >
                     <Typography className={classes.avatarText}>
                        {row.team.teamName.split(' ').length > 1
                           ? row.team.teamName.split(' ')[0][0] + row.team.teamName.split(' ')[1][0]
                           : row.team.teamName.split(' ')[0][0] + row.team.teamName.split(' ')[0][1]}
                     </Typography>
                  </div>
                  <Typography style={{ marginLeft: 10 }}>{row.team.teamName}</Typography>
               </Grid>
            );
         },
      },
      {
         field: 'users',
         headerName: t['users'],
         flex: 1,
         minWidth: 150,
         headerAlign: 'center',
         sortable: false,
         renderCell: ({ row }) => {
            return (
               <Grid container justifyContent='center' alignItems='center'>
                  <AvatarGroup total={row?.users.customerPersons?.length} max={3}>
                     {row?.users.customerPersons?.map((customerPerson) => (
                        <Avatar key={customerPerson._id.toString()} src={customerPerson.photo} />
                     ))}
                  </AvatarGroup>
               </Grid>
            );
         },
      },
      {
         field: 'channels',
         headerName: t['channels'],
         flex: 1,
         minWidth: 150,
         sortable: false,
         headerAlign: 'center',
         renderCell: ({ row }) => {
            return (
               <Grid container justifyContent='center' alignItems='center'>
                  <Typography>
                     {row?.channels.length || 0} {t.channels_with_number}
                  </Typography>
               </Grid>
            );
         },
      },
      {
         field: 'operation',
         headerName: t['page_settings_process'],
         flex: 1,
         sortable: false,
         headerAlign: 'center',
         align: 'end',
         minWidth: 150,
         renderCell: ({ row }) => {
            return (
               <Grid container justifyContent='center' alignItems='center'>
                  <div
                     style={{
                        display: 'flex',
                        boxSizing: 'border-box',
                        marginBottom: '-15px',
                     }}
                  >
                     {row.users.isMain && <Typography>{t?.main_team_cannot_deleted}</Typography>}
                  </div>

                  {!row.users.isMain && (
                     <ActionIconButton
                        onClick={() => {
                           handleOpenModalDelete(row);
                        }}
                        tooltip={t?.comp_button_delete}
                        actionType='DELETE'
                     />
                  )}
                  <ActionIconButton onClick={() => onEdit(row)} tooltip={t?.comp_button_edit} actionType='EDIT' />
               </Grid>
            );
         },
      },
   ];

   return (
      <Fragment>
         <TableData
            teamsLoading={teamsLoading}
            Page={Page}
            setQueryParams={setGetTeamsParam}
            queryParams={getTeamsParam}
            teamList={teamsList}
            teamCount={teamsCount}
            setModalMode={setModalMode}
            handleOpenCreateModal={handleOpenCreateModal}
            text={text}
            setText={setText}
            person={person}
            tableData={teamsData?.getTeamList?.data.docs}
            handleDelete={handleDeleteFacebookPage}
            columns={columns}
         />

         <Modal
            open={CreateModalConfig.show}
            onClose={handleCloseModal}
            modalHeader={modalMode === 'EDIT' ? t['edit_team'] : t['create_new_team']}
         >
            <div>
               <CreateModalContent
                  editData={editData}
                  me={me}
                  customerPersons={personsData?.customerPersons?.data?.docs}
                  allPlatforms={allPlatforms}
                  onClose={handleCloseModal}
                  mode={modalMode}
               />
            </div>
         </Modal>
         <Modal open={DeleteModalConfig.show} onClose={handleCloseModal} modalHeader={t?.delete_team}>
            <div>
               <DeleteModalContent
                  teamsData={teamsData?.getTeamList?.data?.docs}
                  me={me}
                  editData={editData}
                  onClose={handleCloseModal}
               />
            </div>
         </Modal>
      </Fragment>
   );
};

export default TeamPage;

import { LanguageContext } from '@helper/locale/langContext';
import { Button, Paper, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
   pageLayoutContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'hidden',
   },
   mainContainer: {
      flex: 3,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: 15,
      overflow: 'hidden',
   },
   previewContainer: {
      flex: 3,
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '20px',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      margin: '1rem 1.8rem',
   },
}));

const PageLayout = ({ nav, form, previews, onSubmit, isNewSurvey }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const history = useHistory();

   return (
      <div className={classes.pageLayoutContainer}>
         <div className={classes.header}>
            <div style={{ display: 'flex' }}>
               <Link to='/settings/company/customer-satisfaction' style={{ marginTop: 5 }}>
                  <ArrowBackIcon color='disabled' />
               </Link>
               <Typography
                  style={{
                     marginLeft: 10,
                     fontSize: '1.1rem',
                     fontWeight: 'bold',
                     lineHeight: '35px',
                  }}
               >
                  {t['page_settings_typo_customer_satisfaction']}
               </Typography>
            </div>
            <div style={{ display: 'flex', gap: '0.5rem' }}>
               <Button
                  className={classes.modalButtons}
                  style={{
                     height: '40px',
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
                  onClick={() => {
                     history.push('/settings/company/customer-satisfaction');
                  }}
               >
                  {t?.cancel}
               </Button>
               <Button
                  className={classes.modalButtons}
                  style={{
                     height: '40px',
                     color: 'white',
                     backgroundColor: '#157CFC',
                     '&:hover': {
                        backgroundColor: '#105CBB',
                     },
                     float: 'right',
                  }}
                  onClick={onSubmit}
               >
                  {isNewSurvey ? t?.save : t?.update}
               </Button>
            </div>
         </div>
         <div style={{ display: 'flex', height: '100%' }}>
            <Paper elevation={1} className={classes.mainContainer}>
               {form}
            </Paper>
            <div className={classes.previewContainer}>
               {nav}
               {previews}
            </div>
         </div>
      </div>
   );
};

export default PageLayout;

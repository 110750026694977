import { Button, Grid, Paper, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import { useHistory } from 'react-router-dom';
import { WhatsappIcon } from '@assets/icons/SettingsIcons/settingsIcons';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 600,
      padding: 20,
      paddingBottom: 5,
      borderRadius: 15,
      // width: 700,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   iconSize: {
      fontSize: '5rem',
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 30,
      backgroundColor: null,
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   selector: {
      textAlign: 'left',
      border: 'solid 1px lightgrey',
      borderRadius: 5,
      paddingLeft: 3,
      height: 32,
   },
   stepLabel: {
      fontSize: '1.2rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   stepContainer: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      borderRadius: 10,
   },
   informationListItem: {
      fontSize: '1rem',
   },
   btnNext: {
      marginTop: 30,
   },
   confirmButtonContainer: {
      display: 'inline-block',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      backgroundColor: 'rgba(90, 193, 157, 1)',
      padding: 5,
      borderRadius: 5,
      marginRight: 2,
      marginLeft: 2,
      transition: 'all .5s',
      '&:hover': {
         cursor: 'pointer',
         backgroundColor: '#266650',
      },
   },
   resultContainer: {
      width: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      padding: 5,
      borderRadius: 5,
   },
   statusTitle: {
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: 'bold',
   },
   informationTitle: {
      marginTop: 20,
      fontSize: '1.1rem',
   },
}));

const ModalContent = (props) => {
   const classes = useStyles();
   const { whatsAppBusinessPhone, onAccept, onCancel, mode, status, loading } = props;
   const [phoneCode, setPhoneCode] = useState('');
   const { handleSubmit, control } = useForm({
      defaultValues: {
         _id: mode === 'EDIT' ? whatsAppBusinessPhone._id : '',
         name: mode === 'EDIT' ? whatsAppBusinessPhone.name : '',
         number: mode === 'EDIT' ? whatsAppBusinessPhone.number : '',
      },
   });

   // Language Context
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   // only for test
   return (
      <Grid container item xs={12}>
         <Paper className={classes.root} elevation={3}>
            <Typography style={{ textAlign: 'center', fontWeight: 'bold', fontSize: '1.4rem', marginBottom: 15 }}>
               {t.settings_whatsapp_b_bsp_migration_title}
            </Typography>
            <Divider />
            <Grid item style={{ textAlign: 'left', marginTop: 15 }}>
               <Typography style={{ fontSize: '1.1rem', fontWeight: 'bold', marginBottom: 5 }}>
                  {t.settings_whatsapp_b_migrate_to_qpien}
               </Typography>
               <Typography style={{ fontSize: '0.9rem', marginBottom: 15 }}>
                  {t.settings_whatsapp_b_migrate_description}
               </Typography>
               <Typography style={{ fontSize: '0.9rem' }}>{t.settings_whatsapp_b_migrate_step_1}</Typography>
               <Typography style={{ fontSize: '0.9rem' }}>{t.settings_whatsapp_b_migrate_step_2}</Typography>
               <Typography style={{ fontSize: '0.9rem' }}>{t.settings_whatsapp_b_migrate_step_3}</Typography>
            </Grid>
            <Grid
               item
               container
               justifyContent='space-between'
               style={{ marginTop: 50, marginBottom: 25 }}
               className={classes.btnNext}
            >
               <Button
                  variant='outlined'
                  style={{
                     textTransform: 'none',
                     border: '1px solid #B9D0E5',
                     fontSize: '1.1rem',
                     width: 128,
                     color: '#748FA7',
                     height: 40,
                  }}
                  onClick={props.onCancel}
               >
                  {t?.comp_button_cancel}
               </Button>
               <ButtonOK
                  label={t?.contact_us}
                  onClick={props.onAccept}
                  width={128}
                  height={40}
                  fontSize='1.1rem'
                  type='submit'
               />
            </Grid>
         </Paper>
      </Grid>
   );
};

export default ModalContent;

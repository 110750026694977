import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import quillEmoji from 'react-quill-emoji';
import 'react-quill-emoji/dist/quill-emoji.css';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles((theme) => ({
   inputLabel: {
      fontSize: '1.2rem',
      color: (props) => props.labelColor || '',
      '&.focused': {
         color: theme.palette.primary.main,
      },
   },
}));

const RichTextReply = (props) => {
   //Quill.register('modules/imageResize', ImageResize);
   Quill.register(
      {
         'formats/emoji': quillEmoji.EmojiBlot,
         'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
         'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
         'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
      },
      true,
   );

   const { label, placeholder, emailSignature, text, isNewModal } = props;

   const [value, setValue] = useState(`${text}${emailSignature || ''}`);
   const editorRef = useRef(null);

   useEffect(() => {
      if (editorRef.current && !isNewModal) {
         editorRef.current.focus();
      }
   }, [value]);

   useEffect(() => {
      setValue(`${text}${emailSignature || ''}`);
   }, [emailSignature]);

   const classes = useStyles(props);
   const modules = {
      toolbar: {
         container: [
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['emoji'],
         ],
      },
      'emoji-toolbar': true,
      'emoji-textarea': false,
      'emoji-shortname': true,
      //imageResize: {}, // Add the imageResize module
   };

   const formats = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'link',
      'clean',
      'emoji',
      'image',
   ];

   const handleChange = (data) => {
      setValue(data);
      props.onValueChange && props.onValueChange(data);
   };

   return (
      <div>
         <Grid
            container
            alignItems='center'
            style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}
         >
            <Grid>
               <Typography className={classes.inputLabel}>{label}</Typography>
            </Grid>
         </Grid>
         <div className='module-richTextReply'>
            <ReactQuill
               theme='snow'
               value={value}
               onChange={handleChange}
               modules={modules}
               formats={formats}
               className={classes.editorContainer}
               placeholder={placeholder}
               ref={editorRef}
            />
         </div>
      </div>
   );
};

export default RichTextReply;

import React from 'react';

export const NotificationIcon: React.FC<{ className?: string; fill?: string }> = ({ className, fill }) => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' className={className} fill={fill} viewBox='0 0 19.192 21.225'>
         <g id='Group_420' data-name='Group 420' transform='translate(0.1 0.12)'>
            <path
               id='Path_1'
               data-name='Path 1'
               d='M599.354-174.042c-.059.269-.094.526-.171.77a2.481,2.481,0,0,1-1.916,1.784,2.6,2.6,0,0,1-3.142-2.052,3.718,3.718,0,0,1-.057-.376c-.008-.1-.045-.13-.147-.129-.638,0-1.277,0-1.915,0q-1.921-.013-3.841-.03a.844.844,0,0,1-.881-.68,2.259,2.259,0,0,1-.088-.585,6.729,6.729,0,0,1,.062-1.006,2,2,0,0,1,.751-1.284c.161-.129.313-.269.477-.393a3.449,3.449,0,0,0,1.142-1.633,11.585,11.585,0,0,0,.548-2.418,20.517,20.517,0,0,1,.784-3.777,5.483,5.483,0,0,1,3.219-3.466c.2-.082.4-.158.6-.221.1-.032.126-.076.089-.171a1.961,1.961,0,0,1-.132-.815,1.977,1.977,0,0,1,1.523-1.845,1.979,1.979,0,0,1,2.144.969,1.9,1.9,0,0,1,.153,1.561c-.075.245-.069.252.182.335a5.72,5.72,0,0,1,2.668,1.794,6.137,6.137,0,0,1,1.21,2.466,26.785,26.785,0,0,1,.6,3.224,9.93,9.93,0,0,0,.689,2.71,3.092,3.092,0,0,0,.947,1.244c.169.132.327.276.495.409a2.18,2.18,0,0,1,.834,1.669,8.292,8.292,0,0,1-.018,1.008,1.123,1.123,0,0,1-.324.674.692.692,0,0,1-.529.229c-.583.007-1.165.021-1.748.024q-2.046.011-4.091.015Zm5.577-1.323c-.032-.254-.058-.493-.092-.73a.806.806,0,0,0-.33-.556c-.149-.111-.287-.238-.433-.353a4.4,4.4,0,0,1-1.485-2.039,13.175,13.175,0,0,1-.464-1.559c-.14-.662-.213-1.337-.317-2.007a16.431,16.431,0,0,0-.648-2.9,4.193,4.193,0,0,0-2.517-2.639,5.362,5.362,0,0,0-2.984-.25,4.237,4.237,0,0,0-3.5,3.066,16.824,16.824,0,0,0-.655,3.211,13.054,13.054,0,0,1-.645,2.894,4.5,4.5,0,0,1-1.16,1.871c-.268.247-.562.465-.835.705a.941.941,0,0,0-.245.3,2.776,2.776,0,0,0-.154.985Zm-9.547,1.331a1.36,1.36,0,0,0,1.247,1.295A1.352,1.352,0,0,0,598-174.034Zm1.957-16.407a.658.658,0,0,0-.656-.671.652.652,0,0,0-.642.657.676.676,0,0,0,.657.649A.671.671,0,0,0,597.341-190.441Z'
               transform='translate(-587.195 192.424)'
               // fill='#97a4b1'
               // stroke='#7c819d'
               strokeWidth='0.2'
            />
         </g>
      </svg>
   );
};

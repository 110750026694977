import { useQuery } from '@apollo/client';
import { QuestionMessageLeftIcon } from '@assets/icons/QuestionIcons';
import { LanguageContext } from '@helper/locale/langContext';
import { CircularProgress, Grid, LinearProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GET_CUSTOMER_QUESTIONS_QUERY, GET_MY_QUESTIONS_QUERY } from '@queries/Questions/Service/query';
import { SET_MY_CUSTOMER_QUESTIONS, SET_MY_QUESTIONS } from '@store/actions/questions';
import {
   getActiveQuestion,
   getMyCustomerQuestionsState,
   getMyQuestionsState,
   getQnaChatFilters,
} from '@store/reducers/questions';
import { MyTheme } from '@styles/config';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import QuestionItem from './QuestionItem/questionLeftPanelItem';
import LeftPanelToolbar from './Toolbar';
import useCommonQueriesOfChats from '@hooks/useCommonQueriesOfChats';
import { ChatListTab } from '@components/ChatListTabs/ChatListTabs.types';
import moment from 'moment';

const useStyles = makeStyles((theme: MyTheme) => ({
   container: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      width: 'inherit',
   },
   chatContainer: {
      marginTop: 5,
      width: '100%',
      overflowY: 'auto',
      height: 'calc(100vh - 167px)',
   },
   stickyBar: {
      position: 'sticky',
      top: 0,
      zIndex: 99,
      background: theme.chatApp.general.pallet.passiveStructureGrey,
   },
   leftIcon: {
      width: 100,
      height: 100,
   },
   noChatContainer: {
      width: '100%',
      height: '100%',
      marginTop: 200,
   },
   textMessage: {
      fontWeight: 'bold',
      marginTop: 25,
      color: '#ccd8e8',
      fontSize: '1.2rem',
   },
}));

const LeftPanel: React.FC = (props) => {
   const classes = useStyles();
   const dispatch = useDispatch();
   const activeQuestion = useSelector(getActiveQuestion);
   const customerQuestions = useSelector(getMyCustomerQuestionsState);
   const myQuestions = useSelector(getMyQuestionsState);
   const qnaChatFilters = useSelector(getQnaChatFilters);
   const { enqueueSnackbar } = useSnackbar();
   const {
      lang: { translation: t },
   }: any = useContext(LanguageContext);
   const [myChatTab, setMyChatTab] = useState<ChatListTab>(ChatListTab.COMPANY);
   const [isVisibleIconTooltip, setIsVisibleIconTooltip] = useState<boolean>(false);

   const { loading: customerQuestionsLoading, fetchMore: fetchMoreCustomerQuestions } = useQuery(
      GET_CUSTOMER_QUESTIONS_QUERY,
      {
         variables: {
            platformType: qnaChatFilters?.platformType === 'ALL' ? undefined : qnaChatFilters?.platformType,
            teams: qnaChatFilters?.teams?.value || 'ALL',
            startDate: moment(qnaChatFilters?.startDate),
            endDate: moment(qnaChatFilters?.endDate),
            status: qnaChatFilters?.status === 'ALL' ? undefined : qnaChatFilters?.status,
            text: qnaChatFilters?.text,
            tag: qnaChatFilters?.tag === 'ALL' ? undefined : qnaChatFilters?.tag,
            desc: qnaChatFilters?.desc === 'desc',
         },
         fetchPolicy: 'no-cache',
         onCompleted: (data) => {
            if (data) {
               dispatch({
                  type: SET_MY_CUSTOMER_QUESTIONS,
                  payload: {
                     questions: data?.QnAChats?.data?.QnAChats,
                     totalDocs: data?.QnAChats?.data?.totalDocs,
                     // page: data?.QnAChats?.data?.page,
                     hasNextPage: data?.QnAChats?.data?.hasNextPage,
                     hasPrevPage: data?.QnAChats?.data?.hasPrevPage,
                     limit: data?.QnAChats?.data?.limit,
                  },
               });
            }
         },
         onError: (error) => {
            enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 2500 });
         },
      },
   );

   const { loading: myQuestionsLoading, fetchMore: fetchMoreMyQuestions } = useQuery(GET_MY_QUESTIONS_QUERY, {
      variables: {
         me: qnaChatFilters?.me,
         platformType: qnaChatFilters?.platformType === 'ALL' ? undefined : qnaChatFilters?.platformType,
         startDate: moment(qnaChatFilters?.startDate),
         endDate: moment(qnaChatFilters?.endDate),
         status: undefined,
         text: qnaChatFilters?.text,
         tag: qnaChatFilters?.tag === 'ALL' ? undefined : qnaChatFilters?.tag,
         desc: qnaChatFilters?.desc === 'desc',
      },
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         if (data) {
            dispatch({
               type: SET_MY_QUESTIONS,
               payload: {
                  questions: data?.getMyQnAChats?.data?.QnAChats,
                  totalDocs: data?.getMyQnAChats?.data?.totalDocs,
                  page: data?.getMyQnAChats?.data?.page,
                  hasNextPage: data?.getMyQnAChats?.data?.hasNextPage,
                  hasPrevPage: data?.getMyQnAChats?.data?.hasPrevPage,
                  limit: data?.getMyQnAChats?.data?.limit,
               },
            });
         }
      },
      onError: (error) => {
         enqueueSnackbar(error.message, { variant: 'error', autoHideDuration: 2500 });
      },
   });

   const { teams, agents, tags } = useCommonQueriesOfChats();

   const list = myChatTab === ChatListTab.COMPANY ? customerQuestions : myQuestions;

   const hasNext = useCallback(() => {
      if (myChatTab === ChatListTab.YOURS) {
         fetchMoreMyQuestions({
            variables: {
               page: myQuestions.questions.length || 0,
            },
         })
            .then(
               ({
                  data: {
                     getMyQnAChats: { data: myQuestionsData },
                  },
               }: any) => {
                  const newData = [...list.questions, ...myQuestionsData.QnAChats];
                  dispatch({
                     type: SET_MY_QUESTIONS,
                     payload: {
                        questions: newData,
                        totalDocs: newData.length,
                        // page: myQuestionsData.page,
                        hasNextPage: myQuestionsData.hasNextPage,
                        limit: myQuestionsData.limit,
                     },
                  });
               },
            )
            .catch((err) => console.log(err));
      } else {
         fetchMoreCustomerQuestions({
            variables: {
               page: customerQuestions.questions.length || 0,
            },
         })
            .then(
               ({
                  data: {
                     QnAChats: { data: myCustomerQuestionsData },
                  },
               }: any) => {
                  const newData = [...list.questions, ...myCustomerQuestionsData.QnAChats];
                  dispatch({
                     type: SET_MY_CUSTOMER_QUESTIONS,
                     payload: {
                        questions: newData,
                        totalDocs: newData.length,
                        // page: myCustomerQuestionsData.page,
                        hasNextPage: myCustomerQuestionsData.hasNextPage,
                        limit: myCustomerQuestionsData.limit,
                     },
                  });
               },
            )
            .catch((err) => console.log(err));
      }
   }, [
      myChatTab,
      customerQuestions.page,
      dispatch,
      fetchMoreCustomerQuestions,
      fetchMoreMyQuestions,
      list.questions,
      myQuestions.page,
   ]);

   const queryLoading = customerQuestionsLoading || myQuestionsLoading;

   return (
      <div className={classes.container}>
         <Grid container xs={12} className={classes.stickyBar}>
            <LeftPanelToolbar
               teams={teams}
               agents={agents}
               tags={tags}
               currentTab={myChatTab}
               onTabChange={setMyChatTab}
            />
            {queryLoading && (
               <Grid
                  container
                  style={{
                     marginTop: 8,
                  }}
                  alignItems='center'
                  justifyContent='center'
                  xs={12}
                  direction='column'
               >
                  <LinearProgress style={{ width: '100%' }} color='primary' />
               </Grid>
            )}
         </Grid>
         {list?.questions && list?.questions?.length > 0 ? (
            <div id='scrollableDiv' className={`${classes.chatContainer}`}>
               <InfiniteScroll
                  next={hasNext}
                  hasMore={list.hasNextPage}
                  dataLength={list?.questions?.length || 0}
                  scrollableTarget='scrollableDiv'
                  loader={
                     <Grid
                        container
                        style={{
                           height: 70,
                        }}
                        justifyContent='center'
                        alignItems='center'
                     >
                        <CircularProgress size={24} color='primary' />
                     </Grid>
                  }
               >
                  {list?.questions?.map((myChat: any, index: number) => (
                     <Link
                        onClick={(e) => {
                           if (activeQuestion?._id.toString() === myChat._id.toString()) {
                              // e.preventDefault();
                           }
                        }}
                        key={myChat._id + index}
                        to={`/questions/${myChat._id}`}
                     >
                        <QuestionItem
                           isVisibleIconTooltip={isVisibleIconTooltip}
                           setIsVisibleIconTooltip={setIsVisibleIconTooltip}
                           myChat={myChat}
                           teams={teams}
                           renderAgentPhoto={myChatTab === ChatListTab.YOURS}
                        />
                     </Link>
                  ))}
               </InfiniteScroll>
            </div>
         ) : (
            <Grid
               xs={12}
               justifyContent='center'
               alignItems='center'
               container
               direction='column'
               className={classes.noChatContainer}
            >
               <QuestionMessageLeftIcon className={classes.leftIcon} />
               <Typography className={classes.textMessage}>{t['page_chat_typo_no_chat_to_show']}</Typography>
            </Grid>
         )}
      </div>
   );
};

export default LeftPanel;

import React, { Fragment, useContext, useEffect, useRef, useState } from 'react';
import {
   Avatar,
   ClickAwayListener,
   IconButton,
   InputAdornment,
   Menu,
   MenuItem,
   TextField,
   Typography,
   Popover,
   Tooltip,
} from '@material-ui/core';
import cn from 'classnames';
import { FilterIcon } from '@assets/icons/ChatPageIcons';
import { useDispatch } from 'react-redux';
import FilterBox from './FilterBox';
import SortIcon from '@material-ui/icons/Sort';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';

const FIRST_ROW_HEIGHT = 44;

const useStyles = makeStyles((theme) => ({
   errorContainer: {
      border: '1px solid red',
      color: 'red',
      borderRadius: 5,
      padding: 5,
      marginTop: 6,
      marginBottom: 6,
   },
   errorText: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
   },
   toolbarFirstRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 5,
   },
   iconButtonContainer: {
      flexShrink: 0,
   },
   iconButtonContainer2: {
      flexShrink: 0,
      '&..MuiPopover-paper': {
         backgroundColor: 'red',
      },
   },
   iconButton: {
      height: FIRST_ROW_HEIGHT,
      width: FIRST_ROW_HEIGHT,
      backgroundColor: '#fff',
      borderRadius: 6,
      border: '1px solid #e8e9ed',
   },
   createEmailButton: {
      height: FIRST_ROW_HEIGHT,
      width: FIRST_ROW_HEIGHT,
      backgroundColor: theme.chatApp.general.pallet.passiveStructureAzure,
      borderRadius: 6,
      color: '#fff',
      border: '1px solid #e8e9ed',
      '&:hover': {
         backgroundColor: '#116ada',
      },
      '&:disabled': {
         backgroundColor: 'rgba(21, 124, 252, 0.7)',
         color: '#fff',
      },
   },
   iconButtonActive: {
      backgroundColor: '#fff !important',
      // borderWidth: 1,
      // borderColor: theme.chatApp.general.pallet.passiveStructureAzure,
      // '&:hover': {
      //    borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
      //    backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
      // },
   },
   icon: {
      // color: 'red',
   },
   iconActive: {
      color: '#147BFB',
   },
   backspaceIcon: {
      width: 20,
      height: 20,
      color: '#9b9b9b',
   },
   teamMenuInput: {
      height: FIRST_ROW_HEIGHT,
      backgroundColor: '#fff',
      borderRadius: 6,
      paddingRight: 5,
      paddingLeft: 5,
      minWidth: 0,
      width: '100%',
      border: '1px solid #e8e9ed',
   },
   teamMenuInputActive: {
      backgroundColor: '#fff !important',
   },
   teamAvatar: {
      width: 30,
      height: 30,
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlack,
      marginRight: 8,
      fontSize: 16,
   },
   teamLabel: {
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: '500',
   },
   searchbar: {
      display: 'flex',
      backgroundColor: '#fff',
      borderRadius: 6,
      flexGrow: 1,
      '&.MuiTextField-root': {
         margin: '0 !important',
      },
   },
   filterBoxPopover: {
      '& .MuiPaper-root': {
         overflow: 'visible',
      },
   },
   teamRightIcon: {
      width: 18,
      height: 18,
      marginRight: 6,
   },
   teamRightIcon: {
      width: 18,
      height: 18,
      marginRight: 6,
   },
   selectTeamContainer: {
      flexGrow: 1,
   },
}));

const ChatFilters = ({
   chatType,
   chatFilters,
   teams,
   agents,
   tags,
   statusList,
   onSearch,
   onChangeSort,
   onSelectTeam,
   emailAccounts,
   whatsappWebAccounts,
   whatsappBusinessAccounts,
   isQuestionTab, // Dont show createNewEmail button in QnA
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const dispatch = useDispatch();
   const toolbarFirstRowRef = useRef(null);
   const [anchorEl, setAnchorEl] = useState(null);
   const [anchorElNewChat, setAnchorElNewChat] = useState(null); // [TODO
   const openSortMenu = Boolean(anchorEl);
   const openNewChatMenu = Boolean(anchorElNewChat);
   const [teamMenuAnchorEl, setTeamMenuAnchorEl] = useState(null);
   const [filterAnchorEl, setFilterAnchorEl] = useState(null);
   const [showSearchbar, setShowSearchbar] = useState(!!chatFilters?.text);
   const [searchInputText, setSearchInputText] = useState(chatFilters?.text || '');
   const [selectedTeam, setSelectedTeam] = useState(
      chatFilters?.teams || {
         value: 'ALL',
         label: t?.page_chat_all_teams,
         backgroundColor: '#000',
      },
   );

   const handleFilterClose = () => {
      setFilterAnchorEl(null);
   };

   const handleSortIconClick = (event) => {
      setAnchorEl(event.currentTarget);
   };

   const handleSortClose = () => {
      setAnchorEl(null);
   };

   const handleNewChatIconClick = (event) => {
      setAnchorElNewChat(event.currentTarget);
   };

   const handleNewChatClose = () => {
      setAnchorElNewChat(null);
   };

   const toggleSearchbar = () => {
      if (showSearchbar === true) {
         onSearch('');
      }
      setShowSearchbar((prevState) => !prevState);
      setSearchInputText('');
   };

   const handleSearchInputClose = () => {
      if (searchInputText === '') {
         onSearch('');
         setShowSearchbar(false);
      }
   };

   const handleTeamMenuClose = () => {
      setTeamMenuAnchorEl(null);
   };

   const onClickSearchDelete = () => {
      setSearchInputText('');
   };

   const handleNewEmailModal = () => {
      dispatch({
         type: 'CREATE_EMAIL_MODAL_VISIBLE',
         payload: true,
      });
   };

   const handleNewWhatsappModal = () => {
      dispatch({
         type: 'CREATE_WHATSAPP_MODAL_VISIBLE',
         payload: true,
      });
   };

   useEffect(() => {
      const timer = setTimeout(() => {
         onSearch(searchInputText);
      }, 500);
      return () => clearTimeout(timer);
   }, [searchInputText, onSearch]);

   useEffect(() => {
      onSelectTeam(selectedTeam);
      handleTeamMenuClose();
   }, [selectedTeam]);

   return (
      <div ref={toolbarFirstRowRef} className={classes.toolbarFirstRow}>
         {!showSearchbar && (
            <Fragment>
               <div style={{ flexGrow: 1, display: 'flex', minWidth: 0 }}>
                  <MenuItem
                     disableRipple
                     disableTouchRipple
                     onClick={(e) => setFilterAnchorEl(e.currentTarget)}
                     className={cn(classes.teamMenuInput, Boolean(teamMenuAnchorEl) && classes.teamMenuInputActive)}
                  >
                     <div
                        style={{
                           flexGrow: 1,
                           width: '100%',
                           minWidth: 0,
                           display: 'flex',
                           flexDirection: 'row',
                           alignItems: 'center',
                        }}
                     >
                        <Avatar
                           className={classes.teamAvatar}
                           style={{ backgroundColor: chatFilters?.teams?.backgroundColor }}
                        >
                           {chatFilters?.teams?.label?.slice(0, 2)}
                        </Avatar>
                        <Typography variant='body2' noWrap={true} className={classes.teamLabel}>
                           {chatFilters?.teams?.label}
                        </Typography>
                        <div className={classes.teamRightIcon}>
                           <FilterIcon
                              className={classes.icon}
                              fill={Boolean(filterAnchorEl) ? '#147BFB' : '#0000008a'}
                           />
                        </div>
                     </div>
                  </MenuItem>
                  <Popover
                     open={Boolean(filterAnchorEl)}
                     onClose={handleFilterClose}
                     anchorEl={toolbarFirstRowRef.current}
                     getContentAnchorEl={null}
                     anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                     className={classes.filterBoxPopover}
                     style={{ marginTop: 5 }}
                  >
                     <FilterBox
                        onFilterClose={handleFilterClose}
                        setSelectedTeam={setSelectedTeam}
                        selectedTeam={selectedTeam}
                        onSelectTeam={onSelectTeam}
                        teams={teams}
                        chatType={chatType}
                        chatFilters={chatFilters}
                        tags={tags}
                        agents={agents}
                        statusList={statusList}
                        width={toolbarFirstRowRef.current?.offsetWidth}
                     />
                  </Popover>
               </div>
               {!isQuestionTab && (
                  <div className={classes.iconButtonContainer}>
                     <IconButton
                        onClick={handleSortIconClick}
                        disableFocusRipple
                        disableRipple
                        className={cn(classes.iconButton, openSortMenu && classes.iconButtonActive)}
                     >
                        <SortIcon className={cn(classes.icon, openSortMenu && classes.iconActive)} />
                     </IconButton>

                     <Menu
                        id='long-menu'
                        anchorEl={anchorEl}
                        keepMounted
                        open={openSortMenu}
                        onClose={handleSortClose}
                        anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'center',
                        }}
                        transformOrigin={{
                           vertical: 'top',
                           horizontal: 'center',
                        }}
                        getContentAnchorEl={null}
                        style={{ marginTop: 5 }}
                     >
                        <MenuItem
                           onClick={() => {
                              onChangeSort('desc');
                              handleSortClose();
                           }}
                           selected={chatFilters.desc === 'desc'}
                        >
                           <Typography className={classes.sortingText} variant='body1'>
                              {t?.last_received}
                           </Typography>
                        </MenuItem>
                        <MenuItem
                           onClick={() => {
                              onChangeSort('asc');
                              handleSortClose();
                           }}
                           selected={chatFilters.desc === 'asc'}
                        >
                           <Typography className={classes.sortingText} variant='body1'>
                              {t?.first_received}
                           </Typography>
                        </MenuItem>
                     </Menu>
                  </div>
               )}
            </Fragment>
         )}

         {showSearchbar && (
            <ClickAwayListener onClickAway={handleSearchInputClose}>
               <TextField
                  id='outlined-basic'
                  variant='outlined'
                  placeholder={t?.page_chat_search_placeholder}
                  className={classes.searchbar}
                  autoFocus={true}
                  value={searchInputText}
                  onChange={(e) => {
                     setSearchInputText(e.target.value || '');
                  }}
                  onKeyDown={(e) => {
                     if (e.key === 'Enter') {
                        onSearch(searchInputText);
                     }
                  }}
                  InputProps={{
                     endAdornment: !!searchInputText && (
                        <InputAdornment position='end' onClick={onClickSearchDelete}>
                           <BackspaceIcon className={classes.backspaceIcon} />
                        </InputAdornment>
                     ),
                  }}
               />
            </ClickAwayListener>
         )}

         <div className={classes.iconButtonContainer}>
            <IconButton
               onClick={toggleSearchbar}
               disableFocusRipple
               disableRipple
               className={cn(classes.iconButton, showSearchbar && classes.iconButtonActive)}
            >
               {showSearchbar ? <CloseIcon className={classes.icon} /> : <SearchIcon className={classes.icon} />}
            </IconButton>
         </div>
         {!isQuestionTab &&
            (emailAccounts?.length > 0 || whatsappBusinessAccounts?.length > 0 || whatsappWebAccounts?.length > 0 ? (
               <div className={classes.iconButtonContainer2}>
                  <IconButton
                     onClick={handleNewChatIconClick}
                     disableFocusRipple
                     disableRipple
                     className={cn(classes.createEmailButton)}
                  >
                     <CreateIcon />
                  </IconButton>
                  <Menu
                     id='long-menu'
                     anchorEl={anchorElNewChat}
                     keepMounted
                     open={openNewChatMenu}
                     onClose={handleNewChatClose}
                     anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                     }}
                     transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                     }}
                     getContentAnchorEl={null}
                     style={{ marginTop: 5 }}
                  >
                     {emailAccounts?.length > 0 && (
                        <MenuItem
                           onClick={() => {
                              if (!emailAccounts || emailAccounts?.length === 0) return;
                              handleNewEmailModal();
                              handleNewChatClose();
                           }}
                        >
                           <Typography className={classes.sortingText} variant='body1'>
                              {t?.new_email_message}
                           </Typography>
                        </MenuItem>
                     )}
                     {(whatsappWebAccounts?.length > 0 || whatsappBusinessAccounts?.length > 0) && (
                        <MenuItem
                           onClick={() => {
                              if (
                                 (!whatsappBusinessAccounts || whatsappBusinessAccounts?.length === 0) &&
                                 (!whatsappWebAccounts || whatsappWebAccounts?.length === 0)
                              )
                                 return;
                              handleNewWhatsappModal();
                              handleNewChatClose();
                           }}
                        >
                           <Typography className={classes.sortingText} variant='body1'>
                              {t?.whatsapp_message}
                           </Typography>
                        </MenuItem>
                     )}
                  </Menu>
               </div>
            ) : (
               <Tooltip title={t['create_new_contact_account_not_found']}>
                  <div className={classes.iconButtonContainer2}>
                     <IconButton disabled disableFocusRipple disableRipple className={cn(classes.createEmailButton)}>
                        <CreateIcon />
                     </IconButton>
                  </div>
               </Tooltip>
            ))}
      </div>
   );
};

export default ChatFilters;

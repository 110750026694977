import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { LanguageContext } from '@helper/locale/langContext';
import MyTypography from '@components/MyTypography';
import WooCommerceStoreForm from './storeForm';
import WooCommerceAccountForm from './accountForm';
import WooCommerceImg from './faprika.jpeg';
import CloseIcon from '@material-ui/icons/Close';
import { GET_E_COMMERCE_CONNECTION_QUERY } from '@queries/Settings/Service/GraphQL/ECommercePlatform/query';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';
import { CREATE_ECOMMERCE_PLATFORM_MUTATION } from '@queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useSubscription, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { ModalLargeText, ModalMediumText, ModalTitle } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';

const useStyles = makeStyles((theme) => ({
   root: {
      padding: theme.spacing(1),
      transition: 'all 1s',
   },
   content: {
      margin: theme.spacing(2, 0),
   },
   fbold: {
      fontWeight: 'bold',
   },
   informationListItem: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      padding: '4px 0px',
   },
   resultContainer: {
      width: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
      padding: 5,
      borderRadius: 5,
   },
   statusTitle: {
      color: 'white',
      fontSize: '1.2rem',
      fontWeight: 'bold',
   },
   informationTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

function QontoStepIcon(props) {
   const { active, completed } = props;
   return (
      <div>
         {active || completed ? (
            <RadioButtonCheckedIcon fontSize='large' color='primary' />
         ) : (
            <RadioButtonUncheckedIcon color='primary' fontSize='large' />
         )}
      </div>
   );
}

const getList = (t) => {
   return [
      t?.page_settings_modal_enter_store_name,
      t?.page_settings_modal_api_faprika_informations,
      t?.page_settings_modal_connect_your_faprika_accounts,
   ];
};

const ModalContent = ({ onCancel, onAccept, status, loading: _loading, errorMessage, formData: _formData }) => {
   const [createWooCommerceAccount] = useMutation(CREATE_ECOMMERCE_PLATFORM_MUTATION);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [formData, setFormData] = useState(_formData);
   const [loading, setLoading] = useState(_loading);
   const [_status, setStatus] = useState(status);
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [activeStep, setActiveStep] = useState(0);
   const steps = [
      t?.page_settings_modal_whatsapp_modal_step_information,
      t?.page_settings_modal_whatsapp_modal_step_account_information,
      t?.page_settings_modal_store_information,
      // t?.result,
   ];
   const informationList = getList(t);

   const token = useSelector((rootReducer) => rootReducer.personReducer.token);
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);

   const handleNextStep = () => {
      setActiveStep((prev) => prev + 1);
   };

   const handleClickWooCommerceLogin = async (url) => {
      window.open(url, '_blank');
      // const win = window.open(url, '_blank');
      // win.focus();
   };

   const handleBackStep = () => {
      setActiveStep((prev) => prev - 1);
   };

   if (loading) {
      return (
         <Grid container justifyContent='center' alignItems='center' style={{ height: 300, padding: 32 }}>
            <CircularProgress size={24} color='primary' />
         </Grid>
      );
   }

   return (
      <Grid>
         <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
               <Step key={label}>
                  <StepLabel>
                     <Typography className={classes.stepLabel}>{label}</Typography>
                  </StepLabel>
               </Step>
            ))}
         </Stepper>
         {activeStep === 0 && (
            <Grid>
               <ModalTitle>Faprika</ModalTitle>
               <ModalLargeText>{t?.settings_wooCommerce_strengthen_your_relationship}</ModalLargeText>
               <Grid
                  item
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  style={{ marginTop: 20 }}
                  xs={12}
               >
                  <Grid item container className={classes.informationListContainer} xs={9}>
                     {informationList.map((label, index) => (
                        <ModalMediumText key={label}>
                           {index + 1}- {label}
                        </ModalMediumText>
                     ))}
                  </Grid>
                  <Grid item xs={3}>
                     <img
                        src={WooCommerceImg}
                        alt='wooCommerce-icon-qpien'
                        style={{ width: '100%', borderRadius: 16 }}
                     />
                  </Grid>
                  <ModalButtonField>
                     <Button
                        style={{
                           color: '#212121',
                           backgroundColor: '#eeee',
                           '&:hover': {
                              backgroundColor: '#e0e0e0',
                           },
                        }}
                        onClick={onCancel}
                        className={classes.modalButtons}
                     >
                        {t?.comp_button_cancel}
                     </Button>
                     <Button
                        className={classes.modalButtons}
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={handleNextStep}
                        style={{
                           marginLeft: 'auto',
                           color: 'white',
                           backgroundColor: '#157CFC',
                           '&:hover': {
                              backgroundColor: '#105CBB',
                           },
                        }}
                     >
                        {t?.next}
                     </Button>
                  </ModalButtonField>
               </Grid>
            </Grid>
         )}
         {activeStep === 1 && (
            <WooCommerceStoreForm
               onAccept={(data) => {
                  setFormData((prev) => new Object({ ...prev, ...data }));
                  handleNextStep();
               }}
               onBack={handleBackStep}
            />
         )}
         {activeStep === 2 && (
            <WooCommerceAccountForm
               onAccept={(data) => {
                  onAccept({ ...formData, ...data });
                  setLoading(true);
               }}
               onBack={handleBackStep}
            />
         )}
         {/* Result */}
         {activeStep === 3 && (
            <Grid container justifyContent='center' alignItems='center'>
               <Grid style={{ height: 200 }} item container justifyContent='center' alignItems='center'>
                  <Fragment>
                     {_status ? (
                        <Fragment>
                           {' '}
                           <div className={classes.resultContainer} style={{ backgroundColor: '#5AC19D' }}>
                              <Typography className={classes.statusTitle}>{t?.connected}</Typography>
                              <CheckCircleIcon fontSize='large' />
                           </div>
                           <Grid item justifyContent='center' alignItems='center' container xs={12}>
                              <Typography color='primary' className={classes.informationTitle}>
                                 {t?.account_successfully_added}
                              </Typography>
                           </Grid>{' '}
                        </Fragment>
                     ) : (
                        <Fragment>
                           <div className={classes.resultContainer} style={{ backgroundColor: 'red' }}>
                              <Typography className={classes.statusTitle}>{t?.not_connected}</Typography>
                              <CloseIcon color='inherit' fontSize='large' />
                           </div>
                           <Grid
                              container
                              spacing={3}
                              direction='column'
                              justifyContent='center'
                              alignItems='center'
                              xs={12}
                              item
                           >
                              <Typography color='primary' className={classes.informationTitle}>
                                 {t?.account_failed_added}
                              </Typography>
                              <Typography color='error' style={{ marginTop: 8 }} variant='caption'>
                                 {t?.system_message} {errorMessage}
                              </Typography>
                           </Grid>
                        </Fragment>
                     )}
                  </Fragment>
               </Grid>
               <ModalButtonField>
                  {!_status && (
                     <Button variant='outlined' color='inherit' size='large' onClick={handleBackStep}>
                        {t?.back}
                     </Button>
                  )}
                  <Button variant='contained' color='primary' size='large' onClick={onCancel}>
                     {t?.pre_load_forget_pass_sent_OK}
                  </Button>
               </ModalButtonField>
            </Grid>
         )}
      </Grid>
   );
};

export default ModalContent;

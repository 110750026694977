import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import { makeStyles } from '@material-ui/core/styles';
import React, { FC, useContext } from 'react';
import Modal from '@components/Modal/modal';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import { MyTheme } from '@styles/config';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: MyTheme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

interface AreYouSureProps {
   isVisible: boolean;
   link: string;
   actionType?: 'disconnect' | 'delete';
   setShowModal: (param: boolean) => void;
   onPublish: () => void;
   onDraft: () => void;
}

const SaveDraftFlow: FC<AreYouSureProps> = ({ isVisible, setShowModal, onPublish, onDraft, link }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const history = useHistory();

   return (
      <Modal
         open={isVisible}
         onClose={() => {
            setShowModal(false);
         }}
         modalHeader={t.page_chat_typo_leave}
         paperStyle={{ width: 450 }}
      >
         <ModalLargeText style={{ textAlign: 'center', marginBlock: 10 }}>
            {t.automation_flow_draft_publish_warning}
         </ModalLargeText>

         <ModalButtonField>
            <ButtonCancel
               label={t.atomation_flow_draft}
               onClick={() => {
                  onDraft();
                  setShowModal(false);
                  history.push(link);
               }}
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
               }}
            />
            <ButtonOK
               label={t.atomation_flow_publish}
               onClick={() => {
                  onPublish();
                  setShowModal(false);
                  history.push(link);
               }}
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
               }}
            />
         </ModalButtonField>
      </Modal>
   );
};

export default SaveDraftFlow;

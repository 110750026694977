import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Video from '@assets/videos/upper.mp4';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
   svgClass: {
      fill: theme.palette.primary.main,
   },
   preload: {
      textAlign: 'center',
   },
   chat: {
      marginTop: -100,
   },
   preloadText: {
      paddingLeft: '100px',
      paddingRight: '100px',
      marginTop: '-40px',
   },
   chatText: {
      paddingLeft: '100px',
      paddingRight: '100px',
      marginTop: -75,
      fontSize: '2rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.spacing(6),
   },
   title: {
      color: 'black',
      fontSize: '1.5rem',
      paddingBottom: '.75rem',
   },
   textContainer: {
      padding: '1.5rem 0px',
      textAlign: 'center',
   },
}));

const Graphics = ({ text1, text2, title, scale, isChatSection, text }) => {
   const classes = useStyles();
   return (
      <Container className={classes.container}>
         <video width={'78%'} autoPlay='autoplay' loop muted playsInline preload>
            <source src={Video} />
         </video>

         <Grid className={classes.textContainer} container direction='column'>
            <Typography className={classes.title}>{title}</Typography>
            <Typography style={{ color: '#888888', fontSize: '1rem' }}>{text1}</Typography>
            <Typography style={{ color: '#888888', fontSize: '1rem' }}>{text2}</Typography>
         </Grid>
      </Container>
   );
};
Graphics.defaultProps = {
   primaryColor: '#ff4f5b',
};
export default Graphics;

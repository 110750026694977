import React, { FC, useContext, useRef } from 'react';
//@ts-ignore
import classNames from 'classnames';
// import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { ButtonBase, Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MyTheme } from '@styles/config';
import ISelectablePlatformsProps from './types';
import PlatformIconButton from './platformIconButton';
import SocialMediaIcon from '../SocialMediaIcon';
import { LanguageContext } from '@helper/locale/langContext';

// constants for calculations
const PADDING = 2.5;
const ICON_BUTTON_SIZE = 50;
const VIEW_ALL_BUTTON_SIZE = 25;

const useStyles = makeStyles<MyTheme, ISelectablePlatformsProps>((theme: MyTheme) => ({
   container: {
      position: 'relative',
      height: 70,
      zIndex: 99,
   },
   absoluteContainer: {
      position: 'absolute',
      left: 0,
      top: 0,
      right: 0,
      zIndex: 20000,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      overflow: 'auto',
      paddingBottom: 10,
      paddingTop: 10,
      backgroundColor: '#f0f3f7',
      [theme.breakpoints.up('md')]: {
         overflow: 'hidden',
      },
   },
   fitContent: {
      width: 'fit-content',
      borderBottomRightRadius: 10,
      borderTopRightRadius: 10,
      paddingRight: 10,
   },
   allButtonContainer: {
      flexShrink: 0,
      display: 'flex',
   },
   platformsContainer: {
      flexGrow: 1,
      display: 'flex',
      minWidth: 0,
      paddingRight: PADDING,
      paddingLeft: PADDING,
      justifyContent: 'flex-start',
   },
   platformContainer: {
      paddingLeft: PADDING,
      paddingRight: PADDING,
   },
   viewAllButtonContainer: {
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
      height: ICON_BUTTON_SIZE + PADDING + PADDING,
      marginBottom: -PADDING,
      marginTop: -PADDING,
      backgroundColor: '#f0f3f7',
      zIndex: 3000,
      [theme.breakpoints.up('md')]: {
         display: 'flex',
      },
      paddingLeft: 5,
   },
   arrowButton: {
      borderRadius: 99,
   },
   arrowIcon: {
      color: '#385273',
      fontSize: VIEW_ALL_BUTTON_SIZE,
      transition: '300ms',
   },
   arrowIconLeft: {
      transform: 'rotateY(180deg)',
   },
   icon: {
      borderRadius: 10,
      width: ICON_BUTTON_SIZE,
      height: ICON_BUTTON_SIZE,
      border: '1px solid #e8e9ed',
      boxSizing: 'border-box',
      '&:hover': {
         backgroundColor: '#e8e9ed',
      },
   },
   selectedIcon: {
      border: '1px solid #157CFC',
   },
   allIcon: {
      border: ({ currentSelectedPlatform }) =>
         currentSelectedPlatform === 'ALL' ? '1px solid transparent' : '1px solid #e8e9ed',
      backgroundColor: ({ currentSelectedPlatform }) =>
         currentSelectedPlatform === 'ALL' ? theme.chatApp.general.pallet.passiveStructureAzure : '#fff',
      color: ({ currentSelectedPlatform }) =>
         currentSelectedPlatform === 'ALL' ? '#fff' : theme.chatApp.general.pallet.passiveStructureBlue,
      '&:hover': {
         backgroundColor: ({ currentSelectedPlatform }) => (currentSelectedPlatform === 'ALL' ? '#116ada' : '#e8e9ed'),
      },
   },
   test: {
      backgroundColor: 'red',
   },
}));

const SelectablePlatforms: FC<ISelectablePlatformsProps> = (props) => {
   const { platformsData, onSelectPlatform, currentSelectedPlatform } = props;
   const {
      lang: { translation: t },
   }: any = useContext(LanguageContext);
   // const [viewAll, setViewAll] = useState<boolean>(false);
   const classes = useStyles(props);
   const platformListRef = useRef<HTMLDivElement | null>(null);
   // const [numberOfRenderedIcons, setNumberOfRenderedIcons] = useState<number>(0);
   // const theme = useTheme();
   // const isMedium = useMediaQuery(theme.breakpoints.up('md'));

   // when the width of the platforms' container changes it calculate number of rendered platform
   // useEffect(() => {
   //    const containerWidth = platformListRef?.current?.clientWidth;
   //    if (containerWidth) {
   //       const freeWidth = containerWidth - ICON_BUTTON_SIZE - PADDING - PADDING;
   //       setNumberOfRenderedIcons(Math.floor(freeWidth / (PADDING + ICON_BUTTON_SIZE + PADDING)));
   //    }
   // }, [platformListRef?.current?.clientWidth]);

   // hides the extra platforms that are shown when the screen size is small enough
   // useEffect(() => {
   //    if (!isMedium) {
   //       setViewAll(false);
   //    }
   // }, [isMedium]);

   return (
      <div ref={platformListRef} className={classes.container}>
         <div
            // className={classNames(classes.absoluteContainer, !!viewAll && classes.fitContent)}
            className={classes.absoluteContainer}
         >
            <div className={classes.allButtonContainer}>
               <PlatformIconButton count={200} onClick={() => onSelectPlatform('ALL')}>
                  <Tooltip title={t['all_platforms']}>
                     <IconButton className={classNames(classes.icon, classes.allIcon)}>
                        {t['page_chat_typo_all']}
                     </IconButton>
                  </Tooltip>
               </PlatformIconButton>
            </div>
            <div className={classes.platformsContainer}>
               {platformsData.map((socialMedia) => (
                  <div key={socialMedia.code} className={classes.platformContainer}>
                     <PlatformIconButton onClick={() => onSelectPlatform(socialMedia.name)} count={100}>
                        <Tooltip title={socialMedia.tooltip}>
                           <ButtonBase
                              className={classNames(
                                 classes.icon,
                                 currentSelectedPlatform === socialMedia.name && classes.selectedIcon,
                              )}
                           >
                              <SocialMediaIcon
                                 selected={
                                    currentSelectedPlatform === 'ALL' || currentSelectedPlatform === socialMedia.name
                                 }
                                 color={null}
                                 socialMediaCode={socialMedia.code}
                                 width={36}
                                 height={36}
                                 // containerStyle={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                 twitterSize={socialMedia.code === 6 ? '2rem' : null}
                              />
                           </ButtonBase>
                        </Tooltip>
                     </PlatformIconButton>
                  </div>
               ))}
            </div>
            {/* {(viewAll || platformsData?.length > numberOfRenderedIcons) && false && (
               <div className={classes.viewAllButtonContainer}>
                  <ButtonBase onClick={() => setViewAll(!viewAll)} className={classes.arrowButton}>
                     <ArrowForwardIosRoundedIcon
                        className={classNames(classes.arrowIcon, viewAll && classes.arrowIconLeft)}
                     />
                  </ButtonBase>
               </div>
            )} */}
         </div>
      </div>
   );
};

export default SelectablePlatforms;

import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import { ICustomerCartProps, IProductProps } from '../../types';
import { useForm } from 'react-hook-form';
import { CircularProgress, Grid, LinearProgress, Typography } from '@material-ui/core';
import AddProductListItem from './AddProductListItem';
import ButtonOK from '../../../../../../../../../components/ButtonOK/buttonOk';
import Input from '../../../../../../../../../components/InputField';
import {
   GET_E_COMMERCE_PRODUCTS_QUERY,
   GET_E_COMMERCE_VARIANTS_QUERY,
} from '@queries/Settings/Service/GraphQL/ECommercePlatform/query';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { getPerson } from '@store/reducers/person';
import { getECommerceProducts } from '@store/reducers/ecommerce';
import { E_COMMERCE_PRODUCTS } from '@store/actions/ecommerce';
import { useSnackbar } from 'notistack';
import InfiniteScroll from 'react-infinite-scroll-component';
import useWindowResize from '@hooks/useWindowResize';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
   },
   smallText: {
      color: '#385273',
      opacity: '0.7',
      fontSize: '1em',
      lineHeight: 1.3,
   },
   listContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: 8,
   },
   inputsContainer: {
      marginTop: -15,
      marginBottom: -18,
   },
   footerContainer: {
      padding: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   linearProgressContainer: {
      height: 4,
      marginTop: 2,
      marginBottom: 2,
   },
}));

interface AddProductToCustomerCartProps {
   setCart: React.Dispatch<React.SetStateAction<ICustomerCartProps>>;
   closeAddProduct: () => void;
}

const AddProductToCustomerCart: FC<AddProductToCustomerCartProps> = ({ setCart, closeAddProduct }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [selectedProducts, setSelectedProducts] = useState<IProductProps[]>([]);
   const dispatch = useDispatch();
   const me = useSelector(getPerson);
   const eCommerceProducts = useSelector(getECommerceProducts);
   const [searchText, setSearchText] = useState('');
   const [selectedVariantIds, setSelectedVariantIds] = useState<string[]>([]); // [productId]
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [fetchingMore, setFetchingMore] = useState(false);
   const eCommerceType = useSelector((rootReducer: any) => rootReducer?.eCommerceReducer?.eCommerceType);

   const { height } = useWindowResize();

   const selectProduct = (product: IProductProps) => {
      setSelectedProducts((prevState) =>
         prevState.findIndex((pro) => pro.productIdAndVariantId === product.productIdAndVariantId) === -1
            ? [...prevState, product]
            : prevState,
      );
   };

   const deselectProduct = (product: IProductProps) => {
      setSelectedProducts((prevState) => [
         ...prevState.filter((pro) => {
            return pro.productIdAndVariantId !== product.productIdAndVariantId;
         }),
      ]);
   };

   const getVariant = async (productId: string): Promise<any> => {
      if (selectedVariantIds.includes(productId)) return;
      setSelectedVariantIds((prevState) => [productId, ...prevState]);
      return await getECommerceVariants({
         variables: {
            customer: me.customer._id,
            productId,
         },
      })
         .then(({ data }: any) => {
            return data.getECommerceVariants.data.length;
         })
         .catch(() => {
            return 0;
         });
   };

   const [getECommerceVariants, { loading: eCommerceVariantLoading }] = useLazyQuery(GET_E_COMMERCE_VARIANTS_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data: {
         getECommerceVariants: {
            data: {
               variantId: string;
               name: string;
               currency: string;
               salesPrice: string;
               discountedPrice: string;
               stock: number;
               variantsType1: string;
               variantsType2: string;
               variantsType3: string;
            }[];
         };
      }) => {
         if (data.getECommerceVariants.data.length > 0) {
            const updatedECommerceProducts = eCommerceProducts?.products.map((product: any) => {
               if (product._id.toString() === selectedVariantIds[0]) {
                  return {
                     ...product,
                     variants: data.getECommerceVariants.data,
                  };
               } else {
                  return product;
               }
            });
            dispatch({
               type: E_COMMERCE_PRODUCTS,
               payload: {
                  eCommerceProducts: {
                     ...eCommerceProducts,
                     products: updatedECommerceProducts,
                  },
               },
            });
         }
      },
   });

   const addSelectedProductsToCard = () => {
      setCart((prevState) => ({
         ...prevState,
         products: [
            ...prevState.products.map((prevProduct) => {
               const pro = selectedProducts.find(
                  (pro) => pro.productIdAndVariantId === prevProduct.productIdAndVariantId,
               );
               return pro
                  ? {
                       ...prevProduct,
                       quantity: prevProduct.quantity + 1,
                    }
                  : prevProduct;
            }),
            ...selectedProducts
               .filter(
                  (pro) =>
                     prevState.products.findIndex(
                        (prevPro) => prevPro.productIdAndVariantId === pro.productIdAndVariantId,
                     ) === -1,
               )
               .map((product) => ({
                  ...product,
                  // variantName: product.variants ? product.variants[0].name : undefined,
                  // price: product.price || 0,
               })),
         ],
      }));
      setSelectedProducts([]);
      reset();
      closeAddProduct();
   };

   const { watch, control, handleSubmit, reset } = useForm({
      mode: 'all',
      defaultValues: {
         searchProduct: '',
         selectProductType: { value: 0, label: t['page_e_commerce_products_select_input_placeholder'] },
      },
   });

   const watchSearchProduct = watch('searchProduct');
   const watchSelectProductType = watch('selectProductType');

   useEffect(() => {
      const timer = setTimeout(() => {
         setSearchText(watchSearchProduct);
      }, 500);
      return () => clearTimeout(timer);
   }, [watchSearchProduct]);

   const { fetchMore, loading } = useQuery(GET_E_COMMERCE_PRODUCTS_QUERY, {
      variables: {
         customer: me.customer._id,
         page: 1,
         query: searchText,
         status: watchSelectProductType.value,
      },
      fetchPolicy: 'network-only',
      onCompleted: (data: any) => {
         dispatch({
            type: E_COMMERCE_PRODUCTS,
            payload: { eCommerceProducts: data.getECommerceProducts.data },
         });
      },
      onError: (error: any) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key: any) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const handleNext = useCallback(() => {
      if (eCommerceProducts?.hasNextPage) {
         setFetchingMore(true);
         fetchMore({
            variables: {
               page: eCommerceProducts?.nextPage,
               pageToken: eCommerceProducts?.nextPageToken,
            },
         })
            .then(({ data }: any) => {
               dispatch({
                  type: E_COMMERCE_PRODUCTS,
                  payload: {
                     eCommerceProducts: {
                        ...data.getECommerceProducts.data,
                        products: [...eCommerceProducts?.products, ...data.getECommerceProducts.data.products],
                     },
                  },
               });
            })
            .finally(() => {
               setFetchingMore(false);
            });
      }
   }, [
      eCommerceProducts?.totalDocs,
      eCommerceProducts?.hasNextPage,
      eCommerceProducts?.nextPage,
      eCommerceProducts?.nextPageToken,
      eCommerceProducts?.page,
   ]);

   const selectProductOptions = [
      { value: 0, label: t['page_e_commerce_products_select_input_placeholder'] },
      { value: 1, label: t['page_e_commerce_products_active'] },
      { value: 2, label: t['page_e_commerce_products_draft'] },
   ];

   return (
      <div className={classes.container}>
         <form onSubmit={handleSubmit(addSelectedProductsToCard)}>
            <div className={classes.listContainer}>
               <div className={classes.inputsContainer}>
                  <Grid container direction='row' alignItems='center' justify='space-between' wrap='nowrap'>
                     {/* Search Product Input */}
                     <Grid item xs={6}>
                        <Input
                           name='searchProduct'
                           required={false}
                           control={control}
                           placeholder={t['page_e_commerce_products_search_product_input_placeholder']}
                        />
                     </Grid>
                     {/* Select Product Type */}
                     <Grid item xs={6} style={{ paddingLeft: 5 }}>
                        <Input
                           isSelect={true}
                           selectOptions={selectProductOptions}
                           name='selectProductType'
                           control={control}
                        />
                     </Grid>
                  </Grid>
               </div>
               <div className={classes.linearProgressContainer}>{loading && <LinearProgress />}</div>
               <InfiniteScroll
                  next={handleNext}
                  dataLength={eCommerceProducts?.products?.length || 0}
                  height={height - 385 - 60}
                  loader={
                     <Grid
                        style={{
                           height: 350,
                           margin: '20px 0px',
                        }}
                        container
                        justifyContent='center'
                        alignItems='center'
                        xs={12}
                     >
                        {fetchingMore && <CircularProgress size={24} color='primary' />}
                        {!fetchingMore && eCommerceProducts?.products?.length < 1 && (
                           <Typography>{t['page_e_commerce_products_search_product_not_found']}</Typography>
                        )}
                     </Grid>
                  }
                  hasMore={eCommerceProducts.hasNextPage}
                  style={{
                     // flex: 1,
                     // height: '100%',
                     display: 'flex',
                     flexDirection: 'column',
                     overflowX: 'hidden',
                  }}
                  // scrollableTarget='chatDiv'
               >
                  {eCommerceProducts.products?.map((product: any) => {
                     const currentProducts = selectedProducts.filter((pro) => pro._id === product._id);
                     return (
                        <AddProductListItem
                           key={product._id}
                           product={product}
                           isSelected={currentProducts?.length > 0}
                           selectedVariants={currentProducts.map((pro) => pro.variantId)}
                           selectProduct={selectProduct}
                           deselectProduct={deselectProduct}
                           getVariant={getVariant}
                           eCommerceType={eCommerceType}
                           eCommerceVariantLoading={eCommerceVariantLoading}
                        />
                     );
                  })}
               </InfiniteScroll>
            </div>
            <div className={classes.footerContainer}>
               <Typography variant='body2' noWrap className={classes.smallText}>
                  {`${selectedProducts.length} ${t['page_e_commerce_products_typo_product_selected']}`}
               </Typography>
               <ButtonOK
                  type='submit'
                  label={t['page_e_commerce_products_add_product_add_to_cart']}
                  style={{
                     flexGrow: 1,
                     marginLeft: 20,
                  }}
               />
            </div>
         </form>
      </div>
   );
};

export default AddProductToCustomerCart;

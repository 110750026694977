import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import TemplatesTable from './tableTemplatesPage/TemplatesTable';
import TemplatesTopBar from './tableTemplatesPage/TemplatesTopBar';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
   templatePage: {
      height: '100%',
      flexGrow: 1,
      overflowY: 'hidden',
      overflowX: 'auto',
   },
   topBar: {},
   table: {
      height: 'calc(100% - 58px)',
      margin: 0,
   },
});

const TemplatesPage = () => {
   const classes = useStyles();
   const appId = useParams()._id;
   return (
      <Grid style={{ height: '100%' }}>
         <Grid className={classes.templatePage}>
            <Grid className={classes.topBar}>
               <TemplatesTopBar appId={appId} />
            </Grid>
            <Grid className={classes.table}>
               <TemplatesTable appId={appId} />
            </Grid>
         </Grid>
      </Grid>
   );
};

export default TemplatesPage;

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
   Avatar,
   FormControlLabel,
   FormGroup,
   IconButton,
   Switch,
   Typography,
   TextField,
   Box,
   CircularProgress,
} from '@material-ui/core';
import Input from '@components/InputField';
import AddIcon from '@material-ui/icons/AddCircle';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import UserPlaceholder from '@assets/icons/ProfileIcons/user.svg';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@material-ui/core';
import { UPDATE_ME } from '@store/actions/person';
import TranslateIcon from '@material-ui/icons/Translate';
import { useProfile } from '../../Service/account.service';
import Resizer from 'react-image-file-resizer';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import useTranslateLanguage from '@hooks/useTranslateLanguage';
const useStyles = makeStyles((theme) => ({
   selector: {
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 10,
      paddingLeft: 3,
      height: 32,
   },
   titleContainer: {
      color: '#385273',
      borderRadius: 15,
      padding: 15,
      backgroundColor: 'white',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: '1px solid #F7F7FA',
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   button: {
      paddingTop: 10,
      width: 100,
      height: 30,
      color: 'white',
      borderRadius: 10,
   },
   buttonApply: {
      backgroundColor: '#487DBF',
      '&:hover': {
         backgroundColor: '#487DBF',
      },
   },
   passwordSectionTitle: {
      color: '#385273',
      fontSize: '1rem',
   },
   submitBtn: {
      background: '#178CF9',
      paddingLeft: '3rem',
      paddingRight: '3rem',
      textTransform: 'capitalize',
      marginTop: '20px',
   },
   bottomContainer: {
      marginTop: 10,
      marginBottom: 20,
      padding: 5,
      [theme.breakpoints.down('sm')]: {
         marginBottom: 90,
      },
   },
   snackBar: {
      color: '#000',
      fontSize: '13px',
      cursor: 'pointer',
      fontWeight: '500',
   },
   pageTitle: {
      marginLeft: 10,
      fontSize: '1.1rem',
      color: '#434343',
   },
   mainContent: {
      maxWidth: 1000,
      overflowY: 'auto',
      padding: '0px 2rem',
   },
   mt1rem: {
      marginTop: '1rem',
      [theme.breakpoints.down('sm')]: {
         marginBottom: 28,
      },
   },
   mtp10: {
      marginTop: 10,
      padding: 10,
      [theme.breakpoints.down('sm')]: {
         padding: 0,
      },
   },
   avatarContainer: {
      // border: '1px solid #F7F7FA',
      padding: '5px',
      borderRadius: '50%',
   },
   avatar: {
      width: 100,
      height: 100,
      borderRadius: 50,
   },
   posRelative: {
      position: 'relative',
   },
   imgUploadContainer: {
      position: 'absolute',
      top: -35,
      left: 64,
   },
   p5: {
      padding: 5,
   },
   addIcon: {
      color: '#157CFC',
      fontSize: '1.75rem',
   },
   mt10: {
      marginTop: 10,
   },
   imgProfileText: {
      color: '#8A9EB5',
      fontSize: '0.75rem',
      textAlign: 'center',
   },
   form: {
      paddingBottom: '3rem',
      borderBottom: '1px solid #F7F7FA',
   },
   mt20: {
      marginTop: 20,
   },
   pwForm: {
      padding: '2rem 0px',
      width: '100%',
   },
   pwTextContainer: {
      marginTop: 30,
      padding: 5,
   },
   translationContainer: {
      border: '1px solid #cdcdcd',
      borderRadius: '15px',
      backgroundColor: '#f9f9f9',
      marginBottom: '10px',
   },
   translationTitleContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
   },
   translationTitle: {
      fontSize: '1.15rem',
      color: '#0000008a',
   },
   translateIcon: {
      color: '#0000008a',
   },
   translationStatusTitle: { color: '#0000008a' },
   translationLanguageTitle: { color: '#0000008a' },
   customInputDiv: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 42,
      borderRadius: 5,
      border: '2px solid #dcdcdc',
      '&input:focus': {
         outline: 'none !important',
      },
      '&input:focus-visible': {
         outline: 'none !important',
      },
      '&input:hover': {
         outline: 'none !important',
      },
      backgroundColor: '#f4f4f4',
   },
   customInput: {
      height: 38,
      width: 60,
      borderRadius: 7,
      borderLeft: 'none !important',
      borderBottom: 'none !important',
      borderTop: 'none !important',
      outline: 'none !important',
      textDecoration: 'none',
      fontSize: 16,
      borderRight: '2px solid #dcdcdc',
      textAlign: 'center',
   },
   customInputText: {
      width: '100%',
      height: 40,
      padding: 8,
      fontSize: 16,
   },
   customInputDivError: {
      border: '2px solid #ff0000',
   },
   customInputError: {
      borderRight: '2px solid #ff0000',
   },
}));

const resizeFile = (file) =>
   new Promise((resolve) => {
      Resizer.imageFileResizer(
         file,
         300,
         300,
         'JPEG',
         80,
         0,
         (uri) => {
            resolve(uri);
         },
         'file',
      );
   });

const Profile = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const dispatch = useDispatch();
   const [phoneCode, setPhoneCode] = useState('');
   const { data: languages, loading, personLang } = useTranslateLanguage(lang.shortTitle);
   const [language, setLanguage] = useState(null);
   const [translationStatus, setTranslationStatus] = useState(person?.isTranslate);
   const {
      updateProfileMutation,
      changePersonPassword,
      changePersonPhoto,
      updateProfileLoading,
      changePersonPasswordLoading,
      changePersonPhotoLoading,
   } = useProfile();
   const {
      handleSubmit,
      control,
      formState: { isValid, isDirty },
   } = useForm({
      mode: 'all',
      defaultValues: {
         email: person?.email,
         name: person?.name,
         phone: person?.phone,
         surname: person?.surname,
         chatParticipateLimit: person?.chatParticipateLimit || 0,
      },
   });
   const {
      handleSubmit: passwordHandleSubmit,
      control: passwordControl,
      watch,
      reset,
      formState: { isValid: passwordIsValid },
   } = useForm({ mode: 'all' });

   const handleClickUpload = () => {
      document.getElementById('selectProfileImagePerson').click();
   };
   const handleUploadFile = async (files) => {
      if (!files || !files[0]) {
         return;
      }
      if (files[0].size > 1000000) {
         enqueueSnackbar(`${files[0].name} ${t?.page_support_typo_cant_upload_more_than_1_mb}`, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={classes.snackBar} onClick={() => closeSnackbar(key)}>
                  {' '}
                  Kapat
               </div>
            ),
         });
         return false;
      }
      const photo = await resizeFile(files[0]);
      changePersonPhoto({
         variables: {
            _id: person._id,
            photo,
         },
      })
         .then(
            ({
               data: {
                  changePersonPhoto: { data },
               },
            }) => {
               // on success event
               // close modal
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        ...data,
                     },
                  },
               });
               enqueueSnackbar(`{ ${data.name} } fotoğrafın başarılı bir şekilde değiştirildi.`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={classes.snackBar} onClick={() => closeSnackbar(key)}>
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   // update data on submit
   const editPersonSubmit = async (data) => {
      // run update mutation
      delete data.email;
      updateProfileMutation({
         variables: {
            ...data,
            _id: person._id,
            phone: phoneCode ? phoneCode + ' ' + data.phone : data.phone,
            chatParticipateLimit: parseInt(data.chatParticipateLimit || 0),
            isTranslate: translationStatus,
            language: language?.code || personLang.code,
         },
      })
         .then(
            ({
               data: {
                  updateProfile: { data },
               },
            }) => {
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        ...data,
                     },
                  },
               });
               enqueueSnackbar(`{ ${data.name} } başarılı bir şekilde güncellendi`, {
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={classes.snackBar} onClick={() => closeSnackbar(key)}>
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   const passwordChangeSubmit = async (data) => {
      // run update mutation
      changePersonPassword({
         variables: {
            _id: person._id,
            ...data,
         },
      })
         .then(
            ({
               data: {
                  changePersonPassword: { data },
               },
            }) => {
               // on success event
               reset({ oldPassword: '', newPassword: '', newPasswordConfirm: '' });
               // close modal
               enqueueSnackbar(t?.general_information_updated_successfully, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={classes.snackBar} onClick={() => closeSnackbar(key)}>
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   return (
      <Grid container>
         {changePersonPhotoLoading && <div className='loading' />}
         {updateProfileLoading && <div className='loading' />}
         {changePersonPasswordLoading && <div className='loading' />}
         <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
            <Typography className={classes.pageTitle}>{t['comp_sidebar_profile']}</Typography>
         </Grid>
         {/* </AppBar> */}
         <Grid className={classes.mainContent}>
            <Grid item xs={12} container direction='row' className={classes.mtp10}>
               <Grid container direction='row'>
                  <Grid item className={classes.p5} xs={12} container>
                     <Grid direction='column' container>
                        <Grid container direction='row'>
                           <div className={classes.avatarContainer}>
                              <Avatar src={person.photo ? person.photo : UserPlaceholder} className={classes.avatar} />
                              <div className={classes.posRelative}>
                                 <div className={classes.imgUploadContainer}>
                                    <IconButton onClick={handleClickUpload}>
                                       <input
                                          id='selectProfileImagePerson'
                                          hidden
                                          onChange={(e) => handleUploadFile(e.target.files)}
                                          type='file'
                                          accept='image/*'
                                       />
                                       <AddIcon className={classes.addIcon} />
                                    </IconButton>
                                 </div>
                              </div>
                           </div>
                        </Grid>
                        <Grid container direction='row' className={classes.mt10}>
                           <Typography className={classes.imgProfileText}>
                              {t['page_account_typo_profile_image']}
                           </Typography>
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
               <form onSubmit={handleSubmit(editPersonSubmit)} className={classes.form}>
                  <Grid container spacing={3} className={classes.mt20}>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='name'
                           label={t?.name}
                           control={control}
                           rules={{
                              required: t?.name_field_is_required,
                              minLength: {
                                 value: 2,
                                 message: t?.name_must_be_atleast_2_characters,
                              },
                           }}
                        />
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='surname'
                           label={t?.page_account_input_profile_surname}
                           type='text'
                           control={control}
                           rules={{
                              required: t?.surname_field_is_required,
                              minLength: {
                                 value: 2,
                                 message: t?.surname_must_be_atleast_2_characters,
                              },
                           }}
                        />
                     </Grid>
                     <Grid item md={6} xs={12} style={{ height: 100 }}>
                        <Input
                           name='email'
                           label={t?.page_account_input_profile_email}
                           control={control}
                           helperText={t?.the_email_address_cannot_be_changed_by_the_user}
                           disabled
                        />
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Input
                           name='phone'
                           label={t?.page_contacts_typo_phone}
                           isPhone
                           splitCountryCode={true}
                           control={control}
                           rules={{
                              required: t?.phone_is_required,
                           }}
                        />
                     </Grid>
                     <Grid item tem md={12} xs={12}>
                        <Controller
                           name='chatParticipateLimit'
                           control={control}
                           defaultValue={0}
                           rules={{
                              required: true,
                              validate: (value) => {
                                 return !isNaN(parseInt(value)) && parseInt(value) >= 0;
                              },
                           }}
                           render={({ field, fieldState: { error } }) => {
                              return (
                                 <div style={{ marginBottom: 20 }}>
                                    <Typography style={{ marginLeft: 2 }} align='left'>
                                       {t?.page_settings_typo_chat_settings_conversation_assignment_auto_assignment}
                                    </Typography>
                                    <div className={clsx(classes.customInputDiv, error && classes.customInputDivError)}>
                                       <input
                                          className={clsx(classes.customInput, error && classes.customInputError)}
                                          {...field}
                                          type='number'
                                       />
                                       <div className={classes.customInputText}>
                                          {
                                             t[
                                                'page_settings_typo_chat_settings_conversation_assignment_auto_assignment_description'
                                             ]
                                          }
                                       </div>
                                    </div>
                                 </div>
                              );
                           }}
                        />
                     </Grid>
                  </Grid>
                  <Grid container spacing={3} className={classes.translationContainer}>
                     <Grid item md={12} xs={12} className={classes.translationTitleContainer}>
                        <TranslateIcon fontSize='small' className={classes.translateIcon} />
                        <Typography component='h4' className={classes.translationTitle}>
                           {t['profile_message_translation_title']}
                        </Typography>
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Typography component='h5' className={classes.translationStatusTitle}>
                           {t['profile_message_translation_status']}
                        </Typography>
                        <FormGroup>
                           <FormControlLabel
                              control={
                                 <Switch
                                    name='translationStatus'
                                    checked={translationStatus}
                                    color='primary'
                                    onChange={() => setTranslationStatus(!translationStatus)}
                                 />
                              }
                              label={
                                 translationStatus
                                    ? t['page_settings_users_status_enabled']
                                    : t['page_settings_users_status_disabled']
                              }
                           />
                        </FormGroup>
                     </Grid>
                     <Grid item md={6} xs={12}>
                        <Typography component='h5' className={classes.translationLanguageTitle}>
                           {t['profile_message_translation_language']}
                        </Typography>
                        {!loading ? (
                           <Autocomplete
                              id='country-select-demo'
                              sx={{ width: 300 }}
                              options={languages}
                              value={language || personLang}
                              getOptionLabel={(option) => option.label}
                              onChange={(e, value) => setLanguage(value)}
                              renderInput={(params) => (
                                 <TextField
                                    {...params}
                                    label={t['profile_message_translation_choose_lang']}
                                    inputProps={{
                                       ...params.inputProps,
                                    }}
                                 />
                              )}
                           />
                        ) : (
                           <CircularProgress />
                        )}
                     </Grid>
                  </Grid>
                  <Grid container justifyContent='flex-end'>
                     <Button
                        variant='contained'
                        color='primary'
                        className={classes.submitBtn}
                        disabled={
                           (!isValid || !isDirty) &&
                           (language?.code || personLang?.code) === person.language &&
                           translationStatus === person.isTranslate
                        }
                        type='submit'
                     >
                        {t?.update}
                     </Button>
                  </Grid>
               </form>
               <Grid item xs={12} className={classes.pwTextContainer}>
                  <Typography className={classes.passwordSectionTitle}>
                     {t['page_account_input_profile_update_password']}
                  </Typography>
               </Grid>
               <form onSubmit={passwordHandleSubmit(passwordChangeSubmit)} className={classes.pwForm}>
                  <Grid container spacing={3}>
                     <Grid item xs={12}>
                        <Input
                           name='oldPassword'
                           label={t['page_account_input_profile_current_password'] + '*'}
                           type='password'
                           control={passwordControl}
                           rules={{
                              required: t?.required_field,
                              minLength: {
                                 value: 6,
                                 message: t?.password_must_be_6_chars,
                              },
                           }}
                        />
                     </Grid>
                     <Grid item xs={12} md={6}>
                        <Input
                           label={t['page_account_input_profile_new_password'] + '*'}
                           name='newPassword'
                           type='password'
                           control={passwordControl}
                           rules={{
                              required: t?.required_field,
                              minLength: {
                                 value: 6,
                                 message: t?.password_must_be_6_chars,
                              },
                           }}
                        />
                     </Grid>
                     <Grid xs={12} item md={6}>
                        <Input
                           label={t['page_account_input_profile_repeat_new_password'] + '*'}
                           name='newPasswordConfirm'
                           type='password'
                           control={passwordControl}
                           rules={{
                              validate: (value) => {
                                 const isMatch = value === watch('newPassword');
                                 if (!isMatch) {
                                    return 'Passwords do not match';
                                 }
                                 return true;
                              },
                           }}
                        />
                     </Grid>
                  </Grid>
                  <Grid container justifyContent='flex-end' className={classes.mt1rem}>
                     <Button
                        variant='contained'
                        color='primary'
                        className={classes.submitBtn}
                        disabled={!passwordIsValid}
                        type='submit'
                     >
                        {t?.update}
                     </Button>
                  </Grid>
               </form>
            </Grid>
         </Grid>
      </Grid>
   );
};

export default Profile;

import { LanguageContext } from '@helper/locale/langContext';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import StartFlowModal from '@modules/Automation/FlowBots/components/startFlowModal';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Node } from 'reactflow';
import { FlowNodeType } from '../../types';
import TriggerBlocks from '../ContentPanel/Nodes/FlowBlocks/triggerBlocks';
import { TriggerIcon } from '../icons/icons';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      padding: '5px 0',
      width: '100%',
   },
   subContainer: {
      padding: '0 18px 10px 18px',
      overflowY: 'auto',
      overflowX: 'hidden',
      width: '100%',
      height: 'calc(100% - 65px)',
   },
   btnContainer: {
      margin: '10px 20px 20px 10px',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      padding: '5px 13px',
      minWidth: 120,
      height: 36,
      borderRadius: 6,
      transition: 'all 0.5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   sendIcon: {
      width: 35,
      height: 35,
   },
   header: {
      display: 'flex',
      gap: '10px',
      marginBottom: '15px',
      paddingLeft: '18px',
      padding: '5px 18px 10px 18px',
      borderBottom: '1px solid #F7F7FA',
   },
   input: {
      border: 'none',
      outline: 'none',
      fontSize: '1.1rem',
   },
   btn: {
      height: 36,
      width: '100%',
      border: '1px dashed #157AFA',
      borderRadius: '10px',
      background: 'transparent',
      cursor: 'pointer',
   },
}));

interface TriggerPanelProps {
   leftNodes: Node[];
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
   clickedNodeId: string;
}

const TriggerLeftPanel: React.FC<TriggerPanelProps> = ({
   leftNodes,
   setLeftNodes,
   clickedNodeId,
   setContentNodes,
   setIsNewNode,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [showModal, setShowModal] = useState(false);
   const [showBlocks, setShowBlocks] = useState(false);
   const [isSaveTrigger, setIsSaveTrigger] = useState(false);
   const [isBtnDisable, setIsBtnDisable] = useState(true);
   const [isLoading, setIsLoading] = useState(false);

   const handleOpenModal = () => {
      setShowModal(true);
   };
   const handleCloseModal = () => {
      setShowModal(false);
   };

   useEffect(() => {
      if (
         leftNodes.some((node) => node.data?.flowBlocks?.length > 0) &&
         leftNodes.find((node) => node.type === FlowNodeType.Triggers)?.data?.flowBlocks.length > 0
      ) {
         setShowBlocks(true);
      } else {
         setShowBlocks(false);
      }
   }, [leftNodes]);

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' justifyContent='center' xs={12}>
            <CircularProgress size={20} color='inherit' />
         </Grid>
      );
   }, []);

   return (
      <Grid className={classes.container}>
         <Grid className={classes.header}>
            <TriggerIcon className={classes.sendIcon} />
            <Grid style={{ fontSize: '1.1rem', marginTop: '5px' }}>{t.atomation_flow_trigger_block_trigger}</Grid>
         </Grid>
         <Grid className={classes.subContainer} style={{ flex: 1 }}>
            <Grid>
               {showBlocks ? (
                  <TriggerBlocks
                     leftNodes={leftNodes}
                     setIsBtnDisable={setIsBtnDisable}
                     isSaveTrigger={isSaveTrigger}
                     setIsSaveTrigger={setIsSaveTrigger}
                     clickedNodeId={clickedNodeId}
                     isLoading={isLoading}
                     setIsLoading={setIsLoading}
                     setLeftNodes={setLeftNodes}
                     setContentNodes={setContentNodes}
                     setIsNewNode={setIsNewNode}
                  />
               ) : (
                  <button onClick={handleOpenModal} className={classes.btn}>
                     {t.atomation_flow_trigger_block_add_trigger}
                  </button>
               )}
            </Grid>
         </Grid>
         <Grid className={classes.btnContainer}>
            <Grid style={{ overflowY: 'auto', flex: 1 }}>
               <Button
                  className={classes.modalButtons}
                  type='submit'
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     float: 'right',
                  }}
                  onClick={() => setIsSaveTrigger(true)}
                  disabled={isBtnDisable}
               >
                  <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                     {isLoading && <Loader />}
                     <span style={{ whiteSpace: 'nowrap' }}>{t.page_chat_btn_navbar_save}</span>
                  </div>
               </Button>
            </Grid>
         </Grid>

         <StartFlowModal
            open={showModal}
            onClose={handleCloseModal}
            clickedNodeId={clickedNodeId}
            setIsBtnDisable={setIsBtnDisable}
            setLeftNodes={setLeftNodes}
            setContentNodes={setContentNodes}
            setIsNewNode={setIsNewNode}
         />
      </Grid>
   );
};

export default TriggerLeftPanel;

import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm } from 'react-hook-form';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';
import { ModalLargeText, ModalTitle } from '@components/_Modal/ModalTexts';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 650,
      padding: 20,
      paddingBottom: 5, // width: 700,
      height: 440,
      textAlign: 'center',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   modalTitle: {
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '1.3rem',
   },
   iconSize: {
      fontSize: '5rem',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const ModalContent = ({ quickAnswer, onAccept, onCancel, mode, onDelete }) => {
   const classes = useStyles();
   const { enqueueSnackbar } = useSnackbar();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const {
      handleSubmit,
      control,
      register,
      formState: { isDirty, isValid },
      setValue,
      watch,
   } = useForm({
      mode: 'onChange',
      defaultValues: {
         answer: mode === 'EDIT' ? quickAnswer.answer : '',
         name: mode === 'EDIT' ? quickAnswer.name : '',
         _id: mode === 'EDIT' ? quickAnswer?.id : '',
         payload: mode === 'EDIT' ? quickAnswer.payload || [] : [],
      },
   });
   const [remove, setRemove] = useState({
      _id: mode === 'DELETE' ? quickAnswer.id : '',
   });
   const onSubmit = (data) => {
      onAccept(data);
      setRemove((prevState) => ({ ...prevState }));
   };
   let payloadWatch = watch('payload');
   const kbValueNumberFormatter = Intl.NumberFormat('en', {
      notation: 'compact',
      style: 'unit',
      unit: 'byte',
      unitDisplay: 'narrow',
   });

   let handleFile = (e) => {
      //10mb limit

      let newFiles = [];
      for (let i = 0; i < e.target.files.length; i++) {
         const file = e.target.files[i];
         if (file.size > 10000000) {
            enqueueSnackbar(t['upload_area_description'], {
               variant: 'error',
            });
            return;
         }
         newFiles.push({
            name: file.name,
            size: file.size,
            type: file.type,
            file,
         });
      }

      setValue('payload', [...watch('payload'), ...newFiles]);
   };

   // only for test
   return (
      <Grid>
         {mode === 'DELETE' ? (
            <Grid container direction='column' alignItems='center'>
               <ModalLargeText style={{ marginBottom: 30, marginTop: 20 }}>
                  {t?.page_settings_typo_delete_answer}
               </ModalLargeText>
               <ModalButtonField>
                  <ButtonCancel
                     className={classes.modalButtons}
                     onClick={onCancel}
                     label={t['comp_button_cancel']}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        '&:hover': {
                           backgroundColor: '#e0e0e0',
                        },
                     }}
                  />
                  <ButtonOK
                     className={classes.modalButtons}
                     style={{
                        color: 'white',
                        backgroundColor: '#ef5350',
                        '&:hover': {
                           backgroundColor: '#f44336',
                        },
                        float: 'right',
                     }}
                     onClick={() => onDelete(remove)}
                     label={t['comp_button_delete']}
                  />
               </ModalButtonField>
            </Grid>
         ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
               <SingleInput
                  label={t?.page_settings_account_title}
                  name='name'
                  control={control}
                  editable
                  placeholder={t?.page_settings_typo_quick_ans_add_placeholder_title}
                  rules={{
                     required: t?.please_fill_this_field,
                  }}
               />
               <SingleInput
                  label={t?.page_settings_account_content}
                  name='answer'
                  multiline={true}
                  control={control}
                  editable
                  placeholder={t?.page_settings_typo_quick_ans_add_placeholder_content}
                  rules={{
                     required: t?.please_fill_this_field,
                     minLength: {
                        value: 10,
                        message: t?.answer_must_be_at_least_10_characters,
                     },
                  }}
               />
               <div
                  style={{
                     height: 150,
                     textAlign: 'center',
                     marginBottom: 10,
                     borderRadius: 20,
                     backgroundColor: 'rgb(233, 233, 233)',
                     padding: 5,
                     paddingLeft: 10,
                     paddingRight: 10,
                     display: payloadWatch.length > 0 ? 'block' : 'none',
                  }}
               >
                  {payloadWatch.length > 0 ? (
                     <div
                        style={{
                           display: 'flex',
                           justifyContent: 'flex-start',
                           flexDirection: 'row',
                           alignItems: 'center',
                           overflow: 'auto',
                        }}
                     >
                        {payloadWatch.map((item, index) => {
                           return (
                              <div
                                 style={{
                                    display: 'block',
                                    borderRadius: 10,
                                    width: 125,
                                    height: 125,
                                    position: 'relative',
                                    border: '1px solid rgba(0,0,0,0.5)',
                                    marginLeft: 5,
                                    marginBottom: 5,
                                 }}
                              >
                                 <img
                                    src={item.file ? URL.createObjectURL(item.file) : item.path}
                                    style={{
                                       width: 115,
                                       height: 115,
                                       objectFit: 'contain',
                                    }}
                                 />
                                 <div
                                    style={{
                                       position: 'absolute',
                                       top: 3,
                                       right: 3,
                                       borderRadius: 50,
                                       width: 25,
                                       height: 25,
                                       backgroundColor: 'rgba(0,0,0,0.5)',
                                       textAlign: 'center',
                                       cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                       setValue(
                                          'payload',
                                          watch('payload').filter((item, i) => i !== index),
                                       );
                                    }}
                                 >
                                    <svg
                                       xmlns='http://www.w3.org/2000/svg'
                                       height='20'
                                       color='white'
                                       fill='white'
                                       viewBox='0 96 960 960'
                                       width='20'
                                       style={{
                                          marginTop: 2,
                                       }}
                                    >
                                       <path d='m249 849-42-42 231-231-231-231 42-42 231 231 231-231 42 42-231 231 231 231-42 42-231-231-231 231Z' />
                                    </svg>
                                 </div>
                                 <div
                                    style={{
                                       position: 'absolute',
                                       height: 25,
                                       marginRight: 'auto',
                                       backgroundColor: 'rgba(0,0,0,0.5)',
                                       color: 'white',
                                       paddingTop: 2,
                                       paddingLeft: 5,
                                       paddingRight: 5,
                                       bottom: 0,
                                       marginLeft: 'auto',
                                       borderBottomLeftRadius: 8,
                                    }}
                                 >
                                    {kbValueNumberFormatter.format(item.size)}
                                 </div>
                              </div>
                           );
                        })}
                     </div>
                  ) : (
                     <>
                        <h2>
                           <b>{t?.page_settings_account_quickanswers_media}</b>
                        </h2>
                        <Typography>{t?.upload_area_description}</Typography>
                     </>
                  )}
               </div>
               <input
                  type='file'
                  multiple='multiple'
                  accept='.png,.svg,.jpg,.jpeg'
                  id='payload-select'
                  hidden
                  onChange={handleFile}
               />
               <ButtonOK
                  label={t['page_settings_account_quickanswers_media']}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     '&:hover': {
                        backgroundColor: '#105CBB',
                     },
                     display: 'block',
                     marginLeft: 'auto',
                     marginRight: 'auto',
                     width: 'auto',
                     marginTop: 10,
                     marginBottom: 10,
                  }}
                  onClick={() => {
                     document.getElementById('payload-select').click();
                  }}
               />

               <ModalButtonField>
                  <ButtonCancel
                     className={classes.modalButtons}
                     onClick={onCancel}
                     label={t['comp_button_cancel']}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        '&:hover': {
                           backgroundColor: '#e0e0e0',
                        },
                     }}
                  />
                  <ButtonOK
                     className={classes.modalButtons}
                     label={t['comp_button_save']}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        '&:hover': {
                           backgroundColor: '#105CBB',
                        },
                        float: 'right',
                     }}
                     type='submit'
                     disabled={!isDirty || !isValid}
                  />
               </ModalButtonField>
               {/* </Grid> */}
               {/* </Grid>
            </Paper> */}
            </form>
         )}
      </Grid>
   );
};

export default ModalContent;

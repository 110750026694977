import React, { useContext, useMemo } from 'react';
import MaterialTable from 'material-table';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { priceFormat } from '@helper/functions';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
   btnContainer: {
      margin: '15px 0px',
   },
   primaryColor: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   fbold: {
      fontWeight: 'bold',
   },
}));

const RenderTitle = ({ title }) => {
   const classes = useStyles();
   return (
      <Typography variant='body1' className={classes.primaryColor}>
         {title}
      </Typography>
   );
};

const getColumns = (classes, t) => [
   {
      title: t?.payment_no,
      field: 'invoiceNo',
      render: (rowData) => <RenderTitle title={rowData?.invoiceNo} />,
   },
   {
      title: t?.invoice_date,
      field: 'createdAt',
      render: (rowData) => <RenderTitle title={moment(parseInt(rowData?.createdAt)).format('DD.MM.YYYY')} />,
   },
   {
      title: t?.amount_paid,
      field: 'amountPaid',
      render: (rowData) => <RenderTitle title={priceFormat(rowData?.amountPaid)} />,
   },
   {
      title: t?.payment_method,
      field: 'paymentMethod',
      render: (rowData) => (
         <RenderTitle title={rowData?.paymentMethod === 'CREDIT_CARD' ? t?.credit_card : rowData?.paymentMethod} />
      ),
   },
   {
      title: t?.payment_status,
      field: 'paymentStatus',
      render: (rowData) => {
         return (
            <Typography
               style={{
                  color: rowData.paymentStatus === 'Paid' ? '#26BF8A' : 'red',
               }}
            >
               {rowData.paymentStatus === 'Paid' ? t?.paid : t?.unpaid}
            </Typography>
         );
      },
   },
   {
      title: t?.payment_invoice,
      field: 'paymentInvoice',
      render: (rowData) => {
         return (
            <Grid container justifyContent='space-between'>
               <IconButton
                  onClick={() => {
                     if (rowData.paymentInvoice) {
                        window.open(rowData.paymentInvoice);
                     }
                  }}
               >
                  <PDFIcon selected={rowData.paymentStatus === 'Paid'} />
               </IconButton>
            </Grid>
         );
      },
   },
];

const InvoiceTable = ({ data }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   const COLUMNS = useMemo(() => getColumns(classes, t), [classes, t]);
   return (
      <div style={{ width: '100%' }}>
         <MaterialTable
            columns={COLUMNS}
            data={data || []}
            title=''
            components={{
               Toolbar: () => null,
            }}
            style={{
               display: 'grid',
               gridTemplateColums: '1fr',
               gridTemplateRows: 'auto 1fr auto',
               height: '100%',
            }}
            options={{
               headerStyle: {
                  backgroundColor: '#F7F7FA',
                  color: '#385273',
                  fontSize: '1.1rem',
                  fontWeight: 'bold',
                  fontFamily: 'Helvetica',
               },
               exportFileName: 'customer_invoices',
               tableLayout: 'auto',
               paging: true,
               sorting: true,
               pageSize: 10,
               pageSizeOptions: [10, 20, 50],
            }}
         />
      </div>
   );
};

export default InvoiceTable;

const PDFIcon = ({ selected }) => {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         fill={selected ? '#157cfc' : 'grey'}
         width='29.26'
         height='33.143'
         viewBox='0 0 29.26 33.143'
      >
         <g id='Group_1653' data-name='Group 1653' transform='translate(0 0)'>
            <path
               id='Path_1605'
               data-name='Path 1605'
               d='M38.639,185.343V175.081A2.172,2.172,0,0,1,41.3,173.5v-.831c0-3.1.014-6.193-.01-9.29a1.727,1.727,0,0,1,.953-1.752H58.469c.141.149.287.293.422.447,2,2.294,4.017,4.58,6,6.9a1.733,1.733,0,0,1,.331,1.005c.04,1.147.015,2.3.015,3.526a2.171,2.171,0,0,1,2.664,1.577V185.2c-.343,1.361-1.316,1.778-2.662,1.659,0,2.1-.02,4.13.01,6.156a1.719,1.719,0,0,1-.953,1.753H42.244a1.69,1.69,0,0,1-.95-1.741c.037-1.1.009-2.207.009-3.31v-2.867A2.24,2.24,0,0,1,38.639,185.343ZM63.913,170.23c-1.8,0-3.557,0-5.31,0-.85,0-1-.153-1-1.016,0-1.848,0-3.695,0-5.542,0-.243-.025-.486-.038-.724H42.643v10.516h21.27ZM42.639,186.894v6.187H63.912v-6.187Zm7.273-6.458c0,1.43.009,2.86-.006,4.29,0,.386.077.612.523.606,1.128-.013,2.262.069,3.383-.016a4.538,4.538,0,0,0,4.422-3.35c.757-2.779-.1-5.683-3.415-6.245a27.458,27.458,0,0,0-4.474-.061c-.431,0-.437.3-.436.623C49.916,177.668,49.912,179.052,49.912,180.436ZM43.8,185.293V182a19.676,19.676,0,0,0,2.633-.423,2.956,2.956,0,0,0,2.109-3.257,2.781,2.781,0,0,0-2.63-2.656c-1.206-.118-2.433-.009-3.651-.027-.554-.008-.683.24-.679.761.023,2.744.01,5.489.013,8.233,0,.221.024.442.037.666ZM65.528,179.6h-3.4v-2.2h3.647V175.65H59.937v9.631h2.177v-3.875h3.415Z'
               transform='translate(-38.639 -161.63)'
            />
            <path
               id='Path_1606'
               data-name='Path 1606'
               d='M136.249,274.258c1.269-.168,2.47-.159,3.359.921a4.01,4.01,0,0,1,.138,4.076c-.8,1.319-2.087,1.43-3.5,1.352Z'
               transform='translate(-122.713 -258.571)'
            />
            <path
               id='Path_1607'
               data-name='Path 1607'
               d='M75.911,276.774v-2.851c.858-.143,1.705-.256,2.308.5a1.349,1.349,0,0,1,0,1.68C77.665,276.9,76.83,276.867,75.911,276.774Z'
               transform='translate(-70.742 -258.261)'
            />
         </g>
      </svg>
   );
};

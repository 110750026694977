import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import { useHistory } from 'react-router-dom';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { API_URL } from '@helper/config';
import { getSocialMediaFromType } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';
import { Paper, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { GET_PERSON_CUSTOM_FIELDS } from '@queries/Settings/Service/GraphQL/CustomFields/query';
import axios from 'axios';
import FileDownload from 'js-file-download';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useWindowResize from '../../hooks/useWindowResize';
import { UPDATE_PERSON_MUTATION } from '../../queries/Person/Service/GraphQL/mutation';
import { GET_CONTACTS_COUNT, GET_CUSTOMER_CLIENT_LIST } from '../../queries/Person/Service/GraphQL/query';
import { CREATED_PERSON_SUBSCRIPTION } from '../../queries/Person/Service/GraphQL/subscription';
import { PERSON_GROUP_LIST, PERSON_GROUP_LIST_COUNT } from '../../queries/PersonGroups/Service/GraphQL/query';
import AddEditContact from './addEditContact';
import DataGrid from './contactTable';

const useStyles = makeStyles((theme) => ({
   paper: {
      borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
      width: '100%',
      borderRadius: 15,
      overflowY: 'auto',
      overflowX: 'hidden',
   },
   content: {
      flexGrow: 1,
      overflowX: 'hidden',
      height: '100vh',
      overflowY: 'auto',
   },
   container: {
      margin: 0,
      height: 'calc(100% - 65px)',
      // padding:20,
   },
   appBarSpacer: theme.mixins.toolbar,
   root: {
      height: 450,
      // width: '100%',
      width: '100%',
      padding: 20,
      borderRadius: 20,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   exportModalTitle: {
      fontSize: '1.4rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },
   selectFileCountContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.2rem',
      backgroundColor: '#F7F7F7',
      borderRadius: 5,
      height: 50,
      padding: 10,
   },
   dateContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 5,
      height: 50,
      // padding: 2,
      marginTop: 5,
      marginBottom: 15,
   },
   dateBox: {
      border: '1px solid #EDEDED',
      padding: 10,
      borderRadius: 5,
      marginTop: 5,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const Contacts = () => {
   const classes = useStyles();
   const history = useHistory();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector((reducer) => reducer.personReducer.person);
   const token = useSelector((reducer) => reducer.personReducer.token);
   const { width } = useWindowResize();
   const [exportModalVisibility, setExportModalVisibility] = useState(false);
   const [exportLoading, setExportLoading] = useState(false);
   const [selectGroup, setSelectGroup] = useState({ _id: -1 });
   const [queryParams, setQueryParams] = useState({
      pageNumber: 1,
      pageSize: 20,
      customer: person.customer._id,
      // name: '',
      fromDate: moment().subtract(1, 'year').endOf('day').valueOf(),
      toDate: moment().endOf('day').valueOf(),
      type: null,
      team: null,
   });

   const [modalConfig, setModalConfig] = useState({
      show: false,
      mode: 'INFO' /* INFO | NEW */,
      row: {},
   });
   const [searchQuery, setSearchQuery] = useState('');

   const [personGroupList, setPersonGroupList] = useState([]);
   const [personGroupListCount, setPersonGroupListCount] = useState(0);
   const [personRow, setPersonRow] = useState(null);
   const [text, setText] = useState('');

   useEffect(() => {
      const time = setTimeout(() => {
         setSearchQuery(text);
      }, 500);

      return () => clearTimeout(time);
   }, [text]);

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div
                  style={{
                     color: '#000',
                     fontSize: '13px',
                     cursor: 'pointer',
                     fontWeight: '500',
                  }}
                  onClick={() => closeSnackbar(key)}
               >
                  {' '}
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, closeSnackbar, enqueueSnackbar, history]);

   const [getClients, { loading: loadingClientList }] = useLazyQuery(GET_CUSTOMER_CLIENT_LIST, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setPersonGroupList(data.customerClientList.data);
      },
   });

   const [getClientsGroup, { loading: loadingGroupList }] = useLazyQuery(PERSON_GROUP_LIST, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setPersonGroupList(data.personGroupList.data.docs);
         selectGroup?.isDynamic && setPersonGroupListCount(data.personGroupList.data.docsCount);
      },
   });

   const [getContactsCount, { loading: loadingContactsCount }] = useLazyQuery(GET_CONTACTS_COUNT, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setPersonGroupListCount(data.getContactsCount.data);
      },
   });

   const [getClientsGroupCount, { loading: loadingGroupListCount }] = useLazyQuery(PERSON_GROUP_LIST_COUNT, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         setPersonGroupListCount(data.getPersonGroupListCount.data);
      },
   });

   const [getPersonCustomField, { data: personCustomField }] = useLazyQuery(GET_PERSON_CUSTOM_FIELDS, {
      fetchPolicy: 'no-cache',
   });

   useEffect(() => {
      if (selectGroup._id !== -1) {
         getClientsGroup({
            variables: { ...queryParams, name: searchQuery, _id: selectGroup._id, team: queryParams.team?._id },
         });
      } else {
         getClients({ variables: { ...queryParams, name: searchQuery, team: queryParams.team?._id } });
      }
   }, [getClients, getClientsGroup, queryParams, searchQuery, selectGroup]);

   useEffect(() => {
      if (selectGroup._id !== -1 && selectGroup?.isDynamic === false) {
         getClientsGroupCount({
            variables: { ...queryParams, name: searchQuery, _id: selectGroup._id, team: queryParams.team?._id },
         });
      } else if (!selectGroup?.isDynamic) {
         getContactsCount({ variables: { ...queryParams, name: searchQuery, team: queryParams.team?._id } });
      }
   }, [getContactsCount, getClientsGroupCount, queryParams, searchQuery, selectGroup]);

   const [updatePersonMutation, { loading: updateClientPersonLoading }] = useMutation(UPDATE_PERSON_MUTATION, {
      async update(
         cache,
         {
            data: {
               updatePerson: { data: updatePerson },
            },
         },
      ) {
         let index = personGroupList.findIndex((item) => item?._id.toString() === updatePerson?._id.toString());

         if (index > -1) {
            setPersonGroupList([...personGroupList.slice(0, index), updatePerson, ...personGroupList.slice(index + 1)]);
         }
      },
   });

   useSubscription(CREATED_PERSON_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: ({
         subscriptionData: {
            data: {
               createdPerson: { data: createdPerson },
            },
         },
      }) => {
         setPersonGroupList([createdPerson, ...personGroupList]);
         setPersonGroupListCount((prevState) => prevState + 1);
      },
   });

   const handleShowRow = useCallback((row) => {
      setPersonRow(row);
   }, []);

   useEffect(() => {
      if (personCustomField) {
         setModalConfig({
            show: true,
            mode: 'INFO',
            row: {
               ...personRow,
               personCustomFields: personCustomField?.personCustomFields?.data,
            },
         });
      }
   }, [personCustomField]);

   const handleCloseModal = useCallback(() => {
      setModalConfig({
         show: false,
         mode: 'INFO',
         row: {},
      });
      setPersonRow(null);
   }, []);

   const handleGroupChange = useCallback((selected) => {
      if (selected._id === -1) {
         setSelectGroup({ _id: -1 });
      } else {
         setSelectGroup(selected);
      }
   }, []);

   const handlePlatformChange = useCallback((platform) => {
      setQueryParams((prev) => ({ ...prev, type: platform === 'ALL' ? null : platform }));
   }, []);

   const handleTeamChange = useCallback((team) => {
      setQueryParams((prev) => ({ ...prev, team: team === 'ALL' ? null : team }));
   }, []);

   const handleDateChange = useCallback((fromDate, toDate) => {
      setQueryParams((prev) => ({
         ...prev,
         fromDate: fromDate,
         toDate: moment(toDate).endOf('day').valueOf(),
      }));
   }, []);

   const handlePageSizeChange = useCallback((pageSize) => {
      setQueryParams((prev) => ({ ...prev, pageSize }));
   }, []);

   const handlePageNumberChange = useCallback((pageNumber) => {
      setQueryParams((prev) => ({ ...prev, pageNumber }));
   }, []);

   const handleSaveRowDate = (toBeSavedData) => {
      // run update mutation
      updatePersonMutation({
         variables: {
            _id: toBeSavedData._id,
            name: toBeSavedData.name,
            surname: toBeSavedData.surname,
            phone: toBeSavedData.phone,
            email: toBeSavedData.email,
            personCustomFields: toBeSavedData.personCustomFields.length > 0 ? toBeSavedData.personCustomFields : null,
            state: toBeSavedData.state ? toBeSavedData.state.label : null,
            country: toBeSavedData.country ? toBeSavedData.country.label : null,
            tags: toBeSavedData.tags,
            language: toBeSavedData.language ? toBeSavedData.language.label : null,
         },
      })
         .then(
            ({
               data: {
                  updatePerson: { data: updatePerson },
               },
            }) => {
               handleCloseModal();
               enqueueSnackbar(` ${updatePerson.name} ${t['general_information_updated_successfully']}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {t['page_chat_typo_close']}
                  </div>
               ),
            });
         });
   };

   const handleExportModal = () => {
      setExportModalVisibility(true);
   };

   const handleExport = async () => {
      setExportLoading(true);
      return axios
         .post(
            `${API_URL}/Person/download-contacts`,
            { ...queryParams, groupId: selectGroup._id, team: queryParams.team?._id },
            { responseType: 'blob', headers: { authorization: token } },
         )
         .then((result) => {
            FileDownload(result.data, result.headers['file-name']);
            setExportLoading(false);
            setExportModalVisibility(false);
         })
         .catch((error) => {
            // on error event
            setExportLoading(false);
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     {t['page_chat_typo_close']}
                  </div>
               ),
            });
         });
   };

   return (
      <main className={classes.content} style={{ padding: width < 960 ? 0 : 20 }}>
         {(exportLoading || updateClientPersonLoading) && <div className='loading' />}
         <div className={classes.appBarSpacer} />
         <Grid container className={classes.container} wrap='nowrap'>
            <Paper elevation={1} className={classes.paper}>
               <DataGrid
                  getPersonCustomField={getPersonCustomField}
                  loading={loadingClientList || loadingContactsCount || loadingGroupList || loadingGroupListCount}
                  onRowClicked={handleShowRow}
                  totalCount={personGroupListCount}
                  excelExport={handleExportModal}
                  data={personGroupList}
                  width={width}
                  text={text}
                  setText={setText}
                  onPageSizeChange={handlePageSizeChange}
                  onPageNumberChange={handlePageNumberChange}
                  pageNumber={queryParams.pageNumber}
                  onSearchChanged={setSearchQuery}
                  onGroupChanged={handleGroupChange}
                  selectedGroupData={selectGroup}
                  onPlatformChanged={handlePlatformChange}
                  onTeamChanged={handleTeamChange}
                  selectedPlatform={queryParams.type}
                  selectedTeam={queryParams.team}
                  searchValue={queryParams.name}
                  dateFilter={{
                     onDateFilterChange: handleDateChange,
                     fromDate: queryParams.fromDate,
                     toDate: queryParams.toDate,
                  }}
               />
            </Paper>
            {modalConfig.mode && (
               <Modal open={modalConfig.show} onClose={handleCloseModal} modalHeader={t?.user_information}>
                  <AddEditContact
                     mode={modalConfig.mode}
                     row={modalConfig.row}
                     onSave={handleSaveRowDate}
                     onCancel={handleCloseModal}
                  />
               </Modal>
            )}
            <Modal
               open={exportModalVisibility}
               onClose={() => setExportModalVisibility(false)}
               modalHeader={t['page_contacts_excel_download_as_excel']}
            >
               <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  className={classes.selectFileCountContainer}
               >
                  <Typography style={{ fontSize: '1.1rem', fontWeight: 'bold', color: '#212121' }}>
                     {t['page_contacts_excel_file_person_count']}
                  </Typography>
                  <Typography style={{ fontSize: '1.1rem', color: '#212121' }}>{personGroupListCount}</Typography>
               </Grid>
               <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  className={classes.dateContainer}
                  xs={9}
                  sm={12}
               >
                  <Typography
                     style={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                        paddingLeft: 8,
                        color: '#212121',
                     }}
                  >
                     {t['page_contacts_excel_select_date']}
                  </Typography>
                  <Grid className={classes.dateBox}>
                     <Typography style={{ color: '#212121' }}>
                        {moment(queryParams.fromDate).format('DD-MM-YYYY')} -
                        {moment(queryParams.toDate).format('DD-MM-YYYY')}
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  className={classes.dateContainer}
                  xs={12}
               >
                  <Typography
                     style={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                        paddingLeft: 8,
                        color: '#212121',
                     }}
                  >
                     {t?.selected_plaftorm}
                  </Typography>
                  <Grid className={classes.dateBox}>
                     <Typography style={{ fontSize: '1rem', color: '#212121' }}>
                        {queryParams.type === null || queryParams.type === 'ALL'
                           ? t['page_contacts_excel_all_platforms']
                           : getSocialMediaFromType(queryParams.type)}
                     </Typography>
                  </Grid>
               </Grid>
               <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  className={classes.dateContainer}
                  xs={12}
               >
                  <Typography
                     style={{
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                        paddingLeft: 8,
                        color: '#212121',
                     }}
                  >
                     {t['selected_team']}
                  </Typography>
                  <Grid className={classes.dateBox}>
                     <Typography style={{ fontSize: '1rem', color: '#212121' }}>
                        {queryParams.team === null || queryParams.team === 'ALL'
                           ? t['page_contacts_excel_all_teams']
                           : queryParams?.team?.teamName}
                     </Typography>
                  </Grid>
               </Grid>
               {/* Modal Buttons */}
               <ModalButtonField>
                  <ButtonCancel
                     onClick={() => setExportModalVisibility(false)}
                     label={t['comp_button_cancel']}
                     className={classes.modalButtons}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        '&:hover': {
                           backgroundColor: '#e0e0e0',
                        },
                     }}
                  />
                  <ButtonOK
                     onClick={handleExport}
                     label={t['page_contacts_excel_download_file']}
                     className={classes.modalButtons}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        '&:hover': {
                           backgroundColor: '#105CBB',
                        },
                        float: 'right',
                     }}
                  />
               </ModalButtonField>
            </Modal>
         </Grid>
      </main>
   );
};

export default Contacts;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { Box, ClickAwayListener, FormControlLabel, Grid, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { Node } from 'reactflow';
import InfoIcon from '@mui/icons-material/Info';

import {
   FlowBlockType,
   IFlowBlockField,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { LanguageContext } from '@helper/locale/langContext';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { useMutation } from '@apollo/client';
import { UPDATE_BLOCK_MUTATION } from '@queries/Automation/mutation';
import CustomSwitch from '@components/Switch';
import QuickReplyModal from '@modules/Automation/FlowBots/components/quickReplyModal';
import { RootStateOrAny, useSelector } from 'react-redux';

interface WelcomeMsgBlocksProps {
   leftNodes: Node[];
   setIsBtnSendMsgDisable: React.Dispatch<React.SetStateAction<boolean>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   isSaveSendMsg: boolean;
   setIsSaveSendMsg: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   clickedNodeId: string;
}

const WelcomeMsgBlocks: React.FC<WelcomeMsgBlocksProps> = ({
   leftNodes,
   setIsBtnSendMsgDisable,
   isSaveSendMsg,
   setIsSaveSendMsg,
   clickedNodeId,
   isLoading,
   setIsLoading,
   setContentNodes,
   setLeftNodes,
}) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const divRef = useRef(null);

   const useStyles = makeStyles((theme) => ({
      textArea: {
         backgroundColor: '#F7F7FA',
         borderRadius: '5px',
         border: '1px solid #E7E7E7',
         outline: 'none',
         resize: 'none',
         width: '100%',
         minHeight: '80px',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '10px',
         '&:focus': {
            backgroundColor: '#fff',
            border: '2px solid #147CFC',
         },
      },
      textAreaError: {
         backgroundColor: '#F7F7FA',
         borderRadius: '5px',
         border: '1px solid #FF7474',
         outline: 'none',
         resize: 'none',
         width: '100%',
         minHeight: '80px',
         fontSize: '1rem',
         overflow: 'hidden',
         padding: '10px',
         '&:focus': {
            backgroundColor: '#fff',
            border: '2px solid #FF7474',
         },
      },
      item: {
         display: 'flex',
         flexDirection: 'column',
      },
      reply: {
         borderRadius: '10px',
         background: '#C3DDFF',
         cursor: 'pointer',
         padding: '10px',
         textAlign: 'left',
      },

      btn: {
         height: 36,
         width: '40%',
         border: '1px dashed #E7E7E7',
         backgroundColor: '#F7F7FA',
         borderRadius: '10px',
         background: 'transparent',
         cursor: 'pointer',
         float: 'right',
      },

      container: {
         display: 'flex',
         flexDirection: 'column',
         fontSize: '1rem',
         position: 'relative',
         height: '100%',
      },
      boxText: {
         width: '100%',
         overflow: 'hidden',
         paddingBottom: '15px',
      },
      miniBox: {
         position: 'absolute',
         bottom: -15,
         right: 2,
         width: '26%',
         borderRadius: 7,
         float: 'right',
         display: 'grid',
         gridTemplateColumns: 'repeat(3, 1fr)',
         gap: '1px',
         color: '#fff',
         backgroundColor: 'black',
         padding: '2px 4px',
         cursor: 'pointer',
         zIndex: 1,
      },
   }));

   const classes = useStyles();
   const [anchorElField, setAnchorElField] = useState<null | HTMLElement>(null);
   const [anchorElFieldQuickRepli, setAnchorElFieldQuickRepli] = useState<null | HTMLElement>(null);
   const [anchorElWarning, setAnchorElWarning] = useState<null | HTMLElement>(null);
   const [menuId, setMenuId] = useState<string>('');
   const [wordCounts, setWordCounts] = useState<{ [key: string]: number }>({});
   const [activeTextareaId, setActiveTextareaId] = useState<string | null>(null);
   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
   const [toUpdate, setToUpdate] = useState<{ _id: string; fieldsValue: string }[]>([]);
   const [toUpdateQR, setToUpdateQR] = useState<{ _id: string }[]>([]);
   const [isCreate, setIsCreate] = useState(false);
   const [selectedItem, setSelectedItem] = useState<{ title: string | null }>();
   const [selectedBlockId, setSelectedBlockId] = useState<{ _id: string | null; QRindex: number | null }>();
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);

   const handleUpdateFlowBlockInput = async (blockId: string, input: string) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: {
                     type: IFlowBlockFieldTypes.Text,
                     value: input,
                  },
               },
            },
            onCompleted: (res) => {
               setIsLoading(false);
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };
   const handleUpdateFlowBlockQuickReply = async (blockId: string, blockFields: IFlowBlockField[]) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: blockFields.map((field: any) => {
                     for (let [key, value] of Object.entries(field)) {
                        if (value == null) {
                           delete field[key];
                        }
                     }
                     return field;
                  }),
               },
            },
            onCompleted: (res) => {
               setIsLoading(false);
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const handleMenuFieldClose = () => {
      setAnchorElField(null);
   };
   const modalClose = () => {
      setAnchorElFieldQuickRepli(null);
      setSelectedItem({ title: null });
      setSelectedBlockId({ _id: null, QRindex: null });
   };

   const handleModalOpen = (event: React.MouseEvent<HTMLElement>, blockId: string) => {
      setSelectedBlockId({ _id: blockId, QRindex: null });
      setIsCreate(true);
      setAnchorElFieldQuickRepli(event.currentTarget);
   };
   const handleEditModalOpen = (
      event: React.MouseEvent<HTMLElement>,
      value: string | undefined,
      blockId: string,
      qrIndex: number,
   ) => {
      setIsCreate(false);
      if (value) {
         setSelectedItem({ title: value });
         setSelectedBlockId({ _id: blockId, QRindex: qrIndex });
      }
      setAnchorElFieldQuickRepli(event.currentTarget);
   };

   const handleMenuFieldOpen = (id: string) => {
      setAnchorElField(divRef.current);
      setMenuId(id);
   };

   const handlePickerFieldOpen = (id: string) => {
      setShowEmojiPicker(true);
      setActiveTextareaId(id);
   };

   const [isValidMessage, setIsValidMessage] = useState(true);

   const handleMessageBtnChange = (blockId: string, value: string | undefined) => {
      if (value !== '') {
         setIsBtnSendMsgDisable(false);
         setIsValidMessage(true);
      } else {
         setIsBtnSendMsgDisable(true);
         setIsValidMessage(false);
      }
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (value !== undefined) {
               if (item._id === blockId) {
                  return {
                     ...item,
                     fields: item.fields.map((field) => ({
                        ...field,
                        requiredButtonMessage: value,
                     })),
                  };
               }
            }
            return item;
         });

         return updatedBlockState;
      });
      setToUpdateQR([{ _id: blockId }]);
   };

   const handleAddQuickReply = (value: string) => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item.type === FlowBlockType.SendQuickReplyMessage) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.quickReplies) {
                        // If quickReplies exist, merge the new quick reply with the existing ones
                        return {
                           ...field,
                           quickReplies: [...field.quickReplies, { type: 'quickReply', title: value }],
                        };
                     } else {
                        // If quickReplies doesn't exist, create a new array with the new quick reply
                        return {
                           ...field,
                           quickReplies: [{ type: 'quickReply', title: value }],
                        };
                     }
                  }),
               };
            }
            return item;
         });

         return updatedBlockState;
      });
   };
   const handleUpdateQuickReply = (value: string) => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === selectedBlockId?._id) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.quickReplies) {
                        return {
                           ...field,
                           quickReplies: field.quickReplies.map((quick, index) => {
                              if (index === selectedBlockId.QRindex) {
                                 return {
                                    ...quick,
                                    title: value,
                                 };
                              }
                              return quick;
                           }),
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });

         return updatedBlockState;
      });
   };

   const handleDeleteQuickReply = () => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === selectedBlockId?._id) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.quickReplies) {
                        return {
                           ...field,
                           quickReplies: field.quickReplies.filter((_, index) => index !== selectedBlockId.QRindex),
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });

         return updatedBlockState;
      });
      modalClose();
   };

   const calculateWordCount = (id: string, text: string) => {
      const wordCount = text.length;
      setWordCounts((prevWordCounts) => ({
         ...prevWordCounts,
         [id]: 1500 - wordCount,
      }));
   };

   const handleBlockInputChange = (id: string, text: string) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     value: text,
                  })),
               };
            }
            return item;
         });

         if (text.length > 0) {
            setIsBtnSendMsgDisable(false);
         } else {
            setIsBtnSendMsgDisable(true);
         }
         calculateWordCount(id, text);
         return updatedBlockState;
      });

      setToUpdate([{ _id: id, fieldsValue: text }]);
   };

   const backendBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];

   //save
   useEffect(() => {
      if (isSaveSendMsg && !isLoading) {
         setIsSaveSendMsg(false);
         setIsBtnSendMsgDisable(false);
         const existingBlocks = blockState.filter((block) =>
            backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         // Add all new blocks to the backend
         const save = async () => {
            // Update fields
            if (toUpdate.length > 0) {
               setIsLoading(true);
               toUpdate.forEach((item) => {
                  // Check if the item's _id exists in existingBlocks
                  if (existingBlocks.some((block) => block._id === item._id)) {
                     handleUpdateFlowBlockInput(item._id, item.fieldsValue);
                  }
               });
               setToUpdate([]);
            }

            if (toUpdateQR.length > 0) {
               setIsLoading(true);

               // Check if the item's _id exists in existingBlocks
               const blocks = existingBlocks.find((block) => block._id === toUpdateQR[0]?._id);
               if (blocks) {
                  handleUpdateFlowBlockQuickReply(toUpdateQR[0]?._id, blocks.fields);
               }

               setToUpdateQR([]);
            }
         };
         save();
         setToUpdate([]);
      }
   }, [isSaveSendMsg]);

   const listBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks;

   // default
   useEffect(() => {
      setBlockState([]);
      if (listBlocks.length > 0) {
         const data = listBlocks.map((item: IFlowBlockTemplate) => {
            const value = item.fields[0]?.value;
            if (value) {
               calculateWordCount(item._id, value);
            }
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });
         setBlockState(data);
      }
   }, [clickedNodeId]);

   const addEmoji = (e: any, id: string) => {
      const emoji = e.native;

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id && item.fields.some((field) => field.type === IFlowBlockFieldTypes.Text)) {
               const updatedFields = item.fields.map((field) => {
                  if (field.type === IFlowBlockFieldTypes.Text) {
                     const textAreaValue = field.value || '';
                     const textAreaElement = document.getElementById(`textarea-${id}`) as HTMLTextAreaElement | null;
                     if (textAreaElement) {
                        const start = textAreaElement.selectionStart;
                        const end = textAreaElement.selectionEnd;
                        const updatedValue = textAreaValue.substring(0, start) + emoji + textAreaValue.substring(end);
                        calculateWordCount(id, updatedValue);
                        setToUpdate([{ _id: id, fieldsValue: updatedValue }]);
                        return { ...field, value: updatedValue };
                     }
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });

         setIsBtnSendMsgDisable(false);
         return updatedBlockState;
      });

      setShowEmojiPicker(false);
   };

   const addField = (name: string, id: string) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id && item.fields.some((field) => field.type === IFlowBlockFieldTypes.Text)) {
               const updatedFields = item.fields.map((field) => {
                  if (field.type === IFlowBlockFieldTypes.Text) {
                     const textAreaValue = field.value || '';
                     const textAreaElement = document.getElementById(`textarea-${id}`) as HTMLTextAreaElement | null;
                     if (textAreaElement) {
                        const start = textAreaElement.selectionStart;
                        const end = textAreaElement.selectionEnd;
                        const updatedValue = textAreaValue.substring(0, start) + name + textAreaValue.substring(end);
                        calculateWordCount(id, updatedValue);
                        setToUpdate([{ _id: id, fieldsValue: updatedValue }]);
                        return { ...field, value: updatedValue };
                     }
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });

         setIsBtnSendMsgDisable(false);
         return updatedBlockState;
      });

      handleMenuFieldClose();
   };

   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         let numRows = 0;
         let consecutiveChars = 0;

         for (let i = 0; i < content.length; i++) {
            if (content[i] !== '\n') {
               consecutiveChars++;
               if (consecutiveChars === 36) {
                  numRows++;
                  consecutiveChars = 0;
               }
            } else {
               consecutiveChars = 0;
            }
         }

         const enters = (content.match(/\n/g) || []).length;

         numRows += enters;

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: numRows + 2, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };

   // making enabled or disabled the save button
   function hasNegativeValue(obj: { [x: string]: number }) {
      for (let key in obj) {
         if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] < 0) {
               return true;
            }
         }
      }
      return false;
   }
   useEffect(() => {
      const isInvalid = blockState.some((block) =>
         block.fields.some((field) => field.type === 'text' && field.value === ''),
      );
      const qrBlock = blockState.find((block) => block.type === FlowBlockType.SendQuickReplyMessage)?.fields[0]
         ?.quickReplies;

      if (isInvalid || (qrBlock && qrBlock.length <= 0) || hasNegativeValue(wordCounts)) {
         setIsBtnSendMsgDisable(true);
      }
   }, [blockState]);

   const qrBlock = blockState.find((block) => block.type === FlowBlockType.SendQuickReplyMessage)?.fields[0]
      ?.quickReplies;
   const isHasQickReply = qrBlock && qrBlock?.length !== 1;
   const isWelcome = true;

   return (
      <Grid container className={classes.container} wrap='nowrap'>
         <Grid style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Grid className={classes.item}>
               {blockState.map((item) => (
                  <Grid key={item._id}>
                     {item.fields.map((field, index) => {
                        let content = null;

                        if (field.type === IFlowBlockFieldTypes.Text) {
                           content = (
                              <div style={{ width: '100%' }}>
                                 <ClickAwayListener onClickAway={() => setActiveTextareaId(null)}>
                                    <Box className={classes.boxText}>
                                       <textarea
                                          id={`textarea-${item._id}`}
                                          key={item._id}
                                          className={
                                             field.value && field.value?.length > 1500
                                                ? classes.textAreaError
                                                : classes.textArea
                                          }
                                          name='rawText'
                                          onFocus={() => setActiveTextareaId(item._id)}
                                          placeholder={t.atomation_flow_enter_message}
                                          value={field.value}
                                          onChange={(event) => {
                                             handleBlockInputChange(item._id, event.target.value);
                                          }}
                                          {...calculateTextareaSize(field.value)}
                                       />

                                       {activeTextareaId && activeTextareaId === item._id && (
                                          <div style={{ position: 'sticky', top: 0, width: '100%' }} ref={divRef}>
                                             <div className={classes.miniBox}>
                                                <div onClick={() => handleMenuFieldOpen(item._id)}>
                                                   <DataObjectIcon
                                                      style={{
                                                         display: 'flex',
                                                         justifyContent: 'center',
                                                         fontSize: '18px',
                                                      }}
                                                   />
                                                </div>
                                                <EmojiEmotionsIcon
                                                   onClick={() => handlePickerFieldOpen(item._id)}
                                                   style={{
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      fontSize: '18px',
                                                   }}
                                                />
                                                <span
                                                   style={{
                                                      color: 'green',
                                                      display: 'flex',
                                                      justifyContent: 'center',
                                                      fontSize: '14px',
                                                   }}
                                                >
                                                   {wordCounts[item._id] === 0 ? 0 : wordCounts[item._id] || 1500}
                                                </span>
                                             </div>
                                          </div>
                                       )}
                                    </Box>
                                 </ClickAwayListener>
                                 {showEmojiPicker && activeTextareaId === item._id && (
                                    <ClickAwayListener onClickAway={() => setShowEmojiPicker(false)}>
                                       <div style={{ zIndex: '9999', position: 'sticky', marginTop: '-15px' }}>
                                          <Picker
                                             onSelect={(e) => addEmoji(e, item._id)}
                                             style={{ width: '100%' }}
                                             title={t['emoji_selector_title']}
                                          />
                                       </div>
                                    </ClickAwayListener>
                                 )}
                              </div>
                           );
                        }
                        return (
                           <Grid>
                              <div key={item._id} style={{ display: 'flex', position: 'relative' }}>
                                 {content}
                              </div>
                           </Grid>
                        );
                     })}
                  </Grid>
               ))}
               {/* Quick reply flow will be here */}
               {blockState.map((block) => (
                  <div>
                     {block.type === FlowBlockType.SendQuickReplyMessage ? (
                        <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '3rem' }}>
                           {block.fields.map((field) => (
                              <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                                 <div
                                    style={{
                                       display: 'flex',
                                       flexDirection: 'column',
                                       textAlign: 'right',
                                       float: 'right',
                                    }}
                                 >
                                    {field.quickReplies?.map((quick, quickIndex) => (
                                       <div
                                          style={{
                                             display: 'flex',
                                             gap: '5px',
                                             justifyContent: 'flex-end',
                                             margin: '10px 0 0 0',
                                          }}
                                       >
                                          <span
                                             className={classes.reply}
                                             style={{ alignSelf: 'flex-end' }}
                                             key={quickIndex}
                                             onClick={(e) => handleEditModalOpen(e, quick.title, block._id, quickIndex)}
                                          >
                                             {quick.title}
                                          </span>
                                       </div>
                                    ))}
                                 </div>
                                 {field.quickReplies && field.quickReplies?.length < 10 && (
                                    <Grid>
                                       <button className={classes.btn} onClick={(e) => handleModalOpen(e, block._id)}>
                                          + {t.atomation_flow_quick_reply}
                                       </button>
                                    </Grid>
                                 )}

                                 <div>
                                    <Grid
                                       style={{
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          width: '100%',
                                          marginBottom: '5px',
                                          marginTop: '1.2rem',
                                       }}
                                    >
                                       <div style={{ display: 'flex', gap: '3px' }}>
                                          <InfoIcon style={{ color: '#C3DDFF', marginTop: '-2px' }} />
                                          <span style={{ fontSize: '14px' }}>{t.atomation_flow_can_you_response}</span>
                                       </div>
                                       <div style={{ marginRight: '-15px' }}>
                                          <FormControlLabel
                                             control={<CustomSwitch checked={field.isButtonRequired} />}
                                             label=''
                                          />
                                       </div>
                                    </Grid>
                                    {field.isButtonRequired && (
                                       <textarea
                                          className={classes.textArea}
                                          name='rawText'
                                          placeholder={t.atomation_flow_enter_message}
                                          value={field.requiredButtonMessage || ''}
                                          onChange={(e) => handleMessageBtnChange(block._id, e.target.value)}
                                          style={{ borderColor: !isValidMessage ? 'red' : '' }}
                                       />
                                    )}
                                 </div>
                              </div>
                           ))}
                        </Grid>
                     ) : (
                        ''
                     )}
                     <Menu
                        anchorEl={anchorElFieldQuickRepli}
                        open={Boolean(anchorElFieldQuickRepli)}
                        onClose={modalClose}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        PaperProps={{
                           style: {
                              width: '450px',
                              borderRadius: '10px',
                              padding: '10px 20px 0 20px',
                           },
                        }}
                     >
                        <QuickReplyModal
                           value={selectedItem}
                           onSubmit={isCreate ? handleAddQuickReply : handleUpdateQuickReply}
                           closeModal={modalClose}
                           deleteReply={handleDeleteQuickReply}
                           isCreate={isCreate}
                           isHasQickReply={isHasQickReply}
                           isWelcome={isWelcome}
                        />
                     </Menu>
                     <Menu
                        anchorEl={anchorElWarning}
                        open={Boolean(anchorElWarning)}
                        onClose={() => setAnchorElWarning(null)}
                        style={{ marginTop: '-2rem', marginLeft: '1rem' }}
                        PaperProps={{
                           style: {
                              borderRadius: '10px',
                              padding: '10px',
                           },
                        }}
                     >
                        <span>{t.atomation_flow_warning}</span>
                     </Menu>
                  </div>
               ))}
            </Grid>
         </Grid>

         <Menu
            anchorEl={anchorElField}
            open={Boolean(anchorElField)}
            onClose={handleMenuFieldClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            style={{ marginTop: 5 }}
            PaperProps={{
               style: {
                  width: '363px',
                  borderRadius: '5px',
                  marginTop: '20px',
               },
            }}
         >
            <MenuItem onClick={() => addField(t.atomation_flow_first_name_value, menuId)}>
               {t.atomation_flow_first_name}
            </MenuItem>
            <MenuItem onClick={() => addField(t.atomation_flow_last_name_value, menuId)}>
               {t.atomation_flow_last_name}
            </MenuItem>
            <MenuItem onClick={() => addField(t.atomation_full_name_value, menuId)}>{t.atomation_full_name}</MenuItem>
            <MenuItem onClick={() => addField(t.atomation_flow_email_value, menuId)}>{t.atomation_flow_email}</MenuItem>
            <MenuItem onClick={() => addField(t.atomation_flow_phone_value, menuId)}>{t.atomation_flow_phone}</MenuItem>
         </Menu>
      </Grid>
   );
};

export default WelcomeMsgBlocks;

import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Node } from 'reactflow';
import { LanguageContext } from '@helper/locale/langContext';
import UserSendMessage from './userSendMessage';
import UserVisitePage from './userVisitePage';
import { FlowBlockType } from '@modules/Automation/FlowBots/types';

const useStyles = makeStyles((theme) => ({}));

interface TriggerBlocksProps {
   leftNodes: Node[];
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   isSaveTrigger: boolean;
   setIsSaveTrigger: React.Dispatch<React.SetStateAction<boolean>>;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
   isLoading: boolean;
   clickedNodeId: string;
}

const TriggerBlocks: React.FC<TriggerBlocksProps> = ({
   leftNodes,
   setLeftNodes,
   setContentNodes,
   setIsBtnDisable,
   isSaveTrigger,
   setIsSaveTrigger,
   clickedNodeId,
   setIsLoading,
   isLoading,
   setIsNewNode,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const blocks = leftNodes.find((node) => node.id === clickedNodeId)?.data?.flowBlocks;

   return (
      <Grid>
         <Typography style={{ fontWeight: 'bold', marginBottom: '5px' }}>
            {t.atomation_flow_trigger_block_when_will_start}
         </Typography>
         {blocks[0]?.type === FlowBlockType.AllMessageReceived ? (
            <UserSendMessage
               leftNodes={leftNodes}
               setIsBtnDisable={setIsBtnDisable}
               isSaveTrigger={isSaveTrigger}
               setIsSaveTrigger={setIsSaveTrigger}
               clickedNodeId={clickedNodeId}
               setLeftNodes={setLeftNodes}
               setContentNodes={setContentNodes}
               isLoading={isLoading}
               setIsLoading={setIsLoading}
            />
         ) : (
            <UserVisitePage
               leftNodes={leftNodes}
               setIsBtnDisable={setIsBtnDisable}
               isSaveTrigger={isSaveTrigger}
               setIsSaveTrigger={setIsSaveTrigger}
               clickedNodeId={clickedNodeId}
               setLeftNodes={setLeftNodes}
               setContentNodes={setContentNodes}
               isLoading={isLoading}
               setIsLoading={setIsLoading}
               setIsNewNode={setIsNewNode}
            />
         )}
      </Grid>
   );
};

export default TriggerBlocks;

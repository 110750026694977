import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CircularProgress, Divider, Grid, IconButton, TextField, Tooltip, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ImageRoundedIcon from '@material-ui/icons/ImageRounded';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import ReactSelect from 'react-select';
import {
   SET_QUESTION_CLICKED_ITEM,
   SET_QUESTION_CLICKED_REPLY_TYPE,
   SET_QUESTION_CLICKED_STATUS,
} from '@store/actions/questions';
import { capitalizeWithUpperCase } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';
import { trimLongText } from '@helper/textUtils';
import { MyTheme } from '@styles/config';
import MyTypography from '@components/MyTypography';
import { QnAPersonStatusType, QnAPlatformType, QnAStatus } from '../../../util/types';
import QuestionMessageItem from './messageItem';
import Modal from '@components/Modal/modal';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { useMutation } from '@apollo/client';
import { SEND_REPLY_QUESTION_MUTATION } from '@queries/Questions/Service/mutation';
import useQnaQuestions from '@modules/Questions/shared-hooks/useQnaQuestions';
import { useSnackbar } from 'notistack';
import SendIcon from '@material-ui/icons/Send';
import { Button } from '@material-ui/core';
import { ArchiveChatIcon, SpamChatIcon } from '@assets/icons/NavbarIcons/chatHeaderIcons';
import { Cancel, Delete, QuestionAnswer } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';

interface Props {
   item: any;
   messages?: any;
}

const getColorFromStatus = (status: QnAStatus) => {
   switch (status) {
      case QnAStatus.WAITING:
         return '#23A02E';
      case QnAStatus.ANSWERED:
         return '#266FE5';
      case QnAStatus.TIMED_OUT:
         return undefined;
      case QnAStatus.REJECTED:
      case QnAStatus.DELETED:
      case QnAStatus.FAILED:
         return 'red';
      default:
         return undefined;
   }
};

const useStyles = makeStyles<MyTheme, Props & { clickedItem: any; item: any }>((theme: MyTheme) => ({
   root: {
      padding: theme.spacing(1),
      borderRadius: 12,
      cursor: 'pointer',
      transition: 'all 300ms',
      '&:hover': {
         borderColor: theme.palette.primary.main,
      },
      wordBreak: 'break-all',
   },
   messagesContainer: {
      // margin: theme.spacing(1, 0),
   },
   inputRoot: {
      height: 38,
   },
   topSection: {},
   divider: {
      width: '100%',
      marginTop: theme.spacing(2),
   },
   productImg: {
      height: '100%',
      width: '100%',
      maxWidth: '100px',
      maxHeight: '100px',
      borderRadius: 12,
      border: '1px solid #eee',
      objectFit: 'inherit',
      [theme.breakpoints.down('xs')]: {
         maxWidth: '100%',
         maxHeight: '100%',
      },
   },
   input: {
      margin: '0px !important',
      marginTop: '8px !important',
      backgroundColor: '#f2f2f2',
      opacity: 0.9,
      borderRadius: 4,
   },
   inputColor: {
      color: ({ item }) => getColorFromStatus(item?.status),
      fontSize: '0.9rem',
   },
   notchedOutline: {
      borderWidth: '1px',
      borderColor: 'hsl(0,0%,90%) !important',
   },
   textTitle: {
      paddingBottom: 4,
      [theme.breakpoints.down('xs')]: {
         paddingTop: theme.spacing(1),
      },
   },
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalText: {
      color: '#797575',
      fontSize: '1rem',
      margin: 20,
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      borderTop: '1px solid #F1F3F7',
   },

   buttonsContainer: {
      borderRadius: 10,
      maxWidth: 500,
      overflowX: 'auto',
      overflowY: 'hidden',
   },

   actionButton: {
      padding: 0,
      height: 36,
      width: 36,
      minWidth: 36,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F0F0F0',
      border: '1px solid #D8D8D8',
      borderRadius: 8,
      fontSize: 20,
      marginRight: 4,
      '&:focus': {
         backgroundColor: '#D8D8D8',
      },
   },

   statusButton: {
      color: 'white',
      maxWidth: 100,
      padding: 0,
      paddingLeft: '3px',
      paddingRight: '3px',
      height: 36,
      minWidth: 36,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F0F0F0',
      border: '1px solid #D8D8D8',
      borderRadius: 8,
      fontSize: 14,
      marginRight: 4,
      '&:focus': {
         backgroundColor: '#D8D8D8',
      },
   },
}));

const QuestionContentItem: React.FC<{
   item: any;
   hasAuthority: boolean | null;
   isSending?: boolean;
}> = (props) => {
   const { item, isSending, hasAuthority } = props;
   const clickedItem = useSelector((state: any) => state.questionsState.contentState.clickedItem);
   const [isConnected, setIsConnected] = useState(true);
   const [sendReplyMutation, { loading: submitLoading }] = useMutation(SEND_REPLY_QUESTION_MUTATION);
   const { setActiveQuestionMessages, setActiveQuestion, sendReplyQuestion } = useQnaQuestions();
   const { enqueueSnackbar } = useSnackbar();
   const classes = useStyles({
      ...props,
      clickedItem,
      item,
   });
   const activeQuestion = useSelector((state: any) => state.questionsState.activeQuestion);
   const [showMarkAsRepliedModal, setShowMarkAsRepliedModal] = useState(false);
   const [showDeleteModal, setDeleteModal] = useState(false);
   const [showPrivateReplyModal, setPrivateReplyModal] = useState({
      active: false,
      privateMessage: item.privateReply != undefined ? item.privateReply : null,
   });
   const {
      lang: { translation: t, shortTitle },
   }: any = useContext(LanguageContext);
   const [replyTypeState, setReplyTypeState] = useState({
      label: t['qna_' + item.replyType],
      value: item.replyType,
   });

   useEffect(() => {
      setReplyTypeState({
         label: t['qna_' + item.replyType],
         value: item.replyType,
      });
      setStatusState({
         label: t?.qna_reply,
         value: 'reply',
      });
   }, [t]);

   const markAsReplied = (reply: String) => {
      sendReplyMutation({
         variables: {
            _id: item._id,
            replyType: item.replyType,
            rejectReason: reply == '' ? null : reply,
            answer: 'mark_as_replied',
            platformType: item.platformType,
         },
      })
         .then(
            ({
               data: {
                  updateQnA: { data },
               },
            }) => {
               // update state
               sendReplyQuestion(data);

               // show success message
               enqueueSnackbar(t?.general_information_updated_successfully, {
                  variant: 'success',
                  autoHideDuration: 2500,
               });

               // update clicked item
               dispatch({
                  type: SET_QUESTION_CLICKED_ITEM,
                  payload: null,
               });

               setShowMarkAsRepliedModal(false);
            },
         )
         .catch((err) => {
            enqueueSnackbar(err.message, {
               variant: 'error',
               autoHideDuration: 2500,
            });
            setShowMarkAsRepliedModal(false);
         });
   };

   const deleteQnA = (reply: String) => {
      sendReplyMutation({
         variables: {
            _id: item._id,
            replyType: item.replyType,
            rejectReason: reply,
            answer: '',
            platformType: item.platformType,
         },
      })
         .then(
            ({
               data: {
                  updateQnA: { data },
               },
            }) => {
               // update state
               sendReplyQuestion(data);

               // show success message
               enqueueSnackbar(t?.general_information_deleted_successfully, {
                  variant: 'success',
                  autoHideDuration: 2500,
               });

               // update clicked item
               dispatch({
                  type: SET_QUESTION_CLICKED_ITEM,
                  payload: null,
               });

               setDeleteModal(false);
            },
         )
         .catch((err) => {
            enqueueSnackbar(err.message, {
               variant: 'error',
               autoHideDuration: 2500,
            });
            setDeleteModal(false);
         });
   };

   const replyPrivate = (reply: String) => {
      sendReplyMutation({
         variables: {
            _id: item._id,
            replyType: 'private_reply',
            rejectReason: null,
            answer: reply,
            platformType: item.platformType,
         },
      })
         .then(
            ({
               data: {
                  updateQnA: { data },
               },
            }) => {
               // update upper state
               sendReplyQuestion(data);
               setPrivateReplyModal({
                  active: false,
                  privateMessage: reply,
               });
               enqueueSnackbar(t?.qna_page_private_reply_sucess, {
                  variant: 'success',
                  autoHideDuration: 2500,
               });
            },
         )
         .catch((err) => {
            enqueueSnackbar(t?.qna_page_private_reply_error, {
               variant: 'error',
               autoHideDuration: 2500,
            });

            setPrivateReplyModal({
               active: false,
               privateMessage: null,
            });
         });
   };

   const OPTIONS = (isInstagramOrFacebook?: boolean, isN11?: boolean) => [
      {
         label: t?.qna_reply,
         value: 'reply',
      },
      {
         label: t?.qna_mark_as_replied,
         value: 'mark_as_replied',
      },
      !isN11 && {
         label: isInstagramOrFacebook ? t?.qna_delete : t?.qna_reject,
         value: 'reject',
      },
   ];

   const REPLY_TYPE_OPTIONS = (t: any) => [
      {
         label: t?.qna_Public,
         value: 'PUBLIC',
      },
      {
         label: t?.qna_Private,
         value: 'PRIVATE',
      },
   ];

   const [statusState, setStatusState] = useState({
      label: t?.qna_reply,
      value: 'reply',
   });

   const replyOptions = REPLY_TYPE_OPTIONS(t);
   const dispatch = useDispatch();
   const theme = useTheme();
   const isJoinableOrEnabled =
      activeQuestion?.joinedPerson === null &&
      activeQuestion?.status !== QnAPersonStatusType.ARCHIVE &&
      activeQuestion?.canJoin;

   const getRejectOptions = OPTIONS(
      activeQuestion?.platformType === QnAPlatformType.FACEBOOK ||
         activeQuestion?.platformType === QnAPlatformType.INSTAGRAM,
      activeQuestion?.platformType === QnAPlatformType.N11,
   ).filter((e) => e != false);

   const isTimeOutChat = activeQuestion?.status === QnAPersonStatusType.ARCHIVE;

   const onClickHandler = useCallback(() => {
      if (
         item?._id.toString() !== clickedItem?._id.toString() &&
         !isJoinableOrEnabled &&
         !isTimeOutChat &&
         item.status === QnAStatus.WAITING
      ) {
         dispatch({
            type: SET_QUESTION_CLICKED_ITEM,
            payload: item,
         });
         dispatch({
            type: SET_QUESTION_CLICKED_REPLY_TYPE,
            payload: 'PUBLIC',
         });
         dispatch({
            type: SET_QUESTION_CLICKED_STATUS,
            payload: 'reply',
         });
      }
   }, [clickedItem?._id, dispatch, isJoinableOrEnabled, isTimeOutChat, item]);

   useEffect(() => {
      dispatch({
         type: SET_QUESTION_CLICKED_STATUS,
         payload: statusState.value,
      });
   }, [dispatch, statusState]);

   useEffect(() => {
      dispatch({
         type: SET_QUESTION_CLICKED_REPLY_TYPE,
         payload: replyTypeState.value,
      });
   }, [dispatch, replyTypeState]);

   useEffect(() => {
      if (activeQuestion?.platformType === QnAPlatformType.HEPSIBURADA && activeQuestion?.hepsiburadaAccount) {
         setIsConnected(activeQuestion?.hepsiburadaAccount?.isConnected);
      } else if (activeQuestion?.platformType === QnAPlatformType.TRENDYOL && activeQuestion?.trendyolAccount) {
         setIsConnected(activeQuestion?.trendyolAccount?.isConnected);
      } else if (
         activeQuestion?.facebookPage &&
         activeQuestion?.facebookPage !== null &&
         activeQuestion?.platformType === QnAPlatformType.FACEBOOK
      ) {
         setIsConnected(activeQuestion?.facebookPage?.isCommentEnable);
      } else if (
         activeQuestion?.instagramPage &&
         activeQuestion?.instagramPage !== null &&
         activeQuestion?.platformType === QnAPlatformType.INSTAGRAM
      ) {
         setIsConnected(activeQuestion?.instagramPage?.isCommentEnable);
      }
   }, [
      activeQuestion?.facebookPage,
      activeQuestion?.hepsiburadaAccount,
      activeQuestion?.instagramPage,
      activeQuestion?.platformType,
      activeQuestion?.trendyolAccount,
   ]);

   // const isTrendyolOrHepsiburada =
   //    activeQuestion?.platformType === QnAPlatformType.HEPSIBURADA ||
   //    activeQuestion?.platformType === QnAPlatformType.TRENDYOL;

   const hasParent = item?.shadowParent ? item?.shadowParent : item?.parentComment?._id ? item?.parentComment : null;

   return (
      <div
         onClick={hasAuthority !== false ? onClickHandler : undefined}
         style={{
            paddingRight: 6,
         }}
      >
         <Grid
            style={{
               border: `2px solid ${
                  clickedItem?._id.toString() === item?._id.toString() ? theme.palette.primary.main : 'lightgrey'
               }`,
               margin: '8px 0px',
               backgroundColor: item.status !== QnAStatus.WAITING ? '#f0f3f7' : '#fff',
            }}
            container
            className={classes.root}
         >
            <Grid alignItems='center' className={classes.topSection} container>
               <Grid item xs={12} sm={1} md={2} lg={1} justifyContent='center' alignItems='center'>
                  {item?.productImageURL ? (
                     <img
                        src={item.productImageURL}
                        style={{
                           filter: item.status !== QnAStatus.WAITING ? 'grayscale(80%)' : undefined,
                           opacity: item.status !== QnAStatus.WAITING ? 0.6 : undefined,
                        }}
                        className={classes.productImg}
                        alt='qpien'
                     />
                  ) : (
                     <ImageRoundedIcon
                        className={classes.productImg}
                        style={{
                           filter: item.status !== QnAStatus.WAITING ? 'grayscale(80%)' : undefined,
                           opacity: item.status !== QnAStatus.WAITING ? 0.6 : undefined,
                        }}
                        color='inherit'
                     />
                  )}
               </Grid>

               <Grid
                  item
                  xs={12}
                  sm={11}
                  md={10}
                  lg={11}
                  style={{
                     paddingLeft: 16,
                  }}
               >
                  <Grid justifyContent='space-between' alignItems='center' container>
                     <Grid item sm={11} xs={12}>
                        <MyTypography color='inherit' className={classes.textTitle} bold>
                           {item.saleCode
                              ? t.order_saleCode_title + item.saleCode
                              : trimLongText(item.productName, 347)}
                        </MyTypography>
                     </Grid>
                     <Grid item sm={1} container justifyContent='flex-end' xs={12}>
                        <IconButton
                           onClick={() => {
                              if (item?.productLink) {
                                 window.open(item.productLink, '_blank');
                              }
                           }}
                           size='medium'
                        >
                           <OpenInNewIcon color='inherit' />
                        </IconButton>
                     </Grid>
                  </Grid>

                  <Grid
                     className={classes.buttonsContainer}
                     container
                     direction='row'
                     wrap='nowrap'
                     alignItems='center'
                  >
                     <Tooltip title={t['page_chat_typo_reject']}>
                        <Button
                           className={`${classes.statusButton}`}
                           disabled={true}
                           disableTouchRipple
                           style={{
                              color:
                                 item.status === QnAStatus.ANSWERED || item.status === QnAStatus.WAITING
                                    ? 'green'
                                    : item.status === QnAStatus.NOT_ANSWERED
                                    ? 'gray'
                                    : 'red',
                           }}
                        >
                           {t['qna_' + item.status]}
                        </Button>
                     </Tooltip>

                     {(item.platformType == 'INSTAGRAM' || item.platformType == 'FACEBOOK') && (
                        <Tooltip title={t['qna_page_private_reply_title']}>
                           <Button
                              className={classes.actionButton}
                              disabled={
                                 showPrivateReplyModal.privateMessage != null
                                    ? false
                                    : moment(item.createdAt).add(7, 'days').isBefore(moment()) ||
                                      isJoinableOrEnabled ||
                                      item.status === QnAStatus.FAILED ||
                                      item.status === QnAStatus.REJECTED ||
                                      item.status === QnAStatus.DELETED ||
                                      isTimeOutChat ||
                                      !isConnected
                              }
                              color={showPrivateReplyModal.privateMessage != null ? 'primary' : 'default'}
                              onClick={() => {
                                 setPrivateReplyModal({
                                    active: true,
                                    privateMessage: showPrivateReplyModal.privateMessage,
                                 });
                              }}
                              disableTouchRipple
                           >
                              <SendIcon />
                           </Button>
                        </Tooltip>
                     )}
                     {(item.platformType == 'INSTAGRAM' || item.platformType == 'FACEBOOK') && (
                        <Tooltip title={t['page_chat_typo_reject']}>
                           <Button
                              className={classes.actionButton}
                              disabled={
                                 isJoinableOrEnabled ||
                                 item.status !== QnAStatus.WAITING ||
                                 isTimeOutChat ||
                                 !isConnected
                              }
                              onClick={() => {
                                 setDeleteModal(true);
                              }}
                              disableTouchRipple
                           >
                              <Delete />
                           </Button>
                        </Tooltip>
                     )}

                     <Tooltip title={t['mark_as_replied_title']}>
                        <Button
                           className={classes.actionButton}
                           disabled={
                              isJoinableOrEnabled ||
                              hasAuthority === false ||
                              item.status !== QnAStatus.WAITING ||
                              isTimeOutChat ||
                              !isConnected
                           }
                           onClick={() => {
                              setShowMarkAsRepliedModal(true);
                           }}
                           disableTouchRipple
                        >
                           <Cancel />
                        </Button>
                     </Tooltip>
                  </Grid>
               </Grid>
               <Divider variant='fullWidth' className={classes.divider} />
            </Grid>
            <Grid container className={classes.messagesContainer}>
               {hasParent && (
                  <Grid
                     style={{
                        marginTop: 8,
                        marginLeft: 4,
                     }}
                     container
                     xs={8}
                     item
                  >
                     <MyTypography variant={'caption'} bold color='primary'>
                        {hasParent?.fullName || hasParent?.QnAChat?.fullName}
                        <MyTypography
                           display={'inline'}
                           style={{ paddingLeft: 4 }}
                           color='textSecondary'
                           variant={'caption'}
                        >
                           {hasParent?.question}
                        </MyTypography>
                     </MyTypography>
                  </Grid>
               )}

               <QuestionMessageItem
                  type='AUDIO'
                  text={item.question}
                  time={moment(item.createdAt).locale(shortTitle.toLowerCase()).fromNow()}
                  isMine={false}
                  isCommentAnswer={
                     hasParent &&
                     (activeQuestion?.platformType === QnAPlatformType.FACEBOOK || QnAPlatformType.FACEBOOK)
                  }
               />

               {(item?.answer || item?.status === QnAStatus.FAILED) && (
                  <QuestionMessageItem
                     type='AUDIO'
                     text={item?.answer}
                     time={moment(item?.updatedAt).locale(shortTitle.toLowerCase()).fromNow()}
                     isMine={true}
                     isSending={isSending}
                     isRejectedMessage={item?.status === QnAStatus.REJECTED}
                     isDeletedMessage={item?.status === QnAStatus.DELETED}
                     isTimeoutMessage={item?.status === QnAStatus.TIMED_OUT}
                     isFailedMessage={item?.status === QnAStatus.FAILED}
                     isNotAnsweredMessage={item?.status === QnAStatus.NOT_ANSWERED}
                     isModifiedOnPlatform={item?.isModifiedOnPlatform}
                  />
               )}
            </Grid>
         </Grid>

         <Modal
            open={showPrivateReplyModal.active}
            onClose={() =>
               setPrivateReplyModal({
                  active: false,
                  privateMessage: showPrivateReplyModal.privateMessage,
               })
            }
         >
            <div className={classes.modalContainer}>
               <div className={classes.modalHeader}>
                  <Typography className={classes.modalTitle}>{t['qna_page_private_reply_title']}</Typography>
               </div>
               <div className={classes.childrenContainer}>
                  <Typography className={classes.modalText}>
                     <Alert severity='info'>{t['qna_page_private_reply_desc']}</Alert>
                     {showPrivateReplyModal.privateMessage != null ? (
                        <TextField
                           multiline
                           maxRows='5'
                           label={t['qna_page_private_reply_replied']}
                           fullWidth
                           variant='outlined'
                           disabled
                           value={showPrivateReplyModal.privateMessage}
                        />
                     ) : (
                        <TextField
                           multiline
                           maxRows='5'
                           label={t['qna_page_private_reply_replied']}
                           fullWidth
                           id='reply'
                           variant='outlined'
                        />
                     )}
                  </Typography>
               </div>
               <div className={classes.modalFooter}>
                  <ButtonCancel
                     label={t['comp_button_cancel']}
                     onClick={() =>
                        setPrivateReplyModal({ active: false, privateMessage: showPrivateReplyModal.privateMessage })
                     }
                     style={{
                        backgroundColor: 'transparent',
                        color: '#263548',
                        border: '1px solid #263548',
                     }}
                  />
                  {showPrivateReplyModal.privateMessage == null && (
                     <ButtonOK
                        label={t['page_chat_typo_send']}
                        onClick={() => {
                           let input: HTMLInputElement = document.getElementById('reply') as HTMLInputElement;
                           if (input.value == '') {
                              enqueueSnackbar(t?.qna_page_private_reply_empty, {
                                 variant: 'error',
                                 autoHideDuration: 2500,
                              });
                           } else {
                              replyPrivate(input.value);
                           }
                        }}
                        endIcon={
                           submitLoading ? (
                              <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                           ) : (
                              <></>
                           )
                        }
                     />
                  )}
               </div>
            </div>
         </Modal>

         <Modal open={showDeleteModal} onClose={() => setDeleteModal(false)}>
            <div className={classes.modalContainer}>
               <div className={classes.modalHeader}>
                  <Typography className={classes.modalTitle}>{t?.delete_comment_title}</Typography>
               </div>
               <div className={classes.childrenContainer}>
                  <div className={classes.childrenContainer}>
                     <Typography className={classes.modalText}>{t?.delete_comment_text}</Typography>
                  </div>
                  <Typography className={classes.modalText}>
                     <TextField
                        multiline
                        maxRows='5'
                        label={t['enter_a_reason']}
                        fullWidth
                        id='reply'
                        variant='outlined'
                     />
                  </Typography>
               </div>
               <div className={classes.modalFooter}>
                  <ButtonCancel
                     label={t['comp_button_cancel']}
                     onClick={() => setDeleteModal(false)}
                     style={{
                        backgroundColor: 'transparent',
                        color: '#263548',
                        border: '1px solid #263548',
                     }}
                  />
                  <ButtonOK
                     label={t?.delete_comment_btn}
                     onClick={() => {
                        let input: HTMLInputElement = document.getElementById('reply') as HTMLInputElement;
                        deleteQnA(input.value);
                     }}
                     endIcon={
                        submitLoading ? (
                           <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                        ) : (
                           <></>
                        )
                     }
                  />
               </div>
            </div>
         </Modal>

         <Modal open={showMarkAsRepliedModal} onClose={() => setShowMarkAsRepliedModal(false)}>
            <div className={classes.modalContainer}>
               <div className={classes.modalHeader}>
                  <Typography className={classes.modalTitle}>{t?.mark_as_replied_title}</Typography>
               </div>
               <div className={classes.childrenContainer}>
                  <div className={classes.childrenContainer}>
                     <Typography className={classes.modalText}>{t?.mark_as_replied_text}</Typography>
                  </div>
                  <Typography className={classes.modalText}>
                     <TextField
                        multiline
                        maxRows='5'
                        label={t['enter_a_reason']}
                        fullWidth
                        id='mark-as-answered-reply'
                        variant='outlined'
                     />
                  </Typography>
               </div>
               <div className={classes.modalFooter}>
                  <ButtonCancel
                     label={t['comp_button_cancel']}
                     onClick={() => setShowMarkAsRepliedModal(false)}
                     style={{
                        backgroundColor: 'transparent',
                        color: '#263548',
                        border: '1px solid #263548',
                     }}
                  />
                  <ButtonOK
                     label={t?.pre_load_forget_pass_sent_OK}
                     onClick={() =>
                        markAsReplied((document.getElementById('mark-as-answered-reply') as HTMLInputElement).value)
                     }
                     endIcon={
                        submitLoading ? (
                           <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                        ) : (
                           <></>
                        )
                     }
                  />
               </div>
            </div>
         </Modal>
      </div>
   );
};

export default QuestionContentItem;

import { gql } from '@apollo/client';

export const QNA_CHAT_CORE_FIELDS_FRAGMENT = gql`
   fragment QnAChatFields on QnAChat {
      _id
      teams {
         _id
         color
         drivers
         teamName
         isMain
      }
      fullName
      platformType
      platformId
      canJoin
      isJoinable
      status
      color
      lastQnA {
         _id
         question
         answer
         productName
         productLink
         productImageURL
         privateReply
         saleCode
         replyType
         orderNumber
         status
         rejectReason
         type
         isModifiedOnPlatform
         createdAt
         updatedAt
         platformType
      }
      tag {
         _id
         name
         color
         customer {
            _id
         }
         createdAt
      }
      customer {
         _id
      }
      # if person null means nobody joined chat yet
      joinedPerson {
         _id
         name
         surname
         username
         phone
         email
         photo
         type
         country
         city
         state
         address
         postCode
         setup
         status
         createdAt
      }
      ownerPerson {
         _id
         name
         surname
         username
         phone
         email
         photo
         type
         country
         city
         state
         address
         postCode
         setup
         status
         createdAt
      }
      trendyolAccount {
         _id
         isConnected
         storeName
         createdAt
         updatedAt
      }
      hepsiburadaAccount {
         _id
         isConnected
         storeName
         createdAt
         updatedAt
      }
      n11Account {
         _id
         isConnected
         storeName
         createdAt
         updatedAt
      }
      instagramPage
      facebookPage
      createdAt
      updatedAt
      __typename
   }
`;

export const QNA_CORE_FIELDS_FRAGMENT = gql`
   fragment QnAFields on QnA {
      _id
      question
      answer
      privateReply
      productName
      productLink
      saleCode
      platformType
      productImageURL
      replyType
      orderNumber
      status
      rejectReason
      type
      createdAt
      updatedAt
      isModifiedOnPlatform
      __typename
      createdBy {
         _id
         name
         surname
      }
      parentComment {
         _id
         question
         answer
         productName
         productLink
         platformType
         productImageURL
         replyType
         orderNumber
         status
         rejectReason
         type
         createdAt
         updatedAt
         isModifiedOnPlatform
         __typename
         createdBy {
            _id
            name
            surname
         }
         QnAChat {
            _id
            fullName
         }
      }
      shadowParent {
         fullName
         createdAt
         question
      }
   }
`;

import React, { useContext, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { NotificationIcon } from '@assets/icons/NotificationIcons/notificationIcon';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      opacity: 0.8,
   },
   content: {
      backgroundColor: '#fff',
      borderRadius: 15,
      padding: 6,
      paddingLeft: 10,
      paddingRight: 10,
      textAlign: 'left',
      border: '1px solid #eee',
      maxWidth: 600,
      whiteSpace: 'nowrap',
      [theme.breakpoints.down('md')]: {
         whiteSpace: 'normal',
      },
   },
   footer: {
      // fontSize: '0.8rem',
      color: '#7F92A5',
      [theme.breakpoints.down('xs')]: {
         maxWidth: 220,
      },
   },
   metaData: {
      fontSize: '0.8rem',
      // marginTop: 10,
      paddingLeft: 10,
      color: 'grey',
   },
   notificationIcon: {
      width: 25,
      height: 25,
      fill: '#7F92A5',
      paddingLeft: theme.spacing(1),
   },
}));

const detectLang = (text: string, lang: any) => {
   // yeniAd/chat_mutation_tag_replace/#577590/eskiAd/eskiColor
   // code : 0 No sender Name code : 1 SenderName
   const t = lang?.translation;

   const regex = /chat_mutation_tag_(replace|add|remove)/;
   if (regex.test(text)) {
      const str = text.split('/');
      const newTagColorCode = str[2];
      const oldTagColorCode = str[4];

      return {
         tagNameNew: str[0],
         tagNameOld: str[3] ? str[3] + ' ==> ' : '',
         tagEvent: t[str[1]],
         logColorNew: newTagColorCode,
         logColorOld: str[3] ? oldTagColorCode : '',
         code: 1,
      };
   } 
   else if (text.includes('qna_page_archived_qna_log_created_by')) {
      return { tagNameNew: '', tagNameOld: '', tagEvent: t[text], logColorNew: '', logColorOld: '', code: 1 };
   }
   else if (text.includes('qna_page_archived_qna_log')||text.includes('qna_page_auto_out_participant')) {
      return { tagNameNew: '', tagNameOld: '', tagEvent: t[text], logColorNew: '', logColorOld: '', code: 0 };
   }
   else if ( text.includes('qna_page_reopen_qna_log')) {
      return { tagNameNew: '', tagNameOld: '', tagEvent: t[text], logColorNew: '', logColorOld: '', code: 1 };
   } else if (text.includes('qna_page_transferred_qna_log')) {
      const str = text.split('/');
      const code = str[0];
      const name = str[1];
      return {
         tagNameNew: '',
         tagNameOld: '',
         tagEvent: t[code] + ' --> ' + name,
         logColorNew: '',
         logColorOld: '',
         code: 1,
      };
   } else {
      return { tagNameNew: '', tagNameOld: '', tagEvent: t[text], logColorNew: '', logColorOld: '', code: 1 };
   }
};

const QuestionEventItem: React.FC<{
   sender: any;
   text: string;
   time: string | undefined;
}> = ({ sender, text, time }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const tagInfo = useMemo(() => detectLang(text, lang), [lang, text]);
   const logColorNew = tagInfo?.logColorNew;
   const logColorOld = tagInfo?.logColorOld;
   const tagNameNew = tagInfo?.tagNameNew;
   const tagNameOld = tagInfo?.tagNameOld;
   const tagEvent = tagInfo?.tagEvent;
   const codeText = tagInfo?.code;

   return (
      <Grid container justifyContent='center' className={classes.root}>
         <div className={clsx(classes.content)}>
            <Grid container justifyContent='center' alignItems='center' wrap='nowrap'>
               <Grid item xs={1}>
                  <NotificationIcon className={classes.notificationIcon} />
               </Grid>
               <Grid
                  style={{
                     paddingLeft: 8,
                  }}
                  item
                  xs={11}
               >
                  <Typography variant='subtitle2' align='center' className={classes.footer}>
                     {codeText === 1 ? sender?.name + ' - ' : ''}
                     {tagNameOld && (
                        <Typography
                           component='span'
                           variant='subtitle1'
                           style={{ color: logColorOld, fontWeight: 'bold' }}
                        >
                           #{tagNameOld}
                        </Typography>
                     )}
                     {tagNameNew && (
                        <Typography
                           component='span'
                           variant='subtitle1'
                           style={{ color: logColorNew, fontWeight: 'bold' }}
                        >
                           #{tagNameNew}
                        </Typography>
                     )}
                     {tagEvent}
                  </Typography>
               </Grid>
               <Typography style={{ paddingLeft: 8 }} align='center' variant='subtitle2' className={classes.footer}>
                  {time}
               </Typography>
            </Grid>
         </div>
      </Grid>
   );
};

export default QuestionEventItem;

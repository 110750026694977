export interface ICustomFields {
   _id: string;
   name: string;
   type: string;
   listValues: string[];
   customer: string;
   label: string;
   description: string;
   createdAt: Date;
   updatedAt: Date;
}

export enum EnumCustomFieldsType {
   EMAIL = 'EMAIL',
   TEXT = 'TEXT',
   NUMBER = 'NUMBER',
   DATE = 'DATE',
   URL = 'URL',
   LIST = 'LIST',
   PHONE = 'PHONE',
}

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Paper, Typography, Box } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import clsx from 'clsx';
import { AddOnName } from '@types/enums';
const useStyles = makeStyles((theme) => ({
   mainTitle: {
      fontSize: '1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },
   addionalUsage: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      padding: 20,
      borderTop: '1px solid #eee',
   },
   secondaryTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
   },
   iconColor: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   errorColor: {
      color: 'red',
   },

   root: {
      border: '1px solid #D9D9D9',
      borderRadius: 17,
      boxShadow: 'none',
      paddingBottom: '0px',
      height: '100%',
   },
   mainTitleContainer: {
      borderBottom: '1px solid #D9D9D9',
      padding: '10px 15px',
   },
   p15: {
      padding: 20,
      paddingBottom: 0,
      height: 350,
   },
   topSection: {
      height: 325,
      [theme.breakpoints.down('md')]: {
         height: '100%',
      },
   },
   packageDesc: {
      color: '#757575',
   },
   packageDescInfo: {
      color: '#385273',
   },
}));

const CurrentPackage = ({ customer }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const getCustomerAddOnCustomers = () => {
      const activeCustomers = customer?.package?.addOns?.find((item) => item.name === AddOnName.ACTIVE_CUSTOMER);
      if (activeCustomers) {
         return activeCustomers?.quantity;
      } else return 0;
   };
   return (
      <Grid item xs={12} style={{ height: '100%' }}>
         <Paper elevation={3} className={classes.root}>
            <Grid container direction='column' xs='12'>
               <Grid container justifyContent='flex-start' className={classes.mainTitleContainer}>
                  <Typography className={classes.mainTitle}>{t?.my_current_uses}</Typography>
               </Grid>
               <Box container className={clsx(classes.p15, classes.topSection)}>
                  <Grid container spacing={3}>
                     <Grid item container xs={9}>
                        <Typography className={classes.packageDesc}>{t?.user}</Typography>
                     </Grid>
                     <Grid item container justifyContent='center' xs={3}>
                        <Typography className={classes.packageDescInfo}>
                           {customer.usagePersonCount} /{' '}
                           <Typography
                              style={{
                                 color:
                                    customer?.usagePersonCount === customer?.package?.totalPersonCount
                                       ? 'red'
                                       : '#157CFC',
                              }}
                              component='span'
                           >
                              {customer?.package?.totalPersonCount}{' '}
                           </Typography>{' '}
                        </Typography>
                     </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                     <Grid item container xs={9}>
                        <Typography className={classes.packageDesc}> {t?.monthly_single_customer}: </Typography>
                     </Grid>
                     <Grid item container justifyContent='center' xs={3}>
                        <Typography className={classes.packageDesc}>
                           {' '}
                           {customer?.usageClientCount} /{' '}
                           <Typography
                              style={{
                                 color: customer.usageClientCount === customer.clientCount ? 'red' : '#157CFC',
                              }}
                              component='span'
                           >
                              {customer?.package?.clientCount}
                           </Typography>{' '}
                        </Typography>
                     </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                     <Grid item container xs={9}>
                        <Typography className={classes.packageDesc}> {t?.whatsapp_business_telephone}: </Typography>
                     </Grid>
                     <Grid item container justifyContent='center' xs={3}>
                        <Typography className={classes.packageDesc}>
                           {customer.usageWhatsappBusinessCount} /{' '}
                           <Typography
                              style={{
                                 color:
                                    customer.usageWhatsappBusinessCount === customer.package.totalWhatsappBusinessCount
                                       ? 'red'
                                       : '#157CFC',
                              }}
                              component='span'
                           >
                              {' '}
                              {customer.package.totalWhatsappBusinessCount}{' '}
                           </Typography>
                        </Typography>
                     </Grid>
                  </Grid>
               </Box>
            </Grid>
            <Grid container justifyContent='flex-start' xs='12' alignItems='center' className={classes.addionalUsage}>
               <Typography className={classes.packageDescInfo} style={{ fontWeight: 'bold' }}>
                  {t?.additional_usage}
               </Typography>
               <Grid item container xs='12'>
                  <Typography color='textSecondary' variant='body1'>
                     {t?.active_customer}:
                  </Typography>
                  <Typography component='span' style={{ color: 'orange', paddingLeft: 5 }}>
                     {getCustomerAddOnCustomers()}
                  </Typography>
               </Grid>
               <Grid item container xs='12'>
                  <Typography color='textSecondary' variant='body1'>
                     {t?.add_on_balance}:
                  </Typography>
                  <Typography component='span' style={{ color: 'orange', paddingLeft: 5 }}>
                     ${(customer?.package?.accountBalance || 0).toFixed(2)}
                  </Typography>
               </Grid>
            </Grid>
         </Paper>
      </Grid>
   );
};

export default CurrentPackage;

export enum EventType {
   CREATE = 'CREATE',
   UPDATE = 'UPDATE',
}

export type PlatformEventAction =
   | { type: 'CHANGE_FACEBOOK_EVENT_TYPE_AS_CREATE' }
   | { type: 'CHANGE_FACEBOOK_EVENT_TYPE_AS_UPDATE' }
   | { type: 'CHANGE_INSTAGRAM_EVENT_TYPE_AS_CREATE' }
   | { type: 'CHANGE_INSTAGRAM_EVENT_TYPE_AS_UPDATE' };

export interface PlatformEventState {
   facebookEventType: EventType;
   instagramEventType: EventType;
}

const initialState: PlatformEventState = {
   facebookEventType: EventType.CREATE,
   instagramEventType: EventType.CREATE,
};

const reducer = (state = initialState, action: PlatformEventAction): PlatformEventState => {
   switch (action.type) {
      case 'CHANGE_FACEBOOK_EVENT_TYPE_AS_CREATE':
         return {
            ...state,
            facebookEventType: EventType.CREATE,
         };
      case 'CHANGE_FACEBOOK_EVENT_TYPE_AS_UPDATE':
         return {
            ...state,
            facebookEventType: EventType.UPDATE,
         };
      case 'CHANGE_INSTAGRAM_EVENT_TYPE_AS_CREATE':
         return {
            ...state,
            instagramEventType: EventType.CREATE,
         };
      case 'CHANGE_INSTAGRAM_EVENT_TYPE_AS_UPDATE':
         return {
            ...state,
            instagramEventType: EventType.UPDATE,
         };
      default:
         return state;
   }
};

export default reducer;

import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Node } from 'reactflow';

import LeftPanel from '../FlowBots/FlowBots/LeftPanel/leftPanel';
import ContentPanel from '../FlowBots/FlowBots/ContentPanel';

import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { RootStateOrAny, useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import RulesList from '@modules/Automation/E-commerceRules/RulesList';

const useStyles = makeStyles((theme) => ({
   content: {
      display: 'flex',
      height: '100vh',
      width: '100%',
   },
   mainSpace: {
      width: '100%',
   },
}));

interface FlowProps {}

const RulesFlow: React.FC<FlowProps> = ({}) => {
   const classes = useStyles();
   const [filter, setFilter] = useState<string>('');
   const history = useHistory();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector((rootReducer: RootStateOrAny) => rootReducer.personReducer.person);

   const [showEcommerce, setShowEcommerce] = useState(false);

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div
                  style={{
                     color: '#000',
                     fontSize: '13px',
                     cursor: 'pointer',
                     fontWeight: '500',
                  }}
                  onClick={() => closeSnackbar(key)}
               >
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, closeSnackbar, enqueueSnackbar, history]);

   return (
      <main className={classes.content}>
         <LeftPanel setFilter={setFilter} setShowEcommerce={setShowEcommerce} />
         <div className={classes.mainSpace}>
            <RulesList />
         </div>
      </main>
   );
};

export default RulesFlow;

import { Grid } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Input from '@components/InputField';

const useStyles = makeStyles(() => ({
    buttonField: {
        borderTop: '2px solid #f5f5f5',
        // paddingInline: 20,
        paddingBottom: 20,
        paddingTop: 15,
        width: '100%',
        // position: 'sticky',
        // bottom: 0,
        backgroundColor: '#fff',
    },

}))

const ModalButtonField = (props: any) => {
    const classes = useStyles();

    const {
        children,
        style
    } = props;

    return (
        <Grid container justifyContent='space-between' className={classes.buttonField} style={style}>
            {children}
        </Grid>
    )
}

export default ModalButtonField
import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MinimizeRoundedIcon from '@material-ui/icons/MinimizeRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import { LanguageContext } from '../../../../../../../helper/locale/langContext';
import avatar_1 from '../../../images/avatar_1.png';
import avatar_2 from '../../../images/avatar_2.jpg';
import avatar_3 from '../../../images/avatar_3.jpeg';
import LiveChatSettingsContext from '../../../../../../../contexts/LiveChatSettingsContext';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import { Phone, Instagram, Messanger, LiveChatLogo } from '../../Icons';
import classNames from 'classnames';
import { LIVE_CHAT_ICON_POSITION } from '../../../types';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      borderRadius: 15,
      overflowY: 'auto',
      background: '#F7F7FA',
      marginBottom: '3rem',
   },
   mainContainer: {
      flexGrow: 1,
      overflow: 'hidden',
      width: '100%',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
   },
   preview: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      overflowY: 'hidden',
      borderRadius: '8px',
      width: '100%',
      height: '100%',
      maxWidth: '350px',
      maxHeight: '600px',
      marginLeft: (props) =>
         (props.iconPosition === LIVE_CHAT_ICON_POSITION.RIGHT ||
            props.iconPosition === LIVE_CHAT_ICON_POSITION.TOP_RIGHT) &&
         'auto',
      marginRight: (props) =>
         (props.iconPosition === LIVE_CHAT_ICON_POSITION.LEFT ||
            props.iconPosition === LIVE_CHAT_ICON_POSITION.TOP_LEFT) &&
         'auto',
      boxShadow: '0px 0px 21px -8px rgba(204,204,204,1)',
   },
   header: {
      display: 'flex',
      padding: '12px',
      background: (props) => (props.themeColor ? props.themeColor : '#157CFC'),
   },
   logo: {
      flexShrink: 0,
      width: '50px',
      height: '50px',
      borderRadius: '4px',
   },
   headerTexts: {
      flexGrow: 1,
      color: (props) => props.textColor || '#FFF',
      paddingLeft: '8px',
      paddingRight: '8px',
   },
   greetingTitle: {
      fontSize: '.9rem',
      fontWeight: 600,
   },
   greetingContent: {
      fontSize: '.75rem',
   },
   dropDownIcon: {
      flexShrink: 0,
      transform: 'rotate(180deg)',
      color: (props) => props.textColor,
   },
   mainContentContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'hidden',
      position: 'relative',
   },
   continuationOfHeader: {
      position: 'absolute',
      height: '34px',
      top: '-10px',
      left: 0,
      right: 0,
      background: (props) => (props.themeColor ? props.themeColor : '#157CFC'),
      zIndex: 0,
   },
   mainContent: {
      height: '100%',
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      zIndex: 1,
   },
   chatContainer: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: '8px',
      background: 'white',
      color: 'black',
      padding: '12px',
      marginLeft: '12px',
      marginRight: '12px',
      marginBottom: '12px',
      boxShadow: '0px 0px 21px -8px rgba(204,204,204,1)',
   },
   chatTitle: {
      fontSize: '.75rem',
      fontWeight: 'bold',
   },
   newChatSubtitle: {
      fontSize: '.65rem',
   },
   newChatDown: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '12px',
   },
   newChatAvatars: {
      display: 'flex',
      position: 'relative',
      height: '35px',
   },
   newChatAvatar: {
      position: 'absolute',
      flexShrink: 0,
      width: '35px',
      height: '35px',
      border: '1px solid #FFFFFF',
      borderRadius: '35px',
   },
   newChatAvatar2: {
      left: '8px',
   },
   newChatAvatar3: {
      left: '16px',
   },
   chatButton: {
      cursor: 'normal !important',
      boxShadow: 'none',
      backgroundColor: (props) => props.themeColor,
      color: (props) => props.textColor,
      '&:hover': {
         backgroundColor: (props) => props.themeColor,
         boxShadow: 'none',
      },
      '&:focus': {
         backgroundColor: (props) => props.themeColor,
         boxShadow: 'none',
      },
   },
   chatExample: {
      display: 'flex',
      alignItems: 'center',
      marginTop: '8px',
   },
   chatExampleLeft: {
      flexShrink: 0,
      width: '35px',
      height: '35px',
      borderRadius: '35px',
      marginRight: '8px',
   },
   chatExampleCenter: {
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
   },
   chatExampleRight: {
      flexShrink: 0,
      marginLeft: '8px',
      color: (props) => props.themeColor,
   },
   senderName: {
      fontSize: '.75rem',
      fontWeight: 'bold',
   },
   createdAt: {
      color: '#435369',
      fontWeight: 400,
   },
   exampleMessage: {
      fontSize: '.75rem',
   },
   sellAllConversations: {
      color: (props) => props.themeColor,
      fontSize: '.75rem',
      paddingTop: '.5rem',
      display: 'flex',
      justifyContent: 'flex-end',
   },
   channelsAndLabelContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderRadius: '8px',
      background: 'white',
      color: 'black',
      padding: '4px',
      marginLeft: '12px',
      marginRight: '12px',
      marginBottom: '12px',
      boxShadow: '0px 0px 21px -8px rgba(204,204,204,1)',
   },
   channelsLabel: {
      padding: '4px',
      fontSize: '.9rem',
      color: '#1E2944',
   },
   channelsContainer: {
      display: 'flex',
      alignItems: 'center',
      overflowX: 'auto',
   },
   channelContainer: {
      fontSize: '32px',
      color: '#157CFC',
      flexShrink: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '45px',
      width: '45px',
      border: '1px solid #F7F7FA',
      borderRadius: '8px',
      margin: '2px',
      filter: 'grayscale(100%)',
      opacity: '0.6',
   },
   selectedChannel: {},
   currentChannel: {
      filter: 'grayscale(0%)',
      opacity: '1',
      border: (props) => `1px solid ${props.themeColor}`,
   },
   channelTitleContentAndButton: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px',
   },
   channelTitle: {
      textAlign: 'center',
      color: '#1E2944',
      fontWeight: 'bold',
      fontSize: '1.1rem',
   },
   channelContent: {
      textAlign: 'center',
      fontSize: '.9rem',
      color: '#10223A',
      marginTop: '15px',
      marginBottom: '15px',
   },
   channelButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      marginTop: '10px',
      borderRadius: '10px',
   },
   channelButtonLabel: {
      color: '#FFFFFF',
      marginLeft: '8px',
      fontWeight: '500',
   },
   poweredByQpien: {
      textAlign: 'center',
      color: '#444C64',
      fontSize: '.65rem',
      paddingTop: '10px',
      paddingBottom: '10px',
      borderTop: '1px solid #ddd',
   },
   qpien: {
      color: '#157CFC',
      textAlign: 'center',
   },
}));

const Intro = ({ type }) => {
   const { liveChatPreviewSettings } = useContext(LiveChatSettingsContext);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const {
      imgURL,
      greetingTitle,
      greetingContent,
      newChatTitle,
      newChatSubtitle,
      themeColor,
      textColor,
      titleOldChat,
      iconPosition,
      agentProfiles,
      isWhatsapp,
      isFacebook,
      isInstagram,
      isPhoneCall,
      whatsappButtonTitle,
      whatsappTitle,
      whatsappContent,
      facebookTitle,
      facebookContent,
      facebookButtonTitle,
      instagramTitle,
      instagramContent,
      instagramButtonTitle,
      phoneCallTitle,
      phoneCallContent,
      phoneCallButtonTitle,
   } = liveChatPreviewSettings;

   const classes = useStyles({ themeColor, textColor, iconPosition });
   const [contentType, setContentType] = useState();
   const [title, setTitle] = useState('');
   const [description, setDescription] = useState('');
   const [buttonLabel, setButtonLabel] = useState('');
   const [buttonStyle, setButtonStyle] = useState();

   useEffect(() => {
      if (
         type === 'whatsappChannel' ||
         type === 'facebookChannel' ||
         type === 'instagramChannel' ||
         type === 'phoneCallChannel'
      ) {
         setContentType('channels');
         if (type === 'whatsappChannel') {
            setButtonStyle({
               background: 'rgb(64,217,105) linear-gradient(135deg, rgba(64,217,105,1) 0%, rgba(25,158,61,1) 100%)',
            });
         } else if (type === 'facebookChannel') {
            setButtonStyle({
               background: 'rgb(96,191,255) linear-gradient(135deg, rgba(96,191,255,1) 0%, rgba(0,162,255,1) 100%)',
            });
         } else if (type === 'instagramChannel') {
            setButtonStyle({
               background: 'rgb(254,168,56) linear-gradient(45deg, rgba(254,168,56,1) 0%, rgba(179,43,168,1) 100%)',
            });
         } else if (type === 'phoneCallChannel') {
            setButtonStyle({
               background: 'rgb(100,255,100) linear-gradient(135deg, rgba(100,255,100,1) 0%, rgba(0,205,0,1) 100%)',
            });
         }
      } else {
         setContentType('greeting');
      }
   }, []);

   return (
      <div className={classes.preview}>
         <div className={classes.header}>
            {imgURL && <img className={classes.logo} src={imgURL} alt='logo' />}
            <Box className={classes.headerTexts}>
               <Typography className={classes.greetingTitle}>{greetingTitle}</Typography>
               <Typography className={classes.greetingContent}>{greetingContent}</Typography>
            </Box>
            <MinimizeRoundedIcon className={classes.dropDownIcon} />
         </div>
         <Box className={classes.mainContentContainer}>
            <div className={classes.continuationOfHeader} />
            <div className={classes.mainContent}>
               {contentType === 'greeting' && (
                  <>
                     <Box className={classes.chatContainer}>
                        <Typography className={classes.chatTitle}>{newChatTitle}</Typography>
                        <Typography className={classes.newChatSubtitle}>{newChatSubtitle}</Typography>
                        <Box className={classes.newChatDown}>
                           {agentProfiles && (
                              <div className={classes.newChatAvatars}>
                                 <img
                                    src={avatar_1}
                                    className={classNames(classes.newChatAvatar, classes.newChatAvatar1)}
                                    alt={'avatar_1'}
                                 />
                                 <img
                                    src={avatar_2}
                                    className={classNames(classes.newChatAvatar, classes.newChatAvatar2)}
                                    alt={'avatar_2'}
                                 />
                                 <img
                                    src={avatar_3}
                                    className={classNames(classes.newChatAvatar, classes.newChatAvatar3)}
                                    alt={'avatar_3'}
                                 />
                              </div>
                           )}
                           <Button variant='contained' size='small' className={classes.chatButton} disableTouchRipple>
                              {t['page_settings_typo_platforms_live_chat_appearance_preview_chat_button_label']}
                           </Button>
                        </Box>
                     </Box>
                     <Box className={classes.chatContainer}>
                        <Typography className={classes.chatTitle}>{titleOldChat}</Typography>
                        <div className={classes.chatExample}>
                           {agentProfiles && (
                              <img src={avatar_1} className={classes.chatExampleLeft} alt={'avatar_1'} />
                           )}
                           <div className={classes.chatExampleCenter}>
                              <Typography className={classes.senderName}>
                                 John Doe<span className={classes.createdAt}> - 15:00</span>
                              </Typography>
                              <Typography noWrap className={classes.exampleMessage}>
                                 {t['page_settings_typo_platforms_live_chat_appearance_preview_chat_example_message']}
                              </Typography>
                           </div>
                           <ArrowForwardIosRoundedIcon className={classes.chatExampleRight} />
                        </div>
                        <Typography className={classes.sellAllConversations}>
                           {t['page_settings_typo_platforms_live_chat_appearance_preview_chat_example_button_label']}
                        </Typography>
                     </Box>
                  </>
               )}
               {contentType === 'channels' && buttonStyle && (
                  <>
                     <div className={classes.channelsAndLabelContainer}>
                        <Typography className={classes.channelsLabel}>
                           {t['page_settings_typo_platforms_live_chat_settings_preview_channels']}
                        </Typography>
                        <div className={classes.channelsContainer}>
                           <div className={classNames(classes.channelContainer)}>
                              <LiveChatLogo />
                           </div>
                           {isWhatsapp && (
                              <div
                                 className={classNames(
                                    classes.channelContainer,
                                    type === 'whatsappChannel' && classes.currentChannel,
                                 )}
                              >
                                 <WhatsAppIcon style={{ color: '#22D954', height: 37, width: 37 }} />
                              </div>
                           )}
                           {isFacebook && (
                              <div
                                 className={classNames(
                                    classes.channelContainer,
                                    type === 'facebookChannel' && classes.currentChannel,
                                 )}
                              >
                                 <Messanger />
                              </div>
                           )}
                           {isInstagram && (
                              <div
                                 className={classNames(
                                    classes.channelContainer,
                                    type === 'instagramChannel' && classes.currentChannel,
                                 )}
                              >
                                 <Instagram />
                              </div>
                           )}
                           {isPhoneCall && (
                              <div
                                 className={classNames(
                                    classes.channelContainer,
                                    type === 'phoneCallChannel' && classes.currentChannel,
                                 )}
                              >
                                 <Phone />
                              </div>
                           )}
                        </div>
                     </div>
                     <div className={classes.channelTitleContentAndButton}>
                        <Typography variant='body1' className={classes.channelTitle}>
                           {type === 'whatsappChannel' && whatsappTitle}
                           {type === 'facebookChannel' && facebookTitle}
                           {type === 'instagramChannel' && instagramTitle}
                           {type === 'phoneCallChannel' && phoneCallTitle}
                        </Typography>
                        <Typography variant='body2' className={classes.channelContent}>
                           {type === 'whatsappChannel' && whatsappContent}
                           {type === 'facebookChannel' && facebookContent}
                           {type === 'instagramChannel' && instagramContent}
                           {type === 'phoneCallChannel' && phoneCallContent}
                        </Typography>
                        <div className={classes.channelButtonContainer} style={buttonStyle}>
                           {type === 'whatsappChannel' && (
                              <WhatsAppIcon style={{ color: '#FFFFFF', height: 30, width: 30 }} />
                           )}
                           {type === 'facebookChannel' && <Messanger />}
                           {type === 'instagramChannel' && <Instagram />}
                           {type === 'phoneCallChannel' && <Phone />}

                           <Typography variant='body1' className={classes.channelButtonLabel}>
                              {type === 'whatsappChannel' && whatsappButtonTitle}
                              {type === 'facebookChannel' && facebookButtonTitle}
                              {type === 'instagramChannel' && instagramButtonTitle}
                              {type === 'phoneCallChannel' && phoneCallButtonTitle}
                           </Typography>
                        </div>
                     </div>
                  </>
               )}
            </div>
         </Box>
         <Typography className={classes.poweredByQpien}>
            {'Powered by'}
            <span className={classes.qpien}>{' Qpien'}</span>
         </Typography>
      </div>
   );
};

export default Intro;

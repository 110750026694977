import { gql } from '@apollo/client';

// subscriptions
export const CREATED_E_COMMERCE_PLATFORM_SUBSCRIPTION = gql`
   subscription createdShopifyPhoneSubscription($token: String!, $customer: ID!) {
      createdECommercePlatform(data: { token: $token, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;

export const UPDATED_E_COMMERCE_PLATFORM_SUBSCRIPTION = gql`
   subscription updatedECommercePlatform($token: String!, $customer: ID!) {
      updatedECommercePlatform(data: { token: $token, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;
export const DELETED_E_COMMERCE_PLATFORM_SUBSCRIPTION = gql`
   subscription deletedECommercePhoneSubscription($token: String!, $customer: ID!) {
      deletedECommercePlatform(data: { token: $token, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;

export const CREATED_E_COMMERCE_PRODUCTS_SUBSCRIPTION = gql`
   subscription createdECommerceProducts($type: String, $token: String, $_id: ID, $customer: ID) {
      createdECommerceProducts(data: { type: $type, token: $token, _id: $_id, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;

export const UPDATED_E_COMMERCE_PRODUCTS_SUBSCRIPTION = gql`
   subscription updatedECommerceProducts($type: String, $token: String, $_id: ID, $customer: ID) {
      updatedECommerceProducts(data: { type: $type, token: $token, _id: $_id, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;

export const DELETED_E_COMMERCE_PRODUCTS_SUBSCRIPTION = gql`
   subscription deletedECommercePlatform($type: String, $token: String, $_id: ID, $customer: ID) {
      deletedECommerceProducts(data: { type: $type, token: $token, _id: $_id, customer: $customer }) {
         data
         success
         code
         message
      }
   }
`;

const languageOptions = [
   { value: 'aa', englishName: 'Afar', dir: 1, nativeName: 'Afar' },
   { value: 'ab', englishName: 'Abkhazian', dir: 1, nativeName: 'Аҧсуа' },
   { value: 'af', englishName: 'Afrikaans', dir: 1, nativeName: 'Afrikaans' },
   { value: 'ak', englishName: 'Akan', dir: 1, nativeName: 'Akana' },
   { value: 'am', englishName: 'Amharic', dir: 1, nativeName: 'አማርኛ' },
   { value: 'an', englishName: 'Aragonese', dir: 1, nativeName: 'Aragonés' },
   { value: 'ar', englishName: 'Arabic', dir: 0, nativeName: 'العربية' },
   { value: 'as', englishName: 'Assamese', dir: 1, nativeName: 'অসমীয়া' },
   { value: 'av', englishName: 'Avar', dir: 1, nativeName: 'Авар' },
   { value: 'ay', englishName: 'Aymara', dir: 1, nativeName: 'Aymar' },
   { value: 'az', englishName: 'Azerbaijani', dir: 1, nativeName: 'Azərbaycanca / آذربايجان' },
   { value: 'ba', englishName: 'Bashkir', dir: 1, nativeName: 'Башҡорт' },
   { value: 'be', englishName: 'Belarusian', dir: 1, nativeName: 'Беларуская' },
   { value: 'bg', englishName: 'Bulgarian', dir: 1, nativeName: 'Български' },
   { value: 'bh', englishName: 'Bihari', dir: 1, nativeName: 'भोजपुरी' },
   { value: 'bi', englishName: 'Bislama', dir: 1, nativeName: 'Bislama' },
   { value: 'bm', englishName: 'Bambara', dir: 1, nativeName: 'Bamanankan' },
   { value: 'bn', englishName: 'Bengali', dir: 1, nativeName: 'বাংলা' },
   { value: 'bo', englishName: 'Tibetan', dir: 1, nativeName: 'བོད་ཡིག / Bod skad' },
   { value: 'br', englishName: 'Breton', dir: 1, nativeName: 'Brezhoneg' },
   { value: 'bs', englishName: 'Bosnian', dir: 1, nativeName: 'Bosanski' },
   { value: 'ca', englishName: 'Catalan', dir: 1, nativeName: 'Català' },
   { value: 'ce', englishName: 'Chechen', dir: 1, nativeName: 'Нохчийн' },
   { value: 'ch', englishName: 'Chamorro', dir: 1, nativeName: 'Chamoru' },
   { value: 'co', englishName: 'Corsican', dir: 1, nativeName: 'Corsu' },
   { value: 'cr', englishName: 'Cree', dir: 1, nativeName: 'Nehiyaw' },
   { value: 'cs', englishName: 'Czech', dir: 1, nativeName: 'Česky' },
   { value: 'cu', englishName: 'Old Church Slavonic / Old Bulgarian', dir: 1, nativeName: 'словѣньскъ / slověnĭskŭ' },
   { value: 'cv', englishName: 'Chuvash', dir: 1, nativeName: 'Чăваш' },
   { value: 'cy', englishName: 'Welsh', dir: 1, nativeName: 'Cymraeg' },
   { value: 'da', englishName: 'Danish', dir: 1, nativeName: 'Dansk' },
   { value: 'de', englishName: 'German', dir: 1, nativeName: 'Deutsch' },
   { value: 'dv', englishName: 'Divehi', dir: 0, nativeName: 'ދިވެހިބަސް' },
   { value: 'dz', englishName: 'Dzongkha', dir: 1, nativeName: 'ཇོང་ཁ' },
   { value: 'ee', englishName: 'Ewe', dir: 1, nativeName: 'Ɛʋɛ' },
   { value: 'el', englishName: 'Greek', dir: 1, nativeName: 'Ελληνικά' },
   { value: 'en', englishName: 'English', dir: 1, nativeName: 'English' },
   { value: 'eo', englishName: 'Esperanto', dir: 1, nativeName: 'Esperanto' },
   { value: 'es', englishName: 'Spanish', dir: 1, nativeName: 'Español' },
   { value: 'et', englishName: 'Estonian', dir: 1, nativeName: 'Eesti' },
   { value: 'eu', englishName: 'Basque', dir: 1, nativeName: 'Euskara' },
   { value: 'fa', englishName: 'Persian', dir: 0, nativeName: 'فارسی' },
   { value: 'ff', englishName: 'Peul', dir: 1, nativeName: 'Fulfulde' },
   { value: 'fi', englishName: 'Finnish', dir: 1, nativeName: 'Suomi' },
   { value: 'fj', englishName: 'Fijian', dir: 1, nativeName: 'Na Vosa Vakaviti' },
   { value: 'fo', englishName: 'Faroese', dir: 1, nativeName: 'Føroyskt' },
   { value: 'fr', englishName: 'French', dir: 1, nativeName: 'Français' },
   { value: 'fy', englishName: 'West Frisian', dir: 1, nativeName: 'Frysk' },
   { value: 'ga', englishName: 'Irish', dir: 1, nativeName: 'Gaeilge' },
   { value: 'gd', englishName: 'Scottish Gaelic', dir: 1, nativeName: 'Gàidhlig' },
   { value: 'gl', englishName: 'Galician', dir: 1, nativeName: 'Galego' },
   { value: 'gn', englishName: 'Guarani', dir: 1, nativeName: "Avañe'ẽ" },
   { value: 'gu', englishName: 'Gujarati', dir: 1, nativeName: 'ગુજરાતી' },
   { value: 'gv', englishName: 'Manx', dir: 1, nativeName: 'Gaelg' },
   { value: 'ha', englishName: 'Hausa', dir: 0, nativeName: 'هَوُسَ' },
   { value: 'he', englishName: 'Hebrew', dir: 0, nativeName: 'עברית' },
   { value: 'hi', englishName: 'Hindi', dir: 1, nativeName: 'हिन्दी' },
   { value: 'ho', englishName: 'Hiri Motu', dir: 1, nativeName: 'Hiri Motu' },
   { value: 'hr', englishName: 'Croatian', dir: 1, nativeName: 'Hrvatski' },
   { value: 'ht', englishName: 'Haitian', dir: 1, nativeName: 'Krèyol ayisyen' },
   { value: 'hu', englishName: 'Hungarian', dir: 1, nativeName: 'Magyar' },
   { value: 'hy', englishName: 'Armenian', dir: 1, nativeName: 'Հայերեն' },
   { value: 'hz', englishName: 'Herero', dir: 1, nativeName: 'Otsiherero' },
   { value: 'ia', englishName: 'Interlingua', dir: 1, nativeName: 'Interlingua' },
   { value: 'id', englishName: 'Indonesian', dir: 1, nativeName: 'Bahasa Indonesia' },
   { value: 'ie', englishName: 'Interlingue', dir: 1, nativeName: 'Interlingue' },
   { value: 'ig', englishName: 'Igbo', dir: 1, nativeName: 'Igbo' },
   { value: 'ii', englishName: 'Sichuan Yi', dir: 1, nativeName: 'ꆇꉙ / 四川彝语' },
   { value: 'ik', englishName: 'Inupiak', dir: 1, nativeName: 'Iñupiak' },
   { value: 'io', englishName: 'Ido', dir: 1, nativeName: 'Ido' },
   { value: 'is', englishName: 'Icelandic', dir: 1, nativeName: 'Íslenska' },
   { value: 'it', englishName: 'Italian', dir: 1, nativeName: 'Italiano' },
   { value: 'iu', englishName: 'Inuktitut', dir: 1, nativeName: 'ᐃᓄᒃᑎᑐᑦ' },
   { value: 'ja', englishName: 'Japanese', dir: 1, nativeName: '日本語' },
   { value: 'jv', englishName: 'Javanese', dir: 1, nativeName: 'Basa Jawa' },
   { value: 'ka', englishName: 'Georgian', dir: 1, nativeName: 'ქართული' },
   { value: 'kg', englishName: 'Kongo', dir: 1, nativeName: 'KiKongo' },
   { value: 'ki', englishName: 'Kikuyu', dir: 1, nativeName: 'Gĩkũyũ' },
   { value: 'kj', englishName: 'Kuanyama', dir: 1, nativeName: 'Kuanyama' },
   { value: 'kk', englishName: 'Kazakh', dir: 1, nativeName: 'Қазақша' },
   { value: 'kl', englishName: 'Greenlandic', dir: 1, nativeName: 'Kalaallisut' },
   { value: 'km', englishName: 'Cambodian', dir: 1, nativeName: 'ភាសាខ្មែរ' },
   { value: 'kn', englishName: 'Kannada', dir: 1, nativeName: 'ಕನ್ನಡ' },
   { value: 'ko', englishName: 'Korean', dir: 1, nativeName: '한국어' },
   { value: 'kr', englishName: 'Kanuri', dir: 1, nativeName: 'Kanuri' },
   { value: 'ks', englishName: 'Kashmiri', dir: 0, nativeName: 'कश्मीरी / كشميري' },
   { value: 'ku', englishName: 'Kurdish', dir: 0, nativeName: 'Kurdî / كوردی' },
   { value: 'kv', englishName: 'Komi', dir: 1, nativeName: 'Коми' },
   { value: 'kw', englishName: 'Cornish', dir: 1, nativeName: 'Kernewek' },
   { value: 'ky', englishName: 'Kirghiz', dir: 1, nativeName: 'Kırgızca / Кыргызча' },
   { value: 'la', englishName: 'Latin', dir: 1, nativeName: 'Latina' },
   { value: 'lb', englishName: 'Luxembourgish', dir: 1, nativeName: 'Lëtzebuergesch' },
   { value: 'lg', englishName: 'Ganda', dir: 1, nativeName: 'Luganda' },
   { value: 'li', englishName: 'Limburgian', dir: 1, nativeName: 'Limburgs' },
   { value: 'ln', englishName: 'Lingala', dir: 1, nativeName: 'Lingála' },
   { value: 'lo', englishName: 'Laotian', dir: 1, nativeName: 'ລາວ / Pha xa lao' },
   { value: 'lt', englishName: 'Lithuanian', dir: 1, nativeName: 'Lietuvių' },
   { value: 'lv', englishName: 'Latvian', dir: 1, nativeName: 'Latviešu' },
   { value: 'mg', englishName: 'Malagasy', dir: 1, nativeName: 'Malagasy' },
   { value: 'mh', englishName: 'Marshallese', dir: 1, nativeName: 'Kajin Majel / Ebon' },
   { value: 'mi', englishName: 'Maori', dir: 1, nativeName: 'Māori' },
   { value: 'mk', englishName: 'Macedonian', dir: 1, nativeName: 'Македонски' },
   { value: 'ml', englishName: 'Malayalam', dir: 1, nativeName: 'മലയാളം' },
   { value: 'mn', englishName: 'Mongolian', dir: 1, nativeName: 'Монгол' },
   { value: 'mo', englishName: 'Moldovan', dir: 1, nativeName: 'Moldovenească' },
   { value: 'mr', englishName: 'Marathi', dir: 1, nativeName: 'मराठी' },
   { value: 'ms', englishName: 'Malay', dir: 1, nativeName: 'Bahasa Melayu' },
   { value: 'mt', englishName: 'Maltese', dir: 1, nativeName: 'bil-Malti' },
   { value: 'my', englishName: 'Burmese', dir: 1, nativeName: 'Myanmasa' },
   { value: 'na', englishName: 'Nauruan', dir: 1, nativeName: 'Dorerin Naoero' },
   { value: 'nd', englishName: 'North Ndebele', dir: 1, nativeName: 'Sindebele' },
   { value: 'ne', englishName: 'Nepali', dir: 1, nativeName: 'नेपाली' },
   { value: 'ng', englishName: 'Ndonga', dir: 1, nativeName: 'Oshiwambo' },
   { value: 'nl', englishName: 'Dutch', dir: 1, nativeName: 'Nederlands' },
   { value: 'nn', englishName: 'Norwegian Nynorsk', dir: 1, nativeName: 'Norsk (nynorsk)' },
   { value: 'no', englishName: 'Norwegian', dir: 1, nativeName: 'Norsk (bokmål / riksmål)' },
   { value: 'nr', englishName: 'South Ndebele', dir: 1, nativeName: 'isiNdebele' },
   { value: 'nv', englishName: 'Navajo', dir: 1, nativeName: 'Diné bizaad' },
   { value: 'ny', englishName: 'Chichewa', dir: 1, nativeName: 'Chi-Chewa' },
   { value: 'oc', englishName: 'Occitan', dir: 1, nativeName: 'Occitan' },
   { value: 'oj', englishName: 'Ojibwa', dir: 1, nativeName: 'ᐊᓂᔑᓈᐯᒧᐎᓐ / Anishinaabemowin' },
   { value: 'om', englishName: 'Oromo', dir: 1, nativeName: 'Oromoo' },
   { value: 'or', englishName: 'Oriya', dir: 1, nativeName: 'ଓଡ଼ିଆ' },
   { value: 'os', englishName: 'Ossetian / Ossetic', dir: 1, nativeName: 'Иронау' },
   { value: 'pa', englishName: 'Panjabi / Punjabi', dir: 1, nativeName: 'ਪੰਜਾਬੀ / पंजाबी / پنجابي' },
   { value: 'pi', englishName: 'Pali', dir: 1, nativeName: 'Pāli / पाऴि' },
   { value: 'pl', englishName: 'Polish', dir: 1, nativeName: 'Polski' },
   { value: 'ps', englishName: 'Pashto', dir: 0, nativeName: 'پښتو' },
   { value: 'pt', englishName: 'Portuguese', dir: 1, nativeName: 'Português' },
   { value: 'qu', englishName: 'Quechua', dir: 1, nativeName: 'Runa Simi' },
   { value: 'rm', englishName: 'Raeto Romance', dir: 1, nativeName: 'Rumantsch' },
   { value: 'rn', englishName: 'Kirundi', dir: 1, nativeName: 'Kirundi' },
   { value: 'ro', englishName: 'Romanian', dir: 1, nativeName: 'Română' },
   { value: 'ru', englishName: 'Russian', dir: 1, nativeName: 'Русский' },
   { value: 'rw', englishName: 'Rwandi', dir: 1, nativeName: 'Kinyarwandi' },
   { value: 'sa', englishName: 'Sanskrit', dir: 1, nativeName: 'संस्कृतम्' },
   { value: 'sc', englishName: 'Sardinian', dir: 1, nativeName: 'Sardu' },
   { value: 'sd', englishName: 'Sindhi', dir: 1, nativeName: 'सिनधि' },
   { value: 'se', englishName: 'Northern Sami', dir: 1, nativeName: 'Davvisámegiella' },
   { value: 'sg', englishName: 'Sango', dir: 1, nativeName: 'Sängö' },
   { value: 'sh', englishName: 'Serbo-Croatian', dir: 1, nativeName: 'Srpskohrvatski / Српскохрватски' },
   { value: 'si', englishName: 'Sinhalese', dir: 1, nativeName: 'සිංහල' },
   { value: 'sk', englishName: 'Slovak', dir: 1, nativeName: 'Slovenčina' },
   { value: 'sl', englishName: 'Slovenian', dir: 1, nativeName: 'Slovenščina' },
   { value: 'sm', englishName: 'Samoan', dir: 1, nativeName: 'Gagana Samoa' },
   { value: 'sn', englishName: 'Shona', dir: 1, nativeName: 'chiShona' },
   { value: 'so', englishName: 'Somalia', dir: 1, nativeName: 'Soomaaliga' },
   { value: 'sq', englishName: 'Albanian', dir: 1, nativeName: 'Shqip' },
   { value: 'sr', englishName: 'Serbian', dir: 1, nativeName: 'Српски' },
   { value: 'ss', englishName: 'Swati', dir: 1, nativeName: 'SiSwati' },
   { value: 'st', englishName: 'Southern Sotho', dir: 1, nativeName: 'Sesotho' },
   { value: 'su', englishName: 'Sundanese', dir: 1, nativeName: 'Basa Sunda' },
   { value: 'sv', englishName: 'Swedish', dir: 1, nativeName: 'Svenska' },
   { value: 'sw', englishName: 'Swahili', dir: 1, nativeName: 'Kiswahili' },
   { value: 'ta', englishName: 'Tamil', dir: 1, nativeName: 'தமிழ்' },
   { value: 'te', englishName: 'Telugu', dir: 1, nativeName: 'తెలుగు' },
   { value: 'tg', englishName: 'Tajik', dir: 1, nativeName: 'Тоҷикӣ' },
   { value: 'th', englishName: 'Thai', dir: 1, nativeName: 'ไทย / Phasa Thai' },
   { value: 'ti', englishName: 'Tigrinya', dir: 1, nativeName: 'ትግርኛ' },
   { value: 'tk', englishName: 'Turkmen', dir: 1, nativeName: 'Туркмен / تركمن' },
   { value: 'tl', englishName: 'Tagalog', dir: 1, nativeName: 'Tagalog' },
   { value: 'tn', englishName: 'Tswana', dir: 1, nativeName: 'Setswana' },
   { value: 'to', englishName: 'Tonga', dir: 1, nativeName: 'Lea Faka-Tonga' },
   { value: 'tr', englishName: 'Turkish', dir: 1, nativeName: 'Türkçe' },
   { value: 'ts', englishName: 'Tsonga', dir: 1, nativeName: 'Xitsonga' },
   { value: 'tt', englishName: 'Tatar', dir: 1, nativeName: 'Tatarça' },
   { value: 'tw', englishName: 'Twi', dir: 1, nativeName: 'Twi' },
   { value: 'ty', englishName: 'Tahitian', dir: 1, nativeName: 'Reo Mā`ohi' },
   { value: 'ug', englishName: 'Uyghur', dir: 1, nativeName: 'Uyƣurqə / ئۇيغۇرچە' },
   { value: 'uk', englishName: 'Ukrainian', dir: 1, nativeName: 'Українська' },
   { value: 'ur', englishName: 'Urdu', dir: 0, nativeName: 'اردو' },
   { value: 'uz', englishName: 'Uzbek', dir: 1, nativeName: 'Ўзбек' },
   { value: 've', englishName: 'Venda', dir: 1, nativeName: 'Tshivenḓa' },
   { value: 'vi', englishName: 'VietenglishNamese', dir: 1, nativeName: 'Việtnam' },
   { value: 'vo', englishName: 'Volapük', dir: 1, nativeName: 'Volapük' },
   { value: 'wa', englishName: 'Walloon', dir: 1, nativeName: 'Walon' },
   { value: 'wo', englishName: 'Wolof', dir: 1, nativeName: 'Wollof' },
   { value: 'xh', englishName: 'Xhosa', dir: 1, nativeName: 'isiXhosa' },
   { value: 'yi', englishName: 'Yiddish', dir: 0, nativeName: 'ייִדיש' },
   { value: 'yo', englishName: 'Yoruba', dir: 1, nativeName: 'Yorùbá' },
   { value: 'za', englishName: 'Zhuang', dir: 1, nativeName: 'Cuengh / Tôô / 壮语' },
   { value: 'zh', englishName: 'Chinese', dir: 1, nativeName: '中文' },
   { value: 'zu', englishName: 'Zulu', dir: 1, nativeName: 'isiZulu' },
   { value: 'nb', englishName: 'Norwegian Bokmål', dir: 1, nativeName: 'Norsk (bokmål)' },
];

export default languageOptions;

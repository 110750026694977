import { gql } from '@apollo/client';

export const CREATED_CUSTOM_FIELD_MUTATION = gql`
   mutation createCustomField(
      $name: String!
      $type: String!
      $listValues: [String]
      $description: String!
      $customer: String!
   ) {
      createCustomField(
         input: { name: $name, type: $type, listValues: $listValues, description: $description, customer: $customer }
      ) {
         success
         message
         data {
            _id
            customer
            name
            type
            listValues
            label
            description
            createdAt
            updatedAt
         }
         code
      }
   }
`;

export const UPDATED_CUSTOM_FIELD_MUTATION = gql`
   mutation updateCustomField(
      $_id: String!
      $name: String!
      $type: String!
      $listValues: [String]
      $description: String
      $customer: String!
   ) {
      updateCustomField(
         input: {
            _id: $_id
            name: $name
            type: $type
            listValues: $listValues
            description: $description
            customer: $customer
         }
      ) {
         success
         message
         data {
            _id
            customer
            name
            type
            listValues
            label
            description
            createdAt
            updatedAt
         }
         code
      }
   }
`;

export const DELETED_CUSTOM_FIELD_MUTATION = gql`
   mutation deleteCustomField($_id: String!, $customer: String!) {
      deleteCustomField(input: { _id: $_id, customer: $customer }) {
         success
         message
         data {
            _id
            customer
            name
            type
            listValues
            label
            description
            createdAt
            updatedAt
         }
         code
      }
   }
`;

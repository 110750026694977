import { trimLongText } from '@helper/textUtils';
import { Avatar, ButtonBase, Grid, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import { MyTheme } from '@styles/config';
import React, { Fragment } from 'react';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { PLATFORM_TYPE } from '../../modules/Chat/types';
import { QnAPlatformType } from '../../modules/Questions/util/types';
import SocialMediaIcon from '../SocialMediaIcon';
import Tag from '../Tag';
import OnlineCircle from './OnlineCircle';

//             CHAT STATUS
//             0 => aktif (active)
//             1 => kapanan (24 saat sonra) after 24 hours (timeout)
//             2 => kapandı (arşivlenen) (closed)
//             3 => spam
//             4 => engellenen (blocked)

interface FullAvatarProps {
   name: string;
   surname?: string;
   name1?: string;
   surname1?: string;
   person?: any;
   agentStatus?: number;
   isBold: boolean;
   lastSeen: string;
   socialMedia: PLATFORM_TYPE | QnAPlatformType | string;
   socialMediaIconColor?: string | null | undefined;
   avatarSize?: string | number;
   isSelectedItem: boolean;
   chatId?: string;
   unreadMessagesCount?: number;
   onClick?: () => void;
   lastMessage?: string;
   tag?: any;
   isMedia?: boolean;
   joinStatus: boolean;
   image?: string;
   messageSeenStatus?: any;
   chatColor?: string;
   agentPhoto?: string;
   renderAgentPhoto?: boolean;
   textStatus: string;
   textStatusColor?: string;
   online?: boolean;
   countryFlag?: string | undefined;
   subject?: string;
   isVisibleIconTooltip: boolean;
   setIsVisibleIconTooltip: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles<MyTheme, FullAvatarProps>((theme) => ({
   small: {
      width: (props) => props.avatarSize,
      height: (props) => props.avatarSize,
      marginRight: 10,
   },
   avatarContainer: {
      width: '100%',
      backgroundColor: (props) => (props.isSelectedItem ? '#fff' : '#f4f7fb'),
      padding: 10,
      borderRadius: 10,
      transition: 'all .2s',
      '&:hover': {
         backgroundColor: ({ isSelectedItem }) => {
            return !isSelectedItem ? '#fff' : undefined;
         },
      },
   },
   textColor: {
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },

   divContainer: {
      width: 45,
      height: 45,
      borderRadius: 22,
      marginRight: 10,
   },
   SocialMediaIconContainer: {
      position: 'absolute',
      top: -15,
      left: 2,
      backgroundColor: '#fff',
      borderRadius: 10,
   },
   socialMediaBox: {
      position: 'relative',
      display: 'inline',
   },
   avatarText: {
      textAlign: 'center',
      fontSize: '1.2rem',
      color: 'white',
      paddingTop: 8,
   },
   statusText: {
      borderRadius: 5,
      padding: 5,
      paddingTop: 2,
      paddingBottom: 2,
      border: '1px solid #eee',
      color: '#1ac31a',
      fontSize: '0.8rem',
   },
   mediaIcon: {
      width: 20,
      height: 20,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginRight: theme.spacing(1),
   },
   mediaIconContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   senderAvatar: {
      width: 15,
      height: 15,
   },
   profileTooltipButton: {
      height: 44,
      width: 44,
      backgroundColor: theme.chatApp.general.pallet.passiveStructureAzure,
      borderRadius: 6,
      color: '#fff',
      border: '1px solid #e8e9ed',
      '&:hover': {
         backgroundColor: '#116ada',
      },
      '&:disabled': {
         backgroundColor: 'rgba(21, 124, 252, 0.7)',
         color: '#fff',
      },
   },
   iconButtonContainer2: {
      flexShrink: 0,
      '&..MuiPopover-paper': {
         backgroundColor: 'red',
      },
   },
   statusAvatar: {
      width: 12,
      height: 12,
      borderRadius: '50%',
   },
}));

const FullAvatar: React.FC<FullAvatarProps> = (props) => {
   const {
      name,
      surname,
      name1,
      surname1,
      person,
      agentStatus,
      // filter,
      lastSeen,
      socialMedia,
      socialMediaIconColor,
      unreadMessagesCount,
      tag,
      lastMessage,
      subject,
      onClick,
      isBold,
      // chatId,
      isMedia,
      chatColor,
      agentPhoto,
      renderAgentPhoto,
      textStatus,
      isSelectedItem,
      textStatusColor,
      countryFlag,
      online = false,
      isVisibleIconTooltip,
      setIsVisibleIconTooltip,
   } = props;
   const classes = useStyles({ ...props, isSelectedItem });
   const title = (name || '') + ' ' + (surname || '');
   let tooltipTimeout: string | number | NodeJS.Timeout;

   return (
      <ButtonBase
         id='textTransform-none-capitalize'
         className={classes.avatarContainer}
         disabled={isSelectedItem}
         onClick={onClick}
         disableRipple
      >
         <Grid container direction='row' className={classes.textColor} wrap='nowrap' alignItems='flex-start'>
            <Grid>
               <div
                  className={classes.divContainer}
                  style={{
                     backgroundColor: chatColor ? chatColor : 'red',
                  }}
               >
                  <Typography className={classes.avatarText}>
                     {name || surname ? <Fragment>{name ? name[0] : '' + (surname ? surname[0] : '')}</Fragment> : null}
                     {!name && !surname ? (
                        <Fragment>{(title ? title[0] : '') + (title ? title[1] : '')}</Fragment>
                     ) : null}{' '}
                  </Typography>
               </div>
               {socialMedia && (
                  <div className={classes.socialMediaBox}>
                     <div
                        className={classes.SocialMediaIconContainer}
                        style={socialMedia == 'N11' ? { backgroundColor: 'transparent' } : {}}
                     >
                        <SocialMediaIcon
                           socialMediaCode={socialMedia}
                           color={socialMediaIconColor}
                           selected
                           width={15}
                           containerStyle={{
                              padding: 0,
                           }}
                           height={15}
                        />
                     </div>
                  </div>
               )}
            </Grid>

            <Grid container style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
               <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
                  <Grid item>
                     {title && (
                        <Typography variant='subtitle1' className={classes.textColor}>
                           {countryFlag && (
                              <span>
                                 <img
                                    style={{ height: 13, marginRight: 3 }}
                                    src={`https://www.coolgenerator.com/Pic/flag/${countryFlag.toLowerCase()}.png`}
                                    onError={(e) => {
                                       e.currentTarget.src =
                                          'https://upload.wikimedia.org/wikipedia/commons/thumb/e/ef/International_Flag_of_Planet_Earth.svg/1280px-International_Flag_of_Planet_Earth.svg.png';
                                    }}
                                    alt='country_flag'
                                 />
                              </span>
                           )}
                           <div style={{ display: 'flex', flexDirection: 'row', gap: '0.3rem' }}>
                              <div
                                 style={{
                                    border: `1px solid ${textStatusColor ? textStatusColor : 'initial'}`,
                                    backgroundColor: textStatusColor ? textStatusColor : 'initial',
                                    borderRadius: '5px',
                                    width: '4px',
                                    height: '15px',
                                    marginTop: '0.4rem',
                                 }}
                              ></div>
                              <div>{trimLongText(title, 24)}</div>
                              <div>
                                 {socialMedia && socialMedia === PLATFORM_TYPE.LIVE_CHAT && (
                                    <OnlineCircle size={10} style={{ marginLeft: 5 }} online={online} />
                                 )}
                              </div>
                           </div>
                        </Typography>
                     )}
                  </Grid>
                  <Grid item>
                     <Typography variant='caption'>{lastSeen}</Typography>
                  </Grid>
               </Grid>
               <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
                  <Grid item>
                     <Grid
                        container
                        alignItems='center'
                        style={{ margin: 0, padding: 0, height: 18 }}
                     >
                        {socialMedia && socialMedia === PLATFORM_TYPE.EMAIL ? (
                           <div
                              style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 margin: 0,
                                 padding: 0,
                              }}
                           >
                              <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                 {trimLongText(subject, 30)}
                              </div>
                           </div>
                        ) : (
                           <div className={classes.mediaIconContainer}>
                              {isMedia && <PermMediaIcon className={classes.mediaIcon} />}
                              <div
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    margin: 0,
                                    padding: 0,
                                    fontWeight: isBold ? 'bold' : 'normal',
                                 }}
                              >
                                 <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                    {trimLongText(lastMessage, 30)}
                                 </div>
                              </div>
                           </div>
                        )}
                     </Grid>
                  </Grid>
                  <Grid item>
                     {renderAgentPhoto && (
                        <Tooltip
                           placement='right'
                           open={isVisibleIconTooltip}
                           title={
                              <div style={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                 <Brightness1Icon
                                    style={{
                                       fontSize: '10px',
                                       color:
                                          agentStatus === 2 || agentStatus === 1 || agentStatus === 0
                                             ? agentStatus === 2
                                                ? '#49be25'
                                                : '#ababab'
                                             : person?.status === 2
                                             ? '#49be25'
                                             : '#ababab',
                                       marginBottom: 1,
                                    }}
                                 />
                                 <span>{`${name1 || ''} ${surname1 || ''}`}</span>
                              </div>
                           }
                        >
                           <div
                              onMouseOver={() => {
                                 clearTimeout(tooltipTimeout);
                                 tooltipTimeout = setTimeout(() => {
                                    setIsVisibleIconTooltip(true);
                                 }, 1500);
                              }}
                              onMouseLeave={() => {
                                 setIsVisibleIconTooltip(false);
                                 clearTimeout(tooltipTimeout);
                              }}
                              style={{ width: '100%', height: '100%' }}
                           >
                              <Avatar src={agentPhoto} className={classes.senderAvatar} />
                           </div>
                        </Tooltip>
                     )}
                  </Grid>
               </Grid>

               <Grid container direction='row' justifyContent='space-between' alignItems='stretch'>
                  <Grid item>
                     {socialMedia && socialMedia === PLATFORM_TYPE.EMAIL && (
                        <div style={{ color: 'gray', fontWeight: 200 }}>
                           <div
                              style={{ height: 16, overflow: 'hidden' }}
                              dangerouslySetInnerHTML={{
                                 __html: trimLongText(lastMessage?.replace(/<[^>]+>/g, ' '), 44),
                              }}
                           />
                        </div>
                     )}
                  </Grid>
               </Grid>
               {tag && (
                  <Grid style={{ display: 'flex', marginLeft: 'auto', marginTop: '4px' }} item>
                     <Typography>
                        <Tag name={tag?.name} color={tag?.color} small />
                     </Typography>
                  </Grid>
               )}
            </Grid>
         </Grid>
      </ButtonBase>
   );
};

FullAvatar.defaultProps = {
   avatarSize: 64,
};

export default FullAvatar;

import { useMemo } from 'react';
import { useQuery, useSubscription } from '@apollo/client';
import {
   JOIN_CHAT_SUBSCRIPTION,
   LEAVE_CHAT_SUBSCRIPTION,
   UPDATED_CHAT_STATUS_SUBSCRIPTION,
   TRANSFER_CHAT_SUBSCRIPTION,
   CHANGED_CHAT_TAG_SUBSCRIPTION,
   ARCHIVE_CHAT_SUBSCRIPTION,
} from '@queries/Chat/Service/GraphQL/Chat/subscription';
import { MESSAGE_TO_ME_SUBSCRIPTION } from '@queries/Chat/Service/GraphQL/Message/subscription';
import {
   joinChatSubscription,
   joinChatSubscriptionVariables,
} from '@queries/Chat/Service/GraphQL/Chat/__generated__/joinChatSubscription';
import {
   leaveChatSubscription,
   leaveChatSubscriptionVariables,
} from '@queries/Chat/Service/GraphQL/Chat/__generated__/leaveChatSubscription';
import {
   updatedChatStatusSubscription,
   updatedChatStatusSubscriptionVariables,
} from '@queries/Chat/Service/GraphQL/Chat/__generated__/updatedChatStatusSubscription';
import {
   changedChatTagSubscription,
   changedChatTagSubscriptionVariables,
} from '@queries/Chat/Service/GraphQL/Chat/__generated__/changedChatTagSubscription';
import {
   transferChatSubscription,
   transferChatSubscriptionVariables,
} from '@queries/Chat/Service/GraphQL/Chat/__generated__/transferChatSubscription';
import {
   messageToMeSubscription,
   messageToMeSubscriptionVariables,
} from '@queries/Chat/Service/GraphQL/Message/__generated__/messageToMeSubscription';
import {
   archiveChatSubscription,
   archiveChatSubscriptionVariables,
} from '@queries/Chat/Service/GraphQL/Chat/__generated__/archiveChatSubscription';
import { useAppSelector } from '@store/store';
import { useDispatch, useSelector } from 'react-redux';
import {
   ARCHIVE_CHAT,
   CHANGE_CHAT_TAG,
   JOIN_CHAT,
   LEAVE_CHAT,
   MESSAGED_TO_ME,
   TRANSFER_CHAT,
   UPDATED_CHAT_STATUS,
} from '@store/actions/chat';
import { useRef, useState } from 'react';
import useLocalStorage from '@hooks/useLocalStorage';
import useSound from 'use-sound';
import { getSoundByName } from '@assets/beeps';
import { getTeams, getTeamsVariables } from '@queries/Teams/__generated__/getTeams';
import { GET_TEAMS_QUERY } from '@queries/Teams/query';
import { getChatFilters } from '@store/reducers/chat';

const useChatSubscriptions = () => {
   const { token, person } = useAppSelector((state) => state.personReducer);
   const dispatch = useDispatch();
   const chatFilters = useSelector(getChatFilters);
   const variables = { token, customer: person?.customer?._id, me: person?._id };

   const [poolTimerActivated, setPoolTimerActivated] = useState(false);
   const messagesPool = useRef<any>([]);
   const [newMessageSystem] = useLocalStorage('newMessageSystem', {
      active: true,
      sound: 'sound4.mp3',
   });
   const [playNewMessage] = useSound(getSoundByName(newMessageSystem.sound));

   const { data: teamsData } = useQuery<getTeams, getTeamsVariables>(GET_TEAMS_QUERY, {
      variables: { customer: person.customer._id, me: person?._id, page: 1, pageSize: 100 },
      skip: !person.customer._id,
      fetchPolicy: 'network-only',
   });

   const myTeamsIds = useMemo(() => {
      return teamsData?.getTeamList?.data?.docs?.map((team: any) => team._id);
   }, teamsData?.getTeamList?.data.docs);

   const handleMessagePool = (message: any) => {
      return (dispatch: any) => {
         messagesPool.current.push(message);
         if (!poolTimerActivated) {
            setTimeout(() => {
               dispatch({
                  type: MESSAGED_TO_ME,
                  payload: {
                     messagesPool: messagesPool.current,
                     me: person,
                     myTeamsIds,
                  },
               });
               setPoolTimerActivated(false);
               messagesPool.current.length = 0;
            }, 500);
            setPoolTimerActivated(true);
         }
      };
   };

   useSubscription<messageToMeSubscription, messageToMeSubscriptionVariables>(MESSAGE_TO_ME_SUBSCRIPTION, {
      variables,
      onSubscriptionData: ({ subscriptionData }) => {
         if (subscriptionData.data?.messageToMe?.data) {
            const messageToMe = subscriptionData.data.messageToMe.data;
            dispatch(handleMessagePool(messageToMe));
            let myIndex = messageToMe.chat?.participants?.findIndex(
               (participant) => participant?._id?.toString() === person._id.toString(),
            );
            // If chat has a activeFlowInstance we dont want a notification
            let isFlowBotMessage = messageToMe.chat?.status === 6;
            // play sound
            if (
               newMessageSystem.active &&
               messageToMe?.chat?.status !== 3 &&
               !messageToMe?.autoCreate &&
               !isFlowBotMessage &&
               ((myIndex !== undefined &&
                  myIndex > -1 &&
                  messageToMe.sender?._id?.toString() !== person._id.toString() &&
                  messageToMe.type === 0) ||
                  (myIndex !== undefined && myIndex < 0 && !messageToMe.chat?.joinStatus && messageToMe.type === 0)) &&
               (!myTeamsIds ||
                  (messageToMe.chat?.teams &&
                     Array.isArray(messageToMe.chat?.teams) &&
                     messageToMe.chat?.teams?.length > 0 &&
                     ((chatFilters.teams?.value === 'ALL' &&
                        myTeamsIds &&
                        Array.isArray(myTeamsIds) &&
                        myTeamsIds.length > 0 &&
                        myTeamsIds.find((myTeam) => messageToMe.chat?.teams?.find((team) => team?._id === myTeam)) !==
                           undefined) ||
                        !!messageToMe.chat?.teams?.find((team) => team?._id === chatFilters.teams?.value))))
            ) {
               playNewMessage();
            }
         }
      },
   });

   useSubscription<joinChatSubscription, joinChatSubscriptionVariables>(JOIN_CHAT_SUBSCRIPTION, {
      variables,
      onSubscriptionData: ({ subscriptionData }) => {
         if (subscriptionData.data?.joinCustomerChat?.data) {
            dispatch({
               type: JOIN_CHAT,
               payload: { chat: subscriptionData.data.joinCustomerChat.data },
            });
         }
      },
   });

   useSubscription<leaveChatSubscription, leaveChatSubscriptionVariables>(LEAVE_CHAT_SUBSCRIPTION, {
      variables,
      onSubscriptionData: ({ subscriptionData }) => {
         if (subscriptionData.data?.leaveCustomerChat?.data) {
            dispatch({
               type: LEAVE_CHAT,
               payload: { chat: subscriptionData.data.leaveCustomerChat.data, myTeamsIds },
            });
         }
      },
   });

   useSubscription<archiveChatSubscription, archiveChatSubscriptionVariables>(ARCHIVE_CHAT_SUBSCRIPTION, {
      variables,
      onSubscriptionData: ({ subscriptionData }) => {
         if (subscriptionData.data?.archiveCustomerChat?.data) {
            dispatch({
               type: ARCHIVE_CHAT,
               payload: { chat: subscriptionData.data.archiveCustomerChat.data, myTeamsIds },
            });
         }
      },
   });

   useSubscription<updatedChatStatusSubscription, updatedChatStatusSubscriptionVariables>(
      UPDATED_CHAT_STATUS_SUBSCRIPTION,
      {
         variables,
         onSubscriptionData: ({ subscriptionData }) => {
            if (subscriptionData.data?.updatedChatStatus?.data) {
               dispatch({
                  type: UPDATED_CHAT_STATUS,
                  payload: { chat: subscriptionData.data.updatedChatStatus.data, myTeamsIds },
               });
            }
         },
      },
   );

   useSubscription<changedChatTagSubscription, changedChatTagSubscriptionVariables>(CHANGED_CHAT_TAG_SUBSCRIPTION, {
      variables,
      onSubscriptionData: ({ subscriptionData }) => {
         if (subscriptionData.data?.changedChatTag?.data) {
            dispatch({
               type: CHANGE_CHAT_TAG,
               payload: { chat: subscriptionData.data.changedChatTag.data, myTeamsIds },
            });
         }
      },
   });

   useSubscription<transferChatSubscription, transferChatSubscriptionVariables>(TRANSFER_CHAT_SUBSCRIPTION, {
      variables,
      onSubscriptionData: ({ subscriptionData }) => {
         if (subscriptionData.data?.transferChat?.data) {
            dispatch({
               type: TRANSFER_CHAT,
               payload: { chat: subscriptionData.data.transferChat.data },
            });
         }
      },
   });
};

export default useChatSubscriptions;

import React from 'react';
import PageLayout from '../SharedComponents/PageLayout';
import Nav from '../SharedComponents/Nav';
import Form from './Form';
import NoPreview from '../SharedComponents/NoPreview';

const Installation = ({ url }) => (
   <PageLayout nav={<Nav active='installation' url={url} />} form={<Form />} previews={<NoPreview />} />
);

export default Installation;

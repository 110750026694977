import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Route, Switch, Redirect, useLocation, useHistory } from 'react-router-dom';
import { PROJECT_VERSION, responsiveMeasure } from '@helper/config';
import Preload from './modules/Person/Preload';
import Dashboard from './modules/Dashboard/index';
import Chat from './modules/Chat/index';
import Settings from './modules/Settings/index';
import Automation from './modules/Automation/FlowBots/index';
import FacebookCreateAccount from './modules/Settings/SettingSpace/FacebookMessenger/CreateFacebookAccount';
import ShopifyCreateAccount from './modules/Settings/SettingSpace/Shopify/CreateShopifyAccount';
import IdeasoftCreateAccount from './modules/Settings/SettingSpace/Ideasoft/CreateIdeasoftAccount';
import FapricaCreateAccount from './modules/Settings/SettingSpace/Faprika/CreateFaprikaAccount';
import TsoftCreateAccount from './modules/Settings/SettingSpace/Tsoft/CreateTsoftAccount';
import WooCommerceCreateAccount from './modules/Settings/SettingSpace/WooCommerce/CreateWooCommerceAccount';
import InstagramCreateAccount from './modules/Settings/SettingSpace/InstagramDM/CreateInstagramAccount';
import Confirmation from './modules/Person/Confirmation';
import Verify from './modules/Person/Verify';
import LoginByAdmin from './modules/Person/LoginByAdmin/index.tsx';
import './styles/loader.css';
import './styles/index.css';
import 'react-phone-input-2/lib/style.css';
import { useDispatch, useSelector } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
import theme from './styles/config';
import Sidebar from './modules/SideBar';
import Contacts from './modules/Contacts';
import PersonActivity from './modules/PersonActivity';
import NavBar from './modules/NavBar';
import Account from './modules/Account';
import Statistics from './modules/Statistics';
import './assets/fonts/fonts.css';
import Modal from './components/Modal/modal';
import Marketing from './modules/Marketing/index';
import { LanguageContext, getDefaultLang, getAllLanguages } from '@helper/locale/langContext';
import { UI_WIDTH } from '@store/actions/ui';
import { Fragment } from 'react';
import useWindowResize from './hooks/useWindowResize';
import Welcome from './modules/Welcome';
import AutoLogout from './modules/AutoLogout';
import { SIGN_OUT, UPDATE_ME } from '@store/actions/person';
import { useApolloClient, useSubscription } from '@apollo/client';
import { UPDATED_CUSTOMER_SUBSCRIPTION } from '@queries/Customer/Service/GraphQL/Customer/subscription';
import { ThemeProvider } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import { UPDATED_CUSTOMER_PRODUCT_SUBSCRIPTION } from '@queries/Customer/Service/GraphQL/CustomerProduct/subscription';
import { TOGGLE_PROFILE } from '@store/actions/ui';
import io from 'socket.io-client';
// milad test
import { RESET_CHAT_FILTERS, SET_CHAT_FILTERS } from '@store/actions/chat';
import Questions from './modules/Questions';

import Campaigns from './modules/Campaigns/index';
import CreateNewCampaign from './modules/Campaigns/createNew';

import BulkMessaging from './modules/BulkMessaging/index';
import BulkMessagingSend from './modules/BulkMessaging/sendMessage';

import OffLineItem from './components/OfflineItem';
import { API_URL } from '@helper/config';
import { GET_PERSON_ACTIVITY } from '@queries/PersonActivity/query';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment';
import { RESET_QNA_CHAT_FILTERS, SET_QNA_CHAT_FILTERS } from '@store/actions/questions';
import { AddOnName } from '@types/enums';

const App = () => {
   const [marketingModalConfig, setMarketingModalConfig] = useState({
      show: false,
      marketingContent: {},
   });
   let location = useLocation();
   const history = useHistory();
   const { enqueueSnackbar } = useSnackbar();
   const [showSideBar, setShowSideBar] = useState(true);
   const [smallSize, setSmallSize] = useState(false);
   const [navBar, setNavBar] = useState(true);
   const [path, setPath] = useState(null);
   // const [toggleProfile, setToggleProfile] = useState(location.pathname.includes('/questions') || false);
   const profileStatusTemp = useSelector((state) => state?.uiReducer?.isOpenChatProfileMenu);
   const [toggleProfile, setToggleProfile] = useState(profileStatusTemp ? profileStatusTemp : false);
   const [sideBarWidth, setSideBarWidth] = useState(0);
   const projectVersion = useSelector((rootReducer) => rootReducer.personReducer.projectVersion);
   const token = useSelector((rootReducer) => rootReducer.personReducer.token);
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const chatFilters = useSelector((rootReducer) => rootReducer.chatReducer.chatFilters);
   const qnaChatFilters = useSelector((rootReducer) => rootReducer.questionsState.qnaChatFilters);
   const [language, setLanguage] = useState(getDefaultLang());
   const dispatch = useDispatch();
   const client = useApolloClient();
   const [socket, setSocket] = useState(null);

   const [getClients, { loading: loadingClientList, data: clientsData }] = useLazyQuery(GET_PERSON_ACTIVITY, {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
         dispatch({
            type: 'SET_PERSON_ACTIVITY',
            payload: data.personActivities.data.filter((e) => e.status == 'online'),
         });
      },
   });
   const queryParams = {
      page: 0,
      limit: 20,
      customer: person?.customer?._id,
   };

   useEffect(() => {
      if (token) {
         getClients({ variables: queryParams });
      }
   }, [token]);

   // useEffect(() => {
   //    if (token && socket == null && person?.customer?._id) {
   //       let query = {
   //          query: `path=customer&token=${token}&customer=${person?.customer?._id}`,
   //       };
   //       const newSocket = io(API_URL, query);
   //       setSocket(newSocket);
   //       newSocket.on('updatePerson', (personActivity) => {
   //          console.log('update person');
   //          dispatch({ type: 'UPDATE_PERSON_ACTIVITY', payload: personActivity });
   //       });
   //       newSocket.on('chatStartedUser', (personActivity) => {
   //          console.log('update person');
   //          dispatch({ type: 'CHAT_STARTED', payload: personActivity });
   //       });
   //       newSocket.on('createPerson', (personActivity) => {
   //          console.log('Yeni Person');
   //          dispatch({ type: 'NEW_PERSON_ACTIVITY', payload: personActivity });
   //       });
   //    }

   //    return () => {
   //       if (socket) {
   //          socket.off('connect');
   //          socket.off('disconnect');
   //          socket.off('updatePerson');
   //          socket.off('chatStartedUser');
   //          socket.off('createPerson');
   //       }
   //    };
   // }, [token]);

   useEffect(() => {
      if (person?.customer?.accountStatus === 'Inactive') {
         enqueueSnackbar('Your account has been inactiveted. Please contact your admin.', {
            variant: 'warning',
            autoHideDuration: 3000,
         });
         const timeOutId = setTimeout(async () => {
            dispatch({ type: SIGN_OUT, payload: {} });
            localStorage.clear();
            await client.resetStore();
         }, 3000);
         return () => clearTimeout(timeOutId);
      }
   }, [person, dispatch, enqueueSnackbar, client]);

   useEffect(() => {
      dispatch({
         type: TOGGLE_PROFILE,
         payload: toggleProfile,
      });
   }, [dispatch, toggleProfile]);

   useEffect(() => {
      if (!chatFilters) {
         dispatch({
            type: RESET_CHAT_FILTERS,
         });
      }
   }, [dispatch]);

   useEffect(() => {
      if (!qnaChatFilters) {
         dispatch({
            type: RESET_QNA_CHAT_FILTERS,
         });
      }
   }, [dispatch]);

   useEffect(() => {
      if (person?._id && !chatFilters?.me) {
         dispatch({
            type: SET_CHAT_FILTERS,
            payload: { me: person?._id },
         });
      }
   }, [dispatch, person?._id, chatFilters?.me]);

   useEffect(() => {
      if (person?._id && !qnaChatFilters?.me) {
         dispatch({
            type: SET_QNA_CHAT_FILTERS,
            payload: { me: person?._id },
         });
      }
   }, [dispatch, person?._id, qnaChatFilters?.me]);

   useEffect(() => {
      if (chatFilters?.updatedAt && !moment(chatFilters?.updatedAt).isSame(moment().valueOf(), 'days')) {
         dispatch({
            type: RESET_CHAT_FILTERS,
         });
      }
   }, [dispatch]);

   useEffect(() => {
      if (qnaChatFilters?.updatedAt && !moment(qnaChatFilters?.updatedAt).isSame(moment().valueOf(), 'days')) {
         dispatch({
            type: RESET_CHAT_FILTERS,
         });
      }
   }, [dispatch]);

   const { width, height } = useWindowResize();

   const resize = useCallback(() => {
      let chatDetailorQuestionDetail =
         location.pathname.startsWith('/chat/') || location.pathname.startsWith('/questions/');

      if (width < responsiveMeasure.mobile.width) {
         // small sidebar
         if (chatDetailorQuestionDetail) {
            setShowSideBar(false);
            setSmallSize(true);
            // dispatch({ type: UI_WIDTH, payload: { width: window.innerWidth } });
            setNavBar(chatDetailorQuestionDetail);
            setSideBarWidth(0);
         } else {
            setShowSideBar(true);
            setSmallSize(true);
            // dispatch({ type: UI_WIDTH, payload: { width: window.innerWidth } });
            setNavBar(location.pathname !== '/chat' && location.pathname !== '/questions');
            setSideBarWidth(0);
         }
      } else if (width >= responsiveMeasure.mobile.width && width < responsiveMeasure.tablet.width) {
         // navbar chat button collapse
         const isInChatOrQuestions =
            location.pathname.startsWith('/chat') || location.pathname.startsWith('/questions');
         setShowSideBar(true);
         setSmallSize(false);
         dispatch({ type: UI_WIDTH, payload: { width: window.innerWidth } });
         setNavBar(true);
         setSideBarWidth(isInChatOrQuestions ? 63 + 403 : 63);
      } else {
         const isInChatOrQuestions =
            location.pathname.startsWith('/chat') || location.pathname.startsWith('/questions');
         setShowSideBar(true);
         setSmallSize(false);
         dispatch({ type: UI_WIDTH, payload: { width: window.innerWidth } });
         setNavBar(true);
         setSideBarWidth(isInChatOrQuestions ? 63 + 403 : 63);
      }
   }, [dispatch, width, location.pathname]);
   useEffect(() => {
      resize();
      if (path !== location.pathname) {
         setPath(location.pathname);
         resize();
      }
   }, [path, location, width, height, resize]);

   useEffect(() => {
      if (location.pathname == '/authentication/shopify') {
         const queryString = location.search;
         const urlParams = new URLSearchParams(queryString);
         const session = urlParams.get('session');
         if (session) {
            localStorage.setItem('shopifySession', session);
         }
      }

      if (location.pathname == '/authentication/ideasoft') {
         const queryString = location.search;
         const urlParams = new URLSearchParams(queryString);
         const state = urlParams.get('state');
         const code = urlParams.get('code');
         const domain = urlParams.get('domain');
         if (state) {
            localStorage.setItem('ideasoftState', state);
            localStorage.setItem('ideasoftCode', code);
            localStorage.setItem('ideasoftDomain', domain);
         }
      }
   }, []);

   const handleChangeToggleProfile = useCallback(
      (status = null) => {
         if (status !== 'close') {
            setToggleProfile(!toggleProfile);
         } else {
            setToggleProfile(false);
         }
      },
      [toggleProfile],
   );

   const handleCloseNotification = () => {
      setMarketingModalConfig((prevState) => ({ ...prevState, show: false }));
   };

   const handleShowNotificationLater = () => {
      setMarketingModalConfig((prevState) => ({ ...prevState, show: false }));
   };

   const memoProps = useMemo(() => {
      return { toggleProfile, sideBarWidth, smallSize };
   }, [toggleProfile, sideBarWidth, smallSize]);

   const memoToggleProfileFunc = useCallback(
      (params) => handleChangeToggleProfile(params),
      [handleChangeToggleProfile],
   );

   const showNavs = location.pathname.includes('/welcome');
   const showNavBar = location.pathname.includes('/automation');

   const showWelcome =
      person &&
      Number(person.type) === 2 &&
      (person.customer.isANewCustomer ||
         (!!person?.customer?.package?.paymentFromOtherChannel?.confirmationUrl &&
            Object.keys(person?.customer?.package?.paymentFromOtherChannel)?.length === 1));

   const isDisabledAccount = person?.customer?.appStatus === 'Disabled';

   useEffect(() => {
      if (showWelcome && !showNavs) {
         return history.push('/welcome');
      }
   }, [showWelcome, showNavs, history]);

   useSubscription(UPDATED_CUSTOMER_SUBSCRIPTION, {
      variables: { token, _id: person ? person?.customer?._id : '' },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               updatedPersonCustomer: { data: updatedPersonCustomer },
            },
         },
      }) => {
         if (!showWelcome) {
            dispatch({
               type: UPDATE_ME,
               payload: {
                  person: {
                     ...person,
                     customer: {
                        ...person.customer,
                        ...updatedPersonCustomer,
                     },
                  },
               },
            });
         }
      },
   });

   useSubscription(UPDATED_CUSTOMER_PRODUCT_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedPersonCustomerProduct: { data: updatedPersonCustomerProduct },
            },
         },
      }) => {
         dispatch({
            type: UPDATE_ME,
            payload: {
               person: {
                  ...person,
                  customer: {
                     ...person.customer,
                     package: {
                        ...person.customer.package,
                        ...updatedPersonCustomerProduct,
                     },
                  },
               },
            },
         });
      },
   });

   useEffect(() => {
      if (!person?.customer) {
         return;
      }

      if (person?.customer?.package?.clientCount - person?.customer?.usageClientCount === 10) {
         if (person?.customer?.package?.addOns.find((item) => item.name === AddOnName.ACTIVE_CUSTOMER)?.quantity <= 0) {
            enqueueSnackbar('Hakkınızın bitmesine 10 kişi kaldı', {
               variant: 'error',
               autoHideDuration: 4000,
            });
         }
      }
      if (person?.customer?.package?.clientCount - person?.customer?.usageClientCount === 5) {
         if (person?.customer?.package?.addOns.find((item) => item.name === AddOnName.ACTIVE_CUSTOMER)?.quantity <= 0) {
            enqueueSnackbar('Hakkınızın bitmesine 5 kişi kaldı', {
               variant: 'error',
               autoHideDuration: 4000,
            });
         }
      }
      if (person?.customer?.package?.clientCount === person?.customer?.usageClientCount) {
         if (person?.customer?.package?.addOns.find((item) => item.name === AddOnName.ACTIVE_CUSTOMER)?.quantity <= 0) {
            enqueueSnackbar('Hakkınız kalmadı', {
               variant: 'error',
               autoHideDuration: 4000,
            });
         }
      }
   }, [
      enqueueSnackbar,
      person?.customer,
      person?.customer?.package?.addOns,
      person?.customer?.package?.clientCount,
      person?.customer?.usageClientCount,
   ]);

   if (!chatFilters) return null;

   return (
      <ThemeProvider theme={theme}>
         <LanguageContext.Provider
            value={{
               lang: language,
               switchLang: setLanguage,
               languages: getAllLanguages(),
            }}
         >
            {navigator.onLine === false || navigator.onLine === 0 ? (
               <OffLineItem />
            ) : token === null || PROJECT_VERSION !== projectVersion ? (
               <Switch>
                  <Route exact component={Confirmation} path='/sign-up/confirmation' />
                  <Route path='/loginbyadmin' component={LoginByAdmin} />
                  <Route component={Verify} path='/confirm-email/:emailId' />
                  <Route component={Preload} />
               </Switch>
            ) : (
               <Fragment>
                  <AutoLogout />
                  <CssBaseline />

                  <div style={{ display: showNavs ? undefined : 'flex' }}>
                     {showSideBar && !showNavs && <Sidebar displayIsInExtraSmallSize={memoProps.smallSize} />}
                     {navBar && !showNavs && !showNavBar && (
                        <NavBar
                           profileStatus={memoProps.toggleProfile}
                           toggleProfile={memoToggleProfileFunc}
                           sideBarWidth={memoProps.sideBarWidth}
                        />
                     )}
                     <Switch>
                        <Route
                           exact
                           path='/'
                           render={() => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              }
                              return <Dashboard />;
                           }}
                        />
                        <Route path='/welcome'>
                           <Welcome />
                        </Route>
                        <Route
                           exact
                           path='/chat'
                           render={() => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              return (
                                 <Chat
                                    profileStatus={memoProps.toggleProfile}
                                    onProfileCloseRequest={memoToggleProfileFunc}
                                 />
                              );
                           }}
                        />
                        <Route
                           exact
                           path='/chat/:_id'
                           render={() => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              return (
                                 <Chat
                                    profileStatus={memoProps.toggleProfile}
                                    onProfileCloseRequest={memoToggleProfileFunc}
                                 />
                              );
                           }}
                        />
                        <Route
                           exact
                           path='/chat/:_id/profile'
                           render={() => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              return (
                                 <Chat
                                    profileStatus={memoProps.toggleProfile}
                                    onProfileCloseRequest={memoToggleProfileFunc}
                                 />
                              );
                           }}
                        />

                        {/* Campaign */}
                        <Route
                           path='/campaigns/:_id'
                           render={() => {
                              return <CreateNewCampaign />;
                           }}
                        />

                        {/* Campaigns */}
                        <Route
                           path='/campaigns'
                           render={() => {
                              return <Campaigns />;
                           }}
                        />

                        {/* Send Bulk Message */}
                        <Route
                           path='/bulk-messaging/send'
                           render={() => {
                              return <BulkMessagingSend />;
                           }}
                        />

                        {/* Bulk Messaging */}
                        <Route
                           path='/bulk-messaging'
                           render={() => {
                              return <BulkMessaging />;
                           }}
                        />

                        {/* Questions Integration */}
                        <Route
                           path='/questions'
                           render={() => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              return (
                                 <Questions
                                    toggleProfile={memoToggleProfileFunc}
                                    profileStatus={memoProps.toggleProfile}
                                    onProfileCloseRequest={memoToggleProfileFunc}
                                 />
                              );
                           }}
                        />
                        <Route
                           path='/settings'
                           render={() => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              }
                              return <Settings />;
                           }}
                        />
                        <Route
                           path='/contacts'
                           render={() => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              return <Contacts />;
                           }}
                        />

                        <Route
                           path='/active-visitors'
                           render={() => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              return <PersonActivity />;
                           }}
                        />
                        <Route
                           exact
                           path='/authentication/facebook'
                           render={(props) => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              else {
                                 return <FacebookCreateAccount {...props} />;
                              }
                           }}
                        />

                        <Route
                           exact
                           path='/authentication/shopify'
                           render={(props) => {
                              if (isDisabledAccount) {
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              } else {
                                 return <ShopifyCreateAccount {...props} />;
                              }
                           }}
                        />
                        <Route
                           exact
                           path='/authentication/faprika'
                           render={(props) => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              else {
                                 return <FapricaCreateAccount {...props} />;
                              }
                           }}
                        />
                        <Route
                           exact
                           path='/authentication/faprika/close'
                           render={(props) => {
                              setTimeout(() => {
                                 window.close();
                              }, 1000);

                              return <div class='loading'></div>;
                           }}
                        />
                        <Route
                           exact
                           path='/authentication/ideasoft/close'
                           render={(props) => {
                              setTimeout(() => {
                                 window.close();
                              }, 1000);

                              return <div class='loading'></div>;
                           }}
                        />

                        <Route
                           exact
                           path='/authentication/ideasoft'
                           render={(props) => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              else {
                                 return <IdeasoftCreateAccount {...props} />;
                              }
                           }}
                        />

                        <Route
                           exact
                           path='/authentication/tsoft'
                           render={(props) => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              else {
                                 return <TsoftCreateAccount {...props} />;
                              }
                           }}
                        />
                        <Route
                           exact
                           path='/authentication/tsoft/close'
                           render={(props) => {
                              setTimeout(() => {
                                 window.close();
                              }, 1000);

                              return <div class='loading'></div>;
                           }}
                        />

                        <Route
                           exact
                           path='/authentication/woocommerce'
                           render={(props) => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              else {
                                 return <WooCommerceCreateAccount {...props} />;
                              }
                           }}
                        />
                        <Route
                           exact
                           path='/authentication/woocommerce/close'
                           render={(props) => {
                              setTimeout(() => {
                                 window.close();
                              }, 1000);

                              return <div class='loading'></div>;
                           }}
                        />
                        <Route
                           exact
                           path='/authentication/shopify/close'
                           render={(props) => {
                              setTimeout(() => {
                                 window.close();
                              }, 1000);

                              return <div class='loading'></div>;
                           }}
                        />

                        <Route
                           exact
                           path='/authentication/instagram'
                           render={(props) => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              else {
                                 return <InstagramCreateAccount {...props} />;
                              }
                           }}
                        />
                        <Route
                           path='/account'
                           render={(props) => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else {
                                 return <Account />;
                              }
                           }}
                        />
                        <Route
                           path='/statistics'
                           render={(props) => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              } else if (isDisabledAccount)
                                 return (
                                    <Redirect to='/'>
                                       <Dashboard />
                                    </Redirect>
                                 );
                              else {
                                 return <Statistics />;
                              }
                           }}
                        />
                        <Route
                           path='/automation'
                           render={() => {
                              if (showWelcome) {
                                 return <Redirect to='/welcome' />;
                              }
                              return <Automation />;
                           }}
                        />
                        <Redirect to='/' />
                     </Switch>
                     <Modal open={marketingModalConfig.show} onClose={() => {}}>
                        <div>
                           <Marketing
                              marketingContent={marketingModalConfig.marketingContent}
                              onClose={handleCloseNotification}
                              onShowLater={handleShowNotificationLater}
                           />
                        </div>
                     </Modal>
                  </div>
               </Fragment>
            )}
         </LanguageContext.Provider>
      </ThemeProvider>
   );
};

export default App;

import { useMutation } from '@apollo/client';
import { generateRandomId } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConditionIcon } from '@modules/Automation/FlowBots/FlowBuilder/icons/icons';
import DeleteFow from '@modules/Automation/FlowBots/components/DeleteFlow';
import {
   FlowInstanceMessageChannel,
   IFlowBlockField,
   IFlowBlockFieldRules,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {
   CREATE_FLOW_NODE_MUTATION,
   DELETE_FLOW_NODE_MUTATION,
   UPDATE_ASK_QUESTION_BLOCK_MUTATION,
   UPDATE_BLOCK_MUTATION,
   UPDATE_FLOW_NODE_MUTATION,
} from '@queries/Automation/mutation';
import { SET_CLICKED_NODE_ID } from '@store/actions/automation';
import React, { memo, useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Handle, Node, Position, useReactFlow } from 'reactflow';
import UngroupIcon from '../../../icons/UngroupIcon.svg';
import useDetachNodes from '../../App/DynamicGrouping/useDetachNodes';

const useStyles = makeStyles(() => ({
   container: {
      position: 'relative',
      display: 'inline-block',
      paddingTop: 5,
   },
   conditionIcon: {
      width: 25,
      height: 25,
      margin: '0 5px 5px 5px',
   },
   customHeader: {
      display: 'flex',
      borderBottom: '0.1px solid #f2f2f2',
   },
   addText: {
      padding: '10px',
      margin: '5px',
      textAlign: 'center',
      borderRadius: 2,
      fontSize: 7,
      color: '#AFAFAF',
      backgroundColor: '#F7F7FA',
   },
   nodeContainer: {
      border: '1px solid #ffffff',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   nodeContainerHovered: {
      border: '1px solid #157cfc',
      background: 'rgba(255, 255, 255, 0.6)',
   },
   footer: {
      borderTop: ' 0.1px solid #f2f2f2',
      height: '12px',
      position: 'relative',
   },
   handle: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '-1px',
      marginTop: '3px',
      backgroundColor: '#fff',
      width: '9px',
      height: '9px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      cursor: 'default',
      '&:hover': {
         cursor: 'default',
         marginRight: '-2px',
         width: '11px',
         height: '11px',
      },
   },
   handleTarget: {
      backgroundColor: '#fff',
      width: '5px',
      height: '5px',
      top: 14,
      marginLeft: '5px',
   },
   handleBlock: {
      position: 'absolute',
      top: '10px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#fff',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleNotMatchBlock: {
      position: 'absolute',
      top: '10px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#fff',
      width: '8px',
      height: '8px',
      border: '1px solid #FF5A80',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleEdgeConnected: {
      position: 'absolute',
      top: '10px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#ADADAD',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleNotMatchEdgeConnected: {
      position: 'absolute',
      top: '10px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#FF5A80',
      width: '8px',
      height: '8px',
      border: '1px solid #FF5A80',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   iconBox: {
      padding: '2px 3px',
      margin: '0 5px 5px 5px',
      borderRadius: '3px',
      display: 'flex',
      justifyContent: 'center',
   },

   subBlocksTags: {
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      maxWidth: '100%',
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: '10fr 1fr',
      padding: '4px',
      border: '1px solid #F2F2F2',
      borderRadius: '5px',
   },
   blocks: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      margin: '4px',
      fontSize: '7px',
   },
   blockItem: {
      display: 'flex',
      gap: '2px',
      flexWrap: 'wrap',
      paddingBottom: '2.5px',
      borderBottom: '0.1px solid #f2f2f2',
      '&:last-child': {
         borderBottom: 'none',
      },
   },
   InvalidNodeContainer: {
      border: '1px solid #FF7474',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   line: {
      width: '40%',
   },
   iconDiv: {
      display: 'flex',
      borderRadius: 5,
      backgroundColor: '#FFFFFF',
   },
   innerIcons: {
      padding: '2px 5px',
      borderRadius: '5px',
      transition: 'background-color 0.3s ease',
      backgroundColor: 'transparent',
      '&:hover': {
         backgroundColor: '#E8E8E8',
      },
   },
}));

interface ConditionProps {
   id: string;
   isConnectable: boolean;
   data: {
      clickedNodeId: string;
      title: string;
      setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
      setHandleToUpdate: React.Dispatch<React.SetStateAction<string>>;
      setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
      setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
      flowBlocks: IFlowBlockTemplate[];
      isValidNode: Boolean;
      isCreation: Boolean;
      setIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
      setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
      setToGroup: React.Dispatch<React.SetStateAction<string[]>>;
      setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   };
}

const Condition = ({ id, isConnectable, data }: ConditionProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const {
      title,
      clickedNodeId,
      setIsNewNode,
      setClickedNodeId,
      setClickedHandleId,
      setHandleToUpdate,
      isValidNode,
      flowBlocks,
      setIsDraggable,
      isCreation,
      setContentNodes,
      setLeftNodes,
      setToGroup,
   } = data;
   const reactFlow = useReactFlow();
   const nodeList = reactFlow.getNodes();
   const [deleteTarget, setDeleteTarget] = useState(false);
   const [isDelete, setIsDelete] = useState(false);

   const [isHover, setIsHover] = useState(false);
   const [matchingBlocks, setMatchingBlocks] = useState<{ blockId: string; targets: string[] }[]>([]);
   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);
   const LOCATIONS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.locations);
   const LANGUAGES = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.languages);

   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlock = async (blockId: string, target: null) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  target: target,
               },
            },
            onCompleted: (res) => {
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, target: target };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // Filter the flowBlocks where the target matches the current Node Id
   const filteredFlowBlocks = nodeList
      .map((item) => item?.data?.flowBlocks)
      .flat()
      .filter((flowBlock) => flowBlock?.target === id);

   const [quickReplyBlocks, setQuickReplyBlocks] = useState<IFlowBlockTemplate[]>([]);
   const [infButtonBlocks, setInfButtonBlocks] = useState<IFlowBlockTemplate[]>([]);

   useEffect(() => {
      // Filter the flowBlocks where the target matches the current Node Id in the quick reply blocks
      const filteredFlowBlocksQuickReply = nodeList.flatMap(
         (node) =>
            node?.data?.flowBlocks?.filter((flowBlock: IFlowBlockTemplate) =>
               flowBlock?.fields?.some((field) => field?.quickReplies?.some((quickReply) => quickReply?.target === id)),
            ) || [],
      );

      // Update quickReplyBlocks with targets set to null
      const updatedQuickReplyBlocks = filteredFlowBlocksQuickReply.map((flowBlock) => {
         return {
            ...flowBlock,
            fields: flowBlock.fields?.map((field: IFlowBlockField) => ({
               ...field,
               quickReplies: field.quickReplies?.map((quickReply) => ({
                  ...quickReply,
                  target: quickReply.target === id ? null : quickReply.target,
               })),
            })),
         };
      });

      setQuickReplyBlocks(updatedQuickReplyBlocks);
   }, [flowBlocks]);

   useEffect(() => {
      // Filter the flowBlocks where the target matches the current Node Id in the informative button blocks
      const filteredFlowBlocksInfButton = nodeList.flatMap(
         (node) =>
            node?.data?.flowBlocks?.filter((flowBlock: IFlowBlockTemplate) =>
               flowBlock?.fields?.some((field) =>
                  field?.informativeButtons?.some((infButton) => infButton?.target === id),
               ),
            ) || [],
      );

      // Update informativeButtons with targets set to null
      const updatedInfButtonBlocks = filteredFlowBlocksInfButton.map((flowBlock) => {
         return {
            ...flowBlock,
            fields: flowBlock.fields?.map((field: IFlowBlockField) => ({
               ...field,
               informativeButtons: field.informativeButtons?.map((infButton) => ({
                  ...infButton,
                  target: infButton.target === id ? null : infButton.target,
               })),
            })),
         };
      });

      setInfButtonBlocks(updatedInfButtonBlocks);
   }, [flowBlocks]);

   const handleUpdateFlowBlockQuickReply = async (blockId: string, blockFields: IFlowBlockField[]) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: blockFields.map((field: any) => {
                     for (let [key, value] of Object.entries(field)) {
                        if (value == null) {
                           delete field[key];
                        }
                     }
                     return field;
                  }),
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // Update askquestion blocks mutation
   const [updateBlockAskQuestion] = useMutation(UPDATE_ASK_QUESTION_BLOCK_MUTATION);
   const handleUpdateFlowBlockAskQuestion = async (blockId: string, fieldName: string) => {
      try {
         await updateBlockAskQuestion({
            variables: {
               input: {
                  _id: blockId,
                  fieldIndex: 0,
                  fieldName: fieldName,
                  fieldValue: null,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlockField.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
         setIsNewNode(generateRandomId());
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   useEffect(() => {
      setMatchingBlocks([]);

      // Get all askquestion blocks wherethe curen id is used as target source handle
      nodeList
         .map((item) => item?.data?.flowBlocks)
         .flat()
         .forEach((flowBlock) => {
            if (flowBlock?.fields) {
               const matchingTargets = flowBlock.fields.filter(
                  (field: {
                     target_on_reply: string;
                     target_on_not_reply: string;
                     target_on_invalid_input: string;
                  }) => {
                     return (
                        field?.target_on_reply === id ||
                        field?.target_on_not_reply === id ||
                        field?.target_on_invalid_input === id
                     );
                  },
               );

               if (matchingTargets.length > 0) {
                  const targets: string[] = [];

                  if (matchingTargets.some((target: { target_on_reply: string }) => target.target_on_reply === id)) {
                     targets.push('target_on_reply');
                  }

                  if (
                     matchingTargets.some(
                        (target: { target_on_not_reply: string }) => target.target_on_not_reply === id,
                     )
                  ) {
                     targets.push('target_on_not_reply');
                  }

                  if (
                     matchingTargets.some(
                        (target: { target_on_invalid_input: string }) => target.target_on_invalid_input === id,
                     )
                  ) {
                     targets.push('target_on_invalid_input');
                  }

                  setMatchingBlocks((prev) => [...prev, { blockId: flowBlock._id, targets: targets }]);
               }
            }
         });
   }, [clickedNodeId]);

   const handleUpdateFlowBlockHttpRequest = async (blockId: string, fieldName: string) => {
      try {
         const foundField = nodeList.find((node) =>
            node.data?.flowBlocks?.some((block: IFlowBlockTemplate) => block._id === blockId),
         )?.data.flowBlocks[0]?.fields[0];

         let fields = [];
         if (fieldName === 'targetOnSuccess') {
            fields = [
               {
                  targetOnSuccess: null,
                  targetOnFailure: foundField.targetOnFailure,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         } else {
            fields = [
               {
                  targetOnFailure: null,
                  targetOnSuccess: foundField.targetOnSuccess,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         }
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev) => updateNodes(prev, blockId));
               setLeftNodes((prev) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const [matchingHttpBlocks, setMatchingHttpBlocks] = useState<{ blockId: string; targets: string[] }[]>([]);
   useEffect(() => {
      setMatchingHttpBlocks([]);

      // Get all askquestion blocks wherethe curen id is used as target source handle
      nodeList
         .map((item) => item?.data?.flowBlocks)
         .flat()
         .forEach((flowBlock) => {
            if (flowBlock?.fields) {
               const matchingTargets = flowBlock.fields.filter(
                  (field: { targetOnSuccess: string; targetOnFailure: string }) => {
                     return field?.targetOnSuccess === id || field?.targetOnFailure === id;
                  },
               );

               if (matchingTargets.length > 0) {
                  const targets: string[] = [];

                  if (matchingTargets.some((target: { targetOnFailure: string }) => target.targetOnFailure === id)) {
                     targets.push('targetOnFailure');
                  }

                  if (matchingTargets.some((target: { targetOnSuccess: string }) => target.targetOnSuccess === id)) {
                     targets.push('targetOnSuccess');
                  }

                  setMatchingHttpBlocks((prev) => [...prev, { blockId: flowBlock._id, targets: targets }]);
               }
            }
         });
   }, [clickedNodeId]);

   useEffect(() => {
      if (deleteTarget) {
         const target = null;
         filteredFlowBlocks.forEach((block) => {
            handleUpdateFlowBlock(block._id, target);
         });

         if (quickReplyBlocks.length > 0) {
            quickReplyBlocks.forEach((block) => {
               handleUpdateFlowBlockQuickReply(block._id, block.fields);
            });
         }
         if (infButtonBlocks.length > 0) {
            infButtonBlocks.forEach((block) => {
               handleUpdateFlowBlockQuickReply(block._id, block.fields);
            });
         }
      }

      if (matchingBlocks) {
         matchingBlocks.forEach((block) => {
            block.targets.forEach((target) => {
               handleUpdateFlowBlockAskQuestion(block.blockId, target);
            });
         });
      }

      if (matchingHttpBlocks) {
         matchingHttpBlocks.forEach((block) => {
            block.targets.forEach((target) => {
               handleUpdateFlowBlockHttpRequest(block.blockId, target);
            });
         });
      }

      return setDeleteTarget(false);
   }, [deleteTarget]);

   const [deleteFlowNode] = useMutation(DELETE_FLOW_NODE_MUTATION);
   const handleDeleteFlowNode = async (itemId: String) => {
      setDeleteTarget(true);
      setIsDelete(true);
      try {
         await deleteFlowNode({
            variables: {
               input: {
                  _id: itemId,
               },
            },
            onCompleted: () => {
               setLeftNodes((prev) => prev.filter((node) => node.id !== itemId));
               setContentNodes((prev) => prev.filter((node) => node.id !== itemId));

               deleteElements({ nodes: [{ id }] });
               setIsDelete(false);
            },
         });
      } catch (error) {
         console.error('Error deleting node:', error);
      }
   };

   // delete node
   const { deleteElements } = useReactFlow();

   const getLabelFromLocation = (value: string) => {
      const locationItem =
         LOCATIONS && LOCATIONS.find((item: { value: string; label: string }) => item.value === value);
      return locationItem ? locationItem.label : '';
   };

   const getLabelFromLanguages = (value: string) => {
      const Item = LANGUAGES && LANGUAGES.find((item: { value: string }) => item.value === value);
      return Item ? Item.label : '';
   };
   const filteredNoMatchBlocks = flowBlocks.filter((block) =>
      block.fields.some((field) => field.value === 'on_no_match'),
   );
   const blocks = flowBlocks.slice(1);
   const limitedTitle = title.length > 21 ? `${title.slice(0, 19)}...` : title;

   const [isDuplicating, setIsDuplicating] = useState<boolean>(false);
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   const [createFlowNode] = useMutation(CREATE_FLOW_NODE_MUTATION);

   const handleCreateNode = async () => {
      setIsDuplicating(true);
      try {
         const currentNode = nodeList.find((node) => node.id === id) as any;

         const currentBlocks = currentNode.data?.flowBlocks.map((current: any) => ({
            flowBlockTemplateId: current.templateId,
            channelType: current.channelType,
            fields: current.fields.map((field: any) => {
               const filteredField = Object.fromEntries(
                  Object.entries(field).map(([key, value]) => {
                     // Exclude specific keys and values
                     const excludeKeys = [
                        'target_on_reply',
                        'target_on_not_reply',
                        'target_on_invalid_input',
                        'targetOnSuccess',
                        'targetOnFailure',
                     ];

                     if (excludeKeys.includes(key)) {
                        return [key, value];
                     }

                     // Exclude 'target' key from informativeButtons and quickReplies arrays
                     if (key === 'informativeButtons' && Array.isArray(value)) {
                        const updatedButtons = value.map((button) => {
                           if (button && button.target) {
                              const { target, ...buttonWithoutTarget } = button;
                              return buttonWithoutTarget;
                           }
                           return button;
                        });
                        return [key, updatedButtons];
                     }

                     if (key === 'quickReplies' && Array.isArray(value)) {
                        const updatedReplies = value.map((reply) => {
                           if (reply && reply.target) {
                              const { target, ...replyWithoutTarget } = reply;
                              return replyWithoutTarget;
                           }
                           return reply;
                        });
                        return [key, updatedReplies];
                     }

                     return [key, value];
                  }),
               );

               const finalFilteredField = Object.fromEntries(
                  Object.entries(filteredField).filter(([key, value]) => value !== null),
               );

               return finalFilteredField;
            }),
         }));

         if (currentNode) {
            const sourceParent = currentNode?.parentNode;
            const parentPosition = nodeList.find((node) => node.id === sourceParent)?.position;
            let childPosition = null;
            const newPosition = { x: currentNode.position.x + 200, y: currentNode.position.y };
            if (parentPosition) {
               childPosition = {
                  x: newPosition.x + parentPosition?.x - 25,
                  y: newPosition.y + parentPosition?.y - 25,
               };
            }

            const position = childPosition ? childPosition : newPosition;

            createFlowNode({
               variables: {
                  input: {
                     coordinates: position,
                     customer: person.customer._id,
                     flowBot: currentNode.flowBot,
                     templateId: currentNode.templateId,
                     title: t.automation_duplicate_copy + ' - ' + currentNode.data?.title,
                     flowBlocksToBeCreated: currentBlocks,
                     groupId: currentNode.parentNode,
                  },
               },
               onCompleted: async (res) => {
                  const duplicatedNode = res.createFlowNode.data;

                  const sourceParent = currentNode?.parentNode;
                  const parentPosition = nodeList.find((node) => node.id === sourceParent)?.position;
                  let childPosition = null;
                  const newPosition = duplicatedNode.coordinates;
                  if (parentPosition) {
                     childPosition = {
                        x: newPosition.x - parentPosition?.x + 25,
                        y: newPosition.y - parentPosition?.y + 25,
                     };
                  }

                  const newTargetNode = {
                     id: duplicatedNode._id,
                     type: duplicatedNode.type,
                     flowBot: duplicatedNode.flowBot,
                     templateId: duplicatedNode.templateId,
                     parentNode: duplicatedNode.groupId,
                     title: duplicatedNode.title,
                     data: {
                        title: duplicatedNode.title,
                        clickedNodeId,
                        flowBlocks: duplicatedNode.flowBlocks,
                        setIsDraggable: setIsDraggable,
                        setClickedNodeId: setClickedNodeId,
                        setLeftNodes: setLeftNodes,
                        setContentNodes: setContentNodes,
                        isValidNode: duplicatedNode.flowBlocks.length > 0 ? true : false,
                        setClickedHandleId: setClickedHandleId,
                        setIsNewNode: setIsNewNode,
                        setHandleToUpdate: setHandleToUpdate,
                        setToGroup: setToGroup,
                     },
                     position: childPosition ? childPosition : duplicatedNode.coordinates,
                  };
                  setContentNodes((prevNodes) => [...prevNodes, newTargetNode]);
                  setLeftNodes((prevNodes) => [...prevNodes, newTargetNode]);

                  setIsDuplicating(false);
               },
            });
         }
      } catch (error) {
         console.error('Error creating node:', error);
      }
   };

   const fieldHasValue = blocks?.some((item) =>
      item.fields.some(
         (field) =>
            (field.value && field.value.length > 0) ||
            field.rule === IFlowBlockFieldRules.HasAnyValue ||
            field.rule === IFlowBlockFieldRules.Unknown,
      ),
   );
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   useEffect(() => {
      setIsHover(id === clickedId);
   }, [data, id, clickedId]);

   const dispatch = useDispatch();
   const handleClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      isCreation && event.stopPropagation();
      if (botStatus === 'draft') {
         setClickedNodeId(id);
         dispatch({ type: SET_CLICKED_NODE_ID, payload: id });
      }
   };

   const currentNode = reactFlow.getNode(id);
   useEffect(() => {
      if (currentNode?.selected) {
         setIsHover(true);
      }
   }, [currentNode]);

   const detachNodes = useDetachNodes();

   const [updateFlowNode] = useMutation(UPDATE_FLOW_NODE_MUTATION);
   const handleUpdateFlowNodeGroup = async (itemId: string, groupId: string | null) => {
      try {
         await updateFlowNode({
            variables: {
               input: {
                  _id: itemId,
                  groupId: groupId,
               },
            },
         });
      } catch (error) {
         console.error('Error updating node:', error);
      }
   };
   const handleDetachNode = () => {
      const parentNode = currentNode?.parentNode;
      const allChildNodes = nodeList.filter(
         (node) => node.parentNode === parentNode && node.parentNode !== undefined && node.parentNode !== null,
      );
      const allChildNodeIds = nodeList.filter((node) => node.parentNode === parentNode).map((chdNode) => chdNode.id);
      if (allChildNodeIds.length > 2) {
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         const remainingNodeIds = allChildNodes.filter((node) => node.id !== id).map((chdNode) => chdNode.id);
         setToGroup(remainingNodeIds);
         //Update the detached node with groupId to null
         handleUpdateFlowNodeGroup(id, null);
      } else {
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         allChildNodeIds.forEach((sn) => handleUpdateFlowNodeGroup(sn, null));
      }
   };

   const handleDeleteNode = async () => {
      const parentNode = currentNode?.parentNode;
      const allChildNodes = nodeList.filter(
         (node) => node.parentNode === parentNode && node.parentNode !== undefined && node.parentNode !== null,
      );
      const allChildNodeIds = nodeList.filter((node) => node.parentNode === parentNode).map((chdNode) => chdNode.id);
      if (allChildNodeIds.length > 2) {
         await handleDeleteFlowNode(id);
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         const remainingNodeIds = allChildNodes.filter((node) => node.id !== id).map((chdNode) => chdNode.id);
         setToGroup(remainingNodeIds);
      } else {
         await handleDeleteFlowNode(id);
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
      }
   };

   const onDetach = () => handleDetachNode();
   const onDelete = () => handleDeleteNode();

   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (id === clickedId || currentNode?.selected ? setIsHover(true) : setIsHover(false))}
         className={classes.container}
      >
         {isHover && !isCreation && (
            <div
               style={{
                  position: 'absolute',
                  top: '-20px',
                  right: currentNode?.parentNode ? '33%' : '40%',
                  cursor: 'pointer',
               }}
               onMouseEnter={() => setIsDraggable(false)}
               onMouseLeave={() => setIsDraggable(true)}
            >
               {botStatus === 'draft' && (
                  <div className={classes.iconDiv}>
                     {isDuplicating ? (
                        <div className={classes.innerIcons}>
                           <CircularProgress size={13} />
                        </div>
                     ) : (
                        <div className={classes.innerIcons} onClick={() => handleCreateNode()}>
                           <ContentCopyIcon style={{ fontSize: '12px', fontWeight: 800 }} />
                        </div>
                     )}

                     {!isDelete ? (
                        <div className={classes.innerIcons} onClick={() => setShowDeleteModal(true)}>
                           <DeleteIcon style={{ fontSize: '13px', color: 'red' }} />
                        </div>
                     ) : (
                        <div className={classes.innerIcons}>
                           <CircularProgress size={13} />
                        </div>
                     )}

                     {currentNode?.parentNode && (
                        <div className={classes.innerIcons} onClick={onDetach}>
                           <img style={{ width: 12, height: 12 }} src={UngroupIcon} alt='UngroupIcon'></img>
                        </div>
                     )}
                  </div>
               )}
            </div>
         )}
         <div
            className={`${classes.nodeContainer} ${isHover && isValidNode ? classes.nodeContainerHovered : ''} ${
               !isValidNode ? classes.InvalidNodeContainer : ''
            }`}
            onClick={(event) => handleClicked(event)}
         >
            <div className={classes.customHeader}>
               <Handle
                  type='target'
                  position={Position.Left}
                  id={id}
                  className={classes.handleTarget}
                  isConnectable={isConnectable}
               />
               <div style={{ display: 'flex', gap: '5px', margin: '0px 5px 5px 5px' }}>
                  <ConditionIcon />
                  <div style={{ marginTop: '3px', fontSize: 9 }}>{limitedTitle}</div>
               </div>
            </div>
            {blocks.length > 0 && fieldHasValue ? (
               <div className={classes.blocks}>
                  {blocks.map((item, index) => (
                     <div>
                        <div key={index} className={classes.subBlocksTags}>
                           <div style={{ display: 'flex', flexDirection: 'column', gap: '2.5px' }}>
                              {item.fields?.map((field, fieldIndex) => (
                                 <div key={fieldIndex} className={classes.blockItem}>
                                    <span style={{ color: '#000000' }}>
                                       {field.condition_field === 'channel'
                                          ? t.atomation_flow_condition_channel
                                          : field.condition_field === 'email_consent'
                                          ? t.atomation_flow_condition_email_consent
                                          : field.condition_field === 'message_content'
                                          ? t.atomation_flow_condition_message_content
                                          : field.condition_field === 'phone_consent'
                                          ? t.atomation_flow_condition_phone_consent
                                          : field.condition_field === 'browser_language'
                                          ? t.atomation_flow_condition_browser_language
                                          : field.condition_field === 'browser_country'
                                          ? t.atomation_flow_condition_browser_country
                                          : field.condition_field === 'working_hours'
                                          ? t.atomation_flow_condition_working_hours
                                          : field.condition_field === 'email'
                                          ? t.pre_load_input_label_email
                                          : field.condition_field === 'surname'
                                          ? t.atomation_flow_last_name
                                          : field.condition_field === 'name'
                                          ? t.atomation_flow_first_name
                                          : field.condition_field === 'phone'
                                          ? t.atomation_flow_phone
                                          : field.condition_field === 'page_url'
                                          ? t.atomation_flow_condition__page_url
                                          : field.condition_field}
                                    </span>
                                    <span style={{ color: '#818181' }}>
                                       {field.rule === IFlowBlockFieldRules.Contains
                                          ? t.atomation_flow_trigger_block_contains
                                          : field.rule === IFlowBlockFieldRules.Equal
                                          ? t.atomation_flow_trigger_block_equal
                                          : field.rule === IFlowBlockFieldRules.NotContains
                                          ? t.atomation_flow_trigger_block_not_contains
                                          : field.rule === IFlowBlockFieldRules.HasAnyValue
                                          ? t.atomation_flow_trigger_block_has_any_value
                                          : field.rule === IFlowBlockFieldRules.Unknown
                                          ? t.atomation_flow_trigger_block_unknown
                                          : field.rule === IFlowBlockFieldRules.EndWith
                                          ? t.atomation_flow_trigger_block_end_with
                                          : field.rule === IFlowBlockFieldRules.StartWith
                                          ? t.atomation_flow_trigger_block_start_with
                                          : t.atomation_flow_trigger_block_not_equal}
                                    </span>
                                    <span style={{ color: '#000000' }}>
                                       {field.value === 'in_of_working_hours'
                                          ? t.atomation_flow_in_working_hours
                                          : field.value === 'not_in_working_hours'
                                          ? t.atomation_flow_not_in_working_hours
                                          : field.value && field.condition_field === 'browser_country'
                                          ? getLabelFromLocation(field.value)
                                          : field.value && field.condition_field === 'browser_language'
                                          ? getLabelFromLanguages(field.value)
                                          : field.condition_field === 'email_consent'
                                          ? field.value === 'true'
                                             ? t.page_contacts_typo_subscribed
                                             : t.page_contacts_typo_unsubscribed
                                          : field.condition_field === 'phone_consent'
                                          ? field.value === 'true'
                                             ? t.page_contacts_typo_subscribed
                                             : t.page_contacts_typo_unsubscribed
                                          : field.value && field.condition_field === 'channel'
                                          ? field.value === FlowInstanceMessageChannel.WhatsappBusiness
                                             ? t.atomation_flow_whatsappbussiness
                                             : field.value === FlowInstanceMessageChannel.WhatsappWeb
                                             ? t.page_settings_typo_platforms_whats_app_web
                                             : field.value === FlowInstanceMessageChannel.LiveChat
                                             ? t.page_settings_typo_platforms_live_chat
                                             : field.value
                                          : field.value}
                                    </span>
                                 </div>
                              ))}
                           </div>

                           <Handle
                              type='source'
                              position={Position.Right}
                              id={item._id}
                              className={item.target ? classes.handleEdgeConnected : classes.handleBlock}
                              isConnectable={isConnectable}
                              onMouseEnter={() => setClickedHandleId(item._id)}
                           />
                        </div>

                        {index < blocks.length - 1 && (
                           <div style={{ display: 'flex', margin: '5px 5px 0 5px' }}>
                              <div className={classes.line}>
                                 <hr style={{ borderBottom: '1px solid #EDEDED' }} color='#FFFFFF' />
                              </div>
                              <div style={{ width: '20%', textAlign: 'center' }}>{t.atomation_flow_Or}</div>
                              <div className={classes.line}>
                                 <hr style={{ borderBottom: '1px solid #EDEDED' }} color='#FFFFFF' />
                              </div>
                           </div>
                        )}
                     </div>
                  ))}
               </div>
            ) : isCreation ? (
               <Box
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     padding: '10px',
                     margin: '5px',
                     backgroundColor: '#F7F7FA',
                  }}
               >
                  <CircularProgress size={14} />
               </Box>
            ) : (
               <div className={classes.addText}>{t.atomation_flow_condition_add}</div>
            )}

            {filteredNoMatchBlocks && fieldHasValue && (
               <div className={classes.footer}>
                  <div style={{ fontSize: '7px' }}>
                     <span style={{ position: 'absolute', top: 4, right: 17, color: '#717171' }}>
                        {t.atomation_flow_not_match_condition}
                     </span>
                     <Handle
                        type='source'
                        position={Position.Right}
                        id={filteredNoMatchBlocks[0]?._id}
                        className={
                           filteredNoMatchBlocks[0]?.target
                              ? classes.handleNotMatchEdgeConnected
                              : classes.handleNotMatchBlock
                        }
                        isConnectable={isConnectable}
                        onMouseEnter={() => setClickedHandleId(filteredNoMatchBlocks[0]?._id)}
                     />
                  </div>
               </div>
            )}
         </div>
         <DeleteFow
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            onComplate={onDelete}
            text={t.automation_flow_delete_step_warning}
         />
      </div>
   );
};

export default memo(Condition);

import { gql } from '@apollo/client';

export const GET_QNA_ACCOUNTS_QUERY = gql`
   query getQnAAccounsQuery($type: QnAPlatformType!) {
      QnAAccounts(filter: { type: $type }) {
         data
         success
         code
         message
      }
   }
`;

import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ChatItemsContainer from './ChatItemsContainer';

const useStyles = makeStyles((theme) => ({
   root: {
      height: '100%',
      maxHeight: '100%',
      overflowY: 'auto', 
      padding: 10,
      paddingRight: 0,
      [theme.breakpoints.down('sm')]: {
         overflowY: 'hidden',
      },
   },
   graphic: {
      backgroundColor: 'white',
      overflow: 'hidden',
   },
   appBarSpacer: theme.mixins.toolbar,
}));

const ChatSpace = ({ chatInputHeight, isEmail }) => {
   const classes = useStyles();
   return (
      <Grid item xs={12} className={clsx(classes.root)}>
         <div className={classes.appBarSpacer} />
         <ChatItemsContainer chatInputHeight={chatInputHeight} isEmail={isEmail} />
      </Grid>
   );
};

export default ChatSpace;

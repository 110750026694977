import React, { FC } from 'react';
import {IconButton, Typography} from "@material-ui/core";
import ArrowBackIosRoundedIcon from "@material-ui/icons/ArrowBackIosRounded";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(() =>({
   newViewHeaderContainer: {
      display: 'flex',
      padding: 8,
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #D9E0E6',
      marginBottom: 8,
   },
   newViewTitleContainer: {
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: 5,
   },
   newViewTitle: {
      color: '#385273',
      fontSize: '1.3em',
      lineHeight: 1.4,
   },
}));

interface ChatRightPanelTabHeaderProps {
   onClose: () => void;
   title: string;
   style?: any | undefined;
}

const ChatRightPanelTabHeader: FC<ChatRightPanelTabHeaderProps> = ({ onClose, title, style }) => {
   const classes = useStyles();

   return (
      <div className={classes.newViewHeaderContainer} style={style}>
         {onClose && (
            <IconButton onClick={onClose}>
               <ArrowBackIosRoundedIcon />
            </IconButton>
         )}
         <div className={classes.newViewTitleContainer}>
            <Typography noWrap className={classes.newViewTitle}>{title}</Typography>
         </div>
      </div>
   )
};

export default ChatRightPanelTabHeader;

import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import { IFlowBlockFieldTypes, IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import { Node } from 'reactflow';

const useStyles = makeStyles((theme) => ({
   btn: {
      height: 36,
      width: '100%',
      border: '1px dashed #D7D7D7',
      borderRadius: '10px',
      background: 'transparent',
      cursor: 'pointer',
      textAlign: 'center',
   },
}));

interface BtnPannelProps {
   handleCreateBlock: (type: IFlowBlockFieldTypes) => void;
   blockState: IFlowBlockTemplate[];
}
const SendMessageButtonPanel: React.FC<BtnPannelProps> = ({ handleCreateBlock, blockState }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const totalBlocks = blockState.length;

   return (
      <Grid>
         <Grid style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '5px', marginTop: '20px' }}>
            <div>
               <button
                  disabled={totalBlocks >= 11}
                  className={classes.btn}
                  onClick={() => handleCreateBlock(IFlowBlockFieldTypes.Text)}
               >
                  {t.atomation_flow_text}
               </button>
            </div>
            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '5px' }}>
               <button
                  disabled={totalBlocks >= 11}
                  className={classes.btn}
                  onClick={() => handleCreateBlock(IFlowBlockFieldTypes.ImageUrl)}
               >
                  {t.atomation_flow_image}
               </button>
               <button
                  disabled={totalBlocks >= 11}
                  className={classes.btn}
                  onClick={() => handleCreateBlock(IFlowBlockFieldTypes.VideoUrl)}
               >
                  {t.atomation_flow_video}
               </button>
            </div>
         </Grid>
      </Grid>
   );
};

export default SendMessageButtonPanel;

import { gql } from '@apollo/client';

// queries
export const GET_DOMAIN_QUERY = gql`
   query getDomain($_id: String!, $customer: String!) {
      getDomain(input: { _id: $_id, customer: $customer }) {
         code
         data {
            _id
            customer
            domain
            valid
            dns {
               mail_cname {
                  valid
                  type
                  host
                  data
               }
               dkim1 {
                  valid
                  type
                  host
                  data
               }
               dkim2 {
                  valid
                  type
                  host
                  data
               }
            }
         }
         success
         message
      }
   }
`;

import React, { useContext } from 'react';
import { Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThumbUp } from '@modules/Settings/SettingSpace/LiveChat/SharedComponents/Icons';
import { LanguageContext, getDefaultLang, getAllLanguages } from '@helper/locale/langContext';

const useStyles = makeStyles({
   contactContainer: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 'auto',
      background: '#F7F7FA',
      borderRadius: 8,
      color: '#10223A',
      padding: 15,
      gap: 15,
      width: 320,
   },
   headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      flexGrow: 1,
      gap: 15,
   },
   headerLeft: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexGrow: 1,
      gap: 5,
   },
   headerText: {
      fontWeight: 'bold',
      fontSize: '.85rem',
   },
   subHeaderText: {
      fontSize: '.85rem',
   },
   headerRight: {
      display: 'flex',
      alignItems: 'center',
      flexGrow: 1,
   },
   thumbUpContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '36px',
      marginRight: '4px',
      backgroundColor: '#D7EEDF',
      color: '#5DCC77',
      border: '1px solid #EDEDED',
      borderRadius: '8px',
   },
   thumbDownContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '36px',
      marginLeft: '4px',
      backgroundColor: '#FFCCD2',
      color: '#ED3737',
      border: '1px solid #EDEDED',
      borderRadius: '8px',
      transform: 'rotate(180deg)',
   },
   fakeInputContainer: {
      backgroundColor: '#FFF',
      border: '1px solid #c8c8c8',
      borderRadius: 6,
      display: 'flex',
      padding: '10px 15px',
      alignItems: 'center',
      flexGrow: 1,
   },
   textArea: {
      textAlign: 'left',
      alignSelf: 'flex-start',
   },
});

const FakeInput = ({ value }) => {
   const classes = useStyles();

   if (!value) return null;
   return (
      <div className={classes.fakeInputContainer}>
         <Typography variant='body1' className={classes.textArea}>
            {value}
         </Typography>
      </div>
   );
};

const RateUsMessage = ({ reaction, message }) => {
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const classes = useStyles();

   return (
      <Box className={classes.contactContainer}>
         <Box className={classes.headerContainer}>
            <Box className={classes.headerLeft}>
               <Typography className={classes.headerText}>{t?.your_feedback}</Typography>
               <Typography
                  className={classes.subHeaderText}
                  style={{ color: reaction === 'positive' ? '#5DCC77' : '#ED3737' }}
               >
                  {reaction ? t[`page_settings_typo_platforms_live_chat_languages_default_${reaction}`] : ''}
               </Typography>
            </Box>
            <div className={classes.headerRight}>
               <div className={reaction === 'positive' ? classes.thumbUpContainer : classes.thumbDownContainer}>
                  <ThumbUp />
               </div>
            </div>
         </Box>

         <FakeInput value={message} />
      </Box>
   );
};

export default RateUsMessage;

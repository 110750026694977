const localeEn = require('./locale/locales/en-US.json');
const localeTr = require('./locale/locales/tr.json');
const moment = require('moment');
require('moment/locale/tr');
// Slugify.extend({'Ç': 'CH'});

moment.locale('tr', {
   relativeTime: {
      future: 'in %s',
      past: '%s önce',
      s: 'Az önce',
      ss: '%s sn',
      m: '1dk',
      mm: '%ddk',
      h: '1s',
      hh: '%ds',
      d: '1g',
      dd: '%dg',
      M: '1a',
      MM: '%dA',
      y: '1y',
      yy: '%dY',
   },
});

moment.locale('en', {
   relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: 'now',
      ss: '%s sec',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1M',
      MM: '%dM',
      y: '1Y',
      yy: '%dY',
   },
});

const LANGUAGES = [
   {
      code: 'en-US',
      shortTitle: 'EN',
      title: 'English',
      default: true,
      routerRef: true,
      translation: localeEn,
      reverseMapper: {},
      icon: 'https://cleandye.com/wp-content/uploads/2020/01/English-icon.png',
   },
   {
      code: 'tr',
      shortTitle: 'TR',
      title: 'Türkçe',
      default: false,
      routerRef: false,
      translation: localeTr,
      reverseMapper: {},
      icon: 'https://www.anfok.com/wp-content/uploads/2016/10/aa645ebcaec842454fb56664bf71f928.png',
   },
];

const LangCodes = [
   { value: 'AF', label: 'Afrikaans' },
   { value: 'sq', label: 'Albanian' },
   { value: 'ar', label: 'Arabic' },
   { value: 'az', label: 'Azerbaijani' },
   { value: 'bn', label: 'Bengali' },
   { value: 'bg', label: 'Bulgarian' },
   { value: 'ca', label: 'Catalan' },
   { value: 'zh_CN', label: 'Chinese (CHN)' },
   { value: 'zh_HK', label: 'Chinese (HKG)' },
   { value: 'zh_TW', label: 'Chinese (TAI)' },
   { value: 'hr', label: 'Croatian' },
   { value: 'cs', label: 'Czech' },
   { value: 'da', label: 'Danish' },
   { value: 'nl', label: 'Dutch' },
   { value: 'en', label: 'English' },
   { value: 'en_GB', label: 'English (UK)' },
   { value: 'en_US', label: 'English (US)' },
   { value: 'et', label: 'Estonian' },
   { value: 'fil', label: 'Filipino' },
   { value: 'fi', label: 'Finnish' },
   { value: 'fr', label: 'French' },
   { value: 'ka', label: 'Georgian' },
   { value: 'de', label: 'German' },
   { value: 'el', label: 'Greek' },
   { value: 'gu', label: 'Gujarati' },
   { value: 'ha', label: 'Hausa' },
   { value: 'he', label: 'Hebrew' },
   { value: 'hi', label: 'Hindi' },
   { value: 'hu', label: 'Hungarian' },
   { value: 'id', label: 'Indonesian' },
   { value: 'ga', label: 'Irish' },
   { value: 'it', label: 'Italian' },
   { value: 'ja', label: 'Japanese' },
   { value: 'kn', label: 'Kannada' },
   { value: 'kk', label: 'Kazakh' },
   { value: 'rw_RW', label: 'Kinyarwanda' },
   { value: 'ko', label: 'Korean' },
   { value: 'ky_KG', label: 'Kyrgyz (Kyrgyzstan)' },
   { value: 'lo', label: 'Lao' },
   { value: 'lv', label: 'Latvian' },
   { value: 'lt', label: 'Lithuanian' },
   { value: 'mk', label: 'Macedonian' },
   { value: 'ms', label: 'Malay' },
   { value: 'ml', label: 'Malayalam' },
   { value: 'mr', label: 'Marathi' },
   { value: 'nb', label: 'Norwegian' },
   { value: 'fa', label: 'Persian' },
   { value: 'pl', label: 'Polish' },
   { value: 'pt_BR', label: 'Portuguese (BR)' },
   { value: 'pt_PT', label: 'Portuguese (POR)' },
   { value: 'pa', label: 'Punjabi' },
   { value: 'ro', label: 'Romanian' },
   { value: 'ru', label: 'Russian' },
   { value: 'sr', label: 'Serbian' },
   { value: 'sk', label: 'Slovak' },
   { value: 'sl', label: 'Slovenian' },
   { value: 'es', label: 'Spanish' },
   { value: 'es_AR', label: 'Spanish (ARG)' },
   { value: 'es_ES', label: 'Spanish (SPA)' },
   { value: 'es_MX', label: 'Spanish (MEX)' },
   { value: 'sw', label: 'Swahili' },
   { value: 'sv', label: 'Swedish' },
   { value: 'ta', label: 'Tamil' },
   { value: 'te', label: 'Telugu' },
   { value: 'th', label: 'Thai' },
   { value: 'tr', label: 'Turkish' },
   { value: 'uk', label: 'Ukrainian' },
   { value: 'ur', label: 'Urdu' },
   { value: 'uz', label: 'Uzbek' },
   { value: 'vi', label: 'Vietnamese' },
   { value: 'zu', label: 'Zulu' },
];

const LANGUAGES_EN_TR = [
   { value: 'af', english: 'Afrikaans', turkish: 'Afrikanca' },
   { value: 'sq', english: 'Albanian', turkish: 'Arnavutça' },
   { value: 'ar', english: 'Arabic', turkish: 'Arapça' },
   { value: 'hy', english: 'Armenian', turkish: 'Ermenice' },
   { value: 'az', english: 'Azerbaijani', turkish: 'Azerbaycanca' },
   { value: 'eu', english: 'Basque', turkish: 'Baskça' },
   { value: 'be', english: 'Belarusian', turkish: 'Beyaz Rusça' },
   { value: 'bn', english: 'Bengali', turkish: 'Bengalce' },
   { value: 'bs', english: 'Bosnian', turkish: 'Boşnakça' },
   { value: 'bg', english: 'Bulgarian', turkish: 'Bulgarca' },
   { value: 'ca', english: 'Catalan', turkish: 'Katalanca' },
   { value: 'ceb', english: 'Cebuano', turkish: 'Cebuano' },
   { value: 'ny', english: 'Chichewa', turkish: 'Chichewa' },
   { value: 'zh-cn', english: 'Chinese Simplified', turkish: 'Basitleştirilmiş Çince' },
   { value: 'zh-tw', english: 'Chinese Traditional', turkish: 'Geleneksel Çince' },
   { value: 'co', english: 'Corsican', turkish: 'Korsikaca' },
   { value: 'hr', english: 'Croatian', turkish: 'Hırvatça' },
   { value: 'cs', english: 'Czech', turkish: 'Çekçe' },
   { value: 'da', english: 'Danish', turkish: 'Danca' },
   { value: 'nl', english: 'Dutch', turkish: 'Felemenkçe' },
   { value: 'en', english: 'English', turkish: 'İngilizce' },
   { value: 'eo', english: 'Esperanto', turkish: 'Esperanto' },
   { value: 'et', english: 'Estonian', turkish: 'Estonca' },
   { value: 'tl', english: 'Filipino', turkish: 'Filipince' },
   { value: 'fi', english: 'Finnish', turkish: 'Fince' },
   { value: 'fr', english: 'French', turkish: 'Fransızca' },
   { value: 'fy', english: 'Frisian', turkish: 'Frisian' },
   { value: 'gl', english: 'Galician', turkish: 'Galiçyaca' },
   { value: 'ka', english: 'Georgian', turkish: 'Gürcüce' },
   { value: 'de', english: 'German', turkish: 'Almanca' },
   { value: 'el', english: 'Greek', turkish: 'Yunanca' },
   { value: 'gu', english: 'Gujarati', turkish: 'Gujarati' },
   { value: 'ht', english: 'Haitian Creole', turkish: 'Haiti Creole' },
   { value: 'ha', english: 'Hausa', turkish: 'Hausa' },
   { value: 'haw', english: 'Hawaiian', turkish: 'Hawaii Dili' },
   { value: 'iw', english: 'Hebrew', turkish: 'İbranice' },
   { value: 'hi', english: 'Hindi', turkish: 'Hintçe' },
   { value: 'hmn', english: 'Hmong', turkish: 'Hmongca' },
   { value: 'hu', english: 'Hungarian', turkish: 'Macarca' },
   { value: 'is', english: 'Icelandic', turkish: 'İzlandaca' },
   { value: 'ig', english: 'Igbo', turkish: 'İgbo' },
   { value: 'id', english: 'Indonesian', turkish: 'Endonezce' },
   { value: 'ga', english: 'Irish', turkish: 'İrlandaca' },
   { value: 'it', english: 'Italian', turkish: 'İtalyanca' },
   { value: 'ja', english: 'Japanese', turkish: 'Japonca' },
   { value: 'jw', english: 'Javanese', turkish: 'Cava Dili' },
   { value: 'kn', english: 'Kannada', turkish: 'Kannada' },
   { value: 'kk', english: 'Kazakh', turkish: 'Kazakça' },
   { value: 'km', english: 'Khmer', turkish: 'Khmerce' },
   { value: 'ko', english: 'Korean', turkish: 'Korece' },
   { value: 'ku', english: 'Kurdish (Kurmanji)', turkish: 'Kürtçe (Kurmanji)' },
   { value: 'ky', english: 'Kyrgyz', turkish: 'Kırgızca' },
   { value: 'lo', english: 'Lao', turkish: 'Lao Dili' },
   { value: 'la', english: 'Latin', turkish: 'Latince' },
   { value: 'lv', english: 'Latvian', turkish: 'Letonca' },
   { value: 'lt', english: 'Lithuanian', turkish: 'Litvanca' },
   { value: 'lb', english: 'Luxembourgish', turkish: 'Lüksemburgca' },
   { value: 'mk', english: 'Macedonian', turkish: 'Makedonca' },
   { value: 'mg', english: 'Malagasy', turkish: 'Malgaşça' },
   { value: 'ms', english: 'Malay', turkish: 'Malayca' },
   { value: 'ml', english: 'Malayalam', turkish: 'Malayalamca' },
   { value: 'mt', english: 'Maltese', turkish: 'Maltaca' },
   { value: 'mi', english: 'Maori', turkish: 'Maori' },
   { value: 'mr', english: 'Marathi', turkish: 'Marathi' },
   { value: 'mn', english: 'Mongolian', turkish: 'Moğolca' },
   { value: 'my', english: 'Myanmar (Burmese)', turkish: 'Myanmar (Burmaca)' },
   { value: 'ne', english: 'Nepali', turkish: 'Nepalce' },
   { value: 'no', english: 'Norwegian', turkish: 'Norveççe' },
   { value: 'ps', english: 'Pashto', turkish: 'Peştuca' },
   { value: 'fa', english: 'Persian', turkish: 'Farsça' },
   { value: 'pl', english: 'Polish', turkish: 'Lehçe' },
   { value: 'pt', english: 'Portuguese', turkish: 'Portekizce' },
   { value: 'ma', english: 'Punjabi', turkish: 'Pencapça' },
   { value: 'ro', english: 'Romanian', turkish: 'Romence' },
   { value: 'ru', english: 'Russian', turkish: 'Rusça' },
   { value: 'sm', english: 'Samoan', turkish: 'Samoa Dili' },
   { value: 'gd', english: 'Scots Gaelic', turkish: 'İskoç Gaelcesi' },
   { value: 'sr', english: 'Serbian', turkish: 'Sırpça' },
   { value: 'st', english: 'Sesotho', turkish: 'Sesotho' },
   { value: 'sn', english: 'Shona', turkish: 'Shona' },
   { value: 'sd', english: 'Sindhi', turkish: 'Sindhice' },
   { value: 'si', english: 'Sinhala', turkish: 'Sinhala' },
   { value: 'sk', english: 'Slovak', turkish: 'Slovakça' },
   { value: 'sl', english: 'Slovenian', turkish: 'Slovence' },
   { value: 'so', english: 'Somali', turkish: 'Somalice' },
   { value: 'es', english: 'Spanish', turkish: 'İspanyolca' },
   { value: 'su', english: 'Sudanese', turkish: 'Sudan Dili' },
   { value: 'sw', english: 'Swahili', turkish: 'Svahili' },
   { value: 'sv', english: 'Swedish', turkish: 'İsveççe' },
   { value: 'tg', english: 'Tajik', turkish: 'Tacikçe' },
   { value: 'ta', english: 'Tamil', turkish: 'Tamilce' },
   { value: 'te', english: 'Telugu', turkish: 'Telugu' },
   { value: 'th', english: 'Thai', turkish: 'Tayca' },
   { value: 'tr', english: 'Turkish', turkish: 'Türkçe' },
   { value: 'uk', english: 'Ukrainian', turkish: 'Ukraynaca' },
   { value: 'ur', english: 'Urdu', turkish: 'Urduca' },
   { value: 'uz', english: 'Uzbek', turkish: 'Özbekçe' },
   { value: 'vi', english: 'Vietnamese', turkish: 'Vietnamca' },
   { value: 'cy', english: 'Welsh', turkish: 'Galce' },
   { value: 'xh', english: 'Xhosa', turkish: 'Xhosa' },
   { value: 'yi', english: 'Yiddish', turkish: 'Yidiş' },
   { value: 'yo', english: 'Yoruba', turkish: 'Yoruba' },
   { value: 'zu', english: 'Zulu', turkish: 'Zuluca' },
];

module.exports = {
   LANGUAGES,
   LangCodes,
   LANGUAGES_EN_TR,
};

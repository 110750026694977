import React, { useState, useCallback, useEffect } from 'react';
import { BaseEdge, EdgeLabelRenderer, EdgeProps, Node, getBezierPath, useReactFlow } from 'reactflow';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { useMutation } from '@apollo/client';
import { UPDATE_ASK_QUESTION_BLOCK_MUTATION, UPDATE_BLOCK_MUTATION } from '@queries/Automation/mutation';
import {
   FlowBlockType,
   FlowNodeType,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { RootStateOrAny, useSelector } from 'react-redux';

const useStyles = makeStyles((theme: any) => ({
   deleteIcon: {
      fontSize: '12px',
      color: 'red',
      backgroundColor: '#fff',
      marginBottom: '5px',
      cursor: 'pointer',
   },
}));

export default function CustomEdge({
   id,
   sourceX,
   sourceY,
   targetX,
   targetY,
   sourcePosition,
   targetPosition,
   style = {},
   markerEnd,
   data,
}: EdgeProps) {
   const [edgePath, labelX, labelY] = getBezierPath({
      sourceX,
      sourceY,
      sourcePosition,
      targetX,
      targetY,
      targetPosition,
   });
   const classes = useStyles();
   const { color, setLeftNodes, setContentNodes, setClickedNodeId } = data;
   const reactFlow = useReactFlow();

   const [blocks, setBlocks] = useState<IFlowBlockTemplate[]>([]);
   const [isUpdateQR, setIsUpdateQR] = useState(false);
   const [blockIdToUpdate, setBlockIdToUpdate] = useState<string>();
   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);

   const [isHover, setIsHover] = useState(false);

   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);

   const handleUpdateFlowBlock = async (blockId: string) => {
      try {
         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  target: null,
               },
            },
            onCompleted: (res) => {
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, target: null };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev: Node[]) => updateNodes(prev, blockId));
               setLeftNodes((prev: Node[]) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const handleUpdateFlowBlockQuickReply = async (blockId: string, quickReplyIndex: number) => {
      setBlocks((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === blockId) {
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.quickReplies && field.quickReplies[quickReplyIndex]) {
                        return {
                           ...field,
                           quickReplies: field.quickReplies.map((quickReply, index) => {
                              if (index === quickReplyIndex) {
                                 return {
                                    ...quickReply,
                                    target: null,
                                 };
                              }
                              return quickReply;
                           }),
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };

   const handleUpdateFlowBlockInfBtn = async (blockId: string, infBtnIndex: number) => {
      setBlocks((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === blockId) {
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.informativeButtons && field.informativeButtons[infBtnIndex]) {
                        return {
                           ...field,
                           informativeButtons: field.informativeButtons.map((infBtn, index) => {
                              if (index === infBtnIndex) {
                                 return {
                                    ...infBtn,
                                    target: null,
                                 };
                              }
                              return infBtn;
                           }),
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };

   const [updateBlockAskQuestion] = useMutation(UPDATE_ASK_QUESTION_BLOCK_MUTATION);

   const handleUpdateFlowBlockAskQuestion = async (blockId: string, handleToUpdate: string) => {
      try {
         let input = {};

         if (handleToUpdate === '1') {
            input = {
               _id: blockId,
               fieldIndex: 0,
               fieldName: 'target_on_reply',
               fieldValue: null,
            };
         } else if (handleToUpdate === '2') {
            input = {
               _id: blockId,
               fieldIndex: 0,
               fieldName: 'target_on_not_reply',
               fieldValue: null,
            };
         } else if (handleToUpdate === '3') {
            input = {
               _id: blockId,
               fieldIndex: 0,
               fieldName: 'target_on_invalid_input',
               fieldValue: null,
            };
         }

         await updateBlockAskQuestion({
            variables: {
               input: input,
            },
            onCompleted: (res) => {
               const response = res.updateBlockField.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev: Node[]) => updateNodes(prev, blockId));
               setLeftNodes((prev: Node[]) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const handleUpdateFlowBlockHttpRequest = async (blockId: string, handleToUpdate: string) => {
      try {
         const foundField = nodes.find((node) =>
            node.data?.flowBlocks?.some((block: IFlowBlockTemplate) => block._id === blockId),
         )?.data.flowBlocks[0]?.fields[0];

         let fields = [];
         if (handleToUpdate === '1') {
            fields = [
               {
                  targetOnSuccess: null,
                  targetOnFailure: foundField.targetOnFailure,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         } else {
            fields = [
               {
                  targetOnFailure: null,
                  targetOnSuccess: foundField.targetOnSuccess,
                  type: IFlowBlockFieldTypes.HttpRequest,
                  requestBody: foundField.requestBody,
                  requestBodyFormDataUrlEncoded: foundField.requestBodyFormDataUrlEncoded,
                  requestBodyFormData: foundField.requestBodyFormData,
                  requestHeaders: foundField.requestHeaders,
                  responseDotNotation: foundField.responseDotNotation,
                  requestMethod: foundField.requestMethod,
                  requestUrl: foundField.requestUrl,
               },
            ];
         }

         await updateBlock({
            variables: {
               input: {
                  _id: blockId,
                  fields: fields,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlock.data;
               const updateNodes = (prevNodes: Node[], blockId: string) =>
                  prevNodes.map((node) => ({
                     ...node,
                     data: {
                        ...node.data,
                        flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                           if (block._id === blockId) {
                              return { ...block, fields: response?.fields };
                           }
                           return block;
                        }),
                     },
                  }));

               setContentNodes((prev: Node[]) => updateNodes(prev, blockId));
               setLeftNodes((prev: Node[]) => updateNodes(prev, blockId));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   useEffect(() => {
      if (isUpdateQR && blockIdToUpdate) {
         const blockFields = blocks?.find((block) => block._id === blockIdToUpdate)?.fields;
         if (blockFields) {
            try {
               updateBlock({
                  variables: {
                     input: {
                        _id: blockIdToUpdate,
                        fields: blockFields.map((field: any) => {
                           for (let [key, value] of Object.entries(field)) {
                              if (value == null) {
                                 delete field[key];
                              }
                           }
                           return field;
                        }),
                     },
                  },
                  onCompleted: (res) => {
                     const response = res.updateBlock.data;
                     const updateNodes = (prevNodes: Node[], blockId: string) =>
                        prevNodes.map((node) => ({
                           ...node,
                           data: {
                              ...node.data,
                              flowBlocks: node.data?.flowBlocks?.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        }));

                     setContentNodes((prev: Node[]) => updateNodes(prev, blockIdToUpdate));
                     setLeftNodes((prev: Node[]) => updateNodes(prev, blockIdToUpdate));
                  },
               });
            } catch (error) {
               console.error('Error updating block:', error);
            }
         }
      }
   }, [isUpdateQR, blocks]);

   const nodes = reactFlow.getNodes();
   const currentEdge = reactFlow.getEdge(id);
   const targetNode = nodes.find((node) => node.id === currentEdge?.target);
   const sourceNode = nodes.find((node) => node.id === currentEdge?.source);

   // Handle edge click event
   const onEdgeClick = useCallback(
      async (evt: { stopPropagation: () => void }, id: string) => {
         evt.stopPropagation();
         const edgeToDelete = reactFlow.getEdge(id);

         if (edgeToDelete) {
            const blockId = edgeToDelete.sourceHandle;
            if (blockId) {
               const source = edgeToDelete.source;
               const sourceHan = edgeToDelete.sourceHandle;

               const block = nodes.find((node) => node.id === source)?.data?.flowBlocks;
               setBlocks(block);

               let isQR = null;
               let isInfBtn = null;

               if (sourceHan) {
                  isQR = block.find(
                     (block: { _id: string; type: string }) =>
                        block._id === sourceHan.slice(0, -1) && block.type === FlowBlockType.SendQuickReplyMessage,
                  );
                  isInfBtn = block.find(
                     (block: { _id: string; type: string }) =>
                        block._id === sourceHan.slice(0, -1) &&
                        block.type === FlowBlockType.SendInformativeButtonMessage,
                  );
               }

               if (source) {
                  const sourceNodeType = nodes.find((node) => node.id === source)?.type;
                  if (sourceNodeType === FlowNodeType.AskQuestion && sourceHan) {
                     const lastChar = sourceHan.charAt(sourceHan.length - 1);
                     handleUpdateFlowBlockAskQuestion(blockId.slice(0, -1), lastChar);
                  } else if (sourceNodeType === FlowNodeType.HttpRequest && sourceHan) {
                     const lastChar = sourceHan.charAt(sourceHan.length - 1);
                     handleUpdateFlowBlockHttpRequest(blockId.slice(0, -1), lastChar);
                  } else if (
                     blocks &&
                     (sourceNodeType === FlowNodeType.SendButtonMessage ||
                        sourceNodeType === FlowNodeType.WelcomeMessage) &&
                     sourceHan &&
                     isQR
                  ) {
                     const lastChar = sourceHan.charAt(sourceHan.length - 1);
                     await handleUpdateFlowBlockQuickReply(blockId.slice(0, -1), parseInt(lastChar, 10));

                     setBlockIdToUpdate(blockId.slice(0, -1));
                     setIsUpdateQR(true);
                  } else if (blocks && sourceNodeType === FlowNodeType.SendButtonMessage && sourceHan && isInfBtn) {
                     const lastChar = sourceHan.charAt(sourceHan.length - 1);
                     await handleUpdateFlowBlockInfBtn(blockId.slice(0, -1), parseInt(lastChar, 10));

                     setBlockIdToUpdate(blockId.slice(0, -1));
                     setIsUpdateQR(true);
                  } else {
                     handleUpdateFlowBlock(blockId);
                  }
               }
            }
         }
      },
      [reactFlow],
   );

   const edgeColor = isHover ? '#157cfc' : color;

   const { setCenter } = useReactFlow();
   const focusNode = (targetNode: Node<any> | undefined) => {
      if (targetNode && botStatus === 'draft') {
         setClickedNodeId(targetNode.id);
         const node = targetNode;
         const parentNode = nodes.find((nd) => nd.id === node.parentNode);
         const position = {
            x: parentNode ? node.position.x + (parentNode?.positionAbsolute?.x ?? 0) : node.position.x,
            y: parentNode ? node.position.y + (parentNode?.positionAbsolute?.y ?? 0) : node.position.y,
         };
         if (node.width && node.height) {
            const x = position.x + node.width / 2;
            const y = position.y + node.height / 2;
            const zoom = 2;

            setCenter(x, y, { zoom, duration: 1000 });
         }
      }
   };

   return (
      <g
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => setIsHover(false)}
         style={{ position: 'relative', zIndex: isHover ? 99999 : 'auto' }}
         onDoubleClick={() => focusNode(targetNode)}
      >
         <BaseEdge path={edgePath} markerEnd={markerEnd} style={{ ...style, stroke: edgeColor }} />
         <EdgeLabelRenderer>
            <div
               style={{
                  position: 'absolute',
                  transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
                  fontSize: 12,
                  pointerEvents: 'all',
               }}
               className='nodrag nopan'
            >
               {isHover &&
                  !(targetNode?.type === FlowNodeType.WelcomeMessage && sourceNode?.type === FlowNodeType.Triggers) &&
                  botStatus === 'draft' && (
                     <DeleteIcon className={classes.deleteIcon} onClick={(event) => onEdgeClick(event, id)} />
                  )}
            </div>
         </EdgeLabelRenderer>
      </g>
   );
}

import { useCallback } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import {
  JOIN_CHAT_MUTATION,
  LEAVE_CHAT_MUTATION,
  UPDATE_CHAT_STATUS_MUTATION,
  CHANGE_CHAT_TAG_MUTATION,
  TRANSFER_CHAT_MUTATION,
  SIGN_AS_ARCHIVE_CHAT_MUTATION,
} from '@queries/Chat/Service/GraphQL/Chat/mutation';
import { joinChatMutation, joinChatMutationVariables } from '@queries/Chat/Service/GraphQL/Chat/__generated__/joinChatMutation';
// import { getActiveChatQuery_getActiveChat_data } from '@queries/Chat/Service/GraphQL/Chat/__generated__/getActiveChatQuery';
import { leaveChatMutation, leaveChatMutationVariables } from '@queries/Chat/Service/GraphQL/Chat/__generated__/leaveChatMutation';
import {
  updateChatStatusMutation,
  updateChatStatusMutationVariables,
} from '@queries/Chat/Service/GraphQL/Chat/__generated__/updateChatStatusMutation';
import {
  changeChatTagMutation,
  changeChatTagMutationVariables,
} from '@queries/Chat/Service/GraphQL/Chat/__generated__/changeChatTagMutation';
import { transferChatMutation, transferChatMutationVariables } from '@queries/Chat/Service/GraphQL/Chat/__generated__/transferChatMutation';
import {
  signAsArchiveChatMutation,
  signAsArchiveChatMutationVariables,
} from '@queries/Chat/Service/GraphQL/Chat/__generated__/signAsArchiveChatMutation';
import {useDispatch} from "react-redux";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import {getPerson} from "@store/reducers/person";
import {getActiveChat} from "@store/reducers/chat";
import {useHistory} from "react-router-dom";

const useChatMutations = () => {
  const person = useSelector(getPerson);
  const activeChat = useSelector(getActiveChat);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleError = useCallback((error: ApolloError) => {
    enqueueSnackbar(error.message, {
      variant: 'error',
      autoHideDuration: 2500,
    });
  }, [enqueueSnackbar]);

  const [joinChatMut, { loading: joinChatLoading }] = useMutation<joinChatMutation, joinChatMutationVariables>(
    JOIN_CHAT_MUTATION,
  );

  const [leaveChatMut, { loading: leaveChatLoading }] = useMutation<leaveChatMutation, leaveChatMutationVariables>(
    LEAVE_CHAT_MUTATION,
  );

  const [updateChatStatusMut, { loading: updateChatStatusLoading }] = useMutation<
    updateChatStatusMutation,
    updateChatStatusMutationVariables
    >(UPDATE_CHAT_STATUS_MUTATION);

  const [changeChatTagMut, { loading: changeChatTagLoading }] = useMutation<
    changeChatTagMutation,
    changeChatTagMutationVariables
    >(CHANGE_CHAT_TAG_MUTATION);

  const [transferChatMut, { loading: chatTransferLoading }] = useMutation<
    transferChatMutation,
    transferChatMutationVariables
    >(TRANSFER_CHAT_MUTATION);

  const [signAsArchiveChatMut, { loading: signAsArchiveLoading }] = useMutation<
    signAsArchiveChatMutation,
    signAsArchiveChatMutationVariables
    >(SIGN_AS_ARCHIVE_CHAT_MUTATION);

  const joinChat = useCallback(() => {
    if (person._id && activeChat?._id) {
      joinChatMut({
        variables: {
          person: person._id,
          _id: activeChat?._id,
        },
      })
        .then((res) => {
          // const data = res.data?.joinChat.data;
          // if (activeChat && activeChat._id === data?._id && data) {
          //   const newActiveChat: getActiveChatQuery_getActiveChat_data = {
          //     ...activeChat,
          //     ...data,
          //   };
          //   dispatch(setActiveChat(newActiveChat));
          // }
        })
        .catch(handleError);
    }
  }, [activeChat, dispatch, handleError, joinChatMut, person._id]);

  const leaveChat = useCallback(() => {
    if (person._id && activeChat?._id) {
        let person_id = person.type === 1 || person.type === 2 ? activeChat.participants[1]._id : person._id;
        leaveChatMut({
           variables: {
              person: person_id,
              _id: activeChat._id,
           },
        })
        .then((res) => {
          // goToChatListScreen();
          // const data = res.data?.leaveChat.data;
          // if (activeChat && activeChat._id?.toString() === data?._id?.toString()) {
          //   const newActiveChat: getActiveChatQuery_getActiveChat_data = {
          //     ...activeChat,
          //     ...data,
          //   };
          //   dispatch(setActiveChat(newActiveChat));
          // }
          history.replace('/chat');
        })
        .catch(handleError);
    }
  }, [activeChat, dispatch, handleError, leaveChatMut, person._id]);

  const updateChat = useCallback(
    (chatID: string, newStatus: number) => {
      if (chatID && newStatus && person._id) {
        updateChatStatusMut({
          variables: {
            _id: chatID,
            status: newStatus,
            person: person._id,
          },
        })
          .then((res) => {
            // goToChatListScreen();
            // const data = res.data?.updateChatStatus.data;
            // if (activeChat?._id === data?._id) {
            //   // @ts-ignore
            //   const newActiveChat: getActiveChatQuery_getActiveChat_data = {
            //     ...activeChat,
            //     ...data,
            //   };
            //   if (newActiveChat) {
            //     dispatch(setActiveChat(newActiveChat));
            //   }
            // }
            history.push('/chat');
          })
          .catch(handleError);
      }
    },
    [activeChat, dispatch, handleError, person._id, updateChatStatusMut],
  );

  const changeChatTag = useCallback(
    (chatID: string, selectedTagID: string | null | undefined, personID: string) => {
      if (chatID) {
        changeChatTagMut({
          variables: {
            _id: chatID,
            tag: selectedTagID,
            person: personID,
          },
        })
          .then((res) => {
            // const data = res.data?.changeChatTag.data;
            // if (activeChat?._id === chatID) {
            //   const newActiveChat: getActiveChatQuery_getActiveChat_data = {
            //     ...activeChat,
            //     ...data,
            //   };
            //   dispatch(setActiveChat(newActiveChat));
            // }
          })
          .catch(handleError);
      }
    },
    [activeChat, changeChatTagMut, dispatch, handleError],
  );

  const transferChat = useCallback(
    (chatID: string, selectedTeammateId: string | null) => {
      if (chatID && selectedTeammateId) {
        transferChatMut({
          variables: {
            _id: chatID,
            from: person._id,
            to: selectedTeammateId,
          },
        })
          .then(() => {
            // goToChatListScreen();
            // dispatch(resetActiveChat());
            // dispatch(removeChatFromMyChatList({ chatID }));
            history.replace('/chat');
          })
          .catch(handleError);
      }
    },
    [dispatch, handleError, person._id, transferChatMut],
  );

  const signAsArchiveChat = useCallback(
    (chatID: string, personID: string) => {
      signAsArchiveChatMut({
        variables: {
          _id: chatID,
          person: personID,
        },
      })
        .then(() => {
          // goToChatListScreen();
          // dispatch(resetActiveChat());
          history.replace('/chat');
        })
        .catch(handleError);
    },
    [dispatch, handleError, signAsArchiveChatMut],
  );

  return {
    joinChatLoading,
    loading:
      leaveChatLoading ||
      updateChatStatusLoading ||
      changeChatTagLoading ||
      chatTransferLoading ||
      signAsArchiveLoading,
    joinChat,
    leaveChat,
    updateChat,
    changeChatTag,
    transferChat,
    signAsArchiveChat,
  };
};

export default useChatMutations;

import React, { useContext, useEffect, useState } from 'react';
import { Grid, Typography, Button, Box, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Success from './assets/Success';
import Error from './assets/Error';
import { useHistory, useLocation } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';
import { useSelector, useDispatch } from 'react-redux';
import { UPDATE_ME } from '@store/actions/person';
import { useMutation } from '@apollo/client';
import { PAYMENT_FROM_OTHER_CHANNEL } from '@queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import usePreviousState from '@hooks/usePreviousState';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
   main: {
      borderRadius: '8px',
      width: '100%',
      background: '#FFFFFF',
      boxShadow: '0px 1px 3px #00000029',
      maxHeight: '600px',
      minHeight: '400px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '4rem',
   },
   titlesContainer: {
      padding: '2.2rem 0px',
      textAlign: 'center',
   },
   titleText: {
      fontWeight: 'bold',
      fontSize: '1.4rem',
      color: '#315276',
      paddingBottom: '.8rem',
   },
   subTitleText: {
      fontSize: '1.2rem',
      color: '#315276',
   },
   btn: {
      color: 'white',
      background: '#178CF9 0% 0% no-repeat padding-box',
      borderRadius: '8px',
      padding: '.7rem 3rem',
      marginBottom: '1rem',
   },
   btnLink: {
      color: 'white',
      backgroundColor: 'rgb(90,188,8)',
      borderRadius: '8px',
      padding: '.7rem 3rem',
      marginBottom: '2rem',
      transition: 'all 200ms',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
         textDecoration: 'none',
         backgroundColor: 'rgb(73,149,9)',
         color: 'white',
      },
   },
   btnLinkDisabled: {
      cursor: 'not-allowed',
      textDecoration: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      color: 'rgba(0, 0, 0, 0.26)',
      '&:hover': {
         cursor: 'not-allowed',
         textDecoration: 'none',
         backgroundColor: 'rgba(0, 0, 0, 0.12)',
         color: 'rgba(0, 0, 0, 0.26)',
      },
   },
}));

function useSearchQuery() {
   const { search } = useLocation();

   return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Result = ({
   setPaymentSuccess,
   paymentSuccess,
   prevPageHandler,
   errorMessage,
   purchasedProduct,
   isFreeTrial,
}) => {
   const history = useHistory();
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const dispatch = useDispatch();
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const t = lang?.translation;
   const confirmationUrl = person?.customer?.package?.paymentFromOtherChannel?.confirmationUrl;
   const [mutationVariables, setMutationVariables] = useState(undefined);
   const previousMutationVariables = usePreviousState(mutationVariables);
   const [mutationWasRunSuccessfully, setMutationWasRunSuccessfully] = useState(!confirmationUrl);
   const isPaymentSuccess = !!localStorage.getItem('paymentSuccess') || !!paymentSuccess;
   const isFreeTrialSuccess = isPaymentSuccess && (!!localStorage.getItem('isFreeTrial') || !!isFreeTrial);

   let query = useSearchQuery();
   const customerId = query.get('customerId');
   const isYearly = query.get('isYearly');
   const charge_id = query.get('charge_id');
   const accessToken = query.get('accessToken');
   const partnerDomain = query.get('partnerDomain');

   useEffect(() => {
      if (customerId && isYearly && accessToken && partnerDomain) {
         setMutationVariables((prevState) =>
            prevState === undefined
               ? {
                    customerId,
                    isYearly,
                    chargeId: charge_id,
                    accessToken,
                    partnerDomain,
                 }
               : prevState,
         );
      }
   }, [customerId, isYearly, charge_id, accessToken, partnerDomain]);

   const [paymentFromOtherChannelMutation, { loading }] = useMutation(PAYMENT_FROM_OTHER_CHANNEL);

   useEffect(() => {
      if (previousMutationVariables === undefined && !!mutationVariables) {
         paymentFromOtherChannelMutation({
            variables: {
               data: {
                  type: 'SHOPIFY',
                  ...mutationVariables,
               },
            },
         })
            .then(({ data }) => {
               if (!charge_id) {
                  dispatch({
                     type: 'UPDATE_CONFIRMATION_URL',
                     payload: data.paymentFromOtherChannel.data.paymentFromOtherChannel.confirmationUrl,
                  });
                  setMutationWasRunSuccessfully(false);
               } else {
                  setPaymentSuccess({
                     isSuccess: true,
                     purchasedProduct: true,
                     paymentSuccess: true,
                  });
                  setMutationWasRunSuccessfully(true);
               }
            })
            .catch(() => {
               setMutationWasRunSuccessfully(false);
            });
      }
   }, [previousMutationVariables, mutationVariables, paymentFromOtherChannelMutation]);

   const title =
      isFreeTrialSuccess || charge_id
         ? t?.welcome_result_account_ready_to_use
         : isPaymentSuccess
         ? t?.payment_successful
         : t?.payment_failed;
   const subTitle =
      confirmationUrl || charge_id
         ? t?.welcome_result_subtitle_for_shopify
         : isFreeTrialSuccess
         ? t?.welcome_result_lets_start
         : isPaymentSuccess
         ? t?.payment_success_full
         : errorMessage
         ? errorMessage
         : t?.payment_failed_full;
   const icon =
      isFreeTrialSuccess || charge_id ? (
         <span role='img' style={{ fontSize: '5rem' }}>
            {' '}
            🥳{' '}
         </span>
      ) : isPaymentSuccess ? (
         <Success />
      ) : (
         <Error />
      );
   const btnText = isPaymentSuccess ? t?.lets_start : t?.try_again;

   useEffect(() => {
      if (paymentSuccess) {
         localStorage.setItem('paymentSuccess', paymentSuccess);
         localStorage.setItem('isFreeTrial', JSON.stringify(isFreeTrial));
      }
   }, [paymentSuccess, isFreeTrial]);

   const handleLinkPress = (e) => {
      if (mutationWasRunSuccessfully || loading) {
         e.preventDefault();
      }
   };

   return (
      <Grid className={classes.main} container>
         {icon}
         <Box className={classes.titlesContainer}>
            <Typography className={classes.titleText}> {title} </Typography>
            <Typography>
               {' '}
               <span> {subTitle} </span>{' '}
            </Typography>
         </Box>
         {!!confirmationUrl && (
            <Link
               href={confirmationUrl}
               onClick={handleLinkPress}
               className={cn(classes.btnLink, mutationWasRunSuccessfully && classes.btnLinkDisabled)}
            >
               {t?.approve_from_shopify}
            </Link>
         )}

         <Button
            disabled={!mutationWasRunSuccessfully}
            onClick={() => {
               if (isPaymentSuccess) {
                  dispatch({
                     type: UPDATE_ME,
                     payload: {
                        person: {
                           ...person,
                           customer: {
                              ...person.customer,
                              isANewCustomer: false,
                           },
                        },
                     },
                  });
                  dispatch({
                     type: 'UPDATE_CONFIRMATION_URL',
                     payload: null,
                  });
                  window.localStorage.setItem('isWelcome', true);
                  const getshopifySession = localStorage.getItem('shopifySession');
                  const getIdeasoftState = localStorage.getItem('ideasoftState');
                  const getIdeasoftCode = localStorage.getItem('ideasoftCode');
                  const getIdeasoftDomain = localStorage.getItem('ideasoftDomain');
                  if (getshopifySession) {
                     history.replace('/authentication/shopify?session=' + getshopifySession);
                  } else if (getIdeasoftState) {
                     history.replace(
                        `/authentication/ideasoft?state=${getIdeasoftState}&code=${getIdeasoftCode}&domain=${getIdeasoftDomain}`,
                     );
                  } else {
                     history.replace('/');
                  }
               } else {
                  prevPageHandler();
               }
            }}
            variant='contained'
            color='primary'
            className={classes.btn}
         >
            {btnText}
         </Button>
      </Grid>
   );
};

export default Result;

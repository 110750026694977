import { useLazyQuery, useMutation } from '@apollo/client';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import DoubleInput from '@components/_Modal/DoubleInput';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';
import { LanguageContext } from '@helper/locale/langContext';
import useTranslateLanguage from '@hooks/useTranslateLanguage';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import { LOCATIONS } from '@queries/Locations/query';
import {
   CREATE_PERSON_GROUP_MUTATION,
   REMOVE_PERSON_GROUP_MUTATION,
   UPDATE_PERSON_GROUP_MUTATION,
} from '@queries/PersonGroups/Service/GraphQL/mutation';
import { GET_CHAT_TAGS_QUERY } from '@queries/Settings/Service/GraphQL/ChatTags/query';
import { GET_CUSTOM_FIELDS } from '@queries/Settings/Service/GraphQL/CustomFields/query';
import { getPerson } from '@store/reducers/person';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { PLATFORM_TYPE, USERS_TYPE } from '../../Chat/types';

const useStyles = makeStyles((theme) => ({
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
      '&:hover': {
         backgroundColor: '#e0e0e0',
      },
   },
}));

const DynamicGroupModal = ({ setDynamicGroup, showCreateManualAccount, showDynamicGroup }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector(getPerson);
   const { enqueueSnackbar } = useSnackbar();
   const { data: languagesData, loading: languagesLoading } = useTranslateLanguage(lang.shortTitle);

   const FILTER_TYPES = {
      NAME: {
         camelCase: 'name',
         upperCase: 'NAME',
      },
      SURNAME: {
         camelCase: 'surname',
         upperCase: 'SURNAME',
      },
      TEXT: {
         camelCase: 'text',
         upperCase: 'TEXT',
      },
      NUMBER: {
         camelCase: 'number',
         upperCase: 'NUMBER',
      },
      EMAIL: {
         camelCase: 'email',
         upperCase: 'EMAIL',
      },
      PHONE: {
         camelCase: 'phone',
         upperCase: 'PHONE',
      },
      TYPE: {
         camelCase: 'type',
         upperCase: 'TYPE',
      },
      LIST: {
         camelCase: 'list',
         upperCase: 'LIST',
      },
      DATE: {
         camelCase: 'date',
         upperCase: 'DATE',
      },
      URL: {
         camelCase: 'url',
         upperCase: 'URL',
      },
      PHONE_PERMISSION: {
         camelCase: 'phoneUsagePermission',
         upperCase: 'PHONE_USAGE_PERMISSION',
      },
      EMAIL_PERMISSION: {
         camelCase: 'emailUsagePermission',
         upperCase: 'EMAIL_USAGE_PERMISSION',
      },
      COUNTRY: {
         camelCase: 'country',
         upperCase: 'COUNTRY',
      },
      STATE: {
         camelCase: 'state',
         upperCase: 'STATE',
      },
      LANGUAGE: {
         camelCase: 'language',
         upperCase: 'LANGUAGE',
      },
      TAGS: {
         camelCase: 'tags',
         upperCase: 'TAGS',
      },
   };

   const platformTypes = [
      {
         value: USERS_TYPE.WHATS_APP_WEB,
         label: 'WhatsApp Web',
         key: PLATFORM_TYPE.WHATS_APP_WEB,
      },
      {
         value: USERS_TYPE.WHATS_APP_BUSINESS,
         label: 'Whatsapp Business',
         key: PLATFORM_TYPE.WHATS_APP_BUSINESS,
      },
      {
         value: USERS_TYPE.FACEBOOK,
         label: 'Facebook Messenger',
         key: PLATFORM_TYPE.FACEBOOK,
      },
      {
         value: USERS_TYPE.INSTAGRAM,
         label: 'Instagram',
         key: PLATFORM_TYPE.INSTAGRAM,
      },

      {
         value: USERS_TYPE.LIVE_CHAT,
         label: 'Live Chat',
         key: PLATFORM_TYPE.LIVE_CHAT,
      },
      {
         value: USERS_TYPE.TRENDYOL,
         label: 'Trendyol',
         key: PLATFORM_TYPE.TRENDYOL,
      },

      {
         value: USERS_TYPE.HEPSIBURADA,
         label: 'Hepsiburada',
         key: PLATFORM_TYPE.HEPSIBURADA,
      },
      {
         value: USERS_TYPE.TELEGRAM,
         label: 'Telegram',
         key: PLATFORM_TYPE.TELEGRAM,
      },
      {
         value: USERS_TYPE.EMAIL,
         label: 'Email',
         key: PLATFORM_TYPE.EMAIL,
      },
   ];

   const typeEnumRules = {
      EQUAL: {
         label: t['page_contacts_typo_items_equal'],
         value: 'EQUAL',
      },

      CONTAINS: {
         label: t['page_contacts_typo_items_contains'],
         value: 'CONTAINS',
      },
      ALL_MATCH: {
         label: t['page_contacts_typo_all_match_conditions'],
         value: 'ALL_MATCH',
      },
      LEAST_ONE_MATCH: {
         label: t['page_contacts_typo_all_least_one_match_conditions'],
         value: 'LEAST_ONE_MATCH',
      },
   };

   const isValidDynamicPersonFilter = (type) => {
      switch (type) {
         case FILTER_TYPES.EMAIL.upperCase:
            return {
               required: t['required_field'],
            };
         case FILTER_TYPES.TYPE.upperCase:
            return {
               required: t['required_field'],
            };
         case FILTER_TYPES.NUMBER.upperCase:
            return {
               pattern: {
                  value: /^\d+$/,
                  message: t['page_contacts_typo_custom_field_invalid_number'],
               },
               required: t['required_field'],
            };
         case FILTER_TYPES.PHONE.upperCase:
            return {
               required: t['required_field'],
            };
         case FILTER_TYPES.URL.upperCase:
            return {
               required: t['required_field'],
            };
         case FILTER_TYPES.DATE.upperCase:
            return {
               pattern: {
                  value:
                     lang.shortTitle === 'TR'
                        ? /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
                        : /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                  message: t['page_contacts_typo_custom_field_invalid_date'],
               },
               required: t['required_field'],
            };
         case FILTER_TYPES.TEXT.upperCase:
            return {
               required: t['required_field'],
               maxLength: {
                  value: 30,
                  message: t['page_contacts_typo_group_text_max_length'],
               },
               minLength: {
                  value: 2,
                  message: t['page_contacts_typo_group_text_min_length'],
               },
            };
         case FILTER_TYPES.LIST.upperCase:
            return {
               required: t['required_field'],
            };
         case FILTER_TYPES.EMAIL_PERMISSION.upperCase:
            return {
               required: t['required_field'],
            };
         case FILTER_TYPES.PHONE_PERMISSION.upperCase:
            return {
               required: t['required_field'],
            };
         case FILTER_TYPES.COUNTRY.upperCase:
            return {
               required: t['required_field'],
            };
         case FILTER_TYPES.STATE.upperCase:
            return {
               required: t['required_field'],
            };
         case FILTER_TYPES.LANGUAGE.upperCase:
            return {
               required: t['required_field'],
            };
         case FILTER_TYPES.TAGS.upperCase:
            return {
               required: t['required_field'],
            };
         default:
            return;
      }
   };

   const defaultPersonFilter = [
      {
         label: t?.page_contacts_typo_first_name,
         key: FILTER_TYPES.NAME.camelCase,
         isSelect: false,
         type: FILTER_TYPES.TEXT.upperCase,
      },
      {
         label: t?.page_contacts_typo_last_name,
         key: FILTER_TYPES.SURNAME.camelCase,
         isSelect: false,
         type: FILTER_TYPES.TEXT.upperCase,
      },
      {
         label: t?.page_contacts_typo_email_address,
         key: FILTER_TYPES.EMAIL.camelCase,
         isSelect: false,
         type: FILTER_TYPES.EMAIL.upperCase,
      },
      {
         label: t?.page_contacts_typo_phone_number,
         key: FILTER_TYPES.PHONE.camelCase,
         isSelect: false,
         type: FILTER_TYPES.PHONE.upperCase,
      },
      {
         label: t?.page_contacts_typo_platform_type,
         key: FILTER_TYPES.TYPE.camelCase,
         isSelect: false,
         type: FILTER_TYPES.TYPE.upperCase,
      },
      {
         label: t?.page_contacts_typo_phone_permission,
         key: FILTER_TYPES.PHONE_PERMISSION.camelCase,
         isSelect: false,
         type: FILTER_TYPES.PHONE_PERMISSION.upperCase,
      },
      {
         label: t?.page_contacts_typo_email_permission,
         key: FILTER_TYPES.EMAIL_PERMISSION.camelCase,
         isSelect: false,
         type: FILTER_TYPES.EMAIL_PERMISSION.upperCase,
      },
      {
         label: t?.page_contacts_typo_country,
         key: FILTER_TYPES.COUNTRY.camelCase,
         isSelect: false,
         type: FILTER_TYPES.COUNTRY.upperCase,
      },
      {
         label: t?.page_contacts_typo_state,
         key: FILTER_TYPES.STATE.camelCase,
         isSelect: false,
         type: FILTER_TYPES.STATE.upperCase,
      },
      {
         label: t?.page_contacts_typo_language,
         key: FILTER_TYPES.LANGUAGE.camelCase,
         isSelect: false,
         type: FILTER_TYPES.LANGUAGE.upperCase,
      },
      {
         label: t?.page_contacts_typo_tag,
         key: FILTER_TYPES.TAGS.camelCase,
         isSelect: false,
         type: FILTER_TYPES.TAGS.upperCase,
      },
   ];

   const [isAddFilter, setIsAddFilter] = useState(null);
   const [filterItems, setFilterItems] = useState([]);
   const [isDeleting, setIsDeleting] = useState(false);
   const [personFilter, setPersonFilter] = useState(defaultPersonFilter);

   const {
      control,
      handleSubmit,
      watch,
      setValue,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         isAllConditionMatch: {
            label: typeEnumRules.ALL_MATCH.label,
            value: typeEnumRules.ALL_MATCH.value,
         },
         name: '',
      },
   });

   const [createPersonGroupsMutation, { loading }] = useMutation(CREATE_PERSON_GROUP_MUTATION);

   const [updatePersonGroup] = useMutation(UPDATE_PERSON_GROUP_MUTATION);

   const [removePersonGroup] = useMutation(REMOVE_PERSON_GROUP_MUTATION);

   const [getCountryInvoice, { data: countryDataInvoice, loading: countryLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: FILTER_TYPES.COUNTRY.camelCase,
         id: 0,
      },
   });

   const [getStateInvoice, { data: stateDataInvoice, loading: stateLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: FILTER_TYPES.STATE.camelCase,
         id: watch('filterItems.country.value.value') || 0,
      },
   });

   const [getChatTagQuery, { data: chatTagsData, loading: chatTagsDataLoading }] = useLazyQuery(GET_CHAT_TAGS_QUERY, {
      fetchPolicy: 'cache-first',
      variables: {
         customer: person.customer._id,
         pageSize: 100,
         page: 1,
      },
   });

   const [getCustomFieldsData, { data: customFieldsData, loading: customFieldsLoading }] = useLazyQuery(
      GET_CUSTOM_FIELDS,
      {
         fetchPolicy: 'cache-and-network',
         onCompleted: (data) => {
            if (!showDynamicGroup.isEdit) {
               setPersonFilter((prev) => [
                  ...prev,
                  ...data.getCustomFields.data.map((item) => {
                     return {
                        label: item.name,
                        key: item.name,
                        isSelect: false,
                        type: item.type,
                        listValues: item.listValues,
                     };
                  }),
               ]);
            }
         },
      },
   );

   const createGroupSubmit = (data) => {
      const conditions = Object.entries(data.filterItems)
         .map(([key, value]) => {
            if (value && key) {
               let conditionValue;

               if (typeof value.value === 'string') {
                  conditionValue = value.value;
               } else if (
                  [
                     FILTER_TYPES.COUNTRY.camelCase,
                     FILTER_TYPES.STATE.camelCase,
                     FILTER_TYPES.LANGUAGE.camelCase,
                  ].includes(key)
               ) {
                  conditionValue = value.value.label;
               } else {
                  conditionValue = value.value.value;
               }

               return {
                  type: key,
                  rule: value.rule.value,
                  value: conditionValue,
               };
            }
            return null;
         })
         .filter((item) => item !== null);
      if (showDynamicGroup.isEdit) {
         setDynamicGroup({
            ...showDynamicGroup,
            loading: true,
         });
         updatePersonGroup({
            variables: {
               _id: showDynamicGroup.group_id,
               name: data.groupNameTitle,
               customer: person.customer._id,
               shouldMatchAllConditions: data.isAllConditionMatch.value === typeEnumRules.ALL_MATCH.value,
               conditions,
            },
         })
            .then(() => {
               enqueueSnackbar(t['page_settings_typo_chat_settings_automatic_actions_success_message'], {
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            })
            .catch((error) => {
               enqueueSnackbar(error.message, {
                  variant: 'error',
                  autoHideDuration: 2000,
               });
            })
            .finally(() => {
               setDynamicGroup({
                  ...showDynamicGroup,
                  visible: false,
                  loading: false,
               });
            });
      } else {
         setDynamicGroup({
            ...showDynamicGroup,
            loading: true,
         });
         createPersonGroupsMutation({
            variables: {
               name: data.groupNameTitle,
               customer: person.customer._id,
               shouldMatchAllConditions: data.isAllConditionMatch.value === typeEnumRules.ALL_MATCH.value,
               conditions,
               isDynamic: true,
            },
         })
            .then(() => {
               enqueueSnackbar(t['page_settings_typo_chat_settings_automatic_actions_success_message'], {
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            })
            .catch((error) => {
               enqueueSnackbar(error.message, {
                  variant: 'error',
                  autoHideDuration: 2000,
               });
            })
            .finally(() => {
               setDynamicGroup({
                  ...showDynamicGroup,
                  visible: false,
                  loading: false,
               });
            });
      }
   };

   const deleteGroupSubmit = () => {
      setIsDeleting(true);
      removePersonGroup({
         variables: {
            _id: showDynamicGroup.group_id,
            customer: person.customer._id,
         },
      })
         .then(() => {
            enqueueSnackbar(t['general_information_deleted_successfully'], {
               variant: 'success',
               autoHideDuration: 2000,
            });
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               autoHideDuration: 2000,
            });
         })
         .finally(() => {
            setIsDeleting(false);
            setDynamicGroup({
               ...showDynamicGroup,
               visible: false,
            });
         });
   };

   useEffect(() => {
      if (showDynamicGroup.isEdit) {
         getCustomFieldsData({
            variables: {
               customer: person.customer._id,
            },
         });
         getChatTagQuery().then((tag) => {
            if (showDynamicGroup.isEdit) {
               showDynamicGroup.conditions.find((item) => item.type === FILTER_TYPES.TAGS.camelCase)?.value &&
                  setValue('filterItems.tags.value', {
                     label: tag?.data?.getChatTagList?.data.docs.find(
                        ({ _id }) =>
                           _id ===
                           showDynamicGroup.conditions.find((item) => item.type === FILTER_TYPES.TAGS.camelCase)?.value,
                     )?.name,
                     value: showDynamicGroup.conditions.find((item) => item.type === FILTER_TYPES.TAGS.camelCase)
                        ?.value,
                  });
            }
         });
      }
   }, []);

   useEffect(() => {
      if (showDynamicGroup.isEdit && customFieldsData) {
         setValue('groupNameTitle', showDynamicGroup.group_name);
         setValue('isAllConditionMatch', {
            label: showDynamicGroup.shouldMatchAllConditions
               ? typeEnumRules.ALL_MATCH.label
               : typeEnumRules.LEAST_ONE_MATCH.label,
            value: showDynamicGroup.shouldMatchAllConditions
               ? typeEnumRules.ALL_MATCH.value
               : typeEnumRules.LEAST_ONE_MATCH.value,
         });
         setFilterItems(
            showDynamicGroup.conditions.map((item) => {
               const customField = customFieldsData.getCustomFields.data.find(
                  (filterItem) => filterItem.name === item.type,
               );
               const label1 =
                  defaultPersonFilter.find((filterItem) => filterItem.key === item.type)?.label || item.type;
               return {
                  name1: `filterItems.${item.type}.rule`,
                  placeholder1: t['page_contacts_typo_items_equal'],
                  label1,
                  name2: `filterItems.${item.type}.value`,
                  placeholder2: t['page_contacts_typo_items_value'],
                  // if customField?.type is undefined then customFieldsData will be used
                  type: personFilter.find((filterItem) => filterItem.key === item.type)?.type || customField?.type,
                  listValues:
                     personFilter.find((filterItem) => filterItem.key === item.type)?.listValues ||
                     customField?.listValues,
               };
            }),
         );
         setPersonFilter(
            [
               ...personFilter,
               ...customFieldsData.getCustomFields.data.map((item) => {
                  return {
                     label: item.name,
                     key: item.name,
                     isSelect: false,
                     type: item.type,
                     listValues: item.listValues,
                  };
               }),
            ].map((filterItem) => {
               if (filterItem.key === showDynamicGroup.conditions.find((item) => item.type === filterItem.key)?.type) {
                  return {
                     ...filterItem,
                     isSelect: true,
                  };
               }
               return filterItem;
            }),
         );
         showDynamicGroup.conditions.forEach((item) => {
            const customFieldType = customFieldsData.getCustomFields.data.find(
               (filterItem) => filterItem.name === item.type,
            )?.type;
            const whichFilterItem = () => {
               if (customFieldType === FILTER_TYPES.LIST.upperCase) {
                  return {
                     label: item.value,
                     value: item.value,
                  };
               }

               switch (item.type) {
                  case FILTER_TYPES.TYPE.camelCase:
                     return {
                        label: platformTypes.find((platform) => platform.value.toString() === item.value)?.label,
                        value: item.value,
                     };
                  case FILTER_TYPES.EMAIL_PERMISSION.camelCase:
                  case FILTER_TYPES.PHONE_PERMISSION.camelCase:
                     return {
                        label:
                           item.value === 'true'
                              ? t['page_contacts_typo_subscribed']
                              : t['page_contacts_typo_unsubscribed'],
                        value: item.value,
                     };
                  case 'country':
                     return {
                        label: item.value,
                        value: item.value,
                     };
                  case 'state':
                     return {
                        label: item.value,
                        value: item.value,
                     };
                  case 'language':
                     return {
                        label: item.value,
                        value: item.value,
                     };
                  case 'tags':
                     return {
                        label: chatTagsData?.getChatTagList?.data.docs.find(({ _id }) => _id === item.value)?.name,
                        value: item.value,
                     };
                  default:
                     return item.value;
               }
            };
            setValue(`filterItems.${item.type}.rule`, {
               label:
                  item.rule === typeEnumRules.EQUAL.value ? typeEnumRules.EQUAL.label : typeEnumRules.CONTAINS.label,
               value: item.rule,
            });
            setValue(`filterItems.${item.type}.value`, whichFilterItem());
         });
      }
   }, [customFieldsData]);

   return (
      <Modal
         open={
            (showDynamicGroup.visible && (!customFieldsLoading || !showDynamicGroup.isEdit)) ||
            (showDynamicGroup.isEdit && !chatTagsDataLoading && showDynamicGroup.visible)
         }
         onClose={() =>
            setDynamicGroup({
               ...showDynamicGroup,
               visible: false,
            })
         }
         bodyStyle={{
            minHeight: 600,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
         }}
         modalHeader={t?.create_new_user_dynamic_group}
      >
         <div>
            <SingleInput
               label={t?.group_name}
               name='groupNameTitle'
               rules={{
                  required: t?.required_field,
                  maxLength: { value: 30, message: t?.page_contacts_typo_group_name_max_length },
               }}
               control={control}
               placeholder={t?.group_name}
            />
            <div style={{ marginTop: '-1.8rem', marginBottom: '0.5rem' }}>
               <SingleInput
                  className={classes.isAllConditionMatch}
                  name='isAllConditionMatch'
                  rules={{
                     required: t?.required_field,
                     maxLength: { value: 30, message: t?.page_contacts_typo_group_name_max_length },
                  }}
                  isSelect
                  selectOptions={[
                     {
                        label: typeEnumRules.ALL_MATCH.label,
                        value: typeEnumRules.ALL_MATCH.value,
                     },
                     {
                        label: typeEnumRules.LEAST_ONE_MATCH.label,
                        value: typeEnumRules.LEAST_ONE_MATCH.value,
                     },
                  ]}
                  control={control}
               />
            </div>
            <div style={{ borderTop: '1px solid rgba(0, 0, 0, .2)', padding: '1rem 0' }}>
               {filterItems.length > 0 &&
                  filterItems.map((item) => {
                     const selectOptions2 = () => {
                        switch (item.type) {
                           case FILTER_TYPES.LIST.upperCase:
                              return item.listValues?.map((item) => {
                                 return {
                                    label: item,
                                    value: item,
                                 };
                              });
                           case FILTER_TYPES.TYPE.upperCase:
                              return platformTypes.map((item) => {
                                 return {
                                    label: item.label,
                                    value: item.value.toString(),
                                 };
                              });
                           case FILTER_TYPES.EMAIL_PERMISSION.upperCase:
                           case FILTER_TYPES.PHONE_PERMISSION.upperCase:
                              return [
                                 {
                                    label: t['page_contacts_typo_subscribed'],
                                    value: 'true',
                                 },
                                 {
                                    label: t['page_contacts_typo_unsubscribed'],
                                    value: 'false',
                                 },
                              ];
                           case FILTER_TYPES.COUNTRY.upperCase:
                              return countryDataInvoice?.locations?.data?.map(({ name, id }) => ({
                                 value: id,
                                 label: name,
                              }));
                           case FILTER_TYPES.STATE.upperCase:
                              return stateDataInvoice?.locations?.data?.map(({ name, id }) => ({
                                 value: id,
                                 label: name,
                              }));

                           case FILTER_TYPES.LANGUAGE.upperCase:
                              return languagesData.map(({ label, code }) => ({
                                 value: code,
                                 label,
                              }));
                           case FILTER_TYPES.TAGS.upperCase:
                              return chatTagsData?.getChatTagList?.data.docs.map(({ name, _id, color }) => ({
                                 value: _id,
                                 label: name,
                                 color,
                              }));
                           default:
                              return [];
                        }
                     };
                     return (
                        <div
                           key={item.label1}
                           style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 8 }}
                        >
                           <DoubleInput
                              name1={item.name1}
                              control1={control}
                              isSelect1
                              selectOptions1={[
                                 {
                                    label: typeEnumRules.EQUAL.label,
                                    value: typeEnumRules.EQUAL.value,
                                 },
                                 {
                                    label: typeEnumRules.CONTAINS.label,
                                    value: typeEnumRules.CONTAINS.value,
                                 },
                              ]}
                              disabled1={
                                 item.type === FILTER_TYPES.LIST.upperCase ||
                                 item.type === FILTER_TYPES.DATE.upperCase ||
                                 item.type === FILTER_TYPES.TYPE.upperCase ||
                                 item.type === FILTER_TYPES.EMAIL_PERMISSION.upperCase ||
                                 item.type === FILTER_TYPES.PHONE_PERMISSION.upperCase ||
                                 item.type === FILTER_TYPES.COUNTRY.upperCase ||
                                 item.type === FILTER_TYPES.STATE.upperCase ||
                                 item.type === FILTER_TYPES.LANGUAGE.upperCase ||
                                 item.type === FILTER_TYPES.TAGS.upperCase
                              }
                              placeholder1={item.placeholder1}
                              label1={item.label1}
                              rules1={{
                                 required: t['required_field'],
                              }}
                              isSelect2={
                                 item.type === FILTER_TYPES.LIST.upperCase ||
                                 item.type === FILTER_TYPES.TYPE.upperCase ||
                                 item.type === FILTER_TYPES.EMAIL_PERMISSION.upperCase ||
                                 item.type === FILTER_TYPES.PHONE_PERMISSION.upperCase ||
                                 item.type === FILTER_TYPES.COUNTRY.upperCase ||
                                 item.type === FILTER_TYPES.STATE.upperCase ||
                                 item.type === FILTER_TYPES.LANGUAGE.upperCase ||
                                 item.type === FILTER_TYPES.TAGS.upperCase
                              }
                              isLoading2={
                                 stateLoadingInvoice || countryLoadingInvoice || chatTagsDataLoading || languagesLoading
                              }
                              name2={item.name2}
                              onFocus2={() => {
                                 switch (item.type) {
                                    case FILTER_TYPES.COUNTRY.upperCase:
                                       getCountryInvoice().then(() => {
                                          if (showDynamicGroup.isEdit) {
                                             setValue('filterItems.state');
                                          }
                                       });
                                       break;
                                    case FILTER_TYPES.STATE.upperCase:
                                       getStateInvoice();
                                       break;
                                    case FILTER_TYPES.TAGS.upperCase:
                                       getChatTagQuery();
                                       break;
                                    default:
                                       break;
                                 }
                              }}
                              isDate2={item.type === FILTER_TYPES.DATE.upperCase}  // 12/12/2020 for formatter
                              maxMenuHeight2={190}
                              customSelectStyles2={{
                                 menu: (base) => ({
                                    ...base,
                                    top: 'auto',
                                    bottom:
                                       // menuItem position top or bottom
                                       watch('filterItems') &&
                                       Object.values(watch('filterItems')).filter((item) => item !== undefined).length >
                                          2
                                          ? '100%'
                                          : 'auto',
                                 }),
                              }}
                              selectOptions2={selectOptions2()}
                              control2={control}
                              placeholder2={item.placeholder2}
                              rules2={isValidDynamicPersonFilter(item.type)}
                           />
                           <CloseIcon
                              onClick={() => {
                                 setPersonFilter((prev) =>
                                    prev.map((filterItem) => {
                                       if (filterItem.key === item.name1.split('.')[1]) {
                                          return {
                                             ...filterItem,
                                             isSelect: false,
                                          };
                                       }
                                       return filterItem;
                                    }),
                                 );
                                 setFilterItems((prev) =>
                                    prev.filter((filterItem) => filterItem.label1 !== item.label1),
                                 );
                                 setValue(`filterItems.${item.name1.split('.')[1]}`);
                              }}
                              style={{ cursor: 'pointer', color: 'hsl(0,0%,70%)' }}
                           />
                        </div>
                     );
                  })}
               <Select
                  styles={{
                     zIndex: 9999,
                     control: (base, state) => ({
                        ...base,
                        background: 'transparent',
                        textAlign: 'center',
                        cursor: 'pointer',
                        border: !state.isFocused && '2px dashed hsl(0,0%,70%)',
                        borderRadius: 8,
                     }),
                     placeholder: (base, state) => ({
                        ...base,
                        position: 'absolute',
                        left: '50%',
                        top: '20%',
                        transform: 'translateX(-50%)',
                        width: '100%',
                        textAlign: 'center',
                        color: state.isFocused ? '#0080fe' : 'hsl(0,0%,70%)',
                     }),
                     indicatorSeparator: () => ({
                        display: 'none',
                     }),
                     dropdownIndicator: () => ({
                        display: 'none',
                     }),
                     menu: (base) => ({
                        ...base,
                        top: 'auto',
                        bottom:
                           // menuItem position top or bottom
                           watch('filterItems') &&
                           Object.values(watch('filterItems')).filter((item) => item !== undefined).length > 1
                              ? '100%'
                              : 'auto',
                     }),
                  }}
                  onFocus={() => {
                     if (!customFieldsData) {
                        getCustomFieldsData({
                           variables: {
                              customer: person.customer._id,
                           },
                        });
                     }
                  }}
                  onChange={(item) => {
                     setPersonFilter((prev) =>
                        prev.map((filterItem) => {
                           if (filterItem.key === item.key) {
                              return {
                                 ...filterItem,
                                 isSelect: true,
                              };
                           }
                           return filterItem;
                        }),
                     );
                     setIsAddFilter(null);
                     setFilterItems((prev) => [
                        ...prev,
                        {
                           name1: `filterItems.${item.key}.rule`,
                           placeholder1: t['page_contacts_typo_items_equal'],
                           label1: item.label,
                           name2: `filterItems.${item.key}.value`,
                           placeholder2: t['page_contacts_typo_items_value'],
                           type: item.type,
                           listValues: item.listValues,
                        },
                     ]);
                     if (
                        item.type === FILTER_TYPES.LIST.upperCase ||
                        item.type === FILTER_TYPES.TYPE.upperCase ||
                        item.type === FILTER_TYPES.DATE.upperCase ||
                        item.type === FILTER_TYPES.EMAIL_PERMISSION.upperCase ||
                        item.type === FILTER_TYPES.PHONE_PERMISSION.upperCase ||
                        item.type === FILTER_TYPES.COUNTRY.upperCase ||
                        item.type === FILTER_TYPES.STATE.upperCase ||
                        item.type === FILTER_TYPES.LANGUAGE.upperCase ||
                        item.type === FILTER_TYPES.TAGS.upperCase
                     ) {
                        setValue(`filterItems.${item.key}.rule`, {
                           label: typeEnumRules.EQUAL.label,
                           value: typeEnumRules.EQUAL.value,
                        });
                     } else {
                        setValue(`filterItems.${item.key}.rule`, {
                           label: typeEnumRules.CONTAINS.label,
                           value: typeEnumRules.CONTAINS.value,
                        });
                     }
                  }}
                  value={isAddFilter}
                  maxMenuHeight={190}
                  options={personFilter?.filter((item) => item.isSelect === false)}
                  placeholder={
                     <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                        <AddIcon /> <span style={{ fontSize: 15 }}>{t['page_contacts_typo_add_filter']}</span>
                        {customFieldsLoading && (
                           <CircularProgress style={{ marginLeft: 8 }} color='inherit' size={18} />
                        )}
                     </div>
                  }
               />
            </div>
         </div>
         <ModalButtonField>
            <ButtonCancel
               disabled={showCreateManualAccount.loading}
               label={t['comp_button_cancel']}
               onClick={() =>
                  setDynamicGroup({
                     visible: false,
                     loading: false,
                  })
               }
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
               }}
            />
            <Grid>
               <ButtonOK
                  className={classes.modalButtons}
                  disabled={
                     showDynamicGroup.loading ||
                     loading ||
                     !isValid ||
                     (watch('filterItems') &&
                        Object.values(watch('filterItems'))?.filter((item) => item !== undefined).length === 0) ||
                     !watch('filterItems')
                  }
                  label={t?.pre_load_forget_pass_sent_OK}
                  endIcon={
                     showDynamicGroup.loading && (
                        <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                     )
                  }
                  onClick={handleSubmit(createGroupSubmit)}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     float: 'right',
                     marginLeft: 5,
                  }}
               />
               {showDynamicGroup.isEdit && (
                  <ButtonOK
                     className={classes.modalButtons}
                     label={t['delete_group']}
                     style={{
                        color: 'white',
                        backgroundColor: '#ef5350',
                        '&:hover': {
                           backgroundColor: '#f44336',
                        },
                        float: 'right',
                     }}
                     endIcon={isDeleting && <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />}
                     onClick={handleSubmit(deleteGroupSubmit)}
                  />
               )}
            </Grid>
         </ModalButtonField>
      </Modal>
   );
};

export default DynamicGroupModal;

import React from 'react';

const Copy = () => {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='29.291' height='22.996' viewBox='0 0 29.291 22.996'>
         <g id='Group_1570' dataname='Group 1570' transform='translate(-332.204 1797.056)'>
            <path
               id='Path_1575'
               dataname='Path 1575'
               d='M361.494-1792.837v3.107a.958.958,0,0,1-.955.7.837.837,0,0,0-.782,1.022.866.866,0,0,0,.864.684.84.84,0,0,1,.829.85c.012.9.01,1.8,0,2.7a.844.844,0,0,1-.675.838c-.106.023-.218.02-.326.038a.856.856,0,0,0-.714.836.856.856,0,0,0,.719.847c.108.018.22.014.326.038a.865.865,0,0,1,.674.9c0,.836-.012,1.673,0,2.51a.912.912,0,0,1-.9.991.838.838,0,0,0-.821.87.869.869,0,0,0,.863.838.845.845,0,0,1,.852.859c.011.926.007,1.852,0,2.779a.79.79,0,0,1-.478.766,1.314,1.314,0,0,1-.52.1q-11.618.007-23.236,0-2.04,0-4.079,0a.864.864,0,0,1-.931-.933q0-1.128,0-2.256a.872.872,0,0,1,.906-.977.848.848,0,0,0,.806-.87.854.854,0,0,0-.825-.839.864.864,0,0,1-.886-.933q0-1.263,0-2.525a.868.868,0,0,1,.915-.965.858.858,0,0,0,.77-1.073.848.848,0,0,0-.808-.636.859.859,0,0,1-.874-.84q-.01-1.4,0-2.793a.843.843,0,0,1,.763-.82,2.111,2.111,0,0,0,.31-.04.85.85,0,0,0,.628-.942.86.86,0,0,0-.843-.742.851.851,0,0,1-.86-.85c0-1.036-.007-2.072,0-3.107a.815.815,0,0,1,.533-.777,2.008,2.008,0,0,1,.271-.078h27.675a2.691,2.691,0,0,1,.268.076A.92.92,0,0,1,361.494-1792.837Zm-27.575,1.019v.172c0,.4,0,.8,0,1.2a.216.216,0,0,0,.146.233,2.5,2.5,0,0,1,1.567,2.388,2.483,2.483,0,0,1-1.6,2.392.15.15,0,0,0-.111.162c.005.334.007.667,0,1a.214.214,0,0,0,.15.231,2.5,2.5,0,0,1,1.563,2.391,2.477,2.477,0,0,1-1.605,2.388.152.152,0,0,0-.107.167c0,.329.008.657,0,.986a.219.219,0,0,0,.154.244,2.558,2.558,0,0,1,1.534,2.764,2.5,2.5,0,0,1-1.554,2.008c-.046.021-.122.055-.123.086-.01.271-.006.542-.006.813h25.847c0-.039.011-.073.011-.107,0-.309-.006-.618,0-.926a.213.213,0,0,0-.152-.229,2.5,2.5,0,0,1-1.561-2.393,2.471,2.471,0,0,1,1.608-2.385.144.144,0,0,0,.1-.154c0-.333-.008-.667,0-1a.223.223,0,0,0-.155-.243,2.5,2.5,0,0,1-1.558-2.394,2.472,2.472,0,0,1,1.611-2.383.143.143,0,0,0,.1-.156c0-.324-.009-.648,0-.971a.25.25,0,0,0-.172-.278,2.559,2.559,0,0,1-1.489-2.906,2.491,2.491,0,0,1,1.5-1.845c.042-.019.1-.064.1-.1.007-.382,0-.763,0-1.152Z'
               transform='translate(0 -3.502)'
               fill='#178cf9'
            />
            <path
               id='Path_1576'
               dataname='Path 1576'
               d='M614.984-1627.332a.789.789,0,0,1-.188.522,2.21,2.21,0,0,1-.153.163L603.394-1615.4c-.046.046-.091.092-.139.135a.825.825,0,0,1-1.18-.029.827.827,0,0,1-.008-1.181c.02-.022.042-.043.063-.064q5.672-5.671,11.344-11.342a.882.882,0,0,1,.745-.3A.87.87,0,0,1,614.984-1627.332Z'
               transform='translate(-261.553 -163.948)'
               fill='#178cf9'
            />
            <path
               id='Path_1577'
               dataname='Path 1577'
               d='M869.5-1383.2a2.614,2.614,0,0,1,2.6,2.624,2.6,2.6,0,0,1-2.616,2.6,2.607,2.607,0,0,1-2.613-2.627A2.623,2.623,0,0,1,869.5-1383.2Zm-.907,2.577a.9.9,0,0,0,.9.892.908.908,0,0,0,.9-.905.9.9,0,0,0-.915-.89A.894.894,0,0,0,868.6-1380.628Z'
               transform='translate(-518.676 -401.603)'
               fill='#178cf9'
            />
            <path
               id='Path_1578'
               dataname='Path 1578'
               d='M587.382-1592.636a2.6,2.6,0,0,1,2.626-2.609,2.588,2.588,0,0,1,2.6,2.613A2.617,2.617,0,0,1,590-1590.02,2.626,2.626,0,0,1,587.382-1592.636Zm1.719.05a.871.871,0,0,0,.9.892.875.875,0,0,0,.9-.893.909.909,0,0,0-.9-.9A.9.9,0,0,0,589.1-1592.586Z'
               transform='translate(-247.544 -195.907)'
               fill='#178cf9'
            />
         </g>
      </svg>
   );
};

export default Copy;

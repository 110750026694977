import { Grid } from '@material-ui/core';
import React from 'react';
import { trimLongText } from '@helper/textUtils';
import AudioPlayer from './audioPlayer';
import ImageViewer from './imageViewer';
import MiscFile from './miscFile';
import VideoPlayer from './videoPlayer';

const FileViewer = ({ url, type, onShow, name, isLoading, onRemove, showPreview, disabled, styles }) => {
   const slicedName = trimLongText(name, 10);

   // get override styles for the components
   const { image, ...rest } = styles;
   const getFileComponent = () => {
      switch (type?.split('/')[0]) {
         case 'image':
            return (
               <ImageViewer
                  showPreview={showPreview}
                  url={url}
                  name={slicedName}
                  isLoading={isLoading}
                  onClose={onRemove}
                  disabled={disabled}
                  styles={image}
                  onShow={onShow}
               />
            );
         case 'video':
            return (
               <VideoPlayer
                  type={type}
                  showPreview={showPreview}
                  url={url}
                  name={slicedName}
                  isLoading={isLoading}
                  onClose={onRemove}
                  disabled={disabled}
                  onShow={onShow}
               />
            );
         case 'audio':
            return (
               <AudioPlayer
                  showPreview={showPreview}
                  url={url}
                  name={slicedName}
                  isLoading={isLoading}
                  onClose={onRemove}
                  disabled={disabled}
                  onShow={onShow}
               />
            );
         default:
            return (
               <MiscFile
                  showPreview={showPreview}
                  url={url}
                  name={slicedName}
                  isLoading={isLoading}
                  onClose={onRemove}
                  disabled={disabled}
                  onShow={onShow}
               />
            );
      }
   };
   return (
      <Grid container direction='row' justifyContent='space-between' alignItems='flex-end'>
         {getFileComponent()}
      </Grid>
   );
};

export default FileViewer;

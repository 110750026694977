import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@components/InputField';

const useStyles = makeStyles(() => ({
   inputField: {
      width: '100%',
      marginTop: 6,

      '& .MuiInputLabel-root': {
         marginLeft: 3,
         fontSize: 18,
         color: '#212121',
         fontWeight: 'medium',
      },

      '& .makeStyles-inputLabel-141': {
         marginTop: -50,
      },
   },
}));

const SingleInput = (props: any) => {
   const classes = useStyles();

   const {
      control,
      maxLength,
      name,
      label,
      disabled,
      editable,
      required,
      rules,
      type,
      helperText,
      placeholder,
      isPhone,
      isEmail,
      splitCountryCode,
      multiline,
      isSelect,
      isMulti,
      isCreatable,
      isSearchable,
      selectOptions,
      selectDefaultValue,
      customSelectStyles,
      rows,
      notDisplay,
      startAdornment,
      endAdornment,
      isOptionDisabled,
      onKeyPress,
      value,
      autoHeight,
      onChange,
      onFocus,
      maxMenuHeight,
      isClearable,
      backspaceRemovesValue,
      isPaginateSelect,
      loadOptions,
      isDate, // For custom Field 12/10/2021   / / / formatter
   } = props;

   return (
      <Grid container xs={12}>
         <Grid item xs={12} className={classes.inputField}>
            <Input
               maxMenuHeight={maxMenuHeight}
               control={control}
               maxLength={maxLength}
               name={name}
               label={label}
               disabled={disabled}
               editable={editable}
               required={required}
               rules={rules}
               type={type}
               helperText={helperText}
               placeholder={placeholder || label || name}
               isPhone={isPhone}
               isEmail={isEmail}
               splitCountryCode={splitCountryCode}
               multiline={multiline}
               isSelect={isSelect}
               isMulti={isMulti}
               isSearchable={isSearchable}
               isCreatable={isCreatable}
               selectOptions={selectOptions}
               selectDefaultValue={selectDefaultValue}
               customSelectStyles={customSelectStyles}
               rows={rows}
               notDisplay={notDisplay}
               startAdornment={startAdornment}
               endAdornment={endAdornment}
               onKeyPress={onKeyPress}
               value={value}
               autoHeight={autoHeight}
               isOptionDisabled={isOptionDisabled}
               onChange={onChange}
               onFocus={onFocus}
               isClearable={isClearable}
               backspaceRemovesValue={backspaceRemovesValue}
               isPaginateSelect={isPaginateSelect}
               loadOptions={loadOptions}
               isDate={isDate}
            />
         </Grid>
      </Grid>
   );
};

export default SingleInput;

import { gql } from '@apollo/client';

export const CREATE_TEAM_MUTATION = gql`
   mutation createTeams(
      $customer: String!
      $teamName: String!
      $drivers: Array!
      $customerPersons: Array!
      $color: String
   ) {
      createTeams(
         data: {
            customer: $customer
            teamName: $teamName
            drivers: $drivers
            customerPersons: $customerPersons
            color: $color
         }
      ) {
         data {
            _id
            customerPersons {
               _id
            }
            drivers
         }
         success
         code
         message
      }
   }
`;
export const UPDATE_TEAM_MUTATION = gql`
   mutation updateTeams(
      $customer: String!
      $teamName: String!
      $drivers: Array!
      $customerPersons: Array!
      $_id: String!
      $color: String
   ) {
      updateTeams(
         data: {
            _id: $_id
            customer: $customer
            teamName: $teamName
            drivers: $drivers
            customerPersons: $customerPersons
            color: $color
         }
      ) {
         data {
            _id
            customerPersons {
               _id
            }
            drivers
         }
         success
         code
         message
      }
   }
`;

export const DELETE_TEAM_MUTATION = gql`
   mutation deleteTeams($_id: String!, $customer: String!, $selectedTeam: String) {
      deleteTeams(data: { _id: $_id, customer: $customer, selectedTeam: $selectedTeam }) {
         data {
            _id
            customerPersons {
               _id
            }
            drivers
         }
         success
         code
         message
      }
   }
`;

import React, { useContext, useState } from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import {
   REMOVE_PERSON_GROUP_MUTATION,
   UPDATE_PERSON_GROUP_MUTATION,
} from '@queries/PersonGroups/Service/GraphQL/mutation';
import { useForm } from 'react-hook-form';
import { getPerson } from '@store/reducers/person';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';

const useStyles = makeStyles((theme) => ({
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalFooter: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 15,
      borderTop: '1px solid #F1F3F7',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const EditGroupModal = ({ showEditGroup, setEditGroup }) => {
   const person = useSelector(getPerson);
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar } = useSnackbar();
   const [isDeleting, setIsDeleting] = useState(false);
   const [isUpdating, setIsUpdating] = useState(false);

   const [removePersonGroup] = useMutation(REMOVE_PERSON_GROUP_MUTATION);

   const [updatePersonGroup] = useMutation(UPDATE_PERSON_GROUP_MUTATION);

   const onDelete = () => {
      setIsDeleting(true);
      removePersonGroup({
         variables: {
            _id: showEditGroup.groupId,
            customer: person.customer._id,
         },
      })
         .then(() => {
            setEditGroup({
               visible: false,
               groupId: null,
               loading: false,
               groupName: '',
            });
            enqueueSnackbar(t['general_information_deleted_successfully'], {
               variant: 'success',
               autoHideDuration: 2000,
            });
         })
         .catch((error) => {
            setEditGroup({
               visible: false,
               groupId: null,
               loading: false,
               groupName: '',
            });
            enqueueSnackbar(error.message, {
               variant: 'error',
               autoHideDuration: 2000,
            });
         })
         .finally(() => {
            setIsDeleting(false);
         });
   };

   const onUpdate = (data) => {
      setIsUpdating(true);
      updatePersonGroup({
         variables: {
            _id: showEditGroup.groupId,
            name: data.groupName,
            customer: person.customer._id,
         },
      })
         .then(() => {
            setEditGroup({
               visible: false,
               groupId: null,
               loading: false,
               groupName: '',
            });
            enqueueSnackbar(t['page_settings_typo_chat_settings_automatic_actions_success_message'], {
               variant: 'success',
               autoHideDuration: 2000,
            });
         })
         .catch((error) => {
            setEditGroup({
               visible: false,
               groupId: null,
               loading: false,
               groupName: '',
            });
            enqueueSnackbar(error.message, {
               variant: 'error',
               autoHideDuration: 2000,
            });
         })
         .finally(() => {
            setIsUpdating(false);
         });
   };

   const { control, handleSubmit } = useForm({
      mode: 'all',
      defaultValues: {
         groupName: showEditGroup.groupName,
      },
   });

   return (
      <Modal
         open={showEditGroup.visible}
         onClose={() => {
            setEditGroup({
               ...showEditGroup,
               visible: false,
            });
         }}
         modalHeader={t?.update_user_group}
      >
         <SingleInput
            name='groupName'
            label={t?.group_name}
            rules={{
               required: t['page_contacts_typo_group_name_required'],
               maxLength: {
                  value: 30,
                  message: t['page_contacts_typo_group_name_max_length'],
               },
            }}
            control={control}
         />
         <ModalButtonField>
            <ButtonCancel
               className={classes.modalButtons}
               disabled={isUpdating || isDeleting}
               label={t['comp_button_cancel']}
               onClick={() =>
                  setEditGroup({
                     visible: false,
                     loading: false,
                  })
               }
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            />
            <Grid>
               <ButtonOK
                  disabled={isUpdating || isDeleting}
                  label={t['pre_load_forget_pass_sent_OK']}
                  endIcon={isUpdating && <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />}
                  onClick={handleSubmit(onUpdate)}
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     '&:hover': {
                        backgroundColor: '#105CBB',
                     },
                     float: 'right',
                     marginLeft: 5,
                  }}
               />
               <ButtonOK
                  className={classes.modalButtons}
                  disabled={isUpdating || isDeleting}
                  label={t['delete_group']}
                  style={{
                     color: 'white',
                     backgroundColor: '#ef5350',
                     '&:hover': {
                        backgroundColor: '#f44336',
                     },
                     float: 'right',
                  }}
                  endIcon={isDeleting && <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />}
                  onClick={handleSubmit(onDelete)}
               />
            </Grid>
         </ModalButtonField>
      </Modal>
   );
};

export default EditGroupModal;

export const SET_CHAT_FILTERS = 'SET_CHAT_FILTERS';
export const RESET_CHAT_FILTERS = 'RESET_CHAT_FILTERS';
export const SET_MY_CHAT_LIST = 'SET_MY_CHAT_LIST';
export const SET_MY_CUSTOMER_CHAT_LIST = 'SET_MY_CUSTOMER_CHAT_LIST';
export const JOIN_CHAT = 'JOIN_CHAT';
export const LEAVE_CHAT = 'LEAVE_CHAT';
export const ARCHIVE_CHAT = 'ARCHIVE_CHAT';
export const TRANSFER_CHAT = 'TRANSFER_CHAT';
export const CHANGE_CHAT_TAG = 'CHANGE_CHAT_TAG';
export const UPDATED_CHAT_STATUS = 'UPDATED_CHAT_STATUS';
export const MESSAGED_TO_ME = 'MESSAGED_TO_ME';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const CONFIRM_MESSAGE = 'CONFIRM_MESSAGE';
export const CHANGED_PHONE_STATUS = 'CHANGED_PHONE_STATUS';
export const SET_COMPANY_CHAT_COUNT = 'SET_COMPANY_CHAT_COUNT';
export const SET_MINE_CHAT_COUNT = 'SET_MINE_CHAT_COUNT';

export const SET_ACTIVE_CHAT = 'SET_ACTIVE_CHAT';
export const SET_ACTIVE_CHAT_MESSAGES = 'SET_ACTIVE_CHAT_MESSAGES';
export const UPDATE_ACTIVE_CHAT_PARTICIPANTS = 'UPDATE_ACTIVE_CHAT_PARTICIPANTS';
export const UPDATE_MY_CHAT_LIST_PARTICIPANTS = 'UPDATE_MY_CHAT_LIST_PARTICIPANTS';
export const UPDATE_MY_CUSTOMER_CHAT_LIST_PARTICIPANTS = 'UPDATE_MY_CUSTOMER_CHAT_LIST_PARTICIPANTS';

export const SET_ACTIVE_TEAM_CHAT = 'SET_ACTIVE_TEAM_CHAT';
export const SET_ACTIVE_TEAM_CHAT_MESSAGES = 'SET_ACTIVE_TEAM_CHAT_MESSAGES';
export const MESSAGED_TO_ACTIVE_TEAM_CHAT = 'MESSAGED_TO_ACTIVE_TEAM_CHAT';
export const SET_ACTIVE_TEAM_CHAT_STARTUP = 'SET_ACTIVE_TEAM_CHAT_STARTUP';
export const CONFIRM_TEAM_CHAT_MESSAGE = 'CONFIRM_TEAM_CHAT_MESSAGE';
export const SEND_TEAM_CHAT_MESSAGE = 'SEND_TEAM_CHAT_MESSAGE';

export const SET_EMAIL_DATA = 'SET_EMAIL_DATA';
export const SET_EMAIL_ACCOUNTS = 'SET_EMAIL_ACCOUNTS';
export const CREATE_EMAIL_MODAL_VISIBLE = 'CREATE_EMAIL_MODAL_VISIBLE';

export const SET_WHATSAPP_WEB_ACCOUNTS = 'SET_WHATSAPP_WEB_ACCOUNTS';
export const SET_WHATSAPP_BUSINESS_ACCOUNTS = 'SET_WHATSAPP_BUSINESS_ACCOUNTS';
export const CREATE_WHATSAPP_MODAL_VISIBLE = 'CREATE_WHATSAPP_MODAL_VISIBLE';

import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SITE_URL } from '@helper/config';
import {
   CREATE_PLATFORM_MUTATION,
   UPDATE_PLATFORM_MUTATION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/mutation';
import { PLATFORM_TYPE } from '../../../../queries/Settings/Service/GraphQL/Platform/types';
import ErrorPage from '../shared-components/ErrorPage';
import { EventType } from '@store/reducers/platformEventTypes';

const CreateFacebookAccount = ({ location: { search } }) => {
   const { code, state } = queryString.parse(search);
   const [createFacebookAccount, { loading: createAccountLoading }] = useMutation(CREATE_PLATFORM_MUTATION);
   const [updateAccountMutation, { loading: updateAccountLoading }] = useMutation(UPDATE_PLATFORM_MUTATION);
   const person = useSelector((reducer) => reducer.personReducer.person);
   const { facebookEventType } = useSelector((state) => state.platformEventTypeReducer);

   const [status, setStatus] = useState('');
   useEffect(() => {
      if (facebookEventType === EventType.CREATE) {
         createFacebookAccount({
            variables: {
               type: PLATFORM_TYPE.FACEBOOK,
               metaData: {
                  code,
                  customer: person.customer._id,
                  teams: JSON.parse(state)?.teams || [],
                  authenticationUrl: SITE_URL + '/authentication/facebook',
               },
            },
         })
            .then(() => {
               window.close();
               return '';
            })
            .catch((error) => {
               setStatus('failed');
               return '';
            });
      } else {
         updateAccountMutation({
            variables: {
               type: PLATFORM_TYPE.FACEBOOK,
               metaData: {
                  code,
                  customer: person.customer._id,
                  authenticationUrl: SITE_URL + '/authentication/facebook',
               },
            },
         })
            .then(() => {
               window.close();
               return '';
            })
            .catch((error) => {
               setStatus('failed');
               return '';
            });
      }
   }, [code, person?.customer?._id, createFacebookAccount]);
   // if (status === 'failed') {
   //    setTimeout(() => {
   //       window.close();
   //    }, 5000);
   // }

   if (createAccountLoading || updateAccountLoading) {
      return <div className='loading' />;
   }

   return <Fragment>{status === 'failed' && <ErrorPage platformHref={'/settings/platforms/facebook'} />}</Fragment>;
};

export default React.memo(CreateFacebookAccount);

import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../../../../fragments';

// queries
// Get customer of Person Query
export const GET_CUSTOMER_QUERY = gql`
   ${CUSTOMER_FRAGMENT}
   query getCustomerOfPersonQuery($_id: ID!) {
      personCustomer(_id: $_id) {
         data {
            ...CustomerFields
         }
         success
         code
         message
      }
   }
`;

export const GET_CUSTOMER_INVOICES_QUERY = gql`
   query getCustomerInvoicesQuery($customerId: ID!) {
      getCustomerInvoices(customerId: $customerId) {
         data {
            _id
            amountPaid
            products {
               _id
               name
            }
            paymentType
            paymentMethod
            paymentStatus
            paymentInvoice
            invoiceNo
            createdAt
         }
         success
         code
         message
      }
   }
`;

import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonProps, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { MyTheme } from '@styles/config';

interface Props extends ButtonProps {
   width?: number | string;
   height?: number | string;
   fontSize?: any;
   icon?: React.ReactNode;
   fontWeight?: any;
   label: string;
}

const useStyles = makeStyles<MyTheme, Props>((theme) => ({
   button: {
      paddingTop: 5,
      textTransform: 'none',
      width: (props) => props.width || 160,
      height: (props) => props.height || 40,
      borderRadius: 5,
      color: 'white',
      transition: 'all .5s',
   },
   filled: {
      color: 'white',
      backgroundColor: '#F4425B',
      '&:hover': {
         backgroundColor: '#E02E47',
      },
   },
   Outlined: {
      color: '#f12e45',
      backgroundColor: '#F4425B',
      '&:hover': {
         backgroundColor: '#E02E47',
         color: 'white',
      },
      border: '2px solid #F4425B',
   },
   label: {
      fontSize: (props) => props.fontSize || '1.1rem',
      fontWeight: (props) => props.fontWeight || null,
   },
   disabled: {
      '&$disabled': {
         backgroundColor: 'rgba(244, 66, 91, 0.6)',
         cursor: 'not-allowed',
         color: '#fff',
      },
   },
}));

const ButtonCancel: FC<Props> = (props) => {
   const { label, onClick, variant, fullWidth, icon, ...rest } = props;
   const classes = useStyles(props);

   return (
      <Button
         fullWidth={fullWidth}
         onClick={onClick}
         className={clsx(
            classes.button,
            variant === 'contained' && classes.filled,
            variant === 'outlined' && classes.Outlined
         )}
         classes={{ disabled: classes.disabled }}
         {...rest}
      >
         {icon}
         <Typography noWrap className={classes.label}>
            {label}
         </Typography>
      </Button>
   );
};

ButtonCancel.defaultProps = {
   variant: 'contained',
   label: 'Cancel',
   fontSize: '1rem',
};

export default ButtonCancel;

import { useLazyQuery, useSubscription } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import { GET_BULK_SHARINGS } from '@queries/BulkMessaging/query';
import { BULK_SHARING_UPDATE } from '@queries/BulkMessaging/subscription';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { BulkData, BulkFolder, BulkSharingStatusEnum, Folder } from '../types';
import FolderHistoryLister from './FolderHistoryLister';
import FolderLister from './folderLister';
import FolderTabs from './folderTabs';
import { ActiveTab, RightBarData } from './rightBar';

export interface FolderHolderProps {
    rightBarData: RightBarData;
    onTabChange: (tab: ActiveTab) => void;
    onFolderRemove: (folder: Folder) => void;
}

export interface FilterType {
    search: string;
    pageSize: number;
    page: number;
}

export interface PageDataType {
    totalDocs: number;
    hasMore: boolean;
    data: BulkFolder[];
}

const useStyles = makeStyles((theme: any) => ({
    container: {
        width: '100%',
        height: '73%',
        marginTop: '10px',
        backgroundColor: '#ffffff',
    },
}));

const FolderHolder = ({ rightBarData, onTabChange, onFolderRemove }: FolderHolderProps) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;
    const person = useSelector((reducer: any) => reducer.personReducer.person);
    const token = useSelector((reducer: any) => reducer.personReducer.token);

    const [filter, setFilter] = React.useState<FilterType>({
        search: '',
        pageSize: 10,
        page: 1,
    });

    {
        /*
              Search future, to be used in future. Just set setSearchValue with textBar.
              If you asked to do this you know who do thank :)
      
              const [searchValue, setSearchValue] = React.useState('');
              useEffect(() => {
                  const time = setTimeout(() => {
                      setFilter((prev) => ({ ...prev, search: searchValue }));
                  }, 500);
      
                  return () => clearTimeout(time);
              }, [searchValue]);
          */
    }

    const [pageData, setPageData] = React.useState<PageDataType>({
        totalDocs: 0,
        hasMore: false,
        data: [],
    });

    const handleNextPage = () => {
        setPageData({
            totalDocs: 0,
            hasMore: false,
            data: [],
        });
        setFilter((prev) => ({ ...prev, page: prev.page + 1 }));
    };

    const handlePreviousPage = () => {
        setPageData({
            totalDocs: 0,
            hasMore: false,
            data: [],
        });
        setFilter((prev) => ({ ...prev, page: prev.page - 1 }));
    };

    const [getBulkSharing, { loading: loadingGetBulkSharings }] = useLazyQuery(GET_BULK_SHARINGS, {
        fetchPolicy: 'no-cache',
        onCompleted: ({
            getBulkSharings: { data: data },
        }: {
            getBulkSharings: {
                data: {
                    docs: BulkData[];
                    totalRecords: number;
                    hasNextPage: boolean;
                };
            };
        }) => {
            setPageData({
                totalDocs: 0,
                hasMore: data.hasNextPage,
                data: data.docs.map((x) => x.folders).flat(),
            });
        },
    });

    useEffect(() => {
        setPageData({
            totalDocs: 0,
            hasMore: false,
            data: [],
        });
        getBulkSharing({
            variables: {
                input: {
                    customerId: person.customer._id,
                    page: filter?.page,
                    pageSize: filter?.pageSize,
                    status:
                        rightBarData.activeTab == ActiveTab.SendingFolders
                            ? BulkSharingStatusEnum.SENDING
                            : BulkSharingStatusEnum.SENT,
                },
            },
        });
    }, [filter, rightBarData.activeTab]);

    useSubscription<any>(BULK_SHARING_UPDATE, {
        variables: { token, customer: person ? person.customer._id : '' },
        onSubscriptionData: async ({
            client,
            subscriptionData: {
                data: {
                    bulkSharingUpdate: { data: bulkUpdate },
                },
            },
        }) => {
            const bulkData = bulkUpdate as BulkData;
            const newFolders = [];
            for (const folder of pageData.data) {
                const found = bulkData.folders.find((x) => x.folderLink === folder.folderLink);
                if (found) {
                    if (
                        (found.telegramStatus === undefined ||
                            found.telegramStatus === BulkSharingStatusEnum.SENT ||
                            found.telegramStatus === BulkSharingStatusEnum.FAILED) &&
                        (found.whatsAppStatus === undefined ||
                            found.whatsAppStatus === BulkSharingStatusEnum.SENT ||
                            found.whatsAppStatus === BulkSharingStatusEnum.FAILED)
                    ) {
                        continue;
                    } else {
                        newFolders.push(bulkData.folders.find((x) => x.folderLink === folder.folderLink) as BulkFolder);
                    }
                } else {
                    newFolders.push(folder);
                }
            }
            setPageData({
                ...pageData,
                data: newFolders,
            });
        },
    });

    return (
        <div className={classes.container}>
            <FolderTabs
                disabled={rightBarData.uploading}
                isSending={false}
                onTabChange={onTabChange}
                activeTab={rightBarData.activeTab}
            />
            {rightBarData.activeTab == ActiveTab.SelectedFolders && (
                <FolderLister
                    folders={rightBarData.selectedFolders}
                    folderRemoveable={rightBarData.activeTab == ActiveTab.SelectedFolders}
                    onFolderRemove={onFolderRemove}
                />
            )}
            <FolderHistoryLister
                activeTab={rightBarData.activeTab}
                loading={loadingGetBulkSharings}
                filter={filter}
                setFilter={setFilter}
                pageData={pageData}
                handlePreviousPage={handlePreviousPage}
                handleNextPage={handleNextPage}
            />
        </div>
    );
};

export default FolderHolder;

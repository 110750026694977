import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { Button, Slide, Typography, Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useSnackbar } from 'notistack';
import publicIp from 'public-ip';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { UPDATE_ME } from '@store/actions/person';
import { LanguageContext } from '@helper/locale/langContext';
import {
   BUY_CUSTOMER_ADD_ON_MUTATION,
   CHANGE_CUSTOMER_MAIN_PRODUCT_MUTATION,
} from '@queries/Customer/Service/GraphQL/CustomerProduct/mutation';
import {
   GET_ADD_ON_PACKAGES_QUERY,
   GET_CUSTOMER_PRODUCT_RENEWAL_PRICE_QUERY,
} from '@queries/Customer/Service/GraphQL/CustomerProduct/query';
import {
   COMPLATE_3D_CUSTOMER_PRODUCT_SUBSCRIPTION,
   CREATED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION,
   DELETED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION,
   UPDATED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION,
} from '@queries/Customer/Service/GraphQL/CustomerProduct/subscription';
import Modal from '@components/Modal/modal';
import { GET_PRODUCTS_QUERY } from '@queries/Products/Service/GraphQL/query';
import AddOnPackageItem from './addOnPackages/addOnItem';
import ChangeMainPackageModal from './changeMainPackageModal';
import DoPaymentModal from './doPaymentModal';
import Packages from './packages';
import { RenewalType } from '@types/enums';

const useStyles = makeStyles((theme) => ({
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      backgroundColor: '#fff',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottom: '1px solid #F7F7FA',
   },
   addOn: {
      padding: 10,
   },
   extras: {
      fontSize: '1.3rem',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   container: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
      padding: 5,
      paddingRight: 0,
      height: 35,
   },
   oneMonthPass: {
      color: '#97A8BF',
      fontWeight: 'bold',
      fontSize: '0.8rem',
      marginTop: -5,
   },
   piece: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      fontSize: '1.4rem',
   },
   price: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      fontSize: '2rem',
      marginRight: 5,
   },
   topHeaderContainer: {
      marginTop: 20,
      marginBottom: 20,
      padding: 10,
      paddingRight: 0,
      paddingLeft: 0,
      borderBottom: '2px solid ' + theme.chatApp.general.pallet.passiveStructureBlue,
   },

   topHeaderTitle: {
      fontSize: '1.3rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   headerHint: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      marginRight: 10,
   },
   tglBtnContainer: {
      [theme.breakpoints.down('sm')]: {
         margin: '15px 0',
      },
   },
   toggleBtnActive: {
      fontWeight: 'bold',
      backgroundColor: theme.chatApp.general.pallet.passiveStructureAzure,
      '&:hover': {
         backgroundColor: theme.chatApp.general.pallet.passiveStructureAzure,
      },
      margin: 0,
      color: '#fff',
      borderRadius: 8,
      // width: 60,
      fontSize: '0.9rem',
   },
   toggleBtnPassive: {
      backgroundColor: '#fff',
      // width: 70,
      // height: 50,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      '&:hover': {
         backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      },
      margin: 0,
      borderRadius: 8,
      // width: 60,
      fontSize: '0.9rem',
   },
   toggleBtnContainer: {
      padding: '6px !important',
      // borderRadius: 8,
   },
   modalDiv: {
      [theme.breakpoints.down('sm')]: {
         overflow: 'auto',
         height: '100%',
      },
   },
}));

const AllPackages = ({ width }) => {
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const dispatch = useDispatch();
   const customer = useSelector((state) => state.personReducer.person.customer);
   const customerPeriodMode = customer.package.periodMode === 0 ? RenewalType.MONTHLY : RenewalType.YEARLY;
   const [state, setState] = useState({
      period: customerPeriodMode,
      paymentModal: false,
      treeDpaymentModal: false,
      product: null,
      html: null,
      tmpCustomerProduct: null, // transaction id
      addOnBalanceCount: { label: 100, value: 100 },
      addOnUserPackagesCount: { label: 1, value: 1 }, // add on user
      addOnActiveCustomerPackagesCount: { label: 100, value: 100 }, // add on active customer
      addOnWhatsappBusinessCount: { label: 1, value: 1 }, // add on whatsapp business count
      paymentModalMode: 'Main',
      openChangeMainPackageModal: false,
   });
   const classes = useStyles({ ...state });
   const history = useHistory();
   const token = useSelector((rootReducer) => rootReducer.personReducer.token);
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);

   useEffect(() => {
      if (person.type < 1) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div
                  style={{
                     color: '#000',
                     fontSize: '13px',
                     cursor: 'pointer',
                     fontWeight: '500',
                  }}
                  onClick={() => closeSnackbar(key)}
               >
                  {' '}
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, history, closeSnackbar, enqueueSnackbar]);

   const { loading: getProductsLoading, data: productsData } = useQuery(GET_PRODUCTS_QUERY, {
      fetchPolicy: 'cache-first',
      variables: {
         customerId: customer._id,
      },
      onError: (error) => {
         // on query error
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div
                  style={{
                     color: '#000',
                     fontSize: '13px',
                     cursor: 'pointer',
                     fontWeight: '500',
                  }}
                  onClick={() => closeSnackbar(key)}
               >
                  {' '}
                  Kapat
               </div>
            ),
         });
      },
   });

   const onBuy = async (product, buyMode) => {
      let error;
      if (product.type === 0) {
         if (person.customer.usagePersonCount > product.personCount) {
            error = true;
            enqueueSnackbar(
               `Bu pakete geçiş yapamazsınız. Önce ${
                  person.customer.usagePersonCount - product.personCount
               } adet personel silmelisiniz.`,
               {
                  // send error message
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                        Kapat
                     </div>
                  ),
               },
            );
         }
         if (person.customer.usageWhatsappWebCount > product.whatsappWebCount) {
            error = true;
            enqueueSnackbar(
               `Bu pakete geçiş yapamazsınız. Önce ${
                  person.customer.usageWhatsappWebCount - product.whatsappWebCount
               } adet whatsapp web telefonu silmelisiniz.`,
               {
                  // send error message
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                        {' '}
                        Kapat
                     </div>
                  ),
               },
            );
         }
         if (person.customer.usageWhatsappBusinessCount > product.whatsappBusinessCount) {
            error = true;
            enqueueSnackbar(
               `Bu pakete geçiş yapamazsınız. Önce ${
                  person.customer.usageWhatsappBusinessCount - product.whatsappBusinessCount
               } adet whatsapp business telefonu silmelisiniz.`,
               {
                  // send error message
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                        {' '}
                        Kapat
                     </div>
                  ),
               },
            );
         }
         if (person.customer.usageFacebookPageCount > product.facebookPageCount) {
            error = true;
            enqueueSnackbar(
               `Bu pakete geçiş yapamazsınız. Önce ${
                  person.customer.usageFacebookPageCount - product.facebookPageCount
               } adet facebook sayfası silmelisiniz.`,
               {
                  // send error message
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                        {' '}
                        Kapat
                     </div>
                  ),
               },
            );
         }
         if (person.customer.usageInstagramCount > product.instagramCount) {
            error = true;
            enqueueSnackbar(
               `Bu pakete geçiş yapamazsınız. Önce ${
                  person.customer.usageInstagramCount - product.instagramCount
               } adet instagram sayfası silmelisiniz.`,
               {
                  // send error message
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                        {' '}
                        Kapat
                     </div>
                  ),
               },
            );
         }
         if (person.customer.usageTwitterCount > product.twitterCount) {
            error = true;
            enqueueSnackbar(
               `Bu pakete geçiş yapamazsınız. Önce ${
                  person.customer.usageTwitterCount - product.twitterCount
               } adet twitter hesabı silmelisiniz.`,
               {
                  // send error message
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div onClick={() => closeSnackbar(key)} className={'snackbar-error'}>
                        {' '}
                        Kapat
                     </div>
                  ),
               },
            );
         }
      }

      if (!error)
         setState((prevState) => ({
            ...prevState,
            product,
            buyMode,
            openChangeMainPackageModal: true,
         }));
   };

   const { data: addOnPackagesData, loading: addOnPackagesLoading } = useQuery(GET_ADD_ON_PACKAGES_QUERY, {
      onError: (error) => {
         // on query error
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });
   const onCloseBuy = () => {
      // Reset State
      setState((prevState) => ({ ...prevState, paymentModal: false }));
   };

   const [buyAddOn, { loading: buyAddOnLoading }] = useMutation(BUY_CUSTOMER_ADD_ON_MUTATION, {
      refetchQueries: [
         {
            query: GET_CUSTOMER_PRODUCT_RENEWAL_PRICE_QUERY,
            variables: {
               customer: person?.customer?._id,
            },
         },
      ],
      update: (
         cache,
         {
            data: {
               buyCustomerProductAddOn: { data },
            },
         },
      ) => {
         dispatch({
            type: UPDATE_ME,
            payload: {
               person: {
                  ...person,
                  customer: {
                     ...person.customer,
                     package: {
                        ...person.customer.package,
                        addOns: data.addOns,
                        totalWhatsappBusinessCount: data.totalWhatsappBusinessCount,
                        totalPersonCount: data.totalPersonCount,
                     },
                  },
               },
            },
         });
      },
   });

   const [changeCustomerProduct, { loading: changeCustomerProductLoading }] = useMutation(
      CHANGE_CUSTOMER_MAIN_PRODUCT_MUTATION,
      {
         refetchQueries: [
            {
               query: GET_CUSTOMER_PRODUCT_RENEWAL_PRICE_QUERY,
               variables: {
                  customer: person?.customer?._id,
               },
            },
         ],
      },
   );

   const onAccept = async ({
      code,
      quantity,
      cardHolderName,
      cardAlias,
      cardNumber,
      product,
      expireMonth,
      expireYear,
      cvc,
      registerCard,
      selectedCard,
      payment3d,
   }) => {
      // GET Client IP and save To Backend
      const getClientIp = await publicIp.v4({
         fallbackUrls: ['https://ifconfig.co/ip'],
      });

      if (payment3d) {
         // TODO payment 3d
         // paketi tamamla
         // buyAddOn3d({
         //    variables: {
         //       customer: person.customer._id,
         //       product,
         //       code,
         //       ip: getClientIp,
         //       cardHolderName,
         //       cardAlias,
         //       cardNumber,
         //       expireMonth,
         //       expireYear,
         //       cvc,
         //       registerCard,
         //       cardUserKey: selectedCard ? selectedCard.cardUserKey : null,
         //       cardToken: selectedCard ? selectedCard.cardToken : null,
         //    },
         // })
         //    .then(
         //       ({
         //          data: {
         //             buyCustomerProduct3d: { data: buyCustomerProduct3d },
         //          },
         //       }) => {
         //          setState((prevState) => ({
         //             ...prevState,
         //             paymentModal: false,
         //             treeDpaymentModal: true,
         //             html: buyCustomerProduct3d.html,
         //             tmpCustomerProduct: buyCustomerProduct3d.tmpCustomerProduct,
         //          }));
         //       }
         //    )
         //    .catch((error) => {
         //       enqueueSnackbar(error.message, {
         //          // send error message
         //          variant: 'error',
         //          persist: true,
         //          action: (key) => (
         //             <div
         //                style={{ color: '#000', fontSize: '13px', cursor: 'pointer', fontWeight: '500' }}
         //                onClick={() => closeSnackbar(key)}
         //             >
         //                {' '}
         //                Kapat
         //             </div>
         //          ),
         //       });
         //    });
      } else {
         // paketi tamamla
         buyAddOn({
            variables: {
               customerProductId: person.customer.package._id,
               addOnId: product,
               quantity,
               ip: getClientIp,
               cardHolderName,
               cardAlias,
               cardNumber,
               expireMonth,
               expireYear,
               cvc,
               registerCard,
               cardUserKey: selectedCard ? selectedCard.cardUserKey : null,
               cardToken: selectedCard ? selectedCard.cardToken : null,
               code,
            },
         })
            .then(
               ({
                  data: {
                     buyCustomerProductAddOn: { data },
                  },
               }) => {
                  enqueueSnackbar(`Ürünü başarılı bir şekilde satın aldınız.`, {
                     // send success message
                     variant: 'success',
                     autoHideDuration: 2000,
                  });

                  setState((prevState) => ({
                     ...prevState,
                     paymentModal: false,
                  }));
               },
            )
            .catch((error) => {
               enqueueSnackbar(error.message, {
                  // send error message
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                        Kapat
                     </div>
                  ),
               });
            });
      }
   };

   const handleAddOnClickOn = (mode, item) => {
      //TODO
      setState((prev) => ({
         ...prev,
         paymentModal: true,
         paymentModalMode: mode,
         product: item,
         buyMode: customerPeriodMode,
      }));
   };

   useSubscription(COMPLATE_3D_CUSTOMER_PRODUCT_SUBSCRIPTION, {
      variables: { token, tmpCustomerProduct: state.tmpCustomerProduct },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               complate3DCustomerProduct: { data: complate3DCustomerProduct },
            },
         },
      }) => {
         if (state.tmpCustomerProduct === complate3DCustomerProduct.tmpCustomerProduct) {
            // gelen bildirim bu modal ise
            if (complate3DCustomerProduct.status) {
               // işlem başarılıysa
               enqueueSnackbar(complate3DCustomerProduct.message, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            } else {
               // işlem başarısızsa
               enqueueSnackbar(complate3DCustomerProduct.message, {
                  // send error message
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                        {' '}
                        Kapat
                     </div>
                  ),
               });
            }
            setState((state) => ({
               ...state,
               treeDpaymentModal: false,
               html: null,
               tmpCustomerProduct: null,
            }));
         }
      },
   });

   useSubscription(CREATED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION, {
      variables: {
         token,
         customer: person.customer._id,
      },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               createdCustomerSpecialPackage: { data: newData },
            },
         },
      }) => {
         const {
            products: { data },
         } = client.readQuery({
            query: GET_PRODUCTS_QUERY,
            variables: {
               customerId: person.customer._id,
            },
         });

         client.writeQuery({
            query: GET_PRODUCTS_QUERY,
            variables: {
               customerId: person.customer._id,
            },
            data: {
               products: {
                  data: [...data, newData],
               },
            },
         });
      },
   });

   useSubscription(UPDATED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION, {
      variables: {
         token,
         customer: person.customer._id,
      },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               updatedCustomerSpecialPackage: { data: newData },
            },
         },
      }) => {
         const {
            products: { data: cacheData },
         } = client.readQuery({
            query: GET_PRODUCTS_QUERY,
            variables: {
               customerId: person.customer._id,
            },
         });
         const newCacheData = cacheData.map((product) => (product.name === newData.name ? { ...newData } : product));
         client.writeQuery({
            query: GET_PRODUCTS_QUERY,
            variables: {
               customerId: person.customer._id,
            },
            data: {
               products: {
                  data: newCacheData,
               },
            },
         });
      },
   });

   useSubscription(DELETED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION, {
      variables: {
         token,
         customer: person.customer._id,
      },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               deletedCustomerSpecialPackage: { data: newData },
            },
         },
      }) => {
         const {
            products: { data: cacheData },
         } = client.readQuery({
            query: GET_PRODUCTS_QUERY,
            variables: {
               customerId: person.customer._id,
            },
         });

         client.writeQuery({
            query: GET_PRODUCTS_QUERY,
            variables: {
               customerId: person.customer._id,
            },
            data: {
               products: {
                  data: cacheData.filter((product) => product.name === newData.name),
               },
            },
         });
      },
   });

   const onCloseMainPackageModal = () => {
      setState((prevState) => ({
         ...prevState,
         openChangeMainPackageModal: false,
      }));
   };

   // Change customer's Main  Package
   const onAcceptChangeMainPackage = ({ productId, code, periodMode, customerId }) => {
      changeCustomerProduct({
         variables: {
            customerId,
            productId,
            code,
            activeRenewalPeriodMode: periodMode,
         },
      })
         .then(
            ({
               data: {
                  changeCustomerProduct: { data },
               },
            }) => {
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        customer: {
                           ...person.customer,
                           package: {
                              ...data,
                           },
                        },
                     },
                  },
               });

               if (person.customer.customerFromChannel === 'SHOPIFY' && data?.paymentFromOtherChannel?.confirmationUrl)
                  window.open(data.paymentFromOtherChannel.confirmationUrl, '_blank');
               else {
                  enqueueSnackbar(
                     'Paketiniz başarıyla güncellendi. Yeni fatura döneminde yeni paket üzerinden ücretlendirileceksiniz.',
                     {
                        variant: 'success',
                        autoHideDuration: 2000,
                     },
                  );
               }
               history.push('/account/packages/my-package');
            },
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snacbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const loadingOrFetching =
      getProductsLoading || addOnPackagesLoading || buyAddOnLoading || changeCustomerProductLoading;

   if (loadingOrFetching) {
      return <div className='loading' />;
   }

   return (
      <Fragment>
         <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
            {width >= 1280 && (
               <Grid item>
                  <Typography style={{ marginLeft: 10, fontSize: '1.1rem', fontWeight: 'bold' }}>
                     {t?.comp_sidebar_all_factors}
                  </Typography>
               </Grid>
            )}
         </Grid>
         {/* </AppBar> */}
         <Grid container direction='column' style={{ paddingRight: 30, paddingLeft: 30 }}>
            <Grid container justifyContent='space-between'>
               <Grid
                  justifyContent='space-between'
                  container
                  alignItems='center'
                  className={classes.topHeaderContainer}
               >
                  <Grid>
                     <Typography className={classes.topHeaderTitle}>{t?.main_packages}</Typography>
                  </Grid>
                  <Grid>
                     <Grid container alignItems='center' className={classes.tglBtnContainer}>
                        <ToggleButtonGroup
                           value={state.period}
                           exclusive
                           onChange={(_, value) =>
                              state.period !== value && value !== null
                                 ? setState((prevState) => ({
                                      ...prevState,
                                      period: value,
                                   }))
                                 : {}
                           }
                           aria-label='text alignment'
                        >
                           <ToggleButton
                              style={{ background: 'transparent' }}
                              value={RenewalType.YEARLY}
                              selected={state.period === RenewalType.YEARLY}
                              disableRipple
                              classes={{
                                 root: classes.toggleBtnContainer,
                              }}
                           >
                              <Button
                                 className={
                                    state.period === RenewalType.YEARLY
                                       ? classes.toggleBtnActive
                                       : classes.toggleBtnPassive
                                 }
                                 disableRipple
                                 size='small'
                              >
                                 {t?.yearly}
                              </Button>
                           </ToggleButton>
                           <ToggleButton
                              value={RenewalType.MONTHLY}
                              selected={state.period === RenewalType.MONTHLY}
                              style={{ background: 'transparent' }}
                              disableRipple
                              classes={{
                                 root: classes.toggleBtnContainer,
                              }}
                           >
                              <Button
                                 className={
                                    state.period === RenewalType.MONTHLY
                                       ? classes.toggleBtnActive
                                       : classes.toggleBtnPassive
                                 }
                                 size='small'
                                 disableRipple
                              >
                                 {t?.monthly}
                              </Button>
                           </ToggleButton>
                        </ToggleButtonGroup>
                     </Grid>
                  </Grid>
                  {state.period === RenewalType.YEARLY && (
                     <Slide direction='left' in={state.period === RenewalType.YEARLY}>
                        <Grid container style={{ marginTop: 10 }} xs={12} justifyContent='flex-end'>
                           <Typography className={classes.headerHint}> {t?.yearly_payment_discount} </Typography>
                        </Grid>
                     </Slide>
                  )}
               </Grid>
            </Grid>
            <Packages width={width} mode={state.period} products={productsData?.products?.data} onBuy={onBuy} />
            <Grid
               justifyContent='flex-start'
               container
               style={{
                  marginTop: 25,
                  display: customer.customerFromChannel && customer.customerFromChannel === 'SHOPIFY' ? 'none' : null,
               }}
            >
               <Typography className={classes.topHeaderTitle}>{t?.additional_packages}</Typography>
               <Divider />
               <Grid container xs='12' style={{ marginBottom: 50 }}>
                  {addOnPackagesData?.allAddOns?.data?.map((item) => {
                     return (
                        <Grid md='6' xs='12' lg='4' className={classes.addOn}>
                           <AddOnPackageItem
                              onChangeCount={(option, item) => {
                                 if (item === 'User') {
                                    setState((prev) => ({
                                       ...prev,
                                       addOnUserPackagesCount: option,
                                    }));
                                 } else if (item === 'Customer') {
                                    setState((prev) => ({
                                       ...prev,
                                       addOnActiveCustomerPackagesCount: option,
                                    }));
                                 } else if (item === 'Balance') {
                                    setState((prev) => ({
                                       ...prev,
                                       addOnBalanceCount: option,
                                    }));
                                 } else {
                                    setState((prev) => ({
                                       ...prev,
                                       addOnWhatsappBusinessCount: option,
                                    }));
                                 }
                              }}
                              customer={person.customer}
                              item={item}
                              onClickBuy={handleAddOnClickOn}
                           />
                        </Grid>
                     );
                  })}
               </Grid>
            </Grid>
            {/* Add On Payment Modal */}
            <Modal open={state.paymentModal}>
               <div className={classes.modalDiv}>
                  <DoPaymentModal
                     person={person}
                     product={state.product}
                     onAccept={onAccept}
                     buyMode={state.period}
                     onClose={onCloseBuy}
                     mode={state.paymentModalMode}
                     addOnPackagesCount={
                        state.paymentModalMode === 'Customer'
                           ? state.addOnActiveCustomerPackagesCount.value
                           : state.paymentModalMode === 'Balance'
                           ? state.addOnBalanceCount.value
                           : state.paymentModalMode === 'User'
                           ? state.addOnUserPackagesCount.value
                           : state.paymentModalMode === 'Number'
                           ? state.addOnWhatsappBusinessCount.value
                           : undefined
                     }
                  />
               </div>
            </Modal>

            {/* Change Main Package Modal */}
            <Modal open={state.openChangeMainPackageModal} modalHeader={t?.change_main_package}>
               <ChangeMainPackageModal
                  person={person}
                  product={state.product}
                  onAccept={onAcceptChangeMainPackage}
                  buyMode={state.period}
                  onClose={onCloseMainPackageModal}
               />
            </Modal>
            <Modal
               open={state.treeDpaymentModal}
               onClose={() => setState((state) => ({ ...state, treeDpaymentModal: false }))}
            >
               <div style={{ width: '1000px', height: '700px', overflow: 'scroll' }}>
                  <iframe
                     style={{ width: '100%', height: '100%' }}
                     title='test'
                     sandbox='allow-same-origin allow-forms allow-scripts'
                     srcDoc={state.html ? atob(state.html) : <div />}
                  />
               </div>
            </Modal>
         </Grid>
      </Fragment>
   );
};

export default AllPackages;

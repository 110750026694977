import React, { useContext, useEffect, useState } from 'react';
import { Node } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { FlowBlockFieldTimeoutUnits } from '@modules/Automation/FlowBots/types';
import { LanguageContext } from '@helper/locale/langContext';
import { generateRandomId } from '@helper/functions';
import SingleInput from '@components/_Modal/SingleInput';
import { useForm } from 'react-hook-form';

interface DelayBlocksProps {
   leftNodes: Node[];
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   clickedNodeId: string;
}

const DelayBlocks: React.FC<DelayBlocksProps> = ({ leftNodes, setLeftNodes, clickedNodeId }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [time, setTime] = useState<number>(
      leftNodes.find((node) => node.id === clickedNodeId)?.data.flowBlocks.delayTime,
   );
   const [unit, setUnit] = useState<string>();

   const [defaultUnit, setDefaultUnit] = useState<string | null>(null);
   const [searchSelectKey, setSearchSelectKey] = useState<number>(0);
   const [block, setBlock] = useState<{ _id: string; time: number; unit: string }[]>([]);

   useEffect(() => {
      const clickedNode = leftNodes.find((node) => node.id === clickedNodeId);
      setTime(clickedNode?.data.flowBlocks.delayTime);
      setUnit(clickedNode?.data.flowBlocks.delayTimeUnit);

      setBlock([
         {
            _id: generateRandomId(),
            time: clickedNode?.data.flowBlocks.delayTime,
            unit: clickedNode?.data.flowBlocks.delayTimeUnit,
         },
      ]);
      setValue('timeNumber', clickedNode?.data.flowBlocks.delayTime);
   }, [clickedNodeId]);

   const OPTIONS =
      clickedNodeId === '5-second'
         ? [
              {
                 value: FlowBlockFieldTimeoutUnits.Hours,
                 label: time && time > 1 ? t.atomation_flow_hours : t.atomation_flow_hour,
              },
              {
                 value: FlowBlockFieldTimeoutUnits.Days,
                 label: time && time > 1 ? t.atomation_flow_days : t.atomation_flow_day,
              },
           ]
         : [
              {
                 value: FlowBlockFieldTimeoutUnits.Minutes,
                 label: time && time > 1 ? t.atomation_flow_minutes : t.atomation_flow_minute,
              },
              {
                 value: FlowBlockFieldTimeoutUnits.Hours,
                 label: time && time > 1 ? t.atomation_flow_hours : t.atomation_flow_hour,
              },
              {
                 value: FlowBlockFieldTimeoutUnits.Days,
                 label: time && time > 1 ? t.atomation_flow_days : t.atomation_flow_day,
              },
           ];

   const useStyles = makeStyles((theme) => ({
      textInput: {
         marginTop: '20px',
         width: '80%',
      },
   }));

   const classes = useStyles();

   const singularPlurial = (unit: string | undefined, time: number) => {
      if (unit === 'DAYS') {
         setDefaultUnit(time && time > 1 ? t.atomation_flow_days : t.atomation_flow_day);
      } else if (unit === 'MINUTES') {
         setDefaultUnit(time && time > 1 ? t.atomation_flow_minutes : t.atomation_flow_minute);
      } else if (unit === 'HOURS') {
         setDefaultUnit(time && time > 1 ? t.atomation_flow_hours : t.atomation_flow_hour);
      } else {
         setDefaultUnit(null);
      }

      setValue('unit', {
         value: unit,
         label: defaultUnit,
      });
   };

   useEffect(() => {
      singularPlurial(unit, time);
      setSearchSelectKey((prevKey) => prevKey + 1);
   }, [time, unit, defaultUnit]);

   const { control, watch, setValue, getValues, trigger } = useForm({
      mode: 'all',
   });

   let wTime = watch('timeNumber');
   useEffect(() => {
      const numericTime = parseInt(wTime || '0', 10);

      if (!isNaN(numericTime)) {
         setLeftNodes((prevNodes) => {
            return prevNodes.map((node) => {
               if (node.id === clickedNodeId) {
                  const updatedData = {
                     ...node.data,
                     flowBlocks: {
                        ...node.data.flowBlocks,
                        delayTime: numericTime,
                     },
                  };

                  return {
                     ...node,
                     data: updatedData,
                  };
               }

               return node;
            });
         });
      }
   }, [wTime]);

   useEffect(() => {
      trigger('timeNumber');
   }, [watch('unit')]);

   let wUnit = watch('unit');
   useEffect(() => {
      setLeftNodes((prevNodes) => {
         return prevNodes.map((node) => {
            if (node.id === clickedNodeId) {
               const updatedData = {
                  ...node.data,
                  flowBlocks: {
                     ...node.data.flowBlocks,
                     delayTimeUnit: wUnit?.value,
                  },
               };

               return {
                  ...node,
                  data: updatedData,
               };
            }
            return node;
         });
      });
   }, [wUnit]);

   return (
      <Grid>
         <Typography>{t.atomation_flow_bot_delay_enter_time}</Typography>
         {block.map((item, index) => (
            <div
               style={{
                  display: 'flex',
                  gap: '0.5rem',
                  width: '100%',
                  marginTop: '-10px',
               }}
               key={item._id}
            >
               <SingleInput
                  name='timeNumber'
                  placeholder='0'
                  control={control}
                  type='number'
                  rules={{
                     required: true,
                     max: watch('unit').value === 'MINUTES' ? 59 : watch('unit').value === 'HOURS' ? 23 : 31,
                     min: watch('unit').value === 'MINUTES' ? 15 : 1,
                  }}
               />

               <SingleInput
                  name='unit'
                  key={searchSelectKey}
                  selectOptions={OPTIONS}
                  control={control}
                  isSelect
                  placeholder={<div>{t.atomation_flow_select}</div>}
               />
            </div>
         ))}
      </Grid>
   );
};

export default DelayBlocks;

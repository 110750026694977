import { FlowNodeType } from '@modules/Automation/FlowBots/types';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ELK from 'elkjs/lib/elk.bundled.js';
import React, { useEffect, useState } from 'react';
import { Edge, Node, useNodesInitialized, useReactFlow, useStoreApi } from 'reactflow';
import 'reactflow/dist/style.css';

export type ElkNodeData = {
   label: string;
   sourceHandles: { id: string; height: number; position: string; width: number; x: number; y: number }[];
   targetHandles: { id: string; height: number; position: string; width: number; x: number; y: number }[];
   connectedNodes: string[];
};

export type ElkNode = Node<ElkNodeData, 'elk'>;

interface LayoutProps {
   handleUpdateFlowNode: (id: string, position: { x: number; y: number }) => void;
   nodeState: Node[];
}

const Layout = ({ handleUpdateFlowNode, nodeState }: LayoutProps) => {
   const store = useStoreApi();
   const { nodeInternals } = store.getState();
   const nodes = Array.from(nodeInternals).map(([, node]) => node);
   const nodesInitialized = useNodesInitialized();
   const [elkNodes, setElkNodes] = useState<Node[]>([]);
   const { getEdges, fitView } = useReactFlow<ElkNodeData>();
   const { setNodes } = useReactFlow();

   interface GroupedTargets {
      source: string;
      targets: string[];
   }

   //Adding sourceHandles and targetHandles to the node state
   useEffect(() => {
      if (nodesInitialized) {
         const updatedNodes = nodes
            .filter((node) => nodeState.some((contentNode) => contentNode.id === node.id))
            .map((node: any) => {
               const symbolprop = Object.getOwnPropertySymbols(node);
               const handleBounds = node[symbolprop[0]].handleBounds;
               const flowBlocks = nodeState.find((contentNode) => contentNode.id === node.id)?.data?.flowBlocks || [];

               // Get all edges
               const edges = getEdges();

               // Get grouped targets
               const groupedTargets: GroupedTargets[] = [];
               edges.forEach((edge) => {
                  const { source, target } = edge;
                  const existingGroup = groupedTargets.find((group) => group.source === source);
                  if (existingGroup) {
                     existingGroup.targets.push(target);
                  } else {
                     groupedTargets.push({ source, targets: [target] });
                  }
               });

               return {
                  ...node,
                  data: {
                     ...node.data,
                     sourceHandles: handleBounds.source,
                     targetHandles: handleBounds.target,
                     flowBlocks: flowBlocks,
                  },
               };
            });

         setElkNodes(updatedNodes);
      }
   }, [nodesInitialized, nodeState]);

   const layoutOptions = {
      'elk.algorithm': 'layered',
      'elk.direction': 'RIGHT',
      'elk.layered.spacing.edgeNodeBetweenLayers': '40',
      'elk.spacing.nodeNode': '40',
      'elk.layered.nodePlacement.strategy': 'INTERACTIVE',
   };
   const elk = new ELK();

   // uses elkjs to give each node a layouted position
   const getLayoutedNodes = async (nodes: ElkNode[], edges: Edge[]) => {
      const graph = {
         id: 'root',
         layoutOptions,
         children: nodes.map((n) => {
            const targetPorts =
               n.data?.targetHandles?.map((t) => ({
                  id: t.id,

                  // ⚠️ it's important to let elk know on which side the port is
                  // in this example targets are on the left (WEST) and sources on the right (EAST)
                  properties: {
                     side: 'WEST',
                  },
               })) || [];

            const sourcePorts =
               n.data?.sourceHandles?.map((s) => ({
                  id: s.id,
                  properties: {
                     side: 'EAST',
                  },
               })) || [];

            return {
               id: n.id,
               width: n.width ?? 150,
               height: n.height ?? 50,
               // ⚠️ we need to tell elk that the ports are fixed, in order to reduce edge crossings
               properties: {
                  'org.eclipse.elk.portConstraints': 'FIXED_ORDER',
               },
               // we are also passing the id, so we can also handle edges without a sourceHandle or targetHandle option
               ports: [{ id: n.id }, ...targetPorts, ...sourcePorts],
            };
         }),
         edges: edges.map((e) => ({
            id: e.id,
            sources: [e.sourceHandle || e.source],
            targets: [e.targetHandle || e.target],
         })),
      };

      const layoutedGraph = await elk.layout(graph);

      const layoutedNodes = nodes.map((node) => {
         const layoutedNode = layoutedGraph.children?.find((lgNode) => lgNode.id === node.id);

         return {
            ...node,
            position: {
               x: layoutedNode?.x ?? 0,
               y: layoutedNode?.y ?? 0,
            },
            expandParent: undefined,
            parentNode: undefined,
         };
      });

      let lNode: Node[] = layoutedNodes;

      const lastLayouted = lNode.filter((node) => !node.id.startsWith('group_') && node.type !== FlowNodeType.Note);

      return lastLayouted;
   };

   const layoutNodes = (nodes: Node[]) => {
      if (nodes) {
         const layoutNodesAsync = async () => {
            const layoutedNodes = await getLayoutedNodes(nodes as ElkNode[], getEdges());

            setNodes((prev) => [...prev, ...layoutedNodes]);

            layoutedNodes.forEach((node) => {
               if (!node.id.startsWith('group_')) {
                  handleUpdateFlowNode(node.id, node.position);
               }
            });

            setTimeout(() => fitView(), 0);
         };

         layoutNodesAsync();
      }

      return null;
   };

   const onLayout = async () => {
      if (elkNodes.length > 0) {
         layoutNodes(elkNodes);
      }
   };

   return (
      <div>
         <AutoFixHighIcon onClick={onLayout} style={{ width: '20px', height: '15px', margin: '2px 1.3px 0 0' }} />
      </div>
   );
};

export default Layout;

import React, { useRef } from 'react';
import { FilledInput, FilledInputProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MyTheme } from '@styles/config';

const useStyles = makeStyles((theme: MyTheme) => ({
   container: {
      height: 38,
      borderRadius: 4,
      backgroundColor: 'white !important',
      color: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid rgba(0, 0, 0, 0.23)',
      '& .MuiFilledInput-input' : {
         padding: '0 16px',
      }
   },
   icon: {
      fontSize: '1.2rem',
      color: (props: any) => (props.value ? theme.chatApp.general.pallet.passiveStructureBlue : '#D3D3D3'),
   },
   iconContainer: {
      height: 38,
      width: 38,
   },
   sortingButton: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      width: 25,
      height: 25,
   },
   sortingText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
}));

interface SearchBarProps extends FilledInputProps {
   fullWidth?: boolean;
   placeholder: string;
   value: string | undefined | null;
   onChange: any;
   style?: any | undefined;
}

const SearchBar: React.FC<SearchBarProps> = ({fullWidth, placeholder, value, onChange, style, ...rest}) => {
   const textInputRef = useRef();
   const classes = useStyles({ value });

   return (
     <FilledInput
       ref={textInputRef}
       placeholder={placeholder}
       value={value}
       onChange={(e) => onChange(e.target.value)}
       disableUnderline
       autoFocus
       fullWidth={fullWidth}
       className={classes.container}
       style={style}
       {...rest}
     />
   );
};

export default SearchBar;

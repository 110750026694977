import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme) => ({
   menuItemHover: {
      '&:hover': {
         backgroundColor: '#FFFFFF',
         color: 'inherit',
      },
      width: '100%',
      height: '40px',
   },
   nested: {
      paddingLeft: theme.spacing(4),
   },
   active: {
      backgroundColor: '#FFFFFF',
   },
}));

interface SubMenuItem {
   title: string;
   url: string;
   icon: React.ReactNode;
}

interface MenuItem {
   title: string;
   clickable?: boolean;
   url?: string;
   icon: React.ReactNode;
   items?: SubMenuItem[];
   type: string;
}

interface SettingsMenuProps {
   menuItems: MenuItem[];
   setShowEcommerce: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuItemComponent: React.FC<{
   item: MenuItem;
   isActive: boolean;
   onItemClick: (title: string, type: string) => void;
}> = ({ item, isActive, onItemClick }) => {
   const classes = useStyles();
   const [open, setOpen] = useState(false);

   const handleCollapseClick = () => {
      setOpen(!open);
   };

   return (
      <>
         <ListItem
            className={`${classes.menuItemHover} ${isActive ? classes.active : ''}`}
            onClick={item.clickable ? () => onItemClick(item.title, item.type) : handleCollapseClick}
         >
            <div style={{ display: 'flex', gap: '0.5rem', cursor: 'pointer' }}>
               <ListItemText primary={item.title} />
            </div>
         </ListItem>
         <Collapse in={open} timeout='auto' unmountOnExit>
            <List component='div' disablePadding>
               {item.items?.map((subItem) => (
                  <ListItem
                     className={`${classes.menuItemHover} ${classes.nested}`}
                     key={subItem.title}
                     button
                     component={Link}
                     to={subItem.url}
                  >
                     <div style={{ display: 'flex', gap: '0.5rem', paddingTop: '7px' }}>
                        <div>{subItem.icon}</div>
                        <ListItemText primary={subItem.title} />
                     </div>
                  </ListItem>
               ))}
            </List>
         </Collapse>
      </>
   );
};

const MenuSettings: React.FC<SettingsMenuProps> = ({ menuItems, setShowEcommerce }) => {
   const history = useHistory();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [activeItem, setActiveItem] = useState<string | null>(t.atomation_flow_bot);

   const handleItemClick = (title: string, type: string) => {
      if (type === 'e-commerce') {
         history.push('/automation/e-commerce/');
      } else if (type === 'flow-bot') {
         history.push('/automation/flow-bots/');
      }
   };

   useEffect(() => {
      var currentURL = window.location.href;
      if (currentURL.includes('e-commerce')) {
         setActiveItem(t.automation_e_commerce);
      } else {
         setActiveItem(t.atomation_flow_bot);
      }
   }, [window.location.href]);

   return (
      <List>
         {menuItems.map((item) => (
            <React.Fragment key={item.title}>
               <MenuItemComponent item={item} isActive={activeItem === item.title} onItemClick={handleItemClick} />
            </React.Fragment>
         ))}
      </List>
   );
};

export default MenuSettings;

import React, { useContext, useEffect } from 'react';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';
import { Grid } from '@material-ui/core';

import { useForm } from 'react-hook-form';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import { useSelector } from 'react-redux';
import Input from '../../../../../components/InputField';

const TranslationForm = ({ languageCode, setReset }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { liveChatPreviewSettings, updateLiveChatPreviewSettings } = useContext(LiveChatSettingsContext);
   const { translation } = liveChatPreviewSettings;
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const defaultLanguageCode = person.customer.language.toLowerCase();

   const { control, watch, reset } = useForm({
      mode: 'all',
      defaultValues: {
         greetingTitle:
            liveChatPreviewSettings.translation[languageCode]?.greetingTitle || liveChatPreviewSettings.greetingTitle,
         greetingContent:
            liveChatPreviewSettings.translation[languageCode]?.greetingContent ||
            liveChatPreviewSettings.greetingContent,
         newChatTitle:
            liveChatPreviewSettings.translation[languageCode]?.newChatTitle || liveChatPreviewSettings.newChatTitle,
         newChatSubtitle:
            liveChatPreviewSettings.translation[languageCode]?.newChatSubtitle ||
            liveChatPreviewSettings.newChatSubtitle,
         titleOldChat:
            liveChatPreviewSettings.translation[languageCode]?.titleOldChat || liveChatPreviewSettings.titleOldChat,
         greetingMessage1:
            liveChatPreviewSettings.translation[languageCode]?.greetingMessage1 ||
            liveChatPreviewSettings.greetingMessage1,
         welcomeMessageDuringWorkingHoursMessage1:
            liveChatPreviewSettings.translation[languageCode]?.welcomeMessageDuringWorkingHoursMessage1 ||
            liveChatPreviewSettings.welcomeMessageDuringWorkingHoursMessage1,
         welcomeMessageOutsideOfWorkingHoursMessage1:
            liveChatPreviewSettings.translation[languageCode]?.welcomeMessageOutsideOfWorkingHoursMessage1 ||
            liveChatPreviewSettings.welcomeMessageOutsideOfWorkingHoursMessage1,
         contactInformationTitle:
            liveChatPreviewSettings.translation[languageCode]?.contactInformationTitle ||
            liveChatPreviewSettings.contactInformationTitle,
         contactInformationMessage:
            liveChatPreviewSettings.translation[languageCode]?.contactInformationMessage ||
            liveChatPreviewSettings.contactInformationMessage,
         contactInformationThanksMessage:
            liveChatPreviewSettings.translation[languageCode]?.contactInformationThanksMessage ||
            liveChatPreviewSettings.contactInformationThanksMessage,
         rateUsTitle:
            liveChatPreviewSettings.translation[languageCode]?.rateUsTitle || liveChatPreviewSettings.rateUsTitle,
         rateUsMessage:
            liveChatPreviewSettings.translation[languageCode]?.rateUsMessage || liveChatPreviewSettings.rateUsMessage,
         rateUsThanksMessage:
            liveChatPreviewSettings.translation[languageCode]?.rateUsThanksMessage ||
            liveChatPreviewSettings.rateUsThanksMessage,
         whatsappButtonTitle:
            liveChatPreviewSettings.translation[languageCode]?.whatsappButtonTitle ||
            liveChatPreviewSettings.whatsappButtonTitle,
         whatsappTitle:
            liveChatPreviewSettings.translation[languageCode]?.whatsappTitle || liveChatPreviewSettings.whatsappTitle,
         whatsappContent:
            liveChatPreviewSettings.translation[languageCode]?.whatsappContent ||
            liveChatPreviewSettings.whatsappContent,
         facebookButtonTitle:
            liveChatPreviewSettings.translation[languageCode]?.facebookButtonTitle ||
            liveChatPreviewSettings.facebookButtonTitle,
         facebookTitle:
            liveChatPreviewSettings.translation[languageCode]?.facebookTitle || liveChatPreviewSettings.facebookTitle,
         facebookContent:
            liveChatPreviewSettings.translation[languageCode]?.facebookContent ||
            liveChatPreviewSettings.facebookContent,
         instagramButtonTitle:
            liveChatPreviewSettings.translation[languageCode]?.instagramButtonTitle ||
            liveChatPreviewSettings.instagramButtonTitle,
         instagramTitle:
            liveChatPreviewSettings.translation[languageCode]?.instagramTitle || liveChatPreviewSettings.instagramTitle,
         instagramContent:
            liveChatPreviewSettings.translation[languageCode]?.instagramContent ||
            liveChatPreviewSettings.instagramContent,
         phoneCallButtonTitle:
            liveChatPreviewSettings.translation[languageCode]?.phoneCallButtonTitle ||
            liveChatPreviewSettings.phoneCallButtonTitle,
         phoneCallTitle:
            liveChatPreviewSettings.translation[languageCode]?.phoneCallTitle || liveChatPreviewSettings.phoneCallTitle,
         phoneCallContent:
            liveChatPreviewSettings.translation[languageCode]?.phoneCallContent ||
            liveChatPreviewSettings.phoneCallContent,
         sendButtonLabel:
            liveChatPreviewSettings.translation[languageCode]?.sendButtonLabel ||
            t['page_settings_typo_platforms_live_chat_languages_default_sendButtonLabel'],
         seeAllYourConversationsButtonText:
            liveChatPreviewSettings.translation[languageCode]?.seeAllYourConversationsButtonText ||
            t['page_settings_typo_platforms_live_chat_languages_default_seeAllYourConversationsButtonText'],
         typeYourMessageHerePlaceholderText:
            liveChatPreviewSettings.translation[languageCode]?.typeYourMessageHerePlaceholderText ||
            t['page_settings_typo_platforms_live_chat_languages_default_typeYourMessageHerePlaceholderText'],
         online:
            liveChatPreviewSettings.translation[languageCode]?.online ||
            t['page_settings_typo_platforms_live_chat_languages_default_online'],
         offline:
            liveChatPreviewSettings.translation[languageCode]?.offline ||
            t['page_settings_typo_platforms_live_chat_languages_default_offline'],
         contactInformationFullNamePlaceholderText:
            liveChatPreviewSettings.translation[languageCode]?.contactInformationFullNamePlaceholderText ||
            t['page_settings_typo_platforms_live_chat_languages_default_contactInformationFullNamePlaceholderText'],
         contactInformationEmailAddressPlaceholderText:
            liveChatPreviewSettings.translation[languageCode]?.contactInformationEmailAddressPlaceholderText ||
            t['page_settings_typo_platforms_live_chat_languages_default_contactInformationEmailAddressPlaceholderText'],
         contactInformationPhoneNumberPlaceholderText:
            liveChatPreviewSettings.translation[languageCode]?.contactInformationPhoneNumberPlaceholderText ||
            t['page_settings_typo_platforms_live_chat_languages_default_contactInformationPhoneNumberPlaceholderText'],
         contactInformationAddressPlaceholderText:
            liveChatPreviewSettings.translation[languageCode]?.contactInformationAddressPlaceholderText ||
            t['page_settings_typo_platforms_live_chat_languages_default_contactInformationAddressPlaceholderText'],
         contactDetailsText:
            liveChatPreviewSettings.translation[languageCode]?.contactDetailsText ||
            t['page_settings_typo_platforms_live_chat_languages_default_contactDetailsText'],
         writeAReviewPlaceholderText:
            liveChatPreviewSettings.translation[languageCode]?.writeAReviewPlaceholderText ||
            t['page_settings_typo_platforms_live_chat_languages_default_writeAReviewPlaceholderText'],
         yourFeedbackText:
            liveChatPreviewSettings.translation[languageCode]?.yourFeedbackText ||
            t['page_settings_typo_platforms_live_chat_languages_default_yourFeedbackText'],
         positive:
            liveChatPreviewSettings.translation[languageCode]?.positive ||
            t['page_settings_typo_platforms_live_chat_languages_default_positive'],
         negative:
            liveChatPreviewSettings.translation[languageCode]?.negative ||
            t['page_settings_typo_platforms_live_chat_languages_default_negative'],
         channelsText:
            liveChatPreviewSettings.translation[languageCode]?.channelsText ||
            t['page_settings_typo_platforms_live_chat_languages_default_channelsText'],
         continueConversation:
            liveChatPreviewSettings.translation[languageCode]?.continueConversation ||
            t['page_settings_typo_platforms_live_chat_languages_default_continueConversation'],
         openANewChat:
            liveChatPreviewSettings.translation[languageCode]?.openANewChat ||
            t['page_settings_typo_platforms_live_chat_languages_default_openANewChat'],
         chatWithUs:
            liveChatPreviewSettings.translation[languageCode]?.chatWithUs ||
            t['page_settings_typo_platforms_live_chat_languages_default_chatWithUs'],
         you:
            liveChatPreviewSettings.translation[languageCode]?.you ||
            t['page_settings_typo_platforms_live_chat_languages_default_you'],
         youSentAMedia:
            liveChatPreviewSettings.translation[languageCode]?.youSentAMedia ||
            t['page_settings_typo_platforms_live_chat_languages_default_youSentAMedia'],
         youReceivedAMedia:
            liveChatPreviewSettings.translation[languageCode]?.youReceivedAMedia ||
            t['page_settings_typo_platforms_live_chat_languages_default_youReceivedAMedia'],
         sending:
            liveChatPreviewSettings.translation[languageCode]?.sending ||
            t['page_settings_typo_platforms_live_chat_languages_default_sending'],
         emptyChat:
            liveChatPreviewSettings.translation[languageCode]?.emptyChat ||
            t['page_settings_typo_platforms_live_chat_languages_default_emptyChat'],
         endedConversation:
            liveChatPreviewSettings.translation[languageCode]?.endedConversation ||
            t['page_settings_typo_platforms_live_chat_languages_default_endedConversation'],
         closed:
            liveChatPreviewSettings.translation[languageCode]?.closed ||
            t['page_settings_typo_platforms_live_chat_languages_default_closed'],
         liveChatIconLabel:
            liveChatPreviewSettings.translation[languageCode]?.liveChatIconLabel ||
            t['page_settings_typo_platforms_live_chat_languages_default_liveChatIconLabel'],

         contactInformationPermissionContent:
            liveChatPreviewSettings.translation[languageCode]?.contactInformationPermissionContent ||
            t['page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_permissionContent'],

         contactInformationPermissionLinkText:
            liveChatPreviewSettings.translation[languageCode]
               ?.contactInformationPermissionLinkText ||
            t[
               'page_settings_typo_platforms_live_chat_get_started_form_contact_information_thanks_permissionLinkText'
            ],
         satisfied:
            liveChatPreviewSettings.translation[languageCode]?.satisfied ||
            t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_default_thanks_satisfied'],
         dissatisfied:
            liveChatPreviewSettings.translation[languageCode]?.dissatisfied ||
            t[
               'page_settings_typo_platforms_live_chat_get_started_form_rate_us_default_thanks_dissatisfied'
            ],
         neutral:
            liveChatPreviewSettings.translation[languageCode]?.neutral ||
            t['page_settings_typo_platforms_live_chat_get_started_form_rate_us_default_thanks_neutral'],
         yourFeedback:
            liveChatPreviewSettings.translation[languageCode]?.yourFeedback ||
            t[
               'page_settings_typo_platforms_live_chat_get_started_form_rate_us_default_thanks_yourFeedback'
            ],
      },
   });

   useEffect(() => {
      setReset(() => reset);
   }, []);

   const {
      greetingTitle,
      greetingContent,
      newChatTitle,
      newChatSubtitle,
      titleOldChat,
      greetingMessage1,
      welcomeMessageDuringWorkingHoursMessage1,
      welcomeMessageOutsideOfWorkingHoursMessage1,
      contactInformationTitle,
      contactInformationMessage,
      contactInformationThanksMessage,
      rateUsTitle,
      rateUsMessage,
      rateUsThanksMessage,
      whatsappButtonTitle,
      whatsappTitle,
      whatsappContent,
      facebookButtonTitle,
      facebookTitle,
      facebookContent,
      instagramButtonTitle,
      instagramTitle,
      instagramContent,
      phoneCallButtonTitle,
      phoneCallTitle,
      phoneCallContent,
      sendButtonLabel,
      seeAllYourConversationsButtonText,
      typeYourMessageHerePlaceholderText,
      online,
      offline,
      contactInformationFullNamePlaceholderText,
      contactInformationEmailAddressPlaceholderText,
      contactInformationPhoneNumberPlaceholderText,
      contactInformationAddressPlaceholderText,
      contactDetailsText,
      writeAReviewPlaceholderText,
      yourFeedbackText,
      positive,
      negative,
      channelsText,
      continueConversation,
      openANewChat,
      you,
      youSentAMedia,
      youReceivedAMedia,
      chatWithUs,
      sending,
      emptyChat,
      liveChatIconLabel,
      contactInformationPermissionContent,
      contactInformationPermissionLinkText,
      endedConversation,
      closed,
      satisfied,
      dissatisfied,
      neutral,
      yourFeedback,
   } = watch();

   useEffect(() => {
      updateLiveChatPreviewSettings({
         translation: {
            [languageCode]: {
               ...translation[languageCode],
               greetingTitle,
               greetingContent,
               newChatTitle,
               newChatSubtitle,
               titleOldChat,
               greetingMessage1,
               welcomeMessageDuringWorkingHoursMessage1,
               welcomeMessageOutsideOfWorkingHoursMessage1,
               contactInformationTitle,
               contactInformationMessage,
               contactInformationThanksMessage,
               rateUsTitle,
               rateUsMessage,
               rateUsThanksMessage,
               whatsappButtonTitle,
               whatsappTitle,
               whatsappContent,
               facebookButtonTitle,
               facebookTitle,
               facebookContent,
               instagramButtonTitle,
               instagramTitle,
               instagramContent,
               phoneCallButtonTitle,
               phoneCallTitle,
               phoneCallContent,
               sendButtonLabel,
               seeAllYourConversationsButtonText,
               typeYourMessageHerePlaceholderText,
               online,
               offline,
               contactInformationFullNamePlaceholderText,
               contactInformationEmailAddressPlaceholderText,
               contactInformationPhoneNumberPlaceholderText,
               contactInformationAddressPlaceholderText,
               contactDetailsText,
               writeAReviewPlaceholderText,
               yourFeedbackText,
               positive,
               negative,
               channelsText,
               continueConversation,
               openANewChat,
               you,
               youSentAMedia,
               youReceivedAMedia,
               chatWithUs,
               sending,
               emptyChat,
               liveChatIconLabel,
               contactInformationPermissionContent,
               contactInformationPermissionLinkText,
               endedConversation,
               closed,
               satisfied,
               dissatisfied,
               neutral,
               yourFeedback,
            },
         },
      });
   }, [
      greetingTitle,
      greetingContent,
      newChatTitle,
      newChatSubtitle,
      titleOldChat,
      greetingMessage1,
      welcomeMessageDuringWorkingHoursMessage1,
      welcomeMessageOutsideOfWorkingHoursMessage1,
      contactInformationTitle,
      contactInformationMessage,
      contactInformationThanksMessage,
      rateUsTitle,
      rateUsMessage,
      rateUsThanksMessage,
      whatsappButtonTitle,
      whatsappTitle,
      whatsappContent,
      facebookButtonTitle,
      facebookTitle,
      facebookContent,
      instagramButtonTitle,
      instagramTitle,
      instagramContent,
      phoneCallButtonTitle,
      phoneCallTitle,
      phoneCallContent,
      sendButtonLabel,
      seeAllYourConversationsButtonText,
      typeYourMessageHerePlaceholderText,
      online,
      offline,
      contactInformationFullNamePlaceholderText,
      contactInformationEmailAddressPlaceholderText,
      contactInformationPhoneNumberPlaceholderText,
      contactInformationAddressPlaceholderText,
      contactDetailsText,
      writeAReviewPlaceholderText,
      yourFeedbackText,
      positive,
      negative,
      channelsText,
      continueConversation,
      openANewChat,
      you,
      youSentAMedia,
      youReceivedAMedia,
      chatWithUs,
      sending,
      emptyChat,
      liveChatIconLabel,
      contactInformationPermissionContent,
      contactInformationPermissionLinkText,
      endedConversation,
      closed,
      satisfied,
      dissatisfied,
      neutral,
      yourFeedback,
   ]);

   const inputs = [
      { name: 'continueConversation', disabled: false },
      { name: 'openANewChat', disabled: false },
      { name: 'you', disabled: false },
      { name: 'sending', disabled: false },
      { name: 'emptyChat', disabled: false },
      { name: 'liveChatIconLabel', disabled: false },
      { name: 'contactInformationPermissionContent', disabled: false },
      { name: 'contactInformationPermissionLinkText', disabled: false },
      { name: 'endedConversation', disabled: false },
      { name: 'youSentAMedia', disabled: false },
      { name: 'youReceivedAMedia', disabled: false },
      { name: 'chatWithUs', disabled: false },
      { name: 'sendButtonLabel', disabled: false },
      { name: 'seeAllYourConversationsButtonText', disabled: false },
      { name: 'typeYourMessageHerePlaceholderText', disabled: false },
      { name: 'online', disabled: false },
      { name: 'offline', disabled: false },
      { name: 'closed', disabled: false },
      { name: 'contactInformationFullNamePlaceholderText', disabled: false },
      { name: 'contactInformationEmailAddressPlaceholderText', disabled: false },
      { name: 'contactInformationPhoneNumberPlaceholderText', disabled: false },
      { name: 'contactInformationAddressPlaceholderText', disabled: false },
      { name: 'contactDetailsText', disabled: false },
      { name: 'writeAReviewPlaceholderText', disabled: false },
      { name: 'yourFeedbackText', disabled: false },
      { name: 'positive', disabled: false },
      { name: 'negative', disabled: false },
      { name: 'channelsText', disabled: false },
      { name: 'greetingTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'greetingContent', disabled: languageCode === defaultLanguageCode },
      { name: 'newChatTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'newChatSubtitle', disabled: languageCode === defaultLanguageCode },
      { name: 'titleOldChat', disabled: languageCode === defaultLanguageCode },
      { name: 'greetingMessage1', disabled: languageCode === defaultLanguageCode, multiLine: true },
      {
         name: 'welcomeMessageDuringWorkingHoursMessage1',
         disabled: languageCode === defaultLanguageCode,
         multiLine: true,
      },
      {
         name: 'welcomeMessageOutsideOfWorkingHoursMessage1',
         disabled: languageCode === defaultLanguageCode,
         multiLine: true,
      },
      { name: 'contactInformationTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'contactInformationMessage', disabled: languageCode === defaultLanguageCode },
      { name: 'contactInformationThanksMessage', disabled: languageCode === defaultLanguageCode },
      { name: 'rateUsTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'rateUsMessage', disabled: languageCode === defaultLanguageCode },
      { name: 'rateUsThanksMessage', disabled: languageCode === defaultLanguageCode },
      { name: 'whatsappButtonTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'whatsappTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'whatsappContent', disabled: languageCode === defaultLanguageCode },
      { name: 'facebookButtonTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'facebookTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'facebookContent', disabled: languageCode === defaultLanguageCode },
      { name: 'instagramButtonTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'instagramTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'instagramContent', disabled: languageCode === defaultLanguageCode },
      { name: 'phoneCallButtonTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'phoneCallTitle', disabled: languageCode === defaultLanguageCode },
      { name: 'phoneCallContent', disabled: languageCode === defaultLanguageCode },
      { name: 'satisfied', disabled: languageCode === defaultLanguageCode },
      { name: 'dissatisfied', disabled: languageCode === defaultLanguageCode },
      { name: 'neutral', disabled: languageCode === defaultLanguageCode },
      { name: 'yourFeedback', disabled: languageCode === defaultLanguageCode },
   ];

   return (
      <Grid container>
         {inputs.map(({ name, disabled, multiLine }) => (
            <Grid key={name} item xs={12}>
               <Input
                  multiline={multiLine}
                  name={name}
                  disabled={disabled}
                  placeholder={t[`page_settings_typo_platforms_live_chat_languages_placeholder_${name}`]}
                  label={t[`page_settings_typo_platforms_live_chat_languages_label_${name}`]}
                  control={control}
                  background={'white'}
                  rules={{
                     required: t[`page_settings_typo_platforms_live_chat_languages_required_${name}`],
                  }}
               />
            </Grid>
         ))}
      </Grid>
   );
};

export default TranslationForm;

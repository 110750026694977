import { gql } from '@apollo/client';

export const GET_TRANSLATE_LANGUAGE = gql`
   query GetTranslateLanguage($lang: String) {
      getTranslateLanguage(lang: $lang) {
         code
         data
         message
         success
      }
   }
`;

// tüm reducer'ları export edebilmek için redux altındaki combineReducers ı sayfaya dahil et
import { combineReducers } from 'redux';
// person Reducer ı sayfaya dahil et
import personReducer from './person';
import chatReducer from './chat';
import automationReducer from './automation';
import eCommerceReducer from './ecommerce';
import liveChatReducer from './liveChat';
import uiReducer from './ui';
import filterReducer from './filterState';
import questionReducer from './questions';
import platformEventTypeReducer from './platformEventTypes';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import personActivityReducer from './personActivity';
import bulkMessagingReducer from './bulk-messaging';

const questionStateConfig = {
   key: 'questionsState',
   storage: storage,
   blacklist: ['contentState'],
};

const appReducer = combineReducers({
   liveChatReducer,
   eCommerceReducer,
   bulkMessagingReducer,
   personReducer,
   chatReducer,
   uiReducer,
   filterReducer,
   platformEventTypeReducer,
   personActivityReducer,
   automationReducer,
   questionsState: persistReducer(questionStateConfig, questionReducer),
});

const rootReducer = (state, action) => {
   if (action.type === 'SIGN_OUT') {
      return appReducer(undefined, action);
   }
   return appReducer(state, action);
};

export default rootReducer;

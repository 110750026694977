import React, { useContext, useEffect, useState } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';
import _ from 'underscore';

const useStyles = makeStyles(() => ({
   container: {
      flexShrink: 0,
      height: '75px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: '25px',
      paddingRight: '25px',
      borderTop: '1px solid #cccccc24',
   },
   cancelButton: {
      color: 'white',
      boxShadow: 'none',
      height: '40px',
      minWidth: '150px',
      borderRadius: '6px',
      fontSize: '1.2rem',
      fontWeight: 500,
      background: '#F4425B',
      paddingRight: '2.75rem',
      paddingLeft: '2.75rem',
      '&:hover': {
         background: '#c63a4e',
      },
   },
   saveButton: {
      color: 'white',
      boxShadow: 'none',
      height: '40px',
      minWidth: '120px',
      borderRadius: '6px',
      fontSize: '1.2rem',
      fontWeight: 500,
      paddingRight: '2.75rem',
      paddingLeft: '2.75rem',
   },
}));

const FormSaveAndCancelButtons = ({ resetValues }) => {
   const classes = useStyles();
   const { liveChatPreviewSettings, updateLiveChatPreviewSettings, liveChatSettings, updateLiveChatSettings } =
      useContext(LiveChatSettingsContext);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [isEqual, setEquality] = useState(true);

   useEffect(() => {
      setEquality(_.isEqual(liveChatPreviewSettings, liveChatSettings));
   }, [liveChatPreviewSettings, liveChatSettings]);

   const cancel = () => {
      updateLiveChatPreviewSettings(liveChatSettings);
      resetValues(liveChatSettings);
   };
   const validURL = (str) => {
      var pattern = new RegExp(
         '^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$',
         'i',
      ); // fragment locator
      return !!pattern.test(str);
   };
   const controlFormData = (liveChatPreviewSettings) => {
      if (
         liveChatPreviewSettings.contactInformationPermission &&
         liveChatPreviewSettings.contactInformationPermissionLinkText?.length > 0 &&
         !validURL(liveChatPreviewSettings.contactInformationPermissionLinkUrl)
      )
         return false;

      if (
         liveChatPreviewSettings.contactInformationPermission &&
         liveChatPreviewSettings.contactInformationPermissionLinkText?.length > 1 &&
         liveChatPreviewSettings.contactInformationPermissionLinkUrl?.length < 1
      )
         return false;

      if (
         liveChatPreviewSettings.contactInformationPermission &&
         liveChatPreviewSettings.contactInformationPermissionLinkText?.length < 1 &&
         liveChatPreviewSettings.contactInformationPermissionLinkUrl?.length > 1
      )
         return false;

      return true;
   };
   const save = () => {
      if (controlFormData(liveChatPreviewSettings)) updateLiveChatSettings(liveChatPreviewSettings);
   };

   return (
      <Grid container className={classes.container}>
         <Button disabled={isEqual} onClick={cancel} variant='contained' className={classes.cancelButton}>
            {t['comp_button_cancel']}
         </Button>
         <Button disabled={isEqual} onClick={save} variant='contained' color='primary' className={classes.saveButton}>
            {t['comp_button_save']}
         </Button>
      </Grid>
   );
};

export default FormSaveAndCancelButtons;

import { pink } from '@material-ui/core/colors';
import createTheme, { Theme } from '@material-ui/core/styles/createTheme';
import { ModuleTheme } from './theme.types';

export interface MyTheme extends ModuleTheme.RootObject, Theme {}
declare module '@material-ui/styles' {
   interface DefaultTheme extends MyTheme {}
}

declare module '@material-ui/core/styles/createTheme' {
   interface ThemeOptions extends MyTheme {}
}

export const theme = createTheme({
   typography: {
      fontFamily: [
         'Helvetica',
         '-apple-system',
         'BlinkMacSystemFont',
         '"Segoe UI"',
         'Roboto',
         'Arial',
         'sans-serif',
         '"Apple Color Emoji"',
         '"Segoe UI Emoji"',
         '"Segoe UI Symbol"',
      ].join(','),
   },
   chatApp: {
      general: {
         pallet: {
            passiveStructureBlue: '#385273',
            passiveStructureGrey: '#f0f3f7',
            passiveStructureBlack: '#1D2935',
            passiveStructureDark: '#10223A',
            passiveStructureAzure: '#157CFC',
            confirmGreen: '#5AC19D',
         },
      },
      questions: {
         drawerWidth: 350, // questions user profile drawer width
      },
      dashboard: {
         dashboardPallet: {
            // Middle Blocks
            recievedMessagesHoverColor: 0,
            sentMessagesHoverColor: 0,
            // Charts
            chartsPlatformColors: [],
            chartsRecievedMessagesColor: 0,
            chartsSentMessagesColor: 0,
         },
         misc: {
            paperBoarderRadius: 15,
         },
         toBeDeletedLater: {
            // These configs are here only for final design touchs.
            // After being finalized we will remove these configs.
         },
      },
      workspace: {
         sideBar: {
            iconSize: 36,
            // iconColor: '#C7CDD6',
            iconColor: '#7D8DA0',
            // backgroundColor: '#385273',
            backgroundColor: '#10223A',
            selectedItemIconColor: 'blue',
            selectedItemBackgroundColor: '#eff3f7',
         },
         navBar: {
            backgroundColor: '#ffffff',
            titleColor: '#385273',
         },
         settingsPage: {
            titleColor: '#385273',
            subTitleColor: '#97A7BF',
            leftPanelWidth: 370,
         },
         supportPage: {
            leftPanelWidth: 370,
         },
         backgroundColor: '#fafafa',
         settings: {},
      },
   },
   palette: {
      type: 'light',
      primary: {
         main: '#018cf9',
      },
      secondary: pink,
   },
});

export default theme;

import { gql } from '@apollo/client';

export const GET_BULK_SHARINGS = gql`
   query getBulkSharings($input: GetBulkSharingInput) {
      getBulkSharings(input: $input) {
         code
         data {
            docs {
               _id
               customer
               name
               telegramStatus
               whatsAppStatus
               whatsAppSendFolderNames
               telegramSendFolderNames
               qpienPersonGroups
               whatsAppGroups
               telegramChannels
               folders {
                  folderName
                  folderLink
                  telegramStatus
                  whatsAppStatus
                  length
               }
            }
            totalRecords
            hasNextPage
         }
         success
         message
      }
   }
`;

export const BULK_CHECK_SELECTED_TARGETS = gql`
   query checkSelectedTargets($input: CheckSelectedTargetsInput) {
      checkSelectedTargets(input: $input) {
         code
         data
         success
         message
      }
   }
`;

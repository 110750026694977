import { gql } from '@apollo/client';

export const UPDATED_DOMAIN_SUBSCRIPTION = gql`
   subscription updatedDomain($token: String!, $customer: String!) {
      updatedDomain(data: { token: $token, customer: $customer }) {
         data {
            _id
            customer
            domain
            valid
            dns {
               mail_cname {
                  valid
                  type
                  host
                  data
               }
               dkim1 {
                  valid
                  type
                  host
                  data
               }
               dkim2 {
                  valid
                  type
                  host
                  data
               }
            }
         }
         success
         code
         message
      }
   }
`;

export const CREATED_DOMAIN_SUBSCRIPTION = gql`
   subscription createdDomain($token: String!, $customer: String!) {
      createdDomain(data: { token: $token, customer: $customer }) {
         data {
            _id
            customer
            domain
            valid
            dns {
               mail_cname {
                  valid
                  type
                  host
                  data
               }
               dkim1 {
                  valid
                  type
                  host
                  data
               }
               dkim2 {
                  valid
                  type
                  host
                  data
               }
            }
         }
         success
         code
         message
      }
   }
`;

export const DELETED_DOMAIN_SUBSCRIPTION = gql`
   subscription deletedDomain($token: String!, $customer: String!) {
      deletedDomain(data: { token: $token, customer: $customer }) {
         data {
            _id
            customer
            domain
            valid
            dns {
               mail_cname {
                  valid
                  type
                  host
                  data
               }
               dkim1 {
                  valid
                  type
                  host
                  data
               }
               dkim2 {
                  valid
                  type
                  host
                  data
               }
            }
         }
         success
         code
         message
      }
   }
`;

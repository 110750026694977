import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LineChart from './lineChart';
import Toolbar from '../toolbar';
import { GET_INCOMING_MESSAGES_STATISTICS_QUERY } from '../../../../queries/Statistics/Service/GraphQL/query';
import { useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { LanguageContext } from '@helper/locale/langContext';
import Error from '@components/Errors/Error';
import Chip from '../chip';
// const platformCodes = ['whatsAppWeb', 'whatsAppBusiness', 'facebook', 'instagram'];
const useStyles = makeStyles((theme) => ({
   emptyList: {
      fontSize: '1.3rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      marginTop: 40,
   },
}));
const Messages = () => {
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const classes = useStyles();
   const [state, setState] = useState({
      // startDate: moment().subtract(1, 'day').valueOf(),
      startDate: moment().valueOf(),
      endDate: moment().valueOf(),
      platform: 'ALL',
      totalReceived: 0,
      totalSent: 0,
      chartData: [],
   });

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const { data, loading, error, refetch, networkStatus } = useQuery(GET_INCOMING_MESSAGES_STATISTICS_QUERY, {
      variables: {
         customer: person.customer._id,
         startDate: state.startDate,
         endDate: state.endDate,
         platform: state.platform,
      },
      fetchPolicy: 'network-only',
   });

   const handleChangeFilterDate = (startDate, endDate) => {
      setState((prevState) => {
         const newState = { ...prevState };
         if (startDate) {
            newState.startDate = startDate;
         }
         if (endDate) {
            newState.endDate = endDate;
         }
         return newState;
      });
   };

   const handleChangePlatform = (platform) => {
      setState((prevState) => ({ ...prevState, platform }));
   };

   useEffect(() => {
      refetch();
   }, [state, refetch]);
   const loadingOrFetching = networkStatus === 4 || loading;

   if (loadingOrFetching) {
      return <div className='loading'></div>;
   }

   return (
      <Fragment>
         <Toolbar
            onPlatformChange={handleChangePlatform}
            platform={state.platform}
            onDateChange={handleChangeFilterDate}
            startDate={state.startDate}
            endDate={state.endDate}
            title={t['page_statistics_typo_messages']}
            operation={'getCustomerStatistics'}
            params={{
               customer: person.customer._id,
               startDate: state.startDate,
               endDate: state.endDate,
               platform: state.platform,
            }}
         />
         {!loadingOrFetching && data && data.getCustomerStatistics && (
            <Grid container direction='column'>
               <Grid item container direction='row' alignItems='center' spacing={3} xs={12} style={{ padding: 25 }}>
                  <Grid item xs={4}>
                     <Chip
                        title={t?.total}
                        value={
                           data.getCustomerStatistics.data.totalSent + data.getCustomerStatistics.data.totalReceived
                        } // Total
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <Chip
                        title={t['page_statistics_typo_sent_messages']}
                        value={data.getCustomerStatistics.data.totalSent}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <Chip
                        title={t['page_statistics_typo_received_messages']}
                        value={data.getCustomerStatistics.data.totalReceived}
                     />
                  </Grid>
               </Grid>
               {data.getCustomerStatistics.data.chartData.length > 0 ? (
                  <Grid item xs={12}>
                     <LineChart
                        mode={state.endDate - state.startDate <= 86400000 ? 'HOUR' : 'NOT_HOUR'}
                        chartData={data.getCustomerStatistics.data.chartData}
                     />
                  </Grid>
               ) : (
                  <Grid xs={12} container justifyContent='center' style={{ height: 100 }}>
                     <Typography className={classes.emptyList}>{t?.your_message_list_is_empty}</Typography>{' '}
                  </Grid>
               )}
            </Grid>
         )}
         <Error show={!!error} message={error?.message} />
      </Fragment>
   );
};

export default Messages;

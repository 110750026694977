import { useLazyQuery } from '@apollo/client';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import Modal from '@components/Modal/modal';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import SingleInput from '@components/_Modal/SingleInput';
import { LanguageContext } from '@helper/locale/langContext';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GET_CONNECTED_MESSAGE_PLATFORMS_QUERY } from '@queries/Automation/query';
import { MyTheme } from '@styles/config';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { RootStateOrAny, useSelector } from 'react-redux';
import { FlowInstanceMessageChannel } from '../types';

const useStyles = makeStyles((theme: MyTheme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

interface DuplicateFlowProps {
   isVisible: boolean;
   loading: boolean;
   setShowModal: (param: boolean) => void;
   onComplate: (channel: { label: string; value: string } | undefined) => void;
}

const DuplicateFlow: FC<DuplicateFlowProps> = ({ isVisible, setShowModal, onComplate, loading }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;

   const {
      handleSubmit,
      control,
      watch,
      setValue,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         channel: undefined,
      },
   });

   const [isLoading, setIsLoading] = useState(loading);

   useEffect(() => {
      setIsLoading(loading);
   }, [loading]);

   const handleFormSubmit = (data: { channel: { label: string; value: string } | undefined }) => {
      onComplate(data.channel);
      //setShowModal(false);
   };

   const [channel, setChannel] = useState([]);
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   const [getChannels] = useLazyQuery(GET_CONNECTED_MESSAGE_PLATFORMS_QUERY);

   useEffect(() => {
      getChannels({
         variables: {
            customerId: person.customer._id,
         },
         fetchPolicy: 'cache-first',
         onCompleted: (res) => {
            const response = [res.getConnectedMessagePlatforms.data];

            let options: any = [
               {
                  value: FlowInstanceMessageChannel.LiveChat,
                  label: t.page_settings_typo_platforms_live_chat,
               },
            ];

            response.map((item) => {
               if (item.whatsappWeb === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.WhatsappWeb,
                     label: t.page_settings_typo_platforms_whats_app_web,
                  });
               }
               if (item.whatsappBusiness === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.WhatsappBusiness,
                     label: t.atomation_flow_whatsappbussiness,
                  });
               }
               if (item.facebook === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.Facebook,
                     label: t.package_start_feature_4,
                  });
               }
               if (item.instagram === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.Instagram,
                     label: t.package_start_feature_5,
                  });
               }
               if (item.telegram === true) {
                  options.push({
                     value: FlowInstanceMessageChannel.Telegram,
                     label: t.page_settings_typo_platforms_telegram,
                  });
               }
            });
            setChannel(options);
         },
      });
   }, []);

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' justifyContent='center' xs={12}>
            <CircularProgress size={20} color='inherit' />
         </Grid>
      );
   }, []);

   return (
      <Modal
         open={isVisible}
         onClose={() => {
            if (!isLoading) {
               setShowModal(false);
            } else {
               setShowModal(true);
            }
         }}
         modalHeader={t.automation_flow_duplicate_flow}
         paperStyle={{
            width: 600,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
         }}
      >
         <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', height: 250 }}>
               <ModalLargeText style={{ textAlign: 'center', marginBlock: 10, fontWeight: 600 }}>
                  {t.automation_duplicate_warning}
               </ModalLargeText>

               <Typography style={{ textAlign: 'center' }}>{t.automation_duplicate_warning_step}</Typography>

               <SingleInput
                  label={t['page_chat_typo_profile_channel']}
                  name='channel'
                  placeholder={t['atomation_flow_select']}
                  control={control}
                  isSelect
                  disabled={isLoading}
                  selectOptions={channel}
                  autoHeight
                  maxMenuHeight={100}
                  value={watch('channel')}
                  onChange={(channel: any) => {
                     setValue('channel', channel);
                  }}
               />
            </div>

            <ModalButtonField>
               <ButtonCancel
                  label={t['comp_button_cancel']}
                  onClick={() => {
                     setShowModal(false);
                  }}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                  }}
                  disabled={isLoading}
               />

               <Button
                  type='submit'
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     float: 'right',
                  }}
                  disabled={watch('channel') === undefined || isLoading}
               >
                  <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                     {isLoading && <Loader />}
                     <span style={{ whiteSpace: 'nowrap' }}>{t?.automation_flow_duplicate}</span>
                  </div>
               </Button>
            </ModalButtonField>
         </form>
      </Modal>
   );
};

export default DuplicateFlow;

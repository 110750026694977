import React, { Fragment, useState, useEffect, useContext } from 'react';
import { SITE_URL } from '@helper/config';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Avatar, Paper, Typography } from '@material-ui/core';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import { GET_PERSON_CUSTOMERS_QUERY } from '../../../../queries/Person/Service/GraphQL/query';
import { GET_TEAMS_QUERY } from '../../../../queries/Teams/query';
import {
   CREATE_CUSTOMER_PERSON_MUTATION,
   UPDATE_CUSTOMER_PERSON_MUTATION,
   DELETE_CUSTOMER_PERSON_MUTATION,
   RESET_CUSTOMER_PERSON_PASSWORD_MUTATION,
   RESEND_CREATE_PASSWORD_MAIL_MUTATION,
} from '../../../../queries/Person/Service/GraphQL/mutation';
import {
   CREATED_CUSTOMER_PERSON_SUBSCRIPTION,
   UPDATED_CUSTOMER_PERSON_SUBSCRIPTION,
   DELETED_CUSTOMER_PERSON_SUBSCRIPTION,
} from '../../../../queries/Person/Service/GraphQL/subscription';
import { useSnackbar } from 'notistack';
import { useMutation, useLazyQuery, useSubscription, useQuery } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import AddNewUserModal from './newUserModal';
import ResetPasswordModal from './resetPasswordModal';
import EditUserModal from './editUserModal';
import { USERS_TYPE } from '../../../Chat/types';
import { useSelector } from 'react-redux';
import { getPerson } from '@store/reducers/person';
import DeleteUserModal from './deleteUserModal';
import ActionIconButton from '@modules/Settings/SettingSpace/ActionIconButton';
import { DataGrid } from '@material-ui/data-grid';
import SearchBar from '@components/SearchBar';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   selector: {
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 10,
      paddingLeft: 3,
      height: 32,
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   tableSubtitle: {
      fontSize: '1rem',
      fontWeight: 'medium',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   actionButton: {
      // border: '1px solid rgb(111 104 104)',
      // padding: '5px',
      marginLeft: 10,
      borderRadius: '8px',
      justifyContent: 'center',
      display: 'flex',
      cursor: 'pointer',
   },
   tableProfileTexts: {
      fontSize: '0.9rem',
      fontWeight: 'medium',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   ButtonContainer: {
      paddingLeft: 5,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   paper: {
      borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
      width: '100%',
      borderRadius: 15,
      overflowY: 'auto',
      overflowX: 'hidden',
   },
   Table: {
      ' &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
         outline: 'none !important',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
         outline: 'none !important',
      },
      ' &.MuiDataGrid-root .MuiDataGrid-window': {
         overflowX: 'hidden',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
         padding: 0,
      },
   },
   content: {
      height: '100vh',
      overflowX: 'hidden',
      overflowY: 'auto',
      flexGrow: 1,
   },
   rowStyle: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
         outline: 'none',
      },

      fontWeight: 'bold',
      fontSize: '1rem',
   },
   statusAvatar: {
      position: 'absolute',
      top: 4,
      right: '-2px',
      width: 12,
      height: 12,
      borderRadius: '50%',
   },
}));

const getPersonRoleColor = (type) => {
   switch (type) {
      case 0:
         return 'orange';
      case 1:
         return '#157CFC';
      case 2:
         return 'green';
      default:
         return 'grey';
   }
};

const Page = {
   pageSize: 15,
   page: 1,
};

const Users = ({ person, token }) => {
   const classes = useStyles();
   const [modalConfig, setModalConfig] = useState({
      show: false,
      mode: null /* EDIT | NEW */,
   });
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const me = useSelector(getPerson);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();

   const customer = person.customer._id;
   const [personListCount, setPersonListCount] = useState(0);
   const [personList, setPersonList] = useState([]);
   const [text, setText] = useState('');

   const handleEditRow = (row) => {
      setModalConfig({
         show: true,
         mode: 'EDIT',
         row: row,
      });
   };

   const handleDeleteRow = (row) => {
      setModalConfig({
         show: true,
         mode: 'DELETE',
         row: row,
      });
   };

   const handleResetPassword = (row) => {
      setModalConfig({
         show: true,
         mode: 'RESET_PASSWORD',
         row: row,
      });
   };

   const handleCreateNewRow = () => {
      setModalConfig({
         show: true,
         mode: 'NEW',
      });
   };

   const handleCloseModal = () => {
      setModalConfig({
         show: false,
         mode: null,
      });
   };

   // get customer persons
   const [getPersons, { loading: personsLoading }] = useLazyQuery(GET_PERSON_CUSTOMERS_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         let rows = data?.customerPersons?.data?.docs.map((item) => {
            return {
               id: item._id,
               name: item.name,
               surname: item.surname,
               photo: item.photo,
               email: item.email,
               accountStatus: item.accountStatus,
               type: item.type,
               signedTeams: item.signedTeams,
               chatParticipateLimit: item.chatParticipateLimit,
               isEmailVerified: item.isEmailVerified,
               status: item.status,
            };
         });
         setPersonList(rows);

         setPersonListCount(data?.customerPersons?.data?.docsCount);
      },

      onError: (error) => {
         // on query error
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  {t['general_message_button_close']}
               </div>
            ),
         });
      },
   });

   // create person mutation function
   const [createCustomerPerson, { loading: createCustomerPersonLoading }] = useMutation(
      CREATE_CUSTOMER_PERSON_MUTATION,
   );

   // update mutation function
   const [updateCustomerPersonMutation, { loading: updateCustomerPersonLoading }] = useMutation(
      UPDATE_CUSTOMER_PERSON_MUTATION,
   );

   // delete person mutation function
   const [deleteCustomerPersonMutation, { loading: deleteCustomerPersonLoading }] = useMutation(
      DELETE_CUSTOMER_PERSON_MUTATION,
   );

   // delete person mutation function
   const [resendCreatePasswordMutation, { loading: resendCreatePasswordLoading }] = useMutation(
      RESEND_CREATE_PASSWORD_MAIL_MUTATION,
   );

   // update mutation function
   const [resetCustomerPersonPasswordMutation, { loading: resetCustomerPersonPasswordLoading }] = useMutation(
      RESET_CUSTOMER_PERSON_PASSWORD_MUTATION,
   );

   // subscription created person
   useSubscription(CREATED_CUSTOMER_PERSON_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               createdCustomerPerson: { data: createdCustomerPerson },
            },
         },
      }) => {
         const newPerson = {
            id: createdCustomerPerson._id,
            name: createdCustomerPerson.name,
            surname: createdCustomerPerson.surname,
            photo: createdCustomerPerson.photo,
            email: createdCustomerPerson.email,
            type: createdCustomerPerson.type,
            chatParticipateLimit: createdCustomerPerson.chatParticipateLimit,
            signedTeams: createdCustomerPerson.signedTeams,
            accountStatus: createdCustomerPerson.accountStatus,
            isEmailVerified: createdCustomerPerson.isEmailVerified,
         };
         let index = personList.findIndex((item) => item.id === newPerson.id);
         if (index === -1) {
            setPersonList([newPerson, ...personList]);
            setPersonListCount(personListCount + 1);
         }
      },
   });

   // subscription updated person
   useSubscription(UPDATED_CUSTOMER_PERSON_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: ({
         client,
         subscriptionData: {
            data: {
               updatedCustomerPerson: { data: updatedPerson },
            },
         },
      }) => {
         let index = personList.findIndex((item) => item.id.toString() === updatedPerson._id.toString());

         if (index > -1) {
            const newList = personList.map((item) => {
               if (item.id.toString() === updatedPerson._id.toString()) {
                  return {
                     ...item,
                     id: updatedPerson._id,
                     name: updatedPerson.name,
                     surname: updatedPerson.surname,
                     photo: updatedPerson.photo,
                     email: updatedPerson.email,
                     type: updatedPerson.type,
                     signedTeams: updatedPerson.signedTeams,
                     chatParticipateLimit: updatedPerson.chatParticipateLimit,
                  };
               }
               return item;
            });
            setPersonList(newList);
         }
      },
   });

   // subscription deleted person
   useSubscription(DELETED_CUSTOMER_PERSON_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               deletedCustomerPerson: { data: deletedCustomerPerson },
            },
         },
      }) => {
         let index = personList.findIndex((item) => item.id === deletedCustomerPerson._id);
         if (index !== -1) {
            setPersonList(personList.filter((item) => item.id !== deletedCustomerPerson._id));
            setPersonListCount(personListCount - 1);
         }
      },
   });

   // add data on submit
   const addPersonSubmit = async (formData) => {
      // run create mutation
      createCustomerPerson({
         variables: {
            name: formData.name,
            surname: formData.surname,
            phone: formData.phone,
            email: formData.email,
            password: formData.password,
            type: parseFloat(formData.type.value),
            signedTeams: formData.teams?.map((item) => item.value) || [],
            customer: person.customer._id,
         },
      })
         .then(
            ({
               data: {
                  createCustomerPerson: { data: createCustomerPerson },
               },
            }) => {
               const newPerson = {
                  id: createCustomerPerson._id,
                  name: createCustomerPerson.name,
                  surname: createCustomerPerson.surname,
                  photo: createCustomerPerson.photo,
                  email: createCustomerPerson.email,
                  type: createCustomerPerson.type,
                  accountStatus: createCustomerPerson.accountStatus,
                  signedTeams: createCustomerPerson.signedTeams,
                  chatParticipateLimit: createCustomerPerson.chatParticipateLimit,
                  isEmailVerified: createCustomerPerson.isEmailVerified,
               };

               let index = personList.findIndex((item) => item.id === newPerson.id);
               if (index === -1) {
                  setPersonList([newPerson, ...personList]);
                  setPersonListCount(personListCount + 1);
               }
               handleCloseModal();
               enqueueSnackbar(`${createCustomerPerson.name} ${t?.general_information_invited_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   // update data on submit
   const editPersonSubmit = async (formData) => {
      updateCustomerPersonMutation({
         variables: {
            _id: formData._id,
            name: formData.name,
            surname: formData.surname,
            phone: formData.phone,
            email: formData.email,
            signedTeams: formData.teams?.map((item) => item.value) || [],
            type: formData.type.value,
            chatParticipateLimit: parseInt(formData.chatParticipateLimit),
         },
      })
         .then(
            ({
               data: {
                  updateCustomerPerson: { data: updateCustomerPerson },
               },
            }) => {
               //adding update person to list
               let index = personList.findIndex((item) => item.id.toString() === updateCustomerPerson._id.toString());

               if (index > -1) {
                  const newList = personList.map((item) => {
                     if (item.id.toString() === updateCustomerPerson._id.toString()) {
                        return {
                           ...item,
                           id: updateCustomerPerson._id,
                           name: updateCustomerPerson.name,
                           surname: updateCustomerPerson.surname,
                           photo: updateCustomerPerson.photo,
                           email: updateCustomerPerson.email,
                           type: updateCustomerPerson.type,
                        };
                     }
                     return item;
                  });
                  setPersonList(newList);
               }

               handleCloseModal();
               enqueueSnackbar(`${updateCustomerPerson.name} ${t?.general_information_updated_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   // update data on submit
   const resetPersonPasswordSubmit = async (formData) => {
      // run update mutation
      resetCustomerPersonPasswordMutation({
         variables: {
            _id: formData._id,
            newPassword: formData.password,
         },
      })
         .then(
            ({
               data: {
                  resetCustomerPersonPassword: { data: resetCustomerPersonPassword },
               },
            }) => {
               // on success event
               // close modal
               handleCloseModal();
               enqueueSnackbar(`${resetCustomerPersonPassword.name} ${t?.password_reset_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   // delete data on submit
   const handleDelete = async (itemId) => {
      await deleteCustomerPersonMutation({ variables: { _id: itemId } }) // run to delete mutation
         .then(
            ({
               data: {
                  deleteCustomerPerson: { data: deleteCustomerPerson },
               },
            }) => {
               // success event
               let index = personList.findIndex((item) => item.id === deleteCustomerPerson._id);
               if (index !== -1) {
                  setPersonList(personList.filter((item) => item.id !== deleteCustomerPerson._id));
                  setPersonListCount(personListCount - 1);
               }

               handleCloseModal();
               return enqueueSnackbar(`${deleteCustomerPerson.name} ${t?.general_information_deleted_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               });
            },
         )
         .catch((error) => {
            // error event
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const handleResendPasswordCreateEmail = async (itemId) => {
      await resendCreatePasswordMutation({ variables: { _id: itemId } }) // run to delete mutation
         .then(
            ({
               data: {
                  resendCreatePasswordMail: { data: resendCreatePasswordMail },
               },
            }) => {
               // success event
               handleCloseModal();
               return enqueueSnackbar(
                  `${t?.general_information_resend_create_password_successfully.replace(
                     '{0}',
                     resendCreatePasswordMail.name,
                  )}`,
                  {
                     // send success message
                     variant: 'success',
                     autoHideDuration: 3000,
                  },
               );
            },
         )
         .catch((error) => {
            // error event
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const StatusOptions = {
      Active: {
         label: t?.page_settings_users_status_active,
         color: 'green',
      },
      Inactive: {
         label: t?.page_settings_users_status_inactive,
         color: 'red',
      },
      Disabled: {
         label: t?.page_settings_users_status_disabled,
         color: 'orange',
      },
      Enable: {
         label: t?.page_settings_users_status_enable,
         color: 'green',
      },
      Approved: {
         label: t?.page_settings_users_status_approved,
         color: 'green',
      },
      'Waiting approval': {
         label: t?.page_settings_users_status_waiting_approval,
         color: 'gray',
      },
      Verified: {
         label: t?.page_settings_users_status_verified,
         color: 'green',
      },
      'Waiting verification': {
         label: t?.page_settings_users_status_waiting_verification,
         color: 'gray',
      },
   };

   const { loading: teamsLoading, data: teamsData } = useQuery(GET_TEAMS_QUERY, {
      variables: { customer: person.customer._id, pageSize: 100, page: 1 },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const [queryParams, setQueryParams] = useState({
      customer,
      pageSize: Page.pageSize,
      page: Page.page,
      search: '',
   });

   const loadingOrFetching =
      personsLoading ||
      createCustomerPersonLoading ||
      updateCustomerPersonLoading ||
      deleteCustomerPersonLoading ||
      resetCustomerPersonPasswordLoading ||
      teamsLoading ||
      resendCreatePasswordLoading;

   useEffect(() => {
      setQueryParams((prev) => ({ ...prev, search: text }));
   }, [text]);

   useEffect(() => {
      getPersons({
         variables: {
            ...queryParams,
         },
      });
   }, [queryParams]);

   const BotLogo = () => {
      return (
         <>
            <svg xmlns='http://www.w3.org/2000/svg' width='42' height='42' viewBox='0 0 237.486 264.117'>
               <g id='Group_51' data-name='Group 51' transform='translate(-167 -154.001)'>
                  <g id='Group_50' data-name='Group 50' transform='translate(-655.025 -107.766)'>
                     <path
                        id='Path_78'
                        data-name='Path 78'
                        d='M1054.648,378.371C1051.8,367.4,1047.693,356.9,1041,347.639c-9.49-13.139-22.332-21.765-37.509-27-14.3-4.932-29.167-6.771-44.187-7.518-4.507-.225-9.034-.035-13.776-.035,0-3.886.248-6.991-.062-10.039-.394-3.881.171-6.924,4.516-8.012a2.853,2.853,0,0,0,.925-.573c5.677-4.3,8.5-9.974,7.463-17.085-1.092-7.483-5.449-12.647-12.7-14.775-7.14-2.1-13.472-.241-18.392,5.493a18.07,18.07,0,0,0,6.5,28.341c.783.352,1.925,1.136,1.947,1.752.163,4.708.089,9.425.089,14.325-1.438.088-2.438.181-3.438.206-14.64.36-29.2,1.445-43.518,4.793-27.93,6.533-48.382,21.9-58.582,49.42-6.538,17.633-8.727,36-8.166,54.622.721,23.958,11.512,42.7,31.466,55.958a66.392,66.392,0,0,0,37.974,11.029c4.369-.023,8.738,0,13.107,0a5.668,5.668,0,0,1-2.276,1.265c-15.443,5.421-24.285,16.518-27.034,32.376a8.174,8.174,0,0,1-.532,1.912h9.49a29.847,29.847,0,0,1,9.495-18.851,34.128,34.128,0,0,1,23.629-9.082c15.741-.129,31.488-.215,47.226.039,15.065.242,26.541,8.652,31.641,22.771a22.923,22.923,0,0,1,.92,5.123h9.07a6.663,6.663,0,0,1-.124-.767c-1.327-12.576-7.553-22.265-18.053-29.116-3.422-2.234-7.329-3.727-11.237-5.669,4.54,0,8.893.046,13.246-.008,23.3-.289,42.031-9.73,56.021-28.341a63.971,63.971,0,0,0,13.1-35.054A148.927,148.927,0,0,0,1054.648,378.371Zm-122.83-98.552c-.009-4.888,3.868-8.582,8.768-8.962,3.98-.309,9.437,4.1,8.88,8.551.065,5.322-3.49,9.128-8.608,9.211-.064,0-.127,0-.191,0A8.826,8.826,0,0,1,931.818,279.819Zm90.934,190.208a54.254,54.254,0,0,1-29.839,9.227c-17.471.124-34.944.033-52.417.033-17.208,0-34.425.352-51.626-.087-19.4-.5-34.867-9.037-46.511-24.585-9.075-12.122-12.044-25.922-11.075-40.689.635-9.679,1.06-19.478,2.96-28.946,3.712-18.478,11.794-34.8,27.586-46.143,10.2-7.329,21.874-11.4,34.135-13.757,21.885-4.2,43.98-4,66.1-2.864,14.231.726,28.2,2.912,41.561,8.126,12.981,5.063,23.61,13.012,31.5,24.663a78.513,78.513,0,0,1,12.575,33.616,234.486,234.486,0,0,1,2.595,29.306C1050.6,440.141,1041.325,457.759,1022.752,470.027Z'
                        fill='currentColor'
                     />
                     <path
                        id='Path_79'
                        data-name='Path 79'
                        d='M1021.089,375.556c-10.29-6.507-21.786-8.882-33.814-8.9-30.325-.045-60.651-.178-90.973.111a80.4,80.4,0,0,0-20.154,2.8c-10.758,2.932-20.2,8.238-25.523,18.845-6.294,12.534-3.751,32.175,15.284,38.364a69.9,69.9,0,0,0,21.48,3.2c18.118.081,36.237.027,54.356.027h22.4c7.558,0,15.124.214,22.673-.052,8.34-.291,16.725-.579,24.979-1.691,7.845-1.057,14.857-4.543,19.524-11.292C1040.261,404.043,1036.544,385.334,1021.089,375.556ZM901.96,418.428a20.364,20.364,0,1,1,20.4-20.329A20.364,20.364,0,0,1,901.96,418.428Zm77.794-.039a20.315,20.315,0,0,1-20.585-20.041c-.005-.224-.006-.449,0-.673a20.318,20.318,0,1,1,20.588,20.714Z'
                        fill='currentColor'
                     />
                     <path
                        id='Path_80'
                        data-name='Path 80'
                        d='M969.9,448.294c-1.895-1.341-3.681-1.086-5.345.636a27.729,27.729,0,0,1-4.065,3.58c-11.727,8.13-34.213,7.637-44.013-3.934-1.427-1.685-3.5-1.556-5.161-.2a3.556,3.556,0,0,0-.348.274,3.417,3.417,0,0,0-.252,4.826,24.778,24.778,0,0,0,4.38,4.446c6.7,5.149,14.445,7.421,22.836,8.274,4.047-.394,8.162-.454,12.131-1.259,7.847-1.584,15.171-4.389,20.237-11.13C971.813,451.788,971.682,449.556,969.9,448.294Z'
                        fill='currentColor'
                     />
                     <path
                        id='Path_81'
                        data-name='Path 81'
                        d='M902.039,382.824a15.283,15.283,0,0,0-15.36,15.206c.026,8.509,7.083,15.67,15.352,15.57a15.591,15.591,0,0,0,15.214-15.416A15.285,15.285,0,0,0,902.039,382.824Zm.229,19.4a4.426,4.426,0,0,1-4.17-4.485,4.311,4.311,0,0,1,4.382-3.942,4.356,4.356,0,0,1,4.2,4.156A4.505,4.505,0,0,1,902.268,402.226Z'
                        fill='currentColor'
                     />
                     <path
                        id='Path_82'
                        data-name='Path 82'
                        d='M979.485,382.811a15.283,15.283,0,0,0-15.36,15.206c.026,8.509,7.083,15.67,15.352,15.57a15.591,15.591,0,0,0,15.214-15.416A15.285,15.285,0,0,0,979.485,382.811Zm.229,19.4a4.426,4.426,0,0,1-4.17-4.485,4.311,4.311,0,0,1,4.382-3.942,4.356,4.356,0,0,1,4.2,4.156A4.505,4.505,0,0,1,979.714,402.213Z'
                        fill='currentColor'
                     />
                     <path
                        id='Path_83'
                        data-name='Path 83'
                        d='M871.219,525.882H870.7Q870.969,525.885,871.219,525.882Z'
                        fill='#157CFC'
                     />
                  </g>
               </g>
            </svg>
         </>
      );
   };

   const columns = [
      {
         field: 'profile',
         headerName: t?.page_settings_users_profile,
         flex: 1,
         minWidth: 200,
         renderCell: ({ row }) => {
            return (
               <div
                  style={{
                     display: 'flex',
                     flexDirection: 'row',
                     alignItems: 'center',
                  }}
               >
                  {row.type === USERS_TYPE.BOT ? (
                     <div style={{ color: '#157CFC', display: 'flex' }}>
                        <BotLogo />
                     </div>
                  ) : (
                     <div style={{ position: 'relative' }}>
                        <Avatar
                           alt={row.name}
                           src={row.photo ? row.photo : SITE_URL + '/none.jpg'}
                           style={{ width: 42, height: 42 }}
                        />
                        <span
                           className={classes.statusAvatar}
                           style={{
                              backgroundColor: row.status === 2 ? '#49be25' : '#ababab',
                           }}
                        />
                     </div>
                  )}
                  <div
                     style={{
                        marginLeft: 15,
                     }}
                  >
                     <Typography style={{ fontWeight: 'bold' }} className={classes.tableProfileTexts}>
                        {row.name} {row.surname}
                     </Typography>
                     <Typography className={classes.tableProfileTexts}>
                        {row.type === USERS_TYPE.BOT ? '-' : row.email}
                     </Typography>
                  </div>
               </div>
            );
         },
      },
      {
         field: 'status',
         headerName: t?.page_settings_users_status,
         // flex: 1,
         minWidth: 150,
         renderCell: ({ row }) => {
            return (
               <>
                  {row.type === USERS_TYPE.BOT ? (
                     <Typography className={classes.tableProfileTexts}>-</Typography>
                  ) : (
                     <Typography
                        style={{
                           color: StatusOptions[row.accountStatus]?.color || 'black',
                        }}
                        className={classes.tableSubtitle}
                     >
                        {StatusOptions[row.accountStatus]?.label}
                     </Typography>
                  )}
               </>
            );
         },
      },
      {
         field: 'role',
         headerName: t?.page_settings_users_role,
         // flex: 1,
         minWidth: 100,
         renderCell: ({ row }) => {
            return (
               <Typography
                  style={{
                     color: getPersonRoleColor(row.type),
                     fontWeight: 'bold',
                  }}
               >
                  {row.type === USERS_TYPE.OWNER
                     ? t?.owner
                     : row.type === USERS_TYPE.ADMIN
                     ? t?.admin
                     : row.type === USERS_TYPE.PERSONEL
                     ? t?.employee
                     : 'Bot'}
               </Typography>
            );
         },
      },
      {
         field: 'teams',
         headerName: t?.page_settings_users_teams,
         minWidth: 80,
         sortable: false,
         renderCell: ({ row }) => {
            return (
               <Typography
                  style={{
                     color: row.signedTeams?.length > 0 || row.type === USERS_TYPE.BOT ? 'initial' : 'red',
                     fontWeight: 'bold',
                  }}
               >
                  {row.type !== USERS_TYPE.BOT
                     ? row.signedTeams?.length > 0
                        ? row.signedTeams?.length + ' ' + t?.teams_with_number
                        : t.no_teams
                     : '-'}
               </Typography>
            );
         },
      },
      {
         field: 'conservationLimit',
         headerName: t?.conversation_limit,
         // flex: 1,
         minWidth: 100,
         align: 'center',
         sortable: false,
         renderCell: ({ row }) => {
            return (
               <>
                  {row.type === USERS_TYPE.BOT ? (
                     <Typography className={classes.tableProfileTexts}>-</Typography>
                  ) : (
                     <Typography
                        style={{
                           color: 'rgb(21, 124, 252)',
                        }}
                        className={classes.tableSubtitle}
                     >
                        {row.chatParticipateLimit}
                     </Typography>
                  )}
               </>
            );
         },
      },
      {
         field: 'operation',
         headerName: t?.page_settings_process,
         flex: 1,
         headerClassName: 'operationColum',
         sortable: false,
         headerAlign: 'center',
         align: 'center',
         minWidth: 150,
         renderCell: ({ row }) => {
            return (
               <>
                  <Grid container justifyContent='center' alignItems='center'>
                     {!row.isEmailVerified && row.type !== USERS_TYPE.BOT && (
                        <ActionIconButton
                           onClick={() => {
                              handleResendPasswordCreateEmail(row.id);
                           }}
                           tooltip={t?.refetch_products}
                           actionType={'REFRESH'}
                        />
                     )}
                     {(row.type !== USERS_TYPE.OWNER || row.id.toString() === me._id.toString()) && (
                        <ActionIconButton
                           onClick={() =>
                              row.id.toString() !== me._id.toString()
                                 ? handleEditRow(row)
                                 : (window.location.href = '/account/profile')
                           }
                           tooltip={t?.comp_button_edit}
                           actionType='EDIT'
                        />
                     )}
                     {row.id.toString() !== me._id.toString() &&
                        row.type !== USERS_TYPE.BOT &&
                        row.type !== USERS_TYPE.OWNER && (
                           <ActionIconButton
                              onClick={() => handleDeleteRow(row)}
                              tooltip={t?.comp_button_delete}
                              actionType='DELETE'
                           />
                        )}
                  </Grid>
                  {row.type === USERS_TYPE.OWNER && row.id.toString() !== me._id.toString() ? (
                     <Typography className={classes.tableSubtitle}>
                        {t.page_settings_users_owner_cannot_be_deleted}
                     </Typography>
                  ) : null}
               </>
            );
         },
      },
   ];

   return (
      <Fragment>
         {loadingOrFetching && <div className='loading'></div>}
         <div
            className={classes.content}
            style={{
               height: '100%',
               width: '100%',
            }}
         >
            <Grid
               container
               wrap='nowrap'
               style={{
                  height: '100%',
               }}
            >
               <Paper elevation={1} className={classes.paper}>
                  <DataGrid
                     loading={personsLoading}
                     className={classes.Table}
                     components={{
                        Toolbar: (props) => {
                           return (
                              <div
                                 container
                                 className={classes.titleContainer}
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <div style={{ flexGrow: '1', fontSize: '20px', fontWeight: 'bold' }}>
                                    {t?.page_settings_typo_users}
                                 </div>
                                 <div>
                                    <SearchBar
                                       value={text}
                                       onChange={setText}
                                       placeholder={t['search']}
                                       style={{ width: 200, height: 40 }}
                                    />
                                 </div>
                                 <div>
                                    <Typography
                                       style={{
                                          margin: '0px 10px',
                                          color:
                                             person.customer.usagePersonCount < person.customer.package.totalPersonCount
                                                ? 'initial'
                                                : 'red',
                                       }}
                                    >
                                       {person.customer.usagePersonCount} / {person.customer.package.totalPersonCount}
                                    </Typography>
                                 </div>

                                 <div style={{ paddingLeft: 5 }}>
                                    <ButtonOK
                                       onClick={handleCreateNewRow}
                                       width={150}
                                       height={40}
                                       fontSize='1.1rem'
                                       disabled={
                                          person.customer.usagePersonCount >= person.customer.package.totalPersonCount
                                       }
                                       label={t['page_settings_button_invite_user']}
                                    />
                                 </div>
                              </div>
                           );
                        },
                     }}
                     disableSelectionOnClick
                     localeText={{
                        toolbarColumns: '',
                        noRowsLabel: t?.no_data_to_show,
                     }}
                     rowHeight={64}
                     disableColumnMenu
                     disableColumnSelector
                     classes={{ columnHeader: classes.rowStyle }}
                     pageSize={Page.pageSize}
                     paginationMode='server'
                     page={queryParams.page - 1}
                     onPageChange={(params) => {
                        setQueryParams((prevState) => ({
                           ...prevState,
                           page: params + 1,
                        }));
                     }}
                     disableColumn
                     rows={personList}
                     rowCount={personListCount}
                     columns={columns}
                  />
               </Paper>
            </Grid>
         </div>

         <Modal
            paperStyle={{ width: modalConfig.mode === 'DELETE' ? 450 : 600 }}
            open={modalConfig.show}
            onClose={handleCloseModal}
            modalHeader={
               modalConfig.mode === 'NEW'
                  ? t['page_settings_button_invite_user']
                  : modalConfig.mode === 'DELETE'
                  ? t['delete_user']
                  : modalConfig.mode === 'EDIT'
                  ? t['page_settings_button_edit_user']
                  : modalConfig.mode === 'RESET_PASSWORD' && t['user_reset_password']
            }
         >
            <div>
               {/* Add New User Modal */}
               {modalConfig.mode === 'NEW' && (
                  <AddNewUserModal
                     teamsData={teamsData?.getTeamList?.data?.docs}
                     onAccept={addPersonSubmit}
                     onCancel={handleCloseModal}
                  />
               )}

               {modalConfig.mode === 'DELETE' && (
                  <DeleteUserModal person={modalConfig.row} onDelete={handleDelete} onClose={handleCloseModal} />
               )}

               {/* Edit Modal */}
               {modalConfig.mode === 'EDIT' && (
                  <EditUserModal
                     onAccept={editPersonSubmit}
                     teamsData={teamsData?.getTeamList?.data?.docs}
                     person={modalConfig.row}
                     onCancel={handleCloseModal}
                  />
               )}
               {/* Reset Password Modal */}
               {modalConfig.mode === 'RESET_PASSWORD' && (
                  <ResetPasswordModal
                     person={modalConfig.row}
                     onAccept={resetPersonPasswordSubmit}
                     onCancel={handleCloseModal}
                  />
               )}
            </div>
         </Modal>
      </Fragment>
   );
};

export default Users;

import React, { Fragment, useContext, useState } from 'react';
import { Grid, Button, Typography, TextField, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import clsx from 'clsx';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { useCoupon } from '@hooks/useCoupon';
import moment from 'moment';
import { priceFormat } from '@helper/functions';
import { MONTHS, YEARS } from '@lib/constants';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { RenewalType } from '@types/enums';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 1000,
      paddingTop: 20,
      paddingBottom: 20,
      paddingRight: 20,
      borderRadius: 18,
      [theme.breakpoints.down('sm')]: {
         width: 450,
      },
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      boxShadow: '0px 3px 6px #00000029',
      background: '#FFFFFF 0% 0% no-repeat padding-box',
   },
   newRenevalContainer: {
      backgroundColor: '#DBEBFF',
   },
   payBtnContainer: {
      marginTop: 20,
   },
   cancelBtn: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginLeft: 20,
      height: 40,
   },
   content: {
      padding: 20,
      maxHeight: '70vh',
      overflowY: 'auto',
   },
   subtitle: {
      padding: '25px',
      color: 'black',
   },
   bold: {
      fontWeight: 'bold',
   },
   title: {
      fontSize: '1.1rem',
      color: '#385273',
      padding: 10,
   },

   primaryText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   item: {
      padding: 20,
      border: '1px solid #E3E3E3',
      borderRadius: 12,
      marginTop: 10,
      height: 291,
   },
   padding5: {
      padding: 5,
   },
   dashedDivider: {
      alignSelf: 'stretch',
      display: 'flex',
      height: 0,
      flexGrow: 1,
      backgroundColor: 'transparent',
      border: '1px dashed #E3E3E3',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const ChangeMainPackageModal = ({ person, product, onAccept, buyMode, onClose }) => {
   const classes = useStyles();
   const [couponCode, setCouponCode] = useState('');
   const currentPackage = person.customer.package;
   const customer = person.customer;
   // If it 0 -> monthly
   // 1 -> yearly
   const currentPackageIsMonthly = currentPackage.periodMode === 0;
   const productIsMonthly = buyMode === RenewalType.MONTHLY; // This is coming from state

   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const [couponState, { loading: couponLoading, getCouponCode }] = useCoupon(
      productIsMonthly
         ? product.monthlyPrice * currentPackage.totalPersonCount
         : product.yearlyPrice * currentPackage.totalPersonCount,
   );
   const calculatedTaxProductPrice = (couponState.totalPrice * 18) / 100;

   const getPriceFromMode = () => {
      if (productIsMonthly) {
         return product.monthlyPrice;
      } else {
         return product.yearlyPrice;
      }
   };

   return (
      <Grid>
         <ModalLargeText style={{ textAlign: 'center' }}>{t?.change_main_package_modal_title}</ModalLargeText>

         <Grid xs='12' container item className={classes.content} alignItems='center'>
            <Grid item container xs='12' direction='column'>
               {/* Current Reneval Details */}
               <Grid item container xs='12' className={classes.item}>
                  <Grid item xs='12'>
                     <Typography variant='body1'>{t?.reneval_details}</Typography>
                  </Grid>
                  <Grid item xs='12'>
                     <Divider />
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>{t?.renewal_type}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>
                        {currentPackageIsMonthly ? t?.monthly : t?.yearly}
                     </Typography>
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>
                        {' '}
                        {currentPackageIsMonthly
                           ? t?.monthly_price_for_this_package
                           : t?.yearly_price_for_this_package}{' '}
                     </Typography>
                     <Typography style={{ fontWeight: 'bold' }}>
                        {currentPackageIsMonthly
                           ? priceFormat(currentPackage.price)
                           : `12 * $${currentPackage.price / 12} = ${priceFormat(currentPackage.price)}`}
                     </Typography>
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>{t?.next_billing_date}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>
                        {moment(currentPackage.endDate).format('DD/MM/YY')}
                     </Typography>
                  </Grid>
               </Grid>
               {/* Current Package */}
               <Grid item container xs='12' className={classes.item}>
                  <Grid item xs='12'>
                     <Typography variant='body1'>{t?.current_package}</Typography>
                  </Grid>
                  <Grid item xs='12'>
                     <Divider />
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>
                        <Typography component='span' variant='subtitle1' className={clsx(classes.bold)}>
                           {t?.main_plan + ' '}
                        </Typography>
                        / {currentPackage?.name} / {currentPackageIsMonthly ? t?.monthly : t?.yearly}
                     </Typography>
                     <Typography style={{ fontWeight: 'bold' }}>{priceFormat(currentPackage.price)}</Typography>
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>{t?.user}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>{currentPackage.totalPersonCount}</Typography>
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>{t?.tax_included}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>
                        {priceFormat((currentPackage.price * 18) / 100)}
                     </Typography>
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>{t?.discount}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>{priceFormat(0)}</Typography>
                  </Grid>
                  <Divider className={classes.dashedDivider} />
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography style={{ fontWeight: 'bold' }}>{t?.total}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>{priceFormat(currentPackage.price)}</Typography>
                  </Grid>
               </Grid>
            </Grid>

            {/* Right Section */}
            <Grid item container xs='12' direction='column' className={classes.pl20}>
               {/* New Reneval Details */}
               <Grid item container xs='12' className={clsx(classes.item, classes.newRenevalContainer)}>
                  <Grid item xs='12'>
                     <Typography variant='body1'>
                        {' '}
                        {t?.new} {t?.reneval_details}
                     </Typography>
                  </Grid>
                  <Grid item xs='12'>
                     <Divider />
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>{t?.first_billing_date}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>
                        {moment(currentPackage.endDate).format('DD/MM/YY')}
                     </Typography>
                  </Grid>

                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>
                        {' '}
                        {productIsMonthly ? t?.monthly_price_for_this_package : t?.yearly_price_for_this_package}{' '}
                     </Typography>
                     <Typography style={{ fontWeight: 'bold' }}>
                        {productIsMonthly
                           ? priceFormat(product.monthlyPrice)
                           : `12 * ${priceFormat(product.yearlyPrice / 12)} = ${priceFormat(product.yearlyPrice)}`}
                     </Typography>
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>{t?.next_billing_date}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>
                        {/* Add Month or Year To End Date */}
                        {moment(currentPackage.endDate)
                           .add('1', productIsMonthly ? MONTHS : YEARS)
                           .format('DD/MM/YY')}
                     </Typography>
                  </Grid>
               </Grid>
               {/* New Package */}
               <Grid item container xs='12' className={clsx(classes.item, classes.newRenevalContainer)}>
                  <Grid item xs='12'>
                     <Typography variant='body1'>{t?.new_package}</Typography>
                  </Grid>
                  <Grid item xs='12'>
                     <Divider />
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>
                        <Typography component='span' variant='subtitle1' className={clsx(classes.bold)}>
                           {t?.main_plan + ' '}
                        </Typography>
                        / {product?.name} / {productIsMonthly ? t?.monthly : t?.yearly}
                     </Typography>
                     <Typography style={{ fontWeight: 'bold' }}>{priceFormat(getPriceFromMode())}</Typography>
                  </Grid>
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>{t?.user}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>{currentPackage.totalPersonCount}</Typography>
                  </Grid>
                  {/* TAX */}
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography>{t?.tax_included}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>{priceFormat(calculatedTaxProductPrice)}</Typography>
                  </Grid>
                  {/* DISCOUNT */}
                  <Grid
                     container
                     item
                     justifyContent='space-between'
                     className={classes.padding5}
                     style={{ color: couponState.couponStatus && 'green' }}
                  >
                     <Typography>
                        {t?.discount} {couponState.couponCodeText}
                     </Typography>
                     <Typography style={{ fontWeight: 'bold' }}>{priceFormat(couponState.couponDiscount)}</Typography>
                  </Grid>
                  <Divider className={classes.dashedDivider} />
                  {/* TOTAL */}
                  <Grid item container justifyContent='space-between' className={classes.padding5}>
                     <Typography style={{ fontWeight: 'bold' }}>{t?.total}</Typography>
                     <Typography style={{ fontWeight: 'bold' }}>
                        {`${customer.package.totalPersonCount} * ${
                           productIsMonthly ? priceFormat(product.monthlyPrice) : priceFormat(product.yearlyPrice)
                        } = `}
                        {priceFormat(couponState.totalPrice)}
                     </Typography>
                  </Grid>
               </Grid>
            </Grid>
            <Grid className={classes.couponContainer} item container justifyContent='flex-end' xs={12}>
               <Grid item xs={12} className={classes.pl20}>
                  <TextField
                     placeholder={t.enter_coupon_code}
                     disabled={couponState.couponStatus}
                     type='text'
                     fullWidth
                     variant='outlined'
                     inputProps={{
                        maxLength: 11,
                     }}
                     onChange={(e) => setCouponCode(e.target.value)}
                     InputProps={{
                        endAdornment: (
                           <Fragment>
                              {couponCode.trim().length === 11 && (
                                 <Button
                                    onClick={() => {
                                       getCouponCode({
                                          variables: { code: couponCode.toUpperCase() },
                                       });
                                    }}
                                    disabled={couponState.couponStatus}
                                    style={{ width: 150 }}
                                    color='secondary'
                                    variant='text'
                                 >
                                    {t?.apply_coupon}
                                 </Button>
                              )}
                           </Fragment>
                        ),
                     }}
                  />
                  {couponLoading && <div className='loader' />}
                  {couponState.couponErrorMessage && !couponState.couponStatus && (
                     <span style={{ color: 'red', fontSize: '14px' }}>{couponState.couponErrorMessage}</span>
                  )}
                  {!!couponState.couponStatus && couponState.couponErrorMessage.length > 0 && (
                     <span style={{ color: 'green', fontSize: '14px' }}>{couponState.couponErrorMessage}</span>
                  )}
                  {!couponState.isPaying && (
                     <Grid container justifyContent='flex-end' item xs={12}>
                        <ButtonOK
                           fullWidth={true}
                           label={t?.comp_button_continue}
                           onClick={() =>
                              onAccept({
                                 productId: product._id,
                                 couponCode,
                                 customerId: customer._id,
                                 periodMode: productIsMonthly ? 0 : 1,
                              })
                           }
                        />
                     </Grid>
                  )}
               </Grid>
            </Grid>
         </Grid>

         {/* Buttons */}
         <ModalButtonField>
            <Button
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
               className={classes.modalButtons}
               onClick={() => {
                  onClose();
               }}
            >
               <Typography style={{ fontWeight: 'bold' }}>{t?.comp_button_cancel}</Typography>
            </Button>
            <ButtonOK
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
               label={t?.change_package}
               disabled={!couponState.isPaying}
               onClick={() => {
                  onAccept({
                     productId: product._id,
                     periodMode: productIsMonthly ? 0 : 1,
                     customerId: customer._id,
                     code: couponCode,
                  });
               }}
            />
         </ModalButtonField>
      </Grid>
   );
};

export default ChangeMainPackageModal;

import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { API_URL } from '@helper/config';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CREATE_BULK_SHARING, FINALIZE_BULK_SHARING } from '@queries/BulkMessaging/mutation';
import { BULK_CHECK_SELECTED_TARGETS } from '@queries/BulkMessaging/query';
import { BULK_SHARING_CREATE } from '@queries/BulkMessaging/subscription';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LeftPanel, { LeftBarData } from './components/leftBar';
import RightPanel, { ActiveTab, RightBarData } from './components/rightBar';
import TopBar from './components/topBar';
import { BulkMessageStateData, CreateBulkSharingOutput, FinalizeBulkSharingOutput, SenderType, SendingOption } from './types';
import LeaveOrSendModal from './views/leaveOrSendModal';

const useStyles = makeStyles((theme: any) => ({
   content: {
      flexGrowY: 1,
      height: '100vh',
      width: '100%',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
         minHeight: '-webkit-fill-available',
         height: '100%',
      },
      backgroundColor: '#F7F7FA',
      borderRadius: 16,
      border: '10px solid F7F7FA',
      paddingTop: 60,
   },
   container: {
      height: '100%',
      width: '100%',
   },
   tab: {
      height: 'calc(100% - 115px)',
      overflowY: 'auto',
      overflowX: 'hidden',
   },
   tabSection: {
      // width: isSmallScreen ? '100%' : 350,
      width: 350,
      marginTop: 5,
      backgroundColor: 'white',
      paddingRight: 10,
      paddingLeft: 16,
   },
   tabSectionTitle: {
      marginTop: 5,
      marginBottom: 0,
      paddingLeft: 2,
      fontSize: '1.1rem',
      fontWeight: 700,
   },
}));

const BulkMessagingSend = () => {
   const classes = useStyles();
   const person = useSelector((reducer: any) => reducer.personReducer.person);
   const token = useSelector((reducer: any) => reducer.personReducer.token);
   const { lang } = useContext(LanguageContext);
   const history = useHistory();
   const dispatch = useDispatch();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const t = lang?.translation;
   const sendMessageState: BulkMessageStateData = useSelector((rootReducer: any) => rootReducer.bulkMessagingReducer);
   const [sendOrLeaveModalConfig, setSendOrLeaveModalConfig] = useState({ visible: false, isSendModalOpen: false });
   const [rightPanelState, setRightPanelState] = useState<RightBarData>({
      uploading: false,
      selectedFolders: [],
      activeTab: ActiveTab.SelectedFolders,
   });
   const [loadingPercent, setLoadingPercent] = useState(-1);

   const setSendMessageState = (payload: BulkMessageStateData) => {
      dispatch({
         type: 'CHANGE_BULK_MESSAGING_STATE',
         payload,
      });
   };

   const [checkSelectedTargets, { loading: checkSelectedTargetLoading }] = useLazyQuery(BULK_CHECK_SELECTED_TARGETS, {
      fetchPolicy: 'no-cache',
      onCompleted: ({
         checkSelectedTargets: { data },
      }: {
         checkSelectedTargets: {
            data: string[];
         };
      }) => {
         const selectedChatIds = sendMessageState.selectedChatIds.filter((chat) => !data.includes(chat.id));
         const selectedPersonGroupIds = sendMessageState.selectedPersonGroupIds.filter(
            (personGroup) => !data.includes(personGroup.id),
         );

         setSendMessageState({
            ...sendMessageState,
            selectedChatIds,
            selectedPersonGroupIds,
         });
      },
   });

   const [createBulkSharing, { loading: createBulkSharingLoading }] = useMutation<CreateBulkSharingOutput>(CREATE_BULK_SHARING, {
      fetchPolicy: 'no-cache',
   });

   const [finalizeBulkSharing, { loading: finalizeBulkSharingLoading }] = useMutation<FinalizeBulkSharingOutput>(FINALIZE_BULK_SHARING, {
      fetchPolicy: 'no-cache',
   });

   useEffect(() => {
      checkSelectedTargets({
         variables: {
            input: {
               customer: person.customer._id,
               telegramChannels: sendMessageState.selectedChatIds
                  .filter((x) => x.type == SenderType.TelegramChats)
                  .map((x) => x.id),
               whatsAppGroups: sendMessageState.selectedChatIds
                  .filter((x) => x.type == SenderType.WhatsappGroups)
                  .map((x) => x.id),
               qpienPersonGroups: sendMessageState.selectedPersonGroupIds.map((x) => x.id),
            },
         },
      });
   }, []);

   useEffect(() => {
      if (!person.customer.isWholeSaler) {
         enqueueSnackbar(t['access_denied_to_this_module'], {
            // send error message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className={'snackbar-error'} onClick={() => closeSnackbar(key)}>
                  {t['modal_close']}
               </div>
            ),
         });

         history.push('/');
      }
   }, [person, history, enqueueSnackbar, closeSnackbar, t]);

   const onSend = async () => {
      setRightPanelState({
         ...rightPanelState,
         uploading: true,
      });

      const fileBlobs = rightPanelState.selectedFolders.map((x) => x.files.map((f) => f.file)).flat();

      const chunks: {
         file: string;
         part: number;
         totalSize: number;
         totalParts: number;
         bytes: Uint8Array;
      }[] = [];

      const CHUNK_SIZE = 1024 * 1024 * 5;

      for (let file of fileBlobs) {
         let parts = await file.arrayBuffer();
         const totalParts = Math.ceil(parts.byteLength / CHUNK_SIZE);

         for (let part = 0; part < totalParts; part++) {
            const start = part * CHUNK_SIZE;
            const end = Math.min((part + 1) * CHUNK_SIZE, parts.byteLength);

            chunks.push({
               file: file.name,
               part: part + 1,
               totalSize: parts.byteLength,
               totalParts: totalParts,
               bytes: new Uint8Array(parts.slice(start, end)),
            });
         }
      }

      const { data } = await createBulkSharing({
         variables: {
            customerId: person.customer._id,
            qpienPersonGroups: sendMessageState.selectedPersonGroupIds.map((x) => x.id),
            whatsAppGroups: sendMessageState.selectedChatIds.filter((x) => x.type == SenderType.WhatsappGroups).map((x) => x.id),
            telegramChannels: sendMessageState.selectedChatIds.filter((x) => x.type == SenderType.TelegramChats).map((x) => x.id),
            whatsAppSendFolderNames: sendMessageState.whatsAppSendFolderNames,
            telegramSendFolderNames: sendMessageState.telegramSendFolderNames,
            telegramSendingType: sendMessageState.telegramSendingType,
         }
      })

      if (data) {
         let success = true;
         let createBulkSharing = data.createBulkSharing.data;
         for (let i = 0; i < chunks.length; i++) {
            const chunk = chunks[i];
            const formData = new FormData();
            formData.set('_id', createBulkSharing._id);
            formData.set('customer', person.customer._id);
            formData.set('fileName', chunk.file);
            formData.set('totalSize', chunk.totalSize.toString());
            formData.set('part', chunk.part.toString());
            formData.set('totalParts', chunk.totalParts.toString());
            formData.set('data', new Blob([chunk.bytes], { type: 'application/octet-stream' }));

            try {
               await fetch(`${API_URL}/bulkSharing/send`, {
                  method: 'POST',
                  body: formData,
                  headers: {
                     contentType: "application/x-www-form-urlencoded; charset=UTF-8",
                  },
               })
               setLoadingPercent(100 * (i + 1) / chunks.length);
            } catch (error) {
               success = false;
               enqueueSnackbar(t['connection_progress_error'], {
                  variant: 'error',
                  autoHideDuration: 1500,
               });
               setSendOrLeaveModalConfig({
                  visible: false,
                  isSendModalOpen: true,
               });
               break;
            }
         }

         setLoadingPercent(-1);
         if (success) {
            finalizeBulkSharing({
               variables: {
                  _id: createBulkSharing._id,
               }
            }).then(() => {
               setSendOrLeaveModalConfig({
                  visible: false,
                  isSendModalOpen: false,
               });
               setRightPanelState({
                  ...rightPanelState,
                  activeTab: ActiveTab.SendingFolders,
                  uploading: false,
                  selectedFolders: [],
               });
               //@ts-ignore
               document.getElementById('selectFolder').value = '';
               enqueueSnackbar(t?.bulk_send_complete, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            })
               .catch((error) => {
                  enqueueSnackbar(t['connection_progress_error'], {
                     variant: 'error',
                     autoHideDuration: 1500,
                  });
                  setSendOrLeaveModalConfig({
                     visible: false,
                     isSendModalOpen: true,
                  });
               })
         }
      }
   };

   return (
      <Grid container className={classes.content}>
         <Grid xs={12}>
            <TopBar
               onLeave={() => {
                  setSendOrLeaveModalConfig({
                     visible: true,
                     isSendModalOpen: false,
                  });
               }}
               onSend={(() => {
                  const anyChatSelected = sendMessageState.selectedChatIds.length > 0;
                  const isAnyChatSelectedOrAutoFolderSending = sendMessageState.telegramSendingType == SendingOption.ByFolderName;
                  const anyPersonGroupSelected = sendMessageState.selectedPersonGroupIds.length > 0;
                  if (((anyChatSelected || isAnyChatSelectedOrAutoFolderSending) || anyPersonGroupSelected) && !checkSelectedTargetLoading &&
                     rightPanelState.selectedFolders.length) {
                     return () => setSendOrLeaveModalConfig({
                        visible: true,
                        isSendModalOpen: true,
                     })
                  } else {
                     return null;
                  }
               })()
               }
            />
         </Grid>
         <Grid container className={classes.container} wrap='nowrap'>
            <LeftPanel
               disabled={rightPanelState.uploading && checkSelectedTargetLoading}
               leftBarData={sendMessageState}
               onUpdate={(leftBarData: LeftBarData) => setSendMessageState(leftBarData)}
            />
            <RightPanel
               rightBarData={rightPanelState}
               onUpdate={(rightBarData: RightBarData) => setRightPanelState(rightBarData)}
            />
         </Grid>
         <LeaveOrSendModal
            loadingPercent={loadingPercent}
            isSend={sendOrLeaveModalConfig.isSendModalOpen}
            isLoading={rightPanelState.uploading || checkSelectedTargetLoading}
            isOpen={sendOrLeaveModalConfig.visible}
            onAccept={
               sendOrLeaveModalConfig.isSendModalOpen
                  ? onSend
                  : () => {
                     window.location.href = '/bulk-messaging';
                  }
            }
            onClose={() =>
               setSendOrLeaveModalConfig({
                  visible: false,
                  isSendModalOpen: false,
               })
            }
         />
      </Grid>
   );
};

export default BulkMessagingSend;

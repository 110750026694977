import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';

const useStyles = makeStyles(() => ({
   pageLayoutContainer: {
      display: 'flex',
      height: '100%',
   },
   mainContainer: {
      flex: 4,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: 15,
      overflow: 'hidden',
   },
   previewContainer: {
      flex: 2,
      display: 'flex',
      paddingLeft: '20px',
   },
}));

const PageLayout = ({ nav, form, previews }) => {
   const classes = useStyles();

   return (
      <div className={classes.pageLayoutContainer}>
         <Paper elevation={1} className={classes.mainContainer}>
            {nav}
            {form}
         </Paper>
         <div className={classes.previewContainer}>{previews}</div>
      </div>
   );
};

export default PageLayout;

import FacebookMessengerIcon from '@components/SocialMediaIcon/facebookMessengerIcon';
import InstagramIcon from '@components/SocialMediaIcon/instagramIcon';
import LiveChatIcon from '@components/SocialMediaIcon/liveChatIcon';
import WhatsAppIcon from '@components/SocialMediaIcon/whatsappIcon';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { DataGrid } from '@material-ui/data-grid';
import React, { useContext } from 'react';
import GaugeChart from 'react-gauge-chart';
import PercentageSection from './percentageSection';

const ITEMS = (classes, data) => {
   let total = data?.whatsAppWeb + data?.whatsAppBusiness + data?.facebook + data?.instagram + data?.liveChat;
   let whatsApp = ((data?.whatsAppWeb + data?.whatsAppBusiness) / total) * 100;
   let facebook = (data?.facebook / total ) * 100;
   let instagram = (data?.instagram / total ) * 100;
   let liveChat = (data?.liveChat / total ) * 100;

   return [
      {
         tooltip: 'LiveChat',
         icon: <LiveChatIcon className={classes.mainIcon} selected />,
         color: '#34589D',
         value: data?.liveChat,
         percentage: liveChat,
      },
      {
         tooltip: 'WhatsApp',
         icon: <WhatsAppIcon className={classes.mainIcon} selected />,
         color: '#25D366',
         value: data?.whatsAppWeb + data?.whatsAppBusiness,
         percentage: whatsApp,
      },
      {
         tooltip: 'Instagram',
         icon: <InstagramIcon className={classes.mainIcon} selected />,
         color: '#E1306C',
         value: data?.instagram,
         percentage: instagram,
      },
      {
         tooltip: 'Facebook',
         icon: <FacebookMessengerIcon className={classes.mainIcon} selected />,
         color: '#34589D',
         value: data?.facebook,
         percentage: facebook,
      },
   ];
};

const ChannelPerformance = ({ channelData, performanceData, personData }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const parseTime = (ms) => {
      let hour = Math.floor(ms / 3600000);
      let minute = Math.floor((ms % 3600000) / 60000);
      let second = Math.floor(((ms % 3600000) % 60000) / 1000);
      let newTime;

      let h_identifier = t?.format_h;
      let m_identifier = t?.format_m;
      let s_identifier = t?.format_s;

      if (hour > 0) {
         newTime = `${hour}${h_identifier} ${minute}${m_identifier} ${second}${s_identifier}`;
      } else if (minute > 0) {
         if (second === 0) newTime = `${minute}${m_identifier}`;
         else newTime = `${minute}${m_identifier} ${second}${s_identifier}`;
      } else {
         newTime = `${second}${s_identifier}`;
      }
      return newTime;
   };

   let users =
      performanceData && personData
         ? personData
              ?.map((item) => {
                 let personData = performanceData?.personsReport?.find((data) => data._id === item._id);
                 if (personData) {
                    return {
                       id: item._id,
                       name: item.name,
                       firstResponseTime: personData.firstResponseTime / personData.firstResponseCount || 0,
                       resolutionTime: personData.resolutionTime / personData.solvedChatCount || 0,
                    };
                 } else {
                    return null;
                 }
              })
              .filter((item) => item)
         : null;

   //sort by firstResponseTime
   users = users?.sort((a, b) => {
      return a.resolutionTime - b.resolutionTime;
   });

   const useStyles = makeStyles((theme) => ({
      paper: {
         textAlign: 'center',
         height: '240px',
         borderRadius: 15,
         transform: 'scale(1)  translate(0,0)',
      },
      title: {
         textAlign: 'left',
         fontSize: '15px',
         paddingLeft: theme.spacing(2),
         paddingTop: 12,
         color: theme.chatApp.general.pallet.passiveStructureBlue,
      },
      mainIcon: {
         width: 25,
         height: 25,
      },
      Table: {
         '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
         },
         height: 195,
         borderBottomRightRadius: 15,
         borderBottomLeftRadius: 15,
      },
   }));
   const classes = useStyles();
   const items = ITEMS(classes, channelData);

   const columns = [
      {
         field: 'name',
         headerName: t?.page_statistics_typo_users_table_user,
         sortable: false,
         flex: 3,
         renderCell: (params) => {
            return (
               <div
                  style={{
                     display: 'block',
                     marginLeft: 5,
                     color: '#385273',
                  }}
               >
                  {params.value}
               </div>
            );
         },
      },
      {
         field: 'firstResponseTime',
         headerName: t?.page_statistics_typo_users_table_first_response_time,
         sortable: false,
         flex: 2,
         renderCell: (params) => {
            return (
               <div
                  style={{
                     display: 'block',
                     marginLeft: 5,
                     color: params.value > performanceData?.teamFirstResponseGoalTime ? 'red' : 'green',
                  }}
               >
                  {parseTime(params.value)}
               </div>
            );
         },
      },
      {
         field: 'resolutionTime',
         headerName: t?.page_statistics_typo_users_table_resolution_time,
         type: 'number',
         sortable: false,
         flex: 2,
         renderCell: (params) => {
            return (
               <div
                  style={{
                     display: 'block',
                     marginRight: 4,
                     color: params.value > performanceData?.teamGoalTime ? 'red' : 'green',
                  }}
               >
                  {parseTime(params.value)}
               </div>
            );
         },
      },
   ];

   return (
      <Grid container spacing={4}>
         <Grid item xs={12} lg={4}>
            <Paper elevation={3} className={classes.paper}>
               <div style={{ height: 45, borderBottom: '1px solid rgb(238, 238, 238)', marginBottom: 10 }}>
                  <Typography className={classes.title}>{t['channel_performance']}</Typography>
               </div>
               <div>
                  <Grid container direction='column' justifyContent='space-evenly' alignItems=''>
                     {items.map((item) => (
                        <div style={{ marginTop: 10 }}>
                           <Grid container alignItems='center' key={item.name} item xs={12}>
                              <Grid item container justifyContent={'center'} alignItems={'center'} xs={2}>
                                 {item.icon}
                              </Grid>
                              <Grid item xs={8}>
                                 <PercentageSection item={item} />
                              </Grid>
                              <Grid item container justifyContent={'center'} xs={2}>
                                 <Typography bold>
                                    %{isNaN(item.percentage) ? 0 : item.percentage.toFixed(2)}{' '}
                                 </Typography>
                              </Grid>
                           </Grid>
                        </div>
                     ))}
                  </Grid>
               </div>
            </Paper>
         </Grid>

         <Grid item xs={12} lg={4}>
            <Paper elevation={3} className={classes.paper}>
               <div style={{ height: 45, borderBottom: '1px solid rgb(238, 238, 238)', marginBottom: 10 }}>
                  <Typography className={classes.title}>{t['company_performance']}</Typography>
               </div>
               <div>
                  <Grid container direction='row' justifyContent='space-between'>
                     <Grid item xs={6}>
                        <Grid container justifyContent='center' alignItems='stretch' direction='column'>
                           <Grid item>
                              <GaugeChart
                                 id={'gauge-chart1'}
                                 nrOfLevels={10}
                                 cornerRadius={2}
                                 arcsLength={
                                    performanceData?.teamFirstResponseGoalTime === 0
                                       ? [1.0]
                                       : [0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1, 0.1]
                                 }
                                 marginInPercent={0}
                                 hideText={true}
                                 animate={false}
                                 colors={
                                    performanceData?.teamFirstResponseGoalTime === 0
                                       ? ['#5BE12C']
                                       : [
                                            '#5BE12C',
                                            '#5BE12C',
                                            '#5BE12C',
                                            '#5BE12C',
                                            '#F5CD19',
                                            '#EA4228',
                                            '#EA4228',
                                            '#EA4228',
                                            '#EA4228',
                                            '#EA4228',
                                         ]
                                 }
                                 percent={(() => {
                                    let targetMs = performanceData?.teamFirstResponseGoalTime;
                                    if (targetMs === 0) return 0.5;
                                    let firstResponseTime = performanceData?.totalFirstResponseTime;
                                    let firstResponseCount = performanceData?.personsReport?.reduce(
                                       (a, b) => a + b.firstResponseCount,
                                       0,
                                    );
                                    let avgFirstResponseTime = firstResponseTime / firstResponseCount;
                                    let percent = !avgFirstResponseTime
                                       ? 0
                                       : (avgFirstResponseTime / (targetMs * 2)) * 1.0;
                                    return percent > 1.0 ? 1.0 : percent;
                                 })()}
                                 style={{
                                    width: 200,
                                    height: 100,
                                    marginBottom: 10,
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                 }}
                              />
                           </Grid>
                           <Grid
                              item
                              style={{
                                 borderRadius: 10,
                                 border: '1px solid rgb(234, 234, 234)',
                                 color: '#385273',
                                 marginInline: 8,
                                 paddingInline: 10,
                                 height: 60,
                                 marginTop: 5,
                              }}
                           >
                              <Grid container direction='column' style={{ marginBlock: 10 }}>
                                 <Grid item>
                                    <Grid container justifyContent='space-between' alignItems='stretch'>
                                       <Grid item xs={8}>
                                          <Typography style={{ fontSize: 13 }} align='left' noWrap>
                                             {t['page_statistics_typo_users_table_avarage_first_response_time']}
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography
                                             style={{
                                                fontSize: 13,
                                                color:
                                                   (performanceData?.totalFirstResponseTime
                                                      ? performanceData?.totalFirstResponseTime /
                                                        performanceData?.personsReport?.reduce(
                                                           (a, b) => a + b.firstResponseCount,
                                                           0,
                                                        )
                                                      : 0) > performanceData?.teamFirstResponseGoalTime
                                                      ? 'red'
                                                      : 'green',
                                             }}
                                             align='right'
                                             noWrap
                                          >
                                             {(() => {
                                                return parseTime(
                                                   performanceData?.totalFirstResponseTime
                                                      ? performanceData?.totalFirstResponseTime /
                                                           performanceData?.personsReport?.reduce(
                                                              (a, b) => a + b.firstResponseCount,
                                                              0,
                                                           )
                                                      : 0,
                                                );
                                             })()}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                                 <Grid item>
                                    <Grid container direction='row'>
                                       <Grid item xs={8}>
                                          <Typography style={{ fontSize: 13 }} align='left' noWrap>
                                             {t['page_settings_typo_chat_settings_team_goals_target']}
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography style={{ fontSize: 13 }} align='right' noWrap>
                                             {performanceData?.teamFirstResponseGoalTime === 0
                                                ? t?.dashboard_performance_no_target
                                                : parseTime(performanceData?.teamFirstResponseGoalTime)}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item xs={6}>
                        <Grid container justifyContent='center' alignItems='stretch' direction='column'>
                           <Grid item>
                              <GaugeChart
                                 id={'gauge-chart2'}
                                 nrOfLevels={10}
                                 cornerRadius={2}
                                 marginInPercent={0}
                                 animate={false}
                                 arcsLength={performanceData?.teamGoalTime === 0 ? [1.0] : [0.4, 0.1, 0.5]}
                                 colors={
                                    performanceData?.teamGoalTime === 0
                                       ? ['#5BE12C']
                                       : ['#5BE12C', '#F5CD19', '#EA4228']
                                 }
                                 hideText={true}
                                 percent={(() => {
                                    let targetMs = performanceData?.teamGoalTime;
                                    if (targetMs === 0) return 0.5;
                                    let resolutionTime = performanceData?.totalResolutionTime;
                                    let solvedChatCount = performanceData?.solvedChatsCount;
                                    let avgResolutionTime = resolutionTime / solvedChatCount;
                                    let percent = !avgResolutionTime ? 0 : (avgResolutionTime / (targetMs * 2)) * 1.0;
                                    return percent > 1.0 ? 1.0 : percent;
                                 })()}
                                 style={{
                                    width: 200,
                                    height: 100,
                                    marginBottom: 10,
                                    display: 'block',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                 }}
                              />
                           </Grid>
                           <Grid
                              item
                              style={{
                                 borderRadius: 10,
                                 border: '1px solid rgb(234, 234, 234)',
                                 color: '#385273',
                                 marginInline: 8,
                                 paddingInline: 10,
                                 height: 60,
                                 marginTop: 5,
                              }}
                           >
                              <Grid container direction='column' style={{ marginBlock: 10 }}>
                                 <Grid item>
                                    <Grid container justifyContent='space-between' alignItems='stretch'>
                                       <Grid item xs={8}>
                                          <Typography style={{ fontSize: 13 }} align='left' noWrap>
                                             {t['page_statistics_typo_users_table_avarage_resolution_time']}
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography
                                             style={{
                                                fontSize: 13,
                                                color:
                                                   (performanceData?.totalResolutionTime
                                                      ? performanceData?.totalResolutionTime /
                                                        performanceData?.solvedChatsCount
                                                      : 0) > performanceData?.teamGoalTime
                                                      ? 'red'
                                                      : 'green',
                                             }}
                                             align='right'
                                             noWrap
                                          >
                                             {(() => {
                                                return parseTime(
                                                   performanceData?.totalResolutionTime
                                                      ? performanceData?.totalResolutionTime /
                                                           performanceData?.solvedChatsCount
                                                      : 0,
                                                );
                                             })()}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                                 <Grid item>
                                    <Grid container justifyContent='space-between' alignItems='stretch'>
                                       <Grid item xs={8}>
                                          <Typography style={{ fontSize: 13 }} align='left' noWrap>
                                             {t['page_settings_typo_chat_settings_team_goals_target']}
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={4}>
                                          <Typography style={{ fontSize: 13 }} align='right' noWrap>
                                             {performanceData?.teamGoalTime === 0
                                                ? t?.dashboard_performance_no_target
                                                : parseTime(performanceData?.teamGoalTime)}
                                          </Typography>
                                       </Grid>
                                    </Grid>
                                 </Grid>
                              </Grid>
                           </Grid>
                        </Grid>
                     </Grid>
                  </Grid>
               </div>
            </Paper>
         </Grid>

         <Grid item xs={12} lg={4}>
            <Paper elevation={3} className={classes.paper}>
               <div style={{ height: 45, borderBottom: '1px solid rgb(238, 238, 238)' }}>
                  <Typography className={classes.title}>{t['team_performance']}</Typography>
               </div>
               <div>
                  <DataGrid
                     rows={users || []}
                     isLoading={!performanceData || !personData}
                     columns={columns}
                     rowLength={10}
                     className={classes.Table}
                     pagination={false}
                     rowHeight={32}
                     disableColumnMenu
                     disableColumnSelector
                     disableSelectionOnClick
                     disableColumn
                     headerHeight={32}
                     paginationMode='client'
                     disableDensitySelector
                     disableColumnReorder
                     hideFooter
                     disableExtendRowFullWidth
                     disableColumnFilter
                     components={{
                        Footer: null,
                        Pagination: null,
                     }}
                     localeText={{
                        toolbarColumns: '',
                        noRowsLabel: t?.no_data_to_show,
                     }}
                  />
               </div>
            </Paper>
         </Grid>
      </Grid>
   );
};

export default ChannelPerformance;

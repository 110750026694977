import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';

const useStyles = makeStyles(() => ({
   customInput: {
      width: '100%',
      height: 220,
      borderRadius: 5,
      border: '2px solid #D1D1D1',
      marginTop: '10px',
      marginBottom: '10px',
   },
   inputBar: {
      height: '85%',
      width: '100%',
      '& .MuiPopover-paper': {
         backgroundColor: 'red',
         color: 'yellow',
      },
   },
   textArea: {
      resize: 'none',
      fontSize: '14.7px',
      overflow: 'auto',
      width: '100%',
      wordWrap: 'break-word',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      height: 217,
      border: 'none',
      paddingLeft: 10,
      paddingTop: 10,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      '&:focus-visible': {
         border: 'none !important',
         outline: 'none !important',
      },
      '&:focus': {
         border: 'none !important',
         outline: 'none !important',
      },
   },
}));

const MenuProps = {
   anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
   },
   getContentAnchorEl: null,
   transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
   },
   style: {
      marginLeft: 3,
      '&:hover': {
         backgroundColor: 'red',
      },
   },
};

const TemplateContent = ({ contentMarkup }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <div className={classes.customInput}>
         <div className={classes.inputBar}>
            <div
               id='(text-area)'
               className={classes.textArea}
               ref={(element) => element?.focus?.()}
               dangerouslySetInnerHTML={{
                  __html: contentMarkup ? contentMarkup : t['campaign_empty_template'],
               }}
            ></div>
         </div>
      </div>
   );
};

export default WithUrlTranslations(TemplateContent);

import React, { useContext } from 'react';
import { Box, Grid, Paper, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import SocialMediaIcon from '@components/SocialMediaIcon';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
   root: {},
   container: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
   },
   paper: {
      width: 450,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #fff',
      borderRadius: 8,
   },
   lineThrough: {
      border: '1px solid #F1F3F7',
   },
   appBarSpacer: theme.mixins.toolbar,
   welcomeModalTitle: {
      fontSize: '1.2rem',
      fontWeight: 600,
      color: '#395273',
      textAlign: 'center',
      paddingTop: '1rem',
      paddingBottom: '1.75rem',
   },
   welcomeModalSubTitle: {
      fontSize: '1rem',
      fontWeight: 500,
      color: '#395273',
      textAlign: 'center',
      paddingBottom: '.75rem',
   },
   welcomeModalSubTitle2: {
      fontSize: '.8rem',
      fontWeight: 400,
      color: '#395273',
      textAlign: 'center',
      paddingBottom: '1.1rem',
   },
   iconText: {
      fontSize: '1.1rem',
      fontWeight: 500,
      color: '#395273',
   },
   modalIconContainer: {
      borderRadius: 10,
      padding: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   iconContainer: {
      display: 'flex',
      gap: 8,
      alignItems: 'center',
      padding: '6px 0',
   },
}));

const ShowTourModal = ({ onSkip }) => {
   const classes = useStyles();
   const history = useHistory();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);
   return (
      <Grid className={classes.paper}>
         <Typography className={classes.welcomeModalSubTitle}>
            {t?.dashboard_welcome_lets_start_to_your_first_channel}
         </Typography>
         <Typography className={classes.welcomeModalSubTitle2}>
            {t?.dashboard_welcome_which_channel_you_want_to_add_first}
         </Typography>

         <Grid container alignItems='center' spacing={2} style={{ padding: '1rem', paddingBottom: '3rem' }}>
            <React.Fragment>
               <Grid item xs={7}>
                  <Box className={classes.iconContainer}>
                     <Paper className={classes.modalIconContainer} elevation={4}>
                        <SocialMediaIcon socialMediaCode={1} selected width={42} height={44} />
                     </Paper>
                     <Typography className={classes.iconText}>WhatsApp</Typography>
                  </Box>
               </Grid>
               <Grid item xs={5}>
                  <Button
                     onClick={() => history.push('/settings/platforms/whatsapp-web')}
                     style={{ width: '100%' }}
                     variant='contained'
                     color='primary'
                  >
                     {t?.comp_button_add_account}
                  </Button>
               </Grid>
            </React.Fragment>
            <React.Fragment>
               <Grid item xs={7}>
                  <Box className={classes.iconContainer}>
                     <Paper className={classes.modalIconContainer} elevation={4}>
                        <SocialMediaIcon socialMediaCode={3} selected width={42} height={44} />
                     </Paper>
                     <Typography className={classes.iconText}>Facebook</Typography>
                  </Box>
               </Grid>
               <Grid item xs={5}>
                  <Button
                     onClick={() => history.push('/settings/platforms/facebook')}
                     style={{ width: '100%' }}
                     variant='contained'
                     color='primary'
                  >
                     {t?.comp_button_add_account}
                  </Button>
               </Grid>
            </React.Fragment>
            <React.Fragment>
               <Grid item xs={7}>
                  <Box className={classes.iconContainer}>
                     <Paper className={classes.modalIconContainer} elevation={4}>
                        <SocialMediaIcon socialMediaCode={4} selected width={42} height={44} />
                     </Paper>
                     <Typography className={classes.iconText}>Instagram</Typography>
                  </Box>
               </Grid>
               <Grid item xs={5}>
                  <Button
                     onClick={() => history.push('/settings/platforms/instagram')}
                     style={{ width: '100%' }}
                     variant='contained'
                     color='primary'
                  >
                     {t?.comp_button_add_account}
                  </Button>
               </Grid>
            </React.Fragment>
         </Grid>

         <hr className={classes.lineThrough} />

         <Box
            style={{
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'space-between',
               padding: '0 1rem 1rem 1rem',
            }}
         >
            <Button onClick={onSkip}>{t?.skip_setup}</Button>
            <Button onClick={() => history.push('/settings/company/hours')} variant='text' color='primary'>
               {t?.go_to_settings}
            </Button>
         </Box>
      </Grid>
   );
};

export default ShowTourModal;

import { gql } from '@apollo/client';

export const CREATE_CAMPAIGN = gql`
   mutation createCampaign($input: CreateCampaignInput!) {
      createCampaign(input: $input) {
         code
         data {
            _id
         }
         success
         message
      }
   }
`;

export const UPDATE_CAMPAIGN = gql`
   mutation updateCampaign(
      $_id: ID!
      $platform_id: ID!
      $content: String
      $template_id: ID
      $template_params: [WpTemplateParams]
      $media: [Media]
      $schedule_type: Int!
      $schedule_date: Date
      $user_recipients: [ID]
      $group_recipients: [ID]
      $dynamic_group_recipients: [ID]
      $survey: String
   ) {
      updateCampaign(
         input: {
            _id: $_id
            platform_id: $platform_id
            content: $content
            template_id: $template_id
            template_params: $template_params
            media: $media
            schedule_type: $schedule_type
            schedule_date: $schedule_date
            user_recipients: $user_recipients
            group_recipients: $group_recipients
            dynamic_group_recipients: $dynamic_group_recipients
            survey: $survey
         }
      ) {
         code
         data {
            _id
            name
            platform_type
            platform_id
            whats_app_web_id
            whats_app_business_id
            schedule_type
            schedule_date
            user_recipients
            group_recipients
            dynamic_group_recipients
            createdAt
            total_recipient_count
            content
            media
            status
            success_count
            failure_count
            platform_error_count
         }
         success
         message
      }
   }
`;

export const REMOVE_CAMPAIGN = gql`
   mutation removeCampaign($input: RemoveCampaignInput!) {
      removeCampaign(input: $input) {
         code
         data {
            _id
         }
         success
         message
      }
   }
`;

export const FLAG_CAMPAIGN_FOR_SCHEDULE = gql`
   mutation flagCampaignForSchedule($input: SendCampaignInput!) {
      flagCampaignForSchedule(input: $input) {
         code
         success
         message
      }
   }
`;

export const SEND_CAMPAIGN = gql`
   mutation sendCampaign($input: SendCampaignInput!) {
      sendCampaign(input: $input) {
         code
         success
         message
      }
   }
`;

export const TEST_CAMPAIGN = gql`
   mutation testCampaign($input: TestCampaignInput!) {
      testCampaign(input: $input) {
         code
         success
         message
      }
   }
`;

export const MOVE_USERS_TO_GROUP = gql`
   mutation moveUsersToGroup($input: MoveUsersToGroupInput!) {
      moveUsersToGroup(input: $input) {
         code
         success
         message
      }
   }
`;

import React, { FC } from 'react';
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
   container: {
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   cardContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#FAFAFA',
      margin: 20,
      padding: 45,
      borderRadius: 15,
      textAlign: 'center',
   },
   title: {
      marginBottom: 8,
   },
   description: {
      marginBottom: 12,
   }
}));

interface CannotAccessModalProps {
   title?: string;
   description?: string;
   button?: React.ReactNode;
}

const CannotAccessModal: FC<CannotAccessModalProps> = ({ title, description, button}) => {
   const classes = useStyles();

   return (
      <div className={classes.container}>
         <div className={classes.cardContainer}>
            <Typography variant="h4" className={classes.title}>{title}</Typography>
            <Typography variant="subtitle1" className={classes.description}>{description}</Typography>
            {button}
         </div>
      </div>
   )
};

export default CannotAccessModal;

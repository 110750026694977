import { trimLongText } from '@helper/textUtils';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React from 'react';

const useStyles = makeStyles((theme) => ({
   hashtag: {
      marginLeft: (props) => (props.small ? -3 : null),
      marginTop: (props) => (props.small ? -3 : null),
      fontSize: (props) => (props.small ? '0.8rem' : '1.1rem'),
   },
   tagName: {
      paddingTop: 0,
      // maxWidth: 128,
      fontSize: (props) => (props.small ? '0.7rem' : '1.1rem'),
   },
   hashtagButton: {
      width: 24,
      backgroundColor: (props) => props.color,
      borderTopLeftRadius: 5,
      borderBottomLeftRadius: 5,
      paddingLeft: 5,
      paddingRight: 5,
      color: 'white',
      fontSize: (props) => (props.small ? '0.85rem' : '1.2rem'),
      fontWeight: 'bold',
      paddingTop: 0,
   },
   tagNameButton: {
      fontSize: (props) => (props.small ? '0.8rem' : '1rem'),
      // maxWidth: 100,
      paddingRight: 5,
      // color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginTop: (props) => (props.small ? -3 : 0),
      marginLeft: -4,
   },
   buttonStyle: {
      backgroundColor: (props) => (props.hovered ? '#eee' : 'white'),
      borderRadius: 5,
      boxShadow: (props) => (props.small ? '1px 1px 1px 0px lightgrey' : '1px 1px 3px 0px grey'),
      // width:500
   },
   tagNameContainer: {
      display: 'inline-block',
      // border: 'solid 1px gray',
      // borderTopLeftRadius: 0,
      // borderBottomLeftRadius: 0,
      borderRadius: 5,
      borderLeft: null,
      width: '100%',
      textAlign: 'left',
      paddingLeft: 10,
   },
}));

const Tag = (props) => {
   const { name, color, selected, preSelected, small } = props;
   const classes = useStyles(props);

   return (
      <Grid container alignItems='baseline' justifyContent='flex-start'>
         <Grid
            className={classes.buttonStyle}
            container
            justifyContent='flex-start'
            wrap='nowrap'
            item
            xs={12}
         >
            {/* <Typography className={classes.hashtagButton}> # </Typography> */}
            <div className={classes.tagNameContainer}>
               <Grid
                  container
                  justifyContent='space-between'
                  alignItems='center'
                  style={{ marginTop: 3 }}
               >
                  <Grid item xs={small ? 12 : 10}>
                     <Typography className={classes.tagNameButton} noWrap style={{ color: color }}>
                        {trimLongText(name, 17)}
                     </Typography>
                  </Grid>
                  {selected && (
                     <Grid item xs={2} style={{ height: 20 }}>
                        <CheckCircleIcon
                           style={{ color: color, fontSize: '1.2rem', marginTop: 0, padding: 0 }}
                        />
                     </Grid>
                  )}
                  {!selected && preSelected && (
                     <Grid item xs={2} style={{ height: 20 }}>
                        <CheckIcon
                           style={{ color: color, fontSize: '1.2rem', marginTop: 0, padding: 0 }}
                        />
                     </Grid>
                  )}
               </Grid>
            </div>
         </Grid>
      </Grid>
   );
};

export default Tag;

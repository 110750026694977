import React from 'react';
const LiveChatIcon = ({ selected, color, className }) => {
   const color1 = color || (selected ? '#157cfc' : '#c7cdd6');

   return (
      <svg
         id='Group_2306'
         data-name='Group 2306'
         xmlns='http://www.w3.org/2000/svg'
         width='55px'
         height='55px'
         viewBox='0 0 35.267 35.177'
         className={className}
         style={{ width: '100%' }}
      >
         <path
            id='Path_85'
            data-name='Path 85'
            d='M17.506,35.177a38.333,38.333,0,0,1-6.442-1.662,9.024,9.024,0,0,0-5.723-.534c-2.871.569-3.928-.473-3.316-3.331a9.188,9.188,0,0,0-.587-5.634C-1.23,17.346-.214,11.052,4.723,5.764,9.684.45,15.96-1.274,22.984.943c6.93,2.188,11.066,7.039,12.1,14.169C36.617,25.708,28.349,34.942,17.506,35.177ZM7.192,27.868c1.553.475,3.289.88,4.922,1.536a14.187,14.187,0,0,0,8.427.886,13.123,13.123,0,0,0,9.8-16.676A13.288,13.288,0,0,0,12.87,5.288,13.106,13.106,0,0,0,5.687,23.114,35.614,35.614,0,0,1,7.192,27.868Z'
            transform='translate(0 0)'
            fill={color1}
         />
         <path
            id='Path_86'
            data-name='Path 86'
            d='M1.952.939c-.052.559-.293.952-.917.989A.934.934,0,0,1,0,.971.931.931,0,0,1,.957,0,.939.939,0,0,1,1.952.939Z'
            transform='translate(16.992 16.572)'
            fill={color1}
         />
         <path
            id='Path_87'
            data-name='Path 87'
            d='M1,0a.947.947,0,0,1,.929,1.046.878.878,0,0,1-.987.889A.9.9,0,0,1,0,.987.963.963,0,0,1,1,0Z'
            transform='translate(10.242 16.557)'
            fill={color1}
         />
         <path
            id='Path_88'
            data-name='Path 88'
            d='M1.935.968a.942.942,0,0,1-1.01.968A.87.87,0,0,1,0,.985.894.894,0,0,1,.907,0,.976.976,0,0,1,1.935.968Z'
            transform='translate(23.785 16.562)'
            fill={color1}
         />
      </svg>
   );
};

export default LiveChatIcon;

import React, { FC, useContext } from 'react';
import { ORDER_FLAGS } from '../types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Link, Typography } from '@material-ui/core';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import { useForm } from 'react-hook-form';
import { LanguageContext } from '../../../../../../../../helper/locale/langContext';
import NoteCard from '../../../../../ChatItem/NoteCard';
import ProductsWithStatus from '../../../../../ChatItem/ProductsWithStatus';
import ButtonOK from '../../../../../../../../components/ButtonOK/buttonOk';
import PaymentCard from '../../../../../ChatItem/PaymentCard';
import OrderCard from '../../../../../ChatItem/OrderCard';
import { DISCOUNT_TYPES } from '@modules/Chat/RightPanel/Tabs/E-Commerce/Tabs/Customer/types';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
   },
   link: {
      display: 'flex',
   },
   idTypo: {
      color: '#385273',
      fontSize: '1.2em',
   },
   openInNewRoundedIconContainer: {
      padding: 5,
      marginLeft: 3,
   },
   openInNewRoundedIcon: {
      fontSize: '0.7em',
      color: '#385273',
   },
   buttonsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 8,
   },
   selectShippingMethodContainer: {
      marginTop: -15,
      marginBottom: -18,
   },
}));

interface OrderDetailProps {
   order: any;
}

const OrderDetail: FC<OrderDetailProps> = ({ order }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;

   const shippingMethodOptions = [
      { value: 0, label: 'UPS (Free shipping)' },
      { value: 1, label: 'USPS (Free shipping)' },
      { value: 2, label: 'DHL (+$9.90)' },
      { value: 3, label: 'Aras K (+$9.90)' },
   ];

   const { control } = useForm({
      mode: 'all',
      defaultValues: {
         selectShippingMethod: { value: 0, label: 'UPS (Free shipping)' },
      },
   });

   const fulfilledProducts = order.products.filter(
      (product: any) => product.status === ORDER_FLAGS.SHIPPED || product.status === ORDER_FLAGS.FULFILLED,
   );
   const unfulfilledProducts = order.products.filter(
      (product: any) =>
         product.status !== ORDER_FLAGS.SHIPPED &&
         product.status !== ORDER_FLAGS.CANCELLED &&
         product.status !== ORDER_FLAGS.REFUNDED &&
         product.status !== ORDER_FLAGS.FULFILLED,
   );
   const canceledProducts = order.products.filter(
      (product: any) => product.status === ORDER_FLAGS.CANCELLED || product.status === ORDER_FLAGS.REFUNDED,
   );

   return (
      <div className={classes.container}>
         <OrderCard
            order={order}
            cursorPointer={false}
            onIdClick={() => window.open(order.order_status_url, '_blank')}
            containerStyle={{ borderTop: 'none' }}
         />
         {order.order_note && (
            <NoteCard type='orderNote'>
               <Typography>{order.order_note}</Typography>
            </NoteCard>
         )}
         {order.fulfillments.map((fulfillment: any, index: number) => (
            <>
               {fulfillment.tracking_number && (
                  <NoteCard
                     type='trackingInfo'
                     headerRight={
                        fulfillment.tracking_url ? (
                           <Link
                              onClick={() => window.open(fulfillment.tracking_url, '_blank')}
                              className={classes.link}
                           >
                              <Typography variant='body1' noWrap className={classes.idTypo}>
                                 {fulfillment.tracking_company}
                              </Typography>
                              <IconButton className={classes.openInNewRoundedIconContainer}>
                                 <OpenInNewRoundedIcon className={classes.openInNewRoundedIcon} />
                              </IconButton>
                           </Link>
                        ) : (
                           <Typography variant='body1' noWrap className={classes.idTypo}>
                              {fulfillment.tracking_company}
                           </Typography>
                        )
                     }
                  >
                     <Typography>{fulfillment.tracking_number}</Typography>
                  </NoteCard>
               )}
            </>
         ))}
         {/* {order.fulfillments.length <= 0 && (
            <NoteCard type='shippingMethod'>
               <div className={classes.selectShippingMethodContainer}>
                  <Input
                     isSelect={true}
                     selectOptions={shippingMethodOptions}
                     name='selectShippingMethod'
                     control={control}
                     style={{ margin: '0 !important' }}
                  />
               </div>
            </NoteCard>
         )} */}
         {fulfilledProducts.length > 0 && (
            <ProductsWithStatus currency={order.currency} products={fulfilledProducts} status={ORDER_FLAGS.FULFILLED} />
         )}
         {unfulfilledProducts.length > 0 && (
            <ProductsWithStatus
               currency={order.currency}
               products={unfulfilledProducts}
               status={ORDER_FLAGS.UNFULFILLED}
            />
         )}
         {canceledProducts.length > 0 && (
            <ProductsWithStatus currency={order.currency} products={canceledProducts} status={'REMOVED'} />
         )}

         <PaymentCard
            subTotal={order.subtotal_price}
            discount={{
               discountValue: order.discount,
               discountType: DISCOUNT_TYPES.FIXED_VALUE,
               discountReason: '',
            }}
            shipping={order.shipping_price}
            tax={order.tax}
            total={order.totalPrice}
            currency={order.currency}
            // status={ORDER_FLAGS[order?.financial_status]}
            status={ORDER_FLAGS.ALL_ORDERS}
            billIncludesTaxes={true}
         />
         <div className={classes.buttonsContainer}>
            <ButtonOK
               onClick={() => window.open(order.order_status_url, '_blank')}
               label={t['page_e_commerce_store_view_edit_order']}
               style={{
                  backgroundColor: '#fff',
                  borderRadius: 5,
                  border: '1px solid #D9E0E6',
                  color: '#385273',
                  flex: 1,
                  width: 'fit-content',
                  // marginRight: 4,
               }}
               fontSize='14px'
            />
            {/*<ButtonOK*/}
            {/*   onClick={() => alert('share order details')}*/}
            {/*   label={t['page_e_commerce_store_share_order_details']}*/}
            {/*   style={{*/}
            {/*      borderRadius: 5,*/}
            {/*      border: 'none',*/}
            {/*      flex: 1,*/}
            {/*      width: 'fit-content',*/}
            {/*      marginLeft: 4,*/}
            {/*   }}*/}
            {/*   fontSize='14px'*/}
            {/*/>*/}
         </div>
      </div>
   );
};

export default OrderDetail;

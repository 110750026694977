export const statusTranslate = (t, status) => {
   if (status === 'Waiting for approval') {
      return t.settings_whatsapp_b_waiting_approval;
   } else if (status === 'Approved') {
      return t.settings_whatsapp_b_approved;
   } else if (status === 'Rejected') {
      return t.settings_whatsapp_b_rejected;
   }
};

export const getBusinessVerticals = (t) => {
   const businessVerticals = [
      {
         label: t.settings_whatsapp_b_business_verticals_automotive,
         value: 'Automotive',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_beauty,
         value: 'Beauty, Spa and Salon',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_clothing,
         value: 'Clothing and Apparel',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_education,
         value: 'Education',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_entertaiment,
         value: 'Entertainment',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_event_planning,
         value: 'Event Planning and Service',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_finance,
         value: 'Finance and Banking',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_food,
         value: 'Food and Grocery',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_public_service,
         value: 'Public Service',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_hotel,
         value: 'Hotel and Lodging',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_medical,
         value: 'Medical and Health',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_non_profit,
         value: 'Non-profit',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_professional_services,
         value: 'Professional Services',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_shopping,
         value: 'Shopping and Retail',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_travel,
         value: 'Travel and Transportation',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_restaurant,
         value: 'Restaurant',
      },
      {
         label: t.settings_whatsapp_b_business_verticals_other,
         value: 'Other',
      },
   ];

   return businessVerticals;
};

import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import { Button, ButtonBase, CircularProgress, Divider, Grid, MenuItem, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SITE_URL } from '@helper/config';
import { LanguageContext } from '@helper/locale/langContext';
import useBoolean from '../../../hooks/useBoolean';
import { getPerson, getToken } from '@store/reducers/person';
import { getActiveQuestion } from '@store/reducers/questions';
import { MyTheme } from '@styles/config';
import ButtonBasedPopup from '@components/ButtonBasedPopup';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import MediumAvatar from '@components/MediumAvatar';
import Tag from '@components/Tag/index';
import { GET_CUSTOMER_TEAMS_QUERY } from '../../../queries/Person/Service/GraphQL/query';
import useQnaQuestions from '../../Questions/shared-hooks/useQnaQuestions';
import { QnAPersonStatusType, QnAPlatformType } from '../../Questions/util/types';
import { GET_CHAT_TAGS_QUERY } from '../../../queries/Settings/Service/GraphQL/ChatTags/query';
import {
   LeaveChatIcon,
   CloseChatIcon,
   EditChatTagIcon,
   ForwardChatIcon,
   ArchiveChatIcon,
   SpamChatIcon,
   JoinChatIcon,
} from '@assets/icons/NavbarIcons/chatHeaderIcons';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import Modal from '@components/Modal/modal';
import { UPDATED_CUSTOMER_PERSON_SUBSCRIPTION } from '@queries/Person/Service/GraphQL/subscription';
import SearchBar from '@components/SearchBar';
import InfiniteScroll from 'react-infinite-scroll-component';

const Page = {
   pageSize: 10,
   page: 1,
};

interface Props {}

interface IchatTagParam {
   customer: any,
   pageSize: number,
   page: number,
   search: any,
}

const useStyles = makeStyles((theme: MyTheme) => ({
   root: {
      // height:40,
      // width:400,
      padding: 5,
      borderRadius: 10,
   },
   buttonsContainer: {
      padding: 5,
      borderRadius: 10,
      maxWidth: 500,
      overflowX: 'auto',
   },
   menuItemRoot: {
      '&:hover': {
         backgroundColor: 'transparent',
      },
   },
   searchTextField: {
      // background: '#eee',
      borderRadius: 5,
   },
   input: {
      padding: 5,
   },
   button: {
      textTransform: 'none',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      paddingLeft: 8,
      paddingRight: 8,
      marginRight: 4,
      fontSize: '1.1rem',
      borderRadius: 5,
      backgroundColor: 'white',
      height: 36,
   },
   actionButton: {
      padding: 0,
      height: 36,
      width: 36,
      minWidth: 36,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: '#F0F0F0',
      border: '1px solid #D8D8D8',
      borderRadius: 8,
      fontSize: 20,
      marginLeft: 4,
      marginRight: 4,
      '&:focus': {
         backgroundColor: '#D8D8D8',
      },
   },
   disabledActionButton: {
      opacity: '0.5',
      '&:focus': {
         backgroundColor: '#F0F0F0',
      },
   },
   activeButton: {
      cursor: 'pointer',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   inActiveButton: {
      cursor: 'default',
      color: 'grey',
   },
   joined: {
      backgroundColor: 'white',
      textTransform: 'none',
   },
   notJoined: {
      backgroundColor: '#47D054',
      textTransform: 'none',
      color: 'white',
      '&:hover': {
         filter: 'brightness(1.1)',
         backgroundColor: '#47D054',
      },
   },
   tagContainer: {
      padding: 10,
      backgroundColor: 'white',
      position: 'sticky',
      top: -10,
      zIndex: 4,
   },
   tagTitle: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   noTeamMemberTitle: {
      fontSize: '0.9rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   tags: {
      border: 'solid 1px #cbd3dd',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   more: {
      paddingTop: 3,
      border: 'solid 1px #cbd3dd',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      width: 50,
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 35,
      marginTop: 0,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   tagBottomButtons: {
      position: 'sticky',
      zIndex: 5,
      bottom: -10,
      backgroundColor: '#fff',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   tagSearchInputContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: 8,
      marginLeft: 8,
   },
}));

const QuestionNavButtons = (props: Props) => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   }: any = useContext(LanguageContext);
   const activeQuestion = useSelector(getActiveQuestion);
   const me = useSelector(getPerson);
   const token = useSelector(getToken);
   const {
      handleJoinQuestion: handleJoinMutation,
      handleForwardQuestion: handleForwardMutation,
      handleLeaveQuestion: handleLeaveMutation,
      handleAddTagQuestion: handleTagAddMutation,
      handleSignAsArchiveQuestionMutation: handleArchiveMutation,
      loadingState: { mutationsLoading },
   } = useQnaQuestions();

   const [showDisconnectModal, setShowDisconnectModal] = useState<any>(null);
   const [selectedTag, setSelectedTag] = useState<any>(null);
   const { enqueueSnackbar } = useSnackbar();
   const person = useSelector(getPerson);
   // Menu State
   const { value: openMenu, off: closeMenu, on: handleOpen } = useBoolean(false);
   const { value: openTagMenu, off: closeTagMenu, on: handleOpenTagMenu } = useBoolean(false);
   const { value: openSpamMenu, off: closeSpamMenu, on: handleOpenSpamMenu } = useBoolean(false);
   const { value: openUsersMenu, off: closeUsersMenu, on: handleOpenUsersMenu } = useBoolean(false);
   const [isConnected, setIsConnected] = useState(true);
   const [hasAuthority, setHasAuthority] = useState<boolean | null>(null);

   const customerId = me.customer._id;
   const [searchText, setSearchText] = useState<string>();
   const [isSearchText, setTextChange] = useState(false);
   const [chatTagCount, setChatTagCount] = useState(0);
   const [answerList, setAnswerList] = useState<any>([]);
   
   
   useEffect(() => {
      if (!activeQuestion?.joinedPerson) {
         setHasAuthority(null);
      } else if (activeQuestion?.joinedPerson?._id === person?._id) {
         setHasAuthority(true);
      } else {
         setHasAuthority(false);
      }
   }, [activeQuestion?.joinedPerson, person?._id]);

   useEffect(() => {
      if (activeQuestion?.platformType === QnAPlatformType.HEPSIBURADA && activeQuestion?.hepsiburadaAccount) {
         setIsConnected(activeQuestion?.hepsiburadaAccount?.isConnected);
      } else if (activeQuestion?.platformType === QnAPlatformType.TRENDYOL && activeQuestion?.trendyolAccount) {
         setIsConnected(activeQuestion?.trendyolAccount?.isConnected);
      } else if (
         activeQuestion?.facebookPage &&
         activeQuestion?.facebookPage !== null &&
         activeQuestion?.platformType === QnAPlatformType.FACEBOOK
      ) {
         setIsConnected(activeQuestion?.facebookPage?.isCommentEnable);
      } else if (
         activeQuestion?.instagramPage &&
         activeQuestion?.instagramPage !== null &&
         activeQuestion?.platformType === QnAPlatformType.INSTAGRAM
      ) {
         setIsConnected(activeQuestion?.instagramPage?.isCommentEnable);
      }
   }, [
      activeQuestion?.facebookPage,
      activeQuestion?.hepsiburadaAccount,
      activeQuestion?.instagramPage,
      activeQuestion?.platformType,
      activeQuestion?.trendyolAccount,
   ]);

   // Get ChatTags
   const [getChatTagQuery, {  loading: chatTagsDataLoading }] = useLazyQuery(GET_CHAT_TAGS_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         if (isSearchText) {
            setAnswerList([...answerList, ...data?.getChatTagList?.data?.docs]);
            setChatTagCount(data?.getChatTagList?.data?.docsCount );
            setTextChange(false);
            return;
         }
         setAnswerList([...answerList, ...data?.getChatTagList?.data?.docs]);
         setChatTagCount(data?.getChatTagList?.data?.docsCount );
      },
   });

   const [chatTagParam, setChatTagParam] = useState<IchatTagParam>({
      customer: customerId,
      pageSize: Page.pageSize,
      page: Page.page,
      search: null, 
   });

   useEffect(() => {
      setAnswerList([]);
      if (searchText !== undefined) {
         setTextChange(true);
         setTimeout(() => {
            setChatTagParam((prev) => ({ ...prev, search: searchText, page: Page.page }));
         }, 500);
      } else if (searchText === '') {
         setTimeout(() => {
            setChatTagParam((prev) => ({ ...prev, search: searchText, page: Page.page }));
         }, 500);
      }
   }, [searchText]);

   useEffect(() => {
      getChatTagQuery({
         variables: {
            ...chatTagParam,
         },
      });
   }, [chatTagParam]);

   const handleNextAnswerPage = () => {
      if (answerList.length < chatTagCount) {
         setChatTagParam({
            ...chatTagParam,
            page: chatTagParam.page + 1,
         });
      }
   };

   const isTimedOut = !activeQuestion?.isJoinable;

   const isJoinableOrEnabled = activeQuestion?.joinedPerson === null && activeQuestion?.canJoin;

   const isSolvedSpamAndTimeOut =
      (activeQuestion?.status === QnAPersonStatusType.SOLVED || activeQuestion?.status === QnAPersonStatusType.SPAM) &&
      !activeQuestion?.joinedPerson &&
      !activeQuestion?.canJoin;

   const [getCustomerTeams, { data: customerTeamsData, loading: customerTeamsLoading }] = useLazyQuery(
      GET_CUSTOMER_TEAMS_QUERY,
      {
         variables: { person: me._id, customer: me.customer._id },
         fetchPolicy: 'cache-first',
         onError: (error) => {
            enqueueSnackbar(error.message, {
               variant: 'error',
               autoHideDuration: 2500,
            });
         },
      },
   );

   useSubscription(UPDATED_CUSTOMER_PERSON_SUBSCRIPTION, {
      variables: { token, customer: me ? me.customer._id : '' },
      onSubscriptionData: () => {
         getCustomerTeams();
      },
   });

   const handleChangeChatTagSubmit = () => {
      closeTagMenu();
      handleTagAddMutation({
         qnaId: activeQuestion._id,
         tagId: selectedTag._id,
      });
   };

   const handleRemoveChatTag = () => {
      closeTagMenu();
      handleTagAddMutation({
         qnaId: activeQuestion._id,
         tagId: null,
      });
      setSelectedTag(null);
   };

   const handleForwardQuestion = (data: { to: string }) => {
      handleForwardMutation(activeQuestion._id, data.to);
   };

   const handleLeaveQuestion = (status: QnAPersonStatusType) => {
      closeMenu();
      handleLeaveMutation(activeQuestion._id, status);
   };

   const Loader = () => {
      return (
         <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   };


   return (
      <Grid className={classes.root} container sm={12} justifyContent='center' alignItems='center'>
         {mutationsLoading ? (
            <div className='loading'></div>
         ) : (
            <Grid className={classes.buttonsContainer} container direction='row' wrap='nowrap' alignItems='center'>
               {isJoinableOrEnabled || isSolvedSpamAndTimeOut || isTimedOut ? (
                  <Button
                     className={clsx(
                        classes.button,
                        !isJoinableOrEnabled && classes.joined,
                        (isJoinableOrEnabled || isTimedOut) && classes.notJoined,
                     )}
                     disableTouchRipple
                     disabled={!isConnected || isSolvedSpamAndTimeOut || isTimedOut}
                     onClick={() => {
                        handleJoinMutation(activeQuestion?._id);
                     }}
                  >
                     <span style={{ marginRight: 6 }}>{t['page_chat_typo_join']}</span>
                     <JoinChatIcon
                        fill={!isConnected || isSolvedSpamAndTimeOut || isTimedOut ? 'rgba(0, 0, 0, 0.26)' : '#FFF'}
                     />
                  </Button>
               ) : (
                  <>
                     <Tooltip title={t['page_chat_typo_leave']}>
                        <Button
                           onClick={() => {
                              handleLeaveQuestion(QnAPersonStatusType.WAITING);
                           }}
                           className={clsx(
                              classes.actionButton,
                              (!isConnected || isTimedOut || hasAuthority === false) && classes.disabledActionButton,
                           )}
                           disableTouchRipple
                           disabled={!isConnected || isTimedOut || hasAuthority === false}
                        >
                           <LeaveChatIcon />
                        </Button>
                     </Tooltip>
                     <Tooltip title={t['page_chat_typo_solved']}>
                        <Button
                           onClick={() => {
                              handleLeaveQuestion(QnAPersonStatusType.SOLVED);
                           }}
                           className={clsx(
                              classes.actionButton,
                              (!isConnected || isTimedOut || hasAuthority === false) && classes.disabledActionButton,
                           )}
                           disableTouchRipple
                           disabled={!isConnected || isTimedOut || hasAuthority === false}
                        >
                           <CloseChatIcon />
                        </Button>
                     </Tooltip>
                  </>
               )}

               {/* TRANSFER */}

               <ButtonBasedPopup
                  isDisabled={!isConnected || isSolvedSpamAndTimeOut || isTimedOut || hasAuthority === false}
                  style={{ marginLeft: 5 }}
                  button={
                     <Tooltip title={t['page_chat_typo_forward']}>
                        <Button
                           className={clsx(
                              classes.actionButton,
                              (!isConnected || isSolvedSpamAndTimeOut || isTimedOut || hasAuthority === false) &&
                                 classes.disabledActionButton,
                           )}
                           disableTouchRipple
                           disabled={!isConnected || isSolvedSpamAndTimeOut || isTimedOut || hasAuthority === false}
                           onClick={() => {
                              getCustomerTeams();
                              handleOpenUsersMenu();
                           }}
                        >
                           <ForwardChatIcon />
                        </Button>
                     </Tooltip>
                  }
                  open={openUsersMenu}
                  onOpen={handleOpenUsersMenu}
                  onClose={closeUsersMenu}
               >
                  <Grid
                     style={{ paddingBottom: 10, paddingTop: 10, width: '225px', maxHeight: 400 }}
                     container
                     justifyContent='center'
                     alignItems='center'
                  >
                     {customerTeamsLoading && <CircularProgress size={24} color='primary' />}
                     {customerTeamsData?.customerTeams?.data?.length === 0 && (
                        <Grid style={{ height: 150 }} container justifyContent='center' alignItems='center'>
                           <Grid item>
                              <Typography align='center' className={classes.tagTitle}>
                                 {t?.there_is_no_team_member}
                              </Typography>
                           </Grid>
                           <Grid item>
                              <Link to='/settings/users'>
                                 <ButtonOK
                                    label={t?.page_settings_button_add_new}
                                    fontSize='1rem'
                                    width={120}
                                    height={30}
                                    endIcon={<AddIcon fontSize='small' />}
                                 />
                              </Link>
                           </Grid>
                        </Grid>
                     )}
                     {customerTeamsData?.customerTeams?.data.map((person: any, i: number) => (
                        <Grid
                           key={person._id}
                           container
                           justifyContent='flex-start'
                           alignItems='center'
                           style={{ paddingLeft: 10, paddingRight: 10 }}
                        >
                           <MenuItem
                              style={{
                                 paddingLeft: 10,
                                 paddingRight: 0,
                                 borderRadius: 10,
                                 width: '100%',
                                 height: 55,
                              }}
                              onClick={() => {
                                 handleForwardQuestion({
                                    to: person._id,
                                 });
                              }}
                           >
                              <Grid container justifyContent='flex-start' alignItems='center'>
                                 {/* <div className={clsx(classes.item, mouseOverIndex === i && classes.selectedItem)} /> */}
                                 <ButtonBase disableRipple disableTouchRipple>
                                    <MediumAvatar
                                       avatarBorder
                                       title={person.name}
                                       image={person.photo ? person.photo : SITE_URL + '/none.jpg'}
                                       onlineStatus={person.status}
                                       username={person.email}
                                    />
                                 </ButtonBase>
                              </Grid>
                           </MenuItem>
                        </Grid>
                     ))}
                  </Grid>
               </ButtonBasedPopup>

               {/* TAGS */}
               <ButtonBasedPopup
                  style={{ marginLeft: 5 }}
                  isDisabled={
                     isJoinableOrEnabled ||
                     !isConnected ||
                     isSolvedSpamAndTimeOut ||
                     isTimedOut ||
                     hasAuthority === false
                  }
                  button={
                     <Tooltip title={t['page_chat_typo_filter_tag']}>
                        <Button
                           className={clsx(
                              classes.actionButton,
                              (isJoinableOrEnabled ||
                                 !isConnected ||
                                 isSolvedSpamAndTimeOut ||
                                 isTimedOut ||
                                 hasAuthority === false) &&
                                 classes.disabledActionButton,
                           )}
                           disableTouchRipple
                           disabled={
                              isJoinableOrEnabled ||
                              !isConnected ||
                              isSolvedSpamAndTimeOut ||
                              isTimedOut ||
                              hasAuthority === false
                           }
                        >
                           <EditChatTagIcon />
                        </Button>
                     </Tooltip>
                  }
                  open={openTagMenu}
                  onOpen={handleOpenTagMenu}
                  onClose={closeTagMenu}
               >
                  <Grid style={{ padding: 10, width: 280, maxHeight: '400px', overflowY: 'hidden' }}>
                  <Grid item container direction='row' justifyContent='space-between' className={classes.tagContainer}>
                     <Grid container alignItems='center' xs={6} item>
                        <Typography className={classes.tagTitle}>{t['page_chat_typo_tags']}</Typography>
                     </Grid>
                     <Grid item xs={6}>
                        <Link to={'/settings/company/tags'}>
                           <ButtonOK
                              label={t?.page_settings_button_add_new}
                              fontSize='1rem'
                              width={120}
                              height={30}
                              endIcon={<AddIcon fontSize='small' />}
                           />
                        </Link>
                     </Grid>
                  </Grid>
                  <Divider style={{ width: '100%', marginTop: 2, marginBottom: 5 }} />

                  <div className={classes.tagSearchInputContainer}>
                     <div>
                        <SearchBar
                           value={searchText}
                           onChange={setSearchText}
                           placeholder={t['search']}
                           style={{ width: 238, height: 35, right: 3 }}
                        />
                     </div>
                  </div>

                     <InfiniteScroll
                        scrollableTarget='scrollableDiv'
                        dataLength={answerList?.length || 0}
                        hasMore={answerList.length >= chatTagCount ? false : true}
                        loader={<Loader />}
                        next={handleNextAnswerPage}
                        height={220}
                     >
                        {answerList?.map((tag: any) => {
                           return (
                              <Grid
                                 key={tag.id}
                                 container
                                 justifyContent='flex-start'
                                 alignItems='center'
                                 wrap='nowrap'
                                 style={{
                                    paddingLeft: 5,
                                    paddingRight: 5,
                                    marginTop: 5,
                                    cursor: 'pointer',
                                 }}
                              >
                                 <MenuItem
                                    disableRipple
                                    className={classes.menuItemRoot}
                                    onClick={() => setSelectedTag(tag)}
                                    style={{
                                       paddingLeft: 0,
                                       paddingRight: 0,
                                       borderRadius: 10,
                                       width: '100%',
                                       cursor: 'pointer',
                                    }}
                                 >
                                    <Grid
                                       container
                                       justifyContent='flex-start'
                                       alignItems='center'
                                       wrap='nowrap'
                                       style={{ paddingLeft: 3, paddingRight: 3 }}
                                    >
                                       <Tag
                                          name={tag.name}
                                          color={tag.color}
                                          buttonStyle
                                          preSelected={selectedTag?._id === tag._id}
                                          selected={tag._id === activeQuestion?._id}
                                          style={{
                                             cursor: 'pointer',
                                             hovered: true,
                                          }}
                                       />
                                    </Grid>
                                 </MenuItem>
                              </Grid>
                           );
                        })}
                        {chatTagCount === 0 && (
                           <Grid xs={12} container style={{ height: 200 }} justifyContent='center' alignItems='center'>
                              {!chatTagsDataLoading && (
                                 <Typography align='center' className={classes.tagTitle}>
                                    {t?.there_is_no_tag}
                                 </Typography>
                              )}
                           </Grid>
                        )}
                     </InfiniteScroll>
                     {/* If person doesn't have tag, don't show the buttons */}
                     {answerList.length !== 0 ? (
                        <Fragment>
                           <Divider style={{ width: '100%', marginTop: 15, marginBottom: 5 }} />
                           <Grid
                              item
                              xs={12}
                              container
                              justifyContent='space-between'
                              style={{ padding: 15 }}
                              className={classes.tagBottomButtons}
                           >
                              <Grid item xs={6} style={{ paddingRight: 5 }}>
                                 <ButtonCancel
                                    onClick={handleRemoveChatTag}
                                    fontSize='1rem'
                                    height={35}
                                    style={{ width: '100%' }}
                                    label={t?.remove}
                                    disabled={!activeQuestion?.tag || isTimedOut}
                                 />
                              </Grid>
                              <Grid item xs={6} container justifyContent='flex-end' style={{ paddingLeft: 5 }}>
                                 <ButtonOK
                                    onClick={handleChangeChatTagSubmit}
                                    fontSize='1rem'
                                    disabled={!selectedTag?._id || isTimedOut}
                                    height={35}
                                    style={{ width: '100%' }}
                                    label={t?.comp_button_save}
                                 />
                              </Grid>
                           </Grid>
                        </Fragment>
                     ) : (
                        <Grid xs={12} container style={{ height: 200 }} justifyContent='center' alignItems='center'>
                           <Typography align='center' className={classes.tagTitle}></Typography>
                        </Grid>
                     )}
                  </Grid>
               </ButtonBasedPopup>

               {/* ARCHIVE */}
               <Tooltip title={t['page_chat_typo_archive']}>
                  <Button
                     onClick={() =>
                        handleArchiveMutation({
                           qnaId: activeQuestion._id,
                        })
                     }
                     className={clsx(
                        classes.actionButton,
                        (isJoinableOrEnabled ||
                           !isConnected ||
                           isSolvedSpamAndTimeOut ||
                           isTimedOut ||
                           hasAuthority === false) &&
                           classes.disabledActionButton,
                     )}
                     disableTouchRipple
                     disabled={
                        isJoinableOrEnabled ||
                        !isConnected ||
                        isSolvedSpamAndTimeOut ||
                        isTimedOut ||
                        hasAuthority === false
                     }
                  >
                     <ArchiveChatIcon />
                  </Button>
               </Tooltip>

               {/* SPAM */}
               <Tooltip title='Spam'>
                  <Button
                     onClick={() => {
                        setShowDisconnectModal({
                           status: QnAPersonStatusType.SPAM,
                           message: t?.qna_spammed_modal_title,
                        });
                     }}
                     className={clsx(
                        classes.actionButton,
                        (isJoinableOrEnabled ||
                           !isConnected ||
                           isSolvedSpamAndTimeOut ||
                           isTimedOut ||
                           hasAuthority === false) &&
                           classes.disabledActionButton,
                     )}
                     disableTouchRipple
                     disabled={
                        isJoinableOrEnabled ||
                        !isConnected ||
                        isSolvedSpamAndTimeOut ||
                        isTimedOut ||
                        hasAuthority === false
                     }
                  >
                     <SpamChatIcon />
                  </Button>
               </Tooltip>
            </Grid>
         )}
         <Modal
            paperStyle={{ width: 450 }}
            onClose={() => setShowDisconnectModal(null)}
            open={showDisconnectModal != null}
            modalHeader={t?.general_message_are_your_sure}
         >
            <ModalLargeText style={{ textAlign: 'center', marginBlock: 40 }}>
               {showDisconnectModal?.message}
            </ModalLargeText>
            <ModalButtonField>
               <ButtonCancel
                  label={t?.general_message_no}
                  onClick={() => setShowDisconnectModal(null)}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                  }}
               />
               <ButtonOK
                  label={t?.general_message_yes}
                  className={classes.modalButtons}
                  style={{
                     color: 'white',
                     backgroundColor: '#0060df',
                  }}
                  onClick={() => {
                     handleLeaveMutation(activeQuestion._id, showDisconnectModal?.status);
                     setShowDisconnectModal(null);
                  }}
               />
            </ModalButtonField>
         </Modal>
      </Grid>
   );
};

export default QuestionNavButtons;

import { gql } from '@apollo/client';

// subscription
export const CREATED_CUSTOMER_PRODUCT_SUBSCRIPTION = gql`
   subscription createdCustomerProductSubscription($customer: ID!, $token: String!) {
      createdPersonCustomerProduct(data: { customer: $customer, token: $token }) {
         data {
            _id
            name
            channels
            personCount
            clientCount
            messageCount
            whatsappWebCount
            whatsappBusinessCount
            facebookPageCount
            twitterCount
            instagramCount
            teamChat
            support
            price
            feeCharged
            type
            paymentStatus
            status
            autoRenewal
            periodMode
            periodCount
            endDate
            createdAt
            ipAddress
         }
         success
         message
         code
      }
   }
`;

export const COMPLATE_3D_CUSTOMER_PRODUCT_SUBSCRIPTION = gql`
   subscription complateCustomerProductSubscription($tmpCustomerProduct: ID!, $token: String!) {
      complate3DCustomerProduct(data: { tmpCustomerProduct: $tmpCustomerProduct, token: $token }) {
         data {
            tmpCustomerProduct
            status
            message
         }
         success
         code
         message
      }
   }
`;

export const UPDATED_CUSTOMER_PRODUCT_SUBSCRIPTION = gql`
   subscription updateCustomerProductSubscription($customer: ID!, $token: String!) {
      updatedPersonCustomerProduct(data: { customer: $customer, token: $token }) {
         data {
            _id
            activeRenewalPeriodMode
            activeRenewalName
            telegramCount
            totalWhatsappBusinessCount
            totalPersonCount
            addOns {
               _id
               name
               renewalType
               price
               quantity
            }
            name
            channels
            personCount
            clientCount
            messageCount
            whatsappWebCount
            whatsappBusinessCount
            accountBalance
            facebookPageCount
            twitterCount
            instagramCount
            teamChat
            support
            price
            feeCharged
            type
            paymentStatus
            status
            autoRenewal
            periodMode
            periodCount
            endDate
            createdAt
            ipAddress
         }
         success
         code
         message
      }
   }
`;

export const CREATED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION = gql`
   subscription createdCustomerSpecialPackageSubscription($token: String!, $customer: ID!) {
      createdCustomerSpecialPackage(data: { token: $token, customer: $customer }) {
         success
         data {
            _id
            name
            channels
            features
            liveChat
            clientCount
            messages
            whatsappWebCount
            whatsappBusinessCount
            facebookPageCount
            twitterCount
            personCount
            instagramCount
            monthlyPrice
            yearlyPrice
            type
            support
            emailIntegration
            chatBot
            teamChat
            createdAt
            whatsappBusinessAPI
            telegramCount
         }
      }
   }
`;

export const UPDATED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION = gql`
   subscription updatedCustomerSpecialPackageSubscription($token: String!, $customer: ID!) {
      updatedCustomerSpecialPackage(data: { token: $token, customer: $customer }) {
         success
         data {
            _id
            name
            channels
            features
            liveChat
            clientCount
            messages
            whatsappWebCount
            whatsappBusinessCount
            facebookPageCount
            twitterCount
            personCount
            instagramCount
            monthlyPrice
            yearlyPrice
            type
            support
            emailIntegration
            chatBot
            teamChat
            createdAt
            whatsappBusinessAPI
            telegramCount
         }
      }
   }
`;

export const DELETED_CUSTOMER_SPECIAL_PACKAGE_SUBSCRIPTION = gql`
   subscription deletedCustomerSpecialPackageSubscription($token: String!, $customer: ID!) {
      deletedCustomerSpecialPackage(data: { token: $token, customer: $customer }) {
         success
         data {
            _id
            name
            channels
            features
            liveChat
            clientCount
            messages
            whatsappWebCount
            whatsappBusinessCount
            facebookPageCount
            twitterCount
            personCount
            instagramCount
            monthlyPrice
            yearlyPrice
            type
            support
            emailIntegration
            chatBot
            teamChat
            createdAt
            whatsappBusinessAPI
            telegramCount
         }
      }
   }
`;

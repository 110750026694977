export function trimLongText(text, maxLen = 20) {
   if (!text) {
      return '';
   }
   return text.length > maxLen ? text.slice(0, maxLen) + '...' : text;
}

export const currencyHandler = (currency) => {
   if (currency == 'TRY') return '₺';
   if (currency == 'USD') return '$';
   if (currency == 'EUR') return '€';
   return currency;
};

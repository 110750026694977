import React, { useContext, useEffect, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Typography, Modal as MaterialModal, Paper } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css';
import { LanguageContext } from '@helper/locale/langContext';
import Backdrop from '@material-ui/core/Backdrop';
import CloseIcon from '@material-ui/icons/Close';
import { Node } from 'reactflow';
import { FlowBlockType, FlowInstanceMessageChannel } from '../types';
import { useLazyQuery, useMutation } from '@apollo/client';
import { ADD_FLOW_BLOCK_MUTATION, UPDATE_BLOCK_MUTATION } from '@queries/Automation/mutation';
import { GET_CONNECTED_MESSAGE_PLATFORMS_QUERY, GET_LIST_BLOCK_TEMPLATES_QUERY } from '@queries/Automation/query';
import { generateRandomId } from '@helper/functions';
import TelegramIcon from '@mui/icons-material/Telegram';
import { FacebookMessengerIcon, InstagramIcon, LiveChatIcon, WhatsAppBusiness, WhatsAppIcon } from './socialIcons';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SET_BLOCKTEMPLATES } from '@store/actions/automation';

const useStyles = makeStyles((theme: Theme) => ({
   modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
   modalPaper: {
      borderRadius: 12,
      backgroundColor: '#fff',
      minWidth: 450,
      width: 600,
      maxHeight: '99vh',
      fontFamily: 'Helvetica',
   },
   header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      fontSize: '24px',
      color: 'black',
      fontWeight: 'bold',
      width: '100%',
      borderBottom: '2px solid #f5f5f5',
      padding: '10px 24px',
   },
   body: {
      paddingInline: 20,
      padding: '20px 10px 25px 10px',
      overflow: 'overlay',
      overflowX: 'hidden',
      maxHeight: 'calc(99vh - 60px)',
      minHeight: '350px',
      width: '100%',
   },
   selection: {
      display: 'flex',
      gap: '5px',
      border: '1px solid #E7E7E7',
      borderRadius: '10px',
      width: '100%',
      margin: '5px',
      padding: '15px 15px 15px 5px',
      fontSize: '1rem',
      cursor: 'pointer',
   },
   blurredSelection: {
      opacity: 0.4,
   },
   iconBox: {
      padding: '5px',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
   },
   icon: {
      width: 30,
      height: 30,
   },
   iconsBox: {
      float: 'left',
      width: '100%',
      marginBottom: '5px',
   },

   iconImg: {
      borderRadius: '7px',
      textAlign: 'center',
      padding: '5px 5px 1px 5px ',
      cursor: 'pointer',
   },
}));

interface ModalContentProps {
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   onClose: () => void;
   open: boolean;
   clickedNodeId: string;
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
}

const StartFlowModal: React.FC<ModalContentProps> = ({
   setLeftNodes,
   open,
   onClose,
   clickedNodeId,
   setIsBtnDisable,
   setContentNodes,
   setIsNewNode,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [getBlockTemplate] = useLazyQuery(GET_LIST_BLOCK_TEMPLATES_QUERY);
   const dispatch = useDispatch();
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const [getChannels] = useLazyQuery(GET_CONNECTED_MESSAGE_PLATFORMS_QUERY);
   const [OPTIONS_CHANNEL, SET_OPTIONS_CHANNEL] = useState<
      { whatsappWeb: boolean; facebook: boolean; telegram: boolean; instagram: boolean; whatsappBusiness: boolean }[]
   >([]);
   const [isLiveChatSelected, setIsLiveChatSelected] = useState(true);
   const [isWhatsAppBusinessSelected, setIsWhatsAppBusinessSelected] = useState(false);
   const [isWhatsAppWebSelected, setIsWhatsAppWebSelected] = useState(false);
   const [isInstagramSelected, setIsInstagramSelected] = useState(false);
   const [isMessengerSelected, setIsMessengerSelected] = useState(false);
   const [isTelegramSelected, setIsTelegramSelected] = useState(false);

   // Update block mutation
   const [updateBlock] = useMutation(UPDATE_BLOCK_MUTATION);
   const handleUpdateFlowBlock = async (blockId: string, blockType: FlowBlockType) => {
      try {
         const input = {
            _id: blockId,
            fields: [],
         };

         await updateBlock({
            variables: {
               input: input,
            },
            onCompleted: (res) => {
               const fields = res.updateBlock.data.fields;
               let newItem = {};
               if (blockType === FlowBlockType.AllMessageReceived) {
                  newItem = {
                     _id: res.updateBlock.data._id,
                     title: res.updateBlock.data.title,
                     type: res.updateBlock.data.type,
                     fields: [fields[0]],
                  };
               } else if (blockType === FlowBlockType.UserVisitPage) {
                  newItem = {
                     _id: res.updateBlock.data._id,
                     title: res.updateBlock.data.title,
                     type: res.updateBlock.data.type,
                     fields: [],
                  };
               }
               // setLeftNodes((prevData) =>
               //    prevData.map((node) => ({
               //       ...node,
               //       data: {
               //          ...node.data,
               //          flowBlocks: [newItem],
               //       },
               //    })),
               // );
               // setContentNodes((prevData) =>
               //    prevData.map((node) => ({
               //       ...node,
               //       data: {
               //          ...node.data,
               //          flowBlocks: [newItem],
               //       },
               //    })),
               // );
               setIsNewNode(generateRandomId());
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   // Add block mutation
   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlock = async (
      flowBlockTemplateId: string,
      nodeId: string,
      channel: FlowInstanceMessageChannel,
      blockType: FlowBlockType,
   ) => {
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  channelType: channel,
               },
            },

            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];

               handleUpdateFlowBlock(response._id, blockType);
               setIsBtnDisable(false);
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   useEffect(() => {
      getChannels({
         variables: {
            customerId: person.customer._id,
         },
         fetchPolicy: 'cache-first',
         onCompleted: (res) => {
            const response = [res.getConnectedMessagePlatforms.data];
            SET_OPTIONS_CHANNEL(response);
         },
      });
   }, []);

   const handleSelection = (channel: FlowInstanceMessageChannel, blockType: FlowBlockType) => {
      const fetchBlockTemplates = async () => {
         const response = await getBlockTemplate({
            variables: { input: {} },
            fetchPolicy: 'network-only',
         });

         if (response.data) {
            return response.data.listBlockTemplates.data;
         }

         return null;
      };

      (async () => {
         let blockTemplate = [];
         if (LIST_BLOCK_TEMPLATE?.length > 0) {
            blockTemplate = LIST_BLOCK_TEMPLATE;
         } else {
            blockTemplate = await fetchBlockTemplates();
            dispatch({ type: SET_BLOCKTEMPLATES, payload: blockTemplate });
         }
         const blockTemplates = await fetchBlockTemplates();
         if (blockTemplate) {
            const blockTemplateId = blockTemplates?.find((item: { type: FlowBlockType }) => item.type === blockType);
            if (blockTemplateId) {
               handleAddFlowBlock(blockTemplateId._id, clickedNodeId, channel, blockType);
            }
         }
      })();

      onClose();
   };

   const handleLiveChat = () => {
      setIsTelegramSelected(false);
      setIsLiveChatSelected(true);
      setIsInstagramSelected(false);
      setIsMessengerSelected(false);
      setIsWhatsAppBusinessSelected(false);
      setIsWhatsAppWebSelected(false);
   };
   const handleInstagram = () => {
      setIsTelegramSelected(false);
      setIsLiveChatSelected(false);
      setIsInstagramSelected(true);
      setIsMessengerSelected(false);
      setIsWhatsAppBusinessSelected(false);
      setIsWhatsAppWebSelected(false);
   };
   const handleMessenger = () => {
      setIsTelegramSelected(false);
      setIsLiveChatSelected(false);
      setIsInstagramSelected(false);
      setIsMessengerSelected(true);
      setIsWhatsAppBusinessSelected(false);
      setIsWhatsAppWebSelected(false);
   };
   const handleWhatsAppBusiness = () => {
      setIsTelegramSelected(false);
      setIsLiveChatSelected(false);
      setIsInstagramSelected(false);
      setIsMessengerSelected(false);
      setIsWhatsAppBusinessSelected(true);
      setIsWhatsAppWebSelected(false);
   };
   const handleWhatsAppWeb = () => {
      setIsTelegramSelected(false);
      setIsLiveChatSelected(false);
      setIsInstagramSelected(false);
      setIsMessengerSelected(false);
      setIsWhatsAppBusinessSelected(false);
      setIsWhatsAppWebSelected(true);
   };
   const handleTelegram = () => {
      setIsTelegramSelected(true);
      setIsLiveChatSelected(false);
      setIsInstagramSelected(false);
      setIsMessengerSelected(false);
      setIsWhatsAppBusinessSelected(false);
      setIsWhatsAppWebSelected(false);
   };

   return (
      <MaterialModal
         className={classes.modal}
         open={open}
         onClose={onClose}
         closeAfterTransition
         BackdropComponent={Backdrop}
         BackdropProps={{
            timeout: 500,
         }}
         disableEscapeKeyDown={false}
         disableBackdropClick={false}
      >
         <Paper className={classes.modalPaper}>
            <Grid container className={classes.header}>
               <Typography variant='inherit'>{t.atomation_flow_trigger_block_trigger}</Typography>
               <CloseIcon onClick={onClose} style={{ cursor: 'pointer', color: 'gray' }} />
            </Grid>

            <Grid className={classes.body}>
               <Grid className={classes.iconsBox}>
                  {OPTIONS_CHANNEL.map((item) => (
                     <div style={{ display: 'flex', gap: '8px', marginLeft: '5px' }}>
                        <div
                           style={{
                              border: isLiveChatSelected ? '1px solid #157cfc' : '1px solid #EDEDED',
                           }}
                           className={classes.iconImg}
                           onClick={handleLiveChat}
                        >
                           <LiveChatIcon selected={isLiveChatSelected} className={classes.icon} />
                        </div>
                        {item.whatsappWeb === true && (
                           <div
                              style={{
                                 border: isWhatsAppWebSelected ? '1px solid #157cfc' : '1px solid #EDEDED',
                              }}
                              className={classes.iconImg}
                              onClick={handleWhatsAppWeb}
                           >
                              <WhatsAppIcon selected={isWhatsAppWebSelected} className={classes.icon} />
                           </div>
                        )}
                        {item.whatsappBusiness === true && (
                           <div
                              style={{
                                 border: isWhatsAppBusinessSelected ? '1px solid #157cfc' : '1px solid #EDEDED',
                              }}
                              className={classes.iconImg}
                              onClick={handleWhatsAppBusiness}
                           >
                              <WhatsAppBusiness selected={isWhatsAppBusinessSelected} className={classes.icon} />
                           </div>
                        )}
                        {item.telegram === true && (
                           <div
                              style={{
                                 border: isTelegramSelected ? '1px solid #157cfc' : '1px solid #EDEDED',
                              }}
                              className={classes.iconImg}
                              onClick={handleTelegram}
                           >
                              <div
                                 style={{
                                    width: 30,
                                    height: 30,
                                    backgroundColor: '#25A3E1',
                                    borderRadius: '45%',
                                    opacity: isTelegramSelected ? '' : 0.4,
                                 }}
                              >
                                 <TelegramIcon
                                    className={classes.icon}
                                    style={{ color: '#fff', margin: '2px 2px 0 0' }}
                                 />
                              </div>
                           </div>
                        )}
                        {item.instagram === true && (
                           <div
                              style={{
                                 border: isInstagramSelected ? '1px solid #157cfc' : '1px solid #EDEDED',
                              }}
                              className={classes.iconImg}
                              onClick={handleInstagram}
                           >
                              <InstagramIcon selected={isInstagramSelected} className={classes.icon} />
                           </div>
                        )}
                        {item.facebook === true && (
                           <div
                              style={{
                                 border: isMessengerSelected ? '1px solid #157cfc' : '1px solid #EDEDED',
                              }}
                              className={classes.iconImg}
                              onClick={handleMessenger}
                           >
                              <FacebookMessengerIcon selected={isMessengerSelected} className={classes.icon} />
                           </div>
                        )}
                     </div>
                  ))}
               </Grid>

               <Grid container>
                  {isLiveChatSelected ? (
                     <div>
                        <div
                           onClick={() =>
                              handleSelection(FlowInstanceMessageChannel.LiveChat, FlowBlockType.AllMessageReceived)
                           }
                           className={classes.selection}
                        >
                           <div>
                              <div className={classes.iconBox}>
                                 <LiveChatIcon selected={isLiveChatSelected} className={classes.icon} />
                              </div>
                           </div>
                           <div>
                              <div style={{ fontWeight: 'bold' }}>
                                 {t.atomation_flow_trigger_modal_user_send_message}
                              </div>
                              <div>{t.atomation_flow_trigger_modal_automatically_message_reply}</div>
                           </div>
                        </div>
                        <div
                           className={classes.selection}
                           onClick={() =>
                              handleSelection(FlowInstanceMessageChannel.LiveChat, FlowBlockType.UserVisitPage)
                           }
                        >
                           <div className={classes.iconBox}>
                              <LiveChatIcon selected={isLiveChatSelected} className={classes.icon} />
                           </div>
                           <div style={{ width: '100%' }}>
                              <div style={{ fontWeight: 'bold' }}>
                                 {t.atomation_flow_trigger_modal_user_visite_page}
                              </div>
                              <div>{t.atomation_flow_trigger_modal_welcome_user}</div>
                           </div>
                        </div>
                     </div>
                  ) : isWhatsAppWebSelected ? (
                     <div>
                        <div
                           onClick={() =>
                              handleSelection(FlowInstanceMessageChannel.WhatsappWeb, FlowBlockType.AllMessageReceived)
                           }
                           className={classes.selection}
                        >
                           <div>
                              <div className={classes.iconBox}>
                                 <WhatsAppIcon selected={isWhatsAppWebSelected} className={classes.icon} />
                              </div>
                           </div>
                           <div>
                              <div style={{ fontWeight: 'bold' }}>
                                 {t.atomation_flow_trigger_modal_user_send_message}
                              </div>
                              <div>{t.atomation_flow_trigger_modal_automatically_message_reply}</div>
                           </div>
                        </div>
                     </div>
                  ) : isInstagramSelected ? (
                     <div>
                        <div
                           onClick={() =>
                              handleSelection(FlowInstanceMessageChannel.Instagram, FlowBlockType.AllMessageReceived)
                           }
                           className={classes.selection}
                        >
                           <div>
                              <div className={classes.iconBox}>
                                 <InstagramIcon selected={isInstagramSelected} className={classes.icon} />
                              </div>
                           </div>
                           <div>
                              <div style={{ fontWeight: 'bold' }}>
                                 {t.atomation_flow_trigger_modal_user_send_message}
                              </div>
                              <div>{t.atomation_flow_trigger_modal_automatically_message_reply}</div>
                           </div>
                        </div>

                        <div className={`${classes.selection} ${classes.blurredSelection}`}>
                           <div className={classes.iconBox}>
                              <InstagramIcon selected={isInstagramSelected} className={classes.icon} />
                           </div>
                           <div style={{ width: '100%' }}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                 <div style={{ fontWeight: 'bold' }}>
                                    {t.atomation_flow_trigger_modal_user_post_comment}
                                 </div>
                                 <div style={{ fontWeight: 'bold' }}>{t.atomation_flow_trigger_modal_coming_soon}</div>
                              </div>
                              <div>{t.atomation_flow_trigger_modal_automaically_post_reply}</div>
                           </div>
                        </div>
                     </div>
                  ) : isWhatsAppBusinessSelected ? (
                     <div>
                        <div
                           onClick={() =>
                              handleSelection(
                                 FlowInstanceMessageChannel.WhatsappBusiness,
                                 FlowBlockType.AllMessageReceived,
                              )
                           }
                           className={classes.selection}
                        >
                           <div>
                              <div className={classes.iconBox}>
                                 <WhatsAppBusiness selected={isWhatsAppBusinessSelected} className={classes.icon} />
                              </div>
                           </div>
                           <div>
                              <div style={{ fontWeight: 'bold' }}>
                                 {t.atomation_flow_trigger_modal_user_send_message}
                              </div>
                              <div>{t.atomation_flow_trigger_modal_automatically_message_reply}</div>
                           </div>
                        </div>
                     </div>
                  ) : isMessengerSelected ? (
                     <div>
                        <div
                           onClick={() =>
                              handleSelection(FlowInstanceMessageChannel.Facebook, FlowBlockType.AllMessageReceived)
                           }
                           className={classes.selection}
                        >
                           <div>
                              <div className={classes.iconBox}>
                                 <FacebookMessengerIcon selected={isMessengerSelected} className={classes.icon} />
                              </div>
                           </div>
                           <div>
                              <div style={{ fontWeight: 'bold' }}>
                                 {t.atomation_flow_trigger_modal_user_send_message}
                              </div>
                              <div>{t.atomation_flow_trigger_modal_automatically_message_reply}</div>
                           </div>
                        </div>

                        <div className={`${classes.selection} ${classes.blurredSelection}`}>
                           <div className={classes.iconBox}>
                              <FacebookMessengerIcon selected={isMessengerSelected} className={classes.icon} />
                           </div>
                           <div style={{ width: '100%' }}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                 <div style={{ fontWeight: 'bold' }}>
                                    {t.atomation_flow_trigger_modal_user_post_comment}
                                 </div>
                                 <div style={{ fontWeight: 'bold' }}>{t.atomation_flow_trigger_modal_coming_soon}</div>
                              </div>
                              <div>{t.atomation_flow_trigger_modal_automaically_post_reply}</div>
                           </div>
                        </div>
                     </div>
                  ) : isTelegramSelected ? (
                     <div>
                        <div
                           onClick={() =>
                              handleSelection(FlowInstanceMessageChannel.Telegram, FlowBlockType.AllMessageReceived)
                           }
                           className={classes.selection}
                        >
                           <div>
                              <div className={classes.iconBox}>
                                 <div
                                    style={{
                                       width: 30,
                                       height: 30,
                                       backgroundColor: '#25A3E1',
                                       borderRadius: '45%',
                                       opacity: isTelegramSelected ? '' : 0.4,
                                    }}
                                 >
                                    <TelegramIcon
                                       className={classes.icon}
                                       style={{ color: '#fff', margin: '2px 0 0 2px' }}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div>
                              <div style={{ fontWeight: 'bold' }}>
                                 {t.atomation_flow_trigger_modal_user_send_message}
                              </div>
                              <div>{t.atomation_flow_trigger_modal_automatically_message_reply}</div>
                           </div>
                        </div>
                     </div>
                  ) : (
                     <div></div>
                  )}
               </Grid>
            </Grid>
         </Paper>
      </MaterialModal>
   );
};

export default StartFlowModal;

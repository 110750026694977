import { NoSelectedChatIcon } from '@assets/icons/ChatPageIcons';
import { responsiveMeasure } from '@helper/config';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainPanel from '@modules/Chat/MainPanel';
import useChatSubscriptions from '@modules/Chat/hooks/useChatSubscriptions';
import { SET_ACTIVE_CHAT } from '@store/actions/chat';
import { getActiveChat, getMyChatList, getMyCustomerChatList } from '@store/reducers/chat';
import { getPerson } from '@store/reducers/person';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import useWindowResize from '../../hooks/useWindowResize';
import LeftPanel from './LeftPanel';
import RightPanel from './RightPanel';

const useStyles = makeStyles((theme) => ({
   content: {
      flexGrow: 1,
      height: '100vh',
      overflow: 'hidden',
      [theme.breakpoints.down('sm')]: {
         minHeight: '-webkit-fill-available',
         height: '100%',
      },
   },
   container: {
      margin: 0,
      padding: 0,
      backgroundColor: 'white',
   },
   appBarSpacer: theme.mixins.toolbar,
   footer: {
      backgroundColor: 'green',
      borderTop: '1px solid #E7E7E7',
      textAlign: 'center',
      padding: 20,
      position: 'fixed',
      left: 527,
      bottom: 0,
   },
   profileBackgroundContainer: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      // width: 500,
      // maxWidth: 480,
   },
   noChatIcon: {
      width: 200,
      height: 200,
   },
   noChatText: {
      color: '#ccd8e8',
      fontSize: '1.1rem',
      fontWeight: 'bold',
      marginTop: theme.spacing(4),
   },
}));

const Chat = ({ profileStatus, onProfileCloseRequest }) => {
   const classes = useStyles();
   const history = useHistory();
   const { _id } = useParams(); // get chat _id
   const dispatch = useDispatch();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const me = useSelector(getPerson);
   const activeChat = useSelector(getActiveChat);

   const chatList = useSelector(getMyCustomerChatList);
   const myChatList = useSelector(getMyChatList);
   const { width } = useWindowResize();

   useChatSubscriptions();

   /* Responsive width controller system:*/
   let usersPanelWidth;
   if (width < responsiveMeasure.mobile) {
      usersPanelWidth = width;
   } else if (width > responsiveMeasure.mobile && width < responsiveMeasure.tablet) {
      usersPanelWidth = 403;
   } else {
      usersPanelWidth = 403;
   }

   useEffect(() => {
      let chat_list = chatList?.chats;
      let my_chat_list = myChatList.chats;
      let chat = [chat_list, my_chat_list].flat().find((chat) => chat._id === _id);
      if (chat) {
         dispatch({
            type: SET_ACTIVE_CHAT,
            payload: { activeChat: chat },
         });
      } else {
         history.replace('/chat');
      }
   }, [_id]);

   return (
      <main className={classes.content}>
         <Grid container className={classes.container} wrap='nowrap'>
            {((width < responsiveMeasure.mobile.width &&
               !profileStatus &&
               !history.location.pathname.includes('/chat/')) ||
               width >= responsiveMeasure.mobile.width) && (
               <Grid
                  item
                  style={{
                     width: width < responsiveMeasure.mobile.width && '100%',
                  }}
               >
                  <LeftPanel
                     width={width < responsiveMeasure.mobile.width ? (width < 300 ? '300px' : '100%') : usersPanelWidth}
                  />
               </Grid>
            )}
            {activeChat?._id && history.location.pathname.includes('/chat/') ? (
               <Grid item container wrap='nowrap'>
                  <MainPanel isEmail={activeChat?.type==='EMAIL'} profileStatus={profileStatus} width={width} />
                  {profileStatus && (
                     <RightPanel
                        open={profileStatus}
                        activeChatId={_id}
                        activeChatType={activeChat?.type}
                        onClose={() => onProfileCloseRequest('close')}
                     />
                  )}
               </Grid>
            ) : (
               !profileStatus &&
               !history.location.pathname.includes('/chat/') && (
                  <Hidden smDown>
                     <Grid item xs={12} style={{ textAlign: 'center', margin: 'auto' }} direction='column'>
                        <NoSelectedChatIcon className={classes.noChatIcon} />
                        <Typography className={classes.noChatText}>{t?.there_is_no_selected_chat}</Typography>
                     </Grid>
                  </Hidden>
               )
            )}
         </Grid>
      </main>
   );
};

export default Chat;

import { useMutation } from '@apollo/client';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import { LanguageContext } from '@helper/locale/langContext';
import WithUrlTranslations from '@hoc/WithUrlTranslations';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { DELETE_TEMPLATE_MUTATION } from '@queries/Settings/Service/GraphQL/Platform/mutation';
import { useSnackbar } from 'notistack';
import React, { useContext, useState } from 'react';

const useStyles = makeStyles((theme: any) => ({
    modalContainer: {
        backgroundColor: '#fff',
        width: 450,
        borderRadius: 16,
    },
    modalHeader: {
        height: 50,
        padding: '5px 15px',
        borderBottom: '1px solid #F1F3F7',
    },
    modalTitle: {
        color: theme.chatApp.general.pallet.passiveStructureBlue,
        fontSize: '1.1rem',
        fontWeight: 'bold',
    },
    modalBody: {
        paddingInline: 20,
        paddingBottom: 15,
    },
    modalBodyIconContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    modalDescription: {
        textAlign: 'center',
        paddingBlock: 30,
    },
    modalBodyRow: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
    },
    modalText: {
        color: '#797575',
        fontSize: '1rem',
        margin: 20,
    },
    modalButtons: {
        fontSize: '18px',
        textTransform: 'none',
        paddingBlock: 5,
        paddingInline: 15,
        minWidth: 140,
        height: 44,
        borderRadius: 6,
        transition: 'all .5s',
        '&:disabled': {
            opacity: '0.7'
         }
    },
}));

const RemoveTemplate = ({ open, onClose, templateId }: any) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;
    const [createLoading, setCreateLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const [deleteTemplateMutation] = useMutation(DELETE_TEMPLATE_MUTATION, {
        async update(
            cache,
            {
                data: {
                    deleteTemplate: { data: deleteTemplate },
                },
            }
        ) { },
    });
    return (
        <Modal paperStyle={{ width: 450 }} onClose={onClose} open={open} modalHeader={t['settings_whatsapp_business_template_delete_title']}>
            <ModalLargeText style={{ textAlign: 'center', marginBlock: 30 }}>
                {t['settings_whatsapp_business_template_delete_text']}
            </ModalLargeText>
            <ModalButtonField>
                <ButtonCancel
                    className={classes.modalButtons}
                    disabled={createLoading}
                    label={t?.page_chat_btn_filter_cancel}
                    onClick={() => onClose()}
                    style={{ color: '#212121', background: '#eeee' }}
                />
                <ButtonOK
                    className={classes.modalButtons}
                    label={t['campaign_remove_delete']}
                    style={{
                        color: 'white',
                        backgroundColor: '#ef5350',
                    }}
                    startIcon={createLoading ? <CircularProgress size={20} /> : null}
                    disabled={createLoading}
                    onClick={() => {
                        setCreateLoading(true);
                        deleteTemplateMutation({
                            variables: {
                                data: {
                                    templateId,
                                },
                            },
                        })
                            .then((res) => {
                                setCreateLoading(false);
                                onClose(true);
                                enqueueSnackbar(t['page_settings_typo_chat_settings_automatic_actions_success_message'], {
                                    variant: 'success',
                                });
                            })
                            .catch((err) => {
                                setCreateLoading(false);
                                onClose(false);
                                enqueueSnackbar(err.message, {
                                    // send error message
                                    variant: 'error',
                                });
                            });
                    }}
                />
            </ModalButtonField>
        </Modal>
    );
};

export default WithUrlTranslations(RemoveTemplate);
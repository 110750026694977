import React, { useState, useEffect, useContext } from 'react';
import IntroPreview from '../../SharedComponents/Previews/IntroPreview';
import IconAndGreetingPreview from '../../SharedComponents/Previews/IconAndGreetingPreview';
import LiveChatSettingsContext from '../../../../../../contexts/LiveChatSettingsContext';
import usePreviousState from '../../../../../../hooks/usePreviousState';

const Previews = () => {
   const { liveChatPreviewSettings } = useContext(LiveChatSettingsContext);
   const prevPreviewData = usePreviousState(liveChatPreviewSettings, liveChatPreviewSettings);
   const [previewType, setPreviewType] = useState('intro');

   useEffect(() => {
      if (prevPreviewData && liveChatPreviewSettings) {
         const {
            iconLabel: prevIconLabel,
            iconPosition: prevIconPosition,
            iconSize: prevIconSize,
            themeColor: prevThemeColor,
            liveChatIconType: prevLiveChatIconType,
            uploadedLiveChatIcon: prevUploadedLiveChatIcon,
            spaceBottom: prevSpaceBottom,
            spaceSide: prevSpaceSide,
         } = prevPreviewData;
         const { iconLabel, iconPosition, iconSize, themeColor, liveChatIconType, uploadedLiveChatIcon, spaceBottom, spaceSide } = liveChatPreviewSettings;
         if (prevThemeColor === themeColor) {
            if (
               (prevSpaceBottom != 0 || prevSpaceSide != 0 || prevLiveChatIconType !== '' || prevUploadedLiveChatIcon !== '' || prevIconLabel !== '' || prevIconPosition !== undefined || prevIconSize !== undefined) &&
               (prevSpaceBottom !== spaceBottom || prevSpaceSide !== spaceSide || prevLiveChatIconType !== liveChatIconType || prevUploadedLiveChatIcon !== uploadedLiveChatIcon || prevIconLabel !== iconLabel || prevIconPosition !== iconPosition || prevIconSize !== iconSize)
            ) {
               setPreviewType('icon');
            } else {
               setPreviewType('intro');
            }
         }
      }
   }, [liveChatPreviewSettings]);

   return (
      <>
         {previewType === 'intro' && liveChatPreviewSettings && <IntroPreview type='greeting' />}
         {previewType === 'icon' && liveChatPreviewSettings && <IconAndGreetingPreview type='icon' />}
      </>
   );
};

export default Previews;

import { useMutation } from '@apollo/client';
import { Button, CircularProgress, IconButton, TextField, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import clsx from 'clsx';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { useSnackbar } from 'notistack';
import React, { FC, Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ChatNoteIcon, ChatQuickAnswerIcon } from '@assets/icons/ChatPageIcons';
import { LanguageContext } from '@helper/locale/langContext';
import useWindowResize from '../../../../hooks/useWindowResize';
import { getActiveQuestion, getContentClickedItem, getContentState } from '@store/reducers/questions';
import { MyTheme } from '@styles/config';
import ButtonBasedPopup from '@components/ButtonBasedPopup';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import MyTypography from '@components/MyTypography';
import { JOIN_QNA_MUTATION } from '../../../../queries/Questions/Service/mutation';
import { QnAPersonStatusType, QnAPlatformType, QnAStatus, QnAType } from '../../util/types';
import Note from './note';
import QuestionQuickAnswer from './quickAnswer';

export type SubmitInputType = {
   type: QnAType;
   text: string;
};

interface ChatInputProps {
   message: string;
   hasAuthority: boolean | null;
   me?: any;
   loading: boolean;
   buttonLoading: boolean;
   submit: (data: SubmitInputType) => void;
}

const useStyles = makeStyles<MyTheme, any>((theme) => ({
   root: {
      borderRadius: 15,
      position: 'fixed',
      bottom: 5,
      border: '2px solid',
      overflowY: 'auto',
      width: 'inherit',
      zIndex: 100,
      transition: 'all .2s',
      borderColor: (props) => (props.focusedInput ? '#76838C' : '#D0DDE6'),
      backgroundColor: '#fff',
   },
   disabledContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 1000,
      textAlign: 'center',
      paddingTop: 60,
      '-webkit-backdrop-filter': 'blur(8px)',
      'backdrop-filter': 'blur(8px)',
   },
   joinButton: {
      marginLeft: 10,
      color: 'white',
      width: 100,
      height: 34,
      backgroundColor: '#47D054',
      fontSize: '1.1rem',
      '&:hover': {
         backgroundColor: '#47D054',
         filter: 'brightness(1.04)',
      },
   },
   disabledButton: {
      '&$disabled': {
         backgroundColor: 'rgba(21, 124, 252, 0.7)',
         cursor: 'not-allowed',
         color: '#fff',
      },
   },
   textInput: {
      lineHeight: 1.9,
   },
   inputSection: {
      padding: '1px 20px 1px 20px',
   },
   bottomSection: {
      padding: '4px 20px 4px 20px',
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
   },
   inputIconContainer: {
      width: 40,
      height: 40,
      backgroundColor: 'transparent',
      borderRadius: 5,
      border: '1px solid #E2E2E2',
      '&:hover': {
         '& > *': {
            transform: 'scale(1.1)',
            opacity: 1,
         },
      },
      '&:disabled': {
         opacity: 0.5,
      },
      transition: 'all .4s',
   },
   quickAnswerIcon: {
      width: 20,
      height: 20,
      transition: 'all .5s',
      textAlign: 'center',
      opacity: 0.7,
      cursor: 'pointer',
   },
   noteIcon: {
      opacity: 1,
      fill: '#f2b51f',
   },
   textInputRoot: {
      '& label.Mui-focused': {
         color: 'white',
      },
      '& .MuiInput-underline:after': {
         borderBottomColor: 'yellow',
      },
      '& .MuiOutlinedInput-root': {
         '& fieldset': {
            borderColor: 'white',
         },
         '&:hover fieldset': {
            borderColor: 'white',
         },
         '&.Mui-focused fieldset': {
            borderColor: 'yellow',
         },
      },
   },
   button: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      padding: 5,
      backgroundColor: 'white',
      borderRadius: 10,
      paddingRight: 15,
   },
   buttonTitle: {
      marginLeft: 5,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      fontSize: '0.9rem',
   },
   buttonNote: {
      backgroundColor: '#f2cf35',
   },
   buttonSend: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureAzure,
      color: 'white',
   },
   buttonSendTitle: {
      color: 'white',
   },
   iconColor: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   emojiIconContainer: {
      '&:disabled': {
         opacity: 0.5,
      },
   },
}));

interface ChatInputState {
   text: string;
   quickAnswer: boolean;
   showEmoji: boolean;
}

const ChatInput: FC<ChatInputProps> = (props) => {
   const { submit, hasAuthority, message, buttonLoading } = props;
   const contentState = useSelector(getContentState);
   const clickedItem = useSelector(getContentClickedItem);
   const activeQuestion = useSelector(getActiveQuestion);
   const textInput = useRef<HTMLInputElement | null>(null);
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const [openNotes, setOpenNotes] = useState(false);
   const [openEmoji, setOpenEmoji] = useState(false);
   const [focusedInput, setFocusedInput] = useState(false);
   const [joinChatMutation, { loading: joinChatLoading }] = useMutation(JOIN_QNA_MUTATION);
   const classes = useStyles({ ...props, focusedInput });
   const { enqueueSnackbar } = useSnackbar();
   const { width } = useWindowResize();
   const [submitDisabledStatus, setSubmitDisabledStatus] = useState<boolean>(true);
   let isConnected = true;
   const [state, setState] = useState<ChatInputState>({
      text: '',
      quickAnswer: false,
      showEmoji: false,
   });
   const [filterAnswerList, setFilterAnswerList] = useState('');


   if (activeQuestion?.platformType === QnAPlatformType.HEPSIBURADA && activeQuestion.hepsiburadaAccount !== null) {
      isConnected = activeQuestion?.hepsiburadaAccount?.isConnected;
   } else if (activeQuestion?.platformType === QnAPlatformType.TRENDYOL && activeQuestion.trendyolAccount !== null) {
      isConnected = activeQuestion?.trendyolAccount?.isConnected;
   } else if (activeQuestion?.platformType === QnAPlatformType.INSTAGRAM && activeQuestion.instagramPage !== null) {
      isConnected = activeQuestion?.instagramPage?.isCommentEnable;
   } else if (activeQuestion?.platformType === QnAPlatformType.FACEBOOK && activeQuestion.facebookPage !== null) {
      isConnected = activeQuestion?.facebookPage?.isCommentEnable;
   }

   useEffect(() => {
      if (openEmoji) {
         if (state.quickAnswer && !isSmallScreen) {
            //eslint-disable-next-line
            textInput?.current?.focus();
         }
      }
   }, [openEmoji, state.quickAnswer, isSmallScreen]);

   // all input text on change event
   const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = e.target;
      if (!state.quickAnswer && state.text === '' && value === '/') {
         setState((prevState) => ({ ...prevState, quickAnswer: true, text: value }));
      } else if (state.quickAnswer && state.text.startsWith('/') && value === '') {
         setState((prevState) => ({ ...prevState, quickAnswer: false, text: value }));
      } else if (state.quickAnswer && state.text.startsWith('/') && value !== '') {
         setState((prevState) => ({ ...prevState, text: value }));
         setFilterAnswerList(value.slice(1));
      } else {
         setState((prevState) => ({ ...prevState, [name]: value }));
      }
   };

   const handleClickQuickAnswer = async (answer: string) => {
      setState((prevState) => ({
         ...prevState,
         text: answer,
         quickAnswer: false,
      }));
   };

   useEffect(() => {
      if (
         activeQuestion?.platformType === QnAPlatformType.TRENDYOL ||
         activeQuestion?.platformType === QnAPlatformType.N11
      ) {
         if (state.text.trim().length >= 10) {
            setSubmitDisabledStatus(false);
            return;
         } else {
            setSubmitDisabledStatus(true);
            return;
         }
      } else if (state.text.trim().length > 0) {
         setSubmitDisabledStatus(false);
         return;
      } else {
         setSubmitDisabledStatus(true);
         return;
      }
   }, [activeQuestion?.platformType, state.text]);

   const addEmoji = (e: any) => {
      let emoji = e.native;
      const newTest = state.text + emoji;
      setState((prevState) => ({
         ...prevState,
         text: newTest,
      }));
      setOpenEmoji(false);
   };

   const handlePressEnter = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && !submitDisabledStatus) {
         if (!e.shiftKey) {
            e.preventDefault();
            submit({
               type: QnAType.QNA,
               text: state.text,
            });
            setState((prevState) => ({
               ...prevState,
               text: '',
               showEmoji: false,
            }));
         }
      }
   };

   const handleJoinChat = () => {
      joinChatMutation({
         variables: {
            _id: activeQuestion._id,
         },
      })
         .then(({ data }) => {
            enqueueSnackbar(t?.question_joined_question_success, {
               variant: 'success',
               autoHideDuration: 2500,
            });
         })
         .catch((err) => {
            enqueueSnackbar(err.message, {
               variant: 'error',
               autoHideDuration: 2500,
            });
         });
   };

   const getPlaceHolderText = useCallback(() => {
      if (!clickedItem) {
         return t?.please_select_a_question;
      } else if (!isConnected) {
         return 'This channel is not connected.';
      } else {
         if (clickedItem.status !== QnAStatus.WAITING) {
            return t?.page_chat_typo_reject_placeholder;
         } else {
            return t?.page_chat_typo_enter_some_text;
         }
      }
   }, [clickedItem, isConnected, t]);

   const isNotAnswerable = !clickedItem || clickedItem.status !== QnAStatus.WAITING || !isConnected;

   const isRejectedTrendyol =
      activeQuestion?.platformType === QnAPlatformType.TRENDYOL && contentState.operation === 'reject';
   const isRejectedN11 = activeQuestion?.platformType === QnAPlatformType.N11 && contentState.operation === 'reject';
   const isInstagramOrFacebook =
      activeQuestion?.platformType === QnAPlatformType.FACEBOOK ||
      activeQuestion?.platformType === QnAPlatformType.INSTAGRAM;

   const renderChatInputDisabled = () => {
      // can answer
      if (activeQuestion?.joinedPerson !== null && !activeQuestion?.canJoin && !isRejectedTrendyol && !isRejectedN11) {
         return undefined;
      }
      // account is disabled
      else if (!isConnected || isRejectedTrendyol || isRejectedN11) {
         return (
            <div className={classes.disabledContainer}>
               <MyTypography color='error' variant={'body1'} bold>
                  {isRejectedTrendyol
                     ? t?.rejected_trendyol_message
                     : isRejectedN11
                     ? t?.rejected_n11_message
                     : t?.this_qna_account_is_not_connected}
               </MyTypography>
            </div>
         );
      }
      // joinable
      else if (
         activeQuestion?.canJoin
         // ||  activeQuestion?.status === QnAPersonStatusType.ARCHIVE
      ) {
         return (
            <div className={classes.disabledContainer}>
               {message}
               {
                  // activeQuestion?.status === QnAPersonStatusType.ARCHIVE ||
                  activeQuestion?.canJoin ? (
                     <Button className={classes.joinButton} variant='outlined' disableRipple onClick={handleJoinChat}>
                        {t['page_chat_typo_join']}
                     </Button>
                  ) : (
                     ''
                  )
               }
            </div>
         );
      } else if (!activeQuestion?.canJoin && activeQuestion?.joinedPerson === null) {
         return (
            <div className={classes.disabledContainer}>
               {message}

               <a href='https://qpien.com' role='button' target='_blank'>
                  {t['learn_more']}
               </a>
            </div>
         );
      }
   };

   return (
      <div className={classes.root}>
         {joinChatLoading ? (
            <Grid justifyContent='center' style={{ height: '120px' }} alignItems='center' container>
               <CircularProgress size={36} color='primary' />
            </Grid>
         ) : (
            <Fragment>
               {renderChatInputDisabled()}
               <Grid>{state.quickAnswer && <QuestionQuickAnswer onSend={handleClickQuickAnswer} filterAnswerList={filterAnswerList} />}</Grid>
               <Grid style={{ width: '100%' }}>
                  <Grid
                     direction='column'
                     container
                     wrap='nowrap'
                     justifyContent='flex-end'
                     className={classes.inputSection}
                  >
                     <Grid item xs={12}>
                        <TextField
                           inputRef={textInput}
                           id='textInput'
                           fullWidth
                           className={classes.textInput}
                           rowsMax={5}
                           onFocus={() => setFocusedInput(true)}
                           onBlur={() => setFocusedInput(false)}
                           minRows={1}
                           multiline
                           InputProps={{
                              disableUnderline: true,
                              inputProps: {
                                 style: {
                                    lineHeight: '20px',
                                 },
                              },
                              endAdornment: (
                                 <Fragment>
                                    {activeQuestion?.platformType === QnAPlatformType.TRENDYOL &&
                                       state.text.trim().length < 10 && (
                                          <Grid container style={{ width: 50 }}>
                                             <MyTypography bold color='textSecondary' variant='caption'>
                                                Min{' '}
                                                <MyTypography color='error' variant='caption' bold>
                                                   {' '}
                                                   {10 - state.text.trim().length}{' '}
                                                </MyTypography>
                                             </MyTypography>
                                          </Grid>
                                       )}
                                 </Fragment>
                              ),
                           }}
                           onChange={handleInputChange}
                           onKeyDown={(e) => {
                              if ((e.key === ' ' || e.key === 'Enter') && state.text.length === 0) {
                                 e.preventDefault();
                              }
                           }}
                           value={state.text}
                           name='text'
                           placeholder={getPlaceHolderText()}
                           onKeyPress={handlePressEnter}
                           disabled={isNotAnswerable || hasAuthority === false}
                        />
                     </Grid>
                  </Grid>

                  <Grid
                     item
                     container
                     direction='row'
                     xs={12}
                     justifyContent='space-between'
                     alignItems='center'
                     className={classes.bottomSection}
                  >
                     <Grid xs={6} container item spacing={1} alignItems='center' wrap='nowrap'>
                        <Grid item>
                           <div>
                              <IconButton
                                 disabled={isNotAnswerable || hasAuthority === false}
                                 className={classes.inputIconContainer}
                                 onClick={() =>
                                    setState((prevState) => ({
                                       ...prevState,
                                       quickAnswer: !state.quickAnswer,
                                    }))
                                 }
                              >
                                 <ChatQuickAnswerIcon className={classes.quickAnswerIcon} />
                              </IconButton>
                           </div>
                        </Grid>

                        <Grid item>
                           <ButtonBasedPopup
                              anchor={{
                                 vertical: -25,
                                 horizontal: 'center',
                              }}
                              transfer={{
                                 vertical: 'bottom',
                                 horizontal: 'left',
                              }}
                              elevation={12}
                              button={
                                 <IconButton
                                    className={classes.inputIconContainer}
                                    disabled={isNotAnswerable || hasAuthority === false}
                                 >
                                    <ChatNoteIcon className={clsx(classes.quickAnswerIcon, classes.noteIcon)} />
                                 </IconButton>
                              }
                              open={openNotes}
                              onOpen={() => setOpenNotes(true)}
                              onClose={() => setOpenNotes(false)}
                           >
                              <Note
                                 onCancel={() => setOpenNotes(false)}
                                 onSend={(note) => {
                                    submit({
                                       type: QnAType.NOTE,
                                       text: note,
                                    });
                                    setOpenNotes(false);
                                 }}
                                 width={width}
                              />
                           </ButtonBasedPopup>
                        </Grid>
                     </Grid>
                     <Grid item container xs={6} justifyContent='flex-end' alignItems='center' wrap='nowrap'>
                        <Grid>
                           <Grid item>
                              <ButtonBasedPopup
                                 anchor={{
                                    vertical: -25,
                                    horizontal: 'left',
                                 }}
                                 transfer={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                 }}
                                 isDisabled={isNotAnswerable || hasAuthority === false}
                                 button={
                                    <IconButton
                                       disabled={isNotAnswerable || hasAuthority === false}
                                       style={{
                                          marginLeft: -5,
                                          marginRight: -5,
                                       }}
                                    >
                                       <SentimentSatisfiedOutlinedIcon
                                          style={{
                                             opacity: isNotAnswerable || hasAuthority === false ? 0.5 : undefined,
                                             transition: 'all .4s',
                                          }}
                                          className={classes.iconColor}
                                       />
                                    </IconButton>
                                 }
                                 open={openEmoji}
                                 onOpen={() => setOpenEmoji(true)}
                                 onClose={() => setOpenEmoji(false)}
                                 elevation={12}
                              >
                                 <Grid style={{ paddingBottom: 0 }}>
                                    <Picker onSelect={addEmoji} style={{ zIndex: 9999 }} set='google' />
                                 </Grid>
                              </ButtonBasedPopup>
                           </Grid>
                        </Grid>
                        <Grid item>
                           {contentState.operation === 'reject' ? (
                              <ButtonCancel
                                 onClick={() => {
                                    submit({
                                       type: QnAType.QNA,
                                       text: state.text,
                                    });
                                    setState((prevState) => ({
                                       ...prevState,
                                       text: '',
                                       showEmoji: false,
                                    }));
                                 }}
                                 disabled={submitDisabledStatus}
                                 label={
                                    width < 730
                                       ? ''
                                       : isInstagramOrFacebook
                                       ? t?.comp_button_delete
                                       : t['page_chat_typo_reject']
                                 }
                                 fontSize='1rem'
                                 fontWeight='bold'
                                 height={36}
                                 style={{ borderRadius: 10, width: '100%' }}
                                 endIcon={
                                    buttonLoading ? (
                                       <CircularProgress style={{ marginRight: 5 }} size={20} color='inherit' />
                                    ) : (
                                       <SendIcon style={{ marginLeft: 5, marginRight: 5 }} />
                                    )
                                 }
                              />
                           ) : (
                              <ButtonOK
                                 onClick={() => {
                                    submit({
                                       type: QnAType.QNA,
                                       text: state.text,
                                    });
                                    setState((prevState) => ({
                                       ...prevState,
                                       text: '',
                                       showEmoji: false,
                                    }));
                                 }}
                                 disabled={submitDisabledStatus || hasAuthority === false}
                                 label={width < 730 ? '' : t['page_chat_typo_send']}
                                 fontSize='1rem'
                                 fontWeight='bold'
                                 height={36}
                                 style={{ borderRadius: 10, width: '100%' }}
                                 endIcon={
                                    buttonLoading ? (
                                       <CircularProgress size={20} color='inherit' style={{ marginRight: 5 }} />
                                    ) : (
                                       <SendIcon style={{ marginLeft: 5, marginRight: 5 }} />
                                    )
                                 }
                              />
                           )}
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </Fragment>
         )}
      </div>
   );
};

export default ChatInput;

import React, { useState, useEffect } from 'react';
import LinkifyIt from 'linkify-it';
import { useLazyQuery } from '@apollo/client';
import { GET_URL_INFO } from '@queries/Chat/Service/GraphQL/Message/query';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import noThumbnail from '@assets/images/open-graph-no-image.png';
import ShowMoreText from 'react-show-more-text';

const linkify = new LinkifyIt();

const useStyles = makeStyles({
   cardContainer: {
      display: 'flex',
      marginTop: 10,
      gap: 10,
      flexWrap: 'wrap',
   },
   card: {
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'column',
      width: '100%',
      maxWidth: 280,
      borderRadius: 10,
      overflow: 'hidden',
   },
   cardMedia: {
      objectFit: 'cover',
      width: '100%',
      height: 200,
   },
   noThumbnailContainer: {
      width: '100%',
      height: 200,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255,255,255,0.4)',
   },
   noThumbnailIcon: {
      fontSize: 100,
      color: '#444',
   },
   titleAndFooterContainer: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 10,
      backgroundColor: 'rgba(255,255,255,0.9)',
   },
   description: {
      display: '-webkit-box',
      overflow: 'hidden',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 1,
   },
});

const LinkPreview = ({ message }) => {
   const classes = useStyles();
   const [previewData, setPreviewData] = useState(null);

   const [getURLData] = useLazyQuery(GET_URL_INFO, {
      fetchPolicy: 'cache-first',
   });

   useEffect(() => {
      if (message && linkify.test(message)) {
         const links = linkify.match(message);
         if (links && Array.isArray(links)) {
            const urls = links.map((link) => link.url).join(',');
            getURLData({ variables: { url: urls } }).then(({ data: res }) => {
               if (res?.getUrlInfo?.data) {
                  const data = res.getUrlInfo.data.reduce((results, item) => {
                     if (!item || (!item.ogImage?.url && !item.ogTitle && !item.ogDescription)) {
                        return results;
                     }
                     results.push(item);
                     return results;
                  }, []);
                  setPreviewData(data);
               }
            });
         }
      } else {
         setPreviewData(null);
      }
   }, [message]);

   if (!previewData) return null;

   return (
      <div className={classes.cardContainer}>
         {previewData.map((preview, index) => {
            return (
               <div key={`${preview.requestUrl}-${index.toString()}`} className={classes.card}>
                  {preview.ogImage && preview.ogImage.url && linkify.test(preview.ogImage.url) && (
                     <img
                        src={preview.ogImage.url}
                        className={classes.cardMedia}
                        onError={(e) => (e.target.src = noThumbnail)}
                        alt={preview.ogTitle}
                     />
                  )}
                  <div className={classes.titleAndFooterContainer}>
                     {preview.ogTitle && (
                        <ShowMoreText lines={4} more={'>>'} less={'<<'} width={0} truncatedEndingComponent={' '}>
                           {preview.ogTitle}
                        </ShowMoreText>
                     )}
                     {preview.ogDescription && (
                        <Typography variant='body2' className={classes.description}>
                           {preview.ogDescription}
                        </Typography>
                     )}
                  </div>
               </div>
            );
         })}
      </div>
   );
};

export default LinkPreview;

import { useMutation } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { Box, ClickAwayListener, Grid, Menu, MenuItem, TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import {
   ContactFieldsToSave,
   FlowBlockType,
   IFlowBlockField,
   IFlowBlockFieldTimeoutUnits,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import DataObjectIcon from '@mui/icons-material/DataObject';
import { ADD_FLOW_BLOCK_MUTATION, UPDATE_ASK_QUESTION_BLOCK_MUTATION } from '@queries/Automation/mutation';
import { Picker } from 'emoji-mart';
import React, { useContext, useEffect, useRef, useState } from 'react';
import SearchSelect from 'react-select';
import { Node } from 'reactflow';

import { RootStateOrAny, useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
   textArea: {
      backgroundColor: '#F7F7FA',
      borderRadius: '5px',
      border: '1px solid #E7E7E7',
      outline: 'none',
      resize: 'none',
      width: '100%',
      minHeight: '80px',
      fontSize: '1rem',
      overflow: 'hidden',
      padding: '10px',
      marginBottom: '7px',
      '&:focus': {
         backgroundColor: '#fff',
         border: '2px solid #147CFC',
      },
   },
   textAreaError: {
      backgroundColor: '#F7F7FA',
      borderRadius: '5px',
      border: '1px solid #FF7474',
      outline: 'none',
      resize: 'none',
      width: '100%',
      minHeight: '80px',
      fontSize: '1rem',
      overflow: 'hidden',
      padding: '10px',
      marginBottom: '7px',
      '&:focus': {
         backgroundColor: '#fff',
         border: '2px solid #FF7474',
      },
   },
   item: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
   },
   btn: {
      height: 36,
      width: '100%',
      border: '1px dashed #157AFA',
      borderRadius: '10px',
      background: 'transparent',
      cursor: 'pointer',
      marginTop: '5px',
   },
   container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      fontSize: '1rem',
      position: 'relative',
      marginBottom: '5px',
   },
   blocks: {
      border: '1px solid #E7E7E7',
      display: 'grid',
      gridTemplateColumns: '10fr 1fr',
      padding: '10px',
      marginBottom: '10px',
      borderRadius: '10px',
   },
   handle: {
      backgroundColor: '#fff',
      marginTop: '5px',
   },
   miniBox: {
      position: 'absolute',
      bottom: -13,
      right: 2,
      width: '26%',
      borderRadius: 7,
      float: 'right',
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: '1px',
      color: '#fff',
      backgroundColor: 'black',
      padding: '2px 4px',
      cursor: 'pointer',
   },
   firstInputContainer: {
      marginTop: '10px',
   },
   textInput: {
      marginTop: '16px',
      height: '50px',
      width: '80%',
   },
   textInputSave: {
      marginBottom: '10px',
      height: '50px',
      width: '100%',
   },
}));

const customStyles = {
   control: (base: any) => ({
      ...base,
      height: 44,
   }),
};

interface AskQuestionBlocksProps {
   leftNodes: Node[];
   contentNodes: Node[];
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   setIsBtnDisable: React.Dispatch<React.SetStateAction<boolean>>;
   isLoading: boolean;
   setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
   isSaveAskQuestion: boolean;
   setIsSaveAskQuestion: React.Dispatch<React.SetStateAction<boolean>>;
   clickedNodeId: string;
}

const AskQuestionBlocks: React.FC<AskQuestionBlocksProps> = ({
   leftNodes,
   setIsBtnDisable,
   isSaveAskQuestion,
   setIsSaveAskQuestion,
   clickedNodeId,
   setContentNodes,
   contentNodes,
   setLeftNodes,
   isLoading,
   setIsLoading,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const OPTIONS = [
      {
         value: IFlowBlockFieldTypes.Text,
         label: t.atomation_flow_text,
      },
      {
         value: IFlowBlockFieldTypes.Number,
         label: t.atomation_flow_number,
      },
      {
         value: IFlowBlockFieldTypes.Email,
         label: t.atomation_flow_email,
      },
      {
         value: IFlowBlockFieldTypes.Phone,
         label: t.atomation_flow_phone,
      },
      {
         value: IFlowBlockFieldTypes.Date,
         label: t.atomation_flow_date,
      },
      {
         value: IFlowBlockFieldTypes.Url,
         label: t.atomation_flow_url,
      },
      {
         value: IFlowBlockFieldTypes.List,
         label: t.atomation_flow_list,
      },
   ];
   const [OPTIONS2, SET_OPTIONS2] = useState<{ label: string; value: ContactFieldsToSave | string }[]>([
      {
         label: t.atomation_flow_first_name,
         value: ContactFieldsToSave.FirstName,
      },
      {
         label: t.atomation_flow_last_name,
         value: ContactFieldsToSave.LastName,
      },
      {
         label: t.atomation_flow_email,
         value: ContactFieldsToSave.Email,
      },
      {
         label: t.atomation_flow_phone,
         value: ContactFieldsToSave.Phone,
      },
   ]);
   const OPTIONS3 = [
      {
         value: IFlowBlockFieldTimeoutUnits.Seconds,
         label: t.atomation_flow_seconds,
      },
      {
         value: IFlowBlockFieldTimeoutUnits.Minutes,
         label: t.atomation_flow_minutes,
      },
      {
         value: IFlowBlockFieldTimeoutUnits.Hours,
         label: t.atomation_flow_hours,
      },
   ];
   const [wordCounts, setWordCounts] = useState<{ [key: string]: number }>({});
   const [activeTextareaId, setActiveTextareaId] = useState<string | null>(null);
   const [showEmojiPicker, setShowEmojiPicker] = useState(false);
   const [menuId, setMenuId] = useState<string>('');
   const [anchorElField, setAnchorElField] = useState<null | HTMLElement>(null);
   const [toUpdate, setToUpdate] = useState<{ _id: string; fields: string }[]>([]);
   const [timeUnit, seTimeUnit] = useState<{ _id: string; fields: string }[]>([]);
   const [timeValue, setTimeValue] = useState<{ _id: string; fields: string }[]>([]);
   const [saveResponse, seTSaveResponse] = useState<{ _id: string; fields: string }[]>([]);
   const [questionType, setQuestionType] = useState<{ _id: string; fields: string }[]>([]);
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);
   const LIST_BLOCK_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listBlockTemplates);
   const CUSTOM_FIELDS = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.customFields);
   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   const [customFields, setCustomFields] = useState<{ name: string; label: string }[]>([]);

   useEffect(() => {
      setCustomFields(CUSTOM_FIELDS);
      CUSTOM_FIELDS.map((item: { name: string; label: string }) => {
         SET_OPTIONS2((prev) => [...prev, { label: item.name, value: item.label }]);
      });
   }, [CUSTOM_FIELDS]);

   // Add block mutation
   const [addBlock] = useMutation(ADD_FLOW_BLOCK_MUTATION);
   const handleAddFlowBlock = async (flowBlockTemplateId: string, nodeId: string, field: IFlowBlockField[]) => {
      try {
         await addBlock({
            variables: {
               input: {
                  flowBlockTemplateId: flowBlockTemplateId,
                  targetNode: nodeId,
                  fields: field,
               },
            },

            onCompleted: (res) => {
               const data = res.addFlowBlock.data.flowBlocks;
               const response = data[data.length - 1];
               const updatedBlockState = [...blockState];
               updatedBlockState[updatedBlockState.length - 1]._id = response._id;

               setBlockState(updatedBlockState);

               // Add the new flowBlock to our state
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: true,
                              flowBlocks: [response],
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));

               setIsLoading(false);
            },
         });
      } catch (error) {
         console.error('Error adding block:', error);
      }
   };

   const [updateAskQuestionBlock] = useMutation(UPDATE_ASK_QUESTION_BLOCK_MUTATION);
   const handleUpdateFlowBlockInput = async (blockId: string, input: string) => {
      try {
         await updateAskQuestionBlock({
            variables: {
               input: {
                  _id: blockId,
                  fieldIndex: 0,
                  fieldName: 'value',
                  fieldValue: input,
               },
            },
            onCompleted: (res) => {
               const response = res.updateBlockField.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some(
                                 (block) => block?.fields[0]?.value && block?.fields[0]?.value?.length > 0,
                              )
                                 ? true
                                 : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));

               setIsLoading(false);
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };
   const handleUpdateFlowBlockTime = async (blockId: string, input: string) => {
      try {
         await updateAskQuestionBlock({
            variables: {
               input: {
                  _id: blockId,
                  fieldIndex: 0,
                  fieldName: 'question_answer_timeout',
                  fieldValue: parseInt(input, 10),
               },
            },
            onCompleted: (res) => {
               setIsLoading(false);
               const response = res.updateBlockField.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some(
                                 (block) => block?.fields[0]?.value && block?.fields[0]?.value?.length > 0,
                              )
                                 ? true
                                 : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };
   const handleUpdateFlowBlockSaveResponse = async (blockId: string, input: string) => {
      try {
         await updateAskQuestionBlock({
            variables: {
               input: {
                  _id: blockId,
                  fieldIndex: 0,
                  fieldName: 'save_response_to_contact_field',
                  fieldValue: input,
               },
            },
            onCompleted: (res) => {
               setIsLoading(false);
               const response = res.updateBlockField.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some(
                                 (block) => block?.fields[0]?.value && block?.fields[0]?.value?.length > 0,
                              )
                                 ? true
                                 : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };
   const handleUpdateFlowBlockQuestionType = async (blockId: string, input: string) => {
      try {
         await updateAskQuestionBlock({
            variables: {
               input: {
                  _id: blockId,
                  fieldIndex: 0,
                  fieldName: 'type',
                  fieldValue: input,
               },
            },
            onCompleted: (res) => {
               setIsLoading(false);
               const response = res.updateBlockField.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some(
                                 (block) => block?.fields[0]?.value && block?.fields[0]?.value?.length > 0,
                              )
                                 ? true
                                 : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };
   const handleUpdateFlowBlockUnit = async (blockId: string, input: string) => {
      try {
         await updateAskQuestionBlock({
            variables: {
               input: {
                  _id: blockId,
                  fieldIndex: 0,
                  fieldName: 'question_answer_timeout_unit',
                  fieldValue: input,
               },
            },
            onCompleted: (res) => {
               setIsLoading(false);
               const response = res.updateBlockField.data;
               const updateNodes = (prevNodes: Node[]) =>
                  prevNodes.map((node) => {
                     if (node.id === clickedNodeId) {
                        // Update the flowBlocks fields with the new value
                        return {
                           ...node,
                           data: {
                              ...node.data,
                              clickedNodeId: clickedId,
                              isValidNode: blockState.some(
                                 (block) => block?.fields[0]?.value && block?.fields[0]?.value?.length > 0,
                              )
                                 ? true
                                 : false,
                              flowBlocks: node.data.flowBlocks.map((block: IFlowBlockTemplate) => {
                                 if (block._id === blockId) {
                                    return { ...block, fields: response?.fields };
                                 }
                                 return block;
                              }),
                           },
                        };
                     }
                     return node;
                  });
               setContentNodes((prev) => updateNodes(prev));
               setLeftNodes((prev) => updateNodes(prev));
            },
         });
      } catch (error) {
         console.error('Error updating block:', error);
      }
   };

   const calculateWordCount = (id: string, text: string) => {
      const wordCount = text.length;
      setWordCounts((prevWordCounts) => ({
         ...prevWordCounts,
         [id]: 1500 - wordCount,
      }));
   };

   const handleTimeValueChange = (id: string, e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setIsBtnDisable(false);
      const parsedValue = parseInt(e.target.value, 10);

      setTimeValue([{ _id: id, fields: e.target.value }]);

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     question_answer_timeout: parsedValue,
                  })),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };
   const handleTimeUnitChange = (id: string, label: string) => {
      setIsBtnDisable(false);
      seTimeUnit([{ _id: id, fields: label }]);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     question_answer_timeout_unit: label,
                  })),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };
   const handlesaveResponseChange = (id: string, label: ContactFieldsToSave | string) => {
      setIsBtnDisable(false);
      seTSaveResponse([{ _id: id, fields: label }]);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     save_response_to_contact_field: label,
                  })),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };
   const handleQuestionTypeChange = (id: string, label: IFlowBlockFieldTypes) => {
      setIsBtnDisable(false);
      setQuestionType([{ _id: id, fields: label }]);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     type: label,
                  })),
               };
            }
            return item;
         });
         return updatedBlockState;
      });
   };

   const backendBlocks = contentNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];

   // default
   useEffect(() => {
      //setBlockState([]);
      if (backendBlocks?.length > 0) {
         const data = backendBlocks.map((item: IFlowBlockTemplate) => {
            const value = item.fields[0]?.value;
            if (value) {
               calculateWordCount(item._id, value);
            }
            return {
               _id: item._id,
               title: item.title,
               fields: item.fields,
               type: item.type,
            };
         });
         setBlockState(data);
      } else {
         const field = {
            type: IFlowBlockFieldTypes.Text,
            value: '',
            save_response_to_contact_field: ContactFieldsToSave.DontSave,
            question_answer_timeout: 1,
            question_answer_timeout_unit: 'minutes',
            target_on_reply: undefined,
            target_on_not_reply: undefined,
            target_on_invalid_input: undefined,
         };

         const newItem = [
            {
               _id: Date.now().toString(),
               title: '',
               fields: [field],
               type: FlowBlockType.Question,
            },
         ];

         if (backendBlocks && backendBlocks.length <= 0) {
            setBlockState(newItem);
         }
      }
   }, [clickedNodeId]);

   //save
   useEffect(() => {
      if (isSaveAskQuestion && !isLoading) {
         setIsBtnDisable(false);
         //setAskQuestionState(blockState);
         const newBlocks = blockState.filter(
            (block) => !backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );
         const existingBlocks = blockState.filter((block) =>
            backendBlocks.some((backendBlock: { _id: string }) => backendBlock._id === block._id),
         );

         if (backendBlocks.length <= 0) {
            (async () => {
               if (LIST_BLOCK_TEMPLATE) {
                  const templateBlockId = LIST_BLOCK_TEMPLATE.find(
                     (item: { type: FlowBlockType }) => item.type === FlowBlockType.Question,
                  )?._id;

                  newBlocks.forEach((item) => {
                     setIsLoading(true);
                     handleAddFlowBlock(templateBlockId, clickedNodeId, item.fields);
                  });
               }
            })();
         } else {
            if (toUpdate.length > 0) {
               toUpdate.forEach((item) => {
                  setIsLoading(true);
                  // Check if the item's _id exists in existingBlocks
                  if (existingBlocks.some((block) => block._id === item._id)) {
                     handleUpdateFlowBlockInput(item._id, item.fields);
                  }
               });
            }

            if (timeUnit.length > 0) {
               timeUnit.forEach((item) => {
                  setIsLoading(true);
                  // Check if the item's _id exists in existingBlocks
                  if (existingBlocks.some((block) => block._id === item._id)) {
                     handleUpdateFlowBlockUnit(item._id, item.fields);
                  }
               });
            }

            if (timeValue.length > 0) {
               timeValue.forEach((item) => {
                  setIsLoading(true);
                  // Check if the item's _id exists in existingBlocks
                  if (existingBlocks.some((block) => block._id === item._id)) {
                     handleUpdateFlowBlockTime(item._id, item.fields);
                  }
               });
            }

            if (saveResponse.length > 0) {
               saveResponse.forEach((item) => {
                  setIsLoading(true);
                  // Check if the item's _id exists in existingBlocks
                  if (existingBlocks.some((block) => block._id === item._id)) {
                     handleUpdateFlowBlockSaveResponse(item._id, item.fields);
                  }
               });
            }
            if (questionType.length > 0) {
               questionType.forEach((item) => {
                  setIsLoading(true);
                  // Check if the item's _id exists in existingBlocks
                  if (existingBlocks.some((block) => block._id === item._id)) {
                     handleUpdateFlowBlockQuestionType(item._id, item.fields);
                  }
               });
            }
         }
         // Clear the state after the updates
         setToUpdate([]);
         setTimeValue([]);
         seTimeUnit([]);
         seTSaveResponse([]);
         setQuestionType([]);
         setIsSaveAskQuestion(false);
      }
   }, [isSaveAskQuestion]);

   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         let numRows = 0;
         let consecutiveChars = 0;

         for (let i = 0; i < content.length; i++) {
            if (content[i] !== '\n') {
               consecutiveChars++;
               if (consecutiveChars === 36) {
                  numRows++;
                  consecutiveChars = 0;
               }
            } else {
               consecutiveChars = 0;
            }
         }

         const enters = (content.match(/\n/g) || []).length;

         numRows += enters;

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: numRows + 2, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };

   const handleBlockInputChange = (id: string, text: string) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               // Update the value property of fields with the new text
               return {
                  ...item,
                  fields: item.fields.map((field) => ({
                     ...field,
                     value: text,
                  })),
               };
            }
            return item;
         });

         if (text.length > 0) {
            setIsBtnDisable(false);
         } else {
            setIsBtnDisable(true);
         }
         calculateWordCount(id, text);
         return updatedBlockState;
      });

      setToUpdate([{ _id: id, fields: text }]);
   };

   const divRef = useRef(null);
   const handleMenuFieldOpen = (id: string) => {
      setAnchorElField(divRef.current);
      setMenuId(id);
   };

   const handleMenuFieldClose = () => {
      setAnchorElField(null);
   };
   const addEmoji = (e: any, id: string) => {
      const emoji = e.native;

      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               const updatedFields = item.fields.map((field) => {
                  const textAreaValue = field.value || '';
                  const textAreaElement = document.getElementById(`textarea-${id}`) as HTMLTextAreaElement | null;

                  if (textAreaElement) {
                     const start = textAreaElement.selectionStart;
                     const end = textAreaElement.selectionEnd;
                     const updatedValue = textAreaValue.substring(0, start) + emoji + textAreaValue.substring(end);
                     calculateWordCount(id, updatedValue);
                     setToUpdate([{ _id: id, fields: updatedValue }]);
                     return { ...field, value: updatedValue };
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });
         setIsBtnDisable(false);
         return updatedBlockState;
      });
      setShowEmojiPicker(false);
   };

   const addField = (name: string, id: string) => {
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === id) {
               const updatedFields = item.fields.map((field) => {
                  const textAreaValue = field.value || '';
                  const textAreaElement = document.getElementById(`textarea-${id}`) as HTMLTextAreaElement | null;

                  if (textAreaElement) {
                     const start = textAreaElement.selectionStart;
                     const end = textAreaElement.selectionEnd;
                     const updatedValue = textAreaValue.substring(0, start) + name + textAreaValue.substring(end);
                     calculateWordCount(id, updatedValue);
                     setToUpdate([{ _id: id, fields: updatedValue }]);
                     return { ...field, value: updatedValue };
                  }

                  return field;
               });
               return { ...item, fields: updatedFields };
            } else {
               return item;
            }
         });
         setIsBtnDisable(false);
         return updatedBlockState;
      });

      handleMenuFieldClose();
   };

   function hasNegativeValue(obj: { [x: string]: number }) {
      for (let key in obj) {
         if (Object.prototype.hasOwnProperty.call(obj, key)) {
            if (obj[key] < 0) {
               return true;
            }
         }
      }
      return false;
   }

   useEffect(() => {
      const isInvalid = blockState.some((block) => block.fields.some((field) => field.value === ''));
      if (isInvalid || hasNegativeValue(wordCounts)) {
         setIsBtnDisable(true);
      }
   }, [blockState]);

   function getNameByLabel(label: string | undefined) {
      const lowercasedLabel = label?.toLowerCase();
      const matchingProperty = customFields.find((property) => property.label.toLowerCase() === lowercasedLabel);
      return matchingProperty ? matchingProperty.name : null;
   }

   return (
      <div>
         <Grid>
            {blockState.map((item) => (
               <Grid key={item._id}>
                  {item.fields.map((field) => (
                     <Grid>
                        <Grid>
                           <Grid className={classes.firstInputContainer}>
                              <Grid style={{ fontSize: '1rem', marginLeft: '1px' }}>
                                 {t.atomation_flow_question_type}
                              </Grid>
                              <Box>
                                 <SearchSelect
                                    control
                                    name='question_type'
                                    options={OPTIONS}
                                    className={classes.textInputSave}
                                    menuPosition='fixed'
                                    styles={customStyles}
                                    defaultValue={
                                       field.type
                                          ? {
                                               label:
                                                  field.type.toString() === 'text'
                                                     ? t.atomation_flow_text
                                                     : field.type.toString() === 'number'
                                                     ? t.atomation_flow_number
                                                     : field.type.toString() === 'email'
                                                     ? t.atomation_flow_email
                                                     : field.type.toString() === 'phone'
                                                     ? t.atomation_flow_phone
                                                     : field.type.toString() === 'list'
                                                     ? t.atomation_flow_list
                                                     : field.type.toString() === 'date'
                                                     ? t.atomation_flow_date
                                                     : field.type.toString() === 'url'
                                                     ? t.atomation_flow_url
                                                     : '',
                                               value: field.type,
                                            }
                                          : null
                                    }
                                    onChange={(e) => {
                                       if (e) {
                                          handleQuestionTypeChange(item._id, e.value);
                                       }
                                    }}
                                 />
                              </Box>
                           </Grid>

                           <ClickAwayListener onClickAway={() => setActiveTextareaId(null)}>
                              <Box style={{ position: 'relative' }}>
                                 <Typography> {t.atomation_flow_question}</Typography>
                                 <textarea
                                    id={`textarea-${item._id}`}
                                    key={item._id}
                                    value={field.value}
                                    className={
                                       field.value && field.value?.length > 1500
                                          ? classes.textAreaError
                                          : classes.textArea
                                    }
                                    name='rawText'
                                    placeholder={t.atomation_flow_enter_a_question}
                                    onFocus={() => setActiveTextareaId(item._id)}
                                    onClick={() => setActiveTextareaId(item._id)}
                                    onChange={(event) => {
                                       handleBlockInputChange(item._id, event.target.value);
                                    }}
                                    {...calculateTextareaSize(field.value)}
                                 />

                                 {activeTextareaId && (
                                    <div style={{ position: 'sticky', top: 0, width: '100%' }} ref={divRef}>
                                       <div className={classes.miniBox}>
                                          <div onClick={(event) => handleMenuFieldOpen(item._id)}>
                                             <DataObjectIcon
                                                style={{
                                                   display: 'flex',
                                                   justifyContent: 'center',
                                                   fontSize: '18px',
                                                   marginTop: '2px',
                                                }}
                                             />
                                          </div>
                                          <EmojiEmotionsIcon
                                             onClick={() => {
                                                setShowEmojiPicker(true);
                                                setActiveTextareaId(item._id);
                                             }}
                                             style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                fontSize: '18px',
                                                marginTop: '2px',
                                             }}
                                          />
                                          <span
                                             style={{
                                                color: 'green',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                fontSize: '14px',
                                                marginTop: '2px',
                                             }}
                                          >
                                             {wordCounts[item._id] === 0 ? 0 : wordCounts[item._id] || 1500}
                                          </span>
                                       </div>
                                    </div>
                                 )}
                                 {showEmojiPicker && activeTextareaId === item._id && (
                                    <ClickAwayListener onClickAway={() => setShowEmojiPicker(false)}>
                                       <div style={{ zIndex: '9999', position: 'sticky' }}>
                                          <Picker
                                             onSelect={(e) => addEmoji(e, item._id)}
                                             style={{ width: '100%' }}
                                             title={t['emoji_selector_title']}
                                          />
                                       </div>
                                    </ClickAwayListener>
                                 )}

                                 <Menu
                                    anchorEl={anchorElField}
                                    open={Boolean(anchorElField)}
                                    onClose={handleMenuFieldClose}
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                                    style={{ marginTop: 20 }}
                                    PaperProps={{
                                       style: {
                                          width: '363px',
                                          borderRadius: '5px',
                                          maxHeight: '250px',
                                       },
                                    }}
                                 >
                                    <MenuItem onClick={() => addField(t.atomation_flow_first_name_value, menuId)}>
                                       {t.atomation_flow_first_name}
                                    </MenuItem>
                                    <MenuItem onClick={() => addField(t.atomation_flow_last_name_value, menuId)}>
                                       {t.atomation_flow_last_name}
                                    </MenuItem>
                                    <MenuItem onClick={() => addField(t.atomation_full_name_value, menuId)}>
                                       {t.atomation_full_name}
                                    </MenuItem>
                                    <MenuItem onClick={() => addField(t.atomation_flow_email_value, menuId)}>
                                       {t.atomation_flow_email}
                                    </MenuItem>
                                    <MenuItem onClick={() => addField(t.atomation_flow_phone_value, menuId)}>
                                       {t.atomation_flow_phone}
                                    </MenuItem>
                                    {customFields.map((custom) => (
                                       <MenuItem onClick={() => addField('{{' + custom.name + '}}', menuId)}>
                                          {custom.name}
                                       </MenuItem>
                                    ))}
                                 </Menu>
                              </Box>
                           </ClickAwayListener>
                        </Grid>
                     </Grid>
                  ))}
               </Grid>
            ))}
         </Grid>

         {blockState.map((item) => (
            <Grid key={item._id}>
               {item.fields.map((field) => (
                  <Grid>
                     <Grid>
                        <Grid style={{ fontSize: '1rem', marginLeft: '1px' }}>{t.atomation_flow_save_response}</Grid>
                        <Box
                           key={getNameByLabel(
                              field.save_response_to_contact_field && field.save_response_to_contact_field.toString(),
                           )}
                        >
                           <SearchSelect
                              control
                              name='save_response'
                              options={OPTIONS2}
                              className={classes.textInputSave}
                              menuPosition='fixed'
                              placeholder={t.atomation_flow_select}
                              isClearable={true}
                              styles={customStyles}
                              defaultValue={
                                 field.save_response_to_contact_field &&
                                 field.save_response_to_contact_field.toString() !== 'dont_save'
                                    ? {
                                         label: (() => {
                                            const fieldValue = field.save_response_to_contact_field.toString();
                                            return fieldValue === 'name'
                                               ? t.atomation_flow_first_name
                                               : fieldValue === 'surname'
                                               ? t.atomation_flow_last_name
                                               : fieldValue === 'email'
                                               ? t.atomation_flow_email
                                               : fieldValue === 'phone'
                                               ? t.atomation_flow_phone
                                               : getNameByLabel(fieldValue);
                                         })(),
                                         value: field.save_response_to_contact_field.toString(),
                                      }
                                    : null
                              }
                              onChange={(e) => {
                                 if (e) {
                                    handlesaveResponseChange(item._id, e.value);
                                 } else {
                                    handlesaveResponseChange(item._id, ContactFieldsToSave.DontSave);
                                 }
                              }}
                           />
                        </Box>
                     </Grid>
                     <Grid>
                        <Grid className={classes.blocks} key={item._id}>
                           <div>
                              <Typography>{t.atomation_flow_action_on_reply}</Typography>
                              <Typography style={{ fontSize: '0.8rem' }}>
                                 {t.atomation_flow_take_action_on_successful_reply}
                              </Typography>
                           </div>
                           <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <CircleOutlinedIcon
                                 className={classes.handle}
                                 style={{
                                    color: '#147CFC',
                                    width: '20px',
                                    height: '20px',
                                 }}
                              />
                           </div>
                        </Grid>
                        <Grid className={classes.blocks} key={item._id}>
                           <div>
                              <Typography>{t.atomation_flow_action_if_not_reply}</Typography>
                              <Typography style={{ fontSize: '0.8rem' }}>
                                 {t.atomation_flow_take_action_on_not_reply}
                              </Typography>
                           </div>
                           <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <CircleOutlinedIcon
                                 className={classes.handle}
                                 style={{
                                    color: '#FF5A80',
                                    width: '20px',
                                    height: '20px',
                                 }}
                              />
                           </div>
                        </Grid>
                        <Grid className={classes.blocks} key={item._id}>
                           <div>
                              <Typography>{t.atomation_flow_action_if_invalid_input}</Typography>
                              <Typography style={{ fontSize: '0.8rem' }}>
                                 {t.atomation_flow_take_action_on_invalid_reply}
                              </Typography>
                           </div>
                           <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <CircleOutlinedIcon
                                 className={classes.handle}
                                 style={{
                                    color: '#FEBC52',
                                    width: '20px',
                                    height: '20px',
                                 }}
                              />
                           </div>
                        </Grid>
                     </Grid>
                     <Grid>
                        <Grid style={{ fontSize: '1rem', marginLeft: '3px', marginBottom: '-0.8rem' }}>
                           {t.atomation_flow_when_response_time_out}
                        </Grid>
                        <Box
                           style={{
                              display: 'flex',
                              gap: '0.5rem',
                              width: '100%',
                           }}
                        >
                           <TextField
                              name='timeNumber'
                              placeholder='0'
                              defaultValue={field.question_answer_timeout}
                              variant='outlined'
                              style={{ width: '20%' }}
                              onChange={(e) => {
                                 handleTimeValueChange(item._id, e);
                              }}
                           />

                           <SearchSelect
                              options={OPTIONS3}
                              className={classes.textInput}
                              menuPosition='fixed'
                              styles={customStyles}
                              defaultValue={
                                 field.question_answer_timeout_unit
                                    ? {
                                         label:
                                            field.question_answer_timeout_unit === 'minutes'
                                               ? t.atomation_flow_minutes
                                               : field.question_answer_timeout_unit === 'hours'
                                               ? t.atomation_flow_hours
                                               : field.question_answer_timeout_unit === 'seconds'
                                               ? t.atomation_flow_seconds
                                               : '',
                                         value: field.question_answer_timeout_unit,
                                      }
                                    : null
                              }
                              onChange={(e) => {
                                 if (e) {
                                    handleTimeUnitChange(item._id, e.value);
                                 }
                              }}
                           />
                        </Box>
                     </Grid>
                  </Grid>
               ))}
            </Grid>
         ))}
      </div>
   );
};

export default AskQuestionBlocks;

import { LanguageContext } from '@helper/locale/langContext';
import { CircularProgress, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { ActiveTab } from './rightBar';

export interface FolderTabsProps {
    isSending: boolean;
    disabled: boolean;
    activeTab: ActiveTab;
    onTabChange: (tab: ActiveTab) => void;
}

const useStyles = makeStyles((theme: any) => ({
    container: {
        width: '100%',
        height: '100%',
        backgroundColor: '#ffffff',
    },
    tab: {
        width: '88%',
        textAlign: 'center',
        backgroundColor: '#efefef',
        color: '#ababab',
        borderRadius: '7px',
        userSelect: 'none',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        height: 30,
        marginLeft: 10,
        marginRight: 10,
        paddingTop: 5,
        fontWeight: 600,

    },
    tabHolder: {
        marginTop: 10,
        marginBottom: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    loader: {
        marginLeft: 10,
        marginTop: 3,
    },
}));

const FolderTabs = ({ disabled, isSending, onTabChange, activeTab }: FolderTabsProps) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;

    let activeStyle = {
        backgroundColor: '#137cfc',
        color: '#ffff',
    };

    return (
        <div className={classes.tabHolder}>
            <div
                onClick={disabled ? undefined : () => onTabChange(ActiveTab.SelectedFolders)}
                className={classes.tab}
                style={
                    activeTab === ActiveTab.SelectedFolders
                        ? activeStyle
                        : {
                            cursor: disabled ? 'not-allowed' : undefined,
                            backgroundColor: disabled ? "#ababab" : undefined,
                            color: disabled ? "#fff" : undefined,
                        }
                }
            >
                {t?.bulk_upload_new}
            </div>
            <div
                onClick={() => onTabChange(ActiveTab.SendingFolders)}
                className={classes.tab}
                style={activeTab === ActiveTab.SendingFolders ? activeStyle : {}}
            >
                {t?.bulk_upload_sending}{' '}
                {disabled && (
                    <div className={classes.loader}>
                        <CircularProgress size={15} color='inherit' />
                    </div>
                )}
            </div>
            <div
                onClick={() => onTabChange(ActiveTab.SentFolders)}
                className={classes.tab}
                style={activeTab === ActiveTab.SentFolders ? activeStyle : {}}
            >
                {t?.bulk_upload_sent}
            </div>
        </div>
    );
};

export default FolderTabs;
import SearchBar from '@components/SearchBar';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MenuSettings from './menuSetting';
import React, { useState, useContext, useEffect } from 'react';
import { AutomationIcon } from '@assets/icons/SettingsIcons/settingsIcons';
import { LanguageContext } from '@helper/locale/langContext';

const useStyles = makeStyles((theme) => ({
   content: {
      padding: 16,
      width: 300,
   },
}));

interface FlowProps {
   setFilter: React.Dispatch<React.SetStateAction<string>>;
   setShowEcommerce: React.Dispatch<React.SetStateAction<boolean>>;
}

const LeftContent: React.FC<FlowProps> = ({ setFilter, setShowEcommerce }) => {
   const classes = useStyles();
   const [text, setText] = useState('');

   useEffect(() => {
      setFilter(text);
   }, [text]);

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const AUTOMATION_SETTINGS_MENU = [
      {
         title: t.atomation_flow_bot,
         clickable: true,
         url: 'flow-bot',
         icon: '',
         type: 'flow-bot',
      },
      {
         title: t.automation_e_commerce,
         clickable: true,
         url: 'e-commerce',
         icon: '',
         type: 'e-commerce',
      },
   ];

   return (
      <Grid>
         <Grid className={classes.content}>
            <SearchBar value={text} onChange={setText} placeholder={t.automation_flow_search_bots} fullWidth />
            <MenuSettings menuItems={AUTOMATION_SETTINGS_MENU} setShowEcommerce={setShowEcommerce} />
         </Grid>
      </Grid>
   );
};

export default LeftContent;

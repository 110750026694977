import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { FC, Fragment, useCallback, useContext, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import useWindowResize from '../../../../hooks/useWindowResize';
import { MyTheme } from '@styles/config';
import { QnAType } from '../../util/types';
import QuestionContentItem from './chatItem/contentItem';
import QuestionEventItem from './event';
import QuestionNoteItem from './note';
import { CircularProgress, Grid } from '@material-ui/core';

interface Props {
   clickedItem?: any;
   hasAuthority: boolean | null;
   next: () => any;
   hasMore: boolean;
}

const useStyles = makeStyles<MyTheme, Props>((theme) => ({
   root: {
      padding: theme.spacing(1),
      backgroundColor: '#fff',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      overflowY: 'auto',
      maxWidth: `calc(100% - ${theme.chatApp.questions.drawerWidth}px)`,
   },
}));

const getItems = (item: any, lang: string, hasAuthority: Props['hasAuthority']) => {
   const time = moment(item.createdAt).locale(lang.toLowerCase()).fromNow(true);
   switch (item.type) {
      case QnAType.LOG:
         return (
            <QuestionEventItem
               sender={item?.createdBy}
               time={moment(item.createdAt).locale(lang.toLowerCase()).fromNow()}
               text={item.question || item.answer}
            />
         );

      case QnAType.NOTE:
         return (
            <QuestionNoteItem
               isSending={item.isSending}
               sender={item.sender}
               time={time}
               text={item.question || item.answer}
            />
         );

      case QnAType.QNA:
         return <QuestionContentItem isSending={item?.isSending} hasAuthority={hasAuthority} item={item} />;

      default:
         return null;
   }
};

const QuestionChatItem: FC<Props> = (props) => {
   const classes = useStyles(props);
   const { height } = useWindowResize();
   const {
      lang: { shortTitle },
   }: any = useContext(LanguageContext);
   const messageEl = useRef<HTMLDivElement | null>(null);
   const activeQuestionsMessages =
      useSelector((state: any) => state.questionsState.activeQuestionMessages.messages) || [];
   const totalDocs = useSelector((state: any) => state.questionsState.activeQuestionMessages.totalDocs) || 0;
   const { hasAuthority } = props;

   useEffect(() => {
      if (messageEl.current) {
         messageEl.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
      }
   }, [totalDocs]);

   const handleScroll = (e: MouseEvent) => {
   };

   const Loader = useCallback(() => {
      return (
         <Grid
            container
            alignItems='center'
            style={{ padding: 16 }}
            justifyContent='center'
            xs={12}
         >
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   }, []);

   return (
      <div
         id='scrollableDiv'
         style={{
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column-reverse',
            marginTop: 20,
         }}
         className={classes.root}
      >
         {activeQuestionsMessages && activeQuestionsMessages.length > 0 && (
            <Fragment>
               <div ref={messageEl} />
               <InfiniteScroll
                  dataLength={activeQuestionsMessages?.length || 0}
                  scrollableTarget='scrollableDiv'
                  hasMore={props.hasMore}
                  next={props.next}
                  onScroll={handleScroll}
                  inverse={true}
                  style={{
                     display: 'flex',
                     flexDirection: 'column-reverse',
                     overflowX: 'hidden',
                  }}
                  height={height - 250}
                  loader={<Loader />}
               >
                  {activeQuestionsMessages?.map((item: any) => (
                     <Fragment key={item._id}>{getItems(item, shortTitle, hasAuthority)}</Fragment>
                  ))}
               </InfiniteScroll>
            </Fragment>
         )}
      </div>
   );
};

export default QuestionChatItem;

import { priceFormat } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Done, RecentActors, Storage } from '@material-ui/icons';
import { RenewalType } from '@types/enums';
import React, { useContext } from 'react';

const useStyles = makeStyles((theme) => ({
   root: {
      boxShadow: '0px 3px 6px #00000029',
      background: (props) =>
         props.active ? '#178CF9 0% 0% no-repeat padding-box' : '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: 22,
      minWidth: 230,
      maxWidth: 450,
      fontFamily: 'Helvetica Neue',
   },
   header: {
      padding: '1.2rem .8rem',
      borderBottom: '1px solid #F0F0F0',
      alignItems: 'center',
      minHeight: 80,
   },
   startText: {
      color: (props) => (props.active ? 'white' : '#2D81CE'),
      fontSize: '1.2rem',
      letterSpacing: '0px',
   },
   priceText: {
      fontSize: '1.5rem',
      letterSpacing: '0px',
      color: (props) => (props.active ? 'white' : '#2A6EAD'),
   },
   perText: {
      fontSize: '.65rem',
      color: (props) => (props.active ? 'white' : '#2A6EAD'),
      letterSpacing: '0px',
   },
   featuresContainer: {
      padding: '1rem',
   },
   feature: {
      fontSize: '.75rem',
      color: (props) => (props.active ? 'white' : '#143F67'),
   },
   option: {
      fontSize: '.65rem',
      color: (props) => (props.active ? 'white' : '#3D82C3'),
   },
   btn: {
      background: (props) =>
         props.active ? '#FFFFFF 0% 0% no-repeat padding-box' : '#D6ECFF 0% 0% no-repeat padding-box',
      borderRadius: '13px',
      textTransform: 'capitalize',
      color: '#315276',
      '&:hover': {
         background: (props) => (props.active ? '#ffffffd9' : undefined),
      },
   },
   btnContainer: {
      padding: '1rem 0px',
   },
   visible: {
      visibility: (props) => (props.packagePrice === 'no' ? 'hidden' : undefined),
   },
}));

const Package = (props) => {
   const classes = useStyles(props);
   const { product, chooseHandler, paymentType, isTemp } = props;
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   let package_features = {
      start: 11,
      grow: 10,
      scale: 9,
      special: 7,
   };

   return (
      <Box className={classes.root}>
         <Grid container className={classes.header}>
            <Grid item xs={4}>
               <Typography className={classes.startText}> {product.name} </Typography>
            </Grid>
            <Grid item xs={8}>
               {!isTemp && (
                  <Grid container justifyContent='flex-end' alignItems='center' className={classes.visible}>
                     <Typography className={classes.priceText}>
                        {paymentType === RenewalType.YEARLY
                           ? priceFormat(product.yearlyPrice / 12)
                           : priceFormat(product.monthlyPrice)}
                     </Typography>
                     <Box>
                        <Typography className={classes.perText}> / {t?.user} </Typography>
                        <Typography className={classes.perText}> / {t?.month} </Typography>
                     </Box>
                  </Grid>
               )}
            </Grid>
         </Grid>
         <div className={classes.featuresContainer}>
            <div>
               <div style={{ color: props.active ? 'white' : null, display: 'flex' }}>
                  <RecentActors style={{ fontSize: 21 }} />
                  <Typography style={{ marginTop: 2, marginLeft: 10, fontSize: 12 }}>
                     {t[`package_${product.name.toLowerCase()}_option_1`]}
                  </Typography>
               </div>
               <div
                  style={{
                     color: props.active ? 'white' : null,
                     display: 'flex',
                     marginTop: 10,
                  }}
               >
                  <Storage style={{ fontSize: 21 }} />
                  <Typography style={{ marginTop: 2, marginLeft: 10, fontSize: 12 }}>
                     {t[`package_${product.name.toLowerCase()}_option_3`]}
                  </Typography>
               </div>
            </div>
            <div
               style={{
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  height: 1,
                  width: '100%',
                  background: '#F0F0F0',
               }}
            />
            <div>
               <Typography
                  style={{
                     color: props.active ? 'white' : null,
                     marginBottom: 5,
                  }}
               >
                  {t[`package_title_${product.name.toLowerCase()}`]}
               </Typography>
            </div>
            <div style={{ minHeight: 290, display: 'block' }}>
               {Array(package_features[product.name.toLowerCase()])
                  .fill(product.name.toLowerCase())
                  .map((product_name, idx) => {
                     return (
                        <div
                           style={{
                              color: props.active ? 'white' : 'black',
                              display: 'flex',
                              marginBottom: 5,
                           }}
                        >
                           <Done style={{ fontSize: 21 }} />
                           <Typography
                              style={{
                                 color: props.active ? 'white' : null,
                                 marginTop: 2,
                                 marginLeft: 10,
                                 fontSize: 13,
                              }}
                           >
                              {t[`package_${product_name}_feature_${idx + 1}`] ||
                                 `package_${product_name}_feature_${idx + 1}`}
                           </Typography>
                        </div>
                     );
                  })}
            </div>
            <Grid item xs={12} className={classes.btnContainer}>
               <Button
                  className={classes.btn}
                  color={props.active ? 'default' : 'primary'}
                  fullWidth
                  onClick={() => {
                     if (product.name === 'Special' && isTemp) {
                        window.open('https://www.qpien.com/contact-us', '_blank');
                     } else if (product.name === 'Special') {
                        chooseHandler(product.name);
                     } else {
                        chooseHandler(product.name);
                     }
                  }}
               >
                  {product.name === 'Special' ? (isTemp ? t?.contact_us : t?.choose) : t?.choose}
               </Button>
            </Grid>
         </div>
      </Box>
   );
};

export default Package;

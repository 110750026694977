import React, { useState, useContext } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { MenuItem, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ButtonBasedPopup from '@components/ButtonBasedPopup';
import Modal from '@components/Modal/modal';
import AddNewCardModal from './addNewCardModal';
import { useSelector, useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import {
   REMOVE_PAYMENT_CARD_MUTATION,
   MAKE_DEFAULT_PAYMENT_CARD_MUTATION,
   CREATE_NEW_PAYMENT_CARD_MUTATION,
   RENEW_MAIN_PACKAGE_MUTATION,
} from '../../../../queries/Customer/Service/GraphQL/CustomerProduct/mutation';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import { LanguageContext } from '@helper/locale/langContext';
import { UPDATE_ME } from '@store/actions/person';
import ButtonOK from '@components/ButtonOK/buttonOk';
import PaymentIcon from '@material-ui/icons/Payment';
import AddIcon from '@material-ui/icons/Add';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';

const StyledTableCell = withStyles((theme) => ({
   head: {
      backgroundColor: '#F7F7FA',
      color: '#385273',
   },
   body: {
      color: '#385273',
      fontSize: '14px',
   },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
   root: {
      '&:nth-of-type(odd)': {
         backgroundColor: 'white',
         height: 70,
      },
      '&:nth-of-type(even)': {
         backgroundColor: 'white',
         height: 70,
      },
      root: {
         '&:last-child': {
            borderBottom: '0px',
         },
      },
   },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
   root: {
      minWidth: 300,
      overflowX: 'auto',
      [theme.breakpoints.down('sm')]: {
         width: (props) => props.width - 35,
      },
   },
   rowEven: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      padding: 5,
   },
   rowOdd: {
      padding: 5,
   },
   mainTitle: {
      marginLeft: 10,
      fontSize: '1.1rem',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   secondaryTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
   },
   iconColor: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
   },
   boldWeight: {
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   row: {
      padding: 0,
      margin: 0,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const SavedCards = (props) => {
   const classes = useStyles(props);
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const dispatch = useDispatch();
   const [openOptions, setOpenOptions] = useState('');
   const [openAddNewCardModal, setOpenAddNewCardModal] = useState(false);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [removePaymentCard, { loading: removePaymentCardLoading }] = useMutation(REMOVE_PAYMENT_CARD_MUTATION);
   const [makeDefaultPaymentCard, { loading: makeDefaultPaymentCardLoading }] = useMutation(
      MAKE_DEFAULT_PAYMENT_CARD_MUTATION,
   );
   const [renewMainPackage, { loading: newMainPackageLoading }] = useMutation(RENEW_MAIN_PACKAGE_MUTATION);

   const [createNewPaymentCard, { loading: createNewPaymentCardLoading }] = useMutation(
      CREATE_NEW_PAYMENT_CARD_MUTATION,
   );
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [isRemoveCreditCardModalVisible, setRemoveCreditCardVisible] = useState(null);

   const handleChangeDefaultPaymentCard = async (card) => {
      makeDefaultPaymentCard({
         variables: {
            customer: person.customer._id,
            binNumber: card.binNumber,
            lastFourDigits: card.lastFourDigits,
         },
      })
         .then(
            ({
               data: {
                  makeDefaultPaymentCard: { data: makeDefaultPaymentCard },
               },
            }) => {
               // on success event
               // close dialogs

               setOpenOptions('');
               enqueueSnackbar(`Kart başarıyla otomatik ödeme kartı yapıldı`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });

               return '';
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div
                     style={{
                        color: '#000',
                        fontSize: '13px',
                        cursor: 'pointer',
                        fontWeight: '500',
                     }}
                     onClick={() => closeSnackbar(key)}
                  >
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   const createNewCardSubmit = async (card) => {
      createNewPaymentCard({
         variables: {
            customer: person.customer._id,
            cardAlias: card.cardHolderName,
            cardHolderName: card.cardHolderName,
            cardNumber: card.cardNumber,
            expireMonth: card.expireMonth,
            expireYear: card.expireYear,
            cvc: card.cvc,
         },
      })
         .then(
            ({
               data: {
                  createNewPaymentCard: { data: createNewPaymentCard },
               },
            }) => {
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        customer: createNewPaymentCard,
                     },
                  },
               });
               setOpenAddNewCardModal(false);
               enqueueSnackbar(`Yeni Kart başarıyla eklendi`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });

               return '';
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div
                     style={{
                        color: '#000',
                        fontSize: '13px',
                        cursor: 'pointer',
                        fontWeight: '500',
                     }}
                     onClick={() => closeSnackbar(key)}
                  >
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   const onMakePayment = async (data) => {
      renewMainPackage({
         variables: {
            ...data,
         },
      })
         .then(
            ({
               data: {
                  renewMainPackage: { data: renewMainPackage },
               },
            }) => {
               dispatch({
                  type: UPDATE_ME,
                  payload: {
                     person: {
                        ...person,
                        customer: {
                           ...person.customer,
                           ...renewMainPackage,
                        },
                     },
                  },
               });
               setOpenAddNewCardModal(false);
               enqueueSnackbar(`Payment successfully. You can use platform now. 🚀`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });

               return '';
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div
                     style={{
                        color: '#000',
                        fontSize: '13px',
                        cursor: 'pointer',
                        fontWeight: '500',
                     }}
                     onClick={() => closeSnackbar(key)}
                  >
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   const loadingOrFetching =
      removePaymentCardLoading || makeDefaultPaymentCardLoading || createNewPaymentCardLoading || newMainPackageLoading;

   if (loadingOrFetching) {
      return <div className='loading'></div>;
   }

   return (
      <Grid className={classes.root}>
         <Box
            elevation={3}
            style={{
               borderRadius: 17,
               overflowY: 'auto',
               border: '1px solid #D9D9D9',
            }}
         >
            <Grid container direction='column'>
               <Grid container justifyContent='space-between' style={{ padding: 10 }} alignItems='center'>
                  <Typography className={classes.mainTitle}>{t?.saved_cards}</Typography>
                  <Grid>
                     <ButtonOK
                        startIcon={<PaymentIcon />}
                        label={person.customer.appStatus === 'Disabled' ? t?.make_payment : t?.add_new_card}
                        onClick={() => setOpenAddNewCardModal(true)}
                        fontWeight='bold'
                        endIcon={<AddIcon />}
                        style={{ width: '100%' }}
                        height={40}
                     />
                  </Grid>
               </Grid>
               <Grid container item xs={12}>
                  <TableContainer style={{ width: '100%' }}>
                     <Table className={classes.table} aria-label='customized table'>
                        <TableHead>
                           <TableRow>
                              <StyledTableCell align='left' className={classes.tableHead}>
                                 {t?.card_name}
                              </StyledTableCell>

                              <StyledTableCell align='left' className={classes.tableHead}>
                                 {t?.card_number}
                              </StyledTableCell>

                              <StyledTableCell align='center' className={classes.tableHead}>
                                 {t?.bank_name}
                              </StyledTableCell>
                              <StyledTableCell align='center' className={classes.tableHead}>
                                 {t?.status}
                              </StyledTableCell>
                              <StyledTableCell
                                 align='center'
                                 className={classes.tableHead}
                                 style={{ minWidth: 200, textAlign: 'right' }}
                              >
                                 {t.operation}
                              </StyledTableCell>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {person?.customer?.paymentCards?.map((card) => (
                              <StyledTableRow key={card.binNumber + card.lastFourDigits}>
                                 <StyledTableCell align='left' className={classes.row} style={{ paddingLeft: 10 }}>
                                    {card.cardAlias}
                                 </StyledTableCell>
                                 <StyledTableCell align='left' className={classes.row}>
                                    <Typography>
                                       {card.binNumber?.substring(0, 4)} **** **** {card.lastFourDigits}
                                    </Typography>
                                 </StyledTableCell>
                                 <StyledTableCell
                                    align='center'
                                    style={{ fontSize: '1rem', fontWeight: 'bold' }}
                                    className={classes.row}
                                 >
                                    <Typography noWrap>{card.cardBankName}</Typography>
                                 </StyledTableCell>
                                 <StyledTableCell
                                    align='center'
                                    style={{ fontSize: '1rem', fontWeight: 'bold' }}
                                    className={classes.row}
                                 >
                                    <Typography>
                                       {card.default ? (
                                          <span style={{ color: '#26BF8A' }}>{t?.primary}</span>
                                       ) : (
                                          t?.secondary
                                       )}
                                    </Typography>
                                 </StyledTableCell>
                                 <StyledTableCell align='right' style={{ paddingRight: 10 }} className={classes.row}>
                                    <Grid>
                                       <ButtonBasedPopup
                                          button={<MoreHorizIcon style={{ color: '#D0D0D0', cursor: 'pointer' }} />}
                                          open={openOptions === card.binNumber + card.lastFourDigits}
                                          onClose={() => setOpenOptions('')}
                                          onOpen={() => setOpenOptions(card.binNumber + card.lastFourDigits)}
                                       >
                                          <MenuItem
                                             disabled={card.default}
                                             key='1'
                                             onClick={() => handleChangeDefaultPaymentCard(card)}
                                          >
                                             <DoneIcon
                                                style={{
                                                   color: '#26BF8A',
                                                   marginRight: '.2rem',
                                                }}
                                             />{' '}
                                             {t?.make_primary}
                                          </MenuItem>
                                          <MenuItem key='2' onClick={() => setRemoveCreditCardVisible(card)}>
                                             <DeleteForeverOutlinedIcon
                                                style={{
                                                   color: '#F4425B',
                                                   marginRight: '.2rem',
                                                }}
                                             />
                                             {t?.remove}
                                          </MenuItem>
                                       </ButtonBasedPopup>
                                    </Grid>
                                 </StyledTableCell>
                              </StyledTableRow>
                           ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Grid>
            </Grid>
            <Modal open={openAddNewCardModal} onClose={() => setOpenAddNewCardModal(false)}>
               <AddNewCardModal
                  onMakePayment={onMakePayment}
                  onClose={() => setOpenAddNewCardModal(false)}
                  onAccept={createNewCardSubmit}
               />
            </Modal>
            <Modal
               paperStyle={{ width: 450 }}
               onClose={() => setRemoveCreditCardVisible(null)}
               open={!!isRemoveCreditCardModalVisible}
               modalHeader={t['general_message_are_your_sure']}
            >
               <ModalLargeText style={{ textAlign: 'center', marginBlock: 40 }}>
                  {isRemoveCreditCardModalVisible?.cardAlias + t['your_credit_card_will_be_deleted']}
               </ModalLargeText>
               <ModalButtonField>
                  <ButtonCancel
                     label={t?.general_message_no}
                     onClick={() => setRemoveCreditCardVisible(null)}
                     disabled={removePaymentCardLoading}
                     className={classes.modalButtons}
                     style={{
                        color: '#212121',
                        backgroundColor: '#eeee',
                        opacity: removePaymentCardLoading ? 0.7 : 1,
                        '&:hover': {
                           backgroundColor: removePaymentCardLoading ? '#eeee' : '#e0e0e0',
                        },
                     }}
                  />
                  <ButtonOK
                     label={t['general_message_yes']}
                     className={classes.modalButtons}
                     disabled={removePaymentCardLoading}
                     style={{
                        color: 'white',
                        opacity: removePaymentCardLoading ? 0.7 : 1,
                        backgroundColor: '#ef5350',
                        float: 'right',
                     }}
                     onClick={() => {
                        removePaymentCard({
                           variables: {
                              customer: person.customer._id,
                              binNumber: isRemoveCreditCardModalVisible.binNumber,
                              lastFourDigits: isRemoveCreditCardModalVisible.lastFourDigits,
                           },
                        })
                           .then(
                              ({
                                 data: {
                                    removePaymentCard: { data: removePaymentCard },
                                 },
                              }) => {
                                 setRemoveCreditCardVisible(null);
                                 setOpenOptions('');
                                 enqueueSnackbar(`Kart başarıyla kaldırıldı`, {
                                    // send success message
                                    variant: 'success',
                                    autoHideDuration: 2000,
                                 });

                                 return '';
                              },
                           )
                           .catch((error) => {
                              // on error event
                              setRemoveCreditCardVisible(null);
                              enqueueSnackbar(error.message, {
                                 // send error message
                                 variant: 'error',
                                 persist: true,
                                 action: (key) => (
                                    <div
                                       style={{
                                          color: '#000',
                                          fontSize: '13px',
                                          cursor: 'pointer',
                                          fontWeight: '500',
                                       }}
                                       onClick={() => closeSnackbar(key)}
                                    >
                                       {' '}
                                       Kapat
                                    </div>
                                 ),
                              });
                           });
                     }}
                  />
               </ModalButtonField>
            </Modal>
         </Box>
      </Grid>
   );
};

export default SavedCards;

import { gql } from '@apollo/client';
import { CUSTOMER_FRAGMENT } from '../../../fragments';

export const CHECK_RESET_PASSWORD_TOKEN_QUERY = gql`
   query checkResetPasswordTokenQuery($token: String!) {
      checkResetPasswordToken(token: $token) {
         data
         success
         code
         message
      }
   }
`;

// Get Customers Team Members
export const GET_CUSTOMER_TEAMS_QUERY = gql`
   query getCustomerTeamMembersQuery($customer: ID!, $person: ID!) {
      customerTeams(customer: $customer, person: $person) {
         data {
            _id
            name
            surname
            username
            photo
            status
            type
         }
         success
         code
         message
      }
   }
`;

// Get all customer persons with all details
export const GET_PERSON_CUSTOMERS_QUERY = gql`
   ${CUSTOMER_FRAGMENT}
   query CustomerPersons($customer: ID!, $notSelectBot: Boolean, $page: Int!, $pageSize: Int!, $search: String) {
      customerPersons(
         customer: $customer
         notSelectBot: $notSelectBot
         page: $page
         pageSize: $pageSize
         search: $search
      ) {
         data {
            docs {
               _id
               name
               surname
               username
               phone
               email
               photo
               customer {
                  ...CustomerFields
               }
               type
               setup
               status
               createdAt
               chatParticipateLimit
               participatingChatsCount
               signedTeams
               accountStatus
               isEmailVerified
               language
               isTranslate
            }
            hasNextPage
            docsCount
         }
         success
         code
         message
      }
   }
`;

// Contact
// queries
export const GET_CUSTOMER_CLIENT_LIST = gql`
   query getCustomerClientListQuery(
      $customer: ID!
      $name: String
      $fromDate: Date
      $toDate: Date
      $type: Int
      $pageNumber: Int!
      $pageSize: Int!
      $team: String
   ) {
      customerClientList(
         customer: $customer
         name: $name
         fromDate: $fromDate
         toDate: $toDate
         type: $type
         pageNumber: $pageNumber
         pageSize: $pageSize
         team: $team
      ) {
         data {
            _id
            name
            surname
            country
            state
            address
            postCode
            city
            type
            email
            phone
            createdAt
            phoneUsagePermission
            emailUsagePermission
            tags
            language
         }
         success
         code
         message
      }
   }
`;
export const GET_CONTACTS_COUNT = gql`
   query getCustomerClientsCountQuery($customer: ID!, $name: String, $fromDate: Date, $toDate: Date, $type: Int, $team: String) {
      getContactsCount(customer: $customer, name: $name, fromDate: $fromDate, toDate: $toDate, type: $type, team: $team) {
         data
         success
         code
         message
      }
   }
`;

export const GET_APP_PREFERENCE_QUERY = gql`
   query getAppReferenceQuery {
      getPreference {
         data {
            requireAccountApproval
         }
         success
         code
         message
      }
   }
`;

import React, { FC, useContext } from 'react';
import { Typography, ButtonBase, Link, IconButton } from '@material-ui/core';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import { TIMEZONE } from '../../../../../helper/config';
import { OrderProps, ORDER_FLAGS_COLORS } from '../../Tabs/E-Commerce/Tabs/Store/types';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import { currencyHandler } from '@helper/textUtils';

const useStyles = makeStyles(() => ({
   orderCardContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      padding: 8,
      cursor: 'default',
      borderTop: '1px solid #D9E0E6',
   },
   row1: {
      display: 'flex',
      justifyContent: 'space-between',
      flexGrow: 1,
      overflow: 'hidden',
   },
   row2: {
      display: 'flex',
      justifyContent: 'space-between',
      flexGrow: 1,
      overflow: 'hidden',
   },
   row3: {
      display: 'flex',
      flexWrap: 'wrap',
      marginLeft: -5,
      marginRight: -5,
   },
   link: {
      display: 'flex',
   },
   idTypo: {
      color: '#385273',
      fontSize: '1.2em',
   },
   defaultTypo: {
      color: '#6F767E',
      fontSize: '1.2em',
   },
   flagTypo: {
      color: '#FFF',
      padding: '2px 12px',
      borderRadius: 99,
      marginLeft: 5,
      marginRight: 5,
      marginTop: 5,
   },
   cursorPointer: {
      cursor: 'pointer',
   },
   openInNewRoundedIconContainer: {
      padding: 5,
      marginLeft: 3,
   },
   openInNewRoundedIcon: {
      fontSize: '0.7em',
      color: '#385273',
   },
}));

interface OrderCardProps {
   order: any;
   cursorPointer: boolean;
   onClick?: () => void;
   onIdClick?: () => void;
   containerStyle?: React.CSSProperties;
}

const OrderCard: FC<OrderCardProps> = ({ order, cursorPointer, onClick, onIdClick, containerStyle }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const { name, orderCustomer, flags, totalPrice, created_at, currency } = order;

   const getBackgroundColor = (flag: string) => {
      const index = Object.keys(ORDER_FLAGS_COLORS).findIndex((flagValue) => flagValue === flag);
      return Object.values(ORDER_FLAGS_COLORS)[index];
   };

   return (
      <ButtonBase
         className={classNames(classes.orderCardContainer, cursorPointer && classes.cursorPointer)}
         disableRipple
         onClick={onClick}
         style={containerStyle}
      >
         <div className={classes.row1}>
            {onIdClick ? (
               <Link onClick={onIdClick} className={classes.link}>
                  <Typography variant='body1' noWrap className={classes.idTypo}>
                     {`${t['page_e_commerce_store_typo_order_id_prefix']}: ${name}`}
                  </Typography>
                  <IconButton className={classes.openInNewRoundedIconContainer}>
                     <OpenInNewRoundedIcon className={classes.openInNewRoundedIcon} />
                  </IconButton>
               </Link>
            ) : (
               <Typography variant='body1' noWrap className={classes.idTypo}>{`${name}`}</Typography>
            )}
            <Typography variant='body2' noWrap className={classes.defaultTypo}>
               {moment(created_at).tz(TIMEZONE).format('DD.MM.YYYY hh:mm')}
            </Typography>
         </div>
         <div className={classes.row2}>
            <Typography variant='body1' noWrap className={classes.defaultTypo}>
               {(orderCustomer.firstName || '-') + ' ' + (orderCustomer.lastName || '-')}
            </Typography>
            <Typography variant='body1' noWrap className={classes.defaultTypo}>{`${currencyHandler(
               currency
            )}${parseFloat(totalPrice)?.toFixed(2)}`}</Typography>
         </div>
         <div className={classes.row3}>
            {
               //@ts-ignore
               flags?.map((flag, index) => {
                  return t[`page_e_commerce_store_status_option_${flag?.toLowerCase()}`] ? (
                     <Typography
                        key={index}
                        noWrap
                        className={classes.flagTypo}
                        style={{ backgroundColor: getBackgroundColor(flag) }}
                     >
                        {t[`page_e_commerce_store_status_option_${flag?.toLowerCase()}`]}
                     </Typography>
                  ) : null;
               })
            }
         </div>
      </ButtonBase>
   );
};

export default OrderCard;

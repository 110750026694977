import { gql } from '@apollo/client';

export const GET_CHAT_TAGS_QUERY = gql`
   query GetChatTagList($customer: ID!, $pageSize: Int!, $page: Int!, $search: String) {
      getChatTagList(customer: $customer, pageSize: $pageSize, page: $page, search: $search) {
         data {
            docs {
               _id
               name
               color
               customer {
                  accountStatus
                  accountConfirmation
                  appStatus
                  hasFreeTrial
                  freeTrialExpiringDate
                  isANewCustomer
                  _id
                  companyName
               }
               chatCount
               createdAt
            }
            hasNextPage
            docsCount
         }
         success
         code
         message
      }
   }
`;

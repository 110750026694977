import React, { useContext, useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';
import SelectablePlatforms from '@components/SelectablePlatforms';
import { PLATFORM_TYPE } from '../../types';
import SocialMediaIcon from '@components/SocialMediaIcon';
import { useDispatch, useSelector } from 'react-redux';
import { SET_CHAT_FILTERS } from '@store/actions/chat';
import { getChatFilters } from '@store/reducers/chat';

import ChatFilters from '@components/ChatFilters';
import { ChatType } from '../../../../types/enums';
import ChatListTabs from '@components/ChatListTabs';

const FIRST_ROW_HEIGHT = 44;

const useStyles = makeStyles((theme) => ({
   errorContainer: {
      border: '1px solid red',
      color: 'red',
      borderRadius: 5,
      padding: 5,
      marginTop: 6,
      marginBottom: 6,
   },
   errorText: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
   },
   toolbarFirstRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      gap: 5,
   },
   selectTeamContainer: {
      flexGrow: 1,
   },
   iconButtonContainer: {
      flexShrink: 0,
   },
   iconButtonContainer2: {
      flexShrink: 0,
      '&..MuiPopover-paper': {
         backgroundColor: 'red',
      },
   },
   iconButton: {
      height: FIRST_ROW_HEIGHT,
      width: FIRST_ROW_HEIGHT,
      backgroundColor: '#fff',
      borderRadius: 6,
      border: '1px solid #e8e9ed',
   },
   iconButtonActive: {
      backgroundColor: '#fff !important',
      // borderWidth: 1,
      // borderColor: theme.chatApp.general.pallet.passiveStructureAzure,
      // '&:hover': {
      //    borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
      //    backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
      // },
   },
   icon: {
      // color: 'red',
   },
   iconActive: {
      color: '#147BFB',
   },
   backspaceIcon: {
      width: 20,
      height: 20,
      color: '#9b9b9b',
   },
   teamMenuInput: {
      height: FIRST_ROW_HEIGHT,
      backgroundColor: '#fff',
      borderRadius: 6,
      paddingRight: 5,
      paddingLeft: 5,
      minWidth: 0,
      width: '100%',
      border: '1px solid #e8e9ed',
   },
   teamMenuInputActive: {
      backgroundColor: '#fff !important',
   },
   teamMenuItemContainer: {
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 5,
   },
   teamMenuItem: {
      width: '100%',
      backgroundColor: '#fff',
      borderRadius: 6,
      paddingLeft: 10,
      paddingRight: 10,
   },
   teamMenuFooter: {
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 7,
      paddingRight: 10,
      borderTop: '1px solid #E6E6E6',
   },
   teamAvatar: {
      width: 30,
      height: 30,
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlack,
      marginRight: 8,
      fontSize: 16,
   },
   teamLabel: {
      flex: 1,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontWeight: '500',
   },
   teamExpandMoreIcon: {
      alignSelf: 'flex-end',
   },
   searchbar: {
      display: 'flex',
      backgroundColor: '#fff',
      flexGrow: 1,
      '&.MuiTextField-root': {
         margin: '0 !important',
      },
   },
   filterBoxPopover: {
      '& .MuiPaper-root': {
         overflow: 'visible',
      },
   },
}));

const PLATFORMS_MOCK_LIST = [
   { name: PLATFORM_TYPE.LIVE_CHAT, code: 10, tooltip: 'Live Chat' },
   { name: PLATFORM_TYPE.WHATS_APP, code: 1, tooltip: 'WhatsApp' },
   { name: PLATFORM_TYPE.FACEBOOK, code: 3, tooltip: 'Facebook' },
   { name: PLATFORM_TYPE.INSTAGRAM, code: 4, tooltip: 'Instagram Messenger' },
   { name: PLATFORM_TYPE.EMAIL, code: 5, tooltip: 'Email' },
   { name: PLATFORM_TYPE.TELEGRAM, code: -9, tooltip: 'Telegram' },
];

const getStatusList = (t) => [
   { label: t['page_chat_typo_filter_title_all'], key: 'ALL', value: 'ALL', color: '#157CFC' },
   {
      label: t['page_chat_typo_filter_title_waiting'],
      key: 'ACTIVE',
      value: 0,
      color: '#1ac31a',
   },
   {
      label: t['page_chat_typo_filter_title_closed'],
      key: 'CLOSED',
      value: 1,
      color: '#F2B544',
   },
   {
      label: t['page_chat_typo_filter_title_resolved'],
      key: 'RESOLVED',
      value: 2,
      color: '#499cf2',
   },
   { label: t['page_chat_typo_filter_title_spam'], key: 'SPAM', value: 3, color: '#577590' },
   { label: t['page_chat_typo_filter_title_bot_is_joined'], key: 'BotIsJoined', value: 6, color: '#157CFC' },
   { label: t['page_chat_typo_filter_title_deleted'], key: 'DELETED', value: 5, color: '#d71929' },
];

const Toolbar = ({ teams, agents, tags, currentTab, onTabChange, chatError, companyChatCount, mineChatCount }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const history = useHistory();
   const dispatch = useDispatch();
   const chatFilters = useSelector(getChatFilters);
   const statusList = getStatusList(t);
   const emailAccounts = useSelector((state) => state.chatReducer.emailAccounts.emailAccounts);
   const whatsappWebAccounts = useSelector((state) => state.chatReducer.whatsappWebAccounts.whatsappWebAccounts);
   const whatsappBusinessAccounts = useSelector(
      (state) => state.chatReducer.whatsappBusinessAccounts.whatsappBusinessAccounts,
   );
   const onSelectTeam = useCallback(
      (teams) => {
         dispatch({
            type: SET_CHAT_FILTERS,
            payload: { teams },
         });
      },
      [dispatch],
   );

   const onSelectPlatform = useCallback(
      (platform) => {
         dispatch({
            type: SET_CHAT_FILTERS,
            payload: { platform },
         });
      },
      [dispatch],
   );

   const onChangeSort = useCallback(
      (sort) => {
         dispatch({
            type: SET_CHAT_FILTERS,
            payload: { desc: sort },
         });
      },
      [dispatch],
   );

   const onSearch = useCallback(
      (text) => {
         dispatch({
            type: SET_CHAT_FILTERS,
            payload: { text },
         });
      },
      [dispatch],
   );

   const handleClickCheck = (type) => {
      if (type) {
         if (type === PLATFORM_TYPE.FACEBOOK) {
            history.push('/settings/platforms/facebook');
         } else if (type === PLATFORM_TYPE.INSTAGRAM) {
            history.push('/settings/platforms/instagram');
         } else if (type === PLATFORM_TYPE.WHATS_APP_WEB) {
            history.push('/settings/platforms/whatsapp-web');
         } else if (type === PLATFORM_TYPE.WHATS_APP_BUSINESS) {
            history.push('/settings/platforms/whatsapp-business');
         }
      }
   };

   return (
      <div style={{ marginLeft: '10px', marginRight: '10px', paddingTop: '10px', overflowX: 'hidden' }}>
         <ChatFilters
            chatType={ChatType.DM}
            chatFilters={chatFilters}
            teams={teams}
            agents={agents}
            tags={tags}
            statusList={statusList}
            onSearch={onSearch}
            onChangeSort={onChangeSort}
            onSelectTeam={onSelectTeam}
            emailAccounts={emailAccounts}
            whatsappWebAccounts={whatsappWebAccounts}
            whatsappBusinessAccounts={whatsappBusinessAccounts}
         />
         <SelectablePlatforms
            platformsData={PLATFORMS_MOCK_LIST}
            onSelectPlatform={onSelectPlatform}
            currentSelectedPlatform={chatFilters.platform}
         />
         {chatError.length > 0 && (
            <Grid xs={12} container alignItems='center' className={classes.errorContainer}>
               <Grid item className={classes.errorText}>
                  <Typography style={{ marginRight: 10 }}>{t?.platform_connection_error}</Typography>
                  {Array.isArray(chatError) &&
                     chatError.map((e, index) => {
                        return (
                           <div
                              key={index}
                              style={{ marginLeft: 5, cursor: 'pointer' }}
                              onClick={() => handleClickCheck(e)}
                           >
                              <SocialMediaIcon socialMediaCode={e} selected width={25} height={25} />
                           </div>
                        );
                     })}
               </Grid>
            </Grid>
         )}

         <ChatListTabs
            chatType={ChatType.DM}
            chatFilters={chatFilters}
            agents={agents}
            selectedTab={currentTab}
            setSelectedTab={onTabChange}
            companyChatCount={companyChatCount}
            mineChatCount={mineChatCount}
         />
      </div>
   );
};

export default Toolbar;

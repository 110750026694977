import React,{ useContext, useState, useMemo } from 'react';
import WhatsAppBusinessSettingsContext from "../contexts/WhatsAppBusinessSettingsContext";


const WhatsAppBusinessSettingsProvider = ({ children }: { children: React.ReactNode}) => {
  const [value, setValue] = useState<string>('');

  const contextValue = useMemo(() => ({
    value,
    setValue,
  }), [value]);

  return (
    <WhatsAppBusinessSettingsContext.Provider value={{ value, setValue }}>
      {children}
    </WhatsAppBusinessSettingsContext.Provider>
  );
}

export default WhatsAppBusinessSettingsProvider;


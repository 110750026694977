import React, { useContext } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import Button from '@material-ui/core/Button';
import ButtonCancel from '../../../../../components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';

const useStyles = makeStyles(() => ({
   paper: {
      width: 600,
      borderRadius: 15,
      display: 'flex',
      flexDirection: 'column',
   },
   titleContainer: {
      padding: '15px',
      borderBottom: '1px solid #EBEBEB',
   },
   title: {
      textAlign: 'center',
      fontSize: '1.3rem',
      color: '#385273',
      fontWeight: 'bold',
   },
   contentContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px',
   },
   subTitle: {
      fontSize: '1.2rem',
      color: '#1E2944',
      textAlign: 'center',
      marginBottom: '50px',
   },
   description: {
      fontSize: '1.1rem',
      color: '#10223A',
      textAlign: 'center',
      marginBottom: '20px',
   },
   footerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '20px',
   },
   button: {
      height: 40,
      width: 140,
      fontSize: '1.1rem',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const EnableAccountModalContent = ({ onClose, onAccept }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <Grid>
         <ModalLargeText style={{ textAlign: 'center' }}>Do you really want to Enable Live Chat?</ModalLargeText>
         <ModalLargeText style={{ textAlign: 'center' }}>
            You can Enable your live chat account and continue to use it again. You can access all your old data
         </ModalLargeText>
         <ModalButtonField>
            <Button
               onClick={onClose}
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            >
               {t['comp_button_cancel']}
            </Button>
            <ButtonOK
               label='Enable'
               onClick={onAccept}
               className={classes.modalButtons}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
                  float: 'right',
               }}
            />
         </ModalButtonField>
      </Grid>
   );
};

export default EnableAccountModalContent;

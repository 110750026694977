import React, { useContext } from 'react';
import { Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '../../../../../helper/locale/langContext';

const useStyles = makeStyles(() => ({
   preview: {
      height: '100%',
      width: '100%',
      borderRadius: 15,
      overflowY: 'auto',
      background: '#F7F7FA',
      padding: '12px',
      paddingBottom: '2rem',
      marginBottom: '3rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   previewContent: {
      width: '100%',
      maxWidth: '350px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
   },
   previewTitle: {
      color: '#000',
      fontWeight: 600,
      fontSize: '1.4rem',
      marginBottom: '25px',
   },
   previewSubtitle: {
      paddingBottom: '1.1rem',
      color: '#3C3C3C',
      marginBottom: '75px',
   },
   previewFooterText: {
      paddingBottom: '1rem',
      color: '#3C3C3C',
      marginTop: '25px',
   },
}));

const NoPreview = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <Paper className={classes.preview}>
         <div className={classes.previewContent}>
            <Typography className={classes.previewTitle}>
               {t['page_settings_typo_platforms_live_chat_installation_preview_title']}
            </Typography>
            <Typography className={classes.previewSubtitle}>
               {t['page_settings_typo_platforms_live_chat_installation_preview_subtitle']}
            </Typography>
            <img src={'/logo.svg'} width={128} alt='Qpien logo' />
            <Typography className={classes.previewFooterText}>
               {t['page_settings_typo_platforms_live_chat_installation_preview_footer_text']}
            </Typography>
         </div>
      </Paper>
   );
};

export default NoPreview;

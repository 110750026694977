import React from 'react';
import { LiveChatSettingsProvider } from '../../../../contexts/LiveChatSettingsContext';
import Router from './Router';

const LiveChat = () => {
   return (
      <LiveChatSettingsProvider>
         <Router />
      </LiveChatSettingsProvider>
   );
};

export default LiveChat;

import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
const useStyles = makeStyles((theme) => ({
   title: {
      textAlign: 'left',
      fontSize: '15px',
      paddingLeft: theme.spacing(2),
      paddingTop: 12,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },

   itemTitle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: '1rem',
      // marginTop:'5px'
   },
   count: {
      fontFamily: theme.typography.fontFamily,
      color: theme.chatApp.general.pallet.passiveStructureBlack,
      marginTop: 20,
   },

   paper: {
      width: '100%',
      height: '330px',
      borderRadius: 15,
   },

   paperC: {
      padding: '10px',
      // width:150,
      margin: 10,
      height: 122,
      borderRadius: 10,
      backgroundColor: theme.palette.grey[100],
      color: theme.chatApp.workspace.sideBar.backgroundColor,
   },

   chart: {
      color: theme.chatApp.workspace.sideBar.backgroundColor,
      // marginLeft: 0,
      marginTop: -30,
   },
   NoDataContainer: {
      marginTop: 150,
      marginLeft: 200,
   },
   PlatformsChart: {
      [theme.breakpoints.down('xs')]: {
         display: 'none',
      },
   },
}));

const MessagesBox = ({ receivedToday, sentToday }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <Paper elevation={3} className={classes.paper}>
         <div style={{ height: 45, borderBottom: '1px solid rgb(238, 238, 238)' }}>
            <Typography className={classes.title}>{t['dashboard_messages']}</Typography>
         </div>
         <div>
            <Grid container spacing={0}>
               <Grid item sm={12}>
                  <Paper elevation={0} className={classes.paperC}>
                     <Typography className={classes.itemTitle}>{t?.dashboard_messages_total}</Typography>
                     <Typography variant='h4' align='center' className={classes.count}>
                        {receivedToday + sentToday || 0}
                     </Typography>
                  </Paper>
               </Grid>
               <Grid item sm={6}>
                  <Paper
                     style={{  backgroundColor: '#157CFC' }}
                     elevation={0}
                     className={classes.paperC}
                  >
                     <Typography style={{ color: '#fff' }} className={classes.itemTitle}>
                        {t?.dashboard_messages_sent}
                     </Typography>
                     <Typography style={{ color: '#fff' }} variant='h4' align='center' className={classes.count}>
                        { sentToday|| 0}
                     </Typography>
                  </Paper>
               </Grid>
               <Grid item sm={6}>
                  <Paper style={{ backgroundColor: '#5AC19D', color: 'white !important'}} elevation={0} className={classes.paperC}>
                     <Typography style={{ color: '#fff' }} className={classes.itemTitle}>
                        {t?.dashboard_messages_received}
                     </Typography>
                     <Typography style={{ color: '#fff' }} variant='h4' align='center' className={classes.count}>
                        {receivedToday || 0}
                     </Typography>
                  </Paper>
               </Grid>
            </Grid>
         </div>
      </Paper>
   );
};
export default MessagesBox;

export enum IFlowBlockFieldTypes {
   AllMessageReceived = 'all_message_received',
   Text = 'text',
   Note = 'note',
   Number = 'number',
   Email = 'email',
   Phone = 'phone',
   Date = 'date',
   Url = 'url',
   List = 'list',
   VideoUrl = 'video_url',
   ImageUrl = 'image_url',
   QuestionTextInput = 'question_text_input',
   SendMessage = 'send_message',
   keywordsMessageReceived = 'keyword_message_received',
   AssignToAgent = 'assign_to_agent',
   AssignToTeam = 'assign_to_team',
   AddChatTag = 'add_chat_tag',
   RemoveChatTag = 'remove_chat_tag',
   ChangeEmailConsent = 'change_email_consent',
   ChangePhoneConsent = 'change_phone_consent',
   ChangeConversationStatus = 'change_conversation_status',
   SendMessageWithInformativeBtn = 'send_message_with_informative_buttons',
   SendQuickReplyMessage = 'send_quick_reply_message',
   SendMessageWithlist = 'send_message_with_list',
   SetCustomField = 'set_custom_field',
   ClearCustomField = 'clear_custom_field',
   UserVisitPage = 'user_visits_page',
   PageUrlCheck = 'page_url_check',
   BrowserCountryCheck = 'browser_country_check',
   BrowserLanguageCheck = 'browser_language_check',
   HttpRequest = 'http_request',
   StartFlow = 'start_flow',
}

export enum IFlowBlockFieldRules {
   AllMessage = 'all_messages',
   Contains = 'contains',
   Equal = 'equal',
   NotEqual = 'not_equal',
   NotContains = 'not_contains',
   HasAnyValue = 'has_any_value',
   Unknown = 'unknown',
   StartWith = 'starts_with',
   EndWith = 'ends_with',
}

export enum FlowBlockConditionFields {
   FirstName = 'name',
   LastName = 'surname',
   Email = 'email',
   Phone = 'phone',
   MessageContent = 'message_content',
   Channel = 'channel',
}

export enum IFlowBlockFieldTimeoutUnits {
   Seconds = 'seconds',
   Minutes = 'minutes',
   Hours = 'hours',
}
export enum FlowBlockFieldTimeoutUnits {
   Seconds = 'SECONDS',
   Minutes = 'MINUTES',
   Hours = 'HOURS',
   Days = 'DAYS',
}

export enum ContactFieldsToSave {
   FirstName = 'name',
   LastName = 'surname',
   Email = 'email',
   Phone = 'phone',
   DontSave = 'dont_save',
}

export enum FlowInstanceMessageChannel {
   Facebook = 'Facebook',
   Instagram = 'Instagram',
   Telegram = 'Telegram',
   WhatsappWeb = 'WhatsappWeb',
   WhatsappBusiness = 'WhatsappBusiness',
   Email = 'Email',
   LiveChat = 'LiveChat',
}
export enum FlowFrequency {
   OnEveryVisit = 'send_on_every_visit',
   OncePer24H = 'send_once_per_24_hours',
   OnceForUinqueVisitor = 'send_once_for_a_unique_visitor',
}
export interface QuickRepyTypes {
   type: string;
   title?: string;
   hideTheEdge?: boolean;
   postbackText?: string;
   target?: string | null;
}
export interface InformativeButtonsTypes {
   type: string;
   title?: string;
   postbackText?: string;
   target?: string | null;
}
export interface IFlowBlockField {
   type?: IFlowBlockFieldTypes;
   rule?: IFlowBlockFieldRules;
   keywords?: string[];
   value?: string;
   customValue?: string;
   name?: string;
   save_response_to_contact_field?: ContactFieldsToSave | string;
   question_answer_timeout?: number;
   question_answer_timeout_unit?: string;
   target_on_reply?: string;
   target_on_not_reply?: string;
   target_on_invalid_input?: string;
   delay_time?: number;
   delay_time_unit?: string;
   condition_field?: string;
   quickReplies?: QuickRepyTypes[];
   informativeButtons?: InformativeButtonsTypes[];
   isButtonRequired?: boolean;
   requiredButtonMessage?: string | null;
   liveChatVisitFrequencyUnit?: FlowFrequency;
   platformIds?: string[];
   requestUrl?: string;
   targetOnFailure?: string;
   targetOnSuccess?: string;
   requestMethod?: HttpTestMethod;
   responseDotNotation?: HttpResponseDotNotation[];
   requestBody?: string | null;
   requestBodyFormDataUrlEncoded?: HttpTestHeader[] | null;
   requestBodyFormData?: HttpTestHeader[] | null;
   requestHeaders?: HttpTestHeader[];
   startFlowTargetedBot?: string;
   startFlowNodeStep?: string;
   note?: string;
   backgroundColor?: string;
}

export interface IFlowBlockTemplate {
   _id: string;
   title: string;
   type: FlowBlockType;
   category?: FlowNodeType;
   fields: IFlowBlockField[];
   isConnectable?: boolean;
   target?: string;
   channelType?: string;
}

export enum FlowNodeType {
   Triggers = 'trigger',
   Note = 'note',
   SendMessage = 'send_message',
   AskQuestion = 'ask_question',
   StarFlow = 'start_flow',
   Actions = 'actions',
   Conditions = 'conditions',
   Delay = 'delay',
   WelcomeMessage = 'welcome_message',
   SendButtonMessage = 'send_message_buttons',
   HttpRequest = 'http_request',
}

export enum FlowBlockType {
   Question = 'question',
   Action = 'action',
   Condition = 'condition',
   Delay = 'delay',
   Note = 'note',
   StarFlow = 'start_flow',
   SendMessage = 'send_message',
   HttpRequest = 'http_request',
   SendMedia = 'send_media',
   NewMessageReceived = 'keyword_message_recevied',
   UserVisitPage = 'user_visits_page',
   AllMessageReceived = 'all_message_recevied',
   SendMessageConnector = 'send_message_connector',
   SendQuickReplyMessage = 'send_quick_reply_message',
   SendInformativeButtonMessage = 'send_message_with_informative_buttons',
}

export interface TriggerFields {
   channelType?: string;
}

export interface FlowBotDataItem {
   _id: string;
   title: string;
   createdAt: string;
   customer: string;
   status: boolean;
   updatedAt: string;
   triggerFields: TriggerFields[];
}

export interface FlowBlock {
   _id: string;
   customer?: string;
   title: string;
   type?: string;
   category?: string;
   fields: string[];
   isConnectable?: boolean;
   target?: string;
   templateId?: string;
   createdAt?: string;
   updatedAt?: string;
}

export interface FileBlockProps {
   _id: string;
   file: File;
}

export interface TempNode {
   id: string;
   block: FlowBlock[];
}
export interface NodeTemplates {
   _id: string;
   type: string;
   title: string;
}
export interface FlowNode {
   _id: string;
   title: string;
   type: string;
   flowBot: string;
   groupId?: string;
   isValid: Boolean;
   data: {};
   templateId: string;
   flowBlocks: string[];
   coordinates: { x: string; y: string };
}

export type HttpTestHeader = {
   key: string;
   value: string;
};
export type HttpResponseDotNotation = {
   fieldId: string;
   value: string;
};

export enum HttpTestMethod {
   GET = 'GET',
   POST = 'POST',
   PUT = 'PUT',
   DELETE = 'DELETE',
}

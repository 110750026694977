import { LanguageContext } from '@helper/locale/langContext';
import { Grid, OutlinedInput, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useContext } from 'react';
import { Controller } from 'react-hook-form';
import { IForm } from '../types';

const useStyles = makeStyles(() => ({
   sampleURL: {
      paddingBottom: 0,
   },
   title: {
      fontSize: 20,
      paddingBottom: 10,
      paddingLeft: 4,
   },
}));
const SampleURL = ({ watch, isView, control, isSmallScreen, handleMedia, register, setValue }: IForm) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();

   return (
      <Grid className={classes.sampleURL}>
         {(watch('variable.value') === 'template_custom' || watch('variable.value') === 'template_cart_url') && (
            <>
               <Grid className={classes.title}>
                  <Typography variant='inherit'>
                     {t['settings_whatsapp_business_template_form_sample_url_header']}
                  </Typography>
               </Grid>
               <Grid>
                  <Typography style={{ paddingLeft: 3 }}>
                     {t['settings_whatsapp_business_template_form_sample_url_text']}
                  </Typography>
               </Grid>
               <Controller
                  name={'buttonTextwebExample'}
                  rules={{
                     required: true,
                  }}
                  control={control}
                  render={({ field, fieldState: { error, isDirty, isTouched } }) => (
                     <OutlinedInput
                        fullWidth
                        error={!!error}
                        {...field}
                        style={{ background: 'white', marginTop: 10, marginBottom: 10 }}
                        startAdornment={
                           <Typography style={{ paddingLeft: 3 }}>
                              {(watch('buttonTextwebUrl').endsWith('/') || watch('buttonTextwebUrl') === ''
                                 ? watch('buttonTextwebUrl')
                                 : watch('buttonTextwebUrl') + '/') || 'https://zagros.com/product/'}
                           </Typography>
                        }
                        placeholder='[phone-wallet-case]'
                        {...register('buttonTextwebExample')}
                     />
                  )}
               />
               <br />
            </>
         )}
         {watch('variable.value') === 'template_survey_url' && (
            <>
               <Grid className={classes.title}>
                  <Typography variant='inherit'>
                     {t['settings_whatsapp_business_template_form_sample_url_header']}
                  </Typography>
               </Grid>
               <Grid>
                  <Typography style={{ paddingLeft: 3 }}>
                     {t['settings_whatsapp_business_template_form_sample_survey_url_text']}
                  </Typography>
               </Grid>

               <OutlinedInput
                  disabled={true}
                  fullWidth
                  style={{ background: 'white', marginTop: 10, marginBottom: 10 }}
                  startAdornment={
                     <Typography style={{ paddingLeft: 3 }}>
                        https://www.feedback.qpien.com/1a2b3c4d5e6f7g8h9
                     </Typography>
                  }
               />
               <br />
            </>
         )}
      </Grid>
   );
};

/*
<Input
            fullWidth
            control={control}
            {...register('buttonTextwebExample')}
            placeholder='https://zagros.com/product/[phone-wallet-case]'
            background='white'
            rules={{
               required: true,
               validate: (v: any) => {
                  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/gm;
                  const result = regex.test(v);
                  return result;
               },
            }}
         />
*/

export default SampleURL;

import React, { Fragment } from 'react';
import { TypographyProps, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { MyTheme } from '@styles/config';
interface Props extends TypographyProps {
   bold?: boolean;
   margin?: boolean;
}

const useStyles = makeStyles((theme: MyTheme) => ({
   root: {
      fontWeight: (props: any) => (props.bold ? 'bold' : 'normal'),
      margin: (props: any) => (props.margin ? `${theme.spacing(2)}px 0px` : undefined),
   },
}));

const MyTypography = (props: Props) => {
   const { children, bold, className, ...rest } = props;
   const classes = useStyles(props);
   return (
      <Fragment>
         <Typography {...rest} className={clsx(classes.root, className)}>
            {children}
         </Typography>
      </Fragment>
   );
};

export default MyTypography;

import React from 'react';
import { format } from '@flasd/whatsapp-formatting';
import LinkifyIt from 'linkify-it';

const linkify = new LinkifyIt();

const LinkedFormattedText = ({ text }) => {
   if (text) {
      if (linkify.test(text)) {
         const parsedText = [];
         const links = linkify.match(text);

         if (links[0].index > 0) {
            const first = <span dangerouslySetInnerHTML={{ __html: format(text?.substring(0, links[0].index)) }} />;
            parsedText.push(first);
         }

         for (let i = 0; i < links.length; i++) {
            const link = (
               <a target='_blank' href={links[i].url} style={{ wordBreak: 'break-all' }}>
                  {links[i].text}
               </a>
            );
            parsedText.push(link);
            if (!!links[i + 1] && links[i].lastIndex !== links[i + 1].index) {
               const betweenLinks = (
                  <span
                     dangerouslySetInnerHTML={{
                        __html: format(text?.substring(links[i].lastIndex, links[i + 1].index)),
                     }}
                  />
               );
               parsedText.push(betweenLinks);
            } else {
               const last = (
                  <span
                     dangerouslySetInnerHTML={{
                        __html: format(text?.substring(links[i].lastIndex, text.length)),
                     }}
                  />
               );
               parsedText.push(last);
            }
         }
         return parsedText;
      } else {
         return <span dangerouslySetInnerHTML={{ __html: format(text) }} />;
      }
   }
};

export default LinkedFormattedText;

import React, { FC } from 'react';
import { OrderProps } from '../types';
import OrderList from '../../SharedComponents/OrderList';

interface AllOrdersProps {
   openOrderDetail: (order: OrderProps) => void;
}

const AllOrders: FC<AllOrdersProps> = ({ openOrderDetail }) => {
   return <OrderList openOrderDetail={openOrderDetail} />;
};

export default AllOrders;

import { ButtonBase, CircularProgress, Grid, Typography } from '@material-ui/core';
import AudiotapeIcon from '@material-ui/icons/Audiotrack';
import React, { useState } from 'react';
import Modal from '../Modal/modal';

const AudioPlayer = ({ url, name, isLoading, showPreview, disabled, onShow }) => {
   const [showModal, setShowModal] = useState(false);

   return (
      <Grid
         container
         direction='row'
         justifyContent='space-between'
         alignItems='flex-start'
         wrap='nowrap'
         style={{ backgroundColor: disabled ? 'lightgrey' : 'transparent' }}
      >
         <Grid container justifyContent='space-around' alignItems='center' direction='column'>
            {showPreview && (
               <ButtonBase
                  onClick={() => {
                     setShowModal(true);
                     onShow(true);
                  }}
                  style={{backgroundColor:'#dcdbdb', borderRadius:6}}
               >
                  <AudiotapeIcon style={{ fontSize: '3.5rem' }} color='action' />
               </ButtonBase>
            )}
            {name && (
               <Typography
                  style={{
                     fontSize: '1rem',
                     color: 'grey',
                     cursor: 'pointer',
                     marginTop: 5,
                  }}
                  onClick={() => setShowModal(true)}
               >
                  {name}
               </Typography>
            )}

            {isLoading && <CircularProgress />}
            <Modal
               open={showModal}
               onClose={() => {
                  setShowModal(false);
                  onShow(false);
               }}
               paperStyle={{
                  width: 'auto',
                  height: 'auto',
                  backgroundColor: 'transparent',
                  boxShadow: 'unset',
               }}
            >
               <audio controls autoPlay>
                  <source src={url} type='audio/mp3' />
                  Your browser does not support the audio element.
               </audio>
            </Modal>
         </Grid>
      </Grid>
   );
};

export default AudioPlayer;

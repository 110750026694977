import React from 'react';
import { Switch, Route, useRouteMatch, Redirect } from 'react-router-dom';

import Appearance from './Appearance';
import Settings from './Settings';
import GetStarted from './GetStarted';
import Installation from './Installation';
import Languages from './Languages';

const Router = () => {
   const { path, url } = useRouteMatch();

   return (
      <Switch>
         <Route
            exact
            path={path}
            render={() => {
               return <Redirect to={`${path}/appearance`} />;
            }}
         />
         <Route
            exact
            path={`${path}/appearance`}
            render={() => {
               return <Appearance url={url} />;
            }}
         />
         <Route
            exact
            path={`${path}/get-started`}
            render={() => {
               return <GetStarted url={url} />;
            }}
         />
         <Route
            exact
            path={`${path}/settings`}
            render={() => {
               return <Settings url={url} />;
            }}
         />
         <Route
            exact
            path={`${path}/installation`}
            render={() => {
               return <Installation url={url} />;
            }}
         />
         <Route
            exact
            path={`${path}/languages`}
            render={() => {
               return <Languages url={url} />;
            }}
         />
      </Switch>
   );
};

export default Router;

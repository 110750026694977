import React, { FC, useContext, useEffect, useState, useMemo } from 'react';
import { makeStyles } from '@material-ui/styles';
import { useForm } from 'react-hook-form';
import { LanguageContext } from '@helper/locale/langContext';
import { DISCOUNT_TYPES, ICartProductProps, ICustomerCartProps, IDiscountProps } from '../types';
import { BillProps } from '../../Store/types';
import CustomerCartProductListItem from './CustomerCartProductListItem';
import { Grid } from '@material-ui/core';
import FooterButtons from './FooterButtons';
import NoteCard from '../../../../../ChatItem/NoteCard';
import Input from '../../../../../../../../components/InputField';
import PaymentCard from '../../../../../ChatItem/PaymentCard';
import ButtonOK from '../../../../../../../../components/ButtonOK/buttonOk';
import cn from 'classnames';
import { currencyHandler } from '@helper/textUtils';
import ECommerceTabHeaderContext from '../../../../../../../../contexts/ECommerceTabHeaderContext';
import useWindowResize from '@hooks/useWindowResize';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import {
   GET_E_COMMERCE_PLATFORM_TYPE,
   GET_E_COMMERCE_STORE_CARGO_COMPANIES,
} from '@queries/ECommerce/Service/GraphQL/queries';
import { E_COMMERCE_PRODUCTS } from '@store/actions/ecommerce';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      paddingTop: 8,
   },
   addProductButtonContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   addProductButtonContainer2: {
      minHeight: 300,
      display: 'flex',
      justifyContent: 'center',
   },
   inputContainer: {
      marginTop: -15,
      marginBottom: -18,
   },
}));

interface shippingMethod {
   label: string;
   shippingFee: number | null;
}

interface shippingMethodOption {
   value: number;
   label: string;
   shippingFee: number | null;
}

interface CustomerCartProps {
   cart: any;
   isAbandonedCart?: boolean;
   setCart: React.Dispatch<React.SetStateAction<ICustomerCartProps>>;
   openAddProduct: () => void;
   closeCustomerCart: () => void;
}

const CustomerCart: FC<CustomerCartProps> = ({ cart, setCart, openAddProduct, closeCustomerCart, isAbandonedCart }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [products, setProducts] = useState<ICartProductProps[]>(cart.products || []);
   const [bill, setBill] = useState<BillProps>({
      //@ts-ignore
      status: 'payment',
      currency: '',
      subTotal: 0,
      tax: 0,
      billIncludesTaxes: false,
      shipping: 0,
      payment: 0,
      discount: {
         discountType: DISCOUNT_TYPES.FIXED_VALUE,
         discountValue: 0,
         discountReason: '',
      },
      total: 0,
   });
   const eCommerceType = useSelector((rootReducer: any) => rootReducer?.eCommerceReducer?.eCommerceType);
   const eCommerceShippingMethods = useSelector(
      (rootReducer: any) => rootReducer?.eCommerceReducer?.eCommerceShippingMethods,
   );
   const eCommercePaymentTypes = useSelector(
      (rootReducer: any) => rootReducer?.eCommerceReducer?.eCommercePaymentTypes,
   );

   const eCommercePaymentOptions = useMemo(() => {
      if (eCommercePaymentTypes?.types?.length > 0) {
         const filteredTypes = eCommercePaymentTypes.types.filter((type: any) => type.id && type.name);
         return [
            {
               value: -1,
               label: t.payment_types_eft,
               price: 0,
            },
            ...filteredTypes.map((type: any) => ({
               value: type.id,
               label: type.name + (type.price ? ` ${currencyHandler(bill?.currency)}${type.price}` : ''),
               price: parseInt(type.price) || 0,
               subPaymentTypes: type.subPaymentTypes
                  .filter((subType: any) => subType.id && subType.name)
                  .map((subType: any) => ({
                     value: subType.id,
                     label: subType.name + (subType.price ? ` ${currencyHandler(bill?.currency)}${subType.price}` : ''),
                     price: parseInt(subType.price) || 0,
                  })),
            })),
         ];
      } else {
         return [];
      }
   }, [eCommercePaymentTypes, bill?.currency]);

   const { height } = useWindowResize();

   const eCommerceShippingOptions = useMemo(() => {
      if (bill?.subTotal && eCommerceShippingMethods) {
         const subTotal = bill?.subTotal;
         const selectableMethods = eCommerceShippingMethods.filter(
            (method: any) =>
               subTotal > method?.minPrice && (method.maxPrice !== 0 ? subTotal < method?.maxPrice : true),
         );
         const options = selectableMethods.map((method: any) => ({
            value: method.id,
            label: `${method.name} ${currencyHandler(bill?.currency)}${method.price}`,
            shippingFee: method.price,
         }));
         if (eCommerceType === E_COMMERCE_PLATFORM_TYPE.SHOPIFY) {
            options.push({ label: 'Custom', shippingFee: null });
         }
         return options;
      } else {
         return [];
      }
   }, [bill?.subTotal, bill?.currency, eCommerceShippingMethods]);

   const { watch, control, setValue, resetField } = useForm({
      mode: 'all',
      defaultValues: {
         orderNote: '',
         selectShippingMethod: eCommerceShippingOptions[0] || null,
         selectPaymentType: eCommercePaymentOptions[0] || null,
         selectSubPaymentType:
            eCommercePaymentOptions[0]?.subPaymentTypes?.length > 0
               ? eCommercePaymentOptions[0]?.subPaymentTypes[0]
               : null,
         customShippingFee: '',
      },
   });

   useEffect(() => {
      if (eCommerceShippingOptions.length > 0 && eCommerceType === E_COMMERCE_PLATFORM_TYPE.TICIMAX) {
         setValue('selectShippingMethod', eCommerceShippingOptions[0]);
      }
   }, [eCommerceShippingOptions]);

   const watchOrderNote = watch('orderNote');
   const watchShippingMethod = watch('selectShippingMethod');
   const watchPaymentType = watch('selectPaymentType');
   const watchSubPaymentType = watch('selectSubPaymentType');
   const watchCustomShippingFee = watch('customShippingFee');
   useEffect(() => {
      if (watchShippingMethod?.shippingFee === null) {
         if (watchCustomShippingFee) {
            const fee = Number(watchCustomShippingFee);
            if (fee >= 0) {
               setBill((prevState) => ({
                  ...prevState,
                  shipping: fee,
               }));
            } else {
               setBill((prevState) => ({
                  ...prevState,
                  shipping: 0,
               }));
            }
         }
      } else {
         const fee = Number(watchShippingMethod?.shippingFee);
         setBill((prevState) => ({
            ...prevState,
            shipping: fee || 0,
         }));
      }
   }, [watchShippingMethod, watchCustomShippingFee]);

   useEffect(() => {
      setBill((prevState) => ({
         ...prevState,
         payment: watchSubPaymentType?.price || watchPaymentType?.price || 0,
      }));
   }, [watchPaymentType, watchSubPaymentType]);

   useEffect(() => {
      setValue('selectSubPaymentType', watchPaymentType?.subPaymentTypes?.[0] || null);
   }, [watchPaymentType]);

   useEffect(() => {
      setCart((prevState) => ({ ...prevState, products }));
      const findCurrency = products?.find((item) => item?.currency !== undefined)?.currency;

      const calculatedSubTotalAndTax = products.reduce(
         (acc, product) => {
            const { price, quantity, discount, discountedPrice: discountedPriceFromProps } = product;
            let discountedPrice = (discountedPriceFromProps == 0.0 ? null : discountedPriceFromProps) || price;
            if (discount?.discountType && discount?.discountValue) {
               if (discount?.discountType === DISCOUNT_TYPES.PERCENTAGE) {
                  discountedPrice =
                     discountedPriceFromProps ||
                     price - ((discountedPriceFromProps || price) * discount.discountValue) / 100;
               } else if (discount?.discountType === DISCOUNT_TYPES.FIXED_VALUE) {
                  if (discount.discountValue <= (discountedPriceFromProps || price)) {
                     discountedPrice = (discountedPriceFromProps || price) - discount.discountValue;
                  } else {
                     discountedPrice = 0;
                  }
               }
            }

            // const p = discountedPrice || price;
            // const priceWithoutTax = isPriceIncludeTaxes ? (p * 100) / (100 + taxRate) : p;
            // const tax = isPriceIncludeTaxes ? p - priceWithoutTax : (p * taxRate) / 100;
            return {
               subTotal: acc.subTotal + quantity * discountedPrice,
               // subTotal: acc.subTotal + quantity * priceWithoutTax,
               // tax: acc.tax + quantity * tax,
            };
         },
         // { subTotal: 0, tax: 0 }
         { subTotal: 0 },
      );
      setBill((prevState) => ({
         ...prevState,
         currency: findCurrency || '',
         ...calculatedSubTotalAndTax,
      }));
   }, [products]);

   useEffect(() => {
      if (watchShippingMethod?.shippingFee) {
         const option = eCommerceShippingOptions?.find((option: any) => option?.value === watchShippingMethod?.value);
         if (eCommerceShippingOptions?.find((option: any) => option?.value === watchShippingMethod?.value)) {
            setValue('selectShippingMethod', option);
         } else {
            resetField('selectShippingMethod');
         }
      }
   }, [eCommerceShippingOptions, products]);

   const toggleChargeTax = () => {
      setBill((prevState) => ({
         ...prevState,
         billIncludesTaxes: !prevState.billIncludesTaxes,
      }));
   };

   const applyDiscountToProduct = (productIdAndVariantId: string, discount: IDiscountProps) => {
      setProducts((prevState) => [
         ...prevState.map((product) =>
            product.productIdAndVariantId === productIdAndVariantId
               ? {
                    ...product,
                    discount,
                 }
               : product,
         ),
      ]);
   };

   const removeProductFromCart = (productIdAndVariantId: string) => {
      setProducts((prevState) => [
         ...prevState.filter((product) => product.productIdAndVariantId !== productIdAndVariantId),
      ]);
   };
   const changeProductQuantity = (
      action: 'increase' | 'decrease' | 'newValue',
      productIdAndVariantId: string,
      value?: number,
   ) => {
      setProducts((prevState) => [
         ...prevState.map((product) => {
            if (product.productIdAndVariantId === productIdAndVariantId) {
               let newValue;
               switch (action) {
                  case 'decrease':
                     newValue = product.quantity > 1 ? product.quantity - 1 : 1;
                     break;
                  case 'increase':
                     newValue = product.quantity + 1;
                     break;
                  case 'newValue':
                     newValue = value ? value : 1;
                     break;
                  default:
                     newValue = 1;
               }
               return {
                  ...product,
                  quantity: newValue,
               };
            } else {
               return product;
            }
         }),
      ]);
   };
   const increaseNumberOfProduct = (productIdAndVariantId: string) => {
      changeProductQuantity('increase', productIdAndVariantId);
   };
   const decreaseNumberOfProduct = (productIdAndVariantId: string) => {
      changeProductQuantity('decrease', productIdAndVariantId);
   };
   const onProductQuantityChange = (productIdAndVariantId: string, newValue: number) => {
      changeProductQuantity('newValue', productIdAndVariantId, newValue);
   };

   return (
      <div className={classes.container}>
         {products?.length > 0 &&
            products.map((product) => (
               <CustomerCartProductListItem
                  key={product.productIdAndVariantId}
                  product={product}
                  increaseNumberOfProduct={increaseNumberOfProduct}
                  decreaseNumberOfProduct={decreaseNumberOfProduct}
                  removeProductFromCart={removeProductFromCart}
                  onProductQuantityChange={onProductQuantityChange}
                  discountApplicability={eCommerceType == E_COMMERCE_PLATFORM_TYPE.SHOPIFY}
                  applyDiscountToProduct={applyDiscountToProduct}
                  disabled={isAbandonedCart}
               />
            ))}
         <div
            className={classes.addProductButtonContainer}
            style={{
               height: products?.length === 0 ? height - 385 + 55 : undefined,
               display: isAbandonedCart ? 'none' : undefined,
            }}
         >
            <ButtonOK onClick={openAddProduct} label={t['page_e_commerce_customer_customer_cart_add_product']} />
         </div>
         {products?.length > 0 && (
            <>
               {(eCommerceType === E_COMMERCE_PLATFORM_TYPE.SHOPIFY ||
                  eCommerceType === E_COMMERCE_PLATFORM_TYPE.TSOFT) &&
                  !isAbandonedCart && (
                     <NoteCard type={'orderNote'}>
                        <div className={classes.inputContainer}>
                           <Input
                              multiline
                              placeholder={t['page_e_commerce_customer_customer_cart_type_your_note']}
                              disabled={products.length === 0}
                              name='orderNote'
                              control={control}
                           />
                        </div>
                     </NoteCard>
                  )}
               {!isAbandonedCart && (
                  <NoteCard type='shippingMethod'>
                     <Grid container xs={12}>
                        <Grid xs={watchShippingMethod?.shippingFee === null ? 8 : 12} item style={{ paddingRight: 4 }}>
                           <div className={classes.inputContainer}>
                              <Input
                                 isSelect={true}
                                 disabled={products.length === 0 || isAbandonedCart}
                                 selectOptions={eCommerceShippingOptions}
                                 name='selectShippingMethod'
                                 control={control}
                              />
                           </div>
                        </Grid>
                        {watchShippingMethod?.shippingFee === null && (
                           <Grid xs={4} item style={{ paddingLeft: 4 }}>
                              <div className={classes.inputContainer}>
                                 <Input
                                    disabled={products.length === 0 || isAbandonedCart}
                                    name='customShippingFee'
                                    control={control}
                                    startAdornment={currencyHandler(bill.currency)}
                                 />
                              </div>
                           </Grid>
                        )}
                     </Grid>
                  </NoteCard>
               )}
               {eCommercePaymentTypes?.isOn && !isAbandonedCart && (
                  <NoteCard type='paymentType'>
                     <Grid container xs={12}>
                        <Grid xs={12} item style={{ paddingRight: 4 }}>
                           <div className={classes.inputContainer}>
                              <Input
                                 isSelect={true}
                                 disabled={products.length === 0 || isAbandonedCart}
                                 selectOptions={eCommercePaymentOptions}
                                 name='selectPaymentType'
                                 control={control}
                              />
                           </div>
                        </Grid>
                     </Grid>
                  </NoteCard>
               )}
               {watchPaymentType?.subPaymentTypes?.length > 0 && !isAbandonedCart && (
                  <NoteCard type='subPaymentType'>
                     <Grid container xs={12}>
                        <Grid xs={12} item style={{ paddingRight: 4 }}>
                           <div className={classes.inputContainer}>
                              <Input
                                 isSelect={true}
                                 disabled={products.length === 0 || isAbandonedCart}
                                 selectOptions={watchPaymentType?.subPaymentTypes}
                                 name='selectSubPaymentType'
                                 control={control}
                              />
                           </div>
                        </Grid>
                     </Grid>
                  </NoteCard>
               )}
               <PaymentCard
                  subTotal={bill.subTotal}
                  discount={bill.discount}
                  shipping={bill.shipping}
                  payment={bill.payment}
                  tax={bill.tax}
                  total={bill.total}
                  currency={bill.currency}
                  status={bill.status}
                  billIncludesTaxes={bill.billIncludesTaxes}
                  setBill={setBill}
                  editable={
                     (eCommerceType === E_COMMERCE_PLATFORM_TYPE.TSOFT ||
                        eCommerceType === E_COMMERCE_PLATFORM_TYPE.SHOPIFY ||
                        eCommerceType === E_COMMERCE_PLATFORM_TYPE.IDEASOFT ||
                        eCommerceType === E_COMMERCE_PLATFORM_TYPE.TICIMAX) &&
                     !isAbandonedCart
                  }
                  isNoteEditable={eCommerceType === E_COMMERCE_PLATFORM_TYPE.SHOPIFY && !isAbandonedCart}
                  toggleChargeTax={toggleChargeTax}
               />
               {cart.customer && (
                  <FooterButtons
                     selectedCustomerId={cart.customer.platformCustomerId}
                     products={products}
                     closeCustomerCart={closeCustomerCart}
                     orderNote={watchOrderNote}
                     sendInvoiceActive={
                        eCommerceType === E_COMMERCE_PLATFORM_TYPE.SHOPIFY ||
                        eCommerceType === E_COMMERCE_PLATFORM_TYPE.TICIMAX
                     }
                     paymentType={{
                        paymentId: watchPaymentType?.value,
                        subPaymentId: watchSubPaymentType?.value,
                        paymentPrice: bill.payment,
                     }}
                     shipping={{
                        shippingPrice: watchCustomShippingFee || watchShippingMethod?.shippingFee,
                        shippingTitle: watchShippingMethod?.label,
                        shippingId: watchShippingMethod?.value,
                     }}
                     bill={bill}
                     alreadyCreatedOrderId={isAbandonedCart && cart.orderId}
                  />
               )}
            </>
         )}
      </div>
   );
};

export default CustomerCart;

import { gql } from '@apollo/client';
import { CHAT_ALL_FIELDS_FRAGMENT } from '@queries/Chat/Service/GraphQL/Chat/fragments';
export const CREATED_TEAM_MESSAGE_SUBSCRIPTION = gql`
   subscription createdTeamChatSubscription($token: String!, $chat: ID!) {
      createdTeamMessage(data: { token: $token, chat: $chat }) {
         data {
            _id
            sender {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               setup
               createdAt
            }
            chat {
               _id
               type
               status
               participants {
                  _id
                  name
                  surname
                  username
                  phone
                  email
                  photo
                  type
                  status
                  country
                  state
                  address
                  postCode
                  city
                  whatsAppWebPhone
                  whatsAppBusinessPhone
                  facebookId
                  instagramId
                  setup
                  createdAt
               }
            }
            type
            content
            media
            readStatus
            # whatsAppWebId
            ack
            # whatsAppBusinessId
            personStatus
            createdAt
            sendingStatusId
         }
         success
         code
         message
      }
   }
`;

export const MESSAGE_TO_ME_SUBSCRIPTION = gql`
   ${CHAT_ALL_FIELDS_FRAGMENT}
   subscription messageToMeSubscription($token: String!, $me: ID!, $customer: ID) {
      messageToMe(data: { token: $token, me: $me, customer: $customer }) {
         data {
            _id
            sender {
               _id
               name
               surname
               username
               phone
               email
               photo
               type
               status
               country
               state
               address
               postCode
               city
               whatsAppWebPhone
               whatsAppBusinessPhone
               facebookId
               instagramId
               setup
               createdAt
            }
            chat {
               ...ChatAllFields
            }
            emailData {
               from
               to
               cc
               bcc
               envelope {
                  from
                  to
               }
            }
            type
            content
            contentHtml
            subject
            media
            autoCreate
            template_cards
            readStatus
            # whatsAppWebId
            ack
            # whatsAppBusinessId
            personStatus
            platformMessageId
            createdAt
            ##for the validation
            sendingStatusId
            surveyData {
               rating
            }
         }
         success
         code
         message
      }
   }
`;
export const CHANGE_MESSAGE_ACK_SUBSCRIPTION = gql`
   subscription changeMessageAckSubscription($token: String!, $me: ID!) {
      changeMessageAck(data: { token: $token, me: $me }) {
         data {
            _id
            chat {
               _id
               type
            }
            # whatsAppWebId
            ack
            # whatsAppBusinessId
         }
         success
         code
         message
      }
   }
`;

export const READ_MESSAGE_SUBSCRIPTION = gql`
   subscription readMessageSubscription($token: String!, $chat: ID!) {
      readMessage(data: { token: $token, chat: $chat }) {
         data {
            _id
            chat {
               _id
            }
         }
         success
         code
         message
      }
   }
`;

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { FC, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { LanguageContext } from '@helper/locale/langContext';
import FullAvatar from '@components/FullAvatar';
import { QnAPersonStatusType } from '../../util/types';
import { useSelector } from 'react-redux';
import { getQnaChatFilters } from '@store/reducers/questions';

const useStyles = makeStyles((theme) => ({
   root: {
      paddingBottom: 2,
      paddingTop: 2,
      // borderBottom: '1px solid lightgray',
      cursor: 'pointer',
      // paddingRight:10
   },
}));

const getColorFromStatus = (status: QnAPersonStatusType) => {
   switch (status) {
      case QnAPersonStatusType.WAITING:
         return '#1ac31a';
      case QnAPersonStatusType.SOLVED:
         return '#499cf2';
      case QnAPersonStatusType.SPAM:
         return '#577590';
      case QnAPersonStatusType.ARCHIVE:
         return '#F2B544';

      default:
         break;
   }
};
interface QuestionItemProps {
   myChat: any;
   teams: any;
   renderAgentPhoto?: boolean | undefined;
   isVisibleIconTooltip: boolean;
   setIsVisibleIconTooltip: React.Dispatch<React.SetStateAction<boolean>>;
}

const QuestionItem: FC<QuestionItemProps> = React.memo(
   ({ myChat, teams, renderAgentPhoto, isVisibleIconTooltip, setIsVisibleIconTooltip }) => {
      const classes = useStyles();
      const {
         lang: { shortTitle, translation: t },
      }: any = useContext(LanguageContext);
      const location = useLocation();
      const qnaChatFilters = useSelector(getQnaChatFilters);

      let textStatus = '';
      if (myChat.status === QnAPersonStatusType.WAITING) {
         textStatus = myChat.joinedPerson
            ? t?.page_chat_typo_filter_title_active
            : t?.page_chat_typo_filter_title_waiting;
      } else if (myChat.status === QnAPersonStatusType.ARCHIVE) {
         textStatus = t?.page_chat_typo_filter_title_closed;
      } else if (myChat.status === QnAPersonStatusType.SOLVED) {
         textStatus = t?.page_chat_typo_filter_title_resolved;
      } else if (myChat.status === QnAPersonStatusType.SPAM) {
         textStatus = t?.page_chat_typo_filter_title_spam;
      }

      const socialMediaIconColor = useMemo(() => {
         if (teams?.length === 1) return null;
         if (myChat.teams && Array.isArray(myChat.teams) && myChat.teams.length > 0) {
            if (qnaChatFilters?.teams?.value === 'ALL') {
               if (myChat.teams.length > 1) {
                  return '#333';
               } else {
                  const team = myChat?.teams[0];
                  return team?.color || null;
               }
            } else {
               const team = myChat?.teams.find((t: any) => t._id === qnaChatFilters?.teams?.value);
               if (team) return team?.color || null;
               if (!team) {
                  if (myChat.teams.length > 1) {
                     return '#333';
                  } else {
                     const t = myChat?.teams[0];
                     return t?.color || null;
                  }
               }
            }
         }
         return null;
      }, [myChat.teams, teams, qnaChatFilters?.teams?.value]);

      return (
         <Grid container justifyContent='flex-start' className={classes.root}>
            <Grid item xs={12} style={{ margin: '0px 6px', overflow: 'hidden' }}>
               <FullAvatar
                  socialMedia={myChat?.platformType}
                  socialMediaIconColor={socialMediaIconColor}
                  name={myChat?.ownerPerson?.name}
                  tag={myChat?.tag}
                  joinStatus={!myChat?.canJoin}
                  isBold={false}
                  lastSeen={moment(myChat?.lastQnA?.updatedAt).locale(shortTitle.toLowerCase()).fromNow(true)}
                  lastMessage={myChat?.lastQnA?.question}
                  chatId={myChat?._id.toString()}
                  chatColor={myChat?.color}
                  isSelectedItem={myChat?._id.toString() === location.pathname.split('/')[2]}
                  textStatus={textStatus}
                  textStatusColor={getColorFromStatus(myChat.status)}
                  agentPhoto={myChat.joinedPerson?.photo}
                  renderAgentPhoto={renderAgentPhoto}
                  setIsVisibleIconTooltip={setIsVisibleIconTooltip}
                  isVisibleIconTooltip={isVisibleIconTooltip}
               />
            </Grid>
         </Grid>
      );
   },
);

export default QuestionItem;

import React from 'react';
import { Grid, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MiscFile from './miscFile';
import AudioPlayer from './audioPlayer';
import VideoPlayer from './videoPlayer';
import ImageViewer from './imageViewer';
import { trimLongText } from '@helper/textUtils';

const useStyles = makeStyles((theme) => ({
   paper: {
      margin: 5,
      borderRadius: 10,
      overflow: 'hidden',
      height: (props) => props.paperHeight || '',
      width: (props) => props.paperWidth || '',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
   },
}));
const FilePlayer = ({ url, type, name, isLoading, onRemove, showPreview, disabled, ...props }) => {
   const slicedName = trimLongText(name, 10);
   const classes = useStyles(props);
   const getFileComponent = () => {
      switch (type?.split('/')[0]) {
         case 'image':
            return (
               <ImageViewer
                  showPreview={showPreview}
                  url={url}
                  name={slicedName}
                  isLoading={isLoading}
                  onClose={onRemove}
                  disabled={disabled}
                  {...props}
               />
            );
         case 'video':
            return (
               <VideoPlayer
                  type={type}
                  showPreview={showPreview}
                  url={url}
                  name={slicedName}
                  isLoading={isLoading}
                  onClose={onRemove}
                  disabled={disabled}
               />
            );
         case 'audio':
            return (
               <AudioPlayer
                  showPreview={showPreview}
                  url={url}
                  name={slicedName}
                  isLoading={isLoading}
                  onClose={onRemove}
                  disabled={disabled}
               />
            );
         default:
            return (
               <MiscFile
                  showPreview={showPreview}
                  url={url}
                  name={slicedName}
                  isLoading={isLoading}
                  onClose={onRemove}
                  disabled={disabled}
               />
            );
      }
   };
   return (
      <Paper elevation={3} className={classes.paper}>
         <Grid container direction='row' justifyContent='center' wrap='nowrap' alignItems='flex-end'>
            {getFileComponent()}
         </Grid>
      </Paper>
   );
};

export default FilePlayer;

import { useReducer, useEffect, useContext } from 'react';
import { GET_COUPON_CODE_QUERY } from '../queries/Products/Service/GraphQL/query';
import { useLazyQuery } from '@apollo/client';
import moment from 'moment-timezone';
import { LanguageContext } from '@helper/locale/langContext';

const COUPON_USED = 'COUPON_USED';
const COUPON_EXPIRED = 'COUPON_EXPIRED';
const COUPON_VALID = 'COUPON_VALID';
const COUPON_FREE = 'COUPON_FREE';
const COUPON_ERROR = 'COUPON_ERROR';
const SET_TOTAL_PRICE = 'SET_TOTAL_PRICE';

const COUPON_STATUS_ENUM = {
   USING: 'USING',
   USED: 'USED',
   EXPIRED: 'EXPIRED',
   OPEN: 'OPEN',
};

const couponReducer = (state, action) => {
   switch (action.type) {
      case COUPON_USED: {
         return {
            ...state,
            ...action.payload,
         };
      }
      case SET_TOTAL_PRICE: {
         return {
            ...state,
            totalPrice: action.payload.totalPrice,
         };
      }
      case COUPON_EXPIRED: {
         return {
            ...state,
            ...action.payload,
         };
      }
      case COUPON_VALID: {
         return {
            ...state,
            ...action.payload,
         };
      }
      case COUPON_FREE: {
         return {
            ...state,
            ...action.payload,
         };
      }
      case COUPON_ERROR: {
         return {
            ...state,
            ...action.payload,
         };
      }
      default:
         throw new Error(`Action ${action.type} not found`);
   }
};

export const useCoupon = (totalPrice) => {
   const [state, dispatch] = useReducer(couponReducer, {
      couponStatus: false,
      couponCodeText: '',
      couponErrorMessage: '',
      isPaying: true,
      couponDiscount: 0,
      totalPrice: totalPrice,
   });

   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const [getCouponCode, { loading, error, data: couponCodeData }] = useLazyQuery(GET_COUPON_CODE_QUERY, {
      fetchPolicy: 'network-only',
   });

   useEffect(() => {
      dispatch({ type: SET_TOTAL_PRICE, payload: { totalPrice: totalPrice } });
   }, [totalPrice]);

   useEffect(() => {
      if (error) {
         dispatch({
            type: COUPON_ERROR,
            payload: {
               couponStatus: false,
               couponCodeText: '',
               couponErrorMessage: t?.coupon_code_is_not_valid_error || error.message,
               isPaying: true,
               couponDiscount: 0,
               totalPrice: totalPrice,
            },
         });
         return;
      }

      if (couponCodeData?.couponCode?.data) {
         const couponCode = couponCodeData?.couponCode?.data;

         let couponDiscount = (totalPrice / 100) * couponCode?.percent;

         if (moment(couponCode?.endDate).valueOf() < moment().valueOf()) {
            dispatch({
               type: COUPON_EXPIRED,
               payload: {
                  couponStatus: false,
                  couponCodeText: '',
                  couponErrorMessage: t?.coupon_code_expired_error,
                  isPaying: true,
                  couponDiscount: 0,
                  totalPrice: totalPrice,
               },
            });
            return;
         }

         if (couponCode?.status === COUPON_STATUS_ENUM.USED) {
            dispatch({
               type: COUPON_USED,
               payload: {
                  couponStatus: false,
                  couponCodeText: '',
                  couponErrorMessage: t?.coupon_code_used_before_error,
                  isPaying: true,
                  couponDiscount: 0,
                  totalPrice: totalPrice,
               },
            });
            return;
         }

         if (couponCode?.status === COUPON_STATUS_ENUM.EXPIRED) {
            dispatch({
               type: COUPON_EXPIRED,
               payload: {
                  couponStatus: false,
                  couponCodeText: '',
                  couponErrorMessage: t?.coupon_code_expired_error,
                  isPaying: true,
                  couponDiscount: 0,
                  totalPrice: totalPrice,
               },
            });
            return;
         }

         if (couponCode?.status === COUPON_STATUS_ENUM.USING) {
            dispatch({
               type: COUPON_USED,
               payload: {
                  couponStatus: false,
                  couponCodeText: '',
                  couponErrorMessage: t?.coupon_code_using_other_customer_error,
                  isPaying: true,
                  couponDiscount: 0,
                  totalPrice: totalPrice,
               },
            });
            return;
         }

         if (totalPrice - couponDiscount <= 0) {
            // console.log('agaa free');
            dispatch({
               type: COUPON_FREE,
               payload: {
                  isPaying: false,
               },
            });
            return;
         }

         if (couponCode?.status === COUPON_STATUS_ENUM.OPEN) {
            dispatch({
               type: COUPON_VALID,
               payload: {
                  couponStatus: true,
                  couponCodeText: couponCode.code,
                  couponErrorMessage: '',
                  isPaying: true,
                  couponDiscount: couponDiscount,
                  totalPrice: totalPrice - couponDiscount,
               },
            });
            return;
         }
      }
   }, [couponCodeData, totalPrice, loading, error, t]);

   return [state, { loading, error, getCouponCode }];
};

import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Button } from '@material-ui/core';
import Marked from '../../../Welcome/assets/Marked';
import Failed from '../../../Welcome/assets/Failed';
import { LanguageContext } from '@helper/locale/langContext';
import { useHistory } from 'react-router-dom';
import { priceFormat } from '@helper/functions';
import { RenewalType } from '@types/enums';

const useStyles = makeStyles((theme) => ({
   root: {
      boxShadow: '0px 3px 6px #00000029',
      background: (props) =>
         props.isActive ? '#178CF9 0% 0% no-repeat padding-box' : '#FFFFFF 0% 0% no-repeat padding-box',
      borderRadius: 22,
      minWidth: 200,
      maxWidth: 350,
      fontFamily: 'Helvetica Neue',
      marginBottom: 8,
   },
   header: {
      padding: '1.2rem .8rem',
      borderBottom: '1px solid #F0F0F0',
      alignItems: 'center',
      maxHeight: 92,
      minHeight: 92,
   },
   startText: {
      color: (props) => (props.isActive ? 'white' : '#2D81CE'),
      fontSize: '1.2rem',
      letterSpacing: '0px',
   },
   priceText: {
      fontSize: '2.2rem',
      letterSpacing: '0px',
      color: (props) => (props.isActive ? 'white' : '#2A6EAD'),
   },
   perText: {
      fontSize: '.8rem',
      color: (props) => (props.isActive ? 'white' : '#2A6EAD'),
      letterSpacing: '0px',
   },
   featuresContainer: {
      padding: '1rem',
   },
   feature: {
      fontSize: '.75rem',
      color: (props) => (props.isActive ? 'white' : '#143F67'),
   },
   option: {
      fontSize: '.65rem',
      color: (props) => (props.isActive ? 'white' : '#3D82C3'),
   },
   btn: {
      background: (props) =>
         props.isActive ? '#FFFFFF 0% 0% no-repeat padding-box' : '#D6ECFF 0% 0% no-repeat padding-box',
      borderRadius: '13px',
      textTransform: 'capitalize',
      color: '#315276',
      '&:hover': {
         background: (props) => (props.isActive ? '#ffffffd9' : undefined),
      },
   },
   btnContainer: {
      padding: '1rem 0px',
   },
   visible: {
      display: (props) => (props.product.name === 'Special' && props.isTemp ? 'none' : undefined),
   },
}));

const Package = (props) => {
   const classes = useStyles(props);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const history = useHistory();

   const { product, mode, onBuy, btnText, isTemp } = props;

   return (
      <Box className={classes.root}>
         <Grid container className={classes.header}>
            <Grid item xs={4}>
               <Typography className={classes.startText}> {product.name} </Typography>
            </Grid>
            <Grid item xs={8}>
               <Grid container justifyContent='flex-end' alignItems='center' className={classes.visible}>
                  <Typography className={classes.priceText}>
                     {mode === RenewalType.YEARLY
                        ? priceFormat(product.yearlyPrice / 12)
                        : priceFormat(product.monthlyPrice)}{' '}
                  </Typography>
                  <Box marginLeft={2}>
                     <Typography className={classes.perText}> / {t?.user} </Typography>
                     <Typography className={classes.perText}> / {t?.month} </Typography>
                  </Box>
               </Grid>
            </Grid>
         </Grid>

         <Grid container spacing={1} className={classes.featuresContainer}>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     <Marked /> {t?.users}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}>{product.name === 'Special' ? t?.special : 1} </Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     <Marked /> {t?.channels}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}>
                     {' '}
                     {product.name === 'Special' ? t?.all_channels : t[product.channels]}{' '}
                  </Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     <Marked /> {t?.features}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}>
                     {' '}
                     {product.name === 'Special' ? t?.all_channels : t[product.channels]}{' '}
                  </Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     <Marked /> {t?.active_customer}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}> {product.clientCount || t?.unlimited} </Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     <Marked /> {t?.number_of_messages}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}>
                     {' '}
                     {product.name === 'Special' ? t?.unlimited : t[product.messages]}{' '}
                  </Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     <Marked /> {t?.team_chat}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}> {t[product.teamChat] || t?.free} </Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     <Marked /> {t?.live_chat}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}> {t?.yes} </Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     {product.name === 'Start' ? <Failed /> : <Marked />} {t?.whatsapp_business_api}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}>
                     {' '}
                     {product.name === 'Start' ? (
                        t?.no
                     ) : product.name === 'Grow' || product.name === 'Scale' ? (
                        <Typography className={classes.option}>{t?.yes}</Typography>
                     ) : (
                        t?.yes
                     )}{' '}
                  </Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     {product.name === 'Start' ? <Failed /> : <Marked />} {t?.page_chat_typo_profile_mail}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}> {product.name === 'Special' ? t?.yes : t?.soon} </Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     {product.name === 'Start' ? <Failed /> : <Marked />} Chatbot
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}> {product.name === 'Special' ? t?.yes : t?.soon} </Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     {product.name === 'Start' || product.name === 'Grow' ? <Failed /> : <Marked />} {t?.e_commerce}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}>{product.name === 'Special' ? t?.yes : t?.soon}</Typography>
               </Grid>
            </Fragment>
            <Fragment>
               <Grid item xs='9'>
                  <Typography className={classes.feature}>
                     <Marked /> {t?.support}
                  </Typography>
               </Grid>
               <Grid item xs='3' className={classes.option}>
                  <Typography className={classes.option}>
                     {' '}
                     {product.name === 'Special' ? '09:00 - 24:00' : '09:00 - 18:00'}{' '}
                  </Typography>
               </Grid>
            </Fragment>
            <Grid item xs={12} className={classes.btnContainer}>
               <Button
                  className={classes.btn}
                  color={props.active ? 'default' : 'primary'}
                  fullWidth
                  onClick={() => {
                     if (product.name === 'Special' && isTemp) {
                        window.open('mailto:support@qpien.com?subject=Qpien%20Special%20Package%20Request', '_blank');
                     } else if (props.isActive) return;
                     onBuy(
                        {
                           ...product,
                        },
                        mode,
                     );
                  }}
               >
                  {btnText}
               </Button>
            </Grid>
         </Grid>
      </Box>
   );
};

export default Package;

import { useMutation } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteFow from '@modules/Automation/FlowBots/components/DeleteFlow';
import { IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CircularProgress from '@mui/material/CircularProgress';
import {
   CREATE_FLOW_NODE_MUTATION,
   DELETE_FLOW_NODE_MUTATION,
   UPDATE_FLOW_NODE_MUTATION,
} from '@queries/Automation/mutation';
import { SET_CLICKED_NODE_ID } from '@store/actions/automation';
import React, { memo, useContext, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Node, useReactFlow } from 'reactflow';
import UngroupIcon from '../../../icons/UngroupIcon.svg';
import NoteIcon from '@mui/icons-material/StickyNote2Outlined';
import useDetachNodes from '../../App/DynamicGrouping/useDetachNodes';

interface DelayProps {
   id: string;
   isConnectable: boolean;
   data: {
      clickedNodeId: string;
      title: string;
      setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
      setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
      setHandleToUpdate: React.Dispatch<React.SetStateAction<string>>;
      flowBlocks: IFlowBlockTemplate[];
      isValidNode: Boolean;
      isCreation: Boolean;
      setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
      setIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
      setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
      setLeftNodes: React.Dispatch<React.SetStateAction<Node[]>>;
      setToGroup: React.Dispatch<React.SetStateAction<string[]>>;
   };
}

const Note = ({ id, data }: DelayProps) => {
   const {
      title,
      clickedNodeId,
      setIsNewNode,
      setClickedNodeId,
      flowBlocks,
      setClickedHandleId,
      setIsDraggable,
      isCreation,
      setContentNodes,
      setLeftNodes,
      setHandleToUpdate,
      setToGroup,
   } = data;
   const color = flowBlocks[0]?.fields[0]?.backgroundColor || '#FFF0C4';
   const useStyles = makeStyles(() => ({
      container: {
         position: 'relative',
         display: 'inline-block',
         padding: '5px 0',
      },
      iconDiv: {
         display: 'flex',
         borderRadius: 5,
         backgroundColor: '#FFFFFF',
      },
      innerIcons: {
         padding: '2px 5px',
         borderRadius: '5px',
         transition: 'background-color 0.3s ease',
         backgroundColor: 'transparent',
         '&:hover': {
            backgroundColor: '#E8E8E8',
         },
      },
      customHeader: {
         display: 'flex',
         gap: '5px',
         borderBottom: '0.1px solid #E8E8E8',
         padding: '0 5px 5px 5px',
      },

      text: {
         backgroundColor: 'transparent',
         fontSize: 9,
         border: 'none',
         padding: '5px 5px 10px 5px',
         width: '100%',
         outline: 'none',
         resize: 'none',
         overflow: 'hidden',
         whiteSpace: 'pre-wrap',
         maxWidth: '100%',
      },
      addText: {
         padding: '5px',
         margin: '5px',
         textAlign: 'center',
         fontSize: 7,
         color: '#AFAFAF',
      },
      nodeContainer: {
         width: '165px',
         padding: '5px 0 5px 0',
         height: '100%',
         borderRadius: '5px',
         background: 'rgba(255, 255, 255, 0.6)',
         boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
         transition: 'border 0.3s',
         border: `1px solid ${color}`,
      },

      nodeContainerHovered: {
         border: '1px solid #157cfc',
         background: 'rgba(255, 255, 255, 0.6)',
      },
   }));
   const classes = useStyles();

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const reactFlow = useReactFlow();
   const nodeList = reactFlow.getNodes();
   const [isDelete, setIsDelete] = useState(false);

   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);

   const [deleteFlowNode] = useMutation(DELETE_FLOW_NODE_MUTATION);
   const handleDeleteFlowNode = async (itemId: String) => {
      setIsDelete(true);
      try {
         await deleteFlowNode({
            variables: {
               input: {
                  _id: itemId,
               },
            },
            onCompleted: () => {
               setLeftNodes((prev) => prev.filter((node) => node.id !== itemId));
               setContentNodes((prev) => prev.filter((node) => node.id !== itemId));

               deleteElements({ nodes: [{ id }] });
               setIsDelete(false);
            },
         });
      } catch (error) {
         console.error('Error deleting node:', error);
      }
   };

   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         let numRows = 0;
         let consecutiveChars = 0;

         for (let i = 0; i < content.length; i++) {
            if (content[i] !== '\n') {
               consecutiveChars++;
               if (consecutiveChars === 29) {
                  numRows++;
                  consecutiveChars = 0;
               }
            } else {
               consecutiveChars = 0;
            }
         }

         const enters = (content.match(/\n/g) || []).length;

         numRows += enters;

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: content.length < 800 ? numRows : numRows + 2, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };
   // Delete node
   const { deleteElements } = useReactFlow();

   const [isHover, setIsHover] = useState(false);

   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);

   useEffect(() => {
      setIsHover(id === clickedId);
   }, [data, id, clickedId]);

   const limitedTitle = title.length > 21 ? `${title.slice(0, 19)}...` : title;

   const [isDuplicating, setIsDuplicating] = useState<boolean>(false);
   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);

   const [createFlowNode] = useMutation(CREATE_FLOW_NODE_MUTATION);
   const handleCreateNode = async () => {
      setIsDuplicating(true);
      try {
         const currentNode = nodeList.find((node) => node.id === id) as any;

         const currentBlocks = currentNode.data?.flowBlocks.map((current: any) => ({
            flowBlockTemplateId: current.templateId,
            channelType: current.channelType,
            fields: current.fields.map((field: any) => {
               const filteredField = Object.fromEntries(
                  Object.entries(field).map(([key, value]) => {
                     // Exclude specific keys and values
                     const excludeKeys = [
                        'target_on_reply',
                        'target_on_not_reply',
                        'target_on_invalid_input',
                        'targetOnSuccess',
                        'targetOnFailure',
                     ];

                     if (excludeKeys.includes(key)) {
                        return [key, value];
                     }

                     // Exclude 'target' key from informativeButtons and quickReplies arrays
                     if (key === 'informativeButtons' && Array.isArray(value)) {
                        const updatedButtons = value.map((button) => {
                           if (button && button.target) {
                              const { target, ...buttonWithoutTarget } = button;
                              return buttonWithoutTarget;
                           }
                           return button;
                        });
                        return [key, updatedButtons];
                     }

                     if (key === 'quickReplies' && Array.isArray(value)) {
                        const updatedReplies = value.map((reply) => {
                           if (reply && reply.target) {
                              const { target, ...replyWithoutTarget } = reply;
                              return replyWithoutTarget;
                           }
                           return reply;
                        });
                        return [key, updatedReplies];
                     }

                     return [key, value];
                  }),
               );

               const finalFilteredField = Object.fromEntries(
                  Object.entries(filteredField).filter(([key, value]) => value !== null),
               );

               return finalFilteredField;
            }),
         }));

         if (currentNode) {
            const sourceParent = currentNode?.parentNode;
            const parentPosition = nodeList.find((node) => node.id === sourceParent)?.position;
            let childPosition = null;
            const newPosition = { x: currentNode.position.x + 200, y: currentNode.position.y };
            if (parentPosition) {
               childPosition = {
                  x: newPosition.x + parentPosition?.x - 25,
                  y: newPosition.y + parentPosition?.y - 25,
               };
            }

            const position = childPosition ? childPosition : newPosition;

            createFlowNode({
               variables: {
                  input: {
                     coordinates: position,
                     customer: person.customer._id,
                     flowBot: currentNode.flowBot,
                     templateId: currentNode.templateId,
                     title: t.automation_duplicate_copy + ' - ' + currentNode.data?.title,
                     flowBlocksToBeCreated: currentBlocks,
                     groupId: currentNode.parentNode,
                  },
               },
               onCompleted: async (res) => {
                  const duplicatedNode = res.createFlowNode.data;

                  const sourceParent = currentNode?.parentNode;
                  const parentPosition = nodeList.find((node) => node.id === sourceParent)?.position;
                  let childPosition = null;
                  const newPosition = duplicatedNode.coordinates;
                  if (parentPosition) {
                     childPosition = {
                        x: newPosition.x - parentPosition?.x + 25,
                        y: newPosition.y - parentPosition?.y + 25,
                     };
                  }

                  const newTargetNode = {
                     id: duplicatedNode._id,
                     type: duplicatedNode.type,
                     flowBot: duplicatedNode.flowBot,
                     templateId: duplicatedNode.templateId,
                     parentNode: duplicatedNode.groupId,
                     title: duplicatedNode.title,
                     data: {
                        title: duplicatedNode.title,
                        clickedNodeId,
                        flowBlocks: duplicatedNode.flowBlocks,
                        setIsDraggable: setIsDraggable,
                        setClickedNodeId: setClickedNodeId,
                        setLeftNodes: setLeftNodes,
                        setContentNodes: setContentNodes,
                        isValidNode: duplicatedNode.flowBlocks.length > 0 ? true : false,
                        setClickedHandleId: setClickedHandleId,
                        setIsNewNode: setIsNewNode,
                        setHandleToUpdate: setHandleToUpdate,
                        setToGroup: setToGroup,
                     },
                     position: childPosition ? childPosition : duplicatedNode.coordinates,
                  };
                  setContentNodes((prevNodes) => [...prevNodes, newTargetNode]);
                  setLeftNodes((prevNodes) => [...prevNodes, newTargetNode]);

                  setIsDuplicating(false);
               },
            });
         }
      } catch (error) {
         console.error('Error creating node:', error);
      }
   };

   const dispatch = useDispatch();
   const handleClicked = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      isCreation && event.stopPropagation();
      if (botStatus === 'draft') {
         setClickedNodeId(id);
         dispatch({ type: SET_CLICKED_NODE_ID, payload: id });
      }
   };

   const currentNode = reactFlow.getNode(id);
   useEffect(() => {
      if (currentNode?.selected) {
         setIsHover(true);
      }
   }, [currentNode]);

   const detachNodes = useDetachNodes();
   const [updateFlowNode] = useMutation(UPDATE_FLOW_NODE_MUTATION);
   const handleUpdateFlowNodeGroup = async (itemId: string, groupId: string | null) => {
      try {
         await updateFlowNode({
            variables: {
               input: {
                  _id: itemId,
                  groupId: groupId,
               },
            },
         });
      } catch (error) {
         console.error('Error updating node:', error);
      }
   };
   const handleDetachNode = () => {
      const parentNode = currentNode?.parentNode;
      const allChildNodes = nodeList.filter(
         (node) => node.parentNode === parentNode && node.parentNode !== undefined && node.parentNode !== null,
      );
      const allChildNodeIds = nodeList.filter((node) => node.parentNode === parentNode).map((chdNode) => chdNode.id);
      if (allChildNodeIds.length > 2) {
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         const remainingNodeIds = allChildNodes.filter((node) => node.id !== id).map((chdNode) => chdNode.id);
         setToGroup(remainingNodeIds);
         //Update the detached node with groupId to null
         handleUpdateFlowNodeGroup(id, null);
      } else {
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         allChildNodeIds.forEach((sn) => handleUpdateFlowNodeGroup(sn, null));
      }
   };
   const handleDeleteNode = async () => {
      const parentNode = currentNode?.parentNode;
      const allChildNodes = nodeList.filter(
         (node) => node.parentNode === parentNode && node.parentNode !== undefined && node.parentNode !== null,
      );
      const allChildNodeIds = nodeList.filter((node) => node.parentNode === parentNode).map((chdNode) => chdNode.id);
      if (allChildNodeIds.length > 2) {
         await handleDeleteFlowNode(id);
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
         const remainingNodeIds = allChildNodes.filter((node) => node.id !== id).map((chdNode) => chdNode.id);
         setToGroup(remainingNodeIds);
      } else {
         await handleDeleteFlowNode(id);
         detachNodes(allChildNodeIds, parentNode, setContentNodes, setLeftNodes);
      }
   };

   const onDetach = () => handleDetachNode();
   const onDelete = () => handleDeleteNode();

   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (id === clickedId || currentNode?.selected ? setIsHover(true) : setIsHover(false))}
         className={classes.container}
      >
         {isHover && (
            <div
               style={{
                  position: 'absolute',
                  top: '-20px',
                  right: currentNode?.parentNode ? '33%' : '40%',
                  cursor: 'pointer',
               }}
               onMouseEnter={() => setIsDraggable(false)}
               onMouseLeave={() => setIsDraggable(true)}
            >
               {botStatus === 'draft' && (
                  <div className={classes.iconDiv}>
                     {isDuplicating ? (
                        <div className={classes.innerIcons}>
                           <CircularProgress size={13} />
                        </div>
                     ) : (
                        <div className={classes.innerIcons} onClick={() => handleCreateNode()}>
                           <ContentCopyIcon style={{ fontSize: '12px', fontWeight: 800 }} />
                        </div>
                     )}

                     {!isDelete ? (
                        <div className={classes.innerIcons} onClick={() => setShowDeleteModal(true)}>
                           <DeleteIcon style={{ fontSize: '13px', color: 'red' }} />
                        </div>
                     ) : (
                        <div className={classes.innerIcons}>
                           <CircularProgress size={13} />
                        </div>
                     )}

                     {currentNode?.parentNode && (
                        <div className={classes.innerIcons} onClick={onDetach}>
                           <img style={{ width: 12, height: 12 }} src={UngroupIcon} alt='UngroupIcon'></img>
                        </div>
                     )}
                  </div>
               )}
            </div>
         )}
         <div
            className={`${classes.nodeContainer} ${isHover ? classes.nodeContainerHovered : ''}`}
            onClick={(event) => handleClicked(event)}
            style={{ backgroundColor: color }}
         >
            <div className={classes.customHeader}>
               <NoteIcon style={{ fontSize: '15px' }} />
               <div style={{ fontSize: 12 }}>{limitedTitle}</div>
            </div>
            {flowBlocks[0]?.fields[0].note && flowBlocks[0]?.fields[0]?.note?.length > 0 ? (
               <textarea
                  value={flowBlocks[0]?.fields[0]?.note}
                  className={classes.text}
                  readOnly
                  {...calculateTextareaSize(flowBlocks[0]?.fields[0]?.note)}
               />
            ) : (
               <div className={classes.addText}>{t.atomation_flow_add_content}</div>
            )}
         </div>
         <DeleteFow
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            onComplate={onDelete}
            text={t.automation_flow_delete_step_warning}
         />
      </div>
   );
};

export default memo(Note);

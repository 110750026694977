import React, { useContext } from 'react';
import { Fade, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { LanguageContext } from '@helper/locale/langContext';
import CreateIcon from '@material-ui/icons/Create';
import useBoolean from '@hooks/useBoolean';
import { priceFormat } from '@helper/functions';
import { useSelector } from 'react-redux';
import { AddOnName, PACKAGE_RENEWAL_TYPE } from '../../../../types/enums';

const useStyles = makeStyles((theme) => ({
   item: {
      backgroundColor: (props) => (props.active ? theme.chatApp.general.pallet.passiveStructureAzure : '#F1F6FE'),
      padding: '10px 20px',
      borderRadius: 10,
      margin: '10px 0px',
      fontWeight: 'bold',
      color: (props) => (props.active ? '#fff' : theme.chatApp.general.pallet.passiveStructureBlue),
      transition: 'all .7s',
      '&:hover': {
         cursor: 'pointer',
         opacity: '0.9',
      },
      minHeight: 60,
      transitionDuration: '0.4s',
   },
   closeIcon: {
      position: 'relative',
      top: -20,
      right: -30,
      background: '#fff',
      width: 25,
      height: 25,
   },
   priceTitle: {
      color: (props) => (props.active ? '#fff' : '#757575'),
   },
   hint: {
      color: (props) => (props.active ? '#eee' : 'grey'),
   },
}));

const PackageItem = (props) => {
   const { active, item, hint, onCancelPackage, isProduct, isAddOn, product } = props;
   const classes = useStyles(props);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const isProductMonthly = product?.periodMode === PACKAGE_RENEWAL_TYPE.MONTHLY; // only current main packages
   const { value: onMouseHover, on, off: offHover } = useBoolean();
   const currentPackagePeriodMode = useSelector((state) => state.personReducer.person.customer.package.periodMode);

   const getTitle = (product) => {
      let title = t?.main_plan + '/ ' + product?.name || item?.name;
      if (product?.periodMode === 1) {
         title += '/ ' + t?.yearly;
      } else {
         title += '/ ' + t?.monthly;
      }
      return title;
   };

   const getTranslateText = (name) => {
      if (name?.includes(AddOnName.USER)) return t?.user;
      else if (name?.includes('customer')) return t?.active_customer;
      else return name;
   };

   const getAddOnTitle = (item) => {
      let title = t?.add_on_package_title + '/' + getTranslateText(item?.name);
      if (currentPackagePeriodMode === PACKAGE_RENEWAL_TYPE.MONTHLY) {
         title += '/ ' + t?.monthly;
      } else {
         title += '/ ' + t?.yearly;
      }
      return title;
   };

   // Don't show active customer on the active plans
   if (item?.name === AddOnName.ACTIVE_CUSTOMER || item?.name === AddOnName.ACCOUNT_BALANCE) {
      return null;
   }

   return (
      <Grid
         onMouseEnter={on}
         onMouseLeave={offHover}
         container
         className={classes.item}
         justifyContent='space-between'
         alignItems='center'
         xs={12}
      >
         <Fade in={!active && onMouseHover && (item?.name === AddOnName.USER || item?.name === AddOnName.WHATSAPP_BUSINESS)}>
            <Grid item xs={12} container style={{ height: 0 }} justifyContent='flex-end'>
               <Tooltip title='Cancel Package'>
                  <IconButton
                     className={classes.closeIcon}
                     disableFocusRipple
                     onClick={() =>
                        onCancelPackage({
                           type: item.name,
                        })
                     }
                  >
                     <CreateIcon color='primary' style={{ fontSize: 16 }} />
                  </IconButton>
               </Tooltip>
            </Grid>
         </Fade>
         <Grid item xs={6}>
            <Typography style={{ fontWeight: 'bold' }} className={classes.titleBox}>
               {isProduct ? getTitle(product) : isAddOn ? getAddOnTitle(item) : item?.name}
            </Typography>
            {hint && (
               <Typography className={classes.hint} variant='caption'>
                  {hint}
               </Typography>
            )}
         </Grid>
         <Grid item container justifyContent='flex-end' xs={6}>
            <Typography className={classes.priceTitle} style={{ fontWeight: 'bold' }}>
               {isAddOn
                  ? currentPackagePeriodMode === PACKAGE_RENEWAL_TYPE.YEARLY // paket yıllık ise
                     ? `${priceFormat(item?.price / 12)} * ${item?.quantity} * 12 = ${priceFormat(
                          item?.quantity * item?.price,
                       )}`
                     : `${priceFormat(item?.price)} * ${item?.quantity} = ${priceFormat(item?.quantity * item?.price)}` // AYLIK İSE
                  : isProduct
                  ? isProductMonthly
                     ? priceFormat(product?.price)
                     : `${priceFormat(product?.price / 12)} * 12 = ${priceFormat(product?.price)}`
                  : item?.price}
            </Typography>
         </Grid>
      </Grid>
   );
};

export default PackageItem;

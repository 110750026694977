import React, { useState, useEffect, useContext } from 'react';
import { Box, Grid, Typography, Container, Stepper, Step, StepLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Logo from './assets/Logo';
import ActiveDot from './assets/ActiveDot';
import Dot from './assets/Dot';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import Package from './Package';
import Payment from './Payment';
import Business from './Business';
import Result from './Result';
import { useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import LangSelectBox from '@components/LangSelectBox';

const useStyles = makeStyles((theme) => ({
   root: {
      background: '#F7F7FA',
      minHeight: '100vh',
   },
   nav: {
      background: 'white',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '1.5rem',
   },
   welcomeText: {
      fontSize: 30,
      fontWeight: 'regular',
      color: '#326290',
   },
   container: {
      marginTop: '1rem',
      maxWidth: '1130px',
   },
   label: {
      color: '#326290',
      fontWeight: 'regular',
      '&:active': {
         color: '#326290',
      },
   },
   main: {
      borderRadius: '8px',
      width: '100%',
      minHeight: '400px',
      background: '#FFFFFF',
      boxShadow: '0px 1px 3px #00000029',
   },
   title: {
      padding: '1.5rem 2rem',
      borderBottom: '1px solid #DEDEDE',
      color: '#72889D',
   },
   pagesContainer: {
      paddingTop: '1rem',
      paddingBottom: '1rem',
   },
   actionsContainer: {
      padding: '1rem 0px',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row-reverse',
   },
   nextBtn: {
      background: '#178CF9',
      paddingLeft: '3rem',
      paddingRight: '3rem',
      textTransform: 'capitalize',
   },
   backBtn: {
      border: '1px solid #B9D0E5',
      background: '#F7F7FA',
      paddingLeft: '3rem',
      paddingRight: '3rem',
      textTransform: 'capitalize',
      color: '#B9D0E5',
      boxShadow: 'none',
   },
}));

function QontoStepIcon(props) {
   const { active, completed } = props;
   return <div>{active || completed ? <ActiveDot /> : <Dot />}</div>;
}

const Welcome = () => {
   const classes = useStyles();
   const [activeStep, setActiveStep] = useState(0);
   const { lang } = useContext(LanguageContext);
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const t = lang?.translation;
   const steps = [
      t?.welcome_steps_your_business,
      t?.welcome_steps_your_plan,
      t?.welcome_steps_your_free_trial,
      t?.welcome_steps_your_get_started,
   ];
   const location = useLocation();
   const history = useHistory();
   const [paymentSuccess, setPaymentSuccess] = useState({});
   const [packageOptions, setPackageOptions] = useState();

   const isShopifyWelcome =
      !!person?.customer?.package?.paymentFromOtherChannel?.confirmationUrl &&
      Object.keys(person?.customer?.package?.paymentFromOtherChannel)?.length === 1;

   const showWelcome = (person && Number(person.type) === 2 && isShopifyWelcome) || person.customer.isANewCustomer;

   useEffect(() => {
      if (!showWelcome) {
         return history.push('/');
      } else if (isShopifyWelcome && location.pathname !== '/welcome/get-started') {
         return history.push('/welcome/get-started');
      }
   }, [showWelcome, history]);

   useEffect(() => {
      if (location.pathname === '/welcome/get-started') {
         setActiveStep(3);
      } else if (location.pathname === '/welcome') {
         setActiveStep(0);
      } else if (location.pathname === '/welcome/your-plan') {
         setActiveStep(1);
      } else if (location.pathname === '/welcome/free-trial') {
         setActiveStep(2);
      } else {
         // do some stuffs
      }
   }, [location.pathname]);

   const handleRoute = (pageNum) => {
      switch (pageNum) {
         case 1:
            history.push('/welcome/your-plan');
            break;
         case 2:
            history.push('/welcome/free-trial');
            break;
         case 3:
            history.push('/welcome/get-started');
            break;
         case 0:
            history.push('/welcome');
            break;
         default:
            history.push('/welcome');
            break;
      }
   };

   const handleNext = () => {
      setActiveStep((prevActiveStep) => {
         const nextStep = prevActiveStep + 1;
         handleRoute(nextStep);
         return nextStep;
      });
   };

   const handleBack = () => {
      setActiveStep((prevActiveStep) => {
         const prevStep = prevActiveStep - 1;
         handleRoute(prevStep);
         return prevStep;
      });
   };

   return (
      <Box className={classes.root}>
         <Grid container className={classes.nav}>
            <Logo />
            <Typography className={classes.welcomeText}>{t?.welcome_to_qpien}</Typography>
            <LangSelectBox />
         </Grid>
         <Container className={classes.container}>
            <Stepper activeStep={activeStep} alternativeLabel style={{ background: 'inherit' }}>
               {steps.map((label) => (
                  <Step key={label}>
                     <StepLabel classes={{ label: classes.label }} StepIconComponent={QontoStepIcon}>
                        {label}
                     </StepLabel>
                  </Step>
               ))}
            </Stepper>
            <Box className={classes.pagesContainer}>
               <Switch>
                  <Route exact path={'/welcome'}>
                     <Business nextPageHandler={handleNext} />
                  </Route>
                  <Route exact path={'/welcome/your-plan'}>
                     <Package
                        nextPageHandler={handleNext}
                        prevPageHandler={handleBack}
                        getPackageOptions={(packageOptions) => {
                           setPackageOptions(packageOptions);
                        }}
                     />
                  </Route>
                  <Route exact path={'/welcome/free-trial'}>
                     <Payment
                        nextPageHandler={handleNext}
                        prevPageHandler={handleBack}
                        checkIfSuccess={(isSuccess) => {
                           setPaymentSuccess(isSuccess);
                        }}
                        packageOptions={packageOptions}
                     />
                  </Route>
                  <Route exact path={'/welcome/get-started'}>
                     <Result
                        setPaymentSuccess={setPaymentSuccess}
                        prevPageHandler={handleBack}
                        paymentSuccess={paymentSuccess.isSuccess}
                        purchasedProduct={paymentSuccess.purchasedProduct}
                        errorMessage={paymentSuccess.message}
                        isFreeTrial={paymentSuccess.isFreeTrial}
                     />
                  </Route>
               </Switch>
            </Box>
         </Container>
      </Box>
   );
};

export default Welcome;

import React, { FC, Fragment, useContext, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import { Typography } from '@material-ui/core';
import TableContainer from '@material-ui/core/TableContainer';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import { LanguageContext } from '../../../../helper/locale/langContext';
import { MyTheme } from '../../../../styles/config';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classNames from 'classnames';
import AreYouSure from './areYouSure';
import { getPerson } from '@store/reducers/person';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery, useMutation } from '@apollo/client';
import { CONNECT_ECOMMERCE_PLATFORM_MUTATION } from '@queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import { API_URL } from '@helper/config';
import { DELETE_STORE, E_COMMERCE_PRODUCTS } from '@store/actions/ecommerce';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';
import { GET_E_COMMERCE_CONNECTION_QUERY } from '@queries/Settings/Service/GraphQL/ECommercePlatform/query';
import ActionIconButton from "@modules/Settings/SettingSpace/ActionIconButton";

const useStyles = makeStyles((theme: MyTheme) => ({
   table: {
      minWidth: 700,
   },
   width6x: {
      width: '60%',
   },
   width2x: {
      width: '20%',
   },
   text: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   greenText: {
      color: '#28a745',
   },
   orangeText: {
      color: '#e5ad06',
   },
   redText: {
      color: '#dc3545',
   },
}));

const StyledTableCell = withStyles((theme: any) => ({
   head: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
   },
   body: {
      fontSize: '1.1rem',
   },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
   root: {
      backgroundColor: 'transparent',
   },
}))(TableRow);

interface BodyContentProps {
   data: object[];
   deleteECommercePlatform: any;
   setLoading: any;
}

const Index: FC<BodyContentProps> = ({ data, deleteECommercePlatform, setLoading }) => {
   const [connectECommercePlatformMutation, {}] = useMutation(CONNECT_ECOMMERCE_PLATFORM_MUTATION);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const me = useSelector(getPerson);
   const classes = useStyles();
   const dispatch = useDispatch();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [storeIdOfSelectedMenu, setStoreIdOfSelectedMenu] = useState<null | string>();
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [disconnectModalVisible, setDisconnectModalVisible] = useState<boolean>(false);
   const [deleteModalVisible, setDeleteModalVisible] = useState<boolean>(false);
   const [storeIdToTransact, setStoreIdToTransact] = useState<string>('');
   const person = useSelector((reducer: any) => reducer.personReducer.person);
   const token = useSelector((rootReducer: any) => rootReducer.personReducer.token);
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      const { storeId } = event.currentTarget.dataset;
      setStoreIdOfSelectedMenu(storeId);
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setStoreIdOfSelectedMenu(null);
      setAnchorEl(null);
   };

   const loginUrlHandler = (store: any) => {
      getLoginUrl({
         variables: {
            type: E_COMMERCE_PLATFORM_TYPE.WOOCOMMERCE,
            customer: person.customer._id,
            storeUrl: store.store_url,
            metaData: { storeName: store.storeName, store_url: store.store_url },
         },
      });
   };

   const connectToWooCommerce = (store: any) => {
      loginUrlHandler({
         storeName: store.storeName,
         store_url: store.partnerDomain,
      });
   };

   const handleClickWooCommerceLogin = async (url: string | URL | undefined) => {
      window.open(url, '_blank');
      // const win = window.open(url, '_blank');
      // win.focus();
   };
   const [getLoginUrl, { data: loginUrlData }] = useLazyQuery(GET_E_COMMERCE_CONNECTION_QUERY, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
         // setLoading(true);
         const url = data.getLoginUrl.data.url;
         handleClickWooCommerceLogin(url);
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const handleConnectRequest = (id: string) => {
      setStoreIdOfSelectedMenu(null);

      connectECommercePlatformMutation({
         variables: {
            customerId: person.customer._id,
            eCommerceStoreId: id,
         },
      })
         .then((e) => {
            enqueueSnackbar('İşlem başarıyla tamamlandı', {
               variant: 'success',
               autoHideDuration: 2000,
            });
         })
         .catch((error) => {
            console.log('HATA', error);
            return '';
         });
   };

   const handleDisconnectRequest = (id: string) => {
      setStoreIdOfSelectedMenu(null);
      setStoreIdToTransact(id);
      setDisconnectModalVisible(true);
   };

   const handleDeleteRequest = (id: string) => {
      setStoreIdOfSelectedMenu(null);
      setStoreIdToTransact(id);
      setDeleteModalVisible(true);
   };

   const disconnectFromStore = (id: string) => {
      setStoreIdOfSelectedMenu(null);
      // disconnectECommercePlatformMutation({
      //    variables: {
      //       customerId: person.customer._id,
      //       eCommerceStoreId: id,
      //    },
      // })
      //    .then((e) => {

      //       enqueueSnackbar('İşlem başarıyla tamamlandı', {
      //          variant: 'success',
      //          autoHideDuration: 2000,
      //       });
      //    })
      //    .catch((error) => {
      //       console.log('HATA', error);
      //       return '';
      //    });

      setDisconnectModalVisible(false);
   };

   const deleteStore = (id: string) => {
      setLoading(true);
      deleteECommercePlatform({
         variables: {
            type: E_COMMERCE_PLATFORM_TYPE.WOOCOMMERCE,
            metaData: { eCommerceStoreId: id, customer: me.customer._id },
         },
      })
         .then(() => {
            setLoading(false);
            dispatch({
               type: DELETE_STORE,
               payload: { eCommerceProducts: null },
            });

            enqueueSnackbar('İşlem başarıyla tamamlandı', {
               variant: 'success',
               autoHideDuration: 2000,
            });
         })
         .catch((error: any) => {
            setLoading(false);
            enqueueSnackbar(error.message, {
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });

      setStoreIdOfSelectedMenu(null);

      setDeleteModalVisible(false);
      // Remove HTML Element
      const tableRow = document.getElementById(`table-row-${id}`);
      if (tableRow) {
         tableRow.style.display = 'none';
      }
   };

   return (
      <Fragment>
         <AreYouSure
            isVisible={disconnectModalVisible}
            actionType='disconnect'
            onCancel={() => setDisconnectModalVisible(false)}
            onSubmit={() => disconnectFromStore(storeIdToTransact)}
         />
         <AreYouSure
            isVisible={deleteModalVisible}
            actionType='delete'
            onCancel={() => setDeleteModalVisible(false)}
            onSubmit={() => deleteStore(storeIdToTransact)}
         />
         {/* <p>{JSON.stringify(data)}</p> */}
         <TableContainer>
            <Table className={classes.table} aria-label='customized table'>
               <TableHead>
                  <TableRow>
                     <StyledTableCell className={classes.width6x} align='left'>
                        {' '}
                        {t?.page_settings_storeName}{' '}
                     </StyledTableCell>
                     <StyledTableCell className={classes.width2x} align='left'>
                        {' '}
                        {t?.status}{' '}
                     </StyledTableCell>
                     <StyledTableCell className={classes.width2x} align='right'>
                        {t?.page_settings_button_action}
                     </StyledTableCell>
                  </TableRow>
               </TableHead>
               <TableBody>
                  {data?.map((store: any) => {
                     const statusLabel = !store.isConnected
                        ? t['disconnected']
                        : store.connection_progress
                        ? t['connection_progress_' + store.connection_progress]
                        : store.isConnected
                        ? t['connected']
                        : t['disconnected'];
                     const statusColor = !store.isConnected
                        ? classes.redText
                        : store.connection_progress && store.connection_progress != 'connected'
                        ? classes.orangeText
                        : store.isConnected
                        ? classes.greenText
                        : classes.orangeText;
                     return (
                        <StyledTableRow key={store._id} id={`table-row-${store._id}`}>
                           {/* Stone Name */}
                           <StyledTableCell component='th' scope='row'>
                              <Typography className={classes.text}>{store.storeName}</Typography>
                           </StyledTableCell>
                           {/* Status */}
                           <StyledTableCell align='left'>
                              <Typography className={classNames(statusColor)}>{statusLabel}</Typography>
                           </StyledTableCell>
                           {/* Action */}
                           <StyledTableCell align='right'>
                              {store.isConnected === false && (
                                <ActionIconButton
                                  onClick={() => connectToWooCommerce(store)}
                                  tooltip={t?.connect}
                                  actionType={'CONNECT'}
                                />
                              )}
                              <ActionIconButton
                                onClick={() => handleDeleteRequest(store._id)}
                                disabled={
                                   !(
                                     store.connection_progress == 'connected' ||
                                     store.connection_progress == 'error'
                                   )
                                }
                                tooltip={t?.comp_button_delete}
                                actionType="DELETE"
                              />
                           </StyledTableCell>
                        </StyledTableRow>
                     );
                  })}
               </TableBody>
            </Table>
         </TableContainer>
      </Fragment>
   );
};

export default Index;

import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { LeftPanelNoChatIcon } from '@assets/icons/ChatPageIcons';
import { ChatListTab } from '@components/ChatListTabs/ChatListTabs.types';
import { LanguageContext } from '@helper/locale/langContext';
import useCommonQueriesOfChats from '@hooks/useCommonQueriesOfChats';
import { CircularProgress, Grid, LinearProgress, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { PlatformStatus } from '@modules/Settings/SettingSpace/types';
import { GET_MY_CHATS_QUERY, GET_MY_CUSTOMER_CHATS_QUERY } from '@queries/Chat/Service/GraphQL/Chat/query';
import {
   CREATE_EMAIL_MESSAGE_MUTATION,
   CREATE_MESSAGE_FROM_USER_PANEL_MUTATION,
} from '@queries/Chat/Service/GraphQL/Message/mutation';
import { GET_PLATFORMS_QUERY } from '@queries/Settings/Service/GraphQL/Platform/query';
import { UPDATED_PLATFORM_SUBSCRIPTION } from '@queries/Settings/Service/GraphQL/Platform/subscription';
import { PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/Platform/types';
import {
   CHANGED_PHONE_STATUS,
   SET_EMAIL_ACCOUNTS,
   SET_MY_CHAT_LIST,
   SET_WHATSAPP_WEB_ACCOUNTS,
   SET_WHATSAPP_BUSINESS_ACCOUNTS,
   SET_MY_CUSTOMER_CHAT_LIST,
   SET_COMPANY_CHAT_COUNT,
   SET_MINE_CHAT_COUNT,
} from '@store/actions/chat';
import { getChatState, getCompanyChatCount, getMineChatCount } from '@store/reducers/chat';
import { getPerson, getToken } from '@store/reducers/person';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useDispatch, useSelector } from 'react-redux';
import NewEmailModalInput from '../MainPanel/ChatInput/newEmailModalInput';
import NewWhatsappModalInput from '../MainPanel/ChatInput/newWhatsappModalInput';
import ChatItem from './ChatItem';
import Toolbar from './Toolbar';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      height: '100vh',
      boxShadow: 'none', // '5px 0px 5px 1px lightgrey',
      borderRight: 'solid 1px lightgrey',
      overflow: 'hidden',
   },
   chatContainer: {
      marginLeft: 10,
      marginRight: 10,
      marginTop: 5,
      overflowX: 'hidden',
   },
   noChatContainer: {
      width: '100%',
      height: '100%',
      marginTop: 200,
   },
   textMessage: {
      fontWeight: 'bold',
      marginTop: 25,
      color: '#ccd8e8',
      fontSize: '1.2rem',
   },
   leftIcon: {
      width: 100,
      height: 100,
   },
   loadingText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   '@global': {
      '::-webkit-scrollbar': {
         width: 6,
      },
      '::-webkit-scrollbar-track': {
         background: theme.chatApp.general.pallet.passiveStructureGrey,
      },
      '::-webkit-scrollbar-thumb': {
         background: '#C9CCD5',
      },
      '::-webkit-scrollbar-thumb:hover': {
         background: '#9399ab',
      },
   },
}));

const LeftPanel = ({ width }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const dispatch = useDispatch();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const token = useSelector(getToken);
   const me = useSelector(getPerson);
   const createEmailModalVisible = useSelector((state) => state.chatReducer.createEmailModalVisible);
   const createWhatsappModalVisible = useSelector((state) => state.chatReducer.createWhatsappModalVisible);
   const { activeChat, myCustomerChatList, myChatList, chatFilters } = useSelector(getChatState);
   const companyChatCount = useSelector(getCompanyChatCount);
   const mineChatCount = useSelector(getMineChatCount);
   const [chatError, setChatError] = useState([]);
   const [isVisibleIconTooltip, setIsVisibleIconTooltip] = useState(false);
   const [myChatTab, setMyChatTab] = useState(ChatListTab.COMPANY);
   const [list, setList] = useState(undefined);

   const onError = useCallback((error) => {
      enqueueSnackbar(error.message, {
         variant: 'error',
         persist: true,
         action: (key) => (
            <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
               {t['comp_button_close']}
            </div>
         ),
      });
   }, []);

   const [createNewEmailMutation] = useMutation(CREATE_EMAIL_MESSAGE_MUTATION);
   const [createNewWhatsappMutation] = useMutation(CREATE_MESSAGE_FROM_USER_PANEL_MUTATION);

   const { loading: myCustomerChatsLoading, fetchMore: myCustomerChatsFetchMore } = useQuery(
      GET_MY_CUSTOMER_CHATS_QUERY,
      {
         variables: {
            me: me._id,
            teams: chatFilters.teams?.value || 'ALL',
            customer: me.customer._id,
            text: chatFilters.text || '',
            platform: chatFilters.platform,
            tag: chatFilters.tag,
            status: chatFilters.status,
            startDate: moment(chatFilters.startDate).startOf('day'),
            endDate: moment(chatFilters.endDate).endOf('day'),
            desc: chatFilters.desc === 'desc',
            page: 0,
         },
         fetchPolicy: 'no-cache',
         onCompleted: (res) => {
            if (res?.getMyCustomerChats?.data) {
               dispatch({
                  type: SET_COMPANY_CHAT_COUNT,
                  payload: { assignCount: res?.getMyCustomerChats?.data?.totalDocs },
               });
               dispatch({
                  type: SET_MY_CUSTOMER_CHAT_LIST,
                  payload: {
                     myCustomerChatList: { ...res?.getMyCustomerChats?.data },
                  },
               });
            }
         },
         onError,
      },
   );

   const { loading: myChatListLoading, fetchMore: myChatsFetchMore } = useQuery(GET_MY_CHATS_QUERY, {
      variables: {
         me: chatFilters.me,
         customer: me.customer._id,
         text: chatFilters.text || '',
         platform: chatFilters.platform,
         tag: chatFilters.tag,
         status: 'ALL',
         startDate: moment(chatFilters.startDate).startOf('day'),
         endDate: moment(chatFilters.endDate).endOf('day'),
         desc: chatFilters.desc === 'desc',
         page: 0,
      },
      fetchPolicy: 'no-cache',
      onCompleted: (res) => {
         if (res?.getMyChats?.data) {
            dispatch({
               type: SET_MINE_CHAT_COUNT,
               payload: { assignCount: res?.getMyChats?.data?.totalDocs },
            });
            dispatch({
               type: SET_MY_CHAT_LIST,
               payload: {
                  myChatList: { ...res?.getMyChats?.data },
               },
            });
         }
      },
      onError,
   });

   const { teams, agents, tags } = useCommonQueriesOfChats();

   // Check if any connection error on whatsapp phones
   useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.WHATS_APP_WEB, customer: me?.customer._id },
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ getPlatforms: { data } }) => {
         data.map((phone) => {
            if (!phone.status || !phone.sessionStatus) {
               setChatError((prev) => [...prev, PLATFORM_TYPE.WHATS_APP_WEB]);
            }
            return false;
         });
         dispatch({
            type: SET_WHATSAPP_WEB_ACCOUNTS,
            payload: { whatsappWebAccounts: data },
         });
      },
      onError,
   });

   useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.WHATS_APP_BUSINESS, customer: me?.customer._id },
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ getPlatforms: { data } }) => {
         dispatch({
            type: SET_WHATSAPP_BUSINESS_ACCOUNTS,
            payload: { whatsappBusinessAccounts: data },
         });
      },
      onError,
   });

   useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.EMAIL, customer: me?.customer._id },
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ getPlatforms: { data } }) => {
         dispatch({
            type: SET_EMAIL_ACCOUNTS,
            payload: { emailAccounts: data },
         });
      },
      onError,
   });

   useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.FACEBOOK, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
      onCompleted: ({ getPlatforms: { data } }) => {
         data.map((item) => {
            if (item.status === PlatformStatus.DISCONNECTED) {
               setChatError((prev) => [...prev, PLATFORM_TYPE.FACEBOOK]);
            }
            return false;
         });
      },
      onError,
   });

   useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.INSTAGRAM, customer: me?.customer._id },
      fetchPolicy: 'cache-first',
      onCompleted: ({ getPlatforms: { data } }) => {
         data.map((item) => {
            if (item.status === PlatformStatus.DISCONNECTED) {
               setChatError((prev) => [...prev, PLATFORM_TYPE.INSTAGRAM]);
            }
            return false;
         });
      },
      onError,
   });

   useSubscription(UPDATED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: me?.customer._id },
      onSubscriptionData: ({
         subscriptionData: {
            data: {
               updatedPlatform: { data },
            },
         },
      }) => {
         if (data) {
            const { metaData, type } = data;
            if (type === PLATFORM_TYPE.WHATS_APP_WEB) {
               const whatsAppPhone = metaData.whatsAppPhone;
               dispatch({
                  type: CHANGED_PHONE_STATUS,
                  payload: whatsAppPhone,
               });

               if (whatsAppPhone?.onlineStatus !== 'CONNECTED') {
                  setChatError((prev) => {
                     if (prev.includes(PLATFORM_TYPE.WHATS_APP_WEB)) return prev;
                     else return [...prev, PLATFORM_TYPE.WHATS_APP_WEB];
                  });
               } else {
                  setChatError((prev) => {
                     let failed = false;
                     prev.map((phone) => {
                        if (!phone.status || !phone.sessionStatus) {
                           failed = true;
                        }
                     });

                     if (failed) {
                        return prev.filter((e) => e !== PLATFORM_TYPE.WHATS_APP_WEB);
                     } else {
                        return prev;
                     }
                  });
               }
            }

            if (type === PLATFORM_TYPE.FACEBOOK) {
               if (metaData.status === PlatformStatus.DISCONNECTED) {
                  setChatError((prev) => {
                     if (prev.includes(PLATFORM_TYPE.FACEBOOK)) return prev;
                     else return [...prev, PLATFORM_TYPE.FACEBOOK];
                  });
               } else {
                  setChatError((prev) => prev.filter((e) => e !== PLATFORM_TYPE.FACEBOOK));
               }
            }
            if (type === PLATFORM_TYPE.INSTAGRAM) {
               if (metaData.status === PlatformStatus.DISCONNECTED) {
                  setChatError((prev) => {
                     if (prev.includes(PLATFORM_TYPE.INSTAGRAM)) return prev;
                     else return [...prev, PLATFORM_TYPE.INSTAGRAM];
                  });
               } else {
                  setChatError((prev) => prev.filter((e) => e !== PLATFORM_TYPE.INSTAGRAM));
               }
            }
         }
      },
   });

   function formatNumberAboveThreshold(number) {
      if (Number.isNaN(Number(number))) return "0";
      const formattedNumber = new Intl.NumberFormat('en-US', {
         maximumFractionDigits: 6,
      }).format(number);
      return (number > 999999 ? `+999.999` : formattedNumber.replace(',', '.')).toString();
   }

   const sendEmailSubmit = async (formData) => {
      // run create mutation
      createNewEmailMutation({
         variables: {
            me: me._id,
            customer: me.customer._id,
            type: 'EMAIL',
            contentHtml: formData.contentHtml,
            content: formData.content,
            subject: formData.subject,
            media: formData.media,
            emailData: formData.emailData,
            receiverId: formData.receiverId,
            forwardingEmailAddress: formData.forwardingEmailAddress,
         },
      }).catch((error) => {
         enqueueSnackbar(error.message, {
            // send success message
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      });
   };

   const sendWhatsappSubmit = async (formData) => {
      return await createNewWhatsappMutation({
         variables: {
            customerId: formData.customerId,
            me: formData.me,
            receiverIds: formData.receiverIds,
            platformType: formData.platformType,
            platformId: formData.platformId,
            messageType: formData.messageType,
            media: formData.media,
            content: formData.content,
            template_cards: {
               template: {
                  id: formData.templateId,
               },
            },
         },
      })
         .then((res) => {
            enqueueSnackbar(t['chat_page_sent_message'], {
               // send success message
               variant: 'success',
               persist: true,
               autoHideDuration: 3000,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
            return res?.data?.createMessageFromUserPanel?.data;
         })
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send success message
               autoHideDuration: 3000,
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   useEffect(() => {
      if (list === undefined) {
         setList(
            myChatTab === ChatListTab.COMPANY
               ? {
                  ...myCustomerChatList,
                  chats: myCustomerChatList.chats.slice(0, 20),
               }
               : { ...myChatList, chats: myChatList.chats.slice(0, 20), page: 0 },
         );
      } else {
         setList(myChatTab === ChatListTab.COMPANY ? myCustomerChatList : myChatList);
      }
   }, [list, myChatTab, myCustomerChatList, myChatList]);

   const handleNextPage = useCallback(() => {
      if (myChatTab === ChatListTab.COMPANY) {
         myCustomerChatsFetchMore({
            variables: {
               page: list.chats.length,
            },
         }).then((res) => {
            const customerChatData = res?.data?.getMyCustomerChats?.data;
            if (customerChatData) {
               dispatch({
                  type: SET_MY_CUSTOMER_CHAT_LIST,
                  payload: {
                     myCustomerChatList: {
                        ...customerChatData,
                        chats: [...list.chats, ...customerChatData.chats],
                     },
                  },
               });
            }
         });
      } else {
         myChatsFetchMore({
            variables: {
               page: list.chats.length,
            },
         }).then((res) => {
            const myChatsData = res?.data?.getMyChats?.data;
            if (myChatsData) {
               dispatch({
                  type: SET_MY_CHAT_LIST,
                  payload: {
                     myChatList: {
                        ...myChatsData,
                        chats: [...list.chats, ...myChatsData.chats],
                     },
                  },
               });
            }
         });
      }
   }, [
      dispatch,
      me.customer._id,
      myChatTab,
      list,
      myChatsFetchMore,
      myCustomerChatsFetchMore,
      myCustomerChatList,
      chatFilters,
   ]);

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' style={{ padding: 16 }} justifyContent='center' xs={12}>
            <CircularProgress size={36} color='primary' />
         </Grid>
      );
   }, []);

   useEffect(() => {
      if (activeChat && activeChat.joinStatus === true && activeChat.participants.find((user) => user._id === me._id)) {
         setMyChatTab(ChatListTab.YOURS);
      }
   }, [activeChat?.joinStatus]);

   useEffect(() => {
      const params = new URL(window.location.href).searchParams
      const tab = params.get('tab')
      if (tab) {
         setMyChatTab(tab)
      }
   }, [window.location.href])

   useEffect(() => {
      dispatch({
         type: 'CREATE_EMAIL_MODAL_VISIBLE',
         payload: false,
      });
      dispatch({
         type: 'CREATE_WHATSAPP_MODAL_VISIBLE',
         payload: false,
      });
   }, []);

   return (
      <Paper elevation={10} className={classes.root} style={{ width: width, overflow: 'visible' }}>
         <Toolbar
            teams={teams}
            agents={agents}
            tags={tags}
            currentTab={myChatTab}
            onTabChange={setMyChatTab}
            chatError={chatError}
            companyChatCount={formatNumberAboveThreshold(companyChatCount)}
            mineChatCount={formatNumberAboveThreshold(mineChatCount)}
         />
         <div className={`${classes.chatContainer} chat-scrollbar`}>
            <Grid container alignItems='center' justifyContent='center' xs={12} direction='column'>
               {(myChatTab === ChatListTab.COMPANY ? myCustomerChatsLoading : myChatListLoading) && (
                  <LinearProgress style={{ width: '100%' }} color='primary' />
               )}
            </Grid>

            {list?.chats?.length > 0 && (
               <div style={{ overflowY: 'auto' }} id='scrollableDiv'>
                  <InfiniteScroll
                     scrollableTarget='scrollableDiv'
                     dataLength={list?.chats?.length || 0}
                     hasMore={list?.hasNextPage}
                     next={handleNextPage}
                     height={chatError ? 'calc(100vh - 200px)' : 'calc(100vh - 160px)'}
                     loader={<Loader />}
                  >
                     {list?.chats?.map((myChat) => {
                        return (
                           <ChatItem
                              isVisibleIconTooltip={isVisibleIconTooltip}
                              setIsVisibleIconTooltip={setIsVisibleIconTooltip}
                              key={myChat._id}
                              myId={me._id}
                              myChat={myChat}
                              teams={teams}
                              renderAgentPhoto={!!myChat.participants[1]}
                              agentStatus={agents?.find((agent) => agent._id === myChat.participants[1]?._id)?.status}
                           />
                        );
                     })}
                  </InfiniteScroll>
               </div>
            )}

            {list?.chats && list?.chats?.length === 0 && (
               <Grid
                  xs={12}
                  justifyContent='center'
                  alignItems='center'
                  container
                  direction='column'
                  className={classes.noChatContainer}
               >
                  <LeftPanelNoChatIcon className={classes.leftIcon} />
                  <Typography className={classes.textMessage}>{t['page_chat_typo_no_chat_to_show']}</Typography>
               </Grid>
            )}
         </div>
         <NewEmailModalInput sendEmailSubmit={sendEmailSubmit} open={createEmailModalVisible} />
         <NewWhatsappModalInput sendWhatsappSubmit={sendWhatsappSubmit} person={me} open={createWhatsappModalVisible} />
      </Paper>
   );
};

export default LeftPanel;

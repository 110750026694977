import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button as MatButton } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
   primaryRoot: {
      marginTop: '20px',
      fontSize: '1.1rem',
      fontWeight: 'bold',
      borderColor: (props) => props.color || theme.palette.primary.main,
      backgroundColor: (props) => props.color || theme.palette.primary.main,
      '&:hover': {
         backgroundColor: (props) => props.color || theme.palette.primary.main,
      },
      '&$disabled': {
         background: 'rgba(1, 140, 249, 0.4)',
         cursor: 'not-allowed',
      },
   },
   secondaryRoot: {
      borderColor: (props) => props.color || theme.palette.primary.main,
      marginTop: '20px',
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   labelPrimary: {
      color: 'white',
   },
   labelSecondary: {
      borderColor: (props) => props.color || theme.palette.primary.main,
      color: (props) => props.color || theme.palette.primary.main,
   },
   disabled: {
      background: 'rgba(1, 140, 249, 0.4)',
      cursor: 'not-allowed',
   },
}));

const Button = (props) => {
   const { label, isPrimary, onClick, style, disabled, type } = props;
   const classes = useStyles(props);
   return (
      <MatButton
         classes={{
            root: isPrimary ? classes.primaryRoot : classes.secondaryRoot,
            label: isPrimary ? classes.labelPrimary : classes.labelSecondary,
            disabled: classes.disabled,
         }}
         disabled={disabled}
         variant={isPrimary ? 'contained' : 'outlined'}
         fullWidth
         type={type || 'button'}
         style={style}
         onClick={onClick}
      >
         {label}
      </MatButton>
   );
};

export default Button;

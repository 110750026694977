import React, { useCallback, useContext } from 'react';
import { Handle, Node, Position, XYPosition, useReactFlow } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import SendIcon from '@mui/icons-material/Send';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LanguageIcon from '@mui/icons-material/Language';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { generateRandomId } from '@helper/functions';
import { FlowInstanceMessageChannel } from '@modules/Automation/FlowBots/types';
import SmartButtonIcon from '@mui/icons-material/SmartButton';

const useStyles = makeStyles(() => ({
   container: {
      position: 'relative',
      display: 'inline-block',
   },

   nodeContainer: {
      border: '1px solid #ffffff',
      width: '120px',
      padding: '5px',
      borderRadius: '5px',
      background: 'white',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   handleTarget: {
      backgroundColor: '#fff',
      width: '5px',
      height: '5px',
      top: 14,
      marginLeft: '5px',
   },
   menuItem: {
      display: 'flex',
      gap: '5px',
      fontSize: 8.5,
      padding: '2px 0',
      '&:hover': {
         backgroundColor: '#F7F7FA',
         cursor: 'pointer',
      },
   },
   icon: {
      width: 15,
      height: 15,
      marginRight: 2,
   },
   iconBox: {
      padding: '2.8px 3px',
      borderRadius: '3px',
      display: 'flex',
      justifyContent: 'center',
   },
}));

interface MenuProps {
   id: string;
   isConnectable: boolean;
   data: {
      handleCreateNode: (
         selectedNodeType: string,
         title: string,
         event: React.MouseEvent<Element, MouseEvent>,
         position: XYPosition,
      ) => void;
      setIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
      setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
      contentNodes: Node[];
   };
}

const MenuNode = ({ id, isConnectable, data }: MenuProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const reactFlow = useReactFlow();
   const { deleteElements } = useReactFlow();
   const { handleCreateNode, setIsDraggable, setIsNewNode, contentNodes } = data;

   const handleDeleteNode = useCallback(() => {
      deleteElements({ nodes: [{ id }] });
      setIsDraggable(true);
   }, [id, deleteElements]);

   const handleClickAway = useCallback(() => {
      deleteElements({ nodes: [{ id }] });
      setIsDraggable(true);
      setIsNewNode(generateRandomId());
   }, [id, deleteElements]);

   const handleNodeType = async (select: string, title: string, e: React.MouseEvent<Element, MouseEvent>) => {
      const nodes = reactFlow.getNode(id);
      if (nodes) {
         handleCreateNode(select, title, e, nodes.position);
      }
      handleDeleteNode();
   };

   const channelType = contentNodes.find((node) => node.type === 'trigger')?.data?.flowBlocks[0].channelType;

   return (
      <ClickAwayListener onClickAway={handleClickAway}>
         <div className={classes.container}>
            <div className={classes.nodeContainer}>
               <Handle
                  type='target'
                  position={Position.Left}
                  id='mm'
                  className={classes.handleTarget}
                  isConnectable={isConnectable}
               />

               <div style={{ display: 'flex', flexDirection: 'column', gap: '2.5px' }}>
                  <div
                     className={classes.menuItem}
                     onClick={(e) => handleNodeType('send_message', t.atomation_flow_menu_send_message, e)}
                  >
                     <div className={classes.iconBox} style={{ backgroundColor: '#C3DDFF' }}>
                        <SendIcon style={{ color: '#147BFB', fontSize: '12px' }} />
                     </div>
                     <div style={{ marginTop: '1.5px' }}>{t.atomation_flow_menu_send_message}</div>
                  </div>
                  {(channelType === FlowInstanceMessageChannel.LiveChat ||
                     channelType === FlowInstanceMessageChannel.WhatsappBusiness ||
                     channelType === FlowInstanceMessageChannel.Facebook ||
                     channelType === FlowInstanceMessageChannel.Instagram) && (
                     <div
                        className={classes.menuItem}
                        onClick={(e) =>
                           handleNodeType('send_message_buttons', t.atomation_flow_menu_send_button_message, e)
                        }
                     >
                        <div className={classes.iconBox} style={{ backgroundColor: '#C3DDFF' }}>
                           <SmartButtonIcon style={{ color: '#147BFB', fontSize: '12px' }} />
                        </div>
                        <div style={{ marginTop: '1.5px' }}>{t.atomation_flow_menu_send_button_message}</div>
                     </div>
                  )}
                  <div
                     className={classes.menuItem}
                     onClick={(e) => handleNodeType('ask_question', t.atomation_flow_menu_ask_question, e)}
                  >
                     <div className={classes.iconBox} style={{ backgroundColor: '#FFDEDE' }}>
                        <QuestionMarkIcon style={{ color: '#FF7474', fontSize: '12px' }} />
                     </div>
                     <div style={{ marginTop: '1.5px' }}>{t.atomation_flow_menu_ask_question}</div>
                  </div>
                  <div
                     className={classes.menuItem}
                     onClick={(e) => handleNodeType('conditions', t.atomation_flow_menu_condition, e)}
                  >
                     <div className={classes.iconBox} style={{ backgroundColor: '#BEF1DB' }}>
                        <FilterAltIcon style={{ color: '#76bc9f', fontSize: '12px' }} />
                     </div>
                     <div style={{ marginTop: '1.5px' }}>{t.atomation_flow_menu_condition}</div>
                  </div>
                  <div
                     className={classes.menuItem}
                     onClick={(e) => handleNodeType('actions', t.atomation_flow_menu_action, e)}
                  >
                     <div className={classes.iconBox} style={{ backgroundColor: '#D7C3E8' }}>
                        <FlashOnIcon style={{ color: '#b079dc', fontSize: '12px' }} />
                     </div>
                     <div style={{ marginTop: '1.5px' }}>{t.atomation_flow_menu_action}</div>
                  </div>
                  <div
                     className={classes.menuItem}
                     onClick={(e) => handleNodeType('delay', t.atomation_flow_menu_delay, e)}
                  >
                     <div className={classes.iconBox} style={{ backgroundColor: '#fff8d9' }}>
                        <AccessTimeFilledIcon style={{ color: '#ffcf00', fontSize: '12px' }} />
                     </div>
                     <div style={{ marginTop: '1.5px' }}>{t.atomation_flow_menu_delay}</div>
                  </div>
                  <div
                     className={classes.menuItem}
                     onClick={(e) => handleNodeType('http_request', t.atomation_flow_menu_httpRequest, e)}
                  >
                     <div className={classes.iconBox} style={{ backgroundColor: '#C3DDFF' }}>
                        <LanguageIcon style={{ color: '#147BFB', fontSize: '12px' }} />
                     </div>
                     <div style={{ marginTop: '1.5px' }}>{t.atomation_flow_menu_httpRequest}</div>
                  </div>
                  <div
                     className={classes.menuItem}
                     onClick={(e) => handleNodeType('start_flow', t.atomation_flow_menu_startFlow, e)}
                  >
                     <div className={classes.iconBox} style={{ backgroundColor: '#CFCFD8' }}>
                        <SkipNextIcon style={{ color: '#707070', fontSize: '12px' }} />
                     </div>
                     <div style={{ marginTop: '1px' }}>{t.atomation_flow_menu_startFlow}</div>
                  </div>
               </div>
            </div>
         </div>
      </ClickAwayListener>
   );
};

export default MenuNode;

import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ShoppingBasket } from '@material-ui/icons';
import React, { useContext } from 'react';


function formatMoney(amount, currency = 'USD') {
    try {
        const formatter = new Intl.NumberFormat('TR', {
            style: 'currency',
            currency: currency == 'TL' ? 'TRY' : currency,
            minimumFractionDigits: 2,
            currencyDisplay: 'symbol',
        });

        return formatter.format(amount);
    } catch (error) {
        return `${amount} ${currency}`;
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        padding: 20,
        borderRadius: 10,
        width: 350,
    },
    headerContainer: {
        padding: 10,
        backgroundColor: '#F7F7FA',
        fontSize: '1.1rem',
        borderRadius: 10,
        color: theme.chatApp.general.pallet.passiveStructureBlue,
    },
    bottomContainer: {
        padding: 10,
        color: theme.chatApp.general.pallet.passiveStructureBlue,
    },
    countContainer: {
        backgroundColor: '#1AAE7A',
        fontSize: '.9rem',
        height: 28,
        borderRadius: 5,
    },
}));
const CustomTooltip = (props) => {
    const { payload } = props;

    const classes = useStyles();
    const {
        lang: { translation: t, code },
    } = useContext(LanguageContext);
    if (!payload || !payload.length) {
        return null;
    }
    const getColumns = (t, payload) => [
        {
            title: t['page_statistics_typo_abandoned_carts'],
            value: (payload[0]?.payload?.abandonedCart || 0) + (payload[0]?.payload?.recoveredCart || 0),
        },
        {
            title: t['page_statistics_abandoned_carts_recovered_carts'],
            value: payload[0]?.payload?.recoveredCart || 0,
        },
        {
            title: t['page_statistics_abandoned_carts_recovery_rate'],
            value: (code.toLowerCase().includes('tr') ? '%' : '') + (payload[0]?.payload?.recoveryRate || 0) + (!code.toLowerCase().includes('tr') ? '%' : ''),
        },
        {
            title: t['page_statistics_abandoned_carts_recovered_value'],
            value: formatMoney(payload[0]?.payload?.recoveredValue || 0, payload[0]?.payload?.cartCurrency || 'USD'),
        },
        {
            title: t['page_statistics_abandoned_carts_cost'],
            value: formatMoney(payload[0]?.payload?.cost || 0, payload[0]?.payload?.costCurrency || 'USD'),
        },
    ];
    const COLUMNS = getColumns(t, payload);

    return (
        <Paper elevation={3} className={classes.root}>
            <Grid container direction='column' alignItems='center'>
                <Grid item container direction='row' justifyContent='space-between' className={classes.headerContainer}>
                    <Typography style={{ fontSize: '1.2rem', height: 40, width: 40, }}>
                        <ShoppingBasket />
                    </Typography>
                    <Typography style={{ fontSize: '1.2rem' }}>{payload[0]?.payload?.title}</Typography>
                </Grid>
                <Grid container className={classes.bottomContainer} item direction='column' xs={12} justifyContent='center'>
                    {COLUMNS.map((item, index) => (
                        <Grid direction='row' container xs={12} key={item.title + index} style={{ marginTop: 10 }}>
                            <Grid item xs={6}>
                                <Typography variant='body1'>{item.title}</Typography>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                justifyContent='center'
                                alignItems='center'
                                container
                                className={classes.countContainer}
                            >
                                <Typography noWrap={false} variant='subtitle1' style={{
                                    color: 'white', textWrap: 'wrap', textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden"
                                }}>
                                    {item.value}
                                </Typography>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Paper>
    );
};

export default CustomTooltip;

import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import { LanguageContext } from '@helper/locale/langContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useMutation, useQuery } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { FLAG_CAMPAIGN_FOR_SCHEDULE, SEND_CAMPAIGN } from '@queries/Campaigns/Service/GraphQL/mutation';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';
import { useSnackbar } from 'notistack';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import { CALCULATE_BALANCE_USAGE } from '@queries/Campaigns/Service/GraphQL/query';

const useStyles = makeStyles((theme) => ({
   modalContainer: {
      // display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 15,
   },
   modalHeader: {
      height: 50,
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalBody: {
      // flexDirection: 'column',
      paddingInline: 20,
      paddingTop: 20,
      paddingBottom: 15,
      width: '100%',
   },
   modalBodyIconContainer: {},
   modalDescription: {},
   modalBodyRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
   },
   modalText: {
      color: '#797575',
      fontSize: '1rem',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const SendDialog = ({ open, onClose, id, name, time }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [createLoading, setCreateLoading] = useState(false);
   const [sendSuccess, setSendSuccess] = useState(false);
   const { enqueueSnackbar } = useSnackbar();

   const [sendCampaign] = useMutation(SEND_CAMPAIGN, {
      async update(
         cache,
         {
            data: {
               sendCampaign: { data: sendCampaign },
            },
         },
      ) {},
   });

   const [flagCampaignForSchedule] = useMutation(FLAG_CAMPAIGN_FOR_SCHEDULE, {
      async update(
         cache,
         {
            data: {
               flagCampaignForSchedule: { data: flagCampaignForSchedule },
            },
         },
      ) {},
   });

   const { loading: isBalanceCheckLoading, data: balanceCheckResponse } = useQuery(CALCULATE_BALANCE_USAGE, {
      variables: {
         campaignId: id,
      },
      fetchPolicy: 'network-only',
      onError: (err) => {
         enqueueSnackbar(
            err.message === 'Recipient limit exceeded'
               ? t['campaign_dynamic_recipient_limit_exceeded']
               : t['campaign_platform_error'],
            {
               variant: 'error',
               autoHideDuration: 3000,
            },
         );
      },
   });

   return (
      <Modal
         paperStyle={{ width: 450 }}
         open={open}
         disableEscapeKeyDown
         disableBackdropClick
         modalHeader={sendSuccess ? t['campaign_send_success'] : t['campaign_ready_to_send']}
      >
         <ModalLargeText style={{ textAlign: 'center' }}>
            {sendSuccess
               ? (time ? t['campaign_send_planned_desc'] : t['campaign_send_success_desc']).replace(
                    '{recipientCount}',
                    !isBalanceCheckLoading ? balanceCheckResponse?.calculateBalanceUsage?.data?.recipient_count : '',
                 )
               : t['review_campaign_before']}
         </ModalLargeText>
         <Grid
            container
            justifyContent='center'
            alignItems='center'
            style={{
               display: sendSuccess ? 'block' : 'none',
               width: '100%',
               textAlign: 'center',
               marginTop: 25,
               marginBottom: 25,
            }}
         >
            <CheckCircle style={{ color: '#40D114', width: 100, height: 100 }} />
         </Grid>
         <Grid style={{ display: sendSuccess ? 'none' : 'block', marginBlock: 20 }}>
            <ModalLargeText>
               <span>
                  {t['campaign_name']}
                  {': '}
               </span>
               <span>{name}</span>
            </ModalLargeText>
            <ModalLargeText>
               <span>
                  {t['campaign_recipients']}
                  {': '}
               </span>
               <span>
                  {isBalanceCheckLoading ? (
                     <CircularProgress color='secondary' size={17} />
                  ) : (
                     <span
                        style={{
                           color: balanceCheckResponse?.calculateBalanceUsage?.data?.sufficient ? '#40D114' : '#FF0000',
                        }}
                     >
                        {balanceCheckResponse?.calculateBalanceUsage?.data?.recipient_count || ''}
                     </span>
                  )}
               </span>
            </ModalLargeText>
            <ModalLargeText>
               <span>
                  {t['campaign_scheduled_for']}
                  {': '}
               </span>
               <span>{time ? moment(time).format('DD/MM/YYYY HH:mm') : t['campaign_now']}</span>
            </ModalLargeText>
            <ModalLargeText>
               <span>
                  {t['campaign_estimated_price']}
                  {': '}
               </span>
               <span>
                  {isBalanceCheckLoading ? (
                     <CircularProgress color='secondary' size={17} />
                  ) : (
                     <span
                        style={{
                           color: balanceCheckResponse?.calculateBalanceUsage?.data?.sufficient ? '#40D114' : '#FF0000',
                        }}
                     >
                        {balanceCheckResponse?.calculateBalanceUsage?.data?.required_balance
                           ? `$${balanceCheckResponse?.calculateBalanceUsage?.data?.required_balance.toFixed(2)}`
                           : ''}
                     </span>
                  )}
               </span>
            </ModalLargeText>
         </Grid>
         <ModalButtonField>
            {!sendSuccess && (
               <ButtonCancel
                  label={t?.page_chat_btn_filter_cancel}
                  disabled={createLoading || isBalanceCheckLoading}
                  onClick={onClose}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
            )}
            {!isBalanceCheckLoading && (
               <ButtonOK
                  label={
                     balanceCheckResponse?.calculateBalanceUsage?.data?.sufficient
                        ? sendSuccess
                           ? t['comp_button_close']
                           : time
                           ? t['campaign_schedule_for_send']
                           : t['campaign_send_now']
                        : balanceCheckResponse?.calculateBalanceUsage?.data?.recipient_count === 0
                        ? t['campaign_not_found_recipients']
                        : t['campaign_insufficient_balance']
                  }
                  disabled={
                     createLoading ||
                     isBalanceCheckLoading ||
                     !balanceCheckResponse?.calculateBalanceUsage?.data?.sufficient
                  }
                  className={classes.modalButtons}
                  style={{
                     color: sendSuccess ? '#212121' : 'white',
                     backgroundColor: balanceCheckResponse?.calculateBalanceUsage?.data?.sufficient
                        ? sendSuccess
                           ? '#eeee'
                           : '#157CFC'
                        : '#FF0000',
                     '&:hover': {
                        backgroundColor: balanceCheckResponse?.calculateBalanceUsage?.data?.sufficient
                           ? sendSuccess
                              ? '#e0e0e0'
                              : '#105CBB'
                           : '#FF0000',
                     },
                     float: 'right',
                  }}
                  startIcon={createLoading ? <CircularProgress color='inherit' size={20} /> : null}
                  onClick={() => {
                     if (sendSuccess) {
                        window.location.href = '/campaigns';
                     } else {
                        setCreateLoading(true);

                        let promise = time
                           ? flagCampaignForSchedule({
                                variables: {
                                   input: {
                                      _id: id,
                                   },
                                },
                             })
                           : sendCampaign({
                                variables: {
                                   input: {
                                      _id: id,
                                   },
                                },
                             });

                        promise
                           .then((res) => {
                              setCreateLoading(false);
                              setSendSuccess(true);
                           })
                           .catch((err) => {
                              if (err.message === 'campaign_platform_error') {
                                 enqueueSnackbar(t['campaign_platform_error'], {
                                    variant: 'error',
                                    autoHideDuration: 1500,
                                 });
                              } else if (err.message === 'recipient_limit_exceeded') {
                                 enqueueSnackbar(t['campaign_recipient_limit_exceeded'], {
                                    variant: 'error',
                                 });
                              } else if (err.message === 'insufficient_balance') {
                                 enqueueSnackbar(t['campaign_insufficient_balance'], {
                                    variant: 'error',
                                    autoHideDuration: 1500,
                                 });
                              } else {
                                 enqueueSnackbar(t['connection_progress_error'], {
                                    variant: 'error',
                                    autoHideDuration: 1500,
                                 });
                              }
                              onClose();
                              setCreateLoading(false);
                           });
                     }
                  }}
               />
            )}
         </ModalButtonField>
      </Modal>
   );
};

export default WithUrlTranslations(SendDialog);

import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { currencyHandler } from '@helper/textUtils';
import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import SubjectRoundedIcon from '@material-ui/icons/SubjectRounded';
import { CHECK_E_COMMERCE_CUSTOMER_ADDRESSES } from '@queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import {
   GET_E_COMMERCE_CUSTOMER_ABANDONED_CART_QUERY,
   GET_E_COMMERCE_LINKED_CUSTOMER_QUERY,
   GET_E_COMMERCE_ORDERS_QUERY,
} from '@queries/Settings/Service/GraphQL/ECommercePlatform/query';
import { E_COMMERCE_ORDERS, UPDATE_E_COMMERCE_CUSTOMER } from '@store/actions/ecommerce';
import { getActiveChat } from '@store/reducers/chat';
import { getECommerceCustomers } from '@store/reducers/ecommerce';
import { getPerson } from '@store/reducers/person';
import { useSnackbar } from 'notistack';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonOK from '../../../../../../../components/ButtonOK/buttonOk';
import ECommerceTabHeaderContext from '../../../../../../../contexts/ECommerceTabHeaderContext';
import MenuItem from '../../../../ChatItem/MenuItem';
import OrderDetail from '../Store/OrderDetail';
import { OrderProps } from '../Store/types';
import CustomerCart from './CustomerCart';
import AddProductToCustomerCart from './CustomerCart/AddProductToCustomerCart';
import CustomerInfo from './CustomerInfo';
import CustomerOrders from './CustomerOrders';
import { ICustomerCartProps } from './types';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';

const useStyles = makeStyles(() => ({
   root: {
      display: 'flex',
      flexDirection: 'column',
   },
   detailView: {
      display: 'flex',
      flexDirection: 'column',
   },
   menuItemIcon: {
      fontSize: 32,
      color: '#fff',
   },
}));

interface CustomerProps {
   containerStyle?: React.CSSProperties;
   onCloseDetailView?: () => void;
   onCustomerSplit?: (() => Promise<any>) | undefined;
   customer: any;
}

type ViewNameType = 'info' | 'orders' | 'cart' | 'order' | 'add_product' | 'continue_cart' | null;

const Customer: FC<CustomerProps> = ({ containerStyle, onCloseDetailView, onCustomerSplit, customer }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const headerContext = useContext(ECommerceTabHeaderContext);
   const me = useSelector(getPerson);
   const eCommerceType = useSelector((rootReducer: any) => rootReducer?.eCommerceReducer?.eCommerceType);
   const dispatch = useDispatch();
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   // const { info, orders } = MOCK_CUSTOMER_DATA;
   const [isVisibleViewName, setVisibleViewName] = useState<ViewNameType>(null);
   const activeChatPerson = useSelector(getActiveChat).participants[0]._id;
   const [selectedOrder, setSelectedOrder] = useState<OrderProps | null>(null);
   const [cart, setCart] = useState<ICustomerCartProps>({
      customer: null,
      products: [],
   });

   const [continueCart, setContinueCart] = useState<ICustomerCartProps>({
      customer: null,
      products: [],
   });

   customer =
      useSelector(getECommerceCustomers)?.customerList?.find(
         (docCustomer: any) => docCustomer?._id === customer?._id,
      ) || customer;
   const [checkECommerceCustomerAddresses, { loading: addressesLoading }] = useMutation(
      CHECK_E_COMMERCE_CUSTOMER_ADDRESSES,
   );

   const [fetchCart, { loading: cartLoading }] = useLazyQuery(GET_E_COMMERCE_CUSTOMER_ABANDONED_CART_QUERY, {
      fetchPolicy: 'no-cache',
      variables: {
         customer: me.customer._id,
         platformCustomer: customer?.platformCustomerId.toString(),
      },
      onCompleted: (data) => {
         if (data.getCustomerAbandonedOrder?.data?.data?.products) {
            let product = data.getCustomerAbandonedOrder?.data?.data;
            setContinueCart({
               customer,
               orderId: product.orderNumber,
               products: product.products.map((variant: any) => ({
                  _id: variant.productId,
                  variantId: variant.product,
                  name: variant.productName,
                  title: variant.productName,
                  productIdAndVariantId: variant.productId + variant.product,
                  currency: product.currency,
                  salesPrice: product.subtotal_price,
                  discountPrice: product.discount,
                  variantsType1: null,
                  variantsType2: null,
                  variantsType3: null,
                  quantity: variant.quantity,
                  image: variant.image,
                  price: product.subtotal_price,
               })),
            });
         } else {
            setContinueCart({
               customer,
               products: [],
            });
         }
      },
   });

   let [newCustomer, setNewCustomer] = useState<any>(null);

   const { loading: eCommerceCustomerLoading, data: eCommerceLinkedCustomer } = useQuery(
      GET_E_COMMERCE_LINKED_CUSTOMER_QUERY,
      {
         fetchPolicy: 'no-cache',
         variables: {
            customer: me.customer._id,
            person: activeChatPerson,
         },
      },
   );
   useEffect(() => {
      if (customer) {
         fetchCart();
         checkECommerceCustomerAddresses({
            variables: {
               type: customer.eCommercePlatformType,
               metaData: {
                  customerId: me.customer._id,
                  eCommerceCustomer: customer._id,
               },
            },
         }).then((res) => {
            if (res.data.checkECommerceCustomerAddresses.data) {
               setNewCustomer({
                  ...res.data.checkECommerceCustomerAddresses.data,
                  phone:
                     res.data.checkECommerceCustomerAddresses.data.phone == '+90'
                        ? res.data.checkECommerceCustomerAddresses.data.addresses[0]?.phone
                        : res.data.checkECommerceCustomerAddresses.data.phone,
               });
               dispatch({
                  type: UPDATE_E_COMMERCE_CUSTOMER,
                  payload: {
                     customer: res.data.checkECommerceCustomerAddresses.data,
                  },
               });
            } else {
               setNewCustomer(customer);
               dispatch({
                  type: UPDATE_E_COMMERCE_CUSTOMER,
                  payload: {
                     customer,
                  },
               });
            }
         });
      }
   }, []);

   const { data: ordersData, refetch } = useQuery(GET_E_COMMERCE_ORDERS_QUERY, {
      variables: {
         type: customer.eCommercePlatformType,
         customer: me.customer._id,
         platformCustomer: customer?.platformCustomerId.toString(),
         page: 1,
         query: '',
      },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
         dispatch({
            type: E_COMMERCE_ORDERS,
            payload: { eCommerceOrders: data.getECommerceOrders.data },
         });
      },
   });

   const totalSpentFromOrdersData = ordersData?.getECommerceOrders?.data?.docs?.reduce((acc: any, order: any) => {
      return acc + order.totalPrice;
   }, 0);

   const totalOrderCountFromOrdersData = ordersData?.getECommerceOrders?.data?.totalDocs || 0;

   const openDetailView = (viewName: ViewNameType, title: string) => {
      setVisibleViewName(viewName);
      if (headerContext && headerContext.setTitle && headerContext.setOnGoBackClick) {
         headerContext.setTitle(title);
         headerContext.setOnGoBackClick(() => () => closeDetailView());
      }
   };

   const closeDetailView = () => {
      fetchCart();
      setVisibleViewName(null);
      if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
         headerContext.setTitle('');
         headerContext.setOnGoBackClick(() => null);
      }
      if (onCloseDetailView) {
         onCloseDetailView();
      }
   };

   const openOrderDetail = React.useCallback(
      (order: OrderProps) => {
         setVisibleViewName('order');
         setSelectedOrder(order);
         if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
            headerContext.setTitle(t['page_e_commerce_store_typo_order_detail_title']);
            headerContext.setOnGoBackClick(() => () => closeOrderDetail());
         }
      },
      [headerContext],
   );

   const closeOrderDetail = () => {
      setVisibleViewName('orders');
      setSelectedOrder(null);
      if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
         headerContext.setTitle(t['page_e_commerce_customer_typo_customer_orders']);
         headerContext.setOnGoBackClick(() => () => closeDetailView());
      }
   };

   const openAddProduct = () => {
      setVisibleViewName('add_product');
      if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
         headerContext.setTitle(t['page_e_commerce_customer_customer_cart_add_product_title']);
         headerContext.setOnGoBackClick(() => () => closeAddProduct());
      }
   };

   const closeCustomerCart = () => {
      setCart({
         customer: null,
         products: [],
      });
      if (headerContext?.onGoBackClick) {
         // eslint-disable-next-line
         headerContext?.onGoBackClick();
      }
      refetch();
   };

   const closeAddProduct = () => {
      setVisibleViewName('cart');
      setSelectedOrder(null);
      if (headerContext?.setTitle && headerContext?.setOnGoBackClick) {
         headerContext.setTitle(t['page_e_commerce_customer_typo_customer_cart']);
         headerContext.setOnGoBackClick(() => () => closeDetailView());
      }
   };

   return (
      <div className={classes.root} style={containerStyle}>
         {isVisibleViewName && (
            <div className={classes.detailView}>
               {isVisibleViewName === 'info' && (
                  <CustomerInfo
                     customer={newCustomer}
                     onCustomerSplit={onCustomerSplit}
                     platformCustomerId={eCommerceLinkedCustomer?.getECommerceLinkedCustomer?.data?.platformCustomerId}
                  />
               )}
               {isVisibleViewName === 'orders' && (
                  <CustomerOrders customer={customer.platformCustomerId} openOrderDetail={openOrderDetail} />
               )}
               {(isVisibleViewName === 'cart' || isVisibleViewName === 'continue_cart') && (
                  <CustomerCart
                     cart={isVisibleViewName === 'continue_cart' ? continueCart : cart}
                     setCart={isVisibleViewName === 'continue_cart' ? setContinueCart : setCart}
                     openAddProduct={openAddProduct}
                     closeCustomerCart={closeCustomerCart}
                     isAbandonedCart={isVisibleViewName === 'continue_cart'}
                  />
               )}
               {isVisibleViewName === 'order' && selectedOrder && <OrderDetail order={selectedOrder} />}
               {isVisibleViewName === 'add_product' && (
                  <AddProductToCustomerCart setCart={setCart} closeAddProduct={closeAddProduct} />
               )}
            </div>
         )}
         {!isVisibleViewName && (
            <React.Fragment>
               {/* Customer Info */}
               <MenuItem
                  icon={<PersonIcon className={classes.menuItemIcon} />}
                  title={`${customer.firstName || '-'} ${customer.lastName || '-'}`}
                  subtitle={customer.email}
                  onClick={() => openDetailView('info', t['page_e_commerce_store_typo_customer_information_title'])}
                  containerStyle={{ marginTop: 8 }}
                  loading={addressesLoading || eCommerceCustomerLoading || cartLoading}
               />
               {/* Customer Orders */}
               <MenuItem
                  icon={<SubjectRoundedIcon className={classes.menuItemIcon} />}
                  title={t['page_e_commerce_customer_typo_customer_orders']}
                  subtitle={`${customer.orders_count || totalOrderCountFromOrdersData} ${
                     t['page_e_commerce_customer_typo_order']
                  }${
                     eCommerceType != 'TSOFT'
                        ? ` : ${
                             customer.total_spent
                                ? currencyHandler(customer.currency) + Number(customer.total_spent).toFixed(2)
                                : totalSpentFromOrdersData !== undefined
                                ? currencyHandler(customer.currency) + totalSpentFromOrdersData.toFixed(2)
                                : currencyHandler(customer.currency) + (0).toFixed(2)
                          }`
                        : ''
                  }`}
                  onClick={() => openDetailView('orders', t['page_e_commerce_customer_typo_customer_orders'])}
               />
               {/* Customer Cart */}
               <MenuItem
                  icon={<ShoppingBasketIcon className={classes.menuItemIcon} />}
                  title={t['page_e_commerce_customer_typo_customer_cart']}
                  subtitle={
                     cart.products?.length === 0
                        ? t['page_e_commerce_customer_typo_cart_is_empty']
                        : `${cart.products?.length} ${t['page_e_commerce_customer_typo_number_of_product']}`
                  }
                  subTitleButton={
                     continueCart.orderId && (
                        <a
                           style={{
                              color: '#157CFC',
                           }}
                           onClick={() => {
                              openDetailView(
                                 'continue_cart',
                                 t['page_e_commerce_customer_typo_continue_customer_order'],
                              );
                           }}
                        >
                           {`${continueCart.products.length} ${t['page_e_commerce_customer_typo_number_of_product']}`}
                        </a>
                     )
                  }
                  loading={addressesLoading || eCommerceCustomerLoading || cartLoading}
                  cursorPointer={false}
                  headerRight={
                     <ButtonOK
                        onClick={() => {
                           setCart((prevState) => ({
                              ...prevState,
                              customer: customer,
                           }));
                           openDetailView('cart', t['page_e_commerce_customer_typo_customer_cart']);
                        }}
                        disabled={
                           customer.eCommercePlatformType === E_COMMERCE_PLATFORM_TYPE.IKAS ||
                           customer.eCommercePlatformType === E_COMMERCE_PLATFORM_TYPE.FAPRIKA
                        }
                        label={t['page_e_commerce_customer_cart_create_order']}
                        style={{ width: 'fit-content', paddingRight: 16, paddingLeft: 16 }}
                     />
                  }
                  containerStyle={{ marginBottom: 8 }}
               />
            </React.Fragment>
         )}
      </div>
   );
};

export default Customer;

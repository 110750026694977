import { useMutation } from '@apollo/client';
import { ChatQuickAnswerIcon } from '@assets/icons/ChatPageIcons';
import ButtonBasedPopup from '@components/ButtonBasedPopup';
import ButtonOK from '@components/ButtonOK/buttonOk';
import FileUploadPreview from '@components/FileUploadPreview';
import MyTypography from '@components/MyTypography';
import Switch from '@components/Switch';
import { LanguageContext } from '@helper/locale/langContext';
import useChatPlatformStatus from '@hooks/useChatPlatformStatus';
import useWindowResize from '@hooks/useWindowResize';
import { Button, ClickAwayListener, IconButton, TextField, Tooltip, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Mic, Stop } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ReplyAllIcon from '@material-ui/icons/ReplyAll';
import SendIcon from '@material-ui/icons/Send';
import SentimentSatisfiedOutlinedIcon from '@material-ui/icons/SentimentSatisfiedOutlined';
import { PLATFORM_TYPE } from '@modules/Chat/types';
import { JOIN_CHAT_MUTATION } from '@queries/Chat/Service/GraphQL/Chat/mutation';
import { SEND_MESSAGE_MUTATION } from '@queries/Chat/Service/GraphQL/Message/mutation';
import { SET_EMAIL_DATA } from '@store/actions/chat';
import { getPerson } from '@store/reducers/person';
import clsx from 'clsx';
import { Picker } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';
import { useSnackbar } from 'notistack';
import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react';
import Resizer from 'react-image-file-resizer';
import { useDispatch, useSelector } from 'react-redux';
import ModalInput from './modalInput';
import QuickAnswers from './quickAnswers';

const useStyles = makeStyles((theme) => ({
   root: {
      backgroundColor: '#fff',
      borderRadius: 15,
      position: 'fixed',
      bottom: 5,
      border: '2px solid',
      zIndex: 10,
      transition: 'all .2s',
      borderColor: (props) => (props.focusedInput ? '#76838C' : '#D0DDE6'),
   },
   disabledContainer: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      zIndex: 1000,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      '-webkit-backdrop-filter': 'blur(8px)',
      'backdrop-filter': 'blur(8px)',
   },
   joinButton: {
      marginLeft: 10,
      color: 'white',
      width: 100,
      height: 34,
      backgroundColor: '#47D054',
      fontSize: '1.1rem',
      '&:hover': {
         backgroundColor: '#47D054',
         filter: 'brightness(1.04)',
      },
   },
   disabledButton: {
      '&$disabled': {
         backgroundColor: 'rgba(21, 124, 252, 0.7)',
         cursor: 'not-allowed',
         color: '#fff',
      },
   },
   textInput: {
      // width: '100%',
      lineHeight: 1.9,
      // wordBreak: 'break-word',
   },
   inputSection: {
      padding: '1px 20px 1px 20px',
   },
   isPrivateInputSection: {
      backgroundColor: '#FFE9A0',
   },
   isRecordInputSection: {
      backgroundColor: '#99c4f7',
   },
   bottomSection: {
      padding: '4px 20px 4px 20px',
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
   },
   isPrivateBottomSection: {
      backgroundColor: '#eed995',
   },
   isRecordBottomSection: {
      backgroundColor: '#89bcfa',
   },
   inputIconContainer: {
      width: 40,
      height: 40,
      backgroundColor: 'transparent',
      borderRadius: 5,
   },
   quickAnswerIcon: {
      width: 20,
      height: 20,
      transition: 'all .5s',
      textAlign: 'center',
      opacity: 0.7,
   },
   noteIcon: {
      opacity: 1,
      fill: '#f2b51f',
   },
   textInputRoot: {
      '& label.Mui-focused': {
         color: 'white',
      },
      '& .MuiInput-underline:after': {
         borderBottomColor: 'yellow',
      },
      '& .MuiOutlinedInput-root': {
         '& fieldset': {
            borderColor: 'white',
         },
         '&:hover fieldset': {
            borderColor: 'white',
         },
         '&.Mui-focused fieldset': {
            borderColor: 'yellow',
         },
      },
   },
   button: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      padding: 5,
      backgroundColor: 'white',
      borderRadius: 10,
      paddingRight: 15,
   },
   buttonTitle: {
      marginLeft: 5,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      fontSize: '0.9rem',
   },
   buttonNote: {
      backgroundColor: '#f2cf35',
   },
   buttonSend: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureAzure,
      color: 'white',
   },
   buttonSendTitle: {
      color: 'white',
   },
   iconColor: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   iconDisabledColor: {
      color: '#898989',
   },
   switchContainer: {
      display: 'flex',
      marginRight: 10,
   },
   switchLabel: {
      marginLeft: 8,
   },
   tooltipContainer: {
      '& div:first-child': {
         backgroundColor: '#F7F7FA',
         color: '#000000',
         fontSize: '.75rem',
         maxWidth: '220px',
         padding: '0.5rem',
         animation: 'none',
         '&  > span': {
            color: '#F7F7FA',
         },
         '& svg': {
            width: 'inherit',
            height: 'inherit',
            margin: 'auto',
         },
         boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
      },
   },
   emailDetailText: {
      fontWeight: '550',
      color: 'rgba(0, 0, 0, 0.75)',
   },
   emailDetailTitle: {
      color: 'rgba(0, 0, 0, 0.9)',
   },
}));

export const MAX_FILE_COUNT = 20;

const resizeFile = (file) =>
   new Promise((resolve) => {
      if (file.type.includes('image')) {
         Resizer.imageFileResizer(
            file,
            1920,
            1080,
            'JPEG',
            80,
            0,
            (uri) => {
               resolve(uri);
            },
            'file',
         );
      } else {
         resolve(file);
      }
   });

let rec;
let gumStream;

const ChatInput = (props) => {
   const {
      submit,
      submitSurvey,
      activeChat,
      submitPrivateMessage,
      fileIsDropping,
      onFileDropped,
      message,
      setChatInputHeight,
      isEmail,
   } = props;
   const me = useSelector(getPerson);
   const emailAccounts = useSelector((state) => state.chatReducer.emailAccounts.emailAccounts);
   const location = useLocation();

   const FEEDBACK_SURVEY_URL = 'https://feedback.qpien.com';

   const textInput = useRef(null);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const theme = useTheme();
   const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
   const [openEmoji, setOpenEmoji] = useState(false);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [focusedInput, setFocusedInput] = useState(false);
   const [autoRep, setAutoRep] = useState(false);
   const [isRep, setIsRep] = useState(false);
   const [noSubject, setNoSubject] = useState(false); // croix et message entre ou pas
   const [newSubject, setNewSubject] = useState(''); // le champ pour nouveau suject
   const [isNewMessage, setIsNewMessage] = useState(false); // pas de suject mais click sur la croix
   const [showNewSubjectInput, setShowNewSubjectInput] = useState(false);
   const [isReplyMessage, setIsReplyMessage] = useState(false);
   const [sthDropping, setSthDropping] = useState(false);
   const classes = useStyles({ ...props, focusedInput });
   const [openEmailDetails, setOpenEmailDetails] = useState(false);
   const { width } = useWindowResize();
   const [state, setState] = useState({
      isPrivate: false,
      isRecording: false,
      recordStartTime: null,
      contentHtml: '',
      text: '',
      media: [],
      quickAnswer: false,
      showEmoji: false,
      subject: '',
      emailData: {
         to: [],
         from: '',
         cc: [],
         bcc: [],
      },
   });

   const [dataToSend, setDataToSend] = useState(null);
   const platformStatus = useChatPlatformStatus(activeChat);
   const [canISendMessage, setCanISendMessage] = useState(false);
   const [hasAuthority, setHasAuthority] = useState(false);
   const chatInputContainerRef = useRef(null);
   const [filterAnswerList, setFilterAnswerList] = useState('');
   const [prepareToUpload, setPrepareToUpload] = useState(false);
   const [selectedTab, setSelectedTab] = useState('QUICK_ANSWER');
   const [selectedTemplate, setSelectedTemplate] = useState('');
   const [selectedSurvey, setSelectedSurvey] = useState(null);
   const whatsAppBusinessPhoneCreatedAt = activeChat?.whatsAppBusinessPhone?.createdAt;
   const isTemplateMessage = whatsAppBusinessPhoneCreatedAt && selectedTab === 'TEMPLATES' && state.quickAnswer;

   const dispatch = useDispatch();
   const messages = useSelector((state) => state.chatReducer.activeChatMessages[activeChat._id]?.messages);

   const lastEmailMessage = () => {
      for (let i = 0; i < messages?.length; i++) {
         // If not survey message and has email data, return the message
         if (messages[i].emailData?.from && messages[i].type !== 8) {
            return messages[i];
         }
      }
   };

   const emailData = useSelector((rootReducer) => rootReducer.chatReducer)?.emailData;
   const messageObj = useSelector((rootReducer) => rootReducer.chatReducer);
   const [from, setFrom] = useState(emailData?.emailData?.from);
   const [to, setTo] = useState(emailData?.emailData?.to);
   const [subject, setSubject] = useState(
      emailData?.subject.includes('Re:') || emailData?.subject.includes('Fwd:') || emailData?.subject.includes('Fw:')
         ? emailData?.subject
         : `Re: ${emailData?.subject}` || '',
   );
   let updateSignature = `<br/><br/>${messageObj?.activeChat?.emailAccount?.emailSignature || ''}`;
   const [emailSignature, setEmailSignature] = useState(updateSignature);

   const [modalVisible, setModalVisible] = useState(false);
   const [initialRender, setInitialRender] = useState(true);

   const replyDivRef = useRef(null);
   const replyDivRef1 = useRef(null);
   const replyDivRef2 = useRef(null);
   const bottomFooterRef = useRef(null);

   useEffect(() => {
      setIsRep(false);
      setAutoRep(false);
      setNoSubject(false);
      setOpenEmailDetails(false);
      dispatch({
         type: SET_EMAIL_DATA,
         payload: null,
      });
   }, [location]);

   useEffect(() => {
      const handleClickOutside = (event) => {
         const ref = replyDivRef.current && !replyDivRef.current.contains(event.target);
         const ref1 = replyDivRef1.current && !replyDivRef1.current.contains(event.target);
         const ref2 = replyDivRef2.current && !replyDivRef2.current.contains(event.target);
         const ref3 = bottomFooterRef.current && !bottomFooterRef.current.contains(event.target);

         if (ref && ref3 && ((ref1 && !ref2) || (!ref1 && ref2))) {
            setIsRep(false);
            setAutoRep(false);
            setNoSubject(false);
            setOpenEmailDetails(false);
            dispatch({
               type: SET_EMAIL_DATA,
               payload: null,
            });
         }
      };
      !modalVisible && document.addEventListener('dblclick', handleClickOutside);
      return () => {
         document.removeEventListener('dblclick', handleClickOutside);
      };
   }, [setIsRep, setNoSubject]);

   const [data, setData] = useState(emailData?.emailData ? emailData : lastEmailMessage());

   useEffect(() => {
      if (initialRender) {
         setInitialRender(false);
         return;
      }
      setData(emailData?.emailData ? emailData : lastEmailMessage());
      emailData && setIsRep(true);
      emailData && setAutoRep(true);
      setNoSubject(false);
      setTo(emailData?.emailData?.to);
      setFrom(emailData?.emailData?.from);
      setSubject(
         emailData?.subject.includes('Re:') || emailData?.subject.includes('Fwd:') || emailData?.subject.includes('Fw:')
            ? emailData?.subject
            : `Re: ${emailData?.subject}` || '',
      );
      setEmailSignature(updateSignature);
   }, [messageObj?.emailData, emailData]);

   useEffect(() => {
      setData(emailData?.emailData ? emailData : lastEmailMessage());
      setTo(emailData?.emailData?.to);
      setFrom(emailData?.emailData?.from);
      setSubject(
         emailData?.subject.includes('Re:') || emailData?.subject.includes('Fwd:') || emailData?.subject.includes('Fw:')
            ? emailData?.subject
            : `Re: ${emailData?.subject}` || '',
      );
      setEmailSignature(updateSignature);
   }, [messageObj?.activeChat?.lastMessage]);

   const closeModal = () => {
      setModalVisible(false);
      dispatch({
         type: SET_EMAIL_DATA,
         payload: null,
      });
      setIsRep(false);
      setNoSubject(false);
   };
   const openModal = () => {
      setState({
         ...state,
         text: '',
         contentHtml: '',
         media: [],
      });
      setIsRep(false);
      setModalVisible(true);
      setIsNewMessage(false);
      setIsReplyMessage(true);
   };
   const openModalNoSubject = () => {};

   // This function is used to remove the < and > characters from the email address.
   const removedCharacterTo = (character) => {
      const returnCharacter = character.map((item) => {
         if (item.includes('<')) {
            return item.split('<')[1].split('>')[0];
         }
         return item;
      });
      return returnCharacter;
   };

   const removedStringCharacterTo = (email) => {
      if (email?.includes('<')) {
         return email?.split('<')[1]?.split('>')[0];
      }
      return email;
   };

   // This function is used to convert <to> to 'cc' if the user is replying to an email.
   const convertCc = (concatToCc) => {
      // This code should be fixed
      const convertToCc = removedCharacterTo(concatToCc);
      const indexForwardingEmail = convertToCc.findIndex((item) => item === emailData.emailData.envelope.to[0]);
      const indexSenderEmail = convertToCc.findIndex((item) => item === emailData.emailData.envelope.from);
      if (indexForwardingEmail !== -1) {
         convertToCc.splice(indexForwardingEmail, 1);
      } else if (indexSenderEmail !== -1) {
         convertToCc.splice(indexSenderEmail, 1);
      }
      return removedCharacterTo(emailData.emailData.cc).concat(convertToCc);
   };

   const handleTooltipOpen = (event) => {
      event.stopPropagation();
      setOpenEmailDetails(!openEmailDetails);
   };

   const handleCloseAuto = (event) => {
      setIsRep(false);
      setAutoRep(false);
      setNoSubject(false);
      setOpenEmailDetails(false);
      dispatch({
         type: SET_EMAIL_DATA,
         payload: null,
      });
   };

   useEffect(() => {
      if (selectedTab === 'TEMPLATES') {
         setState((prevState) => ({
            ...prevState,
            text: '',
         }));
         setSelectedTemplate('');
      }
   }, [selectedTab]);

   useEffect(() => {
      if (isTemplateMessage) {
         setState((prev) => ({
            ...prev,
            isPrivate: false,
         }));
      } else {
         setSelectedTemplate('');
      }
   }, [isTemplateMessage]);

   // After selecting emoji, focus the input Ref ->
   useEffect(() => {
      if (openEmoji || (state.quickAnswer && !isSmallScreen) || state.media.length > 0) {
         textInput.current.focus();
      }
   }, [openEmoji, state.quickAnswer, state.media, isSmallScreen]);

   useEffect(() => {
      setChatInputHeight(chatInputContainerRef?.current?.offsetHeight || 0);
   }, [chatInputContainerRef?.current?.offsetHeight, setChatInputHeight]);
   const [sendMessageMutation, { loading: sendMessageLoading }] = useMutation(SEND_MESSAGE_MUTATION);

   // all input text on change event
   const handleInputChange = (e) => {
      const { name, value } = e.target;
      if (!state.quickAnswer && state.text === '' && value === '/') {
         setState((prevState) => ({ ...prevState, quickAnswer: true, text: value }));
      } else if (state.quickAnswer && state.text.startsWith('/') && value === '') {
         setState((prevState) => ({ ...prevState, quickAnswer: false, text: value }));
      } else if (state.quickAnswer && state.text.startsWith('/') && value !== '') {
         setState((prevState) => ({ ...prevState, text: value }));
         setFilterAnswerList(value.slice(1));
      } else {
         setState((prevState) => ({ ...prevState, [name]: value }));
      }
   };

   const handleClickQuickAnswer = async (quickAnswer) => {
      if (quickAnswer.payload) {
         setState((prevState) => ({
            ...prevState,
            media: [
               ...prevState.media,
               ...quickAnswer.payload.map((x) => ({
                  path: x.path,
                  type: x.type,
                  name: x.name,
               })),
            ],
            text: quickAnswer.answer,
            quickAnswer: false,
         }));
      } else {
         setState((prevState) => ({
            ...prevState,
            text: quickAnswer.answer,
            quickAnswer: false,
         }));
      }
   };

   useEffect(() => {
      if (state.quickAnswer === false) {
         setFilterAnswerList('');
      }
   }, [state.quickAnswer]);

   const handleClickUpload = () => {
      document.getElementById('selectFile').click();
   };

   const handleUploadFiles = async (files) => {
      setPrepareToUpload(true);
      let newFiles = Array.from(files).filter((file) => {
         const foundFile = state.media.find((addedFile) => addedFile.name === file.name);
         return !(foundFile && foundFile.name);
      });
      if (!newFiles.length) {
         return;
      }
      const sizeCheckedImages = newFiles.filter((file) => {
         if (file.size > 10000000) {
            enqueueSnackbar(`"${file.name}" ${t?.page_support_typo_cant_upload_more_than_10_mb}`, {
               variant: 'error',
               autoHideDuration: 3500,
            });
            return false;
         }
         return true;
      });

      const compressedFiles = sizeCheckedImages?.map(async (image) => {
         return await resizeFile(image);
      });

      await Promise.all(compressedFiles).then((res) => {
         setState((prevState) => ({
            ...prevState,
            media: [...state.media, ...res],
         }));
      });
      setPrepareToUpload(false);
   };

   const addEmoji = (e) => {
      let emoji = e.native;
      const newTest = state.text + emoji;
      setState((prevState) => ({
         ...prevState,
         text: newTest,
      }));
      setOpenEmoji(false);
   };

   const [joinChat, { loading: joinChatLoading }] = useMutation(JOIN_CHAT_MUTATION);

   const handleJoinChat = () => {
      joinChat({
         variables: {
            person: me._id,
            _id: activeChat._id,
         },
      }).catch((error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  {t['general_message_button_close']}
               </div>
            ),
         });
      });
   };

   const onInputFocus = () => {
      setFocusedInput(true);
      if (!state.isPrivate && isEmail && !isRep) {
         dispatch({
            type: SET_EMAIL_DATA,
            payload: lastEmailMessage(),
         });
         setIsRep(true);
         setAutoRep(true);
      }
      if (!activeChat?.joinStatus && activeChat?.isJoinable && !state.isPrivate) {
         handleJoinChat();
      }
   };

   useEffect(() => {
      setCanISendMessage(
         activeChat.joinStatus &&
            !joinChatLoading &&
            platformStatus &&
            activeChat.participants.find((p) => p._id === me._id),
      );
   }, [activeChat?.joinStatus, joinChatLoading, platformStatus, activeChat?.participants]);

   useEffect(() => {
      setHasAuthority(!activeChat?.joinStatus ? true : !!activeChat?.participants.find((p) => p._id === me._id));
   }, [activeChat?.joinStatus, activeChat?.participants]);

   useEffect(() => {
      if (activeChat?._id) {
         setState((prevState) => ({ ...prevState, isPrivate: false, text: '' }));
      }
   }, [activeChat?._id]);

   useEffect(() => {
      if (activeChat?.joinStatus && !activeChat?.participants.find((p) => p._id === me._id)) {
         setState((prevState) => ({
            ...prevState,
            isPrivate: true,
         }));
      }
   }, [activeChat?.joinStatus, activeChat?.participants]);

   const togglePrivate = () => {
      if (hasAuthority) {
         setState((prevState) => ({
            ...prevState,
            media: !prevState.isPrivate ? [] : prevState.media,
            isPrivate: !prevState.isPrivate,
         }));
      }
   };

   const toggleRecordMode = async () => {
      if (hasAuthority) {
         if (state.isRecording) {
            rec.stop();
            gumStream.getAudioTracks()[0].stop();
            rec.exportWAV((blob) => {
               let res = new File([blob], `${new Date().getTime()}-recording.wav`, {
                  type: 'audio/wav',
               });
               setState((prevState) => ({
                  ...prevState,
                  recordStartTime: 1,
                  isRecording: false,
                  media: [...prevState.media, res],
               }));
            });
         } else {
            navigator.mediaDevices
               .getUserMedia({ audio: true })
               .then((stream) => {
                  let audioContext = new AudioContext();
                  gumStream = stream;
                  let input = audioContext.createMediaStreamSource(stream);
                  // eslint-disable-next-line no-undef
                  rec = new Recorder(input, { numChannels: 1 });
                  rec.record();
                  document.getElementById('textInput').value = '';
                  setState((prevState) => ({
                     ...prevState,
                     isRecording: true,
                     recordStartTime: 1,
                     text: '',
                  }));
               })
               .catch((_) => {
                  setState((prevState) => ({
                     ...prevState,
                     isRecording: false,
                     recordStartTime: 1,
                  }));
               });
         }
      }
   };

   useEffect(() => {
      if (state.isRecording) {
         const interval = setInterval(() => {
            setState((prevState) => {
               if (prevState.recordStartTime >= 90) {
                  toggleRecordMode();
               }
               return {
                  ...prevState,
                  recordStartTime: prevState.recordStartTime + 1,
               };
            });
         }, 1000);
         return () => clearInterval(interval);
      }
   }, [state.isRecording]);

   useEffect(() => {
      if (selectedSurvey) {
         setIsRep(false);
      }
   }, [selectedSurvey]);

   const handlePressEnter = (e) => {
      if (e.key === 'Enter' && (state.isPrivate || canISendMessage)) {
         // prevent input contains space and shift enter break line
         if ((state.text.trim().length > 0 || state.media.length > 0) && !e.shiftKey) {
            e.preventDefault();
            if (state.isPrivate) {
               submitPrivateMessage(state);
            } else {
               isEmail
                  ? submit({
                       ...state,
                       text: state.text + `<hr id="line-blockquote"> ${emailData?.contentHtml}` + emailSignature,
                    })
                  : submit(state);
               setIsRep(false);
            }
            setState((prevState) => ({
               ...prevState,
               text: '',
               media: [],
               showEmoji: false,
            }));
         }
      }
   };
   const handleDrop = (e) => {
      if (!state.isPrivate) {
         setSthDropping(false);
         e.preventDefault();
         e.dataTransfer.dropEffect = 'copy';
         let files = [...e.dataTransfer.files];
         handleUploadFiles(files);
         onFileDropped();
      }
   };
   const handleRemoveFile = (index) => {
      state.media.splice(index, 1);
      const media = [...state.media];
      setState((s) => ({ ...s, media }));
   };
   const handleCancelUploads = () => {
      const media = [...state.media];
      setState((s) => ({ ...s, media }));
   };

   const closeQuickAnswer = useCallback(() => {
      setState((prevState) => ({
         ...prevState,
         quickAnswer: false,
      }));
      setSelectedTemplate('');
   }, []);

   const sendTemplateMessage = useCallback(() => {
      sendMessageMutation({
         variables: {
            sender: me?._id,
            chat: activeChat?._id,
            type: 3,
            personStatus: true,
            template_cards: {
               template: {
                  id: selectedTemplate,
               },
            },
         },
      })
         .then(() => {
            enqueueSnackbar(t['chat_page_sent_template_message'], {
               variant: 'success',
               autoHideDuration: 3000,
            });
            setSelectedTemplate('');
         })
         .catch((err) => {
            enqueueSnackbar(err.message, {
               variant: 'error',
               autoHideDuration: 3000,
            });
         });
      closeQuickAnswer();
   }, [me, activeChat, selectedTemplate, closeQuickAnswer]);

   function extractEmailsFromArray(inputArray) {
      if (!inputArray || !Array.isArray(inputArray)) {
         return [];
      }

      const emailRegex = /<([^>]+)>/;
      const extractedEmails = [];
      inputArray.forEach((inputString) => {
         if (inputString && typeof inputString === 'string') {
            const matches = emailRegex.exec(inputString);
            if (matches) {
               extractedEmails.push(matches[1]);
            } else {
               extractedEmails.push(inputString.trim());
            }
         }
      });

      return extractedEmails;
   }

   const isDomainValid = () => {
      return emailAccounts?.find((item) => item.forwardingEmailAddress === emailData?.emailData?.envelope?.to[0])
         ?.domain?.valid;
   };

   const isMineMessage = () => {
      return emailData?.sender?._id === me || emailData?.sender?.type >= 0;
   };

   useEffect(() => {
      // Extract the first element from the 'to' Array and insert the rest into 'cc' Array

      if (isEmail) {
         // Deleted;
         const [frm, ...ccRest] = data?.emailData?.to || [];
         setState((prevState) => ({
            ...prevState,
            contentHtml: prevState.text,
            subject: data?.subject,
            emailData: {
               to: isMineMessage() ? to : [removedStringCharacterTo(emailData?.emailData?.from)],
               from: isMineMessage()
                  ? from
                  : isDomainValid()
                  ? emailAccounts?.find((item) => item.forwardingEmailAddress === emailData?.emailData?.envelope?.to[0])
                       ?.senderEmailAddress
                  : emailData?.emailData?.envelope?.to[0],
               cc: emailData?.emailData?.envelope?.from ? convertCc(to) : data?.emailData?.emailData?.to,
               bcc: data?.emailData?.emailData?.bcc,
            },
         }));
         const updatedDataToSend = {
            ...state,
            contentHtml: state.text,
            subject: data?.subject,
            emailData: {
               to: data && extractEmailsFromArray(data?.ack === 0 ? [data?.emailData?.from] : data?.emailData?.to),
               from: data && extractEmailsFromArray(data?.ack === 0 ? [frm] : [data?.emailData?.from]),
               cc: data && extractEmailsFromArray([...ccRest, ...data?.emailData?.cc]),
               bcc: data && extractEmailsFromArray(data?.emailData?.bcc),
            },
            media: state.media,
         };
         setDataToSend(updatedDataToSend);
      }
   }, [state.text, data, state.media]);

   const handleOnSubmit = useCallback(() => {
      if (isTemplateMessage) {
         sendTemplateMessage();
      } else if (selectedSurvey) {
         setIsRep(false);
         submitSurvey({
            ...state,
            text:
               activeChat.type === PLATFORM_TYPE.LIVE_CHAT
                  ? ''
                  : `${selectedSurvey.title}\n${selectedSurvey.message}\n${FEEDBACK_SURVEY_URL}`,
            surveyData: {
               ...(activeChat.type === PLATFORM_TYPE.LIVE_CHAT && {
                  title: selectedSurvey.title,
                  message: selectedSurvey.message,
                  thanksMessage: selectedSurvey.thankYouMessage,
                  submitButtonText: selectedSurvey.submitButtonText,
                  themeMainColor: selectedSurvey.themeMainColor,
                  commentInputTitle: selectedSurvey.commentInputTitle,
               }),
               surveyId: selectedSurvey._id,
            },
         });
         setSelectedSurvey(null);
         setState((prevState) => ({
            ...prevState,
            quickAnswer: false,
         }));
      } else {
         if (state.isPrivate || canISendMessage) {
            if (state.isPrivate) {
               submitPrivateMessage(state);
            } else {
               setIsRep(false);
               isEmail
                  ? submit({
                       ...state,
                       text: state.text + `<hr id="line-blockquote"> ${emailData?.contentHtml}` + emailSignature,
                    })
                  : submit(state);
            }
            setState((prevState) => ({
               ...prevState,
               text: '',
               media: [],
               showEmoji: false,
            }));
         }
      }
   }, [
      sendTemplateMessage,
      isTemplateMessage,
      state,
      submitPrivateMessage,
      submit,
      submitSurvey,
      canISendMessage,
      selectedSurvey,
   ]);

   const onAnswerFilterChange = (e) => {
      setFilterAnswerList(e);
      if (state.text.startsWith('/')) {
         setState((prevState) => ({
            ...prevState,
            text: '/' + e,
         }));
      }
   };

   const visibleChatInputAddressInfo = () => {
      return data?.emailData?.to.concat(data?.emailData?.cc, data?.emailData?.bcc).slice(0, 2);
   };

   const remainingChatInputAddressInfo = () => {
      return data?.emailData?.to.concat(data?.emailData?.cc, data?.emailData?.bcc).slice(2);
   };

   return (
      <Fragment>
         {state.quickAnswer && (
            <div
               style={{
                  width: chatInputContainerRef?.current?.style.width,
                  position: 'absolute',
                  bottom: 0,
                  paddingBottom: chatInputContainerRef?.current?.offsetHeight + 10,
                  border: '2px solid rgb(208, 221, 230)',
                  borderTopLeftRadius: '15px',
                  borderTopRightRadius: '15px',
                  overflow: 'hidden',
                  transition: 'all .2s',
                  zIndex: 2,
               }}
            >
               <QuickAnswers
                  setState={setState}
                  selectedSurvey={selectedSurvey}
                  setSelectedSurvey={setSelectedSurvey}
                  platformStatus={platformStatus}
                  onSend={handleClickQuickAnswer}
                  whatsAppBusinessPhoneCreatedAt={whatsAppBusinessPhoneCreatedAt}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  selectedTemplate={selectedTemplate}
                  setSelectedTemplate={setSelectedTemplate}
                  filterAnswerList={filterAnswerList}
                  setFilterAnswerList={onAnswerFilterChange}
               />
            </div>
         )}
         <div
            ref={chatInputContainerRef}
            className={classes.root}
            onDragOver={(e) => {
               if (!state.isPrivate) {
                  setSthDropping(true);
                  e.preventDefault();
               }
            }}
            onDrop={(e) => {
               if (!state.isPrivate) {
                  handleDrop(e);
               }
            }}
            onDragEnd={(e) => {
               if (!state.isPrivate) {
                  setSthDropping(false);
                  e.preventDefault();
               }
            }}
            onDragLeave={(e) => {
               if (!state.isPrivate) {
                  setSthDropping(false);
                  e.preventDefault();
               }
            }}
            style={{
               width: width < 800 ? width - 30 : 'inherit',
               minHeight: state.media.length > 0 ? 120 : 120,
               maxHeight: 500,
               overflowY: 'auto',
               border: !state.isPrivate && (fileIsDropping || sthDropping) ? '2px solid red' : null,
            }}
         >
            <div style={{ position: 'relative' }}>
               <Fragment>
                  {(!activeChat.isJoinable || !platformStatus) && (
                     <div className={classes.disabledContainer}>
                        {state?.quickAnswer &&
                        activeChat?.type === PLATFORM_TYPE.WHATS_APP_BUSINESS &&
                        !!platformStatus ? (
                           <Fragment>
                              <MyTypography variant={'body1'}>{t['page_chat_typo_template_description']}</MyTypography>
                              <div style={{ marginTop: 10 }}>
                                 <Button
                                    onClick={() =>
                                       setState((prevState) => ({
                                          ...prevState,
                                          quickAnswer: false,
                                       }))
                                    }
                                    variant='outlined'
                                    style={{ marginRight: 10 }}
                                 >
                                    {t['comp_button_cancel']}
                                 </Button>
                                 <ButtonOK
                                    disabled={!selectedTemplate}
                                    label={t['page_chat_typo_send_template_messages']}
                                    onClick={sendTemplateMessage}
                                 />
                              </div>
                           </Fragment>
                        ) : (
                           <Fragment>
                              {!platformStatus ? (
                                 <MyTypography bold color={'error'} variant={'body1'}>
                                    {t?.chat_disconnect_phone_message}
                                 </MyTypography>
                              ) : (
                                 <MyTypography>
                                    {message}
                                    {!activeChat?.isJoinable && (
                                       <a href='https://qpien.com' role='button' target='_blank'>
                                          {t['learn_more']}
                                       </a>
                                    )}
                                 </MyTypography>
                              )}
                              {!state?.quickAnswer &&
                                 activeChat?.type === PLATFORM_TYPE.WHATS_APP_BUSINESS &&
                                 !!platformStatus && (
                                    <ButtonOK
                                       label={t['choose_template_message']}
                                       onClick={() =>
                                          setState((prevState) => ({
                                             ...prevState,
                                             quickAnswer: true,
                                          }))
                                       }
                                       width='auto'
                                       style={{ padding: '10px 15px', marginTop: 10 }}
                                    />
                                 )}
                           </Fragment>
                        )}
                     </div>
                  )}
               </Fragment>
               <Grid style={{ width: '100%' }}>
                  <div>
                     {isEmail && isRep && !state.isPrivate && autoRep && (
                        <div
                           ref={replyDivRef1}
                           style={{
                              backgroundColor: '#F1F3F7',
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              gridTemplateColumns: '1fr 1fr',
                              padding: '0.4rem',
                           }}
                        >
                           <div
                              style={{
                                 textAlign: 'left',
                                 display: 'flex',
                                 alignItems: 'center',
                              }}
                           >
                              <div style={{ marginRight: '0.5rem', color: '#656565' }}>
                                 <ReplyAllIcon />
                              </div>
                              <div>
                                 <div style={{ marginTop: '3px', cursor: 'auto' }}>
                                    {data?.emailData?.envelope?.from ? (
                                       <span className={classes.emailDetailText}>{from}, </span>
                                    ) : (
                                       visibleChatInputAddressInfo()?.map((recipient, index) => (
                                          <span className={classes.emailDetailText}>{recipient}, </span>
                                       ))
                                    )}
                                    {remainingChatInputAddressInfo()?.length > 0 &&
                                       !data?.emailData?.envelope?.from && (
                                          <ClickAwayListener onClickAway={() => setOpenEmailDetails(false)}>
                                             <Tooltip
                                                PopperProps={{
                                                   disablePortal: true,
                                                }}
                                                placement='top'
                                                onClose={() => setOpenEmailDetails(false)}
                                                open={openEmailDetails}
                                                disableFocusL
                                                classes={{ popper: classes.tooltipContainer }}
                                                disableHoverListener
                                                disableTouchListener
                                                title={remainingChatInputAddressInfo().map(
                                                   (recipient) => `<${recipient}>`,
                                                )}
                                                arrow
                                             >
                                                <span
                                                   style={{
                                                      color: '#4888F7',
                                                      fontSize: '12px',
                                                      cursor: 'pointer',
                                                      fontWeight: 'bold',
                                                   }}
                                                   onClick={handleTooltipOpen}
                                                >
                                                   <span
                                                      style={{
                                                         marginLeft: '10px',
                                                         minWidth: '40px',
                                                      }}
                                                   >
                                                      ...+{remainingChatInputAddressInfo().length}
                                                   </span>
                                                </span>
                                             </Tooltip>
                                          </ClickAwayListener>
                                       )}
                                 </div>
                                 <div>
                                    <span className={classes.emailDetailTitle}>{t['page_support_typo_subject']}: </span>
                                    <span className={classes.emailDetailText}>{subject}</span>
                                 </div>
                              </div>
                           </div>
                           <div
                              style={{
                                 display: 'flex',
                                 justifyContent: 'flex-end',
                                 gap: '0.5rem',
                              }}
                           >
                              <div
                                 onClick={openModal}
                                 style={{
                                    cursor: 'pointer',
                                 }}
                              >
                                 <ExpandLessIcon color='primary' fontSize='large' />
                              </div>
                              <div style={{ borderLeft: '2px solid #a8a8a8', margin: '0.5rem 0' }}></div>
                              <div
                                 onClick={handleCloseAuto}
                                 style={{
                                    padding: '0.8rem 0',
                                    color: '#a8a8a8',
                                    cursor: 'pointer',
                                 }}
                              >
                                 <ClearIcon />
                              </div>
                           </div>
                        </div>
                     )}
                  </div>
                  <ModalInput
                     open={modalVisible}
                     onClose={closeModal}
                     modalHeader={subject}
                     emailSignature={emailSignature}
                     newSubject={newSubject}
                     dataToSend={dataToSend}
                     submit={submit}
                     messageObj={data}
                     isNewMessage={isNewMessage}
                     noSubject={noSubject}
                     isReplyMessage={isReplyMessage}
                     me={me._id}
                  />
                  <Grid
                     direction='column'
                     container
                     wrap='nowrap'
                     justifyContent='flex-end'
                     className={clsx(
                        classes.inputSection,
                        state.isPrivate
                           ? classes.isPrivateInputSection
                           : state.isRecording
                           ? classes.isRecordInputSection
                           : null,
                     )}
                  >
                     {!isTemplateMessage && (
                        <FileUploadPreview
                           preparing={prepareToUpload}
                           width={width}
                           sthIsDropping={!state.isPrivate && (fileIsDropping || sthDropping)}
                           files={state.media}
                           onRemoveFile={handleRemoveFile}
                           onCancel={handleCancelUploads}
                           maxFileSize={MAX_FILE_COUNT}
                        />
                     )}

                     <Grid item xs={12}>
                        <TextField
                           ref={replyDivRef}
                           inputRef={textInput}
                           id='textInput'
                           fullWidth
                           disabled={selectedSurvey || isTemplateMessage || state.isRecording}
                           className={classes.textInput}
                           maxRows={5}
                           onFocus={onInputFocus}
                           onBlur={() => {
                              setFocusedInput(false);
                           }}
                           onPaste={(e) => {
                              var clipboardData = e.clipboardData || window.clipboardData;

                              if (clipboardData?.types.includes('Files')) {
                                 let files = [];
                                 e.stopPropagation();
                                 e.preventDefault();
                                 for (let index in clipboardData.items) {
                                    var item = clipboardData.items[index];
                                    if (item.kind === 'file') {
                                       let file = item.getAsFile();
                                       files.push(file);
                                    }
                                 }
                                 setState((prevState) => ({
                                    ...prevState,
                                    media: [...prevState.media, ...files],
                                 }));
                              }
                           }}
                           minRows={1}
                           multiline
                           InputProps={{
                              disableUnderline: true,
                              inputProps: {
                                 style: {
                                    color: state.isRecording ? 'black' : null,
                                    lineHeight: '20px',
                                 },
                              },
                           }}
                           onChange={(e) => handleInputChange(e)}
                           onKeyDown={(e) => {
                              if (
                                 (e.key === ' ' || (e.key === 'Enter' && !state.media.length > 0)) &&
                                 state.text.length === 0
                              ) {
                                 e.preventDefault();
                              }
                           }}
                           value={state.text}
                           name='text'
                           placeholder={
                              state.isRecording
                                 ? t['page_chat_typo_recording_mode'].replace('$1', state.recordStartTime)
                                 : state.isPrivate
                                 ? t['page_chat_typo_enter_some_private_text']
                                 : t['page_chat_typo_enter_some_text']
                           }
                           onKeyPress={handlePressEnter}
                        ></TextField>
                     </Grid>
                  </Grid>

                  <Grid
                     item
                     container
                     direction='row'
                     xs={12}
                     ref={bottomFooterRef}
                     justifyContent='space-between'
                     alignItems='center'
                     className={clsx(
                        classes.bottomSection,
                        state.isPrivate
                           ? classes.isPrivateBottomSection
                           : state.isRecording
                           ? classes.isRecordBottomSection
                           : null,
                     )}
                  >
                     <Grid item xs={6} container spacing={1} alignItems='center' wrap='nowrap'>
                        <Grid item className={classes.switchContainer}>
                           <Switch
                              checked={state.isPrivate}
                              onChange={togglePrivate}
                              disabled={selectedSurvey || !hasAuthority || isTemplateMessage || state.isRecording}
                           />
                           <MyTypography className={classes.switchLabel}>{t['page_chat_typo_private']}</MyTypography>
                        </Grid>

                        <Grid item>
                           <IconButton
                              disabled={state.isRecording}
                              onClick={() => {
                                 setState((prevState) => ({
                                    ...prevState,
                                    quickAnswer: !prevState.quickAnswer,
                                 }));
                                 setSelectedSurvey(null);
                              }}
                           >
                              <ChatQuickAnswerIcon
                                 className={classes.quickAnswerIcon}
                                 fill={state.quickAnswer ? '#2175f8' : '#385273'}
                              />
                           </IconButton>
                        </Grid>
                        <Grid>
                           <IconButton
                              onClick={handleClickUpload}
                              style={{ marginLeft: -5, marginRight: -5 }}
                              disabled={selectedSurvey || state.isPrivate || isTemplateMessage || state.isRecording}
                           >
                              <input
                                 id='selectFile'
                                 multiple
                                 hidden
                                 onChange={(e) => {
                                    handleUploadFiles(e.target.files);
                                    e.target.value = '';
                                 }}
                                 disabled={selectedSurvey || state.isPrivate || isTemplateMessage}
                                 type='file'
                                 accept={activeChat?.type === 4 ? 'image/*' : undefined}
                              />
                              <AttachFileOutlinedIcon
                                 className={clsx(classes.iconColor, state.isPrivate && classes.iconDisabledColor)}
                                 style={{ transform: 'rotate(30deg)' }}
                              />
                           </IconButton>
                        </Grid>
                        <Grid>
                           <Grid item>
                              <Grid item>
                                 <ButtonBasedPopup
                                    anchor={{
                                       vertical: -25,
                                       horizontal: 'left',
                                    }}
                                    transfer={{
                                       vertical: 'bottom',
                                       horizontal: 'left',
                                    }}
                                    disabled={isTemplateMessage || state.isPrivate || state.isRecording}
                                    button={
                                       <IconButton
                                          disabled={isTemplateMessage || state.isPrivate || state.isRecording}
                                          style={{ marginLeft: -5, marginRight: -5 }}
                                       >
                                          <SentimentSatisfiedOutlinedIcon
                                             className={clsx(
                                                classes.iconColor,
                                                (state.isPrivate || state.isRecording) && classes.iconDisabledColor,
                                             )}
                                          />
                                       </IconButton>
                                    }
                                    open={openEmoji}
                                    onOpen={() => {
                                       if (
                                          !selectedSurvey &&
                                          !isTemplateMessage &&
                                          !state.isPrivate &&
                                          !state.isRecording
                                       ) {
                                          setOpenEmoji(true);
                                       }
                                    }}
                                    onClose={() => setOpenEmoji(false)}
                                    elevation={12}
                                 >
                                    <Grid style={{ paddingBottom: 0 }}>
                                       <Picker
                                          onSelect={addEmoji}
                                          style={{
                                             zIndex: '99999',
                                          }}
                                          title={t['emoji_selector_title']}
                                          exclude={[
                                             'search',
                                             'places',
                                             'foods',
                                             'activity',
                                             'places',
                                             'objects',
                                             'symbols',
                                          ]}
                                          i18n={{
                                             search: t['emoji_selector_search'],
                                             clear: t['emoji_selector_clear'], // Accessible label on "clear" button
                                             notfound: t['emoji_selector_not_found'],
                                             skintext: t['emoji_selector_skintext'],
                                             categories: {
                                                // search: 'Arama Sonuçları',
                                                // recent: 'Sık Kullanılan',
                                                // smileys: 'Yüz ve Duygu İfadeleri',
                                                people: t['emoji_selector_category_people'],
                                                nature: t['emoji_selector_category_nature'],
                                                // foods: 'Yemek ve İçecek',
                                                // activity: 'Aktivite',
                                                // places: 'Seyehat ve Yerler',
                                                // objects: 'Objeler',
                                                // symbols: 'Semboller',
                                                flags: t['emoji_selector_category_flags'],
                                                // custom: 'Özel',
                                             },
                                             categorieslabel: t['emoji_selector_categorieslabel'], // Accessible title for the list of categories
                                             skintones: {
                                                1: t['emoji_selector_skintones_1'],
                                                2: t['emoji_selector_skintones_2'],
                                                3: t['emoji_selector_skintones_3'],
                                                4: t['emoji_selector_skintones_4'],
                                                5: t['emoji_selector_skintones_5'],
                                                6: t['emoji_selector_skintones_6'],
                                             },
                                          }}
                                       />
                                    </Grid>
                                 </ButtonBasedPopup>
                              </Grid>
                           </Grid>
                        </Grid>
                        <Grid>
                           <Grid item>
                              {!isEmail && (
                                 <Grid item>
                                    <IconButton
                                       onClick={toggleRecordMode}
                                       style={{ marginLeft: -5, marginRight: -5 }}
                                       disabled={
                                          selectedSurvey || state.isPrivate || isTemplateMessage || !hasAuthority
                                       }
                                    >
                                       {state.isRecording ? (
                                          <Stop className={clsx(classes.iconColor)} style={{ color: '#f44336' }} />
                                       ) : (
                                          <Mic
                                             className={clsx(
                                                classes.iconColor,
                                                state.isPrivate && classes.iconDisabledColor,
                                             )}
                                          />
                                       )}
                                    </IconButton>
                                 </Grid>
                              )}
                           </Grid>
                        </Grid>
                     </Grid>
                     <Grid item container xs={6} justifyContent='flex-end' alignItems='center' wrap='nowrap'>
                        <Grid item>
                           <ButtonOK
                              onClick={handleOnSubmit}
                              disabled={
                                 !selectedSurvey &&
                                 (sendMessageLoading ||
                                    !(state.isPrivate || canISendMessage) ||
                                    (!isTemplateMessage && !state.text.trim().length > 0 && !state.media.length > 0) ||
                                    (isTemplateMessage && !selectedTemplate) ||
                                    (isEmail && !isRep) ||
                                    ((state?.subject?.length === 0 || !state?.subject) && !state.isPrivate && isEmail))
                              }
                              label={
                                 width < 730
                                    ? ''
                                    : isTemplateMessage
                                    ? t['page_chat_typo_send_template_messages']
                                    : t['page_chat_typo_send']
                              }
                              fontSize='1rem'
                              fontWeight='bold'
                              height={36}
                              endIcon={<SendIcon style={{ marginLeft: 5, marginRight: 5 }} />}
                              style={{ borderRadius: 10, width: '100%' }}
                           />
                        </Grid>
                     </Grid>
                  </Grid>
               </Grid>
            </div>
         </div>
      </Fragment>
   );
};

export default ChatInput;

import { CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { NumberFormatter } from '@helper/functions';

const useStyles = makeStyles((theme) => ({
   paper: {
      cursor: 'pointer',
      textAlign: 'center',
      height: '130px',
      minWidth: '210px',
      borderRadius: 15,
      transform: 'scale(1)  translate(0,0)',
      transition: theme.transitions.create(['transform'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   },
   paperHovered: {
      transform: 'scale(1.005) translate(0, 1px)',
      transition: theme.transitions.create(['transform'], {
         easing: theme.transitions.easing.sharp,
         duration: theme.transitions.duration.enteringScreen,
      }),
   },
   title: {
      textAlign: 'left',
      fontSize: '15px',
      paddingLeft: theme.spacing(2),
      paddingTop: theme.spacing(1),
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   icon: {
      width: ' 36px',
      height: '36px',
      marginBottom: '20px',
   },
   count: {
      color: theme.chatApp.general.pallet.passiveStructureBlack,
      fontSize: '2.1rem',
      [theme.breakpoints.down('xs')]: {
         fontSize: '2.3rem',
      },
   },
}));

const SmallChip = ({ count, icon, title }) => {
   const classes = useStyles();
   const [mouseIsOverMe, setMouseIsOverMe] = useState(false);

   return (
      <Paper
         className={clsx(classes.paper, mouseIsOverMe && classes.paperHovered)}
         elevation={mouseIsOverMe ? 3 : 1}
         onMouseEnter={() => setMouseIsOverMe(true)}
         onMouseLeave={() => setMouseIsOverMe(false)}
      >
         <Grid item xs={12} sm={12} container direction='column'>
            <Grid item xs={12}>
               <Typography className={classes.title}>{title}</Typography>
            </Grid>
            <Grid item xs={12}>
               {count === undefined ? (
                  <CircularProgress color='primary' />
               ) : (
                  <Typography className={classes.count}>{NumberFormatter.format(count)}</Typography>
               )}
            </Grid>
            <Grid container item xs={12} alignItems='center' justifyContent='flex-end' style={{ paddingRight: 10 }}>
               {icon}
            </Grid>
         </Grid>
      </Paper>
   );
};

export default SmallChip;

import { gql } from '@apollo/client';

export const GET_QUICK_ANSWERS_QUERY = gql`
   query getQuickAnswersQuery($customer: ID!, $pageSize: Int!, $page: Int!, $search: String) {
      quickAnswers(customer: $customer, pageSize: $pageSize, page: $page, search: $search) {
         data {
            docs {
               _id
               name
               answer
               payload {
                  name
                  size
                  type
                  path
               }
               customer {
                  accountStatus
                  accountConfirmation
                  appStatus
                  hasFreeTrial
                  freeTrialExpiringDate
                  isANewCustomer
                  _id
                  companyName
               }
               createdAt
            }
            hasNextPage
            docsCount
         }
         success
         code
         message
      }
   }
`;

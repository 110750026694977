import React from 'react';
import { ButtonBase, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import { useState } from 'react';
import Modal from '../Modal/modal';

const ImageViewer = ({ url, name, isLoading, onShow, onClose, showPreview, disabled, styles }) => {
   const [showModal, setShowModal] = useState(false);

   return (
      <Grid
         container
         direction='row'
         justifyContent='space-between'
         alignItems='flex-start'
         wrap='nowrap'
         style={{ backgroundColor: disabled ? 'lightgrey' : 'transparent' }}
      >
         <Grid container justifyContent='space-around' alignItems='flex-start' direction='column'>
            {showPreview &&
               (url ? (
                  <ButtonBase
                     onClick={() => {
                        setShowModal(true);
                        onShow(true);
                     }}
                  >
                     <img src={url} style={{ objectFit: 'cover', width: '150px', height: '100px', borderRadius: 6, ...styles }} alt={name} />
                  </ButtonBase>
               ) : (
                  <ImageIcon style={{ fontSize: '3rem', color: 'grey' }} />
               ))}
            {name && (
               <Typography
                  style={{
                     fontSize: '1rem',
                     color: 'grey',
                     cursor: 'pointer',
                     marginTop: 5,
                  }}
                  onClick={() => {
                     setShowModal(false);
                     onShow(false);
                  }}
               >
                  {name}
               </Typography>
            )}

            {isLoading && <CircularProgress />}
            <Modal
               open={showModal}
               paperStyle={{
                  width: 'auto',
                  height: 'auto',
                  backgroundColor: 'transparent',
                  boxShadow: 'unset',
               }}
               onClose={() => {
                  setShowModal(false);
                  onShow(false);
               }}
            >
               <img
                  src={url}
                  alt={name}
                  style={{
                     margin: 'auto',
                     display: 'block',
                     width: 'auto',
                     maxWidth: '90vh',
                     maxHeight: '90vh',
                  }}
               />
            </Modal>
         </Grid>
      </Grid>
   );
};

export default ImageViewer;

import { useMutation, useQuery, useSubscription } from '@apollo/client';
import DeletePlatformModal from '@components/Modal/DeletePlatformModal';
import Modal from '@components/Modal/modal';
import { LanguageContext } from '@helper/locale/langContext';
import { FormControlLabel, Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ViewCompactIcon from '@material-ui/icons/ViewCompact';
import { PLATFORM_TYPE } from '@modules/Chat/types';
import { Link } from 'react-router-dom';
import { CREATE_PLATFORM_MUTATION, DELETE_PLATFORM_MUTATION, UPDATE_PLATFORM_MUTATION } from '@queries/Settings/Service/GraphQL/Platform/mutation';
import { GET_PLATFORMS_QUERY } from '@queries/Settings/Service/GraphQL/Platform/query';
import { CREATED_PLATFORM_SUBSCRIPTION, DELETED_PLATFORM_SUBSCRIPTION, UPDATED_PLATFORM_SUBSCRIPTION } from '@queries/Settings/Service/GraphQL/Platform/subscription';
import { GET_TEAMS_QUERY } from '@queries/Teams/query';
import { getCustomer } from '@store/reducers/person';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { StyledTableCell, StyledTableRow } from '../shared-components/tableItems';
import ModalContent from './modalContent.js';
import { TelegramProps } from "./telegram.types";
import ActionIconButton from '../ActionIconButton';
import ButtonOK from '@components/ButtonOK/buttonOk';
import CustomSwitch from '@components/Switch';
import ChannelSwitchModal from './channelSwitchModal';

const useStyles = makeStyles((theme: any) => ({
    table: {
        minWidth: 700,
    },

    ButtonContainer: {
        paddingLeft: 5,
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    titleContainer: {
        color: theme.chatApp.general.pallet.passiveStructureBlue,
        borderRadius: 15,
        padding: 15,
        //   backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    },
    title: {
        fontSize: '1.1rem',
        color: theme.chatApp.general.pallet.passiveStructureBlue,
        fontWeight: 'bold',
    },

    modalButtons: {
        fontSize: '18px',
        textTransform: 'none',
        paddingBlock: 5,
        paddingInline: 15,
        minWidth: 140,
        height: 44,
        borderRadius: 6,
        transition: 'all .5s',
        '&:disabled': {
            opacity: '0.7',
        },
    },
    coloredText: {
        color: theme.chatApp.general.pallet.passiveStructureBlue,
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    label: {
        paddingRight: 8,
    },
}));


const Telegram = ({ person, token }: TelegramProps) => {
    const classes = useStyles();
    const { lang } = useContext(LanguageContext);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const customer = useSelector(getCustomer);
    const t = lang?.translation;
    const [updateModalVisible, setUpdateModalVisible] = useState<{
        visible: boolean,
        _id: null | string,
        mode: string,
    }>({
        visible: false,
        _id: null,
        mode: 'CREATE',
    });
    const [channelSwitchModalVisible, setChannelSwitchModalVisible] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedDeleteItemId, setSelectedDeletedItemId] = useState<null | string>(null);

    const {
        loading: telegramAccountLoading,
        data: incomingData,
        client: platformsQueryClient,
    } = useQuery(GET_PLATFORMS_QUERY, {
        variables: { type: PLATFORM_TYPE.TELEGRAM, customer: person.customer._id },
        fetchPolicy: 'cache-first',
        onError: (error: Error) => {
            // on query error
            // send snack bar message
            enqueueSnackbar(error.message, {
                variant: 'error',
                persist: true,
                action: (key) => (
                    <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                        Kapat
                    </div>
                ),
            });
        },
    });

    const { loading: teamsLoading, data: teamsData } = useQuery(GET_TEAMS_QUERY, {
        variables: { customer: person.customer._id, pageSize: 100, page: 1 },
        fetchPolicy: 'cache-and-network',
        onError: (error) => {
            enqueueSnackbar(error.message, {
                variant: 'error',
                persist: true,
                action: (key) => (
                    <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                        Kapat
                    </div>
                ),
            });
        },
    });

    const [updatePlatformMutation, { loading: updateTelegramAccountLoading }] = useMutation(UPDATE_PLATFORM_MUTATION);
    const [createPlatformMutation, { loading: createTelegramAccountLoading }] = useMutation(CREATE_PLATFORM_MUTATION);
    const [deletePlatformMutation, { loading: deleteTelegramAccounLoading }] = useMutation(DELETE_PLATFORM_MUTATION);

    useSubscription(CREATED_PLATFORM_SUBSCRIPTION, {
        variables: { token, customer: person ? person.customer._id : '' },
        onSubscriptionData: ({
            client,
            subscriptionData: {
                data: {
                    createdPlatform: { data },
                },
            },
        }) => {
            //@ts-ignore
            let { getPlatforms } = client.cache.readQuery({
                query: GET_PLATFORMS_QUERY,
                variables: { type: PLATFORM_TYPE.TELEGRAM, customer: person ? person.customer._id : '' },
            });
            client.cache.writeQuery({
                query: GET_PLATFORMS_QUERY,
                variables: { type: PLATFORM_TYPE.TELEGRAM, customer: person ? person.customer._id : '' },
                data: {
                    getPlatforms: {
                        data: [data.metaData.pages, ...getPlatforms.data],
                        success: true,
                        code: null,
                        message: null,
                    },
                }, // add new data into old one
            });
        },
    });

    const updateSubsCache = (data: any, client: any, operation: string) => {
        let { getPlatforms } = client.cache.readQuery({
            query: GET_PLATFORMS_QUERY,
            variables: { type: PLATFORM_TYPE.TELEGRAM, customer: person ? person.customer._id : '' },
        });

        if (operation === 'create') {
        }
        let index = getPlatforms.data.findIndex((item: any) => item._id.toString() === data.metaData._id.toString());
        if (index > -1) {
            let opResult;
            if (operation === 'update') {
                opResult = getPlatforms.data.map((item: any) => {
                    if (item._id.toString() === data.metaData._id.toString()) {
                        const toWrite = {
                            ...item,
                            ...data.metaData,
                        };
                        return toWrite;
                    }
                    return item;
                });
            } else if (operation === 'delete') {
                opResult = getPlatforms.data.filter((item: any) => item._id.toString() !== data.metaData._id.toString());
            }
            client.cache.writeQuery({
                query: GET_PLATFORMS_QUERY,
                variables: { type: PLATFORM_TYPE.TELEGRAM, customer: person ? person.customer._id : '' },
                data: { getPlatforms: { data: opResult, success: true, code: null, message: null } }, // add new data into old one
            });
        }
    };

    // subscription updated whats app phone
    useSubscription(UPDATED_PLATFORM_SUBSCRIPTION, {
        variables: { token, customer: person ? person.customer._id : '' },
        onSubscriptionData: async ({
            client,
            subscriptionData: {
                data: {
                    updatedPlatform: { data },
                },
            },
        }) => {
            if (data.metaData.error && data.metaData.message) {
                enqueueSnackbar(data.metaData.message, {
                    variant: 'error',
                    autoHideDuration: 4000,
                });
            }
            updateSubsCache(data, client, 'update');
        },
    });

    // subscription deleted whats app phone
    useSubscription(DELETED_PLATFORM_SUBSCRIPTION, {
        variables: { token, customer: person ? person.customer._id : '' },
        onSubscriptionData: async ({
            client,
            subscriptionData: {
                data: {
                    deletedPlatform: { data },
                },
            },
        }) => {
            await updateSubsCache(data, client, 'delete');
        },
    });

    const loadingOrFetching =
        telegramAccountLoading ||
        createTelegramAccountLoading ||
        updateTelegramAccountLoading ||
        deleteTelegramAccounLoading ||
        teamsLoading;

    if (loadingOrFetching) {
        return <div className='loading' />;
    }

    const openCreateModal = () => {
        if (!loadingOrFetching)
            setUpdateModalVisible((prevState) => ({
                ...prevState,
                mode: 'CREATE',
                visible: true,
            }));
    };

    const openEditModal = (_id: string) => {
        if (!loadingOrFetching)
            setUpdateModalVisible((prevState) => ({
                ...prevState,
                _id,
                mode: 'UPDATE',
                visible: true,
            }));
    };

    const closeModal = () => {
        if (!loadingOrFetching)
            setUpdateModalVisible((prevState) => ({
                ...prevState,
                _id: null,
                visible: false,
            }));
    };

    const handleCreateSubmit = (data: any, mode?: string) => {
        if (updateModalVisible.mode == 'UPDATE' || mode === 'UPDATE') {
            updatePlatformMutation({
                variables: {
                    type: PLATFORM_TYPE.TELEGRAM,
                    metaData: {
                        customer: customer._id,
                        ...data,
                    },
                },
            })
                .then(({ data }) => {
                    closeModal();
                    enqueueSnackbar(
                        `${data?.updatePlatform?.data?.metaData?.firstName} ${t?.general_information_updated_successfully}`,
                        {
                            variant: 'success',
                            autoHideDuration: 2000,
                        },
                    );
                })
                .catch((error) => {
                    closeModal();
                    enqueueSnackbar(error.message, {
                        variant: 'error',
                        persist: true,
                    });
                });
        } else {
            createPlatformMutation({
                variables: {
                    type: PLATFORM_TYPE.TELEGRAM,
                    metaData: {
                        ...data,
                        customer: customer._id,
                        teams: data.teams.map((x: any) => x.value),
                    },
                },
            })
                .then(({ data }) => {
                    closeModal();
                    enqueueSnackbar(
                        `${data?.createPlatform?.data?.metaData?.pages?.firstName} ${t?.general_information_added_successfully}`,
                        {
                            variant: 'success',
                            autoHideDuration: 2000,
                        },
                    );
                })
                .catch((error) => {
                    closeModal();
                    enqueueSnackbar(error.message, {
                        variant: 'error',
                        persist: true,
                    });
                });
        }
    };

    const handleDeleteSubmit = (item: string, deleteType: string) => {
        deletePlatformMutation({
            variables: { type: PLATFORM_TYPE.TELEGRAM, metaData: { _id: item, deleteType } },
        })
            .then(
                ({
                    data: {
                        deletePlatform: { data },
                    },
                }) => {
                    return enqueueSnackbar(`${data.metaData.firstName} ${t?.general_information_deleted_successfully}`, {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });
                },
            )
            .catch((error) => {
                enqueueSnackbar(error.message, {
                    variant: 'error',
                    autoHideDuration: 3000,
                });
            });
    };

    return (
        <Fragment>
            <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
                <div style={{ display: 'flex' }}>
                    <Link to='/settings/channels-and-integrations' style={{ marginTop: 5 }}>
                        <ArrowBackIcon color='disabled' />
                    </Link>
                    <Typography style={{ marginLeft: 10, fontSize: '1.1rem', fontWeight: 'bold', lineHeight: '35px' }}>
                        Telegram
                    </Typography>
                </div>
                <Grid className={classes.ButtonContainer}>
                    {
                        <Typography
                            style={{
                                marginRight: 10,
                                color:
                                    person.customer.usageTelegramCount < person.customer.package.telegramCount
                                        ? 'initial'
                                        : 'red',
                            }}
                        >
                            {person.customer.usageTelegramCount} / {person.customer.package.telegramCount}
                        </Typography>
                    }
                    <ButtonOK
                        onClick={openCreateModal}
                        disabled={person.customer.usageTelegramCount >= person.customer.package.telegramCount}
                        width={200}
                        height={40}
                        fontSize='1.1rem'
                        label={t.page_settings_button_add_new}
                    />
                </Grid>
            </Grid>
            <Grid>
                <TableContainer>
                    <Table className={classes.table} aria-label='customized table'>
                        <TableHead>
                            <TableRow>
                                <StyledTableCell align='left'>{t['page_chat_typo_profile_name_family']}</StyledTableCell>
                                <StyledTableCell align='left'>{t['page_chat_typo_profile_username']} </StyledTableCell>
                                <StyledTableCell align='left'>{t['teams']} </StyledTableCell>
                                <StyledTableCell align='left'>{t['page_settings_connection_status']} </StyledTableCell>
                                <StyledTableCell align='right'>
                                    {t['page_settings_typo_platforms_telegram_channels']}{' '}
                                </StyledTableCell>
                                <StyledTableCell align='right'>{t['page_settings_process']} </StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {incomingData?.getPlatforms?.data?.map((telegramAccount: any) => (
                                <StyledTableRow key={telegramAccount._id}>
                                    <StyledTableCell component='th' scope='row'>
                                        <Typography className={classes.title}>{telegramAccount.firstName}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell component='th' scope='row'>
                                        <Typography className={classes.title}>{telegramAccount.userName}</Typography>
                                    </StyledTableCell>
                                    <StyledTableCell component='th' scope='row'>
                                        <Typography style={{ color: telegramAccount.teams?.length > 0 ? 'initial' : 'red' }}>
                                            {telegramAccount.teams.length > 0
                                                ? telegramAccount.teams.length + ' ' + t?.teams_with_number
                                                : t.no_teams}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align='left' className={classes.coloredText}>
                                        <Typography
                                            noWrap
                                            style={{
                                                fontSize: '0.9rem',
                                                color: telegramAccount.status ? '#23D935' : 'red',
                                            }}
                                        >
                                            {telegramAccount.status
                                                ? t['page_settings_whatsapp_active']
                                                : t['page_settings_whatsapp_not_active']}
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align='right'>
                                        <Grid container alignItems='center'>
                                            <FormControlLabel
                                                control={
                                                    <CustomSwitch
                                                        checked={telegramAccount?.receiveChannelMessages}
                                                        onChange={() => {
                                                            if (telegramAccount?.receiveChannelMessages) {
                                                                handleCreateSubmit({
                                                                    _id: telegramAccount._id,
                                                                    receiveChannelMessages: false,
                                                                }, 'UPDATE');
                                                            } else {
                                                                setChannelSwitchModalVisible(telegramAccount._id);
                                                            }
                                                        }}
                                                    />
                                                }
                                                label={telegramAccount?.receiveChannelMessages ? t?.enabled : t?.disabled}
                                                labelPlacement='start'
                                                classes={{
                                                    label: classes.label,
                                                }}
                                            />
                                        </Grid>
                                    </StyledTableCell>
                                    <StyledTableCell align='right'>
                                        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                                            <ActionIconButton
                                                onClick={() => {
                                                    openEditModal(telegramAccount._id);
                                                }}
                                                tooltip={t['comp_button_edit']}
                                                actionType={'EDIT'}
                                            />
                                            <ActionIconButton
                                                onClick={() => {
                                                    setShowDeleteModal(true);
                                                    setSelectedDeletedItemId(telegramAccount._id);
                                                }}
                                                tooltip={t['comp_button_delete']}
                                                actionType={'DELETE'}
                                            />
                                        </Grid>
                                    </StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

            <Modal
                open={updateModalVisible.visible}
                onClose={closeModal}
                modalHeader={
                    t[
                    updateModalVisible.mode == 'UPDATE'
                        ? 'page_settings_modal_edit_telegram'
                        : 'page_settings_modal_telegram'
                    ]
                }
            >
                <ModalContent
                    _id={updateModalVisible._id}
                    teamsData={teamsData?.getTeamList?.data?.docs}
                    isLoading={loadingOrFetching}
                    onSubmit={handleCreateSubmit}
                    closeModal={closeModal}
                />
            </Modal>

            <Modal
                onClose={() => setChannelSwitchModalVisible(null)}
                open={!!channelSwitchModalVisible}
                modalHeader={t['page_settings_typo_platforms_telegram_channels']}
            >
                <ChannelSwitchModal onAccept={() => {
                    handleCreateSubmit({
                        _id: channelSwitchModalVisible,
                        receiveChannelMessages: true,
                    }, 'UPDATE');
                    setChannelSwitchModalVisible(null)
                }} onClose={() => setChannelSwitchModalVisible(null)} />
            </Modal>

            <DeletePlatformModal
                isVisible={showDeleteModal}
                setShowModal={setShowDeleteModal}
                selectedDeleteItemId={selectedDeleteItemId as string}
                onComplate={handleDeleteSubmit}
            />
        </Fragment>
    );
};

export default Telegram;

import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import { LanguageContext } from '@helper/locale/langContext';
import { Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { useMutation } from '@apollo/client';
import { makeStyles } from '@material-ui/core/styles';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { TEST_CAMPAIGN } from '@queries/Campaigns/Service/GraphQL/mutation';
import React, { useContext, useState } from 'react';
import PhoneInputComp from '@components/PhoneInput';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';
import { useSnackbar } from 'notistack';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';

const useStyles = makeStyles((theme) => ({
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 16,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingInline: 20,
      paddingTop: 10,
      paddingBottom: 15,
   },
   modalBodyIconContainer: {
      display: 'flex',
      flexDirection: 'column',
   },
   modalDescription: {
      marginBottom: 55,
   },
   modalBodyRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
   },
   modalText: {
      color: '#797575',
      fontSize: '1rem',
      margin: 20,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const TestDialog = ({ open, onClose, id, time }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [createLoading, setCreateLoading] = useState(false);
   const [sendSuccess, setSendSuccess] = useState(false);
   const [getInput, setInput] = useState('');
   const { enqueueSnackbar } = useSnackbar();

   const [testCampaign] = useMutation(TEST_CAMPAIGN, {
      async update(
         cache,
         {
            data: {
               testCampaign: { data: testCampaign },
            },
         },
      ) {},
   });

   return (
      <Modal
         paperStyle={{ width: 450 }}
         open={open}
         disableEscapeKeyDown
         disableBackdropClick
         bodyStyle={{ height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}
         modalHeader={sendSuccess ? t['campaign_test_success'] : t['campaign_ready_to_test']}
      >
         <div>
            <div
               style={{
                  display: sendSuccess ? 'block' : 'none',
                  textAlign: 'center',
                  marginTop: 25,
               }}
            >
               <CheckCircle style={{ color: '#40D114', width: 100, height: 100 }} />
            </div>
            <div
               style={{
                  display: sendSuccess ? 'none' : 'block',
               }}
            >
               <ModalLargeText style={{ textAlign: 'center' }}>{t['campaign_test_button_desc']}</ModalLargeText>
               <PhoneInputComp
                  label={t['page_account_input_profile_phone']}
                  editable={true}
                  height={40}
                  width='100%'
                  name='phone'
                  value={getInput}
                  enableSearch={true}
                  onChange={(value) => setInput(value)}
               />
            </div>
         </div>
         <ModalButtonField style={{ marginTop: 16 }}>
            {!sendSuccess && (
               <ButtonCancel
                  label={t?.page_chat_btn_filter_cancel}
                  disabled={createLoading}
                  onClick={onClose}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     '&:hover': {
                        backgroundColor: '#e0e0e0',
                     },
                  }}
               />
            )}
            <ButtonOK
               className={classes.modalButtons}
               style={{
                  color: sendSuccess ? '#212121' : 'white',
                  backgroundColor: sendSuccess ? '#eeee' : '#157CFC',
                  '&:hover': {
                     backgroundColor: sendSuccess ? '#e0e0e0' : '#105CBB',
                  },
                  float: 'right',
               }}
               label={
                  sendSuccess ? t['comp_button_close'] : time ? t['campaign_schedule_for_send'] : t['campaign_send_now']
               }
               disabled={createLoading}
               startIcon={createLoading ? <CircularProgress color='inherit' size={20} /> : null}
               onClick={() => {
                  if (sendSuccess) {
                     onClose();
                     setCreateLoading(false);
                  } else {
                     setCreateLoading(true);
                     testCampaign({
                        variables: {
                           input: {
                              _id: id,
                              input: getInput,
                           },
                        },
                     })
                        .then((res) => {
                           setCreateLoading(false);
                           setSendSuccess(true);
                        })
                        .catch((err) => {
                           if (err.message === 'campaign_platform_error') {
                              enqueueSnackbar(t['campaign_platform_error'], {
                                 variant: 'error',
                                 autoHideDuration: 1500,
                              });
                           } else if (err.message === 'invalid_phone_number') {
                              enqueueSnackbar(t['invalid_phone'], {
                                 variant: 'error',
                                 autoHideDuration: 1500,
                              });
                           } else if (err.message === 'insufficient_balance') {
                              enqueueSnackbar(t['campaign_insufficient_balance'], {
                                 variant: 'error',
                                 autoHideDuration: 1500,
                              });
                           } else {
                              enqueueSnackbar(t['connection_progress_error'], {
                                 variant: 'error',
                                 autoHideDuration: 1500,
                              });
                           }
                           onClose();
                           setCreateLoading(false);
                        });
                  }
               }}
            />
         </ModalButtonField>
      </Modal>
   );
};

export default WithUrlTranslations(TestDialog);

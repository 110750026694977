import React, {FC, useContext} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {MyTheme} from "../../../../styles/config";

const useStyles = makeStyles((theme: MyTheme) => ({
   container: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
      backgroundColor: '#fff',
   },
}));

interface SettingBodyProps {}

const SettingBody: FC<SettingBodyProps> = ({ children }) => {
   const classes = useStyles();
   return (
      <div className={classes.container}>
         {children}
      </div>
   )
};

export default SettingBody;

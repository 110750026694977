import { gql } from '@apollo/client';

// mutations for notifications
export const SET_NOTIFICATION_SEEN_STATUS_MUTATION = gql`
   mutation changeNotificationSeenStatusMutation($_id: ID!) {
      setSeenStatus(_id: $_id) {
         data {
            _id
         }
         success
         code
         message
      }
   }
`;

export const CLEAR_ALL_NOTIFICATIONS_MUTATION = gql`
   mutation clearAllNotificationsMutation($person: ID!) {
      clearAllNotifications(person: $person) {
         data
         success
         code
         message
      }
   }
`;

export const DELETE_ALL_NOTIFICATIONS_MUTATION = gql`
   mutation deleteAllNotificationsMutation($person: ID!) {
      deleteAllNotifications(person: $person) {
         data
         success
         code
         message
      }
   }
`;

import { useMutation, useQuery, useSubscription, useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState } from 'react';
import { SITE_URL } from '@helper/config';
import { LanguageContext } from '@helper/locale/langContext';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import {
   DELETE_PLATFORM_MUTATION,
   UPDATE_PLATFORM_MUTATION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/mutation';
import {
   GET_PLATFORMS_QUERY,
   RUN_CUSTOM_PLATFORM_QUERY,
} from '../../../../queries/Settings/Service/GraphQL/Platform/query';
import { GET_TEAMS_QUERY } from '../../../../queries/Teams/query';
import {
   CREATED_PLATFORM_SUBSCRIPTION,
   DELETED_PLATFORM_SUBSCRIPTION,
   UPDATED_PLATFORM_SUBSCRIPTION,
} from '../../../../queries/Settings/Service/GraphQL/Platform/subscription';
import { PLATFORM_TYPE } from '../../../../queries/Settings/Service/GraphQL/Platform/types';
import ModalContent from './modalContent';
import TableData from './table';
import { useDispatch } from 'react-redux';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   selector: {
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 10,
      paddingLeft: 3,
      height: 32,
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      // fontWeight: 'bold',
   },
   button: {
      paddingTop: 10,
      width: 144,
      height: 30,
      color: 'white',
      borderRadius: 5,
      marginRight: 10,
   },
   buttonApply: {
      backgroundColor: '#487DBF',
      '&:hover': {
         backgroundColor: '#487DBF',
      },
   },
   ButtonContainer: {
      paddingLeft: 5,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const FacebookPages = ({ person, token }) => {
   const classes = useStyles();
   const [teams, setTeams] = useState([]);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const [isLoading, setIsLoading] = useState(false);
   const [isDirectMessagesModalVisible, setDirectMessagesModalVisible] = useState(null);
   const [isCommentModalVisible, setCommentModalVisible] = useState(null);
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const initialState = {
      show: false,
      status: '',
      errorMessage: '',
   };
   const dispatch = useDispatch();
   const [modalConfig, setModalConfig] = useState(initialState);

   const handleCloseModal = () => {
      setModalConfig(initialState);
   };

   const handleOpenModal = () => {
      setModalConfig((prevState) => ({
         ...prevState,
         show: true,
      }));
   };

   // get chat tags
   const [fetchFacebookLoginURL, { loading }] = useLazyQuery(RUN_CUSTOM_PLATFORM_QUERY, {
      variables: {
         metaData: {
            op: 'GET_LOGIN_URL',
            data: `${SITE_URL}/authentication/facebook`,
            teams: teams,
         },
         type: PLATFORM_TYPE.FACEBOOK,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (e) => {
         const win = window.open(e?.runCustomPlatformQuery?.data, '_blank');
         win.focus();
         handleCloseModal();
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   // get facebook pages
   const { loading: facebookPagesLoading, data: facebookPagesData } = useQuery(GET_PLATFORMS_QUERY, {
      variables: { type: PLATFORM_TYPE.FACEBOOK, customer: person.customer._id },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const [deleteFacebookPage, { loading: deleteFacebookPagesLoading }] = useMutation(DELETE_PLATFORM_MUTATION, {
      fetchPolicy: 'network-only',
      onError: (error) => {
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const [updateAccountMutation, { loading: updateAccountLoading }] = useMutation(UPDATE_PLATFORM_MUTATION);

   const updateSubsCache = (data, client, operation) => {
      try {
         let { getPlatforms } = client.cache.readQuery({
            query: GET_PLATFORMS_QUERY,
            variables: {
               type: PLATFORM_TYPE.FACEBOOK,
               customer: person ? person.customer._id : '',
            },
         }); // react cache query
         //Find index of specific object using findIndex method.
         let updatedData = [];
         if (operation === 'create') {
            data.metaData.pages = Array.isArray(data.metaData.pages) ? data.metaData.pages : [data.metaData.pages];
            updatedData = [...data.metaData.pages, ...getPlatforms.data];
         } else {
            let index = getPlatforms.data.findIndex((item) => item._id.toString() === data.metaData._id.toString());
            if (index > -1) {
               if (operation === 'update') {
                  updatedData = getPlatforms.data.map((item) => {
                     if (item._id.toString() === data.metaData._id.toString()) {
                        const toWrite = {
                           ...item,
                           ...data.metaData,
                        };
                        return toWrite;
                     }
                     return item;
                  });
               } else if (operation === 'delete') {
                  updatedData = getPlatforms.data.filter(
                     (item) => item._id.toString() !== data.metaData._id.toString(),
                  );
               }
            }
         }

         client.cache.writeQuery({
            query: GET_PLATFORMS_QUERY,
            variables: {
               type: PLATFORM_TYPE.FACEBOOK,
               customer: person ? person.customer._id : '',
            },
            data: { getPlatforms: { ...getPlatforms, data: updatedData } }, // add new data into old one
         });
      } catch (error) {
         console.log(error);
      }
   };

   useSubscription(CREATED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               createdPlatform: { data },
            },
         },
      }) => {
         updateSubsCache(data, client, 'create');
      },
   });

   useSubscription(UPDATED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedPlatform: { data },
            },
         },
      }) => {
         updateSubsCache(data, client, 'update');
      },
   });

   useSubscription(DELETED_PLATFORM_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               deletedPlatform: { data },
            },
         },
      }) => {
         updateSubsCache(data, client, 'delete');
      },
   });

   const handleClickFacebookLogin = (teams) => {
      dispatch({ type: 'CHANGE_FACEBOOK_EVENT_TYPE_AS_CREATE' });
      setTeams(teams);
      fetchFacebookLoginURL();
   };

   const handleUpdateFacebookPage = () => {
      dispatch({ type: 'CHANGE_FACEBOOK_EVENT_TYPE_AS_UPDATE' });
      fetchFacebookLoginURL();
   };

   // delete data on submit
   const handleDeleteFacebookPage = async (item, deleteType) => {
      deleteFacebookPage({
         variables: {
            type: PLATFORM_TYPE.FACEBOOK,
            metaData: {
               facebookPageId: item,
               customer: person.customer._id,
               deleteType,
            },
         },
      })
         .then(
            ({
               data: {
                  deletePlatform: {
                     data: { metaData },
                  },
               },
            }) => {
               enqueueSnackbar(`${metaData.name} ${t?.general_information_deleted_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               });
            },
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const updateAccountCommentFunction = (page) => {
      setIsLoading(true);
      updateAccountMutation({
         variables: {
            type: PLATFORM_TYPE.FACEBOOK,
            metaData: {
               _id: page._id,
               isCommentEnable: !page.isCommentEnable,
            },
         },
      })
         .then(({ data }) => {
            setIsLoading(false);
            setCommentModalVisible(null);
            enqueueSnackbar(t?.general_information_updated_successfully, {
               variant: 'success',
               autoHideDuration: 2500,
            });
         })
         .catch((err) => {
            setIsLoading(false);
            setCommentModalVisible(null);
            enqueueSnackbar(err.message, {
               variant: 'error',
               autoHideDuration: 2500,
            });
         });
   };

   const onChangeCommentSwitch = async (page) => {
      // activate
      if (!page.isCommentEnable) {
         updateAccountCommentFunction(page);
         return;
      }
      // deactivate
      else {
         setCommentModalVisible(page);
      }
   };

   const updateAccountMessageFunction = (page) => {
      setIsLoading(true);
      updateAccountMutation({
         variables: {
            type: PLATFORM_TYPE.FACEBOOK,
            metaData: {
               _id: page._id,
               isMessageEnable: !page.isMessageEnable,
            },
         },
      })
         .then(({ data }) => {
            setIsLoading(false);
            setDirectMessagesModalVisible(null);
            enqueueSnackbar(t?.general_information_updated_successfully, {
               variant: 'success',
               autoHideDuration: 2500,
            });
         })
         .catch((err) => {
            setIsLoading(false);
            setDirectMessagesModalVisible(null);
            enqueueSnackbar(err.message, {
               variant: 'error',
               autoHideDuration: 2500,
            });
         });
   };

   const onChangeMessageSwitch = async (page) => {
      if (!page.isMessageEnable) {
         updateAccountMessageFunction(page);
         return;
      }
      setDirectMessagesModalVisible(page);
   };

   const { loading: teamsLoading, data: teamsData } = useQuery(GET_TEAMS_QUERY, {
      variables: { customer: person.customer._id, pageSize: 100, page: 1 },
      fetchPolicy: 'cache-and-network',
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const loadingOrFetching =
      loading || facebookPagesLoading || deleteFacebookPagesLoading || updateAccountLoading || teamsLoading;

   if (loadingOrFetching) {
      return <div className='loading' />;
   }

   return (
      <Fragment>
         <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
            <div style={{ display: 'flex' }}>
               <Link to='/settings/channels-and-integrations' style={{ marginTop: 5 }}>
                  <ArrowBackIcon color='disabled' />
               </Link>
               <Typography style={{ marginLeft: 10, fontSize: '1.1rem', fontWeight: 'bold', lineHeight: '35px' }}>
                  Facebook Messenger
               </Typography>
            </div>
            <Grid className={classes.ButtonContainer}>
               <Typography
                  style={{
                     marginRight: 10,
                     color:
                        person.customer.usageFacebookPageCount < person.customer.package.facebookPageCount
                           ? 'initial'
                           : 'red',
                  }}
               >
                  {t?.team_limit} {person.customer.usageFacebookPageCount} / {person.customer.package.facebookPageCount}
               </Typography>
               <ButtonOK
                  onClick={handleOpenModal}
                  disabled={person.customer.usageFacebookPageCount >= person.customer.package.facebookPageCount}
                  width={200}
                  height={40}
                  fontSize='1.1rem'
                  label={t.page_settings_button_add_new}
               />
            </Grid>
         </Grid>
         <Grid>
            <TableData
               tableData={facebookPagesData?.getPlatforms?.data}
               handleUpdate={handleUpdateFacebookPage}
               handleDelete={handleDeleteFacebookPage}
               onChangeMessageSwitch={onChangeMessageSwitch}
               onChangeCommentSwitch={onChangeCommentSwitch}
            />
         </Grid>
         <Modal
            paperStyle={{ width: 600 }}
            open={modalConfig.show}
            onClose={handleCloseModal}
            modalHeader={t?.page_settings_facebook_add_account}
         >
            <ModalContent
               handleClickFacebookLogin={handleClickFacebookLogin}
               onClose={handleCloseModal}
               teamsData={teamsData?.getTeamList?.data?.docs}
            />
         </Modal>
         <Modal
            paperStyle={{ width: 450 }}
            onClose={() => setCommentModalVisible(null)}
            open={!!isCommentModalVisible}
            modalHeader={t['general_message_are_your_sure']}
         >
            <ModalLargeText style={{ textAlign: 'center', marginBlock: 40 }}>
               {t['general_information_comment_activate_modal_text']}
            </ModalLargeText>
            <ModalButtonField>
               <ButtonCancel
                  label={t?.general_message_no}
                  onClick={() => setCommentModalVisible(null)}
                  disabled={isLoading}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     opacity: isLoading ? 0.7 : 1,
                     '&:hover': {
                        backgroundColor: isLoading ? '#eeee' : '#e0e0e0',
                     },
                  }}
               />
               <ButtonOK
                  label={t['general_message_yes']}
                  className={classes.modalButtons}
                  disabled={isLoading}
                  style={{
                     color: 'white',
                     backgroundColor: '#157cfc',
                     opacity: isLoading ? 0.7 : 1,
                     '&:hover': {
                        backgroundColor: isLoading ? '#105CBB' : '#157cfc',
                     },
                     float: 'right',
                  }}
                  onClick={() => {
                     updateAccountCommentFunction(isCommentModalVisible);
                  }}
               />
            </ModalButtonField>
         </Modal>
         <Modal
            paperStyle={{ width: 450 }}
            onClose={() => setDirectMessagesModalVisible(null)}
            open={!!isDirectMessagesModalVisible}
            modalHeader={t['general_message_are_your_sure']}
         >
            <ModalLargeText style={{ textAlign: 'center', marginBlock: 40 }}>
               {t['general_information_direct_messages_activate_modal_text']}
            </ModalLargeText>
            <ModalButtonField>
               <ButtonCancel
                  label={t?.general_message_no}
                  onClick={() => setDirectMessagesModalVisible(null)}
                  disabled={isLoading}
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                     opacity: isLoading ? 0.7 : 1,
                     '&:hover': {
                        backgroundColor: isLoading ? '#eeee' : '#e0e0e0',
                     },
                  }}
               />
               <ButtonOK
                  label={t['general_message_yes']}
                  className={classes.modalButtons}
                  disabled={isLoading}
                  style={{
                     color: 'white',
                     opacity: isLoading ? 0.7 : 1,
                     backgroundColor: '#157cfc',
                     '&:hover': {
                        backgroundColor: isLoading ? '#105CBB' : '#157cfc',
                     },
                     float: 'right',
                  }}
                  onClick={() => {
                     updateAccountMessageFunction(isDirectMessagesModalVisible);
                  }}
               />
            </ModalButtonField>
         </Modal>
      </Fragment>
   );
};

export default FacebookPages;

import { createContext, Dispatch, SetStateAction } from 'react';

interface ChatRightPanelTabHeaderContext {
   onGoBackClick: () => void;
   setOnGoBackClick: Dispatch<SetStateAction<() => void>>;
   title: string;
   setTitle: Dispatch<SetStateAction<string>>;
}

const ChatRightPanelTabHeaderContext = createContext<ChatRightPanelTabHeaderContext | null>(null);

export default ChatRightPanelTabHeaderContext;

import {
   SET_CHAT_FILTERS,
   RESET_CHAT_FILTERS,
   CHANGE_CHAT_TAG,
   JOIN_CHAT,
   LEAVE_CHAT,
   ARCHIVE_CHAT,
   MESSAGED_TO_ME,
   SET_ACTIVE_CHAT,
   SET_ACTIVE_CHAT_MESSAGES,
   SET_MY_CHAT_LIST,
   SET_MY_CUSTOMER_CHAT_LIST,
   TRANSFER_CHAT,
   UPDATED_CHAT_STATUS,
   SET_ACTIVE_TEAM_CHAT,
   SET_ACTIVE_TEAM_CHAT_MESSAGES,
   MESSAGED_TO_ACTIVE_TEAM_CHAT,
   SET_ACTIVE_TEAM_CHAT_STARTUP,
   UPDATE_ACTIVE_CHAT_PARTICIPANTS,
   UPDATE_MY_CHAT_LIST_PARTICIPANTS,
   UPDATE_MY_CUSTOMER_CHAT_LIST_PARTICIPANTS,
   SEND_MESSAGE,
   SEND_TEAM_CHAT_MESSAGE,
   CHANGED_PHONE_STATUS,
   SET_EMAIL_DATA,
   SET_EMAIL_ACCOUNTS,
   SET_WHATSAPP_WEB_ACCOUNTS,
   SET_WHATSAPP_BUSINESS_ACCOUNTS,
   CREATE_WHATSAPP_MODAL_VISIBLE,
   CREATE_EMAIL_MODAL_VISIBLE,
   SET_MINE_CHAT_COUNT,
   SET_COMPANY_CHAT_COUNT,
} from '../actions/chat'; // action types
import _ from 'underscore';
import moment from 'moment';
import { PLATFORM_TYPE } from '@modules/Chat/types';

// default initial state
const INITIAL_STATE = {
   chatFilters: {
      me: '',
      teams: {
         backgroundColor: '#333',
         label: 'All teams',
         value: 'ALL',
      },
      page: 0,
      text: '',
      platform: 'ALL',
      tag: 'ALL',
      status: 0,
      startDate: moment().subtract(3, 'days').startOf('day').valueOf(),
      endDate: moment().endOf('day').valueOf(),
      desc: 'desc',
      updatedAt: moment().valueOf(),
   },
   myChatList: {
      chats: [],
      page: 0,
      hasNextPage: null,
      totalDocs: null,
      limit: null,
   },
   myCustomerChatList: {
      chats: [],
      page: 0,
      hasNextPage: null,
      totalDocs: null,
      limit: null,
   },
   customerChatCount: 0,
   mineChatCount: 0,
   activeChat: null,
   activeChatMessages: {},
   activeTeamChat: null,
   activeTeamChatMessages: {},
   emailData: null,
   emailAccounts: [],
   whatsappWebAccounts: [],
   whatsappBusinessAccounts: [],
   createEmailModalVisible: false,
   createWhatsappModalVisible: false,
};
//             CHAT STATUS
//             0 => aktif (active)
//             1 => kapanan (24 saat sonra) timeout
//             2 => Kapandı (arşivlenen) (Closed)
//             3 => spam
//             4 => engellenen (blocked)

const checkChatType = ({ type }) => {
   return (
      type === PLATFORM_TYPE.FACEBOOK ||
      type === PLATFORM_TYPE.INSTAGRAM ||
      type === PLATFORM_TYPE.WHATS_APP_WEB ||
      type === PLATFORM_TYPE.WHATS_APP_BUSINESS ||
      type === PLATFORM_TYPE.TELEGRAM ||
      type === PLATFORM_TYPE.LIVE_CHAT ||
      type === PLATFORM_TYPE.EMAIL
   );
};

const isTheChatFitTheFilter = (chat, filter, statusControl = true, ownerControl = false, myTeamsIds) => {
   const isParticipantValid =
      !ownerControl || (chat.participants[1]?._id && filter.me?.includes(chat.participants[1]._id));
   const isTeamValid =
      !myTeamsIds ||
      !chat.teams ||
      (filter.teams?.value === 'ALL' && myTeamsIds.some((myTeam) => chat.teams.some((team) => team?._id === myTeam))) ||
      chat.teams.some((team) => team._id === filter.teams?.value);
   const isStatusValid = !statusControl || filter.status === 'ALL' || chat.status === filter.status;
   const isTagValid = filter.tag === 'ALL' || chat.tag?._id === filter.tag;
   const isPlatformValid = filter.platform === 'ALL' || chat.type === filter.platform;
   const isCreatedAtValid =
      chat.lastMessage.createdAt &&
      moment(filter.startDate).isSameOrBefore(chat.lastMessage.createdAt, 'days') &&
      moment(filter.endDate).isSameOrAfter(chat.lastMessage.createdAt, 'days');
   return isParticipantValid && isTeamValid && isStatusValid && isTagValid && isPlatformValid && isCreatedAtValid;
};

const getIndexOfTheChatInTheList = (chat, chats) => {
   if (!chat || !chats) return undefined;
   const index = chats.findIndex((item) => item?._id === chat._id);
   return index === -1 ? undefined : index;
};

const findTheListIndexToMoveTheChat = (chat, chatList, chatFilter) => {
   if (chatList && Array.isArray(chatList) && chat?.lastMessage?.createdAt) {
      if (chatList.length === 0) {
         return 0;
      }
      let index;
      if (chatFilter.desc === 'desc') {
         index = chatList.findIndex((item) =>
            moment(chat?.lastMessage?.createdAt).isSameOrAfter(item?.lastMessage?.createdAt),
         );
      } else {
         index = chatList.findIndex((item) =>
            moment(chat?.lastMessage?.createdAt).isSameOrBefore(item?.lastMessage?.createdAt),
         );
      }
      return index === -1 ? chatList.length : index;
   }
   return undefined;
};

const updateActiveChatAndGetIt = (chat, activeChat) => {
   if (activeChat && activeChat._id === chat._id) {
      return { ...activeChat, ...chat };
   }
   return activeChat;
};

const removeChatFromListAndGetList = (chat, chats) => {
   if (chats) {
      const currentIndex = getIndexOfTheChatInTheList(chat, chats);
      if (currentIndex !== undefined) {
         chats.splice(currentIndex, 1);
      }
   }
   return chats;
};

const addChatToListAndGetList = (chat, chats, chatFilters, hasNextPage, merge = false) => {
   if (chats) {
      const currentIndex = getIndexOfTheChatInTheList(chat, chats);
      const oldChat = chats[currentIndex];
      if (currentIndex !== undefined) {
         chats.splice(currentIndex, 1);
      }
      const index = findTheListIndexToMoveTheChat(chat, chats, chatFilters);
      if (index !== undefined && !(hasNextPage && index === chats.length)) {
         const newChat = merge ? { ...oldChat, ...chat } : chat;
         chats.splice(index, 0, newChat);
      }
   }
   return chats;
};

const handleJoinChat = (state, { chat }) => {
   let activeChat = { ...state.activeChat };
   let myChats = [...state.myChatList.chats];
   let myCustomerChats = [...state.myCustomerChatList.chats];

   let changeCountOfMineChat = 0;
   let changeCountOfCompanyChat = 0;

   if (state.chatFilters.me.includes(chat?.participants[1]?._id)) {
      changeCountOfMineChat = 1;
      if (isTheChatFitTheFilter(chat, state.chatFilters, false, false, false) && (chat.previousStatus === state.chatFilters.status || state.chatFilters.status == 'ALL') && !chat.isRedirect) {
         changeCountOfCompanyChat = -1;
      }
   } else if (isTheChatFitTheFilter(chat, state.chatFilters, false, false, false) && (chat.previousStatus === state.chatFilters.status || state.chatFilters.status == 'ALL') && !chat.isRedirect) {
      changeCountOfCompanyChat = -1;
   }
   activeChat = updateActiveChatAndGetIt(chat, activeChat);
   myCustomerChats = removeChatFromListAndGetList(chat, myCustomerChats);
   if (checkChatType(chat) && isTheChatFitTheFilter(chat, state.chatFilters, false, true, false)) {
      myChats = addChatToListAndGetList(chat, myChats, state.chatFilters, state.myChatList.hasNextPage);
   }

   return {
      ...state,
      activeChat,
      ...(changeCountOfMineChat && {
         mineChatCount: state.mineChatCount + changeCountOfMineChat,
      }),
      ...(changeCountOfCompanyChat && {
         companyChatCount: state.companyChatCount + changeCountOfCompanyChat,
      }),
      myCustomerChatList: {
         ...state.myCustomerChatList,
         chats: myCustomerChats,
      },
      myChatList: {
         ...state.myChatList,
         chats: myChats,
      },
   };
};

const handleMoveFromMyChatsToMyCustomerChats = (state, { chat, myTeamsIds }) => {
   let activeChat = { ...state.activeChat };
   let myChats = [...state.myChatList.chats];
   let myCustomerChats = [...state.myCustomerChatList.chats];

   activeChat = updateActiveChatAndGetIt(chat, activeChat);
   myChats = removeChatFromListAndGetList(chat, myChats);

   let changeCountOfMineChat = 0;
   let changeCountOfCompanyChat = 0;

   if (state.chatFilters.me.includes(chat.previousParticipant)) {
      changeCountOfMineChat = -1;
   }

   if (isTheChatFitTheFilter(chat, state.chatFilters, false, false, false) && ((chat.participants.length == 2 ? chat.previousStatus === state.chatFilters.status : chat.status == state.chatFilters.status) || state.chatFilters.status == 'ALL')) {
      if (chat.previousParticipant != null) {
         changeCountOfCompanyChat = 1;
      }
   } else if (isTheChatFitTheFilter(chat, state.chatFilters, false, true, false) && (chat.previousStatus === state.chatFilters.status || state.chatFilters.status == 'ALL')) {
      changeCountOfCompanyChat = -1;
   } else if (isTheChatFitTheFilter(chat, state.chatFilters, false, false, false) && (chat.previousStatus === state.chatFilters.status || state.chatFilters.status == 'ALL') && chat.previousParticipant == null) {
      changeCountOfCompanyChat = -1;
   }

   if (
      checkChatType(chat) &&
      !chat.joinStatus &&
      isTheChatFitTheFilter(chat, state.chatFilters, true, false, myTeamsIds)
   ) {
      myCustomerChats = addChatToListAndGetList(
         chat,
         myCustomerChats,
         state.chatFilters,
         state.myCustomerChatList.hasNextPage,
      );
   } else {
      myCustomerChats = removeChatFromListAndGetList(chat, myCustomerChats);
   }

   return {
      ...state,
      activeChat,
      ...(changeCountOfMineChat && {
         mineChatCount: state.mineChatCount + changeCountOfMineChat,
      }),
      ...(changeCountOfCompanyChat && {
         companyChatCount: state.companyChatCount + changeCountOfCompanyChat,
      }),
      myCustomerChatList: {
         ...state.myCustomerChatList,
         chats: myCustomerChats,
      },
      myChatList: {
         ...state.myChatList,
         chats: myChats,
      },
   };
};

const handleTransferChat = (state, { chat }) => {
   let activeChat = { ...state.activeChat };
   let myChats = [...state.myChatList.chats];
   let myCustomerChats = [...state.myCustomerChatList.chats];

   activeChat = updateActiveChatAndGetIt(chat, activeChat);
   myCustomerChats = removeChatFromListAndGetList(chat, myCustomerChats);
   myChats = removeChatFromListAndGetList(chat, myChats);

   let changeCountOfMineChat = 0;
   let changeCountOfCompanyChat = 0;

   if (checkChatType(chat) && isTheChatFitTheFilter(chat, state.chatFilters, false, true, false)) {
      myChats = addChatToListAndGetList(chat, myChats, state.chatFilters, state.myChatList.hasNextPage);
   }

   let isChatFit = isTheChatFitTheFilter(chat, state.chatFilters, false, false, false);

   if (state.chatFilters.me.includes(chat?.participants[1]?._id)) {
      if (!state.chatFilters.me.includes(chat.previousParticipant)) {
         changeCountOfMineChat = 1;
      }
      if (chat.previousParticipant == null && isTheChatFitTheFilter(chat, state.chatFilters, false, false, false) && (chat.previousStatus === state.chatFilters.status || state.chatFilters.status === 'ALL')) {
         changeCountOfCompanyChat = -1;
      }
   } else if (state.chatFilters.me.includes(chat.previousParticipant)) {
      changeCountOfMineChat = -1;
      if (chat.previousParticipant == null && isTheChatFitTheFilter(chat, state.chatFilters, false, false, false) && (chat.previousStatus === state.chatFilters.status || state.chatFilters.status === 'ALL')) {
         changeCountOfCompanyChat = 1;
      }
   } else if (isChatFit && (chat.previousStatus === state.chatFilters.status || state.chatFilters.status === 'ALL')) {
      changeCountOfCompanyChat = -1;
   }

   return {
      ...state,
      activeChat,
      ...(changeCountOfMineChat && {
         mineChatCount: state.mineChatCount + changeCountOfMineChat,
      }),
      ...(changeCountOfCompanyChat && {
         companyChatCount: state.companyChatCount + changeCountOfCompanyChat,
      }),
      myCustomerChatList: {
         ...state.myCustomerChatList,
         chats: myCustomerChats,
      },
      myChatList: {
         ...state.myChatList,
         chats: myChats,
      },
   };
};

const handleChangeChatTag = (state, { chat, myTeamsIds }) => {
   let activeChat = { ...state.activeChat };
   let myChats = [...state.myChatList.chats];
   let myCustomerChats = [...state.myCustomerChatList.chats];

   activeChat = updateActiveChatAndGetIt(chat, activeChat);
   if (checkChatType(chat)) {
      if (chat.joinStatus === false && isTheChatFitTheFilter(chat, state.chatFilters, true, false, myTeamsIds)) {
         myCustomerChats = addChatToListAndGetList(
            chat,
            myCustomerChats,
            state.chatFilters,
            state.myCustomerChatList.hasNextPage,
            true,
         );
      } else {
         myCustomerChats = removeChatFromListAndGetList(chat, myCustomerChats);
      }
      if (chat.joinStatus === true && isTheChatFitTheFilter(chat, state.chatFilters, false, true, false)) {
         myChats = addChatToListAndGetList(chat, myChats, state.chatFilters, state.myChatList.hasNextPage, true);
      } else {
         myChats = removeChatFromListAndGetList(chat, myChats);
      }
   }

   return {
      ...state,
      activeChat,
      myCustomerChatList: {
         ...state.myCustomerChatList,
         chats: myCustomerChats,
      },
      myChatList: {
         ...state.myChatList,
         chats: myChats,
      },
   };
};

const handleMessageToMe = (state, { messagesPool, me, myTeamsIds }) => {
   let activeChat = { ...state.activeChat };
   let activeChatMessages = { ...state.activeChatMessages };
   let myChats = [...state.myChatList.chats];
   let myCustomerChats = [...state.myCustomerChatList.chats];

   let changeCountOfMineChat = 0;
   let changeCountOfCompanyChat = 0;

   const messagesGroupedByTheirChats = _.groupBy(messagesPool, function (message) {
      return message?.chat?._id;
   });
   const chatIds = Object.keys(messagesGroupedByTheirChats);

   chatIds.forEach((chatId) => {
      const lastMessageReceivedForThisChat = messagesGroupedByTheirChats[chatId][0];
      activeChat = updateActiveChatAndGetIt(lastMessageReceivedForThisChat.chat, activeChat);

      if (activeChatMessages[chatId]) {
         activeChatMessages[chatId].savedMessagesLength = state.activeChatMessages[chatId]?.savedMessagesLength
            ? state.activeChatMessages[chatId].savedMessagesLength + messagesGroupedByTheirChats[chatId].length
            : messagesGroupedByTheirChats[chatId].length;
         let numberOfMessagesISent = 0;
         messagesGroupedByTheirChats[chatId].forEach((message) => {
            // There is message.type !== 10 and message.type !== 3 added, because the request data from subscription
            // The last message in the chat is overwritten so subscription is broken

            //? Explanation of a statement: && (message?.sender._id === me._id || (message?.chat.status == 6 && message?.ack == 2))) {
            //! If message.chat.status == 6 that means its FlowResponse, We're only getting messages that ack == 2, or else it gets duplicated.

            if (message?.type !== 5 && message?.type !== 3 && message?.type !== 10 && (message?.sender._id === me._id || (message?.chat.status == 6 && message?.ack == 2))) {
               const i = activeChatMessages[chatId].messages.findIndex(
                  (item) => item.sendingStatusId === message?.sendingStatusId,
               );
               if (i !== undefined && i !== -1) {
                  numberOfMessagesISent += 1;
                  activeChatMessages[chatId].messages[i] = {
                     ...message,
                     isSending: false,
                  };
               }
            } else {
               activeChatMessages[chatId].messages.unshift(message);
            }
         });
         activeChatMessages[chatId].totalDocs =
            activeChatMessages[chatId].totalDocs + messagesGroupedByTheirChats[chatId].length - numberOfMessagesISent;
      }

      if (checkChatType(lastMessageReceivedForThisChat.chat)) {
         if (lastMessageReceivedForThisChat.chat.joinStatus === true) {
            myCustomerChats = removeChatFromListAndGetList(lastMessageReceivedForThisChat.chat, myCustomerChats);
            if (isTheChatFitTheFilter(lastMessageReceivedForThisChat.chat, state.chatFilters, false, true, false)) {
               myChats = addChatToListAndGetList(
                  lastMessageReceivedForThisChat.chat,
                  myChats,
                  state.chatFilters,
                  state.myChatList.hasNextPage,
               );
            } else {
               myChats = removeChatFromListAndGetList(lastMessageReceivedForThisChat.chat, myChats);
            }
         } else if (lastMessageReceivedForThisChat.chat?.teams) {
            myChats = removeChatFromListAndGetList(lastMessageReceivedForThisChat.chat, myChats);
            if (
               isTheChatFitTheFilter(lastMessageReceivedForThisChat.chat, state.chatFilters, true, false, myTeamsIds)
            ) {
               myCustomerChats = addChatToListAndGetList(
                  lastMessageReceivedForThisChat.chat,
                  myCustomerChats,
                  state.chatFilters,
                  state.myCustomerChatList.hasNextPage,
               );
            } else {
               myCustomerChats = removeChatFromListAndGetList(lastMessageReceivedForThisChat.chat, myCustomerChats);
            }
         }
      }
   });

   if (state.myCustomerChatList?.chats?.length != myCustomerChats.length) {
      changeCountOfCompanyChat = myCustomerChats.length - state.myCustomerChatList.chats.length;
   }

   if (state.myChatList?.chats?.length != myChats.length) {
      changeCountOfMineChat = myChats.length - state.myChatList.chats.length;
   }

   return {
      ...state,
      activeChat,
      activeChatMessages,
      ...(changeCountOfMineChat && {
         mineChatCount: state.mineChatCount + changeCountOfMineChat,
      }),
      ...(changeCountOfCompanyChat && {
         companyChatCount: state.companyChatCount + changeCountOfCompanyChat,
      }),
      myCustomerChatList: {
         ...state.myCustomerChatList,
         chats: myCustomerChats,
      },
      myChatList: {
         ...state.myChatList,
         chats: myChats,
      },
   };
};

const handleSendMessage = (state, message) => {
   let { activeChatMessages, activeChat } = state;
   if (message.type === 0 || message.type === 2 || message.type === 4 || message.type === 8) {
      if (activeChatMessages[activeChat._id]) {
         activeChatMessages[activeChat._id].messages = [message, ...activeChatMessages[activeChat._id].messages];
         activeChatMessages[activeChat._id].totalDocs = activeChatMessages[activeChat._id].totalDocs + 1;
      }
   }

   return {
      ...state,
      activeChatMessages,
   };
};

const handleMessageToActiveTeamChat = (state, { message, me }) => {
   let { activeTeamChatMessages, activeTeamChat } = state;
   // if eger ki state içinde daha önce eklenmişse state[activeChatId] ? true
   if (activeTeamChatMessages[message.chat._id]) {
      if (message.sender._id !== me._id.toString()) {
         activeTeamChatMessages[message.chat._id].messages = [
            message,
            ...activeTeamChatMessages[message.chat._id].messages,
         ];
         activeTeamChatMessages[message.chat._id].totalDocs = activeTeamChatMessages[message.chat._id].totalDocs + 1;
      } else {
         const messagesArr = activeTeamChatMessages[message.chat._id].messages.map((itemMessage) => {
            if (message.sendingStatusId === itemMessage.sendingStatusId) {
               return {
                  ...itemMessage,
                  isSending: false,
               };
            }
            return itemMessage;
         });
         activeTeamChatMessages[message.chat._id].messages = messagesArr;
      }

      return {
         ...state,
         activeTeamChatMessages: {
            [activeTeamChat._id]: {
               ...activeTeamChatMessages[message.chat._id],
            },
         },
      };
   }

   return state;
};

const handleSendTeamChatMessage = (state, message) => {
   let { activeTeamChat, activeTeamChatMessages } = state;

   if (activeTeamChatMessages[activeTeamChat._id]) {
      const newMessages = [message, ...activeTeamChatMessages[activeTeamChat._id].messages];
      return {
         ...state,
         activeTeamChatMessages: {
            [activeTeamChat._id]: {
               messages: newMessages,
               totalDocs: activeTeamChatMessages[activeTeamChat._id].totalDocs + 1,
            },
         },
      };
   }

   return state;
};

const updateActiveChatParticipants = (state, updateCustomerPerson) => {
   let { activeChat } = state;
   let participantIndex = activeChat?.participants.findIndex(
      (participant) => participant._id.toString() === updateCustomerPerson._id.toString(),
   );
   if (participantIndex > -1) {
      const newParticipants = activeChat.participants.map((participant) => {
         if (participant._id.toString() === updateCustomerPerson._id.toString()) {
            return {
               ...participant,
               ...updateCustomerPerson,
            };
         }
         return participant;
      });
      activeChat = {
         ...activeChat,
         participants: newParticipants,
      };
   }
   return {
      ...state,
      activeChat,
   };
};
// UPDATE MY CHAT LIST PARTICIPANTS
const updateMyChatListParticipants = (state, { updatePerson, activeChatId }) => {
   let {
      myChatList: { chats: myChatList },
   } = state;

   let myChatIndex = myChatList.findIndex((item) => item._id.toString() === activeChatId.toString());
   if (myChatIndex > -1) {
      let myChatParticipantIndex = myChatList[myChatIndex].participants.findIndex(
         (participant) => participant._id.toString() === updatePerson._id.toString(),
      );
      if (myChatParticipantIndex > -1) {
         const newMyChatList = myChatList.map((chatItem) => {
            if (chatItem._id.toString() === activeChatId.toString()) {
               const newParticipants = chatItem.participants.map((participant) => {
                  if (participant._id.toString() === updatePerson._id.toString()) {
                     return {
                        ...participant,
                        ...updatePerson,
                     };
                  }
                  return participant;
               });

               return {
                  ...chatItem,
                  participants: newParticipants,
               };
            }
            return chatItem;
         });
         myChatList = newMyChatList;
      }
   }
   return {
      ...state,
      myChatList: {
         ...state.myChatList,
         chats: myChatList,
      },
   };
};

// UPDATE CUSTOMER CHAT LIST PARTICIPANTS
const updateMyCustomerChatListParticipants = (state, { updatePerson, activeChatId }) => {
   let {
      myCustomerChatList: { chats: myCustomerChatList },
   } = state;

   let myChatIndex = myCustomerChatList.findIndex((item) => item._id.toString() === activeChatId.toString());
   if (myChatIndex > -1) {
      let myChatParticipantIndex = myCustomerChatList[myChatIndex].participants.findIndex(
         (participant) => participant._id.toString() === updatePerson._id.toString(),
      );
      if (myChatParticipantIndex > -1) {
         const newMyCustomerChatList = myCustomerChatList.map((chatItem) => {
            if (chatItem._id.toString() === activeChatId.toString()) {
               const newParticipants = chatItem.participants.map((participant) => {
                  if (participant._id.toString() === updatePerson._id.toString()) {
                     return {
                        ...participant,
                        ...updatePerson,
                     };
                  }
                  return participant;
               });

               return {
                  ...chatItem,
                  participants: newParticipants,
               };
            }
            return chatItem;
         });
         myCustomerChatList = newMyCustomerChatList;
      }
   }
   return {
      ...state,
      myCustomerChatList: {
         ...state.myCustomerChatList,
         chats: myCustomerChatList,
      },
   };
};

const handleChangedPhoneStatus = (state, phone) => {
   let { activeChat } = state;

   if (activeChat?.type === PLATFORM_TYPE.WHATS_APP_WEB) {
      if (activeChat?.whatsAppPhone?._id.toString() === phone?._id.toString()) {
         activeChat = {
            ...activeChat,
            whatsAppPhone: {
               ...activeChat.whatsAppPhone,
               status: phone.status,
            },
         };
      }
   }

   return {
      ...state,
      activeChat,
   };
};

export default (state = INITIAL_STATE, action = {}) => {
   switch (action.type) {
      case CREATE_EMAIL_MODAL_VISIBLE:
         return {
            ...state,
            createEmailModalVisible: action.payload,
         };
      case SET_EMAIL_ACCOUNTS:
         return {
            ...state,
            emailAccounts: action.payload,
         };
      case CREATE_WHATSAPP_MODAL_VISIBLE:
         return {
            ...state,
            createWhatsappModalVisible: action.payload,
         };
      case SET_WHATSAPP_WEB_ACCOUNTS:
         return {
            ...state,
            whatsappWebAccounts: action.payload,
         };
      case SET_WHATSAPP_BUSINESS_ACCOUNTS:
         return {
            ...state,
            whatsappBusinessAccounts: action.payload,
         };
      case SET_EMAIL_DATA:
         return {
            ...state,
            emailData: action.payload,
         };
      case SET_CHAT_FILTERS:
         return {
            ...state,
            chatFilters: { ...state.chatFilters, ...action.payload, updatedAt: moment().valueOf() },
         };
      case RESET_CHAT_FILTERS:
         return {
            ...state,
            chatFilters: INITIAL_STATE.chatFilters,
         };
      case SET_MY_CHAT_LIST:
         return {
            ...state,
            myChatList: action.payload.myChatList,
         };
      case SET_MY_CUSTOMER_CHAT_LIST:
         return {
            ...state,
            myCustomerChatList: action.payload.myCustomerChatList,
         };
      case UPDATE_ACTIVE_CHAT_PARTICIPANTS:
         return updateActiveChatParticipants(state, action.payload);
      case UPDATE_MY_CHAT_LIST_PARTICIPANTS:
         return updateMyChatListParticipants(state, action.payload);
      case UPDATE_MY_CUSTOMER_CHAT_LIST_PARTICIPANTS:
         return updateMyCustomerChatListParticipants(state, action.payload);
      case JOIN_CHAT:
         return handleJoinChat(state, action.payload);
      case LEAVE_CHAT:
         return handleMoveFromMyChatsToMyCustomerChats(state, action.payload);
      case ARCHIVE_CHAT:
         return handleMoveFromMyChatsToMyCustomerChats(state, action.payload);
      case TRANSFER_CHAT:
         return handleTransferChat(state, action.payload);
      case CHANGE_CHAT_TAG:
         return handleChangeChatTag(state, action.payload);
      case UPDATED_CHAT_STATUS:
         return handleMoveFromMyChatsToMyCustomerChats(state, action.payload);
      case MESSAGED_TO_ME:
         return handleMessageToMe(state, action.payload);
      case SET_ACTIVE_CHAT:
         return {
            ...state,
            activeChat: { ...state.activeChat, ...action.payload.activeChat },
         };
      case SET_ACTIVE_CHAT_MESSAGES:
         return {
            ...state,
            activeChatMessages: {
               ...state.activeChatMessages,
               [action.payload.activeChatId]: {
                  savedMessagesLength: action.payload.messages?.length,
                  ...action.payload,
               },
            },
         };
      case SET_ACTIVE_TEAM_CHAT:
         return {
            ...state,
            activeTeamChat: action.payload.activeTeamChat,
         };
      case SET_COMPANY_CHAT_COUNT:
         return {
            ...state,
            ...(action.payload.assignCount != undefined && {
               companyChatCount: action.payload.assignCount,
            }),
            ...(action.payload.addCount != undefined && {
               companyChatCount: state.companyChatCount + action.payload.addCount,
            }),
         }
      case SET_MINE_CHAT_COUNT:
         return {
            ...state,
            ...(action.payload.assignCount != undefined && {
               mineChatCount: action.payload.assignCount,
            }),
            ...(action.payload.addCount != undefined && {
               mineChatCount: state.mineChatCount + action.payload.addCount,
            }),
         }
      case SET_ACTIVE_TEAM_CHAT_STARTUP:
         return {
            ...state,
            activeTeamChat: {
               ...state.activeTeamChat,
               firstTime: action.payload,
            },
         };
      case SET_ACTIVE_TEAM_CHAT_MESSAGES:
         return {
            ...state,
            activeTeamChatMessages: {
               ...state.activeTeamChatMessages,
               [action.payload.activeChatId]: action.payload.activeTeamChatMessages,
            },
         };
      case MESSAGED_TO_ACTIVE_TEAM_CHAT:
         return handleMessageToActiveTeamChat(state, action.payload);
      case SEND_MESSAGE:
         return handleSendMessage(state, action.payload);
      case SEND_TEAM_CHAT_MESSAGE:
         return handleSendTeamChatMessage(state, action.payload);
      case CHANGED_PHONE_STATUS:
         return handleChangedPhoneStatus(state, action.payload);
      default:
         return state;
   }
};

export const getChatState = (state) => state.chatReducer;
export const getChatFilters = (state) => state.chatReducer.chatFilters;
export const getActiveChat = (state) => state.chatReducer.activeChat;
export const getActiveChatMessages = (state) => state.chatReducer.activeChatMessages;
export const getMyCustomerChatList = (state) => state.chatReducer.myCustomerChatList;
export const getMyChatList = (state) => state.chatReducer.myChatList;
export const getActiveTeamChat = (state) => state.chatReducer.activeTeamChat;
export const getActiveTeamChatMessages = (state) => state.chatReducer.activeTeamChatMessages;
export const getCompanyChatCount = (state) => state.chatReducer.companyChatCount;
export const getMineChatCount = (state) => state.chatReducer.mineChatCount;
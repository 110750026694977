import React, { useContext, useState } from 'react';
import { Grid, Typography, ButtonBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import classNames from 'classnames';
import FormSaveAndCancelButtons from '../SharedComponents/FormSaveAndCancelButtons';
import SelectLanguages from './SelectLanguages';
import TranslationTabView from './TranslationTabView';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import LiveChatSettingsContext from '../../../../../contexts/LiveChatSettingsContext';

const useStyles = makeStyles(() => ({
   formContainer: {
      flexGrow: 1,
      overflowY: 'auto',
      padding: '20px 30px',
   },
   accordion: {
      marginTop: '10px',
      background: '#F7F7FA',
      borderRadius: '8px',
   },
   accordionHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   accordionBorderBottom: {
      borderBottom: '1px solid #EDEDED',
   },
   accordionTitleContainer: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
      padding: '5px 20px',
   },
   accordionTitle: {
      fontSize: '1.1rem',
      color: '#000000',
      fontWeight: 'bold',
   },
   accordionHeaderRight: {
      flexShrink: 0,
      width: '56px',
      height: '56px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },
   arrowButton: {
      borderRadius: 99,
      padding: 6,
   },
   arrowIcon: {
      color: '#7C819D',
      fontSize: '24px',
      transition: '300ms',
      transform: 'rotate(90deg)',
   },
   arrowIconUp: {
      transform: 'rotate(270deg)',
   },
   accordionBody: {
      padding: '20px 20px 0 20px',
   },
}));

const Form = () => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [isLanguageOpen, setIsLanguageOpen] = useState(true);
   const [isTranslationOpen, setIsTranslationOpen] = useState(true);
   const [resetLanguagesSelectForm, setResetLanguagesSelectForm] = useState();
   const [resetTranslationForm, setResetTranslationForm] = useState();
   const [translationFormLanguage, setTranslationFormLanguage] = useState();
   const { liveChatPreviewSettings, updateLiveChatPreviewSettings } = useContext(LiveChatSettingsContext);

   const resetValues = (values) => {
      resetLanguagesSelectForm({ selectedLanguages: values.selectedLanguages });
      resetTranslationForm({ ...values.translation[translationFormLanguage] });
   };

   return (
      <>
         <div className={classes.formContainer}>
            <Grid container alignItems='center' spacing={1}>
               <Grid item xs={12} className={classes.accordion}>
                  <div
                     className={classNames(
                        classes.accordionHeaderContainer,
                        isLanguageOpen && classes.accordionBorderBottom,
                     )}
                  >
                     <div className={classes.accordionTitleContainer}>
                        <Typography className={classes.accordionTitle}>
                           {t['page_settings_typo_platforms_live_chat_languages_languages_title']}
                        </Typography>
                     </div>
                     <div className={classes.accordionHeaderRight}>
                        <ButtonBase onClick={() => setIsLanguageOpen(!isLanguageOpen)} className={classes.arrowButton}>
                           <ArrowForwardIosRoundedIcon
                              className={classNames(classes.arrowIcon, isLanguageOpen && classes.arrowIconUp)}
                           />
                        </ButtonBase>
                     </div>
                  </div>

                  {isLanguageOpen && (
                     <Grid container className={classes.accordionBody}>
                        <SelectLanguages setReset={setResetLanguagesSelectForm} />
                     </Grid>
                  )}
               </Grid>

               <Grid item xs={12} className={classes.accordion}>
                  <div
                     className={classNames(
                        classes.accordionHeaderContainer,
                        isTranslationOpen && classes.accordionBorderBottom,
                     )}
                  >
                     <div className={classes.accordionTitleContainer}>
                        <Typography className={classes.accordionTitle}>
                           {t['page_settings_typo_platforms_live_chat_languages_translation_title']}
                        </Typography>
                     </div>
                     <div className={classes.accordionHeaderRight}>
                        <ButtonBase
                           onClick={() => setIsTranslationOpen(!isTranslationOpen)}
                           className={classes.arrowButton}
                        >
                           <ArrowForwardIosRoundedIcon
                              className={classNames(classes.arrowIcon, isTranslationOpen && classes.arrowIconUp)}
                           />
                        </ButtonBase>
                     </div>
                  </div>
                  {isTranslationOpen && (
                     <TranslationTabView
                        setReset={setResetTranslationForm}
                        setTranslationFormLanguage={setTranslationFormLanguage}
                     />
                  )}
               </Grid>
            </Grid>
         </div>
         <FormSaveAndCancelButtons resetValues={resetValues} />
      </>
   );
};

export default Form;

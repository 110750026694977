import React, { useState, useEffect, useContext, memo } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import { makeStyles } from '@material-ui/core/styles';
import { LanguageContext } from '@helper/locale/langContext';
import {
   FileBlockProps,
   FlowBlockType,
   IFlowBlockFieldTypes,
   IFlowBlockTemplate,
} from '@modules/Automation/FlowBots/types';
import { Grid } from '@material-ui/core';
import { SET_CLICKED_NODE_ID } from '@store/actions/automation';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
   container: {
      position: 'relative',
      display: 'inline-block',
      background: 'rgba(255, 255, 255, 0.6)',
   },
   sendIcon: {
      width: 20,
      height: 20,
      margin: '0 5px 5px 5px',
   },
   customHeader: {
      display: 'flex',
      borderBottom: '0.1px solid #f2f2f2',
   },
   addText: {
      padding: '10px',
      margin: '5px',
      textAlign: 'center',
      borderRadius: 2,
      fontSize: 7,
      color: '#AFAFAF',
      backgroundColor: '#F7F7FA',
   },
   deleteIcon: {
      position: 'absolute',
      top: '-15px',
      right: '46%',
      cursor: 'pointer',
   },
   nodeContainer: {
      border: '1px solid #ffffff',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   InvalidNodeContainer: {
      border: '1px solid #FF7474',
      width: '165px',
      padding: '5px 0',
      borderRadius: '5px',
      background: 'rgba(255, 255, 255, 0.6)',
      boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
      transition: 'border 0.3s',
   },
   nodeContainerHovered: {
      border: '1px solid #157cfc',
      background: 'rgba(255, 255, 255, 0.6)',
   },
   footer: {
      borderTop: ' 0.1px solid #f2f2f2',
      height: '15px',
      position: 'relative',
   },
   handleBlock: {
      position: 'absolute',
      top: '10px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#fff',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleBlockQR: {
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#C3DDFF',
      width: '8px',
      height: '8px',
      border: '1px solid #147BFB',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleEdgeConnectedQR: {
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#147BFB',
      width: '8px',
      height: '8px',
      border: '1px solid #147BFB',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleEdgeConnected: {
      position: 'absolute',
      top: '10px',
      cursor: 'default',
      display: 'flex',
      justifyContent: 'flex-end',
      marginRight: '10px',
      backgroundColor: '#ADADAD',
      width: '8px',
      height: '8px',
      border: '1px solid #ADADAD',
      transition: 'transform 0.3s',
      borderRadius: '50%',
      '&:hover': {
         marginRight: '9px',
         width: '10px',
         height: '10px',
      },
   },
   handleTarget: {
      backgroundColor: '#fff',
      width: '5px',
      height: '5px',
      top: 14,
      marginLeft: '5px',
   },
   rawText: {
      backgroundColor: '#F7F7FA',
      borderRadius: '5px',
      margin: '0 5px',
   },
   textarea: {
      backgroundColor: 'transparent',
      padding: '5px',
      fontSize: 7,
      border: 'none',
      outline: 'none',
      resize: 'none',
      height: '100%',
      overflow: 'hidden',
      whiteSpace: 'pre-wrap',
      display: 'block',
      maxWidth: '100%',
   },
   preview: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      textAlign: 'center',
      borderRadius: '5px',
      margin: '0 5px',
      padding: '12px',
      fontSize: 7,
      border: '0.1px solid #DDDDDD',
   },
   previewImage: {
      margin: '0 5px',
      display: 'flex',
      flexDirection: 'column',
   },
   iconBox: {
      padding: '1px',
      margin: '0 5px 5px 5px',
      borderRadius: '3px',
      display: 'flex',
      justifyContent: 'center',
   },
   blocks: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 5px',
      fontSize: '7px',
   },
   reply: {
      display: 'flex',
      position: 'relative',
      borderRadius: '5px',
      background: '#C3DDFF',
      padding: '5px',
      width: 'max-content',
      maxWidth: '135px',
      textAlign: 'left',
      paddingRight: '20px',
   },
}));

interface WelcomeMessageNodeProps {
   id: string;
   isConnectable: boolean;
   data: {
      clickedNodeId: string;
      title: string;
      isValidNode: Boolean;
      flowBlocks: IFlowBlockTemplate[];
      setFileBlock: React.Dispatch<React.SetStateAction<FileBlockProps[]>>;
      setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
      setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
      setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
      setIsDraggable: React.Dispatch<React.SetStateAction<boolean>>;
      setHandleIndexToUpdate: React.Dispatch<React.SetStateAction<number>>;
   };
}

const WelcomeMessageNode: React.FC<WelcomeMessageNodeProps> = ({ id, isConnectable, data }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const {
      title,
      clickedNodeId,
      flowBlocks,
      setClickedHandleId,
      isValidNode,
      setHandleIndexToUpdate,
      setClickedNodeId,
   } = data;
   const reactFlow = useReactFlow();
   const [isHover, setIsHover] = useState(false);

   const [blocks, setBlocks] = useState<IFlowBlockTemplate[]>([]);
   useEffect(() => {
      setBlocks(flowBlocks);
   }, [flowBlocks]);

   const clickedId = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.clickedNodeId);
   const botStatus = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.botSatus);

   useEffect(() => {
      setIsHover(id === clickedId);
   }, [data, id, clickedId]);

   const limitedTitle = title.length > 21 ? `${title.slice(0, 19)}...` : title;
   const fieldHasValue = flowBlocks.some((item) => item.fields.some((field) => field.value && field.value.length > 0));

   const calculateTextareaSize = (content: string | undefined) => {
      if (content) {
         let numRows = 0;
         let consecutiveChars = 0;

         for (let i = 0; i < content.length; i++) {
            if (content[i] !== '\n') {
               consecutiveChars++;
               if (consecutiveChars === 34) {
                  numRows++;
                  consecutiveChars = 0;
               }
            } else {
               consecutiveChars = 0;
            }
         }

         const enters = (content.match(/\n/g) || []).length;

         numRows += enters;

         const contentLines = content.split('\n');
         const maxCols = Math.max(...contentLines.map((line) => line.length));

         return { rows: numRows + 2, cols: maxCols };
      }
      return { rows: 2, cols: 10 };
   };

   const handleMouseOnHandle = (id: string, handleIndex: number) => {
      setClickedHandleId(id);
      setHandleIndexToUpdate(handleIndex);
   };

   const dispatch = useDispatch();
   const handleClicked = () => {
      if (botStatus === 'draft') {
         setClickedNodeId(id);
         dispatch({ type: SET_CLICKED_NODE_ID, payload: id });
      }
   };

   const currentNode = reactFlow.getNode(id);
   useEffect(() => {
      if (currentNode?.selected) {
         setIsHover(true);
      }
   }, [currentNode]);
   return (
      <div
         onMouseEnter={() => setIsHover(true)}
         onMouseLeave={() => (id === clickedId || currentNode?.selected ? setIsHover(true) : setIsHover(false))}
         className={classes.container}
         onClick={handleClicked}
      >
         <div
            className={`${classes.nodeContainer} ${isHover && isValidNode ? classes.nodeContainerHovered : ''} ${
               !isValidNode ? classes.InvalidNodeContainer : ''
            }`}
         >
            <div className={classes.customHeader}>
               <Handle
                  type='target'
                  position={Position.Left}
                  id={id}
                  className={classes.handleTarget}
                  //isConnectable={isConnectable}
               />
               <div className={classes.iconBox} style={{ backgroundColor: '#fff8d9' }}>
                  <span role='img' aria-label='welcome message icon' style={{ fontSize: '13px' }}>
                     🤚
                  </span>
               </div>
               <div style={{ marginTop: '3px', fontSize: 9 }}>{limitedTitle}</div>
            </div>

            {Array.isArray(blocks) && blocks.length > 0 && fieldHasValue ? (
               <div style={{ margin: '5px 0 0 0' }}>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: '3px' }}>
                     {blocks.map((item) => (
                        <div key={item._id}>
                           {item.fields.map((field) => (
                              <Grid key={item._id}>
                                 {field.type === IFlowBlockFieldTypes.Text && field.value && field.value.length > 0 && (
                                    <div className={classes.rawText}>
                                       <textarea
                                          value={field.value}
                                          className={classes.textarea}
                                          readOnly
                                          {...calculateTextareaSize(field.value)}
                                       />
                                    </div>
                                 )}
                              </Grid>
                           ))}
                        </div>
                     ))}
                  </div>
                  <div style={{ margin: '5px 0' }}>
                     {blocks.map((block) => (
                        <div key={block._id} className={classes.blocks}>
                           {block.type === FlowBlockType.SendQuickReplyMessage && (
                              <div>
                                 {block.fields.map((field, index) => (
                                    <div>
                                       <div
                                          key={index}
                                          style={{
                                             display: 'flex',
                                             flexDirection: 'column',
                                             gap: '0.3rem',
                                             textAlign: 'right',
                                             marginLeft: 'auto',
                                             width: 'max-content',
                                          }}
                                       >
                                          {field.quickReplies?.map((quick, quickIndex) => (
                                             <div
                                                style={{
                                                   display: 'flex',
                                                   justifyContent: 'flex-end',
                                                }}
                                             >
                                                <div className={classes.reply} key={quickIndex}>
                                                   <span>{quick.title}</span>
                                                   <Handle
                                                      type='source'
                                                      position={Position.Right}
                                                      id={block._id + quickIndex}
                                                      className={
                                                         quick.target
                                                            ? classes.handleEdgeConnectedQR
                                                            : classes.handleBlockQR
                                                      }
                                                      isConnectable={isConnectable}
                                                      onMouseEnter={() =>
                                                         handleMouseOnHandle(block._id + quickIndex, quickIndex)
                                                      }
                                                   />
                                                </div>
                                             </div>
                                          ))}
                                       </div>
                                    </div>
                                 ))}
                              </div>
                           )}
                        </div>
                     ))}
                  </div>
               </div>
            ) : (
               <div className={classes.addText}>{t.atomation_flow_add_content}</div>
            )}
         </div>
      </div>
   );
};
export default memo(WelcomeMessageNode);

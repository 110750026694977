import { Avatar, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import clsx from 'clsx';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getRandomChatColor } from '@helper/functions';
import { trimLongText } from '@helper/textUtils';
import { PLATFORM_TYPE } from '../../modules/Chat/types';
import { QnAPlatformType } from '../../modules/Questions/util/types';
import SocialMediaIcon from '../SocialMediaIcon';
import UserOnlineStatus from '../UserOnlineStatus';
import { LanguageContext } from '@helper/locale/langContext';
import OnlineCircle from '@components/FullAvatar/OnlineCircle';
const useStyles = makeStyles((theme) => ({
   xSmall: {
      width: 30,
      height: 30,
   },
   small: {
      width: 36,
      height: 36,
   },
   medium: {
      width: 50,
      height: 50,
   },
   smallMedium: {
      width: 42,
      height: 42,
   },
   large: {
      width: 96,
      height: 96,
      // marginRight:10,
   },
   xSmallSpacer: {
      position: 'absolute',
      top: -30,
      left: 5,
      background: '#fff',
      borderRadius: 10,
   },
   smallSpacer: {
      position: 'absolute',
      top: -17,
      left: 27,
      background: '#fff',
      borderRadius: 10,
   },
   largeSpacer: {
      position: 'absolute',
      top: -40,
      left: 10,
      background: '#fff',
      borderRadius: 100,
   },
   smallMediumSpacer: {
      position: 'absolute',
      top: -30,
      left: 20,
      background: '#fff',
      borderRadius: 10,
   },
   mediumSpacer: {
      position: 'absolute',
      top: -30,
      left: 20,
      background: '#fff',
      borderRadius: 10,
   },
   //
   smallOnlineStatus: {
      position: 'absolute',
      top: -35,
      left: 9,
   },
   mediumOnlineStatus: {
      position: 'absolute',
      top: -47,
      left: 10,
   },
   socialMediaBox: {
      backgroundColor: 'white',
      padding: 'auto',
      position: 'relative',
      display: 'inline',
   },
   box: {
      background: '#fff',
      borderRadius: 10,
   },
   //
   borderColor: {
      border: (props) =>
         '3px solid ' + (props.onlineStatus === 2 ? '#00ff38' : props.onlineStatus === 1 ? '#F81032' : '#707070'),
   },
   textColor: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
}));

const MediumAvatar = (props) => {
   let {
      navBarAvatar,
      image,
      name,
      surname,
      isCustomer,
      title,
      onlineStatus,
      socialMedia,
      avatarBorder,
      hideCircle,
      size,
      caret,
      reverse,
      userName,
      isQna,
      showOnlineStatus,
      online,
   } = props;
   const chat = useSelector((reducer) => reducer.chatReducer.activeChat);
   const question = useSelector((reducer) => reducer.questionsState.activeQuestion);
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const classes = useStyles(props);
   const socialMediaAvatarSize = size === 'large' ? 40 : 20;
   const avatarClass = classes[size];
   const spacerClass = classes[size + 'Spacer'];
   const onlineStatusClass = classes[size + 'OnlineStatus'];
   const location = useLocation();
   let channelName = '';

   const activeChat = isQna ? question : chat;

   if (!isQna) {
      if (activeChat?.type === PLATFORM_TYPE.WHATS_APP_WEB && navBarAvatar) {
         channelName = activeChat?.whatsAppPhone?.name;
      } else if (activeChat?.type === PLATFORM_TYPE.WHATS_APP_BUSINESS && navBarAvatar) {
         channelName = activeChat?.whatsAppBusinessPhone?.name;
      } else if (activeChat?.type === PLATFORM_TYPE.FACEBOOK && navBarAvatar) {
         channelName = activeChat?.facebookPage?.name;
      } else if (activeChat?.type === PLATFORM_TYPE.INSTAGRAM && navBarAvatar) {
         channelName = activeChat?.instagramPage?.name;
      } else if (activeChat?.type === PLATFORM_TYPE.EMAIL && navBarAvatar) {
         channelName = activeChat?.emailAccount?.senderName;
      } else if (activeChat?.type === PLATFORM_TYPE.LIVE_CHAT && navBarAvatar) {
         channelName = t?.page_settings_typo_platforms_live_chat;
      } else if (activeChat?.type === PLATFORM_TYPE.TELEGRAM && navBarAvatar) {
         channelName = activeChat?.telegramAccount?.firstName;
      }
   }

   if (isQna) {
      if (activeChat?.platformType === QnAPlatformType.TRENDYOL && navBarAvatar) {
         channelName = activeChat?.trendyolAccount?.storeName;
      } else if (activeChat?.platformType === QnAPlatformType.HEPSIBURADA && navBarAvatar) {
         channelName = activeChat?.hepsiburadaAccount?.storeName;
      } else if (activeChat?.platformType === QnAPlatformType.FACEBOOK && navBarAvatar) {
         channelName = activeChat?.facebookPage?.name;
      } else if (activeChat?.platformType === QnAPlatformType.INSTAGRAM && navBarAvatar) {
         channelName = activeChat?.instagramPage?.name;
      }
   }

   title = title && trimLongText(title);
   channelName = trimLongText(channelName, 15);

   const TitleAndUsername = (
      <Grid
         item
         container
         direction='column'
         alignItems='flex-start'
         justifyContent='flex-start'
         style={{ marginTop: 0 }}
      >
         <Grid item>
            {title && (
               <Typography
                  align='center'
                  variant='subtitle1'
                  className={classes.textColor}
                  noWrap
                  style={{
                     height: 24,
                     marginRight: 10,
                     marginLeft: 10,
                  }}
               >
                  {title}
                  {title && showOnlineStatus && <OnlineCircle online={online} style={{ marginLeft: 10 }} size={10} />}
               </Typography>
            )}
         </Grid>
         <Grid item>
            {userName && (
               <Typography
                  align='left'
                  variant='subtitle1'
                  style={{
                     width: 120,
                     height: 24,
                     color: 'grey',
                     fontSize: '0.8rem',
                     marginRight: 10,
                     marginLeft: 10,
                  }}
               >
                  {userName}
                  {userName && showOnlineStatus && (
                     <OnlineCircle online={online} style={{ marginLeft: 10 }} size={10} />
                  )}
               </Typography>
            )}
         </Grid>
         <Grid item>
            {channelName && (
               <Typography
                  align='left'
                  variant='subtitle1'
                  style={{
                     width: 120,
                     height: 24,
                     color: 'grey',
                     fontSize: '0.8rem',
                     marginRight: 10,
                     marginLeft: 10,
                  }}
               >
                  {channelName}
               </Typography>
            )}
         </Grid>
      </Grid>
   );

   return (
      <Grid container direction='row' className={classes.textColor} wrap='nowrap' alignItems='center'>
         {reverse ? TitleAndUsername : null}
         <Grid>
            {!isCustomer && (
               <Avatar alt={title} src={image} className={clsx(avatarBorder && classes.borderColor, avatarClass)} />
            )}
            {isCustomer && (
               <div
                  style={{
                     backgroundColor: activeChat?.color
                        ? activeChat?.color
                        : getRandomChatColor(location.pathname) || 'red',
                     width: size === 'small' ? 42 : 72,
                     height: size === 'small' ? 42 : 72,
                     borderRadius: 100,
                     marginRight: 5,
                  }}
               >
                  <Typography
                     style={{
                        margin: 'auto',
                        textAlign: 'center',
                        fontSize: size === 'small' ? '1.1rem' : '1.5rem',
                        color: 'white',
                        paddingTop: size === 'small' ? 8 : 15,
                        flexWrap: 'nowrap',
                     }}
                  >
                     {name || surname ? (
                        <Fragment>{(name ? name[0].toUpperCase() : '') + (surname ? surname[1] : '')}</Fragment>
                     ) : null}
                     {!name && !surname ? (
                        <Fragment>{(title ? title[0].toUpperCase() : '') + (title ? title[1] : '')}</Fragment>
                     ) : null}
                  </Typography>
               </div>
            )}
            {socialMedia ? (
               <div style={{ position: 'relative', display: 'inline' }}>
                  <div className={spacerClass}>
                     <SocialMediaIcon
                        socialMediaCode={socialMedia}
                        selected
                        width={socialMediaAvatarSize}
                        height={socialMediaAvatarSize}
                     />
                  </div>
               </div>
            ) : null}
            {onlineStatus > -1 && !hideCircle ? (
               <div
                  style={{
                     position: 'relative',
                     display: 'inline',
                  }}
               >
                  <div className={onlineStatusClass}>
                     <UserOnlineStatus size={size} status={onlineStatus} />
                  </div>
               </div>
            ) : null}
         </Grid>
         {!reverse ? TitleAndUsername : null}
         {caret ? (
            <Grid
               style={{
                  paddingTop: 15,
                  marginLeft: 10,
               }}
            >
               <ExpandMoreOutlinedIcon />
            </Grid>
         ) : null}
      </Grid>
   );
};

MediumAvatar.defaultProps = {
   avatarSize: 64,
   size: 'small',
};

export default MediumAvatar;

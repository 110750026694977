import React, { Fragment, useState } from 'react';
import numeral from 'numeral';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Button, MenuItem, Select, Typography } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ButtonOK from '@components/ButtonOK/buttonOk';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import { useContext } from 'react';
import { LanguageContext } from '@helper/locale/langContext';
import { UPDATE_CUSTOMER_WORKING_HOUR_MUTATION } from '../../../queries/Customer/Service/GraphQL/Customer/mutation';
import { useSnackbar } from 'notistack';
import { useMutation } from '@apollo/client';
import { UPDATE_ME } from '@store/actions/person';
import { useDispatch } from 'react-redux';

const HOURS = [
   { value: '00', key: 0 },
   { value: '01', key: 1 },
   { value: '02', key: 2 },
   { value: '03', key: 3 },
   { value: '04', key: 4 },
   { value: '05', key: 5 },
   { value: '06', key: 6 },
   { value: '07', key: 7 },
   { value: '08', key: 8 },
   { value: '09', key: 9 },
   { value: '10', key: 10 },
   { value: '11', key: 11 },
   { value: '12', key: 12 },
   { value: '13', key: 13 },
   { value: '14', key: 14 },
   { value: '15', key: 15 },
   { value: '16', key: 16 },
   { value: '17', key: 17 },
   { value: '18', key: 18 },
   { value: '19', key: 19 },
   { value: '20', key: 20 },
   { value: '21', key: 21 },
   { value: '22', key: 22 },
   { value: '23', key: 23 },
];
const MINUTES = [
   { value: '00', key: 0 },
   { value: '15', key: 15 },
   { value: '30', key: 30 },
   { value: '45', key: 45 },
];

// const LEFT_PANEL_WIDTH = 320;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
   anchorOrigin: {
      vertical: 'bottom',
      horizontal: -15,
   },
   getContentAnchorEl: null,
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 80,
      },
   },
};

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
      // tableLayout: 'fixed'
   },
   selector: {
      border: 'solid 1px ' + theme.chatApp.general.pallet.passiveStructureBlue,
      borderColor: '#bdbdbd',
      borderRadius: 7,
      paddingLeft: 10,
      paddingRight: 10,
      height: 32,
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 20,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   daysTitle: {
      fontSize: '1.1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   editButton: {
      width: 130,
      height: 40,
      color: 'white',
      borderRadius: 6,
      backgroundColor: '#157CFC',
      textTransform: 'none',
      transition: 'all .7s',
      '&:hover': {
         backgroundColor: 'rgba(21, 124, 252, 0.8)',
      },
   },
   openSelectBox: {
      '& .MuiSelect-select:focus': {
         backgroundColor: 'transparent',
      },
   },
   openedTitle: {
      color: '#24E19F',
      fontSize: '1.1rem',
   },
   closedTitle: {
      color: '#F4425B',
      fontSize: '1.1rem',
   },
   statusMenuItemContainer: {
      width: 110,
      height: 35,
   },
   Select: {
      '& .MuiSelect-select:focus': {
         backgroundColor: 'transparent',
      },
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   hoursTitle: {
      fontSize: '1.1rem',
      color: '#157CFC',
   },
   hoursMenuItemContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
}));

const StyledTableCell = withStyles((theme) => ({
   head: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      //   fontWeight: '700',
   },
   body: {
      fontSize: '1.1rem',
   },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
   root: {
      backgroundColor: 'transparent',
   },
}))(TableRow);

const MessageHours = ({ person }) => {
   const classes = useStyles();
   const [editModeRow, setEditModeRow] = useState({});
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const dispatch = useDispatch();
   // const [status, setStatus] = useState();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   // update mutation function
   const [updateWorkingHourMutation, { loading: updateWorkingHourLoading }] = useMutation(
      UPDATE_CUSTOMER_WORKING_HOUR_MUTATION,
      {
         async update(
            cache,
            {
               data: {
                  updateCustomerWorkingHour: { data: updateCustomerWorkingHour },
               },
            },
         ) {
            //  updating cache after added
            // redux güncellenecek
            dispatch({
               type: UPDATE_ME,
               payload: {
                  person: {
                     ...person,
                     customer: {
                        ...person.customer,
                        ...updateCustomerWorkingHour,
                     },
                  },
               },
            });
         },
      },
   );

   // update data on submit
   const editWorkingHourSubmit = async (formData) => {
      // run update mutation
      updateWorkingHourMutation({
         variables: {
            name: formData.name,
            workingHourId: formData._id,
            status: formData.status.toString() === 'true' ? true : false,
            openingHour: parseFloat(formData.openingHour),
            openingMinute: parseFloat(formData.openingMinute),
            closingHour: parseFloat(formData.closingHour),
            closingMinute: parseFloat(formData.closingMinute),
         },
      })
         .then(() => {
            // on success event
            // close modal
            setEditModeRow({});

            enqueueSnackbar(t?.general_information_updated_successfully, {
               // send success message
               variant: 'success',
               autoHideDuration: 2000,
            });
         })
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   return (
      <Fragment>
         {updateWorkingHourLoading && <div className='loading' />}
         <Grid container justifyContent='flex-start' alignItems='center' className={classes.titleContainer}>
            <Typography style={{ marginLeft: 10, fontSize: '1.1rem', fontWeight: 'bold' }}>
               {t?.page_settings_typo_messaging_hours}
            </Typography>
         </Grid>
         <Grid>
            <TableContainer style={{ padding: 10 }}>
               <Table className={classes.table} aria-label='customized table'>
                  <TableHead style={{ tableLayout: 'fixed' }}>
                     <TableRow>
                        <StyledTableCell style={{ width: 300 }}> {t?.page_settings_typo_days} </StyledTableCell>
                        <StyledTableCell align='left'> {t?.page_settings_typo_open_close} </StyledTableCell>
                        <StyledTableCell style={{ width: 250 }}>
                           {t?.page_settings_typo_messaging_hours_opened}
                        </StyledTableCell>
                        <StyledTableCell align='left' style={{ width: 250 }}>
                           {t?.page_settings_typo_messaging_hours_closed}
                        </StyledTableCell>

                        <StyledTableCell align='right'>{t?.page_settings_button_operation}</StyledTableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody style={{ tableLayout: 'fixed' }}>
                     {person?.customer?.workingHours.map((row) => (
                        <StyledTableRow key={row._id} style={{ height: 80 }}>
                           {/* Days */}
                           <StyledTableCell component='th' scope='row' className={classes.coloredText}>
                              <Typography className={classes.daysTitle}>{t && t[row.name]}</Typography>
                           </StyledTableCell>
                           {/* Opening Hour  */}
                           <StyledTableCell align='left' className={classes.coloredText}>
                              {row._id === editModeRow._id ? (
                                 <Select
                                    value={editModeRow?.status}
                                    renderValue={() =>
                                       editModeRow.status ? (
                                          <Typography className={classes.openedTitle}>
                                             {t?.page_settings_button_open}
                                          </Typography>
                                       ) : (
                                          <Typography className={classes.closedTitle}>
                                             {t?.page_settings_button_close}
                                          </Typography>
                                       )
                                    }
                                    className={classes.openSelectBox}
                                    disableUnderline
                                    MenuProps={MenuProps}
                                    onChange={(_, value) =>
                                       setEditModeRow({ ...editModeRow, status: value.props.value })
                                    }
                                 >
                                    <MenuItem value={true} className={classes.statusMenuItemContainer}>
                                       <Typography className={classes.openedTitle}>
                                          {t?.page_settings_button_open}
                                       </Typography>
                                    </MenuItem>
                                    <MenuItem value={false} className={classes.statusMenuItemContainer}>
                                       <Typography className={classes.closedTitle}>
                                          {t?.page_settings_button_close}
                                       </Typography>
                                    </MenuItem>
                                 </Select>
                              ) : row.status ? (
                                 <Typography className={classes.openedTitle}>{t?.page_settings_button_open}</Typography>
                              ) : (
                                 <Typography className={classes.closedTitle}>
                                    {t?.page_settings_button_close}
                                 </Typography>
                              )}
                           </StyledTableCell>

                           {/* Closing Hour */}
                           <StyledTableCell style={{ minWidth: 185 }}>
                              {row._id === editModeRow._id ? (
                                 <Grid container justifyContent='flex-start' alignItems='center' position='relative'>
                                    <Grid className={classes.selector}>
                                       <Select
                                          style={{ marginTop: 0 }}
                                          disableUnderline
                                          value={editModeRow?.openingHour}
                                          onChange={(e) =>
                                             setEditModeRow({
                                                ...editModeRow,
                                                openingHour: e.target.value,
                                             })
                                          }
                                          MenuProps={MenuProps}
                                          className={classes.Select}
                                       >
                                          {HOURS.map((hour) => (
                                             <MenuItem
                                                key={hour.key}
                                                value={hour.key}
                                                className={classes.hoursMenuItemContainer}
                                             >
                                                {hour.value}
                                             </MenuItem>
                                          ))}
                                       </Select>
                                    </Grid>
                                    <Typography style={{ marginLeft: 5, marginRight: 5 }}> : </Typography>
                                    <Grid className={classes.selector}>
                                       <Select
                                          style={{ marginTop: 0 }}
                                          disableUnderline
                                          value={editModeRow?.openingMinute}
                                          onChange={(e) =>
                                             setEditModeRow({
                                                ...editModeRow,
                                                openingMinute: e.target.value,
                                             })
                                          }
                                          MenuProps={MenuProps}
                                          className={classes.Select}
                                       >
                                          {MINUTES.map((minute) => (
                                             <MenuItem
                                                key={minute.key}
                                                value={minute.key}
                                                className={classes.hoursMenuItemContainer}
                                             >
                                                {minute.value}
                                             </MenuItem>
                                          ))}
                                       </Select>
                                    </Grid>
                                 </Grid>
                              ) : (
                                 <Typography className={classes.hoursTitle}>
                                    {numeral(row.openingHour).format('00.') +
                                       ':' +
                                       numeral(row.openingMinute).format('00.')}
                                 </Typography>
                              )}
                           </StyledTableCell>
                           {/* Status Buttons (Open or Close) */}
                           <StyledTableCell align='left' style={{ minWidth: 185 }}>
                              {row._id === editModeRow._id ? (
                                 <Grid container justifyContent='flex-start' alignItems='center'>
                                    {/* Closing Hour */}
                                    <Grid className={classes.selector}>
                                       <Select
                                          style={{ marginTop: 0 }}
                                          disableUnderline
                                          value={editModeRow?.closingHour}
                                          onChange={(e) =>
                                             setEditModeRow({
                                                ...editModeRow,
                                                closingHour: e.target.value,
                                             })
                                          }
                                          MenuProps={MenuProps}
                                          className={classes.Select}
                                       >
                                          {HOURS.map((hour) => (
                                             <MenuItem
                                                key={hour.key}
                                                value={hour.key}
                                                className={classes.hoursMenuItemContainer}
                                             >
                                                {hour.value}
                                             </MenuItem>
                                          ))}
                                       </Select>
                                    </Grid>
                                    {/* Closing Minute */}
                                    <Grid style={{ margin: 10 }}>
                                       <Typography style={{ fontSize: '1.1rem' }}>:</Typography>
                                    </Grid>
                                    <Grid className={classes.selector}>
                                       <Select
                                          style={{ marginTop: 0 }}
                                          disableUnderline
                                          value={editModeRow?.closingMinute}
                                          onChange={(e) =>
                                             setEditModeRow({
                                                ...editModeRow,
                                                closingMinute: e.target.value,
                                             })
                                          }
                                          MenuProps={MenuProps}
                                          className={classes.Select}
                                       >
                                          {MINUTES.map((minute) => (
                                             <MenuItem
                                                key={minute.key}
                                                value={minute.key}
                                                className={classes.hoursMenuItemContainer}
                                             >
                                                {minute.value}
                                             </MenuItem>
                                          ))}
                                       </Select>
                                    </Grid>
                                 </Grid>
                              ) : (
                                 <Typography className={classes.hoursTitle}>
                                    {numeral(row.closingHour).format('00.') +
                                       ':' +
                                       numeral(row.closingMinute).format('00.')}
                                 </Typography>
                              )}
                           </StyledTableCell>
                           <StyledTableCell align='right' style={{ minWidth: 280 }}>
                              {row._id === editModeRow._id ? (
                                 <Fragment>
                                    <Grid>
                                       <span style={{ marginRight: 0 }}>
                                          <ButtonCancel
                                             onClick={() => setEditModeRow({})}
                                             width={120}
                                             fontSize='1.1rem'
                                             label={t?.comp_button_cancel}
                                             height={40}
                                          />
                                       </span>
                                       <span style={{ marginLeft: 5 }}>
                                          <ButtonOK
                                             onClick={() => editWorkingHourSubmit(editModeRow)}
                                             width={120}
                                             fontSize='1.1rem'
                                             label={t?.comp_button_save}
                                             height={40}
                                          />
                                       </span>
                                    </Grid>
                                 </Fragment>
                              ) : (
                                 <Button onClick={() => setEditModeRow(row)} className={classes.editButton}>
                                    <Typography noWrap style={{ fontSize: '1.1rem' }}>
                                       {t?.page_settings_button_edit}
                                    </Typography>
                                 </Button>
                              )}
                           </StyledTableCell>
                        </StyledTableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </Grid>
      </Fragment>
   );
};

export default MessageHours;

import React, { FC, useContext, useState, useMemo, Dispatch, SetStateAction, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IProductProps, IProductVariant } from '../../../types';
import { LanguageContext } from '@helper/locale/langContext';
import { ButtonBase, Typography, Checkbox, CircularProgress } from '@material-ui/core';
import classNames from 'classnames';
import { currencyHandler } from '@helper/textUtils';
import { BrokenImage as BrokenImageIcon } from '@material-ui/icons';
import { priceFormatWithoutCurrency } from '@helper/functions';
import { E_COMMERCE_PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/ECommercePlatform/types';

const useStyles = makeStyles(() => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #D9E0E6',
      borderRadius: 4,
      marginBottom: 8,
      width: '100%',
      alignItems: 'stretch',
   },
   selected: {
      border: '1px solid #2175F8',
   },
   defaultMediaIcon: {
      color: '#999999',
      fontSize: 42,
   },
   imageAndInfoContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: 8,
      flexGrow: 1,
   },
   image: {
      width: 42,
      height: 42,
      borderRadius: 4,
      overflow: 'hidden',
      flexShrink: 0,
   },
   infoContainer: {
      flexGrow: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: 5,
   },
   nameContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
   },
   name: {
      color: '#385273',
      fontSize: '1em',
      lineHeight: 1.4,
   },
   availableAndPrice: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   available: {
      color: '#385273',
      opacity: '0.6',
      fontSize: '1em',
      lineHeight: 1.3,
   },
   price: {
      color: '#157CFC',
      fontSize: '1em',
      lineHeight: 1.3,
   },
   variantsContainer: {
      display: 'flex',
      alignItems: 'stretch',
      flexDirection: 'column',
      flexGrow: 1,
   },
   variantContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexGrow: 1,
      padding: '4px 8px',
      borderTop: '1px solid #D9E0E6',
   },
   variantLeft: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flex: 3,
   },
   variantRight: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
   checkBox: {
      padding: '4px !important',
      '&:hover': {
         backgroundColor: 'transparent',
      },
   },
}));

interface AddProductListItemProps {
   product: any;
   isSelected: boolean;
   selectProduct: (product: any) => void;
   deselectProduct: (product: any) => void;
   selectedVariants: Array<string | null>;
   getVariant: (id: string) => Promise<any>;
   eCommerceType: string;
   eCommerceVariantLoading: boolean;
}

const AddProductListItem: FC<AddProductListItemProps> = ({
   product,
   selectedVariants,
   isSelected,
   selectProduct,
   deselectProduct,
   getVariant,
   eCommerceType,
   eCommerceVariantLoading,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const { _id, image, title, available, price, variants } = product;
   const [isVisibleVariants, setVisibleVariants] = useState<boolean>(false);
   const [isECommerceVariantLoading, setIsECommerceVariantLoading] = useState<boolean>(false);
   const [isProductUpdatedRedux, setIsProductUpdatedRedux] = useState<number>(0);

   useEffect(() => {
      const obj = {
         _id: product._id,
         productIdAndVariantId: product.productIdAndVariantId,
         variantId: product.variantId,
         isSelected: isSelected,
         selectedVariants: selectedVariants,
      };
   }, [product, isSelected, selectedVariants]);

   const onClick = async () => {
      const hasVariants = variants?.length === 1;

      if (eCommerceType === E_COMMERCE_PLATFORM_TYPE.IDEASOFT) {
         if (hasVariants) {
            if (eCommerceVariantLoading) return;

            setIsECommerceVariantLoading(true);
            const variantLength = await getVariant(_id.toString());
            setIsECommerceVariantLoading(false);

            if (variantLength > 0) {
               setIsProductUpdatedRedux(variantLength);
            } else {
               const v = variants[0];
               const productIdAndVariantId = product._id + v.variantId;

               if (isSelected) {
                  deselectProduct({ _id: product._id, productIdAndVariantId, variantId: v.variantId });
                  setIsProductUpdatedRedux(0);
               } else {
                  if (variants[0].stock === 0) return;
                  selectProduct({
                     productIdAndVariantId,
                     _id: product._id,
                     ...v,
                     quantity: 1,
                     image: v.image || image,
                     title: title,
                     price: v.price || v.salesPrice || price,
                  });
               }
            }
         } else {
            setVisibleVariants((prevState) => !prevState);
         }
      } else {
         if (!hasVariants) {
            setVisibleVariants((prevState) => !prevState);
         } else {
            const v = variants[0];
            const productIdAndVariantId = product._id + v.variantId;

            if (isSelected) {
               deselectProduct({ _id: product._id, productIdAndVariantId, variantId: v.variantId });
               setIsProductUpdatedRedux(0);
            } else {
               selectProduct({
                  productIdAndVariantId,
                  _id: product._id,
                  ...v,
                  quantity: 1,
                  image: v.image || image,
                  title: title,
                  price: v.price || v.salesPrice || price,
               });
            }
         }
      }
   };

   useEffect(() => {
      if (isProductUpdatedRedux > 0) {
         setVisibleVariants(true);
      }
   }, [isProductUpdatedRedux]);

   const handleChange = (variant: IProductVariant, event: React.ChangeEvent<HTMLInputElement>) => {
      if (variants) {
         if (variant.stock === 0 && eCommerceType === E_COMMERCE_PLATFORM_TYPE.IDEASOFT) return;
         if (event.target.checked) {
            selectProduct({
               productIdAndVariantId: product._id + variant.variantId,
               _id: product._id,
               ...variant,
               quantity: 1,
               image: variant.image || image,
               title: title,
               price: variant.price || variant.salesPrice || price,
            });
         } else {
            deselectProduct({
               _id: product._id,
               productIdAndVariantId: product._id + variant.variantId,
               variantId: variant.variantId,
            });
         }
      }
   };

   const totalStock = useMemo(
      () =>
         variants.reduce((previousValue: number, currentValue: any) => {
            return previousValue + currentValue.stock;
         }, 0),
      [variants],
   );

   return (
      <div className={classNames(classes.container, (isSelected || selectedVariants.length > 0) && classes.selected)}>
         <ButtonBase onClick={onClick} disableRipple className={classes.imageAndInfoContainer}>
            {image ? (
               <div style={{ position: 'relative' }}>
                  <img src={image} alt='product image' className={classes.image} />
                  {isECommerceVariantLoading && (
                     <CircularProgress
                        style={{
                           position: 'absolute',
                           color: '#2175F8',
                           top: 10,
                           left: 10,
                        }}
                        size={20}
                     />
                  )}
               </div>
            ) : (
               <div
                  className={classes.image}
                  style={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     position: 'relative',
                  }}
               >
                  <BrokenImageIcon className={classes.defaultMediaIcon} />
                  {isECommerceVariantLoading && (
                     <CircularProgress
                        style={{
                           position: 'absolute',
                           color: '#2175F8',
                           top: 10,
                           left: 10,
                        }}
                        size={20}
                     />
                  )}
               </div>
            )}
            <div className={classes.infoContainer}>
               <div className={classes.nameContainer}>
                  <Typography variant='body1' noWrap className={classes.name}>
                     {title}
                  </Typography>
               </div>
               <div className={classes.availableAndPrice}>
                  {variants?.length !== 1 && (
                     <Typography variant='body2' className={classes.available}>
                        {`${t['page_e_commerce_products_typo_variants']}: ${variants?.length}`}
                     </Typography>
                  )}
                  {Number.isInteger(totalStock) && (
                     <Typography variant='body2' className={classes.available}>
                        {`${t['page_e_commerce_products_typo_available']}: ${totalStock}`}
                     </Typography>
                  )}
                  {variants?.length === 1 && (
                     <Typography variant='body2' className={classes.price}>
                        {`${currencyHandler(variants[0]?.currency)}${priceFormatWithoutCurrency(
                           variants[0]?.discountedPrice == '0.00' || !variants[0]?.discountedPrice
                              ? variants[0]?.salesPrice
                              : variants[0]?.discountedPrice,
                        )}`}
                     </Typography>
                  )}
               </div>
            </div>
         </ButtonBase>
         {isVisibleVariants && (
            <div className={classes.variantsContainer}>
               {variants?.map((variant: any) => {
                  let variantName = '';
                  if (variant.variantsType1 && variant.variantsType1 !== 'Default Title') {
                     variantName = variant.variantsType1;
                  }
                  if (variant.variantsType2 && variant.variantsType2 !== 'Default Title') {
                     variantName = variantName + ' / ' + variant.variantsType2;
                  }
                  if (variant.variantsType3 && variant.variantsType3 !== 'Default Title') {
                     variantName = variantName + ' / ' + variant.variantsType3;
                  }
                  variantName = variantName.trim() === '' ? variant.name : variantName;
                  return (
                     <div key={variant.variantId} className={classes.variantContainer}>
                        <div className={classes.variantLeft}>
                           <Checkbox
                              disableRipple
                              color='primary'
                              checked={selectedVariants.includes(variant.variantId)}
                              onChange={(e) => handleChange(variant, e)}
                              className={classes.checkBox}
                           />
                           <Typography variant='body2' noWrap className={classes.name}>
                              {variantName}
                           </Typography>
                           <Typography style={{ opacity: 0.5, color: 'black' }}>&nbsp;({variant.stock})</Typography>
                        </div>
                        <div className={classes.variantRight}>
                           <Typography variant='body2' className={classes.price}>
                              {`${currencyHandler(variant?.currency)}${priceFormatWithoutCurrency(
                                 variant?.discountedPrice == '0.00' || !variant?.discountedPrice
                                    ? variant?.salesPrice
                                    : variant?.discountedPrice,
                              )}`}
                           </Typography>
                        </div>
                     </div>
                  );
               })}
            </div>
         )}
      </div>
   );
};

export default AddProductListItem;

import { LanguageContext } from '@helper/locale/langContext';
import { CircularProgress, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Cancel, CheckCircle, Folder as FolderIcon } from '@material-ui/icons';
import React, { useContext } from 'react';
import { Folder } from '../types';

export interface FolderTabsProps {
   folders: Folder[];
   folderRemoveable: boolean;
   onFolderRemove: (folder: Folder) => void;
}

const useStyles = makeStyles((theme: any) => ({
   container: {
      width: '100%',
      height: '100%',
      backgroundColor: '#ffffff',
   },
   tab: {
      width: '88%',
      textAlign: 'center',
      backgroundColor: '#efefef',
      color: '#ababab',
      borderRadius: '7px',
      userSelect: 'none',
   },
   folderLine: {
      display: 'flex',
      width: '100%',
      height: 35,
      border: '1px solid #efefef',
      borderRadius: '7px',
      marginBottom: '7px',
   },
   iconHolder: {
      width: '35px',
      height: '35px',
      borderRadius: '7px',
      backgroundColor: '#efefef',
      padding: '5px',
      color: '#989898',
      fontSize: '20px',
   },
   titleHolder: {
      textAlign: 'start',
      padding: '3px',
      marginLeft: '10px',
   },
   removeHolder: {
      marginLeft: 'auto',
   },
}));

interface FolderLineProps {
   title: string;
   onRemove?: (() => void) | undefined;
}

const FolderLine = ({ title, onRemove }: FolderLineProps) => {
   const classes = useStyles();
   return (
      <div className={classes.folderLine}>
         <div className={classes.iconHolder}>
            <FolderIcon style={{ width: 25, height: 25 }} />
         </div>
         <div className={classes.titleHolder}>
            <Typography variant='subtitle1' color='textSecondary'>
               {title}
            </Typography>
         </div>
         {onRemove && (
            <div className={classes.removeHolder}>
               <IconButton onClick={onRemove} style={{ padding: 5 }}>
                  <Cancel style={{ width: 25, height: 25, color: 'red' }} />
               </IconButton>
            </div>
         )}
      </div>
   );
};

const FolderLister = ({ folders, folderRemoveable, onFolderRemove }: FolderTabsProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   return (
      <div style={{ marginTop: 10, padding: '3px', marginRight: 1, height: '91%', overflow: 'hidden auto' }}>
         {folders.map((folder, index) => {
            return (
               <FolderLine
                  key={index}
                  title={folder.name}
                  onRemove={folderRemoveable ? () => onFolderRemove(folder) : undefined}
               />
            );
         })}
      </div>
   );
};

export default FolderLister;

import React, { useContext } from 'react';
import { Grid, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReactSelect from 'react-select';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import { priceFormat } from '@helper/functions';
import { DAYS } from '@lib/constants';
import moment from 'moment';
import { AddOnName } from '@types/enums';
const useStyles = makeStyles((theme) => ({
   root: {
      padding: 15,
      height: 300,
      border: '1px solid #eee',
      borderRadius: 10,
   },
   userTitle: {
      '@media (min-width:1280px) and (max-width:1600px)': {
         fontSize: '1rem',
      },
   },
   description: {
      color: 'grey',
      '@media (min-width:1280px) and (max-width:1600px)': {
         fontSize: '.85rem',
         color: 'grey',
      },
   },
   bottomSection: {
      [theme.breakpoints.down('sm')]: {
         marginTop: theme.spacing(2),
      },
   },
}));

// React Select Options
const OPTIONS = [
   { label: 1, value: 1 },
   { label: 2, value: 2 },
   { label: 3, value: 3 },
   { label: 4, value: 4 },
   { label: 5, value: 5 },
   { label: 6, value: 6 },
   { label: 7, value: 7 },
   { label: 8, value: 8 },
   { label: 9, value: 9 },
   { label: 10, value: 10 },
];

const activeCustomerOptions = [
   {
      label: 100,
      value: 100,
   },
   {
      label: 250,
      value: 250,
   },
   {
      label: 500,
      value: 500,
   },
   {
      label: 1000,
      value: 1000,
   },
   {
      label: 2500,
      value: 2500,
   },
   {
      label: 5000,
      value: 5000,
   },
];

const balanceOptions = [
   {
      label: 10,
      value: 10,
   },
   {
      label: 50,
      value: 50,
   },
   {
      label: 100,
      value: 100,
   },
   {
      label: 250,
      value: 250,
   },
   {
      label: 500,
      value: 500,
   },
   {
      label: 1000,
      value: 1000,
   },
];

// User -> User
// Customer -> Active Customer
// Number -> Whatsapp Business API
// Balance -> General Account Balance

const AddOnPackageItem = ({ item, customer, onClickBuy, onChangeCount, defaultValue, options }) => {
   const classes = useStyles();
   const {
      lang: { translation: t },
   } = useContext(LanguageContext);

   const diffDay = moment(customer?.package?.endDate).diff(new Date(), DAYS, true);
   const customerProductIsMonthly = customer.package.periodMode === 0;

   const getPriceSubstitle = (name, isDesc) => {
      if (name.includes('User')) {
         if (isDesc) {
            return (
               t?.add_on_description_user_whatsapp_1 +
               t?.add_on_description_user_whatsapp_2 +
               t?.add_on_description_user_whatsapp_3
            );
         } else {
            return 'User';
         }
      } else if (name.includes('Customer')) {
         if (isDesc) {
            return (
               t?.add_on_description_active_customer_1 +
               t?.add_on_description_active_customer_2 +
               t?.add_on_description_active_customer_3
            );
         } else {
            return 'Customer';
         }
      } else if (name.includes('Whatsapp')) {
         if (isDesc) {
            return (
               t?.add_on_description_user_whatsapp_1 +
               t?.add_on_description_user_whatsapp_2 +
               t?.add_on_description_user_whatsapp_3
            );
         } else {
            return 'Number';
         }
      } else if (name.includes('Balance')) {
         if (isDesc) {
            return t?.add_on_description_balance_1 + t?.add_on_description_balance_2 + t?.add_on_description_balance_3;
         } else {
            return 'Balance';
         }
      } else return 'Unknown';
   };

   const getTitleFromName = (name) => {
      if (name.includes('User')) return t?.user;
      else if (name.includes('customer')) return t?.active_customer;
      else if (name.includes('Balance')) return t?.add_on_balance;
      else return name;
   };

   return (
      <Box className={classes.root}>
         <Grid
            container
            justifyContent='flex-end'
            alignContent='space-between'
            alignItems='center'
            direction='column'
            wrap='nowrap'
            style={{ height: '100%' }}
         >
            {/* title */}
            <Grid
               item
               className={classes.titleContainer}
               container
               justifyContent='space-between'
               alignItems='flex-start'
               xs='12'
            >
               <Grid item xs={6}>
                  <Typography color='primary' variant='h6' noWrap className={classes.userTitle}>
                     {getTitleFromName(item.name)}
                  </Typography>
               </Grid>
               <Grid item xs={6} container justifyContent='flex-end' alignItems='center'>
                  <Typography variant='h5' color='primary' className={classes.userTitle}>
                     {priceFormat(
                        item.price
                           ? item.price
                           : customerProductIsMonthly
                           ? customer.package.price
                           : customer?.package?.price / 12,
                     )}{' '}
                     /{' '}
                  </Typography>

                  <Typography color='primary' variant='caption' className={classes.userTitle}>
                     {getTitleFromName(item.name)}
                  </Typography>
               </Grid>
            </Grid>
            {/* Description */}
            <Grid container item justifyContent='flex-start' alignItems='center' xs='12'>
               <Typography style={{ whiteSpace: 'pre-wrap' }} className={classes.description} color='textPrimary'>
                  {getPriceSubstitle(item.name, true)}
               </Typography>
            </Grid>
            {/* Select  */}
            <Grid
               item
               container
               xs='12'
               justifyContent='space-between'
               className={classes.bottomSection}
               alignItems='center'
            >
               <Grid item xs='3'>
                  <ReactSelect
                     options={
                        item.name === AddOnName.ACCOUNT_BALANCE
                           ? balanceOptions
                           : item.name === AddOnName.ACTIVE_CUSTOMER
                           ? activeCustomerOptions
                           : OPTIONS
                     }
                     getOptionValue={(option) => option.value}
                     onChange={(option) => onChangeCount(option, getPriceSubstitle(item.name))}
                     defaultValue={
                        item.name === AddOnName.ACCOUNT_BALANCE || item.name === AddOnName.ACTIVE_CUSTOMER
                           ? { label: 100, value: 100 }
                           : { label: 1, value: 1 }
                     }
                     controlShouldRenderValue
                     menuPlacement='top'
                     isSearchable
                  />
               </Grid>
               <Grid item xs='4'>
                  <ButtonOK
                     label={t?.add_on_package}
                     style={{ width: '100%' }}
                     fontWeight='bold'
                     fontSize='1.1rem'
                     onClick={() => onClickBuy(getPriceSubstitle(item.name), item)}
                  />
               </Grid>
            </Grid>
         </Grid>
      </Box>
   );
};

export default AddOnPackageItem;

export enum DATE_RANGES {
   TODAY = 'TODAY',
   YESTERDAY = 'YESTERDAY',
   THIS_WEEK = 'THIS_WEEK',
   THIS_MONTH = 'THIS_MONTH',
}

export enum PACKAGE_RENEWAL_TYPE {
   MONTHLY = 0,
   YEARLY = 1,
}

export enum PERSON_TYPE {
   EMPLOYEE = 0,
   MANAGER = 1,
   OWNER = 2,
   ROBOT = 3,
}

export enum ChatType {
   DM = 'DM',
   QNA = 'QNA',
}

export enum DmChatStatus {
   ALL = 'ALL',
   ACTIVE = 0,
   TIMED_OUT = 1,
   SOLVED = 2,
   SPAM = 3,
   DELETED = 5,
   BotIsJoined = 6,
}

export enum QnaChatStatus {
   ALL = 'ALL',
   WAITING = 0,
   SOLVED = 1,
   SPAM = 2,
   TIMED_OUT = 3,
   DELETED = 5,
}

export enum RenewalType {
   MONTHLY = 'Monthly',
   YEARLY = 'Yearly',
   ONEOFF = 'OneOff',
}

export enum AddOnName {
   ACCOUNT_BALANCE = 'Account Balance',
   USER = 'User',
   ACTIVE_CUSTOMER = 'Active Customer',
   WHATSAPP_BUSINESS = 'Whatsapp Business API',
}

import { gql } from '@apollo/client/core';

// mutations for quick answers
export const CREATE_QUICK_ANSWER_MUTATION = gql`
   mutation createQuickAnswersMutation($name: String!, $answer: String!, $customer: ID!, $payload: [QuickAnswerPayload]!) {
      createQuickAnswer(data: { name: $name, answer: $answer, customer: $customer, payload: $payload }) {
         data {
            _id
            name
            answer
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const UPDATE_QUICK_ANSWER_MUTATION = gql`
   mutation updateQuickAnswersMutation($_id: ID!, $name: String!, $answer: String!, $payload: [QuickAnswerPayload]!) {
      updateQuickAnswer(data: { _id: $_id, name: $name, answer: $answer, payload: $payload }) {
         data {
            _id
            name
            answer
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            createdAt
         }
         success
         code
         message
      }
   }
`;
export const DELETE_QUICK_ANSWER_MUTATION = gql`
   mutation deleteQuickAnswersMutation($_id: ID!) {
      deleteQuickAnswer(data: { _id: $_id }) {
         data {
            _id
            name
            answer
            customer {
               accountStatus
               accountConfirmation
               appStatus
               hasFreeTrial
               freeTrialExpiringDate
               isANewCustomer
               _id
               companyName
            }
            createdAt
         }
         success
         code
         message
      }
   }
`;

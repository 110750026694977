import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
   FileBlockProps,
   FlowBlock,
   FlowBotDataItem,
   IFlowBlockTemplate,
   TempNode,
} from '@modules/Automation/FlowBots/types';
import Routes from './routes';
import { Node } from 'reactflow';
const useStyles = makeStyles(() => ({
   content: {
      display: 'flex',
      height: '100vh',
      width: '100%',
   },
   mainSpace: {
      width: '100%',
   },
}));

const Settings = () => {
   const classes = useStyles();

   const [leftNodes, setLeftNodes] = useState<Node[]>([]);
   const [contentNodes, setContentNodes] = useState<Node[]>([]);
   const [flowBotData, setFlowBotData] = useState<FlowBotDataItem[]>([]);
   const [selectedItem, setSelectedItem] = useState<FlowBotDataItem | undefined>();
   const [nodes, setNodes] = useState<Node[]>([]);
   const [tempNodes, setTempNodes] = useState<TempNode[]>([]);
   const [clickedNodeId, setClickedNodeId] = useState<string>('');
   const [clickedHandleId, setClickedHandleId] = useState<string>('');
   const [flowBlock, setFlowBlock] = useState<FlowBlock[]>([]);
   const [blockState, setBlockState] = useState<IFlowBlockTemplate[]>([]);
   const [fileBlock, setFileBlock] = useState<FileBlockProps[]>([]);
   const [isNewNode, setIsNewNode] = useState('');

   return (
      <main className={classes.content}>
         <Routes
            leftNodes={leftNodes}
            setLeftNodes={setLeftNodes}
            contentNodes={contentNodes}
            setContentNodes={setContentNodes}
            clickedNodeId={clickedNodeId}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            flowBotData={flowBotData}
            setFlowBotData={setFlowBotData}
            nodes={nodes}
            setNodes={setNodes}
            tempNodes={tempNodes}
            setTempNodes={setTempNodes}
            clickedHandleId={clickedHandleId}
            setClickedNodeId={setClickedNodeId}
            setClickedHandleId={setClickedHandleId}
            flowBlock={flowBlock}
            setFlowBlock={setFlowBlock}
            blockState={blockState}
            setBlockState={setBlockState}
            setIsNewNode={setIsNewNode}
            isNewNode={isNewNode}
            fileBlock={fileBlock}
            setFileBlock={setFileBlock}
         />
      </main>
   );
};

export default Settings;

import { LanguageContext } from '@helper/locale/langContext';
import {
   FormControlLabel,
   Grid,
   Table,
   TableBody,
   TableContainer,
   TableHead,
   TableRow,
   Typography,
   Menu,
   MenuItem,
   CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { Fragment, useContext, useState, useCallback, useEffect } from 'react';
import { StyledTableCell, StyledTableRow } from '../../components/tableItems';
import CustomSwitch from '@components/Switch';
import CreateModal from '@modules/Automation/FlowBots/components/createModal';
import Modal from '@components/Modal/modal';
import DeleteFow from '@modules/Automation/FlowBots/components/DeleteFlow';
import InfiniteScroll from 'react-infinite-scroll-component';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { RootStateOrAny, useSelector } from 'react-redux';
import { getToken } from '@store/reducers/person';
import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { GET_FLOW_BOT_PAGINATED_QUERY, GET_LIST_NODE_TEMPLATES_QUERY } from '@queries/Automation/query';
import {
   CREATE_FLOW_BOT_MUTATION,
   CREATE_FLOW_NODE_MUTATION,
   DELETE_FLOW_BOT_MUTATION,
   DUPLICATE_FLOW_BOT,
   UPDATE_FLOW_BOT_MUTATION,
} from '@queries/Automation/mutation';
import { UPDATE_FLOW_BOT_SUBSCRIPTION } from '@queries/Automation/subscription';
import { formatDate } from '@helper/functions';
import { useHistory } from 'react-router-dom';
import { FlowBotDataItem, FlowInstanceMessageChannel, NodeTemplates } from '@modules/Automation/FlowBots/types';
import { Node } from 'reactflow';
import { SET_BOT, SET_BOT_ID, SET_BOT_STATUS, SET_HAS_OPERATIONS, SET_NODETEMPLATES } from '@store/actions/automation';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import {
   FacebookMessengerIcon,
   InstagramIcon,
   LiveChatIcon,
   WhatsAppBusiness,
   WhatsAppIcon,
} from '@modules/Automation/FlowBots/components/socialIcons';
import TelegramIcon from '@mui/icons-material/Telegram';
import DuplicateFlow from '../../components/Duplicate';

const useStyles = makeStyles((theme: any) => ({
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   title: {
      display: 'flex',
      gap: '10px',
      '&:hover': {
         cursor: 'pointer',
      },
   },
   tableHead: {
      display: 'flex',
      width: '100%',
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
   },
   contentFirstBox: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      width: '100%',

      [theme.breakpoints.down('md')]: {
         gridTemplateColumns: '1fr 2fr',
      },
      [theme.breakpoints.down('sm')]: {
         gridTemplateColumns: '1fr 2fr',
      },
   },
   contentSecondBox: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr 2fr 0.2fr',
   },
   container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: 'calc(100vh - 10rem)',
      textAlign: 'center',
      gap: '1rem',
   },
   text: {
      margin: '0 25rem',
      [theme.breakpoints.down('md')]: {
         margin: '0 1rem',
      },
      [theme.breakpoints.down('sm')]: {
         margin: '0 1rem',
      },
   },
   icon: {
      width: 25,
      height: 25,
   },
}));

interface FlowBotProps {
   selectedItem: FlowBotDataItem | undefined;
   setSelectedItem: React.Dispatch<React.SetStateAction<FlowBotDataItem | undefined>>;
   setFlowBotData: React.Dispatch<React.SetStateAction<FlowBotDataItem[]>>;
   setContentNodes: React.Dispatch<React.SetStateAction<Node[]>>;
   clickedNodeId: string;
   setClickedHandleId: React.Dispatch<React.SetStateAction<string>>;
   setClickedNodeId: React.Dispatch<React.SetStateAction<string>>;
   setIsNewNode: React.Dispatch<React.SetStateAction<string>>;
   filter: string;
}

const FlowBots: React.FC<FlowBotProps> = ({
   selectedItem,
   setSelectedItem,
   setFlowBotData,
   setContentNodes,
   setClickedNodeId,
   clickedNodeId,
   setClickedHandleId,
   filter,
   setIsNewNode,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const { enqueueSnackbar } = useSnackbar();

   const [showModal, setShowModal] = useState(false);
   const [contextMenuAnchor, setContextMenuAnchor] = useState(null);

   const [showDuplicateModal, setShowDuplicateModal] = useState(false);
   const [showDeleteModal, setShowDeleteModal] = useState(false);
   const [isCreate, setIsCreate] = useState(false);
   const [showListFlow, setShowListFlow] = useState(true);
   const [flowBotDataNoFiltered, setFlowBotDataNoFiltered] = useState<FlowBotDataItem[]>([]);

   const person = useSelector((reducer: RootStateOrAny) => reducer.personReducer.person);
   const token = useSelector(getToken);
   const history = useHistory();
   const dispatch = useDispatch();

   const LIST_NODE_TEMPLATE = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.listNodeTemplates);
   const [loading, setLoading] = useState(false);

   const defaultFlowBotQuery = {
      page: 1,
      pageSize: 13,
      customer: person.customer._id,
   };

   const [flowBotQuery, setFlowBotQuery] = useState(defaultFlowBotQuery);
   const [getInfiniteData, setInfiniteData] = useState<{
      hasMore: boolean;
      totalLength: number;
      data: FlowBotDataItem[];
   }>({
      hasMore: true,
      totalLength: 0,
      data: [],
   });

   useEffect(() => {
      if (filter.length > 0) {
         setFlowBotQuery((prevFlowBotQuery) => ({
            ...prevFlowBotQuery,
            search: filter,
         }));
         return () => {};
      }
   }, [filter]);
   const [loadingNewFlow, setLoadingNewFlow] = useState(false);

   const handleNextPage = () => {
      setLoading(true);
      setTimeout(() => {
         getInfiniteData.hasMore &&
            setFlowBotQuery({
               ...flowBotQuery,
               page: flowBotQuery.page + 1,
            });
         setLoading(false);
      }, 500);
   };
   const [isDeleted, setIsDeleted] = useState(false);

   useEffect(() => {
      if (isDeleted && getInfiniteData.data.length === 10) {
         handleNextPage();
      }
   }, [getInfiniteData]);

   const [getBots] = useLazyQuery(GET_FLOW_BOT_PAGINATED_QUERY);
   useEffect(() => {
      getBots({
         variables: { input: flowBotQuery },
         fetchPolicy: 'network-only',
         onCompleted: (res) => {
            if (filter.length > 0) {
               setInfiniteData({
                  ...getInfiniteData,
                  data: [res.getFlowBotPaginated.data.docs].flat(),
                  hasMore: res.getFlowBotPaginated.data.hasNextPage,
                  totalLength: res.getFlowBotPaginated.data.totalRecords,
               });
               setShowListFlow(res.getFlowBotPaginated.data.totalRecords > 0 ? true : false);

               setFlowBotData([...getInfiniteData.data, res.getFlowBotPaginated.data.docs].flat());
               setFlowBotDataNoFiltered([]);
               setContentNodes([]);
               setClickedNodeId('');
            } else {
               setInfiniteData({
                  ...getInfiniteData,
                  data: [...flowBotDataNoFiltered, res.getFlowBotPaginated.data.docs].flat(),
                  hasMore: res.getFlowBotPaginated.data.hasNextPage,
                  totalLength: res.getFlowBotPaginated.data.totalRecords,
               });
               setShowListFlow(res.getFlowBotPaginated.data.totalRecords > 0 ? true : false);

               setFlowBotData([...flowBotDataNoFiltered, res.getFlowBotPaginated.data.docs].flat());
               setFlowBotDataNoFiltered([...flowBotDataNoFiltered, res.getFlowBotPaginated.data.docs].flat());
               setContentNodes([]);
               setClickedNodeId('');
            }
         },
      });
   }, [flowBotQuery]);

   const [createFlowNode] = useMutation(CREATE_FLOW_NODE_MUTATION);
   const handleCreateNode = async (flowBotId: string, nodeTemplate: NodeTemplates[]) => {
      try {
         setContentNodes([]);
         setClickedNodeId('');

         const templateId = nodeTemplate && nodeTemplate.find((item) => item.type === 'trigger');
         const position = { x: 1, y: 1 };

         await createFlowNode({
            variables: {
               input: {
                  coordinates: position,
                  customer: person.customer._id,
                  flowBot: flowBotId,
                  templateId: templateId?._id,
                  title: t.atomation_flow_trigger_block_trigger,
               },
            },
            onCompleted: (res) => {
               history.push(`/automation/flow-builder/${flowBotId}`);
            },
         });
      } catch (error) {
         console.error('Error creating node:', error);
      }
   };

   const [getNodesTemplate] = useLazyQuery(GET_LIST_NODE_TEMPLATES_QUERY);

   // Create mutation
   const [createFlowBot] = useMutation(CREATE_FLOW_BOT_MUTATION);
   const handleCreateFlow = async (newtitle: String) => {
      setLoadingNewFlow(true);
      try {
         await createFlowBot({
            variables: {
               input: {
                  customer: person.customer._id,
                  title: newtitle,
               },
            },
            onCompleted: async (res) => {
               const createdBotId = res.createFlowBot.data._id;
               dispatch({
                  type: SET_BOT_STATUS,
                  payload: res.createFlowBot.data.status === true ? 'published' : 'draft',
               });

               // Define an async function to fetch node templates
               const fetchNodeTemplates = async () => {
                  const response = await getNodesTemplate({
                     variables: { input: {} },
                     fetchPolicy: 'network-only',
                  });

                  if (response.data) {
                     return response.data.listNodeTemplates.data;
                  }

                  return null;
               };

               let nodeTemplate = [];
               if (LIST_NODE_TEMPLATE?.length > 0) {
                  nodeTemplate = LIST_NODE_TEMPLATE;
               } else {
                  nodeTemplate = await fetchNodeTemplates();
                  dispatch({ type: SET_NODETEMPLATES, payload: nodeTemplate });
               }
               if (nodeTemplate) {
                  setShowModal(false);
                  setSelectedItem(res.createFlowBot.data);
                  handleCreateNode(createdBotId, nodeTemplate);
               }
            },
         });
         setLoadingNewFlow(false);
         setShowModal(false);
      } catch (error) {
         console.error('Error creating flow bot:', error);
      }
   };

   // Update mutation
   const [updateFlowBot] = useMutation(UPDATE_FLOW_BOT_MUTATION);

   const handleToggleStatusClicked = async (itemId: String, currentStatus: Boolean) => {
      try {
         await updateFlowBot({
            variables: {
               input: {
                  _id: itemId,
                  status: !currentStatus,
               },
            },
         });
      } catch (error) {
         console.error('Error updating status:', error);

         enqueueSnackbar(t.atomation_flow_notification, {
            variant: 'error',
            autoHideDuration: 2000,
         });
      }
   };

   const handleRenameSubmit = async (newTitle: String) => {
      try {
         if (selectedItem) {
            await updateFlowBot({
               variables: {
                  input: {
                     _id: selectedItem._id,
                     title: newTitle,
                  },
               },
            });
            setShowModal(false);
         }
      } catch (error) {
         console.error('Error renaming flow:', error);
      }
   };
   const [duplicateBotId, setDuplicateBotId] = useState<string>();

   // Subscription
   useSubscription(UPDATE_FLOW_BOT_SUBSCRIPTION, {
      variables: { input: { token, customer: person.customer._id } },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedFlowBot: { data },
            },
         },
      }) => {
         if (data.operationType === 'create') {
            setInfiniteData((prevData) => ({
               ...prevData,
               data: [data.flowBotData, ...prevData.data],
            }));

            //Get the subscription data id to compare with the duplicated Id

            if (duplicateBotId === data.flowBotData._id) {
               setSelectedItem(data.flowBotData);
               history.push(`/automation/flow-builder/${duplicateBotId}`);
               setIsLoading(false);
               dispatch({ type: SET_BOT_STATUS, payload: 'draft' });
            }
         } else if (data.operationType === 'update') {
            setInfiniteData((prevData) => {
               const updatedDataIndex = prevData.data.findIndex((item) => item._id === data.flowBotData._id);
               if (updatedDataIndex !== -1) {
                  const newData = {
                     ...prevData,
                     data: [...prevData.data],
                  };
                  newData.data[updatedDataIndex] = data.flowBotData;
                  return newData;
               }
               return prevData;
            });
         } else if (data.operationType === 'delete') {
            setInfiniteData((prevData) => {
               const updatedDataIndex = prevData.data.findIndex((item) => item._id === data.flowBotData._id);
               if (updatedDataIndex !== -1) {
                  const newData = {
                     ...prevData,
                     data: [...prevData.data],
                  };
                  newData.data.splice(updatedDataIndex, 1);
                  return newData;
               }
               return prevData;
            });
         }
      },
   });

   // Delete mutation
   const [deleteFlowBot] = useMutation(DELETE_FLOW_BOT_MUTATION);
   const handleDeleteSubmit = async () => {
      try {
         if (selectedItem) {
            await deleteFlowBot({
               variables: {
                  input: {
                     _id: selectedItem._id,
                  },
               },
               onCompleted: (res) => {
                  setIsDeleted(true);
                  setFlowBotDataNoFiltered((prevData) => prevData.filter((item) => item._id !== selectedItem._id));
               },
            });
         }
      } catch (error) {
         console.error('Error deleting status:', error);
      }
   };

   const [isLoading, setIsLoading] = useState(false);
   const [duplicateFlowBot] = useMutation(DUPLICATE_FLOW_BOT);
   const handleDuplicateSubmit = async (channel: { label: string; value: string } | undefined) => {
      try {
         if (selectedItem) {
            setIsLoading(true);
            await duplicateFlowBot({
               variables: {
                  input: {
                     channelType: channel?.value,
                     targetedFlowBot: selectedItem._id,
                  },
               },
               onCompleted: (res) => {
                  const flowBotId = res.duplicateFlowBot.data._id;
                  setDuplicateBotId(flowBotId);
               },
            });
         }
      } catch (error) {
         console.error('Error duplicating this flow:', error);
         enqueueSnackbar((error as { message: string }).message, {
            variant: 'error',
            autoHideDuration: 2000,
         });
         setIsLoading(false);
         setShowDuplicateModal(false);
      }
   };

   const closeModal = () => {
      setShowModal(false);
   };

   const handleContextMenuOpen = (event: any, item: FlowBotDataItem) => {
      event.preventDefault();
      setContextMenuAnchor(event.currentTarget);
      setSelectedItem(item);
   };

   const handleContextMenuClose = () => {
      setContextMenuAnchor(null);
   };
   const handleRename = () => {
      setIsCreate(false);
      setShowModal(true);
      setContextMenuAnchor(null);
   };

   const handleTitleClicked = async (item: FlowBotDataItem) => {
      setContentNodes([]);
      setClickedNodeId('');
      setIsNewNode('');

      dispatch({ type: SET_BOT, payload: item?.title });
      dispatch({ type: SET_BOT_ID, payload: item?._id });
      dispatch({ type: SET_BOT_STATUS, payload: item?.status === true ? 'published' : 'draft' });

      await history.push(`/automation/flow-builder/${item._id}`);
   };

   useEffect(() => {
      dispatch({ type: SET_BOT, payload: selectedItem?.title });
      dispatch({ type: SET_HAS_OPERATIONS, payload: false });
   }, [selectedItem]);

   const handleDelete = () => {
      setContextMenuAnchor(null);
      setShowDeleteModal(true);
   };
   const handleDuplicate = () => {
      setContextMenuAnchor(null);
      setShowDeleteModal(false);
      setShowDuplicateModal(true);
   };

   const handleOpenModal = () => {
      setIsCreate(true);
      setShowModal(true);
   };

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' justifyContent='center' xs={12}>
            <CircularProgress size={20} color='inherit' />
         </Grid>
      );
   }, []);

   // screen size and truncate functions
   const getScreenSize = () => {
      const width = window.innerWidth;
      if (width >= 600 && width < 1280) {
         return 17; //sm, md
      } else {
         return 70; //lg
      }
   };

   const truncateString = (str: string, maxLength: number) => {
      if (str?.length <= maxLength) {
         return str;
      } else {
         return str?.slice(0, maxLength) + '...';
      }
   };
   const screenSize = getScreenSize();

   return (
      <Grid style={{ overflow: 'hidden' }}>
         {showListFlow ? (
            <Grid style={{ padding: '0.5rem', maxHeight: '750px', overflow: 'hidden' }}>
               <InfiniteScroll
                  dataLength={getInfiniteData?.data?.length}
                  next={handleNextPage}
                  hasMore={getInfiniteData?.hasMore}
                  loader={loading && <Loader />}
                  scrollableTarget='scrollableDiv'
                  style={{ overflow: 'hidden' }}
               >
                  <TableContainer style={{ width: '100%', overflow: 'hidden' }}>
                     <Table
                        aria-label='customized table'
                        style={{
                           display: 'flex',
                           flexDirection: 'column',
                           width: '100%',
                           overflow: 'hidden',
                        }}
                     >
                        <TableHead className={classes.tableHead}>
                           <TableRow className={classes.contentFirstBox}>
                              <StyledTableCell align='left'>
                                 {t['page_settings_typo_survey_livechat_name']}
                              </StyledTableCell>
                              <div className={classes.contentSecondBox} style={{ paddingRight: '2.5rem' }}>
                                 <StyledTableCell align='right'>{t['page_chat_typo_status']} </StyledTableCell>
                                 <StyledTableCell align='right'>{t['page_settings_typo_update_date']}</StyledTableCell>
                                 <StyledTableCell align='right'></StyledTableCell>
                              </div>
                           </TableRow>
                        </TableHead>
                        <TableBody
                           id='scrollableDiv'
                           style={{
                              display: 'flex',
                              width: '100%',
                              flexDirection: 'column',
                              height: 680,
                              overflow: 'auto',
                           }}
                        >
                           {getInfiniteData.data?.map((item: FlowBotDataItem) => (
                              <StyledTableRow key={item._id} className={classes.contentFirstBox}>
                                 <StyledTableCell component='th' scope='row'>
                                    <Typography className={classes.coloredText}>
                                       <span className={classes.title} onClick={() => handleTitleClicked(item)}>
                                          {item?.triggerFields && (
                                             <div>
                                                {item.triggerFields[0]?.channelType ===
                                                FlowInstanceMessageChannel.LiveChat ? (
                                                   <div>
                                                      <LiveChatIcon selected={true} className={classes.icon} />
                                                   </div>
                                                ) : item.triggerFields[0]?.channelType ===
                                                  FlowInstanceMessageChannel.Facebook ? (
                                                   <FacebookMessengerIcon selected={true} className={classes.icon} />
                                                ) : item.triggerFields[0]?.channelType ===
                                                  FlowInstanceMessageChannel.Instagram ? (
                                                   <InstagramIcon selected={true} className={classes.icon} />
                                                ) : item.triggerFields[0]?.channelType ===
                                                  FlowInstanceMessageChannel.Telegram ? (
                                                   <div
                                                      style={{
                                                         width: 25,
                                                         height: 25,
                                                         backgroundColor: '#25A3E1',
                                                         borderRadius: '45%',
                                                      }}
                                                   >
                                                      <TelegramIcon
                                                         className={classes.icon}
                                                         style={{ color: '#fff', margin: '2px 2px 0 0' }}
                                                      />
                                                   </div>
                                                ) : item.triggerFields[0]?.channelType ===
                                                  FlowInstanceMessageChannel.WhatsappBusiness ? (
                                                   <WhatsAppBusiness selected={true} className={classes.icon} />
                                                ) : item.triggerFields[0]?.channelType ===
                                                  FlowInstanceMessageChannel.WhatsappWeb ? (
                                                   <WhatsAppIcon selected={true} className={classes.icon} />
                                                ) : (
                                                   ''
                                                )}
                                             </div>
                                          )}
                                          <span>{truncateString(item.title, screenSize)}</span>
                                       </span>
                                    </Typography>
                                 </StyledTableCell>
                                 <div className={classes.contentSecondBox}>
                                    <StyledTableCell align='right' component='th' scope='row'>
                                       <FormControlLabel
                                          control={
                                             <CustomSwitch
                                                checked={item?.status}
                                                onChange={() => handleToggleStatusClicked(item._id, item.status)}
                                             />
                                          }
                                          label=''
                                          labelPlacement='start'
                                       />
                                    </StyledTableCell>
                                    <StyledTableCell align='right' component='th' scope='row'>
                                       <Typography>
                                          {formatDate(t.atomation_flow_bot_date_format, item.updatedAt)}
                                       </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align='right'>
                                       <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
                                          <MoreVertIcon onClick={(e) => handleContextMenuOpen(e, item)} />
                                       </Grid>
                                    </StyledTableCell>
                                 </div>
                              </StyledTableRow>
                           ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </InfiniteScroll>
            </Grid>
         ) : (
            <Grid>
               <Grid className={classes.container}>
                  <Typography style={{ fontWeight: 'bold' }}>
                     {t['automation_flow_you_dont_have_any_flow_bot']}
                  </Typography>
                  <Typography className={classes.text}>{t['automation_flow_about_flow_bot']}</Typography>
                  <Grid>
                     <ButtonOK
                        label={t.automation_flow_create_new_plus}
                        style={{
                           color: 'white',
                           backgroundColor: '#157CFC',
                        }}
                        onClick={handleOpenModal}
                     />
                  </Grid>
               </Grid>
            </Grid>
         )}
         <Menu anchorEl={contextMenuAnchor} open={Boolean(contextMenuAnchor)} onClose={handleContextMenuClose}>
            <MenuItem onClick={handleRename}>{t['automation_flow_rename']}</MenuItem>
            <MenuItem onClick={handleDuplicate}>{t['automation_flow_duplicate']}</MenuItem>
            <MenuItem onClick={handleDelete}>
               <span style={{ color: 'red' }}> {t['page_chat_typo_reject']}</span>
            </MenuItem>
         </Menu>
         <Modal open={showModal} onClose={closeModal} modalHeader={t.automation_flow_update_flow}>
            <CreateModal
               value={selectedItem}
               onSubmit={isCreate ? handleCreateFlow : handleRenameSubmit}
               closeModal={closeModal}
               isCreate={isCreate}
               isLoading={loadingNewFlow}
            />
         </Modal>
         <DeleteFow
            isVisible={showDeleteModal}
            setShowModal={setShowDeleteModal}
            onComplate={handleDeleteSubmit}
            text={t.automation_flow_delete_warning}
         />
         <DuplicateFlow
            isVisible={showDuplicateModal}
            setShowModal={setShowDuplicateModal}
            loading={isLoading}
            onComplate={handleDuplicateSubmit}
         />
      </Grid>
   );
};

export default FlowBots;

export function cc_expires_format(string) {
   return string
      .replace(/[^0-9]/g, '')
      .replace(/^([2-9])$/g, '0$1')
      .replace(/^(1{1})([3-9]{1})$/g, '0$1/$2')
      .replace(/^0{1,}/g, '0')
      .replace(/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2');
}

export const normalizeCardNumber = (value) => {
   return (
      value
         .replace(/\s/g, '')
         .match(/[0-9]{1,4}/g)
         ?.join(' ')
         ?.substring(0, 19) || ''
   );
};

import React, { Fragment, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, CircularProgress } from '@material-ui/core';
import ButtonOK from '@components/ButtonOK/buttonOk';
import 'react-phone-input-2/lib/style.css';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm } from 'react-hook-form';
import { ModalLargeText } from '@components/_Modal/ModalTexts';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';

const useStyles = makeStyles((theme: any) => ({
    root: {
        width: 700,
        padding: 20,
        borderRadius: 15,
        maxHeight: 700,
        color: theme.chatApp.general.pallet.passiveStructureBlue,
    },
    iconSize: {
        fontSize: '5rem',
    },
    backButton: {
        color: '#748FA7',
        backgroundColor: '#fff',
        textTransform: 'none',
        width: 128,
        height: 40,
        fontSize: '1.1rem',
        border: '1px solid #748FA7',
    },
    modalButtons: {
        fontSize: '18px',
        textTransform: 'none',
        paddingBlock: 5,
        paddingInline: 15,
        minWidth: 140,
        height: 44,
        borderRadius: 6,
        transition: 'all .5s',
        '&:disabled': {
            opacity: '0.7',
        },
    },
}));


export interface ChannelSwitchModalContentProps {
    onClose: () => void;
    onAccept: () => void;
}

const ChannelSwitchModalContent = ({ onAccept, onClose, ...props }: ChannelSwitchModalContentProps) => {
    const classes = useStyles(props);
    const { lang } = useContext(LanguageContext);
    const t = lang?.translation;

    return (
        <Grid>
            <Fragment>
                <Grid xs={9} item alignItems='flex-start'>
                    <ModalLargeText>
                        {t?.telegram_switch_1}
                        <br />
                        <br />
                        <b>{t?.telegram_switch_2}</b>
                        <br />
                        <br />
                        {t?.telegram_switch_3}
                        <br />
                        {t?.telegram_switch_4}
                        <br />
                        {t?.telegram_switch_5}
                    </ModalLargeText>
                </Grid>
                <Grid container xs={12}>
                    <ModalButtonField>
                        <Button
                            className={classes.modalButtons}
                            onClick={onClose}
                            style={{
                                color: '#212121',
                                backgroundColor: '#eeee',
                                //@ts-ignore
                                '&:hover': {
                                    backgroundColor: '#e0e0e0',
                                },
                            }}
                        >
                            {t?.cancel}
                        </Button>
                        <ButtonOK
                            className={classes.modalButtons}
                            label={t?.update}
                            onClick={onAccept}
                            style={{
                                color: 'white',
                                backgroundColor: '#157CFC',
                                //@ts-ignore
                                '&:hover': {
                                    backgroundColor: '#105CBB',
                                },
                                float: 'right',
                            }}
                        />
                    </ModalButtonField>
                </Grid>
            </Fragment>
        </Grid>
    );
};

export default ChannelSwitchModalContent;

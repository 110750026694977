import React, { Fragment, useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import ModalContent from './modalContent';
import {
   GET_QUICK_ANSWERS_QUERY,
   GET_QUICK_ANSWERS_COUNT,
} from '../../../../queries/Settings/Service/GraphQL/QuickAnswers/query';
import {
   CREATE_QUICK_ANSWER_MUTATION,
   UPDATE_QUICK_ANSWER_MUTATION,
   DELETE_QUICK_ANSWER_MUTATION,
} from '../../../../queries/Settings/Service/GraphQL/QuickAnswers/mutation';
import {
   CREATED_QUICK_ANSWER_SUBSCRIPTION,
   UPDATED_QUICK_ANSWER_SUBSCRIPTION,
   DELETED_QUICK_ANSWER_SUBSCRIPTION,
} from '../../../../queries/Settings/Service/GraphQL/QuickAnswers/subscription';
import { useSnackbar } from 'notistack';
import { useMutation, useLazyQuery, useSubscription } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import ActionIconButton from '@modules/Settings/SettingSpace/ActionIconButton';
import SearchBar from '@components/SearchBar';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
   table: {
      minWidth: 700,
   },
   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   tableBodyTitle: {
      fontSize: '1.1rem',
      fontWeight: 'bold',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   tableBodySubtitle: {
      fontSize: '1rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   paper: {
      borderColor: theme.chatApp.general.pallet.passiveStructureBlue,
      width: '100%',
      borderRadius: 15,
      overflowY: 'auto',
      overflowX: 'hidden',
   },
   Table: {
      ' &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
         outline: 'none !important',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
         outline: 'none !important',
      },
      ' &.MuiDataGrid-root .MuiDataGrid-window': {
         overflowX: 'hidden',
      },
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader': {
         padding: 0,
      },
   },
   content: {
      height: '100vh',
      overflowX: 'hidden',
      overflowY: 'auto',
      flexGrow: 1,
   },
   rowStyle: {
      '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
         outline: 'none',
      },

      fontWeight: 'bold',
      fontSize: '1rem',
   },
}));

const Page = {
   pageSize: 15,
   page: 1,
};

const QuickAnswers = ({ person, token }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [modalConfig, setModalConfig] = useState({ show: false, mode: null /* EDIT | NEW */ });
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const customer = person.customer._id;

   const [quickAnswerListCount, setQuickAnswerListCount] = useState(0);
   const [firstLoad, setFirstLoad] = useState(true);
   const [quickAnswerList, setQuickAnswerList] = useState([]);
   const [noQuickAnswer, setNoQuickAnswer] = useState(false);
   const [text, setText] = useState('');

   // get quick answers
   const [getQuickAnswers, { loading: quickAnswerLoading }] = useLazyQuery(GET_QUICK_ANSWERS_QUERY, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
         let rows = data?.quickAnswers?.data?.docs.map((item) => {
            return {
               id: item._id,
               name: item.name,
               answer: item.answer,
            };
         });
         setQuickAnswerList(rows);

         setQuickAnswerListCount(data?.quickAnswers?.data?.docsCount);
      },

      onError: (error) => {
         // on query error
         // send snack bar message
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  {' '}
                  Kapat
               </div>
            ),
         });
      },
   });

   // create person mutation function
   const [createQuickAnswerMutation, { loading: createQuickAnswerLoading }] = useMutation(CREATE_QUICK_ANSWER_MUTATION);

   // update mutation function
   const [updateQuickAnswerMutation, { loading: updateQuickAnswerLoading }] = useMutation(UPDATE_QUICK_ANSWER_MUTATION);

   // delete person mutation function
   const [deleteQuickAnswerMutation, { loading: deleteQuickAnswerLoading }] = useMutation(DELETE_QUICK_ANSWER_MUTATION);

   // subscription created whats app phone
   useSubscription(CREATED_QUICK_ANSWER_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               createdQuickAnswer: { data },
            },
         },
      }) => {
         const newQuickAnswer = { id: data._id, name: data.name, answer: data.answer };
         const index = quickAnswerList.findIndex((item) => item.id === data._id);
         if (index === -1) {
            setQuickAnswerList([newQuickAnswer, ...quickAnswerList]);
            setQuickAnswerListCount(quickAnswerListCount + 1);
         }
      },
   });

   // subscription updated whats app phone
   useSubscription(UPDATED_QUICK_ANSWER_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               updatedQuickAnswer: { data: updatedData },
            },
         },
      }) => {
         let index = quickAnswerList.findIndex((item) => item.id.toString() === updatedData._id.toString());

         if (index > -1) {
            const newList = quickAnswerList.map((item) => {
               if (item.id.toString() === updatedData._id.toString()) {
                  return {
                     ...item,
                     id: updatedData._id,
                     name: updatedData.name,
                     answer: updatedData.answer,
                  };
               }
               return item;
            });
            setQuickAnswerList(newList);
         }
      },
   });

   // subscription deleted whats app phone
   useSubscription(DELETED_QUICK_ANSWER_SUBSCRIPTION, {
      variables: { token, customer: person ? person.customer._id : '' },
      onSubscriptionData: async ({
         client,
         subscriptionData: {
            data: {
               deletedQuickAnswer: { data: deletedQuickAnswer },
            },
         },
      }) => {
         const { quickAnswer } = deletedQuickAnswer;
         if (quickAnswer) {
            if (quickAnswerListCount === 1) {
               setNoQuickAnswer(true);
            } else {
               setQuickAnswerListCount(quickAnswerListCount - 1);
               setQuickAnswerList(quickAnswerList.filter((item) => item.id !== quickAnswer._id));
            }
         }
      },
   });

   const handleCloseModal = () => {
      setModalConfig({
         show: false,
         mode: null,
      });
   };

   const addQuickAnswerSubmit = async (formData) => {
      // run create mutation
      createQuickAnswerMutation({
         variables: {
            name: formData.name,
            answer: formData.answer,
            customer: person.customer._id,
            payload: formData.payload,
         },
      })
         .then(
            ({
               data: {
                  createQuickAnswer: { data },
               },
            }) => {
               // on success event
               const newQuickAnswer = { id: data._id, name: data.name, answer: data.answer };
               const index = quickAnswerList.findIndex((item) => item.id === data._id);
               if (index === -1) {
                  setQuickAnswerList([newQuickAnswer, ...quickAnswerList]);
                  setQuickAnswerListCount(quickAnswerListCount + 1);
               }

               // close dialogs
               handleCloseModal();
               enqueueSnackbar(` "${data.name}"  ${t?.general_information_added_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
               return '';
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div
                     style={{ color: '#000', fontSize: '13px', cursor: 'pointer', fontWeight: '500' }}
                     onClick={() => closeSnackbar(key)}
                  >
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   // update data on submit
   const editQuickAnswerSubmit = async (formData) => {
      // run update mutation
      updateQuickAnswerMutation({
         variables: {
            _id: formData._id,
            name: formData.name,
            answer: formData.answer,
            payload: formData.payload,
         },
      })
         .then(
            ({
               data: {
                  updateQuickAnswer: { data },
               },
            }) => {
               // on success event
               let index = quickAnswerList.findIndex((item) => item.id.toString() === data._id.toString());

               if (index > -1) {
                  const newList = quickAnswerList.map((item) => {
                     if (item.id.toString() === data._id.toString()) {
                        return {
                           ...item,
                           id: data._id,
                           name: data.name,
                           answer: data.answer,
                        };
                     }
                     return item;
                  });
                  setQuickAnswerList(newList);
               }

               // close modal
               handleCloseModal();
               enqueueSnackbar(`"${data.name}"  ${t?.general_information_updated_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            // on error event
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div
                     style={{ color: '#000', fontSize: '13px', cursor: 'pointer', fontWeight: '500' }}
                     onClick={() => closeSnackbar(key)}
                  >
                     {' '}
                     {t?.general_message_button_close}
                  </div>
               ),
            });
         });
   };

   // delete data on submit
   const handleDeleteQuickAnswer = async (item) => {
      deleteQuickAnswerMutation({ variables: { _id: item._id } }) // run to delete mutation
         .then(
            ({
               data: {
                  deleteQuickAnswer: { data },
               },
            }) => {
               // success event
               if (quickAnswerListCount === 1) {
                  setQuickAnswerList([]);
                  setQuickAnswerListCount(quickAnswerListCount - 1);
               } else {
                  setQuickAnswerListCount(quickAnswerListCount - 1);
                  setQuickAnswerList(quickAnswerList.filter((item) => item.id !== data._id));
               }
               handleCloseModal();
               return enqueueSnackbar(` "${data.name}" ${t.general_information_deleted_successfully}`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               });
            },
         )
         .catch((error) => {
            // error event
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div
                     style={{ color: '#000', fontSize: '13px', cursor: 'pointer', fontWeight: '500' }}
                     onClick={() => closeSnackbar(key)}
                  >
                     {' '}
                     Kapat
                  </div>
               ),
            });
         });
   };

   //edit data
   const handleEditRow = (row) => {
      setModalConfig({
         show: true,
         mode: 'EDIT',
         row: row,
      });
   };

   const handleCreateNewRow = () => {
      setModalConfig({
         show: true,
         mode: 'NEW',
      });
   };

   const handleDeleteModal = (row) => {
      setModalConfig({
         show: true,
         mode: 'DELETE',
         row,
      });
   };

   const [queryParams, setQueryParams] = useState({
      customer,
      pageSize: Page.pageSize,
      page: Page.page,
      search: '',
   });

   useEffect(() => {
      setQueryParams((prev) => ({ ...prev, search: text }));
   }, [text]);

   useEffect(() => {
      getQuickAnswers({
         variables: {
            ...queryParams,
         },
      });
   }, [queryParams]);

   const columns = [
      {
         field: 'name',
         headerName: t?.page_settings_account_title,
         flex: 1,
         minWidth: 150,
      },
      {
         field: 'answer',
         headerName: t?.page_settings_account_content,
         flex: 2,
         minWidth: 150,
      },
      {
         field: 'operation',
         headerName: t?.page_settings_button_operation,
         flex: 1,
         sortable: false,
         headerAlign: 'center',
         align: 'center',
         minWidth: 150,
         renderCell: ({ row }) => {
            return (
               <>
                  <ActionIconButton
                     onClick={() => handleEditRow(row)}
                     tooltip={t?.comp_button_edit}
                     actionType='EDIT'
                  />
                  <ActionIconButton
                     // onClick={() => handleDeleteQuickAnswer(quickAnswer)}
                     onClick={() => handleDeleteModal(row)}
                     tooltip={t?.comp_button_delete}
                     actionType='DELETE'
                  />
               </>
            );
         },
      },
   ];

   return (
      <Fragment>
         {(createQuickAnswerLoading || updateQuickAnswerLoading || deleteQuickAnswerLoading) && (
            <div className='loading' />
         )}

         <div
            className={classes.content}
            style={{
               height: '100%',
               width: '100%',
               // display: firstLoad || noQuickAnswer ? 'none' : 'block',
            }}
         >
            <Grid
               container
               wrap='nowrap'
               style={{
                  height: '100%',
               }}
            >
               <Paper elevation={1} className={classes.paper}>
                  <DataGrid
                     loading={quickAnswerLoading}
                     className={classes.Table}
                     components={{
                        Toolbar: (props) => {
                           return (
                              <div
                                 container
                                 className={classes.titleContainer}
                                 style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                 }}
                              >
                                 <div style={{ flexGrow: '1', fontSize: '20px', fontWeight: 'bold' }}>
                                    {t?.page_settings_typo_quick_answers}
                                 </div>
                                 <div>
                                    <SearchBar
                                       value={text}
                                       onChange={setText}
                                       placeholder={t['search']}
                                       style={{ width: 200, height: 40 }}
                                    />
                                 </div>

                                 <div style={{ paddingLeft: 5 }}>
                                    <ButtonOK
                                       onClick={handleCreateNewRow}
                                       width={200}
                                       height={40}
                                       label={t['page_settings_button_add_new']}
                                       fontSize='1.1rem'
                                    />
                                 </div>
                              </div>
                           );
                        },
                     }}
                     disableSelectionOnClick
                     localeText={{
                        toolbarColumns: '',
                        noRowsLabel: t?.no_data_to_show,
                     }}
                     rowHeight={64}
                     disableColumnMenu
                     disableColumnSelector
                     classes={{ columnHeader: classes.rowStyle }}
                     pageSize={Page.pageSize}
                     paginationMode='server'
                     page={queryParams.page - 1}
                     onPageChange={(params) => {
                        setQueryParams((prevState) => ({
                           ...prevState,
                           page: params + 1,
                        }));
                     }}
                     disableColumn
                     rows={quickAnswerList}
                     rowCount={quickAnswerListCount}
                     columns={columns}
                  />
               </Paper>
            </Grid>
         </div>

         <Modal
            paperStyle={{ width: modalConfig.mode === 'DELETE' ? 450 : 600 }}
            open={modalConfig.show}
            onClose={handleCloseModal}
            modalHeader={
               modalConfig.mode === 'DELETE'
                  ? t?.general_message_are_your_sure
                  : t.page_settings_typo_quick_ans_add_header
            }
         >
            <ModalContent
               quickAnswer={modalConfig.row}
               mode={modalConfig.mode}
               onAccept={modalConfig.mode === 'NEW' ? addQuickAnswerSubmit : editQuickAnswerSubmit}
               onCancel={handleCloseModal}
               onDelete={modalConfig.mode === 'DELETE' && handleDeleteQuickAnswer}
            />
         </Modal>
      </Fragment>
   );
};

export default QuickAnswers;

import React, { useContext } from 'react';
import { Paper, Typography, Grid } from '@material-ui/core';
import { LanguageContext } from '@helper/locale/langContext';
import { DashboardActiveIcon } from '@assets/icons/DashboardIcons/dashboardIcons';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
   paper: {
      width: '220px',
      height: '160px',
   },
   tooltipContainer: {
      background: '#157CFC',
      borderRadius: 4,
      minWidth: 30,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
   },
   count: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: '15px',
   },
   icon: {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
   },
   hour: {
      // color: theme.chatApp.general.pallet.passiveStructureGrey,
      color: '#7C94B2',
   },
}));
const CustomTooltip = (props) => {
   const classes = useStyles();
   const { payload } = props;
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   if (!payload || !payload.length) {
      return null;
   }
   return (
      <Paper style={{ padding: '20px', borderRadius: 10 }} className={classes.paper} elevation={1}>
         <Grid container direction='column' justifyContent='flex-start'>
            <Grid item>
               <Grid container justifyContent='space-between' alignItems='center'>
                  <Typography variant='subtitle2' className={classes.hour}>
                     {payload[0]?.payload?.tooltipTitle}
                  </Typography>
                  <DashboardActiveIcon className={classes.icon} style={{ width: 30, height: 30, color: '#CCD5E3' }} />
               </Grid>
            </Grid>
            <Grid item>
               <Grid container justifyContent='space-between' alignItems='center'>
                  <Typography variant='subtitle2' style={{ color: '#157CFC' }}>
                     {t?.page_dashboard_typo_sent_messages}
                  </Typography>
                  <div className={classes.tooltipContainer}>
                     <Typography
                        // variant='subtitle1'
                        className={classes.count}
                     >
                        {payload[0]?.payload?.sentMessages}
                     </Typography>
                  </div>
               </Grid>
            </Grid>

            {/* Sent Messages */}
            <Grid container justifyContent='space-between' alignItems='center'>
               <Typography variant='subtitle2' style={{ color: '#19AA73' }}>
                  {t?.page_dashboard_typo_received_messages}
               </Typography>
               <div className={classes.tooltipContainer} style={{ background: '#5AC19D' }}>
                  <Typography className={classes.count}>{payload[0]?.payload?.receivedMessages}</Typography>
               </div>
            </Grid>
         </Grid>
      </Paper>
   );
};

export default CustomTooltip;

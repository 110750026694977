import React, { useState, useContext } from 'react';
import { FormControlLabel, Grid, Menu } from '@material-ui/core';
import InfoIcon from '@mui/icons-material/Info';
import CustomSwitch from '@components/Switch';
import QuickReplyModal from '@modules/Automation/FlowBots/components/quickReplyModal';
import { FlowBlockType, IFlowBlockTemplate, QuickRepyTypes } from '@modules/Automation/FlowBots/types';
import { Node } from 'reactflow';
import { LanguageContext } from '@helper/locale/langContext';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   textArea: {
      backgroundColor: '#F7F7FA',
      borderRadius: '5px',
      border: '1px solid #E7E7E7',
      outline: 'none',
      resize: 'none',
      width: '100%',
      height: '100%',
      fontSize: '1rem',
      overflow: 'hidden',
      padding: '10px',
      '&:focus': {
         backgroundColor: '#fff',
         border: '2px solid #147CFC',
      },
   },
   reply: {
      borderRadius: '10px',
      background: '#C3DDFF',
      cursor: 'pointer',
      padding: '10px',
      textAlign: 'left',
   },

   btn: {
      height: 36,
      width: '40%',
      border: '1px dashed #E7E7E7',
      backgroundColor: '#F7F7FA',
      borderRadius: '10px',
      background: 'transparent',
      cursor: 'pointer',
      float: 'right',
   },
}));

interface QuickReplyProps {
   leftNodes: Node[];
   setIsBtnSendMsgDisable: React.Dispatch<React.SetStateAction<boolean>>;
   isBlockTextLast: boolean;
   clickedNodeId: string;
   blockState: IFlowBlockTemplate[];
   setBlockState: React.Dispatch<React.SetStateAction<IFlowBlockTemplate[]>>;
   setToUpdateQR: React.Dispatch<React.SetStateAction<{ _id: string }[]>>;
   setDeletedBlockId: React.Dispatch<React.SetStateAction<string[]>>;
}

const QuickReply: React.FC<QuickReplyProps> = ({
   leftNodes,
   setIsBtnSendMsgDisable,
   clickedNodeId,
   blockState,
   setBlockState,
   setToUpdateQR,
   setDeletedBlockId,
   isBlockTextLast,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const [isCreate, setIsCreate] = useState(false);
   const [selectedItem, setSelectedItem] = useState<{ title: string | null }>();
   const [anchorElFieldQuickRepli, setAnchorElFieldQuickRepli] = useState<null | HTMLElement>(null);
   const [anchorElWarning, setAnchorElWarning] = useState<null | HTMLElement>(null);
   const [selectedBlockId, setSelectedBlockId] = useState<{ _id: string | null; QRindex: number | null }>();
   const [selectedQuickReply, setSelectedQuickReply] = useState<QuickRepyTypes>();
   const backendBlocks = leftNodes.find((item: { id: string }) => item.id === clickedNodeId)?.data?.flowBlocks || [];
   const [isValidMessage, setIsValidMessage] = useState(true);

   const handleToggleBtnClicked = (blockId: string, value: boolean | undefined) => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (value !== undefined) {
               if (item._id === blockId) {
                  return {
                     ...item,
                     fields: item.fields.map((field) => ({
                        ...field,
                        isButtonRequired: !value,
                     })),
                  };
               }
            }
            return item;
         });

         return updatedBlockState;
      });
      setToUpdateQR([{ _id: blockId }]);
   };

   const handleModalOpen = (event: React.MouseEvent<HTMLElement>, blockId: string) => {
      setSelectedBlockId({ _id: blockId, QRindex: null });
      setIsCreate(true);
      setAnchorElFieldQuickRepli(event.currentTarget);
   };
   const handleEditModalOpen = (
      event: React.MouseEvent<HTMLElement>,
      value: string | undefined,
      blockId: string,
      qrIndex: number,
      quick: QuickRepyTypes,
   ) => {
      setIsCreate(false);
      if (value) {
         setSelectedItem({ title: value });
         setSelectedBlockId({ _id: blockId, QRindex: qrIndex });
      }
      setSelectedQuickReply(quick);
      setAnchorElFieldQuickRepli(event.currentTarget);
   };

   const modalClose = () => {
      setAnchorElFieldQuickRepli(null);
      setSelectedItem({ title: null });
      setSelectedBlockId({ _id: null, QRindex: null });
   };

   const handleMessageBtnChange = (blockId: string, value: string | undefined) => {
      if (value !== '') {
         setIsBtnSendMsgDisable(false);
         setIsValidMessage(true);
      } else {
         setIsBtnSendMsgDisable(true);
         setIsValidMessage(false);
      }
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (value !== undefined) {
               if (item._id === blockId) {
                  return {
                     ...item,
                     fields: item.fields.map((field) => ({
                        ...field,
                        requiredButtonMessage: value,
                     })),
                  };
               }
            }
            return item;
         });

         return updatedBlockState;
      });
      setToUpdateQR([{ _id: blockId }]);
   };

   const handleAddQuickReply = (value: string) => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item.type === FlowBlockType.SendQuickReplyMessage) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.quickReplies) {
                        // If quickReplies exist, merge the new quick reply with the existing ones
                        return {
                           ...field,
                           quickReplies: [...field.quickReplies, { type: 'quickReply', title: value }],
                        };
                     } else {
                        // If quickReplies doesn't exist, create a new array with the new quick reply
                        return {
                           ...field,
                           quickReplies: [{ type: 'quickReply', title: value }],
                        };
                     }
                  }),
               };
            }
            return item;
         });

         return updatedBlockState;
      });
   };
   const handleUpdateQuickReply = (value: string) => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === selectedBlockId?._id) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.quickReplies) {
                        return {
                           ...field,
                           quickReplies: field.quickReplies.map((quick, index) => {
                              if (index === selectedBlockId.QRindex) {
                                 return {
                                    ...quick,
                                    title: value,
                                 };
                              }
                              return quick;
                           }),
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });

         return updatedBlockState;
      });
   };
   const handleSelectBackPath = (nodeId: string | null) => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === selectedBlockId?._id) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.quickReplies) {
                        return {
                           ...field,
                           quickReplies: field.quickReplies.map((quick, index) => {
                              if (index === selectedBlockId.QRindex) {
                                 return {
                                    ...quick,
                                    target: nodeId,
                                    hideTheEdge: nodeId === null ? false : true,
                                 };
                              }

                              return quick;
                           }),
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });

         return updatedBlockState;
      });

      setSelectedQuickReply((prev) => ({
         ...prev,
         target: nodeId,
         hideTheEdge: nodeId === null ? false : true,
         type: prev?.type || '',
      }));
   };

   const handleDeleteQuickReply = () => {
      setIsBtnSendMsgDisable(false);
      setBlockState((prevBlockState) => {
         const updatedBlockState = prevBlockState.map((item) => {
            if (item._id === selectedBlockId?._id) {
               setToUpdateQR([{ _id: item._id }]);
               return {
                  ...item,
                  fields: item.fields.map((field) => {
                     if (field.quickReplies) {
                        return {
                           ...field,
                           quickReplies: field.quickReplies.filter((_, index) => index !== selectedBlockId.QRindex),
                        };
                     }
                     return field;
                  }),
               };
            }
            return item;
         });

         return updatedBlockState;
      });
      modalClose();
   };

   const handleBlockDeleteQR = () => {
      const existingBlocks = backendBlocks.some(
         (backendBlock: { _id: string }) => backendBlock._id === selectedBlockId?._id,
      );
      if (existingBlocks) {
         setIsBtnSendMsgDisable(false);
      }

      if (selectedBlockId) {
         setBlockState((prevBlock) => {
            setDeletedBlockId((prevDeletedBlock) => [...prevDeletedBlock, selectedBlockId?._id || '']);
            const updatedBlockState = prevBlock.filter((item) => item._id !== selectedBlockId?._id);
            const texBlock = blockState.find((block) => block.type === FlowBlockType.SendMessage);
            const updatedBlockState2 = updatedBlockState.filter((item) => item._id !== texBlock?._id);

            setDeletedBlockId((prevBlock) => [...prevBlock, texBlock?._id || '']);

            return updatedBlockState2;
         });

         modalClose();
      }
   };

   return (
      <Grid>
         {blockState.map((block) => (
            <div>
               {block.type === FlowBlockType.SendQuickReplyMessage ? (
                  <Grid style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginBottom: '3rem' }}>
                     {block.fields.map((field) => (
                        <div style={{ display: 'flex', gap: '10px', flexDirection: 'column' }}>
                           <div
                              style={{
                                 display: 'flex',
                                 flexDirection: 'column',
                                 textAlign: 'right',
                                 float: 'right',
                              }}
                           >
                              {field.quickReplies?.map((quick, quickIndex) => (
                                 <div
                                    style={{
                                       display: 'flex',
                                       gap: '5px',
                                       justifyContent: 'flex-end',
                                       margin: '10px 0 0 0',
                                    }}
                                 >
                                    <span
                                       className={classes.reply}
                                       style={{ alignSelf: 'flex-end' }}
                                       key={quickIndex}
                                       onClick={(e) =>
                                          handleEditModalOpen(e, quick.title, block._id, quickIndex, quick)
                                       }
                                    >
                                       {quick.title}
                                    </span>
                                 </div>
                              ))}
                           </div>
                           {field.quickReplies && field.quickReplies?.length < 10 && (
                              <Grid>
                                 <button className={classes.btn} onClick={(e) => handleModalOpen(e, block._id)}>
                                    + {t.atomation_flow_quick_reply}
                                 </button>
                              </Grid>
                           )}

                           <div>
                              {field.quickReplies && field.quickReplies?.length > 0 && (
                                 <Grid
                                    style={{
                                       display: 'flex',
                                       justifyContent: 'space-between',
                                       width: '100%',
                                       marginBottom: '5px',
                                       marginTop: '1.2rem',
                                    }}
                                 >
                                    <div style={{ display: 'flex', gap: '3px' }}>
                                       <InfoIcon style={{ color: '#C3DDFF', marginTop: '-2px' }} />
                                       <span style={{ fontSize: '14px' }}>{t.atomation_flow_can_you_response}</span>
                                    </div>
                                    <div style={{ marginRight: '-15px' }}>
                                       <FormControlLabel
                                          control={
                                             <CustomSwitch
                                                checked={field.isButtonRequired}
                                                onChange={() =>
                                                   handleToggleBtnClicked(block._id, field.isButtonRequired)
                                                }
                                             />
                                          }
                                          label=''
                                       />
                                    </div>
                                 </Grid>
                              )}
                              {field.quickReplies && field.quickReplies?.length > 0 && field.isButtonRequired && (
                                 <textarea
                                    className={classes.textArea}
                                    name='rawText'
                                    placeholder={t.atomation_flow_enter_message}
                                    value={field.requiredButtonMessage || ''}
                                    onChange={(e) => handleMessageBtnChange(block._id, e.target.value)}
                                    style={{ borderColor: !isValidMessage ? '#FF7474' : '' }}
                                 />
                              )}
                           </div>
                        </div>
                     ))}
                  </Grid>
               ) : (
                  ''
               )}
               <Menu
                  anchorEl={anchorElFieldQuickRepli}
                  open={Boolean(anchorElFieldQuickRepli)}
                  onClose={modalClose}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{
                     style: {
                        width: '450px',
                        borderRadius: '10px',
                        padding: '10px 20px 0 20px',
                     },
                  }}
               >
                  <QuickReplyModal
                     value={selectedItem}
                     onSubmit={isCreate ? handleAddQuickReply : handleUpdateQuickReply}
                     closeModal={modalClose}
                     deleteReply={handleDeleteQuickReply}
                     deleteAll={handleBlockDeleteQR}
                     isCreate={isCreate}
                     isHasQickReply={true}
                     leftNodes={leftNodes}
                     handleSelectBackPath={handleSelectBackPath}
                     selectedQuickReply={selectedQuickReply}
                     clickedNodeId={clickedNodeId}
                  />
               </Menu>
               <Menu
                  anchorEl={anchorElWarning}
                  open={Boolean(anchorElWarning)}
                  onClose={() => setAnchorElWarning(null)}
                  style={{ marginTop: '-2rem', marginLeft: '1rem' }}
                  PaperProps={{
                     style: {
                        borderRadius: '10px',
                        padding: '10px',
                     },
                  }}
               >
                  <span>{t.atomation_flow_warning}</span>
               </Menu>
            </div>
         ))}
      </Grid>
   );
};

export default QuickReply;

import { useLazyQuery, useMutation } from '@apollo/client';
import { ExternalLinkIcon } from '@assets/icons/ProfileIcons';
import DoubleInput from '@components/_Modal/DoubleInput';
import SingleInput from '@components/_Modal/SingleInput';
import useTranslateLanguage from '@hooks/useTranslateLanguage';
import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import { LOCATIONS } from '@queries/Locations/query';
import { GET_CHAT_TAGS_QUERY } from '@queries/Settings/Service/GraphQL/ChatTags/query';
import { GET_CUSTOM_FIELDS, GET_PERSON_CUSTOM_FIELDS } from '@queries/Settings/Service/GraphQL/CustomFields/query';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ButtonOK from '../../../../../components/ButtonOK/buttonOk';
import { LanguageContext } from '../../../../../helper/locale/langContext';
import { UPDATE_PERSON_MUTATION } from '../../../../../queries/Person/Service/GraphQL/mutation';
import { PLATFORM_TYPE } from '../../../../../queries/Settings/Service/GraphQL/Platform/types';
import {
   UPDATE_ACTIVE_CHAT_PARTICIPANTS,
   UPDATE_MY_CHAT_LIST_PARTICIPANTS,
   UPDATE_MY_CUSTOMER_CHAT_LIST_PARTICIPANTS,
} from '../../../../../store/actions/chat';
import { EnumCustomFieldsType } from '../../../../Settings/SettingSpace/CustomFields/customFields';
const useStyles = makeStyles((theme) => ({
   container: {
      height: 'fit-content',
      overflowY: 'auto',
      borderBottom: '1px solid lightgrey',
   },

   tab: {
      minWidth: 72,
      flex: 1,
   },

   button: {
      // paddingTop:10,
      fontSize: '0.9rem',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: '#23d935',
      '&:hover': {
         backgroundColor: theme.palette.success.dark,
      },
   },
   cancel: {
      backgroundColor: '#f21630',
      '&:hover': {
         backgroundColor: '#970617',
      },
   },
   item: {
      marginTop: 3,
      marginBottom: 3,
   },
   tag: {
      marginLeft: 1,
      marginRight: 1,
      paddingTop: 7,
      height: 30,
      width: 68,
      padding: 5,
      borderRadius: 10,
      fontWeight: 'bold',
      border: 'solid 1px #cbd3dd',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   backgroundContainer: {
      ...theme.mixins.toolbar,
      height: '100vh',
      backgroundColor: theme.chatApp.general.pallet.passiveStructureGrey,
      marginTop: 60,
      width: '400px',
   },
   itemContainer: {
      paddingTop: 2,
      paddingBottom: 2,
      marginTop: 4,
   },
   avatarContainer: {
      paddingLeft: 15,
      marginTop: 10,
      [theme.breakpoints.down('xs')]: {
         marginTop: 25,
      },
   },
   label: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
   },
   fixedValues: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      marginLeft: 4,
   },
   nameText: {
      fontSize: '1.1rem',
   },
   information: {
      borderBottom: '1px solid lightgrey',
      padding: '10px',
   },
   externalLinkIconContainer: {
      marginLeft: 8,
   },
   externalLinkIcon: {
      width: 20,
      height: 20,
   },
   closeIcon: {
      position: 'absolute',
      top: '33px',
      right: '50px',
      width: '20px',
      opacity: '.8',
      cursor: 'pointer',
   },
}));

const Profile = ({ activeChatId, setPersonLoading }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const me = useSelector((reducer) => reducer.personReducer.person);
   const activeChat = useSelector((reducer) => reducer.chatReducer.activeChat);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const { data: languagesData, loading: languagesLoading } = useTranslateLanguage(lang.shortTitle);
   const dispatch = useDispatch();
   const participant = activeChat?.participants.find((item) => item._id !== me._id);

   const CHAT_TAGS_PAGE_SIZE = 10;

   const [isOpenCustomFields, setIsOpenCustomFields] = useState(false);
   const [isRemovePersonCustomFieldList, setIsRemovePersonCustomFieldList] = useState(false);
   const [isChangedTags, setIsChangedTags] = useState(false);
   const [filteredCustomFieldData, setFilteredCustomFieldData] = useState();
   const [chatTagsData, setChatTagsData] = useState();

   const isChatFacebook = activeChat?.type === PLATFORM_TYPE.FACEBOOK;
   const isChatInstagram = activeChat?.type === PLATFORM_TYPE.INSTAGRAM;

   const renderUserName = participant.username && (isChatFacebook || isChatInstagram);
   const userProfileURL =
      renderUserName && `https://www.${isChatFacebook ? 'facebook' : 'instagram'}.com/${participant.username}`;

   const defaultValues = useMemo(
      () => ({
         name: participant.name || '',
         surname: participant.surname || '',
         email: participant.email || '',
         phone: participant.phone || '',
         tags: participant.tags || [],
         country: participant.country
            ? {
                 label: participant.country,
                 value: participant.country,
              }
            : '',
         language: participant.language
            ? {
                 label: participant.language,
                 value: participant.language,
              }
            : '',
         state: participant.state
            ? {
                 label: participant.state,
                 value: participant.state,
              }
            : '',
         personCustomFields: {},
      }),
      [
         participant?.name,
         participant?.surname,
         participant?.phone,
         participant?.email,
         participant?.tags,
         participant?.country,
         participant?.language,
         participant?.state,
      ],
   );

   const {
      control,
      watch,
      formState: { isValid, isDirty },
      setValue,
      reset,
   } = useForm({
      mode: 'all',
      reValidateMode: 'all',
      defaultValues: defaultValues,
   });

   const [updatePersonMutation, { loading: updatePersonLoading }] = useMutation(UPDATE_PERSON_MUTATION, {
      async update(
         cache,
         {
            data: {
               updatePerson: { data: updatePerson },
            },
         },
      ) {
         //  updating REDUX after mutation
         dispatch({ type: UPDATE_ACTIVE_CHAT_PARTICIPANTS, payload: updatePerson });
         dispatch({
            type: UPDATE_MY_CHAT_LIST_PARTICIPANTS,
            payload: { updatePerson, activeChatId },
         });
         dispatch({
            type: UPDATE_MY_CUSTOMER_CHAT_LIST_PARTICIPANTS,
            payload: { updatePerson, activeChatId },
         });
      },
   });

   // update data on submit
   const editPersonSubmit = async () => {
      // run update mutation
      updatePersonMutation({
         variables: {
            _id: participant._id,
            name: watch('name'),
            surname: watch('surname'),
            email: watch('email'),
            phone: watch('phone'),
            tags: watch('tags') || null,
            country: watch('country.label') || null,
            state: watch('state.label') || null,
            language: watch('language.label') || null,
            personCustomFields: Object.entries(watch('personCustomFields')).map(([key, value]) => {
               if (typeof value === 'object' && value !== null) {
                  return {
                     customField: key,
                     value: value.value,
                  };
               } else {
                  return {
                     customField: key,
                     value: value || '',
                  };
               }
            }),
         },
      })
         .then(
            ({
               data: {
                  updatePerson: { data: updatePerson },
               },
            }) => {
               enqueueSnackbar(`${updatePerson.name} başarılı bir şekilde güncellendi`, {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 2000,
               });
            },
         )
         .catch((error) => {
            enqueueSnackbar(error.message, {
               // send error message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         })
         .finally(() => {
            setPersonLoading(true);
         });
   };

   const [getPersonCustomField, { data: personCustomField }] = useLazyQuery(GET_PERSON_CUSTOM_FIELDS, {
      fetchPolicy: 'cache-and-network',
   });

   const [getCustomFieldsData, { data: customFieldsData }] = useLazyQuery(GET_CUSTOM_FIELDS, {
      fetchPolicy: 'cache-first',
      onCompleted: () => {
         getPersonCustomField({
            variables: {
               person: participant._id,
            },
         }).then((res) => {
            if (res.data) {
               res.data.personCustomFields.data.forEach((field) => {
                  if (field.customField.type === EnumCustomFieldsType.LIST) {
                     setValue(`personCustomFields.${field.customField._id}`, {
                        label: field.value,
                        value: field.value,
                     });
                  } else {
                     setValue(`personCustomFields.${field.customField._id}`, field.value);
                  }
               });
               setFilteredCustomFieldData(
                  customFieldsData.getCustomFields.data.filter(
                     (upperItem) =>
                        !res.data.personCustomFields.data.some(
                           (lowerItem) => upperItem._id === lowerItem.customField._id,
                        ),
                  ),
               );
            }
         });
      },
      onError: () => {
         getPersonCustomField({
            variables: {
               person: participant._id,
            },
         }).then((res) => {
            if (res.data) {
               res.data.personCustomFields.data.forEach((field) => {
                  if (field.customField.type === EnumCustomFieldsType.LIST) {
                     setValue(`personCustomFields.${field.customField._id}`, {
                        label: field.value,
                        value: field.value,
                     });
                  } else {
                     setValue(`personCustomFields.${field.customField._id}`, field.value);
                  }
               });
            }
         });
      },
   });

   const [getCountryInvoice, { data: countryDataInvoice, loading: countryLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'country',
         id: 0,
      },
   });

   const [getStateInvoice, { data: stateDataInvoice, loading: stateLoadingInvoice }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'state',
         id: watch('country.value'),
      },
   });

   const [getChatTagQuery] = useLazyQuery(GET_CHAT_TAGS_QUERY, {
      fetchPolicy: 'cache-first',
   });

   const getTagsLoadOptions = async (search, prevOptions) => {
      if (search.length !== 0) {
         if (search.length < 3) {
            return {
               options: chatTagsData.map(({ name, _id, color }) => {
                  return {
                     value: _id,
                     label: name,
                     color,
                  };
               }),
               hasMore: false,
            };
         }
      }

      const getChatTagRequestData = await getChatTagQuery({
         variables: {
            customer: me.customer._id,
            search,
            pageSize: CHAT_TAGS_PAGE_SIZE,
            page: prevOptions.length === 0 ? 1 : prevOptions.length / CHAT_TAGS_PAGE_SIZE + 1,
         },
      })
         .then((res) => {
            setChatTagsData(res.data.getChatTagList.data.docs);
            return res.data.getChatTagList.data;
         })
         .catch(() => []);
      return {
         options: getChatTagRequestData.docs.map(({ name, _id, color }) => {
            return {
               value: _id,
               label: name,
               color,
            };
         }),
         hasMore: getChatTagRequestData.hasNextPage,
      };
   };

   useEffect(() => {
      getCustomFieldsData({
         variables: {
            customer: me.customer._id,
         },
      });
   }, [activeChatId]);

   useEffect(() => {
      if (personCustomField) {
         participant.personCustomFields = personCustomField?.personCustomFields?.data;
      }
   }, [personCustomField]);

   useEffect(() => {
      reset(defaultValues);
   }, [defaultValues, reset]);

   const isValidNewCustomFieldValid = (type) => {
      switch (type) {
         case 'EMAIL':
            return {
               pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t['page_contacts_typo_custom_field_invalid_email'],
               },
            };
         case 'NUMBER':
            return {
               pattern: {
                  value: /^\d+$/,
                  message: t['page_contacts_typo_custom_field_invalid_number'],
               },
            };
         case 'URL':
            return {
               pattern: {
                  value: /^((http(s?)?):\/\/)?([wW]{3}\.)?[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/g,
                  message: t['page_contacts_typo_custom_field_invalid_url'],
               },
            };
         case 'DATE':
            return {
               pattern: {
                  value:
                     lang.shortTitle === 'TR'
                        ? /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/
                        : /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
                  message: t['page_contacts_typo_custom_field_invalid_date'],
               },
            };
         default:
            return;
      }
   };

   const customFieldPlaceHolder = (type) => {
      switch (type) {
         case 'EMAIL':
            return t['page_contacts_typo_custom_field_placeholder_email'];
         case 'NUMBER':
            return t['page_contacts_typo_custom_field_placeholder_number'];
         case 'URL':
            return t['page_contacts_typo_custom_field_placeholder_url'];
         case 'DATE':
            return t['page_contacts_typo_custom_field_placeholder_date'];
         case 'TEXT':
            return t['page_contacts_typo_custom_field_placeholder_text'];
         case 'LIST':
            return t['page_contacts_typo_custom_field_placeholder_list_values'];
         case 'PHONE':
            return t['page_contacts_typo_custom_field_placeholder_phone'];
         default:
            return;
      }
   };

   const tagsStyles = {
      multiValue: (provided, state) => ({
         ...provided,
         backgroundColor: state.data.color,
      }),
      multiValueLabel: (provided, state) => ({
         ...provided,
         color: state.data.color === '#fff' ? '#212121' : '#fff',
      }),
      multiValueRemove: (provided, state) => ({
         ...provided,
         color: state.data.color === '#fff' ? '#212121' : '#fff',
         '&:hover': {
            backgroundColor: 'rgba(0,0,0, .1)',
            color: state.data.color === '#fff' ? '#212121' : '#fff',
         },
         cursor: 'pointer',
      }),
   };

   return updatePersonLoading ? (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
         <CircularProgress size={40} color='primary' />
      </div>
   ) : (
      <>
         <div className={classes.container}>
            {renderUserName && (
               <Grid
                  className={classes.information}
                  item
                  xs={12}
                  style={{ padding: 10 }}
                  container
                  justify='flex-start'
                  alignItems='center'
               >
                  <Typography className={classes.label}> {t['page_chat_typo_profile_username']}: </Typography>
                  <Typography className={classes.fixedValues}>{'@' + participant.username}</Typography>
                  <a
                     target='_blank'
                     rel='noopener noreferrer'
                     href={userProfileURL}
                     className={classes.externalLinkIconContainer}
                  >
                     <ExternalLinkIcon className={classes.externalLinkIcon} />
                  </a>
               </Grid>
            )}
            <Grid style={{ padding: 12 }}>
               <DoubleInput
                  name1='name'
                  control1={control}
                  label1={t['page_account_input_profile_name']}
                  rules1={{
                     required: t['required_field'],
                  }}
                  name2='surname'
                  control2={control}
                  label2={t['page_account_input_profile_surname']}
               />
               <SingleInput name='email' type='email' control={control} label={t['page_account_input_profile_email']} />
               <SingleInput name='phone' control={control} isPhone label={t['page_account_input_profile_phone']} />
               <SingleInput
                  name='tags'
                  type='tags'
                  control={control}
                  label={t['page_account_input_profile_tag']}
                  isPaginateSelect
                  isMulti
                  autoHeight
                  maxMenuHeight={190}
                  customSelectStyles={tagsStyles}
                  selectOptions={chatTagsData?.getChatTagList?.data.docs.map(({ name, _id, color }) => ({
                     value: _id,
                     label: name,
                     color,
                  }))}
                  value={watch('tags')}
                  loadOptions={getTagsLoadOptions}
                  onChange={(tag) => {
                     setValue('tags', tag);
                     setIsChangedTags(true);
                  }}
               />
               <SingleInput
                  maxMenuHeight={190}
                  name='language'
                  isSelect
                  customSelectStyles={{
                     menu: (base) => ({
                        ...base,
                        top: 'auto',
                        bottom:
                           // menuItem position top or bottom
                           Object.values(watch('personCustomFields')).filter((item) => item !== undefined).length ===
                              0 &&
                           !isOpenCustomFields &&
                           '100%',
                     }),
                  }}
                  selectOptions={languagesData?.map(({ label, code }) => ({
                     value: code,
                     label,
                  }))}
                  isLoading={languagesLoading}
                  control={control}
                  label={t['page_account_input_profile_language']}
               />
               <DoubleInput
                  maxMenuHeight1={190}
                  name1='country'
                  control1={control}
                  label1={t['page_account_input_profile_country']}
                  isSelect1
                  isLoading1={countryLoadingInvoice}
                  onFocus1={() => {
                     setValue('state', null);
                     getCountryInvoice();
                  }}
                  customSelectStyles1={{
                     menu: (base) => ({
                        ...base,
                        top: 'auto',
                        bottom:
                           // menuItem position top or bottom
                           Object.values(watch('personCustomFields')).filter((item) => item !== undefined).length ===
                              1 &&
                           !isOpenCustomFields &&
                           '100%',
                     }),
                  }}
                  selectOptions1={countryDataInvoice?.locations?.data?.map(({ name, id }) => ({
                     value: id,
                     label: name,
                  }))}
                  name2='state'
                  maxMenuHeight2={190}
                  control2={control}
                  isSelect2
                  isLoading2={stateLoadingInvoice}
                  disabled2={watch('country').length === 0 || countryLoadingInvoice}
                  onFocus2={() => {
                     if (typeof watch('country.value') === 'number') {
                        getStateInvoice();
                     }
                  }}
                  customSelectStyles2={{
                     menu: (base) => ({
                        ...base,
                        top: 'auto',
                        bottom:
                           // menuItem position top or bottom
                           Object.values(watch('personCustomFields')).filter((item) => item !== undefined).length ===
                              1 &&
                           !isOpenCustomFields &&
                           '100%',
                     }),
                  }}
                  selectOptions2={stateDataInvoice?.locations?.data?.map(({ name, id }) => ({
                     value: id,
                     label: name,
                  }))}
                  label2={t['page_account_input_profile_city']}
               />
               {participant.personCustomFields &&
                  participant.personCustomFields.map((field) => {
                     return (
                        <div style={{ position: 'relative' }}>
                           <SingleInput
                              placeholder={customFieldPlaceHolder(field.customField.type)}
                              name={`personCustomFields.${field.customField._id}`}
                              isDate={field.customField.type === EnumCustomFieldsType.DATE} // 12/12/2020 for formatter
                              isSelect={field.customField.type === EnumCustomFieldsType.LIST}
                              control={control}
                              maxMenuHeight={190}
                              customSelectStyles={{
                                 menu: (base) => ({
                                    ...base,
                                    top: 'auto',
                                    bottom: !isOpenCustomFields && '100%',
                                 }),
                              }}
                              selectOptions={participant.personCustomFields
                                 .filter(
                                    (item) =>
                                       item.customField._id === field.customField._id &&
                                       item.customField.type === EnumCustomFieldsType.LIST,
                                 )[0]
                                 ?.customField?.listValues?.map((value) => ({
                                    label: value,
                                    value,
                                 }))}
                              variant='outlined'
                              label={field.customField.name}
                              rules={isValidNewCustomFieldValid(field.customField.type)}
                              isPhone={field.customField.type === EnumCustomFieldsType.PHONE}
                           />
                           {field.customField.type === EnumCustomFieldsType.LIST &&
                              watch(`personCustomFields.${field.customField._id}`) && (
                                 <span
                                    onClick={() => {
                                       setValue(`personCustomFields.${field.customField._id}`, '');
                                       setIsRemovePersonCustomFieldList(true);
                                    }}
                                 >
                                    <CloseIcon className={classes.closeIcon} />
                                 </span>
                              )}
                        </div>
                     );
                  })}

               <div
                  style={{
                     borderTop: 'solid rgba(0,0,0, .05) 1.5px',
                     borderBottom: 'solid rgba(0,0,0, .05) 1.5px',
                  }}
               >
                  <Grid
                     container
                     direction='participant'
                     style={{
                        borderRadius: '5px',
                     }}
                     alignItems='center'
                     wrap='nowrap'
                  >
                     <Typography
                        noWrap
                        style={{
                           textDecoration: 'none',
                           display: 'flex',
                           justifyContent: 'space-between',
                           width: '100%',
                           color: filteredCustomFieldData ? '#212121' : '#9E9E9E',
                        }}
                     >
                        {t['page_settings_typo_custom_field']}
                     </Typography>
                     <IconButton
                        disabled={!filteredCustomFieldData}
                        onClick={() => setIsOpenCustomFields(!isOpenCustomFields)}
                     >
                        {isOpenCustomFields ? (
                           <KeyboardArrowDownIcon style={{ fontSize: '32px' }} />
                        ) : (
                           <KeyboardArrowRightIcon style={{ fontSize: '32px' }} />
                        )}
                     </IconButton>
                  </Grid>
               </div>
               {isOpenCustomFields && filteredCustomFieldData && (
                  <div style={{ marginTop: '1.5rem' }}>
                     {Array.from(filteredCustomFieldData)
                        .reverse()
                        .map((field, index) => {
                           return (
                              <div style={{ position: 'relative' }}>
                                 <SingleInput
                                    maxMenuHeight={190}
                                    placeholder={customFieldPlaceHolder(field.type)}
                                    name={`personCustomFields.${field._id}`}
                                    isDate={field.type === EnumCustomFieldsType.DATE} // 12/12/2020 for formatter
                                    isSelect={field.type === EnumCustomFieldsType.LIST}
                                    selectOptions={field.listValues.map((value) => ({
                                       label: value,
                                       value,
                                    }))}
                                    customSelectStyles={{
                                       menu: (base) => ({
                                          ...base,
                                          top: 'auto',
                                          bottom: filteredCustomFieldData.length - index < 3 ? '100%' : 'auto',
                                       }),
                                    }}
                                    control={control}
                                    variant='outlined'
                                    label={field.name}
                                    rules={isValidNewCustomFieldValid(field.type)}
                                    isPhone={field.type === EnumCustomFieldsType.PHONE}
                                 />
                                 {field.type === EnumCustomFieldsType.LIST &&
                                    watch(`personCustomFields.${field._id}`) && (
                                       <span onClick={() => setValue(`personCustomFields.${field._id}`, '')}>
                                          <CloseIcon className={classes.closeIcon} />
                                       </span>
                                    )}
                              </div>
                           );
                        })}
                  </div>
               )}
            </Grid>
         </div>
         <Grid style={{ padding: '15px 10px 20px 10px' }}>
            <ButtonOK
               disabled={(!isDirty && !isRemovePersonCustomFieldList && !isChangedTags) || !isValid}
               fullWidth
               onClick={editPersonSubmit}
               label={t['page_chat_typo_profile_save']}
               height={35}
               fontWeight='bold'
               fontSize='1.1rem'
               style={{ width: '100%' }}
            />
         </Grid>
      </>
   );
};

export default Profile;

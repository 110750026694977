import { useMutation, useLazyQuery } from '@apollo/client';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import { LanguageContext } from '@helper/locale/langContext';
import { Typography } from '@material-ui/core';
import {
   Grid,
   IconButton,
   ListItemText,
   ListItem,
   Select,
   Input,
   CircularProgress,
   FormControl,
   FormControlLabel,
   Radio,
   RadioGroup,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Cancel';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { MOVE_USERS_TO_GROUP } from '@queries/Campaigns/Service/GraphQL/mutation';
import { GET_PERSON_GROUPS } from '@queries/PersonGroups/Service/GraphQL/query';
import { useSelector } from 'react-redux';
import WithUrlTranslations from '../../../hoc/WithUrlTranslations';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';

const useStyles = makeStyles((theme) => ({
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 450,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: 25,
      paddingTop: 10,
   },
   modalBodyIconContainer: {
      display: 'flex',
      flexDirection: 'column',
   },
   modalDescription: {
      marginBottom: 55,
   },
   modalBodyRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-between',
   },
   modalText: {
      color: '#797575',
      fontSize: '1rem',
      margin: 20,
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

const ReportModal = ({ open, onClose, campaignData }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const [isLoading, setLoading] = useState(false);
   const [selectedAck, setSelectedAck] = useState(2);
   const [selectedGroup, setSelectedGroup] = useState(-1);
   const person = useSelector((reducer) => reducer.personReducer.person);
   const { enqueueSnackbar } = useSnackbar();

   const [availablePersonGroups, setAvailablePersonGroups] = useState({
      groups: [],
      loading: false,
   });

   const [getPersonGroups] = useLazyQuery(GET_PERSON_GROUPS, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
         setAvailablePersonGroups({
            groups: data.getPersonGroups.data.filter((item) => !item.isDynamic),
            loading: true,
         });
      },
   });

   const [moveUsersToGroup] = useMutation(MOVE_USERS_TO_GROUP, {
      async update(
         cache,
         {
            data: {
               moveUsersToGroup: { data: moveUsersToGroup },
            },
         },
      ) {},
   });

   useEffect(() => {
      if (!availablePersonGroups.loading) {
         getPersonGroups({
            variables: {
               input: {
                  customer: person.customer._id,
               },
            },
         });
         setAvailablePersonGroups({
            ...availablePersonGroups,
            loading: true,
         });
      }
   }, [getPersonGroups, person.customer._id, availablePersonGroups]);

   return (
      <Modal paperStyle={{ width: 450 }} open={open} modalHeader={t['campaign_report_page']}>
         <FormControl>
            <RadioGroup
               aria-labelledby='demo-controlled-radio-buttons-group'
               name='controlled-radio-buttons-group'
               value={selectedAck}
               onChange={(x) => {
                  setSelectedAck(Number(x.target.value));
               }}
            >
               <FormControlLabel
                  value={2}
                  control={<Radio />}
                  label={t['campaign_delivered'] + ` (${campaignData?.success_count} ${t['campaign_contacts']})`}
               />
               <FormControlLabel
                  value={3}
                  control={<Radio />}
                  label={t['campaign_not_delivered'] + ` (${campaignData?.failure_count} ${t['campaign_contacts']})`}
               />
               <FormControlLabel
                  value={4}
                  control={<Radio />}
                  label={
                     t['campaign_platform_error'] +
                     ` (${campaignData?.platform_error_count}  ${t['campaign_contacts']})`
                  }
               />
            </RadioGroup>
         </FormControl>
         <Grid style={{ marginBottom: 16 }}>
            <Select
               fullWidth
               displayEmpty
               value={selectedGroup}
               onChange={(e) => {
                  setSelectedGroup(e.target.value);
               }}
               input={<Input fullWidth disableUnderline={false} />}
               renderValue={(selected) => {
                  return (
                     <span>
                        {selectedGroup === -1
                           ? t['campaign_report_no_group_selected']
                           : availablePersonGroups.groups.find((group) => group._id === selectedGroup)?.name}
                     </span>
                  );
               }}
               MenuProps={{
                  getContentAnchorEl: () => null,
                  anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                  transformOrigin: { vertical: 'top', horizontal: 'left' },
                  PaperProps: {
                     style: {
                        maxHeight: 200,
                        width: 300,
                     },
                  },
               }}
            >
               {availablePersonGroups.groups.length === 0 ? (
                  <ListItem disabled disablePadding>
                     <span>{t['no_user_group']}</span>
                  </ListItem>
               ) : (
                  (() => {
                     let map = [];
                     map.push(
                        <ListItem
                           style={{
                              border: 'none',
                              outline: 'none',
                              cursor: 'pointer',
                           }}
                           key={-1}
                           value={-1}
                           disablePadding
                        >
                           <ListItemText>{t['campaign_report_no_group_selected']}</ListItemText>
                        </ListItem>,
                     );
                     availablePersonGroups.groups.forEach((group) => {
                        let element = (
                           <ListItem
                              style={{
                                 border: 'none',
                                 outline: 'none',
                                 cursor: 'pointer',
                              }}
                              key={group._id}
                              value={group._id}
                              disablePadding
                           >
                              <ListItemText>
                                 {group.name} <span style={{ opacity: 0.7 }}>({group.userCount})</span>
                              </ListItemText>
                           </ListItem>
                        );
                        map.push(element);
                     });
                     return map;
                  })()
               )}
            </Select>
         </Grid>
         <ModalButtonField>
            <ButtonCancel
               label={t?.page_chat_btn_filter_cancel}
               onClick={() => onClose()}
               disabled={isLoading}
               className={classes.modalButtons}
               style={{
                  color: '#212121',
                  backgroundColor: '#eeee',
                  '&:hover': {
                     backgroundColor: '#e0e0e0',
                  },
               }}
            />
            <ButtonOK
               className={classes.modalButtons}
               startIcon={isLoading ? <CircularProgress color='secondary' size={20} /> : null}
               disabled={
                  selectedGroup === -1 ||
                  (selectedAck === 2 && campaignData?.success_count === 0) ||
                  (selectedAck === 3 && campaignData?.failure_count === 0) ||
                  (selectedAck === 4 && campaignData?.platform_error_count === 0)
               }
               onClick={() => {
                  setLoading(true);
                  moveUsersToGroup({
                     variables: {
                        input: {
                           campaignId: campaignData.id,
                           groupId: selectedGroup,
                           ack: selectedAck,
                        },
                     },
                  })
                     .then((res) => {
                        onClose();
                        enqueueSnackbar(t['page_settings_typo_chat_settings_automatic_actions_success_message'], {
                           variant: 'success',
                        });
                        setLoading(false);
                     })
                     .catch((err) => {
                        enqueueSnackbar(err.message, {
                           // send error message
                           variant: 'error',
                        });
                        onClose();
                        setLoading(false);
                     });
               }}
               label={t['campaign_move_to_group']}
               style={{
                  color: 'white',
                  backgroundColor: '#157CFC',
                  '&:hover': {
                     backgroundColor: '#105CBB',
                  },
               }}
            >
               {t['campaign_move_to_group']}
            </ButtonOK>
         </ModalButtonField>
      </Modal>
   );
};

export default WithUrlTranslations(ReportModal);

import { Button, CircularProgress, Collapse, Grid, Hidden, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ClearAll } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, { useCallback, useContext, useRef, useState, useMemo, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NotificationIcon } from '@assets/icons/NotificationIcons/notificationIcon';
import { LanguageContext } from '@helper/locale/langContext';
import { PLATFORM_TYPE, USERS_TYPE } from '../../Chat/types';
import { QnAPlatformType } from '../../Questions/util/types';
import NotificationItem from './notificationItem';
import useNotifications from './useNotifications';

const useStyles = makeStyles((theme) => ({
   root: {
      width: 400,
      padding: 15,
      paddingTop: 8,
      paddingRight: 10,
      borderRadius: 15,
   },
   item: {
      display: 'inline-block',
      width: 7,
      height: 35,
      marginTop: -10,
      backgroundColor: 'white',
      borderTopRightRadius: 20,
      borderBottomRightRadius: 20,
      marginRight: '10px',
   },
   selectedItem: {
      backgroundColor: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   menuItem: {
      margin: 0,
      marginTop: 5,
      marginBottom: 5,
      padding: 0,
      width: 350,
   },
   textColor: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
   },
   title: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      fontSize: '1.1rem',
      marginBottom: 10,
   },
   titleContainer: {
      borderBottom: 'solid 1px #E8E8E8',
   },
   icon: {
      width: '30px',
      height: '30px',
      fill: '#97A4B1',
   },
   noNotificationContainer: {
      height: '170px',
      paddingLeft: 60,
   },
   makeAllButton: {
      fontSize: '0.8rem',
      padding: 5,
      textTransform: 'none',
      marginRight: 10,
      width: 180,
      color: 'grey',
      height: '40px',
      border: 'solid 1px #C8C8C8',
      '&:hover': {
         backgroundColor: '#E8F4FF',
         color: '#35526F',
      },
   },
   clearAllButton: {
      border: 'solid 1px #C8C8C8',
      textTransform: 'none',
      fontSize: '0.8rem',
      width: 180,
      color: '#6E6E6E',
      height: '40px',
   },
}));

const getPlaftormFromCode = (notification) => {
   let value, platform, platformCode;
   if (notification?.sender?.type === USERS_TYPE.WHATS_APP_WEB) {
      // whatsapp use number
      // FIRSTLY GET NAME OF SENDER, IF DOESN'T EXIST GET NUMBER OF SENDER
      if (notification?.sender?.name) {
         value = notification.sender.name;
      } else {
         value = notification?.sender?.phone;
      }
      platform = 'Whatsapp Web';
      platformCode = PLATFORM_TYPE.WHATS_APP_WEB;
   } else if (notification?.sender?.type === USERS_TYPE.WHATS_APP_BUSINESS) {
      // whatsapp business
      if (notification?.sender?.name) {
         value = notification.sender.name;
      } else {
         value = notification?.sender?.phone;
      }
      value = notification.sender.phone;
      platform = 'Whatsapp Business';
      platformCode = PLATFORM_TYPE.WHATS_APP_BUSINESS;
   } else if (notification?.sender?.type === USERS_TYPE.FACEBOOK) {
      // facebook
      value = notification?.sender?.name;
      platform = 'Facebook Messenger';
      platformCode = notification.type === 2 ? PLATFORM_TYPE.FACEBOOK : 9;
   } else if (notification?.sender?.type === USERS_TYPE.INSTAGRAM) {
      // instragram
      value = notification?.sender?.name;
      platform = 'Instagram Messenger';
      platformCode = PLATFORM_TYPE.INSTAGRAM;
   } else if (notification?.sender?.type === USERS_TYPE.N11) {
      value = notification?.sender?.name;
      platform = 'N11';
      platformCode = PLATFORM_TYPE.N11;
   } else if (notification?.sender?.type === USERS_TYPE.HEPSIBURADA) {
      // instragram
      value = notification?.sender?.name;
      platform = 'Hepsi Burada';
      platformCode = QnAPlatformType.HEPSIBURADA;
   } else if (notification?.sender?.type === USERS_TYPE.TRENDYOL) {
      // instragram
      value = notification?.sender?.name;
      platform = 'Trendyol';
      platformCode = QnAPlatformType.TRENDYOL;
   } else if (notification?.sender?.type === USERS_TYPE.LIVE_CHAT) {
      // livechat
      value = notification?.sender?.name;
      platform = 'LIVE_CHAT';
      platformCode = PLATFORM_TYPE.LIVE_CHAT;
   } else if (notification?.sender?.type === USERS_TYPE.TELEGRAM) {
      // telegram
      value = notification?.sender?.name;
      platform = 'TELEGRAM';
      platformCode = PLATFORM_TYPE.TELEGRAM;
   } else if (notification?.sender?.type === USERS_TYPE.EMAIL) {
      // telegram
      value = notification?.sender?.name;
      platform = 'EMAIL';
      platformCode = PLATFORM_TYPE.EMAIL;
   } else if (notification?.sender?.type === USERS_TYPE.ADMIN || USERS_TYPE.OWNER || USERS_TYPE.PERSONEL) {
      // Transferred Chat.
      value = notification?.sender?.name;
      platformCode = -1;
   } else {
      // Timeout Chat
      platformCode = -2;
   }
   return { value, platform, platformCode };
};

const getHumanReadableData = (notification, t) => {
   const data = {
      platform: '',
      message: '',
      title: '',
      platformCode: undefined,
   };

   const { platform, value, platformCode } = getPlaftormFromCode(notification);

   switch (notification.type) {
      case 2:
         // Chat Notifications
         switch (notification.bellType) {
            // new chat
            case 1:
               data.title = t?.notifications_chat_started;
               data.message = t?.notifications_sends_you_a_message.replace('{name}', value);
               data.platform = platform;
               data.platformCode = platformCode;
               break;
            case 2:
               // transfer chat
               data.title = t?.notifications_chat_transfered;
               data.platform = platform;
               data.platformCode = platformCode;
               data.message = t?.notifications_transfered_to_you.replace('{name}', notification?.sender?.name);
               break;
            case 3:
               // timeout and reopen chat
               if (notification.title === 'chat_timeout_approach') {
                  data.title = t?.notifications_chat_timeout;
                  data.platform = platform;
                  data.platformCode = platformCode;
                  data.message = t?.chat_timeout_left_time.replace('{name}', notification?.sender?.name);
               } else if (notification.title === 'chat_out_activated_title') {
                  data.title = t?.chat_out_activated_title;
                  data.platform = platform;
                  data.platformCode = platformCode;
                  data.message = t?.this_chat_reopen_time_out.replace('{name}', notification?.sender?.name);
               } else {
                  data.title = t?.chat_out_title;
                  data.platform = platform;
                  data.platformCode = platformCode;
                  data.message = t?.this_chat_has_timed_out.replace('{name}', notification?.sender?.name);
               }
               break;
            default:
               console.warn('Notification type not Found! -> ', notification.bellType);
         }
         break;
      case 4:
         // Qna Notifications
         switch (notification.bellType) {
            case 1:
               // new Chat
               switch (notification?.sender?.type) {
                  case USERS_TYPE.TRENDYOL:
                  case USERS_TYPE.HEPSIBURADA:
                  case USERS_TYPE.N11:
                     data.title = t?.qna_page_created_qna_notif_hepsi_trendyol_title;
                     data.message = value + t?.qna_page_created_qna_notif_hepsi_trendyol_message;
                     data.platform = platform;
                     data.platformCode = platformCode;
                     break;

                  case USERS_TYPE.FACEBOOK:
                  case USERS_TYPE.INSTAGRAM:
                     data.title = t?.qna_page_created_qna_notif_insta_face_title;
                     data.message = value + t?.qna_page_created_qna_notif_insta_face_message;
                     data.platform = platform;
                     data.platformCode = platformCode;
                     break;
                  default:
                     console.warn(`Invalid user type. Provided ${notification?.sender?.type}`);
                     break;
               }
               break;
            case 2:
               // Transfer chat
               data.title = t?.qna_page_transferred_qna_notif_title;
               data.message = notification?.sender?.name + t?.qna_page_transferred_qna_notif_message;
               data.platform = platform;
               data.platformCode = platformCode;
               break;
            case 3:
               // timeout and reopen chat.
               data.title = t[notification?.title];
               data.message = t[notification?.title?.slice(0, -5) + 'message']?.replaceAll(
                  '{name}',
                  notification?.sender?.name,
               );
               data.platform = platform;
               data.platformCode = platformCode;
               break;
            default:
               console.warn('Notification type not Found! -> ', notification.bellType);
         }
         break;
      default:
         console.warn('Notification category is not found! -> ', notification.category);
         break;
   }
   return data;
};

const Notifications = ({ onClickRequest }) => {
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const classes = useStyles();
   const {
      notificationsData,
      handleClickNotification,
      handleDeleteAllNotifications,
      handleMakeAllNotificationsSeen,
      handleNextMore,
      loadingStatus,
   } = useNotifications();
   const notificationDivContainerRef = useRef();
   const [showButtons, setShowButtons] = useState(false);

   const _notifications = useMemo(
      () =>
         notificationsData?.notifications?.map((notification) => {
            const data = getHumanReadableData(notification, t);
            return {
               ...data,
               ...notification,
               title: data.title,
            };
         }),
      [notificationsData?.notifications, t],
   );

   const handleClick = useCallback(
      (notification) => {
         handleClickNotification(notification);
         onClickRequest();
      },
      [handleClickNotification, onClickRequest],
   );

   const handleDelete = useCallback(() => {
      handleDeleteAllNotifications();
      setShowButtons(false);
   }, [handleDeleteAllNotifications]);

   return (
      <Grid container className={classes.root}>
         {loadingStatus ? (
            <Grid container style={{ height: 200 }} justifyContent='center' alignItems='center'>
               <CircularProgress color='primary' />
            </Grid>
         ) : (
            <Fragment>
               <Grid container justifyContent='space-between' className={classes.titleContainer} alignItems='center'>
                  <Typography align='left' className={classes.title}>
                     {t['comp_navbar_notification_title']}
                  </Typography>

                  {notificationsData?.notifications?.length > 0 && (
                     <IconButton style={{ marginTop: -10 }} onClick={() => setShowButtons(!showButtons)}>
                        <MoreVertIcon />
                     </IconButton>
                  )}
               </Grid>
               <Collapse in={showButtons}>
                  <Grid
                     container
                     justifyContent='space-between'
                     alignItems='center'
                     wrap='nowrap'
                     style={{ marginTop: 10 }}
                  >
                     <Grid>
                        <Button
                           variant='text'
                           color='secondary'
                           disableFocusRipple
                           endIcon={<ClearAll />}
                           onClick={handleMakeAllNotificationsSeen}
                           className={classes.makeAllButton}
                           disabled={notificationsData?.unReadCount === 0}
                           size='small'
                        >
                           {t['comp_navbar_notification_make_all_read']}
                        </Button>
                     </Grid>
                     <Hidden xsDown>
                        <Grid>
                           <Button
                              variant='text'
                              color='default'
                              disableFocusRipple
                              onClick={handleDelete}
                              endIcon={<DeleteIcon />}
                              className={classes.clearAllButton}
                              size='small'
                           >
                              {t['comp_navbar_notification_clear_all']}
                           </Button>
                        </Grid>
                     </Hidden>
                  </Grid>
               </Collapse>
               <div
                  ref={notificationDivContainerRef}
                  style={{ maxHeight: 500, overflowY: 'auto', marginTop: 10 }}
                  id='scrollableDiv'
               >
                  {_notifications?.length === 0 ? (
                     <Grid
                        container
                        justifyContent='center'
                        direction='row'
                        alignItems='center'
                        className={classes.noNotificationContainer}
                     >
                        <NotificationIcon className={classes.icon} />
                        <Typography variant='subtitle2' style={{ color: 'grey', marginLeft: 10 }}>
                           {t['comp_navbar_no_notification']}
                        </Typography>
                     </Grid>
                  ) : (
                     <InfiniteScroll
                        dataLength={notificationsData?.notifications?.length || 0}
                        next={handleNextMore}
                        height={500}
                        hasMore={notificationsData?.hasNextPage}
                        scrollableTarget='scrollableDiv'
                        loader={
                           <Grid container style={{ height: 80 }} justifyContent='center' alignItems='center'>
                              <CircularProgress size={20} color='primary' />
                           </Grid>
                        }
                     >
                        {_notifications?.map((notification, index) => (
                           <NotificationItem key={index} notification={notification} handleClickNotif={handleClick} />
                        ))}
                     </InfiniteScroll>
                  )}
               </div>
            </Fragment>
         )}
      </Grid>
   );
};

export default Notifications;

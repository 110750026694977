import React, { useContext } from 'react';
import { Divider, Link, Typography, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import useSeeMore from '../../../../hooks/useSeeMore';
import { LanguageContext } from '@helper/locale/langContext';
import { ChatNoteIcon } from '@assets/icons/ChatPageIcons';
import { trimLongText } from '@helper/textUtils';
import MyTypography from '@components/MyTypography';

const useStyles = makeStyles((theme) => ({
   root: {
      marginTop: 20,
      marginBottom: 20,
      minWidth: 200,
   },
   content: {
      minWidth: 300,
      backgroundColor: '#ffe59e',
      borderRadius: 15,
      padding: 10,
      maxWidth: 650,
      textAlign: 'left',
      wordBreak: 'break-word',
      [theme.breakpoints.down('sm')]: {
         maxWidth: '60%',
      },
   },
   footer: {
      fontSize: '0.8rem',
   },
   metaData: {
      fontSize: '0.8rem',
      marginTop: 10,
      color: '#a49987',
   },
   textContainer: {
      padding: 2,
   },
   title: {
      marginLeft: theme.spacing(2),
      fontSize: '1.1rem',
   },
   icon: {
      width: 20,
      height: 20,
      fill: '#a49987',
   },
}));

const QuestionNoteItem: React.FC<{
   sender: any;
   text?: string;
   time?: string;
   isSending?: boolean;
}> = ({ sender, text, time, isSending }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const length = 1000;
   //@ts-ignore
   const [showMore, handleSeeMore] = useSeeMore(text, length);

   return (
      <Grid container justifyContent='center' className={classes.root}>
         <Paper className={clsx(classes.content)} elevation={3}>
            <Tooltip
               title={
                  <MyTypography bold variant='caption'>
                     {sender?.name} | {time}
                  </MyTypography>
               }
            >
               <Grid container justifyContent='center' alignItems='center'>
                  <Grid item container alignItems='center' justifyContent='center' direction='row'>
                     <ChatNoteIcon className={classes.icon} />
                     <Typography className={classes.title}>{t?.special_note}</Typography>
                  </Grid>
                  <Divider style={{ width: '100%', marginTop: 5, marginBottom: 5 }} />
                  <Grid xs={12} container className={classes.textContainer}>
                     {showMore ? (
                        <Typography variant='body1'>
                           {trimLongText(text, length)}
                           <Link
                              variant='body2'
                              //@ts-ignore
                              onClick={handleSeeMore}
                              style={{ display: 'inline', wordBreak: 'break-word' }}
                           >
                              {t?.see_more}
                           </Link>
                        </Typography>
                     ) : (
                        <Typography variant='body1'>{text}</Typography>
                     )}
                  </Grid>
                  {isSending && (
                     <Grid item>
                        <Typography align={'right'} className={classes.metaData}>
                           {t?.chat_page_message_sending}
                        </Typography>
                     </Grid>
                  )}
               </Grid>
            </Tooltip>
         </Paper>
      </Grid>
   );
};

export default QuestionNoteItem;

import { gql } from '@apollo/client';

export const GET_TEMPLATE = gql`
   query getTemplate($id: ID!) {
      getTemplate(id: $id) {
         code
         data
         success
         message
      }
   }
`;

// queries
export const GET_PLATFORMS_QUERY = gql`
   query getPlatforms($type: String!, $customer: ID!) {
      getPlatforms(type: $type, customer: $customer) {
         data
         success
         code
         message
      }
   }
`;

export const GET_PLATFORMS_PAGINATED_QUERY = gql`
   query GetPlatformsPaginated($params: GetPlatformsPaginatedInput) {
      getPlatformsPaginated(params: $params) {
         code
         message
         success
         data {
            data
            hasNextPage
            totalRecords
         }
      }
   }
`;

export const RUN_CUSTOM_PLATFORM_QUERY = gql`
   query runCustomPlatformQuery($type: String!, $metaData: Object!) {
      runCustomPlatformQuery(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;

export const GET_PLATFORM_DATA_QUERY = gql`
   query getPlatformData($type: String!, $metaData: Object!) {
      getPlatformData(type: $type, metaData: $metaData) {
         data
         success
         code
         message
      }
   }
`;


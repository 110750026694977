import { gql } from '@apollo/client';

// subscriptions
export const UPDATE_FLOW_BOT_SUBSCRIPTION = gql`
   subscription UpdatedFlowBot($input: UpdateFlowBotSubscriptionInput!) {
      updatedFlowBot(input: $input) {
         success
         data {
            operationType
            customer
            flowBotData {
               _id
               customer
               title
               triggerFields {
                  keywords
                  rule
                  type
                  target
                  channelType
               }
               status
               createdAt
               updatedAt
            }
         }
         code
         message
      }
   }
`;

import { useMutation } from '@apollo/client';
import { IFlowBlockField, IFlowBlockFieldTypes, IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import {
   DELETE_FLOW_NODE_MUTATION,
   UPDATE_ASK_QUESTION_BLOCK_MUTATION,
   UPDATE_BLOCK_MUTATION,
} from '@queries/Automation/mutation';
import { useCallback, useEffect, useState } from 'react';
import {
   Box,
   boxToRect,
   getNodePositionWithOrigin,
   getNodesBounds,
   rectToBox,
   type Node,
   type NodeOrigin,
   type Rect,
   useReactFlow,
} from 'reactflow';

// we have to make sure that parent nodes are rendered before their children
export const sortNodes = (a: Node, b: Node): number => {
   if (a.type === b.type) {
      return 0;
   }
   return a.type === 'group' && b.type !== 'group' ? -1 : 1;
};

export const getId = (prefix = 'node') => `${prefix}_${Math.random() * 10000}`;

export const getNodePositionInsideParent = (node: Partial<Node>, groupNode: Node) => {
   const position = node.position ?? { x: 0, y: 0 };
   const nodeWidth = node.width ?? 0;
   const nodeHeight = node.height ?? 0;
   const groupWidth = groupNode.width ?? 0;
   const groupHeight = groupNode.height ?? 0;

   if (position.x < groupNode.position.x) {
      position.x = 0;
   } else if (position.x + nodeWidth > groupNode.position.x + groupWidth) {
      position.x = groupWidth - nodeWidth;
   } else {
      position.x = position.x - groupNode.position.x;
   }

   if (position.y < groupNode.position.y) {
      position.y = 0;
   } else if (position.y + nodeHeight > groupNode.position.y + groupHeight) {
      position.y = groupHeight - nodeHeight;
   } else {
      position.y = position.y - groupNode.position.y;
   }

   return position;
};

export const getBoundsOfBoxes = (box1: Box, box2: Box): Box => ({
   x: Math.min(box1.x, box2.x),
   y: Math.min(box1.y, box2.y),
   x2: Math.max(box1.x2, box2.x2),
   y2: Math.max(box1.y2, box2.y2),
});

export const getRelativeNodesBounds = (nodes: Node[], nodeOrigin: NodeOrigin = [0, 0]): Rect => {
   if (nodes.length === 0) {
      return { x: 0, y: 0, width: 0, height: 0 };
   }

   const box = nodes.reduce(
      (currBox, node) => {
         const { x, y } = getNodePositionWithOrigin(node, nodeOrigin);
         return getBoundsOfBoxes(
            currBox,
            rectToBox({
               x,
               y,
               width: node.width || 0,
               height: node.height || 0,
            }),
         );
      },
      { x: Infinity, y: Infinity, x2: -Infinity, y2: -Infinity },
   );

   return boxToRect(box);
};

// createGroupNodes is used to create groups when refreshing the page,
//it uses the group id coming from the backend
const padding = 25;
export const createGroupNodes = (nodes: Node[]): Node[] => {
   // Create groups
   const groupedNodes: { [groupId: string]: Node[] } = {};

   nodes.forEach((node: Node) => {
      const groupId = node.data.groupId;
      if (groupId !== null && groupId !== undefined) {
         if (!groupedNodes[groupId]) {
            groupedNodes[groupId] = [];
         }
         groupedNodes[groupId].push(node);
      }
   });
   if (Object.keys(groupedNodes).length === 0) {
      return nodes;
   }

   // Process each group of nodes
   let updatedNodesWithGroups: Node[] = [];
   Object.entries(groupedNodes).forEach(([groupId, childNodes]) => {
      const rectOfNodes = getNodesBounds(childNodes);
      const parentPosition = {
         x: rectOfNodes.x,
         y: rectOfNodes.y,
      };
      const groupNode = {
         id: groupId,
         type: 'group',
         position: {
            x: rectOfNodes.x - padding,
            y: rectOfNodes.y - padding,
         },
         data: {},
      };

      const selectedNodeIds = childNodes.map((nd) => nd.id);

      const nodess = updatedNodesWithGroups.length > 0 ? updatedNodesWithGroups : nodes;
      const nextNodes: Node[] = nodess.map((node: Node) => {
         if (selectedNodeIds.includes(node.id)) {
            return {
               ...node,
               position: {
                  x: node.position.x - parentPosition.x + padding,
                  y: node.position.y - parentPosition.y + padding,
               },
               extent: 'parent',
               parentNode: groupId,
            };
         }
         return node;
      });
      updatedNodesWithGroups = [groupNode, ...nextNodes];
   });

   return updatedNodesWithGroups;
};

// onCreateGroups is used to create a group when a group node is among the selected nodes
export const onCreateGroups = (nodes: Node[], selectedNodes: Node[]) => {
   const rectOfNodes = getNodesBounds(selectedNodes);
   const groupId = getId('group');
   const parentPosition = {
      x: rectOfNodes.x,
      y: rectOfNodes.y,
   };
   const groupNode = {
      id: groupId,
      type: 'group',
      position: {
         x: rectOfNodes.x - padding,
         y: rectOfNodes.y - padding,
      },
      data: {},
   };

   const selectedNodeIds = selectedNodes.map((sn) => sn.id);
   const nextNodes: Node[] = nodes.map((node) => {
      if (selectedNodeIds.includes(node.id)) {
         return {
            ...node,
            position: {
               x: node.position.x - parentPosition.x + padding,
               y: node.position.y - parentPosition.y + padding,
            },
            extent: 'parent',
            parentNode: groupId,
         };
      }

      return node;
   });

   return [groupNode, ...nextNodes];
   //selectedNodeIds.forEach((sn) => handleUpdateFlowNode(sn, groupId));
};

import React, { FC, useEffect, useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { LanguageContext } from '../../../../helper/locale/langContext';
import { MyTheme } from '../../../../styles/config';
import usePreviousState from '../../../../hooks/usePreviousState';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import BodyContent from './bodyContent';
import Modal from '../../../../components/Modal/modal';
import ButtonOK from '../../../../components/ButtonOK/buttonOk';
import SettingHeader from '../shared-components/settingHeader';
import SettingBody from '../shared-components/settingBody';
import NoConnection from '../../../Chat/RightPanel/ChatItem/CannotAccessModal';
import { SHOPIFY_APP_URL } from '@helper/config';
import { useSubscription, useQuery, useMutation } from '@apollo/client';
import {
   CREATED_E_COMMERCE_PLATFORM_SUBSCRIPTION,
   UPDATED_E_COMMERCE_PLATFORM_SUBSCRIPTION,
} from '@queries/Settings/Service/GraphQL/ECommercePlatform/subscription';
import { DELETE_ECOMMERCE_PLATFORM_MUTATION } from '@queries/Settings/Service/GraphQL/ECommercePlatform/mutation';
import {
   GET_ECOMMERCE_ALREADY_CONNECTED,
   GET_E_COMMERCE_PLATFORMS_QUERY,
} from '@queries/Settings/Service/GraphQL/ECommercePlatform/query';
import { E_COMMERCE_PLATFORM_TYPE } from '../../../../queries/Settings/Service/GraphQL/ECommercePlatform/types';
import { getPerson } from '@store/reducers/person';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import shopifyLogo from './shopify-logo.png';
import ButtonCancel from '@components/ButtonCancel/buttonCancel';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import { ModalLargeText, ModalTitle } from '@components/_Modal/ModalTexts';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const useStyles = makeStyles((theme: MyTheme) => ({
   container: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      height: '100%',
   },
   noConnectionContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      paddingLeft: 50,
      paddingRight: 50,
   },
   modalContainer: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      width: 736,
      borderRadius: 11,
   },
   modalHeader: {
      display: 'flex',
      height: 50,
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 15px',
      borderBottom: '1px solid #F1F3F7',
   },
   modalTitle: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1.1rem',
      fontWeight: 'bold',
   },
   modalBody: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: 25,
   },
   modalBodyIconContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   successIcon: {
      fontSize: 70,
      color: '#48B221',
      margin: 15,
   },
   modalDescription: {
      marginBottom: 55,
   },
   informationListItem: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      padding: '4px 0px',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7'
      }
   },
}));

interface ShopifyProps { }

const Shopify: FC<ShopifyProps> = (props) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t: any = lang?.translation;
   const [isConnected, setConnected] = useState<boolean>(false);
   const [isAlreadyConnected, setAlreadyConnected] = useState<boolean>(false);
   const prevIsConnected = usePreviousState(isConnected);
   const [_loading, setLoading] = useState(false);
   const [disableConnect, setDisableConnect] = useState(false);
   const [isModalVisible, setModalVisible] = useState<boolean>(false);
   const [isInformModalVisible, setIsInformModalVisible] = useState<boolean>(false);
   const token = useSelector((rootReducer: any) => rootReducer.personReducer.token);
   const person = useSelector((rootReducer: any) => rootReducer.personReducer.person);
   const connectToShopify = () => {
      // const win = window.open(`${API_URL}/ecommerce/shopify/login`, '_blank');
      const win = window.open(SHOPIFY_APP_URL, '_blank');
      if (win) {
         win.focus();
      }
      // handleCloseModal();
      // setConnected(true);
   };

   useEffect(() => {
      if (prevIsConnected === false && isConnected) {
         setModalVisible(true);
      }
   }, [prevIsConnected, isConnected]);

   const me = useSelector(getPerson);

   const refetchECommercePlatforms = () => {
      refetchECommercePlatformsQuery({ type: 'SHOPIFY', customer: me.customer._id });
   };
   const {
      data: data,
      refetch: refetchECommercePlatformsQuery,
      loading,
   } = useQuery(GET_E_COMMERCE_PLATFORMS_QUERY, {
      variables: { type: 'SHOPIFY', customer: me.customer._id },
      fetchPolicy: 'cache-and-network',
   });

   useQuery(GET_ECOMMERCE_ALREADY_CONNECTED, {
      fetchPolicy: 'network-only',
      variables: {
         token: token,
         customer: person ? person.customer._id : '',
         type: 'SHOPIFY',
      },
      onCompleted: (data) => {
         setDisableConnect(data.getAlreadyConnectedStore.data.isAlreadyConnected);
      },
   });

   useEffect(() => {
      if (data?.getECommercePlatforms?.data?.length > 0) {
         setAlreadyConnected(true);
      } else {
         setConnected(false);
         setAlreadyConnected(false);
      }
   }, [data]);

   const [deleteECommercePlatform, { loading: deleteAddOnLoading }] = useMutation(DELETE_ECOMMERCE_PLATFORM_MUTATION, {
      // refetchQueries: [
      //    {
      //       query: GET_E_COMMERCE_PLATFORMS_QUERY,
      //       variables: {
      //          customer: person?.customer?._id,
      //       },
      //    },
      // ],
      onCompleted: () => {
         refetchECommercePlatforms();
      },
   });
   const { enqueueSnackbar } = useSnackbar();

   const errorHandler = (data: any) => {
      if (data.error) {
         setConnected(false);
         setModalVisible(false);
         enqueueSnackbar(data?.message, {
            variant: 'error',
            autoHideDuration: 3000,
         });
      }
   };

   useSubscription(CREATED_E_COMMERCE_PLATFORM_SUBSCRIPTION, {
      variables: { token: token, customer: person ? person.customer._id : '', type: E_COMMERCE_PLATFORM_TYPE.SHOPIFY },
      onSubscriptionData: async ({ client, subscriptionData: { data } }) => {
         console.log(data);
         setConnected(true);
         refetchECommercePlatforms();
      },
   });

   useSubscription(UPDATED_E_COMMERCE_PLATFORM_SUBSCRIPTION, {
      variables: { token: token, customer: person ? person.customer._id : '', type: E_COMMERCE_PLATFORM_TYPE.SHOPIFY },
      onSubscriptionData: async ({ client, subscriptionData: { data } }) => {
         refetchECommercePlatforms();
         errorHandler(data?.updatedECommercePlatform?.data);
      },
   });

   if (loading || _loading) {
      return <div className='loading' />;
   }

   return (
      <div className={classes.container}>
         <SettingHeader leftButton={
            <Link to='/settings/channels-and-integrations' style={{ marginTop: 5, marginRight: 5 }}>
               <ArrowBackIcon color='disabled' />
            </Link>
         } style={{ justifyContent: 'flex-start' }} title={t?.page_settings_typo_e_commerce_shopify} />
         <SettingBody>
            {isConnected || isAlreadyConnected ? (
               <BodyContent
                  setLoading={setLoading}
                  deleteECommercePlatform={deleteECommercePlatform}
                  data={data.getECommercePlatforms.data}
               />
            ) : (
               <div className={classes.noConnectionContainer}>
                  <NoConnection
                     title={t['page_settings_typo_e_commerce_shopify_no_connection_title']}
                     description={t['page_settings_typo_e_commerce_shopify_no_connection_description']}
                     button={
                        <>
                           <ButtonOK
                              disabled={disableConnect}
                              label={t['page_settings_typo_e_commerce_shopify_no_connection_button']}
                              onClick={() => setIsInformModalVisible(true)}
                           />
                           {disableConnect && (
                              <div style={{ marginTop: 15 }}>
                                 <Typography color='secondary'>{t.you_can_only_have_one_ecommerce_store}</Typography>
                              </div>
                           )}
                        </>
                     }
                  />
               </div>
            )}
         </SettingBody>
         <Modal open={isInformModalVisible} onClose={() => setIsInformModalVisible(false)} modalHeader={t['page_settings_typo_e_commerce_shopify_instruction_title']}>
            <Grid container direction='column' alignItems='center' className={classes.modalBodyIconContainer}>
               <img src={shopifyLogo} alt='wooCommerce-icon-qpien' style={{ width: 180 }} />
               <ModalTitle
                  color='textSecondary'
                  style={{ fontWeight: 'bold', fontSize: 20, marginTop: 20 }}
                  className={classes.informationListItem}
               >
                  Shopify
               </ModalTitle>
               <ModalLargeText>
                  1 - {t['page_settings_typo_e_commerce_shopify_instruction_1']} <br />
                  2 - {t['page_settings_typo_e_commerce_shopify_instruction_2']} <br />
                  3 - {t['page_settings_typo_e_commerce_shopify_instruction_3']}
               </ModalLargeText>
            </Grid>
            <ModalButtonField>
               <ButtonCancel
                  className={classes.modalButtons}
                  style={{
                     color: '#212121',
                     backgroundColor: '#eeee',
                  }}
                  label={t['comp_button_cancel']}
                  onClick={() => setIsInformModalVisible(false)}
               />
               <ButtonOK
                  className={classes.modalButtons}
                  label={t['page_settings_typo_e_commerce_shopify_no_connection_button']}
                  onClick={connectToShopify}
                  style={{
                     color: 'white',
                     backgroundColor: '#157CFC',
                     float: 'right'
                  }}
               />
            </ModalButtonField>
         </Modal>

         <Modal paperStyle={{ width: 450 }} open={isModalVisible} onClose={() => setModalVisible(false)} modalHeader={t['connected']}>
            <div className={classes.modalBodyIconContainer}>
               <CheckCircleRoundedIcon className={classes.successIcon} />
               <Typography className={classes.modalDescription}>{t['connected_successfully']}</Typography>
            </div>
            <ModalButtonField style={{ justifyContent: 'end' }}>
               <ButtonOK
                  label={t['pre_load_forget_pass_sent_OK']}
                  onClick={() => setModalVisible(false)}
                  className={classes.modalButtons}
                  style={{
                     marginLeft: 'auto',
                     color: 'white',
                     backgroundColor: '#157CFC',
                  }}
               />
            </ModalButtonField>
         </Modal>
      </div>
   );
};

export default Shopify;

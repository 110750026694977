import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';
import ButtonOK from '@components/ButtonOK/buttonOk';
import { LanguageContext } from '@helper/locale/langContext';
import EditIcon from '@material-ui/icons/EditOutlined';
import CreateModal from '@modules/Automation/FlowBots/components/createModal';
import Modal from '@components/Modal/modal';
import { FlowBotDataItem } from '@modules/Automation/FlowBots/types';
import { useParams } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import { UPDATE_FLOW_BOT_MUTATION } from '@queries/Automation/mutation';
import { SET_BOT, SET_BOT_STATUS } from '@store/actions/automation';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import TestFlow from '../ContentPanel/Nodes/TestFlow';
import { Edge, Node } from 'reactflow';

const useStyles = makeStyles((theme: any) => ({
   container: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
   },
   title: {
      display: 'flex',
      gap: '5px',
      padding: '25px',
      fontSize: '1rem',
   },
   btn: {
      padding: '10px 16px',
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '10px',
      width: '100%',
      marginTop: '5px',
   },
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 120,
      height: 36,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
}));

interface NavBarProps {
   selectedItem: FlowBotDataItem | undefined;
   flowBotData: FlowBotDataItem[];
   isDraft: boolean;
   setIsDraft: React.Dispatch<React.SetStateAction<boolean>>;
   contentNodes: Node[];
}

const NavBar: React.FC<NavBarProps> = ({ selectedItem, flowBotData, isDraft, setIsDraft, contentNodes }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const dispatch = useDispatch();

   const [bot, setBot] = useState<string | undefined>();
   const [showModal, setShowModal] = useState(false);
   const [openTestModal, setOpenTestModal] = useState(false);
   const botTitle = useSelector((reducer: RootStateOrAny) => reducer.automationReducer.bot);
   const { enqueueSnackbar } = useSnackbar();
   const [loading, setLoading] = useState(false);
   const [anchorElField, setAnchorElField] = useState<null | HTMLElement>(null);

   const handleModalOpen = (event: React.MouseEvent<HTMLElement>) => {
      setOpenTestModal(true);
      setAnchorElField(event.currentTarget);
   };

   let { id } = useParams();
   const flowBotId = id;
   const history = useHistory();

   useEffect(() => {
      setBot(botTitle);
   }, [botTitle]);

   const value = { title: bot };

   const [updateFlowBot] = useMutation(UPDATE_FLOW_BOT_MUTATION);
   const handleRenameSubmit = async (newTitle: String) => {
      setLoading(true);
      try {
         if (flowBotId) {
            await updateFlowBot({
               variables: {
                  input: {
                     _id: flowBotId,
                     title: newTitle,
                  },
               },
            });
            dispatch({ type: SET_BOT, payload: newTitle });
            setShowModal(false);
            setLoading(false);
         }
      } catch (error) {
         console.error('Error renaming flow:', error);
      }
   };

   const handleToggleStatus = async (status: Boolean) => {
      try {
         await updateFlowBot({
            variables: {
               input: {
                  _id: flowBotId,
                  status: status,
               },
            },
            onCompleted: () => {
               dispatch({ type: SET_BOT_STATUS, payload: '' });
               history.push('/automation/flow-bots');
            },
         });
      } catch (error) {
         console.error('Error updating status:', error);
         enqueueSnackbar(t.atomation_flow_notification, {
            variant: 'error',
            autoHideDuration: 2000,
         });
      }
   };
   const handleToggle = async (status: Boolean) => {
      try {
         await updateFlowBot({
            variables: {
               input: {
                  _id: flowBotId,
                  status: status,
               },
            },
            onCompleted: () => {
               setIsDraft(true);
               dispatch({ type: SET_BOT_STATUS, payload: 'draft' });
            },
         });
      } catch (error) {
         console.error('Error updating status:', error);
         enqueueSnackbar(t.atomation_flow_notification, {
            variant: 'error',
            autoHideDuration: 2000,
         });
      }
   };

   const handleShowModal = () => {
      setShowModal(true);
   };
   const closeModal = () => {
      setShowModal(false);
   };

   return (
      <Grid>
         <Grid className={classes.container}>
            <div className={classes.title}>
               <div>{bot}</div>
               <EditIcon style={{ fontSize: '20px', cursor: 'pointer' }} onClick={handleShowModal} />
            </div>
            <div>
               {isDraft ? (
                  <div className={classes.btn}>
                     <Button
                        className={classes.modalButtons}
                        style={{
                           color: '#212121',
                           backgroundColor: '#eeee',
                        }}
                        onClick={handleModalOpen}
                     >
                        {t.campaign_test_button}
                     </Button>
                     <Button
                        className={classes.modalButtons}
                        style={{
                           color: '#212121',
                           backgroundColor: '#eeee',
                        }}
                        onClick={() => handleToggleStatus(false)}
                     >
                        {t.atomation_flow_draft}
                     </Button>
                     <ButtonOK
                        className={classes.modalButtons}
                        label={t.atomation_flow_publish}
                        type='submit'
                        style={{
                           color: 'white',
                           backgroundColor: '#157CFC',
                           float: 'right',
                        }}
                        onClick={() => handleToggleStatus(true)}
                     />
                  </div>
               ) : (
                  <div className={classes.btn}>
                     <ButtonOK
                        className={classes.modalButtons}
                        label={t.page_settings_button_edit}
                        type='submit'
                        style={{
                           color: 'white',
                           backgroundColor: '#157CFC',
                           float: 'right',
                        }}
                        onClick={() => handleToggle(false)}
                     />
                  </div>
               )}
            </div>
         </Grid>
         <Modal open={showModal} onClose={closeModal} modalHeader={t.automation_flow_update_flow}>
            <CreateModal value={value} onSubmit={handleRenameSubmit} closeModal={closeModal} isLoading={loading} />
         </Modal>

         <TestFlow
            open={openTestModal}
            setOpen={setOpenTestModal}
            anchorElField={anchorElField}
            contentNodes={contentNodes}
         />
      </Grid>
   );
};

export default NavBar;

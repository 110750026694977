import { useQuery } from '@apollo/client';
import { LanguageContext } from '@helper/locale/langContext';
import { GET_PERSON_ACTIVITY_SINGLE } from '@queries/PersonActivity/query';
import { getPerson } from '@store/reducers/person';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowCircleLeftIcon from '@material-ui/icons/ArrowBackRounded';
import { CircularProgress, Grid, Link, Typography } from '@material-ui/core';
import moment from 'moment'
import ChangeCircleIcon from '@material-ui/icons/ReplayRounded';
import ArrowCircleRightIcon from '@material-ui/icons/ArrowDropDownCircleRounded';
import { API_URL } from '@helper/config';
import { io } from 'socket.io-client';
import { PLATFORM_TYPE } from '@modules/Chat/types';
import { FirstVisitIcon, LeftIcon, ReturnedIcon, VisitIcon } from '@modules/Settings/SettingSpace/LiveChat/SharedComponents/Icons';
interface ActivityProps { }

const useStyles = makeStyles(() => ({
   activityItem: {
      borderBottom: '1px solid #CCC', display: 'flex', flexDirection: 'column', paddingTop: 10, paddingBottom: 10,
      paddingLeft: '5px',
      paddingRight: '3px',
      justifyContent: 'space-between'
   },
   activityContainer: {
      paddingLeft: 10, paddingRight: 10
   },
   dateContainer: {
      background: "#e9edf1",
      width: '100px',
      height: '26px',
      marginTop: 10,
      marginBottom: 10,
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
   }
   , center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
   },
   loaderContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex'
   }
}));

const Activity: FC<ActivityProps> = () => {
   const classes = useStyles();
   const activeChat = useSelector((reducer: any) => reducer?.chatReducer?.activeChat);


   const { lang } = useContext(LanguageContext);
   const me = useSelector((reducer: any) => reducer?.personReducer.person);
   const t = lang?.translation;
   const participant = activeChat?.participants.find((item: any) => item._id !== me._id);

   const { data: _personActivity, loading } = useQuery(GET_PERSON_ACTIVITY_SINGLE, {
      variables: { customer: me.customer._id, person: participant._id },
      fetchPolicy: 'cache-and-network',
   });


   useEffect(() => {
      setPersonActivity(_personActivity?.getPersonActivity?.data || null)
   }, [_personActivity])

   const [personActivity, setPersonActivity] = useState<any>(null);
   const [socket, setSocket] = useState(null);
   const person = useSelector((reducer: any) => reducer.personReducer.person);
   const token = useSelector((reducer: any) => reducer.personReducer.token);

   // useEffect(() => {

   //    if (activeChat.type !== PLATFORM_TYPE.LIVE_CHAT)
   //       return

   //    let query: any = { query: `path=customer&token=${token}&customer=${person.customer._id}` };
   //    const newSocket: any = io(API_URL, query);
   //    setSocket(newSocket);
   //    newSocket.on(participant._id.toString(), (personActivity: any) => {
   //       setPersonActivity(personActivity)
   //    });

   // }, [setSocket]);



   if (activeChat.type !== PLATFORM_TYPE.LIVE_CHAT)
      return (<div className={classes.loaderContainer}>
         <Typography>{t?.only_avaible_for_livechat}</Typography>
      </div>)


   if (loading) {
      return (<div className={classes.loaderContainer}>
         <CircularProgress />
      </div>)
   }



   if (!personActivity) {
      return (<div className={classes.loaderContainer}>
         <Typography>{t?.personActivity_not_exist}</Typography>
      </div>)
   }
   return (
      <div>
         {
            // (!personActivity ||loading)


         }
         {personActivity && personActivity?.activities && <div className={classes.activityContainer}>
            {personActivity?.activities.map((item: any) =>
               <>
                  {item.activity.map((item2: any) =>
                     <div className={classes.activityItem}>
                        <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                           <Grid style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              {item2.status == 3 && <ReturnedIcon />}
                              {item2.status == 0 && <FirstVisitIcon />}
                              {item2.status == 1 && <VisitIcon />}
                              {item2.status == 2 && <LeftIcon />}
                              <Typography style={{
                                 color: 'rgb(49 85 129)', marginLeft: 5, maxHeight: ' 46px',
                                 fontSize: 15, fontWeight: 500
                              }}>

                                 {/* @ts-ignore */}
                                 {t[`personActivity_status_text_${item2.status}`]}:
                              </Typography>
                           </Grid>
                           <Typography style={{ color: '#aebac8', fontWeight: 'bold', display: 'flex', alignItems: 'center', fontSize: 13 }}>{moment(item2.date).format('HH:mm')}</Typography>
                        </Grid>

                        <Link
                           target={"_blank"}
                           title={item2.url}
                           href={item2.url} style={{ marginLeft: 5, maxHeight: 40, overflow: 'hidden', }}>{item2.url}</Link>
                     </div>
                  )}

                  <div className={classes.center}><div className={classes.dateContainer}><Typography style={{ color: '#647c97', fontSize: '14px' }}>{item.date}</Typography></div></div>

               </>
            )}

         </div>}
      </div >
   )
};

export default Activity;

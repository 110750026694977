import { gql } from '@apollo/client';
import { CHAT_CORE_FIELDS } from '../../../../fragments';
import { QNA_CHAT_CORE_FIELDS_FRAGMENT } from '@queries/Questions/Service/fragments';

// subscriptions
export const MY_NOTIFICATION_SUBSCRIPTION = gql`
   ${CHAT_CORE_FIELDS}
   ${QNA_CHAT_CORE_FIELDS_FRAGMENT}
   subscription myNotificationSubscription($token: String!, $person: ID!) {
      personNotification(data: { token: $token, person: $person }) {
         data {
            _id
            type
            readingStatus
            bellType
            hasSeen
            sender {
               name
               surname
               _id
               type
               phone
               photo # only photo name and extension - create path: domain/photos/<photo>
            }
            title
            messages
            createdAt
            chatId {
               ...ChatCoreFields
            }
            QnAChatId {
               ...QnAChatFields
            }
         }
         success
         code
         message
      }
   }
`;

import React from 'react';
import { CircularProgress, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
   closeIconContainer: {
      width: 30,
      height: 30,
      position: 'relative',
      left: 10,
      top: -5,
   },
   closeIcon: {
      width: 25,
      height: 25,
   },
}));
const MiscFile = ({ name, showPreview, isLoading, onClose, disabled, preview, url }) => {
   const classes = useStyles();
   return (
      <Grid
         container
         direction='row'
         justifyContent='center'
         alignItems='flex-start'
         wrap='nowrap'
         style={{ backgroundColor: disabled ? 'lightgrey' : 'transparent' }}
      >
         <Grid
            container
            justifyContent='space-around'
            alignItems='flex-start'
            direction='column'
            style={{ padding: 10, paddingBottom: 5 }}
         >
            <Grid container direction='row'>
               {showPreview && (
                  <IconButton onClick={() => window.open(url)}>
                     <InsertDriveFileIcon style={{ fontSize: '2.5rem', color: 'grey' }} />
                  </IconButton>
               )}
               {!preview && (
                  <IconButton className={classes.closeIconContainer} onClick={onClose}>
                     <CloseIcon className={classes.closeIcon} />
                  </IconButton>
               )}
            </Grid>

            <Typography style={{ fontSize: '1rem', color: 'grey', marginTop: 5 }}> {name} </Typography>
            {isLoading && <CircularProgress />}
         </Grid>
      </Grid>
   );
};

export default MiscFile;

import React, { useContext, useCallback, useState, useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import 'react-phone-input-2/lib/style.css';
import { LanguageContext } from '@helper/locale/langContext';
import { useForm } from 'react-hook-form';
import ModalButtonField from '@components/_Modal/ModalButtonField';
import SingleInput from '@components/_Modal/SingleInput';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Node } from 'reactflow';
import { FlowNodeType, IFlowBlockTemplate, QuickRepyTypes } from '../types';
import DeleteIcon from '@mui/icons-material/Delete';

interface ModalContentProps {
   onSubmit: (newName: string) => void;
   handleSelectBackPath?: (nodeId: string | null) => void;
   value?: { title: string | null };
   closeModal: () => void;
   deleteAll?: () => void;
   deleteReply?: () => void;
   isLoading?: Boolean;
   isCreate?: Boolean;
   isHasQickReply?: Boolean;
   isWelcome?: Boolean;
   leftNodes?: Node[];
   selectedQuickReply?: QuickRepyTypes;
   selectedBlockId?: { _id: string | null; QRindex: number | null } | undefined;
   blockState?: IFlowBlockTemplate[];
   clickedNodeId?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
   modalButtons: {
      fontSize: '18px',
      textTransform: 'none',
      paddingBlock: 5,
      paddingInline: 15,
      minWidth: 140,
      height: 44,
      borderRadius: 6,
      transition: 'all .5s',
      '&:disabled': {
         opacity: '0.7',
      },
   },
   container: {
      width: '100%',
   },
}));

const QuickReplyModal: React.FC<ModalContentProps> = ({
   value,
   deleteReply,
   onSubmit,
   closeModal,
   isCreate,
   isLoading,
   deleteAll,
   isHasQickReply,
   isWelcome,
   leftNodes,
   handleSelectBackPath,
   selectedQuickReply,
   clickedNodeId,
}) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const Loader = useCallback(() => {
      return (
         <Grid container alignItems='center' justifyContent='center' xs={12}>
            <CircularProgress size={20} color='inherit' />
         </Grid>
      );
   }, []);

   const {
      handleSubmit,
      control,
      formState: { isValid },
   } = useForm({
      mode: 'all',
      defaultValues: {
         name: value && value.title ? value.title : '',
      },
   });

   const handleFormSubmit = (data: { name: string }) => {
      closeModal();
      onSubmit(data.name);
   };
   const handleSelectPath = (pathId: string | null) => {
      if (handleSelectBackPath) {
         handleSelectBackPath(pathId);
      }
      setShowBack(false);
   };

   const [showBack, setShowBack] = useState(false);

   return (
      <div className={classes.container}>
         <form onSubmit={handleSubmit(handleFormSubmit)}>
            <Grid container>
               <SingleInput
                  label={t['automation_flow_quick_reply_title']}
                  name='name'
                  placeholder={t['automation_flow_quick_reply_title_enter']}
                  control={control}
                  rules={{
                     required: t['automation_flow_quick_reply_title_enter_rule'],
                     maxLength: { value: 20, message: t?.automation_flow_flow_bot_title_max_length20 },
                  }}
               />
               {!isCreate && !isWelcome && (
                  <Grid
                     style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        margin: '5px 0',
                        overflow: 'hidden',
                     }}
                  >
                     <Grid>
                        <Grid style={{ width: '100%' }}>
                           {selectedQuickReply?.hideTheEdge === true ? (
                              <div
                                 style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    width: '100%',
                                 }}
                              >
                                 <div style={{ cursor: 'pointer', color: '#157CFC', display: 'flex' }}>
                                    <div onClick={() => setShowBack((prev) => !prev)}>
                                       {leftNodes?.find((node) => node.id === selectedQuickReply.target)?.data.title}
                                    </div>
                                    <div onClick={() => setShowBack((prev) => !prev)}>
                                       {showBack ? (
                                          <KeyboardArrowDownIcon
                                             style={{ color: '#157CFC', cursor: 'pointer' }}
                                             onClick={() => setShowBack(true)}
                                          />
                                       ) : (
                                          <ChevronRightIcon
                                             style={{ color: '#157CFC', cursor: 'pointer' }}
                                             onClick={() => setShowBack(false)}
                                          />
                                       )}
                                    </div>
                                 </div>
                                 <DeleteIcon
                                    style={{ color: 'gray', cursor: 'pointer' }}
                                    onClick={() => handleSelectPath(null)}
                                 />
                              </div>
                           ) : (
                              <div
                                 style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    gap: '10px',
                                    width: '100%',
                                 }}
                              >
                                 <div style={{ cursor: 'pointer' }}>
                                    <div onClick={() => setShowBack((prev) => !prev)}>
                                       {t.automation_flow_back_to_existing_path}
                                    </div>
                                 </div>
                                 {showBack ? (
                                    <KeyboardArrowDownIcon
                                       style={{ color: 'gray', cursor: 'pointer' }}
                                       onClick={() => setShowBack(false)}
                                    />
                                 ) : (
                                    <ChevronRightIcon
                                       style={{ color: 'gray', cursor: 'pointer' }}
                                       onClick={() => setShowBack(true)}
                                    />
                                 )}
                              </div>
                           )}
                        </Grid>
                     </Grid>
                     {showBack && (
                        <Grid style={{ marginLeft: '-5px', maxHeight: '150px', overflow: 'auto' }}>
                           {leftNodes
                              ?.filter((lnode) => lnode.id !== clickedNodeId)
                              ?.map((node) => (
                                 <div>
                                    {!(
                                       node.type === FlowNodeType.WelcomeMessage || node.type === FlowNodeType.Triggers
                                    ) && (
                                       <div style={{ display: 'flex', gap: '5px' }}>
                                          <ArrowRightIcon />
                                          <Grid style={{ cursor: 'pointer' }} onClick={() => handleSelectPath(node.id)}>
                                             {node.data?.title}
                                          </Grid>
                                       </div>
                                    )}
                                 </div>
                              ))}
                        </Grid>
                     )}
                  </Grid>
               )}

               <ModalButtonField>
                  {!isCreate ? (
                     <Button
                        disabled={!isHasQickReply}
                        className={classes.modalButtons}
                        onClick={deleteReply}
                        style={{
                           color: 'white',
                           backgroundColor: 'red',
                           opacity: !isHasQickReply ? 0.4 : '',
                        }}
                     >
                        {t.page_chat_typo_reject}
                     </Button>
                  ) : (
                     <Button
                        className={classes.modalButtons}
                        onClick={isWelcome ? closeModal : deleteAll}
                        style={{
                           color: '#000000',
                           backgroundColor: '#E8E8E8',
                        }}
                     >
                        {isWelcome ? t.cancel : t.delete_all}
                     </Button>
                  )}

                  <Button
                     className={classes.modalButtons}
                     type='submit'
                     disabled={!isValid}
                     style={{
                        color: 'white',
                        backgroundColor: '#157CFC',
                        float: 'right',
                     }}
                  >
                     <div style={{ display: 'flex', gap: '10px', justifyContent: 'space-between' }}>
                        {isLoading && <Loader />}
                        <span style={{ whiteSpace: 'nowrap' }}>{t.page_chat_btn_navbar_save}</span>
                     </div>
                  </Button>
               </ModalButtonField>
            </Grid>
         </form>
      </div>
   );
};

export default QuickReplyModal;

import { gql } from '@apollo/client';

export const CUSTOMER_FRAGMENT = gql`
   fragment CustomerFields on Customer {
      autoArchiveTime
      participantOutChatTime
      freeTrialExpiringDate
      hasFreeTrial
      isANewCustomer
      isWholeSaler
      appStatus
      accountStatus
      accountConfirmation
      appStatus
      _id
      autoCloseByCustomerPerson {
         status
         time
      }
      autoCloseByPerson {
         status
         time
      }
      autoConversationAssignment
      autoConversationAssignmentTime
      autoConversationAssignmentTimeUnit
      phone
      companyName
      authorizedPersonName
      authorizedPersonSurname
      authorizedPersonIdentityNumber
      email
      country
      state
      postCode
      city
      invoiceCompanyName
      invoiceCountry
      invoiceState
      invoicePostCode
      invoiceCity
      invoiceAddress
      taxBuilding
      taxNumber
      address
      purposeOfUsage
      website
      category
      workingHours
      photo
      createdAt
      # product
      companyIndustry
      companySize
      favouriteProduct
      howYouHearAboutUs
      purposeOfUsage
      yourRole
      customerFromChannel
      package {
         paymentFromOtherChannel
         activeRenewalPeriodMode
         activeRenewalName
         totalWhatsappBusinessCount
         totalPersonCount
         accountBalance
         _id
         addOns {
            _id
            name
            renewalType
            price
            quantity
         }
         name
         channels
         personCount
         clientCount
         messageCount
         whatsappWebCount
         whatsappBusinessCount
         facebookPageCount
         teamsCount
         twitterCount
         instagramCount
         telegramCount
         surveyCount
         emailCount
         teamChat
         support
         price
         type
         paymentStatus
         status
         periodMode
         endDate
         createdAt
      }
      # personCount
      usagePersonCount
      # clientCount
      usageClientCount
      # messageCount
      usageMessageCount
      # whatsappWebCount
      usageWhatsappWebCount
      # teamsCount
      usageTeamsCount
      # whatsappBusinessCount
      usageWhatsappBusinessCount
      # facebookPageCount
      usageFacebookPageCount
      # twitterCount
      usageTwitterCount
      # instagramCount
      usageInstagramCount
      usageTelegramCount
      usageSurveyCount
      usageEmailCount
      paymentCards
      activeChatsCount
      closedChatsCount
      sentMessagesCount
      spammedChatsCount
      totalClientsCount
      # notification
      newChatEmail
      newChatSystem
      newChatBell
      newChatBellName
      transferredChatEmail
      transferredChatSystem
      transferredChatBell
      transferredChatBellName
      timeoutChatEmail
      timeoutChatSystem
      timeoutChatBell
      timeoutChatBellName
      newQnAChatEmail
      newQnAChatSystem
      newQnAChatBell
      newQnAChatBellName
      transferredQnAChatEmail
      transferredQnAChatSystem
      transferredQnAChatBell
      transferredQnAChatBellName
      timeoutQnAChatEmail
      timeoutQnAChatSystem
      timeoutQnAChatBell
      timeoutQnAChatBellName
      timeZone
      language
      currency
      __typename
      teamGoalTime
      teamFirstResponseGoalTime
   }
`;

export const COUPON_CODE_CORE_FIELDS = gql`
   fragment CoreCouponCodeFields on CouponCode {
      _id
      name
      code
      endDate
      percent
      createdAt
      status
      couponType
      __typename
   }
`;

export const CHAT_CORE_FIELDS = gql`
   fragment ChatCoreFields on Chat {
      _id
      color
      activeFlowInstance
      participants {
         _id
         name
         surname
      }
      type
      tag {
         _id
         color
         name
      }
      status
      __typename
   }
`;

// @ts-nocheck

import { useLazyQuery, useMutation } from '@apollo/client';
import noImage from '@assets/images/no-image.png';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Input from '@components/InputField';
import { LangCodes } from '@helper/language';
import { LanguageContext } from '@helper/locale/langContext';
import { Box, Button, Grid, InputLabel, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleRounded, ArrowBack } from '@material-ui/icons';
import { UPDATE_PLATFORM_MUTATION } from '@queries/Settings/Service/GraphQL/Platform/mutation';
import { PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/Platform/types';
import LinkifyIt from 'linkify-it';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import { getBusinessVerticals } from './Utils';
import SearchSelect from 'react-select';
import { LOCATIONS } from '@queries/Locations/query';
import { useSelector } from 'react-redux';

const linkify = new LinkifyIt();

interface EditPageProps {
   item: any;
   goBack: () => void;
   teamsData: any;

}

const useStyles = makeStyles((theme) => ({
   container: {
      padding: theme.spacing(2),
      paddingInline: '20%',
      '& .MuiInputBase-root.Mui-disabled': {
         backgroundColor: '#fafafa',
         color: 'rgba(0, 0, 0, 0.87)',
         borderRadius: 8
      },
      '& .makeStyles-inputLabel-216': {
         marginTop: -49,
      },
      '& .css-14jk2my-container': {
         backgroundColor: '#fafafa',
      },
      '& .MuiFormControl-root': {
         paddingBottom: 5
      },
      '& .css-1uccc91-singleValue': {
         color: 'rgba(0, 0, 0, 0.87)',
      },

      '& .css-107lb6w-singleValue': {
         color: 'rgba(0, 0, 0, 0.87)',

      },
   },
   formGroups: {
      marginBottom: 20,
   },
   sectionTitle: {
      marginBottom: 8,
      fontSize: '1.2rem',
      fontWeight: 'bold'
   },
   sectionDescription: {
      fontSize: '1rem',
   },
   inputRow: {
   },
   imageBox: {
      width: 100,
      height: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      paddingBottom: 5,
      border: '1px solid #ccc',
      borderRadius: '50%',
      marginBottom: 16,
   },
   imgURL: {
      width: '65px',
      height: '65px',
   },
   addImageIcon: {
      position: 'absolute',
      color: '#157CFC',
      backgroundColor: '#FFFFFF',
      bottom: '0px',
      right: '5px',
      height: '35px',
      width: '35px',
      borderRadius: '35px',
   },
   inputFix: {
      paddingLeft: 15,
      '& .MuiInputBase-root': {
         height: 38,
         marginTop: '-8px',
         borderRadius: '4px !important',
      },
   },
}));

const EditPage = ({ item, goBack, teamsData }: EditPageProps) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const person = useSelector((rootReducer) => rootReducer.personReducer.person);
   const customer = person.customer;
   let lang_code = customer?.language;
   const businessVerticals = getBusinessVerticals(t);
   const { handleSubmit, control, setValue, watch, formState: { isValid, errors } } = useForm({
      mode: 'all',
      defaultValues: {
         name: item.name,
         website1: item.website1,
         website2: item.website2,
         addressLine: item.addressLine,
         about: item.about,
         email: item.email,
         phone: item.phone,
         image: item.image,
         businessEntity: item.businessEntity,
         businessDescription: item.businessDescription,
         fbContactEmail: item.fbContactEmail,
         fbManagerId: item.fbManagerId,
         faceUrl: item.faceUrl,
         language: {
            value: item.language,
            label: LangCodes
               .find((x) => x.value === item.language)?.label
         },
         zipCode: item.zipCode,
         country: {
            value: 0,
            label: '',
         },
         city: {
            value: 0,
            label: '',
         },
         state: {
            value: 0,
            label: '',
         },
         businessVertical: businessVerticals.filter((vertical) => vertical.value === item.businessVertical)[0],
         teams: teamsData ?? teamsData.filter((t) => item?.teams?.includes(t._id))
            ?.map((team) => ({
               value: team._id,
               label: team.teamName,
            })),
      },
   });

   const [getCountry, { data: countryData, loading: countryLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'country',
         id: 0,
      },
   });
   const [getState, { data: stateData, loading: stateLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'state',
         id: watch('country.value'),
      },
   });
   const [getCity, { data: cityData, loading: cityLoading }] = useLazyQuery(LOCATIONS, {
      fetchPolicy: 'cache-first',
      variables: {
         type: 'city',
         id: watch('state.value'),
      },
   });
   useEffect(() => {
      if (countryData) {
         if (countryData.locations.data.length === 0) {
            setValue('country', {
               value: 0,
               label: '',
            });
         } else {
            let country = countryData.locations.data.find(
               (country: any) =>
                  country.name === item.country ||
                  Object.values(country.translations).includes(item.country)
            );

            if (country) {
               setValue('country', {
                  value: country.id,
                  label: country.name,
               });
            } else {
               setValue('country', {
                  value: countryData.locations.data[lang_code === 'TR' ? 227 : 235].id,
                  label: countryData.locations.data[lang_code === 'TR' ? 227 : 235].name,
               });
            }
         }
      }
      getCountry();
   }, [countryData]);

   useEffect(() => {
      if (stateData) {
         if (stateData.locations.data.length === 0) {
            setValue('state', {
               value: 0,
               label: '',
            });
         } else {
            let state = stateData.locations.data.find(
               (state: any) => state.name === item.state
            );
            if (state) {
               setValue('state', {
                  value: state.id,
                  label: state.name,
               });
            } else {
               setValue('state', {
                  value:
                     watch('country.value') === 225
                        ? stateData.locations.data[39].id
                        : stateData.locations.data[0].id,
                  label:
                     watch('country.value') === 225
                        ? stateData.locations.data[39].name
                        : stateData.locations.data[0].name,
               });
            }
         }
      }
      getState();
   }, [stateData]);

   useEffect(() => {
      if (cityData) {
         if (cityData.locations.data.length === 0) {
            setValue('city', {
               value: 0,
               label: '',
            });
         } else {
            let city = cityData.locations.data.find(
               (city: any) => city.name === item.city
            );

            if (city) {
               setValue('city', {
                  value: city.id,
                  label: city.name,
               });
            } else {
               setValue('city', {
                  value: cityData.locations.data[0].id,
                  label: cityData.locations.data[0].name,
               });
            }
         }
      }
      getCity();
   }, [cityData]);

   //!

   const [image, setImage] = useState(item.imageUrl);
   const [canEdit, setCanEdit] = useState(false);
   const logoInputRef = useRef(null);

   const statusTranslate = (t, status) => {
      if (status === 'WAITING_FOR_APPROVAL') {
         return t.settings_whatsapp_b_waiting_approval;
      }
   };

   const [updatePlatformMutation, { loading: updateWhatsAppBusinessPhoneLoading }] =
      useMutation(UPDATE_PLATFORM_MUTATION);

   const handleImageChange = (e) => {
      if (e.target.files[0]) {
         let reader = new FileReader();
         let file = e.target.files[0];
         reader.onloadend = () => {
            setImage(reader.result);
         };
         reader.readAsDataURL(file);
      }
   };

   const handleUpdatePlatform = async (data) => {
      updatePlatformMutation({
         variables: {
            type: PLATFORM_TYPE.WHATS_APP_BUSINESS,
            metaData: {
               _id: item._id,
               customer: item.customer._id,
               ...data,
               language: data.language.value,
               businessVertical: data.businessVertical.value,
               country: data.country.label,
               state: data.state.label,
               city: data.city.label,
               image: image,
            },
         },
      }).then((_) => {
         setCanEdit(false)
      });
   };

   const handleImageUpload = () => logoInputRef.current.click();

   if (updateWhatsAppBusinessPhoneLoading) {
      return <div className='loading' />;
   }

   return (
      <div>
         <Grid
            container
            style={{
               paddingInline: '16px',
               paddingTop: '16px',
               marginBottom: 40
            }}
            justifyContent='space-between'
            xs={12}
         >
            <Grid container xs={6} alignItems='center' >
               <Grid item>
                  <Button onClick={goBack}>
                     <ArrowBack />
                  </Button>

               </Grid>
               <Grid item>
                  <Typography style={{
                     fontSize: '1.1rem',
                     fontWeight: 'bold',
                  }}> {t.settings_whatsapp_b_edit_account_settings}</Typography>
               </Grid>
            </Grid>
            {canEdit ? (
               <Grid style={{ float: 'right' }}>
                  <Button
                     variant='outlined'
                     onClick={() => {
                        setCanEdit(false);
                     }}
                     style={{
                        textTransform: 'none',
                        border: '1px solid #B9D0E5',
                        fontSize: '1.1rem',
                        width: 128,
                        color: '#748FA7',
                        height: 40,
                        marginRight: '16px',
                     }}
                  >
                     {t?.comp_button_cancel}
                  </Button>
                  <ButtonOK
                     onClick={handleSubmit(handleUpdatePlatform)}
                     label={t.comp_button_save}
                     disabled={!isValid || updateWhatsAppBusinessPhoneLoading}
                  />
               </Grid>
            ) : (
               item.status !== 'Waiting for approval' && (
                  <ButtonOK
                     onClick={() => {
                        setCanEdit(!canEdit);
                     }}
                     label={t.comp_button_edit}
                  />
               )
            )}
         </Grid>
         <form onSubmit={handleSubmit(handleUpdatePlatform)}>
            <Grid container className={classes.container}>
               <Grid className={classes.formGroups}>
                  <Typography className={classes.sectionTitle}>{t.settings_whatsapp_b_edit_waba_details_title}</Typography>
                  <Typography className={classes.sectionDescription}>
                     {t.settings_whatsapp_b_edit_waba_details_description}
                  </Typography>
                  <Grid
                     style={{
                        marginTop: 25,
                     }}
                     container
                     justifyContent='space-between'
                     alignItems='center'
                     xs={12}
                  >
                     <Grid
                        style={{
                           paddingRight: 15,
                        }}
                        item
                        xs={6}
                     >
                        <Input
                           control={control}
                           name='name'
                           required={false}
                           label={t.settings_whatsapp_b_edit_input_display_name}
                           placeholder={t?.settings_whatsapp_b_edit_input_display_name}
                           disabled
                        />
                     </Grid>
                     <Grid
                        style={{
                           paddingLeft: 15,
                        }}
                        item
                        xs={6}
                     >
                        <Input
                           control={control}
                           name='phone'
                           required={false}
                           label={t.settings_whatsapp_b_edit_input_phone_number}
                           placeholder={t?.settings_whatsapp_b_edit_input_phone_number}
                           disabled
                        />
                     </Grid>
                  </Grid>
                  <Grid container justifyContent='space-between' alignItems='center' xs={12}>
                     <Grid
                        style={{
                           paddingRight: 15,
                        }}
                        item
                        xs={6}
                     >
                        <Input
                           control={control}
                           name='businessEntity'
                           required={false}
                           label={t.settings_whatsapp_b_edit_input_business_entity}
                           placeholder={t?.settings_whatsapp_b_edit_input_business_entity}
                           disabled
                        />
                     </Grid>
                     <Grid
                        style={{
                           paddingLeft: 15,
                        }}
                        item
                        xs={6}
                     >
                        <Input
                           control={control}
                           name='faceUrl'
                           required={false}
                           label={t.settings_whatsapp_b_edit_input_fb_page_url}
                           placeholder={t?.settings_whatsapp_b_edit_input_fb_page_url}
                           rules={{
                              required: t?.please_fill_this_field,
                              validate: (value) => linkify.test(value) || t?.url_valid,
                           }}
                           disabled
                        />
                     </Grid>
                     <Grid
                        style={{
                           paddingRight: 15,
                        }}
                        item
                        xs={6}
                     >
                        <Input
                           control={control}
                           name='fbManagerId'
                           required={false}
                           label={t.settings_whatsapp_b_edit_input_fb_business_manager_id}
                           placeholder={t?.settings_whatsapp_b_edit_input_fb_business_manager_id}
                           disabled
                        />
                     </Grid>
                     <Grid
                        style={{
                           paddingLeft: 15,
                        }}
                        item
                        xs={6}
                     >
                        <Input
                           control={control}
                           name='fbContactEmail'
                           required={false}
                           label={t.settings_whatsapp_b_edit_input_fb_business_manager_contact_email}
                           placeholder={t?.settings_whatsapp_b_edit_input_fb_business_manager_contact_email}
                           disabled
                        />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid
                  className={classes.formGroups}
               >
                  <Grid
                     justifyContent='space-between'
                     direction='row'
                     style={{
                        width: '100%',
                     }}
                  >
                     <Grid item className={classes.inputRow}>
                        <Box className={classes.imageBox}>
                           <img src={image || noImage} alt='logo url' className={classes.imgURL} />
                           <input hidden ref={logoInputRef} type='file' accept='image/*' onChange={handleImageChange} />
                           <AddCircleRounded style={{ cursor: canEdit ? 'pointer' : 'not-allowed' }} className={classes.addImageIcon} onClick={() => canEdit ? handleImageUpload() : null} />
                        </Box>
                        <Typography
                           style={{
                              marginTop: 5,
                              width: '60%',
                           }}
                           className={classes.sectionDescription}
                        >
                           {t.settings_whatsapp_b_edit_input_profile_picture_description}
                        </Typography>
                     </Grid>

                     <Typography>{statusTranslate(t, item.status)}</Typography>
                  </Grid>
               </Grid>
               <Grid container className={classes.formGroups}>
                  <Input
                     control={control}
                     name='businessDescription'
                     required={false}
                     label={t.settings_whatsapp_b_edit_input_business_description}
                     placeholder={t?.settings_whatsapp_b_edit_input_business_description}
                     multiline
                     disabled={!canEdit}
                  />
                  <Input
                     control={control}
                     name='email'
                     required={false}
                     label={t.settings_whatsapp_b_edit_input_email_adress}
                     rules={{
                        pattern: {
                           value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                           message: t['email_valid'],
                        },
                        required: t?.please_fill_this_field,
                     }}
                     placeholder={t?.settings_whatsapp_b_edit_input_email_adress}
                     disabled={!canEdit}
                  />
                  <Input
                     control={control}
                     name='website1'
                     required={false}
                     label={t.settings_whatsapp_b_edit_input_website_1}
                     placeholder={t?.settings_whatsapp_b_edit_input_website_1}
                     rules={{
                        required: t?.please_fill_this_field,
                        validate: (value) => linkify.test(value) || t?.url_valid,
                     }}
                     disabled={!canEdit}
                  />
                  <Input
                     control={control}
                     name='website2'
                     required={false}
                     label={t.settings_whatsapp_b_edit_input_website_2}
                     placeholder={t?.settings_whatsapp_b_edit_input_website_2}
                     rules={{
                        required: t?.please_fill_this_field,
                        validate: (value) => linkify.test(value) || t?.url_valid,
                     }}
                     disabled={!canEdit}
                  />
                  <Input
                     control={control}
                     name='about'
                     required={false}
                     label={t.settings_whatsapp_b_edit_input_about}
                     placeholder={t?.settings_whatsapp_b_edit_input_about}
                     multiline
                     disabled={!canEdit}
                  />
               </Grid>
               <Grid>
                  <Grid>
                     <Typography className={classes.sectionTitle}>
                        {t.settings_whatsapp_b_edit_business_adress_title}
                     </Typography>
                     <Typography className={classes.sectionDescription}>
                        {t.settings_whatsapp_b_edit_business_adress_description}
                     </Typography>
                  </Grid>
                  <Grid
                     style={{
                        marginTop: 25,
                     }}
                     container justifyContent='space-between' alignItems='center' xs={12}>
                     <Grid
                        item
                        xs={12}
                     >
                        <Input
                           control={control}
                           name='addressLine'
                           required={false}
                           label={t.settings_whatsapp_b_edit_input_address_line}
                           rules={{
                              required: t?.please_fill_this_field,
                           }}
                           placeholder={t?.settings_whatsapp_b_edit_input_address_line}
                           disabled={!canEdit}
                        />
                     </Grid>

                  </Grid>
                  <Grid container justifyContent='space-between' alignItems='center' xs={12}>
                     <Grid
                        style={{
                           paddingRight: 15,
                        }}
                        item
                        xs={6}
                     >
                        {/* Country Shipping Input */}
                        <InputLabel
                           shrink={false}
                           style={{ marginBottom: 8, color: errors?.country && 'red' }}
                        >
                           {t?.page_e_commerce_customer_country}
                        </InputLabel>
                        <Controller
                           name='country'
                           control={control}
                           rules={{
                              required: true,
                              validate: (value: any) => {
                                 if (value.value) {
                                    return true;
                                 }
                                 return false;
                              },
                           }}
                           render={() => (
                              <SearchSelect
                                 isDisabled={!canEdit || countryLoading}
                                 options={countryData?.locations?.data?.map(({ name, id }: any) => ({
                                    value: id,
                                    label: name,
                                 }))}
                                 value={watch('country')}
                                 onChange={(value: any) => {
                                    setValue('country', value);
                                 }}
                              />
                           )}
                        />
                     </Grid>
                     <Grid
                        style={{
                           paddingLeft: 15,
                        }}
                        item
                        xs={6}
                     >
                        <InputLabel shrink={false} style={{ marginBottom: 8, color: errors?.state && 'red' }}>
                           {t?.city}
                        </InputLabel>
                        <Controller
                           name='state'
                           control={control}
                           rules={{
                              required: true,
                              validate: (value: any) => {
                                 if (value.value) {
                                    return true;
                                 }
                                 return false;
                              },
                           }}
                           render={() => (
                              <SearchSelect
                                 isDisabled={!canEdit || cityLoading || stateData?.locations?.data?.length === 0}
                                 options={stateData?.locations?.data?.map(({ name, id }: any) => ({
                                    value: id,
                                    label: name,
                                 }))}
                                 value={watch('state')}
                                 onChange={(value: any) => {
                                    setValue('state', value);
                                 }}
                              />
                           )}
                        />
                     </Grid>
                  </Grid>
                  <Grid container justifyContent='space-between' alignItems='center' xs={12}>
                     <Grid
                        style={{
                           paddingRight: 15,
                        }}
                        item
                        xs={6}
                     >
                        <InputLabel shrink={false} style={{ marginBottom: 8, color: errors?.city && 'red' }}>
                           {t?.state}
                        </InputLabel>
                        <Controller
                           name='city'
                           control={control}
                           rules={{
                              required: true,
                              validate: (value: any) => {
                                 if (value.value || cityData.locations.data.length === 0) {
                                    return true;
                                 }
                                 return false;
                              },
                           }}
                           render={() => (
                              <SearchSelect
                                 isDisabled={!canEdit || cityLoading || cityData?.locations?.data?.length === 0}
                                 options={cityData?.locations?.data?.map(({ name, id }: any) => ({
                                    value: id,
                                    label: name,
                                 }))}
                                 value={watch('city')}
                                 onChange={(value: any) => {
                                    setValue('city', value);
                                 }}
                              />
                           )}
                        />
                     </Grid>
                     <Grid
                        className={classes.inputFix}
                        item
                        xs={6}
                     >
                        <InputLabel shrink={false} style={{
                           marginTop: 23
                        }}>
                           {t?.settings_whatsapp_b_edit_input_zip_code}
                        </InputLabel>
                        <Input
                           control={control}
                           name='zipCode'
                           required={false}
                           rules={{
                              required: t?.please_fill_this_field,
                           }}
                           placeholder={t?.settings_whatsapp_b_edit_input_zip_code}
                           disabled={!canEdit}
                        />
                     </Grid>
                  </Grid>
                  <Grid container justifyContent='space-between' alignItems='center' xs={12}>
                     <Grid
                        style={{
                           paddingRight: 15,
                        }}
                        item
                        xs={6}
                     >
                        <Input
                           control={control}
                           name='businessVertical'
                           required={false}
                           label={t.settings_whatsapp_b_edit_input_business_industry}
                           isSelect
                           selectOptions={businessVerticals}
                           rules={{
                              required: t?.please_fill_this_field,
                           }}
                           placeholder={t?.settings_whatsapp_b_edit_input_business_industry}
                           disabled={!canEdit}
                        />
                     </Grid>
                     <Grid
                        style={{
                           paddingLeft: 15,
                        }}
                        item
                        xs={6}
                     >
                        <Input
                           control={control}
                           name='language'
                           isSelect
                           required={false}
                           selectOptions={LangCodes}
                           label={t.settings_whatsapp_b_business_details_input_language}
                           rules={{
                              required: t?.please_fill_this_field,
                           }}
                           placeholder={t?.settings_whatsapp_b_business_details_input_language}
                           disabled={!canEdit}
                        />
                     </Grid>
                  </Grid>
               </Grid>
               <Grid
                  style={{
                     marginTop: 25,
                     width: '100%',
                  }}
               ></Grid>
            </Grid>
         </form>
      </div >
   );
};

export default EditPage;

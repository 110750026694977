import moment from 'moment';
import numeral from 'numeral';
import { USERS_TYPE } from '../modules/Chat/types';
import { QnAPlatformType } from '../modules/Questions/util/types';
const sound0 = new Audio(require('../assets/beeps/sound0.mp3'));
const sound1 = new Audio(require('../assets/beeps/sound1.mp3'));
const sound2 = new Audio(require('../assets/beeps/sound2.mp3'));
const sound3 = new Audio(require('../assets/beeps/sound3.mp3'));
const sound4 = new Audio(require('../assets/beeps/sound4.mp3'));
const sound5 = new Audio(require('../assets/beeps/sound5.mp3'));
const sound6 = new Audio(require('../assets/beeps/sound6.mp3'));

// Chat Avatar Colors
export const COLORS = [
   '#98D3FF',
   '#2A9D8F',
   '#FE7F2D',
   '#A1C181',
   '#FF5C5C',
   '#A676D7',
   '#238AB3',
   '#FCCA46',
   '#4CC9F0',
   '#FF3F00',
];

export const playSound = (file = '') => {
   if (file === 'sound1.mp3') {
      sound1.play();
   } else if (file === 'sound2.mp3') {
      sound2.play();
   } else if (file === 'sound3.mp3') {
      sound3.play();
   } else if (file === 'sound4.mp3') {
      sound4.play();
   } else if (file === 'sound5.mp3') {
      sound5.play();
   } else if (file === 'sound6.mp3') {
      sound6.play();
   } else {
      sound0.play();
   }
   return null;
};

export const calculateTimeDifference = (startDate, endDate) => {
   const differenceDays = moment(endDate).diff(startDate, 'days');
   if (differenceDays < 1) {
      return 'HOUR';
   } else if (differenceDays < 31) {
      return 'DAY';
   } else {
      return 'MONTH';
   }
};

export const getSocialMediaFromType = (type) => {
   switch (type) {
      case USERS_TYPE.FACEBOOK:
         return 'FaceBook';
      case USERS_TYPE.WHATS_APP_BUSINESS:
         return 'WhatsApp Business';
      case USERS_TYPE.WHATS_APP_WEB:
         return 'WhatsApp Web';
      case USERS_TYPE.INSTAGRAM:
         return 'Instagram';
      case USERS_TYPE.LIVE_CHAT:
         return 'Live Chat';
      case USERS_TYPE.TELEGRAM:
         return 'Telegram';
      case QnAPlatformType.HEPSIBURADA:
      case USERS_TYPE.HEPSIBURADA:
         return 'Hepsiburada';
      case USERS_TYPE.MANUAL_CREATION:
         return 'Manual';
      case QnAPlatformType.N11:
         return 'N11';
      case QnAPlatformType.TRENDYOL:
      case USERS_TYPE.TRENDYOL:
         return 'Trendyol';
      case USERS_TYPE.EMAIL:
         return 'Email';
      default:
         return 'Unknown Media!';
   }
};

export const fixedNumber = (number, digit = 2) => {
   if (typeof number !== 'number') {
      throw new Error(`Parameter number is must be number but you provided: ${typeof number}`);
   }
   return number.toFixed(digit);
};

export const priceFormat = (price = 0) => {
   return numeral(price).format('$0,0.00');
};
export const priceFormatWithoutCurrency = (price = 0) => {
   return numeral(price).format('0,0.00');
};

export const calculatePercentage = (totalValue, percentage) => {
   return (totalValue * percentage) / 100;
};

export const NumberFormatter = Intl.NumberFormat('en', { notation: 'compact' });

// get Random Color with Chat ID
export const getRandomChatColor = (id) => {
   const lastNumberOfId = id[id?.length - 1];
   switch (lastNumberOfId) {
      case '1':
      case 'a':
         return COLORS[0];
      case '2':
      case 'b':
         return COLORS[1];
      case '3':
      case 'c':
         return COLORS[2];
      case '4':
      case 'd':
         return COLORS[3];
      case '5':
      case 'e':
         return COLORS[4];
      case '6':
      case 'f':
         return COLORS[5];
      case '7':
      case 'g':
         return COLORS[6];
      case '8':
      case 'h':
         return COLORS[7];
      case '9':
      case 'k':
         return COLORS[8];
      default:
         return COLORS[9];
   }
};

export function durationAsString(start, end) {
   const duration = moment.duration(moment(end).diff(moment(start)));

   //Get Days
   const days = Math.floor(duration.asDays());
   const daysFormatted = days ? `${days}d ` : '';

   //Get Hours
   const hours = duration.hours();
   const hoursFormatted = `${hours}h `;

   //Get Minutes
   const minutes = duration.minutes();
   const minutesFormatted = `${minutes}m`;

   return [daysFormatted, hoursFormatted, minutesFormatted].join('');
}

export const getMessageTypeFromMessage = (message) => {
   const medias = message.media;
   if (medias.length === 0) {
      return 'TEXT';
   } else if (medias.every((media) => media.type.includes('image'))) {
      return 'IMAGE';
   } else if (medias.every((media) => media.type.includes('video'))) {
      return 'VIDEO';
   } else {
      return 'FILE';
   }
};

export function capitalizeWithUpperCase(s) {
   return s && s[0].toUpperCase() + s.slice(1).toLowerCase();
}

export function splitNumber(value) {
   const country_codes = [
      '+91',
      '+1',
      '+44',
      '+93',
      '+355',
      '+213',
      '+376',
      '+244',
      '+54',
      '+374',
      '+297',
      '+61',
      '+43',
      '+994',
      '+973',
      '+880',
      '+375',
      '+32',
      '+501',
      '+229',
      '+975',
      '+591',
      '+387',
      '+267',
      '+55',
      '+246',
      '+673',
      '+359',
      '+226',
      '+257',
      '+855',
      '+237',
      '+238',
      '+599',
      '+236',
      '+235',
      '+56',
      '+86',
      '+57',
      '+269',
      '+243',
      '+242',
      '+682',
      '+506',
      '+225',
      '+385',
      '+53',
      '+357',
      '+420',
      '+45',
      '+253',
      '+593',
      '+20',
      '+503',
      '+240',
      '+291',
      '+372',
      '+251',
      '+500',
      '+298',
      '+679',
      '+358',
      '+33',
      '+594',
      '+689',
      '+241',
      '+220',
      '+995',
      '+49',
      '+233',
      '+350',
      '+30',
      '+299',
      '+590',
      '+502',
      '+224',
      '+245',
      '+592',
      '+509',
      '+504',
      '+852',
      '+36',
      '+354',
      '+62',
      '+98',
      '+964',
      '+353',
      '+972',
      '+39',
      '+81',
      '+962',
      '+7',
      '+254',
      '+686',
      '+383',
      '+965',
      '+996',
      '+856',
      '+371',
      '+961',
      '+266',
      '+231',
      '+218',
      '+423',
      '+370',
      '+352',
      '+853',
      '+389',
      '+261',
      '+265',
      '+60',
      '+960',
      '+223',
      '+356',
      '+692',
      '+596',
      '+222',
      '+230',
      '+262',
      '+52',
      '+691',
      '+373',
      '+377',
      '+976',
      '+382',
      '+212',
      '+258',
      '+95',
      '+264',
      '+674',
      '+977',
      '+31',
      '+687',
      '+64',
      '+505',
      '+227',
      '+234',
      '+683',
      '+672',
      '+850',
      '+47',
      '+968',
      '+92',
      '+680',
      '+970',
      '+507',
      '+675',
      '+595',
      '+51',
      '+63',
      '+48',
      '+351',
      '+974',
      '+40',
      '+250',
      '+290',
      '+508',
      '+685',
      '+378',
      '+239',
      '+966',
      '+221',
      '+381',
      '+248',
      '+232',
      '+65',
      '+421',
      '+386',
      '+677',
      '+252',
      '+27',
      '+82',
      '+211',
      '+34',
      '+94',
      '+249',
      '+597',
      '+268',
      '+46',
      '+41',
      '+963',
      '+886',
      '+992',
      '+255',
      '+66',
      '+670',
      '+228',
      '+690',
      '+676',
      '+216',
      '+90',
      '+993',
      '+688',
      '+256',
      '+380',
      '+971',
      '+598',
      '+998',
      '+678',
      '+58',
      '+84',
      '+681',
      '+967',
      '+260',
      '+263',
      '+358',
   ];

   for (let i = 0; i < country_codes.length; i++) {
      if (value?.includes(country_codes[i])) {
         let code_size = country_codes[i].length;
         return { code: value.substring(0, code_size), number: value.substring(code_size) };
      }
   }
}

// get Avatar From Name
// export const getAvatarFromName = (text) => {
//    if (text) {
//       const splitText = text.split(" ");
//       console.log(splitText);
//       if (splitText.length > 0) {
//          const nameFirstLetter = splitText[0];
//          const surnameFirstLetter = splitText[1][0];
//          const avatar = nameFirstLetter + surnameFirstLetter;
//          return avatar;
//       }
//    } else return 'QP';
// };

export const formatDate = (format, inputDateStr) => {
   const inputDate = new Date(inputDateStr);

   const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour24: true,
   };
   return inputDate.toLocaleString(format, options);
};

export const generateRandomId = () => {
   const timestamp = new Date().getTime();
   const random = Math.random().toString(36).substring(2);
   return `${timestamp}-${random}`;
};

// Get the content message from the contentHtml

const pattern =
   /^(.*?)(?=<div class="gmail_quote">|> On|<hr id="line-qpien">|<hr id="line-blockquote">|<blockquote type="cite">|<div dir="ltr"><br>Begin forwarded message:|<div id="appendonsend">|<div id="mail-editor-reference-message-container">|<div name="messageReplySection">|<p>---|<div name="messageSignatureSection">|<blockquote>|<div id="yahoo_quoted_\w+" class="yahoo_quoted">|<div class="signature_started">|<div>-{2,})/s;
export function extractFirstPart(text) {
   let result = pattern.exec(text);
   return result ? result[1].trim() : '';
}

//Get only the text from the extracted part
export function extractTextFromHTMLBlockCode(htmlBlockCode) {
   const parser = new DOMParser();
   const doc = parser.parseFromString(htmlBlockCode, 'text/html');
   function extractTextFromElement(element, newLine = false) {
      let text = '';
      if (element.nodeType === Node.TEXT_NODE) {
         text += newLine ? '\n' + element.textContent : element.textContent;
      } else if (element.nodeType === Node.ELEMENT_NODE) {
         if (element.tagName === 'BR') {
            text += '\n';
         } else {
            for (const childNode of element.childNodes) {
               text += extractTextFromElement(childNode, element.tagName === 'P' || element.tagName === 'DIV');
            }
         }
      }

      return text;
   }

   return extractTextFromElement(doc.body).trim();
}

export const isValidHttpUrl = (str) => {
   const pattern = new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]/gm, 'i');
   return pattern.test(str);
};

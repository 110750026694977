import { gql } from '@apollo/client';

// mutations
export const CHECK_DOMAIN_STATUS_MUTATION = gql`
   mutation checkDomainStatus($_id: String!, $customer: String!) {
      checkDomainStatus(input: { _id: $_id, customer: $customer }) {
         data {
            _id
            customer
            domain
            valid
            dns {
               mail_cname {
                  valid
                  type
                  host
                  data
               }
               dkim1 {
                  valid
                  type
                  host
                  data
               }
               dkim2 {
                  valid
                  type
                  host
                  data
               }
            }
         }
         success
         code
         message
      }
   }
`;

export const REMOVE_DOMAIN_FROM_EMAIL_MUTATION = gql`
   mutation removeDomainFromEmail($_id: String!, $customer: String!) {
      removeDomainFromEmail(input: { _id: $_id, customer: $customer }) {
         data {
            _id
            customer
            domain
            valid
            dns {
               mail_cname {
                  valid
                  type
                  host
                  data
               }
               dkim1 {
                  valid
                  type
                  host
                  data
               }
               dkim2 {
                  valid
                  type
                  host
                  data
               }
            }
         }
         success
         code
         message
      }
   }
`;

export const UPDATE_DOMAIN_MUTATION = gql`
   mutation updateDomain($_id: String!, $customer: String!, $senderEmailAddress: String!) {
      updateDomain(
         input: { _id: $_id, customer: $customer, senderEmailAddress: $senderEmailAddress }
      ) {
         data {
            _id
            customer
            domain
            valid
            dns {
               mail_cname {
                  valid
                  type
                  host
                  data
               }
               dkim1 {
                  valid
                  type
                  host
                  data
               }
               dkim2 {
                  valid
                  type
                  host
                  data
               }
            }
         }
         success
         code
         message
      }
   }
`;

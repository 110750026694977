import React, { useState, useEffect, useContext } from 'react';
import TestFlowModal from './TestFlowModal';
import { Node } from 'reactflow';
import { FlowBlockType, FlowNodeType, IFlowBlockTemplate } from '@modules/Automation/FlowBots/types';
import { generateRandomId } from '@helper/functions';
import { LanguageContext } from '@helper/locale/langContext';

interface TestFlowProps {
   open: boolean;
   anchorElField: HTMLElement | null;
   setOpen: React.Dispatch<React.SetStateAction<boolean>>;
   contentNodes: Node[];
}

const TestFlow: React.FC<TestFlowProps> = ({ open, setOpen, anchorElField, contentNodes }) => {
   const [paths, setPaths] = useState<Node[]>([]);
   const [selectedPath, setSelectedPath] = useState<{ currentNodeId: string; targetId: string }[]>([]);
   const [replyQuestion, setReplyQuestion] = useState<{ text: string; questionId: string }[]>([]);

   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const explorePaths = (nodesList: Node[], currentNode: Node, path: Node[], visitedNodes: Set<string>) => {
      if (visitedNodes.has(currentNode.id)) {
         const node = {
            id: generateRandomId(),
            type: 'loop',
            data: { title: t.automation_flow_test_loop },
            position: { x: 0, y: 0 },
         };
         path.push(node);
         explorePaths(nodesList, node, path, visitedNodes);
         return;
      }

      visitedNodes.add(currentNode.id);

      if (
         currentNode.type === FlowNodeType.WelcomeMessage ||
         currentNode.type === FlowNodeType.Conditions ||
         currentNode.type === FlowNodeType.SendButtonMessage ||
         currentNode.type === FlowNodeType.HttpRequest
      ) {
         const followNext = currentNode.data?.flowBlocks.find(
            (block: IFlowBlockTemplate) =>
               block.type === FlowBlockType.SendInformativeButtonMessage ||
               block.type === FlowBlockType.SendQuickReplyMessage,
         );
         const isUrl = currentNode.data?.flowBlocks.some(
            (block: IFlowBlockTemplate) =>
               block.fields[0]?.informativeButtons && block.fields[0]?.informativeButtons[0]?.type === 'url',
         );

         if (
            followNext &&
            ((currentNode.data?.flowBlocks[1]?.fields[0]?.isButtonRequired === false && isUrl) || isUrl)
         ) {
            const nextNode = nodesList.find((node) => node.id === currentNode.data?.flowBlocks[0].target);
            if (nextNode) {
               path.push(nextNode);
               explorePaths(nodesList, nextNode, path, visitedNodes);
            }
         } else {
            const hasNext = selectedPath.find((pat) => pat.currentNodeId === currentNode.id);
            if (hasNext) {
               const nextNode = nodesList.find((node) => node.id === hasNext.targetId);
               if (nextNode) {
                  path.push(nextNode);
                  explorePaths(nodesList, nextNode, path, visitedNodes);
               }
            }
         }
      } else if (currentNode.type === FlowNodeType.SendMessage) {
         const nextId = currentNode.data?.flowBlocks.find(
            (block: { type: FlowBlockType }) => block.type === FlowBlockType.SendMessageConnector,
         )?.target;

         if (nextId) {
            const nextNode = nodesList.find((node) => node.id === nextId);
            if (nextNode) {
               path.push(nextNode);
               explorePaths(nodesList, nextNode, path, visitedNodes);
            }
         }
      } else if (
         currentNode.type === FlowNodeType.Delay ||
         currentNode.type === FlowNodeType.StarFlow ||
         currentNode.type === FlowNodeType.Actions
      ) {
         const nextId = currentNode.data?.flowBlocks[0]?.target;

         if (nextId) {
            const nextNode = nodesList.find((node) => node.id === nextId);
            if (nextNode) {
               path.push(nextNode);
               explorePaths(nodesList, nextNode, path, visitedNodes);
            }
         }
      } else if (currentNode.type === FlowNodeType.AskQuestion) {
         const hasReply = replyQuestion.find((pat) => pat.questionId === currentNode.id);

         if (hasReply) {
            const node = {
               id: generateRandomId(),
               type: 'reply_question',
               data: { title: hasReply.text, questionId: hasReply.questionId },
               position: { x: 0, y: 0 },
            };
            path.push(node);
            explorePaths(nodesList, node, path, visitedNodes);

            const nextNode = nodesList.find(
               (node) => node.id === currentNode.data?.flowBlocks[0]?.fields[0]?.target_on_reply,
            );
            if (nextNode) {
               path.push(nextNode);
               explorePaths(nodesList, nextNode, path, visitedNodes);
            }
         } else {
            //console.log('Reached AskQuestion node. Stopping exploration.');
         }
      }
   };

   // ...

   useEffect(() => {
      const reversedData = contentNodes.slice().reverse();
      const startingNodeId = reversedData[0]?.data.flowBlocks?.filter(
         (block: { type: FlowBlockType }) =>
            block.type === FlowBlockType.NewMessageReceived || block.type === FlowBlockType.UserVisitPage,
      )[0]?.target;

      if (startingNodeId) {
         const startNode = reversedData.find((node) => node.id === startingNodeId);

         const path: Node[] = [];
         const visitedNodes = new Set<string>();
         if (startNode) {
            path.push(startNode);
            explorePaths(reversedData, startNode, path, visitedNodes);
         }
         setPaths(path);
      }
   }, [contentNodes, replyQuestion, selectedPath]);

   return (
      <div>
         <TestFlowModal
            open={open}
            setOpen={setOpen}
            anchorElField={anchorElField}
            paths={paths}
            setPaths={setPaths}
            setSelectedPath={setSelectedPath}
            selectedPath={selectedPath}
            setReplyQuestion={setReplyQuestion}
         />
      </div>
   );
};

export default TestFlow;

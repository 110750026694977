import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Package from './package';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { LanguageContext } from '@helper/locale/langContext';
import { specialPackage } from '@lib/utils';
import { RenewalType } from '@types/enums';

const useStyles = makeStyles((theme) => ({
   packageContainer: {
      padding: 0,
      marginTop: '.5rem',
      [theme.breakpoints.up('md')]: {
         padding: 5,
      },
   },
}));

// period mode -> 1 Yearly
// period mode -> 0 Monthly

const Packages = ({ width, mode, onBuy, products }) => {
   const classes = useStyles();
   const customer = useSelector((rootReducer) => rootReducer.personReducer.person.customer);
   const activeProductName = customer.package.name;
   const activeProductPeriodMode = customer.package.periodMode;
   const modeToPeriodMode = mode === RenewalType.MONTHLY ? 0 : 1;
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;
   const hasSpecialPackage = products?.findIndex((product) => product.name === 'Special') > -1;

   function getBtnText(productName) {
      if (productName === activeProductName) {
         // if customer current package same with mode
         if (modeToPeriodMode === activeProductPeriodMode) {
            return t?.active_packages;
         } else {
            // if customer package is yearly
            if (activeProductPeriodMode === 1) {
               return t?.switch_to_package_monthly;
            } else return t?.switch_to_package_yearly; // is not yearly
         }
      }
      if (productName === 'Special') return t?.upgrade;
      if (activeProductName === 'Start') {
         return t?.upgrade;
      } else if (activeProductName === 'Grow' && productName === 'Start') {
         return t?.downgrade;
      } else if (activeProductName === 'Grow' && productName === 'Scale') {
         return t?.upgrade;
      } else if (activeProductName === 'Scale') {
         return t?.downgrade;
      }
   }

   return (
      <Grid container direction='row' justifyContent='flex-start'>
         {products?.map((product) => (
            <Grid key={product._id} item xs={12} sm={12} md={6} lg={3} className={classes.packageContainer}>
               <Package
                  product={product}
                  mode={mode}
                  onBuy={onBuy}
                  isActive={customer.package.name === product.name && modeToPeriodMode === activeProductPeriodMode}
                  btnText={getBtnText(product.name) || t?.package}
               />
            </Grid>
         ))}
         {/* Temp Special Package Customer Does not have any special package */}
         {!hasSpecialPackage && (
            <Grid item xs={12} sm={12} md={6} lg={3} className={classes.packageContainer}>
               <Package
                  product={specialPackage}
                  mode={mode}
                  onBuy={onBuy}
                  isActive={false}
                  isTemp
                  btnText={t?.contact_us}
               />
            </Grid>
         )}
      </Grid>
   );
};

export default Packages;

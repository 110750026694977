import { useMutation, useQuery } from '@apollo/client';
import ButtonOK from '@components/ButtonOK/buttonOk';
import Modal from '@components/Modal/modal';
import { LanguageContext } from '@helper/locale/langContext';
import { Grid, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
   CREATED_CUSTOM_FIELD_MUTATION,
   DELETED_CUSTOM_FIELD_MUTATION,
   UPDATED_CUSTOM_FIELD_MUTATION,
} from '@queries/Settings/Service/GraphQL/CustomFields/mutation';
import { GET_CUSTOM_FIELDS } from '@queries/Settings/Service/GraphQL/CustomFields/query';
import { useSnackbar } from 'notistack';
import React, { Fragment, useContext, useState } from 'react';
import { Person } from 'src/types/commonTypes';
import ActionIconButton from '../ActionIconButton';
import { StyledTableCell, StyledTableRow } from '../shared-components/tableItems';
import { ICustomFields } from './customFields';
import ModalContent from './modalContent';

const useStyles = makeStyles((theme: any) => ({
   table: {
      minWidth: 700,
   },

   ButtonContainer: {
      paddingLeft: 5,
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
   },

   titleContainer: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      borderRadius: 15,
      padding: 15,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
   },
   coloredText: {
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontSize: '1rem',
      fontWeight: 'bold',
   },
   label: {
      paddingRight: 8,
   },
}));

const CustomFields = ({ person, token }: { person: Person; token: string }) => {
   const classes = useStyles();
   const { lang } = useContext(LanguageContext);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const t = lang?.translation;

   const [modalConfig, setModalConfig] = useState<{ show: boolean; operation: string; data: ICustomFields | null }>({
      show: false,
      operation: '' /* DELETE | NEW | EDIT */,
      data: null,
   });

   const [customFieldData, setCustomFieldData] = useState<ICustomFields[]>([]);

   const [deleteCustomFieldMutation, { loading: deleteCustomFieldLoading }] =
      useMutation(DELETED_CUSTOM_FIELD_MUTATION);

   const [createdCustomFieldMutation, { loading: createdCustomFieldLoading }] =
      useMutation(CREATED_CUSTOM_FIELD_MUTATION);

   const [updatedCustomFieldMutation, { loading: updatedCustomFieldLoading }] =
      useMutation(UPDATED_CUSTOM_FIELD_MUTATION);

   const { loading: getCustomFieldsLoading } = useQuery(GET_CUSTOM_FIELDS, {
      variables: {
         customer: person.customer._id,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data: {
         getCustomFields: {
            data: ICustomFields[];
         };
      }) => {
         setCustomFieldData(data.getCustomFields.data);
      },
      onError: (error) => {
         enqueueSnackbar(error.message, {
            variant: 'error',
            persist: true,
            action: (key) => (
               <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                  Kapat
               </div>
            ),
         });
      },
   });

   const handleCloseModal = () => {
      setModalConfig({
         show: false,
         operation: '',
         data: null,
      });
   };

   const handleCreateNewModal = () => {
      setModalConfig({
         show: true,
         operation: 'CREATE',
         data: null,
      });
   };

   const handleDeleteModal = (data: ICustomFields) => {
      setModalConfig({
         show: true,
         operation: 'DELETE',
         data,
      });
   };

   const handleUpdateModal = (data: ICustomFields) => {
      setModalConfig({
         show: true,
         operation: 'EDIT',
         data,
      });
   };

   const handleCreateCustomField = async (data: {
      name: string;
      type: { label: string; value: string };
      description: string;
      listValues: string[] | null;
   }) => {
      await createdCustomFieldMutation({
         variables: {
            name: data.name,
            type: data.type.value,
            description: data.description,
            listValues: data.listValues,
            customer: person.customer._id,
         },
      })
         .then((data) => {
            enqueueSnackbar(
               `${data.data?.createCustomField.data.name} ${t['general_information_added_successfully']}`,
               {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               },
            );
            setCustomFieldData([...customFieldData, data.data?.createCustomField.data]);
            handleCloseModal();
         })
         .catch((error) => {
            // error event
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const handleUpdateCustomField = async (data: {
      _id: string;
      name: string;
      type: { label: string; value: string };
      description: string;
      listValues: string[] | null;
   }) => {
      await updatedCustomFieldMutation({
         variables: {
            _id: data._id,
            name: data.name,
            type: data.type.value,
            description: data.description,
            listValues: data.listValues,
            customer: person.customer._id,
         },
      })
         .then((data) => {
            enqueueSnackbar(
               `${data.data?.updateCustomField.data.name} ${t['general_information_updated_successfully']}`,
               {
                  // send success message
                  variant: 'success',
                  autoHideDuration: 3000,
               },
            );
            setCustomFieldData(
               customFieldData.map((item) => {
                  if (item._id === data.data?.updateCustomField.data._id) {
                     return data.data?.updateCustomField.data;
                  }
                  return item;
               }),
            );
            handleCloseModal();
         })
         .catch((error) => {
            // error event
            enqueueSnackbar(error.message, {
               // send success message
               variant: 'error',
               persist: true,
               action: (key) => (
                  <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                     Kapat
                  </div>
               ),
            });
         });
   };

   const handleDeleteCustomField = async (_id: string | null) => {
      handleCloseModal();
      if (_id) {
         await deleteCustomFieldMutation({
            variables: {
               _id,
               customer: person.customer._id,
            },
         }) // run to delete mutation
            .then((data) => {
               enqueueSnackbar(
                  `${data.data?.deleteCustomField.data.name} ${t['general_information_deleted_successfully']}`,
                  {
                     // send success message
                     variant: 'success',
                     autoHideDuration: 3000,
                  },
               );
               setCustomFieldData(customFieldData.filter((item) => item._id !== _id));
            })
            .catch((error: { message: string }) => {
               // error event
               enqueueSnackbar(error.message, {
                  // send success message
                  variant: 'error',
                  persist: true,
                  action: (key) => (
                     <div className='snackbar-error' onClick={() => closeSnackbar(key)}>
                        Kapat
                     </div>
                  ),
               });
            });
      }
   };

   const isLoading = getCustomFieldsLoading || deleteCustomFieldLoading;

   if (isLoading) {
      return <div className='loading' />;
   }

   return (
      <Fragment>
         <Grid container justifyContent='space-between' alignItems='center' className={classes.titleContainer}>
            <div style={{ display: 'flex' }}>
               <Typography
                  style={{
                     marginLeft: 10,
                     fontSize: '1.1rem',
                     fontWeight: 'bold',
                     lineHeight: '35px',
                  }}
               >
                  {t['page_settings_typo_custom_fields']}
               </Typography>
            </div>
            <Grid className={classes.ButtonContainer}>
               <ButtonOK
                  onClick={handleCreateNewModal}
                  width={200}
                  height={40}
                  fontSize='1.1rem'
                  label={t.page_settings_type_new_custom_fields}
               />
            </Grid>
         </Grid>
         <Grid>
            <TableContainer>
               <Table className={classes.table} aria-label='customized table'>
                  <TableHead>
                     <TableRow>
                        <StyledTableCell align='left'>
                           {t['page_setting_type_custom_fields_field_name']}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                           {t['page_setting_type_custom_fields_field_description']}{' '}
                        </StyledTableCell>
                        <StyledTableCell align='left'>
                           {t['page_setting_type_custom_fields_field_type']}
                        </StyledTableCell>
                        <StyledTableCell align='right'></StyledTableCell>
                     </TableRow>
                  </TableHead>
                  <TableBody>
                     {Array.from(customFieldData)
                        .reverse()
                        .map((item: ICustomFields) => (
                           <StyledTableRow key={item._id}>
                              <StyledTableCell component='th' scope='row'>
                                 <Typography className={classes.coloredText}>{item.name}</Typography>
                              </StyledTableCell>
                              <StyledTableCell component='th' scope='row'>
                                 <Typography>{item.description}</Typography>
                              </StyledTableCell>
                              <StyledTableCell component='th' scope='row'>
                                 <Typography>{item.type}</Typography>
                              </StyledTableCell>
                              <StyledTableCell align='right'>
                                 <ActionIconButton
                                    onClick={() => handleUpdateModal(item)}
                                    tooltip={t?.comp_button_edit}
                                    actionType='EDIT'
                                 />
                                 <ActionIconButton
                                    onClick={() => handleDeleteModal(item)}
                                    tooltip={t?.comp_button_delete}
                                    actionType='DELETE'
                                 />
                              </StyledTableCell>
                           </StyledTableRow>
                        ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </Grid>
         <Modal
            paperStyle={{ width: modalConfig.operation === 'DELETE' ? 450 : 600 }}
            open={modalConfig.show}
            onClose={handleCloseModal}
            modalHeader={
               modalConfig.operation === 'DELETE' ? t?.general_message_are_your_sure : t.page_settings_typo_custom_field
            }
         >
            <ModalContent
            updatedCustomFieldLoading={updatedCustomFieldLoading}
               createdCustomFieldLoading={createdCustomFieldLoading}
               mode={modalConfig}
               onAccept={handleCreateCustomField}
               onEdit={handleUpdateCustomField}
               onCancel={handleCloseModal}
               onDelete={handleDeleteCustomField}
            />
         </Modal>
      </Fragment>
   );
};

export default CustomFields;

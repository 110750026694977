import React, { Fragment, useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import Toolbar from '../toolbar';
import moment from 'moment-timezone';

import Chip from '../chip';
import { useQuery } from '@apollo/client';
import _ from 'underscore';
import { useSelector } from 'react-redux';
import { GET_TAGS_STATISTICS_QUERY } from '../../../../queries/Statistics/Service/GraphQL/query';
import { LanguageContext } from '@helper/locale/langContext';
import Error from '@components/Errors/Error';
import TagItem from './item';

const useStyles = makeStyles((theme) => ({
   padding20: {
      padding: 20,
   },
   chipContainer: {
      paddingRight: 20,
      paddingLeft: 20,
   },
   marginTop20: {
      marginTop: 20,
      overflowY: 'auto',
   },
   pieChartContainer: {
      padding: '10px 20px',
      marginTop: 10,
   },
   emptyList: {
      fontSize: '1.3rem',
      color: theme.chatApp.general.pallet.passiveStructureBlue,
      fontWeight: 'bold',
      marginTop: 40,
   },
}));

const Tags = () => {
   const classes = useStyles();
   const [state, setState] = useState({
      startDate: moment().valueOf(),
      endDate: moment().valueOf(),
      platform: 'ALL',
      totalReceived: 0,
      totalSent: 0,
      chartData: [],
   });
   // Language Context
   const { lang } = useContext(LanguageContext);
   const t = lang?.translation;

   const MOCK_SUMMARY_DATA = [
      {
         title: t['page_statistics_typo_total_chats'],
         value: 0,
      },
      {
         title: t['page_statistics_typo_total_tag_chats'],
         value: 0,
      },
      {
         title: t['page_statistics_typo_not_total_tag_chats'],
         value: 0,
      },
   ];

   const person = useSelector((rootReducer) => rootReducer.personReducer.person);

   const handleChangeFilterDate = (startDate, endDate) => {
      setState((prevState) => {
         const newState = { ...prevState };
         if (startDate) {
            newState.startDate = startDate;
         }
         if (endDate) {
            newState.endDate = endDate;
         }

         return newState;
      });
   };
   const handleChangePlatform = (platform) => {
      setState((prevState) => ({ ...prevState, platform }));
   };
   // get chat tags
   const {
      data: chatTagsStatistics,
      error,
      refetch,
      loading,
      networkStatus,
   } = useQuery(GET_TAGS_STATISTICS_QUERY, {
      variables: {
         customer: person.customer._id,
         startDate: state.startDate,
         endDate: state.endDate,
         platform: state.platform,
      },
      fetchPolicy: 'network-only',
   });

   useEffect(() => {
      refetch();
   }, [state, refetch]);

   const loadingOrFetching = networkStatus === 4 || loading;
   let totalAccumulatedTags = 0;
   let tagPercents = [];
   if (chatTagsStatistics?.getCustomerTagStatistics && !error) {
      const report = chatTagsStatistics.getCustomerTagStatistics.data;
      MOCK_SUMMARY_DATA[0].value = report.totalChats;
      MOCK_SUMMARY_DATA[1].value = report.totalTaggedChats;
      MOCK_SUMMARY_DATA[2].value = report.totalChats - report.totalTaggedChats;
      const tagsList = _.sortBy(chatTagsStatistics?.getCustomerTagStatistics?.data.tagsData, 'count').reverse();
      totalAccumulatedTags = tagsList.map((item) => item.count).reduce((a, b) => a + b, 0);
      for (let i = 0; i < tagsList.length; i++) {
         const isLast = i === tagsList.length - 1;
         if (isLast) {
            tagPercents[i] = 100 - tagPercents.reduce((a, b) => a + b, 0);
         } else {
            tagPercents[i] = (100 * tagsList[i].count) / totalAccumulatedTags;
         }
      }
   }

   return (
      <Fragment>
         {loadingOrFetching && <div className='loading' />}
         <Toolbar
            onPlatformChange={handleChangePlatform}
            platform={state.platform}
            onDateChange={handleChangeFilterDate}
            startDate={state.startDate}
            endDate={state.endDate}
            title={t['page_statistics_typo_tags']}
            operation={'getCustomerTagStatistics'}
            params={{
               customer: person.customer._id,
               startDate: state.startDate,
               endDate: state.endDate,
               platform: state.platform,
            }}
         />
         {chatTagsStatistics?.getCustomerTagStatistics && !error && (
            <Grid container direction='column' className={classes.padding20}>
               <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                  {MOCK_SUMMARY_DATA.map((item) => (
                     <Grid key={item.title} item xs={4} className={classes.chipContainer}>
                        <Chip key={item.title} title={item.title} value={item.value} />
                     </Grid>
                  ))}
               </Grid>
               <Grid
                  container
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  className={classes.marginTop20}
               >
                  {chatTagsStatistics?.getCustomerTagStatistics?.data?.tagsData?.length > 0 ? (
                     _.sortBy(chatTagsStatistics.getCustomerTagStatistics.data.tagsData, 'count')
                        .reverse()
                        .map((item, i) => (
                           <Grid key={item._id} item xs={12} className={classes.pieChartContainer}>
                              <TagItem
                                 value={item.count}
                                 totalValue={totalAccumulatedTags}
                                 name={item.title}
                                 color={item.color}
                                 percent={tagPercents[i].toFixed(2)}
                              />
                           </Grid>
                        ))
                  ) : (
                     <Grid xs={12} container justifyContent='center' style={{ height: 100 }}>
                        <Typography className={classes.emptyList}>{t?.your_message_list_is_empty}</Typography>
                     </Grid>
                  )}
               </Grid>
            </Grid>
         )}
         <Error show={!!error} message={error?.message} />
      </Fragment>
   );
};

export default Tags;

import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import NotifCount from '../NotifCount';
import { IPlatformIconButtonProps } from './types';

const useStyles = makeStyles((theme) => ({
   root: {
      display: 'inline-block',
      borderRadius: '10px',
      width: 50,
      height: 50,
      boxShadow: 'none',
   },
   badge: {
      border: `2px solid`,
      borderRadius: 7,
      fontsize: '1.5em',
   },
}));

const PlatformIconButton: FC<IPlatformIconButtonProps> = ({ onClick, count, children }) => {
   const classes = useStyles();
   // only for test
   return (
      <Paper className={classes.root} elevation={1} onClick={onClick}>
         <NotifCount count={count} max={99}>
            {children}
         </NotifCount>
      </Paper>
   );
};

export default PlatformIconButton;

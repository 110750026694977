// import Item from '../SideBar/item';
import { ButtonBase, Grid, useMediaQuery } from '@material-ui/core';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { SITE_URL } from '@helper/config';
import ButtonBasedPopup from '@components/ButtonBasedPopup';
import MediumAvatar from '@components/MediumAvatar';
import ChatButtons from './chatButtons';
import { getPersonActivities } from '@store/reducers/personActivity';
import { PLATFORM_TYPE } from '@queries/Settings/Service/GraphQL/Platform/types';
const ChatNavbarElements = ({ toggleProfile }) => {
   const [collapsButtons, setCollapseButtons] = useState(false);
   const [open, setOpen] = useState(false);
   const person = useSelector((reducer) => reducer.personReducer.person);
   const activeChat = useSelector((reducer) => reducer.chatReducer.activeChat);
   const isSmaller = useMediaQuery('(max-width: 1450px)');

   useEffect(() => {
      setCollapseButtons(isSmaller);
   }, [isSmaller]);

   let participant = activeChat?.participants?.filter(
      (participant) => participant._id.toString() !== person?._id.toString(),
   )[0];
   const personActivities = useSelector(getPersonActivities);

   return (
      <Grid container wrap='nowrap' xs={12} spacing={2}>
         <ButtonBase onClick={toggleProfile}>
            <MediumAvatar
               onlineStatus={participant?.onlineStatus}
               hideCircle
               navBarAvatar={true}
               isCustomer
               image={participant?.photo ? participant?.photo : SITE_URL + '/none.jpg'}
               title={participant?.name}
               name={participant?.name}
               username={participant?.email}
               socialMedia={person.socialMedia}
               showOnlineStatus={activeChat?.type == PLATFORM_TYPE.LIVE_CHAT}
               online={personActivities.some((e) => e.personString == participant?._id)}
            />
         </ButtonBase>
         <Grid item xs={12} container direction='row' justifyContent='center' alignItems='center'>
            {collapsButtons ? (
               <Grid container justifyContent='flex-end'>
                  <ButtonBasedPopup
                     open={open}
                     onOpen={() => setOpen(true)}
                     onClose={() => setOpen(false)}
                     button={<MoreIcon style={{ color: 'grey' }} />}
                  >
                     <ChatButtons />
                  </ButtonBasedPopup>
               </Grid>
            ) : (
               <ChatButtons />
            )}
         </Grid>
      </Grid>
   );
};

export default React.memo(ChatNavbarElements);
